import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import Grid from '@material-ui/core/Grid';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import { FormGroup } from '@material-ui/core';

const initialState = {
    formData: {
        autoPartUpdates: false,
        autoDocumentUpdates: false
    },
    staffAutoUpdatesMenuAnchor: null
}

class UpdateStaffAutoUpdates extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount() {
        this.getComponentData();
    }
    getComponentData = () => {
        const { staffData } = this.props; 
        this.setState({
            formData:{
                autoPartUpdates: staffData.staff_auto_part_updates,
                autoDocumentUpdates: staffData.staff_auto_document_updates
            }
        });
    }
    handleStaffAutoUpdatesMenuClick = event => this.setState({ staffAutoUpdatesMenuAnchor: event.currentTarget });
    handleStaffAutoUpdatesMenuClose = path => this.setState({ staffAutoUpdatesMenuAnchor: null }, () => this.props.history.push(path));
    render() {
        return(
            <React.Fragment>
                <Card>
                    {(this.props.isLoading && (
                        <LoadingCircle />
                    )) || (
                        <React.Fragment>
                            <CardContent>
                                <Grid container item alignItems="center" align="center" spacing={1}>
                                    <Grid item xs={1}>
                                        <FALightIcon icon='envelope' size='large' />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        name="autoPartUpdates"
                                                        color="primary" 
                                                        checked={this.state.formData.autoPartUpdates ? true : false}
                                                        disabled
                                                    />
                                                }
                                                label="Part Updates"
                                                labelPlacement="end"
                                                style={{margin: '0 10px'}}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="autoDocumentUpdates"
                                                        color="primary"
                                                        checked={this.state.formData.autoDocumentUpdates ? true : false}
                                                        disabled
                                                    />
                                                }
                                                label="Document Updates"
                                                labelPlacement="end"
                                                style={{margin: '0 10px'}}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </React.Fragment>
                    )}
                </Card>
            </React.Fragment>
        );
    }
}

export default UpdateStaffAutoUpdates;