import React, { Component } from 'react';
import API from 'API';
import _ from 'lodash';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';

import { Grid, Typography } from '@material-ui/core/';

const initialState = {
    isLoading:          true, 
    parts:              [],
    part:               null,
    stock:              {},
}

class QuickPartQrScan extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount() {
        this.getParts();
    }

    getParts = () => {
        API.get('/parts/all', { params: { use: 'partSearch', withStock: true }}).then(res => {
            this.setState({ 
                parts:     _.map(res.data, part => ({ value: part.part_id, label: `${part.part_number} - ${part.part_description}`, part })), 
                isLoading: false 
            });
        })
    }

    getPart = (part) => {
        API.get(`/toolbox/wheresMyStock/${part.value}`).then(res => {
            this.setState({
                part:  part.value,
                stock: res.data
            })
        })
    }

    render() {

        const { parts, isLoading, part, stock } = this.state;

        if (!parts.length && !isLoading) { return <LoadingCircle /> }

        return (
            <Grid container spacing={3}>
                <Grid item lg={4} md={6} xs={12}>
                    <PaddedPaper>
                        <AutoCompleteSelect
                            fullWidth
                            label="Part"
                            options={parts}
                            value={part}
                            onChange={this.getPart}
                        /><br/><br/>
                        * Note all stock values are for after 31/09/2022
                    </PaddedPaper>
                </Grid>
                {stock && part && 
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{fontWeight: 'bold', textTransform: 'uppercase'}}>STOCK</Typography>
                                </Grid>
                                <Grid item xs={3}><Typography variant='body1'><b>Activity: {parseFloat(stock['total'].a).toFixed(3)}</b></Typography></Grid>
                                <Grid item xs={3}><Typography variant='body1'><b>Calculated: {parseFloat(stock['total'].c).toFixed(3)}</b></Typography></Grid>
                                <Grid item xs={3}><Typography variant='body1'><b>Database: {parseFloat(stock['total'].s).toFixed(3)}</b></Typography></Grid>
                                <Grid item xs={3}><Typography variant='body1'><b>Activity Last: {parseFloat(stock['total'].l).toFixed(3)}</b></Typography></Grid>
                                <Grid item xs={12}>
                                    <DataTable
                                        config={{
                                            key: 'key',
                                            noHeader: true,
                                        }}
                                        columns={[
                                            {
                                                heading: '#',
                                                field:   'key',
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: 'Activity',
                                                field:   'a',
                                                fieldFormat: 'decimal:3',
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: 'Calculated',
                                                field:   'c',
                                                fieldFormat: 'decimal:3',
                                                sizeToContent: true,
                                            }
                                        ]}
                                        rows={_.filter(_.map(_.keys(stock), key => key !== 'total' && ({
                                            key,
                                            ...stock[key]
                                        })), i => !!i)}
                                    />
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
                {stock && _.map(_.keys(stock), key => 
                    (key !== 'total') && (stock[key].a !== stock[key].c) && 
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" style={{fontWeight: 'bold', textTransform: 'uppercase'}}>{key}</Typography>
                                    </Grid>
                                    <Grid item xs={4}><Typography variant='body1'><b>Activity: {parseFloat(stock[key].a).toFixed(3)}</b></Typography></Grid>
                                    <Grid item xs={4}><Typography variant='body1'><b>Calculated: {parseFloat(stock[key].c).toFixed(3)}</b></Typography></Grid>
                                    <Grid item xs={4}><Typography variant='body1'><b>Diff: {parseFloat(Math.abs(stock[key].a - stock[key].c)).toFixed(3)}</b></Typography></Grid>
                                    {stock[key]?.i?.length > 0 &&
                                        <Grid item xs={12}>
                                            <DataTable
                                                config={{
                                                    key:         'q',
                                                    noHeader: true,
                                                }}
                                                columns={[
                                                    {
                                                        heading: '#',
                                                        field:   'q',
                                                        type:    'number',
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading: 'order',
                                                        field:   'w',
                                                    },
                                                    {
                                                        actions: r => ([
                                                            {
                                                                icon: icons.search,
                                                                link: key === 'sales' ? `/sales/orders/${r.q}` : 
                                                                    (key === 'returns' ? `/returns/creditnote/${r.q}` : (
                                                                        (key === 'greenaware' ? `/returns/greenaware` : (
                                                                            (key === 'worksOrder' ? `/WorksOrders/view/${r.q}` : null)
                                                                        ))
                                                                    )),
                                                            }
                                                        ])
                                                    }
                                                ]}
                                                rows={_.uniqBy(stock[key].i, 'q')}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                )}
                {stock && part && stock['total'].q.length > 0 &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{fontWeight: 'bold', textTransform: 'uppercase'}}>Faulty Activity</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <DataTable
                                        config={{
                                            key: 'q'
                                        }}
                                        columns={[
                                            {
                                                heading: '#',
                                                field:   r => <>{r.q}<br/><span style={{color: colors.red}}>{r.o}</span></>,
                                                sizeToContent: true,
                                                alignment: 'center',
                                            },
                                            {
                                                heading: 'Type',
                                                field:   r => <>{r.w}<br/><span style={{color: colors.red}}>{r.p}</span></>,
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: 'Ref',
                                                field:   r => <>{r.e}<br/><span style={{color: colors.red}}>{r.a}</span></>,
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: 'Discrepancy',
                                                field:   r => <>{r.t} {' -> '} {r.s}</>,
                                                sizeToContent: true,
                                                alignment: 'center',
                                            },
                                            {
                                                heading: '',
                                                field: r => parseFloat(r.s - r.t).toFixed(3),
                                                sizeToContent: true,
                                                alignment: 'center',
                                            },
                                            {
                                                heading: 'Start / End',
                                                field:   r => <>{r.r} {' -> '} {r.t}<br/><span style={{color: colors.red}}>{r.s} {' -> '} {r.d}</span></>,
                                            },
                                            {
                                                heading: 'Change',
                                                field:   r => <>{r.y}<br/><span style={{color: colors.red}}>{r.f}</span></>,
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: 'Date',
                                                field:   r => <>{r.i}<br/><span style={{color: colors.red}}>{r.h}</span></>,
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: 'Created',
                                                field:   r => <>{r.u}<br/><span style={{color: colors.red}}>{r.g}</span></>,
                                                sizeToContent: true,
                                            },
                                        ]}
                                        rows={stock['total'].q}
                                    />
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
            </Grid>
        )
    }
}

export default QuickPartQrScan;