import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { Divider, Table, TableBody, TableRow, TableCell, Avatar } from '@material-ui/core';
import { displayFileSize } from '../../../Functions/MiscFunctions';
import { isTouchMonitor } from 'Functions/MiscFunctions';
import TouchScreenFileInput from './TouchScreenFileInput';

const style = theme => ({
    dropzone: {
        marginTop: 24,
        backgroundColor: '#fff',
        whiteSpace: 'nowrap', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis',
        textAlign: 'center',
        padding: '8px'
    },
    dropzoneArea: {
        border: '1px dashed rgb(187, 186, 186)',
        borderRadius: 4,
    },      
    dropzoneDisabled: {
        backgroundColor: 'rgba(187, 186, 186, 0.1)',
        color: 'rgba(0,0,0,0.5)',
        cursor: 'default'
    },    
    dropzoneHover: {
        color: '#fff',
        backgroundColor: '#0282C6'
    },  
    dropzoneHasFile: {
        backgroundColor: '#e8f5e9'
    },
    fileInput: {
        display: 'none'
    }
});
const initialState = {
    fileKey: 1,
    dropzoneHover: false
}
class DragFileInputMulti extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState;
        this.fileInputRef = React.createRef();
    }
    componentDidMount = () => {
    }
    handleChange = (drop, name, event) => {
        if(drop) event.preventDefault();     
        if(this.props.disabled) return;
        this.props.onChange(drop, name, event);
        this.setState({
            dropzoneHover: false
        });
    }
    handleClearFile = (fileIdx, cancelOnClick) => {
        this.setState({
            fileKey: Math.random().toString(36),
        }, () => this.props.cancelOnClick(fileIdx));
    }
    
    handleDragOver = (event) => {
        event.preventDefault();        
        if(this.props.disabled) return;
        this.setState({
            dropzoneHover: true 
        });
    }
    handleDragLeave = () => {
        this.setState({
            dropzoneHover: false
        });
    }
    
    handleOpenDialog = () => {
        if(this.props.disabled) return;
        this.fileInputRef.current.click();
    }
    render() {
        const { fileKey, dropzoneHover } = this.state;
        const { classes, type, disableTouchMonitor } = this.props;

        
        if ( isTouchMonitor() && !disableTouchMonitor ) return (<TouchScreenFileInput multiple {...this.props} />);

        return (
            <FormControl 
                error={!!this.props.errorText}
                fullWidth 
                key={`file-${fileKey}`}
                margin={this.props.noMargin ? 'none' : 'normal'}                      
            >                
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor={this.props.id} shrink={true}>
                            {this.props.label}
                        </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <div
                            className={`${classes.dropzone} ${classes.dropzoneArea} ${dropzoneHover ? classes.dropzoneHover : ''} ${this.props.file ? classes.dropzoneHasFile : ''} ${this.props.disabled ? classes.dropzoneDisabled : ''}`}
                            onDragOver={this.handleDragOver}
                            onDragLeave={this.handleDragLeave}
                            onDrop={(event) => this.handleChange(true, this.props.name, event)} 
                        >
                            <div onClick={this.handleOpenDialog} style={{cursor: 'pointer'}}>
                                <input 
                                    className={`${classes.fileInput}`}
                                    disabled={this.props.disabled}
                                    file={this.props.file}
                                    id={this.props.id}
                                    name={this.props.name}
                                    onChange={(event) => this.handleChange(false, this.props.name, event)}
                                    ref={this.fileInputRef}
                                    type="file"
                                    multiple
                                />
                                {(dropzoneHover && (
                                    <FAIcon type="light" icon="file-check" style={{marginBottom: 5}} buttonPrimary />
                                )) || (
                                    <FAIcon type="light" icon="upload" style={{marginBottom: 5, color: this.props.disabled ? 'rgba(0,0,0,0.2)' : ''}} button/>
                                )}
                                {(this.props.placeholder && (                                 
                                    <Typography variant="body2">
                                        {this.props.placeholder}
                                    </Typography>
                                )) || (
                                    (dropzoneHover && (
                                        <Typography variant="body2">
                                            Drop {type}(s) to upload.
                                        </Typography>
                                    )) || (
                                        <Typography variant="body2">
                                            Choose {type}(s) or drag here.
                                        </Typography>
                                    )
                                )}
                            </div>
                            {(this.props.file && this.props.file.length > 0 && (
                                <React.Fragment>
                                    <Divider style={{margin: '8px 0'}} />
                                    <div style={{maxWidth: '100%', overflowX: 'auto'}}>
                                        <Table>
                                            <TableBody>
                                                {Array.from(this.props.file).map((file, fileIdx) => 
                                                    file && (
                                                    <TableRow key={fileIdx}>
                                                        <TableCell style={{width:1}}>
                                                            <Avatar alt={file.name} src={URL.createObjectURL(file)}>
                                                                {/*Icon*/}
                                                            </Avatar>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="subtitle2">
                                                                {file.name}
                                                            </Typography>
                                                            <Typography variant="caption">
                                                                {displayFileSize(file.size)}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell style={{width:1}}>
                                                            <span key={fileIdx} onClick={() => this.handleClearFile(fileIdx)} >
                                                                <FAIcon type="light" icon='times-circle' size='small' style={{marginRight: 5}} button />
                                                            </span>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </React.Fragment>
                            )) || (
                                <React.Fragment />
                            )}
                        </div>
                        {this.props.errorText &&
                            <FormHelperText>{this.props.errorText}</FormHelperText>
                        }
                    </Grid>
                </Grid>
            </FormControl>
        )
    }
}
export default withStyles(style)(DragFileInputMulti);
