import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import moment               from 'moment';
import { connect }          from 'react-redux';

import DataTable          from 'Components/Common/DataTables/CiDataTable';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import Textarea           from 'Components/Common/Inputs/Textarea';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import ProgressBar        from 'Components/Common/ProgressBar/ProgressBar';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import SelectPartWithQR   from 'Components/Common/Selects/SelectPartWithQR';

import { colors }    from 'Helpers/ColourHelper';
import icons         from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';

import { AppBar, Button, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, List, ListItem, ListItemText, Tab, Tabs, TextField, 
    Typography, Dialog } from '@material-ui/core/';

import logo from 'Assets/Images/clenaware_logo_icon.png';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

const initialState = {
    isLoading:   true,
    formData:    {
        part:       '',
        customPart: null,
        trackerQty: '',
        qty:        '',
    },
    partList:       [],
    staffList:      [],
    trackers:       [],
    showTracker:    0,
    notes:          null,
    currentTab:     'Created'
}


class ThreeDPrinterTracker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            showTracker: parseInt(this.props.match.params.tid)
        }
    }

    componentDidMount = () => { 
        this.getStaticData();
        this.getData(); 
    }

    getStaticData = () => {
        Promise.all([
            API.get('/parts/all' , {params: {active: true, has: '3D'}}),
            API.get('/parts/all' , {params: {active: true}}),
            API.get('/staff/all/withAccess/threed-printing-tracker:notification', {params: {active: true }}),
            API.get('/staff/my/access/check/threed-printing-tracker:create'), 
        ]).then(([partsRes, oPartRes, staffRes, createAccessRes]) => {
            this.setState({
                partList:   _.map(partsRes.data, part => _.assign({
                                    value: part.part_id,
                                    label: `${part.part_number} - ${part.part_description}`,
                                    part
                                })
                            ),
                otherPartList: _.map(_.filter(oPartRes.data, i => !_.find(partsRes.data, {part_id: i.part_id})), part => _.assign({
                                        value: part.part_id,
                                        label: `${part.part_number} - ${part.part_description}`,
                                        part
                                    })
                                ),
                staffList:  _.map(staffRes.data, staff => _.assign({
                    value: staff.staff_id,
                    label: staff.full_name,
                    staff
                })),
                access: { 
                    create: createAccessRes.data.has_access || false,
                }
            })
        })
    }

    getData = () => {
        this.setState({isLoading: true},()=>
            API.get('/partsOrdering/3dtracker',{params:{status: this.state.currentTab}})
            .then(res => {
                this.setState({
                    trackers:  _.map(res.data, i => {
                        let lastUsed   = _.maxBy(i.prints, 'tdptp_datetime') || null;
                        let lastNote   = _.maxBy(i.notes, 'tdptn_datetime') || null;
                        let lastUpdate = lastUsed ? (
                                            lastNote ? (
                                                moment(lastUsed.tdptp_datetime).isBefore(moment(lastNote.tdptn_datetime)) ? 
                                                    lastNote : lastUsed
                                                ) : lastUsed
                                         ) : lastNote;
                        return {
                            ...i,
                            lastUpdate: lastUpdate && {
                                date:  lastUpdate?.tdptp_datetime || lastUpdate?.tdptn_datetime,
                                staff: lastUpdate?.tdptp_staff    || lastUpdate?.tdptn_tracker,
                                note:  lastUpdate?.tdptn_note     || ''
                            },
                        }
                    }),
                    isLoading: false,
                    note:      null,
                });
            })
        )
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    }

    handleChange = fieldName => event => {
        const value = event?.target?.value ? (event.target.value < 0 ? null : event.target.value) : null;
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: value
            }
        });
    }

    handleCreateStockTrack = () => {
        API.post('/partsOrdering/3dtracker',this.state.formData).then(res => {
            this.getData();
            this.setState({
                showTracker: res.data.tdpt_id,
                formData: initialState.formData
            });
        })
    }

    
    handleTabChange = (event, value) => {
        this.setState({currentTab: value},this.getData);
    };
    
    updateAssigned = e => {
        if (e) API.post(`/partsOrdering/3dtracker/${this.state.showTracker}`,{staff: e.value}).then(this.getData);
    }
    
    handleChangeStatus = (id, status) => {
        if (this.state.note) { API.post(`/partsOrdering/3dtracker/${id}`,{note: this.state.note}); }
        this.setState({showTracker: 0}, () => {API.post(`/partsOrdering/3dtracker/${id}`,{status}).then(this.getData)})
    }
    
    addNote = id => {
        API.post(`/partsOrdering/3dtracker/${id}`,{note: this.state.note})
        .then(res => {this.setState({note: null},this.getData)})
    }

    handleSupplier = e => {
        if (e) API.post(`/partsOrdering/3dtracker/${this.state.showTracker}`,{supplier: e.value}).then(this.getData);
    }

    handleUpdateQty = e => {
        API.post(`/partsOrdering/3dtracker/${this.state.showTracker}`,{printed: this.state.formData.trackerQty})
        .then(res => { this.setState({formData: {...this.state.formData, trackerQty: initialState.formData.trackerQty}},this.getData); });
    }

    downloadFile = download_url => {
        window.open(download_url, '_blank');
    }

    resetForm = () => {
        this.setState({
            formData: initialState.formData
        })
    }

    handleClose = ()=> {
        if (this.state.note) { this.addNote(this.state.showTracker); }
        this.setState({
            showTracker: 0, 
            note: null, 
            formData: {
                ...this.state.formData, 
                trackerQty: initialState.formData.trackerQty
            }
        });
    }

    render() {
        const {partList, otherPartList, isLoading, formData, trackers, access, note, currentTab, showTracker} = this.state;

        const tabs = [
            {label: 'Created',   value: 'Created'},
            {label: 'Printing',   value: 'Printing'},
            {label: 'Completed', value: 'Completed'},
        ]

        const tracker = showTracker && _.find(trackers, {tdpt_id: showTracker});

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h5'>3D Printing Tracker</Typography>
                </Grid>
                <Grid item xs={12}>
                    <AppBar position="static" style={{backgroundColor:'white'}} >
                        <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                            {_.map(tabs, t => <Tab {...t}/>   )}
                        </Tabs>
                    </AppBar>
                </Grid>
                {currentTab == 'Created' && access?.create && 
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <SelectPartWithQR
                                        label=      "3D Part"
                                        parts=      {partList}
                                        onChange=   {this.handleSelectChange('part')}
                                        value=      {formData.part}
                                        margin=     "none"
                                        disabled=   {formData.customPart || _.find(otherPartList, {value: formData.part})}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectPartWithQR
                                        label=      "None 3D Part"
                                        parts=      {otherPartList}
                                        onChange=   {this.handleSelectChange('part')}
                                        value=      {formData.part}
                                        margin=     "none"
                                        disabled=   {formData.customPart || _.find(partList, {value: formData.part})}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label=      "Custom Part"
                                        value=      {formData.customPart}
                                        onChange=   {this.handleChange('customPart')}
                                        disabled=   {formData.part}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name='qty'
                                        label='Qty'
                                        type='number'
                                        value={formData.qty}
                                        onChange={this.handleChange('qty')}
                                        inputProps={{
                                            min: 0,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className='buttonRow'>
                                    <Button onClick={this.resetForm} variant='outlined'><AllIcon icon={icons.history}/>Reset</Button>
                                    <Button disabled={formData.qty < 1 || ( !formData.part && !formData.customPart) || (formData.part == '' && !formData.customPart) || !formData.qty} variant='contained' color='primary' onClick={this.handleCreateStockTrack}>Add</Button>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
                <Grid item xs={12}>
                    {isLoading ? <LoadingCircle/> :
                        <PaddedPaper>
                            <DataTable
                                config={{
                                    key: 'ppot_id',
                                }}
                                columns={[
                                    {
                                        heading:       'Date',
                                        field:         'tdpt_created_datetime',
                                        fieldFormat:   'clenydate',
                                        sizeToContent: true,
                                        alignment:     'center',
                                        filter:        'dateRange'
                                    },
                                    {
                                        heading:       'Part',
                                        field:         rowData => rowData.part ? rowData.part.part_number : 'Custom Part' ,
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       'Description',
                                        field:         rowData => !!rowData.tdpt_custom_part ? rowData.tdpt_custom_part : rowData.part.part_description,
                                        filter:        'select',
                                        filterMod:     {
                                            styleGrid: {
                                                width: '20%'
                                            }
                                        },
                                        truncate: true
                                    },
                                    {
                                        heading:       'Created Version',
                                        field:         rowData => rowData.tdpt_version ? `v${rowData.tdpt_version}` : '-',
                                        sizeToContent: true,
                                        alignment:     'center',
                                    },
                                    {
                                        heading:       'Current Version',
                                        field:         rowData => rowData.part ? ( parseInt(rowData.part.part_issue_status_design) > 0 ? `v${parseInt(rowData.part.part_issue_status_design)}` : '-' ) : '-',
                                        sizeToContent: true,
                                        alignment:     'center',
                                    },
                                    {
                                        heading:       'Assigned To',
                                        field:         rowData => rowData.tdpt_assigned || '-',
                                        dataRef:       'tdpt_assigned',
                                        sizeToContent: true,
                                        filter:        'select',
                                        filterMod:     {
                                            styleGrid: {
                                                width: '20%'
                                            }
                                        }
                                    },
                                    {
                                        heading:       'Printed',
                                        field:         'tdpt_qty_printed',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       'Out Of',
                                        field:         'tdpt_qty',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       'Left To Print',
                                        field:         rowData => Math.abs(rowData.tdpt_qty - rowData.tdpt_qty_printed),
                                        fieldPrefix:   rowData => rowData.tdpt_qty - rowData.tdpt_qty_printed == 0 ? '' : (rowData.tdpt_qty - rowData.tdpt_qty_printed < 0 ? '+' : '-'),
                                        sizeToContent: true,
                                        style:         rowData => ({color: rowData.tdpt_qty - rowData.tdpt_qty_printed == 0 ? null : (rowData.tdpt_qty - rowData.tdpt_qty_printed < 0 ? colors.green : colors.red) }), 
                                    },
                                    {
                                        heading:        'Last Update',
                                        field:          rowData => rowData.lastUpdate ? rowData.lastUpdate.staff : '-',
                                        sizeToContent:  true,
                                        alignment:      'left',
                                        info:           rowData => rowData.lastUpdate && 
                                                            <>
                                                                {clenyDate(rowData.lastUpdate.date)}<br/>
                                                                {rowData.lastUpdate.note && rowData.lastUpdate.note.split('\n').map((item, key) => {
                                                                        return <span key={key}>{item}<br/></span>
                                                                    })
                                                                }
                                                            </>,
                                        infoEnd:        'stretch',
                                        dataRef:        rowData => _.last(rowData.notes)?.tdptn_tracker || '-',
                                        filter:        'select',
                                        filterMod:     {
                                            styleGrid: {
                                                width: '20%'
                                            }
                                        }
                                    },
                                    {
                                        actions: rowData => [
                                            {
                                                name: 'STL',
                                                icon: icons.download,
                                                disabled: !rowData.part,
                                                onClick: (rowData)=>
                                                    ((rowData.tdpt_version && 
                                                        parseInt(rowData.tdpt_version ) != parseInt(rowData.part.part_issue_status_design)
                                                    ) && (_.find(rowData.part.stls, {part_3D_version: parseInt(rowData.tdpt_version)}))) ?
                                                    this.props.deployDialog(
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Button variant='outlined' fullWidth onClick={()=>this.downloadFile(_.find(rowData.part.stls, {part_3D_version: parseInt(rowData.tdpt_version)}).file_path)}>
                                                                    Created (v{rowData.tdpt_version})
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button variant='outlined' fullWidth onClick={()=>this.downloadFile(_.maxBy(rowData.part.stls, 'part_3D_version').file_pathfile_path)}>
                                                                    Current (v{rowData.part.part_issue_status_design})
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button variant='outlined' fullWidth onClick={this.props.closeDialog}>
                                                                    Close
                                                                </Button>
                                                            </Grid>
                                                        </Grid>,
                                                        'STL Versions',
                                                        'xs'
                                                    ) 
                                                    : this.downloadFile(_.maxBy(rowData.part.stls, 'part_3D_version').file_path)
                                            },
                                            {
                                                name: 'Process Document',
                                                icon: icons.file,
                                                disabled: !rowData.part,
                                                onClick: (rowData)=>
                                                    (rowData.tdpt_version && 
                                                        parseInt(rowData.tdpt_version ) != parseInt(rowData.part.part_issue_status_design)
                                                    ) ?
                                                    this.props.deployDialog(
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Button variant='outlined' fullWidth onClick={()=>this.downloadFile(_.find(rowData.part.pdf_files, {part_doc_version: parseInt(rowData.tdpt_version)}).file_path)}>
                                                                    Created (v{rowData.tdpt_version})
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button variant='outlined' fullWidth onClick={()=>this.downloadFile(rowData.part.pdf_drawing.file_path)}>
                                                                    Current (v{rowData.part.part_issue_status_design})
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button variant='outlined' fullWidth onClick={this.props.closeDialog}>
                                                                    Close
                                                                </Button>
                                                            </Grid>
                                                        </Grid>,
                                                        'Process Document Versions',
                                                        'xs'
                                                    ) 
                                                    : this.downloadFile(rowData.part.pdf_drawing.file_path)
                                            },
                                            {
                                                name: 'Edit',
                                                icon: rowData.tdpt_status == 'Completed' ? icons.search : icons.edit,
                                                onClick: () => {this.setState({showTracker: rowData.tdpt_id})}
                                            }
                                        ]
                                    }
                                ]}
                                rows={_.orderBy(trackers, ['tdpt_created_datetime'], ['desc'])}
                            />
                        </PaddedPaper>
                    }
                </Grid>
                {!!showTracker && tracker &&
                    <Dialog 
                        open={showTracker} 
                        onClose={()=>this.setState({showTracker: 0})} 
                        fullWidth={true} 
                        fullScreen
                    >
                        <DialogTitle style={{padding: 0, background: colors.blue}}>
                            {tracker.part ?
                                <Grid container xs={12} style={{alignItems: 'center', padding: '0.5em 1em'}}>
                                    <Grid item style={{paddingRight: 15}}>
                                        <img src={tracker.part?.default_image?.thumbnail_file_path || logo} alt='Not Found' style={{maxHeight:65, maxWidth:65}} />
                                    </Grid>
                                    <Grid item>
                                        <Typography style={{color: colors.white}} variant="h6">{tracker.part?.part_number}</Typography>
                                    </Grid>
                                    <Grid item style={{marginLeft: 'auto'}}>
                                        <AllIcon noMargin icon={'print'} color={colors.white} size='large' solid onClick={()=>{window.open(`https://eiger.io`, '_blank').focus();}} />
                                        <AllIcon noMargin icon={icons.search} color={colors.white} size='large' solid onClick={()=>{window.open(`/parts/view/${tracker.part?.part_id}`, '_blank').focus();}} />
                                    </Grid>
                                </Grid> : 
                                <Grid container xs={12} style={{alignItems: 'center', padding: '0.5em 1em'}}>
                                    <Grid item>
                                        <Typography style={{color: colors.white}} variant="h6">Custom Part</Typography>
                                        <Typography style={{color: colors.white}} variant="body1">{tracker.tdpt_custom_part}</Typography>
                                    </Grid>
                                    <Grid item style={{marginLeft: 'auto'}}>
                                        <AllIcon noMargin icon={'print'} color={colors.white} size='large' solid onClick={()=>{window.open(`https://eiger.io`, '_blank').focus();}} />
                                    </Grid>
                                </Grid>
                            }
                        </DialogTitle>
                        <DialogContent>
                            <Grid container xs={12} spacing={3} style={{height: '100%', paddingTop: '1em'}}>
                                <Grid item xs={5}>
                                    <Grid container xs={12} style={{height: '100%', alignItems: 'flex-start', alignContent: 'flex-start'}}>
                                        {tracker.tdpt_status != 'Completed' &&
                                            <Grid item xs={12} style={{marginBottom: '1.5em'}}>
                                                <Typography variant='h5'>Assigned</Typography> 
                                                <FormControl fullWidth>
                                                    <AutoCompleteSelect
                                                        label='Assigned To'
                                                        options={this.state.staffList}
                                                        value={tracker.tdpt_assigned_id}
                                                        onChange={this.updateAssigned}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item xs={12} style={{marginBottom: '1.5em'}}>
                                            <PaddedPaper>
                                                <Typography variant='h5'>Printed</Typography> 
                                                <Grid container spacing={3}>
                                                    {tracker.tdpt_status != 'Completed' &&
                                                        <>
                                                            <Grid item xs={10}>
                                                                <TextField
                                                                    value={formData.trackerQty}
                                                                    onChange={this.handleChange('trackerQty')}
                                                                    type='number'
                                                                    fullWidth
                                                                />
                                                            </Grid>   
                                                            <Grid item xs={2}>
                                                                <Button fullWidth variant='contained' color='primary' onClick={this.handleUpdateQty} disabled={!formData.trackerQty || formData.trackerQty < 1}>Add</Button>
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid container spacing={1} item xs={12} style={{alignItems: 'center'}}>
                                                        <Grid item >
                                                            <b>Required</b><br/>
                                                            {tracker.tdpt_qty}
                                                        </Grid>
                                                        <Grid item ><AllIcon size='large' icon={'slash-forward'} noMargin /></Grid>
                                                        <Grid item >
                                                            <b>Printed</b><br/>
                                                            {tracker.tdpt_qty_printed}
                                                        </Grid>
                                                        <Grid item ><AllIcon size='large' icon={'slash-forward'} noMargin /></Grid>
                                                        <Grid item >
                                                            <b>Remaining</b><br/>
                                                            <span style={{color: (tracker.tdpt_qty - tracker.tdpt_qty_printed) !== 0 && (tracker.tdpt_qty - tracker.tdpt_qty_printed) > 0 ? colors.red : colors.green}}>{(tracker.tdpt_qty - tracker.tdpt_qty_printed) !== 0 && (tracker.tdpt_qty - tracker.tdpt_qty_printed) > 0 ? '-' : '+'}{Math.abs(tracker.tdpt_qty - tracker.tdpt_qty_printed)}</span>
                                                        </Grid>
                                                        <Grid item style={{width: '60%', paddingLeft: '3%'}}>
                                                            <ProgressBar 
                                                                progress={parseInt(tracker.tdpt_qty_printed) + parseInt(formData.trackerQty || 0)} 
                                                                max={tracker.tdpt_qty} 
                                                                showProgressLabel 
                                                                progressType='percentage'
                                                                colorPrimary={colors.green}
                                                                colorSecondary={`${colors.red}40`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} style={tracker.prints.length > ( tracker.tdpt_status != 'Completed' ? 8 : 11 ) ? {
                                                            overflowY: 'scroll',
                                                            height: tracker.tdpt_status != 'Completed' ? '50vh' : '66vh'
                                                    }: {}}>
                                                        <DataTable
                                                            config={{
                                                                key: 'tdptp_id',
                                                                plainHeader: true,
                                                            }}
                                                            columns={[
                                                                {
                                                                    heading:        'Date',
                                                                    field:          rowData => clenyDate(rowData.tdptp_datetime),
                                                                    
                                                                },
                                                                {
                                                                    heading:        'Printed',
                                                                    field:          'tdptp_qty',
                                                                    sizeToContent:  true,
                                                                    alignment:      'center',
                                                                },
                                                                {
                                                                    heading:        'Remaining',
                                                                    field:          'remaining',
                                                                    fieldFormat:    'absolute',
                                                                    fieldPrefix:    rowData => rowData.remaining == 0 ? '' : (rowData.remaining > 0 ? '-' : '+'),
                                                                    style:          rowData => rowData.remaining == 0 ? {color: colors.black} : (rowData.remaining > 0 ? {color: colors.red} : {color: colors.green}),
                                                                    sizeToContent:  true,
                                                                    alignment:      'center',
                                                                }
                                                            ]}
                                                            rows={_.orderBy(_.map(tracker.prints, i => _.assign({
                                                                ...i,
                                                                remaining: parseFloat(tracker.tdpt_qty - 
                                                                    _.sumBy(
                                                                        _.filter(tracker.prints,
                                                                            f => f.tdptp_id < i.tdptp_id
                                                                        ),
                                                                        i => parseFloat(i.tdptp_qty)
                                                                    )
                                                                ) - i.tdptp_qty
                                                            })), ['tdptp_datetime'], ['desc'])}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant='h5'>Notes</Typography> 
                                    {tracker.tdpt_status != 'Completed' &&
                                        <>
                                            <Textarea
                                                value={this.state.note ? note : ''}
                                                onChange={e => this.setState({note: e.target.value})}
                                                label="Notes"
                                                margin='none'
                                                rows={4}
                                            />
                                            <div className='buttonRow'>
                                                <Button variant='contained' color='primary' onClick={()=>this.addNote(this.state.showTracker)}>Add Note</Button>
                                            </div>
                                        </>
                                    }
                                    <div style={{height: tracker.tdpt_status != 'Completed' ? 'calc(100vh - 425px)' : 'calc(100vh - 250px)', overflowY:'scroll', marginTop: '1.5em'}}>
                                        <List style={{padding: 0, paddingRight: 25}}>
                                            {_.map(_.orderBy(tracker.notes, ['tdptn_datetime'], 'desc'), note => 
                                                <ListItem style={{padding: 0, borderBottom: `1px solid ${colors.disabled}`}}>
                                                    <ListItemText 
                                                        primary={<em>{note.tdptn_tracker}</em>} 
                                                        secondary={
                                                            <>
                                                                <em>{clenyDate(note.tdptn_datetime, true)}</em><br/>
                                                                {note.tdptn_note.split('\n').map((item, key) => {
                                                                    return <span key={key}>{item}<br/></span>
                                                                })} 
                                                            </>
                                                        }
                                                    />
                                                    <Divider/>
                                                </ListItem>
                                            )}
                                        </List>
                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{borderTop: `1px solid ${colors.disabled}`}}>
                            {tracker.part && tracker.part?.stls?.length > 0 && (
                                <>
                                    {(!!tracker.tdpt_version && (parseInt(tracker.tdpt_version ) != parseInt(tracker.part.part_issue_status_design)) && (_.find(tracker.part.stls, {part_3D_version: parseInt(tracker.tdpt_version)})) &&
                                        <Button onClick={()=>this.downloadFile(_.find(tracker.part.stls, {part_3D_version: parseInt(tracker.tdpt_version)}).file_path)} variant="outlined" ><AllIcon icon={icons.download}/>STL FILE v{parseInt(tracker.tdpt_version)}</Button> 
                                    )}
                                    <Button onClick={()=>this.downloadFile(_.maxBy(tracker.part.stls, 'part_3D_version').file_path)} variant="outlined" ><AllIcon icon={icons.download}/>STL FILE { (_.find(tracker.part.stls, {part_3D_version: parseInt(tracker.tdpt_version)}) && ( parseInt(tracker.tdpt_version ) > 0 && (parseInt(tracker.tdpt_version ) != parseInt(tracker.part.part_issue_status_design)))) && `v${parseInt(tracker.part.part_issue_status_design)}`}</Button>
                                </>
                            )}
                            {tracker.part && (
                                <>
                                    {(!!tracker.tdpt_version && (parseInt(tracker.tdpt_version ) != parseInt(tracker.part.part_issue_status_design)) &&
                                        <Button onClick={()=>this.downloadFile(_.find(tracker.part.pdf_files, {part_doc_version: parseInt(tracker.tdpt_version)}).file_path)} variant="outlined" ><AllIcon icon={icons.download}/>Process Document v{parseInt(tracker.tdpt_version)}</Button> 
                                    )}
                                    <Button onClick={()=>this.downloadFile(tracker.part.pdf_drawing.file_path)} variant="outlined" ><AllIcon icon={icons.download}/>Process Document { ( parseInt(tracker.tdpt_version ) > 0 && (parseInt(tracker.tdpt_version ) != parseInt(tracker.part.part_issue_status_design)) ) && `v${parseInt(tracker.part.part_issue_status_design)}`}</Button>
                                </>
                            )}
                            {tracker.tdpt_status != 'Completed' && (tracker.tdpt_status == 'Created' ?
                                <Button onClick={()=>this.handleChangeStatus(this.state.showTracker, 'Printing')} variant="contained" style={{background: colors.orange, color: colors.white}}>Mark As Printing</Button> :
                                <Button onClick={()=>this.handleChangeStatus(this.state.showTracker, 'Completed')} variant="contained" style={{background: colors.green, color: colors.white}}>Mark As Complete</Button> )
                            }
                            <Button onClick={this.handleClose} variant="outlined" color="default">Close</Button>
                        </DialogActions>
                    </Dialog>
                }
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployDialog:           (content, title, size=null) => { dispatch(deployDialog(content, title, null, size))},
    deployConfirmation:     (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    closeDialog:            ()                        => {dispatch(closeDialog())}
})


export default connect(null,mapDispatchToProps)(ThreeDPrinterTracker);
