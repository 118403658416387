/**
 * int  customerId  -> id of customer to get address's from
 * str  addrType    -> value of the address type (Registered, Accounts etc.)
 * int  addrId      -> id of the selected address
 * int  contId      -> id of selected contact 
 * str  cname       -> default: company name
 * str addressName
 * str address
 * str contact
 * str email
 * str phone 
 * str mobile
 * 
 * 
 * func callback(data)
 */

import React, { Component } from 'react';
import _                    from 'lodash';
import API                  from 'API';
import { connect }          from 'react-redux';

import { SignalCellularNullRounded } from '@material-ui/icons';
import { FormControl, Typography, Grid, IconButton, Button } from '@material-ui/core';

import AutoCompleteSelect   from 'Components/Common/Selects/AutoCompleteSelect';
import AllIcon              from 'Components/Common/Icons/AllIcon';
import LoadingCircle        from 'Components/Common/LoadingCircle/LoadingCircle';

import icons    from 'Helpers/IconHelper';
import {colors} from 'Helpers/ColourHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = () => ({
    formData:    {
        type:       null,
        address:    null,
        contact:    null
    },
    display: {
        type:           null,
        addressId:      null,
        contactId:      null,
        addressName:    null,
        fullAddress:    null,
        contactName:    null,
        email:          null,
        phone:          null,
        active:         true
    },
    editMode:    false,
    addrList:    [],
    contactList: [],
    isLoading:   true,
    contact:     {},
    names:       {
        address: 'Address',
        contact: 'Contact',
    },
    changedAddress: false,
    addressChanged: false,  
})

const col1Size = 4;
const col2Size = 8;

class SelectCustomerAddressFormSwitcher extends Component {
    constructor(props) {
        super(props);
        this.state = {...initialState(), display: {
            type:           null,
            addressId:      null,
            contactId:      null,
            addressName:    this.props.addressName,
            fullAddress:    this.props.address,
            contactName:    this.props.contact,
            email:          this.props.email,
            phone:          this.props.phone && this.props.mobile ? `${this.props.phone} / ${this.props.mobile}` : ( this.props.mobile ? this.props.mobile : this.props.phone )
        }};
    }

    componentDidMount = () => {
        if(this.props.customerId) this.getCustomerAddress();
    }


    getAddressString = data => {
        let addr = '';
        if (data.address_line_one)      addr += data.address_line_one       + '\n';
        if (data.address_line_two)      addr += data.address_line_two       + '\n';
        if (data.address_line_three)    addr += data.address_line_three     + '\n';
        if (data.address_town)          addr += data.address_town           + '\n';
        if (data.county?.county_name)   addr += data.county.county_name     + '\n';
        if (data.address_postcode)      addr += data.address_postcode       + '\n';
        if (data.country?.country_name) addr += data.country.country_name;
        return addr;
    }

    getCustomerAddress = () => {
        API.get(`/customers/${this.props.customerId}`)
        .then(res => {
            if (res.data){
                let addrList = {}, contactList = {};
                
                const types = [
                    {key: 'Registered',   value:'registered_address'},
                    {key: 'Accounts',     value:'account_addresses'},
                    {key: 'Trading',      value:'trading_addresses'},
                    {key: 'Site',         value:'site_addresses'},
                ]
                let changed = true;
                for(var i = 0; types.length > i; i++){
                    const {key, value} = types[i];
                    let data = (Object.prototype.toString.call(res.data[value]) !== '[object Array]') ? [res.data[value]] : res.data[value];
                    if (data.length > 0){
                        addrList = {...addrList, [key]: data};
                        data.map(addr => {
                            if (this.getAddressString(addr) == this.props.address) {
                                changed = !addr.address_active;
                            }
                            contactList = {
                                ...contactList, 
                                [addr.address_id]: _.map(addr.active_contacts, i => ({
                                    ...i,
                                    primary:  addr?.primary_invoice_contact && addr?.primary_invoice_contact[0]?.contact_id == i.contact_id
                                }))
                            }
                        })
                    }
                }

                this.setState({addrList, contactList, isLoading: false, changedAddress: changed }, () => {
                    if (this.props.contId && this.props.addrId) {
                        this.setState({
                            contact: _.find(contactList[this.props.addrId],{contact_id: this.props.contId})
                        })
                    }
                });
            }
        });
    }

    handleChange = field => e => {
        let formData = this.state.formData;
        let contact = null;
        switch(field){
            case 'type':
                formData = {...initialState().formData, type: e ? e.value : null }
                break;
            case 'address':
                formData = {...formData, address: e ? e.value : null , contact: null }
                break;
            case 'contact':
                formData = {...formData, contact: e ? e.value : null  }
                contact  = e ? e.data : null; 
                break;
        }
        this.setState({ formData, contact });
    }

    handleToggleEdit = () => {this.setState({editMode: !this.state.editMode});}

    close = () => { this.setState({formData:initialState().formData}, this.handleToggleEdit);}

    submit = () => {
        const  { type, address, contact } = this.state.formData;
        let addr = _.find(this.state.addrList[type],        {'address_id': address});
        let cont = _.find(this.state.contactList[address],  {'contact_id': contact});

        let display = {
            ...this.state.formData,
            addressName:     addr.address_name,
            fullAddress:     this.getAddressString(addr),
            contactName:     cont.contact_name,
            email:           cont.contact_email,
            phone:           cont.contact_telephone && cont.contact_mobile ? `${cont.contact_telephone} / ${cont.contact_mobile}` : ( cont.contact_mobile ? cont.contact_mobile : cont.contact_telephone ),
            active:          addr.address_active,
            addressId:       address,
            customerAddress: address
        };

        this.setState({
            formData:initialState().formData,
            display, 
            changedAddress: false,
            addressChanged: true
        }, () => {
            this.handleToggleEdit()
            this.props.callback(this.state.display);
        } );
        
    }

    handleChangePrimary = () => {
        let {contactList , formData} = this.state;
        if (_.find(contactList[formData.address], {contact_id: formData.contact}).primary || !!!this.props.setPrimaryContact){
            this.submit();
        } else {
            this.props.deployConfirmation(
                'New invoice contact has been selected would you like to make them the primary invoice contact for this address?', 
                'Change Primary Contact?', 
                ()=>{
                    this.props.setPrimaryContact && this.props.setPrimaryContact();
                    this.submit();
                }, 
                this.submit
            );
        }
    }

    render(){

        const {addrList, contactList, formData, isLoading, names, editMode, display } = this.state;
        const {formErrors, name}                                                      = this.props;

        return (
            <Typography variant="body">
            {isLoading ? <LoadingCircle /> :
                <>
                    {editMode ? 
                        [<>
                            {this.props.header && 
                                <Grid container xs={12}>
                                    <Grid item xs={10}><Typography variant="h6">{this.props.header}</Typography></Grid>
                                </Grid>
                            }
                        </>,
                        <FormControl fullWidth margin="normal">
                            <AutoCompleteSelect 
                                options={[
                                    {value: 'Registered',   label: 'Registered'},
                                    {value: 'Accounts',     label: 'Accounts'},
                                    {value: 'Trading',      label: 'Trading'},
                                    {value: 'Site',         label: 'Site'}
                                ]} 
                                label='Address Type'
                                onChange={(e)=>{this.handleChange('type')(e)}}
                                value={formData.type}
                            />
                        </FormControl>,
                        <FormControl fullWidth margin="normal">
                            <AutoCompleteSelect 
                                options={_.map(_.filter(addrList[formData.type], {address_active: 1} ), i => {
                                    return _.assign({
                                        value:  i.address_id,
                                        label: `${i.address_name} - ${i.address_line_one}, ${i.address_town}, ${i.address_postcode} - ${i.address_id}`
                                    })
                                })}
                                label='Address *'                                                    
                                onChange={(e)=>{this.handleChange('address')(e)}}
                                value={formData.address}
                                name={`${name}${names.address}`}
                                error={formErrors && formErrors[`${name}${names.address}`] && true}
                                errorText={formErrors && formErrors[`${name}${names.address}`]}
                            />
                        </FormControl>,
                        <FormControl fullWidth margin="normal">
                            <AutoCompleteSelect 
                                options={_.map(_.filter(contactList[formData.address], i => {
                                    return i.contact_email
                                }), i => {
                                    return _.assign({
                                        value:  i.contact_id,
                                        label:  i.primary ? `${i.contact_name} (Primary Contact)` : i.contact_name,
                                        data:   i
                                    })
                                })}
                                label='Contact *'                                                    
                                onChange={(e)=>{this.handleChange('contact')(e)}}
                                value={formData.contact}
                                name={`${name}${names.contact}`}
                                error={formErrors && formErrors[`${name}${names.contact}`] && true}
                                errorText={formErrors && formErrors[`${name}${names.contact}`]}
                            />
                        </FormControl>,
                        <br />,<br />,
                        <Grid container item spacing={3}>
                            <Grid container item xs={12}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1">Phone Number</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {formData.address && formData.contact &&
                                        <Typography variant="subtitle1">
                                            {_.find(contactList[formData.address], {'contact_id' : formData.contact})?.contact_telephone && _.find(contactList[formData.address], {'contact_id' : formData.contact})?.contact_mobile ? 
                                                _.find(contactList[formData.address], {'contact_id' : formData.contact})?.contact_telephone + ' / ' + _.find(contactList[formData.address], {'contact_id' : formData.contact})?.contact_mobile
                                            : 
                                                (_.find(contactList[formData.address], {'contact_id' : formData.contact})?.contact_telephone ? 
                                                    _.find(contactList[formData.address], {'contact_id' : formData.contact})?.contact_telephone 
                                                : 
                                                    _.find(contactList[formData.address], {'contact_id' : formData.contact})?.contact_mobile )
                                            }
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1">
                                        Email Address *
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {formData.address && formData.contact &&
                                        <Typography variant="subtitle1">
                                            {_.find(contactList[formData.address], {'contact_id' : formData.contact})?.contact_email}
                                        </Typography>
                                    }
                                </Grid>
                                
                            </Grid>
                            <Grid container item xs={12}>
                                {!!this.props.systemGenerated && 
                                    <Grid item xs={12}>
                                        <Typography style={{color: colors.red}}>This address was system generated, based on its site, changing may link to an incorrect accounts address</Typography>
                                    </Grid>
                                }
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6}>
                                    <div className='buttonRow'>
                                        <Button variant="outlined" onClick={this.close}>Cancel</Button>
                                        <Button variant="contained" color='primary' 
                                            disabled={!(formData.type && formData.address && formData.contact)} 
                                            onClick={this.handleChangePrimary}
                                        >Update</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>] : 
                        <form noValidate autoComplete="off">
                            <Grid item xs={12}>
                                <Grid container xs={12} justifyContent='space-between' style={{justifyContent: 'space-between'}}>
                                    <Grid item xs={10}>
                                        <Typography variant="h5" gutterBottom>{this.props.header}</Typography>
                                    </Grid>
                                    <Grid item>
                                        {!!this.props.systemGenerated && 
                                            <AllIcon icon={icons.robot} button noMargin tooltip='Address Was System Generated'/>
                                        }
                                        <IconButton onClick={this.handleToggleEdit}><AllIcon icon='pencil' button noMargin/></IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item lg={col1Size}>
                                    <Typography variant="subtitle1">
                                        {this.props.cname} Name
                                    </Typography>
                                </Grid>
                                <Grid item lg={col2Size}>
                                    {display.addressName &&
                                        <Typography variant="subtitle1">
                                            {display.addressName}
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item lg={col1Size}>
                                    <Typography variant="subtitle1" >
                                        Address
                                    </Typography>
                                </Grid>
                                <Grid item lg={col2Size}>
                                    {display.fullAddress.split('\n').map(i=><>{i}<br/></>)}
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item lg={col1Size}>
                                    <Typography variant="subtitle1" >
                                        Contact
                                    </Typography>
                                </Grid>
                                <Grid item lg={col2Size}>
                                    <Typography variant="subtitle1">
                                        {display.contactName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item lg={col1Size}>
                                    <Typography variant="subtitle1" >
                                        Email Address
                                    </Typography>
                                </Grid>
                                <Grid item lg={col2Size}>
                                    <Typography variant="subtitle1">
                                        {display.email}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item lg={col1Size}>
                                    <Typography variant="subtitle1" >
                                        Phone Number
                                    </Typography>
                                </Grid>
                                <Grid item lg={col2Size}>
                                    <Typography variant="subtitle1">
                                        {display.phone}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {this.state.changedAddress && 
                                <Grid container item spacing={3}  xs={12}>
                                    <Typography variant="subtitle1" style={{marginTop: '1.5em', color: colors.red, float: 'left' }} >
                                        <AllIcon icon='exclamation-triangle' style={{color: colors.red}} /> The original address has been changed, deleted or made inactive
                                    </Typography>
                                </Grid>
                            }
                            {this.state.addressChanged &&
                                <Grid container item spacing={3}  xs={12}>
                                    <Typography variant="subtitle1" style={{marginTop: '1.5em',  float: 'left' }} >
                                        <AllIcon icon='pencil' /> You have edited this address
                                    </Typography>
                                </Grid>
                            }
                        </form>
                    }
                </>
            }
        </Typography>
    )}
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success, close, variant='YesNo') => dispatch(deployConfirmation(message, title, success, close, variant)),
    }
}

export default connect(null, mapDispatchToProps)(SelectCustomerAddressFormSwitcher);