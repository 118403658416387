import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import DataTable from '../../Common/DataTables/DataTable';
import API from '../../../API';

const initialState = {
    rows: [],
    isLoading: true
}

class PartTypes extends Component{
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getPartNumberingList();
    }

    getPartNumberingList = (e) => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/parts/numbering/all')
            .then((result) => {
                this.setState({
                    ...this.state,
                    rows: result.data,
                    isLoading: false
                })
            });
        });
    }

    render(){
        return (
            <Grid item xs={12}>
                <DataTable  
                    config={{
                        key: 'part_id',
                        alternatingRowColours: true,
                        isLoading: this.state.isLoading
                    }}
                    columns={[
                        {
                            heading: 'Part Type',
                            field: rowData => rowData.part_type,
                            sizeToContent: true
                        },
                        {
                            heading: 'Build Type',
                            field: rowData => rowData.build_type,
                            sizeToContent: true
                        },
                        {
                            heading: 'Last Used',
                            field: rowData => rowData.last_used,
                            sizeToContent: true
                        },
                        {
                            heading: 'Next Used',
                            field: rowData => rowData.next_used
                        }
                    ]}
                    rows={this.state.rows}
                />
            </Grid>
        );
    }
}

export default PartTypes;