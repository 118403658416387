import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import SnackBar from './../../Common/SnackBars/SnackBar'
import Button from '@material-ui/core/Button';
import API from '../../../API';
import CustomerPaymentTermsList from './CustomerPaymentTermsList';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { withStyles } from '@material-ui/core/styles';

import { deployDialog } from 'Actions/Dialog/Dialog';
import CustomerPaymentTermsAmendDialog from 'Components/Accounts/CustomerPaymentTerms/CustomerPaymentTermsAmendDialog';

const initialState = {
    formData: {
        name: '',
        upfrontPayment: null,
        allowEpdq: false,
        allowStripe: false,
        allowPaypal: false,
        allowCheque: false,
        allowBankTransfer: false,
        allowAccount: false,
        allowDirectDebit: false,
        allowCash: false
    },
    formErrors: {},
    paymentTerms: {},
    list: {
        paymentTermMethods: {}
    },
    access: {
        addPaymentTerm: false
    },
    isLoading: true,
    snackbarOpen: false,
    confirmationOpen: false
}

const styles = theme => ({
    settingsHeading: {
        paddingTop: theme.spacing(2)
    }
});
class CustomerPaymentTerms extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount(){
        this.checkAccess();
        this.getPaymentTerms();
    }
    getPaymentTerms = () => {
        Promise.all([
            API.get('/customers/paymentTerms'),
            API.get('/customers/paymentTermMethods')
        ])
        .then(([termRes, methodRes]) => {
            this.setState({
                paymentTerms: termRes && termRes.data,
                list: {
                    paymentTermMethods: methodRes && methodRes.data,
                },
                isLoading: false
            });
        });
    };
    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/customer-payment-terms:add-payment-term'), 
        ])
        .then(([nameRes]) =>  {
            nameRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addPaymentTerm: nameRes.data.has_access
                    }
                });
        });
    }
    /*
     * Add Payment Terms
     */
    addPaymentTerm = () => {
        API.post('/customers/paymentTerms', this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.checkAccess();
                this.getPaymentTerms();
            }
        });
    }
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                upfrontPayment: selectedOption && selectedOption.value
            }
        });
    }
    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    };
    handleCheck = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: JSON.parse(e.target.value)
            }
        });
    }
    generatePaymentMethodCheckbox = el => {
        let name = '';
        let label = '';

        switch(el.cpm_name) {
            case 'BarclayCard EPDQ':
                name = 'allowEpdq';
                label = 'Allow BarclayCard EPDQ';
            break;
            case 'Stripe':
                name = 'allowStripe';
                label = 'Allow Stripe';
            break;
            case 'PayPal':
                name = 'allowPaypal';
                label = 'Allow PayPal';
            break;
            case 'Cheque':
                name = 'allowCheque';
                label = 'Allow Cheque'
            break;
            case 'Bank Transfer':
                name = 'allowBankTransfer';
                label = 'Allow Bank Transfer';
            break;
            case 'Account':
                name = 'allowAccount';
                label = 'Allow Account';
            break;
            case 'Direct Debit':
                name = 'allowDirectDebit';
                label = 'Allow Direct Debit';
            break;
            case 'Cash':
                name = 'allowCash';
                label = 'Allow Cash';
            break;
            default:
                throw new Error('unhandled payment method');
        }

        return(
            <FormGroup row key={el.cpm_id}>
                <FormControlLabel
                    control={
                        <Checkbox 
                            id={name}
                            name={name}
                            onChange={this.handleCheck}
                            checked={this.state.formData[name] && true} 
                            value={!this.state.formData[name]}
                            color="primary"
                        />
                    }
                    label={label}
                />
            </FormGroup>
        );
    }
    handleAmend = rowData => {
        let dialog = <CustomerPaymentTermsAmendDialog 
            rowData={rowData}
            paymentMethodsList={this.state.list.paymentTermMethods}
            callback={this.getPaymentTerms}
        />;
        this.props.deployDialog(dialog, 'Amend Payment Term Methods', '', '')
    }
    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };
    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true
        });
    };
    handleConfirmationClose = () => {
        this.setState({
            confirmationOpen: false
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: false
        });
        this.addPaymentTerm();
    };
    render() {
        const { access, paymentTerms, formData, formData:{upfrontPayment}, formErrors, snackbarOpen, confirmationOpen, isLoading } = this.state;
        const { classes } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Payment Terms
                    </Typography>
                </Grid>
                {isLoading ? (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                ) : (
                    <React.Fragment>
                        {access.addPaymentTerm && (
                            <Grid item xs={12}>
                                <Grid container item spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <PaddedPaper>
                                            <Typography variant="h6">
                                                Add Payment Term
                                            </Typography>
                                            <form noValidate autoComplete="off">
                                                <TextField
                                                    id="name"
                                                    name="name"
                                                    label="Name *"
                                                    value={formData.name}
                                                    onChange={this.handleChange}
                                                    error={formErrors && formErrors['name'] && true}
                                                    helperText={formErrors && formErrors['name']}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                                
                                                <FormControl fullWidth margin="normal">
                                                    <AutoCompleteSelect 
                                                        options={[
                                                            {'value': true, 'label': 'Upfront Payment'},
                                                            {'value': false, 'label': 'Credit'}
                                                        ]}
                                                        label='Payment Settings *'
                                                        onChange={this.handleSelectChange('paymentSettings')}
                                                        value={upfrontPayment}
                                                    />
                                                </FormControl>
                                                <br></br><br></br>
                                                {upfrontPayment !== null && 
                                                    <React.Fragment>
                                                        <Typography variant="subtitle1" className={classes.settingsHeading}>Payment Methods:</Typography>
                                                        {this.state.list.paymentTermMethods.map(el => { 
                                                            if(Boolean(el.cpm_upfront_payment) === upfrontPayment) {
                                                                return this.generatePaymentMethodCheckbox(el);
                                                            } else {
                                                                return '';
                                                            }
                                                        })}
                                                    </React.Fragment>
                                                }

                                                <div className='buttonRow'>
                                                    <Button 
                                                        onClick={this.handleConfirmationOpen}
                                                        variant="contained" 
                                                        color="primary" 
                                                        disabled={!this.state.formData.name || this.state.formData.upfrontPayment == null}
                                                    >
                                                        Add
                                                    </Button>
                                                </div>

                                                <ConfirmationDialog 
                                                    open={confirmationOpen} 
                                                    success={this.handleConfirmationSuccess} 
                                                    close={this.handleConfirmationClose} 
                                                    title="Add A New Payment Term?" 
                                                    message="Are you sure you want to add a new payment term?"
                                                />
                                                <SnackBar
                                                    variant="success"
                                                    anchorOriginVertical='bottom'
                                                    anchorOriginHorizontal='right'
                                                    open={snackbarOpen}
                                                    onClose={this.handleSnackbarClose}
                                                    message="You have successfully added a new payment term"
                                                />
                                            </form>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <CustomerPaymentTermsList paymentTerms={paymentTerms} isLoading={isLoading} handleAmend={this.handleAmend} />
                            </PaddedPaper>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, variant, size) => {
            dispatch(deployDialog(content, title, variant, size))
        }
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(CustomerPaymentTerms));