import React                            from 'react';
import PropTypes                        from 'prop-types';
import { Redirect }                     from 'react-router';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect }                      from 'react-redux';
import API                              from 'API';
import { FORCE_WAREHOUSE }              from 'Constants'

import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';

import AdminLayout     from './AdminLayout/AdminLayout';
import WarehouseLayout from './WarehouseLayout/WarehouseLayout';
import Login           from '../Login/Login';

import MessagePopout   from 'Components/Messages/MessagePopout';

import { loggedIn } from 'Functions/AuthFunctions';

const styles = theme => ({
    "@global": {
        html: {
            [theme.breakpoints.down("xs")]: {
                fontSize: '0.8em',
            }
        },
        '.buttonRow': {
            marginTop: theme.spacing(2),
            display: 'flex',
            justifyContent: 'flex-end',
        },
        '.buttonRow > .MuiButton-root': {
            marginLeft: theme.spacing(1),
            textDecoration: 'none'
        },
        '.buttonRow > a': {
            marginLeft: theme.spacing(1),
            textDecoration: 'none'
        },
        '.linkStyle': {
            textDecoration:'none'
        },
        '.blueLink': {
            textDecoration: 'none',
            color: '#0282C6',
            cursor: 'pointer'
        },
        '.redLink': {
            textDecoration: 'none',
            color: '#d9534f',
            cursor: 'pointer'
        },
        '.padding3': {
            padding: theme.spacing(3)
        },
        '.paddingTop0': {
            paddingTop: 0
        },
        '.paddingBottom0': {
            paddingBottom: 0
        },
        '.paddingRight0': {
            paddingRight: 0
        },
        '.paddingLeft0': {
            paddingLeft: 0
        }
    },
    root: {
        display: 'flex'
    }
});

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.load = false;
        this.notifications = false;
    }

    componentDidMount() {
        this.getNotifications();
        this.notifications = setInterval(this.getNotifications, 5000);
    }

    componentWillUnmount = () => {
        if(this.load)
            clearTimeout(this.load);
        if(this.notifications)
            clearInterval(this.notifications);
    }

    getNotifications = () => {
        API.get('/notifications',
        {
            params: {
                ln: this.props.ln,
            },
            props: {
                noLoading: true,
                handleNotifications: true,
                ignoreErrors: true,
            }
        })
    }

    render() {        
        const { classes } = this.props;
        return (
            <BrowserRouter>
                <React.Fragment>
                    <CssBaseline />
                    <div className={classes.root}>
                        <Switch>
                            <Route 
                                exact 
                                path="/" 
                                render={
                                    () => (
                                        loggedIn() ? (
                                            <Redirect 
                                                to={{
                                                    pathname: "/dashboard",
                                                    hash: window.location.hash,
                                                    search: window.location.search
                                                }} 
                                            />
                                        ) : (
                                            <Redirect to="/login" />
                                        )
                                    )
                                }
                            />
                            <Route 
                                exact 
                                path="/login"
                                render={
                                    props => (
                                        loggedIn() ? (
                                            <Redirect to="/dashboard" />
                                        ) : (
                                            <Login {...props} /> 
                                        )
                                    )
                                }
                            />
                            <Route
                                exact
                                path="/messaging"
                                render={
                                    props => (
                                        (loggedIn() && (
                                            <MessagePopout {...props} />
                                        )) || (                                            
                                            <Redirect to="/login" />
                                        )
                                    )
                                }
                            />
                            <Route 
                                render={
                                    props => (
                                        loggedIn() ? (
                                            (this.props.ui.mode === 'Warehouse' || FORCE_WAREHOUSE ?
                                                <WarehouseLayout {...props} /> :
                                                <AdminLayout {...props} />
                                            )
                                        ) : (                                            
                                            <Redirect to="/login" />
                                        )
                                    )
                                }
                            />
                        </Switch>
                    </div>
                </React.Fragment>
            </BrowserRouter>
        );
    }
}

Layout.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state){
    return {
        ln: state.notifications.ln,
        ui: state.ui,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Layout));