import React from 'react';
import _ from 'lodash';
import API from 'API';
import Paper from 'Components/Common/Paper/PaddedPaper';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import DataTable from 'Components/Common/DataTables/DataTable';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import BackButton from 'Components/Common/Buttons/BackButton';

const styles = theme => ({
    image: {
        textAlign: 'center',
        maxWidth: '100%',
    },
    label: {
        color: 'black'
    }
});

const initialState = {
    product: {},
    isLoading: true
}

class ViewEcommerceProduct extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.loadInitialData(this.props.match.params.id);
    }

    loadInitialData = (ecomId) => {
        Promise.all([ 
            API.get(`/e-commerce/products/${ecomId}`)
        ])
        .then(([productRes]) =>  {
            this.setState({
                product : productRes.data,
                isLoading: false,
            });
        });
    };

    downloadFile = url => {
        window.location.assign(atob(url));
    }

    render() {
        const { product, isLoading } = this.state;
        const { classes } = this.props;

        return (
            <Grid container spacing={3}>
            {isLoading ? (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            ) : (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            E-Commerce Product Details
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper>
                            <Grid container item spacing={3}>
                                <Grid item xs={4} align="center">
                                    <ImageWithError src={product.part.default_image?.thumbnail_file_path} className={classes.image} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="h6" className='clenawareBlue'>
                                    {_.map(product.categories, 'ecom_cat_name').join(', ')}
                                </Typography>
                                    <Typography variant="h6">{product.part.part_description}</Typography>
                                    <Typography variant="body1">{product.part.part_number}</Typography>
                                    <br />
                                    <Typography variant="h6" className='clenawareBlue'>
                                        {product.part.default_price ?
                                            <>£{product.part.default_price.price_total}
                                            <span style={{fontSize:'12px', verticalAlign:'super', marginLeft:'5px'}}>ex. VAT</span></>
                                        :
                                            '- No Price -'
                                        }
                                    </Typography>
                                    <Typography variant="h6" className='clenawareBlue'>
                                        {product.part.default_price ?
                                            <><b>£{product.part.default_price.price_inc_vat}
                                            <span style={{fontSize:'12px', verticalAlign:'super', marginLeft:'5px'}}>inc. VAT</span></b></>
                                        :
                                            ''
                                        }
                                    </Typography>
                                    <div className='buttonRow'>
                                    <BackButton props={this.props} />
                                    </div>
                                </Grid>
                            </Grid>    
                        </Paper>
                    </Grid>

                    <Grid item xs={12} lg={9}>
                        <Paper>
                            <Typography variant="h6">
                                Technical Library Documents
                            </Typography>
                            <br></br>
                            {Object.keys(product.technicalLibrary).length>0 ?
                                Object.keys(product.technicalLibrary).map((cat, idx) => {
                                    return (
                                        <>
                                        <Typography variant="body1">
                                            <b>{cat}</b>
                                        </Typography>
                                        <DataTable  
                                            config={{
                                                key: 'doc_id',
                                                plainHeader: true,
                                                withBorder: true,
                                                alternatingRowColours: true,
                                                responsiveImportance: true,
                                                isLoading: this.state.isLoading
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Document Name',
                                                    field: rowData => rowData.doc_name,
                                                    important: true,
                                                    truncate: true
                                                },
                                                {
                                                    heading: 'Version',
                                                    field: rowData => rowData.version ? 'v'+rowData.version : '-',
                                                    sizeToContent: true,
                                                    alignment: 'center'
                                                },
                                                {
                                                    actions: rowData => {
                                                        return [
                                                            {name: 'Download PDF', icon: 'arrow-to-bottom', onClick: () => { this.downloadFile(rowData.url) }, disabled: (!rowData.url)}
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={product.technicalLibrary[cat]}
                                        />
                                        <br />
                                    </>
                                    )
                                })
                            :
                                '0 Technical Documents'
                            }
                            
                        </Paper>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
        )
    }

}

export default withStyles(styles)(ViewEcommerceProduct);