import React from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

export default function (props) {
    return(
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox 
                        style={{
                            color: props.white && 'white',
                        }}
                        {...props}

                    />
                }
                label={props.label}
                style={{
                    color: props.white && 'white',
                }}
            />
        </FormGroup>
    );
}