import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Table, TableCell, TableHead, TableRow, Typography, Button } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import API from "API";
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import BarChart from "Components/Common/Charts/BarChart";
import LineChart from "Components/Common/Charts/LineChart";
import PieChart from "Components/Common/Charts/PieChart";
import CiDataTable from "Components/Common/DataTables/CiDataTable";
import AllIcon from "Components/Common/Icons/AllIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import InfoBox from "Components/Common/reports/InfoBox";
import { colors } from "Helpers/ColourHelper";
import IconHelper from "Helpers/IconHelper";
import { clenyDate } from "Helpers/TimeHelper";
import _ from "lodash";
import { i } from "mathjs";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";

const initialState = (props) => ({
    isLoading: true,
    campaign: null,
    campaigns: [],
    perRunFiler:{},
    runFiler: 'Contacts',
    subLoading: false,
    overviewData: {
        contacts: 0,
        sent: 0,
        opened: 0,
        unread: 0,
        bounced: 0,
        complaints: 0,
        unsubscribed: 0,
        lineChart: [],
        splitTable: {}
    }
})

const splitTableBorderRight = [ 'Contacts', 'Failed', 'Complaints', 'Unread' ];
class CampaignsTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getCampaigns();  
    }

    getCampaigns = () => {
        API.get('/reports/marketing/campaigns')
        .then(res => {
            this.setState({
                campaigns: res.data,
                isLoading: false,
            }, ()=> {
                this.props.campaignId && this.selectCampaign( this.props.campaignId)
            })
        })
    }

    selectCampaign = (value) => {
        this.setState({
            campaign: null,
            subLoading: true,
        }, ()=>{
            API.get(`/marketing/campaigns/emailCampaign/${value}`)
            .then(res => {
                let perRunFiler = {};
                _.each(res.data.runs, i => {
                    perRunFiler[i.ecr_run_count] = 'Contacts'
                })

                let contacts = [];
                let sent=0, opened=0, unread=0, bounced=0, unsubscribed=0, failed=0, complaints=0;

                _.each(res.data.runs, i => {
                    contacts = [...contacts, ..._.uniqBy(i.responses, 'ecrr_contact_id')]
                    sent += _.filter(i.responses, j => !!j.email).length;
                    opened += _.filter(i.responses, j => j?.email && j?.email?.email_datetime_opened !== '0000-00-00 00:00:00').length;
                    unread += _.filter(i.responses, j => j?.email && j?.email?.email_status === 'Sent').length;
                    bounced += _.filter(i.responses, j => j?.email && j?.email?.email_status === 'Bounced').length;
                    complaints += _.filter(i.responses, j => j?.email && j?.email?.email_status === 'Complaint').length;
                    unsubscribed += _.filter(i.responses, j => j?.email && j?.email?.email_datetime_optOut).length;
                    failed += _.filter(i.responses, j => !j.email).length;
                })

                let lineChart = {};

                _.each(res.data.runs, run => {
                    let runDate = moment(run.ecr_datetime).format('YYYY-MM-DD');
                    _.each(run.responses, res => {
                        if (res.email){
                            if (lineChart[runDate]){
                                lineChart[runDate].value.Sent += 1;
                            } else {
                                lineChart[runDate] = {
                                    label:  clenyDate(moment(runDate)),
                                    date:   runDate,
                                    value: {
                                        Sent: 1,
                                        Opened: 0,
                                        Unsubscribed: 0,
                                        Bounced: 0,
                                        Complaints: 0,
                                    },
                                    color: {
                                        Sent: colors.primary,
                                        Opened: colors.green,
                                        Unsubscribed: colors.red,
                                        Bounced: colors.orange,
                                        Complaints: colors.stickyNotes
                                    }
                                }
                            }
                        }
                        if (res?.email?.email_status === 'Bounced'){
                            let bounceDate = moment(res?.email?.email_datetime_bounced).format('YYYY-MM-DD');
                            if (lineChart[bounceDate]){
                                lineChart[bounceDate].value.Bounced += 1;
                            } else {
                                lineChart[bounceDate] = {
                                    label:  clenyDate(moment(bounceDate)),
                                    date:   bounceDate,
                                    value: {
                                        Sent: 0,
                                        Opened: 0,
                                        Unsubscribed: 0,
                                        Bounced: 1,
                                        Complaints: 0,
                                    },
                                    color: {
                                        Sent: colors.primary,
                                        Opened: colors.green,
                                        Unsubscribed: colors.red,
                                        Bounced: colors.orange,
                                        Complaints: colors.stickyNotes
                                    }
                                }
                            }
                        } else {
                            if (res?.email?.email_datetime_complaint){
                                let complaintDate = moment(res?.email?.email_datetime_complaint).format('YYYY-MM-DD');
                                if (lineChart[complaintDate]){
                                    lineChart[complaintDate].value.Complaints += 1;
                                } else {
                                    lineChart[complaintDate] = {
                                        label:  clenyDate(moment(complaintDate)),
                                        date:   complaintDate,
                                        value: {
                                            Sent: 0,
                                            Opened: 0,
                                            Unsubscribed: 0,
                                            Bounced: 0,
                                            Complaints: 1,
                                        },
                                        color: {
                                            Sent: colors.primary,
                                            Opened: colors.green,
                                            Unsubscribed: colors.red,
                                            Bounced: colors.orange,
                                            Complaints: colors.stickyNotes
                                        }
                                    }
                                }
                            }
                            if (res?.email?.email_datetime_optOut){
                                let optOutDate = moment(res?.email?.email_datetime_optOut).format('YYYY-MM-DD');
                                if (lineChart[optOutDate]){
                                    lineChart[optOutDate].value.Unsubscribed += 1;
                                } else {
                                    lineChart[optOutDate] = {
                                        label:  clenyDate(moment(optOutDate)),
                                        date:   optOutDate,
                                        value: {
                                            Sent: 0,
                                            Opened: 0,
                                            Unsubscribed: 1,
                                            Bounced: 0,
                                            Complaints: 0,
                                        },
                                        color: {
                                            Sent: colors.primary,
                                            Opened: colors.green,
                                            Unsubscribed: colors.red,
                                            Bounced: colors.orange,
                                            Complaints: colors.stickyNotes
                                        }
                                    }
                                }
                            }
                            if (res?.email?.email_datetime_opened !== '0000-00-00 00:00:00') {
                                let openDate = moment(res?.email?.email_datetime_opened).format('YYYY-MM-DD');
                                if (lineChart[openDate]){
                                    lineChart[openDate].value.Opened += 1;
                                } else {
                                    lineChart[openDate] = {
                                        label:  clenyDate(moment(openDate)),
                                        date:   openDate,
                                        value: {
                                            Sent: 0,
                                            Opened: 1,
                                            Unsubscribed: 0,
                                            Bounced: 0,
                                            Complaints: 0,
                                        },
                                        color: {
                                            Sent: colors.primary,
                                            Opened: colors.green,
                                            Unsubscribed: colors.red,
                                            Bounced: colors.orange,
                                            Complaints: colors.stickyNotes
                                        }
                                    }
                                }
                            }
                        }
                    })
                });

                let splitTable = {
                    Contacts: [sent + failed, ((sent + failed) > 0 ? (sent + failed) / res.data.runs.length : 0),100],
                    Sent: [sent, (sent > 0 ? sent / res.data.runs.length : 0),((sent / (sent + failed)) * 100)],
                    Failed: [failed, (failed > 0 ? failed / res.data.runs.length : 0), ((failed / (sent + failed)) * 100)],
                    Bounced: [bounced, (bounced > 0 ? bounced / res.data.runs.length : 0), ((bounced / sent) * 100)],
                    Complaints: [complaints, (complaints > 0 ? complaints / res.data.runs.length : 0), ((complaints / sent) * 100)],
                    Opened: [opened, (opened > 0 ? opened / res.data.runs.length : 0),((opened / sent) * 100)],
                    Unread: [unread, (unread > 0 ? unread / res.data.runs.length : 0),((unread / sent) * 100)],
                    Unsubscribed: [unsubscribed, (unsubscribed > 0 ? unsubscribed / res.data.runs.length : 0), ((unsubscribed / sent) * 100)],
                }

                this.setState({
                    campaign: res.data,
                    perRunFiler: perRunFiler,
                    overviewData: {
                        contacts: sent + failed,
                        sent,
                        opened,
                        unread,
                        bounced,
                        complaints,
                        unsubscribed,
                        failed,
                        lineChart: _.orderBy(_.values(lineChart), 'date', 'asc'),
                        splitTable
                    },
                    subLoading: false,
                })
            })
        })
    }

    handleSetRunFilter = runNumber => filter => () => {
        let perRunFiler = this.state.perRunFiler;
        perRunFiler[runNumber] = filter;
        this.setState({perRunFiler})
    }

    tableRunFilter = run => row => {
        switch(this.state.perRunFiler[run]) {
            case 'Contacts':
                return true;
            case 'Sent':
                return !!row.email;
            case 'Failed':
                return !row.email;
            case 'Opened':
                return row?.email && row?.email?.email_datetime_opened !== '0000-00-00 00:00:00';
            case 'Unread':
                return row?.email && row?.email?.email_status === 'Sent';
            case 'Bounced':
                return row?.email && row?.email?.email_status === 'Bounced';
            case 'Complaint':
                return row?.email && row?.email?.email_status === 'Complaint';
            case 'Unsubscribed':
                return row?.email && row?.email?.email_datetime_optOut;
            case 'Order':
                return _.find(this.state.campaign.promotion?.orders, o => _.find(row.addresses, {address_id: o.order_delivery_cust_address_id}) || o.order_delivery_cust_contact_email === row.contact?.contact_email || o.order_cust_contact_email === row.contact?.contact_email );
            default:
                return true;
        }
    }

    handleSetFilter = filter => () => {
        this.setState({
            runFiler: filter,
            perRunFiler: _.mapValues(this.state.perRunFiler, i => filter)
        })
    }

    getRunLineData = run => {
        let lineChart = {};
        let runDate = moment(run.ecr_datetime).format('YYYY-MM-DD');
        _.each(run.responses, res => {
            if (res.email){
                if (lineChart[runDate]){
                    lineChart[runDate].value.Sent += 1;
                } else {
                    lineChart[runDate] = {
                        label:  clenyDate(moment(runDate)),
                        date:   runDate,
                        value: {
                            Sent: 1,
                            Opened: 0,
                            Unsub: 0,
                            Bounced: 0,
                            Complaints: 0,
                        },
                        color: {
                            Sent: colors.primary,
                            Opened: colors.green,
                            Unsub: colors.red,
                            Bounced: colors.orange,
                            Complaints: colors.stickyNotes
                        }
                    }
                }
            }
            if (res?.email?.email_status === 'Bounced'){
                let bounceDate = moment(res?.email?.email_datetime_bounced).format('YYYY-MM-DD');
                if (lineChart[bounceDate]){
                    lineChart[bounceDate].value.Bounced += 1;
                } else {
                    lineChart[bounceDate] = {
                        label:  clenyDate(moment(bounceDate)),
                        date:   bounceDate,
                        value: {
                            Sent: 0,
                            Opened: 0,
                            Unsub: 0,
                            Bounced: 1,
                            Complaints: 0,
                        },
                        color: {
                            Sent: colors.primary,
                            Opened: colors.green,
                            Unsubscribed: colors.red,
                            Bounced: colors.orange,
                            Complaints: colors.stickyNotes
                        }
                    }
                }
            } else {
                if (res?.email?.email_datetime_complaint){
                    let optOutDate = moment(res?.email?.email_datetime_complaint).format('YYYY-MM-DD');
                    if (lineChart[optOutDate]){
                        lineChart[optOutDate].value.Complaints += 1;
                    } else {
                        lineChart[optOutDate] = {
                            label:  clenyDate(moment(optOutDate)),
                            date:   optOutDate,
                            value: {
                                Sent: 0,
                                Opened: 0,
                                Unsub: 0,
                                Bounced: 0,
                                Complaints: 1,
                            },
                            color: {
                                Sent: colors.primary,
                                Opened: colors.green,
                                Unsubscribed: colors.red,
                                Bounced: colors.orange,
                                Complaints: colors.stickyNotes
                            }
                        }
                    }
                }
                if (res?.email?.email_datetime_optOut){
                    let optOutDate = moment(res?.email?.email_datetime_optOut).format('YYYY-MM-DD');
                    if (lineChart[optOutDate]){
                        lineChart[optOutDate].value.Unsub += 1;
                    } else {
                        lineChart[optOutDate] = {
                            label:  clenyDate(moment(optOutDate)),
                            date:   optOutDate,
                            value: {
                                Sent: 0,
                                Opened: 0,
                                Unsub: 1,
                                Bounced: 0,
                                Complaints: 0,
                            },
                            color: {
                                Sent: colors.primary,
                                Opened: colors.green,
                                Unsubscribed: colors.red,
                                Bounced: colors.orange,
                                Complaints: colors.stickyNotes
                            }
                        }
                    }
                }
                if (res?.email?.email_datetime_opened !== '0000-00-00 00:00:00') {
                    let openDate = moment(res?.email?.email_datetime_opened).format('YYYY-MM-DD');
                    if (lineChart[openDate]){
                        lineChart[openDate].value.Opened += 1;
                    } else {
                        lineChart[openDate] = {
                            label:  clenyDate(moment(openDate)),
                            date:   openDate,
                            value: {
                                Sent: 0,
                                Opened: 1,
                                Unsub: 0,
                                Bounced: 0,
                                Complaints: 0,
                            },
                            color: {
                                Sent: colors.primary,
                                Opened: colors.green,
                                Unsubscribed: colors.red,
                                Bounced: colors.orange,
                                Complaints: colors.stickyNotes
                            }
                        }
                    }
                }
            }
        });
        return _.values(lineChart);
    }

    filterOrdersToRun = (run) => {
        let orders = this.state.campaign.promotion?.orders;
        let runs = this.state.campaign.runs;
        let runDate = moment(run.ecr_datetime).format('YYYY-MM-DD');
        let runIndex = _.findIndex(runs, {ecr_id: run.ecr_id});

        //set effective period either between runs or a fortnight after the last run
        let endDate = ((runIndex + 1) !== runs.length) ?
            moment(runs[runIndex + 1].ecr_datetime).format('YYYY-MM-DD') :
            moment(runDate).add(2,'week').format('YYYY-MM-DD');

        return _.filter(orders, o => moment(o.order_date).isBetween(runDate, endDate));
    }

    render() {

        const {isLoading, campaign, campaigns, perRunFiler, overviewData, subLoading} = this.state;

        if (isLoading) {
            return (
                <LoadingCircle/>
            )
        }

        if (!campaign) return (
            <Grid container spacing={2} style={{alignItems: 'center'}}>
                <Grid item xs={12}>
                    <CiDataTable
                        config={{
                            key: 'ec_id'
                        }}
                        rows={_.orderBy(campaigns, 'id', 'desc')}
                        columns={[
                            {
                                heading: 'Reference',
                                field: 'ref',
                                dataRef: 'ref',
                                sizeToContent: true
                            },
                            {
                                heading: 'name',
                                field: 'name',
                                dataRef: 'name',
                                truncate: true,
                            },
                            {
                                heading: 'status',
                                field: i => <AllIcon
                                    tooltip={i.active ? 'In Progress' : 'Completed'}
                                    icon={i.active ? IconHelper.dash : IconHelper.true}
                                    color={i.active ? colors.orange : colors.green}
                                    noMargin
                                />,
                                dataRef: 'active',
                                sizeToContent: true,
                                alignment: 'center', 
                            },
                            {
                                heading: 'runs',
                                field: 'runs',
                                dataRef: 'runs',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'scheduled',
                                field: 'scheduled',
                                dataRef: 'scheduled',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'contacts',
                                field: 'attempt',
                                dataRef: 'attempt',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'sent',
                                field: 'sent',
                                dataRef: 'sent',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'failed',
                                field: 'failed',
                                dataRef: 'failed',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'bounced',
                                field: 'bounced',
                                dataRef: 'bounced',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'complaints',
                                field: 'complaints',
                                dataRef: 'complaints',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'opened',
                                field: 'opened',
                                dataRef: 'opened',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'unread',
                                field: 'unread',
                                dataRef: 'unread',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'unsubscribed',
                                field: 'unsubscribed',
                                dataRef: 'unsubscribed',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'orders',
                                field: 'sales',
                                dataRef: 'sales',
                                sizeToContent: true,
                                alignment: 'center',
                                info: i => `£${parseFloat(i?.sales_total ?? 0).toFixed(2)}`
                            },
                            {
                                actions: i => ([
                                    {
                                        name: 'View Report',
                                        icon: IconHelper.search,
                                        onClick: ()=>this.selectCampaign(i.id)
                                    }
                                ])
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        )

        return (
            <Grid container spacing={2} style={{alignItems: 'center'}}>
                <Grid item xs={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                onClick={()=>this.setState({campaign: null})}
                                color="primary"
                                variant="contained"
                                fullWidth
                            >Select Another Campaign</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper style={{height: 318}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            <strong>Scheduling</strong><br/>
                                            {(campaign.ec_sending_options === 'Manual' && 'Manual') ||
                                            (
                                                (campaign.ec_sending_options === 'Scheduled (Repeating)' && 'Scheduled') ||
                                                (campaign.ec_sending_options === 'Scheduled (Fixed)' && 'Fixed')
                                            )}
                                        </Typography>
                                    </Grid>
                                    {campaign.ec_desc &&
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                <strong>Description</strong><br/>{campaign.ec_desc}
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <PaddedPaper style={{height: '100%'}}>
                                <Grid container spacing={2} style={{fontWeight: 'bold', fontSize: '1.2rem', justifyContent: 'center'}}>
                                    <Grid item
                                        style={{color: campaign?.scheduled.length === 0 || campaign?.runs.length === 0 ? colors.red : (
                                            campaign?.runs.length === campaign?.scheduled.length ? colors.green : (campaign.ec_active ? colors.orange : colors.red)
                                        )}}
                                    >{
                                        campaign?.scheduled.length === 0 || campaign?.runs.length === 0 ?
                                            'Not Started' :
                                            campaign?.runs.length === campaign?.scheduled.length ?
                                                'Completed' :
                                                (campaign.ec_active ?
                                                    'In Progress' :
                                                    'Ended Early'
                                                )
                                    }</Grid>
                                    <Grid item>/</Grid>
                                    <Grid item>Progress: {parseFloat((campaign?.runs.length / campaign?.scheduled.length) * 100).toFixed(2)}%</Grid>
                                    <Grid item>/</Grid>
                                    <Grid item>Scheduled: {campaign?.scheduled.length}</Grid>
                                    <Grid item>/</Grid>
                                    <Grid item>Runs: {campaign?.runs.length}</Grid>
                                    <Grid item>/</Grid>
                                    <Grid item>Sent: {parseFloat(overviewData.sent ? ((overviewData.sent / (overviewData.failed + overviewData.sent)) * 100) : 0).toFixed(2)}%</Grid>
                                    <Grid item>/</Grid>
                                    <Grid item>Opened: {parseFloat(overviewData.sent ? ((overviewData.opened / overviewData.sent) * 100) : 0).toFixed(2)}%</Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        {(campaign?.runs.length > 0 && overviewData.contacts > 0) ?
                            <>
                                <Grid item xs={7}>
                                    <PaddedPaper>
                                        <LineChart 
                                            data={overviewData.lineChart} 
                                            style={{ height: 231 }}
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={5}>
                                    <PaddedPaper>
                                        <PieChart 
                                            data={[
                                                {label: 'Opened',      value: overviewData.opened, color: colors.green},
                                                {label: 'Unread',      value: overviewData.sent - overviewData.opened, color: '#dadada'},
                                                {label: 'Failed To Send',    value: overviewData.failed, color: colors.red},
                                                {label: 'Bounced',    value: overviewData.bounced, color: colors.orange},
                                                {label: 'Complaint',    value: overviewData.complaint, color: colors.stickyNotes},
                                            ]} 
                                            style={{ height: 231 }}
                                            legendPosition="right"
                                        />
                                    </PaddedPaper>
                                </Grid>
                        </> : <Grid item xs={12}></Grid>}
                    </Grid>
                </Grid>
                {(campaign?.runs.length > 0 && overviewData.contacts > 0) ?
                    <>
                        <Grid item xs={6}>
                            <PaddedPaper>
                                <Table>
                                    <TableHead>
                                        <TableCell style={{textAlign: 'center'}}></TableCell>
                                        {_.map(_.keys(overviewData.splitTable), i => <TableCell style={{textAlign: 'center', borderRight: splitTableBorderRight.includes(i) && `1px solid ${colors.disabled}`, fontWeight: 'bold'}}>{i}</TableCell>)}
                                    </TableHead>
                                    <TableRow>
                                        <TableCell style={{textAlign: 'center'}}> <AllIcon noMargin heavy size='small' icon={IconHelper.total} /></TableCell>
                                        {_.map(_.keys(overviewData.splitTable), i => <TableCell style={{textAlign: 'center', borderRight: splitTableBorderRight.includes(i) && `1px solid ${colors.disabled}`}}>{ overviewData.splitTable[i][0] ? overviewData.splitTable[i][0] : '-'}</TableCell>)}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{textAlign: 'center'}}> <AllIcon noMargin heavy size='small' icon={IconHelper.average} /></TableCell>
                                        {_.map(_.keys(overviewData.splitTable), i => <TableCell style={{textAlign: 'center', borderRight: splitTableBorderRight.includes(i) && `1px solid ${colors.disabled}`}}>{ overviewData.splitTable[i][1] ? parseFloat(overviewData.splitTable[i][1]).toFixed(2) : '-'}</TableCell>)}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{textAlign: 'center'}}> <AllIcon noMargin heavy size='small' icon={IconHelper.percent} /></TableCell>
                                        {_.map(_.keys(overviewData.splitTable), i => <TableCell style={{textAlign: 'center', borderRight: splitTableBorderRight.includes(i) && `1px solid ${colors.disabled}`}}>{ overviewData.splitTable[i][2] ? `${parseFloat(overviewData.splitTable[i][2]).toFixed(2)}%` : '-'}</TableCell>)}
                                    </TableRow>
                                </Table>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={6}>
                            <PaddedPaper>
                                <BarChart
                                    data={_.map(_.orderBy(campaign?.runs, 'ecr_run_count', 'asc'), i => ({
                                        label: `Run ${i.ecr_run_count}`,
                                        color: {
                                            Failed: colors.red,
                                            Opened: colors.green,
                                            Bounced: colors.orange,
                                            Unread: '#dadada',
                                        },
                                        value: {
                                            Failed: _.filter(i.responses, j => !j.email).length,
                                            Opened: _.filter(i.responses, j => j?.email && j?.email?.email_datetime_opened !== '0000-00-00 00:00:00').length,
                                            Bounced: _.filter(i.responses, j => j?.email && j?.email?.email_status === 'Bounced').length,
                                            Unread: _.filter(i.responses, j => !!j.email).length - _.filter(i.responses, j => j?.email && j?.email?.email_datetime_opened !== '0000-00-00 00:00:00').length - _.filter(i.responses, j => j?.email && j?.email?.email_status === 'Bounced').length
                                        },
                                    }))}
                                    style={{height:'207px'}}
                                    stacked
                                />
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <InfoBox rowSize={9} title="Contacts" icon={IconHelper.contacts} value={overviewData.contacts} />
                                <InfoBox rowSize={9} title="Sent" icon={IconHelper.emailRuns} value={overviewData.sent} />
                                <InfoBox rowSize={9} title="Failed" icon={IconHelper.false} value={overviewData.failed} />
                                <InfoBox rowSize={9} title="Bounced" icon={IconHelper.emailBounced} value={overviewData.bounced} />
                                <InfoBox rowSize={9} title="Complaint" icon={IconHelper.emailComplaint} value={overviewData.complaints} />
                                <InfoBox rowSize={9} title="Opened" icon={IconHelper.emailOpen} value={overviewData.opened} />
                                <InfoBox rowSize={9} title="Unread" icon={IconHelper.email} value={overviewData.unread} />
                                <InfoBox rowSize={9} title="Unsubed" icon={IconHelper.ban} value={overviewData.unsubscribed} />
                                <InfoBox rowSize={9} title="Orders" icon={IconHelper.sales} value={campaign?.promotion?.orders?.length} />
                            </Grid>
                        </Grid>
                    </> : 
                    (subLoading ? <LoadingCircle/> : <></>)
                }
                {(campaign?.runs.length > 0 && overviewData.contacts > 0) &&
                    <>
                        <Grid item style={{width: 60}}>
                            <Typography variant="h6">Runs</Typography>  
                        </Grid>
                        <Grid item style={{width: 'calc(100% - 60px)'}}>
                            <hr/>
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    {_.map(_.orderBy(campaign?.runs, 'ecr_run_count', 'asc'), (i,idx) => 
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Run {i.ecr_run_count} - {clenyDate(i.ecr_datetime, true)}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={2}
                                    style={{paddingTop: '1em'}}
                                >
                                    <Grid item xs={6}>
                                        <PaddedPaper>
                                            <Table>
                                                <TableHead>
                                                    {_.map(_.keys(overviewData.splitTable), j => <TableCell style={{textAlign: 'center', fontWeight: 'bold',borderRight: splitTableBorderRight.includes(j) && `1px solid ${colors.disabled}`}}>{j}</TableCell>)}
                                                </TableHead>
                                                <TableRow>
                                                    {_.map([
                                                        i.responses.length,
                                                        _.filter(i.responses, j => !!j.email).length,
                                                        _.filter(i.responses, j => !j.email).length,
                                                        _.filter(i.responses, j => j?.email && j?.email?.email_status === 'Bounced').length,
                                                        _.filter(i.responses, j => j?.email && j?.email?.email_status === 'Complaint').length,
                                                        _.filter(i.responses, j => j?.email && j?.email?.email_datetime_opened !== '0000-00-00 00:00:00').length,
                                                        _.filter(i.responses, j => j?.email && j?.email?.email_status === 'Sent').length,
                                                        _.filter(i.responses, j => j?.email && j?.email?.email_datetime_optOut).length,
                                                    ], (j,idx)=><TableCell style={{textAlign: 'center', borderRight: splitTableBorderRight.includes(_.keys(overviewData.splitTable)[idx]) && `1px solid ${colors.disabled}`}}>{ j ? `${parseFloat(j).toFixed(0)}` : '-'}</TableCell>)}
                                                </TableRow>
                                                <TableRow>
                                                    {_.map([
                                                        100,
                                                        (_.filter(i.responses, j => !!j.email).length / i.responses.length) * 100,
                                                        (_.filter(i.responses, j => !j.email).length / i.responses.length) * 100,
                                                        (_.filter(i.responses, j => j?.email && j?.email?.email_status === 'Bounced').length / _.filter(i.responses, j => !!j.email).length) * 100,
                                                        (_.filter(i.responses, j => j?.email && j?.email?.email_status === 'Complaint').length / _.filter(i.responses, j => !!j.email).length) * 100,
                                                        (_.filter(i.responses, j => j?.email && j?.email?.email_datetime_opened !== '0000-00-00 00:00:00').length / _.filter(i.responses, j => !!j.email).length) * 100,
                                                        (_.filter(i.responses, j => j?.email && j?.email?.email_status === 'Sent').length / _.filter(i.responses, j => !!j.email).length) * 100,
                                                        (_.filter(i.responses, j => j?.email && j?.email?.email_datetime_optOut).length / _.filter(i.responses, j => !!j.email).length) * 100,
                                                    ], (j,idx)=><TableCell style={{textAlign: 'center', borderRight: splitTableBorderRight.includes(_.keys(overviewData.splitTable)[idx]) && `1px solid ${colors.disabled}`}}>{ j ? `${parseFloat(j).toFixed(0)}%` : '-'}</TableCell>)}
                                                </TableRow>
                                            </Table>
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <PaddedPaper>
                                            <LineChart 
                                                data={this.getRunLineData(i)} 
                                                style={{ height: 155 }}
                                            />
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <PaddedPaper>
                                            <PieChart 
                                                data={[
                                                    {label: 'Opened',      value: _.filter(i.responses, j => j?.email && j?.email?.email_datetime_opened !== '0000-00-00 00:00:00').length, color: colors.green},
                                                    {label: 'Unread',      value: _.filter(i.responses, j => j?.email && j?.email?.email_status === 'Sent').length, color: '#dadada'},
                                                    {label: 'Failed To Send',    value: _.filter(i.responses, j => !j.email).length, color: colors.red},
                                                    {label: 'Bounced',    value: _.filter(i.responses, j => j?.email && j?.email?.email_status === 'Bounced').length, color: colors.orange},
                                                    {label: 'Complaint',    value: _.filter(i.responses, j => j?.email && j?.email?.email_status === 'Complaint').length, color: colors.stickyNotes},
                                                ]} 
                                                style={{ height: 155 }}
                                                legendPosition="right"
                                            />
                                        </PaddedPaper>
                                    </Grid>
                                    <InfoBox {...(perRunFiler[i.ecr_run_count] === 'Contacts' ? {color: colors.primary, border: `1px solid ${colors.primary}`} : {}) } onClick={this.handleSetRunFilter(i.ecr_run_count)('Contacts')} rowSize={9} title="Contacts" icon={IconHelper.contacts} value={i.responses.length} />
                                    <InfoBox {...(perRunFiler[i.ecr_run_count] === 'Sent' ? {color: colors.primary, border: `1px solid ${colors.primary}`} : {}) } onClick={this.handleSetRunFilter(i.ecr_run_count)('Sent')} rowSize={9} title="Sent" icon={IconHelper.emailRuns} value={_.filter(i.responses, j => !!j.email).length} />
                                    <InfoBox {...(perRunFiler[i.ecr_run_count] === 'Failed' ? {color: colors.red, border: `1px solid ${colors.red}`} : {}) } onClick={this.handleSetRunFilter(i.ecr_run_count)('Failed')} rowSize={9} title="Failed" icon={IconHelper.false} value={_.filter(i.responses, j => !j.email).length} />
                                    <InfoBox {...(perRunFiler[i.ecr_run_count] === 'Bounced' ? {color: colors.orange, border: `1px solid ${colors.orange}`} : {}) } onClick={this.handleSetRunFilter(i.ecr_run_count)('Bounced')} rowSize={9} title="Bounced" icon={IconHelper.emailBounced} value={_.filter(i.responses, j => j?.email && j?.email?.email_status === 'Bounced').length} />
                                    <InfoBox {...(perRunFiler[i.ecr_run_count] === 'Complaint' ? {color: colors.stickyNotes, border: `1px solid ${colors.stickyNotes}`} : {}) } onClick={this.handleSetRunFilter(i.ecr_run_count)('Complaint')} rowSize={9} title="Complaint" icon={IconHelper.emailComplaint} value={_.filter(i.responses, j => j?.email && j?.email?.email_status === 'Complaint').length} />
                                    <InfoBox {...(perRunFiler[i.ecr_run_count] === 'Opened' ? {color: colors.green, border: `1px solid ${colors.green}`} : {}) } onClick={this.handleSetRunFilter(i.ecr_run_count)('Opened')} rowSize={9} title="Opened" icon={IconHelper.emailOpen} value={_.filter(i.responses, j => j?.email && j?.email?.email_datetime_opened !== '0000-00-00 00:00:00').length} />
                                    <InfoBox {...(perRunFiler[i.ecr_run_count] === 'Unread' ? {color: '#dadada', border: `1px solid ${'#dadada'}`} : {}) } onClick={this.handleSetRunFilter(i.ecr_run_count)('Unread')} rowSize={9} title="Unread" icon={IconHelper.email} value={_.filter(i.responses, j => j?.email && j?.email?.email_status === 'Sent').length} />
                                    <InfoBox {...(perRunFiler[i.ecr_run_count] === 'Unsubscribed' ? {color: colors.red, border: `1px solid ${colors.red}`} : {}) } onClick={this.handleSetRunFilter(i.ecr_run_count)('Unsubscribed')} rowSize={9} title="Unsubed" icon={IconHelper.ban} value={_.filter(i.responses, j => j?.email && j?.email?.email_datetime_optOut).length} />
                                    <InfoBox {...(perRunFiler[i.ecr_run_count] === 'Order' ? {color: colors.primary, border: `1px solid ${colors.primary}`} : {}) } onClick={this.handleSetRunFilter(i.ecr_run_count)('Order')} rowSize={9} title="Orders" icon={IconHelper.sales} value={_.filter(i.responses, j => _.find(this.filterOrdersToRun(i), o => _.find(j.addresses, {address_id: o.order_delivery_cust_address_id}) || o.order_delivery_cust_contact_email === j.contact?.contact_email || o.order_cust_contact_email === j.contact?.contact_email )).length} />
                                    <Grid item xs={12}>
                                        <CiDataTable
                                            config={{
                                                key: 'ecrr_id',
                                                pagination: _.filter(i.responses, this.tableRunFilter(i.ecr_run_count)).length > 25,
                                                rowsPerPage: 25,
                                            }}
                                            rows={_.filter(i.responses, this.tableRunFilter(i.ecr_run_count))}
                                            columns={[
                                                {
                                                    heading: 'Email',
                                                    field: 'contact.contact_email',
                                                    dataRef: 'contact.contact_email',
                                                    truncate: true,
                                                },
                                                {
                                                    heading: 'Customer',
                                                    field: i => i.contact?.customer?.cust_name ?? 'Manual',
                                                    dataRef: i => i.contact?.customer?.cust_name ?? 'Manual',
                                                    truncate: true,
                                                },
                                                {
                                                    heading: 'Sent',
                                                    field: j => !!j.email,
                                                    dataRef: j => !!j.email,
                                                    sizeToContent: true,
                                                    fieldFormat: 'boolean',
                                                    alignment: 'center'
                                                },
                                                {
                                                    heading: 'Failed',
                                                    field: j => !j.email,
                                                    dataRef: j => !j.email,
                                                    sizeToContent: true,
                                                    fieldFormat: 'boolean',
                                                    alignment: 'center'
                                                },
                                                {
                                                    heading: 'bounced',
                                                    field: j => j?.email && j?.email?.email_status === 'Bounced',
                                                    dataRef: j => j?.email && j?.email?.email_status === 'Bounced',
                                                    sizeToContent: true,
                                                    fieldFormat: 'boolean',
                                                    alignment: 'center'
                                                },
                                                {
                                                    heading: 'Complaint',
                                                    field: j => j?.email && j?.email?.email_status === 'Complaint',
                                                    dataRef: j => j?.email && j?.email?.email_status === 'Complaint',
                                                    sizeToContent: true,
                                                    fieldFormat: 'boolean',
                                                    alignment: 'center'
                                                },
                                                {
                                                    heading: 'opened',
                                                    field: j => j?.email && j?.email?.email_datetime_opened !== '0000-00-00 00:00:00',
                                                    dataRef: j => j?.email && j?.email?.email_datetime_opened !== '0000-00-00 00:00:00',
                                                    sizeToContent: true,
                                                    fieldFormat: 'boolean',
                                                    alignment: 'center'
                                                },
                                                {
                                                    heading: 'unread',
                                                    field: j => j?.email && j?.email?.email_status === 'Sent',
                                                    dataRef: j => j?.email && j?.email?.email_status === 'Sent',
                                                    sizeToContent: true,
                                                    fieldFormat: 'boolean',
                                                    alignment: 'center'
                                                },
                                                {
                                                    heading: 'unsubscribed',
                                                    field: j => j?.email && j?.email?.email_datetime_optOut,
                                                    dataRef: j => j?.email && j?.email?.email_datetime_optOut,
                                                    sizeToContent: true,
                                                    fieldFormat: 'boolean',
                                                    alignment: 'center'
                                                },
                                                {
                                                    heading: 'order',
                                                    field: j => _.find(this.filterOrdersToRun(i), o => _.find(j.addresses, {address_id: o.order_delivery_cust_address_id}) || o.order_delivery_cust_contact_email === j.contact?.contact_email || o.order_cust_contact_email === j.contact?.contact_email ),
                                                    dataRef: j => _.find(this.filterOrdersToRun(i), o => _.find(j.addresses, {address_id: o.order_delivery_cust_address_id}) || o.order_delivery_cust_contact_email === j.contact?.contact_email || o.order_cust_contact_email === j.contact?.contact_email ),
                                                    sizeToContent: true,
                                                    fieldFormat: 'boolean',
                                                    alignment: 'center'
                                                },
                                                {
                                                    actions: i => ([
                                                        {
                                                            name: 'View Customer',
                                                            icon: IconHelper.customer,
                                                            link: i.contact?.customer?.cust_id ? `/customers/view/${i.contact?.customer?.cust_id}` : '/marketing/manualContacts',
                                                        },
                                                        {
                                                            name: 'View Contact',
                                                            icon: IconHelper.search,
                                                            link: `/customers/contacts/view/${i.contact?.contact_id}`,
                                                        }
                                                    ])
                                                }
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )}
                </Grid>
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, size) => {
            dispatch(deployDialog(content, title, 'system', size))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        }
    }
}

export default connect(null, mapDispatchToProps)(CampaignsTab);