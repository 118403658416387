import * as loadingActionTypes from '../Loading/Types/Types';

export function startLoading(requestId) {
    return async (dispatch) => {
        try {
            dispatch({
                type: loadingActionTypes.LOADING_START,
                payload: {
                    requestId: requestId
                }
            });
        } catch (err) {
        }
    };
}

export function finishLoading(requestId) {
    return async (dispatch) => {
        try {
            dispatch({
                type: loadingActionTypes.LOADING_FINISH,
                payload: {
                    requestId: requestId
                }
            });
        } catch (err) {
        }
    };
}