import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import StockOutstanding from './StockMovements/OutstandingStockMovements';
import AllStockMovements from './StockMovements/StockMovements';
import { AppBar, Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core/';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';
import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';

const initialState = {
    currentTab: 0,
}

class ViewStockMovements extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `ViewStockMovements`;
        this.state              = this.getInitialState(initialState);
    }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        },this.savePageState);
    };
       
    render() {
        const {access, currentTab} = this.state;
        const tabs = [
            { label: 'Outstanding', ref:'o'},
            { label: 'ALL',         ref:'a'},
        ]

        return (
            <Grid container >
                <Grid item xs={12} style={{marginBottom:'1em'}}><Typography variant="h5">Stock Movement</Typography></Grid>
                <Grid item xs={12}>
                    <AppBar position="static" style={{backgroundColor:'white', color:"#000", minWidth: 1522, width: '100%', zIndex:'auto'}} >
                        <Tabs   value={currentTab} 
                                onChange={this.handleTabChange} 
                                className='xs-max-width' 
                                indicatorColor="primary" 
                                textColor="primary" 
                                variant='scrollable'
                                scrollButtons="auto"
                        >
                            {_.map(tabs, (i, idx) => <Tab label={i.label} value={idx} />)}
                        </Tabs>
                    </AppBar>
                </Grid>
                {currentTab == _.findIndex(tabs, i => i.ref == 'o') && 
                    <Grid item xs={12}>
                        <Paper style={{width:'100%'}}>
                            <Grid container xs={12} style={{padding:'1em'}} >
                                <Grid item xs={12}>
                                    <StockOutstanding/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                }
                {currentTab == _.findIndex(tabs, i => i.ref == 'a') && 
                    <Grid item xs={12}>
                        <Paper style={{width:'100%'}}>
                            <Grid container xs={12} style={{padding:'1em'}} >
                                <Grid item xs={12}>
                                    <AllStockMovements/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                }
            </Grid>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    clearPersistence: (key)        => dispatch(clearPersistence(key)),
    setPersistence:   (key, state) => dispatch(setPersistence(key, state))
})

export default connect(null, mapDispatchToProps)(ViewStockMovements);
