import React       from 'react';
import API 	       from 'API';
import _ 	       from 'lodash';
import moment      from 'moment';
import { connect } from 'react-redux';

import { Grid, Button, Typography, ListItemText, ListItem, List, ListItemSecondaryAction } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';
import AllIcon       from 'Components/Common/Icons/AllIcon';

import { clenyDate } from 'Helpers/TimeHelper';
import { colors }    from 'Helpers/ColourHelper';
import icons         from 'Helpers/IconHelper';

const initialState = {
	page: 0,
	perPage: 4,
	takes: [],
    searchResults: [],
    isLoading: true,
}

class StockTakes extends React.Component {
	constructor(props) {
        super(props);
		this.state = initialState;
	}

	componentDidMount = () => {
        this.getData();
		this.props.addKeyDown(this._handleKeyDown);
    }

	componentWillUnmount = () => {
		this.props.removeKeyDown()
	}

    getData = () => {
		this.setState({ isLoading: true },()=>{
			Promise.all([
				API.get(`/stock/take/all`, {params: {status: 'new'}}),
				API.get(`/stock/take/all`, {params: {status: 'recheck'}})
			])
			.then(([res1, res2]) => {
				let searchResults = _.orderBy([
					..._.filter(res1.data, i => parseInt(i.st_taker_id) === parseInt(this.props.loggedInStaff.id)),
					..._.filter(res2.data, i => parseInt(i.st_taker_id) === parseInt(this.props.loggedInStaff.id))
				], i => i.st_date, 'asc');
				
				this.setState({
					searchResults,
					takes:          _.take(searchResults, this.state.perPage),
					isLoading:      false
				});
			});
		});
    }

	_handleKeyDown = (e) => {
		if (e.keyCode === 0 || e.keyCode === 192) return;
		e.preventDefault();
		switch(e.key) {
			case 'F1':
				this.state.page > 0 && this.prevPage();
				break;
			case 'F2':
				((this.state.page + 1) * this.state.perPage) < this.state.searchResults.length && this.nextPage();
				break;
			case 'F3':
				this.getData();
			break;
			case '1':
			case '2':
			case '3':
			case '4':
            case '5':
				let takes = this.state.takes[parseInt(e.key) - 1];
				if (takes) this.startPick(takes);
			break
			default: break;
		}
	}

	nextPage = () => {
		const { page, perPage, searchResults } = this.state;
		const newPage = page + 1;
		const newOrders = _.take(_.slice(searchResults, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			takes: newOrders
		});
	}

	prevPage = () => {
		const { page, perPage, searchResults } = this.state;
		const newPage = page - 1;
		const newOrders = _.take(_.slice(searchResults, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			takes: newOrders
		});
	}

    startPick = (movement) => {
        this.props.history.push(`/stock/take/${movement.st_id}`);
    }

  	render() {

		const { isLoading, searchResults, takes, page, perPage } = this.state;
		const { classes } = this.props;

		if (isLoading) return <LoadingCircle/>;

		return (
            <Grid container spacing={1} style={{height: '100%'}}> 
                <Grid item xs={12}>
					<Typography variant='h4' style={{textAlign: 'center'}}>Stock Takes</Typography>
				</Grid>
				{parseInt(takes.length) === 0 ?
					<Grid item xs={12}>
						<PaddedPaper>
							<Typography variant='h6' className='mb-1' style={{textAlign: 'center'}}>No Stock Takes</Typography>
							<Button
								variant='contained'
								color='primary'
								onClick={this.getData}
								fullWidth
							>Refresh [F3]</Button>
						</PaddedPaper>
					</Grid> :	
					<Grid item xs={12}>
						<List>
							{_.map(takes, i => 
								<ListItem key={i.sm_id} className={classes.ListItem}
									onClick={()=>this.startPick(i)}
									style={{backgroundColor: moment(i.st_date).isSame(moment().format('YYYY/MM/DD'))
									? 'inherit'
									: colors.importantInfo}}
								>
									<ListItemText 
										primary={i.st_ref} 
										secondary={<>
												{i.st_checks > 1 ? 'Re-check' : 'First Check'}<br/>
												{clenyDate(i.st_date)}
											</>
										}
									/>
									<ListItemSecondaryAction>
										<AllIcon
											noMargin
											size='25'
											icon={_.filter(i.details, i => i.std_taken_stock === null).length > 0 ? icons.qr : icons.review}
										/>
									</ListItemSecondaryAction>
								</ListItem>
							)}
						</List>
                	</Grid>
				}	
				{ searchResults.length > perPage &&
					<Grid item xs={12} style={{marginTop: 'auto'}}>
						<Grid container spacing={1} style={{justifyContent: 'space-between'}}> 
							{page > 0 &&
								<Grid item style={{marginRight: 'auto'}}>
									<Button variant='contained' color='primary'
										onClick={this.prevPage}
									>[F1] Prev</Button>
								</Grid>
							}
							{((page + 1) * perPage) < searchResults.length &&
								<Grid item style={{marginLeft: 'auto'}}>
									<Button variant='contained' color='primary'
										onClick={this.nextPage}
									>Next [F2]</Button>
								</Grid>
							}
						</Grid>
					</Grid>
				}
            </Grid>
		);
  	}
}

const styles = theme => ({
    ListItem: {
		backgroundColor: colors.white, 
		borderRadius: 	 5,
		marginBottom: 	 '0.75em',
		boxShadow: 	  	 '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
	}
});

function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff
    };
}


export default  connect(mapStateToProps, null)(withStyles(styles)(StockTakes));