import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography, TextField } from '@material-ui/core';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import React from 'react';
import NewMailingListDialog from './NewMailingListDialog';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import API from 'API';
import IconHelper from 'Helpers/IconHelper';
import _ from 'lodash';
import { colors } from 'Helpers/ColourHelper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import InputHelper from 'Helpers/InputHelper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import InfoBox from 'Components/Common/reports/InfoBox';
import { cos } from 'mathjs';

const initialState = (props) => ({
    isLoading: true,
    dataLoading: false,
    mailingLists: [],
    showAddMailingListDialog: false,
    searchFormData: {
        keyword: null,
        customerId: props.customerId ?? null,
        campaignId: props.campaignId ?? null,
        contactId: props.contactId ?? null,
        status: 'Active',
        typeFilter: 'All',
    },
    access: {
        'create-mailing-lists': false,
        'view-mailing-lists': false,
        'inactivate-mailing-lists': false,
        'reactivate-mailing-lists': false,
    }
})

class MailingLists extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
        this.inputHelper = new InputHelper(this, 'searchFormData', this.getMailingLists);
    }

    componentDidMount() {
        this.inputHelper.handleSetAccess(_.keys(this.state.access))
        this.getMailingLists();
    }

    getMailingLists = () => {
        this.setState({
            dataLoading: true,
            mailingLists: [],
        }, ()=>{
            API.get(`/marketing/campaigns/mailingLists`, { params: this.state.searchFormData })
            .then(res => {
                this.setState({
                    mailingLists: res.data, 
                    isLoading: false,
                    dataLoading: false,
                });
            })
        })
    }

    handleNewMailingList = () => {
        this.setState({ showAddMailingListDialog: true });
    }   

    handleNewMailingListClose = () => {
        this.setState({ showAddMailingListDialog: false },this.getMailingLists);
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            searchFormData: {
                ...this.state.searchFormData,
                [name]: value
            }
        }, this.getMailingLists)
    }

    deleteMailingList = (ml) => {
        API.post(`/marketing/campaigns/mailingLists/${ml.ml_id}/delete`)
        .then(this.getMailingLists)
    }

    reactivateMailingList = (ml) => {
        API.post(`/marketing/campaigns/mailingLists/${ml.ml_id}/revive`)
        .then(this.getMailingLists)
    }

    removeContactFromMailingList = ({ml_id}) => {
        API.post(`/marketing/campaigns/mailingLists/${ml_id}/contacts/0/remove`, {contact_id: this.state.searchFormData.contactId})
        .then(this.getMailingLists);
    }

    render() {

        const { isLoading, mailingLists, showAddMailingListDialog, searchFormData, access } = this.state;
        const { preview } = this.props;
 
        if (isLoading) {
            return (
                <LoadingCircle/>
            )
        }

        return (
            <Grid container spacing={3}>
                {!preview &&
                    <Grid item xs={12}>
                        <Typography variant="h5">Mailing Lists</Typography>
                    </Grid>
                }
                {!preview &&
                    <Grid item xs={6}>
                        <PaddedPaper>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField 
                                        id="keywordSearch" 
                                        fullWidth 
                                        value={searchFormData.keyword ?? ''} 
                                        name='keyword'  
                                        label="Keyword" 
                                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value), this.getMailingLists)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AutoCompleteSelect
                                        label='Status'
                                        value={searchFormData.status}
                                        onChange={this.inputHelper.handleSelectChange('status')}
                                        options={[
                                            {label: 'Active', value: 'Active'},
                                            {label: 'Inactive', value: 'Deleted'},
                                            {label: 'All', value: 'All'},
                                        ]}
                                        fullWidth
                                        noClear
                                        disableSort
                                        style={{marginBottom: '1em', marginTop: '1em'}}
                                    />
                                </Grid>
                                <Grid item xs={12} className='buttonRow'>
                                    <Button
                                        variant="outlined"
                                        onClick={()=>this.inputHelper.handleSetState(initialState(this.props).searchFormData)}
                                    >
                                    <AllIcon
                                            icon={IconHelper.clear}
                                    /> Reset Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }       
                <InfoBox
                    onClick={this.inputHelper.handleButtonChange('typeFilter', 'All')}
                    title='All'
                    value={mailingLists.length}
                    icon={IconHelper.mailingList}
                    rowSize={preview ? 4 : 8}
                    color={searchFormData.typeFilter === 'All' && colors.green}
                    border={searchFormData.typeFilter === 'All' && `1px solid ${colors.green}`}
                />
                <InfoBox
                    onClick={this.inputHelper.handleButtonChange('typeFilter', 'Valid')}
                    title='Valid'
                    value={_.filter(mailingLists, i => !['Error', 'Warning'].includes(i.ml_contacts_status)).length}
                    icon={IconHelper.true}
                    rowSize={preview ? 4 : 8}
                    color={searchFormData.typeFilter === 'Valid' && colors.green}
                    border={searchFormData.typeFilter === 'Valid' && `1px solid ${colors.green}`}
                />
                <InfoBox
                    onClick={this.inputHelper.handleButtonChange('typeFilter', 'Warning')}
                    title='Warning'
                    value={_.filter(mailingLists, i => ['Warning'].includes(i.ml_contacts_status)).length}
                    icon={IconHelper.warning}
                    rowSize={preview ? 4 : 8}
                    color={searchFormData.typeFilter === 'Warning' && colors.orange}
                    border={searchFormData.typeFilter === 'Warning' && `1px solid ${colors.orange}`}
                />
                <InfoBox
                    onClick={this.inputHelper.handleButtonChange('typeFilter', 'Error')}
                    title='Invalid'
                    value={_.filter(mailingLists, i => ['Error'].includes(i.ml_contacts_status)).length}
                    icon={IconHelper.warning}
                    rowSize={preview ? 4 : 8}
                    color={searchFormData.typeFilter === 'Error' && colors.red}
                    border={searchFormData.typeFilter === 'Error' && `1px solid ${colors.red}`}
                />
                <Grid item xs={12}>
                    <PaddedPaper>
                        {(!this.props.preview && access['create-mailing-lists']) &&
                            <div className='buttonRow' style={{width: '100%', marginBottom: '0.5em'}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleNewMailingList}
                                >Create New Mailing List</Button>
                            </div>
                        }
                        <DataTable
                            config={{
                                key: 'ml_id',
                                pagination: true,
                                style: i => ({
                                    color: i.ml_status !== 'Active' && colors.disabled
                                }),
                                isLoading: this.state.dataLoading,
                            }}
                            rows={_.orderBy(
                                searchFormData.typeFilter === 'All' ?
                                    mailingLists : _.filter(
                                        mailingLists,
                                        i => {
                                            switch (searchFormData.typeFilter) {
                                                case 'Valid':
                                                    return !['Error', 'Warning'].includes(i.ml_contacts_status);
                                                case 'Warning':
                                                    return ['Warning'].includes(i.ml_contacts_status);
                                                case 'Error':
                                                    return ['Error'].includes(i.ml_contacts_status);
                                            }
                                        }
                                    )
                                
                                , 'ml_id', 'desc')}
                            columns={_.filter([
                                {
                                    heading: 'Date',
                                    field: 'ml_date',
                                    sizeToContent: true,
                                    dataRef: 'ml_date',
                                    fieldFormat: 'clenydate'
                                },
                                {
                                    heading: 'Reference',
                                    field: 'ml_reference',
                                    dataRef: 'ml_reference',
                                    truncate: true,
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Name',
                                    field: 'ml_name',
                                    dataRef: 'ml_name',
                                    truncate: true,
                                },
                                {
                                    heading: 'Contact Adding',
                                    field: i => {
                                        switch (i.ml_logic_type) {
                                            case 'manual':
                                                return 'Manual';
                                            case 'CustomerMarketing':
                                                return 'Customer Marketing';
                                            case 'ServicePlan':
                                                return (parseInt(i.ml_logic_data) ? 'With' : 'Without ') + ' Service Plan';
                                            case 'BusinessType':
                                                return 'Business Type: ' + JSON.parse(i.ml_logic_data)?.type;
                                            case 'LegalStatus':
                                                return 'Legal Status: ' + JSON.parse(i.ml_logic_data)?.status;
                                        }
                                    },
                                    sizeToContent: true,
                                    dataRef: 'ml_logic_type',
                                },
                                {
                                    heading: 'Total Contacts',
                                    field: 'contacts_count',
                                    sizeToContent: true,
                                    alignment: 'center',
                                    dataRef: 'contacts_count',
                                },
                                {
                                    heading: 'Valid Contacts',
                                    field: 'ml_contacts_valid',
                                    sizeToContent: true,
                                    alignment: 'center',
                                    dataRef: 'ml_contacts_valid',
                                },
                                {
                                    heading: '',
                                    field: i => 
                                        i.ml_status === 'Active' ?
                                            <AllIcon 
                                                icon={['Error', 'Warning'].includes(i.ml_contacts_status) ? IconHelper.warning : IconHelper.true}
                                                color={['Error', 'Warning'].includes(i.ml_contacts_status) ? ( i.ml_contacts_status === 'Error' ? colors.red : colors.orange) : colors.green}
                                                tooltip={['Error', 'Warning'].includes(i.ml_contacts_status) ? ( i.ml_contacts_status === 'Error' ? 'Mailing list has invalid contacts' : 'Mailing list has warnings' ) : 'Mailing list has no issues'}
                                                noMargin
                                            /> :
                                            <AllIcon
                                                icon={IconHelper.false}
                                                color={colors.disabled}
                                                tooltip='Mailing list is inactive'
                                                noMargin
                                            />
                                            ,
                                    sizeToContent: true,
                                },
                                {
                                    actions: i => (_.filter([
                                        searchFormData.contactId &&
                                        {
                                            name: 'Remove From Mailing List',
                                            icon: IconHelper.false,
                                            onClick: (i) => this.props.deployConfirmation('Are you sure you want to remove this contact from this mailing list?', 'Remove Contact From Mailing List', ()=>{this.removeContactFromMailingList(i)}),
                                        },
                                        {
                                            name: 'View',
                                            link: `/marketing/mailingLists/${i.ml_id}`,
                                            icon: IconHelper.view,
                                            disabled: !access['view-mailing-lists'],
                                        },
                                        !preview &&
                                        {
                                            name: i.ml_status === 'Active' ? 'Inactivate' : 'Re-Activate',
                                            onClick: () => 
                                                i.ml_status === 'Active' ?
                                                    this.props.deployConfirmation('Are you sure you want to make this mailing list inactive?', 'Inactivate Mailing List',() => this.deleteMailingList(i)) :
                                                    this.props.deployConfirmation('Are you sure you want to make this mailing list active?', 'Activate Mailing List',() => this.reactivateMailingList(i)),
                                            icon: i.ml_status === 'Active' ? IconHelper.minus : IconHelper.plus,
                                            disabled: i.ml_status === 'Active' ? !access['inactivate-mailing-lists'] : !access['reactivate-mailing-lists'],
                                        }
                                    ], i=>i))
                                }
                            ], i => i)}
                        />
                    </PaddedPaper>
                </Grid>                
                {showAddMailingListDialog &&
                    <Dialog
                        open={showAddMailingListDialog}
                        fullWidth
                        maxWidth='md'
                    >
                        <DialogTitle>
                            <Typography variant="h6">Create New Mailing List</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <NewMailingListDialog
                                onClose={
                                    this.handleNewMailingListClose
                                }
                            />
                        </DialogContent>
                    </Dialog>
                }
            </Grid>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback) => {
            dispatch(deployConfirmation(content, title, callback))
        }
    }
}

export default connect(null, mapDispatchToProps)(MailingLists);