import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';
import { clenyDate } from 'Helpers/TimeHelper';

import { Grid } from '@material-ui/core/';


import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = {
    isLoading:  true,
    stockTake:  [],
    formErrors: [],
    access:     {},
    staff_id:   0
}


class NewStockTakes extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => { 
        this.getAccess();    
        this.getData(); 
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/stock-take:delete'), 
            API.get('/staff/my/access/check/stock-take:download'), 
            API.get('/staff/my/access/check/stock-take:manual'), 
            API.get('/staff/my/access/check/stock-take:scan'), 
            API.get('/staff/my/access/check/stock-take:review'), 
        ]).then(([deleteRes, downloadRes, manualRes, scanRes, reviewRes]) => {
            this.setState({
                access: {
                    download: downloadRes.data.has_access || false,
                    delete:   deleteRes.data.has_access   || false,
                    manual:   manualRes.data.has_access   || false,
                    scan:     scanRes.data.has_access     || false,
                    review:   reviewRes.data.has_access   || false
                }
            })
        });
    }

    getData = () => {
        let params = {status: 'new'};
        Promise.all([
            API.get(`/stock/take/all`,{params}),
            API.get(`/staff/my/profile`),
        ]).then(([Res, staffRes]) => {
            this.setState({
                stockTake: _.filter(Res.data, j =>_.filter(j.details, i => i.std_taken_by !== null && i.std_taken_by !== null).length > 0),
                staff_id: staffRes.data.staff_id,
                isLoading: false
            });
        })
    }

    delete = id => {
        API.post(`/stock/take/${id}/delete`)
        .then( res => {this.getData();})
    }

    download = id => {
        API.get(`/stock/take/${id}/download`)
        .then( result => {
            if(result.data) pdfFromBase64(result.data.pdf, result.data.pdfName);
        })
    }

    render() {

        const {isLoading, formErrors, stockTake, access} = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {isLoading ? <LoadingCircle/>: 
                        <>
                            <DataTable
                                config={{
                                    key: 'st_id',
                                    alternatingRowColours:  true,
                                    responsiveimportant:    true,
                                    pagination: true,
                                    filterInPagination: true,
                                    filterMod: {
                                        filterInPagination:     true,
                                        colSpan:                2,
                                        clearable:              true,
                                    },
                                    style: rowData => ({
                                        color: moment(rowData.st_date).diff(moment(),'days') < 0 && colors.red
                                    })
                                }}
                                columns={[
                                    {
                                        heading:        'Date',
                                        field:          rowData => clenyDate(rowData.st_date),
                                        dataRef:        'st_date',
                                        sizeToContent:  true,
                                        filter:         'custom',
                                        filterMod: {
                                            dataRef: (search, rowData) => {return `${rowData.st_date}|${rowData.full_reference}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                            label:     'Keywords',
                                        }
                                    },
                                    {
                                        heading:    'Reference',
                                        field:      rowData => rowData.full_reference,
                                        dataRef:    'sc_ref'
                                    },
                                    {
                                        heading:    '',
                                        field:      rowData => null,
                                    },
                                    {
                                        heading:    'Counter',
                                        field:      rowData => rowData.st_taker,
                                        sizeToContent: true,
                                        dataRef:    'st_taker'
                                    },
                                    {
                                        heading:    'Reviewer',
                                        field:      rowData => rowData.st_reviewer,
                                        sizeToContent: true,
                                        dataRef:    'st_reviewer'
                                    },
                                    {
                                        heading:    'Progress',
                                        field:      rowData => `${_.filter(rowData.details, i => i.std_taken_by !== null && i.std_taken_by !== null).length}/${rowData.details.length}`,
                                        dataRef:    rowData => _.filter(rowData.details, i => i.std_taken_by !== null && i.std_taken_by !== null).length,
                                        sizeToContent: true,
                                        alignment: 'right',
                                    },
                                    {
                                        actions: rowData => {return([
                                            {name: 'Download', icon: icons.download,  onClick: ()=>{this.download(rowData.st_id)},                                    disabled: !access.download },
                                            {name: 'Manual', icon: icons.manualEntry, link: `/stock/take/manual/${rowData.st_id}`, disabled: !access.manual || rowData.st_check_type == 'QR'     || (rowData.st_taker_id !== this.state.staff_id)},
                                            {name: 'Scan',   icon: icons.qr,          link: `/stock/take/scan/${rowData.st_id}`,   disabled: !access.scan   || rowData.st_check_type == 'Manual' || (rowData.st_taker_id !== this.state.staff_id)},
                                        ])}
                                    }
                                ]}
                                rows={_.orderBy(stockTake, ['st_date'],['asc'])}
                            />
                        </>
                    }
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation:     (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
})


export default connect(null,mapDispatchToProps)(NewStockTakes);
