import React, { Component } from 'react';
import API                  from 'API';
import { connect }          from 'react-redux';
import _                    from 'lodash';

import { Button, Grid, Typography, Dialog, DialogTitle, DialogContent, ListItem, List } from '@material-ui/core';

import { closePrint } from 'Actions/Print/Print';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import AllIcon       from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';


const LoadingPrint = ({id, status, fileName=null}) =>
    <ListItem>
        <Grid container style={{alignItems: 'center'}}>
            <Grid item xs={9}>
                <Typography variant='body1'>{fileName || `Print ${id}`}</Typography>
            </Grid>
            <Grid item xs={3} style={{textAlign: 'right'}}>
                {status === 'Completed' ? <AllIcon size='small' icon={icons.true} color={colors.green} /> : (
                        status === 'Error' ? <AllIcon size='small' icon={icons.false} color={colors.red} /> : <LoadingCircle noMargin padding='xs' size={20} message={' '}/>
                    )}
            </Grid>
        </Grid>
    </ListItem>

class Print extends Component {
    constructor(props){
        super(props);
        this.state = {
            statuses: [],
            ids:      []
        };
        this.timeout = null;
    }

    componentDidMount(){
        this.props.multi.length > 0 ? this.multiPrint() : this.singlePrint();
    }

    componentWillUnmount(){
        clearTimeout(this.timeout);
    }

    multiPrint = () => {
        _.each(this.props.multi, i => {
            this.print(i.printer, i.file, i.url)
        })
    }

    singlePrint = () => {
        this.print(this.props.printer, this.props.file, this.props.url)
    }
    
    print = (printer, file=null, url=null) => {
        let newFormDate = new FormData();
        newFormDate.append('printer', printer);
        newFormDate.append('file',    file);
        newFormDate.append('url',     url);
        API.post('/print/print', newFormDate).then(res => {
            this.setState({ids: [...this.state.ids, res.data]}, this.getStatus);
        })
    }

    getStatus = () => {
        API.get(`/print/status`, { params: { ids: this.state.ids } }).then(res => {
            this.setState({statuses: res.data});
            
            _.filter(_.values(res.data), i => i === 'Completed' || i === 'Error').length === 
            this.state.ids.length ? 
                this.timeout = setTimeout(this.close, 1000) : 
                this.timeout = setTimeout(this.getStatus, 2000);
            
        });
    }

    close = () => {
        clearTimeout(this.timeout);
        this.props.closePrint();
    }

    render() {

        
        const { statuses, ids } = this.state;
        
        if (statuses?.length > 0 ) return (<LoadingCircle message='Sending...'/>)

        return (
            <Dialog
                open={true}
            >
                <DialogTitle>Printing...</DialogTitle>
                <DialogContent>
                    <Grid container style={{justifyContent: 'center'}} spacing={2}>
                    {parseInt(this.props.multi?.length) > 0 ?
                        <Grid item xs={12}>
                            <List>{_.map(this.props.multi, (i, idx) => <LoadingPrint id={i.id} status={_.values(statuses)[idx]} fileName={i.fileName}  />)}</List>
                        </Grid> :
                            (_.values(statuses).length > 0 && <>
                                    <Grid item xs={12} style={{textAlign: 'center'}}>
                                        {statuses[ids[0]] === 'Completed' ? <Typography variant='h5'>{statuses[ids[0]] === 'Sent' ? 'Received' : statuses[ids[0]]}</Typography> :
                                            statuses[ids[0]] === 'Error' ?  <AllIcon size='xxxlarge' icon={icons.false} color={colors.red} /> : <Typography variant='h5'>{this.props.fileName}</Typography>}
                                    </Grid>
                                    <Grid item xs={12}  style={{textAlign: 'center'}}>
                                        {statuses[ids[0]] === 'Completed' ? <AllIcon size='xxxlarge' icon={icons.true} color={colors.green} /> : (
                                            statuses[ids[0]] === 'Error' ? <AllIcon size='xxxlarge' icon={icons.false} color={colors.red} /> : <LoadingCircle message={statuses[ids[0]]}/>
                                        )}
                                    </Grid>
                                </>)
                             }
                        <Grid item xs={12} className='buttonRow'>
                            <Button variant='outlined' onClick={this.close}>Close</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    closePrint: () => dispatch(closePrint())
})

export default connect(null, mapDispatchToProps)(Print);
