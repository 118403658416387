import * as SoundActionTypes from './Types/Types';
import Message from 'Assets/Sounds/Message.mp3';
import MessageForMe from 'Assets/Sounds/MessageForMe.mp3';
import MessageMlgAirhorn from 'Assets/Sounds/MessageMlgAirhorn.mp3';
import MessageMsn from 'Assets/Sounds/MessageMsn.mp3';
import MessageNewMessage from 'Assets/Sounds/MessageNewMessage.mp3';
import PickSuccess from 'Assets/Sounds/PickSuccess.wav';
import PickError from 'Assets/Sounds/PickError.wav';
import PickComplete from 'Assets/Sounds/PickComplete.wav';

export function handlePlaySound(src, state) {
    return dispatch => {
        dispatch({
            type: SoundActionTypes.PLAY_SOUND,
            payload: {
                src,
                state,
                status: 'playing'
            }
        });
    }
}

export function playSound(type, state = false) {
    return (dispatch, getState) => {
        let src = null;
        const staffId = getState().staffAuth.staff?.id,
                staff = getState().notifications?.status;
        switch(type) {
            case 'msg':
                switch(staff?.[staffId]?.msgSound) {
                    case "MESSAGE_DEFAULT":
                        src = Message;
                    break;
                    case "MESSAGE_FOR_ME":
                        src = MessageForMe;
                    break;
                    case "MESSAGE_MLG_AIRHORN":
                        src = MessageMlgAirhorn;
                    break;
                    case "MESSAGE_MSN":
                        src = MessageMsn;
                    break;
                    case "MESSAGE_NEW_MESSAGE":
                        src = MessageNewMessage;
                    break;
                    case "MESSAGE_NONE":
                        src = null;
                    break;
                    default:
                        src = Message;
                    break;
                }
            break;
            case 'pickSuccess':
                src = PickSuccess;
            break;
            case 'pickFailed':
                src = PickError;
            break;
            case 'pickComplete':
                src = PickComplete;
            break;
            default:
                src = null;
            break;
        }
        dispatch(handlePlaySound(src, state));
    }
}

export function handleStopSound() {
    return dispatch => {
        dispatch({
            type: SoundActionTypes.STOP_SOUND,
            payload: {
                src: null,
                status: 'stop'
            }
        });
    }
}

export function stopSound() {
    return dispatch => {
        dispatch(handleStopSound());
    }
}