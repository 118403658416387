import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import {getFiscalYearTimestamps}  from 'Helpers/ReportsHelper';
import _ from 'lodash';
import TabBar from 'Components/Common/Navigation/TabBar';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import SalesReport from './Reports/SalesReport';
import PartsReport from './Reports/PartsReport';
import MarginReport from './Reports/MarginReport';
import DiscountsReport from './Reports/DiscountsReport';
import PartCategoriesReport from './Reports/PartCategoriesReport';


const currentYear = parseInt(getFiscalYearTimestamps().end.format('YYYY'))

const initialState = () => ({
    isLoading: true,
    year: currentYear,
    years: [],
    report: 'sales', //default: 'sales'
    reports: [
        {label: 'Sales', value: 'sales' },
        {label: 'Parts', value: 'parts' },
        {label: 'Margins', value: 'margins' },
        {label: 'Discounts', value: 'discounts' },
        {label: 'Part Categories', value: 'partCategories' },
    ]
})

class YoYsReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.minYear = (this.props.startYear && this.props.startYear >= 2021) ? this.props.startYear : 2021;
    }   

    componentDidMount() {
        this.getYears();
    }

    getYears = () => {
        this.setState({
            years: _.orderBy([
                {label: `${getFiscalYearTimestamps().start.format('YYYY')}/${getFiscalYearTimestamps().end.format('YYYY')}`, value: currentYear},
                ..._.map(
                    _.range(this.minYear, getFiscalYearTimestamps().end.format('YYYY')),
                    i => ({label: `${i-1}/${i}`, value: i})
                )
            ], i => i.value, 'desc')
        })
    }

    handleChangeYear = (event,year) => {
        this.setState({year});
    }

    handleChangeReport = (event,report) => {
        this.setState({report});
    }


    render() {

        const {years, year, reports, report} = this.state;

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {( this.props.partId || this.props.cid ) ?
                            <></> :
                            <Typography variant="h5">
                                Year On Year Sales Reports
                            </Typography>
                        }
                    </Typography>
                </Grid>
                <Grid item xs={12}  style={{paddingBottom:0}}>
                    <TabBar
                        tabs={reports}
                        currentTab={report} 
                        handleTabChange={this.handleChangeReport}
                    />
                    <TabBar
                        tabs={years}
                        currentTab={year} 
                        handleTabChange={this.handleChangeYear}
                    />
                </Grid>
                <Grid item xs={12} style={{paddingTop:0}}>
                    <PaddedPaper>
                        {report === 'sales' &&
                            <SalesReport history={this.props.history} year={year} current={year === currentYear ? 1 : 0} cid={this.props.cid} partId={this.props.partId} />
                        }
                        {report === 'parts' &&
                            <PartsReport history={this.props.history} year={year} current={year === currentYear ? 1 : 0} cid={this.props.cid} partId={this.props.partId} />
                        }
                        {report === 'margins' &&
                            <MarginReport history={this.props.history} year={year} current={year === currentYear ? 1 : 0} cid={this.props.cid} partId={this.props.partId} />
                        }
                        {report === 'discounts' &&
                            <DiscountsReport history={this.props.history} year={year} current={year === currentYear ? 1 : 0} cid={this.props.cid} partId={this.props.partId} />
                        }
                        {report === 'partCategories' &&
                            <PartCategoriesReport history={this.props.history} year={year} current={year === currentYear ? 1 : 0} cid={this.props.cid} partId={this.props.partId} />
                        }
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

export default YoYsReports;