import React, { Component } from 'react';
import _                    from 'lodash';
import API                  from 'API';
import { connect }          from 'react-redux';

import { Grid, Typography,ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Button } from '@material-ui/core/';

import LoadingCircle        from 'Components/Common/LoadingCircle/LoadingCircle';
import DataTable            from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper          from 'Components/Common/Paper/PaddedPaper';
import AllIcon              from 'Components/Common/Icons/AllIcon';
import AutoCompleteSelect   from 'Components/Common/Selects/AutoCompleteSelect';
import DragFileInput        from 'Components/Common/Inputs/DragFileInput';
import SinglePrintDialog    from 'Components/Common/Dialogs/SinglePrintDialog';

import { deployDialog } from 'Actions/Dialog/Dialog';

import icons from 'Helpers/IconHelper';

const initialState = {
    isLoading:          true, 
    jobs:               [],
    printers:           [],
    formData:           {
        printer:        '',
        file:           '',
    }
}

class Printing extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        Promise.all([
            API.get('/print/printers'),
            API.get('/print/jobs'),
        ]).then((response) => {
            this.setState({
                printers: response[0].data,
                jobs: response[1].data,
                isLoading: false,
                formData: initialState.formData,
            })
        })
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                file
            }
        })
    }

    handlePrint = () => {
        let newFormData = new FormData();
        newFormData.append('printer', this.state.formData.printer);
        newFormData.append('file', this.state.formData.file);

        API.post('/print/print', newFormData).then((res) => {
            this.showPrintDialog(res.data);
        });
    }

    rePrint = (job) => {
        API.post(`/print/${job.job_id}/reprint`).then((res) => {
            this.showPrintDialog(res.data);
        });
    }

    showPrintDialog = (id) => {
        this.props.deployDialog(<SinglePrintDialog id={id} onClose={this.getData} />, 'Printing...');
    }

    render() {

        const { isLoading, printers, jobs } = this.state;

        if (isLoading) return <LoadingCircle/>

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                    <Typography variant="h5">Printing</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <PaddedPaper>
                        <Typography>Test Print</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12} >
                                <AutoCompleteSelect
                                    label="Printer *"
                                    options={_.map(printers, i => ({value: i.printer_id, label: i.printer_name}))}
                                    value={this.state.formData.printer}
                                    onChange={(e) => this.setState({formData: {...this.state.formData, printer: e.value}})}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DragFileInput
                                    label="File *"
                                    onChange={(drop, name, event) => this.setState({ formData: { ...this.state.formData, file:  drop === true ? event.dataTransfer.files[0] : event.target.files[0] }})}
                                    cancelOnClick={(e) => this.setState({formData: {...this.state.formData, file: null}})}
                                    fullWidth
                                    file={this.state.formData.file}
                                />
                            </Grid>
                            <Grid item xs={12} className='buttonRow'>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handlePrint}
                                    disabled={!this.state.formData.printer || !this.state.formData.file}
                                >Print</Button>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>

                <Grid item xs={12} lg={12}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<AllIcon icon={icons.arrowDown} noMargin white />}>
                            <Typography>Printers ({printers.length})</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <DataTable
                                config={{
                                    key: 'printer_id',
                                }}
                                columns={[
                                    {
                                        heading: 'Printer',
                                        field: 'printer_name',
                                    },
                                    {
                                        heading: 'Location',
                                        field: 'printer_location',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Paper (inch)',
                                        field: i => `${i.printer_paper_width} x ${i.printer_paper_height}`,
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:  'Margin',
                                        field: 'printer_margins',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:  'Raw PDF?',
                                        field: 'printer_raw_pdf',
                                        sizeToContent: true,
                                        fieldFormat: 'boolean',
                                        alignment: 'center',
                                    }
                                ]}
                                rows={printers}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<AllIcon icon={icons.arrowDown} noMargin white />}>
                            <Typography>Print Jobs ({jobs.length})</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <DataTable
                                config={{
                                    key: 'job_id',
                                }}
                                columns={[
                                    {
                                        heading: 'Job',
                                        field:   'job_id',
                                        sizeToContent: true,
                                        alignment: 'center',
                                    },
                                    {
                                        heading: 'Status',
                                        field:   'st',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:  'Created',
                                        field: 'cr',
                                        fieldFormat: 'datetime',
                                    },
                                    {
                                        heading:  'Printed',
                                        field: 'se',
                                        fieldFormat: 'datetime',
                                    },
                                    {
                                        actions: row => ([
                                            {
                                                icon: icons.refresh,
                                                name: 'Reprint',
                                                onClick: this.rePrint
                                            }
                                        ])
                                    }
                                ]}
                                rows={jobs}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog:           (content, title, variant, size='sm') => { dispatch(deployDialog(content, title, variant, size))},
    }
}
export default connect(null, mapDispatchToProps)(Printing)