import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import API from '../../../API';
import _ from 'lodash';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import IconButton from '@material-ui/core/IconButton';
import SnackBar from '../../Common/SnackBars/SnackBar';
import { Divider } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import Textarea from '../../Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import BackButton from 'Components/Common/Buttons/BackButton';
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData:  {
        otherRows: [
            {
                detailId: '',
                description: '',
                quantity: '',
                unitPrice: '',
                totalPrice: '',
                nominalList: [],
            }
        ],
        overallPrice: 0
    },
    ppo: {},
    defaultItemsList: [],
    defaultItems: {},
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    redirect: false,
    successRedirect: false,
    successRedirectID: 0,
    isLoading: true
}

const styles = theme => ({
    td : {
        border: 'none'
    },
    [theme.breakpoints.down('xs')]: {
        responsiveTable: {
            maxWidth: '100%', 
            overflowX: 'auto'
        }
    }
});

class UpdatePartsOrderOtherItems extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.populateDropdowns();
        this.getPPO();
    }

    getPPO = () => {
        API.get('/partsOrdering/' + this.props.match.params.id)
        .then(result => {
            if(result.data.errors) {
                this.setState({ redirect: true })
            } else if(result.data) {
                let otherRows = [];
                result.data.parts_order_details.forEach((row) => {
                    // Nominal Codes
                    if(row.ppo_detail_part_id === 0){
                        let otherItem = _.find(this.state.defaultItems, {'ppo_list_description': row && row.ppo_detail_description });
                        var nominalList = [];
                        if(otherItem) {
                            nominalList = _.map(otherItem.nominal_codes, (el) => {
                                return _.assign({
                                    value: el.nominal_id,
                                    label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                                });
                            });
                        }
                        otherRows.push({
                            detailId: row.ppo_detail_id,
                            description: row.ppo_detail_description,
                            nominalCode: row.nominal_code && row.nominal_code.nominal_id,
                            quantity: row.ppo_detail_qty,
                            unitPrice: row.ppo_detail_unit_price,
                            totalPrice: row.ppo_detail_total_price,
                            nominalList: nominalList
                        });
                    }
                });
                
                this.setState({
                    isLoading: false,
                    formData: {
                        ...this.state.formData,
                        otherRows: otherRows 
                    },
                    ppo: result.data
                },
                () => {
                    this.calculateOverallTotals();
                });
            }

        });
    }

    populateDropdowns = () => {
        Promise.all([
            API.get('/partsOrdering/defaultList')
        ])
        .then(([defaultItems]) =>  {
            let defaultItemsList = [];

            if(defaultItems.data) {
                defaultItemsList = _.map(defaultItems.data, (el) => {
                    return _.assign({
                        value: el.ppo_list_description,
                        label: el.ppo_list_description
                    });
                });
            }
            this.setState({
                defaultItemsList: defaultItemsList,
                defaultItems: defaultItems.data
            });
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    }

    handleAddRow = fieldName => t => {
        const item = initialState.formData[fieldName][0];
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: [...this.state.formData[fieldName], item]
            }
        });
    }

    handleRowChange = (idx, array, decimals) => e => {
        const { name, value, checked } = e.target;
        let newQty =  [...this.state.formData[array]];
        let newVal = (name === 'attachPO') ? checked : decimals ? parseFloat(value).toFixed(decimals) : value;

        newQty[idx] = {
            ...newQty[idx],
            [name]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                [array]: newQty 
            }
        },
        () => {
            this.calculatePrice(idx, array);
        });
    };

    handleSelectRowChange = (idx, fieldName, array) => selectedOption => {

        let data =  [...this.state.formData[array]];
        let newVal = selectedOption && selectedOption.value;

        data[idx] = {
            ...data[idx],
            [fieldName]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                [array]: data
            }
        },
        () => {
            if(fieldName === 'description'){
                let data =  [...this.state.formData[array]];
                var nominalList = [];
                let otherItem = {};

                if(selectedOption) {
                    let otherItem = _.find(this.state.defaultItems, {'ppo_list_description': selectedOption && selectedOption.value });
                    nominalList = _.map(otherItem.nominal_codes, (el) => {
                        return _.assign({
                            value: el.nominal_id,
                            label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                        });
                    });
                }

                data[idx] = {
                    ...data[idx],
                    nominalCode: (otherItem && otherItem.nominal_code && otherItem.nominal_code.nominal_id) || '',
                    nominalList: nominalList
                };
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [array]: data
                    }
                });
            }
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleRemoveRow = (idx, array) => () => {
        let rows = [...this.state.formData[array]]
        rows.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                [array]: rows
            }
        },
        () => {
            this.calculatePrice(idx, array);
        })
    }

    calculatePrice = (idx, array) => {
        let data =  [...this.state.formData[array]];
        if(data[idx]){
            data[idx] = {
                ...data[idx],
                totalPrice: data[idx] ? (data[idx].unitPrice * data[idx].quantity).toFixed(2) : (0).toFixed(2)
            };
            // Set new total price before setting overall price
            this.setState({
                formData: {
                    ...this.state.formData,
                    [array]: data
                }
            }, 
            () => {
                this.calculateOverallTotals();
            });
        }
        else {
            // Remove row - just calculate overall totals
            this.calculateOverallTotals();
        }
    }

    calculateOverallTotals = () => {
        let overallPrice = 0;
        this.state.ppo.parts_order_details.forEach((row) => {
            if(!isNaN((row.ppo_detail_total_price*1)) && row.ppo_detail_part_id > 0){
                overallPrice = overallPrice + (row.ppo_detail_total_price*1);
            }
        });
        this.state.formData.otherRows.forEach((row) => {
            if(!isNaN((row.totalPrice*1))){
                overallPrice = overallPrice + (row.totalPrice*1);
            }
        });
        this.setState({
            formData: {
                ...this.state.formData,
                overallPrice: overallPrice.toFixed(2)
            }
        });
    }

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
            formData: {
                ...this.state.formData,
                previewPPO: false,
                previewSchedule: false
            }
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false,
            formData: {
                ...this.state.formData,
                previewPPO: false,
                previewSchedule: false
            }
        });
        this.submit();
    }
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    submit = () => {
        API.post(`/partsOrdering/${this.props.match.params.id}/updateOtherItems`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){
                
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    successRedirect: true,
                    successRedirectID: result.data.ppoID
                });
            }
            this.props.scrollToTop();
        });
    }

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    }
       
    render() {
        const { classes } = this.props;
        const { formErrors, ppo } = this.state;
        if (this.state.successRedirect === true) {
            return <Redirect 
                        to={{
                            pathname:`/parts-order/view/${this.state.successRedirectID}`,
                            state: {successMsg: 'You have successfully amended this parts order'}
                        }}
                    />
        }
        if (this.state.redirect === true) {
            return <Redirect to="/parts-order/search/" />
        }
        return (
            <React.Fragment>
                {this.state.isLoading ? (
                    <LoadingCircle />
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Edit Other Items
                            </Typography>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} lg={4}>
                                            <Typography variant="subtitle1">
                                                Purchase Order Number
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={8}>
                                            <Typography variant="subtitle1">
                                                {ppo.full_reference}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className='xs-only'><br/></Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Typography variant="subtitle1" gutterBottom>
                                            FAO
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={8}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                {ppo.ppo_supplier_contact}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className='xs-only'><br/></Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Typography variant="subtitle1" gutterBottom>
                                            FAO Email
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={8}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                {ppo.supplier_contact && ppo.supplier_contact.supp_cont_email}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className='xs-only'><br/></Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Typography variant="subtitle1" gutterBottom>
                                            Purchase Order Date
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={8}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                {moment(ppo.ppo_date).format('DD/MM/YYYY')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className='xs-only'><br/></Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Typography variant="subtitle1" gutterBottom>
                                            Order Date
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={8}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                {moment(ppo.ppo_order_date).format('DD/MM/YYYY')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className='xs-only'><br/></Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Order Placed By
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={8}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                {ppo.ppo_placed_by}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className='xs-only'><br/></Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Typography variant="subtitle1" gutterBottom>
                                            Payment Terms
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={8}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                {ppo.ppo_supplier_payment_terms}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <PaddedPaper>
                                    <Grid container spacing={0}>
                                        <Grid item className='not-xs' lg={4}>
                                            <Typography variant="subtitle1">
                                                Company
                                            </Typography>
                                        </Grid>                                
                                        <Grid item xs={12} lg={8}>
                                            <Typography variant="subtitle1">
                                                {ppo.ppo_supplier_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className='xs-only'><br/></Grid>
                                        <Grid item className='not-xs' lg={4}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Address
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={8}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                {ppo.ppo_supplier_address && ppo.ppo_supplier_address.split('\n').map((item, key) => {
                                                    return <span key={key}>{item}<br/></span>
                                                })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h6" gutterBottom>
                                    Part Items
                                </Typography>
                                <div className={classes.responsiveTable}>
                                    <Table style={{minWidth: 900}}>
                                        <TableBody>
                                            {ppo.parts_order_details && ppo.parts_order_details.map((item, idx) => {
                                                if(item.ppo_detail_part_id > 0) {
                                                    return (
                                                        <React.Fragment key={`pr${idx}`}>
                                                            <TableRow>
                                                                <TableCell className={classes.td} style={{width:'30%'}}>
                                                                    <TextField
                                                                        value={item.ppo_detail_part_number + ' - ' + item.ppo_detail_description}
                                                                        label="Part"
                                                                        fullWidth
                                                                        disabled
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                    <TextField
                                                                        name="quantity"
                                                                        value={item.ppo_detail_qty}
                                                                        label="Quantity"
                                                                        type="number"
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                    <TextField
                                                                        name="unitPrice"
                                                                        value={item.ppo_detail_unit_price}
                                                                        label="Unit Price"
                                                                        type="number"
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                    <TextField
                                                                        name="totalPrice"
                                                                        value={item.ppo_detail_total_price}
                                                                        label="Total Price"
                                                                        type="number"
                                                                        disabled
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell rowSpan={2} className={classes.td} style={{padding:0}}>
                                                                    <TextField
                                                                        name="notes"
                                                                        value={item.ppo_detail_notes}
                                                                        label="Notes"
                                                                        fullWidth
                                                                        multiline
                                                                        rows="5"
                                                                        variant="outlined"
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td} rowSpan={2}>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow key={idx}>
                                                                <TableCell className={classes.td} style={{width:'30%'}}>
                                                                    <TextField
                                                                        name="nominalCode"
                                                                        value={(item.nominal_code && `${item.nominal_code.nominal_code} - ${item.ppo_detail_nominal_name} (${item.nominal_code.category.nom_cat_name})`) || ''}
                                                                        label="Nominal Code"
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                    <TextField
                                                                        name="supplierReference"
                                                                        value={item.ppo_detail_supplier_reference}
                                                                        label="Supplier Reference"
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                    <TextField
                                                                        name="drawingNo"
                                                                        label="Drawing No."
                                                                        value={item.ppo_detail_drawing_no}
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                name="attachPO"
                                                                                checked={item.ppo_detail_attach_drawing === 1}
                                                                                value="true"
                                                                                disabled
                                                                                color='primary'
                                                                            />
                                                                        }
                                                                        label="Attach to PO"
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell className={classes.td} colSpan={6}>
                                                                    <Divider style={{border:'5px solid #f2f2f2'}} />
                                                                </TableCell>
                                                            </TableRow>
                                                        </React.Fragment>
                                                    );
                                                } else {
                                                    return null
                                                }
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>

                                <Typography variant="h6" gutterBottom style={{marginTop: 20}}>
                                    Other Items
                                </Typography>
                                <form noValidate autoComplete="off">
                                    <div className={classes.responsiveTable}>
                                        <Table style={{minWidth: 900}}>
                                            <TableBody>
                                                {this.state.formData.otherRows.map((item, idx) => {
                                                    return (
                                                        <React.Fragment key={`or_${idx}`}>
                                                            <TableRow>
                                                                    <TableCell className={classes.td} style={{width:'30%'}}>
                                                                        <FormControl fullWidth>
                                                                            <AutoCompleteSelect 
                                                                                options={this.state.defaultItemsList} 
                                                                                value={item.description}
                                                                                label="Description"
                                                                                error={formErrors && formErrors['otherRows|description|'+idx] && true}
                                                                                errorText={formErrors && formErrors['otherRows|description|'+idx]}
                                                                                onChange={this.handleSelectRowChange(idx, 'description', 'otherRows')}
                                                                                fullWidth
                                                                            />
                                                                        </FormControl>
                                                                    </TableCell>
                                                                    <TableCell className={classes.td} style={{width:'30%'}}>
                                                                        <FormControl fullWidth>
                                                                            <AutoCompleteSelect 
                                                                                options={item.nominalList} 
                                                                                value={item.nominalCode}
                                                                                label='Nominal Code *'
                                                                                error={formErrors && formErrors['otherRows|nominalCode|'+idx] && true}
                                                                                errorText={formErrors && formErrors['otherRows|nominalCode|'+idx]}
                                                                                onChange={this.handleSelectRowChange(idx, 'nominalCode', 'otherRows')}
                                                                                fullWidth
                                                                            />
                                                                        </FormControl>
                                                                    </TableCell>
                                                                    <TableCell className={classes.td}>
                                                                        <TextField
                                                                            name="quantity"
                                                                            value={item.quantity}
                                                                            label="Quantity"
                                                                            error={formErrors && formErrors['otherRows|quantity|'+idx] && true}
                                                                            helperText={formErrors && formErrors['otherRows|quantity|'+idx]}
                                                                            onChange={this.handleRowChange(idx, 'otherRows')}
                                                                            onBlur={this.handleRowChange(idx, 'otherRows', 3)}
                                                                            type="number"
                                                                            inputProps={{ min: 0 }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell className={classes.td}>
                                                                        <TextField
                                                                            name="unitPrice"
                                                                            value={item.unitPrice}
                                                                            label="Unit Price"
                                                                            error={formErrors && formErrors['otherRows|unitPrice|'+idx] && true}
                                                                            helperText={formErrors && formErrors['otherRows|unitPrice|'+idx]}
                                                                            onChange={this.handleRowChange(idx, 'otherRows')}
                                                                            onBlur={this.handleRowChange(idx, 'otherRows', 5)}
                                                                            type="number"
                                                                            inputProps={{ min: 0 }}
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell className={classes.td}>
                                                                        <TextField
                                                                            name="totalPrice"
                                                                            value={item.totalPrice}
                                                                            label="Total Price"
                                                                            error={formErrors && formErrors['otherRows|totalPrice|'+idx] && true}
                                                                            helperText={formErrors && formErrors['otherRows|totalPrice|'+idx]}
                                                                            onChange={this.handleRowChange(idx, 'otherRows')}
                                                                            onBlur={this.handleRowChange(idx, 'otherRows', 2)}
                                                                            type="number"
                                                                            disabled
                                                                            fullWidth
                                                                        />
                                                                    </TableCell>
                                                                    
                                                                    <TableCell className={classes.td}>
                                                                        <IconButton onClick={this.handleRemoveRow(idx, 'otherRows')}>
                                                                            <FALightIcon icon='times' noMargin button />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                        </React.Fragment>
                                                    );
                                                })}
                                                <TableRow>
                                                    <TableCell className={classes.td} colSpan={5}>
                                                        <Button 
                                                            onClick={this.handleAddRow('otherRows')}
                                                            variant="contained"
                                                            color="primary"
                                                            size="small">
                                                            Add Row
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </form>
                                {formErrors && formErrors['otherRows'] && formErrors['otherRows'].split('\n').map((item, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <Typography component={"div"} style={{color: colors.red}}>
                                                {item}<br/>
                                            </Typography>
                                        </React.Fragment>
                                    )
                                })}
                                <Typography variant="h6" style={{display: 'flex', justifyContent:'flex-end', fontWeight: 'bold' }}>
                                    Total Price: £{this.state.formData.overallPrice}
                                </Typography>
                            </PaddedPaper>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Grid container>
                                        <Grid item xs={12} lg={6}>
                                            <Typography variant="h6" gutterBottom>
                                                Invoice Details
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <Typography variant="h6" gutterBottom>
                                                Delivery Details
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} lg={6}>
                                            <Grid container>
                                                <Grid item xs={12} lg={4}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Invoice Address
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={8}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {ppo.ppo_invoice_address && ppo.ppo_invoice_address.split('\n').map((item, key) => {
                                                            return <span key={key}>{item}<br/></span>
                                                        })}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} className='xs-only'><br/></Grid>
                                                <Grid item xs={12} lg={4}>
                                                    <Typography variant="subtitle1">
                                                        Invoice FAO
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}lg={8}>
                                                    <Typography variant="subtitle1">
                                                        {ppo.ppo_invoice_fao}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} className='xs-only'><br/></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <Grid container>
                                                <Grid item xs={12} lg={4}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Delivery Address
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={8}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {ppo.ppo_delivery_address && ppo.ppo_delivery_address.split('\n').map((item, key) => {
                                                            return <span key={key}>{item}<br/></span>
                                                        })}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} className='xs-only'><br/></Grid>
                                                <Grid item xs={12} lg={4}>
                                                    <Typography variant="subtitle1">
                                                        Delivery FAO
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={8}>
                                                    <Typography variant="subtitle1">
                                                        {ppo.ppo_delivery_fao}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <Typography variant="h6" gutterBottom>
                                        Email Details
                                    </Typography>
                                    <Textarea
                                        id="emailText"
                                        name="emailText"
                                        label="Email Text"
                                        value={ppo.ppo_email_text}
                                        rows={10}
                                        disabled
                                        onChange={this.handleChange}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                    <TextField
                                        value={ppo.ppo_approval_by || ''}
                                        label="Approval Required By"
                                        fullWidth
                                        disabled
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                    {formErrors && formErrors.generic && (
                                        <React.Fragment>
                                            <Typography component={"div"} style={{color: colors.red}}>
                                                {formErrors.generic}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                    <div className='buttonRow'>
                                        <BackButton props={this.props} />
                                        <Button onClick={this.handleConfirmationOpen}
                                                variant="contained"
                                                color="primary"
                                            >
                                            Update
                                        </Button>
                                    </div>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <ConfirmationDialog 
                        open={this.state.confirmationOpen} 
                        success={this.handleConfirmationSuccess} 
                        close={this.handleConfirmationClose} 
                        title="Amend This Parts Order?" 
                        message="Are you sure you want to amend this parts order?"
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen}
                            onClose={this.handleSnackbarClose}
                            message='You have successfully added a new parts purchase order'
                        />
                    </Grid>
                )}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(UpdatePartsOrderOtherItems);
