import React       from 'react';
import moment      from 'moment';
import uuidv4      from 'uuid/v4';
import _           from 'lodash';
import { connect } from 'react-redux';
import { Link }    from 'react-router-dom';
import ReactDOM    from 'react-dom';
import API         from 'API';

import {DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE, API_URL} from 'Constants'

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx-js-style";

import { Button, Checkbox, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid,
        IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Table, TableBody, TableCell, TableFooter, TableHead,
        TableRow, Tooltip, Typography, TextField, FormControl } from '@material-ui/core';
import MaterialLink                                             from '@material-ui/core/Link';
import { withStyles }                                           from '@material-ui/core/styles';

import { handleScrollToTop } from 'Actions/UI/UI';
import { setPersistence }    from 'Actions/StatePersistence/StatePersistence';

import FABrandIcon             from 'Components/Common/Icons/FontAwesome/FABrandIcon';
import FASolidIcon             from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import FADataTableIcon         from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FADuoIcon               from 'Components/Common/Icons/FontAwesome/FADuoIcon';
import Pagination              from 'Components/Common/Pagination/Pagination';
import CopyToClipboardDatagrid from 'Components/Common/CopyToClipboard/CopyToClipboardDatagrid';
import LoadingCircle           from 'Components/Common/LoadingCircle/LoadingCircle';
import DatePicker              from 'Components/Common/DatePickers/DatePicker';
import AllIcon                 from 'Components/Common/Icons/AllIcon';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect'; 
import ImageWithError          from 'Components/Common/ImageWithError/ImageWithError';

import { cloneObject, excelColumnIndexToLetter, excelFitToColumns, unpackObjectUsingString, checkTrueFalse, convertRealNumber } from 'Functions/MiscFunctions';
import { getToken }                                                                                                             from 'Functions/AuthFunctions';

import { pdfFromBase64 }            from 'Helpers/PDFHelper';
import { colors }                   from 'Helpers/ColourHelper';
import icons                        from 'Helpers/IconHelper';
import { clenyDate, formatSeconds } from 'Helpers/TimeHelper';
import { currency }                 from 'Helpers/NumberHelper';
import ActionMenu                   from 'Components/Common/ActionMenu/ActionMenu';

const styles = theme => ({
    "@global": {
        ".a, .a:active, a:visited": {
            textDecoration: 'none',
        }
    },
    headerOptions: {
    },
    alternatingRowColours: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.background.default,
      },
    },
    listIcon: {
        minWidth: 24,
        marginLeft: 8
    },
    searchTextField: {
        marginLeft: 6, 
        marginTop: 8,
        borderColor: theme.palette.grey[100]
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    iconButton: {
        fontSize: 14,
        "&:hover": {
            backgroundColor: 'transparent'
        }
    },
    hover: {
        textDecoration: 'none',
        "&:hover": {
          textDecoration: 'underline',
        }
    },
    sticky: {
        position: 'fixed',
        top: 30,
        zIndex:500
    }
});

const TableHeaderCell = withStyles(theme => ({
    head: {
        backgroundColor: colors.blue,
        color: theme.palette.common.white,
        padding: '8px 12px',
    },
    body: {
      fontSize: 14,
      whiteSpace: 'nowrap'
    }
}))(TableCell);

const SortableTableHeaderCell = withStyles(theme => ({
    head: {
        backgroundColor: colors.blue,
        color: theme.palette.common.white,
        padding: '8px 12px',
        "&:hover": {
            cursor: 'pointer',
        }
    },
    body: {
      fontSize: 14,
      whiteSpace: 'nowrap'
    }
}))(TableCell);

const TableBodyCell = withStyles(theme => ({
    body: {
        padding: '12px 12px',
        whiteSpace: 'nowrap',
        color: 'inherit'
    }
}))(TableCell);

const TableBodyCellDense = withStyles(theme => ({
    body: {
        padding: '4px 12px',
        whiteSpace: 'nowrap',
        color: 'inherit'
    }
}))(TableCell);

const TableBodyCellNoPadding = withStyles(theme => ({
    body: {
        padding: 0,
        color: 'inherit'
    }
}))(TableCell);

const TableBodyCellCard = withStyles(theme => ({
    body: {
        padding: 0,
        borderBottom: 'none',
        color: 'inherit'
    }
}))(TableCell);

const initialState = () => ({
    activeColumns: [],
    contextMenu: {
        columnMenu: false,
        filterMenu: false,
        helpAndSupportMenu: false,
        printMenu: false,        
        excelMenu: false,
        pdfMenu: false,
        csvMenu: false
    },
    exportData: {
        allRows: [],
        displayedRows: [],
        headers: [],
    },
    pagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    },
    responsiveActionsColumns: null,
    responsiveActionsRow: null,
    rows: [],
    ogRows: [],
    sort: {
        dataRef: '',
        dataOrder: '',
    },
    filters: {},
    sticky: false,
    stickyWidth: 0,
    dropped: {},
    truncate: '100%',
})

class CiDataTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.filterMenu = React.createRef();
        this.columnMenu = React.createRef();
        this.excelMenu = React.createRef();
        this.pdfMenu = React.createRef();
        this.csvMenu = React.createRef();
        this.printMenu = React.createRef();
        this.helpAndSupportMenu = React.createRef();
        this.state = this.getInitialTableState();
        this.tableRef = React.createRef();
    }
    componentDidMount = () => {
        !this.hasTableState() && this.configureDataTable();
        if (this.props.config?.sticky)  window.addEventListener('scroll', this.handleScroll, true);
    }
    componentWillUnmount = () =>{
        if (this.props.config?.sticky)  window.removeEventListener('scroll', this.handleScroll, true);
    }
    componentDidUpdate = (prevProps, prevState) => {
        
        if (!this.props.ui?.device?.isTablet) {
            if (this.tableRef.current && _.find(this.props.columns, i => !!i?.truncate)){
                //only check if we have a truncate column
                this.isOverSized();
            }
        }
        
        if(this.props.config?.persistenceId !== prevProps.config?.persistenceId) {
            if(!this.hasTableState()) {
                this.configureDataTable();
            } else {
                this.setState({
                    ...this.getInitialTableState()
                })
            }
            return;
        }

        if (!this.props.ui?.device?.isTablet) {
            if((JSON.stringify(prevState.pagination) !== JSON.stringify(this.state.pagination)) || (JSON.stringify(prevState.sort) !== JSON.stringify(this.state.sort))) {
                this.saveTableState();
                if (_.find(this.props.columns, i => !!i?.truncate)){
                    this.isUnderSized();
                }
            }
        }

        if(JSON.stringify(this.props.rows) !== JSON.stringify(prevProps.rows) && JSON.stringify(this.props.rows) !== JSON.stringify(this.state.rows)) {
            let {
                rows
            } = this.props;

            let {
                sort: {
                    dataRef,
                    dataOrder
                }
            } = this.state;

            dataOrder = dataOrder === '' ? 'DESC' : dataOrder;

            if(dataRef !== '') {
                
                if(!_.isEmpty(rows)) {
                    rows.sort((a,b) => {
                        let sourceA = this.getFieldData(dataRef, a);
                        let sourceB = this.getFieldData(dataRef, b);
                        sourceA = sourceA === null || sourceA === "" ? '-' : sourceA; 
                        sourceB = sourceB === null || sourceB === "" ? '-' : sourceB; 
                        if(!isNaN(sourceA) && !isNaN(sourceB)) {
                            return parseInt(sourceA) - parseInt(sourceB)
                        } else {
                            return sourceA.toString().localeCompare(sourceB) 
                        }
                    })
                }

                if(dataOrder === 'ASC')
                    rows.reverse();
            }

            this.setState({
                sort: {
                    ...this.state.sort,
                    dataOrder,
                    dataRef: (this.props.config?.options?.defaultSort !== prevProps?.config?.options?.defaultSort) ? (this.state.sort?.dataRef === prevProps?.config?.options?.defaultSort ? (this.props.config?.options?.defaultSort ?? '') : initialState().sort.dataRef ) : this.state.sort?.dataRef,
                },
                pagination: {
                    ...this.state.pagination,
                    page: this.props.config?.page ? this.props.config.page : 0
                },
                rows,
                ogRows:rows,
            }, () => {
                this.saveTableState();
                if(this.props.config.options && this.props.config.options.export)
                    this.handleExportData();
            })

        }
        if (this.props.config.options && this.props.config.options.export ) {
            if (JSON.stringify(prevProps.columns) !== JSON.stringify(this.props.columns)){
                this.updateExportData();
            }
        }        
    }
    updateExportData = () => {
        let activeColumns = [], 
            exportHeaders = [];

        _.each(this.props.columns, column => {

            if(!column.actions && column.heading && column.dataRef) {

                exportHeaders.push({ 
                    label: column.heading, 
                    key: column.dataRef, 
                    enabled: true 
                })

                activeColumns[column.dataRef] = true;

            }

        });

        this.setState({
            activeColumns,
            exportData: {
                ...this.state.exportData,
                headers: exportHeaders
            }
        },this.handleExportData)
    }
    getInitialTableState = () => ({
        ...initialState(),
        ...(this.hasTableState() && this.props.statePersistence?.[`dataTable:${this.props?.config?.persistenceId}`])
    })
    hasTableState = () => {
        return this.props?.config?.persistenceId ? !!this.props.statePersistence?.[`dataTable:${this.props?.config?.persistenceId}`] : false;
    }    
    saveTableState = () => {
        this.props?.config?.persistenceId && this.props?.config?.persistenceId !== null && this.props.setPersistence(`dataTable:${this.props?.config?.persistenceId}`, this.state);
    }    
    configureDataTable = () => {

        let activeColumns = [], 
            exportHeaders = [];

        _.each(this.props.columns, column => {

            if(!column.actions && column.heading && column.dataRef) {

                exportHeaders.push({ 
                    label: column.heading, 
                    key: column.dataRef, 
                    enabled: true 
                })

                activeColumns[column.dataRef] = true;

            }

        });

        this.setState({
            ...this.getInitialTableState(),
            activeColumns,
            exportData: {
                ...this.state.exportData,
                headers: exportHeaders
            },
            pagination: {
                ...this.state.pagination,
                rowsPerPage: this.props.config.rowsPerPage ? this.props.config.rowsPerPage : this.state.pagination.rowsPerPage,
            },
            rows: this.props.rows,
            ogRows: this.props.rows,
            sort: {
                ...this.state.sort,
                dataRef: this.state.sort.dataRef === '' ? (this.props.config?.options?.defaultSort ?? '') : ''
            }
        },
        () => {
            if(this.props.config.options && this.props.config.options.export)
                this.handleExportData();
        })
        
    }
    handleChangePage = (event, page) => {
        this.setState({ 
            pagination: { 
                ...this.state.pagination, 
                page: page 
            } 
        },
        () => { 
            this.props.handleScrollToTop();
            this.props.config.gridPageChanged && this.props.config.gridPageChanged(page)
            if(this.props.config.options && this.props.config.options.export)
                this.handleExportData();
        });
    };  
    handleChangeRowsPerPage = event => {
        this.setState({ pagination: { ...this.state.pagination, rowsPerPage: event.target.value, page: 0 } }, this.props.config.gridRowsPerPageChanged && this.props.config.gridRowsPerPageChanged(event.target.value));
    };
    handleResponsiveActionsOpen = (columns, row) => {
        this.setState({
            ...this.state,
            responsiveActionsColumns: columns,
            responsiveActionsRow: row
        })
    }
    handleResponsiveActionsClose = (e) => {
        this.setState({
            ...this.state,
            responsiveActionsColumns: null,
            responsiveActionsRow: null
        })
    }    
    handleColumnToggle = (dataRef) => {
        let exportHeaders = [];
        let activeColumns = this.state.activeColumns;
        activeColumns[dataRef] = !activeColumns[dataRef];
        _.each(this.props.columns, column => {
            if(activeColumns[column.dataRef])
                exportHeaders.push({ label: column.heading, key: column.dataRef })
        }); 
        this.setState({
            activeColumns: activeColumns,
            exportData: {
                ...this.state.exportData,
                headers: exportHeaders
            }
        })
    }
    handleExportData = () => {
        const { config, columns } = this.props;
        const { pagination } = this.state;  
        let allRows = [];
        let displayedRows = [];
        let rows = _.values(cloneObject(this.state.rows));            
        if(typeof rows !== "undefined" || rows.length > 0) {
            allRows = rows;
        }         
        _.each(columns, field => {
            if(field.fieldFormat) {
                allRows = _.map(allRows, row => {                
                    row[field.dataRef] = this.handleExportFormat(field.fieldFormat, row[field.dataRef])
                    return row;
                })
            }
        });    
        displayedRows = (config.pagination && allRows.slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage)) || allRows; 
        this.setState({
            exportData: {
                ...this.state.exportData,
                allRows: allRows,
                displayedRows: displayedRows
            }
        })
    }
    handleExportFormat = (format, data) => {    
        if( format === 'boolean') {
            if(data === 'active')
                data = 'Active'                        
            if(data === 'inactive')
                data = 'Inactive'
            if(data === 'true' || data === true)
                data = 'Y'                        
            if(data === 'false' || data === false)
                data = 'N'                                       
            if(data > 0)
                data = 'Y'                                    
            if(data === 0)
                data = 'N'       
        } else if (format === 'datetime') {
            data = moment(new Date(data)).format("H:mm on DD/MM/YYYY")
        } 
        else if (format === 'date') {
            data = moment(new Date(data)).format("DD/MM/YYYY")
        }
        else if (format.startsWith('percentage')) {
            data = parseFloat(data).toFixed(format.includes(':') ? format.split(":")[1] : 2) + '%'
        } 
        else if(format.startsWith('decimal')) {
            data = parseFloat(data).toFixed(format.includes(':') ? format.split(":")[1] : 2)
        } 
        return data;
    }
    handleExportMenuClick = (menu, type) => {
        /* Close Export Menu */
        this.setState({
            contextMenu: initialState().contextMenu,
            exportData: {
                ...this.state.exportData,
                [menu]: !this.state.exportData[menu]
            }
        }, 
            () => {
                /* 
                 * CSV downloads are handled in-line via <CSVLink /> and pass a false value for the type variable here when selected
                 * If type is not false then the user has requested a pdf download; type will be either allRows (all) or displayedRows (current page)
                 */
                if(type !== false) {
                    const { config } = this.props;
                    this.handleExportPDF(type, `${config.options.export.name}-${type === 'allRows' ? 'All' : `Page${(this.state.pagination.page + 1)}`}-${moment().format("DD/MM/YYYY[-]HH:mm:ss")}.pdf`)
                }
            }
        )
    } 
    handleExportPDF = (type, filename) => {
        const { config } = this.props;
        API.post('/reports/pdf', {
            title: config.options.export.title,
            rows: this.state.exportData[type],
            headers: this.state.exportData.headers
        })
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, filename);
            }
        });
    }
    handleMenuToggle = (menu) => {
        this.setState({
            contextMenu: {
                ...initialState().contextMenu,
                [menu]: !this.state.contextMenu[menu]
            }
        })
    }
    handlePrint = type => {
        const { config } = this.props;
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", API_URL + '/reports/print');
        form.setAttribute("target", "printReport");

        var token = document.createElement("input"); 
        token.setAttribute("type", "hidden");
        token.setAttribute("name", "token");
        token.setAttribute("value", btoa(getToken()));
        form.appendChild(token);

        var title = document.createElement("input"); 
        title.setAttribute("type", "hidden");
        title.setAttribute("name", "title");
        title.setAttribute("value", btoa(config.options.export.title));
        form.appendChild(title);

        var headers = document.createElement("input"); 
        headers.setAttribute("type", "hidden");
        headers.setAttribute("name", "headers");
        headers.setAttribute("value", JSON.stringify(this.state.exportData.headers));
        form.appendChild(headers);

        var rows = document.createElement("input"); 
        rows.setAttribute("type", "hidden");
        rows.setAttribute("name", "rows");
        rows.setAttribute("value", JSON.stringify(this.state.exportData[type]));
        form.appendChild(rows);
        
        document.body.appendChild(form);
        window.open('', 'printReport');
        form.submit();
        document.body.removeChild(form);
    }
    handleReset = () => {
        let activeColumns = []
        _.each(this.props.columns, column => {     
            if(!column.actions) { 
                activeColumns[column.dataRef] = true;
            }
        });
        this.setState({
            activeColumns: activeColumns,
            sort: {
                dataRef: '',
                dataOrder: '',
            }
        },
        () => {
            const { config } = this.props;
            config.options.reset()
        })
    }
    handleSort = dataRef => {
        let rows = this.state.rows;
        let dataOrder = this.state.sort.dataOrder !== '' ? this.state.sort.dataOrder : 'DESC';
        if(dataRef === this.state.sort.dataRef) {
            dataOrder = dataOrder === 'DESC' ? 'ASC' : 'DESC';
            rows.reverse();
        } else {
            //rows = _.sortBy(rows, [data => this.getFieldData(dataRef,data)],[dataOrder]);
            if (_.isFunction(dataRef)){
                rows = _.sortBy(rows, [data => dataRef(data)],[dataOrder]);
            } else {
                const sortRef = dataRef.split('.');
                if(!_.isEmpty(rows)) {
                    rows.sort((a,b) => {
                        let sourceA = a[sortRef[0]];
                        let sourceB = b[sortRef[0]];
                        if(sortRef.length > 1) {
                            for(let i = 1; i < sortRef.length; i++) {
                                sourceA = sourceA[sortRef[i]];
                                sourceB = sourceB[sortRef[i]];
                            }
                        }
                        sourceA = sourceA === null || sourceA === "" ? '-' : sourceA; 
                        sourceB = sourceB === null || sourceB === "" ? '-' : sourceB; 
                        if(!isNaN(sourceA) && !isNaN(sourceB)) {
                            return sourceA - sourceB
                        } else {
                            return sourceA.toString().localeCompare(sourceB) 
                        }
                    })
                }
            }
        }
        this.setState({
            sort: {
                dataRef: dataRef,
                dataOrder: dataOrder
            },
            rows: rows,
            pagination: {
                ...this.state.pagination,
                page: 0
            } 
        }, 
        () => {
            this.saveTableState();
            if(this.props.config.options && this.props.config.options.export)
                this.handleExportData();
        });
    }

    
    handleExportMenuClick = (menu, type, exportType) => {
        /* Close Export Menu */
        this.setState(
            {
                exportData: {
                    ...this.state.exportData,
                    [menu]: !this.state.exportData[menu],
                },
            },
            () => {
                const { config } = this.props;

                if (type === "pdf") {
                    this.handleExportPDF(
                        exportType,
                        `${config.options.export.name}-${
                            exportType === "allRows" ? "All" : `Page${this.state.pagination.page + 1}`
                        }-${moment().format("DD/MM/YYYY[-]HH:mm:ss")}.pdf`
                    );
                } else if (type === 'csv') {
                    this.handleExportCSV(
                        exportType,
                        `${config.options.export.name}-${
                            exportType === "allRows" ? "All" : `Page${this.state.pagination.page + 1}`
                        }-${moment().format("DD/MM/YYYY[-]HH:mm:ss")}.csv`
                    );
                } else {
                    this.handleExportExcel(
                        exportType,
                        `${config.options.export.name}-${
                            exportType === "allRows" ? "All" : `Page${this.state.pagination.page + 1}`
                        }-${moment().format("DD/MM/YYYY[-]HH:mm:ss")}.xlsx`
                    );
                }
            }
        );
    };

    handleExportCSV(type, fileName) { 
        let header = [],
        headerArr = [],
        exportData = [],
        headers = this.state.exportData?.headers ?? [];

        /* Get data */
        _.each(this.state.exportData[type], (row) => {
            let exportRow = [];
            _.each(headers, (field) => {
                exportRow.push(row[field.key]);
            });
            exportData.push(exportRow);
        });

        /* Get headings */
        _.each(headers, (field, idx) => {
            header.push(field.label);
            headerArr.push(`${field.label}`);
        });

        let csvContent = "data:text/csv;charset=utf-8," +
        [
            headerArr,
            ...exportData
        ].map(e => e.join(",")).join("\n");

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName);
        document.body.appendChild(link); // Required for FF
        
        link.click();
        link.remove();
    }

    handleExportExcel(type, fileName) { const { config } = this.props;

    let header = [],
        headerArr = [],
        exportData = [],
        headers = this.state.exportData?.headers ?? [],
        wb = XLSX?.utils?.book_new();

    /* Get data */
    _.each(this.state.exportData[type], (row) => {
        let exportRow = [];
        _.each(headers, (field) => {
            exportRow.push(row[field.key]);
        });
        exportData.push(exportRow);
    });

    /* Get headings */
    _.each(headers, (field, idx) => {
        header.push(field.label);
        headerArr.push(`${field.label}XXX`);
    });

    /* Create worksheet */
    const ws = XLSX.utils.json_to_sheet([]);

    /* Add headers to sheet */
    XLSX.utils.sheet_add_aoa(ws, [header]);

    /* Add data to sheet */
    XLSX.utils.sheet_add_json(ws, exportData, { origin: "A2", skipHeader: true });

    /* Get range of cells */
    const range = XLSX.utils.decode_range(ws["!ref"]);

    /* Add filters to columns */
    ws['!autofilter'] = {
        ref: `A1:${excelColumnIndexToLetter(range.e.c)}1`,
    }

    /* Set column with to data set size */
    ws["!cols"] = excelFitToColumns([...exportData, headerArr]);

    /* Loop rows for formatting */
    for (let x = range.s.r + 1; x <= range.e.r; ++x) {

        /* Loop columns for formatting */
        for (let y = range.s.c; y <= range.e.c; ++y) {

            /* Check reference exists */
            if(ws[`${excelColumnIndexToLetter(y)}${x}`]) {

                /* Set cell styles */
                ws[`${excelColumnIndexToLetter(y)}${x}`].s = {
                    font: {
                        bold: x === 1 ? true : false,
                        name: 'Arial',
                        sz: 10,
                    },
                    border: x === 1 ? {
                        bottom: {
                            style: "thin"
                        }
                    } : undefined
                }

            }

        }

    }

    /* Add sheet to workbook */
    XLSX.utils.book_append_sheet(wb, ws, config.options.export.name);

    /* Convert to an excel object */
    const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
    });

    /* Convert excel object to a blob */
    const data = new Blob([excelBuffer], {
        type: "xlsx",
    });

    /* Output blob to browser */
    FileSaver.saveAs(data, fileName?.toLowerCase?.());
}

    renderOptions = () => {
        const { classes, columns, config } = this.props;
        const { activeColumns, contextMenu, exportData, pagination } = this.state;
            return (
                <Grid container alignItems='center'>
                    {!this.props.ui.device.isMobile && (
                        <Grid item md={6}>
                            <Typography variant="h6">
                                    {/*Search Results*/}
                            </Typography>
                        </Grid>
                    )}
                    <Grid className={classes.headerOptions} item xs={12} md={6} align={!this.props.ui.device.isMobile ? 'right' : 'center'}  style={{padding:0, paddingBottom: 6}}>
                        <React.Fragment>   
                            {config.options && config.options.reset && (
                                <Tooltip title="Reset">
                                    <IconButton onClick={() => this.handleReset()} style={{marginLeft: 12}}>
                                        <FADataTableIcon icon="undo" size="small" noMargin button />
                                    </IconButton>
                                </Tooltip>
                            )}                             
                            {config.options && config.options.dataRef && ( 
                                <React.Fragment>
                                    <Tooltip title="Display Columns">
                                        <IconButton ref={this.columnMenu} onClick={() => this.handleMenuToggle('columnMenu')} style={{marginLeft: 12}}>
                                            <FADataTableIcon icon="line-columns" size="small" noMargin button />
                                        </IconButton>
                                    </Tooltip>
                                    <Popper open={contextMenu.columnMenu} anchorEl={this.columnMenu.current}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={() => this.handleMenuToggle('columnMenu')}>
                                                <MenuList style={{padding:24}}>
                                                    <Typography variant="subtitle1" color="primary">Display Columns</Typography><br />
                                                    {_.map(columns, (column, idx) => {
                                                        if(!column.actions && column.heading)
                                                            return (
                                                                <FormControlLabel
                                                                    checked={activeColumns[column.dataRef]} 
                                                                    control={<Checkbox color="primary" onChange={() => this.handleColumnToggle(column.dataRef)}/>}
                                                                    key={idx}
                                                                    label={<Typography variant="body2" component="span">{column.heading}</Typography>}
                                                                    style={{display: 'block', fontSize: 5, color: '#000'}}        
                                                                    disableTypography
                                                                />
                                                            )
                                                    })}  
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Popper>     
                                </React.Fragment>                           
                            )}  
                            {config.options && config.options.export && config.options.export.print && ( 
                                <React.Fragment>
                                    <Tooltip title="Print">
                                        <IconButton ref={this.printMenu} onClick={() => this.handleMenuToggle('printMenu')} style={{marginLeft: 12}}>
                                            <FADataTableIcon icon="print" size="small" noMargin button />
                                        </IconButton>
                                    </Tooltip>
                                    <Popper open={contextMenu.printMenu} anchorEl={this.printMenu.current}>
                                        <Paper style={{width: 200, maxWidth: 300}}>
                                            <ClickAwayListener onClickAway={() => this.handleMenuToggle('printMenu')}>
                                                <MenuList>
                                                    <Typography variant="subtitle1" style={{padding: '9px 24px'}} color="primary">Print</Typography>
                                                    <MenuItem onClick={() => { this.handleMenuToggle('printMenu'); this.handlePrint('allRows')}}>  
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <FADataTableIcon icon="print" size="small" noMargin />
                                                        </ListItemIcon>
                                                        <Typography variant="body2">
                                                            All Results
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => { this.handleMenuToggle('printMenu'); this.handlePrint('displayedRows')}}>  
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <FADataTableIcon icon="print" size="small" noMargin />
                                                        </ListItemIcon>  
                                                        <Typography variant="body2">
                                                            Current Page ({(pagination.page + 1)})
                                                        </Typography> 
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Popper>
                                </React.Fragment>
                            )}
                            {config.options && config.options.export && config.options.export.pdf && (
                                <React.Fragment>
                                    <Tooltip title="Export to PDF">
                                        <IconButton ref={this.pdfMenu} onClick={() => this.handleMenuToggle('pdfMenu')} style={{marginLeft: 12}}>
                                            <FADataTableIcon icon="file-pdf" noMargin button style={{color: colors.red}} />
                                        </IconButton>
                                    </Tooltip>
                                    <Popper open={contextMenu.pdfMenu} anchorEl={this.pdfMenu.current}>
                                        <Paper style={{width: 200, maxWidth: 300}}>
                                            <ClickAwayListener onClickAway={() => this.handleMenuToggle('pdfMenu')}>
                                                <MenuList>
                                                    <Typography variant="subtitle1" style={{padding: '9px 24px'}} color="primary">Export to PDF</Typography>
                                                    <MenuItem onClick={() => this.handleExportMenuClick('pdfMenu', 'pdf', 'allRows')} disabled={config.options?.export?.disable?.pdf?.all && config.options.export.disable.pdf.all(exportData.allRows)}>  
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <FADataTableIcon icon="download" size="small" noMargin />
                                                        </ListItemIcon>
                                                        <Typography variant="body2">
                                                            Search Results
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => this.handleExportMenuClick('pdfMenu', 'pdf', 'displayedRows')} disabled={config.options?.export?.disable?.pdf?.page && config.options.export.disable.pdf.page(exportData)}>  
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <FADataTableIcon icon="download" size="small" noMargin />
                                                        </ListItemIcon>  
                                                        <Typography variant="body2">
                                                            Current Page ({(pagination.page + 1)})
                                                        </Typography> 
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Popper>
                                </React.Fragment>
                            )}          
                            {config.options && config.options.export && config.options.export.excel && (
                                <React.Fragment>
                                    <Tooltip title="Export to Excel">
                                        <IconButton ref={this.excelMenu} onClick={() => this.handleMenuToggle('excelMenu')} style={{marginLeft: 12}}>
                                            <FADataTableIcon icon="file-excel" noMargin button style={{color: colors.green}} />
                                        </IconButton>
                                    </Tooltip>
                                    <Popper open={contextMenu.excelMenu} anchorEl={this.excelMenu.current}>
                                        <Paper style={{ width: 200, maxWidth: 300 }}>
                                            <ClickAwayListener onClickAway={() => this.handleMenuToggle("excelMenu")}>
                                                <MenuList>
                                                    <Typography
                                                        variant="body2"
                                                        className="fw-400"
                                                        style={{ padding: "9px 24px" }}
                                                    >
                                                        Export to Excel
                                                    </Typography>
                                                    <MenuItem
                                                        style={{ color: "#000" }}
                                                        onClick={() =>
                                                            this.handleExportMenuClick("excelMenu", "excel", "allRows")
                                                        }
                                                    >
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <AllIcon type="light" icon="file-excel" size={15} noMargin />
                                                        </ListItemIcon>
                                                        <ListItemText disableTypography>
                                                            <Typography variant="caption">All Results</Typography>
                                                        </ListItemText>
                                                    </MenuItem>
                                                    <MenuItem
                                                        style={{ color: "#000" }}
                                                        onClick={() =>
                                                            this.handleExportMenuClick(
                                                                "excelMenu",
                                                                "excel",
                                                                "displayedRows"
                                                            )
                                                        }
                                                    >
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <AllIcon type="light" icon="file-excel" size={15} noMargin />
                                                        </ListItemIcon>
                                                        <ListItemText disableTypography>
                                                            <Typography variant="caption">Current Page</Typography>
                                                        </ListItemText>
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Popper>
                                    {/*<Popper open={contextMenu.excelMenu} anchorEl={this.excelMenu.current}>
                                        <Paper style={{width: 200, maxWidth: 300}}>
                                            <ClickAwayListener onClickAway={() => this.handleMenuToggle('excelMenu')}>
                                                <MenuList>
                                                    <Typography variant="subtitle1" style={{padding: '9px 24px'}} color="primary">Export to Excel</Typography>
                                                    <div className="csvLink">    
                                                        <CSVLink
                                                            data={exportData.allRows}
                                                            headers={exportData.headers}
                                                            filename={`${config.options.export.name}-All-${moment().format("DD/MM/YYYY[-]HH:mm:ss")}.csv`}
                                                            target={config.options.export.target ? config.options.export.target : '_blank'}
                                                        >
                                                            <MenuItem className="csvLink" onClick={() => this.handleExportMenuClick('excelMenu', false)}>        
                                                                <ListItemIcon className={classes.listIcon}>
                                                                    <FADataTableIcon icon="download" size="small" noMargin />
                                                                </ListItemIcon> 
                                                                <Typography variant="body2">
                                                                    All Results
                                                                </Typography>
                                                            </MenuItem>
                                                        </CSVLink>    
                                                    </div>
                                                    <div className="csvLink">                                                                  
                                                        <CSVLink
                                                            data={exportData.displayedRows}
                                                            headers={exportData.headers}
                                                            filename={`${config.options.export.name}-Page${(this.state.pagination.page + 1)}-${moment().format("DD/MM/YYYY[-]HH:mm:ss")}.csv`}
                                                            target={config.options.export.target ? config.options.export.target : '_blank'}                 
                                                        >
                                                            <MenuItem onClick={() => this.handleExportMenuClick('excelMenu', false)}>        
                                                                <ListItemIcon className={classes.listIcon}>
                                                                    <FADataTableIcon icon="download" size="small" />
                                                                </ListItemIcon>                                                                 
                                                                <Typography variant="body2">
                                                                    Current Page ({(pagination.page + 1)})
                                                                </Typography> 
                                                            </MenuItem>
                                                        </CSVLink>    
                                                    </div>                                                                  
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Popper>*/}
                                </React.Fragment>
                            )}                                   
                            {config.options && config.options.export && config.options.export.csv && (
                                <React.Fragment>
                                    <Tooltip title="Export to CSV">
                                        <IconButton ref={this.csvMenu} onClick={() => this.handleMenuToggle('csvMenu')} style={{marginLeft: 12}}>
                                            <FADataTableIcon icon="file-csv" noMargin button style={{color: colors.green}} />
                                        </IconButton>
                                    </Tooltip>
                                    <Popper open={contextMenu.csvMenu} anchorEl={this.csvMenu.current}>
                                        <Paper style={{width: 200, maxWidth: 300}}>
                                            <ClickAwayListener onClickAway={() => this.handleMenuToggle('csvMenu')}>
                                                <MenuList>
                                                    <Typography variant="subtitle1" style={{padding: '9px 24px'}} color="primary">Export to CSV</Typography>
                                                    <MenuItem onClick={() => this.handleExportMenuClick('csvMenu', 'csv', 'allRows')} disabled={config.options?.export?.disable?.csv?.all && config.options.export.disable.csv.all(exportData.allRows)}>  
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <FADataTableIcon icon="download" size="small" noMargin />
                                                        </ListItemIcon>
                                                        <Typography variant="body2">
                                                            All Results
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => this.handleExportMenuClick('csvMenu', 'csv', 'displayedRows')} disabled={config.options?.export?.disable?.csv?.page && config.options.export.disable.csv.page(exportData)}>  
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <FADataTableIcon icon="download" size="small" noMargin />
                                                        </ListItemIcon>  
                                                        <Typography variant="body2">
                                                            Current Page ({(pagination.page + 1)})
                                                        </Typography> 
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Popper>
                                </React.Fragment>
                            )}       
                            {config.options && config.options.helpAndSupport && (
                                <React.Fragment>
                                    <Tooltip title="Help and Support">
                                        <IconButton ref={this.helpAndSupportMenu} onClick={() => this.handleMenuToggle('helpAndSupportMenu')} style={{marginLeft: 12}}>
                                            <FADataTableIcon icon="question" size="small" noMargin button />
                                        </IconButton>
                                    </Tooltip>
                                    <Popper open={contextMenu.helpAndSupportMenu} anchorEl={this.helpAndSupportMenu.current}>
                                        <ClickAwayListener onClickAway={() => this.handleMenuToggle('helpAndSupportMenu')}>
                                            <Paper style={{maxWidth: 330, padding: 24}}>
                                                {config.options.helpAndSupport}
                                            </Paper>
                                        </ClickAwayListener>
                                    </Popper>
                                </React.Fragment>
                            )}                        
                        </React.Fragment>
                    </Grid>
                </Grid>
            )
        
    }
    renderStickyHead(){
        const { config, columns, rows, classes } = this.props;

        const ogCols = document.querySelector('#staticHeader').childNodes;
        return (
            <React.Fragment>
                <TableHead className={classes.sticky} style={{width: this.state.stickyWidth, top: 70 }}>
                    <TableRow>
                        {_.map(columns, (column, idx) => {
                            const HeaderCellElement = config.plainHeader ? TableCell : (column.dataRef ? SortableTableHeaderCell : TableHeaderCell);
                            return (
                                ((((this.props.ui.device.isMobile && (column.actions || (config.responsiveImportance && column.important !== true))) || column.hidden === true) || this.state.activeColumns[column.dataRef] === false) && (
                                    <React.Fragment key={uuidv4()} />
                                )) || (column.dataRef && (
                                    <HeaderCellElement key={uuidv4()} onClick={() => this.handleSort(column.dataRef)} style={{fontWeight: this.state.sort.dataRef === column.dataRef && 'bold', width: ogCols[idx]?.offsetWidth || '100%', whiteSpace: (column.actions || column.sizeToContent) && 'nowrap', textAlign: (column.actions && !column.actionsCustomHeader && 'center') || (column.alignment && column.alignment)}} {...(typeof config.headerCellProps === "function" && config.headerCellProps(column, rows))}>

                                        {(column.actions && !column.actionsCustomHeader && 'Actions') || column.heading}
                                        {this.state.sort.dataRef && this.state.sort.dataRef === column.dataRef ?
                                            <FADuoIcon icon={this.state.sort.dataOrder === 'DESC' ? 'sort-down' : 'sort-up'} style={{color: config.plainHeader ? '##909090' : '#fff', marginLeft: 3, width: 13, height: 13, marginRight: 3}} /> :
                                            <AllIcon icon={'sort'} heavy style={{color: config.plainHeader ? '##909090' : '#82b6db', marginLeft: 3, width: 13, height: 13, marginRight: 3}} />
                                        }
                                    </HeaderCellElement>
                                )) || (
                                    <HeaderCellElement key={uuidv4()} style={{width: ogCols[idx]?.offsetWidth || '100%', whiteSpace: (column.actions || column.sizeToContent) && 'nowrap', textAlign: (column.alignment && column.alignment) || (column.actions && 'center') }} {...(typeof config.headerCellProps === "function" && config.headerCellProps(column, rows))}>
                                        {(column.actions && !column.actionsCustomHeader && 'Actions') || column.heading}
                                    </HeaderCellElement>
                                )
                            )
                        })}
                    </TableRow>
                </TableHead>
            </React.Fragment>
        )
    }
    renderHeader() {
        const { config, columns } = this.props;
        const { pagination } = this.state;
        const rows = this.state.filters ? this.state.rows : this.props.rows;
        const hasDrop = config.dropRow ? true : false;
        return (
            <React.Fragment>                  
                <TableHead>
                    <TableRow>
                        {config.pagination && (rows.length > 0 || (config.filterMod?.filterInPagination)) && (
                            <>
                                {config.filterMod?.filterInPagination && (
                                    <TableCell colSpan={config.filterMod.colSpan}>
                                        {this.renderFilters()}
                                    </TableCell>
                                )}
                                <Pagination
                                    colSpan={columns.length - (config.filterMod?.colSpan || 0)}
                                    count={rows.length}
                                    rowsPerPage={(config.defaultRowsPerPage && config.defaultRowsPerPage) || pagination.rowsPerPage}
                                    page={pagination.page}
                                    onChangePage={(this.handleChangePage)}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    isMobile={this.props.ui.device.isMobile}
                                    noRowSelector={config.noRowSelector}
                                    style={config.filterMod?.filterInPagination ? {verticalAlign: 'bottom'} : {}}
                                />
                            </>
                        )}
                    </TableRow>
                    <TableRow id='staticHeader'>
                        {_.map(columns, column => {
                            const HeaderCellElement = config.plainHeader ? TableCell : (column.dataRef ? SortableTableHeaderCell : TableHeaderCell);
                            const header = column.heading ? ( !_.isString(column.heading) ? column.heading : _.map(column.heading.split(' '), i => `${i.charAt(0).toUpperCase()}${i.slice(1)}`).join(' ') ) : column.heading;
                            return (
                                ((((this.props.ui.device.isMobile && (column.actions || (config.responsiveImportance && column.important !== true))) || column.hidden === true) || this.state.activeColumns[column.dataRef] === false) && (
                                    <React.Fragment key={uuidv4()} />
                                )) || (column.dataRef && (
                                    <HeaderCellElement key={uuidv4()} onClick={() => this.handleSort(column.dataRef)} style={{fontWeight: this.state.sort.dataRef === column.dataRef && 'bold', width: (column.actions || column.sizeToContent) && '1%', whiteSpace: (column.actions || column.sizeToContent) && 'nowrap', textAlign: (column.actions && !column.actionsCustomHeader && 'center') || (column.alignment && column.alignment)}} {...(typeof config.headerCellProps === "function" && config.headerCellProps(column, rows))}>
                                        <Grid container style={{flexWrap: 'nowrap', alignItems: 'center', justifyContent: ( (column.alignment === 'right' && 'flex-end') || (column.alignment === 'center' && 'center') ) || 'flex-start' }}>
                                            <Grid item style={{lineHeight: '18px', textAlign: (column.actions && !column.actionsCustomHeader && 'center') || (column.alignment && column.alignment)}}>
                                                {(column.actions && !column.actionsCustomHeader && 'Actions') || (config.compactHeader && header ? <span style={{whiteSpace: 'pre-wrap'}}>{header.split(' ').join('\n')}</span> : header)}
                                            </Grid>
                                            <Grid item>
                                                {this.state.sort.dataRef && this.state.sort.dataRef === column.dataRef ?
                                                    <FADuoIcon icon={this.state.sort.dataOrder === 'DESC' ? 'sort-down' : 'sort-up'} style={{color: config.plainHeader ? '##909090' : '#fff', marginLeft: 3, width: 13, height: 13, marginRight: 3}} /> :
                                                    <AllIcon icon={'sort'} heavy style={{color: config.plainHeader ? '##909090' : '#82b6db', marginLeft: 3, width: 13, height: 13, marginRight: 3}} />
                                                }
                                            </Grid>
                                        </Grid>
                                        
                                       
                                    </HeaderCellElement>
                                )) || (
                                    <HeaderCellElement key={uuidv4()} style={{width: ((column.actions && !column.important) || column.sizeToContent) && '1%', whiteSpace: (column.actions || column.sizeToContent) && 'nowrap', textAlign: (column.alignment && column.alignment) || (column.actions && 'center') }} {...(typeof config.headerCellProps === "function" && config.headerCellProps(column, rows))}>
                                        {(column.actions && !column.actionsCustomHeader && 'Actions') || (config.compactHeader && header ? <span style={{whiteSpace: 'pre-wrap'}}>{header.split(' ').join('\n')}</span> : header)}
                                    </HeaderCellElement>
                                )
                            )
                        })}
                        {hasDrop && !_.find(columns, column=>column.actions) &&  
                            (config.plainHeader ?
                                <TableCell key={uuidv4()} style={{width:1}}></TableCell> :
                                <TableHeaderCell key={uuidv4()} style={{width:1}}></TableHeaderCell>
                            )
                        }
                    </TableRow>
                </TableHead>
            </React.Fragment>
        );
    }
    renderBody() {
        const { pagination } = this.state;
        const { config, columns } = this.props;
        let rows = _.values(this.state.rows);
        if(typeof rows === "undefined" || rows.length === 0) {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={columns.length} style={{textAlign: 'center'}}>
                            <Typography>
                                {(config.noResultsText && (
                                    (config.noResultsPaper && (
                                        <Paper>
                                            {config.noResultsText}
                                        </Paper>
                                    )) || (
                                        config.noResultsText
                                    )
                                )) || (config.noResultsPaper && (
                                    <Paper>
                                        No results were found
                                    </Paper>
                                )) || (
                                    'No results were found'
                                )}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        } else {
            for (let i = 0, l = rows.length; i < l; i++) {
                rows[i]['rowNumber'] = i + 1;
                rows[i]['rowNumberReversed'] = (rows.length - i);
                rows[i]['rowIdx'] = i;
            }
            rows = (config.pagination && rows.slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage)) || rows;
            return (
                <TableBody>
                    {_.map(rows, (row, bodyIdx) => {
                        return this.renderRow(row, bodyIdx)
                    })}
                    {(config.totalColumns && config.totalColumns.length > 0) && 
                        <>
                            {this.renderTotalRowOld()}
                        </>
                    }
                    {(_.filter(columns,i => i.totalRef || i.totalLabel).length > 0) && 
                        <>
                            {this.renderTotalRow()}
                        </>
                    }
                </TableBody>
            )
        }
    }

    renderTotalRow () {
        const { classes, config,columns } = this.props;
        const { rows }                    = this.state;
        let TableCellElement = config.dense ? TableBodyCellDense : (config.isCard ? TableBodyCellCard : (config.noPadding ? TableBodyCellNoPadding : TableBodyCell));
        if(!this.props.ui.device.isMobile) {
            _.map(columns, (column, colIdx) => {
                if(column.actions && config.dense !== false) {
                    TableCellElement = TableBodyCellDense;
                }
            })
        }
        return (
            <React.Fragment key={`fragmentTotal`}>
                <TableRow key={`body2Total`} className={config.alternatingRowColours && classes.alternatingRowColours} hover={!config.noRowHover}>
                    {_.map(columns, (column, colIdx) => {
                        let data = null;
                        if ( column.totalRef  &&_.isFunction(column.totalRef))  data =  _.sumBy(rows, i => { return column.totalRef(i) } );
                        if ( column.totalRef && !_.isFunction(column.totalRef)) data =  _.sumBy(rows, a => {
                                                                                            const sortRef = column.totalRef.split('.');
                                                                                            let sourceA = a[sortRef[0]];
                                                                                            if(sortRef.length > 1) {
                                                                                                for(let i = 1; i < sortRef.length; i++) {
                                                                                                    sourceA = sourceA[sortRef[i]];
                                                                                                }
                                                                                            }
                                                                                            sourceA = sourceA === null || sourceA === "" ? '-' : sourceA; 
                                                                                            return parseFloat(sourceA)
                                                                                        })
                        return (
                            <TableCellElement colSpan={1} key={`col${colIdx}`} style={{...column.totalStyle}}>
                                {column.totalLabel ? ( (_.isFunction(column.totalLabel) && column.totalLabel(data)) || column.totalLabel ) : <Typography variant='body2' style={{textAlign:'right', fontWeight: 'bold'}}>
                                    {!!data && this.renderFormat(column, null, data)}
                                </Typography>}
                            </TableCellElement>
                        )
                    })}
                </TableRow>
            </React.Fragment>
        )
    }

    renderTotalRowOld() {
        const { classes, config,columns } = this.props;
        const { rows }                    = this.state;

        let TableCellElement = config.dense ? TableBodyCellDense : (config.isCard ? TableBodyCellCard : (config.noPadding ? TableBodyCellNoPadding : TableBodyCell));
        if(!this.props.ui.device.isMobile) {
            _.map(columns, (column, colIdx) => {
                if(column.actions && config.dense !== false) {
                    TableCellElement = TableBodyCellDense;
                }
            })
        }
        let skipColumns = 0;
        return (
            <React.Fragment key={`fragmentTotal`}>
                <TableRow key={`body2Total`} className={config.alternatingRowColours && classes.alternatingRowColours} hover={!config.noRowHover}>
                    {_.map(columns, (column, colIdx) => {
                        if(skipColumns > 0) {
                            skipColumns = skipColumns - 1;
                            return <React.Fragment key={`skip-${colIdx}`} />
                        } else {
                            let total = 0;
                            const totalColumn = _.find( config.totalColumns, { heading:column.heading } );
                            if ( totalColumn ) {
                                if (totalColumn.data){
                                    _.each(rows, i => {
                                        total += parseFloat( totalColumn.data(i) );
                                    })
                                }
                            }

                            return (
                                <TableCellElement colSpan={1} key={`col${colIdx}`} style={{color: total < 0 && colors.red, fontWeight: 'bold'}}>
                                    { totalColumn &&
                                        <>
                                            {!!totalColumn.label ? totalColumn.label(total) : parseFloat(total).toFixed(2)}
                                        </>
                                    }
                                </TableCellElement>
                            )
                        }
                    })}
                </TableRow>
            </React.Fragment>
        )
    }

    isOverSized = () => {  

        if (this.tableRef && this.tableRef.current){

            if (this.tableRef.current.offsetWidth < this.tableRef.current.scrollWidth){
                //add truncate
                if (this.state.truncate === '100%'){
                    this.setState({
                        truncate: (this.tableRef.current.offsetWidth / (this.props.columns.length - 1))
                    })
                } else {
                    if (this.state.truncate > 1){
                        //keep going if not small enough
                        this.setState({
                            truncate: this.state.truncate - ( ( this.tableRef.current.scrollWidth - this.tableRef.current.offsetWidth ) / _.filter(this.props.columns, i => i?.truncate).length )
                        })
                    } else if (this.state.truncate !== 1){
                        this.setState({
                            truncate: 1
                        })
                    }
                }
            } 

        }
    }

    isUnderSized = () => {
        if (this.tableRef && this.tableRef.current){

            if (this.tableRef.current.offsetWidth >= this.tableRef.current.scrollWidth){
                //add truncate
                if (this.state.truncate !== '100%'){
                    this.setState({
                        truncate: '100%'
                    })
                }
            } 

        }
    }

    renderRow(row, bodyIdx, indentDepth = 0) {
        const { classes, config, columns } = this.props;
        let hasDrop = config.dropRow && (_.isFunction(config.dropRow.droppable) ? config.dropRow.droppable(row) : config.dropRow.droppable);
        let TableCellElement = config.dense ? TableBodyCellDense : (config.isCard ? TableBodyCellCard : (config.noPadding ? TableBodyCellNoPadding : TableBodyCell));
        if(!this.props.ui.device.isMobile) {
            _.map(columns, (column, colIdx) => {
                if(column.actions && config.dense !== false) {
                    TableCellElement = TableBodyCellDense;
                }
            })
        }
        let skipColumns = 0;
        let inActions   = _.filter(columns, column=> column.actions && !column.actionsCustomHeader).length > 0;
        return (
            <React.Fragment key={`fragment${bodyIdx}${row[config.key] || 0}`}>
                <TableRow key={`body2${bodyIdx}`} className={config.alternatingRowColours && classes.alternatingRowColours} {...(config.rowProps && config.rowProps(row))} onClick={() => { this.props.ui.device.isMobile && this.handleResponsiveActionsOpen(columns, row) }} hover={!config.noRowHover}>
                    {_.map(columns, (column, colIdx) => {                        
                        if(skipColumns > 0) {
                            skipColumns = skipColumns - 1;
                            return <React.Fragment key={`skip-${colIdx}`} />
                        } else {
                            let cellProps = column.cellProps && column.cellProps(row);
                            if(cellProps) {
                                cellProps = {
                                    ...cellProps,
                                    style: {
                                        ...cellProps.style,
                                        textAlign: column.alignment && column.alignment
                                    }
                                }
                            } else {
                                cellProps = {
                                    style: {
                                        textAlign: column.alignment && column.alignment
                                    }
                                }
                            }

                            if (config.noHeader && ( column.sizeToContent || column.actions ) ){
                                cellProps.style = {
                                    ...cellProps.style,
                                    width: '1%'
                                }
                            }

                            if(!!column.truncate || (this.props.ui.device.isMobile && column.truncateXs)) {
                                cellProps.style = {
                                    ...cellProps.style,
                                    textOverflow: 'ellipsis',
                                    overflow: 'ellipsis',
                                    overflowX: 'hidden',
                                    whiteSpace: 'nowrap',
                                    maxWidth: this.state.truncate,

                                }
                            }
                            
                            if(column.colSpan && column.colSpan(row) > 1 && !(this.props.ui.device.isMobile && config.responsiveImportance && column.important !== true)) {
                                skipColumns = column.colSpan(row) - 1;
                            }
                            let columnStyle = {textAlign: column.alignment, ...cellProps.style };
                            
                            if (_.isFunction(config.style)) { columnStyle = {...columnStyle, ...config.style({...row, depth: indentDepth ? indentDepth : 0})} }
                            else if (config.style) { columnStyle = {...columnStyle, ...config.style}}

                            if (_.isFunction(column.style)) { columnStyle = {...columnStyle, ...column.style({...row, depth: indentDepth ? indentDepth : 0})} }
                            else if (column.style) { columnStyle = {...columnStyle, ...column.style}}
                            return (
                                ( column.actions && (
                                    (this.props.ui.device.isMobile && (
                                        <React.Fragment />
                                    )) || (
                                        <TableCellElement style={columnStyle} key={`col${colIdx}`}>
                                            {column.actions && column.actions(row).map((action, actnIdx) => {
                                                let ButtonElement = (action.label && Button) || IconButton;
                                                let IconEl = FADataTableIcon;
                                                if(action?.lib) {
                                                    switch(action?.lib) {
                                                        case 'brand':
                                                            IconEl = FABrandIcon;
                                                        break;
                                                        case 'solid':
                                                            IconEl = FASolidIcon;
                                                        break;
                                                        case 'duo':
                                                            IconEl = FADuoIcon;
                                                        break;
                                                        default:
                                                        break;
                                                    }
                                                }
                                                if (action.hide) return <ButtonElement disabled style={{width:44}}></ButtonElement>
                                                let actionColor = null;
                                                if (action.color) {
                                                    if (_.isFunction(action.color)) {
                                                        actionColor = action.color(row);
                                                    } else {
                                                        actionColor = action.color;
                                                    }
                                                }
                                                return (
                                                        ((action.type && action.type === "copyToClipboard" && (
                                                            <CopyToClipboardDatagrid key={`col${colIdx}${actnIdx}`} text={action.data} disabled={action.disabled} />
                                                        )) ||
                                                        ((action.type && action.type === "checkBox" && 
                                                            <Checkbox color={actionColor || 'primary'} checked={action.checked} onClick={action.onCheck} disabled={action.disabled}  key={`col${colIdx}${actnIdx}${action.checked}`} />
                                                        ) ||
                                                        ((action.type && action.type === "plainLink" && (
                                                            ((action.label && typeof action.label !== 'undefined') ?
                                                                (typeof action.link === 'function' ? 
                                                                    <Link onClick={action.link} style={{ color: colors.blue }} className={`${classes.hover}  ${action.class}`} key={`col${colIdx}${actnIdx}`}>
                                                                        {action.label && action.label}<br></br>
                                                                    </Link> : 
                                                                    <Link to={action.link} style={{ color: actionColor ? actionColor : colors.blue }} className={`${classes.hover}  ${action.class}`} key={`col${colIdx}${actnIdx}`}>
                                                                        {action.label && action.label}<br></br>
                                                                    </Link> 
                                                                ) : <></>
                                                            )

                                                        ))) ||
                                                          ((action.type && action.type === "block" && 
                                                            <div>{action.label && action.label}</div>
                                                        )) || 
                                                          (action.type && action.type === "actionMenu" && (
                                                              <ActionMenu
                                                                  rows={action?.rows}
                                                              />
                                                        )) || (

                                                            (action.disabled && (
                                                                (!action.hideIfDisabled && (
                                                                    <i onClick={() => {}} key={`col${colIdx}${actnIdx}`}>
                                                                        <Tooltip title={action.name}>
                                                                            <ButtonElement disabled>
                                                                                {action.icon && <IconEl disabled icon={action.icon} style={{color:actionColor || ''}} button noMargin={!action.label} fixedHeight />} {action.label && action.label}
                                                                            </ButtonElement>
                                                                        </Tooltip>
                                                                    </i>
                                                                )) || (
                                                                    <React.Fragment key={`col-${bodyIdx}-${colIdx}-${actnIdx}`} />
                                                                ))

                                                        ) || (                                                        
                                                            (action.link && (
                                                                <Link to={action.link} style={{textDecoration: 'none'}} key={`col${colIdx}${actnIdx}`}>
                                                                    <Tooltip title={action.name}>
                                                                        <ButtonElement>
                                                                            {action.icon && <IconEl icon={action.icon} className={action?.className} style={{color:actionColor || ''}} button noMargin={!action.label} fixedHeight />} {action.label && action.label}
                                                                        </ButtonElement>
                                                                    </Tooltip>
                                                                </Link>
                                                        )) || (action.linkExternal && (
                                                                <MaterialLink href={`${action.linkExternal}`} target='_blank' style={{textDecoration: 'none'}} key={`col${colIdx}${actnIdx}`}>
                                                                    <Tooltip title={action.name}>
                                                                        <ButtonElement>
                                                                            {action.icon && <IconEl icon={action.icon} className={action?.className} button noMargin={!action.label} fixedHeight />} {action.label && action.label}
                                                                        </ButtonElement>
                                                                    </Tooltip>
                                                                </MaterialLink>                                                            
                                                        )) || (action.onClick && 
                                                            <i onClick={() => action.onClick(row)} key={`col${colIdx}${actnIdx}`}>
                                                                <Tooltip title={action.name}>
                                                                    <ButtonElement>
                                                                        {action.icon && <IconEl icon={action.icon} className={action?.className} style={{color:actionColor || ''}} button noMargin={!action.label} fixedHeight />} {action.label && action.label}
                                                                    </ButtonElement>
                                                                </Tooltip>
                                                            </i>
                                                        )) || (
                                                            action.label && 
                                                            action.label
                                                    )
                                                ))))
                                            })}
                                            {hasDrop && !column.actionsCustomHeader ?
                                                <i onClick={() => this.handleDrop(bodyIdx)} key={`col${columns.length+1}DropRow`}>
                                                    <Tooltip title='Drop Down'>
                                                        <IconButton>
                                                            <FADataTableIcon icon={ this.state.dropped[`drop${bodyIdx}`] ?  icons.arrowUp : icons.arrowDown}  button noMargin={true} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </i>
                                                : 
                                                (!!config.dropRow && <></>)
                                            }
                                        </TableCellElement>
                                    )
                                )) || (
                                    ((((this.props.ui.device.isMobile && config.responsiveImportance && column.important !== true) || column.hidden === true) || this.state.activeColumns[column.dataRef] === false) && (
                                        <React.Fragment key={uuidv4()} />
                                    )) || (
                                        <TableCellElement colSpan={(column.colSpan && column.colSpan(row) > 1 && column.colSpan(row)) || 1} {...cellProps} key={`col${colIdx}`} style={columnStyle}>
                                            {config.nesting && column.nestingDropdown && ((row.children && (
                                                <React.Fragment>
                                                    <div style={{display: 'inline-block', width: (30 * indentDepth) + 'px'}}></div>
                                                    <FADataTableIcon icon='chevron-down' button />
                                                </React.Fragment>
                                            )) || (
                                                <span style={{display: 'inline-block', width: (30 * indentDepth) + 'px'}}></span>
                                            ))}
                                            {column.info && this.getFieldData(column.info,row) ? 
                                                <Grid container spacing={1} style={{
                                                        alignItems: 'center',
                                                        flexWrap:   'nowrap',
                                                        flexDirection: !column.infoStart && 'row-reverse', 
                                                        justifyContent: column.alignment === 'center' ? 'center' : ( column.infoStart ? (column.alignment !== 'right' ? 'flex-start' : 'flex-end') : ( column.alignment === 'right' ? 'flex-start' : 'flex-end' ) ),
                                                    }}>
                                                    <Grid item style={{marginLeft: column.infoStart ? '' : 'auto'}} >
                                                        <AllIcon 
                                                            icon=   {icons.info} 
                                                            size=   'small' 
                                                            tooltip={<Typography variant='body2'>{this.getFieldData(column.info,row)}</Typography>}
                                                            color=  {colors.info}
                                                            noPadding 
                                                            heavy 
                                                            noMargin
                                                        />
                                                    </Grid>
                                                    <Grid item style={{maxWidth: 'calc(100% - 23px)', overflowX: 'hidden', textOverflow: 'ellipsis',}}>
                                                        {this.renderCellData(column, row)}
                                                    </Grid>
                                                </Grid> : 
                                                this.renderCellData(column, row)    
                                            }
                                        </TableCellElement>
                                    )
                                )
                            )
                        }
                    })}
                    {hasDrop && !inActions ?
                        <TableCellElement  key={`col${columns.length+1}`}>
                            <i onClick={() => this.handleDrop(bodyIdx)} key={`col${columns.length+1}DropRow`}>
                                <Tooltip title='Drop Down'>
                                    <IconButton>
                                        <FADataTableIcon icon={ this.state.dropped[`drop${bodyIdx}`] ?  icons.arrowUp : icons.arrowDown}  button noMargin={true} />
                                    </IconButton>
                                </Tooltip>
                            </i>
                        </TableCellElement> : 
                        (
                            config.dropRow && !inActions && <TableCellElement  key={`col${columns.length+1}`}></TableCellElement>
                        )
                    }
                    
                </TableRow>
                {config.nesting && row.children && this.renderNestedChildren(row.children, indentDepth + 1)}
                {!!hasDrop && this.renderDrop(row, bodyIdx)}
            </React.Fragment>
        )
    }

    handleDrop(bodyIdx) {
        this.setState({
            dropped: {
                ...this.state.dropped,
                [`drop${bodyIdx}`]: !this.state.dropped[`drop${bodyIdx}`]
            }
        })
    }

    renderDrop(row, bodyIdx){
        const { config } = this.props;
        let d = config.dropRow.data ? this.getFieldData(config.dropRow.data, row) : row;
        let data = _.isArray(d) ? d : [d];
        let columns = this.props.columns;
        if (config.dropRow.columns){
            columns = _.map(config.dropRow.columns, (col, colIdx) => {
                col.heading =  '';
                return col;
            });
        } 
        return(
            <React.Fragment key={`fragmentDrop${bodyIdx}`}>
                {this.state.dropped[`drop${bodyIdx}`] && 
                    _.map(data, (rowData, dropRowIdx) => {
                        return this.renderDropRow(rowData, columns, bodyIdx, dropRowIdx);
                    })
                }
            </React.Fragment>
        )
    }

    renderDropRow(row, columns, bodyIdx, dropRowIdx) {
        
        const { config } = this.props;
        let TableCellElement = config.dense ? TableBodyCellDense : (config.isCard ? TableBodyCellCard : (config.noPadding ? TableBodyCellNoPadding : TableBodyCell));
        if(!this.props.ui.device.isMobile) {
            _.map(columns, (column, colIdx) => {
                if(column.actions && config.dense !== false) {
                    TableCellElement = TableBodyCellDense;
                }
            })
        }
        let inActions = _.filter(this.props.columns, column => column.actions && !column.actionsCustomHeader).length > 0

        return (
            <React.Fragment key={`fragment${bodyIdx}Drop${dropRowIdx}`}>
                <TableRow key={`body2${bodyIdx}Drop${dropRowIdx}`} {...(config.rowProps && config.rowProps(row))} onClick={() => { this.props.ui.device.isMobile && this.handleResponsiveActionsOpen(columns, row) }} hover={!config.noRowHover}>
                    {_.map(columns, (col, colIdx) => { 
                        const column = (col.useParent) ? this.props.columns[col.useParent] : col;                   
                        let cellProps = column.cellProps && column.cellProps(row);
                        if(cellProps) {
                            cellProps = {
                                ...cellProps,
                                style: {
                                    ...cellProps.style,
                                    textAlign: column.alignment && column.alignment
                                }
                            }
                        } else {
                            cellProps = {
                                style: {
                                    textAlign: column.alignment && column.alignment
                                }
                            }
                        }
                        if(!!column.truncate || (this.props.ui.device.isMobile && column.truncateXs)) {
                            cellProps.style = {
                                ...cellProps.style,
                                textOverflow: 'ellipsis',
                                overflowX: 'hidden',
                                whiteSpace: 'nowrap',
                                maxWidth: this.state.truncate,
                            }
                        }

                        let columnStyle = {textAlign: column.alignment };
                        
                        if (_.isFunction(config.dropRow.style)) { columnStyle = {...columnStyle, ...config.dropRow.style({...row})} }
                        else if (config.dropRow.style) { columnStyle = {...columnStyle, ...config.dropRow.style}}

                        if (_.isFunction(column.style)) { columnStyle = {...columnStyle, ...column.style({...row})} }
                        else if (column.style) { columnStyle = {...columnStyle, ...column.style}}

                        return (
                            (
                                ((((this.props.ui.device.isMobile && config.responsiveImportance ) || column.hidden === true)) && (
                                    <React.Fragment key={uuidv4()} />
                                )) || (
                                    <TableCellElement colSpan={(column.colSpan && _.isFunction(column.colSpan) ? (column.colSpan(row) > 1 && column.colSpan(row)) : (column.colSpan > 1 && column.colSpan)) || 1} {...cellProps} key={`col${colIdx}Drop${dropRowIdx}`} style={columnStyle}>
                                        {column.actions ?
                                            this.renderActions( row, column, colIdx, bodyIdx, true) :
                                            this.renderCellData(column, row)
                                        }
                                    </TableCellElement>
                                )
                            )
                        )
                        
                    })}                    
                    {!inActions &&
                        <TableCellElement key={`col${columns.length + 1 }Drop${dropRowIdx}`} style={
                                _.isFunction(config.dropRow.style) ? config.dropRow.style({...row}) : config.dropRow.style
                            }>
                            {/*!config.dropRow.hideDropArrow && <AllIcon icon={icons.dropRow} />*/}
                        </TableCellElement>
                    }
                </TableRow>
            </React.Fragment>
        )
    }

    

    renderNestedChildren(children, depth = 0) {
        return (
            <React.Fragment>
                {_.map(children, (row, bodyIdx) => {
                    return (
                        <React.Fragment>
                            {this.renderRow(row, bodyIdx, depth)}
                        </React.Fragment>
                    )
                })}
            </React.Fragment>
        );
    }
    
    applyFieldFormat(format, data, column=null, row={}){

        return (
            (format === 'boolean' && (
                <span style={{textAlign: column?.alignment}}>  
                    {
                        checkTrueFalse(data) ? 
                            <FADataTableIcon noMargin icon="check" style={{color: colors.green}} button={column?.tooltip ? true : false} />  :
                            <FADataTableIcon noMargin icon="times" style={{color: colors.red}}   button={column?.tooltip ? true : false} /> 
                    }
                </span>
            )) ||
            (format === 'datetime' && (
                isNaN(Date.parse(data)) ? '-' : moment(new Date(data)).format("H:mm on DD/MM/YYYY")
            )) ||
            (format === 'date' && (
                isNaN(Date.parse(data)) ? '-' : moment(new Date(data)).format("DD/MM/YYYY")
            )) ||
            (format === 'clenydate' && (
                isNaN(Date.parse(data)) ? '-' : clenyDate(data)
            )) ||
            (format === 'clenydatetime' && (
                isNaN(Date.parse(data)) ? '-' : clenyDate(data, true)
            )) ||
            (format === 'clenytime' && (
                isNaN(Date.parse(data)) ? '-' : clenyDate(data, false, true)
            )) ||
            (format && format.startsWith('percentage') && (
                parseFloat(data).toFixed(format.includes(':') ? format.split(":")[1] : 2) + '%'
            )) ||
            (format && format.startsWith('decimal') && (
                parseFloat(data).toFixed(format.includes(':') ? format.split(":")[1] : 2)
            )) ||
            (format && format.startsWith('absolute') && (
                format.includes('absolute:') ?
                    this.applyFieldFormat( format.slice('absolute:'.length), Math.abs(parseFloat(data))) :
                    Math.abs(parseFloat(data))
            )) ||
            (format && format.startsWith('realNumber') && (
                format.includes('realNumber:') ? 
                    this.applyFieldFormat( format.slice('realNumber:'.length),  convertRealNumber(data)) :
                    convertRealNumber(data)
            )) ||
            (format && format.startsWith('nullDash') && (
                format.includes('nullDash:') ? 
                    (
                        this.applyFieldFormat( format.slice('nullDash:'.length),  data) || '-'
                    ) : (!data ? '-' : data )
            )) ||
            (format && format.startsWith('redMinus') && (
                format.startsWith('redMinus:') ? 
                    (
                        parseFloat(data) < 0 ?
                            <span style={{color: colors.red}}>{this.applyFieldFormat( format.slice('redMinus:'.length),  parseFloat(data) )}</span> : 
                            this.applyFieldFormat( format.slice('redMinus:'.length),  parseFloat(data) )
                    ) :
                    (parseFloat(data) < 0 ? <span style={{color: colors.red}}>{parseFloat(data)}</span> : parseFloat(data))
            )) ||
            (format && format.startsWith('colorNumbers') && (
                format.startsWith('colorNumbers:') ? 
                    (
                        !parseFloat(data)  ?
                            this.applyFieldFormat( format.slice('colorNumbers:'.length), 0 ) : 
                            <span style={{color: parseFloat(data) < 0 ? colors.red : colors.green}}>{parseFloat(data) > 0 && '+'}{this.applyFieldFormat( format.slice('colorNumbers:'.length),  parseFloat(data) )}</span>
                    ) :
                    (!parseFloat(data) ? 0 : <span style={{color: parseFloat(data) < 0 ? colors.red : colors.green}}>{parseFloat(data) > 0 && '+'}{parseFloat(data)}</span> )
            )) ||
            (format && format.startsWith('formatSeconds') && (
                format.includes('formatSeconds:') ?
                    this.applyFieldFormat( format.slice('formatSeconds:'.length), formatSeconds(parseFloat(data), true, true, true, false, ':')) :
                    formatSeconds(parseFloat(data), true, true, true))
            ) ||
            (format === 'changeCurrency' && (
                currency(data,2, true, true, true)
            )) ||
            (format === 'currency' && (
                currency(data,2)
            )) ||
            (format === 'currencyCost' && (
                currency(data)
            )) ||
            (format === 'dashOnBlank' && (
                data || '-'
            )) ||
            (format && format.startsWith('count') && (
                format.includes('count:') ?
                    this.applyFieldFormat( format.slice('count:'.length), data.length || '0') :
                    data.length || '0'
            )) ||
            (format === 'icon' && (
                <div style={{textAlign: 'center'}}>
                    <AllIcon icon={data} noMargin
                        color={column?.color ? ( _.isFunction(column.color) ? column.color(row) : column.color ) : null}
                    />
                </div>
            )) ||
            (format === 'image' && (
                <div style={{textAlign: 'center'}}>
                    <ImageWithError src={data} style={{maxHeight: 30, maxWidth: 30}} />
                </div>
            )) ||
            data
        )
    }
    renderFormat(column, row, fieldData=null) {
        const data = fieldData ? fieldData : this.getFieldData(column.field,row);
        return (
            <>
                {column.fieldPrefix && ( _.isFunction(column.fieldPrefix) ? column.fieldPrefix(row) : column.fieldPrefix )}
                {this.applyFieldFormat(column.fieldFormat, data, column, row)}
                {column.fieldSuffix && ( _.isFunction(column.fieldSuffix) ? column.fieldSuffix(row) : column.fieldSuffix )}
            </>
        )
    }
    renderTotals() {
        const { pagination } = this.state;
        const { classes, config, columns } = this.props;
        const getFieldData = this.getFieldData;
        let TableCellElement = config.dense ? TableBodyCellDense : TableBodyCell;
        if(config.showTotals) {
            let rows = _.values(this.state.rows);
            if(typeof rows === "undefined" || rows.length === 0) {
                return (
                    <React.Fragment />
                )
            } else {
                rows = (config.pagination && rows.slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage)) || rows;
                let totals = [];
                _.each(rows, function (row) {
                    _.each(columns, function (column, idx) {
                        if(column.showTotal) {
                            let data = getFieldData( column.field, row );
                            totals[idx] = ((idx in totals && totals[idx]) || 0) + Number(data);
                        } else {
                            if(column.showTotalLabel) {
                                totals[idx] = 'Total';
                            } else {
                                totals[idx] = '';
                            }
                        }
                    });
                });
                return (
                    <React.Fragment>
                        <TableBody>
                            <TableRow className={config.alternatingRowColours && classes.alternatingRowColours} key='totals'>
                                {_.map(columns, (column, idx) => {
                                    return (
                                        (this.props.ui.device.isMobile && config.responsiveImportance && column.important !== true && (
                                            <React.Fragment />
                                        )) || (
                                            <TableCellElement key={`totals-${idx}`} style={{textAlign: column.alignment && column.alignment, fontWeight: '500', ...config.totalStyle}}>
                                                {(column.showTotal && (
                                                    <React.Fragment>
                                                        {(column.overwriteTotal && (
                                                            <React.Fragment>
                                                                {column.overwriteTotal}
                                                            </React.Fragment>
                                                        )) ||
                                                        (
                                                            <React.Fragment>
                                                                {this.renderFormat(column, null,totals[idx])}
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )) ||
                                                (column.showTotalLabel && (
                                                    <React.Fragment>
                                                        {totals[idx]}
                                                    </React.Fragment>
                                                ))}
                                            </TableCellElement>
                                        )
                                    )
                                })}
                            </TableRow>
                        </TableBody>
                    </React.Fragment>
                )
            }
        }
    }
    renderFullTotals() {
        const { classes, config, columns } = this.props;
        let TableCellElement = config.dense ? TableBodyCellDense : TableBodyCell;
        if(config.showFullTotals) {
            let rows = _.values(this.state.rows);
            if(typeof rows === "undefined" || rows.length === 0) {
                return (
                    <React.Fragment />
                )
            } else {
                let totals = [];
                _.each(rows, function (row) {
                    _.each(columns, function (column, idx) {
                        if(column.showTotal) {
                            totals[idx] = ((idx in totals && totals[idx]) || 0) + ((typeof column.totalField === 'function' && Number(column.totalField(row))) || Number((column.field(row))));
                        } else {
                            if(column.showTotalLabel) {
                                totals[idx] = 'Total';
                            } else {
                                totals[idx] = '';
                            }
                        }
                    });
                });
                return (
                    <React.Fragment>
                        <TableBody>
                            <TableRow className={config.alternatingRowColours && classes.alternatingRowColours} key='totals'>
                                {_.map(columns, (column, idx) => {
                                    return (
                                        <TableCellElement style={{textAlign: column.alignment && column.alignment, fontWeight: 'bold'}}>
                                            {(column.showTotal && (
                                                <React.Fragment>
                                                    {(column.overwriteTotal && (
                                                        <React.Fragment>
                                                            {column.overwriteTotal}
                                                        </React.Fragment>
                                                    )) ||
                                                    (
                                                        <React.Fragment>
                                                            {this.renderFormat(column, null, totals[idx])}
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )) ||
                                            (column.showTotalLabel && (
                                                <React.Fragment>
                                                    {totals[idx]}
                                                </React.Fragment>
                                            ))}
                                        </TableCellElement>
                                    )
                                })}
                            </TableRow>
                        </TableBody>
                    </React.Fragment>
                )
            }
        }
    }
    getFieldData(column, row) {
        if (!row) return 0;
        if ( _.isFunction( column )) return column(row);
        if (column in row) return row[column];
        let unpack = unpackObjectUsingString(row,column);
        return unpack === null ? column : unpack;
        //return _.isFunction( column ) ? column(row) : ( column in row ? row[column] : (unpackObjectUsingString(row,column) || column) );
    }
    renderFooter() {
        const { config, columns, footer } = this.props;
        //if we filter data make the footer row count reflect the filtered data
        const rows = this.state.filters ? this.state.rows : this.props.rows;
        const { pagination } = this.state;
        return (
            <TableFooter>
                {footer}
                <TableRow>
                    {config.pagination && rows.length > 0 && (
                        <Pagination
                            colSpan={columns.length}
                            count={rows.length}
                            rowsPerPage={(config.defaultRowsPerPage && config.defaultRowsPerPage) || pagination.rowsPerPage}
                            page={pagination.page}
                            onChangePage={(this.handleChangePage)}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            isMobile={this.props.ui.device.isMobile}
                            noRowSelector={config.noRowSelector}
                            plainPagination={config.plainPagination}
                        />
                    )}
                </TableRow>
            </TableFooter>
        );
    }
    renderTooltip(field, text){
        return(
            <Tooltip
                title={
                    <Typography variant="subtitle">{text}</Typography>
                }
                placement="left"
            >
                <div>
                    {field}
                </div>
            </Tooltip>
        );
    }
    renderCellData(column, row, responsiveDialog = false) {
        let field = (
            (responsiveDialog && (
                <React.Fragment>
                    <Typography variant="body2">
                        {column.heading}:
                    </Typography>
                    <Typography variant="body1" style={{marginBottom: 16}}>
                        {this.renderFormat(column, row)}
                    </Typography>
                </React.Fragment>
            )) || (
                <React.Fragment>
                    {this.renderFormat(column, row)}
                </React.Fragment>
            )
        )
        if(column.tooltip) {
            return this.renderTooltip(field, this.getFieldData(column.tooltip, row));
        } else {
            return field;
        }
    }
    renderResponsiveDialog = () => {
        let mainColumnFound = false;
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={Boolean(this.state.responsiveActionsRow)}
                onClose={this.handleResponsiveActionsClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {_.map(this.state.responsiveActionsColumns, (column, colIdx) => {
                        if(column.main)
                            mainColumnFound = true;
                        return column.main && this.renderCellData(column, this.state.responsiveActionsRow)
                    })}
                    {!mainColumnFound && (
                        this.renderCellData(this.state.responsiveActionsColumns[0], this.state.responsiveActionsRow)
                    )}
                    <IconButton aria-label="Close" className={this.props.classes.closeButton} onClick={this.handleResponsiveActionsClose}>
                        <FADataTableIcon icon='times' noMargin />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component="div">
                        {_.map(this.state.responsiveActionsColumns, (column, colIdx) => {
                            return !column.actions && !column.hideInResponsiveDialog && this.renderCellData(column, this.state.responsiveActionsRow, true)
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {_.map(this.state.responsiveActionsColumns, (column, colIdx) => {
                        return (
                            (column.actions &&
                                <React.Fragment>
                                    {column.actions(this.state.responsiveActionsRow).map((action, actnIdx) => {
                                        return (
                                            <React.Fragment>
                                                {(action.disabled &&
                                                    <Button disabled key={`col${colIdx}${actnIdx}`}>
                                                        {action.icon && <FADataTableIcon icon={action.icon} button disabled />} {action.name}
                                                    </Button>
                                                ) || (
                                                    (action.link && (
                                                        <Link to={action.link} style={{textDecoration: 'none'}} key={`col${colIdx}${actnIdx}`}>
                                                            <Button>
                                                                {action.icon && <FADataTableIcon icon={action.icon} button />} {(action.label && action.label) || action.name}
                                                            </Button>
                                                        </Link>
                                                    )) || (
                                                        action.onClick && 
                                                        <i onClick={() => { this.handleResponsiveActionsClose(); action.onClick(this.state.responsiveActionsRow) }} key={`col${colIdx}${actnIdx}`}>
                                                            <Button>
                                                                {action.icon && <FADataTableIcon icon={action.icon} button />} {(action.label && action.label) || action.name}
                                                            </Button>
                                                        </i>
                                                    )
                                                )}
                                            </React.Fragment>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        )
                    })}
                </DialogActions>
            </Dialog>
        )
    }

    renderActions = (row, column, colIdx, bodyIdx, inDrop = false) => {
        const { classes, config, columns } = this.props;
        const hasDrop = config.dropRow ? !inDrop : false;
        return (
            <>
                {column.actions && column.actions(row).filter(i => !!i).map((action, actnIdx) => {
                    let ButtonElement = (action.label && Button) || IconButton;
                    let IconEl = FADataTableIcon;
                    if(action?.lib) {
                        switch(action?.lib) {
                            case 'brand':
                                IconEl = FABrandIcon;
                            break;
                            case 'solid':
                                IconEl = FASolidIcon;
                            break;
                            case 'duo':
                                IconEl = FADuoIcon;
                            break;
                            default:
                            break;
                        }
                    }
                    if (action.hide) return <ButtonElement disabled style={{width:44}}></ButtonElement>;
                    return (
                            ((action.type && action.type === "copyToClipboard" && (
                                <CopyToClipboardDatagrid key={`col${colIdx}${actnIdx}`} text={action.data} disabled={action.disabled} />
                            )) ||
                            ((action.type && action.type === "checkBox" && 
                                <Checkbox color={action.color || 'secondary'} checked={action.checked} onClick={action.onCheck} disabled={action.disabled}/>
                            ) ||
                            ((action.type && action.type === "plainLink" && (
                                ((action.label && typeof action.label !== 'undefined') ?
                                    (typeof action.link === 'function' ? 
                                        <Link onClick={action.link} style={{ color: colors.blue }} className={`${classes.hover}  ${action.class}`} key={`col${colIdx}${actnIdx}`}>
                                            {action.label && action.label}<br></br>
                                        </Link> : 
                                        <Link to={action.link} style={{ color: action.color ? action.color : colors.blue }} className={`${classes.hover}  ${action.class}`} key={`col${colIdx}${actnIdx}`}>
                                            {action.label && action.label}<br></br>
                                        </Link> 
                                    ) : <></>
                                )
                            ))) ||
                            (action.type && action.type === "actionMenu" && (
                                <ActionMenu
                                    rows={action?.rows}
                                />
                            ))
                            
                            || (
                                (action.disabled &&
                                    <ButtonElement disabled key={`col${colIdx}${actnIdx}`}>
                                        {action.icon && <IconEl icon={action.icon} button noMargin={!action.label} disabled />} {action.label && action.label}
                                    </ButtonElement>
                            ) || (                                                        
                                (action.link && (
                                    <Link to={action.link} style={{textDecoration: 'none'}} key={`col${colIdx}${actnIdx}`}>
                                        <Tooltip title={action.name}>
                                            <ButtonElement>
                                                {action.icon && <IconEl icon={action.icon} style={{color:action.color || ''}} button noMargin={!action.label} fixedHeight />} {action.label && action.label}
                                            </ButtonElement>
                                        </Tooltip>
                                    </Link>
                            )) || (action.linkExternal && (
                                    <MaterialLink href={`${action.linkExternal}`} target='_blank' style={{textDecoration: 'none'}} key={`col${colIdx}${actnIdx}`}>
                                        <Tooltip title={action.name}>
                                            <ButtonElement>
                                                {action.icon && <IconEl icon={action.icon} button noMargin={!action.label} fixedHeight />} {action.label && action.label}
                                            </ButtonElement>
                                        </Tooltip>
                                    </MaterialLink>                                                            
                            )) || (action.onClick && 
                                <i onClick={() => action.onClick(row)} key={`col${colIdx}${actnIdx}`}>
                                    <Tooltip title={action.name}>
                                        <ButtonElement>
                                            {action.icon && <IconEl icon={action.icon} style={{color:action.color || ''}} button noMargin={!action.label} fixedHeight />} {action.label && action.label}
                                        </ButtonElement>
                                    </Tooltip>
                                </i>
                            )) || (
                                action.label && 
                                action.label
                        )
                    ))))
                })}
                {hasDrop && !column.actionsCustomHeader ?
                    <i onClick={() => this.handleDrop(bodyIdx)} key={`col${columns.length+1}DropRow`}>
                        <Tooltip title='Drop Down'>
                            <IconButton>
                                <FADataTableIcon icon={ this.state.dropped[`drop${bodyIdx}`] ?  icons.arrowUp : icons.arrowDown}  button noMargin={true} />
                            </IconButton>
                        </Tooltip>
                    </i>
                    : 
                    (!!config.dropRow && <></>)
                }
                {inDrop && !config.hideDropArrow &&  <IconButton>{/*<AllIcon icon={icons.dropRow} />*/}</IconButton>}
            </>
        ) 
            
        
    }
    
    renderFilters = () => {
        const { config, columns, rows } = this.props;
        if (_.filter(columns, i=>i.filter).length > 0 || !!this.state.filters.length){
            return (
                <Grid container xs={12} style={{marginBottom: config.filterMod?.filterInPagination ? 0 : '1em', alignItems: 'flex-end'}}>
                    {_.map(_.orderBy(_.filter(columns, i=>i.filter), i=>i.filterMod?.displayOrder || 0), (col,idx) => {
                        const {filter} = col; 
                        return (
                            <> 
                                {
                                    ( (
                                        filter === 'custom' &&
                                        <Grid item style={{paddingLeft: idx > 0 && '1em',...col.filterMod?.styleGrid}}>
                                            <FormControl margin="normal" fullWidth style={{margin:0}}>
                                                <TextField  label={col.filterMod.label || 'search'} 
                                                            fullWidth 
                                                            value={this.state.filters[`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}${idx}`] || ''} 
                                                            onChange={(e)=>{this.handleAddFilterParam(`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}${idx}`)(e.target.value)}}
                                                />
                                            </FormControl>
                                        </Grid>
                                    ) || 
                                        ( filter === 'dateRange' && 
                                            <>
                                                <Grid item style={{paddingLeft: idx > 0 && '1em',...col.filterMod?.styleGrid}}>
                                                    <DatePicker
                                                        type='date'
                                                        id={`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}From${idx}`}
                                                        name={`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}From${idx}`}
                                                        label={`${col.heading ? col.heading : 'Date '} From`}
                                                        autoOk={true}
                                                        value={this.state.filters[`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}From${idx}`] || null}
                                                        onChange={this.handleAddFilterParam(`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}From${idx}`)}
                                                        margin='none'
                                                        clearable={true}
                                                    />
                                                </Grid>
                                                <Grid item style={{paddingLeft: '1em',...col.filterMod?.styleGrid}}>
                                                    <DatePicker
                                                        type='date'
                                                        id={`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}To${idx}`}
                                                        name={`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}To${idx}`}
                                                        label={`${col.heading ? col.heading : 'Date '} To`}
                                                        autoOk={true}
                                                        value={this.state.filters[`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}To${idx}`] || null}
                                                        onChange={this.handleAddFilterParam(`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}To${idx}`)}
                                                        margin='none'
                                                        clearable={true}
                                                    />
                                                </Grid>
                                            </>
                                        ) ||
                                        ( 
                                            (filter === 'date' && 
                                                <Grid item style={{paddingLeft: idx > 0 && '1em',...col.filterMod?.styleGrid}}>
                                                    <DatePicker
                                                        type        = 'date'
                                                        id          = {`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}${idx}`}
                                                        name        = {`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}${idx}`}
                                                        label       = {col.heading ? col.heading : 'Date'}
                                                        autoOk      = {true}
                                                        value       = {this.state.filters[`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}${idx}`] || null}
                                                        onChange    = {this.handleAddFilterParam(`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}${idx}`)}
                                                        margin      = 'none'
                                                        clearable   = {true}
                                                    />
                                                </Grid>
                                            ) || (
                                                (filter === 'select' && 
                                                    <Grid item style={{paddingLeft: idx > 0 && '1em',...col.filterMod?.styleGrid}}>
                                                        <FormControl fullWidth margin="none">
                                                            <AutoCompleteSelect 
                                                                options  = {_.map(_.uniqBy(rows, i => {
                                                                    if (col.dataRef) return _.isFunction(col.dataRef) ? col.dataRef(i) : i[col.dataRef];
                                                                    return this.getFieldData(col.field,i);
                                                                }), (i, idx) => {
                                                                    let data = col.dataRef ? _.isFunction(col.dataRef) ? col.dataRef(i) : i[col.dataRef] : this.getFieldData(col.field,i);
                                                                    return {label: data, value: idx + 1, filter: data}
                                                                })}
                                                                name     = {`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}${idx}`}
                                                                label    = {col.heading ? col.heading : 'Date'}
                                                                value    = {this.state.filters[`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}${idx}`] || null}
                                                                onChange = {this.handleAddFilterParam(`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}${idx}`)}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                )
                                            )
                                        )
                                    )
                                }
                            </>
                        )
                    })}
                    {config.filterMod?.clearable && 
                        <Grid item style={{paddingLeft: '1em'}}>
                            <Button onClick={this.handleClearFilters} style={{padding: 0}}><AllIcon icon={icons.clear} size='small' />Reset Search</Button>
                        </Grid>
                    }
                </Grid>
            )
        }
    }

    handleClearFilters = () => {this.setState({filters: {}},this.handleFilter)}
    handleAddFilterParam = key => value => {
        this.setState({
            filters:{
                ...this.state.filters,
                [key]: value
            }
        },this.handleFilter)
    }
    handleFilter = () => {
        //add back in sorting
        this.setState({
            rows: _.filter(this.state.ogRows, i => {
                const { columns } = this.props;
                const {filters} = this.state;
                let allow = true;
                _.each(_.orderBy(_.filter(columns, i=>i.filter), i=>i.filterMod?.displayOrder || 0), (col,idx) => {
                    const data   = this.getFieldData(col.dataRef || col.field, i);
                    const filter = filters[`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}${idx}`]
                    switch(col.filter){
                        case 'custom':
                            if (filter) allow = col.filterMod.dataRef(filter, i)
                        break;
                        case 'dateRange':
                            if (filters[`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}From${idx}`]){
                                allow = moment(data).isSameOrAfter(moment(filters[`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}From${idx}`]), 'day') ? allow : false;
                            }
                            if (filters[`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}To${idx}`]){
                                allow = moment(data).isSameOrBefore(moment(filters[`${col.heading ? col.heading.replace(' ','_') : `${idx}_`}To${idx}`]), 'day') ? allow : false;
                            }
                        break
                        case 'date':
                            if (filter) if (!moment(data).isSame(moment(filter),'day')) allow = false;
                        break;
                        case 'select':
                            if (filter) if ( filter.filter !== (this.getFieldData(col.dataRef ?? col.field, i))) allow = false;
                        break;
                        default: break;
                    }
                })
                return allow;
            })
        }, ()=>{this.saveTableState(); this.state.sort.dataRef && this.handleSort(this.state.sort.dataRef) })
    }

    handleScroll = e => {
        let table = ReactDOM.findDOMNode(this).getBoundingClientRect();
        this.setState({stickyWidth: table.width})
        let sticky = this.state.sticky;
        //need to get header hight and change 100 -> 60 + header height
        let offset = 41 ;
        if (parseFloat(table.top) <  (60 + offset) ) sticky = true;
        if (parseFloat(table.top) >= (60 - offset) ) sticky = false;
        this.setState({sticky})
    }

    render() {
        const { config } = this.props;

        return (
            <div style={{maxWidth: '100%', overflowX: 'auto'}} ref={this.tableRef}>                
                <div style={{border: config.withBorder && '1px solid rgba(224, 224, 224, 1)', borderRadius: config.withBorderRadius && '4px'}}>          
                    {(config.isLoading === true && (
                        <LoadingCircle />
                    )) || (      
                        <React.Fragment>   
                            {!config.filterMod?.filterInPagination && this.renderFilters()}
                            {!config.noHeader && (config.options && config.options.export && this.renderOptions())}    
                            <Typography variant="body2" component={Table}>
                                {!config.noHeader && this.renderHeader()  }
                                {( this.state.sticky && !config.noHeader ) && this.renderStickyHead()}
                                {this.renderBody()}
                                {this.renderTotals()}
                                {this.renderFullTotals()}
                                {this.renderFooter()}
                            </Typography>
                        </React.Fragment>      
                    )}
                    {Boolean(this.state.responsiveActionsRow) && this.renderResponsiveDialog()}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        statePersistence: state.statePersistence,
        ui: state.ui
    };
}
function mapDispatchToProps(dispatch) {
    return {
        handleScrollToTop: () => {
            dispatch(handleScrollToTop())
        },
        setPersistence: (key, state) => {
            dispatch(setPersistence(key, state))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CiDataTable));