import React, { Component } from 'react';
import { connect }          from 'react-redux';

import DataTable from 'Components/Common/DataTables/CiDataTable';

import icons from 'Helpers/IconHelper';

import { printA4 } from 'Actions/Print/Print';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import { downloadS3File }  from 'Functions/MiscFunctions';

import EmailDialog from 'Components/Common/Dialogs/EmailDialog';

class PickingFiles extends Component {
    constructor(props) {
        super(props);
    }

    download = (row) => {
        downloadS3File(row.file_url_pdf_front);
    }

    print = (row) => {
        this.props.printA4(row.file_url_pdf_front, row.doc_title);
    }

    email = (row) => {
        const dialog = <EmailDialog
                            customerId={this.props.order.order_cust_id}
                            customerContactId={this.props.order.order_cust_contact_id}
                            type={'pickingDocuments'}
                            id={row.doc_id} 
                            pickingDocuments={this.props.order.pickingDocuments}
                        />
        this.props.deployDialog(dialog, `You Are About To Email - Linked Part File`, 'Success');
    }

    render() {

        const { pickingDocuments } = this.props.order;

        return (
            <DataTable
                config={{
                    key: 'orpl_id'
                }}
                rows={pickingDocuments}
                columns={[
                    {
                        heading: 'Category',
                        field: 'doc_cat_name'
                    },
                    {
                        heading: 'Sub Category',
                        field: 'subcategory_name'
                    },
                    {
                        heading: 'Title',
                        field: 'doc_title'
                    }, 
                    {
                        heading: 'Version',
                        field: 'latest_file.file_version',
                        fieldPrefix: 'v',
                        alignment: 'center'
                    },
                    {
                        actions: i => ([
                            {
                                name: 'Download',
                                icon: icons.download,
                                onClick: this.download
                            },
                            {
                                name: 'Print',
                                icon: icons.print,
                                onClick: this.print
                            },
                            {
                                name: 'Email',
                                icon: icons.email,
                                onClick: this.email
                            }
                        ])
                    }
                ]}
            />
        )
    }
}

const mapDispatchToProps = dispatch => ({
    printA4:    (data, file) => dispatch(printA4(data, file)),
    deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:        ()                              => dispatch(closeDialog())
})

export default connect(null, mapDispatchToProps)(PickingFiles);