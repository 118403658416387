import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import { Button, Grid, TextField } from "@material-ui/core";
import React from "react";
import InputHelper from 'Helpers/InputHelper';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { connect } from 'react-redux';
import API from 'API';
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import CiDataTable from "Components/Common/DataTables/CiDataTable";
import _ from "lodash";
import Textarea from "Components/Common/Inputs/Textarea";

const initialState = () => ({
    formData: {
        name: '',
        desc: '',
    },
    sources: [],
    isLoading: true,
})

class ContactSources extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState();
        this.inputHelper = new InputHelper(this);
    }

    componentDidMount() {
        this.getSources();
    }

    submitNewSource = () => {   
        API.post('/customers/contacts/sources', this.state.formData)
        .then(res => {
            this.setState(initialState(), this.getSources);
        });
    }

    getSources = () => {
        API.get('/customers/contacts/sources').then(res => {
            this.setState({
                sources: res.data,
                isLoading: false,
            });
        });
    }

    render () {

        const { formData, sources, isLoading } = this.state;

        if (isLoading) {
            return <LoadingCircle />
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <PaddedPaper>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Name *"
                                    name="formData.name"
                                    value={formData.name}
                                    onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Textarea
                                    fullWidth
                                    label="Desc *"
                                    name="formData.desc"
                                    key="formData.desc"
                                    value={formData.desc}
                                    //onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                    onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                    rows={4}
                                />
                            </Grid>
                            <Grid item xs={12} className='buttonRow'>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>this.props.deployConfirmation(
                                        'Are you sure you want to add this contact source?',
                                        'Add Contact Source',
                                        this.submitNewSource
                                    )}
                                    disabled={!formData.name || !formData.desc || _.find(sources, {cs_source: formData.name})}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <CiDataTable
                        rows={sources}
                        config={{key: 'cs_id'}}
                        columns={[
                            {heading: 'Name', field: 'cs_source', sizeToContent: true},
                            {heading: 'Description', field: 'cs_desc', truncate: true, info: i => <span style={{whiteSpace: 'pre-wrap'}}>{i.cs_desc}</span>},
                        ]}
                    />
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback)      => dispatch(deployConfirmation(content, title, callback)),
    }
}

export default connect(null, mapDispatchToProps)(ContactSources);