

import React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import API from "API";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import { closeDialog, deployDialog } from "Actions/Dialog/Dialog";
import { deploySnackBar } from "Actions/SnackBar/SnackBar";
import { deployConfirmation } from "Actions/Confirmation/Confirmation";
import { handleChange } from "Functions/FormFunctions";

const initialState = () => ({
    formData: {
        firstName: '',
        lastName: '',
        company: '',
        position: '',
        telephone: '',
        mobile: '',
        email: '',
    },
    contactId: 0,
    formErrors: {},
    isLoading: true,
    contact: null,
});

class PhoneBookForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
    }

    componentDidMount = () => {
        if (this.props.contact) {
            let contact = this.props.contact,
                contactId = this.props.contactId;
            this.setState({
                contact,
                contactId,
                formData: {
                    ...this.state.formData,
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    position: contact.position,
                    telephone: contact.telephone,
                    mobile: contact.mobile,
                    email: contact.email,
                    company: contact.company,
                },
                isLoading: false,
            });
        } else {
            this.setState({
                isLoading: false,
                formData: {
                    ...this.state.formData,
                    telephone: this.props.telephone ?? this.state.formData.telephone,
                    mobile: this.props.mobile ?? this.state.formData.mobile
                }
            });
        }
    };

    handleCustomCompany = (v) => {
        if (v) {
            // if (v.__isNew__) {
            //     this.setState({
            //         formData: {
            //             ...this.state.formData,
            //             company: v.value,
            //         },
            //     });
            // } else {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        company: v.value,
                    },
                });
            // }
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    company: '',
                },
            });
        }
    };

    submit = () => {
        let route = this.state.contactId ? `/voip/phoneBook/${this.state.contactId}` : `/voip/phoneBook`;
        API.post(route, this.state.formData)
        .then((result) => {
            if (result?.data?.success) {
                this.props.deploySnackBar("success", `You have successfully ${this.state.contactId ? "updated" : "created"} the contact`);
                if(this.props.callback) {
                    this.props.callback();
                }
                this.props.closeDialog();
            }
        });
    };

    render() {
        const { companyList } = this.props;
        const { formErrors, formData, isLoading, contact } = this.state;
        return isLoading ? (
            <LoadingCircle />
        ) : (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <AutoCompleteSelect
                                                    creatable
                                                    creatableMessage={(userInput) => `Company: ${userInput}`}
                                                    onChange={(v) => this.handleCustomCompany(v)}
                                                    label={`Company`}
                                                    options={companyList ?? []}
                                                    value={formData.company}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextField
                                        id="firstName"
                                        name="firstName"
                                        label="First Name"
                                        value={formData?.firstName}
                                        error={formErrors && formErrors["firstName"] && true}
                                        helperText={formErrors && formErrors["firstName"]}
                                        onChange={this.handleChange}
                                        margin="none"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextField
                                        id="lastName"
                                        name="lastName"
                                        label="Last Name"
                                        value={formData?.lastName}
                                        error={formErrors && formErrors["lastName"] && true}
                                        helperText={formErrors && formErrors["lastName"]}
                                        onChange={this.handleChange}
                                        margin="none"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextField
                                        id="position"
                                        name="position"
                                        label="Contact Position"
                                        value={formData?.position}
                                        error={formErrors && formErrors["position"] && true}
                                        helperText={formErrors && formErrors["position"]}
                                        onChange={this.handleChange}
                                        margin="none"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextField
                                        id="mobile"
                                        name="mobile"
                                        label="Mobile"
                                        value={formData?.mobile}
                                        error={formErrors && formErrors["mobile"] && true}
                                        helperText={formErrors && formErrors["mobile"]}
                                        onChange={this.handleChange}
                                        margin="none"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextField
                                        id="telephone"
                                        name="telephone"
                                        label="Telephone"
                                        value={formData?.telephone}
                                        error={formErrors && formErrors["telephone"] && true}
                                        helperText={formErrors && formErrors["telephone"]}
                                        onChange={this.handleChange}
                                        margin="none"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email Address"
                                        value={formData?.email}
                                        error={formErrors && formErrors["email"] && true}
                                        helperText={formErrors && formErrors["email"]}
                                        onChange={this.handleChange}
                                        margin="none"
                                        fullWidth
s                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid item xs={12} align="right">
                        <Button onClick={() => this.props.closeDialog()} variant="outlined" color="default" style={{ marginRight: 10 }}>
                            <FAIcon icon="times" size={15} button />
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            disabled={Object.values(formData).every((x) => x === null || x === "")}
                            onClick={() => this.props.deployConfirmation(`Are you sure you want to ${contact ? "update" : "add"} this contact?`, `${contact ? 'Update' : 'Add'} Contact`, () => this.submit())}
                            variant="contained"
                            style={{ color: "white" }}
                        >
                            <FAIcon icon="check" size={15} buttonPrimary />
                            {contact ? "Update" : "Add"} Contact
                        </Button>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, title, variant = 'standard', size = 'md', style = {}) => dispatch(deployDialog(content, title, variant, size, style))
    };
};

export default connect(null, mapDispatchToProps)(PhoneBookForm);
