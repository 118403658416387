import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { Box, Typography } from "@material-ui/core";
import uuid from "uuid";
import { colors } from "Helpers/ColourHelper";

export default function(props){
    
    let [options, setOptions] = useState(props.options || []);

    const handleOnDragEnd = result => {
        if (!result.destination) return;
        const items = Array.from(options);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setOptions(items)
        if (props.onChange) props.onChange(items);
    }

    if (options.length === 0) return null
    return (
        <Box>
            {props.label &&
                <Typography variant="h6">{props.label}</Typography>
            }
            <DragDropContext onDragEnd={handleOnDragEnd} key={`DragDropContext-${props?.key || uuid()}`}>
                <Droppable droppableId="droppableList">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {options.map(({label, value}, index) => 
                                <Draggable key={value} draggableId={`draggableListItem${index}`} index={index}>
                                    {(_provided) => (
                                        <div
                                            {..._provided.draggableProps}
                                            {..._provided.dragHandleProps}
                                            ref={_provided.innerRef}
                                            style={{
                                                ..._provided.draggableProps.style,
                                                ...props.itemStyle
                                            }}
                                            key={`Droppable-${value}`}
                                            value={value}
                                        >{label}</div>    
                                    )}
                                </Draggable>
                            )}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Box>
    )

};