import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import DataTable       from 'Components/Common/DataTables/CiDataTable';
import AllIcon         from 'Components/Common/Icons/AllIcon';
import Textarea        from 'Components/Common/Inputs/Textarea';
import LoadingCircle   from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper     from 'Components/Common/Paper/PaddedPaper';
import WorksOrderNotes from 'Components/WorksOrder/ViewWorksOrder/sections/WorksOrderNotes';
import BackButton      from 'Components/Common/Buttons/BackButton';

import ScanDialog from './ScanDialog';

import { colors }    from 'Helpers/ColourHelper';
import icons         from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';
import { pdfFromBase64, pdfDataFromBase64 } from 'Helpers/PDFHelper';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar }            from 'Actions/SnackBar/SnackBar';

import { Button, Dialog, DialogContent, DialogTitle, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import logo from 'Assets/Images/clenaware_logo_icon.png';

import { printA4, printLabel } from 'Actions/Print/Print';

const initialState = {
    isLoading:          true,
    access:             {},
    pick:               {},
    existingPick:       false,
    startPick:          false,
    notes:              '',
    importantNoteShown: false,
}


class PickWorksOrder extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount(){
        this.getAccess();
        this.getPickData();
    }

    displayImportantInfo = () => {
        this.setState({importantNoteShown: true}, () => {
            this.props.deployDialog(
            <>
                <Typography component="p">
                    {this.state.worksOrder?.wo_important_note && this.state.worksOrder?.wo_important_note.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
                </Typography>
                <Button variant='outlined' style={{float: 'right'}} onClick={this.props.closeDialog}>Close</Button>
            </>
            , 'Important Information', null, 'sm', {backgroundColor : '#fcc6c5', color: '#000'})
        })
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/works-order:pick')
        ])
        .then(([pickRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    pick: pickRes.data.has_access || false,
                }
            });
        })
    }

    getPickData = () => {
        API.get(`/worksOrders/${this.props.match.params.woId}`)
        .then(res => {
            this.setState({
                ...this.state,
                isLoading:  false,
                worksOrder: res.data,
                pick:       res.data.pick
            })
            if (!this.state.importantNoteShown && res.data.wo_important_note) this.displayImportantInfo();
        });
    }

    handleStartPick = () => {
        // api call to start worksOrder pick
        API.post(`/worksOrders/${this.props.match.params.woId}/picks/${this.state.pick.wop_id}/start`)
        .then(res => {
            if (res.data.errors){
                this.setState({existingPick: true});
            } else {
                API.get(`/worksOrders/${this.props.match.params.woId}`).then(res => {
                    if (this.state.notes) this.handleAddNotes();
                    this.setState({
                        startPick:  true,
                        worksOrder: res.data,
                        pick:       res.data.pick
                    });
                })
            }
        });
    }

    endPick = () => {
        API.post(`/worksOrders/${this.props.match.params.woId}/picks/${this.state.pick.wop_id}/end`)
        .then(res => {
            this.setState({
                startPick: false,
            },this.getPickData);
        });
    }

    closePick = (notes=null) => {
        //if (notes && notes != '') API.post(`/worksOrders/${this.props.match.params.woId}/addNotes`, {notes})
        this.endPick()
    }

    handleSavePick = detail => {
        API.post(`/worksOrders/${this.props.match.params.woId}/picks/${this.state.pick.wop_id}/pick`, detail)
        .then(this.getPickData);
    }

    handleNoteChange = (e) => {this.setState({notes: e.target.value})}

    handleAddNotes = () => {
        API.post(`/worksOrders/${this.props.match.params.woId}/addNotes`, {notes: this.state.notes, stage: 'Picking'})
        .then(res => { this.setState({ notes: initialState.notes }, this.getPickData); });
    }

    downloadPartLabel = (id, part_number) => () => {
        API.get('/parts/' + id + '/stockLabel',{
            params: {
              type: 'partLabel'
            }
        })
        .then(result => {
            if(result.data) {
                this.props.printLabel(pdfDataFromBase64(result.data.pdf), 'part_label_'+part_number+'.pdf');
            }
        });
    }

    downloadWorksOrderHeader = () => {
        API.get(`/worksOrders/${this.props.match.params.woId}/headerSheet`)
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        })
    }

    render(){

        const { isLoading, worksOrder, pick, existingPick, startPick, notes } = this.state;
        if (isLoading) return (<LoadingCircle />);
        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        Works Order Picking
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container xs={12} spacing={3}>
                        <Grid item style={{padding:'0 0.5em'}} xs={12}>
                            <PaddedPaper style={{textAlign:'center'}}>
                                <Typography variant="h5" style={{textAlign:'left', paddingTop:'0.5em'}}>Reference</Typography>
                                <Typography variant="h4" style={{textAlign:'left'}}>{pick?.full_reference}</Typography>
                            </PaddedPaper>
                        </Grid>
                        <Grid item style={{padding:'0 0.5em', marginTop: '1em'}} xs={12}>
                            <PaddedPaper style={{textAlign:'center'}}>
                                <Grid container style={{alignItems: 'flex-end'}}>
                                    <Grid item>
                                        <img src={worksOrder && worksOrder.part?.default_image?.thumbnail_file_path ? worksOrder.part?.default_image?.thumbnail_file_path : logo} alt={worksOrder.part.part_description} style={{maxHeight:70, maxWidth:70}} />
                                    </Grid>
                                    <Grid item style={{paddingLeft: 25}}>
                                        <Typography variant="h5" style={{textAlign:'left', paddingTop:'0.5em'}}>Sub Assembly</Typography>
                                        <Typography variant="h4" style={{textAlign:'left'}}>{worksOrder.part.part_number} - {worksOrder.part.part_description}</Typography>
                                    </Grid>
                                </Grid>
                                
                            </PaddedPaper>
                        </Grid>
                        <Grid item style={{padding:'0 0.5em', marginTop: '1em'}} xs={4} >
                            <PaddedPaper style={{textAlign:'center'}}>
                                <AllIcon icon={icons.year} size='xxlarge' />
                                <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Order Date</Typography>
                                <Typography variant="h4" style={{textAlign:'center'}}>{clenyDate(worksOrder.wo_date)}</Typography>
                            </PaddedPaper>
                        </Grid>
                        <Grid item style={{padding:'0 0.5em', marginTop: '1em'}} xs={4} >
                            <PaddedPaper style={{textAlign:'center'}}>
                                <AllIcon icon={icons.parts} size='xxlarge' />
                                <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Parts</Typography>
                                <Typography variant="h4" style={{textAlign:'center'}}>{
                                    _.filter( pick.details, i => i.wopd_status == 'Not Required' || i.wopd_picked === i.wopd_required).length
                                }/{pick.details.length}</Typography>
                            </PaddedPaper>
                        </Grid>
                        <Grid item style={{padding:'0 0.5em', marginTop: '1em'}} xs={4} >
                            <PaddedPaper style={{textAlign:'center'}}>
                                <AllIcon icon={icons.stock} size='xxlarge' />
                                <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Qty</Typography>
                                <Typography variant="h4" style={{textAlign:'center'}}>{_.sumBy(pick.details, i => i.wopd_status == 'Not Required' ? parseFloat(i.wopd_required) : parseFloat(i.wopd_picked) ).toFixed(2)}/{_.sumBy(pick.details, i => parseFloat(i.wopd_required) ).toFixed(2)}</Typography>
                            </PaddedPaper>
                        </Grid>
                        <Grid item style={{padding:'0 0.5em', marginTop: '1em'}} xs={12} >
                            <PaddedPaper>
                                <DataTable
                                    config={{
                                        key:    'wopd_id',
                                    }}
                                    columns={[
                                        {
                                            heading:        '',
                                            field:          'part.default_image.thumbnail_file_path',
                                            fieldFormat:    'image',
                                            sizeToContent:  true,
                                        },
                                        {
                                            heading:        'Part Number',
                                            field:          rowData => rowData.part?.part_number,
                                            sizeToContent:  true,
                                        },
                                        {
                                            heading:        'Part Description',
                                            field:          rowData => rowData.part?.part_description,
                                            truncate:       200,
                                        },
                                        {
                                            heading:        'Required',
                                            field:          rowData => parseFloat(rowData.wopd_required).toFixed(3),
                                            alignment:      'center',
                                            sizeToContent:  true,
                                        },
                                        {
                                            heading:        'Picked',
                                            field:          rowData => rowData.wopd_status == 'Not Required' ? '-' : parseFloat(rowData.wopd_picked).toFixed(3),
                                            alignment:      'center',
                                            sizeToContent:  true,
                                        },
                                        {
                                            heading:        '',
                                            field:          rowData => rowData.wopd_status !== 'Pending' && <AllIcon icon={icons.picking} color={
                                                rowData.wopd_status == 'Partial Pick' || rowData.wopd_status == 'Stock Missing' ?
                                                    colors.red : colors.green
                                            } />,
                                            alignment:      'center',
                                            sizeToContent:  true,
                                        },
                                        {
                                            heading:        'Status',
                                            field:          'wopd_status',
                                            alignment:      'center',
                                            sizeToContent:  true,
                                        },
                                        {
                                            actions: rowData => ([
                                                {
                                                    name:    'Print Part Label',
                                                    icon:    icons.print,
                                                    onClick: this.downloadPartLabel(rowData.part?.part_id, rowData.part?.part_number)
                                                }
                                            ])
                                        }
                                    ]}
                                    rows={_.orderBy(pick.details, ['wo_date'],['asc'])}
                                />
                            </PaddedPaper>
                        </Grid>
                        <Grid item style={{padding:'0 0.5em', marginTop: '1em'}} xs={12} >
                            <PaddedPaper>
                                {worksOrder.notes.length > 0 ?
                                    <ExpansionPanel>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>Notes</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <WorksOrderNotes worksOrder={worksOrder}/>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel> : 
                                    <Typography variant='h6'>Notes</Typography>
                                }
                                <Textarea
                                    label="Notes"
                                    value={notes}
                                    onChange={this.handleNoteChange}
                                />
                                <div className='buttonRow'>
                                    <Button onClick={this.handleAddNotes} variant="contained" color="primary"
                                        disabled={!notes || notes == ''}
                                    >Add Notes</Button>
                                </div>
                            </PaddedPaper>
                        </Grid>
                        <Grid item style={{padding:'0 0.5em', marginTop: '1em'}} xs={12} >
                            {existingPick && 
                                <Typography variant="body2" style={{color:colors.red}}>
                                    This pick is already being picked.
                                </Typography>
                            }
                            <div className="buttonRow" style={{marginTop: 0}}>
                                <BackButton/>
                                <Button
                                    variant="contained"
                                    color='primary'
                                    onClick={this.downloadWorksOrderHeader}
                                >
                                    <AllIcon icon={icons.worksOrder} color={colors.white}/>
                                    Header Sheet
                                </Button>
                                <Button
                                    variant="contained"
                                    color='primary'
                                    onClick={() => this.props.history.push(`/WorksOrders/view/${this.props.match.params.woId}`)}
                                >
                                    <AllIcon icon={icons.worksOrder} color={colors.white}/>
                                    Works Order
                                </Button>
                                {worksOrder.wo_status === 'Picking' &&
                                    <Button 
                                        variant="contained" 
                                        color="primary"
                                        onClick={this.handleStartPick}
                                        disabled={existingPick}
                                    >
                                        Start Pick
                                    </Button>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog 
                    open={startPick} 
                    onClose={this.closePick} 
                    fullScreen
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title" style={{backgroundColor: colors.blue,  fontWeight: 'bold'}}> 
                        <Grid container spacing={2} style={{
                            alignContent:   'center',
                            justifyContent: 'space-between',
                            alignItems:     'center'
                        }}>
                            <Grid item>
                                Pick for {pick.full_reference}
                            </Grid>
                            <Grid item>
                                <AllIcon 
                                    icon    ={icons.close} 
                                    color   ={colors.white}
                                    onClick ={this.closePick}
                                />
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={{height:'calc(100vh - 76px)', width:'100vw', Maxwidth: '100vw'}}>
                        <ScanDialog 
                            pick        = {pick}
                            onClose     = {this.closePick}
                            onChange    = {this.handleSavePick}
                            woId        = {worksOrder.wo_id}
                        />
                    </DialogContent>
                </Dialog>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                                          => dispatch(closeDialog()),
    deployDialog:       (content, title, variant, size, style=null) => dispatch(deployDialog(content, title, variant, size, style)),
    deployConfirmation: (message, title, success)                   => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)                              => dispatch(deploySnackBar(variant, msg)),
    printLabel:         (data, file)                                => dispatch(printLabel(data, file)),
})

export default connect(null, mapDispatchToProps)(PickWorksOrder);


