import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import _ from 'lodash';

import { find } from 'lodash';

import { useInput } from 'Functions/FormFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { colors } from 'Helpers/ColourHelper';

import API from 'API';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';

import { closeDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

function ConfirmationPreview({lastUsed, serialNumberArr, duplicates=[]}) {
    return(
        <>
            <Typography variant="body2" className="pb-2">
                <strong>Last used:</strong> {lastUsed}
            </Typography>
            <Typography variant="body2" className="pb-2">
                {duplicates.length > 0 ?
                    'Generate Serial Numbers, skipping duplicates?':
                    'Are you sure you want to generate these serial numbers?'
                }
            </Typography>
            {serialNumberArr.map((el, idx) => (
                <Typography variant="body2" key={idx} style={{color: _.find(duplicates, i=>i.psn_serial_number==el) && colors.red }}>
                    {el}
                </Typography>
            ))}
        </>
    );
}

const GenerateSerialNumberDialog = ({modelTypeId, modelTypes, buildLocations, loadComponentData, nextUniqueNumber, years, yearIssued}) => {
    const initialState = {
        formErrors:{},
        isLoading: false
    };

    const [state, setState] = useState(initialState);

    const { formErrors, isLoading } = state;

    const dispatch = useDispatch();

    const { value: buildLocationValue, bind: buildLocationBind, reset: buildLocationReset } = useInput('');
    const { value: yearValue, bind: yearBind, reset: yearReset } = useInput(yearIssued);
    const { value: serialNumberQtyValue, bind: serialNumberQtyBind, reset: serialNumberQtyReset } = useInput('');
    const { value: serialUniqueNumberValue, bind: serialUniqueNumberBind, reset: serialUniqueNumberReset } = useInput(nextUniqueNumber);

    const handleClose = () => dispatch(closeDialog());

    const resetFields = () => {
        serialNumberQtyReset();
        buildLocationReset();
        serialUniqueNumberReset();
        yearReset();
    }

    const handleSubmit = () => {
        let formData = {
            modelTypeId: modelTypeId,
            buildLocationId: buildLocationValue,
            serialNumberQty: serialNumberQtyValue,
            newUniqueNumber: serialUniqueNumberValue,
            yearIssued: yearValue,
            preview: true
        };

        API.post('/parts/serialNumbers/preview', formData)
        .then(result => {
            if(result.data.errors) {
                if (_.find(result.data.errors, i=>i.err=="duplicate")) {
                    let lastUsed = find(modelTypes, {pmt_id: modelTypeId}).last ?? '-';
                    let component = <ConfirmationPreview 
                                        lastUsed={lastUsed}
                                        serialNumberArr={_.find(result.data.errors, i=>i.err=="duplicate").msg[0]}
                                        duplicates={_.find(result.data.errors, i=>i.err=="duplicate").msg[1]}
                                    />;

                    dispatch(deployConfirmation(component, 'Duplicate Serial Numbers', () => submit(formData, true)));
    
                } else {
                    let formErrors = formatValidationErrors(result.data.errors);
                    setState(state => ({
                        ...state,
                        formErrors,
                        isLoading: false
                    }));
                }


            } else {
               let lastUsed = find(modelTypes, {pmt_id: modelTypeId}).last ?? '-';

                let component = <ConfirmationPreview 
                    lastUsed={lastUsed}
                    serialNumberArr={result.data.serialNumberArr}
                />;
        
                dispatch(deployConfirmation(component, 'Generate Serial Numbers', () => submit(formData))); 
            }
            
        });
    }

    const submit = (formData, skip=false) => {
        setState(state => ({
            ...state,
            isLoading: true
        }));

        formData = {
            ...formData,
            preview: false,
            skip
        };

        API.post('/parts/serialNumbers', formData)
        .then(result => {

            if(result.data.errors) {

                setState(state => ({
                    ...state,
                    formErrors: formatValidationErrors(result.data.errors),
                    isLoading: false
                }));

            } else if (result.data.success) {

                resetFields();
                if(loadComponentData) loadComponentData();
                handleClose();
                dispatch(deploySnackBar('success', 'Serial numbers have been successfully generated!'));

            }

        });
    }

    return (
        <>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <>
                    <FormControl fullWidth>
                        <AutoCompleteSelect 
                            id="buildLocation"
                            name="buildLocation"
                            label='Build Location *'
                            options={buildLocations} 
                            {...buildLocationBind}
                            error={formErrors && formErrors['buildLocation'] && true}
                            errorText={formErrors && formErrors['buildLocation']}
                            noClear
                        />
                    </FormControl>
                    <TextField 
                        id="qty"
                        name="qty"
                        label="Number of Serial Numbers"
                        margin="normal"
                        type="number"
                        fullWidth
                        {...serialNumberQtyBind}
                        error={formErrors && formErrors['qty'] && true}
                        helperText={formErrors && formErrors['qty']}
                    />
                    <FormControl fullWidth>
                        <AutoCompleteSelect 
                            options={years}
                            name='yearIssued'
                            label='Year Issued'
                            error={formErrors && formErrors['yearIssued'] && true}
                            errorText={formErrors && formErrors['yearIssued']}
                            {...yearBind}
                            noClear
                        />
                    </FormControl>
                    <TextField 
                        id="newUniqueNumber"
                        name="newUniqueNumber"
                        label="Next Number to Use"
                        margin="normal"
                        type="number"
                        fullWidth
                        {...serialUniqueNumberBind}
                        error={formErrors && formErrors['newUniqueNumber'] && true}
                        helperText={formErrors && formErrors['newUniqueNumber']}
                    />
                    <Typography variant="caption" gutterBottom>
                        <FALightIcon icon='info-circle' size='small' /> The 'Next Number to Use' is pre-populated with the next available serial number, change this number if you need to skip serial numbers
                    </Typography>
                    <div className='buttonRow'>
                        <Button 
                            variant="outlined"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleSubmit}
                            disabled={buildLocationValue !== '' && serialNumberQtyValue !== '' && serialUniqueNumberValue !== '' ? false : true}
                        >
                            Generate
                        </Button>
                    </div>
                </>
            )}
        </>
    );
}

export default GenerateSerialNumberDialog;