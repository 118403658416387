import React, { PureComponent } from 'react';
import API                      from 'API';
import _                        from 'lodash';

import { Grid, Typography } from '@material-ui/core/';

import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';
import DataTable     from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import InfoIcon      from 'Components/Common/Icons/InfoIcon';
import AllIcon       from 'Components/Common/Icons/AllIcon';

import DateRangeDialog from './DateRangeDialog';

import { colors }                 from 'Helpers/ColourHelper';
import icons                      from 'Helpers/IconHelper';
import { colorNumbers, currency } from 'Helpers/NumberHelper';
import {getFiscalYearTimestamps}  from 'Helpers/ReportsHelper';

const initialState = { 
    filter:      {
        type:       'fyear',      
        from:       getFiscalYearTimestamps().start.format('YYYY-MM-DD'), 
        to:         getFiscalYearTimestamps().end.format('YYYY-MM-DD'), 
    },
    formErrors:  {},
    data:        [],
    isLoading:   true,
 }

const InfoBlock = ({title, icon, value}) => {
    return (
        <Grid item xs={2}>
            <PaddedPaper  style={{textAlign: 'center'}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}><Typography variant="h6" style={{textAlign: 'center'}}>{title}</Typography></Grid>
                    <Grid item xs={12}><AllIcon icon={icon} noMargin size='large' /></Grid>
                    <Grid item xs={12}><Typography variant="h6"  style={{textAlign: 'center'}}>{value}</Typography></Grid>
                </Grid>
            </PaddedPaper>
        </Grid>
    )
} 

class StockReportsStock extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getData();
    }

    handleSetFilterParams = (filter) => { this.setState({filter, showResults: true}, this.getData)} 

    getData = () => {
        API.get('/reports/stocksReport/stock', {params: {...this.state.filter, partId: this.props.partId}})
        .then(res => {
            this.setState({
                data:      res.data, 
                isLoading: false
            });
        })
    }

    handleStockCorrection = (r) => {
        API.post('/stock/correction/withPart', {partId: r.q})
        .then(res => {
            window.location = '/stock/correction/edit/' + res.data.sc_id;
        })
    }

    render() {
        const { filter, formErrors, isLoading, data } = this.state;

        return (
            <Grid container spacing={3} xs={12} style={{width: '100%'}}>
               <Grid item xs={12}>
                    <PaddedPaper style={{height: '100%'}}>
                        <DateRangeDialog 
                            callback={this.handleSetFilterParams} 
                            error={formErrors} 
                            formData={filter}
                            inline
                        />
                    </PaddedPaper>
                </Grid>
                {isLoading ?
                    <Grid item xs={12}><LoadingCircle /></Grid> :
                    <>  
                        <Grid item xs={12}>  
                            <Grid container spacing={2}>
                                <InfoBlock
                                    title='Opening Stock'
                                    icon={icons.stock}
                                    value={<>
                                        <AllIcon icon={icons.parts}/>{colorNumbers(data.length)}<br/>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(data, r => r.h - parseFloat(r.j + r.k)).toFixed(3))}
                                    </>}
                                />
                                <InfoBlock
                                    title='Incoming'
                                    icon={icons.plus}
                                    value={<>
                                        <AllIcon icon={icons.parts}/>{colorNumbers(_.filter(data, i => i.j > 0).length)}<br/>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(data, 'j').toFixed(3))}
                                    </>}
                                />
                                <InfoBlock
                                    title='Outgoing'
                                    icon={icons.minus}
                                    value={<>
                                        <AllIcon icon={icons.parts}/>{colorNumbers(0 - _.filter(data, i => i.k < 0).length)}<br/>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(data, 'k').toFixed(3))}
                                    </>}
                                />
                                <InfoBlock
                                    title='Closing Stock'
                                    icon={icons.stock}
                                    value={<>
                                        <AllIcon icon={icons.parts}/>{colorNumbers(data.length)}<br/>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(data, 'h').toFixed(3))}
                                    </>}
                                />
                                <InfoBlock
                                    title='Change'
                                    icon={icons.stock}
                                    value={<>
                                        <AllIcon icon={icons.parts}/>{colorNumbers(_.filter(data, i => i.j > 0 || i.k < 0).length)}<br/>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(data, i => i.j + i.k).toFixed(3))}
                                    </>}
                                />
                                <InfoBlock
                                    title='Minus Stock'
                                    icon={icons.warning}
                                    value={<>
                                        <AllIcon icon={icons.parts}/>{colorNumbers(0 - _.filter(data, i => i.h < 0).length)}<br/>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(_.filter(data, i => i.h < 0), 'h').toFixed(3))}
                                    </>}
                                />
                                <InfoBlock
                                    title='Opening Stock'
                                    icon={icons.poundSign}
                                    value={currency(_.sumBy(data, i => parseFloat( i.h - parseFloat( i.j + i.k) ) * i.t))}
                                />
                                <InfoBlock
                                    title='Incoming'
                                    icon={icons.poundSign}
                                    value={currency(_.sumBy(data, i =>i.j * i.t))}
                                />
                                <InfoBlock
                                    title='Outgoing'
                                    icon={icons.poundSign}
                                    value={currency(_.sumBy(data, i =>i.k * i.t))}
                                />
                                <InfoBlock
                                    title='Closing Stock'
                                    icon={icons.poundSign}
                                    value={currency(_.sumBy(data, i =>i.h * i.t))}
                                />
                                <InfoBlock
                                    title='Change'
                                    icon={icons.poundSign}
                                    value={currency(_.sumBy(data, i => parseFloat(i.j + i.k) * i.t))}
                                />
                                <InfoBlock
                                    title='Minus Stock'
                                    icon={icons.poundSign}
                                    value={currency(_.sumBy(_.filter(data, i => i.h < 0), i =>i.h * i.t))}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DataTable 
                                config={{
                                    key: 'q',
                                    pagination: true,
                                    responsiveimportant: true,
                                    rowsPerPage: 25,
                                    filter: true,
                                    filterMod: {
                                        filterInPagination:     true,
                                        clearable:              true,
                                        colSpan:                4,
                                    },
                                    sticky:true
                                }}
                                columns={[
                                    {
                                        field:        'r',
                                        fieldFormat: 'image',
                                        sizeToContent: true,
                                        filter:         'custom',
                                        filterMod: {
                                            dataRef:   (search, rowData) => {return `${rowData.w}|${rowData.e}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                            label:     'Search Parts'
                                        }
                                    },
                                    {
                                        heading:  'Part/Description',
                                        field:    i => <>{i.w}<br/>{i.e}</>,
                                        truncate: true,
                                    },
                                    {
                                        heading:       <>SSR <InfoIcon info='Supplier Stock Return' color={colors.white}/></>,
                                        field:         'y',
                                        dataRef:       'y',
                                        alignment:     'right',
                                        sizeToContent: true,
                                        fieldFormat:   'colorNumbers:decimal:3',
                                    },
                                    {
                                        heading:       <>PPO <InfoIcon info='Part Purchase Order' color={colors.white}/></>,
                                        field:         'u',
                                        dataRef:       'u',
                                        alignment:     'right',
                                        sizeToContent: true,
                                        fieldFormat:   'colorNumbers:decimal:3',
                                    },
                                    {
                                        heading:       <>Sales</>,
                                        field:         'i',
                                        dataRef:       'i',
                                        alignment:     'right',
                                        sizeToContent: true,
                                        fieldFormat:   'colorNumbers:decimal:3',
                                    },
                                    {
                                        heading:       <>Ret <InfoIcon info='Credit Notes & Greenaware' color={colors.white}/></>,
                                        field:         'o',
                                        dataRef:       'o',
                                        alignment:     'right',
                                        sizeToContent: true,
                                        fieldFormat:   'colorNumbers:decimal:3',
                                    },
                                    {
                                        heading:       <>WOC <InfoIcon info='Works Order Component' color={colors.white}/></>,
                                        field:         'a',
                                        dataRef:       'a',
                                        alignment:     'right',
                                        sizeToContent: true,
                                        fieldFormat:   'colorNumbers:decimal:3',
                                    },
                                    {
                                        heading:       <>WOB <InfoIcon info='Works Order Build' color={colors.white}/></>,
                                        field:         'p',
                                        dataRef:       'p',
                                        alignment:     'right',
                                        sizeToContent: true,
                                        fieldFormat:   'colorNumbers:decimal:3',
                                    },
                                    {
                                        heading:       <>Movement</>,
                                        field:         'd',
                                        dataRef:       'd',
                                        alignment:     'right',
                                        sizeToContent: true,
                                        fieldFormat:   'colorNumbers:decimal:3',
                                    },
                                    {
                                        heading:       <>Correction</>,
                                        field:         'f',
                                        dataRef:       'f',
                                        alignment:     'right',
                                        sizeToContent: true,
                                        fieldFormat:   'colorNumbers:decimal:3',
                                    },
                                    {
                                        heading:       <>Opening <br/> Stock</>,
                                        field:         r => r.h - parseFloat(r.j + r.k),
                                        dataRef:       r => r.h - parseFloat(r.j + r.k),
                                        fieldFormat:   'colorNumbers:decimal:3',
                                        alignment:     'right',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       'Stock +',
                                        field:         'j',
                                        dataRef:       'j',
                                        fieldFormat:   'colorNumbers:decimal:3',
                                        alignment:     'right',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       'Stock -',
                                        field:         'k',
                                        dataRef:       'k',
                                        fieldFormat:   'colorNumbers:decimal:3',
                                        alignment:     'right',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       <>Closing <br/> Stock</>,
                                        field:         'h',
                                        dataRef:       'h',
                                        fieldFormat:   'colorNumbers:decimal:3',
                                        alignment:     'right',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       'Change',
                                        field:         r => r.j + r.k,
                                        dataRef:       r => r.j + r.k,
                                        fieldFormat:   'colorNumbers:decimal:3',
                                        alignment:     'right',
                                        sizeToContent: true,
                                    },
                                    {
                                        actions: r => (
                                            [
                                                {
                                                    name:    'Stock Correction',
                                                    icon:    icons.stock,
                                                    onClick: this.handleStockCorrection,
                                                },
                                                {
                                                    name:   'View',
                                                    icon:   icons.search,
                                                    link: `/parts/view/${r.q}`,
                                                }, 
                                            ]
                                        )
                                    }
                                ]}
                                rows={_.orderBy(data, 'w')}
                            />
                        </Grid>
                    </>
                }
            </Grid>
        )
    }
};

export default StockReportsStock;

