import IconHelper from 'Helpers/IconHelper';
import React from 'react';
import _ from 'lodash';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';

class GenericTable extends React.Component {

    cleanLink = (str, item) => {
        const regex = /\${(.*?)}/g;
        const matches = [];
        let match;
        while ((match = regex.exec(str)) !== null) {
            // The captured group (i.e., text within ${ and }) is at index 1
            matches.push(match[1]);
        }
        _.each(matches, i => {
            str = str.replace(`\${${i}}`, item[i]);
        })
        return str;
    }

    cleanField = (field, item) => {
        switch (field.type) {
            case 'evalFunction':
                return eval(field.function)(item);
            case 'replace':
                return this.cleanLink(field.field, item);
        }
    }

    render() {
        const { data, layout } = this.props;

        return (
            <CiDataTable
                config={layout.config}
                rows={data}
                columns={
                    [
                        ..._.map(layout.columns, i => ({
                            ...i,
                            field: i.field ? (_.isObject(i.field) ? rowData => this.cleanField(i.field, rowData) : i.field) : null,
                        })),
                        layout.actions.length > 0 && {
                            actions: i => _.map(layout.actions, a => ({
                                name: a?.name ?? 'View',
                                icon: a?.icon ?? IconHelper.view,
                                link: a?.link ? this.cleanLink(a.link, i) : '',
                            }))
                        }
                    ]
                }
            />
        )
    }
}

export default GenericTable;