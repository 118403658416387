import _ from 'lodash';
import * as loadingActionTypes from '../../Actions/Loading/Types/Types';

function loadingReducer(state = {}, action) {
    switch(action.type) {
        case loadingActionTypes.LOADING_START:
            return {
                ...state.loading,
                [action.payload.requestId]: true
            };
        case loadingActionTypes.LOADING_FINISH:
            return _.omit(state, action.payload.requestId);
        default:
            return state;
    }
}

export default loadingReducer;