import React from 'react';
import _ from "lodash";

import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { capitalize, isTablet } from "Functions/MiscFunctions";
import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";

const WildixCallDynamicIsland = ({ activeCalls, call, callData, getCallActions, getCallStatusClass, handleToggleDrawer, priorityQueues }) => {
    const [showInternalCall, setShowInternalCall] = useState(false),
        [showPriorityQueue, setShowPriorityQueue] = useState(false);

    const popOut = (localStorage?.getItem?.("voipPopOut") ?? "false") === "true";

    const data = callData?.find?.((c) => c.ch === call.getChannel())?.dt ?? null;

    const callDirection = call?.getDirection?.(),
        callDuration = call?.getFormattedDuration?.(),
        callName = call?.getCalleeName?.(),
        callNumber = call?.getCalleeNumber?.(),
        callState = call?.getFormattedState?.(),
        isAnonymous = call?.getCalleeNumber?.() === "anonymous",
        isIncoming = call?.isIncoming?.() ?? false,
        isExternal = !isIncoming || (isIncoming && (callName.startsWith("+") || callName.startsWith("0"))),
        isInternal = call?.getCalleeNumber?.()?.length === 3,
        isOnHold = call.isOnHold() ?? false,
        isRinging = call?.isRinging?.() ?? false,
        isPopOut = popOut && isRinging && isIncoming;

    let priorityCount = 0;
    if(priorityQueues) {
        priorityQueues.forEach((pq) => {
            priorityCount += _.size(pq?.users ?? 0);
        });
    }

    let internalCallNotifier = null;
    if(!isRinging && !isOnHold) {
        const internalCalls = activeCalls?.filter?.((c) => c?.getCalleeNumber?.()?.startsWith('2') && c?.getCalleeNumber?.()?.length === 3 && c?.isRinging()) ?? [];
        if(internalCalls.length > 0) {
            if(internalCalls.length > 1) {
                internalCallNotifier = `${internalCalls.length} Internal Calls Waiting`;
            } else {
                internalCallNotifier = `Internal Call Waiting - ${internalCalls[0]?.getCalleeName?.()}`;
            }
        }
    }
    
    const getIcon = () => {
        return isRinging ? "phone-volume" : isOnHold ? "pause-circle" : isIncoming ? "phone-arrow-down-left" : "phone-arrow-up-right";
    };

    const getIconColor = () => {
        return isOnHold ? "#D61F26" : isRinging ? "#000000" : "#5da14d";
    };
    
    useEffect(() => {
        let timeout = null;
        if (internalCallNotifier) {
            if (showInternalCall) {
                setTimeout(() => setShowInternalCall(false), 1200);
            } else {
                setTimeout(() => setShowInternalCall(true), 1200);
            }
        } else if (showInternalCall) {
            if (timeout) {
                clearTimeout(timeout);
            }
            setShowInternalCall(false);
        }
        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [internalCallNotifier, showInternalCall]);

    useEffect(() => {
        let timeout = null;
        if (priorityCount > 0) {
            if (showPriorityQueue) {
                setTimeout(() => setShowPriorityQueue(false), 1200);
            } else {
                setTimeout(() => setShowPriorityQueue(true), 1200);
            }
        } else if (showPriorityQueue) {
            if (timeout) {
                clearTimeout(timeout);
            }
            setShowPriorityQueue(false);
        }
        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [priorityCount, showPriorityQueue]);

    return (
        <Chip
            align="left"
            avatar={
                <FAIcon
                    className={isRinging ? `icon-shake textPrimary` : ``}
                    icon={getIcon()}
                    type="solid"
                    color={getIconColor()}
                    size={isRinging ? (isPopOut ? 30 : 17.5) : 17.5}
                />
            }
            clickable
            color="default"
            label={
                <Grid container alignItems="center" wrap={!isPopOut ? "nowrap" : "wrap"}>
                    {isPopOut && (
                        <Grid item xs={12} align="left">
                            <Typography variant="h4" className="pl-1 pt-1">
                                Incoming Call
                            </Typography>
                        </Grid>
                    )}
                    {(internalCallNotifier && showInternalCall && (
                        <Grid item xs>
                            <Typography variant={"body2"} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} className={`fw-500 textError pr-2`}>
                                <strong>** {internalCallNotifier} **</strong>
                            </Typography>
                        </Grid>
                    )) || (priorityQueues && showPriorityQueue && (
                        <Grid item xs>
                            <Typography variant={"body2"} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} className={`fw-500 textError pr-2`}>
                                <strong>** {priorityCount} Call{priorityCount > 1 ? "s" : ""} Waiting **</strong>
                            </Typography>
                        </Grid>
                    )) || (
                        <Grid item xs style={{maxWidth: 400}}>
                            {((data?.fn || data?.ln) && (
                                <>
                                    <Typography
                                        variant={isPopOut ? "body1" : "body2"}
                                        style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                                        className={`fw-400 pr-2 ${isPopOut ? "pl-1" : ""}`}
                                        align={isPopOut ? "left" : undefined}
                                    >
                                        <span className="fw-600">[{isInternal ? `INTERNAL` : isExternal ? `EXTERNAL` : callName?.toUpperCase?.()}] </span>
                                        {isRinging ? (isIncoming ? `${capitalize(callDirection)} call from ` : `Dialling `) : ``}
                                        {data.fn} {data.ln} {data.cn ? ` | ${data.cn}` : ""}
                                        {data.lo ? ` (${data.lo})` : ``}
                                    </Typography>
                                </>
                            )) ||
                                (data?.cn && (
                                    <>
                                        <Typography
                                            variant={isPopOut ? "body1" : "body2"}
                                            style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                                            className={`fw-400 pr-2 ${isPopOut ? "pl-1" : ""}`}
                                            align={isPopOut ? "left" : undefined}
                                        >
                                            <span className="fw-600">[{isInternal ? `INTERNAL` : isExternal ? `EXTERNAL` : callName?.toUpperCase?.()}] </span>
                                            {isRinging ? (isIncoming ? `${capitalize(callDirection)} call from ` : `Dialling `) : ``}
                                            {data.cn}
                                            {data.lo ? ` (${data.lo})` : ``}
                                        </Typography>
                                    </>
                                )) || (
                                    <>
                                        <Typography
                                            variant={isPopOut ? "body1" : "body2"}
                                            style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                                            className={`fw-400 pr-2 ${isPopOut ? "pl-1" : ""}`}
                                            align={isPopOut ? "left" : undefined}
                                        >
                                            <span className="fw-600">[{isInternal ? `INTERNAL` : isExternal ? `EXTERNAL` : callName?.toUpperCase?.()}] </span>
                                            {isRinging ? (isIncoming ? `${capitalize(callDirection)} call from ` : `Dialling `) : ``}
                                            {!isIncoming || (isIncoming && isInternal) ? (isAnonymous ? "Anonymous" : callName) : callNumber}
                                        </Typography>
                                    </>
                                )}
                        </Grid>
                    )}
                    {!isPopOut && (
                        <Grid item className="pr-1 pl-1">
                            <Box pt={0.15}>
                                <span className={getCallStatusClass(call)}>{callState}</span>
                                {!isRinging && <span className="pl-1">{callDuration}</span>}
                            </Box>
                        </Grid>
                    )}
                    {_.map(getCallActions(call, data?.ci ?? null, data?.si ?? null), (action, idx) => (
                        <Grid item key={idx}>
                            <Tooltip title={action.name}>
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();    
                                        action.onClick();
                                    }}
                                >
                                    <FAIcon icon={action.icon} className={action.className ?? ""} type={action.type ?? "light"} size={isPopOut ? 30 : 22.5} noMargin button />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    ))}
                    {!isRinging && (
                        <Grid item>
                            <IconButton onClick={handleToggleDrawer} size="small">
                                <FAIcon icon="chevron-down" size={15} noMargin button />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            }
            onClick={handleToggleDrawer}
            style={
                isRinging
                    ? isPopOut
                        ? {
                              justifyContent: "flex-start",
                              background: "#fff",
                              color: "#fff!important",
                              border: "1px solid #ddd",
                              height: 100,
                              paddingLeft: 12,
                              width: isTablet() ? "75%" : "40%",
                              position: "fixed",
                              top: "7.5%",
                              marginLeft: isTablet() ? "-37.5%" : "-20%",
                              boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)",
                              left: "50%",
                              zIndex: 9999,
                          }
                        : {
                              background: "#fff",
                              border: "1px solid #ddd",
                              height: isTablet() ? 32.5 : 34,
                              paddingLeft: 12,
                          }
                    : {
                          background: "#fff",
                          border: "1px solid #ddd",
                          height: isTablet() ? 32.5 : 34,
                          paddingLeft: 12,
                      }
            }
            variant="outlined"
        />
    );
};

export default WildixCallDynamicIsland;
