import React, { Component } from 'react';
import API                  from 'API';
import _, { isInteger }     from 'lodash';
import { connect }          from 'react-redux';

import Image              from 'Components/Common/ImageWithError/ImageWithError';
import NumberField        from 'Components/Common/Inputs/NumberField';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import QrScanWrapper      from 'Components/Warehouse/Common/Inputs/QrScanWrapper';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import BasicSelect        from 'Components/Common/Selects/BasicSelect';

import { colors } from 'Helpers/ColourHelper';

import { playSound } from 'Actions/Sounds/Sounds';

import { Button, Grid, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';


const initialState = {
    part:       null,
    showError:  false,
    formData: {
        part:   null,
        qty:    0,
        in:     1,
        reason: null,
        template: 0,
    },
    confirmationDialog: {
        open:    false,
        title:   '',
        message: '',
        success: null
    },
    parts: [],
    isLoading: true,
    formErrors: []
}

class ScanDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getParts();
    }

    getParts = () => {
        Promise.all([
            API.get('/parts/all',{params:{withDefaultImg:true, isStockAble:true, withStock:true}}),
            API.get('/stock/movement/reasons/all')
        ]).then(([res, reasons]) => {
            this.setState({
                parts:      res.data,
                isLoading:  false,
                reasons:    _.map(reasons.data, i => _.assign({
                    value: i.smr_id,
                    label: i.smr_reason
                })),
            });
        })
    }

    handleScan = (scannedPartNumber) => {
        if (scannedPartNumber){
            let part = _.find(this.state.parts, {part_number: scannedPartNumber});
            if (part){
                this.props.playSound('pickSuccess', 1)
                this.setState({
                    part: part,
                    formData: {
                        ...this.state.formData,
                        part: part.part_id,
                    }
                })
            } else {
                this.props.playSound('pickFailed', 1);
                this.setState({qrError: 'Part not found'});
            }
        }
    }

    handleClose = () => {
        this.props.close();
    }

    _handleKeyDown = (e) => {
        let { formData, confirmationDialog } = this.state;
        if (e.keyCode === 0) return;
        e.preventDefault();
        switch(e.key){
            case 'F1':
                if (confirmationDialog.open) this.handleConfirmationClose();
            break;
            case 'F2':
                if (!this.props.locked){
                    if (confirmationDialog.open){
                        this.submitStockMovement();
                    } else {
                        if ( formData.qty && parseFloat(formData.qty) > 0 && formData.reason !== null)  
                            this.deployConfirmation(
                                'Are you sure you want to add this part to the stock movement?',
                                'Add Stock movement Part?',
                                this.submitStockMovement
                            );
                    }
                }
            break;
            case 'F3':
                this.handleClose();
            break;
            default:
                if (formData.part) {
                    if (e.key === 'Backspace'){
                        formData.qty = formData.qty.toString().slice(0, -1);
                    } else if ( e.key === '.' ) {
                        formData.qty = formData.qty.toString() + '.';
                    } else if (isInteger(parseInt(e.key))){
                        formData.qty = parseFloat(formData.qty.toString() + e.key.toString());
                    } 
                    this.setState({formData});
                }
            break;
        } 
    }

    handleChange = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    deployConfirmation = (message, title, success) => {
        this.setState({
            confirmationDialog: {
                open: true,
                message: message,
                title: title,
                success: success
            }
        })
    }

    handleConfirmationClose = () => {
        this.setState({
            confirmationDialog: {
                ...this.state.confirmationDialog,
                open: false
            }
        })
    }

    submitStockMovement = () => {
        this.handleConfirmationClose();
        API.post(`/stock/movement/${this.props.movement.sm_id}/save`,{
            details: [ this.state.formData ],
            notes: this.props.movement.sm_notes
        }).then(result => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    qrError:  'Part Is locked',
                    part:     initialState.part,
                    formData: initialState.formData
                });
            } else if (!result.data.success) {
                this.setState({
                    qrError:  'Part cannot be added to the stock movement',
                    part:     initialState.part,
                    formData: initialState.formData
                });
            } else {
                this.setState({
                    part:     initialState.part,
                    formData: initialState.formData
                });
            }
        })
    }

    render() {

        const { isLoading, formData, part, qrError, confirmationDialog, reasons, formErrors } = this.state;

        const { classes, locked } = this.props;

        if (isLoading) return (<LoadingCircle />);

        return(
            <Grid container justify="center" className={`${classes.root} text-center`} spacing={1}>
                {locked &&
                    <Grid item xs={12}>
                        <Typography variant="h4" style={{textAlign: 'center', color: colors.red}}>
                            This movement is currently locked/unavailable   
                        </Typography>
                    </Grid>
                }
                {part ? 
                    <>
                        <Grid item xs={12} >
                            <Grid container justify="center" className="text-center" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" style={{textAlign: 'center'}}>
                                        {part.part_number}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{textAlign: 'center'}}>
                                        {part.part_description}<br></br>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{textAlign: 'center'}}>
                                    <Image src={part.default_image?.file_path} className={classes.img} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <BasicSelect  
                                fullWidth
                                options={[
                                    {value: 1, label: 'Stock In'},
                                    {value: 0, label: 'Stock Out'}
                                ]} 
                                label='Movement'
                                onChange={(e)=>{this.handleChange('in', e.value)}}
                                value={formData.in}
                                isClearable={false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BasicSelect 
                                fullWidth
                                options={reasons} 
                                label='Reason *'
                                onChange={(e)=>{this.handleChange('reason', e.value)}}
                                value={formData.reason}
                                isClearable={false}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <NumberField
                                fullWidth
                                label="Quantity *"
                                value={formData.qty}
                                onChange={(e)=>{this.handleChange('qty', e)}}
                                inputProps={{
                                    min: 0,
                                    pattern: "\d*"
                                }}
                                granularity={1}
                                disableKeyboard={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={!formData.qty || parseFloat(formData.qty) <= 0 || formData.reason === null || this.props.locked}
                                onClick={()=>
                                    this.deployConfirmation(
                                        'Are you sure you want to add this part to the stock movement?',
                                        'Add Stock movement Part?',
                                        this.submitStockMovement
                                    )
                                }
                            >Add [F2]</Button>
                        </Grid>
                    </> :
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h4">Scan Part</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" style={{color: colors.red}}>{qrError}</Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={12} style={{marginTop: 'auto'}}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={this.handleClose}
                    >Close [F3]</Button>
                </Grid>
                <QrScanWrapper
                    handleScan={this.handleScan}
                    parentScan={this._handleKeyDown}
                />
                {confirmationDialog.open &&
                    <ConfirmationDialog
                        open={confirmationDialog.open}
                        title={confirmationDialog.title}
                        message={confirmationDialog.message}
                        success={confirmationDialog.success}
                        close={this.handleConfirmationClose}
                        isPda={true}
                    />
                }
            </Grid>
        )    
    }
}

const styles = theme => ({
    root: {
        padding:    theme.spacing(1),
        alignItems: 'flex-start',
    },
    qrRow: {
        justifyContent: 'flex-end',
        maxHeight:      '50px',
    },
    img: {
        maxHeight: 80,
        maxWidth:  130,
        width: 'auto',
        height: 'auto',
    },  
    qtyRow: {
        textAlign: 'center'
    },
    actionText: {
        border: `2px dashed ${theme.palette.secondary.main}`,
        padding: 15,
        width: '80%',
        margin: '0 auto',
        textAlign: 'center',
        marginTop: '1em'
    },
});

function mapDispatchToProps(dispatch) {
    return {
        playSound: (type, state) => {
            dispatch(playSound(type, state));
        },
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ScanDialog));