import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { CSVLink }          from "react-csv";
import { connect }          from 'react-redux';

import DataTable          from 'Components/Common/DataTables/CiDataTable';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import Textarea           from 'Components/Common/Inputs/Textarea';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import StaffActions       from 'Components/Common/StaffActions/StaffActions';

import ScanDialog from './ScanDialog';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons                      from 'Helpers/IconHelper';
import { clenyDate }              from 'Helpers/TimeHelper';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar }            from 'Actions/SnackBar/SnackBar';

import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Tab, Tabs, TextField, Typography } from '@material-ui/core/';

const initialState = {
    lists: {
        parts:     [],
        reasons:   [],
        templates: [],
    },
    formDataMovement: {
        notes: ''
    },
    formDataDetail: {
        part:   null,
        qty:    0,
        in:     1,
        reason: null,
    },
    formDataEditDetail: {
        part:    null,
        qty:     0,
        in:      1,
        reason:  null,
        id:      0,
        idx:     0,
        updated: true
    },
    formDataTemplate: {
        template:   null,
        qty:        1,
    },
    stockMovement: {},
    details: [],
    isLoading: true,
    access: {},
    showEditDialog: false,
    currentTab: 0,
    formErrors: {},
    showScan: false,
    disableSave: false,
    submitted: false,
    csvDownload: {
        data: [],
        filename: '',
    }
}


class EditStockMovement extends Component {
    constructor(props) {
        super(props);
        this.state = {...initialState, id: this.props.match.params.smtId === 'new' ? 0 : this.props.match.params.smtId }
    }

    componentDidMount() {
        this.getAccess();
        this.getData();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-stock-movement'),
            API.get('/staff/my/access/check/save-stock-movement'),
            API.get('/staff/my/access/check/submit-stock-movement'),
            API.get('/staff/my/access/check/delete-stock-movement'),
            API.get('/staff/my/access/check/template-stock-movement'),
        ])
        .then(([editRes, saveRes, submitRes, deleteRes, templateRes]) => {
            this.setState({
                access: {
                    edit:       (editRes?.data?.has_access)     || false,
                    save:       (saveRes?.data?.has_access)     || false,
                    submit:     (submitRes?.data?.has_access)   || false,
                    delete:     (deleteRes?.data?.has_access)   || false,
                    template:   (templateRes?.data?.has_access) || false,
                },
            })
        });
    }

    getData = () => {
        Promise.all([
            API.get('/stock/movement/reasons/all'),
            API.get('/stock/movement/templates/all'),
            API.get(`/stock/movement/${this.props.match.params.smId}`),
        ]).then(([reasonsRes, templatesRes, stockMovementRes]) => {
            API.get('/parts/all',{params:{withDefaultImg:true, isStockAble:true, withStock:true, withStockDate: stockMovementRes.data.sm_date}})
            .then(partsRes => {
                if (stockMovementRes.data.sm_submitted || stockMovementRes.data.sm_deleted) {
                    this.props.history.push('/stock/movement');
                }
                this.setState({
                    stockMovement: stockMovementRes.data,
                    details: _.map(stockMovementRes.data.details, (i,idx) => _.assign({
                        id:       i.smd_id,
                        part:     i.smd_part_id,
                        qty:      i.smd_qty_in ? i.smd_qty_in : i.smd_qty_out,
                        in:       i.smd_qty_in ? 1 : 0,
                        reason:   i.smd_reason_id,
                        template: i.smd_template_id,
                        updated:  0,
                        idx,
                    })),
                    grouped: _.map(stockMovementRes.data.grouped, (i,idx) => { 
                        let part = _.find(partsRes.data, {part_id: i.smg_part_id});
                        let before = parseFloat(part?.stock_activity.length ? parseFloat(part?.stock_activity[0]?.stock_activity_new_qty) : 0);
                        return _.assign({
                            id:         i.smg_id,
                            part:       i.smg_part_id,
                            number:     i.part.part_number,
                            desc:       i.part.part_description,
                            before:     before,
                            in:         parseFloat(i.smg_stock_in),
                            out:        parseFloat(i.smg_stock_out),
                            change:     parseFloat(i.smg_stock_in) - parseFloat(i.smg_stock_out),
                            after:      before + parseFloat(parseFloat(i.smg_stock_in) - parseFloat(i.smg_stock_out))
                        })
                    }),
                    formDataMovement: {
                        notes: stockMovementRes.data.sm_notes
                    },
                    lists: {
                        parts:      _.map(partsRes.data, i => _.assign({
                            value: i.part_id,
                            label: `${i.part_number} - ${i.part_description}`, 
                            part:  i
                        })),
                        reasons:    _.map(reasonsRes.data, i => _.assign({
                            value: i.smr_id,
                            label: i.smr_reason
                        })),
                        templates:   _.map(templatesRes.data, i => _.assign({
                            value: i.smt_id,
                            label: i.smt_name,
                            parts: i.details
                        }))
                    },
                    isLoading: false
                },  this.getMasterExcel)
            })
        })
    }

    handleChange = fd => e => {
        this.setState({
            [fd]: {
                ...this.state[fd],
                [e.target.name]: e.target.value
            }
        });
    }

    handleSelectChange = fd => f => e => {
        let value = e ? e.value : null;
        this.setState({
            [fd]: {
                ...this.state[fd],
                [f]: value
            }
        });
    }

    addPart = (detailData = null) => {

        let detail  = detailData ? detailData : this.state.formDataDetail;

        let idx     = _.findIndex(this.state.details, {part: detail.part, in: detail.in, reason: detail.reason});

        let details = this.state.details;
        if(idx >= 0 ) {
            details[idx].qty = parseFloat(details[idx].qty) + parseFloat(detail.qty);
            details[idx].updated = 1;
        } else {
            details.push({...detail, idx: details.length});
        }

        this.setState({
            details,
            formDataDetail: initialState.formDataDetail
        },this.calculateGrouped);
    }

    getTableColumns = () => {
        const {lists:{parts, reasons}, lists, formDataEditDetail}  = this.state;

        return [
            {
                heading:        'Part',
                field:          rowData => _.find(parts,i=>i.value==rowData.part).part.part_number,
                dataRef:        'part',
                sizeToContent:  true,
                style:          {width: '6%'}
            },
            {
                heading:    'Description',
                field:      rowData => _.find(parts,i=>i.value==rowData.part).part.part_description,
                dataRef:    'part',
                style:      {width: '59%'}
            },
            {
                heading:        'Stock',
                field:          rowData => parseFloat(rowData.qty).toFixed(3),
                dataRef:        'qty',
                alignment:      'center',
                sizeToContent:  true,
                style:          {width: '5%'}
            },
            {
                heading:    'Reason',
                field:      rowData => _.find(reasons,i=>i.value==rowData.reason).label,
                dataRef:    'reason',
                alignment:  'left',
                style:      {width: '22%'}
            },
            {
                actions: rowData => {
                    return([
                        {name: 'Edit',   icon: icons.edit,   onClick: () => {
                                                                                this.setState({
                                                                                    formDataEditDetail: {...rowData, updated: 1}, 
                                                                                    showEditDialog: true
                                                                                })
                                                                            }
                        },
                        {name: 'Delete',   icon: icons.delete,   onClick: ()=>{this.deleteRow(rowData.idx)}},
                    ])
                }
            }
        ]
    }

    updatePart = () => {
        let {formDataEditDetail} = this.state;
        if (parseFloat(formDataEditDetail.qty) === 0){
            this.deleteRow(formDataEditDetail.idx);
        } else {
            let details = this.state.details;
            details[formDataEditDetail.idx] = formDataEditDetail;
            this.setState({
                details, 
                showEditDialog: false
            },this.calculateGrouped);
        }
    }

    calculateGrouped = () => {
        this.setState({
            grouped: _.map(_.uniqBy(_.filter(this.state.details, i => i.qty != 0), i=>i.part), i => {
                const part = _.find(this.state.lists.parts, j=> parseInt(j.value) === parseInt(i.part)).part;
                let before = parseFloat(part?.stock_activity.length ? parseFloat(part?.stock_activity[0]?.stock_activity_new_qty) : 0);
                return _.assign({
                    number: part.part_number,
                    desc:   part.part_description,
                    partId: i.part,
                    before: before,
                    in:     _.sumBy(_.filter(this.state.details, j=>j.part==i.part && j.in),  j=>parseFloat(j.qty)),
                    out:    _.sumBy(_.filter(this.state.details, j=>j.part==i.part && !j.in), j=>parseFloat(j.qty)),
                });
            }),
        }, ()=> {
            this.setState({
                csvDownload: {
                    ...initialState.csvDownload,
                    data: _.map(this.state.csvDownload.data, i => {
                        let group = _.find(this.state.grouped, j=>j.number==i.number);
                        return group ?
                            {...i, out: group.out, in: group.in} :
                            i
                    }),
                }
            })
        })
    }

    deleteRow = idx => {
        let details = this.state.details;
        details[idx].qty     = 0;
        details[idx].updated = 1;
        this.setState({
            details, 
            showEditDialog: false
        },this.calculateGrouped);
    }

    handleNoteChange = e => { this.setState({ formDataMovement: {notes: e.target.value} }); }

    save = () => {
        if(!this.state.submitted) {
            this.setState({
                submitted: true,
                isLoading: true,
            }, () => {
                API.post(`/stock/movement/${this.props.match.params.smId}/save`,{
                    notes:   this.state.formDataMovement.notes,
                    details: _.filter(this.state.details, i => {
                                if (!i.id && i.qty != 0) return true;
                                if (i.id && i.updated) return true;
                                return false;
                            })
                }).then(result => {
                    if(result.data.errors && result.data.errors.length > 0){           
                        this.setState({
                            formErrors: formatValidationErrors(result.data.errors),
                            submitted: false,
                            isLoading: false
                        });
                    } else {
                        if (!result.data.success) {
                            this.props.deploySnackBar('error', 'Stock Movement could not be saved');
                        }
                        window.location.href = `/stock/movement`;
                    }
                })

            })
        }
        
    }

    handleTabChange = (event, value) => { this.setState({currentTab: value, isLoading: false});};

    submit = () => { 
        if(!this.state.submitted) {
            this.setState({
                isLoading: true,
                submitted: true
            },()=>{ API.post(`/stock/movement/${this.props.match.params.smId}/save`,{
                notes:   this.state.formDataMovement.notes,
                details: _.filter(this.state.details, i => {
                            if (!i.id && i.qty != 0) return true;
                            if (i.id && i.updated) return true;
                            return false;
                        })
            }).then(result => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        submitted: false,
                        isLoading: false,
                    });
                } else {
                    if (result.data.success) {
                        API.post(`/stock/movement/${this.props.match.params.smId}/submit`)
                        .then(()=>{
                            this.props.history.push('/stock/movement');
                        });
                    } else {
                        this.props.deploySnackBar('error', 'Stock Movement could not be saved');
                        window.location.href = `/stock/movement`;
                    }
                }
            })})
        }
    }

    addScannedPart = (formDataDetail, close=false) => {
        if (!formDataDetail.qty || !formDataDetail.part || !formDataDetail.reason) {
            this.setState({ showScan: !close});
        } else {
            this.setState({
                showScan: !close,
                formDataDetail
            }, ()=>{
                this.addPart();
            })
        }
    }

    addTemplate = () => {
        let template = _.find(this.state.lists.templates, i => parseInt(i.value) === parseInt(this.state.formDataTemplate.template));
        let details = this.state.details;
        _.each(template.parts, i => {
            this.addPart({
                part:       i.smtd_part_id,
                qty:        (parseInt(this.state.formDataTemplate.qty) * parseFloat(i.smtd_qty)),
                in:         i.smtd_in ? 1 : 0,
                reason:     i.smtd_reason_id,
                template:   this.state.formDataTemplate.template,
                updated:    0,
            })
        });
    }

    getMasterExcel = () => {
        API.get(`/stock/movement/${this.props.match.params.smId}/masterExcel`)
        .then(res => {
            this.setState({
                csvDownload: {
                    ...initialState.csvDownload,
                    data:      res.data,
                }
            })
        })
    }

    downloadMasterExcel = () => {
        this.setState({
            csvDownload: {
                ...this.state.csvDownload,
                filename: `MasterExcelParts_${this.state.stockMovement.sm_ref}.csv`,
            }
        }, ()=>{
            setTimeout(()=>{
                document.getElementById('downloadMasterExcel').click();
            }, 100);
        })
    }

    lockOrder = locked => {
        this.setState({locked});
    }

    render() {
        const {csvDownload, access, formDataDetail, formDataEditDetail, isLoading, lists, details, showEditDialog, stockMovement, currentTab, formDataMovement, 
            grouped, formErrors, showScan, formDataTemplate, locked} = this.state;
        
        if (isLoading) return( <LoadingCircle/> );
        return (
            <Grid container xs={12} spacing={3}>
                {!!csvDownload.filename && !!csvDownload.data &&
                    <CSVLink 
                        data={csvDownload.data} 
                        label={''} 
                        filename={csvDownload.filename}
                        className='hidden'
                        target='_blank'
                        id='downloadMasterExcel'
                    />
                }
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Amend Stock Movement
                    </Typography>
                </Grid>
                <StaffActions type='movement' id={stockMovement.sm_id} lockCallback={this.lockOrder} lockWithView/>
                <Grid item lg={6} xs={12}>
                    <PaddedPaper>
                        <Grid container item spacing={3}>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Stock Movement:
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="subtitle1">
                                    {stockMovement.sm_ref}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Date:
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="subtitle1">
                                    {clenyDate(stockMovement.sm_date)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Created By:
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="subtitle1">
                                    {stockMovement.sm_created_by}
                                </Typography>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <AppBar position="static" style={{backgroundColor:'white', color:"#000", width: '100%', zIndex:'auto', marginTop:0}} >
                        <Tabs   value={currentTab} 
                                onChange={this.handleTabChange} 
                                className='xs-max-width' 
                                indicatorColor="primary" 
                                textColor="primary" 
                                variant='scrollable'
                                scrollButtons="auto"
                        >
                            <Tab label='Detailed' />
                            <Tab label='Overview' />
                        </Tabs>
                    </AppBar>
                </Grid>
                <Dialog
                    open={showScan}
                    fullScreen
                >
                    <DialogContent>
                        <ScanDialog
                            details= {_.orderBy(_.filter(details, i => parseInt(i.reScan) === 1 || i.std_taken_stock === null), [i => i.part_number],['desc'])}
                            reasons= {lists.reasons}
                            parts=   {_.filter(lists.parts, i => !i.part_locked)}
                            addPart= {this.addScannedPart}
                        />
                    </DialogContent>
                </Dialog>
                {currentTab === 0 &&
                    <>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Button onClick={()=>{this.setState({
                                    showScan: true
                                })}} variant='contained' color='primary' fullWidth>Scan In Part Movements</Button>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Grid container xs={12} spacing={3} style={{marginRight: 0, alignItems: 'flex-end'}}>
                                    <Grid item xs={12} style={{paddingBottom: 10}}>
                                        <Typography variant="h6">
                                            Add Part
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{paddingTop: 0}}>
                                        <FormControl fullWidth margin="none">
                                            <AutoCompleteSelect
                                                options={_.filter(lists.parts, i => !i.part_locked)} 
                                                label='Part *'
                                                onChange={this.handleSelectChange('formDataDetail')('part')}
                                                value={formDataDetail.part}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} style={{paddingTop: 0}}>
                                        <FormControl fullWidth margin="none">
                                            <AutoCompleteSelect
                                                options={[
                                                    {value: 1, label: 'Stock In'},
                                                    {value: 0, label: 'Stock Out'}
                                                ]} 
                                                label='Movement'
                                                onChange={this.handleSelectChange('formDataDetail')('in')}
                                                value={formDataDetail.in}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} style={{paddingTop: 0}}>
                                        <TextField
                                            fullWidth
                                            margin="none"
                                            name="qty"
                                            label='Qty *'
                                            value={formDataDetail.qty}
                                            onChange={this.handleChange('formDataDetail')}
                                            type="number"
                                            inputProps={{min: 0}}
                                        />
                                    </Grid>
                                    <Grid item xs={10} style={{paddingTop: 0}}>
                                        <FormControl fullWidth margin="none">
                                            <AutoCompleteSelect
                                                options={lists.reasons}
                                                label='Reason *'
                                                onChange={this.handleSelectChange('formDataDetail')('reason')}
                                                value={formDataDetail.reason}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2} style={{paddingTop: 0, paddingRight: 0}}>
                                        <Button fullWidth variant='contained' color='primary'
                                                onClick={()=>this.addPart(null)} disabled={!formDataDetail.part || !formDataDetail.qty || !formDataDetail.reason}
                                        >Add</Button>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Grid container xs={12} spacing={3} style={{marginRight: 0, alignItems: 'flex-end'}}>
                                    <Grid item xs={12} style={{paddingBottom: 10}}>
                                        <Typography variant="h6">
                                            Add From Template
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{paddingTop: 0}}>
                                        <FormControl fullWidth margin="none">
                                            <AutoCompleteSelect
                                                options={lists.templates} 
                                                label='Template *'
                                                onChange={this.handleSelectChange('formDataTemplate')('template')}
                                                value={formDataTemplate.template}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} style={{paddingTop: 0}}>
                                        <TextField
                                            fullWidth
                                            margin="none"
                                            name="qty"
                                            label='Qty *'
                                            value={formDataTemplate.qty}
                                            onChange={this.handleChange('formDataTemplate')}
                                            type="number"
                                            inputProps={{min: 1}}
                                        />
                                    </Grid>
                                    <Grid item xs={10}></Grid>
                                    <Grid item xs={2} style={{paddingTop: '0.8em'}}>
                                        <Button fullWidth variant='contained' color='primary'
                                                onClick={
                                                    () => {
                                                        if (_.find(details, j => parseInt(j.template) ===  parseInt(this.state.formDataTemplate.template) && j.qty > 0)){
                                                            this.props.deployConfirmation('This template has already been added, do you want to update the part quantities?','Template Already Added', this.addTemplate)
                                                        } else {
                                                            this.addTemplate();
                                                        }
                                                    }    
                                                
                                                } disabled={!formDataTemplate.template || !formDataTemplate.qty }
                                        >Add</Button>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        {_.filter(details, i=> i.in && i.qty ).length > 0 &&
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h4"><AllIcon size='large' icon={icons.plus} style={{color:colors.green}} /> Stock In</Typography>
                                    <br/>
                                    <DataTable
                                        config={{
                                            key: 'part',
                                            alternatingRowColours: true,
                                            responsiveimportant: true,
                                        }}
                                        columns={this.getTableColumns()}
                                        rows={_.orderBy(_.filter(details,i=>i.in && i.qty > 0), [i=>_.find(this.state.lists.parts, j=> parseInt(j.value) === parseInt(i.part)).part.part_number], ['asc'])}
                                    />
                                </PaddedPaper>
                            </Grid>
                        }
                        {_.filter(details, i=> !i.in && i.qty ).length > 0 &&
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h4"><AllIcon size='large' icon={icons.minus} style={{color:colors.red}} /> Stock Out</Typography>
                                    <br/>
                                    <DataTable
                                        config={{
                                            key: 'part',
                                            alternatingRowColours: true,
                                            responsiveimportant: true,
                                        }}
                                        columns={this.getTableColumns()}
                                        rows={_.orderBy(_.filter(details,i=>!i.in && i.qty > 0), [i=>_.find(this.state.lists.parts, j=> parseInt(j.value) === parseInt(i.part)).part.part_number], ['asc'])}
                                    />
                                </PaddedPaper>
                            </Grid>
                        }
                        <Grid item xs={12} style={{paddingTop:'1.5em'}}>
                            <PaddedPaper>
                                <Textarea
                                    id="notes"
                                    name="notes"
                                    label='Stock Movement Notes'
                                    value={formDataMovement.notes}
                                    rows={10}
                                    onChange={this.handleNoteChange}
                                    noMargin
                                />
                            </PaddedPaper>
                        </Grid>
                    </>
                }

                {parseInt(currentTab) === 1 &&
                    <>
                        {_.filter(grouped, g => g.in > 0).length > 0 &&
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h4"><AllIcon size='large' icon={icons.plus} style={{color:colors.green}} /> Stock In</Typography>
                                    <br/>
                                    <DataTable
                                        config={{
                                            key: 'partId',
                                            sticky:                 true,
                                            alternatingRowColours:  true,
                                            responsiveimportant:    true,
                                        }}
                                        columns={[
                                            {
                                                heading:    'Part',
                                                field:      rowData => rowData.number,
                                                dataRef:    'number',
                                                sizeToContent: true,
                                                style: {width: '6%'},
                                            },
                                            {
                                                heading:    'Description',
                                                field:      rowData => rowData.desc,
                                                dataRef:    'desc',
                                                style:      {width: '32%'},
                                            },
                                            {
                                                heading:    'Opening Stock',
                                                field:      'before',
                                                dataRef:    'before',
                                                alignment:  'center',
                                                fieldFormat: 'decimal:3',
                                                style:      {width: '20%'},
                                            },
                                            {
                                                heading:    'Stock In',
                                                field:      rowData => parseFloat(rowData.in).toFixed(3),
                                                dataRef:    'in',
                                                alignment:  'center',
                                                style:      {width: '22%'},
                                            },
                                            {
                                                heading:    'Closing Stock',
                                                field:      rowData => ( parseFloat(rowData.before) + parseFloat(rowData.in) ) .toFixed(3),
                                                dataRef:    rowData => parseFloat(rowData.before) + parseFloat(rowData.in),
                                                alignment:  'center',
                                                style:      {width: '20%'},
                                            },
                                            
                                        ]}
                                        rows={_.orderBy(_.filter(grouped, g => g.in > 0),['number'],['asc'])}
                                    />
                                </PaddedPaper>
                            </Grid>
                        }
                        {_.filter(grouped, g => g.out > 0).length > 0 &&
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h4"><AllIcon size='large' icon={icons.minus} style={{color:colors.red}} /> Stock Out</Typography>
                                    <br/>
                                    <DataTable
                                        config={{
                                            key: 'partId',
                                            sticky:                 true,
                                            alternatingRowColours:  true,
                                            responsiveimportant:    true,
                                        }}
                                        columns={[
                                            {
                                                heading:    'Part',
                                                field:      rowData => rowData.number,
                                                dataRef:    'number',
                                                sizeToContent: true,
                                                style: {width: '6%'},
                                            },
                                            {
                                                heading:    'Description',
                                                field:      rowData => rowData.desc,
                                                dataRef:    'desc',
                                                style:      {width: '32%'},
                                            },
                                            {
                                                heading:    'Opening Stock',
                                                field:      rowData => parseFloat(rowData.before) + parseFloat(rowData.in),
                                                dataRef:    rowData => parseFloat(rowData.before) + parseFloat(rowData.in),
                                                alignment:  'center',
                                                fieldFormat: 'decimal:3',
                                                style:      {width: '20%'},
                                            },
                                            {
                                                heading:    'Stock Out',
                                                field:      rowData => parseFloat(rowData.out).toFixed(3),
                                                dataRef:    'out',
                                                alignment:  'center',
                                                style:      {width: '22%'},
                                            },
                                            {
                                                heading:    'Closing Stock',
                                                field:      rowData => ( parseFloat(rowData.before) + parseFloat(rowData.in - rowData.out) ).toFixed(3),
                                                dataRef:    rowData => parseFloat(rowData.before) + parseFloat(rowData.in - rowData.out),
                                                alignment:  'center',
                                                style:      {width: '20%'},
                                            },
                                            
                                        ]}
                                        rows={_.orderBy(_.filter(grouped, g => g.out > 0),['number'],['asc'])}
                                    />
                                </PaddedPaper>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h4"><AllIcon size='large' icon="plus-minus" /> Stock Change</Typography>
                                <br/>
                                <DataTable
                                    config={{
                                        key: 'partId',
                                        sticky:                 true,
                                        alternatingRowColours:  true,
                                        responsiveimportant:    true,
                                    }}
                                    columns={[
                                        {
                                            heading:        'Part',
                                            field:          rowData => rowData.number,
                                            dataRef:        'number',
                                            sizeToContent:  true,
                                            style:          {width: '6%'}
                                        },
                                        {
                                            heading:    'Description',
                                            field:      rowData => rowData.desc,
                                            dataRef:    'desc',
                                            style:      {width: '32%'},
                                        },
                                        {
                                            heading:    'Opening Stock',
                                            field:      'before',
                                            dataRef:    'before',
                                            alignment:  'center',
                                            fieldFormat: 'decimal:3',
                                            style:      rowData => ({
                                                width: '20%',
                                                color:  parseInt(rowData.before) === 0 ? colors.black : (rowData.before > 0 ? colors.green : colors.red)
                                            }),
                                        },
                                        {
                                            heading:    'Stock Change',
                                            field:      rowData => `${parseFloat(rowData.in) - parseFloat(rowData.out) < 0 ? '-' : '+'}${Math.abs(parseFloat(rowData.in) - parseFloat(rowData.out)).toFixed(3)}`,
                                            dataRef:    rowData => parseFloat(rowData.in) - parseFloat(rowData.out),
                                            alignment:  'center',
                                            style:      rowData => ({
                                                width: '20%',
                                                color:  parseFloat(parseFloat(rowData.in) - parseFloat(rowData.out)) === 0 ? colors.black : ((parseFloat(rowData.in) - parseFloat(rowData.out)) > 0 ? colors.green : colors.red)
                                            }),
                                        },
                                        {
                                            heading:    'Closing Stock',
                                            field:      rowData => ( parseFloat(rowData.before) + parseFloat(parseFloat(rowData.in) - parseFloat(rowData.out)) ).toFixed(3),
                                            dataRef:    rowData => parseFloat(rowData.before) + parseFloat(parseFloat(rowData.in) - parseFloat(rowData.out)),
                                            alignment:  'center',
                                            style:      rowData => ({
                                                width: '20%',
                                                color:  parseFloat(parseFloat(rowData.before) + parseFloat(parseFloat(rowData.in) - parseFloat(rowData.out))) === 0 ? colors.black : ((parseFloat(rowData.before) + parseFloat(parseFloat(rowData.in) - parseFloat(rowData.out))) > 0 ? colors.green : colors.red)
                                            }),
                                        },
                                        
                                    ]}
                                    rows={_.orderBy(_.filter(grouped, g => g.out > 0 || g.in > 0),['number'],['asc'])}
                                />
                            </PaddedPaper>
                        </Grid>
                    </>
                }
                {formErrors['part_locked'] &&
                    <Grid container xs={12}>
                        <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>
                            Some parts are locked, please try again later.
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12} style={{paddingTop:'1.5em'}}>
                    <PaddedPaper>
                        <div className='buttonRow' style={{marginTop:0}}>
                            <Button variant='outlined' onClick={()=>{window.location.href = `/stock/movement`;} }>Back</Button>
                            <Button disabled={locked} variant='contained' color='primary' onClick={this.downloadMasterExcel}>download Master Excel</Button>
                            <Button disabled={locked} variant='contained' color='primary' onClick={this.save}>Save</Button> 
                            {currentTab === 1 && _.filter(grouped, g => g.out > 0 || g.in > 0).length > 0 && details.length > 0 && <Button variant='contained' color='primary' disabled={!access.submit || locked} onClick={()=>this.submit()}>Submit</Button>}
                            {currentTab === 0 && details.length > 0 && <Button disabled={locked} variant='contained' color='primary' onClick={()=>{this.setState({isLoading: true},()=>this.handleTabChange(null,1))}}>Review & Submit</Button>}
                        </div>
                    </PaddedPaper>
                </Grid>
                <Dialog 
                    open={showEditDialog} 
                    maxWidth="xs" 
                    scroll="body"
                >
                    <DialogTitle>
                        <Typography variant='h5'>Update Part</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container xs={12}>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={lists.parts} 
                                        label='Part *'
                                        onChange={this.handleSelectChange('formDataEditDetail')('part')}
                                        value={formDataEditDetail.part}
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={[
                                            {value: 1, label: 'Stock In'},
                                            {value: 0, label: 'Stock Out'}
                                        ]} 
                                        label='Movement'
                                        onChange={this.handleSelectChange('formDataEditDetail')('in')}
                                        value={formDataEditDetail.in}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    name="qty"
                                    label='Qty'
                                    value={formDataEditDetail.qty}
                                    onChange={this.handleChange('formDataEditDetail')}
                                    type="number"
                                    inputProps={{min: 0}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={lists.reasons} 
                                        label='Reason *'
                                        onChange={this.handleSelectChange('formDataEditDetail')('reason')}
                                        value={formDataEditDetail.reason}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <div className='buttonRow' style={{padding: '1em', paddingTop:0}}>
                            <Button variant='outlined'  
                                onClick={()=>{this.setState({formDataEditDetail: initialState.formDataEditDetail, showEditDialog: false})}}
                            >Close</Button>
                            <Button variant='contained' color='primary' 
                                onClick={this.updatePart}
                            >Update</Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                               => dispatch(closeDialog()),
    deployDialog:       (content, title, variant, size)  => dispatch(deployDialog(content, title, variant,size)),
    deployConfirmation: (message, title, success)        => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)                   => dispatch(deploySnackBar(variant, msg)),
})

export default connect(null, mapDispatchToProps)(EditStockMovement);
