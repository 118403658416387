import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import TaskAlert from 'Components/Tasks/Misc/TaskAlert';
import TaskDialog from 'Components/Tasks/Misc/TaskDialog';
import { deployDialog } from 'Actions/Dialog/Dialog';

const styles = theme => ({
    paper: {
        marginBottom: 24,
        borderRadius: 3
    },
    listItem: {
        padding: theme.spacing(2)
    },
    iconSecondary: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
    textInProgress: {
        color: '#E65100'
    },
    textNew: {
        color: '#0288D1'
    },
    headerNew: {
        color: '#fff',
        backgroundColor: '#0288D1!important',
    },
    headerInProgress: {
        color: '#fff',
        backgroundColor: '#F57F17!important',
    },
    headerCompleted: {
        color: '#fff',
        backgroundColor: '#4CAF50!important',
    },
})

const TaskCard = props => { 
    let alert, date;
    if(props.task && props.task.task_status && props.task.task_status === 'Completed') {
        alert = 'completed'
        date = moment(props.task.task_completed_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")
    } else {
        if(props.task && props.task.task_deadline_datetime) {
            if(moment(props.task.task_deadline_datetime, "YYYY-MM-DD HH:mm:ss").isBefore(moment())) {
                alert = 'overdue';
            } 
            else if(moment(props.task.task_deadline_datetime, "YYYY-MM-DD HH:mm:ss").isBefore(moment().endOf('day'))) {
                alert = 'duetoday';
            } else if(props.task.task_deadline_datetime) {
                alert = 'due'
                date = moment(props.task.task_deadline_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")
            } else {
                alert = false
            }
        }
    }
    return (
        <Paper className={props.classes.paper}>
            <TaskAlert type={alert} date={date} />
            <ListItem
                button
                className={props.classes.listItem}
                onClick={() => props.deployDialog(<TaskDialog id={props.task.task_id} callback={props.callback} />, "", "standard", "md")}
                disableGutters
            >  
                <Grid container alignItems='center'>
                    <Grid item xs>
                        {props.displayStatus && (
                            <Grid container className="pb-2">
                                <Grid item >
                                    <Chip
                                        avatar={<Avatar alt='Status' className={props.task.task_status === 'New' ? props.classes.headerNew : (props.task.task_status === 'In Progress' ? props.classes.headerInProgress : props.classes.headerCompleted)} style={{width: 20, height: 20}}><FASolidIcon size="small" icon="traffic-light" noMargin white /></Avatar>}
                                        className={props.task.task_status === 'New' ? props.classes.headerNew : (props.task.task_status === 'In Progress' ? props.classes.headerInProgress : props.classes.headerCompleted)}
                                        label={props.task.task_status}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs align='right'>
                                    <Chip
                                        avatar={<Avatar alt={`${props.task.assignee.staff_first_name} ${props.task.assignee.staff_last_name}`} src={props.task.assignee.staff_profile_photo} style={{width: 20, height: 20}} />}
                                        label={`${props.task.assignee.staff_first_name} ${props.task.assignee.staff_last_name}`}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Typography variant="body1" component="div" paragraph>
                            {props.task.task_title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Typography variant="caption" color="textSecondary">
                                    <FALightIcon icon="comments" className={props.classes.iconSecondary} size="small" /> {props.task.notes_count}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption" color="textSecondary">
                                    <FALightIcon icon="eye" className={props.classes.iconSecondary} size="small" /> {props.task.watchers_count}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption" color="textSecondary">
                                    <FALightIcon icon="user-lock" className={props.classes.iconSecondary} size="small" /> {props.task.task_private ? 'Private' : 'Public'}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption" color="textSecondary">
                                    <FALightIcon icon="calendar-plus" className={props.classes.iconSecondary} size="small" /> {moment(props.task.task_created_datetime).format("DD/MM/YYYY HH:mm")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItem>
        </Paper>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployDialog: (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size))
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(TaskCard));