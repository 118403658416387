import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import LinearProgress from '@material-ui/core/LinearProgress';

class WarehouseLoadingBar extends React.PureComponent {
    render() {
        return (
            <LinearProgress variant={_.isEmpty(this.props.loading) ? 'determinate' : 'indeterminate'} value={100} />
        )
    }
}

function mapStateToProps(state){
    return {
        loading: {
            ...state.loading
        }
    };
}

export default connect(mapStateToProps)(WarehouseLoadingBar);