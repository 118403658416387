import React from 'react';
import DataTable from 'Components/Common/DataTables/CiDataTable';

class CustomerPaymentTermsList extends React.Component {

    render() {
        const { paymentTerms, isLoading, handleAmend } = this.props;
        return (
            <React.Fragment>
                <DataTable  
                    config={{
                        key: 'payment_term_id',
                        responsiveImportance: true,
                        isLoading: isLoading
                    }}
                    columns={[
                        {
                            heading: 'Name',
                            field: rowData => rowData.payment_term_name,
                            truncate: true
                        },
                        {
                            heading: 'Payment Settings',
                            field: rowData => rowData.payment_term_upfront_payment ? 'Upfront Payment' : 'Credit',
                        },
                        {
                            heading: 'Allow Barclay Card EPDQ',
                            field: rowData => rowData.payment_method_epqd,
                            fieldFormat: 'boolean',
                            alignment: 'center'

                        },
                        {
                            heading: 'Allow Stripe',
                            field: rowData => rowData.payment_method_stripe,
                            fieldFormat: 'boolean',
                            alignment: 'center'

                        },
                        {
                            heading: 'Allow PayPal',
                            field: rowData => rowData.payment_method_paypal,
                            fieldFormat: 'boolean',
                            alignment: 'center'

                        },
                        {
                            heading: 'Allow Cheque',
                            field: rowData => rowData.payment_method_cheque,
                            fieldFormat: 'boolean',
                            alignment: 'center'

                        },
                        {
                            heading: 'Allow Bank Transfer',
                            field: rowData => rowData.payment_method_bank_transfer,
                            fieldFormat: 'boolean',
                            alignment: 'center'

                        },
                        {
                            heading: 'Allow Account',
                            field: rowData => rowData.payment_method_account,
                            fieldFormat: 'boolean',
                            alignment: 'center'

                        },
                        {
                            heading: 'Allow Direct Debit',
                            field: rowData => rowData.payment_method_direct_debit,
                            fieldFormat: 'boolean',
                            alignment: 'center'

                        },
                        {
                            heading: 'Allow Cash',
                            field: rowData => rowData.payment_method_cash,
                            fieldFormat: 'boolean',
                            alignment: 'center'

                        },
                        {
                            heading: 'Allow Recurring Invoice',
                            field: rowData => rowData.payment_terms_allow_recurring_invoice,
                            fieldFormat: 'boolean',
                            alignment: 'center'
                        },
                        {
                            actions: rowData => {
                                return [
                                    { name: 'Amend', icon: 'pencil-alt', onClick: handleAmend }
                                ]
                            }
                        }
                    ]}
                    rows={paymentTerms}
                />
            </React.Fragment>
        );
    }
}

export default CustomerPaymentTermsList;