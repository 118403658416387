import React from 'react';
import * as errorImageSrc from './Types/Types';

class ImageWithError extends React.Component {
    render() {
        const { alt, src, width, height, style, className, onClick, type } = this.props;
        const errorImage = errorImageSrc[`DEFAULT_${type}`] ? errorImageSrc[`DEFAULT_${type}`] : errorImageSrc['DEFAULT_IMAGE'];
        return (
            <img 
                alt={alt ? alt : 'Image'}
                src={src ? src : 'NoImgSrcDeclaredFallback'}
                width={width}
                height={height}
                style={style}
                className={className}
                onClick={onClick}
                onError={(e) => {e.target.onerror=null;e.target.src=errorImage}}
            />
        )
    }
}

export default ImageWithError;