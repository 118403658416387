import React from 'react';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import API from '../../../API';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import Textarea from '../../Common/Inputs/Textarea';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { TextField } from '@material-ui/core';

const initialState = {
    staff: {},
    staffList: [],
    customers: {},
    customerList: [],
    customerContacts: [],
    customerContactList: [],
    suppliers: {},
    supplierList: [],
    supplierContacts: [],
    supplierContactList: [],
}

class MeetingInviteForm extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getStaff();
        this.getSuppliers();
        this.getCustomers();
    }
    getSuppliers = () => {
        API.get('/suppliers/all')
        .then((result) => {
            let supplierList = _.map(result.data, el => {
                return _.assign({
                    value: el.supp_id,
                    label: el.supp_company_name
                });
            });
            this.setState({
                supplierList: supplierList,
                suppliers: result.data
            })
        });
    }
    getSupplierContacts = (supplierId) => {
        if(supplierId) {
            let suppList = _.find(this.state.suppliers, {'supp_id': supplierId});
            let suppContPersonList = _.filter(suppList.active_contacts, {'supp_cont_type': 'Person'});
            let supplierContactList = _.map(suppContPersonList, (cont) => {
                return _.assign({
                    value: cont.supp_cont_id,
                    label: cont.supp_cont_first_name + ' ' + cont.supp_cont_last_name
                });
            });
            this.setState({
                ...this.state,
                supplierContactList: supplierContactList,
                supplierContacts: suppContPersonList
            });
        } else {
            this.setState({
                supplierContactList: initialState.supplierContactList,
                supplierContacts: initialState.supplierContacts
            });
        }
    }
    getCustomers = () => {
        API.get('/customers')
        .then((result) => {
            let customerList = _.map(result.data, el => {
                el.registeredPostcode = _.result(_.find(el?.addresses, {'address_type': 'Registered'}), 'address_postcode');
                return _.assign({
                    value: el.cust_id,
                    label: el.cust_name + ' ('+el?.registeredPostcode+')'
                });
            });
            this.setState({
                customerList: customerList,
                customers: result.data
            })
        });
    }
    getCustomerContacts = (customerId) => {
        if(customerId) {
            let custList = _.find(this.state.customers, {'cust_id': customerId});
            let customerContactList = [];
            _.map(custList.active_contacts, (cont) => {
                customerContactList.push({
                    value: cont.contact_id,
                    label: `${cont.contact_name} (${cont.contact_mobile || 'No mobile number'}) (${cont.contact_email || 'No email address'})`
                });
            });
            this.setState({
                ...this.state,
                customerContactList: customerContactList,
                customerContacts: custList.active_contacts
            });
        } else {
            this.setState({
                customerContactList: initialState.customerContactList,
                customerContacts: initialState.customerContacts
            });
        }
    }
    getStaff = () => {
        API.get('/staff/all', { params: { active: true } })
        .then(result => {
            if(result.data) {
                let staffList = _.map(result.data, (staff) => {
                    return _.assign({
                        value: staff.staff_id,
                        label: staff.staff_first_name + ' ' + staff.staff_last_name,
                        disabled: false
                    });
                });
                this.setState({
                    ...this.state,
                    staffList: staffList,
                    staff: result.data
                });
            }
        });
    }
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, 
        () => {
            let callback = null;
            if(fieldName === 'inviteType') {
                this.setState({
                    customerContacts: initialState.customerContacts,
                    customerContactList: initialState.customerContactList,
                    supplierContacts: initialState.supplierContacts,
                    supplierContactList: initialState.supplierContactList
                });
                callback = () => {
                    this.props.updateFormData({
                        smsName: '',
                        smsNumber: '',
                        emailName: '',
                        emailAddress: '',
                        staffContact: null,
                        supplier: null,
                        supplierContact: null,
                        customer: null,
                        customerContact: null
                    });
                }
            } else if(fieldName === 'staff') {
                callback = () => {
                    this.props.updateFormData({
                        smsName: '',
                        smsNumber: '',
                        emailName: '',
                        emailAddress: ''
                    });
                }
            } else if(fieldName === 'supplier') {
                this.getSupplierContacts(selectedOption && selectedOption.value);
                callback = () => {
                    this.props.updateFormData({
                        smsName: '',
                        smsNumber: '',
                        emailName: '',
                        emailAddress: ''
                    });
                }
            } else if(fieldName === 'customer') {                
                this.getCustomerContacts(selectedOption && selectedOption.value);
                callback = () => {
                    this.props.updateFormData({
                        smsName: '',
                        smsNumber: '',
                        emailName: '',
                        emailAddress: ''
                    });
                }
            } else if(fieldName === 'manual') {
                callback = () => {
                    this.props.updateFormData({
                        smsName: '',
                        smsNumber: '',
                        emailName: '',
                        emailAddress: ''
                    });
                }
            } else if(fieldName === 'supplierContact') {
                if(selectedOption) {
                    const supplierContact = this.state.supplierContacts.find(obj => {
                        return obj.supp_cont_id === selectedOption.value;
                    })
                    callback = () => {
                        this.props.updateFormData({
                            smsName: supplierContact.supp_cont_first_name + ' ' + supplierContact.supp_cont_last_name,
                            smsNumber: supplierContact.supp_cont_mobile,
                            emailName: supplierContact.supp_cont_first_name + ' ' + supplierContact.supp_cont_last_name,
                            emailAddress: supplierContact.supp_cont_email
                        });
                    }
                } else {
                    callback = () => {
                        this.props.updateFormData({
                            smsName: '',
                            smsNumber: '',
                            emailName: '',
                            emailAddress: ''
                        });
                    }
                }
            } else if(fieldName === 'customerContact') {
                if(selectedOption) {
                    const customerContact = this.state.customerContacts.find(obj => {
                        return obj.contact_id === selectedOption.value;
                    })
                    callback = () => {
                        this.props.updateFormData({
                            smsName: customerContact.contact_name,
                            smsNumber: customerContact.contact_mobile,
                            emailName: customerContact.contact_name,
                            emailAddress: customerContact.contact_email
                        });
                    }
                } else {
                    callback = () => {
                        this.props.updateFormData({
                            smsName: '',
                            smsNumber: '',
                            emailName: '',
                            emailAddress: ''
                        });
                    }
                }
            } else if(fieldName === 'staffContact') {
                if(selectedOption) {
                    const staffContact = this.state.staff.find(obj => {
                        return obj.staff_id === selectedOption.value;
                    })
                    callback = () => {
                        this.props.updateFormData({
                            smsName: staffContact.staff_first_name + ' ' + staffContact.staff_last_name,
                            smsNumber: staffContact.staff_mobile_number,
                            emailName: staffContact.staff_first_name + ' ' + staffContact.staff_last_name,
                            emailAddress: staffContact.staff_email
                        });
                    }
                } else {
                    callback = () => {
                        this.props.updateFormData({
                            smsName: '',
                            smsNumber: '',
                            emailName: '',
                            emailAddress: ''
                        });
                    }
                }
            }
            this.props.handleSelectChange(fieldName)(selectedOption, callback);
        });
    };
    render() {
        const { formData, formErrors, allowNone } = this.props;
        const inviteTypeOpts = allowNone ?
            [
                {value: 'staff', label: 'Staff'},
                {value: 'supplier', label: 'Supplier'},
                {value: 'customer', label: 'Customer'},
                {value: 'manual', label: 'Manual'},
                {value: 'none', label: 'None'}
            ] : [
                {value: 'staff', label: 'Staff'},
                {value: 'supplier', label: 'Supplier'},
                {value: 'customer', label: 'Customer'},
                {value: 'manual', label: 'Manual'}
            ];
        return (
            <React.Fragment>
                <FormControl margin="normal" fullWidth>
                    <AutoCompleteSelect 
                        options={inviteTypeOpts} 
                        label='Invite Type *'
                        value={formData.inviteType}
                        onChange={this.handleSelectChange('inviteType')}
                        error={formErrors && formErrors['inviteType'] && true}
                        errorText={formErrors && formErrors['inviteType']}
                    />
                </FormControl>
                {formData.inviteType && formData.inviteType === 'supplier' && (
                <React.Fragment>
                    <FormControl fullWidth margin="normal">
                    <AutoCompleteSelect 
                        options={this.state.supplierList} 
                        label='Supplier *'
                        value={formData.supplier}
                        onChange={this.handleSelectChange('supplier')}
                        error={formErrors && formErrors['supplier'] && true}
                        errorText={formErrors && formErrors['supplier'] && formErrors['supplier']}
                    />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <AutoCompleteSelect 
                            options={this.state.supplierContactList} 
                            label='Supplier Contact *'
                            value={formData.supplierContact}
                            onChange={this.handleSelectChange('supplierContact')}
                            error={formErrors && formErrors['supplierContact'] && true}
                            errorText={formErrors && formErrors['supplierContact']}
                        />
                    </FormControl>
                </React.Fragment>
                )}
                {formData.inviteType && formData.inviteType === 'customer' && (
                    <React.Fragment>
                        <FormControl fullWidth margin="normal">
                        <AutoCompleteSelect 
                            options={this.state.customerList}
                            label='Customer *'
                            value={formData.customer}
                            onChange={this.handleSelectChange('customer')}
                            error={formErrors && formErrors['customer'] && true}
                            errorText={formErrors && formErrors['customer'] && formErrors['customer']}
                        />
                        </FormControl>
                        <FormControl margin="normal" fullWidth>
                            <AutoCompleteSelect 
                                options={this.state.customerContactList} 
                                label='Customer Contact *'
                                value={formData.customerContact}
                                onChange={this.handleSelectChange('customerContact')}
                                error={formErrors && formErrors['customerContact'] && true}
                                errorText={formErrors && formErrors['customerContact']}
                            />
                        </FormControl>
                    </React.Fragment>
                )}
                {formData.inviteType && formData.inviteType === 'staff' && (
                    <FormControl margin="normal" fullWidth>
                        <AutoCompleteSelect
                            options={this.state.staffList} 
                            label='Staff Member'
                            value={formData.staffContact}
                            onChange={this.handleSelectChange('staffContact')}
                            error={formErrors && formErrors['staffContact'] && true}
                            errorText={formErrors && formErrors['staffContact']}
                        />
                    </FormControl>
                )}
                {formData.inviteType && formData.inviteType !== 'none' && (
                    <React.Fragment>
                        {formErrors['invitationMethod'] && (
                            <Typography>
                                <React.Fragment>
                                    <Typography component={"div"} variant={"subtitle2"} style={{color: '#f44336', marginTop: 24}}>
                                        {formErrors['invitationMethod']}
                                    </Typography>
                                </React.Fragment>
                            </Typography>
                        )}
                        <FormControl margin="normal" fullWidth>
                            <FormControlLabel
                                control={
                                    <Switch name="sendSmsInvite"
                                            checked={formData.sendSmsInvite}
                                            onChange={this.props.handleCheckChange('sendSmsInvite')}
                                            value={true}
                                            color="primary"
                                            margin="normal"
                                    />
                                }
                                label={<span style={{color: 'rgba(0, 0, 0, 0.54)'}}>Send SMS Invite?</span>}
                            />
                        </FormControl>
                        {formData.sendSmsInvite && (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <TextField
                                        id="smsName"
                                        name="smsName"
                                        label="Name *"
                                        value={formData.smsName}
                                        error={formErrors && formErrors['smsName']}
                                        helperText={formErrors && formErrors['smsName']}
                                        onChange={this.props.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="smsNumber"
                                        name="smsNumber"
                                        label="Mobile Number *"
                                        value={formData.smsNumber}
                                        error={formErrors && formErrors['smsNumber']}
                                        helperText={formErrors && formErrors['smsNumber']}
                                        onChange={this.props.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Textarea
                                        margin="none"
                                        name="smsBody"
                                        value={formData.smsBody}
                                        error={formErrors && formErrors['smsBody']}
                                        onChange={this.props.handleChange}
                                        rows={5}
                                    />
                                </Grid>
                            </React.Fragment>
                        )}
                        <FormControl margin="normal" fullWidth>
                            <FormControlLabel
                                control={
                                    <Switch name="sendEmailInvite"
                                            checked={formData.sendEmailInvite}
                                            onChange={this.props.handleCheckChange('sendEmailInvite')}
                                            value={true}
                                            color="primary"
                                            margin="normal" />
                                }
                                label={<span style={{color: 'rgba(0, 0, 0, 0.54)'}}>Send Email Invite?</span>}
                            />
                        </FormControl>
                        {formData.sendEmailInvite && (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <TextField
                                        id="emailName"
                                        name="emailName"
                                        label="Name *"
                                        value={formData.emailName}
                                        error={formErrors && formErrors['emailName']}
                                        helperText={formErrors && formErrors['emailName']}
                                        onChange={this.props.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="emailAddress"
                                        name="emailAddress"
                                        label="Email Address *"
                                        value={formData.emailAddress}
                                        error={formErrors && formErrors['emailAddress']}
                                        helperText={formErrors && formErrors['emailAddress']}
                                        onChange={this.props.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Textarea
                                        margin="none"
                                        name="emailBody"
                                        value={formData.emailBody}
                                        error={formErrors && formErrors['emailBody']}
                                        onChange={this.props.handleChange}
                                        rows={5}
                                    />
                                </Grid>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default MeetingInviteForm;