import React, { PureComponent }                 from 'react';
import { connect }                              from 'react-redux';
import API                                      from 'API';
import moment                                   from 'moment';
import _                                        from 'lodash';

import {deployDialog, closeDialog}              from 'Actions/Dialog/Dialog';

import DateRangeDialog                          from './../DateRangeDialog';
import ReportCell                               from './../ReportCell';
import LoadingCircle                            from 'Components/Common/LoadingCircle/LoadingCircle';
import AllIcon                                  from 'Components/Common/Icons/AllIcon';
import DataTable                                from 'Components/Common/DataTables/CiDataTable';
import CustomerReturnsEmailPrevDocsDialog       from 'Components/CustomerReturns/CustomerReturnsEmailPrevDocsDialog/CustomerReturnsEmailPrevDocsDialog';
import CustomerReturnsDownloadPrevDocsDialog    from 'Components/CustomerReturns/CustomerReturnsDownloadPrevDocsDialog/CustomerReturnsDownloadPrevDocsDialog';
import OrderDownloadPrevDocsDialog              from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog                 from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';
import DownloadCSV                              from 'Components/Common/Buttons/DownloadCSV';
import PaddedPaper                              from 'Components/Common/Paper/PaddedPaper';
import ImageWithError                           from 'Components/Common/ImageWithError/ImageWithError';
import PieChart                                 from 'Components/Common/Charts/PieChart';
import BarChart                                 from 'Components/Common/Charts/BarChart';

import logo                                     from 'Assets/Images/clenaware_logo_icon.png';

import { formatValidationErrors }               from 'Helpers/ErrorHelper';
import {getDatesBetweenTwoDates}                from 'Helpers/ReportsHelper';
import icons                                    from 'Helpers/IconHelper';
import { SystemColors, colors }                from 'Helpers/ColourHelper';

import { Card, CardContent, CardHeader, Typography, Grid, Divider, Tooltip, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Box, Button } from '@material-ui/core/';
import { withStyles }                           from '@material-ui/styles';
import { icon } from '@fortawesome/fontawesome-svg-core';

import {setPersistence} from 'Actions/StatePersistence/StatePersistence';
import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';

const styles = theme => ({
    card: {
        color: '#000',
        backgroundColor: '#FFF',
        padding: '0'
    },
    row: {
        color: '#000',
        backgroundColor: '#FFF',
        '&:hover': {
            backgroundColor: '#FCFCFC',
            cursor: 'pointer'
        },
    },
    header: {
        background:'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', 
        color: '#FFF'
    },
    legendWrapper: {
        padding: '0 1rem'
    },
    legendTitle: {
        marginBottom: '0.8rem'
    },
    legendItem: {
        display: 'grid',
        gridTemplateColumns: '30px 60px auto',
        marginBottom: '0.4rem'
    },
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }

});

const initialState = {
    filter:      {
        to:         moment(),
        from:       moment(),
    },
    parts:       {},
    days:        [],
    isLoading:   true,
    showResults: false,
    formErrors:  null
}


const OverviewRow = ({data, classes, label}) => {
    let rData = {
        in: {
            q: _.sumBy(data, 'in.q'),
            s: _.sumBy(data, 'in.s'),
            t: _.sumBy(data, 'in.t'),
            d: _.sumBy(data, 'in.d'),
            p: parseInt((_.sumBy(data, 'in.d')/_.sumBy(data, 'in.t'))*100)
        },
        out: {
            q: _.sumBy(data, 'out.q'),
            s: _.sumBy(data, 'out.s'),
            t: _.sumBy(data, 'out.t'),
            d: _.sumBy(data, 'out.d'),
            p: parseInt((_.sumBy(data, 'out.d')/_.sumBy(data, 'out.t'))*100)
        },
        tot: {
            q: _.sumBy(data, 'tot.q'),
            s: _.sumBy(data, 'tot.s'),
            t: _.sumBy(data, 'tot.t'),
            d: _.sumBy(data, 'tot.d'),
            p: parseInt((_.sumBy(data, 'tot.d')/_.sumBy(data, 'tot.t'))*100)
        }
        
    }
    return (
        <TableRow class={classes.row} sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
            <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  borderBottom: 0, textAlign:'center'}}>
                <AllIcon icon={icons[label]} size='medium'/>
                <Typography variant="h6" style={{textTransform: 'capitalize'}}>{label}</Typography>
            </TableCell>
            <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  borderBottom: 0}}>
                <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                    <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} primaryColor={colors.green} />
                    <Typography variant="h6" style={{fontSize: '14px'}}>
                        Invoiced
                    </Typography>
                </Box>
                <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                    <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} swapOpacity secondaryColor={colors.red} />
                    <Typography variant="h6" style={{fontSize: '14px'}}>
                        Credited
                    </Typography>
                </Box>
                <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                    <AllIcon icon={'equals'} style={{display: 'inline-block', paddingRight: '0.5rem', color: '#2d2d2d'}} />
                    <Typography variant="h6" style={{fontSize: '14px'}}>
                        Total
                    </Typography>
                </Box>
            </TableCell>
            <ReportCell data={rData} manualTotal style={{borderBottom: 0}} field={'q'} hidePound  />
            <ReportCell data={rData} manualTotal style={{borderBottom: 0}} field={'s'}            />
            <ReportCell data={rData} manualTotal style={{borderBottom: 0}} field={'t'}            />
            <ReportCell data={rData} manualTotal style={{borderBottom: 0}} field={'d'}            />
            <ReportCell data={rData} manualTotal style={{borderBottom: 0}} field={'p'} percentage />
        </TableRow>
    )
}

class SalesReportDiscountBreakDown extends PureComponent {
    constructor(props) {
        super(props);
        this.clearPageState  = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState    = hasPageState.bind(this);
        this.savePageState   = savePageState.bind(this);
        switch (true){
            case     props.cid > 0:    this.persistenceId = `SalesReport:discounts:cid:${props.cid}`;    break;
            case     props.partId > 0: this.persistenceId = `SalesReport:discounts:pid:${props.partId}`; break;
            default:                   this.persistenceId = `SalesReport:discounts`;                     break;
        }
        this.state           = this.getInitialState(initialState)
    }

    handleSetFilterParams = (filter) => { this.setState({filter, showResults: true}, this.getData)} 

    getData = () => {
        this.setState({isLoading: true}, ()=> {
            const params = {params: {...this.state.filter, cid:this.props.cid, partId:this.props.partId}}
            API.get( '/reports/salesReport/discounts/breakDown' , params)
            .then(res => {
                this.setState({
                    discounts: res.data, 
                    isLoading: false
                },()=>{this.savePageState()});
            })
        })
    }


    getCsvData = () => {
        const {discounts} = this.state;
        return _.map(discounts, i => {
            return _.assign({
                'Discount':     i.i, 
                'parts':        i.tot.c,
                'List':         i.tot.l,
                'discount':     i.tot.l - i.tot.s,
                'sold':         i.tot.s,
            });
        })
    }

    render() {
        const { isLoading, filter, discounts, showResults, days } = this.state;
        const { classes }                                         =  this.props;

        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <DateRangeDialog callback={this.handleSetFilterParams}/>
                    </PaddedPaper>
                </Grid>
                {showResults &&
                    <>
                        {isLoading ? <Grid item xs={12} lg={12}><LoadingCircle/></Grid> : 
                            <>
                                {!!discounts && 
                                    <>
                                        <Grid item xs={12} lg={5}>
                                            <PaddedPaper>
                                                <BarChart
                                                    stacked
                                                    sidebar
                                                    tLabel={(e)=>{return `£${parseFloat(e.raw).toFixed(2)}`}}
                                                    data={_.map(_.filter(discounts, i=>(i.tot.s.d + i.tot.p.d) > 0), i => {
                                                        return _.assign({
                                                            label: `${i.i}%`,
                                                            value: {
                                                                service: i.tot.s.d,
                                                                product: i.tot.p.d
                                                            },
                                                            color: {
                                                                service: colors.blue,
                                                                product: colors.green
                                                            }
                                                        })
                                                    })}
                                                    style={{height:'244px'}}
                                                    xLabel={i=>`£${i}`}
                                                />
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={12} lg={3}>
                                            <PaddedPaper>
                                                <Typography variant='h6' style={{textAlign:'center', marginBottom:7}}>Discount Usage</Typography>
                                                <PieChart 
                                                    data={_.orderBy(_.map(_.filter(discounts, i=>i.i != 0), i => {
                                                        let label = `${i.i}%`, color = null;
                                                        switch (label){
                                                            case '100%': color = colors.red; break;
                                                            case '40%':  color = colors.green; break;
                                                            case '30%':  color = colors.blue; break;
                                                            case '20%':  color = colors.yellow; break;
                                                        }
                                                        return _.assign({label,value: i.tot.c,color})
                                                    }), 'label')}
                                                    hideLegend
                                                    labelSegments
                                                    style={{ height: 205 }}
                                                    tLabel={e=>`${e.label}: ${parseFloat(e.raw).toFixed(2)}`}
                                                />
                                            </PaddedPaper>
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12} lg={12} style={{padding:'1em 0px'}}>
                                    <Divider/>
                                </Grid>
                                {!!discounts && 
                                    <Grid item xs={12} lg={12}>
                                        <DownloadCSV 
                                            data={this.getCsvData()} color='primary' variant='contained' 
                                            filename={`${filter.from}_to_${filter.to}_discount_report`} 
                                            style={{marginBottom: '1em'}} 
                                        >download Report</DownloadCSV>
                                        <Grid container xs={12} lg={12}>
                                            {_.map(_.orderBy(_.filter(discounts,i=>i.s),['i'],['asc']), i => 
                                                <Grid xs={12} lg={6} item style={{padding: '0.5rem 0.5rem', overflowX: 'auto'}}>
                                                    <Card className={classes.card} >
                                                        <CardHeader
                                                            title={`${i.i}% ${i.m ? ' (Manual)' : ''}`}
                                                            titleTypographyProps={{variant:'h6'}}
                                                            className={classes.header}
                                                        />
                                                        <CardContent style={{padding:5}}>
                                                            <Grid container xs={12}>
                                                                
                                                                <Grid item xs={3} style={{padding:'1em'}}>
                                                                    <PaddedPaper style={{textAlign:'center'}}>
                                                                        <Typography style={{paddingBottom:3}}>System</Typography>
                                                                        <AllIcon icon={i.s ? icons.true : icons.false} color={i.s ? colors.green : colors.red}/>
                                                                    </PaddedPaper>
                                                                </Grid>
                                                                <Grid item xs={3} style={{padding:'1em'}}>
                                                                    <PaddedPaper style={{textAlign:'center'}}>
                                                                        <Typography style={{paddingBottom:3}}>Customers</Typography>
                                                                        {i.c}
                                                                    </PaddedPaper>
                                                                </Grid>
                                                                <Grid item xs={3} style={{padding:'1em'}}>
                                                                    <PaddedPaper style={{textAlign:'center'}}>
                                                                        <Typography style={{paddingBottom:3}}>Invoices</Typography>
                                                                        {i.in.i}
                                                                    </PaddedPaper>
                                                                </Grid>
                                                                <Grid item xs={3} style={{padding:'1em'}}>
                                                                    <PaddedPaper style={{textAlign:'center'}}>
                                                                        <Typography style={{paddingBottom:3}}>Credit Notes</Typography>
                                                                        {Math.abs(i.out.i)}
                                                                    </PaddedPaper>
                                                                </Grid>
                                                                <Grid item xs={12} style={{padding:'1em', paddingTop:0}}>
                                                                    <PaddedPaper>
                                                                        <Grid container xs={12}>
                                                                            <Grid item container style={{width:'10%', padding:5}}>
                                                                                <Grid item xs={12}>&nbsp;</Grid>
                                                                                <Grid item xs={5}><AllIcon size={'small'} icon={icons.products} style={{display: 'inline-block', paddingRight: '0.5rem'}} /></Grid>
                                                                                <Grid item xs={7}>Products</Grid>
                                                                                <Grid item xs={12}>&nbsp;</Grid>
                                                                                <Grid item xs={12}>&nbsp;</Grid>
                                                                                <Grid item xs={5} style={{overflow: 'hidden', height:20}}><AllIcon icon={icons.services} style={{display: 'inline-block', paddingRight: '0.5rem'}} /></Grid>
                                                                                <Grid item xs={7}>Services</Grid>
                                                                                <Grid item xs={12}>&nbsp;</Grid>
                                                                                <Grid item xs={12}>&nbsp;</Grid>
                                                                                <Grid item xs={5}><AllIcon size={'small'} icon={'equals'} style={{display: 'inline-block', paddingRight: '0.5rem'}} /></Grid>
                                                                                <Grid item xs={7}>Total</Grid>
                                                                                <Grid item xs={12}>&nbsp;</Grid>
                                                                                <Grid item xs={12}>&nbsp;</Grid>
                                                                            </Grid>
                                                                            <Grid item container style={{width:'22.5%', padding:5, textAlign:'right'}}>
                                                                                <Grid item xs={12} style={{paddingBottom:5}}>Qty</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+{parseFloat(i.in.p.c).toFixed(3)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-{parseFloat(Math.abs(i.out.p.c)).toFixed(3)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{parseFloat(i.tot.p.c).toFixed(3)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+{parseFloat(i.in.s.c).toFixed(3)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-{parseFloat(Math.abs(i.out.s.c)).toFixed(3)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{parseFloat(i.tot.s.c).toFixed(3)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+{parseFloat(i.in.c).toFixed(3)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-{parseFloat(Math.abs(i.out.c)).toFixed(3)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{parseFloat(i.tot.c).toFixed(3)}</Grid>
                                                                            </Grid>
                                                                            <Grid item container style={{width:'22.5%', padding:5, textAlign:'right'}}>
                                                                                <Grid item xs={12} style={{paddingBottom:5}}>List Price</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.p.l).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.p.l)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.p.l).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.s.l).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.s.l)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.s.l).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.l).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.l)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.l).toFixed(2)}</Grid>
                                                                            </Grid>
                                                                            <Grid item container style={{width:'22.5%', padding:5, textAlign:'right'}}>
                                                                                <Grid item xs={12} style={{paddingBottom:5}}>Net</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.p.t).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.p.t)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.p.t).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.s.t).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.s.t)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.s.t).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.t).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.t)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.t).toFixed(2)}</Grid>
                                                                            </Grid>
                                                                            <Grid item container style={{width:'22.5%', padding:5, textAlign:'right'}}>
                                                                                <Grid item xs={12} style={{paddingBottom:5}}>Discount</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.p.d).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.p.d)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.p.d).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.s.d).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.s.d)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.s.d).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.d).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.d)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.d).toFixed(2)}</Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </PaddedPaper>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Divider />
                                        <Grid container xs={12} lg={12}>
                                            {_.map(_.orderBy(_.filter(discounts,i=>!i.s),['i'],['asc']), i => 
                                                <Grid xs={12} lg={6} item style={{padding: '0.5rem 0.5rem', overflowX: 'auto'}}>
                                                    <Card className={classes.card} >
                                                        <CardHeader
                                                            title={`${i.i}% (Manual)`}
                                                            titleTypographyProps={{variant:'h6'}}
                                                            className={classes.header}
                                                        />
                                                        <CardContent style={{padding:5}}>
                                                            <Grid container xs={12}>
                                                            {/*
                                                                <Grid item xs={3} style={{padding:'1em'}}>
                                                                    <PaddedPaper style={{textAlign:'center'}}>
                                                                        <Typography style={{paddingBottom:3}}>System</Typography>
                                                                        <AllIcon icon={i.s ? icons.true : icons.false} color={i.s ? colors.green : colors.red}/>
                                                                    </PaddedPaper>
                                                                </Grid>
                                                                <Grid item xs={3} style={{padding:'1em'}}>
                                                                    <PaddedPaper style={{textAlign:'center'}}>
                                                                        <Typography style={{paddingBottom:3}}>Customers</Typography>
                                                                        {i.c}
                                                                    </PaddedPaper>
                                                                </Grid>
                                                                <Grid item xs={3} style={{padding:'1em'}}>
                                                                    <PaddedPaper style={{textAlign:'center'}}>
                                                                        <Typography style={{paddingBottom:3}}>Invoices</Typography>
                                                                        {i.in.i}
                                                                    </PaddedPaper>
                                                                </Grid>
                                                                <Grid item xs={3} style={{padding:'1em'}}>
                                                                    <PaddedPaper style={{textAlign:'center'}}>
                                                                        <Typography style={{paddingBottom:3}}>Credit Notes</Typography>
                                                                        {Math.abs(i.out.i)}
                                                                    </PaddedPaper>
                                                                </Grid>
                                                            */}
                                                                <Grid item xs={12} style={{padding:'1em', paddingTop:0, marginTop:'1em'}}>
                                                                    <PaddedPaper>
                                                                        <Grid container xs={12}>
                                                                            <Grid item container style={{width:'10%', padding:5}}>
                                                                                <Grid item xs={12}>&nbsp;</Grid>
                                                                                <Grid item xs={5}><AllIcon size={'small'} icon={icons.products} style={{display: 'inline-block', paddingRight: '0.5rem'}} /></Grid>
                                                                                <Grid item xs={7}>Products</Grid>
                                                                                <Grid item xs={12}>&nbsp;</Grid>
                                                                                <Grid item xs={12}>&nbsp;</Grid>
                                                                                <Grid item xs={5} style={{overflow: 'hidden', height:20}}><AllIcon icon={icons.services} style={{display: 'inline-block', paddingRight: '0.5rem'}} /></Grid>
                                                                                <Grid item xs={7}>Services</Grid>
                                                                                <Grid item xs={12}>&nbsp;</Grid>
                                                                                <Grid item xs={12}>&nbsp;</Grid>
                                                                                <Grid item xs={5}><AllIcon size={'small'} icon={'equals'} style={{display: 'inline-block', paddingRight: '0.5rem'}} /></Grid>
                                                                                <Grid item xs={7}>Total</Grid>
                                                                                <Grid item xs={12}>&nbsp;</Grid>
                                                                                <Grid item xs={12}>&nbsp;</Grid>
                                                                            </Grid>
                                                                            <Grid item container style={{width:'22.5%', padding:5, textAlign:'right'}}>
                                                                                <Grid item xs={12} style={{paddingBottom:5}}>Qty</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+{parseFloat(i.in.p.c)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-{parseFloat(Math.abs(i.out.p.c))}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{parseFloat(i.tot.p.c)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+{parseFloat(i.in.s.c)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-{parseFloat(Math.abs(i.out.s.c))}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{parseFloat(i.tot.s.c)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+{parseFloat(i.in.c)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-{parseFloat(Math.abs(i.out.c))}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{parseFloat(i.tot.c)}</Grid>
                                                                            </Grid>
                                                                            <Grid item container style={{width:'22.5%', padding:5, textAlign:'right'}}>
                                                                                <Grid item xs={12} style={{paddingBottom:5}}>List Price</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.p.l).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.p.l)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.p.l).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.s.l).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.s.l)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.s.l).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.l).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.l)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.l).toFixed(2)}</Grid>
                                                                            </Grid>
                                                                            <Grid item container style={{width:'22.5%', padding:5, textAlign:'right'}}>
                                                                                <Grid item xs={12} style={{paddingBottom:5}}>Net</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.p.t).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.p.t)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.p.t).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.s.t).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.s.t)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.s.t).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.t).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.t)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.t).toFixed(2)}</Grid>
                                                                            </Grid>
                                                                            <Grid item container style={{width:'22.5%', padding:5, textAlign:'right'}}>
                                                                                <Grid item xs={12} style={{paddingBottom:5}}>Discount</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.p.d).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.p.d)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.p.d).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.s.d).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.s.d)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.s.d).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(i.in.d).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(i.out.d)).toFixed(2)}</Grid>
                                                                                <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{' '}£{parseFloat(i.tot.d).toFixed(2)}</Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </PaddedPaper>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            )}
                                        </Grid>
                                      {/*  <DataTable 
                                            config={{
                                                key: 'i',
                                                pagination: false,
                                                alternatingRowColours: true,
                                                responsiveimportant: true,
                                                isLoading: isLoading,
                                            }}
                                            columns={[
                                                {
                                                    heading:        'Discount',
                                                    field:          rowData => rowData.i,
                                                    dataRef:        'i',
                                                    important:      true,
                                                    sizeToContent:  true,
                                                    fieldSuffix:    '%',
                                                    alignment:      'center',
                                                    style:          {verticalAlign: 'top', padding:10, textAlign: 'center', paddingTop: 15}
                                                },
                                                {
                                                    heading:        'System',
                                                    field:          rowData => rowData.s,
                                                    important:      true,
                                                    fieldFormat:    'boolean',
                                                    tooltip:        rowData => rowData.s ? 'System Discount' : 'Manual Discount',
                                                    sizeToContent:  true,
                                                    alignment:      'center',
                                                    style:          {verticalAlign: 'top', padding:10, textAlign: 'center', paddingTop: 15}
                                                },
                                                {
                                                    heading:        'Customers',
                                                    field:          rowData => rowData.c,
                                                    dataRef:        'c',
                                                    important:      true,
                                                    sizeToContent:  true,
                                                    style:          {verticalAlign: 'top', padding:10, textAlign: 'center', paddingTop: 15}
                                                },
                                                {
                                                    heading: '',
                                                    field: rowData => 
                                                        <Grid container xs={12} style={{padding:0, height:'100%'}}>
                                                            <Grid item xs={6}><AllIcon size={'small'} icon={icons.products} style={{display: 'inline-block', paddingRight: '0.5rem'}} /></Grid>
                                                            <Grid item xs={6}>Products</Grid>
                                                            <Grid item xs={12}>&nbsp;</Grid>
                                                            <Grid item xs={12}>&nbsp;</Grid>
                                                            <Grid item xs={6} style={{overflow: 'hidden', height:20}}><AllIcon icon={icons.services} style={{display: 'inline-block', paddingRight: '0.5rem'}} /></Grid>
                                                            <Grid item xs={6}>Services</Grid>
                                                            <Grid item xs={12}>&nbsp;</Grid>
                                                            <Grid item xs={12}>&nbsp;</Grid>
                                                            <Grid item xs={6}><AllIcon size={'small'} icon={'equals'} style={{display: 'inline-block', paddingRight: '0.5rem'}} /></Grid>
                                                            <Grid item xs={6}>Total</Grid>
                                                            <Grid item xs={12}>&nbsp;</Grid>
                                                            <Grid item xs={12}>&nbsp;</Grid>
                                                        </Grid>,
                                                    sizeToContent: true,
                                                    alignment: 'right',
                                                    totalPrefix: 'Total:'
                                                },
                                                {
                                                    heading: 'Parts',
                                                    field: rowData => 
                                                        <Grid container xs={12} style={{padding:0, height:'100%'}}>
                                                            <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{parseFloat(rowData.tot.p.c)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+{parseFloat(rowData.in.p.c)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-{parseFloat(Math.abs(rowData.out.p.c))}</Grid>
                                                            <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{parseFloat(rowData.tot.s.c)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+{parseFloat(rowData.in.s.c)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-{parseFloat(Math.abs(rowData.out.s.c))}</Grid>
                                                            <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>{parseFloat(rowData.tot.c)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+{parseFloat(rowData.in.c)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-{parseFloat(Math.abs(rowData.out.c))}</Grid>
                                                        </Grid>,
                                                    dataRef: 'tot.c',
                                                    totalRef: 'tot.c',
                                                    alignment: 'right'
                                                },
                                                {
                                                    heading: 'List Price',
                                                    field: rowData => 
                                                        <Grid container xs={12} style={{padding:0, height:'100%'}}>
                                                            <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>£{parseFloat(rowData.tot.p.l).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(rowData.in.p.l).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(rowData.out.p.l)).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>£{parseFloat(rowData.tot.s.l).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(rowData.in.s.l).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(rowData.out.s.l)).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>£{parseFloat(rowData.tot.l).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(rowData.in.l).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(rowData.out.l)).toFixed(2)}</Grid>
                                                        </Grid>,
                                                    dataRef: 'tot.l',
                                                    totalRef: 'tot.l',
                                                    totalPrefix: '£',
                                                    alignment: 'right'
                                                },
                                                {
                                                    heading: 'Total',
                                                    field: rowData => 
                                                        <Grid container xs={12} style={{padding:0, height:'100%'}}>
                                                            <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>£{parseFloat(rowData.tot.p.t).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(rowData.in.p.t).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(rowData.out.p.t)).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>£{parseFloat(rowData.tot.s.t).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(rowData.in.s.t).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(rowData.out.s.t)).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>£{parseFloat(rowData.tot.t).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(rowData.in.t).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(rowData.out.t)).toFixed(2)}</Grid>
                                                        </Grid>,
                                                    dataRef: 'tot.t',
                                                    totalRef: 'tot.t',
                                                    totalPrefix: '£',
                                                    alignment: 'right'
                                                },
                                                {
                                                    heading: 'Discount',
                                                    field: rowData => 
                                                        <Grid container xs={12} style={{padding:0, height:'100%'}}>
                                                            <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>£{parseFloat(rowData.tot.p.d).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(rowData.in.p.d).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(rowData.out.p.d)).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>£{parseFloat(rowData.tot.s.d).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(rowData.in.s.d).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(rowData.out.s.d)).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{fontWeight:'bold', textAlign:'right'}}>£{parseFloat(rowData.tot.d).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.green, textAlign:'right'}}>+£{parseFloat(rowData.in.d).toFixed(2)}</Grid>
                                                            <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>-£{parseFloat(Math.abs(rowData.out.d)).toFixed(2)}</Grid>
                                                        </Grid>,
                                                    dataRef: 'tot.d',
                                                    totalRef: 'tot.d',
                                                    totalPrefix: '£',
                                                    alignment: 'right'
                                                },
                                            ]}
                                            rows={_.orderBy(discounts,['i'],['asc'])}
                                        />*/}
                                    </Grid>
                                }
                            </>
                        }
                    </>
                }
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    setPersistence: (key, state)                => dispatch(setPersistence(key, state)),
    deployDialog:   (content, title, size='sm') => dispatch(deployDialog(content, title, null, size)),
    closeDialog:    ()                          => {dispatch(closeDialog())}
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SalesReportDiscountBreakDown));