import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogTitle,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    List,
    ListItem, ListItemSecondaryAction,
    ListItemText,
    TextField, Typography
} from '@material-ui/core';

import AllIcon from 'Components/Common/Icons/AllIcon';

import IconHelper from 'Helpers/IconHelper';

import { colors } from 'Helpers/ColourHelper';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import InfoBox from 'Components/Common/reports/InfoBox';
import { checkEmails } from 'Helpers/EmailHelper';
import { checkMailingListContacts } from 'Helpers/EmailHelper';

const initialState = props => ({
    open: props.open,
    contacts:{contacts: props.eligibleContacts},
    mlContacts: props.mlContacts,
    editContact: null,
    forceSave: false,
    emailValidation: {
        error: null,
        warning: null,
    },
    isLoading: true
});

class CSVContactsDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.checkContacts();
    }

    deleteContact = contact => () => {
        this.setState({
            contacts: {
                ...this.state.contacts,
                contacts: _.map(_.filter(this.state.contacts.contacts, c => c.idx !== contact.idx), (c,idx) => ({...c, idx}))
            }
        }, this.checkContacts);
    }

    editContact = contact => () => {
        this.setState({
            editContact: contact
        });
    }

    
    validateContact = contact => () => {

        let contacts = this.state.contacts.contacts;
        let cIdx = _.findIndex(contacts, {idx: contact.idx});
        if (cIdx >= 0){
            contacts[cIdx] = {
                ...contacts[cIdx],
                valid: true
            }
        }
        this.setState({
            contacts: {
                ...this.state.contacts,
                contacts: contacts
            }
        }, this.checkContacts);
    }

    updateContact = () => {
        let { editContact, contacts } = this.state;

        this.setState({
            contacts: {
                ...contacts,
                contacts: _.map(contacts.contacts, c => c.idx === editContact.idx ? editContact : c)
            },
            editContact: null
        }, this.checkContacts);
    }

    handleChange = formData => ({target:{name, value}}) => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                [name]: value
            }
        });
    }

    checkContacts = async () => {
        let data = await checkMailingListContacts(this.state.contacts.contacts);
        this.setState({
            contacts: {
                ...data.breakdown,
                contacts: data.contacts,
                toAdd: data.toAdd,  
                dontAdd: data.dontAdd,
            },
            isLoading: false
        })
    }

    submit = () => {
        this.props.onSubmit(this.state.contacts)(true)();
    }

    removeAll = type => {
        let remContacts = this.state.contacts[type];
        let allContacts = this.state.contacts.contacts;
        this.setState({
            contacts: {
                ...this.state.contacts,
                contacts: _.filter(allContacts, contact => !_.find(remContacts, {idx: contact.idx})),
                [type]: []
            }
        }, this.checkContacts);
    }

    flattenDuplicates = () => {
        let { contacts } = this.state;
        this.setState({
            contacts: {
                ...contacts,
                contacts: _.uniqBy(contacts.contacts , i => i.contact_email.toLowerCase()),
                inListDuplicate: []
            }
        }, this.checkContacts);
    }

    render() {

        const { open, contacts, editContact, isLoading } = this.state;

        if (isLoading){
            return (
                <Dialog
                    open={open}
                    maxWidth="lg"
                    fullWidth
                >
                    <LoadingCircle/>
                </Dialog>
            )
        }

        return (
            <>
                <Dialog
                    open={open}
                    maxWidth="lg"
                    fullWidth
                >
                    <DialogTitle>CSV Contacts</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <InfoBox 
                                title='Total'
                                value={contacts.contacts.length}
                                icon={IconHelper.customers}
                                rowSize={7}
                            />
                            <InfoBox 
                                title='Existing'
                                info='Contacts in the system (connected to a customer & manually added)'
                                value={contacts.exsisting.length}
                                icon={IconHelper.true}
                                rowSize={7}
                            />
                            <InfoBox 
                                title='New'
                                info='Contacts not in the system'
                                value={contacts.new.length}
                                icon={IconHelper.plus}
                                rowSize={7}
                            />
                            <InfoBox 
                                title='Duplicate'
                                info='Contacts with duplicate emails'
                                value={[...contacts.inListDuplicate, ...contacts.outListDuplicate].length}
                                icon={IconHelper.copy}
                                rowSize={7}
                            />
                            <InfoBox 
                                title='Warnings'
                                info='Contacts that may have incorrect emails'
                                value={contacts.warning.length}
                                icon={IconHelper.warning}
                                rowSize={7}
                            />
                            <InfoBox 
                                title='Invalid'
                                info='Contacts with invalid emails'
                                value={contacts.invalid.length}
                                icon={IconHelper.ban}
                                rowSize={7}
                            />
                            
                            <InfoBox 
                                title='Not Eligible'
                                info='Contacts that are unsubscribed or accounts'
                                value={[...contacts.na].length}
                                icon={IconHelper.false}
                                rowSize={7}
                            />
                            <Grid item xs={12}>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                                        style={{backgroundColor: colors.green }}
                                    ><Typography variant='body1'>Existing Contacts: {contacts.exsisting.length}</Typography></ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{padding: '5px 8px'}}>
                                        <List dense style={{width: '100%'}}>
                                            {_.map(contacts.exsisting, (contact, i) => {
                                                return (
                                                    <ListItem key={`eligible-${contacts.exsisting.length}-${i}`}>
                                                        <ListItemText primary={`${contact.contact_name} (${contact?.customer ?? 'Manual Contact'})`} secondary={contact.contact_email} />
                                                        <ListItemSecondaryAction>
                                                            <AllIcon icon={IconHelper.bin} noMargin onClick={this.deleteContact(contact)}/>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                            }
                                            )}
                                        </List>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                                        style={{backgroundColor: colors.green }}
                                    ><Typography variant='body1'>New Contacts: {contacts.new.length}</Typography></ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{padding: '5px 8px'}}>
                                        <List dense style={{width: '100%'}}>
                                            {_.map(contacts.new, (contact, i) =>
                                                <ListItem key={`newContacts-${contacts.new.length}-${i}`}>
                                                    <ListItemText primary={`${contact.contact_name}`} secondary={contact.contact_email} />
                                                    <ListItemSecondaryAction>
                                                        <AllIcon icon={IconHelper.edit} noMargin onClick={this.editContact(contact)}/>
                                                        <AllIcon icon={IconHelper.bin} noMargin onClick={this.deleteContact(contact)}/>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )}
                                        </List>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                                        style={{backgroundColor: contacts.inListDuplicate.length > 0 ? colors.red : colors.green }}
                                    ><Typography variant='body1'>Duplicate Emails: {contacts.inListDuplicate.length} Contacts / {_.uniqBy(contacts.inListDuplicate, i => i.contact_email.toLowerCase()).length} Unique Emails</Typography></ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{padding: '5px 8px'}}>
                                        <Grid container>
                                            {contacts.inListDuplicate.length > 0 &&
                                                <Grid item xs={12} className='buttonRow'>
                                                    <Button
                                                        color='secondary'
                                                        variant='contained'
                                                        onClick={this.flattenDuplicates}
                                                        style={{
                                                            marginBottom: '1em',
                                                            marginRight: '1em'
                                                        }}
                                                    >
                                                        Remove All / Take First
                                                    </Button>
                                                </Grid>
                                            }
                                            {_.map(_.uniqBy(contacts.inListDuplicate, i => i.contact_email.toLowerCase()), ({contact_email}, pi) =>
                                                <Grid item xs={12}>
                                                    <Typography style={{paddingLeft: 16, fontWeight: 'bold'}} variant='body1'>{contact_email}</Typography>
                                                    <List dense style={{width: 'calc(100% - 16px)', marginLeft: '16px'}}>
                                                        {_.map(_.filter(contacts.inListDuplicate, {contact_email}), (contact, i) =>
                                                            <ListItem key={`inListDuplicate-${contacts.inListDuplicate.length}-${i}`}>
                                                                <ListItemText primary={`${contact.contact_name}`}/>
                                                                <ListItemSecondaryAction>
                                                                    <AllIcon icon={IconHelper.edit} noMargin onClick={this.editContact(contact)}/>
                                                                    <AllIcon icon={IconHelper.bin} noMargin onClick={this.deleteContact(contact)}/>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        )}
                                                    </List>
                                                </Grid>
                                            )}
                                            {contacts.inListDuplicate.length > 0 &&
                                                <Grid item xs={12} className='buttonRow'>
                                                    <Button
                                                        color='secondary'
                                                        variant='contained'
                                                        onClick={this.flattenDuplicates}
                                                        style={{
                                                            marginTop: '1em',
                                                            marginBottom: '1em',
                                                            marginRight: '1em'
                                                        }}
                                                    >
                                                        Remove All / Take First
                                                    </Button>
                                                </Grid>
                                            }
                                        </Grid>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                {contacts.outListDuplicate.length > 0 &&
                                    <ExpansionPanel>
                                        <ExpansionPanelSummary
                                            expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                                            style={{backgroundColor: colors.red }}
                                        ><Typography variant='body1'>Contacts Already Added: {contacts.outListDuplicate.length}</Typography></ExpansionPanelSummary>
                                        <ExpansionPanelDetails style={{padding: '5px 8px'}}>
                                            <List dense style={{width: '100%'}}>
                                                {contacts.outListDuplicate.length > 0 &&
                                                    <ListItem
                                                        style={{
                                                            marginTop: '1em',
                                                            marginBottom: '1em',
                                                        }}
                                                    >
                                                        <ListItemSecondaryAction>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={
                                                                    () => {
                                                                        this.props.deployConfirmation(
                                                                            'Are you sure you want to remove all contacts already added?',
                                                                            'Remove Contacts Already Added',
                                                                            () => {
                                                                                this.removeAll('outListDuplicate');
                                                                            }
                                                                        )
                                                                    }
                                                                }
                                                            >Remove All</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                }
                                                {_.map(contacts.outListDuplicate, (contact, i) =>
                                                    <ListItem key={`outListDuplicate-${contacts.outListDuplicate.length}-${i}`}>
                                                        <ListItemText primary={`${contact.contact_name} (${contact?.customer ?? 'Manual Contact'})`} secondary={contact.contact_email} />
                                                        <ListItemSecondaryAction>
                                                            <AllIcon icon={IconHelper.bin} noMargin onClick={this.deleteContact(contact)}/>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )}
                                                {contacts.outListDuplicate.length > 0 &&
                                                    <ListItem
                                                        style={{
                                                            marginTop: '1em',
                                                            marginBottom: '1em',
                                                        }}
                                                    >
                                                        <ListItemSecondaryAction>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={
                                                                    () => {
                                                                        this.props.deployConfirmation(
                                                                            'Are you sure you want to remove all contacts already added?',
                                                                            'Remove Contacts Already Added',
                                                                            () => {
                                                                                this.removeAll('outListDuplicate');
                                                                            }
                                                                        )
                                                                    }
                                                                }
                                                            >Remove All</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                }
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                }
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                                        style={{backgroundColor: contacts.warning.length > 0 ? colors.red : colors.green }}
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant='body1'>
                                                    Warnings: {contacts.warning.length}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='caption'>
                                                    Contacts that may have incorrect emails, soft bounced, misspelt valid domain or have a valid domain but invalid ending
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{padding: '5px 8px'}}>
                                        <List dense style={{width: '100%'}}>
                                            {
                                                contacts.warning.length > 0 &&
                                                    <ListItem
                                                        style={{
                                                            marginTop: '1em',
                                                            marginBottom: '1em',
                                                        }}
                                                    >
                                                        <ListItemSecondaryAction>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={
                                                                    () => {
                                                                        this.props.deployConfirmation(
                                                                            'Are you sure you want to remove all warning contacts?',
                                                                            'Remove Warning Contacts',
                                                                            () => {
                                                                                this.removeAll('warning');
                                                                            }
                                                                        )
                                                                    }
                                                                }
                                                            >Remove All</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                            }
                                            {_.map(contacts.warning, (contact, i) =>
                                                <ListItem key={`warnings-${contacts.warning.length}-${i}`}>
                                                    <ListItemText 
                                                        primary={`${contact.contact_name} (${contact?.customer ?? 'Manual Contact'})`} 
                                                        secondary={
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    {contact.contact_email}
                                                                </Grid>
                                                                {contact.reason &&
                                                                    <Grid item xs={12} style={{fontWeight: 'bold'}}>
                                                                        {( contact.reason === 'invalid domain' && 'Warning/Misspelt Domain')
                                                                            || ( contact.reason === 'soft bounce' && 'Bounced Email')
                                                                        }
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        }
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <AllIcon icon={IconHelper.edit} noMargin onClick={this.editContact(contact)}/>
                                                        <AllIcon icon={IconHelper.bin} noMargin onClick={this.deleteContact(contact)}/>
                                                        <AllIcon icon={IconHelper.true} noMargin onClick={this.validateContact(contact)}/>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )}
                                            {
                                                contacts.warning.length > 0 &&
                                                    <ListItem
                                                        style={{
                                                            marginTop: '1em',
                                                            marginBottom: '1em',
                                                        }}
                                                    >
                                                        <ListItemSecondaryAction>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={
                                                                    () => {
                                                                        this.props.deployConfirmation(
                                                                            'Are you sure you want to remove all warning contacts?',
                                                                            'Remove Warning Contacts',
                                                                            () => {
                                                                                this.removeAll('warning');
                                                                            }
                                                                        )
                                                                    }
                                                                }
                                                            >Remove All</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                }
                                        </List>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                                        style={{backgroundColor: contacts.invalid.length > 0 ? colors.red : colors.green }}
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant='body1'>
                                                    Invalid Emails: {contacts.invalid.length}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='caption'>
                                                    Contacts that are missing an email or have an invalid email
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{padding: '5px 8px'}}>
                                        <List dense style={{width: '100%'}}>
                                            {
                                                contacts.invalid.length > 0 &&
                                                    <ListItem
                                                        style={{
                                                            marginTop: '1em',
                                                            marginBottom: '1em',
                                                        }}
                                                    >
                                                        <ListItemSecondaryAction>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={
                                                                    () => {
                                                                        this.props.deployConfirmation(
                                                                            'Are you sure you want to remove all invalid contacts?',
                                                                            'Remove Invalid Contacts',
                                                                            () => {
                                                                                this.removeAll('invalid');
                                                                            }
                                                                        )
                                                                    }
                                                                }
                                                            >Remove All</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                            }
                                            {_.map(contacts.invalid, (contact, i) =>
                                                <ListItem key={`faultyEmails-${contacts.invalid.length}-${i}`}>
                                                    <ListItemText primary={`${contact.contact_name} (${contact?.customer ?? 'Manual Contact'})`} 
                                                        secondary={
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    {contact.contact_email}
                                                                </Grid>
                                                                {contact.reason &&
                                                                    <Grid item xs={12} style={{fontWeight: 'bold'}}>
                                                                        {( contact.reason === 'invalid' && 'Invalid Email')
                                                                            || (
                                                                                ( contact.reason === 'invalid ending' && 'Invalid Email Ending')
                                                                                || ( contact.reason === 'No Email' && 'Email Missing')
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        }
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <AllIcon icon={IconHelper.edit} noMargin onClick={this.editContact(contact)}/>
                                                        <AllIcon icon={IconHelper.bin} noMargin onClick={this.deleteContact(contact)}/>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )}
                                            {
                                                contacts.invalid.length > 0 &&
                                                    <ListItem
                                                        style={{
                                                            marginTop: '1em',
                                                            marginBottom: '1em',
                                                        }}
                                                    >
                                                        <ListItemSecondaryAction>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={
                                                                    () => {
                                                                        this.props.deployConfirmation(
                                                                            'Are you sure you want to remove all invalid contacts?',
                                                                            'Remove Invalid Contacts',
                                                                            () => {
                                                                                this.removeAll('invalid');
                                                                            }
                                                                        )
                                                                    }
                                                                }
                                                            >Remove All</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                }
                                        </List>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                                        style={{backgroundColor: contacts.na.length > 0 ? colors.red : colors.green }}
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant='body1'>
                                                    Not Eligible: {contacts.na.length} 
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='caption'>
                                                    Contacts that are unsubscribed, accounts, hard bounced, blacklisted or a closed customer
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{padding: '5px 8px'}}>
                                        <List dense style={{width: '100%'}}>
                                            {contacts.na.length > 0 &&
                                                <ListItem
                                                    style={{
                                                        marginTop: '1em',
                                                        marginBottom: '1em',
                                                    }}
                                                >
                                                    <ListItemSecondaryAction>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={
                                                                () => {
                                                                    this.props.deployConfirmation(
                                                                        'Are you sure you want to remove all N/A contacts?',
                                                                        'Remove All N/A Contacts',
                                                                        () => this.removeAll('na')
                                                                    )
                                                                }
                                                            }
                                                        >Remove All</Button>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            }
                                            {_.map(contacts.na, (contact, i) =>
                                                <ListItem key={`na-${contacts.na.length}-${i}`}>
                                                    <ListItemText primary={`${contact.contact_name} (${contact?.customer ?? 'Manual Contact'})`}
                                                        secondary={
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    {contact.contact_email}
                                                                </Grid>
                                                                {contact.reason &&
                                                                    <Grid item xs={12} style={{fontWeight: 'bold'}}>
                                                                        {( contact.reason === 'Closed Customer' && 'Customer Closed')
                                                                            || (
                                                                                ( contact.reason === 'unsubscribed' && 'Unsubscribed From Marketing')
                                                                                || (
                                                                                    ( contact.reason === 'accounts' && 'Accounts Email')
                                                                                    || (
                                                                                        ( contact.reason === 'hard bounce' && 'Bounced Email')
                                                                                        || (
                                                                                            ( contact.reason === 'blacklist' && 'Blacklisted Email')
                                                                                            || (
                                                                                                ( contact.reason === 'complaint' && 'Email Complaint')
                                                                                                || contact.reason
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        }
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <AllIcon icon={IconHelper.bin} noMargin onClick={this.deleteContact(contact)}/>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )}
                                            {contacts.na.length > 0 &&
                                                <ListItem
                                                    style={{
                                                        marginTop: '1em',
                                                        marginBottom: '1em',
                                                    }}
                                                >
                                                    <ListItemSecondaryAction>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={
                                                                () => {
                                                                    this.props.deployConfirmation(
                                                                        'Are you sure you want to remove all N/A contacts?',
                                                                        'Remove All N/A Contacts',
                                                                        () => this.removeAll('na')
                                                                    )
                                                                }
                                                            }
                                                        >Remove All</Button>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            }
                                        </List>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.submit}
                        >Save & Close</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={!!editContact}
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle>Edit Contact</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name='contact_name'
                                    label='Name *'
                                    value={editContact?.contact_name}
                                    onChange={this.handleChange('editContact')}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name='contact_email'
                                    label='Email *'
                                    value={editContact?.contact_email}
                                    onChange={this.handleChange('editContact')}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='outlined'
                            onClick={()=>this.setState({editContact: null})}
                        >Close</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.updateContact}
                        >Update Contact</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback, variant=null) => {
            dispatch(deployConfirmation(content, title, callback, variant=null))
        },
    }
}

export default connect(null, mapDispatchToProps)(CSVContactsDialog);