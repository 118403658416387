import React from "react";
import { useDispatch } from "react-redux";
import { deployConfirmation } from "Actions/Confirmation/Confirmation";
import { deploySnackBar } from "Actions/SnackBar/SnackBar";

const WildixContext = React.createContext();

const WildixProvider = ({ children }) => {
    const [dial, setDial] = React.useState(false);
    const dispatch = useDispatch();

    const useDial = (phoneNumber, requireConfirmation = true) => {
        if (dial === null) {
            if(requireConfirmation) {
                dispatch(deployConfirmation(`Are you sure you want to dial ${phoneNumber}?`, `Dial Out`, () => setDial(phoneNumber.replace(/\s/g, ""))));
            } else {
                setDial(phoneNumber.replace(/\s/g, ""));
            }
        } else {
            dispatch(deploySnackBar(`error`, `Could not connect to the VOIP service`));
        }
    };

    const value = {
        dial,
        setDial,
        useDial,
    };

    return <WildixContext.Provider value={value}>{children}</WildixContext.Provider>;
};

export { WildixProvider };

export default WildixContext;
