import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import API from '../../../API';
import _ from 'lodash';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { connect } from 'react-redux';
import SnackBar from '../../Common/SnackBars/SnackBar';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import SupplierDetails from './SupplierDetails';
import InvoiceDetails from './InvoiceDetails';
import DeliveryDetails from './/DeliveryDetails';
import EmailDetails from './EmailDetails';
import OrderItems from './OrderItems';
import PurchaseOrderDetails from './PurchaseOrderDetails';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData:  {
        type: '',
        orderRows: [
            {
                description: '',
                nominalCode: '',
                quantity: '',
                unitPrice: '',
                totalPrice: '',
                nominalList: [],
            }
        ],
        overallPrice: 0,
        address: '',
        paymentTerms: '',
        purchaseOrderDate: moment().toString(),
        orderDate: null,
        attendanceDate: null,
        jobReference: '',
        supplier: '',
        fao: '',
        faoEmail: '',
        emailTemplate: '',
        emailText: '',
        approvedRequiredBy: '',
        invoiceAddress: '',
        invoiceFao: '',
        // Delivery To
        deliveryTo: '',
        deliveryDefaultAddress: '',
        deliveryDefaultFao: '',
        deliverySupplierAddress: '',
        deliverySupplierFao: '',
        deliveryCustomer: '',
        deliveryCustomerAddressType: '',
        deliveryCustomerAddress: '',
        deliveryCustomerFao: '',
        previewPO: false
    },
    showDeliveryToDefault: false,
    showDeliveryToCustomer: false,
    showDeliveryToSupplier: false,
    showNoStockWarning: false,
    staffList: [],
    defaultItemsList: [],
    defaultItems: {},
    customers: {},
    customerAddresses: {},
    lists: {
        defaultAddressList: [],
        customerList: [],
        customerAddressTypeList: [],
        customerAddressList: [],
        customerContactList: [],
        deliverySupplierContactList: [],
        supplierList: [],
    },
    supplierWithAddressList: [],
    supplierContactList: [],
    suppliers: {},
    supplierContacts: {},
    templateList: [],
    nominalList: [],
    typeList: [], 
    templates: {},
    showForm: false,
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: true
}

class AddPurchaseOrder extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.populateDropdowns();
    }
    
    populateDropdowns = () => {
        Promise.all([
            API.get('/staff/all', { params: { active: true } }),
            API.get('/purchaseOrders/emailTemplates'),
            API.get('/purchaseOrders/addresses'),
            API.get('/suppliers/all'),
            API.get('/customers'),
            API.get('/purchaseOrders/defaultList'),
            API.get('/accounts/nominalCodes'),
            API.get('/purchaseOrders/types')
        ])
        .then(([staffRes, templateRes, addressRes, allSuppRes, custRes, defaultItems, nominalRes, typeRes]) =>  {
            let staffList = [];
            let templateList = [];
            let defaultAddressList = [];
            let supplierList = [];
            let supplierWithAddressList = [];
            let customerList = [];
            let defaultItemsList = [];
            let nominalList = [];
            let typeList = [];


            if(staffRes.data) {
                staffList = _.map(staffRes.data, (el) => {
                    return _.assign({
                        value: el.staff_id,
                        label: el.staff_first_name + ' ' + el.staff_last_name
                    });
                });
            }
            if(templateRes.data) {
                templateList = _.map(templateRes.data, (el) => {
                    return _.assign({
                        value: el.email_id,
                        label: el.email_name
                    });
                });
                templateList.unshift({
                    value: 0,
                    label: 'None'
                });
            }
            if(addressRes.data) {
                defaultAddressList = _.map(addressRes.data, (el) => {
                    return _.assign({
                        value: el.address_id,
                        label: `${el.address_company_name}, ${el.address_line_one}, ${el.address_town}, ${el.address_postcode}`
                    });
                });
            }
            if(allSuppRes.data){
                supplierList = _.map(allSuppRes.data, (el) => {
                    return _.assign({
                        value: el.supp_id,
                        label: el.supp_company_name
                    });
                });
                supplierWithAddressList = _.map(allSuppRes.data, (el) => {
                    return _.assign({
                        value: el.supp_id,
                        label: `${el.supp_company_name} ${String.fromCharCode(8226)} ${el.supp_address_line_one}, ${el.supp_city}, ${el.supp_postcode}`
                    });
                });
            }
            if(custRes.data) {
                customerList = _.map(custRes.data, (el) => {
                    el.registeredPostcode = _.result(_.find(el?.addresses, {'address_type': 'Registered'}), 'address_postcode');
                    return _.assign({
                        value: el.cust_id,
                        label: el.cust_name + ' ('+el?.registeredPostcode+')'
                    });
                });
            }
            if(defaultItems.data) {
                defaultItemsList = _.map(defaultItems.data, (el) => {
                    return _.assign({
                        value: el.po_list_description,
                        label: el.po_list_description
                    });
                });
            }
            if(nominalRes.data) {
                nominalList = _.map(nominalRes.data, (el) => {
                    return _.assign({
                        value: el.nominal_id,
                        label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                    });
                });
            }
            if(typeRes.data) {
                typeList = _.map(typeRes.data, (el) => {
                    return _.assign({
                        value: el.po_type_id,
                        label: el.po_type_name
                    });
                });
            }

            
            this.setState({
                staffList: staffList,
                templateList: templateList,
                templates: templateRes.data,
                lists: {
                    ...this.state.lists,
                    defaultAddressList: defaultAddressList,
                    customerList: customerList,
                    supplierWithAddressList: supplierWithAddressList,
                },
                supplierList: supplierList,
                suppliers: allSuppRes.data,
                customers: custRes.data,
                defaultItemsList: defaultItemsList,
                defaultItems: defaultItems.data,
                nominalList: nominalList,
                typeList: typeList,
                isLoading: false
            });
        });
    }

    handleSelectChange = fieldName => selectedOption => {

        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if((fieldName === 'type') && (this.state.formData.type > 0)){
                this.setState({
                    formData:{
                        ...initialState.formData,
                        type: selectedOption && selectedOption.value
                    },
                    showForm: true
                });
            }
            if((fieldName === 'supplier') && (this.state.formData.supplier > 0)){
                API.get(`/suppliers/${this.state.formData.supplier}`)
                .then(suppRes =>  {
                    let supplierContactList = _.map(suppRes.data.active_contacts, (el) => {
                        return _.assign({
                            value: el.supp_cont_id,
                            label: el.supp_cont_first_name + ' ' + el.supp_cont_last_name + ' (' + el.supp_cont_type + ')'
                        });
                    });
                    this.setState({
                        formData:{
                            ...this.state.formData,
                            address: suppRes.data.supp_address_line_one + "\n\r" +
                                    (suppRes.data.supp_address_line_two ? suppRes.data.supp_address_line_two + "\n\r":'') +
                                    (suppRes.data.supp_address_line_three ? suppRes.data.supp_address_line_three + "\n\r":'') +
                                    suppRes.data.supp_city + "\n\r" +
                                    (suppRes.data.county && suppRes.data.county.county_name ? suppRes.data.county.county_name + "\n\r":'') +
                                    (suppRes.data.country && suppRes.data.country.country_name ? suppRes.data.country.country_name + "\n\r":'') +
                                    suppRes.data.supp_postcode,
                            paymentTerms: suppRes.data.supp_payment_terms,
                            fao: initialState.formData.fao,
                            faoEmail: initialState.formData.faoEmail
                        },
                        supplierContactList: supplierContactList,
                        supplierContacts: suppRes.data.active_contacts
                    });
                });
            }
            if(fieldName === 'emailTemplate'){
                let email = _.find(this.state.templates, {'email_id': selectedOption && selectedOption.value });

                this.setState({
                    formData:{
                        ...this.state.formData,
                        emailText: (typeof email === 'undefined') ? '' : email.email_text
                    }
                });
            }
            if(fieldName === 'fao'){
                let contact = _.find(this.state.supplierContacts, {'supp_cont_id': selectedOption && selectedOption.value });

                this.setState({
                    formData:{
                        ...this.state.formData,
                        faoEmail: (typeof contact === 'undefined') ? '' : contact.supp_cont_email
                    }
                });
            }
            if(fieldName === 'deliveryTo'){
                this.setState({
                    showDeliveryToDefault: this.state.formData.deliveryTo === 'default' ? true : false,
                    showDeliveryToCustomer: this.state.formData.deliveryTo === 'customer' ? true : false,
                    showDeliveryToSupplier: this.state.formData.deliveryTo === 'supplier' ? true : false,
                });
            }
            if(fieldName === 'deliverySupplierAddress' && this.state.formData.deliverySupplierAddress > 0){
                let supplier = _.find(this.state.suppliers, {'supp_id': selectedOption && selectedOption.value });
                let deliverySupplierContactList = _.map(supplier.active_contacts, (el) => {
                    return _.assign({
                        value: el.supp_cont_id,
                        label: el.supp_cont_first_name + ' ' + el.supp_cont_last_name
                    });
                });
                this.setState({
                    lists: {
                        ...this.state.lists,
                        deliverySupplierContactList: deliverySupplierContactList,
                    },
                    formData:{
                        ...this.state.formData,
                        deliverySupplierFao: ''
                    }
                });
            }
            if(fieldName === 'deliveryCustomer' && this.state.formData.deliveryCustomer > 0){
                let cust = _.find(this.state.customers, {'cust_id': selectedOption && selectedOption.value });
                let customerAddressTypeList = _.map(cust.address_types, (el) => {
                    return _.assign({
                        value: el.address_type,
                        label: el.address_type
                    });
                });
                this.setState({
                    lists: {
                        ...this.state.lists,
                        customerAddressTypeList: customerAddressTypeList,
                    },
                    customerAddresses: cust.addresses,
                    formData:{
                        ...this.state.formData,
                        deliveryCustomerAddressType: '',
                        deliveryCustomerAddress: '',
                        deliveryCustomerFao: ''
                    }
                });
            }
            if(fieldName === 'deliveryCustomerAddressType' && this.state.formData.deliveryCustomerAddressType){
                let addresses = _.filter(this.state.customerAddresses, { 'address_type': selectedOption && selectedOption.value });

                let customerAddressList = _.map(_.filter(addresses, i=>i.address_active), (el) => {
                    return _.assign({
                        value: el.address_id,
                        label: `${el.address_name} - ${el.address_line_one}, ${el.address_town}, ${el.address_postcode}`
                    });
                });
                this.setState({
                    lists: {
                        ...this.state.lists,
                        customerAddressList: customerAddressList,
                    },
                    formData:{
                        ...this.state.formData,
                        deliveryCustomerAddress: '',
                        deliveryCustomerFao: ''
                    }
                });
            }
            if(fieldName === 'deliveryCustomerAddress' && this.state.formData.deliveryCustomerAddress){
                let address = _.find(this.state.customerAddresses, { 'address_id': selectedOption && selectedOption.value });

                let customerContactList = _.map(address.active_contacts, (el) => {
                    return _.assign({
                        value: el.contact_id,
                        label: el.contact_name
                    });
                });
                this.setState({
                    lists: {
                        ...this.state.lists,
                        customerContactList: customerContactList,
                    },
                    formData:{
                        ...this.state.formData,
                        deliveryCustomerFao: ''
                    }
                });
            }
        });
    }

    handleAddRow = fieldName => t => {
        const item = initialState.formData[fieldName][0];
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: [...this.state.formData[fieldName], item]
            }
        });
    }

    handleRowChange = (idx, array, decimals) => e => {
        const { name, value } = e.target;
        let newQty =  [...this.state.formData[array]];
        let newVal = decimals ? parseFloat(value).toFixed(decimals) : value;

        newQty[idx] = {
            ...newQty[idx],
            [name]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                [array]: newQty 
            }
        },
        () => {
            this.calculatePrice(idx, array);
        });
    };

    handleSelectRowChange = (idx, fieldName, array) => selectedOption => {

        let data =  [...this.state.formData[array]];
        let newVal = selectedOption && selectedOption.value;

        data[idx] = {
            ...data[idx],
            [fieldName]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                [array]: data
            }
        }, 
        () => {
            if(fieldName === 'description'){
                let otherItem = _.find(this.state.defaultItems, {'po_list_description': selectedOption && selectedOption.value });
                let data =  [...this.state.formData[array]];

                var nominalList = []
                if(otherItem && otherItem.nominal_codes.length > 0){
                    nominalList = _.map(otherItem.nominal_codes, (el) => {
                        return _.assign({
                            value: el.nominal_id,
                            label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                        });
                    });
                }

                data[idx] = {
                    ...data[idx],
                    nominalList: nominalList
                };
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [array]: data
                    }
                });
            }
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleRemoveRow = (idx, array) => () => {
        let rows = [...this.state.formData[array]]
        rows.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                [array]: rows
            }
        },
        () => {
            this.calculatePrice(idx, array);
        })
    }

    calculatePrice = (idx, array) => {
        let data =  [...this.state.formData[array]];
        if(data[idx]){
            data[idx] = {
                ...data[idx],
                totalPrice: data[idx] ? (data[idx].unitPrice * data[idx].quantity).toFixed(2) : (0).toFixed(2)
            };
            // Set new total price before setting overall price
            this.setState({
                formData: {
                    ...this.state.formData,
                    [array]: data
                }
            }, 
            () => {
                this.calculateOverallTotals(idx, array);
            });
        }
        else {
            // Remove row - just calculate overall totals
            this.calculateOverallTotals(idx, array);
        }
    }

    calculateOverallTotals = (idx, array) => {
        let overallPrice = 0;
        this.state.formData.orderRows.forEach((row) => {
            if(!isNaN((row.totalPrice*1))){
                overallPrice = overallPrice + (row.totalPrice*1);
            }
        });
        this.setState({
            formData: {
                ...this.state.formData,
                overallPrice: overallPrice.toFixed(2)
            }
        });
    }

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
            formData: {
                ...this.state.formData,
                previewPO: false
            }
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false,
            formData: {
                ...this.state.formData,
                previewPO: false
            }
        });
        this.submit();
    }
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handlePreviewPO = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                previewPO: true
            }
        },
        () => {
            this.submit();
        });
    }

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            const formData = {
                ...this.state.formData,
                purchaseOrderDate: (moment(new Date(this.state.formData.purchaseOrderDate)).format('DD/MM/YYYY')),
                orderDate: this.state.formData.orderDate && (moment(new Date(this.state.formData.orderDate)).format('DD/MM/YYYY')),
                attendanceDate: this.state.formData.attendanceDate && (moment(new Date(this.state.formData.attendanceDate)).format('DD/MM/YYYY')),
            }

            API.post('/purchaseOrders', formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    if(result.data.pdf){
                        this.setState({
                            isLoading: false
                        },
                        () => {
                            pdfFromBase64(result.data.pdf, result.data.pdfName);
                        });
                    }
                    else {
                        this.setState({
                            ...initialState,
                            snackbarOpen: true
                        }, () => {
                            this.populateDropdowns();
                        });
                    }
                }
                this.props.scrollToTop();
            });
        });
    }

    handleDateChange = fieldName => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: date
            }
        });
    };

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    }
       
    render() {
        const { formErrors, isLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Purchase Order
                    </Typography>
                </Grid>                
                {(isLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                )) || (
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    {formErrors && formErrors.generic && (
                                        <React.Fragment>
                                            <Typography component={"div"} style={{color: colors.red}}>
                                                {formErrors.generic}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                    <form noValidate autoComplete="off">
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                options={this.state.typeList} 
                                                label='Type *'
                                                value={this.state.formData.type}
                                                onChange={this.handleSelectChange('type')}
                                                error={formErrors && formErrors['type'] && true}
                                                errorText={formErrors && formErrors['type']}
                                            />
                                        </FormControl>
                                    </form>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
                {this.state.showForm &&
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <PurchaseOrderDetails 
                                        supplierList={this.state.supplierList}
                                        supplierContactList={this.state.supplierContactList}
                                        supplier={this.state.formData.supplier}
                                        fao={this.state.formData.fao}
                                        faoEmail={this.state.formData.faoEmail}
                                        purchaseOrderDate={this.state.formData.purchaseOrderDate}
                                        orderDate={this.state.formData.orderDate}
                                        attendanceDate={this.state.formData.attendanceDate}
                                        jobReference={this.state.formData.jobReference}
                                        formErrors={formErrors}
                                        handleSelectChange={this.handleSelectChange}
                                        handleChange={this.handleChange}
                                        handleDateChange={this.handleDateChange}
                                        type={this.state.formData.type}
                                    />
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <PaddedPaper>
                                    <SupplierDetails address={this.state.formData.address} paymentTerms={this.state.formData.paymentTerms} />
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <OrderItems
                                    orderRows={this.state.formData.orderRows}
                                    defaultItemsList={this.state.defaultItemsList}
                                    handleRemoveRow={this.handleRemoveRow}
                                    handleAddRow={this.handleAddRow}
                                    handleSelectRowChange={this.handleSelectRowChange}
                                    handleRowChange={this.handleRowChange}
                                    formErrors={formErrors}
                                    type={this.state.formData.type}
                                    nominalList={this.state.nominalList}
                                />
                                <Typography variant="h6" style={{display: 'flex', justifyContent:'flex-end', fontWeight: 'bold' }}>
                                    Total Price: £{this.state.formData.overallPrice}
                                </Typography>
                            </PaddedPaper>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <InvoiceDetails 
                                        lists={this.state.lists} 
                                        staffList={this.state.staffList}
                                        invoiceFao={this.state.formData.invoiceFao}
                                        invoiceAddress={this.state.formData.invoiceAddress}
                                        handleSelectChange={this.handleSelectChange}
                                        formErrors={formErrors}
                                    />
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <DeliveryDetails 
                                        lists={this.state.lists} 
                                        staffList={this.state.staffList}
                                        deliverySupplierFao={this.state.formData.invoicedeliverySupplierFaoAddress}
                                        deliverySupplierAddress={this.state.formData.invoiceAddeliverySupplierAddressdress}
                                        deliveryCustomerFao={this.state.formData.deliveryCustomerFao}
                                        deliveryCustomerAddress={this.state.formData.deliveryCustomerAddress}
                                        deliveryCustomerAddressType={this.state.formData.deliveryCustomerAddressType}
                                        deliveryCustomer={this.state.formData.deliveryCustomer}
                                        deliveryDefaultFao={this.state.formData.deliveryDefaultFao}
                                        deliveryDefaultAddress={this.state.formData.deliveryDefaultAddress}
                                        deliveryTo={this.state.formData.deliveryTo}
                                        showDeliveryToCustomer={this.state.showDeliveryToCustomer}
                                        showDeliveryToDefault={this.state.showDeliveryToDefault}
                                        showDeliveryToSupplier={this.state.showDeliveryToSupplier}
                                        handleSelectChange={this.handleSelectChange}
                                        formErrors={formErrors}
                                        type={this.state.formData.type}
                                    />
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <EmailDetails 
                                        templateList={this.state.templateList} 
                                        staffList={this.state.staffList} 
                                        formErrors={formErrors} 
                                        handleChange={this.handleChange} 
                                        handleSelectChange={this.handleSelectChange} 
                                        emailTemplate={this.state.emailTemplate} 
                                        emailText={this.state.formData.emailText} 
                                        approvedRequiredBy={this.state.formData.approvedRequiredBy}
                                    />
                                    <div className='buttonRow'>
                                        <Button onClick={this.handlePreviewPO}
                                                variant="outlined"
                                                color="default">
                                            Preview
                                        </Button>
                                        <Button onClick={this.handleConfirmationOpen}
                                                variant="contained"
                                                color="primary"
                                                disabled={this.state.isLoading}>
                                            Add
                                        </Button>
                                    </div>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
                <ConfirmationDialog 
                  open={this.state.confirmationOpen} 
                  success={this.handleConfirmationSuccess} 
                  close={this.handleConfirmationClose} 
                  title="Add This Purchase Order?" 
                  message="Are you sure you want to add this new purchase order?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added a new purchase purchase order'
                />
            </Grid>
        );
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
        loading: {
            ...state.loading
        }
    };
}

export default connect(mapStateToProps)(AddPurchaseOrder);
