import React  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import {
    Button,
    Grid,
    TextField,
    FormControl,
    DialogActions,
} from '@material-ui/core';
import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { getFormData, handleSelectChange, handleChange } from 'Functions/FormFunctions';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { toggleDialog } from 'Functions/MiscFunctions';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

const initialState = () => ({
    id: 0,
    formData: {
        id: null,
        name: '',
        categoryId: null,
        areaName: null,
    },
    formErrors: [],
    areas: [],
    categories: [],
    categoryList: [],
    isLoading: true,
});

class FormCategoriesDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.getFormData = getFormData.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleChange = handleChange.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData(); 
        
    }

    loadComponentData = () => {

        const { id } = this.props;

        this.setState({
            isLoading: true,
            id,
        },
        () => {
            this.getCategories();
        });
    }

    getCategories = () => {
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([
                API.get(`/forms/configuration/categories`),
                API.get(`/access/navigation/topLevel`),
            ])
            .then(([catRes, navRes]) => {

                
                let categories = [], areas = [];

                if(navRes.data){
                    _.map(navRes.data, nav => {
                        areas.push({value: nav.nm, label: nav.nm })
                    });
                }

                if(catRes.data) {
                    _.map(catRes.data, area => {
                        
                        _.map(area?.cat, cat => {
                            categories.push({value: cat.id, label: cat.nm, areaNm: area.nm })
                        })
                    });
                }
                
                this.setState({
                    ...this.state,
                    categories,
                    areas,
                    isLoading: false,
                }, 
                () => {
                    if (this.state.id) {
                        this.loadData();
                    }
                });

            })
        })
    }

    loadData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get(`/forms/configuration/categories/${this.state.id}`)
            .then(result => {
                if(result) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            name: result.data.nm,
                            categoryId: result.data.categoryId,
                            areaName: result.data.areaName,
                        },
                        isLoading: false,
                    }, () => {
                        this.areaChange(result.data.areaName)

                    });
                }
            },
            err => API.handleError(err));
        });
    }

    handleSubmit = () => {
        const   { id, formData }      = this.state,
                route   = id ? `/forms/configuration/categories/${id}`: `/forms/configuration/categories`;

        this.setState({
            isLoading: true
        }, () => {
            API.post(route, formData).then(result => {
                if(result) {
                    if(result?.data?.errors) {
                        this.setState({
                            formData,
                            formErrors: formatValidationErrors(result.data.errors),
                            isLoading: false
                        });
                    } else {

                        this.setState(initialState(), () => {
                            this.props.deploySnackBar('success', `You have successfully ${id ? 'updated the' : 'added a new'} category`);
                            this.props.refresh();
                        });
                    }
                }
            },
            err => API.handleError(err));
        });
    }

    areaChange = (label) => {
        let { categories, id, formData } = this.state;

        let categoryList = _.filter(categories, { areaNm: label });

        this.setState({
            formData: {
                ...this.state.formData,
                areaName: label,
                categoryId: id ? formData?.categoryId : null,
            },
            categoryList
        });
    }

    render() {
        const { formErrors, formData, isLoading, id, categoryList, areas } = this.state;
        return (   
            <>   
            <Grid container spacing={3}>  
                <Grid item xs={12}>
                    <form noValidate>
                        {(isLoading && (
                            <LoadingCircle />          
                        )) || (
                            <Grid container item spacing={3}>
                                
                                <Grid item xs={12}>
                                    <FormControl margin="none" fullWidth>
                                        <AutoCompleteSelect 
                                            options={areas}
                                            label='Area *'
                                            onChange={(v) => this.areaChange(v?.label)}
                                            error={formErrors && formErrors['areaName'] && true}
                                            errorText={formErrors && formErrors['areaName']}
                                            value={formData.areaName}
                                            noClear
                                            disabled={id}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl margin="none" fullWidth>
                                        <AutoCompleteSelect 
                                            options={[
                                                { label: "New Category", options: [{ label: "New Category", value: 0 }]}, 
                                                { label: "Existing Categories", options: [...categoryList]}
                                            ]}
                                            label='Category *'
                                            onChange={(v) => this.handleSelectChange("categoryId", v)}
                                            error={formErrors && formErrors['categoryId'] && true}
                                            errorText={formErrors && formErrors['categoryId']}
                                            value={formData.categoryId}
                                            noClear
                                            isGrouped
                                            disabled={!formData?.areaName || id}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="name"
                                        label={formData.categoryId ? "Sub-Category Name *" : "Category Name *"}
                                        value={formData.name}
                                        error={formErrors && formErrors['name'] && true}
                                        helperText={formErrors && formErrors['name']}
                                        onChange={this.handleChange}
                                        margin="none"
                                        fullWidth
                                        disabled={!formData?.areaName}
                                    />
                                </Grid>
                                <Grid item xs={12} align="right">
                                    <DialogActions className='pr-0 pb-0'>
                                        <Button onClick={() => this.props.onClose()} variant="text">
                                            <AllIcon icon="times" size={15} />
                                            Close
                                        </Button>
                                        <Button onClick={() => this.props.deployConfirmation(`Are you sure you want to ${id ? 'update' : 'add'} this category?`, `${id ? 'Update' : 'Add'} Category?`, this.handleSubmit)}
                                                variant="contained"
                                                color="primary"
                                                disabled={Object.values(formData).every(x => (x === null || x === ""))}>
                                            {id ? 'Update' : 'Add'}
                                        </Button>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        )}
                    </form>
                </Grid>  
            </Grid>
            </>  
        );
    }
}

const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
    }
)

export default connect(null, mapDispatchToProps)(FormCategoriesDialog);