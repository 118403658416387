import React, { useState, useEffect } from "react";
import { useDispatch }                from "react-redux";
import API                            from "API";
import _                              from 'lodash'
import { find }                       from "lodash";

import { pdfFromBase64 } from "Helpers/PDFHelper";

import FALightIcon from "Components/Common/Icons/FontAwesome/FALightIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import PDFViewer                                from 'Components/Common/PDFViewer/PDFViewer';
import AllIcon from "Components/Common/Icons/AllIcon";
import { FullScreenDialog }             from 'Components/Common/Dialogs/FullScreenDialog';

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { closeDialog, deployDialog } from "Actions/Dialog/Dialog";
import { deploySnackBar } from "Actions/SnackBar/SnackBar";

import { downloadS3File }  from 'Functions/MiscFunctions';

import { statusHelper } from 'Helpers/CustomerReturnsHelper';
import icons            from 'Helpers/IconHelper';
import { colors }       from 'Helpers/ColourHelper';
import ReturnDownloadDialog from "Components/CustomerReturns/CustomerReturns/ViewCustomerReturns/ReturnDownloadDialog";

const useStyles = makeStyles(() => ({
    button: {
        textTransform: "capitalize",
    },
    buttonRow: {
        float: "right",
        margin: 0,
    },
}));

const initialState = {
    order:         [],
    isLoading:     true,
    enablePayment: false,
    pdfData:       null,
};

const OrderDownloadPrevDocsDialog = ({ orderId }) => {
    
    const [state, setState] = useState(initialState),
        { order, isLoading, enablePayment, pdfData } = state,
        classes = useStyles(),
        dispatch = useDispatch(),
        handleDialogClose = () => dispatch(closeDialog()),

        handleDownload = (type, id = null) => {
            API.post(`/sales/orders/${orderId}/prevPdf`, {
                type: type,
                id,
            }).then((result) => {
                if (result.data) {
                    pdfFromBase64(result.data.pdf, result.data.pdfName);
                    dispatch(
                        deploySnackBar("success", "PDF successfully downloaded")
                    );
                    /*setState(state => ({...state, pdfData: res.data}))*/
                }
            });
        },
        handleCNDownload = (type, cnId) => {
            API.post(`/customerReturns/creditNote/${cnId}/prevPdf`, {type: type,}).then((result) => {
                if (result.data) {
                    pdfFromBase64(result.data.pdf, result.data.pdfName);
                    dispatch(
                        deploySnackBar("success", "PDF successfully downloaded")
                    );
                }
            });
        },
        handleDownloadFromFile = (file, name) => {
            downloadS3File(file, name);
        }

    useEffect(() => {
        API.get(`/sales/orders/${orderId}`).then((result) => {
            if (result.data) {
                let enablePayment = result.data?.payment_term?.payment_term_upfront_payment;
                setState((state) => ({
                    ...state,
                    order: result.data,
                    isLoading: false,
                    enablePayment
                }));
            }
        });
    }, [orderId]);

    return (
        <Grid container spacing={3}>
            {isLoading ? (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            ) : (
                <>
                    <Grid item xs={12}>
                        <Typography variant="caption">
                            Quotes
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            startIcon={
                                <FALightIcon
                                    icon="file"
                                    style={{ color: colors.yellow }}
                                />
                            }
                            className={classes.button}
                            onClick={() => handleDownload("quote")}
                            disabled={order.order_quote_id !== 0 ? false : true}
                        >
                            Original Quotation
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption">
                            Orders
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            startIcon={
                                <FALightIcon
                                    icon="file-user"
                                    style={{ color: colors.orange }}
                                />
                            }
                            className={classes.button}
                            onClick={() => handleDownload("acknowledgement")}
                        >
                            Order Acknowledgement
                        </Button>
                    </Grid>
                    {!find(order.activity, ["order_log_type","Declined"]) && !find(order.activity, ["order_log_type","Cancelled"]) &&
                        <Grid item xs={12} md={4}>
                            <Button
                                startIcon={
                                    <FALightIcon
                                        icon="user-check"
                                        style={{ color: colors.orange }}
                                    />
                                }
                                className={classes.button}
                                onClick={() => handleDownload("confirmation")}
                                disabled={
                                    find(order.activity, ["order_log_type","Approved",]) ? false: true}
                            >
                                Order Confirmation
                            </Button>
                        </Grid>
                    }
                    {enablePayment ?
                        <>
                            <Grid item xs={12} md={4}>
                                <Button
                                    startIcon={
                                        <FALightIcon
                                            icon="credit-card"
                                            style={{ color: colors.orange }}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={() => handleDownload("awaiting-payment")}
                                    disabled={
                                        find(order.activity, ["order_log_type","Confirmed",]) ? false: true}
                                >
                                    Order Awaiting Payment
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Button
                                    startIcon={
                                        <FALightIcon
                                            icon="receipt"
                                            style={{ color: colors.orange }}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={() => handleDownload("payment-confirmed")}
                                    disabled={
                                        find(order.activity, ["order_log_type","Payment Made"]) ? false : true}
                                >
                                    Order Payment Confirmation
                                </Button>
                            </Grid>
                        </>
                    :''}
                    {find(order.activity, ["order_log_type", "Declined", ]) &&
                        <Grid item xs={12} md={4}>
                            <Button
                                startIcon={
                                    <FALightIcon
                                        icon="user-times"
                                        style={{ color: colors.red }}
                                    />
                                }
                                className={classes.button}
                                onClick={() => handleDownload("declined")}
                            >
                                Order Declined
                            </Button>
                        </Grid>
                    }
                    {find(order.activity, ["order_log_type","Cancelled"]) &&
                        <Grid item xs={12} md={4}>
                            <Button
                                startIcon={
                                    <FALightIcon
                                        icon="ban"
                                        style={{ color: colors.red }}
                                    />
                                }
                                className={classes.button}
                                onClick={() => handleDownload("cancelled")}
                            >
                                Order Cancelled
                            </Button>
                        </Grid>
                    }
                    {order.despatch && order.despatch.length > 0 && (
                        <>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                Delivery Notes
                            </Typography>
                        </Grid>
                        {order.despatch.map((el) => (
                            <Grid
                                item
                                xs={12}
                                md={4}
                                key={el.desp_id}
                            >
                                <Button
                                    startIcon={
                                        <FALightIcon
                                            icon={"truck"}
                                            style={{ color: colors.orange }}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={() =>
                                        handleDownload(
                                            "delivery-note",
                                            el.desp_id
                                        )
                                    }
                                >
                                    {el.full_reference}
                                </Button>
                            </Grid>
                        ))}
                        </>
                    )}
                    {order.greenaware && order.greenaware.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Greenaware Request Forms
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                key={_.first(order.greenaware).gpr_id}
                            >
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.greenaware}
                                            color={colors.green}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={() =>
                                        handleDownload(
                                            "greenaware",
                                            _.first(order.greenaware).gpr_id
                                        )
                                    }
                                >
                                    {_.first(order.greenaware).template.gt_template_label}
                                </Button>
                            </Grid>
                            {_.map(_.uniqBy(_.filter(order.greenaware, i => i.gpr_collection_receipt), 'gpr_collection_receipt'), (b, bidx) => 
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    key={bidx}
                                >
                                    <Button
                                        startIcon={
                                            <AllIcon
                                                icon={icons.greenaware}
                                                color={colors.green}
                                            />
                                        }
                                        className={classes.button}
                                        onClick={() =>
                                            handleDownloadFromFile(b.file_url, b.gpr_collection_receipt )
                                        }
                                    >
                                        Collection Confirmation {_.uniqBy(order.greenaware, 'gpr_collection_receipt').length > 1 ? `${bidx + 1}` : ''}
                                    </Button>
                                </Grid>
                            )}
                        </>
                    )}
                    {order.picking_files && order.picking_files.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Linked Part Files 
                                </Typography>
                            </Grid>
                            {_.map(order.picking_files, (el, idx) => 
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    key={idx}
                                >
                                    <Button
                                        startIcon={
                                            <AllIcon
                                                icon={icons.picking}
                                            />
                                        }
                                        className={classes.button}
                                        onClick={() =>
                                            handleDownloadFromFile(el.file_upload, _.last(el.file_upload.split('/')) )
                                        }
                                    >
                                        v{el.orpl_version} - {el.orpl_name.replace('Clenaware Systems - ', '')}
                                    </Button>
                                </Grid>
                            )}
                        </>
                    )}
                    {order.invoices && order.invoices.length > 0 && (
                        <>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                Invoices
                            </Typography>
                        </Grid>
                        {order.invoices.map((el) => (
                            <Grid
                                item
                                xs={12}
                                md={4}
                                key={el.invoice_id}
                            >
                                <Button
                                    startIcon={
                                        <FALightIcon
                                            icon={"file-invoice-dollar"}
                                            style={{ color: colors.orange }}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={() =>
                                        handleDownload(
                                            "invoice",
                                            el.invoice_id
                                        )
                                    }
                                >
                                    {el.invoice_number}
                                </Button>
                            </Grid>
                        ))}
                        </>
                    )}
                    {order.commercial_invoices && order.commercial_invoices.length > 0 && (
                        <>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                Commercial Invoices
                            </Typography>
                        </Grid>
                        {order.commercial_invoices.map((el) => (
                            <Grid
                                item
                                xs={12}
                                md={4}
                                key={el.commercial_id}
                            >
                                <Button
                                    startIcon={
                                        <FALightIcon
                                            icon={"file-invoice"}
                                            style={{ color: colors.orange }}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={() =>
                                        handleDownload(
                                            "commercialInvoice",
                                            el.commercial_id
                                        )
                                    }
                                >
                                    {el.commercial_invoice_number}
                                </Button>
                            </Grid>
                        ))}
                        </>
                    )}
                    {order.files && _.filter(order.files, {co_file_type: "Engineer Job Sheet"}).length > 0 && (
                        <>
                             <Grid item xs={12}>
                                <Typography variant="caption">
                                    Engineer Job Sheets
                                </Typography>
                            </Grid>
                            {_.filter(order.files, {co_file_type: "Engineer Job Sheet"}).map((el, idx) => (
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    key={el.co_id}
                                >
                                    <Button
                                        startIcon={
                                            <FALightIcon
                                                icon={"file"}
                                                style={{ color: colors.orange }}
                                            />
                                        }
                                        className={classes.button}
                                        onClick={() =>downloadS3File(el.file_url)}
                                    >
                                       Engineer Job Sheet v{idx+1}
                                    </Button>
                                </Grid>
                            ))}
                        </>
                    )}
                    {order.files && _.filter(order.files, {co_file_type: "Purchase Order"}).length > 0 && (
                        <>
                             <Grid item xs={12}>
                                <Typography variant="caption">
                                    Purchase Orders
                                </Typography>
                            </Grid>
                            {_.filter(order.files, {co_file_type: "Purchase Order"}).map((el, idx) => (
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    key={el.co_id}
                                >
                                    <Button
                                        startIcon={
                                            <FALightIcon
                                                icon={"file"}
                                                style={{ color: colors.orange }}
                                            />
                                        }
                                        className={classes.button}
                                        onClick={() =>downloadS3File(el.file_url)}
                                    >
                                       Purchase Order v{idx+1}
                                    </Button>
                                </Grid>
                            ))}
                        </>
                    )}
                    {
                        order.customer_returns && order.customer_returns.length > 0 &&
                        <>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Customer Returns
                                </Typography>
                            </Grid>
                            {_.map(order.customer_returns, cr => 
                                <Grid item xs={12} >
                                    <ReturnDownloadDialog
                                        cr={cr}
                                        subElement
                                    />
                                </Grid>
                            )}
                        </>
                    }
                    {order.credit_notes && order.credit_notes.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Credit Notes 
                                </Typography>
                            </Grid>
                            {_.map(order.credit_notes, el => (
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="caption">
                                            {el.cn_reference}-{el.cn_reference_amendment > 9 ? el.cn_reference_amendment : `0${el.cn_reference_amendment}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button
                                            startIcon={<FALightIcon icon="file-user" style={{color: '#F39C12'}} />} 
                                            className={classes.button}
                                            onClick={() => handleCNDownload('Acknowledgement', el.cn_id)}
                                        >
                                            Credit Note Acknowledgement
                                        </Button>
                                    </Grid>
                                    {_.map(el.status_change, i => 
                                        <>
                                            {i.cnsc_status !== 'Approval' && i.cnsc_status !== 'Credit Note Awaiting Approval' && i.cnsc_status !== 'Credit Note' &&
                                                <Grid item xs={12} md={4}>
                                                    <Button
                                                        startIcon={<FALightIcon icon={statusHelper(i.cnsc_status)} style={{color: '#F39C12'}} />}
                                                        className={classes.button}
                                                        onClick={() => handleCNDownload(i.cnsc_status, el.cn_id)}
                                                    >
                                                        Credit Note {i.cnsc_status}
                                                    </Button>
                                                </Grid>
                                            }
                                        </>
                                    )}
                                    {el.invoice &&
                                        <>
                                            <Grid item xs={12} md={4}>
                                                <Button
                                                    startIcon={<FALightIcon icon="file-invoice-dollar" style={{color: '#F39C12'}} />} 
                                                    className={classes.button}
                                                    onClick={() => handleCNDownload('Invoice', el.cn_id)}
                                                >
                                                    {el.invoice.cni_invoice_number}
                                                </Button>
                                            </Grid>
                                        </>
                                    }
                                </>
                            ))}
                        </>
                    )}
                    {order.pickingDocuments.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Linked Part Files
                                </Typography>
                            </Grid>
                            {order.pickingDocuments.map((el) => (
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    key={el.doc_id}
                                >
                                    <Button
                                        startIcon={
                                            <FALightIcon
                                                icon={"file-pdf"}
                                                style={{ color: "#F39C12" }}
                                            />
                                        }
                                        className={classes.button}
                                        onClick={() =>downloadS3File(el.file_url_pdf_front)}
                                    >
                                        {el.doc_title}
                                    </Button>
                                </Grid>
                            ))}
                        </>
                    )}
                    <Grid item xs={12}>
                        <div className={classes.buttonRow}>
                            <Button
                                variant="outlined"
                                color="default"
                                onClick={handleDialogClose}
                            >
                                Close
                            </Button>
                        </div>
                    </Grid>
                </>
            )}
            <FullScreenDialog 
                open={pdfData}  
                content={
                    <PDFViewer 
                        pdfData={pdfData?.pdf}
                        onClose={()=>{setState(state => ({...state, pdfData: null}))}}
                        downloadTitle={pdfData?.pdfName}
                        allowPrint={true}
                    />
                }
            />
        </Grid>
    );
};

export default OrderDownloadPrevDocsDialog;
