import React from 'react';
import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { Grid, Button } from '@material-ui/core';
import GenericTable from './InfoDialogOptions/GenericTable';

const initialState = () => ({
    isLoading: true,
    data: {}
})

class InfoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }
    componentDidMount(){
        this.getData();
    }
    getData = () => {
        this.setState({
            isLoading: true
        }, ()=> {
            Promise.all([
                API.get(`/dashboard`, {params: { section: 'Outstanding', id: this.props.id, fullData: true}}),
                API.get(`/dashboard/${this.props.id}/layout`)
            ])
            .then(res => {
                this.setState({
                    data: res[0].data,
                    layout: res[1].data,
                    isLoading: false
                })
            })
        })
    }
    render () {
        const { isLoading, layout, data } = this.state;

        if (isLoading) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button variant="outlined"  onClick={this.props.closeDialog}>Close</Button>
                    </Grid>
                </Grid>
            )
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                   {(layout.type === 'table' && 
                        <GenericTable
                            data={data}
                            layout={layout}
                        />
                   )}
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button variant="outlined"  onClick={this.props.closeDialog}>Close</Button>
                </Grid>
            </Grid>
        )
    }
}

export default InfoDialog;