import React, {Component}  from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import API from '../../../../API';
import ConfirmationDialog from '../../../Common/Dialogs/ConfirmationDialog';
import { formatValidationErrors } from '../../../../Helpers/ErrorHelper';
import SnackBar from './../../../Common/SnackBars/SnackBar';
import PaddedPaper from '../../../Common/Paper/PaddedPaper';
import DragFileInputMulti from '../../../Common/Inputs/DragFileInputMulti';
import AutoCompleteSelect from '../../../Common/Selects/AutoCompleteSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import _ from 'lodash';
import FALightIcon from '../../../Common/Icons/FontAwesome/FALightIcon';
import { MAX_UPLOAD_LIMIT_SIZE } from './../../../../Constants';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import imageCompression from 'browser-image-compression';
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData: {
        siteName: '',
        machineType: '',
        serialNumber: '',
        engineerUploads: ''
    },
    siteList: [],
    formErrors: '',
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: false
}

class AddUploads extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getCustomerSiteAddresses();
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    compressFileUploads = (engineerUploads) => new Promise(function(resolve, reject) {
        let newArray = [];
        if(engineerUploads.length > 0){
            _.each(engineerUploads, (upld, idx) => {
                const options = {
                    maxSizeMB: 5,
                    maxIteration: 50,
                    useWebWorker: true
                }
                // Compress jpeg and png image by reducing storage size before uploading to server 
                imageCompression(upld, options)
                .then(res => {
                    newArray.push(new File([res], upld['name'], {type: upld['type'], lastModified: upld['lastModified']}));
                    if(parseInt(newArray.length) === parseInt(engineerUploads.length)){
                        resolve(newArray)
                    }
                })
                .catch(e => {
                    newArray.push(new File([upld], upld['name'], {type: upld['type'], lastModified: upld['lastModified']}));
                    if(parseInt(newArray.length) === parseInt(engineerUploads.length)){
                        resolve(newArray)
                    }
                }); 
            })
        }
        else {
            resolve(false)
        }
    });

     handleSubmit = () => {
        this.setState({
            isLoading: true
        }, () => {
            this.compressFileUploads(this.state.formData.engineerUploads)
            .then(res => {
                let newFormData = new FormData();
                if(res){
                    _.each(res, (file) => {
                        newFormData.append('file[]', file);
                    })
                }
                Object.keys(this.state.formData).forEach(key => {
                    if(key === 'file') {
                        newFormData.append('file[]', this.state.formData[key]);
                    } else if(key !== 'engineerUploads') {
                        newFormData.append(key, this.state.formData[key]);
                    }
                });

                API.post('/engineers/uploads', newFormData)
                .then(res => {
                    if(res.data.errors && res.data.errors.length > 0) {
                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors),
                            isLoading: false
                        });
                    } else {
                        this.setState({
                            ...initialState,
                            snackbarOpen: true,
                            isLoading: false
                        },() =>{
                            this.getCustomerSiteAddresses();
                        });
                    }
                });
            })
        });
    }
    
    handleSnackbarClose = () => {
        this.setState({ 
            snackbarOpen: false 
        });
    };

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        }, () => this.handleSubmit());
    }

    handleUploads = (drop, name, event) => {
        let newFiles = drop === true ? event.dataTransfer.files : event.target.files;
        let existingFiles = this.state.formData.engineerUploads;
        let allFiles = Array.prototype.slice.call(existingFiles).concat(Array.prototype.slice.call(newFiles))
        this.setState({
            formData: {
                ...this.state.formData,
                engineerUploads: allFiles
            }
        });
    }

    clearUploads = idx => {
        let engineerUploads = Array.from(this.state.formData.engineerUploads);
        engineerUploads.splice(idx, 1);
        if(engineerUploads.length === 0){
            engineerUploads = '';
        }
        this.setState({
            formData:{
                ...this.state.formData,
                engineerUploads
            }
        });
    }

    handleSelectChange = res => {
        this.setState({
            formData: {
                ...this.state.formData,
                siteName: res && res.value
            }
        });
    };

    getCustomerSiteAddresses = () => {
        API.get(`/customers/addresses/byType` , {
            params: {
                type: 'Site',
            }
        })
        .then((res) =>  {

            let addressList = _.map(res.data, (el) => {
                return _.assign({
                    value: el.address_name + ', ' + el.address_town + ', '+ el.address_postcode,
                    label: el.address_name + ', ' + el.address_town + ', '+ el.address_postcode
                });
            });

            this.setState({
                siteList: addressList
            });
        })
    }

    render() {
        const { formErrors, formData, snackbarOpen, isLoading } = this.state;
        return (            
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        New Upload(s)
                    </Typography>
                </Grid>
                {isLoading ? (
                    <Grid item xs={12} style={{textAlign:'center'}}>
                        <LoadingCircle />
                        <Typography variant="caption">
                            Please wait, this may take a few minutes...
                        </Typography>
                    </Grid>
                ) : (
                <Grid container item spacing={3}>                        
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>                                
                            <form onSubmit={this.handleSubmit} noValidate>   
                                <Typography variant="h6" gutterBottom>
                                    Upload Details
                                </Typography>
                                {formErrors && formErrors.generic && (
                                    <React.Fragment>
                                        <Typography component={"div"} style={{color: colors.red}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </React.Fragment>
                                )}
                                <FormControl error={formErrors['siteName'] && true} fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.siteList} 
                                        label='Site Name *'
                                        onChange={this.handleSelectChange}
                                        value={formData.siteName || ''}
                                        creatable
                                    />
                                    {formErrors['siteName'] &&
                                        <FormHelperText>{formErrors['siteName']}</FormHelperText>
                                    }
                                </FormControl>
                                <TextField  name="machineType"
                                            label="Machine Type"
                                            margin="normal"
                                            error={!!formErrors && formErrors['machineType'] && true}
                                            helperText={formErrors && formErrors['machineType']}
                                            value={formData.machineType || ''}
                                            onChange={this.handleChange}
                                            fullWidth
                                />
                                <TextField  name="serialNumber"
                                            label="Serial Number"
                                            margin="normal"
                                            error={!!formErrors && formErrors['serialNumber'] && true}
                                            helperText={formErrors && formErrors['serialNumber']}
                                            value={formData.serialNumber || ''}
                                            onChange={this.handleChange}
                                            fullWidth
                                />
                                <DragFileInputMulti
                                    id="engineerUploads"
                                    name="engineerUploads[]"
                                    label="Upload Pictures / Videos (.jpg, .tiff, .gif, .png, .mp4, .mov, .heic)*"
                                    file={formData.engineerUploads}
                                    type="file"
                                    error={formErrors && formErrors['engineerUploads'] && true}
                                    errorText={formErrors && formErrors['engineerUploads']}
                                    onChange={this.handleUploads}
                                    cancelOnClick={this.clearUploads}
                                    emptyText='No files uploaded'
                                />
                                <div className='buttonRow' style={{justifyContent: 'space-between', alignItems:'center'}}>
                                    <Typography variant="subtitle2">
                                        <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                                    </Typography>
                                    <Button onClick={this.handleConfirmationOpen}
                                            variant="contained"
                                            color="primary"
                                            disabled={Object.values(formData).every(x => (x === null || x === ""))}>
                                        Add
                                    </Button>
                                </div>
                                <ConfirmationDialog 
                                    open={this.state.confirmationOpen} 
                                    success={this.handleConfirmationSuccess} 
                                    close={this.handleConfirmationClose} 
                                    title="Upload Photo / Video?" 
                                    message="Are you sure you want to upload these photos / videos?"
                                />
                                <SnackBar
                                    variant="success"
                                    anchorOriginVertical='bottom'
                                    anchorOriginHorizontal='right'
                                    open={snackbarOpen}
                                    onClose={this.handleSnackbarClose}
                                    message="The upload was successfully added"
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                )}
            </Grid>
        );
    }
}

export default AddUploads;