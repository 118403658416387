import API                        from 'API';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import _                          from 'lodash';
import React                      from 'react';
import { connect }                from 'react-redux';

import FALightIcon                                  from 'Components/Common/Icons/FontAwesome/FALightIcon';
import ImageWithError                               from 'Components/Common/ImageWithError/ImageWithError';
import DragFileInput                                from 'Components/Common/Inputs/DragFileInput';
import LoadingCircle                                from 'Components/Common/LoadingCircle/LoadingCircle';
import { default as PaddedPaper, default as Paper } from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteMultiSelect                      from 'Components/Common/Selects/AutoCompleteMultiSelect';

import { Button, FormControl, Grid, TextField, Typography } from '@material-ui/core';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog }        from 'Actions/Dialog/Dialog';
import { deploySnackBar }     from 'Actions/SnackBar/SnackBar';

import BackButton         from 'Components/Common/Buttons/BackButton';
import Textarea           from 'Components/Common/Inputs/Textarea';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { colors } from 'Helpers/ColourHelper';
import { Checkbox } from '@material-ui/core';

const initialState = {
    formErrors: {},
    formData: {
        name:        '',
        logo:        '',
        trackingURL: '',
        rates:       '',
        zones:       []
    },
    courier:    {},
    supplier:   '',
    logoURL:    '',
    isLoading:  true,
    redirect:   false
} 

class UpdateCourier extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        API.get(`/couriers/${this.props.match.params.id}`)
        .then(result => {
            let logo = _.find(result.data.courier.file, {'cour_file_type': 'Logo'});
            this.setState({
                formData: {
                    ...this.state.formData,
                    name:        result.data.courier.cour_name,
                    trackingURL: result.data.courier.cour_tracking_url,
                    zones:       _.map(result.data.courier.zones, i=> ({id: i.cz_id, name: i.cz_name, desc: i.cz_desc,  postcodes: _.map(i.area_codes, j => j.cza_areacode), default: i.cz_default})),
                },
                courier:   result.data.courier,
                supplier: result.data.courier.supplier.supp_company_name,
                logoURL: logo ? logo.file_url : '',
                isLoading: false
            });
        });
    }

    handleChange = e => {
        const {value, name} = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        });
    }

    clearFile = type => {
        this.setState({
            formData: {
                ...this.state.formData,
                [type]: ''
            }
        });
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    handleSubmit = () => {
        this.props.closeDialog();
        this.setState({ isLoading: true}, () => {
            let newFormData = new FormData();
            Object.keys(this.state.formData).forEach(key => {
                if(key === 'file') {
                    newFormData.append('file[]', this.state.formData[key]);
                } else if (key === 'zones') {
                    newFormData.append(key, JSON.stringify(this.state.formData[key]));
                } else {
                    newFormData.append(key, this.state.formData[key]);
                }
            });
            API.post(`/couriers/${this.props.match.params.id}`, newFormData)
            .then(result => {
                if(result.data.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                } else if(result.data.success) {
                    this.setState({
                        isLoading: false
                    }, this.getData);
                    this.props.deploySnackBar('success', 'You have successfully updated courier');
                    this.props.history.push('/courier/search');
                }
            });
        });
    }

    handleZonesChange = e => {
        let value = e ? e.value : 0;
        let zones = this.state.formData.zones;
        let i     = zones.length;
        if (i > value) { for (i; value < i; i--){ zones.splice(-1)} }
        else {for (i; value > i; i++){zones.push({name: null, desc: null, postcodes: [], default: 0});}}

        this.setState({
            formData: {
                ...this.state.formData,
                zones
            }
        });
    }

    handleZoneChange = i => e => {
        let zones = this.state.formData.zones;
        zones[i][e.target.name] = e.target.value;
        this.setState({
            formData: {
                ...this.state.formData,
                zones
            }
        });
    }

    handleMultiSelectChange = i => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        let zones = this.state.formData.zones;
        zones[i].postcodes = values
        this.setState({
            formData: {
                ...this.state.formData,
                zones
            }
        });
    }
    handleZoneCheck = i => () => {
        let zones = this.state.formData.zones;
        zones[i].default = !zones[i].default;
        this.setState({
            formData: {
                ...this.state.formData,
                zones
            }
        });
    }

    render () {
        const {formErrors, formData:{name, logo, trackingURL, rates, zones}, supplier, logoURL, isLoading, courier} = this.state;

        if ( isLoading ) return (<LoadingCircle />);

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Update Courier
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Paper>
                        <form noValidate autoComplete="off">
                            <TextField
                                id="supplier"
                                name="supplier"
                                label="Supplier"
                                value={supplier}
                                margin="normal"
                                fullWidth
                                disabled
                            />
                            <TextField
                                id="name"
                                name="name"
                                label="Courier Name *"
                                value={name}
                                error={formErrors && formErrors['name'] && true}
                                helperText={formErrors && formErrors['name']}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth
                            />
                            <ImageWithError src={logoURL} style={{maxWidth: 250, margin: 15, marginLeft: 0}} />
                            <DragFileInput
                                id="logo"
                                name="logo"
                                label='Upload Logo'
                                file={logo}
                                errorText={formErrors && formErrors['logo']}
                                onChange={this.handleFileChange}
                                cancelOnClick={() => this.clearFile('logo')}
                                emptyText='No logo selected'
                            />
                            <TextField
                                id="trackingURL"
                                name="trackingURL"
                                label="Courier Tracking URL"
                                value={trackingURL}
                                error={formErrors && formErrors['trackingURL'] && true}
                                helperText={formErrors && formErrors['trackingURL']}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth
                            />
                            <Typography variant="subtitle2" style={{flex: 1}}>
                                <FALightIcon icon='info-circle' size='small' />
                                Url for tracking, e.g. http://www.royalmail.com/portal/rm/track?trackNumber={'{{reference}}'}
                            </Typography>
                            <DragFileInput
                                id="rates"
                                name="rates"
                                label='Upload Rates Card'
                                file={rates}
                                errorText={formErrors && formErrors['rates']}
                                onChange={this.handleFileChange}
                                cancelOnClick={() => this.clearFile('rates')}
                                emptyText='No logo selected'
                            />
                            <div className="buttonRow">
                                <BackButton />
                                <Button 
                                    disabled={_.filter(zones, i => !i.name || !i.desc).length > 0}
                                    onClick={()=>this.props.deployConfirmation('Update courier details?','Update details', this.handleSubmit)} 
                                    variant="contained" color="primary">Submit</Button>
                            </div>
                        </form>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PaddedPaper>
                        <Grid container spacing={3}>
                            <Grid item xs={12}><Typography variant="h6">Zones</Typography></Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="none">
                                    <AutoCompleteSelect
                                        noClear
                                        options={_.map(_.range(courier.zones.length,11), i => _.assign({value: i, label: i}))}
                                        value={zones.length}
                                        onChange={this.handleZonesChange}
                                        label='Number of zones'
                                    />
                                </FormControl>
                            </Grid>
                            {_.map(zones, (zone, i) => 
                                <Grid item xs={12} key={i}>
                                    <PaddedPaper>
                                        <FormControl fullWidth margin="none">
                                            <TextField
                                                name='name'
                                                label={`Zone ${i+1} Name *`}
                                                value={zone.name}
                                                onChange={this.handleZoneChange(i)}
                                            />
                                            <Textarea
                                                name='desc'
                                                label={`Zone ${i+1} Description *`}
                                                value={zone.desc}
                                                onChange={this.handleZoneChange(i)}
                                                rows={3}
                                            />
                                            <Typography variant="subtitle2" style={{color: colors.disabled}}>Area codes</Typography>
                                            <Grid container>
                                                <Grid item xs={2}>
                                                    <Checkbox 
                                                        value={zone.default} 
                                                        checked={zone.default} 
                                                        onChange={this.handleZoneCheck(i)} 
                                                        name='default'
                                                        color="primary" 
                                                        disabled={_.find(zones, (z,idx) => z.default && idx !== i)}
                                                    /> Default
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <AutoCompleteMultiSelect 
                                                        fullWidth
                                                        key={zone.postcodes.length}
                                                        options={_.map(zone.postcodes, i=>({value: i, label: i}))}
                                                        value={zone.postcodes}
                                                        onChange={this.handleMultiSelectChange(i)}
                                                        creatable
                                                        disabled={zone.default}
                                                        noClear
                                                    />
                                                </Grid>
                                            </Grid>
                                        </FormControl>
                                    </PaddedPaper>
                                </Grid>
                            )}
                        </Grid>
                    </PaddedPaper>
                </Grid>
            </Grid>
        )
    }

}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                        => dispatch(closeDialog()),
    deploySnackBar:     (type, message)           => dispatch(deploySnackBar(type, message)),
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
})

export default connect(null, mapDispatchToProps)(UpdateCourier);