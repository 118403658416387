import React, { Component } from 'react';
import { connect }          from 'react-redux';

import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';

import WarrantyDialog from './WarrantyDialog';

import { Button, Grid, InputAdornment, TextField } from '@material-ui/core';

import { closeDialog } from 'Actions/Dialog/Dialog';

const initialState = {
    showWarrantyDialog: false,
    partFormData: {
        id:              null,
        warrantyTerm:    null,
        notes:           '',

        qty:             '0.00',

        unitPrice:       '0.00',
        newUnitPrice:    '0.00',
        subTotal:        '0.00',

        discountRate:    '0.00',
        unitDiscount:    '0.00',
        totalDiscount:   '0.00',

        total:           '0.00',
    },
}

class EditPartDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            partFormData: this.props.partFormData
        }
    }


    handleChange = formData => name => event => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                [name]: event.target.value,
            }
        }, () => {
            if (formData === 'partFormData') {
                this.calculatePartData();
            }
        });
    }

    handleDP = formData => name => () => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                [name]: parseFloat(this.state.partFormData[name]).toFixed(2),
            }
        });
    }

    calculatePartData = () => {

        let unitPrice = parseFloat(this.state.partFormData.unitPrice);
        let qty = parseFloat(this.state.partFormData.qty);
        let discountRate = parseFloat(this.state.partFormData.discountRate) / 100;

        let subTotal = parseFloat(unitPrice * qty) || 0;

        let unitDiscount = parseFloat(unitPrice * discountRate) || 0;
        let totalDiscount = parseFloat(unitDiscount * qty) || 0;

        let newUnitPrice = parseFloat(unitPrice - unitDiscount);

        let total = parseFloat(newUnitPrice * qty);

        this.setState({
            partFormData: {
                ...this.state.partFormData,
                subTotal:        parseFloat(subTotal).toFixed(2),
                unitDiscount:    parseFloat(unitDiscount).toFixed(2),
                totalDiscount:   parseFloat(totalDiscount).toFixed(2),
                newUnitPrice:    parseFloat(newUnitPrice).toFixed(2),
                total:           parseFloat(total).toFixed(2)
            }
        });
    }

    handleUpdate = () => {
        this.props.updatePart(this.state.partFormData);
        this.props.closeDialog();
    }

    handleSelectChange = formData => name => event => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                [name]: event.value,
            }
        });
    }

    render(){
        const { partFormData, showWarrantyDialog } = this.state;

        const { part, warrantyTerms, discountType } = this.props;

        return (
            <Grid container spacing={3} style={{ alignItems: 'center' }}>
                <Grid item style={{width: 66}}>
                    <ImageWithError
                        src={part?.thumbnail_url}
                        style={{maxHeight:48, maxWidth:48}}
                    />
                </Grid>
                <Grid item xs={discountType ? 2 : 4}>
                    {part.part_number}<br/>
                    {part.part_description}
                </Grid>
                <Grid item style={{ width: discountType ? 140 : 250 }}>
                    <TextField
                        name="qty"
                        label="Quantity"
                        value={partFormData.qty}
                        onChange= {this.handleChange('partFormData')('qty')}
                        onBlur= {this.handleDP('partFormData')('qty')}
                        type="number"
                        fullWidth
                        disabled={!partFormData.id}
                    />
                </Grid>
                <Grid item style={{ width: discountType ? 140 : 250 }}>
                    <TextField
                        name="unitPrice"
                        label="Unit Price"
                        value={partFormData.unitPrice}
                        onChange= {this.handleChange('partFormData')('unitPrice')}
                        onBlur= {this.handleDP('partFormData')('unitPrice')}
                        type="number"
                        fullWidth
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                            inputProps: {
                                min: 0
                            }
                        }}
                        disabled={!partFormData.id}
                    />
                </Grid>
                {!!discountType && <>
                    <Grid item style={{width: 140}}>
                        <TextField
                            name="discountRate"
                            label="Discount Rate"
                            value={partFormData.discountRate}
                            onChange= {this.handleChange('partFormData')('discountRate')}
                            onBlur= {this.handleDP('partFormData')('discountRate')}
                            type="number"
                            fullWidth
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                                inputProps: {
                                    min: 0
                                }
                            }}
                            disabled={!partFormData.id}
                        />
                    </Grid>
                    <Grid item style={{width: 130}}>
                        <TextField
                            name="unitDiscount"
                            label="Unit Discount"
                            value={partFormData.unitDiscount || null}
                            type="number"
                            fullWidth
                            disabled
                            InputProps={{
                                startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item style={{width: 130}}>
                        <TextField
                            name="newUnitPrice"
                            label="New Unit Price"
                            value={partFormData.newUnitPrice || null}
                            type="number"
                            fullWidth
                            disabled
                            InputProps={{
                                startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                    <Grid item style={{width: 130}}>
                        <TextField
                            name="totalDiscount"
                            label="Discount Total"
                            value={partFormData.totalDiscount || null}
                            type="number"
                            fullWidth
                            disabled
                            InputProps={{
                                startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </Grid>
                </>}
                <Grid item style={{ width: discountType ? 130 : 250 }}>
                    <TextField
                        name="total"
                        label="Total Price"
                        value={partFormData.total || null}
                        type="number"
                        fullWidth
                        disabled
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                            inputProps: {
                                min: 0
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                    <Button color='primary' variant='contained' onClick={()=>this.setState({showWarrantyDialog: true})} >Update Warranty/Notes</Button>
                    <Button color='primary' variant='contained' onClick={this.handleUpdate} disabled={!partFormData.id || parseFloat(partFormData.qty) <= 0 || parseFloat(partFormData.unitPrice) < 0} >Update</Button>
                </Grid>
                <WarrantyDialog 
                    open={showWarrantyDialog}
                    handleClose={()=>this.setState({showWarrantyDialog: false})}
                    onSubmit={()=>this.setState({showWarrantyDialog: false})}
                    handleChange={this.handleChange('partFormData')}
                    handleSelectChange={this.handleSelectChange('partFormData')}
                    partFormData={partFormData}
                    warrantyTerms={warrantyTerms}
                />
            </Grid>  
        );
    }

}



const mapDispatchToProps = dispatch => ({   
    closeDialog: () => dispatch(closeDialog())
})

export default connect(null, mapDispatchToProps)(EditPartDialog);
