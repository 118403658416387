import React from 'react';

import { Button, FormControl, InputAdornment, TextField, Typography } from '@material-ui/core';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

class SelectSerialAndWarrantyList extends React.Component {
    renderList = () => {
        let list = [];

        this.props.formData.forEach((el, i) => {
            if (this.props.pick.part.part_req_serial_number == 'Manual') {

                if(i === 0) {
                    list.push(
                        <Typography variant="h6" className="text-left">
                            Assign Serial Numbers For The Following {this.props.itemQty} Product(s):
                        </Typography>
                    );
                }

                list.push(
                    <FormControl fullWidth margin="normal" key={i}>
                        <TextField 
                            value={this.props.formData[i]}
                            onChange={e =>{this.props.handleChange(i, e.target.value)}}
                            label={`Product ${i+1} *`}
                            error={this.props.formErrors?.['SerialNumber'+i] && true}
                            helperText={this.props.formErrors?.['SerialNumber'+i]}
                            name='manual'
                        />
                    </FormControl>
                );

            } else if(this.props.serialNumberList) {

                if(i === 0) {
                    list.push(
                        <Typography variant="h6" className="text-left">
                            Assign Serial Numbers For The Following {this.props.itemQty} Product(s):
                        </Typography>
                    );
                }
                
                list.push(
                    <FormControl fullWidth margin="normal" key={i}>
                        <AutoCompleteSelect 
                            options={this.props.serialNumberList} 
                            label={`Product ${i+1} *`}
                            value={this.props.formData[i]}
                            onChange={e => this.props.handleChange(i, e)}
                            isSearchable={false}
                        />
                    </FormControl>
                );

            } else {

                if(i === 0) {
                    list.push(
                        <div className="text-left">
                            <Typography variant="h6">
                                Enter Warranty Void Labels For The Following {this.props.itemQty} Product(s):
                            </Typography>
                            <span className="textError">
                                {this.props.formErrors?.warrantyVoid}
                            </span>
                        </div>
                    );
                }

                list.push(
                    <FormControl fullWidth margin="normal" key={i}>
                        <TextField 
                            value={this.props.formData[i]}
                            onChange={e => this.props.handleChange(i, e)}
                            label={`Product ${i+1} *`}
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">CS-</InputAdornment>
                            }}
                            onInput = {e => {
                                e.target.value = e.target.value?.length > 7 ? e.target.value.slice(0, 7) : e.target.value;
                            }}
                            error={this.props.formErrors?.['warrantyVoid'+i] && true}
                            helperText={this.props.formErrors?.['warrantyVoid'+i]}
                        />
                    </FormControl>
                );

            }
        });

        return list;
    }

    render() {
        return (
            <>
                {this.renderList()}
                <div className="buttonRow">
                    <Button
                        onClick={() => this.props.handlePickSuccess()}
                        variant="contained"
                        color="primary"
                        disabled={this.props.formData.includes('')}
                        style={{fontSize: 24}}
                        fullWidth
                    >
                        Confirm
                    </Button>
                </div>
            </>
        );

    }

}

export default SelectSerialAndWarrantyList;