import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import API from '../../../API';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DataTable from '../../Common/DataTables/CiDataTable';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import {DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE} from 'Constants'
import { gridPageChanged, gridRowsPerPageChanged } from 'Functions/FormFunctions'
import { 
    connect 
} from 'react-redux';
import _ from 'lodash';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { ca } from 'date-fns/locale';

const initialState = () => ({
    keywords: '',
    searchResults: {},
    categories: [],
    subCatagories: [],
    access: {
        viewVideo: false,
        updateVideo: false
    },
    isLoading: true,
    //gridPagination: {
    //    page: DEFAULT_PAGINATION_PAGE,
    //    rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    //}
});
class VideoSearch extends Component {
    constructor(props) {
        super(props);
        this.clearPageState         = clearPageState.bind(this);
        this.getInitialState        = getInitialState.bind(this);
        this.hasPageState           = hasPageState.bind(this);
        this.savePageState          = savePageState.bind(this);
        this.persistenceId          = 'Video:Search';
        this.state                  = this.getInitialState(initialState());
        this.gridPageChanged        = gridPageChanged.bind(this);
        this.gridRowsPerPageChanged = gridRowsPerPageChanged.bind(this);
    }

    componentDidMount = () => {
        this.getSearchData();
        this.frontFilter();
        this.getAccess();
    }

    getAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/view-video'), 
            API.get('/staff/my/access/check/update-video'),
        ])
        .then(([viewRes, updateRes]) =>  {
            viewRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        viewVideo: viewRes.data.has_access
                    }
                });
            updateRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        updateVideo: updateRes.data.has_access
                    }
                });
        })
    }

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/videos/all' , {
                params: {
                    keywords: this.state.keywords,
                },
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        categories: this.state.categories.length > 0 ? this.state.categories : _.map(_.uniqBy(result.data, rowData => rowData.category_name), rowData => ({
                            value: rowData.category_name,
                            label: rowData.category_name
                        })),
                        subCatagories: this.state.subCatagories.length > 0 ? this.state.subCatagories : _.map(_.uniqBy(result.data, rowData => rowData.subcategory_name), rowData => ({
                            value: rowData.subcategory_name,
                            label: rowData.subcategory_name,
                            category: rowData.category_name
                        })),
                        isLoading: false
                    }, this.frontFilter)
                }
            })
        })
    }

    handleSearchKeyword = (e) => {
       
        this.setState({
            keywords: e.target.value,
            //gridPagination: initialState.gridPagination
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    handleCategorySearch = (name) => (e) => {
        this.setState({
            [name]: e ? e.value : '',
            //gridPagination: initialState.gridPagination
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    }

    reset = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
            catName: '',
            subCatName: '',
            //gridPagination: initialState.gridPagination
        }, 
        () => {
            //this.props.history.replace();
            this.getSearchData();
            
        });
    }

    frontFilter = () => {
        this.setState({
            searchResults: _.filter(this.state.searchResults, (rowData) => {
                let allow = true;
                if (this.state.catName){
                    if (rowData.category_name !== this.state.catName){
                        allow = false;
                    }
                }
                if (this.state.subCatName){
                    if (rowData.subcategory_name !== this.state.subCatName){
                        allow = false;
                    }
                }
                return allow;
            }),
        })
    }

    render() {
        const { access, gridPagination, keywords, catName, subCatName, categories, subCatagories } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Video Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect
                                        label="Category"
                                        value={catName}
                                        onChange={this.handleCategorySearch('catName')}
                                        options={categories}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect
                                        label="Sub Category"
                                        value={subCatName}
                                        onChange={this.handleCategorySearch('subCatName')}
                                        options={catName ? _.filter(subCatagories, {category: catName}) : subCatagories}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.reset}
                                            variant="outlined"
                                            color="default"
                                           
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key:                    'video_id',
                                pagination:             true,
                                //page: gridPagination.page,
                                //rowsPerPage: gridPagination.rowsPerPage,
                                //gridPageChanged: this.gridPageChanged,
                                //gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                persistenceId:          this.persistenceId,
                                isLoading:              this.state.isLoading,
                                alternatingRowColours:  true,
                                responsiveImportant:    true,
                            }}
                            columns={[
                                {
                                    heading: 'Category',
                                    field: rowData => rowData.category_name,
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Sub Category',
                                    field: rowData => rowData.subcategory_name,
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Title',
                                    field: rowData => rowData.video_title,
                                    truncate: true,
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil-alt', link: { pathname: '/videos/update/' + rowData.video_id, state: {keywords, gridPagination}}, disabled: !(access.updateVideo && (rowData.latest_update_order && rowData.latest_file && rowData.latest_update_order.vuo_version > rowData.latest_file.video_file_version))},
                                            {name: 'View', icon: 'search', link: { pathname: '/videos/view/' + rowData.video_id, state: {keywords, gridPagination}}, disabled: !access.viewVideo}
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(VideoSearch);