import React, {Component}  from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import API from '../../../API';
import _ from 'lodash';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import SnackBar from '../../Common/SnackBars/SnackBar';
import { TableFooter } from '@material-ui/core';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import SupplierDetails from './SupplierDetails';
import PartOrderDetails from './PartOrderDetails';
import DeliveryDetails from './DeliveryDetails';
import EmailDetails from './EmailDetails';
import OtherItems from './OtherItems';
import PartItems from './PartItems';
import InvoiceDetails from './InvoiceDetails';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import {colors} from 'Helpers/ColourHelper';
import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';

const initialState = {
    formData:  {
        supplier: '',
        partRows: [{
            part: '',
            quantity: '',
            unitPrice: '',
            totalPrice: '',
            nominalCode: '',
            supplierReference: '',
            drawingNo: '',
            drawingPdfName: '',
            drawingUrl: '',
            attachPO: false,
            notes: '',
            disableAttachDrawing: false,
            deliveryType: '',
            notesDialogOpen: false,
            notesButtonColor: '',
            //Schedule
            dialogOpen: false,
            deliveryDate: null,
            frequency: '',
            duration: '',
            durationPlaceholder: 'Month(s)',
            startingFrom: null,
            deliverySchedule: '',
            deliveryTable: '',
            issueStatusPDF: '',
            scheduleButtonColor: '',
            nominalList: [],
            validSchedule: false
        }],
        otherRows: [{
            description: '',
            nominalCode: '',
            quantity: '',
            unitPrice: '',
            totalPrice: '',
            nominalList: [],
        }],
        overallPrice: 0.00,
        supplierDetails: {
            company: '',
            address: '',
            paymentTerms: '',
        },
        purchaseOrderDate: moment().toString(),
        orderDate: null,
        fao: '',
        faoEmail: '',
        emailTemplate: '',
        emailText: '',
        approvedRequiredBy: '',
        invoiceAddress: '',
        invoiceFao: '',
        // Delivery To
        deliveryTo: '',
        deliveryDefaultAddress: '',
        deliveryDefaultFao: '',
        deliverySupplierAddress: '',
        deliverySupplierFao: '',
        deliveryCustomer: '',
        deliveryCustomerAddressType: '',
        deliveryCustomerAddress: '',
        deliveryCustomerFao: '',
        previewPPO: false,
        previewSchedule: false
    },
    showDeliveryToDefault: false,
    showDeliveryToCustomer: false,
    showDeliveryToSupplier: false,
    showNoStockWarning: false,
    supplierWithCostsList: [],
    staffList: [],
    defaultItemsList: [],
    defaultItems: {},
    customers: {},
    customerAddresses: {},
    lists: {
        defaultAddressList: [],
        customerList: [],
        customerAddressTypeList: [],
        customerAddressList: [],
        customerContactList: [],
        deliverySupplierContactList: [],
        supplierList: [],
    },    
    supplierContactList: [],
    suppliers: {},
    supplierContacts: {},
    partList: [],
    templateList: [],
    templates: {},
    showForm: false,
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    snackbarError: {
        snackbarErrorOpen: false,
        snackbarErrorMessage: ''
    },
    isLoading: true
}

class AddPartsOrder extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.populateDropdowns();
    }
    
    populateDropdowns = () => {
        Promise.all([
            API.get('/suppliers/withCosts'), 
            API.get('/staff/all', { params: { active: true } }),
            API.get('/partsOrdering/emailTemplates'),
            API.get('/partsOrdering/addresses'),
            API.get('/suppliers/all'),
            API.get('/customers'),
            API.get('/partsOrdering/defaultList'),
            API.get('/accounts/nominalCodes')
        ])
        .then(([suppRes, staffRes, templateRes, addressRes, allSuppRes, custRes, defaultItems, nominalRes]) =>  {
            let supplierWithCostsList = [];
            let staffList = [];
            let templateList = [];
            let defaultAddressList = [];
            let supplierList = [];
            let customerList = [];
            let defaultItemsList = [];

            if(suppRes.data){
                supplierWithCostsList = _.map(suppRes.data, (el) => {
                    return _.assign({
                        value: el.supp_id,
                        label: el.supp_company_name
                    });
                });
            }
            if(staffRes.data) {
                staffList = _.map(staffRes.data, (el) => {
                    return _.assign({
                        value: el.staff_id,
                        label: el.staff_first_name + ' ' + el.staff_last_name
                    });
                });
            }
            if(templateRes.data) {
                templateList = _.map(templateRes.data, (el) => {
                    return _.assign({
                        value: el.ppo_email_id,
                        label: el.ppo_email_name
                    });
                });
                templateList.unshift({
                    value: 0,
                    label: 'None'
                });
            }
            if(addressRes.data) {
                defaultAddressList = _.map(addressRes.data, (el) => {
                    return _.assign({
                        value: el.ppo_address_id,
                        label: `${el.ppo_address_company_name}, ${el.ppo_address_line_one}, ${el.ppo_address_town}, ${el.ppo_address_postcode}`
                    });
                });
            }
            if(allSuppRes.data){
                supplierList = _.map(allSuppRes.data, (el) => {
                    return _.assign({
                        value: el.supp_id,
                        label: `${el.supp_company_name} ${String.fromCharCode(8226)} ${el.supp_address_line_one}, ${el.supp_city}, ${el.supp_postcode}`
                    });
                });
            }
            if(custRes.data) {
                customerList = _.map(custRes.data, (el) => {
                    el.registeredPostcode = _.result(_.find(el?.addresses, {'address_type': 'Registered'}), 'address_postcode');
                    return _.assign({
                        value: el.cust_id,
                        label: el.cust_name + ' ('+el?.registeredPostcode+')'
                    });
                });
            }
            if(defaultItems.data) {
                defaultItemsList = _.map(defaultItems.data, (el) => {
                    return _.assign({
                        value: el.ppo_list_description,
                        label: el.ppo_list_description
                    });
                });
            }
            this.setState({
                supplierWithCostsList: supplierWithCostsList,
                staffList: staffList,
                templateList: templateList,
                templates: templateRes.data,
                lists: {
                    ...this.state.lists,
                    defaultAddressList: defaultAddressList,
                    customerList: customerList,
                    supplierList: supplierList,

                },
                suppliers: allSuppRes.data,
                customers: custRes.data,
                defaultItemsList: defaultItemsList,
                defaultItems: defaultItems.data,
                isLoading: false
            });
        });
    }

    handleSelectChange = fieldName => selectedOption => {

        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if((fieldName === 'supplier') && (this.state.formData.supplier > 0)){
                Promise.all([
                    API.get(`/suppliers/${this.state.formData.supplier}`), 
                    API.get(`/parts/bySupplier/${this.state.formData.supplier}`),
                ])
                .then(([suppRes, partsRes]) =>  {
                    let supplierContactList = _.map(suppRes.data.active_contacts, (el) => {
                        return _.assign({
                            value: el.supp_cont_id,
                            label: el.supp_cont_first_name + ' ' + el.supp_cont_last_name
                        });
                    });
                    let partList = _.map(_.filter(partsRes.data, i=>i.part_status == 'Active' && !i.part_locked && i.default_cost.cost_supplier_id == this.state.formData.supplier ), (el) => {
                        return _.assign({
                            value: el.part_id,
                            label: el.part_number + ' - ' + el.part_description,
                        });
                    });
                    this.setState({
                        formData:{
                            ...this.state.formData,
                            supplierDetails: {
                                company: suppRes.data.supp_company_name,
                                address: suppRes.data.supp_address_line_one + "\n\r" +
                                        (suppRes.data.supp_address_line_two ? suppRes.data.supp_address_line_two + "\n\r":'') +
                                        (suppRes.data.supp_address_line_three ? suppRes.data.supp_address_line_three + "\n\r":'') +
                                        suppRes.data.supp_city + "\n\r" +
                                        (suppRes.data.county && suppRes.data.county.county_name ? suppRes.data.county.county_name + "\n\r":'') +
                                        (suppRes.data.country && suppRes.data.country.country_name ? suppRes.data.country.country_name + "\n\r":'') +
                                        suppRes.data.supp_postcode,
                                paymentTerms: suppRes.data.supp_payment_terms
                            },
                            partRows: initialState.formData.partRows,
                            fao: initialState.formData.fao,
                            faoEmail: initialState.formData.faoEmail
                        },
                        supplierContactList: supplierContactList,
                        supplierContacts: suppRes.data.active_contacts,
                        partList: partList,
                        showForm: true
                    });
                });
            }
            if(fieldName === 'emailTemplate'){
                let email = _.find(this.state.templates, {'ppo_email_id': selectedOption && selectedOption.value });

                this.setState({
                    formData:{
                        ...this.state.formData,
                        emailText: (typeof email === 'undefined') ? '' : email.ppo_email_text
                    }
                });
            }
            if(fieldName === 'fao'){
                let contact = _.find(this.state.supplierContacts, {'supp_cont_id': selectedOption && selectedOption.value });

                this.setState({
                    formData:{
                        ...this.state.formData,
                        faoEmail: (typeof contact === 'undefined') ? '' : contact.supp_cont_email
                    }
                });
            }
            if(fieldName === 'deliveryTo'){
                this.setState({
                    showDeliveryToDefault: this.state.formData.deliveryTo === 'default' ? true : false,
                    showDeliveryToCustomer: this.state.formData.deliveryTo === 'customer' ? true : false,
                    showDeliveryToSupplier: this.state.formData.deliveryTo === 'supplier' ? true : false,
                });
            }
            if(fieldName === 'deliverySupplierAddress' && this.state.formData.deliverySupplierAddress > 0){
                let supplier = _.find(this.state.suppliers, {'supp_id': selectedOption && selectedOption.value });
                let deliverySupplierContactList = _.map(supplier.active_contacts, (el) => {
                    return _.assign({
                        value: el.supp_cont_id,
                        label: el.supp_cont_first_name + ' ' + el.supp_cont_last_name + ' (' + el.supp_cont_type + ')'
                    });
                });
                this.setState({
                    lists:{
                        ...this.state.lists,
                        deliverySupplierContactList: deliverySupplierContactList,
                    },
                    formData:{
                        ...this.state.formData,
                        deliverySupplierFao: ''
                    }
                });
            }
            if(fieldName === 'deliveryCustomer' && this.state.formData.deliveryCustomer > 0){
                let cust = _.find(this.state.customers, {'cust_id': selectedOption && selectedOption.value });
                let customerAddressTypeList = _.map(cust.address_types, (el) => {
                    return _.assign({
                        value: el.address_type,
                        label: el.address_type
                    });
                });
                this.setState({
                    lists: {
                        ...this.state.lists,
                        customerAddressTypeList: customerAddressTypeList,
                    },
                    customerAddresses: cust.addresses,
                    formData:{
                        ...this.state.formData,
                        deliveryCustomerAddressType: '',
                        deliveryCustomerAddress: '',
                        deliveryCustomerFao: ''
                    }
                });
            }
            if(fieldName === 'deliveryCustomerAddressType' && this.state.formData.deliveryCustomerAddressType){
                let addresses = _.filter(this.state.customerAddresses, { 'address_type': selectedOption && selectedOption.value });

                let customerAddressList = _.map(addresses, (el) => {
                    return _.assign({
                        value: el.address_id,
                        label: `${el.address_name} - ${el.address_line_one}, ${el.address_town}, ${el.address_postcode}`
                    });
                });
                this.setState({
                    lists: {
                        ...this.state.lists,
                       customerAddressList: customerAddressList, 
                    },
                    formData:{
                        ...this.state.formData,
                        deliveryCustomerAddress: '',
                        deliveryCustomerFao: ''
                    }
                });
            }
            if(fieldName === 'deliveryCustomerAddress' && this.state.formData.deliveryCustomerAddress){
                let address = _.find(this.state.customerAddresses, { 'address_id': selectedOption && selectedOption.value });

                let customerContactList = _.map(address.active_contacts, (el) => {
                    return _.assign({
                        value: el.contact_id,
                        label: el.contact_name
                    });
                });
                this.setState({
                    lists: {
                        ...this.state.lists,
                        customerContactList: customerContactList,
                    },
                    formData:{
                        ...this.state.formData,
                        deliveryCustomerFao: ''
                    }
                });
            }
        });
    }

    handleAddRow = fieldName => t => {
        const item = initialState.formData[fieldName][0];
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: [...this.state.formData[fieldName], item]
            }
        });
    }

    handleRowChange = (idx, array, decimals) => e => {
        const { name, value, checked } = e.target;
        let newQty =  [...this.state.formData[array]];
        let newVal = ((name === 'attachPO') ? checked : ((decimals && !isNaN(value)) ? parseFloat(value).toFixed(decimals) : value));

        newQty[idx] = {
            ...newQty[idx],
            [name]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                [array]: newQty 
            }
        },
        () => {
            this.calculatePrice(idx, array, name);
            if(name === 'deliveryType' && (value === 'delivery_tbc' || value === 'delivery_date' || value === 'delivery_schedule')){
                this.generateDeliverySchedule(idx);
            }
        });
    };

    handleRowDateChange = (idx, fieldName) => date => {
        let newData =  [...this.state.formData.partRows];

        newData[idx] = {
            ...newData[idx],
            [fieldName]: date
        };
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: newData 
            }
        },
        () => {
            if(fieldName === 'deliveryDate'){
                this.generateDeliverySchedule(idx);
            }
        });
    };

    handleSelectRowChange = (idx, fieldName, array) => selectedOption => {

        let newPart = (fieldName === 'part' && _.find(this.state.formData.partRows, {'part': selectedOption && selectedOption.value })) || undefined;

        if(newPart === undefined){ // Check it's not a duplicate part
            let data =  [...this.state.formData[array]];
            let newVal = selectedOption && selectedOption.value;

            data[idx] = {
                ...data[idx],
                [fieldName]: newVal
            };
            this.setState({
                formData: {
                    ...this.state.formData,
                    [array]: data
                }
            }, 
            () => {
                if(fieldName === 'part'){
                    let partId = selectedOption && selectedOption.value;
                    if(partId){
                        API.get(`/parts/${partId}`)
                        Promise.all([ 
                            API.get(`/parts/${partId}`), 
                            API.get(`/researchDevelopment/changeOrders/part/${partId}/actualPdfIssue`),
                        ])
                        .then(([partsRes, pdfIssue]) =>  {
                            if(partsRes.data && partsRes.data.default_cost){
                                var nominalList = []
                                if(partsRes.data.nominal_codes && partsRes.data.nominal_codes.length > 0){
                                    nominalList = _.map(partsRes.data.nominal_codes, (el) => {
                                        return _.assign({
                                            value: el.nominal_id,
                                            label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                                        });
                                    });
                                }

                                data[idx] = {
                                    ...data[idx],
                                    unitPrice: partsRes.data.default_cost.cost_per_unit,
                                    nominalCode: partsRes.data.nominal_code && partsRes.data.nominal_code.nominal_id,
                                    supplierReference: partsRes.data.default_cost.cost_reference,
                                    quantity: partsRes.data.default_cost.cost_qty,
                                    drawingNo: partsRes.data.part_issue_status_pdf === 0 ? 'N/A' : 'v'+partsRes.data.part_issue_status_pdf,
                                    disableAttachDrawing: partsRes.data.part_issue_status_pdf === 0 ? true : false,
                                    attachPO: partsRes.data.part_issue_status_pdf === 0 ? false : true,
                                    drawingPdfName: partsRes.data.part_number+'_v'+partsRes.data.part_issue_status_pdf+'.pdf',
                                    drawingUrl: partsRes.data.pdf_drawing.full_url,
                                    issueStatusPDF: partsRes.data.part_issue_status_pdf,
                                    partNumber: partsRes.data.part_number,
                                    description: partsRes.data.part_description,
                                    // Clear Schedule
                                    startingFrom: null,
                                    duration: '',
                                    deliveryTable: '',
                                    deliveryDate: null,
                                    frequency: '',
                                    deliverySchedule: '',
                                    deliveryType: '',
                                    nominalList: nominalList
                                };
                                this.setState({
                                    formData: {
                                        ...this.state.formData,
                                        [array]: data
                                    },
                                    formErrors: {
                                        ...this.state.formErrors,
                                        [`partRows|drawingNo|${idx}`]: ((partsRes.data.part_issue_status_pdf === pdfIssue.data) || (partsRes.data.part_issue_status_pdf === 0 && pdfIssue.data === null)) ? '' : 'Outdated PDF'
                                    },
                                },
                                () => {
                                    this.calculatePrice(idx, array, fieldName);
                                });
                            }
                        });
                    }
                }
                if(fieldName === 'frequency'){
                    let frequencyText = '';
                    switch (newVal){
                        case 'Weekly':
                            frequencyText = 'Week(s)';
                        break;
                        case 'Monthly':
                            frequencyText = 'Month(s)';
                        break;
                        case 'Bi-Monthly':
                            frequencyText = 'Bi-Month(s)';
                        break;
                        case 'Quarterly':
                            frequencyText = 'Quarter(s)';
                        break;
                        default:
                            frequencyText = '';
                        break;
                    }
                    data[idx] = {
                        ...data[idx],
                        durationPlaceholder: frequencyText
                    };
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            [array]: data
                        }
                    });
                }
                if(fieldName === 'description'){
                    let otherItem = _.find(this.state.defaultItems, {'ppo_list_description': selectedOption && selectedOption.value });
                    let data =  [...this.state.formData[array]];

                    var nominalList = []
                    if(otherItem && otherItem.nominal_codes.length > 0){
                        nominalList = _.map(otherItem.nominal_codes, (el) => {
                            return _.assign({
                                value: el.nominal_id,
                                label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                            });
                        });
                    }

                    data[idx] = {
                        ...data[idx],
                        nominalCode: otherItem && otherItem.nominal_code && otherItem.nominal_code.nominal_id,
                        nominalList: nominalList
                    };
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            [array]: data
                        }
                    });
                }
            });
        }
        else {
            this.setState({ 
                snackbarError: {
                    snackbarErrorOpen: true,
                    snackbarErrorMessage: 'You have already selected this part' 
                }
            });
        }
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleRemoveRow = (idx, array) => () => {
        let rows = [...this.state.formData[array]]
        rows.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                [array]: rows
            }
        },
        () => {
            this.calculatePrice(idx, array);
        })
    }

    calculatePrice = (idx, array, fieldName) => {
        let data =  [...this.state.formData[array]];
        if(data[idx]){
            data[idx] = {
                ...data[idx],
                totalPrice: data[idx] ? (data[idx].unitPrice * data[idx].quantity).toFixed(2) : (0).toFixed(2)
            };
            // Set new total price before setting overall price
            this.setState({
                formData: {
                    ...this.state.formData,
                    [array]: data
                }
            }, 
            () => {
                this.calculateOverallTotals(idx, array, fieldName);
            });
        }
        else {
            // Remove row - just calculate overall totals
            this.calculateOverallTotals(idx, array, fieldName);
        }
    }

    calculateOverallTotals = (idx, array, fieldName = '') => {
        let overallPrice = 0;
        this.state.formData.partRows.forEach((row) => {
            if(!isNaN((row.totalPrice*1))){
                overallPrice = overallPrice + (row.totalPrice*1);
            }
        });
        this.state.formData.otherRows.forEach((row) => {
            if(!isNaN((row.totalPrice*1))){
                overallPrice = overallPrice + (row.totalPrice*1);
            }
        });
        this.setState({
            formData: {
                ...this.state.formData,
                overallPrice: overallPrice.toFixed(2)
            }
        },
        () => {
            if(fieldName === 'quantity' && array === 'partRows'){
                this.clearDeliverySchedule(idx, 'clear');
            }
        });
    }
    // Delivery Schedule Dialog
    handleScheduleOpen = (idx) => (e) => {
        if(this.state.formData.partRows[idx].part > 0 && this.state.formData.partRows[idx].quantity){
            let data =  [...this.state.formData.partRows];
            data[idx] = {
                ...data[idx],
                dialogOpen: true
            };
            this.setState({
                formData: {
                    ...this.state.formData,
                    partRows: data
                }
            });
        }
        else {
            this.setState({ 
                snackbarError: {
                    snackbarErrorOpen: true,
                    snackbarErrorMessage: 'A part needs to be selected on each row to schedule a delivery' 
                }
            });
        }
    };
    handleScheduleClose = (idx) => (e) => {
        let data =  [...this.state.formData.partRows];
        data[idx] = {
            ...data[idx],
            dialogOpen: false
        };
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: data
            }
        },
        () => {
            this.clearDeliverySchedule(idx, 'clear');
        });
    };
    handleScheduleSave = (idx) => (e) => {
        let data =  [...this.state.formData.partRows];
        data[idx] = {
            ...data[idx],
            dialogOpen: false
        };
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: data
            }
        },
        () => {
            this.changeScheduleButtonColor(idx, 'partRows');
        });
    };
    // Notes Dialog
    handleNotesOpen = (idx) => (e) => {
        let data =  [...this.state.formData.partRows];
        data[idx] = {
            ...data[idx],
            notesDialogOpen: true
        };
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: data
            }
        });
        
    };
    handleNotesClose = (idx) => (e) => {
        let data =  [...this.state.formData.partRows];
        data[idx] = {
            ...data[idx],
            notesDialogOpen: false
        };
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: data
            }
        });
    };
    handleNotesSave = (idx) => (e) => {
        let data =  [...this.state.formData.partRows];
        data[idx] = {
            ...data[idx],
            notesDialogOpen: false
        };
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: data
            }
        },
        () => {
            this.changeNotesButtonColor(idx, 'partRows');
        });
    };
    // Confirmation Dialog
    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
            formData: {
                ...this.state.formData,
                previewPPO: false,
                previewSchedule: false
            }
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false,
            formData: {
                ...this.state.formData,
                previewPPO: false,
                previewSchedule: false
            }
        });
        this.submit();
    }
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handlePreviewPPO = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                previewPPO: true,
                previewSchedule: false
            }
        },
        () => {
            this.submit();
        });
    }
    handlePreviewSchedule = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                previewPPO: false,
                previewSchedule: true
            }
        },
        () => {
            this.submit();
        });
    }

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            let newData =  [...this.state.formData.partRows];
            newData.forEach((el) => {
                el.deliveryDate = el.deliveryDate && moment(new Date(el.deliveryDate)).format('DD/MM/YYYY')
                el.startingFrom = el.startingFrom && moment(new Date(el.startingFrom)).format('DD/MM/YYYY')
            });

            const formData = {
                ...this.state.formData,
                purchaseOrderDate: (moment(new Date(this.state.formData.purchaseOrderDate)).format('DD/MM/YYYY')),
                orderDate: this.state.formData.orderDate && (moment(new Date(this.state.formData.orderDate)).format('DD/MM/YYYY')),
                partRows: newData
            }

            API.post('/partsOrdering', formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    if(result.data.pdf){
                        this.setState({
                            isLoading: false
                        },
                        () => {
                            pdfFromBase64(result.data.pdf, result.data.pdfName);
                        });
                    }
                    else {
                        this.setState({
                            ...initialState,
                            snackbarOpen: true
                        }, () => {
                            this.populateDropdowns();
                        });
                    }
                }
                this.props.scrollToTop();
            });
        });
    }

    handleDateChange = fieldName => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: date
            }
        });
    };

    generateDeliverySchedule = (idx) => {
        let delivery_type = this.state.formData.partRows[idx].deliveryType,
		    total_part_quantity = this.state.formData.partRows[idx].quantity,
            total_part_price = this.state.formData.partRows[idx].totalPrice,
            i = 1,
            schedule = {},
            deliveryTable = '',
            validSchedule = false;

        switch (delivery_type) {
            case 'delivery_date':
                let schedule_drop_date = this.state.formData.partRows[idx].deliveryDate ? moment(this.state.formData.partRows[idx].deliveryDate).format('DD/MM/YYYY') : this.state.formData.partRows[idx].deliveryDate;
                schedule['delivery_dates'] 			= {};
                schedule['delivery_dates'][i] 		= {'delivery_date' : schedule_drop_date, 'qty': total_part_quantity, 'price' : total_part_price};
                schedule['delivery_type'] 			= 'Delivery Date';
                schedule['total_delivery_qty'] 		= total_part_quantity;
                schedule['total_delivery_price'] 	= total_part_price;
                if (schedule_drop_date) {
                    validSchedule = true;
                }
            break;
            case 'delivery_schedule':
                let drop_date		 	= moment(this.state.formData.partRows[idx].startingFrom).format('DD/MM/YYYY');
                let duration_number 	= this.state.formData.partRows[idx].duration;
                let delivery_frequency 	= this.state.formData.partRows[idx].frequency;
                let part_unit_price 	= this.state.formData.partRows[idx].unitPrice;
                
                if(!delivery_frequency || !duration_number || !drop_date){
                } else {
                    let qty_per_delivery 	= (total_part_quantity/duration_number);
                    let price_per_delivery 	= parseFloat(qty_per_delivery * part_unit_price);
                    let moment_duration, moment_number = 1;

                    // Is qty_per_delivery a whole number
                    let validity_msg = '';
                    if(qty_per_delivery % 1 !== 0){
                        validity_msg = <small style={{color:colors.red}}>Invalid Schedule</small>;
                        validSchedule = false; 

                    } else {
                        validity_msg = <small style={{color:colors.green}}>Valid Schedule</small>;
                        validSchedule = true; 
                    }
                    switch (delivery_frequency){
                        case 'Weekly':
                            moment_duration = 'weeks';
                        break;
                        case 'Monthly':
                            moment_duration = 'months';
                        break;
                        case 'Bi-Monthly':
                            moment_duration = 'months';
                            moment_number = 2;
                        break;
                        case 'Quarterly':
                            moment_duration = 'quarters';
                        break;
                        default:
                        break;
                    }
                    let schedule_drop_date = drop_date;
                    // If the delivery date lays on a Saturday or Sunday
                    if (moment(drop_date,"DD/MM/YYYY").isoWeekday() === 6) { // Saturday, set to monday
                        drop_date = moment(drop_date,"DD/MM/YYYY").add(2, 'days').format("DD/MM/YYYY");
                    } else if(moment(drop_date,"DD/MM/YYYY").isoWeekday() === 7) { // Sunday, set to monday
                        drop_date = moment(drop_date,"DD/MM/YYYY").add(1, 'days').format("DD/MM/YYYY");
                    }

                    schedule['delivery_dates'] = {};
                    schedule['delivery_dates'][i] = {'delivery_date' : drop_date, 'qty': qty_per_delivery, 'price' : price_per_delivery};
                    for (i = 2; i <= duration_number; i++) {
                        let new_schedule_drop_date = moment(schedule_drop_date, "DD/MM/YYYY").add(moment_number, moment_duration).format("DD/MM/YYYY");

                        let abc = new_schedule_drop_date;
                        // If the delivery date lays on a Saturday or Sunday
                        if (moment(new_schedule_drop_date,"DD/MM/YYYY").isoWeekday() === 6) { // Saturday, set to monday
                            abc = moment(new_schedule_drop_date,"DD/MM/YYYY").add(2, 'days').format("DD/MM/YYYY");
                        } else if(moment(new_schedule_drop_date,"DD/MM/YYYY").isoWeekday() === 7) { // Sunday, set to monday
                            abc = moment(new_schedule_drop_date,"DD/MM/YYYY").add(1, 'days').format("DD/MM/YYYY");
                        }
                        schedule['delivery_dates'][i] = {'delivery_date' : abc, 'qty': qty_per_delivery, 'price' : price_per_delivery};
                        schedule_drop_date = new_schedule_drop_date;
                    }
                    
                    let total_delivery_qty = 0, total_delivery_price = 0;
                    deliveryTable = <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Qty</TableCell>
                                                <TableCell>Price</TableCell>
                                                <TableCell>Delivery Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {Object.keys(schedule['delivery_dates']).map((property, idx) => 
                                            <TableRow key={idx}>
                                                <TableCell>{schedule['delivery_dates'][property].qty.toFixed(2)}</TableCell>
                                                <TableCell>£{schedule['delivery_dates'][property].price.toFixed(2)}</TableCell>
                                                <TableCell>{schedule['delivery_dates'][property].delivery_date}</TableCell>
                                            </TableRow>
                                        )}
                                        {Object.keys(schedule['delivery_dates']).forEach(property => {
                                            total_delivery_qty += parseFloat(schedule['delivery_dates'][property].qty);
                                            total_delivery_price += parseFloat(schedule['delivery_dates'][property].price);
                                        })}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell>{parseFloat(total_delivery_qty).toFixed(2)}</TableCell>
                                                <TableCell>{parseFloat(total_delivery_price).toFixed(2)}</TableCell>
                                                <TableCell>{validity_msg}</TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>;
                    
                    schedule['delivery_type'] 			= 'Schedule';
                    schedule['total_delivery_qty'] 		= parseFloat(total_delivery_qty).toFixed(2);
                    schedule['total_delivery_price'] 	= parseFloat(total_delivery_price).toFixed(2);
                }
            break;
            case 'delivery_tbc':
                schedule['delivery_dates'] 			= {};
                schedule['delivery_dates'][i] 		= {'delivery_date' : '0000-00-00', 'qty': total_part_quantity, 'price' : total_part_price};
                schedule['delivery_type'] 			= 'Supplier to Confirm';
                schedule['total_delivery_qty'] 		= total_part_quantity;
                schedule['total_delivery_price'] 	= total_part_price;
                validSchedule = true;
            break;
            default:
            break;
        }
        if(schedule){            
            let newData =  [...this.state.formData.partRows];
            newData[idx] = {
                ...newData[idx],
                deliverySchedule: JSON.stringify(schedule),
                deliveryTable: deliveryTable,
                validSchedule: validSchedule
            };
            this.setState({
                formData: {
                    ...this.state.formData,
                    partRows: newData
                }
            });
        }
    }

    clearDeliverySchedule = (idx, clearDeliveryType) => {
        let newData =  [...this.state.formData.partRows];
        newData[idx] = {
            ...newData[idx],
            startingFrom: null,
            duration: '',
            deliveryTable: '',
            deliveryDate: null,
            frequency: '',
            deliverySchedule: '',
            deliveryType: clearDeliveryType ? '' : this.state.formData.partRows[idx].deliveryType,
        };
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: newData
            }
        },
        () => {
            this.changeScheduleButtonColor(idx, 'partRows');
        });
    }

    changeScheduleButtonColor = (idx, array) => {
        if(array === 'partRows'){
            let newData =  [...this.state.formData.partRows];
            if(newData[idx]){
                newData[idx] = {
                    ...newData[idx],
                    scheduleButtonColor: (newData[idx].deliverySchedule === '') ? '' : colors.green
                };
                this.setState({
                    formData: {
                        ...this.state.formData,
                        partRows: newData
                    }
                });
            }
        }
    }

    changeNotesButtonColor = (idx, array) => {
        if(array === 'partRows'){
            let newData =  [...this.state.formData.partRows];
            if(newData[idx]){
                newData[idx] = {
                    ...newData[idx],
                    notesButtonColor: (newData[idx].notes === '') ? '' : colors.green
                };
                this.setState({
                    formData: {
                        ...this.state.formData,
                        partRows: newData
                    }
                });
            }
        }
    }

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    }

    handleSnackbarErrorClose = () => {
        this.setState({
            snackbarError: {
                snackbarErrorOpen: false
            }
        });
    }
       
    render() {
        const { formErrors, isLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Parts Order
                    </Typography>
                </Grid>                
                {(isLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                )) || (
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.supplierWithCostsList} 
                                            label='Supplier *'
                                            value={this.state.formData.supplier}
                                            onChange={this.handleSelectChange('supplier')}
                                            error={formErrors && formErrors['supplier'] && true}
                                            errorText={formErrors && formErrors['supplier']}
                                        />
                                    </FormControl>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
                {this.state.showForm &&
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PartOrderDetails 
                                    supplierContactList={this.state.supplierContactList}
                                    fao={this.state.formData.fao}
                                    faoEmail={this.state.formData.faoEmail}
                                    purchaseOrderDate={this.state.formData.purchaseOrderDate}
                                    orderDate={this.state.formData.orderDate}
                                    formErrors={formErrors}
                                    handleSelectChange={this.handleSelectChange}
                                    handleChange={this.handleChange}
                                    handleDateChange={this.handleDateChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SupplierDetails supplierDetails={this.state.formData.supplierDetails} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <PartItems
                                    partRows={this.state.formData.partRows}
                                    partList={this.state.partList}
                                    frequency={this.state.formData.frequency}
                                    handleScheduleSave={this.handleScheduleSave}
                                    handleScheduleClose={this.handleScheduleClose}
                                    handleScheduleOpen={this.handleScheduleOpen}
                                    generateDeliverySchedule={this.generateDeliverySchedule}
                                    handleRowDateChange={this.handleRowDateChange}
                                    handleRemoveRow={this.handleRemoveRow}
                                    handleAddRow={this.handleAddRow}
                                    handleSelectRowChange={this.handleSelectRowChange}
                                    handleRowChange={this.handleRowChange}
                                    formErrors={formErrors}
                                    handleNotesSave={this.handleNotesSave}
                                    handleNotesClose={this.handleNotesClose}
                                    handleNotesOpen={this.handleNotesOpen}
                                />

                                <OtherItems
                                    otherRows={this.state.formData.otherRows}
                                    defaultItemsList={this.state.defaultItemsList}
                                    handleRemoveRow={this.handleRemoveRow}
                                    handleAddRow={this.handleAddRow}
                                    handleSelectRowChange={this.handleSelectRowChange}
                                    handleRowChange={this.handleRowChange}
                                    formErrors={formErrors}
                                />
                                <Typography variant="h6" style={{display: 'flex', justifyContent:'flex-end', fontWeight: 'bold' }}>
                                    Total Price: £{this.state.formData.overallPrice}
                                </Typography>
                            </PaddedPaper>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <InvoiceDetails
                                    handleSelectChange={this.handleSelectChange}
                                    formErrors={formErrors}
                                    lists={this.state.lists} 
                                    staffList={this.state.staffList}
                                    invoiceFao={this.state.formData.invoiceFao}
                                    invoiceAddress={this.state.formData.invoiceAddress}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <DeliveryDetails 
                                    lists={this.state.lists} 
                                    staffList={this.state.staffList}
                                    deliverySupplierFao={this.state.formData.invoicedeliverySupplierFaoAddress}
                                    deliverySupplierAddress={this.state.formData.invoiceAddeliverySupplierAddressdress}
                                    deliveryCustomerFao={this.state.formData.deliveryCustomerFao}
                                    deliveryCustomerAddress={this.state.formData.deliveryCustomerAddress}
                                    deliveryCustomerAddressType={this.state.formData.deliveryCustomerAddressType}
                                    deliveryCustomer={this.state.formData.deliveryCustomer}
                                    deliveryDefaultFao={this.state.formData.deliveryDefaultFao}
                                    deliveryDefaultAddress={this.state.formData.deliveryDefaultAddress}
                                    deliveryTo={this.state.formData.deliveryTo}
                                    showDeliveryToCustomer={this.state.showDeliveryToCustomer}
                                    showDeliveryToDefault={this.state.showDeliveryToDefault}
                                    showDeliveryToSupplier={this.state.showDeliveryToSupplier}
                                    handleSelectChange={this.handleSelectChange}
                                    formErrors={formErrors}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <EmailDetails 
                                        templateList={this.state.templateList} 
                                        staffList={this.state.staffList} 
                                        formErrors={formErrors} 
                                        handleChange={this.handleChange} 
                                        handleSelectChange={this.handleSelectChange} 
                                        emailTemplate={this.state.emailTemplate} 
                                        emailText={this.state.formData.emailText} 
                                        approvedRequiredBy={this.state.formData.approvedRequiredBy}
                                    />
                                    <div className='buttonRow'>
                                        <Button onClick={this.handlePreviewPPO}
                                                variant="outlined"
                                                color="default"
                                               >
                                            Preview Purchase Order
                                        </Button>
                                        <Button onClick={this.handlePreviewSchedule}
                                                variant="outlined"
                                                color="default"
                                               >
                                            Preview Delivery Schedule
                                        </Button>
                                        <Button onClick={this.handleConfirmationOpen}
                                                variant="contained"
                                                color="primary"
                                                disabled={this.state.isLoading}
                                               >
                                            Add
                                        </Button>
                                    </div>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Add This Parts Order?" 
                    message="Are you sure you want to add this new parts order?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added a new parts purchase order'
                />
                <SnackBar
                    variant="error"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarError.snackbarErrorOpen}
                    onClose={this.handleSnackbarErrorClose}
                    message={this.state.snackbarError.snackbarErrorMessage}
                />
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, variant, size, style) => {
            dispatch(deployDialog(content, title, variant, size, style))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        },
    }
}

export default connect(null, mapDispatchToProps)(AddPartsOrder);;
