import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {colors} from 'Helpers/ColourHelper';

const styles = theme => ({
    header: {
        fontWeight: 'bold',
    },
    buttons: {
        color: colors.blue,
    },
    content : {
        paddingBottom: theme.spacing(2)
    },
    dialogTitleSuccess: {
        backgroundColor: colors.green,
        color: 'white',
    },
    dialogTitleWarning: {
        backgroundColor: colors.red,
        color: 'white',
    },
    dialogTitleAmber: {
        backgroundColor: colors.yellow,
        color: 'white',
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ConfirmationDialog extends React.Component {

    generateDialogTitleClassName = () => {
        let className = '';
        const { classes } = this.props;
        
        switch(this.props.variant) {
            case 'success': className = classes.dialogTitleSuccess; break;
            case 'warning': className = classes.dialogTitleWarning; break;
            case 'amber':   className = classes.dialogTitleAmber;   break;
            default:                                                break;
        }

        return className;
    }

  render() {
    return (
      <React.Fragment>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.props.open}
                TransitionComponent={Transition}
                onClose={this.props.close}
                scroll="body"
            >
                <DialogTitle  className={`header ${this.generateDialogTitleClassName()}`}>{this.props.title || ''}</DialogTitle>
                <DialogContent>
                    <DialogContentText component="div">
                        {this.props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.close} variant="outlined" color="default">{this.props.variant === 'YesNo' ? 'No' : 'Cancel'} {!!this.props.isPda && '[F1]'}</Button>
                    <Button onClick={this.props.success} autoFocus variant="contained" color="primary" className={`${this.generateDialogTitleClassName()}`}>Yes {!!this.props.isPda && '[F2]'}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
  }
}

export default withStyles(styles)(ConfirmationDialog);