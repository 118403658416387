import React from 'react';

import loading from 'Assets/Images/loadingCircle.gif';

import { keys } from 'lodash';

class LoadingCircle extends React.Component {
    render() {

        const sizes = {
            'small': 40,
            'large': 80,
            'xlarge': 100
        }

        let size = keys(sizes).includes(this.props.size) ? sizes[this.props.size] : (this.props.size || 75);

        return (
            <div 
                style={{
                    ...this?.props?.style,
                    textAlign: 'center', 
                    padding: (this.props.padding && (this.props.padding === 'large' ? 48 : (this.props.padding === 'xs' ? 6 : 12))) || 24,
                    width: '100%',
                }}
            >
                <img src={loading} alt="Loading..." style={{maxWidth: size, maxHeight: size, width: size, height: size, marginBottom: (this.props.noMargin ? 0 : 10)}} /><br/>
                {this.props.message || 'Just Washing...'}
            </div>
        )
    }
}

export default LoadingCircle;