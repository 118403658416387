import React from 'react';
import _ from 'lodash';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import API from "API";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deployConfirmation } from "Actions/Confirmation/Confirmation";
import { closeDialog } from "Actions/Dialog/Dialog";
import { deploySnackBar } from "Actions/SnackBar/SnackBar";
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';

const WildixCallAssignment = ({ callback, id }) => {
    const dispatch = useDispatch(),
        [call, setCall] = useState(null),
        [addresses, setAddresses] = useState([]),
        [contacts, setContacts] = useState([]),
        [contact, setContact] = useState(""),
        [customers, setCustomers] = useState([]),
        [isLoading, setIsLoading] = useState(true),
        [options, setOptions] = useState([]),
        [suppliers, setSuppliers] = useState([]),
        [type, setType] = useState(null),
        [value, setValue] = useState(null),
        [updateAssignment, setUpdateAssignment] = useState(id);

    const handleSubmit = () => {
        setIsLoading(true);
        API.put(`/voip/${id}/assign`, { type, value, contact, updateAssignment })
        .then((res) => {
            if (res?.data?.success) {
                if(callback) {
                    callback();
                }
                dispatch(deploySnackBar("success", "Call assignment updated successfully"));
                dispatch(closeDialog());
            }
        });
    };

    useEffect(() => {
        if (call) {
            setIsLoading(false);
        }
    }, [call]);

    useEffect(() => {
        if(!call || !type) return;
        if(value !== null) {
            setContact('');
            setValue('');
        } else {
            setValue(type === 'site' ? call.ai : type === 'customer' ? call.ci : type === 'supplier' ? call.si : '');
        }

        let newOptions;
        const accountStatus = ['','Active - Verified','On Hold','Closed','Active - Not Verified']

        if(type === 'supplier') {
            newOptions = _.map(suppliers, (s) => ({ label: s.supp_trading_name || s.supp_company_name, value: s.supp_id }));
        } else if(type === 'customer') {
            newOptions = _.map(customers, el => {
                el.registeredPostcode = _.result(_.find(el?.addresses, {'address_type': 'Registered'}), 'address_postcode');
                return _.assign({
                    value: el.cust_id,
                    label: el.cust_name + ' | ' + el.cust_type + ' ('+el?.registeredPostcode+') | '+ accountStatus[el.account_status.cas_id] + ' | ' + 'CS' + el.cust_id
                });
            });
        } else if(type === 'site') {
            newOptions = _.map(addresses, el => {
                return _.assign({
                    value: el.address_id,
                    label: el.address_name + ' | ' + el.address_type + ' | ' + el.address_postcode + ' | ' + accountStatus[el.cust_account_status_id] + ' | ' + 'CS' + el.cust_id
                });
            });
        } else {
            newOptions = [];
        }

        setOptions(newOptions);

    }, [call, type]) /* eslint-disable-line */

    useEffect(() => {
        
        let newContacts = [];
        if(type === 'site') {
            let site = _.find(addresses, {'address_id': value});
            if(site?.active_contacts) {
                newContacts = _.map(site.active_contacts, c => {
                    return {
                        label: c.contact_name,
                        value: c.contact_id
                    }
                })
            }
        } else if(type === 'customer') {
            let cust = _.find(customers, {'cust_id': value});
            if(cust?.active_contacts) {
                newContacts = _.map(cust.active_contacts, c => {
                    return {
                        label: c.contact_name,
                        value: c.contact_id
                    }
                })
            }
        } else if(type === 'supplier') {
            let supp = _.find(suppliers, {'supp_id': value});
            if(supp?.active_contacts) {
                newContacts = _.map(supp.active_contacts, c => {
                    return {
                        label: `${c.supp_cont_first_name} ${c.supp_cont_last_name}`,
                        value: c.supp_cont_id
                    }
                })
            }
        }

        setContacts(newContacts);

    }, [type, value]) /* eslint-disable-line */

    useEffect(() => {
        Promise.all([
            API.get(`/voip/${id}`),
            API.get(`/customers`),
            API.get(`/suppliers/all`)
        ])
        .then(([res, cust, supp]) => {
            if (res?.data) {
                let newAddresses = [];
                if(cust?.data) {
                    _.forEach(cust.data, c => {
                        _.forEach(_.filter(c.addresses, e => e.address_type === 'Site'), a => {
                            newAddresses.push({
                                ...a,
                                account_status: c.account_status,
                                cust_id: c.cust_id,
                                cust_account_status_id: c.account_status.cas_id
                            })
                        })
                    })
                }
                setCall(res?.data);
                setContact(res?.data?.cc > 0 ? res?.data?.cc : '')
                setAddresses(newAddresses)
                setCustomers(cust?.data);
                setSuppliers(supp?.data);
                setType(res?.data?.ai ? 'site' : res?.data?.ci ? 'customer' : res?.data?.si ? 'supplier' : '');
            }
        });
    }, []); /* eslint-disable-line */

    const incoming = call?.ic === 1,
        answered = call?.st === 1;

    return (
        (isLoading && <LoadingCircle />) || (
            <Box>
                <Grid container>
                    {call && (
                        <Grid item xs={12} className="content-light-white pl-2">
                            <List dense>
                                <ListItem disableGutters>
                                    {!incoming ? (
                                        <ListItemAvatar>
                                            <FAIcon className={answered ? "" : "textError"} icon={`phone-arrow-up-right`} type="light" />
                                        </ListItemAvatar>
                                    ) : null}
                                    <ListItemText
                                        primary={
                                            <>
                                                <Typography className={`${answered ? "" : "textError"} fw-400`} variant="body1">
                                                    {call.nm}
                                                </Typography>
                                                {call.cn && (
                                                    <Typography className={`${answered ? "" : "textError"} fw-400`} variant="body1">
                                                        {call.cn}
                                                    </Typography>
                                                )}
                                            </>
                                        }
                                        secondary={
                                            <Typography className={answered ? "" : "textError"} variant="body1" color="textSecondary">
                                                {call.nu}
                                            </Typography>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Typography variant="body2" component="div" align="right">
                                                    {call.ti}
                                                </Typography>
                                                <Typography variant="body2" component="div" align="right">
                                                    {call.dt}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={1} className="pt-3 pb-3">
                    <Grid item xs={12}>
                        <FormControl>
                            <Grid container spacing={1} style={{alignItems: 'center'}}>
                                <Grid item>
                                    <Checkbox
                                        checked={updateAssignment === 'new'}
                                        color="primary"
                                        onChange={()=>{
                                            setUpdateAssignment(updateAssignment === 'new' ? id : 'new')
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1">Create Secondary Assignment</Typography>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            value={type}
                            onChange={(e) => setType(e?.target?.value ?? "")}
                            row
                        >
                            <FormControlLabel
                                value="customer"
                                control={<Radio color="primary" checked={type === 'customer'} />}
                                label="Customer Name"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="site"
                                control={<Radio color="primary" checked={type === 'site'} />}
                                label="Customer Site"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="supplier"
                                control={<Radio color="primary" checked={type === 'supplier'} />}
                                label="Supplier Name"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <AutoCompleteSelect
                                disabled={type === ''}
                                label={type === 'customer' ? 'Customer Name *' : type === 'site' ? 'Site Name *' : type === 'supplier' ? 'Supplier Name *' : 'Please select a type above to enable options'}
                                onChange={(o) => setValue(o?.value ?? "")}
                                options={options}
                                value={value}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <AutoCompleteSelect
                                disabled={type === ''}
                                label={type === 'customer' ? 'Customer Contact' : type === 'site' ? 'Site Contact' : type === 'supplier' ? 'Supplier Contact' : 'Please select a type above to enable options'}
                                onChange={(o) => setContact(o?.value ?? "")}
                                options={contacts ?? []}
                                value={contact}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <div className="buttonRow">
                    <Button color="default" onClick={() => dispatch(closeDialog())} variant="outlined">
                        <FAIcon icon="times" button />
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="textLight"
                        disabled={type === '' || value === ''}
                        onClick={() => dispatch(deployConfirmation(`Are you sure you want to update assignment for this call?`, `Update Call Assignment`, handleSubmit))}
                        variant="contained"
                    >
                        <FAIcon icon="check" buttonPrimary />
                        Update Assignment
                    </Button>
                </div>
            </Box>
        )
    );
};

export default WildixCallAssignment;
