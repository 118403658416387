import React, { Component } from 'react';
import _                    from 'lodash';
import API                  from 'API';

import { Button, FormControl, Grid, InputAdornment, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@material-ui/core';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

const initialState = {
    showResults: false,
    calculatorResults: {},
    roundedResults: false,
    formErrors: [],
    formData:  {
        part: '',
        sparePartsPercentage: '',
        minLevel: '',
        maxLevel: '',
        calculatorPercentage: ''
    },
}

class MinMaxCalculator extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.state.formData.minLevel = this.props.min;
        this.state.formData.maxLevel = this.props.max;
    }

    calculate = () => {
        API.post(`/stock/${this.props.id}/calculator`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                let calculatorResults = {};
                let roundedResults = false;

                _.each(_.keys(result.data), i => {
                    if (i === 'direct_parts' || i === 'indirect_parts'){
                        calculatorResults[i] = result.data[i];
                    } else {
                        calculatorResults[i] = (Math.round(parseFloat(result.data[i])) === parseFloat(result.data[i])) ? parseFloat(result.data[i]) : Math.round(parseFloat(result.data[i]));
                        roundedResults = (Math.round(parseFloat(result.data[i])) === parseFloat(result.data[i])) ? roundedResults : true;
                    }
                });

                this.setState({
                    calculatorResults,
                    roundedResults,
                    showResults: true
                });
            }
        });
    }

    handleDecimalChange = decimals => e => {
        const { name, value } = e.target;
        let newVal = parseFloat(value).toFixed(decimals);
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: newVal
            }
        });
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        });
    }

    render() {
        const {showResults, calculatorResults, formErrors, roundedResults} = this.state;
        return (
            <PaddedPaper style={{backgroundColor:'#616161'}}>
                <PaddedPaper>
                    <Typography variant="h6" >
                        Stock Level Calculator
                    </Typography>
                    <Typography variant="subtitle2">
                        If the part is used in other parts then the calculator will automatically calculate the spare part figures.<br></br>
                        If the part is not used in other parts then it will just display the spare part figures based on the current min / max levels.
                    </Typography>
                    <Grid container spacing={3} style={{alignItems: 'center'}}>
                        <Grid item xs={12} lg={8}>
                            <FormControl margin="normal" fullWidth>
                                <TextField
                                    name="calculatorPercentage"
                                    label='Spare Parts Percentage'
                                    value={this.state.formData.calculatorPercentage}
                                    error={formErrors && formErrors['calculatorPercentage'] && true}
                                    helperText={(formErrors && formErrors['calculatorPercentage']) || 
                                        (roundedResults && 'The figures below have been rounded up to create whole stock')
                                    }
                                    onChange={this.handleChange}
                                    onBlur={this.handleDecimalChange(0)}
                                    type="number"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment  position="end">
                                            %
                                            </InputAdornment>
                                        ),
                                    }}
                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                    inputProps={{ min: 0 }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={4}><br></br>
                            <Button onClick={this.calculate}
                                    variant="outlined">
                                Calculate
                            </Button>
                        </Grid>
                    </Grid>
                    {showResults &&
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Table style={{marginTop:20}}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{fontWeight:'bold'}}>Based on Direct Usage Parts:</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>Min</TableCell>
                                            <TableCell>{calculatorResults.parts_min.toFixed(3)}</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>Max</TableCell>
                                            <TableCell>{calculatorResults.parts_max.toFixed(3)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{fontWeight:'bold'}}>Based on X%:</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>Min</TableCell>
                                            <TableCell>{calculatorResults.percentage_min.toFixed(3)}</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>Max</TableCell>
                                            <TableCell>{calculatorResults.percentage_max.toFixed(3)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{fontWeight:'bold'}}>Total:</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>Min</TableCell>
                                            <TableCell>{parseFloat(parseFloat(calculatorResults.parts_min) + parseFloat(calculatorResults.percentage_min)).toFixed(3)}</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>Max</TableCell>
                                            <TableCell>{parseFloat(parseFloat(calculatorResults.parts_max) + parseFloat(calculatorResults.percentage_max)).toFixed(3)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Typography variant="subtitle2" gutterBottom style={{fontWeight:'bold'}}>
                                    Directly Used By:
                                </Typography>
                                {_.map(calculatorResults.direct_parts, el => {
                                    return (
                                        <Typography variant="body2" gutterBottom style={{color: el.status !== 'Active' ? colors.red : ''}}>
                                            {el.part}
                                        </Typography>
                                    )
                                })}
                            </Grid>
                            <Grid item xs lg={6}>
                                <Typography variant="subtitle2" gutterBottom style={{fontWeight:'bold'}}>
                                    Indirectly Used By:
                                </Typography>
                                {_.map(calculatorResults.indirect_parts, el => {
                                    return (
                                        <Typography variant="body2" gutterBottom style={{color: el.status !== 'Active' ? colors.red : ''}}>
                                            {el.part}
                                        </Typography>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    }
                </PaddedPaper>
            </PaddedPaper>
        )
    }

}

export default MinMaxCalculator;
