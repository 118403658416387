import { Grid } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import SalesReportCompleteMonth from './SalesReportCompleteMonth';
import SalesReportYear from './SalesReportYear';
import SalesReportCurrentMonth from './SalesReportCurrentMonth';
import { colors } from 'Helpers/ColourHelper';

class salesReport extends React.Component {

    render() {

        const {year, current} = this.props;

        let currentMonth = moment().month() + 1;      

        return (
            <Grid container spacing={2}>

                {/* Year Report */}
                <SalesReportYear {...this.props}/>

                <Grid item xs={12} style={{marginTop: '1em', marginBottom: '1em', borderTop: `1px solid ${colors.disabled}`, padding: 0}}></Grid>

                {!!current &&
                    <SalesReportCurrentMonth
                        month={currentMonth} 
                        year={year}  
                        cid={this.props.cid} 
                        partId={this.props.partId}
                    />
                }

                {/* Complete Months */}
                <>
                    {_.map(_.reverse(_.range(-2, current ? ( (currentMonth > 10) ? (currentMonth - 12) : currentMonth ) : 10))
                    , idx => 
                        <SalesReportCompleteMonth 
                            month={parseFloat(idx) <= 0 ? 12 + parseFloat(idx) : parseFloat(idx)} 
                            year={year}  
                            cid={this.props.cid} 
                            partId={this.props.partId}
                        />
                    )}
                </>

               
            </Grid>
        )
    }
}

export default salesReport;