import React       from 'react';
import _           from 'lodash';
import API         from 'API';
import { connect } from 'react-redux';
import moment      from 'moment';
import { API_URL } from 'Constants';

import FALightIcon          from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper          from 'Components/Common/Paper/PaddedPaper';
import DataTable            from 'Components/Common/DataTables/CiDataTable';
import ConfirmationDialog   from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar             from 'Components/Common/SnackBars/SnackBar';
import ViewStaffMiniProfile from 'Components/Staff/ViewStaff/ViewStaffMiniProfileCard';
import MediaTable           from 'Components/Common/DataTables/MediaTable';
import AllIcon              from 'Components/Common/Icons/AllIcon';

import LinkToOrderDialog from './LinkToOrderDialog';

import { Button, FormControl, Grid, TextField, Typography, Dialog, DialogContent, DialogActions, 
         DialogTitle, InputLabel, Input,
         Box} from '@material-ui/core';

import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';
import { getToken }                                                     from 'Functions/AuthFunctions';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';
    
import { clearPersistence, setPersistence} from 'Actions/StatePersistence/StatePersistence';
import { deployDialog }                    from 'Actions/Dialog/Dialog';
import { closeDialog }                     from 'Actions/Dialog/Dialog';
import InfoIcon from 'Components/Common/Icons/InfoIcon';

const initialState = () => ({
    access: {
        deleteUploads: false
    },
    formData: {
        searchName: ''
    },
    searchResults: [],
    isLoading: true,
    dialogOpen: false,
    selectedUpload: {},
    deleteOpen: false,
    snackbarOpen: false,
});

class UploadsSearch extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'engineers:UploadsSearch';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount() {
        !this.hasPageState() && this.loadComponentData();
    }

    loadComponentData = () => {
        this.getAccess();
        this.getUploads();
    }

    getAccess = () => { API.get('/staff/my/access/check/engineer-uploads-search:delete-uploads')
        .then(deleteRes => {
            this.setState({
                ...this.state,
                access: {
                    deleteUploads: deleteRes.data && deleteRes.data.has_access
                }
            });
        })
    }

    handleChange = e => {
        const {name, value} = e.target;
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: value
            }
        }, () => { this.getUploads(); });
    }

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                searchName: ''
            }
        }, () => { this.getUploads(); });
    }

    getUploads = () => {
        API.get('/engineers/uploads', {
            params: {
                searchName: this.state.formData.searchName
            },
            props: {
                cancellation: true
            }
        })
        .then(result => {
            if(result?.data?.success) {
                this.setState({
                    ...this.state,
                    searchResults: result.data.engineerUploads,
                    isLoading: false
                }, ()=> {this.savePageState()});
            }
        });
    }

    getZipFile = uid => e => {
        const token = getToken();
        window.open(API_URL+'/downloadZipFile?type='+btoa('Engineer')+'&token='+btoa(token)+'&request='+btoa(uid));
    }

    handleDialogOpen = (rowData) => {
        this.setState({
            ...this.state,
            dialogOpen: true,
            selectedUpload: rowData
        });
    };

    handleDialogClose = () => {
        this.setState({
            ...this.state,
            dialogOpen: false
        });
    };

    handleDeleteOpen = (rowData) => {
        this.setState({
            ...this.state,
            deleteOpen: true,
            selectedUpload: rowData
        });
    }

    handleDeleteClose = () => {
        this.setState({
            ...this.state,
            deleteOpen: false
        });
    }

    handleDeleteSuccess = () => {
        this.setState(state => ({
            ...this.state,
            deleteOpen: false
        }));
        this.deleteContact();
    }

    handleSnackbarClose = () => {
        this.setState(state => ({
            ...this.state,
            snackbarOpen: false
        }));
    }

    deleteContact = () => {
        API.post(`/engineers/uploads/${this.state.selectedUpload.engineer_upload_id}/delete`)
        .then((result) => {
            if(result.data.success) {
                this.setState({
                    ...this.state,
                    snackbarOpen: true
                }, () => {this.getUploads();});
            }
        });
    }

    handleLinkToOrder = () => {
        this.props.deployDialog(
            <LinkToOrderDialog 
                files={this.state.selectedUpload.uploaded_files}
                serialNumber={this.state.selectedUpload.engineer_upload_serial_number}
                site={this.state.selectedUpload.engineer_upload_site_name}
                onClose={ () => {
                    this.getUploads();
                    this.handleDialogClose();
                }}
            />,
            'Link Engineer Upload to Order / Quote'
        )
    }

    render() {
        const {access, formData, formData:{searchName}, searchResults, isLoading, dialogOpen, selectedUpload, deleteOpen, snackbarOpen} = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Upload Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField  
                                        id="searchName"
                                        name="searchName"
                                        label="Keywords"
                                        value={searchName}
                                        onChange={this.handleChange}
                                        fullWidth 
                                    />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button 
                                        onClick={this.resetSearch}
                                        variant="outlined"
                                        color="default"
                                            
                                    >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key:                    'engineer_upload_id',
                                isLoading:              isLoading,
                                responsiveImportance:   true,
                                pagination:             true,
                                persistenceId:          this.persistenceId,
                                alternatingRowColours:  true,
                            }}
                            columns={[ 
                                {
                                    heading: 'Date',
                                    field: 'engineer_upload_datetime_added',
                                    fieldFormat: 'clenydate',
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Engineer Name',
                                    field: rowData => rowData.staff.full_name,
                                    important: true,
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Site Name',
                                    field: rowData => <Box
                                        style={{
                                            maxWidth: '290px',
                                            overflowX: 'hidden',
                                        }}
                                    >
                                        <InfoIcon
                                            info={rowData.engineer_upload_site_name}
                                            style={{marginRight: 8}}
                                        />
                                        {rowData.engineer_upload_site_name}
                                    </Box>
                                    ,
                                    important: true,
                                },
                                {
                                    heading: 'Machine Type',
                                    field: rowData => <Box
                                        style={{
                                            maxWidth: '290px',
                                            overflowX: 'hidden',
                                        }}
                                    >
                                        {!!rowData.engineer_upload_machine_type &&
                                            <InfoIcon
                                                info={rowData.engineer_upload_machine_type || '-'}
                                                style={{marginRight: 8}}
                                            />
                                        }
                                        {rowData.engineer_upload_machine_type || '-'}
                                    </Box>
                                    ,
                                    important: true,
                                },
                                {
                                    heading: 'Serial Number',
                                    field: rowData => <Box
                                        style={{
                                            maxWidth: '290px',
                                            overflowX: 'hidden',
                                        }}
                                    >
                                        {!!rowData.engineer_upload_serial_number &&
                                            <InfoIcon
                                                info={rowData.engineer_upload_serial_number || '-'}
                                                style={{marginRight: 8}}
                                            />
                                        }
                                        {rowData.engineer_upload_serial_number || '-'}
                                    </Box>
                                    ,
                                    important: true,
                                },
                                {
                                    heading: 'Linked Qty',
                                    field: rowData => _.filter(rowData.uploaded_files, i => i.engineer_media?.descriptions && _.find(i.engineer_media?.descriptions, _i => _i.orders.length > 0 || _i.quotes.length > 0 )).length,
                                    important: true,
                                    alignment: 'right',
                                },
                                {
                                    heading: 'Upload Qty',
                                    field: rowData => rowData.uploaded_files.length,
                                    important: true,
                                    alignment: 'right',
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'View', icon: 'search', onClick: () => this.handleDialogOpen(rowData)},
                                            {name: 'Delete', icon: 'trash-alt', onClick: () => this.handleDeleteOpen(rowData) , disabled: !access.deleteUploads}
                                        ]
                                    }
                                }
                            ]}
                            rows={searchResults}
                        />
                    </PaddedPaper>
                </Grid>
                <Dialog
                    open={dialogOpen} 
                    onClose={this.handleDialogClose} 
                    fullWidth={true} 
                    maxWidth="md" 
                    scroll="body"
                    disableEscapeKeyDown
                    disableBackdropClick	
                >
                    <React.Fragment>                     
                        <DialogTitle>Uploaded Files</DialogTitle>
                        <Typography component={DialogContent} variant="body2" style={{height:'100%'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}  align="center">
                                        <ViewStaffMiniProfile staffData={selectedUpload.staff} header='Engineer' vertical/>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <form noValidate autoComplete="off">
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={9}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <InputLabel htmlFor="siteName" shrink={true} style={{color: 'black'}}>Site Name</InputLabel>
                                                        <Input id="siteName" value={selectedUpload.engineer_upload_site_name} disabled />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <InputLabel htmlFor="dateUploaded" shrink={true} style={{color: 'black'}}>Date</InputLabel>
                                                        <Input id="dateUploaded" value={moment(selectedUpload.engineer_upload_datetime_added).format("DD-MM-YYYY")} disabled /> 
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <InputLabel htmlFor="machineType" shrink={true} style={{color: 'black'}}>Machine Type</InputLabel>
                                                        <Input id="machineType" value={selectedUpload.engineer_upload_machine_type || '-'} disabled />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <InputLabel htmlFor="serialNumber" shrink={true} style={{color: 'black'}}>Serial Number</InputLabel>
                                                        <Input id="serialNumber" value={selectedUpload.engineer_upload_serial_number || '-'} disabled />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                    {selectedUpload?.uploaded_files && 
                                        <Grid item xs={12}>
                                            <MediaTable
                                                config={{
                                                    key: 'euf_id',
                                                    size: {
                                                        xs: 4,
                                                    }
                                                }}
                                                media='full_url'
                                                fields={[ 
                                                    { actions: rowData => ([ 
                                                        { 
                                                            download: true
                                                        },
                                                        _.sumBy(rowData.engineer_media?.descriptions, i =>  i.orders ? i.orders.length : ( i.quotes ? i.quotes.length : 0 )) > 0 && {
                                                            name: <>
                                                                { _.sumBy(rowData.engineer_media?.descriptions, i =>  i.orders ? i.orders.length : 0 ) > 0 &&
                                                                    <>Linked To Orders<br/>
                                                                        {_.map(rowData.engineer_media?.descriptions, i => i.orders?.length && _.map(i.orders, _o => <>{_o.order_reference}<br/></>))}
                                                                    <br/></>
                                                                }
                                                                { _.sumBy(rowData.engineer_media?.descriptions, i =>  i.quotes ? i.quotes.length : 0 ) > 0 &&
                                                                    <>Linked To Quotes<br/>
                                                                        {_.map(rowData.engineer_media?.descriptions, i => i.quotes?.length && _.map(i.quotes, _o => <>{_o.quote_reference}<br/></>))}
                                                                    </>
                                                                }

                                                            </>,
                                                            icon: icons.sales,
                                                        } 
                                                    ])}
                                                ]}
                                                items={selectedUpload.uploaded_files}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                        </Typography>
                        <DialogActions>       
                            <Button variant='outlined' onClick={this.handleLinkToOrder}>
                                <AllIcon icon={icons.sales} size='small'/>
                                Link To Order / Quote          
                            </Button>            
                            <Button onClick={this.getZipFile(selectedUpload.engineer_upload_uid )} variant="outlined" color="primary">
                                <FALightIcon icon={icons.download} style={{color: colors.blue}}/>
                                Download All
                            </Button>
                            <Button onClick={this.handleDialogClose} variant="outlined" color="default">Close</Button>
                        </DialogActions>
                    </React.Fragment>
                </Dialog>
                <ConfirmationDialog 
                    open={deleteOpen} 
                    success={this.handleDeleteSuccess} 
                    close={this.handleDeleteClose} 
                    title="Delete Upload(s)?"
                    variant={ selectedUpload?.uploaded_files?.length && ( _.sumBy( selectedUpload?.uploaded_files, f => _.sumBy( f.engineer_media?.descriptions, i => i.orders ? i.orders?.length : ( i.quotes ? i.quotes?.length : 0 ) ) ) <  selectedUpload?.uploaded_files?.length && "warning" ) }
                    message={ selectedUpload?.uploaded_files?.length && ( _.sumBy( selectedUpload?.uploaded_files, f => _.sumBy( f.engineer_media?.descriptions, i => i.orders ? i.orders?.length : ( i.quotes ? i.quotes?.length : 0 ) ) ) >= selectedUpload?.uploaded_files?.length ? 
                                "Are you sure you want to delete the uploads?" : "Are you sure you want to delete the uploads, not all files have been linked to orders/quotes?")
                            }
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message= 'You have successfully deleted the uploads' 
                />
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)             => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)      => dispatch(setPersistence(key, state)),
    deployDialog:       (content, header) => dispatch(deployDialog(content, header, null, 'md')),
    closeDialog:        ()                => dispatch(closeDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadsSearch);