import React from 'react';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { colors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import InputHelper from 'Helpers/InputHelper';
import { connect } from 'react-redux';

const initialState = () => ({ 
    formData: {
        customerId: '',
        name: '',
        position: '',
        telephone: '',
        mobile: '',
        email: ''
    },
    formErrors: [],
    isLoading: true,
});

class EditContactDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.inputHelper = new InputHelper(this);
    }

    componentDidMount(){
        this.getContact();
    }

    getContact = () => {
        API.get(`/customers/contacts/${this.props.id}`)
        .then((res) =>  {
            if (res.data) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        customerId: res.data.contact_customer_id,
                        name: res.data.contact_name,
                        position: res.data.contact_position,
                        telephone: res.data.contact_telephone,
                        mobile: res.data.contact_mobile,
                        email: res.data.contact_email,
                    },
                    isLoading: false,
                    contact: res.data,
                });
            }
        })
    }

    submit = () => {
        API.post(`/customers/contacts/${this.props.id}`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.props.onClose();
                this.props.onUpdate();
            }
        });
    }

    render() {

        const { formErrors, formData, isLoading } = this.state;

        if (isLoading) {
            return (
                <LoadingCircle/>
            )
        }

        return (
            <Box>
                {formErrors && formErrors.generic && (
                    <React.Fragment>
                        <Typography component={"div"} style={{color: colors.red}}>
                            {formErrors.generic}
                        </Typography>
                    </React.Fragment>
                )}
                <form noValidate autoComplete="off">
                    <TextField
                        id="name"
                        name="formData.name"
                        label="Name *"
                        value={formData.name}
                        error={formErrors && formErrors['name'] && true}
                        helperText={formErrors && formErrors['name']}
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        id="position"
                        name="formData.position"
                        label="Position"
                        value={formData.position}
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        id="email"
                        name="formData.email"
                        label="Email"
                        value={formData.email}
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                        margin="normal"
                        fullWidth
                    />
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                id="mobile"
                                name="formData.mobile"
                                label="Mobile"
                                value={formData.mobile}
                                onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                id="telephone"
                                name="formData.telephone"
                                label="Telephone"
                                value={formData.telephone}
                                onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </form>
                <Box width={'100%'} className='buttonRow'>
                    <Button
                        variant="outlined"
                        onClick={this.props.onClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => this.props.deployConfirmation(
                                'Are you sure you want to update this contact?',
                                'Update Contact',
                                this.submit
                            )
                        }
                    >
                        Update
                    </Button>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback) => {
            dispatch(deployConfirmation(content, title, callback))
        }
    }
}

export default connect(null, mapDispatchToProps)(EditContactDialog);