import React, { Component }                          from 'react';
import _                                             from 'lodash';
import moment                                        from 'moment';
import { connect }                                   from 'react-redux';
import { Link, Redirect }                            from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import API                                           from 'API';
import { MAX_UPLOAD_LIMIT_SIZE }                     from 'Constants';

import { AppBar, Avatar, Button, Card, CardContent, CardHeader, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, ExpansionPanel, Stepper, ExpansionPanelDetails, 
    ExpansionPanelSummary, FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, Step, StepLabel,Tab, Tabs, Typography} from '@material-ui/core';
import MaterialLink   from '@material-ui/core/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles';

import BackButton              from 'Components/Common/Buttons/BackButton';
import AllIcon                 from 'Components/Common/Icons/AllIcon';
import DataTable               from 'Components/Common/DataTables/DataTable';
import FALightIcon             from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon             from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import DragFileInput           from 'Components/Common/Inputs/DragFileInput';
import Textarea                from 'Components/Common/Inputs/Textarea';
import LoadingCircle           from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper             from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';
import SnackBar                from 'Components/Common/SnackBars/SnackBar';
import PartsPurchaseOrder      from '../PartsPurchaseOrder';

import DeliverySchedule   from './../DeliverySchedule';

import { colors }                 from 'Helpers/ColourHelper';
import icons                      from 'Helpers/IconHelper';
import { ppoActivityLog }         from 'Helpers/ColourHelper';
import { pdfFromBase64 }          from 'Helpers/PDFHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import '../../../Assets/timeLine.css';

import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    btnRow: {
        textAlign: 'center', 
    },
    btnRowBtn: {   
        width: '100%',
    },
    link: {
        textDecoration: 'none',
    },
    chip: {
        display: 'none',
    },
    poAlign: {
        marginLeft: '1.01em'
    },
    outstanding: {
        color:'#999'
    },       
    topGap: {
        marginTop: theme.spacing(3)
    },       
    bottomGap: {
        marginBottom: theme.spacing(1.5)
    },      
    bottomGapLg: {
        marginBottom: theme.spacing(3)
    },  
    contentGap: {
        marginTop: theme.spacing(3)
    },
    expansionPanelDetails: {        
        width: 'calc(100% + 24px)!important',
        padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(5)}px ${theme.spacing(3)}px`,
    },
    statusUpdate: {
        textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        },
        stepper: {
            display: 'none',
        },
        chip: {
            display: 'flex',
            width: '160px',
            marginBottom: theme.spacing(3),
        },
        poAlign: {
            marginLeft: 0
        },
    },
    [theme.breakpoints.down('md')]: {        
        contentGap: {
            marginTop: 0,
        },
    }
});

const initialState = () => ({
    formData: {
        noteType: '',
        notesText: '',

        fileType: '',
        invoiceType: '',
        file: '',
        fileDate: '',

        cancellationNotes:'',
        amendTo:'',

        emailTo: '',
        emailText: '',
        emailTemplate: '',
        emailSchedules: [],
        emailPartsOrders: [],
        emailDrawings: [],

        deliveryFileID: ''
    },
    expansionPanel: {
        approval: false,
        confirmation: false,
        delivery: false,
        invoice: false,
        completed: false,
        declined: false,
        cancelled: false,
    },
    expansionPanelDisabled: {
        approval: true,
        confirmation: true,
        delivery: true,
        invoice: true,
        completed: true,
        declined: true,
        cancelled: true,
    },
    formErrors: [],
    currentTab: 0,
    ppo: {},
    statuses: [],
    activeStep: 0,
    showSSR: false,
    activityActionedBy: [],
    nextStep: '',
    deliveryCommenced: false,
    barColor: [],
    notes: [],
    files: [],
    templateList: [],
    templates: {},
    supplierContactList: [],
    outstandingDeliveryInvoices: [],
    showInvoiceType: false,
    showDeliveryDates: false,
    // Dialogs
    dialogNotesOpen: false,
    dialogCancelOpen: false,
    dialogEmailOpen: false,
    dialogFilesOpen: false,
    dialogDeliveryInvoiceOpen: false,
    //Snackbars
    snackbarNotesOpen: false,
    snackbarFilesOpen: false,
    snackbarCancelOpen: false,
    snackbarCustomOpen: false,
    succesMsg: '',
    isLoading: true,
    redirect: false,
    latestPpoId: 0
});

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

class ViewPartsOrder extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `PartsOrder:View:${this.props.match.params.id}`;
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.loadComponentData();
    }

    loadComponentData = () => {
        API.get('/partsOrdering/' + this.props.match.params.id + '/mostRecentPPOLinked')
        .then(result => {
            this.setState({
                latestPpoId: result.data.latestPpoId !== parseInt(this.props.match.params.id) ? result.data.latestPpoId : this.props.match.params.id
            }, () => { 
                this.getPPO();
                this.props.history.replace('/parts-order/view/' + this.state.latestPpoId);
            })
        });
    }

    handlePanel = (panel) => {        
        if(this.state.expansionPanelDisabled[panel] === false){
            this.setState({
                expansionPanel: {
                    ...this.state.expansionPanel,
                    [panel]: this.state.expansionPanel[panel] === true ? false : true,
                }
            });
        }
    }

    getPPO = () => {
        API.get('/partsOrdering/' + this.state.latestPpoId)
        .then(result => {
            if (result.data.errors) {
                this.setState({ redirect: true });
            } else if (result.data) {
                // OTHER API REQ API CALLS
                this.getEmailTemplates();
                this.getOutstandingDeliveryInvoices();
                // PROGRESS STEPS
                let statuses = ['Approval', 'Confirmation', 'Delivery', 'Invoice', 'Completed'];
                const activeStep = _.findIndex(statuses, function(el) { return el === result.data.ppo_status; });
                // BAR COLOURS - for the panels in Overview
                let barColor = [];
                statuses.forEach((status, idx) => {
                    if((activeStep && idx < activeStep) || activeStep === 4){
                        barColor[idx] = colors.green;
                    } 
                    else if(idx === activeStep){
                        barColor[idx] = 'orange';
                    } 
                    else if(idx === 3 && activeStep === 2){
                        barColor[idx] = 'orange';
                    }
                    else {
                        barColor[idx] = 'white';
                    }
                });
                let deliveryCommenced = false;
                _.map(result.data.parts_order_details, (detail) => {
                    switch(detail.ppo_detail_schedule_delivery_status){
                        case 'Pending':
                        case 'In Progress':
                            detail.delivery_panel_colour = 'orange';
                        break;
                        case 'Cancelled':
                        case 'Declined':
                            detail.delivery_panel_colour = colors.red;
                        break;
                        case 'Completed':
                            detail.delivery_panel_colour = colors.green;
                        break;
                        default:
                        break;
                    }

                    let remainderStock = (detail.received_total_qty - detail.original_part_quantity).toFixed(2);
                    switch(detail.ppo_detail_schedule_stock_status){
                        case 'Exact':
                            detail.scheduleStockStatus = <span style={{color:colors.green}}>Exact</span>;
                        break;
                        case 'Under':
                            detail.scheduleStockStatus = <span style={{color:'orange'}}>Under ({remainderStock})</span>;
                        break;
                        case 'Over':
                            detail.scheduleStockStatus = <span style={{color:colors.red}}>Over ({remainderStock})</span>;
                        break;
                        default:
                        break;
                    }
                    if(detail.ppo_detail_part_id > 0 && detail.ppo_detail_schedule_delivery_status !== 'Pending'){
                        deliveryCommenced = true;
                    }
                });
                // NEXT STEP - link displayed under ppo
                let nextStep = `This parts order has been ${result.data.ppo_status.toLowerCase()}`;
                if(['Approval','Confirmation','Delivery','Invoice'].includes(result.data.ppo_status)){
                    nextStep =  <Typography style={{textAlign:'center'}} variant="body1" gutterBottom>
                                    This parts order is now awaiting {result.data.ppo_status.toLowerCase()}.<br></br>
                                    <Link to={`/parts-order/${result.data.ppo_status.toLowerCase()}`} className='blueLink'>
                                        {result.data.ppo_status === 'Approval' ? 'Approve Parts Order' : (result.data.ppo_status === 'Confirmation' ? 'Confirm Parts Order' : `Confirm ${result.data.ppo_status}`)}
                                    </Link>
                                </Typography>;
                }
                // SUPPLER CONTACTS - for 'email to' dropdown
                let supplierContactList = _.map(result.data.supplier_contacts, (el) => {
                    return _.assign({
                        value: el.supp_cont_id,
                        label: `${el.supp_cont_first_name} ${el.supp_cont_last_name} (${el.supp_cont_email})`
                    });
                });
                
                // ACTIVITY
                let actionedBy = [];
                _.map(result.data.activity, (log) => {
                    let data = ppoActivityLog(log.ppo_log_type);
                    log.color = data['color'];
                    log.icon = data['icon'];
                    log.time = moment(log.ppo_log_datetime_added).format("DD/MM/YYYY");

                    switch(log.ppo_log_type){
                        case 'Approved':
                            if(!actionedBy['approved_by']){actionedBy['approved_by'] = []}
                            actionedBy['approved_by'].push(log);
                        break;
                        case 'Declined':
                            if(!actionedBy['declined_by']){actionedBy['declined_by'] = []}
                            actionedBy['declined_by'].push(log);
                        break;
                        case 'Confirmed':
                            if(!actionedBy['confirmed_by']){actionedBy['confirmed_by'] = []}
                            actionedBy['confirmed_by'].push(log);
                        break;
                        case 'Delivery':
                            if(!actionedBy['delivered_by']){actionedBy['delivered_by'] = []}
                            actionedBy['delivered_by'].push(log);
                        break;
                        case 'All Invoices Received':
                            if(!actionedBy['invoice_by']){actionedBy['invoice_by'] = []}
                            actionedBy['invoice_by'].push(log);
                        break;
                        case 'Cancelled':
                            if(!actionedBy['cancelled_by']){actionedBy['cancelled_by'] = []}
                            actionedBy['cancelled_by'].push(log);
                        break;
                        default:
                        break;
                    }
                });
                // NOTES
                let notes = [];
                _.map(result.data.notes, (note) => {
                    switch(note.ppo_note_type){
                        case 'Approval':
                            if(!notes['Approval']){notes['Approval'] = []}
                            notes['Approval'].push(note);
                        break;
                        case 'Declined':
                            if(!notes['Declined']){notes['Declined'] = []}
                            notes['Declined'].push(note);
                        break;
                        case 'Invoice':
                            if(!notes['Invoice']){notes['Invoice'] = []}
                            notes['Invoice'].push(note);
                        break;
                        case 'Delivery':
                            if(!notes['Delivery']){notes['Delivery'] = []}
                            notes['Delivery'].push(note);
                        break;
                        case 'Cancelled':
                            if(!notes['Cancelled']){notes['Cancelled'] = []}
                            notes['Cancelled'].push(note);
                        break;
                        case 'Confirmation':
                            if(!notes['Confirmation']){notes['Confirmation'] = []}
                            notes['Confirmation'].push(note);
                        break;
                        default:
                        break;
                    }
                });
                // FILES
                let files = [];
                _.map(result.data.files, (file) => {
                    switch(file.ppo_file_type){
                        case 'Confirmation':
                            if(!files['Confirmation']){files['Confirmation'] = []}
                            files['Confirmation'].push(file);
                        break;
                        case 'Delivery':
                            if(!files['Delivery']){files['Delivery'] = []}
                            files['Delivery'].push(file);
                        break;
                        case 'Invoice':
                            if(file.ppo_file_delivery_date === '0000-00-00'){
                                if(!files['Invoice']){files['Invoice'] = []}
                                files['Invoice'].push(file);
                            } else {
                                if(!files['DeliveryInvoice']){files['DeliveryInvoice'] = []}
                                files['DeliveryInvoice'].push(file);
                            }
                        break;
                        case 'Pro forma':
                            if(!files['Proforma']){files['Proforma'] = []}
                            files['Proforma'].push(file);
                        break;
                        default:
                        break;
                    }
                });

                // Expand current status
                let defaultExpanded;
                if(actionedBy.declined_by){                    
                    defaultExpanded = 'declined';
                } else if(actionedBy.cancelled_by){
                    defaultExpanded = 'cancelled';
                } else {
                    defaultExpanded = statuses[activeStep].toLowerCase();
                }

                // Disable non-completed stages               
                const expansionPanelDisabled = {
                    ...this.state.expansionPanelDisabled
                }
                
                if(actionedBy['approved_by']) {
                    if(actionedBy['declined_by'] || actionedBy['cancelled_by'])
                        barColor[0] = 'disabled';
                    expansionPanelDisabled['approval'] = false;
                }                    

                if(actionedBy['confirmed_by']) {
                    if(actionedBy['declined_by'] || actionedBy['cancelled_by'])
                        barColor[1] = 'disabled';
                    expansionPanelDisabled['confirmation'] = false;
                }       
                    
                if(actionedBy['delivered_by']) {     
                    if(actionedBy['declined_by'] || actionedBy['cancelled_by']) {       
                        barColor[2] = 'disabled';
                        barColor[3] = 'disabled';
                    }
                    expansionPanelDisabled['delivery'] = false;                    
                    expansionPanelDisabled['invoice'] = false;
                }       

                if(result.data.ppo_completed_datetime && result.data.ppo_completed_datetime !== '0000-00-00 00:00:00') {
                    if(actionedBy['declined_by'] || actionedBy['cancelled_by'])
                        barColor[4] = 'disabled';
                    expansionPanelDisabled['completed'] = false;
                }       

                if(actionedBy['declined_by'])
                    expansionPanelDisabled['declined'] = false;

                if(actionedBy['cancelled_by'])
                    expansionPanelDisabled['cancelled'] = false; 

                if(['Approval','Confirmation','Delivery','Invoice'].includes(result.data.ppo_status)){
                    expansionPanelDisabled[result.data.ppo_status.toLowerCase()] = false;
                    
                    if(result.data.ppo_status === 'Delivery'){
                        expansionPanelDisabled['invoice'] = false;
                    }
                }

                if (files['Proforma']?.length > 0) { expansionPanelDisabled['invoice'] = false; barColor[3] = 'orange'; }
                
                this.setState({
                    ppo: result.data,
                    statuses: statuses,
                    activeStep: activeStep,
                    activityActionedBy: actionedBy,
                    expansionPanelDisabled,                      
                    expansionPanel: {
                        ...this.state.expansionPanel,
                        [defaultExpanded]: true
                    },
                    nextStep: nextStep,
                    barColor: barColor,
                    notes: notes,
                    files: files,
                    supplierContactList: supplierContactList,
                    snackbarCustomOpen: this.props.location.state && this.props.location.state.successMsg ? true : false,
                    succesMsg: this.props.location.state && this.props.location.state.successMsg ? this.props.location.state.successMsg : '',
                    isLoading: false,
                    deliveryCommenced: deliveryCommenced
                });
            }
        });
    }

    getEmailTemplates = () => {
        API.get('/partsOrdering/emailTemplates')
        .then((templateRes) =>  {
            let templateList = [];

            if(templateRes.data) {
                templateList = _.map(templateRes.data, (el) => {
                    return _.assign({
                        value: el.ppo_email_id,
                        label: el.ppo_email_name
                    });
                });
                templateList.unshift({
                    value: 0,
                    label: 'None'
                });
            }
            this.setState({
                templateList: templateList,
                templates: templateRes.data
            });
        });
    }

    getOutstandingDeliveryInvoices = () => {
        API.get(`/partsOrdering/${this.props.match.params.id}/outstandingDeliveryInvoices`)
        .then((res) =>  {

            let outstandingDeliveryInvoices = [];
            if(res.data) {
                outstandingDeliveryInvoices = _.map(res.data, (el) => {
                    return _.assign({
                        value: el.ppo_file_id,
                        label: moment(el.ppo_file_delivery_date).format("DD/MM/YYYY")
                    });
                });
            }
            this.setState({
                outstandingDeliveryInvoices: outstandingDeliveryInvoices
            });
        });
    }

    downloadPPO = (ppoId) => {
        API.get('/partsOrdering/' + ppoId + '/pdfPartsOrder')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }
    downloadDS = (ppoId, version) => {
        API.get('/partsOrdering/' + ppoId + '/pdfDeliverySchedule', 
        { 
            params: { 
                version: version 
            } 
        })
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }
    viewPPO = () => {
        this.setState(prevState => ({
            showPPO: !prevState.showPPO,
            showDS: false
        }),()=>this.savePageState());
    }
    viewDS = () => {
        this.setState(prevState => ({
            showDS: !prevState.showDS,
            showPPO: false
        }),()=>this.savePageState());
    }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        },()=>this.savePageState());
    };

    viewSSR = () => {
        this.setState(prevState => ({
            showSSR: !prevState.showSSR
        }));
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if(fieldName === 'emailTemplate'){
                let email = _.find(this.state.templates, {'ppo_email_id': selectedOption && selectedOption.value });

                this.setState({
                    formData:{
                        ...this.state.formData,
                        emailText: (typeof email === 'undefined') ? '' : email.ppo_email_text
                    }
                });
            }
            this.setState({
                showInvoiceType: this.state.formData.fileType === 'Invoice' || (selectedOption && selectedOption.value === 'Invoice') ? true : false
            });
        });
    }
    handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        },
        () => {
            this.setState({
                showDeliveryDates: this.state.formData.invoiceType === 'Delivery Invoice' ? true : false
            });
        });
    }    
    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }
    clearFile = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                file: initialState().formData.file
            }
        });
    };

    // NOTES
    handleNotesOpen = () => {this.setState({dialogNotesOpen: true});};
    handleNotesClose = () => {this.setState({dialogNotesOpen: false});};
    handleNotesSuccess = () => {
        this.setState({ dialogNotesOpen: false });
        this.notesSubmit();
    }
    handleNotesSnackbarClose = () => {this.setState({ snackbarNotesOpen: false }); };
    notesSubmit = () => {
        API.post(`/partsOrdering/${this.props.match.params.id}/notes`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogNotesOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    snackbarNotesOpen: true
                });
                this.loadComponentData();
            }
        });
    }
    // CANCEL
    handleCancelOpen = () => {this.setState({dialogCancelOpen: true});};
    handleCancelClose = () => {this.setState({dialogCancelOpen: false});};
    handleCancelSuccess = () => {
        this.setState({ dialogCancelOpen: false });
        this.cancelSubmit();
    }
    handleCancelSnackbarClose = () => {this.setState({ snackbarCancelOpen: false }); };
    cancelSubmit = () => {
        API.post(`/partsOrdering/${this.props.match.params.id}/cancel`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogCancelOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    snackbarCancelOpen: true
                });
                this.loadComponentData();
            }
        });
    }
    // EMAIL
    handleEmailOpen = () => {this.setState({dialogEmailOpen: true});};
    handleEmailClose = () => {this.setState({dialogEmailOpen: false});};
    handleEmailSuccess = () => {
        this.setState({ dialogEmailOpen: false });
        this.emailSubmit();
    }
    emailSubmit = () => {
        API.post(`/partsOrdering/${this.props.match.params.id}/email`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogEmailOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    snackbarEmailOpen: true
                });
                this.loadComponentData();
            }
        });
    }
    // FILES
    handleFilesOpen = () => {
        this.setState({
            dialogFilesOpen: true,
            showInvoiceType:false,
            showDeliveryDates: false, 
            formData: {
                ...this.state.formData,
                fileType: '',
                invoiceType: '',
                deliveryFileID: '',
                file: '',
            }
        });
    };
    handleFilesClose = () => {this.setState({dialogFilesOpen: false});};
    handleFilesSuccess = () => {
        this.setState({ dialogFilesOpen: false });
        this.filesSubmit();
    }
    handleFilesSnackbarClose = () => {this.setState({ snackbarFilesOpen: false }); };
    filesSubmit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            newFormData.append(key, this.state.formData[key]);
        });
        API.post(`/partsOrdering/${this.props.match.params.id}/files`, newFormData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogFilesOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    snackbarFilesOpen: true
                });
                this.loadComponentData();
            }
        });
    }
    // DELIVERY INVOICE
    handleDeliveryInvoiceOpen = (rowData) => {
        this.setState({
            dialogDeliveryInvoiceOpen: true,
            formData: {
                ...this.state.formData,
                fileType: 'Invoice',
                invoiceType: 'Delivery Invoice',
                deliveryFileID: rowData.ppo_file_id,
                fileDate: rowData.ppo_file_delivery_date,
                file: '',
            }
        });
    };
    handleDeliveryInvoiceClose = () => {this.setState({dialogDeliveryInvoiceOpen: false});};
    handleDeliveryInvoiceSuccess = () => {
        this.setState({ dialogDeliveryInvoiceOpen: false });
        this.deliveryInvoiceSubmit();
    }
    handleFilesSnackbarClose = () => {this.setState({ snackbarFilesOpen: false }); };
    deliveryInvoiceSubmit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            newFormData.append(key, this.state.formData[key]);
        });
        API.post(`/partsOrdering/${this.props.match.params.id}/files`, newFormData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogDeliveryInvoiceOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    snackbarFilesOpen: true
                });
                this.loadComponentData();
            }
        });
    }

    handleCustomSnackbarClose = () => {this.setState({ snackbarCustomOpen: false }); };

    displayNotes = (notes) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} className={this.props.classes.bottomGap}>                      
                <Typography variant="body1">
                    <FALightIcon icon='comment-alt-lines' /> Notes
                </Typography>
            </Grid>
            {notes ?
                notes.map(note => (
                    <Grid key={`note-${note.ppo_note_id}`} item xs={12} align='left' className={this.props.classes.bottomGapLg}>
                        <Card style={{boxShadow: 'none', border: '1px solid #DDD'}}>
                            <CardHeader
                                avatar={
                                    <Avatar alt={`${note.staff.staff_first_name} ${note.staff.staff_last_name}`} src={note.staff.staff_profile_photo} />
                                }
                                title={note.ppo_note_staff}
                                subheader={ moment(note.ppo_note_date_time, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
                            />
                            <CardContent>
                                <Typography variant="body2">
                                    {note.ppo_note_text && note.ppo_note_text.split('\n').map((item, key) => {
                                        return <span key={key}>{item}<br/></span>
                                    })}
                                </Typography>
                            </CardContent>
                        </Card>                                
                    </Grid>
                ))
                :
                (
                <Grid item xs={12}>
                    <Typography variant="body2">
                        No notes available to view
                    </Typography>
                </Grid> 
                )
            }
            </Grid>
        )
    }

    displayFiles = (files, fileTitle) => {
        const title = fileTitle;
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} className={this.props.classes.bottomGap}>                 
                    <Typography variant="body1">
                        <FALightIcon icon='download' /> {title}
                    </Typography>
                </Grid>
                {files ?
                    (
                        <Grid item xs={12}>
                            <DataTable  
                                config={{
                                    key: 'ppo_file_id',
                                    noHeader: true,
                                    withBorder: true,
                                    withBorderRadius: true,
                                }}
                                columns={[
                                    {
                                        field: rowData => <MaterialLink href={rowData.file_url} target="_blank" className='blueLink'>{rowData.ppo_file_filename}</MaterialLink>,
                                    }
                                ]}
                                rows={files}
                                />                                                                            
                        </Grid>
                    )
                    :
                    (
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            No files available to download
                        </Typography>
                    </Grid> 
                    )
                }
            </Grid>
        )
    }

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions && selectedOptions.map(a => a.value);
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }

    handleDeliveryInvoiceNotRequired = (fileId) => {
        API.post(`/partsOrdering/files/${fileId}/notRequired`)
        .then((result) => {
            if(result.data.success){
                this.loadComponentData();
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { currentTab, ppo, activityActionedBy, isLoading, expansionPanel, expansionPanelDisabled, activeStep, statuses, nextStep, deliveryCommenced,formData, formErrors, notes, files, latestPpoId } = this.state;  
        if (this.state.latestRedirect) {
            return <Redirect to={`/parts-order/view/${latestPpoId}`} />
        } else if (this.state.redirect) {
            return <Redirect to="/parts-order/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Parts Order Details
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={9}>
                            <PaddedPaper>
                                {(isLoading && (
                                    <LoadingCircle />               
                                )) || (
                                <React.Fragment>   
                                     {statuses[activeStep] &&
                                        <Grid item align='center' className='xs-only'>
                                            <Chip label={statuses[activeStep]} color='primary' size='medium' className={classes.chip}  />
                                        </Grid>  
                                    }                                                        
                                    <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                                        {this.state.statuses.map((label, idx) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    <Typography variant="h4" align="center" className={classes.heading} style={{marginBottom: 25}}>
                                        {ppo.full_reference}
                                    </Typography>
                                    <table style={{margin: '0 auto'}}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Typography variant="body1" align="right" gutterBottom className={classes.poAlign}>
                                                        Parts Purchase Order (v{parseInt(ppo.ppo_number_amendment)})<br className='xs-only' />
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Button variant="outlined"
                                                        color="default"
                                                        size="small"
                                                        className={classes.btn}
                                                        onClick={this.viewPPO}
                                                        style={{marginLeft:10}}
                                                    >                                    
                                                        <FALightIcon icon='search' />
                                                       {this.state.showPPO ? 'Hide': 'View'}
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button variant="outlined"
                                                            color="default"
                                                            size="small"
                                                            className={classes.btn}
                                                            onClick={() => this.downloadPPO(ppo.ppo_id)}
                                                    >
                                                        <FALightIcon icon='download' />
                                                        Download
                                                    </Button> 
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="body1" align="right" gutterBottom>
                                                        Delivery Schedule (v{parseInt(ppo.ppo_schedule_version)})<br className='xs-only' />
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Button variant="outlined"
                                                        color="default"
                                                        size="small"
                                                        onClick={this.viewDS}
                                                        className={classes.btn}
                                                        style={{marginLeft:10}}
                                                    >
                                                        <FALightIcon icon='search' />
                                                        {this.state.showDS ? 'Hide': 'View'}
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button variant="outlined"
                                                        color="default"
                                                        size="small"
                                                        className={classes.btn}
                                                        onClick={() => this.downloadDS(ppo.ppo_id, parseInt(ppo.ppo_schedule_version))}
                                                    >                                            
                                                        <FALightIcon icon='download' />
                                                        Download
                                                    </Button> 
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br className='xs-only' />
                                   
    
                                    { this.state.showPPO ? <PartsPurchaseOrder ppo={ppo} /> : null }
                                    { this.state.showDS ? <DeliverySchedule ppo={ppo} /> : null }
                                
                                    <AppBar position="static" style={{backgroundColor:'white', margin: '25px 0'}} >
                                        <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary" variant="scrollable">
                                            <Tab label="Overview" />
                                            <Tab label="Delivery" />
                                            <Tab label="Notes" />
                                            <Tab label="Activity Log" />
                                        </Tabs>
                                    </AppBar>
                                    {currentTab === 0 && (
                                        //Overview
                                        <TabContainer>
                                            {/* Approval */}
                                            <ExpansionPanel expanded={expansionPanel.approval} disabled={expansionPanelDisabled['approval']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('approval')} expandIcon={!expansionPanelDisabled['approval'] && <ExpandMoreIcon />} className={this.state.barColor[0]}>
                                                    <Typography>Approval</Typography>
                                                </ExpansionPanelSummary>
                                                {!activityActionedBy.approved_by ? (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3} justify='center'>
                                                            <Grid item align='center' xs={12}>                                              
                                                                <FALightIcon size='xlarge' icon='exclamation-circle' noMargin /> 
                                                                {nextStep}
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayNotes(notes.Approval)}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                ) : (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3} justify='center'>
                                                            <Grid item xs={12} align='center'>
                                                                {activityActionedBy.approved_by &&
                                                                    <React.Fragment>
                                                                        <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                        {activityActionedBy.approved_by.map(approved_by => (
                                                                            <Typography key={approved_by.ppo_log_id} variant="body1">Approved by {approved_by.ppo_log_staff} on {approved_by.time}</Typography>
                                                                        ))}
                                                                    </React.Fragment>
                                                                }
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayNotes(notes.Approval)}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                )}
                                            </ExpansionPanel>
                                            
                                            {/* Confirmation */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.confirmation} disabled={expansionPanelDisabled['confirmation']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('confirmation')} expandIcon={!expansionPanelDisabled['confirmation'] && <ExpandMoreIcon />} className={this.state.barColor[1]}>
                                                    <Typography>Confirmation</Typography>
                                                </ExpansionPanelSummary>
                                                {!activityActionedBy.confirmed_by ? (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3} justify='center'>
                                                            <Grid item align='center' xs={12}>                                               
                                                                <FALightIcon size='xlarge' icon='exclamation-circle' noMargin /> 
                                                                {nextStep}
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}  align='center'>
                                                                {this.displayNotes(notes.Confirmation)}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayFiles(files.Confirmation, 'Files')}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                ) : (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} align='center'>
                                                                {activityActionedBy.confirmed_by &&
                                                                    <React.Fragment>
                                                                        <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                        {activityActionedBy.confirmed_by.map(confirmed_by => (
                                                                            <Typography key={confirmed_by.ppo_log_id} variant="body1">Processed by {confirmed_by.ppo_log_staff} on {confirmed_by.time}</Typography>
                                                                        ))}
                                                                    </React.Fragment>
                                                                }
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}  align='center'>
                                                                {this.displayNotes(notes.Confirmation)}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayFiles(files.Confirmation, 'Files')}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                )}
                                            </ExpansionPanel>
                                            
                                            {/* Delivery */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.delivery} disabled={expansionPanelDisabled['delivery']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('delivery')} expandIcon={!expansionPanelDisabled['delivery'] && <ExpandMoreIcon />} className={this.state.barColor[2]}>
                                                    <Typography>Delivery</Typography>
                                                </ExpansionPanelSummary>
                                                {!activityActionedBy.delivered_by ? (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3} justify='center'>
                                                            <Grid item align='center' xs={12}>                                                 
                                                                <FALightIcon size='xlarge' icon='exclamation-circle' noMargin /> 
                                                                {nextStep}
                                                                <Divider className={classes.topGap} />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}  align='center'>
                                                            {this.displayNotes(notes.Delivery)}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} align='center'>
                                                            {this.displayFiles(files.Delivery, 'Files')}
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                                ) : (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} align='center'>
                                                            {activityActionedBy.delivered_by &&
                                                                <React.Fragment>
                                                                    <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                    {activityActionedBy.delivered_by.map(delivered_by => (
                                                                        <Typography key={delivered_by.ppo_log_id} variant="body1">Delivery processed by {delivered_by.ppo_log_staff} on {delivered_by.time}</Typography>
                                                                    ))}
                                                                </React.Fragment>
                                                            }
                                                            <Divider className={classes.topGap} />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}  align='center'>
                                                            {this.displayNotes(notes.Delivery)}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} align='center'>
                                                            {this.displayFiles(files.Delivery, 'Files')}
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                                )}
                                            </ExpansionPanel>
    
                                            {/* Invoice */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.invoice} disabled={expansionPanelDisabled['invoice']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('invoice')} expandIcon={!expansionPanelDisabled['invoice'] && <ExpandMoreIcon />} className={this.state.barColor[3]}>
                                                    <Typography>Invoice</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                    <Grid container spacing={3} justify='center'>
                                                        {!activityActionedBy.invoice_by ? (                                                    
                                                            <Grid item align='center' xs={12}>                                                    
                                                                <FALightIcon size='xlarge' icon='exclamation-circle' noMargin /> 
                                                                {nextStep}
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                        ) : (    
                                                            <Grid item xs={12} align='center'>
                                                                {activityActionedBy.invoice_by &&
                                                                    <React.Fragment>
                                                                        <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                        {activityActionedBy.invoice_by.map(invoice_by => (
                                                                            <Typography key={invoice_by.ppo_log_id} variant="body1">Invoiced by {invoice_by.ppo_log_staff} on {invoice_by.time}</Typography>
                                                                        ))}
                                                                    </React.Fragment>
                                                                }
                                                                <Divider className={classes.topGap} />                                                            
                                                            </Grid>
                                                        )}
                                                        <Grid item xs={12} lg={6} align='center'>
                                                            {this.displayNotes(notes.Invoice)}
                                                        </Grid>                                                    
                                                        <Grid item xs={12} lg={6} align='center'>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} className={this.props.classes.bottomGap}>              
                                                                    <Typography variant="body1">
                                                                        <FALightIcon icon='download' /> Delivery Invoices
                                                                    </Typography>
                                                                </Grid>
                                                                {files.DeliveryInvoice  ? 
                                                                    (<Grid item xs={12} align='left'>
                                                                        <DataTable  
                                                                            config={{
                                                                                key: 'ppo_file_id',
                                                                                noHeader: true,
                                                                                withBorder: true,
                                                                                withBorderRadius: true,
                                                                            }}
                                                                            columns={[
                                                                                {
                                                                                    field: rowData =>   rowData.ppo_file_filename === '' ? 
                                                                                                            <MaterialLink onClick={() => this.handleDeliveryInvoiceOpen(rowData)} className='blueLink'>
                                                                                                                {moment(rowData.ppo_file_delivery_date).format("DD/MM/YYYY")}
                                                                                                            </MaterialLink>
                                                                                                        :
                                                                                                        moment(rowData.ppo_file_delivery_date).format("DD/MM/YYYY"),
                                                                                    alignment: 'right',
                                                                                },
                                                                                {
                                                                                    field: rowData => rowData.ppo_file_filename === '' ? // Missing Invoice
                                                                                    <>
                                                                                        <Grid container>
                                                                                            <Grid item xs>
                                                                                                <FALightIcon style={{color:'#999'}} icon='exclamation-triangle' noMargin /> Outstanding
                                                                                            </Grid>
                                                                                            <Grid item xs align="right">
                                                                                                <MaterialLink 
                                                                                                    onClick={() => this.props.deployConfirmation(`Are you sure an invoice is not required for this delivery?`, `Invoice Not Required?`, () => this.handleDeliveryInvoiceNotRequired(rowData?.ppo_file_id))}
                                                                                                    className='redLink'>
                                                                                                    Not required?
                                                                                                </MaterialLink>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                    </> 
                                                                                    : 
                                                                                    (
                                                                                        rowData.ppo_file_filename === null ? // Invoice Not Required
                                                                                            <>N/A</> 
                                                                                        :
                                                                                            // Display Invoice 
                                                                                            <MaterialLink href={rowData.file_url} target="_blank" className='blueLink'>{rowData.ppo_file_filename}</MaterialLink>
                                                                                    ),
                                                                                }
                                                                            ]}
                                                                            rows={files.DeliveryInvoice}
                                                                            />                                                                            
                                                                    </Grid>)
                                                                :
                                                                    (
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body2">
                                                                            No files available to download
                                                                        </Typography>
                                                                    </Grid> 
                                                                    )
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>                                                                                                                     
                                                            <Divider className={classes.topGap} />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} align='center'>
                                                            {this.displayFiles(files.Proforma, 'Proforma')}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} align='center'>
                                                            {this.displayFiles(files.Invoice, 'Invoices')}
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>  
                                            </ExpansionPanel>
    
                                            {/* Completed */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.completed} disabled={expansionPanelDisabled['completed']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('completed')} expandIcon={!expansionPanelDisabled['completed'] && <ExpandMoreIcon />} className={this.state.barColor[4]}>
                                                    <Typography>Completed</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container>
                                                            <Grid item xs={12} align='center'>   
                                                                {ppo.ppo_completed_datetime && ppo.ppo_completed_datetime !== '0000-00-00 00:00:00' &&        
                                                                    <React.Fragment>   
                                                                        <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                        <Typography variant="body1">Completed on {moment(ppo.ppo_completed_datetime).format("DD/MM/YYYY")}</Typography>
                                                                    </React.Fragment>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                            
                                            {/* Declined */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.declined} disabled={expansionPanelDisabled['declined']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('declined')} expandIcon={!expansionPanelDisabled['declined'] && <ExpandMoreIcon />} className={(ppo.ppo_status === 'Declined' && colors.red) || 'white'}>
                                                    <Typography>Declined</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container justify='center' spacing={3}>
                                                            <Grid item xs={12} align='center'>   
                                                                {activityActionedBy.declined_by && (
                                                                    <React.Fragment>
                                                                        {activityActionedBy.declined_by.map(declined_by => (                         
                                                                            <React.Fragment>          
                                                                                <FALightIcon size='xlarge' icon='times-circle' noMargin /> 
                                                                                <Typography variant="body1">Declined by {declined_by.ppo_log_staff} on {declined_by.time}</Typography>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </React.Fragment>
                                                                )}
                                                            </Grid>  
                                                            <Grid item xs={12} align='center'>
                                                                <Divider className={classes.bottomGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayNotes(notes.Declined)}
                                                            </Grid> 
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                            
                                            {/* Cancelled */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.cancelled} disabled={expansionPanelDisabled['cancelled']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('cancelled')} expandIcon={!expansionPanelDisabled['cancelled'] && <ExpandMoreIcon />} className={(ppo.ppo_status === 'Cancelled' && colors.red) || 'white'}>
                                                    <Typography>Cancelled</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container justify='center' spacing={3}>
                                                            <Grid item xs={12} align='center'>   
                                                                {activityActionedBy.cancelled_by && (
                                                                    <React.Fragment>
                                                                        {activityActionedBy.cancelled_by.map(cancelled_by => (                         
                                                                            <React.Fragment>          
                                                                                <FALightIcon size='xlarge' icon='times-circle' noMargin /> 
                                                                                <Typography variant="body1">Cancelled by {cancelled_by.ppo_log_staff} on {cancelled_by.time}</Typography>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </React.Fragment>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} align='center'>
                                                                <Divider className={classes.bottomGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayNotes(notes.Cancelled)}
                                                            </Grid> 
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                            
                                        </TabContainer>
                                    )}
                                    {currentTab === 1 && (
                                        //Delivery
                                        <TabContainer>
                                            {ppo.parts_order_details && ppo.parts_order_details.map((detail, dtidx) => (
                                                <React.Fragment key={`dt-${dtidx}`}>
                                                    {detail.ppo_detail_part_id > 0 && 
                                                            <ExpansionPanel>
                                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={detail.delivery_panel_colour}>
                                                                    <Grid container spacing={3} style={{color:'white'}}>
                                                                        <Grid item xs={6}>
                                                                            {detail.ppo_detail_part_number + ' - ' + detail.ppo_detail_description}
                                                                        </Grid>
                                                                        <Grid item xs={6} align="right">
                                                                            {detail.ppo_detail_schedule_delivery_status}
                                                                        </Grid>
                                                                    </Grid>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                                                                    <Grid container spacing={3} style={{margin:8, fontSize: '0.8125rem',}}>
                                                                        <Grid item xs={6} style={{backgroundColor:'#f5f5f5', textAlign:'center'}}>
                                                                            <b>Schedule</b>
                                                                        </Grid>
                                                                        <Grid item xs={6} style={{backgroundColor:'#f5f5f5', textAlign:'center'}}>
                                                                            <b>Received Deliveries</b>
                                                                        </Grid>
    
                                                                        <Grid item xs={6} style={{borderRight: '1px solid #f2f2f2'}}>
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={6} style={{textAlign:'right'}}>
                                                                                    <b>Quantity</b>
                                                                                </Grid>
                                                                                <Grid item xs={6} style={{textAlign:'right'}}>
                                                                                    <b>Delivery Date</b>
                                                                                </Grid>
                                                                                {detail.delivery_schedule && detail.delivery_schedule.map((sched, sidx) => (
                                                                                    <React.Fragment key={`sc-${sidx}`}>
                                                                                        <Grid item xs={6} style={{textAlign:'right'}}>
                                                                                            {parseFloat(sched.schedule_quantity).toFixed(2)}
                                                                                        </Grid>
                                                                                        <Grid item xs={6} style={{textAlign:'right'}}>
                                                                                            {sched.schedule_delivery_date === '0000-00-00' ? 'TBC' : moment(sched.schedule_delivery_date).format('DD/MM/YYYY')}
                                                                                        </Grid>
                                                                                    </React.Fragment>
                                                                                ))}
                                                                                <Grid item xs={6} style={{textAlign:'right'}}>
                                                                                    <b>{parseFloat(detail.schedule_total_qty).toFixed(2)}</b>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={6} style={{textAlign:'right'}}>
                                                                                    <b>Quantity</b>
                                                                                </Grid>
                                                                                <Grid item xs={6} style={{textAlign:'right'}}>
                                                                                    <b>Delivery Date</b>
                                                                                </Grid>
                                                                                {detail.received_deliveries && detail.received_deliveries.map((deliveries, ddidx) => (
                                                                                    <React.Fragment key={`dd-${ddidx}`}>
                                                                                        <Grid item xs={6} style={{textAlign:'right'}}>
                                                                                            {deliveries.delivery_received_quantity}
                                                                                        </Grid>
                                                                                        <Grid item xs={6} style={{textAlign:'right'}}>
                                                                                            {moment(deliveries.delivery_received_date).format('DD/MM/YYYY')}
                                                                                        </Grid>
                                                                                    </React.Fragment>
                                                                                ))}
                                                                            </Grid>
                                                                        </Grid>
    
                                                                        <Grid item xs={3} style={{backgroundColor:'#f5f5f5'}}>
                                                                            <b style={{float:'left'}}>Order Total</b> <b  style={{float:'right'}}>{parseFloat(detail.ppo_detail_qty).toFixed(2)}</b>
                                                                        </Grid>
                                                                        <Grid item xs={3} style={{backgroundColor:'#f5f5f5'}}></Grid>
                                                                        <Grid item xs={3} style={{backgroundColor:'#f5f5f5', textAlign:'right'}}>
                                                                            <b>{parseFloat(detail.received_total_qty).toFixed(2)}</b>
                                                                        </Grid>
                                                                        <Grid item xs={3} style={{backgroundColor:'#f5f5f5', textAlign:'right'}}>
                                                                            <b>{detail.scheduleStockStatus}</b>
                                                                        </Grid>
                
                                                                    </Grid>
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                    }
                                                </React.Fragment>
                                            ))}
                                        </TabContainer>
                                    )}
                                    {currentTab === 2 && (
                                        //Notes
                                        <TabContainer>
                                        <Grid container spacing={3}>
                                            {ppo.notes && 
                                                ppo.notes.map(note => (
                                                    <Grid item xs={12} key={note.ppo_note_id}>
                                                        <Card>
                                                            <CardHeader
                                                                avatar={<Avatar alt={`${note.staff.staff_first_name} ${note.staff.staff_last_name}`} src={note.staff.staff_profile_photo} />}
                                                                title={note.ppo_note_staff}
                                                                subheader={`${moment(note.ppo_note_date_time).format("DD/MM/YYYY")} (${note.ppo_note_type})`}
                                                            />
                                                            <CardContent>
                                                                <Typography component="p">
                                                                    {note.ppo_note_text && note.ppo_note_text.split('\n').map((item, key) => {
                                                                        return <span key={key}>{item}<br/></span>
                                                                    })}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                ))
                                            }
                                            {!ppo.notes.length > 0 && (
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader                                                       
                                                            title='No notes'
                                                        />
                                                        <CardContent>
                                                            <Typography component="p">
                                                                There are no notes to show for this parts order
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            )}
                                            </Grid>
                                        </TabContainer>
                                    )}
                                    {currentTab === 3 && (
                                        //Activity Log
                                        <Card>
                                            <CardContent>
                                                <TabContainer>
                                                    <VerticalTimeline layout="1-column">
                                                        {ppo.activity && ppo.activity.map(log => (
                                                            <VerticalTimelineElement key={log.ppo_log_id}
                                                                className="vertical-timeline-element--work"
                                                                date={moment(log.ppo_log_datetime_added).format("DD/MM/YYYY") + ' - ' + log.ppo_log_staff}
                                                                iconStyle={{ backgroundColor: log.color, color: 'white' }}
                                                                icon={<FASolidIcon icon={log.icon} style={{color:'white'}} />}
                                                            >
                                                                <h3 className="vertical-timeline-element-title">{log.ppo_log_type}</h3>
                                                                {log.ppo_log_text && log.ppo_log_text.split('\n').map((item, key) => {
                                                                    return <span key={key}>{item}<br/></span>
                                                                })}
                                                            </VerticalTimelineElement>
                                                        ))}
                                                    </VerticalTimeline>
                                                </TabContainer>
                                            </CardContent>
                                        </Card>
                                    )}
                                    <Divider style={{marginBottom:20}} />
                                    <div className={`buttonRow ${classes.btnRow}`}>
                                        <Grid container justify='center' spacing={1}>
                                            <Grid item xs={12} md={2}>
                                            <BackButton props={this.props} />
                                            </Grid>
                                                {ppo.ppo_status !== 'Approval' && (                                            
                                                    <Grid item xs={12} md={2}>
                                                        <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleEmailOpen}><FALightIcon icon='envelope' buttonPrimary />Email</Button>
                                                    </Grid>
                                                )}                                        
                                                <Grid item xs={12} md={2}>
                                                    <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleNotesOpen}>
                                                        <FALightIcon icon='plus' buttonPrimary />Notes
                                                    </Button>
                                                </Grid>        
                                                {ppo.locked ?
                                                    <Grid item xs={12} md={3}>
                                                        <Button variant="contained" className={classes.btnRowBtn} color="secondary" onClick={()=>{}} disabled>
                                                            <AllIcon icon={icons.lock} buttonPrimary /> Locked
                                                        </Button>
                                                    </Grid>:     
                                                    <>                           
                                                        {ppo.ppo_status !== 'Declined' && ppo.ppo_status !== 'Cancelled' &&
                                                            <React.Fragment>
                                                                {ppo.ppo_status !== 'Approval' && (                                                 
                                                                    <Grid item xs={12} md={3}>
                                                                        <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleFilesOpen}><FALightIcon icon='plus' buttonPrimary />Files</Button>
                                                                    </Grid>
                                                                )}
                                                                {ppo.ppo_status === 'Delivery' && (
                                                                    <Grid item xs={12} md={5} lg={4}>
                                                                        <Link to={`/parts-order/delivery/change-schedule/${ppo.ppo_id}`} className={classes.link}>
                                                                            <Button variant="contained" className={classes.btnRowBtn} color="primary"><FALightIcon icon='pen' buttonPrimary /> Change Delivery Schedule</Button>
                                                                        </Link>
                                                                    </Grid>
                                                                )}
                                                                {ppo.ppo_status !== 'Completed' && (
                                                                    <Grid item xs={12} md={5} lg={4}>
                                                                        <Link to={`/parts-order/other-items/update/${ppo.ppo_id}`} className={classes.link}>
                                                                            <Button variant="contained" className={classes.btnRowBtn} color="primary"><FALightIcon icon='pen' buttonPrimary /> Amend Other Items</Button>
                                                                        </Link>
                                                                    </Grid>
                                                                )}
                                                                {ppo.ppo_status !== 'Completed' && !deliveryCommenced && (
                                                                    <Grid item xs={12} md={2}>
                                                                        <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleCancelOpen}><FALightIcon icon='ban' buttonPrimary /> Cancel</Button>
                                                                    </Grid>
                                                                )}
                                                            </React.Fragment>
                                                        }
                                                    </>
                                                }
                                        </Grid>
                                    </div>
                                    </React.Fragment>
                                    )}
                                </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            {!isLoading && (
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Typography variant="h5" gutterBottom>
                                                Previous Revisions
                                            </Typography>
                                            {ppo.revisions && ppo.revisions.length > 0 ?
                                                ppo.revisions.map((rev, idx) => (
                                                    <React.Fragment key={idx}>
                                                        <Link to="" onClick={(e) => { e.preventDefault(); this.downloadPPO(rev.ppo_id)}} className='blueLink'>
                                                            {rev.full_reference || '-'}
                                                        </Link>
                                                        <br></br>
                                                    </React.Fragment>
                                                ))
                                            :
                                                <Typography variant="body1" gutterBottom>
                                                    0 previous Parts orders
                                                </Typography>
                                            }
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Typography variant="h5" gutterBottom>
                                                Previous Delivery Schedules
                                            </Typography>
                                            {ppo.delivery_revisions && ppo.delivery_revisions.length > 0 ?
                                                ppo.delivery_revisions.map((rev, ridx) => (
                                                    <React.Fragment key={`v-${ridx}`}>
                                                        <Link to="" onClick={(e) => { e.preventDefault(); this.downloadDS(rev.ppo_id, rev.schedule_version)}} className='blueLink'>
                                                            Delivery Schedule (v{rev.schedule_version})
                                                        </Link>
                                                        <br></br>
                                                    </React.Fragment>
                                                ))
                                            :
                                                <Typography variant="body1" gutterBottom>
                                                    0 previous Delivery Schedules
                                                </Typography>
                                            }
                                    </PaddedPaper>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                
                    {/* NOTES */}
                    <Dialog 
                        open={this.state.dialogNotesOpen} 
                        onClose={this.handleNotesClose} 
                        fullWidth={true} 
                        maxWidth="sm"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title">Add Notes</DialogTitle>
                        <DialogContent>
                            <FormControl error={formErrors && formErrors['noteType'] && true} fullWidth margin="normal">
                                <AutoCompleteSelect
                                    options={[
                                        {value: 'Approval', label: 'Approval'},
                                        {value: 'Confirmation', label: 'Confirmation'},
                                        {value: 'Delivery', label: 'Delivery'},
                                        {value: 'Invoice', label: 'Invoice'},
                                        {value: 'Declined', label: 'Declined'},
                                        {value: 'Cancelled', label: 'Cancelled'}
                                    ]}
                                    label='Note Type *'
                                    value={formData.noteType}
                                    onChange={this.handleSelectChange('noteType')}
                                    error={formErrors && formErrors['noteType'] && true}
                                    errorText={formErrors && formErrors['noteType'] && formErrors['noteType']}
                                />
                            </FormControl>
                            <Textarea
                                id="notesText"
                                name="notesText"
                                label="Notes *"
                                value={this.state.formData.notesText}
                                rows={5}
                                error={formErrors && formErrors['notesText']}
                                onChange={this.handleChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleNotesClose} variant="outlined" color="default">Cancel</Button>
                            <Button onClick={this.handleNotesSuccess} autoFocus variant="contained" color="primary">Save</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarNotesOpen}
                        onClose={this.handleNotesSnackbarClose}
                        message='You have successfully added new notes'
                    />
    
                    {/* FILES */}
                    <Dialog 
                        open={this.state.dialogFilesOpen} 
                        onClose={this.handleFilesClose} 
                        fullWidth={true} 
                        maxWidth="sm"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title">Add Files</DialogTitle>
                        <DialogContent>
                            <FormControl error={formErrors && formErrors['fileType'] && true} fullWidth margin="normal">
                                <AutoCompleteSelect
                                    options={[
                                        {value: 'Confirmation', label: 'Confirmation'},
                                        {value: 'Delivery', label: 'Delivery Note'},
                                        {value: 'Invoice', label: 'Invoice'}
                                    ]}
                                    label='File Type *'
                                    value={formData.fileType}
                                    onChange={this.handleSelectChange('fileType')}
                                    error={formErrors && formErrors['fileType'] && true}
                                    errorText={formErrors && formErrors['fileType'] && formErrors['fileType']}
                                />
                            </FormControl>
                            {this.state.showInvoiceType && 
                                <React.Fragment>
                                    <FormControl error={formErrors && formErrors['invoiceType'] && true} fullWidth margin="normal">
                                        <RadioGroup
                                            name="invoiceType"
                                            value={this.state.formData.invoiceType}
                                            onChange={this.handleChange}
                                            row
                                        >
                                            <FormControlLabel
                                                value="Invoice"
                                                control={<Radio color="primary" />}
                                                label="Invoice"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="Delivery Invoice"
                                                control={<Radio color="primary" />}
                                                label="Delivery Invoice"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="Pro forma"
                                                control={<Radio color="primary" />}
                                                label="Pro forma"
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                        {formErrors && formErrors['invoiceType'] && <FormHelperText>{formErrors['invoiceType']}</FormHelperText>}
                                    </FormControl>
                                    {this.state.showDeliveryDates &&
                                        <FormControl error={formErrors && formErrors['deliveryFileID'] && true} fullWidth margin="normal">
                                            <AutoCompleteSelect
                                                options={this.state.outstandingDeliveryInvoices} 
                                                label='Delivery Date'
                                                value={this.state.formData.deliveryFileID}
                                                onChange={this.handleSelectChange('deliveryFileID')}
                                                error={formErrors && formErrors['deliveryFileID'] && true}
                                                errorText={formErrors && formErrors['deliveryFileID'] && formErrors['deliveryFileID']}
                                            />
                                        </FormControl>
                                    }
                                </React.Fragment>
                            }
                            <DragFileInput
                                id="file"
                                name="file"
                                label="File *"
                                file={formData.file}
                                errorText={formErrors && formErrors['file']}
                                onChange={this.handleFileChange}
                                cancelOnClick={this.clearFile}
                                emptyText='No file selected'
                            />
                        </DialogContent>
                        <DialogActions>
                            <Typography variant="subtitle2" style={{flex: 1}}>
                                <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                            </Typography>
                            <Button onClick={this.handleFilesClose} variant="outlined" color="default">Cancel</Button>
                            <Button onClick={this.handleFilesSuccess} autoFocus variant="contained" color="primary">Upload</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarFilesOpen}
                        onClose={this.handleFilesSnackbarClose}
                        message='You have successfully added a new file'
                    />
                    {/* CANCEL */}
                    <Dialog 
                        open={this.state.dialogCancelOpen} 
                        onClose={this.handleCancelClose} 
                        fullWidth={true} 
                        maxWidth="sm"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title" color="secondary">You Are About To <span style={{color:colors.red}}>Cancel</span> This Parts Order</DialogTitle>
                        <DialogContent>
                            <Typography variant="body1">Please feel free to leave any notes relating to this parts order:</Typography>
                            <Textarea
                                id="cancellationNotes"
                                name="cancellationNotes"
                                label="Notes"
                                value={this.state.formData.cancellationNotes}
                                rows={5}
                                error={formErrors && formErrors['cancellationNotes']}
                                onChange={this.handleChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCancelClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={this.handleCancelSuccess} color="secondary" autoFocus variant="contained">Cancel</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarCancelOpen}
                        onClose={this.handleCancelSnackbarClose}
                        message='You have successfully cancelled this parts order'
                    />
                    {/* EMAIL */}
                    <Dialog 
                        open={this.state.dialogEmailOpen} 
                        onClose={this.handleEmailClose} 
                        fullWidth={true} 
                        maxWidth="sm"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title" color="secondary">Email Supplier</DialogTitle>
                        <DialogContent>
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteMultiSelect
                                    name="emailTo"
                                    options={this.state.supplierContactList}
                                    label='Email To *'
                                    value={formData.emailTo}
                                    onChange={this.handleMultiSelectChange('emailTo')}
                                    error={formErrors && formErrors['emailTo'] && true}
                                    errorText={formErrors && formErrors['emailTo']}
                                />
                            </FormControl>
                            <FormControl error={formErrors && formErrors['emailTemplate'] && true} fullWidth margin="normal">
                                <AutoCompleteSelect
                                    options={this.state.templateList} 
                                    label='Email Template'
                                    value={this.state.formData.emailTemplate}
                                    onChange={this.handleSelectChange('emailTemplate')}
                                    error={formErrors && formErrors['emailTemplate'] && true}
                                    errorText={formErrors && formErrors['emailTemplate'] && formErrors['emailTemplate']}
                                />
                            </FormControl>
                            <Textarea
                                id="emailText"
                                name="emailText"
                                label="Email Text *"
                                value={this.state.formData.emailText}
                                rows={5}
                                error={formErrors && formErrors['emailText']}
                                onChange={this.handleChange}
                            />
                            <br></br><br></br>
                            <Typography variant="subtitle1"><FALightIcon icon='paperclip' /> Attachments Files</Typography>
                            {ppo.attachments && ppo.attachments['Parts Orders'] &&
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteMultiSelect
                                        name="emailPartsOrders"
                                        options={ppo.attachments['Parts Orders']}
                                        label='Parts Orders'
                                        value={formData.emailPartsOrders}
                                        onChange={this.handleMultiSelectChange('emailPartsOrders')}
                                        error={formErrors && formErrors['emailPartsOrders'] && true}
                                        errorText={formErrors && formErrors['emailPartsOrders']}
                                    />
                                </FormControl>
                            }
                            {ppo.attachments && ppo.attachments['Schedules'] &&
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteMultiSelect
                                        name="emailSchedules"
                                        options={ppo.attachments['Schedules']}
                                        label='Schedules'
                                        value={formData.emailSchedules}
                                        onChange={this.handleMultiSelectChange('emailSchedules')}
                                        error={formErrors && formErrors['emailSchedules'] && true}
                                        errorText={formErrors && formErrors['emailSchedules']}
                                    />
                                </FormControl>
                            }
                            {ppo.attachments && ppo.attachments['Drawings'] &&
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteMultiSelect
                                        name="emailDrawings"
                                        options={ppo.attachments['Drawings']}
                                        label='Drawings'
                                        value={formData.emailDrawings}
                                        onChange={this.handleMultiSelectChange('emailDrawings')}
                                        error={formErrors && formErrors['emailDrawings'] && true}
                                        errorText={formErrors && formErrors['emailDrawings']}
                                    />
                                </FormControl>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleEmailClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={this.handleEmailSuccess} color="primary" autoFocus variant="contained">Send Email</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarEmailOpen}
                        onClose={this.handleEmailSnackbarClose}
                        message='You have successfully sent the email'
                    />
    
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarCustomOpen}
                        onClose={this.handleCustomSnackbarClose}
                        message={this.state.succesMsg}
                    />

                    {/* DELIVERY INVOICE */}
                    <Dialog 
                        open={this.state.dialogDeliveryInvoiceOpen} 
                        onClose={this.handleDeliveryInvoiceClose} 
                        fullWidth={true} 
                        maxWidth="sm"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title">Upload Delivery Invoice for {moment(this.state.formData.fileDate).format("DD/MM/YYYY")}</DialogTitle>
                        <DialogContent>
                            <DragFileInput
                                id="file"
                                name="file"
                                label="File *"
                                file={formData.file}
                                errorText={formErrors && formErrors['file']}
                                onChange={this.handleFileChange}
                                cancelOnClick={this.clearFile}
                                emptyText='No file selected'
                            />
                        </DialogContent>
                        <DialogActions>
                            <Typography variant="subtitle2" style={{flex: 1}}>
                                <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                            </Typography>
                            <Button onClick={this.handleDeliveryInvoiceClose} variant="outlined" color="default">Cancel</Button>
                            <Button onClick={this.handleDeliveryInvoiceSuccess} autoFocus variant="contained" color="primary">Upload</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            );
            
        }
        
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state)),
    deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewPartsOrder));