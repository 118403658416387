import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import StaffMember from 'Components/Layout/Misc/StaffMember';
import { ReduxAvatar } from 'Components/Common/Badges/Avatar';
import {deployPopNotification, closePopNotifications} from 'Actions/PopNotification/PopNotification'

const styles = theme => ({
    list: {
        paddingTop: 0,
        paddingBottom: 0,
    },
})

const initialState = {
    anchorEl: null
}

class AdminUser extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    
    handleClick = event => {
        this.props.closePopNotifications();
        this.setState({ anchorEl: event.currentTarget });
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    }

    render() {
        const { anchorEl } = this.state;
        const { classes } = this.props;
        let user = null;
        let team = null;
        if(this.props.notifications && !_.isEmpty(this.props.notifications.status) && this.props.loggedInStaff && this.props.loggedInStaff.id > 0) {
            let staffId = this.props.loggedInStaff.id;
            let status = this.props.notifications.status;
            user = status[staffId];
            team = _.filter(status, function (el) { return (el.status !== 0 && el.id !== staffId) || el.troll === 1 });
        }
        return (
            user && (
                <React.Fragment>
                    <IconButton
                        aria-controls='team-menu'
                        aria-haspopup="true"
                        size="small"
                        onClick={this.handleClick}
                    >
                        {ReduxAvatar(user, {size:'md'})}
                    </IconButton>
                    <Menu
                        classes={{list: classes.list}}
                        id="team-menu"
                        anchorEl={anchorEl}
                        className="dd-menu"
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                    >
                    {(!_.isEmpty(team) && (
                        <List disablePadding>
                            {_.map(team, (staff, idx) => (
                                <StaffMember
                                    key={idx}
                                    border={true}
                                    staff={staff}
                                />
                            ))}
                        </List>
                    )) || (
                        <Grid container alignItems='center' className='sm-menu-text'>
                            <Grid item xs={12}>
                                <Typography component="div" variant="caption">
                                    No other users are online
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Menu>
               </React.Fragment>
            )
        )
    }
}

function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff,
        notifications: state.notifications
    };
}

const mapDispatchToProps = (dispatch) => {return {closePopNotifications: () => dispatch(closePopNotifications())}};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminUser));