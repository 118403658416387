import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import ThreadAvatar from 'Components/Messages/ThreadAvatar';
import ThreadContent from 'Components/Messages/ThreadContent';
import ThreadDetails from 'Components/Messages/ThreadDetails';
import ThreadFile from 'Components/Messages/ThreadFile';
import ThreadImage from 'Components/Messages/ThreadImage';
import ThreadNudge from 'Components/Messages/ThreadNudge';
import ThreadOpenGraph from 'Components/Messages/ThreadOpenGraph';

const ThreadMessage = ({classes, myMessage, from, msg, fileName, fileSize, fileUrl, img, og, read, sent, to}) => {

    const staff = useSelector(state => state.notifications.status),
          align = myMessage ? 'right' : 'left',
          hideMessage = ((og?.og === "email" && msg !== og.email) || (og?.og === "url" && msg !== og.url) || _.isEmpty(msg)),
          marginLeft = myMessage ? 7.5 : 1,
          marginRight = !myMessage ? 7.5 : 1,
          nudge = msg === "nudge",
          messageClass = myMessage ? classes.messageSent : classes.messageReceived,
          recipient = myMessage ? staff[to].name : staff[from].name;

    return (
        <Grid item xs={12} align={align}>
            <Grid container alignItems='center'>
                {!myMessage && staff?.[from] && (
                    <ThreadAvatar
                        staff={staff[from]}
                    />
                )}
                <Grid item xs>
                    {!_.isEmpty(img) && (
                        <Box ml={marginLeft} mr={marginRight} mt={2} mb={0.5} className={classes.richContent}>
                            <ThreadImage
                                classes={classes}
                                image={img}
                                message={msg}
                            />
                        </Box>
                    )}
                    {!_.isEmpty(og?.og) && (
                        <Box ml={marginLeft} mr={marginRight} mt={2} mb={0.5} className={classes.richContent}>
                            <ThreadOpenGraph
                                openGraph={og}
                            />
                        </Box>
                    )}
                    {!_.isEmpty(fileUrl) && (
                        <Box ml={marginLeft} mr={marginRight} mt={2} mb={0.5} className={classes.richContent}>
                            <ThreadFile
                                classes={classes}
                                fileName={fileName} 
                                fileSize={fileSize} 
                                fileUrl={fileUrl}
                            />
                        </Box>
                    )}
                    {(nudge && (
                        <Box ml={marginLeft} mr={marginRight} mt={2} mb={0.5} className={classes.richContent}>
                            <ThreadNudge 
                                classes={classes}
                                myNudge={myMessage}
                                name={recipient}
                            />
                        </Box>
                    )) || (!hideMessage && (
                        <Box mt={2} mr={2} ml={1} p={2} className={messageClass}>
                           <ThreadContent
                                classes={classes}
                                message={msg}
                                myMessage={myMessage}
                            />
                        </Box>
                    ))}
                    <Box mr={2} ml={1}>
                        <ThreadDetails
                            myMessage={myMessage}
                            read={read}
                            sent={sent}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ThreadMessage;