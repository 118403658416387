import React                            from 'react';
import { connect }                      from 'react-redux';
import API                              from 'API';
import DataTable                        from 'Components/Common/DataTables/CiDataTable';
import FALightIcon                      from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper                      from 'Components/Common/Paper/PaddedPaper';
import { FullScreenDialog }             from 'Components/Common/Dialogs/FullScreenDialog';
import { deployDialog, closeDialog }    from 'Actions/Dialog/Dialog';
import FormBuilder                      from 'Components/Common/Forms/FormBuilder/FormBuilder';
import { CLENAWARE_RED, CLENAWARE_GREEN } from 'Constants';
import {
    Button,
    TextField,
    Grid,
    Typography
} from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { forEach, find, map } from 'lodash';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';
import _ from 'lodash';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import ReusableElementsDialog from 'Components/Forms/Misc/ReusableElementsDialog';

const initialState = () => ({
    searchResults: [],
    access: {
        manageForms: false,
        viewForms: false
    },
    dataLoading: true,
    searchCriteria: {
        areaId: null,
        subCatId: null,
        searchString: '',
        systemCatId: null
    },
    categories: [],
    areasList: [],
    categoriesList: [],
    systemCategories: [],
})

class FormSearch extends React.Component {
    constructor(props) {
        super(props);
        this.getInitialState    = getInitialState.bind(this);
        this.persistenceId      = 'Forms:Search';
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.clearPageState     = clearPageState.bind(this);
        this.state              = this.getInitialState(initialState());   
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.location?.search !== prevProps.location?.search){
            this.loadComponentData();
        }
    }

    loadComponentData = () => {

        // Get the url query params - user may have come from a link with filter
        const searchParams  = new URLSearchParams(this.props.location?.search);
        const areaName      = searchParams.get('area');

        Promise.all([
            API.get(`/forms/configuration/categories`),
            API.get(`/forms/configuration/categories/system`)
        ])
        .then(([result, sysCatRes]) =>  {
            let categories          = result.data,
                areasList           = [],
                areaId              = null,
                systemCategories    = [];

            // Create Areas List for Select
            forEach(result.data, (area) => {
                if(areaName == area.nm){ // If we have a query param for area, set the areaId
                    areaId = area.id;
                }
                areasList.push({
                    label: area.nm,
                    value: area.id
                });
            })
            // System Category List
            _.forEach(sysCatRes.data, (sc) => {
                systemCategories.push({
                    label: sc.nm,
                    options: _.map(sc.sub, (subCategory) => ({
                        label: subCategory.nm,
                        value: subCategory.id
                    }))
                })
            })

            this.setState({
                categories,
                areasList,
                searchCriteria: {
                    ...this.state.searchCriteria,
                    areaId
                },
                systemCategories
            }, () => {

                this.getSearchData();
                this.checkAccess();
            })
        });
    }

    getSearchData = () => {
        this.setState({
            dataLoading: true
        }, () => {
            API.get('/forms/configuration', { 
                params: this.state.searchCriteria
            })
            .then(result => {
                this.setState({
                    dataLoading: false
                });
                if(result.data) {
                    this.setState({
                        searchResults: result.data
                    });
                } 
            });
        });
    }

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/forms-search:manage-forms'), 
            API.get('/staff/my/access/check/forms-search:view-form'),
        ])
        .then(([manageRes, viewRes]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    manageForms: manageRes.data && manageRes.data.has_access,
                    viewForms: viewRes.data && viewRes.data.has_access
                }
            })
        });
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                searchString: event.target.value
            }
        },
        () => {
            this.savePageState();
            this.getSearchData();
        });
    }

    handleCategoryChange = (type, value) => {

        if(type == 'area'){

            let categoriesList = [];

            // Find area in categories array
            let area = find(this.state.categories, (area) => area.id == value)

            // Create categoriesList to dynamically populate the select
            forEach(area.cat, (category) => {
                categoriesList.push({
                    label: category.nm,
                    options: map(category.sub, (subCategory) => ({
                        label: subCategory.nm,
                        value: subCategory.id
                    }))
                })
            })

            this.setState({
                searchCriteria: {
                    ...this.state.searchCriteria,
                    areaId: value,
                    subCatId: null,
                },
                categoriesList
            },
            () => {
                this.savePageState();
                this.getSearchData();
            });
        }
        else if(type == 'subCat'){
            this.setState({
                searchCriteria: {
                    ...this.state.searchCriteria,
                    subCatId: value,
                }
            },
            () => {
                this.savePageState();
                this.getSearchData();
            });
        }
        else if(type == 'sysCat'){
            this.setState({
                searchCriteria: {
                    ...this.state.searchCriteria,
                    systemCatId: value,
                }
            },
            () => {
                this.savePageState();
                this.getSearchData();
            });
        }
    }

    resetSearch = () => {
        this.setState({ searchCriteria: {...initialState().searchCriteria} }, () =>{ this.savePageState();  this.getSearchData()} );
    }

    openForm = (id = null) => {
        this.props.deployDialog(
            <FullScreenDialog 
                open={1} 
                onClose={this.props.closeDialog} 
                title={`${id ? 'Update': ' Add'} Form`} 
                withMargin
                style={{backgroundColor: '#fafafa'}}
                content={
                    <FormBuilder 
                        onClose={this.props.closeDialog}
                        formId={id}
                        refresh={this.loadComponentData}
                        history={this.props.history}
                    />
                }
            />, 
            "", 
            null, 
            'fs'
        )
    }

    openReusableElements = (id = null) => {
        this.props.deployDialog(
            <SmallFormDialog
                open={1} 
                title="Elements with Reusable Results"
                onClose={this.props.closeDialog}
                content={
                    <ReusableElementsDialog formConfigId={id} />
                }
                maxWidth='sm'
            />,
            "",
            null,
            'sm'
        )
    }

    render() {
        const { access, searchCriteria, areasList, categoriesList, gridPagination, systemCategories  } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Search Forms
                    </Typography>
                </Grid> 
                <Grid item xs={12} lg={6}>
                    <PaddedPaper>
                        <form noValidate autoComplete="off">
                            <Grid container item spacing={3}>
                                <Grid item xs={12}>
                                    <AutoCompleteSelect 
                                        isGrouped
                                        options={systemCategories} 
                                        label='Type of Form'
                                        fullWidth
                                        value={searchCriteria.systemCatId}
                                        noClear
                                        onChange={(v) => this.handleCategoryChange('sysCat', v.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AutoCompleteSelect 
                                        options={areasList} 
                                        label='Area'
                                        fullWidth
                                        value={searchCriteria.areaId}
                                        noClear
                                        onChange={(v) => this.handleCategoryChange('area', v.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AutoCompleteSelect 
                                        isGrouped
                                        options={categoriesList} 
                                        label='Sub Category'
                                        fullWidth
                                        value={searchCriteria.subCatId}
                                        noClear
                                        disabled={!searchCriteria.areaId}
                                        onChange={(v) => this.handleCategoryChange('subCat', v.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="nameSearch" label="Form Name" fullWidth value={searchCriteria.searchString} onChange={this.onSearchStringChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className='buttonRow'>
                                        <Button onClick={this.resetSearch}
                                                variant="outlined"
                                                color="default"                                       
                                                >
                                            <FALightIcon icon='undo' button />
                                            Reset Search
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </form> 
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} align="right">
                    {access.manageForms && (
                        <Button 
                            variant="contained" 
                            color="primary"
                            onClick={() => this.openForm()}
                        >
                            <FALightIcon icon='plus' button color="white" /> Add Form
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key: 'id',
                                pagination: true,
                                persistenceId: this.persistenceId,
                                alternatingRowColours: true,
                                isLoading: this.state.dataLoading,
                                responsiveImportance: true
                            }}
                            columns={[
                                {
                                    heading: 'Type of Form',
                                    field: rowData => rowData.sysCat,
                                    important: true,
                                    main: true,
                                    hideInResponsiveDialog: true,
                                },
                                {
                                    heading: 'Area',
                                    field: rowData => rowData.area,
                                    important: true,
                                    main: true,
                                    hideInResponsiveDialog: true,
                                },
                                {
                                    heading: 'Category',
                                    field: rowData => rowData.cat,
                                    important: true,
                                    main: true,
                                    hideInResponsiveDialog: true,
                                },
                                {
                                    heading: 'Sub Category',
                                    field: rowData => rowData.subCat,
                                    important: true,
                                    main: true,
                                    hideInResponsiveDialog: true,
                                },
                                {
                                    heading: 'Form Name',
                                    field: rowData => rowData.nm,
                                    important: true,
                                    main: true,
                                    hideInResponsiveDialog: true,
                                },
                                {
                                    heading: 'Assoc. Parts',
                                    field: rowData => rowData.assocParts,
                                    important: true,
                                    main: true,
                                    hideInResponsiveDialog: true,
                                    alignment: 'center',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Assoc. Docs',
                                    field: rowData => rowData.assocDocs,
                                    important: true,
                                    main: true,
                                    hideInResponsiveDialog: true,
                                    alignment: 'center',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Version',
                                    field: rowData => 'v'+rowData.v,
                                    important: true,
                                    sizeToContent: true,
                                    alignment: 'center',
                                },
                                {
                                    heading: 'Status',
                                    field: rowData => rowData.st,
                                    sizeToContent: true,
                                    hideInResponsiveDialog: true,
                                    cellProps: rowData => ({
                                        style: {
                                            color: rowData?.st == 'Active' ? CLENAWARE_GREEN  : CLENAWARE_RED
                                        }
                                    })
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Reusable Elements', icon: 'recycle', onClick: () => { this.openReusableElements(rowData.id) }, disabled: !rowData?.reusableElements },
                                            {name: 'Related Forms ' + '('+parseFloat(rowData.relatedForms)+')', icon: 'memo', link: '/forms/related/' + rowData.id, color: parseFloat(rowData.relatedForms) > 0 && CLENAWARE_GREEN },
                                            {name: 'Update', icon: 'pencil-alt', onClick: () => { this.openForm(rowData.id) }, disabled: !access.manageForms, state: {searchCriteria} },
                                            {name: 'View', icon: 'search', link: '/forms/view/' + rowData.id, disabled: !access.viewForms, state: {searchCriteria}}
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deployDialog: (content, title, variant, size='md')  => {dispatch(deployDialog(content, title, variant, size))},
    closeDialog: ()                                     => {dispatch(closeDialog())},
    clearPersistence: ()                                => dispatch(clearPersistence()),
    setPersistence: (key, state)                        => dispatch(setPersistence(key, state))
});

export default connect(mapStateToProps, mapDispatchToProps)(FormSearch);