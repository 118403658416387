import React, {Component}  from 'react';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import API from 'API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import { Button, FormControl, Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import BackButton from 'Components/Common/Buttons/BackButton';
import {colors} from 'Helpers/ColourHelper';

const initialState = { 
    formData: {
        categories: [],
    },
    product: {},
    categoryList: [],
    formErrors: [],
    confirmationOpen: false,
    isLoading: false
}

class UpdateEcommerceProduct extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.loadInitialData(this.props.match.params.id);
    }

    loadInitialData = (ecomProdId) => {
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([ 
                API.get(`/e-commerce/products/${ecomProdId}`),
                API.get(`/e-commerce/productCategories`)
            ])
            .then(([productRes, categoryRes]) =>  {
                let categoryList = _.map(categoryRes.data, el => {
                    return _.assign({
                        value: el.ecom_cat_id,
                        label: el.ecom_cat_name
                    });
                });
                let categories = [];
                productRes.data.categories.forEach(cat => {
                    categories.push(cat.ecom_cat_id);
                });

                this.setState({
                    formData: {
                        ...this.state.formData,
                        categories
                    },
                    product: productRes.data,
                    categoryList,
                    isLoading: false,
                });
            });
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            let ecomProdId = this.state.product.ecom_prod_id;
            API.post(`/e-commerce/products/${ecomProdId}`, this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...initialState
                    });
                    this.props.deploySnackBar("success", "You have successfully updated this e-commerce product")
                    this.loadInitialData(ecomProdId);
                }
                this.props.scrollToTop();
            });
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }
       
    render() {
        const { formErrors, formData, product, categoryList } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Update E-Commerce Product
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <form noValidate autoComplete="off">
                                <TextField
                                    name="part"
                                    label="Part"
                                    value={product?.part?.part_description}
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteMultiSelect 
                                        options={categoryList} 
                                        label='Categories'
                                        value={formData.categories}
                                        onChange={this.handleMultiSelectChange('categories')}
                                        error={formErrors && formErrors['categories'] && true}
                                        errorText={formErrors && formErrors['categories']}
                                    />
                                </FormControl>
                            </form>
                            <div className='buttonRow'>
                                <BackButton props={this.props} />
                                <Button onClick={this.handleConfirmationOpen}
                                        variant="contained"
                                        color="primary"
                                        disabled={this.state.isLoading}
                                       >
                                    Update
                                </Button>
                            </div>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Update E-Commerce Product?"
                    message="Are you sure you want to update this e-commerce product?"
                />
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, msg) => {
            dispatch(deploySnackBar(variant, msg))
        }
    }
}

export default connect(null, mapDispatchToProps)(UpdateEcommerceProduct);