import React from 'react';
import { Grid } from '@material-ui/core/';
import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher';
import Marketing from './MarketingTabs/Marketing';
import Promotions from './MarketingTabs/Promotions';
import PromotionOrders from './MarketingTabs/PromotionOrders';
import PromotionQuotes from './MarketingTabs/PromotionQuotes';

export default function(props){
    return (
        <Grid container xs={12} style={{width: '100%'}}>
            <Grid item xs={12}>
                <TabBarSwitcher
                    tabs={[
                            {
                                label:   'Marketing',
                                content: <Marketing  {...props}/>
                            },
                            {
                                label:   'Email Campaigns',
                                content: <Promotions  {...props}/>
                            },
                            {
                                label:   'Orders',
                                content: <PromotionOrders {...props}/>
                            },
                            {
                                label:   'Quotes',
                                content: <PromotionQuotes {...props}/>
                            }
                        ]}
                />
            </Grid>
        </Grid>
    )
}


