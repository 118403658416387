import React from 'react';

import _ from 'lodash';

import { colors } from 'Helpers/ColourHelper';

export function getPercentage(value, total, decimal=0, retNaN='-') {
    let i = ( parseFloat(value) / parseFloat(total) ) * 100;
    if (_.isNaN(i)) return retNaN;

    return i.toFixed(decimal);
}

export function currency(_value, float=3, redNegative=true, greenPositive=false, positivePlus= false) {
    let value = _.isNaN(parseFloat(_value)) ? 0 : parseFloat(_value);
    return <span style={{color: ((redNegative && value < 0) && colors.red) || ((greenPositive && value > 0) && colors.green) }}>{`${value < 0 ? '-' : (positivePlus ? '+':'')}£${Math.abs(value).toFixed(float)}`}</span>;
}

export function colorNumbers(value, disableGreen=true){
    return <span style={{color: parseFloat(value) < 0 ? colors.red : (disableGreen ? colors.black : colors.green)}}>{value}</span>;
}