import React, { Component } from 'react';
import { connect }          from 'react-redux';
import API                  from 'API';
import _                    from 'lodash';
import moment               from 'moment';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import DataTable          from 'Components/Common/DataTables/CiDataTable';
import DatePicker         from 'Components/Common/DatePickers/DatePicker';
import DragFileInput      from 'Components/Common/Inputs/DragFileInput';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import PDFViewer          from 'Components/Common/PDFViewer/PDFViewer';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';

import { Button, Dialog, DialogActions, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControl, Grid, InputAdornment, List, ListItem, ListItemSecondaryAction, 
    ListItemText, Typography, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { colors }        from 'Helpers/ColourHelper';
import icons             from 'Helpers/IconHelper';
import { pdfFromBase64 } from "Helpers/PDFHelper";
import { downloadS3File } from 'Functions/MiscFunctions';
import EmailDialog from 'Components/Common/Email/EmailDialog/EmailDialog';

const initialState = {
    isLoading:  true,
    data:       null,
    checked:    [],
    collectionApproval: {
        open:         false,
        id:           null,
        date:         null,
        qty:          1,
        consignments: [],
        parcelNumber: null,
        file:         null,
    },
    collectionComplete: {
        open:  false,
        id:    null,
        parts: []      
    },
    uploadReceipt: {
        uuid: null,
        file: null
    }
};

class Template extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        API.get(`/customerReturns/greenaware/returns`, {params: {
            template: this.props.template.gt_id,
            customer: this.props?.custId,
            orderId:  this.props?.orderId
        }})
        .then(res => {
            this.setState({
                isLoading: false,
                data:      _.uniqBy(_.map(res.data, item => _.assign({
                    ...item,
                    gpr_qty: _.sumBy(res.data,  i => i.gpr_uuid == item.gpr_uuid ? i.gpr_qty : 0),
                    items:   _.filter(res.data, i => i.gpr_uuid == item.gpr_uuid)
                })), 'gpr_uuid')
            })
        });
    }

    cancelCollection = uuid => () => {
        API.post(`/customerReturns/greenaware/returns/updateStatus`, {status: 'Cancelled', uuid})
        .then(this.getData)
    }

    markForCollection = uuid => () => {
        let newFormData =  new FormData();
        Object.keys(this.state.collectionApproval).forEach(key => { 
            if (key == 'consignments')
                newFormData.append(key, JSON.stringify(this.state.collectionApproval[key]));
            else if (key == 'date')
                newFormData.append(key, moment(this.state.collectionApproval[key]).format('YYYY-MM-DD'));
            else    
                newFormData.append(key, this.state.collectionApproval[key]); 
        });

        newFormData.append('uuid', uuid);
        newFormData.append('status', 'Awaiting Collection');

        API.post(`/customerReturns/greenaware/returns/updateStatus`, newFormData)
        .then(res => {
            let item = _.find(this.state.data, {gpr_uuid:uuid})
            this.props.deployDialog(
                <EmailDialog
                    type='greenAware'
                    typeId={uuid}
                    to={item.gpr_contact_id}
                    templateSource='customer_returns_email_template'
                    templateId={6}
                    replace={{
                        date: moment(this.state.collectionApproval.date).format('DD/MM/YYYY'),
                    }}
                    basicMode
                    passInData={0}
                />,
                'Send Email',
                'success',
                'md'
            )
            this.getData();
            this.closeAllDialogs();
        })
    }



    failedCollection = id => () => {
        API.post(`/customerReturns/greenaware/returns/${id}/updateStatus`, {status: 'Failed'})
        .then(this.getData)
    }


    handleChangeDate = (object, item) => date => {
        this.setState({
            [object]: {
                ...this.state[object],
                [item]: date
            }
        })
    }

    handleChangeInput = (object, item) => e => {
        this.setState({
            [object]: {
                ...this.state[object],
                [item]: e.target.value
            }
        })
    }

    handleChangeSelect = (object, item) => e => {
        this.setState({
            [object]: {
                ...this.state[object],
                [item]: e.value
            }
        })
    }

    closeAllDialogs = () => {
        this.setState({
            collectionApproval: initialState.collectionApproval,
            collectionComplete: initialState.collectionComplete,
            uploadReceipt:      initialState.uploadReceipt
        })
    }

    handleChangeConsignments = e => {
        let value        = e ? e.value : 0;
        let consignments = this.state.collectionApproval.consignments;
        let i            = consignments.length;
        if (i > value){
            for(i; value < i; i--){ consignments.splice(-1);}
        } else {
            for(i; value > i; i++){consignments.push({number: '', parcels: 1});}
        }
        this.setState({
            collectionApproval: {
                ...this.state.collectionApproval,
                consignments
            }
        });
    }

    handleChangeConsignment = (f,n) => e => {
        let consignments = this.state.collectionApproval.consignments;
        consignments[n][f] = e;
        this.setState({
            collectionApproval: {
                ...this.state.collectionApproval,
                consignments,
            }
        })
    }

    
    changeStatus = (uuid, status) => () => {
        API.post(`/customerReturns/greenaware/returns/updateStatus`, {status, uuid})
        .then(this.getData);
    }

    
    handleDownload = (rowData) => {
        API.get(`/customerReturns/greenaware/returns/${rowData.gpr_uuid}/pdf`).then(res => { pdfFromBase64(res.data.pdf, res.data.pdfName); })
    };

    handleResendEmail = rowData => {
        API.get(`/customerReturns/greenaware/returns/${rowData.gpr_uuid}/pdf`)
        .then(_res => {
            let attached = [
                {
                    label: _res.data.pdfName,
                    file:  _res.data.pdf,
                    icon:  icons.pdf,
                    type:  'pdf'
                }
            ];

            this.props.deployDialog(
                <EmailDialog
                    type='greenAware'
                    typeId={rowData.gpr_uuid}
                    to={rowData.gpr_contact_id}
                    templateSource='customer_returns_email_template'
                    templateId={8}
                    replace={{link: `<a>https://www.clenaware.co.uk/greenaware/${rowData.gpr_uuid}</a>`}}
                    basicMode
                    passInData={1}
                />,
                'Send Email',
                'success',
                'md'
            )

/*
            this.props.deployDialog(
                <GenericEmailDialog
                    customerId={rowData.gpr_customer_id} 
                    contactId={rowData.gpr_contact_id}
                    templateId={40}
                    replace={{link: `<a>https://www.clenaware.co.uk/greenaware/${rowData.gpr_uuid}</a>`}}
                    attached={attached}
                />,'Send Email', 'success'
            );
*/
        })
    }
    
    handleMarkCollectionComplete = () => {
        let parts = [];
        _.each(this.state.collectionComplete.parts, p => {_.each(p.items, i => {parts.push({value: i.value, id: i.part})})})
        API.post(`/customerReturns/greenaware/returns/updateStatus`, {status: 'Complete', parts, uuid: this.state.collectionComplete.uuid})
        .then(res => {
            let item = _.find(this.state.data, {gpr_uuid:this.state.collectionComplete.uuid})
            this.getData();
            this.closeAllDialogs();
            this.props.deployDialog(
                <EmailDialog
                    type='greenAware'
                    typeId={this.state.collectionComplete.uuid}
                    to={item.gpr_contact_id}
                    templateSource='customer_returns_email_template'
                    templateId={7}
                    basicMode
                    passInData={0}
                />,
                'Send Email',
                'success',
                'md'
            )
        })
    }

    changeCompletionValue = ( idx, iidx, value) => {
        let parts = this.state.collectionComplete.parts;
        let items = parts[idx].items;
        items[iidx].value = value;
        parts[idx].items = items;
        this.setState({collectionComplete: {...this.state.collectionComplete, parts}});
    }

    handleFileChange = _name => (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            [_name]: {
                ...this.state[_name],
                [name]: file
            }
        });
    }
    
    clearFile = () => {
        this.setState({
            collectionApproval: {
                ...this.state.collectionApproval,
                file: initialState.collectionApproval.file
            },
            uploadReceipt: {
                ...this.state.uploadReceipt,
                file: initialState.uploadReceipt.file
            },
        });
    };

    updateCollectionReceipt = () => {
        let formData = new FormData();
        formData.append('file', this.state.uploadReceipt.file);
        formData.append('uuid', this.state.uploadReceipt.uuid);
        API.post(`/customerReturns/greenaware/returns/updateReceipt`, formData)
        .then(res => { this.setState({ uploadReceipt: initialState.uploadReceipt }, this.getData ); })
    }

    handleProcessCheck = (id, type='Cancel') => {
        this.setState({
            data: _.map(this.state.data, item => item.gpr_id === id ? {...item, checked: !item.checked} : item)
        });
    }

    handleBatchCancel = () => {
        let promise = _.map(_.filter(this.state.data, i => i.checked), i => 
            API.post(`/customerReturns/greenaware/returns/updateStatus`, {status: i.gpr_status === 'Awaiting Collection' ? 'Failed' : 'Cancelled', uuid: i.gpr_uuid})
        )
        Promise.all(promise).then(this.getData);
    }

    handleCheckAll = status => () => {
        this.setState({
            data: _.map(this.state.data, item => item.gpr_status === status ? {...item, checked: true} : item)
        });
    }

    handleDownloadReceipt = rowData => () => {
        downloadS3File(rowData.file_url);
    }

    render() {
        const { isLoading, data, collectionApproval, collectionComplete, uploadReceipt }  = this.state;
        const { template }         = this.props;

        if ( isLoading ) return (<LoadingCircle />);

        const statuses = [
            { status: 'Pending',             heading: 'Pending Customer Booking'    },
            { status: 'Acknowledged',        heading: 'Pending Collection Approval' },
            { status: 'Awaiting Collection', heading: 'Pending Collection'          },
            { status: 'Complete',            heading: 'Completed'                   },
            { status: 'Failed',              heading: 'Failed'                      },
            { status: 'Cancelled',           heading: 'Cancelled'                   }
        ]

        return (
            <>
                <Grid container style={{paddingTop: 0}} spacing={3}>
                    <Grid item xs={9}>
                        <Typography variant='h5'>{template.gt_heading}</Typography>
                        <Typography variant='body1'>{template.gt_description}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {_.filter(data, i => i.checked).length > 0 && (
                            <PaddedPaper>
                                <Button fullWidth variant='contained' color='secondary' onClick={()=>{
                                                                        this.props.deployConfirmation(
                                                                            `Are you sure you want to cancel/fail thees collections?`,
                                                                            'Cancel/Fail Collections',
                                                                            this.handleBatchCancel
                                                                        )
                                                                    }}>Cancel / Fail <br/>{_.filter(data, i => i.checked).length}  Collections</Button><br/><br/>
                                <Typography variant='body2'>* Pending Collection will be set to failed, Pending Customer Booking and Pending Collection Approval will be set to cancelled</Typography>
                            </PaddedPaper>
                        )}
                    </Grid>
                    <Grid item xs={12} key={data.length}>
                        {_.map(statuses, status => 
                            <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>{status.heading} ({_.filter(data, {gpr_status: status.status}).length})</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails >
                                    <Grid container >
                                        <Grid item xs={12} style={{marginBottom: '1em'}}>
                                            {_.filter(data, {gpr_status: status.status}).length > 0 && status.heading === 'Pending Customer Booking' && (
                                                <div style={{width: '100%'}} className='buttonRow'>
                                                    <Button variant='contained' color='secondary' onClick={this.handleCheckAll(status.status)}>Check All</Button>
                                                </div>
                                            ) }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DataTable
                                                config={{
                                                    key:  i => `${i.gpr_id} ${i.checked}`,
                                                }}
                                                columns={_.filter([
                                                    !this.props?.orderId && {
                                                        heading:       'Customer',
                                                        field:         'customer.cust_name',
                                                        sizeToContent: true,
                                                        truncate:      200 
                                                    },
                                                    {
                                                        heading:       'Site',
                                                        field:         'site.address_name',
                                                        info:          r => 
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    {r.site.address_name}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {r.site.address_line_two}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {r.site.address_town}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {r.site.address_postcode}
                                                                </Grid>
                                                                <Grid item xs={12}><br/></Grid>
                                                                <Grid item xs={12}>
                                                                    {r.contact.contact_name}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {r.contact.contact_telephone || r.contact.contact_mobile}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {r.contact.contact_email}
                                                                </Grid>
                                                            </Grid>,
                                                        sizeToContent: true,
                                                        truncate:      200 
                                                    },
                                                    !this.props?.orderId && {
                                                        heading:    'Order',
                                                        field:      r => r.order ? 
                                                                        <a className='link hover' onClick={() => { window.open(`/sales/order/view/${r.order.order_id}`, '_blank')}}>{`${r.order.order_reference}-${r.order.order_reference_amendment}`}</a> : 
                                                                        'Manual Entry',
                                                    },
                                                    {
                                                        heading:       <>Expected<br/>Qty</>,
                                                        field:         'gpr_qty',
                                                        alignment:     'center',
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading:       <>Customer<br/>Qty</>,
                                                        field:         'gpr_customer_qty',
                                                        alignment:     'center',
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading:       'Consignments',
                                                        field:         r =>  JSON.parse(r.gpr_consignments)?.length,
                                                        fieldFormat:   'nullDash',
                                                        info:          r => r.gpr_consignments && <Grid container>{JSON.parse(r.gpr_consignments)?.map(c => <Grid item xs={12}>{c.number} * {c.parcels} Parcels</Grid>)}</Grid>,
                                                        sizeToContent: true,
                                                        alignment:     'center'
                                                    },
                                                    {
                                                        heading:       <>Parcels</>,
                                                        field:         'gpr_parcelforce_number',
                                                        field:         r => _.sumBy(JSON.parse(r.gpr_consignments), i => parseInt(i.parcels)),
                                                        fieldFormat:   'nullDash',
                                                        sizeToContent: true,
                                                        alignment:     'center'
                                                    },
                                                    {
                                                        heading:       <>Collection<br/>Date</>,
                                                        field:         'gpr_pick_up_date',
                                                        style:         r => ({
                                                            color: status.heading !== 'Completed' && (moment(r.gpr_pick_up_date).isBefore(moment()) ? colors.red : colors.green) 
                                                        }),
                                                        fieldFormat:   'clenydate',
                                                        sizeToContent: true,
                                                        alignment:     'center'
                                                    },
                                                    {
                                                        actions: rowData => {
                                                            let base = [
                                                                {
                                                                    name:    rowData.gpr_collection_receipt ? 'Collection Confirmation'  : 'Upload Collection Confirmation',
                                                                    icon:    rowData.gpr_collection_receipt ? icons.file : icons.upload,
                                                                    onClick: () => { 
                                                                        rowData.gpr_collection_receipt ?
                                                                            this.props.deployDialog(
                                                                                <Grid container xs={12}  style={{padding:'1em'}}>
                                                                                    <Grid item xs={12} style={{padding:'1em', textAlign: 'center'}}>
                                                                                        {rowData.gpr_collection_receipt.includes('.pdf') ? 
                                                                                            <PDFViewer src={rowData.file_url} style={{ height:'100%', maxHeight: 700, minHeight: 400, maxWidth: '100%' }} /> :
                                                                                            <img src={rowData.file_url} style={{ height:'100%', maxHeight: 700, minHeight: 400, maxWidth: '100%' }} />
                                                                                        }
                                                                                    </Grid>
                                                                                    <Grid item xs={12} className='buttonRow'>
                                                                                        <Button variant='outlined' onClick={this.handleDownloadReceipt(rowData)}>Download File</Button>
                                                                                        <Button variant='outlined' onClick={()=>{this.setState({ uploadReceipt: { uuid: rowData.gpr_uuid, file: null } }, this.props.closeDialog)}}>Update File</Button>
                                                                                        <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                                                                                    </Grid>
                                                                                </Grid>,'', null, 'md'
                                                                            ) : this.setState({ uploadReceipt: { uuid: rowData.gpr_uuid, file: null } })
                                                                        }
                                                                },
                                                                {
                                                                    name:    'Reprint Notes',
                                                                    icon:    icons.download,
                                                                    onClick: () => this.handleDownload( rowData ),
                                                                },
                                                                {
                                                                    name:    'Email Form',
                                                                    icon:    icons.email,
                                                                    onClick: () => this.handleResendEmail(rowData),
                                                                },
                                                                {
                                                                    name:    'Link',
                                                                    icon:    icons.link,
                                                                    onClick: () => { window.open(`https://www.clenaware.co.uk/greenaware/${rowData.gpr_uuid}`, '_blank') }
                                                                },
                                                            ]
                
                
                                                            switch(status.status){
                                                                case 'Pending':
                                                                    return [
                                                                        ...base,
                                                                        {
                                                                            name:    'Cancel',
                                                                            icon:    icons.close,
                                                                            onClick: ()=>{
                                                                                this.props.deployConfirmation(
                                                                                    `Are you sure you want to cancel this collection?`,
                                                                                    'Cancel Collection',
                                                                                    this.changeStatus(rowData.gpr_uuid, 'Cancelled')
                                                                                )
                                                                            }
                                                                        },
                                                                        {   
                                                                            type: 'checkBox',                   
                                                                            checked: rowData.checked, 
                                                                            onCheck: ()=>{this.handleProcessCheck(rowData.gpr_id)},  
                                                                            color:   'secondary'
                                                                        }
                                                                    ];
                                                                case 'Acknowledged':
                                                                    return [
                                                                        ...base,
                                                                        {
                                                                            name:    'Cancel',
                                                                            icon:    icons.close,
                                                                            onClick: ()=>{
                                                                                this.props.deployConfirmation(
                                                                                    `Are you sure you want to cancel this collection?`,
                                                                                    'Cancel Collection',
                                                                                    this.changeStatus(rowData.gpr_uuid, 'Cancelled')
                                                                                )
                                                                            }
                                                                        },
                                                                        {
                                                                            name: 'Mark For Collection',
                                                                            icon: icons.truck,
                                                                            onClick: ()=>{
                                                                                this.setState({
                                                                                    collectionApproval: {
                                                                                        open:         true,
                                                                                        id:           rowData.gpr_uuid,
                                                                                        date:         rowData.gpr_pick_up_date,
                                                                                        qty:          rowData.gpr_customer_qty,
                                                                                        consignments: [],
                                                                                    }
                                                                                })
                                                                            }
                                                                        },
                                                                        {   
                                                                            type: 'checkBox',                   
                                                                            checked: rowData.checked, 
                                                                            onCheck: ()=>{this.handleProcessCheck(rowData.gpr_id)},  
                                                                            color:   'secondary'
                                                                        }
                                                                    ];
                                                                case 'Awaiting Collection':
                                                                    return [
                                                                        ...base,
                                                                        {
                                                                            name:    'Failed',
                                                                            icon:    icons.close,
                                                                            onClick: ()=>{
                                                                                this.props.deployConfirmation(
                                                                                    `Are you sure this collection failed?`,
                                                                                    'Failed Collection',
                                                                                    this.changeStatus(rowData.gpr_uuid, 'Failed')
                                                                                )
                                                                            }
                                                                        },
                                                                        {
                                                                            name: 'Complete',
                                                                            icon: icons.true,
                                                                            onClick: ()=>{
                                                                                this.setState({
                                                                                    collectionComplete: {
                                                                                        ...this.state.collectionComplete,
                                                                                        open:   true,
                                                                                        id:     rowData.gpr_id,
                                                                                        uuid:   rowData.gpr_uuid,
                                                                                        parts:  _.map(rowData.items, i => ({
                                                                                            label:   `${i.part.part.part_number} - ${i.part.part.part_description}`,
                                                                                            items: _.map(i.part.parts, p => ({
                                                                                                label: `${p.part_number} - ${p.part_description}`,
                                                                                                part:  p.part_id,
                                                                                                value: i.gpr_customer_qty > i.gpr_qty ? i.gpr_qty : i.gpr_customer_qty,
                                                                                                max:   i.gpr_customer_qty > i.gpr_qty ? i.gpr_qty : i.gpr_customer_qty,
                                                                                            }))
                                                                                        }) )
                                                                                    }
                                                                                });
                                                                            }
                                                                        },
                                                                        {   
                                                                            type: 'checkBox',                   
                                                                            checked: rowData.checked, 
                                                                            onCheck: ()=>{this.handleProcessCheck(rowData.gpr_id, 'Failed')},  
                                                                            color:   'secondary'
                                                                        }
                                                                    ];
                                                                default:
                                                                    return [
                                                                        ...base,
                                                                    ];
                                                            }
                                                        },
                                                        alignment: 'right'
                                                    }
                                                ], i => !!i)}
                                                rows={_.orderBy(_.filter(data, {gpr_status: status.status}), 'gpr_id', 'desc')}
                                            />
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )}
                    </Grid>
                </Grid>

                <Dialog
                    open={collectionComplete.open}
                    onClose={this.closeAllDialogs}
                    maxWidth="md" 
                >
                    <DialogTitle>Mark Collection Complete</DialogTitle>
                    <DialogContent style={{height: '100%', width: 500 }} >
                        Mark items collected, so stock levels can be updated.
                        <List>
                            {_.map(collectionComplete.parts, (i, pidx) => 
                                <>
                                    <ListItem>
                                        <ListItemText primary={<b>{i.label}</b>} />
                                    </ListItem>
                                    {_.map(i.items, (p, iidx) => 
                                        <ListItem>
                                            <ListItemText primary={p.label} />
                                            <ListItemSecondaryAction>
                                                <TextField
                                                    fullWidth
                                                    value={p.value}       
                                                    type='number'
                                                    InputProps={{ inputProps: { min: 0, max: p.max } }}
                                                    onChange={(e) => this.changeCompletionValue(pidx, iidx, e.target.value)}
                                                    endAdornment={<InputAdornment position="end">/{p.max}</InputAdornment>}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </>
                            )}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <div className='buttonRow'>
                            <Button variant='outlined' onClick={this.closeAllDialogs} >Close</Button>
                            <Button 
                                variant='contained' 
                                color='primary'
                                onClick={()=>{
                                    this.props.deployConfirmation(
                                        'Are you sure you want to mark this as collected?',
                                        'Mark Collected',
                                        this.handleMarkCollectionComplete
                                    )
                                }}
                            >Approve</Button>
                        </div>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={collectionApproval.open}
                    onClose={this.closeAllDialogs}
                    maxWidth="md" 
                >
                    <DialogTitle>Mark For Collection</DialogTitle>
                    <DialogContent style={{height: '100%'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DatePicker 
                                    label='Collection Date'
                                    value={collectionApproval.date}
                                    fullWidth
                                    onChange={this.handleChangeDate('collectionApproval', 'date')}
                                    margin='none'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="To Be Collected"
                                    value={collectionApproval.qty}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="none">
                                    <AutoCompleteSelect 
                                        options={_.map(_.range(0,11), i => _.assign({value: i, label: i}))}
                                        label='Consignments *'
                                        value={collectionApproval?.consignments?.length}
                                        onChange={this.handleChangeConsignments}
                                        noClear
                                    />
                                </FormControl>
                            </Grid>
                            {!!collectionApproval?.consignments?.length > 0 && 
                                _.map(_.range(0,collectionApproval?.consignments?.length), n => 
                                    <Grid item xs={12}>
                                        <Typography>Consignment {n + 1}:</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={8}>
                                                <TextField
                                                    value={ collectionApproval?.consignments[n].number  }
                                                    onChange={(e) => this.handleChangeConsignment('number',n)(e.target.value)}
                                                    label='Parcel Force Number *'
                                                    fullWidth
                                                    noClear
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth margin="none">
                                                    <AutoCompleteSelect 
                                                        options={_.map(_.range(0,11), i => _.assign({value: i, label: i}))}
                                                        label="Parcels *"
                                                        value={ collectionApproval?.consignments[n].parcels }
                                                        onChange={(e)=>this.handleChangeConsignment('parcels',n)(e.value)}
                                                        noClear
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        
                                    </Grid>
                                )
                            }
                            <Grid item xs={12}>
                                <DragFileInput
                                    id="file"
                                    name="file"
                                    label="Collection Confirmation"
                                    file={collectionApproval.file}
                                    onChange={this.handleFileChange('collectionApproval')}
                                    cancelOnClick={this.clearFile}
                                    emptyText='No file selected'
                                />
                            </Grid>
                            <Grid item xs={12} className='buttonRow'>
                                <Button variant='outlined' onClick={this.closeAllDialogs} >Close</Button>
                                <Button 
                                    variant='contained' 
                                    color='primary'
                                    disabled={
                                        !collectionApproval.date ||
                                        !collectionApproval.qty ||
                                        !collectionApproval.consignments?.length ||
                                        _.filter(collectionApproval.consignments, i => !i.number || !i.parcels).length > 0
                                    }
                                    onClick={()=>{
                                        this.props.deployConfirmation(
                                            'Are you sure you want to mark this for collection?',
                                            'Mark For Collection',
                                            this.markForCollection(collectionApproval.id)
                                        )
                                    }}
                                >Approve</Button>
                            </Grid>
                        </Grid>
                    </DialogContent>

                </Dialog>


                <Dialog
                    open={uploadReceipt.uuid}
                    maxWidth="md" 
                >
                    <DialogTitle>Upload Collection Confirmation</DialogTitle>
                    <DialogContent style={{height: '100%'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DragFileInput
                                    id="file"
                                    name="file"
                                    label="Collection Confirmation "
                                    file={uploadReceipt.file}
                                    onChange={this.handleFileChange('uploadReceipt')}
                                    cancelOnClick={this.clearFile}
                                    emptyText='No file selected'
                                />
                            </Grid>
                            <Grid item xs={12} className='buttonRow'>
                                <Button variant='outlined' onClick={this.closeAllDialogs} >Close</Button>
                                <Button 
                                    variant='contained' 
                                    color='primary'
                                    disabled={!uploadReceipt.file}
                                    onClick={()=>{
                                        this.props.deployConfirmation(
                                            'Are you sure you want to update this Collection Confirmation?',
                                            'Upload Collection Confirmation',
                                            this.updateCollectionReceipt
                                        )
                                    }}
                                >Upload</Button>
                            </Grid>
                        </Grid>
                    </DialogContent>

                </Dialog>

            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
    deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:        ()                              => dispatch(closeDialog())
})

export default connect(null, mapDispatchToProps)(Template);