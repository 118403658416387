import React, { useState, useEffect } from 'react';
import _                              from 'lodash';

import TabBar      from 'Components/Common/Navigation/TabBar';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

class TabBarSwitcher extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: props.defaultTab ? props.defaultTab : _.filter(props.tabs, i => i?.label)[0].label
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tab !== this.props.tab) {
            this.setState({currentTab: this.props.tab})
        }
        if (prevProps.defaultTab !== this.props.defaultTab) {
            this.setState({currentTab: this.props.defaultTab})
        }
    }

    handleTabChange = (event, value) => { 
        this.setState({currentTab:value}); 
        this.props.onChange && this.props.onChange(value); 
    };

    render() {
        const { tabs, nopad, disableMenu} = this.props;
        const { currentTab } = this.state;

        return (
            <>
                <TabBar
                    tabs={_.map(_.filter(tabs, i => i?.label), (i, value) => ({label: i.label}))}
                    currentTab={currentTab} 
                    handleTabChange={this.handleTabChange}
                    disableMenu={_.filter(tabs, i => i?.label).length < 7 ? 1 : disableMenu}
                    nested
                />
                    {
                        (nopad || _.find(tabs, {label: currentTab})?.noPad) ?
                        <div  key={currentTab} style={{paddingTop: ( nopad ) && '1em'}}>
                            {_.map(_.filter(tabs, i => i?.label), (i, value) => {
                                if(i.label === currentTab) {
                                    return (i.content)
                                }
                            })}
                        </div> :
                        <PaddedPaper  key={currentTab} style={{paddingTop: ( nopad ) && '1em'}}>
                            {_.map(_.filter(tabs, i => i?.label), (i, value) => {
                                if(i.label === currentTab) {
                                    return (i.content)
                                }
                            })}
                        </PaddedPaper>
                    }
            </>
        )
    }
}

export default TabBarSwitcher;