import React from 'react';
import { Grid, Typography, Button, TextField } from '@material-ui/core';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { handleChange } from 'Functions/FormFunctions';
import Textarea from 'Components/Common/Inputs/Textarea';
import EmailTemplate from 'Assets/json/emailCampaignsTemplate.json';
import EmailEditorPreset from 'Assets/json/emailEditorPreset.json';
import EmailEditor from 'react-email-editor';
import { connect } from 'react-redux';
import Legend from 'Components/Marketing/EmailCampaigns/EmailTemplates/Legend';
import EmailPreviewDialog from 'Components/Marketing/EmailCampaigns/EmailTemplates/EmailPreviewDialog';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import API from 'API';
import _ from 'lodash';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

const initialState = (props) => ({
    formData: {
        name: '',
        desc: '',

        //email
        emailSubject: '',
        category: null,
        subCategory: null,
        formId: null,
    },
    formsList: [],
    isLoading: false,
    showEmailPreviewDialog: false,
})

class NewSurveyDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);

        this.emailEditorRef = React.createRef();

        //bindings
        this.handleChange = handleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.copy || this.props.edit){ 
            this.handleCopy();
        }
        this.getData();

    }

    handleCopy = () => {
        let survey = this.props.copy || this.props.edit;
        this.setState({
            formData: {
                id: this.props.edit ? survey.survey_id : 0,
                name: survey.survey_name,
                desc: survey.survey_desc,
                emailSubject: survey.survey_email_subject,
                category: survey.form.sub_category.category.form_cat_name,
                subCategory: survey.form.sub_category.form_cat_name,
                formId: survey.survey_form_id,
                emailDesign: JSON.parse(survey.survey_email_json),
            }
        })
    }

    getData = () => {
        this.setState({ isLoading: true }, () => {
            API.get('/forms/configuration', {params: {
                systemCatId: 8
            }}).then(res => {
                this.setState({ isLoading: false, formsList: _.map(res.data, i => ({value: i.id, label: i.nm, category:i.cat, subCategory: i.subCat })) })
            })
        })
    }

    EmailEditorOnReady = () => {
        // editor is ready
        // you can load your template here;
        // const templateJson = {};
        if (!this.state.emailEditorIsLoaded){
            if (this.state.formData.emailDesign) {
                this.emailEditorRef.current.editor.loadDesign(this.state.formData.emailDesign);
            } else {
                this.emailEditorRef.current.editor.loadDesign(EmailTemplate);
            }
            this.setState({ emailEditorIsLoaded: true });
        }
    };

    submitSurvey = () => {
        this.emailEditorRef.current.editor.exportHtml((emailData) => {
            let formData = this.state.formData;
            let _formData = new FormData();

            if (!emailData.html.includes('{%survey%}')){
                this.props.deployConfirmation(
                    'Email does not contain a link to the survey, please add one in using the tag {%survey%} and try again.',
                    'Missing Survey Link',
                    ()=>{},
                    'warning'
                )
                return;
            }

            _.map(_.keys(formData), key => {_formData.append(key, this.state.formData[key]);})

            const { html, design } = emailData;
            _formData.append('emailHtml', html);
            _formData.append('emailDesign', JSON.stringify(design));

            API.post('/survey', _formData).then(res => {
                this.props.onClose();
            })
        })

    }

    render() {

        const { formData, isLoading, showEmailPreviewDialog, formsList } = this.state;

        let isUpdate = !!this.props.edit;  

        if (isLoading) { return <LoadingCircle/> }
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={4}>
                        <PaddedPaper>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        name='name'
                                        label='Survey Name *'
                                        value={formData.name}
                                        onChange={this.handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Textarea
                                        name='desc'
                                        label='Description'
                                        value={formData.desc}
                                        onChange={this.handleChange}
                                        rows={4}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AutoCompleteSelect
                                        label='Category'
                                        name='category'
                                        value={formData.category}
                                        onChange={e => this.setState({formData: {...formData, category: e.value, subCategory: null, formId: null}})}
                                        options={_.map(_.uniqBy(formsList, 'category'), i => ({value: i.category, label: i.category}))}
                                        fullWidth
                                        noClear
                                    />
                                </Grid>
                                {formData.category &&
                                    <Grid item xs={12}>
                                        <AutoCompleteSelect
                                            label='Category'
                                            name='category'
                                            value={formData.subCategory}
                                            onChange={e => this.setState({formData: {...formData, subCategory: e.value,  formId: null}})}
                                            options={_.map(_.uniqBy(_.filter(formsList,{category: formData.category}), 'subCategory'), i => ({value: i.subCategory, label: i.subCategory}))}
                                            fullWidth
                                            noClear
                                        />
                                    </Grid>
                                }
                                {formData.subCategory &&
                                    <Grid item xs={12}>
                                        <AutoCompleteSelect
                                            label='Form *'
                                            name='formId'
                                            value={formData.formId}
                                            onChange={e => this.setState({formData: {...formData, formId: e.value}})}
                                            options={
                                                (this.props.copy || this.props.edit ) ?
                                                (
                                                    _.find(formsList, {value: formData.formId}) ?
                                                        _.filter(formsList,{subCategory: formData.subCategory}) :
                                                        [..._.filter(formsList,{subCategory: formData.subCategory}), {value: formData.formId, label: `${(this.props.copy || this.props.edit).form.form_config_name} (Old Version)` }]
                                                ) :
                                                _.filter(formsList,{subCategory: formData.subCategory})
                                            }
                                            fullWidth
                                            noClear
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={8} style={{height: '100%'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <PaddedPaper  style={{height: '100%'}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={8}>
                                            <Typography variant="h6">Email Layout</Typography>
                                        </Grid>
                                        <Grid item style={{marginLeft: 'auto'}}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={()=>this.setState({showEmailPreviewDialog: true})}
                                            >
                                                Email Preview
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}> 
                                            <Legend 
                                                additionalPlaceholders={[
                                                    {etp_name: '{%survey%}', etp_desc: 'Link to access the survey form'},
                                                ]}
                                            /> 
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Email's Subject *"
                                                value={formData.emailSubject}
                                                name='emailSubject'
                                                onChange={this.handleChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <EmailEditor 
                                                ref={this.emailEditorRef}
                                                onReady={this.EmailEditorOnReady}
                                                tools={
                                                    EmailEditorPreset.tools
                                                }
                                                fonts={
                                                    EmailEditorPreset.fonts
                                                }
                                                style={{minHeight: 535}}
                                            />
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant="outlined"
                            onClick={this.props.onClose}
                        >Close</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                this.props.deployConfirmation(
                                    'Are you sure you want to create this survey?',
                                    'Create Survey',
                                    this.submitSurvey
                                )
                            }}
                            disabled={!formData.name || !formData.formId || !formData.emailSubject}
                        >{isUpdate ? 'Update' : 'Create'} Survey</Button>
                    </Grid>
                </Grid>
                {showEmailPreviewDialog &&
                    <EmailPreviewDialog
                        open={showEmailPreviewDialog}
                        onClose={()=>this.setState({showEmailPreviewDialog: false})}
                        emailPrefill={[
                            this.props.loggedInStaff.email
                        ]}
                        emailData={{
                            ref: this.emailEditorRef,
                            subject: formData.emailSubject,
                        }}
                    />
                }
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback, variant=null) => {
            dispatch(deployConfirmation(content, title, callback, null, variant))
        }
    }
}

function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewSurveyDialog);