import React, { Component } from 'react';
import _                    from 'lodash';
import { connect }          from 'react-redux';
import { Redirect }         from 'react-router-dom';
import API                  from 'API';

import { FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Input, InputAdornment, InputLabel, List, ListItemAvatar, ListItemSecondaryAction, 
    ListItemText, Radio, RadioGroup, TextField, Tooltip, Typography, Button, ListItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import BackButton     from 'Components/Common/Buttons/BackButton';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import DragFileInput  from 'Components/Common/Inputs/DragFileInput';

import FALightIcon              from 'Components/Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle            from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper              from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteMultiSelect  from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AutoCompleteSelect       from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog       from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar                 from 'Components/Common/SnackBars/SnackBar';
import DataTable                from 'Components/Common/DataTables/CiDataTable';
import AllIcon                  from 'Components/Common/Icons/AllIcon';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { colors }                 from 'Helpers/ColourHelper';
import icons                      from 'Helpers/IconHelper';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

const initialState = {
    formData: {
        partType: null,
        buildType: null,
        newPartNumber: 'CS-',
        oldPartNumber: '',
        description:  '',
        importCommodity1:  '',
        importCommodity2:  '',
        importCommodity3:  '',
        exportCommodity1:  '',
        exportCommodity2:  '',
        exportCommodity3:  '',
        nominalCode: [],
        associatedParts: [],
        sparePart: null,
        spareForPart: [],
        status: '',
        replacedPart: null,
        partDiscount: '',
        product: '',
        reqSerialNumber: '',
        warrantyVoidLabel: 'No',
        warrantyTerms: '',
        barcodeNumber: '',
        barcodeImage: '',
        barcodeUrl:'',
        deleteBarcode: false,
        technicalLibraryAccess: '',

        shippingWeight: '',
        shippingWidth: '',
        shippingHeight: '',
        shippingDepth: '',

        partWeight: '',
        partWidth: '',
        partHeight: '',
        partDepth:'',

        electricalConnection:  'No',
        electricalConnections: [],

        category: 0,
        exemptFromStock: 'No',
        stockThreshold: 0,

        linkedParts: {},

        requiresStockMovement: 'No'
    },
    catagories:     [],
    showReplacedPart: false,
    partsList:      [],
    nominalList:    [],
    ecList:         [],
    formErrors:     [],
    confirmationOpen: false,
    partId: 0,
    part: {},
    snackbarOpen: false,
    ccFocused: {
        importCommodity1: false,
        importCommodity2: false,
        importCommodity3: false
    },
    isLoading: true,
    showAssociatedSpareParts: false,
    redirect: false,
    access: {}
}

const styles = () => ({
    ccGroup: {
        paddingBottom: '1px',
        marginBottom: '8px',
        '&:hover': {
            borderBottom: '2px solid #0282C6',
            paddingBottom: 0
        }
    },
    cc1: {
        paddingLeft: '8px',
        border: '1px solid rgba(212, 213, 231, 1)',
        borderRight: 'none',
        background:'rgba(212, 213, 231, 0.7)',
        '&:before': {
            borderBottom: '1px solid rgba(212, 213, 231, 1)'
        },
        '&:after': {
            borderBottom: '2px solid rgba(212, 213, 231, 1)'
        }
    },
    cc2: {
        paddingLeft: '8px',
        border: '1px solid rgba(182, 215, 235, 1)',
        borderRight: 'none',
        background: 'rgba(182, 215, 235, 0.7)',
        '&:before': {
            borderBottom: '1px solid rgba(182, 215, 235, 1)'
        },
        '&:after': {
            borderBottom: '2px solid rgba(182, 215, 235, 1)'
        }
    },
    cc3: {
        paddingLeft: '8px',
        border: '1px solid rgba(181, 222, 219, 1)',
        background:'rgba(181, 222, 219, 0.7)',
        '&:before': {
            borderBottom: '1px solid rgba(181, 222, 219, 1)'
        },
        '&:after': {
            borderBottom: '2px solid rgba(181, 222, 219, 1)'
        }
    }
});

class UpdatePart extends Component {
    constructor(props) {
        super(props);
    
        this.state = initialState;
    }

    componentDidMount(){
        this.getAllCategories();
        this.creatCommodityRefs();
        this.getPartsList();
        this.getNominalCodes();
        this.getWarrantyTerms();
        this.getElectricalConnections();
        this.getAccess();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/lock-part'),
        ])
        .then(([lockRes]) => {
            this.setState({
                access: {
                    lock:  (lockRes?.data?.has_access)  || false,
                },
            })
        });
    }

    componentWillUnmount(){ this.props.closeDialog() }

    creatCommodityRefs = () => {
        this.ccInput1 = React.createRef();
        this.ccInput2 = React.createRef();
        this.ccInput3 = React.createRef();

        this.ccExportInput1 = React.createRef();
        this.ccExportInput2 = React.createRef();
        this.ccExportInput3 = React.createRef();
    }

    getPart = () => {
        API.get(`/parts/${this.props.match.params.id}`)
        .then((result) => {
            if(result.data.errors) {
                this.setState({ redirect: true });
            } else if(result.data){
                const part = result.data;
                let nominalCodes = [];
                _.map(part.nominal_codes, (nominal) => {
                    nominalCodes.push(nominal.nominal_id);
                });
                let spareForParts = [];
                _.map(part.spare_for_parts, (part) => {
                    spareForParts.push(part.part_id);
                });
                let associatedParts = [];
                _.map(part.associated_parts, (part) => {
                    associatedParts.push(part.part_id);
                });
                let electricalConnections = [];
                _.map(result.data?.electrical_connections, (con) => {electricalConnections.push(con.pec_ec_id);})
                let linkedParts = {};
                _.map(part.linked_parts, (linked_part) => {
                    linkedParts[linked_part.plp_linked_part_id] = linked_part.plp_qty;
                });

                this.setState({
                    ...this.state,
                    partId: part.part_id,
                    showReplacedPart: part.part_status === 'Replaced' ? true : false,
                    showAssociatedSpareParts: part.part_spare_part === 'Yes' ? true : false,
                    formData: {
                        stockThreshold: part.part_stock_threshold,
                        exemptFromStock: part.part_exempt_from_stock ? 'Yes' : 'No',
                        partType: part.part_type.part_type_name,
                        buildType: part.build_type.build_type_name,
                        newPartNumber: part.part_number,
                        oldPartNumber: part.part_old_number,
                        description:  part.part_description,
                        partWeight: part.part_weight,
                        importCommodity1: part.part_import_commodity_code.substr(0, 2),
                        importCommodity2:  part.part_import_commodity_code.substr(2, 2),
                        importCommodity3:  part.part_import_commodity_code.substr(4, 7),
                        exportCommodity1: part.part_export_commodity_code.substr(0, 2),
                        exportCommodity2: part.part_export_commodity_code.substr(2, 2),
                        exportCommodity3: part.part_export_commodity_code.substr(4, 4),
                        sparePart: part.part_spare_part,
                        product: part.part_product,
                        reqSerialNumber: part.part_req_serial_number,
                        status: part.part_status,
                        nominalCode: nominalCodes,
                        associatedParts,
                        spareForPart: spareForParts,
                        replacedPart: _.find(this.state.partsList, ['value', part.part_replaced_part_id]),
                        partDiscount: part.part_discount,
                        warrantyVoidLabel: part.part_warranty_void_label,
                        warrantyTerms: part.part_warranty_terms,
                        barcodeNumber: part.part_barcode_number,
                        barcodeUrl: part.barcode_url,
                        technicalLibraryAccess: part.part_file_technical_library_access,
                        shippingWidth: part.part_shipping_dimensions_width,
                        shippingHeight: part.part_shipping_dimensions_height,
                        shippingDepth: part.part_shipping_dimensions_depth,
                        shippingWeight: part.part_shipping_weight,
                        partDepth: part.part_dimensions_depth,
                        partHeight: part.part_dimensions_height,
                        partWidth: part.part_dimensions_width,
                        electricalConnection: (result.data.electrical_connections.length ? 'Yes' : 'No'),
                        electricalConnections,
                        category: part.part_part_category_id,
                        requiresStockMovement: part.part_requires_stock_movement ? 'Yes' : 'No',
                        linkedParts
                    },
                    part:   result.data,
                    isLoading: false
                });
            }
        })
    }

    getWarrantyTerms = () => {
        API.get('/parts/warrantyTerms')
        .then(result => {
            let warrantyTermsList = [];
            if(result.data){
                warrantyTermsList = _.map(result.data, el => {
                    return _.assign({
                        value: el.pwt_id,
                        label: el.pwt_name
                    });
                });
                this.setState({ warrantyTermsList });
            }

        });
    }

    getNominalCodes = () => {
        API.get('/accounts/nominalCodes')
        .then(result => {
            let nominalList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.nominal_id,
                    label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                });
            });
            this.setState({
                nominalList: nominalList
            });
        });
    };

    getElectricalConnections = () => {
        API.get('/parts/electricalConnections')
        .then( result => {
            let ecList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.ec_id,
                    label: el.ec_name
                })
            });
            this.setState({ecList});
        } )
    }

    getPartsList = () => {
        API.get('/parts/all', {params: {withDefaultImg: true}})
        .then((result) => {
            let partsList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description,
                    part: el
                });
            });
            // Remove the Part we are currently updating from the list
            partsList = partsList.filter((obj) => {
                return obj.value !== parseInt(this.props.match.params.id);
            });

            this.setState({
                partsList:  partsList
            }, () => {
                this.getPart();
            });
        })
    }

    commodityCodeBlur = (e) => {
        this.setState({
            ccFocused: {
                ...this.state.ccFocused,
                [e.target.name]: false
            }
        });
    }
    handleChange = e => {
        // Import Commodity
        if(e.target.name === 'importCommodity1' && e.target.value.length === 2) {
            this.ccInput2.current.focus();
        } else if(e.target.name === 'importCommodity2') {
            if(e.target.value.length === 0) {
                this.ccInput1.current.focus();
            } else if(e.target.value.length === 2) {
                this.ccInput3.current.focus();
            }
        } else if(e.target.name === 'importCommodity3') {
            if(e.target.value.length === 0) {
                this.ccInput2.current.focus();
            } 
        }
        // Export Commodity
        if(e.target.name === 'exportCommodity1' && e.target.value.length === 2) {
            this.ccExportInput2.current.focus();
        } else if(e.target.name === 'exportCommodity2') {
            if(e.target.value.length === 0) {
                this.ccExportInput1.current.focus();
            } else if(e.target.value.length === 2) {
                this.ccExportInput3.current.focus();
            }
        } else if(e.target.name === 'exportCommodity3') {
            if(e.target.value.length === 0) {
                this.ccExportInput2.current.focus();
            }
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleToDecimalPlace = (decimal) => e => {
        let newVal = parseFloat(e.target.value).toFixed(decimal);

        let formData = {
            ...this.state.formData,
            [e.target.name]: newVal
        };

        this.setState({ formData });
    }

    submit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            if(key === 'nominalCode' || key === 'spareForPart' || key === 'associatedParts' || key === 'electricalConnections' || key === 'linkedParts') {
                newFormData.append(key, JSON.stringify(this.state.formData[key]))
            } else {
                newFormData.append(key, this.state.formData[key]);
            }
        });
        API.post(`/parts/${this.state.partId}`, newFormData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                }, () => {
                    if (this.state.formErrors.hasPendingStock) this.props.deployDialog(
                        <>
                            <DataTable
                                config={{
                                    key: 'i',
                                    noHeader: true
                                }}
                                columns={[
                                    {
                                        heading: 'Type',
                                        field: rowData => {
                                            switch(rowData.type){
                                                case 'Stock Delivered - Parts Order': 
                                                    return 'Parts Order';
                                                case 'Sales - Stock Returned':
                                                case 'Credit Note':
                                                    return 'Credit Note'
                                                case 'Supplier Stock Return':
                                                case 'Stock Delivered - Supplier Stock Return':
                                                    return 'Supplier Stock Return';
                                                case 'Sales - Invoiced':
                                                case 'Sales - Stock Sold':
                                                    return 'Sales';
                                                default:
                                                    return rowData.type;
                                            }
                                        },
                                        alignment: 'left'
                                    },
                                    {
                                        heading: 'Reference',
                                        field: rowData => rowData.ref,
                                        alignment: 'left'
                                    },
                                    {
                                        heading: 'Qty',
                                        field: rowData => `${rowData.qty < 0 ? '-' : '+'}${parseFloat(Math.abs(rowData.qty))}`,
                                        alignment: 'left'
                                    },
                                    {
                                        actions: rowData => {
                                            let pathname = null;
                                            switch(rowData.type){
                                                case 'Stock Addition': 
                                                    pathname = `/stock/additions/view/${rowData.id}`; 
                                                break;
                                                case 'Stock Delivered - Parts Order':
                                                    pathname = `/parts-order/view/${rowData.id}`;
                                                break;
                                                case 'Sales - Invoiced':
                                                case 'Sales - Stock Sold':
                                                    pathname = `/sales/order/view/${rowData.id}`;
                                                break;
                                                case 'Sales - Stock Returned':
                                                case 'Credit Note':
                                                    pathname = `/returns/creditnote/${rowData.id}`;
                                                break;
                                                case 'Stock Write Off':
                                                    pathname = `/stock/write-off/view/${rowData.id}`;
                                                break;
                                                case 'Supplier Stock Return':
                                                case 'Stock Delivered - Supplier Stock Return':
                                                    pathname = `/supplier-stock-return/view/${rowData.id}`;
                                                break;
                                                default:
                                                    pathname = null;
                                                break;
                                            }
                                            return (pathname) ?  [
                                                {name: 'View', icon: 'search', link: {pathname}, style:{color:colors.red}},  
                                            ] : [];
                                        }
                                    }
                                ]}
                                rows={this.state.formErrors?.hasPendingStock}
                            />
                            <div className='buttonRow'>
                                <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                            </div>
                        </>,
                        'Part Has Pending Stock',null,'sm',{color: `${colors.red}`}
                    )
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.creatCommodityRefs();
                
                this.getPartsList();
                this.getNominalCodes();
                this.getElectricalConnections();
                this.getAllCategories();
            }
            this.props.scrollToTop();
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    deleteBarcode = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                deleteBarcode: !this.state.formData.deleteBarcode
            }
        });
    }

    handleStatusChange = selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                status: selectedOption && selectedOption.value
            }
        },
        () => {
            if(this.state.formData.status === 'Replaced'){
                this.setState({
                    showReplacedPart: true
                });
            } else {
                this.setState({
                    showReplacedPart: false
                });
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value,
            }
        },
        () => {
            if(fieldName === 'sparePart'){
                this.setState({
                    showAssociatedSpareParts: selectedOption && selectedOption.value === 'Yes' ? true : false
                });

            }
        });
    };

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    clearFile = type => {
        this.setState({
            formData: {
                ...this.state.formData,
                [type]: ''
            }
        });
    }
       
    getAllCategories = () => {
        API.get('/parts/catagories')
        .then(res => {this.setState({catagories: res.data});});
    }

    lockPart = () => {
        API.post(`/parts/${this.props.match.params.id}/lock`)
        .then(res => {this.getPart();});
    }

    handleLinkedPartsChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                linkedParts: {...this.state.formData.linkedParts, [e.value]: 1}
            }
        });
    }

    handleLinkedPartsRemove = part => () => {
        let linkedParts = {...this.state.formData.linkedParts};
        delete linkedParts[part];
        this.setState({
            formData: {
                ...this.state.formData,
                linkedParts
            }
        });
    }

    handleLinkedPartsChangeQty = part => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                linkedParts: {
                    ...this.state.formData.linkedParts,
                    [part]: parseFloat(e.target.value)
                }
            }
        });
    }

    render() {
        const { formErrors, isLoading, showAssociatedSpareParts, catagories, part, access } = this.state;
        const { classes } = this.props;
        if (this.state.redirect) {
            return <Redirect to='/parts/search'/>
        }
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Update Part
                    </Typography>
                </Grid>                
                {(isLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                )) || (
                    part.part_locked ?
                        <>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Grid container item spacing={3}>
                                            <Grid item container spacing={3}>
                                                <Grid item xs={10}>
                                                    <Typography variant="h6">
                                                        Part Details
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} style={{textAlign: 'right'}}>
                                                    {access.lock && !part.part_in_stock_take  &&
                                                        <AllIcon icon={icons.lock} onClick={()=>{
                                                            this.props.deployConfirmation(<>Are you sure you want to unlock this part?</>, 'Unlock Part?', this.lockPart);
                                                        }} tooltip='Unlock Part'/>
                                                    }
                                                </Grid>
                                            </Grid>  
                                            <Grid item xs={12} style={{textAlign:'center'}}>
                                                <TextField
                                                    id="partType"
                                                    name="partType"
                                                    label="Part Type"
                                                    value={this.state.formData.partType || ''}
                                                    margin="normal"
                                                    fullWidth
                                                    disabled
                                                    InputLabelProps={{
                                                        shrink: !!this.state.formData.partType
                                                    }}
                                                />
                                                <TextField
                                                    id="buildType"
                                                    name="buildType"
                                                    label="Build Type"
                                                    value={this.state.formData.buildType || ''}
                                                    margin="normal"
                                                    fullWidth
                                                    disabled
                                                    InputLabelProps={{
                                                        shrink: !!this.state.formData.buildType
                                                    }}
                                                />
                                                <TextField
                                                    id="newPartNumber"
                                                    name="newPartNumber"
                                                    label="Part Number"
                                                    value={this.state.formData.newPartNumber || ''}
                                                    margin="normal"
                                                    fullWidth
                                                    disabled
                                                />
                                                <TextField
                                                    id="description"
                                                    name="description"
                                                    label="Description"
                                                    value={this.state.formData.description || ''}
                                                    margin="normal"
                                                    fullWidth
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{textAlign:'center'}}>
                                                <AllIcon icon={icons.lock} size='xxxlarge' />
                                                <br/>
                                                <br/>
                                                <Typography variant='h4'>Part Is Locked</Typography>
                                            </Grid>                          
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </> :
                        <>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Grid container spacing={3}>
                                            <Grid item xs={10}>
                                                <Typography variant="h6">
                                                    Part Details
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{textAlign: 'right'}}>
                                                {access.lock && 
                                                    <AllIcon icon={icons.unlock} onClick={()=>{
                                                        this.props.deployConfirmation(<>Are you sure you want to lock this part? <br/><br/> NOTE: You will no longer be able to update, stock, order, pick, despatch or invoice this part</>, 'Lock Part?', this.lockPart, 'warning');
                                                    }} tooltip='Lock Part'/>
                                                }
                                            </Grid>
                                        </Grid>    
                                        {formErrors && formErrors.generic && (
                                            <React.Fragment>
                                                <Typography component={"div"} style={{color: colors.red}}>
                                                    {formErrors.generic}
                                                </Typography>
                                            </React.Fragment>
                                        )}
                                        <form noValidate autoComplete="off">
                                            <TextField
                                                id="partType"
                                                name="partType"
                                                label="Part Type"
                                                value={this.state.formData.partType || ''}
                                                margin="normal"
                                                fullWidth
                                                disabled
                                                InputLabelProps={{
                                                    shrink: !!this.state.formData.partType
                                                }}
                                            />
                                            <TextField
                                                id="buildType"
                                                name="buildType"
                                                label="Build Type"
                                                value={this.state.formData.buildType || ''}
                                                margin="normal"
                                                fullWidth
                                                disabled
                                                InputLabelProps={{
                                                    shrink: !!this.state.formData.buildType
                                                }}
                                            />
                                            <TextField
                                                id="newPartNumber"
                                                name="newPartNumber"
                                                label="New Part Number"
                                                value={this.state.formData.newPartNumber || ''}
                                                error={formErrors && formErrors['newPartNumber'] && true}
                                                helperText={formErrors && formErrors['newPartNumber']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                                disabled
                                            />
                                            <TextField
                                                id="oldPartNumber"
                                                name="oldPartNumber"
                                                label="Old Part Number"
                                                value={this.state.formData.oldPartNumber || ''}
                                                error={formErrors && formErrors['oldPartNumber'] && true}
                                                helperText={formErrors && formErrors['oldPartNumber']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <TextField
                                                id="description"
                                                name="description"
                                                label="Description"
                                                value={this.state.formData.description || ''}
                                                error={formErrors && formErrors['description'] && true}
                                                helperText={formErrors && formErrors['description']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteSelect 
                                                    options={this.state.warrantyTermsList} 
                                                    label='Warranty Terms *'
                                                    onChange={this.handleSelectChange('warrantyTerms')}
                                                    error={formErrors && formErrors['warrantyTerms'] && true}
                                                    errorText={formErrors && formErrors['warrantyTerms']}
                                                    value={this.state.formData.warrantyTerms}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteSelect 
                                                    options={[
                                                        {value: 'No', label: 'No'},
                                                        {value: 'Yes', label: 'Yes'}
                                                    ]} 
                                                    label='Spare Part *'
                                                    onChange={this.handleSelectChange('sparePart')}
                                                    error={formErrors && formErrors['sparePart'] && true}
                                                    errorText={formErrors && formErrors['sparePart']}
                                                    value={this.state.formData.sparePart}
                                                />
                                            </FormControl>
                                            {showAssociatedSpareParts &&
                                                <FormControl fullWidth margin="normal">
                                                    <AutoCompleteMultiSelect 
                                                        options={this.state.partsList} 
                                                        label='Spare For Parts'
                                                        value={this.state.formData.spareForPart}
                                                        onChange={this.handleMultiSelectChange('spareForPart')}
                                                        error={formErrors && formErrors['spareForPart'] && true}
                                                        errorText={formErrors && formErrors['spareForPart']}
                                                    />
                                                </FormControl>
                                            }

                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteSelect 
                                                    options={[
                                                        {value: 'No', label: 'No'},
                                                        {value: 'Yes', label: 'Yes'}
                                                    ]} 
                                                    label='Electrical Connection *'
                                                    onChange={this.handleSelectChange('electricalConnection')}
                                                    error={formErrors && formErrors['electricalConnection'] && true}
                                                    errorText={formErrors && formErrors['electricalConnection']}
                                                    value={this.state.formData.electricalConnection}
                                                />
                                            </FormControl>

                                            {this.state.formData.electricalConnection === 'Yes' && 
                                                <FormControl fullWidth margin="normal">
                                                    <AutoCompleteMultiSelect 
                                                        options={this.state.ecList} 
                                                        label='Electrical Connections *'
                                                        value={this.state.formData.electricalConnections}
                                                        onChange={this.handleMultiSelectChange('electricalConnections')}
                                                        error={formErrors && formErrors['electricalConnections'] && true}
                                                        errorText={formErrors && formErrors['electricalConnections']}
                                                    />
                                                </FormControl>
                                            }

                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteMultiSelect 
                                                    options={this.state.nominalList} 
                                                    label='Nominal Codes *'
                                                    value={this.state.formData.nominalCode}
                                                    onChange={this.handleMultiSelectChange('nominalCode')}
                                                    error={formErrors && formErrors['nominalCode'] && true}
                                                    errorText={formErrors && formErrors['nominalCode']}
                                                />
                                            </FormControl>
                                            
                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteSelect 
                                                    options={[
                                                        {value: 'Yes', label: 'Product'},
                                                        {value: 'No', label: 'Service'}
                                                    ]} 
                                                    label='Product/Service *'
                                                    onChange={this.handleSelectChange('product')}
                                                    error={formErrors && formErrors['product'] && true}
                                                    errorText={formErrors && formErrors['product']}
                                                    value={this.state.formData.product}
                                                    noClear
                                                />
                                            </FormControl>
                                            {this.state.formData.product === 'Yes' &&
                                                <FormControl fullWidth margin="normal" component="fieldset">
                                                    <FormLabel component="legend">Exempt From Stock Take</FormLabel>
                                                    <RadioGroup
                                                        name="exemptFromStock"
                                                        value={this.state.formData.exemptFromStock}
                                                        onChange={this.handleChange}
                                                        error={formErrors && formErrors['exemptFromStock'] && true}
                                                        errorText={formErrors && formErrors['exemptFromStock']}
                                                        row
                                                    >
                                                        <FormControlLabel
                                                            value="No"
                                                            control={<Radio color="primary" />}
                                                            label="No"
                                                            labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                            value="Yes"
                                                            control={<Radio color="primary" />}
                                                            label="Yes"
                                                            labelPlacement="end"
                                                        />
                                                    </RadioGroup>
                                                    {formErrors && formErrors['exemptFromStock'] &&
                                                        <FormHelperText style={{color: colors.red}}> {formErrors['exemptFromStock']}</FormHelperText>
                                                    }
                                                </FormControl>
                                            }
                                            {
                                                this.state.formData.product === 'Yes' &&
                                                this.state.formData.exemptFromStock === 'No' &&
                                                <FormControl fullWidth margin="normal" component="fieldset">
                                                    <FormLabel component="legend">Does this require a stock movement?</FormLabel>
                                                    <RadioGroup
                                                        name="requiresStockMovement"
                                                        value={this.state.formData.requiresStockMovement}
                                                        onChange={this.handleChange}
                                                        error={formErrors && formErrors['requiresStockMovement'] && true}
                                                        errorText={formErrors && formErrors['requiresStockMovement']}
                                                        row
                                                    >
                                                        <FormControlLabel
                                                            value="No"
                                                            control={<Radio color="primary" />}
                                                            label="No"
                                                            labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                            value="Yes"
                                                            control={<Radio color="primary" />}
                                                            label="Yes"
                                                            labelPlacement="end"
                                                        />
                                                    </RadioGroup>
                                                    {formErrors && formErrors['requiresStockMovement'] &&
                                                        <FormHelperText style={{color: colors.red}}> {formErrors['requiresStockMovement']}</FormHelperText>
                                                    }
                                                </FormControl>
                                            }
                                            {this.state.formData.product === 'Yes' && 
                                            this.state.formData.exemptFromStock === 'No' &&
                                                <>
                                                    <TextField
                                                        id="stockThreshold"
                                                        name="stockThreshold"
                                                        label="Stock Take Threshold"
                                                        value={this.state.formData.stockThreshold || 0}
                                                        error={formErrors && formErrors['stockThreshold'] && true}
                                                        helperText={formErrors && formErrors['stockThreshold']}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        margin="normal"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                                                        }}
                                                        inputProps={{
                                                            min: '0',
                                                            max: '99'
                                                        }}
                                                        type="number"
                                                    />
                                                    {this.state.formData.stockThreshold > 0 &&
                                                        <FormHelperText>
                                                            Based on {part.stock.stock_current_qty > 10 ? 'current' : 'a'} stock of {part.stock.stock_current_qty > 10 ? part.stock.stock_current_qty : 100 }, this threshold will allow stock takes between {parseFloat((part.stock.stock_current_qty > 10 ? part.stock.stock_current_qty : 100) - ((part.stock.stock_current_qty > 10 ? part.stock.stock_current_qty : 100)*(this.state.formData.stockThreshold/100))).toFixed(2)} and {parseFloat(parseFloat(part.stock.stock_current_qty > 10 ? part.stock.stock_current_qty : 100) + parseFloat((part.stock.stock_current_qty > 10 ? part.stock.stock_current_qty : 100)*(this.state.formData.stockThreshold/100))).toFixed(2)} 
                                                        </FormHelperText>
                                                    }
                                                </>
                                            }
                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteSelect 
                                                    options={_.map(catagories, i => {return _.assign({value: i.pc_id, label: i.pc_name})})} 
                                                    label='Category *'
                                                    onChange={this.handleSelectChange('category')}
                                                    error={formErrors && formErrors['category'] && true}
                                                    errorText={formErrors && formErrors['category']}
                                                    value={this.state.formData.category}
                                                    noClear
                                                />
                                            </FormControl>
                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteSelect 
                                                    options={[
                                                        {value: 'Active', label: 'Active'},
                                                        {value: 'Replaced', label: 'Replaced'},
                                                        {value: 'Discontinued', label: 'Discontinued'}
                                                    ]}
                                                    label='Status *'
                                                    onChange={this.handleStatusChange}
                                                    error={(formErrors && formErrors['status'] && true) || ((this.state.part.packages && this.state.part.packages.length > 0) || (this.state.part.parts_linked && this.state.part.parts_linked.length > 0) )}
                                                    errorText={((this.state.part.packages && this.state.part.packages.length > 0) || (this.state.part.parts_linked && this.state.part.parts_linked.length > 0) ) ? 
                                                        'Part Status cannot be changed with linked parts / packages' :
                                                        (formErrors && formErrors['status'])}
                                                    value={this.state.formData.status}
                                                    disabled={((this.state.part.packages && this.state.part.packages.length > 0) || (this.state.part.parts_linked && this.state.part.parts_linked.length > 0) ) }
                                                />
                                            </FormControl>
                                            {this.state.showReplacedPart &&
                                                <FormControl fullWidth margin="normal">
                                                    <AutoCompleteSelect 
                                                        options={this.state.partsList} 
                                                        label='Replaced Part *'
                                                        onChange={this.handleSelectChange('replacedPart')}
                                                        error={formErrors && formErrors['replacedPart'] && true}
                                                        errorText={formErrors && formErrors['replacedPart']}
                                                        value={this.state.formData.replacedPart}
                                                    />
                                                </FormControl>
                                            }
                                            <FormControl margin="normal" component="fieldset">
                                                <FormLabel component="legend">Part Discount</FormLabel>
                                                <RadioGroup
                                                    name="partDiscount"
                                                    value={this.state.formData.partDiscount}
                                                    onChange={this.handleChange}
                                                    row
                                                >
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio color="primary" />}
                                                        label="No"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio color="primary" />}
                                                        label="Yes"
                                                        labelPlacement="end"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                            <FormControl fullWidth margin="normal" component="fieldset">
                                                <FormLabel component="legend">Serial Number Required</FormLabel>
                                                <RadioGroup
                                                    name="reqSerialNumber"
                                                    value={this.state.formData.reqSerialNumber || 'No'}
                                                    onChange={this.handleChange}
                                                    row
                                                >
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio color="primary" />}
                                                        label="No"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio color="primary" />}
                                                        label="Yes - System Generated"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Manual"
                                                        control={<Radio color="primary" />}
                                                        label="Yes - Manual"
                                                        labelPlacement="end"
                                                    />
                                                </RadioGroup>
                                                {formErrors && formErrors['reqSerialNumber'] && 
                                                    <FormHelperText>{formErrors['reqSerialNumber']}</FormHelperText>
                                                }
                                            </FormControl>
                                            <FormControl fullWidth margin="normal" component="fieldset">
                                                <FormLabel component="legend">Warranty Void Label</FormLabel>
                                                <RadioGroup
                                                    name="warrantyVoidLabel"
                                                    value={this.state.formData.warrantyVoidLabel}
                                                    onChange={this.handleChange}
                                                    row
                                                >
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio color="primary" />}
                                                        label="No"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio color="primary" />}
                                                        label="Yes"
                                                        labelPlacement="end"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                            
                                            <TextField
                                                id="barcodeNumber"
                                                name="barcodeNumber"
                                                label="Barcode Number"
                                                value={this.state.formData.barcodeNumber}
                                                error={formErrors && formErrors['barcodeNumber'] && true}
                                                helperText={formErrors && formErrors['barcodeNumber']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <DragFileInput
                                                id="barcodeImage"
                                                name="barcodeImage"
                                                label='Upload New Barcode Image (.png, .jpg)'
                                                file={this.state.formData.barcodeImage}
                                                errorText={formErrors && formErrors['barcodeImage']}
                                                onChange={this.handleFileChange}
                                                cancelOnClick={() => this.clearFile('barcodeImage')}
                                                emptyText='No barcode selected'
                                            />
                                            <Grid container>
                                                <Grid item xs={5}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <InputLabel shrink={true}>Current Barcode Image</InputLabel>
                                                        <br></br>
                                                        {this.state.formData.barcodeUrl ?
                                                            <div style={{display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', width: '170px'}}>
                                                                <ImageWithError alt='Barcode' width="175" src={`${this.state.formData.barcodeUrl}?${new Date().getTime()}`} type='WHITESPACE' style={{opacity: this.state.formData.deleteBarcode ? 0.2 : 1}} />
                                                                {this.state.formData.deleteBarcode && 
                                                                    <FormHelperText style={{background: 'rgba(0,0,0,0.7)', textAlign:'center', marginTop:0, padding: '0 5px'}}>
                                                                        <Typography variant="caption" style={{color: '#fff'}}>Image will be deleted on update</Typography>
                                                                    </FormHelperText>
                                                                }
                                                                <Tooltip title={this.state.formData.deleteBarcode ? 'Undo' : 'Delete'}>
                                                                    <div>{/* Need tag for tooltip */}
                                                                    <IconButton edge="bottom" onClick={() => this.deleteBarcode()} >
                                                                        <FALightIcon icon={this.state.formData.deleteBarcode ? 'undo' : 'trash-alt'} button noMargin/>
                                                                    </IconButton>
                                                                    </div>
                                                                </Tooltip>
                                                            </div> 
                                                        : 
                                                            '-'
                                                        }
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            
                                        </form>
                                        
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Grid container item spacing={3}>
                                        {this.state.part.packages && this.state.part.packages.length > 0 &&
                                            <Grid item xs={12}>
                                                <PaddedPaper
                                                    style={{
                                                        backgroundColor: this.state.formData.status !== 'Active' && colors.importantInfo,
                                                    }}
                                                >
                                                    <Grid container item spacing={1} alignItems='center'>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" gutterBottom><>Associated Packages</></Typography>
                                                            <Typography variant="body2" gutterBottom>Please Note: This part is used in the following packages, these may be affected by any changes to the part </Typography>
                                                        </Grid>
                                                        {_.map(this.state.part.packages, (i, index) =>
                                                            <>
                                                                <Grid item xs={10} key={index}>
                                                                    <Typography variant="body1">{i.p_name} </Typography>
                                                                    <Typography variant="caption" >{i.p_ref}</Typography>
                                                                </Grid>
                                                                <Grid item xs={2} style={{textAlign: 'right'}}>
                                                                    <AllIcon icon={icons.search} noMargin onClick={() => this.props.history.push(`/sales/package/${i.p_id}`)} tooltip='View Package'/>
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </PaddedPaper>  
                                            </Grid>
                                        }
                                        {this.state.part.parts_linked && this.state.part.parts_linked.length > 0 &&
                                            <Grid item xs={12}>
                                                <PaddedPaper
                                                    style={{
                                                        backgroundColor: this.state.formData.status !== 'Active' && colors.importantInfo,
                                                    }}
                                                >
                                                    <Grid container item spacing={1} alignItems='center'>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" gutterBottom><>Linked To Parts</></Typography>
                                                            <Typography variant="body2" gutterBottom>Please Note: This part is linked to the following parts, these may be affected by any changes to the part </Typography>
                                                        </Grid>
                                                        {_.map(this.state.part.parts_linked, (i, index) =>
                                                            <>
                                                                <Grid item xs={1} key={index}>
                                                                    <ImageWithError src={i.linked_part?.default_image?.thumbnail_file_path} style={{maxWidth: '30px', maxHeight: '30px'}}/>
                                                                </Grid>
                                                                <Grid item xs={9} key={index}>
                                                                    <Typography variant="body1">{i.linked_part.part_number} </Typography>
                                                                    <Typography variant="caption" >{i.linked_part.part_description}</Typography>
                                                                </Grid>
                                                                <Grid item xs={2} style={{textAlign: 'right'}}>
                                                                    <AllIcon icon={icons.search} noMargin onClick={() => this.props.history.push(`/parts/view/${i.linked_part.part_id}`)} tooltip='View Part'/>
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>

                            </Grid>
                            
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="body2" gutterBottom><b>Add Linked Parts</b></Typography>
                                        <AutoCompleteSelect
                                            label='Parts To Link'
                                            fullWidth
                                            value={null}
                                            options={_.filter(this.state.partsList, i => !_.find(_.keys(this.state.formData.linkedParts), j => parseInt(j) === parseInt(i.value) ))}
                                            onChange={this.handleLinkedPartsChange}
                                            noClear
                                        />
                                        {_.keys(this.state.formData.linkedParts).length > 0 &&
                                            <List>
                                                {_.map(_.orderBy(_.keys(this.state.formData.linkedParts), [part => _.find(this.state.partsList, {value: parseInt(part)})?.part.part_number], ['asc']), part =>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <ImageWithError src={_.find(this.state.partsList, {value: parseInt(part)})?.part?.default_image?.thumbnail_file_path} 
                                                                style={{maxWidth: '35px', maxHeight: '35px'}}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText 
                                                            primary={_.find(this.state.partsList, {value: parseInt(part)})?.part.part_number}
                                                            secondary={_.find(this.state.partsList, {value: parseInt(part)})?.part.part_description}  
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <TextField
                                                                type='number'
                                                                value={this.state.formData.linkedParts[part]}
                                                                onChange={this.handleLinkedPartsChangeQty(part)}
                                                                error={!parseFloat(this.state.formData.linkedParts[part]) || parseFloat(this.state.formData.linkedParts[part]) <= 0}
                                                                helperText={!parseFloat(this.state.formData.linkedParts[part]) || parseFloat(this.state.formData.linkedParts[part]) <= 0 ? 'Invalid Quantity' : ''}
                                                            />
                                                            <AllIcon
                                                                icon={icons.delete}
                                                                onClick={this.handleLinkedPartsRemove(part)}
                                                                noMargin
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )}
                                            </List>
                                        }
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="body2" gutterBottom>
                                            <b>Commodity Codes</b>
                                        </Typography>
                                        <Grid container item spacing={3}>
                                            <Grid item lg={5}>
                                                <FormControl className={classes.ccGroup} fullWidth style={{marginTop: 16}}>
                                            <InputLabel htmlFor="importCommodity1" shrink={true}>Import</InputLabel>
                                            <div style={{display:'flex-inline', marginTop:8}}>
                                                <FormControl error={formErrors && formErrors['importCommodityCode'] && true}  margin="normal">
                                                    <Input
                                                        id="importCommodity1"
                                                        name="importCommodity1"
                                                        className={classes.cc1}
                                                        value={this.state.formData.importCommodity1}
                                                        onChange={this.handleChange}
                                                        onBlur={this.commodityCodeBlur}
                                                        variant="filled"
                                                        inputProps={{
                                                            ref:this.ccInput1,
                                                            size:2,
                                                            maxLength:2,
                                                            style: {
                                                                letterSpacing: 8
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl error={formErrors && formErrors['importCommodityCode'] && true} margin="normal">
                                                    <Input
                                                        id="importCommodity2"
                                                        name="importCommodity2"
                                                        className={classes.cc2}
                                                        value={this.state.formData.importCommodity2}
                                                        onChange={this.handleChange}
                                                        onBlur={this.commodityCodeBlur}
                                                        variant="filled"
                                                        inputProps={{
                                                            ref:this.ccInput2,
                                                            size:2,
                                                            maxLength:2,
                                                            style: {
                                                                letterSpacing: 8
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl error={formErrors && formErrors['importCommodityCode'] && true}  margin="normal">
                                                    <Input
                                                        id="importCommodity3"
                                                        name="importCommodity3"
                                                        className={classes.cc3}
                                                        value={this.state.formData.importCommodity3}
                                                        onChange={this.handleChange}
                                                        onBlur={this.commodityCodeBlur}
                                                        variant="filled"
                                                        inputProps={{
                                                            ref:this.ccInput3,
                                                            size:11,
                                                            maxLength:6,
                                                            style: {
                                                                letterSpacing: 8
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                                {formErrors && formErrors['importCommodityCode'] &&
                                                    <FormHelperText style={{color:colors.red}}>{formErrors['importCommodityCode']}</FormHelperText>
                                                }
                                            </div>
                                        </FormControl>
                                            </Grid>
                                            <Grid item lg={7}>
                                                <FormControl className={classes.ccGroup} style={{marginTop: 16}} >
                                                    <InputLabel htmlFor="exportCommodity1" shrink={true}>Export</InputLabel>
                                                    <div style={{display:'flex-inline', marginTop:8}}>
                                                        <FormControl error={formErrors && formErrors['exportCommodityCode'] && true}  margin="normal">
                                                            <Input
                                                                id="exportCommodity1"
                                                                name="exportCommodity1"
                                                                className={classes.cc1}
                                                                value={this.state.formData.exportCommodity1}
                                                                onChange={this.handleChange}
                                                                onBlur={this.commodityCodeBlur}
                                                                variant="filled"
                                                                inputProps={{
                                                                    ref:this.ccExportInput1,
                                                                    size:2,
                                                                    maxLength:2,
                                                                    style: {
                                                                        letterSpacing: 8
                                                                    }
                                                                }}
                                                            />
                                                        </FormControl>
                                                        <FormControl error={formErrors && formErrors['exportCommodityCode'] && true} margin="normal">
                                                            <Input
                                                                id="exportCommodity2"
                                                                name="exportCommodity2"
                                                                className={classes.cc2}
                                                                value={this.state.formData.exportCommodity2}
                                                                onChange={this.handleChange}
                                                                onBlur={this.commodityCodeBlur}
                                                                variant="filled"
                                                                inputProps={{
                                                                    ref:this.ccExportInput2,
                                                                    size:2,
                                                                    maxLength:2,
                                                                    style: {
                                                                        letterSpacing: 8
                                                                    }
                                                                }}
                                                            />
                                                        </FormControl>
                                                        <FormControl error={formErrors && formErrors['exportCommodityCode'] && true}  margin="normal">
                                                            <Input
                                                                id="exportCommodity3"
                                                                name="exportCommodity3"
                                                                className={classes.cc3}
                                                                value={this.state.formData.exportCommodity3}
                                                                onChange={this.handleChange}
                                                                onBlur={this.commodityCodeBlur}
                                                                variant="filled"
                                                                inputProps={{
                                                                    ref:this.ccExportInput3,
                                                                    size:6,
                                                                    maxLength:4,
                                                                    style: {
                                                                        letterSpacing: 8
                                                                    }
                                                                }}
                                                            />
                                                        </FormControl>
                                                        {formErrors && formErrors['exportCommodityCode'] &&
                                                            <FormHelperText style={{color:colors.red}}>{formErrors['exportCommodityCode']}</FormHelperText>
                                                        }
                                                    </div>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="body2" gutterBottom>
                                            <b>Technical Library</b>
                                        </Typography>
                                        <FormControl fullWidth margin="normal" component="fieldset">
                                            <FormLabel component="legend">Part File Available in Technical Library (PDF Only)</FormLabel>
                                            <RadioGroup
                                                name="technicalLibraryAccess"
                                                value={this.state.formData.technicalLibraryAccess}
                                                onChange={this.handleChange}
                                                row
                                            >
                                                <FormControlLabel
                                                    value="No"
                                                    control={<Radio color="primary" />}
                                                    label="No"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    value="Yes"
                                                    control={<Radio color="primary" />}
                                                    label="Yes"
                                                    labelPlacement="end"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteMultiSelect 
                                                options={this.state.partsList} 
                                                label='Associated Parts - Technical Library'
                                                value={this.state.formData.associatedParts}
                                                onChange={this.handleMultiSelectChange('associatedParts')}
                                                error={formErrors && formErrors['associatedParts'] && true}
                                                errorText={formErrors && formErrors['associatedParts']}
                                            />
                                        </FormControl>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="body2" gutterBottom>
                                            <b>Part Weight &amp; Dimensions</b>
                                        </Typography>
                                        <TextField
                                            id="partWeight"
                                            name="partWeight"
                                            label="Part Weight"
                                            value={this.state.formData.partWeight}
                                            onChange={this.handleChange}
                                            onBlur={this.handleToDecimalPlace(7)}
                                            margin="normal"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">g</InputAdornment>
                                            }}
                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                            inputProps={{
                                                min: '0'
                                            }}
                                            type="number"
                                            error={     formErrors && formErrors['partWeight']}
                                            helperText={formErrors && formErrors['partWeight']}
                                            fullWidth
                                        />
                                        <Typography variant="subtitle2" style={{flex: 1}}>
                                            <FALightIcon icon='info-circle' size='small' noMargin /> 1000g = 1kg
                                        </Typography>
                                        <Grid container item spacing={3}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id="partWidth"
                                                    name="partWidth"
                                                    label="Width"
                                                    value={this.state.formData.partWidth}
                                                    onChange={this.handleChange}
                                                    onBlur={this.handleToDecimalPlace(0)}
                                                    margin="normal"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                                    }}
                                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                                    inputProps={{
                                                        min: '0'
                                                    }}
                                                    type="number"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id="partHeight"
                                                    name="partHeight"
                                                    label="Height"
                                                    value={this.state.formData.partHeight}
                                                    onChange={this.handleChange}
                                                    onBlur={this.handleToDecimalPlace(0)}
                                                    margin="normal"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                                    }}
                                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                                    inputProps={{
                                                        min: '0'
                                                    }}
                                                    type="number"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id="partDepth"
                                                    name="partDepth"
                                                    label="Depth"
                                                    value={this.state.formData.partDepth}
                                                    onChange={this.handleChange}
                                                    onBlur={this.handleToDecimalPlace(0)}
                                                    margin="normal"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                                    }}
                                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                                    inputProps={{
                                                        min: '0'
                                                    }}
                                                    type="number"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="body2" gutterBottom>
                                            <b>Shipping Weight &amp; Dimensions</b>
                                        </Typography>
                                        <TextField
                                            id="shippingWeight"
                                            name="shippingWeight"
                                            label="Shipping Weight"
                                            value={this.state.formData.shippingWeight}
                                            onChange={this.handleChange}
                                            onBlur={this.handleToDecimalPlace(7)}
                                            margin="normal"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">g</InputAdornment>
                                            }}
                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                            inputProps={{
                                                min: '0'
                                            }}
                                            type="number"
                                            error={     formErrors && formErrors['shippingWeight']}
                                            helperText={formErrors && formErrors['shippingWeight']}
                                            fullWidth
                                        />
                                        <Typography variant="subtitle2" style={{flex: 1}}>
                                            <FALightIcon icon='info-circle' size='small' noMargin /> 1000g = 1kg
                                        </Typography>
                                        <Grid container item spacing={3}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id="shippingWidth"
                                                    name="shippingWidth"
                                                    label="Width"
                                                    value={this.state.formData.shippingWidth}
                                                    onChange={this.handleChange}
                                                    onBlur={this.handleToDecimalPlace(0)}
                                                    margin="normal"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                                    }}
                                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                                    inputProps={{
                                                        min: '0'
                                                    }}
                                                    type="number"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id="shippingHeight"
                                                    name="shippingHeight"
                                                    label="Height"
                                                    value={this.state.formData.shippingHeight}
                                                    onChange={this.handleChange}
                                                    onBlur={this.handleToDecimalPlace(0)}
                                                    margin="normal"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                                    }}
                                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                                    inputProps={{
                                                        min: '0'
                                                    }}
                                                    type="number"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id="shippingDepth"
                                                    name="shippingDepth"
                                                    label="Depth"
                                                    value={this.state.formData.shippingDepth}
                                                    onChange={this.handleChange}
                                                    onBlur={this.handleToDecimalPlace(0)}
                                                    margin="normal"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                                    }}
                                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                                    inputProps={{
                                                        min: '0'
                                                    }}
                                                    type="number"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        <div className='buttonRow'>
                                        <BackButton props={this.props} />
                                            <Button onClick={this.handleConfirmationOpen}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                Update
                                            </Button>
                                        </div>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                            <ConfirmationDialog 
                                open={this.state.confirmationOpen} 
                                success={this.handleConfirmationSuccess} 
                                close={this.handleConfirmationClose} 
                                title="Update This Part?" 
                                message="Are you sure you want to update this part?"
                            />
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarOpen}
                                onClose={this.handleSnackbarClose}
                                message='You have successfully updated this part'
                            />
                        </>
                )}
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog:       (content, title, variant, size, style)    => { dispatch(deployDialog(content, title, variant, size, style)) },
        closeDialog:        ()                                        => { dispatch(closeDialog()) },
        deployConfirmation: (message, title, success, variant = null) => { dispatch(deployConfirmation(message, title, success,false, variant)) },
    }
}
export default connect(null, mapDispatchToProps)(withStyles(styles)(UpdatePart));