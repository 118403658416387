import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';
import EmailDialog from 'Components/Common/Dialogs/EmailDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE } from 'Constants';
import IconHelper from 'Helpers/IconHelper';
import _ from 'lodash';
import moment from 'moment';
import API from '../../../API';
import DataTable from '../../Common/DataTables/CiDataTable';
import DatePicker from '../../Common/DatePickers/DatePicker';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon from '../../Common/Icons/FontAwesome/FASolidIcon';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import SnackBar from '../../Common/SnackBars/SnackBar';
import { statusColour } from './../../../Helpers/ColourHelper';
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import { colors } from './../../../Helpers/ColourHelper';
import StickyNotes        from 'Components/Common/StickyNotes/StickyNotes';

const styles = () => ({
    card: {
        display: 'flex', 
        justifyContent:'center', 
        alignItems:'center', 
        textAlign:'left'
    },
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }
});

const initialState = () => ({
    searchCriteria: {
        keywords:   '',
        status:     'All',
        dateFrom:   null,
        dateTo:     null,
        site:       'All',
    },
    access: {
        viewQuotation: false,
        'repeat-quotation': false
    },
    gridPagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    },
    quote: {},
    searchResults: {},
    isLoading: true,
    emailDialogOpen: false,
    customerName: '',
    sites:              null
});
class QuotationSearch extends Component {
    constructor(props) {
        super(props);    
        this.state = initialState();
    }

    componentDidMount = () => {
        !this.props.aid && this.getCustomerSites();
        this.getSearchData();
    }

    getSearchData = () => {
        const params = {
            keywords:   this.state.searchCriteria.keywords,
            status:     this.state.searchCriteria.status,
            dateFrom:   this.state.searchCriteria.dateFrom !== null ? moment(this.state.searchCriteria.dateFrom).format('YYYY-MM-DD') : null,
            dateTo:     this.state.searchCriteria.dateTo !== null   ? moment(this.state.searchCriteria.dateTo).format('YYYY-MM-DD')   : null,
            site:       this.props.aid ? this.props.aid : (this.state.searchCriteria.site === 'All'    ? null                                                            : this.state.searchCriteria.site),
        }
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([
                API.get('/staff/my/access/check/view-customer-quotation'),
                API.get(`/customers/${this.props.cid}/sales/quotations`, {params}),
                API.get(`/sales/quotations/stats`, {
                    params: {
                        customerId: this.props.cid,
                        site:       params.site
                    }
                }),
                API.get(`/staff/my/access/check/repeat-quotation`)
            ])
            .then(([accessRes, searchRes, statsRes, repeatAccess]) => {
                let customer = _.first(searchRes.data, {'quote_cust_id': this.props.cid});
                _.map(searchRes.data, q => {
                    // Status Colour
                    q.colour = statusColour(q.quote_status);
                    // Activity Log
                    let log = _.filter(q.activity_log, { 'cq_log_type': 'Email Sent' });
                    q.email_sent = log.length > 0 ? true : false

                    switch(q.quote_status){
                        case 'Open':
                            q.toolTip1 = 'Quote ' + q.quote_status;
                            q.toolTip2 = 'For ' + moment().diff(moment(q.quote_date), 'days') + ' Days';
                            q.icon = 'file';
                        break;
                        case 'Ordered':
                            q.toolTip1 = 'Quote ' + q.quote_status;
                            q.toolTip2 = moment(q.quote_ordered_datetime).format('DD/MM/YYYY');
                            q.icon = 'check';
                        break;
                        case 'Closed':
                        case 'Expired':
                            q.toolTip1 = 'Quote ' + q.quote_status;
                            q.toolTip2 = moment(q.quote_closed_datetime).format('DD/MM/YYYY');
                            q.icon = 'ban';
                        break;
                        default:
                        break;
                    }
                });
                this.setState({
                    ...this.state,
                    access: {
                        viewQuotation: (accessRes.data && accessRes.data.has_access) || false,
                        'repeat-quotation': (repeatAccess.data && repeatAccess.data.has_access) || false
                    },
                    searchResults: searchRes.data,
                    statusStats: statsRes.data,
                    customerName: customer && customer.quote_cust_name,
                    isLoading: false
                });
            });
        });
    }

    handleSearchKeyword = (e) => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                keywords: e.target.value
            },
            //gridPagination: initialState.gridPagination
        }, 
        () => {
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            searchCriteria:initialState().searchCriteria,
            //gridPagination: initialState().gridPagination
        }, 
        () => {
            //this.props.history.replace();
            this.getSearchData();
        });
    }

    downloadQuote = rowData => {
        API.get('/sales/quotations/' + rowData.quote_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    // EMAIL
    handleEmailOpen = rowData => {
        API.get('/sales/quotations/' + rowData.quote_id).then(result => {
            const dialog = <EmailDialog
                                customerId={rowData.quote_cust_id}
                                customerContactId={rowData.quote_cust_contact_id}
                                type='quote'
                                id={rowData.quote_id}
                                pickingDocuments={result.data.pickingDocuments}
                            />
            this.props.deployDialog(dialog, 'You Are About To Email - Quotation', 'success');
        });
    }
    
    handleEmailClose = () => {
        this.props.closeDialog();
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                [fieldName]: selectedOption && selectedOption.value
            },
            //gridPagination: initialState().gridPagination
        }, 
        () => {
            this.getSearchData();
        });
    };

    handleDateChange = name => date => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                [name]: date
            },
            //gridPagination: initialState().gridPagination
        }, 
        () => {
            this.getSearchData();
        });
    };

    getCustomerSites = () => {
        API.get(`/customers/${this.props.cid}`)
        .then(res => {
            this.setState({
                sites: [
                    {value: 'All', label:'All'},
                    {   value: res.data.registered_address.address_id, 
                        label:  res.data.registered_address.address_postcode + ' | ' + res.data.registered_address.address_name 
                    },
                    ..._.map(res.data.trading_addresses, i => {
                        return _.assign({
                            value: i.address_id,
                            label: i.address_postcode + ' | ' + i.address_name  
                        })
                     }),
                    ..._.map(res.data.account_addresses, i => {
                        return _.assign({
                            value: i.address_id,
                            label: i.address_postcode + ' | ' + i.address_name  
                        })
                     }),
                    ..._.map(res.data.site_addresses, i => {
                        return _.assign({
                            value: i.address_id,
                            label: i.address_postcode + ' | ' + i.address_name  
                        })
                     })
                    ]
            })
        })
    }

    handleShowStickyNote = quoteId => { this.props.deployDialog(<StickyNotes quoteId={quoteId} dialog/>, 'Sticky Notes', null,'xs') }

    render() {
        const { access, customerName, isLoading, searchCriteria, gridPagination, sites } = this.state;
        const { classes } = this.props;
        if(customerName !== '') {
            return (
                <Grid container spacing={3} xs={12} style={{margin:'1em'}}>
                    <Grid item container xs={12}>
                        <Grid item xs={12} style={{paddingRight: '2em'}}>
                            <PaddedPaper>
                                <form noValidate autoComplete="off">
                                    <Grid container spacing={1} xs={12}>
                                        <Grid item xs={12} lg={2}>
                                            <FormControl margin="none" fullWidth>
                                                <TextField label="Keywords" fullWidth value={searchCriteria?.keywords} onChange={this.handleSearchKeyword} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <FormControl margin="none" fullWidth>
                                                <AutoCompleteSelect 
                                                    options={[
                                                        {value: 'All', label: 'All'},
                                                        {value: 'Open', label: 'Open'},
                                                        {value: 'Ordered', label: 'Ordered'},
                                                        {value: 'Closed', label: 'Closed'},
                                                        {value: 'Expired', label: 'Expired'}
                                                    ]} 
                                                    label='Status'
                                                    onChange={this.handleSelectChange('status')}
                                                    value={searchCriteria?.status}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {sites &&
                                            <Grid item xs={12} lg={2}>
                                                <FormControl margin="none" fullWidth>
                                                    <AutoCompleteSelect 
                                                        options={sites} 
                                                        label='Site'
                                                        onChange={this.handleSelectChange('site')}
                                                        value={searchCriteria?.site}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item xs={12} lg={2}>
                                            <DatePicker
                                                type='date'
                                                id="dateFrom"
                                                name="dateFrom"
                                                label="Date From"
                                                margin="none"
                                                autoOk={true}
                                                value={searchCriteria?.dateFrom}
                                                onChange={this.handleDateChange('dateFrom')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <DatePicker
                                                type='date'
                                                id="dateTo"
                                                name="dateTo"
                                                label="Date To"
                                                margin="none"
                                                autoOk={true}
                                                value={searchCriteria?.dateTo}
                                                onChange={this.handleDateChange('dateTo')}                                            
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <div className='buttonRow'>
                                                <Button onClick={this.resetSearch}
                                                        variant="outlined"
                                                        color="default"
                                                        >
                                                    <FALightIcon icon='undo' button />
                                                    Reset Search
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{paddingRight:'2.5em'}}>
                            {isLoading ? <LoadingCircle /> : (
                                <DataTable  
                                    config={{
                                        key: 'quote_id',
                                        pagination: true,
                                        //page: gridPagination.page,
                                        //rowsPerPage: gridPagination.rowsPerPage,
                                        //gridPageChanged: this.gridPageChanged,
                                        //gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                        persistenceId: this.persistenceId,
                                        alternatingRowColours: true,
                                        responsiveimportant: true,
                                        isLoading: this.state.isLoading
                                    }}
                                    columns={[
                                        {
                                            heading: 'Quote Date',
                                            field: rowData => moment(rowData.quote_date).format("DD/MM/YYYY"),
                                            important: true,
                                            sizeToContent: true
                                        },
                                        {
                                            actionsCustomHeader:    true,
                                            sizeToContent:          true,
                                            alignment:              'left',
                                            heading:                'Reference',
                                            actions: rowData => {
                                                return [
                                                    {label: rowData.quote_reference + '-' + rowData.quote_reference_amendment, link: `/sales/quotation/view/${rowData.quote_id}`, type:'plainLink',}
                                                ]
                                            }
                                        },
                                        {
                                            heading: 'Discount',
                                            field: rowData => rowData.quote_type,
                                            important: true,
                                            fieldFormat: 'boolean',
                                            tooltip: rowData => rowData.quote_type,
                                            sizeToContent: true,
                                            alignment: 'center'
                                        },
                                        {
                                            heading: 'Delivery Address',
                                            field: rowData => rowData.quote_delivery_cust_address_name,
                                            important: true
                                        },
                                        {
                                            heading: 'Status',
                                            field: rowData =>   <React.Fragment>
                                                                    <Tooltip
                                                                        title={
                                                                            <div>
                                                                                <Typography variant="body2">{rowData.toolTip1}</Typography>
                                                                                <Typography variant="body2">{rowData.toolTip2}</Typography>
                                                                            </div>
                                                                        }
                                                                        placement="left"
                                                                    >
                                                                        <IconButton className={classes.iconButton}>
                                                                            <FALightIcon 
                                                                                icon={`${rowData.icon}`}
                                                                                button
                                                                                style={{
                                                                                    color: rowData.colour
                                                                                }}
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </React.Fragment>,
                                            important: true,
                                            alignment: 'center'
                                        },
                                        {
                                            heading: 'Total',
                                            field: rowData => ( 
                                                <React.Fragment>
                                                    {rowData.quote_total_incl_vat}
                                                    {' '}
                                                    <Tooltip 
                                                        title={
                                                            <div style={{display: 'flex', width: 200}}>
                                                                <div style={{width: '50%', textAlign:'right'}}>
                                                                    <Typography variant="body2">Sub Total</Typography>
                                                                    <Typography variant="body2">VAT ({rowData.vat_value.vat_value_percentage}%)</Typography>
                                                                    <Typography variant="body2">Total</Typography>
                                                                </div>
                                                                <div style={{width: '50%', textAlign:'right'}}>
                                                                    <Typography variant="body2">£{rowData.quote_sub_total}</Typography>
                                                                    <Typography variant="body2">£{rowData.quote_vat}</Typography>
                                                                    <Typography variant="body2">£{rowData.quote_total_incl_vat}</Typography>
                                                                </div>
                                                            </div>
                                                        } 
                                                        placement="left"
                                                    >
                                                        <Link>
                                                            <FASolidIcon 
                                                                icon='info-circle'
                                                                button 
                                                                size='small'
                                                                style={{
                                                                    color: 'rgba(0, 0, 0, 0.38)'
                                                                }}
                                                            />
                                                        </Link>
                                                    </Tooltip>
                                                </React.Fragment>
                                            ),
                                            important: true,
                                            fieldPrefix: '£',
                                            alignment: 'right',
                                        },
                                        {
                                            heading: 'Quoted By',
                                            field: rowData => rowData.quote_staff_name,
                                            important: true
                                        },
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Sticky Notes', icon: IconHelper.stickyNote, onClick: () => this.handleShowStickyNote(rowData.quote_id), color: (_.filter(rowData?.sticky_notes, i => !i.sn_deleted && !i.sn_completed).length > 0 && colors.yellow), hide: !_.filter(rowData?.sticky_notes, i => !i.sn_deleted)?.length},
                                                    {name: 'Repeat', icon: IconHelper.repeat, link: '/sales/quotation/add/repeat/' + rowData.quote_id, disabled: !access['repeat-quotation']},
                                                    {name: 'Download', icon: 'download', onClick: this.downloadQuote},
                                                    {name: 'Send Quote to Customer', icon: 'envelope', onClick: this.handleEmailOpen, disabled: rowData.quote_status !== 'Open' && true},
                                                    {name: 'View', icon: 'search', link: { pathname: '/sales/quotation/view/' + rowData.quote_id, state: {searchCriteria, gridPagination}}, disabled: !access.viewQuotation}
                                                    
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={this.state.searchResults}
                                />
                            )}
                        
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.emailSnackbarOpen}
                            onClose={this.handleEmailSnackbarClose}
                            message='You have successfully sent the email'
                        />
                    </Grid>
                </Grid>
            );
        } else {
            return <LoadingCircle />;
        }
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                          => dispatch(closeDialog()),
    deployDialog:       (content, title, variant)   => dispatch(deployDialog(content, title, variant)),
    clearPersistence:   (key)                       => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(QuotationSearch));