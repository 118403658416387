import React, { Component } from 'react';

import moment from 'moment';

import logo from 'Assets/Images/clenaware_logo_icon.png';

import { TableFooter } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { MultiDial } from 'Functions/MiscFunctions';

const styles = theme => ({
    tblHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    tblHeaderCell: {
        color:'white',
    },
    tblFooter: {
        backgroundColor: '#f2f2f2',
    }
});
const initialState = {
    colspan: ''
};
class Order extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount = () => {
        this.getColspan();
    }
    getColspan = () => {
        let colspan = (this.props.co.order_type === 'With Discount') 
                    ? 8
                    : 5;
            // add 1 if order has picks 
            colspan = (this.props.co.hasPick)
                    ? colspan + 1
                    : colspan;

        this.setState({
            ...this.state,
            colspan
        });
    }
    trimPartDescription = partDescription => {
        if(partDescription) {
            if(partDescription.length > 40){
                partDescription = partDescription.substring(0, 40) + '...';
            }
        } else {
            partDescription = '';
        }
        return partDescription;
    }
    render() {
        const { colspan } = this.state;
        const { classes, co } = this.props;
        
        const customerPhone = [];
        if(co.order_cust_contact_phone && co.order_cust_contact_mobile) {
            customerPhone.push(co.order_cust_contact_phone, co.order_cust_contact_mobile);
        } else if(co.order_cust_contact_phone) {
            customerPhone.push(co.order_cust_contact_phone);
        } else if(co.order_cust_contact_mobile) {
            customerPhone.push(co.order_cust_contact_mobile);
        }
        
        const deliveryPhone = [];
        if(co.order_delivery_cust_contact_phone && co.order_delivery_cust_contact_mobile) {
            deliveryPhone.push(co.order_delivery_cust_contact_phone, co.order_delivery_cust_contact_mobile);
        } else if (co.order_delivery_cust_contact_phone) {
            deliveryPhone.push(co.order_delivery_cust_contact_phone);
         } else if (co.order_delivery_cust_contact_mobile) {
            deliveryPhone.push(co.order_delivery_cust_contact_mobile);
         }

        return (
            <React.Fragment>
                <Grid container item spacing={1}>
                    <Grid item xs={5}>
                        <Grid container item spacing={1}>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Order Date</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {moment(co.order_date).format("DD/MM/YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Ordered By</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {co.order_staff_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Picking Date</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {co.order_picking_date === '0000-00-00' ? '-' : co.order_picking_date == null ? 'TBC' : moment(co.order_picking_date).format('DD/MM/YYYY')}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Despatch Date</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {co.order_despatch_date === '0000-00-00' ? '-' : co.order_despatch_date == null ? 'TBC' : moment(co.order_despatch_date).format('DD/MM/YYYY')}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Delivery Date</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {co.order_delivery_date === '0000-00-00' ? '-' : co.order_delivery_date == null ? 'TBC' : moment(co.order_delivery_date).format('DD/MM/YYYY')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                        <Grid container item spacing={1}>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Reference</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {co.full_reference}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Payment Terms</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {co.order_payment_terms}
                                </Typography>
                            </Grid>
                            {
                                co.payments.filter( i => { return ( i.op_txn_status === "Authorised" ) } ).map(i => 
                                    <>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Payment Method</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                { ( i.op_txn_method === 'Card' || i.op_txn_method === 'Stripe' ) ? 
                                                    ( ( i.op_txn_method === 'Stripe') ? <>Card - { i.op_txn_method}</> : <>Card - Barclaycard</>  ) 
                                                    : <>{i.op_txn_method}</> 
                                                }
                                            </Typography>
                                        </Grid>    
                                    </>
                                )
                            }
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>PO Number</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {co.order_po_details !== '' ? co.order_po_details : '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br></br>
                <Grid container item spacing={1}>
                    <Grid item xs={5}>
                        <Grid container item spacing={1}>
                            <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                                Customer
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Name</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {co.order_cust_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Address</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {co.order_cust_address.split('\n').map((item, key) => {
                                        return  <span key={key}>
                                                    {item}{co.order_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                </span>
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Contact</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {co.order_cust_contact_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Phone Number</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    <MultiDial numbers={customerPhone} />
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Email</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {co.order_cust_contact_email}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                        <Grid container item spacing={1}>
                            <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                                Delivery
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Name</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {co.order_delivery_cust_address_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Address</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {co.order_delivery_cust_address.split('\n').map((item, key) => {
                                        return  <span key={key}>
                                                    {item}{co.order_delivery_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                </span>
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Contact</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {co.order_delivery_cust_contact_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Phone Number</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    <MultiDial numbers={deliveryPhone} />
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Email</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {co.order_delivery_cust_contact_email}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <br></br>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tblHeader}>
                                <TableCell className={classes.tblHeaderCell}></TableCell>
                                <TableCell className={classes.tblHeaderCell}>Part Number</TableCell>
                                <TableCell className={classes.tblHeaderCell}>Part Description</TableCell>
                                <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Quantity</TableCell>
                                {co.hasPick && <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Quantity Picked</TableCell>}
                                <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Unit Price</TableCell>
                                {co.order_type && co.order_type === 'With Discount' &&
                                    <React.Fragment>
                                        <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Discount Rate</TableCell>
                                        <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>New Unit Price</TableCell>
                                        <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Discount Total</TableCell>
                                    </React.Fragment>
                                }
                                <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Total Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {co.order_details && co.order_details.map((row ,key) => (
                            <TableRow style={{borderBottom: '1px solid rgb(224, 224, 224)'}} key={key}>
                                <TableCell style={{height:65, borderBottom: 'none', verticalAlign: 'middle'}} align='center'>
                                    <img src={(row.part && row.part.default_image && row.part.default_image.library_thumbnail_url) ? row.part.default_image.thumbnail_file_path : logo} style={{overflow:'visible', maxHeight: 45, maxWidth: 45}} alt="" />
                                </TableCell>
                                <TableCell style={{whiteSpace:'nowrap'}}>{row.od_part_number}</TableCell>
                                <TableCell style={{whiteSpace:'nowrap', width:'30%'}}>
                                    {this.trimPartDescription(row.od_part_description)}<br/>
                                    {row.od_electrical_connection ? <> <span style={{color: '#757575', fontSize: '12px'}}>{`${row.od_electrical_connection} Connection`} </span><br></br> </>: ''}
                                    <span style={{color: '#757575', fontSize: '12px'}}>{row.od_warranty_name !== null && row.od_warranty_duration !== null ? row.od_warranty_name : ''}</span><br></br>
                                    <span style={{fontSize: '0.7rem', whiteSpace:'pre-wrap'}}>
                                        {row.od_notes && row.od_notes.split('\n').map((item, key) => {
                                            return <span key={key}>{item}<br/></span>
                                        })}
                                    </span>
                                </TableCell>
                                <TableCell style={{textAlign:'right'}}>{parseFloat(row.od_quantity)}</TableCell>
                                {co.hasPick && <TableCell style={{textAlign:'right'}}>{row.pick_detail?.opd_quantity_picked ? parseFloat(row.pick_detail.opd_quantity_picked) : '-'}</TableCell>}
                                <TableCell style={{textAlign:'right'}}>£{row.od_unit_price}</TableCell>
                                {co.order_type && co.order_type === 'With Discount' &&
                                    <React.Fragment>
                                        <TableCell style={{textAlign:'right'}}>{row.od_discount_rate}%</TableCell>
                                        <TableCell style={{textAlign:'right'}}>£{row.od_new_unit_price}</TableCell>
                                        <TableCell style={{textAlign:'right'}}>£{row.od_discount_total}</TableCell>
                                    </React.Fragment>
                                }
                                <TableCell style={{textAlign:'right'}}>£{row.od_total_price}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell style={{textAlign:'right', paddingRight: '8px'}} colSpan={colspan}><strong>Sub Total</strong></TableCell>
                                <TableCell style={{textAlign:'right'}}><strong>£{co.order_sub_total}</strong></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{textAlign:'right', paddingRight: '8px'}} colSpan={colspan}><strong>VAT ({co.vat_value && co.vat_value.vat_value_percentage}%)</strong></TableCell>
                                <TableCell style={{textAlign:'right'}}><strong>£{co.order_vat}</strong></TableCell>
                            </TableRow>
                            <TableRow className={classes.tblFooter}>
                                <TableCell style={{textAlign:'right', paddingRight: '8px'}} colSpan={colspan}><strong>Total</strong></TableCell>
                                <TableCell style={{textAlign:'right'}}><strong>£{co.order_total_incl_vat}</strong></TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                    <br></br>
                </Grid>
                <Grid container item spacing={1} className="mb-3">
                    <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                        Order Notes
                    </Grid>
                    <Grid item xs={12}>
                        {co.order_notes ?
                            co.order_notes.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br/></span>
                            })
                        :
                            'No Notes'
                        }
                    </Grid>
                </Grid>
                <Grid container item spacing={1} className="mb-3">
                    <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                        Picking Notes
                    </Grid>
                    <Grid item xs={12}>
                        {( co.order_picking_notes && co.order_picking_notes !== 'null' ) ?
                            co.order_picking_notes.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br/></span>
                            })
                        :
                            'No Notes'
                        }
                    </Grid>
                </Grid>
                {/* {
                    co.order_despach_notes &&
                    <Grid container item spacing={1} className="mb-3">
                        <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                            Despatch Notes
                        </Grid>
                        <Grid item xs={12}>
                            {co.order_notes ?
                                co.order_despach_notes.split('\n').map((item, key) => {
                                    return <span key={key}>{item}<br/></span>
                                })
                            :
                                'No Notes'
                            }
                        </Grid>
                    </Grid>
                } */}
                <Grid container item spacing={1}>
                    <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                        Courier Notes
                    </Grid>
                    <Grid item xs={12}>
                        {co.order_courier_notes ?
                            co.order_courier_notes.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br/></span>
                            })
                        :
                            'No Courier Notes'
                        }
                    </Grid>
                </Grid>
                <br></br>
            </React.Fragment>
        );
    }
}

export default (withStyles(styles)(Order));