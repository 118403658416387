import API from 'API';
import React from 'react';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ViewReturn from 'Components/CustomerReturns/CustomerReturns/ViewCustomerReturn/sections/ViewReturn';


const initialState = () => ({
    isLoading: true,
    cr: {}
})

class CustomerReturn extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getCustomerReturn();
    }

    getCustomerReturn = () => {
        API.get(`/customerReturns/returns/${this.props.cr_id}`)
        .then(res => {
            this.setState({ 
                cr: res.data, 
                isLoading: false 
            });
        })
    }

    render() {

        const { cr, isLoading } = this.state;

        if (isLoading) return (<LoadingCircle/>);

        return (
            <ViewReturn cr={cr} />
        )
    }
}

export default CustomerReturn