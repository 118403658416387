import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, TextField, Typography } from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconPicker from 'Components/Common/Inputs/IconPicker';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import InputHelper from 'Helpers/InputHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

const initialState = () => ({
    isLoading: true,
    folders: [],
    formData: {},

    changeIconDialog: {
        show: false,
        icon: null,
        pid: null,
        sid: null,
    },

    subjects: {},
    usedSubjects: {},
})

const disabledTopLevel = [
    'CC', 'Forwarded', 'Resend', 'Manual', 'BCC'
]

class ViewEmailFolders extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState();
        this.InputHelper = new InputHelper(this);
    }

    componentDidMount(){
        this.getEmailFolders();
    }

    getEmailFolders = () => {
        Promise.all([
            API.get(`/email/folders`),
            API.get(`/email/folders/validSubjects`)
        ]).then(([r, s]) => {
            let formData = {};
            let usedSubjects = {};
            _.each(_.orderBy(r.data, 'ef_order'), f => {
                let children = {};
                _.each(_.orderBy(f.sub_folders, 'ef_order', 'asc'), c => {
                    children[c.ef_id] = {
                        id: c.ef_id,
                        name: c.ef_name,
                        icon: c.ef_icon ?? 'folder',
                        subjects: JSON.parse(c.ef_subject),
                        order: c.ef_order,
                    }
                })
                
                formData = {
                    ...formData,
                    [f.ef_id]: {
                        id: f.ef_id,
                        name: f.ef_name,
                        icon: f.ef_icon ?? 'folder',
                        children: children,
                        order: f.ef_order,
                    }
                }
            })

            _.each(r.data, f => {
                let ucs = []; 

                _.each(f.sub_folders, c => {
                    ucs = [
                        ...ucs,
                        ...JSON.parse(c.ef_subject)
                    ]
                })

                usedSubjects[f.ef_name] = ucs;
            })

            this.setState({
                isLoading: false,
                folders: r.data,
                ogFormData: formData,
                formData: formData,
                subjects: s.data,
                usedSubjects: usedSubjects,
            })
        });
    }

    showChangeIcon = pid => (sid = null) => () => {
        this.setState({
            changeIconDialog: {
                show: true,
                pid: pid,
                sid: sid,
                icon: sid ? this.state.formData[pid].children[sid].icon : this.state.formData[pid].icon,
            }
        })
    }

    handleChangeIcon = () => {
        const { formData } = this.state;
        const { pid, sid, icon } = this.state.changeIconDialog;
        let parent = formData[pid];
        if (sid){
            let children = parent.children;
            let child = children[sid];
            child.icon = icon;
            children[sid] = child;
            parent.children = children;
        } else {
            parent.icon = icon;
        }
        formData[pid] = parent;
        this.setState({
            formData: formData,
            changeIconDialog: initialState().changeIconDialog,
        });
    }

    addChild = pid => () => {
        const { formData } = this.state;
        formData[pid].children = {
            ...formData[pid].children, 
            [_.keys(formData[pid].children).length + 1]: {
                id: _.keys(formData[pid].children).length + 1,
                name: 'Sub Folder',
                icon: 'folder',
                subjects: [],
                order: _.keys(formData[pid].children).length + 1
            }
        }
        this.setState({
            formData: formData,
        })
    }

    removeSubCategory = pid => sid => () => {
        const { formData } = this.state;
        let parent = formData[pid];
        let children = parent.children;
        delete children[sid];
        parent.children = children;
        formData[pid] = parent;
        this.setState({
            formData: formData,
        })
    }

    submit = () => {
        let formData = [];
        _.each(_.values(this.state.formData), t => {
            formData = [
                ...formData,
                {
                    id: t.id,
                    name: t.name,
                    icon: t.icon,
                    children: _.orderBy(_.values(t.children), 'name', 'asc'),
                    order: t.order,
                }
            ]
        });
        API.post(`/email/folders`, {folders: JSON.stringify(formData)}).then(this.getEmailFolders)
    }

    moveUp = pid => (sid = null) => () => {
        let topLevel = this.state.formData[pid];
        if (sid){
            
        } else {
            let replace = _.find(this.state.formData, f => f.order === topLevel.order - 1);
            replace.order = topLevel.order;
            topLevel.order = topLevel.order - 1;
            this.setState({
                formData: {
                    ...this.state.formData,
                    [topLevel.id]: topLevel,
                    [replace.id]: replace,
                }
            })
        }
    }

    moveDown = pid => sid => () => {
        let topLevel = this.state.formData[pid];
        if (sid){
            let children = topLevel.children;
            let child = children[sid];
            let replace = _.find(children, c => c.order === child.order + 1);
            replace.order = child.order;
            child.order = child.order + 1;
            topLevel.children = {...children, [child.id]: child, [replace.id]: replace};
            this.setState({
                formData: {
                    ...this.state.formData,
                    [topLevel.id]: topLevel,
                }
            })
        } else {
            let replace = _.find(this.state.formData, f => f.order === topLevel.order + 1);
            replace.order = topLevel.order;
            topLevel.order = topLevel.order + 1;
            this.setState({
                formData: {
                    ...this.state.formData,
                    [topLevel.id]: topLevel,
                    [replace.id]: replace,
                }
            })
        }
    }

    render(){
        const { isLoading, formData, changeIconDialog, subjects, usedSubjects } = this.state;

        if (isLoading) return <LoadingCircle />;

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                        Email Folders
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {_.map(_.orderBy(_.values(formData), 'order'), p => 
                        <ExpansionPanel>
                            <ExpansionPanelSummary 
                                expandIcon={
                                    <>
                                        {disabledTopLevel.includes(p.name) ?
                                            null
                                            :
                                            ( _.values(p.children).length > 0 ? 
                                                <AllIcon icon={IconHelper.arrowDown} white noMargin noPadding/> : 
                                                <AllIcon icon={IconHelper.plus} white noMargin noPadding  onClick={this.addChild(p.id)}/> )}
                                    </>
                                }
                            >
                                <Grid container style={{width: '100%', alignItems: 'center'}}>
                                    <Grid item>
                                        <AllIcon
                                            icon={p.icon}
                                            white
                                            onClick={this.showChangeIcon(p.id)()}
                                            noPadding
                                            noMargin
                                        />
                                    </Grid>
                                    <Grid item >
                                        <Typography
                                            style={{marginLeft: '1em'}}
                                        >{p.name}</Typography>
                                    </Grid>
                                    <Grid item style={{marginLeft: 'auto', marginRight: disabledTopLevel.includes(p.name) && 20}}>
                                        <Grid container >
                                            <Grid item>
                                                <AllIcon
                                                    icon={IconHelper.arrowUp}
                                                    white
                                                    noMargin
                                                    size={12}
                                                    onClick={this.moveUp(p.id)(null)}
                                                    disabled={p.order === 1}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <AllIcon
                                                    icon={IconHelper.arrowDown}
                                                    white
                                                    noMargin
                                                    size={12}
                                                    onClick={this.moveDown(p.id)(null)}
                                                    disabled={p.order === _.keys(formData).length}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelSummary>
                            {_.values(p.children).length > 0 &&
                                <ExpansionPanelDetails>
                                    <Grid container spacing={1}
                                        style={{
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                            border: `1px solid ${colors.disabled}`,
                                            borderRadius: 5,
                                            marginTop: '1em'
                                        }}
                                    >
                                        {_.map(_.orderBy(_.values(p.children), 'order'), c =>
                                            <>
                                                <Grid item container style={{width: 'calc(100% - 70px)', paddingLeft: '1em', paddingTop: '1em' }}>
                                                    <Grid item style={{width: 50}}>
                                                        <AllIcon
                                                            icon={c.icon}
                                                            onClick={this.showChangeIcon(p.id)(c.id)}
                                                            noMargin
                                                        />
                                                    </Grid>
                                                    <Grid item style={{width: 'calc(100% - 50px)'}}>
                                                        <TextField
                                                            value={c.name}
                                                            name={`formData.${p.id}.children.${c.id}.name`}
                                                            onChange={e=>this.setState(this.InputHelper.setNestedValue(e.target.name, e.target.value))}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <AutoCompleteMultiSelect
                                                            noClear
                                                            name={`formData.${p.id}.children.${c.id}.subjects`}
                                                            value={c.subjects}
                                                            onChange={this.InputHelper.handleMultiSelectChange(`formData.${p.id}.children.${c.id}.subjects`)}
                                                            options={_.map([..._.filter(subjects[p.name], s => !_.includes(usedSubjects[p.name], s)),...c.subjects], s => ({label: s, value: s}))}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item style={{
                                                    width: 70,
                                                }}>
                                                    <AllIcon
                                                        icon={IconHelper.bin}
                                                        onClick={this.removeSubCategory(p.id)(c.id)}
                                                        size='medium'
                                                        noMargin
                                                    /><br/>
                                                    <Grid container 
                                                        style={{flexWrap: 'nowrap'}}
                                                    >
                                                        <Grid item>
                                                            <AllIcon
                                                                style={{padding: 7}}
                                                                noPadding
                                                                icon={IconHelper.arrowUp}
                                                                noMargin
                                                                size={12}
                                                                onClick={this.moveUp(p.id)(c.id)}
                                                                disabled={c.order === 1}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <AllIcon
                                                                style={{padding: 7}}
                                                                noPadding
                                                                icon={IconHelper.arrowDown}
                                                                noMargin
                                                                size={12}
                                                                onClick={this.moveDown(p.id)(c.id)}
                                                                disabled={c.order === _.keys(p.children).length}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}
                                                    style={{
                                                        borderBottom: `1px solid ${colors.disabled}`,
                                                        paddingTop: '1em',
                                                    }}
                                                ></Grid>
                                            </>
                                        )}
                                        <Grid item xs={12}
                                            style={{padding: '1em'}}
                                        >
                                            <Button
                                                onClick={this.addChild(p.id)}
                                                variant='outlined'
                                                fullWidth
                                            >Add Sub Folder <AllIcon icon={IconHelper.plus} style={{marginLeft: '1em'}} noMargin /></Button>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            }
                        </ExpansionPanel>
                    )}
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => this.props.deployConfirmation(
                            'Are you sure you want to update the email folders?',
                            'Update Email Folders',
                            this.submit
                        )}
                    >Update</Button>
                </Grid>
                <Dialog
                    open={changeIconDialog.show}
                    maxWidth='sm'
                >
                    <DialogTitle>Change Icon</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={8}
                                style={{
                                    alignItems: 'center'
                                }}
                            >
                                <IconPicker
                                    onChange={this.InputHelper.handleValueChange('changeIconDialog.icon')}
                                    icon={changeIconDialog.icon}
                                    freeType
                                    icons={['folder']}
                                />
                            </Grid>
                            <Grid item xs={4}
                                style={{textAlign: 'center'}}
                            >
                                <AllIcon
                                    icon={changeIconDialog.icon ?? 'folder'}
                                    noPadding
                                    noMargin
                                    size='xlarge'  
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={
                            this.InputHelper.handleButtonChange('changeIconDialog.show', false)} 
                            variant='outlined'    
                        >
                            Cancel
                        </Button>
                        <Button     
                            onClick={this.handleChangeIcon} 
                            color="primary"
                            variant='contained'
                        >
                            Set Icon
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
});

export default connect(null, mapDispatchToProps)(ViewEmailFolders);