import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import API from '../../../API';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import DatePicker from '../../Common/DatePickers/DatePicker';
import moment from 'moment';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import DataTable from '../../Common/DataTables/DataTable';

import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    keywords: '',
    dateFrom: null,
    dateTo: null,
    searchResults: {},
    isLoading: true
});
class VideoUpdateOrderSearch extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'video:UpdateOrderSearch';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        this.getSearchData();
    }

    getSearchData = () => {
        const params = {
            keywords: this.state.keywords,
            dateFrom: this.state.dateFrom ? (moment(this.state.dateFrom).format('DD/MM/YYYY')): null,
            dateTo: this.state.dateTo ? (moment(this.state.dateTo).format('DD/MM/YYYY')) : null
        }
        this.setState({
            isLoading: true
        }, () => {
            API.get('/videos/updateOrders/all', {
                params,
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    })
                }
            })
        })
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    handleDateChange = name => date => {
        this.setState({
            [name]: date
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
            dateFrom: null,
            dateTo: null
        }, 
        () => {
            this.getSearchData();
        });
    }

    downloadFile = rowData => {
        window.open(rowData.vuo_file_url, '_blank');
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Search Video Update Orders
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={6}>
                                        <DatePicker
                                            type="date"
                                            id="dateFrom"
                                            name="dateFrom"
                                            label="Date From"
                                            value={this.state.dateFrom}
                                            onChange={this.handleDateChange('dateFrom')}
                                            disableFuture={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <DatePicker
                                            type="date"
                                            id="dateTo"
                                            name="dateTo"
                                            label="Date To"
                                            value={this.state.dateTo}
                                            onChange={this.handleDateChange('dateTo')}
                                            disableFuture={true}
                                        />
                                    </Grid>
                                </Grid>
                                <FormControl margin="normal" fullWidth style={{marginTop: 32}}>
                                    <TextField label="Keywords" fullWidth value={this.state.keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                           
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key: 'vuo_id',
                                pagination: true,
                                responsiveImportant: true,
                                isLoading: this.state.isLoading,
                                alternatingRowColours: true
                            }}
                            columns={[
                                {
                                    heading: 'VUO Number',
                                    field: rowData => rowData.vuo_reference,
                                    important: true,
                                    main: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Title',
                                    field: rowData => rowData.vuo_title,
                                    important: true,
                                    truncate: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Download', icon: 'download', onClick: this.downloadFile},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}
const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(VideoUpdateOrderSearch);