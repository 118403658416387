import React, { useState, useEffect } from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Typography, FormControl, Button} from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea from 'Components/Common/Inputs/Textarea';

export default function WarrantyDialog(props) {
    const { warrantyDialogOpen, handleWarrantyDialogClose, selectedPartRow, warrantyUpdate, dialogRowData, warrantyTerms, handleWarrantyChange, selectedWarrantyTerm, handleWarrantyDialogSuccess } = props;
    const [overview, setOverview] = useState('');
    const { notes } = warrantyUpdate ? dialogRowData : selectedPartRow;
    useEffect(() => {
        const { partNumber, partDescription, warrantyName, warrantyDuration } = warrantyUpdate ? dialogRowData : selectedPartRow;
        let overviewText = '';
        if(warrantyName === null && warrantyDuration === null) {
            overviewText = `The warranty for ${partNumber} ${partDescription} is not yet set`;
        } else {
            overviewText = `The ${warrantyUpdate ? '' : 'default'} warranty for ${partNumber} ${partDescription} is ${warrantyName} (${warrantyDuration !== null ? warrantyDuration : 0} Months)`;
        }
        setOverview(overviewText);
    }, [warrantyUpdate, dialogRowData, selectedPartRow]);
    return(
        <Dialog 
            open={warrantyDialogOpen} 
            onClose={handleWarrantyDialogClose} 
            fullWidth={true} 
            scroll="body"
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle>Part Warranty Terms</DialogTitle>
            <DialogContent>
                <Typography variant="body2">
                    { overview }
                </Typography>
                <FormControl fullWidth margin="normal">
                    <AutoCompleteSelect 
                        options={warrantyTerms} 
                        label='Warranty Term *'
                        onChange={handleWarrantyChange('warrantyTerm')}
                        value={selectedWarrantyTerm && selectedWarrantyTerm.id}
                        disableSort={true}
                        noClear
                    />
                </FormControl>
                <Textarea
                    name="notes"
                    value={notes}
                    label="Part Notes"
                    rows={3}
                    onChange={handleWarrantyChange('notes')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleWarrantyDialogClose} variant="outlined" color="default">Close</Button>
                <Button onClick={handleWarrantyDialogSuccess} color="primary" variant="contained">{warrantyUpdate ? 'Update' : 'Add'}</Button>
            </DialogActions>
        </Dialog>
    );
}