import {
    Grid,
} from '@material-ui/core';
import API from 'API';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import React, { useEffect, useState } from 'react';

const EquipmentCustomerReturns = ({equipmentId}) => {

    const [customerReturns, setCustomerReturns]         = useState(true),
          [isLoading, setIsLoading]     = useState(true);

    useEffect(() => {
        API.get(`/equipment/${equipmentId}/customerReturns`)
        .then(res => {

            setCustomerReturns(res.data);

            setIsLoading(false);

        });
    }, []);

    return (
        isLoading ? <LoadingCircle /> :
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PaddedPaper>
                    <CiDataTable  
                        config={{
                            key: 'id',
                            pagination: true,
                            alternatingRowColours: false,
                            responsiveImportance: true,
                            isLoading: isLoading,
                        }}
                        columns={[
                            {
                                actionsCustomHeader:    true,
                                important:              true,
                                alignment:              'left',
                                heading:                'Reference',
                                actions: rowData => {
                                    let ret = [
                                        { label: rowData?.ref,    link: `/returns/customerReturn/${rowData.id}`,    type:'plainLink'},
                                       // { label: rowData?.ref,      class: "textSecondary"},
                                    ];
                                    return ret;
                                }
                            },
                            {
                                heading: 'Outcome',
                                field: rowData => rowData?.outcome,
                                important: true
                            },
                            {
                                heading: 'Date',
                                field: rowData => rowData?.date,
                                important: true,
                                fieldFormat: 'date'
                            },
                            {
                                actions: rowData => {
                                    return [
                                        {name: 'View Customer Return', icon: 'search', link: `/returns/customerReturn/${rowData.id}`}
                                    ]
                                }
                            }
                        ]}
                        rows={customerReturns}
                    />
                </PaddedPaper>
            </Grid>
        </Grid>
    )
}

export default EquipmentCustomerReturns;