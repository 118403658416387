import React from 'react';
import API 	 from 'API';
import _ 	 from 'lodash';
import { connect } from 'react-redux';

import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { addKeyDown, clearKeyDown, removeKeyDown, setKeyDown } from 'Actions/KeyDown/KeyDown';

const initialState = {
	isLoading: true,
	access: {
		pick:  	 false,
		woPick:  false,
		woBuild: false,
		sa: 	 false,
		sm: 	 false,
		sc: 	 false,
		st:	     false,
	}
}

class Dashboard extends React.Component {
	constructor(props) {
        super(props);
		this.state = initialState;
		this.buttons = [
			{
				label:  'Order Picking',
				page:   'Sales',
				access: 'pick',
				path:   '/sales/order/picking',
			},
			{
				label:  'Works Order Picking',
				page:   'Works Orders',
				access: 'woPick',
				path:   '/WorksOrders/all',
			},
			{
				label:  'Works Order Build',
				page:   'Works Orders',
				access: 'woBuild',
				path:   '/WorksOrders/builds',
			},
			{
				label:  'Stock Check',
				page:   'Stock',
				access: 'sa',
				path:   '/stock/activity',
			},
			{
				label:  'Stock Movement',
				page:   'Stock Movement',
				access: 'sm',
				path:   '/stock/movement',
			},
			{
				label:  'Stock Correction',
				page:   'Stock Correction',
				access: 'sc',
				path:   '/stock/correction',
			},
			/*
			{
				label:  'Stock Take',
				page:   'Stock Take',
				access: 'st',
				path:   '/stock/take',
			},
		*/
		]
	}
	
	componentWillMount(){
		//clear the stack on dashboard
		this.props.clearKeyDown();
		this.props.addKeyDown(this._handleKeyDown);
		this.getAccess();
	}

	componentWillUnmount = () => {
		this.props.removeKeyDown()
	}

	getAccess = () => {
		Promise.all([
			API.get('/staff/my/access/check/order-picking'), 
			API.get('/staff/my/access/check/works-order:pick'), 
			API.get('/staff/my/access/check/works-order:view'), 
			API.get('/staff/my/access/check/stock-activity'), 
			API.get('/staff/my/access/check/stock-movement'), 
			API.get('/staff/my/access/check/stock-correction'), 
			API.get('/staff/my/access/check/stock-take'), 
		]).then(([pick, woPick, woBuild, sa, sm, sc, st]) => {
			this.setState({
				access: {
					pick:  	 pick.data.has_access 	 || false,
					woPick:  woPick.data.has_access  || false,
					woBuild: woBuild.data.has_access || false,
					sa: 	 sa.data.has_access 	 || false,
					sm: 	 sm.data.has_access	     || false,
					sc: 	 sc.data.has_access	     || false,
					st:	     st.data.has_access	     || false,
				},
				isLoading: false,
			})
		})
	}

	_handleKeyDown = (e) => {
		if (_.isInteger(parseInt(e.key)) && this.buttons[parseInt(e.key - 1)]){
			this.changePage(this.buttons[parseInt(e.key - 1)].page, this.buttons[parseInt(e.key - 1)].path)();
		}
	}

	changePage = (title, page) => () => {
		this.props.changePage(title, page);
		this.props.history.push(page);
	}

  	render() {
		
		const { isLoading, access } = this.state;
		const { classes } = this.props;

		if (isLoading) return <LoadingCircle/>;
		return (
            <Grid container spacing={1}> 
				{_.map(_.filter(this.buttons, i => access[i.access]), (button, index) => 
					<Grid item xs={12}>
						<Button variant='contained' color='primary' fullWidth className={classes.Button}
							onClick={this.changePage(button.page,button.path)}
						>{button.label}</Button>
					</Grid>
				)}
            </Grid>
		);
  	}
}

const styles = theme => ({
    Button: {
		padding: '1.5em',
		fontWeight: 'bold',
	}
});

const mapDespatchToProps = dispatch => ({
	addKeyDown: (func) => dispatch(addKeyDown(func,'Dashboard')),
	setKeyDown: () => dispatch(setKeyDown('Dashboard')),
	removeKeyDown: () => dispatch(removeKeyDown('Dashboard')),
	clearKeyDown: () => dispatch(clearKeyDown())
});

export default connect(null, mapDespatchToProps)(withStyles(styles)(Dashboard));