import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';

import API from 'API';
import {
    Grid,
    Typography,
    Button
} from '@material-ui/core';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import { toggleDialog } from 'Functions/MiscFunctions';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import VehicleMaintenanceForm from './VehicleMaintenanceForm';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

const initialState = () => ({
    vehicleId: 0,
    maintenanceHistory: [],
    dialog: {
        maintenance: false,
        maintenanceId: 0,
    },
    access: {
        updateVehicle: false,
    },
    isLoading: true,
    formErrors: [],
    formData:{
        typeFilter: null
    }
})

class VehicleMaintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.toggleDialog = toggleDialog.bind(this);
    }

    componentDidMount() {
        const { vehicleId } = this.props;
        this.setState({
            ...this.state,
            vehicleId
        }, () => this.getAccess());
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-vehicle')
        ])
        .then(([manageRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    updateVehicle: (manageRes.data && manageRes.data.has_access) || false
                }
            }, () => this.getSearchData());
        }) 
    }

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, 
        () => {
            API.get(`/vehicles/${this.props.vehicleId}/maintenance`, { params: this.state.formData})
            .then(result =>  {

                if(result.data) {
                    this.setState({
                        isLoading: false,
                        maintenanceHistory: result.data
                    });
                }
            });
        });
    }

    openMaintenanceDialog = (id = 0, readOnly = false) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                maintenance: true,
                maintenanceId: id,
                readOnly
            },
        });
    }

    resetSearch = () => {
        this.setState({
            formData:{
                ...this.state.formData,
                typeFilter: null,
            }
        }, 
        () => {
            this.getSearchData();
        });
    }

    handleSelectChange(fieldName, selectedOption) {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, () => {
            this.getSearchData();
        });
    }

    render() {

        const { vehicleId, maintenanceHistory, dialog, isLoading, access, formData } = this.state;
        const { activeVehicle } = this.props;
        return (
            <>
                <PaddedPaper>
                    {(isLoading && (
                        <LoadingCircle />
                    )) || (
                        <Grid container item spacing={3}>                        
                            <Grid item xs={12}>
                                <Grid container item spacing={3}>                        
                                    <Grid item xs>
                                        <Typography variant="h6" paragraph>
                                            <b>Maintenance</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs align='right'>
                                        {access.updateVehicle && activeVehicle ?
                                            <Button 
                                                variant='outlined' 
                                                size="small" 
                                                onClick={() => this.openMaintenanceDialog()}
                                            >
                                                <AllIcon icon='plus' size="small" />
                                                Add Maintenance
                                            </Button>
                                        : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container item spacing={3} direction="row" alignItems="flex-end">
                                    <Grid item xs={5} md={3}>
                                        <AutoCompleteSelect 
                                            name="typeFilter"
                                            options={[
                                                {value: 'Service', label: 'Service'},
                                                {value: 'Repair', label: 'Repair'},
                                            ]} 
                                            label='Type'
                                            value={formData?.typeFilter}
                                            onChange={(v) => this.handleSelectChange('typeFilter', v)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button 
                                            onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                            margin="none"
                                        >
                                            <AllIcon icon='undo' button noMargin />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {maintenanceHistory.length > 0 ?
                                <>
                                <Grid item xs={12}>
                                    <DataTable  
                                        config={{
                                            key: 'id',
                                            pagination: true,
                                            alternatingRowColours: false,
                                            responsiveImportance: true,
                                            isLoading: isLoading,
                                            plainPagination: true,
                                        }}
                                        columns={[
                                            {
                                                heading: 'Type',
                                                field: rowData => rowData?.type,
                                                important: true,
                                                sizeToContent: true,
                                                hideInResponsiveDialog: true,
                                            },
                                            {
                                                heading: 'Date',
                                                field: rowData => moment(rowData?.date).format('MMM D, YYYY'),
                                                important: true,
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: 'Description',
                                                field: rowData => rowData?.desc,
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: 'No. of Documents',
                                                field: rowData => rowData?.docCount,
                                                alignment: 'right',
                                            },
                                            {
                                                heading: 'Mileage',
                                                field: rowData => rowData?.type == 'Service' ? rowData?.mileage?.toLocaleString() : 'N/A',
                                                important: true,
                                                alignment: 'right',
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Update', icon: 'pencil-alt', onClick: () => this.openMaintenanceDialog(rowData?.id), disabled: !access.updateVehicle || !activeVehicle},
                                                        {name: 'View', icon: 'search', onClick: () => this.openMaintenanceDialog(rowData?.id, true)},
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={maintenanceHistory}
                                    />
                                </Grid>
                                </>
                            :
                                <Grid item xs={12} align="center">
                                    <Typography variant="h6" paragraph color='textSecondary'>
                                        <em>No maintenance history found</em>
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    )}
                </PaddedPaper>
                <SmallFormDialog
                    open={dialog?.maintenance}
                    onClose={() => this.toggleDialog('maintenance')}
                    content={
                        <VehicleMaintenanceForm 
                            onClose={() => this.toggleDialog('maintenance', true)}
                            maintenanceId={dialog?.maintenanceId}
                            refresh={() => {this.toggleDialog('maintenance', true); this.props.refresh();}}
                            readOnly={dialog?.readOnly}
                            vehicleId={vehicleId}
                        />
                    }
                    maxWidth='sm'
                />
            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),

    }
}

export default connect(null, mapDispatchToProps)(VehicleMaintenance)