import React from 'react';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import {
    Typography,
    TextField,
    Button,
    FormControl,
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel
} from '@material-ui/core';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import {colors} from 'Helpers/ColourHelper';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import { handleDateChange, handleChangeUc, clearValue, handleChange, handleFileChange, getFormData } from 'Functions/FormFunctions';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { connect } from 'react-redux';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';

const initialState = {
    formData: {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        jobTitle: '',
        dob: null,
        password: '',
        confirmPassword: '',
        msgSound: 'MESSAGE_DEFAULT',
        // Driving Information
        hasDrivingLicence: 0,
        licenceNumber: 'XXXXXXXX',
        validFrom: null,
        validTo: null,
        endorsementOffences: 0,
        endorsementPoints: 0,
        drivingRecordFile: null,
        drivingRecordDate: null,
    },
    formErrors: {}
}

class AddStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleFileChange = handleFileChange.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
        this.handleChangeUc = handleChangeUc.bind(this);
        this.clearValue = clearValue.bind(this);
        this.handleChange = handleChange.bind(this);
        this.getFormData = getFormData.bind(this);
    }

    handleSubmit = () => {
        API.post('/staff', this.getFormData())
        .then(result => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                }, () => {
                    this.props.deploySnackBar("success", `The new staff member was successfully added`);
                });
            }
            this.props.scrollToTop();
        },
        err => API.handleError(err));
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    }

    render() {
        const { formData, formErrors } = this.state;
        return (
            <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Staff
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Staff Details
                            </Typography>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <form noValidate autoComplete="off">
                                <TextField
                                    name="firstName"
                                    label="First Name *"
                                    margin="normal" 
                                    autoComplete="off"
                                    error={formErrors && formErrors['firstName'] && true}
                                    helperText={formErrors && formErrors['firstName']}
                                    value={formData.firstName || ''}
                                    fullWidth
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    name="lastName"
                                    label="Last Name *"
                                    margin="normal"
                                    autoComplete="off"
                                    error={formErrors && formErrors['lastName'] && true}
                                    helperText={formErrors && formErrors['lastName']}
                                    value={formData.lastName || ''}
                                    fullWidth
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    name="email"
                                    label="Email Address *"
                                    margin="normal"
                                    autoComplete="off"
                                    error={formErrors && formErrors['email'] && true}
                                    helperText={formErrors && formErrors['email']}
                                    value={formData.email || ''}
                                    fullWidth
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    name="mobile"
                                    label="Mobile Number"
                                    margin="normal"
                                    autoComplete="off"
                                    error={formErrors && formErrors['mobile'] && true}
                                    helperText={formErrors && formErrors['mobile']}
                                    value={formData.mobile || ''}
                                    fullWidth
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    name="jobTitle"
                                    label="Job Title *"
                                    margin="normal"
                                    autoComplete="off"
                                    error={formErrors && formErrors['jobTitle'] && true}
                                    helperText={formErrors && formErrors['jobTitle']}
                                    value={formData.jobTitle || ''}
                                    fullWidth
                                    onChange={this.handleChange}
                                />
                                <DatePicker
                                    type="date"
                                    id="dob"
                                    name="dob"
                                    label="Date of Birth *"
                                    value={formData.dob}
                                    errorText={formErrors && formErrors['dob']}
                                    onChange={date => this.handleDateChange('dob', date, 'YYYY-MM-DD')}
                                    autoOk={true}
                                />
                                <TextField
                                    type="password"
                                    name="password"
                                    label="Password *"
                                    margin="normal"
                                    autoComplete="off"
                                    error={formErrors && formErrors['password'] && true}
                                    helperText={formErrors && formErrors['password']}
                                    value={formData.password || ''}
                                    fullWidth
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    type="password"
                                    name="confirmPassword"
                                    label="Confirm Password *"
                                    margin="normal"
                                    autoComplete="off"
                                    error={formErrors && formErrors['confirmPassword'] && true}
                                    helperText={formErrors && formErrors['confirmPassword']}
                                    value={formData.confirmPassword || ''}
                                    fullWidth
                                    onChange={this.handleChange}
                                />
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={[
                                            {value: 'MESSAGE_DEFAULT', label: 'Default'},
                                            {value: 'MESSAGE_FOR_ME', label: 'Message For Me'},
                                            {value: 'MESSAGE_MLG_AIRHORN', label: 'MLG Air Horn'},
                                            {value: 'MESSAGE_MSN', label: 'MSN Messenger'},
                                            {value: 'MESSAGE_NEW_MESSAGE', label: 'You Got A New Message'},
                                            {value: 'MESSAGE_NONE', label: 'None'}
                                        ]}
                                        label='Message Sound *'
                                        onChange={this.handleSelectChange('msgSound')}
                                        error={formErrors && formErrors['msgSound'] && true}
                                        errorText={formErrors && formErrors['msgSound']}
                                        value={this.state.formData.msgSound}
                                        noClear
                                    />
                                </FormControl>
                            </form>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6" paragraph>
                                Driving Information
                            </Typography>
                            <Grid container item spacing={1}>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Has driving licence?</FormLabel>
                                        <RadioGroup
                                            name="hasDrivingLicence"
                                            onChange={this.handleChange}
                                            row
                                        >
                                            <FormControlLabel
                                                value={1}
                                                control={<Radio color="primary" />}
                                                label="Yes"
                                                labelPlacement="end"
                                                checked={formData?.hasDrivingLicence == 1 ? true : false}
                                            />
                                            <FormControlLabel
                                                value={0}
                                                control={<Radio color="primary" />}
                                                label="No"
                                                labelPlacement="end"
                                                checked={formData?.hasDrivingLicence == 0 ? true : false}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {formData?.hasDrivingLicence == 1 ?
                                    <>
                                    <Grid item xs={12}>
                                        <TextField 
                                            name="licenceNumber"
                                            label="Licence Number"
                                            onChange={this.handleChangeUc}
                                            error={formErrors && formErrors['licenceNumber'] && true}
                                            helperText={formErrors && formErrors['licenceNumber']}
                                            value={formData.licenceNumber}
                                            margin="normal"
                                            fullWidth
                                            inputProps={{
                                                size:16,
                                                maxLength:16
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container item spacing={3}>
                                            <Grid item xs={6}>
                                                <DatePicker
                                                    type="date"
                                                    id="validFrom"
                                                    name="validFrom"
                                                    label="Licence valid from"
                                                    value={formData.validFrom}
                                                    errorText={formErrors && formErrors['validFrom']}
                                                    onChange={date => this.handleDateChange('validFrom', date, 'YYYY-MM-DD')}
                                                    autoOk={true}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DatePicker
                                                    type="date"
                                                    id="validTo"
                                                    name="validTo"
                                                    label="Licence valid to"
                                                    value={formData.validTo}
                                                    errorText={formErrors && formErrors['validTo']}
                                                    onChange={date => this.handleDateChange('validTo', date, 'YYYY-MM-DD')}
                                                    autoOk={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="caption" gutterBottom>
                                            <b>Endorsements</b>
                                        </Typography>
                                        <Grid container item spacing={3}>
                                            <Grid item xs={6}>
                                                <TextField 
                                                    name="endorsementOffences"
                                                    label="Offences"
                                                    onChange={this.handleChange}
                                                    error={formErrors && formErrors['endorsementOffences'] && true}
                                                    helperText={formErrors && formErrors['endorsementOffences']}
                                                    value={formData.endorsementOffences}
                                                    margin="normal"
                                                    fullWidth
                                                    type='number'
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField 
                                                    name="endorsementPoints"
                                                    label="Points "
                                                    onChange={this.handleChange}
                                                    error={formErrors && formErrors['endorsementPoints'] && true}
                                                    helperText={formErrors && formErrors['endorsementPoints']}
                                                    value={formData.endorsementPoints}
                                                    margin="normal"
                                                    fullWidth
                                                    type='number'
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="caption" gutterBottom>
                                            <b>Driving Record</b>
                                        </Typography>
                                        <br></br>
                                        <Typography variant="caption" gutterBottom>
                                            You will receive a reminder a week before the annual renewal date.
                                        </Typography>
                                        <DatePicker
                                            type="date"
                                            id="drivingRecordDate"
                                            name="drivingRecordDate"
                                            label="Driving Record Date "
                                            value={formData.drivingRecordDate}
                                            errorText={formErrors && formErrors['drivingRecordDate']}
                                            onChange={date => this.handleDateChange('drivingRecordDate', date, 'YYYY-MM-DD')}
                                            autoOk={true}
                                        />
                                        <DragFileInput
                                            id="drivingRecordFile"
                                            name="drivingRecordFile"
                                            label="Driving Record File"
                                            file={formData.drivingRecordFile}
                                            errorText={formErrors && formErrors['drivingRecordFile']}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={() => this.clearValue('drivingRecordFile')}
                                            emptyText='No file selected'
                                        />
                                    </Grid>
                                    </>
                                : null}
                            </Grid>  
                            <div className='buttonRow'>
                                <Button 
                                    onClick={() => this.props.deployConfirmation('Are you sure you want to add this new staff member?', 'Add A New Staff Member?', this.handleSubmit)}
                                    variant="contained"
                                    color="primary"
                                    disabled={Object.values(formData).every(x => (x === null || x === ""))}
                                >
                                    Add
                                </Button>
                            </div>
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </Grid>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
    }
)

export default connect(null, mapDispatchToProps)(AddStaff);