import React                   from 'react';
import _                       from 'lodash';
import { connect }             from 'react-redux';
import { GOOGLE_MAPS_API_KEY } from 'Constants';
import GoogleMapReact          from 'google-map-react';

import { Box, Grid } from '@material-ui/core';

import DefaultMapPin from 'Assets/Images/google-marker.png';

import NewMarkerDialog from './NewMarkerDialog';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
 
const Marker = ({ show, place, readonly, markerPin }) => (
    <>
        <div style={{cursor: readonly ? 'grab' : 'cursor'}} >{markerPin}</div>
        {(!readonly && show) && <InfoWindow place={place} />}
    </>

)

const InfoWindow = (props) => {
    const { place } = props;
    const infoWindowStyle = {
        position: 'relative',
        bottom: 200,
        left: '-190px',
        width: 380,
        color: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        fontSize: 13,
        zIndex: 100,
        padding: 0,
    };

    return (
        <Box style={infoWindowStyle}>
            {place?.content}
        </Box>
    );
  };

const initialState = () => ({
    lat: 52.300959,
    lng: -0.739988,
    draggable: true,
    zoom: 11,
    center: [52.300959, -0.739988],
    markers: [],

    showStreetView: false,
    ddMode: false,
    formData: {
        lat: 52.034530825365685,
        lng: -0.7466207881302033,
    },

    filters: []
})
class TargetMap extends React.Component {

    constructor(props) {
        super(props);
        let { lat, lng } = this.props;

        this.state = {...initialState(),
            lat:    lat ? lat : initialState().lat,
            lng:    lng ? lng : initialState().lng
        };
    }

    componentDidMount = () => {
        let { lat, lng, zoom } = this.props;

        this.setState({
            lat:    lat ? lat : this.state.lat,
            lng:    lng ? lng : this.state.lng,
            zoom:   zoom ? zoom : this.state.zoom,
            center: [lat ? lat : this.state.lat, lng ? lng : this.state.lng]
        });
    }

    onChildClickCallback = (key) => {

        let markers = this.state.markers;

        _.map(markers, (item, idx) => {
            markers[idx].show = key === idx ? !markers[key].show : false;
        });

        this.setState({
            markers,
        });
    };

    onMarkerInteraction = (childKey, childProps, mouse) => {
        this.setState({
            draggable: false,
            formData: {
                lat: mouse.lat,
                lng: mouse.lng
            }
        });
    }

    onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        this.setState({ 
            draggable: true
        });
    }

    getAddress = () => {
        let formData = _.cloneDeep(this.state.formData);
        this.setState({
            ddMode: false,
            formData: initialState().formData
        }, () => this.props.deployDialog(
            <NewMarkerDialog lat={formData.lat} lng={formData.lng} onMarkerCreate={this.props.onMarkerCreate}/>,
            'New Marker', 'sm'
        ));
    }

    handleNewMarker = () => {
        this.setState({
            ddMode: !this.state.ddMode
        });
    }

    _onClick = (value) => {
        this.setState({
            lat: value?.lat,
            lng: value?.lng
        });
    }

    _onChange = ({ center, zoom }) => {
        
        if(this.state.mapApiLoaded){
            this.setState({
                center: center,
                zoom: zoom,
            });
        }
    }


    onMarkerInteraction = (childKey, childProps, mouse) => {
        this.setState({
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng
        });
    }

    onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        this.setState({ 
            draggable: true
        },() => {
            this.props.setLngLat(this.state.lng, this.state.lat);
        });      
    }

    render() {
        const { lat, lng, draggable, zoom, ddMode } = this.state;
        const { width, height, readOnly, center, marker } = this.props;

        return (
            <Grid container>
                <Grid item style={{height:  height || '100vh', width: width || '100%', cursor: ddMode ? 'crosshair' :'default'}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ 
                            key: GOOGLE_MAPS_API_KEY,
                            
                        }}
                        defaultCenter={{
                            lat: parseFloat(lat),
                            lng: parseFloat(lng)
                        }}
                        zoom={parseInt(zoom)}
                        center={center || [parseFloat(lat), parseFloat(lng)]}
                        draggable={readOnly ? false : draggable}
                        options={(map) => ({
                            draggableCursor: ddMode ? 'crosshair' : 'grab',
                            clickableIcons: false,
                            disableDoubleClickZoom: true,
                            disableDefaultUi: true,
                            fullscreenControl: false,
                            zoomControl: readOnly ||  ddMode ? false : true,
                            mapTypeId: map.MapTypeId.ROADMAP,
                            StreetViewStatus: true,
                            styles: [{
                                "featureType": "poi",
                                "elementType": "labels",
                                "stylers": [{
                                "visibility":  "off"
                                
                                }]
                            }]
                        })}
                        yesIWantToUseGoogleMapApiInternals
                        onChildClick={!readOnly && this.onChildClickCallback}
                        onTilesLoaded={this.fetchPlaces}
                        onBoundsChanged={this.fetchPlaces}

                        //d&d
                        onChildMouseDown={!readOnly &&  this.onMarkerInteraction}
                        onChildMouseUp={!readOnly &&  this.onMarkerInteractionMouseUp}
                        onChildMouseMove={!readOnly &&  this.onMarkerInteraction}
                        onClick={!readOnly && ((e) => this._onClick(e))}
                        onChange={!readOnly && this._onChange}

                    >
                        <Marker
                            lat={parseFloat(lat)}
                            lng={parseFloat(lng)}
                            markerPin={marker ? marker : <img src={DefaultMapPin} alt="Map Pin" height={30} />}
                        /> 
                    </GoogleMapReact>
                </Grid>
            </Grid>
        )
    }
}
 

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, size) => { dispatch(deployDialog(content, title, null, size))},
        closeDialog:  ()                     => {dispatch(closeDialog())}
    }
}

export default connect(null, mapDispatchToProps)(TargetMap);