import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import AccessIcon from '@material-ui/icons/LockOpen';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import BackButton from 'Components/Common/Buttons/BackButton';
import moment from 'moment';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import { Dial, downloadS3File } from 'Functions/MiscFunctions';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

const initialState = {
    staffProfileMenuAnchor: null,
}

class ViewStaffProfileCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    handleStaffProfileMenuClick = event => {
        this.setState({ staffProfileMenuAnchor: event.currentTarget });
    };  
    handleStaffProfileMenuClose = path => {
        this.setState({ staffProfileMenuAnchor: null }, () => {
            this.props.history.push(path);
        });
    };
    render() {
        
        const { staffProfileMenuAnchor } = this.state;
        let { access, isLoading, staffData, engineerId } = this.props;
        if(staffData === undefined){
            this.props.history.push('/engineers/search');
            return (<React.Fragment></React.Fragment>);
        } else {
            return (
                <Card>
                    {(isLoading && (
                        <LoadingCircle />
                    )) || (   
                        <React.Fragment>
                            <CardHeader
                                avatar={
                                    <Avatar alt={`${staffData.staff_first_name} ${staffData.staff_last_name}`} src={staffData.staff_profile_photo} style={{height: 80, width: 80, margin: 0}} />
                                }
                                action={
                                    access ? (
                                        <IconButton
                                            aria-owns={staffProfileMenuAnchor ? 'staffProfileMenu' : undefined}
                                            aria-haspopup="true"
                                            onClick={this.handleStaffProfileMenuClick}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    ) : (
                                    ''
                                    )
                                }
                                disableTypography
                                title={<Typography variant='h5'>{staffData.staff_first_name} {staffData.staff_last_name}</Typography>}
                            />
                            {access && (
                                <Menu id="staffProfileMenu"
                                    anchorEl={staffProfileMenuAnchor}
                                    open={Boolean(staffProfileMenuAnchor)}
                                    onClose={this.handleStaffProfileMenuClose}
                                >
                                    {access.updateStaff && (
                                    <MenuItem onClick={()=>{ this.handleStaffProfileMenuClose(`/staff/update/${staffData.staff_id}`);}}>
                                        <ListItemIcon>
                                            <EditIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Update Staff Member
                                        </ListItemText>
                                    </MenuItem>
                                    )}
                                    {access.staffAccess && (
                                    <MenuItem onClick={()=>{ this.handleStaffProfileMenuClose(`/staff/access/${staffData.staff_id}`);}}>
                                        <ListItemIcon>
                                            <AccessIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Staff Member Access
                                        </ListItemText>
                                    </MenuItem>
                                    )}
                                    {access.updateEngineer && (
                                    <MenuItem onClick={()=>{ this.handleStaffProfileMenuClose(`/engineers/update/${engineerId}`);}}>
                                        <ListItemIcon>
                                            <EditIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Update Engineer
                                        </ListItemText>
                                    </MenuItem>
                                    )}
                                </Menu>
                            )}
                            <CardContent>
                                <Typography variant="body1" component="div">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4} lg={3}>
                                            <strong>Email Address:</strong>
                                        </Grid>
                                        <Grid item xs={12} sm={8} lg={9}>
                                            {staffData.staff_email}
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={3}>
                                            <strong>Mobile Number:</strong>
                                        </Grid>
                                        <Grid item xs={12} sm={8} lg={9}>
                                            {staffData.staff_mobile_number ? <Dial number={staffData.staff_mobile_number} /> : <em>No mobile number</em>}
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={3}>
                                            <strong>Job Title:</strong>
                                        </Grid>
                                        <Grid item xs={12} sm={8} lg={9}>
                                            {staffData.staff_job_title || ''}
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={3}>
                                            <strong>Date of Birth:</strong>
                                        </Grid>
                                        <Grid item xs={12} sm={8} lg={9}>
                                            {staffData.staff_dob != '0000-00-00' ? 
                                                <>{moment(staffData.staff_dob).format('DD MMMM YYYY')}  <Typography variant="caption"> <em>({moment().diff(staffData.staff_dob, 'years')} years old)</em></Typography></>
                                            : '-'}
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={3}>
                                            <strong>Status:</strong>
                                        </Grid>
                                        <Grid item xs={12} sm={8} lg={9}>
                                            {staffData.staff_status === 'active' ? 'Active' : 'Inactive'}
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={3}>
                                            <strong>Signature:</strong>
                                        </Grid>
                                        <Grid item xs={12} sm={8} lg={9}>
                                            <ImageWithError type="SIGNATURE" alt={`${staffData.staff_first_name} ${staffData.staff_last_name}'s signature`} src={staffData.staff_signature} style={{maxHeight: 100}} />
                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Grid item xs={12}>
                                    <Typography variant="h5" paragraph>
                                        Driving Information
                                    </Typography>
                                </Grid>
                                {staffData.hasDrivingLicence ? 
                                    <Grid item xs={12}>
                                        <PaddedPaper style={{backgroundColor:'#f8f9fa'}}>
                                            <Grid container item spacing={1}>
                                                <Grid item xs={12}>
                                                    <TextField 
                                                        label='Licence Number'
                                                        disabled={true}
                                                        value={staffData?.licenceNumber}
                                                        margin="normal"
                                                        fullWidth
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container item spacing={3}>
                                                        <Grid item xs={6}>
                                                            <DatePicker
                                                                type="date"
                                                                label={'Licence valid from'}
                                                                value={staffData?.validFrom !== '0000-00-00' ? staffData?.validFrom : null}
                                                                disabled={true}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <DatePicker
                                                                type="date"
                                                                label={'Licence valid to'}
                                                                value={staffData?.validTo !== '0000-00-00' ? staffData?.validTo : null}
                                                                disabled={true}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" gutterBottom>
                                                        <b>Endorsements</b>
                                                    </Typography>
                                                    <Grid container item spacing={3}>
                                                        <Grid item xs={6}>
                                                            <TextField 
                                                                label='Offences'
                                                                disabled={true}
                                                                value={staffData?.endorsementOffences || ''}
                                                                margin="normal"
                                                                fullWidth
                                                                type='number'
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField 
                                                                label='Points'
                                                                disabled={true}
                                                                value={staffData?.endorsementPoints || ''}
                                                                margin="normal"
                                                                fullWidth
                                                                type='number'
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" gutterBottom>
                                                        <b>Driving Record</b>
                                                    </Typography>
                                                    {staffData?.drivingRecordDate !== '0000-00-00' ?
                                                        <>
                                                        <br></br>
                                                        <Typography variant="caption" gutterBottom>
                                                            Driving record due for renewal on <span style={{color: 'red'}}>{moment(staffData?.drivingRecordDate).add(1, 'year').format('MMM D, YYYY')}</span>. A reminder will be sent a week before.
                                                        </Typography>
                                                        </>
                                                    : null}
                                                    <Grid container item spacing={3} direction="row" alignItems="flex-end">
                                                        <Grid item xs={6}>
                                                            <DatePicker
                                                                type="date"
                                                                id="drivingRecordDate"
                                                                label={'Driving Record Date'}
                                                                value={staffData?.drivingRecordDate !== '0000-00-00' ? staffData?.drivingRecordDate : null}
                                                                disabled={true}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Link component="div" variant="body1" className='blueLink' style={{cursor: 'pointer'}} onClick={() => {downloadS3File(staffData?.drivingRecordFileUrl)}}>
                                                                View Driving Record
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper> 
                                    </Grid>
                                : 
                                    <Grid item xs={12}>
                                        <Typography variant="body2" paragraph>
                                            <em>No Driving Licence</em>
                                        </Typography>
                                    </Grid>
                                }
                                <div className='buttonRow'>
                                    <BackButton props={this.props} />
                                </div>
                            </CardContent>
                        </React.Fragment>
                    )}
                </Card>
            )
        }
    }
}

export default ViewStaffProfileCard;