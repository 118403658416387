import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import InputHelper from 'Helpers/InputHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { 
    DEFAULT_EMAIL,
    WEB_EMAIL,
    ACCOUNTS_EMAIL,
    SALES_EMAIL
} from 'Constants';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { Grid, Button, TextField } from '@material-ui/core';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconHelper from 'Helpers/IconHelper';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import Textarea from 'Components/Common/Inputs/Textarea';

const initialState = props => ({
    isLoading: true,
    formData: {
        to: props.to || null,
        from: props.from || 'Default',

        cc: props.CC ?? [],
        staffCC: props.staffCC ?? [],

        subject: props.subject || '',
        bodyType: props.bodyType || '',
        body: props.body || '',

        type: props.type || 'Manual',
        typeId: props.typeId || null,
        passInData: props.passInData || null,
    },
    contact: {},
    lists: {
        contacts: [],
        staff: [],
        from: [
            {value: 'Default', label: `Default (${DEFAULT_EMAIL})`},
            {value: 'Accounts', label: `Accounts (${ACCOUNTS_EMAIL})`},
            {value: 'Web', label: `Web (${WEB_EMAIL})`},
            {value: 'Sales', label: `Sales (${SALES_EMAIL})`},
        ]
    },
    store: {},
    show: {
        bcc: ((props.CC || props.staffCC) && true) || false,
    }
})

/*this isnt used yet*/
class EmailDialog extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState(props);
        this.inputHelper = new InputHelper(this);
    }

    componentDidMount(){
        this.getPageData();
    }

    getPageData = () => {
        if (this.props.to){
            this.getContact();
        } else {
            this.getAllContacts();
        }

        this.getStaff();
        if (this.props.templateId){
            this.getTemplate();
        }
    }

    getTemplate = () => {
        if (this.props.templateId) {
            switch (this.props.templateSource){
                case 'customer_returns_email_template':
                    API.get('/customerReturns/emailTemplates').then(res=>{
                        let template = _.find(res.data, {cret_id: parseInt(this.props.templateId)});
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                subject: template.cret_name,
                                body: template.cret_text,
                                bodyType: 'text'
                            }
                        }, this.checkBody);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    checkBody = () => {
        if (this.props.replace) {
            let body = this.state.formData.body;
            _.each(_.keys(this.props.replace), key => {
                body = body.replace(new RegExp(`{>${key}<}`, 'g'), this.props.replace[key]);
            })
            this.setState({
                formData: {
                    ...this.state.formData,
                    body: body,
                }
            })
        }
    }

    getContact = () => {
        API.get(`/customers/contacts/${this.state.formData.to}`)
        .then(res => {
            let contact = res.data;
            API.get(`/customers/${contact.contact_customer_id}`).then(res => {
                this.setState({
                    contact: contact,
                    lists: {
                        ...this.state.lists,
                        contacts: _.map(res.data.active_contacts, i => ({
                            value: i.contact_id,
                            label: `${i.contact_name} (${i.contact_email})`,
                            contact: i,
                        })),
                    }
                })
            })
        })
    }

    getAllContacts = () => {
        API.get('/marketing/campaigns/mailingLists/eligibleContacts', {params: {breakdown: true}}).then(res => {
        })
    }

    getStaff = () => {
        API.get('/staff/all', {params: {active :true}}).then(res => {
            this.setState({
                lists: {
                    ...this.state.lists,
                    staff: _.map(res.data, i => ({
                        value: i.staff_id,
                        label: i.staff_first_name + ' ' + i.staff_last_name,
                        staff: i,
                    })),
                }
            })
        })
    }

    submit = () => {
        let formData = new FormData();
        _.each(_.keys(this.state.formData), key => {
            switch (key){
                case 'cc':
                case 'staffCC':
                    formData.append(key, JSON.stringify(this.state.formData[key]));
                    break;
                default:
                    formData.append(key, this.state.formData[key]);
                    break;
            }
        })
        API.post('/email/newSend', formData).then(res => {
            if (res.data.success){
                this.props.deploySnackBar('success', 'Email sent successfully');
                this.props.closeDialog();
            }
        });
    }

    render () {
        const { lists, formData, show } = this.state;

        return (
            <Grid container spacing={1} style={{alignItems: 'flex-end'}}>
                <Grid item style={{width: 'calc(100% - 40px)'}}>
                    <AutoCompleteSelect
                        label='To *'
                        name='formData.to'
                        value={formData.to}
                        onChange={this.inputHelper.handleSelectChange('formData.to')}
                        options={lists.contacts}
                        fullWidth
                        noClear
                    />
                </Grid>
                <Grid item style={{width: 40}}>
                    <Button
                        variant="outlined"
                        onClick={this.inputHelper.handleButtonChange('show.bcc', !show.bcc)}
                        fullWidth
                        style={{
                            height: 32,
                            width: 40,
                            padding: 0,
                            minWidth: 40,
                        }}
                    ><AllIcon icon={IconHelper[show.bcc ? 'arrowUp' : 'arrowDown']} noMargin size='small'/></Button>
                </Grid>
                {show.bcc &&
                    <>
                        <Grid item xs={12}>
                            <AutoCompleteSelect
                                label='From *'
                                name='formData.from'
                                value={formData.from}
                                onChange={this.inputHelper.handleSelectChange('formData.from')}
                                options={lists.from}
                                fullWidth
                                noClear
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AutoCompleteMultiSelect
                                label='CC'
                                name='formData.cc'
                                value={formData.cc}
                                onChange={this.inputHelper.handleMultiSelectChange('formData.cc')}
                                options={_.filter(lists.contacts, i => i.value !== formData.to)}
                                fullWidth
                                noClear
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AutoCompleteMultiSelect
                                label='staff'
                                name='formData.staffCC'
                                value={formData.staffCC}
                                onChange={this.inputHelper.handleMultiSelectChange('formData.staffCC')}
                                options={lists.staff}
                                fullWidth
                                noClear
                            />
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <TextField
                        label='Subject *'
                        name='formData.subject'
                        value={formData.subject}
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Textarea
                        label='Body *'
                        name='formData.body'
                        value={formData.body}
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                        fullWidth
                        rows={4}
                        style={{marginTop: 0}}
                    />
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant='outlined'
                        onClick={this.props.closeDialog}
                    >Close</Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={()=>this.props.deployConfirmation(
                            'Are you sure you want to send this email?',
                            'Send Email',
                            this.submit
                        )}
                    >Send</Button>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    closeDialog: () => dispatch(closeDialog()),
    deploySnackBar: (variant, msg) => dispatch(deploySnackBar(variant, msg)),
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
});

export default connect(null, mapDispatchToProps)(EmailDialog);
