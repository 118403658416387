import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import API from 'API';
import LineChart from 'Components/Common/Charts/LineChart';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { clenyDate, getOrdinal } from 'Helpers/TimeHelper';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import BarChart from 'Components/Common/Charts/BarChart';
import { getColorCurrency, getColorPercentage, months } from 'Helpers/ReportsHelper';


const initialState = () => ({
    isLoading: true,
    data: [],
})

const ConsistentSalesRow = ({icon, data, comparator, showRed, showComparator}) => {
	return (
		<>
			<Grid item xs={showComparator ? 2 : 3} style={{textAlign:'center'}}><AllIcon icon={icon} size='xs' /></Grid>
			<Grid item xs={showComparator ? 4 : 9} style={{color: showRed && colors.red}}>{data < 0 ? '-' : ''}£{parseFloat(Math.abs(data)).toFixed(2)}</Grid>
            {showComparator && <Grid item xs={6}>{comparator}</Grid> }
        </>
	)
}

class SalesReportCompleteMonth extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.year !== this.props.year || prevProps.month !== this.props.month) {
            this.setState(initialState(), this.getData);
        }
    }

    getData = () => {
        let params = {params: {cid:this.props.cid, partId:this.props.partId, year:this.props.year, month: this.props.month, current: 1}}
        API.get('/reports/yoysReport/sales/month', params)
        .then(response => {
            this.setState({
                data: response.data, 
                isLoading: false
            });
        })
    }

    render() {
        const {isLoading, data} = this.state;
        const {year, month} = this.props;

        let
            currentYear = `${months[month]} ${month >= 10 ? (year-1) : year}`,
            lastYear = `${months[month]} ${month >= 10 ? (year-2) : (year-1)}`,
            currentDay = moment().format('D');

        let current = true;

        return (
            <Grid item xs={12}>
                <Card style={{color: '#000',backgroundColor: '#FFF',padding: 0}} >
                    <CardHeader
                        avatar={<AllIcon icon={`TextToIcon:${`${month}`.padStart(2,'0')}`} size='medium' style={{color: '#fff'}} noMargin/>}
                        title={
                            <Grid container spacing={1} style={{alignItems: 'flex-end'}}>
                                <Grid item >
                                    <>{months[parseInt(month)]}</>
                                </Grid>
                                <Grid item >
                                    <Typography variant="body1" style={{paddingBottom: 1}} >{current ? ` Up Until ${getOrdinal(moment().format('D'))} ${moment().format('MMMM YYYY')}` : ''}</Typography>
                                </Grid>
                            </Grid>
                        }
                        titleTypographyProps={{variant:'h6'}}
                        style={{background: 'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', color: '#FFF'}}
                    />
                    <CardContent style={{padding:15}}>
                    {isLoading ? 
                        <Grid item xs={12}>
                            <LoadingCircle/>
                        </Grid>
                    :
                        <Grid container spacing={2}>

                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>{currentYear}</b></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PaddedPaper style={{padding: 10}}>
                                            <Grid container>
                                                <Grid item>
                                                    <Typography gutterBottom variant='caption'><b>Total {current ? `To ${clenyDate(moment())}` : ''}</b></Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{marginLeft: 'auto'}}>
                                                    <Typography gutterBottom variant='caption'><b>Difference</b></Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                        <ConsistentSalesRow icon={IconHelper.products} data={data.c.p.c} comparator={
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={6}>
                                                                    {getColorCurrency(data.c.p.c - data.c.p.l , false, false)}
                                                                </Grid>
                                                                
                                                                <Grid item xs={6}>
                                                                    {getColorPercentage((data.c.p.c - data.c.p.l ), data.c.p.l, false, false)}
                                                                </Grid> 
                                                            </Grid>
                                                        } showComparator />
                                                        <ConsistentSalesRow icon={IconHelper.services} data={data.c.s.c} comparator={
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={6}>
                                                                    {getColorCurrency(data.c.s.c - data.c.s.l , false, false)}
                                                                </Grid>
                                                                
                                                                <Grid item xs={6}>
                                                                    {getColorPercentage((data.c.s.c - data.c.s.l ), data.c.s.l, false, false)}
                                                                </Grid> 
                                                            </Grid>
                                                        } showComparator />
                                                        <ConsistentSalesRow icon={IconHelper.total} data={data.c.t.c} comparator={
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={6}>
                                                                    {getColorCurrency(data.c.t.c - data.c.t.l , false, false)}
                                                                </Grid>
                                                                
                                                                <Grid item xs={6}>
                                                                    {getColorPercentage((data.c.t.c - data.c.t.l ), data.c.t.l, false, false)}
                                                                </Grid> 
                                                            </Grid>
                                                        } showComparator />
                                                    </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>{lastYear}</b></Typography>
                                    </Grid>
                                    {current &&
                                        <Grid item xs={6}>
                                            <PaddedPaper style={{padding: 10}}>
                                                <Typography variant='caption'><b>Total {current ? `To ${clenyDate(moment().subtract(1,'year'))}` : ''}</b></Typography>
                                                <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                    <ConsistentSalesRow icon={IconHelper.products} data={data.c.p.l} comparator={data.c.p.l + data.c.p.c}  />
                                                    <ConsistentSalesRow icon={IconHelper.services} data={data.c.s.l} comparator={data.c.s.l + data.c.s.c}  />
                                                    <ConsistentSalesRow icon={IconHelper.total} data={data.c.t.l} comparator={data.c.t.l + data.c.t.c}  />
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>
                                    }
                                    <Grid item xs={current ? 6 : 12}>
                                        <PaddedPaper style={{padding: 10}}>
                                            <Typography variant='caption'><b>Total Month</b></Typography>
                                            <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                <ConsistentSalesRow icon={IconHelper.products} data={data.t.p.l} />
                                                <ConsistentSalesRow icon={IconHelper.services} data={data.t.s.l} />
                                                <ConsistentSalesRow icon={IconHelper.total} data={data.t.t.l} />
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Difference</b></Typography>
                                    </Grid>
                                    {current &&
                                        <Grid item xs={6}>
                                            <PaddedPaper style={{padding: 10}}>
                                                <Typography gutterBottom variant='caption' style={{paddingBottom: 8}}><b>Total {current ? `To ${clenyDate(moment())}` : ''}</b></Typography>
                                                <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                    <Grid item={3}>
                                                        <AllIcon icon={data.t.t.c > data.c.t.l ? IconHelper.arrowStemUp : (data.t.t.c < data.c.t.l ? IconHelper.arrowStemDown : IconHelper.dash)} size={65} color={
                                                            data.t.t.c > data.c.t.l ? colors.green : (data.t.t.c < data.c.t.l ? colors.red : colors.grey)
                                                        } />
                                                    </Grid>
                                                    <Grid item={9}>
                                                        <Typography variant='body1'>{getColorPercentage((data.c.t.c - data.c.t.l ), data.c.t.l, false, false)}</Typography>
                                                        <Typography variant='h5'>{getColorCurrency(data.c.t.c - data.c.t.l , false, false)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>
                                    }
                                    <Grid item xs={current ? 6 : 12}>
                                        <PaddedPaper style={{padding: 10}}>
                                            <Typography gutterBottom variant='caption' style={{paddingBottom: 8}}><b>Total Month</b></Typography>
                                            <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                <Grid item={3}>
                                                    <AllIcon icon={data.t.t.c > data.t.t.l ? IconHelper.arrowStemUp : ( (data.t.t.c < data.t.t.l) ? IconHelper.arrowStemDown : IconHelper.dash )} size={65} color={data.t.t.c > data.t.t.l ? colors.green : ( (data.t.t.c < data.t.t.l) ? colors.red : colors.grey )} />
                                                </Grid>
                                                <Grid item={9}>
                                                    <Typography variant='body1'>{getColorPercentage((data.t.t.c - data.t.t.l ), data.t.t.l, false, false)}</Typography>
                                                    <Typography variant='h5'>{getColorCurrency((data.t.t.c - data.t.t.l ), false, false)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Day On Day</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Running Total</b></Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <PaddedPaper style={{padding: 10}}>
                                    <BarChart
                                        data={_.map(_.orderBy(_.keys(data.b), i=>i, 'asc'), idx => {

                                            let monthData = data.b[idx];
                                            let nullMonth = currentDay < parseInt(idx);

                                            return {
                                                label: parseInt(idx),
                                                value: {
                                                    [currentYear] : nullMonth ? null : parseFloat(monthData['t']['c']),
                                                    [lastYear] :   nullMonth ? null : parseFloat(monthData['t']['l']),
                                                },
                                                color: {
                                                    [currentYear] : colors.primary,
                                                    [lastYear] :    colors.red,
                                                },
                                                bold:  current ?  parseInt(currentDay) === parseInt(idx) : 0
                                            }
                                            
                                        })}
                                        yLabel={i => `${i<0?'-':''}£${Math.abs(i)}`}
                                        tLabel={(e)=>`${parseFloat(e.raw)<0?'-':''}£${parseFloat(Math.abs(e.raw)).toFixed(2)}`}
                                        style={{
                                            height: 300,
                                        }}
                                    />
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={6}>
                                <PaddedPaper style={{padding: 10}}>
                                    <LineChart
                                        data={[
                                            {
                                                label:'0',
                                                value: {
                                                    [currentYear] : 0,
                                                    [lastYear] :   0,
                                                },
                                                color: {
                                                    [currentYear] : colors.primary,
                                                    [lastYear] :    colors.red,
                                                },
                                            },
                                            ..._.map(_.orderBy(_.keys(data.b2), i=>i, 'asc'), idx => {

                                            let monthData = data.b2[idx];
                                            let nullMonth = current ? parseInt( parseInt(idx) ) > parseInt(moment().format('D')) : 0;

                                            return {
                                                label: parseInt(idx),
                                                value: {
                                                    [currentYear] : nullMonth ? null : parseFloat(monthData['t']['c']),
                                                    [lastYear] :   parseFloat(monthData['t']['l']),
                                                },
                                                color: {
                                                    [currentYear] : colors.primary,
                                                    [lastYear] :    colors.red,
                                                },
                                                bold:  current ?  parseInt(currentDay) === parseInt(idx) : 0
                                            }
                                            
                                        })]}
                                        yLabel={i => `${i<0?'-':''}£${Math.abs(i)}`}
                                        tLabel={(e)=>`${parseFloat(e.raw)<0?'-':''}£${parseFloat(Math.abs(e.raw)).toFixed(2)}`}
                                        style={{
                                            height: 300,
                                        }}
                                        noPoints
                                    />
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    }
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

export default SalesReportCompleteMonth;