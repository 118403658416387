import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const FullScreenDialog = ({open, noExit, onClose, title, content, withMargin, style}) => (
    <Dialog 
        fullScreen 
        open={open} 
        onClose={noExit ? () => {} : onClose} 
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        TransitionComponent={Transition}
        PaperProps={{
            style: {
                ...style
            },
          }}
    >
        {!!(title || onClose) &&
            <AppBar>
                <Toolbar>
                    <Typography variant="h6">
                        {title}
                    </Typography>
                    {!noExit && 
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" style={{marginLeft: 'auto'}}>
                            <CloseIcon />
                        </IconButton>
                    }
                </Toolbar>
            </AppBar>
        }
        <Container maxWidth="xl" style={ !!(title || onClose) ? {marginTop: withMargin ? 80 : 20, height: 'auto'} : {height: '100vh', width: '100vw', padding:0} }>
            {content}
        </Container>
    </Dialog>
);