import API from 'API';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import DatePicker from 'Components/Common/DatePickers/DatePicker';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import Textarea from 'Components/Common/Inputs/Textarea';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    TextField,
    Typography
} from '@material-ui/core/';


import { closeDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

const AddReminderDialog = ({addressId, callback}) => {

    const initialState = {
        showConfirm:    false,
        formData: {
            reminderTitle:          null,
            reminderText:           '',
            reminderDue:            null,
            reminderRepeats:        0,
            reminderOccurance:      1,
            reminderRepeat:         'day',
            attachType:             'address',
            attach:                 1,
            attachId:               addressId
        },
        formErrors:     [],
    }

    let [state, setState] = useState(initialState);
    const { formData, showConfirm, formErrors } = state;

    const dispatch = useDispatch();

    const close = () => {
        dispatch(closeDialog())
    };

    const submit = () => {
        closeConfirmationDialog();
        API.post('/reminders', formData)
            .then( result => {
                if(result.data.errors) {
                    setState({...state, formErrors: formatValidationErrors(result.data.errors) })
                    closeConfirmationDialog();
                } else {
                    setState({...state, initialState })
                    deploySnackBar("success", "You have successfully created a new reminder")
                    close();
                    if (callback) callback();
                }
            })
    }

    const handleDateChange = date => {
        setState({
            ...state,
            formData: {
                ...formData,
                reminderDue: moment(date).format('YYYY-MM-DD HH:mm'),
            }
        })
    }

    const handleCheckBox = () => {
        setState({
            ...state,
            formData: {
                ...formData,
                reminderRepeats: !formData.reminderRepeats
            }
        });
    }

    const handleChange = name => data => {
        setState({
            ...state,
            formData: {
                ...formData,
                [name]: data.value,
            }
        })
    }

    const openConfirmationDialog = () => {
        setState({
            ...state,
            showConfirm: true
        })
    }

    const closeConfirmationDialog = () => {
        setState({
            ...state,
            showConfirm: false
        })
    }

    return (
        <>
            <form noValidate autoComplete="off">
                <TextField
                    name="reminderTitle"
                    label="Reminder *"
                    margin="normal" 
                    autoComplete="off"
                    error={formErrors && formErrors['reminderTitle'] && true}
                    helperText={formErrors && formErrors['reminderTitle']}
                    fullWidth
                    value={formData.reminderTitle}
                    onChange={(e)=>{handleChange('reminderTitle')(e.target)}}
                />
                <FormControl fullWidth>
                    <DatePicker
                        id="reminderDue"
                        name="reminderDue"
                        type="datetime"
                        label="Date/Time"
                        value={formData.reminderDue}
                        onChange={handleDateChange}
                        errorText={formErrors && formErrors['reminderDue']}
                        autoOk={true}
                        disablePast={true}
                    />
                </FormControl>
                <Textarea
                    name="reminderText"
                    label='Notes'
                    value={formData.reminderText}
                    onChange={(e)=>{handleChange('reminderText')(e.target)}}
                />
                <FormControl fullWidth margin="normal">
                    <FormControlLabel
                        control={
                            <Checkbox 
                                checked={state.formData.reminderRepeats ? true : false} 
                                value={formData.reminderRepeats}
                                onChange={handleCheckBox}
                                name="reminderRepeats"
                                color="primary"
                            />
                        }
                        label="Repeat Reminder"
                    />
                </FormControl>
                {!!formData.reminderRepeats && (
                    <Grid container spacing={2} alignItems='center'>
                        <Grid item container spacing={2} alignItems='center'>
                            <Grid item xs={2}>
                                <Typography variant="body1">
                                    Every
                                </Typography>
                            </Grid>
                            { formData.reminderRepeat !== 'weekday' && 
                                <Grid item xs={5}>
                                    <FormControl margin="none" fullWidth>
                                        <AutoCompleteSelect
                                            options={[
                                                {label: '1', value: 1},
                                                {label: '2', value: 2},
                                                {label: '3', value: 3},
                                                {label: '4', value: 4},
                                                {label: '5', value: 5},
                                                {label: '6', value: 6},
                                                {label: '7', value: 7},
                                                {label: '8', value: 8},
                                                {label: '9', value: 9},
                                                {label: '10', value: 10},
                                                {label: '11', value: 11},
                                                {label: '12', value: 12},
                                            ]} 
                                            error={formErrors && formErrors['reminderOccurance'] && true}
                                            disableSort
                                            noClear
                                            value   = {formData.reminderOccurance}
                                            name    = 'reminderOccurance'
                                            onChange={handleChange('reminderOccurance')}
                                        />
                                    </FormControl>
                                </Grid>
                            }
                                <Grid item xs={5}>
                                    <FormControl margin="none" fullWidth>
                                        <AutoCompleteSelect
                                            options={[
                                                {label: `Day${formData.reminderOccurance > 1 ? 's' : ''}`, value: `day`},
                                                {label: `Week Day`, value: `weekday`},
                                                {label: `Week${formData.reminderOccurance > 1 ? 's' : ''}`, value: `week`},
                                                {label: `Month${formData.reminderOccurance > 1 ? 's' : ''}`, value: `month`},
                                                {label: `Year${formData.reminderOccurance > 1 ? 's' : ''}`, value: `year`},
                                            ]} 
                                            disabled={!formData.reminderRepeat}
                                            value   = {formData.reminderRepeat}
                                            name    = 'reminderRepeat'
                                            onChange={handleChange('reminderRepeat')}
                                            error={formErrors && formErrors['reminderRepeat'] && true}
                                            disableSort
                                            noClear
                                        />
                                    </FormControl>
                                </Grid>
                            
                        </Grid>
                    </Grid>
                )}
                <div className='buttonRow'>
                    <Button
                        variant="outlined"
                        color="default"
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={openConfirmationDialog}
                        variant="contained"
                        color="primary"
                        disabled={!(formData.reminderTitle && formData.reminderDue)}
                    >
                        Add
                    </Button>
                </div>
            </form>
            <ConfirmationDialog 
                open=   {showConfirm} 
                success={submit} 
                close=  {closeConfirmationDialog} 
                title=  {`Add Reminder?`} 
                message={`Are you sure you want to add this reminder?`}
            />
        </>
    )

}

export default AddReminderDialog;