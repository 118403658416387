import * as CiServiceWorkerTypes from '../../Actions/CiServiceWorker/Types/Types';

 const initialState = {
    installed: false
};

 function ciServiceWorkerReducer(state = initialState, action) {
    switch (action.type) {
        case CiServiceWorkerTypes.INSTALLED:
            return {
                ...state,
                installed: action.payload
            };
        case CiServiceWorkerTypes.RESET:
            return {
                ...state,
                installed: false
            };
        default:
            return state;
    }
}

 export default ciServiceWorkerReducer;