import API from 'API';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { Grid } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import ReturnDownloadDialog from 'Components/CustomerReturns/CustomerReturns/ViewCustomerReturns/ReturnDownloadDialog';
import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

const initialState = {
    isLoading:  true,
    customerReturns:  []
};

class CustomerReturns extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getCustomerReturns();
    }

    getCustomerReturns = () => {
        Promise.all(_.map(this.props.ids, id => API.get(`/customerReturns/returns/${id}`)))
        .then(res => {
            this.setState({ 
                customerReturns: _.map(res, r => r.data), 
                isLoading: false 
            });
        })
    }

    handleDownload = (cr) => {
        API.get(`/customerReturns/returns/${cr.cr_id}/prevDownload`, {params: {status: cr.cr_status}})
        .then(res => {
            pdfFromBase64(res.data.pdf, res.data.filename);
        });
    }

    render() {
        const { isLoading, customerReturns }  = this.state;

        if (isLoading) return (<LoadingCircle />);

        return (
            <Grid container >
                <Grid item xs={12}>
                    <DataTable
                        config={{
                            key: 'cr_id',
                        }}
                        rows={customerReturns}
                        columns={[
                            {
                                heading: 'Return Date',
                                field: 'cr_date',
                                fieldFormat: 'clenydate',
                                sizeToContent: true,
                            },
                            {
                                heading: 'Reference',
                                field: i => i.cr_reference + '-' + (i.cr_reference_amendment < 10 ? '0' + i.cr_reference_amendment : i.cr_reference_amendment),
                                truncate: true,
                            },
                            {
                                heading: 'Customer',
                                field: rowData =>   <span className={this.props.classes.custName} onClick={()=>{ this.props.history.push(`/customers/view/${rowData.cr_cust_id}`); }}>
                                                        {rowData.cr_cust_name}
                                                        {' '}
                                                        <Typography component="div" variant="body2">
                                                            {rowData.cr_collection_address_name}
                                                        </Typography>
                                                    </span>,
                                important: true,
                                info:    rowData =>     <div>
                                                            <Typography variant="body2">
                                                                <b>Collection Contact:</b><br />
                                                                {rowData.cr_collection_address_contact_name} <br/>
                                                                {rowData.cr_collection_address_contact_email}<br/>
                                                                {rowData.cr_collection_address_contact_phone} {!!rowData.cr_collection_address_contact_mobile && !!rowData.cr_collection_address_contact_phone && '/' } {rowData.cr_collection_address_contact_mobile} <br/>
                                                                <br /><b>Collection Address:</b><br />
                                                                {rowData.cr_collection_address.split('\n').map((item, key) => {
                                                                    return  <span key={key}>
                                                                                {item}{rowData.cr_collection_address.split('\n').length === key+1 ? null : <br/>}
                                                                            </span>
                                                                })}
                                                            </Typography>
                                                        </div>,
                                infoEnd: 'stretch',
                                truncate: true,
                            },
                            {
                                heading: 'Total',
                                field: 'cr_total_with_restock',
                                fieldFormat: 'currency',
                                sizeToContent: true,
                                alignment: 'center',
                                fieldPrefix: '-',
                                style:{color: colors.red},
                                info: i =>  <Grid container
                                                spacing={1}
                                                style={{fontSize: '0.8rem'}}
                                            >
                                                <Grid item xs={6} style={{textAlign: 'right'}}>
                                                    <b>Sub Total</b>
                                                </Grid>
                                                <Grid item xs={6} style={{textAlign: 'right'}}>
                                                    -£{parseFloat(i.cr_total).toFixed(2)}
                                                </Grid>
                                                <Grid item xs={6} style={{textAlign: 'right'}}>
                                                    <b>VAT ({parseInt(i.cr_vat_rate * 100)}%)</b>
                                                </Grid>
                                                <Grid item xs={6} style={{textAlign: 'right'}}>
                                                    -£{parseFloat(i.cr_total * i.cr_vat_rate).toFixed(2)}
                                                </Grid>
                                                <Grid item xs={6} style={{textAlign: 'right'}}>
                                                    <b>Total</b>
                                                </Grid>
                                                <Grid item xs={6} style={{textAlign: 'right'}}>
                                                    -£{parseFloat(i.cr_total_with_restock).toFixed(2)}
                                                </Grid>
                                            </Grid>
                            },
                            {
                                heading: 'Status',
                                field: i => ({
                                    'Awaiting Collection': 'Delivery',
                                    'Awaiting Outcome' : 'Post Review',
                                    'Awaiting Confirmation': 'Confirmation',
                                    'Awaiting Approval': 'Approval',
                                    'Awaiting Review': 'Review',                                    
                                }[i.cr_status] || i.cr_status),
                                sizeToContent: true,
                                truncate: true,
                            },
                            {
                                heading: 'Expected Outcome',
                                field: i => ({
                                    'Refund' : 'Credit Note',
                                    'Replace': 'Replace',
                                    'Repair': 'Repair'
                                }[i.cr_outcome || i.cr_expected_outcome]),
                                sizeToContent: true,
                                alignment: 'left',
                            },
                            {
                                heading: 'Outcome',
                                field: i => 
                                    i.outcomes.length > 0 ?
                                        _.map(i.outcomes, o => <>{o.cro_outcome === 'Refund' ? 'Credit Note' : o.cro_outcome} <br/></>) : '-'
                                ,
                                sizeToContent: true,
                                truncate: true,
                            },
                            {
                                actions: i => ([
                                    {
                                        name: 'Download',
                                        icon: icons.download,
                                        onClick: i => {
                                            this.props.deployDialog(<ReturnDownloadDialog cr={i}/>, 'Download Previous Documents', '', 'md');
                                        },
                                    },
                                    {
                                        name: 'View',
                                        icon: icons.view,
                                        link: `/returns/customerReturn/${i.cr_id}`,
                                    }
                                ])
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        )
    }
}

const styles = theme => ({
    custName: {
        '&:hover':{cursor: 'pointer'}
    }
});

const mapDispatchToProps = dispatch => ({
    deploySnackBar:     (variant, msg)                          => dispatch(deploySnackBar(variant, msg)),
    deployDialog:       (content, title, variant, size = 'lg')  => dispatch(deployDialog(content, title, variant, size)),
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(CustomerReturns));