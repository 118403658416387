import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import React, { PureComponent } from 'react';

const styles = theme => ({
    td : {
        border: 'none'
    },
    [theme.breakpoints.down('xs')]: {
        responsiveTable: {
            maxWidth: '100%', 
            overflowX: 'auto'
        }
    }
});

class PartItems extends PureComponent {
    
    render() {
        const { classes, formErrors, partRows, handleRowChange,  } = this.props;
            
        return (
            <React.Fragment>
                <form noValidate autoComplete="off">            
                    <div className={classes.responsiveTable}>
                        <Table style={{minWidth: 900}}>
                            <TableBody>
                                {partRows.map((item, idx) => {
                                    return (
                                        <React.Fragment key={`pr${idx}`}>
                                            <TableRow>
                                                <TableCell className={classes.td}>
                                                    <TextField
                                                        name="description"
                                                        value={item.partNumber + ' - ' +item.partDescription}
                                                        label="Description"
                                                        fullWidth
                                                        disabled
                                                        InputProps={{
                                                            className: 'disabledText'
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td} style={{width:'8%'}}>
                                                    <TextField
                                                        name="hsNumber"
                                                        value={item.hsNumber}
                                                        label="HS. Number"
                                                        fullWidth
                                                        disabled
                                                        InputProps={{
                                                            className: 'disabledText'
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td} style={{width:'10%'}}>
                                                    <TextField
                                                        name="countryOfOrigin"
                                                        value={item.countryOfOrigin}
                                                        label="Country of Origin"
                                                        fullWidth
                                                        disabled
                                                        InputProps={{
                                                            className: 'disabledText'
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td} style={{width:'8%'}}>
                                                    <TextField
                                                        name="unitWeight"
                                                        value={item.unitWeight}
                                                        label="Unit Weight"
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: ( <InputAdornment  position="start">Kg</InputAdornment>),
                                                            className: 'disabledText'
                                                        }}
                                                        disabled
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td} style={{width:'5%'}}>
                                                    <TextField
                                                        name="unitOfMeasure"
                                                        value={item.unitOfMeasure}
                                                        label="UOM"
                                                        fullWidth
                                                        disabled
                                                        InputProps={{
                                                            className: 'disabledText'
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td} style={{width:'8%'}}>
                                                    <TextField
                                                        name="quantity"
                                                        value={parseFloat(item.quantity)}
                                                        label="Quantity"
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        fullWidth
                                                        disabled
                                                        InputProps={{
                                                            className: 'disabledText'
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td} style={{width:'10%'}}>
                                                    <TextField
                                                        name="newUnitPrice"
                                                        value={item.newUnitPrice}
                                                        label="Unit Price"
                                                        type="number"
                                                        fullWidth
                                                        disabled
                                                        InputProps={{
                                                            startAdornment: ( <InputAdornment  position="start">£</InputAdornment>),
                                                            className: 'disabledText'
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td} style={{width:'10%'}}>
                                                    <TextField
                                                        name="totalAmount"
                                                        value={item.totalAmount}
                                                        label="Total Amount"
                                                        // error={(formErrors && formErrors['partRows|totalAmount|'+idx] && true) || false}
                                                        // helperText={formErrors && formErrors['partRows|totalAmount|'+idx]}
                                                        // onChange={handleRowChange(idx, 'partRows')}
                                                        // onBlur={handleRowChange(idx, 'partRows', 2)}
                                                        type="number"
                                                        fullWidth
                                                        disabled
                                                        InputProps={{
                                                            startAdornment: ( <InputAdornment  position="start">£</InputAdornment>),
                                                            className: 'disabledText'
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <br/><br/>
                        <Typography variant="body2">
                            Exporter of the products covered by this document EORI number GB978060588000 declares that, except where otherwise clearly indicated, these products are of UK preferential origin.<br/>
                            Clenaware Systems Limited
                        </Typography> 
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(PartItems);
    