import _ from 'lodash';
import React, { Component } from 'react';

import { Grid, Typography } from '@material-ui/core';

import DataTable from 'Components/Common/DataTables/CiDataTable';

import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';
import { connect } from 'react-redux';

const initialState = props => {
    return {
        isLoading: true,
        cr: props.cr,
        returnConditions: [],
        editFormData: {
            id: null,
            condition: null,
            notes: null,
            notesAfter: null,
            existingFiles: null,
            files:[],
            filesAfter: [],
            removedFiles: []
        }
    }
}

class ViewReturn extends Component {
    constructor(props) {
        super(props);

        this.state = initialState(props);
    }
   
    componentDidMount() {
        this.getData();
    }

    
    getData = () => {
        Promise.all([
            API.get(`/customerReturns/returns/returnConditions`),
        ]).then(res => {
            this.setState({ 
                returnConditions: _.map(_.orderBy(res[0].data, [r => {return {pass: 0,  warning: 1, Fail:2}[r.crrc_grade]}, 'crrc_condition'], ['asc', 'asc']), i => ({value: i.crrc_condition, label: `(${i.crrc_grade === 'pass' ? 'Pass' : i.crrc_grade === 'warning' ? 'Pass With Warning' : 'Fail'}) - ${i.crrc_condition}`})),
                isLoading: false 
            });
        })
        this.setState({ isLoading: false });
    }

    handleSelectChange = (name) => (e) => {

        let editFormData = this.state.editFormData;

        editFormData[name] = e ? e.value : null;

        this.setState({ editFormData: editFormData });

    }

    handleShowUpdate = (id) => () => {
        if (!id){
            this.setState({
                editFormData: {
                    id: null,
                }
            })
        } else {
            let details = this.props.cr.collection.details;
            let item = null;
            _.each(details, (d, i) => {
                if (_.find(d.received, {crcdr_id: id})){
                    item = _.find(d.received, {crcdr_id: id});
                }
            })
            if (item){
                this.setState({
                    editFormData: {
                        id: item.crcdr_id,
                        condition: item.crcdr_condition,
                        notes: item.crcdr_notes,
                        notesAfter: item.crcdr_notes_after,
                        existingFiles: item.files,
                        files:[],
                        filesAfter: [],
                        removedFiles: []
                    }
                })
            }
        }
    }

    handleInputChange = (name) => (e) => {
        let editFormData = this.state.editFormData;
        editFormData[name] = e.target.value;
        this.setState({ editFormData: editFormData });
    }

    handleDeleteMedia = ({crcdrf_id}) => {
        let editFormData = this.state.editFormData;
        editFormData.removedFiles.push(crcdrf_id);
        editFormData.existingFiles = _.filter(editFormData.existingFiles, f => f.crcdrf_id !== crcdrf_id);
        this.setState({ editFormData: editFormData });
    }

    handleUploads = type => (drop, name, event) => {
        let newFiles = drop === true ? event.dataTransfer.files : event.target.files;
        let existingFiles = this.state.editFormData[type];
        let allFiles = Array.prototype.slice.call(existingFiles).concat(Array.prototype.slice.call(newFiles))
        this.setState({
            editFormData: {
                ...this.state.editFormData,
                [type]: allFiles
            }
        });
    }

    clearUploads = type => idx => {
        let currentFiles = Array.from(this.state.editFormData[type]);
        currentFiles.splice(idx, 1);
        if(currentFiles.length === 0) currentFiles = '';

        this.setState({
            editFormData:{
                ...this.state.editFormData,
                [type]: currentFiles
            }
        });
    }


    updateReceived = () => {
        let formData = this.state.editFormData;
        let newformData = new FormData();

        _.forEach(_.keys(formData), key => {
            switch(key){
                case 'removedFiles':
                    newformData.append(key, JSON.stringify(formData[key]));
                    break;
                case 'existingFiles':
                    newformData.append(key, formData[key].length);
                    break;
                case 'files':
                    _.forEach(formData[key], file => {
                        newformData.append('files[]', file);
                    })
                    break;
                case 'filesAfter':
                    _.forEach(formData[key], file => {
                        newformData.append('filesAfter[]', file);
                    })
                    break;
                case 'notesAfter':
                case 'notes':
                    newformData.append(key, formData[key] || '');
                    break;
                default:
                    newformData.append(key, formData[key]);
            }
        })

        API.post(`/customerReturns/returns/${this.props.cr.cr_id}/received/${this.state.editFormData.id}/update`, newformData)
        .then(res => {
            if (res.data.error){
                this.deploySnackbar('error', 'Something went wrong, please try again');
            } else {
                this.handleShowUpdate(null)();
                this.props.refresh();
            }
        })
    }

    render() {

        const { isLoading, returnConditions, editFormData } = this.state;

        const { cr, outcomes, preview, update } = this.props;

        let { collection } = cr;
    
        if (isLoading) {
            return (
                <LoadingCircle/>
            )
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DataTable
                        config={{
                            key: 'crcd_id',
                            dropRow: {
                                droppable: rowData => parseFloat(rowData.crcd_recieved) > 0,
                                hideDropArrow: true,
                                columns: [
                                    {
                                        colSpan: 6,
                                        field: i => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                    Received By:
                                                </Grid>
                                                <Grid item xs={update ? 8 : 10}>
                                                    {i.crcdr_staff}
                                                </Grid>
                                                {update &&
                                                    <Grid item style={{marginLeft: 'auto'}}>
                                                        {editFormData.id === i.crcdr_id ?
                                                            <>
                                                                <AllIcon 
                                                                    icon={IconHelper.false}
                                                                    onClick={this.handleShowUpdate(null)}
                                                                    noMargin
                                                                    tooltip="Close"
                                                                />
                                                                <AllIcon 
                                                                    icon={IconHelper.true}
                                                                    onClick={()=>
                                                                        this.props.deployConfirmation(
                                                                            'Are you sure you want to submit this update?',
                                                                            'Update Received',
                                                                            this.updateReceived
                                                                        )
                                                                    }
                                                                    noMargin
                                                                    tooltip="Submit"
                                                                />
                                                            </>
                                                            :
                                                            <AllIcon
                                                                icon={IconHelper.edit}
                                                                onClick={this.handleShowUpdate(i.crcdr_id)}
                                                                noMargin
                                                                tooltip="Update"
                                                            />
                                                        }
                                                    </Grid>
                                                }
                                                <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                    Received On:
                                                </Grid>
                                                <Grid item xs={10}>
                                                    { clenyDate(i.crcdr_datetime) }
                                                </Grid>
                                                <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                    Received Qty:
                                                </Grid>
                                                <Grid item xs={10}>
                                                    { parseFloat(i.crcdr_recieved).toFixed(3) } 
                                                </Grid>
                                                {( JSON.parse(i.crcdr_serial_numbers).length > 0 || JSON.parse(i.crcdr_warranty_void_labels).length > 0) &&
                                                    <>
                                                        <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                            Serial Number / <br/> Warranty Void Label:
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            { JSON.parse(i.crcdr_serial_numbers).length > 0 &&
                                                                 JSON.parse(i.crcdr_serial_numbers).join(', ')
                                                            }
                                                            { JSON.parse(i.crcdr_warranty_void_labels).length > 0 &&
                                                                JSON.parse(i.crcdr_warranty_void_labels).join(', ')
                                                            }
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                    }
                                ],
                                data: 'received'
                            }
                        }}
                        columns={[
                            {
                                heading:       '',
                                field:         'part.default_image.thumbnail_file_path',
                                fieldFormat:   'image',
                                sizeToContent: true,
                            },
                            {
                                heading:       'Part Number',
                                field:         'part.part_number',
                                sizeToContent: true,
                            },
                            {
                                heading:       'Part Description',
                                field:         i => {
                                                    let sn = [];
                                                    let wvl = [];

                                                    _.each(i.received, j => {
                                                        sn  = [...sn,  ...JSON.parse(j.crcdr_serial_numbers)];
                                                        wvl = [...wvl, ...JSON.parse(j.crcdr_warranty_void_labels)];
                                                    })

                                                    return (    
                                                        <>
                                                            {i.part.part_description}<br />
                                                            {sn.length > 0 &&
                                                                <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>
                                                                    {_.map(sn, j => <>{j}<br/></>)}
                                                                </Typography>
                                                            }
                                                            {wvl.length > 0 &&
                                                                <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>
                                                                    {_.map(wvl, j => <>{j}<br/></>)}
                                                                </Typography>
                                                            }
                                                        </>
                                                    )
                                                },
                            },
                            {
                                heading:       'Expected',
                                field:         'crcd_expected',
                                fieldFormat:   'decimal:3',
                                sizeToContent: true
                            },
                            {
                                heading:       'Received',
                                field:         'crcd_recieved',
                                fieldFormat:   'decimal:3',
                                sizeToContent: true
                            },
                            {
                                heading:       'Remaining',
                                field:         'crcd_remaining',
                                fieldFormat:   'decimal:3',
                                sizeToContent: true,
                                style:         i => parseFloat(i.crcd_remaining) > 0 ? {color: 'red'} : {}
                            },
                            {
                                heading:       '',
                                field:         i => 
                                                    {
                                                        let fail = !!_.find(i.received, j => j.crcdr_condition_grade === 'fail');
                                                        let warning = !!_.find(i.received, j => j.crcdr_condition_grade === 'warning');

                                                        if (!fail && !warning) return '';

                                                        return (
                                                            <AllIcon
                                                                noMargin
                                                                icon={IconHelper.warning}
                                                                color={fail ? colors.red : colors.orange}
                                                                tooltip={fail ? 'Part/s failed inspection' : 'Part/s passed inspection but with warnings'}
                                                            />
                                                        )

                                                    },
                                sizeToContent: true,
                                alignment:     'center',
                            }
                        ]}
                        rows={collection.details}
                    />
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)            => dispatch(deploySnackBar(variant, msg))
});

export default connect(null, mapDispatchToProps)(ViewReturn);
