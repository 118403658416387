import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AdminRouteSwitcher from '../../Routes/AdminRoutes/AdminRouteSwitcher';
import ErrorUnauthorized from '../../Common/ErrorHandling/ErrorUnauthorized';

const styles = theme => ({
    pwaBarSpacer: {
        minHeight: 'env(safe-area-inset-top)',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        padding: theme.spacing(3),
        width: '100%',
        height: '100vh',
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
    container: {
        marginBottom: 50,
    },
    [theme.breakpoints.down('sm')]: {
        content: {
            marginLeft: theme.spacing(7),
        },
        container: {
            marginBottom: 0,
        }
    },
    [theme.breakpoints.down('xs')]: {
        content: {
            marginLeft: 0,
            padding: theme.spacing(2.5),
            webkitOverflowScrolling: 'touch',
        }
    },
    ['@media (orientation: landscape) and (max-height: 767px)']: { // eslint-disable-line no-useless-computed-key
        content: {
            marginLeft: 'env(safe-area-inset-left)',
            marginRight: 'env(safe-area-inset-right)',
        }
    },
});

const initialState = {
    scrollHeight: window.innerHeight,
}

class AdminContentWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.main = null;
        this.setMainRef = (element) => {
          this.main = element;
        };
    } 

    componentDidMount = () => {
        this.setState({
            scrollHeight: this.props.scrollHeight,
        });
    }

    componentDidUpdate(prevProps){
        if(this.props.scrollHeight !== prevProps.scrollHeight){
            this.setState({
                scrollHeight: this.props.scrollHeight,
            });
        }
        if(this.props.pagePath !== prevProps.pagePath || this.props.scrollToTopUuid !== prevProps.scrollToTopUuid){
            this.main.scrollTop = 0;
        }
    }

    scrollToTop = () => {
        this.main.scrollTop = 0;
    }

    render() {
        const { scrollHeight } = this.state;
        const { classes, changePath, changePage } = this.props;
        return (
            <React.Fragment>
                <main ref={this.setMainRef} className={classes.content} style={{height: window.matchMedia('(display-mode: standalone)').matches ? `100vh` : `${scrollHeight}px`}}>
                    <div className={classes.pwaBarSpacer} />
                    <div className={classes.appBarSpacer} />
                    <div className={classes.container} style={{marginTop: this.props.updateNotification !== false ? 35 : ''}}>
                        {(this.props.unauthorized && (
                            <ErrorUnauthorized />
                        )) || (
                            <AdminRouteSwitcher changePath={changePath} changePage={changePage} scrollToTop={this.scrollToTop} />
                        )}
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state){
    return {
        scrollToTopUuid: state.ui.scrollToTopUuid
    };
}

export default connect(mapStateToProps)(withStyles(styles)(AdminContentWrapper));