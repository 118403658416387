import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import API from '../../../API';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon from '../../Common/Icons/FontAwesome/FASolidIcon';
import {statusColour} from './../../../Helpers/ColourHelper'
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import DataTable from '../../Common/DataTables/CiDataTable';
import moment from 'moment';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import {colors} from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    keywords: '',
    searchResults: {},
    access: {
        updateDelivery: false
    },
});

class PartsOrderDelivery extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'partsOrdering:partsOrderDelivery';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.loadComponentData();
    }

    loadComponentData = () => {
        this.getSearchData();
        this.checkAccess();
    }

    checkAccess = () => {
        API.get('/staff/my/access/check/update-parts-order-delivery')
        .then((viewRes) =>  {
            viewRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        updateDelivery: viewRes.data.has_access
                    }
                });
        });
    }

    getSearchData = () => {
        const params = {
            keywords: this.state.keywords,
            status: 'Delivery'
        }
        this.setState({
            isLoading: true
        }, () => {
            API.get('/partsOrdering', {
                params,
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    _.map(result.data, (ppo) => {
                        ppo.colour = statusColour(ppo.ppo_status);
                    });
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    })
                }
            })
        })
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.setState({
            keywords: '',
        }, 
        () => {
            this.clearPageState();
            this.getSearchData();
        });
    }

    downloadFile = type => rowData => {
        API.get('/partsOrdering/' + rowData.ppo_id + '/' + type)
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Parts Order Delivery
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={this.state.keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                           
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key: 'ppo_id',
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: this.state.isLoading
                            }}
                            columns={[
                                {
                                    heading: 'Parts Order Date',
                                    field: rowData => moment(rowData.ppo_date).format("DD/MM/YYYY"),
                                    sizeToContent: true
                                },
                                {
                                    actionsCustomHeader:    true,
                                    sizeToContent:          true,
                                    alignment:              'left',
                                    heading:                'Parts Order Number',
                                    actions: rowData => {
                                        return [
                                            {label: rowData.ppo_number + '-' + rowData.ppo_number_amendment, link: `/parts-order/view/${rowData.ppo_id}`, type:'plainLink',}
                                        ]
                                    }
                                },
                                {
                                    heading: 'Company Name',
                                    field: rowData => rowData.ppo_supplier_name
                                },
                                {
                                    heading: 'Outstanding Invoices',
                                    alignment: 'center',
                                    field: rowData => (
                                        <React.Fragment>
                                            {rowData.receivedDeliveries === 0 ?
                                                <FALightIcon 
                                                    icon='truck'
                                                    noMargin 
                                                />
                                            :
                                                <React.Fragment>
                                                    {rowData.outstandingInvoices && rowData.outstandingInvoices.length > 0 ?
                                                        <Tooltip 
                                                            title={
                                                                <React.Fragment>
                                                                    <Typography variant="body1"><strong>{rowData.outstandingInvoices.length} Outstanding Delivery Invoices</strong></Typography>
                                                                    {(_.map(rowData.outstandingInvoices, (inv,idx) => {
                                                                        return (<Typography key={idx} variant="body2">{ moment(inv.ppo_file_delivery_date).format("DD/MM/YYYY") }</Typography>)
                                                                    }))}
                                                                </React.Fragment>
                                                            } 
                                                            placement="right"
                                                        >
                                                            <Link href={`/parts-order/view/${rowData.ppo_id}`}>
                                                                <FASolidIcon 
                                                                    icon='file-alt'
                                                                    button 
                                                                    noMargin 
                                                                    style={{color: colors.red}} 
                                                                />
                                                            </Link>
                                                        </Tooltip>
                                                    :
                                                        <FASolidIcon 
                                                            icon='file-alt'
                                                            noMargin 
                                                            style={{color: colors.green}} 
                                                        />
                                                    }
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    )
                                },
                                {
                                    heading: 'Placed By',
                                    field: rowData => rowData.ppo_placed_by,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Total Price',
                                    field: rowData => rowData.ppo_total_price,
                                    fieldPrefix: '£',
                                    alignment: 'right',
                                    sizeToContent: true
                                },
                                {
                                    actions: rowData => {
                                        let ret = [
                                            {name: 'Download', icon: 'download', onClick: this.downloadFile('pdfPartsOrder')},
                                            {name: 'Download Delivery Schedule', icon: 'calendar-alt', onClick: this.downloadFile('pdfDeliverySchedule')},
                                        ]
                                        ret.push( rowData.locked ? 
                                            {name: 'Locked', icon: icons.lock, onClick:()=>{}}:
                                            {name: 'View',   icon: 'search',   link: '/parts-order/delivery/update/' + rowData.ppo_id, disabled: !this.state.access.updateDelivery}
                                        )
                                        return ret;
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(PartsOrderDelivery);
