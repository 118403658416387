import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import API from '../../../API';
import _ from 'lodash';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import Button from '@material-ui/core/Button';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import MinMaxCalculator from 'Components/Stock/MinMaxCalculator/MinMaxCalculator'

const initialState = {
    formData:  {
        part: '',
        quantity: '',
        minLevel: '',
        maxLevel: '',
        openingStockDate: moment().format("DD/MM/YYYY"),
    },
    partList: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
}

class OpeningStock extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getParts();
    }

    getParts = () => {
        let partList;
        API.get('/parts/withoutStock')
        .then((partRes) =>  {
            if(partRes.data){
                partList = _.map(partRes.data, el => {
                    return _.assign({
                        value: el.part_id,
                        label: el.part_number + ' - ' + el.part_description
                    });
                });
            }
            this.setState({
                partList: partList
            });
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        API.post('/stock', this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState(initialState);
                this.setState({
                    snackbarOpen: true
                });
                this.getParts();
            }
            this.props.scrollToTop();
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        });
    }

    handleDecimalChange = decimals => e => {
        const { name, value } = e.target;
        let newVal = parseFloat(value).toFixed(decimals);
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: newVal
            }
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }
       
    render() {
        const { formErrors } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Opening Stock
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Typography variant="h6">
                                    Select Part
                                </Typography>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.partList} 
                                        label='Part *'
                                        onChange={this.handleSelectChange('part')}
                                        error={formErrors && formErrors['part'] && true}
                                        errorText={formErrors && formErrors['part']}
                                        value={this.state.formData.part}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        name="quantity"
                                        label='Quantity *'
                                        value={this.state.formData.quantity}
                                        error={formErrors && formErrors['quantity'] && true}
                                        helperText={formErrors && formErrors['quantity']}
                                        onChange={this.handleChange}
                                        onBlur={this.handleDecimalChange(3)}
                                        type="number"
                                        inputProps={{ min: 0 }}
                                    />
                                </FormControl>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} lg={6}>
                                        <FormControl fullWidth margin="normal">
                                            <TextField
                                                name="minLevel"
                                                label='Minimum Level *'
                                                value={this.state.formData.minLevel}
                                                error={formErrors && formErrors['minLevel'] && true}
                                                helperText={formErrors && formErrors['minLevel']}
                                                onChange={this.handleChange}
                                                onBlur={this.handleDecimalChange(3)}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}lg={6}>
                                        <FormControl fullWidth margin="normal">
                                            <TextField
                                                name="maxLevel"
                                                label='Maximum Level *'
                                                value={this.state.formData.maxLevel}
                                                error={formErrors && formErrors['maxLevel'] && true}
                                                helperText={formErrors && formErrors['maxLevel']}
                                                onChange={this.handleChange}
                                                onBlur={this.handleDecimalChange(3)}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <FormControl margin="normal">
                                    <TextField
                                        name="openingStockDate"
                                        label='Opening Stock Date'
                                        value={this.state.formData.openingStockDate}
                                        disabled
                                    />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.handleConfirmationOpen}
                                            variant="contained"
                                            color="primary">
                                        Add
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12} lg={6}></Grid>
                    {this.state.formData.part ?
                        <Grid item xs={12} lg={6}>
                            <MinMaxCalculator 
                                id= {this.state.formData.part}
                                min={this.state.formData.minLevel}
                                max={this.state.formData.maxLevel}
                            />
                        </Grid> : <></>}
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Add This Opening Stock?" 
                    message="Are you sure you want to add this opening stock?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added this opening stock'
                />
            </Grid>
        );
    }
}

export default OpeningStock;
