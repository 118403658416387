import API from 'API';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, Grid } from '@material-ui/core/';
import Textarea from 'Components/Common/Inputs/Textarea';

import { closeDialog } from 'Actions/Dialog/Dialog';

const initialState = {
    formData:      {
        note:      null,
    },
}

class ImportantInfoDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() { 
        this.setState({ 
            formData: { 
                ...this.state.formData, 
                note: this.props.worksOrder.wo_important_note 
            } 
        });
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ formData: { ...this.state.formData, [name]: value } });
    }

    handleSubmit = () => {
        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/addImportantNote`, this.state.formData)
        .then(res => {
            this.props.refresh();
            this.props.closeDialog();
        })
    }

    handleRemove = () => {
        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/addImportantNote`, {note: null})
        .then(res => {
            this.props.refresh();
            this.props.closeDialog();
        })
    }

    render(){

        const { formData }   = this.state;

        return (
            <Grid container spacing={2}>
               <Grid item xs={12}>
                    <Textarea
                        name="note"
                        value={formData.note}
                        onChange={this.handleChange}
                        rows={5}
                        style={{marginTop: 0}}
                    />
               </Grid>
               <Grid item xs={12} className='buttonRow'>
                    <Button variant='outlined' onClick={this.props.closeDialog}>Cancel</Button>
                    {formData.notes && <Button variant='contained' color='secondary' onClick={this.handleRemove}>Remove</Button>}
                    <Button variant='contained' color='primary' onClick={this.handleSubmit}>Update</Button>
               </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                              => dispatch(closeDialog())
})

export default connect(null, mapDispatchToProps)(ImportantInfoDialog);