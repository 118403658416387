import React       from 'react';
import API         from 'API';
import _           from 'lodash';
import { connect } from 'react-redux';

import { Typography, Grid, FormControl, TextField, Button } from '@material-ui/core'; 

import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable          from 'Components/Common/DataTables/CiDataTable';
import FALightIcon        from 'Components/Common/Icons/FontAwesome/FALightIcon';
import AllIcon            from 'Components/Common/Icons/AllIcon';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons                      from 'Helpers/IconHelper';
import {colors}                   from 'Helpers/ColourHelper';

import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    formData: {
        subAssembly: '',
        quantity: '',
        display: 'all',
        results: null
    },
    subAssembliesList: [],
    isLoading: false
});

class BuildChecker extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'stock:BuildChecker';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        this.getSubAssemblies();
        this.hasPageState() &&  this.submit();
    }

    getSubAssemblies = () => {
        API.get('/subAssemblies/all')
        .then((result) => {
            let subAssembliesList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.sa_id,
                    label: el.part_number + ' - ' + el.part_description
                });
            });
            this.setState({
                subAssembliesList: subAssembliesList
            });
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, this.savePageState);
    };

    handleChange = (event) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.value
            }
        }, this.savePageState);
    }

    reset = () => {
        this.setState({
            formData: initialState().formData
        }, ()=>this.clearPageState());
    }

    submit = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.post('/stock/buildChecker', this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        ...this.state,
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...this.state,
                        formData: {
                            ...this.state.formData,
                            results: result.data
                        },
                        isLoading: false
                    }); 
                }
            });
        })
    }

    render() {
      const { formData, formErrors } = this.state;
      return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Build Checker - Stock Levels
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper> 
                            <form noValidate autoComplete="off">
                                <Typography variant="h6">
                                    Select the sub assembly you wish to build and the quantity
                                </Typography>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.subAssembliesList}
                                        error={formErrors && formErrors['subAssembly'] && true}
                                        errorText={formErrors && formErrors['subAssembly']}
                                        label='Sub Assembly *'
                                        value={formData.subAssembly}
                                        onChange={this.handleSelectChange('subAssembly')}
                                    />
                                </FormControl>
                                <TextField
                                    id="quantity"
                                    name="quantity"
                                    label="Required Quantity *"
                                    value={this.state.formData.quantity}
                                    error={formErrors && formErrors['quantity'] && true}
                                    helperText={formErrors && formErrors['quantity']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    type="number"
                                    inputProps={{ min: 0 }}
                                />
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        name="display"
                                        options={[
                                            {value: 'all', label: 'All'},
                                            {value: 'differences_only', label: 'Negative Differences Only - Stock Only'},
                                            {value: 'totals_only', label: 'Negative Totals Only - Stock & Deliveries'}
                                        ]}
                                        label='Display'
                                        onChange={this.handleSelectChange('display')}
                                        value={this.state.formData.display}
                                    />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.reset}
                                            variant="outlined"
                                            color="default"
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset
                                    </Button>
                                    <Button onClick={this.submit}
                                            variant="contained"
                                            color="primary">
                                        Run Check
                                    </Button>
                                </div> 
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {(this.state.formData.results || this.state.isLoading) && (
                        <PaddedPaper>
                            <DataTable  
                                config={{
                                    key:                    'rowNumber',
                                    nesting:                true,
                                    isLoading:              this.state.isLoading,
                                    persistenceId:          this.persistenceId,
                                    sticky:                 true
                                }}
                                columns={[
                                    {
                                        heading: 'Part',
                                        field: rowData => rowData.part,
                                        nestingDropdown: true,
                                        style: rowData => {
                                            return ({
                                                fontWeight: (rowData.children && 'bold') || 'normal',
                                                backgroundColor: rowData.children && `${colors.grey}${25 - (10 * (rowData.depth ? rowData.depth : 0 ) )}`
                                            })
                                        }
                                        
                                    },
                                    {
                                        heading: 'Status',
                                        field: rowData => parseFloat(rowData.difference) < 0 ? 
                                            <AllIcon icon={icons.warning} color={colors.red}/> 
                                            : <AllIcon icon={icons.stockStock} color={colors.green}/>,
                                        
                                        alignment: 'center',
                                        style: rowData => ({
                                            fontWeight: (rowData.children && 'bold') || 'normal',
                                            backgroundColor: rowData.children && `${colors.grey}${25 - (10 * (rowData.depth ? rowData.depth : 0 ) )}`
                                        }),
                                    },
                                    {
                                        heading: 'Qty Needed',
                                        fieldFormat: 'decimal:3',
                                        field: rowData => rowData.qty_needed,
                                        cellProps: rowData => {
                                            return ({
                                                style: {
                                                    fontWeight: (rowData.children && 'bold') || 'normal'
                                                }
                                            });
                                        },
                                        
                                        alignment: 'right',
                                        sizeToContent: true,
                                        style: rowData => ({
                                            fontWeight: (rowData.children && 'bold') || 'normal',
                                            backgroundColor: rowData.children && `${colors.grey}${25 - (10 * (rowData.depth ? rowData.depth : 0 ) )}`
                                        }),
                                    },
                                    {
                                        heading: 'In Stock',
                                        fieldFormat: 'decimal:3',
                                        field: rowData => rowData.in_stock,
                                        cellProps: rowData => {
                                            return ({
                                                style: {
                                                    fontWeight: (rowData.children && 'bold') || 'normal'
                                                }
                                            });
                                        },
                                        
                                        alignment: 'right',
                                        sizeToContent: true,
                                        style: rowData => ({
                                            fontWeight: (rowData.children && 'bold') || 'normal',
                                            backgroundColor: rowData.children && `${colors.grey}${25 - (10 * (rowData.depth ? rowData.depth : 0 ) )}`
                                        }),
                                    },
                                    {
                                        heading: 'Difference',
                                        fieldFormat: 'decimal:3',
                                        field: rowData => rowData.difference,
                                        cellProps: rowData => {
                                            return ({
                                                style: {
                                                    fontWeight: (rowData.children && 'bold') || 'normal',
                                                    color: rowData.difference < 0 ? colors.red : rowData.difference > 0 ? colors.green : ''
                                                }
                                            });
                                        },
                                      
                                        alignment: 'right',
                                        sizeToContent: true,
                                        style: rowData => ({
                                            fontWeight: (rowData.children && 'bold') || 'normal',
                                            backgroundColor: rowData.children && `${colors.grey}${25 - (10 * (rowData.depth ? rowData.depth : 0 ) )}`
                                        }),
                                    },
                                    {
                                        heading: 'In Production',
                                        fieldFormat: 'decimal:3',
                                        field: rowData => rowData.in_production,
                                        cellProps: rowData => {
                                            return ({
                                                style: {
                                                    fontWeight: (rowData.children && 'bold') || 'normal'
                                                }
                                            });
                                        },
                                        
                                        alignment: 'right',
                                        sizeToContent: true,
                                        style: rowData => ({
                                            fontWeight: (rowData.children && 'bold') || 'normal',
                                            backgroundColor: rowData.children && `${colors.grey}${25 - (10 * (rowData.depth ? rowData.depth : 0 ) )}`
                                        }),
                                    },
                                    {
                                        heading: 'To Be Delivered',
                                        fieldFormat: 'decimal:3',
                                        field: rowData => rowData.to_be_delivered,
                                        cellProps: rowData => {
                                            return ({
                                                style: {
                                                    fontWeight: (rowData.children && 'bold') || 'normal'
                                                }
                                            });
                                        },
                                        
                                        alignment: 'right',
                                        sizeToContent: true,
                                        style: rowData => ({
                                            fontWeight: (rowData.children && 'bold') || 'normal',
                                            backgroundColor: rowData.children && `${colors.grey}${25 - (10 * (rowData.depth ? rowData.depth : 0 ) )}`
                                        }),
                                    },
                                    {
                                        heading: 'Total',
                                        field: rowData => rowData.total,
                                        cellProps: rowData => {
                                            return ({
                                                style: {
                                                    fontWeight: (rowData.children && 'bold') || 'normal',
                                                    color: rowData.total < 0 ? colors.red : colors.green
                                                }
                                            });
                                        },
                                        
                                        alignment: 'right',
                                        sizeToContent: true,
                                        style: rowData => ({
                                            fontWeight: (rowData.children && 'bold') || 'normal',
                                            backgroundColor: rowData.children && `${colors.grey}${25 - (10 * (rowData.depth ? rowData.depth : 0 ) )}`
                                        }),
                                    }
                                ]}
                                rows={formData.results}
                            />
                        </PaddedPaper>
                    )}
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(BuildChecker);