import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import BarChart from 'Components/Common/Charts/BarChart';
import LineChart from 'Components/Common/Charts/LineChart';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { getColorCurrency, getColorPercentageChange, months } from 'Helpers/ReportsHelper';
import { clenyDate, getOrdinal } from 'Helpers/TimeHelper';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';


const ConsistentSalesRow = ({icon, data, comparator, showRed, showComparator}) => {
	return (
		<>
			<Grid item xs={showComparator ? 2 : 3} style={{textAlign:'center'}}><AllIcon icon={icon} size='xs' /></Grid>
			<Grid item xs={showComparator ? 4 : 9} style={{color: showRed && colors.red}}>{data < 0 ? '-' : ''}£{parseFloat(Math.abs(data)).toFixed(2)}</Grid>
            {showComparator && <Grid item xs={6}>{comparator}</Grid> }
        </>
	)
}

class SalesReportCompleteMonth extends React.Component {


    render() {
        const {year, month, isLoading, data} = this.props;

        let
            currentYear = `${months[month]} ${month >= 10 ? (year-1) : year}`,
            currentDay = moment().format('D'),
            daysInMonth = moment().daysInMonth();

        let current = true;

        return (
            <Grid item xs={12}>
                <Card style={{color: '#000',backgroundColor: '#FFF',padding: 0}} >
                    <CardHeader
                        avatar={<AllIcon icon={`TextToIcon:${`${month}`.padStart(2,'0')}`} size='medium' style={{color: '#fff'}} noMargin/>}
                        title={
                            <Grid container spacing={1} style={{alignItems: 'flex-end'}}>
                                <Grid item >
                                    <>{months[parseInt(month)]}</>
                                </Grid>
                                <Grid item >
                                    <Typography variant="body1" style={{paddingBottom: 1}} >{current ? ` Up Until ${getOrdinal(moment().format('D'))} ${moment().format('MMMM YYYY')}` : ''}</Typography>
                                </Grid>
                            </Grid>
                        }
                        titleTypographyProps={{variant:'h6'}}
                        style={{background: 'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', color: '#FFF'}}
                    />
                    <CardContent style={{padding:15}}>
                    {isLoading ? 
                        <Grid item xs={12}>
                            <LoadingCircle/>
                        </Grid>
                    :
                        <Grid container spacing={2}>

                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>{currentYear}</b></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PaddedPaper style={{padding: 10}}>
                                            <Grid container>
                                                <Grid item>
                                                    <Typography gutterBottom variant='caption'><b>Total {current ? `To ${clenyDate(moment())}` : ''}</b></Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{marginLeft: 'auto'}}>
                                                    <Typography gutterBottom variant='caption'><b>Difference</b></Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                {
                                                    _.map([
                                                        {
                                                            icon: IconHelper.products,
                                                            k: 'p'
                                                        },
                                                        {
                                                            icon: IconHelper.services,
                                                            k: 's'
                                                        },
                                                        {
                                                            icon: IconHelper.total,
                                                            k: 't'
                                                        },
                                                    ], ({k, icon}) => 
                                                    
                                                        <ConsistentSalesRow icon={icon} data={data.t.c.m[month][k].b} comparator={
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={7}>
                                                                    {getColorCurrency(data.t.c.m[month][k].b - data.b.c.m[month][k].b , false, false)}
                                                                </Grid>
                                                                
                                                                <Grid item xs={5}>
                                                                    {getColorPercentageChange(data.t.c.m[month][k].b, data.b.c.m[month][k].b, false, false)}
                                                                </Grid> 
                                                            </Grid>
                                                        } showComparator />
                                                    )
                                                }
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Budget</b></Typography>
                                    </Grid>
                                    {!!current &&
                                        <Grid item xs={6}>
                                            <PaddedPaper style={{padding: 10}}>
                                                <Typography gutterBottom variant='caption'><b>Budget {current ? `To ${clenyDate(moment())}` : ''}</b></Typography>
                                                <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                    {_.map([
                                                        {
                                                            icon: IconHelper.products,
                                                            k: 'p'
                                                        },
                                                        {
                                                            icon: IconHelper.services,
                                                            k: 's'
                                                        },
                                                        {
                                                            icon: IconHelper.total,
                                                            k: 't'
                                                        },
                                                    ], ({k, icon}) => 
                                                    <ConsistentSalesRow icon={icon} data={data.b.c.m[month][k].b} />
                                                )}
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>
                                    }
                                    <Grid item xs={current ? 6 : 12}>
                                        <PaddedPaper style={{padding: 10}}>
                                            <Typography gutterBottom variant='caption'><b>Budget Month</b></Typography>
                                            <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                {_.map([
                                                    {
                                                        icon: IconHelper.products,
                                                        k: 'p'
                                                    },
                                                    {
                                                        icon: IconHelper.services,
                                                        k: 's'
                                                    },
                                                    {
                                                        icon: IconHelper.total,
                                                        k: 't'
                                                    },
                                                ], ({k, icon}) => 
                                                <ConsistentSalesRow icon={icon} data={data.b.t.m[month][k].b} />
                                            )}
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Difference</b></Typography>
                                    </Grid>
                                    {current &&
                                        <Grid item xs={6}>
                                            <PaddedPaper style={{padding: 10}}>
                                                <Typography gutterBottom variant='caption' style={{paddingBottom: 8}}><b>Total {current ? `To ${clenyDate(moment())}` : ''}</b></Typography>
                                                <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                    <Grid item={3}>
                                                        <AllIcon icon={data.t.c.m[month].t.b > data.b.c.m[month].t.b ? IconHelper.arrowStemUp : (data.t.c.m[month].t.b < data.b.c.m[month].t.b ? IconHelper.arrowStemDown : IconHelper.dash)} size={65} color={
                                                            data.t.c.m[month].t.b > data.b.c.m[month].t.b ? colors.green : (data.t.c.m[month].t.b < data.b.c.m[month].t.b ? colors.red : colors.grey)
                                                        } />
                                                    </Grid>
                                                    <Grid item={9}>
                                                        <Typography variant='body1'>{getColorPercentageChange(data.t.c.m[month].t.b, data.b.c.m[month].t.b, false, false)}</Typography>
                                                        <Typography variant='h5'>{getColorCurrency(data.t.c.m[month].t.b - data.b.c.m[month].t.b , false, false)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>
                                    }
                                    <Grid item xs={current ? 6 : 12}>
                                        <PaddedPaper style={{padding: 10}}>
                                            <Typography gutterBottom variant='caption' style={{paddingBottom: 8}}><b>Total Month</b></Typography>
                                            <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                <Grid item={3}>
                                                    <AllIcon icon={data.t.t.m[month].t.b > data.b.t.m[month].t.b ? IconHelper.arrowStemUp : (data.t.t.m[month].t.b < data.b.t.m[month].t.b ? IconHelper.arrowStemDown : IconHelper.dash)} size={65} color={
                                                        data.t.t.m[month].t.b > data.b.t.m[month].t.b ? colors.green : (data.t.t.m[month].t.b < data.b.t.m[month].t.b ? colors.red : colors.grey)
                                                    } />
                                                </Grid>
                                                <Grid item={9}>
                                                    <Typography variant='body1'>{getColorPercentageChange(data.t.t.m[month].t.b, data.b.t.m[month].t.b, false, false)}</Typography>
                                                    <Typography variant='h5'>{getColorCurrency(data.t.t.m[month].t.b - data.b.t.m[month].t.b , false, false)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Day On Day</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Running Total</b></Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <PaddedPaper style={{padding: 10}}>
                                    <BarChart
                                        data={_.map(_.range(1, (daysInMonth + 1)), idx => {
                                            let nullMonth = currentDay < parseInt(idx);

                                            return {
                                                label: parseInt(idx),
                                                value: {
                                                    [currentYear] : nullMonth ? null : ( data.t[currentDay === parseInt(idx) ? 'c' : 't'].m[month].d[idx].f ? parseFloat(data.t[currentDay === parseInt(idx) ? 'c' : 't'].m[month].d[idx].f.t.b) : 0),
                                                    'Budget' :   nullMonth ? null : ( data.b.t.m[month].d[idx] ? parseFloat(data.b.t.m[month].d[idx].f.t.b) : 0), 
                                                },
                                                color: {
                                                    [currentYear] : colors.primary,
                                                    'Budget' :    colors.red,
                                                },
                                                bold:  current ?  parseInt(currentDay) === parseInt(idx) : 0
                                            }
                                            
                                        })}
                                        yLabel={i => `${i<0?'-':''}£${Math.abs(i)}`}
                                        tLabel={(e)=>`${parseFloat(e.raw)<0?'-':''}£${parseFloat(Math.abs(e.raw)).toFixed(2)}`}
                                        style={{
                                            height: 300,
                                        }}
                                    />
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={6}>
                                <PaddedPaper style={{padding: 10}}>
                                    <LineChart
                                        data={[
                                            {
                                                label:'0',
                                                value: {
                                                    [currentYear] : 0,
                                                    'Budget' :   0,
                                                },
                                                color: {
                                                    [currentYear] : colors.primary,
                                                    'Budget' :    colors.red,
                                                },
                                            },
                                            ..._.map(_.range(1, (daysInMonth + 1)), idx => {
                                                let nullMonth = currentDay < parseInt(idx);

                                                return {
                                                    label: parseInt(idx),
                                                    value: {
                                                        [currentYear] : nullMonth ? null : ( data.t.t.m[month].d[idx] ? parseFloat(data.t.t.m[month].d[idx].r.t.b) : 0),
                                                        'Budget' :  parseFloat(data.b.t.m[month].d[idx].r.t.b), 
                                                    },
                                                    color: {
                                                        [currentYear] : colors.primary,
                                                        'Budget' :    colors.red,
                                                    },
                                                    bold:  current ?  parseInt(currentDay) === parseInt(idx) : 0
                                                }
                                            
                                            })
                                        ]}
                                        yLabel={i => `${i<0?'-':''}£${Math.abs(i)}`}
                                        tLabel={(e)=>`${parseFloat(e.raw)<0?'-':''}£${parseFloat(Math.abs(e.raw)).toFixed(2)}`}
                                        style={{
                                            height: 300,
                                        }}
                                        noPoints
                                    />
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    }
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

export default SalesReportCompleteMonth;