import React, { Component } from 'react';
import { deployDialog }     from 'Actions/Dialog/Dialog';
import { deploySnackBar }   from 'Actions/SnackBar/SnackBar';
import { connect }          from 'react-redux';
import _                    from 'lodash';
import moment               from 'moment';
import API                  from 'API';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import DataTable                   from 'Components/Common/DataTables/CiDataTable';
import AllIcon                     from 'Components/Common/Icons/AllIcon';
import FALightIcon                 from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper                 from 'Components/Common/Paper/PaddedPaper';
import StickyNotes                 from 'Components/Common/StickyNotes/StickyNotes';
import OrderSearchBlock            from 'Components/Sales/Order/OrderSearch/OrderSearchBlock';
import ApproveOrderDialog          from 'Components/Sales/Order/ApproveOrderDialog/ApproveOrderDialog';
import DeclineOrderDialog          from 'Components/Sales/Order/DeclineOrderDialog/DeclineOrderDialog';
import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog    from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, Tooltip, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';
import { downloadS3File }                                               from 'Functions/MiscFunctions';
import JobSnapshot from 'Components/Engineers/Jobs/Misc/JobSnapshot';
import IconHelper from 'Helpers/IconHelper';

const styles = theme => ({
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    },
    dialogContent: {
        padding: theme.spacing(2)
    },
    custName: {
        '&:hover':{cursor: 'pointer'}
    }
});

const initialState = () => ({
    access: {
        orderSearch: false
    },
    formData: {
        paymentTermId: null,
        notesText: ''
    },
    formErrors: [],
    orderStats: [],
    paymentTermsList: [],
    keywords: '',
    status: 'Awaiting Approval',
    dateFrom: null,
    dateTo: null,
    searchResults: {},
    isLoading: false,

    statusWarningOpen: false
});

class OrderApproval extends Component {
    
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Sales:approval';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        this.getSearchData();
    }

    getSearchData = () => {
        const params = {
            keywords: this.state.keywords,
            status: this.state.status,
            dateFrom: this.state.dateFrom !== null ? moment(this.state.dateFrom).format('YYYY-MM-DD') : null, 
            dateTo: this.state.dateTo !== null ? moment(this.state.dateTo).format('YYYY-MM-DD') : null
        }
        this.setState({
            isLoading: true
        },
        () => {
            Promise.all([
                API.get('/staff/my/access/check/customer-order-search'),
                API.get('/sales/orders', {params}),
                API.get('/customers/paymentTerms'),
                API.get('/staff/my/access/check/repeat-customer-order'),
            ])
            .then(([accessRes, resultRes, paymentRes, repeatOrderRes]) => {
                let paymentTermsList = [];

                paymentTermsList = _.map(paymentRes.data, el => {
                    return _.assign({
                        value: el.payment_term_id,
                        label: el.payment_term_name,
                    });
                });

                this.setState({
                    ...this.state,
                    access: {
                        customerOrderSearch: (accessRes.data && accessRes.data.has_access) || false,
                        repeatOrder:         ( repeatOrderRes.data   && repeatOrderRes.data.has_access )   || false
                    },
                    searchResults: _.map(resultRes.data, i => {
                        i.f = {
                            ej: _.find(i.past_revisions, p => {return _.find(p.files, {co_file_type: "Engineer Job Sheet"})} ) ? 1 : 0,
                            po: _.find(i.past_revisions, p => {return _.find(p.files, {co_file_type: "Purchase Order"})}     ) ? 1 : 0,
                        }
                        return i;
                    }),
                    paymentTermsList,
                    isLoading: false
                });
            });
        });
    }

    handleDateChange = name => date => {
        this.setState({
            [name]: date
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.setState({
            keywords: '',
            status: 'Awaiting Approval',
            dateFrom: null,
            dateTo: null,
        }, 
        () => {
            this.clearPageState();
            this.getSearchData();
        });
    }

    handleApproveOrder = rowData => {

        if(rowData.customers.account_status.cas_name == 'Active - Not Verified'){
            this.setState({ 
                statusWarningOpen: true 
            });
        }
        else {
            const dialog = <ApproveOrderDialog 
                orderId={rowData.order_id}
                paymentTermsList={this.state.paymentTermsList}
                callback={this.getSearchData}
            />
            const title = <React.Fragment>You Are About To <b>Approve</b> This Order</React.Fragment>;

            this.props.deployDialog(dialog, title, 'success');
        }
    }

    handleStatusWarningClose = () => {
        this.setState({ 
            statusWarningOpen: false 
        });
    }

    handleDeclineOrder = rowData => {
        const dialog = <DeclineOrderDialog 
            orderId={rowData.order_id}
            callback={this.getSearchData}
        />
        const title = <React.Fragment>You Are About To <b>Decline</b> This Order</React.Fragment>

        this.props.deployDialog(dialog, title, 'warning');
    }
    
    handleDownloadOpen = orderId => this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md');

    handleEmailOpen = orderId => this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />, 'Email Previous Documents', '', 'md');
    
    handleShowStickyNote = orderId => { this.props.deployDialog(<StickyNotes orderId={orderId} dialog/>, 'Sticky Notes', null,'xs') }

    handleSetSearch = props => {
        this.setState({
            ...props,
            status: 'Awaiting Approval',
        },this.getSearchData)
    }

    render() {
        const { access } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Orders Awaiting Approval
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={12}>
                        <OrderSearchBlock disableSites disableStatus callBack={this.handleSetSearch} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key:                    'order_id',
                                pagination:             true,
                                alternatingRowColours:  true,
                                responsiveimportant:    true,
                                persistenceId:          this.persistenceId,
                                isLoading:              this.state.isLoading
                            }}
                            columns={[
                                {
                                    heading: 'Order Date',
                                    field: rowData => moment(rowData.order_date).format("DD/MM/YYYY"),
                                    important: true,
                                },
                                {
                                    actionsCustomHeader:    true,
                                    important:              true,
                                    alignment:              'left',
                                    heading:                'Reference',
                                    actions: rowData => {
                                        return [
                                            { label: rowData.full_reference,                                link: `/sales/order/view/${rowData.order_id}`,              type:'plainLink'},
                                            { label: rowData?.quotation_full_reference?.full_reference,     link: `/sales/quotation/view/${rowData.order_quote_id}`,    type:'plainLink', class: "textSecondary"}
                                        ]
                                    }
                                },
                                // {
                                //     heading: 'Discount',
                                //     field: rowData => rowData.order_type === 'With Discount',
                                //     important: true,
                                //     sizeToContent: true,
                                //     fieldFormat: 'boolean',
                                //     tooltip: rowData => rowData.order_type,
                                //     alignment: 'center'
                                // },
                                {
                                    heading: 'Customer',
                                    field: rowData =>   <Tooltip
                                                            title={
                                                                <Typography variant="body2">
                                                                    <b>Delivery Address:</b><br />
                                                                    {rowData.order_delivery_cust_address.split('\n').map((item, key) => {
                                                                        return  <span key={key}>
                                                                                    {item}{rowData.order_delivery_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                                                </span>
                                                                    })}
                                                                </Typography>
                                                            }
                                                            placement="left"
                                                        >
                                                            <span className={this.props.classes.custName} onClick={()=>{ this.props.history.push(`/customers/view/${rowData.order_cust_id}`); }}>
                                                                {rowData.order_cust_name}
                                                                {' '}
                                                                <Typography component="div" variant="body2">
                                                                    {rowData.order_delivery_cust_address_name}
                                                                </Typography>
                                                            </span>
                                                        </Tooltip>,
                                    important: true,
                                    truncate: true,
                                },
                                {
                                    heading: 'Files',
                                    field: rowData => 
                                        <Grid container style={{width:'100%'}} spacing={1} xs={12} alignItems='flex-end' justify="center">
                                             {!!rowData.ejs_url && 
                                                <Grid item xs={12} lg={6}>
                                                    <AllIcon 
                                                        icon='user-hard-hat'  
                                                        button 
                                                        noMargin
                                                        tooltip='Engineer Job Sheet'
                                                        onClick={() => {downloadS3File(rowData.ejs_url)}}
                                                    />
                                                </Grid>
                                            }
                                            {!!rowData.po_url && 
                                                <Grid item xs={12} lg={6}>
                                                    <AllIcon 
                                                        icon={(rowData.po_url.includes('.wav') || rowData.po_url.includes('.x-wav')) ? 'cassette-tape' : 'file-alt' }
                                                        button 
                                                        noMargin
                                                        tooltip='Purchase Order'
                                                        onClick={() => {downloadS3File(rowData.po_url)}}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>,
                                    alignment: 'center',
                                    sizeToContent: true,
                                },
                                {
                                    heading: '',
                                    field: rowData => rowData.customers.cust_account_status_id !== 1 &&
                                        <AllIcon 
                                            icon={icons.warning}
                                            color={rowData.customers.cust_account_status_id == 4 ? colors.orange : colors.red}
                                            tooltip={rowData.customers.account_status.cas_name}
                                        />,
                                    important: true,
                                    alignment: 'center'
                                },
                                {
                                    heading: 'Total',
                                    field: rowData => ( 
                                            <Tooltip 
                                                title={
                                                    <div style={{display: 'flex', width: 200}}>
                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                            <Typography variant="body2">Sub Total</Typography>
                                                            <Typography variant="body2">VAT ({rowData.vat_value.vat_value_percentage}%)</Typography>
                                                            <Typography variant="body2">Total</Typography>
                                                        </div>
                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                            <Typography variant="body2">£{rowData.order_sub_total}</Typography>
                                                            <Typography variant="body2">£{rowData.order_vat}</Typography>
                                                            <Typography variant="body2">£{rowData.order_total_incl_vat}</Typography>
                                                        </div>
                                                    </div>
                                                } 
                                                placement="left"
                                            >
                                                <span>{rowData.order_total_incl_vat}</span>
                                            </Tooltip>
                                    ),
                                    important: true,
                                    dataRef: 'order_total_incl_vat',
                                    alignment: 'right',
                                    fieldPrefix: '£',
                                    sizeToContent: true,
                                },
                                {
                                        heading: 'Jobs',
                                        field:      rowData => <>
                                            {rowData?.ej && 
                                                <Tooltip
                                                    title={<JobSnapshot jobRef={rowData?.ej?.ref} forms={rowData?.ej?.f} />}
                                                    placement="left"
                                                >
                                                    <IconButton className={this.props.classes.iconButton} onClick={() => this.props.history.push(`/engineers/jobs/view/${rowData.ej?.id}`) }>
                                                        <FALightIcon 
                                                            icon={`user-helmet-safety`}
                                                            button
                                                            noMargin
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </>,
                                        alignment: 'center',
                                        sizeToContent: true,
                                        important: false
                                    },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Sticky Notes',                          icon: icons.stickyNote,                        onClick: () => this.handleShowStickyNote(rowData.order_id), hide:rowData.sticky_notes.length === 0, color: _.filter(rowData.sticky_notes, i =>!i.sn_completed).length > 0 && colors.yellow },
                                            {name: 'View',                                  icon: 'search',                                link: '/sales/order/view/' + rowData.order_id},
                                            ...(
                                                rowData.CRLocked ?
                                                [
                                                    {name: 'Repair Order',   icon: icons.repair, link: `/returns/customerReturn/repair/${rowData.quotation.repair.rog_id}`},
                                                    {name: 'locked',   icon: null,   onClick: () => {}},
                                                ] :
                                                [
                                                    {name: rowData.locked ? 'locked' : 'Approve',   icon: rowData.locked ? null : 'check',         onClick: () => !rowData.locked && this.handleApproveOrder(rowData)},
                                                    {name: rowData.locked ? 'locked' : 'Decline',   icon: rowData.locked ? icons.lock : 'times',   onClick: () => !rowData.locked && this.handleDeclineOrder(rowData)},
                                                ]
                                            ),
                                            {
                                                type: 'actionMenu',
                                                rows: [
                                                    {
                                                        icon: icons.repeat,
                                                        label: "Repeat Order",
                                                        onClick:() => this.props.history.push(`/sales/order/add/repeat/${rowData.order_id}`),
                                                        display: true,
                                                        disabled: !access.repeatOrder
                                                    },
                                                    {
                                                        icon: "download",
                                                        label: "Download",
                                                        onClick: () => this.handleDownloadOpen(rowData.order_id),
                                                        display: true
                                                    },
                                                    {
                                                        icon: "envelope",
                                                        label: "Send Order to Customer",
                                                        onClick: () => this.handleEmailOpen(rowData.order_id),
                                                        display: true
                                                    },
                                                    {
                                                        icon: "file-search",
                                                        label: "View this Customers Orders",
                                                        onClick: () => this.props.history.push(`/customers/view/${rowData.order_cust_id}/Orders`),
                                                        display: true,
                                                        disabled: !access.customerOrderSearch
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
                <Dialog
                    open={this.state.statusWarningOpen}
                    onClose={this.handleStatusWarningClose} 
                    scroll="body"
                    maxWidth="sm"
                >
                    <DialogTitle style={{backgroundColor: colors.yellow,  fontWeight: 'bold'}}>
                        <FALightIcon icon='exclamation-triangle' noMargin /> Customer Not Verified 
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText component="div">
                            This customer has not been verified. Please follow these steps: <br /><br />
                            1) Ensure all details of this customer are correct <br />
                            2) If you changed any details on the customer, <b>amend this order</b> <br />
                            3) Update the customers status to 'Active - Verified'
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleStatusWarningClose} variant="outlined" color="default">Close</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deploySnackBar:     (variant, msg)                          => dispatch(deploySnackBar(variant, msg)),
    deployDialog:       (content, title, variant, size = 'lg')  => dispatch(deployDialog(content, title, variant, size)),
    clearPersistence:   (key)                                   => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                            => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderApproval));
