//AddFilesDialog
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MAX_UPLOAD_LIMIT_SIZE } from 'Constants';
import API from 'API';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import Button from '@material-ui/core/Button';


import { closeDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

export default function VehicleDocumentForm(props) {

    const { vehicleId, callback, type='' } = props;

    const dispatch = useDispatch();
    const initialState = {
        formErrors: {},
        formData: {
            vehicleId:       '',
            fileType: type,
            file:       '',
        },
        isLoading: false,
    }
    let [state, setState] = useState({ ...initialState })
    const { formErrors, formData, formData: { fileType, file }, isLoading } = state;

    const handleSelectChange = fieldName => selectedValue => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [fieldName]: selectedValue && selectedValue.value
            }
        }));
    }
    const clearFile = () => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                file: initialState.formData.file
            }
        }));
    }
    const handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: file
            }
        }));
    }
    const handleFilesClose = () => dispatch(closeDialog());

    const handleSubmit = () => {
        setState(state => ({
            ...state,
            isLoading: true
        }));
        let newFormData = new FormData();
        Object.keys(formData).forEach(key => {
            if (key === 'file') {
                newFormData.append('file[]', formData[key]);
            } else {
                newFormData.append(key, formData[key]);
            }
        });
        API.post(`/vehicles/${vehicleId}/documents`, newFormData)
        .then(result => {
            if (result.data.errors) {
                setState(state => ({
                    ...state,
                    formErrors: formatValidationErrors(result.data.errors),
                    isLoading: false
                }));
            } else if (result.data.success) {
                handleFilesClose();
                dispatch(deploySnackBar('success', 'You have successfully uploaded a file'));
                if (callback) callback();
            }
        });
    }

    const getOptions = () => {
        return [
            { value: 'Contract Hire Agreement',     label: 'Contract Hire Agreement' },
            { value: 'Proposal Form',               label: 'Proposal Form' },
            { value: 'V5 Registration Document',    label: 'V5 Registration Document' },
            { value: 'Delivery Note',               label: 'Delivery Note' },
            { value: 'Returned Vehicle Note',       label: 'Returned Vehicle Note' },
        ];
    }

    return (
        <>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <>
                    <FormControl error={formErrors && formErrors['fileType'] && true} fullWidth margin="normal">
                        <AutoCompleteSelect
                            options={getOptions()}
                            label='Document Type *'
                            value={fileType}
                            onChange={handleSelectChange('fileType')}
                            error={formErrors && formErrors['fileType'] && true}
                            errorText={formErrors && formErrors['fileType'] && formErrors['fileType']}
                        />
                    </FormControl>
                    <DragFileInput
                        id="file"
                        name="file"
                        label='File *'
                        file={file}
                        errorText={formErrors && formErrors['file']}
                        onChange={handleFileChange}
                        cancelOnClick={clearFile}
                        emptyText='No file selected'
                    />
                    <div className="buttonRow">
                        <Typography variant="subtitle2" style={{ flex: 1 }}>
                            <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                        </Typography>
                        <Button onClick={handleFilesClose} variant="outlined" color="default">Cancel</Button>
                        <Button onClick={handleSubmit} color="primary" autoFocus variant="contained">Upload</Button>
                    </div>
                </>
            )}
        </>
    );
}