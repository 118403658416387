import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { useKeyPress } from "Functions/MiscFunctions";

const WildixDialler = ({ handleTogglePhoneBook, handleSubmit }) => {
    const [to, setTo] = useState(""),
        [err, setError] = useState(false);

    const keyboardSubmit = useKeyPress("Enter");

    const handleDial = () => {
        const number = to.replace(/\s/g, "");

        setError(false);

        const phoneRegExp = new RegExp(/^(\+\d{10,15})*$/),
            numberRegExp = new RegExp(/^((\+\d+)|(#|\*|\d)+)$/);

        if (!number.match(numberRegExp) && !number.match(phoneRegExp)) {
            setError(true);
            return false;
        }

        handleSubmit(number);

        setTo("");
    };

    useEffect(() => {
        if (keyboardSubmit) {
            handleDial();
        }
    }, [keyboardSubmit]); /* eslint-disable-line */

    return (
        <TextField
            value={to}
            onChange={(e) => setTo(e.target.value)}
            margin="none"
            fullWidth
            error={err}
            placeholder="Enter number to dial"
            helperText={err && "Please enter a valid phone number"}
            variant="outlined"
            InputProps={{
                endAdornment: (
                    <>
                        <InputAdornment position="end">
                            <Tooltip title="Dial">
                                <IconButton onClick={handleDial} size="small">
                                    <FAIcon icon="circle-phone" type={"solid"} className={`p-05 textSuccess`} button noMargin size={32.5} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Colleagues">
                                <IconButton onClick={handleTogglePhoneBook} size="small">
                                    <FAIcon icon="address-book" type="thin" className="p-1" button noMargin size={24} />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    </>
                ),
            }}
        />
    );
};

export default WildixDialler;
