import React, { Component } from 'react';
import _                    from 'lodash';

import AllIcon     from 'Components/Common/Icons/AllIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import { clenyDate } from 'Helpers/TimeHelper';
import { colors }    from 'Helpers/ColourHelper';

import { Grid, Typography, Card, CardHeader, Avatar, CardContent } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

function InfoBox({heading, value, icon}) {
    return (
        <Grid item xs={3} lg={3}>
            <PaddedPaper style={{textAlign:'center'}}>
                <AllIcon icon={icon} size='xlarge' />
                <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>{heading}</Typography>
                <Typography variant="h4" style={{textAlign:'center'}}>{value}</Typography>
            </PaddedPaper>
        </Grid>
    )
}
class WorksOrderNotes extends Component {
    constructor(props) {
        super(props);
    }

    render(){

        const { classes, worksOrder } = this.props;

        return (
            <Grid container spacing={2}>
                {worksOrder.wo_important_note &&
                    <Grid item xs={12} lg={12} key={-1}>
                        <Card className={classes.paperGrey} style={{backgroundColor : colors.importantNote, color: colors.black}}>
                            <CardHeader
                                title={'Important Information'}
                                subheader={`${worksOrder.wo_important_note_staff} - ${clenyDate(worksOrder.wo_important_note_datetime)}`}
                            />
                            <CardContent>
                                <Typography component="p">
                                    {worksOrder.wo_important_note.split('\n').map((item, key) => <span key={key}>{item}<br/></span>)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                }
                {_.map(worksOrder.notes, (note, idx) => 
                    <Grid item xs={12} lg={12} key={idx}>
                        <Card className={classes.paperGrey} style={{backgroundColor: note.won_stage === "Cancelled" && colors.importantInfo }}>
                            <CardHeader
                                avatar={<Avatar alt={`${note.staff.staff_first_name} ${note.staff.staff_last_name}`} src={note.staff.staff_profile_photo} />}
                                title={note.won_staff}
                                subheader={`${clenyDate(note.won_datetime)} ${note.won_stage === "Cancelled" ? ' - Cancelled' : ''}`}
                            />
                            <CardContent>
                                <Typography component="p">
                                    {note?.won_note.split('\n').map((item, key) => <span key={key}>{item}<br/></span>)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        )
    }
}

const styles = theme => ({
    paperGrey : {
        padding : theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding : theme.spacing(3),
        },
        backgroundColor :'#f8f9fa'
    },
});

export default withStyles(styles)(WorksOrderNotes);