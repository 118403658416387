import React from 'react';
import moment from 'moment';
import API from 'API';

import {
    Grid,
    Typography,
    Link,
    Tooltip
} from '@material-ui/core';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { toggleDialog } from 'Functions/MiscFunctions';
import { handleDateChange } from 'Functions/FormFunctions';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import VehicleBreakdownForm from 'Components/Vehicles/Breakdown/VehicleBreakdownForm';
import VehicleInsuranceForm from 'Components/Vehicles/Insurance/VehicleInsuranceForm';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    linkCard: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
});

const initialState = () => ({
    vehicle: {},
    isLoading: true,
    dialog: {
        breakdown: false,
        breakdownId: 0,
        insurance: false,
        insuranceId: 0,
    },
    access: {
        viewInsurance: false,
        viewBreakdown: false,
    },
})

class VehicleInsurance extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.toggleDialog = toggleDialog.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
    }

    componentDidMount() {
        const { vehicle } = this.props;
        this.setState({
            ...this.state,
            vehicle,
            isLoading: false
        }, () => this.getAccess());
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/view-vehicle-insurance'),
            API.get('/staff/my/access/check/view-vehicle-breakdown')
        ])
        .then(([insRes, brkRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    viewInsurance: (insRes.data && insRes.data.has_access) || false,
                    viewBreakdown: (brkRes.data && brkRes.data.has_access) || false
                }
            });
        }) 
    }

    openDialog = (dialogName, dialogId, id = 0) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                [dialogName]: true,
                [dialogId]: id
            },
        });
    }

    render() {

        const { vehicle, isLoading, dialog, access } = this.state;
        const { classes } = this.props;

        return (
            <>
                <PaddedPaper>
                    {(isLoading && (
                        <LoadingCircle />
                    )) || (
                        <Grid container item spacing={3}>                        
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    <b>Insurance Policies</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {vehicle?.insurances?.length > 0 ?
                                    <>
                                    <Grid container item spacing={3}> 
                                        {vehicle.insurances.map((insurance, index) => (
                                            <Grid item xs={12} md={4} key={index}>
                                                <Link className={access?.viewInsurance ? classes.linkCard : null } style={{textDecoration: 'none'}} onClick={access?.viewInsurance ? () => this.openDialog('insurance', 'insuranceId', insurance?.id ) : null }>
                                                    <PaddedPaper>
                                                        <Typography variant="body1" paragraph>
                                                            <AllIcon icon="certificate" heavy /><b>{insurance?.company}</b>
                                                        </Typography>
                                                        <Grid container item spacing={3}>
                                                            <Grid item xs={6}>
                                                                <Tooltip title="Policy Number"><span><AllIcon icon="hashtag" size={15} noMargin /> {insurance?.policyNumber}</span></Tooltip>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                
                                                            </Grid>                        
                                                            <Grid item xs={6}>
                                                                <Typography variant="caption">
                                                                    Effective Date:
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {moment(insurance?.startDate).format('D MMMM YYYY') }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography variant="caption">
                                                                    End Date:
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {moment(insurance?.endDate).format('D MMMM YYYY')}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </PaddedPaper>
                                                </Link>
                                            </Grid>
                                            
                                        ))}
                                    </Grid>
                                    </>
                                : 
                                    <Typography variant="body1">
                                        <em>No insurance policies</em>
                                    </Typography> }
                            </Grid>
                            <Grid item xs={12}>
                                <br></br>
                                <Typography variant="h6">
                                    <b>Breakdown Policies</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {vehicle?.breakdown?.length > 0 ?
                                    <>
                                    <Grid container item spacing={3}> 
                                        {vehicle.breakdown.map((br, index) => (
                                            <Grid item xs={12} md={4} key={index}>
                                                <Link className={access?.viewBreakdown ? classes.linkCard : null } style={{textDecoration: 'none'}} onClick={access?.viewInsurance ? () => this.openDialog('breakdown', 'breakdownId', br?.id ) : null }>
                                                    <PaddedPaper>
                                                        <Typography variant="body1" paragraph>
                                                            <AllIcon icon="certificate" heavy /><b>{br?.supplier?.name}</b>
                                                        </Typography>
                                                        <Typography variant="body1" paragraph>
                                                            {br?.cover && br?.cover.split('\n').map((item, key) => {
                                                                return <span key={key}>{item}<br/></span>
                                                            })}
                                                        </Typography>
                                                        <Grid container item spacing={3}>
                                                            <Grid item xs={6}>
                                                                <Tooltip title="Policy Number"><span><AllIcon icon="hashtag" size={15} noMargin /> {br?.policyNumber}</span></Tooltip>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Tooltip title="Emergency Contact"><span><AllIcon icon="phone" size={15} noMargin /> {br?.emergencyContact}</span></Tooltip>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography variant="caption">
                                                                    Effective Date:
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {moment(br?.startDate).format('D MMMM YYYY') }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography variant="caption">
                                                                    End Date:
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {moment(br?.endDate).format('D MMMM YYYY')}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </PaddedPaper>
                                                </Link>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    </>
                                : 
                                    <Typography variant="body1">
                                        <em>No breakdown policies</em>
                                    </Typography> }
                                
                            </Grid>
                        </Grid>
                    )}
                </PaddedPaper>

                <SmallFormDialog
                    open={dialog?.breakdown}
                    onClose={() => this.toggleDialog('breakdown')}
                    content={
                        <VehicleBreakdownForm 
                            onClose={() => this.toggleDialog('breakdown', true)}
                            breakdownId={dialog?.breakdownId}
                            readOnly={true}
                        />
                    }
                    maxWidth='md'
                />
                <SmallFormDialog
                    open={dialog?.insurance}
                    onClose={() => this.toggleDialog('insurance')}
                    content={
                        <VehicleInsuranceForm 
                            onClose={() => this.toggleDialog('insurance', true)}
                            insuranceId={dialog?.insuranceId}
                            readOnly={true}
                        />
                    }
                    maxWidth='lg'
                />
            </>
        )
    }
}

export default (withStyles(styles)(VehicleInsurance));