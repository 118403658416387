import React, { useCallback, useEffect, useState } from 'react';
import API                                         from 'API';
import { WEB_EMAIL }                               from 'Constants';
import { assign, filter, find, map }               from 'lodash';
import { useDispatch }                             from 'react-redux';
import { Redirect }                                from 'react-router-dom';

import { Button, FormControl, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog }        from 'Actions/Dialog/Dialog';
import { deploySnackBar }     from 'Actions/SnackBar/SnackBar';

import Textarea                from 'Components/Common/Inputs/Textarea';
import LoadingCircle           from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const EmailPaymentDialog = ({customerId, customerContactId, type, id, redirectPath, callback, emailAttachment, deliveryAddressId = 0, paymentTerm = '', amountOutstanding = 0, paymentTermId}) => {

    const dispatch = useDispatch();

    const initialState = {
        formErrors: {},
        formData: {
            type: '',
            id: '',
            emailTemplate: '',
            emailTo: '',
            emailName: '',
            emailAddress: '',
            emailCc: [],
            staff: [],
            emailText: '',
            paymentAmount: amountOutstanding,
            // Address details for PayPal shipping
            addressLineOne: '',
            addressLineTwo: '',
            addressPostcode: '',
            addressTown: '',
            addressCounty: '',
            addressCountryCode: '',
            addressCountry: ''
        },
        emailTemplateList: [],
        customerContactList: [],
        staffList: [],
        countryList: [],
        isLoading: true,
        redirect: false,
        paymentTerm: paymentTerm
    };

    let [state, setState] = useState({...initialState});
    
    const {formErrors, formData, 
        formData:{emailTemplate, emailTo, emailCc, staff, emailText, paymentAmount, addressLineOne, addressLineTwo, addressPostcode, addressTown, addressCounty, addressCountryCode, addressCountry}, 
        emailTemplateList, customerContactList, staffList, isLoading, redirect, countryList} = state;
        
    const handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleSelectChange = fieldName => selectedOption => {
        setState(state => ({
            ...state,
            formData:{
                ...formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }));
    }

    const handleToDecimalPlace = (decimal) => e => {
        const {name, value} = e.target;
        let newVal = parseFloat(value).toFixed(decimal);

        setState(state => ({
            ...state,
            formData:{
                ...formData,
                [name]: newVal
            }
        }));
    }

    const handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [fieldName]: values
            }
        }));
    }

    const handleEmailDialogClose = () => {
        if(redirectPath) setState(state => ({...state, redirect: true}));
        dispatch(closeDialog());
    }

    const handleSubmit = () => dispatch(deployConfirmation('Are you sure you want to send email to customer?', 'Send Email?', submit));

    const submit = () => {
        if(emailAttachment){
            setState(state => ({...state, isLoading: true}));
            let apiURL = '';

            switch(emailAttachment) {
                case 'invoice':
                    apiURL = `/sales/orders/invoice/${id}/emailPayment`;
                break;
                case 'order':
                    apiURL = `/sales/orders/${id}/emailPayment`;
                break;
            }
            API.post(apiURL, formData)
            .then(result => {
                if(result.data.errors) {
                    setState(state => ({
                        ...state, 
                        formErrors: formatValidationErrors(result.data.errors), 
                        isLoading: false
                    }));
                } else if(result.data.success) {
                    handleEmailSuccess();
                }
            });
        }
    }

    const handleEmailSuccess = () => {
        if(redirectPath) setState(state => ({...state, redirect: true}));
        if(callback) callback();
        handleEmailDialogClose();
        dispatch(deploySnackBar('success', 'You have successfully sent the email'));
    }

    // re-enable all when emailTo is cleared
    const resetFields = useCallback(() => {
        customerContactList.forEach(el => el.disabled = false);
        setState(state => ({...state, customerContactList}));

    }, [customerContactList])

    const setFields = useCallback(() => {
        let customer = find(customerContactList, { 'value': emailTo});
        let newContactList = [...customerContactList];
        let newEmailCcList = [...emailCc];

        for(let i in newContactList) {
            if(newContactList[i].value === emailTo) {
                newContactList[i].disabled = true;
                if(emailCc.includes(emailTo)) {
                    newEmailCcList = newEmailCcList.filter(id => id !== emailTo);
                }
            } else {
                newContactList[i].disabled = false;
            }
        }

        setState(state => ({
            ...state, 
            customerContactList: newContactList,
            formData: {
                ...state.formData,
                emailName: customer.name,
                emailAddress: customer.email,
                emailCc: newEmailCcList
            }
        }));

    }, [customerContactList, emailTo, emailCc])

    useEffect(() => {
        let country = find(countryList, { 'value': addressCountry});
        setState(state => ({
            ...state,
            formData: {
                ...state.formData,
                addressCountryCode: country?.code
            }
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressCountry]);

    // Handle email formData prefill on emailTo change (required for send email on submit) and emailcc disabling
    useEffect(() => {
        if(emailTo && emailTo !== '') {
            setFields();
        } else {
            resetFields();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailTo]);

    // Handle prefill email text from on email template change 
    useEffect(() => {
        if(emailTemplateList && emailTemplateList.length > 0 && emailTemplate !== '') {
            let selectedEmailTemplate = find(emailTemplateList, { 'value': emailTemplate });

            setState(state => ({
                ...state,
                formData: {
                    ...state.formData,
                    emailText: selectedEmailTemplate ? selectedEmailTemplate.text : ''
                }
            }));
        }

    }, [emailTemplate, emailTemplateList]);
    
    // On load, get email templates, activ staff and customer contacts, then format for autocomplete select
    useEffect(() => {

        let promiseArr = [
            API.get('/sales/emailTemplates'),
            API.get(`/customers/${customerId}/contacts`),
            API.get('/staff/all'),
            API.get('/misc/countries/all'),
        ];

        Promise.all(promiseArr)
        .then(([emailRes, contactRes, staffRes, countryRes]) => {
            let emailTemplateList = map(emailRes.data, el => {
                return assign({
                    value: el.set_id,
                    label: el.set_name,
                    text: el.set_text
                });
            });

            let customerContactList = map(filter(contactRes.data, i => { return i.contact_email }), el => {
                return assign({
                    value: el.contact_id,
                    label: el.contact_name + ' (' + el.contact_email + ')',
                    name: el.contact_name,
                    email: el.contact_email
                });
            });

            let staffList = map(staffRes.data, el => {
                return assign({
                    value: el.staff_id,
                    label: el.staff_first_name + ' ' + el.staff_last_name,
                });
            });

            let countryList = map(countryRes.data, el => {
                return assign({
                    value: el.country_id,
                    label: el.country_name,
                    code: el.country_code,
                });
            });

            let emailTemplateId = null;
            switch(emailAttachment) {
                case 'invoice':
                    switch(type) {
                        case 'stripe':
                            emailTemplateId = 15;
                        break;
                        case 'cheque':
                            emailTemplateId = 16;
                        break;
                        case 'transfer':
                            emailTemplateId = 17;
                        break;
                        case 'cash':
                            emailTemplateId = 18;
                        break;
                        case 'paypal':
                            emailTemplateId = 19
                        break;
                        default:
                            emailTemplateId = null;
                    }
                break;
                case 'order':
                    switch(type) {
                        case 'stripe':
                            emailTemplateId = 6;
                        break;
                        case 'cheque':
                            emailTemplateId = 7;
                        break;
                        case 'transfer':
                            emailTemplateId = 8;
                        break;
                        case 'cash':
                            emailTemplateId = 9;
                        break;
                        case 'paypal':
                            emailTemplateId = 12
                        break;
                        default:
                            emailTemplateId = null;
                    }
                break;
            }

            setState(state => ({
                ...state, 
                formData: {
                    ...state.formData,
                    type,
                    id,
                    emailTemplate: emailTemplateId,
                    emailTo: customerContactId && parseInt(customerContactId)
                },
                emailTemplateList, 
                customerContactList,
                staffList,
                countryList,
                isLoading: false
            }));
            // If PayPal then get the delivery address we will use to populate PayPal shipping
            if(type === 'paypal'){
                API.get(`/customers/addresses/${deliveryAddressId}`)
                .then((addressRes => {
                    setState(state => ({
                        ...state, 
                        formData: {
                            ...state.formData,
                            addressLineOne: !addressRes?.data?.address_line_three ? addressRes?.data?.address_line_one : addressRes?.data?.address_line_two,
                            addressLineTwo: !addressRes?.data?.address_line_three ? addressRes?.data?.address_line_two : addressRes?.data?.address_line_three,
                            addressPostcode: addressRes?.data?.address_postcode,
                            addressTown: addressRes?.data?.address_town,
                            addressCounty: addressRes?.data?.county?.county_name,
                            addressCountryCode: addressRes?.data?.country?.country_code,
                            addressCountry: addressRes.data?.country?.country_id,
                        }
                    }));
                }))
            }
        });

    }, [customerId, customerContactId, id, type]);

    if(redirect && redirectPath) {
        return (
            <Redirect to={redirectPath} />
        );
    }

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <React.Fragment>
                    <br></br>
                    {formErrors && formErrors.generic && (
                        <React.Fragment>
                            <Typography component={"div"} style={{color: colors.red}}>
                                {formErrors.generic}
                            </Typography>
                        </React.Fragment>
                    )}
                    <>
                    <Grid container item spacing={3}>
                        <Grid item lg={5}>
                            <TextField
                                name="paymentAmount"
                                label="Payment Amount Required"
                                value={paymentAmount}
                                onChange={handleChange}
                                onBlur={handleToDecimalPlace(2)}
                                margin="normal"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                                type="number"
                                fullWidth
                                error={formErrors && formErrors['paymentAmount'] && true}
                                helperText={formErrors && formErrors['paymentAmount']}
                                disabled={parseInt(paymentTermId) === 5}
                            />
                            <TextField
                                label={emailAttachment === 'order' ? "Order Total": "Amount Outstanding"}
                                value={amountOutstanding}
                                margin="normal"
                                fullWidth
                                disabled
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                    className: 'disabledText'
                                }}
                            />
                            <TextField
                                label="Payment Term"
                                value={paymentTerm}
                                margin="normal"
                                fullWidth
                                disabled
                                InputProps={{
                                    className: 'disabledText'
                                }}
                            />
                            {type === 'paypal' && (
                                <>
                                <br /><br />
                                <Typography variant="body1">
                                    <strong>PayPal Shipping Address</strong>
                                </Typography>
                                <TextField
                                    id="addressLineOne"
                                    name="addressLineOne"
                                    label="Address Line One *"
                                    value={addressLineOne}
                                    error={formErrors && formErrors['addressLineOne'] && true}
                                    helperText={formErrors && formErrors['addressLineOne']}
                                    onChange={handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    id="addressLineTwo"
                                    name="addressLineTwo"
                                    label="Address Line Two"
                                    value={addressLineTwo}
                                    error={formErrors && formErrors['addressLineTwo'] && true}
                                    helperText={formErrors && formErrors['addressLineTwo']}
                                    onChange={handleChange}
                                    margin="normal"
                                    fullWidth
                                /> 
                                <Grid container >
                                    <Grid container item spacing={3}>
                                        <Grid item lg={6}>
                                            <TextField
                                                id="addressTown"
                                                name="addressTown"
                                                label="Town / City *"
                                                value={addressTown}
                                                error={formErrors && formErrors['addressTown'] && true}
                                                helperText={formErrors && formErrors['addressTown']}
                                                onChange={handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item lg={6}>
                                            <TextField
                                                id="addressCounty"
                                                name="addressCounty"
                                                label="County"
                                                value={addressCounty}
                                                error={formErrors && formErrors['addressCounty'] && true}
                                                helperText={formErrors && formErrors['addressCounty']}
                                                onChange={handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={3}>
                                        <Grid item lg={6}>
                                            <TextField
                                                id="addressPostcode"
                                                name="addressPostcode"
                                                label="Postocde"
                                                value={addressPostcode}
                                                error={formErrors && formErrors['addressPostcode'] && true}
                                                helperText={formErrors && formErrors['addressPostcode']}
                                                onChange={handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item lg={6}>
                                            <TextField
                                                id="addressCountryCode"
                                                name="addressCountryCode"
                                                label="Country Code *"
                                                value={addressCountryCode}
                                                error={formErrors && formErrors['addressCountryCode'] && true}
                                                helperText={formErrors && formErrors['addressCountryCode']}
                                                margin="normal"
                                                fullWidth
                                                disabled
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{
                                                    className: 'disabledText'
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={countryList} 
                                            label='Country'
                                            onChange={handleSelectChange('addressCountry')}
                                            error={formErrors && formErrors['addressCountry'] && true}
                                            errorText={formErrors && formErrors['addressCountry']}
                                            value={addressCountry}
                                        />
                                    </FormControl>
                                </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid item lg={7}>
                            <Typography variant="h6" gutterBottom>
                                Email Preview
                            </Typography>
                            <Typography variant="caption" gutterBottom>
                                From: Clenaware Systems {`<${WEB_EMAIL}>`}
                            </Typography>
                            <form noValidate autoComplete="off">
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect
                                        id="emailTo"
                                        name="emailTo"
                                        options={customerContactList} 
                                        label='To *'
                                        value={emailTo}
                                        onChange={handleSelectChange('emailTo')}
                                        error={formErrors && formErrors['emailTo'] && true}
                                        errorText={formErrors && formErrors['emailTo'] && formErrors['emailTo']}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteMultiSelect 
                                        options={customerContactList} 
                                        label='Email Cc'
                                        value={emailCc}
                                        onChange={handleMultiSelectChange('emailCc')}
                                        error={formErrors && formErrors['emailCc'] && true}
                                        errorText={formErrors && formErrors['emailCc']}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteMultiSelect 
                                        options={staffList} 
                                        label='Staff'
                                        value={staff}
                                        onChange={handleMultiSelectChange('staff')}
                                        error={formErrors && formErrors['staff'] && true}
                                        errorText={formErrors && formErrors['staff']}
                                    />
                                </FormControl>
                                <FormControl error={formErrors && formErrors['emailTemplate'] && true} fullWidth margin="normal">
                                    <AutoCompleteSelect
                                        options={emailTemplateList} 
                                        label='Email Template'
                                        value={emailTemplate}
                                        onChange={handleSelectChange('emailTemplate')}
                                        error={formErrors && formErrors['emailTemplate'] && true}
                                        errorText={formErrors && formErrors['emailTemplate'] && formErrors['emailTemplate']}
                                    />
                                </FormControl>
                                <Textarea
                                    id="emailText"
                                    name="emailText"
                                    label="Email Text *"
                                    value={emailText}
                                    rows={5}
                                    error={formErrors && formErrors['emailText']}
                                    onChange={handleChange}
                                />
                            </form>
                        </Grid>
                    </Grid>
                    </>
                    <div className="buttonRow">
                        <Button 
                            onClick={handleEmailDialogClose} 
                            variant="contained" 
                            color="secondary"
                        >
                            Don't Send
                        </Button>
                        <Button 
                            onClick={handleSubmit} 
                            autoFocus 
                            variant="contained" 
                            color="primary" 
                            style={{backgroundColor:'#5da14d', color:'white'}}
                        >
                            Send
                        </Button>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default EmailPaymentDialog;