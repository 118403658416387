import { combineReducers }      from 'redux';

import AuthReducer              from './Auth/AuthReducer';
import CiServiceWorkerReducer   from './CiServiceWorker/CiServiceWorkerReducer.js';
import LoadingReducer           from './Loading/LoadingReducer';
import NotificationsReducer     from './Notifications/NotificationsReducer';
import SnackBarReducer          from './SnackBar/SnackBarReducer.js';
import UIReducer                from './UI/UIReducer.js';
import DialogReducer            from './Dialog/DialogReducer.js';
import ConfirmationReducer      from './Confirmation/ConfirmationReducer.js';
import StatePersistenceReducer  from './StatePersistence/StatePersistenceReducer';
import popNotificationReducer   from './PopNotification/PopNotification.js';
import PrintReducer             from './Print/PrintReducer.js';
import keyDownReducer           from './KeyDown/KeyDownReducer.js';

import SoundReducer             from 'Reducers/Sounds/SoundReducer';

const rootReducer = combineReducers({
    staffAuth:          AuthReducer,
    loading:            LoadingReducer,
    ciSw:               CiServiceWorkerReducer,
    notifications:      NotificationsReducer,
    snackbar:           SnackBarReducer,
    sound:              SoundReducer,
    ui:                 UIReducer,
    dialog:             DialogReducer,
    confirmation:       ConfirmationReducer,
    statePersistence:   StatePersistenceReducer,
    popNotification:    popNotificationReducer,
    print:              PrintReducer,
    keyDown:            keyDownReducer,
});

export default rootReducer;