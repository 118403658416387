import React  from 'react';
import API    from 'API';
import _      from 'lodash';
import moment from 'moment';

import { Button, Dialog, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, Typography, ListItemSecondaryAction, DialogActions } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';
import Textarea      from 'Components/Common/Inputs/Textarea';

import ScanDialog from './ScanDialog';

import { colors } from 'Helpers/ColourHelper';

import { addKeyDown, removeKeyDown, setKeyDown } from 'Actions/KeyDown/KeyDown';
import { connect } from 'react-redux';

const initialState = {
	isLoading: true,
    movement: {},
    page:                   0,
	perPage:                3,
    searchResults:          [],
    parts:                  [],
    reasons:                [],
    startPick:              false,
    notes:                  '',
    saveLoading:            false,
}

class StockCorrection  extends React.Component {
	constructor(props) {
        super(props);
		this.state = initialState;
	}

	componentDidMount = () => {
        this.getMovement();
    }

	componentWillUnmount = () => {
		this.props.removeKeyDown()
	}

    getMovement = () => {
        Promise.all([
            API.get(`/stock/correction/${this.props.match.params.id}`),
            API.get('/parts/all',{params:{withDefaultImg:true, isStockAble:true, withStock:true}})
        ])
        .then(([parts, allParts]) => {
            let searchResults = _.orderBy(parts.data.details, i => i.part.part_number);
            this.setState({
                movement:       parts.data,
                isLoading:      false,
                parts:          _.take(searchResults, this.state.perPage),
                allParts:       _.filter(allParts.data, i => !_.find(searchResults, j => j.part.part_id === i.part_id)),
                searchResults,
                notes:          parts.data.sc_notes,
            }, () => {
                this.props.addKeyDown(this._handleKeyDown)
            });
        })
    }

	_handleKeyDown = (e) => {
		if (e.keyCode === 0 || e.keyCode === 192) return;
        e.preventDefault(); 
		switch(e.key) {
			case 'F1':
                if (this.state.notesOpen){
                    this.closeNotes();
                } else {
                    this.state.page > 0 && this.prevPage();
                }
				break;
			case 'F2':
                if (this.state.notesOpen){
                    this.saveNotes();
                } else {
				    ((this.state.page + 1) * this.state.perPage) < this.state.searchResults.length && this.nextPage();
                }
				break;
            case 'F3':
                !this.state.notesOpen && this.handleStartPick();
                break;
            default: break;
		}
	}

	nextPage = () => {
		const { page, perPage, searchResults } = this.state;
		const newPage = page + 1;
		const newOrders = _.take(_.slice(searchResults, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			parts: newOrders
		});
	}

	prevPage = () => {
		const { page, perPage, searchResults } = this.state;
		const newPage = page - 1;
		const newOrders = _.take(_.slice(searchResults, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			parts: newOrders
		});
	}


    handleStartPick = () => {
        this.props.removeKeyDown();
        this.setState({
            startPick: true
        })
    }

    handleEndPick = () => {
        this.setState({
            startPick: false,
            isLoading: true
        }, this.getMovement)
    }

  	render() {

		const { isLoading, searchResults, parts, startPick, perPage, movement, allParts, notesOpen, saveLoading } = this.state;
		const { classes } = this.props;

		if (isLoading) return <LoadingCircle/>;

		return (
            <Grid container spacing={1} style={{height: '100%'}}> 
                <Grid item xs={12}>
					<Typography variant='h4' style={{textAlign: 'center'}}>Stock Correction</Typography>
				</Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{textAlign: 'center'}}>{movement.sc_ref}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{textAlign: 'center'}}><strong>{moment(movement?.sm_date).format('DD/MM/YYYY')}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
					<List>
						{_.map(parts, i => 
							<ListItem key={i.scd_id} className={classes.ListItem}>
								<ListItemText 
									primary={i.part.part_number} 
                                    secondary={i.part.part_description}
								/>
								<ListItemSecondaryAction
                                    
                                >
                                    {i.scd_current_stock} <br/>
                                    <span style={{
                                        color: parseFloat(i.scd_new_stock) > parseFloat(i.scd_current_stock) ? colors.green : colors.red,
                                        fontWeight: 'bold'
                                    }}>{i.scd_new_stock}</span>
								</ListItemSecondaryAction>
							</ListItem>
						)}
					</List>
                </Grid>
                <Grid item xs={12} style={{marginTop: 'auto'}}>
                    <Grid container spacing={1} style={{justifyContent: 'space-between'}}> 
                        <Grid item xs={3} style={{marginRight: 'auto'}}>
                            { searchResults.length > perPage && this.state.page > 0 && 
                                    <Button variant='contained' color='primary'
                                        onClick={this.prevPage} fullWidth
                                    >[F1] Prev</Button>
                                }
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant='contained' color='primary' onClick={this.handleStartPick} fullWidth>[F3] {this.scanData} <br/> Scan Parts </Button>
                        </Grid>
                        <Grid item xs={3} style={{marginLeft: 'auto'}}>
                            { searchResults.length > perPage && ((this.state.page + 1) * this.state.perPage) < searchResults.length &&
                                <Button variant='contained' color='primary'
                                    onClick={this.nextPage} fullWidth
                                >[F2] Next</Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {notesOpen && 
                    <Dialog
                        open={notesOpen}
                        onClose={this.closeNotes}
                        fullScreen
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title" style={{backgroundColor: colors.blue,  fontWeight: 'bold', color: colors.white}}>Notes</DialogTitle>
                        <DialogContent>
                            {saveLoading ?
                                <LoadingCircle/> :
                                <Textarea
                                    value={this.state.notes}
                                    onChange={e => this.setState({notes: e.target.value})}
                                    style={{width: '100%', height: '100%'}}
                                    rows={6}
                                />
                        }
                        </DialogContent>
                        {!saveLoading &&
                            <DialogActions>
                                <Button onClick={this.closeNotes} variant='outlined'>
                                    Close
                                </Button>
                                <Button onClick={this.saveNotes} color="primary" variant='contained'>
                                    Save 
                                </Button>
                            </DialogActions>
                        }
                    </Dialog>
                }
                {startPick &&
                    <Dialog 
                        open={startPick} 
                        onClose={this.handleEndPick} 
                        fullScreen
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title" style={{backgroundColor: colors.blue,  fontWeight: 'bold'}}> 
                            <Grid container style={{
                                alignContent:   'center',
                                justifyContent: 'space-between',
                                alignItems:     'center'
                            }}>
                                <Grid item style={{color: colors.white}}>
                                    {movement.sc_ref}
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ width:'100vw', Maxwidth: '100vw'}}>
                            <ScanDialog      
                                correction = {movement}
                                parts      = {allParts}
                                close      = {this.handleEndPick}
                            />
                        </DialogContent>
                    </Dialog>
                }
            </Grid>
		);
  	}
}

const styles = theme => ({
    ListItem: {
		backgroundColor: colors.white, 
		borderRadius: 	 5,
		marginBottom: 	 '0.75em',
		boxShadow: 	  	 '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
	}
});

const mapDespatchToProps = dispatch => ({
	addKeyDown: (func) => dispatch(addKeyDown(func,'StockCorrection')),
	setKeyDown: () => dispatch(setKeyDown('StockCorrection')),
	removeKeyDown: () => dispatch(removeKeyDown('StockCorrection'))
});

export default connect(null, mapDespatchToProps)(withStyles(styles)(StockCorrection ));