import React, { Component }  from 'react';
import _ from 'lodash';
import API from 'API';

import Button from '@material-ui/core/Button';

import LoadingCircle    from 'Components/Common/LoadingCircle/LoadingCircle';
import AllIcon          from 'Components/Common/Icons/AllIcon';

const initialState = () => ({
    partOrder:      [],
    isLoading:      true,
})

class OrderPartDetailsButton extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getPartTypeOrder();
    }

    getPartTypeOrder = () => {
        API.get('/parts/numbering/partTypes/all')
        .then(res => {
            this.setState({partOrder: _.sortBy(res.data, [ 'part_type_sort_order' ,'asc']), isLoading: false });
        })
    }

    reOrder = () => {
        let partRows = this.props.partRows;
        let sorted       = [];
        _.each(partRows, i => {
            let pt      = i.partNumber ? i.partNumber : i.part_number;
                pt      = pt.substring(3, pt.length);
                pt      = pt.replace(/[0-9]/g, '');
            let order   = _.find(this.state.partOrder, {'part_type_code': pt}) ? _.find(this.state.partOrder, {'part_type_code': pt}).part_type_sort_order : 99; 

            if (!sorted[order]) sorted[order] = [];
            sorted[order].push(i);
            sorted[order] = _.orderBy(sorted[order], [i => {
                let pt      = i.partNumber ? i.partNumber : i.part_number
                return parseInt(pt.substring(pt.length - 4));
            }], ['asc']);
        })
        this.props.callback(_.compact(_.flatten(sorted)));
    }

    render() {
        const {isLoading} = this.state;
        return (
            <>
                {isLoading ? 
                    <LoadingCircle /> : 
                    <Button onClick={this.reOrder} variant="contained" color="primary"><AllIcon icon='random' size='small' style={{color:'white'}}/>Sort</Button>
                }
            </>
        )
    }
}

export default OrderPartDetailsButton;