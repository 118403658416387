import React, { Component } from "react";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Grid, Typography, ExpansionPanelSummary, ExpansionPanel, ExpansionPanelDetails, Checkbox } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AllIcon from "Components/Common/Icons/AllIcon";

import { colors } from 'Helpers/ColourHelper';

class DraggableList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items:      this.props.items,
            dropped:    []
        };
    }

    handleOnDragEnd = result => {
        if (!result.destination) return;
    
        const items = Array.from(this.state.items);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        this.setState({items}, () => {this.props.onChange && this.props.onChange(items);});
    }

    handleChildChange = (parentId) => (items) => {
        this.setState({
            items: _.map(this.state.items, item => ({
                ...item,
                children: item.id === parentId ? items : item.children
            }))
        }, () => {this.props.onChange && this.props.onChange(items);});
    }

    handleDrop = id => {
        const { dropped } = this.state;
        if (!dropped.includes(id)) {
            this.setState({dropped: [...dropped, id]});
        } else {
            this.setState({dropped: dropped.filter(i => i !== id)});
        }
    }
    handleCheck = (id) => {
        this.setState({
            items: _.map(this.state.items, item => ({
                ...item,
                checked: item.id === id ? !item.checked : item.checked
            }))
        }, () => { this.props.onCheck && this.props.onCheck(id) })
    }

    render() {

        const { items, dropped } = this.state;

        return (
            <DragDropContext onDragEnd={this.handleOnDragEnd} key={`DragDropContext-${this.props.key || ''}`}>
                <Droppable droppableId="droppableList">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map(({id, key, primary, secondary, icon, children, actions, checked}, index) => 
                                this.props.disableDrag ?
                                    <ExpansionPanel 
                                        style={{backgroundColor: colors.white}} 
                                        onClick={(e)=>{ }} 
                                        expanded={dropped.includes(id)}
                                    >
                                        <ExpansionPanelSummary expandIcon={children && children.length > 0 && <ExpandMoreIcon style={{color: colors.black}} onClick={()=>{this.handleDrop(id) }}  />}
                                            style={{
                                                backgroundColor: colors.white,
                                                color: colors.black,
                                            }}
                                        >
                                            <Grid container spacing={1} style={{alignItems: 'center'}}>
                                                {icon && <Grid item><AllIcon icon={icon} /></Grid>}
                                                <Grid item>
                                                    <Typography component="div" color="inherit">{primary}</Typography>
                                                </Grid>
                                                <Grid item style={{marginLeft: 'auto'}}>
                                                    <Grid container style={{justifyContent: 'flex-end'}}>
                                                        {this.props.onCheck && checked !== null && _.keys(items[index]).includes('checked') &&  
                                                            <Grid item>
                                                                <Checkbox checked={checked} onChange={ ()=>{this.handleCheck(id)}}/>
                                                            </Grid>
                                                        }
                                                        {actions && actions.length > 0 && 
                                                            _.map(actions, a => 
                                                                <Grid item>
                                                                    <AllIcon icon={a.icon}  tooltip={a.name} onClick={a.onClick} noMargin />
                                                                </Grid>
                                                            )
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        {children && children.length > 0 && (
                                            <ExpansionPanelDetails>
                                                <DraggableList
                                                    items={children}
                                                    onChange={this.handleChildChange(id)}
                                                    onCheck={this.props.onCheck}
                                                />
                                            </ExpansionPanelDetails>
                                        )}
                                    </ExpansionPanel> :
                                    <Draggable key={key || id} draggableId={`draggableListItem${id}`} index={index}>
                                        {(provided) => (
                                            <div
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                style={{
                                                    ...provided.draggableProps.style,
                                                    borderBottom: `1px solid ${colors.disabled}`,
                                                }}
                                            >
                                                <ExpansionPanel 
                                                    style={{backgroundColor: colors.white}} 
                                                    onClick={(e)=>{ }} 
                                                    expanded={dropped.includes(id)}
                                                >
                                                    <ExpansionPanelSummary expandIcon={children && children.length > 0 && <ExpandMoreIcon style={{color: colors.black}} onClick={()=>{this.handleDrop(id) }}  />}
                                                        style={{
                                                            backgroundColor: colors.white,
                                                            color: colors.black,
                                                        }}
                                                    >
                                                        <Grid container spacing={1} style={{alignItems: 'center'}}>
                                                            {icon && <Grid item><AllIcon icon={icon} /></Grid>}
                                                            <Grid item>
                                                                <Typography component="div" color="inherit">{primary}</Typography>
                                                            </Grid>
                                                            <Grid item style={{marginLeft: 'auto'}}>
                                                                <Grid container style={{justifyContent: 'flex-end'}}>
                                                                    {this.props.onCheck && checked !== null && _.keys(items[index]).includes('checked') &&  
                                                                        <Grid item>
                                                                            <Checkbox checked={checked} onChange={ ()=>{this.handleCheck(id)}}/>
                                                                        </Grid>
                                                                    }
                                                                    {actions && actions.length > 0 && 
                                                                        _.map(actions, a => 
                                                                            <Grid item>
                                                                                <AllIcon icon={a.icon}  tooltip={a.name} onClick={a.onClick} noMargin />
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelSummary>
                                                    {children && children.length > 0 && (
                                                        <ExpansionPanelDetails>
                                                            <DraggableList
                                                                items={children}
                                                                onChange={this.handleChildChange(id)}
                                                                onCheck={this.props.onCheck}
                                                            />
                                                        </ExpansionPanelDetails>
                                                    )}
                                                </ExpansionPanel>
                                            </div>
                                        )}
                                    </Draggable>
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

export default DraggableList;
