//InvoiceCreditNote
import React, { Component } from 'react';
import { connect }          from 'react-redux';
import _                    from 'lodash';
import API                  from 'API';

import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployDialog }   from 'Actions/Dialog/Dialog';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import Parts     from './Sections/Parts';
import Templates from './Sections/Templates';
import Template  from './Sections/Template';
import Manual    from './Sections/Manual';

import { AppBar, Tab, Tabs, Grid } from '@material-ui/core';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

const initialState = {
    currentTab: 1,
    isLoading:  true,
    templates:  []
};

class Greenaware extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getTemplates();
    }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value,
        });
    };

    getTemplates = () => {
        API.get('/customerReturns/greenaware/templates')
        .then(res => {
            this.setState({
                templates:  res.data,
                isLoading:  false
            })
        });
    }

    render() {
        const { isLoading, currentTab, templates }  = this.state;

        if (isLoading) return (<LoadingCircle />);

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} style={{paddingBottom: 0}}>
                    <AppBar position="static" style={{backgroundColor:'white', paddingBottom: 0}}>
                        <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                            {_.map(templates, t => <Tab value={t.gt_id} label={t.gt_template_label} /> )}
                            <Tab value='Manual'    label='Manual Entry' />
                            <Tab value='parts'     label='parts' />
                            <Tab value='templates' label='templates' />
                        </Tabs>
                    </AppBar>
                </Grid>
                <Grid item xs={12} style={{paddingTop: 0}}>
                    {currentTab === 'parts'     && <Parts />}
                    {currentTab === 'Manual'    && <Manual />}
                    {currentTab === 'templates' && <Templates callBack={this.getTemplates} />}
                    {_.isInteger(currentTab)    && <PaddedPaper><Template  template={_.find(templates, {gt_id: currentTab})} /></PaddedPaper>}
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deploySnackBar:     (variant, msg)                          => dispatch(deploySnackBar(variant, msg)),
    deployDialog:       (content, title, variant, size = 'lg')  => dispatch(deployDialog(content, title, variant, size)),
})

export default connect(null, mapDispatchToProps)(Greenaware);