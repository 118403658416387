import { Box, Button, Grid, Typography } from '@material-ui/core';
import API from 'API';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import FormView from 'Components/Common/Forms/FormView/FormView';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import InfoBox from 'Components/Common/reports/InfoBox';
import { CLENAWARE_BLUE } from 'Constants';
import IconHelper from 'Helpers/IconHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import FormReportElement from './FormReportElement';

const initialState = () => ({
    isLoading: true,
    form: {},
    forms: [],
    showGroupDialog: false,
})

class FormReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getForm();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.formId !== this.props.formId) {
            this.setState(initialState(), () => this.getForm());
        }
        if (prevProps.formIds !== this.props.formIds) {
            this.setState(initialState(), () => this.getForm());
        }
    }

    getForm = () => {
        Promise.all([
            API.get(`/forms/configuration/${this.props.formId}`),
            API.get(`/forms/configuration/${this.props.formId}/forms`, {params:{
                formIds: this.props?.formIds
            }})
        ]).then(res => {
            this.setState({
                form: res[0].data,
                forms: _.filter(res[1].data, i => i.status !== 'Cancelled'),
                isLoading: false,
            })
        })
    }

    previewForm = (form) => {
        // console.log(form, this.props.uuidReplace)
        this.props.deployDialog(
            <Grid container spacing={2}>
                <Grid item xs={12} style={{marginTop: 16}}>
                    <FormView formId={this.props.formId} displayForm={form.id} />
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                </Grid>
            </Grid>,
            this.props.uuidReplace(form.uuid)
        )
    }

    render() {

        const { isLoading, form, forms, showGroupDialog } = this.state;
        const { OverrideTitle } = this.props;

        if (isLoading) {
            return <LoadingCircle/>
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {OverrideTitle ? 
                        <OverrideTitle/> :
                        <Grid container spacing={1}>
                            <InfoBox
                                title={'Forms Sent'}
                                value={forms.length}
                                icon={IconHelper.forms}
                                rowSize={4}
                            />
                            <InfoBox
                                title={'Forms Completed'}
                                value={_.filter(forms, {status: 'Completed'}).length}
                                icon={IconHelper.true}
                                rowSize={4}
                            />
                            <InfoBox
                                title={'Forms In Progress'}
                                value={_.filter(forms, {status: 'In Progress'}).length}
                                icon={IconHelper.dash}
                                rowSize={4}
                            />
                            <InfoBox
                                title={'Forms Unanswered'}
                                value={_.filter(forms, {status: 'Not Started'}).length}
                                icon={IconHelper.false}
                                rowSize={4}
                            />
                        </Grid>
                    }
                </Grid>
                {_.filter(forms, {status: 'Not Started'}).length === forms.length ?
                    <Grid item container spacing={2}>
                        <Grid item xs={12} align="left">
                            <PaddedPaper>
                                <Typography variant="h6">
                                    No data available
                                </Typography>
                                <Typography variant="body1">
                                    Come back later when a form has been filled out.
                                </Typography>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    :
                    <Grid item container spacing={2}>
                        {form?.pages?.map((page, pIdx) => {
                            return (
                                <Grid item container spacing={2}>
                                    <Grid item xs={12} align="right">
                                        <Typography variant="body2" >
                                            <b>Page {pIdx + 1}</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} align="center">
                                                    <Grid item xs={12}>
                                                        <Grid container item spacing={3}>
                                                            <Grid item xs={12} align="left">
                                                                {page.groups?.map((group, gIdx) => {
                                                                    return(
                                                                        <React.Fragment key={gIdx}>
                                                                            <Box style={{backgroundColor: CLENAWARE_BLUE, border: '1px solid rgb(241, 241, 241)', color: 'white'}} p={2} mt={1}>
                                                                                <Grid container spacing={3} style={{justifyContent: 'space-between'}}>
                                                                                    <Grid item align="left">
                                                                                        <Typography variant="subtitle1" gutterBottom>
                                                                                            {group?.nm}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                            <Box p={2}>
                                                                                <Grid container spacing={3}>
                                                                                    {group?.elements.map((e, eIdx) => <FormReportElement key={eIdx} element={e} forms={forms} formConfigId={this.props.formId} setFormPreview={this.previewForm} uuidReplace={this.props.uuidReplace}/> )}
                                                                                </Grid>
                                                                            </Box>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                }
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, size='lg') => {
            dispatch(deployDialog(content, title, 'system', size))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        }
    }
}

export default connect(null, mapDispatchToProps)(FormReport);