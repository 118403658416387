import { useState } from "react";
import moment from 'moment';

export function handleChange(e) {
    this.setState({
        formData: {
            ...this.state.formData,
            [e.target.name]: e.target.value
        }
    });
}

export function handleAddOrRemove(fieldName, value) {
    this.setState({
        formData: {
            ...this.state.formData,
            [fieldName]: this.state.formData[fieldName].includes(value) ? this.state.formData[fieldName].filter(item => item !== value) : [...this.state.formData[fieldName], value]
        }
    })
}

export function handleSortCode(e) {
    let sortCode = e.target.value.replace(/(\d{2})(\d{2})(\d{2})/, "$1-$2-$3")
    this.setState({
        formData: {
            ...this.state.formData,
            [e.target.name]: sortCode
        }
    });
}

export function handleTabChange(event, value) {
    this.setState({
        currentTab: value
    });
};

export function handleCheckedChange(e) {
    let name = e.target.name, val = e.target.checked;
    this.setState({
        formData: {
            ...this.state.formData,
            [name]: val ? 1 : 0
        }
    });
}

export function handleSelectChange(fieldName, selectedOption) {
    this.setState({
        formData: {
            ...this.state.formData,
            [fieldName]: selectedOption && selectedOption.value
        }
    });
}

export function handleMultiSelectChange(fieldName, selectedOptions) {
    let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
    this.setState({
        formData: {
            ...this.state.formData,
            [fieldName]: values
        }
    });
}

export function handleFileChange(drop, name, event) {
    const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
    this.setState({
        formData: {
            ...this.state.formData,
            [name]: file
        }
    });
}

export function clearValue(name, idx = null, array = null) {

    if(array){
        let data = this.state.formData[array];
        data[idx][name] = null;
        this.setState({
            formData: {
                ...this.state.formData,
                [array]: data
            }
        });
    }
    else {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: null
            }
        });
    }
};

export function handleMultiFileChange(drop, name, event) {
    let newFiles        = drop === true ? event.dataTransfer.files : event.target.files;
    let existingFiles   = this.state.formData[name];
    let allFiles        = Array.prototype.slice.call(existingFiles).concat(Array.prototype.slice.call(newFiles))
    this.setState({
        formData: {
            ...this.state.formData,
            [name]: allFiles
        }
    });
}

export function clearMultiFiles(idx, name) {
    let arr = Array.from(this.state.formData[name]);
    arr.splice(idx, 1);
    if(arr.length === 0){
        arr = '';
    }
    this.setState({
        formData:{
            ...this.state.formData,
            [name]: arr
        }
    });
}

export function handleChangeUc(e) {
    if(this.state.formData[e.target.name] !== e.target.value) {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e?.target?.value?.toUpperCase?.()
            }
        });
    }
}

/*
* *********** Dynamic Rows ************
*/
export function handleAddRow(fieldName, object)  {
    let array = this.state.formData?.[fieldName];
    array.push(object)
    this.setState({
        ...this.state,
        formData: {
            ...this.state.formData,
            [fieldName]: array
        }
    })
}

export function handleRemoveRow(fieldName, idx, callBack){
    let array = this.state.formData?.[fieldName];
    array.splice(idx, 1);
    
    this.setState({
        ...this.state,
        formData: {
            ...this.state.formData,
            [fieldName]: array
        }
    }, () => {
        if(callBack){
            callBack();
        }
    });
}

export function handleRowEdit(idx, field, array, value){
    let arr = this.state.formData?.[array];
    arr[idx] = {
        ...arr[idx],
        [field]: value
    };
    this.setState({
        ...this.state,
        formData: {
            ...this.state.formData,
            [array]: arr
        }
    })
}

export function handleRowSelectChange(idx, field, array, selectedOption, callBack) {
    let arr = this.state.formData?.[array];
    arr[idx] = {
        ...arr[idx],
        [field]: selectedOption && selectedOption.value
    };

    this.setState({
        formData: {
            ...this.state.formData,
            [array]: arr
        }
    }, () => {
        if(callBack){
            callBack();
        }
    });
}

export function handleRowMultiSelectChange(idx, field, array, selectedOptions) {
    let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
    let arr = this.state.formData?.[array];
    arr[idx] = {
        ...arr[idx],
        [field]: values
    };
    this.setState({
        formData: {
            ...this.state.formData,
            [array]: arr
        }
    });
}

export function handleRowFileChange(drop, name, event, array, idx){
    
    const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];

    let arr = this.state.formData?.[array];
    arr[idx] = {
        ...arr[idx],
        [name]: file
    };
    this.setState({
        ...this.state,
        formData: {
            ...this.state.formData,
            [array]: arr
        }
    })
}

export function handleRowDateChange(array, idx, name, date, format = '') {
    let newDate = format ? moment(date).format(format) : moment(date);

    let arr = this.state.formData?.[array];
    arr[idx] = {
        ...arr[idx],
        [name]: date ? newDate : null
    };

    this.setState({
        formData: {
            ...this.state.formData,
            [array]: arr
        }
    });
}



/*
* ************************************
*/

export function handleDateChange(name, date, format = '') {
    let newDate = format ? moment(date).format(format) : moment(date);
    this.setState({
        formData: {
            ...this.state.formData,
            [name]: date ? newDate : null
        }
    });
}

export function handleTimeChange(name, time) {
    let newTime = time && moment(time).format('HH:mm')

    this.setState({
        formData: {
            ...this.state.formData,
            [name]: newTime
        }
    });
}

export function handleFloat(e) {
    this.setState({
        formData: {
            ...this.state.formData,
            [e.target.name]: parseFloat(e.target.value).toFixed(2)
        }
    })
}

export function handleUpdateChange(e) {
    this.setState({
        updateData: {
            ...this.state.updateData,
            [e.target.name]: e.target.value
        }
    });
}

export function handleUpdateSelectChange(fieldName, selectedOption) {
    this.setState({
        updateData: {
            ...this.state.updateData,
            [fieldName]: selectedOption && selectedOption.value
        }
    });
};

export function handleSnackbarClose(type) {
    this.setState({ 
        snackbar: {
        ...this.state.snackbar,
        [type]: false,
        } 
    });
};

export function handleConfirmationOpen(type) {
    this.setState({ 
        confirmation: {
            ...this.state.confirmation,
            [type]: true,
        } 
    });
};

export function handleConfirmationClose(type) {
    this.setState({ 
        confirmation: {
            ...this.state.confirmation,
            [type]: false,
        } 
    });
};

export function handleConfirmationSuccess(type) {
    this.setState({ 
        confirmation: {
            ...this.state.confirmation,
            [type]: false,
        } 
    }, () => {
        if(type === 'submit') {
            this.submit();
        } else if(type === 'delete') {
            this.delete();
        } else if(type === 'archive') {
            this.archive();
        } else if(type === 'update') {
            this.update();
        } else if(type === 'sync') {
            this.sync();
        } else if(type === 'cancel') {
            this.cancel();
        }
    });
}

export function handleDialogClose(type) {
    this.setState({ 
        dialog: {
            ...this.state.dialog,
            [type]: false,
        } 
    });
}

export function cancelSubmit(e) {
    e.preventDefault();
}

function buildFormData(formData, data, parentKey) {
    if(data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}

export function getFormData() {
    const formData = new FormData();
    buildFormData(formData, this.state.formData);
    return formData;
}

export function gridPageChanged(page) {
    this.setState({
        gridPagination: {
            ...this.state.gridPagination,
            page
        }
    });
}

export function gridRowsPerPageChanged(rowsPerPage) {
    this.setState({
        gridPagination: {
            ...this.state.gridPagination,
            rowsPerPage
        }
    });
}

// Hooks

// use Input can be used with text fields and autocomplete selects
export const useInput = initialValue => {
    const [value, setValue] = useState(initialValue);
  
    return {
      value,
      setValue,
      reset: () => setValue(""),
      bind: {
        value,
        onChange: event => {
          setValue(event.value ?? event.target.value);
        }
      }
    };
};
