import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../../Common/Paper/PaddedPaper';
import API from '../../../../API';
import CiDataTable from '../../../Common/DataTables/CiDataTable';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    results: [],
    isLoading: true
});

class MaximumStockLevelReport extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'reports:MaximumStockLevelReport';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.getParts();
    }

    getParts = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/parts/aboveMaximumStockLevels')
            .then((partRes) =>  {
                this.setState({
                    ...this.state,
                    results: partRes.data,
                    isLoading: false
                });
            });
        })
    }
       
    render() {
        const { results } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Maximum Stock Level Report
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <CiDataTable  
                            config={{
                                key:                    'stock_id',
                                pagination:             true,
                                alternatingRowColours:  true,
                                persistenceId:          this.persistenceId,
                                isLoading:              this.state.isLoading
                            }}
                            columns={[
                                {
                                    heading: 'Number',
                                    field: rowData => rowData.part.part_number,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Description',
                                    field: rowData => rowData.part.part_description
                                },
                                {
                                    heading: 'Minimum Level',
                                    field: rowData => rowData.stock_minimum_level,
                                    alignment: 'right',
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Maximum Level',
                                    field: rowData => rowData.stock_maximum_level,
                                    alignment: 'right',
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Quantity',
                                    field: rowData => rowData.stock_current_qty,
                                    alignment: 'right',
                                    cellProps: rowData => {
                                        return ({
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        });
                                    },
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Difference',
                                    field: rowData => '+' + rowData.difference_above_maximum_level,
                                    alignment: 'right',
                                    cellProps: rowData => {
                                        return ({
                                            style: {
                                                color: 'darkorange'
                                            }
                                        });
                                    },
                                    sizeToContent: true
                                }
                            ]}
                            rows={results}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(MaximumStockLevelReport);
