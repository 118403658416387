import React from 'react';

import { Badge, Grid, ListItem, Typography } from '@material-ui/core';

import { ReduxAvatar } from 'Components/Common/Badges/Avatar';

const StaffMember = ({badge, border, className, content, disabled, inlineContent, minimal, noPadding, selected, staff, size, stacked, handleAction, vertical, img}) => {
    if(staff && staff.name) {
        let name = staff.name;
        if(minimal) {
            name = staff.name.split(' ');
            if(name.length > 1) {
                name = `${name[0]} ${name[1].charAt(0)}.`
            } else {
                name = name[0]
            }
        }

        const Avatar = () => (
            (badge) ?
                <Badge
                    badgeContent={badge ? parseInt(badge) : 0}
                    className={parseInt(badge) > 0 ? 'mt-1' : ''}
                    color='primary'
                    max={99}
                >
                    {ReduxAvatar(staff, {size: size ? size : 'md'})}
                </Badge> :
                <Badge>
                    {ReduxAvatar(staff, {size: size ? size : 'md'})}
                </Badge>
        )

        return (
            <ListItem 
                className={`${className ? className : (minimal ? "sm-list-item-min" : (border ? "sm-list-item" : "sm-list-item-borderless"))}${noPadding ? ` p-0`:``}${selected ? ` selected`:``}`}
                disableGutters
                onClick={() => { if(!disabled && handleAction) { handleAction(); }}}
                button={!disabled && handleAction ? true : false}
            >
                <Grid container spacing={2} alignItems='center'>
                    {(vertical && (
                        <React.Fragment>
                            <Grid item xs={12} align='center'>
                                <Avatar />
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Typography variant={((size === "sm" || minimal) && 'caption') || 'subtitle1'}>
                                    {name}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    )) || (content && (
                        <React.Fragment>
                            <Grid item xs={12} className='gap-2' />
                            <Grid item xs={12} align='center'>
                                <Avatar />
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Typography variant={(size === "sm" && 'body2') || 'subtitle1'}>
                                    {name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} align='center'>
                                {content}
                            </Grid>
                        </React.Fragment>
                    )) || (
                        <React.Fragment>
                            <Grid item>
                                <Avatar />
                            </Grid>
                            <Grid item xs>
                                <Typography variant={(size === "sm" && 'body2') || 'subtitle1'}>
                                    {name}
                                </Typography>
                                {stacked && inlineContent} 
                            </Grid>
                            {!stacked && inlineContent}
                            {img && 
                                <Grid item xs={12} align='center'>
                                    <img src={img} alt='Staff Member' style={{maxWidth: 300, maxHeight: 150}}/>
                                </Grid>
                            }
                        </React.Fragment>
                    )}
                </Grid>
            </ListItem>
        )
    } else {
        return null
    }    
}

export default StaffMember;