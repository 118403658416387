import React                   from 'react';
import API                     from 'API';
import { GOOGLE_MAPS_API_KEY } from 'Constants';
import GoogleMapReact          from 'google-map-react';
import { connect }             from 'react-redux';
import _                       from 'lodash';

import { Box, Grid, IconButton, Tooltip, Typography, Button } from '@material-ui/core';

import DefaultMapPin  from 'Assets/Images/google-marker.png'; 
import streetViewIcon from 'Assets/Icons/street-view-solid.svg';

import AllIcon        from 'Components/Common/Icons/AllIcon';
import PaddedPaper    from 'Components/Common/Paper/PaddedPaper';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';

import NewMarkerDialog    from './NewMarkerDialog';
import MapMarker          from './MapMarker';
import StreetMap          from './StreetMap';
import UpdateMarkerDialog from './UpdateMarkerDialog';

import { colors }        from 'Helpers/ColourHelper';
import icons             from 'Helpers/IconHelper';
import { formatSeconds } from 'Helpers/TimeHelper';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar }            from 'Actions/SnackBar/SnackBar';
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard';

const Marker = ({ show, place, readonly, markerPin, key }) => (
    <>
        <div style={{cursor: readonly ? 'grab' : 'cursor'}}>{markerPin}</div>
        {(!readonly && show) && <InfoWindow place={place} />}
    </>
)

const InfoWindow = (props) => {
    const { place } = props;
    const infoWindowStyle = {
        position: 'relative',
        bottom: '100%',
        left: '-190px',
        width: 380,
        color: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        fontSize: 13,
        zIndex: 100,
        padding: 0,
    };

    return (
        <Box style={infoWindowStyle}>
            {place?.content}
        </Box>
    );
};

const initialState = () => ({
    lat: 52.300959,
    lng: -0.739988,
    draggable: true,
    zoom: 11,
    center: [52.300959, -0.739988],
    ogMarkers: [],
    markers: [],
    showStreetView: false,
    ddMode: false,
    formData: {
        lat: 52.300959,
        lng: -0.739988,
    },
    hybridMap: false,
    filters: [],
    streetViewFormData: {
        pick: false,
        show: false,
        lat: 52.300959,
        lng: -0.739988,
    },
    access: {
        edit:  false,
        delete: false,
        create: false,
    },
    mainMarkerFiltered: false,
})
class Map extends React.Component {

    constructor(props) {
        super(props);
        let { lat, lng } = this.props;

        this.googleMap = React.createRef();

        this.state = {...initialState(),
            lat:    lat ? lat : initialState().lat,
            lng:    lng ? lng : initialState().lng
        };
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/map-markers-create'), 
            API.get('/staff/my/access/check/map-markers-edit'),
            API.get('/staff/my/access/check/map-markers-delete'),
        ]).then(([ createRes, editRes, deleteRes]) => {
            this.setState({ 
                access: { 
                    edit:   editRes?.data?.has_access   || false, 
                    delete: deleteRes?.data?.has_access || false,
                    create: createRes?.data?.has_access || false,
                } 
            });
        })
    }

    componentDidMount = () => {
        let { lat, lng, markers, zoom, center, showMarkers } = this.props;

        this.getAccess();

        this.setState({
            lat: lat ? lat : this.state.lat,
            lng: lng ? lng : this.state.lng,
            markers: markers ? markers : this.state.markers,
            ogMarkers: markers ? markers : this.state.ogMarkers,
            zoom: zoom ? zoom : this.state.zoom,
            center: center ? center : this.state.center
        }, () => {
            if (showMarkers) this.getMarkers();
        });

    }

    componentDidUpdate = (prevProps) => {
        let { uuid, markers } = this.props;
        if ( (uuid && uuid !== prevProps.uuid) || (markers && !_.isEqual(markers, prevProps.markers))) {
            this.setState({
                ogMarkers: markers ? markers : this.state.ogMarkers,
                markers: markers ? markers : this.state.markers
            });
        }
    }

    editMarker = id => {
        this.props.deployDialog(<UpdateMarkerDialog id={id} onUpdate={()=>{ this.props.closeDialog(); this.onMarkerChange();}} />, 'Update Marker', 'md');
    }

    deleteMarker = id => {
        API.post(`/engineers/maps/markers/${id}/delete`)
        .then(res => {
            this.onMarkerChange()
        })
    }

    showBigImage = image => () => {
        this.props.deployDialog(
            <Grid container>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <img src={image} style={{maxWidth: 500}} />
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                </Grid>
            </Grid>, 'View Image', 'sm'
        )
    }

    getMarkers = () => {
        this.setState({
            markers: initialState().markers,
        },() => {
            API.get('/engineers/maps/markers', {params: { lng: this.state.lng, lat: this.state.lat, office: _.isEqual([initialState().lat, initialState().lng], [this.state.lat, this.state.lng]) ? 1 : 0 }})
            .then(res => {
                this.setState({ 
                    ogMarkers: [...this.props.markers ? this.props.markers : [], ..._.map(res.data, i => ({
                        lat:    i.mm_lat,
                        lng:    i.mm_lng,
                        marker: <MapMarker icon={i.category.mmc_icon} color={i.category.mmc_color} size={30}/>,
                        filter: `${i.category.mmc_category}${i.category.mmc_name}`,
                        zoomFilter: this.props.markersShowZoomLevel,
                        content: 
                            <PaddedPaper style={{marginBottom: '-100%'}}>
                                <Grid container >
                                    <Grid item xs={12} style={{display:'flex'}}>
                                        <Typography variant='h6'>{i.mm_name}</Typography>
                                        <div style={{display: 'flex', marginLeft: 'auto'}}>
                                            <CopyToClipboard color={colors.disabled} noPadding style={{padding: 5}} copy={
`${i.mm_name} 
${i.mm_input_address || `${i.mm_lat}, ${i.mm_lng}`} 

${i.mm_notes}`
                                            } />
                                            <CopyToClipboard color={colors.disabled} noPadding style={{padding: 5}} copy={i.mm_input_address || `${i.mm_lat}, ${i.mm_lng}`} icon={icons.map}/>
                                            {this.state.access.edit && this.props.allowEditMarker && 
                                                <AllIcon
                                                    icon={icons.edit} 
                                                    size='small'
                                                    color={colors.disabled}
                                                    onClick={() => this.editMarker(i.mm_id)} 
                                                    noPadding
                                                    noMargin
                                                    style={{
                                                        padding: 5,  
                                                    }}
                                                />
                                            }
                                            {this.state.access.delete && this.props.allowDeleteMarker && 
                                                <AllIcon 
                                                    icon={icons.delete} 
                                                    color={colors.disabled}
                                                    onClick={() => this.props.deployConfirmation('Are you sure you want to delete this marker?', 'Delete Marker', ()=>this.deleteMarker(i.mm_id))} 
                                                    size='small'
                                                    noPadding
                                                    noMargin
                                                    style={{
                                                        padding: 5,  
                                                    }}
                                                />
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {i.mm_input_address ? 
                                            <Typography variant='body2'>{i.mm_input_address} </Typography> :
                                            <Typography variant='body2'>{i.mm_lat} | {i.mm_lng}</Typography>
                                        }
                                        <Typography variant='body2'>{parseFloat(i.travel_miles).toFixed(2)} Miles / {formatSeconds(i.travel_time * 60, true, true, false)} Hours Away</Typography>
                                        <Typography variant='body1' style={{paddingTop: '1em'}}>{_.map(_.split(i.mm_notes, '\n'), n => <>{n} <br/></>)}</Typography><br></br>
                                        {i.mm_image && 
                                            <Button onClick={this.showBigImage(i.file_upload)} style={{textAlign: 'center', width: '100%'}}>
                                                <ImageWithError style={{maxWidth: 300, maxHeight: 200}} src={i.file_upload} />
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                                
                            </PaddedPaper>
                    }))],
                }, this.handleFilter);
            })
        })
    }

    onChildClickCallback = (key) => {

        let markers = this.state.markers;

        _.map(markers, (item, idx) => {
            markers[idx].show = key === idx ? !markers[key].show : false;
        });

        this.setState({
            markers,
        });
    };

    getRoute = ({ map, maps }) => {
        const directionsService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
        const origin      = this.props.startingLocation || { lat: initialState().lat, lng: initialState().lng };
        const destination = this.props.endingLocation || { lat: parseFloat(this.state.lat), lng: parseFloat(this.state.lng) };
        
        directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: window.google.maps.TravelMode.DRIVING
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              directionsRenderer.setDirections(result);
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
    }

    onMarkerInteraction = (childKey, childProps, mouse) => {
        this.setState({
            draggable: false,
            formData: {
                lat: mouse.lat,
                lng: mouse.lng
            }
        });
    }

    onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        this.setState({ 
            draggable: true
        });
        this.getAddress();
    }

    getAddress = () => {
        let formData = _.cloneDeep(this.state.formData);
        this.setState({
            ddMode: false,
            formData: initialState().formData
        }, () => this.props.deployDialog(
            <NewMarkerDialog onMarkerCreate={this.onMarkerChange}/>,
            'New Marker', 'md'
        ));
    }

    onMarkerChange = () => {
        this.props.onMarkerChange && this.props.onMarkerChange();
        this.props.showMarkers && this.getMarkers();
    }

    handleNewMarker = () => {
        this.setState({
            ddMode: !this.state.ddMode
        });
    }

    _onClick = (value) => {
        if (this.state.streetViewFormData.pick) {
            this.setState({
                streetViewFormData: {
                    ...this.state.streetViewFormData,
                    lat: value.lat,
                    lng: value.lng,
                    pick: false,
                    show: true
                }
            });
        } else {
            this.setState({
                formData: {
                    lat: value?.lat,
                    lng: value?.lng
                }
            },
            () => {
                this.getAddress();
            });
        }
    }

    addFilter = (filter) => () => {
        let filters = this.state.filters;

        if ( filters.includes(filter) ) filters = _.filter(filters, f => f !== filter);
        else filters.push(filter);

        this.setState({ filters }, this.handleFilter);
    } 

    handleFilter = (zoom=11) => {
        this.setState({
            markers: _.filter(this.state.ogMarkers, m => {
                    if (!m) return false;
                    return !(this.state.filters.includes(m.filter)) && ( m?.zoomFilter ? m.zoomFilter <= zoom : true )
                }
            ) 
        })
    }

    handleFilterAll = () => {
        const filters = this.state.filters;
        let _filters = [];
        if (_.uniqBy(this.state.ogMarkers ,'filter').length !== _.filter(_.uniqBy(this.state.ogMarkers ,'filter'), i => filters.includes(i.filter)).length){
            _filters = _.map(_.uniqBy(this.state.ogMarkers ,'filter'), i => i.filter);
        } 
        this.setState({ filters: _filters }, this.handleFilter);
    }

    hideViewBox = e => {
        e.event.target.tagName !== 'svg' &&
            this.setState({
                markers: _.map(this.state.markers, m => ({...m, show: false}))
            });
    }

    addMainMarkerFilter = () => {
        this.setState({
            mainMarkerFiltered: !this.state.mainMarkerFiltered
        })
    }

    render() {
        const { mainMarkerFiltered, lat, lng, draggable, markers, ddMode, filters, hybridMap, streetViewFormData, access } = this.state;
        const { disableScroll, width, height, readOnly, center, showRoute, showFilter, allowCreateMarker, allowDeleteMarker, allowEditMarker, disableGoogleMarkers, showStreetView, showSwapMapType, zoom } = this.props;

        const allFiltered =  _.uniqBy(this.state.ogMarkers ,'filter').length === _.filter(_.uniqBy(this.state.ogMarkers ,'filter'), i => filters.includes(i.filter)).length;

        return (
            <Grid container>
                <Grid item style={{height:  height || '100vh', width: width || '100%', cursor: streetViewFormData.pick ? `url(${streetViewIcon}), auto` : (ddMode ? 'crosshair' : 'grab'), position: 'relative'}}>
                    {streetViewFormData.show ?
                        <>
                            <StreetMap 
                                lat={streetViewFormData.lat}
                                lng={streetViewFormData.lng}
                                height={height}
                                width={width}
                            />
                            <AllIcon 
                                onClick={()=>this.setState({streetViewFormData: {...streetViewFormData, show :!streetViewFormData.show}})} 
                                icon={ icons.map }
                                noMargin
                                color={colors.white}
                                heavy
                                size={16}
                                noHover
                                buttonStyle={ 
                                    {
                                        position: 'absolute', 
                                        zIndex: 99999, 
                                        boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
                                        right: 10,
                                        bottom: 80,
                                        width: 48, 
                                        height: 48, 
                                        aspectRatio: 'auto 48 / 48',
                                        backgroundColor: '#222222', 
                                    } 
                                }
                            />
                        </> : <>
                            <GoogleMapReact
                                onGoogleApiLoaded={ showRoute && this.getRoute }
                                bootstrapURLKeys={{ 
                                    key: GOOGLE_MAPS_API_KEY,
                                }}
                                ref={this.googleMap}
                                onZoomAnimationEnd ={this.handleFilter}
                                defaultCenter={{
                                    lat: parseFloat(lat),
                                    lng: parseFloat(lng)
                                }}
                                zoom={parseInt(zoom)}
                                center={center || [parseFloat(lat), parseFloat(lng)]}
                                draggable={readOnly ? false : draggable}
                                options={(map) => ({
                                    draggableCursor:  streetViewFormData.pick ? `url(${streetViewIcon}), auto` : (ddMode ? 'crosshair' : 'grab'),
                                    clickableIcons: false,
                                    disableDoubleClickZoom: true,
                                    disableDefaultUi: true,
                                    fullscreenControl: false,
                                    zoomControl: readOnly ||  ddMode ? false : true,
                                    mapTypeId: hybridMap ? map.MapTypeId.HYBRID : map.MapTypeId.ROADMAP,
                                    StreetViewStatus: true,
                                    scrollwheel: !disableScroll,
                                    styles: [{
                                        "featureType": "poi",
                                        "elementType": "labels",
                                        "stylers": [{
                                            "visibility":  "off"
                                        }]
                                    }]
                                })}
                                yesIWantToUseGoogleMapApiInternals
                                onChildClick={!readOnly && this.onChildClickCallback}
                                onTilesLoaded={this.fetchPlaces}
                                onBoundsChanged={this.fetchPlaces}

                                //d&d
                                /*
                                onChildMouseDown={(!readOnly && ddMode) && this.onMarkerInteraction}
                                onChildMouseUp={  (!readOnly && ddMode) && this.onMarkerInteractionMouseUp}
                                onChildMouseMove={(!readOnly && ddMode) && this.onMarkerInteraction}
                                */
                                onClick={         (!readOnly && streetViewFormData.pick) ? (e) => this._onClick(e) : this.hideViewBox}
                                onChange={        (!readOnly && ddMode) && this._onChange}
                            >
                                    {!_.isEmpty(markers) &&
                                        _.map(markers, (item, idx) => (
                                            <Marker
                                                lat={parseFloat(item?.lat)}
                                                lng={parseFloat(item?.lng)}
                                                show={item?.show}
                                                place={item}
                                                readOnly={readOnly}
                                                markerPin={item.marker || <img src={DefaultMapPin} alt="Map Pin" height={15} />}
                                                
                                            />
                                        ))
                                    }
                                    {
                                        ( this.props.lat || this.props.lng ) && !mainMarkerFiltered &&
                                            <Marker
                                                lat={parseFloat(lat)}
                                                lng={parseFloat(lng)}
                                                markerPin={this.props.marker || <img src={DefaultMapPin} alt="Map Pin" height={15} />}
                                            /> 
                                    }
                                    <Marker
                                        lat={parseFloat(initialState().lat)}
                                        lng={parseFloat(initialState().lng)}
                                        markerPin={<img src={DefaultMapPin} alt="Map Pin" height={30} />}
    
                                    /> 
                            </GoogleMapReact>
                            {( showStreetView || showSwapMapType ) &&
                                <Grid container style={{position: 'absolute', bottom: 24,  right: 60, zIndex: 88, gridGap: 10, width: '50%' }} className='buttonRow'>
                                    {showStreetView && 
                                        <AllIcon 
                                            onClick={()=>{this.setState({streetViewFormData: {...streetViewFormData, pick: !streetViewFormData.pick}})}}
                                            icon={icons.streetView}
                                            noMargin
                                            color={streetViewFormData.pick ? colors.black : colors.grey}
                                            heavy
                                            size={16}
                                            noHover
                                            buttonStyle={ 
                                                {
                                                    zIndex: 88, 
                                                    backgroundColor: colors.white, 
                                                    borderRadius: 2, 
                                                    width: 40, 
                                                    height: 40, 
                                                    boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
                                                    aspectRatio: 'auto 40 / 40'
                                                }
                                            }
                                        />
                                    }
                                    {showSwapMapType && 
                                        <AllIcon 
                                            onClick={()=>{this.setState({hybridMap: !hybridMap})}}
                                            icon={hybridMap ? icons.map : icons.satellite}
                                            noMargin
                                            color={colors.grey}
                                            heavy
                                            size={16}
                                            noHover
                                            buttonStyle={ 
                                                {
                                                    zIndex: 88, 
                                                    backgroundColor: colors.white, 
                                                    borderRadius: 2, 
                                                    width: 40, 
                                                    height: 40, 
                                                    boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
                                                    aspectRatio: 'auto 40 / 40'
                                                }
                                            }
                                        />
                                    }
                                </Grid>
                            }
                        </>
                    }
                </Grid>
                { !streetViewFormData.show && ( showFilter || allowCreateMarker ) &&
                    <Grid item style={{width: '100%'}}>
                        <PaddedPaper style={{height: '100%', width: '100%', padding: 10}}>
                            <Grid container spacing={1} style={{justifyContent: 'left'}}>
                                <Grid item style={{borderRight: `1px solid ${colors.disabled}`, textAlign: 'center', width: 64}}>
                                    <AllIcon noMargin icon={ allFiltered ? icons.locationPin : icons.locationSlash } 
                                        size={30} onClick={this.handleFilterAll} tooltip={allFiltered ? 'Show All' : 'Hide All'} ttPlacement="top"/>        
                                </Grid>
                                { showFilter && <>
                                    {_.map( _.orderBy(_.uniqBy(this.state.ogMarkers ,'filter'), 'filter') ,i =>
                                        <Grid item style={{textAlign: 'center', width: 64}}>
                                            <Tooltip title={i.filter} placement="top">
                                                <IconButton onClick={this.addFilter(i.filter)} style={{backgroundColor: filters.includes(i.filter) && colors.importantInfo}}>
                                                    {i.marker}
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                    { ( this.props.lat || this.props.lng ) &&
                                        <Grid item style={{textAlign: 'center', width: 64}}>
                                            <Tooltip title={'Marker'} placement="top">
                                                <IconButton onClick={this.addMainMarkerFilter} style={{backgroundColor: mainMarkerFiltered && colors.importantInfo}}>
                                                    {this.props.marker || <img src={DefaultMapPin} alt="Map Pin" height={15} />}
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    }
                                </>}
                                {
                                    allowCreateMarker && access.create &&
                                        <Grid item style={{ width: 64, marginLeft: 'auto', borderLeft: `1px solid ${colors.disabled}`, textAlign: 'center'}}>
                                            <AllIcon 
                                                noMargin 
                                                icon={icons.locationPlus}
                                                size={30} 
                                                onClick={this.getAddress}
                                                tooltip='Create New Marker'
                                            />    
                                        </Grid>
                                }
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
                
            </Grid>
        )
    }
}
 

const mapDispatchToProps = (dispatch) => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    deployDialog:       (content, title, size)    => dispatch(deployDialog(content, title, null, size)),
    closeDialog:        ()                        => dispatch(closeDialog()),
    deploySnackBar:     (variant, msg)            => dispatch(deploySnackBar(variant, msg))
});

export default connect(null, mapDispatchToProps)(Map);