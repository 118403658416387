import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography, TextField } from '@material-ui/core';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import React from 'react';
import CreateOrUpdateEmailTemplateDialog from './CreateOrUpdateEmailTemplateDialog';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import API from 'API';
import IconHelper from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';

import EmailEditor from 'Components/Common/Email/EmailEditor/EmailEditor'
import { FullScreenDialog } from 'Components/Common/Dialogs/FullScreenDialog';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { connect } from 'react-redux';

const initialState = () => ({
    isLoading: true,
    emailTemplates: [],
    templateDialogData: {
        open: false,
        template: null,
    },
    searchFormData: {
        keyword: null,
    }
})

class EmailTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getEmailTemplates();
    }

    getEmailTemplates = () => {
        API.get('/marketing/emailTemplates')
        .then((response) => {
            this.setState({
                emailTemplates: response.data,
                isLoading: false,
            });
        });
    }

    handleEmailTemplateDialogShow = (template) => () => {

        this.props.deployDialog(
            <FullScreenDialog 
                open={1} 
                onClose={this.handleEmailTemplateDialogClose} 
                title={`${template ? 'Edit' : 'Create'} Email Template`} 
                content={
                    <CreateOrUpdateEmailTemplateDialog
                        update={template}
                        onClose={this.handleEmailTemplateDialogClose}
                    />
                }
            />, 
            "", 
            null, 
            'fs'
        )

        // this.setState({
        //     templateDialogData: {
        //         open: true,
        //         template,
        //     }
        // });
    }

    handleEmailTemplateDialogClose = () => {
        this.props.closeDialog();
        this.getEmailTemplates();
        // this.setState({
        //     templateDialogData: {
        //         open: false,
        //         template: null,
        //     }
        // }, this.getEmailTemplates);
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            searchFormData: {
                ...this.state.searchFormData,
                [name]: value
            }
        }, this.getEmailTemplates)
    }


    render() {

        const { isLoading, templateDialogData, emailTemplates, searchFormData } = this.state;

        if (isLoading) {
            return (
                <LoadingCircle/>
            )
        }

        return (
            <Grid container spacing={1}>
                <Grid item style={{maxWidth: 500, width: '100%'}}>
                    <TextField
                        placeholder='Keyword Search...'
                        name='keyword'
                        value={searchFormData.keyword}
                        onChange={this.handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item
                    style={{
                        marginLeft: 'auto',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleEmailTemplateDialogShow()}
                    >Create New Email Template</Button>
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        config={{
                            key: 'ml_id',
                            pagination: true,
                        }}
                        rows={emailTemplates}
                        columns={[
                            {
                                heading: 'Name',
                                field: 'met_name',
                                dataRef: 'met_name',
                            },
                            {
                                actions: i => ([
                                    {
                                        name: 'Edit',
                                        onClick: this.handleEmailTemplateDialogShow(i),
                                        icon: IconHelper.edit
                                    }
                                ])
                            }
                        ]}
                    />
                </Grid>
                {templateDialogData.open &&
                    <Dialog
                        open={templateDialogData.open}
                        fullWidth
                        fullScreen
                    >
                        <DialogTitle
                            style={{
                                backgroundColor: colors.primary,
                            }}
                        >
                            <Typography variant="h5"
                                style={{color: colors.white}}
                            >{!!templateDialogData.template ? 'Update' : 'Create'} New Email Template</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <CreateOrUpdateEmailTemplateDialog
                                update={templateDialogData.template}
                                onClose={
                                    this.handleEmailTemplateDialogClose
                                }
                            />
                        </DialogContent>
                    </Dialog>
                }
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, size) => { dispatch(deployDialog(content, title, null, size))},
        closeDialog:  ()                     => {dispatch(closeDialog())}
    }
}

export default connect(null, mapDispatchToProps)(EmailTemplates);