import React       from 'react';
import API         from 'API';
import _           from 'lodash';
import { connect } from 'react-redux';

import { Checkbox, Typography, Grid, Button, ButtonGroup } from '@material-ui/core';

import DataTable     from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import InfoIcon      from 'Components/Common/Icons/InfoIcon';
import AllIcon       from 'Components/Common/Icons/AllIcon';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import { colors }      from 'Helpers/ColourHelper';
import icons           from 'Helpers/IconHelper';
import { checkAccess } from 'Helpers/ErrorHelper';

const initialState = {
    stock:         [],
    table:         'Overview',
    isLoading:     true,
    incorrectOnly: false,
    access:        {}
}

class StockTracker extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }   

    componentDidMount() {
        this.getTrackerData();
        this.getAccess();
    }

    getAccess = async () => {
        this.setState({access: {
            correctStock: await checkAccess('stock-tracker:correct-stock')
        }});
    }

    getTrackerData = () => {
        API.get(`/stock/tracker`)
        .then( res => {
            this.setState({ stock: res.data, isLoading: false });
        })
    }

    changeTable = (table) => () => {
        this.setState({isLoading: true}, () => this.setState({ table, isLoading: false }));
    } 

    correctPendingStock = (part) => () => {
        API.post(`/stock/tracker/correctStock/${part.q}`, {
            pending:           part.t.d,
            pendingSSR:        part.t.t,
            pendingPPO:        part.t.y,
            pendingSales:      part.t.u,
            pendingReturns:    part.t.i,
            pendingWO:         parseFloat(parseFloat(part.t.o) + parseFloat(part.t.p)),
            pendingMovement:   parseFloat(parseFloat(part.t.s) + parseFloat(part.t.a)),
            stock:             part.y.u,
        }).then(this.getTrackerData)
    }

    confirmStockFix = r => () => {
        this.props.deployConfirmation(
            <Grid container>
                <Grid item xs={12} style={{paddingBottom: '0.5em'}}>
                    <Typography variant='h6'>The Following stock will be changed</Typography>
                    <Typography variant='body2'>Note: this will change the numbers stored in the database without effecting stock activity</Typography>
                </Grid>
                <Grid item container xs={12}>
                    {parseFloat(r.y.i) !== parseFloat(r.y.u) && <>
                        <Grid item xs={5}><b>Current Stock</b></Grid>
                        <Grid item xs={1}>{parseFloat(r.y.i).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon heavy icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><b>{parseFloat(r.y.u).toFixed(3)}</b></Grid>
                    </>}
                    {parseFloat(r.t.f) !== parseFloat(r.t.d) && <>
                        <Grid item xs={5}><b>Pending Stock</b></Grid>
                        <Grid item xs={1}>{parseFloat(r.t.f).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon heavy icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><b>{parseFloat(r.t.d).toFixed(3)}</b></Grid>
                    </>}
                    {parseFloat(r.t.k) !== parseFloat(r.t.t) && <>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>SSR</Grid>
                        <Grid item xs={1}>{parseFloat(r.t.k).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><>{parseFloat(r.t.t).toFixed(3)}</></Grid>
                    </>}
                    {parseFloat(r.t.l) !== parseFloat(r.t.y) && <>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>PPO</Grid>
                        <Grid item xs={1}>{parseFloat(r.t.l).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><>{parseFloat(r.t.y).toFixed(3)}</></Grid>
                    </>}
                    {parseFloat(r.t.g) !== parseFloat(r.t.u) && <>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>Sales</Grid>
                        <Grid item xs={1}>{parseFloat(r.t.g).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><>{parseFloat(r.t.u).toFixed(3)}</></Grid>
                    </>}
                    {parseFloat(r.t.h) !== parseFloat(r.t.i) && <>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>Returns</Grid>
                        <Grid item xs={1}>{parseFloat(r.t.h).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><>{parseFloat(r.t.i).toFixed(3)}</></Grid>
                    </>}
                    {parseFloat(r.t.z) !== parseFloat(r.t.o + r.t.p) && <>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>WorksOrders</Grid>
                        <Grid item xs={1}>{parseFloat(r.t.z).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><>{parseFloat(r.t.o + r.t.p).toFixed(3)}</></Grid>
                    </>}
                    {parseFloat(r.t.j) !== parseFloat(r.t.a + r.t.s) && <>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>Movements</Grid>
                        <Grid item xs={1}>{parseFloat(r.t.j).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><>{parseFloat(r.t.a + r.t.s).toFixed(3)}</></Grid>
                    </>}
                    {parseFloat(parseFloat(r.y.i) + parseFloat(r.t.f)) !== parseFloat(parseFloat(r.y.u) + parseFloat(r.t.d)) && <>
                        <Grid item xs={5}><b>Predicted Stock</b></Grid>
                        <Grid item xs={1}>{parseFloat(parseFloat(r.y.i) + parseFloat(r.t.f)).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon heavy icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><b>{parseFloat(parseFloat(r.y.u) + parseFloat(r.t.d)).toFixed(3)}</b></Grid>
                    </>}
                </Grid>
            </Grid>,
            'Are you sure you want to fix this parts stock?',
            this.correctPendingStock(r));
    }

    render() {
        const { stock, isLoading, table, incorrectOnly, access } = this.state;

        if ( isLoading ) return ( <LoadingCircle /> );

        let columns = [
            {
                field:          'r',
                fieldFormat:    'image',
                sizeToContent:  true,
                filter:         'custom',
                filterMod:      {
                                    dataRef:    (search, i) => {return `${i.w}|${i.e}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                    label:      'Keywords',
                                    styleGrid:  {width: '50%'},
                                }
            },
            {
                heading: 'Part/Description',
                field:   i => <>{i.w}<br/>{i.e}</>,
            }
        ]

        let rows = stock;
        switch (table){
            case 'Pending':
                rows = _.filter(stock, r => !!(parseFloat(r.t.f) || parseFloat(r.t.d)));
                if (incorrectOnly)
                    rows = _.filter(rows, r => 
                        (parseFloat(r.t.k) !== parseFloat(r.t.t)) ||
                        (parseFloat(r.t.l) !== parseFloat(r.t.y)) ||
                        (parseFloat(r.t.g) !== parseFloat(r.t.u)) ||
                        (parseFloat(r.t.h) !== parseFloat(r.t.i)) ||
                        (parseFloat(r.t.z) !== parseFloat(r.t.o + r.t.p)) ||
                        (parseFloat(r.t.j) !== parseFloat(r.t.a + r.t.s)) ||
                        (parseFloat(r.t.f) !== parseFloat(r.t.d)) 
                    );
                
                columns = [
                    ...columns,
                    {
                        heading:       <>SSR <InfoIcon info='Supplier Stock Return' color={colors.white}/></>,
                        field:         't.t',
                        dataRef:       't.t',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                        style:         r => ({border: parseFloat(r.t.k) !== parseFloat(r.t.t) && `2px solid ${colors.red}`}),
                        info:          r => parseFloat(r.t.k) !== parseFloat(r.t.t) && <>Database: {parseFloat(r.t.k).toFixed(3)}<br/>Calculated: {parseFloat(r.t.t).toFixed(3)}</>,
                    },
                    {
                        heading:       <>PPO <InfoIcon info='Part Purchase Order' color={colors.white} /></>,
                        field:         't.y',
                        dataRef:       't.y',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                        style:         r => ({border: parseFloat(r.t.l) !== parseFloat(r.t.y) && `2px solid ${colors.red}`}),
                        info:          r => parseFloat(r.t.l) !== parseFloat(r.t.y) && <>Database: {parseFloat(r.t.l).toFixed(3)}<br/>Calculated: {parseFloat(r.t.y).toFixed(3)}</>,
                    },
                    {
                        heading:       <>Sales</>,
                        field:         't.u',
                        dataRef:       't.u',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                        style:         r => ({border: parseFloat(r.t.g) !== parseFloat(r.t.u) && `2px solid ${colors.red}`}),
                        info:          r => parseFloat(r.t.g) !== parseFloat(r.t.u) && <>Database: {parseFloat(r.t.g).toFixed(3)}<br/>Calculated: {parseFloat(r.t.u).toFixed(3)}</>,
                    },
                    {
                        heading:       <>Returns</>,
                        field:         't.i',
                        dataRef:       't.i',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                        style:         r => ({border: parseFloat(r.t.h) !== parseFloat(r.t.i) && `2px solid ${colors.red}`}),
                        info:          r => parseFloat(r.t.h) !== parseFloat(r.t.i) && <>Database: {parseFloat(r.t.h).toFixed(3)}<br/>Calculated: {parseFloat(r.t.i).toFixed(3)}</>,
                    },
                    {
                        heading:       <>WOB <InfoIcon info='Works Order Build' color={colors.white} /></>,
                        field:         't.o',
                        dataRef:       't.o',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                        style:         r => ({border: parseFloat(r.t.z) !== parseFloat(r.t.o + r.t.p) && `2px solid ${colors.red}`, borderRight: 'initial'}),
                    },
                    {
                        heading:       <>WOC <InfoIcon info='Works Order Component' color={colors.white} /></>,
                        field:         't.p',
                        dataRef:       't.p',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                        style:         r => ({border: parseFloat(r.t.z) !== parseFloat(r.t.o + r.t.p) && `2px solid ${colors.red}`, borderLeft: 'initial'}),
                        info:          r => parseFloat(r.t.z) !== parseFloat(r.t.o + r.t.p) && <>Database: {parseFloat(r.t.z).toFixed(3)}<br/>Calculated: {parseFloat(r.t.o + r.t.p).toFixed(3)}</>,
                    },
                    {
                        heading:       <>Movement</>,
                        field:         't.a',
                        dataRef:       't.a',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                        style:         r => ({border: parseFloat(r.t.j) !== parseFloat(r.t.a + r.t.s) && `2px solid ${colors.red}`, borderRight: 'initial'}),
                    },
                    {
                        heading:       <>Correction</>,
                        field:         't.s',
                        dataRef:       't.s',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                        style:         r => ({border: parseFloat(r.t.j) !== parseFloat(r.t.a + r.t.s) && `2px solid ${colors.red}`, borderLeft: 'initial'}),
                        info:          r => parseFloat(r.t.j) !== parseFloat(r.t.a + r.t.s) && <>Database: {parseFloat(r.t.j).toFixed(3)}<br/>Calculated: {parseFloat(r.t.a + r.t.s).toFixed(3)}</>,
                    },
                    {
                        heading:       <>Pending</>,
                        field:         't.j',
                        dataRef:       't.j',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                        style:         {borderLeft: `1px solid ${colors.disabled}`},
                        style:         r => ({border: parseFloat(r.t.j) !== parseFloat(r.t.k) && `2px solid ${colors.red}`}),
                        info:          r => parseFloat(r.t.j) !== parseFloat(r.t.k) && <>Database: {parseFloat(r.t.j).toFixed(3)}<br/>Calculated: {parseFloat(r.t.k).toFixed(3)}</>,
                    },
                ];
            break;
            case 'Overview':
                rows = _.filter(stock, r => !!(parseFloat(r.t.f) || parseFloat(r.t.d)) || !!(parseFloat(r.y.u) || parseFloat(r.y.i)));
                if (incorrectOnly)
                    rows = _.filter(rows, r => 
                        (parseFloat(r.t.k) !== parseFloat(r.t.t)) ||
                        (parseFloat(r.t.l) !== parseFloat(r.t.y)) ||
                        (parseFloat(r.t.g) !== parseFloat(r.t.u)) ||
                        (parseFloat(r.t.h) !== parseFloat(r.t.i)) ||
                        (parseFloat(r.t.z) !== parseFloat(r.t.o + r.t.p)) ||
                        (parseFloat(r.t.j) !== parseFloat(r.t.a + r.t.s)) ||
                        (parseFloat(r.t.f) !== parseFloat(r.t.d)) ||
                        (parseFloat(r.y.i) !== parseFloat(r.y.u))
                    );
                columns = [
                    ...columns,
                    {
                        heading:       'Stock +',
                        field:         r => _.sumBy(_.filter(Object.keys(r.y), i => ['q','w','e','r','t','y'].includes(i)), i => parseFloat(r.y[i]) > 0 ? parseFloat(r.y[i]) : 0),
                        dataRef:       r => _.sumBy(_.filter(Object.keys(r.y), i => ['q','w','e','r','t','y'].includes(i)), i => parseFloat(r.y[i]) > 0 ? parseFloat(r.y[i]) : 0),
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                    },
                    {
                        heading:       'Stock -',
                        field:         r => _.sumBy(_.filter(Object.keys(r.y), i => ['q','w','e','r','t','y'].includes(i)), i => parseFloat(r.y[i]) < 0 ? parseFloat(r.y[i]) : 0),
                        dataRef:       r => _.sumBy(_.filter(Object.keys(r.y), i => ['q','w','e','r','t','y'].includes(i)), i => parseFloat(r.y[i]) < 0 ? parseFloat(r.y[i]) : 0),
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                    },
                    {
                        heading:       <>Stock</>,
                        field:         'y.u',
                        dataRef:       'y.u',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                        style:         r => parseFloat(r.y.i) !== parseFloat(r.y.u) ? {border:`2px solid ${colors.red}`, borderRight: null} : { borderLeft: `1px solid ${colors.disabled}` } ,
                    },
                    {
                        heading:       <></>,
                        field:         r =>                                    
                                            <AllIcon
                                                color={ parseFloat(r.y.u) < parseFloat(r.u) ? colors.red : ( parseFloat(r.y.u) > parseFloat(r.i) ? colors.orange : colors.green ) }
                                                icon={ parseFloat(r.y.u) < parseFloat(r.u) ? icons.stockLevelLow : ( parseFloat(r.y.u) > parseFloat(r.i) ? icons.stockLevelHigh : icons.stockLevelCorrect ) }
                                                tooltip={`${parseFloat(r.u).toFixed(3)} <-> ${parseFloat(r.i).toFixed(3)}`}
                                                noMargin
                                            />,
                        dataRef:       r => parseFloat(r.y.u) < parseFloat(r.u) ? -1 : ( parseFloat(r.y.u) > parseFloat(r.i) ? 1 : 0 ),
                        alignment:     'left',
                        sizeToContent: true,
                        style:         r => parseFloat(r.y.i) !== parseFloat(r.y.u) ? {border:`2px solid ${colors.red}`, borderLeft: null} : { borderRight: `1px solid ${colors.disabled}` },
                        info:          r => parseFloat(r.y.i) !== parseFloat(r.y.u) && <>Database: {parseFloat(r.y.i).toFixed(3)}<br/>Calculated: {parseFloat(r.y.u).toFixed(3)}</>,
                    },
                    {
                        heading:       'Pending +',
                        field:         r => _.sumBy(_.filter(Object.keys(r.t), i => ['t','y','u','i','o','p','a','s'].includes(i)), i => parseFloat(r.t[i]) > 0 ? parseFloat(r.t[i]) : 0),
                        dataRef:       r => _.sumBy(_.filter(Object.keys(r.t), i => ['t','y','u','i','o','p','a','s'].includes(i)), i => parseFloat(r.t[i]) > 0 ? parseFloat(r.t[i]) : 0),
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                        style:         r => {
                                                let calc = _.sumBy(_.filter(Object.keys(r.t), i => ['t','y','u','i','o','p','a','s'].includes(i)), i => parseFloat(r.t[i]) > 0 ? parseFloat(r.t[i]) : 0);
                                                let sys  = _.sumBy(_.filter(Object.keys(r.t), i => ['g','h','j','k','l','z'].includes(i)), i => parseFloat(r.t[i]) > 0 ? parseFloat(r.t[i]) : 0);
                                                return {border: calc !== sys && `2px solid ${colors.red}`};
                                            },
                        info:          r => {
                                                let calc = _.sumBy(_.filter(Object.keys(r.t), i => ['t','y','u','i','o','p','a','s'].includes(i)), i => parseFloat(r.t[i]) > 0 ? parseFloat(r.t[i]) : 0);
                                                let sys  = _.sumBy(_.filter(Object.keys(r.t), i => ['g','h','j','k','l','z'].includes(i)), i => parseFloat(r.t[i]) > 0 ? parseFloat(r.t[i]) : 0);
                                                return calc !== sys && <>Database: {parseFloat(sys).toFixed(3)}<br/>Calculated: {parseFloat(calc).toFixed(3)}</>;
                                            },
                    },
                    {
                        heading:       'Pending -',
                        field:         r => _.sumBy(_.filter(Object.keys(r.t), i => ['t','y','u','i','o','p','a','s'].includes(i)), i => parseFloat(r.t[i]) < 0 ? parseFloat(r.t[i]) : 0),
                        dataRef:       r => _.sumBy(_.filter(Object.keys(r.t), i => ['t','y','u','i','o','p','a','s'].includes(i)), i => parseFloat(r.t[i]) < 0 ? parseFloat(r.t[i]) : 0),
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                        style:         r => {
                                                let calc = _.sumBy(_.filter(Object.keys(r.t), i => ['t','y','u','i','o','p','a','s'].includes(i)), i => parseFloat(r.t[i]) < 0 ? parseFloat(r.t[i]) : 0);
                                                let sys  = _.sumBy(_.filter(Object.keys(r.t), i => ['g','h','j','k','l','z'].includes(i)), i => parseFloat(r.t[i]) < 0 ? parseFloat(r.t[i]) : 0);
                                                return {border: calc !== sys && `2px solid ${colors.red}`};
                                            },
                        info:          r => {
                                                let calc = _.sumBy(_.filter(Object.keys(r.t), i => ['t','y','u','i','o','p','a','s'].includes(i)), i => parseFloat(r.t[i]) < 0 ? parseFloat(r.t[i]) : 0);
                                                let sys  = _.sumBy(_.filter(Object.keys(r.t), i => ['g','h','j','k','l','z'].includes(i)), i => parseFloat(r.t[i]) < 0 ? parseFloat(r.t[i]) : 0);
                                                return calc !== sys && <>Database: {parseFloat(sys).toFixed(3)}<br/>Calculated: {parseFloat(calc).toFixed(3)}</>;
                                            },
                    },
                    {
                        heading:       <>Pending</>,
                        field:         't.j',
                        dataRef:       't.j',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                        style:         r => (parseFloat(r.t.k) !== parseFloat(r.t.j)) ? {border:`2px solid ${colors.red}`} : { borderRight: `1px solid ${colors.disabled}`, borderLeft: `1px solid ${colors.disabled}` },
                        info:          r => parseFloat(r.t.k) !== parseFloat(r.t.j) && <>Database: {parseFloat(r.t.k).toFixed(3)}<br/>Calculated: {parseFloat(r.t.j).toFixed(3)}</>,
                    },
                    {
                        heading:       'Predicted',
                        field:         r => parseFloat(r.y.u) + parseFloat(r.t.j),
                        dataRef:       r => parseFloat(r.y.u) + parseFloat(r.t.j),
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                        style:         r => parseFloat(parseFloat(r.y.u) + parseFloat(r.t.j)) !== parseFloat(parseFloat(r.y.i) + parseFloat(r.t.k)) && {border:`2px solid ${colors.red}`, borderRight: 'initial'},
                    },
                    {
                        heading:       <></>,
                        field:         r =>                                    
                                            <AllIcon
                                                color={ parseFloat(parseFloat(r.y.u) + parseFloat(r.t.j)) < parseFloat(r.u) ? colors.red : ( parseFloat(parseFloat(r.y.u) + parseFloat(r.t.j)) > parseFloat(r.i) ? colors.orange : colors.green ) }
                                                icon={ parseFloat(parseFloat(r.y.u) + parseFloat(r.t.j)) < parseFloat(r.u) ? icons.stockLevelLow : ( parseFloat(parseFloat(r.y.u) + parseFloat(r.t.j)) > parseFloat(r.i) ? icons.stockLevelHigh : icons.stockLevelCorrect ) }
                                                tooltip={`${parseFloat(r.u).toFixed(3)} <-> ${parseFloat(r.i).toFixed(3)}`}
                                                noMargin
                                            />,
                        dataRef:       r => parseFloat(parseFloat(r.y.u) + parseFloat(r.t.j)) < parseFloat(r.u) ? -1 : ( parseFloat(parseFloat(r.y.u) + parseFloat(r.t.j)) > parseFloat(r.i) ? 1 : 0 ),
                        alignment:     'left',
                        sizeToContent: true,
                        style:         r => parseFloat(parseFloat(r.y.u) + parseFloat(r.t.j)) !== parseFloat(parseFloat(r.y.i) + parseFloat(r.t.k)) ? {border:`2px solid ${colors.red}`, borderLeft: 'initial'} : { borderRight: `1px solid ${colors.disabled}` },
                        info:          r => parseFloat(parseFloat(r.y.u) + parseFloat(r.t.j)) !== parseFloat(parseFloat(r.y.i) + parseFloat(r.t.k)) && <>Database: {parseFloat(parseFloat(r.y.i) + parseFloat(r.t.k)).toFixed(3)}<br/>Calculated: {parseFloat(parseFloat(r.y.u) + parseFloat(r.t.d)).toFixed(3)}</>,
                    },
                ];
            break;
            case 'Stock':
                rows = _.filter(stock, r => !!(parseFloat(r.y.u) || parseFloat(r.y.i)));
                if (incorrectOnly) rows = _.filter(rows, r => parseFloat(r.y.i) !== parseFloat(r.y.u));
                columns = [
                    ...columns,
                    {
                        heading:       <>SSR <InfoIcon info='Supplier Stock Return' color={colors.white}/></>,
                        field:         'y.q',
                        dataRef:       'y.q',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                    },
                    {
                        heading:       <>PPO <InfoIcon info='Part Purchase Order' color={colors.white}/></>,
                        field:         'y.w',
                        dataRef:       'y.w',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                    },
                    {
                        heading:       <>Sales</>,
                        field:         'y.e',
                        dataRef:       'y.e',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                    },
                    {
                        heading:       <>Returns</>,
                        field:         'y.r',
                        dataRef:       'y.r',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                    },
                    {
                        heading:       <>WO <InfoIcon info='Works Order' color={colors.white}/></>,
                        field:         'y.t',
                        dataRef:       'y.t',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                    },
                    {
                        heading:       <>Movement</>,
                        field:         'y.y',
                        dataRef:       'y.y',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                    },
                    {
                        heading:       <>Stock</>,
                        field:         'y.u',
                        dataRef:       'y.u',
                        fieldFormat:   'colorNumbers:decimal:3',
                        alignment:     'right',
                        sizeToContent: true,
                        style:         r => ({border: parseFloat(r.y.i) !== parseFloat(r.y.u) && `2px solid ${colors.red}`, borderRight: 'initial'} ),
                    },
                    {
                        heading:       <></>,
                        field:         r =>                                    
                                            <AllIcon
                                                color={ parseFloat(r.y.u) < parseFloat(r.u) ? colors.red : ( parseFloat(r.y.u) > parseFloat(r.i) ? colors.orange : colors.green ) }
                                                icon={ parseFloat(r.y.u) < parseFloat(r.u) ? icons.stockLevelLow : ( parseFloat(r.y.u) > parseFloat(r.i) ? icons.stockLevelHigh : icons.stockLevelCorrect ) }
                                                tooltip={`${parseFloat(r.u)} <-> ${parseFloat(r.i)}`}
                                                noMargin
                                            />,
                        dataRef:       r => parseFloat(r.y.u) < parseFloat(r.u) ? -1 : ( parseFloat(r.y.u) > parseFloat(r.i) ? 1 : 0 ),
                        alignment:     'left',
                        sizeToContent: true,
                        style:         r => ({border: parseFloat(r.y.i) !== parseFloat(r.y.u) && `2px solid ${colors.red}`, borderLeft: 'initial'} ),
                        info:          r => parseFloat(r.y.i) !== parseFloat(r.y.u) && <>Database: {parseFloat(r.y.i).toFixed(3)}<br/>Calculated: {parseFloat(r.y.u).toFixed(3)}</>,
                    }
                ];
            break;
        }

        let hasIncorrect = !!_.filter(rows, r => (parseFloat(r.t.k) !== parseFloat(r.t.t)) || (parseFloat(r.t.l) !== parseFloat(r.t.y)) || (parseFloat(r.t.g) !== parseFloat(r.t.u)) || (parseFloat(r.t.h) !== parseFloat(r.t.i)) || (parseFloat(r.t.z) !== parseFloat(r.t.o + r.t.p)) || (parseFloat(r.t.j) !== parseFloat(r.t.a + r.t.s)) || (parseFloat(r.t.f) !== parseFloat(r.t.d)) || (parseFloat(r.y.i) !== parseFloat(r.y.u)) ).length;

        access.correctStock && hasIncorrect ? 
        columns.push({
            actions: r => ([
                {
                    icon:       icons.true,
                    name:       'Fix Stock',
                    onClick:    this.confirmStockFix(r),
                    disabled:   !(  (parseFloat(r.t.k) !== parseFloat(r.t.t)) ||
                                    (parseFloat(r.t.l) !== parseFloat(r.t.y)) ||
                                    (parseFloat(r.t.g) !== parseFloat(r.t.u)) ||
                                    (parseFloat(r.t.h) !== parseFloat(r.t.i)) ||
                                    (parseFloat(r.t.z) !== parseFloat(r.t.o + r.t.p)) ||
                                    (parseFloat(r.t.j) !== parseFloat(r.t.a + r.t.s)) ||
                                    (parseFloat(r.t.f) !== parseFloat(r.t.d)) ||
                                    (parseFloat(r.y.i) !== parseFloat(r.y.u)))
                },
                {
                    name: 'View',
                    icon: icons.search,
                    link: `/parts/view/${r.q}`,
                }, 
            ])
        }) : 
        columns.push({
            actions: r => ([
                {
                    name: 'View',
                    icon: icons.search,
                    link: `/parts/view/${r.q}`,
                }, 
            ])
        });

        return (
            <Grid container spacing={3} >
                <Grid item xs={12}>
                    <Grid container spacing={2} style={{alignItems: 'center', alignContent: 'center'}}>
                        <Grid item>
                            <ButtonGroup variant='outlined'>
                                <Button onClick={this.changeTable('Overview')} color={table === 'Overview' && 'primary'} variant={table === 'Overview' && 'contained'} >Overview</Button>
                                <Button onClick={this.changeTable('Pending')}  color={table === 'Pending'  && 'primary'} variant={table === 'Pending'  && 'contained'} >Pending </Button>
                                <Button onClick={this.changeTable('Stock')}    color={table === 'Stock'    && 'primary'} variant={table === 'Stock'    && 'contained'} >Stock   </Button>
                            </ButtonGroup>
                        </Grid>
                        {hasIncorrect &&
                            <Grid item>
                                <Typography variant="h6">
                                    <Checkbox 
                                        checked={incorrectOnly}
                                        onClick={()=>{this.setState({incorrectOnly: !incorrectOnly})}}
                                        color='primary'
                                    />Only Show Incorrect
                                </Typography>
                            </Grid>
                        }   
                    </Grid>
                    <DataTable
                        config={{
                            key:        'q',
                            pagination: true,
                            loading:    isLoading,
                            filter:     true,
                            filterMod:  {
                                filterInPagination:     true,
                                clearable:              true,
                                colSpan:                3,
                            },
                            sticky: true
                        }}
                        columns={columns}
                        rows={_.orderBy(rows, 'w', 'asc')}
                    />
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) { return { deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)) } };

export default connect(null, mapDispatchToProps)(StockTracker);