import React, { PureComponent } from 'react';
import API                      from 'API';
import _                        from 'lodash';
import { connect }              from 'react-redux';

import { Grid, Typography, Checkbox } from '@material-ui/core/';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';
import DataTable     from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import InfoIcon      from 'Components/Common/Icons/InfoIcon';
import AllIcon       from 'Components/Common/Icons/AllIcon';

import { colors }                 from 'Helpers/ColourHelper';
import icons                      from 'Helpers/IconHelper';
import { checkAccess }            from 'Helpers/ErrorHelper';
import { colorNumbers, currency } from 'Helpers/NumberHelper';

const initialState = { 
    formErrors:    {},
    data:          [],
    isLoading:     true,
    access:        {},
    incorrectOnly: false
}

const InfoBlock = ({title, icon, value}) => {
    return (
        <Grid item xs={3}>
            <PaddedPaper  style={{textAlign: 'center'}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}><Typography variant="h6" style={{textAlign: 'center'}}>{title}</Typography></Grid>
                    <Grid item xs={12}><AllIcon icon={icon} noMargin size='large' /></Grid>
                    <Grid item xs={12}><Typography variant="h6"  style={{textAlign: 'center'}}>{value}</Typography></Grid>
                </Grid>
            </PaddedPaper>
        </Grid>
    )
} 

class StockReportsPending extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getData();
        this.getAccess();
    }

    getAccess = async () => {
        this.setState({access: {
            correctStock: await checkAccess('stock-tracker:correct-stock')
        }});
    }

    getData = () => {
        API.get('/reports/stocksReport/pending', {params: {partId: this.props.partId}})
        .then(res => {
            this.setState({
                data:      _.filter(res.data, 'w'), 
                isLoading: false
            });
        })
    }

    
    correctPendingStock = (part) => () => {
        API.post(`/stock/tracker/correctStock/${part.q}`).then(this.getData)
    }

    confirmStockFix = r => {
        this.props.deployConfirmation(
            <Grid container>
                <Grid item xs={12} style={{paddingBottom: '0.5em'}}>
                    <Typography variant='h6'>The following stock will be changed</Typography>
                    <Typography variant='body2'>Note: This will change the numbers stored in the database without effecting stock activity</Typography>
                </Grid>
                <Grid item container xs={12}>
                    {parseFloat(r.j.c) !== parseFloat(r.j.s) && <>
                        <Grid item xs={5}><b>Current Stock</b></Grid>
                        <Grid item xs={1}>{parseFloat(r.j.s).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon heavy icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><b>{parseFloat(r.j.c).toFixed(3)}</b></Grid>
                    </>}
                    {parseFloat(r.h.c) !== parseFloat(r.h.s) && <>
                        <Grid item xs={5}><b>Pending Stock</b></Grid>
                        <Grid item xs={1}>{parseFloat(r.h.s).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon heavy icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><b>{parseFloat(r.h.c).toFixed(3)}</b></Grid>
                    </>}
                    {parseFloat(r.y.s) !== parseFloat(r.y.c) && <>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>SSR</Grid>
                        <Grid item xs={1}>{parseFloat(r.y.s).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><>{parseFloat(r.y.c).toFixed(3)}</></Grid>
                    </>}
                    {parseFloat(r.u.s) !== parseFloat(r.u.c) && <>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>PPO</Grid>
                        <Grid item xs={1}>{parseFloat(r.u.s).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><>{parseFloat(r.u.c).toFixed(3)}</></Grid>
                    </>}
                    {parseFloat(r.i.s) !== parseFloat(r.i.c) && <>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>Sales</Grid>
                        <Grid item xs={1}>{parseFloat(r.i.s).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><>{parseFloat(r.i.c).toFixed(3)}</></Grid>
                    </>}
                    {parseFloat(r.o.s) !== parseFloat(r.o.c) && <>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>Returns</Grid>
                        <Grid item xs={1}>{parseFloat(r.o.s).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><>{parseFloat(r.o.c).toFixed(3)}</></Grid>
                    </>}
                    {parseFloat(r.s.c) !== parseFloat(r.s.s) && <>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>Works Orders</Grid>
                        <Grid item xs={1}>{parseFloat(r.s.s).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><>{parseFloat(r.s.c).toFixed(3)}</></Grid>
                    </>}
                    {parseFloat(r.g.s) !== parseFloat(r.g.c) && <>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>Movements</Grid>
                        <Grid item xs={1}>{parseFloat(r.g.s).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><>{parseFloat(r.g.c).toFixed(3)}</></Grid>
                    </>}
                    {(parseFloat(r.h.c) !== parseFloat(r.h.s) || parseFloat(r.j.c) !== parseFloat(r.j.s)) && <>
                        <Grid item xs={5}><b>Predicted Stock</b></Grid>
                        <Grid item xs={1}>{parseFloat(r.h.s + r.j.c).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon heavy icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><b>{parseFloat(r.h.c + r.j.s).toFixed(3)}</b></Grid>
                    </>}
                </Grid>
            </Grid>,
            'Are you sure you want to fix this parts stock?',
            this.correctPendingStock(r));
    }

    render() {
        const { data, isLoading, access, incorrectOnly } = this.state;

        if (isLoading) return <LoadingCircle />;

        const hasIncorrect = _.filter(_.filter(data, r => 
            parseFloat(r.y.c).toFixed(3) !== parseFloat(r.y.s).toFixed(3) || 
            parseFloat(r.u.c).toFixed(3) !== parseFloat(r.u.s).toFixed(3) || 
            parseFloat(r.i.c).toFixed(3) !== parseFloat(r.i.s).toFixed(3) || 
            parseFloat(r.o.c).toFixed(3) !== parseFloat(r.o.s).toFixed(3) || 
            parseFloat(r.s.c).toFixed(3) !== parseFloat(r.s.s).toFixed(3) || 
            parseFloat(r.g.c).toFixed(3) !== parseFloat(r.g.s).toFixed(3) || 
            parseFloat(r.h.c).toFixed(3) !== parseFloat(r.h.s).toFixed(3)
        ),'w').length > 0;

        return (
            <Grid container xs={12} spacing={2} style={{width: '100%'}}>
                <Grid item xs={12}>
                    <Grid container spacing={2} >
                        <InfoBlock 
                            title='Pending' 
                            icon={icons.stock} 
                            value={<>
                                <AllIcon icon={icons.parts}/>{colorNumbers(data.length)}<br/>
                                <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(data,  r => r.h.c).toFixed(3))}
                            </>}
                        />
                        <InfoBlock 
                            title='Incoming' 
                            icon={icons.plus} 
                            value={<>
                                <AllIcon icon={icons.parts}/>{colorNumbers(_.filter(data, r => parseFloat(r.k) > 0).length)}<br/>
                                <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(_.filter(data, r => parseFloat(r.k) > 0),  r => parseFloat(r.k)).toFixed(3))}
                            </>} 
                        />
                        <InfoBlock 
                            title='Outgoing' 
                            icon={icons.minus} 
                            value={<>
                                <AllIcon icon={icons.parts}/>{colorNumbers(0 - _.filter(data, r => parseFloat(r.l) < 0).length)}<br/>
                                <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(_.filter(data, r => parseFloat(r.l) < 0),  r => parseFloat(r.l)).toFixed(3))}
                            </>} 
                        />
                        <InfoBlock 
                            title='Incorrect' 
                            icon={icons.warning} 
                            value={<>
                                <AllIcon icon={icons.parts}/>{colorNumbers(_.filter(data, r => parseFloat(r.y.c) !== parseFloat(r.y.s) || parseFloat(r.u.c) !== parseFloat(r.u.s) ||parseFloat(r.i.c) !== parseFloat(r.i.s) ||parseFloat(r.o.c) !== parseFloat(r.o.s) ||parseFloat(r.s.c) !== parseFloat(r.s.s) ||parseFloat(r.g.c) !== parseFloat(r.g.s) ||parseFloat(r.h.c) !== parseFloat(r.h.s)).length)}<br/>
                                <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(_.filter(data, r => parseFloat(r.y.c) !== parseFloat(r.y.s) || parseFloat(r.u.c) !== parseFloat(r.u.s) ||parseFloat(r.i.c) !== parseFloat(r.i.s) ||parseFloat(r.o.c) !== parseFloat(r.o.s) ||parseFloat(r.s.c) !== parseFloat(r.s.s) ||parseFloat(r.g.c) !== parseFloat(r.g.s) ||parseFloat(r.h.c) !== parseFloat(r.h.s)), r => (r.h.c - r.h.s)).toFixed(3))}
                            </>} 
                        />
                        
                        <InfoBlock title='Pending' icon={icons.poundSign}  value={currency(_.sumBy(data, r => r.h.c * r.t))}/>
                        <InfoBlock title='Incoming' icon={icons.poundSign} value={currency(_.sumBy(data, r => parseFloat(r.k) > 0 ? parseFloat(r.k) * parseFloat(r.t) : 0 ))}/>
                        <InfoBlock title='Outgoing' icon={icons.poundSign} value={currency(_.sumBy(data, r => parseFloat(r.l) < 0 ? parseFloat(r.l) * parseFloat(r.t) : 0 ))}/>
                        <InfoBlock 
                            title='Incorrect' 
                            icon={icons.poundSign} 
                            value={currency(_.sumBy(_.filter(data, r => parseFloat(r.y.c) !== parseFloat(r.y.s) || parseFloat(r.u.c) !== parseFloat(r.u.s) ||parseFloat(r.i.c) !== parseFloat(r.i.s) ||parseFloat(r.o.c) !== parseFloat(r.o.s) ||parseFloat(r.s.c) !== parseFloat(r.s.s) ||parseFloat(r.g.c) !== parseFloat(r.g.s) ||parseFloat(r.h.c) !== parseFloat(r.h.s)), r => (r.h.c - r.h.s) * r.t))}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{paddingTop: '1.5em'}}> 
                    {hasIncorrect &&
                        <span style={{textAlign: 'right', width: '100%', marginLeft: '85%', display: 'flex', alignItems: 'center'}}><Checkbox checked={incorrectOnly} onChange={e => this.setState({incorrectOnly: !incorrectOnly})} /><Typography variant='h6'>Incorrect Only</Typography></span>
                    }
                    <DataTable 
                         config={{
                            key: 'q',
                            pagination: true,
                            responsiveimportant: true,
                            rowsPerPage: 25,
                            filter: true,
                            filterMod: {
                                filterInPagination:     true,
                                clearable:              true,
                                colSpan:                4,
                            },
                            sticky:true
                        }}
                        columns={[
                            {
                                field:        'r',
                                fieldFormat: 'image',
                                sizeToContent: true,
                                filter:         'custom',
                                filterMod: {
                                    dataRef:   (search, rowData) => {return `${rowData.w}|${rowData.e}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                    label:     'Search Parts'
                                }
                            },
                            {
                                heading:  'Part/Description',
                                field:    i => <>{i.w}<br/>{i.e}</>,
                                truncate: true,
                            },
                            {
                                heading:       <>SSR <InfoIcon info='Supplier Stock Return' color={colors.white}/></>,
                                field:         'y.c',
                                dataRef:       'y.c',
                                alignment:     'right',
                                sizeToContent: true,
                                fieldFormat:   'colorNumbers:decimal:3',
                                style:         r => ({border: parseFloat(r.y.c).toFixed(3) !== parseFloat(r.y.s).toFixed(3) && `2px solid ${colors.red}`}),
                                info:          r => parseFloat(r.y.c).toFixed(3) !== parseFloat(r.y.s).toFixed(3) && <>Database: {parseFloat(r.y.s).toFixed(3)}<br/>Calculated: {parseFloat(r.y.c).toFixed(3)}</>,
                            },
                            {
                                heading:       <>PPO <InfoIcon info='Part Purchase Order' color={colors.white}/></>,
                                field:         'u.c',
                                dataRef:       'u.c',
                                alignment:     'right',
                                sizeToContent: true,
                                fieldFormat:   'colorNumbers:decimal:3',
                                style:         r => ({border: parseFloat(r.u.c).toFixed(3) !== parseFloat(r.u.s).toFixed(3) && `2px solid ${colors.red}`}),
                                info:          r => parseFloat(r.u.c).toFixed(3) !== parseFloat(r.u.s).toFixed(3) && <>Database: {parseFloat(r.u.s).toFixed(3)}<br/>Calculated: {parseFloat(r.u.c).toFixed(3)}</>,
                            },
                            {
                                heading:       <>Sales</>,
                                field:         'i.c',
                                dataRef:       'i.c',
                                alignment:     'right',
                                sizeToContent: true,
                                fieldFormat:   'colorNumbers:decimal:3',
                                style:         r => ({border: parseFloat(r.i.c).toFixed(3) !== parseFloat(r.i.s).toFixed(3) && `2px solid ${colors.red}`}),
                                info:          r => parseFloat(r.i.c).toFixed(3) !== parseFloat(r.i.s).toFixed(3) && <>Database: {parseFloat(r.i.s).toFixed(3)}<br/>Calculated: {parseFloat(r.i.c).toFixed(3)}</>,
                            },
                            {
                                heading:       <>Returns <InfoIcon info='Credit Notes & Greenaware' color={colors.white}/></>,
                                field:         'o.c',
                                dataRef:       'o.c',
                                alignment:     'right',
                                sizeToContent: true,
                                fieldFormat:   'colorNumbers:decimal:3',
                                style:         r => ({border: parseFloat(r.o.c).toFixed(3) !== parseFloat(r.o.s).toFixed(3) && `2px solid ${colors.red}`}),
                                info:          r => parseFloat(r.o.c).toFixed(3) !== parseFloat(r.o.s).toFixed(3) && <>Database: {parseFloat(r.o.s).toFixed(3)}<br/>Calculated: {parseFloat(r.o.c).toFixed(3)}</>,
                            },
                            {
                                heading:       <>WOC <InfoIcon info='Works Order Component' color={colors.white}/></>,
                                field:         'a.c',
                                dataRef:       'a.c',
                                alignment:     'right',
                                sizeToContent: true,
                                fieldFormat:   'colorNumbers:decimal:3',
                                style:         r => parseFloat(r.s.c).toFixed(3) !== parseFloat(r.s.s).toFixed(3) && ({border: `2px solid ${colors.red}`, borderRight: 'none'}),
                            },
                            {
                                heading:       <>WOB <InfoIcon info='Works Order Build' color={colors.white}/></>,
                                field:         'p.c',
                                dataRef:       'p.c',
                                alignment:     'right',
                                sizeToContent: true,
                                fieldFormat:   'colorNumbers:decimal:3',
                                style:         r => parseFloat(r.s.c).toFixed(3) !== parseFloat(r.s.s).toFixed(3) && ({border: `2px solid ${colors.red}`, borderLeft: 'none'}),
                                info:          r => parseFloat(r.s.c).toFixed(3) !== parseFloat(r.s.s).toFixed(3) && <>Database: {parseFloat(r.s.s).toFixed(3)}<br/>Calculated: {parseFloat(r.s.c).toFixed(3)}</>,
                            },
                            {
                                heading:       <>Movement</>,
                                field:         'd.c',
                                dataRef:       'd.c',
                                alignment:     'right',
                                sizeToContent: true,
                                fieldFormat:   'colorNumbers:decimal:3',
                                style:         r => parseFloat(r.g.c).toFixed(3) !== parseFloat(r.g.s).toFixed(3) && ({border: `2px solid ${colors.red}`, borderRight: 'none'}),
                            },
                            {
                                heading:       <>Correction</>,
                                field:         'f.c',
                                dataRef:       'f.c',
                                alignment:     'right',
                                sizeToContent: true,
                                fieldFormat:   'colorNumbers:decimal:3',
                                style:         r => parseFloat(r.g.c).toFixed(3) !== parseFloat(r.g.s).toFixed(3) && ({border: `2px solid ${colors.red}`, borderLeft: 'none'}),
                                info:          r => parseFloat(r.g.c).toFixed(3) !== parseFloat(r.g.s).toFixed(3) && <>Database: {parseFloat(r.g.s).toFixed(3)}<br/>Calculated: {parseFloat(r.g.c).toFixed(3)}</>,
                            },
                            {
                                heading:       <>Pending +</>,
                                field:         'k',
                                dataRef:       'k',
                                alignment:     'right',
                                sizeToContent: true,
                                fieldFormat:   'colorNumbers:decimal:3',
                                style:         {borderLeft: `1px solid ${colors.disabled}`}
                            },
                            {
                                heading:       <>Pending -</>,
                                field:         'l',
                                dataRef:       'l',
                                alignment:     'right',
                                sizeToContent: true,
                                fieldFormat:   'colorNumbers:decimal:3',
                            },
                            {
                                heading:       <>Pending</>,
                                field:         'h.c',
                                dataRef:       'h.c',
                                alignment:     'right',
                                sizeToContent: true,
                                fieldFormat:   'colorNumbers:decimal:3',
                                style:         r => parseFloat(r.h.c).toFixed(3) !== parseFloat(r.h.s).toFixed(3) ? {border: `2px solid ${colors.red}`} : {borderRight: `1px solid ${colors.disabled}`, borderLeft: `1px solid ${colors.disabled}`},
                                info:          r => parseFloat(r.h.c).toFixed(3) !== parseFloat(r.h.s).toFixed(3) && <>Database: {parseFloat(r.h.s).toFixed(3)}<br/>Calculated: {parseFloat(r.h.c).toFixed(3)}</>,
                            },
                            {
                                actions: r => access?.correctStock && hasIncorrect ?
                                [
                                    {
                                        icon:       icons.true,
                                        name:       'Fix Stock',
                                        onClick:    this.confirmStockFix,
                                        disabled:   !(  parseFloat(r.y.c).toFixed(3) !== parseFloat(r.y.s).toFixed(3) || 
                                                        parseFloat(r.u.c).toFixed(3) !== parseFloat(r.u.s).toFixed(3) ||
                                                        parseFloat(r.i.c).toFixed(3) !== parseFloat(r.i.s).toFixed(3) ||
                                                        parseFloat(r.o.c).toFixed(3) !== parseFloat(r.o.s).toFixed(3) ||
                                                        parseFloat(r.s.c).toFixed(3) !== parseFloat(r.s.s).toFixed(3) ||
                                                        parseFloat(r.g.c).toFixed(3) !== parseFloat(r.g.s).toFixed(3) ||
                                                        parseFloat(r.h.c).toFixed(3) !== parseFloat(r.h.s).toFixed(3) )
                                    },
                                    {
                                        name:   'View',
                                        icon:   icons.search,
                                        link: `/parts/view/${r.q}`,
                                    },  
                                ] : [
                                    {
                                        name:   'View',
                                        icon:   icons.search,
                                        link: `/parts/view/${r.q}`,
                                    }, 
                                ]
                            }
                        ]}
                        rows={_.orderBy(_.filter(incorrectOnly ?  _.filter(data, r => parseFloat(r.y.c).toFixed(3) !== parseFloat(r.y.s).toFixed(3) || parseFloat(r.u.c).toFixed(3) !== parseFloat(r.u.s).toFixed(3) || parseFloat(r.i.c).toFixed(3) !== parseFloat(r.i.s).toFixed(3) || parseFloat(r.o.c).toFixed(3) !== parseFloat(r.o.s).toFixed(3) || parseFloat(r.s.c).toFixed(3) !== parseFloat(r.s.s).toFixed(3) || parseFloat(r.g.c).toFixed(3) !== parseFloat(r.g.s).toFixed(3) || parseFloat(r.h.c).toFixed(3) !== parseFloat(r.h.s).toFixed(3)) : data, 'w'), 'w')}
                    />
                </Grid>
            </Grid>
        )
    }
};


function mapDispatchToProps(dispatch) { return { deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)) } };

export default connect(null, mapDispatchToProps)(StockReportsPending);
