import React, { Component } from 'react';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import {Grid, Typography, ListItem, Paper} from '@material-ui/core';

import { dismissPopNotification }  from 'Actions/PopNotification/PopNotification';
import { putNotificationStatus }   from 'Actions/Notifications/Notifications';

import StaffMember          from 'Components/Common/Staff/StaffMember';
import AllIcon              from 'Components/Common/Icons/AllIcon';
import TypographyAllowHtml  from 'Components/Common/Typography/TypographyAllowHtml';

//const viewTime = 10000;

const initialState = {
  data: [],
  timeout: null
}

class Notification extends Component{
  constructor(props) {
    super(props);
 
    this.state    = {data: this.props.data};
    this.timeout  = false;
  }
/*
  componentDidMount = () => {
    this.timeout = setTimeout(this.clearNotification, viewTime);
  }

  componentDidUpdate = () => {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(()=>{this.clearNotification()}, viewTime);
  }
  
  componentWillUnmount = () => {
    this.clearTime();
  }

  clearTime = () => {
    if (this.timeout) clearTimeout(this.timeout);
  }
*/
  clearNotification = () => { _.each(this.state.data, i => {this.handleClose(i.id, i)})}

  handleClose = (id,i) => {
    let data = this.state.data;
    this.props.markRead(id);
    this.setState(data);
  };

  Notification = i => {
    switch(i.type){
      case 'message':
        return  (
          <StaffMember
              key={i.id}
              badge={null}
              staff={this.props.staff[i.i]}
              handleAction={() => {}}
              size="xs"
              stacked
              inlineContent={i.m}
              img={i.im}
          />
        )
      case 'notification':
        const icon = i.b.split(':');
        return (
          <ListItem button disableGutters onClick={()=>{this.handleClose(i.id, i)}} fullWidth style={{paddingLeft: 15, paddingRight: 15}}>
            <Grid container spacing={2} alignItems='center'>
              <Grid item>
                <AllIcon icon={icon[0]} className={icon[1]} size="avatar" noMargin />
              </Grid>
              <Grid item xs>
                <Grid container alignItems='flex-start'>
                  <Grid item xs>
                    <Typography variant="body2">{i.t}</Typography>
                    <Typography variant="caption" component="div" className="pr-1">
                      {i.c && i.c.split('\n').map((item, key) => (
                          <TypographyAllowHtml component='span' key={key} body={`${item} <br><br/>`} />
                      ))}
                    </Typography>
                    <Typography variant="overline" color="textSecondary" component="div" style={{paddingTop: 8}}>
                        {i.w}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        )
    }
  }

  render() {
    const { data } = this.state;
    return (
      <Grid style={{position: 'fixed', right: '1.1em', top: '67px', zIndex: 69}}>
          {!!data.length && _.map(_.filter(_.reverse(_.sortBy(data, 'w')),i => {return !i.closed}), (i,idx) => 
            <>
              {idx < 3 &&
                <Paper style={{marginTop: '0.75em'}} button onClick={()=>{this.handleClose(i.id, i)}}>
                  {this.Notification(i)}
                </Paper>
              }
            </>
          )}
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    markRead:               (data)       => dispatch(dismissPopNotification(data)),
    putNotificationStatus:  (id, action) => dispatch(putNotificationStatus(id, action)),
  };
}

const mapStateToProps = (state) => {
  return {
      badges: state.notifications.badges,
      staff: state.notifications.status,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);