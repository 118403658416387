import React from 'react';

import Box from '@material-ui/core/Box';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const ThreadLoading = () => (
    <Box p={1.5}>
        <div style={{position: 'absolute', top: '50%', height: 200, marginTop: -100, textAlign: 'center', width: 'calc(100% - 24px)'}}>
            <LoadingCircle size='large' />
        </div>
    </Box>
)

export default ThreadLoading;