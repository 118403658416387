import React, {Component} from 'react';
import moment             from 'moment';
import API                from 'API';
import _                  from 'lodash';

import PaddedPaper  from 'Components/Common/Paper/PaddedPaper';
import DataTable    from 'Components/Common/DataTables/CiDataTable';
import AllIcon      from 'Components/Common/Icons/AllIcon';

import { colors }    from 'Helpers/ColourHelper';
import icons         from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';

import { Grid, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const initialState = {
    isLoading: true,
    formData: {
        date: null
    },
    stockMovements: [],
    formErrors: []
}

const StockTable = ({data, label, icon, expanded}) => (
    <Grid item xs={12}>
        <ExpansionPanel styles={{width:'100%'}} expanded={expanded}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <AllIcon icon={icon} color={colors.white} /><Typography>{label}</Typography> 
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <DataTable
                    config={{
                        key: 'sc_id',
                        alternatingRowColours: true,
                        responsiveimportant: true,
                        pagination: true,
                        filterInPagination: true,
                        filterMod: {
                            filterInPagination:     true,
                            colSpan:                2,
                            clearable:              true,
                        },
                    }}
                    columns={[
                        {
                            heading:    'Date',
                            field:      rowData => clenyDate(rowData.sc_date),
                            dataRef:    'sc_date',
                            sizeToContent: true,
                            filter:     'date',
                            filterMod: {displayOrder: 1}
                        },
                        {
                            heading:    'Reference',
                            field:      rowData => rowData.sc_ref,
                            dataRef:    'sc_ref',
                            filter:     'custom',
                            filterMod: {
                                dataRef: (search, rowData) => {return `${rowData.sc_created_by}|${rowData.sc_ref}|${rowData.sc_updated_by}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                label:     'Keywords',
                                styleGrid: {width: '50%'}
                            },
                            truncate:   true
                        },
                        {
                            heading:        'Parts',
                            field:          rowData => parseFloat(rowData.details.length),
                            dataRef:        rowData => parseFloat(rowData.details.length),
                            sizeToContent:  true,
                            alignment:      'right',
                        },
                        {
                            heading:        'Stock In',
                            field:          rowData => parseFloat(_.sumBy(rowData.details, i=>parseFloat(i.scd_stock_change) > 0 ? parseFloat(i.scd_stock_change) : 0)),
                            dataRef:        rowData => parseFloat(_.sumBy(rowData.details, i=>parseFloat(i.scd_stock_change) > 0 ? parseFloat(i.scd_stock_change) : 0)),
                            sizeToContent:  true,
                            alignment:      'right',
                        },
                        {
                            heading:        'Stock out',
                            field:          rowData => parseFloat(_.sumBy(rowData.details, i=>parseFloat(i.scd_stock_change) < 0 ? parseFloat(i.scd_stock_change) : 0)),
                            dataRef:        rowData => parseFloat(_.sumBy(rowData.details, i=>parseFloat(i.scd_stock_change) < 0 ? parseFloat(i.scd_stock_change) : 0)),
                            sizeToContent:  true,
                            alignment:      'right',
                        },
                        {
                            heading:    'Created',
                            field:      rowData => <>
                                {rowData.sc_created_by}<br/>
                                {clenyDate(rowData.sc_created_datetime)}
                            </>,
                            dataRef:    'sc_created_by',
                            sizeToContent:  true,
                        },
                        {
                            heading:    icon === icons.tick ? 'Completed' : (icon === icons.delete ? 'Deleted' : 'Last Changed'),
                            field:      rowData => 
                                icon === icons.tick ? 
                                    <>
                                        {rowData.sc_submitted_by}<br/>
                                        {clenyDate(rowData.sc_submitted_datetime)}
                                    </>
                                : (icon === icons.delete ? 
                                    <>
                                        {rowData.sc_deleted_by}<br/>
                                        {clenyDate(rowData.sc_deleted_datetime)}
                                    </>
                                : 
                                    (
                                        rowData.details.length > 0 ? 
                                        <>
                                            {_.first(_.orderBy(rowData.details, [i=>i.scd_updated_by ? i.scd_updated_datetime : i.scd_created_datetime],['asc'])).scd_updated_by ||
                                                _.first(_.orderBy(rowData.details, [i=>i.scd_updated_by ? i.scd_updated_datetime : i.scd_created_datetime],['asc'])).scd_created_by
                                            }<br/>
                                            {clenyDate(
                                                _.first(_.orderBy(rowData.details, [i=>i.scd_updated_by ? i.scd_updated_datetime : i.scd_created_datetime],['asc'])).scd_updated_datetime ||
                                                _.first(_.orderBy(rowData.details, [i=>i.scd_updated_by ? i.scd_updated_datetime : i.scd_created_datetime],['asc'])).scd_created_datetime
                                            )}
                                        </> : '-'
                                    )
                                ),
                            dataRef:     rowData => 
                            icon === icons.tick ? rowData.sc_submitted_datetime
                                        : (icon === icons.delete ? rowData.sc_deleted_datetime
                                            : 
                                                (
                                                    rowData.details.length > 0 ? 
                                                            _.first(_.orderBy(rowData.details, [i=>i.scd_updated_by ? i.scd_updated_datetime : i.scd_created_datetime],['asc'])).scd_updated_datetime ||
                                                            _.first(_.orderBy(rowData.details, [i=>i.scd_updated_by ? i.scd_updated_datetime : i.scd_created_datetime],['asc'])).scd_created_datetime
                                                    : null
                                                )
                            ),
                            sizeToContent:  true,
                        },
                        {
                            actions: rowData => {
                                return [{name: 'View', icon: 'search', link: { pathname: '/stock/correction/' + rowData.sc_id }, disabled: moment(rowData.sc_date).diff(moment(),'days') > 0}]
                            }
                        }
                    ]}
                    rows={_.orderBy(data, ['sc_date','sc_id'],['desc','desc'])}
                />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    </Grid>
)



class StockCorrections extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => { this.getData(); }

    getData = () => {
        Promise.all([
            API.get(`/stock/correction/all`),
        ]).then(([movementRes]) => {
            this.setState({
                stockCorrections: movementRes.data,
                isLoading: false
            });
        })
    }

    render() {

        const { stockCorrections } = this.state;

        return (
            <Grid container >
                <Grid item style={{padding:'1em', width:'20%'}}>
                    <PaddedPaper style={{textAlign:'center'}}>
                        <AllIcon icon={icons.stockStock} size='xxlarge' />
                        <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Outstanding</Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>{_.filter(stockCorrections, i=>!i.sc_deleted && !i.sc_submitted).length}</Typography>
                    </PaddedPaper>
                </Grid>
                <Grid item style={{padding:'1em', width:'20%'}}>
                    <PaddedPaper style={{textAlign:'center'}}>
                        <AllIcon icon={icons.due} size='xxlarge' />
                        <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Due</Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>{_.filter(stockCorrections, i=>!i.sc_deleted && !i.sc_submitted && parseFloat(moment(i.sc_date).diff(moment().startOf('day'),'days')) === 0).length}</Typography>
                    </PaddedPaper>
                </Grid>
                <Grid item style={{padding:'1em', width:'20%'}}>
                    <PaddedPaper style={{textAlign:'center'}}>
                        <AllIcon icon={icons.overdue} size='xxlarge' />
                        <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Overdue</Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>{_.filter(stockCorrections, i=>!i.sc_deleted && !i.sc_submitted && moment(i.sc_date).diff(moment().startOf('day'),'days') < 0).length}</Typography>
                    </PaddedPaper>
                </Grid>
                <Grid item style={{padding:'1em', width:'20%'}}>
                    <PaddedPaper style={{textAlign:'center'}}>
                        <AllIcon icon={icons.delete} size='xxlarge' />
                        <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Deleted</Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>{_.filter(stockCorrections, i=>i.sc_deleted).length}</Typography>
                    </PaddedPaper>
                </Grid>
                <Grid item style={{padding:'1em', width:'20%'}}>
                    <PaddedPaper style={{textAlign:'center'}}>
                        <AllIcon icon={icons.tick} size='xxlarge' />
                        <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Completed</Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>{_.filter(stockCorrections, i=>i.sc_submitted).length}</Typography>
                    </PaddedPaper>
                </Grid>
                <StockTable data={_.filter(stockCorrections, i=>!i.sc_deleted && !i.sc_submitted && parseFloat(moment(i.sc_date).diff(moment().startOf('day'),'days')) === 0)} label={`Due (${_.filter(stockCorrections, i=>!i.sc_deleted && !i.sc_submitted && parseFloat(moment(i.sc_date).diff(moment().startOf('day'),'days')) === 0).length})`} icon={icons.due} />
                <StockTable data={_.filter(stockCorrections, i=>!i.sc_deleted && !i.sc_submitted && moment(i.sc_date).diff(moment().startOf('day'),'days') < 0)}  label={`Overdue (${_.filter(stockCorrections, i=>!i.sc_deleted && !i.sc_submitted && moment(i.sc_date).diff(moment().startOf('day'),'days') < 0).length})`} icon={icons.overdue} />
                <StockTable data={_.filter(stockCorrections, i=>i.sc_deleted)}    label={`Deleted (${_.filter(stockCorrections, i=>i.sc_deleted).length})`}   icon={icons.delete} />
                <StockTable data={_.filter(stockCorrections, i=>i.sc_submitted)}  label={`Completed (${_.filter(stockCorrections, i=>i.sc_submitted).length})`} icon={icons.tick} />
            </Grid>
        );
    }
}



export default StockCorrections;
