import DateFnsUtils from '@date-io/date-fns';
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    List, ListItem, ListItemSecondaryAction,
    ListItemText,
    TextField,
    Typography
} from '@material-ui/core';
import { DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import EmailEditor from 'react-email-editor';
import { connect } from 'react-redux';

import EmailTemplate from 'Assets/json/emailCampaignsTemplate.json';
import EmailEditorPreset from 'Assets/json/emailEditorPreset.json';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import InputHelper from 'Helpers/InputHelper';
import { clenyDate } from 'Helpers/TimeHelper';
import Legend from '../EmailTemplates/Legend';

import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import DragFileInputMulti from 'Components/Common/Inputs/DragFileInputMulti';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = props => ({
    formData: {
        ec_id:  !props.copy ? (props.ecId ?? null) : null,
        name: '',
        description: '',
        mailingLists: props.defaultMailingList ? [parseInt(props.defaultMailingList)] : [],
        sendingOptions: 'Manual',
        singleSendDateTime: null,
        sendingFrequency: 'Daily',
        sendingFrequencyData: null,
        sendingFrequencyDataSecondary: null,
        endCampaign: false,
        endCampaignData: null,

        fixedDateTime: null,

        emailSubject: '',
        emailDesign: null,
        emailHtml: null,

        template: 'No Template',

        attachments: null,
        existingAttachments: [],

        promotion: {
            include: false,
            code: null,
            type: null,
            name: null,
            description: null,
            showCode: false,
            type: null,
            start: moment().format('YYYY-MM-DD HH:mm:ss'),
            end: null,
        }
    },
    promotionFormData: {
        showCode: false,
        code: null,
        name: null,
        description: null,
        type: null,
        start: moment().format('YYYY-MM-DD HH:mm:ss'),
        end: null,
    },
    isUpdate: props.ecId && !props.copy ? true : false,
    isCopy: props.copy ? true : false,
    isLoading: true,
    lists: {
        mailingLists: [],
        templates: [],
        promotions: [],
    },
    emailPreviewDialogOpen: false,
    emailPreviewDialogData: {
        email: [props.loggedInStaff.email],
        type: 'staff'
    },
    campaign: null,
    emailEditorIsLoaded: false,
    ecId: (props.ecId || props.copy) || null,
    formErrors: {},
});

class NewEmailCampaignDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
        this.emailEditorRef = React.createRef()
        this.inputHelper = new InputHelper(this);
    }

    componentDidMount() {
        if (this.state.isUpdate || this.state.isCopy) {
            this.getCampaign();
        } else {
            this.getLists();
        }
    }

    getCampaign = () => {
        API.get(`/marketing/campaigns/emailCampaign/${this.props.ecId}`)
        .then((res) => { 

            let formData = this.state.formData;
            formData.name = res.data.ec_name;
            formData.description = res.data.ec_desc;
            formData.mailingLists = res.data.mailing_lists.map(list => list.ml_id);
            formData.existingAttachments = res.data.attachments;

            formData.emailSubject = res.data.ec_subject;
            formData.emailDesign = JSON.parse(res.data.ec_email_json);
            formData.emailHtml = res.data.ec_email_html;

            formData.sendingOptions = res.data.ec_sending_options;

            if (res?.data?.promotion) {
                formData.promotion = {
                    ...formData.promotion,
                    code: res?.data?.promotion?.p_code,
                    name: res?.data?.promotion?.p_name,
                    description: res?.data?.promotion?.p_desc,
                    type: res?.data?.promotion?.p_type,
                    showCode: false,
                    start: moment(res?.data?.promotion?.p_start).format('YYYY-MM-DD'),
                    end: moment(res?.data?.promotion?.p_end).format('YYYY-MM-DD'),
                    include: !res?.data?.promotion?.p_hidden,
                }
            } else {
                formData.promotion = {
                    ...formData.promotion,
                    include: false,
                }
            }

            this.setState({
                campaign: res.data,
                formData: {
                    ...this.state.formData,
                    ...formData,
                },
                promotionFormData: {
                    ...this.state.promotionFormData,
                    ...formData.promotion,
                }
            }, this.getLists);
        });
    }

    getLists = () => {
        Promise.all([
            API.get(`/marketing/campaigns/mailingLists`, {params:{status: 'Active'}}),
            API.get('/marketing/emailTemplates'),
            API.get('/staff/all', {params: {searchStatus: 'active'}}),
            API.get('/marketing/promotions')
        ]).then(([mailingLists, EmailTemplates, staff, promo]) => {
            this.setState({
                isLoading: false,
                lists: {
                    mailingLists: mailingLists.data,
                    templates: EmailTemplates.data,
                    staff: staff.data,
                    promotions: promo.data,
                }
            });
        })
    }

    handleChange = name => event => {
        const { formData } = this.state;
        formData[name] = event.target.value;
        this.setState({ formData });
    }

    addRemoveMailingList = (id, add) => {
        let { formData } = this.state;
        let mailingLists = formData.mailingLists;
        if (add) {
            mailingLists.push(id);
        } else {
            mailingLists = mailingLists.filter(list => list !== id);
        }
        formData.mailingLists = mailingLists;
        this.setState({ formData });
    }

    handleSelectChange = name => (e) => {
        const { formData } = this.state;
        formData[name] = e.value;
        this.setState({ formData });
    }

    handleDateChange = name => (e) => {
        const { formData } = this.state;
        formData[name] = e;
        this.setState({ formData });
    }

    showEmailPreviewDialog = () => {
        this.setState({ 
            emailPreviewDialogOpen: true,
            emailPreviewDialogData: initialState(this.props).emailPreviewDialogData,
        });
    }   

    closeEmailPreviewDialog = () => {
        this.setState({ emailPreviewDialogOpen: false });
    }

    sendEmailPreview = () => {
        this.emailEditorRef.current.editor.exportHtml((data) => {
            const {  html } = data;
            let formData = new FormData();
            formData.append('email', JSON.stringify(this.state.emailPreviewDialogData.email));
            formData.append('subject', `Preview - ${this.state.formData.emailSubject}` ?? 'Email Preview');
            formData.append('html', html);
            API.post(`/marketing/campaigns/emailCampaign/emailPreview`, formData)
                .then(response => {
                    this.closeEmailPreviewDialog();
                })
        });

    }

    EmailEditorOnReady = () => {
        // editor is ready
        // you can load your template here;
        // const templateJson = {};
        if (!this.state.emailEditorIsLoaded){
            if (this.state.formData.emailDesign) {
                this.emailEditorRef.current.editor.loadDesign(this.state.formData.emailDesign);
            } else {
                this.emailEditorRef.current.editor.loadDesign(EmailTemplate);
            }
            this.setState({ emailEditorIsLoaded: true });
        }
    };
    
    submitCampaign = () => {
        this.emailEditorRef.current.editor.exportHtml((emailData) => {
            let formData = this.state.formData;
            let _formData = new FormData();

            if (!emailData.html.includes('{%unsubscribe%}')){
                this.props.deployConfirmation(
                    'Email does not contain an unsubscribe link, please add one in using the tag {%unsubscribe%} and try again.',
                    'Missing Unsubscribe Link',
                    ()=>{},
                    'warning'
                )
                return;
            }

            _.map(_.keys(formData), key => {

                switch (key) {
                    case 'mailingLists':
                        _formData.append(key, JSON.stringify(this.state.formData[key]));
                        break;
                    case 'sendingFrequencyDataSecondary':
                        if (formData[key]){
                            _formData.append(key, moment(formData[key]).format('YYYY-MM-DD HH:mm:ss'));
                        }
                        break;
                    case 'sendingFrequencyData':
                        if (formData.sendingFrequency === 'Yearly'){
                            _formData.append(key, moment(formData[key]).format('YYYY-MM-DD HH:mm:ss'));
                        } else {
                            _formData.append(key, formData[key]);
                        }
                        break;
                    case 'endCampaignData':
                        if (formData.endCampaign === 'specificDate'){
                            _formData.append(key, moment(formData[key]).format('YYYY-MM-DD HH:mm:ss'));
                        } else {
                            _formData.append(key, formData[key]);
                        }
                        break;
                    case 'mailingLists':
                        _formData.append(key, JSON.stringify(this.state.formData[key]));
                        break;
                    case 'sendingFrequencyDataSecondary':
                        if (formData[key]){
                            _formData.append(key, moment(formData[key]).format('YYYY-MM-DD HH:mm:ss'));
                        }
                        break;
                    case 'attachments':
                        if (formData[key]){
                            _.each(formData[key], (file) => { _formData.append('attachments[]', file); });
                        }
                        break;
                    case 'existingAttachments':
                        if (formData[key]){
                            _formData.append(key, JSON.stringify(_.map(formData[key], i => i.eca_id)));
                        }
                        break;
                    case 'promotion':
                        _formData.append(key, JSON.stringify(
                            {...this.state.formData[key],
                                code: this.state.formData[key].code ? this.state.formData[key].code.toUpperCase() : this.state.formData.name.replaceAll(' ','').toUpperCase(),
                                name: this.state.formData[key].name ? this.state.formData[key].name : this.state.formData.name,
                                description: this.state.formData[key].description ? this.state.formData[key].description : this.state.formData.description,
                            }
                        ));
                        break;
                    default:
                        _formData.append(key, this.state.formData[key]);
                        break;
                }
            })
            const { html, design } = emailData;
            _formData.append('emailHtml', html);
            _formData.append('emailDesign', JSON.stringify(design));

            API.post(`/marketing/campaigns/emailCampaign`, _formData)
            .then(res => {
                if (res.data.errors) {
                    this.setState({formErrors: formatValidationErrors(res.data.errors)});
                } else {
                    //send preview email on success
                    this.setState({
                        emailPreviewDialogData: initialState(this.props).emailPreviewDialogData,
                    }, () => {
                        //this.sendEmailPreview();
                        this.props.onClose(res.data.success);
                    })
                }
            })
        });
    }

    handleSetTemplate = (template) => {
        if (template.value !== 'No Template'){
            this.emailEditorRef.current.editor.loadDesign(template.json);
        } else {
            this.emailEditorRef.current.editor.loadDesign(EmailTemplate);
        }
        this.setState({
            formData: {
                ...this.state.formData,
                template: template.value,
            }
        })
    }

    handleAddFixedDateTime = () => {
        const { formData } = this.state;
        let sendingFrequencyData = JSON.parse(formData.sendingFrequencyData);
        if (!_.isArray(sendingFrequencyData)){
            sendingFrequencyData = [];
        }
        sendingFrequencyData.push(moment(formData.fixedDateTime).format('YYYY-MM-DD HH:mm:ss'));
        sendingFrequencyData = _.orderBy(sendingFrequencyData, (date) => moment(date).unix(), 'asc');
        formData.sendingFrequencyData = JSON.stringify(sendingFrequencyData);
        formData.fixedDateTime = null;
        this.setState({ formData });
    }

    handleDeleteFixedDateTime = (index) => {
        const { formData } = this.state;
        let sendingFrequencyData = JSON.parse(formData.sendingFrequencyData);
        sendingFrequencyData.splice(index, 1);
        formData.sendingFrequencyData = JSON.stringify(sendingFrequencyData);
        this.setState({ formData });
    }


    addMailingList = row => {
        this.setState({
            formData: {
                ...this.state.formData,
                mailingLists: [...this.state.formData.mailingLists, row.ml_id]
            }
        })
    }

    removeMailingList = row => {
        this.setState({
            formData: {
                ...this.state.formData,
                mailingLists: this.state.formData.mailingLists.filter(list => list !== row.ml_id)
            }
        })
    }

    render() {
        const { formData, isLoading, lists, emailPreviewDialogOpen, emailPreviewDialogData, isUpdate, campaign, formErrors, promotionFormData } = this.state;

        if (isLoading) {
            return (
                <LoadingCircle />
            )
        }

        return (
            <Grid container spacing={1} style={{ marginTop: '0.5em', backgroundColor: colors.white }}>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h6">Campaign Details</Typography>
                                <TextField
                                    label="Campaign Name *"
                                    value={formData.name}
                                    name='formData.name'
                                    onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                    fullWidth
                                />
                                <Textarea
                                    label="Campaign Description"
                                    value={formData.description}
                                    name='formData.description'
                                    onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                    rows={4}
                                />
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Grid container spacing={2} alignItems='flex-end'>
                                    <Grid item xs={12} style={{paddingBottom: 0}}>
                                        <Typography variant="h6">Campaign Attachments</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{paddingTop: 0}}>
                                        <DragFileInputMulti
                                            label="Attachments (Max Upload: 20MB)"
                                            file={formData.attachments}
                                            name='formData.attachments'
                                            onChange={this.inputHelper.handleMultiFileChange('formData.attachments')}
                                            cancelOnClick={this.inputHelper.handleMultiClearFile('formData.attachments')}
                                            fullWidth
                                            error={formErrors && formErrors['file'] && true}
                                            errorText={formErrors && formErrors['file']}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="caption">Note: Attachments are sent with uploaded file name</Typography>
                                    </Grid>
                                    {formData.existingAttachments.length > 0 &&
                                        <Grid item xs={12}>
                                            Already Attached:
                                            <List>
                                                {_.map(formData.existingAttachments, (a, idx) => 
                                                    <ListItem>
                                                        <ListItemText primary={_.last(a.eca_file.split('/'))} />
                                                        <ListItemSecondaryAction>
                                                            <AllIcon 
                                                                icon={IconHelper.bin} 
                                                                noMargin 
                                                                onClick={this.inputHelper.handleRemoveArrayIndex('formData.existingAttachments', idx)}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>    
                                                )}
                                            </List>
                                        </Grid>
                                    }
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Grid container spacing={2} alignItems='flex-end'>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Mailing Behavior</Typography>
                                    </Grid>
                                    {<>
                                        <Grid item xs={12}>
                                            <AutoCompleteSelect
                                                label="Sending Options *"
                                                options={[
                                                    { label: 'Manual', value: 'Manual' },
                                                    { label: 'Scheduled (Repeating)', value: 'Scheduled (Repeating)' },
                                                    { label: 'Scheduled (Fixed)', value: 'Scheduled (Fixed)' },
                                                ]}
                                                value={formData.sendingOptions}
                                                onChange={this.inputHelper.handleSelectChange('formData.sendingOptions')}
                                                fullWidth
                                                noClear
                                            />
                                        </Grid>
                                        {
                                            formData.sendingOptions === 'Scheduled (Fixed)' &&
                                            <>
                                                <Grid item xs={5}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DatePicker
                                                            fullWidth
                                                            label={`Date *`}
                                                            value={formData.fixedDateTime}
                                                            onChange={this.inputHelper.handleDateChange('formData.fixedDateTime')}
                                                            format={"dd/MM/yyyy"}
                                                            autoOk={true}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardTimePicker
                                                            label="Time *"
                                                            value={formData.fixedDateTime}
                                                            onChange={this.inputHelper.handleDateTimeChange('formData.fixedDateTime')}
                                                            fullWidth
                                                            ampm={false}
                                                            autoOk={true}
                                                            format='HH:mm'
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item={2}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleAddFixedDateTime}
                                                    >Add</Button>
                                                </Grid>
                                                {
                                                    formData.sendingFrequencyData &&
                                                    formData.sendingFrequencyData.length > 0 &&
                                                        <Grid item xs={12}>
                                                            <List>
                                                                {
                                                                    _.map(JSON.parse(formData.sendingFrequencyData), (f, index) => 
                                                                        <ListItem key={index}>
                                                                            <ListItemText primary={clenyDate(f,1)} />
                                                                            <ListItemSecondaryAction>
                                                                                <AllIcon
                                                                                    icon={IconHelper.delete}
                                                                                    onClick={() => this.handleDeleteFixedDateTime(index)}
                                                                                    noMargin
                                                                                />
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    )   
                                                                }
                                                            </List>
                                                        </Grid>
                                                }
                                            </>
                                        }
                                        {
                                            formData.sendingOptions === 'Scheduled (Repeating)' &&
                                            <>
                                                <Grid item xs={12}>
                                                    <AutoCompleteSelect
                                                        label="Frequency *"
                                                        options={[
                                                            {
                                                                label: 'Daily',
                                                                value: 'Daily',
                                                            },
                                                            {
                                                                label: 'Weekly',
                                                                value: 'Weekly',
                                                            },
                                                            {
                                                                label: 'Monthly',
                                                                value: 'Monthly',
                                                            },
                                                            {
                                                                label: 'Yearly',
                                                                value: 'Yearly',
                                                            }
                                                        ]}
                                                        value={formData.sendingFrequency}
                                                        onChange={this.inputHelper.handleSelectChange('formData.sendingFrequency')}
                                                        fullWidth
                                                        noClear
    
                                                    />
                                                </Grid>
                                                {
                                                    formData.sendingFrequency === 'Yearly' &&
                                                    <Grid item xs={12}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <DatePicker
                                                                fullWidth
                                                                label={`Day of the Year *`}
                                                                value={formData.sendingFrequencyData}
                                                                onChange={this.inputHelper.handleDateChange('formData.sendingFrequencyData')}
                                                                format={"dd/MM/yyyy"}
                                                                autoOk={true}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                }
                                                {
                                                    formData.sendingFrequency === 'Monthly' &&
                                                    <Grid item xs={12}>
                                                        <AutoCompleteSelect
                                                            label="Day of the Month *"
                                                            options={[
                                                                {
                                                                    label: 'Last Day Of Month',
                                                                    value: 'Last Day Of Month',
                                                                },
                                                                {
                                                                    label: 'Last Friday Of Month',
                                                                    value: 'Last Friday Of Month',
                                                                },
                                                                ..._.range(1, 29).map(day => ({
                                                                    label: day,
                                                                    value: day,
                                                                }))
                                                            ]}
                                                            value={formData.sendingFrequencyData}
                                                            onChange={this.inputHelper.handleSelectChange('formData.sendingFrequencyData')}
                                                            fullWidth
                                                            noClear
        
                                                        />
                                                    </Grid>
                                                }
                                                {
                                                    formData.sendingFrequency === 'Weekly' &&
                                                    <Grid item xs={12}>
                                                        <AutoCompleteSelect
                                                            label="Day of the Week *"
                                                            options={[
                                                                {
                                                                    label: 'Monday',
                                                                    value: 'Monday',
                                                                },
                                                                {
                                                                    label: 'Tuesday',
                                                                    value: 'Tuesday',
                                                                },
                                                                {
                                                                    label: 'Wednesday',
                                                                    value: 'Wednesday',
                                                                },
                                                                {
                                                                    label: 'Thursday',
                                                                    value: 'Thursday',
                                                                },
                                                                {
                                                                    label: 'Friday',
                                                                    value: 'Friday',
                                                                },
                                                                {
                                                                    label: 'Saturday',
                                                                    value: 'Saturday',
                                                                },
                                                                {
                                                                    label: 'Sunday',
                                                                    value: 'Sunday',
                                                                },
                                                            ]}
                                                            value={formData.sendingFrequencyData}
                                                            onChange={this.inputHelper.handleSelectChange('formData.sendingFrequencyData')}
                                                            fullWidth
                                                            noClear
        
                                                        />
                                                    </Grid>
                                                }
                                                {
                                                    formData.sendingFrequency !== 'Custom' &&
                                                    <Grid item xs={12}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardTimePicker
                                                                label="Time *"
                                                                value={formData.sendingFrequencyDataSecondary}
                                                                onChange={this.inputHelper.handleDateTimeChange('formData.sendingFrequencyDataSecondary')}
                                                                fullWidth
                                                                ampm={false}
                                                                autoOk={true}
            
                                                                format='HH:mm'
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                }
                                            </>
                                        }
                                    </>}
                                    {isUpdate && <>
                                        <Grid item xs={12}>
                                            <Typography variant="caption">Please Note: Scheduled emails will be reset when campaign is amended</Typography>
                                        </Grid>
                                    </>}
                                    {
                                        formData.sendingOptions === 'Scheduled (Repeating)' &&
                                        <Grid item xs={12}>
                                            <AutoCompleteSelect
                                                label="End Campaign *"
                                                options={[
                                                    {
                                                        label: 'Never',
                                                        value: 'never',
                                                    },
                                                    {
                                                        label: 'After a number of sends',
                                                        value: 'numberSends',
                                                    },
                                                    {
                                                        label: 'On a specific date',
                                                        value: 'specificDate',
                                                    }
                                                ]}
                                                value={formData.endCampaign}
                                                onChange={this.inputHelper.handleSelectChange('formData.endCampaign')}
                                                fullWidth
                                                noClear
                                            />
                                        </Grid>
                                    }
                                    {
                                        formData.endCampaign === 'numberSends' &&
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Number of sends *"
                                                value={formData.endCampaignData}
                                                name='formData.endCampaignData'
                                                onChange={this.inputHelper.handleNumberInputChange}
                                                fullWidth
                                                type='number'
                                                inputProps={{
                                                    min: isUpdate ? (campaign.ec_run_count + 1) : 1,
                                                }}
                                            />
                                        </Grid>
                                    }
                                    {
                                        formData.endCampaign === 'specificDate' &&
                                        <Grid item xs={12}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    fullWidth
                                                    label={`End Date *`}
                                                    value={formData.endCampaignData}
                                                    onChange={this.inputHelper.handleDateChange('formData.endCampaignData')}
                                                    format={"dd/MM/yyyy"}
                                                    autoOk={true}
                                                    disablePast
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    }
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        {true &&
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Grid container spacing={2} style={{alignItems: 'center'}}>
                                        <Grid item xs={6}>
                                            <Typography variant="h6">Promotion</Typography>
                                        </Grid>
                                        <Grid item style={{marginLeft: 'auto'}}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        disabled={!formData?.name}
                                                        id="formData.promotion.include"
                                                        checked={formData.promotion.include} 
                                                        onChange={()=>{
                                                            this.setState({
                                                                formData: {
                                                                    ...this.state.formData,
                                                                    promotion: {
                                                                        ...this.state.formData.promotion,
                                                                        include: !formData.promotion.include
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                        value={formData.promotion.include}
                                                        color="primary"
                                                    />
                                                }
                                                label="Include Promotion"
                                            />
                                        </Grid>
                                        {!!formData.promotion.include &&
                                            <>
                                                <Grid item xs={11} >
                                                    <Box style={{ border: `1px dashed ${colors.subText}`}}>
                                                        <Typography variant="body2"
                                                            style={{fontWeight: 'bold', textAlign: 'center', padding: '0.5em'}}
                                                        >
                                                            {formData?.promotion?.code ? formData?.promotion?.code?.toUpperCase() : formData?.name?.replaceAll(' ','').toUpperCase()}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item style={{marginLeft: 'auto'}}>
                                                    <AllIcon 
                                                        icon={IconHelper.edit}
                                                        onClick={this.inputHelper.handleButtonChange('formData.promotion.showCode', 1)}
                                                        noMargin
                                                        noPadding
                                                        disabled={!formData?.name}
                                                    />
                                                </Grid>
                                                {_.find(_.filter(lists.promotions, p => this.state.isUpdate ? p.p_id !== this.state.campaign.promotion.p_id : true), p => p.p_code.toUpperCase() === (formData?.promotion?.code ? formData?.promotion?.code?.toUpperCase() : formData?.name?.replaceAll(' ','').toUpperCase())) &&
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption" style={{color: colors.red}}>
                                                            This code is already in use, please change it *
                                                        </Typography>
                                                    </Grid>
                                                }

                                            </>
                                        }
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        }
                        {!!formData.promotion.showCode &&
                            <Dialog
                                open={formData.promotion.showCode}
                                fullWidth
                                maxWidth='sm'
                            >
                                <DialogTitle>
                                    Promotion Details
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Name *"
                                                value={promotionFormData.name}
                                                name='promotionFormData.name'
                                                onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                                fullWidth
                                                placeholder={formData?.name}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AutoCompleteSelect
                                                label="Promotion Type"
                                                creatable
                                                options={_.map(_.uniqBy(lists.promotions, p => p.p_type), p => ({value: p.p_type, label: p.p_type}))}
                                                value={promotionFormData?.type}
                                                fullWidth
                                                onChange={this.inputHelper.handleSelectChange('promotionFormData.type')}
                                                helperText='leave blank for uncategorised'
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                label="Code *"
                                                value={promotionFormData.code}
                                                name='promotionFormData.code'
                                                onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                                fullWidth
                                                placeholder={formData?.name?.replaceAll(' ','').toUpperCase()}
                                                inputProps={{
                                                    style:{textTransform: 'uppercase', fontWeight: 'bold'}
                                                }}
                                                helperText={
                                                    _.find(_.filter(lists.promotions, p => this.state.isUpdate ? p.p_id !== this.state.campaign.promotion.p_id : true), p => p.p_code.toUpperCase() === (promotionFormData?.code ? promotionFormData?.code?.toUpperCase() : formData?.name?.replaceAll(' ','').toUpperCase())) &&
                                                    'This code is already in use, please change it *'
                                                }
                                                error={_.find(_.filter(lists.promotions, p => this.state.isUpdate ? p.p_id !== this.state.campaign.promotion.p_id : true), p => p.p_code.toUpperCase() === (promotionFormData?.code ? promotionFormData?.code?.toUpperCase() : formData?.name?.replaceAll(' ','').toUpperCase()))}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Textarea
                                                label="Description"
                                                value={promotionFormData.description}
                                                name='promotionFormData.description'
                                                onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                                rows={4}
                                                placeholder={formData?.description}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    fullWidth
                                                    label={`Start Date *`}
                                                    value={promotionFormData.start}
                                                    onChange={this.inputHelper.handleDateChange('promotionFormData.start')}
                                                    format={"dd/MM/yyyy"}
                                                    autoOk={true}
                                                    disablePast
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    fullWidth
                                                    label={`End Date`}
                                                    value={promotionFormData.end}
                                                    onChange={this.inputHelper.handleDateChange('promotionFormData.end')}
                                                    format={"dd/MM/yyyy"}
                                                    autoOk={true}
                                                    disablePast
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                </DialogContent>    
                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        onClick={this.inputHelper.handleButtonChange('formData.promotion.showCode', 0)}
                                    >Close</Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.inputHelper.handleButtonChange('formData.promotion', {...promotionFormData, showCode: 0, include: 1})}
                                    >Save</Button>
                                </DialogActions>
                            </Dialog>
                        }
{/*
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography
                                    variant="h6"
                                >Mailing Lists</Typography>
                                <List>
                                    {lists.mailingLists.map(list =>
                                        <ListItem>
                                            <ListItemText>
                                                {list.ml_name}
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    color='primary'
                                                    checked={formData.mailingLists.includes(list.ml_id)}
                                                    onChange={() => this.addRemoveMailingList(list.ml_id, !!!formData.mailingLists.includes(list.ml_id))}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </List>
                            </PaddedPaper>
                        </Grid>
*/}
                    </Grid>
                </Grid>
                <Grid item xs={8} style={{height: '100%'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <PaddedPaper  style={{height: '100%'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Typography variant="h6">Email Layout</Typography>
                                    </Grid>
                                    <Grid item style={{marginLeft: 'auto'}}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.showEmailPreviewDialog}
                                        >
                                            Email Preview
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Email's Subject *"
                                            value={formData.emailSubject}
                                            onChange={this.handleChange('emailSubject')}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AutoCompleteSelect
                                            label="Templates"
                                            onChange={this.handleSetTemplate}
                                            fullWidth
                                            noClear
                                            disableSort
                                            value={formData.template}
                                            options={[
                                                { value: 'No Template', label: 'No Template' },
                                                ...lists.templates.map(template => ({ value: template.met_id, label: template.met_name, json: JSON.parse(template.met_json) }))
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12}> <Legend /> </Grid>
                                    <Grid item xs={12}>
                                        <EmailEditor 
                                            ref={this.emailEditorRef}
                                            onReady={this.EmailEditorOnReady}
                                            tools={
                                                EmailEditorPreset.tools
                                            }
                                            fonts={
                                                EmailEditorPreset.fonts
                                            }
                                            style={{minHeight: 535}}
                                        />
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}
                    style={{marginTop: '0.7em'}}
                >
                    <Grid container spacing={2}>
                    <Grid item xs={6}>
                            <PaddedPaper>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">All Mailing Lists</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CiDataTable
                                            config={{
                                                key:'ml_id',
                                                pagination: lists.mailingLists.filter(list => !formData.mailingLists.includes(list.ml_id)).length > 25,
                                                rowsPerPage: 25,
                                                filter: true,
                                                filterMod: {
                                                    clearable: true,
                                                }
                                            }}
                                            rows={_.orderBy(lists.mailingLists.filter(list => !formData.mailingLists.includes(list.ml_id)), 'rans_count', 'desc')}
                                            columns={[
                                                {
                                                    heading: 'Mailing List',
                                                    field: 'ml_name',
                                                    truncate: true,
                                                    filter:         'custom',
                                                    filterMod: {
                                                        dataRef:   (search, rowData) => {return `${rowData.ml_name}|${rowData.ml_description}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                                        label:     'Search Mailing Lists'
                                                    }
                                                },
                                                {
                                                    heading: 'contacts',
                                                    field: 'active_contacts_count',
                                                    sizeToContent: true,
                                                    alignment: 'center',
                                                }, 
                                                {
                                                    heading: 'Campaigns',
                                                    field: 'campaigns_count',
                                                    sizeToContent: true,
                                                    alignment: 'center'
                                                },
                                                {
                                                    heading: 'Runs',
                                                    field: 'rans_count',
                                                    sizeToContent: true,
                                                    alignment: 'center'
                                                },
                                                {
                                                    heading: 'Last Run',
                                                    field: i => _.last(i.rans)?.ecr_datetime,
                                                    fieldFormat: 'clenydate',
                                                    sizeToContent: true,
                                                    alignment: 'center'
                                                },
                                                {
                                                    actions: i => ([
                                                        {
                                                            name: 'Add',
                                                            icon: IconHelper.plus,
                                                            onClick: this.addMailingList
                                                        }
                                                    ]),
                                                }
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={6}>
                            <PaddedPaper>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Mailing Lists To Be Added</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CiDataTable
                                            config={{
                                                key:'ml_id',
                                                pagination: lists.mailingLists.filter(list => formData.mailingLists.includes(list.ml_id)).length > 25,
                                                rowsPerPage: 25,
                                                totalStyle: {fontWeight: 'bold', textAlign: 'center'},   
                                            }}
                                            rows={lists.mailingLists.filter(list => formData.mailingLists.includes(list.ml_id))}
                                            columns={[
                                                {
                                                    heading: 'Mailing List',
                                                    field: 'ml_name',
                                                    truncate: true,
                                                },
                                                {
                                                    heading: 'Mailing Lists',
                                                    field: i=>'1',
                                                    totalLabel:  <Typography variant='body2' style={{textAlign:'right', fontWeight: 'bold'}}>{`${formData.mailingLists.length}`}</Typography>,
                                                    sizeToContent: true,
                                                    alignment: 'right'
                                                },
                                                {
                                                    heading: 'contacts',
                                                    field: 'active_contacts_count',
                                                    totalRef: 'active_contacts_count',
                                                    sizeToContent: true,
                                                    alignment: 'right'
                                                }, 
                                                {
                                                    heading: 'Campaigns',
                                                    field: 'campaigns_count',
                                                    totalRef: 'campaigns_count',
                                                    sizeToContent: true,
                                                    alignment: 'right'
                                                },
                                                {
                                                    heading: 'Runs',
                                                    field: 'rans_count',
                                                    totalRef: 'rans_count',
                                                    sizeToContent: true,
                                                    alignment: 'right'
                                                },
                                                {
                                                    heading: 'Last Run',
                                                    field: i => _.last(i.rans)?.ecr_datetime,
                                                    fieldFormat: 'clenydate',
                                                    sizeToContent: true,
                                                    alignment: 'right'
                                                },
                                                {
                                                    actions: i => ([
                                                        {
                                                            name: 'Remove',
                                                            icon: IconHelper.minus,
                                                            onClick: this.removeMailingList
                                                        }
                                                    ]),
                                                }
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='buttonRow' >
                    <Button
                        variant="outlined"
                        onClick={()=>this.props.onClose()}
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            !formData.name ||
                            !formData.sendingOptions ||
                            (
                                formData.sendingOptions === 'Scheduled (Repeating)' &&
                                (
                                    !formData.sendingFrequency ||
                                    (formData.sendingFrequency !== 'Daily' && !formData.sendingFrequencyData) ||
                                    !formData.sendingFrequencyDataSecondary ||
                                    (
                                        formData.endCampaign !== 'never' &&
                                        (!formData.endCampaignData || (
                                                formData.endCampaign === 'numberSends' &&
                                                formData.endCampaignData < (isUpdate ? (campaign.ec_run_count + 1) : 1)
                                            )
                                        )
                                    )
                                )
                            ) ||
                            (
                                formData.sendingOptions === 'Scheduled (Fixed)' &&
                                (
                                    !formData.sendingFrequencyData ||
                                    formData.sendingFrequencyData.length < 1
                                )
                            ) ||
                            (
                                formData.sendingOptions === 'Scheduled (Single)' &&
                                !formData.singleSendDateTime
                            ) ||
                            formData.mailingLists.length < 1 ||
                            !formData.emailSubject ||
                            (formData.promotion.include && _.find(_.filter(lists.promotions, p => this.state.isUpdate ? p.p_id !== this.state.campaign.promotion.p_id : true), p => p.p_code.toUpperCase() === (formData?.promotion?.code ? formData?.promotion?.code?.toUpperCase() : formData?.name?.replaceAll(' ','').toUpperCase())))
                        }
                        onClick={
                            () => {
                                this.props.deployConfirmation(
                                    'Are you sure you want to create this campaign?',
                                    'Create Email Campaign',
                                    this.submitCampaign
                                )
                            }
                        }
                    >
                        {
                            isUpdate ?
                            'Update' : 'Create'
                        }
                    </Button>
                </Grid>
                {emailPreviewDialogOpen &&
                    <Dialog
                        open={emailPreviewDialogOpen}
                        fullWidth
                        maxWidth='sm'
                    >
                        <DialogTitle>
                            Email Preview
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={1} style={{alignItems: 'center'}}>
                                <Grid item xs={12}>
                                    <ButtonGroup
                                        fullWidth
                                    >
                                        <Button
                                            onClick={this.inputHelper.handleButtonChange('emailPreviewDialogData.type', 'staff')}
                                            variant={emailPreviewDialogData.type === 'staff' ? 'contained' : 'outlined'}
                                            color={emailPreviewDialogData.type === 'staff' ? 'primary' : 'default'}
                                        >Staff</Button>
                                        <Button
                                            onClick={this.inputHelper.handleButtonChange('emailPreviewDialogData.type', 'custom')}
                                            variant={emailPreviewDialogData.type === 'custom' ? 'contained' : 'outlined'}
                                            color={emailPreviewDialogData.type === 'custom' ? 'primary' : 'default'}
                                        >custom</Button>
                                    </ButtonGroup>
                                </Grid>
                                {emailPreviewDialogData.type === 'custom' &&
                                    <>
                                        <Grid item xs={11}>
                                            <TextField
                                                label="Email *"
                                                value={emailPreviewDialogData?.customerEmail}
                                                name='emailPreviewDialogData.customerEmail'
                                                onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <AllIcon
                                                noMargin
                                                icon={IconHelper.plus}
                                                onClick={() => {
                                                    this.inputHelper.handleArrayAddRemove('emailPreviewDialogData.email')(emailPreviewDialogData.customerEmail)
                                                    this.inputHelper.handleButtonChange('emailPreviewDialogData.customerEmail','')()
                                                }}
                                            />
                                        </Grid>
                                    </>
                                }   
                                {emailPreviewDialogData.type === 'staff' &&
                                    <Grid item xs={12}>
                                        <AutoCompleteSelect
                                            label="Staff *"
                                            options={_.map(_.filter(lists.staff, s =>!emailPreviewDialogData.email.includes(s.staff_email)), s => ({ label: `${s.staff_first_name} ${s.staff_last_name} (${s.staff_email})`, value: s.staff_email }))}
                                            noClear
                                            fullWidth
                                            onChange={e => this.inputHelper.handleArrayAddRemove('emailPreviewDialogData.email')(e.value)}
                                        />
                                    </Grid>
                                }
                                {
                                    emailPreviewDialogData.email.length > 0 &&
                                    <Grid item xs={12}>
                                        <List>
                                            {_.map(emailPreviewDialogData.email, e => 
                                                <ListItem
                                                    style={{
                                                        borderBottom: `1px solid ${colors.border}`,
                                                    }}
                                                >
                                                    <ListItemText primary={e} />
                                                    <ListItemSecondaryAction>
                                                        <AllIcon
                                                            icon={IconHelper.bin}
                                                            noMargin
                                                            onClick={() => this.inputHelper.handleArrayAddRemove('emailPreviewDialogData.email')(e)}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>    
                                            )}
                                        </List>
                                    </Grid>
                                }
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={this.closeEmailPreviewDialog}
                            >Close</Button>
                            <Button
                                variant="contained"
                                color='primary'
                                onClick={this.sendEmailPreview}
                                disabled={emailPreviewDialogData.email.length < 1}
                            >Send</Button>
                        </DialogActions>
                    </Dialog>
                }
            </Grid>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback, variant=null) => {
            dispatch(deployConfirmation(content, title, callback, null, variant))
        }
    }
}

function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewEmailCampaignDialog);