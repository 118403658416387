import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import API from '../../../API';
import _ from 'lodash';

const initialState = {
    initialFormData: {},
    formData: {
        autoUpdates: true,
        autoPartUpdates: false,
        autoDocumentUpdates: false
    },
    confirmationOpen: false,
    snackbarOpen: false
}

class UpdateStaffAutoUpdates extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount() {
        this.getComponentData();
    }
    getComponentData = () => {
        let initialFormData = {
            autoUpdates: true,
            autoPartUpdates: this.props.staffData.staff_auto_part_updates,
            autoDocumentUpdates: this.props.staffData.staff_auto_document_updates
        };
        this.setState({
            initialFormData,
            formData: initialFormData
        });
    }
    handleStaffAutoUpdates = e => {
        this.setState({
            formData:{
                ...this.state.formData,
                [e.target.name]: JSON.parse(e.target.value)
            }
        });
    }
    handleSnackbarClose = () => this.setState({ snackbarOpen: false });
    handleConfirmationClose = () => this.setState({ confirmationOpen: false });
    handleConfirmationOpen = () => this.setState({ confirmationOpen: true });
    handleConfirmationSuccess = () => this.setState({  confirmationOpen: false }, () => this.handleSubmit());
    handleSubmit = () => {
        API.put('/staff/' + this.props.staffId, this.state.formData)
        .then(result => {
            if(result.data){
                this.setState({
                    ...this.state,
                    initialFormData: this.state.formData,
                    snackbarOpen: true
                });
            }
            this.props.scrollToTop();
        });
    }
    render() {
        return(
            <React.Fragment>
                <FormControl component="fieldset">
                    <FormGroup
                        name="staffAutoUpdates"
                        onChange={this.handleStaffAutoUpdates}
                        style={{marginTop: 5}}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    value={!!!this.state.formData.autoPartUpdates}
                                    name="autoPartUpdates"
                                    color="primary" 
                                    checked={this.state.formData.autoPartUpdates ? true : false}
                                />
                            }
                            label="Part Updates"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={!!!this.state.formData.autoDocumentUpdates} 
                                    name="autoDocumentUpdates"
                                    color="primary"
                                    checked={this.state.formData.autoDocumentUpdates ? true : false}
                                />
                            }
                            label="Document Updates"
                            labelPlacement="end"
                        />
                    </FormGroup>
                </FormControl>
                <div className="buttonRow">
                    <Button 
                        onClick={this.handleConfirmationOpen}
                        variant="contained"
                        color="primary"
                        disabled={_.isEqual(this.state.initialFormData, this.state.formData)}
                        style={{float: 'right'}}
                    >
                        Update
                    </Button>
                </div>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Update Staff Member Auto Updates?" 
                    message="Are you sure you want to update this staff member's auto update settings?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message="The staff member's auto update settings were successfully updated"
                />
            </React.Fragment>
        );
    }
}

export default UpdateStaffAutoUpdates;