import React, { PureComponent } from 'react'
import moment                   from 'moment';

import { Grid, FormControl, IconButton } from '@material-ui/core/'

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import FASolidIcon        from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import TimePicker         from 'Components/Common/DatePickers/TimePicker';

class AccessTimesForm extends PureComponent {
    render() {
        const { idx, addressIdx, data, formErrors, handleTimeChange, handleDayChange, handleRemoveRow, update } = this.props;
        return(
            <Grid container item>
                <Grid item xs>  
                    <Grid container item spacing={3}>
                        <Grid item lg={4}>
                            <FormControl fullWidth>
                                <AutoCompleteSelect 
                                    options={[
                                    {value: 'Monday', label: 'Mon'},
                                    {value: 'Tuesday', label: 'Tue'},
                                    {value: 'Wednesday', label: 'Wed'},
                                    {value: 'Thursday', label: 'Thu'},
                                    {value: 'Friday', label: 'Fri'},
                                    {value: 'Saturday', label: 'Sat'},
                                    {value: 'Sunday', label: 'Sun'},
                                    ]} 
                                    label='Day *'
                                    value={data.day}
                                    onChange={handleDayChange(addressIdx, idx)}
                                    error={formErrors && formErrors['day' + idx] && true}
                                    errorText={formErrors && formErrors['day' + idx]}
                                    disableSort={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={4}>
                            <FormControl fullWidth>
                                <TimePicker 
                                    margin="normal"
                                    id="startTime"
                                    value={moment(data.startTime, 'HH:mm:ss').format()}
                                    onChange={handleTimeChange('startTime', addressIdx, idx)}
                                    error={formErrors && formErrors['startTime' + idx] && true}
                                    errorText={formErrors && formErrors['startTime' + idx]}
                                    autoOk={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={4}>
                            <FormControl fullWidth>
                                <TimePicker 
                                    margin="normal"
                                    id="endTime"
                                    name="endTime"
                                    value={moment(data.endTime, 'HH:mm:ss').format()}
                                    onChange={handleTimeChange('endTime', addressIdx, idx)}
                                    error={formErrors && formErrors['endTime' + idx] && true}
                                    errorText={formErrors && formErrors['endTime' + idx]}
                                    autoOk={true}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{paddingTop: 10}}>
                    <IconButton onClick={handleRemoveRow(addressIdx, idx)} disabled={!update ? (idx+1) === 1 : false} style={{position:'relative', paddingTop:13, left:10}}>
                        <FASolidIcon icon='trash-alt' noMargin button style={{color: ((idx+1) === 1 && !update) ? '#fefefe' : 'inherit', height:16}} />
                    </IconButton>
                </Grid>
            </Grid>
        );
    }
}

export default (AccessTimesForm);
