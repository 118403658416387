import _, { isSet } from 'lodash';
import API from 'API';
import moment from 'moment';
export default class InputHelper {
   /* constructor(state, setState, formData=null) {
        this._state = formData ? state[formData] : state;
        this._setState = setState;
        this.formData = formData;
    }*/

    constructor(obj, formData=null, onSetState=null) {        
        this.obj = obj;
        this.onSetState = onSetState;
        this.formData = formData;
    }

    updateState = (state) => {
        this._state = state;
    }

//handle input change

    handleNumberInputChange = async (event) => {
        let { name, value } = event.target;
        value = parseFloat(value);

        if ( _.isSet(event.target.min)){
            if (parseFloat(value) < parseFloat(event.target.min)){
                value = parseFloat(event.target.min)
            }
        }
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, parseFloat(value)));
    }

    handleTextInputChange = async (event) => {
        const { name, value } = event.target;
       this.#setNestedValue(this.#getState(), name, value, true);
    }

    handleButtonChange = (name, value) => async () => {
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, value));
    }

    handleSelectChange = name => async (event) => {
        let value = event ? event.value : null;
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, value));
    }

    handleMultiSelectChange = name => async (event) => {
        let value = event ? event.map(option => option.value) : [];
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, value));
    }

    handleValueChange = name => async (value) => {
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, value));
    }

    handleCheckboxChange = async (event) => {
        const { name } = event.target;
        let value = await this.#getNestedValue(_.clone(this.#getState()), name);
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, !value));
    }

    handleArrayAddRemove = name => async (value) => {
        //this will add/remove an item from array
        let arr = await this.#getNestedValue(_.clone(this.#getState()), name);
        if (arr.includes(value)){
            arr = arr.filter(item => item !== value);
        } else {
            arr.push(value);
        }
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, arr));
    }

    handleDateChange = name => async (date) => {
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name,  moment(date).format("YYYY-MM-DD")));
    }

    handleTimeChange = name => async (time) => {
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name,  moment(time).format("HH:mm:ss")));
    }

    handleDateTimeChange = name => async (dateTime) => {
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name,  moment(dateTime).format("YYYY-MM-DD HH:mm:ss")));
    }

    handleSetArrayIndex = (name, idx) => async (value) => {
        let arr = await this.#getNestedValue(_.clone(this.#getState()), name);
        arr[idx] = value;
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, arr));
    }

    handleRemoveArrayIndex = (name, idx) => async () => {
        let arr = await this.#getNestedValue(_.clone(this.#getState()), name);
        arr.splice(idx, 1);
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, arr));
    }

    handleAddArray = (name) => async (value) => {
        let arr = await this.#getNestedValue(_.clone(this.#getState()), name);
        arr.push(value);
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, arr));
    }

    handleFileChange = name => async (drop, _name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, file));
    }

    handleMultiFileChange = name => async (drop, _name, event) => {
        let newFiles = drop === true ? event.dataTransfer.files : event.target.files;
        let existingFiles = await this.#getNestedValue(_.clone(this.#getState()), name);
        if (!_.isArray(existingFiles)){existingFiles=[];}
        let allFiles = Array.prototype.slice.call(existingFiles).concat(Array.prototype.slice.call(newFiles))
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, allFiles));
    }

    handleClearFile = name => async () => {
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, null));
    }

    handleMultiClearFile = name => async (idx) => {
        let arr = await this.#getNestedValue(_.clone(this.#getState()), name);
        arr.splice(idx, 1);
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, arr));
    }


    handleCheck = name => async () => {
        let value = await this.#getNestedValue(_.clone(this.#getState()), name);
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, !value));
    }
    
    handleObjectMerge = (name) => async (_obj) => {
        let obj = await this.#getNestedValue(_.clone(this.#getState()), name);
        this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, {...obj, ..._obj}));

    }

//Page Changes

    handleSetState = async (obj) => {
        this.#setState(obj);
    }

    handleUnpackChange = async obj => {
        this.#setState({..._.clone(this.#getState()), ...obj});
    }
    
    handleSetAccess = async (accessArr) => {
        let access = {};
        _.each(accessArr, i => {
            access[i] = false;
        })
        this.obj.setState({access}, ()=>{
            Promise.all(_.map(accessArr, i => API.get(`/staff/my/access/check/${i}`)))
            .then(res => {
                _.each(res, (i,idx) => {
                    access[accessArr[idx]] = i.data.has_access ?? false;
                });
                this.obj.setState({access});
            })
        });
    }

    handleReactionChange = (name, after) => async (value) => {
        let _value = await this.#getValue(value);
        Promise.all([
            this.#setState(await this.#setNestedValue(_.clone(this.#getState()), name, _value))
        ]).then(after);
    }

    setNestedValue = async (path, value) => {
        if (this.formData){
            return {
                [this.formData] : await this.#setNestedValue(this.#getState(), path, value, false, false)
            }
        }
        return this.#setNestedValue(this.#getState(), path, value);
    }
//private
    #getValue = async (value) => {
        return (value?.target) ? value.target.value : 
            ( value?.value ? value.value : value )
        ;
    }

    #setNestedValue = async (obj, path, value, setState = false, firstLoad=true) => {
        let _obj = obj;
        if (path.indexOf('.') < 1) {
            _obj[path] = value;
        } else {
            const pList = path.split('.');
            const key = pList[0];
            if (!obj[key]){
                _obj[key] = {};
            }
            if (firstLoad && !this.formData){
                _obj = {
                    [key]: await this.#setNestedValue(obj[key], path.replace(`${key}.`, ''), value, false, false)
                }
            }
            _obj[key] = await this.#setNestedValue(obj[key], path.replace(`${key}.`, ''), value, false, false); 
        }
        if (setState){
            this.#setState(_obj);
        }
        return _obj;
    }

    #getNestedValue = async (obj, path) => {
        if (path.indexOf('.') < 1) {
            return obj[path];
        } else {
            const pList = path.split('.');
            const key = pList[0];
            if (!obj[key]){
                obj[key] = {};
            }
            return await this.#getNestedValue(obj[key], path.replace(`${key}.`, '')); 
        }
    }

    #setState = (state) => {
        if (this.formData){
            this.obj.setState({
                [this.formData]: state
            }, ()=>{if (this.onSetState) this.onSetState()})
        } else {
            this.obj.setState(state, ()=>{if (this.onSetState) this.onSetState()})
        }
        return;
    }

    #getState = () => {
        if (this.formData){
            return this.obj.state[this.formData];
        } else {
            return this.obj.state;
        }
    }

}