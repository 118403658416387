import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import API from '../../../API';
import _ from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import Button from '@material-ui/core/Button';
import { Link, Redirect } from 'react-router-dom';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import ImageWithError from '../../Common/ImageWithError/ImageWithError';
import { MAX_UPLOAD_LIMIT_SIZE } from './../../../Constants';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import BackButton from 'Components/Common/Buttons/BackButton';

const initialState = {
    formData: {
        libraryId: '',
        imageFile: '',
        imageFileRaw: '',
        image3DFileObj: '',
        image3DFileMtl: ''
    },
    category: '',
    categoryImage: '',
    noImageAvailable: false,
    disableImageFile: false,
    disableRawImageFile: false,
    disable3DImageFile: false,
    showImageFile: false,
    showRawImageFile: false,
    show3DImageFile: false,
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: false,
    initialLoading: true,
    redirect: false
}

class EditCategoryImage extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getCategoryImageFiles();
    }

    getCategoryImageFiles = () => {        
        API.get(`/library/categories/${this.props.match.params.id}/${this.props.match.params.ref}`)
        .then((result) =>  {
            if (result.data.errors) {
                this.setState({ redirect: true });
            } else {
                let categoryImage = _.find(result.data.files, {'library_type': 'Category Image'});
                this.setState({
                    formData: {
                        ...this.state.formData,
                        libraryId: categoryImage ? categoryImage.library_id : '',
                    },
                    category: result.data.category.library_cat_name,
                    categoryImage: categoryImage ? categoryImage.file : result.data.no_image,
                    noImageAvailable: categoryImage ? false : true,
                    showImageFile: _.find(result.data.files, {'library_type': 'Category Image'}) ? false : true,
                    showRawImageFile: _.find(result.data.files, {'library_type': 'Category Image File'}) ? false : true,
                    show3DImageFile: _.find(result.data.files, {'library_type': 'Category 3D Image File'}) ? false : true,
                    initialLoading: false
                });
            }
        })
    }

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            let newFormData = new FormData();
            Object.keys(this.state.formData).forEach(key => {
                newFormData.append(key, this.state.formData[key])  
            });
            API.post(`/library/categories/${this.props.match.params.id}/${this.props.match.params.ref}`, newFormData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...initialState,
                        snackbarOpen: true
                    });
                    this.getCategoryImageFiles();
                }
                this.props.scrollToTop();
            });
        });
    }

    handleCheckChange = name => event => {
        if(name === 'defaultImage'){
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: event.target.checked
                }
            })
        }
        else {
            this.setState({ [name]: event.target.checked });
        }
    };
    
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        });
    }

    clearDocument = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: initialState.formData.name
            }
        });
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }
       
    render() {
        const { formErrors, initialLoading } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/library/category/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Edit Category Image
                        </Typography>
                    </Grid>
                    {(initialLoading && (
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <LoadingCircle />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>                    
                    )) || (
                        <React.Fragment>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <div style={{textAlign:'center'}}>
                                            <Typography variant="h6" gutterBottom>
                                                {this.state.category}
                                            </Typography>
                                            <ImageWithError src={this.state.categoryImage} style={{height:200}} alt="Current" />
                                        </div>
                                        <Typography variant="h6">
                                            Image Files
                                        </Typography>
                                        {this.state.showImageFile &&
                                            <Grid container alignItems="center">
                                                <Grid item xs={2} lg={1} alignItems="center">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                            checked={this.state.disableImageFile}
                                                            onChange={this.handleCheckChange('disableImageFile')}
                                                            value="disableImageFile"
                                                            color="primary"
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={10} lg={11} alignItems="center">
                                                    <DragFileInput
                                                        id="imageFileInput"
                                                        name="imageFile"
                                                        label="Image File (.jpg, .tiff, .gif) *"
                                                        file={this.state.formData.imageFile}
                                                        errorText={formErrors && formErrors['imageFile']}
                                                        onChange={this.handleFileChange}
                                                        cancelOnClick={this.clearDocument('imageFile')}
                                                        emptyText='No file selected'
                                                        disabled={!this.state.disableImageFile}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        {this.state.showRawImageFile &&
                                            <Grid container alignItems="center">
                                                <Grid item xs={2} lg={1}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                            checked={this.state.disableRawImageFile}
                                                            onChange={this.handleCheckChange('disableRawImageFile')}
                                                            value="disableRawImageFile"
                                                            color="primary"
                                                            />
                                                        }
                                                    />    
                                                </Grid>
                                                <Grid item xs={10} lg={11}>
                                                    <DragFileInput
                                                        id="imageFileRawInput"
                                                        name="imageFileRaw"
                                                        label="Raw Image File (.psd, .ai) *"
                                                        file={this.state.formData.imageFileRaw}
                                                        errorText={formErrors && formErrors['imageFileRaw']}
                                                        onChange={this.handleFileChange}
                                                        cancelOnClick={this.clearDocument('imageFileRaw')}
                                                        emptyText='No file selected'
                                                        disabled={!this.state.disableRawImageFile}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        {this.state.show3DImageFile &&
                                            <Grid container alignItems="center">
                                                <Grid item xs={2} lg={1}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                            checked={this.state.disable3DImageFile}
                                                            onChange={this.handleCheckChange('disable3DImageFile')}
                                                            value="disable3DImageFile"
                                                            color="primary"
                                                            />
                                                        }
                                                    />    
                                                </Grid>
                                                <Grid item xs={10} lg={11}>
                                                    <DragFileInput
                                                        id="image3DFileObj"
                                                        name="image3DFileObj"
                                                        label="3D File (.obj) *"
                                                        file={this.state.formData.image3DFileObj}
                                                        errorText={formErrors && formErrors['image3DFileObj']}
                                                        onChange={this.handleFileChange}
                                                        cancelOnClick={this.clearDocument('image3DFileObj')}
                                                        emptyText='No file selected'
                                                        disabled={!this.state.disable3DImageFile}
                                                    />
                                                    <DragFileInput
                                                        id="image3DFileMtl"
                                                        name="image3DFileMtl"
                                                        label="3D File (.mtl) *"
                                                        file={this.state.formData.image3DFileMtl}
                                                        errorText={formErrors && formErrors['image3DFileMtl']}
                                                        onChange={this.handleFileChange}
                                                        cancelOnClick={this.clearDocument('image3DFileMtl')}
                                                        emptyText='No file selected'
                                                        disabled={!this.state.disable3DImageFile}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        {!this.state.showImageFile && !this.state.showRawImageFile && !this.state.show3DImageFile &&
                                            <Typography variant="body1" gutterBottom style={{textAlign:'center'}}>
                                                All files have been uploaded for this image
                                            </Typography>
                                        }
                                        <div className='buttonRow' style={{justifyContent: 'space-between', flexDirection: 'row', alignItems:'center'}}>
                                            <Typography variant="subtitle2" style={{flex: 1}}>
                                                <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                                            </Typography>
                                            <BackButton props={this.props} />
                                            {(this.state.showImageFile || this.state.showRawImageFile || this.state.show3DImageFile) &&
                                                <Button onClick={this.handleConfirmationOpen}
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={this.state.isLoading}
                                                    >
                                                    Update
                                                </Button>
                                            }
                                        </div>
                                        <ConfirmationDialog 
                                            open={this.state.confirmationOpen} 
                                            success={this.handleConfirmationSuccess} 
                                            close={this.handleConfirmationClose} 
                                            title="Update Image Files?" 
                                            message="Are you sure you want to update these image files?"
                                        />
                                        <SnackBar
                                            variant="success"
                                            anchorOriginVertical='bottom'
                                            anchorOriginHorizontal='right'
                                            open={this.state.snackbarOpen}
                                            onClose={this.handleSnackbarClose}
                                            message='You have successfully updated the image file(s)'
                                        />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            );
        }
    }
}

export default EditCategoryImage;
