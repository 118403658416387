import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import React,{ PureComponent } from 'react';

import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ReportCell from './../ReportCell';

import { Box, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import { getFiscalYearTimestamps } from 'Helpers/ReportsHelper';


const initialState = {
    reportData: {},
    isLoading:  true
}

const styles = theme => ({
    card: {
        color: '#000',
        backgroundColor: '#FFF',
        padding: '0'
    },
    row: {
        color: '#000',
        backgroundColor: '#FFF',
        '&:hover': {
            backgroundColor: '#FCFCFC',
            cursor: 'pointer'
        },
    },
    header: {
        background: ({title}) => title === 'Awaiting Invoice Payment' ? 'linear-gradient(90deg, #3D94D6 0%, rgb(38 175 241) 100%)' : 'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', 
        color: '#FFF'
    },
    legendWrapper: {
        padding: '0 1rem'
    },
    legendTitle: {
        marginBottom: '0.8rem'
        
    },
    legendItem: {
        display: 'grid',
        gridTemplateColumns: '30px 60px auto',
        marginBottom: '0.4rem'
    }

});

const WeekEndCell = ({data, field, hidePound, style, inverse}) => {
    let newData = {in:{[field]:null},out:{[field]:null}};
    newData.in[field] = data.sat.in[field] + data.sun.in[field];
    newData.out[field] = data.sat.out[field] + data.sun.out[field];
    return <ReportCell data={newData} field={field} style={style} hidePound={hidePound} inverse={inverse}/>
}

class SalesReportOverview extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount() { 
        //this.lazyLoadData('day')
        this.lazyLoadData('days')
        this.lazyLoadData('week')
        this.lazyLoadData('month')
        this.lazyLoadData('year')
        this.lazyLoadData('total')
    }

    lazyLoadData = (type) => {
        API.get(`/reports/salesReport`,{
                params:{
                    cid:this.props.cid, 
                    partId:this.props.partId, 
                    limitStatus: ['Awaiting Invoice Payment'],
                    limitTime: [type]
                }
            }
        ).then(res =>{
            this.setState({
                reportData: 
                    { 
                        ...this.state.reportData,
                        [type]: res.data['Awaiting Invoice Payment'][type]
                    },
                isLoading:  false
            });
        })
    }
/*
    getData = () => {
        Promise.all([ 
            API.get(`/reports/salesReport`,{
                params:{
                    cid:this.props.cid, 
                    partId:this.props.partId, 
                    limitStatus: ['Awaiting Invoice Payment'],
                    limitTime: ['day', 'days', 'week', 'month']
                }
            }
            ),
        ])
        .then( ([res]) => {
            this.setState({
                reportData: _.find(res.data, (i, title) => {return title == 'Awaiting Invoice Payment'}),
                isLoading:  false
            });
        })
    }
*/
    getLabels = () => {
        let year, month, week, day, fy = getFiscalYearTimestamps();
        day = moment().format('dddd Do of MMMM');
        week = moment().startOf('week').format('dddd Do of MMMM') + ' - ' + moment().endOf('week').format('dddd Do of MMMM');
        month = moment().startOf('month').format('dddd Do of MMMM') + ' - ' + moment().endOf('month').format('dddd Do of MMMM');
        year = fy.start.format('YYYY') + ' - ' + fy.end.format('YYYY');
        return {year, month, week, day}
    }


    render() {
        const {isLoading, reportData, ledgerData}   = this.state;
        const { classes}                            = this.props;
        const labels                                = this.getLabels();
        return (
            <>
                {isLoading ? <LoadingCircle/> : 
                    <Grid container xs={12}>
                        {reportData['days'] &&
                            _.map(reportData['days'], (i, day) =>
                                <>
                                    {(day != 'sun' && day != 'sat') &&
                                        <Grid xs={12} lg={12} item style={{padding: '0.5rem 0.5rem', overflowX: 'auto'}}>
                                            <Card className={classes.card} >
                                                <CardHeader
                                                    avatar={<AllIcon icon={ icons['day'] } size='medium' style={{color: '#fff'}} />}
                                                    title={moment(i.date).format('dddd Do of MMMM YYYY')}
                                                    titleTypographyProps={{variant:'h6'}}
                                                    className={classes.header}
                                                />
                                                <CardContent style={{padding:5}}>
                                                    <Table size="small" aria-label="a dense table" style={{color:'black'}}>
                                                        <TableHead style={{backgroundColor:'#f5f5f5'}}>
                                                            <TableRow>
                                                                <TableCell align='center'   style={{width:'10%', borderBottom: 'none',}}></TableCell>
                                                                <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Invoices</b></Typography></TableCell>
                                                                <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Qty</b></Typography></TableCell>
                                                                <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Stock</b></Typography></TableCell>
                                                                <TableCell align='center'   style={{width:'20%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Net</b></Typography></TableCell>
                                                                <TableCell align='center'   style={{width:'20%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Vat</b></Typography></TableCell>
                                                                <TableCell align='center'   style={{width:'20%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Gross</b></Typography></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow class={classes.row} sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
                                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  borderBottom: 0}}>
                                                                    <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                                        <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} primaryColor='#33dd33' />
                                                                        <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                            Invoiced
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                                        <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} swapOpacity secondaryColor={colors.red} />
                                                                        <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                            Credited
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                                        <AllIcon icon={'equals'} size='medium' style={{display: 'inline-block', paddingRight: '0.5rem', color: '#2d2d2d'}} />
                                                                        <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                            Total
                                                                        </Typography>
                                                                    </Box>
                                                                </TableCell>
                                                                <ReportCell data={i} field={'qty'}   style={{borderBottom: 0}} hidePound/>
                                                                <ReportCell data={i} field={'parts'} style={{borderBottom: 0}} hidePound forceFixed={3}/>
                                                                <ReportCell data={i} field={'partsStock'} style={{borderBottom: 0}} hidePound forceFixed={3} inverse/>
                                                                <ReportCell data={i} field={'net'}   style={{borderBottom: 0}} />
                                                                <ReportCell data={i} field={'vat'}   style={{borderBottom: 0}} />
                                                                <ReportCell data={i} field={'total'} style={{borderBottom: 0}} />
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    }
                                </>
                            )
                        }
                        {reportData['days'] &&
                            <Grid xs={12} item style={{padding: '0.5rem 0.5rem', overflowX: 'auto'}}>
                                <Card className={classes.card} >
                                    <CardHeader
                                        avatar={<AllIcon icon={ icons['day'] } size='medium' style={{color: '#fff'}} />}
                                        title={`Weekend (${moment(reportData['days'].sat.date).format('Do MMMM YYYY')} & ${moment(reportData['days'].sun.date).format('Do MMMM YYYY')})`}
                                        titleTypographyProps={{variant:'h6'}}
                                        className={classes.header}
                                    />
                                    <CardContent style={{padding:5}}>
                                        <Table size="small" aria-label="a dense table" style={{color:'black'}}>
                                            <TableHead style={{backgroundColor:'#f5f5f5'}}>
                                                <TableRow>
                                                    <TableCell align='center'   style={{width:'10%', borderBottom: 'none',}}></TableCell>
                                                    <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Invoices</b></Typography></TableCell>
                                                    <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Qty</b></Typography></TableCell>
                                                    <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Stock</b></Typography></TableCell>
                                                    <TableCell align='center'   style={{width:'20%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Net</b></Typography></TableCell>
                                                    <TableCell align='center'   style={{width:'20%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Vat</b></Typography></TableCell>
                                                    <TableCell align='center'   style={{width:'20%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Gross</b></Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow class={classes.row} sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
                                                    <TableCell align='center' style={{color:'black', display: 'flex', alignItems: 'center', flexDirection: 'column', paddingLeft: 15, borderBottom: 0}}>
                                                        <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                            <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo primaryColor='#33dd33' />
                                                        </Box>
                                                        <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                            <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo swapOpacity secondaryColor={colors.red} />
                                                        </Box>
                                                        <Box style={{display: 'flex',  alignItems: 'center', height: '32px'}}>
                                                            <AllIcon icon={'equals'} size='medium' style={{ color: '#2d2d2d'}} />
                                                        </Box>
                                                    </TableCell>
                                                    <WeekEndCell data={reportData['days']} field={'qty'}   style={{borderBottom: 0}}  hidePound/>
                                                    <WeekEndCell data={reportData['days']} field={'parts'} style={{borderBottom: 0}}  hidePound  forceFixed={3}/>
                                                    <WeekEndCell data={reportData['days']} field={'partsStock'} style={{borderBottom: 0}}  hidePound  forceFixed={3} inverse/>
                                                    <WeekEndCell data={reportData['days']} field={'net'}   style={{borderBottom: 0}} />
                                                    <WeekEndCell data={reportData['days']} field={'vat'}   style={{borderBottom: 0}} />
                                                    <WeekEndCell data={reportData['days']} field={'total'} style={{borderBottom: 0}} />
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </CardContent>
                                </Card>
                            </Grid>
                        }
                        <Grid item xs={12} style={{padding: '0.5rem 0.5rem'}}>
                            <Card className={classes.card} >
                                <CardHeader
                                    avatar={<AllIcon icon={ icons['sales'] } size='medium' style={{color: '#fff'}} />}
                                    title='Sales (Invoices, Recurring Invoices & Credit Notes)'
                                    titleTypographyProps={{variant:'h6'}}
                                    className={classes.header}
                                />
                                <CardContent style={{padding:0, paddingBottom: '1.5em'}}>
                                    <Grid container xs={12}>
                                        <Grid item xs={12} style={{padding: '0.8rem 1.2rem', overflowX: 'auto'}}>
                                            <Table size="small" aria-label="a dense table" style={{color:'black'}}>
                                                <TableHead style={{backgroundColor:'#f5f5f5'}}>
                                                    <TableRow>
                                                        <TableCell align='center'   style={{width:'3%', borderBottom: 'none',}}></TableCell>
                                                        <TableCell align='center'   style={{width:'7%', borderBottom: 'none', color:'black'}}></TableCell>
                                                        <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Invoices</b></Typography></TableCell>
                                                        <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Qty</b></Typography></TableCell>
                                                        <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Stock</b></Typography></TableCell>
                                                        <TableCell align='center'   style={{width:'20%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Net</b></Typography></TableCell>
                                                        <TableCell align='center'   style={{width:'20%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Vat</b></Typography></TableCell>
                                                        <TableCell align='center'   style={{width:'20%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Gross</b></Typography></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {_.map( _.orderBy(_.keys(reportData), name => ['week', 'month', 'year', 'total'].indexOf(name), 'asc'), (idx) => 
                                                        {
                                                            let i = reportData[idx];
                                                            return (
                                                                <>
                                                                    { idx != 'day' && idx != 'days' && 
                                                                        <TableRow class={classes.row} sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
                                                                            <TableCell align='center' style={{padding: '10px 10px 10px 15px', color:'black'}}>
                                                                                <AllIcon icon={icons[idx]} size='medium' style={{color: 'black'}} noMargin/>
                                                                                <Box style={{fontWeight: '600', color: '#3d3d3d', textTransform: 'capitalize'}}>
                                                                                    {idx}
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem'}}>
                                                                                <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                                                    <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} primaryColor='#33dd33' />
                                                                                    <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                                    Invoiced
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                                                    <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} swapOpacity secondaryColor={colors.red} />
                                                                                    <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                                        Credited
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                                                    <AllIcon icon={'equals'} size='medium' style={{display: 'inline-block', paddingRight: '0.5rem', color: '#2d2d2d'}} />
                                                                                    <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                                        Total
                                                                                    </Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <ReportCell data={i} field={'qty'}   hidePound/>
                                                                            <ReportCell data={i} field={'parts'} hidePound forceFixed={3}/>
                                                                            <ReportCell data={i} field={'partsStock'} hidePound forceFixed={3} inverse/>
                                                                            <ReportCell data={i} field={'net'}   />
                                                                            <ReportCell data={i} field={'vat'}   />
                                                                            <ReportCell data={i} field={'total'} />
                                                                        </TableRow>
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} style={{padding: '0.8rem 1.2rem', overflowX: 'auto'}}>
                                        <Grid item xs={12} >
                                            <Grid item container xs={12} className={classes.legendWrapper}>
                                                <Grid item xs={12}>
                                                    <Box className={classes.legendTitle}>
                                                        <Typography variant="h6" align="left" style={{fontWeight: '600'}}>Legend</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid container>
                                                    <Grid container item xs={12} >
                                                        <Grid item xs={12} lg={4}>
                                                            <Box className={classes.legendItem}>
                                                                <Box>
                                                                    <AllIcon icon={icons['week']} noMargin/>
                                                                </Box>
                                                                <Box style={{fontWeight: '600', color: '#3d3d3d'}}>
                                                                    Week
                                                                </Box>
                                                                <Box>
                                                                    {labels['week']}
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} lg={4}>
                                                            <Box className={classes.legendItem}>
                                                                <Box>
                                                                    <AllIcon icon={icons['month']} noMargin/>
                                                                </Box>
                                                                <Box style={{fontWeight: '600', color: '#3d3d3d'}}>
                                                                    Month
                                                                </Box>
                                                                <Box>
                                                                    {labels['month']}
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} lg={4}>
                                                            <Box className={classes.legendItem}>
                                                                <Box>
                                                                    <AllIcon icon={icons['year']} noMargin/>
                                                                </Box>
                                                                <Box style={{fontWeight: '600', color: '#3d3d3d'}}>
                                                                    Year
                                                                </Box>
                                                                <Box>
                                                                    {labels['year']}
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                }
            </>
        )
    }
};

export default withStyles(styles)(SalesReportOverview);

