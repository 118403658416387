import React, { PureComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import AutoCompleteSelect from '../../../Common/Selects/AutoCompleteSelect';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Textarea from '../../../Common/Inputs/Textarea';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DragFileInputMulti from '../../../Common/Inputs/DragFileInputMulti';
import ImageWithError from '../../../Common/ImageWithError/ImageWithError';
import IconButton from '@material-ui/core/IconButton';
import FALightIcon from '../../../Common/Icons/FontAwesome/FALightIcon';
import { Divider } from '@material-ui/core';
import PartImagePicker from '../../../Common/PartImagePicker/PartImagePicker';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {colors} from 'Helpers/ColourHelper';

class AffectedParts extends PureComponent {
    render(){
        const { sectionIdx, idx, partsList, data, formErrors, handlePartSelectChange, handlePartFields, handleDefaultImage, handleFilesIssued, handleRemovePart, handlePartDetails, handleImageUpload, clearImageUpload,
            handlePartImagePickerSelection, handleRemoveUploadedImage } = this.props;
        return(
            <React.Fragment>
                <Grid item xs={12} style={{marginTop:25}}>
                    <Divider style={{marginBottom:20}} />
                    <Typography variant="h6" style={{fontSize:'1.1rem'}}>Part {idx + 1}</Typography>
                </Grid>
                <Grid item xs={11}>
                    <FormControl fullWidth margin="normal">
                        <AutoCompleteSelect 
                            id="part"
                            name="part"
                            label="Affected Part *"
                            options={partsList}
                            value={data && data.id && data.id}
                            onChange={handlePartSelectChange(sectionIdx, idx)}
                            error={formErrors && formErrors['part'+sectionIdx+'|'+idx] && true}
                            errorText={formErrors && formErrors['part'+sectionIdx+'|'+idx]}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={1} style={{textAlign:'center'}}>
                    <IconButton onClick={() => handleRemovePart(sectionIdx, idx)} style={{position:'relative', top:34}} disabled={(idx+1) === 1}>
                        <FALightIcon icon="trash-alt" noMargin button style={{height:16, width:16, color: (idx+1) === 1 ? '#fefefe' : 'inherit'}}/>
                    </IconButton>
                </Grid>
                {data.id !== 0 && (
                    <Grid container>
                        <Grid container item spacing={3}>
                            <Grid item xs={6}>
                                <FormControl fullWidth margin="normal">
                                    <TextField 
                                        label="Part Number"
                                        value={data && data.number && data.number}
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth margin="normal">
                                    <TextField 
                                        label="Part Description"
                                        value={data && data.description && data.description}
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroup
                                name="fields"
                                style={{marginTop:5}}
                                row
                            >
                                <FormControlLabel
                                    value='new'
                                    control={<Radio color="primary" />}
                                    label="New"
                                    labelPlacement="end"
                                    onChange={handlePartFields(sectionIdx, idx)}
                                    checked={(data.fields === 'new') ? true : false}
                                    disabled={data.version > 1}
                                />
                                <FormControlLabel
                                    value='discontinued'
                                    control={<Radio color="primary" />}
                                    label="Discontinued"
                                    labelPlacement="end"
                                    onChange={handlePartFields(sectionIdx, idx)}
                                    checked={(data.fields === 'discontinued') ? true : false}
                                />
                                <FormControlLabel
                                    value='upIssued'
                                    control={<Radio color="primary" />}
                                    label="Up Issued"
                                    labelPlacement="end"
                                    onChange={handlePartFields(sectionIdx, idx)}
                                    checked={(data.fields === 'upIssued') ? true : false}
                                    disabled={data.version <= 1}
                                />
                                <FormControlLabel
                                    value='changed'
                                    control={<Radio color="primary" />}
                                    label="Changed"
                                    labelPlacement="end"
                                    onChange={handlePartFields(sectionIdx, idx)}
                                    checked={(data.fields === 'changed') ? true : false}
                                    disabled={data.version <= 1}
                                />
                            </RadioGroup>
                            {formErrors && formErrors['fields'+sectionIdx+'|'+idx] && 
                                <Typography component={"div"} style={{color: colors.red, fontSize:14}}>
                                    {formErrors['fields'+sectionIdx+'|'+idx]}
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" style={{marginTop:30, fontSize:'1.1rem'}}>Files Issued?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup
                                name="filesIssued"
                                onChange={handleFilesIssued(sectionIdx, idx)}
                                style={{marginTop:5}}
                                row
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value="pdf"
                                            color="primary"
                                            checked={data.filesIssued.pdf ? true : false}
                                        />
                                    }
                                    label="PDF"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value="design"
                                            color="primary"
                                            checked={data.filesIssued.design ? true : false}
                                        />
                                    }
                                    label="Design"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value="stl"
                                            color="primary"
                                            checked={data.filesIssued.stl ? true : false}
                                        />
                                    }
                                    label="STL"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                            {formErrors && formErrors['files'+sectionIdx+'|'+idx] && 
                                <Typography component={"div"} style={{color: colors.red, fontSize:14}}>
                                    {formErrors['files'+sectionIdx+'|'+idx]}
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Textarea
                                id="details"
                                name="details"
                                label="Details *"
                                value={data.details}
                                rows={2}
                                error={formErrors && formErrors['details'+sectionIdx+'|'+idx]}
                                onChange={handlePartDetails(sectionIdx, idx)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" style={{marginTop:30, fontSize:'1.1rem'}}>Use Default Image?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroup
                                name="useDefaultImage"
                                style={{marginTop:5}}
                                onChange={handleDefaultImage(sectionIdx, idx)}
                                row
                            >
                                <FormControlLabel
                                    value={true}
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="end"
                                    checked={data.useDefaultImage ? true : false}
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="end"
                                    checked={!data.useDefaultImage ? true : false}
                                />
                            </RadioGroup>
                            {formErrors && formErrors['useDefaultImage'+sectionIdx+'|'+idx] && 
                                <Typography component={"div"} style={{color: colors.red, fontSize:14}}>
                                    {formErrors['useDefaultImage'+sectionIdx+'|'+idx]}
                                </Typography>
                            }
                        </Grid>
                        {(!!data.useDefaultImage && (
                            <Grid item xs={12}>
                                <ImageWithError
                                    src={data.defaultImageURL}
                                    style={{maxWidth:200, marginTop:20}}
                                />
                            </Grid>
                        ))}
                        {!data.useDefaultImage && (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <PartImagePicker 
                                        id="partImagePicker"
                                        name="partImagePicker"
                                        label="Part Images:"
                                        idx={idx}
                                        sectionIdx={sectionIdx}
                                        partId={data.id}
                                        handlePartImagePickerSelection={handlePartImagePickerSelection}
                                        selectedPartImages={data.selectedPartImages}
                                        error={formErrors && formErrors['partImagePicker'+sectionIdx+'|'+idx] && true}
                                        errorText={formErrors && formErrors['partImagePicker'+sectionIdx+'|'+idx]}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{marginTop: 25}}>
                                    <Typography variant="body2" style={{textAlign:'center'}}>OR</Typography>
                                </Grid>
                                {data.uploadedImageURLs.length > 0 && (
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{fontSize: 12, color: 'rgba(0, 0, 0, 0.54)', marginBottom: 8}}>
                                                Uploaded Images:
                                            </Typography>
                                        </Grid>
                                        <Grid container item style={{display: 'flex', alignItems: 'center'}}>
                                            {data.uploadedImageURLs.map((url, urlIdx) => (
                                                <Grid item xs={2} key={urlIdx}>
                                                    <Card style={{marginRight:10, marginBottom: 10, position:'relative', padding:2}}>
                                                        <span style={{position:'absolute', top: 2, right: 2}}>
                                                            <IconButton onClick={() => handleRemoveUploadedImage(sectionIdx, idx, urlIdx)}>
                                                                <FALightIcon
                                                                    icon="trash-alt"
                                                                    button
                                                                    noMargin
                                                                    size="small"
                                                                />
                                                            </IconButton>
                                                        </span>
                                                        <CardContent style={{height: 120, display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                                                            <ImageWithError src={url} style={{maxWidth:'350px', maxHeight:'400px', overflow:'hidden', alignSelf: 'center'}} />
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </React.Fragment>
                                )}
                                <Grid item xs={12}>
                                    <DragFileInputMulti
                                        id="imgUpload"
                                        name="imgUpload[]"
                                        label="Upload Images (.jpg, .jpeg, .tiff, .gif)"
                                        file={data.imgUpload}
                                        type="image"
                                        error={formErrors && formErrors['imgUpload'+sectionIdx+'|'+idx] && true}
                                        errorText={formErrors && formErrors['imgUpload'+sectionIdx+'|'+idx]}
                                        onChange={handleImageUpload(sectionIdx, idx)}
                                        cancelOnClick={clearImageUpload(sectionIdx, idx)}
                                        emptyText='No image(s) uploaded'
                                    />
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                )}
            </React.Fragment>
        );
    }
}

export default AffectedParts;