import React from 'react';
import _ from 'lodash';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

class NominalCodeList extends React.Component {

    render() {
        const { codes } = this.props;
        return (
            _.map(codes, category => {
                return (
                    <ExpansionPanel key={category.nom_cat_id}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{category.nom_cat_name}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} >
                                    {(category.nominal_codes && category.nominal_codes.length > 0 && (
                                        <List>
                                            {_.map(category.nominal_codes, code => {
                                                return (
                                                    <ListItem key={code.nominal_id}>
                                                        <ListItemIcon>
                                                            <FALightIcon icon='level-up' rotation={90} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={`${code.nominal_code} - ${code.nominal_name}`} />
                                                        <ListItemSecondaryAction>
                                                            <Link to={`/accounts/nominal-codes/update/${code.nominal_id}`}>
                                                                <IconButton edge="end">
                                                                    <FALightIcon icon='pencil' button noMargin/>
                                                                </IconButton>
                                                            </Link>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                    )) || (
                                        <List>
                                            <ListItem>
                                                <ListItemText primary={<em>No nominal codes to display</em>} />
                                            </ListItem>
                                        </List>
                                    )}
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );
            })
        );
    }
}

export default NominalCodeList;