import React, { Component } from 'react';
import _                    from 'lodash';

import { Typography, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DataTable   from 'Components/Common/DataTables/DataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';

class SpareParts extends Component {

    render() {
        const { parts, originalPart } = this.props;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Available Spare Parts</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {parts && parts.length > 0 ? 
                                <React.Fragment>
                                    <br></br>
                                    <Typography variant="subtitle2" style={{display:'flex', alignItems: 'center'}}><FALightIcon icon='info-circle'/> Below is a list of available spare parts for {originalPart}</Typography>
                                    <br></br>
                                    <DataTable 
                                        config={{
                                            key: 'part_id',
                                            pagination: false,
                                            plainHeader: true
                                        }}
                                        columns={[
                                            {
                                                heading: 'Part Number',
                                                field: row => row.part_number,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Description',
                                                field: row => row.part_description
                                            },
                                            {
                                                heading: 'Version',
                                                field: row => row.latest_dco_details && row.latest_dco_details.dco_details_v_version ? 'v'+row.latest_dco_details.dco_details_v_version : '-',
                                            },
                                            {
                                                heading: 'Status',
                                                field: row => row.part_status
                                            },
                                            {
                                                heading: 'Current Price',
                                                field: row => row.default_price && row.default_price.price_total ? '£'+row.default_price.price_total : '-',
                                            },
                                            {
                                                heading: 'Current Stock',
                                                field: row => row.stock && row.stock.stock_current_qty ? row.stock.stock_current_qty : '-',
                                            },
                                            {
                                                actions: row => {
                                                    return [
                                                        {name: 'View', icon: 'search', link: '/parts/view/' + row.part_id}
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={_.sortBy(parts, function (row) { return row.part_number })}
                                    />
                                </React.Fragment>
                            :
                                <Typography>There are 0 parts found.</Typography>
                            }
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default SpareParts;
