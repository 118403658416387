import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import { parseRow } from 'Helpers/CsvHelper.js';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { deployDialog as _deployDialog, closeDialog as _closeDialog } from 'Actions/Dialog/Dialog';

export default function Manual({onSubmit, contacts}) {

    let dispatch = useDispatch();

    const deployDialog = (content, title, size, variant=null) => {
        dispatch(_deployDialog(content, title, variant, size))
    }
    const closeDialog = () => {
        dispatch(_closeDialog())
    }

    let csvUpload = e => {
        let data = e.target.result.split('\n');

        let head = data[0].replaceAll('\r','').split(',');
        let body = [];

        for (let i = 1; i < data.length; i++) {
            let row = parseRow(data[i]);
            let obj = {};
    
            for (let j = 0; j < row.length; j++) {
                obj[String(head[j].replaceAll('"','').replaceAll("'",''))] = row[j].replaceAll('"','').replaceAll("'",'');
            }
    
            body.push(obj);
        }

        if (head[0] !== 'Name' || head[1] !== 'Email' ){
            deployDialog(
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            The CSV file must contain the following columns in the following order: Name, Email.<br/> Please check your CSV file and try again.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant="contained"
                            color='secondary'
                            onClick={closeDialog}
                        >Ok</Button>
                    </Grid>
                </Grid>,
                'Invalid CSV File',
                'sm',
                'error'
            )
        } else if (body.length < 1){
            deployDialog(
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            The CSV file must contain at least one contact.<br/> Please check your CSV file and try again.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant="contained"
                            color='secondary'
                            onClick={closeDialog}
                        >Ok</Button>
                    </Grid>
                </Grid>,
                'Invalid CSV File',
                'sm',
                'error'
            )
        } else {
            onSubmit(_.map(_.filter(body, c => c?.Email), (c, idx) => {
                let _contact = _.find(contacts, {contact_email: c.Email.replaceAll('\r','').trim(), contact_name: c.Name.replaceAll('\r','').trim()});
                return {...(_contact ? _contact : {contact_id: 0, contact_email: c.Email.replaceAll('\r','').trim(), contact_name: c.Name.replaceAll('\r','').trim()}) ,idx};
            }))();
        }

    }

    let handleFileChange = async (drop, name, event) => {
        const csv = drop === true ? event.dataTransfer.files[0] : event.target.files[0];

        var reader = new FileReader();

        reader.onload = csvUpload;
        reader.readAsText(csv);
    }

    return (
        <Grid item xs={12}>
            <PaddedPaper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='body1' style={{fontWeight: 'bold', marginBottom: '1em'}}>Note: Uploaded CSV must have the following headings:</Typography>
                        <DataTable
                            config={{
                                plainHeader: true,
                                noHeader: true,
                            }}
                            columns={[
                                {
                                    field:   'c',
                                    sizeToContent: true,
                                    style: { fontWeight: 'bold', borderRight: `1px solid ${colors.disabled}` },
                                },
                                {
                                    field: 'n',
                                },
                            ]}
                            rows={[
                                {c: 'A1', n: 'Name' },
                                {c: 'B1', n: 'Email' },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DragFileInput
                            accept=".csv"
                            onChange={handleFileChange}
                            label='Upload CSV *'
                        />
                    </Grid>
                </Grid>
            </PaddedPaper>
        </Grid>
    )

}