import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import TaskColumn from 'Components/Tasks/Misc/TaskColumn';
import TaskDialog from 'Components/Tasks/Misc/TaskDialog';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { deployDialog } from 'Actions/Dialog/Dialog';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

/* Styles */
const styles = theme => ({
    columnNew: {
        color: '#000',
        backgroundColor: '#0288D1',
        padding: theme.spacing(3,3,0,3),
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
    },
    headerNew: {
        padding: theme.spacing(2, 3),
        color: '#fff',
        backgroundColor: '#0288D1',
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
    },
    columnInProgress: {
        color: '#000',
        backgroundColor: '#F57F17',
        padding: theme.spacing(3,3,0,3),
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
    },
    headerInProgress: {
        padding: theme.spacing(2, 3),
        color: '#fff',
        backgroundColor: '#F57F17',
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
    },
    columnCompleted: {
        color: '#000',
        backgroundColor: '#4CAF50',
        padding: theme.spacing(3,3,0,3),
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
    },
    headerCompleted: {
        padding: theme.spacing(2, 3),
        color: '#fff',
        backgroundColor: '#4CAF50',
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
    },
    columnWatched: {
        color: '#000',
        backgroundColor: '#607D8B',
        padding: theme.spacing(3,3,0,3),
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
    },
    headerWatched: {
        padding: theme.spacing(2, 3),
        backgroundColor: '#607D8B',
        color: '#fff',
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
    },
    icon: {
        width: 25, 
        height: 25
    }
});

/* Inital State */
const initialState = () => ({
    access: {
        staffSelector: false,
    },
    isLoading: true,
    searchString: '',
    staffId: false,
    staffList: [],
    status: 0,
    tasks: {}
});

/* Component */
class Tasks extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Tasks:all';
        this.state              = this.getInitialState(initialState());
    }                      

    componentDidMount = () => {
        !this.hasPageState() && this.loadComponentData();
    }
    loadComponentData = () => {
        if(this.props.match && this.props.match.params &&  this.props.match.params.id) {
            this.handleViewTask(this.props.match.params.id)
        }
        Promise.all([
            API.get('/staff/my/access/check/tasks:staff-selection'),
            API.get('/staff/all', 
                { 
                    params: { 
                        active: true
                    } 
                }
            )
        ])
        .then(([staffSelector, result]) => {
            if(result && result.data) {
                let staffList = _.map(result.data, staff => {
                    return _.assign({
                        value: staff.staff_id,
                        label: staff.staff_first_name + ' ' + staff.staff_last_name,
                    })
                });
                this.setState({
                    access: {
                        staffSelector: (staffSelector.data && staffSelector.data.has_access) || false
                    },
                    staffId: this.props.loggedInStaff ? this.props.loggedInStaff.id : '',
                    staffList,
                }, () => {
                    this.savePageState();
                    this.getTasks();
                })
            }
        })
    }


    componentDidUpdate = (prevProps) => {
        if(this.props.match && this.props.match.params && this.props.match.params.id) {
            if((!prevProps.match && !prevProps.match.params && !prevProps.match.params.id)
                || (this.props.match.params.id !== prevProps.match.params.id)) {
                    this.handleViewTask(this.props.match.params.id)
            }
        }
    }

    getTasks = (loadingBar = true) => {
        const cancellation = true;
        API.get(`/tasks`, 
            {
                params: {
                    id: this.state.staffId,
                    status: this.state.status,
                    searchString: this.state.searchString
                },
                props: {
                    loadingBar,
                    cancellation
                },
            }
        )
        .then(result => {
            if(result?.data) {
                this.setState({
                    isLoading: false,
                    tasks: result.data
                }, ()=>this.savePageState())
            }
        })
    }

    delayGetTasks = _.debounce(this.getTasks, 250);

    handleCallback = () => { 
        this.getTasks(false)
    }

    handleReset = () => { 
        this.clearPageState();
        this.setState({
            isLoading: true,
            staffId: this.props.loggedInStaff.id,
            status: 0,
            searchString: ''
        }, () => { this.getTasks() })
    }

    handleSearchChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {this.savePageState(); this.delayGetTasks()});
    }

    handleSelectChange = (fieldName, selectedOption) => {
        this.setState({
            [fieldName]: (selectedOption && selectedOption.value) || this.props.loggedInStaff.id,
            isLoading: true
        }, () => { this.savePageState(); this.getTasks()})
    }

    handleStatusChange = () => {
        this.setState({
            status: this.state.status === 1 ? 0 : 1,
            isLoading: true
        }, () => { this.savePageState();this.getTasks() })
    }

    handleViewTask = id => {
        this.props.deployDialog(<TaskDialog id={id} callback={this.getTasks} />, "", "standard", "md")
    }

    render() {
        const { classes, loggedInStaff } = this.props;
        const { access:{staffSelector}, isLoading, searchString, staffId, staffList, status, tasks } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Tasks
                    </Typography>
                </Grid>
                {(isLoading && (
                    <Grid item xs={12} align='center'>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Grid container item spacing={3} alignItems='center'>
                                {staffSelector && (
                                    <Grid item xs={12} lg={6}>
                                        <PaddedPaper>
                                            <form noValidate autoComplete="off">
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={6}>
                                                        <FormControl margin="none" fullWidth>
                                                            <AutoCompleteSelect 
                                                                options={staffList} 
                                                                label='Staff Member'
                                                                value={staffId}
                                                                onChange={(v) => this.handleSelectChange('staffId', v)}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <TextField 
                                                            label="Task"
                                                            fullWidth 
                                                            value={searchString} 
                                                            onChange={this.handleSearchChange} 
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <div className='buttonRow'>
                                                    <Button 
                                                        onClick={() => this.handleStatusChange()}
                                                        variant="outlined"
                                                        style={{color: status ? '#0282C6' : 'rgba(0, 0, 0, 0.87)'}}
                                                        color={status ? 'primary' : 'default'}
                                                    >
                                                        <FALightIcon icon='archive' style={{color: status ? '#0282C6' : 'rgba(0, 0, 0, 0.87)'}} />
                                                        {status ? 'Hide' : 'Show'} Archived
                                                    </Button>
                                                    <Button 
                                                        onClick={() => this.handleReset()}
                                                        variant="outlined"
                                                        color="default"
                                                    >
                                                        <FALightIcon icon='undo' button />
                                                        Reset
                                                    </Button>
                                                </div>
                                            </form>
                                        </PaddedPaper>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                {(!status && (
                                    <React.Fragment>
                                        <TaskColumn 
                                            title="New" 
                                            color="#fff" 
                                            icon="plane" 
                                            tasks={tasks.new}
                                            classColumn={classes.columnNew}
                                            classHeader={classes.headerNew}
                                            classIcon={classes.icon}
                                            callback={this.handleCallback}
                                        />
                                        <TaskColumn 
                                            title="In Progress" 
                                            color="#fff" 
                                            icon="heart-rate" 
                                            tasks={tasks.inprogress}
                                            classColumn={classes.columnInProgress}
                                            classHeader={classes.headerInProgress}
                                            classIcon={classes.icon}
                                            callback={this.handleCallback}
                                        />
                                        <TaskColumn 
                                            title="Completed" 
                                            color="#fff" 
                                            icon="calendar-check" 
                                            tasks={tasks.completed}
                                            classColumn={classes.columnCompleted}
                                            classHeader={classes.headerCompleted}
                                            classIcon={classes.icon}
                                            callback={this.handleCallback}
                                        />
                                        {staffId === loggedInStaff.id && (
                                            <TaskColumn 
                                                title="Watching" 
                                                color="#fff" 
                                                icon="eye" 
                                                tasks={tasks.watched}
                                                classColumn={classes.columnWatched}
                                                classHeader={classes.headerWatched}
                                                classIcon={classes.icon}
                                                callback={this.handleCallback}
                                                displayStatus
                                            />
                                        )}
                                    </React.Fragment>
                                )) || (
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <CiDataTable  
                                                config={{
                                                    key:                    'task_id',
                                                    pagination:             true,
                                                    isLoading:              isLoading,
                                                    responsiveImportance:   true,
                                                    alternatingRowColours:  true,
                                                    persistenceId:          this.persistenceId,
                                                    options: {
                                                        dataRef: true,
                                                    }
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Task',
                                                        field: rowData => rowData.task_title,
                                                        important: true,
                                                        main: true,
                                                        truncate: true
                                                    },
                                                    {
                                                        heading: 'Completed',
                                                        field: rowData => rowData.task_completed_datetime,
                                                        dataRef: 'task_completed_datetime',
                                                        fieldFormat: 'date',
                                                        sizeToContent: true,
                                                        alignment: 'center'
                                                    },
                                                    {
                                                        heading: 'Deadline',
                                                        field: rowData => !_.isEmpty(rowData.task_deadline_datetime) ? rowData.task_deadline_datetime : '0000-00-00 00:00:00',
                                                        dataRef: 'task_deadline_datetime',
                                                        fieldFormat: 'date',
                                                        sizeToContent: true,
                                                        alignment: 'center'
                                                    },
                                                    {
                                                        heading: 'Created',
                                                        field: rowData =>rowData.task_created_datetime,
                                                        dataRef: 'task_created_datetime',
                                                        fieldFormat: 'date',
                                                        sizeToContent: true,
                                                        alignment: 'center'
                                                    },
                                                    {
                                                        actions: rowData => {
                                                            return [
                                                                {name: 'View', icon: 'search', onClick: rowData => this.handleViewTask(rowData.task_id)}
                                                            ]
                                                        }
                                                    }
                                                ]}
                                                rows={_.sortBy(tasks.completed, function (el) { return el.task_completed_datetime}).reverse()}
                                            />
                                        </PaddedPaper>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        )
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff:      state.staffAuth.staff,
        statePersistence:   state.statePersistence
    }
}

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                                               => dispatch(clearPersistence(key)),
    deployDialog:       (content, title, variant = 'standard', size = 'md') => dispatch(deployDialog(content, title, variant, size)),
    setPersistence:     (key, state)                                        => dispatch(setPersistence(key, state))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Tasks))