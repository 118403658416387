import { DEFAULT_PAGINATION_PAGE } from 'Constants';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, Card, CardActionArea, CardContent, Checkbox, Dialog, DialogContent, FormControl, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons';

import AllIcon from 'Components/Common/Icons/AllIcon';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PDFViewer from 'Components/Common/PDFViewer/PDFViewer';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import VideoWithError from 'Components/Common/VideoWithError/VideoWithError';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { setPersistence } from 'Actions/StatePersistence/StatePersistence';
import { handleScrollToTop } from 'Actions/UI/UI';

import { getMediaType } from 'Helpers/FileHelper';
import icons from 'Helpers/IconHelper';

import { downloadS3File } from 'Functions/MiscFunctions';
import { colors } from 'Helpers/ColourHelper';

const initialState = {
    activeColumns: [],
    pagination: {
        page:         DEFAULT_PAGINATION_PAGE,
        itemsPerPage: 25,
    },
    items:   [],
    ogItems: [],
    previewItem: null,
    filters: {},
    size:    {
        sx: 6,
        lg: 4,
        xl: 3
    }  
}

class MediaTable extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialTableState();
    }

    componentDidMount() {
        !this.hasTableState() && this.configureDataTable();
    }

    componentWillUnmount = () =>{
        if (this.props.config?.sticky)  window.removeEventListener('scroll', this.handleScroll, true);
    }

    componentDidUpdate = (prevProps, prevState) => {

        if(this.props.config?.persistenceId !== prevProps.config?.persistenceId) {
            if(!this.hasTableState()) {
                this.configureDataTable();
            } else {
                this.setState({...this.getInitialTableState()})
            }
            return;
        }

        if((JSON.stringify(prevState.pagination) !== JSON.stringify(this.state.pagination)) || (JSON.stringify(prevState.sort) !== JSON.stringify(this.state.sort))) {
            this.saveTableState();
        }

        if(JSON.stringify(this.props.items) !== JSON.stringify(prevProps.items) && JSON.stringify(this.props.items) !== JSON.stringify(this.state.items)) {

            let {items} = this.props;

            this.setState({
                pagination: {
                    ...this.state.pagination,
                    page: 0
                },
                items: items,
                ogItems: items,
            }, this.saveTableState)

        }

    }

    getInitialTableState = () => ({
        ...initialState,
        ...(this.hasTableState() && this.props.statePersistence?.[`MediaTable:${this.props?.config?.persistenceId}`])
    })

    hasTableState = () => {
        return this.props?.config?.persistenceId ? !!this.props.statePersistence?.[`MediaTable:${this.props?.config?.persistenceId}`] : false;
    }    

    saveTableState = () => {
        this.props?.config?.persistenceId && this.props?.config?.persistenceId !== null && this.props.setPersistence(`MediaTable:${this.props?.config?.persistenceId}`, this.state);
    }   

    configureDataTable = () => {
        this.setState({
            ...this.getInitialTableState(),
            pagination: {
                ...this.state.pagination,
                itemsPerPage: this.props.config?.itemsPerPage ? this.props.config?.itemsPerPage : this.state.pagination.itemsPerPage,
            },
            items: this.props.items,
            ogItems: this.props.items,
        })
    }

    renderBody = () => {
        const { pagination } = this.state;
        const { config } = this.props;

        let items = _.values(this.state.items);
        for (let i = 0, l = items.length; i < l; i++) {
            items[i]['itemNumber'] = i + 1;
            items[i]['itemNumberReversed'] = (items.length - i);
        }
        items = (config?.pagination && items.slice(pagination.page * pagination.itemsPerPage, pagination.page * pagination.itemsPerPage + pagination.itemsPerPage)) || items;
        return (
            <Grid container spacing={3} style={{alignContent: 'space-between', alignItems: 'stretch'}}>
                {_.map(items, (item, itemIdx) => {
                    return this.renderItem(item, itemIdx)
                })}
            </Grid>
        )
        
    }

    handleMediaClick = (item) => {
        if (this.props.config?.onClick){ this.props.config.onClick(item)
        } else { this.showBigMedia(item) }
    }

    showBigMedia = (item) => {
        if (this.props.config?.disableMediaPreview) return;
        this.setState({previewItem: item});
    }

    getBigMediaPreview = (item, style={}) => {    
        const { media } = this.props;
        const imageUrl = this.getFieldData(media, item);

        let newStyle = {height:'100%', maxHeight: 600, minHeight: 400, maxWidth: '100%', ...style};

        switch (getMediaType(imageUrl)){
            case 'image':
                return <ImageWithError src={imageUrl} style={newStyle} alt='Image Not Found' />
            case 'video':
                return <VideoWithError src={imageUrl} />
            case 'document':
                return <AllIcon icon={icons.file} size='xxxlarge' />
            case 'pdf':
                return <PDFViewer src={imageUrl} style={{...newStyle, maxHeight: 600, height: '100%'}} /> 
            default: 
                return <AllIcon icon='file-circle-exclamation' size='xxlarge' style={newStyle} color={colors.red} heavy />
        }


    }

    getMediaPreview = (item, style={}) => {    
        const { media } = this.props;
        const imageUrl = this.getFieldData(media, item);

        let newStyle = {height:'max-content', minHeight: 120, maxHeight: 200, maxWidth: '100%', ...style};

        switch (getMediaType(imageUrl)){
            case 'image':
                return <ImageWithError src={imageUrl} style={newStyle} alt='Image Not Found' />
            case 'video':
                return <AllIcon icon={icons.videos} size='xxxlarge' style={newStyle} />
            case 'pdf':
                return <AllIcon icon={icons.pdf} size='xxlarge' style={newStyle} />
            case 'document':
                return <AllIcon icon={icons.file} size='xxlarge' style={newStyle} />
            default: 
                return <AllIcon icon='file-circle-exclamation' size='xxlarge' style={newStyle} color={colors.red} heavy />
        }

    }

    getLayoutSizes = (item) => {
        let { xs, lg, xl, width } = this.props.config?.size ? this.props.config?.size : this.state.size;        
        if (width) return { style: { width: this.getFieldData(width, item) } };

        if (this.props.ui.device.isMobile){
            if (this.props.items.length < ((this.props.ui.device.isMobile) ? this.props.config?.mobileItemPerRow : this.props.config?.itemPerRow)) {
                //more responsive on mobile
                return { style: { width: `${100 / this.props.items.length}%` } };
            }
            if (this.props.config?.mobileItemPerRow) return { style: { width: `${100 / this.getFieldData(this.props.config?.mobileItemPerRow, item)}%` } };
        } else {
            if (this.props.config?.itemPerRow) return { style: { width: `${100 / this.getFieldData(this.props.config?.itemPerRow, item)}%` } };
        }

        if (xs) xs = this.getFieldData(xs, item);
        if (lg) lg = this.getFieldData(lg, item);
        if (xl) xl = this.getFieldData(xl, item);

        if (!xl) xl = lg ? lg : xs;
        if (!lg) lg = xs;
        if (!xs) xs = 12;

        return { xs, lg, xl };
    }

    renderItem(item, itemIdx) {
        const { classes, config, fields } = this.props;
        const sizes = this.getLayoutSizes(item);

        let actions = _.find(fields, i => i.actions)?.actions;
        actions = actions && (_.isFunction(actions) ? actions(item) : actions)

        return (
            <Grid item {...sizes} key={`fragment${itemIdx}${this.getFieldData(config?.key, item) || 0}`}>
                <Card className={classes.itemCard} style={{justifyContent: 'flex-start'}}>
                    <CardActionArea onClick={() => this.handleMediaClick(item)} className={classes.itemCardBody} style={{height: '230px !important'}}>
                        <CardContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 230, ...config?.style?.media}}>
                            <div onClick={() => this.handleMediaClick(item)}>
                                {this.getMediaPreview(item)}
                            </div>
                        </CardContent>
                    </CardActionArea>
                    {fields?.length > 0 &&
                        <CardContent  className={classes.itemCardAction}>
                            <Grid container style={{alignContent: 'space-between', height: '100%',}}>
                                <Grid item xs={12} container style={{ alignItems: 'flex-start', justifyContent: 'flex-start', alignContent: 'flex-start'}}>
                                    {_.filter(fields, i => !i.actions).length > 0 && _.map(_.filter(fields, i => !i.actions), (field, fieldIdx) => this.renderField(field, item, fieldIdx, itemIdx))}
                                </Grid>
                                {actions.length &&
                                    <Grid item xs={12} container spacing={1} style={{alignItems: 'center', justifyContent: 'center'}} className={classes.itemCardActionAction}>
                                        {_.map(actions, action => this.renderAction(action, item))}
                                    </Grid>
                                }
                            </Grid>
                            {/*
                                <Grid container spacing={1} style={{alignItems: 'flex-end', height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start', alignContent: 'flex-start'}}>
                                    {_.filter(fields, i => !i.actions).length > 0 && _.map(_.filter(fields, i => !i.actions), (field, fieldIdx) => this.renderField(field, item, fieldIdx, itemIdx))}
                                    {actions.length &&
                                        <Grid item container spacing={1} style={{marginTop: 'auto', paddingTop: 'auto', alignItems: 'center', justifyContent: 'center'}} className={classes.itemCardActionAction}>
                                            {_.map(actions, action => this.renderAction(action, item))}
                                        </Grid>
                                    }
                                </Grid>
                            */}
                        </CardContent>  
                    }
                </Card>
                <Grid container>

                </Grid>
            </Grid>
        )
    }

    getActionData = (action, item) => {

        let icon    = action.icon && this.getFieldData(action.icon, item);
        let tooltip = action.name;
        let header  = action.header || action.name;

        switch (true){
            case action.download:
                return {
                    icon:    icon ? icon : icons.download,
                    tooltip: tooltip || 'Download',
                    header:  header || 'Download',
                    onClick: ()=>this.handleActionDownload(item)
                }
            case action.view:
                return {
                    icon:    icon ? icon : icons.search,
                    tooltip: tooltip || 'View',
                    header:  header || 'View',
                    onClick: ()=>this.handleMediaClick(item)
                }
            default:
                return {
                    icon,
                    tooltip: action.name,
                    header:  action.header || action.name,
                    onClick: ()=>this.handleActionOnClick(action,item),
                    default: true
                }
        }
    }

    renderAction(action, item) {
        if (action){
            if (action.checkBox){
                return (
                    <Checkbox 
                        color='primary' 
                        checked={action.checked || item.checked} 
                        onClick={action.onCheck(item)} 
                        disabled={action.disabled || false}
                    />
                )
            }

            let actionData = this.getActionData(action, item);
            if (!actionData.icon) return <Grid item></Grid>;
            return (
                <Grid item>
                    <AllIcon 
                        icon={actionData.icon} 
                        onClick={actionData.onClick} 
                        tooltip={actionData.tooltip}
                        noMargin
                    />
                </Grid>
            )
        }
    }

    handleActionDownload = (item) => {
        const { media } = this.props;
        const imageUrl = this.getFieldData(media, item);
        downloadS3File(imageUrl);
    }

    handleActionOnClick = (action, item) => {
        if (action.link) { window.location.href = action.link;}
        if (action.onClick) {return action.onClick(item);}

    }

    renderField(field, item, fieldIdx, itemIdx) {
        let { config } = this.props;
        return (
            <Grid item xs={12} key={`field${fieldIdx}${itemIdx}${this.getFieldData(config?.key, item) || 0}`}>
                <Typography variant='body2' key={`fieldTypography${fieldIdx}${itemIdx}${this.getFieldData(config?.key, item) || 0}`}>{field.heading && <b style={{marginRight:'0.5em'}}>{field.heading}: </b>}{this.renderFormat(field, item)}</Typography>
            </Grid>
        )
    }

    renderFormat(field, item, fieldData=null) {
        const data = fieldData ? fieldData : this.getFieldData(field.field, item);
        return (<>{field.fieldPrefix && this.getFieldData(field.fieldPrefix, item)}{this.getFormattedData(data,field.format)}{field.fieldSuffix && this.getFieldData(field.fieldSuffix, item)}</>)
    }

    getFormattedData(data, format){
        if (!format) return data;
        switch (true){
            case format.startsWith('newLine'):
                return (format.startsWith('newLine:')) ?
                    this.getFormattedData(<><br/>{data}</>, format.replace('newLine:','')) :
                    <><br/>{data}</>;
            default:
                return data;
        }
    }

    getFieldData(field, item) {
        return _.isFunction( field ) ? field(item) : ( field in item ? item[field] : field );
    }

    renderFilters = () => {
        const { filters } = this.props;

        if (filters && filters.length > 0){
            return (
                <Grid container spacing={1} style={{paddingBottom: '1em', alignItems: 'flex-end'}}>
                    {_.map(filters, (filter, filterIdx) => this.renderFilter(filter, filterIdx))}
                </Grid>
            )
        } 

    }

    renderFilter(filter, filterIdx) {
        let key   = `filter:${filterIdx}`;
        let value = this.state.filters[key] || ''; 
        let gridStyle = {...filter.style};

        if (this.props.ui.device.isMobile){
            gridStyle = {...gridStyle, width: '100%'};
        }

        switch (filter.type){
            case 'search':
                return (
                    <Grid key={key} item style={gridStyle}>
                        <FormControl margin="none" fullWidth style={{margin:0}}>
                            <TextField  
                                label={filter.label || 'Search'} 
                                fullWidth 
                                value={value} 
                                onChange={(e)=>{this.handleChangeFilter(key, e.target.value)}}
                            />
                        </FormControl>
                    </Grid>
                )
            case 'select':
                let options = [] 
                _.each(_.map(this.state.items, item => { return this.getFieldData(filter.dataRef, item) }), i => _.isArray(i) ? options = [...options, ...i] : options.push(i) );
                return (
                    <Grid key={key} item style={{minWidth: 150,...gridStyle}}>
                        <FormControl fullWidth margin="none">
                            <AutoCompleteSelect 
                                label    = {filter.label} 
                                value    = {value} 
                                onChange = {(e)=>this.handleChangeFilter(key, e.value)}
                                options  = {_.map(_.uniqBy(options), data => _.assign({value: data, label: data}))}
                                noClear
                            />
                        </FormControl>
                    </Grid>
                )
            case 'reset':
                return (
                    <Grid key={key} item style={gridStyle}>
                        <Button
                            variant="outlined"
                            onClick={this.handleResetFilters}
                            fullWidth={this.props.ui.device.isMobile}
                        ><AllIcon icon={icons.clear} size='small' />Reset</Button>
                    </Grid>
                )
            case 'fileType':
                return (
                    <Grid key={key} item style={{minWidth: 150,...gridStyle}}>
                        <FormControl fullWidth margin="none">
                            <AutoCompleteSelect 
                                label    = {filter.label || 'File Type'}
                                value    = {value} 
                                onChange = {(e)=>this.handleChangeFilter(key, e.value)}
                                options  = {_.map(_.uniq(_.map(this.props.items, item => getMediaType(this.getFieldData(this.props.media, item)))),i=>_.assign({value: i, label: i}))}
                                noClear
                            />
                        </FormControl>
                    </Grid>
                )
            case 'custom':
                return (
                    <Grid key={key} item style={gridStyle}>
                        {filter.field}
                    </Grid>
                )
        }
        return ( <Grid key={key} item style={gridStyle}><></></Grid>)
    }

    handleResetFilters = () => {
        this.setState({filters: initialState.filters}, this.handleFilter);
    }

    handleChangeFilter = (key, value) => {
        this.setState({filters: {...this.state.filters, [key]: value}}, this.handleFilter);
    }

    handleFilter = () => {
        this.setState({
            items: _.filter(this.state.ogItems, item => {
                let allow = true;
                _.each(_.keys(this.state.filters), fkey => {
                    const filter = this.props.filters[fkey.split(':').pop()];
                    const value  = this.state.filters[fkey];
                    if ( value ) {
                        switch(filter.type){
                            case 'search':
                                allow = this.getFieldData(filter.dataRef, item).toLowerCase().includes(value.toLowerCase())
                                break;
                            case 'select':
                                const data = this.getFieldData(filter.dataRef, item);
                                allow = _.isArray(data) ?
                                    data.includes(value) :
                                    data === value;
                                break;
                            case 'fileType':
                                allow = getMediaType(this.getFieldData(this.props.media, item)) == value;
                                break;
                        }
                    }
                    if (!allow) return false;
                })
                return allow;
            })
        });
    }

    handleSetPage = (page) => {
        if (page === this.state.pagination.page) return;
        this.setState({
            pagination: {
                ...this.state.pagination, 
                page
            }
        });
    }

    renderPagination = () => {
        const { pagination, items } = this.state;

        const lowPage = pagination.page ? pagination.page * pagination.itemsPerPage : 1;
        const outPage = pagination.page ? ( pagination.page + 1 ) * pagination.itemsPerPage : pagination.itemsPerPage;

        if (lowPage > items.length) this.handleSetPage(0);

        return (
            <Grid container spacing={1} style={{justifyContent: 'flex-end', alignItems: 'center'}}>
                <Grid item>
                    <Typography variant='body2'>{lowPage}-{outPage > items.length ? items.length : outPage} of {items.length}</Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item>
                            <IconButton
                                onClick={()=>this.handleSetPage(0)}
                                disabled={pagination.page === 0}
                                aria-label="First Page"
                            >
                                <FirstPage />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={()=>this.handleSetPage(pagination.page - 1)}
                                disabled={pagination.page === 0}
                                aria-label="Previous Page"
                            >
                                <KeyboardArrowLeft />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={()=>this.handleSetPage(pagination.page + 1)}
                                disabled={pagination.page === parseInt(items.length / pagination.itemsPerPage)}
                                aria-label="Next Page"
                            >
                                <KeyboardArrowRight />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={()=>this.handleSetPage(Math.floor(items.length / pagination.itemsPerPage))}
                                disabled={pagination.page >= Math.floor(items.length / pagination.itemsPerPage)}
                                aria-label="Last Page"
                            >
                                <LastPage />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    renderHeader = () => {
        const { config } = this.props;
        return (
            <>
                <Grid container spacing={1} style={{justifyContent: 'space-between', alignItems: 'center'}}>
                    {!config?.filterInPagination && <Grid item xs={12}>{this.renderFilters()}</Grid>}
                    <Grid item>
                        {config?.filterInPagination && this.renderFilters()}
                    </Grid>
                    <Grid item style={{marginLeft: 'auto'}}>
                        {config?.pagination && this.renderPagination()}
                    </Grid>
                </Grid>
            </>
        )
    }

    renderFooter = () => {
        const { config } = this.props;
        return config?.pagination && this.renderPagination()
    }

    renderDialog = () => {

        const {  config, fields, media } = this.props;
        const { previewItem, items } = this.state;

        let item = previewItem;

        const previewIndex = _.findIndex(items, i => i == previewItem );
        let actions = _.find(fields, i => i.actions)?.actions;
        actions = actions && (_.isFunction(actions) ? actions(item) : actions)

        return (

            <Dialog 
                open={previewItem}  
                maxWidth="md" 
                scroll="body"
            >
                <DialogContent>
                    <Grid container xs={12} style={{ justifyContent: 'center' }} >
                        <Grid item style={{padding:'1em'}} key={item.eof_id}>
                            {this.getBigMediaPreview(item)}
                        </Grid>
                        {items.length > 1 &&
                            <Grid item xs={12}>
                                <Grid container spacing={2} style={{justifyContent: 'center'}}>
                                    <Grid item>
                                        <Button variant="outlined"  
                                            onClick={() => {
                                                this.setState({previewItem: items[ previewIndex - 1 < 0 ? items.length - 1 : previewIndex - 1 ]})
                                            }}
                                            fullWidth
                                        ><AllIcon icon='left-to-line' size='small' />Prev</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined"  
                                            onClick={() => {
                                                this.setState({previewItem: items[ previewIndex + 1 >= items.length  ? 0 : previewIndex + 1 ]})
                                            }}
                                            fullWidth
                                        >Next<AllIcon icon='right-to-line' size='small' noMargin style={{marginLeft: 'auto'}}/></Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={12} className='buttonRow' style={{textAlign:'center', marginTop: '1em', marginBottom: '1em', justifyContent: 'center'}}>
                            {_.map(_.filter(actions, i => !i.view ), (action, idx) => {
                                const actionData = this.getActionData(action, item);
                                return !( actionData.default && !( action.link || action.onClick ) ) && (
                                    <Button 
                                        variant='outlined'
                                        onClick={actionData.onClick}
                                    >
                                        <AllIcon icon={actionData.icon}/>
                                        {actionData.header}
                                    </Button>
                                )
                            })}
                            <Button variant='outlined' onClick={()=>{
                                this.setState({previewItem: null})
                            }}>Close</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }

    render() {
        const {  config, items } = this.props;
        const { previewItem } = this.state;

        return (
            <div style={{maxWidth: '100%'}}>                      
                {(config?.isLoading === true && (
                    <LoadingCircle />
                )) || (      
                    (typeof items === "undefined" || items.length === 0) ?
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography>No results were found</Typography>
                            </Grid>
                        </Grid> :
                        <>   
                            {this.renderHeader()}
                            {this.renderBody()}
                            {this.renderFooter()}
                        </>      
                )}
                {previewItem && !config?.disableMediaPreview && this.renderDialog()}
            </div>
        );
    }
}

const styles = theme => ({
    itemCard: {
        display:       'flex',
        flexDirection: 'column',
        height:        '100%',
    },
    itemCardBody: {
        display:        'flex',
        flexDirection:  'column',
    },
    itemCardAction :{
        display:        'flex',
        height:         '100%',
    },
    itemCardActionAction: {
        marginTop: 'auto',
    }
});

function mapStateToProps(state) {
    return {
        statePersistence: state.statePersistence,
        ui: state.ui
    };
}
function mapDispatchToProps(dispatch) {
    return {
        handleScrollToTop:  ()                => dispatch(handleScrollToTop()),
        setPersistence:     (key, state)      => dispatch(setPersistence(key, state)),
        deployDialog:       (content, header) => dispatch(deployDialog(content, header, null, 'md')),
        closeDialog:        ()                => dispatch(closeDialog())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MediaTable));