import React from 'react';
import _ from 'lodash';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class VideoCategoryList extends React.Component {
    render() {
      const { categories } = this.props;
      return (
        _.map(categories, category => {
            return (
                <ExpansionPanel key={category.video_cat_id}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography style={{color:'white'}}>{category.video_cat_name}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{padding: 0}}>
                        {(category.children && category.children.length > 0 && (
                            <List>
                                {_.map(category.children, childCategory => {
                                    return (
                                        <ListItem key={childCategory.video_cat_id}>
                                            <ListItemIcon>
                                                <FALightIcon icon='level-up' rotation={90} />
                                            </ListItemIcon>
                                            <ListItemText primary={childCategory.video_cat_name} />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        )) || (
                            <List>
                                <ListItem>
                                    <ListItemText primary={<em>No sub-categories to display</em>} />
                                </ListItem>
                            </List>
                        )}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            );
        })
      );
    }
}

export default VideoCategoryList;