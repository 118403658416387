import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
    Avatar,
    Button,
    Grid,
    TextField,
    Typography,
    FormControl
} from '@material-ui/core';
import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { CLENAWARE_RED, CLENAWARE_GREEN, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE } from 'Constants';
import AllIcon from 'Components/Common/Icons/AllIcon';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    searchResults: {},
    searchCriteria: {
        searchString: '',
        status: 'In Contract / Ownership',
    },
    gridPagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    },
    access: {
        viewVehicle: false,
        updateVehicle: false,
    }
})

class SearchVehicles extends Component {
    constructor(props) {
        super(props);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.clearPageState     = clearPageState.bind(this);
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        Promise.all([
            API.get('/staff/my/access/check/view-vehicle'),
            API.get('/staff/my/access/check/update-vehicle')
        ])
        .then(([viewVehicleRes, updateVehicleRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    viewVehicle: (viewVehicleRes.data && viewVehicleRes.data.has_access) || false,
                    updateVehicle: (updateVehicleRes.data && updateVehicleRes.data.has_access) || false,
                }
            }, () => this.getSearchData());
        })        
    };

    getSearchData = () => {
        this.setState({
            dataLoading: true
        }, () => {
            API.get('/vehicles', { params: this.state.searchCriteria }).then(result => {
                this.setState({
                    dataLoading: false
                });
                if(result.data) {
                    this.setState({
                        searchResults: result.data
                    });
                } else {
                    /* Error */
                }
            });
        });
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                searchString: event.target.value
            }
        },
        () => {
            this.savePageState();
            this.getSearchData();
        });
    }

    handleSelectChange = (name, value) => {
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                [name]: value
            }
        },
        () => {
            this.savePageState();
            this.getSearchData();
        });
    }
    
    resetSearch = () => {
        this.clearPageState();
        this.setState({
            searchCriteria: initialState().searchCriteria
        }, 
        () => {
            this.getSearchData();
        });
    }
    
    render() {
        const { access, dataLoading, searchCriteria, gridPagination } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Search Vehicles
                    </Typography>
                </Grid> 
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={[
                                            { value: 'All', label: 'All' },
                                            { value: 'Lease Completed / Sold', label: 'Lease Completed / Sold' },
                                            { value: 'In Contract / Ownership', label: 'In Contract / Ownership' },
                                        ]} 
                                        label='Status'
                                        onChange={v => this.handleSelectChange('status', v?.value)}
                                        value={searchCriteria?.status}
                                        noClear
                                    />
                                </FormControl>
                                <TextField id="nameSearch" label="Vehicle Registration / Make / Model" fullWidth value={searchCriteria?.searchString} onChange={this.onSearchStringChange} />
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"                                   
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key: 'id',
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: dataLoading,
                                responsiveImportance: true
                            }}
                            columns={[
                                {
                                    field: rowData => <Avatar alt={`${rowData?.make} ${rowData.model}`} src={rowData?.photo} />,
                                    hideInResponsiveDialog: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Vehicle',
                                    field: rowData => (
                                        <>
                                            {rowData?.make} {rowData?.model}
                                        </>
                                    ),
                                    main: true,
                                    important: true,
                                    hideInResponsiveDialog: true,
                                    truncate: true,
                                },
                                {
                                    heading: 'Registration Number',
                                    field: rowData => rowData?.regNumber,                                            
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Fuel Type',
                                    field: rowData => rowData?.fuelType,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Ownership Type',
                                    field: rowData => rowData?.ownershipType,
                                    sizeToContent: true,
                                    hideInResponsiveDialog: true
                                },
                                {
                                    heading: 'Type',
                                    field: rowData => rowData?.type,
                                    sizeToContent: true,
                                    hideInResponsiveDialog: true
                                },
                                {
                                    heading: 'Allocated To',
                                    field:  rowData => rowData?.status == 'In Contract / Ownership' ?
                                                <>
                                                {rowData?.currentAllocation ? 
                                                    <>
                                                    <Typography variant="body2">
                                                        {rowData?.currentAllocation?.fn} {rowData?.currentAllocation?.ln}
                                                    </Typography>
                                                    <Typography variant={rowData?.currentAllocation?.allocatedTo == "Pool Vehicle" ? "body2" : "caption"}>
                                                        {rowData?.currentAllocation?.allocatedTo}
                                                    </Typography>
                                                    </>
                                                : 
                                                    <Typography variant="body2">
                                                        <b><AllIcon icon='triangle-exclamation' heavy noMargin style={{color: CLENAWARE_RED }} size="small" /> Not Allocated</b>
                                                    </Typography>
                                                }
                                                </>
                                            :
                                                '-',
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Auto Mileage Tracker Enabled',
                                    field: rowData => rowData?.tracker ? 'Yes' : 'No',
                                    sizeToContent: true,
                                    alignment: 'center'
                                },
                                {
                                    heading: 'Status',
                                    field: rowData => rowData?.status,
                                    important: true,
                                    sizeToContent: true,
                                    alignment: 'center',
                                    cellProps: rowData => ({
                                        style: {
                                            color: rowData?.status == 'Lease Completed / Sold' ? CLENAWARE_RED : CLENAWARE_GREEN
                                        }
                                    })
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'View', icon: 'search', link: '/vehicles/view/' + rowData.id, state: {searchCriteria, gridPagination}, disabled: !access.viewVehicle}
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   ()                          => dispatch(clearPersistence()),
    setPersistence:     (key, state)                => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchVehicles);