import React         from 'react';
import { connect }   from 'react-redux';
import { Link }      from 'react-router-dom';
import _             from 'lodash';
import moment        from 'moment';
import { findIndex } from 'lodash';
import API           from 'API';

import { formatValidationErrors }           from 'Helpers/ErrorHelper';
import {colors}                             from 'Helpers/ColourHelper';
import icons                                from 'Helpers/IconHelper';
import { pdfFromBase64, pdfDataFromBase64 } from 'Helpers/PDFHelper';

import { handleChange }                     from 'Functions/FormFunctions';
import { downloadS3File }                   from 'Functions/MiscFunctions';

import { Button, Grid, Table, TableHead, TableBody, TableRow, TableCell, Typography, TextField, DialogActions, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import DataTable                   from 'Components/Common/DataTables/CiDataTable';
import FALightIcon                 from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon                 from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import Image                       from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle               from 'Components/Common/LoadingCircle/LoadingCircle';
import Qr                          from 'Components/Common/QrReader/QrReader';
import SelectSerialAndWarrantyList from 'Components/Sales/Order/Picking/SelectSerialAndWarrantyList';
import AllIcon                     from 'Components/Common/Icons/AllIcon';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deploySnackBar }     from 'Actions/SnackBar/SnackBar';
import { playSound }          from 'Actions/Sounds/Sounds';

import { printA4, printLabel, printMulti } from 'Actions/Print/Print';

const styles = theme => ({
    root: {
        height: '80vh',
        padding: theme.spacing(1)
    },
    img: {
        maxHeight: 330,
        maxWidth: '100%'
    },
    qrRow: {
        maxHeight: 60
    },
    qtyRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    actionText: {
        border: `2px dashed ${theme.palette.secondary.main}`,
        padding: 35,
        width: '60%',
        margin: '0 auto'
    },
    connectionText : {
        margin: '1em auto',
        width:  '40%',
        border: `1px solid ${colors.red}`,
    },
    errorText: {
        color: theme.palette.secondary.main
    },
    button: {
        marginTop: 35
    },
    confirmQtyInputContainer: {
        maxWidth: 160,
    },
    confirmQtyInput: {
        textAlign: 'center',
        maxWidth: 140,
        padding: 30,
        fontSize: 50,
    },
    largeButton: {
        fontSize: 24
    },
    successTick: {
        color: colors.green
    },
    [theme.breakpoints.down('sm')]: {
        img: {
            maxHeight: 240
        },
        actionText: {
            fontSize: '2rem',
        },
        confirmQtyInputContainer: {
            maxWidth: 120,
        },
        confirmQtyInput: {
            maxWidth: 100,
            padding: 20,
            fontSize: 30,
        },
        largeButton: {
            fontSize: 18
        }
    }
});

const initialState = {
    currentPick: 0,
    step: 'scanPart',
    formData: {
        qty : '',
        serialNumbers: [],
        warrantyVoid: [],
        manualNumbers: [],
        pickStatus: ''
    },
    itemsPicked: 0,
    timeElapsed: 0,
    lists: {
        serialNumberList: [],
        completedPicks: []
    },
    despId: null,
    formErrors: {},
    isLoading: 0,
    showManualDuplicate: 0,
    forceManuel: 0,
    greenaware: [],
    pickedParts: [],
    pickingDocuments: [],
    showAdditional: false,
}

class PickingDialogContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState;
        this.handleChange = handleChange.bind(this)
        
    }

    componentDidMount = () => {
        this.setState({
            ...this.state,
            currentPick: this.props.startIndex
        });
    }

    handleScan = scannedPartNumber => {
        if(this.state.step === 'scanPart' || this.state.step === 'wrongPart') {

            // if correct part number enter quantity
            if(scannedPartNumber === this.props.pickingList[this.state.currentPick].od_part_number) {
                
                this.setState({
                    ...this.state,
                    step: 'enterQty',
                },
                () => {
                    this.props.playSound('pickSuccess', 1)
                });

            // if not correct part number show error)
            } else if(scannedPartNumber !== this.props.pickingList[this.state.currentPick].od_part_number && scannedPartNumber !== null) {

                this.setState({
                    ...this.state, 
                    step: 'wrongPart',
                },
                () => {
                    this.props.playSound('pickFailed', 1);

                    setTimeout(() => {
                        this.setState({
                            ...this.state,
                            step: 'scanPart'
                        });
                    }, 2000);
                });

            }
            
        }
    }

    handleSkip = () => {
        this.props.deployConfirmation(
            "Are you sure you want to skip this pick?",
            "Skip Pick",
            this.skip
        );
    }

    skip = () => {
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                qty: 0
            }
        }, 
        () => {
            this.handlePickSuccess('Skipped');
        })
    }

    handleNoPick = () => {
        this.props.deployConfirmation(
            "Are you sure this item doesn't require picking?", 
            "No Pick Required", 
            () => this.handlePickSuccess('Not Required')
        );
    }
    
    // function triggered on key press of qty input
    // this allows user to use the 'Go' button on the android keyboard to submit the quantity
    // as well as the green tick button
    handleEnter = e => {
        if(e.key === 'Enter' && this.state.formData.qty !== '') {
            this.handleEnterQty();
        }
    }

    handleStorePickedPart = part_id => {
        if (!this.state.pickedParts.includes(part_id) && parseInt(this.props.pickingList[this.state.currentPick].part.picking_documents_count) > 0) {
            API.get(`/parts/${part_id}/pickingDocuments`)
            .then((result) => {
                this.setState({
                    ...this.state,
                    pickedParts: _.uniq([...this.state.pickedParts, part_id]),
                    pickingDocuments: [...this.state.pickingDocuments, ...result.data]
                });
            })
        }
    }

    handleEnterQty = () => {
        this.setState({
            ...this.state,
            isLoading: 1
        },
        () => {
            let amount =  (this.props.pickingList[this.state.currentPick].part.part_req_serial_number === 'Yes' && (this.props.pickingList[this.state.currentPick].part.part_model_type === null || this.props.pickingList[this.state.currentPick].part.part_model_type?.issued_serial_number?.length < parseInt(this.props.pickingList[this.state.currentPick].pick_detail.opd_quantity_to_follow))) ?
                            parseFloat( this.props.pickingList[this.state.currentPick].part.part_model_type?.issued_serial_number?.length) :
                            parseFloat(this.props.pickingList[this.state.currentPick].pick_detail.opd_quantity_to_follow);

            amount = parseFloat(amount) > parseFloat(this.props.pickingList[this.state.currentPick].pick_detail.opd_quantity_to_follow) ?  parseFloat(this.props.pickingList[this.state.currentPick].pick_detail.opd_quantity_to_follow) : parseFloat(amount);

            // if the quantity picked equals amount required
            if(parseFloat(this.state.formData.qty) === parseFloat(amount)) {

                this.handlePickItem();
    
            // if the quantity picked is greater than amoutn required, (api will return errors if true)
            } else if (parseFloat(this.state.formData.qty) > parseFloat(amount) || parseFloat(this.state.formData.qty) < 0) {

                // handle pick success will return formErrors array from api
                this.handlePickSuccess();
    
            // if quantity is less user will need to confirm quantity is less
            } else {

                this.setState({
                    ...this.state,
                    step: 'confirmQty',
                    isLoading: 0
                });

            }
        });
    }

    handleGoBack = () => {
        this.setState({
            ...this.state,
            step: 'enterQty'
        });
    }

    // enter serials if req, enter warranty labels if req, handle next item
    handlePickItem = () => {
        // if 0 quantity no serial numbers or warranty labels need to be entered,
        // handle next item
        if(parseFloat(this.state.formData.qty) === 0) {
            this.handlePickSuccess('Skipped');
            return;
        }

        // does item require serial numbers?
        if(this.props.pickingList[this.state.currentPick].part.part_req_serial_number === 'Yes' || this.props.pickingList[this.state.currentPick].part.part_req_serial_number === 'Manual') {

            let serialNumberList = [], 
                serialNumbers    = [],
                manualNumbers    = [];

            // generate serial form data
            for(let i = 0; i < this.state.formData.qty; i++) {
                if (this.props.pickingList[this.state.currentPick].part.part_req_serial_number === 'Manual'){
                    manualNumbers.push('')
                } else {
                    serialNumbers.push('');
                }
            }

            this.setState({
                ...this.state,
                step: 'selectSerial',
                lists: {
                    ...this.state.lists,
                    serialNumberList
                },
                formData: {
                    ...this.state.formData,
                    serialNumbers,
                    manualNumbers
                },
                isLoading: 0
            }, ()=>{
                if (this.props.pickingList[this.state.currentPick]?.part?.part_model_type?.pmt_id) {
                    API.get(`/parts/serialNumbers/modelTypes/${this.props.pickingList[this.state.currentPick].part.part_model_type.pmt_id}/byStatus`, { params: { status: 'Issued' } })
                    .then(serialRes => {
                        if(serialRes.data){
                            serialRes.data.forEach(el => {
                                serialNumberList.push({
                                    value: el.psn_id,
                                    label: el.psn_serial_number
                                });
                            });
                            this.setState({
                                lists: {
                                    ...this.state.lists,
                                    serialNumberList
                                },
                            });
                        }
                    });
                }
            });    

        // does item require warranty void labels
        } else if(this.props.pickingList[this.state.currentPick].part.part_warranty_void_label === 'Yes') {

            let warrantyVoid = [];

            // generate warranty form data
            for(let i = 0; i < this.state.formData.qty; i++) {
                warrantyVoid.push('');
            }

            this.setState({
                ...this.state,
                step: 'selectWarranty',
                formData: {
                    ...this.state.formData,
                    warrantyVoid
                },
                lists: {
                    ...this.state.lists,
                    serialNumbersList: []
                },
                isLoading: 0
            });

        // pick success
        } else {

            this.handlePickSuccess();

        }
    }

    handleManualSerialChange = (i,value) => {
        let manualNumbers = this.state.formData.manualNumbers;
        manualNumbers[i] = value;

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                manualNumbers
            },
        });
    }

    handleSerialChange = (i, e) => {
        let serialNumbers    = this.state.formData.serialNumbers,
            serialNumberList = this.state.lists.serialNumberList;

        if(e) {
            // old selected serial needs undisabling from list if reselecting without clearing
            if(serialNumbers[i]) {
                let oldSerialIdx = findIndex(serialNumberList, {value: serialNumbers[i]});

                serialNumberList[oldSerialIdx] = {
                    ...serialNumberList[oldSerialIdx],
                    disabled: false
                }
            }

            let serialIdx = findIndex(serialNumberList, {value: e.value});

            serialNumberList[serialIdx] = {
                ...serialNumberList[serialIdx],
                disabled: true
            }

            serialNumbers[i] = e.value;

        } else {
            //undisable cleared serial number from list
            let serialVal = serialNumbers[i],
                serialIdx = findIndex(serialNumberList, {value: serialVal});

            serialNumberList[serialIdx] = {
                ...serialNumberList[serialIdx],
                disabled: false
            }

            serialNumbers[i] = '';
        }

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                serialNumbers
            },
            lists: {
                ...this.state.lists,
                serialNumberList
            }
        });
    }

    handleWarrantyChange = (i, e) => {
        let warrantyVoid = this.state.formData.warrantyVoid;

        warrantyVoid[i] = e.target.value;

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                warrantyVoid
            }
        });
    }

    // this will be passed via the select serial and warranty dialog 
    handlePickSuccess = (pickStatus = 'Picked') => {
        if (pickStatus !== 'Skipped') {
            this.handleStorePickedPart(this.props.pickingList[this.state.currentPick].od_part_id)
        }
        this.setState({
            ...this.state,
            isLoading: 1
        },
        () => {
            let manualNumbers = [];
            _.forEach(this.state.formData.manualNumbers, n => {
                manualNumbers.push(n.trim());
            } )

            let formData = {
                ...this.state.formData,
                qty: pickStatus !== 'Picked' ? 0 : this.state.formData.qty,
                pickStatus,
                forceManuel: this.state.forceManuel,
                manualNumbers
            };
            API.post(`/sales/orders/pick/${this.props.orderId}/${this.props.pickingList[this.state.currentPick].od_id}`, formData)
            .then(result => {
                if(result.data.errors) {
                    this.setState({
                        ...this.state,
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: 0
                    }, () => {
                        if (this.state.formErrors['manuelSerialNumber']){
                            var sn  = [];
                            _.forEach(this.state.formErrors['manuelSerialNumber'], i => {
                                if (!_.find(sn, {number: i.psn_serial_number})){
                                    sn.push({
                                        number: i.psn_serial_number,
                                        dates : [moment(i.psn_created_at_datetime).format("DD/MM/YYYY")]
                                    })
                                } else {
                                    sn[_.findIndex(sn,{number: i.psn_serial_number})].dates.push(moment(i.psn_created_at_datetime).format("DD/MM/YYYY"));
                                }
                            })
                            this.setState({
                                forceManuel:            0,
                                showManualDuplicate:    sn,
                            })
                        }
                    });                    
                } else if(result.data.success) {
    
                    this.setState({
                        ...this.state,
                        formData: initialState.formData,
                        formErrors: {},
                        forceManuel: 0,
                    },
                    () => {
                        this.checkGreenaware();
                        this.handleNext(this.props.pickingList[this.state.currentPick].od_id);
                    });
    
                }
            });
        });
    }

    checkGreenaware = () => {
        API.get('/customerReturns/greenaware/returns', {params: {orderId: this.props.orderId}})
        .then(res => {
            this.setState({
                greenaware: res.data
            })
        })
    }

    handleNext = () => {
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                qty: ''
            },
            isLoading: 1
        },
        () => {
            if(this.state.currentPick + 1 !== this.props.pickingList.length) {
    
                this.setState({
                    ...this.state,
                    currentPick: this.state.currentPick + 1,
                    step: 'scanPart',
                    isLoading: 0
                });
                
            } else {
    
                this.handleCompletePick();
    
            }
        });
    }

    handleCompletePick = () => {
        API.post(`/sales/orders/pick/${this.props.orderId}/complete`)
        .then(result => {

            this.checkGreenaware();
            this.setState({
                ...this.state,
                step: 'complete',
                itemsRequired: result.data.stats.items_required,
                itemsPicked: result.data.stats.total_picked,
                timeElapsed: result.data.stats.time_elapsed,
                lists: {
                    ...this.state.lists,
                    completedPicks: result.data.completedPicks
                },
                despId: result.data.despId,
                isLoading: 0
            },
            () => {
                this.props.playSound('pickComplete', 1);
            });

        });
    }

    handlePrint = () => {
        API.post(`/sales/orders/${this.props.orderId}/prevPdf`, {'type': 'delivery-note', 'id': this.state.despId})
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
                this.props.deploySnackBar('success', 'PDF successfully downloaded');
            }
        });
    }

    handlePrintGreenaware = () => {
        API.post(`/sales/orders/${this.props.orderId}/prevPdf`, {'type': 'delivery-note', 'id': this.state.despId})
        .then(result => {
            if (result.data.additional && result.data.additional.length > 0) {
                let file = result.data.additional[0]
                pdfFromBase64(file.pdf, file.pdfName);
                this.props.deploySnackBar('success', 'PDF successfully downloaded');
            }
        });
    }

    handleManualDuplicateConfirmationSuccess = () => {
        this.setState({
            forceManuel:            1,
            showManualDuplicate:    0,
        }, ()=>this.handlePickSuccess());
    }

    handleManualDuplicateConfirmationClose = () => {
        this.setState({
            forceManuel:            0,
            showManualDuplicate:    0,
        });
    }

    handlePrintAllAdditional = () => {
        this.props.printMulti(_.map(_.uniqBy(this.state.pickingDocuments, i => i.file_url_pdf || i.file_url_original), i => ({
            printer:  1,
            url:      i.file_url_pdf || i.file_url_original, 
            fileName: i.doc_title
        })));
    }

    downloadPartLabel = (id, part_number) => {
        API.get('/parts/' + id + '/stockLabel',{
            params: {
              type: 'partLabel'
            }
        })
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, 'part_label_'+part_number+'.pdf');
            }
        });
    }

    printPartLabel = (id, part_number) => () => {
        API.get('/parts/' + id + '/stockLabel',{
            params: {
              type: 'partLabel'
            }
        })
        .then(result => {
            if(result.data) {
                this.props.printLabel(pdfDataFromBase64(result.data.pdf), 'part_label_'+part_number+'.pdf');
            }
        });
    }

    render() {
        const { isLoading, step, formData:{ qty }, currentPick, itemsPicked, formErrors, showAdditional } = this.state;

        const { classes } = this.props;

        return(
            <Grid container justify="center" className={classes.root} spacing={8}>
                {(isLoading && (
                    <LoadingCircle />
                )) || (
                    (step !== 'complete' && (
                        <>
                            <Grid container item className={classes.qrRow}>
                                <Grid item xs={6}>
                                    {       step !== 'selectSerial' 
                                        &&  step !== 'selectWarranty'
                                        &&  step !== 'enterQty'
                                        &&  step !== 'confirmQty'
                                        && <Qr handleScan={this.handleScan} width="30%" />
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6" className="float-right">
                                        Pick {currentPick + 1} of {this.props.pickingList.length}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <Grid container justify="center" className="text-center" spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h3">
                                            {this.props.pickingList[currentPick].od_part_number}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">
                                            {this.props.pickingList[currentPick].od_part_description}<br></br>
                                            
                                            {this.props.pickingList[currentPick].od_electrical_connection ?
                                                    <Grid container justify="center" alignContent='center' spacing={3} className={classes.connectionText}>
                                                        <Grid item style={{float:'right'}}><FASolidIcon icon='plug' noMargin style={{color: colors.red}}/></Grid>
                                                        <Grid item style={{paddingTop:'0.75em', color: colors.red}}>
                                                            <Typography variant='body1' style={{fontWeight: 'bold'}}>{`${this.props.pickingList[currentPick].od_electrical_connection} Connection`}</Typography>
                                                        </Grid>
                                                        <Grid item alignContent='center'><FASolidIcon icon='plug' noMargin style={{color: colors.red}}/></Grid>
                                                    </Grid>
                                                : '' 
                                            }
                                        </Typography>
                                    </Grid>
                                    {(step === 'selectSerial' && (
                                        <Grid item xs={12}>
                                            {this.props.pickingList[currentPick].part.part_req_serial_number === 'Manual' ?
                                                <SelectSerialAndWarrantyList
                                                    odId={this.props.pickingList[currentPick].od_id}
                                                    formData={this.state.formData.manualNumbers}
                                                    serialNumberList={this.state.lists.serialNumberList}
                                                    handleChange={this.handleManualSerialChange}
                                                    handlePickSuccess={this.handlePickSuccess}
                                                    formErrors={formErrors}
                                                    pick={this.props.pickingList[currentPick]}
                                                /> : 
                                                <SelectSerialAndWarrantyList
                                                    odId={this.props.pickingList[currentPick].od_id}
                                                    formData={this.state.formData.serialNumbers}
                                                    serialNumberList={this.state.lists.serialNumberList}
                                                    handleChange={this.handleSerialChange}
                                                    handlePickSuccess={this.handlePickSuccess}
                                                    formErrors={formErrors}
                                                    pick={this.props.pickingList[currentPick]}
                                                />
                                            }
                                        </Grid> 
                                    )) || (
                                        (step === 'selectWarranty' && (
                                            <Grid item xs={12}>
                                                <SelectSerialAndWarrantyList
                                                    odId={this.props.pickingList[currentPick].od_id}
                                                    formData={this.state.formData.warrantyVoid}
                                                    handleChange={this.handleWarrantyChange}
                                                    handlePickSuccess={this.handlePickSuccess}
                                                    formErrors={formErrors}
                                                    pick={this.props.pickingList[currentPick]}
                                                />
                                            </Grid>
                                        ))
                                    ) || (
                                        <>
                                            <Grid item xs={12}>
                                                <Image src={this.props.pickingList[currentPick].part?.default_image?.file_path_base64} className={classes.img} />
                                            </Grid>
                                            {step !== 'confirmQty' && (
                                                (this.props.pickingList[currentPick].part.part_req_serial_number === 'Yes' && (this.props.pickingList[currentPick].part.part_model_type === null || this.props.pickingList[currentPick].part.part_model_type?.issued_serial_number?.length < parseInt(this.props.pickingList[currentPick].pick_detail.opd_quantity_to_follow))) ?
                                                    <>
                                                        <Grid item xs={12} className={classes.qtyRow}>
                                                            <Typography variant="h4" component="span">
                                                                Required:
                                                            </Typography>
                                                            <Typography variant="h3" component="span" className="pl-1">
                                                                <strong>{parseFloat(this.props.pickingList[currentPick].pick_detail.opd_quantity_to_follow)}</strong>
                                                            </Typography>
                                                        </Grid> 
                                                        <Grid item xs={12} className={classes.qtyRow}>
                                                            <Typography variant="h4" component="span">
                                                                Available:
                                                            </Typography>
                                                            <Typography variant="h3" component="span" className="pl-1">
                                                                <strong>{ parseFloat( this.props.pickingList[currentPick].part.part_model_type?.issued_serial_number?.length) }</strong>
                                                            </Typography>
                                                        </Grid> 
                                                    </>    
                                                    :
                                                    <Grid item xs={12} className={classes.qtyRow}>
                                                        <Typography variant="h4" component="span">
                                                            Required:
                                                        </Typography>
                                                        <Typography variant="h3" component="span" className="pl-1">
                                                            <strong>{
                                                                (this.props.pickingList[currentPick].part.part_req_serial_number === 'Yes' && (this.props.pickingList[currentPick].part.part_model_type === null || this.props.pickingList[currentPick].part.part_model_type?.issued_serial_number?.length < parseInt(this.props.pickingList[currentPick].pick_detail.opd_quantity_to_follow))) ?
                                                                    parseFloat( this.props.pickingList[currentPick].part.part_model_type?.issued_serial_number?.length) :
                                                                    parseFloat(this.props.pickingList[currentPick].pick_detail.opd_quantity_to_follow)
                                                            }</strong>
                                                        </Typography>
                                                    </Grid>
                                            )}
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        {(step === 'enterQty' && (
                                            <Grid container item justify="center" spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="qty"
                                                        name="qty"
                                                        label="Confirm Quantity *"
                                                        value={qty}
                                                        type="number"
                                                        variant="outlined"
                                                        error={formErrors?.['quantity'] && true}
                                                        helperText={
                                                            formErrors?.['quantity'] &&
                                                            (this.props.pickingList[currentPick].part.part_req_serial_number === 'Yes' && (this.props.pickingList[currentPick].part.part_model_type === null || this.props.pickingList[currentPick].part.part_model_type?.issued_serial_number?.length < parseInt(this.props.pickingList[currentPick].pick_detail.opd_quantity_to_follow))) ?
                                                                formErrors?.['quantity'].replace('required', 'available') :
                                                                formErrors?.['quantity']
                                                        }
                                                        onChange={this.handleChange}
                                                        onKeyPress={e => this.handleEnter(e)}
                                                        margin="normal"
                                                        className={classes.confirmQtyInputContainer}
                                                        inputProps={{
                                                            className: classes.confirmQtyInput,
                                                            min: 0, // eslint-disable-next-line
                                                            pattern: "\d*"
                                                        }}
                                                        autoFocus
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary"
                                                        size="large"
                                                        onClick={this.handleEnterQty}
                                                        disabled={qty === '' && true}
                                                        className={classes.largeButton}
                                                    >
                                                        Confirm
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button 
                                                        variant="outlined"
                                                        endIcon={<FALightIcon icon="step-forward" />}
                                                        className="mt-3 mr-1"
                                                        onClick={this.handleSkip}
                                                        style={{border: '1px solid black'}}
                                                    >
                                                        Skip
                                                    </Button>
                                                    <Button 
                                                        variant="outlined"
                                                        endIcon={<FALightIcon icon="times" />}
                                                        className="mt-3 mr-1"
                                                        onClick={this.handleNoPick}
                                                        style={{border: '1px solid black'}}
                                                    >
                                                        Pick Never Required
                                                    </Button>
                                                    <Button 
                                                        variant="outlined"
                                                        endIcon={<FALightIcon icon={icons.print} />}
                                                        className="mt-3"
                                                        onClick={this.printPartLabel(this.props.pickingList[currentPick].part?.part_id, this.props.pickingList[currentPick].part?.part_number)}
                                                        style={{border: '1px solid black'}}
                                                    >
                                                        Print Part Label
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )) || (
                                            (step === 'confirmQty' && (
                                                <Grid container justify="center" spacing={2}>
                                                    <Grid item xs={12} lg={6}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell className="text-center"><Typography variant="h5"><strong>Required</strong></Typography></TableCell>
                                                                    <TableCell className="text-center"><Typography variant="h5"><strong>Picked</strong></Typography></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell className="text-center"><Typography variant="h6">{parseFloat(this.props.pickingList[currentPick].pick_detail.opd_quantity_to_follow)}</Typography></TableCell>
                                                                    <TableCell className="text-center"><Typography variant="h6">{qty}</Typography></TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </Grid>
                                                    <Grid item xs={12} className="mb-1">
                                                        <Typography variant="h4">
                                                            <strong>Quantity {parseFloat(this.props.pickingList[currentPick].pick_detail.opd_quantity_to_follow) - qty} Short?</strong>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Button 
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={this.handleGoBack}
                                                            fullWidth
                                                            className={classes.largeButton}
                                                        >
                                                            No
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Button 
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={this.handlePickItem}
                                                            fullWidth
                                                            className={classes.largeButton}
                                                        >
                                                            Yes
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button 
                                                            variant="outlined"
                                                            endIcon={<FALightIcon icon="step-forward" />}
                                                            className="mt-3 mr-1"
                                                            onClick={this.handleSkip}
                                                            style={{border: '1px solid black'}}
                                                        >
                                                            Skip
                                                        </Button>
                                                        <Button 
                                                            variant="outlined"
                                                            endIcon={<FALightIcon icon="times" />}
                                                            className="mt-3 mr-1"
                                                            onClick={this.handleNoPick}
                                                            style={{border: '1px solid black'}}
                                                        >
                                                            Pick Never Required
                                                        </Button>
                                                        <Button 
                                                            variant="outlined"
                                                            endIcon={<FALightIcon icon={icons.print} />}
                                                            className="mt-3"
                                                            onClick={this.printPartLabel(this.props.pickingList[currentPick].part?.part_id, this.props.pickingList[currentPick].part?.part_number)}
                                                            style={{border: '1px solid black'}}
                                                        >
                                                            Print Part Label
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            )
                                        )) || (
                                            (step !== 'selectSerial' && 
                                            step !== 'selectWarranty' && (
                                                <>
                                                    <Typography variant="h4" className={classes.actionText}>
                                                        {step === 'scanPart' && 
                                                            <strong>Please Scan QR Code</strong>
                                                        }
                                                        {step === 'wrongPart' && 
                                                            <span className={classes.errorText}>Incorrect Part Please Try Again</span>
                                                        }
                                                    </Typography>
                                                    <Grid item xs={12}>
                                                        <Button 
                                                            variant="outlined"
                                                            endIcon={<FALightIcon icon="step-forward" />}
                                                            className="mt-3 mr-1"
                                                            onClick={this.handleSkip}
                                                            style={{border: '1px solid black'}}
                                                        >
                                                            Skip
                                                        </Button>
                                                        <Button 
                                                            variant="outlined"
                                                            endIcon={<FALightIcon icon="times" />}
                                                            className="mt-3 mr-1"
                                                            onClick={this.handleNoPick}
                                                            style={{border: '1px solid black'}}
                                                        >
                                                            Pick Never Required
                                                        </Button>
                                                        <Button 
                                                            variant="outlined"
                                                            endIcon={<FALightIcon icon={icons.print} />}
                                                            className="mt-3"
                                                            onClick={this.printPartLabel(this.props.pickingList[currentPick].part?.part_id, this.props.pickingList[currentPick].part?.part_number)}
                                                            style={{border: '1px solid black'}}
                                                        >
                                                            Print Part Label
                                                        </Button>
                                                    </Grid>
                                                </>
                                            ))
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>)
                    )) || (
                        <Grid 
                            container 
                            spacing={3} 
                            justify="center" 
                            className={`${classes.root} text-center`}
                        >
                            <Grid container item xs={12} alignItems="center" justify="center">
                                <FALightIcon 
                                    icon="check-circle"
                                    size="xxlarge"
                                    className={classes.successTick}
                                />
                                <Typography variant="h3" className="pl-2">
                                    Pick Complete
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="text-center"><Typography variant="h5"><strong>Required</strong></Typography></TableCell>
                                            <TableCell className="text-center"><Typography variant="h5"><strong>Picked</strong></Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="text-center"><Typography variant="h6">{this.props.totalQty}</Typography></TableCell>
                                            <TableCell className="text-center"><Typography variant="h6">{itemsPicked}</Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table> 
                            </Grid>
                            <Grid item xs={10}>
                                <DataTable 
                                    config={{
                                        key: 'od_id',
                                        isLoading
                                    }}
                                    columns={[
                                        {
                                            heading: '',
                                            field: rowData =>   <div className="thumbnailContainer">
                                                                    <Image 
                                                                        src={rowData?.order_detail?.part?.default_image?.thumbnail_file_path} 
                                                                        alt={rowData?.order_detail?.od_part_description} 
                                                                        className="thumbnail" 
                                                                    />
                                                                </div>,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Part Number',
                                            field: rowData => rowData?.order_detail?.od_part_number
                                        },
                                        {
                                            heading: 'Part Description',
                                            field: rowData =>
                                                 <>
                                                    <Typography >
                                                        {rowData?.order_detail?.od_part_description} 
                                                    </Typography>
                                                    <span style={{color: '#757575', fontSize: '12px'}}> 
                                                        {rowData?.order_detail?.od_electrical_connection ? `${rowData?.order_detail?.od_electrical_connection} Connection`: '' }
                                                    </span>
                                                </>,
                                        },
                                        {
                                            heading: 'Quantity Required',
                                            field: rowData => parseFloat(rowData?.latest_pick?.pick_quantity_required),
                                            alignment: 'right'
                                        },
                                        {
                                            heading: 'Quantity Picked',
                                            field: rowData => parseFloat(rowData?.latest_pick?.pick_quantity_picked),
                                            alignment: 'right'
                                        },
                                        {
                                            heading: 'Status',
                                            field: rowData =>   rowData?.latest_pick?.pick_status === 'Not Required' ? 'Not Required' : 
                                                                rowData?.latest_pick?.pick_quantity_to_follow > 0 ? 
                                                                <span className="textError">Short</span> : 
                                                                <span className="textSuccess">Fulfilled</span>
                                        },
                                        {
                                            actions: rowData => ([
                                                {
                                                    name:    'Print Part Label',
                                                    icon:    icons.print,
                                                    onClick: this.printPartLabel(rowData?.order_detail?.od_part_id, rowData?.order_detail?.od_part_number)
                                                }
                                            ])
                                        }
                                    ]}
                                    rows={this.state.lists.completedPicks}
                                />
                            </Grid>
                            <Grid item xs={6}> 
                            
                                <Typography variant="h6" className="text-center mb-3" style={{border: `1px solid ${colors.red}`, color: colors.red, fontWeight: 'bold', padding: '0.5em'}}>
                                    <Grid container>
                                        <Grid item xs={1}>
                                            <AllIcon icon={icons.print} color={colors.red} noMargin/>
                                        </Grid>
                                        <Grid item xs={10}>
                                            Print Part Labels from Actions above
                                        </Grid>
                                        <Grid item xs={1}>
                                            <AllIcon icon={icons.print} color={colors.red} noMargin/>
                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={this.handlePrint}
                                    style={{marginBottom: '10px'}}
                                >
                                    Print Delivery Note
                                </Button>
                                {this.state.pickingDocuments.length > 0 &&
                                    <Button
                                        variant="contained"
                                        color='primary'
                                        size="large"
                                        onClick={()=>this.setState({showAdditional: true})}
                                        fullWidth
                                        style={{marginBottom: '10px'}}
                                    >
                                        Linked Part Files - {_.uniqBy(this.state.pickingDocuments, 'doc_title').length}
                                    </Button>
                                }
                                {this.state.greenaware.length > 0 &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        onClick={this.handlePrintGreenaware}
                                        style={{marginBottom: '10px'}}
                                    >
                                        Print Greenaware Notes
                                    </Button>
                                }
                                
                                <Link to="/sales/order/picking" className='linkStyle'>
                                    <Button 
                                        variant="contained" 
                                        color="secondary"
                                        size="large"
                                        fullWidth
                                        style={{marginBottom: '10px'}}
                                    >
                                        Finish
                                    </Button>
                                </Link>
                                {showAdditional &&
                                    <Dialog
                                        open={showAdditional}
                                        onClose={()=>this.setState({showAdditional: false})}
                                        fullWidth={true} 
                                        maxWidth="sm" 
                                        scroll="body"
                                    >
                                        <DialogTitle>
                                            <Typography>Additional Documents</Typography>
                                        </DialogTitle>
                                        <DialogContent>
                                            <DataTable
                                                config={{
                                                    key: 'file_url_id',
                                                    noHeader: true
                                                }}
                                                columns={[
                                                    {
                                                        field:         'latest_file.file_version',
                                                        fieldPrefix:   'v',
                                                        sizeToContent: true
                                                    }, 
                                                    {
                                                        field:       'doc_title'
                                                    }, 
                                                    {
                                                        actions: i => ([
                                                            {
                                                                name: 'Print',
                                                                icon: icons.print,
                                                                onClick: () => this.props.printA4(i.file_url_pdf || i.file_url_original, i.doc_title)
                                                            },
                                                            {
                                                                name: 'Download',
                                                                icon: icons.download,
                                                                onClick: () => downloadS3File(i.file_url_pdf || i.file_url_original)
                                                            }
                                                        ])
                                                    }
                                                ]}
                                                rows={_.uniqBy(this.state.pickingDocuments, 'doc_title')}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={()=>this.setState({showAdditional: false})} variant="outlined" color="default">Close</Button>
                                            <Button onClick={this.handlePrintAllAdditional} autoFocus variant="contained" color="primary">Print All</Button>
                                        </DialogActions>
                                    </Dialog>
                                }
                            </Grid>
                        </Grid>
                    )}
                    {this.state.showManualDuplicate.length &&
                        <Dialog
                            open={this.state.showManualDuplicate.length > 0}
                            maxWidth="md" 
                            scroll="body"
                        >
                            <DialogTitle>
                                <Typography>Duplicate Serial Numbers</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <Typography>Please note {this.state.showManualDuplicate.length > 1 ? 'these' : 'this'} serial number has been used before</Typography>
                                <DataTable  
                                    config={{
                                        key: 'number',
                                        alternatingRowColours: true,
                                        isLoading: this.state.dataLoading,
                                    }}
                                    columns={[
                                        {
                                            heading: 'Number',
                                            field: rowData => rowData.number,
                                            hideInResponsiveDialog: true,
                                            sizeToContent: true,
                                            import:true
                                        },
                                        {
                                            heading: 'Used',
                                            field: rowData => rowData.dates.map(
                                                i => <>{i} <br></br></>
                                            ),
                                            hideInResponsiveDialog: true,
                                            sizeToContent: true,
                                            import:true
                                        },
                                    ]}
                                    rows={this.state.showManualDuplicate}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleManualDuplicateConfirmationClose} variant="outlined" color="default">Cancel</Button>
                                {/* <Button onClick={this.handleManualDuplicateConfirmationSuccess} autoFocus variant="contained" color="primary">Add</Button> */}
                            </DialogActions>
                        </Dialog>
                    }
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success, cancel) => {
            dispatch(deployConfirmation(message, title, success, cancel))
        },
        playSound: (type, state) => {
            dispatch(playSound(type, state));
        },
        deploySnackBar: (variant, message) => {
            dispatch(deploySnackBar(variant, message));
        },
        printA4:    (data, file) => dispatch(printA4(data, file)),
        printLabel: (data, file) => dispatch(printLabel(data, file)),
        printMulti: (data) => dispatch(printMulti(data)),
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(PickingDialogContent));