import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import { clearPersistence } from 'Actions/StatePersistence/StatePersistence';

const styles = theme => ({
    link: {
        marginLeft: 0,
        textDecoration: 'none',
        color: '#000'
    },
    subLink: {
        textDecoration: 'none',
        color: '#000'
    }
});

const initialState = {
    open: false,
}

class AdminNavItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        if(this.props.navId === this.props.selectedNavId){
            this.setState({
                open: true,
            });
        } else {
            if(this.props.selectedNavId === false){
                if(typeof this.props.submenu !== "undefined"){ 
                    this.props.submenu.forEach(menu => {
                        if(menu.access_route.length > 0 && this.props.pagePath.startsWith(menu.access_route)){
                            this.props.onClick(this.props.navId);
                            this.props.changePage(this.props.name, menu.access_route);
                        }
                    })
                } else {
                    if(this.props.link === this.props.pagePath){
                        this.props.changePage(this.props.name, this.props.link);
                    }
                }
            }
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.selectedNavId !== this.props.selectedNavId){
            if(this.props.navId === this.props.selectedNavId) {
                this.setState({
                    open: true,
                });
            } else {
                if(this.state.open === true){
                    this.setState({
                        open: false,
                    });
                }
            }
        }
    }

    checkExpand(){
        if(typeof(this.props.link) === "undefined") {
            return(
                <React.Fragment>
                    {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </React.Fragment>
            );
        }
    }

    checkNavOpen(){
        if(this.props.navOpen === false) {
            return false
        } else {
            return this.state.open
        }
    }

    render(){
        const { classes } = this.props;
        return (
        <React.Fragment>
            {typeof(this.props.link) !== "undefined" ? 
                <Link className={classes.link} to={this.props.link} onClick={() => { this.props.clearPersistence(); this.props.changePage(this.props.name, this.props.link)}} style={{color: this.state.open === true ? '#fff': null}}>
                    <ListItem 
                        button
                        onClick={() => this.props.submenu === undefined ? this.props.history.push(this.props.link) : this.props.onClick(this.props.navId)} 
                        style={{paddingTop: 3, paddingBottom: 3, backgroundColor: this.checkNavOpen() ? ( this.state.open === true ? '#0282C6': null) : null, color: this.checkNavOpen() ? ( this.state.open === true ? '#fff': null ) : null}}
                    >
                        <ListItemIcon> 
                            <FALightIcon icon={this.props.icon} button noMargin fixedWidth style={{color:  this.checkNavOpen() ? ( this.state.open === true ? '#fff': null ) : null}} />
                        </ListItemIcon>
                        <ListItemText primary={this.props.name} />
                        {this.checkExpand()}
                    </ListItem>
                </Link>
            :
                <ListItem 
                    button
                    onClick={() => this.props.onClick(this.props.navId)} 
                    style={{paddingTop: 3, paddingBottom: 3, backgroundColor: this.checkNavOpen() ? ( this.state.open === true ? '#0282C6': null) : null, color: this.checkNavOpen() ? ( this.state.open === true ? '#fff': null ) : null}}
                >
                    <ListItemIcon> 
                        <FALightIcon icon={this.props.icon} button noMargin fixedWidth style={{color:  this.checkNavOpen() ? ( this.state.open === true ? '#fff': null ) : null}} />
                    </ListItemIcon>
                    <ListItemText primary={this.props.name} />
                    {this.checkExpand()}
                </ListItem>
            }
            {typeof this.props.submenu !== "undefined" ? this.props.submenu.map(menu => {
                return (menu.access_display_on_nav && (
                    <React.Fragment key={menu.access_id}>
                        <Collapse in={this.checkNavOpen()} unmountOnExit>
                            <List component="div" disablePadding>
                                <Link className={classes.subLink} to={menu.access_route} onClick={() => { this.props.clearPersistence(); this.props.changePage(this.props.name, menu.access_route)}}>
                                    <ListItem button style={{paddingTop: 5, paddingBottom: 5, backgroundColor: this.state.open === true ? 'rgba(2,130,198,0.1)': null}}>
                                        <ListItemText disableTypography
                                                    inset
                                                    primary={<Typography variant="body2">{menu.access_name}</Typography>} />
                                    </ListItem>
                                    {menu.access_display_divider === 1 &&
                                        <Divider />
                                    }
                                </Link>
                            </List>
                        </Collapse>
                    </React.Fragment>  
                )) || (
                    null
                );
            }) : null}
        </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    clearPersistence: () => dispatch(clearPersistence())
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(AdminNavItem));