import React, {Component} from 'react';

import { withStyles } from '@material-ui/core/styles';
import { SvgIcon }    from '@material-ui/core';

//icons
import {ReactComponent as DirectDebitIcon}          from 'Assets/Icons/direct-debit-icon.svg';
import {ReactComponent as InternalOrderProcessIcon} from 'Assets/Icons/internal-order-process-icon.svg';
import {ReactComponent as ClenawareIcon}            from 'Assets/Icons/clenaware.svg';

const styles = theme => ({
    iconStyle:{
        marginRight:theme.spacing(1),
        //marginLeft:theme.spacing (1),
        color: 'rgba(0,0,0,0.65)'
    },
    iconStyleLight:{
        marginRight:theme.spacing(1),
        //marginLeft:theme.spacing (1),
        color: 'rgba(0,0,0,0.54)'
    },
    iconStyleDisabled:{
        marginRight:theme.spacing(1),
        //marginLeft:theme.spacing (1),
        color: 'rgba(0,0,0,0.2)'
    },
    iconStyleError:{
        marginRight:theme.spacing(1),
        //marginLeft:theme.spacing (1),
        color: '#f44336'
    }
});

const icons = {
    DirectDebit:            {component: DirectDebitIcon,            viewBox: "-10 -24 90 100"},
    InternalOrderProcess:   {component: InternalOrderProcessIcon,   viewBox: "-75 -24 550 550"},
    clenAware:              {component: ClenawareIcon,              viewBox: "-75 -24 550 500"}
}

class CiCustomIcon extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        const props = this.props;  
        const Svg = icons[props.icon];

        return (
            <SvgIcon
                {...Svg}
                style={{
                    cursor: this.props.link || (this.props.button || this.props.buttonPrimary) ? 'pointer' : 'default',
                    margin: this.props.noMargin && 0,
                    height: (!this.props.fixedWidth && (this.props.size === 'avatar' ? '27.5' : (this.props.size === 'xxxxlarge' ? '150' : (this.props.size === 'xxxlarge' ? '100' : (this.props.size === 'xxlarge' ? '75' : (this.props.size === 'xlarge' ? '50' : ( this.props.size === 'large' ? '35' : (this.props.size === 'xmedium' ? '23' : (this.props.size === 'small' ? '15' : ( this.props.size || '25' ) /* Medium (default) */))))))))),
                    width: (!this.props.fixedHeight && (this.props.size === 'avatar' ? '27.5' : (this.props.size === 'xxxxlarge' ? '150' : (this.props.size === 'xxxlarge' ? '100' : (this.props.size === 'xxlarge' ? '75' : (this.props.size === 'xlarge' ? '50' : ( this.props.size === 'large' ? '35' : (this.props.size === 'xmedium' ? '23': (this.props.size === 'small' ? '15' : ( this.props.size || '25' ) /* Medium (default) */))))))))) || 'auto',
                    ...this.props.style
                }}
                height={(!this.props.fixedWidth && (this.props.size === 'avatar' ? '27.5' : (this.props.size === 'xxxxlarge' ? '150' : (this.props.size === 'xxxlarge' ? '100' : (this.props.size === 'xxlarge' ? '75' : (this.props.size === 'xlarge' ? '50' : ( this.props.size === 'large' ? '35' : (this.props.size === 'small' ? '15' : ( this.props.size || '25' ) /* Medium (default) */))))))))}
                width={(!this.props.fixedHeight && (this.props.size === 'avatar' ? '27.5' : (this.props.size === 'xxxxlarge' ? '150' : (this.props.size === 'xxxlarge' ? '100' : (this.props.size === 'xxlarge' ? '75' : (this.props.size === 'xlarge' ? '50' : ( this.props.size === 'large' ? '35' : (this.props.size === 'small' ? '15' : ( this.props.size || '25' ) /* Medium (default) */))))))))}
                className={this.props.className ? this.props.className : (this.props.disabled ? this.props.classes.iconStyleDisabled : (this.props.error ? this.props.classes.iconStyleError : (this.props.light ? this.props.classes.iconStyleLight : this.props.classes.iconStyle)))}
             />
        )
    }

}

export default withStyles(styles)(CiCustomIcon);