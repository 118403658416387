import { 
    Typography,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid
 } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import DataTable from 'Components/Common/DataTables/DataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import API from 'API';

class Forms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            open: false
        }
    }

    componentDidMount(){
        // this.getFormsForPart();
    }

    getFormsForPart = () => {
        API.get(`/parts/${this.props.partId}/forms`)
        .then((result) => {
            this.setState({
                rows: result.data,
                open: true
            })
        })
    }

    handleOpen = () => {
        if(!this.state.open){
            this.getFormsForPart();
        } else {
            this.setState({
                open: false
            });
        }
    }

    render() {
        const { originalPart } = this.props;
        const { rows, open } = this.state;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={this.handleOpen}>
                    <Typography>Forms</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {open &&
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <br></br>
                                <Typography variant="body2" gutterBottom>
                                    <b>Linked Forms</b>
                                </Typography>
                                {rows?.linkedForms?.length > 0 ? 
                                    <>
                                        <Typography variant="subtitle2" style={{display:'flex', alignItems: 'center'}}>
                                            <FALightIcon icon='info-circle' size={12} />Below is a list of forms that {originalPart} is linked to 
                                        </Typography>
                                        <br></br>
                                        <DataTable  
                                            config={{
                                                key: 'id',
                                                plainHeader: true
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Area',
                                                    field: rowData => rowData.area,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Category',
                                                    field: rowData => rowData.cat,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Sub Category',
                                                    field: rowData => rowData.subCat,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Form Name',
                                                    field: rowData => rowData.nm,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Version',
                                                    field: rowData => 'v'+rowData.v,
                                                    important: true,
                                                    sizeToContent: true,
                                                    alignment: 'center',
                                                },
                                                {
                                                    heading: 'Status',
                                                    field: rowData => rowData.st,
                                                    sizeToContent: true,
                                                },
                                                {
                                                    actions: rowData => {
                                                        return [
                                                            {name: 'View', icon: 'search', link: '/forms/view/' + rowData.id}
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={rows?.linkedForms}
                                        />
                                    </>
                                :
                                    <Typography variant="body2"><i>There are 0 linked forms found.</i></Typography>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" gutterBottom>
                                    <b>Associated Forms</b>
                                </Typography>
                                {rows?.associatedForms?.length > 0 ? 
                                    <>
                                        <Typography variant="subtitle2" style={{display:'flex', alignItems: 'center'}}>
                                            <FALightIcon icon='info-circle' size={12} />Below is a list of forms that {originalPart} is associated with
                                        </Typography>
                                        <br></br>
                                        <DataTable  
                                            config={{
                                                key: 'id',
                                                plainHeader: true
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Area',
                                                    field: rowData => rowData.area,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Category',
                                                    field: rowData => rowData.cat,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Sub Category',
                                                    field: rowData => rowData.subCat,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Form Name',
                                                    field: rowData => rowData.nm,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Version',
                                                    field: rowData => 'v'+rowData.v,
                                                    important: true,
                                                    sizeToContent: true,
                                                    alignment: 'center',
                                                },
                                                {
                                                    heading: 'Status',
                                                    field: rowData => rowData.st,
                                                    sizeToContent: true,
                                                },
                                                {
                                                    actions: rowData => {
                                                        return [
                                                            {name: 'View', icon: 'search', link: '/forms/view/' + rowData.id}
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={rows?.associatedForms}
                                        />
                                    </>
                                :
                                    <Typography variant="body2"><i>There are 0 associated forms found.</i></Typography>
                                }
                            </Grid>
                        </Grid>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default Forms;
