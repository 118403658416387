import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { Button, Grid, TextField } from '@material-ui/core/';

import { closeDialog } from 'Actions/Dialog/Dialog';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea           from 'Components/Common/Inputs/Textarea';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import MapMarker          from 'Components/Common/Map/MapMarker';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import TargetMap          from 'Components/Common/Map/TargetMap';
import DragFileInput      from 'Components/Common/Inputs/DragFileInput';

import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = {
    formData: {
        lng:          0,
        lat:          0,
        name:         '',
        notes:        '',
        category:     0,
        file:         '',
        existingFile: '',
        address:      '',
    }, 
    formErrors: {},
    catagories: [],
    moveMode: false,
    isLoading: true,
}

class UpdateMarkerDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getCatagories();
    }

    getCatagories(){
        API.get('/engineers/maps/categories')
        .then(res => {
            this.setState({ 
                catagories: _.map(res.data, i => ({
                    value: i.mmc_id,
                    label: `${i.mmc_category} | ${i.mmc_name}`,
                    color: i.mmc_color,
                    icon:  i.mmc_icon
                })), 
            }, this.getMarker);
        })
    }

    getMarker = () => {
        API.get(`/engineers/maps/markers/${this.props.id}`)
        .then(res => {
            this.setState({...this.state, isLoading: false, formData: {
                lng:          res.data.mm_lng,
                lat:          res.data.mm_lat,
                name:         res.data.mm_name,
                notes:        res.data.mm_notes,
                category:     res.data.mm_category_id,
                existingFile: res.data.mm_image,
                address:      res.data.mm_input_address
            }});
        })
    }

    handleSelectChange = name => e => {
        let value = e ? e.value : initialState.formData[name];
        this.setState({formData: {...this.state.formData, [name]: value}}); 
    }

    handleChange = name => e => {
        let value = e.target.value;
        this.setState({formData: {...this.state.formData, [name]: value ?? ''}});
    }

    updateMarker = () => {

        this.setState({isLoading: true}, () => {
            let newFormData = new FormData();
            Object.keys(this.state.formData).forEach(key => {
                newFormData.append(key, this.state.formData[key]);
            });
    
            API.post(`/engineers/maps/markers/${this.props.id}`, newFormData)
            .then( res => {
                if(res.data.errors && res.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(res.data.errors),
                        isLoading: false
                    });
                } else {
                    this.props.onUpdate();
                }
            });
        });

    }

    setLngLat = (lng, lat) => {
        this.setState({formData: {...this.state.formData, lng: parseFloat(lng), lat: parseFloat(lat)}});
    }

    handleFileChange = field => (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: file
            }
        })
    }

    clearFile = field => () => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: ''
            }
        })
    }

    render(){

        const { formData:{lng, lat, name, notes, category, existingFile, file, address} , isLoading, catagories, moveMode, formErrors} = this.state;

        if ( isLoading && catagories.length === 0) return (<LoadingCircle/>);
        return (
            <>
                <Grid container spacing={3} style={{justifyContent: 'space-evenly', alignItems: 'stretch'}}>
                    <Grid item xs={6} spacing={1} style={{alignItems: 'center', height: '100%'}}>
                        <Grid container spacing={1} style={{height: '100%'}}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Address"
                                    value={address || ''}
                                    onChange={this.handleChange('address')} 
                                />
                            </Grid>
                            <Grid item xs={12} key={`${lat}${lng}`}>
                                {lat && lng && 
                                    <TargetMap
                                        lat={lat}
                                        lng={lng}
                                        height={300}
                                        setLngLat={this.setLngLat}
                                        marker={<MapMarker icon={_.find(catagories, {value: category})?.icon} color={_.find(catagories, {value: category})?.color} size={30}/>}
                                    />
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label='Latitude'
                                    value={lat}
                                    type='number'
                                    inputProps={{
                                        max: 180,
                                        min: -180,
                                    }}
                                    onChange={(e) => { this.setLngLat(lng, parseFloat(e.target.value)) }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label='Longitude'
                                    value={lng}
                                    type='number'
                                    inputProps={{
                                        max: 180,
                                        min: -180,
                                    }}
                                    onChange={(e) => { this.setLngLat(parseFloat(e.target.value), lat) }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} style={{height: '100%', alignItems: 'stretch'}}>
                        <PaddedPaper style={{height: '100%', padding: 16, minHeight: 410}}>
                            <AutoCompleteSelect
                                fullWidth
                                label='Category'
                                options={catagories}
                                value={category}
                                onChange={this.handleSelectChange('category')}
                            />
                            <TextField margin='normal' fullWidth value={name} label='Name' onChange={this.handleChange('name')}/>
                            <Textarea margin='normal' fullWidth value={notes} label='Notes' onChange={this.handleChange('notes')}/>
                            <DragFileInput 
                                id="file"
                                name='file'
                                label="Upload Image:"
                                file={file}
                                onChange={this.handleFileChange('file')}
                                cancelOnClick={this.clearFile('file')}
                                emptyText={existingFile ? "Marker has attached image" : "No image selected"}
                                error={formErrors.file}
                                errorText={formErrors.file}
                            />
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button variant='outlined' onClick={this.props.closeDialog} >Close</Button>
                        <Button variant='contained' color='primary' onClick={this.updateMarker} disabled={!this.state.formData.category || !name || name === '' }>Update</Button>
                    </Grid>
                </Grid>
            </>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
        closeDialog: () => dispatch(closeDialog()),
    }
}

export default connect(null, mapDispatchToProps)(UpdateMarkerDialog);