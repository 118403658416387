import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { Grid, Link } from '@material-ui/core/';

import DataTable                   from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle               from 'Components/Common/LoadingCircle/LoadingCircle';
import StickyNotes                 from 'Components/Common/StickyNotes/StickyNotes';
import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';

import { colors }    from 'Helpers/ColourHelper';
import icons         from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import { downloadS3File } from 'Functions/MiscFunctions';

const initialState = {
    customerId: 0,
    sites:      [],
    isLoading:  true,
}

class siteAddresses extends Component {
    constructor(props) {
        super(props);
        this.state = {...initialState, customerId: this.props.customerId};
    }

    componentDidMount = () => {
        this.getJobSheets();
    }

    getJobSheets  = () => {
        API.get(`/customers/${this.state.customerId}/sales/orders/engineerJobSheets`, {params: {siteId: this.props.siteId}})
        .then(res => {
            this.setState({
                sites: res.data, 
                isLoading: false
            });
        });
    }

    handleShowStickyNote = orderId => { this.props.deployDialog(<StickyNotes orderId={orderId} dialog/>, 'Sticky Notes', null,'xs') }

    handleDownloadOpen = orderId => this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md');

    render(){
        const { isLoading, sites } = this.state;

        const subTypeIcons = {
            'Other'                         : 'O',
            'Machine Installation'          : 'MI',
            'Machine Maintenance Service'   : 'MMS',
            'Machine Breakdown Repair'      : 'MBR',
            'Machine Assessment'            : 'O',
            'TBC'                           : 'TBC',
        }

        if (isLoading) return (<LoadingCircle/>);
        return (
            <Grid container xs={12}>
                <Grid item xs={12}>
                    <DataTable
                        config={{
                            key:                    'order_id',
                            alternatingRowColours:  true,
                            responsiveimportant:    true,
                            pagination:             true,
                            filterMod:              {clearable: true},
                            dropRow: {
                                droppable: rowData => rowData.files.length > 1,
                                style: {fontWeight: 'bold',},
                                columns: [
                                    {
                                        colSpan: 3,
                                        field:   rowData => null
                                    },
                                    {
                                        field:    rowData => <Link onClick={() => {downloadS3File(rowData.u)}}>{rowData.n}</Link>,
                                        truncate: 400,
                                    },
                                    {
                                        field:      rowData => `v${rowData.v}`,
                                        alignment:  'center',
                                    },
                                    {
                                        field:     rowData => subTypeIcons[rowData.t],
                                        alignment: 'center',
                                    },
                                    {
                                        field:   rowData => clenyDate(rowData.d),
                                    },
                                    {
                                        field:   rowData => null,
                                    }
                                ],
                                data: rowData => _.orderBy(_.filter(rowData.files, i => i.v !== rowData.files.length ), ['v'], ['desc'])
                            }
                        }}
                        columns={[
                            {
                                heading:        'Order Date',
                                field:          'order_date',
                                fieldFormat:    'clenydate',
                                important:      true,
                                sizeToContent:  true,
                                dataRef:        'order_date',
                                filter:         'dateRange',
                                filterMod:      {displayOrder: 1}
                            },
                            {
                                actionsCustomHeader:    true,
                                important:              true,
                                heading:                'Reference',
                                actions:                rowData => {
                                                            return [
                                                                { label: rowData.full_reference,                                link: `/sales/order/view/${rowData.order_id}`,              type:'plainLink'},
                                                                { label: rowData?.quotation_full_reference?.full_reference,     link: `/sales/quotation/view/${rowData.order_quote_id}`,    type:'plainLink', class: "textSecondary"}
                                                            ]
                                                        },
                                dataRef:                'full_reference',
                                alignment:              'left',
                                filter:                 'custom',
                                filterMod:              {
                                                            dataRef:    (search, rowData) => {return `${rowData.order_date}|${rowData.full_reference}|${rowData.order_delivery_cust_address_name}|${rowData.order_delivery_cust_address}|${_.head(_.orderBy(rowData.files,['i'], ['desc'])).n}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                                            label:      'Keywords',
                                                            styleGrid:  {width: '20%'},
                                                        }
                            },
                            {
                                heading:        'Site',
                                field:          'order_delivery_cust_address_name',
                                important:      true,   
                                dataRef:        'order_delivery_cust_address_name',
                                sizeToContent:  true,
                                filter:         'select',
                                filterMod:      {
                                                    styleGrid:  {width: '20%'},
                                                }
                            },
                            {
                                heading:    'Job Sheet',
                                field:      rowData => <Link onClick={() => {downloadS3File(_.head(_.orderBy(rowData.files,['i'], ['desc'])).u)}}>{_.head(_.orderBy(rowData.files,['i'], ['desc'])).n}</Link>,
                                important:  true,   
                                dataRef:    rowData => _.head(_.orderBy(rowData.files,['i'], ['desc'])).n,
                                truncate:   400,
                            },
                            {
                                heading:        'Version',
                                field:          rowData => `v${rowData.files.length}`,
                                important:      true,   
                                dataRef:        rowData => rowData.files.length,
                                sizeToContent:  true,
                                alignment:      'center',
                            },
                            {
                                heading:        'Type',
                                field:          rowData => subTypeIcons[_.head(_.orderBy(rowData.files,['i'], ['desc'])).t],
                                sizeToContent:  true,
                                alignment:      'center',
                                dataRef:        rowData => _.head(_.orderBy(rowData.files,['i'], ['desc'])).t,
                                filter:         'select',
                                filterMod:      { styleGrid:  {width: '15%'}, }
                            },
                            {
                                heading:        'Uploaded',
                                field:          rowData => clenyDate(_.head(_.orderBy(rowData.files,['i'], ['desc'])).d),
                                sizeToContent:  true,
                                alignment:      'center',
                                dataRef:        rowData => _.head(_.orderBy(rowData.files,['i'], ['desc'])).d
                            },
                            {
                                actions: rowData => {
                                    return [
                                        {name: 'Sticky Notes',           icon: icons.stickyNote, onClick: () => this.handleShowStickyNote(rowData.order_id), hide:rowData.sticky_notes.length === 0, color: _.filter(rowData.sticky_notes, i =>!i.sn_completed).length > 0 && colors.yellow },
                                        {name: 'Download',               icon: 'download',       onClick: () => this.handleDownloadOpen(rowData.order_id)},
                                        {name: 'View',                   icon: 'search',         link: '/sales/order/view/' + rowData.order_id},
                                    ]
                                }
                            }
                        ]}
                        rows={_.orderBy(sites,['order_date'],['desc'])}
                    />
                </Grid>
            </Grid>
        )
    }

}

const mapDispatchToProps = dispatch => ({
    deployDialog:       (content, title, variant, size)  => dispatch(deployDialog(content, title, variant,size)),
    closeDialog:        ()                               => dispatch(closeDialog()),
})

export default connect(null, mapDispatchToProps)(siteAddresses)
