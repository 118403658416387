import React from 'react';
import Typography from '@material-ui/core/Typography';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import SnackBar from './../../Common/SnackBars/SnackBar'
import Button from '@material-ui/core/Button';
import API from '../../../API';
import _ from 'lodash';
import WarrantyTermsList from './WarrantyTermsList';

const initialState = {
    formData: {
        name: '',
        duration: ''
    },
    formErrors: {},
    snackbarOpen: false,
    confirmationOpen: false,
    warrantyTerms: {},
    warrantyTermsList: [],
    access: {
        addWarrantyType: false
    },
    isLoading: true
}

class WarrantyTerms extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.checkAccess();
        this.getWarrantyTerms();
    }

    getWarrantyTerms = () => {
        API.get('/parts/warrantyTerms')
        .then(result => {
            if(result.data) {
                let warrantyTermsList = _.map(result.data, (el) => {
                    return _.assign({
                        value: el.pwt_id,
                        name: el.pwt_name,
                        duration: el.pwt_duration
                    });
                });
                this.setState({
                    warrantyTerms: result.data,
                    warrantyTermsList,
                    isLoading: false
                });
            }
        });
    };
    
    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/warranty-terms'), 
        ])
        .then(([nameRes]) =>  {
            nameRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addWarrantyType: nameRes.data.has_access
                    }
                });
        });
    }

    /*
     * Add Warranty Terms
     */
    addWarrantyTerm = () => {
        API.post('/parts/warrantyTerms', this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.checkAccess();
                this.getWarrantyTerms();
            }
        });
    }
    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    };
    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };
    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true
        });
    };
    handleConfirmationClose = () => {
        this.setState({
            confirmationOpen: false
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: false
        });
        this.addWarrantyTerm();
    };
    render() {
        const { warrantyTerms, formData, formErrors, snackbarOpen, confirmationOpen } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Warranty Terms
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} md={6}>
                            <PaddedPaper>
                                <Typography variant="h6">
                                    Add Warranty Term
                                </Typography>
                                <form onChange={this.handleChange} noValidate autoComplete="off">
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="name"
                                                name="name"
                                                label="Name *"
                                                value={formData.name}
                                                error={formErrors && formErrors['name'] && true}
                                                helperText={formErrors && formErrors['name']}
                                                margin="normal"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="duration"
                                                name="duration"
                                                label="Duration (Months) *"
                                                value={formData.duration}
                                                error={formErrors && formErrors['duration'] && true}
                                                helperText={formErrors && formErrors['duration']}
                                                margin="normal"
                                                type="number"
                                                inputProps={{min: 0}}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className='buttonRow'>
                                        <Button 
                                            onClick={this.handleConfirmationOpen}
                                            variant="contained" 
                                            color="primary" 
                                            disabled={!this.state.formData.name || !this.state.formData.duration}
                                        >
                                            Add
                                        </Button>
                                    </div>
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <WarrantyTermsList warrantyTerms={warrantyTerms} isLoading={this.state.isLoading} />
                    </PaddedPaper>
                </Grid>
                <ConfirmationDialog 
                    open={confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Add A New Warranty Type?" 
                    message="Are you sure you want to add a new warranty type?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message="You have successfully added a new warranty type"
                />
            </Grid>
        );
    }
}

export default WarrantyTerms;