import * as NotificationActionTypes from './Types/Types';
import API from '../../API';

export function handleIamActive(staffId) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.I_AM_ACTIVE,
            payload: staffId
        });
    }
}

export function handleLoadStaffStatus(status) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.LOAD_STAFF_STATUS,
            payload: status
        });
    }
}

export function handleNotifications(result) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.HANDLE_NOTIFICATIONS,
            payload: result
        });
    }
}

export function handleMinusMsgBadge(count) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.MINUS_UNREAD_MESSAGE,
            payload: count
        });
    }
}

export function handleDiscardNotification(id) {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.DISCARD_NOTIFICATION,
            payload: id
        });
    }
}

export function handleDiscardAllNotifications() {
    return (dispatch) => {
        dispatch({
            type: NotificationActionTypes.DISCARD_ALL_NOTIFICATIONS
        });
    }
}

export function loadNotifications(ln) {
    return (dispatch, getState) => {  
        const state = getState().notifications,
                ln = state.ln;

        API.get('/notifications',
        {
            params: {
                ln,
            },
            props: {
                noLoading: true,
                handleNotifications: true,
                ignoreErrors: true
            }
        })
        // .then(res => {
        //     if(res && res.data) {
        //         dispatch(handleNotifications(res.data))
        //     }
        // })
    }
}

export function loadStaffStatus() {
    return dispatch => {    
        API.get('/staff/status')
        .then(res => {
            if(res && res.data) {      
                dispatch(handleLoadStaffStatus(res.data))
            }
        })
    }
}

export function dismissAllNotifications() {
    return dispatch => {    
        API.put(`/misc/notifications/dismiss`, 
            {
                props: {
                    noLoading: true
                }
            }
        )
        .then(res => {
            if(res && res.data) {      
                dispatch(handleDiscardAllNotifications())
            }
        })
    }
}

export function putNotificationStatus(id, action) {
    return dispatch => {    
        API.put(`/misc/notifications/${id}`, 
            { 
                status: action 
            },
            {
                props: {
                    noLoading: true
                }
            }
        )
        .then(res => {
            if(res && res.data) {      
                dispatch(handleDiscardNotification(id))
            }
        })
    }
}