import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import Textarea from '../../Common/Inputs/Textarea';
import {colors} from 'Helpers/ColourHelper';

class EmailDetails extends PureComponent {
    
    render() {
        const { templateList, staffList, formErrors, handleChange, handleSelectChange, emailTemplate, emailText, approvedRequiredBy } = this.props;
        return (
            <React.Fragment>
                <Typography variant="h6">
                    Email Details
                </Typography>
                <form noValidate autoComplete="off">
                    <FormControl fullWidth margin="normal">
                        <AutoCompleteSelect 
                            options={templateList} 
                            label='Email Template'
                            value={emailTemplate}
                            onChange={handleSelectChange('emailTemplate')}
                        />
                    </FormControl>
                    <Textarea
                        id="emailText"
                        name="emailText"
                        label="Email Text *"
                        value={emailText}
                        rows={10}
                        error={formErrors && formErrors['emailText']}
                        onChange={handleChange}
                    />
                    <FormControl margin="normal" fullWidth>
                        <AutoCompleteSelect 
                            options={staffList} 
                            label='Approval Required By *'
                            onChange={handleSelectChange('approvedRequiredBy')}
                            error={formErrors && formErrors['approvedRequiredBy'] && true}
                            errorText={formErrors && formErrors['approvedRequiredBy']}
                            value={approvedRequiredBy}
                        />
                    </FormControl>
                </form>
                {formErrors && formErrors.generic && (
                    <React.Fragment>
                        <Typography component={"div"} style={{color: colors.red}}>
                            {formErrors.generic}
                        </Typography>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default EmailDetails;
    