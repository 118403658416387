import React, { Component} from 'react';
import _ from 'lodash';
import API from '../../../../API';
import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DataTable from '../../../Common/DataTables/DataTable';

class DesignChangeOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            open: false
        }
    }

    componentDidMount(){
        // this.getDCOforPart();
    }

    getDCOforPart = () => {
        API.get(`/researchDevelopment/changeOrders/part/${this.props.partId}/all`)
        .then((result) => {
            result.data.forEach((dco) => {
                let version = Math.max(dco.dco_details_design_issue, dco.dco_details_pdf_issue);
                dco.version = 'From v' + (version - 1) + ' to v' + version;
            });
            this.setState({
                rows: result.data,
                open: true
            })
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.searchResults !== prevProps.searchResults) {
            this.setState({
                rows: _.orderBy(this.props.searchResults, 'dco_date_added', 'desc')
            });
        }
    }

    downloadFile = rowData => {
        window.open(rowData.dco_file_url, '_blank');
    }

    handleOpen = () => {
        if(!this.state.open){
            this.getDCOforPart();
        } else {
            this.setState({
                open: false
            });
        }
    }

    render() {
        const { rows, open } = this.state;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={this.handleOpen}>
                    <Typography>Design Change Orders</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {open &&
                        <>
                            {rows.length > 0 ? 
                                <DataTable 
                                    config={{
                                        key: 'dco_id',
                                        pagination: false,
                                        plainHeader: true,
                                    }}
                                    columns={[
                                        {
                                            heading: 'Reference',
                                            field: row => row.dco_reference
                                        },
                                        {
                                            heading: 'Title',
                                            field: row => row.dco_title
                                        },
                                        {
                                            heading: 'Version',
                                            field: row => row.version
                                        },
                                        {
                                            actions: row => {
                                                return [
                                                    {name: 'Download', icon: 'download', onClick: this.downloadFile, disabled: (!row.dco_file_url)},
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={rows}
                                /> 
                            :
                                <Typography>There are 0 Design Change Orders found.</Typography>
                            }
                        </>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default DesignChangeOrders;
