import React, { Component } from 'react';
import API from 'API';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';

import AllIcon from 'Components/Common/Icons/AllIcon';

import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';



import SingleEmailDialog from 'Components/Common/Dialogs/SingleEmailDialog';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { connect } from 'react-redux';

class ReturnEmailDialog extends Component {
    constructor(props) {
        super(props);    
    }

    handleEmail = status => () => {
        let template = 0;
        let title = '';

        switch(status) {
            case "Acknowledgement":
                template = 9;
                title = 'You Are About To Email - Customer Return Acknowledgement';
            break;
            case "Approved":
                template = 15;
                title = 'You Are About To Email - Customer Return confirmation';
            break;
            case "Declined":
                template = 10;
                title = 'You Are About To Email - Customer Return Declined';
            break;
            case "Cancelled":
                template = 14;
                title = 'You Are About To Email - Customer Return Cancelled';
            break;
            case "Received":
                template = 12;
                title = 'You Are About To Email - Customer Return Received Confirmation';
            break;
            case "Completed":
                template = 13;
                title = 'You Are About To Email - Customer Return Complete Confirmation';
            break;
            case 'Awaiting Collection':
                template = 16;
                title = 'You Are About To Email - Customer Return Collection Confirmation';
            break;
        }

        API.get(`/customerReturns/emailTemplates/${template}`)
        .then(res => {
            this.props.deployDialog(
                <SingleEmailDialog
                    contactId={this.props.cr.cr_collection_address_contact_id}
                    customerId={this.props.cr.cr_cust_id}
                    subject={res.data.cret_name}
                    body={res.data.cret_text}
                    emailUrl={`/customerReturns/returns/${this.props.cr.cr_id}/sendEmail`}
                    postParams={{
                        templateId: template,
                    }}
                    onSubmit={this.props.closeDialog}
                    onClose={this.props.closeDialog}
                />, title, 'success', 'md'
            )
        })
    }

    render(){

        let { classes, cr } = this.props;

        let customerReturns = 0;

        switch(cr.cr_status){
            case 'Declined':
                customerReturns = -1;
                break;
            case 'Cancelled':
                customerReturns = -2;
                break;
            case 'Awaiting Confirmation':
                customerReturns = 1;
                break;
            case 'Awaiting Collection':
                customerReturns = 2;
                break;
            case 'Awaiting Review':
                customerReturns = 3;
                break;
            case 'Awaiting Outcome':
            case 'Completed':
                customerReturns = 99;
                break;
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="caption">Customer Returns</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={5}>
                            <Button
                                startIcon={
                                    <AllIcon
                                        icon={icons.orderAwaitingApproval}
                                        color={colors.orange}
                                    />
                                }
                                className={classes.button}
                                onClick={this.handleEmail("Acknowledgement")}
                            >
                                Customer Return Acknowledgement
                            </Button>
                        </Grid>
                        {(customerReturns > 0) &&
                            <Grid item xs={12} md={5}>
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.true}
                                            color={colors.orange}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleEmail("Approved")}
                                >
                                    Customer Return Confirmation
                                </Button>
                            </Grid>
                        }       
                        {(customerReturns > 1) &&
                            <Grid item xs={12} md={5}>
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.truck}
                                            color={colors.orange}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleEmail("Awaiting Collection")}
                                >
                                    Customer Return Collection Confirmation
                                </Button>
                            </Grid>
                        }
                        {(customerReturns > 2) &&
                            <Grid item xs={12} md={5}>
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.truck}
                                            color={colors.orange}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleEmail("Received")}
                                >
                                    Customer Return Received Confirmation
                                </Button>
                            </Grid>
                        }
                        {(customerReturns > 3) &&
                            <Grid item xs={12} md={5}>
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.true}
                                            color={colors.green}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleEmail("Completed")}
                                >
                                    Customer Return Complete Confirmation
                                </Button>
                            </Grid>
                        }
                        {(customerReturns === -1) &&
                            <Grid item xs={12} md={5}>
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.false}
                                            color={colors.red}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleEmail("Declined")}
                                >
                                    Customer Return Declined
                                </Button>
                            </Grid>
                        }
                        {(customerReturns === -2) &&
                            <Grid item xs={12} md={5}>
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.false}
                                            color={colors.red}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleEmail("Cancelled")}
                                >
                                    Customer Return Cancelled
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant="outlined"
                        onClick={this.props.closeDialog}
                    >Close</Button>
                </Grid>
            </Grid>
        )
    }

}

const styles = {
    button: {
        textTransform: "capitalize",
    },
    buttonRow: {
        float: "right",
        margin: 0,
    },
}


const mapDispatchToProps = dispatch => ({
    closeDialog:  ()                                    => dispatch(closeDialog()),
    deployDialog: (content, title, variant, size='md')  => dispatch(deployDialog(content, title, variant, size)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(ReturnEmailDialog));