import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';

import { colors }        from 'Helpers/ColourHelper';
import icons             from 'Helpers/IconHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

import LoadingCircle     from 'Components/Common/LoadingCircle/LoadingCircle';
import AllIcon           from 'Components/Common/Icons/AllIcon';
import PaddedPaper       from 'Components/Common/Paper/PaddedPaper';
import ImageWithError    from 'Components/Common/ImageWithError/ImageWithError';
import QrScanWrapper     from 'Components/Warehouse/Common/Inputs/QrScanWrapper';
import SinglePrintDialog from 'Components/Warehouse/Common/Dialogs/SinglePrintDialog';

import { Typography, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const initialState = {
    parts: [],
    part: null,
    isLoading: true,
    printingDialog:    false,
};

class Stock extends Component {
    constructor(props) {
        super(props);    
        this.state = initialState;
        this.timeout = null;
    }

    componentDidMount = () => {
        this.getParts();
    }

    componentWillUnmount = () => {
        clearTimeout(this.timeout);
    }

    downloadPartLabel = () => {
        API.get('/parts/' + this.state.part.id + '/partLabel')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, 'part_label_'+this.state.part.number+'.pdf');
            }
        });
    }

    getParts = () => {
        API.get('/parts/withStockActivity')
        .then((partRes) =>  {
            this.setState({
                parts: _.map(partRes.data, i => _.assign({
                    id:          i.part_id,
                    number:      i.part_number,
                    description: i.part_description,
                    img:         i.default_image?.file_path,
                    stock:       i.stock.stock_current_qty,
                    pending:     i.stock.stock_pending,
                    predicted:   parseFloat(parseFloat(i.stock.stock_current_qty) + parseFloat(i.stock.stock_pending)).toFixed(3),
                    level:       i.stock.stock_level,
                    minMax:      `${parseFloat(i.stock.stock_minimum_level)} / ${parseFloat(i.stock.stock_maximum_level)}`,
                    value:       parseFloat(i.stock.stock_current_qty) * parseFloat(i?.default_cost?.cost_per_unit),
                })),
                isLoading: false,
            });
        });
    }

    _handleKeyDown = (e) => {
        if (this.state.printingDialog && e.ket === 'F3') this.setState({printingDialog: null});
        return;
    }

    handleScan = number => {
        if (number) {
            const { parts } = this.state;
            const part = _.find(parts, {number});
            if (part) { this.setState({part}); } 
        }
    }

    downloadStockLabel = (type) => () => {
        this.timeout = setTimeout(
            ()=> {
                clearTimeout(this.timeout);
                API.get('/parts/' + this.state.part.id + '/stockLabel', {
                    params: {
                      type: type,
                      action: 'print'
                    }
                }).then(result => {
                    if(result.data) this.setState({printingDialog: result.data});
                });
            }, 500
        )
    }

    render = () => {
        const { part, isLoading, printingDialog } = this.state;

        if (isLoading) { return <LoadingCircle />; }

        return(
            <QrScanWrapper
                handleScan={this.handleScan}
                parentScan={this._handleKeyDown}
            >
                <Grid container spacing={1} style={{height: '100%'}}> 
                    {!!part ? 
                        <>
                            <Grid item container spacing={1} xs={12} style={{alignItems: 'center'}}>
                                <Grid item xs={4} style={{maxHeight: '6em'}}>
                                    <ImageWithError
                                        src={part?.img}
                                        height='100%'
                                        width='100%'
                                        style={{
                                            objectFit: 'contain',
                                            maxHeight: '6em'
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant='h5' style={{textAlign: 'left', fontWeight: 'bold'}}>{part?.number}</Typography>
                                    <Typography variant='h6' style={{textAlign: 'left'}}>{part?.description}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: '1.5em'}}>
                                <PaddedPaper>
                                    <Grid container spacing={1} style={{alignItems: 'center'}}>
                                        <Grid item>
                                            <AllIcon icon={icons.stock} size='large'/>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant='body2' style={{fontWeight: 'bold'}}>Stock</Typography>
                                            <Typography variant='h5' style={{fontWeight: 'bold'}}>{part?.stock}</Typography>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={6}>
                                <PaddedPaper>
                                    <Grid container spacing={1}  style={{alignItems: 'center'}}>
                                        <Grid item>
                                            <AllIcon icon={icons.stockPending} size='25' />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant='body1' style={{fontWeight: 'bold'}}>Pending</Typography>
                                            <Typography variant='h5' style={{fontWeight: 'bold'}}>{part?.pending}</Typography>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={6}>
                                <PaddedPaper>
                                    <Grid container spacing={1}  style={{alignItems: 'center'}}>
                                        <Grid item >
                                            <AllIcon icon={icons.boxes} size='25'/>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant='body1' style={{fontWeight: 'bold'}}>Predicted</Typography>
                                            <Typography variant='h5' style={{fontWeight: 'bold'}}>{part?.predicted}</Typography>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>                
                            <Grid item xs={12}>
                                <Button fullWidth className='mt-2' variant='outlined' style={{padding: '0.75em', fontWeight: 'bold', backgroundColor: colors.white}} onClick={this.downloadStockLabel('partLabel')}>Part Label (90mm * 29 mm)</Button>
                                <Button fullWidth className='mt-2' variant='outlined' style={{padding: '0.75em', fontWeight: 'bold', backgroundColor: colors.white}} onClick={this.downloadStockLabel('longLabel')}>Long Label (143mm * 20 mm)</Button>
                                <Button fullWidth className='mt-2' variant='outlined' style={{padding: '0.75em', fontWeight: 'bold', backgroundColor: colors.white}} onClick={this.downloadStockLabel('boxLabel')}>Box Label (71mm * 44 mm)</Button>
                                <Button fullWidth className='mt-2' variant='outlined' style={{padding: '0.75em', fontWeight: 'bold', backgroundColor: colors.white}} onClick={this.downloadStockLabel('a4Label')}>A4 Label (A4)</Button>
                            </Grid>
                        </> :
                        <Grid item xs={12}>
                            <Typography variant='h4' style={{textAlign: 'center'}}>Scan Part</Typography>
                        </Grid>
                    }
                </Grid>
                <SinglePrintDialog
                    id={printingDialog}
                    handleClose={()=>this.setState({printingDialog: null})}
                />
            </QrScanWrapper>
        );
    }
}


const styles = theme => ({
    ListItem: {
		backgroundColor: colors.white, 
		borderRadius: 	 5,
		marginBottom: 	 '0.75em',
		boxShadow: 	  	 '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
	}
});

export default withStyles(styles)(Stock);