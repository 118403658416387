import React, { Component } from 'react';
import { TableFooter } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import logo from 'Assets/Images/clenaware_logo_icon.png';
import { MultiDial } from 'Functions/MiscFunctions';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import API from 'API';

const styles = theme => ({
    tblHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    tblHeaderCell: {
        color:'white',
    },
    tblFooter: {
        backgroundColor: '#f2f2f2',
    }
});
class Quotation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            cq: props?.cq,
        };
    }

    trimPartDescription = partDescription => {
        if(partDescription) {
            if(partDescription.length > 50){
                partDescription = partDescription.substring(0, 50) + '...';
            }
        } else {
            partDescription = '';
        }
        return partDescription;
    }

    componentDidMount() {
        if (this.props.cqId) {
            this.getQuote();
        }
    }

    getQuote = () => {
        this.setState({  isLoading: true }, () => {
            API.get('/sales/quotations/' + this.props.cqId)
            .then(result => {
                this.setState({
                    cq: result.data,
                    isLoading: false,
                });
            })
        });
    }

    render() {
        const { classes } = this.props;
        const { cq, isLoading } = this.state;

        if (isLoading || !cq) return (<LoadingCircle />);

        const customerPhone = [];
        if(cq.quote_cust_contact_phone && cq.quote_cust_contact_mobile) {
            customerPhone.push(cq.quote_cust_contact_phone, cq.quote_cust_contact_mobile);
        } else if(cq.quote_cust_contact_phone) {
            customerPhone.push(cq.quote_cust_contact_phone);
        } else if(cq.quote_cust_contact_mobile) {
            customerPhone.push(cq.quote_cust_contact_mobile);
        }
            
        const deliveryPhone = [];
        if(cq.quote_delivery_cust_contact_phone && cq.quote_delivery_cust_contact_mobile) {
            deliveryPhone.push(cq.quote_delivery_cust_contact_phone, cq.quote_delivery_cust_contact_mobile);
        } else if(cq.quote_delivery_cust_contact_phone) {
            deliveryPhone.push(cq.quote_delivery_cust_contact_phone);
        } else if(cq.quote_delivery_cust_contact_mobile) {
            deliveryPhone.push(cq.quote_delivery_cust_contact_mobile);
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container item spacing={1}>
                            <Grid item xs={5}>
                                <Grid container item spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Quote Date</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {moment(cq.quote_date).format("DD/MM/YYYY")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Quoted By</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cq.quote_staff_name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={5}>
                                <Grid container item spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Reference</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cq.full_reference}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Payment Terms</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cq.quote_payment_terms}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br></br>
                        <Grid container item spacing={1}>
                            <Grid item xs={5}>
                                <Grid container item spacing={1}>
                                    <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                                        Customer
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Name</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cq.quote_cust_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Address</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cq.quote_cust_address.split('\n').map((item, key) => {
                                                return  <span key={key}>
                                                            {item}{cq.quote_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                        </span>
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Contact</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cq.quote_cust_contact_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Phone Number</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            <MultiDial numbers={customerPhone} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Email</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cq.quote_cust_contact_email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={5}>
                                <Grid container item spacing={1}>
                                    <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                                        Delivery
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Name</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cq.quote_delivery_cust_address_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Address</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cq.quote_delivery_cust_address.split('\n').map((item, key) => {
                                                return  <span key={key}>
                                                            {item}{cq.quote_delivery_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                        </span>
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Contact</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cq.quote_delivery_cust_contact_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Phone Number</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            <MultiDial numbers={deliveryPhone} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Email</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cq.quote_delivery_cust_contact_email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <br></br>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.tblHeader}>
                                        <TableCell className={classes.tblHeaderCell}></TableCell>
                                        <TableCell className={classes.tblHeaderCell}>Part Number</TableCell>
                                        <TableCell className={classes.tblHeaderCell}>Part Description</TableCell>
                                        <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Quantity</TableCell>
                                        <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Unit Price</TableCell>
                                        {cq.quote_type && cq.quote_type === 'With Discount' &&
                                            <React.Fragment>
                                                <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Discount Rate</TableCell>
                                                <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>New Unit Price</TableCell>
                                                <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Discount Total</TableCell>
                                            </React.Fragment>
                                        }
                                        <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Total Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {cq.quote_details && cq.quote_details.map((row ,key) => (
                                    <TableRow style={{borderBottom: '1px solid rgb(224, 224, 224)'}} key={key}>
                                        <TableCell style={{height:65, borderBottom: 'none', verticalAlign: 'middle'}} align='center'>
                                            <img src={(row.part && row.part.default_image && row.part.default_image.library_thumbnail_url) ? row.part.default_image.thumbnail_file_path : logo} style={{overflow:'visible', maxHeight: 45, maxWidth: 45}} alt="" />
                                        </TableCell>
                                        <TableCell style={{whiteSpace:'nowrap'}}>{row.qd_part_number}</TableCell>
                                        <TableCell style={{whiteSpace:'nowrap', width:'30%'}}>
                                            {this.trimPartDescription(row.qd_part_description)} 
                                            <br/> 
                                            <span style={{color: '#757575', fontSize: '12px'}}>{row.qd_warranty_name !== null && row.qd_warranty_duration !== null ? row.qd_warranty_name : ''}</span>
                                            <br/> 
                                            <span style={{fontSize: '12px', whiteSpace:'pre-wrap'}}>
                                                {row.qd_notes && row.qd_notes.split('\n').map((item, key) => {
                                                    return <span key={key}>{item}<br/></span>
                                                })}
                                            </span>
                                        </TableCell>
                                        <TableCell style={{textAlign:'right'}}>{parseFloat(row.qd_quantity)}</TableCell>
                                        <TableCell style={{textAlign:'right'}}>£{row.qd_unit_price}</TableCell>
                                        {cq.quote_type && cq.quote_type === 'With Discount' &&
                                            <React.Fragment>
                                                <TableCell style={{textAlign:'right'}}>{row.qd_discount_rate}%</TableCell>
                                                <TableCell style={{textAlign:'right'}}>£{row.qd_new_unit_price}</TableCell>
                                                <TableCell style={{textAlign:'right'}}>£{row.qd_discount_total}</TableCell>
                                            </React.Fragment>
                                        }
                                        <TableCell style={{textAlign:'right'}}>£{row.qd_total_price}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell style={{textAlign:'right', paddingRight: '8px'}} colSpan={cq.quote_type && cq.quote_type === 'With Discount' ? 8 : 5}><strong>Sub Total</strong></TableCell>
                                        <TableCell style={{textAlign:'right'}}><strong>£{cq.quote_sub_total}</strong></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{textAlign:'right', paddingRight: '8px'}} colSpan={cq.quote_type && cq.quote_type === 'With Discount' ? 8 : 5}><strong>VAT ({cq.vat_value && cq.vat_value.vat_value_percentage}%)</strong></TableCell>
                                        <TableCell style={{textAlign:'right'}}><strong>£{cq.quote_vat}</strong></TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tblFooter}>
                                        <TableCell style={{textAlign:'right', paddingRight: '8px'}} colSpan={cq.quote_type && cq.quote_type === 'With Discount' ? 8 : 5}><strong>Total</strong></TableCell>
                                        <TableCell style={{textAlign:'right'}}><strong>£{cq.quote_total_incl_vat}</strong></TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                            <br></br>
                        </Grid>
                        <Grid container item spacing={1}>
                            <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                                Quotation Notes
                            </Grid>
                            <Grid item xs={12}>
                                {cq.quote_notes ?
                                    cq.quote_notes.split('\n').map((item, key) => {
                                        return <span key={key}>{item}<br/></span>
                                    })
                                :
                                    ''
                                }
                            </Grid>
                        </Grid>
                        
                    </PaddedPaper>
                    <br></br>
                </Grid>
            </Grid>
        );
    }
}

export default (withStyles(styles)(Quotation));