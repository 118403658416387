import React from 'react';
import { Grid, Button, Box, List, ListItem, ListItemText, ListItemIcon, Checkbox } from '@material-ui/core';
import API from 'API';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import _ from 'lodash';

const initialState = (props) => ({
    formData: {
        contactId: props.contact.contact_id,
        customerId: 0,
        sites: [],
    },
    isLoading: true,
    customers: [],
})

class LinkContactToCustomerDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getCustomers();
    }

    getCustomers = () => {
        API.get('/customers')
        .then((response) => {
            this.setState({
                customers: response.data,
                isLoading: false,
            });
        });
    }

    selectChange = name => ({value}) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
                sites: [],
            }
        });
    }

    handleCheck = (addressId) => () => {
        let { sites } = this.state.formData;

        if (_.includes(sites, addressId)) {
            sites = _.filter(sites, id => id !== addressId);
        } else {
            sites.push(addressId);
        }

        this.setState({
            formData: {
                ...this.state.formData,
                sites,
            }
        })
    }

    submit = () => {
        API.post('/marketing/contacts/link', {...this.state.formData, sites: JSON.stringify(this.state.formData.sites)})
        .then((response) => {
            this.props.onClose();
        });
    }

    render() {
        const { customers, isLoading, formData } = this.state;

        if (isLoading) 
            return <LoadingCircle/>

        const customer = _.find(customers, {cust_id: formData.customerId});

        return (
            <Box>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <AutoCompleteSelect
                            label='Customer *'
                            options={_.map(customers, c => ({
                                value: c.cust_id,
                                label: c.cust_name,
                            }))}
                            value={formData.customerId}
                            onChange={this.selectChange('customerId')}
                            fullWidth
                            noClear
                        />
                    </Grid>
                    {!!formData.customerId &&
                        <Grid item xs={12}>
                            <List>
                                {_.map(customer.addresses, s => (
                                    <ListItem>
                                        <ListItemIcon>
                                            <Checkbox 
                                                checked={_.includes(formData.sites, s.address_id)}
                                                onChange={this.handleCheck(s.address_id)}
                                                color='primary'
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={`${s.address_name} (${s.address_type})`} secondary={
                                            `${s.address_line_one}, ${s.address_line_two}, ${s.address_town}, ${s.address_state}, ${s.address_postcode}`
                                        }/>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    }
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant='outlined'
                            onClick={this.props.onClose}
                        >Close</Button>
                        <Button
                            disabled={!formData.customerId}
                            variant='contained'
                            color='primary'
                            onClick={()=>this.props.deployConfirmation(
                                'Are you sure you want to link this contact to this customer, they will no appear in the manual contacts list?',
                                'Link Contact to Customer',
                                this.submit
                            )}
                        >Submit</Button>

                    </Grid>
                </Grid>
            </Box>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback) => {
            dispatch(deployConfirmation(content, title, callback))
        }
    }
}

export default connect(null, mapDispatchToProps)(LinkContactToCustomerDialog);