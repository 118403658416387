import React, { Component }     from 'react';
import API                      from 'API';
import _, { assign, find, map } from 'lodash';
import moment                   from 'moment';
import { connect }              from 'react-redux';

import { colors }        from 'Helpers/ColourHelper';
import icons             from 'Helpers/IconHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

import DataTable                   from 'Components/Common/DataTables/CiDataTable';
import EmailPaymentDialog          from 'Components/Common/Dialogs/EmailPaymentDialog';
import AllIcon                     from 'Components/Common/Icons/AllIcon';
import PaddedPaper                 from 'Components/Common/Paper/PaddedPaper';
import SnackBar                    from 'Components/Common/SnackBars/SnackBar';
import StickyNotes                 from 'Components/Common/StickyNotes/StickyNotes';
import DespatchDialog              from 'Components/Sales/Order/DespatchDialog/DespatchDialog';
import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog    from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';
import SelectPaymentDialog         from 'Components/Sales/Order/OrderPayment/SelectPaymentDialog';
import OrderSearchBlock            from 'Components/Sales/Order/OrderSearch/OrderSearchBlock';
import Order                       from 'Components/Sales/Order/ViewOrder/Order';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, Tooltip, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { deployDialog }                     from 'Actions/Dialog/Dialog';
import { deploySnackBar }                   from 'Actions/SnackBar/SnackBar';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { downloadS3File }                                               from 'Functions/MiscFunctions';
import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';
import JobSnapshot from 'Components/Engineers/Jobs/Misc/JobSnapshot';

const styles = () => ({
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    },
    custName: {
        width:200, 
        overflow:'hidden',
        textOverflow: "ellipsis", 
        '&:hover':{cursor: 'pointer'}
    }
});

const initialState = () => ({
    access: {
        customerOrderSearch: false
    },
    orderStats: [],
    keywords: '',
    status: 'Awaiting Payment',
    dateFrom: null,
    dateTo: null,
    searchResults: {},
    confResults: {},
    paymentTermsList: [],
    isLoading: false,
    approveDialogOpen: false,
    declineDialogOpen: false
});

class OrderPayment extends Component {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Sales:payment';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        this.getSearchData();
    }

    getSearchData = () => {
        this.setState({
            isLoading: true
        },
        () => {
            Promise.all([
                API.get('/staff/my/access/check/customer-order-search'),
                API.get('/staff/my/access/check/repeat-customer-order'),
                API.get('/sales/orders', {
                    params: {
                        keywords: this.state.keywords,
                        status: 'Awaiting Payment',
                        dateFrom: this.state.dateFrom !== null ? moment(this.state.dateFrom).format('YYYY-MM-DD') : null,
                        dateTo: this.state.dateTo !== null ? moment(this.state.dateTo).format('YYYY-MM-DD') : null,
                        pendingPayments: true
                    }
                }),
                API.get('/sales/orders', {
                    params: {
                        keywords: this.state.keywords,
                        status: 'Payment Confirmed',
                        dateFrom: this.state.dateFrom !== null ? moment(this.state.dateFrom).format('YYYY-MM-DD') : null,
                        dateTo: this.state.dateTo !== null ? moment(this.state.dateTo).format('YYYY-MM-DD') : null,
                    }
                }),
                API.get('/sales/orders/stats', {params: {customerId: null}}),
                API.get(`/customers/paymentTerms`)
            ])
            .then(([accessRes, repeatOrderRes, resultRes, confRes, statsRes, paymentTermRes]) => {
                let paymentTermsList = map(paymentTermRes?.data, el => {
                    return assign({
                        value: el.payment_term_id,
                        label: el.payment_term_name,
                        upfrontPayment: el.payment_term_upfront_payment
                    });
                })

                this.setState({
                    ...this.state,
                    access: {
                        customerOrderSearch: ( accessRes.data      && accessRes.data.has_access )      || false,
                        repeatOrder:         ( repeatOrderRes.data && repeatOrderRes.data.has_access ) || false
                    },
                    searchResults: _.map(resultRes.data, i => {
                        i.f = {
                            ej: _.find(i.past_revisions, p => {return _.find(p.files, {co_file_type: "Engineer Job Sheet"})} ) ? 1 : 0,
                            po: _.find(i.past_revisions, p => {return _.find(p.files, {co_file_type: "Purchase Order"})}     ) ? 1 : 0,
                        }
                        return i;
                    }),
                    confResults: _.map(confRes.data, i => {
                        i.f = {
                            ej: _.find(i.past_revisions, p => {return _.find(p.files, {co_file_type: "Engineer Job Sheet"})} ) ? 1 : 0,
                            po: _.find(i.past_revisions, p => {return _.find(p.files, {co_file_type: "Purchase Order"})}     ) ? 1 : 0,
                        }
                        return i;
                    }),
                    orderStats: statsRes?.data,
                    paymentTermsList,
                    isLoading: false
                });
            });
        });
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
            status: 'Awaiting Payment',
            dateFrom: null,
            dateTo: null,
        }, 
        () => {
            this.getSearchData();
        });
    }

    downloadOrder = rowData => {
        API.get('/sales/orders/' + rowData.order_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    handleDownloadOpen = orderId => this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md');

    handleEmailOpen = orderId => this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />, 'Email Previous Documents', '', 'md');

    handleEmailClose = () => {
        this.setState({
            emailDialogOpen: false
        }, 
        () => {
            this.getSearchData();
        });
    };

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        }, 
        () => {
            this.getSearchData();
        });
    };

    handleDateChange = name => date => {
        this.setState({
            [name]: date
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    handleSelectPayment = rowData => {
        API.get(`/sales/orders/${rowData.order_id}/checkProcessed`, {params: {stage: 'Awaiting Payment'}}).then(res => {
            if(!res.data.processed){
                let dialog = <SelectPaymentDialog 
                    orderId={rowData.order_id} 
                    orderTotal={rowData.order_total_incl_vat}
                    paymentTermId={rowData.order_payment_term_id}
                    paymentTermsList={this.state.paymentTermsList}
                    callback={this.getSearchData}
                />;
        
                this.props.deployDialog(dialog, 'Select Payment', '', 'md');
            } else {
                this.props.deploySnackBar('error', 'Order payment has already been selected!');
                this.getSearchData();
            }
        })
        
    }

    handleDespatch = rowData => {
        API.get(`/sales/orders/${rowData.order_id}/checkProcessed`, {params: {stage: 'Payment Confirmed'}}).then(res => {
            if(!res.data.processed){
                let dialog = <DespatchDialog 
                    orderId={rowData.order_id} 
                    callback={this.getSearchData}
                    paymentConfirmed={true}
                />;

                this.props.deployDialog(dialog, 'Handle Despatch', '', 'xs');
            } else {
                this.props.deploySnackBar('error', 'Order despatch has already been selected!');
                this.getSearchData();
            }
        });
    }

    handleOrderActions = type => rowData => {
        API.get(`/sales/orders/${rowData.order_id}/checkProcessed`, {params: {stage: 'Awaiting Payment'}}).then(res => {
            if(!res.data.processed){
                let dialog, title;

                switch(type) {
                    case 'card':
                        title = 'Send Card Payment Instruction';
                    break;
                    case 'stripe':
                        title = 'Send Stripe Payment Instruction';
                    break;
                    case 'cheque':
                        title = 'Send Cheque Payment Instruction';
                    break;
                    case 'transfer':
                        title = 'Send Transfer Payment Instruction';
                    break;
                    case 'cash':
                        title = 'Send Cash Payment Instruction';
                    break;
                    case 'paypal':
                        title = 'Send PayPal Payment Instruction';
                    break;
                    default:
                        throw new Error('unhandled type');
                }

                let paymentTerm = find(this.state.paymentTermsList, {'value': rowData.order_payment_term_id});

                dialog = <EmailPaymentDialog 
                    customerId={rowData.order_cust_id}
                    customerContactId={rowData.order_cust_contact_id}
                    type={type}
                    id={rowData.order_id}
                    callback={this.getSearchData}
                    deliveryAddressId={rowData.order_delivery_cust_address_id}
                    emailAttachment="order"
                    paymentTerm={paymentTerm.label}
                    paymentTermId={paymentTerm.value}
                    amountOutstanding={rowData.order_total_incl_vat}
                />

                this.props.deployDialog(dialog, title, 'success', '');
            } else {
                this.props.deploySnackBar('error', 'Order payment has already been selected!');
                this.getSearchData();
            }
        });
    }

    handleShowStickyNote = orderId => { this.props.deployDialog(<StickyNotes orderId={orderId} dialog/>, 'Sticky Notes', null,'xs') }
    
    handleSetSearch = props => {
        this.setState({
            ...props,
            status: 'Awaiting Payment',
        },this.getSearchData)
    }

    render() {
        const { access } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Order Payment
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={12}>
                        <OrderSearchBlock disableSites disableStatus callBack={this.handleSetSearch} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Typography variant="h5" gutterBottom>
                            <strong>Payment Confirmed</strong><br></br>
                        </Typography>
                        <DataTable  
                            config={{
                                key: 'order_id',
                                pagination: true,
                                alternatingRowColours: true,
                                responsiveimportant: true,
                                persistenceId: `${this.persistenceId}-confirmed`,
                                isLoading: this.state.isLoading,
                                rowsPerPage: 25
                            }}
                            columns={[
                                {
                                    heading: 'Order Date',
                                    field: rowData => moment(rowData.order_date).format("DD/MM/YYYY"),
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    actionsCustomHeader:    true,
                                    important:              true,
                                    alignment:              'left',
                                    heading:                'Reference',
                                    actions: rowData => {
                                        return [
                                            { label: rowData.full_reference,                                link: `/sales/order/view/${rowData.order_id}`,              type:'plainLink'},
                                            { label: rowData?.quotation_full_reference?.full_reference,     link: `/sales/quotation/view/${rowData.order_quote_id}`,    type:'plainLink', class: "textSecondary"}
                                        ]
                                    }
                                },
                                // {
                                //     heading: 'Discount',
                                //     field: rowData => rowData.order_type === 'With Discount',
                                //     important: true,
                                //     sizeToContent: true,
                                //     fieldFormat: 'boolean',
                                //     tooltip: rowData => rowData.order_type,
                                //     alignment: 'center'
                                // },
                                {
                                    heading: 'Customer',
                                    field: rowData =>   <Tooltip
                                                            title={
                                                                <Typography variant="body2">
                                                                    <b>Delivery Address:</b><br />
                                                                    {rowData.order_delivery_cust_address.split('\n').map((item, key) => {
                                                                        return  <span key={key}>
                                                                                    {item}{rowData.order_delivery_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                                                </span>
                                                                    })}
                                                                </Typography>
                                                            }
                                                            placement="left"
                                                        >
                                                            <span className={this.props.classes.custName} onClick={()=>{ this.props.history.push(`/customers/view/${rowData.order_cust_id}`); }}>
                                                                {rowData.order_cust_name}
                                                                {' '}
                                                                <Typography component="div" variant="body2">
                                                                    {rowData.order_delivery_cust_address_name}
                                                                </Typography>
                                                            </span>
                                                        </Tooltip>,
                                    important: true,
                                    truncate: true,
                                },
                                {
                                    heading: 'Files',
                                    field: rowData => 
                                        <Grid container style={{width:'100%'}} spacing={1} xs={12} alignItems='flex-end' justify="center">
                                             {!!rowData.ejs_url && 
                                                <Grid item xs={12} lg={6}>
                                                    <AllIcon 
                                                        icon='user-hard-hat'  
                                                        button 
                                                        noMargin
                                                        tooltip='Engineer Job Sheet'
                                                        onClick={() => {downloadS3File(rowData.ejs_url)}}
                                                    />
                                                </Grid>
                                            }
                                            {!!rowData.po_url && 
                                                <Grid item xs={12} lg={6}>
                                                    <AllIcon 
                                                        icon={(rowData.po_url.includes('.wav') || rowData.po_url.includes('.x-wav')) ? 'cassette-tape' : 'file-alt' }
                                                        button 
                                                        noMargin
                                                        tooltip='Purchase Order'
                                                        onClick={() => {downloadS3File(rowData.po_url)}}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>,
                                    alignment: 'center',
                                    sizeToContent: true,
                                },
                                {
                                    heading:        'Total',
                                    field: rowData => ( 
                                        <Tooltip 
                                            title={
                                                <div style={{display: 'flex', width: 200}}>
                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                            <Typography variant="body2">Sub Total</Typography>
                                                            <Typography variant="body2">VAT ({rowData.vat_value.vat_value_percentage}%)</Typography>
                                                            <Typography variant="body2">Total</Typography>
                                                        </div>
                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                            <Typography variant="body2">£{rowData.order_sub_total}</Typography>
                                                            <Typography variant="body2">£{rowData.order_vat}</Typography>
                                                            <Typography variant="body2">£{rowData.order_total_incl_vat}</Typography>
                                                        </div>
                                                    </div>
                                            } 
                                            placement="left"
                                        >
                                            <span>{rowData.order_total_incl_vat}</span>
                                        </Tooltip>
                                    ),
                                    important:      true,
                                    alignment:      'right',
                                    fieldPrefix:    '£',
                                },
                                {
                                    heading: '',
                                    field: i => !!i?.authorised_payment?.op_txn_amount && ( parseFloat(i.authorised_payment.op_txn_amount).toFixed(3) !== parseFloat(i.order_total_incl_vat).toFixed(3) ) &&
                                        <AllIcon
                                            icon={icons.partialOrderPayment}
                                            tooltip='Order is partially paid'
                                            color={colors.red}
                                            noMargin
                                        />,
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Jobs',
                                    field:      rowData => <>
                                        {rowData?.ej && 
                                            <Tooltip
                                                title={<JobSnapshot jobRef={rowData?.ej?.ref} forms={rowData?.ej?.f} />}
                                                placement="left"
                                            >
                                                <IconButton className={this.props.classes.iconButton} onClick={() => this.props.history.push(`/engineers/jobs/view/${rowData.ej?.id}`) }>
                                                    <AllIcon 
                                                        icon={`user-helmet-safety`}
                                                        button
                                                        noMargin
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </>,
                                    alignment: 'center',
                                    sizeToContent: true,
                                    important: false
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Sticky Notes',                                icon: icons.stickyNote,                      onClick: () => this.handleShowStickyNote(rowData.order_id), hide:rowData.sticky_notes.length === 0, color: _.filter(rowData.sticky_notes, i =>!i.sn_completed).length > 0 && colors.yellow },
                                            {name: 'View',                                        icon: 'search',                              link: '/sales/order/view/' + rowData.order_id},
                                            {name: rowData.locked ? 'locked' : 'Handle Despatch', icon:  rowData.locked ? icons.lock :'truck', onClick: !rowData.locked && this.handleDespatch},
                                            {
                                                type: 'actionMenu',
                                                rows: [
                                                    {
                                                        icon: icons.repeat,
                                                        label: "Repeat Order",
                                                        onClick:() => this.props.history.push(`/sales/order/add/repeat/${rowData.order_id}`),
                                                        display: true,
                                                        disabled: !access.repeatOrder
                                                    },
                                                    {
                                                        icon: "download",
                                                        label: "Download",
                                                        onClick: () => this.handleDownloadOpen(rowData.order_id),
                                                        display: true
                                                    },
                                                    {
                                                        icon: "envelope",
                                                        label: "Send Order to Customer",
                                                        onClick: () => this.handleEmailOpen(rowData.order_id),
                                                        display: true
                                                    },
                                                    {
                                                        icon: "file-search",
                                                        label: "View this Customers Orders",
                                                        onClick: () => this.props.history.push(`/customers/view/${rowData.order_cust_id}/Orders`),
                                                        display: true,
                                                        disabled: !access.customerOrderSearch
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.confResults}
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Typography variant="h5" gutterBottom>
                            <strong>Awaiting Payment</strong><br></br>
                        </Typography>
                        <DataTable  
                            config={{
                                key: 'order_id',
                                pagination: true,
                                alternatingRowColours: true,
                                responsiveimportant: true,
                                isLoading: this.state.isLoading,
                                persistenceId: `${this.persistenceId}-awaiting`,
                                rowsPerPage: 25
                            }}
                            columns={[
                                {
                                    heading: 'Order Date',
                                    field: rowData => moment(rowData.order_date).format("DD/MM/YYYY"),
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    actionsCustomHeader:    true,
                                    important:              true,
                                    alignment:              'left',
                                    heading:                'Reference',
                                    actions: rowData => {
                                        return [
                                            { label: rowData.full_reference,                                link: `/sales/order/view/${rowData.order_id}`,              type:'plainLink'},
                                            { label: rowData?.quotation_full_reference?.full_reference,     link: `/sales/quotation/view/${rowData.order_quote_id}`,    type:'plainLink', class: "textSecondary"}
                                        ]
                                    }
                                },
                                // {
                                //     heading: 'Discount',
                                //     field: rowData => rowData.order_type === 'With Discount',
                                //     important: true,
                                //     sizeToContent: true,
                                //     fieldFormat: 'boolean',
                                //     tooltip: rowData => rowData.order_type,
                                //     alignment: 'center'
                                // },
                                {
                                    heading: 'Customer',
                                    field: rowData =>   <Tooltip
                                                            title={
                                                                <Typography variant="body2">
                                                                    <b>Delivery Address:</b><br />
                                                                    {rowData.order_delivery_cust_address.split('\n').map((item, key) => {
                                                                        return  <span key={key}>
                                                                                    {item}{rowData.order_delivery_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                                                </span>
                                                                    })}
                                                                </Typography>
                                                            }
                                                            placement="left"
                                                        >
                                                            <span className={this.props.classes.custName} onClick={()=>{ this.props.history.push(`/customers/view/${rowData.order_cust_id}`); }}>
                                                                {rowData.order_cust_name}
                                                                {' '}
                                                                <Typography component="div" variant="body2">
                                                                    {rowData.order_delivery_cust_address_name}
                                                                </Typography>
                                                            </span>
                                                        </Tooltip>,
                                    important: true,
                                    truncate: true,
                                },
                                {
                                    heading: 'Files',
                                    field: rowData => 
                                        <Grid container style={{width:'100%'}} spacing={1} xs={12} alignItems='flex-end' justify="center">
                                             {!!rowData.ejs_url && 
                                                <Grid item xs={12} lg={6}>
                                                    <AllIcon 
                                                        icon='user-hard-hat'  
                                                        button 
                                                        noMargin
                                                        tooltip='Engineer Job Sheet'
                                                        onClick={() => {downloadS3File(rowData.ejs_url)}}
                                                    />
                                                </Grid>
                                            }
                                            {!!rowData.po_url && 
                                                <Grid item xs={12} lg={6}>
                                                    <AllIcon 
                                                        icon={(rowData.po_url.includes('.wav') || rowData.po_url.includes('.x-wav')) ? 'cassette-tape' : 'file-alt' }
                                                        button 
                                                        noMargin
                                                        tooltip='Purchase Order'
                                                        onClick={() => {downloadS3File(rowData.po_url)}}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>,
                                    alignment: 'center',
                                    sizeToContent: true,
                                },
                                {
                                    heading:        'Total',
                                    field: rowData => ( 
                                            <Tooltip 
                                                title={
                                                    <div style={{display: 'flex', width: 200}}>
                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                            <Typography variant="body2">Sub Total</Typography>
                                                            <Typography variant="body2">VAT ({rowData.vat_value.vat_value_percentage}%)</Typography>
                                                            <Typography variant="body2">Total</Typography>
                                                        </div>
                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                            <Typography variant="body2">£{rowData.order_sub_total}</Typography>
                                                            <Typography variant="body2">£{rowData.order_vat}</Typography>
                                                            <Typography variant="body2">£{rowData.order_total_incl_vat}</Typography>
                                                        </div>
                                                    </div>
                                                } 
                                                placement="left"
                                            >
                                                <span>{rowData.order_total_incl_vat}</span>
                                            </Tooltip>
                                    ),
                                    important:      true,
                                    alignment:      'right',
                                    fieldPrefix:    '£',
                                },
                                {
                                    heading: 'Jobs',
                                    field:      rowData => <>
                                        {rowData?.ej && 
                                            <Tooltip
                                                title={<JobSnapshot jobRef={rowData?.ej?.ref} forms={rowData?.ej?.f} />}
                                                placement="left"
                                            >
                                                <IconButton className={this.props.classes.iconButton} onClick={() => this.props.history.push(`/engineers/jobs/view/${rowData.ej?.id}`) }>
                                                    <AllIcon 
                                                        icon={`user-helmet-safety`}
                                                        button
                                                        noMargin
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </>,
                                    alignment: 'center',
                                    sizeToContent: true,
                                    important: false
                                },
                                {
                                    actions: rowData => {
                                        let ret = [
                                            {name: 'Sticky Notes',                            icon: icons.stickyNote,   onClick: () => this.handleShowStickyNote(rowData.order_id), hide:rowData.sticky_notes.length === 0, color: _.filter(rowData.sticky_notes, i =>!i.sn_completed).length > 0 && colors.yellow },
                                            {name: 'View',                                    icon: 'search',           link: '/sales/order/view/' + rowData.order_id},
                                        ];
                                        if (!rowData.locked)
                                            ret = [ ...ret,
                                                {name: 'Select payment method / process payment', icon: 'check',            onClick: this.handleSelectPayment},
                                                {name: 'BarclayCard Link',                        icon: 'credit-card',      onClick: () => window.open('https://payments.epdq.co.uk/Ncol/Prod/orderras.asp?new=True&MenuId=34&CSRFSP=%2fncol%2fprod%2fbackoffice%2fmenuindex.asp&CSRFKEY=3CC5B065D2A2A612AA86457776D8F1E0BC0418D9&CSRFTS=20200729141810&branding=EPDQ&MigrationMode=DOTNET', '_blank'), color: '#008cdd', disabled: !rowData.barclaycardepdq ?? true},
                                                {name: 'Send Stripe Payment Instruction',         icon: 'stripe-s',         onClick: this.handleOrderActions('stripe'), lib: 'brand', color: !rowData.pendingStripe ? colors.blue : colors.yellow,  disabled: !rowData.stripe       ?? true},
                                                {name: 'Send PayPal Payment Instruction',         icon: 'paypal',           onClick: this.handleOrderActions('paypal'), lib: 'brand', color: !rowData.pendingPaypal ? colors.blue : colors.yellow,  disabled: !rowData.paypal       ?? true},
                                                {name: 'Send Cheque Payment Instruction',         icon: 'money-check-edit', onClick: this.handleOrderActions('cheque'),               color: !rowData.pendingCheque ? colors.blue : colors.yellow,  disabled: !rowData.cheque       ?? true},
                                                {name: 'Send Bank Transfer Instruction',          icon: 'piggy-bank',       onClick: this.handleOrderActions('transfer'),             color: !rowData.pendingBank   ? colors.blue : colors.yellow,  disabled: !rowData.banktransfer ?? true},
                                                {name: 'Send Cash Payment Instruction',           icon: 'money-bill',       onClick: this.handleOrderActions('cash'),                 color: !rowData.pendingCash   ? colors.blue : colors.yellow,  disabled: !rowData.cash         ?? true}
                                            ];
                                        
                                        if (rowData.locked)
                                            ret.push({name: 'locked', icon: icons.lock, onClick: () => {}});

                                        ret.push({
                                                type: 'actionMenu',
                                                rows: [
                                                    {
                                                        icon: icons.repeat,
                                                        label: "Repeat Order",
                                                        onClick:() => this.props.history.push(`/sales/order/add/repeat/${rowData.order_id}`),
                                                        display: true,
                                                        disabled: !access.repeatOrder
                                                    },
                                                    {
                                                        icon: "download",
                                                        label: "Download",
                                                        onClick: () => this.handleDownloadOpen(rowData.order_id),
                                                        display: true
                                                    },
                                                    {
                                                        icon: "envelope",
                                                        label: "Send Order to Customer",
                                                        onClick: () => this.handleEmailOpen(rowData.order_id),
                                                        display: true
                                                    },
                                                    {
                                                        icon: "file-search",
                                                        label: "View this Customers Orders",
                                                        onClick: () => this.props.history.push(`/customers/view/${rowData.order_cust_id}/Orders`),
                                                        display: true,
                                                        disabled: !access.customerOrderSearch
                                                    }
                                                ]
                                            });

                                        return ret
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                    <Dialog 
                        open={this.state.approveDialogOpen} 
                        onClose={this.handleApproveClose} 
                        fullWidth={true} 
                        maxWidth="lg" 
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title" style={{backgroundColor:'#5da14d'}}>
                            <Typography style={{color:'white', fontSize:'1.25rem'}}>You Are About To <b>Approve</b> This Order</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Order co={this.state.order} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleApproveClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={this.handleApproveSuccess} style={{backgroundColor:'#5da14d', color:'white'}} autoFocus variant="contained">Approve</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog 
                        open={this.state.declineDialogOpen} 
                        onClose={this.handleDeclineClose} 
                        fullWidth={true} 
                        maxWidth="lg" 
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown                         
                    >
                        <DialogTitle id="form-dialog-title" style={{backgroundColor:'#D61F26'}}>
                            <Typography style={{color:'white', fontSize:'1.25rem'}}>
                                You Are About To <b>Decline</b> This Order
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Order co={this.state.order} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDeclineClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={this.handleDeclineSuccess} color="secondary" autoFocus variant="contained">Decline</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.emailSnackbarOpen}
                        onClose={this.handleEmailSnackbarClose}
                        message='You have successfully sent the email'
                    />
                </Grid>
            </Grid>
        );
    }
}


const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                    => dispatch(setPersistence(key, state)),
    deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
    deploySnackBar:     (variant, msg)                  => dispatch(deploySnackBar(variant, msg))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderPayment));
