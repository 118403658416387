import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const ThreadNew = () => (
    <Box p={1.5}>
        <div style={{position: 'absolute', top: '50%', height: 200, marginTop: -100, textAlign: 'center', width: 'calc(100% - 24px)'}}>
            <FAIcon type="duo" icon='comment-alt-lines' size={100} style={{color: '#777'}} noMargin /><br /><br />
            <Typography variant="body2">
                Start a conversation by typing your<br />message below
            </Typography>
        </div>
    </Box>
)

export default ThreadNew;