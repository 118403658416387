import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import moment from 'moment';
import FormHelperText from '@material-ui/core/FormHelperText';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import DatePicker from '../../Common/DatePickers/DatePicker';
import Checkbox from '@material-ui/core/Checkbox';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { toggleDialog } from 'Functions/MiscFunctions';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import API from 'API';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const styles = theme => ({
    td : {
        border: 'none'
    },
});
class TransactionDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialog: {
                receiptDialog: false,
            },
            receiptData: {},
            receiptErrors: {}
        }
        this.toggleDialog = toggleDialog.bind(this);
    }

    fileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            receiptData: {
                ...this.state.receiptData,
                [name]: file
            }
        });
    }

    clearFile = () => {
        this.setState({
            receiptData: {
                ...this.state.receiptData,
                file: ''
            }
        });
    };

    handleSubmit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.receiptData).forEach(key => {
            newFormData.append(key, this.state.receiptData[key]);
        });
        API.post(`/accounts/banks/transactions/${this.props.formData.transId}/updateReceipt`, newFormData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    receiptErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    dialog: {
                        receiptDialog: false,
                    },
                }, () => {
                    this.props.refreshTransaction();
                    this.props.deploySnackBar("success", "You have successfully updated the receipt");
                });
            }
        });
    }

    closeReceiptDialog = () => {
        this.setState({
            dialog: {
                receiptDialog: false,
            },
            receiptData: {},
            receiptErrors: {}
        });
    }
    
    render() {
        const { classes, open, onClose, formData, handleDialogTransactionSuccess, handleRowChange, handleSelectChange, handleDateChange,
            handleRemoveRow, handleAddRow, handleSelectRowChange, nominalList, formErrors, handleFileChange, clearFile, downloadPresignedFile, paymentDetails, staffList, editable, statementList } = this.props;
            const { dialog, receiptData, receiptErrors } = this.state;
        return (
            <>
                <Dialog 
                    open={open} 
                    onClose={onClose} 
                    fullWidth={true} 
                    maxWidth="xl"
                    disableBackdropClick
                    scroll="body"
                >
                    <DialogContent>
                        <form noValidate autoComplete="off">
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={4}>
                                    <PaddedPaper>
                                        <FormControl fullWidth>
                                            <AutoCompleteSelect 
                                                options={staffList}
                                                name='assignedTo'
                                                value={formData.assignedTo}
                                                label={formData.status == 'Completed' ? 'Assigned To' : 'Assigned To *'}
                                                error={(formErrors && formErrors['assignedTo'] && true) || false}
                                                errorText={formErrors && formErrors['assignedTo']}
                                                onChange={handleSelectChange('assignedTo')}
                                                fullWidth
                                                disabled={formData.status == 'Completed'}
                                            />
                                        </FormControl>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <DatePicker
                                                    type="date"
                                                    id="receiptDate"
                                                    name="receiptDate"
                                                    label="Receipt Date *"
                                                    value={formData.receiptDate}
                                                    errorText={formErrors && formErrors['receiptDate']}
                                                    onChange={handleDateChange('receiptDate')}
                                                    autoOk={true}
                                                />
                                            </Grid>
                                            {formData.status =='Completed' &&
                                            <>
                                                <Grid item>
                                                    <Button  style={{marginTop:'18%'}} onClick={() => downloadPresignedFile(paymentDetails.receiptFileUrl)} size='small' autoFocus variant="contained" color="primary">
                                                        <FALightIcon icon='download' white /> Download Receipt
                                                    </Button>
                                                </Grid>
                                                <Grid item style={{display: 'flex', flexDirection: 'column-reverse'}} >
                                                    <AllIcon icon='upload' onClick={() => this.toggleDialog('receiptDialog')} noMargin tooltip="Upload New Receipt" ttPlacement="bottom" />
                                                </Grid>
                                            </>
                                            }
                                        </Grid>
                                        {formData.status =='Completed' &&
                                            <Grid container spacing={3}>
                                                <Grid item xs={6}>
                                                    <DatePicker
                                                        type="date"
                                                        id="statementDate"
                                                        name="statementDate"
                                                        label="Statement Date"
                                                        value={formData.statementDate}
                                                        errorText={formErrors && formErrors['statementDate']}
                                                        onChange={handleDateChange('statementDate')}
                                                        autoOk={true}
                                                    /> 
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth margin="normal">
                                                        <AutoCompleteSelect 
                                                            options={statementList}
                                                            name='bms'
                                                            label='Monthly Statement'
                                                            error={formErrors && formErrors['bms'] && true}
                                                            errorText={formErrors && formErrors['bms']}
                                                            value={formData.bms}
                                                            onChange={handleSelectChange('bms')}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        }
                                    </PaddedPaper>
                                    <br></br>
                                    <PaddedPaper style={{backgroundColor:'#f8f9fa'}}>
                                        <Typography variant="body1">
                                            Payment Data
                                        </Typography>  
                                        <TextField
                                            name="plaidDate"
                                            label="Bank / Card Transaction Date"
                                            value={moment(formData.plaidDate).format("DD-MM-YYYY")}
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                className: 'disabledText'
                                            }}
                                            InputLabelProps={{
                                                className: 'disabledText'
                                            }}
                                            disabled
                                        />
                                        <TextField
                                            name="plaidAccountOwner"
                                            label="Account Owner"
                                            value={formData.plaidAccountOwner}
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                className: 'disabledText'
                                            }}
                                            InputLabelProps={{
                                                className: 'disabledText'
                                            }}
                                            disabled
                                        />
                                        <TextField
                                            name="plaidDescription"
                                            label="Description"
                                            value={formData.plaidDescription}
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                className: 'disabledText'
                                            }}
                                            InputLabelProps={{
                                                className: 'disabledText'
                                            }}
                                            disabled
                                        />
                                        <TextField
                                            name="plaidAmount"
                                            value={formData.plaidAmount}
                                            label="Amount"
                                            type="number"
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                className: 'disabledText',
                                                startAdornment: (
                                                <InputAdornment  position="start">£</InputAdornment>
                                                ),
                                            }}
                                            InputLabelProps={{
                                                className: 'disabledText'
                                            }}
                                            disabled
                                        />
                                    </PaddedPaper>
                                    <br></br>
                                    <PaddedPaper style={{backgroundColor:'#f8f9fa'}}>
                                        <Grid container item spacing={3}>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">
                                                    Authorised by: {paymentDetails?.authorisedBy?.full_name || '-'}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Confirmed by: {paymentDetails?.confirmedBy?.full_name || '-'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>    
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    {formData.status =='Authorisation' ? 
                                        <Grid container alignItems='center' spacing={1}>
                                            <Grid item align='center'>
                                                <FALightIcon icon='credit-card' style={{marginLeft: 8}} size='large' />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body1">
                                                    Authorise Payment
                                                </Typography>  
                                                <Typography variant="caption">
                                                    Amend the relevant fields below and if necessary, break the payment down into multiple payments
                                                </Typography>  
                                            </Grid>         
                                        </Grid>
                                    :
                                        <Typography variant="h6">
                                            <FALightIcon icon='credit-card' /> {formData.status =='Completed' ? 'Payment Details' : 'Confirm Payment'}
                                        </Typography>  
                                    }

                                    <Table style={{minWidth: 900}}>
                                        <TableBody>
                                            {formData.transactionRows.map((item, idx) => {
                                                let number = idx+1
                                                return (
                                                    <TableRow key={`tr${idx}`}>
                                                        <TableCell className={classes.td} style={{width:'35%'}}>
                                                            <TextField
                                                                name="description"
                                                                label="Description *"
                                                                error={(formErrors && formErrors['transactionRows|description|'+idx] && true) || false}
                                                                helperText={formErrors && formErrors['transactionRows|description|'+idx]}
                                                                value={item.description}
                                                                margin="normal"
                                                                fullWidth
                                                                onChange={handleRowChange(idx)}
                                                                InputProps={{
                                                                    tabIndex: "1"
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.td} style={{width:'2%', verticalAlign:'bottom'}}>
                                                            <Checkbox
                                                                name="editNominal"
                                                                checked={formData.status =='Authorisation' || !item.transId ? true : item.editNominal}
                                                                onChange={formData.status =='Authorisation' || !item.transId ? null : handleRowChange(idx)}
                                                                value="true"
                                                                color="primary"
                                                                tabIndex="-1"
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.td}>
                                                            {formData.status =='Authorisation' || !item.transId || item.editNominal ?
                                                                <FormControl fullWidth>
                                                                    <AutoCompleteSelect 
                                                                        options={nominalList}
                                                                        value={item.nominalCode}
                                                                        label='Nominal Code *'
                                                                        error={(formErrors && formErrors['transactionRows|nominalCode|'+idx] && true) || false}
                                                                        errorText={formErrors && formErrors['transactionRows|nominalCode|'+idx]}
                                                                        onChange={handleSelectRowChange(idx, 'nominalCode', 'transactionRows')}
                                                                        fullWidth
                                                                        tabIndex='2'
                                                                    />
                                                                </FormControl> 
                                                            :
                                                                <TextField
                                                                    name="existingNominalLabel"
                                                                    label="Nominal Code *"
                                                                    value={item.existingNominalLabel}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    disabled
                                                                />
                                                            }
                                                        </TableCell>
                                                        
                                                        <TableCell className={classes.td} style={{width:'13%'}}>
                                                            <TextField
                                                                name="net"
                                                                value={item.net}
                                                                label="Net *"
                                                                error={(formErrors && formErrors['transactionRows|net|'+idx] && true) || false}
                                                                helperText={formErrors && formErrors['transactionRows|net|'+idx]}
                                                                onChange={handleRowChange(idx)}
                                                                onBlur={handleRowChange(idx, 2)}
                                                                type="number"
                                                                margin="normal"
                                                                fullWidth
                                                                InputProps={{
                                                                    startAdornment: (
                                                                    <InputAdornment  position="start">£</InputAdornment>
                                                                    ),
                                                                    tabIndex:"3"
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.td} style={{width:'13%'}}>
                                                            <TextField
                                                                name="vat"
                                                                value={item.vat}
                                                                label="VAT *"
                                                                error={(formErrors && formErrors['transactionRows|vat|'+idx] && true) || false}
                                                                helperText={formErrors && formErrors['transactionRows|vat|'+idx]}
                                                                onChange={handleRowChange(idx)}
                                                                onBlur={handleRowChange(idx, 2)}
                                                                type="number"
                                                                margin="normal"
                                                                fullWidth
                                                                InputProps={{
                                                                    startAdornment: (
                                                                    <InputAdornment  position="start">£</InputAdornment>
                                                                    ),
                                                                    tabIndex:"4"
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.td} style={{width:'13%'}}>
                                                            <TextField
                                                                name="gross"
                                                                value={item.gross}
                                                                label="Amount"
                                                                error={(formErrors && formErrors['transactionRows|gross|'+idx] && true) || false}
                                                                helperText={formErrors && formErrors['transactionRows|gross|'+idx]}
                                                                type="number"
                                                                margin="normal"
                                                                fullWidth
                                                                InputProps={{
                                                                    className: 'disabledText',
                                                                    startAdornment: (
                                                                    <InputAdornment  position="start">£</InputAdornment>
                                                                    ),
                                                                }}
                                                                InputLabelProps={{
                                                                    className: 'disabledText'
                                                                }}
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.td} style={{textAlign:'right', width:'2%'}}>
                                                            {number > 1 &&
                                                                <IconButton onClick={handleRemoveRow(idx)}>
                                                                    <FALightIcon icon='times' noMargin button />
                                                                </IconButton>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell className={classes.td}>
                                                    {editable && 
                                                        <Button 
                                                            onClick={handleAddRow}
                                                            variant="contained"
                                                            color="primary"
                                                            size="small">
                                                            Split Payment
                                                        </Button>
                                                    }
                                                </TableCell>
                                                <TableCell colSpan={2} className={classes.td} style={{textAlign:'right', paddingTop:'3.1%'}} >
                                                    <Typography variant="body1" style={{fontWeight:'bold'}}>Totals</Typography>
                                                </TableCell>
                                                <TableCell className={classes.td}>
                                                    <TextField
                                                        name="overallNat"
                                                        value={formData.overallNet}
                                                        label="Total Net"
                                                        type="number"
                                                        margin="normal"
                                                        fullWidth
                                                        InputProps={{
                                                            className: 'disabledText',
                                                            startAdornment: (
                                                            <InputAdornment  position="start">£</InputAdornment>
                                                            )
                                                        }}
                                                        InputLabelProps={{
                                                            className: 'disabledText'
                                                        }}
                                                        disabled
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td}>
                                                    <TextField
                                                        name="overallVat"
                                                        value={formData.overallVat}
                                                        label="Total Vat"
                                                        type="number"
                                                        margin="normal"
                                                        fullWidth
                                                        InputProps={{
                                                            className: 'disabledText',
                                                            startAdornment: (
                                                            <InputAdornment  position="start">£</InputAdornment>
                                                            )
                                                        }}
                                                        InputLabelProps={{
                                                            className: 'disabledText'
                                                        }}
                                                        disabled
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td}>
                                                    <TextField
                                                        name="overallAmount"
                                                        value={formData.overallAmount}
                                                        label="Total Amount"
                                                        error={(formData.overallAmount != formData.plaidAmount && true) || false}
                                                        type="number"
                                                        margin="normal"
                                                        fullWidth
                                                        InputProps={{
                                                            className: 'disabledText',
                                                            startAdornment: (
                                                            <InputAdornment  position="start">£</InputAdornment>
                                                            )
                                                        }}
                                                        InputLabelProps={{
                                                            className: 'disabledText'
                                                        }}
                                                        disabled
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={6} textalign='right'><FormHelperText error>{formErrors && formErrors['plaidAmount']}</FormHelperText></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                                    {formData.status =='Confirmation' &&
                                        <>
                                            <br></br>
                                            <Typography variant="h6">
                                                <FALightIcon icon='receipt' />Upload Receipt
                                            </Typography>
                                            <DragFileInput
                                                id="file"
                                                name="file"
                                                label="Receipt *"
                                                file={formData.file}
                                                errorText={formErrors && formErrors['file']}
                                                onChange={handleFileChange}
                                                cancelOnClick={clearFile}
                                                emptyText='No file selected'
                                            />
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} variant="outlined" color="default">Cancel</Button>
                        {editable && 
                            <Button onClick={() => handleDialogTransactionSuccess()} autoFocus variant="contained" color="primary" disabled={formData.overallAmount != formData.plaidAmount}>
                                Confirm
                            </Button>
                        }
                    </DialogActions>
                </Dialog>

                { /* Update Receipt */ }
                <SmallFormDialog
                    open={dialog?.receiptDialog}
                    title={`Upload New Receipt`}
                    maxWidth="sm"
                    content={
                        <Grid container item spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="caption" paragraph>
                                    Uploading a new receipt will overwrite the existing receipt.
                                </Typography>
                                <form noValidate autoComplete="off">
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12}>
                                            <DragFileInput
                                                id="file"
                                                name="file"
                                                label="Receipt *"
                                                file={receiptData.file}
                                                errorText={receiptErrors && receiptErrors['file']}
                                                onChange={this.fileChange}
                                                cancelOnClick={this.clearFile}
                                                emptyText='No file selected'
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                                <DialogActions className='pr-0 pb-0'>
                                    <Button 
                                        onClick={() => this.closeReceiptDialog()} 
                                        variant="outlined"
                                    >
                                        Cancel
                                    </Button>
                                    <Button 
                                        color="primary"
                                        onClick={() => this.handleSubmit()}
                                        variant="contained" 
                                    >
                                        Upload
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    }
                />
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deploySnackBar:     (variant, msg)  => dispatch(deploySnackBar(variant, msg))
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(TransactionDialog));