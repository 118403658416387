import React        from 'react';
import API          from 'API';
import _            from 'lodash';
import { Redirect } from 'react-router-dom';
import { create, all }              from 'mathjs'

import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, Paper, Radio, RadioGroup, TableCell, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import BackButton         from 'Components/Common/Buttons/BackButton';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import FALightIcon        from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import SnackBar           from 'Components/Common/SnackBars/SnackBar';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons                      from 'Helpers/IconHelper';

import { downloadS3File } from 'Functions/MiscFunctions';

const math = create(all, {});

const TableHeaderCell = withStyles(theme => ({
    head: {
        backgroundColor: colors.blue,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    }
}))(TableCell);

const initialState = {
    subAssembly: {},
    formData: {
        parts: [{
            part: '',
            quantity: '',
            origQuantity: '',
            saId: '',
            delete: false,
            partId: '',
            partNumber: '',
            partDesc: '',
            costPerUnit: '',
            totalCost: '',
            costPercent: ''
        }],
        jigs: [{
            part:       '',
            partId:     '',
            partNumber: '',
            delete:     false,
            saId:       '',
        }],
        originalParts: [],
        subAssembly: null,
        saId: null,
        saPartId: null,
        saPartNumber: null,
        saVersion: null,
        updateType: '',
        outsourcedSubAssembly: false
    },
    instructions: [],
    instructionsError: {
        parts:  {},
        show:   false,
    },
    instructionTestFiles: [],
    showSubAssemblyParts: true,
    formErrors: {},
    parts: [],
    partsList: [],
    confirmationOpen: false,
    snackbarOpen: false,
    successRedirect: false,
    redirect: false,
    isLoading: false,
    usedIn: []
};

class UpdateSubAssembly extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getSubAssembly();
    }

    handleSubmit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            API.post('/subAssemblies/' + this.props.match.params.id + '/update', {...this.state.formData, instructions: this.state.instructions})
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        successRedirect: true
                    });
                }
                this.props.scrollToTop();
            });
        });
    }

    getSubAssembly = () => {
        API.get('/subAssemblies/' + this.props.match.params.id)
        .then((result) => {
            if (result.data.errors) {
                this.setState({ redirect: true });
            } else {
                this.getDocumentsForPart(result.data.sa_part_id);
                this.getPartsList();
                let partsList = _.map(result.data.sub_assembly_parts, (el) => {
                    return _.assign({
                        part: el.part.part_number + ' - ' + el.part.part_description,
                        quantity: el.sa_qty,
                        origQuantity: el.sa_qty,
                        saId: el.sa_id,
                        delete: false,
                        partId: el.part.part_id,
                        partNumber: el.part.part_number,
                        partDesc: el.part.part_description,
                        costPerUnit: el.part.default_cost.cost_per_unit,
                        totalCost: el.part.default_cost.cost,
                    });
                });
                let jigsList =[];
                _.each(result.data.instruction.stages, e => {
                    _.each(e.jigs, j => {
                        jigsList.push({
                            part:       j.jig.part_number + ' - ' + j.jig.part_description,
                            partId:     j.jig.part_id,
                            partNumber: j.jig.part_number,
                            delete:     false,
                            saId:       j.saisj_id,
                        });
                    } );
                })

                partsList = _.sortBy(partsList, pn => pn.partNumber)
                this.setState({
                    subAssembly: result.data,
                    formData: {
                        ...this.state.formData,
                        subAssembly: result.data.part.part_number + ' - ' + result.data.part.part_description,
                        saId: result.data.sa_id,
                        saPartId: result.data.sa_part_id,
                        saPartNumber: result.data.part.part_number,
                        saVersion: result.data.sa_version,
                        parts: partsList,
                        jigs: jigsList,
                        originalParts: partsList,
                        outsourcedSubAssembly: result.data.part.part_outsourced_subassembly === 1 ? true : false
                    },
                    instructions: result.data.instruction ? _.map(result.data.instruction.stages, i => _.assign({
                        stage:      i.sais_number,
                        name:       i.sais_name,
                        test:       i.sais_test_stage > 0 ? true : false,
                        testFile:   i.sais_test_stage,
                        parts:      _.extend.apply({}, _.map(i.parts, p => _.assign({[p.saisp_part_id]:p.saisp_qty}))),
                        jigs:       _.map(i.jigs, j => j.jig.part_id),
                    })) : [],
                },this.getPartUsage);
            }
        });
    }

    getPartUsage = () => {
        API.get(`/parts/${this.state.subAssembly.sa_part_id}/partsUsage?origPart=${this.state.subAssembly.sa_part_id}`)
        .then((result) => {
            this.setState({ 
                usedIn:_.map(_.keys(_.merge(result.data?.Direct, result.data?.Indirect)), i => parseInt(i))
            });
        })
    }

    getDocumentsForPart = partId => {
        API.get(`/parts/${partId}/documents`,)
        .then((result) => {
            this.setState({
                instructionTestFiles: _.map(_.filter(result.data, i => i.category_name == 'Test Procedures' && i.file_url_pdf ), i => _.assign({
                    value: i.doc_id,
                    label: i.doc_title
                }))
            })
        })
    }

    getPartsList = () => {
        API.get(`/parts/availableForSubAssembly/${this.props.match.params.id}`)
        .then((result) => {
            let partsList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description,
                    part:  el
                });
            });
            this.setState({
                parts: result.data,
                partsList: partsList
            });
        })
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };
    handleChange = fieldName => event => {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.value
            },
            showSubAssemblyParts: (fieldName === 'updateType' && event.target.value === 'Part') ? true : false
        });
    }
    handlePartsChange = (idx, decimals) => e => {
        const { name, value } = e.target;
        let newParts =  [...this.state.formData.parts];
        let newVal = decimals ? parseFloat(value).toFixed(decimals) : value;
        newParts[idx] = {
            ...newParts[idx],
            [name]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                parts: newParts
            }
        }, this.checkInstructionsError);
    }
    handlePartsSelectChange = (fieldName, idx) => selectedOption => {
        let newParts =  [...this.state.formData.parts];
        newParts[idx] = {
            ...newParts[idx],
            [fieldName]: selectedOption && selectedOption.value,
            partNumber:  selectedOption && selectedOption.part.part_number,
        };
        this.setState({
            formData: {
                ...this.state.formData,
                parts: newParts
            }
        }, this.checkInstructionsError);
    };
    handleJigsSelectChange = (fieldName, idx) => selectedOption => {
        let newParts =  [...this.state.formData.jigs];
        newParts[idx] = {
            ...newParts[idx],
            partId:      selectedOption && selectedOption.value,
            partNumber:  selectedOption && selectedOption.part.part_number,
            part:        selectedOption && selectedOption.label,
        };
        this.setState({
            formData: {
                ...this.state.formData,
                jigs: newParts
            }
        }, this.checkInstructionsError);
    };
    handleCheckChange = idx => e => {
        let newParts =  [...this.state.formData.parts];
        newParts[idx] = {
            ...newParts[idx],
            delete: e.target.checked
        };
        this.setState({
            formData: {
                ...this.state.formData,
                parts: newParts
            },
            instructions: _.map(this.state.instructions, i => {
                _.each(_.filter(newParts, j => j.delete), l => {delete i.parts[l.partId];})
                return i
            })
        }, this.checkInstructionsError);
    }
    handleJigCheckChange = idx => e => {
        let newParts =  [...this.state.formData.jigs];
        newParts[idx] = {
            ...newParts[idx],
            delete: e.target.checked
        };
        this.setState({
            formData: {
                ...this.state.formData,
                jigs: newParts
            },
            instructions: _.map(this.state.instructions, i => {
                _.each(_.filter(newParts, j => j.delete), l => {i.jigs && delete i.jigs[_.findIndex(i.jigs, j => j === l.partId)];})
                return i
            })
        }, this.checkInstructionsError);
    }
    handleAddPart = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                parts: [...this.state.formData.parts, _.cloneDeep(initialState.formData.parts[0])]
            }
        }, this.checkInstructionsError);
    }
    handleAddJig = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                jigs: [...this.state.formData.jigs, _.cloneDeep(initialState.formData.jigs[0])]
            }
        }, this.checkInstructionsError);
    }
    handleRemovePart = (idx) => () => {
        const parts = [...this.state.formData.parts]
        let partId = parts[idx].partId;
        parts.splice(idx, 1);
        this.setState({
            formData: {
                ...this.state.formData,
                parts: parts
            },
            instructions: _.map(this.state.instructions, i => {
                let parts = i.parts;
                delete parts[partId];
                return {
                    ...i,
                    parts
                }
            })
        }, this.checkInstructionsError);
    }
    handleRemoveJig = (idx) => () => {
        const jigs = [...this.state.formData.jigs]
        jigs.splice(idx, 1);
        this.setState({
            formData: {
                ...this.state.formData,
                jigs: jigs
            }
        }, this.checkInstructionsError);
    }
    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    }
    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    }
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    }
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }
    handleAddStage = () => {
        this.setState({
            instructions: [
                ...this.state.instructions, 
                {
                    stage:      this.state.instructions.length + 1,
                    name:       '',
                    test:       false,
                    testFile:   0,
                    parts:      {}
                }
            ]
        }, this.checkInstructionsError)
    }
    handleInstructionsChange = (stage, field) => (e) => {
        this.setState({
            instructions: this.state.instructions.map(i => {
                return i.stage === stage ? {...i, [field] : e.target.value} : i;
            })
        }, this.checkInstructionsError)
    }
    handleCheckbox = stage => e => {
        this.setState({
            instructions: this.state.instructions.map(i => {
                return i.stage === stage ? {...i, test: !i.test} : i;
            })
        }, this.checkInstructionsError)
    }
    handleInstructionsPartsChange = (stage, partId, fixed = null) => (e) => {
        this.setState({
            instructions: this.state.instructions.map(i => {
                return i.stage === stage ? 
                    {...i, 
                        parts: {
                            ...i.parts, 
                            [partId]: fixed ? parseFloat(e.target.value).toFixed(3) : e.target.value
                        }
                    } : i;
            })
        }, this.checkInstructionsError)
    }
    handleInstructionsJigsChange = (stage, partId, fixed = null) => (e) => {
        this.setState({
            instructions: this.state.instructions.map(i => {
                if (i.stage !== stage) return i;
                return {...i, jigs: i.jigs && i.jigs.includes(partId) ? _.filter(i.jigs, j => j !== partId) : ( i.jigs ? [...i.jigs, partId] : [partId])};
            })
        }, this.checkInstructionsError)
    }

    checkInstructionsError = () => {
        let parts = _.filter(
            _.filter(this.state.showSubAssemblyParts ? this.state.formData.parts : this.state.formData.originalParts, i => !i.delete ),
            (item, idx) => {
                let stageTotal = parseFloat(math.sum(_.map(this.state.instructions, i => parseFloat(i.parts[item.partId]) || 0.000))).toFixed(3);
                let difference = math.subtract(stageTotal,item.quantity);
                return (parseFloat(difference) !== 0) ? item.partId : null;
            }
        );

        this.setState({
            instructionsError: {
                parts,
                show: parts.length > 0 ? true : false
            }
        });
    }
    handleInstructionsFileChange = stage => e => {
        this.setState({
            instructions: this.state.instructions.map(i => {
                return i.stage === stage ? {...i, testFile: e ? e.value : 0} : i;
            })
        }, this.checkInstructionsError);
    }
    handleRemoveInstruction = stage => {
        this.setState({
            instructions: _.map(_.filter(this.state.instructions, i => i.stage !== stage), (i,idx) => {
                return {
                    ...i, 
                    name:   i.stage > stage ? '' : i.name, 
                    stage:  (idx+1),  
                }
            })
        }, this.checkInstructionsError);
    }
    handleNumberOfStagesChange = (e) => {   
        let instructions = this.state.instructions;
        if (instructions.length < e.value) {
            //loop around instructions and add new ones
            for(var i = instructions.length; i < e.value; i++) {
                instructions.push({
                    stage:      i + 1,
                    name:       '',
                    test:       false,
                    testFile:   0,
                    parts:      {}
                });
            }
        } else {
            instructions.splice(e.value - instructions.length);
        }
        this.setState({instructions},this.checkInstructionsError);
    }
    render() {
        if (this.state.successRedirect === true) {
            return <Redirect 
                        to={{
                            pathname:`/sub-assemblies/view/${this.props.match.params.id}`,
                            state: {successMsg: 'You have successfully updated the sub assembly'}
                        }}
                    />
        }
        if (this.state.redirect === true) {
            return <Redirect to="/sub-assemblies/search/" />
        }
        const { classes } = this.props;
        const { formData, formErrors, instructions, instructionsError, usedIn } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Update Sub Assembly
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Sub Assembly: {formData.subAssembly}
                            </Typography>
                            <Typography variant="h6">
                                Current Version: v{formData.saVersion}
                            </Typography>
                            <FormControl fullWidth margin="normal" error={formErrors && formErrors['updateType'] && true}>
                                <RadioGroup
                                    name="updateType"
                                    value={formData.updateType}
                                    onChange={this.handleChange('updateType')}
                                    row
                                >
                                    <FormControlLabel
                                        value="Part"
                                        control={<Radio color="primary" />}
                                        label="Update Part"
                                        labelPlacement="end"
                                        disabled={formData.outsourcedSubAssembly}
                                    />
                                    <FormControlLabel
                                        value="Document"
                                        control={<Radio color="primary" />}
                                        label="Update Document"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="Outsourced"
                                        control={<Radio color="primary" />}
                                        label="Outsourced"
                                        labelPlacement="end"
                                        disabled={formData.outsourcedSubAssembly}
                                    />
                                    <FormControlLabel
                                        value="SubAssembly"
                                        control={<Radio color="primary" />}
                                        label="Sub Assembly"
                                        labelPlacement="end"
                                        disabled={!formData.outsourcedSubAssembly}
                                    />
                                </RadioGroup>
                                {formErrors && formErrors['updateType'] && <FormHelperText>{formErrors['updateType']}</FormHelperText>}
                            </FormControl>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                {formData.updateType !== 'Outsourced' &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container spacing={3} style={{justifyContent: 'space-between'}}>
                                <Grid item>
                                    <Typography variant="h6">
                                        Sub Assembly Build Stages
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {this.state.subAssembly.part?.pdf_drawing && this.state.subAssembly.part?.pdf_drawing?.part_doc_url &&
                                        <Button variant='contained' color='primary' onClick={() => downloadS3File(this.state.subAssembly.part.pdf_drawing.url)}>
                                            <AllIcon icon={icons.download} color={colors.white} />Process Document
                                        </Button>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options=    {_.map(_.range(1,16), i => _.assign({value: i, label: i}))}
                                            label=      'Number Of Stages *'
                                            value=      {instructions.length}
                                            onChange=   {this.handleNumberOfStagesChange}
                                            noClear
                                        />
                                    </FormControl>
                                </Grid>
                                {instructions && 
                                    <Grid container xs={12} spacing={3} style={{margin: '0.75em 0'}}>
                                        {_.map(instructions, i =>
                                            <Grid item xs={12} key={i.stage}>
                                                <Grid container style={{justifyContent: 'space-between', alignItems: 'flex-end'}}>
                                                    <Grid item xs={2}>
                                                        <Typography variant="body1">
                                                            Stage {i.stage}:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <FormControl fullWidth margin="none">
                                                            <TextField
                                                                value       = {i.name}
                                                                onChange    = {this.handleInstructionsChange(i.stage, 'name')}
                                                                placeholder = 'Name *'
                                                                error       = {formErrors[i.stage]}
                                                                helperText  = {formErrors[i.stage]}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <AllIcon 
                                                            icon=    {icons.delete}
                                                            onClick= {()=>{this.handleRemoveInstruction(i.stage)}}
                                                            tooltip= "Delete"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                }
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
                {/*!this.state.showSubAssemblyParts && formData.updateType !== 'Outsourced' &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Sub Assembly Jigs
                            </Typography><br/>
                            <Grid container spacing={3} xs={12}>
                                {this.state.formData.jigs.map((item, idx) => {
                                    let number = idx+1;
                                    return (
                                        <Grid item xs={12} key={idx}>
                                            <Paper className={classes.paperGrey}>
                                                <Grid container xs={12} spacing={1} style={{alignItems: 'center'}}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            margin="normal"
                                                            name={`Jig${number}`}
                                                            label={`Jig ${number}:`}
                                                            value={formData.jigs[idx].part}
                                                            fullWidth
                                                            disabled
                                                        />
                                                    </Grid>
                                                    {!formData.jigs[idx].delete && !!formData.jigs[idx].partId &&
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={1} xs={12}>
                                                                    {_.map(_.filter(instructions, i => !i.delete ), i => 
                                                                        <Grid item style={{width: instructions.length > 6 && `${100 / instructions.length}%`}}>
                                                                            <Checkbox
                                                                                margin=     "none"
                                                                                checked=    {i.jigs.includes(formData.jigs[idx].partId)}
                                                                                onChange=   {this.handleInstructionsJigsChange(i.stage, formData.jigs[idx].partId)}
                                                                                color=      "primary"
                                                                            />
                                                                            Stage {i.stage}
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    }
                                                </Grid>
                                            </Paper>   
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                */}
                {/*(this.state.showSubAssemblyParts && !formData.outsourcedSubAssembly) &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Sub Assembly Jigs
                            </Typography><br/>
                            <Grid container spacing={3} xs={12}>
                                {this.state.formData.jigs.map((item, idx) => {
                                    let number = idx+1;
                                    return (
                                        <Grid item xs={12} key={idx}>
                                            <Paper className={classes.paperGrey}>
                                                <Grid container xs={12} spacing={1} style={{alignItems: 'center'}}>
                                                    <Grid item xs={12}>
                                                        {formData.jigs[idx].saId ?
                                                            <TextField
                                                                margin="normal"
                                                                name={`Jig${number}`}
                                                                label={`Jig ${number}:`}
                                                                value={formData.jigs[idx].part}
                                                                fullWidth
                                                                disabled
                                                            />
                                                            :
                                                            <FormControl fullWidth margin="normal">
                                                                <AutoCompleteSelect
                                                                    label={`Jig ${number}:`}
                                                                    options={_.filter(this.state.partsList, i=> !usedIn.includes(i.value) && i.part.part_type_id === 21 && !_.find(formData.jigs, {partId: i.value}))}
                                                                    error={formErrors && formErrors['jigs|jig|' + idx] && true}
                                                                    errorText={formErrors && formErrors['jigs|jig|' + idx]}
                                                                    value={formData.jigs[idx].partId}
                                                                    onChange={this.handleJigsSelectChange('partId', idx)}
                                                                />
                                                            </FormControl>
                                                        }
                                                    </Grid>
                                                    {!formData.jigs[idx].delete && !!formData.jigs[idx].partId &&
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={1} xs={12}>
                                                                    {_.map(_.filter(instructions, i => !i.delete ), i => 
                                                                        <Grid item style={{width: instructions.length > 6 && `${100 / instructions.length}%`}}>
                                                                            <Checkbox
                                                                                margin=     "none"
                                                                                checked=    {i?.jigs?.includes(formData?.jigs[idx]?.partId)}
                                                                                onChange=   {this.handleInstructionsJigsChange(i.stage, formData.jigs[idx].partId)}
                                                                                color=      "primary"
                                                                            />
                                                                            Stage {i.stage}
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid item xs={12} style={{textAlign: 'right'}}>
                                                        <Grid container style={{justifyContent: 'flex-end', alignItems: 'center'}}>
                                                            <Grid item><Typography variant='h6' style={{color: colors.red}}>Delete</Typography> </Grid>
                                                            <Grid item>
                                                                {formData.jigs[idx].saId ? 
                                                                    <Checkbox
                                                                        checked={formData.jigs[idx].delete}
                                                                        onChange={this.handleJigCheckChange(idx)}
                                                                        value={idx}
                                                                        color='secondary'
                                                                    />
                                                                :
                                                                    <IconButton onClick={this.handleRemoveJig(idx)}>
                                                                        <FALightIcon icon='times-circle' noMargin button />
                                                                    </IconButton>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Paper>   
                                        </Grid>
                                    )
                                })}
                                <Grid item xs={12} sm={6} md={3} lg={2}>
                                    <PaddedPaper className={`${classes.paperGrey} ${classes.addPart} ${classes.partCardMargin}`} onClick={this.handleAddJig}>
                                        <FALightIcon icon='plus' noMargin button style={{width: '100%', height: '74px', color: '#ddd'}} />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                */}
                {/*!this.state.showSubAssemblyParts && formData.updateType !== 'Outsourced' &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Sub Assembly Jigs
                            </Typography><br/>
                            <Grid container spacing={3} xs={12}>
                                {this.state.formData.jigs.map((item, idx) => {
                                    let number = idx+1;
                                    return (
                                        <Grid item xs={12} key={idx}>
                                            <Paper className={classes.paperGrey}>
                                                <Grid container xs={12} spacing={1} style={{alignItems: 'center'}}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            margin="normal"
                                                            name={`Jig${number}`}
                                                            label={`Jig ${number}:`}
                                                            value={formData.jigs[idx].part}
                                                            fullWidth
                                                            disabled
                                                        />
                                                    </Grid>
                                                    {!formData.jigs[idx].delete && !!formData.jigs[idx].partId &&
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={1} xs={12}>
                                                                    {_.map(_.filter(instructions, i => !i.delete ), i => 
                                                                        <Grid item style={{width: instructions.length > 6 && `${100 / instructions.length}%`}}>
                                                                            <Checkbox
                                                                                margin=     "none"
                                                                                checked=    {i.jigs.includes(formData.jigs[idx].partId)}
                                                                                onChange=   {this.handleInstructionsJigsChange(i.stage, formData.jigs[idx].partId)}
                                                                                color=      "primary"
                                                                            />
                                                                            Stage {i.stage}
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    }
                                                </Grid>
                                            </Paper>   
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                */}
                {(this.state.showSubAssemblyParts && !formData.outsourcedSubAssembly) &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                    <React.Fragment> 
                                        <Typography variant="h6">
                                            Sub Assembly Parts
                                        </Typography><br/>
                                        {formErrors && formErrors['generic'] && (
                                            <FormControl fullWidth margin="normal">
                                                <Typography variant="body2" style={{color:colors.red}}>{formErrors['generic']}</Typography>
                                            </FormControl>
                                        )}
                                        <Grid container spacing={3} xs={12}>
                                            {this.state.formData.parts.map((item, idx) => {
                                                let number = idx+1;
                                                let stageTotal = parseFloat(math.sum(_.map(instructions, i => parseFloat(i.parts[formData.parts[idx].partId]) || 0.000))).toFixed(3);
                                                let difference = math.subtract(stageTotal,formData.parts[idx].quantity);

                                                return (
                                                    <Grid item xs={12} key={idx}>
                                                        <Paper className={classes.paperGrey}>
                                                            <Grid container xs={12} spacing={1} style={{alignItems: 'center'}}>
                                                                <Grid item xs={10}>
                                                                    {formData.parts[idx].saId ?
                                                                        <TextField
                                                                            margin="normal"
                                                                            name={`Part${number}`}
                                                                            label={`Part ${number}:`}
                                                                            value={formData.parts[idx].part}
                                                                            fullWidth
                                                                            disabled
                                                                        />
                                                                        :
                                                                        <FormControl fullWidth margin="normal">
                                                                            <AutoCompleteSelect
                                                                                label={`Part ${number}:`}
                                                                                options={_.filter(this.state.partsList, i=> parseInt(i.value) === parseInt(formData.parts[idx].partId) ? 1 : !_.find(formData.parts, {partId: i.value}))}
                                                                                error={formErrors && formErrors['parts|part|' + idx] && true}
                                                                                errorText={formErrors && formErrors['parts|part|' + idx]}
                                                                                value={formData.parts[idx].partId}
                                                                                onChange={this.handlePartsSelectChange('partId', idx)}
                                                                                noClear
                                                                                key={idx}
                                                                            />
                                                                        </FormControl>
                                                                    }
                                                                </Grid>
                                                                <Grid item >
                                                                    <TextField
                                                                        margin="normal"
                                                                        name="quantity"
                                                                        label="Total Qty *"
                                                                        error={formErrors && formErrors['parts|quantity|' + idx] && true}
                                                                        helperText={formErrors && formErrors['parts|quantity|' + idx]}
                                                                        value={formData.parts[idx].quantity}
                                                                        onChange={this.handlePartsChange(idx)}
                                                                        onBlur={this.handlePartsChange(idx, 3)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                {!formData.parts[idx].delete &&
                                                                    <>
                                                                        <Grid item xs={10}>
                                                                            <Grid container spacing={1} xs={12}>
                                                                                {_.map(_.filter(instructions, i => !i.delete ), i => 
                                                                                    <Grid item style={{width: instructions.length > 6 && `${100 / instructions.length}%`}}>
                                                                                        <TextField
                                                                                            margin=     "none"
                                                                                            label=      {`Stage ${i.stage}`}
                                                                                            value=      {i.parts[formData.parts[idx].partId] || 0.000}
                                                                                            onChange=   {this.handleInstructionsPartsChange(i.stage,formData.parts[idx].partId)}
                                                                                            onBlur=     {this.handleInstructionsPartsChange(i.stage,formData.parts[idx].partId, 3)}
                                                                                            type=       "number"
                                                                                            inputProps= {{min: 0}}
                                                                                        />
                                                                                    </Grid>
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            <Grid container xs={12}>
                                                                                <Grid item xs={6}>
                                                                                    Stage Total:<br/>
                                                                                    Difference:
                                                                                </Grid>
                                                                                <Grid item xs={6} style={{color: (difference) == 0 ? colors.green : colors.red}}>
                                                                                    {parseFloat(stageTotal).toFixed(3)}<br/>
                                                                                    {`${parseFloat(difference) == 0 ? '' : parseFloat(math.subtract(Math.round(stageTotal, 3), formData.parts[idx].quantity)) > 0 ? '+' : '-'}${parseFloat(math.abs(difference)).toFixed(3)}`}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                <Grid item xs={12} style={{textAlign: 'right'}}>
                                                                    <Grid container style={{justifyContent: 'flex-end', alignItems: 'center'}}>
                                                                        <Grid item><Typography variant='h6' style={{color: colors.red}}>Delete</Typography> </Grid>
                                                                        <Grid item>
                                                                            {formData.parts[idx].saId ? 
                                                                                <Checkbox
                                                                                    checked={formData.parts[idx].delete}
                                                                                    onChange={this.handleCheckChange(idx)}
                                                                                    value={idx}
                                                                                    color='secondary'
                                                                                />
                                                                            :
                                                                                <IconButton onClick={this.handleRemovePart(idx)}>
                                                                                    <FALightIcon icon='times-circle' noMargin button />
                                                                                </IconButton>
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Paper>   
                                                    </Grid>
                                                )
                                            })}
                                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                                <PaddedPaper className={`${classes.paperGrey} ${classes.addPart} ${classes.partCardMargin}`} onClick={this.handleAddPart}>
                                                    <FALightIcon icon='plus' noMargin button style={{width: '100%', height: '74px', color: '#ddd'}} />
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                        {/*}
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableHeaderCell></TableHeaderCell>
                                                    <TableHeaderCell>
                                                        Part
                                                    </TableHeaderCell>
                                                    <TableHeaderCell>
                                                        Qty
                                                    </TableHeaderCell>
                                                    <TableHeaderCell style={{textAlign:'center'}}>
                                                        Delete
                                                    </TableHeaderCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.formData.parts.map((item, idx) => {
                                                    let number = idx+1;
                                                    return (
                                                        <>
                                                            <TableRow key={`table-${idx}`} >
                                                                <TableCell style={{color: formErrors && (formErrors['parts|part|' + idx] || formErrors['parts|quantity|' + idx]) && colors.red, borderBottom: 0}}>
                                                                    Part {number}:
                                                                </TableCell>
                                                                <TableCell style={{borderBottom: 0}}>
                                                                    {formData.parts[idx].saId ?
                                                                        <Typography variant="body1">
                                                                            {formData.parts[idx].part}
                                                                        </Typography>
                                                                        :
                                                                        <FormControl fullWidth margin="none">
                                                                            <AutoCompleteSelect
                                                                                options={this.state.partsList}
                                                                                error={formErrors && formErrors['parts|part|' + idx] && true}
                                                                                errorText={formErrors && formErrors['parts|part|' + idx]}
                                                                                value={formData.parts[idx].partID}
                                                                                onChange={this.handlePartsSelectChange('partId', idx)}
                                                                            />
                                                                        </FormControl>
                                                                    }
                                                                </TableCell>
                                                                <TableCell style={{borderBottom: 0}}>
                                                                    <TextField
                                                                        margin="none"
                                                                        name="quantity"
                                                                        error={formErrors && formErrors['parts|quantity|' + idx] && true}
                                                                        helperText={formErrors && formErrors['parts|quantity|' + idx]}
                                                                        value={formData.parts[idx].quantity}
                                                                        onChange={this.handlePartsChange(idx)}
                                                                        onBlur={this.handlePartsChange(idx, 3)}
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={{textAlign:'center', borderBottom: 0}}>
                                                                    {formData.parts[idx].saId ? 
                                                                        <Checkbox
                                                                            checked={formData.parts[idx].delete}
                                                                            onChange={this.handleCheckChange(idx)}
                                                                            value={idx}
                                                                            color='primary'
                                                                        />
                                                                    :
                                                                        <IconButton onClick={this.handleRemovePart(idx)}>
                                                                            <FALightIcon icon='times-circle' noMargin button />
                                                                        </IconButton>
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow key={`table-stages-${idx}`}>
                                                                <TableCell colSpan={2}>
                                                                    <Grid container spacing={1} xs={12}>
                                                                        {_.map(_.filter(instructions, i => !i.delete ), i => 
                                                                            <Grid item xs={1}>
                                                                                <TextField
                                                                                    margin=     "none"
                                                                                    label=      {`Stage ${i.stage}`}
                                                                                    value=      {i.parts[formData.parts[idx].partId] || 0.000}
                                                                                    onChange=   {this.handleInstructionsPartsChange(i.stage,formData.parts[idx].partId)}
                                                                                    onBlur=     {this.handleInstructionsPartsChange(i.stage,formData.parts[idx].partId, 3)}
                                                                                    type=       "number"
                                                                                    inputProps= {{min: 0}}
                                                                                />
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </TableCell>
                                                                <TableCell colSpan={2}>
                                                                    <TextField
                                                                        margin="none"
                                                                        name="quantity"
                                                                        value={parseFloat(_.sumBy(instructions, i => parseFloat(i.parts[formData.parts[idx].partId] || 0))).toFixed(3)}
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                            */}
                                        {formErrors && formErrors['delete'] && (
                                            <FormControl fullWidth margin="normal">
                                                <Typography variant="body2" style={{color:colors.red}}>{formErrors['delete']}</Typography>
                                            </FormControl>
                                        )}
                                        {formErrors && formErrors['no_change'] && (
                                            <FormControl fullWidth margin="normal">
                                                <Typography variant="body2" style={{color:colors.red}}>{formErrors['no_change']}</Typography>
                                            </FormControl>
                                        )}
                                        {/*}
                                        <br></br>
                                        <Button onClick={this.handleAddPart}
                                                variant="outlined"
                                                color="primary">
                                            Add Additional Part
                                        </Button>
                                        */}
                                    </React.Fragment> 
                            </form>
                        </PaddedPaper>
                    </Grid>
                }
                
                {!this.state.showSubAssemblyParts && formData.updateType !== 'Outsourced' &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Sub Assembly Parts
                            </Typography><br/>
                            <Grid container spacing={3} xs={12}>
                                {this.state.formData.originalParts.map((item, idx) => {
                                    let number = idx+1;
                                    let stageTotal = parseFloat(math.sum(_.map(instructions, i => parseFloat(i.parts[formData.parts[idx].partId]) || 0.000))).toFixed(3);
                                    let difference = math.subtract(stageTotal,formData.parts[idx].quantity);

                                    return (
                                        <Grid item xs={12} key={idx}>
                                            <Paper className={classes.paperGrey}>
                                                <Grid container xs={12} spacing={1} style={{alignItems: 'center'}}>
                                                    <Grid item xs={10}>
                                                        <TextField
                                                            margin="normal"
                                                            name={`Part${number}`}
                                                            label={`Part ${number}:`}
                                                            value={formData.parts[idx].part}
                                                            fullWidth
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item >
                                                        <TextField
                                                            margin="normal"
                                                            name="quantity"
                                                            label="Total Qty *"
                                                            error={formErrors && formErrors['parts|quantity|' + idx] && true}
                                                            helperText={formErrors && formErrors['parts|quantity|' + idx]}
                                                            value={formData.parts[idx].quantity}
                                                            onChange={this.handlePartsChange(idx)}
                                                            onBlur={this.handlePartsChange(idx, 3)}
                                                            fullWidth
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Grid container spacing={1} xs={12}>
                                                            {_.map(_.filter(instructions, i => !i.delete ), i => 
                                                                <Grid item style={{width: instructions.length > 6 && `${100 / instructions.length}%`}}>
                                                                    <TextField
                                                                        margin=     "none"
                                                                        label=      {`Stage ${i.stage}`}
                                                                        value=      {i.parts[formData.parts[idx].partId] || 0.000}
                                                                        onChange=   {this.handleInstructionsPartsChange(i.stage,formData.parts[idx].partId)}
                                                                        onBlur=     {this.handleInstructionsPartsChange(i.stage,formData.parts[idx].partId, 3)}
                                                                        type=       "number"
                                                                        inputProps= {{min: 0}}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Grid container xs={12}>
                                                            <Grid item xs={6}>
                                                                Stage Total:<br/>
                                                                Difference:
                                                            </Grid>
                                                            <Grid item xs={6} style={{color: (difference) == 0 ? colors.green : colors.red}}>
                                                                {parseFloat(stageTotal).toFixed(3)}<br/>
                                                                {`${parseFloat(difference) == 0 ? '' : parseFloat(math.subtract(Math.round(stageTotal, 3), formData.parts[idx].quantity)) > 0 ? '+' : '-'}${parseFloat(math.abs(difference)).toFixed(3)}`}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Paper>   
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
                {formData.updateType !== 'Outsourced' &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Sub Assembly Jigs
                            </Typography><br/>
                            <Grid container spacing={3} xs={12}>
                                {this.state.formData.jigs.map((item, idx) => {
                                    let number = idx+1;
                                    return (
                                        <Grid item xs={12} key={idx}>
                                            <Paper className={classes.paperGrey}>
                                                <Grid container xs={12} spacing={1} style={{alignItems: 'center'}}>
                                                    <Grid item xs={12}>
                                                        {formData.jigs[idx].saId ?
                                                            <TextField
                                                                margin="normal"
                                                                name={`Jig${number}`}
                                                                label={`Jig ${number}:`}
                                                                value={formData.jigs[idx].part}
                                                                fullWidth
                                                                disabled
                                                            />
                                                            :
                                                            <FormControl fullWidth margin="normal">
                                                                <AutoCompleteSelect
                                                                    label={`Jig ${number}:`}
                                                                    options={_.filter(this.state.partsList, i=> !usedIn.includes(i.value) && i.part.part_type_id === 21 && !_.find(formData.jigs, {partId: i.value}))}
                                                                    error={formErrors && formErrors['jigs|jig|' + idx] && true}
                                                                    errorText={formErrors && formErrors['jigs|jig|' + idx]}
                                                                    value={formData.jigs[idx].partId}
                                                                    onChange={this.handleJigsSelectChange('partId', idx)}
                                                                    noClear
                                                                />
                                                            </FormControl>
                                                        }
                                                    </Grid>
                                                    {!formData.jigs[idx].delete && !!formData.jigs[idx].partId &&
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={1} xs={12}>
                                                                    {_.map(_.filter(instructions, i => !i.delete ), i => 
                                                                        <Grid item xs={12}>
                                                                            <Checkbox
                                                                                margin=     "none"
                                                                                checked=    {i.jigs && i.jigs.includes(formData.jigs[idx].partId)}
                                                                                onChange=   {this.handleInstructionsJigsChange(i.stage, formData.jigs[idx].partId)}
                                                                                color=      "primary"
                                                                            />
                                                                            Stage {i.stage} - {i.name}
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid item xs={12} style={{textAlign: 'right'}}>
                                                        <Grid container style={{justifyContent: 'flex-end', alignItems: 'center'}}>
                                                            <Grid item><Typography variant='h6' style={{color: colors.red}}>Delete</Typography> </Grid>
                                                            <Grid item>
                                                                {formData.jigs[idx].saId ? 
                                                                    <Checkbox
                                                                        checked={formData.jigs[idx].delete}
                                                                        onChange={this.handleJigCheckChange(idx)}
                                                                        value={idx}
                                                                        color='secondary'
                                                                    />
                                                                :
                                                                    <IconButton onClick={this.handleRemoveJig(idx)}>
                                                                        <FALightIcon icon='times-circle' noMargin button />
                                                                    </IconButton>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Paper>   
                                        </Grid>
                                    )
                                })}
                                <Grid item xs={12} sm={6} md={3} lg={2}>
                                    <PaddedPaper className={`${classes.paperGrey} ${classes.addPart} ${classes.partCardMargin}`} onClick={this.handleAddJig}>
                                        <FALightIcon icon='plus' noMargin button style={{width: '100%', height: '74px', color: '#ddd'}} />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
                
                <Grid item xs={12}>
                    <PaddedPaper>
                        {!!instructionsError.show &&
                            <Grid item xs={12} style={{textAlign: 'right', color: colors.red, marginBottom: '1em'}}>
                                Build Stage parts do not match sub assembly parts
                            </Grid>
                        }
                        {instructions.length == 0 &&
                            <Grid item xs={12} style={{textAlign: 'right', color: colors.red, marginBottom: '1em'}}>
                                Sub assembly requires Build Stages
                            </Grid>
                        }
                        {!this.state.showSubAssemblyParts && formData.updateType !== 'Outsourced' && !_.isEqual(this.state.formData.originalParts, this.state.formData.parts) &&
                            <Grid item xs={12} style={{textAlign: 'right', color: colors.red, marginBottom: '1em'}}>
                                You have changed parts with 'Update Document' selected
                            </Grid>
                        }
                        <div className='buttonRow' style={{marginTop: 0}}>
                            <BackButton props={this.props} />
                            <Button onClick={this.handleConfirmationOpen}
                                    variant="contained"
                                    color="primary"
                                    disabled={ _.filter(_.filter(formData.jigs, {delete: false}), j => _.find(instructions, _j => _j.jigs && _j.jigs.includes(j.partId))).length !== _.filter(formData.jigs, {delete: false}).length || this.state.isLoading || instructionsError.show || instructions.length == 0 || (!this.state.showSubAssemblyParts && formData.updateType !== 'Outsourced' && !_.isEqual(this.state.formData.originalParts, this.state.formData.parts))}>
                                Update
                            </Button>
                        </div>
                    </PaddedPaper>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Update Sub Assembly?" 
                    message="Are you sure you want to update the sub assembly?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully updated the sub assembly'
                />
            </Grid>
        );
    }
}

const styles = theme => ({
    paperGrey: {        
        margin: 0,
        padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px `,
        backgroundColor:'#fefefe'
    }, 
    addPart: {
        display: 'flex',
        alignItems: 'center',
        justify: 'center',
        padding: theme.spacing(1),
    },
});

export default withStyles(styles)(UpdateSubAssembly);