import React from "react";
import ActivityLog from 'Components/Common/ActivityLog/ActivityLog';
import { colors } from "Helpers/ColourHelper";
import IconHelper from "Helpers/IconHelper";
import _ from "lodash";

const aclToItem = {
    Created: {
        color: colors.primary,
        icon: IconHelper.plus,
    },
    Updated: {
        color: colors.primary,
        icon: IconHelper.edit,   
    },

    'Campaign Email Sent': {
        color: colors.primary,
        icon: IconHelper.email,
    },
    'Email Sent': {
        color: colors.primary,
        icon: IconHelper.email,
    },
    'Added To Mailing List': {
        color: colors.primary,
        icon: IconHelper.mailingList,
    },
    'Added To Mailing List (Unsubscribed)': {
        color: colors.primary,
        icon: IconHelper.mailingList,
    },
    'Lapsed From Mailing List': {
        color: colors.red,
        icon: IconHelper.mailingList,
    },
    'Re-added To Mailing List': {
        color: colors.green,
        icon: IconHelper.mailingList,
    },
    'Unsubscribed': {
        color: colors.red,
        icon: IconHelper.ban,
    },
    'Resubscribed': {
        color: colors.green,
        icon: IconHelper.plus,
    },
    'Email Resent': {
        color: colors.primary,
        icon: IconHelper.refresh,
    },
    'Email Forwarded': {
        color: colors.primary,
        icon: IconHelper.forward,
    },
    'Linked Customer': {
        color: colors.primary,
        icon: IconHelper.customers
    }
}

export default function ({contact}){
    return (
        <ActivityLog 
            filter={[
                {label: 'Contact', types: ['Updated', 'Created']}, 
                {label: 'Mailing Lists', types: ['Added To Mailing List', 'Campaign Email Sent', 'Lapsed From Mailing List', 'Re-added To Mailing List', 'Unsubscribed']}, 
                {label: 'Emails', types: ['Email Sent', 'Campaign Email Sent', 'Unsubscribed']}
            ]}
            fields={{
                type:   'cca_type',
                date:   'cca_datetime',
                icon:   { color: 'color', icon: 'icon' },
                text:   'cca_msg',
                staff:  'cca_staff',
            }}
            atl={_.map(contact?.activity_log, i => ({
                ...i,
                color: aclToItem[i.cca_type]?.color,
                icon: aclToItem[i.cca_type]?.icon,
            }))}
        />
    )
}