/**
 * store of all icons so i dont have to remember them & can esaly change them 
 * 
 * ! more will be addded as it gets used
 */
 export default {
 
    //Sections --------------------------------------------
    sales:                               'handshake',
    parts:                               'wrench',
    worksOrder:                          'hammer-crash',
    picking:                             'people-carry',
    videos:                              'video',   
    stock:                               'chart-line',  
    customers:                           'users',
    customer:                            'users',
    accounts:                            'coins',
    partsOrdering:                       'file-powerpoint',
    ssr:                                 'arrow-right-arrow-left',
    returns:                             'box',
    marketing:                           'bullseye-pointer',
    reports:                             'chart-area',
    performance:                         'chart-user',
    survey:                              'pen-to-square',
    forms:                               'clipboard',

    //Common ----------------------------------------------
    contact:                             'user',
    image:                               'image',
    copy:                                'copy',
    refresh:                             'arrows-rotate',
    lock:                                'lock-keyhole',                
    unlock:                              'lock-keyhole-open',
    customerOrders:                      'file-search',
    qr:                                  'qrcode',
    manualEntry:                         'clipboard-list',
    userLocked:                          'user-lock',  
    review:                              'clipboard-check',
    instructions:                        'person-chalkboard',
    addContact:                          'user-plus',    
    removeContact:                       'user-minus',    
    expand:                              'chevron-down',
    link:                                'link',  
    notes:                               'pen',
    percent:                             'percent',
    account:                             'sack-dollar',
    edit:                                'pen',       
    update:                              'pencil-alt',
    download:                            'download',
    history:                             'clock-rotate-left',
    system:                              'robot',
    tick:                                'check',
    unlink:                              'link-slash',
    search:                              'magnifying-glass',
    view:                                'magnifying-glass',
    warning:                             'triangle-exclamation',
    save:                                'floppy-disk',
    today:                               'bell-on',
    clear:                               'undo',
    info:                                'info-circle',
    bin:                                 'trash',
    binAlt:                              'trash-alt',
    delete:                              'trash',
    waiting:                             'snooze',                             
    vat:                                 'percent',
    true:                                'check',
    trueCircle:                          'check-circle',
    false:                               'times',
    falseCircle:                         'times-circle',
    close:                               'times',
    serialNumber:                        'xmarks-lines', 
    greenaware:                          'recycle',
    truck:                               'truck',
    pdf:                                 'file-pdf',
    location:                            'location-crosshairs',
    streetView:                          'street-view',
    map:                                 'map-location',
    bolt:                                'bolt',
    parking:                             'circle-parking',
    locationPin:                         'location-pin',
    bed:                                 'bed',
    locationPlus:                        'location-plus',
    locationSlash:                       'location-pin-slash',
    icons:                               'icons',
    satellite:                           'satellite',
    carbonCopy:                          'envelopes',  
    pound:                               'sterling-sign',
    supplier:                            'truck',
    ev:                                  'car-side-bolt',
    feedback:                            'comment-pen',
    mouse:                               'arrow-pointer',
    thumbTack:                           'thumbtack',
    filter:                              'filter',
    service:                             'wrench-simple',
    inspection:                          'nfc-magnifying-glass',
    todo:                                'clipboard-list',

    //PDF --------------------------------------------------
    zoomIn:                              'magnifying-glass-plus',
    zoomOut:                             'magnifying-glass-minus',
    fullScreen:                          'maximize',
    exitFullScreen:                      'minimize',
    jigs:                                'brackets-square',
    forWhat:                             'turn-down',
    angleLeft:                           'angle-left',
    signOut:                             'right-from-bracket',
    bookmark:                            'bookmark',
    excel:                               'file-excel', 
    mia:                                 'paper-plane',
    plane:                               'plane',
    heartRate:                           'heart-pulse',
    
    //Imports ---------------------------------------------
    circle:                              'circle',
    star:                                'star',
    ban:                                 'ban',
    trophy:                              'trophy',
    exclamation:                         'exclamation',      
    poundSign:                           'pound-sign',
    fileUser:                            'file-user',
    userCheck:                           'user-check',
    receipt:                             'receipt',
    fileCheck:                           'file-check',
    userTimes:                           'user-times',
    balanceScale:                        'balance-scale',
    tally:                               'tally',
    alarmClock:                          'alarm-clock',
    box:                                 'box',
    arrowLeft:                           'arrow-left',
    arrowRight:                          'arrow-right',
    pencil:                              'pencil',
    levelUp:                             'level-up',
    building:                            'building',
    file:                                'file',
    plus:                                'plus',
    plusMinus:                           'plus-minus',
    plusCircle:                          'plus-circle',
    minus:                               'minus',
    equals:                              'equals',
    robot:                               'robot',
    user:                                'user',
    userSecret:                          'user-secret',
    shieldCheck:                         'shield-check',
    upload:                              'upload',
    shopSlash:                           'shop-slash',       
    exclamationCircle:                   'exclamation-circle',        
    wrench:                              'wrench', 
    exclamationTriangle:                 'exclamation-triangle',
    fileCircleExclamation:               'file-circle-exclamation',
    leftLine:                            'left-to-line',
    rightLine:                           'right-to-line',
    ellipsis:                            'ellipsis',
    phone:                               'phone',
    mobile:                              'mobile-alt',
    bars:                                'bars',
    home:                                'home',
    userHardHat:                         'user-hard-hat',
    archive:                             'archive',
    videoSlash:                          'video-slash',
    car:                                 'car',
    bell:                                'bell',
    cube:                                'cube',
    cubes:                               'cubes',
    plug:                                'plug',
    listCheck:                           'list-check',
    paperclip:                           'paperclip',
    shield:                              'shield-halved',
    chartArea:                           'chart-area',
    dollar:                              'dollar-sign',
    message:                             'comment-alt-lines',
    coins:                               'coins',
    fileSignature:                       'file-signature',
    signature:                           'signature',
    random:                              'shuffle',
    csv:                                 'file-csv',
    truckFast:                           'truck-fast',
    handHoldingBox:                      'hand-holding-box',
    temperature:                         'thermometer-three-quarters',
    wallet:                              'wallet',
    bellExclamation:                     'bell-exclamation',
    ellipsisV:                           'ellipsis-vertical',
    list:                                'list',
    trafficLight:                        'traffic-light',
    lineColumns:                         'line-columns',
    locationArrow:                       'location-arrow',
    boxes:                               'boxes-stacked',
    sack:                                'sack',
    addressCard:                         'address-card',
    cloudDownload:                       'cloud-download',
    smiley:                              'smile',
    camera:                              'camera',
    eye:                                 'eye',
    university:                          'university',
    slashForward:                        'slash-forward',
    sort:                                'sort',
    badgeDollar:                         'badge-dollar',
    tag:                                 'tag',
    broken:                              'square-fragile',
    print:                               'print',
    timer:                               'timer',
    qty:                                 'cubes-stacked',
    unpackTruck:                         'truck-ramp-box',

    fillEmpty:                           'dial-off',
    fillMin:                             'dial-min',
    fillLow:                             'dial-low',
    fillMidLow:                          'dial-med-low',
    fillMid:                             'dial-med',
    fillMidHigh:                         'dial',
    fillHigh:                            'dial-high',
    fillMax:                             'dial-max',

    bookReader:                          'book-reader',
    repair:                              'screwdriver-wrench',
    merge:                               'merge',

    checkBoxChecked:                    'square-check',
    checkBoxUnchecked:                  'square',

    //directions
    arrowCircleLeft:                     'arrow-circle-left',
    arrowCircleRight:                    'arrow-circle-right',
    exchange:                            'arrow-right-arrow-left',
    forward:                             'forward',
    arrowDown:                           'chevron-down',
    arrowUp:                             'chevron-up',
    arrowStemDown:                       'arrow-down',
    arrowStemUp:                         'arrow-up',
    dropRow:                             'arrow-turn-down-left',
    dropRowReverse:                      'arrow-turn-down-right',
    previous:                            'chevron-left',
    next:                                'chevron-right',
    stepForward:                         'step-forward',
    repeat:                              'repeat',
    comments:                            'comments',
    externalLink:                        'arrow-up-right-from-square',
    downUp:                              'arrow-down-arrow-up',
    sortDown:                            'sort-down',
    sortUp:                              'sort-up',
    play:                                'play',
    pause:                               'pause',
    dash:                                'dash',

    //Stock ------------------------------------------------
    stockStock:                          'shelves',
    decline:                             'times',    
    
    //Time -------------------------------------------------
    day:                                 'calendar-day',
    week:                                'calendar-week',
    month:                               'calendar-alt',
    year:                                'calendar',
    clock:                               'clock',
    calendarCheck:                       'calendar-check',
    calendarExclamation:                 'calendar-exclamation',
    calendarPlus:                        'calendar-plus',

    //WorksOrder -------------------------------------------
    supervisorApproval:                 'person-circle-check',
    supervisorDecline:                  'person-circle-xmark',
    proccessDocument:                   'book-bible',
    versionMissMatch:                   'lightbulb-exclamation-on',

    //Stock ------------------------------------------------
    stockValue:                          'sack-dollar',
    stockLevel:                          'arrows-from-dotted-line',
    stockPending:                        'clock',
    stockLevelLow:                       'down-from-dotted-line',
    stockLevelHigh:                      'up-from-dotted-line',
    stockLevelCorrect:                   'arrows-to-dotted-line',
    inside:                              'arrows-to-dotted-line',
    outside:                             'arrows-from-dotted-line',
    exact:                               'down-left-and-up-right-to-center', 

    //Orders -----------------------------------------------
    stickyNote:                          'note',
    addStickyNote:                       'note-medical',
    orderAwaitingApproval:               'file-user',
    orderConfirmed:                      'user-check',
    orderAwaitingPayment:                'credit-card',
    orderPaymentConfirmed:               'receipt',
    orderPicking:                        'people-carry',
    orderAwaitingDespatch:               'truck',
    orderAwaitingInvoice:                'file-invoice-dollar',
    orderAwaitingInvoicePayment:         'file-check',
    orderDeclined:                       'user-times',
    orderCancelled:                      'ban',
    orderCompleted:                      'check',

    //CreditNotes -------------------------------------------
    creditOverDue:                       'alarm-exclamation',
    creditDue:                           'alarm-clock',
    creditComplete:                      'check',
    creditCreditNote:                    'minus',

    //Accounts ----------------------------------------------
    overdue:                             'alarm-exclamation',
    due:                                 'alarm-clock',
    complete:                            'check',
    total:                               't',
    average:                             'a',
    services:                            'clenAware',
    products:                            'wrench',

    //Payments ----------------------------------------------
    partialOrderPayment:                'sterling-sign',
    invoice:                            'file-invoice-dollar',  
    creditNote:                         'file-minus',           
    foc:                                'clenAware',            
    card:                               'credit-card',          
    barclaycard:                        'credit-card',          
    unknown:                            'question-circle',  
    directDebit:                        'DirectDebit',    
    dd:                                 'DirectDebit',    
    cheque:                             'money-check-edit',   
    stripe:                             'stripe-s',
    cash:                               'money-bill',  
    bank:                               'piggy-bank',
    bankTransfer:                       'piggy-bank',
    paypal:                             'paypal',
    recurring:                          'sync-alt',
    recurringInvoice:                   'sync-alt',
    stockAdjustment:                    'arrow-trend-down',
    prepayment:                         'calendar-clock',
    cancel:                             'ban',
    'Account - 7 Days':                 'TextToIcon:7',
    'Account - 14 Days':                'TextToIcon:14',
    'Account - 30 Days':                'TextToIcon:30',
    'Account - 60 Days':                'TextToIcon:60',
    'Account - 90 Days':                'TextToIcon:90',
    'Direct Debit':                     'DirectDebit',
    'Spread Payments':                  'tally',
    'Internal Order Process':           'clenAware',
    'Prepayment':                       'calendar-clock',
    'Card Payment':                     'credit-card',
    'Pre-Live Order System':            'block',
    'Invoice':                          'file-invoice-dollar', 
    'Bank Transfer':                    'piggy-bank',
    'Bank Transfer (PR)':               'piggy-bank',
    'Cash':                             'money-bill',
    'Cash (PR)':                        'money-bill',
    'Card':                             'credit-card', 
    'Card (PR)':                        'credit-card', 
    'Card - Barclaycard':               'credit-card',
    'Card - Stripe':                    'credit-card',
    'Stock Adjustment':                 'arrow-trend-down',
    'Paypal':                           'paypal',
    'Paypal (PR)':                      'paypal',
    'PayPal':                           'paypal',
    'PayPal (PR)':                      'paypal',
    'Stripe':                           'stripe-s',
    'Stripe (PR)':                      'stripe-s',
    'FOC':                              'clenAware',  
    'Credit Note':                      'file-minus',  
    'Cheque':                           'money-check-edit', 
    'Cheque (PR)':                      'money-check-edit', 

    //Tables ------------------------------------------------
    tableLast:                           'angles-right',
    tableFirst:                          'angles-left',
    tableNext:                           'chevron-right',
    tablePrevious:                       'chevron-left',
    tablePDF:                            'file-pdf',

    //File Types --------------------------------------------
    filePDF:                             'file-pdf',
    fileCSV:                             'file-csv',
    fileExcel:                           'file-excel',
    fileWord:                            'file-word',
    fileImage:                           'file-image',
    fileText:                            'file-alt',
    filePowerpoint:                      'file-powerpoint',
    fileVideo:                           'file-video',
    fileDocument:                        'file',

    //Emails ------------------------------------------------
    email:                              'envelope',
    emailCampaigns:                     'envelopes-bulk',
    emailRuns:                          'paper-plane-top',
    emailOpen:                          'envelope-open',
    emailBounced:                       'times-circle',
    emailComplaint:                     'face-angry',
    mailingList:                        'address-book',
    contacts:                           'users',


    //Sections ----------------------------------------------
    subassemblies:                        'layer-group',
    
    //Dashboard ---------------------------------------------
    'outstanding-Parts':                 'wrench',
    'outstanding-Customers':             'users',
    'outstanding-Stock':                 'chart-line',  
    'outstanding-Sales':                 'handshake',
    'outstanding-Purchase Orders':       'file',
    'outstanding-Parts Ordering':        'file-powerpoint',
    'outstanding-Accounts':              'coins',
    'outstanding-Customer Returns':      'box',
    'outstanding-Marketing':             'bullseye-pointer',
    'outstanding-R & D':                 'lightbulb-on',
    'outstanding-Staff':                 'users',
    'outstanding-Works Order':           'hammer-crash',
    'outstanding-Supplier Stock Return': 'arrow-right-arrow-left',
    'outstanding-Costs':                 'credit-card',
    'outstanding-Prices':                'pound-sign',
    'outstanding-Sub Assemblies':        'layer-group',
    'outstanding-Online Meetings':       'webcam',
    'outstanding-Tasks':                 'tasks',
    'outstanding-Reminders':             'tasks',

    // Form Elements ----------------------------------------
    'Text Field':                       'text-size',
    'Number Input':                     'input-numeric',
    'Text Area':                        'input-text',
    'Dropdown':                         'caret-square-down',
    'Checkbox':                         'square',
    'Radio':                            'circle',
    'File Upload':                      'file-arrow-up',
    'Signature':                        'signature',
    'Date':                             'calendar-days',
    'Time':                             'clock',
    'Date / Time':                      'calendar-clock',
    'Job Time':                         'watch',
    'Parts Table':                      'wrench',
    'Static Table':                     'table',
    'Dynamic Table':                    'table',
    'Information':                      'square-info',
    'Information Selector':             'circle-info',
    'General Information':              'memo-circle-info'

}