import React from 'react';
import API   from 'API';
import _     from 'lodash';

import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import SnackBar           from 'Components/Common/SnackBars/SnackBar';

import PartCard from './PartCard';
import JigCard  from './JigCard';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons                      from 'Helpers/IconHelper';

import { downloadS3File } from 'Functions/MiscFunctions';

import { Button, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paperGrey: {        
        margin: 0,
        padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px `,
        backgroundColor:'#fefefe'
    }, 
    addPart: {
        display: 'flex',
        alignItems: 'center',
        justify: 'center',
        padding: theme.spacing(1),
    },
    partCardMargin: {        
        marginTop: theme.spacing(3)
    }
});

const initialState = {
    formData: {
        parts: [{
            part: '',
            quantity: ''
        }],
        jigs: [],
        subAssemblyPart: null,
        supplier: null
    },
    instructions: [],
    instructionsError: {
        parts:  {},
        show:   false,
    },
    formErrors: {},
    part: {},
    parts: [],
    partsList: [],
    partsToBecomeSubAssembly: [],
    partsToBecomeSubAssemblyList: [],
    suppliers: [],
    suppliersList: [],
    confirmationOpen: false,
    snackbarOpen: false,
    errorSnackbarOpen: false,
    isLoading: false
};

class AddSubAssembly extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount(){
        this.getPartsAvailableToBecomeSubAssembly();
        this.getSupplierList();
        this.getPartsWithCosts();
    }
    handleSubmit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            API.post('/subAssemblies', {...this.state.formData, instructions: this.state.instructions})
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...initialState,
                        snackbarOpen: true
                    });
                    this.getPartsAvailableToBecomeSubAssembly();
                    this.getSupplierList();
                    this.getPartsWithCosts();
                }
                this.props.scrollToTop();
            });
        });
    }
    getPartsAvailableToBecomeSubAssembly = () => {
        API.get(`/parts/availableToBecomeSubAssembly`)
        .then((result) => {
            let partsToBecomeSubAssemblyList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description,
                });
            });
            this.setState({
                partsToBecomeSubAssembly: result.data,
                partsToBecomeSubAssemblyList: partsToBecomeSubAssemblyList
            });
        });
    }
    getSupplierList = () => {
        API.get('/suppliers/all')
        .then((result) => {
            this.setState({
                suppliers: result.data
            });
            let suppliersList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.supp_id,
                    label: el.supp_company_name
                });
            });
            this.setState({
                suppliers: result.data,
                suppliersList: suppliersList
            });
        });
    }
    getPartsWithCosts = () => {
        API.get('/parts/withCosts')
        .then((result) => {
            let partsList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description,
                    part: el
                });
            });
            this.setState({
                parts: result.data,
                partsList: partsList
            });
        })
    }
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption ? selectedOption.value : null
            }
        }, ()=> {
            if (fieldName === 'subAssemblyPart' && selectedOption) {
                API.get(`/parts/${selectedOption.value}`)
                .then(res => {this.setState({part: res.data});})
            }
        });
    };
    handleChange = (event) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.value
            }
        });
    }
    handlePartsSelectChange = (fieldName, idx) => selectedOption => {
        let newParts =  [...this.state.formData.parts];

        let existingPart = _.find(newParts, {'part': selectedOption.value});
        if(existingPart){
            this.setState({
                errorSnackbarOpen: true
            });
        }
        else {
           newParts[idx] = {
                ...newParts[idx],
                [fieldName]: selectedOption && selectedOption.value
            };
            this.setState({
                formData: {
                    ...this.state.formData,
                    parts: newParts
                }
            }); 
        }
    };  
    handleJigsSelectChange = (fieldName, idx) => selectedOption => {
        let newJigs =  [...this.state.formData.jigs];

        let existingPart = _.find(newJigs, {'part': selectedOption.value});
        if(existingPart){
            this.setState({
                errorSnackbarOpen: true
            });
        }
        else {
           newJigs[idx] = {
                ...newJigs[idx],
                [fieldName]: selectedOption && selectedOption.value
            };
            this.setState({
                formData: {
                    ...this.state.formData,
                    jigs: newJigs
                }
            }); 
        }
    }; 
    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    }
    handleErrorSnackbarClose = () => {
        this.setState({
            errorSnackbarOpen: false
        });
    }
    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    }
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    }
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }  
    handleAddJig = () => {
        this.setState({
            formData:{
                ...this.state.formData,
                jigs: [...this.state.formData.jigs, { jig: ''}]
            }
        });
    }
    handleAddPart = () => {
        let item = initialState.formData.parts[0];
        this.setState({
            formData:{
                ...this.state.formData,
                parts: [...this.state.formData.parts, item]
            }
        }, this.checkInstructionsError);
    }
    handleRemovePart = (idx) => () => {
        const parts = [...this.state.formData.parts]
        parts.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                parts: parts
            }
        }, this.checkInstructionsError);
    }
    handleRemoveJig = (idx) => () => {
        const jigs = [...this.state.formData.jigs]
        jigs.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                jigs: jigs
            }
        }, this.checkInstructionsError);
    }
    handlePartChange = (idx, decimals) => e => {
        const { name, value } = e.target;
        let newParts =  [...this.state.formData.parts];
        let newVal = (value && decimals && !isNaN(value)) ? parseFloat(value).toFixed(decimals) : value;
        newParts[idx] = {
            ...newParts[idx],
            [name]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                parts: newParts
            }
        }, this.checkInstructionsError);
    }
    handleInstructionsChange = (stage, field) => (e) => {
        this.setState({
            instructions: this.state.instructions.map(i => {
                return i.stage === stage ? {...i, [field] : e.target.value} : i;
            })
        }, this.checkInstructionsError)
    }
    handleInstructionsPartsChange = (stage, partId, fixed = null) => (e) => {
        this.setState({
            instructions: this.state.instructions.map(i => {
                return i.stage === stage ? 
                    {...i, 
                        parts: {
                            ...i.parts, 
                            [partId]: fixed ? parseFloat(e.target.value).toFixed(3) : e.target.value
                        }
                    } : i;
            })
        }, this.checkInstructionsError)
    }
    handleInstructionsJigsChange = (stage, jigId) => (e) => {
        let jigs = _.find(this.state.instructions, {stage}).jigs;
        
        if (!_.find(jigs, i=>i === jigId)) { jigs.push(jigId); } else { jigs = _.filter(jigs, j => j !== jigId); }
        
        this.setState({
            instructions: this.state.instructions.map(i => { return i.stage === stage ?  {...i, jigs } : i; })
        }, this.checkInstructionsError)
    }
    checkInstructionsError = () => {
        let parts = _.filter(_.map(this.state.formData.parts, p => {
            return (parseFloat(p.quantity) != _.sumBy(this.state.instructions, i =>parseFloat(i.test ? 0 : (i.parts[p.part] || 0)))) ? p.part : null;
        }), i => i != null);
        this.setState({
            instructionsError: {
                parts,
                show: parts.length > 0 ? true : false
            }
        })
    }
    handleNumberOfStagesChange = (e) => {   
        let instructions = this.state.instructions;
        if (instructions.length < e.value) {
            //loop around instructions and add new ones
            for(var i = instructions.length; i < e.value; i++) {
                instructions.push({
                    stage:      i + 1,
                    name:       '',
                    test:       false,
                    testFile:   0,
                    parts:      {},
                    jigs:       []
                });
            }
        } else {
            instructions.splice(e.value - instructions.length);
        }
        this.setState({instructions},this.checkInstructionsError);
    }
    handleRemoveInstruction = stage => {
        this.setState({
            instructions: _.map(_.filter(this.state.instructions, i => i.stage !== stage), (i,idx) => {
                return {
                    ...i, 
                    name:   i.stage > stage ? '' : i.name, 
                    stage:  (idx+1),  
                }
            })
        }, this.checkInstructionsError);
    }
    render() {
        const { classes } = this.props;
        const { formData, formErrors, instructions, instructionsError, parts, part, partsList } = this.state;
        return (
            <Grid container spacing={3} style={{alignItems: 'flex-start'}}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Sub Assembly
                    </Typography>
                </Grid>
                <Grid item container style={{alignItems: 'flex-start'}} spacing={3} xs={12} lg={12}>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Typography variant="h6">
                                    Sub Assembly Details
                                </Typography>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.partsToBecomeSubAssemblyList} 
                                        label='Sub Assembly *'
                                        error={formErrors && formErrors['subAssemblyPart'] && true}
                                        errorText={formErrors && formErrors['subAssemblyPart']}
                                        value={formData.subAssemblyPart}
                                        onChange={this.handleSelectChange('subAssemblyPart')}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.suppliersList} 
                                        label='Supplier *'
                                        error={formErrors && formErrors['supplier'] && true}
                                        errorText={formErrors && formErrors['supplier']}
                                        value={formData.supplier}
                                        onChange={this.handleSelectChange('supplier')}
                                    />
                                </FormControl>
                            </form>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container style={{alignItems: 'center'}}>
                                <Grid item xs={7}>
                                    <Typography variant="h6">
                                        Sub Assembly Build Stages
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} style={{textAlign: 'right'}}>
                                    {part?.pdf_drawing && part?.pdf_drawing?.part_doc_url ?
                                        <Button variant='contained' color='primary' onClick={() => downloadS3File(part.pdf_drawing.url)}>
                                            <AllIcon icon={icons.download} color={colors.white} />Process Document
                                        </Button> : 'No Process Document'
                                    }
                                </Grid>
                            </Grid>
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteSelect 
                                    options=    {_.map(_.range(1,16), i => _.assign({value: i, label: i}))}
                                    label=      'Number Of Stages *'
                                    value=      {instructions.length}
                                    onChange=   {this.handleNumberOfStagesChange}
                                    noClear
                                />
                            </FormControl>
                            {instructions && 
                                <Grid container xs={12} spacing={3} style={{margin: '0.75em 0'}}>
                                    {_.map(instructions, i =>
                                        <Grid item xs={12} key={i.stage}>
                                            <Grid container style={{justifyContent: 'space-between', alignItems: 'flex-end'}}>
                                                <Grid item xs={2}>
                                                    <Typography variant="body1">
                                                        Stage {i.stage}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FormControl fullWidth margin="none">
                                                        <TextField
                                                            value       = {i.name}
                                                            onChange    = {this.handleInstructionsChange(i.stage, 'name')}
                                                            placeholder = 'Name *'
                                                            error       = {formErrors[i.stage]}
                                                            helperText  = {formErrors[i.stage]}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <AllIcon 
                                                        icon=    {icons.delete}
                                                        onClick= {()=>{this.handleRemoveInstruction(i.stage)}}
                                                        tooltip= "Delete"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            }
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Sub Assembly Parts
                            </Typography>
                            {formErrors && formErrors['parts'] && (
                                <FormControl fullWidth margin="normal">
                                    <Typography variant="body2" style={{color:colors.red}}>{formErrors['parts']}</Typography>
                                </FormControl>
                            )}
                            <Grid container item spacing={0}>
                                {this.state.formData.parts.map((item, idx) => (
                                    <Grid item xs={12} key={idx} className={classes.partCardMargin}>                            
                                        <PartCard 
                                            idx                             ={idx}
                                            data                            ={item}
                                            classes                         ={classes}
                                            options                         ={this.state.partsList}
                                            handleRemove                    ={this.handleRemovePart}
                                            handleChange                    ={this.handlePartChange}
                                            handleSelectChange              ={this.handlePartsSelectChange('part', idx)}
                                            formErrors                      ={formErrors}
                                            handleInstructionsPartsChange   ={this.handleInstructionsPartsChange}
                                            instructions                    ={instructions}
                                        />
                                    </Grid>
                                ))}
                                <Grid item xs={12} sm={6} md={3} lg={2}>
                                    <PaddedPaper className={`${classes.paperGrey} ${classes.addPart} ${classes.partCardMargin}`} onClick={this.handleAddPart}>
                                        <AllIcon icon={icons.plus} button noMargin color={colors.disabled} style={{width: '100%', height: '74px'}} />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Jigs
                            </Typography>
                            <Grid container item spacing={0}>
                                {_.map(formData.jigs, (item, idx) => 
                                    <Grid item xs={12} key={idx} className={classes.partCardMargin}>
                                        <JigCard 
                                            idx                           = {idx}
                                            data                          = {item}
                                            classes                       = {classes}
                                            options                       = {_.filter(this.state.partsList, i => i.part.part_type_id === 21 && ( !_.find(formData.jigs, {part: i.value})  || i.value === item.part))}
                                            handleRemove                  = {this.handleRemoveJig}
                                            handleChange                  = {this.handlePartChange}
                                            handleSelectChange            = {this.handleJigsSelectChange('part', idx)}
                                            formErrors                    = {formErrors}
                                            handleInstructionsPartsChange = {this.handleInstructionsJigsChange}
                                            instructions                  = {instructions}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={6} md={3} lg={2}>
                                    <PaddedPaper className={`${classes.paperGrey} ${classes.addPart} ${classes.partCardMargin}`} onClick={this.handleAddJig}>
                                        <AllIcon icon={icons.plus} button noMargin color={colors.disabled} style={{width: '100%', height: '74px'}} />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            {!!instructionsError.show &&
                                <Grid item xs={12} style={{textAlign: 'right', color: colors.red, marginBottom: '1em'}}>
                                    Build Stage parts do not match sub assembly parts
                                </Grid>
                            }
                            {_.sumBy(instructions, i => i.jigs.length > 0 ? 1 : 0) < formData.jigs.length &&
                                <Grid item xs={12} style={{textAlign: 'right', color: colors.red, marginBottom: '1em'}}>
                                    Jigs have to been added but not assigned to a stage
                                </Grid>
                            }
                            <div className="buttonRow">
                                <Button onClick={this.handleConfirmationOpen}
                                        variant="contained"
                                        color="primary"
                                        className={classes.partCardMargin}
                                        disabled={this.state.isLoading || instructions.length == 0 || !!instructionsError.show || _.sumBy(instructions, i => i.jigs.length > 0 ? 1 : 0) < formData.jigs.length}>
                                    Add
                                </Button>
                            </div>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Add A New Sub Assembly?" 
                    message="Are you sure you want to add this new sub assembly?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added a new sub assembly'
                />
                <SnackBar
                    variant="error"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.errorSnackbarOpen}
                    onClose={this.handleErrorSnackbarClose}
                    message='You have already selected this part'
                />
            </Grid>
        );
    }
}

export default withStyles(styles)(AddSubAssembly);
