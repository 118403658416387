import React, {Component}   from 'react';
import moment               from 'moment';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';


import {colors} from 'Helpers/ColourHelper';

import {Tab, Tabs, AppBar, Grid, Typography, Paper, Badge} from '@material-ui/core/';


import CompletedStockTake   from './CompletedStockTake';
import NewStockTakes        from './NewStockTakes';    
import RecheckStockTake     from './RecheckStockTake';
import ReviewStockTakes     from './ReviewStockTakes';
import InProgressStockTakes from './InProgressStockTakes';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';
import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = {
    currentTab: 0,
    tabData: {
        new:        0,
        inProgress: 0,
        review:     0,
        recheck:    0
    }
}


class StockTake extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `StockCorrections`;
        this.state              = this.getInitialState(initialState);
    }

    componentDidMount(){
        this.getTabData();
    }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        },this.savePageState);
    };
       
    getTabData(){
        let params = {status: 'open'};
        API.get(`/stock/take/all`,{params})
        .then((res) => {
            this.setState({
                tabData: {
                    new:        _.filter(res.data, j => j.st_status == 'Created' && j.st_checks == 1 && _.filter(j.details, i => i.std_taken_by !== null && i.std_taken_by !== null).length == 0).length,
                    inProgress: _.filter(res.data, j => j.st_status == 'Created' && j.st_checks == 1 && _.filter(j.details, i => i.std_taken_by !== null && i.std_taken_by !== null).length > 0).length,
                    review:     _.filter(res.data, i => i.st_status == 'Approval').length,
                    recheck:    _.filter(res.data, i => i.st_status == 'Created' && i.st_checks > 1).length,
                }
            })
        })
    }

    render() {
        const {access, currentTab, tabData} = this.state;
        const tabs = [
            { label: tabData.new        == 0 ? 'New' :         <Badge badgeContent={tabData.new}        color="primary">New</Badge>,          ref:'n'},
            { label: tabData.inProgress == 0 ? 'In Progress' : <Badge badgeContent={tabData.inProgress} color="primary">In Progress</Badge>,  ref:'p'},
            { label: tabData.recheck    == 0 ? 'Recheck' :     <Badge badgeContent={tabData.recheck}    color="primary">Recheck</Badge>,      ref:'rc'},
            { label: tabData.review     == 0 ? 'Review' :      <Badge badgeContent={tabData.review}     color="primary">Review</Badge>,       ref:'r'},
            { label: 'Completed',                                                                                                        ref:'c'},
        ]

        return (
            <Grid container >
                <Grid item xs={12} style={{marginBottom:'1em'}}><Typography variant="h5">Stock Take</Typography></Grid>
                <Grid item xs={12}>
                    <AppBar position="static" style={{backgroundColor:'white', color:"#000", minWidth: 1522, width: '100%', zIndex:'auto'}} >
                        <Tabs   value={currentTab} 
                                onChange={this.handleTabChange} 
                                className='xs-max-width' 
                                indicatorColor="primary" 
                                textColor="primary" 
                                variant='scrollable'
                                scrollButtons="auto"
                        >
                            {_.map(tabs, (i, idx) => <Tab label={i.label} value={idx} />)}
                        </Tabs>
                    </AppBar>
                </Grid>
                {currentTab == _.findIndex(tabs, i => i.ref == 'n') && 
                    <Grid item xs={12}>
                        <Paper style={{width:'100%'}}>
                            <Grid container xs={12} style={{padding:'1em'}} >
                                <Grid item xs={12}>
                                   <NewStockTakes/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                }
                {currentTab == _.findIndex(tabs, i => i.ref == 'p') && 
                    <Grid item xs={12}>
                        <Paper style={{width:'100%'}}>
                            <Grid container xs={12} style={{padding:'1em'}} >
                                <Grid item xs={12}>
                                   <InProgressStockTakes/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                }
                {currentTab == _.findIndex(tabs, i => i.ref == 'rc') && 
                    <Grid item xs={12}>
                        <Paper style={{width:'100%'}}>
                            <Grid container xs={12} style={{padding:'1em'}} >
                                <Grid item xs={12}>
                                   <RecheckStockTake/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                }
                 {currentTab == _.findIndex(tabs, i => i.ref == 'r') && 
                    <Grid item xs={12}>
                        <Paper style={{width:'100%'}}>
                            <Grid container xs={12} style={{padding:'1em'}} >
                                <Grid item xs={12}>
                                   <ReviewStockTakes/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                }
                {currentTab == _.findIndex(tabs, i => i.ref == 'c') && 
                    <Grid item xs={12}>
                        <Paper style={{width:'100%'}}>
                            <Grid container xs={12} style={{padding:'1em'}} >
                                <Grid item xs={12}>
                                   <CompletedStockTake/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                }
            </Grid>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    clearPersistence: (key)        => dispatch(clearPersistence(key)),
    setPersistence:   (key, state) => dispatch(setPersistence(key, state))
})

export default connect(null, mapDispatchToProps)(StockTake);
