import React, { useState, useEffect } from 'react';
import { 
    Grid, RadioGroup, FormControlLabel, Radio, 
    Card, CardContent, 
    Box, Typography, Link, IconButton,  Tooltip,
    DialogActions, Button
} from '@material-ui/core';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import API from 'API';
import { assign, map, find } from 'lodash';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import LoadingCircle   from 'Components/Common/LoadingCircle/LoadingCircle';
import { useDispatch } from 'react-redux';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

const AttachmentList = ({ attachmentList, removeFromInformationList, readOnly }) => {

    // Re-factor the attachmentList array to be grouped by type, with type as the key of the array
    const infoList = {};

    attachmentList.forEach((info) => {
        let key = '';
        switch(info.type){
            case 'Document':
                key = 'Documents';
            break;
            case 'Part Drawing':
                key = 'Part Drawings';
            break;
            default:
                key = 'Images';
            break;
        }
        if (!infoList[key]) {
            infoList[key] = [];
        }
        infoList[key].push(info);
    });

    return (
        <>
            {map(infoList, (infoArray, type) => {
                return (
                    <Grid item xs={12} key={type}>
                        <Typography variant="caption" paragraph>
                            <b>{type}</b>
                        </Typography>
                        <Grid container item spacing={1}>
                            {map(infoArray, (info, idx) => (
                                <>
                                    {(info?.type == 'Document' || info?.type == 'Part Drawing') && (
                                        <Grid item xs={11} key={idx}>
                                        <div 
                                            style={{
                                                display: 'flex',
                                                flexDirection:'row',
                                                alignItems: 'center'
                                            }}>
                                            <Link href={info?.fileUrl} target='_blank' variant="body1" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer'}} >
                                                {info?.title}
                                            </Link>
                                            {!readOnly && (
                                                <Tooltip title="Remove">
                                                    <div>{/* Need tag for tooltip */}
                                                        <IconButton onClick={() => removeFromInformationList(info?.type, info?.id)}>
                                                            <FALightIcon icon='times' noMargin fixedHeight button size={15} color="red" />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            )}
                                            </div>
                                        </Grid>
                                    )}
                                    {(info?.type == 'Part Image' || info?.type == 'Category Image') && (
                                        <Grid item xs={4} key={idx}>
                                            <Card>
                                                <CardContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 150}}>
                                                    <ImageWithError src={info?.fileUrl} style={{height:'auto', maxHeight: 120, maxWidth: 120}} />
                                                </CardContent>
                                                {!readOnly && (
                                                    <CardContent>
                                                        <div 
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:'row',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }} >
                                                            <Tooltip title="Remove">
                                                                <div>{/* Need tag for tooltip */}
                                                                    <IconButton onClick={() => removeFromInformationList(info?.type, info?.id)}>
                                                                        <FALightIcon icon='times' noMargin fixedHeight button size={15} color="red" />
                                                                    </IconButton>
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    </CardContent>
                                                )}
                                            </Card>
                                        </Grid>
                                    )}
                                </>
                            ))}
                        </Grid>
                    </Grid>
                )
            })}
        </>
    )
}

const FormAttachmentsDialog = ({ attachments, toggleDialog, orderId, customerId, readOnly, formId, callBack}) => {

    const [informationType, setInformationType] = useState('Part Image');
    const [partList, setPartList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [documentsList, setDocumentsList] = useState([]);
    const [pdfPartDrawingList, setPdfPartDrawingList] = useState([]);
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [images, setImages] = useState([]);
    const [attachmentList, setAttachmentList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

	useEffect(() => {
        setIsLoading(true);
        Promise.all([
            API.get('/parts/withImages'),
            API.get('/library/categories/withImages'),
            API.get('/documents/all', { params: { forSelect: true } }),
            API.get('/parts/withDrawings'),
        ])
        .then(([partRes, catRes, docRes, drawingRes]) => {
            let partList = map(partRes.data, (el) => {
                return assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description
                });
            });
            let categoryList = map(catRes.data, (el) => {
                return assign({
                    value: el.library_cat_id,
                    label: el.library_cat_name
                });
            });
            let documentsList = [];
            map(docRes.data, (el) => {
                if(el.filePdfUrl){
                    documentsList.push({
                        value:      el.id,
                        label:      el.subCategory + ' | ' + el.title,
                        id:         el.id,
                        fileUrl:    el.filePdfUrl,
                        fileName:   el.filePdfName,
                        title:      el.title,
                    });
                }
            });
            let pdfPartDrawingList = map(drawingRes.data, (el) => {
                return assign({
                    value: el.id,
                    label: el.number + ' - ' + el.desc,
                    title: el.number + ' - ' + el.desc,
                    fileUrl: el.pdfUrl,
                });
            });


            setPartList(partList);
            setCategoryList(categoryList);
            setDocumentsList(documentsList);
            setPdfPartDrawingList(pdfPartDrawingList);
            setSelectOptions(partList);

            if(attachments){
                setAttachmentList(attachments);
            }
            setIsLoading(false);
        })
	}, []);

    useEffect(() => {
        switch(informationType){
            case 'Part Image':
                setSelectOptions(partList);
            break;
            case 'Category Image':
                setSelectOptions(categoryList);
            break;
            case 'Document':
                setSelectOptions(documentsList);
            break;
            case 'Part Drawing':
                setSelectOptions(pdfPartDrawingList);
            break;
        }
        setSelectedOption(null);
        setImages([]);
        
    }, [informationType]);

    useEffect(() => {

        if(selectedOption){

            let list = [...attachmentList];

            switch(informationType){
    
                case 'Part Image':
                case 'Category Image':

                    const route = informationType === 'Part Image' ? `/library/parts/${selectedOption}/imageFiles` : `/library/categories/${selectedOption}/imageFiles`;
    
                    let images = [];
                    API.get(route)
                    .then((result) => {
                        result.data.forEach(val => {
    
                            images.push({
                                id:         val.library_id,
                                fileUrl:    val.library_file_url,
                                type:       informationType,
                            });
                        });
    
                        setImages(images);
                    })
                break;
                case 'Document':
                    let document    = find(documentsList, { value: selectedOption });
                    list.push({
                        id:         document.id,
                        title:      document.title,
                        fileUrl:    document.fileUrl,
                        fileName:   document.fileName,
                        type:       informationType,
                    });
                    setAttachmentList(list);
                break;
                case 'Part Drawing':
                    
                    let drawing     = find(pdfPartDrawingList, { value: selectedOption });
                    list.push({
                        id:         drawing.value,
                        title:      drawing.label,
                        fileUrl:    drawing.fileUrl,
                        type:       informationType,
                    });
                    setAttachmentList(list);
                break;
            }
        }
        else {
            setImages([]);
        }
    }, [selectedOption]);

    const addToInformationList = (object) => {
        let list = [...attachmentList];
        list.push(object);
        setAttachmentList(list);
    }

    const removeFromInformationList = (type, id) => {
        
        let infoList = [...attachmentList];

        let idx = infoList.findIndex((el) => {
            return el.id === id && el.type === type;
        });

        infoList.splice(idx, 1);
        setAttachmentList(infoList);
    }

    const submit = () => {

        API.post(`/forms/${formId}/attachments`, { attachments: attachmentList, type: 'Attachments' })
        .then(result => {

            if(result.data?.success){

                toggleDialog('informationForm', true);

                dispatch(deploySnackBar('success', 'Attachments updated successfully'));

                if(callBack){
                    callBack();
                }
            }
            
        });
        
    }

    return (
        <>
        <Grid container item spacing={3}>
            {(isLoading && (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            )) || (
                <>
                {!readOnly && (
                    <Grid item xs={6}>
                        <Grid container item spacing={3}>
                            <Grid item xs={12}>
                                <RadioGroup
                                    name="informationType"
                                    value={informationType}
                                    onChange={(e) => setInformationType(e.target.value)}
                                    row
                                >
                                    <FormControlLabel
                                        value="Part Image"
                                        control={<Radio color="primary" />}
                                        label="Part Image"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="Category Image"
                                        control={<Radio color="primary" />}
                                        label="Category Images"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="Document"
                                        control={<Radio color="primary" />}
                                        label="Document"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="Part Drawing"
                                        control={<Radio color="primary" />}
                                        label="Part Drawing"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12}>
                                {selectOptions?.length > 0 && (
                                    <AutoCompleteSelect 
                                        options={selectOptions} 
                                        label={`Select ${informationType}`}
                                        fullWidth
                                        value={selectedOption}
                                        onChange={(e) => setSelectedOption(e?.value)}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {images.length > 0 && (
                                    <Box style={{border: '1px solid #fefefe', overflowY: 'scroll', height: '500px', padding: 6}}>
                                        <Grid container item spacing={1}>
                                            {images.map((img, idx) => (
                                                <Grid item xs={3} key={idx}>
                                                    <Card onClick={() => addToInformationList(img)}>
                                                        <CardContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 150}}>
                                                            <ImageWithError src={img?.fileUrl} style={{height:'auto', maxHeight: 120, maxWidth: 120}} />
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            ))} 
                                        </Grid>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={readOnly ? 12 : 6}>
                    {readOnly ? 
                        <Grid container item spacing={1}>
                            <AttachmentList attachmentList={attachmentList} removeFromInformationList={removeFromInformationList} readOnly={readOnly} />
                        </Grid>
                    :
                        <Box style={{backgroundColor: '#f2f2f2', padding: 15}}>
                            <Typography variant="caption" paragraph>
                                <b>Attachment List</b>
                            </Typography>
                            <PaddedPaper>
                                <Grid container item spacing={1}>

                                    <AttachmentList attachmentList={attachmentList} removeFromInformationList={removeFromInformationList} readOnly={readOnly} />
                                    
                                </Grid>
                            </PaddedPaper>
                        </Box>
                    }
                </Grid>
                </>
            )}
        </Grid>
        <DialogActions className='pr-0 pb-0'>
            <Button 
                onClick={() => toggleDialog('informationForm', true)}
                variant="text"
            >
                <FALightIcon icon="times" size={15} button />
                Close
            </Button>
            {!readOnly && (
                <Button 
                    color="primary"
                    onClick={() => submit()}
                    variant="contained"
                    disabled={isLoading}
                >
                    <FALightIcon icon="check" size={15} color="white" />
                    Update
                </Button>
            )}
        </DialogActions>
        </> 
    );
};

export default FormAttachmentsDialog;
