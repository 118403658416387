import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import API from '../../../API';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import DataTable from '../../Common/DataTables/DataTable';
import Textarea from '../../Common/Inputs/Textarea';
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData: {
        name: '',
        text: ''
    },
    templates: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    access: {
        addTemplate: false,
        updateTemplate: false
    },
    isLoading: true
}

class PurchaseOrderEmailTemplates extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.checkAccess();
        this.getTemplates();
    }

    getTemplates = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/purchaseOrders/emailTemplates')
            .then(result => {
                if(result.data) {
                    this.setState({
                        ...this.state,
                        templates: result.data,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            });
        })
    };

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/purchase-order-email-template:add'), 
            API.get('/staff/my/access/check/update-purchase-order-email-template'),
        ])
        .then(([addRes, updateRes]) =>  {
            addRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addTemplate: addRes.data.has_access
                    }
                });
            updateRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        updateTemplate: updateRes.data.has_access
                    }
                });
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        API.post('/purchaseOrders/emailTemplates', this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.checkAccess();
                this.getTemplates();
            }
            this.props.scrollToTop();
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }
       
    render() {
        const { formErrors, access } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Purchase Order Email Template
                    </Typography>
                </Grid>
                {access.addTemplate &&
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <form noValidate autoComplete="off">
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Name *"
                                    value={this.state.formData.name}
                                    error={formErrors && formErrors['name'] && true}
                                    helperText={formErrors && formErrors['name']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <Textarea
                                    id="text"
                                    name="text"
                                    label="Text *"
                                    value={this.state.formData.text}
                                    rows={10}
                                    error={formErrors && formErrors['text']}
                                    onChange={this.handleChange}
                                />
                                <div className='buttonRow'>
                                    <Button onClick={this.handleConfirmationOpen}
                                            variant="contained"
                                            color="primary">
                                        Add
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                        </Grid>
                    </Grid>
                }    
                <Grid item xs={12}>    
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key: 'email_id',
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: this.state.isLoading
                            }}
                            columns={[
                                {
                                    heading: 'Name',
                                    field: rowData => rowData.email_name
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil-alt', link: '/purchase-order/email-template/update/' + rowData.email_id, disabled: !access.updateTemplate},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.templates}
                        />
                    </PaddedPaper>
                </Grid>   
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Add A New Email Template?" 
                    message="Are you sure you want to add this new email template?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added a new email template'
                />
            </Grid>
        );
    }
}

export default PurchaseOrderEmailTemplates;