import { Grid, Typography } from "@material-ui/core";
import TabBarSwitcher from "Components/Common/Navigation/TabBarSwitcher";
import React from "react";

import ReceivedConditions from "./ReceivedConditions";
import RepairWriteOffConditions from "./RepairWriteOffConditions";

class CustomerReturnDefaults extends React.Component {

    render () {
        return (
            <Grid container spacing={1} >
                <Grid item xs={12}>
                    <Typography variant='h6'>
                        Customer Return Defaults
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TabBarSwitcher
                        tabs={[
                            {
                                label:   'Received Conditions',
                                content: <ReceivedConditions />,
                            },
                            {
                                label:   'Write Off Reasons',
                                content: <RepairWriteOffConditions />,
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        )
    }

}

export default CustomerReturnDefaults;