import API from 'API';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closeDialog } from 'Actions/Dialog/Dialog';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { Button, FormControl, Grid } from '@material-ui/core/';

class LinkInvoicesDialog extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            invoices:   this.props.invoices,
            addresses:  _.map(this.props.addresses, i => {
                return _.assign({label: i.a, value: i.i})
            }), 
            links: {}
        };
    }

    handleLink = i => e => {
        this.setState({
            links: {
                ...this.state.links,
                [i]: e ? e.value : null
            }
        })
    }

    submit = () => {
        API.post(`/customers/${this.props.customer}/accounts/addresses/linkAddressToAccounts`, {links:this.state.links, creditNote: this.props.creditNote || 0})
        .then(res => {this.close();})
    }

    close = () => {
        if (this.props.callback) this.props.callback();
        this.props.closeDialog();
    }

    render() {
        const {invoices, addresses, links} = this.state;
        return (
            <>
                <DataTable
                    config={{
                        key:            'invoice_id',
                        pagination:     false,
                        plainHeader:    false,
                    }}
                    columns={[
                        {
                            heading: 'Reference',
                            field: rowData => 
                                <>
                                    {`${ this.props.creditNote ? rowData.cn_reference : rowData.invoice_reference}-${this.props.creditNote ? rowData.cn_reference_amendment :rowData.invoice_reference_unique_number}`}<br></br>
                                    { this.props.creditNote ? rowData.invoice.cni_invoice_number : rowData.invoice_number} (Sage Number: {this.props.creditNote ? rowData.invoice.cni_sage_invoice_number : rowData.invoice_sage_invoice_number})
                                </>,
                            sizeToContent: true
                        },
                        {
                            heading: 'Address',
                            field: rowData => this.props.creditNote ? rowData.cn_cust_name : rowData.invoice_cust_name,
                            sizeToContent: true
                        },
                        {
                            heading: 'Link To',
                            field: rowData =>  
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteSelect 
                                                    fullWidth
                                                    options={addresses}
                                                    name={`address${this.props.creditNote ? rowData.cn_id : rowData.invoice_id}`}
                                                    label=''
                                                    value={links[this.props.creditNote ? rowData.cn_id : rowData.invoice_id]}
                                                    onChange={this.handleLink(this.props.creditNote ? rowData.cn_id : rowData.invoice_id)}
                                                />
                                            </FormControl>,
                            
                        },
                    ]}
                    rows={invoices}
                />
                <Grid container xs={12} spacing={3} style={{justifyContent: 'flex-end', marginTop: '1.5em'}}>
                    <Grid item>
                        <Button onClick={this.close} variant="outlined" >Close</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained"  color='primary' onClick={this.submit} >Save</Button>
                    </Grid>
                </Grid>
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({closeDialog: () => dispatch(closeDialog())})
export default connect(null, mapDispatchToProps)(LinkInvoicesDialog);