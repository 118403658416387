import React, { Component, useRef } from 'react';
import API                          from 'API';
import _                            from 'lodash';
import { connect }                  from 'react-redux';
import { CSVLink }                  from "react-csv";

import DataTable    from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper  from 'Components/Common/Paper/PaddedPaper';
import StickyNotes  from 'Components/Common/StickyNotes/StickyNotes';
import Textarea     from 'Components/Common/Inputs/Textarea';

import SearchBar from './SearchBar';

import icons         from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';
import { colors }    from 'Helpers/ColourHelper';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar }            from 'Actions/SnackBar/SnackBar';

import { Grid, Link, Typography } from '@material-ui/core/';
import { DialogTitle, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';

import AllIcon from 'Components/Common/Icons/AllIcon';
import Versions from 'Components/Marketing/EmailCampaigns/ViewEmailCampaign/Tabs/Versions';

const initialState = {
    isLoading: true,
    access: {},
    worksOrders: [],
    formData: {
        keywords: '',
        dateFrom: null,
        dateTo:   null,
        part:     null,
    },
    csvDownload: {
        data: [],
        filename: '',
    },
    woCancel: {}
}

const InfoBlock = ({xs, title, icon, value}) => {
    return (
        <Grid item xs={xs}>
            <PaddedPaper  style={{textAlign: 'center', height: '100%'}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}><Typography variant="h6" style={{textAlign: 'center'}}>{title}</Typography></Grid>
                    <Grid item xs={12}><AllIcon icon={icon} noMargin size='large'/></Grid>
                    <Grid item xs={12}><Typography variant="h6"  style={{textAlign: 'center'}}>{value}</Typography></Grid>
                </Grid>
            </PaddedPaper>
        </Grid>
    )
} 
class WorksOrderPicking extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount(){
        this.getAccess();
        this.getSearchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.worksOrderType !== this.props.worksOrderType) this.getSearchData();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/works-order:view'), 
            API.get('/staff/my/access/check/works-order:pick')
        ])
        .then(([viewRes, pickRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    view: viewRes.data.has_access || false,
                    pick: pickRes.data.has_access || false,
                }
            });
        })
    }

    getSearchData = () => {
        this.setState({isLoading: true}, ()=> {
            API.get('/worksOrders/all',{params: {...this.state.formData, type: this.props.worksOrderType}})
            .then(res => {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    worksOrders: res.data
                });
            })
        })
    }

    handleSearch = formData => {
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                ...formData
            }
        }, () => {
            this.getSearchData();
        })
    }

    handleCancelWorksOrder = wo => {
        API.post(`/worksOrders/${wo.wo_id}/cancel`, {notes: wo.cancel_notes}).then(() => {this.setState({woCancel: initialState.woCancel}, this.getSearchData)})
    }

    handleShowStickyNote = woId => { this.props.deployDialog(<StickyNotes woId={woId} dialog/>, 'Sticky Notes', null,'xs', {backgroundColor: colors.stickyNotes}) }

    downloadMasterExcel = row => {
        API.get(`/worksOrders/${row.wo_id}/masterExcel`)
        .then(res => {
            this.setState({
                csvDownload: {
                    data: res.data,
                    filename: `MasterExcelParts_${row.wo_ref}.csv`,
                }
            }, ()=>{
                setTimeout(()=>{
                    document.getElementById('downloadMasterExcel').click();
                }, 100);
            })
        })
        
    }

    render(){

        const { isLoading, worksOrders, access, csvDownload, woCancel } = this.state;

        return (
            <Grid container xs={12} spacing={3}>
                {!!csvDownload.filename && !!csvDownload.data &&
                    <CSVLink 
                        data={csvDownload.data} 
                        label={''} 
                        filename={csvDownload.filename}
                        className='hidden'
                        target='_blank'
                        id='downloadMasterExcel'
                    />
                }
                <Grid item xs={12}>
                    <Typography variant="h5" style={{textTransform: 'capitalize'}}>
                        {this.props.worksOrderType || 'All'} Works Orders
                    </Typography>
                </Grid>
                {( !this.props.worksOrderType || ( this.props.worksOrderType === 'active' ) ) &&
                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={3}>
                            <InfoBlock xs={!this.props.worksOrderType ? 2 : 3} title='Picking' icon={icons.qr} value={_.filter(worksOrders, i => i.wo_status === 'Picking').length}/>
                            <InfoBlock xs={!this.props.worksOrderType ? 2 : 3} title='Shortage' icon={icons.qr} value={_.filter(worksOrders, rowData => (rowData.wo_status == 'Build' && ( rowData.shortage && !rowData.shortage?.woss_complete) ? 'Shortage' : rowData.wo_status) === 'Shortage' ).length}/>
                            <InfoBlock xs={!this.props.worksOrderType ? 2 : 3} title='Build' icon={icons.worksOrder} value={_.filter(worksOrders, rowData => (rowData.wo_status == 'Build' && ( rowData.shortage && !rowData.shortage?.woss_complete) ? 'Shortage' : rowData.wo_status) === 'Build' ).length}/>
                            <InfoBlock xs={!this.props.worksOrderType ? 2 : 3} title='Review' icon={icons.review} value={_.filter(worksOrders, i=>i.wo_status === 'Review').length}/>
                            {!this.props.worksOrderType && [
                                <InfoBlock xs={2} title='Cancelled' icon={icons.false} value={_.filter(worksOrders, i=>i.wo_status === 'Cancelled').length}/>,
                                <InfoBlock xs={2} title='Completed' icon={icons.true} value={_.filter(worksOrders, i=>i.wo_status === 'Completed').length}/>
                            ]}
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12} lg={12}>
                    <SearchBar 
                        callBack={this.handleSearch}
                        parts={_.map(_.uniqBy(worksOrders, 'wo_part_id'), i=>_.assign({
                            value: i.wo_part_id,
                            label: `${i?.part?.part_number} - ${i?.part?.part_description}`,
                            part:  i.part
                        }))}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <PaddedPaper>
                        <DataTable
                            config={{
                                key:                    'so_id',
                                alternatingRowColours:  true,
                                responsiveimportant:    true,
                                pagination:             true,
                                isLoading:              isLoading
                            }}
                            columns={[
                                {
                                    heading:        'Date',
                                    field:          rowData => clenyDate(rowData.wo_date),
                                    dataRef:        'wo_date',
                                    sizeToContent:  true,
                                },
                                {
                                    heading:        'Reference',
                                    field:          rowData => <Link href={`/WorksOrders/view/${rowData.wo_id}`} disabled={access.view}>{rowData.wo_ref}</Link>,
                                    dataRef:        'wo_ref',
                                    sizeToContent:  true,
                                },
                                {
                                    heading:        'Assembly',
                                    field:          rowData => <>{rowData?.part?.part_number} - {rowData?.part?.part_description}</>,
                                    dataRef:        rowData => rowData?.part?.part_number,
                                    truncate:       true,
                                },
                                {
                                    heading:        '',
                                    field:          i => !!((i.wo_status !== 'Completed' && i.wo_status !== 'Cancelled') && ( i.wo_version && (i.wo_version != i.part.part_issue_status_design) )) &&
                                                        <AllIcon icon={icons.versionMissMatch} color={colors.red} tooltip='Version Miss-match' noMargin />,
                                    sizeToContent:  true,
                                    alignment:      'center',
                                },
                                {
                                    heading:        'created Version',
                                    field:          i => i.wo_version ? `v${i.wo_version}` : '-',
                                    dataRef:        'wo_version',
                                    sizeToContent:  true,
                                    alignment:      'center',
                                },
                                {
                                    heading:        this.props.worksOrderType === 'completed' ? 'Completed Version' : 'current Version',
                                    field:          i => i.wo_version_completed ?  i.wo_version_completed : i.part.part_issue_status_design,
                                    dataRef:        i => i.wo_version_completed ?  i.wo_version_completed : i.part.part_issue_status_design,
                                    fieldPrefix:    'v',
                                    sizeToContent:  true,
                                    alignment:      'center',
                                },
                                {
                                    heading:        this.props.worksOrderType === 'completed' ? 'Built' : 'Required',
                                    field:          rowData => rowData.wo_qty,
                                    sizeToContent:  true
                                },
                                {
                                    heading:        'Stage',
                                    field:          rowData => rowData.wo_status == 'Build' && ( rowData.shortage && !rowData.shortage?.woss_complete) ? 'Shortage' : rowData.wo_status,
                                    dataRef:        rowData => rowData.wo_status == 'Build' && ( rowData.shortage && !rowData.shortage?.woss_complete) ? 'Shortage' : rowData.wo_status,
                                    sizeToContent:  true,
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Sticky Notes', icon: icons.stickyNote, onClick: () => this.handleShowStickyNote(rowData.wo_id), hide:rowData.sticky_notes.length === 0, color: _.filter(rowData.sticky_notes, i =>!i.sn_completed).length > 0 && colors.yellow },
                                            rowData.wo_status === 'Completed' && {
                                                name:     'Master Excel',
                                                icon:     icons.excel,
                                                onClick:  this.downloadMasterExcel
                                            },
                                            rowData.wo_status !== 'Completed' && {
                                                name:     'Cancel',
                                                icon:     icons.false,
                                                disabled: (  rowData.wo_status == 'Picking' && ( !access.pick || rowData.pick?.active_pick ) ) || !( rowData.wo_status == 'Picking' || rowData.wo_status == 'Build' ),
                                                onClick:  () => this.setState({woCancel: rowData})
                                                //onClick:  () => {this.props.deployConfirmation('Are you sure you want to cancel this works orders?', 'Cancel Works Order?', ()=>{this.handleCancelWorksOrder(rowData.wo_id);})}
                                            },
                                            {
                                                name: rowData.wo_status == 'Build' && ( rowData.shortage && !rowData.shortage?.woss_complete) ? 'Shortage' : rowData.wo_status,
                                                icon: (
                                                    (   rowData.wo_status == 'Picking'   && icons.qr )                                                               ||
                                                    ( ( rowData.wo_status == 'Build'     && ( rowData.shortage && !rowData.shortage?.woss_complete) ) && icons.qr )  ||
                                                    (   rowData.wo_status == 'Build'     && icons.worksOrder )                                                       ||
                                                    (   rowData.wo_status == 'Cancelled' && icons.view )                                                             ||
                                                    (   rowData.wo_status == 'Review'    && icons.review )                                                           ||
                                                    (   rowData.wo_status == 'Completed' && icons.search )                                                           ||
                                                    icons.unknown
                                                ),
                                                link: (
                                                    ( rowData.wo_status    == 'Picking' && `/WorksOrders/pick/${rowData.wo_id}` )                                                                   ||
                                                    (( ( rowData.wo_status == 'Build'   && ( rowData.shortage && !rowData.shortage?.woss_complete) ) && `/WorksOrders/shortages/${rowData.wo_id}` ) ||
                                                    `/WorksOrders/view/${rowData.wo_id}`)
                                                ),
                                                disabled: (
                                                    (  rowData.wo_status == 'Picking' && ( !access.pick || rowData.pick?.active_pick ) ) || 
                                                    ( ( ( rowData.wo_status == 'Build' && !rowData.shortage?.woss_complete ) && ( !access.pick /*|| rowData.shortage?.active_pick*/ ) ) ||
                                                        false)
                                                )
                                            }
                                        ]
                                    },
                                    alignment: 'left',
                                }
                            ]}
                            rows={_.orderBy(_.filter(worksOrders, i => i?.part), ['wo_date'],['desc'])}
                        />
                    </PaddedPaper>
                </Grid>
                {woCancel?.wo_id &&
                    <Dialog
                        open={woCancel}
                        onClose={()=>this.setState({woCancel: initialState.woCancel})}
                        maxWidth="md" 
                        scroll="body"
                        fullWidth
                    >
                        <DialogTitle style={{background: colors.red, color: colors.white}}>
                            Cancel Works Order?
                        </DialogTitle>
                        <DialogContent>
                            <Typography>Reference: {woCancel?.wo_ref}</Typography>
                            <Textarea
                                label='Cancel Reason *'
                                value={woCancel?.cancel_notes}
                                onChange={e => this.setState({woCancel: {...woCancel, cancel_notes: e.target.value}})}
                                rows={4}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>this.setState({woCancel: initialState.woCancel})} variant="outlined" autoFocus>
                                Close
                            </Button>
                            <Button onClick={()=>this.handleCancelWorksOrder(woCancel)} color="secondary" variant='contained' autoFocus disabled={!woCancel?.cancel_notes}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                                            => dispatch(closeDialog()),
    deployDialog:       (content, title, variant, size, style = null) => dispatch(deployDialog(content, title, variant, size, style)),
    deployConfirmation: (message, title, success)                     => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)                                => dispatch(deploySnackBar(variant, msg)),
})

export default connect(null, mapDispatchToProps)(WorksOrderPicking);


