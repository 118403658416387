import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DatePicker from '../../Common/DatePickers/DatePicker';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import moment from 'moment';
import API from '../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import { MAX_UPLOAD_LIMIT_SIZE } from './../../../Constants';

import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData: {
        category: '',
        subCategory: '',
        title: '',
        fileUploads: 'both',
        mov:  '',
        mp4:  '',
        dateAdded: moment().toString(),
    },
    showMov: true,
    showMp4: true,
    categoryList: [],
    subCategoryList: [],
    categories: {},
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: false
}

class AddVideo extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getCategories();
    }

    getCategories = () => {
        API.get('/videos/categories/all').then(result => {
            if(result.data) {
                let categoryList = _.map(result.data, (el) => {
                    return _.assign({
                        value: el.video_cat_id,
                        label: el.video_cat_name
                    });
                });
                this.setState({
                    categoryList: categoryList,
                    categories: result.data
                });
            }
        });
    };

    getSubCategories = (categoryID) => {
        if(categoryID) {
            let catList = _.find(this.state.categories, { 'video_cat_id': categoryID });
            let subCategoryList = _.map(catList.children, (el) => {
                return _.assign({
                    value: el.video_cat_id,
                    label: el.video_cat_name
                });
            });
            this.setState({
                subCategoryList: subCategoryList
            });
        } else {
            this.setState({
                subCategoryList: initialState.subCategoryList
            });
            this.setState({
                formData: {
                  ...this.state.formData,
                  subCategory: null
                }
            });
        }
    };
    
    handleDateChange = date => {
        this.setState({
            formData: {
                ...this.state.formData,
                dateAdded: date
            }
        });
    };

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            let newFormData = new FormData();
            Object.keys(this.state.formData).forEach(key => {
                if(key === 'dateAdded'){
                    newFormData.append(key, moment(new Date(this.state.formData[key])).format('DD/MM/YYYY'))
                }
                else{
                    newFormData.append(key, this.state.formData[key])
                }
                
            });
            API.post('/videos', newFormData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...initialState,
                        snackbarOpen: true
                    });
                    this.getCategories();
                }
                this.props.scrollToTop();
            });
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleSelectChange = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: res && res.value
            }
        }, 
        () => {
            if(name === 'category'){
                this.setState({
                    formData: {
                      ...this.state.formData,
                      subCategory: null
                    }
                },
                () => {
                    this.getSubCategories(res && res.value);
                });
            } else if(name === 'fileUploads'){
                switch(this.state.formData.fileUploads) {
                    case 'both':
                        this.setState({showMov: true, showMp4: true});
                    break;
                    case 'mov_only':
                        this.setState({showMov: true, showMp4: false});
                    break;
                    case 'mp4_only':
                        this.setState({showMov: false, showMp4: true});
                    break;
                    default:
                    break;
                }
            }
        });
    };

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    clearMov = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                mp4: initialState.formData.mp4
            }
        });
    };

    clearMp4 = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                mov: initialState.formData.mov
            }
        });
    };
       
    render() {
        const { formErrors } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Video
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Video Details
                            </Typography>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <form noValidate autoComplete="off">
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.categoryList} 
                                        label='Category *'
                                        value={this.state.formData.category}
                                        onChange={this.handleSelectChange('category')}
                                        error={formErrors && formErrors['category'] && true}
                                        errorText={formErrors && formErrors['category'] && formErrors['category']}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.subCategoryList} 
                                        label='Sub Category *'
                                        value={this.state.formData.subCategory}
                                        onChange={this.handleSelectChange('subCategory')}
                                        error={formErrors && formErrors['subCategory'] && true}
                                        errorText={formErrors && formErrors['subCategory'] && formErrors['subCategory']}
                                    />
                                </FormControl>
                                <TextField
                                    id="title"
                                    name="title"
                                    label="Title *"
                                    value={this.state.formData.title}
                                    error={formErrors && formErrors['title'] && true}
                                    helperText={formErrors && formErrors['title']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <FormControl error={formErrors && formErrors['fileUploads'] && true} fullWidth margin="normal">
                                    <AutoCompleteSelect
                                        options={[
                                            {value: 'both', label: 'Both (MOV and MP4)'},
                                            {value: 'mov_only', label: 'MOV Only'},
                                            {value: 'mp4_only', label: 'MP4 Only'}
                                        ]}
                                        label='File Uploads *'
                                        value={this.state.formData.fileUploads}
                                        onChange={this.handleSelectChange('fileUploads')}
                                        error={formErrors && formErrors['fileUploads'] && true}
                                        errorText={formErrors && formErrors['fileUploads'] && formErrors['fileUploads']}
                                    />
                                </FormControl>
                                {this.state.showMov &&
                                    <DragFileInput
                                        id="movInput"
                                        name="mov"
                                        label="MOV (.mov) *"
                                        file={this.state.formData.mov}
                                        errorText={formErrors && formErrors['mov']}
                                        onChange={this.handleFileChange}
                                        cancelOnClick={this.clearMp4}
                                        emptyText='No file selected'
                                    />
                                }
                                {this.state.showMp4 &&
                                    <DragFileInput
                                        id="mp4Input"
                                        name="mp4"
                                        label="MP4 (.mp4) *"
                                        file={this.state.formData.mp4}
                                        errorText={formErrors && formErrors['mp4']}
                                        onChange={this.handleFileChange}
                                        cancelOnClick={this.clearMov}
                                        emptyText='No file selected'
                                    />
                                }
                                <DatePicker
                                    type="date"
                                    id="poi"
                                    name="poi"
                                    label="Date Added *"
                                    value={this.state.formData.dateAdded}
                                    errorText={formErrors && formErrors['dateAdded']}
                                    onChange={this.handleDateChange}
                                    disableFuture={true}
                                />
                                <div className='buttonRow' style={{justifyContent: 'space-between', alignItems:'center'}}>
                                    <Typography variant="subtitle2">
                                        <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                                    </Typography>
                                    <Button onClick={this.handleConfirmationOpen}
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.isLoading}
                                           >
                                        Add
                                    </Button>
                                </div>
                            </form>
                            <ConfirmationDialog 
                                open={this.state.confirmationOpen} 
                                success={this.handleConfirmationSuccess} 
                                close={this.handleConfirmationClose} 
                                title="Add A New Video?" 
                                message="Are you sure you want to add this new video?"
                            />
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarOpen}
                                onClose={this.handleSnackbarClose}
                                message='You have successfully added a new video'
                            />
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default AddVideo;