import React, { Component } from 'react';
import API                  from 'API';
import { connect }          from 'react-redux';

import AllIcon       from 'Components/Common/Icons/AllIcon';
import Image         from 'Components/Common/ImageWithError/ImageWithError';
import NumberField   from 'Components/Common/Inputs/NumberField';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Qr            from 'Components/Common/QrReader/QrReader';

import { colors }            from 'Helpers/ColourHelper';
import icons                 from 'Helpers/IconHelper';
import { pdfDataFromBase64 } from 'Helpers/PDFHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deploySnackBar }     from 'Actions/SnackBar/SnackBar';
import { playSound }          from 'Actions/Sounds/Sounds';
import {  printLabel }        from 'Actions/Print/Print';

import { Button, Grid, Typography } from '@material-ui/core/';
import { withStyles }               from '@material-ui/styles';

const initialState = {
    detail:     {},
    formData:   {
        qty:        0,
        required:   0,
        part:       null,
        skipped:    0,
        action:     'Pick',
        detail:     {}
    },
    scanStage:  0,
    activePart: {},
    returnData: [],
    formErrors: {},
    isLoading:  true,
    showQr:     false,
    showQty:    false,
    showError:  false,
    showNotes:  false,
    notes:      '',
}

class SingleScanDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.state.detail = this.props.pick;
    }

    componentDidMount = () => {
        this.getShortageData()
    }

    getShortageData = () => {
        this.setState({isLoading: true},
        ()=>{
            const detail = this.state.detail;
            this.setState({
                formData: {
                    ...initialState.formData,
                    required: parseFloat(detail.wossd_qty_remaining),
                    part:     detail.part.part_id,
                    detail
                },
                activePart: {...detail.part, part_required: detail.wossd_qty_remaining, part_picked: detail.wossd_qty_picked},
                isLoading:  false,
                showQr:     true,
                showQty:    false,
            })
        })
    }

    handleScan = (scannedPartNumber) => {
        if (scannedPartNumber){
            if (scannedPartNumber === this.state.activePart.part_number){
                this.props.playSound('shortageSuccess', 1)
                this.setState({
                    showQr:     false,
                    showQty:    true,
                    showError:  false,
                })
            } else {
                this.props.playSound('shortageFailed', 1);
                this.setState({showError: true})
            }
        }
    }

    findNextStage = () => {
        let current = this.state.scanStage;
        if (!this.state.details[this.state.scanStage + 1]) return false;
        for (let i = current + 1; i < this.state.details.length; i++){
            if (this.state.details[i].wossd_status === 'Pending') return i;
        }
    }

    nextStage = () => {
        if (this.state.formData.action !== 'skip') this.props.onChange(this.state.formData);
        this.props.onClose();
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                qty: e
            }
        })
    }

    handleClose = () => {
        //if (this.state.formData.qty > 0 && this.state.formData.action !== 'skip') this.props.onChange(this.state.formData);
        this.props.onClose(this.state.notes);
    }

    handleNoshortage = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                action: 'NotRequired',
                qty:     0
            }
        },this.nextStage);
    }

    handleMissing = () => {
        API.post(`/worksOrders/${this.props.woId}/addPartLog`, {
            type: 'Shortage Stock Missing',
            part: this.state.formData.part,
        }).then(() => {
            this.setState({
                formData: {
                    ...this.state.formData,
                    action: 'skip',
                    qty:     0
                }
            },this.nextStage);
        });
    }

    handleSkip = () => {
        API.post(`/worksOrders/${this.props.woId}/addPartLog`, {
            type: 'Shortage Pick Skipped',
            part: this.state.formData.part,
        }).then(() => {
            this.setState({
                formData: {
                    ...this.state.formData,
                    action: 'skip',
                    qty:     0
                }
            },this.nextStage);
        });
    }

    handleNoteChange = (e) => {this.setState({notes: e.target.value})}

    handlePrint = () => {
        API.get('/parts/' + this.state.activePart.part_id + '/stockLabel',{
            params: {
              type: 'partLabel'
            }
        })
        .then(result => {
            if(result.data) {
                this.props.printLabel(pdfDataFromBase64(result.data.pdf), 'part_label_'+this.state.activePart.part_number+'.pdf');
            }
        });
    }

    render(){
        const { formData, showError, showQty, showQr, isLoading, activePart  } = this.state;
        const { classes } = this.props;
        
        if (isLoading) return (<LoadingCircle/>);
        /*
        if (showNotes) return (
            <Grid container justify="center" className={`${classes.root} text-center`} spacing={8}>
                <Grid item xs={12}>
                    <Textarea
                        label="Notes"
                        value={notes}
                        onChange={this.handleNoteChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button 
                        variant="outlined"
                        className="mt-3 mr-1"
                        onClick={this.handleClose}
                        style={{border: '1px solid black'}}
                    >
                        Save & Close
                    </Button>
                </Grid>
            </Grid>
        )
        */
        return (
            <Grid container justify="center" className={`${classes.root} text-center`} spacing={8}>
                <Grid container item className={classes.qrRow}>
                    <Grid item xs={6}>
                        {showQr && <Qr handleScan={this.handleScan} width="30%" /> }
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Grid container justify="center" className="text-center" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h3">
                                {activePart.part_number}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                {activePart.part_description}<br></br>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="center" className="text-center" spacing={3}>
                                <Grid item xs={12}>
                                    <Image src={activePart.default_image?.file_path} className={classes.img} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {showQty ?
                    <Grid container item justify="center" spacing={2} style={{paddingBottom: 0}}>
                        <Grid item xs={12} className={classes.qtyRow} style={{paddingTop: 0}}>
                            <Typography variant="h4" component="span">
                                Required:
                            </Typography>
                            <Typography variant="h3" component="span" className="pl-1">
                                <strong>{parseFloat(activePart.part_required).toFixed(3)}</strong>
                            </Typography>
                        </Grid>
                        <Grid item style={{width: 400}}>
                            <NumberField
                                id="qty"
                                name="qty"
                                label={`Quantity *`}
                                value={formData.qty}
                                type="number"
                                variant="outlined"
                                onChange={this.handleChange}
                                margin="normal"
                                className={classes.confirmQtyInputContainer}
                                granularity={function(){
                                    var decimals = (parseFloat(activePart.part_required) !== parseFloat(Math.floor(activePart.part_required)))?(activePart.part_required.toString()).split('.')[1].length:0;
                                    if (!decimals) return 1;
                                    return Math.pow(10, -(decimals-1));
                                }()}
                                inputProps={{
                                    className: classes.confirmQtyInput,
                                    min: 0,
                                    max: parseFloat(activePart.part_required),
                                    pattern: "*"
                                }}
                            />
                        </Grid>
                        {   parseFloat(formData.qty) > 0 &&
                            parseFloat(formData.qty) !== parseFloat(activePart.part_required) &&
                            (
                                <Grid item xs={12}>
                                    <Typography variant="body1" component="span" className="pl-1" style={{color:colors.red}}>
                                        {(
                                            formData.qty < parseFloat(activePart.part_required) &&
                                            `This part pick is ${(parseFloat(activePart.part_required) - formData.qty).toFixed(3)} stock short`
                                        ) ||
                                        (
                                            formData.qty > parseFloat(activePart.part_required) &&
                                            `This part pick cannot be more than stock required`
                                        )}
                                    </Typography>
                                </Grid>
                            )
                        }
                    </Grid> : 
                    <>
                        <Grid item xs={12} className={classes.qtyRow}>
                            <Typography variant="h4" component="span">
                                Required:
                            </Typography>
                            <Typography variant="h3" component="span" className="pl-1">
                                <strong>{parseFloat(activePart.part_required).toFixed(3)}</strong>
                            </Typography>
                        </Grid>
                        <Typography variant="h4" style={{maxHeight: '3.5em'}} className={classes.actionText}>
                            <strong>{showError ? 'Incorrect Part Please Try Again' :'Please Scan QR Code'}</strong>
                        </Typography>
                    </>
                }
                <Grid item xs={8} style={{paddingTop: 0}}>
                    <Grid container spacing={1}>
                        {showQty &&
                            <Grid item xs={12}>
                                <Button 
                                    variant="contained" 
                                    color="primary"
                                    onClick={this.nextStage}
                                    disabled={parseFloat(formData.qty) <= 0 || parseFloat(formData.qty) > parseFloat(activePart.part_required)}
                                    className="mt-3 mr-1"
                                    fullWidth
                                >
                                    Confirm
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Button 
                                fullWidth
                                variant="outlined"
                                endIcon={<AllIcon icon={icons.print} />}
                                className="mt-3 mr-1"
                                onClick={this.handlePrint}
                                style={{border: '1px solid black'}}
                            >
                                Print Part Label 
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button 
                                fullWidth
                                variant="outlined"
                                endIcon={<AllIcon icon="step-forward" />}
                                className="mt-3 mr-1"
                                onClick={this.handleSkip}
                                style={{border: '1px solid black'}}
                            >
                                Skip Part
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button 
                                fullWidth
                                variant="outlined"
                                endIcon={<AllIcon icon={icons.false} />}
                                className="mt-3 mr-1"
                                onClick={()=>{
                                    this.props.deployConfirmation(
                                        'Are you sure this part is missing stock?',
                                        'Set Stock As Missing?',
                                        this.handleMissing
                                    )
                                }}
                                style={{border: '1px solid black'}}
                            >
                                All Part Stock Missing
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button 
                                fullWidth
                                variant="outlined"
                                endIcon={<AllIcon icon="times" />}
                                className="mt-3 mr-1"
                                onClick={()=>{
                                    this.props.deployConfirmation(
                                        'Are you sure this part is not required?',
                                        'Part Never Required?',
                                        this.handleNoshortage
                                    )
                                }}
                                style={{border: '1px solid black'}}
                                disabled={parseFloat(activePart.part_picked)}
                            >
                                Part Not Required
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button 
                                fullWidth
                                variant="outlined"
                                className="mt-3 mr-1"
                                onClick={this.handleClose}
                                style={{border: '1px solid black'}}
                                
                            >
                                Save & Close
                            </Button>
                        </Grid>
                    </Grid> 
                </Grid>
            </Grid>
        )
    }
}

const styles = theme => ({
    root: {
        height:     'calc(100vh - 91px)',
        maxHeight:  'calc(100vh - 91px)',
        width:      '100vw',
        Maxwidth:   '100vw',
        overflow:   'hidden',
    },
    img: {
        maxHeight: 200,
        maxWidth:  200,
        marginTop: '0.5em'
    },
    qrRow: {
        maxHeight: 60,
        maxWidth: '100vw',
    },
    qtyRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    actionText: {
        border: `2px dashed ${theme.palette.secondary.main}`,
        padding: 35,
        width: '60%',
        margin: '0 auto'
    },
    connectionText : {
        margin: '1em auto',
        width:  '40%',
        border: `1px solid ${colors.red}`,
        maxHeight: 'calc(35.5px + 2em)'
    },
    errorText: {
        color: theme.palette.secondary.main
    },
    button: {
        marginTop: 35
    },
    confirmQtyInputContainer: {
        maxWidth: 400,
    },
    confirmQtyInput: {
        textAlign: 'center',
        maxWidth: 400,
        fontSize: 40,
    },
    largeButton: {
        fontSize: 24
    },
    successTick: {
        color: colors.green
    },
    [theme.breakpoints.down('sm')]: {
        img: {
            maxHeight: 150,
            maxWidth:  150
        },
        actionText: {
            fontSize: '2rem',
        },
        largeButton: {
            fontSize: 18
        }
    },
});

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success, cancel) => {
            dispatch(deployConfirmation(message, title, success, cancel))
        },
        playSound: (type, state) => {
            dispatch(playSound(type, state));
        },
        deploySnackBar: (variant, message) => {
            dispatch(deploySnackBar(variant, message));
        },
        printLabel:         (data, file)                                => dispatch(printLabel(data, file)),
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(SingleScanDialog));