import React, { Component, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import API from 'API';
import _ from 'lodash';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { InputAdornment, Grid, TextField, Button, Box, Typography, Tooltip } from '@material-ui/core';
import moment from 'moment';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const EquipmentReasons = () => {

    const   dispatch = useDispatch(),
            [isLoading, setIsLoading] = useState(true),
            [access, setAccess] = useState({
                manageReason: false
            }),
            [reasonName, setReason] = useState(''),
            [reasons, setReasons] = useState([]),
            [formErrors, setFormErrors] = useState({});
        

    useEffect(() => {
        // Set Access
        API.get('/staff/my/access/check/equipment-reasons:manage')
        .then((res) =>  {
            res.data && 
            setAccess({
                ...access,
                manageReason: res.data.has_access
            });
        });

        getReasons();

    }, []);

    const getReasons = () => {
        API.get('/equipment/reasons')
        .then(result => {
            if(result.data) {
                setReasons(result.data);
                setIsLoading(false);
            }
        });
    }

    const handleSubmit = () => {
        API.post('/equipment/reasons', {
            reasonName
        })
        .then(res => {
            if(res.data.errors){
                setFormErrors(formatValidationErrors(res.data.errors));
            } else {
                dispatch(deploySnackBar("success", "Equipment reason added successfully"));
                setReason('');
                getReasons();
            }
        })
    }

    return (
        (isLoading && <LoadingCircle />) || (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Equipment Reasons
                    </Typography>
                </Grid>
                {access.manageReason && (
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <TextField
                                    name="reasonName"
                                    label="Reason *"
                                    value={reasonName}
                                    error={formErrors && formErrors['reasonName'] && true}
                                    helperText={formErrors && formErrors['reasonName']}
                                    margin="normal"
                                    fullWidth
                                    onChange={(e) => setReason(e.target.value)}
                                />
                                <div className='buttonRow'>
                                    <Button 
                                        onClick={() => dispatch(deployConfirmation(`Are you sure you want to add this reason?`, `Add Equipment Reason`, handleSubmit))}
                                        variant="contained" 
                                        color="primary"
                                    >
                                        Add
                                    </Button>
                                </div>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key: 'id',
                                alternatingRowColours: true
                            }}
                            columns={[
                                {
                                    heading: 'Reasons',
                                    field: rowData => rowData.name
                                }
                            ]}
                            rows={reasons}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        )
        
    )
};

export default EquipmentReasons;