import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { Grid, Typography, Button } from '@material-ui/core';
import InputHelper from 'Helpers/InputHelper';
import React from 'react';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import API from 'API';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import _ from 'lodash';
import CheckBox from 'Components/Common/Inputs/CheckBox';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

const initialState = () => ({
    formData: {
        addressTypes: [],
        removeDuplicates: true,
        linkedCustomer: null,
        marketingPreference: null,
        customerStatus: [],
        removeInactiveSites: true,
        servicePlan: null,
        columns: {
           // Id: false,
            Name: true,
            Email: true,
            Customer: false,
            //Unsubscribed: false,
           // 'Service Plan': false,
           // 'Registered Address': false,
            //'Trading Addresses': false,
          //  'Accounts Addresses': false,
           // 'Site Addresses': false,
        }
    },
    contacts: [],
    filteredContacts: [],
    isLoading: true,
})

let columns = _.keys(initialState().formData.columns)

class ContactsExcel extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.inputHandler = new InputHelper(this, 'formData', () => this.filterContacts());
    }
    
    componentDidMount() {
        this.getContacts();
    }

    getContacts = () => {
        API.get('/marketing/campaigns/mailingLists/eligibleContacts', {params: {breakdown: true}})
        .then(response => {
            this.setState({
                contacts: _.map(response.data.contacts, i => ({
                    //columns
                    Id: i.contact_id,
                    Name: i.contact_name,
                    Email: i.contact_email,
                    Unsubscribed: i.contact_email_opt_out,
                    Customer: i?.customer?.cust_name || 'Manual Contact',
                    'Service Plan': i?.customer?.cust_service_plan || 0,
                    'Registered Address': i?.addresses ? _.map(_.filter(i.addresses, {address_type: 'Registered'}), 'address_name').join(', ') : '',
                    'Trading Addresses': i?.addresses ? _.map(_.filter(i.addresses, {address_type: 'Trading'}), 'address_name').join(', ') : '',
                    'Accounts Addresses': i?.addresses ? _.map(_.filter(i.addresses, {address_type: 'Accounts'}), 'address_name').join(', ') : '',
                    'Site Addresses': i?.addresses ? _.map(_.filter(i.addresses, {address_type: 'Site'}), 'address_name').join(', ') : '',

                    //customer status
                    status: i?.customer?.cust_account_status_id ?? 4,

                    //filter items
                    addresses: i.addresses,
                })),
                isLoading: false,
            }, this.filterContacts)
        })
    }

    filterContacts = () => {
        let contacts = this.state.contacts;
        let formData = this.state.formData;

        if (formData?.addressTypes && formData?.addressTypes?.length > 0){
            contacts = contacts.filter(contact => _.find(contact.addresses, j => formData.addressTypes.includes(j.address_type)))
        }

        if (formData?.removeDuplicates){
            contacts = _.uniqBy(contacts, i => i?.Email && i?.Email?.toLowerCase());
        }

        if (formData?.linkedCustomer !== 'All' && !(formData?.addressTypes && formData?.addressTypes?.length > 0)){
            contacts = _.filter(contacts, i => i.Customer !== 'Manual Contact' && formData.linkedCustomer === 'Linked' || i.Customer === 'Manual Contact' && formData.linkedCustomer === 'Manual')
        }

        if (formData?.servicePlan !== 'all'){
            contacts = _.filter(contacts, i => formData?.servicePlan && (formData?.servicePlan === 'yes'? i['Service Plan'] : !i['Service Plan']))
        }

        if (formData?.marketingPreference !== 'All'){
            contacts = _.filter(contacts, i => formData.marketingPreference === 'unsub' && i.Unsubscribed || formData.marketingPreference === 'allow' && !i.Unsubscribed)
        }
        
        if (formData.customerStatus.length > 0){
            contacts = _.filter(contacts, i => i.status && formData.customerStatus.includes(i.status))
        }
        
        if (formData.removeInactiveSites){
            contacts = _.filter(contacts, i => i.addresses.length === 0 || _.filter(i.addresses, j => j.address_active).length > 0)
        }

        this.setState({
            filteredContacts: contacts,
        })

    }   

    downloadCSV = () => {
        let filteredContacts = this.state.filteredContacts;

        let contacts = _.map(filteredContacts, i => {
            let contact = {};
            _.each(columns, j => {
                if ( this.state.formData.columns[j] ){
                    contact[j] = i[j]; 
                }
            })
            return contact;
        })

    }

    render() {

        const { isLoading, filteredContacts, formData } = this.state;

        if (isLoading) return (<LoadingCircle />);

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5'>Export Contacts</Typography>
                </Grid>
                <Grid item xs={7}>
                    <PaddedPaper>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>Filters</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteMultiSelect
                                    label="Address Types *"
                                    name="addressTypes"
                                    value={formData.addressTypes}
                                    onChange={this.inputHandler.handleMultiSelectChange('addressTypes')}
                                    options={[
                                        { value: "Registered", label: "Registered" },
                                        { value: "Trading", label: "Trading" },
                                        { value: "Accounts", label: "Accounts" },
                                        { value: 'Site', label: 'Site' },
                                    ]}
                                    fullWidth
                                    noSort
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    disableSort
                                    label="Linked Customer *"
                                    noClear
                                    name="customer"
                                    value={formData.addressTypes.length > 0 ? 'Linked' : formData.linkedCustomer}
                                    disabled={formData.addressTypes.length > 0}
                                    onChange={this.inputHandler.handleSelectChange('linkedCustomer')}
                                    options={[
                                        { value: 'All',    label: 'Both Manual And Customer Contacts' },
                                        { value: 'Linked', label: 'Customer Contacts Only' },
                                        { value: 'Manual', label: 'Manual Contacts Only' },
                                    ]}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    disableSort
                                    label="Marketing Preference *"
                                    noClear
                                    name="marketingPreference"
                                    value={formData.marketingPreference}
                                    onChange={this.inputHandler.handleSelectChange('marketingPreference')}
                                    options={[
                                        { value: 'All',   label: 'All' },
                                        { value: 'unsub', label: 'Unsubscribed' },
                                        { value: 'allow', label: 'Subscribed' },
                                    ]}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    disableSort
                                    label="Service Plan *"
                                    noClear
                                    name="servicePlan"
                                    value={formData.servicePlan}
                                    onChange={this.inputHandler.handleSelectChange('servicePlan')}
                                    options={[
                                        {value: 'all' , label: 'Both'},
                                        {value: 'yes' , label: 'Yes'},
                                        {value: 'no' , label: 'No'},
                                    ]}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteMultiSelect
                                    disableSort
                                    label="Customer Status *"
                                    noClear
                                    name="customerStatus"
                                    value={formData.customerStatus}
                                    onChange={this.inputHandler.handleMultiSelectChange('customerStatus')}
                                    options={[
                                        {value: 1 , label: 'Active - Verified'},
                                        {value: 4 , label: 'Active - Not Verified'},
                                        {value: 2 , label: 'On Hold'},
                                        {value: 3 , label: 'Closed'},
                                    ]}
                                />
                            </Grid>
                            <Grid item>
                                <CheckBox
                                    label="Remove Inactive Sites"
                                    onClick={()=>this.inputHandler.handleValueChange(`removeInactiveSites`)(!formData.removeInactiveSites)}
                                    checked={formData.removeInactiveSites}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item>
                                <CheckBox
                                    label="Remove Duplicate Emails"
                                    onClick={()=>this.inputHandler.handleValueChange(`removeDuplicates`)(!formData.removeDuplicates)}
                                    checked={formData.removeDuplicates}
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={7}>
                    <PaddedPaper>
                        <Grid container >
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{marginBottom: '1em'}}>Columns</Typography>
                            </Grid>
                            {_.map(columns, i =>
                                <Grid item>
                                    <CheckBox
                                        label={i}
                                        onClick={()=>this.inputHandler.handleValueChange(`columns.${i}`)(!formData.columns[i])}
                                        checked={formData.columns[i]}
                                        color="primary"
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </PaddedPaper>
                </Grid>
                {filteredContacts.length > 0 &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <DataTable
                                config={{
                                    key: 'contact_id',
                                    pagination: true,
                                    options: {
                                        export: {
                                            title: `Contacts`,
                                            name: `Contacts`,
                                            excel: true,
                                            pdf: true,
                                            print: true,
                                            csv: true,
                                        },
                                    }
                                }}
                                rows={filteredContacts}
                                columns={_.filter(
                                    _.map(columns, i => formData.columns[i] && ({
                                        field: i,
                                        heading: i,
                                        dataRef: i,
                                        truncate: true,
                                    })), i => i)
                                }
                            />
                        </PaddedPaper>
                    </Grid>
                }
            </Grid>
        );
    }
}

export default ContactsExcel;