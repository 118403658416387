import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import API from '../../../API';
import _ from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import Button from '@material-ui/core/Button';
import { Link, Redirect } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import ImageWithError from '../../Common/ImageWithError/ImageWithError';
import { MAX_UPLOAD_LIMIT_SIZE } from './../../../Constants';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import BackButton from 'Components/Common/Buttons/BackButton';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { TextField } from '@material-ui/core';

const initialState = {
    formData: {
        libraryId: '',
        defaultImage: false,
        imageFile: '',
        imageFileRaw: '',
        image3DFileObj: '',
        image3DFileMtl: '',
        version: 0,
    },
    part: '',
    partImage: '',
    default: 'No',
    noImageAvailable: false,

    disableImageFile: false,
    disableRawImageFile: false,
    disable3DImageFile: false,
    disableWeb3DImageFile: false,

    showImageFile: false,
    showRawImageFile: false,
    show3DImageFile: false,
    showWeb3DImageFile: false,

    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: false,
    initialLoading: true,
    redirect: false
}

class EditPartImage extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getPartImageFiles();
    }

    getPartImageFiles = () => {        
        Promise.all([ 
            API.get(`/parts/${this.props.match.params.id}`), 
            API.get(`/library/parts/${this.props.match.params.id}/${this.props.match.params.ref}`),
            API.get(`/researchDevelopment/changeOrders/part/${this.props.match.params.id}/actualIssue`),
        ])
        .then(([partRes, result, versions]) =>  {
            if (partRes.data.errors || result.data.errors) {
                this.setState({ redirect: true });
            } else if (partRes.data && result.data) {
                let partImage = _.find(result.data.files, {'library_type': 'Part Image'});
                this.setState({
                    formData: {
                        ...this.state.formData,
                        libraryId: partImage ? partImage.library_id : '',
                        version: partImage ? partImage.library_part_version : 0,
                    },
                    part: partRes.data.part_number + ' - ' + partRes.data.part_description,
                    partImage: partImage ? partImage.file : result.data.no_image,
                    default: partImage ? partImage.library_default_image : '',
                    noImageAvailable: partImage ? false : true,
                    showImageFile: _.find(result.data.files, {'library_type': 'Part Image'}) ? false : true,
                    showRawImageFile: _.find(result.data.files, {'library_type': 'Part Image File'}) ? false : true,
                    show3DImageFile: _.find(result.data.files, {'library_type': 'Part 3D Image File'}) ? false : true,
                    showWeb3DImageFile: _.find(result.data.files, {'library_type': 'Part Web 3D Image File'}) ? false : true,
                    partVersion: versions.data ? versions.data  : 0,
                    initialLoading: false
                });
            }
        })
    }

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            let newFormData = new FormData();
            Object.keys(this.state.formData).forEach(key => {
                newFormData.append(key, this.state.formData[key])  
            });
            API.post(`/library/parts/${this.props.match.params.id}/${this.props.match.params.ref}`, newFormData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...initialState,
                        snackbarOpen: true
                    });
                    this.getPartImageFiles();
                }
                this.props.scrollToTop();
            });
        });
    }

    handleCheckChange = name => event => {
        if(name === 'defaultImage'){
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: event.target.checked
                }
            })
        }
        else {
            this.setState({ [name]: event.target.checked });
        }
    };

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        });
    }

    clearDocument = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: initialState.formData.name
            }
        });
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    }
       
    render() {
        const { formErrors, initialLoading } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/library/parts/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Edit Part Image
                        </Typography>
                    </Grid>
                    {(initialLoading && (
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <LoadingCircle />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>                    
                    )) || (
                        <React.Fragment>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <div style={{textAlign:'center'}}>
                                            <Typography variant="h6" gutterBottom>
                                                {this.state.part}
                                            </Typography>
                                            <ImageWithError src={this.state.partImage} style={{height:200, maxWidth: '100%'}} alt="The part" />
                                        </div>
                                        <Typography variant="h6">
                                            Default Image
                                        </Typography>
                                        {this.state.default === 'Yes' ?
                                            <div style={{ display:'flex'}} >
                                                <FALightIcon icon='shield-check' size='large' style={{color:'#20c997', display:'flex', justifyContent:'flex-start'}} />
                                                <Typography variant="body1" gutterBottom  style={{color:'#20c997', display:'flex', justifyContent:'flex-start'}}>
                                                    This is the Default Image
                                                </Typography>
                                            </div>
                                        :
                                            this.state.noImageAvailable ?
                                                <Typography variant="body2" gutterBottom>
                                                    Upload an Image File to make this the Default Image
                                                </Typography>
                                                :
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox name="defaultImage"
                                                                checked={this.state.formData.defaultImage}
                                                                onChange={this.handleCheckChange('defaultImage')}
                                                                value={true}
                                                                color="primary"
                                                                margin="normal" />
                                                    }
                                                    label={<span style={{color: 'rgba(0, 0, 0, 0.54)'}}>Make this the default image</span>}
                                                />
                                        }
                                        {parseFloat(this.state.partVersion) > 0 && 
                                            <>
                                                <Typography variant="h6">
                                                    Part Versions
                                                </Typography>
                                                <Grid container alignItems="center" spacing={2} style={{marginBottom: '1em'}}>
                                                    <Grid item xs={12} >
                                                        <TextField 
                                                            disabled
                                                            fullWidth
                                                            label="Part Current Version"
                                                            value={`v${this.state.partVersion}`}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <AutoCompleteSelect
                                                            options={_.reverse(_.map(_.range(0, (parseFloat(this.state.partVersion) + 1)), i => ({
                                                                value: i,
                                                                label: i ? `v${i}` : 'Un-versioned'
                                                            })))}
                                                            fullWidth
                                                            noClear
                                                            value={this.state.formData.version}
                                                            label='Link Images To Part Version *'
                                                            disableSort
                                                            onChange={this.handleSelectChange('version')}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }
                                        <Typography variant="h6">
                                            Image Files
                                        </Typography>
                                        {this.state.showImageFile &&
                                            <Grid container alignItems="center">
                                                <Grid item xs={2} lg={1} alignItems="center">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                            checked={this.state.disableImageFile}
                                                            onChange={this.handleCheckChange('disableImageFile')}
                                                            value="disableImageFile"
                                                            color="primary"
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={10} lg={11} alignItems="center">
                                                    <DragFileInput
                                                        id="imageFileInput"
                                                        name="imageFile"
                                                        label="Image File (.jpg, .tiff, .gif) *"
                                                        file={this.state.formData.imageFile}
                                                        errorText={formErrors && formErrors['imageFile']}
                                                        onChange={this.handleFileChange}
                                                        cancelOnClick={this.clearDocument('imageFile')}
                                                        emptyText='No file selected'
                                                        disabled={!this.state.disableImageFile}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        {this.state.showRawImageFile &&
                                            <Grid container alignItems="center">
                                                <Grid item xs={2} lg={1}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                            checked={this.state.disableRawImageFile}
                                                            onChange={this.handleCheckChange('disableRawImageFile')}
                                                            value="disableRawImageFile"
                                                            color="primary"
                                                            />
                                                        }
                                                    />    
                                                </Grid>
                                                <Grid item xs={10} lg={11}>
                                                    <DragFileInput
                                                        id="imageFileRawInput"
                                                        name="imageFileRaw"
                                                        label="Raw Image File (.psd, .ai) *"
                                                        file={this.state.formData.imageFileRaw}
                                                        errorText={formErrors && formErrors['imageFileRaw']}
                                                        onChange={this.handleFileChange}
                                                        cancelOnClick={this.clearDocument('imageFileRaw')}
                                                        emptyText='No file selected'
                                                        disabled={!this.state.disableRawImageFile}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        {this.state.show3DImageFile &&
                                            <Grid container alignItems="center">
                                                <Grid item xs={2} lg={1}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                            checked={this.state.disable3DImageFile}
                                                            onChange={this.handleCheckChange('disable3DImageFile')}
                                                            value="disable3DImageFile"
                                                            color="primary"
                                                            />
                                                        }
                                                    />    
                                                </Grid>
                                                <Grid item xs={10} lg={11}>
                                                    <DragFileInput
                                                        id="image3DFileObj"
                                                        name="image3DFileObj"
                                                        label="3D File (.obj) *"
                                                        file={this.state.formData.image3DFileObj}
                                                        errorText={formErrors && formErrors['image3DFileObj']}
                                                        onChange={this.handleFileChange}
                                                        cancelOnClick={this.clearDocument('image3DFileObj')}
                                                        emptyText='No file selected'
                                                        disabled={!this.state.disable3DImageFile}
                                                    />
                                                    <DragFileInput
                                                        id="image3DFileMtl"
                                                        name="image3DFileMtl"
                                                        label="3D File (.mtl) *"
                                                        file={this.state.formData.image3DFileMtl}
                                                        errorText={formErrors && formErrors['image3DFileMtl']}
                                                        onChange={this.handleFileChange}
                                                        cancelOnClick={this.clearDocument('image3DFileMtl')}
                                                        emptyText='No file selected'
                                                        disabled={!this.state.disable3DImageFile}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        {this.state.showWeb3DImageFile &&
                                            <Grid container alignItems="center">
                                                <Grid item xs={2} lg={1}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                            checked={this.state.disableWeb3DImageFile}
                                                            onChange={this.handleCheckChange('disableWeb3DImageFile')}
                                                            value="disableWeb3DImageFile"
                                                            color="primary"
                                                            />
                                                        }
                                                    />    
                                                </Grid>
                                                <Grid item xs={10} lg={11}>
                                                    <DragFileInput
                                                        id="webImage3DFile"
                                                        name="webImage3DFile"
                                                        label="Web 3D File (.glb) *"
                                                        file={this.state.formData.webImage3DFile}
                                                        errorText={formErrors && formErrors['webImage3DFile']}
                                                        onChange={this.handleFileChange}
                                                        cancelOnClick={this.clearDocument('webImage3DFile')}
                                                        emptyText='No file selected'
                                                        disabled={!this.state.disableWeb3DImageFile}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        {!this.state.showImageFile && !this.state.showRawImageFile && !this.state.show3DImageFile &&
                                            <Typography variant="body1" gutterBottom style={{textAlign:'center'}}>
                                                All files have been uploaded for this image
                                            </Typography>
                                        }
                                        <div className='buttonRow' style={{justifyContent: 'space-between', flexDirection: 'row', alignItems:'center'}}>
                                            <Typography variant="subtitle2" style={{flex: 1}}>
                                                <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                                            </Typography>
                                            <BackButton props={this.props} />
                                            <Button onClick={this.handleConfirmationOpen}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={this.state.isLoading}
                                                >
                                                Update
                                            </Button>
                                        </div>
                                        <ConfirmationDialog 
                                            open={this.state.confirmationOpen} 
                                            success={this.handleConfirmationSuccess} 
                                            close={this.handleConfirmationClose} 
                                            title="Update Image Files?" 
                                            message="Are you sure you want to update these image files?"
                                        />
                                        <SnackBar
                                            variant="success"
                                            anchorOriginVertical='bottom'
                                            anchorOriginHorizontal='right'
                                            open={this.state.snackbarOpen}
                                            onClose={this.handleSnackbarClose}
                                            message='You have successfully updated the image file(s)'
                                        />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            );
        }
    }
}

export default EditPartImage;
