import React       from 'react';
import API         from 'API';
import moment      from 'moment';
import {filter}    from 'lodash';
import { connect } from 'react-redux';

import DataTable          from 'Components/Common/DataTables/DataTable';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import FALightIcon        from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon        from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import {Grid, TextField, Typography, Button, FormControl, AppBar, Tab, Tabs, Badge, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import { deploySnackBar }                   from 'Actions/SnackBar/SnackBar';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';
import { Dial }                             from 'Functions/MiscFunctions';
import {colors}                     from 'Helpers/ColourHelper';

import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

const initialState = () => ({
    searchName: '',
    searchStatus: 'All',
    searchResults: [],
    awaitingApproval: [],
    isLoading: true,
    currentTab: 1,
    approvalDialogOpen: false,
    statusDialogOpen: false,
    selectedUser: {},
    formStatus: '',
    tabStore: {
        searchName  : '',
        searchStatus: 'All',
    }
})
class CustomerAccess extends React.Component {

    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Customers:customersAccess';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        !this.hasPageState() && this.getSearchData();
    }

    getSearchData = () => {
        API.get(`/customers/webUsers`, {
            params: {
                searchName: this.state.searchName,
                searchStatus: this.state.searchStatus,
            }
        })
        .then(result => {
            if(result?.data) {
                let awaitingApproval = filter(result.data, { 'web_user_status': 'Pending' });
                let searchResults = filter(result.data, (usr) => {
                    return usr.web_user_status === 'Active' || usr.web_user_status === 'Inactive'
                });
                this.setState({
                    searchResults,
                    awaitingApproval,
                    isLoading: false
                });
            }
        });
    }

    handleChange = (e) => {
        this.setState({
            searchName: e.target.value
        }, 
        () => {
            this.getSearchData();
            this.savePageState();
        });
    }

    resetSearch = () => {
        this.setState({
            searchName: '',
            searchStatus: 'All',
        },
        () => {
            this.clearPageState();
            this.props.history.replace();
            this.getSearchData();
        });
    }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value,
            searchStatus: (value === 2) ? 'All' : this.state.tabStore.searchStatus,
            tabStore: {
                searchName  : this.state.searchName,
                searchStatus: this.state.searchStatus,
            }
        },()=>{
            this.savePageState();
            this.getSearchData();
        });
    };
    // Approval
    handleApprovalOpen = (rowData, type) => {
        rowData.action = type;
        this.setState({
            approvalDialogOpen: true,
            selectedUser: rowData
        });
    };
    handleApprovalClose = () => {
        this.setState({
            approvalDialogOpen: false
        });
    };
    handleApprovalSuccess = () => {
        this.setState({
            approvalDialogOpen: false
        },
        () => {
            let status = this.state.selectedUser.action === 'Approve' ? 'Active' : 'Inactive';
            API.post(`/customers/webUsers/${this.state.selectedUser.web_user_id}/status`, {status: status})
            .then((result) => {
                this.getSearchData();
                this.props.deploySnackBar("success", "You have successfully " + (this.state.selectedUser.action === 'Approve' ? 'approved' : 'declined') + " this user");
            });
        });
    };
    handleSelectChange = name => res => {
        this.setState({
            [name]: res && res.value
        },
        () => {
            if(name === 'searchStatus'){
                this.getSearchData();
            }
            this.savePageState();
        });
    };
    // Status
    handleStatusOpen = (rowData) => {
        this.setState({
            statusDialogOpen: true,
            selectedUser: rowData,
            formStatus: rowData.web_user_status
        });
    };
    handleStatusClose = () => {
        this.setState({
            statusDialogOpen: false
        });
    };
    handleStatusSuccess = () => {
        this.setState({
            statusDialogOpen: false
        },
        () => {
            API.post(`/customers/webUsers/${this.state.selectedUser.web_user_id}/status`, {status: this.state.formStatus})
            .then((result) => {
                this.getSearchData();
                this.props.deploySnackBar("success", "You have successfully updated this user");
            });
        });
    };
    

    render() {
        const { searchName, isLoading, searchResults, currentTab, awaitingApproval, approvalDialogOpen, selectedUser, statusDialogOpen, searchStatus } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Customer Access
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <AppBar position="static" style={{backgroundColor:'white'}} >
                        <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                            <Tab label="Users" value={1} />
                            <Tab 
                                label={
                                    awaitingApproval.length > 0 ?
                                    <Badge badgeContent={awaitingApproval.length} color="secondary">Awaiting Approval</Badge> :
                                    <span>Awaiting Approval</span>
                                } 
                                value={2} 
                                style={{width:'12%'}} 
                            />
                        </Tabs>
                    </AppBar>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <form noValidate autoComplete="off">
                                    <FormControl margin="normal" fullWidth>
                                        <TextField  
                                            id="searchName"
                                            name="searchName"
                                            label="Keywords"
                                            value={searchName}
                                            onChange={this.handleChange}
                                            fullWidth 
                                        />
                                    </FormControl>
                                    {currentTab === 1 && 
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                options={[
                                                    {'value': 'All', 'label': 'All'},
                                                    {'value': 'Active', 'label': 'Active'},
                                                    {'value': 'Inactive', 'label': 'Inactive'}
                                                ]}
                                                label='Status'
                                                onChange={this.handleSelectChange('searchStatus')}
                                                value={searchStatus}
                                                noClear
                                            />
                                        </FormControl>
                                    }
                                    <div className='buttonRow'>
                                        <Button 
                                            onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                        >
                                            <FALightIcon icon='undo' button />
                                            Reset Search
                                        </Button>
                                    </div>
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <br></br>
                    {currentTab === 1 && 
                        <TabContainer>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h6" gutterBottom>
                                        Users of clenaware.co.uk
                                    </Typography>
                                    <DataTable  
                                        config={{
                                            key: 'web_user_id',
                                            pagination: true,
                                            isLoading: isLoading,
                                            responsiveImportance: true,
                                        }}
                                        columns={[
                                            {
                                                heading: 'First Name',
                                                field: rowData => rowData.web_user_first_name,
                                                important: true,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Last Name',
                                                field: rowData => rowData.web_user_last_name,
                                                important: true,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Company Name',
                                                field: rowData => rowData.web_user_company_name ? rowData.web_user_company_name : '-',
                                                important: true,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Email',
                                                field: rowData => rowData.web_user_email,
                                                important: true,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Contact Number',
                                                field: rowData => <Dial number={rowData.web_user_contact_number ? rowData.web_user_contact_number : '-'} />,
                                                important: true,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Status',
                                                field: rowData => rowData.web_user_status === 'Active' ? 
                                                                <><FASolidIcon icon='circle' size='small' style={{color: colors.green}}/> {rowData.web_user_status} </>
                                                                : 
                                                                <><FASolidIcon icon='ban' size='small' style={{color: colors.red}}/> {rowData.web_user_status} </> ,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Last Login',
                                                field: rowData => rowData.web_user_last_login_datetime === '0000-00-00 00:00:00' ? '-' : moment(rowData.web_user_last_login_datetime).format("DD-MM-YYYY"),
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Registered Since',
                                                field: rowData => rowData.web_user_created_datetime === '0000-00-00 00:00:00' ? '-' : moment(rowData.web_user_created_datetime).format("DD-MM-YYYY"),
                                                sizeToContent: true
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Update Status', icon: 'lock-alt', onClick: () => this.handleStatusOpen(rowData)},
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={searchResults}
                                    />
                                </PaddedPaper>
                            </Grid>
                        </TabContainer>
                    }
                    {currentTab === 2 && 
                        <TabContainer>
                            <PaddedPaper>
                                {awaitingApproval && awaitingApproval.length>0 ?
                                    <DataTable  
                                        config={{
                                            key: 'web_user_id',
                                            isLoading: isLoading,
                                            responsiveImportance: true,
                                        }}
                                        columns={[
                                            {
                                                heading: 'First Name',
                                                field: rowData => rowData.web_user_first_name,
                                                important: true,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Last Name',
                                                field: rowData => rowData.web_user_last_name,
                                                important: true,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Company Name',
                                                field: rowData => rowData.web_user_company_name ? rowData.web_user_company_name : '-',
                                                important: true,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Email',
                                                field: rowData => rowData.web_user_email,
                                                important: true,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Contact Number',
                                                field: rowData => rowData.web_user_contact_number ? rowData.web_user_contact_number : '-',
                                                important: true,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Registered',
                                                field: rowData => rowData.web_user_created_datetime === '0000-00-00 00:00:00' ? '-' : moment(rowData.web_user_created_datetime).format("DD-MM-YYYY"),
                                                sizeToContent: true
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Approve', icon: 'check', onClick: () => this.handleApprovalOpen(rowData, 'Approve')},
                                                        {name: 'Decline', icon: 'times', onClick: () => this.handleApprovalOpen(rowData, 'Decline')}
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={awaitingApproval}
                                    />
                                    :
                                    <Typography variant="body1" gutterBottom>
                                        0 users awaiting approval
                                    </Typography>
                                }
                            </PaddedPaper>
                        </TabContainer>
                    }
                </Grid>

                <Dialog 
                    open={approvalDialogOpen} 
                    onClose={this.handleApprovalClose} 
                    fullWidth={true} 
                    maxWidth="xs" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title" >
                        <Typography>
                            {selectedUser.action} User
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        Are you sure you want to { selectedUser.action === 'Approve' ? 'approve':'decline'} {selectedUser.web_user_first_name} {selectedUser.web_user_last_name}?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleApprovalClose} variant="outlined" color="default">Close</Button>
                        <Button onClick={this.handleApprovalSuccess} autoFocus variant="contained" style={{backgroundColor:(selectedUser.action === 'Approve' ? '#5da14d':'#D61F26'), color:'white'}}>{selectedUser.action}</Button>
                    </DialogActions>
                </Dialog>

                <Dialog 
                    open={statusDialogOpen} 
                    onClose={this.handleStatusClose} 
                    fullWidth={true} 
                    maxWidth="xs" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title" >
                        <Typography>
                            Change Status for {selectedUser.web_user_first_name} {selectedUser.web_user_last_name}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth margin="normal">
                            <AutoCompleteSelect 
                                options={[
                                    {'value': 'Active', 'label': 'Active'},
                                    {'value': 'Inactive', 'label': 'Inactive'}
                                ]}
                                label='Status *'
                                onChange={this.handleSelectChange('formStatus')}
                                value={this.state.formStatus}
                                noClear
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleStatusClose} variant="outlined" color="default">Close</Button>
                        <Button onClick={this.handleStatusSuccess} color="primary" autoFocus variant="contained" >Submit</Button>
                    </DialogActions>
                </Dialog>
                
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deploySnackBar:     (variant, msg)  => dispatch(deploySnackBar(variant, msg)),
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAccess);
