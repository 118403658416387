import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { ButtonGroup, Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControl, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { colors } from 'Helpers/ColourHelper';

import DragFileInputMulti from 'Components/Common/Inputs/DragFileInputMulti';
import Textarea           from 'Components/Common/Inputs/Textarea';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import SelectPartWithQR   from 'Components/Common/Selects/SelectPartWithQR';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog }        from 'Actions/Dialog/Dialog';
import NumberField from 'Components/Common/Inputs/NumberField';

const initialReasonState = {
    file:   null,
    notes:  null,
    reason: null,
    copy:   null
}

const initialState = {
    isLoading:    true,
    writeOffs:    [],
    writeOffList: [],
    stage:        {},
    parts:        [],
    formErrors:   {},
    formData:     {
        part:       {},
        partId:     0,
        qty:        0,
        reasons:    [{
            file:   null,
            notes:  null,
            reason: null,
            copy:   null
        }],
    },
    mode:         'single',
}

class WriteOff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            formData:     {
                part:       {},
                partId:     0,
                qty:        0,
                reasons:    [{
                    file:   null,
                    notes:  null,
                    reason: null,
                    copy:   null
                }],
            },
            stage: this.props.stage,
        };
    }

    componentDidMount() { 
        this.getWriteOffReasons();
    }

    getWriteOffReasons = () => {
        API.get('/worksOrders/writeOffReasons/all')
        .then(result => {
            this.setState({
                writeOffList: _.map(result.data, j => _.assign({value: j.wowor_id , label: j.wowor_reason})),
            }, this.getParts)
        })
    }

    getParts = () => {
        let parts = [];
        _.each(_.filter(this.props.worksOrder.build.stages, s => parseInt(s.instruction.sais_number) <= parseInt(this.props.stage.instruction.sais_number)), s => {
            _.each(s.instruction.parts, i => {
                let pickStatus = _.find(this.props.worksOrder.pick.details, {wopd_part_id: i.part.part_id})?.wopd_status;
                let notRequired = false;
                if (pickStatus === "Complete" || pickStatus === 'Not Required' ) {
                    notRequired = ( pickStatus === 'Not Required')
                } else if (this.props.worksOrder.shortage) {
                    pickStatus = _.find(this.props?.worksOrder?.shortage?.details, {wossd_part_id : i.part.part_id})?.wossd_status ;
                    notRequired = ( pickStatus === 'Not Required')
                }
                let idx = _.findIndex(parts, {id: i.part.part_id});
                if (idx > -1) { 
                    parts[idx].max += ( parseFloat(i.saisp_qty) *  this.props.worksOrder.wo_qty ) || 0;
                } else {
                    parts.push({
                        id:          i.part.part_id,
                        number:      i.part.part_number,
                        desc:        i.part.part_description,
                        img:         i.part?.default_image?.file_path,
                        max:         ( parseFloat(i.saisp_qty) *  this.props.worksOrder.wo_qty ) || 0,
                        step:        parseFloat(i.saisp_qty) > 1 ? 1 : parseFloat(i.saisp_qty),
                        notRequired
                    });
                }
            });
        });

        _.each(_.filter(this.props.worksOrder.pick.details, {wopd_status: 'Not Required'}), i => {
            _.remove(parts, {id: i.part.part_id});
        });

        _.each(_.filter(this.props?.worksOrder?.shortage?.details, {wossd_status: 'Not Required'}), i => {
            _.remove(parts, {id: i.part.part_id});
        });

        _.each(
            _.uniqBy(_.filter(this.props.worksOrder.additionals, j => {
                let instruction = _.find(this.props.worksOrder.build.stages, {wobs_id: j.wobas_wobs_id})?.instruction;
                return (this.props.stage.instruction.sais_number >= instruction.sais_number )
            }), 'wobas_part_id')
            , i => {
                let adds = parseFloat(
                    _.sumBy(
                        _.filter(this.props.worksOrder.additionals, j => {
                            let instruction = _.find(this.props.worksOrder.build.stages, {wobs_id: j.wobas_wobs_id})?.instruction;
                            return (this.props.stage.instruction.sais_number >= instruction.sais_number ) && (parseInt(j.wobas_part_id) === parseInt(i.part.part_id))
                        }), i => parseFloat(i.wobas_qty)
                    )
                );

                let idx = _.findIndex(parts, {id: i.part.part_id});
                if (idx > -1) { 
                    parts[idx].max += parseFloat(adds); 
                } else {
                    parts.push({
                        id:          i.part.part_id,
                        number:      i.part.part_number,
                        desc:        i.part.part_description,
                        img:         i.part?.default_image?.file_path,
                        step:        parseFloat(adds) > 1 ? 1 : parseFloat(adds) / parseFloat(this.props.worksOrder.wo_qty),
                        max:         parseFloat(adds),
                        notRequired: false
                    })
                }
            }
        )

        _.each(this.props.worksOrder.returns, i => {
            let instruction = _.find(this.props.worksOrder.build.stages, {wobs_id: i.wobsr_wobs_id})?.instruction;
            if (this.state.stage.instruction.sais_number >= instruction.sais_number) {
                let idx = _.findIndex(parts, {id: i.part.part_id});
                if (parts[idx]){
                    let max = parts[idx]?.max ? parts[idx]?.max - parseFloat(i.wobsr_qty) : parseFloat(i.wobsr_qty)
                    parts[idx] = {
                        ...parts[idx],
                        max,
                        step: parseFloat(max) / parseFloat(this.props.worksOrder.wo_qty)
                    }
                }
            }
        });

        this.setState({
            isLoading: false,
            parts: _.filter(parts, i => i.max !== 0),
        })
    
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value,
                part: fieldName === 'partId' ? _.find(this.state.parts, {id: selectedOption.value}) : this.state.formData.part,
                reasons: fieldName === 'partId' ? (this.state.mode === 'single' ? [initialReasonState] : []) : this.state.formData.reasons,
            }
        });
    }

    handleChange = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            }
        })
    }

    handleFileChange = index => (drop, name, event) => {
        const files = drop === true ? event.dataTransfer.files : event.target.files;
        this.setState({
            formData: {
                ...this.state.formData,
                reasons: _.map(this.state.formData.reasons, (i,idx) => idx === index ? {...i, file: i.file ? [...i.file, ...files] : files} : i)
            }
        });
    }

    handleClearFile = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                file: null,
            }
        });
    }

    handleClearFile = ridx => idx => {
        this.setState({
            formData: {
                ...this.state.formData,
                reasons: _.map(this.state.formData.reasons, (i,idx) => idx === ridx ? {
                    ...i,
                    file: _.filter(i.file, (j, jdx) => jdx !== idx)
                } : i)
            }
        });
    }

    handleSubmit = () => {
        this.setState({isLoading: true}, () => {
            let { partId, qty, reasons } = this.state.formData;
            let newFormData = new FormData();

            let quantityPer = qty / reasons.length;
            
            newFormData.append('part',        partId);
            newFormData.append('quantity',    qty);
            newFormData.append('batch',       _.find(this.state.parts,        {id:    partId}).max);
            /*
            _.each(_.filter(reasons, i => (!i.copy) || (i.copy && !i.copyFiles)), (i,idx) => {
                if (i.file && i.file.length > 0) {
                    _.each(i.file, j => {
                        newFormData.append(`files${idx}[]`, j ? j : null);
                    });
                } else {
                    newFormData.append(`files${idx}[]`, null);
                }
            })
            */
            let rr = JSON.stringify(_.map(_.filter(reasons, i => (i.copy === null) || (i.copy !== null && !i.copyFiles)), (i, idx) => {
                        let copied =  (i.copy) ? [] : _.filter(reasons, j => j.copy === idx && j.copyFiles);
                        let reasonLabel = _.find(this.state.writeOffList, {value: i.reason}).label;

                        if (i.file && i.file.length > 0) {
                            _.each(i.file, j => {
                                newFormData.append(`files${idx}[]`, j ? j : null);
                            });
                        } else {
                            newFormData.append(`files${idx}[]`, null);
                        }

                        let qty = quantityPer + ( quantityPer * copied.length );
                        let unpack = (i.copy) ? reasons[i.copy] : i

                        return { ...unpack, reasonLabel, qty, file: i?.file?.length > 0 }
                    }));
            newFormData.append('reasons', rr);
            
            API.post(`/worksOrders/${this.props.worksOrder.wo_id}/build/${this.props.worksOrder.build.wob_id}/${this.props.stage.wobs_id}/writeOff`, newFormData)
            .then(this.props.callBack)
        });
    }

    handleChangeWriteOff = e => {
        let value   = e ? e.value : 0;
        let reasons = this.state.formData.reasons;
        let i       = reasons.length;
        if (i > value) for(i; value < i; i--){ reasons.splice(-1); }
        else           for(i; value > i; i++){ reasons.push(initialReasonState); }
        
        this.setState({
            formData: {
                ...this.state.formData,
                reasons,
                qty: parseFloat(e.qty)
            }
        });
    }

    handleReasonChange = (index, name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                reasons: _.map(this.state.formData.reasons, (i,idx) => idx === index ? {...i, [name]: value} : i)
            }
        })
    }

    handleReasonCopy = index => e => {
        
        let reasons = this.state.formData.reasons;
        let reason  = reasons[index];

        reason = e ?    {...reasons[e.value], 
                            file: reason.file,
                            copy: e.value,
                            copyFiles: true,
                        } : initialReasonState;
        reasons[index] = reason;
    
        this.setState({
            formData: {
                ...this.state.formData,
                reasons
            }
        });
    }

    clearFile = index => idx => {
        this.setState({
            formData: {
                ...this.state.formData,
                reasons: _.map(this.state.formData.reasons, (i,ridx) => ridx === index ? {
                    ...i, 
                    file: _.filter(i.file, (j, jdx) => jdx !== idx)
                } : i)
            }
        })
    };

    handleCopyFiles = (index, copyFiles) => () => {
        this.setState({
            formData: {
                ...this.state.formData,
                reasons: _.map(this.state.formData.reasons, (i,ridx) => ridx === index ? {
                    ...i, 
                    copyFiles, 
                    file: copyFiles ? this.state.formData.reasons[i.copy].file : initialState.file
                } : i)
            }
        })
    }

    handleChangeMode = mode => () => {
        this.setState({
            mode,
            formData: {
                ...this.state.formData,
                reasons: mode === 'single' ? [initialReasonState] : [],
                qty: initialState.formData.qty
            }
        });
    }

    handleNumberChange = name => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: e
            }
        });
    }

    render() {

        const { isLoading, parts, formData, formErrors, writeOffList, mode } = this.state;

        if (isLoading) return ( <LoadingCircle /> )

        let part = formData.partId ? _.find(parts, {id: formData.partId}) : null; 

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <SelectPartWithQR
                        parts=      {_.map(_.filter(parts, {notRequired: false}), i => ({value: i.id, label: `${i.number} - ${i.desc}`, part: {...i, part_number: i.number}}))}
                        onChange=   {this.handleSelectChange('partId')}
                        value=      {formData.partId}
                        margin=     "none"
                        noClear
                    />
                </Grid>
                {part &&
                    <>
                        <Grid item xs={12}>
                            <ButtonGroup fullWidth >
                                <Button color={mode === 'single' && 'primary'} variant={mode === 'single' && 'contained'} onClick={this.handleChangeMode('single')} >Single Reason Write Off</Button>
                                <Button color={mode !== 'single' && 'primary'} variant={mode !== 'single' && 'contained'} onClick={this.handleChangeMode('multi')}>Multiple Reason Write Offs</Button>
                            </ButtonGroup>
                        </Grid>

                        {mode === 'single' &&
                            <Grid container xs={12}>
                                <Grid item xs={12}>
                                    <NumberField
                                        value= {formData.qty}
                                        label={`Write Off Quantity * (max: ${part.max})`}
                                        onChange= {this.handleNumberChange('qty')}
                                        inputProps= {{min: 0, max: part.max, step: part.step}}
                                    />
                                </Grid>
                                {(parseFloat(formData.qty) > 0 && parseFloat(formData.qty) <= parseFloat(part.max)) &&
                                    <>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <AutoCompleteSelect
                                                    label="Fault *"
                                                    options={writeOffList}
                                                    value={formData.reasons[0]?.reason}
                                                    onChange={(e) => this.handleReasonChange(0, 'reason', e.value)}
                                                    noClear
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Textarea
                                                id="feedback"
                                                name="notes"
                                                label="Describe The Fault *"
                                                value={formData.reasons[0]?.notes}
                                                rows={5}
                                                onChange={(e) => this.handleReasonChange(0, 'notes', e.target.value)}
                                                margin="dense"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DragFileInputMulti
                                                id="pof-file"
                                                name="file"
                                                label="Photo Of Fault"
                                                file={formData.reasons[0]?.file}
                                                errorText={formErrors && formErrors['file']}
                                                cancelOnClick={this.clearFile(0)}
                                                onChange={this.handleFileChange(0)}
                                                emptyText='No photo taken'
                                                placeholder='Photo Of Fault'
                                                capture='user'
                                            />
                                        </Grid>
                                    </>
                                }
                                {parseFloat(formData.qty) > parseFloat(part.max) &&
                                    <span style={{color: colors.red, width: '100%', textAlign: 'right'}}>You cannot return more stock than has been picked</span>
                                }
                            </Grid>
                        }
                        {mode !== 'single' &&
                            <>
                                {(part?.max / part?.step) > 0 &&
                                    <FormControl fullWidth>
                                        <AutoCompleteSelect
                                            label={`Write Off Quantity * (max: ${part.max})`}
                                            value={formData?.reasons?.length ?? 0}
                                            options={_.map(_.range(parseFloat(part.step), (part.max + parseFloat(part.step)), parseFloat(part.step)), (i, idx) => ({qty: (i).toFixed(3),value: (idx + 1), label: ` ${idx + 1} (${(i).toFixed(3)})`}))}
                                            onChange={this.handleChangeWriteOff}
                                            disableSort
                                            noClear
                                        />
                                    </FormControl>
                                }
                                <Grid container style={{marginTop: '1em'}}>
                                    {_.map(formData.reasons,(i, idx) => 
                                        <Grid item xs={12}>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{backgroundColor: (i.copy !== null ||  (i.reason && i.notes && (i.file && i.file.length > 0))) ? colors.green : colors.orange}}>
                                                    <Typography>Write Off: {idx + 1}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container xs={12}>
                                                        {i.copy == null ?
                                                            [
                                                                <Grid item xs={12}>
                                                                    <FormControl fullWidth>
                                                                        <AutoCompleteSelect
                                                                            label="Fault *"
                                                                            options={writeOffList}
                                                                            value={i.reason}
                                                                            onChange={(e) => this.handleReasonChange(idx, 'reason', e.value)}
                                                                            noClear
                                                                        />
                                                                    </FormControl>
                                                                </Grid>,
                                                                <Grid item xs={12}>
                                                                    <Textarea
                                                                        id="feedback"
                                                                        name="notes"
                                                                        label="Describe The Fault *"
                                                                        value={i.notes}
                                                                        rows={5}
                                                                        onChange={(e) => this.handleReasonChange(idx, 'notes', e.target.value)}
                                                                        margin="dense"
                                                                    />
                                                                </Grid>
                                                            ] : 
                                                            <Grid item xs={12} style={{marginTop: '1em'}}>
                                                                Copy Files?
                                                                <ButtonGroup fullWidth variant='contained' style={{marginTop: '0.5em'}}>
                                                                    <Button onClick={this.handleCopyFiles(idx, true)} style={i.copyFiles ? {backgroundColor: colors.blue, color: colors.white} : {backgroundColor: colors.disabled}}>Use write off {i.copy + 1} Files</Button>
                                                                    <Button onClick={this.handleCopyFiles(idx, false)} style={!i.copyFiles ? {backgroundColor: colors.blue, color: colors.white} : {backgroundColor: colors.disabled}}>Separate Write Off Files</Button>
                                                                </ButtonGroup>
                                                            </Grid>
                                                        }
                                                        {!i.copyFiles &&
                                                            <Grid item xs={12}>
                                                                <DragFileInputMulti
                                                                    id="pof-file"
                                                                    name="file"
                                                                    label="Photo Of Fault"
                                                                    file={i.file}
                                                                    errorText={formErrors && formErrors['file']}
                                                                    cancelOnClick={this.clearFile(idx)}
                                                                    onChange={this.handleFileChange(idx)}
                                                                    emptyText='No photo taken'
                                                                    placeholder='Photo Of Fault'
                                                                    capture='user'
                                                                />
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                    )}
                                </Grid>
                            </>
                        }
                    </>
                }
                <Grid item xs={12} className='buttonRow'>
                    <Button variant='outlined' onClick={this.props.closeDialog} >Cancel</Button>
                    <Button 
                        variant='contained' 
                        color='primary' 
                        disabled={parseFloat(formData.qty) > parseFloat(formData.part.max) || (
                            _.filter(formData.reasons, (i) => i.copy !== null || (i.reason && i.notes && (i.file && i.file.length > 0))).length !== formData.reasons.length
                        ) || formData.reasons.length === 0}
                        onClick={() => {
                            this.props.deployConfirmation('Are you sure you want to submit this write off?, The build will be stopped and cannot be resumed until write off is re-picked', 'Submit Write Off?', this.handleSubmit)
                        }}
                    >Submit</Button>
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success, variant=null) => dispatch(deployConfirmation(message, title, success, null, variant)),
        closeDialog:        ()                                      => dispatch( closeDialog() ) ,
    }
}


export default connect(null, mapDispatchToProps)(WriteOff)