import { Grid, ListItem, ListItemAvatar, Typography, List, ListItemText, ListItemSecondaryAction, Tooltip } from "@material-ui/core";
import API from "API";
import AllIcon from "Components/Common/Icons/AllIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import {
	clearPageState,
	getInitialState,
	hasPageState,
	savePageState,
} from "Functions/StatePersistenceFunctions";
import { colors } from "Helpers/ColourHelper";
import IconHelper from "Helpers/IconHelper";
import React from "react";
import _, { subtract } from "lodash";
import ImageWithError from "Components/Common/ImageWithError/ImageWithError";
import moment from "moment";
import { clenyDate } from "Helpers/TimeHelper";
import InfoIcon from "Components/Common/Icons/InfoIcon";

const SalesColumnItemItem = ({icon, data, comparitor, showRed}) => {
	return (
		<>
			<Grid item xs={2} style={{textAlign:'center'}}><AllIcon icon={icon} size='xs' /></Grid>
			<Grid item xs={6} style={{color: showRed && colors.red}}>{data < 0 ? '-' : ''}£{parseFloat(Math.abs(data)).toFixed(2)}</Grid>
			<Grid item xs={4} style={{color: showRed && colors.red}}>{ parseFloat(comparitor) !== 0 ? (parseFloat(data) !== 0 ? parseFloat(parseFloat(parseFloat(data) / parseFloat(comparitor)) * 100).toFixed(2) : '0.00') : '0.00'}%</Grid>
		</>
	)
}

const SalesColumnItem = ({name, icon, data, comparitor, info}) => {
	let _comparitor = _.isArray(comparitor) ? comparitor : [comparitor, comparitor, comparitor];
	return (
		<Grid item xs={3}>
			<PaddedPaper style={{padding: 10}}>
				<Typography variant="body2" gutterBottom>
					<Grid container >
						<Grid item>
							<b>{name}</b>
						</Grid>
						<Grid item style={{marginLeft: 'auto'}}>
							<InfoIcon info={info} />
						</Grid>
					</Grid>
				</Typography>
				<Grid container spacing={1} alignItems="center">
					<SalesColumnItemItem icon={IconHelper.parts} data={name==='Margin' ? Math.abs(data.p) : data.p} comparitor={name==='Margin' ? Math.abs(_comparitor[0]):_comparitor[0]} showRed={name==='Margin' && parseFloat(data.p) < 0}/>
					<SalesColumnItemItem icon={IconHelper.services} data={name==='Margin' ? Math.abs(data.s) : data.s} comparitor={name==='Margin' ? Math.abs(_comparitor[1]):_comparitor[1]} showRed={name==='Margin' && parseFloat(data.s) < 0}/>
					<SalesColumnItemItem icon={IconHelper.total} data={name==='Margin' ? Math.abs(data.t) : data.t} comparitor={name==='Margin' ? Math.abs(_comparitor[2]):_comparitor[2]} showRed={name==='Margin' && parseFloat(data.t) < 0}/>
				</Grid>
			</PaddedPaper>
		</Grid>
	)
}
	

const SalesColumn = ({name, icon, data, column, sub}) => {
	return (
		<Grid item xs={12}>
			<Grid container spacing={1} alignItems="center">
				<Grid item xs={12}>
					<Typography variant="h5" gutterBottom style={{borderBottom: `1px solid ${colors.disabled}`}}>
						<Grid container spacing={1} style={{alignItems: 'flex-end'}}>
							<Grid item>
								<AllIcon icon={icon} size={27} solid/>
							</Grid>
							<Grid item>
								<b>{name}</b>
							</Grid>
							<Grid item><Typography variant="body1">{sub}</Typography></Grid>
							<Grid item style={{marginLeft: 'auto'}}>
								<Typography variant="h6" >Sales Invoiced:</Typography>
							</Grid>
							<Grid item style={{color: parseFloat(data.i[column].t) < 0 && colors.red}}>
								<b>{parseFloat(data.i[column].t) < 0 ? '-' : ''}£{parseFloat(Math.abs(parseFloat(data.i[column].t))).toFixed(2)}</b>
							</Grid>
						</Grid>
					</Typography>
				</Grid>
				<SalesColumnItem name="Order Sales (Not Invoiced)" 	data={data.s[column]} comparitor={data.s[column].t} 
					info={<>
							Orders and credit notes that have not yet been invoiced (includes invoices awaiting approval).<br/><br/>
							% Percentage based on total sales yet to be invoiced.
						</>} 
				/>
				<SalesColumnItem name="Sales (Invoiced)" 			data={data.i[column]} comparitor={data.i[column].t} 
					info={<>
						Orders and credit notes that have been invoiced (does not include invoices awaiting approval).<br/><br/>
						% Percentage based on total sales that have been invoiced.
					</>} 
				/>
				<SalesColumnItem name="Discount" 					data={data.d[column]} comparitor={[data.l[column].p, data.l[column].s, data.l[column].t] } 
					info={<>
						Total discount amount in orders and credit notes that have been discounted.<br/><br/>
						% Percentage based on the average discount added to a discounted order and credit note
					</>} 
				/>
				<SalesColumnItem name="Margin" 						data={data.m[column]} comparitor={[data.i[column].p, data.i[column].s, data.i[column].t] } 
					info={<>
						Amount based on order sub total (not including vat) with costs removed (based on order date).<br/><br/>
						% Percentage based on the average margin of an order and credit note.
					</>} 
				/>
			</Grid>
		</Grid>
	)
}

const initialState = () => ({
	sales: {},
	isLoading: true,
	access: {
		'sales-reports': false
	},
	countdown: 15*60
});
class Sales extends React.Component {
	constructor(props) {
		super(props);
		this.clearPageState = clearPageState.bind(this);
		this.getInitialState = getInitialState.bind(this);
		this.hasPageState = hasPageState.bind(this);
		this.savePageState = savePageState.bind(this);
		this.persistenceId = "Dashboard:Sales";
		this.state = this.getInitialState(initialState());

		this.timeout = null;
	}

	componentDidMount(){
		if (!this.hasPageState()) {
			this.loadReports();
		} else {
			clearTimeout(this.timeout);
			this.addTimer();
		}
	}

	componentWillUnmount(){
		clearTimeout(this.timeout);
	}

	loadReports = (withLoading=true) => {
		clearTimeout(this.timeout);
		this.setState({
			isLoading: withLoading,
			countdown: 0
		}, ()=> {
			API.get('/staff/my/access/check/sales-reports').then(res => {
				this.setState({
					access: {
						'sales-reports': res.data.has_access
					}
				}, ()=> {
					API.get(`/dashboard`, {params: {
						section: 'Sales'
					}}).then(res => {
						this.setState({
							sales: res.data,
							isLoading: false,
							countdown: 15*60
						}, ()=> {
							 this.addTimer()
						})
					})
				})
			})
		})
	}   

	addTimer = () => {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(()=>this.countdown(15*60), 1000);
	}

	countdown = (num) => {
		if (num > 0) {
			this.setState({
				countdown: num
			}, ()=> {
				this.timeout = setTimeout(() => this.countdown(num - 1), 1000)
			});
		} else {
			this.setState({
				countdown: 0
			}, ()=>this.loadReports(false));
		}
	}

	render() {
		const { isLoading, sales, access } = this.state;
		const loggedInStaff =
			this.props.loggedInStaff !== undefined
				? this.props.loggedInStaff.firstName +
				" " +
				this.props.loggedInStaff.lastName
				: "";

		if (isLoading ) {
			return (
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={6} style={{marginRight: 'auto'}}>
						<Typography variant="h6" gutterBottom>
							Welcome <b>{loggedInStaff}</b>, Home Of The Perfect Pint!
						</Typography>
					</Grid>
					<Grid item>
						<AllIcon 
							icon={IconHelper.home}
							heavy
							onClick={this.props.onBack}
							noMargin
						/>
						<AllIcon 
							icon={IconHelper.refresh}
							heavy
							onClick={this.loadReports}
							noMargin
						/>
					</Grid>
					<Grid item xs={12}>
						<LoadingCircle />
					</Grid>
				</Grid>
			)
		}

		return (
			<Grid container spacing={3} alignItems="top" style={{alignItems: 'center'}}>
				<Grid item xs={6} style={{marginRight: 'auto'}}>
					<Typography variant="h6" gutterBottom>
						Welcome <b>{loggedInStaff}</b>, Home Of The Perfect Pint!
					</Typography>
				</Grid>
				<Grid item>
					<Grid container alignItems="center">
						<Grid item>
							<AllIcon 
								icon={IconHelper.home}
								heavy
								onClick={this.props.onBack}
								noMargin
								tooltip="Back to Dashboard"
							/>
						</Grid>
						<Grid item>
							{!!access['sales-reports'] &&
								<AllIcon 
									icon={IconHelper.reports}
									heavy
									link="reports/sales"
									tooltip="View Sales Reports"
									noMargin
								/>
							}
						</Grid>
						
						<Grid item>
							<AllIcon 
								icon={IconHelper.refresh}
								heavy
								onClick={()=>this.loadReports(false)}
								noMargin
								tooltip="Refresh Data"
							/>
						</Grid>
						{/* <Grid item>
							{this.state.countdown > 0 &&
								<>
									<Typography variant="caption" style={{size: , width: 50}}>
										<>Refreshing In</>
									</Typography>
									<Typography variant="body2" style={{width: 50}}>
										{parseInt(this.state.countdown/60)}:{`${parseInt((parseFloat(this.state.countdown/60) - parseInt(this.state.countdown/60)) * 60)}`.padStart(2,'0')}
									</Typography>
								</>
							}
						</Grid> */}
						
						<Grid item>
							{this.state.countdown > 0 &&
								<>
									<Typography variant="body1" style={{width: 80}}>
										in {parseInt(this.state.countdown/60)}:{`${parseInt((parseFloat(this.state.countdown/60) - parseInt(this.state.countdown/60)) * 60)}`.padStart(2,'0')}
									</Typography>
								</>
							}
							{this.state.countdown < 1 &&
								<>
									<Typography variant="body1" style={{width: 110}}>
										Refreshing ...
									</Typography>
								</>
							}
						</Grid>
						
					</Grid>
				</Grid>
				<Grid item xs={9}>
					<Grid container spacing={2} alignItems="center">
						<SalesColumn name="Day" icon={IconHelper.day} data={sales} column='d' sub={moment().format('dddd MMM Do')}/>
						<SalesColumn name="Week" icon={IconHelper.week} data={sales} column='w' sub={`${clenyDate(moment().startOf('week'))} - ${clenyDate(moment().endOf('week'))}`}/>
						<SalesColumn name="Month" icon={IconHelper.month} data={sales} column='m' sub={moment().format('MMMM')}/>
						<SalesColumn name="Year" icon={IconHelper.year} data={sales} column='y' sub={
							moment().isBefore(`${moment().format('YYYY')}-10-1`) ?
								`${moment().subtract(1, 'year').format('YYYY')}/${moment().format('YYYY')}` :
								`${moment().format('YYYY')}/${moment().add(1, 'year').format('YYYY')}`
						}/>
					</Grid>
				</Grid>
				<Grid item xs={3}>
					<Grid container spacing={2} alignItems="center">
					{/*	<Grid item xs={12}>
							<PaddedPaper style={{padding: 14}}>
								<Grid container spacing={1} alignItems="center">
									<Grid item xs={12} style={{textAlign: 'center', padding: 8}}>
										<Typography variant="body1" gutterBottom>
											<b>Most Sold Service</b>
										</Typography>
									</Grid>
									<>
										<Grid item xs={2} style={{textAlign: 'center'}}>
											<ImageWithError src={_.orderBy(sales.services, 't', 'desc')[0].p?.i} height={30} style={{maxWidth: 30}}/>
										</Grid>
										<Grid item xs={7} style={{maxHeight: 50, overflow: 'hidden'}}>
											<Typography variant="body2">{_.orderBy(sales.services, 't', 'desc')[0].p.n}</Typography>
											<Typography variant="caption">{_.orderBy(sales.services, 't', 'desc')[0].p.d}</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography variant="body2">£{parseFloat(_.orderBy(sales.services, 't', 'desc')[0].t).toFixed(2)}</Typography>
											<Typography variant="caption">{parseFloat(parseFloat(parseFloat(_.orderBy(sales.services, 't', 'desc')[0].t) / parseFloat(sales.t.y.s))*100).toFixed(2)}%</Typography>
										</Grid>
									</>
								</Grid>
							</PaddedPaper>
						</Grid> */}

						<Grid item xs={12}>
							<Grid container spacing={1} alignItems="center">	
								<Grid item xs={12}>
									<Typography variant="h5" gutterBottom style={{borderBottom: `1px solid ${colors.disabled}`}}>
										<Grid container spacing={1} style={{alignItems: 'flex-end'}}>
											<Grid item>
												<strong>Most Products Sold</strong>
											</Grid>
											<Grid item style={{marginLeft: 'auto'}}>
												{
													moment().isBefore(`${moment().format('YYYY')}-10-1`) ?
														`${moment().subtract(1, 'year').format('YYYY')}/${moment().format('YYYY')}` :
														`${moment().format('YYYY')}/${moment().add(1, 'year').format('YYYY')}`
												}
												<InfoIcon info={
													<>
														Top 5 products sold (Invoiced) <br/><br/>
														% Percentage based on total product sales that have been invoiced.
													</>
												} style={{marginLeft: 5}}/>
											</Grid>
										</Grid>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<PaddedPaper style={{padding: 10, paddingBottom: 20, paddingTop: 20}}>
										<Grid container spacing={1} alignItems="center">
											{_.map(_.orderBy(_.filter(sales.products, i => parseFloat(i.t) > 0), i => parseFloat(i.t), 'desc'), p => 
												<>
													<Grid item xs={2} style={{textAlign: 'center'}}>
														<ImageWithError src={p?.p?.i} height={20} style={{maxWidth: 30, width: 'auto'}}/>
													</Grid>
													<Grid item xs={4} style={{maxHeight: 24, overflow: 'hidden'}}>
														<Typography variant="body2">{p.p.n}</Typography>
													</Grid>
													<Grid item xs={3}>
														<Typography variant="body2">£{parseFloat(p.t).toFixed(2)}</Typography>
													</Grid>
													<Grid item xs={2}>
														<Typography variant="body2">{parseFloat(parseFloat(parseFloat(p.t) / parseFloat(sales.i.y.p))*100).toFixed(2)}%</Typography>
													</Grid>
													<Grid item xs={1}>
														<InfoIcon info={<>
															{p.p.n}<br/>
															{p.p.d}
														</>}/>
													</Grid>
												</>
											)}
											{_.filter(sales.products, i => parseFloat(i.t) > 0).length < 5 &&
												_.map(_.range(5 - _.filter(sales.products, i => parseFloat(i.t) > 0).length), (i) =>
													<>
														<Grid item xs={2} style={{textAlign: 'center', height: 33}}>
															<Typography variant="body1">{_.filter(sales.products, i => parseFloat(i.t)>0).length + (i+1)}.</Typography>
														</Grid>
														<Grid item xs={4} style={{maxHeight: 24, overflow: 'hidden'}}>
															<Typography variant="body2">-</Typography>
														</Grid>
														<Grid item xs={3}>
															<Typography variant="body2">£0.00</Typography>
														</Grid>
														<Grid item xs={3}>
															<Typography variant="body2">0.00%</Typography>
														</Grid>
													</>
												)
											}
										</Grid>
										{/* <List>
											{_.map(sales.products, p => 
												<ListItem key={p.id} compact>
													<ListItemAvatar>
														<ImageWithError src={p?.p?.i} height={25} style={{maxWidth: 25}}/>
													</ListItemAvatar>
													<ListItemText>
														<Typography variant="body2">{p.p.n}</Typography>
														<Typography variant="caption">{p.p.d}</Typography>
													</ListItemText>
													<ListItemSecondaryAction>
														<Grid container spacing={1}>
															<Grid item xs={12} style={{textAlign: 'right'}}>
																£{parseFloat(p.t).toFixed(2)}
															</Grid>
															<Grid item xs={12} style={{textAlign: 'right'}}>
																50%
															</Grid>
														</Grid>
													</ListItemSecondaryAction>
												</ListItem>
											)}
										</List> */}
									</PaddedPaper>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={1} alignItems="center">	
								<Grid item xs={12}>
									<Typography variant="h5" gutterBottom style={{borderBottom: `1px solid ${colors.disabled}`}}>
										<Grid container spacing={1} style={{alignItems: 'flex-end'}}>
											<Grid item>
												<strong>Most Services Sold</strong>
											</Grid>
											<Grid item style={{marginLeft: 'auto'}}>
												{
													moment().isBefore(`${moment().format('YYYY')}-10-1`) ?
														`${moment().subtract(1, 'year').format('YYYY')}/${moment().format('YYYY')}` :
														`${moment().format('YYYY')}/${moment().add(1, 'year').format('YYYY')}`
												}
												<InfoIcon info={
													<>
														Top 5 services sold (Invoiced) <br/><br/>
														% Percentage based on total service sales that have been invoiced.
													</>
												} style={{marginLeft: 5}}/>
											</Grid>
										</Grid>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<PaddedPaper style={{padding: 10, paddingBottom: 19, paddingTop: 20}}>
										<Grid container spacing={1} alignItems="center">
											{_.map(_.orderBy(_.filter(sales.services, i => parseFloat(i.t) > 0), i => parseFloat(i.t), 'desc'), p => 
												<>
													<Grid item xs={2} style={{textAlign: 'center'}}>
														<ImageWithError src={p?.p?.i} height={20} style={{maxWidth: 30, width: 'auto'}}/>
													</Grid>
													<Grid item xs={4} style={{maxHeight: 24, overflow: 'hidden'}}>
														<Typography variant="body2">{p.p.n}</Typography>
													</Grid>
													<Grid item xs={3}>
														<Typography variant="body2">£{parseFloat(p.t).toFixed(2)}</Typography>
													</Grid>
													<Grid item xs={2}>
														<Typography variant="body2">{parseFloat(parseFloat(parseFloat(p.t) / parseFloat(sales.i.y.s))*100).toFixed(2)}%</Typography>
													</Grid>
													<Grid item xs={1}>
														<InfoIcon info={<>
															{p.p.n}<br/>
															{p.p.d}
														</>}/>
													</Grid>
												</>
											)}
											{_.filter(sales.services, i => parseFloat(i.t) > 0).length < 5 &&
												_.map(_.range(5 - _.filter(sales.services, i => parseFloat(i.t) > 0).length), (i) =>
													<>
														<Grid item xs={2} style={{textAlign: 'center', height: 33}}>
															<Typography variant="body1">{_.filter(sales.services, i => parseFloat(i.t)>0).length + (i+1)}.</Typography> 
														</Grid>
														<Grid item xs={4} style={{maxHeight: 33, overflow: 'hidden'}}>
															<Typography variant="body2">-</Typography>
														</Grid>
														<Grid item xs={3}>
															<Typography variant="body2">£0.00</Typography>
														</Grid>
														<Grid item xs={3}>
															<Typography variant="body2">0.00%</Typography>
														</Grid>
													</>
												)
											}
										</Grid>
										{/* <List>
											{_.map(sales.products, p => 
												<ListItem key={p.id} compact>
													<ListItemAvatar>
														<ImageWithError src={p?.p?.i} height={25} style={{maxWidth: 25}}/>
													</ListItemAvatar>
													<ListItemText>
														<Typography variant="body2">{p.p.n}</Typography>
														<Typography variant="caption">{p.p.d}</Typography>
													</ListItemText>
													<ListItemSecondaryAction>
														<Grid container spacing={1}>
															<Grid item xs={12} style={{textAlign: 'right'}}>
																£{parseFloat(p.t).toFixed(2)}
															</Grid>
															<Grid item xs={12} style={{textAlign: 'right'}}>
																50%
															</Grid>
														</Grid>
													</ListItemSecondaryAction>
												</ListItem>
											)}
										</List> */}
									</PaddedPaper>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={1} alignItems="center">	
								<Grid item xs={12}>
									<Typography variant="h5" gutterBottom style={{borderBottom: `1px solid ${colors.disabled}`}}>
										<Grid container spacing={1} style={{alignItems: 'flex-end'}}>
											<Grid item>
												<strong>Top Categories</strong>
											</Grid>
											<Grid item style={{marginLeft: 'auto'}}>
												{
													moment().isBefore(`${moment().format('YYYY')}-10-1`) ?
														`${moment().subtract(1, 'year').format('YYYY')}/${moment().format('YYYY')}` :
														`${moment().format('YYYY')}/${moment().add(1, 'year').format('YYYY')}`
												}
												<InfoIcon info={
													<>
														Top 5 part categories sold (Invoiced) <br/><br/>
														% Percentage based on total sales that have been invoiced.
													</>
												} style={{marginLeft: 5}}/>
											</Grid>
										</Grid>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<PaddedPaper style={{padding: 10, paddingBottom: 21, paddingTop: 20}}>
										<Grid container spacing={1} alignItems="center">
											{_.map(_.orderBy(_.filter(sales.partCategories, i => parseFloat(i.t)>0), i => parseFloat(i.t), 'desc'), (p,idx) => 
												<>
													<Grid item xs={1} style={{textAlign: 'center'}}>
														<Typography variant="body1">{idx+1}.</Typography>
													</Grid>
													<Grid item xs={6} style={{maxHeight: 24, overflow: 'hidden'}}>
														<Typography variant="body2">{p.n}</Typography>
													</Grid>
													<Grid item xs={3}>
														<Typography variant="body2">£{parseFloat(p.t).toFixed(2)}</Typography>
													</Grid>
													<Grid item xs={2}>
														<Typography variant="body2">{parseFloat(parseFloat(parseFloat(p.t) / parseFloat(sales.i.y.t))*100).toFixed(2)}%</Typography>
													</Grid>
												</>
											)}
											{_.filter(sales.partCategories, i => parseFloat(i.t)>0).length < 5 &&
												_.map(_.range(5 - _.filter(sales.partCategories, i => parseFloat(i.t)>0).length), (i) =>
													<>
														<Grid item xs={1} style={{textAlign: 'center'}}>
															<Typography variant="body1">{_.filter(sales.partCategories, i => parseFloat(i.t)>0).length + (i+1)}.</Typography>
														</Grid>
														<Grid item xs={6} style={{maxHeight: 24, overflow: 'hidden'}}>
															<Typography variant="body2">-</Typography>
														</Grid>
														<Grid item xs={3}>
															<Typography variant="body2">£0.00</Typography>
														</Grid>
														<Grid item xs={2}>
															<Typography variant="body2">0.00%</Typography>
														</Grid>
													</>
												)
											}
										</Grid>
										{/* <List>
											{_.map(sales.products, p => 
												<ListItem key={p.id} compact>
													<ListItemAvatar>
														<ImageWithError src={p?.p?.i} height={25} style={{maxWidth: 25}}/>
													</ListItemAvatar>
													<ListItemText>
														<Typography variant="body2">{p.p.n}</Typography>
														<Typography variant="caption">{p.p.d}</Typography>
													</ListItemText>
													<ListItemSecondaryAction>
														<Grid container spacing={1}>
															<Grid item xs={12} style={{textAlign: 'right'}}>
																£{parseFloat(p.t).toFixed(2)}
															</Grid>
															<Grid item xs={12} style={{textAlign: 'right'}}>
																50%
															</Grid>
														</Grid>
													</ListItemSecondaryAction>
												</ListItem>
											)}
										</List> */}
									</PaddedPaper>
								</Grid>
							</Grid>
						</Grid>
						
						{/* <Grid item xs={12}>
							<PaddedPaper style={{padding: 13}}>
								<Grid container spacing={1} alignItems="center">
									<Grid item xs={12} style={{textAlign: 'center', padding: 8}}>
										<Typography variant="body1" gutterBottom>
											<b>Most Sold Part Categories</b>
										</Typography>
									</Grid>
									{_.map(_.orderBy(sales.partCategories, 't', 'desc'), pc => 
										<>
											<Grid item xs={9}>
												<Typography variant="body2">{pc.n}</Typography>
												<Typography variant="caption">{pc.d}</Typography>
											</Grid>
											<Grid item xs={3}>
												<Typography variant="body2">£{parseFloat(pc.t).toFixed(2)}</Typography>
												<Typography variant="caption">{parseFloat(parseFloat(parseFloat(pc.t) / parseFloat(sales.t.t.t))*100).toFixed(2)}%</Typography>
											</Grid>
										</>
									)}
								</Grid>
							</PaddedPaper>
						</Grid> */}
					</Grid>
				</Grid>
			</Grid>
		)

	}
}

export default Sales;
