import React  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import {
    Button,
    Grid,
    TextField,
    Typography,
    DialogActions,
    Link
} from '@material-ui/core';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { handleFileChange, handleDateChange, getFormData, handleSelectChange, clearValue } from 'Functions/FormFunctions';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { toggleDialog } from 'Functions/MiscFunctions';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import { downloadS3File } from 'Functions/MiscFunctions';

const initialState = () => ({
    deviceId: 0,
    device: {},
    formData: {
        type: '',
        modelNumber: '',
        serialNumber: '',
        startDate: null,
        endDate: null,
        file: null,
        supplierId: 0,
        membershipNumber: '',
        status: 'Active',
    },
    formErrors: [],
    suppList: [],
    isLoading: true,
    readOnly: false
});

class VehicleDeviceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleFileChange = handleFileChange.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
        this.getFormData = getFormData.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.clearValue = clearValue.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData(); 
    }

    loadComponentData = () => {

        const { deviceId, readOnly } = this.props;

        API.get('/suppliers/all', {
            params: {
                supplierType: 10 // Vehicle
            }
        })
        .then(suppRes => {

            let suppList = _.map(suppRes.data, (supp) => {
                return _.assign({
                    value: supp.supp_id,
                    label: supp.supp_company_name
                });
            });

            this.setState({
                isLoading: false,
                deviceId,
                readOnly,
                suppList
            },
            () => {
                if (this.state.deviceId) {
                    this.loadDeviceData();
                }
            }); 
            
        });
         
    }

    loadDeviceData = () => {
        this.setState({
            isLoading: true,
        }, 
        () => {
            API.get('/vehicles/devices/' + this.state.deviceId)
            .then(res => {
    
                let dev = res.data;
    
                this.setState({
                    ...this.state,
                    isLoading: false,
                    device: dev,
                    formData: {
                        ...this.state.formData,
                        type: dev?.type,
                        modelNumber: dev?.modelNumber,
                        serialNumber: dev?.serialNumber,
                        startDate: dev?.startDate !== '0000-00-00' ? dev.startDate : null,
                        endDate: dev?.endDate !== '0000-00-00' ? dev?.endDate : null,
                        supplierId: dev?.supplier?.id,
                        membershipNumber: dev?.membershipNumber,
                        status: dev?.status,
                    }
                });
            });
        });
    }

    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        })
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            },
        });
    }

    handleSubmit = () => {
        const { deviceId } = this.state;
        const route = deviceId ? `/vehicles/devices/${deviceId}`: `/vehicles/devices`;

        API.post(route, this.getFormData())
        .then(res => {
            if(res.data.errors && res.data.errors.length > 0) {
                this.setState({
                    formErrors: formatValidationErrors(res.data.errors)
                });
            } else {
                this.setState({
                    ...initialState(),
                }, () => {
                    this.props.deploySnackBar("success", `The device was successfully ${deviceId ? 'updated' : 'added'}`);
                    this.props.refresh();
                })
            }
        });
    }

    clearDates = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                startDate: null,
                endDate: null
            }
        });
    }

    render() {
        const { formErrors, formData, isLoading, deviceId, device, readOnly, suppList } = this.state;
        return (   
            <>   
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {readOnly ? 'Device Details' : (deviceId ? 'Update Device' : 'Add Device') }
                    </Typography>
                </Grid>     
                <Grid item xs={12}>
                    <form noValidate>
                        {(isLoading && (
                            <LoadingCircle />          
                        )) || (
                            <Grid container item spacing={3}>
                                <Grid item xs={12}>
                                    {formErrors && formErrors.generic && (
                                        <Typography component={"div"} style={{color: 'red'}}>
                                            {formErrors.generic}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <Grid container item spacing={2}>
                                                <Grid item xs={12}>
                                                    <AutoCompleteSelect 
                                                        options={[
                                                            {value: 'Sat Nav', label: 'Sat Nav'},
                                                            {value: 'Dash Cam', label: 'Dash Cam'},
                                                            {value: 'Tracker', label: 'Tracker'},
                                                        ]} 
                                                        label={"Type " + (deviceId ? '' : '*')}
                                                        onChange={v => this.handleSelectChange('type', v)}
                                                        error={formErrors && formErrors['type'] && true}
                                                        errorText={formErrors && formErrors['type']}
                                                        value={formData?.type}
                                                        noClear
                                                        fullWidth
                                                        disabled={readOnly}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField 
                                                        name="modelNumber"
                                                        label={"Model No. / Description"}
                                                        onChange={this.handleChange}
                                                        error={formErrors && formErrors['modelNumber'] && true}
                                                        helperText={formErrors && formErrors['modelNumber']}
                                                        value={formData.modelNumber}
                                                        margin="normal"
                                                        fullWidth
                                                        disabled={readOnly}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField 
                                                        name="serialNumber"
                                                        label={"Serial Number"}
                                                        onChange={this.handleChange}
                                                        error={formErrors && formErrors['serialNumber'] && true}
                                                        helperText={formErrors && formErrors['serialNumber']}
                                                        value={formData.serialNumber}
                                                        margin="normal"
                                                        fullWidth
                                                        disabled={readOnly}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AutoCompleteSelect 
                                                        options={suppList} 
                                                        label={"Company "}
                                                        onChange={v => this.handleSelectChange('supplierId', v)}
                                                        error={formErrors && formErrors['supplierId'] && true}
                                                        errorText={formErrors && formErrors['supplierId']}
                                                        value={formData?.supplierId}
                                                        noClear
                                                        fullWidth
                                                        disabled={readOnly}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField 
                                                        name="membershipNumber"
                                                        label={"Membership Number"}
                                                        onChange={this.handleChange}
                                                        error={formErrors && formErrors['membershipNumber'] && true}
                                                        helperText={formErrors && formErrors['membershipNumber']}
                                                        value={formData.membershipNumber}
                                                        margin="normal"
                                                        fullWidth
                                                        disabled={readOnly}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid container item spacing={2}>
                                                <Grid item xs={12}>
                                                    <Grid container item spacing={3}>
                                                        <Grid item xs={6}>
                                                            <DatePicker
                                                                type="date"
                                                                id="startDate"
                                                                name="startDate"
                                                                label={"Start Date"}
                                                                value={formData.startDate}
                                                                errorText={formErrors && formErrors['startDate']}
                                                                onChange={date => this.handleDateChange('startDate', date, 'YYYY-MM-DD')}
                                                                autoOk={true}
                                                                disabled={readOnly}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <DatePicker
                                                                type="date"
                                                                id="endDate"
                                                                name="endDate"
                                                                label={"End Date"}
                                                                value={formData.endDate}
                                                                errorText={formErrors && formErrors['endDate']}
                                                                onChange={date => this.handleDateChange('endDate', date, 'YYYY-MM-DD')}
                                                                autoOk={true}
                                                                disabled={readOnly}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                            {(formData.startDate || formData.endDate) && !readOnly ?
                                                                <Link className='redLink' variant="body2" style={{cursor: 'pointer', float:'right'}} onClick={() => {this.clearDates()}}>
                                                                    <div>Clear Dates</div>
                                                                </Link>
                                                            : null}
                                                        </Grid>
                                                        
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {deviceId && device?.fileUrl ?
                                                        <Link className='blueLink' variant="body2" style={{cursor: 'pointer'}} onClick={() => {downloadS3File(device?.fileUrl)}}>
                                                            <div>Download Device File</div>
                                                        </Link>
                                                    : null}
                                                    {!readOnly && (
                                                        <DragFileInput
                                                            id="file"
                                                            name="file"
                                                            label="File"
                                                            file={formData.file}
                                                            errorText={formErrors && formErrors['file']}
                                                            onChange={this.handleFileChange}
                                                            cancelOnClick={() => this.clearValue('file')}
                                                            emptyText='No file selected'
                                                        />
                                                    )}
                                                </Grid>
                                                {deviceId ?
                                                    <Grid item xs={12}>
                                                        <AutoCompleteSelect 
                                                            options={[
                                                                {value: 'Active', label: 'Active'},
                                                                {value: 'Inactive', label: 'Inactive'}
                                                            ]}
                                                            label={"Status " + (deviceId ? '' : '*')}
                                                            onChange={(v) => this.handleSelectChange('status', v)}
                                                            error={formErrors && formErrors['status'] && true}
                                                            errorText={formErrors && formErrors['status']}
                                                            value={formData.status}
                                                            fullWidth
                                                            noClear
                                                            margin="normal"
                                                            disabled={readOnly}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                        />
                                                    </Grid>
                                                : null }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} align="right">
                                    <DialogActions className='pr-0 pb-0'>
                                        <Button onClick={() => this.props.onClose()} variant="text">
                                            <AllIcon icon="times" size={15} />
                                            Close
                                        </Button>
                                        {!readOnly && (
                                            <Button onClick={() => this.props.deployConfirmation(`Are you sure you want to ${deviceId ? 'update' : 'add'} this device?`, `${deviceId ? 'Update' : 'Add'} Device?`, this.handleSubmit)}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={Object.values(formData).every(x => (x === null || x === ""))}>
                                                {deviceId ? 'Update' : 'Add'}
                                            </Button>
                                        )}
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        )}
                    </form>
                </Grid>  
            </Grid>
            </>  
        );
    }
}

const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
    }
)

export default connect(null, mapDispatchToProps)(VehicleDeviceForm);