import React from 'react';
import _ from 'lodash';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import {Dialog, DialogTitle,DialogContent, DialogActions, Typography, TextField, Grid, FormControl, Button, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import API from '../../../API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import {DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE} from 'Constants'
import { gridPageChanged, gridRowsPerPageChanged } from 'Functions/FormFunctions'
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { connect } from 'react-redux';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

import icons from 'Helpers/IconHelper';

const initialState = () => ({
    searchCriteria: {
        category: '',
        subCategory: '',
        keywords: '',
    },
    gridPagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    },
    categoryData: '',
    categoryList: {},
    subCategoryList: {},
    searchResults: {},
    access: {
        viewDocument: false,
        updateDocument: false,
        updateParts: false
    },
    formData:{
        documentId: '',
        technicalLibraryAccess: '',
        title: ''
    },
    isLoading: true,
    technicalLibraryDialogOpen: false
});
class DocumentSearch extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState         = clearPageState.bind(this);
        this.getInitialState        = getInitialState.bind(this);
        this.hasPageState           = hasPageState.bind(this);
        this.savePageState          = savePageState.bind(this);
        this.persistenceId          = 'Document:Search';
        this.state                  = this.getInitialState(initialState());
        this.gridPageChanged        = gridPageChanged.bind(this);
        this.gridRowsPerPageChanged = gridRowsPerPageChanged.bind(this);
    }

    componentDidMount(){
        !this.hasPageState() && this.loadComponentData();
    }

    loadComponentData = () => {
        this.getAccess();
        this.getCategories();
    }

    getAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/view-document'), 
            API.get('/staff/my/access/check/update-document'),
            API.get('/staff/my/access/check/update-document-parts'),
        ])
        .then(([viewRes, updateRes, partRes]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    viewDocument: (viewRes.data && viewRes.data.has_access ) || false,
                    updateDocument:  (updateRes.data && updateRes.data.has_access) || false,
                    updateParts: (partRes.data && partRes.data.has_access) || false,
                }
            });
        })
    }

    getCategories = () => {
        API.get('/documents/categories/all').then(result => {
            if(result.data) {
                var categoryList = _.map(result.data, (category) => {
                    return _.assign({
                        value: category.doc_cat_id,
                        label: category.doc_cat_name
                    });
                });
                this.setState({
                    categoryData: result.data,
                    categoryList: categoryList,
                },
                () => {
                    this.savePageState();
                    this.getSearchData();
                });
            }
        });
    };

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/documents/all' , {
                params: {
                    keywords: this.state.searchCriteria.keywords,
                    category: this.state.searchCriteria.category,
                    subCategory: this.state.searchCriteria.subCategory,
                },
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    }, ()=>{this.savePageState()})
                }
            }) 
        })
    }
    
    handleCategorySelectChange = selectedOption => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                category: selectedOption && selectedOption.value,
                subCategory: '',
            },
            gridPagination: initialState().gridPagination
        }, () => {
            this.savePageState()
            this.getSearchData()
        });
    }
        
    handleSubCategorySelectChange = selectedOption => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                subCategory: selectedOption && selectedOption.value
            },
            gridPagination: initialState().gridPagination
        }, () => {
            this.savePageState()
            this.getSearchData()
        });
    }

    handleSearchKeyword = (e) => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                keywords: e.target.value
            },
            gridPagination: initialState().gridPagination
        }, 
        () => {
            this.getSearchData();
            this.savePageState();
        });
    };

    reset = () => {
        this.clearPageState();
        this.setState({
            searchCriteria: initialState().searchCriteria,
           // gridPagination: initialState().gridPagination
        }, 
        () => {
           // this.props.history.replace();
            this.getSearchData();
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        },()=>{this.clearPageState();});
    }

    // Technical Library
    handleTechnicalLibraryOpen = rowData => {
        this.setState({
            technicalLibraryDialogOpen: true,
            formData: {
                ...this.state.formData,
                technicalLibraryAccess: rowData.doc_technical_library_access,
                documentId: rowData.doc_id,
                title: rowData.doc_title,
            }
        });
    };
    handleTechnicalLibraryClose = () => {this.setState({technicalLibraryDialogOpen: false});};
    handleTechnicalLibrarySuccess = () => {
        this.setState({ technicalLibraryDialogOpen: false });
        this.technicalLibrarySubmit();
    }
    technicalLibrarySubmit = () => {
        API.post(`/documents/${this.state.formData.documentId}/updateTechnicalLibrary`, this.state.formData)
        .then((result) => {
            if(result.data.success){
                this.getSearchData();
                this.props.deploySnackBar("success", "You have successfully updated the document");
            }
        });
    }

    render() {
        const { access, searchCriteria, categoryData, categoryList, gridPagination, formData } = this.state;
        if(searchCriteria.category) {
            const subCategory = categoryData[_.findIndex(categoryData, (c) => c.doc_cat_id === searchCriteria.category)];            
            var subCategoryList = _.map(subCategory.children, (subCat) => {
                return _.assign({
                    value: subCat.doc_cat_id,
                    label: subCat.doc_cat_name
                })
            });
        }
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Document Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect                                        
                                        options={categoryList} 
                                        label='Category *'
                                        onChange={this.handleCategorySelectChange}
                                        value={searchCriteria?.category}
                                    />
                                </FormControl>
                                <FormControl key={`sub-${searchCriteria?.category}`} margin="normal" fullWidth>
                                    <AutoCompleteSelect                                         
                                        options={subCategoryList ? subCategoryList : categoryList} 
                                        disabled={!searchCriteria?.category}
                                        label='Sub Category *'
                                        onChange={this.handleSubCategorySelectChange}
                                        value={searchCriteria?.subCategory}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={searchCriteria?.keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.reset}
                                            variant="outlined"
                                            color="default"
                                           
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key:                    'doc_id',
                                pagination:             true,
                                //page: gridPagination.page,
                                //rowsPerPage: gridPagination.rowsPerPage,
                                //gridPageChanged: this.gridPageChanged,
                                //gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                persistenceId:          this.persistenceId,
                                alternatingRowColours:  true,
                                responsiveImportance:   true,
                                isLoading:              this.state.isLoading
                            }}
                            columns={[
                                {
                                    heading: 'Category',
                                    field:   'category_name',
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Sub Category',
                                    field:   'subcategory_name',
                                    sizeToContent: true,
                                    important: true
                                },
                                {
                                    heading: 'Title',
                                    field:   'doc_title',
                                    truncate: true,
                                    important: true,
                                    main: true,
                                },
                                {
                                    heading: <>Available in <br/> Technical Library</>,
                                    field:   'doc_technical_library_access',
                                    sizeToContent: true,
                                    cellProps: rowData => {
                                        return ({
                                            style: {
                                                color: rowData.doc_technical_library_access == 'Yes' && '#009933'
                                            }
                                        });
                                    },
                                },
                                {
                                    heading: <>Associated <br/> Parts</>,
                                    field:   'parts_count',
                                    sizeToContent: true
                                },
                                {
                                    heading: <>Linked <br/> Part Files</>,
                                    field:   'picking_parts_count',
                                    sizeToContent: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Technical Library', icon: icons.bookReader, color: rowData.doc_technical_library_access == 'Yes' && '#009933', onClick: () => this.handleTechnicalLibraryOpen(rowData), disabled: rowData.doc_file_uploads == 'original_only'},
                                            {name: 'Associated Parts',  icon: icons.parts,      color: parseFloat(rowData.parts_count) > 0 && '#009933', link: { pathname: '/documents/update-parts/' + rowData.doc_id, state: {searchCriteria, gridPagination}}, disabled: !access.updateParts},
                                            {name: 'Linked Part Files', icon: icons.picking,    color: parseFloat(rowData.picking_parts_count) > 0 && '#009933', link: { pathname: '/documents/picking-parts/' + rowData.doc_id, state: {searchCriteria, gridPagination}}, disabled: !access.updateParts || rowData.doc_file_uploads == 'original_only'},
                                            {name: 'Update',            icon: icons.edit,       link: { pathname: '/documents/update/' + rowData.doc_id, state: {searchCriteria, gridPagination}}, disabled: !(access.updateDocument && (rowData.latest_update_order && rowData.latest_file && rowData.latest_update_order.duo_version > rowData.latest_file.file_version))},
                                            {name: 'View',              icon: icons.view,       link: { pathname: '/documents/view/' + rowData.doc_id, state: {searchCriteria, gridPagination}}, disabled: !access.viewDocument}
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
                <Dialog 
                    open={this.state.technicalLibraryDialogOpen} 
                    onClose={this.handleTechnicalLibraryClose} 
                    fullWidth={true} 
                    maxWidth="sm" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle>
                        {formData.title}
                    </DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth margin="normal" component="fieldset">
                            <FormLabel component="legend">Available in Technical Library?</FormLabel>
                            <RadioGroup
                                name="technicalLibraryAccess"
                                value={formData.technicalLibraryAccess}
                                onChange={this.handleChange}
                                row
                            >
                                <FormControlLabel
                                    value='No'
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value='Yes'
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleTechnicalLibraryClose} variant="outlined" color="default">Close</Button>
                        <Button onClick={this.handleTechnicalLibrarySuccess} color="primary" autoFocus variant="contained">Save</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state)),
    deploySnackBar:     (variant, msg)  => dispatch(deploySnackBar(variant, msg))
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSearch);