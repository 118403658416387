import { Box, Grid, List, ListItem, ListItemText, TextField, Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import { addKeyDown, setKeyDown, unsetKeyDown } from 'Actions/KeyDown/KeyDown';
import AllIcon from "Components/Common/Icons/AllIcon";
import { colors } from 'Helpers/ColourHelper';
import IconHelper from "Helpers/IconHelper";
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import PaddedPaper from '../Paper/PaddedPaper';

const initialState = () => ({
    expression: '',
    result: 0,
    history: [],
    showHistory: false,
})

const CalcButton = (props) => {
    const {label, onClick, style} = props;
    return (
        <Box
            className={props.class}
            style={{
                ...style
            }}
            onClick={onClick}
        >
            <Typography
                variant="h6"
            >{label}</Typography>
        </Box>
    )
}

class Calculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.focusCalculator();
        //document.addEventListener('keydown', this.handleKeyDown);
        this.props.addKeyDown(this.handleKeyDown);
        this._unsetKeyDown();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.calculatorOpen && !prevProps.calculatorOpen){
            this._setKeyDown();
            this.focusCalculator();
        } 
        if (!this.props.calculatorOpen && prevProps.calculatorOpen){
            this._unsetKeyDown();
        }
    }

    _unsetKeyDown = () => {
        if (this.props.ui.mode === 'Warehouse'){
            this.props.unsetKeyDown();
        }
    }
    _setKeyDown = () => {
        if (this.props.ui.mode === 'Warehouse'){
            this.props.setKeyDown();
        }
    }

    focusCalculator = () => {
        if (this.props.ui.mode !== 'Warehouse'){
            this.inputRef.current.focus();
        }
    }

    handleKeyDown = (event) => {
        let expression = this.state.expression;

        switch(event.keyCode) {
            case 106:
                expression = `${expression}X`;
                break;
            case 109:
            case 107:
            case 111:
            case 57:
            case 48:
            case 110:
                expression = `${expression}${event.key}`;
                break;
            case 8:
                expression =  (!this.state.expression || !this.state.result) ? '' : expression.slice(0, -1);
                break;
            default:
                if (_.isInteger(parseInt(event.key))){
                    expression = `${expression}${event.key}`;
                }
        }

        if (event.keyCode === 13) {
            if (this.state.result){
                this.setState({
                    history: [...this.state.history, [expression, this.state.result]],
                    expression: this.state.result,
                    result: null
                });
            }
        } else {
            this.setState({
                expression: expression
            }, this.getResult);
        }
        return event.preventDefault();
/*

        this.setState({
            expression: `${this.state.expression}${_.isInteger(parseInt(event.key)) ? event.key : ''}`
        });
        */
    }

    getResult = () => {
        let result = null;
        try {
            result = eval(this.state.expression.replaceAll('X','*'));
        } catch (error) {}
        this.setState({ 
            result: result ?? this.state.result
        });
    }

    buttonKeyDown = (label) => () => {
        let expression = this.state.expression;

        if (label === '=' || label === 'save') {
            if (this.state.result){
                this.setState({
                    history: [...this.state.history, [expression, this.state.result]],
                    expression: this.state.result,
                    result: null
                });
            }
        } else {
            if (label === 'C') {
                expression = '';
            } else {
                expression = `${expression}${label}`;
            }
            this.setState({
                expression: expression
            }, this.getResult);
        }
    }

    render() {
        const { expression, result, history, showHistory } = this.state;
        const { calculatorOpen, popOut } = this.props;

        let boxStyle = this.props.style ?? {};
        if (popOut){
            boxStyle = {
                backgroundColor: colors.white,
                display: calculatorOpen ? 'block' : 'none',
                position: 'fixed',
                zIndex: 9999,
                borderRadius: 5,
                ...(
                    (this.props.ui.mode === 'Warehouse') ? 
                        {
                            width: '96vw',
                            bottom: '2vh',
                            left: '2vw',
                        }:{
                            width: 300,
                            right: 10,
                            top: 80,
                        }
                ),
                ...boxStyle,
            }
        }

        return (
            <Box 
                style={boxStyle}
            >
                <PaddedPaper
                    style={{
                        backgroundColor: history.length > 11 ? `#D4AF37`: `${colors.primary}25`,
                        width: '100%',
                    }}
                    onClick={this.focusCalculator}
                >
                    <Grid container spacing={1}>
                        <link href="https://fonts.cdnfonts.com/css/calculator" rel="stylesheet"></link>
                        <Grid

                            style={{marginBottom: 0, marginLeft: 4, fontWeight: 'bold'}}
                        >CASTHIO {history.length > 11 && ' GOLD'}</Grid>
                        <Grid item xs={12}>
                            <Box
                                style={{
                                    borderRadius: 5,
                                    padding: 7,
                                    paddingTop: history.length > 0 ? 0 : 7,
                                    backgroundColor: colors.black,
                                    minHeight: 64,
                                }}
                            >
                                {history.length > 0 &&
                                    <Grid container
                                        
                                    >
                                        {showHistory &&
                                            <Grid item xs={12}>
                                                <List>
                                                    {_.map(history, i =>
                                                        <ListItem>
                                                            <ListItemText
                                                                style={{
                                                                    textAlign: 'right',
                                                                    padding: 0,
                                                                    margin: 0,
                                                                }}
                                                                primaryTypographyProps={{style:{color: colors.white}}}
                                                                secondaryTypographyProps={{style:{color: colors.disabled}}}
                                                                primary={i[1]}
                                                                secondary={i[0]}
                                                            />
                                                        </ListItem>    
                                                    )}
                                                </List>
                                            </Grid>
                                        }
                                        <Grid item style={{width: 'calc(100% - 70px)', boxShadow: '0.5px 0.5px 0.5px white', height: 1, marginTop: (this.props.ui.mode === 'Warehouse') ? 7 : 10 }} onClick={() => this.setState({showHistory: !showHistory})}>
                                        </Grid>
                                        <Grid item className={this.props.classes.historyDrop} onClick={() => this.setState({showHistory: !showHistory})}>
                                            <Typography
                                                variant="caption"
                                                style={{
                                                    color: colors.white,
                                                    textAlign: 'right',
                                                }}
                                            >
                                                History <AllIcon icon={showHistory ? IconHelper.arrowUp : IconHelper.expand} noMargin white size='xxsmall' style={{marginLeft: 5}}/>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                <TextField
                                    inputRef={this.inputRef}
                                    disableUnderline
                                    fullWidth
                                    className={this.props.classes.calcTextField}
                                    inputProps={{
                                        className: this.props.classes.calcInput,
                                        disableUnderline: true 
                                    }}
                                    InputProps={{ disableUnderline: true, readOnly: true }}
                                    value={expression}
                                    onKeyDown={this.handleKeyDown}
                                    multiline
                                />
                                <Typography
                                    variant="body2"
                                    style={{
                                        textAlign: 'right',
                                        color:colors.white,
                                    }}
                                >
                                    {(expression && result) && `Result: ${result}`}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                {_.map([
                                    'C','/', 'X', '-', 
                                    7, 8, 9, '+',
                                    4, 5, 6, '.',
                                ], i => 
                                    <Grid item xs={3}>
                                        <CalcButton class={this.props.classes.calcButton} style={{borderColor: history.length > 11 && colors.black}} onClick={this.buttonKeyDown(i)} label={i}  />
                                    </Grid>    
                                )}
                                <Grid item xs={9}>
                                    <Grid container>
                                        {_.map([
                                            1, 2, 3,
                                            0, '(', ')', 
                                        ], i => 
                                            <Grid item xs={4}>
                                                <CalcButton class={this.props.classes.calcButton} style={{borderColor: history.length > 11 && colors.black}} onClick={this.buttonKeyDown(i)} label={i} />
                                            </Grid>    
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <CalcButton 
                                    class={this.props.classes.calcButton} 
                                        style={{borderColor: history.length > 11 && colors.black, height: '100%'}} 
                                        onClick={this.buttonKeyDown('=')} 
                                        label='='
                                        color="primary"    
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </PaddedPaper>
            </Box>
        )
    }
}

const mapDespatchToProps = dispatch => ({
	addKeyDown: (func) => dispatch(addKeyDown(func,'calculator')),
	setKeyDown: () => dispatch(setKeyDown('calculator')),
	unsetKeyDown: () => dispatch(unsetKeyDown('calculator'))
});


function mapStateToProps(state){
    return {
        ui: state.ui,
    };
}

const styles = theme => ({
    calcButton: {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        padding: '0.5em',
        border: `1px solid ${colors.disabled}75`,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: `${colors.disabled}50`,
        }
    },
    calcInput: {
        textAlign: 'right',
        color:colors.green,
        fontWeight: 'bold',
        fontFamily:'Calculator',
        fontSize: 28,
        wordWrap: 'break-word',
        padding: 4
    },
    calcTextField: {
        textAlign: 'right',
        color:colors.green,
        fontWeight: 'bold',
        fontFamily:'Calculator',
    },
    historyDrop: {
        width: 70, 
        textAlign: 'right',
        '&:hover': {
            cursor: 'pointer',
        }
    }
})

export default connect(mapStateToProps, mapDespatchToProps)( withStyles(styles)(Calculator));