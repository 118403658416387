import API from 'API';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import {
    Button,
    Checkbox,
    Dialog, DialogActions, DialogContent,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControl,
    Grid,
    List, ListItem, ListItemText,
    Typography
} from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import logo from 'Assets/Images/clenaware_logo_icon.png';
import BackButton from 'Components/Common/Buttons/BackButton';

const initialState = {
    stockTake:  {},
    formData:   {
        notes: '',
        taker: 0
    },
    list: {
        staff: []
    },
    details:    [],
    access:     {},
    isLoading:  true,
    formErrors: {},
}

class ReviewStockTake extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;  
    }

    componentDidMount() { 
        this.getData(); 
        this.getAccess();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/stock-take:submit'), 
            API.get('/staff/my/access/check/stock-take:review'), 
            API.get('/staff/my/access/check/stock-take:download'), 
        ]).then(([submitRes,  reviewRes, downloadRes]) => {
            this.setState({
                access: {
                    download:   downloadRes.data.has_access || false,
                    submit:     submitRes.data.has_access   || false,
                    review:     reviewRes.data.has_access   || false,
                }
            })
        });
    }

    getData = () => {
        Promise.all([
            API.get(`/stock/take/${this.props.match.params.stId}`),
            API.get('/staff/all', {params:{active:true}})
        ]).then(([res, staffRes]) => {
            this.setState({
                stockTake: res.data,
                details:   _.map(res.data.details, i => 
                    _.assign({
                        ...i, 
                        reCheck: i.std_skip_recheck ? 0 : (i.std_threshold === 'Outside' ? 1 : 0 )
                    })),
                formData: {
                    notes: res.data.st_notes,
                    taker: res.data.st_taker_id,
                },
                lists: {
                    staff: staffRes.data,
                },
                isLoading: false,
            });
        });
    }

    handleReCheck = (id, value) => {
        this.setState({
            details: _.map(this.state.details, i => {
                if (i.std_id === id) i.reCheck = value;
                return i;
            })
        });
    }

    loadPartImage = (rowData) => {
        this.setState({ 
            partImageDialog: true,
            partImageDialogData: rowData.default_image?.file_path
        });
    };

    handleApprove = () => {
        API.post(`/stock/take/${this.state.stockTake.st_id}/complete`, this.state.formData)
        .then(res => { 
            this.props.history.push(`/stock/take`);
        })
    }

    handleRecheck = () => {
        API.post(`/stock/take/${this.state.stockTake.st_id}/reCheck`, {...this.state.formData,details: _.map(this.state.details, i => { return { id: i.std_id, part: i.std_part_id, reCheck: i.reCheck ? 1 : 0}})})
        .then(res => { 
            this.props.history.push(`/stock/take`);
        })
    }

    loadPartImage = (rowData) => {
        this.setState({ 
            partImageDialog: true,
            partImageDialogData: rowData.default_image?.file_path
        });
    };

    handleNoteChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                notes: e.target.value
            }
        })
    }

    handleChangeTaker = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                taker: e ? e.value : null,
            }
        })
    }

    render(){
        const { stockTake, formData, isLoading, access, details, lists } = this.state;

        if (isLoading) return (<LoadingCircle />);
        return (
            <Grid container xs={12} spacing={3}>
                <Grid item lg={6} xs={12}>
                    <PaddedPaper>
                        <Grid container xs={12}>
                            <Grid container item spacing={3} xs={12}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Stock Take:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                        {stockTake.full_reference}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3} xs={12}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Date:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                        {clenyDate(stockTake.st_date)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3} xs={12}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Counted By:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                        {_.find(stockTake.statuses, i=> i.sts_status === 'Approval').sts_by}
                                    </Typography>
                                </Grid>
                            </Grid>
                         {/*}   <Grid container item spacing={3} xs={12}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Counted At:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                        {clenyDate(_.find(stockTake.statuses, i=> i.sts_status === 'Approval').sts_at)}
                                    </Typography>
                                </Grid>
        </Grid>*/}
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item lg={12} xs={12}>
                    <PaddedPaper>
                        <DataTable 
                            config={{
                                key: 'std_id',
                                alternatingRowColours: true,
                                responsiveimportant: true,
                                isLoading: isLoading,
                                style: rowData => ({
                                    color: rowData.std_threshold == 'Correct' || rowData.std_skip_recheck ? `${colors.grey}` : (
                                        rowData.std_threshold == 'Outside' ? 
                                            colors.red : null
                                    )
                                })
                            }}
                            columns={[
                                {
                                    field: rowData => 
                                        <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            {rowData && rowData.part.default_image?.thumbnail_file_path ?
                                                <Button onClick={() => this.loadPartImage(rowData)}>
                                                    <img src={rowData.part.default_image?.thumbnail_file_path} alt={rowData.part.part_description} style={{maxHeight:35, maxWidth:35}} />
                                                </Button> 
                                            : 
                                                <img src={logo} alt="logo" style={{maxHeight:35, maxWidth:35}}/>
                                            }
                                        </div>,
                                    sizeToContent: true,
                                    alignment: 'center',
                                },
                                {
                                    heading:        'Number',
                                    field:          rowData => rowData.part.part_number,
                                    dataRef:        rowData => rowData.part.part_number,
                                    important:      true,
                                    sizeToContent:  true
                                },
                                {
                                    heading:    'Description',
                                    field:      rowData => rowData.part.part_description,
                                    dataRef:    rowData => rowData.part.part_description,
                                    important:  true,
                                    main:       true,
                                },  
                                {
                                    heading:        '',
                                    field:          rowData =>
                                        <AllIcon  
                                            icon={
                                                rowData.std_threshold == 'Correct' ? icons.exact :
                                                    (rowData.std_threshold == 'Outside' ? icons.outside : icons.inside)
                                            }
                                            color={
                                                rowData.std_threshold == 'Correct' ? colors.green :
                                                    (rowData.std_threshold == 'Outside' ? colors.orange : colors.red)
                                            }
                                            tooltip={rowData.std_threshold}
                                        />,
                                    important:      true,
                                    sizeToContent:  true
                                }, 
                                {
                                    heading:    'Expected Stock',
                                    field:      rowData => parseFloat(rowData.part.stock.stock_current_qty),
                                    dataRef:    rowData => parseFloat(rowData.part.stock.stock_current_qty),
                                    important:  true,
                                    main:       true,
                                    alignment: 'right'
                                },  
                                {
                                    heading:    'Counted Stock',
                                    field:      rowData => parseFloat(rowData.std_taken_stock),
                                    dataRef:    rowData => parseFloat(rowData.std_taken_stock),
                                    important:  true,
                                    main:       true,
                                    alignment: 'right'
                                },  
                                {
                                    heading:    'Difference',
                                    field:      rowData => {
                                        const diff = parseFloat(rowData.std_taken_stock) - parseFloat(rowData.part.stock.stock_current_qty);
                                        if (diff == 0) return '0';
                                        const percent = (diff / rowData.part.stock.stock_current_qty ) * 100;
                                        return `${diff > 0 ? '+' : '-'}${Math.abs(diff)} ${_.isFinite(percent) ? `(${parseInt(percent)}%)` : ''}`;
                                    },
                                    dataRef:    rowData => parseFloat(rowData.std_taken_stock) - parseFloat(rowData.part.stock.stock_current_qty),
                                    important:  true,
                                    main:       true,
                                    alignment: 'right'
                                },  
                                {
                                    heading:    'Threshold',
                                    field:      rowData => `${parseFloat(parseFloat(rowData.part.stock.stock_current_qty) - (parseFloat(rowData.part.stock.stock_current_qty) * (parseFloat(rowData.part.part_stock_threshold) / 100)))}/${parseFloat(parseFloat(rowData.part.stock.stock_current_qty) + (parseFloat(rowData.part.stock.stock_current_qty) * (parseFloat(rowData.part.part_stock_threshold) / 100)))} (${rowData.part.part_stock_threshold}%)`,
                                    dataRef:    rowData => rowData.part.part_stock_threshold,
                                    important:  true,
                                    main:       true,
                                    alignment: 'right'
                                }, 
                                {
                                    heading:    'Counted At',
                                    field:      rowData => clenyDate(rowData.std_taken_at),
                                    dataRef:    rowData => rowData.std_taken_at,
                                    important:  true,
                                    main:       true,
                                }, 
                                {
                                    heading: 'Re-Check?',
                                    field: rowData => 
                                        rowData.std_taken_stock !== null &&
                                            <Checkbox
                                                name="reCheck"
                                                checked={rowData.reCheck ? true : false}
                                                onChange={()=>{this.handleReCheck(rowData.std_id, 1)}}
                                                value={rowData.reCheck}
                                                color="primary"
                                                tabIndex="-1"
                                                disabled={rowData.std_skip_recheck}
                                            />,
                                },
                                {
                                    heading: 'Approve',
                                    field: rowData => 
                                        rowData.std_taken_stock !== null &&
                                            <Checkbox
                                                name="reCheck"
                                                checked={rowData.reCheck ? false : true}
                                                onChange={()=>{this.handleReCheck(rowData.std_id, 0)}}
                                                value={!rowData.reCheck}
                                                color="primary"
                                                tabIndex="-1"
                                                disabled={rowData.std_skip_recheck}
                                            />,
                                }, 
                            ]}
                            rows={_.orderBy(details,[i=>i.part.part_number],['asc'])}
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        {_.filter(stockTake.logs, i => i.stl_notes && i.stl_notes != "" ).length > 0 &&
                            <ExpansionPanel styles={{width:'100%'}} >
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Previous Notes</Typography> 
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <List dense={true} fullWidth style={{width: '100%'}}>
                                        {_.map(_.filter(stockTake.logs, i => i.stl_notes && i.stl_notes != "" ), (i, idx) => 
                                            <ListItem style={{borderBottom: (idx+1) < _.filter(stockTake.logs, i => i.stl_notes && i.stl_notes != "" ).length && `1px solid ${colors.disabled}`}} fullWidth>
                                                <ListItemText
                                                    style={{padding: '1em 0'}}
                                                    primary={<>{i.stl_notes}<br/><br/></>}
                                                    secondary={`${i.stl_staff_name} - ${clenyDate(i.stl_datetime)}`}
                                                />
                                            </ListItem>
                                        )}
                                    </List>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        }
                        <Textarea
                            id="notes"
                            name="notes"
                            label='Add Stock Take Notes'
                            value={formData.notes}
                            onChange={this.handleNoteChange}
                            noMargin
                        />
                    </PaddedPaper>
                </Grid>
                {_.find(details, i => i.reCheck) &&
                    <Grid item xs={12} lg={12}>
                        <PaddedPaper>
                            <FormControl fullWidth margin="none">
                                <AutoCompleteSelect 
                                    options={_.map(lists.staff, i => _.assign({
                                        value: i.staff_id,
                                        label: `${i.staff_first_name} ${i.staff_last_name}`
                                    }))}
                                    label='Staff To Recheck Stock *'
                                    onChange={this.handleChangeTaker}
                                    value={formData.taker}
                                />
                            </FormControl>
                        </PaddedPaper>
                    </Grid>
                }
                <Grid item lg={12} xs={12}>
                    <PaddedPaper>
                        <div className='buttonRow'>
                            <BackButton />
                            { _.find(details, i => i.reCheck) ?
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={()=>{this.props.deployConfirmation('All selected items will be added to a new stock take', 'Create Stock Recheck?',this.handleRecheck)}}
                                >Re-check Selected</Button> :
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={()=>{this.props.deployConfirmation('Are you sure you want approve this stock take', 'Approve Stock Take?',this.handleApprove)}}
                                >Approve & Commit</Button>
                            }
                        </div>
                    </PaddedPaper>
                </Grid>
                <Dialog
                    open={this.state.partImageDialog}
                    onClose={this.handlePartImageDialogClose}
                    scroll="body"
                    disableBackdropClick	
                    disableEscapeKeyDown
                >                
                    <DialogContent>
                        <ImageWithError src={this.state.partImageDialogData} alt='Part Image' style={{maxWidth: 500}}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{
                            this.setState({partImageDialog: false})
                        }} variant="outlined" color="default">Close</Button>
                    </DialogActions>
                </Dialog> 
            </Grid>
        )
    }

}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                               => dispatch(closeDialog()),
    deployDialog:       (content, title, variant, size)  => dispatch(deployDialog(content, title, variant,size)),
    deployConfirmation: (message, title, success)        => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)                   => dispatch(deploySnackBar(variant, msg)),
})

export default connect(null, mapDispatchToProps)(ReviewStockTake);