import API from 'API';
import _, { truncate } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import BackButton from 'Components/Common/Buttons/BackButton';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import { FullScreenDialog } from 'Components/Common/Dialogs/FullScreenDialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import InfoIcon from 'Components/Common/Icons/InfoIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SelectPartWithQR from 'Components/Common/Selects/SelectPartWithQR';
import ViewPart from 'Components/Parts/ViewPart/ViewPart';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import { Button, Checkbox, Grid, TextField, Typography } from '@material-ui/core/';

import logo from 'Assets/Images/clenaware_logo_icon.png';

const initialState = {
    isLoading: true,
    formData: {
        part:  null,
        qty:   1,
        notes: null,
        globalWriteOff: []
    },
    subAssemblies: [],
    subAssembly:   {},
    details:       [],
    viewPartDialog: {
        open: false,
        part: null,
    }
}


class NewWorksOrder extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount(){
        this.getSearchData();
        if (this.props.match.params.saId) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    part: this.props.match.params.saId
                }
            }, () => {
                this.getSubAssembly();
            })
        }
    }

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/subAssemblies/all', {params:{for: 'worksOrder'}}).then(result => {
                if(result?.data) {
                    this.setState({
                        ...this.state,
                        subAssemblies: _.filter(result.data, i => !i.part_locked),
                        isLoading: false
                    });
                }
            });
        })
    }

    handleSelectChange = name => event => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: event?.value
            },
            subAssembly: initialState.subAssembly,
            details:     initialState.details
        },this.getSubAssembly);
    }

    handleChange = name => event => {
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: event.target.value
            }
        });
    }

    getSubAssembly = () => {
        this.state.formData.part && 
        API.get(`/subAssemblies/${this.state.formData.part}`, {params:{for: 'worksOrder'}}).then(result => {
            let globalWriteOff = {};
            /*
                _.each(result.data.instruction.stages, i => {
                    globalWriteOff[i.sais_id] = i.sais_global_write_off;
                })
            */
            this.setState({
                ...this.state,
                subAssembly: result.data,
                details:     _.map(result.data.sub_assembly_parts, i => {
                    const pendingStock = 
                        parseFloat(i.part.stock.stock_pending_parts_order) +
                        parseFloat(i.part.stock.stock_pending_works_order) +
                        parseFloat(i.part.stock.stock_pending_sales) +
                        parseFloat(i.part.stock.stock_pending_stock_change) +
                        parseFloat(i.part.stock.stock_pending_customer_returns) +
                        parseFloat(i.part.stock.stock_pending_supplier_stock_return);

                    return {
                        part:                       i.part.part_id,
                        number:                     i.part.part_number,
                        desc:                       i.part.part_description,
                        img:                        i.part.default_image?.thumbnail_file_path,
                        locked:                     i.part.part_locked,

                        toBeDelivered:              parseFloat(i.part.stock.stock_pending_parts_order),
                        inWorksOrder:               parseFloat(i.part.stock.stock_pending_works_order),
                        inSales:                    parseFloat(i.part.stock.stock_pending_sales) -  parseFloat(i.part.stock.stock_pending_customer_returns),
                        inMovement:                 parseFloat(i.part.stock.stock_pending_stock_change),
                        toReturn:                   parseFloat(i.part.stock.stock_pending_supplier_stock_return),
                        pendingStock:               pendingStock,

                        available:                  parseFloat(i.part.stock.stock_current_qty),
                        predicted:                  parseFloat(i.part.stock.stock_current_qty) + pendingStock,
                        required:                   parseFloat(i.sa_qty),

                        maxBuildableCurrent:        Math.floor( ( ( parseFloat(i.part.stock.stock_current_qty) + pendingStock ) - parseFloat(i.part.stock.stock_pending_parts_order) ) / parseFloat( i.sa_qty ) ),
                        maxBuildablePredicted:      Math.floor( ( parseFloat(i.part.stock.stock_current_qty) + pendingStock ) / parseFloat( i.sa_qty ) ),   
                    }   
                }),
                formData: {
                    ...this.state.formData,
                    globalWriteOff,
                }
            })
        });
    }

    handleSubmit = () => {

        let _formData = new FormData();
        _.each(_.keys(this.state.formData), key => {
            if (key === 'globalWriteOff'){
                _formData.append(key, JSON.stringify(this.state.formData[key]));
            } else if (key === 'notes'){
                _formData.append(key, this.state.formData[key] ?? '');
            } else {
                _formData.append(key, this.state.formData[key]);
            }
        });

        API.post('/worksOrders', _formData).then(result => {
            if(result?.data)window.location.href = `/WorksOrders/active`;
        });

    }

    handleSubSubAssembly = (part, qty) => {
        this.setState({
            formData: {
                ...initialState.formData,
                part: _.find(this.state.subAssemblies, s => s.sa_part_id === part)?.sa_id,
                qty
            }
        }, ()=> {
            this.setState({
                subAssembly: initialState.subAssembly,
                details:     initialState.details
            },this.getSubAssembly);
        });
    }

    closeViewPartDialog = () => {
        this.setState({ viewPartDialog: initialState.viewPartDialog });
    }

    handleGlobalWriteOff = id => () => {
        this.setState({
            formData: {
                ...this.state.formData,
                globalWriteOff: {
                    ...this.state.formData.globalWriteOff,
                    [id]: !this.state.formData.globalWriteOff[id]
                }
            }
        })
    }

    render(){

        const { isLoading, subAssemblies, subAssembly, formData, details, viewPartDialog } = this.state;

        if (isLoading) return (<LoadingCircle />);
        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Works Order
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <form noValidate autoComplete="off">
                            <Typography variant="h6" gutterBottom>
                                Select Sub Assembly
                            </Typography>
                            <SelectPartWithQR
                                parts=      {_.map(subAssemblies, el => ({
                                    value: el.sa_id,
                                    label: el.part_number + ' - ' + el.part_description,
                                    part:  {part_number: el.part_number}
                                }))}
                                onChange=   {this.handleSelectChange('part')}
                                value=      {formData.part}
                                margin=     "none"
                                label=      "Sub Assembly *"
                            />
                            <TextField
                                fullWidth
                                type=       'number'
                                margin=     'normal'
                                label=      'Build Quantity *'
                                value=      {formData.qty}
                                onChange=   {this.handleChange('qty')}
                                inputProps= {{min: 1}}
                            />
                        </form>
                    </PaddedPaper>
                </Grid>
                {!!formData.part &&
                    <>
                        {details.length == 0 ?  
                            <LoadingCircle/> :
                            <>
                                <Grid item xs={12} lg={12}>
                                    <PaddedPaper style={{marginBottom:0, padding: 17}}>
                                        <Grid container spacing={2} xs={12} style={{justifyContent: 'space-between'}}>
                                            <Grid item container xs={6} spacing={2} style={{justifyContent: 'flex-start', alignItems: 'center'}}>
                                                <Grid item>
                                                    {subAssembly && subAssembly.part?.default_image?.thumbnail_file_path ?
                                                        <img src={subAssembly.part?.default_image?.thumbnail_file_path} alt={subAssembly.part.part_description} style={{maxHeight:50, maxWidth:50}} />
                                                    : 
                                                        <img src={logo} alt="logo" style={{maxHeight:50, maxWidth:50}}/>
                                                    }
                                                </Grid>
                                                <Grid item >
                                                    Sub Assembly:
                                                    <Typography variant='h5' style={{textAlign: 'left', marginTop:'auto'}}>{subAssembly.part?.part_number} - {subAssembly.part?.part_description}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={6} spacing={2} style={{justifyContent: 'center'}}>
                                                <Grid item container xs={12} spacing={2} style={{justifyContent: 'space-evenly'}}>
                                                    <Grid item>
                                                        Required <InfoIcon info='Number intended to be built'/>
                                                        <Typography variant='h5' style={{textAlign: 'center'}}>{formData.qty}</Typography>
                                                    </Grid>
                                                    <Grid item >
                                                        <br/>
                                                        <AllIcon size='large' icon={'slash-forward'} noMargin />
                                                    </Grid>
                                                    <Grid item>
                                                        Buildable Now <InfoIcon info='Predicted stock not including to be delivered'/>
                                                        <Typography variant='h5' style={{textAlign: 'center', color: _.minBy(details, 'maxBuildableCurrent')?.maxBuildableCurrent < formData.qty ? colors.red : colors.green}}>
                                                            {_.minBy(details, 'maxBuildableCurrent')?.maxBuildableCurrent < formData.qty ?
                                                                ( _.minBy(details, 'maxBuildableCurrent')?.maxBuildableCurrent > 0 ? _.minBy(details, 'maxBuildableCurrent')?.maxBuildableCurrent : 0)
                                                                : formData.qty
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item >
                                                        <br/>
                                                        <AllIcon size='large' icon={'slash-forward'} noMargin />
                                                    </Grid>
                                                    <Grid item>
                                                        Buildable In Future <InfoIcon info='Predicted stock'/>
                                                        <Typography variant='h5' style={{textAlign: 'center', color: _.minBy(details, 'maxBuildablePredicted').maxBuildablePredicted < formData.qty ? colors.red : colors.green}}>
                                                            {_.minBy(details, 'maxBuildablePredicted').maxBuildablePredicted < formData.qty ?
                                                                ( _.minBy(details, 'maxBuildablePredicted').maxBuildablePredicted > 0 ? _.minBy(details, 'maxBuildablePredicted').maxBuildablePredicted : 0)
                                                                : formData.qty
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={12} spacing={2} style={{justifyContent: 'space-evenly'}}>
                                                    <Grid item>
                                                        Active Works Orders <InfoIcon info='Works orders currently in progress'/>
                                                        <Typography variant='h5' style={{textAlign: 'center'}}>{parseFloat(subAssembly?.part?.stock?.stock_pending_works_order)}</Typography>
                                                    </Grid>
                                                    <Grid item >
                                                        <br/>
                                                        <AllIcon size='large' icon={'slash-forward'} noMargin />
                                                    </Grid>
                                                    <Grid item>
                                                        Maximum Buildable <InfoIcon info='Max buildable based on predicted stock not including to be delivered'/>
                                                        <Typography variant='h5' style={{textAlign: 'center'}}>{_.minBy(details, 'maxBuildableCurrent')?.maxBuildableCurrent > 0 ? _.minBy(details, 'maxBuildableCurrent')?.maxBuildableCurrent : 0}</Typography>
                                                    </Grid>
                                                    <Grid item >
                                                        <br/>
                                                        <AllIcon size='large' icon={'slash-forward'} noMargin />
                                                    </Grid>
                                                    <Grid item>
                                                        Maximum Future Buildable <InfoIcon info='Max buildable based on predicted stock'/>
                                                        <Typography variant='h5' style={{textAlign: 'center'}}>{_.minBy(details, 'maxBuildablePredicted').maxBuildablePredicted > 0 ? _.minBy(details, 'maxBuildablePredicted').maxBuildablePredicted : 0}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <DataTable
                                            config={{
                                                key: 'sa_id',
                                            }}
                                            columns={[
                                                {
                                                    field: 'img',
                                                    fieldFormat: 'image',
                                                    sizeToContent: true,
                                                    alignment: 'center',
                                                },
                                                {
                                                    heading:       'Part',
                                                    field:         rowData => rowData.number,
                                                    important:     true,
                                                    sizeToContent: true,
                                                    dataRef:       'number',
                                                },
                                                {
                                                    heading:   'Description',
                                                    field:     'desc',
                                                    important: true,
                                                    main:      true,
                                                    truncate:  true,
                                                    dataRef:   'desc',
                                                },  
                                                {
                                                    heading:       'Locked',
                                                    field:         rowData => !!rowData.locked &&
                                                        <AllIcon 
                                                            icon=   {icons.lock}
                                                            color=  {colors.red}
                                                            tooltip={'Part Is Locked'}
                                                        />,
                                                    important:     true,
                                                    main:          true,
                                                    sizeToContent: true,
                                                    alignment:     'center',
                                                    dataRef:       'locked',
                                                    truncate:  true,
                                                },
                                                {
                                                    heading:   <>Stock<br/>Missing<br/></>,
                                                    field:         rowData => (parseFloat(rowData.available) - parseFloat(rowData.required * formData.qty)) < 0 &&
                                                        <AllIcon 
                                                            icon=   {icons.warning}
                                                            color=  {rowData.predicted > parseFloat(rowData.required * formData.qty) ? colors.orange : colors.red}
                                                            tooltip={rowData.predicted > parseFloat(rowData.required * formData.qty) ? 'Requires Pending Stock' : 'Missing Required Stock'}
                                                            noMargin
                                                        />,
                                                    important:     true,
                                                    main:          true,
                                                    sizeToContent: true,
                                                    truncate:  true,
                                                    alignment:     'center',
                                                    dataRef:       rowData => (parseFloat(rowData.available) < parseFloat(rowData.required * formData.qty)) ? 
                                                                                (rowData.predicted > parseFloat(rowData.required * formData.qty) ? 1 : 2)
                                                                                : 0,
                                                },
                                                {
                                                    heading:   <>Required<br/>Stock<br/></>,
                                                    field:     rowData => parseFloat(rowData.required * formData.qty),
                                                    important: true,
                                                    main:      true,
                                                    truncate:  true,
                                                    alignment: 'right',
                                                    dataRef:   rowData => parseFloat(rowData.required * formData.qty),
                                                    fieldFormat: 'redMinus:decimal:3',
                                                },
                                                {
                                                    heading:   <>Current<br/>Stock<br/></>,
                                                    field:     rowData => parseFloat(rowData.available),
                                                    dataRef:   rowData => parseFloat(rowData.available),
                                                    important: true,
                                                    main:      true,
                                                    truncate:  true,
                                                    alignment: 'right',
                                                    style:     {borderRight: `1px solid #ededed`, borderLeft: `1px solid #ededed`, fontWeight: 'bold'},
                                                    fieldFormat: 'redMinus:decimal:3',
                                                },
                                                {
                                                    heading:   <>Resulting<br/>Stock<br/></>,
                                                    field:     rowData => parseFloat(rowData.available) - parseFloat(rowData.required * formData.qty),
                                                    dataRef:   rowData => parseFloat(rowData.available) - parseFloat(rowData.required * formData.qty),
                                                    important: true,
                                                    main:      true,
                                                    truncate:  true,
                                                    style:     rowData => ({
                                                                    color: parseFloat(rowData.available) - parseFloat(rowData.required * formData.qty) < 0 && colors.red
                                                               }),
                                                    alignment: 'right',
                                                    fieldFormat: 'redMinus:decimal:3',
                                                }, 
                                                {
                                                    heading:   <>Pending<br/>Delivery<br/></>,
                                                    field:     rowData => parseFloat(rowData.toBeDelivered),
                                                    dataRef:   rowData => parseFloat(rowData.toBeDelivered),
                                                    important: true,
                                                    main:      true,
                                                    truncate:  true,
                                                    fieldFormat: 'redMinus:decimal:3',
                                                    alignment: 'right',
                                                },
                                                {
                                                    heading:   <>Pending<br/>Sales<br/></>,
                                                    field:     rowData => parseFloat(rowData.inSales),
                                                    dataRef:   rowData => parseFloat(rowData.inSales),
                                                    important: true,
                                                    truncate:  true,
                                                    main:      true,
                                                    fieldFormat: 'redMinus:decimal:3',
                                                    alignment: 'right',
                                                },  
                                                {
                                                    heading:   <>Pending<br/>Movement<br/></>,
                                                    field:     rowData => parseFloat(rowData.inMovement),
                                                    dataRef:   rowData => parseFloat(rowData.inMovement),
                                                    important: true,
                                                    truncate:  true,
                                                    main:      true,
                                                    fieldFormat: 'redMinus:decimal:3',
                                                    alignment: 'right',
                                                },  
                                                {
                                                    heading:   <>Pending<br/>Works Order<br/></>,
                                                    field:     rowData => parseFloat(rowData.inWorksOrder),
                                                    dataRef:   rowData => parseFloat(rowData.inWorksOrder),
                                                    important: true,
                                                    main:      true,
                                                    truncate:  true,
                                                    fieldFormat: 'redMinus:decimal:3',
                                                    alignment: 'right',
                                                },  
                                                
                                                {
                                                    heading:   <>Predicted<br/>Stock<br/></>,
                                                    field:     rowData => parseFloat(rowData.predicted),
                                                    dataRef:   rowData => parseFloat(rowData.predicted),
                                                    important: true,
                                                    truncate:  true,
                                                    main:      true,
                                                    fieldFormat: 'redMinus:decimal:3',
                                                    alignment: 'right',
                                                    style:     {borderRight: `1px solid #ededed`, borderLeft: `1px solid #ededed`, fontWeight: 'bold',},
                                                },
                                                {
                                                    heading:   <>Resulting<br/>Predicted Stock<br/></>,
                                                    field:     rowData => parseFloat(rowData.predicted) - parseFloat(rowData.required * formData.qty),
                                                    dataRef:   rowData => parseFloat(rowData.predicted) - parseFloat(rowData.required * formData.qty),
                                                    important: true,
                                                    truncate:  true,
                                                    main:      true,
                                                    fieldFormat: 'redMinus:decimal:3',
                                                    style:     rowData => ({
                                                                   fontWeight: 'bold',
                                                                   color: parseFloat(rowData.predicted) - parseFloat(rowData.required * formData.qty) < 0 && colors.red
                                                               }),
                                                    alignment: 'right'
                                                }, 
                                                {
                                                    actions: rowData => {
                                                        return _.find(subAssemblies, s => s.sa_part_id === rowData.part) ?
                                                            [
                                                                {name: 'View Part',          icon: icons.search,     onClick: () => { this.setState({viewPartDialog: { open: true, part: rowData.part }}) } },
                                                                {name: 'Create Works Order', icon: icons.worksOrder, onClick: () => {this.handleSubSubAssembly(rowData.part,  parseFloat(rowData.required * formData.qty))}},
                                                            ] : [
                                                                {name: 'View Part',          icon: icons.search,     onClick: () => { this.setState({viewPartDialog: { open: true, part: rowData.part }}) } }
                                                            ];
                                                    }
                                                }
                                            ]}
                                            rows={_.orderBy(details, 'number', 'asc')}
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <DataTable
                                            config={{
                                                key: 'sais_id'
                                            }}
                                            columns={[
                                                {
                                                    heading:   'Stage',
                                                    field:     'sais_number',
                                                    sizeToContent: true,
                                                    alignment: 'center',
                                                },
                                                {
                                                    heading:   'Name',
                                                    field:     'sais_name',
                                                },
                                                {
                                                    heading:   'Parts',
                                                    field:     'parts',
                                                    fieldFormat: 'count',
                                                    alignment: 'center',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Global Write Off',
                                                    sizeToContent: true,
                                                    alignment: 'center',
                                                    field: i => <>
                                                        <Checkbox
                                                            checked={formData.globalWriteOff[i.sais_id]}
                                                            color="primary"
                                                            onChange={this.handleGlobalWriteOff(i.sais_id)}
                                                        />
                                                    </>
                                                }
                                            ]}
                                            rows={_.orderBy(subAssembly.instruction.stages, 'sais_number')}
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Textarea
                                            label="Notes"
                                            value={formData.notes}
                                            onChange={this.handleChange('notes')}
                                        />
                                    </PaddedPaper>
                                </Grid>
                                {!!_.find(details, i => i.locked) &&
                                    <Grid item container xs={12} style={{justifyContent: 'flex-end'}}>
                                        <Grid item style={{color: colors.red}}>
                                            Sub assembly uses a locked part
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item container xs={12} style={{justifyContent: 'flex-end'}}>
                                    <Grid item>
                                        <BackButton body='Cancel'/>
                                    </Grid>
                                    <Grid item style={{paddingLeft: '2em'}}>
                                        <Button 
                                            variant='contained' 
                                            color='primary'
                                            onClick={() => {
                                                this.props.deployConfirmation('Are you sure you want to create this works order?', 'Create Works Order', this.handleSubmit)
                                            }}
                                            disabled={_.find(details, i => i.locked)}
                                        >Create Works Order</Button>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </>
                }
                {/* VIEW PART DIALOG */}
                {viewPartDialog.part &&
                    <FullScreenDialog
                        open={viewPartDialog.open}
                        onClose={this.closeViewPartDialog}
                        title="Part Information"
                        content={<ViewPart id={viewPartDialog.part} />}
                    />}
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                               => dispatch(closeDialog()),
    deployDialog:       (content, title, variant, size)  => dispatch(deployDialog(content, title, variant,size)),
    deployConfirmation: (message, title, success)        => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)                   => dispatch(deploySnackBar(variant, msg)),
})

export default connect(null, mapDispatchToProps)(NewWorksOrder);