import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../Common/Paper/PaddedPaper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { Component } from 'react';

const styles = theme => ({
    tblHeader: {
        backgroundColor:  '#f2f2f2',
    },
    tblFooter: {
        fontWeight: 'bold'
    }
});
class DeliverySchedule extends Component {
    render() {
        const { classes, ppo } = this.props;
        return (
            <PaddedPaper>
                <Grid container spacing={3} style={{marginBottom: 25}}>
                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Company Name
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_supplier_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Address
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_supplier_address && ppo.ppo_supplier_address.split('\n').map((item, key) => {
                                        return <span key={key}>{item}<br/></span>
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    FAO
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_supplier_contact}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Reference
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.full_reference}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Delivery Schedule Version
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    v{ppo.ppo_schedule_version}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Parts Order Date
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {moment(ppo.ppo_date).format("DD/MM/YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Order Date
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {moment(ppo.ppo_order_date).format("DD/MM/YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Order Placed By
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_placed_by}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Payment Terms
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_supplier_payment_terms}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                                <TableRow className={classes.tblHeader}>
                                    <TableCell>Part Number</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell align="right">Quantity</TableCell>
                                    <TableCell align="right">Total Price</TableCell>
                                    <TableCell align="right">Delivery Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {ppo.parts_order_details.map((row, idxx) => {
                                if(row.delivery_schedule.length > 0) {
                                    return(
                                        <React.Fragment key={idxx}>
                                            {row.delivery_schedule.map((ds, idx) => {
                                                let schedule_delivery_date = ds.schedule_delivery_date === '0000-00-00' ? 'Supplier to Confirm' : moment(ds.schedule_delivery_date).format("DD/MM/YYYY");
                                                return (
                                                    <TableRow key={ds.schedule_id}>
                                                        <TableCell>{idx === 0 && row.ppo_detail_part_number}</TableCell>
                                                        <TableCell>{idx === 0 && row.ppo_detail_description}</TableCell>
                                                        <TableCell align="right">{parseFloat(ds.schedule_quantity).toFixed(2)}</TableCell>
                                                        <TableCell align="right">£{ds.schedule_price}</TableCell>
                                                        <TableCell align="right">{schedule_delivery_date}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell align="right" className={classes.tblFooter}>Total</TableCell>
                                                <TableCell align="right" className={classes.tblFooter}>{parseFloat(row.schedule_total_qty).toFixed(2)}</TableCell>
                                                <TableCell align="right" className={classes.tblFooter}>£{row.schedule_total_price}</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    )
                                } else {
                                    return null
                                }
                            })}
                            </TableBody>
                        </Table>    
                    </Grid>
                </Grid>
            </PaddedPaper>
        );
    }
}

export default (withStyles(styles)(DeliverySchedule));