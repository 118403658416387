import React       from 'react';
import API         from 'API';
import { connect } from 'react-redux';
import moment      from 'moment';

import { TextField, Grid, Button, Typography } from '@material-ui/core';

import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import DragFileInput      from 'Components/Common/Inputs/DragFileInput';
import ImageWithError     from 'Components/Common/ImageWithError/ImageWithError';
import BackButton         from 'Components/Common/Buttons/BackButton';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';

import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = {
        formData: {
            categoryName: '',
            categoryImage: ''
        },
        category: {},
        formErrors:[],
        confirmationOpen: false,
        isLoading: true
    }

class UpdateEcommerceProductCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getCategory(this.props.match.params.id);
    }

    submit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            newFormData.append(key, this.state.formData[key])  
        });
        API.post(`/e-commerce/productCategories/${this.props.match.params.id}`, newFormData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState
                });
                this.props.deploySnackBar("success", "You have successfully updated this product category")
                this.getCategory(this.props.match.params.id);
            }
            this.props.scrollToTop();
        });
    }
    
    getCategory = (catId) => {
        this.setState({
            isLoading: true
        }, () => {
            API.get(`/e-commerce/productCategories/${catId}`)
            .then((result) => {
                this.setState({
                    ...this.state,
                    formData: {
                    ...this.state.formData,
                        categoryName: result.data.ecom_cat_name
                    },
                    category: result.data,
                    isLoading: false
                });
            })
        })
    }
    
    handleChange = (e) => {
        this.setState({
            formData: {
            ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
    
    handleConfirmationOpen = () => {
        this.setState({ confirmationOpen: true });
    };

    handleConfirmationClose = () => {
        this.setState({ confirmationOpen: false });
    };

    handleConfirmationSuccess = () => {
        this.setState({ confirmationOpen: false });
        this.submit();
    }

    clearDocument = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: initialState.formData.name
            }
        });
    };

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    render() {
        const { formErrors, formData, category } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Product Category
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Update Product Category
                            </Typography>
                            <form noValidate autoComplete="off">
                                <TextField
                                    id="categoryName"
                                    name="categoryName"
                                    label="Category Name *"
                                    value={formData.categoryName}
                                    error={formErrors && formErrors['categoryName'] && true}
                                    helperText={formErrors && formErrors['categoryName']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <br />
                                <Grid container item spacing={3}>
                                    <Grid item xs={3}>
                                        <Typography variant="body2" gutterBottom>
                                            Current Image
                                        </Typography>
                                        <ImageWithError alt='Category Image' style={{height:'auto', maxHeight: 120, maxWidth: '100%'}} src={category.image_url + '?' + moment().format("HH:mm:ss")} />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <DragFileInput
                                            id="categoryImage"
                                            name="categoryImage"
                                            label="New Image File (.jpg, .jpeg, .tiff, .tif, .gif, .png)"
                                            file={formData.categoryImage}
                                            errorText={formErrors && formErrors['categoryImage']}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={this.clearDocument('categoryImage')}
                                            emptyText='No image selected'
                                        />
                                    </Grid>
                                </Grid>
                            
                                <div className='buttonRow'>
                                    <BackButton props={this.props} />
                                    <Button 
                                        onClick={this.handleConfirmationOpen}
                                        variant="contained" 
                                        color="primary"
                                    >
                                        Update
                                    </Button>
                                </div>
                                <ConfirmationDialog 
                                    open={this.state.confirmationOpen} 
                                    success={this.handleConfirmationSuccess} 
                                    close={this.handleConfirmationClose} 
                                    title="Update Product Category?" 
                                    message="Are you sure you want to update this product category?"
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, msg) => {
            dispatch(deploySnackBar(variant, msg))
        }
    }
}

export default connect(null, mapDispatchToProps)(UpdateEcommerceProductCategory);