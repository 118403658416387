import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { Link, Redirect } from 'react-router-dom';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import API from '../../../API';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon from '../../Common/Icons/FontAwesome/FASolidIcon';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from '../../Common/Selects/AutoCompleteMultiSelect';
import SnackBar from '../../Common/SnackBars/SnackBar';
import { ssrActivityLog } from './../../../Helpers/ColourHelper';
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import SupplierStockReturn from './../SupplierStockReturn';
import './../../../Assets/timeLine.css';
import DataTable from '../../Common/DataTables/DataTable';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import { withStyles } from '@material-ui/styles';
import { MAX_UPLOAD_LIMIT_SIZE } from './../../../Constants';
import Textarea from '../../Common/Inputs/Textarea';
import MaterialLink from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton  from '@material-ui/core/IconButton';
import ImageWithError from '../../Common/ImageWithError/ImageWithError';
import DragFileInputMulti from '../../Common/Inputs/DragFileInputMulti';
import { downloadS3File } from 'Functions/MiscFunctions';
import BackButton from 'Components/Common/Buttons/BackButton';
import {colors} from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import MediaTable from 'Components/Common/DataTables/MediaTable';

const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    btnRow: {
        textAlign: 'center', 
    },
    btnRowBtn: {   
        width: '100%',
    },
    link: {
        textDecoration: 'none',
    },
    chip: {
        display: 'none',
    },
    ssrAlign: {
        marginLeft: '1.01em'
    }, 
    topGap: {
        marginTop: theme.spacing(3)
    },       
    bottomGap: {
        marginBottom: theme.spacing(1.5)
    },      
    bottomGapLg: {
        marginBottom: theme.spacing(3)
    },  
    contentGap: {
        marginTop: theme.spacing(3)
    },
    expansionPanelDetails: {        
        width: 'calc(100% + 24px)!important',
        padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(5)}px ${theme.spacing(3)}px`,
    },
    statusUpdate: {
        textAlign: 'center',
    },
    dispatchAlign: {
        textAlign: 'right'
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        },
        stepper: {
            display: 'none',
        },
        chip: {
            display: 'flex',
            width: '160px',
            marginBottom: theme.spacing(3),
        },
        ssrAlign: {
            marginLeft: 0
        },
        dispatchAlign: {
            textAlign: 'left'
        },
        responsiveTable: {
            maxWidth: '100%', 
            overflowX: 'auto'
        }
    },
    [theme.breakpoints.down('md')]: {        
        contentGap: {
            marginTop: 0,
        },
    }
});

const initialState = () => ({
    formData: {
        noteType: '',
        notesText: '',

        fileType: '',
        file: '',
        imgUploads: '',

        cancellationNotes:'',
        amendTo:'',

        emailTo: '',
        emailText: '',
        emailTemplate: '',
        emailDispatchNotes: [],
        emailStockReturnForms: [],
        amendRows: [{
            partNumber: '',
            partDescription: '',
            nominalList: [],
            srdId: '',
            nominalCode: ''
        }],
        emailPhotos: [],
    },
    formErrors: [],
    currentTab: 0,
    ssr: {},
    statuses: [],
    activeStep: 0,
    showSSR: false,
    expanded: null,
    activityActionedBy: [],
    nextStep: '',
    deliveryCommenced: false,
    barColor: [],
    redirect: false,
    successRedirect: false,
    latestSrId: 0,
    notes: [],
    files: [],
    templateList: [],
    templates: {},
    supplierContactList: [],
    dispatchNoteList: [],
    stockReturnList: [],
    photoList: [],
    enablePhotoUploader: false,
    // Expansion panels    
    expansionPanel: {
        approval: false,
        dispatch: false,
        delivery: false,
        confirmation: false,
        credit: false,
        completed: false,
        declined: false,
        cancelled: false,
    },
    expansionPanelDisabled: {
        approval: true,
        dispatch: true,
        delivery: true,
        confirmation: true,
        credit: true,
        completed: true,
        declined: true,
        cancelled: true,
    },
    // Dialogs
    dialogNotesOpen: false,
    dialogAmendOpen: false,
    dialogCancelOpen: false,
    dialogEmailOpen: false,
    dialogFilesOpen: false,
    dialogPhotoOpen: false,
    dialogPhotoURL: '',
    dialogPhotoFileName: '',
    //Snackbars
    snackbarNotesOpen: false,
    snackbarFilesOpen: false,
    snackbarCancelOpen: false,
    snackbarAmendOpen: false,
    // Loading
    isLoading: true
});

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

class ViewSupplierStockReturn extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `SupplierStockReturn:View:${this.props.match.params.id}`;
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.loadComponentData();
    }

    loadComponentData = () => {
        API.get('/supplierStockReturns/' + this.props.match.params.id + '/mostRecentSSRLinked')
        .then(result => {
            this.setState({
                isLoading: true,
                latestSrId: result.data.latestSrId !== parseInt(this.props.match.params.id) ? result.data.latestSrId : this.props.match.params.id,
            }, () => { 
                this.getSSR();
                this.props.history.replace({pathname: '/supplier-stock-return/view/' + this.state.latestSrId, state: this.props.location?.state});
                this.props.scrollToTop()
            })
        });
    }

    handlePanel = (panel) => {        
        if(this.state.expansionPanelDisabled[panel] === false){
            this.setState({
                expansionPanel: {
                    ...this.state.expansionPanel,
                    [panel]: this.state.expansionPanel[panel] === true ? false : true,
                }
            });
        }
    }

    getSSR = () => {
        Promise.all([ 
            API.get(`/supplierStockReturns/${this.state.latestSrId}`), 
            API.get('/accounts/nominalCodes'),
        ])
        .then(([result, nominalRes]) =>  {
            if(result.data.errors) {
                this.setState({ redirect: true });
            } else if(result.data) {
                this.getEmailTemplates();            
                // PROGRESS STEPS
                let statuses = ['Approval', 'Dispatch', 'Confirmation', (result.data.sr_return_type === 'Credit' ? 'Credit' : 'Delivery'), 'Completed'];
                const activeStep = ['Credit', 'Delivery'].includes(result.data.sr_status) ? 3 : _.findIndex(statuses, function(el) { return el === result.data.sr_status; });
                // BAR COLOURS - for the panels in Overview
                let barColor = [];
                statuses.forEach((status, idx) => {
                    if((activeStep && idx < activeStep) || activeStep === 4){
                        barColor[idx] = colors.green;
                    } 
                    else if(idx === activeStep){
                        barColor[idx] = 'orange';
                    } 
                    else {
                        barColor[idx] = 'white';
                    }
                });
                let deliveryCommenced = false;
                _.map(result.data.stock_return_details, (ssrd) => {
                    switch(ssrd.srd_delivery_status){
                        case 'Pending':
                        case 'In Progress':
                            ssrd.delivery_panel_colour = 'orange';
                        break;
                        case 'Cancelled':
                        case 'Declined':
                            ssrd.delivery_panel_colour = colors.red;
                        break;
                        case 'Completed':
                            ssrd.delivery_panel_colour = colors.green;
                        break;
                        default:
                        break;
                    }
                    if(ssrd.srd_delivery_status !== 'Pending'){
                        deliveryCommenced = true;
                    }
                });

                // NEXT STEP - link displayed under po
                let nextStep = `This supplier stock return has ${result.data.sr_status.toLowerCase()}`;
                if(['Approval','Confirmation','Dispatch','Delivery','Credit'].includes(result.data.sr_status)){
                    nextStep =  <Typography style={{textAlign:'center'}} variant="body1" gutterBottom>
                                    This stock return is now awaiting {result.data.sr_status.toLowerCase()}.<br></br>
                                    <Link to={`/supplier-stock-return/${result.data.sr_status.toLowerCase()}`} className='blueLink'>
                                        {result.data.sr_status === 'Approval' ? 'Approve Stock Return' : (result.data.sr_status === 'Confirmation' ? 'Confirm Stock Return' : `Confirm ${result.data.sr_status}`)}
                                    </Link>
                                </Typography>;
                }
            
                // SUPPLER CONTACTS - for 'email to' dropdown
                let supplierContactList = _.map(result.data.supplier_contacts, (el) => {
                    return _.assign({
                        value: el.supp_cont_id,
                        label: `${el.supp_cont_first_name} ${el.supp_cont_last_name} (${el.supp_cont_email})`
                    });
                });
                // DISPATCH NOTES - for 'attatchments' dropdown
                let dispatchNoteList = [];
                result.data.files.forEach(el => {
                    if(el.sr_file_type === 'Dispatch'){
                        dispatchNoteList.push({
                            value: el.sr_file_id,
                            label: el.sr_file_filename
                        });
                    }
                });
                // STOCK RETURN FORMS - for 'attatchments' dropdown
                let stockReturnList = [];
                result.data.all_versions.forEach(el => {
                    stockReturnList.push({
                        value: el.sr_id,
                        label: el.full_reference
                    });
                });
                // PHOTOS - for 'attatchments' dropdown
                let photoList = [];
                result.data.files.forEach(el => {
                    if (el.sr_file_type === "Photo") {
                        photoList.push({
                            value: el.sr_file_id,
                            label: el.sr_file_filename
                        });
                    }
                });
                // ACTIVITY
                let actionedBy = [];
                _.map(result.data.activity, (log) => {
                    let data = ssrActivityLog(log.sr_log_type);
                    log.color = data['color'];
                    log.icon = data['icon'];
                    log.time = moment(log.sr_log_datetime_added).format("DD/MM/YYYY");

                    switch(log.sr_log_type){
                        case 'Approved':
                            if(!actionedBy['approved_by']){actionedBy['approved_by'] = []}
                            actionedBy['approved_by'].push(log);
                        break;
                        case 'Declined':
                            if(!actionedBy['declined_by']){actionedBy['declined_by'] = []}
                            actionedBy['declined_by'].push(log);
                        break;
                        case 'Confirmed':
                            if(!actionedBy['confirmed_by']){actionedBy['confirmed_by'] = []}
                            actionedBy['confirmed_by'].push(log);
                        break;
                        case 'Dispatched':
                        case 'Dispatch Not Required':
                            if(!actionedBy['dispatched_by']){actionedBy['dispatched_by'] = []}
                            actionedBy['dispatched_by'].push(log);
                        break;
                        case 'Delivery':
                            if(!actionedBy['delivered_by']){actionedBy['delivered_by'] = []}
                            actionedBy['delivered_by'].push(log);
                        break;
                        case 'Credit Received':
                            if(!actionedBy['credited_by']){actionedBy['credited_by'] = []}
                            actionedBy['credited_by'].push(log);
                        break;
                        case 'Cancelled':
                            if(!actionedBy['cancelled_by']){actionedBy['cancelled_by'] = []}
                            actionedBy['cancelled_by'].push(log);
                        break;
                        default:
                        break;
                    }
                });
                // NOTES
                let notes = [];
                _.map(result.data.notes, (note) => {
                    switch(note.sr_note_type){
                        case 'Approval':
                            if(!notes['Approval']){notes['Approval'] = []}
                            notes['Approval'].push(note);
                        break;
                        case 'Declined':
                            if(!notes['Declined']){notes['Declined'] = []}
                            notes['Declined'].push(note);
                        break;
                        case 'Dispatch':
                            if(!notes['Dispatch']){notes['Dispatch'] = []}
                            notes['Dispatch'].push(note);
                        break;
                        case 'Delivery':
                            if(!notes['Delivery']){notes['Delivery'] = []}
                            notes['Delivery'].push(note);
                        break;
                        case 'Credit':
                            if(!notes['Credit']){notes['Credit'] = []}
                            notes['Credit'].push(note);
                        break;
                        case 'Cancelled':
                            if(!notes['Cancelled']){notes['Cancelled'] = []}
                            notes['Cancelled'].push(note);
                        break;
                        case 'Confirmation':
                            if(!notes['Confirmation']){notes['Confirmation'] = []}
                            notes['Confirmation'].push(note);
                        break;
                        default:
                        break;
                    }
                });
                // FILES
                let files = [];
                _.map(result.data.files, (file) => {
                    switch(file.sr_file_type){
                        case 'Confirmation':
                            if(!files['Confirmation']){files['Confirmation'] = []}
                            files['Confirmation'].push(file);
                        break;
                        case 'Dispatch':
                            if(!files['Dispatch']){files['Dispatch'] = []}
                            files['Dispatch'].push(file);
                        break;
                        case 'Credit':
                            if(!files['Credit']){files['Credit'] = []}
                            files['Credit'].push(file);
                        break;
                        case 'Delivery':
                            if(!files['Delivery']){files['Delivery'] = []}
                            files['Delivery'].push(file);
                        break;
                        case 'Faulty Stock Report':
                            if(!files['FaultyStockReport']){files['FaultyStockReport'] = []}
                            files['FaultyStockReport'].push(file);
                        break;
                        case 'Photo':
                            if(!files['Photo']){files['Photo'] = []}
                            files['Photo'].push(file);
                        break;
                        case 'Video':
                            if(!files['Video']){files['Video'] = []}
                            files['Video'].push(file);
                        break;
                        default:
                        break;
                    }
                });

                files['FaultyStockReport'] = _.orderBy(files['FaultyStockReport'], 'sr_file_id', 'desc');
                
                // Expand current status
                let defaultExpanded;
                if(actionedBy.declined_by){                    
                    defaultExpanded = 'declined';
                } else if(actionedBy.cancelled_by){
                    defaultExpanded = 'cancelled';
                } else {
                    defaultExpanded = statuses[activeStep].toLowerCase();
                }

                // Disable non-completed stages               
                const expansionPanelDisabled = {
                    ...this.state.expansionPanelDisabled
                }
  
                if(actionedBy['approved_by']) {
                    if(actionedBy['declined_by'] || actionedBy['cancelled_by'])
                        barColor[0] = 'disabled';
                    expansionPanelDisabled['approval'] = false;
                }      

                if(actionedBy['dispatched_by']) {     
                    if(actionedBy['declined_by'] || actionedBy['cancelled_by']) {       
                        barColor[1] = 'disabled';
                    }
                    expansionPanelDisabled['dispatch'] = false;  
                }                  

                if(actionedBy['confirmed_by']) {
                    if(actionedBy['declined_by'] || actionedBy['cancelled_by'])
                        barColor[2] = 'disabled';
                    expansionPanelDisabled['confirmation'] = false;
                }       

                                
                if(actionedBy['delivered_by']) {     
                    if(actionedBy['declined_by'] || actionedBy['cancelled_by']) {       
                        barColor[3] = 'disabled';
                    }
                    expansionPanelDisabled['delivery'] = false;  
                }  
                
                if(actionedBy['credited_by']) {     
                    if(actionedBy['declined_by'] || actionedBy['cancelled_by']) {       
                        barColor[3] = 'disabled';
                    }                 
                    expansionPanelDisabled['credit'] = false;
                }   

                if(result.data.sr_completed_datetime && result.data.sr_completed_datetime !== '0000-00-00 00:00:00') {
                    if(actionedBy['declined_by'] || actionedBy['cancelled_by'])
                        barColor[4] = 'disabled';
                    expansionPanelDisabled['completed'] = false;
                }       

                if(actionedBy['declined_by'])
                    expansionPanelDisabled['declined'] = false;

                if(actionedBy['cancelled_by'])
                    expansionPanelDisabled['cancelled'] = false; 
                
                if(['Approval','Confirmation','Dispatch','Delivery','Credit'].includes(result.data.sr_status)){
                    expansionPanelDisabled[result.data.sr_status.toLowerCase()] = false;
                }

                let nominalList = [];
                nominalList = _.map(nominalRes.data, (el) => {
                    return _.assign({
                        value: el.nominal_id,
                        label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                    });
                });
                let amendRows = [];
                _.map(result.data.stock_return_details, (ssrd) => {
                    if(ssrd.part && ssrd.part.nominal_codes && ssrd.part.nominal_codes.length > 0){
                        nominalList = _.map(ssrd.part.nominal_codes, (el) => {
                            return _.assign({
                                value: el.nominal_id,
                                label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                            });
                        });
                    }
                    amendRows.push({
                        partNumber: ssrd.part.part_number,
                        partDescription: ssrd.part.part_description,
                        nominalList: nominalList,
                        srdId: ssrd.srd_id,
                        nominalCode: ''
                    });
                    
                });
                
                this.setState({
                    ssr: result.data,
                    statuses: statuses,
                    activeStep: activeStep,
                    activityActionedBy: actionedBy,
                    expansionPanelDisabled,                      
                    expansionPanel: {
                        ...this.state.expansionPanel,
                        [defaultExpanded]: true
                    },
                    nextStep: nextStep,
                    barColor: barColor,
                    notes: notes,
                    files: files,
                    supplierContactList: supplierContactList,
                    dispatchNoteList: dispatchNoteList,
                    stockReturnList: stockReturnList,
                    photoList: photoList,
                    isLoading: false,
                    deliveryCommenced: deliveryCommenced,
                    formData: {
                        ...this.state.formData,
                        amendRows: amendRows
                    }
                });
            }
        });
    }

    getEmailTemplates = () => {
        API.get('/supplierStockReturns/emailTemplates')
        .then((templateRes) =>  {

            let templateList = [];

            if(templateRes.data) {
                templateList = _.map(templateRes.data, (el) => {
                    return _.assign({
                        value: el.sr_email_id,
                        label: el.sr_email_name
                    });
                });
                templateList.unshift({
                    value: 0,
                    label: 'None'
                });
            }
            this.setState({
                templateList: templateList,
                templates: templateRes.data
            });
        });
    }

    downloadSSR = (sr_id) => {
        API.get('/supplierStockReturns/' + sr_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        },()=>{this.savePageState();});
    };

    viewSSR = () => {
        this.setState(prevState => ({
            showSSR: !prevState.showSSR
        }),()=>{this.savePageState();});
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if(fieldName === 'emailTemplate'){
                let email = _.find(this.state.templates, {'sr_email_id': selectedOption && selectedOption.value });

                this.setState({
                    formData:{
                        ...this.state.formData,
                        emailText: (typeof email === 'undefined') ? '' : email.sr_email_text
                    }
                });
            }
        });
    }
    handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }
    handleImageUploads = (drop, name, event) => {
        let newFiles = drop === true ? event.dataTransfer.files : event.target.files;
        let existingFiles = this.state.formData.imgUploads;
        let allFiles = Array.prototype.slice.call(existingFiles).concat(Array.prototype.slice.call(newFiles))
        this.setState({
            formData: {
                ...this.state.formData,
                imgUploads: allFiles
            }
        });
    }
    clearFile = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                file: initialState().formData.file
            }
        });
    };
    clearImage = idx => {
        let imgUploads = Array.from(this.state.formData.imgUploads);

        imgUploads.splice(idx, 1);
        // Changes component colour to original state after it has been fully cleared
        if(imgUploads.length === 0){
            imgUploads = '';
        }

        this.setState({
            formData:{
                ...this.state.formData,
                imgUploads
            }
        });
    }

    // NOTES
    handleNotesOpen = () => {this.setState({dialogNotesOpen: true});};
    handleNotesClose = () => {this.setState({dialogNotesOpen: false});};
    handleNotesSuccess = () => {
        this.setState({ dialogNotesOpen: false });
        this.notesSubmit();
    }
    handleNotesSnackbarClose = () => {this.setState({ snackbarNotesOpen: false }); };
    notesSubmit = () => {
        API.post(`/supplierStockReturns/${this.props.match.params.id}/notes`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogNotesOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    snackbarNotesOpen: true
                });
                this.loadComponentData();
            }
        });
    }
    // AMEND
    handleAmendOpen = () => {this.setState({dialogAmendOpen: true});};
    handleAmendClose = () => {this.setState({dialogAmendOpen: false});};
    handleAmendSuccess = () => {
        this.setState({ dialogAmendOpen: false });
        this.amendSubmit();
    }
    handleAmendSnackbarClose = () => {this.setState({ snackbarAmendOpen: false }); };
    amendSubmit = () => {
        API.post(`/supplierStockReturns/${this.props.match.params.id}/amend`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogAmendOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    snackbarAmendOpen: true
                });
                this.loadComponentData();
            }
        });
    }
    // CANCEL
    handleCancelOpen = () => {this.setState({dialogCancelOpen: true});};
    handleCancelClose = () => {this.setState({dialogCancelOpen: false});};
    handleCancelSuccess = () => {
        this.setState({ dialogCancelOpen: false });
        this.cancelSubmit();
    }
    handleCancelSnackbarClose = () => {this.setState({ snackbarCancelOpen: false }); };
    cancelSubmit = () => {
        API.post(`/supplierStockReturns/${this.props.match.params.id}/cancel`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogCancelOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    snackbarCancelOpen: true
                });
                this.loadComponentData();
            }
        });
    }
    // EMAIL
    handleEmailOpen = () => {this.setState({dialogEmailOpen: true});};
    handleEmailClose = () => {this.setState({dialogEmailOpen: false});};
    handleEmailSuccess = () => {
        this.setState({ dialogEmailOpen: false });
        this.emailSubmit();
    }
    emailSubmit = () => {
        API.post(`/supplierStockReturns/${this.props.match.params.id}/email`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogEmailOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    snackbarEmailOpen: true
                });
                this.loadComponentData();
            }
        });
    }
    // FILES
    handleFilesOpen = () => {this.setState({dialogFilesOpen: true});};
    handleFilesClose = () => {this.setState({dialogFilesOpen: false});};
    handleFilesSuccess = () => {
        this.setState({ dialogFilesOpen: false });
        this.filesSubmit();
    }
    handleFilesSnackbarClose = () => {this.setState({ snackbarFilesOpen: false }); };
    handleEmailSnackbarClose = () => {this.setState({ snackbarEmailOpen: false }); };

    filesSubmit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            if(key === 'imgUploads') {
                // Append images to file[] array
                Array.from(this.state.formData[key]).forEach(img => {
                    newFormData.append('file[]', img);
                });
            } else if(key === 'file') {
                newFormData.append('file[]', this.state.formData[key]);
            } else {
                newFormData.append(key, this.state.formData[key]);
            }
        });
        API.post(`/supplierStockReturns/${this.props.match.params.id}/files`, newFormData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogFilesOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    snackbarFilesOpen: true
                });
                this.loadComponentData();
            }
        });
    }

    displayNotes = (notes) => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} className={this.props.classes.bottomGap}>                      
                    <Typography variant="body1">
                        <FALightIcon icon='comment-alt-lines' /> Notes
                    </Typography>
                </Grid>
                {notes ?
                    notes.map(note => (
                        <Grid key={note.sr_note_id} item xs={12} align='left' className={this.props.classes.bottomGapLg}>
                            <Card style={{boxShadow: 'none', border: '1px solid #DDD'}}>
                                <CardHeader
                                    avatar={
                                        <Avatar alt={`${note.staff.staff_first_name} ${note.staff.staff_last_name}`} src={note.staff.staff_profile_photo} />
                                    }
                                    title={note.sr_note_staff_name}
                                    subheader={ moment(note.sr_note_datetime_added, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
                                />
                                <CardContent>
                                    <Typography variant="body2">
                                        {note.sr_note_text && note.sr_note_text.split('\n').map((item, key) => {
                                            return <span key={key}>{item}<br/></span>
                                        })}
                                    </Typography>
                                </CardContent>
                            </Card>                                
                        </Grid>
                    ))
                    :
                    (
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            No notes available to view
                        </Typography>
                    </Grid> 
                    )
                }
            </Grid>
        )
    }

    displayFiles = (files, fileTitle) => {
        const title = fileTitle;
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} className={this.props.classes.bottomGap}>                 
                    <Typography variant="body1">
                        <FALightIcon icon='download' /> {title}
                    </Typography>
                </Grid>
                {files ?
                    (
                        <Grid item xs={12}>
                            <DataTable  
                                config={{
                                    key: 'sr_file_id',
                                    noHeader: true,
                                    withBorder: true,
                                    withBorderRadius: true,
                                }}
                                columns={[
                                    {
                                        field: rowData => <MaterialLink href={rowData.file_url} target="_blank" className='blueLink'>{rowData.sr_file_filename}</MaterialLink>,
                                    }
                                ]}
                                rows={files}
                                />                                                                            
                        </Grid>
                    )
                    :
                    (
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            No files available to download
                        </Typography>
                    </Grid> 
                    )
                }
            </Grid>
        )
    }
    // Photos
    handlePhotoOpen = (photoURL, photoFileName) => {this.setState({dialogPhotoOpen: true, dialogPhotoURL: photoURL, dialogPhotoFileName: photoFileName});};
    handlePhotoClose = () => {this.setState({dialogPhotoOpen: false});};

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions && selectedOptions.map(a => a.value);
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }

    handleSelectRowChange = (idx, fieldName) => selectedOption => {
        let data =  [...this.state.formData.amendRows];
        let newVal = selectedOption && selectedOption.value;

        data[idx] = {
            ...data[idx],
            [fieldName]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                amendRows: data
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { currentTab, ssr, isLoading, expansionPanel, expansionPanelDisabled, activityActionedBy, activeStep, statuses, nextStep, deliveryCommenced, formData, formErrors, notes, files, redirect } = this.state;
        if (redirect) {
            return <Redirect to="/supplier-stock-return/search/" />
        } else {
            return (            
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Supplier Stock Return Details
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={9}>
                            <PaddedPaper>
                                {(isLoading && (
                                    <LoadingCircle />               
                                )) || (
                                    <React.Fragment>
                                    {statuses[activeStep] &&
                                        <Grid item align='center' className='xs-only'>
                                            <Chip label={statuses[activeStep]} color='primary' size='medium' className={classes.chip}  />
                                        </Grid>  
                                    }                              
                                    <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                                        {this.state.statuses.map((label, idx) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    <Typography variant="h4" align="center" className={classes.heading} style={{marginBottom: 25}}>
                                        {ssr.full_reference}
                                    </Typography>
                                    <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                        Supplier Stock Return (v{parseInt(ssr.sr_reference_amendment)})<br />
                                        <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={this.viewSSR}
                                            style={{marginLeft:10}}
                                        >                                    
                                            <FALightIcon icon='search' />
                                            {this.state.showSSR ? 'Hide' : 'View'}
                                        </Button>
                                        <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={() => this.downloadSSR(ssr.sr_id)}
                                        >
                                            <FALightIcon icon='download' />
                                            Download
                                        </Button> 
                                    </Typography>
                                    <br className='xs-only' /> 
                                    { this.state.showSSR ? <SupplierStockReturn ssr={ssr} /> : null }

                                    <AppBar position="static" style={{backgroundColor:'white', margin: '25px 0'}} >
                                        <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary" >
                                            <Tab label="Overview" />
                                            {(ssr.sr_return_type !== 'Credit' && 
                                                <Tab label="Delivery" />
                                            )}
                                            <Tab label="Notes" />
                                            <Tab label="Activity Log" />
                                            <Tab label="Faulty Stock Reports" />
                                            <Tab label="Photos" />
                                            <Tab label="Videos" />
                                        </Tabs>
                                    </AppBar>
                                
                                    {currentTab === 0 && (
                                        //Overview
                                        <TabContainer>
                                            {/* Approval */}
                                            <ExpansionPanel expanded={expansionPanel.approval} disabled={expansionPanelDisabled['approval']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('approval')} expandIcon={!expansionPanelDisabled['approval'] && <ExpandMoreIcon />} className={this.state.barColor[0]}>
                                                    <Typography>Approval</Typography>
                                                </ExpansionPanelSummary>                                            
                                                {!activityActionedBy.approved_by ? (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3} justify='center'>
                                                            <Grid item align='center' xs={12}>                                              
                                                                <FALightIcon size='xlarge' icon='exclamation-circle' noMargin /> 
                                                                {nextStep}
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayNotes(notes.Approval)}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                ) : (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3} justify='center'>
                                                            <Grid item xs={12} align='center'>
                                                                {activityActionedBy.approved_by &&
                                                                    <React.Fragment>
                                                                        <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                        {activityActionedBy.approved_by.map(approved_by => (
                                                                            <Typography key={approved_by.sr_log_id} variant="body1">Approved by {approved_by.sr_log_staff} on {approved_by.time}</Typography>
                                                                        ))}
                                                                    </React.Fragment>
                                                                }
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayNotes(notes.Approval)}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                )}
                                            </ExpansionPanel>

                                            {/* Dispatch */}
                                            <ExpansionPanel expanded={expansionPanel.dispatch} disabled={expansionPanelDisabled['dispatch']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('dispatch')} expandIcon={!expansionPanelDisabled['dispatch'] && <ExpandMoreIcon />} className={this.state.barColor[1]}>
                                                    <Typography>Dispatch</Typography>
                                                </ExpansionPanelSummary>                                            
                                                {!activityActionedBy.dispatched_by ? (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3} justify='center'>
                                                            <Grid item align='center' xs={12}>                                              
                                                                <FALightIcon size='xlarge' icon='exclamation-circle' noMargin /> 
                                                                {nextStep}
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayNotes(notes.Dispatch)}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayFiles(files.Dispatch, 'Files')}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                ) : (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3} justify='center'>
                                                            <Grid item xs={12} align='center'>
                                                                {activityActionedBy.dispatched_by && (
                                                                    <React.Fragment>
                                                                        <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                        {activityActionedBy.dispatched_by.map(dispatched_by => (
                                                                            <Typography key={dispatched_by.sr_log_id} variant="body1">Dispatch processed by {dispatched_by.sr_log_staff} on {dispatched_by.time}</Typography>
                                                                        ))}                                                                  

                                                                        <Divider className={classes.topGap} />
                                                                        <Grid item xs={12} lg={6} className={classes.topGap}>
                                                                            <Grid container spacing={2}>                                                                            
                                                                                <Grid item xs={12} className={this.props.classes.bottomGap}>                      
                                                                                    <Typography variant="body1">
                                                                                        <FALightIcon icon='shipping-fast' /> Dispatch
                                                                                    </Typography>
                                                                                </Grid>                      
                                                                                <Grid item xs={12} sm={6} align='left'> 
                                                                                    <div className={classes.dispatchAlign}>                                                                                                                                        
                                                                                        <Typography variant="body2">
                                                                                            Dispatch required:
                                                                                        </Typography>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} align='left'>                                                                                                                                         
                                                                                    <Typography variant="body2">
                                                                                        {ssr.sr_dispatch_required}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} align='left'> 
                                                                                    <div className={classes.dispatchAlign}>                                                                                                                                             
                                                                                        <Typography variant="body2">
                                                                                            Dispatch courier:
                                                                                        </Typography>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} align='left'>                                                                                                                                         
                                                                                    <Typography variant="body2">
                                                                                        {(ssr.sr_dispatch_courier && (                                                                                
                                                                                            <React.Fragment>
                                                                                                {ssr.sr_dispatch_courier}<br />
                                                                                                {ssr.dispatch_supplier && 
                                                                                                    <MaterialLink href={ssr.dispatch_supplier.supp_website.includes("http") ? ssr.dispatch_supplier.supp_website : '//'+ssr.dispatch_supplier.supp_website} target='_blank' variant="body1" className='blueLink' rel="noopener">
                                                                                                        {ssr.dispatch_supplier.supp_website}
                                                                                                    </MaterialLink>
                                                                                                }                                                                                    
                                                                                            </React.Fragment>
                                                                                        )) || (
                                                                                            <React.Fragment>
                                                                                                Unknown
                                                                                            </React.Fragment>
                                                                                        )}  
                                                                                    </Typography>                                                                              
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} align='left'>                                                                             
                                                                                    <div className={classes.dispatchAlign}>                                                                                                                                            
                                                                                        <Typography variant="body2">
                                                                                            Tracking number:
                                                                                        </Typography>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} align='left'>                                                                                                                                         
                                                                                    <Typography variant="body2">
                                                                                        {(ssr.sr_dispatch_tracking_number && (                                                                                
                                                                                            <React.Fragment>
                                                                                                {ssr.sr_dispatch_tracking_number}<br />
                                                                                                {ssr.sr_dispatch_tracking_url && 
                                                                                                    <MaterialLink href={ssr.sr_dispatch_tracking_url} target="_blank" variant="body1" className='blueLink'>
                                                                                                        {ssr.sr_dispatch_tracking_url}
                                                                                                    </MaterialLink>
                                                                                                }                                                                                    
                                                                                            </React.Fragment>
                                                                                        )) || (
                                                                                            <React.Fragment>
                                                                                                Not Available
                                                                                            </React.Fragment>
                                                                                        )}   
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                )}
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayNotes(notes.Dispatch)}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayFiles(files.Dispatch, 'Files')}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                )}
                                            </ExpansionPanel>

                                            {/* Confirmation */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.confirmation} disabled={expansionPanelDisabled['confirmation']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('confirmation')} expandIcon={!expansionPanelDisabled['confirmation'] && <ExpandMoreIcon />} className={this.state.barColor[2]}>
                                                    <Typography>Confirmation</Typography>
                                                </ExpansionPanelSummary>
                                                {!activityActionedBy.confirmed_by ? (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3} justify='center'>
                                                            <Grid item align='center' xs={12}>                                               
                                                                <FALightIcon size='xlarge' icon='exclamation-circle' noMargin /> 
                                                                {nextStep}
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}  align='center'>
                                                                {this.displayNotes(notes.Confirmation)}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayFiles(files.Confirmation, 'Files')}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                ) : (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3} justify='center'>
                                                            <Grid item xs={12} align='center'>
                                                                {activityActionedBy.confirmed_by &&
                                                                    <React.Fragment>
                                                                        <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                        {activityActionedBy.confirmed_by.map(confirmed_by => (
                                                                            <Typography key={confirmed_by.sr_log_id} variant="body1">Processed by {confirmed_by.sr_log_staff} on {confirmed_by.time}</Typography>
                                                                        ))}
                                                                    </React.Fragment>
                                                                }
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}  align='center'>
                                                                {this.displayNotes(notes.Confirmation)}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayFiles(files.Confirmation, 'Files')}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                )}
                                            </ExpansionPanel>

                                            {/* Delivery */}
                                            {ssr.sr_return_type !== 'Credit' &&
                                                <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.delivery} disabled={expansionPanelDisabled['delivery']}>
                                                    <ExpansionPanelSummary onClick={() => this.handlePanel('delivery')} expandIcon={!expansionPanelDisabled['delivery'] && <ExpandMoreIcon />} className={this.state.barColor[3]}>
                                                        <Typography>Delivery</Typography>
                                                    </ExpansionPanelSummary>
                                                    {!activityActionedBy.delivered_by ? (
                                                        <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                            <Grid container spacing={3}>
                                                                <Grid item align='center' xs={12}>                                                 
                                                                    <FALightIcon size='xlarge' icon='exclamation-circle' noMargin /> 
                                                                    {nextStep}
                                                                    <Divider className={classes.topGap} />
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}  align='center'>
                                                                    {this.displayNotes(notes.Delivery)}
                                                                </Grid>
                                                                <Grid item xs={12} lg={6} align='center'>
                                                                    {this.displayFiles(files.Delivery, 'Files')}
                                                                </Grid>
                                                            </Grid>
                                                        </ExpansionPanelDetails>
                                                    ) : (
                                                        <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} align='center'>
                                                                    {activityActionedBy.delivered_by &&
                                                                        <React.Fragment>
                                                                            <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                            {activityActionedBy.delivered_by.map(delivered_by => (
                                                                                <Typography key={delivered_by.sr_log_id} variant="body1">Delivery processed by {delivered_by.sr_log_staff} on {delivered_by.time}</Typography>
                                                                            ))}
                                                                        </React.Fragment>
                                                                    }
                                                                    <Divider className={classes.topGap} />
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}  align='center'>
                                                                    {this.displayNotes(notes.Delivery)}
                                                                </Grid>
                                                                <Grid item xs={12} lg={6} align='center'>
                                                                    {this.displayFiles(files.Delivery, 'Files')}
                                                                </Grid>
                                                            </Grid>
                                                        </ExpansionPanelDetails>
                                                    )}
                                                </ExpansionPanel>
                                            }

                                            {/* Credit */}
                                            {ssr.sr_return_type === 'Credit' &&
                                                <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.credit} disabled={expansionPanelDisabled['credit']}>
                                                    <ExpansionPanelSummary onClick={() => this.handlePanel('credit')} expandIcon={!expansionPanelDisabled['credit'] && <ExpandMoreIcon />} className={this.state.barColor[3]}>
                                                        <Typography>Credit</Typography>
                                                    </ExpansionPanelSummary>
                                                    {!activityActionedBy.credited_by ? (
                                                        <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                            <Grid container spacing={3} justify='center'>
                                                                <Grid item align='center' xs={12}>                                                 
                                                                    <FALightIcon size='xlarge' icon='exclamation-circle' noMargin /> 
                                                                    {nextStep}
                                                                    <Divider className={classes.topGap} />
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}  align='center'>
                                                                    {this.displayNotes(notes.Credit)}
                                                                </Grid>
                                                                <Grid item xs={12} lg={6} align='center'>
                                                                    {this.displayFiles(files.Credit, 'Files')}
                                                                </Grid>
                                                            </Grid>
                                                        </ExpansionPanelDetails>
                                                    ) : (
                                                        <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                            <Grid container spacing={3} justify='center'>
                                                                <Grid item xs={12} align='center'>
                                                                    {activityActionedBy.credited_by &&
                                                                        <React.Fragment>
                                                                            <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                            {activityActionedBy.credited_by.map(credited_by => (
                                                                                <Typography key={credited_by.sr_log_id} variant="body1">Delivery processed by {credited_by.sr_log_staff} on {credited_by.time}</Typography>
                                                                            ))}
                                                                        </React.Fragment>
                                                                    }
                                                                    <Divider className={classes.topGap} />
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}  align='center'>
                                                                    {this.displayNotes(notes.Credit)}
                                                                </Grid>
                                                                <Grid item xs={12} lg={6} align='center'>
                                                                    {this.displayFiles(files.Credit, 'Files')}
                                                                </Grid>
                                                            </Grid>
                                                        </ExpansionPanelDetails>
                                                    )}
                                                </ExpansionPanel>
                                            }

                                            {/* Completed */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.completed} disabled={expansionPanelDisabled['completed']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('completed')} expandIcon={!expansionPanelDisabled['completed'] && <ExpandMoreIcon />} className={this.state.barColor[4]}>
                                                    <Typography>Completed</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                    <Grid container>
                                                        <Grid item xs={12} align='center'>   
                                                        {ssr.sr_completed_datetime && ssr.sr_completed_datetime !== '0000-00-00 00:00:00' &&    
                                                                <React.Fragment>   
                                                                    <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                    <Typography variant="body1">Completed on {moment(ssr.sr_completed_datetime).format("DD/MM/YYYY")}</Typography>
                                                                </React.Fragment>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>

                                            {/* Declined */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.declined} disabled={expansionPanelDisabled['declined']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('declined')} expandIcon={!expansionPanelDisabled['declined'] && <ExpandMoreIcon />} className={(ssr.sr_status === 'Declined' && colors.red) || 'white'}>
                                                    <Typography>Declined</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container justify='center' spacing={3}>
                                                            <Grid item xs={12} align='center'>   
                                                                {activityActionedBy.declined_by && (
                                                                    <React.Fragment>
                                                                        {activityActionedBy.declined_by.map((declined_by, idx) => (                         
                                                                            <React.Fragment key={idx}>          
                                                                                <FALightIcon size='xlarge' icon='times-circle' noMargin /> 
                                                                                <Typography variant="body1">Declined by {declined_by.sr_log_staff} on {declined_by.time}</Typography>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </React.Fragment>
                                                                )}
                                                            </Grid>  
                                                            <Grid item xs={12} align='center'>
                                                                <Divider className={classes.bottomGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayNotes(notes.Declined)}
                                                            </Grid> 
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                            </ExpansionPanel>

                                            {/* Cancelled */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.cancelled} disabled={expansionPanelDisabled['cancelled']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('cancelled')} expandIcon={!expansionPanelDisabled['cancelled'] && <ExpandMoreIcon />} className={(ssr.sr_status === 'Cancelled' && colors.red) || 'white'}>
                                                    <Typography>Cancelled</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container justify='center' spacing={3}>
                                                            <Grid item xs={12} align='center'>   
                                                                {activityActionedBy.cancelled_by && (
                                                                    <React.Fragment>
                                                                        {activityActionedBy.cancelled_by.map((cancelled_by, idx) => (                         
                                                                            <React.Fragment key={idx}>          
                                                                                <FALightIcon size='xlarge' icon='times-circle' noMargin /> 
                                                                                <Typography variant="body1">Cancelled by {cancelled_by.sr_log_staff} on {cancelled_by.time}</Typography>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </React.Fragment>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} align='center'>
                                                                <Divider className={classes.bottomGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayNotes(notes.Cancelled)}
                                                            </Grid> 
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                            </ExpansionPanel>        
                                        </TabContainer>
                                    )}
                                    {currentTab === 1 && ssr.sr_return_type !== 'Credit' && (
                                        //Delivery
                                        <TabContainer>
                                            {ssr.stock_return_details && ssr.stock_return_details.map((ssrd, idx) => (
                                                <ExpansionPanel key={`expanel-${idx}`}>
                                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={ssrd.delivery_panel_colour}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={6}>
                                                                {ssrd.srd_part_number + ' - ' + ssrd.srd_part_description}
                                                            </Grid>
                                                            <Grid item xs={6} align="right">
                                                                {ssrd.srd_delivery_status}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <div className={classes.responsiveTable} style={{marginTop: 24}}>
                                                            <Grid container spacing={3} style={{minWidth: 500, minHeight: 125, fontSize: '0.8125rem',}}>
                                                                <Grid item xs={4} style={{backgroundColor:'#f5f5f5', textAlign:'right', fontWeight: 'bold'}}>
                                                                    Expected Quantity
                                                                </Grid>
                                                                <Grid item xs={4} style={{backgroundColor:'#f5f5f5', textAlign:'right', fontWeight: 'bold'}}>
                                                                    Received Quantity
                                                                </Grid>
                                                                <Grid item xs={4} style={{backgroundColor:'#f5f5f5', textAlign:'right', fontWeight: 'bold'}}>
                                                                    Delivery Date
                                                                </Grid>
                                                                <Grid item xs={4} style={{textAlign:'right',borderRight: '1px solid #ddd'}}>
                                                                    {ssrd.srd_quantity}
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Grid container spacing={3}>
                                                                        {ssrd.received_deliveries && ssrd.received_deliveries.map(deliveries => (
                                                                            <React.Fragment key={deliveries.sr_delivery_id}>
                                                                                <Grid item xs={6} style={{textAlign:'right'}}>
                                                                                    {deliveries.sr_delivery_quantity}
                                                                                </Grid>
                                                                                <Grid item xs={6} style={{textAlign:'right'}}>
                                                                                    {moment(deliveries.sr_delivery_date).format('DD/MM/YYYY')}
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={4} style={{backgroundColor:'#f5f5f5', fontWeight: 'bold'}}>
                                                                    <span style={{float:'left'}}>Total</span> <span  style={{float:'right'}}>{ssrd.srd_quantity}</span>
                                                                </Grid>
                                                                <Grid item xs={4} style={{backgroundColor:'#f5f5f5', textAlign:'right', fontWeight: 'bold'}}>
                                                                    {ssrd.received_total_qty}
                                                                </Grid>
                                                                <Grid item xs={4} style={{backgroundColor:'#f5f5f5'}}></Grid>
                                                            </Grid>
                                                        </div>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            ))}
                                        </TabContainer>
                                    )}
                                    {(  (ssr.sr_return_type === 'Credit' && currentTab === 1) || 
                                        (ssr.sr_return_type !== 'Credit' && currentTab === 2)) && (
                                        //Notes
                                        <TabContainer>
                                        <Grid container spacing={3}>
                                            {ssr.notes && 
                                                ssr.notes.map(note => (
                                                    <Grid key={note.sr_note_id} item xs={12}>
                                                        <Card>
                                                            <CardHeader
                                                                avatar={<Avatar alt={`${note.staff.staff_first_name} ${note.staff.staff_last_name}`} src={note.staff.staff_profile_photo} />}
                                                                title={note.sr_note_staff_name}
                                                                subheader={`${moment(note.sr_note_datetime_added).format("DD/MM/YYYY")} (${note.sr_note_type})`}
                                                            />
                                                            <CardContent>
                                                                <Typography component="p">
                                                                    {note.sr_note_text && note.sr_note_text.split('\n').map((item, key) => {
                                                                        return <span key={key}>{item}<br/></span>
                                                                    })}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                ))
                                            }
                                            {!ssr.notes.length > 0 && (
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader                                                       
                                                            title='No notes'
                                                        />
                                                        <CardContent>
                                                            <Typography component="p">
                                                                There are no notes to show for this supplier stock return
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            )}
                                            </Grid>
                                        </TabContainer>
                                    )}
                                    {(  (ssr.sr_return_type === 'Credit' && currentTab === 2) || 
                                        (ssr.sr_return_type !== 'Credit' && currentTab === 3)) && (
                                        //Activity Log
                                        <Card>
                                            <CardContent>
                                                <TabContainer>
                                                    <VerticalTimeline layout="1-column">
                                                        {ssr.activity && ssr.activity.map(log => (
                                                            <VerticalTimelineElement
                                                                key={`activity-${log.sr_log_id}`}
                                                                className="vertical-timeline-element--work"
                                                                date={moment(log.sr_log_datetime_added).format("DD/MM/YYYY") + ' - ' + log.sr_log_staff}
                                                                iconStyle={{ backgroundColor: log.color, color: 'white' }}
                                                                icon={<FASolidIcon icon={log.icon} style={{color:'white'}} />}
                                                            >
                                                                <h3 className="vertical-timeline-element-title">{log.sr_log_type}</h3>
                                                                {log.sr_log_text && log.sr_log_text.split('\n').map((item, key) => {
                                                                    return <span key={key}>{item}<br/></span>
                                                                })}
                                                            </VerticalTimelineElement>
                                                        ))}
                                                    </VerticalTimeline>
                                                </TabContainer>
                                            </CardContent>
                                        </Card>
                                    )}
                                    {(  (ssr.sr_return_type === 'Credit' && currentTab === 3) ||
                                        (ssr.sr_return_type !== 'Credit' && currentTab === 4)) && (
                                        //Faulty stock report
                                        <TabContainer>
                                            <Card>
                                                <CardContent>
                                                    <Grid container item spacing={3} align="center" justify="center" style={{marginBottom:20}}>
                                                        <Grid item xs={12} lg={8} align='center'>
                                                            <FALightIcon icon="download" style={{height:30, width:30, marginBottom:5}} />
                                                            <Typography variant="h5" style={{marginBottom:15}}>Faulty Stock Reports</Typography>
                                                            {(files.FaultyStockReport && files.FaultyStockReport.length > 0) ? (
                                                                <DataTable  
                                                                    config={{
                                                                        key: files.FaultyStockReport.sr_file_id,
                                                                        noHeader: true,
                                                                        withBorder: true,
                                                                        withBorderRadius: true,
                                                                    }}
                                                                    columns={[
                                                                        {
                                                                            field: rowData => <MaterialLink href={rowData.file_url} target="_blank" className='blueLink'>{rowData.sr_file_filename}</MaterialLink>,
                                                                        }
                                                                    ]}
                                                                    rows={files.FaultyStockReport}
                                                                />    
                                                            ) : (
                                                                <Typography variant="body2">There are no faulty stock reports for this supplier stock return</Typography>
                                                            )}                
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </TabContainer>
                                    )}
                                    {(  (ssr.sr_return_type === 'Credit' && currentTab === 4) ||
                                        (ssr.sr_return_type !== 'Credit' && currentTab === 5)) && (
                                        //Photos
                                            <TabContainer>
                                                <Grid container item spacing={3} alignItems="center" alignContent="center" style={{marginBottom:20}}>
                                                    {files.Photo ? files.Photo.map(photo =>
                                                        (<Grid item xs={6} lg={4} xl={3} key={photo.sr_file_id}>
                                                            <Card>
                                                                <CardActionArea>
                                                                    <CardContent style={{textAlign:'center', display:'flex', flexDirection:'column'}}>
                                                                        <MaterialLink onClick={() => this.handlePhotoOpen(photo.file_url, photo.sr_file_filename)} style={{cursor:'pointer'}}>
                                                                            <ImageWithError src={photo.file_url} alt={photo.sr_file_filename} style={{height:100, maxWidth: '100%', padding:0, marginBottom:5}} />
                                                                        </MaterialLink>
                                                                        <span>{photo.sr_file_filename}</span>
                                                                    </CardContent>
                                                                </CardActionArea>
                                                                <CardActions style={{justifyContent:'center', padding:0}}>
                                                                    <Tooltip title="Image File">
                                                                        <div>{/* Need tag for tooltip */}
                                                                            <IconButton onClick={() => downloadS3File(photo.file_url)}>
                                                                                <FALightIcon icon='image' noMargin fixedHeight button />
                                                                            </IconButton>
                                                                        </div>
                                                                    </Tooltip>
                                                                </CardActions>
                                                            </Card>
                                                        </Grid>)
                                                    ) : (
                                                        <Grid item xs={12}>
                                                            <Card>
                                                                <CardHeader title='No photos' />
                                                                <CardContent>
                                                                    <Typography component="p">
                                                                        There are no photos to show for this supplier stock return
                                                                    </Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>)}
                                                </Grid>
                                            </TabContainer>
                                    )}
                                    {(  (ssr.sr_return_type === 'Credit' && currentTab === 5) ||
                                        (ssr.sr_return_type !== 'Credit' && currentTab === 6)) && (
                                            //Videos
                                            <TabContainer>
                                                <Grid container item spacing={3} alignItems="center" alignContent="center" style={{marginBottom:20}}>
                                                    {files.Video ? 
                                                        <Grid item xs={12}>
                                                            <MediaTable
                                                                config={{
                                                                    isLoading:  isLoading,
                                                                    key:        'sr_file_id',
                                                                    itemPerRow: 5,
                                                                    mobileItemPerRow: 1,
                                                                    pagination: true,
                                                                    filterInPagination: false,
                                                                    download: true,
                                                                }}
                                                                media='file_url'
                                                                items={files.Video}
                                                                fields={[
                                                                    {
                                                                        actions: rowData => [
                                                                            {
                                                                                download: true,
                                                                            }
                                                                        ]
                                                                    }
                                                                ]}
                                                            />
                                                            {/* <CiDataTable
                                                                config={{
                                                                    key: 'sr_file_id',
                                                                }}
                                                                columns={[
                                                                    {
                                                                        heading: 'file',
                                                                        field: 'sr_file_filename',
                                                                    },
                                                                    {
                                                                        actions: i => ([
                                                                            {
                                                                                name: 'Download',
                                                                                icon: 'download',
                                                                                onClick: () => downloadS3File(i.file_url)
                                                                            }
                                                                        ])
                                                                    }
                                                                ]}
                                                                rows={files.Video}
                                                            /> */}
                                                        </Grid>
                                                    : (
                                                        <Grid item xs={12}>
                                                            <Card>
                                                                <CardHeader title='No Videos' />
                                                                <CardContent>
                                                                    <Typography component="p">
                                                                        There are no Videos to show for this supplier stock return
                                                                    </Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>)}
                                                </Grid>
                                            </TabContainer>
                                    )}
                                    <Divider style={{marginBottom:20}} />
                                        <div className={`buttonRow ${classes.btnRow}`}>
                                            <Grid container justify='center' spacing={1}>
                                                <Grid item xs={12} md={2}>
                                                    <BackButton props={this.props} style={{width:'100%'}} />
                                                </Grid>                                                
                                                {ssr.sr_status !== 'Approval' &&
                                                    <Grid item xs={12} md={2}>
                                                        <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleEmailOpen}><FALightIcon icon='envelope' buttonPrimary /> Email Supplier</Button>
                                                    </Grid>
                                                }
                                                <Grid item xs={12} md={2}>
                                                    <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleNotesOpen}><FALightIcon icon='plus' buttonPrimary /> Add Notes</Button>
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleFilesOpen}><FALightIcon icon='plus' buttonPrimary /> Add Files</Button>
                                                </Grid> 
                                                {ssr.locked ?
                                                    <Grid item xs={12} md={4}>
                                                        <Button variant="contained" className={classes.btnRowBtn} color="primary" disabled><FALightIcon icon={icons.lock} buttonPrimary /> Locked</Button>
                                                    </Grid> :
                                                    <>
                                                        {ssr.sr_status !== 'Declined' && ssr.sr_status !== 'Cancelled' && ssr.sr_status !== 'Completed' && !deliveryCommenced &&
                                                            <Grid item xs={12} md={4}>
                                                                <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleAmendOpen}><FALightIcon icon='pen' buttonPrimary /> Amend Return Type</Button>
                                                            </Grid>
                                                        }
                                                        {ssr.sr_status !== 'Declined' && ssr.sr_status !== 'Cancelled' && ssr.sr_status !== 'Completed' && !deliveryCommenced && ssr.sr_status !== 'Approval' &&
                                                            <Grid item xs={12} md={2}>
                                                                <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleCancelOpen}><FALightIcon icon='ban' buttonPrimary /> Cancel</Button>
                                                            </Grid>
                                                        }      
                                                    </>         
                                                }                                             
                                            </Grid>
                                        </div>
                                    </React.Fragment>
                                )}
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Grid container item spacing={3}>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h5">
                                            Previous Revisions
                                        </Typography>
                                        {ssr.revisions && ssr.revisions.length > 0 ?
                                            ssr.revisions.map((rev, idx) => (
                                                <React.Fragment key={idx}>
                                                    <MaterialLink component="button" variant="body1" className='blueLink' onClick={() => this.downloadSSR(rev.sr_id)}>
                                                        {rev.full_reference || '-'}
                                                    </MaterialLink>
                                                    <br></br>
                                                </React.Fragment>
                                            ))
                                        :
                                            <Typography variant="body1" gutterBottom>
                                                0 previous stock returns
                                                
                                            </Typography>
                                        }
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                
                    {/* NOTES */}
                    <Dialog 
                        open={this.state.dialogNotesOpen} 
                        onClose={this.handleNotesClose} 
                        fullWidth={true} 
                        maxWidth="sm" 
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title">Add Notes</DialogTitle>
                        <DialogContent>
                            <FormControl error={formErrors && formErrors['noteType'] && true} fullWidth margin="normal">
                                <AutoCompleteSelect
                                    options={[
                                        {value: 'Approval', label: 'Approval'},
                                        {value: 'Dispatch', label: 'Dispatch'},
                                        ssr.sr_return_type === 'Credit' ? {value: 'Credit', label: 'Credit'} : {value: 'Delivery', label: 'Delivery'},
                                        {value: 'Declined', label: 'Declined'},
                                        {value: 'Cancelled', label: 'Cancelled'}
                                    ]}
                                    label='Note Type *'
                                    value={formData.noteType}
                                    onChange={this.handleSelectChange('noteType')}
                                    error={formErrors && formErrors['noteType'] && true}
                                    errorText={formErrors && formErrors['noteType'] && formErrors['noteType']}
                                />
                            </FormControl>
                            <Textarea
                                id="notesText"
                                name="notesText"
                                label="Notes *"
                                value={this.state.formData.notesText}
                                rows={5}
                                error={formErrors && formErrors['notesText']}
                                onChange={this.handleChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleNotesClose} variant="outlined" color="default">Cancel</Button>
                            <Button onClick={this.handleNotesSuccess} autoFocus variant="contained" color="primary">Save</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarNotesOpen}
                        onClose={this.handleNotesSnackbarClose}
                        message='You have successfully added new notes'
                    />

                    {/* FILES */}
                    <Dialog 
                        open={this.state.dialogFilesOpen} 
                        onClose={this.handleFilesClose} 
                        fullWidth={true} 
                        maxWidth="sm" 
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title">Add Files</DialogTitle>
                        <DialogContent>
                            <FormControl error={formErrors && formErrors['fileType'] && true} fullWidth margin="normal">
                                <AutoCompleteSelect
                                    options={[
                                        {value: 'Dispatch', label: 'Dispatch Note'},
                                        {value: 'Confirmation', label: 'Confirmation'},
                                        ssr.sr_return_type === 'Credit' ? {value: 'Credit', label: 'Credit Note'} : {value: 'Delivery', label: 'Delivery Note'},
                                        {value: 'Faulty Stock Report', label: 'Faulty Stock Report'},
                                        {value: 'Photo', label: 'Photos'},
                                        {value: 'Video', label: 'Videos'}
                                    ]}
                                    label='File Type *'
                                    value={formData.fileType}
                                    onChange={this.handleSelectChange('fileType')}
                                    error={formErrors && formErrors['fileType'] && true}
                                    errorText={formErrors && formErrors['fileType'] && formErrors['fileType']}
                                />
                            </FormControl>
                            {formData.fileType !== 'Photo' ? 
                                <DragFileInput
                                    id="file"
                                    name="file"
                                    label='File *'
                                    file={formData.file}
                                    errorText={formErrors && formErrors['file']}
                                    onChange={this.handleFileChange}
                                    cancelOnClick={this.clearFile}
                                    emptyText='No file selected'
                                /> : 
                                <DragFileInputMulti
                                    id="imgUploads"
                                    name="imgUploads[]"
                                    label={formData.fileType !== 'Photo' ? "Upload Video(s) (.mp4, .mov, .avi, .wmv, .flv, .mpeg, .mpg, .m4v)" : "Upload Image(s) (.jpg, .jpeg, .tiff, .gif)"}
                                    file={formData.imgUploads}
                                    type="image"
                                    error={formErrors && formErrors['file'] && true}
                                    errorText={formErrors && formErrors['file']}
                                    onChange={this.handleImageUploads}
                                    cancelOnClick={this.clearImage}
                                    emptyText={formData.fileType !== 'Photo' ? 'No video(s) uploaded' : 'No image(s) uploaded' }
                            />
                            }
                        </DialogContent>
                        <DialogActions>
                            <Typography variant="subtitle2" style={{flex: 1}}>
                                <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                            </Typography>
                            <Button onClick={this.handleFilesClose} variant="outlined" color="default">Cancel</Button>
                            <Button onClick={this.handleFilesSuccess} color="primary" autoFocus variant="contained">Upload</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarFilesOpen}
                        onClose={this.handleFilesSnackbarClose}
                        message='You have successfully added a new file'
                    />
                    {/* PHOTOS */}
                    <Dialog 
                        open={this.state.dialogPhotoOpen} 
                        onClose={this.handlePhotoClose} 
                        fullWidth={true} 
                        maxWidth="sm" 
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title">{this.state.dialogPhotoFileName}</DialogTitle>
                        <DialogContent style={{textAlign:'center'}}>
                            <ImageWithError src={this.state.dialogPhotoURL} alt={this.state.dialogPhotoFileName} style={{maxWidth:'100%'}} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handlePhotoClose} variant="outlined" color="default">Close</Button>
                        </DialogActions>
                    </Dialog>
                    {/* CANCEL */}
                    <Dialog open={this.state.dialogCancelOpen} onClose={this.handleCancelClose} fullWidth={true} maxWidth="sm" scroll="body" disableEscapeKeyDown disableBackdropClick>
                        <DialogTitle id="form-dialog-title" color="secondary">You Are About To <span style={{color:colors.red}}>Cancel</span> This Stock Return</DialogTitle>
                        <DialogContent>
                            <Typography variant="body1">Please add notes relating to this stock return:</Typography>
                            <Textarea
                                id="cancellationNotes"
                                name="cancellationNotes"
                                label="Notes"
                                value={this.state.formData.cancellationNotes}
                                rows={5}
                                error={formErrors && formErrors['cancellationNotes']}
                                onChange={this.handleChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCancelClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={this.handleCancelSuccess} color="secondary" autoFocus variant="contained" disabled={!this.state.formData.cancellationNotes}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarCancelOpen}
                        onClose={this.handleCancelSnackbarClose}
                        message='You have successfully cancelled this stock return'
                    />
                    {/* AMEND */}
                    <Dialog 
                        open={this.state.dialogAmendOpen} 
                        onClose={this.handleAmendClose} 
                        fullWidth={true} 
                        maxWidth="md" 
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                       <DialogTitle id="form-dialog-title" color="secondary">Do You Want To Amend This Stock Return?</DialogTitle>
                        <DialogContent>
                            <TextField
                                id="currentType"
                                name="currentType"
                                label="Return Type"
                                value={ssr.sr_return_type}
                                margin="normal"
                                fullWidth
                                disabled
                            />
                            <FormControl error={formErrors && formErrors['amendTo'] && true} fullWidth margin="normal">
                                <AutoCompleteSelect
                                options={[
                                        {value: 'Repair', label: 'Repair', disabled: ssr.sr_return_type === 'Repair'},
                                        {value: 'Replace', label: 'Replace', disabled: ssr.sr_return_type === 'Replace'},
                                        {value: 'Credit', label: 'Credit', disabled: ssr.sr_return_type === 'Credit'}
                                    ]}
                                    label='Amend To *'
                                    value={this.state.formData.amendTo}
                                    onChange={this.handleSelectChange('amendTo')}
                                    error={formErrors && formErrors['amendTo'] && true}
                                    errorText={formErrors && formErrors['amendTo'] && formErrors['amendTo']}
                                />
                            </FormControl>
                            {this.state.formData.amendTo === 'Credit' &&
                                <React.Fragment>
                                    <br></br><br></br>
                                    <Typography variant="body1">
                                        Please select the relevant nominal codes for the parts on this stock return:
                                    </Typography>
                                    <Table>
                                        <TableBody>
                                        {formData.amendRows && formData.amendRows.map((row, idx) => (
                                            <TableRow key={row.srdId}>
                                                <TableCell style={{verticalAlign:'bottom', width:'1%', whiteSpace: 'nowrap'}}>{row.partNumber + ' - ' + row.partDescription}</TableCell>
                                                <TableCell>
                                                    <FormControl fullWidth>
                                                        <AutoCompleteSelect 
                                                            label='Nominal Code *'
                                                            options={row.nominalList} 
                                                            value={row.nominalCode}
                                                            error={formErrors && formErrors['amendRows|nominalCode|'+idx] && true}
                                                            errorText={formErrors && formErrors['amendRows|nominalCode|'+idx]}
                                                            onChange={this.handleSelectRowChange(idx, 'nominalCode')}
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </React.Fragment>
                            }
                            <br></br>
                        </DialogContent>
                        <DialogActions>
                            <Typography variant="subtitle2">Clicking 'Amend' will update the version of this supplier stock return</Typography>
                            <Button onClick={this.handleAmendClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={this.handleAmendSuccess} color="primary" autoFocus variant="contained">Amend</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarAmendOpen}
                        onClose={this.handleAmendSnackbarClose}
                        message='You have successfully amended this stock return'
                    />
                    {/* EMAIL */}
                    <Dialog 
                        open={this.state.dialogEmailOpen} 
                        onClose={this.handleEmailClose} 
                        fullWidth={true} 
                        maxWidth="sm" 
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title" color="secondary">Email Supplier</DialogTitle>
                        <DialogContent>
                            {formErrors && formErrors.generic && (
                                <Typography component={"p"} style={{color: colors.red}}>
                                    {formErrors.generic}
                                </Typography>
                            )}
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteMultiSelect
                                    name="emailTo"
                                    options={this.state.supplierContactList}
                                    label='Email To *'
                                    value={formData.emailTo}
                                    onChange={this.handleMultiSelectChange('emailTo')}
                                    error={formErrors && formErrors['emailTo'] && true}
                                    errorText={formErrors && formErrors['emailTo']}
                                />
                            </FormControl>
                            <FormControl error={formErrors && formErrors['emailTemplate'] && true} fullWidth margin="normal">
                                <AutoCompleteSelect
                                    options={this.state.templateList} 
                                    label='Email Template'
                                    value={this.state.formData.emailTemplate}
                                    onChange={this.handleSelectChange('emailTemplate')}
                                    error={formErrors && formErrors['emailTemplate'] && true}
                                    errorText={formErrors && formErrors['emailTemplate'] && formErrors['emailTemplate']}
                                />
                            </FormControl>
                            <Textarea
                                id="emailText"
                                name="emailText"
                                label="Email Text *"
                                value={this.state.formData.emailText}
                                rows={5}
                                error={formErrors && formErrors['emailText']}
                                onChange={this.handleChange}
                            />
                            <br></br><br></br>
                            <Typography variant="subtitle1"><FALightIcon icon='paperclip' /> Attachments Files</Typography>
                            {formErrors && formErrors.attachment && (
                                <Typography component={"p"} variant="body2" style={{color: colors.red}}>
                                    {formErrors.attachment}
                                </Typography>
                            )}
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteMultiSelect
                                    name="emailStockReturnForms"
                                    options={this.state.stockReturnList}
                                    label='Stock Return Form(s)'
                                    value={formData.emailStockReturnForms}
                                    onChange={this.handleMultiSelectChange('emailStockReturnForms')}
                                    error={formErrors && formErrors['emailStockReturnForms'] && true}
                                    errorText={formErrors && formErrors['emailStockReturnForms']}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteMultiSelect
                                    name="emailDispatchNotes"
                                    options={this.state.dispatchNoteList}
                                    label='Dispatch Note(s)'
                                    value={formData.emailDispatchNotes}
                                    onChange={this.handleMultiSelectChange('emailDispatchNotes')}
                                    error={formErrors && formErrors['emailDispatchNotes'] && true}
                                    errorText={formErrors && formErrors['emailDispatchNotes']}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteMultiSelect
                                    name="emailPhotos"
                                    options={this.state.photoList}
                                    label='Photo(s)'
                                    value={formData.emailPhotos}
                                    onChange={this.handleMultiSelectChange('emailPhotos')}
                                    error={formErrors && formErrors['emailPhotos'] && true}
                                    errorText={formErrors && formErrors['emailPhotos']}
                                />
                            </FormControl>
                            <Typography variant="subtitle2" style={{marginTop:20}}>
                                <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum attatchment limit 10MB
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleEmailClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={this.handleEmailSuccess} color="primary" autoFocus variant="contained">Send Email</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarEmailOpen}
                        onClose={this.handleEmailSnackbarClose}
                        message='You have successfully sent the email'
                    />
                </Grid>
            );
        }
    }
}
const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewSupplierStockReturn));