import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import STLViewer from 'stl-viewer';

const styles = theme => ({
    buttons: {
        color: '#0282C6',
    },
    [theme.breakpoints.down('sm')]:{
        dialog: {
            width: '100vw',
            height: '100vh'
        }
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ModelViewerDialog extends React.Component {
  render() {
      const { classes } = this.props;
    const modelSize = window.innerWidth < 600 ? 200 : 550;
    return (
      <React.Fragment>
            <Dialog
                open={this.props.open}
                TransitionComponent={Transition}
                onClose={this.props.close}
                className={classes.dialog}
                scroll="body"
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>                   
                    <STLViewer
                        model={this.props.modelUrl}
                        modelColor='#F3F3F3'
                        backgroundColor='#FFF'
                        width={modelSize}
                        height={modelSize}
                        rotate={false}
                        orbitControls={true}
                        url={this.props.modelUrl}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.close} variant="outlined" color="default">Cancel</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
  }
}

export default withStyles(styles)(ModelViewerDialog);