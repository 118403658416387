import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    Grid,
    CardHeader,
    Card,
    Avatar,
    IconButton,
    Typography,
    MenuItem,
    ListItemText,
    ListItemIcon,
    CardContent,
    Menu,
    TextField,
    Link
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import API from 'API';
import ViewVehicleMiniProfileCard from 'Components/Vehicles/ViewVehicle/ViewVehicleMiniProfileCard';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import BackButton from 'Components/Common/Buttons/BackButton';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import { DialAdornment, downloadS3File } from 'Functions/MiscFunctions';
import Textarea from 'Components/Common/Inputs/Textarea';
import { handleAppError } from 'Actions/UI/UI';

const initialState = {
    access: {
        updateEngineer: false,
        updateEngineerVehicle: false,
    },
    e: {
        engineer: {},
        vehicle: {},
    },
    engineerProfileMenuAnchor: null,
    isLoading: true,
}

class ViewEngineer extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        if(this.props?.loggedInStaff?.id !== 0){ // loggedInStaff is sometimes empty on refresh - this is a workaround as it will later call componentDidUpdate
            this.checkAccess();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loggedInStaff !== this.props?.loggedInStaff) {
            this.checkAccess();
        }
    }

    checkAccess = () => {
        // User must have access to 'View all Engineers' or 'View my Engineer Profile'
        Promise.all([
            API.get('/staff/my/access/check/view-all-engineers'),
            API.get('/staff/my/access/check/view-engineer')
        ])
        .then(([allEngineers, viewEngineer]) =>  {

            // If user has access to View Engineer they can only view their own profile. If they have access to View All Engineers they can view any profile.
            if((allEngineers.data?.has_access || (viewEngineer.data?.has_access && this.props.loggedInStaff.engId === parseInt(this.props.match.params.engineerId)))) {
                this.loadComponentData();
            }
            else {
                this.props.handleAppError("UNAUTHORIZED", this.props.location.pathname)
                this.props.history.replace('/')
            }
        });
    }

    loadComponentData = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-engineer'),
            API.get('/staff/my/access/check/update-engineer-vehicle'),
            API.get('/engineers/' + this.props.match.params.engineerId),
        ])
        .then(([updateEngineerRes, updateEngineerVehicleRes, engineerRes]) => {
            this.setState({
                ...this.state,
                access: {
                    updateEngineer: (updateEngineerRes.data && updateEngineerRes.data.has_access) || false,
                    updateEngineerVehicle: (updateEngineerVehicleRes.data && updateEngineerVehicleRes.data.has_access) || false,
                },                
                e: engineerRes.data,
                isLoading: false,
            });
        });      
    }

    engineerMenuOpen = event => {
        this.setState({ engineerProfileMenuAnchor: event.currentTarget });
    };  
    engineerMenuClose = path => {
        this.setState({ engineerProfileMenuAnchor: null }, () => {
            this.props.history.push(path);
        });
    };

    render() {
        const { history } = this.props;
        const { access, e, isLoading, engineerProfileMenuAnchor } = this.state;
        const { engineer, vehicleAllocations } = e;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        View Engineer
                    </Typography>
                </Grid>
                {(isLoading && (
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <LoadingCircle />
                        </PaddedPaper>
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12} lg={6}>
                            <Grid container item spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        <b>Personal Details</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card> 
                                        <>
                                            <CardHeader
                                                avatar={
                                                    <Avatar alt={`${engineer?.fn} ${engineer?.ln}`} src={e.photo} style={{height: 80, width: 80, margin: 0}} />
                                                }
                                                action={
                                                    access.updateEngineer ? (
                                                        <IconButton
                                                            aria-owns={engineerProfileMenuAnchor ? 'engineerMenu' : undefined}
                                                            aria-haspopup="true"
                                                            onClick={this.engineerMenuOpen}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                    ) : (
                                                    ''
                                                    )
                                                }
                                                disableTypography
                                                title={<Typography variant='h5'>{engineer?.fn} {engineer?.ln}</Typography>}
                                            />
                                            {access.updateEngineer && (
                                                <Menu id="engineerMenu"
                                                    anchorEl={engineerProfileMenuAnchor}
                                                    open={Boolean(engineerProfileMenuAnchor)}
                                                    onClose={this.engineerMenuClose}
                                                >
                                                    {access.updateEngineer && (
                                                    <MenuItem onClick={()=>{ this.engineerMenuClose(`/engineers/update/${this.props.match.params.engineerId}`);}}>
                                                        <ListItemIcon>
                                                            <EditIcon />
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            Update Engineer
                                                        </ListItemText>
                                                    </MenuItem>
                                                    )}
                                                </Menu>
                                            )}
                                            <CardContent>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <TextField 
                                                            label='Company'
                                                            disabled={true}
                                                            value={engineer?.company}
                                                            margin="normal"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField 
                                                            label='Email'
                                                            disabled={true}
                                                            value={engineer?.email}
                                                            margin="normal"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField 
                                                            label='Work Mobile'
                                                            disabled={true}
                                                            value={engineer?.mobile}
                                                            margin="normal"
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: <DialAdornment number={engineer?.mobile ?? ''} />
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {e?.type == "Internal" && (
                                                            <>
                                                            <Typography variant="caption" paragraph>
                                                                <b>Signature</b>
                                                            </Typography>
                                                            <ImageWithError type="SIGNATURE" alt={`${engineer?.fn} ${engineer?.ln}'s signature`} src={engineer?.signature} style={{maxHeight: 100}} />
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <div className='buttonRow'>
                                                    <BackButton props={history} />
                                                </div>
                                            </CardContent>
                                        </>
                                    </Card>
                                </Grid>
                                
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {e?.type == "Internal" && (
                                <>
                                <Typography variant="body1">
                                    <b>Vehicle Allocations</b>
                                </Typography>
                                <Typography variant="caption" paragraph>
                                    The vehicles this engineer is currently allocated to
                                </Typography>
                                <PaddedPaper>
                                    {vehicleAllocations?.length > 0 ?
                                        vehicleAllocations?.map((vehicle, index) => {
                                            return (
                                                <ViewVehicleMiniProfileCard key={index} vehicleData={vehicle} />
                                            )
                                        })
                                    :
                                        <Typography variant="body2">
                                            <em>No vehicle allocations</em>       
                                        </Typography>
                                    }
                                </PaddedPaper>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <b>External Profiles</b>
                            </Typography>
                            <Typography variant="caption" paragraph>
                                The details of any external profiles linked to this engineer            
                            </Typography>
                            <Grid container item spacing={3}>
                                {e?.externalProfiles?.length > 0 ?
                                    e?.externalProfiles.map((prof, idx) => {
                                        return (
                                            <Grid item xs={12} md={4}>
                                                <PaddedPaper>
                                                    <Grid container item spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Grid container item spacing={1} alignItems='center'>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6">
                                                                        {prof?.nm}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Textarea
                                                                id="details"
                                                                label="Details"
                                                                value={prof?.details}
                                                                disabled
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Link component="div" variant="body2" className='blueLink' style={{cursor: 'pointer'}} onClick={() => {downloadS3File(prof?.fileUrl)}}>
                                                                {prof?.file}
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        )
                                    })
                                :
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Typography variant="body2">
                                                <em>No External Profiles</em>       
                                            </Typography>
                                        </PaddedPaper>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <b>Qualifications</b>
                            </Typography>
                            <Typography variant="caption" paragraph>
                                The qualifications linked to this engineer. A reminder email will be sent <b>1 month</b> before any qualifications expire.
                            </Typography>
                            <Grid container item spacing={3}>
                                {e?.qualifications?.length > 0 ?
                                    e?.qualifications.map((qual, idx) => {
                                        return (
                                            <Grid key={idx} item xs={12} md={4}>
                                                <PaddedPaper>
                                                    <Grid container item spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Grid container item spacing={1} alignItems='center'>
                                                                <Grid item xs={12}>
                                                                    <TextField 
                                                                        label="Name"
                                                                        disabled
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        inputProps={{
                                                                            className:"textDefault"
                                                                        }}
                                                                        value={qual?.name}
                                                                        margin="normal"
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Textarea
                                                                id="details"
                                                                label="Details"
                                                                value={qual?.details}
                                                                disabled
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <DatePicker
                                                                type="date"
                                                                label={'Expiry Date'}
                                                                value={qual?.expiryDate != '0000-00-00' ? qual?.expiryDate : null}
                                                                disabled={true}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Link component="div" variant="body2" className='blueLink' style={{cursor: 'pointer'}} onClick={() => {downloadS3File(qual?.fileUrl)}}>
                                                                {qual?.file}
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        )
                                    })
                                :
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Typography variant="body2">
                                                <em>No Qualifications</em> 
                                            </Typography>
                                        </PaddedPaper>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    loggedInStaff: state.staffAuth.staff
})

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError: (error, message) => dispatch(handleAppError(error, message)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewEngineer);