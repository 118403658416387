
import React from 'react';
import { useDispatch } from 'react-redux';
import { closeDialog } from 'Actions/Dialog/Dialog';
import { Button, Grid, Box } from '@material-ui/core';
import ViewEmail from '../ViewEmail';
import { colors } from 'Helpers/ColourHelper';

export default function({emailId}) {

    let dispatch = useDispatch();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box
                    style={{
                        margin: '1em',
                        border: `1px solid ${colors.grey}20`,
                        borderRadius: '5px',
                    }}
                >
                    <ViewEmail 
                        emailId={emailId}    
                    />
                </Box>
            </Grid>
            <Grid item xs={12} className='buttonRow'>
                <Button
                    variant="outlined"
                    onClick={() => dispatch(closeDialog())}
                >
                    Close
                </Button>
            </Grid>
        </Grid>
    )

}