import { Card, CardContent, CardHeader, Grid, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import API from 'API';
import BarChart from 'Components/Common/Charts/BarChart';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { getColorArrow, getColorCurrencyChange, getColorPercentageChange, getCurrency, getMarginPercent, getPlainPercentage, months } from 'Helpers/ReportsHelper';
import { getOrdinal } from 'Helpers/TimeHelper';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import MonthsBlock from './MonthsBlock';

let initialState = (props) => ({
    isLoading: true,
    globalData: {},
    data: {},
    view: { value: 'year', allowCurrent: props.current },
    showCurrent: props.current,
    partSearch: '',
})

class PartCategoriesReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.year !== this.props.year || prevProps.current !== this.props.current){
            this.setState(initialState(this.props));
            this.getData();
        }
    }

    getData = () => {
        Promise.all([
            API.get('/reports/performanceReport/sales/year', {params: {year:this.props.year, current: this.props.current ? 1 : 0}}),
            API.get(`/parts/catagories`),
        ])
        .then(response => {
            let partCatagories = {};
            _.each(response[1].data, i => partCatagories[i.pc_id] = i.pc_name);
            this.setState({
                globalData: response[0].data,
                partCatagories: partCatagories
            }, this.setData);
        })
    }

    setView = (view) => () => {
        this.setState({view}, this.setData);
    }

    setData = () => {
        let time = this.state.showCurrent ? 'c' : 't';
        this.setState({
            data: this.state.view.value === 'year' ? {
                'b': this.state.globalData['b'][time].t,
                't': this.state.globalData['t'][time].t
            } : {
                'b': this.state.globalData['b'][time].m[this.state.view.value],
                't': this.state.globalData['t'][time].m[this.state.view.value],
            },
            isLoading: false
        });
    }


    render() {

        const {isLoading, data, view, showCurrent} = this.state;
        const {year} = this.props;

        let
            currentYear = `${year-1}/${year}`;
        if (view.value !== 'year') {
            currentYear = `${months[view.value].slice(0,3)} ${view.value<10 ? year : (year-1)}`;
        }

        return (
            <Grid container spacing={2}>
               <Grid item xs={12}>
                    <MonthsBlock {...this.props} view={view} showCurrent={showCurrent}
                        setCurrentView={(showCurrent,v)=>{this.setState({ showCurrent }, this.setView(v))}}
                        setView={v=>{this.setState({ showCurrent: false }, this.setView(v))}}
                    />
                </Grid>
                {isLoading ?
                    <Grid item xs={12}>
                        <LoadingCircle/>
                    </Grid> :
                    <>
                       <Grid item xs={12} lg={12}>
                            <PaddedPaper>
                                <BarChart
                                    data={
                                        _.map(
                                            _.orderBy(_.map(data.b.c, (d, i) => ({
                                                i: i,
                                                b: d,
                                                t: data.t.c[i] ? data.t.c[i] : {b: 0, c: 0},
                                                n: this.state.partCatagories[i]
                                            })), 'n', 'asc'),
                                            i => ({
                                                label: i.n,
                                                value: {
                                                    [currentYear] : i.t.b,
                                                    'Budget' :  i.b.b,
                                                },
                                                color: {
                                                    [currentYear] : colors.primary,
                                                    'Budget' :    colors.red,
                                                },
                                            })
                                        )
                                    } 
                                    yLabel={i => `${i<0?'-':''}£${Math.abs(i)}`}
                                    tLabel={(e)=>`${parseFloat(e.raw)<0?'-':''}£${parseFloat(Math.abs(e.raw)).toFixed(2)}`}
                                    style={{
                                        height: 300,
                                    }}
                                />
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Card>
                                <CardHeader
                                    // avatar={<AllIcon icon={ IconHelper.percent} size='medium' style={{color: '#fff'}} />}
                                    style={{background:'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', color: '#FFF'}}
                                    title={
                                        <Grid container spacing={1} style={{alignItems: 'flex-end'}}>
                                            <Grid item >
                                                <>Part Categories Report</>
                                            </Grid>
                                            <Grid item >
                                                <Typography variant="body1" style={{paddingBottom: 1}} >{currentYear} vs. Budget {(this.state.showCurrent) ? ` Up Until ${getOrdinal(moment().format('D'))} ${moment().format('MMMM')}` : ''}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    titleTypographyProps={{variant:'h6'}}
                                />
                                <CardContent style={{padding:5}}>
                                    <Table size="small" aria-label="a dense table" style={{color:'black'}}>
                                        <TableHead style={{backgroundColor:'#f5f5f5'}}>
                                            <TableRow>
                                                <TableCell align='center'   style={{width:'20%', borderBottom: 'none', color:'black'}}></TableCell>
                                                <TableCell align='center'   style={{width:'20%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Sales</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'20%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Cost</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'20%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Margin £</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'20%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Margin %</b></Typography></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: '0px solid black'}}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography>
                                                                {currentYear}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography>
                                                                Budget
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            Difference
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            Difference %
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                {
                                                    _.map([
                                                        {
                                                            heading: <>Total<br/>Sales</>,
                                                            k: 'b',
                                                        },
                                                        {
                                                            heading: <>Total<br/>Cost</>,
                                                            k: 'c',
                                                        },
                                                    ], ({heading, k}) => 
                                                        <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    <Typography>
                                                                        {getCurrency(data.t.t[k])}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography>
                                                                        {getCurrency(data.b.t[k])}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getColorCurrencyChange(data.t.t[k], data.b.t[k])}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getColorPercentageChange(data.t.t[k], data.b.t[k])}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    )
                                                }
                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: '0px solid black'}}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(data.t.t.b - data.t.t.c)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(data.b.t.b - data.b.t.c)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}> 
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange((data.t.t.b - data.t.t.c), (data.b.t.b - data.b.t.c) )}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange((data.t.t.b - data.t.t.c), (data.b.t.b - data.b.t.c) )}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: '0px solid black'}}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getMarginPercent(data.t.t.b , data.t.t.c)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getMarginPercent(data.b.t.b , data.b.t.c)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} style={{
                                                            height: 28.04 * 2,
                                                            alignContent: 'center'
                                                        }}
                                                            container
                                                        >
                                                            <Grid item xs={12}>
                                                                <Typography variant="h6" style={{fontSize: '14px', color: 
                                                                        parseFloat(
                                                                            getPlainPercentage( parseFloat(data.t.t.b - data.t.t.c) , parseFloat(data.t.t.b) ) -
                                                                            getPlainPercentage( parseFloat(data.b.t.b - data.b.t.c) , parseFloat(data.b.t.b) ) 
                                                                        ) === 0 ? colors.green :
                                                                        parseFloat(
                                                                            getPlainPercentage( parseFloat(data.t.t.b - data.t.t.c) , parseFloat(data.t.t.b) ) -
                                                                            getPlainPercentage( parseFloat(data.b.t.b - data.b.t.c) , parseFloat(data.b.t.b) ) 
                                                                        ) > 0 ? colors.green : colors.red
                                                                    }}
                                                                >
                                                                    {
                                                                        getColorArrow(
                                                                            parseFloat(
                                                                                getPlainPercentage( parseFloat(data.t.t.b - data.t.t.c) , parseFloat(data.t.t.b) ) -
                                                                                getPlainPercentage( parseFloat(data.b.t.b - data.b.t.c) , parseFloat(data.b.t.b) ) 
                                                                            )
                                                                        )
                                                                    }
                                                                    {
                                                                            parseFloat(
                                                                            getPlainPercentage( parseFloat(data.t.t.b - data.t.t.c) , parseFloat(data.t.t.b) ) -
                                                                            getPlainPercentage( parseFloat(data.b.t.b - data.b.t.c) , parseFloat(data.b.t.b) ) 
                                                                        ) === 0 ? '' :
                                                                        parseFloat(
                                                                            getPlainPercentage( parseFloat(data.t.t.b - data.t.t.c) , parseFloat(data.t.t.b) ) -
                                                                            getPlainPercentage( parseFloat(data.b.t.b - data.b.t.c) , parseFloat(data.b.t.b) ) 
                                                                        ) > 0 ? '+' : '-'
                                                                    }
                                                                    {
                                                                        parseFloat(
                                                                            Math.abs(
                                                                                parseFloat(
                                                                                    getPlainPercentage( parseFloat(data.t.t.b - data.t.t.c) , parseFloat(data.t.t.b) ) -
                                                                                    getPlainPercentage( parseFloat(data.b.t.b - data.b.t.c) , parseFloat(data.b.t.b) ) 
                                                                                )
                                                                            )
                                                                        ).toFixed(2)
                                                                    }%
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                        {/* <Grid item xs={7}>
                            <Card>
                                <CardHeader
                                    avatar={<AllIcon icon={ IconHelper.percent} size='medium' style={{color: '#fff'}} />}
                                    style={{background:'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', color: '#FFF'}}
                                    title={
                                        <Grid container spacing={1} style={{alignItems: 'flex-end'}}>
                                            <Grid item >
                                                <>Margins Report</>
                                            </Grid>
                                            <Grid item >
                                                <Typography variant="body1" style={{paddingBottom: 1}} >{currentYear} {(this.props.current ) ? ` Up Until ${getOrdinal(moment().format('D'))} ${moment().format('MMMM')}` : ''}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    titleTypographyProps={{variant:'h6'}}
                                />
                                <CardContent style={{padding:5}}>
                                    <Table size="small" aria-label="a dense table" style={{color:'black'}}>
                                        <TableHead style={{backgroundColor:'#f5f5f5'}}>
                                            <TableRow>
                                                <TableCell align='center'   style={{width:'5%', borderBottom: 'none', color:'black'}}></TableCell>
                                                <TableCell align='center'   style={{width:'9%', borderBottom: 'none', color:'black'}}></TableCell>
                                                <TableCell align='center'   style={{width:'14%', borderBottom: 'none', borderBottom: 'none', color:'black',  textAlign:'center'}}><Typography style={{fontWeight: '600'}}><b>Cost</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'14%', borderBottom: 'none', borderBottom: 'none', color:'black', textAlign:'center'}}><Typography style={{fontWeight: '600'}}><b>Net</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'14%', borderBottom: 'none', borderBottom: 'none', color:'black', textAlign:'center'}}><Typography style={{fontWeight: '600'}}><b>Margin</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'14%', borderBottom: 'none', borderBottom: 'none', color:'black', textAlign:'center'}}><Typography style={{fontWeight: '600'}}><b>Difference</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'14%', borderBottom: 'none', borderBottom: 'none', color:'black', textAlign:'center'}}><Typography style={{fontWeight: '600'}}><b>Margin %</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'14%', borderBottom: 'none', borderBottom: 'none', color:'black', textAlign:'center'}}><Typography style={{fontWeight: '600'}}><b>Change</b></Typography></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {_.map([
                                            {
                                                name: 'Products',
                                                icon: IconHelper.products,
                                                k: 'p'
                                            },
                                            {
                                                name: 'Services',
                                                icon: IconHelper.services,
                                                k: 's'
                                            },
                                            {
                                                name: 'Total',
                                                icon: IconHelper.total,
                                                k: 't'
                                            }
                                        ], ({name, icon, k}, idx) => 
                                                    <TableRow>
                                                        <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <AllIcon icon={icon} size='medium'/> 
                                                            <Typography variant="body1" style={{textTransform: 'capitalize'}}>{name}</Typography>
                                                        </TableCell>
                                                        <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {currentYear}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    Budget
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        {_.map(['c', 'b'], j => 
                                                            <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                                <Grid container spacing={1}>
                                                                    {_.map(['t', 'b'], l =>
                                                                        <Grid item xs={12}>
                                                                            {getCurrency(data[l][k][j])}
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </TableCell>
                                                        )}
                                                        <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                {_.map(['t', 'b'], l =>
                                                                    <Grid item xs={12}>
                                                                        {getCurrency(data[l][k].b - data[l][k].c)}
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getColorCurrencyChange((data.t[k].b - data.t[k].c), (data.b[k].b - data.b[k].c))}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                {_.map(['t', 'b'], l =>
                                                                    <Grid item xs={12}>
                                                                        {getMarginPercent(data[l][k].b,data[l][k].c)}
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {
                                                                        getColorPercentageChange(
                                                                            getPlainPercentage(parseFloat(data.t[k].b -  data.t[k].c), data.t[k].b),
                                                                            getPlainPercentage(parseFloat(data.b[k].b -  data.b[k].c), data.b[k].b)
                                                                        )
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item style={{width: `${parseFloat(100/12) * 2.5}%`}}>
                            <PaddedPaper style={{padding: 10}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} style={{textAlign:'center'}}>
                                        <Typography variant="h6">
                                            <b>{currentYear}</b> <InfoIcon placement='top' info=
                                                    {<Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            Pie Chart Showing Margin £
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <div style={{height: 15, width: 15, borderRadius: 15, backgroundColor: colors.green}}></div>
                                                        </Grid>
                                                        <Grid item xs={11}>Products</Grid>
                                                        <Grid item xs={1}>
                                                            <div style={{height: 15, width: 15, borderRadius: 15, backgroundColor: colors.primary}}></div>
                                                        </Grid>
                                                        <Grid item xs={11}>Services</Grid>
                                                    </Grid>} 
                                                />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PieChart 
                                            data={[
                                                {label: 'Parts', value: parseFloat(data.t.p.b - data.t.p.c), color: colors.green},
                                                {label: 'Services', value: parseFloat(data.t.s.b - data.t.s.c), color: colors.primary},
                                            ]} 
                                            style={{ height: 250 }}
                                            hideLegend
                                            tLabel={(e)=> getCurrency(e.raw)}
                                        />
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item style={{width: `${parseFloat(100/12) * 2.5}%`}}>
                            <PaddedPaper style={{padding: 10}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} style={{textAlign:'center'}}>
                                        <Typography variant="h6">
                                            <b>Budget</b> <InfoIcon placement='top' info=
                                                    {<Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            Pie Chart Showing Budgets Margin £
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <div style={{height: 15, width: 15, borderRadius: 15, backgroundColor: colors.green}}></div>
                                                        </Grid>
                                                        <Grid item xs={11}>Products</Grid>
                                                        <Grid item xs={1}>
                                                            <div style={{height: 15, width: 15, borderRadius: 15, backgroundColor: colors.primary}}></div>
                                                        </Grid>
                                                        <Grid item xs={11}>Services</Grid>
                                                    </Grid>} 
                                                />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PieChart 
                                            data={[
                                                {label: 'Parts', value: parseFloat(data.b.p.b - data.b.p.c), color: `${colors.green}75`},
                                                {label: 'Services', value: parseFloat(data.b.s.b - data.b.s.c), color: `${colors.primary}75`},
                                            ]} 
                                            style={{ height: 250 }}
                                            hideLegend
                                            tLabel={(e)=> getCurrency(e.raw)}
                                        />
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid> */}
                        <Grid item xs={5}>
                            <TextField 
                                placeholder='Search Category...'
                                fullWidth
                                variant="outlined"
                                value={this.state.partSearch}
                                onChange={(e)=>this.setState({partSearch: e.target.value})}
                                inputProps={{
                                    style: {padding: '10px'},
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment  position="end">
                                            <AllIcon icon={IconHelper.refresh} size='small' onClick={()=>this.setState({partSearch: ''})} noMargin/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CiDataTable
                                config={{
                                    key: 'i',
                                    pagination: false,
                                    alternatingRowColours: true,
                                    responsiveimportant: true,
                                    isLoading: isLoading,
                                    sticky:true
                                }}
                                rows={
                                    _.orderBy(
                                        _.filter(
                                            _.map(data.b.c, (d, i) => ({
                                                i: i,
                                                b: d,
                                                t: data.t.c[i] ? data.t.c[i] : {b: 0, c: 0},
                                                n: this.state.partCatagories[i]
                                            })),
                                            i => this.state.partSearch ? i.n.toLowerCase().includes(this.state.partSearch.toLowerCase() ) : 1
                                        ), 'n', 'asc')
                                }
                                columns={[
                                    {
                                        heading: 'name',
                                        field: 'n',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: '',
                                        field: () => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{currentYear}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>Budget</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>Difference</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>Difference %</Typography>
                                                </Grid>
                                            </Grid>,
                                        sizeToContent: true
                                    },
                                    ..._.map([
                                        {
                                            heading: <>Total<br/>Sales</>,
                                            k: 'b',
                                        },
                                        {
                                            heading: <>Total<br/>Cost</>,
                                            k: 'c',
                                        },
                                    ], ({heading, k}) => 
                                        (
                                            {
                                                heading: heading,
                                                sizeToContent: true,
                                                dataRef: `t.${k}`,
                                                field: i =>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(i.t[k])}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(i.b[k])}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}> 
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange(i.t[k], i.b[k] )}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(i.t[k], i.b[k] )}</Typography>
                                                        </Grid>
                                                    </Grid>,
                                            }
                                        )
                                    ),
                                    {
                                        heading: 'Margin £',
                                        sizeToContent: true,
                                        dataRef: i => (i.t.b - i.t.c),
                                        field: i =>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(i.t.b - i.t.c)}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(i.b.b - i.b.c)}</Typography>
                                                </Grid>
                                                <Grid item xs={12}> 
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange((i.t.b - i.t.c), (i.b.b - i.b.c) )}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange((i.t.b - i.t.c), (i.b.b - i.b.c) )}</Typography>
                                                </Grid>
                                            </Grid>,
                                    },
                                    {
                                        heading: 'Margin %',
                                        sizeToContent: true,
                                        dataRef: i => (i.t.b - i.t.c),
                                        field: i =>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" style={{fontSize: '14px'}}>{getMarginPercent(i.t.b , i.t.c)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" style={{fontSize: '14px'}}>{getMarginPercent(i.b.b , i.b.c)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{
                                                        height: 28.04 * 2,
                                                        alignContent: 'center'
                                                    }}
                                                        container
                                                    >
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px', color: 
                                                                    parseFloat(
                                                                        getPlainPercentage( parseFloat(i.t.b - i.t.c) , parseFloat(i.t.b) ) -
                                                                        getPlainPercentage( parseFloat(i.b.b - i.b.c) , parseFloat(i.b.b) ) 
                                                                    ) === 0 ? colors.green :
                                                                    parseFloat(
                                                                        getPlainPercentage( parseFloat(i.t.b - i.t.c) , parseFloat(i.t.b) ) -
                                                                        getPlainPercentage( parseFloat(i.b.b - i.b.c) , parseFloat(i.b.b) ) 
                                                                    ) > 0 ? colors.green : colors.red
                                                                }}
                                                            >
                                                                {
                                                                    getColorArrow(
                                                                        parseFloat(
                                                                            getPlainPercentage( parseFloat(i.t.b - i.t.c) , parseFloat(i.t.b) ) -
                                                                            getPlainPercentage( parseFloat(i.b.b - i.b.c) , parseFloat(i.b.b) ) 
                                                                        )
                                                                    )
                                                                }
                                                                {
                                                                        parseFloat(
                                                                        getPlainPercentage( parseFloat(i.t.b - i.t.c) , parseFloat(i.t.b) ) -
                                                                        getPlainPercentage( parseFloat(i.b.b - i.b.c) , parseFloat(i.b.b) ) 
                                                                    ) === 0 ? '' :
                                                                    parseFloat(
                                                                        getPlainPercentage( parseFloat(i.t.b - i.t.c) , parseFloat(i.t.b) ) -
                                                                        getPlainPercentage( parseFloat(i.b.b - i.b.c) , parseFloat(i.b.b) ) 
                                                                    ) > 0 ? '+' : '-'
                                                                }
                                                                {
                                                                    parseFloat(
                                                                        Math.abs(
                                                                            parseFloat(
                                                                                getPlainPercentage( parseFloat(i.t.b - i.t.c) , parseFloat(i.t.b) ) -
                                                                                getPlainPercentage( parseFloat(i.b.b - i.b.c) , parseFloat(i.b.b) ) 
                                                                            )
                                                                        )
                                                                    ).toFixed(2)
                                                                }%
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>,
                                    }
                                ]}
                            />
                        </Grid>
                </>}
            </Grid>
        )
    }
}

export default PartCategoriesReport;