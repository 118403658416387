import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    fad
 } from '@fortawesome/pro-duotone-svg-icons'

/*
 * Add Light Icons to the library
 */
library.add(fad);

const styles = theme => ({
    iconStyle:{
        marginRight:theme.spacing(1),
        //marginLeft:theme.spacing (1),
        color: 'rgba(0,0,0,0.65)'
    },
    iconStyleLight:{
        marginRight:theme.spacing(1),
        //marginLeft:theme.spacing (1),
        color: 'rgba(0,0,0,0.54)'
    },
    iconStyleDisabled:{
        marginRight:theme.spacing(1),
        //marginLeft:theme.spacing (1),
        color: 'rgba(0,0,0,0.2)'
    },
    iconStyleError:{
        marginRight:theme.spacing(1),
        //marginLeft:theme.spacing (1),
        color: '#f44336'
    }
});

class FADuoIcon extends Component {
    render() {
        return (
            <React.Fragment>
                <FontAwesomeIcon    icon={['fad', this.props.icon]}
                                    style={{
                                        cursor: this.props.link || (this.props.button || this.props.buttonPrimary) ? 'pointer' : 'default',
                                        color: (this.props.buttonPrimary && (!this.props.disabled && 'white')) || (this.props.heading &&  '#0282C6' ) ||  (this.props.color === 'primary' && '#0282C6') || (this.props.color === 'info' && '#0288D1') || (this.props.color === 'error' && '#d32f2f') ||  (this.props.color === 'success' && '#388E3C'),
                                        margin: this.props.noMargin && 0,
                                        height: (!this.props.fixedWidth && (this.props.size === 'avatar' ? '27.5' : (this.props.size === 'xxxxlarge' ? '150' : (this.props.size === 'xxxlarge' ? '100' : (this.props.size === 'xxlarge' ? '75' : (this.props.size === 'xlarge' ? '50' : ( this.props.size === 'large' ? '35' : (this.props.size === 'medium' ? '30' : (this.props.size === 'xmedium' ? '23' : (this.props.size === 'small' ? '15' : (this.props.size ? this.props.size :'20') /* Medium (default) */)))))))))),
                                        width: (!this.props.fixedHeight && (this.props.size === 'avatar' ? '27.5' : (this.props.size === 'xxxxlarge' ? '150' : (this.props.size === 'xxxlarge' ? '100' : (this.props.size === 'xxlarge' ? '75' : (this.props.size === 'xlarge' ? '50' : ( this.props.size === 'large' ? '35' : (this.props.size === 'medium' ? '30' : (this.props.size === 'xmedium' ? '23' : (this.props.size === 'small' ? '15' : (this.props.size ? this.props.size :'20') /* Medium (default) */)))))))))) || 'auto',
                                        '--fa-primary-opacity': this.props.swapOpacity ? '0.5' : '',
                                        '--fa-secondary-opacity': this.props.swapOpacity ? '1' : '',
                                        '--fa-primary-color': this.props.primaryColor ? this.props.primaryColor : '',
                                        '--fa-secondary-color': this.props.secondaryColor ? this.props.secondaryColor : '',
                                        ...this.props.style
                                    }}
                                    className={this.props.disabled ? this.props.classes.iconStyleDisabled : (this.props.className ? this.props.className : (this.props.error ? this.props.classes.iconStyleError : (this.props.light ? this.props.classes.iconStyleLight : this.props.classes.iconStyle)))}
                                    rotation={this.props.rotation} 
                                    spin={this.props.spin}
                                    />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(FADuoIcon);
