import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import API from 'API';

export default function LegalStatus({onSubmit, logicData}) {

    const [status, setStatus] = useState(logicData?.status)

    const getEligibleContacts = () => {
        if (!status) return;
        API.get(`/marketing/campaigns/mailingLists/eligibleContacts`, {params: {
            breakdown: 1,
            logicData: JSON.stringify({
                ...logicData,
                status: status,
            }),
            logicType: 'LegalStatus',
        }})
        .then(({data}) => {
            onSubmit(data.contacts)({
                ...logicData,
                status: status,
            });
        })
    }

    useEffect(getEligibleContacts, [status])

    return (
        <>
            <Grid item xs={12}>
                <AutoCompleteSelect
                    label="Legal Status *"
                    value={status}
                    onChange={e => setStatus(e?.value)}
                    fullWidth
                    options={[
                        {
                            value: 'Consumer',
                            label: 'Consumer',
                        },
                        {
                            value: 'Limited Company',
                            label: 'Limited Company',
                        },
                        {
                            value: 'Other',
                            label: 'Other',
                        },
                        {
                            value: 'Partnership',
                            label: 'Partnership',
                        },
                        {
                            value: 'Public Limited Company',
                            label: 'Public Limited Company',
                        },
                        {
                            value: 'Sole Trader',
                            label: 'Sole Trader',
                        },
                    ]}
                    disableSort
                    noClear
                />
            </Grid>
        </>
    )
}