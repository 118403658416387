import { Grid, Typography, Button } from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import 'Assets/Styles/emailPreveiew.css';
import BackButton from 'Components/Common/Buttons/BackButton';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import React from 'react';
import { connect } from 'react-redux';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconHelper from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';

import TabBar from 'Components/Common/Navigation/TabBar';
import NewEmailCampaignDialog from 'Components/Marketing/EmailCampaigns/NewEmailCampaign/NewEmailCampaignDialog';

import Overview from './Tabs/Overview';
import EmailPreview from './Tabs/EmailPreview';
import MailingLists from './Tabs/MailingLists';
import Contacts from './Tabs/Contacts';
import ActivityLog from './Tabs/ActivityLog';
import Versions from './Tabs/Versions';
import Scheduled from './Tabs/Scheduled';
import Runs from './Tabs/Runs';
import Emails from './Tabs/Emails';

import ProcessEmailCampaignDialog from './Dialogs/ProcessEmailCampaignDialog';

import { clenyDate } from 'Helpers/TimeHelper';

import _ from 'lodash';
import moment from 'moment';

const initialState = () => ({
    isLoading: true,
    campaign: {},
    currentTab: 'o',
})

class ViewEmailCampaign extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getCampaign();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState(initialState(), () => {
                this.getCampaign();
            })
        }
    }

    getCampaign = () => {
        API.get(`/marketing/campaigns/emailCampaign/${this.props.match.params.id}`)
        .then(res => {
            this.setState({
                campaign: res.data,
                isLoading: false
            })
        })
    }

    amendEmailCampaign = () => {
        this.props.deployDialog(
            <NewEmailCampaignDialog
                onClose={this.closeAmendCampaign}
                ecId={this.props.match.params.id}
            />,
            'Amend Email Campaign',
            'fs',
            'system'
        )
    }

    closeAmendCampaign = id => {
        this.props.closeDialog();
        if (id){
            this.props.history.push(`/marketing/campaigns/emailCampaigns/${id}`);
        }
    }

    endEmailCampaign = () => {
        API.post(`/marketing/campaigns/emailCampaign/${this.props.match.params.id}/end`)
        .then(this.getCampaign);
    }

    setTab = (event, tab) => {
        this.setState({currentTab: tab});
    }

    copyEmailCampaign = () => {
        this.props.deployDialog(
            <NewEmailCampaignDialog
                onClose={this.closeAmendCampaign}
                ecId={this.props.match.params.id}
                copy
            />,
            'Copy Email Campaign',
            'fs',
            'system'
        )
    }

    processEmailCampaign = () => {
        this.props.deployDialog(
            <ProcessEmailCampaignDialog
                onClose={this.closeProcessCampaign}
                campaign={this.state.campaign}
            />,
            'Schedule Email Campaign',
            'xs'
        )
    }

    closeProcessCampaign = () => {
        this.props.closeDialog();
        this.getCampaign();
    }

    render() {
        const { isLoading, campaign, currentTab } = this.state;

        if (isLoading) {
            return (
                <LoadingCircle/>
            )
        }

        let nextScheduled = _.find(campaign?.scheduled, {ecse_status: 'Awaiting'});

        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Email Campaign</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <PaddedPaper>
                            <Typography variant='h4' style={{marginBottom: '0.2em'}}>{campaign.ec_reference}-{campaign.ec_reference_amendment > 9 ? campaign.ec_reference_amendment : `0${campaign.ec_reference_amendment}` }</Typography>
                            <Typography variant="h5" style={{width: '100%'}}>{campaign.ec_name}</Typography>
                            {campaign.ec_desc && <Typography variant="body1" gutterBottom style={{whiteSpace: 'pre-wrap'}}>{campaign.ec_desc}</Typography>}
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={4}>
                        {campaign.ec_status === 'Ended' ? 
                            <Grid container spacing={1} >
                                <Grid item xs={12}>
                                    <PaddedPaper
                                        style={{
                                            backgroundColor: colors.importantInfo
                                        }}
                                    >
                                        <Typography variant="h5">Campaign Ended</Typography>
                                        <Typography variant="body1">This campaign was ended on {clenyDate(_.find(campaign.activity_logs, {ecal_type: 'Ended'})?.ecal_datetime)} by {_.find(campaign.activity_logs, {ecal_type: 'Ended'})?.ecal_staff || 'System'}.</Typography>
                                    </PaddedPaper> 
                                </Grid>    
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={this.copyEmailCampaign}
                                    ><AllIcon icon={IconHelper.copy} white/> Copy Campaign</Button>
                                </Grid>
                            </Grid>    
                            :
                            <Grid container spacing={1} >
                                <Grid item xs={12}>
                                    <PaddedPaper
                                        style={{
                                            backgroundColor:    ((campaign?.runs?.length === 0) && colors.red) ||
                                                                (campaign?.runs?.length < campaign?.scheduled?.length && colors.orange) ||
                                                                colors.green,
                                            padding: 10,
                                            paddingLeft: 15
                                        }}
                                    >
                                        <Typography variant="h6"
                                            style={{color: colors.white}}
                                        >
                                            {
                                                ((campaign?.runs?.length === 0 &&  campaign?.scheduled?.length === 0) && 'Campaign Has Not Yet Started') ||
                                                (campaign?.runs?.length < campaign?.scheduled?.length && 'Campaign Has Scheduled Runs') ||
                                                'Campaign Has Completed All Runs'
                                            }
                                        </Typography>
                                    </PaddedPaper>
                                </Grid>
                                {nextScheduled &&
                                    <Grid item xs={12}>
                                        <PaddedPaper
                                            style={{
                                                padding: 10,
                                                paddingLeft: 15
                                            }}
                                        >
                                            <Typography variant="h6">
                                                Next scheduled run on {moment(nextScheduled.ecse_send_date).format('DD/MM/YYYY HH:mm')}
                                            </Typography>
                                        </PaddedPaper>
                                    </Grid>
                                }
                                {(campaign.ec_sending_options === 'Manual') &&
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={this.processEmailCampaign}
                                        ><AllIcon icon={IconHelper.emailRuns} white/> Schedule Run</Button>                                       
                                    </Grid>
                                }
                                {( campaign?.runs?.length === 0 ) &&
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={this.amendEmailCampaign}
                                        ><AllIcon icon={IconHelper.edit} white/>Amend Campaign</Button>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        onClick={()=>this.props.deployConfirmation(
                                            'Are you sure you want to end this campaign?',
                                            'End Campaign',
                                            this.endEmailCampaign
                                        )}
                                    ><AllIcon icon={IconHelper.false} white/>End Campaign</Button>
                                </Grid>
                            </Grid> 
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <TabBar
                            handleTabChange={this.setTab}
                            currentTab={currentTab}
                            tabs={[
                                {
                                    label: 'Overview',
                                    value: 'o',
                                },
                                {
                                    label: 'Preview',
                                    value: 'p',
                                },
                                {
                                    label: `Mailing Lists`,
                                    value: 'm',
                                },
                                {
                                    label: `Contacts`,
                                    value: 'c',
                                },
                                {
                                    label: `Schedule`,
                                    value: 's',
                                },
                                {/*
                                    label: `Runs (${campaign?.runs?.length ?? 0})`,
                                    value: 'r',
                                */},
                                {
                                    label: `Emails`,
                                    value: 'e',
                                },
                                {/*
                                    label: `Versions (${campaign?.versions?.length ?? 0})`,
                                    value: 'v',
                                */},
                                {
                                    label: `Activity Log`,
                                    value: 'acl',
                                }
                            ]}
                        />
                        {
                            currentTab === 'r' &&
                            <PaddedPaper>
                                <Runs campaign={campaign} refresh={this.getCampaign} />
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'v' &&
                            <PaddedPaper>
                                <Versions campaign={campaign} refresh={this.getCampaign} />
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'o' &&
                            <PaddedPaper>
                                <Overview campaign={campaign} refresh={this.getCampaign} />
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'p' &&
                            <PaddedPaper>
                                <EmailPreview campaign={campaign} refresh={this.getCampaign} />
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'm' &&
                            <PaddedPaper>
                                <MailingLists campaign={campaign} refresh={this.getCampaign} />
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'c' &&
                            <PaddedPaper>
                                <Contacts campaign={campaign} refresh={this.getCampaign} />
                            </PaddedPaper>
                        }
                        {
                            currentTab === 's' &&
                            <PaddedPaper>
                                <Scheduled campaign={campaign} refresh={this.getCampaign} />
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'acl' &&
                            <PaddedPaper>
                                <ActivityLog campaign={campaign} refresh={this.getCampaign} />
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'e' &&
                            <PaddedPaper>
                                <Emails campaign={campaign} refresh={this.getCampaign} />
                            </PaddedPaper>
                        }
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <BackButton history={this.props.history} />
                    </Grid>
                </Grid>
            </>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, size, variant=null) => {
            dispatch(deployDialog(content, title, variant, size))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        },
        deployConfirmation: (message, title, success, variant)   => dispatch(deployConfirmation(message, title, success, null, variant))
    }
}

export default connect(null, mapDispatchToProps)(ViewEmailCampaign);