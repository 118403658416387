import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, TextField, Typography, Button, InputAdornment, DialogActions, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import React from 'react'

import InputHelper from 'Helpers/InputHelper';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import API from 'API';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import IconHelper from 'Helpers/IconHelper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { deployConfirmation }   from 'Actions/Confirmation/Confirmation';
import { connect } from 'react-redux';
import {checkEmails} from 'Helpers/EmailHelper';
import { colors } from 'Helpers/ColourHelper'
import CheckBox from 'Components/Common/Inputs/CheckBox';
import InfoIcon from 'Components/Common/Icons/InfoIcon';

const initialState = () => ({
    formData: {
        type: '',
        primary: '',
        secondary: [],
        setBounced: true
    },
    checkEmail: {
        email: '',
        valid: null,
        error: null,
        warning: null,
    },
    editFormData: {
        eav_id: null,
        eav_secondary: [],
    },
    errors: [],
    warnings: [],
    bounced: [],
    blacked: [],
    isLoading: true,
})

const reasonSwap = {
    'General' : 'General',
    'Undetermined' : 'Undetermined',
    'NoEmail' : 'No Email',
    'Suppressed' : 'Suppressed',
    'OnAccountSuppressionList': 'On Account Suppression List',
    'MailboxFull': 'Mailbox Full',
    'MessageTooLarge': 'Message Too Large',
    'ContentRejected': 'Content Rejected',
    'AttachmentRejected': 'Attachment Rejected',

    'abuse': 'Unsolicted / Abuse / Spam',
    'auth-failure': 'Failed Authentication',
    'fraud' : 'Suspected Fraud / Phishing',
    'not-spam': 'Tagged As Spam',
    'other': 'Unknown',
    'virus': 'Virus Detected',
}

const bounceTypes = {
    'Permanent': [
        'General',
        'NoEmail',
        'Suppressed',
        'OnAccountSuppressionList'
    ],
    'Transient': [
        'General',
        'MailboxFull',
        'MessageTooLarge',
        'ContentRejected',
        'AttachmentRejected'
    ],
}

class EmailValidation extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.InputHelper = new InputHelper(this, 'formData');
    }

    componentDidMount() {
        this.getEmailAddressValidation();
    }

    getEmailAddressValidation = () => {
        API.get(`/marketing/contacts/emailValidation/all`)
        .then(res => {
            this.setState({
                isLoading: false,
                errors: _.filter(res.data, (validation) => validation.eav_type === 'ending'),
                warnings: _.filter(res.data, (validation) => validation.eav_type === 'domains'),
                bounced: _.filter(res.data, (validation) => validation.eav_type === 'bounce'),
                blacked: _.filter(res.data, (validation) => validation.eav_type === 'black'),
                complaints: _.filter(res.data, (validation) => validation.eav_type === 'complaint'),
            })
        })
    }

    handleAdd = () => {
        API.post(`/marketing/contacts/emailValidation`, {...this.state.formData, 
            primary: this.state.formData.type === 'bounce' ? this.state.formData.primary : (this.state.formData.type === 'ending' ?
                ( this.state.formData.primary.startsWith('.') ? this.state.formData.primary : `.${this.state.formData.primary}` ) :
                ( this.state.formData.primary.startsWith('@') ? this.state.formData.primary : `@${this.state.formData.primary}` )),
            secondary: JSON.stringify(_.map(this.state.formData.secondary, i =>  `${i.startsWith('.') ? i : `.${i}`}`))
        })
        .then(res => {
            this.setState({
                formData: initialState().formData,
            }, () => {
                this.getEmailAddressValidation();
            })
        })
    }

    handleEdit = (data) => {
        this.setState({
            editFormData: {
                eav_id: data.eav_id,
                eav_secondary: JSON.parse(data.eav_secondary),
            }
        })
    }

    handleDelete = ({eav_id}) => {
        API.post(`/marketing/contacts/emailValidation/${eav_id}/delete`)
        .then(res => {
            this.getEmailAddressValidation();
        })
    }

    handleCheckEmail = async () => {
        let value = this.state.checkEmail.email;
        let checkEmail = initialState().checkEmail;

        if (!value) {
            this.setState({checkEmail});
        } else {
            let warnings = {};
            _.each(this.state.warnings, i => {
                warnings[i.eav_primary] = JSON.parse(i.eav_secondary)
            });

            let checkEmail = await checkEmails(value,{
                error: _.map(this.state.errors, i => i.eav_primary),
                warning: warnings,
                bounce: _.map(this.state.bounced, i => i.eav_primary),
                black: _.map(this.state.blacked, i => i.eav_primary),
                complaint: _.map(this.state.complaints, i => i.eav_primary),
            });

            this.setState({
                checkEmail: {
                    email: value,
                    valid: checkEmail.valid.length > 0,
                    error: checkEmail.error.length > 0,
                    warning: checkEmail.warning.length > 0,
                    bounce: checkEmail.bounce.length > 0,
                    black: checkEmail.black.length > 0,
                    complaint: checkEmail.complaint.length > 0,
                }
            });
        }

    }

    handleChangeEditFormData = e => {
        this.setState({
            editFormData: {
                ...this.state.editFormData,
                eav_secondary: _.map(e, i => i.value),
            }
        })
    }

    submitEdit = () => {
        API.post(`/marketing/contacts/emailValidation/${this.state.editFormData.eav_id}/update`, {...this.state.editFormData, secondary: JSON.stringify(this.state.editFormData.eav_secondary)})
        .then(res => {
            this.setState({
                editFormData: initialState().editFormData,
            })
            this.getEmailAddressValidation();
        })
    }

    render() {

        const { formData, errors, warnings, isLoading, checkEmail, editFormData, bounced, blacked, complaints } = this.state;
        const { classes } = this.props;

        if (isLoading) return (
            <LoadingCircle />
        );

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">Email Validation</Typography>
                </Grid>
                <Grid item xs={5}>
                    <PaddedPaper>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Add New Email Validation</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    label="Validation Type *"
                                    name="type"
                                    options={[
                                        {label: "Email Endings (e.g. '.com')", value: 'ending'},
                                        {label: "Domains (e.g. '@google.co.uk')", value: 'domains'},
                                        {label: "Black List", value: 'black'}
                                    ]}
                                    value={formData.type}
                                    onChange={this.InputHelper.handleSelectChange('type')}
                                    fullWidth
                                    noClear
                                />  
                            </Grid>
                            {formData.type === 'bounce' && <>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Bounced Email *"
                                        name="primary"
                                        value={formData.primary}
                                        onChange={e=>this.setState(this.InputHelper.setNestedValue(e.target.name, e.target.value))}
                                        fullWidth
                                    />
                                </Grid>
                            </>}
                            {formData.type === 'black' && <>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Black List Domain *"
                                        name="primary"
                                        value={formData.primary}
                                        onChange={e=>this.setState(this.InputHelper.setNestedValue(e.target.name, e.target.value))}
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">@</InputAdornment>),
                                        }}
                                    />
                                </Grid>
                            </>}
                            {formData.type === 'ending' && <>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Valid Email Ending *"
                                        name="primary"
                                        value={formData.primary}
                                        onChange={e=>this.setState(this.InputHelper.setNestedValue(e.target.name, e.target.value))}
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">.</InputAdornment>),
                                        }}
                                    />
                                </Grid>
                            </>}
                            {formData.type === 'domains' && <>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Domain *"
                                        name="primary"
                                        value={formData.primary}
                                        onChange={e=>this.setState(this.InputHelper.setNestedValue(e.target.name, e.target.value))}
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">@</InputAdornment>),
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AutoCompleteMultiSelect
                                        label="Valid Email Endings *"
                                        name="secondary"
                                        options={_.map(errors, e => ({value: e.eav_primary, label: e.eav_primary}))}
                                        value={_.map(formData.secondary, i => i)}
                                        onChange={this.InputHelper.handleMultiSelectChange('secondary')}
                                        fullWidth
                                        noClear
                                    />
                                </Grid>
                            </>}
                            <Grid item xs={12} className='buttonRow'>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>{
                                        this.props.deployConfirmation(
                                            'Are you sure you want to add this validation?',
                                            'Add Validation',
                                            this.handleAdd
                                        )
                                    }}
                                    disabled={
                                        !formData.type 
                                        || !formData.primary 
                                        || _.map([...errors, ...warnings, ...blacked], i => i.eav_primary).includes(`@${formData.primary}`)
                                        || (formData.type === 'bounce' && _.map(bounced, i => i.eav_primary).includes(formData.primary))
                                    }
                                >Submit</Button>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={3}>
                    <PaddedPaper
                        style={{
                            backgroundColor: checkEmail.black ? colors.black : (checkEmail.valid ? colors.green : checkEmail.error ? colors.red : (checkEmail.warning || checkEmail.bounce  || checkEmail.complaint) ? colors.orange : null),
                            color: ( checkEmail.valid || checkEmail.error || checkEmail.warning || checkEmail.bounce || checkEmail.black) && colors.white,    
                        }}
                    >
                        <Typography variant="h6">Check Email</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12} style={{marginTop: '1em'}}>
                                <TextField
                                    label="Email Address"
                                    name="email"
                                    value={checkEmail.email}
                                    onChange={e => this.setState({checkEmail: {...checkEmail, email: e.target.value}})}
                                    fullWidth
                                    style={{
                                        color: ( checkEmail.valid || checkEmail.error || checkEmail.warning || checkEmail.bounce || checkEmail.black || checkEmail.complaint) && colors.white,
                                        borderColor: ( checkEmail.valid || checkEmail.error || checkEmail.warning || checkEmail.bounce || checkEmail.black || checkEmail.complaint) && colors.white,
                                    }}
                                    InputProps={{
                                        classes: ( checkEmail.valid || checkEmail.error || checkEmail.warning || checkEmail.bounce || checkEmail.black || checkEmail.complaint) && {
                                            root: classes.emailTester,
                                            focused: classes.emailTester,
                                            notchedOutline: classes.emailTester
                                        }
                                    }}
                                    inputProps={{
                                        classes: ( checkEmail.valid || checkEmail.error || checkEmail.warning || checkEmail.bounce || checkEmail.black || checkEmail.complaint) && {
                                            root: classes.emailTester,
                                            focused: classes.emailTester,
                                            notchedOutline: classes.emailTester
                                        }
                                    }}
                                    InputLabelProps={{
                                        classes: ( checkEmail.valid || checkEmail.error || checkEmail.warning || checkEmail.bounce || checkEmail.black || checkEmail.complaint) && {
                                            root: classes.emailTester,
                                            focused: classes.emailTester,
                                            notchedOutline: classes.emailTester
                                        }
                                    }}
                                    InputBorderProps={{
                                        classes: ( checkEmail.valid || checkEmail.error || checkEmail.warning || checkEmail.bounce || checkEmail.black || checkEmail.complaint) && {
                                            root: classes.emailTester,
                                            focused: classes.emailTester,
                                            notchedOutline: classes.emailTester
                                        }
                                    }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {checkEmail.valid && <Typography variant="h6" style={{textAlign: 'center', color: colors.white}}>Email Is Valid</Typography>}
                                {checkEmail.error && <Typography  variant="h6" style={{textAlign: 'center', color: colors.white}}>Email Is Invalid</Typography>}
                                {checkEmail.warning && <Typography variant="h6" style={{textAlign: 'center', color: colors.white}}>Email Is Valid With Warnings</Typography>}
                                {checkEmail.bounce  && <Typography variant="h6" style={{textAlign: 'center', color: colors.white}}>Email Is Has Previously Bounced</Typography>}
                                {checkEmail.black && <Typography variant="h6" style={{textAlign: 'center', color: colors.white}}>Email Is Black Listed</Typography>}
                                {checkEmail.complaint && <Typography variant="h6" style={{textAlign: 'center', color: colors.white}}>Email Has A Complaint</Typography>}
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleCheckEmail}
                                    fullWidth
                                >Check</Button>
                            </Grid>
                            
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                        >
                            <Grid container>
                                <Grid item xs={12}><Typography variant="body1">Email Endings</Typography></Grid>
                                <Grid item xs={12}><Typography variant="caption">Email addresses without these endings will be flagged as 'Invalid'</Typography></Grid> 
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <DataTable
                                config={{
                                    key: 'eav_id'
                                }}
                                rows={errors}
                                columns={[
                                    { 
                                        heading: 'Email Ending',
                                        field: 'eav_primary',
                                    },
                                    {
                                        actions:  i => ([
                                            {
                                                name: 'Delete',
                                                icon: IconHelper.bin,
                                                onClick: this.handleDelete
                                            }
                                        ]),
                                    }
                                ]}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                        >
                            <Grid container>
                                <Grid item xs={12}><Typography variant="body1">Email Domains</Typography></Grid>
                                <Grid item xs={12}><Typography variant="caption">Email addresses with this domain, that do not match the set endings will be flagged with 'Warning'</Typography></Grid> 
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <DataTable
                                config={{
                                    key: 'eav_id'
                                }}
                                rows={warnings}
                                columns={[
                                    { 
                                        heading: 'Domain',
                                        field: 'eav_primary',   
                                    },
                                    {
                                        heading: 'Valid Email Endings',
                                        field: i => JSON.parse(i.eav_secondary).join(', '),
                                    },
                                    {
                                        actions: i => ([
                                            {
                                                name: 'Edit',
                                                icon: IconHelper.edit,
                                                onClick: this.handleEdit
                                            },
                                            {
                                                name: 'Delete',
                                                icon: IconHelper.bin,
                                                onClick: this.handleDelete
                                            }
                                        ])
                                    }
                                ]}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                        >
                            <Grid container>
                                <Grid item xs={12}><Typography variant="body1">Email Bounced</Typography></Grid>
                                <Grid item xs={12}><Typography variant="caption">Email addresses that have previously bounced</Typography></Grid> 
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <DataTable
                                config={{
                                    key: 'eav_id',
                                    dropRow: {
                                        droppable: rowData => rowData?.bounced_emails.length > 0,
                                        hideDropArrow: true,
                                        columns: [
                                            {   
                                                colSpan: 5,
                                                field: i => 
                                                    <PaddedPaper>
                                                        <DataTable
                                                            rows={i?.bounced_emails}
                                                            config={{key: 'eb_id', plainHeader: true}}
                                                            columns={[
                                                                {
                                                                    heading: 'date',
                                                                    field: 'eb_datetime',
                                                                    sizeToContent: true,
                                                                    fieldFormat: 'clenydate',
                                                                },
                                                                {
                                                                    heading: 'email',
                                                                    field: 'eb_email',
                                                                },
                                                                {
                                                                    heading: 'reason',
                                                                    field: i => reasonSwap[i.eb_reason],
                                                                    sizeToContent: true,
                                                                },
                                                                {
                                                                    heading: 'bounce type',
                                                                    field: i => i.eb_type === 'Permanent' ? 'Hard' : 'Soft',
                                                                    sizeToContent: true,
                                                                },
                                                                {
                                                                    heading: 'origin',
                                                                    field: 'eb_origen',
                                                                    sizeToContent: true,
                                                                },
                                                                {
                                                                    heading: 'unsubscribed',
                                                                    field: 'eb_unsubscribed',
                                                                    sizeToContent: true,
                                                                    fieldFormat: 'boolean',
                                                                    alignment: 'center'
                                                                },
                                                                {
                                                                    heading: 'unsubscribed Reason',
                                                                    field: 'eb_unsub_reason',
                                                                    sizeToContent: true,
                                                                }
                                                            ]}
                                                        />
                                                    </PaddedPaper>
                                                ,
                                            },
                                        ],
                                    }
                                }}
                                rows={bounced}
                                columns={[
                                    { 
                                        heading: 'Address',
                                        field: 'eav_primary',   
                                    },
                                    {
                                        heading: <>Soft Bounces <InfoIcon color={colors.white} info={<>
                                            One of the following reasons:<br/>
                                            {_.map(bounceTypes['Transient'], i => <>{reasonSwap[i]}<br/></>)}
                                        </>} /></>,
                                        field: i => _.filter(i.bounced_emails, j => j.eb_type !== 'Permanent').length,
                                        sizeToContent: true,
                                        alignment: 'center'
                                    },
                                    {
                                        heading: <>Hard Bounces <InfoIcon color={colors.white} info={<>
                                            One of the following reasons:<br/>
                                            {_.map(bounceTypes['Permanent'], i => <>{reasonSwap[i]}<br/></>)}
                                        </>} /></>,
                                        field: i => _.filter(i.bounced_emails, j => j.eb_type === 'Permanent').length,
                                        sizeToContent: true,
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'last bounce',
                                        field: i => _.last(i.bounced_emails)?.eb_datetime,
                                        sizeToContent: true,
                                        fieldFormat: 'clenydate',
                                    },
                                    {
                                        heading: 'Unsubscribed',
                                        field: i => JSON.parse(i.eav_secondary)[0],
                                        fieldFormat: 'boolean',
                                        sizeToContent: true,
                                        alignment: 'center'
                                    },
                                    {
                                        actions: i => ([
                                            {
                                                name: 'Delete',
                                                icon: IconHelper.bin,
                                                onClick: this.handleDelete
                                            }
                                        ])
                                    }
                                ]}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                        >
                            <Grid container>
                                <Grid item xs={12}><Typography variant="body1">Email Complaints</Typography></Grid>
                                <Grid item xs={12}><Typography variant="caption">Email addresses that have been reported or marked as spam</Typography></Grid> 
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <DataTable
                                config={{
                                    key: 'eav_id'
                                }}
                                rows={complaints}
                                columns={[
                                    { 
                                        heading: 'Address',
                                        field: 'eav_primary',   
                                    },
                                    {
                                        heading: 'Reason',
                                        field: i => reasonSwap[i.complaints.ec_reason],
                                    },
                                    {
                                        actions: i => ([
                                            {
                                                name: 'Delete',
                                                icon: IconHelper.bin,
                                                onClick: this.handleDelete
                                            }
                                        ])
                                    }
                                ]}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                        >
                            <Grid container>
                                <Grid item xs={12}><Typography variant="body1">Email Black List</Typography></Grid>
                                <Grid item xs={12}><Typography variant="caption">Email domains that marketing cannot be sent to</Typography></Grid> 
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <DataTable
                                config={{
                                    key: 'eav_id'
                                }}
                                rows={blacked}
                                columns={[
                                    { 
                                        heading: 'Address',
                                        field: 'eav_primary',   
                                    },
                                    {
                                        actions: i => ([
                                            {
                                                name: 'Delete',
                                                icon: IconHelper.bin,
                                                onClick: this.handleDelete
                                            }
                                        ])
                                    }
                                ]}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
                {editFormData.eav_id &&
                    <Dialog
                        open={editFormData.eav_id}
                        maxWidth="sm"
                        fullWidth
                    >
                        <DialogTitle>Edit Email Validation</DialogTitle>
                        <DialogContent>
                            <AutoCompleteMultiSelect 
                                label="Valid Email Endings *"
                                name="eav_secondary"
                                options={_.map(errors, e => ({value: e.eav_primary, label: e.eav_primary}))}
                                value={editFormData.eav_secondary}
                                onChange={this.handleChangeEditFormData}
                                noClear
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                variant="outlined"
                                onClick={() => this.setState({editFormData: initialState().editFormData})}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.submitEdit}
                                disabled={!editFormData.eav_secondary || editFormData.eav_secondary.length === 0}
                            >Update</Button>
                        </DialogActions>
                    </Dialog>
                }
            </Grid>
        );
    }
}

const styles = theme => ({
    emailTester: {
        borderWidth: '1px',
        borderColor: 'white !important',
        color: 'white !important'
    },
})

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
    }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(EmailValidation))
