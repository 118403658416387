import React from 'react';
import _ from 'lodash';

import moment from 'moment';
import {
    Grid,
    Box,
    Typography,
    Avatar,
    Tooltip,
    Link
} from '@material-ui/core';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import { CLENAWARE_GREEN, CLENAWARE_RED, CLENAWARE_ORANGE, CLENAWARE_YELLOW, CLENAWARE_BLUE } from 'Constants';
import AllIcon from 'Components/Common/Icons/AllIcon';
import TileStat from 'Components/Common/Tiles/TileStat';
import DataTable from 'Components/Common/DataTables/DataTable';
import { Hidden } from '@material-ui/core';

const initialState = () => ({
    vehicle: {}
})

class VehicleOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        const { vehicle, activeVehicle } = this.props;

        this.setState({
            ...this.state,
            vehicle: vehicle
        });
    }

    render() {
        

        const { vehicle } = this.state;
        const { activeVehicle } = this.props;

        /*
        * Calculate the Service Status
        */
        let today = moment();

        let lastServiceDate     = vehicle?.latestService ? vehicle.latestService?.date : vehicle?.deliveryDate;

        let nextServicedDate    = moment(lastServiceDate).add(vehicle?.serviceMonths, 'months').format('YYYY-MM-DD');
        let nextServiceMiles    = vehicle?.latestService ? (vehicle.latestService?.mileage + vehicle?.serviceMiles) : vehicle?.serviceMiles;
        let serviceDueStatus    = 'Not Due';

        if(today.isSameOrAfter(moment(nextServicedDate).format('YYYY-MM-DD')) || vehicle?.mileage?.currentMileage >= nextServiceMiles) {
            serviceDueStatus = 'Overdue';
        }
        // nextServicedDate 1 month away or nextServiceMiles 2500 miles away
        else if (today.isSameOrAfter(moment(nextServicedDate).subtract(1, 'months').format('YYYY-MM-DD')) || vehicle?.mileage?.currentMileage >= (nextServiceMiles - 2500)) {
            serviceDueStatus = 'Due Soon';
        }

        return (
            <Box mt={3}>
                <Grid container item spacing={3}>
                    
                    <Grid item xs={12} lg={8}>
                        <Grid container item spacing={3}>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Grid container item spacing={1} alignItems="center">                        
                                        <Grid item xs={12} md={9}>
                                            <Grid container spacing={3}>
                                                <Hidden smDown>
                                                    <Grid item>
                                                        <Avatar alt='Vehicle' src={vehicle?.photo} style={{height: 150, width: 150, margin: 0}}>
                                                            <FASolidIcon icon='car' size='large' noMargin buttonPrimary />
                                                        </Avatar>
                                                    </Grid>
                                                </Hidden>
                                                <Grid item>
                                                    <Typography variant="h6" gutterBottom>                                        
                                                        <b>{vehicle?.make} {vehicle?.model}</b>
                                                    </Typography>
                                                    <Typography variant="h4" gutterBottom>
                                                        <div style={{backgroundColor:'#ffd307', padding: '2px 6px'}}><b>{vehicle?.regNumber}</b></div>
                                                    </Typography>        
                                                    <Typography variant="body1">                                        
                                                        <AllIcon size="small" icon={(['Electric', 'Hybrid']).includes(vehicle?.fuelType) ? 'bolt' : 'gas-pump'} /> {vehicle?.fuelType}
                                                    </Typography>
                                                    {(['Electric', 'Hybrid']).includes(vehicle?.fuelType) && vehicle?.electricRange ?
                                                        <Typography variant="body1">                                        
                                                            {vehicle?.electricRange?.toLocaleString()} Mile Range
                                                        </Typography>
                                                    : null}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={3} align="center"> 
                                            <AllIcon icon='steering-wheel' size={50} duo noMargin />
                                            <br></br>
                                            <Typography variant="h6">
                                                Total Miles Driven
                                            </Typography>
                                            <Typography variant="h5">
                                                <b>{vehicle?.mileage ? vehicle?.mileage?.totalMiles?.toLocaleString() : '-'}</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} align="right">
                                            <Typography variant="caption" gutterBottom>
                                                {activeVehicle ? 
                                                    <>This vehicle has been in your possession for approx. {moment().diff(moment(vehicle?.deliveryDate), 'months')} months</>
                                                : 
                                                    <>This vehicle was in your possession for approx. {moment(vehicle?.returnInfo?.returnDate).diff(moment(vehicle?.deliveryDate), 'months')} months</>
                                                }
                                            </Typography>
                                            {activeVehicle && vehicle?.returnInfo?.returnDate !== null ?
                                                <Typography variant="body2"><b style={{color: CLENAWARE_ORANGE}}>Due to be returned {moment(vehicle?.returnInfo?.returnDate).format('DD MMM YYYY')}</b></Typography>  
                                            : null }   
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            {activeVehicle ?
                            <Grid item xs={12}>
                                <Grid container item spacing={3}>                        
                                    <Grid item xs={12} sm>
                                        <Tooltip 
                                            title={
                                                <>
                                                <Typography variant="body2" paragraph>
                                                    Current car mileage: <b>{vehicle?.mileage?.currentMileage?.toLocaleString()}</b>
                                                </Typography>
                                                <Typography variant="body2">
                                                    The next service is due on <b>{moment(nextServicedDate).format('D MMM, YYYY') }</b>  or at <b>{nextServiceMiles?.toLocaleString()} miles</b> whichever comes first.
                                                </Typography>
                                                </>
                                            } 
                                            placement="right"
                                        >
                                            <div>
                                            <TileStat
                                                color={serviceDueStatus == 'Overdue' ? CLENAWARE_RED : (serviceDueStatus == 'Due Soon' ? CLENAWARE_ORANGE : CLENAWARE_GREEN)}
                                                icon={serviceDueStatus == 'Overdue' ? 'light-emergency-on' : (serviceDueStatus == 'Due Soon' ? 'circle-exclamation' : 'face-smile')} 
                                                text="Service"
                                                stat={serviceDueStatus}
                                            />  
                                            </div>
                                        </Tooltip>
                                        <small> <i>Service reminder at 1 month or 2,500 miles before</i></small>
                                    </Grid>
                                    <Grid item xs={12} sm>
                                        <TileStat
                                            color={vehicle?.motColour}
                                            icon='clipboard-list-check'
                                            text="MOT"
                                            stat={vehicle?.motExpiry !== '0000-00-00' ? moment(vehicle?.motExpiry).format('D MMM YYYY') : '-'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm>
                                        <TileStat
                                            color={
                                            vehicle?.roadTaxRequired ? 
                                                (moment().isSameOrAfter(vehicle?.roadTaxDue) ? 
                                                    CLENAWARE_RED 
                                                : 
                                                    (moment().isSameOrAfter(moment(vehicle?.roadTaxDue).subtract(1, 'months').format('YYYY-MM-DD')) ? 
                                                        CLENAWARE_ORANGE 
                                                    : 
                                                        CLENAWARE_GREEN
                                                    )
                                                ) 
                                            : '#efefef'}

                                            icon='sterling-sign'
                                            text="Tax"
                                            stat={vehicle?.roadTaxRequired ? moment(vehicle?.roadTaxDue).format('D MMM YYYY') : 'N/A'}
                                        />                
                                    </Grid>
                                </Grid>
                            </Grid>
                            : null }
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Grid container item spacing={3}>                        
                                        <Grid item xs>
                                            <Typography variant="h6">
                                                <b>Mileage Tracker</b>
                                            </Typography>
                                            <Typography variant="caption" paragraph>
                                                Last 3 entries
                                            </Typography>
                                        </Grid>
                                        {vehicle?.ownershipType == 'Leased' && vehicle?.leaseContractMileageAllowance ?
                                            <Tooltip 
                                                title={
                                                    <>
                                                    <Typography variant="h6" paragraph>Lease Allowances</Typography>
                                                    <Typography variant="body2" paragraph>
                                                        {vehicle?.mileage?.totalMiles > vehicle?.leaseContractMileageAllowance ?
                                                            <><AllIcon icon="circle-xmark" style={{color: CLENAWARE_RED}} heavy /> You are outside your contract allowance</>
                                                        :
                                                            <><AllIcon icon="check-circle" style={{color: CLENAWARE_GREEN}} heavy /> You are within your contract allowance</>
                                                        }
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <b>{vehicle?.leaseContractMileageAllowance?.toLocaleString()}</b> miles allowed within the contract
                                                    </Typography>
                                                    <Typography variant="body2" paragraph>
                                                        <b>{vehicle?.leaseAnnualMileageAllowance?.toLocaleString()}</b> miles allowed per annum
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <em>You will be charged {vehicle?.leaseExcessMileageCharge}p per mile for excess contract milage</em>
                                                    </Typography>
                                                    </>
                                                } 
                                                placement="right"
                                            >
                                                <Grid item align="right" >
                                                    <Typography variant="body2">
                                                        Contract Mileage Allowance
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        <b style={{color: vehicle?.mileage?.totalMiles > vehicle?.leaseContractMileageAllowance ? CLENAWARE_RED : CLENAWARE_GREEN}}>
                                                            {vehicle?.mileage?.totalMiles?.toLocaleString()} / {vehicle?.leaseContractMileageAllowance?.toLocaleString()}
                                                        </b>
                                                    </Typography>
                                                </Grid>
                                            </Tooltip>
                                        :
                                            null}
                                    </Grid>
                                    <DataTable  
                                        config={{
                                            key: 'id',
                                            pagination: false,
                                            alternatingRowColours: false,
                                            responsiveImportance: true,
                                            dense: true, 
                                            plainHeader: true,
                                        }}
                                        columns={[
                                            {
                                                heading: <Typography variant="caption"><b>Date</b></Typography>,
                                                field: rowData => moment(rowData?.date).format('ddd DD MMM, YYYY'),                                   
                                                important: true,
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: <Typography variant="caption"><b>Previous Car Mileage</b></Typography>,
                                                field: rowData => rowData?.prevMileage ? rowData?.prevMileage?.toLocaleString() : '-',
                                                important: true,
                                                alignment: 'right'
                                            },
                                            {
                                                heading: <Typography variant="caption"><b>Miles Driven</b></Typography>,
                                                field: rowData => rowData?.milesDriven ? rowData?.milesDriven?.toLocaleString() : '-',
                                                important: true,
                                                alignment: 'right'
                                            },
                                            {
                                                heading: <Typography variant="caption"><b>Car Mileage</b></Typography>,
                                                field: rowData => rowData?.currentMileage ? rowData?.currentMileage?.toLocaleString() : <b style={{color: CLENAWARE_RED }}>Awaiting</b>,
                                                important: true,
                                                alignment: 'right'
                                            }
                                        ]}
                                        rows={vehicle?.mileage?.history}
                                    />
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        {activeVehicle ?
                            <Grid container item spacing={3}>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                {vehicle?.currentAllocation ? 
                                                    <>
                                                    <Typography variant="h6" paragraph>
                                                        <b>Allocated</b>
                                                    </Typography>
                                                    <Grid container spacing={2} alignContents="center">
                                                        <Grid item>
                                                            {vehicle?.currentAllocation?.photo ?
                                                                <Avatar alt='Vehicle' src={vehicle?.currentAllocation?.photo} />
                                                            :
                                                                <AllIcon icon='car-side' duo noMargin style={{color: CLENAWARE_GREEN }} size={30} />
                                                            }
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body1">
                                                                {vehicle?.currentAllocation?.allocatedTo}
                                                            </Typography>
                                                            {vehicle?.currentAllocation?.allocatedTo !== 'Pool Vehicle' ?
                                                                <Typography variant="body2">
                                                                    {vehicle?.currentAllocation?.fn} {vehicle?.currentAllocation?.ln}
                                                                </Typography>
                                                            : null}
                                                        </Grid>
                                                    </Grid>
                                                    </>
                                                :
                                                    <>
                                                    <Typography variant="h6" paragraph>
                                                        <AllIcon heavy icon='triangle-exclamation' noMargin style={{color: CLENAWARE_RED }} /> <b>Not Allocated</b>
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        This vehicle is not currently allocated
                                                    </Typography>
                                                    </> 
                                                }
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                                {vehicle?.outstandingPCN > 0 ?
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Typography variant="body1">
                                                <FASolidIcon icon='bell-on' style={{color: CLENAWARE_YELLOW}} /> Unsettled Penalty Charge (<Link onClick={(e) => this.props?.tabChange(e, 'incidents')} className='blueLink'>{vehicle?.outstandingPCN}</Link>)
                                            </Typography>
                                        </PaddedPaper>
                                    </Grid>
                                : null }
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6">
                                                    <b>Active Subscriptions</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {vehicle?.activeSubs && vehicle?.activeSubs.length > 0 ? (
                                                    <>
                                                    {_.map(vehicle?.activeSubs, (subscription, idx) => (
                                                            <Grid key={idx} container spacing={3} alignItems="center">
                                                            <Grid item xs={1}>
                                                                <AllIcon icon='arrows-repeat' noMargin style={{color: CLENAWARE_GREEN }} />
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Typography variant="body1">
                                                                    {subscription?.name}
                                                                    {subscription?.desc && (
                                                                        <> - {subscription?.desc}</>
                                                                    )}
                                                                </Typography>
                                                                <Typography variant="body2" paragraph>
                                                                    {moment(subscription?.startDate).format('MMM D, YYYY')} - {(subscription?.endDate !== '0000-00-00' ? moment(subscription?.endDate).format('MMM D, YYYY') : <em>Ongoing</em>)}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                    </>
                                                ) 
                                                : 
                                                    <Typography variant="body2">
                                                        <em>No active subscriptions</em>
                                                    </Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        :
                            <Grid container item spacing={3}>
                                <Grid item xs={12} align="center">
                                    <PaddedPaper style={{padding: '80px 20px'}}>
                                        <AllIcon icon="clipboard-check" noMargin style={{color: CLENAWARE_BLUE, marginBottom: 20 }} size={40} />
                                        <Typography variant="h4">
                                            Vehicle {vehicle?.ownershipType == "Owned" ? "Sold" : "Lease Completed"} 
                                        </Typography>
                                        <Typography variant="body1">
                                            Returned on {moment(vehicle?.returnInfo?.returnDate).format('MMM D, YYYY')}
                                        </Typography>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    
                </Grid>
            </Box>
        )
    }
}

export default VehicleOverview;