import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';

import { addKeyDown, removeKeyDown, setKeyDown } from 'Actions/KeyDown/KeyDown';

import { colors } from 'Helpers/ColourHelper';

import StockAddition from './StockAddition';
import StockReturn   from './StockReturn';
import StockWriteOff from './StockWriteOff';

const initialState = {
    isLoading:    true,
    worksOrder:   {},
    stages:       [],
    currentStage: null,
    showStockAddition: false,
    showStockReturn: false,
    showStockWriteOff: false,
}

class BuildWorksOrder extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount(){
        this.props.addKeyDown(this._handleKeyDown)
        this.getWorksOrder();
    }

	componentWillUnmount = () => {
		this.props.removeKeyDown()
	}

    getWorksOrder = () => {
        API.get(`/worksOrders/${this.props.match.params.id}`)
        .then(res => {
            this.setState({
                isLoading:          false,
                worksOrder:         res.data,
                stages:             res.data.build.stages,
                currentStage:       this.state.currentStage !== null ? this.state.currentStage : _.findIndex(res.data.build.stages, i =>  parseInt(res.data.build.current_stage?.wobs_id) === parseInt(i?.wobs_id)),
            }, () => {
                if (!!res.data.wo_completed )
                    this.props.history.push(`/WorksOrders/builds`);
            })
        });
    }

    handleClockIn =  () => { 
        API.post(`/worksOrders/${this.state.worksOrder.wo_id}/build/${this.state.worksOrder.build.wob_id}/clockIn`)
        .then(this.getWorksOrder()) 
    }

    handleClockOut = () => { API.post(`/worksOrders/${this.state.worksOrder.wo_id}/build/${this.state.worksOrder.build.wob_id}/clockOut`).then(this.getWorksOrder) }
/*
    startStopBuild = (showDoc=true) => {
        const { worksOrder } = this.props;
        if (this.state.worksOrder.build?.active_time_sheet.length > 0 && _.find(this.state.worksOrder.build?.active_time_sheet, {wobt_staff_id: this.props.loggedInStaff.id})) 
            this.handleClockOut();
        else  
            this.handleClockIn(showDoc);
    }
*/
    _handleKeyDown = (e) => {
		if (!e.key) return;
        e.preventDefault();
        switch (e.key) {
            case 'F1':
                this.state.currentStage !== 0 && this.prev();
                break;
            case 'F2':
                this.state.currentStage + 1 !== this.state.stages.length && this.next();
                break;
            case 'F3':
                /*
                this.state.stages[this.state.currentStage].instruction.parts.length > 0 &&
                ((this.state.worksOrder.build.current_stage?.wobs_id == this.state.stages[this.state.currentStage].wobs_id) || this.state.stages[this.state.currentStage].wobs_complete) &&
                    this.returnStock();
                */
                    this.getWorksOrder();
                break;
            case ' ':
                /*
                this.state.stages[this.state.currentStage].instruction.parts.length > 0 &&
                ((this.state.worksOrder.build.current_stage?.wobs_id == this.state.stages[this.state.currentStage].wobs_id) || this.state.stages[this.state.currentStage].wobs_complete) &&
                    this.addStock();
                    */
                break;
            case 'Tab':
                ( parseInt(this.state.worksOrder.build.current_stage?.wobs_id) === parseInt(this.state.stages[this.state.currentStage]?.wobs_id) ) && 
                    (this.state.worksOrder.active_write_off &&  this.handlePickWiteOff());
                break;
            default: break;
        }
    }

    handlePickWiteOff = () => {
        API.post(`/worksOrders/${this.state.worksOrder.wo_id}/writeOffs/${this.state.worksOrder.active_write_off.wobwo_id}/start`)
        .then(res => {
            if (res.data.errors) {
                this.getWorksOrder()
            } else {
                this.props.removeKeyDown()
                this.setState({
                    showStockWriteOff: true,
                })
            }
        })
    }
        
    returnStock = () => {
        this.props.removeKeyDown()
        this.setState({
            showStockReturn: true,
        });
    }

    addStock = () => {
        this.props.removeKeyDown()
        this.setState({
            showStockAddition: true,
        });
    }

    handleDialogClose = () => {
        this.setState({
            showStockAddition:  false,
            showStockReturn:    false,
            showStockWriteOff:  false,
        }, () => {
            this.props.addKeyDown(this._handleKeyDown)
            this.getWorksOrder();
        });
    }

    prev = () => {
        this.setState({
            currentStage: this.state.currentStage - 1
        });
    }

    next = () => {
        this.setState({
            currentStage: this.state.currentStage + 1
        });
    }

    render(){

        const { isLoading, worksOrder, stages, currentStage, showStockAddition, showStockReturn, showStockWriteOff } = this.state;

        if (isLoading) return (<LoadingCircle/>);

        let stage   = stages[currentStage];
        let current = parseInt(worksOrder.build.current_stage?.wobs_id) === parseInt(stage?.wobs_id);
        let editable = current || stage.wobs_complete;

        let backgroundColor = current ? colors.orange : (
            stage.wobs_complete ? colors.green : colors.red
        );
        
        return (
            <Grid container spacing={1} style={{height: '100%', alignItems: 'center'}}> 
                <Grid item xs={12}>
                    <Typography variant='h4' style={{textAlign: 'center'}}>Build Works Order</Typography>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{textAlign: 'center'}}>{worksOrder.wo_ref}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{textAlign: 'center'}}><strong>{worksOrder.part.part_number} - {worksOrder.part.part_description}</strong></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{textAlign: 'center'}}><strong>Requested: {worksOrder.wo_qty}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper style={{backgroundColor}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h5" style={{textAlign: 'center', color: colors.white}}><strong>Stage {currentStage + 1}</strong></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{textAlign: 'center', color: colors.white}}><strong>{stage.instruction.sais_name}</strong></Typography>
                            </Grid>
                            {!!current &&
                                <Grid item xs={12}>
                                    { worksOrder.active_write_off &&
                                            <Button
                                                style={{padding: '1em'}}
                                                fullWidth
                                                variant="contained"
                                                color='secondary' 
                                                onClick={this.handlePickWiteOff}> Pick Write Off</Button>
                                    }
                                </Grid>
                            }
                            {editable && stage.instruction.parts.length > 0 ?
                                <>
                                    <Grid item xs={12}>
                                        <Button
                                            style={{padding: '1em', backgroundColor: colors.white}}
                                            fullWidth
                                            variant="contained"
                                            onClick={this.getWorksOrder}
                                        >Refresh [F3]</Button>
                                    </Grid>
                                </> : 
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{textAlign: 'center', color: colors.white}}>Please complete the previous stage</Typography>
                                </Grid>
                            }
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={4}>
                    {currentStage !== 0 &&
                        <Button
                            fullWidth
                            variant="contained"
                            color='primary'
                            onClick={this.prev}>
                                [F1] Stage {currentStage}     
                        </Button>
                    }
                </Grid>
                <Grid item xs={4}><Typography variant="body2" style={{textAlign: 'center'}}><strong>Stage {currentStage + 1} Of {stages.length}</strong></Typography></Grid>
                <Grid item xs={4}>
                    {currentStage + 1 !== stages.length &&
                        <Button
                            fullWidth
                            variant="contained"
                            color='primary'
                            onClick={this.next}>
                                Stage {currentStage + 2} [F2]
                        </Button>
                    }
                </Grid>

                {showStockAddition &&
                    <Dialog 
                        open={showStockAddition} 
                        onClose={this.handleDialogClose} 
                        fullScreen
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title" style={{backgroundColor: colors.blue,  fontWeight: 'bold'}}> 
                            <Grid container style={{
                                alignContent:   'center',
                                justifyContent: 'space-between',
                                alignItems:     'center'
                            }}>
                                <Grid item style={{color: colors.white}}>
                                    Stage {stage.instruction.sais_number} - Stock Addition
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ width:'100vw', Maxwidth: '100vw'}}>
                            <StockAddition 
                                stage={stage}
                                worksOrder={worksOrder}
                                onClose={this.handleDialogClose}
                            />
                        </DialogContent>
                    </Dialog>
                }
                {showStockReturn &&
                    <Dialog 
                        open={showStockReturn} 
                        onClose={this.handleDialogClose} 
                        fullScreen
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title" style={{backgroundColor: colors.blue,  fontWeight: 'bold'}}> 
                            <Grid container style={{
                                alignContent:   'center',
                                justifyContent: 'space-between',
                                alignItems:     'center'
                            }}>
                                <Grid item style={{color: colors.white}}>
                                    Stage {stage.instruction.sais_number} - Stock Return
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ width:'100vw', Maxwidth: '100vw'}}>
                            <StockReturn 
                                stage={stage}
                                worksOrder={worksOrder}
                                onClose={this.handleDialogClose}
                            />
                        </DialogContent>
                    </Dialog>
                }
                {showStockWriteOff &&
                    <Dialog 
                        open={showStockWriteOff} 
                        onClose={this.handleDialogClose} 
                        fullScreen
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title" style={{backgroundColor: colors.blue,  fontWeight: 'bold'}}> 
                            <Grid container style={{
                                alignContent:   'center',
                                justifyContent: 'space-between',
                                alignItems:     'center'
                            }}>
                                <Grid item style={{color: colors.white}}>
                                    Pick Stock Write Off
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ width:'100vw', Maxwidth: '100vw'}}>
                            <StockWriteOff 
                                worksOrder={worksOrder}
                                onClose={this.handleDialogClose}
                            />
                        </DialogContent>
                    </Dialog>
                }
                

            </Grid>
        )
    }
}

const styles = theme => ({
    ListItem: {
		backgroundColor: colors.white, 
		borderRadius: 	 5,
		marginBottom: 	 '0.75em',
		boxShadow: 	  	 '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
	}
});

function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff
    };
}

const mapDespatchToProps = dispatch => ({
	addKeyDown: (func) => dispatch(addKeyDown(func,'BuildWorksOrder')),
	setKeyDown: () => dispatch(setKeyDown('BuildWorksOrder')),
	removeKeyDown: () => dispatch(removeKeyDown('BuildWorksOrder'))
});

export default  connect(mapStateToProps, mapDespatchToProps)(withStyles(styles)(BuildWorksOrder));


