import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';

import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { Divider, Grid, Typography } from '@material-ui/core';

const initialState = {
    isLoading:          true, 
    parts:              [],
    sales:              [],
    showParts:          [],
    saParts:            [],

    part:               null,
    sale:               null,
}

class QuickPartQrScan extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount() {
        this.getParts();
        this.getSales();
    }

    getSales = () => {
        API.get('/sales/orders/search', {params:{ slim:1, activeOnly: 1}})
        .then(res => {
            this.setState({sales: _.map(res.data, i => ({value: i.i, label: i.r}))});
        })
    }

    getParts = () => {
        API.get('/parts/all', { params: { use: 'partSearch', withStock: true }}).then(res => {
            this.setState({ 
                parts:     _.map(res.data, part => ({ value: part.part_id, label: `${part.part_number} - ${part.part_description}`, part })), 
                showParts: _.map(res.data, p => ({
                    qr:          p.part_qr_code,
                    part:        p.part_number,
                    description: p.part_description,
                    stock:       p?.stock?.stock_predicted ?? '-',
                })),
                isLoading: false 
            });
        })
    }

    getPart = (e) => {
        if (!e.value){
            this.setState({ 
                showParts: _.map(this.state.parts, p => ({
                    qr:          p.part.part_qr_code,
                    part:        p.part.part_number,
                    description: p.part.part_description,
                    stock:       p?.part?.stock?.stock_predicted ?? '-',
                })),
                saParts:   initialState.saParts,
                isLoading: false,
                part:      initialState.part,
            });
        } else {
            API.get(`/parts/${e.value}/subAssembly`).then(res => {
                this.setState({ 
                    showParts: _.map(_.filter(this.state.parts, p => p.part.part_id === e.value), p => ({
                        qr:          p.part.part_qr_code,
                        part:        p.part.part_number,
                        description: p.part.part_description,
                        stock:       p?.part?.stock?.stock_predicted ?? '-',
                    })),
                    saParts: _.map(res.data?.sub_assembly_parts, p => ({
                        qr:          p.part.part_qr_code,
                        part:        p.part.part_number,
                        description: p.part.part_description,
                        stock:       p?.part?.stock?.stock_predicted ?? '-',
                    })),
                    isLoading: false,
                    part:      e.value,
                    sale:      initialState.sale,
                });
            })
        }
    }


    getSale = (e) => {
        API.get(`/sales/orders/${e.value}`)
        .then(res => {
            this.setState({
                ...initialState,
                showParts: _.map(res.data.order_details, p => ({
                    qr:          p.part.part_qr_code,
                    part:        p.part.part_number,
                    description: p.part.part_description,
                    stock:       null,
                })),
                parts: this.state.parts,
                sales: this.state.sales,
                sale: e.value
            })
        })
    }

    render() {

        const { parts, isLoading, part, showParts, saParts, sales, sale } = this.state;

        if (!parts.length && !isLoading) { return <LoadingCircle /> }

        return (
            <Grid container spacing={3}>
                {parts.length > 0 &&
                    <Grid item lg={4} md={6} xs={12}>
                        <PaddedPaper>
                            <AutoCompleteSelect
                                fullWidth
                                label="Part"
                                options={parts}
                                value={part}
                                onChange={this.getPart}
                            />
                        </PaddedPaper>
                    </Grid>
                }
                {sales.length > 0 &&
                    <Grid item lg={4} md={6} xs={12}>
                        <PaddedPaper>
                            <AutoCompleteSelect
                                fullWidth
                                label="Orders"
                                options={sales}
                                value={sale}
                                onChange={this.getSale}
                            />
                        </PaddedPaper>
                    </Grid>
                }
                <Grid item container xs={12} style={{alignItems: 'center'}}>
                    <Grid item xs={1}><Typography variant='h5'>Parts</Typography></Grid>
                    <Grid item xs={11}><Divider/></Grid>
                </Grid>
                {_.map(showParts, i => (
                    <Grid item lg={3} md={6} sm={12}>
                        <PaddedPaper style={{hight: '100%'}}>
                            <Grid container style={{textAlign: 'center'}}>
                                <Grid item xs={12}><Typography variant='h6'><b>{i.part}</b></Typography></Grid>
                                <Grid item xs={12}><Typography>{i.description}</Typography></Grid>
                                <Grid item xs={12}><img src={i.qr} alt='QR' height='250px'/></Grid>
                                {!!i.stock && <Grid item xs={12}><Typography>Predicted Stock: {i.stock}</Typography></Grid>}
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                ))}
                {saParts.length > 0 && <>
                    <Grid item container xs={12} style={{alignItems: 'center'}}>
                        <Grid item xs={2}><Typography variant='h5'>Components</Typography></Grid>
                        <Grid item xs={10}><Divider/></Grid>
                    </Grid>
                    {_.map(_.orderBy(saParts, i => i.part), i => (
                        <Grid item lg={3} md={6} sm={12}>
                            <PaddedPaper style={{hight: '100%'}}>
                                <Grid container style={{textAlign: 'center'}}>
                                    <Grid item xs={12}><Typography variant='h6'><b>{i.part}</b></Typography></Grid>
                                    <Grid item xs={12}><Typography>{i.description}</Typography></Grid>
                                    <Grid item xs={12}><img src={i.qr} alt='QR' height='250px'/></Grid>
                                    <Grid item xs={12}><Typography>Predicted Stock: {i.stock}</Typography></Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    ))}
                </>}
            </Grid>
        )
    }
}

export default QuickPartQrScan;