import React, { Component } from 'react';
import { Redirect }         from 'react-router-dom';
import API                  from 'API';

import { Button, Grid, TextField, Typography } from '@material-ui/core';

import BackButton         from 'Components/Common/Buttons/BackButton';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar           from 'Components/Common/SnackBars/SnackBar';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = { 
    formData: {
        name: '',
    },
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: true,
    redirect: false
}

class UpdateNominalCodes extends Component {
    constructor(props) {
        super(props);
    
        this.state = initialState;
    }

    componentDidMount(){
        this.getNominalCode(this.props.match.params.id);
    }

    getNominalCode = (nomId) => {
        API.get(`/accounts/nominalCodes/${nomId}`)
        .then((res) =>  {
            if (res.data.errors) {
                this.setState({ redirect: true });
            } else {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        name: res.data.nominal_name
                    },
                    isLoading: false
                });
            }
        })
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        API.post(`/accounts/nominalCodes/${this.props.match.params.id}`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState(initialState);
                this.setState({
                    snackbarOpen: true
                });
                this.getNominalCode(this.props.match.params.id);
            }
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }
       
    render() {
        const { formErrors, formData, isLoading } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/accounts/nominal-codes" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Update Nominal Code Name
                        </Typography>
                    </Grid>
                    {(isLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                    )) || (
                        <React.Fragment>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        {formErrors && formErrors.generic && (
                                            <React.Fragment>
                                                <Typography component={"div"} style={{color: colors.red}}>
                                                    {formErrors.generic}
                                                </Typography>
                                            </React.Fragment>
                                        )}
                                        <form noValidate autoComplete="off">
                                            <TextField
                                                id="name"
                                                name="name"
                                                label="Name *"
                                                value={formData.name}
                                                error={formErrors && formErrors['name'] && true}
                                                helperText={formErrors && formErrors['name']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                        </form>
                                        <div className='buttonRow'>
                                            <BackButton props={this.props} />
                                            <Button onClick={this.handleConfirmationOpen}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                Update
                                            </Button>
                                        </div>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                            <ConfirmationDialog 
                                open={this.state.confirmationOpen} 
                                success={this.handleConfirmationSuccess} 
                                close={this.handleConfirmationClose} 
                                title="Update Name?" 
                                message="Are you sure you want to update this name?"
                            />
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarOpen}
                                onClose={this.handleSnackbarClose}
                                message='You have successfully updated the name'
                            />
                        </React.Fragment>
                    )}
                </Grid>
            );
        }
    }
}

export default UpdateNominalCodes;