import React, { PureComponent }  from 'react';
import _ from 'lodash';

import { Paper, TextField, Grid, IconButton, FormControl } from '@material-ui/core';

import FASolidIcon        from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import {colors} from 'Helpers/ColourHelper';

class PartCard extends PureComponent {
    
    render() {
        const { classes, idx, options, data, formErrors, handleSelectChange, handleChange, handleRemove, handleInstructionsPartsChange, instructions } = this.props;
        return (
            <Paper className={classes.paperGrey}>
                <form noValidate autoComplete="off">
                    <Grid container>
                        <Grid item xs>                    
                            <Grid container spacing={1}>
                                <Grid item xs={12} xl={10}>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect
                                            name='part'
                                            label={`Part ${(idx+1)}`}
                                            options={options}
                                            error={formErrors && formErrors['parts|part|' + idx] && true}
                                            errorText={formErrors && formErrors['parts|part|' + idx]}
                                            value={data.part}
                                            onChange={handleSelectChange}
                                            noClear
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} xl={2}>
                                    <FormControl fullWidth margin="normal">
                                        <TextField
                                            margin="none"
                                            name="quantity"
                                            label='Quantity *'
                                            error={formErrors && formErrors['parts|quantity|' + idx] && true}
                                            helperText={formErrors && formErrors['parts|quantity|' + idx]}
                                            value={data.quantity}
                                            onChange={handleChange(idx)}
                                            onBlur={handleChange(idx, 3)}
                                            fullWidth
                                        />      
                                    </FormControl>
                                </Grid>  
                                <Grid item xs={12} xl={10} >
                                    <Grid container spacing={1}>
                                        {_.map(_.filter(instructions, i => !i.delete ),(i, idx) => 
                                            <Grid item style={{width: instructions.length > 6 && `${100 / instructions.length}%`}}>
                                                <TextField
                                                    margin=     "none"
                                                    label=      {`Stage ${i.stage}`}
                                                    value=      {i.parts[data.part] || 0.000}
                                                    onChange=   {handleInstructionsPartsChange(i.stage, data.part)}
                                                    onBlur=     {handleInstructionsPartsChange(i.stage, data.part, 3)}
                                                    type=       "number"
                                                    inputProps= {{min: 0}}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} xl={2}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Stage Total:<br/>
                                            Difference:
                                        </Grid>
                                        <Grid item xs={6} style={{color: parseFloat(_.sumBy(instructions, i => parseFloat(i.parts[data.part]) || 0.000) - data.quantity) === 0 ? colors.green : colors.red}}>
                                            {parseFloat(_.sumBy(instructions, i => parseFloat(i.parts[data.part]) || 0.000)).toFixed(3)}<br/>
                                            {`${parseFloat(_.sumBy(instructions, i => parseFloat(i.parts[data.part]) || 0.000) - data.quantity) === 0 ? '' : parseFloat(_.sumBy(instructions, i => parseFloat(i.parts[data.part]) || 0.000) - data.quantity) > 0 ? '+' : '-'}${parseFloat(Math.abs(_.sumBy(instructions, i => parseFloat(i.parts[data.part]) || 0.000) - data.quantity)).toFixed(3)}`}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>    
                        </Grid>
                        <Grid item style={{paddingTop: 30}}>
                                <IconButton onClick={handleRemove(idx)} disabled={(idx+1) === 1}>
                                    <FASolidIcon icon='trash-alt' noMargin button style={{color: (idx+1) === 1 ? '#fefefe' : 'inherit'}} />
                                </IconButton>
                        </Grid>
                    </Grid>   
                </form>                
            </Paper>
        )
    }
}

export default (PartCard);
