import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import React, { Component } from 'react';
import API from 'API';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import { IconButton, Tooltip, Grid, ExpansionPanelDetails, ExpansionPanelSummary, ExpansionPanel, 
        DialogContent, DialogActions, Dialog, CardActionArea, CardContent, Card, Typography, Button }  from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import { downloadS3File } from 'Functions/MiscFunctions';

const styles = theme => ({
    icons: {
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

class PartImages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            dialog: false,
            dialogFile: '',
            open: false
        }
    }

    componentDidMount(){
        // this.getPartImages();
    }

    getPartImages = () => {
        let tileData = [];
        API.get(`/library/parts/${this.props.partId}`)
        .then((result) => {
            result.data.forEach(val => {
                let imageFile = _.find(val.image_files.files, {'library_type': 'Part Image'});
                let imageFileRaw = _.find(val.image_files.files, {'library_type': 'Part Image File'});
                let imageFile3D = _.filter(val.image_files.files, {'library_type': 'Part 3D Image File'});
                let imageFileWeb3D = _.find(val.image_files.files, {'library_type': 'Part Web 3D Image File'});

                tileData.push({
                    fileUrl: val.library_file_url,
                    fileName: val.library_url,

                    disableImage: imageFile ? false : true,
                    disableImageRaw: imageFileRaw ? false : true,
                    disableImage3D: imageFile3D.length > 0 ? false : true,
                    disableImageWeb3D: imageFileWeb3D ? false : true,

                    imageUrl: imageFile,
                    imageRawUrl: imageFileRaw,
                    image3DUrls: imageFile3D,
                    imageWeb3DUrl: imageFileWeb3D,
                });
            });
            this.setState({
                tileData: tileData,
                open: true
            });
        })
    }

    downloadFile = data => e => {
        if(Array.isArray(data)){
            data.forEach((files) => {
                downloadS3File(files.file)
            });
       }
       else {
            downloadS3File(data.file)
       }
    }
    
    loadImage = (file) => {
        this.setState({ 
            dialog: true,
            dialogFile: file
        });
    };
    
    handleDialogClose = () => {
        this.setState({ 
            dialog: false,
            dialogFile: ''
        });
    };

    handleOpen = () => {
        if(!this.state.open){
            this.getPartImages();
        } else {
            this.setState({
                open: false
            });
        }
    }

    render() {
        const { classes } = this.props;
        const { open } = this.state;
        return (
            <React.Fragment>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={this.handleOpen}>
                        <Typography>Part Images</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {open &&
                            <>
                                {this.state.tileData && this.state.tileData.length > 0 ? 
                                    <Grid container spacing={3}>
                                        {this.state.tileData.map((tile, idx) => (
                                            <Grid item xs={6} lg={3} xl={2} key={idx}>
                                                <Card>                                            
                                                    <CardActionArea>
                                                        <CardContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 150}}>
                                                            <div onClick={() => this.loadImage(tile.fileUrl)}>
                                                                <ImageWithError src={tile.fileUrl} style={{cursor: 'pointer', height:'auto', maxHeight: 120, maxWidth: '100%'}} alt={tile.fileName} />
                                                            </div>
                                                        </CardContent>
                                                    </CardActionArea>
                                                    <CardContent style={{textAlign:'center'}}>
                                                        <div className={classes.icons}>
                                                            <Tooltip title="Image File">
                                                                <div>{/* Need tag for tooltip */}
                                                                    <IconButton onClick={this.downloadFile(tile.imageUrl)} disabled={tile.disableImage}>
                                                                        <FALightIcon icon='image' noMargin fixedHeight button disabled={tile.disableImage} />
                                                                    </IconButton>
                                                                </div>
                                                            </Tooltip>
                                                            <Tooltip title="Raw Image File">
                                                                <div>{/* Need tag for tooltip */}
                                                                    <IconButton onClick={this.downloadFile(tile.imageRawUrl)} disabled={tile.disableImageRaw}>
                                                                        <FALightIcon icon='file-image' noMargin fixedHeight button disabled={tile.disableImageRaw} />
                                                                    </IconButton>
                                                                </div>
                                                            </Tooltip>
                                                            <Tooltip title="3D Image File">
                                                                <div>{/* Need tag for tooltip */}
                                                                    <IconButton onClick={this.downloadFile(tile.image3DUrls)} disabled={tile.disableImage3D}>
                                                                        <FALightIcon icon='cube' noMargin fixedHeight button disabled={tile.disableImage3D} />
                                                                    </IconButton>
                                                                </div>
                                                            </Tooltip>
                                                            <Tooltip title="Web 3D Image File">
                                                                <div>{/* Need tag for tooltip */}
                                                                    <IconButton onClick={this.downloadFile(tile.imageWeb3DUrl)} disabled={tile.disableImageWeb3D}>
                                                                        <FASolidIcon icon='cube' noMargin fixedHeight button disabled={tile.disableImageWeb3D} />
                                                                    </IconButton>
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    </CardContent>
                                                    <CardContent style={{textAlign:'center'}}>
                                                        <Typography>{tile.fileName}</Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    :
                                    <Typography>There are 0 Part Images found.</Typography>
                                }
                            </>
                        }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Dialog
                    open={this.state.dialog}
                    onClose={this.handleDialogClose}
                    scroll="body"
                    disableEscapeKeyDown
                    disableBackdropClick	
                >                
                    <DialogContent>
                        <ImageWithError src={this.state.dialogFile} alt='Part Image' style={{maxWidth: 500}}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} variant="outlined" color="default">Close</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(PartImages);
