import { Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Typography, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import CheckBox from 'Components/Common/Inputs/CheckBox';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { handleAddOrRemove, handleChange, handleSelectChange } from 'Functions/FormFunctions';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { parse } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

const initialState = () => ({
    isLoading: false,
    formData: {
        type: null,
        search: '',
        customer: null,
        toSend: [],
        dontSend: [],
        neverSend: [],
    },
    dialog: {
        send: {
            open: false,
            customer: null,
            toSend: [],
            dontSend: [],
            neverSend: [],
        }
    }
})

class UnsentContactsManual extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();

        this.handleAddOrRemove = handleAddOrRemove.bind(this);
        this.handleChange = handleChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
    }



    #send = (contacts, noSend=[], neverSend=[]) => {
        let fd = new FormData();
        _.each({contacts, noSend, neverSend}, (v,k) => {
            fd.append(k, JSON.stringify(
                _.map(v, i => ({ id: i.contactId, invoice: 0}))
            ));
        });

        API.post(`/survey/${this.props.survey.survey_id}/contacts`, fd).then(res => {
            this.showSendDialog()();
            this.props.refresh(true);
        })
    }

    removePendingInvoice = ({contacts}) => () => {
        API.post(
            '/survey/' + this.props.survey.survey_id + '/contacts/removePending',
            {
                contacts: JSON.stringify(_.map(contacts, contact => ({
                    contactId: contact.id,
                    invoiceId: 0,
                })))
            }
        ).then(res => {
            this.props.refresh(true);
        })
    }

    removePending = (contact) => () => {
        API.post(
            '/survey/' + this.props.survey.survey_id + '/contacts/removePending',
            {
                invoiceId: 0,
                contactId: contact.id,
            }
        ).then(res => {
            this.props.refresh(true);
        })
    }

    showSendDialog = (contact=null) => () => {
        if (contact) {
            this.setState({
                dialog: {
                    send: {
                        ...initialState().dialog.send,
                        open: true,
                        customer: contact,
                    }
                }
            })
        } else {
            this.setState({
                dialog: {
                    send: initialState().dialog.send
                }
            })
        }
    }

    handleSend = () => {
        this.#send(
            _.map(this.state.dialog.send.toSend, id => ({
                contactId: id,
                id: 0,
            })),
            _.map(_.filter(this.state.dialog.send.customer.contacts, i => !this.state.dialog.send.toSend.includes(i.id)), ({id}) => ({
                contactId: id,
                id: 0,
            })),
            []
        );
       
    }

    render() {

        const { formData,  isLoading, dialog} = this.state;
        const contacts = this.props.pendingContacts;

        if (isLoading) {return (<LoadingCircle/>)}

        return (
            <Grid container spacing={2}
                alignItems='center'
                justifyContent='flex-end'
            >
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        label='Search'
                        value={formData.search}
                        onChange={this.handleChange}
                        name='search'
                    />
                </Grid>
                <Grid item xs={2}>
                    <AutoCompleteSelect
                        label='Customer'
                        options={_.uniqBy(contacts, 'name').map(i => ({value:i.name, label: i.name}))}
                        value={formData.customer}
                        onChange={e => this.handleSelectChange('customer',e)}
                        fullWidth
                        noClear
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button variant='outlined' onClick={()=>this.setState({formData: {
                        ...formData,
                        search: '',
                        customer: null,
                    }})}>Reset Search</Button>
                </Grid>                
                <Grid item xs={12}>
                    <CiDataTable
                        config={{
                            key: 'id',
                            pagination: contacts.length > 50,
                            rowsPerPage: 50,
                        }}
                        rows={_.orderBy(
                            (formData.search || formData.customer || formData.type) ? _.filter(contacts, i => 
                                (formData.search && (
                                    i?.name?.toLowerCase().includes(formData.search.toLowerCase()) ||
                                    i?.sites?.toLowerCase().includes(formData.search.toLowerCase()) ||
                                    i?.postcode?.toLowerCase().includes(formData.search.toLowerCase()) 
                                ) ||
                                (formData.customer && i.name === formData.customer)
                            )) :
                            contacts, i=>i.name, 'asc')}
                        columns={[
                            {
                                actionsCustomHeader:    true,
                                important:              true,
                                alignment:              'left',
                                heading:                'Customer',
                                actions: i => ([
                                    { label: `${i.name}`,         link: ()=>{window.open(`/customers/view/${i.id}`)},         type:'plainLink'},
                                ]),
                                dataRef: 'name',
                            },
                            // {
                            //     heading: 'surveys completed',
                            //     field: i => {
                            //         let s = _.filter(this.props.sent, _s => _.find(i.contacts, {id: _s.sc_contact_id}));
                            //         if (s.length === 0) return '-';
                            //         return (
                            //             `${_.filter(s, {sc_status: "Complete"}).length} / ${s.length}`
                            //         )
                            //     },
                            //     dataRef: i => _.filter(this.props.sent, _s => _.find(i.contacts, {id: _s.sc_contact_id})).length,
                            //     alignment: 'center'
                            // },
                            {
                                heading: 'pending contacts',
                                field: i => i.contacts.length,
                                dataRef: i => i.contacts.length,
                                alignment: 'center'
                            },
                            {
                                actions: i => ([
                                    {
                                        name: 'Remove',
                                        icon: IconHelper.delete,
                                        onClick: ()=>this.props.deployConfirmation(
                                            'Are you sure you want to remove this customer?',
                                            'Remove Contact',
                                            this.removePendingInvoice(i),
                                        ),
                                    },
                                    {
                                            name: 'Send',
                                            icon: IconHelper.email,
                                            onClick: this.showSendDialog(i),    
                                        }
                                ])
                            }
                        ]}
                    />
                </Grid>

                {/*Dialogs*/}
                {
                    dialog.send.open &&
                    <Dialog
                        open={dialog.send.open}
                        maxWidth='sm'
                        fullWidth
                    >
                        <DialogTitle>Send Survey - {dialog.send.customer.name}</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <List>
                                        <ListItem>
                                            <ListItemText/>
                                            <ListItemSecondaryAction>
                                                <Grid container spacing={1} style={{justifyContent: 'center', width: 60, alignItems: 'center'}}>
                                                    <Grid item xs={6}>
                                                        Send
                                                    </Grid>
                                                    {/* <Grid item xs={6}>
                                                        Don't Send
                                                    </Grid> */}
                                                    {/*}
                                                    <Grid item xs={4}>
                                                        Never Send
                                                    </Grid> */}
                                                </Grid>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                     {
                                            _.map(dialog.send.customer.contacts, contact => 
                                                <ListItem>
                                                    <ListItemText
                                                        primary={contact.name}
                                                        secondary={contact.email}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Grid container spacing={1} style={{justifyContent: 'center', width: 60}}>
                                                            <Grid item xs={6}>
                                                                <CheckBox
                                                                    color='primary'
                                                                    value={dialog.send.toSend.includes(contact.id) ? true : false}
                                                                    // disabled={dialog.send.neverSend.includes(contact.id) || dialog.send.dontSend.includes(contact.id)}
                                                                    onChange={ () => 
                                                                        this.setState({
                                                                            dialog: {
                                                                                send: {
                                                                                    ...dialog.send,
                                                                                    toSend: dialog.send.toSend.includes(contact.id) ?
                                                                                        _.filter(dialog.send.toSend, i => i !== contact.id) :
                                                                                        [...dialog.send.toSend, contact.id]
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                />
                                                            </Grid>
                                                            {/* <Grid item xs={6}>
                                                                <CheckBox
                                                                    color='secondary'
                                                                    disabled={dialog.send.neverSend.includes(contact.id) || dialog.send.toSend.includes(contact.id)}
                                                                    value={dialog.send.dontSend.includes(contact.id) ? true : false}
                                                                    onChange={ () => 
                                                                        this.setState({
                                                                            dialog: {
                                                                                send: {
                                                                                    ...dialog.send,
                                                                                    dontSend: dialog.send.dontSend.includes(contact.id) ?
                                                                                        _.filter(dialog.send.dontSend, i => i !== contact.id) :
                                                                                        [...dialog.send.dontSend, contact.id]
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                />
                                                            </Grid> */}
                                                            {/*<Grid item xs={4}>
                                                                <CheckBox
                                                                    color='secondary'
                                                                    value={dialog.send.neverSend.includes(contact.id) ? true : false}
                                                                    disabled={dialog.send.dontSend.includes(contact.id) || dialog.send.toSend.includes(contact.id)}
                                                                    onChange={ () => 
                                                                        this.setState({
                                                                            dialog: {
                                                                                send: {
                                                                                    ...dialog.send,
                                                                                    neverSend: dialog.send.neverSend.includes(contact.id) ?
                                                                                        _.filter(dialog.send.neverSend, i => i !== contact.id) :
                                                                                        [...dialog.send.neverSend, contact.id]
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                />
                                                            </Grid> */}
                                                        </Grid>
                                                        
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant='outlined'
                                onClick={this.showSendDialog()}
                            >Close</Button>
                            <Button
                                // disabled={[...dialog.send.toSend, ...dialog.send.dontSend, ...dialog.send.neverSend].length !== dialog.send.customer.contacts.length}
                                onClick={
                                    ()=>this.props.deployConfirmation(
                                        'Are you sure you want to send this survey to ' + dialog.send.toSend.length + ' contacts?',
                                        'Send Survey\'s',
                                        this.handleSend
                                    )
                                }
                                color='primary'
                                variant='contained'
                            >
                                {dialog.send.toSend.length > 0 ?
                                    <>Send {dialog.send.toSend.length > 0 ? `to ${dialog.send.toSend.length} contacts` : ''}</> :
                                    <>Skip Contacts</> 
                                }
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback, variant=null) => {
            dispatch(deployConfirmation(content, title, callback, null, variant))
        },
    }
}

export default connect(null, mapDispatchToProps)(UnsentContactsManual);