import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import _ from 'lodash';
import IconHelper from "Helpers/IconHelper";
import AllIcon from "Components/Common/Icons/AllIcon";
import { colors } from "Helpers/ColourHelper";
import { clenyDate } from "Helpers/TimeHelper";
import InfoBox from "Components/Common/reports/InfoBox";

class Runs extends React.Component {

    render(){
        return (
            <Grid container spacing={2}>
                <InfoBox
                    title='Runs'
                    value={this.props.campaign.runs.length}
                    icon={IconHelper.emailRuns}
                    rowSize={4}
                />
                <InfoBox
                    title='Sent'
                    value={_.sumBy(this.props.campaign.runs, i => i.responses.length)}
                    icon={IconHelper.email}
                    rowSize={4}
                />
                <InfoBox
                    title='Opened'
                    value={_.sumBy(this.props.campaign.runs, i => i.responses.filter(r => r.email.email_status === 'Opened').length)}
                    icon={IconHelper.emailOpen}
                    rowSize={4}
                />
                <InfoBox
                    title='Unsubscribed'
                    value={_.sumBy(this.props.campaign.runs, i => i.responses.filter(r => r.email.email_status === 'OptOut').length)}
                    icon={IconHelper.ban}
                    rowSize={4}
                />
                <Grid item xs={12}>
                    <CiDataTable
                        config={{
                            key: 'ecr_id',
                            dropRow: {
                                droppable: true,
                                style: {
                                    backgroundColor: `${colors.primary}20`,
                                },
                                columns: [
                                    {
                                        colSpan: 1,
                                        field: i => 
                                            <AllIcon
                                                icon={
                                                    i.email.email_status === 'Opened' ? 
                                                        IconHelper.emailOpen :
                                                        i.email.email_status === 'OptOut' ?
                                                            IconHelper.ban :
                                                            IconHelper.email
                                                }
                                                color={
                                                    i.email.email_status === 'Opened' ? 
                                                        colors.green :
                                                        i.email.email_status === 'OptOut' ?
                                                            colors.red :
                                                            colors.orange
                                                }
                                            />,
                                        alignment: 'center',
                                    },
                                    {
                                        colSpan: 1,
                                        field: i => <>
                                            <Typography variant="body2" >{
                                                i.email.email_status === 'Opened' ?  
                                                    'Opened' :
                                                    i.email.email_status === 'OptOut' ?
                                                        'Unsubscribed' :
                                                        'Sent'
                                            }</Typography>
                                            <Typography variant="caption" >{
                                                clenyDate(
                                                    i.email.email_status === 'Opened' ?  
                                                        i.email.email_opened_datetime :
                                                        i.email.email_status === 'OptOut' ?
                                                            i.email.email_datetime_optOut :
                                                            i.email.email_datetime_added
                                                )
                                            }</Typography>
                                        </>
                                    },
                                    {
                                        colSpan: 2,
                                        field: i => <>
                                            <Typography variant="body2" >{i.contact.contact_name}</Typography>
                                            <Typography variant="caption" >{i.email.email_to}</Typography>
                                        </>,
                                    }
                                ],
                                data: 'responses',
                            }
                        }}
                        rows={_.orderBy(this.props.campaign.runs, 'ecr_id', 'desc')}
                        columns={[
                            {
                                heading: '#',
                                field: 'ecr_run_count',
                                dataRef: 'ecr_run_count',
                                sizeToContent: true,
                                alignment: 'center',
                            },
                            {
                                heading: 'Date',
                                field: 'ecr_datetime',
                                dataRef: 'ecr_datetime',
                                fieldFormat: 'clenydate',
                                sizeToContent: true,
                            },
                            {
                                heading: 'Campaign',
                                field: i => `${this.props.campaign.ec_reference}-0${_.find(this.props.campaign.versions, {ec_id: i.ecr_ec_id})?.ec_reference_amendment}`,
                                dataRef: 'ecr_ec_id ',
                            },
                            {
                                heading: 'Sent',
                                field: i => i.responses.length,
                                dataRef: i => i.responses.length,
                                sizeToContent: true,
                                alignment: 'center',
                            },
                            {
                                heading: 'Opened',
                                field: i => i.responses.filter(r => r.email.email_status === 'Opened').length,
                                dataRef: i => i.responses.filter(r => r.email.email_status === 'Opened').length,
                                sizeToContent: true,
                                alignment: 'center',
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        )
    }

}

export default Runs;