import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import logo from 'Assets/Images/clenaware_logo_icon.png';
import DataTable from 'Components/Common/DataTables/DataTable';
import { FullScreenDialog } from 'Components/Common/Dialogs/FullScreenDialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import ViewPart from 'Components/Parts/ViewPart/ViewPart';
import EditPartRowDialog from './EditPartRowDialog';

import { colors } from 'Helpers/ColourHelper';

class CustomersReturnsPartItems extends Component {
    trimPartDescription = partDescription => {
        if(partDescription) {
            if(partDescription.length > 50){
                partDescription = partDescription.substring(0, 50) + '...';
            }
        } else {
            partDescription = '';
        }
        return partDescription;
    }

    edit = (rowData) => {
        this.props.deployDialog(<EditPartRowDialog part={rowData} usedSerialNumbers={this.props.usedSerialNumbers} warrantyVoidLabels={this.props.warrantyVoidLabels} 
            discount={this.props.discount} callBack={this.props.edit}/>,`Update Part for ${rowData.part_number} - ${rowData.part_description}`, null)
    }

    handleMove = (i,d) => {this.props.move(i,d)}

    remove = j => {this.props.remove(j)}

    render() {
        const { partRows, discount } = this.props; 
        const columns = (discount === 'With Discount' ?
            [
                {
                    field: rowData =>   <div style={{width: 35, height:35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <img src={rowData.thumbnail_url ? rowData.thumbnail_url : (rowData.default_image?.thumbnail_file_path ? rowData.default_image?.thumbnail_file_path : logo) } alt="" style={{maxHeight: 35, maxWidth: 35}} />
                                        </div>,
                    sizeToContent: true
                },
                {
                    heading: 'Part',
                    field: rowData =>   <React.Fragment >
                                            <span style={{color: colors.red}}>
                                                { `${rowData.part_number} - ${rowData.part_description}` }
                                            </span>
                                           <br/>
                                            {_.map(rowData.serialNumbers, (i,idx) => { return <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>{i.serialNumber}</Typography>})}
                                            {_.map(rowData.warrantyVoidLabels, (i,idx) => { return <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>{i}</Typography>})}
                                            {!!rowData.cnNote && 
                                                <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>
                                                    {rowData.cnNote}
                                                </Typography>
                                            }
                                        </React.Fragment>,
                    sizeToContent: true
                },
                {
                    heading: 'Quantity',
                    field: rowData => <span style={{color: colors.red}}>{parseFloat(rowData.quantity).toFixed(3)}</span>,
                    alignment: 'right'
                },
                {
                    heading: 'Unit Price',
                    field: rowData => <span style={{color: colors.red}}>{`£${parseFloat(rowData.unitPrice).toFixed(2)}`}</span>,
                    alignment: 'right'
                },
                {
                    heading: 'Discount Rate',
                    field: rowData => <span style={{color: colors.red}}>{`${parseFloat(rowData.discountRate).toFixed(2)}%`}</span>,
                    alignment: 'right'
                },
                {
                    heading: 'New Unit Price',
                    field: rowData => <span style={{color: colors.red}}>{`£${parseFloat(rowData.newUnitPrice).toFixed(2)}`}</span>,
                    alignment: 'right'
                },
                {
                    heading: 'Discount Total',
                    field: rowData => <span style={{color: colors.red}}>{`£${parseFloat(rowData.discountTotal).toFixed(2)}`}</span>,
                    alignment: 'right'
                },
                {
                    heading: 'Total Price',
                    field: rowData => <span style={{color: colors.red}}>{`£${parseFloat(rowData.subTotal).toFixed(2)}`}</span>,
                    alignment: 'right'
                },
                {
                    heading: 'Restocking Fee',
                    field: rowData => <span style={{color: colors.red}}>{`£${parseFloat(rowData.restockFeePrice).toFixed(2)}`}</span>,
                    alignment: 'right'
                },
                {
                    heading: 'Total Credit',
                    field: rowData => <span style={{color: colors.red}}>{`-£${parseFloat(rowData.totalPrice).toFixed(2)}`}</span>,
                    alignment: 'right'
                },
                {
                    heading: 'Part Restock',
                    alignment: 'center',
                    field: i => <AllIcon
                        icon={i.stockable ? (i.restock ? 'check' : 'times') : 'dash'}
                        style={{color: i.stockable ? ( i.restock ? colors.green : colors.red ) : colors.disabled}}
                        noMargin
                    />,
                    sizeToContent: true
                },
                {
                    actions: rowData => {
                        return [
                            {name: 'Update',    icon: 'pencil-alt',    onClick: () => {this.edit(rowData)}},
                            {name: 'View',      icon: 'search',        onClick: () => {this.props.deployDialog(<FullScreenDialog open={1} onClose={this.props.closeDialog} title="Part Information" content={<ViewPart id={rowData.part_id} />}/>, "", null, 'fs')}},
                            {name: 'Delete',    icon: 'trash-alt',     onClick: () => {this.remove(rowData)}},
                            {name: 'Move Up',   icon: 'chevron-up',    onClick: () => {this.handleMove(rowData.rowIdx,'up')}, disabled: !rowData.rowIdx},
                            {name: 'Move Down', icon: 'chevron-down',  onClick: () => {this.handleMove(rowData.rowIdx,'down')}, disabled: rowData.rowIdx === (partRows.length -1)}
                        ]
                    }
                }
            ] 
                :
            [
                {
                    field: rowData =>   <div style={{width: 35, height:35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <img src={rowData.partImage ? rowData.partImage : logo} alt="" style={{maxHeight: 35, maxWidth: 35}} />
                                        </div>,
                    sizeToContent: true
                },
                {
                    heading: 'Part',
                    field: rowData =>   <React.Fragment>
                                            <span style={{color: colors.red}}>
                                                { `${rowData.part_number} - ${rowData.part_description}` }
                                            </span>
                                            <br/>
                                            {_.map(rowData.serialNumbers, (i,idx) => { return <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>{i.serialNumber}</Typography>})}
                                            {_.map(rowData.warrantyVoidLabels, (i,idx) => { return <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>{i}</Typography>})}
                                            {!!rowData.cnNote && 
                                                <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>
                                                    {rowData.cnNote}
                                                </Typography>
                                            }
                                        </React.Fragment>,
                    sizeToContent: true
                },
                {
                    heading: 'Quantity',
                    field: rowData => <span style={{color: colors.red}}>{parseFloat(rowData.quantity).toFixed(3)}</span>,
                    alignment: 'right'
                },
                {
                    heading: 'Unit Price',
                    field: rowData => <span style={{color: colors.red}}>{'£' + parseFloat(rowData.unitPrice).toFixed(2)}</span>,
                    alignment: 'right'
                },
                {
                    heading: 'Total Price',
                    field: rowData => <span style={{color: colors.red}}>{'£' + parseFloat(rowData.subTotal).toFixed(2)}</span>,
                    alignment: 'right'
                },
                {
                    heading: 'Restocking Fee',
                    field: rowData => <span style={{color: colors.red}}>{`£${parseFloat(rowData.restockFeePrice).toFixed(2)}`}</span>,
                    alignment: 'right'
                },
                {
                    heading: 'Total Credit',
                    field: rowData => <span style={{color: colors.red}}>{`£${parseFloat(rowData.totalPrice).toFixed(2)}`}</span>,
                    alignment: 'right'
                },
                {
                    heading: 'Part Restock',
                    alignment: 'center',
                    field: i => <AllIcon
                        icon={i.stockable ? (i.restock ? 'check' : 'times') : 'dash'}
                        style={{color: i.stockable ? ( i.restock ? colors.green : colors.red ) : colors.disabled}}
                        noMargin
                    />,
                    sizeToContent: true
                },
                {
                    actions: rowData => {
                        return [
                            {name: 'Update',    icon: 'pencil-alt',    onClick: () => {this.edit(rowData)}},
                            {name: 'View',      icon: 'search',        onClick: () => {this.props.deployDialog(<FullScreenDialog open={1} onClose={this.props.closeDialog} title="Part Information" content={<ViewPart id={rowData.part_id} />}/>, "", null, 'fs')}},
                            {name: 'Delete',    icon: 'trash-alt',     onClick: () => {this.remove(rowData)}},
                            {name: 'Move Up',   icon: 'chevron-up',    onClick: () => {this.handleMove(rowData.rowIdx,'up')}, disabled: !rowData.rowIdx},
                            {name: 'Move Down', icon: 'chevron-down',  onClick: () => {this.handleMove(rowData.rowIdx,'down')}, disabled: rowData.rowIdx === (partRows.length -1)}
                        ]
                    }
                }
            ]);        

        return(
            <DataTable  
                config={{
                    key: 'part',
                }}
                columns={columns}
                rows={partRows}
            />
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployDialog: (content, title, variant, size='md')  => {dispatch(deployDialog(content, title, variant, size))},
    closeDialog:  ()                                    => {dispatch(closeDialog())}
});

export default connect(null, mapDispatchToProps)(CustomersReturnsPartItems);
