import React, { PureComponent } from 'react';
import _ from 'lodash';

import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, Link, Paper, Radio, RadioGroup, TextField, Typography, Button } from '@material-ui/core';


import AddressLookup      from 'Components/Common/AddressLookup/AddressLookup';
import FASolidIcon        from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import Textarea           from 'Components/Common/Inputs/Textarea';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import AccessTimes  from '../AccessTimes/AccessTimes';
import OpeningTimes from '../OpeningTimes/OpeningTimes';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

class AddressCard extends PureComponent {
    
    getLogoSrc = files => {
        let logo = _.find(files, {'cour_file_type': 'Logo'});
        return logo ? logo.file_url : '';
    }

    render() {
        const { classes, address, countryList, idx, handleChange, formErrors, handleCheckChange, handleRemove, addressType, handleSelectedAddress, tradingAddresses, useTradingAddress, handleManuallyEnterAddress, accountsList,
        //AccessTimes 
        linkAccount, handleDayChange, handleTimeChange, handleAddAccessTimes, handleAddAccessTimeRow, handleRemoveRow, addAccessTimes, formData, update, handleAddressSelectChange, addressNameList, handleNoteChange, addOpeningTimes } = this.props;
        return (
            <Paper className={classes.paperGrey}>
                <form noValidate autoComplete="off">
                    <Grid container>
                        <Grid item xs>
                            <RadioGroup
                                name="useAddress"
                                value={address.useAddress}
                                onChange={handleCheckChange(idx, addressType)}
                                row
                            >
                                <FormControlLabel
                                    value="custom"
                                    control={<Radio color="primary" />}
                                    label="Custom"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="registered"
                                    control={<Radio color="primary" />}
                                    label="Registered"
                                    labelPlacement="end"
                                />
                                {addressType !== 'tradingAddresses' &&
                                    <FormControlLabel
                                        value="trading"
                                        control={<Radio color="primary" />}
                                        label="Trading"
                                        labelPlacement="end"
                                    />
                                }
                            </RadioGroup>

                            {address.useAddress === 'trading' &&
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={tradingAddresses} 
                                        label='Use Trading Address'
                                        onChange={useTradingAddress(idx, addressType)}
                                    />
                                </FormControl>
                            }
                        </Grid>
                        <Grid item>                            
                            <IconButton onClick={handleRemove(idx, addressType)} disabled={(idx+1) === 1}>
                                <FASolidIcon icon='trash-alt' noMargin button style={{color: (idx+1) === 1 ? '#fefefe' : 'inherit'}} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <br></br>
                    <Typography variant="body1" gutterBottom>
                        <b>Address Details:</b>
                    </Typography>
                    {address.useAddress === 'custom' ?
                        <TextField
                            name="name"
                            label="Name *"
                            value={address.name}
                            className={classes.textField}
                            error={formErrors && formErrors[addressType+'|name|'+idx] && true}
                            helperText={formErrors && formErrors[addressType+'|name|'+idx]}
                            onChange={handleChange(idx, 'name', addressType)}
                            margin="none"
                            fullWidth
                        />
                    :
                        <FormControl error={formErrors && formErrors[addressType+'|name|'+idx] && true} fullWidth margin="normal">
                            <AutoCompleteSelect 
                                options={addressNameList} 
                                label='Name *'
                                onChange={handleAddressSelectChange(idx, 'name', addressType)}
                                value={address.name || ''}
                                creatable
                            />
                            {formErrors[addressType+'|name|'+idx] &&
                                <FormHelperText>{formErrors && formErrors[addressType+'|name|'+idx]}</FormHelperText>
                            }
                        </FormControl>
                    }
                    <FormControl fullWidth margin="normal">
                        <AutoCompleteSelect 
                            name="country"
                            options={countryList} 
                            label='Country'
                            onChange={handleChange(idx, 'country', addressType)}
                            value={address.country}
                        />
                    </FormControl>

                    {address.country === 243 && address.showAddressLookup && // United Kingdom
                        <AddressLookup 
                            handleSelectedAddress={handleSelectedAddress}
                            inlineLayout={false}
                            value={address.selectedAddress}
                        />
                    }

                    <Link component="button" variant="caption" className='blueLink' onClick={handleManuallyEnterAddress}>
                        {address.showAddressLookup ? 'Manually enter address': 'Use address lookup (UK Only)' }
                    </Link>

                    {(address.country !== 243 || (address.country === 243 && address.showAddress) || address.showAddress)  &&
                        <React.Fragment>
                            <TextField
                                name="addressOne"
                                label="Address Line 1 *"
                                value={address.addressOne}
                                className={classes.textField}
                                error={formErrors && formErrors[addressType+'|addressOne|'+idx] && true}
                                helperText={formErrors && formErrors[addressType+'|addressOne|'+idx]}
                                onChange={handleChange(idx, 'addressOne', addressType)}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                name="addressTwo"
                                label="Address Line 2"
                                value={address.addressTwo}
                                className={classes.textField}
                                onChange={handleChange(idx, 'addressTwo', addressType)}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                name="addressThree"
                                label="Address Line 3"
                                value={address.addressThree}
                                className={classes.textField}
                                onChange={handleChange(idx, 'addressThree', addressType)}
                                margin="normal"
                                fullWidth
                            />
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <TextField
                                        name="town"
                                        label="City / Town *"
                                        value={address.town}
                                        className={classes.textField}
                                        onChange={handleChange(idx, 'town', addressType)}
                                        error={formErrors && formErrors[addressType+'|town|'+idx] && true}
                                        helperText={formErrors && formErrors[addressType+'|town|'+idx]}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="postcode"
                                        label="Postcode"
                                        value={address.postcode}
                                        className={classes.textField}
                                        onChange={handleChange(idx, 'postcode', addressType)}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            {address.country === 243 &&
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        name="county"
                                        label="County"
                                        value={address.county}
                                        className={classes.textField}
                                        onChange={handleChange(idx, 'county', addressType)}
                                        margin="normal"
                                        fullWidth
                                    />
                                </FormControl>
                            }
                        </React.Fragment>
                    }
                     {addressType === 'siteAddresses' && 
                        <FormControl margin="normal" fullWidth>
                            <AutoCompleteSelect 
                                options={_.map(accountsList, (a,i)=>{return _.assign({value:i, label:a.name})})} 
                                label='Accounts Address'
                                onChange={linkAccount(idx)}
                                value={address.linkedAccount}
                                disabled={accountsList.length === 1}
                                isClearable={false}
                            />
                        </FormControl>
                    }
                    <TextField
                        id="phone"
                        name="phone"
                        label="Phone"
                        value={address.phone}
                        className={classes.textField}
                        onChange={handleChange(idx, 'phone', addressType)}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        id="email"
                        name="email"
                        label="Email"
                        value={address.email}
                        className={classes.textField}
                        onChange={handleChange(idx, 'email', addressType)}
                        margin="normal"
                        fullWidth
                    />
                    {addressType === 'siteAddresses' && (
                        <>
                            <AccessTimes
                                addressIdx={idx} 
                                handleDayChange={handleDayChange('accessTimes')}
                                handleTimeChange={handleTimeChange('accessTimes')}
                                handleAddAccessTimes={handleAddAccessTimes('addAccessTimes')}
                                handleAddAccessTimeRow={handleAddAccessTimeRow('accessTimes')}
                                handleRemoveRow={handleRemoveRow('accessTimes')}
                                addAccessTimes={addAccessTimes}
                                formData={formData}
                                formErrors={formErrors}
                                update={update}
                            />
                            <Textarea 
                                label="Access Time Notes"
                                name="accessTimeNotes"
                                onChange={handleNoteChange(idx)('accessTimeNotes')}
                            />
                        </>
                    )}
                    {addressType === 'siteAddresses' && (
                        <>
                            <OpeningTimes
                                addressIdx={idx} 
                                handleDayChange={handleDayChange('openingTimes')}
                                handleTimeChange={handleTimeChange('openingTimes')}
                                handleAddAccessTimes={handleAddAccessTimes('addOpeningTimes')}
                                handleAddAccessTimeRow={handleAddAccessTimeRow('openingTimes')}
                                handleRemoveRow={handleRemoveRow('openingTimes')}
                                addAccessTimes={addOpeningTimes}
                                formData={formData}
                                formErrors={formErrors}
                                update={update}
                            />
                            <Textarea 
                                label="Opening Time Notes"
                                name="openingTimeNotes"
                                onChange={handleNoteChange(idx)('openingTimeNotes')}
                            />
                        </>
                    )}
                    <Grid container spacing={2} style={{alignItems: 'center'}} key={this.props.key + idx}>
                        <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>Couriers</Typography> 
                        </Grid>
                        <Grid item xs={6} className='buttonRow' >
                            <Button onClick={this.props.checkZones}> <AllIcon icon={icons.location }/> Check Postcode </Button>
                        </Grid>
                        {_.map(_.filter(this.props.couriers, c => c.zones.length > 0), (i, idx) => {
                            let cz = _.find(address.zones, {courier: i.cour_id})
                            return (
                                <Grid item container xs={12} spacing={1} style={{alignItems: 'center', borderBottom: (idx + 1) !== _.filter(this.props.couriers, c => c.zones.length > 0).length && `1px solid ${colors.disabled}`}}>
                                    <Grid item>
                                        <Checkbox 
                                            checked={!!cz} 
                                            value={!!cz}
                                            onChange={()=>this.props.handleCourierCheck(i.cour_id)}
                                            key={this.props.key + idx + (cz ? 'checked' : 'unchecked')}
                                        />
                                    </Grid>
                                    <Grid item style={{paddingTop: '0.5em'}}>
                                        <img src={this.getLogoSrc(i.file)} alt='logo' style={{maxWidth: 64}} />
                                    </Grid>    
                                    <Grid item >{i.cour_name}</Grid>
                                    <Grid item xs={5} style={{marginLeft: 'auto'}}>
                                        <AutoCompleteSelect 
                                            fullWidth
                                            options={_.map(i.zones, z => ({label: z.cz_name, value: z.cz_id}))}
                                            onChange={this.props.handleCourierSelect(i.cour_id)}
                                            value={ cz ? cz?.zone : null }
                                            noClear
                                            disabled={!cz}
                                            key={this.props.key + idx}
                                        />
                                    </Grid>
                                </Grid>    
                            )}
                        )}
                    </Grid>
                </form>
            </Paper>
        )
    }
}

export default (AddressCard);
