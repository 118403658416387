import React, { Component} from 'react';
import _ from 'lodash';
import API from '../../../../API';
import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DataTable from '../../../Common/DataTables/DataTable';
import { downloadS3File } from 'Functions/MiscFunctions';

class Documents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            open: false
        }
    }

    componentDidMount(){
        //this.getDocumentsforPart();
    }

    getDocumentsforPart = () => {
        API.get(`/parts/${this.props.partId}/documents`)
        .then((result) => {
            this.setState({
                rows: result.data,
                open: true
            })
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.searchResults !== prevProps.searchResults) {
            this.setState({
                rows: _.orderBy(this.props.searchResults, 'dco_date_added', 'desc')
            });
        }
    }

    handleOpen = () => {
        if(!this.state.open){
            this.getDocumentsforPart();
        } else {
            this.setState({
                open: false
            });
        }
    }

    render() {
        const { rows, open} = this.state;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}  onClick={this.handleOpen}>
                    <Typography>Documents</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {open && <>
                        {rows.length > 0 ? 
                            <DataTable 
                                config={{
                                    key: 'dco_id',
                                    pagination: false,
                                    plainHeader: true,
                                }}
                                columns={[
                                    {
                                        heading: 'Category',
                                        field: row => row.category_name
                                    },
                                    {
                                        heading: 'Sub Category',
                                        field: row => row.subcategory_name
                                    },
                                    {
                                        heading: 'Title',
                                        field: row => row.doc_title
                                    },
                                    {
                                        actions: row => {
                                            return [
                                                {name: 'Download PDF', label: 'PDF', icon: 'download', onClick: () => { downloadS3File(row.file_url_pdf) }, disabled: (!row.file_url_pdf)},
                                                {name: 'Download Original', label: 'Original', icon: 'download', onClick: () => { downloadS3File(row.file_url_original) }, disabled: (!row.file_url_original)},
                                            ]
                                        }
                                    }
                                ]}
                                rows={rows}
                            />
                        :
                            <Typography>There are 0 Documents found.</Typography>
                        }
                    </>}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default Documents;
