import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, Input, InputAdornment, InputLabel, TextField, Typography } from '@material-ui/core';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import { FullScreenDialog } from 'Components/Common/Dialogs/FullScreenDialog';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import Image from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import PickingDialogContent from 'Components/Sales/Order/Picking/PickingDialogContent';
import StaffActions from 'Components/Sales/Order/ViewOrder/sections/StaffActions';

import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import AllIcon from 'Components/Common/Icons/AllIcon';
import { colors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import IconHelper from 'Helpers/IconHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';
import BackButton from 'Components/Common/Buttons/BackButton';

const initialState = {
    order:                  {},
    pickingList:            {},
    totalQty:               0,
    pickingDialogOpen:      false,
    isLoading:              true,
    disabled:               false,
    disabledMessage:        '',
    partDialogOpen:         false,
    partsMissingWeight:     [],
    shippingMissingWeights: [],
    missingWeights:         [],
    formData: {
        partWeights:     {},
        shippingWeights: {}
    },
    formErrors:             [],
    orderLocked:            true,
};

class PickOrder extends Component {
    constructor(props) {
        super(props);    
        this.state = initialState;
    }

    componentDidMount = () => {
        this.loadInitialData()
    }

    loadInitialData = () => {
        this.setState({
            ...this.state,
            isLoading: true
        }, 
        () => {
            API.get(`/sales/orders/pick/${this.props.match.params.orderId}`, 
            {
                params: {
                    forPicking: true
                }
            })
            .then(result => {
                let totalQty                = 0, 
                    pickingList             = [],
                    disabled                = false,
                    disabledMessage         = '',
                    partsMissingWeight      = [],
                    shippingMissingWeights  = [],
                    partWeights             = {},
                    shippingWeights         = {}, 
                    missingWeights          = [];

                result.data.order_details.forEach(detail => {
                    totalQty += parseFloat(detail.pick_detail.opd_quantity_to_follow);
                    // remove from picking list
                    if(parseFloat(detail.pick_detail.opd_quantity_to_follow) > 0 
                        && detail.pick_detail.opd_status !== 'Not Required') {
                            pickingList.push(detail);
                    }
                });
                
                // if no items are required to pick, disabled
                // a pick with 0 items req can only be accessed via url manipulation
                if(totalQty === 0) {
                    disabled = true;
                    disabledMessage = 'No items left to pick for this order';
                }

                pickingList.forEach(item => {
                    // if part requires serial number and there is no serial numbers or if there is not enough 
                    // issued serial numbers in the system disable pick order and show message explaining why
                    if(item.part.part_req_serial_number === 'Yes' && (item.part.part_model_type === null || item.part.part_model_type?.issued_serial_number?.length < parseInt(item.pick_detail.opd_quantity_to_follow))) {
                        if (item.part.part_model_type?.issued_serial_number?.length === 0){
                            disabled = true;
                            disabledMessage = 'Item has no serial numbers';
                        } else {
                            disabledMessage = disabledMessage != '' ?  disabledMessage : 'Item is missing serial numbers';
                        }
                    }
                    // Check if any parts have a weight of 0 - if they do, the user will need to enter weights

                    if(item.part.part_weight == 0 || item.part.part_shipping_weight == 0){
                        if (!_.find(missingWeights, {part_id:item.part.part_id})) missingWeights.push(item.part);
                        if (item.part.part_weight == 0 && item.part.part_shipping_weight == 0){
                            partWeights     = {...partWeights,      ...{[item.part.part_id]: ''}}
                            shippingWeights = {...shippingWeights,  ...{[item.part.part_id]: ''}}
                        } else {
                            if(item.part.part_weight == 0) {
                                partWeights     = {...partWeights,      ...{[item.part.part_id]: ''}}
                                shippingWeights = {...shippingWeights,  ...{[item.part.part_id]: item.part.part_shipping_weight}}
                            }
                            if(item.part.part_shipping_weight == 0) {
                                partWeights     = {...partWeights,      ...{[item.part.part_id]: item.part.part_weight }}
                                shippingWeights = {...shippingWeights,  ...{[item.part.part_id]: ''}}
                            }
                        }
                    }
                });

                // if order pick is in progress no other staff member will be able to pick
                if(result?.data?.order_pick?.op_status === 'In Progress' && result?.data?.order_pick?.op_staff_id !== this.props.loggedInStaff.id) {
                    disabled = true;
                    disabledMessage = 'Order is currently being picked by another member of staff'
                }

                this.setState({
                    ...this.state,
                    order: result.data,
                    pickingList,
                    totalQty,
                    isLoading: false,
                    disabled,
                    disabledMessage, 
                    partsMissingWeight,
                    partDialogOpen: missingWeights.length > 0 ? true : false,
                    formData: {
                        ...this.state.formData,
                        partWeights     : partWeights,
                        shippingWeights : shippingWeights
                    },
                    missingWeights,
                    shippingMissingWeights,
                }, this.handleShowNotes);
            });
        });
    }

    handleDownloadNotes = () => {
        API.get(`/sales/orders/${this.state.order.order_id}/pickingNotesPDF`)
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    handleShowNotes = () => {
        this.setState({
            ...this.state,
            showNotes: !this.state.showNotes
        });
    }

    acknoledgeNotes = () => {
        API.post(`/sales/orders/pick/${this.props.match.params.orderId}/acknowledgeNotes`)
        .then(result => {
            this.setState({
                ...this.state,
                showNotes: false
            });
        });
    }

    handleStartPick = () => {
        // api call to create order pick
        Promise.all([
            API.post(`/sales/orders/pick/${this.props.match.params.orderId}/start`),
        ]).then(res => {
            this.setState({
                ...this.state,
                pickingDialogOpen: true
            });
        })
    }

    // closing dialog mid pick
    handleEndPick = () => {
        this.setState({
            ...this.state,
            pickingDialogOpen: false
        });
    }

    handlePartDialogClose = () => {this.setState({partDialogOpen: false});};

    handleChange = (part, type = 'shippingWeights') => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [type]: {
                    ...this.state.formData[type],
                    [part]: e.target.value
                }
            }
        });
    }
    handleWeightChange = (part, type = 'partWeights') => e => {
        let newVal = parseFloat(e.target.value).toFixed(7);
        this.setState({
            formData: {
                ...this.state.formData,
                [type]: {
                    ...this.state.formData[type],
                    [part]: newVal
                }
            }
        });
    }

    submitPartWeights = () => {
        API.post('/parts/updatePartWeights', this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){     
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({partDialogOpen: false});
                this.props.deploySnackBar('success', 'You have successfully updated the part weight(s)');
                this.loadInitialData()
            }
        });
    }

    lockOrder = orderLocked => {
        this.setState({ orderLocked })
    }

    render = () => {
        const { order, pickingList, totalQty, pickingDialogOpen, isLoading, disabled, disabledMessage, formData, formErrors, missingWeights, orderLocked } = this.state;
        
        if (this.state.showNotes) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Please read and confirm you understand the following order notes</Typography>
                    </Grid>
                    <>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Grid container item spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Order Notes</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {order.order_notes ?
                                            order.order_notes.split('\n').map((item, key) => {
                                                return <span key={key}>{item}<br/></span>
                                            })
                                        :
                                            'No Notes'
                                        }
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item sm={12} md={12} xl={12}>
                            <PaddedPaper>
                                <Grid container item spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Picking Notes</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel shrink={true} style={{color: 'black'}}>Expected Picking Date</InputLabel>
                                            <Input value={moment(order.order_picking_date).format('DD/MM/YYYY')} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='calendar-alt' /></InputAdornment>} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {( order.order_picking_notes && order.order_picking_notes !== 'null' ) ?
                                            order.order_picking_notes.split('\n').map((item, key) => {
                                                return <span key={key}>{item}<br/></span>
                                            })
                                        :
                                            'No Notes'
                                        }
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item sm={12} md={12} xl={12}>
                            <PaddedPaper>
                                <Grid container item spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Courier Notes</Typography>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={3}>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={true} style={{color: 'black'}}>Expected Despatch Date</InputLabel>
                                                <Input value={moment(order.order_despatch_date !== "0000-00-00" && order.order_despatch_date !== null ? order.order_despatch_date : order.despatch[0].desp_despatched_date).format('DD/MM/YYYY')} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='calendar-alt' /></InputAdornment>} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={true} style={{color: 'black'}}>Expected Delivery Date</InputLabel>
                                                <Input value={moment(order.order_delivery_date !== "0000-00-00" && order.order_delivery_date !== null ? order.order_delivery_date : order.despatch[0].desp_delivery_date).format('DD/MM/YYYY')} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='calendar-alt' /></InputAdornment>} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={true} style={{color: 'black'}}>Courier</InputLabel>
                                                <Input value={order?.despatch?.length > 0 ? _.uniq(_.map(order.despatch, i => i.courier?.cour_name )).join(', ') : order?.courier?.cour_name} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='truck' /></InputAdornment>} />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{marginTop: '1em'}}>
                                        {order.order_courier_notes ?
                                            order.order_courier_notes.split('\n').map((item, key) => {
                                                return <span key={key}>{item}<br/></span>
                                            })
                                        :
                                            'No Notes'
                                        }
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </>
                    <Grid item xs={12} >
                        <PaddedPaper className='buttonRow'>
                            <Link to="/sales/order/picking">
                                <Button variant="outlined">
                                    Back
                                </Button>
                            </Link>
                            <Button onClick={this.handleDownloadNotes} variant="outlined" >Download</Button>
                            <Button onClick={this.acknoledgeNotes} variant="contained" color="primary">Acknowledge</Button>
                        </PaddedPaper>
                    </Grid>
                </Grid>
            );
        }

        return(
            <>
                <Grid container spacing={2}>
                    {isLoading ? (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12} lg={12}>
                                <StaffActions orderId={order.order_id} lockCallback={this.lockOrder} type={'Picking'}/>
                            </Grid>
                            <Grid item sm={12} md={12} xl={12}>
                                <PaddedPaper>
                                    <Grid container item spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="h4">
                                                {order?.full_reference}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                Customer: <strong>{order?.order_cust_name}</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                Despatch Date: <strong>{moment(order?.order_despatch_date).format('DD/MM/YYYY')}</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                Total Items To Pick: <strong>{totalQty}</strong>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item sm={12} md={12} xl={12}>
                                <PaddedPaper>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">Order Notes</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {order.order_notes ?
                                                order.order_notes.split('\n').map((item, key) => {
                                                    return <span key={key}>{item}<br/></span>
                                                })
                                            :
                                                'No Notes'
                                            }
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item sm={12} md={12} xl={12}>
                                <PaddedPaper>
                                    <Grid container item spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">Picking Notes</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={true} style={{color: 'black'}}>Expected Picking Date</InputLabel>
                                                <Input value={moment(order.order_picking_date).format('DD/MM/YYYY')} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='calendar-alt' /></InputAdornment>} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {( order.order_picking_notes && order.order_picking_notes !== 'null' ) ?
                                                order.order_picking_notes.split('\n').map((item, key) => {
                                                    return <span key={key}>{item}<br/></span>
                                                })
                                            :
                                                'No Notes'
                                            }
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item sm={12} md={12} xl={12}>
                                <PaddedPaper>
                                    <Grid container item spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">Courier Notes</Typography>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={3}>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel shrink={true} style={{color: 'black'}}>Expected Despatch Date</InputLabel>
                                                    <Input value={moment(order.order_despatch_date !== "0000-00-00" && order.order_despatch_date !== null ? order.order_despatch_date : order.despatch[0].desp_despatched_date).format('DD/MM/YYYY')} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='calendar-alt' /></InputAdornment>} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel shrink={true} style={{color: 'black'}}>Expected Delivery Date</InputLabel>
                                                    <Input value={moment(order.order_delivery_date !== "0000-00-00" && order.order_delivery_date !== null ? order.order_delivery_date : order.despatch[0].desp_delivery_date).format('DD/MM/YYYY')} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='calendar-alt' /></InputAdornment>} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel shrink={true} style={{color: 'black'}}>Courier</InputLabel>
                                                    <Input value={order?.despatch?.length > 0 ? _.uniq(_.map(order.despatch, i => i.courier?.cour_name )).join(', ') : order?.courier?.cour_name} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='truck' /></InputAdornment>} />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} style={{marginTop: '1em'}}>
                                            {order.order_courier_notes ?
                                                order.order_courier_notes.split('\n').map((item, key) => {
                                                    return <span key={key}>{item}<br/></span>
                                                })
                                            :
                                                'No Notes'
                                            }
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item sm={12} md={12} xl={12}>
                                <PaddedPaper>
                                    <Grid container item spacing={1}>
                                        <Grid item xs={12}>
                                            <DataTable 
                                                config={{
                                                    key: 'od_id',
                                                    isLoading
                                                }}
                                                columns={[
                                                    {
                                                        heading: '',
                                                        field: rowData => rowData.part.part_req_serial_number === 'Yes' && (rowData.part.part_model_type === null || rowData.part.part_model_type?.issued_serial_number.length < parseInt(rowData.pick_detail.opd_quantity_to_follow)) 
                                                                            ? <AllIcon icon={IconHelper.warning} style={{color: '#D61F26'}} tooltip={`Missing ${parseInt(parseInt(rowData.pick_detail.opd_quantity_to_follow) - rowData.part.part_model_type?.issued_serial_number.length )} Serial Numbers`} noMargin/> 
                                                                            : '',
                                                        sizeToContent: true,
                                                        alignment: 'center'
                                                    },
                                                    {
                                                        field: rowData =>   <div className="thumbnailContainer">
                                                                                <Image 
                                                                                    src={rowData.part?.default_image?.thumbnail_file_path} 
                                                                                    alt={rowData.part?.part_description} 
                                                                                    className="thumbnail" 
                                                                                />
                                                                            </div>,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Part Number',
                                                        field: rowData => rowData.od_part_number
                                                    },
                                                    {
                                                        heading: 'Part Description',
                                                        field: rowData => 
                                                            <>
                                                                <Typography >
                                                                    {rowData.od_part_description} 
                                                                </Typography>
                                                                <span style={{color: '#757575', fontSize: '12px'}}> 
                                                                    {rowData.od_electrical_connection ? `${rowData.od_electrical_connection} Connection`: '' }
                                                                </span>
                                                            </>,
                                                        truncate: true
                                                    },
                                                    {
                                                        heading: "Qty Ordered",
                                                        field: (rowData) =>
                                                            parseFloat(rowData.od_quantity),
                                                        alignment: "right",
                                                    },
                                                    {
                                                        heading: "Qty Required",
                                                        field: (rowData) =>
                                                            parseFloat(
                                                                rowData.pick_detail
                                                                    .opd_quantity_to_follow
                                                            ),
                                                        alignment: "right",
                                                    },
                                                    {
                                                        heading: "Qty Available",
                                                        field: (item) =>
                                                            {
                                                                if(item.part.part_req_serial_number === 'Yes' && (item.part.part_model_type === null || item.part.part_model_type?.issued_serial_number?.length < parseInt(item.pick_detail.opd_quantity_to_follow))) {
                                                                    return item.part.part_model_type?.issued_serial_number?.length;
                                                                }
                                                                if (item?.part?.stock) {
                                                                    if (parseFloat( ( parseFloat(item.part.stock.stock_available) + parseFloat( parseFloat(item.od_quantity) - parseFloat(item.pick_detail.opd_quantity_to_follow)) ) ) < parseFloat(item.pick_detail.opd_quantity_to_follow)) {
                                                                        return parseFloat(item.part.stock.stock_available) > 0 ? parseFloat(item.part.stock.stock_available) : 0;
                                                                    }
                                                                }
                                                                return parseFloat(item.pick_detail.opd_quantity_to_follow);
                                                            },
                                                        alignment: "right",
                                                    },
                                                    {
                                                        heading: "Qty Already Sent",
                                                        field: (rowData) =>
                                                            parseFloat(
                                                                rowData.pick_detail
                                                                    .opd_quantity_picked
                                                            ),
                                                        alignment: "right",
                                                    },
                                                ]}
                                                rows={pickingList}
                                            />
                                        </Grid>
                                        {disabledMessage && 
                                            <Grid item xs={12}>
                                                <Typography variant="body2" className="float-right textError">
                                                    <strong>{disabledMessage}</strong>
                                                </Typography>
                                            </Grid>
                                        }
                                        {orderLocked ? <>
                                            <Grid item xs={12} className='buttonRow'>
                                                <Link to="/sales/order/picking">
                                                    <Button variant="outlined">
                                                        Back
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        </> :
                                            <Grid item xs={12}>
                                                <div className="buttonRow">
                                                    <Link to="/sales/order/picking">
                                                        <Button variant="outlined">
                                                            Back
                                                        </Button>
                                                    </Link>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary"
                                                        onClick={this.handleStartPick}
                                                        disabled={disabled}
                                                    >
                                                        Start Pick
                                                    </Button>
                                                </div>
                                            </Grid>
                                        }
                                        
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </>
                    )}
                </Grid>
                <FullScreenDialog 
                    open={pickingDialogOpen}
                    onClose={this.handleEndPick}
                    title={`Picking For Order: ${order?.full_reference}`}
                    content={
                        <PickingDialogContent 
                            orderId={order.order_id}
                            pickingList={pickingList} 
                            totalQty={totalQty}
                            startIndex={order?.order_pick?.op_current_pick ?? 0}
                            order={order}
                        />
                    }
                    withMargin
                    noExit
                />
                {/* <Dialog
                    open={this.state.showNotes}
                    onClose={this.handleShowNotes}
                    fullScreen
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title" style={{backgroundColor: colors.primary,  fontWeight: 'bold', color: colors.white}}> 
                        <AllIcon icon={IconHelper.notes} color={colors.white} />Order Notes
                    </DialogTitle>
                    <DialogContent>
                        
                    </DialogContent>
                    <DialogActions>
                        
                    </DialogActions>
                </Dialog> */}
                <Dialog 
                    open={this.state.partDialogOpen} 
                    onClose={this.handlePartDialogClose} 
                    fullWidth={true} 
                    maxWidth="sm" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title" style={{backgroundColor: colors.yellow,  fontWeight: 'bold'}}> 
                        <FALightIcon icon='exclamation-triangle' noMargin /> Warning - Part Weight(s) Required!
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle2" gutterBottom>
                            The following parts have a weight of 0g - please update the parts below to continue picking (1000g = 1kg)
                        </Typography>

                        <Grid container spacing={1} style={{marginTop:'1em'}}>
                            <Grid item xs={2}><b>Part Number</b></Grid>
                            <Grid item xs={4}><b>Part</b></Grid>
                            <Grid item xs={3}><b>Part Weight</b></Grid>
                            <Grid item xs={3}><b>Shipping Weight</b></Grid>
                        </Grid>
                        <Divider />
                        {missingWeights.map((part, k) => ([
                            <Grid container key={k} spacing={1}  style={{padding:'10px 0 10px 0', display:'flex'}}>
                                <Grid container item xs={2} style={{alignContent:'center'}}>
                                    {part.part_number} 
                                </Grid>
                                <Grid container item xs={4} style={{alignContent:'center'}}>
                                    {part.part_description}
                                </Grid>
                                <Grid item xs={3}>
                                        <TextField
                                            id="partWeight"
                                            name="partWeight"
                                            value={formData.partWeights[part.part_id]}
                                            onChange={this.handleChange(part.part_id, 'partWeights')}
                                            onBlur={this.handleWeightChange(part.part_id)}
                                            error={formErrors && formErrors['partWeight'+part.part_id] && parseFloat(_.find(pickingList, {part: { part_id : part.part_id }}).part.part_weight) == 0.0000000 }
                                            helperText={ parseFloat(_.find(pickingList, {part: { part_id : part.part_id }}).part.part_weight) == 0.0000000 && formErrors && formErrors['partWeight'+part.part_id]}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">g</InputAdornment>
                                            }}
                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                            inputProps={{
                                                min: 0
                                            }}
                                            type="number"
                                            fullWidth
                                            disabled={_.find(pickingList, {part: { part_id : part.part_id }}).part.part_weight > 0}
                                        />
                                </Grid>
                                <Grid item xs={3}>
                                        <TextField
                                            id="shippingWeight"
                                            name="shippingWeight"
                                            value={formData.shippingWeights[part.part_id]}
                                            onChange={this.handleChange(part.part_id)}
                                            onBlur={this.handleWeightChange(part.part_id, 'shippingWeights')}
                                            error={formErrors && formErrors['shippingWeight'+part.part_id] && parseFloat(_.find(pickingList, {part: { part_id : part.part_id }}).part.part_shipping_weight) == 0.0000000 }
                                            helperText={ parseFloat(_.find(pickingList, {part: { part_id : part.part_id }}).part.part_shipping_weight) == 0.0000000 && formErrors && formErrors['shippingWeight'+part.part_id]}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">g</InputAdornment>
                                            }}
                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                            inputProps={{
                                                min: _.find(pickingList, {part: { part_id : part.part_id }}).part.part_weight 
                                            }}
                                            type="number"
                                            fullWidth
                                            disabled={_.find(pickingList, {part: { part_id : part.part_id }}).part.part_shipping_weight > 0}
                                        />
                                </Grid>
                            </Grid>
                        ,<Divider />]))}
                        <br/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.submitPartWeights} variant="contained" color="primary" style={{backgroundColor:'#5da14d', color:'white'}}>Update</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = state => ({
    loggedInStaff: state.staffAuth.staff
});

const mapDispatchToProps = dispatch => ({
    deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
});

export default connect(mapStateToProps, mapDispatchToProps)(PickOrder);