import React, { Component } from 'react';
import { connect }          from 'react-redux';
import _                    from 'lodash';
import API                  from 'API';

import SnackBar from 'Components/Common/SnackBars/SnackBar';

import CreditNotes                 from './CreditNotes';
import CreditNotesAwaitingApproval from './CreditNotesAwaitingApproval';
import InvoiceAwaitingApproval     from './InvoiceAwaitingApproval';
import Invoices                    from './Invoices';
import InvoicesFOC                 from './InvoicesFOC';
import RecurringInvoices           from './RecurringInvoices';
import RecurringInvoicesPayment    from './RecurringInvoicesPayment';
import InvoiceEndOfMonth            from './InvoiceEndOfMonth';

import { AppBar, Badge, Grid, Tab, Tabs, Typography } from '@material-ui/core';

import { deployDialog }                     from 'Actions/Dialog/Dialog';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';
import { deployConfirmation }               from 'Actions/Confirmation/Confirmation';

import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';
import TabBar from 'Components/Common/Navigation/TabBar';

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

const initialState = () => ({
    access: {
        customerOrderSearch: false
    },
    isLoading: false,
    currentTab: 'iaa',
    tabs: {},
});

class OrderInvoice extends Component {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'sales:overView';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        this.getAccess();
        this.getTabData();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/customer-order-search'),
            API.get('/staff/my/access/check/recurring-invoice'),
            API.get('/staff/my/access/check/delete-recurring-invoice'),
            API.get('/staff/my/access/check/amend-recurring-invoice'),
            API.get('/staff/my/access/check/invoice-credit-note')
        ])
        .then(([accessRes, riAccess, delRiAccess, amedRiAccess, cnAccess]) => {
            this.setState({
                access: {
                    customerOrderSearch:    (accessRes?.data?.has_access)    || false,
                    recurringInvoice:       (riAccess?.data?.has_access)     || false,
                    delRecurringInvoice:    (delRiAccess?.data?.has_access)  || false,
                    amendRecurringInvoice:  (amedRiAccess?.data?.has_access) || false,
                    creditNote:             (cnAccess?.data?.has_access)     || false,
                },
            })
        });
    }

    getTabData = () => {
        API.get('/sales/orders/invoice/tabsCount')
        .then(res => {
            this.setState({
                tabs: {
                    awaitingApproval:   res.data.awaitingApproval,
                    endOfMonth:         res.data.eomApproval,
                    invoices:           res.data.invoices,
                    invoicesFOC:        res.data.invoicesFOC,
                    RecurringApproval:  res.data.RecurringApproval,
                    Recurringinvoices:  res.data.Recurringinvoices,
                    creditNoteApproval: res.data.creditNoteApproval,
                    creditNotes:        res.data.creditNotes,
                }
            })
        })
    }

    getTabs = () => {
        let columns = [
            {name: 'iaa', label: this.state.tabs.awaitingApproval > 0 ? `Invoices Awaiting Approval (${this.state.tabs.awaitingApproval})` : 'Invoices Awaiting Approval' },
            {name: 'ieom', label: this.state.tabs.endOfMonth      > 0 ? `End Of Month Approval (${this.state.tabs.endOfMonth})` : 'End Of Month Approval' },
            {name: 'i',   label: this.state.tabs.invoices         > 0 ? `Invoices (${this.state.tabs.invoices})` : 'Invoices'},
            {name: 'if',   label: this.state.tabs.invoicesFOC     > 0 ? `Invoices FOC (${this.state.tabs.invoicesFOC})` : 'Invoices FOC'},
        ]

        if (this.state.access.recurringInvoice){
            columns.push({name: 'ri',   label: this.state.tabs.RecurringApproval > 0 ? `Recurring Invoices (${this.state.tabs.RecurringApproval})` : <span>Recurring Invoices</span>})
            columns.push({name: 'riaa', label: this.state.tabs.Recurringinvoices > 0 ? `Recurring Invoices Awaiting Payment (${this.state.tabs.Recurringinvoices})` : <span>Recurring Invoices Awaiting Payment</span>})
        }

        if (this.state.access.creditNote){
            columns.push({name: 'cn',  label: this.state.tabs.creditNoteApproval > 0  ? `Credit Notes Awaiting Approval (${this.state.tabs.creditNoteApproval})` : 'Credit Notes Awaiting Approval'})
            columns.push({name: 'cni', label: this.state.tabs.creditNotes        > 0  ? `Credit Notes (${this.state.tabs.creditNotes})` : 'Credit Notes'})
        }
        return columns;
    }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        }, () => {this.savePageState();});
    };

    render() {
        const { currentTab } = this.state;
        const tabs = this.getTabs();
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Invoicing
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TabBar
                        tabs={_.map(_.filter(tabs, i => i?.label), i => ({label: i.label, value: i.name, width: i.width}))}
                        currentTab={currentTab} 
                        handleTabChange={this.handleTabChange} 
                    />
                    {currentTab === 'iaa'  &&  <TabContainer><InvoiceAwaitingApproval     updateTabs={this.getTabData} history={this.props.history}/></TabContainer>}
                    {currentTab === 'ieom' &&  <TabContainer><InvoiceEndOfMonth           updateTabs={this.getTabData} history={this.props.history}/></TabContainer>}
                    {currentTab === 'i'    &&  <TabContainer><Invoices                    updateTabs={this.getTabData} history={this.props.history}/></TabContainer>}
                    {currentTab === 'if'   &&  <TabContainer><InvoicesFOC                 updateTabs={this.getTabData} history={this.props.history}/></TabContainer>}
                    {currentTab === 'ri'   &&  <TabContainer><RecurringInvoices           updateTabs={this.getTabData} history={this.props.history}/></TabContainer>}
                    {currentTab === 'riaa' &&  <TabContainer><RecurringInvoicesPayment    updateTabs={this.getTabData} history={this.props.history}/></TabContainer>}
                    {currentTab === 'cn'   &&  <TabContainer><CreditNotesAwaitingApproval updateTabs={this.getTabData} history={this.props.history}/></TabContainer>}
                    {currentTab === 'cni'  &&  <TabContainer><CreditNotes                 updateTabs={this.getTabData} history={this.props.history}/></TabContainer>}
                    
                </Grid>
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.emailSnackbarOpen}
                    onClose={this.handleEmailSnackbarClose}
                    message='You have successfully sent the email'
                />
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deployDialog:       (content, title, variant, size)         => {dispatch(deployDialog(content, title, variant, size))},
    clearPersistence:   (key)                                   => {dispatch(clearPersistence(key))},
    setPersistence:     (key, state)                            => {dispatch(setPersistence(key, state))},
    deployConfirmation: (message, title, success)               => dispatch(deployConfirmation(message, title, success))
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderInvoice);
