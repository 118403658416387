import React from 'react';
import Typography from '@material-ui/core/Typography';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import SnackBar from './../../Common/SnackBars/SnackBar'
import Button from '@material-ui/core/Button';
import API from '../../../API';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import FormControl from '@material-ui/core/FormControl';
import _ from 'lodash';
import VATRateList from './VATRateList';
import DatePicker from '../../Common/DatePickers/DatePicker';
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

const initialState = () => ({
    formData: {
        addVatType: {
            type: ''
        },
        addVatValue: {
            type: '',
            startDate: null,
            vatValue: '',
        }
    },
    formErrors: {
        addVatType: {},
        addVatValue: {}
    },
    snackbarOpen: {
        addVatType: false,
        addVatValue: false
    },
    confirmationOpen: {
        addVatType: false,
        addVatValue: false
    },
    vatTypes: {},
    vatTypeList: [],
    currentTab: 0,
    access: {
        addVATType: false,
        addVatValue: false
    },
    vatRates: {}
});

class VATRates extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'VATRates';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.checkAccess();
        this.getVatTypes();
        this.getVatValues();
    }

    getVatValues = () => {
        API.get('/accounts/vatRates/byType')
        .then(result => {
            if(result.data) {
                this.setState({
                    vatRates: result.data,
                });
            }
        });
    };

    getVatTypes = () => {
        API.get('/accounts/vatRates/types').then(result => {
            if(result.data) {
                let vatTypeList = _.map(result.data, (el) => {
                    return _.assign({
                        value: el.vat_type_id,
                        label: el.vat_type_name
                    });
                });
                this.setState({
                    vatTypes: result.data,
                    vatTypeList: vatTypeList
                });
            }
        });
    };
    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/vat-rates:add-vat-type'), 
            API.get('/staff/my/access/check/vat-rates:add-vat-value'),
        ])
        .then(([typeRes, vatRes]) =>  {
            typeRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addVATType: typeRes.data.has_access
                    }
                });
            vatRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addVatValue: vatRes.data.has_access
                    }
                });
        });
    }
    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        }, () => {this.savePageState();});
    };
    /*
     * Add VAT Type
     */
    addVatType = () => {
        API.post('/accounts/vatRates/types', this.state.formData.addVatType).then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        addVatType: formatValidationErrors(result.data.errors)
                    }
                });
            } else {
                this.clearPageState();
                this.setState({
                    ...initialState(),
                    snackbarOpen: {
                        ...this.state.snackbarOpen,
                        addVatType: true
                    }
                });
                this.checkAccess();
                this.getVatTypes();
                this.getVatValues();
            }
            this.props.scrollToTop();
        });
    }
    handleVatTypeChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                addVatType: {
                    ...this.state.formData.addVatType,
                    [e.target.name]: e.target.value
                }
            }
        });
    };
    handleVatTypeSnackbarClose = () => {
        this.setState({
            snackbarOpen: {
                ...this.state.snackbarOpen,
                addVatType: false
            }
        });
    };
    handleVatTypeConfirmationOpen = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addVatType: true
            }
        });
    };
    handleVatTypeConfirmationClose = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addVatType: false
            }
        });
    };
    handleVatTypeConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addVatType: false
            }
        });
        this.addVatType();
    };
    /*
     * Add VAT Value
     */
    addVatValue = () => {
        const formData = {
            ...this.state.formData.addVatValue,
            startDate: this.state.formData.addVatValue.startDate && (moment(new Date(this.state.formData.addVatValue.startDate)).format('DD/MM/YYYY'))
        }
        API.post('/accounts/vatRates', formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        addVatValue: formatValidationErrors(result.data.errors)
                    }
                });
            } else {
                this.clearPageState();
                this.setState({
                    ...initialState(),
                    currentTab: 1,
                    snackbarOpen: {
                        ...this.state.snackbarOpen,
                        addVatValue: true
                    }
                });
                this.checkAccess();
                this.getVatTypes();
                this.getVatValues();
            }
            this.props.scrollToTop();
        });
    }
    handleVatValueChange = (decimals) => (e) => {
        let newVal = decimals ? parseFloat(e.target.value).toFixed(decimals) : e.target.value;
        this.setState({
            formData: {
                ...this.state.formData,
                addVatValue: {
                    ...this.state.formData.addVatValue,
                    [e.target.name]: newVal
                }
            }
        });
    };
    handleVatValueSnackbarClose = () => {
        this.setState({
            snackbarOpen: {
                ...this.state.snackbarOpen,
                addVatValue: false
            }
        });
    };
    handleVatValueConfirmationOpen = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addVatValue: true
            }
        });
    };
    handleVatValueConfirmationClose = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addVatValue: false
            }
        });
    };
    handleVatValueConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addVatValue: false
            }
        },
        () => {
            this.addVatValue();
        });
    };
    handleSelectChange = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                addVatValue: {
                    ...this.state.formData.addVatValue,
                    [name]: res && res.value,
                }
            }
        });
    };
    handleDateChange = name => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                addVatValue: {
                    ...this.state.formData.addVatValue,
                    [name]: date,
                }
            }
        });
    };
    handleCheckChange = name => event => {
        this.setState({
            formData: {
                ...this.state.formData,
                addVatValue: {
                    ...this.state.formData.addVatValue,
                    [name]: event.target.checked
                }
            }
        })
    };
    render() {
        const { access, currentTab, vatRates, formData, formErrors, snackbarOpen, confirmationOpen } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        VAT Rates
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        {(access.addVATType || access.addVatValue) && (
                            <React.Fragment>
                                <AppBar position="static" style={{backgroundColor:'white'}} >
                                    <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                                        <Tab label="Add Type" disabled={!access.addVATType} />
                                        <Tab label="Add VAT Value" disabled={!access.addVatValue} />
                                    </Tabs>
                                </AppBar>
                                {(access.addVATType && currentTab === 0) && (
                                    <TabContainer>
                                        <Grid container item spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <PaddedPaper>
                                                    <Typography variant="h6">
                                                        Add VAT Type
                                                    </Typography>
                                                    <form onChange={this.handleVatTypeChange} noValidate autoComplete="off">
                                                        <TextField
                                                            id="type"
                                                            name="type"
                                                            label="Type *"
                                                            value={formData.addVatType.type}
                                                            error={formErrors.addVatType && formErrors.addVatType['type'] && true}
                                                            helperText={formErrors.addVatType && formErrors.addVatType['type']}
                                                            margin="normal"
                                                            fullWidth
                                                        />
                                                        <div className='buttonRow'>
                                                            <Button 
                                                                onClick={this.handleVatTypeConfirmationOpen}
                                                                variant="contained" 
                                                                color="primary" 
                                                                disabled={!this.state.formData.addVatType.type}
                                                            >
                                                                Add
                                                            </Button>
                                                        </div>
                                                        <ConfirmationDialog 
                                                            open={confirmationOpen.addVatType} 
                                                            success={this.handleVatTypeConfirmationSuccess} 
                                                            close={this.handleVatTypeConfirmationClose} 
                                                            title="Add A New VAT Type?" 
                                                            message="Are you sure you want to add a new VAT type?"
                                                        />
                                                        <SnackBar
                                                            variant="success"
                                                            anchorOriginVertical='bottom'
                                                            anchorOriginHorizontal='right'
                                                            open={snackbarOpen.addVatType}
                                                            onClose={this.handleVatTypeSnackbarClose}
                                                            message="You have successfully added a new VAT type"
                                                        />
                                                    </form>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </TabContainer>
                                )}
                                {(access.addVatValue && currentTab === 1) && (
                                    <TabContainer>
                                        <Grid container item spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <PaddedPaper>
                                                    <Typography variant="h6">
                                                        Add VAT Value
                                                    </Typography>
                                                    <form noValidate autoComplete="off">
                                                        <FormControl fullWidth margin="normal">
                                                            <AutoCompleteSelect 
                                                                options={this.state.vatTypeList} 
                                                                label='Type *'
                                                                onChange={this.handleSelectChange('type')}
                                                                error={formErrors.addVatValue && formErrors.addVatValue['type'] && true}
                                                                errorText={formErrors.addVatValue && formErrors.addVatValue['type']}
                                                            />
                                                        </FormControl>
                                                        <Grid container item spacing={3}>
                                                            <Grid item xs={12} md={4}>
                                                                <DatePicker
                                                                    type="date"
                                                                    id="startDate"
                                                                    name="startDate"
                                                                    label="Start Date *"
                                                                    value={formData.addVatValue.startDate}
                                                                    errorText={formErrors.addVatValue && formErrors.addVatValue['startDate']}
                                                                    onChange={this.handleDateChange('startDate')}
                                                                    autoOk={true}
                                                                    disablePast={true}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container item spacing={3}>
                                                            <Grid item xs={12} md={4}>
                                                                <TextField
                                                                    id="vatValue"
                                                                    name="vatValue"
                                                                    label="VAT Value *"
                                                                    value={formData.addVatValue.vatValue}
                                                                    error={formErrors.addVatValue && formErrors.addVatValue['vatValue'] && true}
                                                                    helperText={formErrors.addVatValue && formErrors.addVatValue['vatValue']}
                                                                    margin="normal"
                                                                    type="number"
                                                                    onChange={this.handleVatValueChange()}
                                                                    onBlur={this.handleVatValueChange(2)}
                                                                    fullWidth
                                                                    InputProps={{
                                                                        endAdornment: (<InputAdornment position="end">%</InputAdornment>)
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        
                                                        <div className='buttonRow'>
                                                            <Button 
                                                                onClick={this.handleVatValueConfirmationOpen}
                                                                variant="contained" 
                                                                color="primary" 
                                                                disabled={!formData.addVatValue.vatValue && !formData.addVatValue.type && !formData.addVatValue.startDate}
                                                            >
                                                                Add
                                                            </Button>
                                                        </div>
                                                        <ConfirmationDialog 
                                                            open={confirmationOpen.addVatValue} 
                                                            success={this.handleVatValueConfirmationSuccess} 
                                                            close={this.handleVatValueConfirmationClose} 
                                                            title="Add A New VAT Value?"
                                                            message="Are you sure you want to add a new VAT value?"
                                                        />
                                                        <SnackBar
                                                            variant="success"
                                                            anchorOriginVertical='bottom'
                                                            anchorOriginHorizontal='right'
                                                            open={snackbarOpen.addVatValue}
                                                            onClose={this.handleVatValueSnackbarClose}
                                                            message="You have successfully added a new VAT value"
                                                        />
                                                    </form>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </TabContainer>
                                )}
                            </React.Fragment>
                        )}
                        <div className={`padding3  ${(access.addVATType || access.addVatValue) && 'paddingTop0'}`}>
                            <VATRateList vatRates={vatRates} />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(VATRates);