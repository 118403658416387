import API from 'API';
import _ from 'lodash';
import React,{ Component } from 'react';
import { connect } from 'react-redux';

import { Button, Grid, IconButton, Link, Step, StepLabel, Stepper, Tooltip, Typography } from '@material-ui/core';
import MuiLink from '@material-ui/core/Link';
import { withStyles } from '@material-ui/styles';


import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import BackButton from 'Components/Common/Buttons/BackButton';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher';
import PDFViewer from 'Components/Common/PDFViewer/PDFViewer';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import StickyNotes from 'Components/Common/StickyNotes/StickyNotes';

import SingleEmailDialog from 'Components/Common/Dialogs/SingleEmailDialog';
import ViewEngineerMedia from 'Components/Common/EngineerMedia/ViewEngineerMedia';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import { downloadS3File } from 'Functions/MiscFunctions';
import ActivityLogs from './sections/ActivityLogs';
import AddFilesDialog from './sections/AddFilesDialog';
import AddNotesDialog from './sections/AddNotesDialog';
import CancelDialog from './sections/CancelDialog';
import Collection from './sections/Collection';
import EngineerMediaDialog from './sections/EngineerMediaDialog';
import GoodsReceived from './sections/GoodsReceived';
import GoodsInspected from './sections/GoodsInspected';
import Notes from './sections/Notes';
import Outcome from './sections/Outcome';
import StatusBox from './sections/StatusBox';
import ViewOrder from './sections/ViewOrder';
import ViewReturn from './sections/ViewReturn';
import ViewEmails from 'Components/Common/Email/ViewEmails/ViewEmails';

const initialState = () => ({
    isLoading: true,
    cr: {},
    access: {
        amend: false,
    }
})

const statusToEmail = {
    'Awaiting Approval': 9,
    'Declined': 10,
    'Awaiting Confirmation': 15,
    'Awaiting Collection': 16,
    'Awaiting Review':12,
    'Completed': 13,
    'Cancelled': 14,
}

class ViewCustomerReturns extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getCR();
        this.getAccess();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/amend-return'),
        ]).then(res => {
            this.setState({
                access: {
                    amend: res[0]?.data?.has_access || false,
                }
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (parseInt(prevProps.match.params.id) !== parseInt(this.props.match.params.id)) {
            this.getCR();
        }
    }

    getCR = () => {
        API.get(`/customerReturns/returns/${this.props.match.params.id}`)
        .then(res => {
            this.setState({ 
                cr: res.data, 
                isLoading: false 
            });
        })
    }

    handleDownload = () => {
        API.get(`/customerReturns/returns/${this.state.cr.cr_id}/prevDownload`, {params: {status: 'Preview'}})
        .then(res => {
            pdfFromBase64(res.data.pdf, res.data.filename);
        });
    }

    handlePDFView = () => {
        API.get(`/customerReturns/returns/${this.state.cr.cr_id}/prevDownload`, {params: {status: 'Preview'}})
        .then(result => { 
            if (result.data) this.props.deployDialog(<PDFViewer disableDownload onClose={this.props.closeDialog} pdfData={result.data.pdf} style={{ height:'100%', maxHeight: 700, minHeight: 400, maxWidth: '100%' }} />,'', null, 'md');
        });
    }

    handleAddNote = () => {
        this.props.deployDialog(
            <AddNotesDialog
                cr={this.state.cr}
                onClose={()=>{this.props.closeDialog(); this.getCR();}}
            />,
            'Add Notes', '', 'md'
        )
    }
   
    handleCancel = () => {
        this.props.deployDialog(
            <CancelDialog
                cr={this.state.cr}
                onClose={(success=false)=>{this.props.closeDialog();  if (success) { this.handleEmail(14); }}}
            />,
            'Cancel This Order?', 'warning', 'md'
        )
    }

    handleEmail = (template, callBack) => {
        let id = this.state.cr.cr_id;
        Promise.all([
            API.get(`/customerReturns/emailTemplates/${template}`),
            API.get(`/customerReturns/returns/${id}`)
        ])
        .then(res => {
            this.props.deployDialog(
                <SingleEmailDialog
                    contactId={res[1].data.cr_collection_address_contact_id}
                    customerId={res[1].data.cr_cust_id}
                    subject={res[0].data.cret_name}
                    body={res[0].data.cret_text}
                    emailUrl={`/customerReturns/returns/${id}/sendEmail`}
                    postParams={{
                        templateId: template,
                    }}
                    onSubmit={()=>{this.getCR(); callBack && callBack()}}
                    onClose={()=>{this.getCR(); callBack && callBack()}}
                />, 'Email Customer Return', template === 14 ? 'warning' : 'success', 'sm'
            )
        })
    }

    handleAddEngineerMedia = () => {
        this.props.deployDialog(
            <EngineerMediaDialog
                cr={this.state.cr}
                onClose={()=>{this.props.closeDialog(); this.getCR();}}
            />,
            'Add Engineer Media', '', 'sm'
        )
    }

    handleAddFiles = () => {
        this.props.deployDialog(
            <AddFilesDialog
                cr={this.state.cr}
                onClose={()=>{this.props.closeDialog(); this.getCR();}}
            />,
            'Add Files', '', 'sm'
        )
    }

    render() {

        const { cr, isLoading, access } = this.state;
        const { classes } = this.props;

        if (isLoading) return ( <LoadingCircle/> )

        let statuses = [];
        let activeStep = 0;

        if (cr.cr_status === 'Declined' || cr.cr_status === 'Cancelled') {
            statuses = [ cr.cr_status];
            activeStep = 1;
        } else {

            statuses = ['Approval', 'Confirmed', 'Collection', 'Inspection', 'Review', 'Completed'];
            activeStep = 0;
            switch (cr.cr_status) {
                case 'Awaiting Approval':
                    activeStep = 0;
                    break;
                case 'Awaiting Confirmation':
                    activeStep = 1;
                    break;
                case 'Awaiting Collection':
                    activeStep = 2;
                    break;
                case 'Awaiting Inspection':
                    activeStep = 3;
                    break;
                case 'Awaiting Review':
                    activeStep = 4;
                    break;
                case 'Awaiting Outcome':
                case 'Completed':
                    activeStep = 5;
                    break;
            }
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={9}>
                    <PaddedPaper>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                                    {statuses.length === 1 ? 
                                        statuses.map(label => (<Step key={label}>
                                            <StepLabel icon={statuses.length === 1 && <AllIcon noMargin style={{backgroundColor: colors.red, borderRadius: 20, padding: 5 }} white icon={icons.false}/>} >{label}</StepLabel>
                                        </Step>)) :
                                        statuses.map(label => (<Step key={label}>
                                            <StepLabel >{label}</StepLabel>
                                        </Step>))
                                    }
                                </Stepper>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" style={{textAlign: 'center'}}>{cr.cr_reference}-{cr.cr_reference_amendment < 10 ? `0${cr.cr_reference_amendment}` : cr.cr_reference_amendment}</Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                    Customer Return (v{cr.cr_reference_amendment}): 
                                    <Button variant="outlined" color="default" size="small" className={classes.btn} onClick={this.handleDownload} ><AllIcon icon={icons.download}/> Download</Button>
                                    <Button variant="outlined" color="default" size="small" className={classes.btn} onClick={this.handlePDFView} ><AllIcon icon={icons.search} />View</Button> 
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TabBarSwitcher
                                    tabs={[
                                        {
                                            label:   'Customer Return',
                                            content: <ViewReturn cr={cr} />,
                                        },
                                        /*parseFloat(_.sumBy(cr.details, 'crd_qty')) !== parseFloat(_.sumBy(cr.order, 'crd_qty')) && {
                                            label:   'Excluded From Return',
                                            content: <ExcludedFromReturn cr={cr} />,
                                        },*/
                                        cr?.collection && 
                                        {
                                            label:   'Collection',
                                            content: <Collection cr={cr} refresh={this.getCR} />,
                                        },
                                        cr?.collection &&
                                        {
                                            label:   'Delivered',
                                            content: <GoodsReceived cr={cr} outcomes={ cr.outcomes } preview/>,
                                        },
                                        _.find(cr?.collection?.details, i => i.inspections.length>0) && 
                                        {
                                            label:   'Inspected',
                                            content: <GoodsInspected cr={cr} outcomes={ cr.outcomes } preview/>,
                                        },
                                        ...(cr.outcomes.length > 0 ? 
                                            _.map(
                                                cr.outcomes,
                                                (outcome, i) => ({
                                                    label:   outcome.cro_outcome === 'Refund' ? 'Credit Note' : outcome.cro_outcome,
                                                    content: <Outcome cr={cr} outcome={outcome} />,
                                                })
                                            ) : []),
                                        {
                                            label:   `Notes (${cr?.notes?.length ?? 0})`,
                                            content: <Notes cr={cr} refresh={this.getCR}/>,
                                        },
                                        {
                                            label:   'Activity Logs',
                                            content: <ActivityLogs cr={cr} />,
                                        },
                                        {
                                            label:   'Order',
                                            content: <ViewOrder order={cr.order} cr={cr} />,
                                        },
                                         {
                                            label: `Emails`,
                                            content: <ViewEmails
                                                customerReturnId={cr.cr_id}
                                                smallMode
                                                sendProps={
                                                    {
                                                        to: [cr.cr_collection_address_contact_id],
                                                        customerId: cr.cr_cust_id,
                                                    }
                                                }
                                            />
                                        },
                                        {
                                            label:   `Engineer Media`,
                                            content: <ViewEngineerMedia 
                                                        returnId={cr.cr_id} 
                                                        customerId={cr.cr_cust_id}
                                                        slim
                                                    />,
                                        }
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                
                                <Grid container spacing={1}>
                                <Grid item>
                                        <Button
                                            color='primary'
                                            variant='contained'
                                            onClick={()=>this.handleEmail(statusToEmail[cr.cr_status])}
                                        >
                                            <AllIcon icon={icons.email} white/> Email
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color='primary'
                                            variant='contained'
                                            onClick={this.handleAddNote}
                                        >
                                            <AllIcon icon={icons.plus} white/> Add Notes
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color='primary'
                                            variant='contained'
                                            onClick={this.handleAddFiles}
                                        >
                                            <AllIcon icon={icons.plus} white/> Add Files
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color='primary'
                                            variant='contained'
                                            onClick={this.handleAddEngineerMedia}
                                        >
                                            <AllIcon icon={icons.plus} white/> Add Engineer Media
                                        </Button>
                                    </Grid>
                                    {!!cr.cr_latest &&
                                        <>
                                            {['Awaiting Approval', 'Awaiting Confirmation'].includes(cr.cr_status) && [
                                                    <Grid item>
                                                        <Button
                                                            color='primary'
                                                            variant='contained'
                                                            onClick={() => this.props.history.push(`/returns/edit/${cr.cr_id}`)}
                                                            disabled={!access.amend}
                                                        ><AllIcon icon={icons.edit} white/>Amend</Button>
                                                    </Grid>,
                                                    <Grid item>
                                                        <Button
                                                            color='secondary'
                                                            variant='contained'
                                                            onClick={this.handleCancel}
                                                            disabled={!access.amend}
                                                        ><AllIcon icon={icons.cancel} white/>Cancel Return</Button>
                                                    </Grid>,
                                                ]
                                            }
                                        </>
                                    }
                                </Grid>
                                
                            </Grid>
                            <Grid item xs={12} className='buttonRow' style={{marginTop: 0}}>
                                <BackButton props={this.props}/>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <StatusBox cr={cr} />
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h5">
                                    Previous Customer Returns
                                </Typography>
                                {cr.other_returns.length > 1 ?
                                    _.map(cr.other_returns, i => 
                                        i.cr_id !== cr.cr_id ?
                                            <Typography variant="body1">
                                                <Link
                                                    onClick={()=>this.props.history.push(`/returns/customerReturn/${i.cr_id}`)}
                                                    style={{cursor: 'pointer'}}
                                                >{cr.cr_reference}-{i.cr_reference_amendment < 10 ? `0${i.cr_reference_amendment}` : i.cr_reference_amendment}</Link>   
                                            </Typography> :
                                            <Typography variant="body1">{cr.cr_reference}-{i.cr_reference_amendment < 10 ? `0${i.cr_reference_amendment}` : i.cr_reference_amendment}</Typography>
                                    ) : <Typography variant="body1">0 previous Customer Returns</Typography>}
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h5">
                                    Engineer Job Sheet's
                                </Typography>
                                {cr.job_sheets?.length > 0 ? 
                                    cr.job_sheets?.map((rev, idx) => (
                                        <Grid container key={idx} style={{alignItems: 'center'}}>
                                            <Grid item xs={1}>
                                                { idx === (cr.job_sheets?.length - 1) ? 
                                                    <Tooltip title={'Delete'}>
                                                        <IconButton size='small' onClick={()=>this.handleDeleteFileConfirmation(rev.crf_cr_id)}>
                                                            <FALightIcon icon='trash-alt' size="small" noMargin button /> 
                                                        </IconButton> 
                                                    </Tooltip>    
                                                    : <></> 
                                                }
                                            </Grid>

                                            <Grid item xs={11} style={{padding:'2px'}}>
                                                {rev.co_file_sub_type} 
                                                <MuiLink component="div" variant="body2" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={() => {downloadS3File(rev.file_path)}}>
                                                    {_.last(rev.crf_file.split('/')) || '-'}
                                                </MuiLink>
                                            </Grid>
                                        </Grid>
                                    ))
                                : (
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            0 job sheet(s)
                                        </Typography>
                                    </Grid>
                                )}
                            </PaddedPaper>
                        </Grid>
                        {!!cr.cr_latest ?
                            <Grid item xs={12}>
                                <PaddedPaper style={{backgroundColor: colors.stickyNotes}}>
                                    <Typography variant="h5">
                                        Sticky Notes
                                    </Typography>
                                    <StickyNotes edit={1} crId={cr.cr_group_id}/>
                                </PaddedPaper>
                            </Grid>
                        : null}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    btnRow: {
        textAlign: 'center', 
    },
    btnRowBtn: {   
        width: '100%',
    },
    link: {
        textDecoration: 'none',
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        },
        stepper: {
            display: 'none',
        },
    },
    statusHeading: {
        display: 'flex',
        alignItems: 'center'
    },
    label: {
        color: 'black'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tabScrollButtons: {
        color: 'black'
    }
});


const mapDispatchToProps = dispatch => ({
    deployDialog: (content, title, variant, size='md')  => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:  ()                                    => dispatch(closeDialog()),
    deployConfirmation: (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(ViewCustomerReturns));
