import React, { Component} from 'react';
import { connect }         from 'react-redux';

import { Typography }        from '@material-ui/core';
import ExpansionPanel        from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon        from '@material-ui/icons/ExpandMore';

import StockReports from 'Components/Reports/StockReports/StockReports.js';

import {setPersistence} from 'Actions/StatePersistence/StatePersistence';
import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

class PartStock extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `Parts:${this.props.partId}:PartSales`;
        this.state              = this.getInitialState({
            rows: [],
            open: false,
        });
    }

    componentDidMount(){
        if (this.state.open) this.myRef.scrollIntoView();
    }

    handleOpen = () => {
        this.setState({open: !this.state.open},()=>{this.savePageState()});
    }

    render() {
        const {open} = this.state;
        return (
            <ExpansionPanel defaultExpanded={open} onClick={this.handleOpen} ref={(ref) => this.myRef=ref}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Stock Report</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {open &&
                        <box style={{Width:1522}}>
                            <StockReports partId={this.props.partId}/>
                        </box>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}


const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    setPersistence: (key, state) => dispatch(setPersistence(key, state)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PartStock);
