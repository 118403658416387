import { Button, Grid, Table, TableCell, TableHead, TableRow } from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import FormView from 'Components/Common/Forms/FormView/FormView';
import AllIcon from 'Components/Common/Icons/AllIcon';
import InfoBox from 'Components/Common/reports/InfoBox';
import { handleAddOrRemove } from 'Functions/FormFunctions';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

const initialState = () => ({
    formData: {
        type: null,
        toSend: [],
    }
})

class PartialContacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleAddOrRemove = handleAddOrRemove.bind(this);
    }

    selectAll = () => {
        const { contacts } = this.props;
        const { formData } = this.state;
        this.setState({
            formData: {
                ...formData,
                toSend: (formData.type ?
                    _.filter(contacts,  i => {
                        switch (formData.type) {
                            case '0%':
                                return i.form.results.length === 0;
                            case '25%':
                                return i.form.results.length > 0 && parseFloat(_.filter(i.form.results, r => r.f_result_value !== '' && r.f_result_value !== null ).length / this.props.survey.form.elements_count ) < 0.7;
                            case '75%':
                                return i.form.results.length > 0 && parseFloat(_.filter(i.form.results, r => r.f_result_value !== '' && r.f_result_value !== null ).length / this.props.survey.form.elements_count ) > 0.7;
                        }
                    } ) :
                    contacts).map(i => i.sc_id)
            }
        })
    }

    sendMulti = () => {
        this.#send(this.state.formData.toSend);
    }

    sendSingle = (contact) => () => {
        this.#send([contact]);
    }

    #send = (contacts) => {
        API.post(`/survey/${this.props.survey.survey_id}/send`, { contacts: JSON.stringify(contacts) }).then(res => {
            this.setState({ toSend: [] }, this.props.refresh);
        })
    }

    removeInvite = (contact) => {
        this.props.deployConfirmation(
            'Are you sure you want to remove this survey invite for ' + contact.contact.contact_name + '?',
            'Remove Survey',
            ()=>API.post(`/survey/${this.props.survey.survey_id}/${contact.sc_id}/remove`).then(res => {
                this.props.refresh();
            })
        )
    }

    render() {

        const { contacts } = this.props;
        const { formData } = this.state;

        return (
            <Grid container spacing={2}>
                <InfoBox
                    title='Contacts'
                    icon={IconHelper.contacts}
                    value={_.uniqBy(contacts, 'sc_contact_id').length}
                    rowSize={5}
                />
                <InfoBox
                    title='Survey Invites'
                    icon={IconHelper.survey}
                    value={contacts.length}
                    onClick={()=>this.setState({formData: {...formData, type: null}})}
                    rowSize={5}
                    color={!formData.type && colors.primary}
                    border={!formData.type && `1px solid ${colors.primary}`}
                />
                <InfoBox
                    title='No Progress'
                    icon={'circle'}
                    value={_.filter(contacts,  i => i.form.results.length > 0 && parseFloat(_.filter(i.form.results, r => r.f_result_value !== '' && r.f_result_value !== null ).length / this.props.survey.form.elements_count ) === 0 ).length}
                    onClick={()=>this.setState({formData: {...formData, type: '0%'}})}
                    rowSize={5}
                    color={formData.type === '0%' && colors.red}
                    border={formData.type === '0%' && `1px solid ${colors.red}`}
                />
                <InfoBox
                    title='Partial Progress'
                    icon={'circle-half-stroke'}
                    iconType={'solid'}
                    value={_.filter(contacts,  i => i.form.results.length > 0 && parseFloat(_.filter(i.form.results, r => r.f_result_value !== '' && r.f_result_value !== null ).length / this.props.survey.form.elements_count ) < 0.7  && parseFloat(_.filter(i.form.results, r => r.f_result_value !== '' && r.f_result_value !== null ).length / this.props.survey.form.elements_count ) > 0 ).length}
                    onClick={()=>this.setState({formData: {...formData, type: '25%'}})}
                    rowSize={5}
                    color={formData.type === '25%' && colors.orange}
                    border={formData.type === '25%' && `1px solid ${colors.orange}`}
                />
                <InfoBox
                    title='Nearly Complete'
                    icon={'circle'}
                    iconType={'solid'}
                    value={_.filter(contacts,  i => i.form.results.length > 0 && parseFloat(_.filter(i.form.results, r => r.f_result_value !== '' && r.f_result_value !== null ).length / this.props.survey.form.elements_count ) > 0.7 ).length}
                    onClick={()=>this.setState({formData: {...formData, type: '75%'}})}
                    rowSize={5}
                    color={formData.type === '75%' && colors.green}
                    border={formData.type === '75%' && `1px solid ${colors.green}`}
                />
                {contacts.length > 0 &&
                    <Grid item xs={12} className='buttonRow'>
                        {formData.toSend.length > 0 ?
                            <Button variant='contained' color='primary' onClick={
                                ()=>this.props.deployConfirmation(
                                    'Are you sure you want to send a reminder to ' + formData.toSend.length + ' contacts?',
                                    'Send Reminder',
                                    this.sendMulti
                                )}>Send Reminder</Button> :
                            <Button variant='outlined' onClick={this.selectAll}>Select All</Button>
                        }
                    </Grid>
                }
                <Grid item xs={12}>
                    <CiDataTable
                         config={{
                            key: 'sc_id',
                            dropRow:{
                                droppable: true,
                                columns: [
                                    {
                                        colSpan: 9,
                                        field: i => 
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{fontWeight: 'bold'}}>
                                                            Email
                                                        </TableCell>
                                                        <TableCell style={{width: '1%', fontWeight: 'bold'}}>
                                                            Sent
                                                        </TableCell>
                                                        <TableCell style={{width: '1%', fontWeight: 'bold', textAlign:'center'}}>
                                                            Read
                                                        </TableCell>
                                                        <TableCell style={{width: '1%', fontWeight: 'bold', textAlign:'center'}}>
                                                            Status
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {i.emails.map(e => 
                                                    <TableRow key={e.email_id}>
                                                        <TableCell>
                                                            {e.email_to}
                                                        </TableCell>
                                                        <TableCell style={{width: '1%'}}>
                                                            {clenyDate(e.email_datetime_added)}
                                                        </TableCell>
                                                        <TableCell style={{width: '1%', textAlign:'center'}}>
                                                            {e.email_datetime_opened !== '0000-00-00 00:00:00' ? clenyDate(e.email_datetime_opened) : '-'}
                                                        </TableCell>
                                                        <TableCell style={{width: '1%', textAlign:'center'}}>
                                                            {e.email_status}
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </Table>
                                    }
                                ]
                            }
                        }}
                        rows={
                            formData.type ?
                                _.filter(contacts,  i => {
                                    switch (formData.type) {
                                        case '0%':
                                            return i.form.results.length === 0;
                                        case '25%':
                                            return i.form.results.length > 0 && parseFloat(_.filter(i.form.results, r => r.f_result_value !== '' && r.f_result_value !== null ).length / this.props.survey.form.elements_count ) < 0.5;
                                        case '75%':
                                            return i.form.results.length > 0 && parseFloat(_.filter(i.form.results, r => r.f_result_value !== '' && r.f_result_value !== null ).length / this.props.survey.form.elements_count ) > 0.5;
                                    }
                                } ) :
                                contacts
                        }
                        columns={[
                            {
                                heading: 'Name',
                                field: 'contact.contact_name',
                                dataRef: 'contact.contact_name',
                                sizeToContent: true
                            },
                            {
                                heading: 'Email',
                                field: 'contact.contact_email',
                                dataRef: 'contact.contact_email',
                            },
                            {
                                actionsCustomHeader:    true,
                                important:              true,
                                alignment:              'left',
                                heading:                'Customer',
                                actions: i => ([
                                    { label: `${i.contact.customer.cust_name}`,         link: ()=>{window.open(`/customers/view/${i.contact.customer.cust_id}`)},         type:'plainLink'},
                                ]),
                                dataRef: 'name',
                                truncate: true
                            },
                            this.props.survey.form.linked_parts.length > 0 &&
                            {
                                actionsCustomHeader:    true,
                                important:              true,
                                alignment:              'left',
                                heading:                'Order',
                                actions: i => ([
                                    { label: `${i.invoice.order.order_reference}-${i.invoice.order.order_reference_amendment}`,         link: ()=>{window.open(`/sales/order/view/${i.invoice.invoice_order_id}`)},         type:'plainLink'},
                                    ...(i?.invoice?.order?.credit_notes?.length > 0 ? i.invoice.order.credit_notes.map(creditNote => ({ label: `${creditNote.cn_reference}-0${creditNote.cn_reference_amendment}`, link: ()=>{window.open(`/returns/creditnote/${creditNote.cn_id}`)}, type:'plainLink', color: colors.red})) : [])
                                ])
                            },
                            {
                                heading: 'Emails Sent',
                                field: 'emails',
                                dataRef: 'emails',
                                fieldFormat: 'count',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'Created Date',
                                field: 'form.form_created_datetime',
                                fieldFormat: 'clenydate',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'Last Viewed',
                                field: 'form.form_last_ping_datetime',
                                dataRef: 'form.form_last_ping_datetime',
                                fieldFormat: 'clenydate',
                            },
                            {
                                heading: 'progress',
                                alignment: 'center',
                                field: i =>{
                                    let progress = parseFloat(_.filter(i.form.results, r => r.f_result_value !== '' && r.f_result_value !== null ).length / this.props.survey.form.elements_count) * 100;
                                    let icon = 'circle';
                                    let iconType = 'default';
                                    let info = 'No Progress';
                                    if(progress > 0){
                                        iconType = 'solid';
                                        info = 'Nearly Complete';
                                        if (progress < 70){
                                            icon = 'circle-half-stroke';
                                            info = 'Partial Progress';
                                        }
                                    }
                                    return (
                                        <AllIcon
                                            icon={icon}
                                            heavy={iconType === 'solid'}
                                            size='small'
                                            tooltip={info}
                                            noMargin
                                        />
                                    )

                                },
                                dataRef: i => _.filter(i.form.results, r => r.f_result_value !== '' && r.f_result_value !== null ).length / this.props.survey.form.elements_count,
                            },
                            {
                                actions: i => ([
                                    {
                                        name: 'Sticky Notes',
                                        icon: IconHelper.stickyNote,
                                        onClick: this.props.showStickyNotes,
                                        color: i.sticky_notes_count > 0 ? colors.yellow : 'inherit'
                                    },
                                    {
                                        name: 'View Survey',
                                        icon: IconHelper.survey,
                                        linkExternal: i.form.adminExternalLink
                                    },
                                    {
                                        name: 'View Answers',
                                        icon: 'clipboard-list',
                                        onClick: ()=>this.props.deployDialog(
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} style={{marginTop: 16}}>
                                                    <FormView formId={this.props.survey.survey_form_id} displayForm={i.form.form_id} />
                                                </Grid>
                                                <Grid item xs={12} className='buttonRow'>
                                                    <Button variant='outlined' onClick={()=>this.props.closeDialog()}>Close</Button>
                                                </Grid>
                                            </Grid>,
                                            this.props.uuidReplace(i.form.form_uuid)
                                        )
                                    },
                                    {
                                        name: 'Remove',
                                        icon: IconHelper.delete,
                                        onClick: this.removeInvite
                                    },
                                    {
                                        name: 'Send a Reminder',
                                        icon: IconHelper.refresh,
                                        onClick: ()=>this.props.deployConfirmation(
                                            'Are you sure you want to send a reminder to ' + i.contact.contact_name + '?',
                                            'Send a Reminder',
                                            this.sendSingle(i.sc_id),
                                        ),
                                        // disabled: i.contact.contact_email_opt_out
                                    },
                                    {
                                        type: 'checkBox',
                                        onCheck: () => this.handleAddOrRemove('toSend', i.sc_id),
                                        checked: formData.toSend.includes(i.sc_id),
                                        // disabled: i.contact.contact_email_opt_out
                                    },
                                    {
                                        name: 'View Contact',
                                        icon: IconHelper.view,
                                        link: `/customers/contacts/view/${i.contact.contact_id}`,
                                    }
                                ])
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback, variant=null) => {
            dispatch(deployConfirmation(content, title, callback, null, variant))
        },
        deployDialog: (content, title, size='lg') => {
            dispatch(deployDialog(content, title, 'system', size))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        }
    }
}

export default connect(null, mapDispatchToProps)(PartialContacts);