import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import API from '../../../API';
import _ from 'lodash';
import { connect } from 'react-redux';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { ContactsOutlined } from '@material-ui/icons';

const initialState = {
    initialFormData: {},
    allAppearOnLists: {},
    staffAppearOnLists: {},
    confirmationOpen: false,
    snackbarOpen: false
}

function objectsHaveSameKeys(...objects) {
    const allKeys = objects.reduce((keys, object) => keys.concat(Object.keys(object)), []);
    const union = new Set(allKeys);
    return objects.every(object => union.size === Object.keys(object).length);
}

class UpdateStaffLists extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount() {
        this.getComponentData();
    }
    getComponentData = () => {
        Promise.all([ 
            API.get(`/misc/appearOnLists/all`),
            API.get(`/staff/${this.props.staffId}/appearOnLists`),
        ])
        .then(([typesRes, notifRes]) =>  {
            this.setState({
                allAppearOnLists: typesRes.data,
            });
            _.map(notifRes.data, staffNotif => {
                this.setState({
                    staffAppearOnLists: {
                        ...this.state.staffAppearOnLists,
                        [staffNotif.aol_id]: true
                    },
                    initialFormData: {
                        ...this.state.initialFormData,
                        [staffNotif.aol_id]: true
                    }
                })
            })
        });
    }

    handleSnackbarClose = () => this.setState({ snackbarOpen: false });
    handleConfirmationClose = () => this.setState({ confirmationOpen: false });
    handleConfirmationOpen = () => this.setState({ confirmationOpen: true });
    handleConfirmationSuccess = () => this.setState({  confirmationOpen: false }, () => this.handleSubmit());
    handleSubmit = () => {
        let notificationTypes = Object.keys(this.state.staffAppearOnLists);
        API.put(`/staff/${this.props.staffId}/appearOnLists`, notificationTypes)
        .then(result => {
            if(result.data){
                this.setState({
                    ...initialState
                }, ()=> {
                    this.props.deploySnackBar("success", "The staff member's appear on lists were successfully updated");
                    this.getComponentData();
                    this.props.scrollToTop();
                });
            }
        });
    }

    toggleNotification = (event) => {
        let notificationTypeId = event.target.name;
        if(this.state.staffAppearOnLists.hasOwnProperty(notificationTypeId)) {
            this.setState({
                staffAppearOnLists: _.omit(this.state.staffAppearOnLists, notificationTypeId)
            })
        } else {
            this.setState({
                staffAppearOnLists: {
                    ...this.state.staffAppearOnLists,
                    [notificationTypeId]: true
                }
            });
        }
    };

    render() {
        const { allAppearOnLists, staffAppearOnLists, initialFormData } = this.state;
    
        return(
            <React.Fragment>
                <FormControl component="fieldset">
                    <FormGroup>
                        {_.map(allAppearOnLists, aol => {
                            return (
                                <FormControlLabel
                                    key={aol.aol_id}
                                    control={
                                        <Checkbox 
                                            name={aol.aol_id.toString()}
                                            onClick={this.toggleNotification}
                                            checked={staffAppearOnLists.hasOwnProperty(aol.aol_id)}
                                            color="primary"
                                        />
                                    }
                                    label={aol.aol_name}
                                    labelPlacement="end"
                                />
                            )
                        })}
                    </FormGroup>
                </FormControl>
                <div className="buttonRow">
                    <Button 
                        onClick={this.handleConfirmationOpen}
                        variant="contained"
                        color="primary"
                        disabled={objectsHaveSameKeys(initialFormData, staffAppearOnLists)}
                        style={{float: 'right'}}
                    >
                        Update
                    </Button>
                </div>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Update Staff Member appearance on lists?" 
                    message="Are you sure you want to update this staff member's appearance on lists?"
                />
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, msg) => {
            dispatch(deploySnackBar(variant, msg))
        }
    }
}

export default connect(null, mapDispatchToProps)(UpdateStaffLists);