import React, {Component} from 'react';
import API                from 'API';
import _                  from 'lodash';
import moment             from 'moment';
import { connect }        from 'react-redux';

import { Typography, Grid } from '@material-ui/core';

import { colors }                 from 'Helpers/ColourHelper';
import icons                      from 'Helpers/IconHelper';
import { colorNumbers, currency } from 'Helpers/NumberHelper';

import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import DataTable          from 'Components/Common/DataTables/CiDataTable';
import SelectPartWithQR   from 'Components/Common/Selects/SelectPartWithQR';


import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';

import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

const initialState = () => ({
    part: '',
    partList: [],
    searchResults: [],
    currentStock:  null,
    page: 0,
    rowsPerPage: 10,
    isLoading: false,
    partObject: {},
});

const InfoBlock = ({title, icon, value, size, iconColor
}) => {
    return (
        <Grid item style={{width: size}}>
            <PaddedPaper  style={{textAlign: 'center', height: '100%'}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}><Typography variant="h6" style={{textAlign: 'center'}}>{title}</Typography></Grid>
                    <Grid item xs={12}><AllIcon icon={icon} color={iconColor} noMargin size='large' /></Grid>
                    <Grid item xs={12}><Typography variant="h6"  style={{textAlign: 'center'}}>{value}</Typography></Grid>
                </Grid>
            </PaddedPaper>
        </Grid>
    )
} 

class StockActivity extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = this.props.partId ? `stock:Activity:${this.props.partId}` : 'stock:Activity';
        this.state              = this.getInitialState(initialState());
        if (this.props.partId)  this.state.part = this.props.partId;
    }

    componentDidMount(){
        !this.hasPageState() && this.getParts();
        (this.hasPageState() || this.props.partId) && this.getSearchData(this.state.part);
    }

    getParts = () => {
        API.get('/parts/withStockActivity')
        .then((partRes) =>  {
            this.setState({
                partList: _.map(partRes.data, el => {
                    return _.assign({
                        value: el.part_id,
                        label: el.part_number + ' - ' + el.part_description,
                        part:  el
                    });
                })
            }, ()=>{
                if (this?.props?.match?.params?.pid) {
                    this.handleSelectChange('part')({value: this.props.match.params.pid});
                }
                this.savePageState()
            });
        });
    }

    getSearchData = (partId) => {        
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([
                API.get(`/stock/${partId}/activity`),
                API.get(`/stock/${partId}`),
                API.get(`/reports/stocksReport/deliveries`, {params: {partId: partId, to: '9999-12-31', from: '1970-01-01',type: 'all'}}),
                API.get('/reports/stocksReport/shortages', {params: {partId: partId}}),
                API.get('/worksOrders/forPart',{params: {part: partId}}),
                API.get(`/parts/${partId}`),
                API.get('/worksOrders/partUsage',{params: {part: partId}}),
            ])
            .then(([result, currentStock, deliveries, shortages, worksOrders, parts, usage]) => {
                _.map(result.data, el => {
                    // Symbol
                    let symbol = '';
                    let stock_change = '';
                    if(el.stock_activity_type === 'Opening Stock'){
                        symbol = 'Open';
                        stock_change = el.stock_activity_increased_qty;
                    } 
                    else if(el.stock_activity_type === 'Min / Max Levels Changed') {
                        symbol = 'Min / Max';
                        stock_change = '-';
                    } 
                    else if(el.stock_activity_increased_qty > 0) {
                        symbol = "increased";
                        stock_change = el.stock_activity_increased_qty;
                    } 
                    else if(el.stock_activity_decreased_qty > 0) {
                        symbol = "decreased";
                        stock_change = el.stock_activity_decreased_qty;
                    }
                    else {
                        stock_change = el.stock_activity_decreased_qty;
                    }
                    el.symbol = symbol;
                    el.stockChange = stock_change;
                });
                let part = parts.data;
                let isSubAssembly = part.part_design_file === 'Yes' && !part.part_outsourced_subassembly;
                this.setState({
                    ...this.state,
                    searchResults: result.data,
                    currentStock: currentStock.data,
                    deliveries: (_.first(deliveries.data) || !isSubAssembly) && {
                        due:          parseFloat(_.first(deliveries.data)?.a || 0),
                        dueCost:      parseFloat(_.first(deliveries.data)?.s || 0),
                        expected:     parseFloat(_.first(deliveries.data)?.u || 0),
                        expectedCost: parseFloat(_.first(deliveries.data)?.g || 0),
                        received:     parseFloat(_.first(deliveries.data)?.o || 0),
                        receivedCost: parseFloat(_.first(deliveries.data)?.d || 0),
                        pos:          _.first(deliveries.data)?.v.length || 0,
                    },
                    worksOrders: usage.data,
                    shortages: {
                        qty: parseFloat(_.find(shortages.data, {q: partId})?.u || 0) ,
                        wo:  _.find(shortages.data, {q: partId})?.y.length || 0
                    },
                    isLoading: false,
                    partObject: part,
                }, ()=>this.savePageState())
            })
        })
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        }, 
        () => {
            if(fieldName === 'part' && selectedOption){
                this.savePageState();
                this.getSearchData(selectedOption && selectedOption.value);
            }
        });
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const { searchResults, isLoading, currentStock, deliveries, shortages, worksOrders, part, partObject } = this.state;

        return (
            <Grid container spacing={2}>
                {this.props.partId ? <></>:<>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Stock Activity
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Typography variant="h6" gutterBottom>
                                    Select Part
                                </Typography>
                                <SelectPartWithQR
                                    parts=      {this.state.partList}
                                    onChange=   {this.handleSelectChange('part')}
                                    value=      {parseInt(this.state.part)}
                                    margin=     "none"
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                </>}
                {((searchResults && searchResults.length > 0) || isLoading) &&
                    <React.Fragment>
                        {currentStock && <>
                            {partObject.part_status === 'Active' ? <>
                                <Grid item xs={4} lg={this.props.partId ? 4 : 2}>
                                    <PaddedPaper style={{textAlign:'center', paddingBottom: '1.5em', paddingTop: '1.5em'}}>
                                        <Typography style={{textAlign:'center'}} variant="h6" align="right"> Stock Status </Typography>
                                        <AllIcon 
                                            icon={currentStock.stock_stock_status === 0 ? icons.true : icons.false}
                                            color={currentStock.stock_stock_status === 0 ? colors.green : colors.red}
                                            tooltip={currentStock.stock_stock_status === 0 ? 'Stock is Correct' : (currentStock.stock_stock_status < 0 ? 'Stock is below what it should be' : 'Stock is above what it should be')}
                                            size='xlarge'
                                            noMargin
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={4} lg={this.props.partId ? 4 : 2}>
                                    <PaddedPaper style={{textAlign:'center', paddingBottom: '1.5em', paddingTop: '1.5em'}}>
                                        <Typography style={{textAlign:'center'}} variant="h6" align="right"> Pending Status </Typography>
                                        <AllIcon 
                                            icon={currentStock.stock_pending_status === 0 ? icons.true : icons.false}
                                            color={currentStock.stock_pending_status === 0 ? colors.green : colors.red}
                                            tooltip={currentStock.stock_pending_status === 0 ? 'Pending stock is Correct' : (currentStock.stock_pending_status < 0 ? 'Pending stock is below what it should be' : 'Pending stock is above what it should be')}
                                            size='xlarge'
                                            noMargin
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={4} lg={this.props.partId ? 4 : 2}>
                                    <PaddedPaper style={{textAlign:'center', paddingBottom: '1.5em', paddingTop: '1.5em'}}>
                                        <Typography style={{textAlign:'center'}} variant="h6" align="right"> Available Status </Typography>
                                        <AllIcon 
                                            icon={currentStock.stock_available_status === 0 ? icons.true : icons.false}
                                            color={currentStock.stock_available_status === 0 ? colors.green : colors.red}
                                            tooltip={currentStock.stock_available_status === 0 ? 'Available stock is Correct' : (currentStock.stock_available_status < 0 ? 'Available stock is below what it should be' : 'Available stock is above what it should be')}
                                            size='xlarge'
                                            noMargin
                                        />
                                    </PaddedPaper>
                                </Grid>
                            </> : 
                                <>
                                    <Grid item xs={12} >
                                        <PaddedPaper
                                            style={{padding: 15}}
                                        >
                                            <Typography
                                                variant="h6"
                                                style={{fontWeight: 'bold'}}
                                            >Please Note: This Part Is {partObject.part_status}</Typography>
                                        </PaddedPaper>
                                    </Grid>
                                    {parseFloat(currentStock.stock_current_qty) > 0 && 
                                        <Grid item xs={12} >
                                            <PaddedPaper
                                                style={{backgroundColor: colors.red, color: colors.white, padding: 15}}
                                            >
                                                <Typography
                                                    variant="body1"
                                                >
                                                    <AllIcon icon={icons.warning} white/>Non-active stock should have a stock holding of 0.000, please contact your admin</Typography>
                                            </PaddedPaper>
                                        </Grid>
                                    }
                                </>
                            }

                                <InfoBlock
                                    title={<>Total<br/>Stock</>}
                                    icon={icons.stock}
                                    value={<>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(currentStock.stock_current_qty ?? 0).toFixed(3))}<br/>
                                        <AllIcon icon={icons.pound}/>{currency(parseFloat(currentStock.stock_current_qty_cost))}
                                    </>}
                                    size={`${100/6}%`}
                                />
                                <InfoBlock
                                    title={<>Pending<br/>Incoming</>}
                                    icon={icons.plus}
                                    value={<>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(currentStock.stock_pending_incoming ?? 0).toFixed(3))}<br/>
                                        <AllIcon icon={icons.pound}/>{currency(parseFloat(currentStock.stock_pending_incoming_cost))}
                                    </>}
                                    size={`${100/6}%`}
                                />
                                <InfoBlock
                                    title={<>Pending<br/>Outgoing</>}
                                    icon={icons.minus}
                                    value={<>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(currentStock.stock_pending_outgoing ?? 0).toFixed(3))}<br/>
                                        <AllIcon icon={icons.pound}/>{currency(parseFloat(currentStock.stock_pending_outgoing_cost))}
                                    </>}
                                    size={`${100/6}%`}
                                />
                                <InfoBlock
                                    title={<>Total<br/>Pending</>}
                                    icon={icons.stockPending}
                                    value={<>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(currentStock.stock_pending ?? 0).toFixed(3))}<br/>
                                        <AllIcon icon={icons.pound}/>{currency(parseFloat(currentStock.stock_pending_cost))}
                                    </>}
                                    size={`${100/6}%`}
                                />
                                <InfoBlock
                                    title={<>Total<br/>Predicted</>}
                                    icon={icons.stockPending}
                                    value={<>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(currentStock.stock_predicted ?? 0).toFixed(3))}<br/>
                                        <AllIcon icon={icons.pound}/>{currency(parseFloat(currentStock.stock_predicted_cost))}
                                    </>}
                                    size={`${100/6}%`}
                                />
                                <InfoBlock
                                    title={<>Total<br/>Available</>}
                                    icon={icons.sales}
                                    value={<>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(currentStock.stock_available ?? 0).toFixed(3))}<br/>
                                        <AllIcon icon={icons.pound}/>{currency(parseFloat(currentStock.stock_available_cost))}
                                    </>}
                                    size={`${100/6}%`}
                                />
                                <InfoBlock
                                    title={<>Stock<br/>Level</>}
                                    icon={
                                        currentStock.stock_level == 0 ? icons.stockLevelCorrect :
                                            ( currentStock.stock_level == -1 ? icons.stockLevelLow : icons.stockLevelHigh)
                                    }
                                    value={currentStock.stock_level == 0 ? 
                                        <Typography variant="h4" style={{textAlign:'center', color: colors.green}}>Correct</Typography> : 
                                        (currentStock.stock_level == -1 ? 
                                            <Typography variant="h4" style={{textAlign:'center', color: colors.red}}>Under</Typography>:
                                            <Typography variant="h4" style={{textAlign:'center', color: colors.orange}}>Over</Typography>
                                        )
                                    }
                                    size={`${100/7}%`}
                                />
                                <InfoBlock
                                    title={<>Min/Max<br/><br/></>}
                                    icon={icons.stockLevel}
                                    value={<Typography variant="h4" >{parseFloat(currentStock.stock_minimum_level)}/{parseFloat(currentStock.stock_maximum_level)}</Typography>}
                                    size={`${100/7}%`}
                                />
                                <InfoBlock
                                    title={<>Stock<br/>Threshold</>}
                                    icon={null}
                                    value={<AllIcon icon={`TextToIcon:${currentStock.part.part_stock_threshold}%`} size='50px' style={{fontWeight: 'normal', color: 'rgba(0,0,0,0.7)'}} />}
                                    size={`${100/7}%`}
                                />
                                {!!deliveries && <>
                                    <InfoBlock
                                        title={<>PO <br/> Scheduled</>}
                                        icon={icons.calendarExclamation}
                                        value={<>
                                            <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(deliveries.due).toFixed(3))}<br/>
                                            <AllIcon icon={icons.truck}/>{parseFloat(deliveries.pos)}<br/>
                                            <AllIcon icon={icons.pound}/>{currency(parseFloat(deliveries.dueCost))}
                                        </>}
                                        size={`${100/7}%`}
                                    />
                                    <InfoBlock
                                        title={<>PO <br/> Outstanding</>}
                                        icon={icons.truck}
                                        value={<>
                                            <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(deliveries.expected).toFixed(3))}<br/>
                                            <AllIcon icon={icons.truck}/>{parseFloat(deliveries.pos)}<br/>
                                            <AllIcon icon={icons.pound}/>{currency(parseFloat(deliveries.expectedCost))}
                                        </>}
                                        size={`${100/7}%`}
                                    />
                                    <InfoBlock
                                        title={<>PO <br/> Received</>}
                                        icon={icons.truck}
                                        value={<>
                                            <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(deliveries.received).toFixed(3))}<br/>
                                            <AllIcon icon={icons.truck}/>{parseFloat(deliveries.pos)}<br/>
                                            <AllIcon icon={icons.pound}/>{currency(parseFloat(deliveries.receivedCost))}
                                        </>}
                                        size={`${100/7}%`}
                                    />
                                </>}
                                {!!worksOrders && <>
                                    <InfoBlock
                                        title={<>WO <br/> Picking</>}
                                        icon={icons.qr}
                                        value={<>
                                            <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(worksOrders.w.q).toFixed(3))}<br/>
                                            <AllIcon icon={icons.worksOrder}/>{(parseFloat(worksOrders.w.w))}<br/>
                                            <AllIcon icon={icons.pound}/>{currency(parseFloat(worksOrders.w.c))}
                                        </>}
                                        size={`${100/7}%`}
                                    />
                                    <InfoBlock
                                        title={<>Works Order<br/>Shortages</>}
                                        icon={icons.worksOrder}
                                        value={<>
                                            <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(worksOrders.e.q).toFixed(3))}<br/>
                                            <AllIcon icon={icons.worksOrder}/>{parseFloat(worksOrders.e.w)}<br/>
                                            <AllIcon icon={icons.pound}/>{currency(parseFloat(worksOrders.e.c))}
                                        </>}
                                        size={`${100/7}%`}
                                    />
                                    <InfoBlock
                                        title={<>WO <br/> Build</>}
                                        icon={icons.worksOrder}
                                        value={<>
                                            <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(worksOrders.r.q).toFixed(3))}<br/>
                                            <AllIcon icon={icons.worksOrder}/>{(parseFloat(worksOrders.r.w))}<br/>
                                            <AllIcon icon={icons.pound}/>{currency(parseFloat(worksOrders.r.c))}
                                        </>}
                                        size={`${100/7}%`}
                                    />
                                    <InfoBlock
                                        title={<>WO <br/> Review</>}
                                        icon={icons.review}
                                        value={<>
                                            <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(worksOrders.t.q).toFixed(3))}<br/>
                                            <AllIcon icon={icons.worksOrder}/>{(parseFloat(worksOrders.t.w))}<br/>
                                            <AllIcon icon={icons.pound}/>{currency(parseFloat(worksOrders.t.c))}
                                        </>}
                                        size={`${100/7}%`}
                                    />
                                </>}
                                
                        </>}
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <DataTable
                                    config={{
                                        key:                    'stock_activity_id',
                                        pagination:             true,
                                        alternatingRowColours:  true,
                                        responsiveImportance:   true,
                                        isLoading:              isLoading,
                                        pagination:             true,
                                        persistenceId:          this.persistenceId,
                                    }}
                                    columns={[
                                        {
                                            heading: 'In / Out',
                                            field: rowData =>
                                                rowData.symbol === 'increased'  ? <AllIcon icon='plus' style={{color:colors.green}} />
                                                                                : ((rowData.symbol === 'decreased') ? <AllIcon icon='minus' style={{color:colors.red}} />
                                                                                                                    : rowData.symbol),
                                            important: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Type',
                                            field: rowData => rowData.stock_activity_type,
                                            style: rowData => {return{color: rowData.symbol === 'decreased' && colors.red, paddingRight: 20}},
                                            important: true,
                                            main: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: '',
                                            field: rowData => rowData.stock_activity_description,
                                            style: rowData => {return{color: rowData.symbol === 'decreased' && colors.red}},
                                            important: true,
                                            main: true,
                                            sizeToContent: true
                                            
                                        },
                                        {
                                            heading: 'Previous Stock Levels',
                                            field: rowData => rowData.stock_activity_previous_qty,
                                            style: rowData => {return{color: rowData.symbol === 'decreased' && colors.red, textAlign: 'right'}},
                                            alignment: 'right',
                                        },
                                        
                                        {
                                            heading: 'Stock Change',
                                            field: rowData => rowData.stockChange == '-' ? '0.000' : ( rowData.symbol === 'decreased' ? (0 - rowData.stockChange) : rowData.stockChange) ,
                                            alignment: 'center',
                                            fieldFormat: 'colorNumbers:decimal:3',
                                        },
                                        {
                                            heading: 'New Stock Levels',
                                            field: rowData => rowData.stock_activity_new_qty,
                                            style: rowData => {return{color: rowData.symbol === 'decreased' && colors.red, textAlign: 'right'}},
                                            alignment: 'right',
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Date',
                                            field: rowData => moment(rowData.stock_activity_date).format("DD/MM/YYYY"),
                                            style: rowData => {return{color: rowData.symbol === 'decreased' && colors.red, textAlign: 'center'}},
                                            important: true,
                                            alignment: 'center',
                                        },
                                        {
                                            actions: rowData => {
                                                let pathname = null;
                                                switch(rowData.stock_activity_type){
                                                    case 'Stock Addition': 
                                                        pathname = `/stock/additions/view/${rowData.stock_activity_reference_id}`; 
                                                    break;
                                                    case 'Stock Delivered - Parts Order':
                                                        pathname = `/parts-order/view/${rowData.stock_activity_reference_id}`;
                                                    break;
                                                    case 'Sales - Invoiced':
                                                    case 'Sales - Stock Sold':
                                                        pathname = `/sales/order/view/${rowData.stock_activity_reference_id}`;
                                                    break;
                                                    case 'Sales - Stock Returned':
                                                    case 'Credit Note':
                                                        pathname = `/returns/creditnote/${rowData.stock_activity_reference_id}`;
                                                    break;
                                                    case 'Stock Write Off':
                                                        pathname = `/stock/write-off/view/${rowData.stock_activity_reference_id}`;
                                                    break;
                                                    case 'Supplier Stock Return':
                                                    case 'Stock Delivered - Supplier Stock Return':
                                                        pathname = `/supplier-stock-return/view/${rowData.stock_activity_reference_id}`;
                                                    break;
                                                    case 'Stock Movement - Write Off':
                                                    case 'Stock Movement - Addition':
                                                        pathname = `/stock/movement/${rowData.stock_activity_reference_id}`;
                                                    break;
                                                    case 'Stock Correction - Write Off':
                                                    case 'Stock Correction - Addition':
                                                        pathname = `/stock/correction/${rowData.stock_activity_reference_id}`;
                                                    break;
                                                    case 'Stock Take - Write Off':
                                                    case 'Stock Take - Addition':
                                                        pathname = `/stock/take/view/${rowData.stock_activity_reference_id}`;
                                                    break;
                                                    case 'Works Order - Write Off':
                                                        pathname = `/parts/writeOffs/view/${rowData.stock_activity_reference_id}`;
                                                    break;
                                                    case 'Works Order - Component':
                                                    case 'Works Order - Built':
                                                    case 'Works Order - Addition':
                                                    case 'Works Order - Return':
                                                        pathname = `/WorksOrders/view/${rowData.stock_activity_reference_id}`;
                                                    break;
                                                }
                                                return (pathname) ?  [
                                                    {name: 'View', icon: 'search', link: {pathname}},  
                                                ] : [];
                                            },
                                            alignment: 'right',
                                        }
                                    ]}
                                    rows={searchResults}
                                />
                            </PaddedPaper>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(StockActivity);
