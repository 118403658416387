import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { Button, Grid, InputAdornment, Typography, Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';

import { deployDialog }                     from 'Actions/Dialog/Dialog';
import { deploySnackBar }                   from 'Actions/SnackBar/SnackBar';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';
import { deployConfirmation }               from 'Actions/Confirmation/Confirmation';

import DataTable          from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import ImageWithError     from 'Components/Common/ImageWithError/ImageWithError';
import Textarea           from 'Components/Common/Inputs/Textarea';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import SelectPartWithQR   from 'Components/Common/Selects/SelectPartWithQR';
import BackButton         from 'Components/Common/Buttons/BackButton';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import EditPartDialog         from './EditPartDialog';
import WarrantyDialog         from './WarrantyDialog';
import LinkedPartsDialog      from './LinkedPartsDialog';
import OrderPartDetailsButton from './OrderPartDetailsButton';

import icons from 'Helpers/IconHelper';

const initialState = () => ({
    isLoading: true,
    parts: [],
    warrantyTerms: [],
    vatRate: 0.20,
    formData:  {
        discountType: 0,
        name:         '',
        description:  '',

        partRows: [],

        subTotal: 0,
        totalDiscount: 0,
        totalVat: 0,
        total: 0,
    },
    editPartRow: {
        id:              null,
        warrantyTerm:    null,
        notes:           '',

        qty:             '1.00',

        unitPrice:       '0.00',
        newUnitPrice:    '0.00',
        subTotal:        '0.00',

        discountRate:    '0.00',
        unitDiscount:    '0.00',
        totalDiscount:   '0.00',

        total:           '0.00',
    },
    partFormData: {
        id:              null,
        warrantyTerm:    null,
        notes:           '',

        qty:             '1.00',

        unitPrice:       '0.00',
        newUnitPrice:    '0.00',
        subTotal:        '0.00',

        discountRate:    '0.00',
        unitDiscount:    '0.00',
        totalDiscount:   '0.00',

        total:           '0.00',
    },
    showWarrantyDialog: false,
    updatePackage: false,
    linkedPartsFormData: [],
    linkedPartsDialogOpen: false
});

class AddPackages extends Component {
    constructor(props) {
        super(props);    
        this.state = initialState();
    }

    componentDidMount = () => {
        this.getLists();
        if (this.props.match.params.packageId ){
            this.setState({updatePackage: this.props.match.params.packageId},this.getPackage)
        } 
    }

    getPackage = () => {
        API.get(`/sales/packages/${this.state.updatePackage}`)
        .then(res => {
            let _package = res.data;
            this.setState({formData: {
                name:        _package.p_name,
                description: _package.p_desc,
                discountType: _package.p_discounted,
                partRows:    _.map(_package.details, i => ({
                    id:              i.pd_part_id,
                    partNumber:      i.part.part_number,
                    warrantyTerm:    i.pd_warranty_id,
                    notes:           i.pd_notes,
                    qty:             parseFloat(i.pd_qty),
                    unitPrice:       parseFloat(i.pd_unit_price),
                    newUnitPrice:    parseFloat(i.pd_new_unit_price).toFixed(2),
                    subTotal:        parseFloat(i.pd_subtotal).toFixed(2),
                    discountRate:    parseFloat(i.pd_discount),
                    unitDiscount:    parseFloat(i.pd_unit_discount).toFixed(2),
                    totalDiscount:   parseFloat(i.pd_discount_total).toFixed(2),
                    total:           parseFloat(i.pd_total).toFixed(2),
                })),
            }}, this.calculateTotals);
        });
    }

    getLists = () => {
        Promise.all([
            API.get('/parts/all' , {params: {active: true, hasPrice: true, use: 'quotation'}}),
            API.get('/parts/warrantyTerms'),
            API.get('/accounts/vatRates/byType')
        ]).then(([parts, warranty, vat]) => {
            this.setState({
                parts: _.map(parts.data, i => ({
                    part: i,
                    value: i.part_id,
                    label: i.part_number + ' - ' + i.part_description,
                })),
                warrantyTerms: _.map(warranty.data, el => ({
                    value: el.pwt_id,
                    label: el.pwt_duration !== null ? el.pwt_name + ' (' + el.pwt_duration + ' months)' : el.pwt_name,
                    name: el.pwt_name,
                    duration: el.pwt_duration
                })),
                vatRate: parseFloat(_.find(_.find(vat.data, {vat_type_name: 'UK Standard'}).vat_values, {current: true}).vat_value_percentage) / 100,
                isLoading: false,
            });
        });
    }

    handleChange = formData => name => event => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                [name]: event.target.value,
            }
        }, () => {
            if (formData === 'partFormData') {
                this.calculatePartData();
            }
        });
    }

    handleSelectChange = formData => name => event => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                [name]: event.value,
            }
        }, () => {
            if (name === 'id' && formData === 'partFormData') {
                const part = _.find(this.state.parts, {value: this.state.partFormData.id}).part;
                this.setState({
                    partFormData: {
                        ...this.state.partFormData,
                        unitPrice: parseFloat(part.price).toFixed(2),
                        warrantyTerm: part.pwt_id,
                    }
                }, this.calculatePartData);
            }
            if (formData === 'formData' && name === 'discountType' && !parseInt(event.value)) {
                this.removeDiscount();
            }
        });
    }

    removeDiscount = () => {
        const {formData} = this.state;
        formData.partRows = _.map(formData.partRows, i => ({
            ...i,
            discountRate: 0,
            unitDiscount: 0,
            newUnitPrice: i.unitPrice,
            totalDiscount: 0,
            total: i.subTotal,
        }));
        this.setState({formData}, this.calculateTotals);
    }

    calculatePartData = () => {

        let unitPrice = parseFloat(this.state.partFormData.unitPrice);
        let qty = parseFloat(this.state.partFormData.qty);
        let discountRate = parseFloat(this.state.partFormData.discountRate) / 100;

        let subTotal = parseFloat(unitPrice * qty) || 0;

        let unitDiscount = parseFloat(unitPrice * discountRate) || 0;
        let totalDiscount = parseFloat(unitDiscount * qty) || 0;

        let newUnitPrice = parseFloat(unitPrice - unitDiscount);

        let total = parseFloat(newUnitPrice * qty);

        this.setState({
            partFormData: {
                ...this.state.partFormData,
                subTotal:        parseFloat(subTotal).toFixed(2),
                unitDiscount:    parseFloat(unitDiscount).toFixed(2),
                totalDiscount:   parseFloat(totalDiscount).toFixed(2),
                newUnitPrice:    parseFloat(newUnitPrice).toFixed(2),
                total:           parseFloat(total).toFixed(2)
            }
        });
    }

    handleDP = formData => name => () => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                [name]: parseFloat(this.state.partFormData[name]).toFixed(2),
            }
        });
    }

    displayLinkedParts = partRow => {
        let linkedParts = _.map(_.find(this.state.parts, {value: partRow.id}).part.linked_parts, i => {
            let quantity     = parseFloat(i.quantity) * parseFloat(partRow.qty);
            let discountRate = partRow.discountRate;
            return {
                ..._.find(this.state.parts, {value: i.part_id}).part,
                include: true,
                quantity, 
                discountRate
            }
        });

        this.setState({
            linkedPartsFormData:   _.orderBy(linkedParts, ['part_number'], ['asc']),
            linkedPartsDialogOpen: true
        })
        
    }

    handleAddPart = () => { 
        let part = _.find(this.state.parts, {value: this.state.partFormData.id}).part;
        if (part.linked_parts.length > 0) {
            this.displayLinkedParts(this.state.partFormData);
        }
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: [
                    ...this.state.formData.partRows,
                    {
                        ...this.state.partFormData,
                        partNumber: _.find(this.state.parts, {value: this.state.partFormData.id})?.part?.part_number
                    }
                ]
            },
            partFormData: initialState().partFormData,
            showWarrantyDialog: false,
        }, this.calculateTotals);
    }

    calculateTotals = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                subTotal:      parseFloat(_.sumBy(this.state.formData.partRows, i => parseFloat(i.total))).toFixed(2),
                totalDiscount: parseFloat(_.sumBy(this.state.formData.partRows, i => parseFloat(i.totalDiscount))).toFixed(2),
                totalVat:      parseFloat(parseFloat(_.sumBy(this.state.formData.partRows, i => parseFloat(i.total))) * this.state.vatRate).toFixed(2),
                total:         parseFloat(parseFloat(_.sumBy(this.state.formData.partRows, i => parseFloat(i.total))) + parseFloat(parseFloat(_.sumBy(this.state.formData.partRows, i => parseFloat(i.total))) * this.state.vatRate)).toFixed(2)
            }
        })
    }


    updatePartRow = e => {
        const index = e.rowIdx;
        const partRows = this.state.formData.partRows;
        partRows[index] = e;
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: partRows
            },
            updatePartRow: initialState().updatePartRow,
        }, this.calculateTotals);
    }

    handleEdit = e => {
        this.props.deployDialog(
            <EditPartDialog
                partFormData={{
                    ...e,
                    qty:             parseFloat(e.qty).toFixed(2),
                    unitPrice:       parseFloat(e.unitPrice).toFixed(2),
                    newUnitPrice:    parseFloat(e.newUnitPrice).toFixed(2),
                    subTotal:        parseFloat(e.subTotal).toFixed(2),
                    discountRate:    parseFloat(e.discountRate).toFixed(2),
                    unitDiscount:    parseFloat(e.unitDiscount).toFixed(2),
                    totalDiscount:   parseFloat(e.totalDiscount).toFixed(2),
                    total:           parseFloat(e.total).toFixed(2),
                }}
                part={_.find(this.state.parts, {value: e.id}).part}
                updatePart={this.updatePartRow}
                warrantyTerms={this.state.warrantyTerms}
                discountType={this.state.formData.discountType}
            />, 'Edit Part', 'lg'
        )
    }

    handleSubmit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            if (key === 'partRows') {
                newFormData.append(key, JSON.stringify(this.state.formData[key]));
            } else {
                newFormData.append(key, this.state.formData[key]);
            }
        });

        API.post(this.state.updatePackage ? `/sales/packages/${this.state.updatePackage}`: '/sales/packages', newFormData)
        .then(res => {
            this.props.history.push('/sales/package');
        })
    }

    deleteRow = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: _.filter(this.state.formData.partRows, (i, index) => index !== e.rowIdx)
            }
        }, this.calculateTotals);
    }

    setLinkedPartsFormData = (linkedPartsFormData) => {
        this.setState({linkedPartsFormData})
    }

    handleLinkedPartsDialogClose = () => {
        this.setState({linkedPartsDialogOpen: false})
    }

    addLinkedParts = () => {
        let { partRows } = this.state.formData;
        _.each(_.filter(this.state.linkedPartsFormData, i => i.include && parseFloat(i.quantity) > 0), i => {

            let price        = parseFloat(i.price)        > 0 ? parseFloat(i.price)        : 0;
            let discountRate = parseFloat(i.discountRate) > 0 ? parseFloat(i.discountRate) : 0;
            let quantity     = parseFloat(i.quantity)     > 0 ? parseFloat(i.quantity)     : 0;

            let warranty = _.find(this.state.warrantyTerms, {value: i.part_warranty_terms})

            partRows.push({
                id:              i.part_id,
                partNumber:      i.part_number,
                warrantyTerm:    warranty.value,
                notes:           i.notes,

                qty:             parseFloat(quantity).toFixed(2),

                unitPrice:       parseFloat(price),
                newUnitPrice:    parseFloat(price) - (parseFloat(price) * (parseFloat(discountRate) / 100)),
                subTotal:        parseFloat(price) * quantity,

                discountRate:    discountRate,
                unitDiscount:    parseFloat(price) * (parseFloat(discountRate) / 100),
                totalDiscount:   (parseFloat(price) * (parseFloat(discountRate) / 100)) * quantity,

                total:            (parseFloat(price) - (parseFloat(price) * (parseFloat(discountRate) / 100))) * quantity,
            });
        });

        this.setState({
            formData:{...this.state.formData, partRows}, 
            linkedPartsDialogOpen: false, 
            linkedPartsFormData: initialState.linkedPartsFormData
        }, this.calculateTotals);
    }

    moveDown = (e) => {
        let index = e.rowIdx;
        let { partRows } = this.state.formData;
        let temp = partRows[index];
        partRows[index] = partRows[index + 1];
        partRows[index + 1] = temp;
        this.setState({formData: {...this.state.formData, partRows}});
    }

    moveUp = (e) => {
        let index = e.rowIdx;
        let { partRows } = this.state.formData;
        let temp = partRows[index];
        partRows[index] = partRows[index - 1];
        partRows[index - 1] = temp;
        this.setState({formData: {...this.state.formData, partRows}});
    }

    updateRows = partRows => {
        this.setState({formData:{...this.state.formData, partRows}})
    }

    render() {

        const { isLoading, parts, warrantyTerms, formData, partFormData, showWarrantyDialog, updatePackage } = this.state;

        if ( isLoading ) return <LoadingCircle/>;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {updatePackage ? 'Update' : 'Add'} Sales Package
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <PaddedPaper>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {updatePackage ? 
                                    <TextField
                                        label="Discount Type"
                                        name="discountType"
                                        value={formData.discountType ? 'With Discount' : 'Without Discount'}

                                        fullWidth
                                    /> :
                                    <AutoCompleteSelect
                                        options={[
                                            {value: 0, label: 'Without Discount'},
                                            {value: 1, label: 'With Discount'},
                                        ]}
                                        label="Discount Type *"
                                        name="discountType"
                                        value={formData.discountType}
                                        onChange={this.handleSelectChange('formData')('discountType')}
                                        fullWidth
                                        noClear
                                    />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={formData.name}
                                    onChange={this.handleChange('formData')('name')}
                                    fullWidth
                                    label="Package Name *"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Textarea
                                    value={formData.description}
                                    onChange={this.handleChange('formData')('description')}
                                    fullWidth
                                    rows={4}
                                    label="Package Description/Notes"
                                />
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Typography variant="h6">
                            Add Part
                        </Typography>
                        <Grid container spacing={1} style={{alignItems: 'start', marginTop: 16}}> 
                            <Grid item style={{width: 66 }}>
                                <ImageWithError
                                    src={_.find(parts, {value: partFormData.id})?.part?.thumbnail_url}
                                    style={{maxHeight:48, maxWidth:48}}
                                />
                            </Grid>
                            <Grid item xs={formData.discountType ? 2 : 4}>
                                <SelectPartWithQR
                                    parts=    {parts}
                                    value=    {partFormData.id}
                                    onChange= {this.handleSelectChange('partFormData')('id')}
                                    label=    "Select Part *"
                                    noClear
                                    disableQr
                                />
                            </Grid>
                            <Grid item style={{ width: formData.discountType ? 185 : 275 }}>
                                <TextField
                                    name="qty"
                                    label="Quantity"
                                    value={partFormData.qty}
                                    onChange= {this.handleChange('partFormData')('qty')}
                                    onBlur= {this.handleDP('partFormData')('qty')}
                                    type="number"
                                    fullWidth
                                    disabled={!partFormData.id}
                                />
                            </Grid>
                            <Grid item style={{ width: formData.discountType ? 185 : 275 }}>
                                <TextField
                                    name="unitPrice"
                                    label="Unit Price"
                                    value={partFormData.unitPrice}
                                    onChange= {this.handleChange('partFormData')('unitPrice')}
                                    onBlur= {this.handleDP('partFormData')('unitPrice')}
                                    type="number"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                    disabled={!partFormData.id}
                                />
                            </Grid>
                            {!!formData.discountType &&
                                <>
                                    <Grid item style={{width: 185}}>
                                        <TextField
                                            name="discountRate"
                                            label="Discount Rate"
                                            value={partFormData.discountRate}
                                            onChange= {this.handleChange('partFormData')('discountRate')}
                                            onBlur= {this.handleDP('partFormData')('discountRate')}
                                            type="number"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                                                inputProps: {
                                                    min: 0
                                                }
                                            }}
                                            disabled={!partFormData.id}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TextField
                                            name="unitDiscount"
                                            label="Unit Discount"
                                            value={partFormData.unitDiscount || null}
                                            type="number"
                                            fullWidth
                                            disabled
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                                inputProps: {
                                                    min: 0
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TextField
                                            name="newUnitPrice"
                                            label="New Unit Price"
                                            value={partFormData.newUnitPrice || null}
                                            type="number"
                                            fullWidth
                                            disabled
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                                inputProps: {
                                                    min: 0
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TextField
                                            name="totalDiscount"
                                            label="Discount Total"
                                            value={partFormData.totalDiscount || null}
                                            type="number"
                                            fullWidth
                                            disabled
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                                inputProps: {
                                                    min: 0
                                                }
                                            }}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={formData.discountType ? 1 : 2}>
                                <TextField
                                    name="total"
                                    label="Total Price"
                                    value={partFormData.total || null}
                                    type="number"
                                    fullWidth
                                    disabled
                                    InputProps={{
                                        startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    onClick={()=>this.setState({showWarrantyDialog: true})}
                                    disabled={!partFormData.id || parseFloat(partFormData.qty) <= 0 || parseFloat(partFormData.unitPrice) < 0}
                                    fullWidth
                                    style={{marginTop: 12}}
                                >Add Part</Button>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                {formData.partRows.length > 0 && <>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <DataTable
                                config={{}}
                                columns={_.filter([
                                    {
                                        heading: '',
                                        field: i => _.find(parts, {value: i.id})?.part?.thumbnail_url,
                                        fieldFormat: 'image',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Part',
                                        field: i => _.find(parts, {value: i.id})?.label,
                                    },
                                    {
                                        heading: 'Quantity',
                                        field: i=> parseFloat(i.qty),
                                        sizeToContent: true,
                                        alignment: 'right'
                                    },
                                    {
                                        heading: 'Unit Price',
                                        field: 'unitPrice',
                                        fieldFormat: 'currency',
                                        sizeToContent: true,
                                        alignment: 'right'
                                    },
                                    ...(!!formData.discountType ? [
                                    {
                                        heading:     'Discount Rate',
                                        field:       'discountRate',
                                        fieldFormat: 'decimal:2',
                                        sizeToContent: true,
                                        fieldSuffix: '%',
                                        alignment: 'right'
                                    },
                                    {
                                        heading:     'New Unit Price',
                                        field:       'newUnitPrice',
                                        fieldFormat: 'currency',
                                        sizeToContent: true,
                                        alignment: 'right'
                                    },
                                    {
                                        heading:     'Discount Total',
                                        field:       'totalDiscount',
                                        fieldFormat: 'currency',
                                        sizeToContent: true,
                                        alignment: 'right'
                                    }] : []),
                                    {
                                        heading:       'Total',
                                        field:         'total',
                                        fieldFormat:   'currency',
                                        sizeToContent: true,
                                        alignment: 'right'
                                    },
                                    {
                                        actions: row => ([
                                            {
                                                name:    'Remove',
                                                icon:    icons.delete,
                                                onClick: this.deleteRow
                                            },
                                            {
                                                name: 'Edit',
                                                icon: icons.edit,
                                                onClick: this.handleEdit
                                            },
                                            {
                                                name: 'Move Up',
                                                icon: icons.arrowUp,
                                                onClick: this.moveUp,
                                                disabled: parseFloat(formData.partRows.length) === 1 || parseFloat(row.rowIdx) === 0
                                            },
                                            {
                                                name: 'Move Down',
                                                icon: icons.arrowDown,
                                                onClick: this.moveDown,
                                                disabled: parseFloat(formData.partRows.length) === 1 || parseFloat(row.rowIdx) === parseFloat( formData.partRows.length - 1 )
                                            }
                                        ])
                                    }
                                ], i => i)}
                                rows={formData.partRows}
                            />
                            <div className='buttonRow'>
                                <OrderPartDetailsButton   partRows={formData.partRows}  callback={this.updateRows}/>
                            </div>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={6}>
                        <PaddedPaper>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="right">
                                            <Typography variant="subtitle1">
                                                Sub Total
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                name="subTotal"
                                                value={formData.subTotal}
                                                fullWidth
                                                disabled
                                                InputProps={{
                                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell colSpan="2"></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="right">
                                            <Typography variant="subtitle1">
                                                VAT ({ parseFloat(formData.totalVat) > 0 ? Math.ceil((formData.totalVat / formData.subTotal ) * 100).toFixed(2) : '0.00'}%)
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                name="totalVat"
                                                value={formData.totalVat}
                                                fullWidth
                                                disabled
                                                InputProps={{
                                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell colSpan="2"></TableCell>
                                    </TableRow>
                                    <TableRow style={{backgroundColor:'#f2f2f2'}}>
                                        <TableCell align="right">
                                            <Typography variant="subtitle1">
                                                Total
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                name="overallPrice"
                                                value={formData.total}
                                                fullWidth
                                                disabled
                                                InputProps={{
                                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell colSpan="2"></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </PaddedPaper>
                    </Grid> 
                    <Grid item xs={6}></Grid>
                </>}
                <Grid item xs={6}>
                    <PaddedPaper>
                        <div className='buttonRow'>
                            <BackButton/>
                            {updatePackage ? 
                                <Button 
                                    variant='contained' 
                                    color='primary' 
                                    disabled={!(formData.partRows.length > 0) || !formData.name || formData.discountType === null}
                                    onClick={()=>this.props.deployConfirmation(
                                        'Are you sure you want to update this Package?',
                                        'Update Package',
                                        this.handleSubmit
                                    )}
                                >Update</Button> :
                                <Button 
                                    variant='contained' 
                                    color='primary' 
                                    disabled={!(formData.partRows.length > 0) || !formData.name || formData.discountType === null}
                                    onClick={()=>this.props.deployConfirmation(
                                        'Are you sure you want to create this Package?',
                                        'Create Package',
                                        this.handleSubmit
                                    )}
                                >Save</Button>
                            }
                        </div>
                    </PaddedPaper>
                </Grid>
                <WarrantyDialog 
                    open={showWarrantyDialog}
                    handleClose={()=>this.setState({showWarrantyDialog: false})}
                    onSubmit={this.handleAddPart}
                    handleChange={this.handleChange('partFormData')}
                    handleSelectChange={this.handleSelectChange('partFormData')}
                    partFormData={partFormData}
                    warrantyTerms={warrantyTerms}
                />
                <LinkedPartsDialog
                    open={this.state.linkedPartsDialogOpen}
                    formData={this.state.linkedPartsFormData}
                    setFormData={this.setLinkedPartsFormData}
                    close={this.handleLinkedPartsDialogClose}
                    submit={this.addLinkedParts}
                    warrantyTerms={warrantyTerms}
                    allowDiscount={formData.discountType}
                />
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                                   => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                            => dispatch(setPersistence(key, state)),
    deploySnackBar:     (variant, msg)                          => dispatch(deploySnackBar(variant, msg)),
    deployDialog:       (content, title, variant, size = 'lg')  => dispatch(deployDialog(content, title, variant, size)),
    deployConfirmation: (message, title, success)               => dispatch(deployConfirmation(message, title, success)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddPackages);
