import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import API from '../../../API';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import moment from 'moment';
import {statusColour} from './../../../Helpers/ColourHelper'
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SnackBar from '../../Common/SnackBars/SnackBar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import { connect } from 'react-redux';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import Textarea from '../../Common/Inputs/Textarea';
import DataTable from '../../Common/DataTables/CiDataTable';
import Link from '@material-ui/core/Link';
import FASolidIcon from '../../Common/Icons/FontAwesome/FASolidIcon';
import {colors} from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    formData: {
        ppo_id: '',
        notes: ''
    },
    disableSubmit: false,
    outstandingInvoices: false,
    keywords: '',
    ppo: {},
    searchResults: {},
    approveDialogOpen: false,
    approveSnackbarOpen: false,
    snackbarErrorMsg: '',
    isLoading: true
});

class PartsOrderInvoice extends Component {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'partsOrdering:PartsOrderInvoice';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.getSearchData();
    }

    getSearchData = () => {
        const params = {
            keywords: this.state.keywords,
            status: 'Invoice'
        }
        this.setState({
            isLoading: true
        }, () => {
            API.get('/partsOrdering', {
                params,
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    _.map(result.data, (ppo) => {
                        ppo.colour = statusColour(ppo.ppo_status);
                    });
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    })
                }
            })
        })
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
        }, 
        () => {
            this.getSearchData();
        });
    }

    handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleApproveOpen = rowData => {
        API.get(`/partsOrdering/${rowData.ppo_id}/outstandingDeliveryInvoices`)
        .then(result => {
            let outstandingInvoices = false;
            let string;

            if(result.data.length > 0){
                outstandingInvoices = true;
                
                let rows = [];
                result.data.forEach((val, idx) => {
                    let row =   (<TableRow key={idx}>
                                    <TableCell>Delivery Date: <b>{moment(val.ppo_file_delivery_date).format("DD/MM/YYYY")}</b></TableCell>
                                    <TableCell style={{color:'#999'}}><FALightIcon style={{color:'#999'}} icon='exclamation-triangle' noMargin /> Outstanding Invoice</TableCell>
                                </TableRow>)
                    rows.push(row);
                });
                string = <Table><TableBody>{rows}</TableBody></Table>;
            }

            this.setState({
                formData: {
                    ...this.state.formData,
                    ppo_id: rowData.ppo_id
                },
                approveDialogOpen: true,
                outstandingInvoices: outstandingInvoices,
                invoiceTable: string,
                disableSubmit: outstandingInvoices
            });
        });
        
    };
    handleApproveClose = () => {this.setState({approveDialogOpen: false});};
    handleApproveSnackbarClose = () => {this.setState({ approveSnackbarOpen: false }); };
    handleApproveSuccess = () => {
        this.setState({ approveDialogOpen: false });
        this.approveSubmit();
    }
    approveSubmit = () => {
        API.post(`/partsOrdering/${this.state.formData.ppo_id}/confirmInvoice`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    approveDialogOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    approveSnackbarOpen: true
                });
                
                this.getSearchData();
            }
        });
    }

    downloadFile = type => rowData => {
        API.get('/partsOrdering/' + rowData.ppo_id + '/' + type)
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Parts Order Invoice
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={this.state.keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button 
                                        onClick={this.resetSearch}
                                        variant="outlined"
                                        color="default"
                                    >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>

                        <DataTable  
                            config={{
                                key: 'ppo_id',
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: this.state.isLoading
                            }}
                            columns={[
                                {
                                    heading: 'Parts Order Date',
                                    field: rowData => moment(rowData.ppo_date).format("DD/MM/YYYY"),
                                    sizeToContent: true
                                },
                                {
                                    actionsCustomHeader:    true,
                                    sizeToContent:          true,
                                    alignment:              'left',
                                    heading:                'Parts Order Number',
                                    actions: rowData => {
                                        return [
                                            {label: rowData.ppo_number + '-' + rowData.ppo_number_amendment, link: `/parts-order/view/${rowData.ppo_id}`, type:'plainLink',}
                                        ]
                                    }
                                },
                                {
                                    heading: 'Company Name',
                                    field: rowData => rowData.ppo_supplier_name
                                },
                                {
                                    heading: 'Outstanding Invoices',
                                    alignment: 'center',
                                    field: rowData => (
                                        <React.Fragment>
                                            {rowData.receivedDeliveries === 0 ?
                                                <FALightIcon 
                                                    icon='truck'
                                                    noMargin 
                                                />
                                            :
                                                <React.Fragment>
                                                    {rowData.outstandingInvoices && rowData.outstandingInvoices.length > 0 ?
                                                        <Tooltip 
                                                            title={
                                                                <React.Fragment>
                                                                    <Typography variant="body1"><strong>{rowData.outstandingInvoices.length} Outstanding Delivery Invoices</strong></Typography>
                                                                    {(_.map(rowData.outstandingInvoices, (inv,idx) => {
                                                                        return (<Typography key={idx} variant="body2">{ moment(inv.ppo_file_delivery_date).format("DD/MM/YYYY") }</Typography>)
                                                                    }))}
                                                                </React.Fragment>
                                                            } 
                                                            placement="right"
                                                        >
                                                            <Link href={`/parts-order/view/${rowData.ppo_id}`}>
                                                                <FASolidIcon 
                                                                    icon='file-alt'
                                                                    button 
                                                                    noMargin 
                                                                    style={{color: colors.red}} 
                                                                />
                                                            </Link>
                                                        </Tooltip>
                                                    :
                                                        <FASolidIcon 
                                                            icon='file-alt'
                                                            noMargin 
                                                            style={{color: colors.green}} 
                                                        />
                                                    }
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    )
                                },
                                {
                                    heading: 'Placed By',
                                    field: rowData => rowData.ppo_placed_by,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Total Price',
                                    field: rowData => rowData.ppo_total_price,
                                    fieldPrefix: '£',
                                    alignment: 'right',
                                    sizeToContent: true
                                },
                                {
                                    actions: rowData => {
                                        let ret = [
                                            {name: 'Download', icon: 'download', onClick: this.downloadFile('pdfPartsOrder')},
                                            {name: 'Download Delivery Schedule', icon: 'calendar-alt', onClick: this.downloadFile('pdfDeliverySchedule')},
                                        ]
                                        ret.push( rowData.locked ?
                                            {name: 'Locked', icon: icons.lock, onClick:()=>{}}:
                                            {name: 'Confirm Invoice', icon: 'check', onClick: this.handleApproveOpen}
                                        )
                                        return ret;
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>

                <Dialog 
                    open={this.state.approveDialogOpen} 
                    onClose={this.handleApproveClose} 
                    fullWidth={true} 
                    maxWidth="md"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title">Confirm Invoice</DialogTitle>
                    <DialogContent>
                        <Stepper activeStep={3} alternativeLabel >
                            <Step><StepLabel>Approval</StepLabel></Step>
                            <Step><StepLabel>Confirmation</StepLabel></Step>
                            <Step><StepLabel>Delivery</StepLabel></Step>
                            <Step><StepLabel>Invoice</StepLabel></Step>
                            <Step><StepLabel>Complete</StepLabel></Step>
                        </Stepper>
                        {this.state.outstandingInvoices ? 
                            <React.Fragment>
                                <small style={{color:colors.red}}>There are still outstanding delivery invoices for this Parts Order. Please upload these first before trying to confirm. See below for a list of outstanding invoices:</small>
                                <br></br><br></br>
                                {this.state.invoiceTable}
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <small>When you are ready to confirm the invoice you have the option to add some notes, then click Confirm Invoice.</small>
                                <Textarea
                                    id="notes"
                                    name="notes"
                                    label="Notes"
                                    value={this.state.formData.notes}
                                    rows={5}
                                    onChange={this.handleChange}
                                />
                            </React.Fragment>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleApproveClose} variant="outlined" color="default">Close</Button>
                        <Button onClick={this.handleApproveSuccess} color="primary" autoFocus variant="contained" disabled={this.state.disableSubmit}>Confirm Invoice</Button>
                    </DialogActions>
                </Dialog>
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.approveSnackbarOpen}
                    onClose={this.handleApproveSnackbarClose}
                    message='You have successfully confirmed the invoice for this parts order'
                />
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence:   state.statePersistence,
    loggedInStaff:      state.staffAuth.staff
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(PartsOrderInvoice);