import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import React from 'react';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import { Grid, Button, TextField } from '@material-ui/core';
import IconHelper from 'Helpers/IconHelper';
import { connect } from 'react-redux';
import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';
import NewEmailCampaignDialog from 'Components/Marketing/EmailCampaigns/NewEmailCampaign/NewEmailCampaignDialog';
import { colors } from 'Helpers/ColourHelper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect'
import _ from 'lodash';
import AllIcon from 'Components/Common/Icons/AllIcon';

const initialState = () => ({
    isLoading: true,
    campaigns: [],
    searchFormData: {
        keyword: null,
        status: 'All',
    }
})

class EmailCampaigns extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getCampaigns();
        //this.handleNewCampaign();
    }

    getCampaigns = () => {
        API.get('/marketing/campaigns/emailCampaign', {params: this.state.searchFormData})
        .then(res => {
            this.setState({
                campaigns: res.data,
                isLoading: false
            })
        })
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            searchFormData: {
                ...this.state.searchFormData,
                [name]: value
            }
        }, this.getCampaigns)
    }

    handleSelectChange = ({value}) => {
        this.setState(
            {
                searchFormData: {
                    ...this.state.searchFormData,
                    status: value
                }
            }, this.getCampaigns
        )
    }

    handleNewCampaign = () => {
        this.props.deployDialog(
            <NewEmailCampaignDialog
                onClose={this.closeAddCampaign}
            />,
            'Create New Email Campaign',
            'fs'
        )
    }

    closeAddCampaign = () => {
        this.props.closeDialog();
        this.getCampaigns();
    }

    copyCampaign = (campaign) => {
        this.props.deployDialog(
            <NewEmailCampaignDialog
                onClose={this.closeAddCampaign}
                copy={campaign.ec_id}
                ecId={campaign.ec_id}
            />,
            'Create New Email Campaign',
            'fs'
        )
    }

    editCampaign = (campaign) => {
        this.props.deployDialog(
            <NewEmailCampaignDialog
                onClose={this.closeAddCampaign}
                ecId={campaign.ec_id}
            />,
            'Amend Email Campaign',
            'fs',
            'system'
        )
    }

    render() {
        const { isLoading, campaigns, searchFormData } = this.state;

        if (isLoading) {
            return (
                <LoadingCircle/>
            )
        }
        

        return (
            <Grid container spacing={1}>
                <Grid item style={{maxWidth: 500, width: '100%'}}>
                    <TextField
                        placeholder='Keyword Search...'
                        name='keyword'
                        value={searchFormData.keyword}
                        onChange={this.handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item style={{maxWidth: 255, width: '100%'}}>
                    <AutoCompleteSelect
                        placeholder='Status'
                        options={[
                            {
                                label: 'All',
                                value: 'All'
                            },
                            {
                                label: 'Active',
                                value: 'Active'
                            },
                            {
                                label: 'Ended',
                                value: 'Ended'
                            }
                        ]}
                        noClear
                        fullWidth
                        name='status'
                        value={searchFormData.status}
                        onChange={this.handleSelectChange}
                    />
                </Grid>
                <Grid item
                    style={{
                        marginLeft: 'auto',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleNewCampaign}
                    >Create New Email Campaign</Button>
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        config={{
                            key: 'i',
                            pagination: true,
                        }}
                        rows={_.orderBy(campaigns, ['ec_id'], ['desc'])}
                        columns={[
                            {
                                heading: 'Date added',
                                field: i => i.activity_logs[0]?.ecal_datetime,
                                dataRef: i => i.activity_logs[0]?.ecal_datetime,
                                fieldFormat: 'clenydate',
                                sizeToContent: true,
                            },
                            {
                                heading: 'Reference',
                                field: i => `${i.ec_reference}-${i.ec_reference_amendment > 9 ? i.ec_reference_amendment : `0${i.ec_reference_amendment}` }`,
                                dataRef: 'ec_reference',
                                sizeToContent: true,
                            },
                            {
                                heading: 'Name',
                                field: 'ec_name',
                                dataRef: 'ec_name',
                                truncate: true,
                            },
                            {
                                heading: 'Type',
                                field: i => (
                                    (i.ec_sending_options === 'Manual' && 'Manual') ||
                                    (
                                        (i.ec_sending_options === 'Scheduled (Repeating)' && 'Scheduled') ||
                                        (i.ec_sending_options === 'Scheduled (Fixed)' && 'Fixed')
                                    )
                                ),
                                dataRef: 'ec_sending_options',
                                sizeToContent: true,
                                truncate: true,
                            },
                            {
                                heading: 'contacts',
                                field: 'ec_valid_contacts',
                                dataRef: 'ec_valid_contacts',
                                sizeToContent: true,
                                alignment: 'center',
                            },
                            {
                                heading: 'last run',
                                field: i => _.last(i.runs)?.ecr_datetime || '-',
                                dataRef: i => _.last(i.runs)?.ecr_datetime,
                                fieldFormat: 'clenydate',
                                sizeToContent: true,
                                alignment: 'center',
                            },
                            {
                                heading: 'next run',
                                field: i => _.first(i.upcoming_scheduled)?.ecse_send_date || '-',
                                dataRef: i => _.first(i.upcoming_scheduled)?.ecse_send_date || '-',
                                fieldFormat: 'clenydate',
                                sizeToContent: true,
                                alignment: 'center',
                            },
                            {
                                heading: 'runs',
                                field: i => `${i.runs.length} / ${i.runs.length + i.upcoming_scheduled.length}`,
                                dataRef: i => `${i.runs.length} / ${i.runs.length + i.upcoming_scheduled.length}`,
                                sizeToContent: true,
                                alignment: 'center',
                            },
                            {
                                heading: 'run status',
                                field: i => <AllIcon
                                    icon={IconHelper.emailCampaigns}
                                    color={
                                        (i.runs.length === 0 && colors.red) ||
                                        (i.runs.length < (i.runs.length + i.upcoming_scheduled.length) && colors.orange) ||
                                        colors.green
                                    }
                                    tooltip={
                                        (i.runs.length === 0 && 'Not Sent') ||
                                        (i.runs.length < (i.runs.length + i.upcoming_scheduled.length) && 'Sending') ||
                                        'Complete'
                                    }
                                    noMargin
                                />,
                                dataRef: i => i.runs.length / (i.runs.length + i.upcoming_scheduled.length),
                                sizeToContent: true,
                                alignment: 'center',
                                truncate: true,
                            },
                            {
                                heading: 'campaign status',
                                truncate: true,
                                field: i =>
                                    <AllIcon
                                        icon={
                                            (i.ec_status === 'Active' && IconHelper.true) ||
                                            (i.ec_status === 'Ended' && IconHelper.false) ||
                                            (IconHelper.dash)
                                        }
                                        color={
                                            (i.ec_status === 'Active' && colors.green) ||
                                            (i.ec_status === 'Ended' && colors.red) ||
                                            (colors.disabled)
                                        }
                                        tooltip={i.ec_status}
                                        noMargin
                                    />,
                                dataRef: 'ec_status',
                                sizeToContent: true,
                                alignment: 'center',
                            },
                            
                            {
                                actions: c => ([
                                    {
                                        name: 'Edit',
                                        icon: IconHelper.edit,
                                        onClick: this.editCampaign,
                                        disabled: c.ec_status === 'Ended'|| c.runs.length > 0
                                    },
                                    {
                                        name: 'Copy',
                                        icon: IconHelper.copy,
                                        onClick: this.copyCampaign,
                                        disabled: c.ec_status === 'Active'
                                    },
                                    {
                                        name: 'View',
                                        icon: IconHelper.search,
                                        link: `/marketing/campaigns/emailCampaigns/${c.ec_id}`
                                    }
                                ])
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        )

    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, size) => {
            dispatch(deployDialog(content, title, 'system', size))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        }
    }
}

export default connect(null, mapDispatchToProps)(EmailCampaigns);