import React  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
    Button,
    Grid,
    TextField,
    Typography,
    DialogActions,
    Link,
    RadioGroup,
    Radio,
    FormControlLabel,
    InputAdornment,
    TableCell,
    TableRow,
    Table,
    TableBody
} from '@material-ui/core';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { handleDateChange, getFormData, handleSelectChange, clearMultiFiles, handleChange, handleMultiFileChange, clearValue, handleFileChange } from 'Functions/FormFunctions';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { toggleDialog } from 'Functions/MiscFunctions';
import DragFileInputMulti from 'Components/Common/Inputs/DragFileInputMulti';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import Textarea from 'Components/Common/Inputs/Textarea';
import { downloadS3File } from 'Functions/MiscFunctions';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

const initialState = () => ({
    incidentId: 0,
    incident: {},
    formData: {
        dateTime: null,
        driverStaffId: 0,
        street: '',
        town: '',
        postcode: '',
        what3Words: '',
        incidentType: 'Accident',
        incidentFiles: '',
        // Accident
        accFaultOption: '',
        accInsuranceClaimed: '',
        accInsuranceUsed: '',
        accDescription: '',
        // Penalty Charge Notice
        pcnNumber: '',
        pcnContraventionCode: '',
        pcnAmountPayable: 0,
        pcnAdminFee: 0,
        pcnSettled: 0,
        pcnDateSettled: null,
        pcnReceipt: ''
    },
    formErrors: [],
    isLoading: true,
    staffList: [],
    readOnly: false
});

class VehicleIncidentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
        this.getFormData = getFormData.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleMultiFileChange = handleMultiFileChange.bind(this);
        this.clearMultiFiles = clearMultiFiles.bind(this);
        this.clearValue = clearValue.bind(this);
        this.handleFileChange = handleFileChange.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData(); 
    }

    loadComponentData = () => {

        const { incidentId, readOnly } = this.props;

        this.setState({
            ...this.state,
            incidentId,
            readOnly
        },
        () => {
            if (this.state.incidentId) {
                this.getIncident();
            }
            else {
                this.getStaffList();
            }
        });
    }

    getIncident = () => {
        this.setState({
            isLoading: true,
        }, 
        () => {
            API.get(`/vehicles/${this.props.vehicleId}/incidents/${this.state.incidentId}`)
            .then(res => {
    
                let inc = res.data;
    
                this.setState({
                    ...this.state,
                    incident: inc,
                    formData: {
                        ...this.state.formData,
                        dateTime: inc?.dateTime,
                        driverStaffId: inc?.driverStaffId,
                        street: inc?.street,
                        town: inc?.town,
                        postcode: inc?.postcode,
                        what3Words: inc?.what3Words,
                        incidentType: inc?.incidentType,
                        // Accident
                        accFaultOption: inc?.accFaultOption,
                        accInsuranceClaimed: inc?.accInsuranceClaimed,
                        accInsuranceUsed: inc?.accInsuranceUsed,
                        accDescription: inc?.accDescription,
                        // Penalty Charge Notice
                        pcnNumber: inc?.pcnNumber,
                        pcnContraventionCode: inc?.pcnContraventionCode,
                        pcnAmountPayable: inc?.pcnAmountPayable,
                        pcnAdminFee: inc?.pcnAdminFee,
                        pcnSettled: inc?.pcnSettled,
                        pcnDateSettled: inc?.pcnDateSettled != '0000-00-00' ? inc?.pcnDateSettled : null,
                    }
                }, 
                () => {
                    this.getStaffList(this.state.formData.driverStaffId);
                });
            });
        });
    }

    getStaffList = (staffId) => {
        API.get('/staff/all', { 
            params: { 
                active: true,
                currentStaffId: staffId
            } 
        })
        .then(result => {
            if(result.data) {

                let staffList = _.map(result.data, (staff) => {
                    return _.assign({
                        value: staff.staff_id,
                        label: staff.staff_first_name + ' ' + staff.staff_last_name
                    });
                });

                this.setState({
                    ...this.state,
                    staffList,
                    isLoading: false,
                });
            }
        });
    }

    handleSubmit = () => {
        const { incidentId } = this.state;
        const route = incidentId ? `/vehicles/${this.props.vehicleId}/incidents/${this.state.incidentId}`: `/vehicles/${this.props.vehicleId}/incidents`;

        this.setState({
            isLoading: true,
        }, 
        () => {
            API.post(route, this.getFormData())
            .then(res => {
                if(res.data.errors && res.data.errors.length > 0) {
                    this.setState({
                        formErrors: formatValidationErrors(res.data.errors),
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        ...initialState(),
                    }, () => {
                        this.props.deploySnackBar("success", `The incident was successfully ${incidentId ? 'updated' : 'added'}`);
                        this.props.refresh();
                    })
                }
            });
        });
    }

    handleDeleteFile = (incidentFileId) => {
        this.setState({
            isLoading: true,
        }, 
        () => {
            API.post(`/vehicles/${this.props.vehicleId}/incidents/files/${incidentFileId}/delete`)
            .then(res => {
                if(res.data.errors && res.data.errors.length > 0) {
                    this.setState({
                        formErrors: formatValidationErrors(res.data.errors),
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        ...initialState(),
                    }, () => {
                        this.props.deploySnackBar("success", `The file was successfully deleted`);
                        this.loadComponentData(); 
                    })
                }
            });
        });
    }

    render() {
        const { formErrors, formData, isLoading, incidentId, incident, staffList, readOnly } = this.state;
        return (   
            <>   
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {readOnly ? 'Incident Details' : (incidentId ? 'Update Incident' : 'Add Incident') }
                    </Typography>
                </Grid>     
                <Grid item xs={12}>
                    <form noValidate>
                        {(isLoading && (
                            <LoadingCircle />          
                        )) || (
                            <Grid container item spacing={3}>
                                {formErrors && formErrors.generic && (
                                    <Grid item xs={12}>
                                        <Typography component={"div"} style={{color: 'red'}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12} md={6}>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                type="datetime"
                                                id="date"
                                                name="dateTime"
                                                label="Date / Time"
                                                value={formData?.dateTime}
                                                errorText={formErrors && formErrors['dateTime']}
                                                onChange={date => this.handleDateChange('dateTime', date, 'YYYY-MM-DD HH:mm')}
                                                autoOk={true}
                                                disabled={readOnly}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                                disableFuture={true}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AutoCompleteSelect 
                                                name="driverStaffId"
                                                options={staffList} 
                                                label={`Driver ${readOnly ? '':'*'}`}
                                                value={formData?.driverStaffId}
                                                onChange={(v) => this.handleSelectChange('driverStaffId', v)}
                                                fullWidth
                                                error={!!formErrors && formErrors['driverStaffId'] && true}
                                                errorText={formErrors && formErrors['driverStaffId']}
                                                disabled={readOnly}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" gutterBottom>
                                                <b>Location</b>
                                            </Typography>
                                            <TextField  
                                                name="street"
                                                label="Street"
                                                margin="none"
                                                error={!!formErrors && formErrors['street'] && true}
                                                helperText={formErrors && formErrors['street']}
                                                value={formData.street || ''}
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={readOnly}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container item spacing={3}>
                                                <Grid item xs={6}>
                                                    <TextField  
                                                        name="town"
                                                        label="Town"
                                                        margin="none"
                                                        error={!!formErrors && formErrors['town'] && true}
                                                        helperText={formErrors && formErrors['town']}
                                                        value={formData.town || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        disabled={readOnly}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField  
                                                        name="postcode"
                                                        label="Postcode"
                                                        margin="none"
                                                        error={!!formErrors && formErrors['postcode'] && true}
                                                        helperText={formErrors && formErrors['postcode']}
                                                        value={formData.postcode || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        disabled={readOnly}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField  
                                                name="what3Words"
                                                label="What3words"
                                                margin="none"
                                                error={!!formErrors && formErrors['what3Words'] && true}
                                                helperText={formErrors && formErrors['what3Words']}
                                                value={formData.what3Words || ''}
                                                onChange={this.handleChange}
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (<InputAdornment><span style={{color: 'red'}}>///</span></InputAdornment>)
                                                }}
                                                disabled={readOnly}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        {!readOnly ?
                                            <Grid item xs={12}>
                                                <DragFileInputMulti
                                                    id="incidentFiles"
                                                    name="incidentFiles[]"
                                                    label="Files / Photos"
                                                    type="files"
                                                    file={formData?.incidentFiles}
                                                    error={formErrors && formErrors['files'] && true}
                                                    errorText={formErrors && formErrors['incidentFiles']}
                                                    onChange={(drop, name, event) => this.handleMultiFileChange(drop, 'incidentFiles', event)}
                                                    cancelOnClick={idx => this.clearMultiFiles(idx, 'incidentFiles')}
                                                    emptyText='No files uploaded'

                                                />
                                            </Grid>
                                        :null}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                name="incidentType"
                                                onChange={this.handleChange}
                                                row
                                            >
                                                <FormControlLabel
                                                    value='Accident'
                                                    control={<Radio color="primary" />}
                                                    label="Accident"
                                                    labelPlacement="end"
                                                    checked={formData?.incidentType == 'Accident' ? true : false}
                                                    disabled={readOnly}
                                                    inputProps={{
                                                        className:"textDefault"
                                                    }}
                                                />
                                                <FormControlLabel
                                                    value='Penalty Charge Notice'
                                                    control={<Radio color="primary" />}
                                                    label="Penalty Charge Notice"
                                                    labelPlacement="end"
                                                    checked={formData?.incidentType == 'Penalty Charge Notice' ? true : false}
                                                    disabled={readOnly}
                                                    inputProps={{
                                                        className:"textDefault"
                                                    }}
                                                />
                                            </RadioGroup>
                                        </Grid>
                                        {formData?.incidentType == 'Accident' ?
                                            <>
                                            <Grid item xs={12}>
                                                <AutoCompleteSelect 
                                                    name="accFaultOption"
                                                    options={[
                                                        { value: 'At Fault', label: 'At Fault' },
                                                        { value: 'Non Fault', label: 'Non Fault' },
                                                    ]} 
                                                    label={`Fault ${readOnly ? '':'*'}`}
                                                    value={formData?.accFaultOption}
                                                    onChange={(v) => this.handleSelectChange('accFaultOption', v)}
                                                    fullWidth
                                                    error={!!formErrors && formErrors['accFaultOption'] && true}
                                                    errorText={formErrors && formErrors['accFaultOption']}
                                                    disabled={readOnly}
                                                    inputProps={{
                                                        className:"textDefault"
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AutoCompleteSelect 
                                                    name="accInsuranceClaimed"
                                                    options={[
                                                        { value: 1, label: 'Yes' },
                                                        { value: 0, label: 'No' },
                                                    ]} 
                                                    label={`Insurance Claimed? ${readOnly ? '':'*'}`}
                                                    value={formData?.accInsuranceClaimed}
                                                    onChange={(v) => this.handleSelectChange('accInsuranceClaimed', v)}
                                                    fullWidth
                                                    error={!!formErrors && formErrors['accInsuranceClaimed'] && true}
                                                    errorText={formErrors && formErrors['accInsuranceClaimed']}
                                                    disabled={readOnly}
                                                    inputProps={{
                                                        className:"textDefault"
                                                    }}
                                                />
                                            </Grid>
                                            {formData?.accInsuranceClaimed == 1 ?
                                                <Grid item xs={12}>
                                                    <AutoCompleteSelect 
                                                        name="accInsuranceUsed"
                                                        options={[
                                                            { value: 'Own', label: 'Own' },
                                                            { value: 'Third Party', label: 'Third Party' },
                                                        ]} 
                                                        label={`Insurance Used ${readOnly ? '':'*'}`}
                                                        value={formData?.accInsuranceUsed}
                                                        onChange={(v) => this.handleSelectChange('accInsuranceUsed', v)}
                                                        fullWidth
                                                        error={!!formErrors && formErrors['accInsuranceUsed'] && true}
                                                        errorText={formErrors && formErrors['accInsuranceUsed']}
                                                        disabled={readOnly}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                            :null}
                                            <Grid item xs={12}>
                                                <Textarea
                                                    id="accDescription"
                                                    name="accDescription"
                                                    label="Description of Accident"
                                                    value={formData.accDescription}
                                                    onChange={this.handleChange}
                                                    InputLabelProps={{
                                                        shrink: !!formData.accDescription,
                                                    }}
                                                    disabled={readOnly}
                                                    inputProps={{
                                                        className:"textDefault"
                                                    }}
                                                />
                                            </Grid>
                                            </>
                                        :
                                        formData?.incidentType == 'Penalty Charge Notice' ?
                                            <>
                                            <Grid item xs={12}>
                                                <TextField  
                                                    name="pcnNumber"
                                                    label={`PCN Number ${readOnly ? '':'*'}`}
                                                    margin="none"
                                                    error={!!formErrors && formErrors['pcnNumber'] && true}
                                                    helperText={formErrors && formErrors['pcnNumber']}
                                                    value={formData.pcnNumber || ''}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    disabled={readOnly}
                                                    inputProps={{
                                                        className:"textDefault"
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Textarea
                                                    id="pcnContraventionCode"
                                                    name="pcnContraventionCode"
                                                    label={`Contravention Code and Description ${readOnly ? '':'*'}`}
                                                    value={formData.pcnContraventionCode}
                                                    onChange={this.handleChange}
                                                    InputLabelProps={{
                                                        shrink: !!formData.pcnContraventionCode,
                                                    }}
                                                    error={!!formErrors && formErrors['pcnContraventionCode'] && true}
                                                    errorText={formErrors && formErrors['pcnContraventionCode']}
                                                    disabled={readOnly}
                                                    inputProps={{
                                                        className:"textDefault"
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container item spacing={3}>
                                                    <Grid item xs={6}>
                                                        <TextField  
                                                            name="pcnAmountPayable"
                                                            type="number"
                                                            label={`Amount Payable ${readOnly ? '':'*'}`}
                                                            margin="none"
                                                            error={!!formErrors && formErrors['pcnAmountPayable'] && true}
                                                            helperText={formErrors && formErrors['pcnAmountPayable']}
                                                            value={formData.pcnAmountPayable || ''}
                                                            onChange={this.handleChange}
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (<InputAdornment>£</InputAdornment>)
                                                            }}
                                                            disabled={readOnly}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField  
                                                            name="pcnAdminFee"
                                                            type="number"
                                                            label="Admin Fee"
                                                            margin="none"
                                                            error={!!formErrors && formErrors['pcnAdminFee'] && true}
                                                            helperText={formErrors && formErrors['pcnAdminFee']}
                                                            value={formData.pcnAdminFee || ''}
                                                            onChange={this.handleChange}
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (<InputAdornment>£</InputAdornment>)
                                                            }}
                                                            disabled={readOnly}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container item spacing={3} direction="row" alignItems="flex-end">
                                                    <Grid item xs={6}>
                                                        <AutoCompleteSelect 
                                                            name="pcnSettled"
                                                            options={[
                                                                { value: 1, label: 'Yes' },
                                                                { value: 0, label: 'No' },
                                                            ]}
                                                            label={`Settled ${readOnly ? '':'*'}`}
                                                            value={formData?.pcnSettled}
                                                            onChange={(v) => this.handleSelectChange('pcnSettled', v)}
                                                            fullWidth
                                                            error={!!formErrors && formErrors['pcnSettled'] && true}
                                                            errorText={formErrors && formErrors['pcnSettled']}
                                                            disabled={readOnly}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                            noClear
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {formData?.pcnSettled == 1 ?
                                                            <DatePicker
                                                                type="date"
                                                                id="date"
                                                                name="pcnDateSettled"
                                                                label={`Date Settled ${readOnly ? '':'*'}`}
                                                                value={formData?.pcnDateSettled}
                                                                errorText={formErrors && formErrors['pcnDateSettled']}
                                                                onChange={date => this.handleDateChange('pcnDateSettled', date, 'YYYY-MM-DD')}
                                                                autoOk={true}
                                                                disabled={readOnly}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                                disableFuture={true}
                                                                margin="none"
                                                            />
                                                        : null}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {incidentId && incident?.receipt ?
                                                    <Link className='blueLink' variant="body2" style={{cursor: 'pointer'}} onClick={() => {downloadS3File(incident?.receipt?.fileUrl)}}>
                                                        <div>View PCN Receipt</div>
                                                    </Link>
                                                : null}
                                                {!readOnly && formData?.pcnSettled == 1 ?
                                                    <DragFileInput
                                                        id="pcnReceipt"
                                                        name="pcnReceipt"
                                                        label="Receipt"
                                                        file={formData?.pcnReceipt}
                                                        error={formErrors && formErrors['pcnReceipt'] && true}
                                                        errorText={formErrors && formErrors['pcnReceipt']}
                                                        onChange={(drop, name, event) => this.handleFileChange(drop, 'pcnReceipt', event)}
                                                        cancelOnClick={() => this.clearValue('pcnReceipt')}
                                                        emptyText='No file uploaded'

                                                    />
                                                : null}
                                            </Grid>
                                            </>
                                        :
                                        null
                                        }
                                    </Grid>
                                </Grid>
                                {(readOnly || incidentId) && incident?.files?.length>0 ?
                                    <Grid item xs={12}>
                                        <Typography variant="body2" gutterBottom>
                                            <b>Files / Photos</b>
                                        </Typography>
                                        <Table size="small">
                                            <TableBody>
                                                {incident?.files?.map((v, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <Link variant="body2" className='blueLink' style={{cursor: 'pointer'}} onClick={() => {downloadS3File(v?.fileUrl)}}>
                                                                {v?.filename}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell>
                                                            {!readOnly ?
                                                                <AllIcon button icon="times" onClick={() => this.props.deployConfirmation(`Are you sure you want to delete this file?`, 'Delete File?', () => this.handleDeleteFile(v?.id))} noMargin tooltip="Delete" />
                                                            : null}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                : null }
                                <Grid item xs={12} align="right">
                                    <DialogActions className='pr-0 pb-0'>
                                        <Button onClick={() => this.props.onClose()} variant="text">
                                            <AllIcon icon="times" size={15} />
                                            Close
                                        </Button>
                                        {!readOnly && ((
                                            <Button 
                                                onClick={() => this.props.deployConfirmation(`Are you sure you want to ${incidentId ? 'update' : 'add'} this Incident?`, `${incidentId ? 'Update' : 'Add'} Incident?`, this.handleSubmit)}
                                                variant="contained"
                                                color="primary"
                                            >
                                                {incidentId ? 'Update' : 'Add'}
                                            </Button>
                                        ))}
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        )}
                    </form>
                </Grid>  
            </Grid>
            </>  
        );
    }
}

const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
    }
)

export default connect(null, mapDispatchToProps)(VehicleIncidentForm);