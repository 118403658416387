import API from 'API';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import IconHelper from 'Helpers/IconHelper';
import _ from 'lodash';
import React from 'react';

class ContactsSystem extends React.Component {

    removeContact = (contact) => {
        API.post(`/marketing/campaigns/mailingLists/${this.props.mailingList.i}/contacts/${contact.mlc_id}/remove`)
        .then(this.props.refresh);
    }

    render () {

        const { mailingList } = this.props;

        return (
            <DataTable
                config={{
                    key: 'mlc_id',
                    pagination:_.filter(mailingList?.c?.c, i => i.contact.customer)?.length >  100,
                    isLoading: this.props.dataLoading
                }}
                rows={_.filter(mailingList?.c?.c, i => i.contact.customer)}
                columns={[
                    { 
                        heading: 'Name', 
                        field: 'contact.contact_name', 
                        sizeToContent: true,
                        dataRef: 'contact.contact_name', 
                    },
                    { 
                        heading: 'Company', 
                        field: i => i.contact?.customer?.cust_name ?? '-',
                        truncate: true,
                        dataRef: i => i.contact?.customer?.cust_name ?? '-',
                    },
                    { 
                        heading: 'Email', 
                        field: 'contact.contact_email', 
                        dataRef: 'contact.contact_email', 
                    },
                    {
                        actions: r => ([
                            {
                                name: 'View',
                                link: `/customers/contacts/view/${r.contact.contact_id}`,
                                icon: IconHelper.view,
                            },
                            {
                                name: 'Remove',
                                onClick: this.removeContact,
                                icon: IconHelper.false
                            }
                        ])
                    }
                ]}
            />
        )
    }

}


export default ContactsSystem;