import API         from 'API';
import _           from 'lodash';
import React       from 'react';
import { connect } from 'react-redux';

import { Button, Grid, Typography } from '@material-ui/core';

import DataTable     from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = {
    highInStock:     [],
    notHighInStock:  [],
    isLoading:      true,
}

class HighInStock extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }   

    componentDidMount() { this.getHighInStock(); }

    getHighInStock() {
        this.setState({ isLoading: true },()=>{
            API.get(`/stock/highInStock`)
            .then(res => {
                this.setState({
                    highInStock:    _.filter(res.data, (item) => parseFloat(item.stock.stock_maximum_level) <  parseFloat(item.stock.stock_predicted)),
                    notHighInStock: _.filter(res.data, (item) => parseFloat(item.stock.stock_maximum_level) >= parseFloat(item.stock.stock_predicted)),
                    isLoading: false
                });
            })
        });
    }

    handleProcessCheck = f => id => {
        this.setState({ 
            [f]: _.map(this.state[f], i => {
                if (i.phis_id === id) i.checked = !i.checked;
                return i;
            })
        })
    }

    checkAll = f => () => {
        this.setState({ 
            [f]: _.map(this.state[f], i => {
                i.checked = true;
                return i;
            })
        })
    }

    dealWithChecked = () => {
        let promise = _.map( _.filter( _.merge( this.state.notHighInStock, this.state.highInStock ), {checked: true} ), i => {
            return API.post(`/stock/highInStock/${i.phis_id}`);
        } );
        if ( promise.length > 0 ) 
            Promise.all(promise).then(e=>{this.getHighInStock()});
    }

    dismissAllNotHighInStock = () => {
        let promise = _.map(this.state.notHighInStock, i => {
            return API.post(`/stock/highInStock/${i.phis_id}`);
        })
        if ( promise.length > 0 ) 
            Promise.all(promise).then(e=>{this.getHighInStock()});
    }

    dealWithPart = id => {
        API.post(`/stock/highInStock/${id}`).then(e=>{this.getHighInStock()});
    }

    render() {
        const { highInStock, notHighInStock, isLoading } = this.state;

        if ( isLoading ) return ( <LoadingCircle /> );
        return (
            <Grid container spacing={3} >
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>High In Stock</Typography>
                </Grid>
                {_.filter(_.merge(highInStock, notHighInStock), {checked: true}).length > 0 &&
                    <Grid item container xs={12} style={{justifyContent: 'flex-end'}}>
                        <Grid item xs={3}>
                            <PaddedPaper>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <b>Checked:</b>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {_.filter(_.merge(highInStock, notHighInStock), {checked: true}).length}
                                    </Grid>
                                    {highInStock.length > 0 && notHighInStock.length > 0 &&
                                        <>
                                            <Grid item xs={6}>
                                                <b>High Checked:</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {_.filter(HighInStock, {checked: true}).length}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>Not High Checked:</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {_.filter(notHighInStock, {checked: true}).length}
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <Button
                                            style={{marginTop: '1em'}}
                                            variant="contained"
                                            color="primary"
                                            onClick={()=> this.props.deployConfirmation('Are you sure, you want to deal with the checked parts?','Deal With Parts',this.dealWithChecked)}
                                            fullWidth
                                        >Mark Dealt With</Button>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                }
                {HighInStock.length > 0 && [ 
                    /*
                    <Grid item xs={12}>
                        <div className='buttonRow'>
                            <Button 
                                variant="contained"
                                color="primary"
                                onClick={this.checkAll('HighInStock')}
                            >Check All</Button>
                        </div>
                    </Grid>,*/
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <DataTable
                                config={{
                                    key:         'phis_id',
                                    pagination:  true,
                                    rowsPerPage: 50
                                }}
                                columns={[
                                    {
                                        field:         'part.default_image.thumbnail_file_path',
                                        fieldFormat:   'image',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'part',
                                        field:   rowData => <>{rowData.part?.part_number}<br/>{rowData.part?.part_description}</>,
                                        dataRef: rowData => rowData.part?.part_number
                                    },
                                    {
                                        heading:       'Current Stock',
                                        field:         rowData => rowData.stock?.stock_current_qty,
                                        dataRef:       rowData => rowData.stock?.stock_current_qty,
                                        fieldFormat:   'decimal:3',
                                        alignment:     'right',
                                        sizeToContent: true
                                    },
                                    {
                                        heading:       'Pending Stock',
                                        field:         rowData => rowData.stock?.stock_pending,
                                        dataRef:       rowData => rowData.stock?.stock_pending,
                                        fieldFormat:   'decimal:3',
                                        alignment:     'right',
                                        sizeToContent: true
                                    },
                                    {
                                        heading:       'Predicted Stock',
                                        field:         rowData => rowData.stock?.stock_predicted,
                                        dataRef:       rowData => rowData.stock?.stock_predicted,
                                        fieldFormat:   'decimal:3',
                                        alignment:     'right',
                                        sizeToContent: true
                                    },
                                    {
                                        heading:       'High Stock Level',
                                        field:         rowData => rowData.stock?.stock_maximum_level,
                                        dataRef:       rowData => rowData.stock?.stock_maximum_level,
                                        fieldFormat:   'decimal:3',
                                        alignment:     'right',
                                        sizeToContent: true
                                    },
                                    {
                                        heading:       'Stock Above',
                                        field:         rowData => rowData.stock.stock_predicted - rowData.stock?.stock_maximum_level,
                                        dataRef:       rowData => rowData.stock.stock_predicted - rowData.stock?.stock_maximum_level,
                                        style:         rowData => ({color: (rowData.stock?.stock_maximum_level - rowData.stock?.stock_predicted) !== 0 && colors.green}),
                                        fieldFormat:   'decimal:3',
                                        alignment:     'right',
                                        sizeToContent: true
                                    },
                                    {
                                        actions: rowData => ([
                                            {
                                                name: 'View',
                                                icon: icons.search,
                                                link: `/parts/view/${rowData.part.part_id}`,
                                            },
                                            {
                                                name:    'Deal With',
                                                icon:    icons.tick,
                                                onClick: () => this.props.deployConfirmation('Are you sure, you want to deal with this part?','Deal With Part',()=>this.dealWithPart(rowData.phis_id))
                                            },
                                            { 
                                                type: 'checkBox',                   
                                                checked: rowData.checked, 
                                                onCheck: ()=>{this.handleProcessCheck('highInStock')(rowData.phis_id)},  
                                            }
                                        ])
                                    }
                                ]}
                                rows={_.orderBy(highInStock, [i=>i.part?.part_number], ['asc'])}
                            />
                        </PaddedPaper>
                    </Grid>
                ]}
                {notHighInStock.length > 0 && 
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container style={{alignItems: 'flex-end'}}>
                                <Grid item xs={6}>
                                    <Typography variant="h6" gutterBottom>No Longer High In Stock</Typography>
                                </Grid>
                                <Grid item xs={6} className='buttonRow' style={{marginTop: 0}}>
                                    <Button variant='contained' color='primary' onClick={this.dismissAllnotHighInStock}>Dismiss All</Button>
                                </Grid>
                                <Grid item xs={12}>
                                <DataTable
                                    config={{
                                        key:         'phis_id',
                                        pagination:  true,
                                        rowsPerPage: 50
                                    }}
                                    columns={[
                                        {
                                            field:         'part.default_image.thumbnail_file_path',
                                            fieldFormat:   'image',
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: 'part',
                                            field:   rowData => <>{rowData.part?.part_number}<br/>{rowData.part?.part_description}</>,
                                            dataRef: rowData => rowData.part?.part_number
                                        },
                                        {
                                            heading:       'Current Stock',
                                            field:         rowData => rowData.stock?.stock_current_qty,
                                            dataRef:       rowData => rowData.stock?.stock_current_qty,
                                            fieldFormat:   'decimal:3',
                                            alignment:     'right',
                                            sizeToContent: true
                                        },
                                        {
                                            heading:       'Pending Stock',
                                            field:         rowData => rowData.stock?.stock_pending,
                                            dataRef:       rowData => rowData.stock?.stock_pending,
                                            fieldFormat:   'decimal:3',
                                            alignment:     'right',
                                            sizeToContent: true
                                        },
                                        {
                                            heading:       'Predicted Stock',
                                            field:         rowData => rowData.stock?.stock_predicted,
                                            dataRef:       rowData => rowData.stock?.stock_predicted,
                                            fieldFormat:   'decimal:3',
                                            alignment:     'right',
                                            sizeToContent: true
                                        },
                                        {
                                            heading:       'Low Stock Level',
                                            field:         rowData => rowData.stock?.stock_maximum_level,
                                            dataRef:       rowData => rowData.stock?.stock_maximum_level,
                                            fieldFormat:   'decimal:3',
                                            alignment:     'right',
                                            sizeToContent: true
                                        },
                                        {
                                            heading:       'Stock Above',
                                            field:         rowData => rowData.stock.stock_predicted - rowData.stock?.stock_maximum_level,
                                            dataRef:       rowData => rowData.stock.stock_predicted - rowData.stock?.stock_maximum_level,
                                            style:         rowData => ({color: (rowData.stock?.stock_maximum_level - rowData.stock?.stock_predicted) !== 0 && colors.green}),
                                            fieldFormat:   'decimal:3',
                                            alignment:     'right',
                                            sizeToContent: true
                                        },
                                        {
                                            actions: rowData => ([
                                                {
                                                    name: 'View',
                                                    icon: icons.search,
                                                    link: `/parts/view/${rowData.part.part_id}`,
                                                },
                                                {
                                                    name:    'Deal With',
                                                    icon:    icons.tick,
                                                    onClick: () => this.props.deployConfirmation('Are you sure, you want to deal with this part?','Deal With Part',()=>this.dealWithPart(rowData.phis_id))
                                                },
                                                { 
                                                    type: 'checkBox',                   
                                                    checked: rowData.checked, 
                                                    onCheck: ()=>{this.handleProcessCheck('notHighInStock')(rowData.phis_id)},  
                                                }
                                            ])
                                        }
                                    ]}
                                    rows={_.orderBy(notHighInStock, [i=>i.part?.part_number], ['asc'])}
                                />
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) { return { deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)) } };

export default connect(null, mapDispatchToProps)(HighInStock);