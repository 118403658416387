import React       from 'react';
import { connect } from 'react-redux';
import moment      from 'moment';
import API         from 'API';
import _           from 'lodash';

import AllIcon from 'Components/Common/Icons/AllIcon';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import DatePicker from 'Components/Common/DatePickers/DatePicker';

import { Button, Grid, InputAdornment, TextField } from '@material-ui/core';

import icons from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';

import { closeDialog } from 'Actions/Dialog/Dialog';

const initialState = {
    isLoading:      true,
    tab:            0,
    formData:       {
        paymentOutstanding: 0,
        paymentAmount:      0,
        date:               moment(),
        paymentMethod:      null,
        img:                null,
    },
    cardFormData: {
        paymentRef: '',
        authNo:     '',
        file:       ''
    },
    stripeFormData: {
        paymentRef: '',
        file:       ''
    },
    payPalFormData: {
        paymentRef: '',
        file:       ''
    },
    chequeFormData: {
        chequeNo:    '',
        chequePayee: '',
        file:        ''
    },
    bankTransferFormData: {
        ref:    '',
        payee: ''
    }
}

class SelectPaymentDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState
        this.state.formData.paymentAmount      = parseFloat(props.item.crp_amount - props.item.sum_of_payments).toFixed(2);
        this.state.formData.paymentOutstanding = parseFloat(props.item.crp_amount - props.item.sum_of_payments).toFixed(2);
    }

    changeTab = paymentMethod => () => {
        this.setState({
            formData: {
                ...this.state.formData,
                paymentMethod
            }
        })
    }

    submit = () => {
        let formData = {};
        switch (this.state.formData.paymentMethod) {
            case 'Card':          formData = this.state.cardFormData;         break;
            case 'Stripe':        formData = this.state.stripeFormData;       break;
            case 'PayPal':        formData = this.state.payPalFormData;       break;
            case 'Cheque':        formData = this.state.chequeFormData;       break;
            case 'Bank Transfer': formData = this.state.bankTransferFormData; break;
            default:              formData = {};                              break;                   
        }

        formData = {...this.state.formData, ...formData};

        let newFormData = new FormData();
        _.each(_.keys(formData), key => {
            if (key === 'file') {
                newFormData.append('file', formData[key]);
            } if (key === 'img') {
                newFormData.append('img', formData[key]);
            } else if (key === 'date') {
                newFormData.append(key, formData[key].format('YYYY-MM-DD HH:mm:ss'));
            } else {
                newFormData.append(key, formData[key]);
            }
        })

        API.post(`/accounts/customerPaymentRequests/${this.props.item.crp_id}/proccessPayment`, newFormData)
        .then(this.props.onSubmit);
    }

    handleChange = formData => field => e => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                [field]: e.target.value
            }
        })
    }

    handleBlur = formData => field => e => {
        let value = e.target.value;
        if (field === 'paymentAmount') {
            if (value < 0){ value = 0 }
            if (parseFloat(value) > parseFloat(this.state.formData.paymentOutstanding)) { value = this.state.formData.paymentOutstanding }
        }
        this.setState({
            [formData]: {
                ...this.state[formData],
                [field]: parseFloat(value).toFixed(2)
            }
        })
    }

    handleFileChange = formData => field => (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            [formData]: {
                ...this.state[formData],
                [field]: file
            }
        })
    }

    clearFile = formData => field => () => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                [field]: ''
            }
        })
    }

    handleDateChange  = formData => field => e => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                [field]: moment(e)
            }
        })
    }

    render() {

        const { formData, cardFormData, stripeFormData, payPalFormData, chequeFormData, bankTransferFormData} = this.state;

        return (
            <Grid container>
                <Grid item container spacing={1} style={{paddingTop: '1.5em', paddingBottom: '1.5em'}}>
                    <Grid item>
                        <Button 
                            startIcon={<AllIcon icon={icons.barclaycard} style={{color: colors.blue }} />}
                            onClick={this.changeTab('Card')}
                            variant={formData.paymentMethod === 'Card' ? 'outlined' : null}
                        >
                            BarclayCard EPDQ
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            startIcon={<AllIcon icon={icons.stripe} style={{color: colors.blue }} />}
                            onClick={this.changeTab('Stripe')}
                            variant={formData.paymentMethod === 'Stripe' ? 'outlined' : null}
                        >
                            Stripe
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            startIcon={<AllIcon icon={icons.paypal} style={{color: colors.blue }} />}
                            onClick={this.changeTab('PayPal')}
                            variant={formData.paymentMethod === 'PayPal' ? 'outlined' : null}
                        >
                            PayPal
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            startIcon={<AllIcon icon={icons.cheque} style={{color: colors.blue }} />}
                            onClick={this.changeTab('Cheque')}
                            variant={formData.paymentMethod === 'Cheque' ? 'outlined' : null}
                        >
                            Cheque
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            startIcon={<AllIcon icon={icons.bank} style={{color: colors.blue }} />}
                            onClick={this.changeTab('Bank Transfer')}
                            variant={formData.paymentMethod === 'Bank Transfer' ? 'outlined' : null}
                        >
                            Bank Transfer
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            startIcon={<AllIcon icon={icons.cash} style={{color: colors.blue }} />}
                            onClick={this.changeTab('Cash')}
                            variant={formData.paymentMethod === 'Cash' ? 'outlined' : null}
                        >
                            Cash
                        </Button>
                    </Grid>
                </Grid>
                {!!formData.paymentMethod && 
                    <Grid item container xs={12} spacing={2} style={{paddingBottom: '1.5em'}}>
                        {/*<Grid item xs={12}>
                            <TextField
                                type="number"
                                fullWidth
                                label="Amount *"
                                value={formData.paymentAmount}
                                onChange={this.handleChange('formData')('paymentAmount')}
                                onBlur={this.handleBlur('formData')('paymentAmount')}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                    inputProps: { min: 0, max: formData.paymentOutstanding }
                                }}

                            />
                        </Grid>
                        */}
                        <Grid item xs={12}>
                            <TextField
                                label="Amount Outstanding"
                                type="number"
                                fullWidth
                                value={formData.paymentOutstanding}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>
                                }}
                                disabled
                            />
                        </Grid>
                    </Grid>
                }
                {formData.paymentMethod === 'Card' && 
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Payment Reference *"
                                value={cardFormData.paymentRef}
                                onChange={this.handleChange('cardFormData')('paymentRef')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Auth No *"
                                value={cardFormData.authNo}
                                onChange={this.handleChange('cardFormData')('authNo')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DragFileInput 
                                id="file"
                                name='file'
                                label="Upload File:"
                                file={cardFormData.file}
                                onChange={this.handleFileChange('cardFormData')('file')}
                                cancelOnClick={this.clearFile('cardFormData')('file')}
                                emptyText="No file selected"
                            />
                        </Grid>
                    </Grid>
                }
                {formData.paymentMethod === 'Stripe' && 
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Payment Reference *"
                                value={stripeFormData.paymentRef}
                                onChange={this.handleChange('stripeFormData')('paymentRef')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DragFileInput 
                                id="file"
                                name='file'
                                label="Upload File:"
                                file={stripeFormData.file}
                                onChange={this.handleFileChange('stripeFormData')('file')}
                                cancelOnClick={this.clearFile('stripeFormData')('file')}
                                emptyText="No file selected"
                            />
                        </Grid>
                    </Grid>
                }
                {formData.paymentMethod === 'PayPal' && 
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Payment Reference *"
                                value={payPalFormData.paymentRef}
                                onChange={this.handleChange('payPalFormData')('paymentRef')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DragFileInput 
                                id="file"
                                name='file'
                                label="Upload File:"
                                file={payPalFormData.file}
                                onChange={this.handleFileChange('payPalFormData')('file')}
                                cancelOnClick={this.clearFile('payPalFormData')('file')}
                                emptyText="No file selected"
                            />
                        </Grid>
                    </Grid>
                }
                {formData.paymentMethod === 'Cheque' && 
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Cheque Number *"
                                value={chequeFormData.chequeNo}
                                onChange={this.handleChange('chequeFormData')('chequeNo')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Cheque Payee *"
                                value={chequeFormData.chequePayee}
                                onChange={this.handleChange('chequeFormData')('chequePayee')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DragFileInput 
                                id="file"
                                name='file'
                                label="Upload Cheque Image:"
                                file={chequeFormData.file}
                                onChange={this.handleFileChange('chequeFormData')('file')}
                                cancelOnClick={this.clearFile('chequeFormData')('file')}
                                emptyText="No file selected"
                            />
                        </Grid>
                    </Grid>
                }
                {formData.paymentMethod === 'Bank Transfer' && 
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Ref *"
                                value={bankTransferFormData.ref}
                                onChange={this.handleChange('bankTransferFormData')('ref')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Payee *"
                                value={bankTransferFormData.payee}
                                onChange={this.handleChange('bankTransferFormData')('payee')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DragFileInput 
                                id="file"
                                name='file'
                                label="file:"
                                file={formData.img}
                                onChange={this.handleFileChange('formData')('img')}
                                cancelOnClick={this.clearFile('formData')('img')}
                                emptyText="No file selected"
                            />
                        </Grid>
                    </Grid>
                }
                {formData.paymentMethod === 'Cash' && 
                    <Grid item xs={12}>
                        <DragFileInput 
                            id="file"
                            name='file'
                            label="file:"
                            file={formData.img}
                            onChange={this.handleFileChange('formData')('img')}
                            cancelOnClick={this.clearFile('formData')('img')}
                            emptyText="No file selected"
                        />
                    </Grid>
                }
                {!!formData.paymentMethod && 
                    <Grid item container xs={12} spacing={2} style={{paddingBottom: '1.5em'}}>
                        <Grid item xs={12}>
                            <DatePicker
                                type="date"
                                id="date"
                                name="date"
                                label="Payment Date *"
                                value={formData.date}
                                onChange={this.handleDateChange('formData')('date')}
                                autoOk={true}
                                disablePast
                            />
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12} className='buttonRow'>
                    <Button onClick={this.props.closeDialog} variant='outlined' >Close</Button>
                    {!!formData.paymentMethod &&
                        <Button onClick={this.submit} variant='contained' color='primary'
                            disabled={formData.paymentAmount === 0 || formData.paymentAmount > formData.paymentOutstanding}
                        >Submit</Button>
                    }
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    closeDialog: () => dispatch(closeDialog())
})

export default connect(null, mapDispatchToProps)(SelectPaymentDialog);