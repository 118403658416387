import { Button, Grid, Typography } from "@material-ui/core";
import API from "API";
import AllIcon from "Components/Common/Icons/AllIcon";
import InfoIcon from "Components/Common/Icons/InfoIcon";
import ImageWithError from "Components/Common/ImageWithError/ImageWithError";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import {
	clearPageState,
	getInitialState,
	hasPageState,
	savePageState,
} from "Functions/StatePersistenceFunctions";
import { colors } from "Helpers/ColourHelper";
import IconHelper from "Helpers/IconHelper";
import { getColorArrow, getColorCurrencyChange, getColorPercentage, getColorPercentageChange, getCurrency, getPercentage, getPlainPercentage } from 'Helpers/ReportsHelper';
import { getOrdinal } from "Helpers/TimeHelper";
import _ from "lodash";
import moment from "moment";
import {getFiscalYearTimestamps}  from 'Helpers/ReportsHelper';
import React from "react";

const reportRows = [
	{
		label: 'Products',
		icon: IconHelper.products,
		k:'p',
	},
	{
		label: 'Services',
		icon: IconHelper.services,
		k:'s',
	},
	{
		label: 'Categories',
		icon: IconHelper.total,
		k:'t',
	}
]

const initialState = () => ({
	data: {},
	isLoading: true,
	access: {
		'sales-reports': false
	},
	countdown: 15*60,
	view: 'p'
});

const views = [
	{
		label: 'Most Products Sold',
		value: 'p',
	},
	{
		label: 'Most Services Sold',
		value: 's'
	},
	{
		label: 'Top Categories',
		value: 'c'
	}
]

const currentYear = parseInt(getFiscalYearTimestamps().end.format('YYYY'))

class Budget extends React.Component {
	constructor(props) {
		super(props);
		this.clearPageState = clearPageState.bind(this);
		this.getInitialState = getInitialState.bind(this);
		this.hasPageState = hasPageState.bind(this);
		this.savePageState = savePageState.bind(this);
		this.persistenceId = "Dashboard:budget";
		this.state = this.getInitialState(initialState());

		this.timeout = null;
	}

	componentDidMount(){
		if (!this.hasPageState()) {
			this.loadReports();
		} else {
			clearTimeout(this.timeout);
			this.addTimer();
		}
	}

	componentWillUnmount(){
		clearTimeout(this.timeout);
	}

	loadReports = (withLoading=true) => {
		clearTimeout(this.timeout);
		this.setState({
			isLoading: withLoading,
			countdown: 0
		}, ()=> {
            API.access('performance-reports').then(res => {
                this.setState({
					access: {
						'performance-reports': res
					}
				}, ()=> {
                    API.get(`/dashboard`, {params: {
                        section: 'Budget'
                    }}).then(res => {
						this.setState({
							data: res.data,
							isLoading: false,
							countdown: 15*60
						}, ()=> {
                            this.addTimer()
						})
					})
                })
            })
        })
	}   

	addTimer = () => {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(()=>this.countdown(15*60), 1000);
	}

	countdown = (num) => {
		if (num > 0) {
			this.setState({
				countdown: num
			}, ()=> {
				this.timeout = setTimeout(() => this.countdown(num - 1), 1000)
			});
		} else {
			this.setState({
				countdown: 0
			}, ()=>this.loadReports(false));
		}
	}

	render() {
		const { isLoading, data, access } = this.state;
		const loggedInStaff =
			this.props.loggedInStaff !== undefined
				? this.props.loggedInStaff.firstName +
				" " +
				this.props.loggedInStaff.lastName
				: "";

		if (isLoading ) {
			return (
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={6} style={{marginRight: 'auto'}}>
						<Typography variant="h6" gutterBottom>
							Welcome <b>{loggedInStaff}</b>, Home Of The Perfect Pint!
						</Typography>
					</Grid>
					<Grid item>
						<AllIcon 
							icon={IconHelper.home}
							heavy
							onClick={this.props.onBack}
							noMargin
						/>
						<AllIcon 
							icon={IconHelper.refresh}
							heavy
							onClick={this.loadReports}
							noMargin
						/>
					</Grid>
					<Grid item xs={12}>
						<LoadingCircle />
					</Grid>
				</Grid>
			)
		}

		return (
			<Grid container spacing={3} alignItems="top" >
				<Grid item xs={6} style={{marginRight: 'auto'}}>
					<Typography variant="h6" gutterBottom>
						Welcome <b>{loggedInStaff}</b>, Home Of The Perfect Pint!
					</Typography>
				</Grid>
				<Grid item>
					<Grid container alignItems="center">
						<Grid item>
							<AllIcon 
								icon={IconHelper.home}
								heavy
								onClick={this.props.onBack}
								noMargin
								tooltip="Back to Dashboard"
							/>
						</Grid>
						<Grid item>
							{!!access['performance-reports'] &&
								<AllIcon 
									icon={IconHelper.reports}
									heavy
									link="reports/performance"
									tooltip="View Performance Reports"
									noMargin
								/>
							}
						</Grid>
						
						<Grid item>
							<AllIcon 
								icon={IconHelper.refresh}
								heavy
								onClick={()=>this.loadReports(false)}
								noMargin
								tooltip="Refresh Data"
							/>
						</Grid>
						
						<Grid item>
							{this.state.countdown > 0 &&
								<>
									<Typography variant="body1" style={{width: 80}}>
										in {parseInt(this.state.countdown/60)}:{`${parseInt((parseFloat(this.state.countdown/60) - parseInt(this.state.countdown/60)) * 60)}`.padStart(2,'0')}
									</Typography>
								</>
							}
							{this.state.countdown < 1 &&
								<>
									<Typography variant="body1" style={{width: 110}}>
										Refreshing ...
									</Typography>
								</>
							}
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={9}>
					<Grid container spacing={2} >
						{_.map(
							[
								{
									label: 'Year',
									icon: IconHelper.year,
									j: 'y',
									sub: `Budget vs. ${currentYear-1}/${currentYear} Up Until ${getOrdinal(moment().format('D'))} ${moment().format('MMMM YYYY')}`
								},
								{
									label: 'Month',
									icon: IconHelper.month,
									j: 'm',
									sub: `${moment().format('MMMM')} Up Until ${getOrdinal(moment().format('D'))} ${moment().format('MMMM YYYY')}`
								}
							], ({label, icon,  j, sub}) =>
								<Grid item xs={12}>
									<Grid container spacing={1} alignItems="center">
										<Grid item xs={12}>
											<Typography variant="h5" gutterBottom style={{borderBottom: `1px solid ${colors.disabled}`}}>
												<Grid container spacing={1} style={{alignItems: 'flex-end'}}>
													<Grid item>
														<AllIcon icon={icon} size={27} solid/>
													</Grid>
													<Grid item style={{fontWeight: 'bold'}}>
														<b>{label}</b>
													</Grid>
													<Grid item>
														<Typography variant="body1">
															{sub}
														</Typography>
													</Grid>
													<Grid item xs={4} style={{marginLeft: 'auto'}}>
														<Typography variant="h6" style={{textAlign: 'right'}}>
															<Grid container spacing={1} style={{justifyContent: 'flex-end', alignItems: 'center'}}>
																<Grid item>
																	{getColorCurrencyChange(data[j].c.t.t,data[j].l.t.t)}  
																</Grid>
																<Grid item>
																	/ {getColorPercentage(data[j].c.t.t - data[j].l.t.t, data[j].l.t.t, false, false)}
																</Grid>
															</Grid>
														</Typography>
													</Grid>
												</Grid>
											</Typography>
										</Grid>
										{
											_.map(
												[
													{
														label: 'Sales',
														func: (k, t, data) => data[j][t][k].t,
														pFunc: (k, t, data) => parseInt(Math.round(getPlainPercentage(data[j][t][k].t , data[j][t].t.t))) + '%',
														invert: false,
														// sub: `${getCurrency(data[j].c.t.t)} / ${getCurrency(data[j].l.t.t)}`
													},
													{
														label: 'Cost',
														func: (k, t, data) => data[j][t][k].c,
														pFunc: (k, t, data) => parseInt(Math.round(getPlainPercentage(data[j][t][k].c , data[j][t].t.c))) + '%',
														invert: false,
														// sub: `${getPercentage((data[j].c.t.p - data[j].c.t.t),data[j].c.t.p)} / ${getPercentage((data[j].l.t.p - data[j].l.t.t),data[j].l.t.p)}`
													},
													{
														label: 'Margin',
														func: (k, t, data) => data[j][t][k].t - data[j][t][k].c,
														pFunc: (k, t, data) => parseInt(Math.round(getPlainPercentage(data[j][t][k].t - data[j][t][k].c , data[j][t][k].t))) + '%',
														invert: false,
														// sub: `${getPercentage(data[j].c.t.t - data[j].c.t.c, data[j].c.t.t)} / ${getPercentage(data[j].l.t.t - data[j].l.t.c,data[j].l.t.t)}`
													},
												], 
												({label, func, invert, sub, pFunc}) =>
												<Grid item xs={4}>
													<Grid container spacing={1} alignItems="center">
														<Grid item xs={12}>
															<Typography variant="body1" gutterBottom style={{borderBottom: `1px solid ${colors.disabled}`, textAlign: 'left', fontWeight: 'bold'}}>
																<Grid container spacing={1} style={{alignItems: 'center'}}>
																	<Grid item>
																		{label}
																	</Grid>
																	<Grid item style={{marginLeft: 'auto'}}>
																		{/* <Typography variant="caption">
																			{sub}
																		</Typography> */}
																	</Grid>
																</Grid>
															</Typography>
														</Grid>
														{/* 
															{_.map(reportRows, ({label, icon, k}) => {
																return (
																	<Grid item xs={12}>
																		
																			<Grid container spacing={1} style={{alignItems: 'center'}}>
																				<Grid item xs={1} style={{textAlign:'center'}}><AllIcon icon={icon} size={20} /></Grid>
																				<Grid item xs={5}>
																					<PaddedPaper style={{padding: 10, height: '100%'}}>
																						<Grid container spacing={2}>
																							<Grid xs={3}>
																								<b>{moment().format('YYYY')}</b>
																							</Grid>
																							<Grid xs={9}>
																								{getCurrency(func(k, 'c', data))}
																							</Grid>
																							<Grid xs={3}>
																								<b>{parseInt(moment().format('YYYY')) - 1}</b>
																							</Grid>
																							<Grid xs={9}>
																								{getCurrency(func(k, 'l', data))}
																							</Grid>
																						</Grid>
																					</PaddedPaper>
																				</Grid>
																					
																				<Grid item xs={6}>
																					<PaddedPaper style={{padding: 10}}>
																						<Grid container style={{alignItems: 'center', paddingTop: 4}}>
																							<Grid item={3}>
																								{getColorArrow(
																									func(k, 'c', data) - func(k, 'l', data),
																									false,
																									'medium' 
																								)}
																							</Grid>
																							<Grid item={9}>
																								<Typography variant={'caption'}>{getColorPercentage(func(k, 'c', data) - func(k, 'l', data), func(k, 'l', data), false, false)}</Typography>
																								<Typography variant={'body1'}>{getColorCurrencyChange(func(k, 'c', data), func(k, 'l', data), false, false)} </Typography>
																							</Grid>
																						</Grid>
																					</PaddedPaper>
																				</Grid>
																			</Grid>
																		
																	</Grid>
																)
															})}
														*/}
														<Grid item xs={12}>
															<PaddedPaper style={{padding: 12, paddingTop: 14, paddingBottom: 14}}>
																<Grid container spacing={0} alignItems="center">
																	<Grid item xs={2} style={{textAlign:'center'}}></Grid>
																	<Grid item xs={5} style={{marginBottom: 5}}>
																		<Typography>
																			<b>{moment().format('YYYY')}</b>
																		</Typography>
																	</Grid>
																	<Grid item xs={5} style={{marginBottom: 5}}>
																		<Typography>
																			<b>Budget</b>
																		</Typography>
																	</Grid>
																	{/* <Grid item xs={4}><b>Difference</b></Grid> */}
																	{_.map(reportRows, ({label, icon, k}) => 
																		<>
																			<Grid item xs={2} style={{textAlign:'center'}}><AllIcon icon={icon} size='xs' /></Grid>
																			<Grid item xs={3}>
																				{getCurrency(func(k, 'c', data))}
																			</Grid>
																			<Grid item xs={2}>
																				({pFunc(k, 'c', data)})
																			</Grid>
																			<Grid item xs={3}>
																				{getCurrency(func(k, 'l', data))}
																			</Grid>
																			<Grid item xs={2}>
																				({pFunc(k, 'l', data)})
																			</Grid>
																			{/* <Grid item xs={4}>
																				{getColorCurrencyChange(func(k, 'c', data), func(k, 'l', data))}  
																			</Grid> */}
																		</>
																	)}
																</Grid>
															</PaddedPaper>
														</Grid>
														{_.map(reportRows, ({label, icon, k}) => {
															let showIcon = k !== 't';
															let xs = showIcon ? 6 : 12;
															let v1 = showIcon ? 'caption' : 'body2';
															let v2 = showIcon ? 'body1' : 'h6';
															return (
																<Grid item xs={xs}>
																	<PaddedPaper style={{padding: 10}}>
																		<Grid container style={{alignItems: 'center', paddingTop: 4}}>
																			<Grid item style={{width: `${(100 / 12) * (showIcon ? 3 : 1.5)}%`}} >
																				{getColorArrow(
																					func(k, 'c', data) - func(k, 'l', data),
																					invert,
																					showIcon ? 'medium' : 'large',
																					true
																				)}
																			</Grid>
																			<Grid item style={{width: `${(100 / 12) * (showIcon ? 9 : 10.5)}%`}} container alignItems="center"> 
																				<Grid item xs={10}>
																					<Typography variant={v1}>{getColorPercentage(func(k, 'c', data) - func(k, 'l', data), func(k, 'l', data), invert, false)}</Typography>
																				</Grid>
																				<Grid item xs={2} style={{textAlign: 'right'}}>
																					<AllIcon
																						icon={icon}
																						noMargin
																						size={15}
																					/>
																				</Grid>
																				<Grid item xs={12}>
																					<Typography variant={v2}>{getColorCurrencyChange(func(k, 'c', data), func(k, 'l', data), invert, false)} </Typography>
																				</Grid>
																			</Grid>
																		</Grid>
																	</PaddedPaper>
																</Grid>
															)
														})}
													</Grid>
											</Grid>
											)
										}
									</Grid>
								</Grid>
							)
						}
					</Grid>
				</Grid>
				<Grid item xs={3}>
                    <Grid container spacing={2} alignItems="center" style={{marginTop: 1}}>
                        {_.map(_.take(_.orderBy(data.c, 'd', 'desc'), 5), ({d, l, c, n}, idx) =>
                            <Grid item xs={12}>
                                <PaddedPaper style={{padding: 13}}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={12} >
                                            <Typography variant="body1">
                                                {n}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} >
                                            {getColorArrow(d, false, 'large', true)}
                                        </Grid>
                                        <Grid item xs={10} container>
                                            <Grid item xs={12}>
                                                <Typography variant={'body2'}>{getColorPercentage(c - l, l, false, false)}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant={'h6'}>{getColorCurrencyChange(c, l, false, false)} </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="caption">
                                                {currentYear}: {getCurrency(c)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="caption">
                                                Budget: {getCurrency(l)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        )}
                    </Grid>
				</Grid>
			</Grid>
		)
	}
}

export default Budget;
