import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography } from '@material-ui/core/';

import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { deployDialog } from 'Actions/Dialog/Dialog';

import icons from 'Helpers/IconHelper';

const processItems = [
    {
        title: 'Incorrectly Picked Serial Number',
        content:         
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='body1'>
                        {'>'} Change the serial number inside the picking, inside the database go to the `picks` table and 
                        find the LAST record using the serial number you want to change ( SELECT * FROM  `picks` WHERE pick_serial_numbers LIKE LIKE '%replace_this_with_serial_number%' ).
                        Once found double click the field and replace the old serial number with the new serial number, you can confirm the serial number has changed by viewing the picking tab inside the related order
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1'>
                        {'>'} Change the serial number inside the despatch, inside the database go to the `order_despatch_details` table and 
                        find the LAST record using the serial number you want to change ( SELECT * FROM `order_despatch_details` WHERE odd_serial_numbers LIKE '%replace_this_with_serial_number%' ).
                        Once found double click the field and replace the old serial number with the new serial number, you can confirm the serial number has changed by viewing the related despatch file
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1'>
                        {'>'} If the order has reached the invoice stage you will also need to replace the serial number inside the invoice. Inside the database, go to `order_invoice_details` and find the LAST record using the serial number
                        you want to change ( SELECT * FROM `order_invoice_details` WHERE invoice_detail_serial_numbers LIKE '%replace_this_with_serial_number%' ). Once found double click the field and replace the old serial number 
                        with the new serial number, you can confirm the serial number has changed by viewing the related invoice
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1'>
                        {'>'} If the serial number is system generated you will need to set the old serial number as issued and the new serial number as sold. Inside the database, go to the `part_serial_numbers` 
                        table and find the old serial number ( SELECT * FROM `part_serial_numbers` WHERE psn_serial_number LIKE '%replace_this_with_serial_number%' ). Once found double click on the 'psn_serial_number_status' field and change it
                        from 'Sold' to 'Issued' then make a copy of the `psn_od_id` field and set it to 0. repeat this for the new serial number (make a copy of 'psn_id') changing from 'Issued' to 'Sold' and setting the `psn_od_id` field to the copied old serial number psn_od_id ,
                        you can check this worked by viewing the linked parts issued (and sold) serial numbers 
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1'>
                        {'>'} If the serial number is manualy entered, you will need to replace the old serial number. Inside the database, go to the `part_serial_numbers` 
                        table and find the old serial number ( SELECT * FROM `part_serial_numbers` WHERE psn_serial_number LIKE '%replace_this_with_serial_number%' ). Once found (note: psn_manual_serial_number should be set to 1) double click 'psn_serial_number' and replace it with
                        the new serial number and take a note of the 'psn_id' number.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1'>
                        {'>'} Update the serial numbers history. Inside the database, go to the `part_serial_number_sold_log` table and find the old serial number ( SELECT * FROM  `part_serial_number_sold_log` WHERE psnsl_serial_number LIKE '%replace_this_with_serial_number%' ).
                            Replace the serial number (in 'psnsl_serial_number') with the new serial number and change the 'psnsl_serial_number_id' to the copies psn_id from the previouse stage
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1' style={{fontWeight: 'bold'}}>
                        TLDR; change picks.pick_serial_numbers, order_despatch_details.odd_serial_numbers, order_invoice_details.invoice_detail_serial_numbers, part_serial_numbers.psn_od_id, part_serial_numbers.psn_serial_number_status, 
                        part_serial_numbers.psn_serial_number, part_serial_number_sold_log.psnsl_serial_number_id, part_serial_number_sold_log.psnsl_serial_number
                    </Typography>
                </Grid>
            </Grid>
    }
]

const initialState = {
    keyword: ''
}

class Processes extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    render() {

        const { isLoading } = this.state;

        if (isLoading) return <LoadingCircle/>

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">Processes</Typography>
                </Grid>
                <Grid item xs={12}>
                    <>
                        {_.map( processItems, i => 
                            <ExpansionPanel>
                                <ExpansionPanelSummary 
                                    expandIcon={<AllIcon noMargin icon={icons.arrowDown} white/>} 
                                ><Typography variant='body1'>{i.title}</Typography></ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    {i.content}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )}
                    </>
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog:           (content, title, variant, size='sm') => { dispatch(deployDialog(content, title, variant, size))},
    }
}
export default connect(null, mapDispatchToProps)(Processes)