import React            from 'react';
import moment           from 'moment';
import { withStyles }   from '@material-ui/core/styles';

import {IconButton, Card, CardHeader, CardContent, Avatar, Typography, CardActions  } from '@material-ui/core';

import FALightIcon          from 'Components/Common/Icons/FontAwesome/FALightIcon';
import NotesDialog          from 'Components/Common/Dialogs/NotesDialog';

const styles = theme => ({
    paperGrey : {
        padding : theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding : theme.spacing(3),
        },
        backgroundColor :'#f8f9fa'
    },
});

class NoteBlock extends React.Component {
    constructor(props) {
        super(props);
    }

    getType = prefix => {
        switch (prefix){
            case 'ori': return 'recurring-invoice-notes'
            case 'cq':  return 'quotation-notes';   
            default:    return 'order-notes';
        }
    }

    render() {

        const { note, classes, prefix } = this.props;

        return (
            <Card className={classes.paperGrey}>
                <CardHeader
                    avatar={<Avatar alt={`${note.staff.staff_first_name} ${note.staff.staff_last_name}`} src={note.staff.staff_profile_photo} />}
                    title={ note[`${prefix}_note_staff_name`]}
                    subheader={`${moment(note[`${prefix}_note_datetime_added`]).format("DD/MM/YYYY")}`+ ( prefix == 'co' ? `(${note.co_note_type})` : '' )}
                    action={
                        <>
                            <IconButton onClick={()=>{this.props.deleteCallback( note[`${prefix}_note_id`] )}} >
                                <FALightIcon icon='times' size="small" button noMargin/>
                            </IconButton>
                            <IconButton 
                                onClick={() => this.props.deployDialog(
                                    <NotesDialog 
                                        type={ this.getType(prefix) } 
                                        id={ note[`${prefix}_note_id`]} 
                                        updateText={ note[`${prefix}_note_text`]} 
                                        callback={this.props.editCallback} />, 'Update Note', '', 'xs'
                                )}>
                                <FALightIcon icon='pencil' size="small" button noMargin/>
                            </IconButton>
                        </>
                    }
                />
                <CardContent>
                    <Typography component="p">
                        { note[`${prefix}_note_text`] && note[`${prefix}_note_text`].split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })}
                    </Typography>
                    {( note[`${prefix}_updated_by_staff_id`] ) ? (
                        <CardActions className="float-right pl-2">
                            <Typography component="p" variant="body2" className="textSecondary">
                                <small>Updated by {note[`${prefix}_updated_by_name`]} at {moment( note[prefix === 'co' ? `${prefix}_updated_at_datetime` : ( prefix === 'cq' ?  `${prefix}_updated_at_datetime` : `${prefix}_updated_by_datetime`)] ).format('DD/MM/YYYY')}</small>
                            </Typography>
                        </CardActions>
                    ) : <></>}
                </CardContent>
            </Card>
        )
    }
}

export default withStyles(styles)(NoteBlock)