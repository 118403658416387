import { orderStatusIcons } from 'Helpers/SalesHelper';
import _ from 'lodash';
import { CLENAWARE_RED, CLENAWARE_BLUE, CLENAWARE_GREEN, CLENAWARE_GREY, CLENAWARE_YELLOW, CLENAWARE_ORANGE } from 'Constants';
import IconHelper from './IconHelper';

const colours = {
	red: 			CLENAWARE_RED,
	green:			CLENAWARE_GREEN,
	yellow: 		CLENAWARE_YELLOW,
	blue:			CLENAWARE_BLUE,
	primary: 		CLENAWARE_BLUE,
	orange: 		CLENAWARE_ORANGE,
	grey:           CLENAWARE_GREY,
	white:      	'#FFFFFF',
	darkBlue:		'#1e5d88',
	black:      	'rgba(0, 0, 0, 0.87)',


	disabled:		'#acacac',
	stickyNotes:	'#FFFF88',
	product:		CLENAWARE_GREEN,
	service:        CLENAWARE_BLUE,
	info:			'rgba(0, 0, 0, 0.38)',
	background:		'#fafafa',
	importantNote:	'#fcc6c5',
	importantInfo:  '#fcc6c5',

	subText:		'#9E9E9E',
}

export function statusColour(status) {
	let colour = '';
	
    switch (status){
		case 'Completed':
		case 'Complete':
		case 'Ordered':
		case 'New':
			colour = colours.green
		break;
		case 'Declined':
		case 'Cancelled':
		case 'Closed':
		case 'Expired':
		case 'Not Started':
			colour = colours.red;
		break;
		case 'Approval':
		case 'Confirmation':
		case 'Invoice':
		case 'Awaiting Despatch':
		case 'Delivery':
		case 'Credit':
		case 'Pending':
		case 'In Progress':
		case 'Open':
		case 'Pre-planning':
		case 'To Be Allocated':
			colour = '#D9A200';
		break;
		case 'Awaiting Approval':
		case 'Confirmed':
		case 'Awaiting Payment':
		case 'Payment Confirmed':
		case 'Awaiting Invoice Completion':
		case 'Picking':
			colour = '#F39C12';
		break;
		case 'N/A':
			colour = '#333333';
		break;
        default:
        break;
	}
	
    return colour;
}

export function ssrActivityLog(status) {
	let result = [];

    switch(status){
		case 'Stock Return Created':
			result['icon'] 	= 'star';
			result['color'] = '#F39C12';
		break;
		case 'Approved':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'Declined':
			result['icon'] 	= 'times';
			result['color'] = colours.red;
		break;
		case 'Confirmed':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'Dispatched':
		case 'Dispatch Not Required':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'Delivery':
			result['icon'] 	= 'truck';
			result['color'] = colours.green;
		break;
		case 'All Deliveries Completed':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'Credit Received':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'Cancelled':
			result['icon'] 	= 'ban';
			result['color'] = colours.red;
		break;
		case 'Completed':
			result['icon'] 	= 'trophy';
			result['color'] = '#F1C40F';
		break;
		case 'Added Notes':
		case 'Added Files':
			result['icon'] 	= 'plus';
			result['color'] = colours.blue;
		break;
		case 'Amended':
			result['icon'] 	= 'pen';
			result['color'] = '#BDC3C7';
		break;
		case 'Emailed Supplier':
			result['icon'] 	= 'envelope';
			result['color'] = colours.blue;
		break;
        default:
        break;
	}

    return result;
}

export function ppoActivityLog(status) {
	let result = [];
	
    switch(status){
		case 'Parts Order Created':
			result['icon'] 	= 'star';
			result['color'] = '#F39C12';
		break;
		case 'Approved':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'Confirmed':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'Delivery':
			result['icon'] 	= 'truck';
			result['color'] = colours.green;
		break;
		case 'All Invoices Received':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'Declined':
			result['icon'] 	= 'times';
			result['color'] = colours.red;
		break;
		case 'Cancelled':
			result['icon'] 	= 'ban';
			result['color'] = colours.red;
		break;
		case 'Added Notes':
		case 'Added Files':
			result['icon'] 	= 'plus';
			result['color'] = colours.blue;
		break;
		case 'Purchase Order Amended':
			result['icon'] 	= 'pen';
			result['color'] = '#BDC3C7';
		break;
		case 'Completed':
			result['icon'] 	= 'trophy';
			result['color'] = '#F1C40F';
		break;
		case 'Delivery Schedule Amended':
			result['icon'] 	= 'pen';
			result['color'] = '#BDC3C7';
		break;
		case 'Emailed Supplier':
			result['icon'] 	= 'envelope';
			result['color'] = colours.blue;
		break;
		case 'All Deliveries Completed':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'File Not Required':
			result['icon'] 	= 'ban';
			result['color'] = colours.red;
		break;
        default:
        break;
	}

    return result;
}

export function poActivityLog(status) {
	let result = [];
	
    switch(status){
		case 'Purchase Order Created':
			result['icon'] 	= 'star';
			result['color'] = '#F39C12';
		break;
		case 'Approved':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'Confirmed':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'Delivered':
			result['icon'] 	= 'truck';
			result['color'] = colours.green;
		break;
		case 'Invoice Received':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'Declined':
			result['icon'] 	= 'times';
			result['color'] = colours.red;
		break;
		case 'Cancelled':
			result['icon'] 	= 'ban';
			result['color'] = colours.red;
		break;
		case 'Added Notes':
		case 'Added Files':
			result['icon'] 	= 'plus';
			result['color'] = colours.blue;
		break;
		case 'Amended':
			result['icon'] 	= 'pen';
			result['color'] = '#BDC3C7';
		break;
		case 'Completed':
			result['icon'] 	= 'trophy';
			result['color'] = '#F1C40F';
		break;
        default:
        break;
	}

    return result;
}

export function cqActivityLog(status) {
	let result = [];
	
    switch(status){
		case 'Customer Quote Created':
			result['icon'] 	= 'star';
			result['color'] = '#F39C12';
		break;
		case 'Amended':
		case 'Edited Note':
		case 'Amended Sticky Note':
			result['icon'] 	= 'pen';
			result['color'] = '#BDC3C7';
		break;
		case 'Email Sent':
			result['icon'] 	= 'envelope';
			result['color'] = colours.blue;
		break;
		case 'Closed':
		case 'Expired':
			result['icon'] 	= 'ban';
			result['color'] = colours.red;
		break;
		case 'Ordered':
		case 'Completed Sticky Note':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'Files Added':
		case 'Added Notes':
		case 'Added Sticky Note':
			result['icon'] 	= 'plus';
			result['color'] = colours.blue;
			break;
		case 'Files Removed':
		case 'Deleted Note':
		case 'Deleted Sticky Note':
			result['icon'] = 'minus';
			result['color'] = colours.red;
		break;
		case 'Quote Extended':
			result['icon'] 	= 'clock-rotate-left';
			result['color'] = colours.primary;
			break;
		case 'Quote Repeated':
			result['icon'] 	= 'repeat';
			result['color'] = colours.primary;
			break;
		case 'Quote Revived':
			result['icon'] 	= 'cross';
			result['color'] = colours.primary;
		break;
		default:
        break;
	}

    return result;
}

export function CustActivityLog(status) {
	let result = [];
	switch(status){
		case 'Quote Created':
		case 'Order Created':
			result['icon'] = 'star';
			result['color'] = '#F39C12';
		break;
		case 'Amended':
		case 'Edited Note':
		case 'Edited Customer':
		case 'Edited Address':
		case 'Edited Contact':
		case 'Edited Reminder':
		case 'Important Information Edited':
			result['icon'] = 'pen';
			result['color'] = '#BDC3C7';
		break;
		case 'Contact Added':
		case 'Note Added':
		case 'Reminder Added':
		case 'Customer Created':
		case 'Address Added':
		case 'Payment Term Added':
		case 'Important Information Added':
		case 'Greenaware Return Acknowledged':
			result['icon'] = 'plus';
			result['color'] = colours.blue;
		break;
		case 'Note Deleted':
		case 'Contact Deleted':
		case 'Deleted Reminder':
		case 'Reminder Deleted':
		case 'Important Information Deleted':
		case 'Credit Note Created':
		case 'Customer Return Created':
			result['icon'] = 'minus';
			result['color'] = colours.red;
		break;
		case 'Closed':
			result['icon'] 	= 'ban';
			result['color'] = colours.red;
		break;
		case 'On Hold':
			result['icon'] 	= 'ban';
			result['color'] = '#F39C12';
		break;
		case 'Name Changed':
		case 'Snoozed Reminder':
		case 'Credit Statement Sent':
			result['icon'] 	= 'exclamation';
			result['color'] = '#F39C12';
		break;
		case 'Active - Not Verified':
			result['icon'] 	= 'check';
			result['color'] = '#F39C12';
		break;
		case 'Active - Verified':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'Customer Linked':
			result['icon'] 	= 'user-plus';
			result['color'] = colors.blue;
		break;
		case 'Customer Unlinked':
			result['icon'] 	= 'user-minus';
			result['color'] = colors.blue;
		break;
		case 'Subscribed Marketing':
		case 'Marketing Preference Added':
			result['icon'] 	= 'bullseye-pointer';
			result['color'] = colours.blue;
			break;
		case 'Unsubscribed Marketing':
		case 'Marketing Preference Removed':
			result['icon'] 	= 'bullseye-pointer';
			result['color'] = colours.red;
			break;
		default:
			break;
	}
	return result;
}

export function oriActivityLog(status){
	let result = [];
	switch (status) {
		case 'Created': 
			result['icon'] = 'star';
			result['color'] = '#F39C12';
		break;
		case 'Order Processed': 
			result['icon'] = 'plus';
			result['color'] ='#F39C12';
		break;
		case 'Note Added':
			result['icon'] = 'plus';
			result['color'] = colours.blue;
		break;
		case 'Note Deleted':
			result['icon'] = 'minus';
			result['color'] = colours.red;
		break;
		case 'Note Amended':
		case 'Amended':
			result['icon'] = 'pen';
			result['color'] = '#BDC3C7';
		break;
		case 'Recurring Stopped':
			result['icon'] = 'ban';
			result['color'] = colours.red;
		break;
		default:
			result['icon'] = 'question-circle';
			result['color'] = colours.red;
	}
	return result;
}

export function coActivityLog(status) {
	let result = [];

	switch(status){
		case 'Acknowledged Picking Notes':
			result['icon'] = IconHelper.true;
			result['color'] = colours.orange;
		break;
		case 'Order Created':
			result['icon'] = 'star';
			result['color'] = '#F39C12';
		break;
		case 'Amended Sticky Note':
		case 'Amended':
		case 'Edited Note':
		case 'Invoice Amended':
			result['icon'] = 'pen';
			result['color'] = '#BDC3C7';
		break;
		case 'Email Sent':
			result['icon'] = 'envelope';
			result['color'] = colours.blue;
		break;
		case 'Declined':
		case 'Cancelled':
			result['icon'] = 'ban';
			result['color'] = colours.red;
		break;
		case 'Completed':
			result['icon'] 	= 'trophy';
			result['color'] = '#F1C40F';
		break;
		case 'Added Sticky Note':
		case 'Added Notes':
		case 'Added Files':
			result['icon'] = 'plus';
			result['color'] = colours.blue;
		break;
		case 'Deleted Note':
		case 'Deleted File':
		case 'Deleted Sticky Note':
			result['icon'] = 'minus';
			result['color'] = colours.red;
		break;
		case 'Approved':
		case 'Confirmed':
		case 'Payment Confirmed':
		case 'Payment Made':
		case 'Picked':
		case 'Invoice Approved':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'No Despatch Note Added':
		case 'No Despatch Note':
		case 'Despatched':
			result['icon'] 	= 'truck';
			result['color'] = colours.green;
		break;
		case 'Invoice Payment Made':
			result['icon'] 	= 'pound-sign';
			result['color'] = colours.green;
		break;
		case 'Awaiting Invoice':
			result['icon'] = orderStatusIcons('Invoice');
			result['color'] = '#F39C12';
		break;
		case 'Completed Sticky Note':
			result['icon'] = 'minus';
			result['color'] = colours.blue;
		break;
		case 'Pushed Back':
			result['icon'] = 'clock-rotate-left';
			result['color'] = colours.red;
		break;
		default:
			
	}

	return result;
}

export function CreditNoteActivityLog(status){
	let result = [];
	switch(status){
		case 'Created':
			result['icon'] = 'star';
			result['color'] = '#F39C12';
		break;
		case 'Note Added':
		case 'File Uploaded':
			result['icon'] = 'plus';
			result['color'] = colours.blue;
		break;
		case 'Amended':
		case 'Note Amended':
			result['icon'] = 'pen';
			result['color'] = '#BDC3C7';
		break;
		case 'Note Deleted':
		case 'File Removed':
			result['icon'] = 'minus';
			result['color'] = colours.red;
		break;
		case 'Approved':
		case 'Confirmed':
		case 'Refund Made':
		case 'Credit Note Approved':
			result['icon'] 	= 'check';
			result['color'] = colours.green;
		break;
		case 'Declined':
		case 'Cancelled':
			result['icon'] = 'ban';
			result['color'] = colours.red;
		break;
		case 'Completed':
			result['icon'] 	= 'trophy';
			result['color'] = '#F1C40F';
		break;
		case 'Email Sent':
			result['icon'] = 'envelope';
			result['color'] = colours.blue;
		break;
		default:
			break;
	}
	return result;
}

export function engineerJobActivityLog(status){
	let result = [];
	switch(status){
		case 'Job Created':
		case 'Job Sheet Created':
		case 'Form Created':
			result['icon'] = 'star';
			result['color'] = colours.orange;
		break;
		case 'Job To Be Allocated':
			result['icon'] = 'user-helmet-safety';
			result['color'] = colors.yellow;
		break;
		case 'Form Up-issued':
			result['icon'] = 'arrow-up-to-line';
			result['color'] = colors.green;
		break;
		case 'Form Copied':
			result['icon'] = 'copy';
			result['color'] = colors.green;
		break;
		case 'Job Cancelled':
			result['icon'] = 'ban';
			result['color'] = colours.red;
		break;
		case 'Email Sent':
			result['icon'] 	= 'envelope';
			result['color'] = colours.blue;
		break;
		default:
			break;
	}
	return result;
}


export function SystemColors(){
	return colours
}

const chartColours = ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7", "#ffb400", "#d2980d", "#a57c1b", "#786028", 
	"#363445", "#48446e", "#5e569b", "#776bcd", "#9080ff", "#54bebe", "#76c8c8", "#98d1d1", "#badbdb", "#dedad2", "#e4bcad", "#df979e", "#d7658b", "#c80064",
	"#0000b3", "#0010d9", "#0020ff", "#0040ff", "#0060ff", "#0080ff", "#009fff", "#00bfff", "#00ffff"]

export function getRandomColor(){
	return chartColours[_.random(chartColours.length-1)]
}

export const colors = colours;

export function getChartColor(){
	return chartColours[_.random(chartColours.length-1)]
}