import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { closeDialog } from 'Actions/Dialog/Dialog';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { Grid, TextField, Button, FormControl } from '@material-ui/core/';

const initialState = {
    invoice:     {},
    terms:       [
        {value: 1, label: '7 Days',  days: 7},
        {value: 2, label: '14 Days', days: 14},
        {value: 3, label: '30 Days', days: 30},
        {value: 8, label: '60 Days', days: 60},
        {value: 9, label: '90 Days', days: 90},
    ],
    formData: {
        paymentTerm:    3,
        payments:       1,
        singlePayment:  0,
        endDate:        moment(),
        next:           moment(),
    },
    isLoading:   false,
    formErrors:  []
}

class PaymentTermsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.state.invoice = this.props.invoice;
    }

    componentDidMount = () => { this.calculatePayments(); }

    submit = () => {
        this.props.callback(this.state.formData);
        this.props.closeDialog();
    }

    handleSelectChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                paymentTerm: e.value
            }
        }, this.calculatePayments)
    }

    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                payments: e.target.value
            }
        }, this.calculatePayments)
    }

    calculatePayments = () => {
        const {paymentTerm, payments} = this.state.formData;
        const days  = _.find(this.state.terms, {value: paymentTerm}).days, total = parseFloat(this.state.invoice.order.order_total_incl_vat)

        this.setState({
            formData: {
                ...this.state.formData,
                singlePayment: parseFloat(total / payments).toFixed(2),
                endDate:       moment().add( payments * days ,'day'),
                next:          moment().add(days, 'day')
            }
        })

    }

    render() {
        const {invoice, terms, formErrors, formData} = this.state;
        return (
            <Grid container xs={12} spacing={1}>
                <Grid item xs={12} lg={6}>
                    <FormControl fullWidth margin="normal">
                        <AutoCompleteSelect 
                            options={terms}
                            name='paymentTerm'
                            label='Payment Terms *'
                            error={formErrors && formErrors['paymentTerm'] && true}
                            errorText={formErrors && formErrors['paymentTerm']}
                            value={formData.paymentTerm}
                            onChange={this.handleSelectChange}
                            isSearchable={false}
                            disableSort
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            name="payments"
                            label="No. Payments *"
                            value={formData.payments}
                            error={formErrors && formErrors['payments'] && true}
                            helperText={formErrors && formErrors['payments']}
                            onChange={this.handleChange}
                            margin="none"
                            fullWidth
                            type='number'
                            InputLabelProps={{shrink: true}}
                            InputProps={{inputProps: { min: 1 },}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>Order Total:</Grid>
                <Grid item xs={12} lg={6}>£{parseFloat(invoice.order.order_total_incl_vat).toFixed(2)}</Grid>
                <Grid item xs={12} lg={6}>Single Payment:</Grid>
                <Grid item xs={12} lg={6}>£{parseFloat(formData.singlePayment).toFixed(2)}</Grid>
                <Grid item xs={12} lg={6}>Next Payment:</Grid>
                <Grid item xs={12} lg={6}>{formData.next.format("DD/MM/YYYY")}</Grid>
                <Grid item xs={12} lg={6}>Last Payment:</Grid>
                <Grid item xs={12} lg={6}>{formData.endDate.format("DD/MM/YYYY")}</Grid>

                <Grid item container xs={12} style={{justifyContent: 'flex-end', marginTop:'2em'}}>
                    <Grid item>
                        <Button onClick={this.props.closeDialog} variant="outlined" >Close</Button>
                    </Grid>
                    <Grid item style={{paddingLeft: '1em'}}>
                        <Button variant="contained"  color='primary' onClick={() => {
                            this.props.deployConfirmation('Are you sure you want to set this payment term', 'Set Payment Term', this.submit)
                        }}>Save</Button>
                    </Grid>
                </Grid>
            </Grid>
               
        )
    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog:  ()                                        => dispatch(closeDialog()),
    deployConfirmation: (message, title, success, cancel)   => dispatch(deployConfirmation(message, title, success, cancel)),
})
export default connect(null, mapDispatchToProps)(PaymentTermsDialog);