import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import API from 'API';
import {
    Grid,
    Typography,
    Box,
    Link,
    Button
} from '@material-ui/core';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import { toggleDialog } from 'Functions/MiscFunctions';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import { handleDateChange } from 'Functions/FormFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import VehicleMotForm from './VehicleMotForm';
import { downloadS3File } from 'Functions/MiscFunctions';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = () => ({
    vehicleId: 0,
    motHistory: [],
    dialog: {
        mot: false,
        motId: 0,
    },
    access: {
        updateVehicle: false,
    },
    isLoading: true,
    formErrors: [],
    formData: {
        motExpiry: null
    },
    editMode: false
})

class VehicleMot extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.toggleDialog = toggleDialog.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
    }

    componentDidMount() {
        const { vehicle } = this.props;
        this.setState({
            ...this.state,
            vehicleId: vehicle?.id
        }, () => this.getAccess());
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-vehicle')
        ])
        .then(([manageRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    updateVehicle: (manageRes.data && manageRes.data.has_access) || false
                }
            }, () => this.loadComponentData());
        }) 
    }

    loadComponentData = () => {
        API.get(`/vehicles/${this.props?.vehicle?.id}/mots`)
        .then(result =>  {

            if(result.data) {
                this.setState({
                    isLoading: false,
                    motHistory: result.data
                });
            }
        });
    }

    openMotDialog = (id = 0, readOnly = false) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                mot: true,
                motId: id,
                readOnly
            },
        });
    }

    toggleMotExpiry = (submit = false) => {

        if(submit){

            API.post(`/vehicles/${this.props?.vehicle?.id}/motExpiry`, this.state?.formData)
            .then(res => {
                if(res.data.errors && res.data.errors.length > 0) {
                    this.setState({
                        formErrors: formatValidationErrors(res.data.errors)
                    });
                } else {
                    this.setState({
                        editMode: !this.state.editMode
                    }, () => {
                        this.props.deploySnackBar("success", `The mot was successfully saved`);
                        this.props.refresh();
                    })
                }
            });
        }
        else {
            this.setState({
                editMode: !this.state.editMode
            })
        }
    }

    render() {

        const { vehicleId, motHistory, dialog, isLoading, access, formData, formErrors, editMode } = this.state;
        const { activeVehicle } = this.props;
        return (
            <>
                <PaddedPaper>
                    {(isLoading && (
                        <LoadingCircle />
                    )) || (
                        <Grid container item spacing={3}>                        
                            <Grid item xs={12}>
                                <Grid container item spacing={3}>                        
                                    <Grid item xs>
                                        <Typography variant="h6" paragraph>
                                            <b>MOT History</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs align='right'>
                                        {access.updateVehicle && activeVehicle ?
                                            <Button 
                                                variant='outlined' 
                                                size="small" 
                                                onClick={() => this.openMotDialog()}
                                            >
                                                <AllIcon icon='plus' size="small" />
                                                Add MOT Record
                                            </Button>
                                        : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container item spacing={3}>                        
                                    <Grid item xs={12} md={3}>
                                        <Box 
                                            style={{
                                                textAlign: 'center', 
                                                height: '110%'
                                            }}
                                        >
                                            <PaddedPaper>
                                                <Grid container item>
                                                    {access.updateVehicle && activeVehicle ?
                                                        <>
                                                        <Grid item xs={12} align="right">
                                                            {editMode ?
                                                                <AllIcon 
                                                                    icon='times'
                                                                    button
                                                                    noMargin
                                                                    tooltip='Cancel'
                                                                    onClick={()=>{this.toggleMotExpiry()}}
                                                                />
                                                            : null }
                                                            <AllIcon 
                                                                icon={editMode ? 'check' : 'pencil-alt'}
                                                                button
                                                                noMargin
                                                                tooltip={editMode ? 'Save' : 'Edit'}
                                                                onClick={()=>{this.toggleMotExpiry(editMode ? true : false)}}
                                                            />
                                                        </Grid>
                                                        </>
                                                    : 
                                                    null }
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" gutterBottom>
                                                            MOT expires
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h5" gutterBottom>
                                                            {editMode ?
                                                                <Box style={{width: '150px', margin: '0 auto'}}>
                                                                    <DatePicker
                                                                        type="date"
                                                                        id="motExpiry"
                                                                        name="motExpiry"
                                                                        value={formData?.motExpiry}
                                                                        errorText={formErrors && formErrors['motExpiry']}
                                                                        onChange={date => this.handleDateChange('motExpiry', date, 'YYYY-MM-DD')}
                                                                        autoOk={true}
                                                                    />
                                                                </Box>
                                                            :
                                                                <b style={{color: this.props.vehicle?.motColour}}>{this.props?.vehicle?.motExpiry !== '0000-00-00' ? moment(this.props?.vehicle?.motExpiry).format('D MMMM YYYY') : '-'}</b>
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </PaddedPaper>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {motHistory.length > 0 ?
                                <>
                                <Grid item xs={12}>
                                    <DataTable  
                                        config={{
                                            key: 'id',
                                            pagination: true,
                                            alternatingRowColours: false,
                                            responsiveImportance: true,
                                            isLoading: isLoading,
                                            plainPagination: true,
                                        }}
                                        columns={[
                                            {
                                                heading: 'Date Tested',
                                                field: rowData => moment(rowData?.date).format('MMM D, YYYY'),                                   
                                                important: true,
                                                sizeToContent: true,
                                                hideInResponsiveDialog: true,
                                            },
                                            {
                                                heading: 'Result',
                                                field: rowData => rowData?.result.toUpperCase(),                                   
                                                important: true,
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: 'Mileage',
                                                field: rowData => rowData?.mileage ? rowData?.mileage?.toLocaleString() : '-',
                                                important: true,
                                            },
                                            {
                                                heading: 'Location',
                                                field: rowData => rowData?.location ? rowData?.location : '-',                                   
                                                important: true,
                                            },
                                            {
                                                heading: 'MOT Certificate',
                                                field: rowData => rowData?.filename ? 
                                                                    <Link variant="body2" className='blueLink' style={{cursor: 'pointer'}} onClick={() => {downloadS3File(rowData?.fileUrl)}}>
                                                                        View Certificate
                                                                    </Link>
                                                                    : 
                                                                    <Typography variant='caption' color='default'><em>No File</em></Typography>,                                            
                                                important: true,
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Update', icon: 'pencil-alt', onClick: () => this.openMotDialog(rowData?.id), disabled: !access.updateVehicle || !activeVehicle},
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={motHistory}
                                    />
                                </Grid>
                                </>
                            :
                                <Grid item xs={12} align="center">
                                    <Typography variant="h6" paragraph color='textSecondary'>
                                        <em>No MOT history found</em>
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    )}
                </PaddedPaper>
                <SmallFormDialog
                    open={dialog?.mot}
                    onClose={() => this.toggleDialog('mot')}
                    content={
                        <VehicleMotForm 
                            onClose={() => this.toggleDialog('mot', true)}
                            motId={dialog?.motId}
                            refresh={() => {this.toggleDialog('mot', true); this.props.refresh();}}
                            readOnly={dialog?.readOnly}
                            vehicleId={vehicleId}
                            motExpiry={this.props?.vehicle?.motExpiry}
                        />
                    }
                    maxWidth='sm'
                />
            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),

    }
}

export default connect(null, mapDispatchToProps)(VehicleMot)