import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import logo from 'Assets/Images/logo.png';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'fixed',
        top: 0,
        webkitOverflowScrolling: 'touch',
        webkitTransform: 'translateZ(0)',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: '0 4px 2px -2px rgba(0,0,0,0.2)',
    },
    container: {
        width: '100%',
        marginTop: 60
    },
    toolbar: {
        height: 68,
        minHeight: 68,
        paddingTop: 'env(safe-area-inset-top)',
        paddingRight: theme.spacing(3),
        backgroundColor: '#fff',
    },   
    logo:{
        width: 175,
        height:'auto',
        marginLeft: theme.spacing(2),
    }
}))

const MessagesHeader = () => {
    const classes = useStyles();
    return (
        <AppBar
            position="absolute"
            className={classes.appBar}
        >
            <Toolbar disableGutters={true} className={classes.toolbar}>
                <img alt="MyClenaware" className={classes.logo} src={logo} />
            </Toolbar>
        </AppBar>
    )
}

export default MessagesHeader;