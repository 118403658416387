import React  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Button,
    Grid,
    TextField,
    Typography,
    DialogActions,
    Link
} from '@material-ui/core';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { handleDateChange, getFormData, handleSelectChange, handleFileChange, handleChange, clearValue } from 'Functions/FormFunctions';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { toggleDialog } from 'Functions/MiscFunctions';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import Textarea from 'Components/Common/Inputs/Textarea';
import { downloadS3File } from 'Functions/MiscFunctions';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

const initialState = () => ({
    motId: 0,
    mot: {},
    formData: {
        date: null,
        motResult: '',
        mileage: '',
        testLocation: '',
        motCertificate: null,
        newMotExpiryDate: null,
    },
    formErrors: [],
    isLoading: true,
});

class VehicleMotForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
        this.getFormData = getFormData.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleFileChange = handleFileChange.bind(this);
        this.clearValue = clearValue.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData(); 
    }

    loadComponentData = () => {

        const { motId } = this.props;

        this.setState({
            ...this.state,
            isLoading: false,
            motId,
            formData: {
                ...this.state.formData,
                newMotExpiryDate: motId ? null : (this.props.motExpiry !== '0000-00-00' ? moment(this.props.motExpiry).add(1, 'year').format('YYYY-MM-DD') : null )
            }
        },
        () => {
            if (this.state.motId) {
                this.getMot();
            }
        });
    }

    getMot = () => {
        this.setState({
            isLoading: true,
        }, 
        () => {
            API.get(`/vehicles/${this.props.vehicleId}/mots/${this.state.motId}`)
            .then(motRes => {
    
                let mot = motRes.data;
    
                this.setState({
                    ...this.state,
                    isLoading: false,
                    mot,
                    formData: {
                        ...this.state.formData,
                        date: mot?.date,
                        motResult: mot?.result,
                        mileage: mot?.mileage,
                        testLocation: mot?.location
                    }
                });
            });
        });
    }

    handleSubmit = () => {
        const { motId } = this.state;
        const route = motId ? `/vehicles/${this.props.vehicleId}/mots/${this.state.motId}`: `/vehicles/${this.props.vehicleId}/mots`;

        this.setState({
            isLoading: true,
        }, 
        () => {
            API.post(route, this.getFormData())
            .then(res => {
                if(res.data.errors && res.data.errors.length > 0) {
                    this.setState({
                        formErrors: formatValidationErrors(res.data.errors),
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        ...initialState(),
                    }, () => {
                        this.props.deploySnackBar("success", `The mot was successfully ${motId ? 'updated' : 'added'}`);
                        this.props.refresh();
                    })
                }
            });
        });
    }

    render() {
        const { formErrors, formData, isLoading, motId, mot } = this.state;
        return (   
            <>   
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {motId ? 'Update MOT' : 'Add MOT'}
                    </Typography>
                </Grid>     
                <Grid item xs={12}>
                    <form noValidate>
                        {(isLoading && (
                            <LoadingCircle />          
                        )) || (
                            <Grid container item spacing={2}>
                                {formErrors && formErrors.generic && (
                                    <Grid item xs={12}>
                                        <Typography component={"div"} style={{color: 'red'}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <DatePicker
                                        type="date"
                                        id="date"
                                        name="date"
                                        label={"Date Tested *"}
                                        value={formData.date}
                                        errorText={formErrors && formErrors['date']}
                                        onChange={date => this.handleDateChange('date', date, 'YYYY-MM-DD')}
                                        autoOk={true}
                                        inputProps={{
                                            className:"textDefault"
                                        }}
                                        disableFuture={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AutoCompleteSelect
                                        label={"MOT Result *"}
                                        value={formData?.motResult}
                                        options={[
                                            {value: 'Pass', label: 'Pass'},
                                            {value: 'Fail', label: 'Fail'},
                                        ]}
                                        onChange={v => this.handleSelectChange('motResult', v)}
                                        noClear
                                        fullWidth
                                        error={formErrors && formErrors['motResult'] && true}
                                        errorText={formErrors && formErrors['motResult']}
                                    />
                                </Grid>
                                {formData?.motResult == 'Pass' ?
                                    <Grid item xs={12}>
                                        <TextField  
                                            name="mileage"
                                            label="Mileage *"
                                            margin="none"
                                            error={!!formErrors && formErrors['mileage'] && true}
                                            helperText={formErrors && formErrors['mileage']}
                                            value={formData.mileage || ''}
                                            onChange={this.handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                : null}
                                <Grid item xs={12}>
                                    <Textarea
                                        id="testLocation"
                                        name="testLocation"
                                        label="Location"
                                        value={formData.testLocation}
                                        onChange={this.handleChange}
                                        InputLabelProps={{
                                            shrink: !!formData.testLocation,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DragFileInput
                                        id="motCertificate"
                                        name="motCertificate"
                                        label="MOT Certificate *"
                                        file={formData?.motCertificate}
                                        errorText={formErrors && formErrors['motCertificate']}
                                        onChange={this.handleFileChange}
                                        cancelOnClick={() =>this.clearValue('motCertificate')}
                                        emptyText='No file selected'
                                    />
                                    {motId && mot?.fileUrl ?
                                        <Link className='blueLink' variant="body2" style={{cursor: 'pointer'}} onClick={() => {downloadS3File(mot?.fileUrl)}}>
                                            <div>View MOT Certificate</div>
                                        </Link>
                                    : null}
                                </Grid>
                                {!motId ?
                                    <Grid item xs={12}>
                                        <DatePicker
                                            type="date"
                                            id="newMotExpiryDate"
                                            name="newMotExpiryDate"
                                            label={"New MOT Expiry Date " + (motId ? '' : '*')}
                                            value={formData?.newMotExpiryDate}
                                            errorText={formErrors && formErrors['newMotExpiryDate']}
                                            onChange={date => this.handleDateChange('newMotExpiryDate', date, 'YYYY-MM-DD')}
                                            autoOk={true}
                                            disabled={motId && true}
                                            inputProps={{
                                                className:"textDefault"
                                            }}
                                        />
                                    </Grid>
                                : null}
                                <Grid item xs={12} align="right">
                                    <DialogActions className='pr-0 pb-0'>
                                        <Button onClick={() => this.props.onClose()} variant="text">
                                            <AllIcon icon="times" size={15} />
                                            Close
                                        </Button>
                                        <Button 
                                            onClick={() => this.props.deployConfirmation(`Are you sure you want to ${motId ? 'update' : 'add'} this MOT?`, `${motId ? 'Update' : 'Add'} MOT?`, this.handleSubmit)}
                                            variant="contained"
                                            color="primary"
                                        >
                                            {motId ? 'Update' : 'Add'}
                                        </Button>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        )}
                    </form>
                </Grid>  
            </Grid>
            </>  
        );
    }
}

const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
    }
)

export default connect(null, mapDispatchToProps)(VehicleMotForm);