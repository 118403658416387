import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    content: {
        display: 'flex',
        alignItems: 'center',
    },
});

class ViewStaffMiniProfileCard extends React.Component {
    render() {
        const { classes, staffData } = this.props;
        const fontSize = this.props.size === 'small' ? '10px' : '15px';
        const avatarSize = this.props.size === 'small' ? 50 : 80;
        const align = this.props.verical ? 'center' : '';
        const width = this.props.vertical ? '100%' : 'inherit';
        const justify = this.props.vertical ? 'center' : '';

        return (
            <React.Fragment>
                <Grid container className={classes.root} spacing={3}>
                    {!this.props.vertical && (
                        <Grid item>
                            <Avatar alt={`${staffData && staffData.staff_first_name} ${staffData && staffData.staff_last_name}`} src={staffData && staffData.staff_profile_photo} style={{height: avatarSize, width: avatarSize, margin: 0}} />
                        </Grid>
                        )}
                    <Grid className={classes.content} item xs style={{justifyContent: justify, textAlign: align, width: width}}>
                        <div>
                            {this.props.vertical && (
                                    <Avatar alt={`${staffData && staffData.staff_first_name} ${staffData && staffData.staff_last_name}`} src={staffData && staffData.staff_profile_photo} style={{height: avatarSize, width: avatarSize, margin: 0}} />
                            )}
                            {this.props.header && (                            
                                <Typography variant="caption" style={{fontSize: fontSize, fontWeight: 'bold'}}>
                                    {this.props.header}
                                </Typography>          
                            )}  
                            <Typography variant="h5" style={{fontSize: fontSize}}>
                                {staffData ? `${staffData && staffData.staff_first_name} ${staffData && staffData.staff_last_name}` : '-'}
                            </Typography>               
                            <Typography variant="body2" style={{fontSize: fontSize}}>
                                {staffData && staffData.staff_email}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </React.Fragment>                    
        )
    }
}

export default withStyles(styles)(ViewStaffMiniProfileCard);