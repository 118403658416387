import React from 'react';
import _, { each } from 'lodash';
import { connect } from 'react-redux';
import API from 'API';
import {
    Grid,
    Typography,
    Link,
    Button
} from '@material-ui/core';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import VehicleDocumentForm from './VehicleDocumentForm';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { deployDialog } from 'Actions/Dialog/Dialog';
import DataTable from 'Components/Common/DataTables/DataTable';
import { downloadS3File } from 'Functions/MiscFunctions';
import ActionMenu from 'Components/Common/ActionMenu/ActionMenu';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = () => ({
    vehicleId: 0,
    documents: [],
    access: {
        updateVehicle: false,
    },
    isLoading: true
})

class VehicleDocuments extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        const { vehicleId } = this.props;
        this.setState({
            ...this.state,
            vehicleId
        }, () => this.getAccess());
    }
    
    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-vehicle')
        ])
        .then(([updateRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    updateVehicle: (updateRes.data && updateRes.data.has_access) || false
                }
            }, () => this.loadComponentData());
        }) 
    }

    loadComponentData = () => {
        const { vehicleId } = this.state;
        API.get(`/vehicles/${vehicleId}/documents`)
        .then(res => {
            let documents = [
                {type: 'Contract Hire Agreement', fileName: '', fileUrl: ''},
                {type: 'Proposal Form', fileName: '', fileUrl: ''},
                {type: 'V5 Registration Document', fileName: '', fileUrl: ''},
                {type: 'Delivery Note', fileName: '', fileUrl: ''},
                {type: 'Returned Vehicle Note', fileName: '', fileUrl: ''},
            ];

            each(documents, (file) => {
                let found = _.find(res.data, {type: file.type});
                if(found){
                    file.id = found.id;
                    file.fileName = found.filename;
                    file.fileUrl = found.fileUrl;
                }
            })

            this.setState({
                ...this.state,
                documents,
                isLoading: false
            });
        });  
    }

    deleteDocument = (id) => {
        const { vehicleId } = this.state;
        API.put(`/vehicles/${vehicleId}/documents/${id}/delete`)
        .then(res => {
            this.props.deploySnackBar("success", `The document was successfully deleted`);
            this.loadComponentData();
        });
    }

    render() {

        const { vehicleId, documents, access, isLoading } = this.state;
        const { activeVehicle } = this.props;
        return (
            <>
                <PaddedPaper>
                    {(isLoading && (
                        <LoadingCircle />
                    )) || (
                        <Grid container item spacing={3}>                        
                            <Grid item xs={12}>
                                <Grid container item spacing={3}>                        
                                    <Grid item xs>

                                        <Typography variant="h6" paragraph>
                                            <b>Vehicle Documents</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs align='right'>
                                        {access.updateVehicle && activeVehicle && (
                                            <Button 
                                                variant='outlined' 
                                                size="small" 
                                                onClick={()=>{this.props.deployDialog(<VehicleDocumentForm vehicleId={vehicleId} callback={this.loadComponentData} type="" />,'Add Document', '')}}
                                            >
                                                <AllIcon icon='plus' size="small" />
                                                Add Document
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <DataTable  
                                    config={{
                                        key: 'id',
                                        pagination: true,
                                        alternatingRowColours: false,
                                        responsiveImportance: true,
                                        noHeader: true,
                                        dense:true
                                    }}
                                    columns={[
                                        {
                                            field: rowData => rowData?.type,
                                            main: true,
                                            sizeToContent: true,
                                            important: true,
                                            hideResponsiveDisplay: true
                                        },
                                        {
                                            field: rowData => rowData?.fileName ? 
                                                                <Link variant="body2" className='blueLink' style={{cursor: 'pointer'}} onClick={() => {downloadS3File(rowData?.fileUrl)}}>
                                                                    {rowData?.fileName}
                                                                </Link>
                                                                : 
                                                                <Typography variant='caption' color='default'><em>No File</em></Typography>,                                            
                                            important: true,
                                        },
                                        {
                                            field: rowData => 
                                                <ActionMenu
                                                    rows={[
                                                        {
                                                            icon: "trash-alt",
                                                            label: "Delete Document",
                                                            onClick: () => this.props.deployConfirmation('Are you sure you want to delete this document?', 'Delete Document?', () => this.deleteDocument(rowData.id)),
                                                            display: (rowData.id && access?.updateVehicle && activeVehicle) && true
                                                        },
                                                        {
                                                            icon: "pencil-alt",
                                                            label: "Replace Document",
                                                            onClick: () => {this.props.deployDialog(<VehicleDocumentForm vehicleId={vehicleId} callback={this.loadComponentData} type={rowData?.type} />,'Add Files', '')},
                                                            display: access?.updateVehicle && activeVehicle ? true : false
                                                        }
                                                    ]}
                                                />,
                                            alignment: 'right',
                                        }
                                    ]}
                                    rows={documents}
                                />
                            </Grid>
                        </Grid>
                    )}
                </PaddedPaper>
            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployDialog:       (content, title, variant, size='sm')    => dispatch(deployDialog(content, title, variant, size)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),

    }
}

export default connect(null, mapDispatchToProps)(VehicleDocuments)