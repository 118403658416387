import React from 'react';
import Typography from '@material-ui/core/Typography';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import API from '../../../API';
import _ from 'lodash';
import DataTable from 'Components/Common/DataTables/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import { connect } from 'react-redux';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import {statusColour} from './../../../Helpers/ColourHelper'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import { downloadS3File } from 'Functions/MiscFunctions';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';
import { Alert } from '@material-ui/lab';

const initialState = () => ({
    formData: {
        bank: '',
        file: ''
    },
    formErrors: {},
    access: {
        bankTransactions: false,
        runningTotals: false
    },
    bankList: [],
    statements: [],
    unassignedTransactions: [],
    isLoading: false,
    completeDialogOpen: false,
    dialogData: {}
});

const styles = theme => ({
    settingsHeading: {
        paddingTop: theme.spacing(2)
    }
});
class BankSearch extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'accounts:bankSearch';
        this.state              = this.getInitialState(initialState());
    }
    componentDidMount(){
        this.setQuery();
        this.getBankAccounts();
        this.checkAccess();        
        if (this.hasPageState()) this.handleSelectChange('bank')({value:this.state.formData.bank});
    }

    setQuery = () => {
        const { state = {} } = this.props.location;
        this.setState({
            formData: {
                ...this.state.formData,
                bank: state?.formData?.bank || initialState().formData.bank,
            }
        },
        () => {
            this.getBankMonthlyStatements();
            this.getUnassignedTransactions();
        });
    };

    getBankAccounts = () => {
        API.get('/accounts/banks')
        .then((bankRes) => {
            let bankList = _.map(bankRes.data, (el) => {
                return _.assign({
                    value: el.bank_id,
                    label: el.bank_name
                });
            });
            this.setState({
                bankList: bankList,
                isLoading: false
            });
        });
    };

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/bank-transactions'),
            API.get('/staff/my/access/check/bank-running-totals'),
        ])
        .then(([tranRes, totalsRes]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    bankTransactions: tranRes.data.has_access,
                    runningTotals: totalsRes.data.has_access,
                }
            });
        });
    }

    handleSelectChange = fieldName => selectedValue => {
        if(selectedValue && selectedValue.value){
            this.setState({
                formData: {
                    ...this.state.formData,
                    [fieldName]: selectedValue && selectedValue.value
                }
            }, 
            () => {
                if(fieldName === 'bank'){
                    this.getBankMonthlyStatements();
                    this.getUnassignedTransactions();
                }
                this.savePageState();
            });
        } else {
            this.props.history.replace();
            this.setState({
                formData: {
                    ...this.state.formData,
                    bank: initialState().formData.bank
                }
            }, () => {
                this.savePageState()
            });
        }
    }

    getBankMonthlyStatements = () => {
        let bank_id = this.state.formData?.bank;
        if(bank_id) {
            this.setState({
                isLoading: true
            }, 
            () => {
                API.get(`/accounts/banks/${bank_id}/monthlyStatements`)
                .then(res => {
                    if(res.data){
                        this.setState({
                            statements: res.data,
                            isLoading: false
                        });
                    }
                });
            });
        }
    }

    getUnassignedTransactions = () => {
        let bank_id = this.state.formData?.bank;
        if(bank_id) {
            API.get(`/accounts/banks/${bank_id}/unassignedTransactionCounts`)
            .then(res => {
                if(res.data){
                    this.setState({
                        unassignedTransactions: res.data
                    });
                }
            });
        }
    }

    handleDialogClose = () => {this.setState({completeDialogOpen: false});};
    handleDialogOpen = rowData => {
        this.setState({
            completeDialogOpen: true,
            dialogData: rowData
        });
    };
    handleDialogSuccess = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            newFormData.append(key, this.state.formData[key]);
        });
        
        API.post(`/accounts/banks/monthlyStatements/${this.state.dialogData.bms_id}/completeMonth`, newFormData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    completeDialogOpen: true
                });
            }
            else {
                let bank_id = this.state.dialogData.bms_bank_id;
                this.props.deploySnackBar("success", "You have successfully completed this month");
                this.setState({
                    ...initialState(),
                    formData: {
                        ...this.state.formData,
                        bank: bank_id
                    }
                },
                () => {
                    this.getBankAccounts();
                    this.checkAccess();
                    this.getBankMonthlyStatements();
                    this.getUnassignedTransactions();
                });
            }
        });
    }

    // Files
    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }
    clearFile = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                file: initialState().formData.file
            }
        });
    };

    render() {
        const { isLoading, formErrors, formData, bankList, statements, dialogData, unassignedTransactions, access } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Bank / Card Search
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Alert severity="info">
                        <Typography>
                            Plaid integration removed on 10th June 2024 - no new transactions will be imported.
                        </Typography>
                    </Alert>
                </Grid>
                {isLoading ? (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                ) : (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Select Bank / Card
                                        </Typography>
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                options={bankList}
                                                name='bank'
                                                label='Bank / Card *'
                                                error={formErrors && formErrors['bank'] && true}
                                                errorText={formErrors && formErrors['bank']}
                                                value={formData.bank}
                                                onChange={this.handleSelectChange('bank')}
                                            />
                                        </FormControl>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </Grid>
                        {formData.bank &&
                            <>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Transactions
                                        </Typography>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align='center'>Awaiting Authorisation</TableCell>
                                                    <TableCell align='center'>Awaiting Confirmation</TableCell>
                                                    <TableCell align='center'>Awaiting Assignment</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align='center'>
                                                        {unassignedTransactions.authCount}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {unassignedTransactions.confCount}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {unassignedTransactions.completeCount}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <div className='buttonRow'>
                                            <Link to={{ pathname: `/accounts/unassigned-bank-transactions/`+formData.bank, state: {formData}}}>
                                                <Button variant="outlined" size="small">View Unassigned Transactions</Button>
                                            </Link> 
                                        </div>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    {access.bankTransactions &&
                                        <PaddedPaper>
                                            <Typography variant="h6">
                                                Payments
                                            </Typography>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align='center'>Awaiting Confirmation</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align='center'>
                                                            {unassignedTransactions.paymentCount}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <div className='buttonRow'>
                                                <Link to={{ pathname: `/accounts/bank-payments/`+formData.bank, state: {formData}}}>
                                                    <Button variant="outlined" size="small">View Payments</Button>
                                                </Link> 
                                            </div>
                                        </PaddedPaper>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {access.bankTransactions &&
                                    <PaddedPaper>
                                        {access?.runningTotals &&
                                            <div className='buttonRow'>
                                                <Link to={{ pathname: `/accounts/bank-running-totals/`+formData.bank, state: {formData}}}>
                                                    <Button variant="outlined" size="small">View Running Totals</Button>
                                                </Link> 
                                            </div>
                                        }
                                        <Typography variant="h6">
                                            Monthly Statements
                                        </Typography>
                                        <br></br>
                                        <DataTable  
                                            config={{
                                                key: 'bms_id',
                                                responsiveImportance: true,
                                                alternatingRowColours: true,
                                                isLoading: isLoading
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Month',
                                                    field: rowData => rowData.bms_month,
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Year',
                                                    field: rowData => rowData.bms_year,
                                                },
                                                {
                                                    heading: 'Total Transactions',
                                                    field: rowData => rowData.completeCount,
                                                    alignment: 'right'
                                                },
                                                {
                                                    heading: 'Total Spend',
                                                    field: rowData => rowData.bms_status == 'Complete' ?  '£'+rowData.bms_total_spend : (rowData.totalSpend ? '£'+rowData.totalSpend : '-'),
                                                    alignment: 'right'
                                                },
                                                {
                                                    heading: 'Status',
                                                    field: rowData => rowData.bms_status,
                                                    cellProps: rowData => {
                                                        return ({
                                                            style: {
                                                                color: statusColour(rowData.bms_status)
                                                            }
                                                        });
                                                    },
                                                },
                                                {
                                                    actions: rowData => {
                                                        return [
                                                            {name: 'View', icon: 'search', link: { pathname: '/accounts/bank-transactions/' + rowData.bms_id, state: {formData}}},
                                                            {name: 'Complete Month', icon: 'check', onClick: () => this.handleDialogOpen(rowData), disabled: rowData.bms_status != 'Open'},
                                                            {name: 'Monthly Statement', icon: 'file-alt', onClick: () => downloadS3File(rowData.statement_file_url), disabled: !rowData.bms_monthly_statement_filename}
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={statements}
                                        />
                                    </PaddedPaper>
                                }
                            </Grid>
                            </>
                        }
                        <Dialog 
                            open={this.state.completeDialogOpen} 
                            onClose={this.handleDialogClose} 
                            fullWidth={true} 
                            maxWidth="sm" 
                            scroll="body"
                        >
                            <DialogTitle>
                                <Typography>Complete The Month - {dialogData.bms_month} {dialogData.bms_year}</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <DragFileInput
                                    id="file"
                                    name="file"
                                    label="Upload Monthly Statement *"
                                    file={formData.file}
                                    errorText={formErrors && formErrors['file']}
                                    onChange={this.handleFileChange}
                                    cancelOnClick={this.clearFile}
                                    emptyText='No file selected'
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleDialogClose} variant="outlined" color="default">Close</Button>
                                <Button onClick={this.handleDialogSuccess} autoFocus variant="contained" color="primary">Confirm</Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deploySnackBar:     (variant, msg)  => dispatch(deploySnackBar(variant, msg)),
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BankSearch));