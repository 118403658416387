import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, TextField, Typography, Box } from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import InfoIcon from 'Components/Common/Icons/InfoIcon';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import InfoBox from 'Components/Common/reports/InfoBox';
import { colors } from 'Helpers/ColourHelper';
import { checkEmails } from 'Helpers/EmailHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import IconHelper from 'Helpers/IconHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { parseRow } from 'Helpers/CsvHelper.js'
import { checkMailingListContacts } from 'Helpers/EmailHelper';

const initialState = (props = null) => ({
    isLoading: true,
    contacts: [],
    contactBreakdown: {
        all: [],
        eligible: [],
        newContacts: [],
        inListDuplicate: [],
        faultyEmails: [],
        unsubed: [],
        accounts: [],
        warnings: [],
        outListDuplicate: [],
    },
    showCheckContactsDialog: false,
});

class AddAutomatedContactDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getContacts();
    }

    getContacts = () => {
        API.get(`/marketing/campaigns/mailingLists/eligibleContacts`, {params: {logicType: this.props.mailingList.t, logicData: this.props.mailingList.l, breakdown: 1}}).then(res => {
            this.setState({
                contacts: res.data.contacts, 
                contactBreakdown: {
                    ...this.state.contactBreakdown,
                    contacts: res.data.contacts,
                }
            }, this.checkEligibleContacts);
        })
    }

    checkEligibleContacts = async () => {        
        let breakdown = await checkMailingListContacts(_.filter(this.state.contacts, c => 
                                                            !_.find(this.props.mailingList.c.c, {e: c.contact_email}) &&
                                                            !_.find(this.props.mailingList.c.r, {e: c.contact_email}) &&
                                                            !_.find(this.props.mailingList.c.o, {e: c.contact_email}) 
                                                        ));
        this.setState({
            breakdown: breakdown,
            isLoading: false,
        });
    }

    showCheckContacts = () => {
        this.setState({
            showCheckContactsDialog: true,
        })
    }

    render() {
        const { isLoading, breakdown, showCheckContactsDialog } = this.state;

        if (isLoading) {
            return (
                <LoadingCircle/>
            )
        }

        return (
            <Box>
                <Grid container spacing={2}>
                    <InfoBox
                        rowSize={3}
                        icon={IconHelper.customers}
                        value={breakdown.contacts.length}
                        title="Contacts"
                    />
                    <InfoBox
                        rowSize={3}
                        icon={IconHelper.plus}
                        value={breakdown.toAdd.length}
                        title="Eligible"
                    />
                    <InfoBox
                        rowSize={3}
                        icon={IconHelper.false}
                        value={_.sumBy(['na', 'invalid', 'duplicate', 'unsubed'], r => breakdown[r]?.length ?? 0)}
                        title={<>Not Eligible <InfoIcon info='This includes; unsubscribed contacts, accounts contacts, invalid email addresses, and duplicate email addresses.'/></>}
                    />
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant="outlined"
                            onClick={this.props.closeDialog}
                        >
                            Close
                        </Button>
                        {
                            breakdown.toAdd.length !== breakdown.contacts.length &&
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.showCheckContacts}
                            >Check Contacts</Button>
                        }
                        {
                            breakdown.contacts.length > 0 &&
                            <>
                                {breakdown.toAdd.length > 0 &&
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Preview Eligible Contacts 
                                    </Button>
                                }
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={breakdown.toAdd.length !== breakdown.contacts.length}
                                >
                                    Add
                                </Button>
                            </>
                        }
                        {
                            breakdown.contacts.length === 0 &&
                            <Button
                                variant="contained"
                                color="primary"
                            >
                                Mark as Checked
                            </Button>
                        }
                    </Grid>
                </Grid>
                {/*showCheckContactsDialog &&
                    <CSVContactsDialog
                        open={showCheckContactsDialog}
                        eligibleContacts={eligibleContacts}
                        contacts={lists.contacts}
                        onSubmit={this.onContactsDialogSubmit}
                    />
                    */}
            </Box>
        )

    }

}

const mapDispatchToProps = (dispatch) => ({
    closeDialog: () => dispatch(closeDialog()),
})

export default connect(null, mapDispatchToProps)(AddAutomatedContactDialog);