import React       from 'react';
import ReactHowler from 'react-howler';
import { connect } from 'react-redux';
import uuidv4      from 'uuid/v4';

import MUIStylesProvider from '@material-ui/styles/ThemeProvider';

import { stopSound } from 'Actions/Sounds/Sounds';
import { deploySnackBar }                         from 'Actions/SnackBar/SnackBar';
import { handleAppError, handleUserUnauthorized } from 'Actions/UI/UI';
import { WildixProvider } from "Context/WildixContext";

import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import Notification       from 'Components/Common/Notifications/Notification';
import MessageDrawer      from 'Components/Messages/MessageDrawer';
import Dialog             from 'Components/Common/Dialogs/Dialog';
import PrintDialog        from 'Components/Common/Dialogs/PrintDialog';
import ErrorDialog        from 'Components/Common/ErrorHandling/ErrorDialog';
import SnackBar           from 'Components/Common/SnackBars/SnackBar';

import RequireAuth         from '../../RequireAuth';
import theme               from '../../Theme/Theme';
import AdminContentWrapper from './AdminContentWrapper';
import AdminFooter         from './AdminFooter';
import AdminHeader         from './AdminHeader';
import AdminNav            from './AdminNav';

const initialState = {
    isMobile: false,
    navOpen: true,
    drawers: {
        chat: false
    },
    pageTitle: '',
    pagePath: '',
    appError: {
        key: uuidv4(),
        detail: "",
        state: false,
    },
    snackbar: {
        key: uuidv4(),
        message: "",
        variant: "",
        state: false
    },
    popNotification: {
        key:        uuidv4(),
        data:       [],
        state:      false
    },
    dialog: {
        key: uuidv4(),
        dialogOpen: false,
        dialogContent: '',
        dialogTitle: '',
        dialogType: ''
    },
    confirmation: {
        key: uuidv4(),
        open: false,
        message: '',
        title: '',
        variant: ''
    },
    print: {
        key:     uuidv4(),
        open:    false,
        printer: '',
        url:     '',
        file:    null,
        multi:   []
    }
}

class AdminLayout extends React.PureComponent {
    constructor() {
        super();
        this.state = initialState;
    }

    componentDidMount(){
        this.setState({
            isMobile: this.props.ui.device.isMobile,
            navOpen: this.props.ui.device.isMobile ? false : true,
            pagePath: this.props.history.location.pathname,
            appError: {
                ...this.state.appError,
                key: uuidv4(),
                detail: this.props.ui.appError.detail,
                state: this.props.ui.appError.state
            },
            snackbar: {
                ...this.state.snackbar,
                key: uuidv4(),
                message: this.props.snackbar.message,
                variant: this.props.snackbar.variant,
                state: this.props.snackbar.message.length > 0 ? true : false
            },
            dialog: {
                ...this.state.dialog,
                key: uuidv4(),
                dialogOpen:     this.props.dialog.dialogOpen,
                dialogContent:  this.props.dialog.dialogContent,
                dialogTitle:    this.props.dialog.dialogTitle,
                dialogVariant:  this.props.dialog.dialogVariant,
                dialogSize:     this.props.dialog.dialogSize,
                dialogStyle:    this.props.dialog.dialogStyle
            },
            confirmation: {
                key: uuidv4(),
                open: false,
                message: '',
                title: '',
                close: () => {},
                success: () => {},
            }, 
        });
    }

    componentDidUpdate(prevProps){
        if(this.props.ui.device.isMobile !== prevProps.ui.device.isMobile) {
            this.setState({
                isMobile: this.props.ui.device.isMobile,
                navOpen: this.props.ui.device.isMobile ? false : true,
            });
        }
        if(this.props.ui.appError.state !== prevProps.ui.appError.state) {
            this.setState({
                appError: {
                    ...this.state.appError,
                    key: uuidv4(),
                    state: this.props.ui.appError.state,
                    detail: this.props.ui.appError.detail
                }
            });
        }
        if(this.props.snackbar.message !== prevProps.snackbar.message) { 
            this.setState({
                snackbar: {
                    ...this.state.snackbar,
                    key: uuidv4(),
                    message: this.props.snackbar.message,
                    variant: this.props.snackbar.variant,
                    state: this.props.snackbar.message.length > 0 ? true : false
                }
            });
        }
        if (this.props.popNotification.key != prevProps.popNotification.key ){
            this.setState({
                popNotification: {
                    ...this.state.popNotification,
                    key: uuidv4(),
                    data:  this.props.popNotification?.data,
                    state: this.props.popNotification?.data.length ? true : false
                }
            });
        }
        if(this.props.dialog.dialogContent !== prevProps.dialog.dialogContent) {
            this.setState({
                dialog: {
                    ...this.state.dialog,
                    key:            uuidv4(),
                    dialogOpen:     this.props.dialog.dialogOpen,
                    dialogContent:  this.props.dialog.dialogContent,
                    dialogTitle:    this.props.dialog.dialogTitle,
                    dialogVariant:  this.props.dialog.dialogVariant,
                    dialogSize:     this.props.dialog.dialogSize,
                    dialogStyle:    this.props.dialog.dialogStyle,
                    disableDrag:    this.props.dialog.disableDrag
                }
            });
        }
        if(this.props.confirmation.message !== prevProps.confirmation.message) {
            this.setState({
                confirmation: {
                    ...this.state.confirmation,
                    key: uuidv4(),
                    open: this.props.confirmation.open,
                    message: this.props.confirmation.message,
                    title: this.props.confirmation.title,
                    close: this.props.confirmation.close,
                    success: this.props.confirmation.success,
                    variant: this.props.confirmation.variant
                }
            });
        }
        if(this.props.print.key !== prevProps.print.key) { 
            this.setState({
                print: {
                    ...this.state.print,
                    key: uuidv4(),
                    open:     this.props.print.open,
                    printer:  this.props.print.printer,
                    url:      this.props.print.url,
                    file:     this.props.print.file,
                    multi:    this.props.print.multi,
                    fileName: this.props.print.fileName
                }
            });
        }
    }

    openNav = () => {
        if(this.state.navOpen === false) {
            this.setState({
                navOpen: true
            });
        }
    }

    closeNav = () => {
        if(this.state.navOpen === true) {
            this.setState({
                navOpen: false
            })
        }
    }

    changePage = (title, path) => {
        if(this.props.ui.unauthorized) {
            this.props.handleUserUnauthorized(false);
        }
        if(title !== this.state.pageTitle) {
            this.setState({
                pageTitle: title
            });
        } else {
            if(path === this.props.location.pathname) {
                if(this.state.isMobile === true) {
                    if(this.state.navOpen === true) {
                        this.closeNav();
                    }
                }
            }
        }
    }

    changePath = path => {
        if(path !== this.state.pagePath){
            this.setState({
                navOpen: this.state.isMobile === true ? false : this.state.navOpen,
                pagePath: path,
            });
        } else { 
            this.setState({
                navOpen: this.state.isMobile === true ? false : this.state.navOpen
            });
        }
    }

    handleSnackbarClose = () => {
        this.props.deploySnackBar("success", "")
    }

    handleDialogClose = () => {
        this.setState({
            dialog: {
                ...initialState.dialog,
                key: uuidv4()
            }
        });
    }

    toggleDrawer = type => {
        this.setState({
            drawers: {
                ...this.state.drawers,
                [type]: !this.state.drawers[type]
            }
        });
    }

    render() {
        const { sound, stopSound } = this.props;
        const { appError, drawers, snackbar, dialog, confirmation, popNotification, print } = this.state;
        return (
            <WildixProvider>
                <MUIStylesProvider theme={theme}>
                    <AdminHeader    
                        history={this.props.history}
                        location={this.props.location}
                        navOpen={this.state.navOpen}
                        toggleDrawer={this.toggleDrawer}
                        openNav={this.openNav}
                        changePage={this.changePage}
                        pageTitle={this.state.pageTitle}
                        updateNotification={this.props.ui.updateNotification}
                    />
                    <AdminNav   
                        history={this.props.history}
                        navOpen={this.state.navOpen}
                        closeNav={this.closeNav}
                        openNav={this.openNav}
                        changePage={this.changePage}
                        pagePath={this.state.pagePath} 
                        scrollHeight={this.props.ui.device.height}
                    />
                    <MessageDrawer   
                        open={drawers.chat}
                        toggleDrawer={this.toggleDrawer}
                    />
                    <AdminContentWrapper    
                        changePath={this.changePath}     
                        changePage={this.changePage}    
                        pagePath={this.state.pagePath}
                        scrollHeight={this.props.ui.device.height}
                        updateNotification={this.props.ui.updateNotification}
                        unauthorized={this.props.ui.unauthorized}
                    />
                    <AdminFooter/>
                    <Dialog 
                        key=                {dialog.key}
                        dialogOpen=         {dialog.dialogOpen}
                        dialogContent=      {dialog.dialogContent}
                        dialogTitle=        {dialog.dialogTitle}
                        dialogVariant=      {dialog.dialogVariant}
                        dialogSize=         {dialog.dialogSize}
                        dialogStyle=        {dialog.dialogStyle}
                        disableDrag=        {dialog.disableDrag}
                        handleDialogClose=  {this.handleDialogClose}
                        disableBackdropClick
                        disableEscapeKeyDown
                    />
                    <ConfirmationDialog 
                        open={confirmation.open} 
                        success={confirmation.success} 
                        close={confirmation.close} 
                        message={confirmation.message}
                        title={confirmation.title}
                        variant={confirmation.variant}
                    /> 
                    <SnackBar
                        key={snackbar.key}
                        autoHideDuration={3000}
                        variant={snackbar.variant}
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={snackbar.state}
                        onClose={this.handleSnackbarClose}
                        message={snackbar.message}
                    />
                    <Notification 
                        key={popNotification.key}
                        autoHideDuration={3000}
                        anchorOriginVertical='top'
                        anchorOriginHorizontal='right'
                        open={popNotification.state}
                        data={popNotification.data}
                    />
                    {print.open &&
                        <PrintDialog
                            key={print.key}
                            url={print.url}
                            file={print.file}
                            printer={print.printer}
                            multi={print.multi}
                            fileName={print.fileName}
                        />
                    }
                    {appError.state && (
                        <ErrorDialog
                            key={appError.key}
                            state={appError.state}
                            detail={appError.detail}
                            history={this.props.history}
                            closeError={() => this.props.handleAppError(false, "")}
                        />
                    )}
                    {sound.src !== null && (
                        <ReactHowler
                            src={sound.src}
                            playing={sound.status === 'playing'}
                            onEnd={stopSound}
                        />
                    )}
                </MUIStylesProvider>
            </WildixProvider>
        );
    }
}

function mapStateToProps(state){
    return {
        snackbar:           state.snackbar,
        popNotification:    state.popNotification,
        confirmation:       state.confirmation,
        dialog:             state.dialog,
        sound:              state.sound,
        ui:                 state.ui,
        print:              state.print
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError:         (error, message)    => dispatch(handleAppError(error, message)),
        handleUserUnauthorized: (pathname)          => dispatch(handleUserUnauthorized(pathname)),
        deploySnackBar:         (variant, message)  => dispatch(deploySnackBar(variant, message)),
        stopSound:              ()                  => dispatch(stopSound())
    };
}
export default RequireAuth(connect(mapStateToProps, mapDispatchToProps)(AdminLayout));