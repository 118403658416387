import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';
import uuidv4               from 'uuid/v4';

import { Button, Grid, TextField } from '@material-ui/core/';

import icons                      from 'Helpers/IconHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import { closeDialog } from 'Actions/Dialog/Dialog';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import Textarea           from 'Components/Common/Inputs/Textarea';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import DragFileInput      from 'Components/Common/Inputs/DragFileInput';

import MapMarker from './MapMarker';
import TargetMap from './TargetMap';

const initialState = {
    formData: {
        lng:      '',
        lat:      '',
        name:     '',
        notes:    '',
        category: 0,
        address:  '',
        file:     ''
    },
    formErrors: {},
    error: null,
    isLoading: true,
    catagories: [],
    mapUuid:    '',
}

class NewMarkerDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {...initialState, formData: {...initialState.formData, lng: props.lng, lat: props.lat}};
    }

    componentDidMount() {
        this.getCatagories();
    }

    getCatagories(){
        API.get('/engineers/maps/categories')
        .then(res => {
            this.setState({ 
                catagories: _.map(res.data, i => ({
                    value: i.mmc_id,
                    label: `${i.mmc_category} | ${i.mmc_name}`,
                    color: i.mmc_color,
                    icon:  i.mmc_icon
                })), 
                isLoading: false 
            });
        })
    }

    handleSelectChange = name => e => {
        let value = e ? e.value : initialState.formData[name];
        this.setState({formData: {...this.state.formData, [name]: value}}); 
    }

    handleChange = name => e => {
        let value = e.target.value;
        this.setState({formData: {...this.state.formData, [name]: value}});
    }

    newMarker = () => {

        this.setState({isLoading: true}, () => {
            let newFormData = new FormData();
            Object.keys(this.state.formData).forEach(key => {
                newFormData.append(key, this.state.formData[key]);
            });

            API.post('/engineers/maps/markers', newFormData)
            .then(res => {
                if(res.data.errors && res.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(res.data.errors),
                        isLoading: false
                    });
                } else {
                    this.props.onMarkerCreate && this.props.onMarkerCreate();
                    this.props.closeDialog();
                }
            });
        });
    }

    setLngLat = (lng, lat) => {
        this.setState({formData: {...this.state.formData, lng, lat}});
    }

    getAddress = () => {
        API.get('/misc/address/latlng', {
            params: {
                address: this.state.formData.address
            } 
        })
        .then(result => {

            this.setState({
                formData: {
                    ...this.state.formData, 
                    lng: result.data.lng, 
                    lat: result.data.lat
                },
                error: result.data.result || result.data.res ? null : 'Address not found',
                mapUuid: uuidv4()
            });
        });
    }

    handleFileChange = field => (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: file
            }
        })
    }

    clearFile = field => () => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: ''
            }
        })
    }

    render(){

        const { formData:{lng, lat, name, notes, category, address, file} , isLoading, catagories, formErrors} = this.state;

        if (isLoading) return (<LoadingCircle/>);

        return (
            <Grid container spacing={3} style={{justifyContent: 'space-evenly'}}>
                <Grid item container xs={6} spacing={1} style={{alignItems: 'center'}}>
                    <Grid item xs={12}>
                        <Grid style={{display: 'flex'}}>
                            <TextField 
                                placeholder='Search for a location by address or what 3 words'
                                fullWidth
                                value={address}
                                onChange={this.handleChange('address')}
                                error={this.state.error}
                                helperText={this.state.error}
                            />
                            <AllIcon icon={icons.search} noMargin onClick={this.getAddress} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} key={`${lat}${lng}`}>
                        <TargetMap
                            key={this.state.mapUuid}
                            lat={parseFloat(lat)}
                            lng={parseFloat(lng)}
                            height={250}
                            setLngLat={this.setLngLat}
                            marker={<MapMarker icon={_.find(catagories, {value: category})?.icon} color={_.find(catagories, {value: category})?.color} size={30}/>}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label='Latitude'
                            value={lat || ''}
                            type='number'
                            inputProps={{
                                max: 180,
                                min: -180,
                            }}
                            onChange={(e) => { this.setLngLat(lng, parseFloat(e.target.value)) }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label='Longitude'
                            value={lng || ''}
                            type='number'
                            inputProps={{
                                max: 180,
                                min: -180,
                            }}
                            onChange={(e) => { this.setLngLat(parseFloat(e.target.value), lat) }}
                        />
                    </Grid>
                </Grid>
                {/*<Grid item style={{width: 120}}>
                    <MapMarker icon={_.find(catagories, {value: category})?.icon} color={_.find(catagories, {value: category})?.color} size={100}/>
        </Grid>*/}
                <Grid item xs={6}>
                    <PaddedPaper style={{height: '100%', padding: 16}}>
                        <AutoCompleteSelect
                            fullWidth
                            label='Category | Type'
                            options={catagories}
                            value={category}
                            onChange={this.handleSelectChange('category')}
                        />
                        <TextField margin='none' fullWidth value={name} label='Name' onChange={this.handleChange('name')} style={{marginTop: 0}}/>
                        <Textarea margin='none' fullWidth value={notes} label='Notes' onChange={this.handleChange('notes')}/>
                        <DragFileInput 
                            id="file"
                            name='file'
                            label="Upload Image:"
                            file={file}
                            onChange={this.handleFileChange('file')}
                            cancelOnClick={this.clearFile('file')}
                            emptyText="No image selected"
                            error={formErrors.file}
                            errorText={formErrors.file}
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                    <Button variant='contained' color='primary' onClick={this.newMarker} disabled={!category || !name || name === '' || (!lat || !lng) }>Save</Button>
                </Grid>
            </Grid>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return {
        closeDialog: () => {dispatch(closeDialog())}
    }
}

export default connect(null, mapDispatchToProps)(NewMarkerDialog);