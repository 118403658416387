import React from 'react';

import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import { ReduxAvatar } from 'Components/Common/Badges/Avatar';

const StaffMember = props => {
    const { border, className, content, disabled, staff, handleAction } = props;

    if(staff) {
        return (
            <ListItem 
                className={className ? className : (border ? "sm-list-item" : "sm-list-item-borderless")}
                disableGutters
                onClick={() => { if(!disabled && handleAction) { handleAction(); }}}
                button={!disabled && handleAction ? true : false}
            >
                <Grid container spacing={2} alignItems='center'>
                    {(content && (
                        <React.Fragment>
                            <Grid item xs={12} className='gap-2' />
                            <Grid item xs={12} align='center'>
                                {ReduxAvatar(staff, {size:'md'})}
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Typography variant="subtitle1" style={{color: staff?.troll === 1 ? '#D81B60' : undefined}}>
                                    {staff.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} align='center'>
                                {content}
                            </Grid>
                        </React.Fragment>
                    )) || (
                        <React.Fragment>
                            <Grid item>
                                {ReduxAvatar(staff, {size:'md'})}
                            </Grid>
                            <Grid item xs>
                                <Typography variant="subtitle1" style={{color: staff?.troll === 1 ? '#D81B60' : undefined}}>
                                    {staff.name}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </ListItem>
        )

    } else {
        return <React.Fragment />
    }    
}

export default StaffMember;