import React, { Component } from 'react';
import moment               from 'moment';

import DataTable from 'Components/Common/DataTables/CiDataTable';

class StatusDataTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { searchResults, actions, status, isLoading } = this.props;
        return (
            <DataTable  
                config={{
                    key: 'po_id',
                    pagination: true,
                    alternatingRowColours: true,
                    isLoading: isLoading
                }}
                columns={[
                    {
                        heading: 'Purchase Order Date',
                        field: rowData => moment(rowData.po_date).format("DD/MM/YYYY"),
                        sizeToContent: true
                    },
                    {
                        actionsCustomHeader:    true,
                        sizeToContent:          true,
                        alignment:              'left',
                        heading:                'Purchase Order Number',
                        actions: rowData => {
                            return [
                                {label: rowData.po_number + '-' + rowData.po_number_amendment, link: `/purchase-order/view/${rowData.po_id}`, type:'plainLink',}
                            ]
                        }
                    },
                    {
                        heading: 'Company Name',
                        field: rowData => rowData.po_supplier_name,
                        truncate: true
                    },
                    {
                        heading: status === 'Declined' ? 'Declined By' : 'Placed By',
                        field: rowData => rowData.po_placed_by,
                        sizeToContent: true
                    },
                    {
                        heading: 'Total Price',
                        field: rowData => parseFloat(rowData.po_total_price).toFixed(2),
                        fieldPrefix: '£',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        actions: actions
                    }
                ]}
                rows={searchResults}
            />
        );
    }
}

export default StatusDataTable;
