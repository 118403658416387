import { Box, Button, Dialog, DialogActions, DialogContent, Grid, InputAdornment, MenuItem, Select, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import AllIcon from "Components/Common/Icons/AllIcon";
import CheckBox from "Components/Common/Inputs/CheckBox";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import { colors } from "Helpers/ColourHelper";
import IconHelper from "Helpers/IconHelper";
import InputHelper from "Helpers/InputHelper";
import { clenyDate } from "Helpers/TimeHelper";
import _ from "lodash";
import React from "react";
import './searchBoxCss.css';

const initialState = (props) => ({
    formData: {
        search: props?.formData?.search ?? '',
        read:  props?.formData?.read ?? 'all',

        //advanced search
        from: 'all',
        fromEmail: 'all',
        hasAttachment: false,
        cc: false,
        dateFrom: null,
        dateTo: null,
        //TODO: add advanced search
    },
    tempFormData: {
        show: false,
        from: 'all',
        fromEmail: 'all',
        hasAttachment: false,
        cc: false,
        dateFrom: null,
        dateTo: null,
    },
})

const FilterTag = ({label, onDelete}) => (
    <Grid item
        style={{
            padding: '3px 6px', 
            backgroundColor: colors.white, 
            color: colors.primary,
            borderRadius: 2,
            size: 7,
            marginRight: 5,
            marginBottom: 5,
            fontSize: 13,
        }}
    >
        <AllIcon 
            icon={IconHelper.false}
            onClick={onDelete}
            color={colors.primary}
            noPadding
            noMargin
            size='small'
        />
        {' '}
        {label}
    </Grid>
)

class SearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props)
        this.inputHelper = new InputHelper(this, null, ()=>props.onChange(this.state.formData));
    }

    search = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                ...this.state.tempFormData,
            },
            tempFormData: {
                ...this.state.tempFormData,
                show: false,
            }
        }, () => {
            this.props.onChange(this.state.formData);
        })
    }

    resetSearch = () => {
        this.setState(initialState(this.props), () => {
            this.props.onChange(this.state.formData);
        })
    }

    render(){
        const { formData, tempFormData } = this.state;

        const { classes, lists } = this.props;

        return (
            <Grid
                container
                style={{
                    border: `1px solid ${colors.white}`,
                    borderRadius: 5,
                }}
            >
                <Grid item
                    style={{
                        borderRight: `1px solid ${colors.white}`,
                        width: 100,
                    }}
                >
                    <Select
                        onChange={this.inputHelper.handleTextInputChange}
                        name="formData.read"
                        value={formData.read}
                        variant="outlined"
                        style={{ 
                            color: colors.white,
                        }}
                        classes={{ root: classes.rootFirstSelect }}
                        className="noBorder"
                        fullWidth
                        inputProps={{
                            classes: {
                                icon: classes.icon,
                            },
                        }}
                    >
                        <MenuItem value="AllSent">All Sent</MenuItem>
                        <MenuItem value="Opened">Read</MenuItem>
                        <MenuItem value="Sent">Unread</MenuItem>
                        <MenuItem value="Bounced">Bounced</MenuItem>
                        <MenuItem value="Complaint">Complaint</MenuItem>
                        <MenuItem value="All">All Emails</MenuItem>
                    </Select>
                </Grid>
                <Grid item
                    style={{width: "calc(100% - 100px)"}}
                >
                    <TextField
                        placeholder="Search..."
                        fullWidth
                        value={formData.search}
                        name="formData.search"
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value), ()=>this.props.onChange(this.state.formData))}
                        classes={{ root: classes.rootFirstSelect }}
                        variant="outlined"
                        className="noBorder"
                        style={{
                            color: colors.white,
                            padding: 0,
                        }}
                        inputProps={{
                            style: {
                                padding: "4px 10px",
                                color: colors.white,
                                width: "100%",
                            }
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AllIcon 
                                        icon={formData.search ? IconHelper.arrowLeft : IconHelper.search} 
                                        size="small" 
                                        noMargin noPadding white 
                                        onClick={this.inputHelper.handleButtonChange('formData.search', '')} 
                                    />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <AllIcon icon={IconHelper.filter}
                                        size="small" 
                                        heavy={tempFormData.show}
                                        noMargin noPadding white 
                                        onClick={this.inputHelper.handleButtonChange('tempFormData', {
                                            ...formData,
                                            show: true
                                        })}
                                    />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                {(formData.fromEmail !== 'all' || formData.from !== 'all' || formData.hasAttachment || formData.cc || formData.dateFrom || formData.dateTo) && 
                    <Grid item xs={12} style={{borderTop: `1px solid ${colors.white}`}}>
                        <Grid container style={{padding: '0.5em', paddingBottom: 'calc(0.5em - 5px)'}}>
                            {formData.from !== 'all' &&
                                <FilterTag
                                    label={`From: ${_.find(lists.from, {value: formData.from}).label}`}
                                    onDelete={this.inputHelper.handleButtonChange('formData.from', 'all')}
                                />
                            }
                            {formData.fromEmail !== 'all' &&
                                <FilterTag
                                    label={`${formData.fromEmail} Email`}
                                    onDelete={this.inputHelper.handleButtonChange('formData.fromEmail', 'all')}
                                />
                            }
                            {formData.hasAttachment &&
                                <FilterTag
                                    label={`Has Attachment`}
                                    onDelete={this.inputHelper.handleButtonChange('formData.hasAttachment', false)}
                                />
                            }
                            {formData.cc &&
                                <FilterTag
                                    label={`Carbon Copied`}
                                    onDelete={this.inputHelper.handleButtonChange('formData.cc', false)}
                                />
                            }
                            {formData.dateFrom &&
                                <FilterTag
                                    label={`From: ${clenyDate(formData.dateFrom)}`}
                                    onDelete={this.inputHelper.handleButtonChange('formData.dateFrom', null)}
                                />
                            }
                            {formData.dateTo &&
                                <FilterTag
                                    label={`To: ${clenyDate(formData.dateTo)}`}
                                    onDelete={this.inputHelper.handleButtonChange('formData.dateTo', null)}
                                />
                            }
                        </Grid>
                    </Grid>
                }
                {!!tempFormData.show &&
                    <Dialog 
                        open={!!tempFormData.show} 
                        show={!!tempFormData.show} 
                        onClose={this.inputHelper.handleButtonChange('tempFormData.show', false)}
                        maxWidth="sm"
                        fullWidth
                    >
                        <DialogContent>
                            <Box style={{margin: '0.5em'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            margin='none'
                                            label="Date From"
                                            name="tempFormData.dateFrom"
                                            value={tempFormData.dateFrom}
                                            onChange={this.inputHelper.handleDateChange('tempFormData.dateFrom')}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            margin='none'
                                            label="Date To"
                                            name="tempFormData.dateTo"
                                            value={tempFormData.dateTo}
                                            onChange={this.inputHelper.handleDateChange('tempFormData.dateTo')}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <AutoCompleteSelect
                                            margin='none'
                                            label="From"
                                            name="tempFormData.from"
                                            value={tempFormData.from}
                                            onChange={this.inputHelper.handleSelectChange('tempFormData.from')}
                                            options={[{value: 'all', label: 'All'},...lists.from]}
                                            fullWidth
                                            noClear
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CheckBox
                                            color='primary'
                                            label="Has Attachment"
                                            name="tempFormData.hasAttachment"
                                            value={tempFormData.hasAttachment}
                                            checked={tempFormData.hasAttachment}
                                            onChange={this.inputHelper.handleCheckboxChange}
                                        />
                                    </Grid>
                                    
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={this.inputHelper.handleButtonChange('tempFormData.show', false)}
                            >Close</Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={this.resetSearch}
                            >Clear Filters</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.search}
                            >Search</Button>
                        </DialogActions>
                    </Dialog>
                }
            </Grid>
        )

    }
}

const styles = theme => ({
    rootFirstSelect: {
        padding: "4px 10px",
    },
    icon: {
        fill: colors.white,
    }
})

export default withStyles(styles)(SearchBox);