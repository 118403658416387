import React, {Component, useState}         from 'react';
import _                                    from 'lodash';
import moment                               from 'moment';
import API                                  from 'API';
import { connect, useDispatch }             from 'react-redux';
import uuidv4                               from 'uuid/v4';

import { deploySnackBar }                   from 'Actions/SnackBar/SnackBar';
import { deployDialog, closeDialog }        from 'Actions/Dialog/Dialog';
import { deployConfirmation }               from 'Actions/Confirmation/Confirmation';

import LoadingCircle                        from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper                          from 'Components/Common/Paper/PaddedPaper';
import DatePicker                           from 'Components/Common/DatePickers/DatePicker';
import SelectCustomerAddressFormSwitcher    from 'Components/Customers/SelectCustomerAddressForm/SelectCustomerAddressFormSwitcher';
import DataTable                            from 'Components/Common/DataTables/CiDataTable';
import BackButton                           from 'Components/Common/Buttons/BackButton';
import Textarea                             from 'Components/Common/Inputs/Textarea';
import EmailDialog                          from 'Components/Common/Dialogs/EmailCustomerReturnsDialog';

import { formatValidationErrors }           from 'Helpers/ErrorHelper';
import { pdfFromBase64 }                    from 'Helpers/PDFHelper';
import {colors}                             from 'Helpers/ColourHelper';

import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, TextField, Typography, Button, Grid, FormControl, InputLabel, Input} from '@material-ui/core';

import CopyToClipboard from 'Components/Common/CopyToClipboard/CopyToClipboard';

const styles = theme => ({
    td : {
        textAlign: 'right'
    },
    label: {
        color: 'black'
    },
    underline: {
        "&&&:before": {
          borderBottom: "none"
        },
        "&&:after": {
          borderBottom: "none"
        }
      },
      subTotals : {
        fontWeight: 'bold'
    },
});

const initialState = {
    invoice: {},
    formData:  {
        orderRef:           null,
        sageInvoiceNumber:  null,
        invoiceDate:        null,
        //customerAddress
        customerAddress: {
            type:       null,
            address:    null,
            contact:    null,
            key:        uuidv4(),
        },
        deliveryAddress: {
            type:       null,
            address:    null,
            contact:    null,
            key:        uuidv4(),
        },
        //parts
        partRows:       [],
        previewMode:    false
    },
    isLoading: true,
    customer: {},
    importantNoteShown: false,
}

const NotesDialog = ({onSuccess, note, id}) => {
    const [snote, setNote] = useState(note);

    const onChange = e => {setNote(e.target.value);}
    const dispatch = useDispatch();

    return (
        <>
            <Textarea
                name="notes"
                value={snote}
                rows={3}
                label="Notes"
                onChange={onChange}
            />
            <Grid container spacing={2} className='buttonRow'>
                <Grid item><Button onClick={()=>{dispatch(closeDialog())}} variant='outlined'>Close</Button></Grid>
                <Grid item><Button variant='contained' color='primary' onClick={()=>{onSuccess(id, snote); dispatch(closeDialog())}}>Edit</Button></Grid>
            </Grid>
        </>
    )
}

class CreditNoteInvoiceApproval extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getInvoice();
    }

    getInvoice = () => {
        this.setState({
            ...this.state,
            isLoading: true
        },
        () => {
            API.get(`/customerReturns/creditNote/invoice/${this.props.match.params.id}`)
            .then(result => {
                if(result.data) {
                    const invoice = result.data;
                    this.setState({
                        invoice,
                        formData: {
                            ...this.state.formData,
                            // Misc
                            orderRef:       invoice.cni_order_ref ?? null,
                            invoiceDate:    invoice.cni_invoice_date,
                            customerAddress: {
                                type:           'Registered',
                                address:        invoice.cni_cust_address_id,
                                contact:        invoice.cni_cust_contact_id,
                            },
                            deliveryAddress: {
                                type:           invoice.cni_delivery_cust_address_type,
                                address:        invoice.cni_delivery_cust_address_id,
                                contact:        invoice.cni_delivery_cust_contact_id
                            }
                        },
                        isLoading: false,
                    }, this.getCustomer);
                } else {
                    window.location.href = `/sales/order/invoice`;
                }
            });
        });
    }

    handleDateChange = fieldName => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: moment(date).format('YYYY-MM-DD')
            }
        });
    };

    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleAddressChange = t => e => {
        let invoice = (t == 'customerAddress') ?  
            {
                ...this.state.invoice,
                cni_cust_address_name:  e.addressName,
                cni_cust_address:       e.fullAddress,
                cni_cust_contact_email: e.email,
                cni_cust_contact_mobile:e.phone,
                cni_cust_contact_phone: '',
                cni_cust_contact_name:  e.contactName
            } : {
                ...this.state.invoice,
                cni_cust_address_name:  e.addressName,
                cni_cust_address:       e.fullAddress,
                cni_cust_contact_email: e.email,
                cni_cust_contact_mobile:e.phone,
                cni_cust_contact_phone: '',
                cni_cust_contact_name:  e.contactName
            };
        this.setState({
            invoice,
            formData: {
                ...this.state.formData,
                [t] : e
            }
        })
    }

    handleAddressChangeReset = t => {
        const invoice =  this.state.invoice;
        if (t == 'customerAddress'){
            this.setState({
                formData: {
                    ...this.state.formData,
                    customerAddress: {
                        type:           'Registered',
                        address:        invoice.cni_cust_address_id,
                        contact:        invoice.cni_cust_contact_id,
                        key:            uuidv4()
                    },
                }
            })
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    deliveryAddress: {
                        type:           invoice.cni_delivery_cust_address_type,
                        address:        invoice.cni_delivery_cust_address_id,
                        contact:        invoice.cni_delivery_cust_contact_id,
                        key:            uuidv4()
                    }
                }
            })
        }
    }

    trimPartDescription = partDescription => {
        if(partDescription) {
            if(partDescription.length > 50){
                partDescription = partDescription.substring(0, 50) + '...';
            }
        } else {
            partDescription = '';
        }
        return partDescription;
    }

    handleNotesDialogOpen = e => {}

    getColumns = () => {
        let columns = [
            {
                heading: 'Details',
                field: rowData =>   <>
                                        <span style={{color: colors.red}}>{rowData.cnd_part_number + ' - ' + this.trimPartDescription(rowData.cnd_part_description) }</span>
                                        {/* Warranty */}
                                        {rowData.cnd_warranty_void_labels && _.map(rowData.cnd_warranty_void_labels, i => <><br /><span style={{color: '#9E9E9E', fontSize: '0.7rem'}}>{i}</span></>)}
                                        {/* Serial Numbers */}
                                        {rowData.cnd_serial_numbers && _.map(rowData.cnd_serial_numbers, i => <><br /><span style={{color: '#9E9E9E', fontSize: '0.7rem'}}>{i.serialNumber}</span></>)}
                                        {/* Notes */}
                                        {rowData.cnd_notes && rowData.cnd_notes.split('\n').map((item, key) => {
                                            return <><br/><span style={{fontSize: '0.7rem', whiteSpace:'pre-wrap', color: '#9E9E9E'}} key={key}>{item}</span></>
                                        })}
                                    </>,
                sizeToContent: true
            },
            {
                heading: 'Quantity',
                field: rowData => <span style={{color: colors.red}}>{parseFloat(rowData.cnd_quantity).toFixed(3)}</span>,
                alignment: 'right'
            },
            {
                heading: 'Unit Price',
                field: rowData => <span style={{color: colors.red}}>{'£' + parseFloat(rowData.cnd_unit_price).toFixed(2)}</span>,
                alignment: 'right'
            },
        ];

        if (this.state.invoice.credit_note.cn_type == 'With Discount') {
            columns.push({
                heading:    'Discount Rate',
                field:      rowData => <span style={{color: colors.red}}>{`${parseFloat(rowData.cnd_discount_rate).toFixed(2)}%`}</span>,
                alignment: 'right'
            });
            columns.push({
                heading:    'New Unit Price',
                field:      rowData => <span style={{color: colors.red}}>{`£${parseFloat(rowData.cnd_new_unit_price).toFixed(2)}`}</span>,
                alignment: 'right'
            });
            columns.push({
                heading:    'Discount Total',
                field:      rowData => <span style={{color: colors.red}}>{`£${parseFloat(rowData.cnd_discount_total).toFixed(2)}`}</span>,
                alignment: 'right'
            });
        }

        if (_.find(this.state.invoice.credit_note.details, i => i.cnd_restock_fee)){
            columns.push({
                heading:    'Total Price',
                field:      rowData => <span style={{color: colors.red}}>{`£${(parseFloat(rowData.cnd_new_unit_price).toFixed(2) * parseFloat(rowData.cnd_quantity)).toFixed(2)}`}</span>,
                alignment: 'right'
            });
            columns.push({
                heading:    'Restocking Fee',
                field:      rowData => <span style={{color:colors.red}}>{`£${parseFloat(rowData.cnd_restock_total_price).toFixed(2)}`}</span>,
                alignment: 'right'
            });
        }

        columns.push({
            heading: 'Total Credit',
            field: rowData => <span style={{color:colors.red}}>{`-£${parseFloat(rowData.cnd_total_price).toFixed(2)}`}</span>,
            alignment: 'right'
        })
        columns.push({actions: rowData => {return [{name: 'Update', icon: 'pencil-alt', onClick: () => {
            this.props.deployDialog(<NotesDialog onSuccess={this.handleNotesSuccess} id={rowData.cnd_id} note={rowData.cnd_notes} />, 'Update Notes', '', 'sm');
            this.handleNotesDialogOpen(rowData)}}]}})

        return columns;
    }

    handleNotesSuccess = (id,e) => {
        let formData = this.state.formData
        let partRows = formData.partRows;
        let details  = this.state.invoice.credit_note.details;
        if (_.findIndex(partRows,{'id': id}) > -1){
            partRows[_.findIndex(partRows,{'id': id})] = {id: id, note: e}
        } else {
            partRows.push({id: id, note: e})
        }
        details[_.findIndex(details,{'cnd_id': id})].cnd_notes = e;

        this.setState({
            formData: {...formData, partRows},
            invoice: {...this.state.invoice, credit_note: {...this.state.invoice.credit_note, details}}
        })
    }

    confirmSubmit = () => {
        this.setState({formData: {...this.state.formData, previewMode: false}},
        () => {this.submit();});
    }

    handlePreview = () => {
        this.setState({formData: {...this.state.formData, previewMode: true}},
        () => {this.submit();});
    }

    submit = () => {
        this.setState({
            formErrors: [],
            isLoading: true
        },() => {
            API.post(`/customerReturns/creditNote/invoice/${this.state.invoice.cni_id}/approve`, this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    if(result.data.pdf){
                        this.setState({
                            isLoading: false
                        },
                        () => {
                            pdfFromBase64(result.data.pdf, result.data.pdfName);
                        });
                    }
                    else {
                        this.setState({
                            snackbarOpen: true,
                            emailDialogOpen: true,
                            // Email
                            emailDialogID: this.state.invoice.invoice_id,
                            emailDialogCustomerId: this.state.invoice.invoice_cust_id,
                            emailDialogContactId: this.state.invoice.invoice_cust_contact_id
                        }, () => {
                            this.handleEmail();
                        });
                    }
                }
            })
        });
    }

    handleEmail = () => {
        let title = 'Credit Note Approval';
        const callBack  = () => {window.location.replace(`/sales/order/invoice`);}
        const dialog =  <EmailDialog id={this.state.invoice.cni_cn_id} 
                                    customerId={this.state.invoice.cni_cust_id} 
                                    customerContactId={this.state.invoice.cni_cust_contact_id} 
                                    type={'creditNote:Invoice'} 
                                    subType={'Invoice'} 
                                    callback={callBack} 
                                    onclose={callBack}
                        />
        this.props.deployDialog(dialog, `You Are About To Email - ${title}`, 'success')
    }

    handleEmailClose = () => {this.props.closeDialog();}

    getCustomer = () => {
        API.get(`/customers/${this.state.invoice.cni_cust_id}`)
        .then(custRes => {
            this.setState({
                customer: custRes.data
            }, ()=> {
                if (!this.state.importantNoteShown && custRes.data.cust_important_info) this.displayImportantInfo();
            });
        });
    }

    displayImportantInfo = () => {
        this.setState({importantNoteShown: true}, () => {
            this.props.deployDialog(
            <>
                <Typography component="p">
                    {this.state.customer?.cust_important_info && this.state.customer?.cust_important_info.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
                </Typography>
                <Button variant='outlined' style={{float: 'right'}} onClick={this.props.closeDialog}>Close</Button>
            </>
            , 'Important Information', null, 'sm', {backgroundColor : '#fcc6c5', color: '#000'})
        });
    }

    render() {
        const { classes } = this.props;
        const { formErrors, invoice, formData, customer } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Approve Credit Note
                    </Typography>
                </Grid>
                {(this.state.isLoading && (
                    <div>
                        <LoadingCircle />    
                    </div>
                )) || (  
                    <>
                        <Grid container item spacing={3}>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    {formErrors && formErrors.generic && (
                                        <React.Fragment>
                                            <Typography component={"div"} style={{color: colors.red}}>
                                                {formErrors.generic}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                    <Grid container item spacing={3} xs={12}>
                                        <Grid item xs={4}>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={true} className={classes.label}>Credit Note Number</InputLabel>
                                                <Input value={invoice.cni_invoice_number} disabled />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DatePicker
                                                type="date"
                                                id="invoiceDate"
                                                name="invoiceDate"
                                                label="Credit Note / Tax Date"
                                                value={formData.invoiceDate}
                                                errorText={formErrors && formErrors['invoiceDate']}
                                                onChange={this.handleDateChange('invoiceDate')}
                                                autoOk={true}
                                                disableFuture={true}
                                            />
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Grid item xs={11} >
                                                <TextField 
                                                    id="orderRef"
                                                    name="orderRef"
                                                    label="Order Reference *"
                                                    value={formData.orderRef}
                                                    onChange={this.handleChange}
                                                    error={formErrors && formErrors['orderRef'] && true}
                                                    helperText={formErrors && formErrors['orderRef']}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={1} >
                                                <CopyToClipboard copy={formData.orderRef}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={3} xs={12}>
                                        <Grid item xs={4}>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={true} className={classes.label}>Account Number</InputLabel>
                                                <Input value={`CS${invoice.cni_cust_id}`} disabled />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}></Grid>
                                        <Grid item xs={4}>
                                            <TextField 
                                                id="sageInvoiceNumber"
                                                name="sageInvoiceNumber"
                                                label="Sage Credit Note Number *"
                                                value={formData.sageInvoiceNumber}
                                                onChange={this.handleChange}
                                                error={formErrors && formErrors['sageInvoiceNumber'] && true}
                                                helperText={formErrors && formErrors['sageInvoiceNumber']}
                                                margin="normal"
                                                fullWidth
                                                onInput = {(e) =>{
                                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,7)
                                                }}
                                                type='number'
                                            />
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            {customer?.cust_name && customer?.account_status?.cas_name != 'Active - Verified' &&
                                <Grid item xs={12} style={{padding:'1em', color: colors.white, backgroundColor: colors.red, margin: '0px 1em'}}>
                                    <Typography style={{fontWeight:'bold'}}>{customer.cust_name} is {customer?.account_status?.cas_name }</Typography>
                                </Grid>
                            }
                            <Grid container item spacing={3}>                           
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <SelectCustomerAddressFormSwitcher
                                            customerId= {invoice.cni_cust_id}
                                            callback=   {this.handleAddressChange('customerAddress')}
                                            name=       'customerAddress'
                                            header=     'Credit Note Address'
                                            formErrors= {formErrors}
                                            cname=      'Company'
                                            addressName={invoice.cni_cust_address_name}
                                            address=    {invoice.cni_cust_address}
                                            contact=    {invoice.cni_cust_contact_name}
                                            email=      {invoice.cni_cust_contact_email}
                                            phone=      {invoice.cni_cust_contact_phone}
                                            mobile=     {invoice.cni_cust_contact_mobile}
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <SelectCustomerAddressFormSwitcher
                                            customerId= {invoice.cni_cust_id}
                                            callback=   {this.handleAddressChange('deliveryAddress')}
                                            name=       'deliveryAddress'
                                            header=     'Delivery Address'
                                            formErrors= {formErrors}
                                            cname=      'Delivery'
                                            addressName={invoice.cni_delivery_cust_address_name}
                                            address=    {invoice.cni_delivery_cust_address}
                                            contact=    {invoice.cni_delivery_cust_contact_name}
                                            email=      {invoice.cni_delivery_cust_contact_email}
                                            phone=      {invoice.cni_delivery_cust_contact_phone}
                                            mobile=     {invoice.cni_delivery_cust_contact_mobile}
                                        />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <DataTable 
                                        config={{
                                            key: 'cni_id',
                                            pagination: false,
                                            alternatingRowColours: true,
                                            responsiveimportant: true,
                                        }}
                                        columns={this.getColumns()}
                                        rows={invoice.credit_note.details}
                                    />
                                    <Grid container xs={12} item justify="flex-end">
                                        <Grid item xs={4}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="right" className={classes.td}>
                                                            <Typography variant="subtitle1" className={classes.subTotals}>
                                                                Total Net
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.td}>
                                                            <Typography variant="subtitle1" className={classes.subTotals} style={{color:colors.red}}>
                                                                -£{invoice.cni_sub_total}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right" className={classes.td}>
                                                            <Typography variant="subtitle1" className={classes.subTotals}>
                                                                Total VAT ({invoice?.vat?.vat_value_percentage}%)
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell  align="right" className={classes.td}>
                                                            <Typography variant="subtitle1" className={classes.subTotals} style={{color:colors.red}}>
                                                                -£{invoice.cni_vat}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow style={{backgroundColor:'#f2f2f2'}}>
                                                        <TableCell align="right" className={classes.td}>
                                                            <Typography variant="subtitle1" className={classes.subTotals}>
                                                                Credit Note Total
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.td}>
                                                            <Typography variant="subtitle1" className={classes.subTotals} style={{color:colors.red}}>
                                                                -£{invoice.cni_total_incl_vat}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                    {formErrors && formErrors['part_locked'] &&
                                        <Grid container xs={12} style={{paddingTop:'1em'}}>
                                            <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>
                                                Some parts are locked, please try again later.
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid container spacing={6} className='buttonRow'>
                                        <Grid item>
                                            <Button onClick={this.handlePreview}
                                                    variant="contained"
                                                    color="primary">
                                                Preview
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <BackButton props={this.props} />
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={()=>{this.props.deployConfirmation('Do you want approve this credit note', 'Approve Credit Note', this.confirmSubmit)}}
                                                    variant="contained"
                                                    color="primary">
                                                Approve
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar:     (variant, msg)                              => {dispatch(deploySnackBar(variant, msg))},
        deployDialog:       (content, title, variant, size, style=null) => {dispatch(deployDialog(content, title, variant, size, style))},
        closeDialog:        ()                                          => {dispatch(closeDialog())},
        deployConfirmation: (message, title, success)                   => {dispatch(deployConfirmation(message, title, success))}
    }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(CreditNoteInvoiceApproval));