import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import API from 'API';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { map, assign } from 'lodash';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Paper from 'Components/Common/Paper/PaddedPaper';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import ConfirmationDialog from 'Components/Common/Dialogs/ReduxConfirmationDialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';

export default function AddCourier() {
    const dispatch = useDispatch();
    const initialState = {
        access: {
            addCourier: false
        },
        formErrors: {},
        formData: {
            supplier: '',
            name: '',
            logo: '',
            trackingURL: '',
            rates: ''
        },
        supplierList: [],
        isLoading: true
    }
    let [state, setState] = useState({...initialState});
    const {formErrors, formData, formData:{supplier, name, logo, trackingURL, rates}, supplierList, isLoading} = state;
    const handleSelectChange = fieldName => selectedValue => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [fieldName]: selectedValue && selectedValue.value
            }
        }));
    }
    const handleChange = e => {
        const {value, name} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }
    const clearFile = type => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [type]: ''
            }
        }));
    }
    const handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: file
            }
        }));
    }
    const handleConfirmationOpen = () => {
        const dialog = <ConfirmationDialog message="Are you sure you want to add this courier?" success={handleSubmit} />
        dispatch(deployDialog(dialog, 'Add Courier', '', 'xs'));
    }
    const handleSubmit = () => {
        dispatch(closeDialog());
        setState(state => ({...state, isLoading: true}));
        let newFormData = new FormData();
        Object.keys(formData).forEach(key => {
            if(key === 'file') {
                newFormData.append('file[]', formData[key]);
            } else {
                newFormData.append(key, formData[key]);
            }
        });
        API.post('/couriers', newFormData)
        .then(result => {
            if(result.data.errors) {
                setState(state => ({
                    ...state,
                    formErrors: formatValidationErrors(result.data.errors),
                    isLoading: false
                }));
            } else if(result.data.success) {
                setState(state => ({
                    ...state,
                    formData: {
                        supplier: '',
                        name: '',
                        logo: '',
                        trackingURL: '',
                        rates: ''
                    },
                    isLoading: false
                }));
                dispatch(deploySnackBar('success', 'You have successfully added courier'));
            }
        })
    }
    useEffect(() => {
        Promise.all([
            API.get('/staff/my/access/check/add-courier'),
            API.get('/suppliers/all')
        ])
        .then(([accessRes, suppRes]) => {
            let supplierList = map(suppRes.data, el => {
                return assign({
                    value: el.supp_id,
                    label: el.supp_company_name
                });
            });
            setState(state => ({
                ...state,
                access: {
                    addCourier: accessRes.data && accessRes.data.has_access
                },
                supplierList,
                isLoading: false
            }));
        });
    }, [])
    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5">
                    Add Courier
                </Typography>
            </Grid>
            <Grid container item spacing={3}>
                {isLoading ? (
                    <LoadingCircle />
                ) : (
                    <Grid item xs={12} lg={6}>
                        <Paper>
                            <form noValidate autoComplete="off">
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={supplierList} 
                                        label='Supplier *'
                                        error={formErrors && formErrors['supplier'] && true}
                                        errorText={formErrors && formErrors['supplier']}
                                        value={supplier}
                                        onChange={handleSelectChange('supplier')}
                                    />
                                </FormControl>
                                {supplier && 
                                    <React.Fragment>
                                        <TextField
                                            id="name"
                                            name="name"
                                            label="Courier Name *"
                                            value={name}
                                            error={formErrors && formErrors['name'] && true}
                                            helperText={formErrors && formErrors['name']}
                                            onChange={handleChange}
                                            margin="normal"
                                            fullWidth
                                        />
                                        <DragFileInput
                                            id="logo"
                                            name="logo"
                                            label='Upload Logo'
                                            file={logo}
                                            errorText={formErrors && formErrors['logo']}
                                            onChange={handleFileChange}
                                            cancelOnClick={() => clearFile('logo')}
                                            emptyText='No logo selected'
                                        />
                                        <TextField
                                            id="trackingURL"
                                            name="trackingURL"
                                            label="Courier Tracking URL"
                                            value={trackingURL}
                                            error={formErrors && formErrors['trackingURL'] && true}
                                            helperText={formErrors && formErrors['trackingURL']}
                                            onChange={handleChange}
                                            margin="normal"
                                            fullWidth
                                        />
                                        <Typography variant="subtitle2" style={{flex: 1}}>
                                            <FALightIcon icon='info-circle' size='small' />
                                            Url for tracking, e.g. http://www.royalmail.com/portal/rm/track?trackNumber={'{{reference}}'}
                                        </Typography>
                                        <DragFileInput
                                            id="rates"
                                            name="rates"
                                            label='Upload Rates Card'
                                            file={rates}
                                            errorText={formErrors && formErrors['rates']}
                                            onChange={handleFileChange}
                                            cancelOnClick={() => clearFile('rates')}
                                            emptyText='No logo selected'
                                        />
                                        <div className="buttonRow">
                                            <Button onClick={handleConfirmationOpen} variant="contained" color="primary">Submit</Button>
                                        </div>
                                    </React.Fragment>
                                }
                            </form>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}