import { Button, Grid } from "@material-ui/core";
import API from "API";
import { deployConfirmation } from "Actions/Confirmation/Confirmation";
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import DataTable from "Components/Common/DataTables/CiDataTable";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import IconHelper from "Helpers/IconHelper";
import React from "react";
import { connect } from "react-redux";
import FormView from 'Components/Common/Forms/FormView/FormView';

const initialState = ({contact}) => ({
    isLoading: true,
    surveys: [],
})

class Marketing extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        API.get('/survey/surveyInvites', {params: {contactId: this.props.contact.contact_id}}).then(res => {
            this.setState({
                surveys: res.data,
                isLoading: false,
            })
        })
    }

    render () {
        const { isLoading, surveys } = this.state;

        if (isLoading) {
            return <LoadingCircle />
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <DataTable 
                        config={{
                            key: 'id',
                        }}
                        rows={surveys}
                        columns={[
                            {
                                heading: 'sent',
                                field: 'created',
                                fieldFormat: 'clenydate',
                                sizeToContent: true,
                            },
                            {
                                heading: 'survey',
                                field: 'survey.name'
                            },
                            {
                                heading: 'reference',
                                field: 'survey.ref',
                            },
                            {
                                heading: 'invite UUID',
                                field: 'uuid',
                            },
                            {
                                actionsCustomHeader:    true,
                                important:              true,
                                alignment:              'left',
                                heading:                'Linked Order',
                                actions: i => ([
                                    { label: i?.invoice?.order?.ref,         link: `/sales/order/view/${i.invoice.invoice_order_id}`,         type:'plainLink'},
                                ])
                            },
                            {
                                heading: 'status',
                                field: 'status',
                                sizeToContent: true,
                            },
                            {
                                heading: 'emails',
                                field: i=> i.emails.length,
                                sizeToContent: true,
                            },
                            {
                                actions: i => ([
                                    {
                                        name: 'View Answers',
                                        icon: IconHelper.search,
                                        onClick: ()=>this.props.deployDialog(
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} style={{marginTop: 16}}>
                                                    <FormView formId={i.form.configId} displayForm={i.form.id} />
                                                </Grid>
                                                <Grid item xs={12} className='buttonRow'>
                                                    <Button variant='outlined' onClick={()=>this.props.closeDialog()}>Close</Button>
                                                </Grid>
                                            </Grid>,
                                            `Survey Answers - ${i._uuid}`
                                        )
                                    },
                                    {
                                        name: 'view Survey',
                                        icon: IconHelper.survey,
                                        link: `/survey/${i.survey.id}`
                                    },
                                ])
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (content, title, callback, variant=null) => {
        dispatch(deployConfirmation(content, title, callback, null, variant))
    },
    deployDialog: (content, title, size='lg') => {
        dispatch(deployDialog(content, title, 'system', size))
    },
    closeDialog: () => {
        dispatch(closeDialog())
    }
});

export default connect(null, mapDispatchToProps)(Marketing);