//BatchProcessPayments
import API from 'API';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog } from 'Actions/Dialog/Dialog';
import DataTable from 'Components/Common/DataTables/DataTable';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import AllIcon from 'Components/Common/Icons/AllIcon';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import {
    Box,
    Button,
    FormControl,
    Grid,
    TextField,
    Typography
} from '@material-ui/core';

const initialState = () => ({
    formData: {
        date:   null,
        type:   null,
        ref:    null,
        payee:  null,
        number: null,
        auth:   null,
        orders: null,
    },
    formErrors: [],
    isLoading: false,
    notProcessed: [],
});

const additionalFields = {
    ref:    ['Card', 'Bank Transfer', 'Direct Debit'],
    payee:  ['Cheque', 'Bank Transfer'],
    number: ['Cheque'],
    auth:   ['Card'],
}

class BatchProcessPayments extends Component {
    constructor(props) {
        super(props);   
        this.state = {...initialState(), formData: {
            ...initialState().formData,
            orders: this.props.orders
        }};
    }

    handleDateChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                date: moment(e).format('YYYY-MM-DD')
            },
        });
    }
    
    handleSetPaymentType = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                type: e.value
            },
        });
    }

    handleChange = type => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [type]: e.target.value
            },
        });
    }

    submit = () => {
        const {formData} = this.state;
        let newFormData = new FormData();
        Object.keys(formData).forEach(key => {
            newFormData.append(key, formData[key]);
        });
        API.post('/sales/orders/invoice/processPayments', newFormData)
        .then(res => {
             if (res.data.not_processed) {
                this.setState({notProcessed: res.data.not_processed})
            } if (res.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(res.data.errors),
                })
            } else {
                if (this.props.callback) this.props.callback();
                this.props.closeDialog();
            }   
        })
    }

    closeWithCallback = () => {
        if (this.props.callback) this.props.callback();
        this.props.closeDialog();
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    clearFile = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                file: ''
            }
        });
    }

    render() {
        const { formErrors, formData, disableProcess, isLoading, notProcessed } = this.state;
        const { orders }                               = this.props
        return (
            <>
                { isLoading ?  <LoadingCircle /> :
                    <>
                        {!!notProcessed.length ?
                            <>
                                <Typography variant={'h6'} >Batch Payment Processed With Some Errors</Typography><br></br>
                                
                                <DataTable
                                    config={{
                                        key: 'i',
                                        responsiveImportance: true
                                    }}
                                    columns={
                                        [
                                            {
                                                heading: 'Invoice',
                                                field: rowData => rowData.i,
                                            },
                                            {
                                                heading: 'Reason',
                                                field: rowData => rowData.e,
                                            },
                                        ]
                                    }
                                    rows={notProcessed}
                                />
                                <Typography variant={'body'} style={{float:'right'}}>{orders.length - notProcessed.length}/{orders.length} Processed</Typography><br></br>
                                <div className='buttonRow'>
                                    <Button variant='outlined' onClick={this.closeWithCallback}>Close</Button>
                                </div>
                            </>
                            :
                            <>
                                <FormControl margin="normal" fullWidth>
                                    <DatePicker
                                        type="date"
                                        id="paymentDate"
                                        name="paymentDate"
                                        label="Payment Date *"
                                        value={formData.date}
                                        errorText={formErrors && formErrors['date']}
                                        onChange={this.handleDateChange}
                                        autoOk={true}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect
                                        name="type"
                                        options={[
                                            {value: 'Card',             label: 'BarclayCard EPDQ',  icon: 'credit-card'},
                                            {value: 'Cheque',           label: 'Cheque',            icon: 'money-check-edit'},
                                            {value: 'Bank Transfer',    label: 'Bank Transfer',     icon: 'piggy-bank'},
                                            {value: 'Cash',             label: 'Cash',              icon: 'money-bill'},
                                            {value: 'Direct Debit',     label: 'Direct Debit',      icon: 'university'},
                                        ]}
                                        label='Payment Method'
                                        value={formData.type}
                                        onChange={this.handleSetPaymentType}
                                        noClear
                                        formatOptionLabel={({label, icon}) => (
                                            <Box>
                                                <Grid container alignItems='center'>
                                                    <Grid item>
                                                        <AllIcon icon={icon} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body2" >
                                                            {label}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )}
                                    />
                                </FormControl>
                                {additionalFields.ref.includes(formData.type) &&
                                    <FormControl margin="normal" fullWidth>
                                        <TextField 
                                            label={`${formData.type == 'Card' ? 'Payment' : ''} Ref *`}   
                                            error={formErrors && formErrors['ref']}
                                            helperText={formErrors && formErrors['ref']}
                                            fullWidth 
                                            value={formData.ref} 
                                            onChange={this.handleChange('ref')} 
                                        />
                                    </FormControl>
                                }
                                {additionalFields.payee.includes(formData.type) &&
                                    <FormControl margin="normal" fullWidth>
                                        <TextField 
                                            label={`${formData.type == 'Cheque' ? 'Cheque': ''} Payee *`} 
                                            error={formErrors && formErrors['payee']}
                                            helperText={formErrors['payee']}
                                            fullWidth 
                                            value={formData.payee} 
                                            onChange={this.handleChange('payee')} 
                                        />
                                    </FormControl>
                                }
                                {additionalFields.auth.includes(formData.type) &&
                                    <FormControl margin="normal" fullWidth>
                                        <TextField 
                                            label="Auth No *"   
                                            error={formErrors && formErrors['auth']}
                                            helperText={formErrors && formErrors['auth']}
                                            fullWidth 
                                            value={formData.auth} 
                                            onChange={this.handleChange('auth')} 
                                        />
                                    </FormControl>
                                }
                                {additionalFields.number.includes(formData.type) &&
                                    <FormControl margin="normal" fullWidth>
                                        <TextField 
                                            label="Cheque Number *"   
                                            error={formErrors && formErrors['number']}
                                            helperText={formErrors && formErrors['number']}
                                            fullWidth 
                                            value={formData.number} 
                                            onChange={this.handleChange('number')} 
                                        />
                                    </FormControl>
                                }
                                <DragFileInput 
                                    id="file"
                                    name='file'
                                    label="Upload File:"
                                    file={formData.file}
                                    errorText={formErrors && formErrors['file']}
                                    onChange={this.handleFileChange}
                                    cancelOnClick={this.clearFile}
                                    emptyText="No file selected"
                                />
                                <div className='buttonRow'>
                                    <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                                    <Button variant='contained' color='primary' disabled={!formData.type || !formData.date} onClick={()=>{this.props.deployConfirmation('Are you sure you want to process these payments?', `Process ${orders.length} Payments`, this.submit)}}>Process</Button>
                                </div>
                            </>
                        }
                    </>
                }
            </>
        );
    }
}
function mapDispatchToProps(dispatch) { return {
    closeDialog:        ()                          => dispatch(closeDialog()),
    deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success))
}}

export default connect(null, mapDispatchToProps)(BatchProcessPayments);