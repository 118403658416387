import React, { Component } from 'react';

import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import { Button, FormControl, Grid, TextField } from '@material-ui/core';

const initialState = {
    keywords:           '',
}

class SearchBlock extends Component {
    constructor(props) {
        super(props);    
        this.timeout = false;
        this.state   = {...initialState, ...props.formData};
        this.timeout = false;
    }

    componentWillUnmount = () => { if(this.timeout) clearTimeout(this.timeout) }

    handleReset = () => { this.setState(initialState,this.handleReturnChange) }

    handleReturnChange = () => { 
        clearTimeout(this.timeout);
        this.timeout  = setTimeout(() => {
            this.props.callBack({...this.state}); 
        },400);
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, this.handleReturnChange);
    };

    render(){

        const { keywords } = this.state;
        const Element = this.props.noPad ? Grid : PaddedPaper;
        const bigSize = 12;
        return (
            <Grid container xs={bigSize}>
                <Element style={{width:'100%'}}>
                    <form noValidate autoComplete="off">
                        <Grid container xs={12} spacing={3}>
                            <Grid item style={{width: `60%`}}>
                                <FormControl margin="normal" fullWidth style={{margin:0}}>
                                    <TextField label="Keywords" fullWidth value={keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                            </Grid>
                            {!this.props.disableReset &&
                                <Grid item   style={{marginLeft:'auto'}}>
                                    <div className='buttonRow'>
                                        <Button onClick={this.handleReset}
                                                variant="outlined"
                                                color="default"
                                        >
                                            <FALightIcon icon='undo' button />
                                            Reset Search
                                        </Button>
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    </form>
                </Element>
            </Grid>
        )
    }

}

export default SearchBlock;