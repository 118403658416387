import { Button, DialogActions, DialogContent, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import { getCurrency, getMarginPercent, getPercentage } from 'Helpers/ReportsHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

const months = [null, 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const fmonths = ['October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September' ];

const initialState = (props) => ({
    formData: {
        months: [],
        yearlyTarget: 0,
        yearlyCost: 0,
    },
    months: [],
    lockCost: false,
    lockBudget: false,
    recommended: [],
    isLoading: true,
})


class SetCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount = () => {
        this.getData();
    }

    getData = () => {
        Promise.all([
            API.get(`/performance/year/${this.props.year}/pc/${this.props.id}/recommended`),
            API.get('/performance/year/' + this.props.year),
            API.get('/performance/year/' + this.props.year + '/getMonths')
        ])
        .then(([rec,res,mres]) => {
            let _months = {};
            _.each(mres.data, (i) => {
                if (i){
                    _months[i.month] = {
                        id: i.month,
                        name: i.name,
                        workDays: i.workingDays,
                        budget: 0,
                        cost: 0,
                    };
                }
            })
            let cost = 0, budget = 0;
            _.each(res.data?.months, i => {
                let pc = _.find(i.part_categories, { tympc_pc_id: this.props.id });
                if (pc){
                    _months[i.ytm_month] = {
                        ..._months[i.ytm_month],
                        budget: parseFloat(pc.tympc_budget).toFixed(2),
                        cost: parseFloat(pc.tympc_cost).toFixed(2),
                    }
                    cost += parseFloat(pc.tympc_cost);
                    budget += parseFloat(pc.tympc_budget);
                }
            });

            this.setState({
                formData: {
                    months: _months, 
                    yearlyBudget: parseFloat(budget).toFixed(2),
                    yearlyCost: parseFloat(cost).toFixed(2),
                },
                recommended: rec.data,
                isLoading: false,
            });
        })
    }
  
    setMonthlyTarget = (id, field) => e => {
        let formData = _.cloneDeep(this.state.formData);
        formData.months[id][field] = parseFloat(e.target.value);

        this.setState({ 
            formData,
            lockCost: field === 'budget' ? this.state.lockCost : true,
            lockBudget: field === 'budget' ? true :  this.state.lockBudget,
        }, this.updateYearlyTarget);
    }

    updateYearlyTarget = () => {
        let formData = _.cloneDeep(this.state.formData);

        formData.yearlyBudget = parseFloat(_.sumBy(_.values(formData.months), i => parseFloat(i.budget))).toFixed(2);
        formData.yearlyCost = parseFloat(_.sumBy(_.values(formData.months),  i => parseFloat(i.cost))).toFixed(2);
        this.setState({ formData });
    }

    setYearlyTarget = field => e => {
        let formData = _.cloneDeep(this.state.formData);
        formData[
            field === 'budget' ? 'yearlyBudget' : 'yearlyCost'
        ] = parseFloat(e.target.value);
        let per =  parseFloat(parseFloat(e.target.value) / 12).toFixed(2);
        _.each(_.keys(formData.months), i => {
            formData.months[i][field] = per;
        })
        this.setState({ formData });
    }

    save = () => {
        let params = {
            months: JSON.stringify(this.state.formData.months),
        }
        API.post(`/performance/year/${this.props.year}/pc/${this.props.id}`, params)
        .then(()=>this.props.onClose(1));
    }


    render() {
        const { formData, isLoading, recommended, lockBudget, lockCost } = this.state;

        if (isLoading) { return <LoadingCircle/> }

        return (
            <>
                <DialogContent>
                    <Grid container spacing={2}>
                        {
                            !!( formData.yearlyCost || formData.yearlyBudget ) && 
                            <>
                                {_.map([
                                    {
                                        label: `Per Year (1)`,
                                        div: 1
                                    },
                                    {
                                        label: `Per Month (12)`,
                                        div: 12
                                    },
                                    
                                    {
                                        label: `Per Week (${52})`,
                                        div: 52
                                    },
                                    {
                                        label: `Per Working Day (${_.sumBy(_.values(formData.months), i => parseInt(i.workDays))})`,
                                        div: parseInt(_.sumBy(_.values(formData.months), i => parseInt(i.workDays)))
                                    }
                                ], ({label, div}) =>
                                    <Grid item xs={3}>
                                        <PaddedPaper>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        <b>
                                                            {label}
                                                        </b>
                                                    </Typography>
                                                </Grid>
                                                <>
                                                    <Grid item xs={4}>Sales:</Grid>
                                                    <Grid item xs={8}>{getCurrency(formData.yearlyBudget / div)}</Grid>
                                                </>

                                                <>
                                                    <Grid item xs={4}>Cost:</Grid>
                                                    <Grid item xs={8}>{getCurrency(formData.yearlyCost / div)}</Grid>
                                                </>
                                            
                                                <>
                                                    <Grid item xs={4}>Margin £:</Grid>
                                                    <Grid item xs={8}>{getCurrency( ( formData.yearlyBudget - formData.yearlyCost ) / div)}</Grid>
                                                    <Grid item xs={4}>Margin %:</Grid>
                                                    <Grid item  xs={8}>
                                                        {
                                                            getMarginPercent( formData.yearlyBudget, formData.yearlyCost )
                                                        }
                                                    </Grid>
                                                </>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                )}
                            </>
                        }
                        <Grid item xs={6} style={{borderTop: `1px solid ${colors.disabled}`}}>
                            <PaddedPaper>
                                <Grid container>
                                    <Grid item xs={12}>
                                        Set Yearly Sales
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            type="number"
                                            value={formData.yearlyBudget}
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                            }}
                                            fullWidth
                                            onChange={this.setYearlyTarget('budget')}
                                            onBlur={()=>this.setState({formData: {...this.state.formData, yearlyBudget: parseFloat(this.state.formData.yearlyBudget).toFixed(2)}})}
                                            disabled={lockBudget}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={5}>
                                        <Select
                                            value={formData.targetSplit}
                                            onChange={this.setTargetSplit}
                                            fullWidth
                                        >
                                            <option value="even">Spit Evenly</option>
                                            <option value="performance">Spit Based On Performance</option>
                                        </Select>
                                    </Grid> */}
                                    {lockBudget ?
                                        <>
                                            <Grid item xs={12}>
                                                <Typography variant='caption'>
                                                    Yearly Sales cannot be changed after setting individual month
                                                </Typography>
                                            </Grid>
                                        </> :
                                        <>
                                            <Grid item xs={12}>
                                                <Typography variant='caption'>
                                                    Recommended Sales: {getCurrency(recommended.t)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='caption'>
                                                    * Please note: Recommended Sales is based on the last years performance
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={6} style={{borderTop: `1px solid ${colors.disabled}`}}>
                            <PaddedPaper>
                                <Grid container>
                                    <Grid item xs={12}>
                                        Set Yearly Cost
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            type="number"
                                            value={formData.yearlyCost}
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                            }}
                                            fullWidth
                                            onChange={this.setYearlyTarget('cost')}
                                            onBlur={()=>this.setState({formData: {...this.state.formData, yearlyCost: parseFloat(this.state.formData.yearlyCost).toFixed(2)}})}
                                            disabled={lockCost}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={5}>
                                        <Select
                                            value={formData.targetSplit}
                                            onChange={this.setTargetSplit}
                                            fullWidth
                                        >
                                            <option value="even">Spit Evenly</option>
                                            <option value="performance">Spit Based On Performance</option>
                                        </Select>
                                    </Grid> */}
                                    {lockCost ?
                                        <>
                                            <Grid item xs={12}>
                                                <Typography variant='caption'>
                                                    Yearly Cost cannot be changed after setting individual month
                                                </Typography>
                                            </Grid>
                                        </> :
                                        <>
                                            <Grid item xs={12}>
                                                <Typography variant='caption'>
                                                    Recommended Cost: {getCurrency(recommended.c)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='caption'>
                                                    * Please note: Recommended cost is based on the last years performance
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <CiDataTable
                                config={{
                                    key: 'id',
                                    style: c => ({
                                        backgroundColor: parseFloat(c.budget) <= parseFloat(c.cost)  && colors.importantNote,
                                    })
                                }}
                                rows={_.orderBy(_.values(formData.months), i => fmonths.indexOf(i.name), ['asc'])}
                                columns={[
                                    {
                                        heading: 'Month',
                                        field: 'name',
                                    },
                                    {
                                        heading: 'Work Days',
                                        field: 'workDays'
                                    },
                                    {
                                        heading: 'Sales',
                                        field: i => <>
                                            <TextField
                                                type="number"
                                                value={i.budget}
                                                InputProps={{
                                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                }}
                                                fullWidth
                                                onChange={this.setMonthlyTarget(i.id, 'budget')}
                                                onBlur={()=>this.setState({formData: {...this.state.formData, 
                                                    months: {
                                                        ...this.state.formData.months,
                                                        [i.id]: {
                                                            ...this.state.formData.months[i.id],
                                                            budget: parseFloat(this.state.formData.months[i.id].budget).toFixed(2)
                                                        }
                                                    }
                                                }})}
                                            />
                                        </>,
                                    },
                                    {
                                        heading: '% of Total',
                                        field: i => getPercentage(i.budget, formData.yearlyBudget),
                                        fieldFormat: 'percentage',
                                        sizeToContent: true,
                                        style: {
                                            borderRight: `1px solid ${colors.disabled}`
                                        }
                                    },
                                    {
                                        heading: 'Cost',
                                        field: i => <>
                                            <TextField
                                                type="number"
                                                value={i.cost}
                                                InputProps={{
                                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                }}
                                                fullWidth
                                                onChange={this.setMonthlyTarget(i.id, 'cost')}
                                                onBlur={()=>this.setState({formData: {...this.state.formData, 
                                                    months: {
                                                        ...this.state.formData.months,
                                                        [i.id]: {
                                                            ...this.state.formData.months[i.id],
                                                            cost: parseFloat(this.state.formData.months[i.id].cost).toFixed(2)
                                                        }
                                                    }
                                                }})}
                                            />
                                        </>,
                                    },
                                    {
                                        heading: '% of Total',
                                        field: i => getPercentage(i.cost, formData.yearlyCost),
                                        fieldFormat: 'percentage',
                                        sizeToContent: true,
                                        style: {
                                            borderRight: `1px solid ${colors.disabled}`
                                        }
                                    },
                                    {
                                        heading: 'Margin £',
                                        field: c => getCurrency(parseFloat(c.budget) - parseFloat(c.cost))
                                    },
                                    {
                                        heading: '% of Total',
                                        field: i => getPercentage(i.budget - i.cost, formData.yearlyBudget - formData.yearlyCost),
                                        fieldFormat: 'percentage',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Margin %',
                                        field: c => getPercentage(parseFloat(c.budget) - parseFloat(c.cost), parseFloat(c.budget)),
                                        fieldFormat: 'percentage',
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => this.props.onClose(0)}
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => this.props.deployConfirmation(
                                'Are you sure you want to save these category budgets?', 
                                'Save category budgets', 
                                this.save
                            )
                        }
                        disabled={
                            parseFloat(formData.yearlyBudget) < 0 || 
                            parseFloat(formData.yearlyCost) < 0
                        }
                    >
                        Save
                    </Button>
                </DialogActions>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
});

export default connect(null, mapDispatchToProps)(SetCategory);
