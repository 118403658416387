import React, {Component} from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import API from '../../../API';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import DataTable from '../../Common/DataTables/CiDataTable';
import moment from 'moment';
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import SnackBar from '../../Common/SnackBars/SnackBar';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import FASolidIcon from '../../Common/Icons/FontAwesome/FASolidIcon';
import {statusColour} from './../../../Helpers/ColourHelper';
import _ from 'lodash';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import DatePicker from '../../Common/DatePickers/DatePicker';
import { withStyles } from '@material-ui/core/styles';
import QuotationStats from '../../Sales/Quotation/QuotationSearch/QuotationStats';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import IconButton from '@material-ui/core/IconButton';
import EmailDialog from 'Components/Common/Dialogs/EmailDialog';
import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';
import {DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE} from 'Constants'
import { gridPageChanged, gridRowsPerPageChanged } from 'Functions/FormFunctions'
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const styles = () => ({
    card: {
        display: 'flex', 
        justifyContent:'center', 
        alignItems:'center', 
        textAlign:'left'
    },
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }
});

const initialState = () => ({
    searchCriteria: {
        keywords: '',
        status: 'All',
        dateFrom: null,
        dateTo: null,
    },
    access: {
        viewQuotation: false
    },
    gridPagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    },
    quote: {},
    searchResults: {},
    isLoading: true,
    emailDialogOpen: false,
    customerName: ''
});
class CustomerQuotationSearch extends Component {
    constructor(props) {
        super(props);    
        this.clearPageState         = clearPageState.bind(this);
        this.getInitialState        = getInitialState.bind(this);
        this.hasPageState           = hasPageState.bind(this);
        this.savePageState          = savePageState.bind(this);
        this.persistenceId          = `CustomerQuotationSearch:${this.props.match.params.id}`;
        this.state                  = this.getInitialState(initialState());
        this.gridPageChanged        = gridPageChanged.bind(this);
        this.gridRowsPerPageChanged = gridRowsPerPageChanged.bind(this);
    }

    componentDidMount = () => {
        this.getSearchData();
    }

    getSearchData = () => {
        const params = {
            keywords: this.state.searchCriteria.keywords,
            status: this.state.searchCriteria.status,
            dateFrom: this.state.searchCriteria.dateFrom !== null ? moment(this.state.searchCriteria.dateFrom).format('YYYY-MM-DD') : null,
            dateTo: this.state.searchCriteria.dateTo !== null ? moment(this.state.searchCriteria.dateTo).format('YYYY-MM-DD') : null
        }
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([
                API.get('/staff/my/access/check/view-customer-quotation'),
                API.get(`/customers/${this.props.match.params.id}/sales/quotations`, {params}),
                API.get(`/sales/quotations/stats`, {
                    params: {
                        customerId: this.props.match.params.id,
                    }
                })
            ])
            .then(([accessRes, searchRes, statsRes]) => {
                let customer = _.first(searchRes.data, {'quote_cust_id': this.props.match.params.id});
                _.map(searchRes.data, q => {
                    // Status Colour
                    q.colour = statusColour(q.quote_status);
                    // Activity Log
                    let log = _.filter(q.activity_log, { 'cq_log_type': 'Email Sent' });
                    q.email_sent = log.length > 0 ? true : false

                    switch(q.quote_status){
                        case 'Open':
                            q.toolTip1 = 'Quote ' + q.quote_status;
                            q.toolTip2 = 'For ' + moment().diff(moment(q.quote_date), 'days') + ' Days';
                            q.icon = 'file';
                        break;
                        case 'Ordered':
                            q.toolTip1 = 'Quote ' + q.quote_status;
                            q.toolTip2 = moment(q.quote_ordered_datetime).format('DD/MM/YYYY');
                            q.icon = 'check';
                        break;
                        case 'Closed':
                        case 'Expired':
                            q.toolTip1 = 'Quote ' + q.quote_status;
                            q.toolTip2 = moment(q.quote_closed_datetime).format('DD/MM/YYYY');
                            q.icon = 'ban';
                        break;
                        default:
                        break;
                    }
                });
                this.setState({
                    ...this.state,
                    access: {
                        viewQuotation: (accessRes.data && accessRes.data.has_access) || false
                    },
                    searchResults: searchRes.data,
                    statusStats: statsRes.data,
                    customerName: customer && customer.quote_cust_name,
                    isLoading: false
                });
            });
        });
    }

    handleSearchKeyword = (e) => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                keywords: e.target.value
            },
            //gridPagination: initialState.gridPagination
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            searchCriteria:initialState().searchCriteria,
            //gridPagination: initialState().gridPagination
        }, 
        () => {
            //this.props.history.replace();
            this.getSearchData();
        });
    }

    downloadQuote = rowData => {
        API.get('/sales/quotations/' + rowData.quote_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    // EMAIL
    handleEmailOpen = rowData => {
        const dialog = <EmailDialog
                            customerId={rowData.quote_cust_id}
                            customerContactId={rowData.quote_cust_contact_id}
                            type='quote'
                            id={rowData.quote_id}
                        />
        this.props.deployDialog(dialog, 'You Are About To Email - Quotation', 'success');
    }
    handleEmailClose = () => {
        this.props.closeDialog();
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                [fieldName]: selectedOption && selectedOption.value
            },
            //gridPagination: initialState().gridPagination
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    handleDateChange = name => date => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                [name]: date
            },
            //gridPagination: initialState().gridPagination
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    render() {
        const { access, customerName, statusStats, isLoading, searchCriteria, gridPagination } = this.state;
        const { classes } = this.props;
        if(customerName !== '') {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Customer Quotation Search
                        </Typography>
                    </Grid>
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={5}>
                                <PaddedPaper>
                                    <form noValidate autoComplete="off">
                                        <FormControl margin="normal" fullWidth>
                                            <AutoCompleteSelect 
                                                options={[
                                                    {value: 'All', label: 'All'},
                                                    {value: 'Open', label: 'Open'},
                                                    {value: 'Ordered', label: 'Ordered'},
                                                    {value: 'Closed', label: 'Closed'},
                                                    {value: 'Expired', label: 'Expired'}
                                                ]} 
                                                label='Status'
                                                onChange={this.handleSelectChange('status')}
                                                value={searchCriteria?.status}
                                            />
                                        </FormControl>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} lg={6}>
                                                <DatePicker
                                                    type='date'
                                                    id="dateFrom"
                                                    name="dateFrom"
                                                    label="Date From"
                                                    autoOk={true}
                                                    value={searchCriteria?.dateFrom}
                                                    onChange={this.handleDateChange('dateFrom')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <DatePicker
                                                    type='date'
                                                    id="dateTo"
                                                    name="dateTo"
                                                    label="Date To"
                                                    autoOk={true}
                                                    value={searchCriteria?.dateTo}
                                                    onChange={this.handleDateChange('dateTo')}                                            
                                                />
                                            </Grid>
                                        </Grid>
                                        <FormControl margin="normal" fullWidth>
                                            <TextField label="Keywords" fullWidth value={searchCriteria?.keywords} onChange={this.handleSearchKeyword} />
                                        </FormControl>
                                        <div className='buttonRow'>
                                            <Button onClick={this.resetSearch}
                                                    variant="outlined"
                                                    color="default"
                                                    >
                                                <FALightIcon icon='undo' button />
                                                Reset Search
                                            </Button>
                                        </div>
                                    </form>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={12} lg={6}>
                                {isLoading ? <LoadingCircle /> : <QuotationStats stats={statusStats} />}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h5">
                                    <strong>{customerName}</strong>
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {this.state.status} Customer Quotations<br></br><br></br>
                                </Typography>
                                {isLoading ? <LoadingCircle /> : (
                                    <DataTable  
                                        config={{
                                            key: 'quote_id',
                                            pagination: true,
                                            //page: gridPagination.page,
                                            //rowsPerPage: gridPagination.rowsPerPage,
                                            //gridPageChanged: this.gridPageChanged,
                                            //gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                            persistenceId: this.persistenceId,
                                            alternatingRowColours: true,
                                            responsiveimportant: true,
                                            isLoading: this.state.isLoading
                                        }}
                                        columns={[
                                            {
                                                heading: 'Quote Date',
                                                field: rowData => moment(rowData.quote_date).format("DD/MM/YYYY"),
                                                important: true,
                                                sizeToContent: true
                                            },
                                            {
                                                actionsCustomHeader:    true,
                                                sizeToContent:          true,
                                                alignment:              'left',
                                                heading:                'Reference',
                                                actions: rowData => {
                                                    return [
                                                        {label: rowData.quote_reference + '-' + rowData.quote_reference_amendment, link: `/sales/quotation/view/${rowData.quote_id}`, type:'plainLink',}
                                                    ]
                                                }
                                            },
                                            {
                                                heading: 'Discount',
                                                field: rowData => rowData.quote_type,
                                                important: true,
                                                fieldFormat: 'boolean',
                                                tooltip: rowData => rowData.quote_type,
                                                sizeToContent: true,
                                                alignment: 'center'
                                            },
                                            {
                                                heading: 'Delivery Address',
                                                field: rowData => rowData.quote_delivery_cust_address_name,
                                                important: true
                                            },
                                            {
                                                heading: 'Status',
                                                field: rowData =>   <React.Fragment>
                                                                        <Tooltip
                                                                            title={
                                                                                <div>
                                                                                    <Typography variant="body2">{rowData.toolTip1}</Typography>
                                                                                    <Typography variant="body2">{rowData.toolTip2}</Typography>
                                                                                </div>
                                                                            }
                                                                            placement="left"
                                                                        >
                                                                            <IconButton className={classes.iconButton}>
                                                                                <FALightIcon 
                                                                                    icon={`${rowData.icon}`}
                                                                                    button
                                                                                    style={{
                                                                                        color: rowData.colour
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </React.Fragment>,
                                                important: true,
                                                alignment: 'center'
                                            },
                                            {
                                                heading: 'Total',
                                                field: rowData => ( 
                                                    <React.Fragment>
                                                        {rowData.quote_total_incl_vat}
                                                        {' '}
                                                        <Tooltip 
                                                            title={
                                                                <div style={{display: 'flex', width: 200}}>
                                                                    <div style={{width: '50%', textAlign:'right'}}>
                                                                        <Typography variant="body2">Sub Total</Typography>
                                                                        <Typography variant="body2">VAT ({rowData.vat_value.vat_value_percentage}%)</Typography>
                                                                        <Typography variant="body2">Total</Typography>
                                                                    </div>
                                                                    <div style={{width: '50%', textAlign:'right'}}>
                                                                        <Typography variant="body2">£{rowData.quote_sub_total}</Typography>
                                                                        <Typography variant="body2">£{rowData.quote_vat}</Typography>
                                                                        <Typography variant="body2">£{rowData.quote_total_incl_vat}</Typography>
                                                                    </div>
                                                                </div>
                                                            } 
                                                            placement="left"
                                                        >
                                                            <Link>
                                                                <FASolidIcon 
                                                                    icon='info-circle'
                                                                    button 
                                                                    size='small'
                                                                    style={{
                                                                        color: 'rgba(0, 0, 0, 0.38)'
                                                                    }}
                                                                />
                                                            </Link>
                                                        </Tooltip>
                                                    </React.Fragment>
                                                ),
                                                important: true,
                                                fieldPrefix: '£',
                                                alignment: 'right',
                                            },
                                            {
                                                heading: 'Quoted By',
                                                field: rowData => rowData.quote_staff_name,
                                                important: true
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Download', icon: 'download', onClick: this.downloadQuote},
                                                        {name: 'Send Quote to Customer', icon: 'envelope', onClick: this.handleEmailOpen, disabled: rowData.quote_status !== 'Open' && true},
                                                        {name: 'View', icon: 'search', link: { pathname: '/sales/quotation/view/' + rowData.quote_id, state: {searchCriteria, gridPagination}}, disabled: !access.viewQuotation}
                                                        
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={this.state.searchResults}
                                    />
                                )}
                            </PaddedPaper>
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.emailSnackbarOpen}
                                onClose={this.handleEmailSnackbarClose}
                                message='You have successfully sent the email'
                            />
                        </Grid>
                    </React.Fragment>
                </Grid>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                          => dispatch(closeDialog()),
    deployDialog:       (content, title, variant)   => dispatch(deployDialog(content, title, variant)),
    clearPersistence:   (key)                       => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerQuotationSearch));