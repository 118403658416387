import React  from 'react';
import { map, assign } from 'lodash';
import { connect } from 'react-redux';

import {
    Button,
    Grid,
    Typography,
    DialogActions,
    TextField
} from '@material-ui/core';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { handleDateChange, getFormData, handleSelectChange, handleChange } from 'Functions/FormFunctions';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { toggleDialog } from 'Functions/MiscFunctions';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';


const initialState = () => ({
    subscriptionId: 0,
    subscription: {},
    formData: {
        subscriptionSupplierId: '',
        description: '',
        startDate: null,
        endDate: null,
    },
    formErrors: [],
    isLoading: true,
    suppList: [],
});

class VehicleSubscriptionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleDateChange = handleDateChange.bind(this);
        this.getFormData = getFormData.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleChange = handleChange.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData(); 
    }

    loadComponentData = () => {

        const { subscriptionId } = this.props;

        API.get('/suppliers/all', {
            params: {
                supplierType: 10 // Vehicles
            }
        })
        .then(suppRes => {

            let suppList = map(suppRes.data, (supp) => {
                return assign({
                    value: supp.supp_id,
                    label: supp.supp_company_name
                });
            });

            this.setState({
                ...this.state,
                suppList,
                isLoading: false,
                subscriptionId
            },
            () => {
                if (this.state.subscriptionId) {
                    this.getSubscription();
                }
            });
        });  
    }

    getSubscription = () => {
        this.setState({
            isLoading: true,
        }, 
        () => {
            API.get(`/vehicles/${this.props.vehicleId}/subscriptions/${this.state.subscriptionId}`)
            .then(subscriptionRes => {
    
                let sub = subscriptionRes.data;
    
                this.setState({
                    ...this.state,
                    isLoading: false,
                    subscription: sub,
                    formData: {
                        ...this.state.formData,
                        subscriptionSupplierId: sub?.supplierId,
                        description: sub?.desc,
                        startDate: sub?.startDate,
                        endDate: sub?.endDate !== '0000-00-00' ? sub?.endDate : null,
                    }
                });
            });
        });
    }

    handleSubmit = () => {
        const { subscriptionId } = this.state;
        const route = subscriptionId ? `/vehicles/${this.props.vehicleId}/subscriptions/${this.state.subscriptionId}`: `/vehicles/${this.props.vehicleId}/subscriptions`;

        API.post(route, this.getFormData())
        .then(res => {
            if(res.data.errors && res.data.errors.length > 0) {
                this.setState({
                    formErrors: formatValidationErrors(res.data.errors)
                });
            } else {
                this.setState({
                    ...initialState(),
                }, () => {
                    this.props.deploySnackBar("success", `The subscription was successfully ${subscriptionId ? 'updated' : 'added'}`);
                    this.props.refresh();
                })
            }
        });
    }

    render() {
        const { formErrors, formData, isLoading, subscriptionId, suppList } = this.state;
        return (   
            <>   
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {subscriptionId ? 'Update Subscription' : 'Add Subscription'}
                    </Typography>
                </Grid>     
                <Grid item xs={12}>
                    <form noValidate>
                        {(isLoading && (
                            <LoadingCircle />          
                        )) || (
                            <Grid container item spacing={1}>
                                <Grid item xs={12}>
                                    {formErrors && formErrors.generic && (
                                        <Typography component={"div"} style={{color: 'red'}}>
                                            {formErrors.generic}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <AutoCompleteSelect
                                        label={"Subscription " + (subscriptionId ? '' : '*')}
                                        value={formData.subscriptionSupplierId}
                                        options={suppList}
                                        onChange={v => this.handleSelectChange('subscriptionSupplierId', v)}
                                        noClear
                                        fullWidth
                                        error={formErrors && formErrors['subscriptionSupplierId'] && true}
                                        errorText={formErrors && formErrors['subscriptionSupplierId']}
                                        disabled={subscriptionId && true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField  
                                        name="description"
                                        label="Description"
                                        margin="normal"
                                        value={formData.description || ''}
                                        onChange={this.handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePicker
                                        type="date"
                                        id="startDate"
                                        name="startDate"
                                        label={"Start Date " + (subscriptionId ? '' : '*')}
                                        value={formData.startDate}
                                        errorText={formErrors && formErrors['startDate']}
                                        onChange={date => this.handleDateChange('startDate', date, 'YYYY-MM-DD')}
                                        autoOk={true}
                                        disabled={subscriptionId && true}
                                        inputProps={{
                                            className:"textDefault"
                                        }}
                                    />
                                </Grid>
                                {subscriptionId ? (
                                    <Grid item xs={12}>
                                        <DatePicker
                                            type="date"
                                            id="endDate"
                                            name="endDate"
                                            label={"End Date " + (subscriptionId ? '' : '*')}
                                            value={formData.endDate}
                                            errorText={formErrors && formErrors['endDate']}
                                            onChange={date => this.handleDateChange('endDate', date, 'YYYY-MM-DD')}
                                            autoOk={true}
                                            inputProps={{
                                                className:"textDefault"
                                            }}
                                        />
                                    </Grid>
                                ) : null}
                                <Grid item xs={12} align="right">
                                    <DialogActions className='pr-0 pb-0'>
                                        <Button onClick={() => this.props.onClose()} variant="text">
                                            <AllIcon icon="times" size={15} />
                                            Close
                                        </Button>
                                        <Button 
                                            onClick={() => this.props.deployConfirmation(`Are you sure you want to ${subscriptionId ? 'update' : 'add'} this subscription?`, `${subscriptionId ? 'Update' : 'Add'} Subscription?`, this.handleSubmit)}
                                            variant="contained"
                                            color="primary"
                                        >
                                            {subscriptionId ? 'Update' : 'Add'}
                                        </Button>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        )}
                    </form>
                </Grid>  
            </Grid>
            </>  
        );
    }
}

const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
    }
)

export default connect(null, mapDispatchToProps)(VehicleSubscriptionForm);