import React, { useState, useEffect } from 'react';
import moment                         from 'moment';
import API                            from 'API';

import { statusColour }                         from 'Helpers/ColourHelper';
import { orderStatusIcons, orderStatusTooltip } from 'Helpers/SalesHelper';
import { colors }                               from 'Helpers/ColourHelper';

import DataTable   from 'Components/Common/DataTables/DataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';

import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, Tooltip, IconButton, Link } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        },
        padding: 0
    },
    refLink: {
        cursor: 'pointer'
    }
});

export default function ReviewDialog(props) {
    const classes = useStyles();
    const initialState = {
        formData:   {
            oldAccountStatus:   null,
            newAccountStatus:   null,
            data:               [],
            showSuccess:        false,
        },
        isLoading:  true
    };
    let [state, setState] = useState({...initialState});
    const { formData: {data, showSuccess}, isLoading} = state;
    const {customerId, reviewQuotes, reviewOrders, reviewDialog, handleReviewDialogClose, handleFullScreenDialog, accountStatus: {oldStatus, newStatus}, success} = props;
    const generateReferenceField = rowData => {
        let type = rowData.quote_id ? 'quotation' : 'order';
        let ref = type === 'quotation' ? `${rowData.quote_reference}-${rowData.quote_reference_amendment}` : `${rowData.order_reference}-${rowData.order_reference_amendment}` 

        return(
            <Link onClick={() => handleFullScreenDialog(rowData)} className={classes.refLink}>{ref}</Link>
        );
    }
    const generateStatusIcon = rowData => (
        <React.Fragment>
            <Tooltip
                title={
                    <div>
                        <Typography variant="body2">{rowData.tooltip}</Typography>
                    </div>
                }
                placement="left"
            >
                <IconButton
                    className={classes.iconButton}
                >
                    <FALightIcon 
                        icon={`${rowData.icon}`}
                        button
                        style={{
                            color: rowData.colour
                        }}
                        noMargin
                    />
                </IconButton>
            </Tooltip>
        </React.Fragment>
    )
    useEffect(() => {
        setState(state => ({...state, isLoading: true}));
        let params = {
            reviewQuotes,
            reviewOrders
        };
        API.post(`/customers/${customerId}/sales/review`, params)
        .then(result => {
            let showSuccess = true;
            let formattedRes = result.data.map(el => {
                if(el.quote_id) {
                    el.colour = statusColour(el.quote_status);
                    switch(el.quote_status){
                        case 'Open':
                            el.tooltip = <>Quote {el.quote_status} <br /> For {moment().diff(moment(el.quote_date), 'days')} Days</>
                            el.icon = 'file'
                        break;
                        default:
                        break;
                    }
                    showSuccess= false;
                } else {
                    el.colour = statusColour(el.order_status);
                    el.icon = orderStatusIcons(el.order_status);
                    el.tooltip = orderStatusTooltip(el);
                    if (newStatus !== 2 || el.order_status  !== "Invoice") showSuccess = false;
                }
                return el;
            });
            setState(state => ({
                ...state, 
                formData: {
                    oldAccountStatus:   oldStatus, 
                    newAccountStatus:   newStatus,
                    data:               formattedRes,
                    showSuccess
                },
                isLoading: false
            }));
        });
    }, [customerId, newStatus, oldStatus, reviewDialog, reviewOrders, reviewQuotes]);

    return(
        <Dialog 
            open={reviewDialog} 
            onClose={handleReviewDialogClose} 
            fullWidth={true} 
            maxWidth="lg" 
            scroll="body"
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle style={{backgroundColor: colors.yellow,  fontWeight: 'bold'}}><FALightIcon icon='exclamation-triangle' noMargin />{showSuccess ? 'Warning - Outstanding Invoices' : 'Warning - Outstanding Orders / Quotations'}</DialogTitle>
            <DialogContent>
                {showSuccess ? 
                    <Typography variant="subtitle2" gutterBottom>
                        Be aware the following invoices are still outstanding
                    </Typography>
                :   <Typography variant="subtitle2" gutterBottom>
                        Before updating the status of this customer, the orders / quotations below must be dealt with
                    </Typography>}
                <br/>
                <DataTable 
                    config={{
                        key: 'quote_id',
                        responsiveImportance: true,
                        isLoading,
                        plainHeader: true
                    }}
                    columns={[
                        {
                            heading: 'Type',
                            field: rowData => rowData.quote_id ? 'Quote' : 'Order'
                        },
                        {
                            heading: 'Date',
                            field: rowData => rowData.quote_id ? rowData.quote_date : rowData.order_date,
                            fieldFormat: 'date'
                        },
                        {
                            heading: 'Reference',
                            field: rowData => generateReferenceField(rowData)
                        },
                        {
                            heading: 'Discount',
                            field: rowData => rowData.quote_id ? rowData.quote_type : rowData.order_type,
                            important: true,
                            sizeToContent: true,
                            fieldFormat: 'boolean',
                            tooltip: rowData => rowData.quote_id ? rowData.quote_type : rowData.order_type,
                            alignment: 'center'
                        },
                        {
                            heading: 'Status',
                            field: rowData => generateStatusIcon(rowData),
                            alignment: 'center'
                        }
                    ]}
                    rows={data}
                />
            </DialogContent>
            <DialogActions>
                <Grid container style={{margin: '0 15px'}}>
                    <Grid item xs={12}>
                        <div style={{float: 'right'}}>
                            <Button onClick={handleReviewDialogClose} variant="outlined" color="default">Close</Button>
                            {showSuccess && <Button style={{marginLeft: '10px'}} onClick={success} variant="contained" color="primary">Update Anyway</Button>}
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}