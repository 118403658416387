import React, { PureComponent } from 'react';
import { connect }              from 'react-redux';
import API                      from 'API';
import moment                   from 'moment';
import _                        from 'lodash';

import {deployDialog, closeDialog} from 'Actions/Dialog/Dialog';

import DateRangeDialog from './../DateRangeDialog';
import AllIcon         from 'Components/Common/Icons/AllIcon';
import DataTable       from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper     from 'Components/Common/Paper/PaddedPaper';
import BarChart        from 'Components/Common/Charts/BarChart';

import { colors } from 'Helpers/ColourHelper';

import { Card, CardContent, CardHeader, Typography, Grid, Divider, Table, TableHead, TableRow, TableCell, TableBody, Box } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

import {setPersistence} from 'Actions/StatePersistence/StatePersistence';
import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';

const styles = theme => ({
    card: {
        color: '#000',
        backgroundColor: '#FFF',
        padding: '0'
    },
    row: {
        color: '#000',
        backgroundColor: '#FFF',
        '&:hover': {
            backgroundColor: '#FCFCFC',
            cursor: 'pointer'
        },
    },
    header: {
        background:'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', 
        color: '#FFF'
    },
    legendWrapper: {
        padding: '0 1rem'
    },
    legendTitle: {
        marginBottom: '0.8rem'
    },
    legendItem: {
        display: 'grid',
        gridTemplateColumns: '30px 60px auto',
        marginBottom: '0.4rem'
    },
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }

});

const initialState = {
    filter:      {
        to:         moment(),
        from:       moment(),
    },
    catagories:  {},
    overview:    {},
    isLoading:   true,
    showResults: false,
    formErrors:  null
}


class SalesReportPartCatagories extends PureComponent {
    constructor(props) {
        super(props);
        this.clearPageState  = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState    = hasPageState.bind(this);
        this.savePageState   = savePageState.bind(this);
        switch (true){
            case     props.cid > 0:    this.persistenceId = `SalesReport:catagories:cid:${props.cid}`;    break;
            case     props.partId > 0: this.persistenceId = `SalesReport:catagories:pid:${props.partId}`; break;
            default:                   this.persistenceId = `SalesReport:catagories`;                     break;
        }
        this.state           = this.getInitialState(initialState)
    }

    handleSetFilterParams = (filter) => { this.setState({filter, showResults: true}, this.getData)} 

    getData = () => {
        this.setState({isLoading: true}, ()=> {
            const params = {params: {...this.state.filter, cid:this.props.cid, partId:this.props.partId}}
            API.get( '/reports/salesReport/parts/categories' , params)
            .then(res => {
                
                this.setState({
                    catagories: _.map(res.data, i => {
                        
                        i.in.m  = i.in.s - i.in.c;
                        i.out.m = i.out.s - i.out.c;
                        i.tot.m = i.tot.s - i.tot.c;

                        i.in.ma  = i.in.m  / i.in.q;
                        i.out.ma = i.out.m / i.out.q;
                        i.tot.ma = i.tot.m / i.tot.q;

                        i.in.ma  = _.isNaN(i.in.ma)  ? 0 : i.in.ma;
                        i.out.ma = _.isNaN(i.out.ma) ? 0 : i.out.ma;
                        i.tot.ma = _.isNaN(i.tot.ma) ? 0 : i.tot.ma;

                        i.in.mp  = i.in.m  / i.in.s;
                        i.out.mp = i.out.m / i.out.s;
                        i.tot.mp = i.tot.m / i.tot.s;

                        i.in.mp  = _.isNaN(i.in.mp) ? 0 : i.in.mp * 100;
                        i.out.mp = _.isNaN(i.out.mp) ? 0 : i.out.mp * 100;
                        i.tot.mp = _.isNaN(i.tot.mp) ? 0 : i.tot.mp * 100;

                        i.in.d  = i.in.l - i.in.s;
                        i.out.d = i.out.l - i.out.s;
                        i.tot.d = i.tot.l - i.tot.s;

                        i.in.da  = i.in.d  / i.in.q;
                        i.out.da = i.out.d / i.out.q;
                        i.tot.da = i.tot.d / i.tot.q;

                        i.in.da  = _.isNaN(i.in.da)  ? 0 : i.in.da;
                        i.out.da = _.isNaN(i.out.da) ? 0 : i.out.da;
                        i.tot.da = _.isNaN(i.tot.da) ? 0 : i.tot.da;

                        i.in.dp  = i.in.d  / i.in.l;
                        i.out.dp = i.out.d / i.out.l;
                        i.tot.dp = i.tot.d / i.tot.l;

                        i.in.dp  = _.isNaN(i.in.dp) || i.in.d   < 0 ? 0 : i.in.dp  * 100;
                        i.out.dp = _.isNaN(i.out.dp)                ? 0 : i.out.dp * 100;
                        i.tot.dp = _.isNaN(i.tot.dp) || i.tot.d < 0 ? 0 : i.tot.dp * 100;

                        return i;
                    }), 
                    isLoading: false
                },()=>{this.savePageState()})
            })
        });
    }


    getCsvData = () => {
        const {catagories} = this.state;
        return _.map(catagories, i => {
            return _.assign({
                'Type':     i.n, 
                'Qty':      i.tot.q,
                'List':     i.tot.l,
                'Net':     i.tot.s,
                'Cost':     i.tot.c,
                'Discount': i.tot.d,
                'Margin':   i.tot.m,
            });
        })
    }


    render() {
        const { isLoading, filter, catagories, showResults, overview } = this.state;
        const { classes }                                    =  this.props;

        return (
            <Grid container xs={12}>
                <Grid item xs={12} lg={4} style={{paddingRight:'0.75em'}}>
                    <PaddedPaper>
                        <DateRangeDialog callback={this.handleSetFilterParams}/>
                    </PaddedPaper>
                </Grid>
                {(showResults && catagories) &&
                    <Grid item xs={12} lg={8} style={{padding:'0 0.75em'}}>
                        <PaddedPaper>
                            <BarChart
                                tLabel={(e)=>{return `Net: £${parseFloat(e.raw).toFixed(2)} (${parseInt(e.raw / _.sum(e.dataset.data)*100)}%)`}}
                                data={_.map(_.orderBy(_.filter(catagories,i=>i.tot.s != 0),[i=>i.n.toLowerCase()],['asc']), i => {
                                    return _.assign({
                                        label: i.n,
                                        color: colors.blue,
                                        value: i.tot.s
                                    })
                                })}
                                style={{height:'244px'}}
                                yLabel={i => `£${i}`}
                                xLabel={i => i.slice(0,20)}
                                hideLegend
                            />
                        </PaddedPaper>
                    </Grid>
                }
                {(showResults && catagories) &&
                    <Grid item xs={12} lg={12} style={{paddingTop: '1.5em'}}>
                        <Card className={classes.card} >
                            <CardHeader
                                title={`Part Categories Report For ${
                                    filter.to === filter.from ?
                                        moment(filter.from).format('dddd Do of MMMM') :
                                        `${moment(filter.from).format('DD/MM/YYYY')} To ${moment(filter.to).format('DD/MM/YYYY')}`
                                    }`}
                                titleTypographyProps={{variant:'h6'}}
                                className={classes.header}
                            />
                            <CardContent style={{padding:15}}>
                                <Table size="small" aria-label="a dense table" style={{color:'black'}}>
                                    <TableHead style={{backgroundColor:'#f5f5f5'}}>
                                        <TableRow>
                                            <TableCell align='center'   style={{ width:'10%',  borderBottom: 'none', color:'black'}}></TableCell>
                                            <TableCell align='center'   style={{ width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Qty</b></Typography></TableCell>
                                            <TableCell align='center'   style={{ width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Invoices</b></Typography></TableCell>
                                            <TableCell align='center'   style={{ width:'10%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>List Price</b></Typography></TableCell>
                                            <TableCell align='center'   style={{ width:'10%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Net</b></Typography></TableCell>
                                            <TableCell align='center'   style={{ width:'10%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Cost</b></Typography></TableCell>
                                            <TableCell align='center'   style={{ width:'10%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Margin £</b></Typography></TableCell>
                                            <TableCell align='center'   style={{ width:'10%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Margin %</b></Typography></TableCell>
                                            <TableCell align='center'   style={{ width:'10%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Discount £</b></Typography></TableCell>
                                            <TableCell align='center'   style={{ width:'10%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Discount %</b></Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {_.map(['in','out','tot'], mod => {
                                            let data = {
                                                invoice:   _.sumBy(catagories, i => i[mod].i),
                                                qty:       _.sumBy(catagories, i => i[mod].q),
                                                list:      _.sumBy(catagories, i => i[mod].l),
                                                net:       _.sumBy(catagories, i => i[mod].s),
                                                cost:      _.sumBy(catagories, i => i[mod].c),
                                                margin:    _.sumBy(catagories, i => i[mod].m),
                                                discount:  _.sumBy(catagories, i => i[mod].d),
                                                marginP:   (_.sumBy(catagories, i => i[mod].m)  / _.sumBy(catagories, i => i[mod].s) ) * 100,
                                                discountP: (_.sumBy(catagories, i => i[mod].d)  / _.sumBy(catagories, i => i[mod].l) ) * 100,
                                            };
                                            if (_.isNaN(data.marginP)) data.marginP = 0;
                                            if (_.isNaN(data.discountP)) data.discountP = 0;
                                            let vars = {
                                                in: {
                                                    icon:  <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} primaryColor={colors.green} />,
                                                    label: 'Invoiced', 
                                                    color: null
                                                },
                                                out: {
                                                    icon:  <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} swapOpacity secondaryColor={colors.red} />,
                                                    label: 'Credited', 
                                                    color: colors.red
                                                },
                                                tot: {
                                                    icon:  <AllIcon icon={'equals'} size='medium' style={{display: 'inline-block', paddingRight: '0.5rem', color: '#2d2d2d'}} />,
                                                    label: 'Total', 
                                                    color: null
                                                }
                                            };
                                            let {icon, label, color} = vars[mod];
                                            return (
                                                <TableRow class={classes.row} sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
                                                    <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  borderBottom: 0}}>
                                                        <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                            {icon}
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                {label}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align='center' style={{color}} >
                                                        {parseFloat(data.qty).toFixed(2)}
                                                    </TableCell>
                                                    <TableCell align='center' style={{color}} >
                                                        {parseInt(data.invoice)}
                                                    </TableCell>
                                                    <TableCell align='center' style={{color}} >
                                                        {data.list < 0 ? '-£' : '£'}{parseFloat(Math.abs(data.list)).toFixed(2)}
                                                    </TableCell>
                                                    <TableCell align='center' style={{color}} >
                                                        {data.net < 0 ? '-£' : '£'}{parseFloat(Math.abs(data.net)).toFixed(2)}
                                                    </TableCell>
                                                    <TableCell align='center' style={{color}} >
                                                        {data.cost < 0 ? '-£' : '£'}{parseFloat(Math.abs(data.cost)).toFixed(2)}
                                                    </TableCell>
                                                    <TableCell align='center' style={{color}} >
                                                        {data.margin < 0 ? '-£' : '£'}{parseFloat(Math.abs(data.margin)).toFixed(2)}
                                                    </TableCell>
                                                    <TableCell align='center' style={{color}} >
                                                        {_.isFinite(data.marginP) ? parseFloat(data.marginP).toFixed(2)+'%' : '-'}
                                                    </TableCell>
                                                    <TableCell align='center' style={{color}} >
                                                        {data.discount < 0 ? '-£' : '£'}{parseFloat(Math.abs(data.discount)).toFixed(2)}
                                                    </TableCell>
                                                    <TableCell align='center' style={{color}} >
                                                        {parseInt(Math.round(data.discountP))}%
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                }
                
                
                {(showResults && catagories) &&
                    <Grid container xs={12} lg={12} style={{paddingTop: '1em'}}>
                        <Grid item xs={12} lg={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <DataTable 
                                config={{
                                    key: 'i',
                                    pagination: false,
                                    alternatingRowColours: true,
                                    responsiveimportant: true,
                                    isLoading: isLoading,
                                    sticky: true,
                                    options: {
                                        dataRef: true,
                                        reset: this.resetSearch,
                                        export: {
                                            title:  `Part Categories Report For ${
                                                filter.to === filter.from ?
                                                    moment(filter.from).format('dddd Do of MMMM') :
                                                    `${moment(filter.from).format('DD/MM/YYYY')} To ${moment(filter.to).format('DD/MM/YYYY')}`
                                                }`,
                                            name:   `Parts`,
                                            excel:  true,
                                        }
                                    }
                                }}
                                columns={[
                                    {
                                        heading: 'Name',
                                        field: rowData => 
                                        <Grid container spacing={0}
                                            onClick={()=>{
                                                this.props.history.push('/parts/categories/' + rowData.i);
                                            }}
                                            style={{cursor: 'pointer'}}
                                        >
                                            <Grid item xs={12}>{rowData.n}</Grid>
                                        </Grid>,
                                        dataRef: 'n',
                                        important: true,
                                        truncate: true,
                                    },
                                    {
                                        heading: 'Net %',
                                        field: rowData => `${parseFloat((rowData.tot.s / _.sumBy(catagories,i=>parseFloat(i.tot.s))) * 100).toFixed(2)}%`,
                                        dataRef: rowData => parseFloat((rowData.tot.s / _.sumBy(catagories,i=>parseFloat(i.tot.s))) * 100),
                                        important: true,
                                        sizeToContent: true,
                                        alignment: 'center', 
                                        style: i => ({textAlign: 'center',})
                                    },
                                    {
                                        heading: '',
                                        field: rowData =>
                                            <Grid container xs={12} style={{textAlign: 'left'}}>
                                                <Grid item xs={12}><AllIcon icon={'arrow-down-arrow-up'} size='small' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} primaryColor='#33dd33' />Invoiced</Grid>
                                                <Grid item xs={12} ><AllIcon icon={'arrow-down-arrow-up'} size='small' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} swapOpacity secondaryColor={colors.red} />Credited</Grid>
                                                <Grid item xs={12}><AllIcon icon={'equals'} size='small' style={{display: 'inline-block', paddingRight: '0.5rem', color: '#2d2d2d'}} />Total</Grid>
                                            </Grid>,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Invoice',
                                        field: rowData => 
                                            <Grid container xs={12} style={{textAlign: 'right'}}>
                                                <Grid item xs={12}>{parseInt(rowData.in.i)}</Grid>
                                                <Grid item xs={12} style={{color: colors.red}}>{parseInt(rowData.out.i)}</Grid>
                                                <Grid item xs={12} style={{color:  rowData.tot.i < 0 ? colors.red : null}}>{parseInt(rowData.tot.i)}</Grid>
                                            </Grid>,
                                        dataRef: rowData => {return parseFloat(rowData.tot.i)},
                                        sizeToContent: true,
                                        alignment: 'right'
                                    },
                                    {
                                        heading: 'Qty',
                                        field: rowData => 
                                            <Grid container xs={12} style={{textAlign: 'right'}}>
                                                <Grid item xs={12}>{parseFloat(rowData.in.q).toFixed(2)}</Grid>
                                                <Grid item xs={12} style={{color: colors.red}}>{parseFloat(rowData.out.q).toFixed(2)}</Grid>
                                                <Grid item xs={12} style={{color:  rowData.tot.q < 0 ? colors.red : null}}>{parseFloat(rowData.tot.q).toFixed(2)}</Grid>
                                            </Grid>,
                                        dataRef: rowData => {return parseFloat(rowData.tot.q)},
                                        sizeToContent: true,
                                        alignment: 'right'
                                    },
                                    {
                                        heading: 'List Price',
                                        field: rowData => 
                                            <Grid container xs={12} style={{textAlign: 'right'}}>
                                                <Grid item xs={12}>£{parseFloat(rowData.in.l).toFixed(2)}</Grid>
                                                <Grid item xs={12} style={{color: colors.red}}>-£{parseFloat(Math.abs(rowData.out.l)).toFixed(2)}</Grid>
                                                <Grid item xs={12} style={{color:  rowData.tot.l < 0 ? colors.red : null}}>{rowData.tot.l < 0 ? '-£' : '£'}{parseFloat(rowData.tot.l).toFixed(2)}</Grid>
                                            </Grid>,
                                        dataRef: rowData => {return parseFloat(rowData.tot.l)},
                                        sizeToContent: true,
                                        alignment: 'right'
                                    },
                                    {
                                        heading: 'Net',
                                        field: rowData => 
                                            <Grid container xs={12} style={{textAlign: 'right'}}>
                                                <Grid item xs={12}>£{parseFloat(rowData.in.s).toFixed(2)}</Grid>
                                                <Grid item xs={12} style={{color: colors.red}}>-£{parseFloat(Math.abs(rowData.out.s)).toFixed(2)}</Grid>
                                                <Grid item xs={12} style={{color:  rowData.tot.s < 0 ? colors.red : null}}>{rowData.tot.s < 0 ? '-£' : '£'}{parseFloat(rowData.tot.s).toFixed(2)}</Grid>
                                            </Grid>,
                                        dataRef: rowData => {return parseFloat(rowData.tot.s)},
                                        sizeToContent: true,
                                        alignment: 'right'
                                    },
                                    {
                                        heading: 'Cost',
                                        field: rowData => 
                                            <Grid container xs={12} style={{textAlign: 'right'}}>
                                                <Grid item xs={12}>£{parseFloat(rowData.in.c).toFixed(2)}</Grid>
                                                <Grid item xs={12} style={{color: colors.red}}>-£{parseFloat(Math.abs(rowData.out.c)).toFixed(2)}</Grid>
                                                <Grid item xs={12} style={{color:  rowData.tot.c < 0 ? colors.red : null}}>{rowData.tot.c < 0 ? '-£' : '£'}{parseFloat(rowData.tot.c).toFixed(2)}</Grid>
                                            </Grid>,
                                        dataRef: rowData => {return parseFloat(rowData.tot.c)},
                                        sizeToContent: true,
                                        alignment: 'right'
                                    },
                                    {
                                        heading: 'Margin £',
                                        field: rowData => 
                                            <Grid container xs={12} style={{textAlign: 'right'}}>
                                                <Grid item xs={12}>£{parseFloat(rowData.in.m).toFixed(2)}</Grid>
                                                <Grid item xs={12} style={{color: colors.red}}>-£{parseFloat(Math.abs(rowData.out.m)).toFixed(2)}</Grid>
                                                <Grid item xs={12} style={{color:  rowData.tot.m < 0 ? colors.red : null}}>{rowData.tot.m < 0 ? '-£' : '£'}{parseFloat(rowData.tot.m).toFixed(2)}</Grid>
                                            </Grid>,
                                        dataRef: rowData => {return parseFloat(rowData.tot.m)},
                                        sizeToContent: true,
                                        alignment: 'right'
                                    },
                                    {
                                        heading: 'Margin %',
                                        field: rowData => 
                                            <Grid container xs={12} style={{textAlign: 'right'}}>
                                                <Grid item xs={12}>{parseFloat(rowData.in.mp).toFixed(2)}%</Grid>
                                                <Grid item xs={12} style={{color: colors.red}}>{_.isFinite(rowData.out.mp) ? parseFloat(Math.abs(rowData.out.mp)).toFixed(2)+'%' : '-'}</Grid>
                                                <Grid item xs={12} style={{color:  rowData.tot.mp < 0 ? colors.red : null}}>{_.isFinite(rowData.tot.mp) ? parseFloat(rowData.tot.mp).toFixed(2)+'%' : '-'}</Grid>
                                            </Grid>,
                                        dataRef: rowData => {return parseFloat(rowData.tot.mp)},
                                        sizeToContent: true,
                                        alignment: 'right'
                                    },
                                    {
                                        heading: 'Discount £',
                                        field: rowData => 
                                            <Grid container xs={12} style={{textAlign: 'right'}}>
                                                <Grid item xs={12}>{rowData.in.d < 0 ? '-£' : '£'}{parseFloat(Math.abs(rowData.in.d)).toFixed(2)}</Grid>
                                                <Grid item xs={12} style={{color: colors.red}}>-£{parseFloat(Math.abs(rowData.out.d)).toFixed(2)}</Grid>
                                                <Grid item xs={12} style={{color:  rowData.tot.d < 0 ? colors.red : null}}>{rowData.tot.d < 0 ? '-£' : '£'}{parseFloat(Math.abs(rowData.tot.d)).toFixed(2)}</Grid>
                                            </Grid>,
                                        dataRef: rowData => {return parseFloat(rowData.tot.d)},
                                        sizeToContent: true,
                                        alignment: 'right'
                                    },
                                    {
                                        heading: 'Discount %',
                                        field: rowData => 
                                            <Grid container xs={12} style={{textAlign: 'right'}}>
                                                <Grid item xs={12}>{parseInt(rowData.in.dp)}%</Grid>
                                                <Grid item xs={12} style={{color: colors.red}}>{ _.isFinite(rowData.out.dp) ? parseInt(Math.abs(rowData.out.dp)) : '-'}%</Grid>
                                                <Grid item xs={12} style={{color:  rowData.tot.dp < 0 ? colors.red : null}}>{parseInt(rowData.tot.dp)}%</Grid>
                                            </Grid>,
                                        dataRef: rowData => {return parseFloat(rowData.tot.dp)},
                                        sizeToContent: true,
                                        alignment: 'right'
                                    },
                                ]}
                                rows={_.orderBy(catagories,[i=>i.n.toLowerCase()],['asc'])}
                            />
                        </Grid>
                    </Grid>
                }
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    setPersistence: (key, state)                => dispatch(setPersistence(key, state)),
    deployDialog:   (content, title, size='sm') => dispatch(deployDialog(content, title, null, size)),
    closeDialog:    ()                          => {dispatch(closeDialog())}
})



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SalesReportPartCatagories));