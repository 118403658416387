import React          from 'react';
import { withStyles } from '@material-ui/core/styles';
import API            from 'API';
import moment         from 'moment';
import { connect }    from 'react-redux';

import { Card, CardActions, CardHeader, IconButton, Typography } from '@material-ui/core';

import NotesDialog from 'Components/Common/Dialogs/NotesDialog';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deployDialog }       from 'Actions/Dialog/Dialog';

const styles = theme => ({
    paperGrey : {
        padding : theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding : theme.spacing(3),
        },
        //backgroundColor :'rgba(255, 238, 88, 0.3)'
        backgroundColor :'#fcc6c5'
    },
});

class CustomerImportantInformation extends React.Component {
    deleteNote = () => {
        API.post(`/misc/notes/${this.props.customer.cust_id}/delete`, {type : "customer-important-info"}).then(result => {if (!result.data.error) this.props.success()})
    }

    render() {

        const { customer, success, editable, classes } = this.props;

        return (
            <Card className={classes.paperGrey}>
                <CardHeader
                    title={
                        <Typography component="p">
                            {customer?.cust_important_info && customer?.cust_important_info.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
                        </Typography>
                    }
                    action={editable &&
                        <>
                            <IconButton onClick={()=>{this.props.deployConfirmation('Are you sure you want to delete this important information?', 'Delete Important Information',this.deleteNote)}} >
                                <FALightIcon icon='times' size="small" button noMargin/>
                            </IconButton>
                            <IconButton onClick={() => this.props.deployDialog(<NotesDialog type="customer-important-info" id={customer.cust_id} callback={success} updateText={customer?.cust_important_info} />, 'Edit Important Information', '', 'xs')}>
                                <FALightIcon icon='pencil' size="small" button noMargin/>
                            </IconButton>
                        </>
                    }
                />
                    <CardActions className="float-right pl-2">
                        <Typography component="p" variant="body2" className="textSecondary">
                            <small>Updated by {`${customer.important_information_staff.staff_first_name} ${customer.important_information_staff.staff_last_name}`} on {`${moment(customer.cust_important_info_updated_at).format("DD/MM/YYYY")}`}</small>
                        </Typography>
                    </CardActions>
            </Card>
        )
    }
}



const mapDispatchToProps = dispatch => ({
    deployDialog:       (content, title, variant, size)         => {dispatch(deployDialog(content, title, variant, size))},
    deployConfirmation: (message, title, success)               => dispatch(deployConfirmation(message, title, success))
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(CustomerImportantInformation)); 