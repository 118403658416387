import { Button, Grid } from "@material-ui/core";
import API from "API";
import { deployConfirmation } from "Actions/Confirmation/Confirmation";
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import DataTable from "Components/Common/DataTables/CiDataTable";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import IconHelper from "Helpers/IconHelper";
import React from "react";
import { connect } from "react-redux";
import FormView from 'Components/Common/Forms/FormView/FormView';
import { Typography } from "@material-ui/core";
import { colors } from 'Helpers/ColourHelper';
import _ from 'lodash';


const initialState = () => ({
    isLoading: true,
    surveys: [],
})

class Surveys extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.fetchSurveys();
    }

    fetchSurveys = () => {
        API.get('/survey/surveyInvites', {params: {customerId: this.props.customerId}}).then(res => {
            this.setState({
                surveys: _.map(_.uniqBy(res.data, c=>c.survey.id), s => ({...s.survey,contacts: _.filter(res.data, c => c.survey.id === s.survey.id)})),
                isLoading: false,
            })
        })
    }

    render() {  

        const { surveys, isLoading } = this.state;

        if(isLoading) return ( <LoadingCircle/> );

        return (
            <Grid container spacing={1}>
                <Grid item>
                    <Typography variant="h6" gutterBottom className="float-left">Surveys</Typography>
                </Grid>
                <Grid item xs={12}>
                    <>
                        <DataTable
                            config={{
                                key: 'id',
                                dropRow:{
                                    droppable: true,
                                    columns: [
                                        {
                                            field: 'contact.name'
                                        },
                                        {
                                            field: 'uuid'
                                        },
                                        {
                                            field: 'status',
                                        },
                                        {
                                            field: ()=>''
                                        },
                                        {
                                            field: ({emails}) => emails.length
                                        },
                                        {
                                            actions: i => ([
                                                {
                                                    name: 'View Answers',
                                                    icon: IconHelper.search,
                                                    onClick: ()=>this.props.deployDialog(
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} style={{marginTop: 16}}>
                                                                <FormView formId={i.form.configId} displayForm={i.form.id} />
                                                            </Grid>
                                                            <Grid item xs={12} className='buttonRow'>
                                                                <Button variant='outlined' onClick={()=>this.props.closeDialog()}>Close</Button>
                                                            </Grid>
                                                        </Grid>,
                                                        `Survey Answers - ${i._uuid}`
                                                    )
                                                }
                                            ]),
                                        }
                                    ],
                                    data: 'contacts',
                                    style: {
                                        backgroundColor: `${colors.primary}10`,
                                    }
                                }
                            }}
                            rows={surveys}
                            columns={[
                                {
                                    heading: 'Name',
                                    field: 'name',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Reference',
                                    field: 'ref',
                                },
                                {
                                    heading: 'Status',
                                    field: i => _.capitalize(i.status),
                                },
                                {
                                    heading: 'Contacts',
                                    field: ({contacts}) => contacts.length,
                                    sizeToContent: true,
                                    alignment: 'center',
                                },
                                {
                                    heading: 'emails',
                                    field: i => _.sumBy(i.contacts, c => c.emails.length),
                                    sizeToContent: true,
                                    alignment: 'center',
                                },
                                {
                                    actions: i => ([
                                        {
                                            name: 'View',
                                            icon: IconHelper.view,
                                            link: `/survey/${i.id}`,
                                        }
                                    ])
                                }
                            ]}
                        />
                    </>
                </Grid>
            </Grid>
        )
    }
}


const mapDispatchToProps = dispatch => ({
    deployConfirmation: (content, title, callback, variant=null) => {
        dispatch(deployConfirmation(content, title, callback, null, variant))
    },
    deployDialog: (content, title, size='lg') => {
        dispatch(deployDialog(content, title, 'system', size))
    },
    closeDialog: () => {
        dispatch(closeDialog())
    }
});

export default connect(null, mapDispatchToProps)(Surveys);