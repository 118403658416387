import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import moment               from 'moment';
import { connect }          from 'react-redux';

import DataTable                   from 'Components/Common/DataTables/CiDataTable';
import AllIcon                     from 'Components/Common/Icons/AllIcon';
import PaddedPaper                 from 'Components/Common/Paper/PaddedPaper';
import SnackBar                    from 'Components/Common/SnackBars/SnackBar';
import StickyNotes                 from 'Components/Common/StickyNotes/StickyNotes';
import ProcessOrderDialog          from 'Components/Sales/Order/OrderConfirmed/ProcessOrderDialog';
import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog    from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';
import OrderSearchBlock            from 'Components/Sales/Order/OrderSearch/OrderSearchBlock';
import Order                       from 'Components/Sales/Order/ViewOrder/Order';


import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, Tooltip, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { deployDialog }                     from 'Actions/Dialog/Dialog';
import { deploySnackBar }                   from 'Actions/SnackBar/SnackBar';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import { downloadS3File }                                               from 'Functions/MiscFunctions';
import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';
import JobSnapshot from 'Components/Engineers/Jobs/Misc/JobSnapshot';

const styles = theme => ({
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    },
    custName: {
        '&:hover':{cursor: 'pointer'}
    }
});

const initialState = () => ({
    access: {
        orderSearch: false
    },
    order: {},
    orderStats: [],
    keywords: '',
    status: 'Confirmed',
    dateFrom: null,
    dateTo: null,
    searchResults: {},
    isLoading: false,
    approveDialogOpen: false,
    declineDialogOpen: false,
    redirect: false
});

class OrderConfirmed extends Component {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Sales:confirmed';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        this.getSearchData();
    }

    getSearchData = () => {
        const params = {
            keywords: this.state.keywords,
            status: this.state.status,
            dateFrom: this.state.dateFrom !== null ? moment(this.state.dateFrom).format('YYYY-MM-DD') : null,
            dateTo: this.state.dateTo !== null ? moment(this.state.dateTo).format('YYYY-MM-DD') : null
        }
        this.setState({
            isLoading: true
        },
        () => {
            Promise.all([
                API.get('/staff/my/access/check/customer-order-search'),
                API.get('/sales/orders', {params}),
                API.get('/staff/my/access/check/repeat-customer-order')
            ])
            .then(([accessRes, resultRes, repeatOrderRes]) => {
                this.setState({
                    ...this.state,
                    access: {
                        customerOrderSearch: ( accessRes.data      && accessRes.data.has_access )      || false,
                        repeatOrder:         ( repeatOrderRes.data && repeatOrderRes.data.has_access ) || false
                    },
                    searchResults: resultRes.data,
                    isLoading:     false
                });
            });
        });
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
            status: 'Confirmed',
            dateFrom: null,
            dateTo: null,
        }, 
        () => {
            this.getSearchData();
        });
    }

    handleDownloadOpen = orderId => this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md');

    handleEmailOpen = orderId => this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />, 'Email Previous Documents', '', 'md');

    handleProcessOpen = rowData => {
        const dialog = <ProcessOrderDialog orderId={rowData.order_id} callback={this.getSearchData} />
        this.props.deployDialog(dialog, 'You are about to process this order', 'success');
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    handleDateChange = name => date => {
        this.setState({
            [name]: date
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };
    
    handleShowStickyNote = orderId => { this.props.deployDialog(<StickyNotes orderId={orderId} dialog/>, 'Sticky Notes', null,'xs') }

    handleSetSearch = props => {
        this.setState({
            ...props,
            status: 'Confirmed',
        },this.getSearchData)
    }

    render() {
        const { access } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Order Confirmed
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={12}>
                        <OrderSearchBlock disableSites disableStatus callBack={this.handleSetSearch} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key:                    'order_id',
                                pagination:             true,
                                alternatingRowColours:  true,
                                responsiveimportant:    true,
                                isLoading:              this.state.isLoading,
                                persistenceId:          this.persistenceId
                            }}
                            columns={[
                                {
                                    heading: 'Order Date',
                                    field: rowData => moment(rowData.order_date).format("DD/MM/YYYY"),
                                    important: true,
                                },
                                {
                                    actionsCustomHeader:    true,
                                    important:              true,
                                    alignment:              'left',
                                    heading:                'Reference',
                                    actions: rowData => {
                                        return [
                                            { label: rowData.full_reference,                                link: `/sales/order/view/${rowData.order_id}`,              type:'plainLink'},
                                            { label: rowData?.quotation_full_reference?.full_reference,     link: `/sales/quotation/view/${rowData.order_quote_id}`,    type:'plainLink', class: "textSecondary"}
                                        ]
                                    },
                                },
                                // {
                                //     heading: 'Discount',
                                //     field: rowData => rowData.order_type === 'With Discount',
                                //     important: true,
                                //     sizeToContent: true,
                                //     fieldFormat: 'boolean',
                                //     tooltip: rowData => rowData.order_type,
                                //     alignment: 'center'
                                // },
                                {
                                    heading: 'Customer',
                                    field: rowData =>   <Tooltip
                                                            title={
                                                                <Typography variant="body2">
                                                                    <b>Delivery Address:</b><br />
                                                                    {rowData.order_delivery_cust_address.split('\n').map((item, key) => {
                                                                        return  <span key={key}>
                                                                                    {item}{rowData.order_delivery_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                                                </span>
                                                                    })}
                                                                </Typography>
                                                            }
                                                            placement="left"
                                                        >
                                                            <span className={this.props.classes.custName} onClick={()=>{ this.props.history.push(`/customers/view/${rowData.order_cust_id}`); }}>
                                                                {rowData.order_cust_name}
                                                                {' '}
                                                                <Typography component="div" variant="body2">
                                                                    {rowData.order_delivery_cust_address_name}
                                                                </Typography>
                                                            </span>
                                                        </Tooltip>,
                                    important: true,
                                    truncate: true,
                                },
                                {
                                    heading: 'Files',
                                    field: rowData => 
                                        <Grid container style={{width:'100%'}} spacing={1} xs={12} alignItems='flex-end' justify="center">
                                            {!!rowData.ejs_url && 
                                                <Grid item xs={12} lg={6}>
                                                    <AllIcon 
                                                        icon='user-hard-hat'  
                                                        button 
                                                        noMargin
                                                        tooltip='Engineer Job Sheet'
                                                        onClick={() => {downloadS3File(rowData.ejs_url)}}
                                                    />
                                                </Grid>
                                            }
                                            {!!rowData.po_url && 
                                                <Grid item xs={12} lg={6}>
                                                    <AllIcon 
                                                        icon={(rowData.po_url.includes('.wav') || rowData.po_url.includes('.x-wav')) ? 'cassette-tape' : 'file-alt' } 
                                                        button 
                                                        noMargin
                                                        tooltip='Purchase Order'
                                                        onClick={() => {downloadS3File(rowData.po_url)}}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>,
                                    alignment: 'center',
                                    sizeToContent: true,
                                },
                                {
                                    heading:        'Total',
                                    field: rowData => ( 
                                        <Tooltip 
                                            title={
                                                <div style={{display: 'flex', width: 200}}>
                                                    <div style={{width: '50%', textAlign:'right'}}>
                                                        <Typography variant="body2">Sub Total</Typography>
                                                        <Typography variant="body2">VAT ({rowData.vat_value.vat_value_percentage}%)</Typography>
                                                        <Typography variant="body2">Total</Typography>
                                                    </div>
                                                    <div style={{width: '50%', textAlign:'right'}}>
                                                        <Typography variant="body2">£{rowData.order_sub_total}</Typography>
                                                        <Typography variant="body2">£{rowData.order_vat}</Typography>
                                                        <Typography variant="body2">£{rowData.order_total_incl_vat}</Typography>
                                                    </div>
                                                </div>
                                            } 
                                            placement="left"
                                        >
                                            <span>{rowData.order_total_incl_vat}</span>
                                        </Tooltip>
                                    ),
                                    important:      true,
                                    alignment:      'right',
                                    fieldPrefix:    '£',
                                },
                                {
                                    heading: 'Jobs',
                                    field:      rowData => <>
                                        {rowData?.ej && 
                                            <Tooltip
                                                title={<JobSnapshot jobRef={rowData?.ej?.ref} forms={rowData?.ej?.f} />}
                                                placement="left"
                                            >
                                                <IconButton className={this.props.classes.iconButton} onClick={() => this.props.history.push(`/engineers/jobs/view/${rowData.ej?.id}`) }>
                                                    <AllIcon 
                                                        icon={`user-helmet-safety`}
                                                        button
                                                        noMargin
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </>,
                                    alignment: 'center',
                                    sizeToContent: true,
                                    important: false
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Sticky Notes',                              icon: icons.stickyNote,                      onClick: () => this.handleShowStickyNote(rowData.order_id), hide:rowData.sticky_notes.length === 0, color: _.filter(rowData.sticky_notes, i =>!i.sn_completed).length > 0 && colors.yellow },
                                            {name: 'View',                                      icon: 'search',                              link: '/sales/order/view/' + rowData.order_id},
                                            {name: rowData.locked ? 'locked' :'Process Order',  icon: rowData.locked ? icons.lock : 'check', onClick: () => !rowData.locked && this.handleProcessOpen(rowData)},
                                            {
                                                type: 'actionMenu',
                                                rows: [
                                                    {
                                                        icon: icons.repeat,
                                                        label: "Repeat Order",
                                                        onClick:() => this.props.history.push(`/sales/order/add/repeat/${rowData.order_id}`),
                                                        display: true,
                                                        disabled: !access.repeatOrder
                                                    },
                                                    {
                                                        icon: "download",
                                                        label: "Download",
                                                        onClick: () => this.handleDownloadOpen(rowData.order_id),
                                                        display: true
                                                    },
                                                    {
                                                        icon: "envelope",
                                                        label: "Send Order to Customer",
                                                        onClick: () => this.handleEmailOpen(rowData.order_id),
                                                        display: true
                                                    },
                                                    {
                                                        icon: "file-search",
                                                        label: "View this Customers Orders",
                                                        onClick: () => this.props.history.push(`/customers/view/${rowData.order_cust_id}/Orders`),
                                                        display: true,
                                                        disabled: !access.customerOrderSearch
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                    <Dialog 
                        open={this.state.approveDialogOpen} 
                        onClose={this.handleApproveClose} 
                        fullWidth={true} 
                        maxWidth="lg" 
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title" style={{backgroundColor:'#5da14d'}}>
                            <Typography style={{color:'white', fontSize:'1.25rem'}}>You Are About To <b>Approve</b> This Order</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Order co={this.state.order} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleApproveClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={this.handleApproveSuccess} style={{backgroundColor:'#5da14d', color:'white'}} autoFocus variant="contained">Approve</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog 
                        open={this.state.declineDialogOpen} 
                        onClose={this.handleDeclineClose} 
                        fullWidth={true} 
                        maxWidth="lg" 
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title" style={{backgroundColor:'#D61F26'}}>
                            <Typography style={{color:'white', fontSize:'1.25rem'}}>
                                You Are About To <b>Decline</b> This Order
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Order co={this.state.order} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDeclineClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={this.handleDeclineSuccess} color="secondary" autoFocus variant="contained">Decline</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.emailSnackbarOpen}
                        onClose={this.handleEmailSnackbarClose}
                        message='You have successfully sent the email'
                    />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                                   => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                            => dispatch(setPersistence(key, state)),
    deploySnackBar:     (variant, msg)                          => {dispatch(deploySnackBar(variant, msg))},
    deployDialog:       (content, title, variant, size = 'lg')  => { dispatch(deployDialog(content, title, variant, size))}
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderConfirmed));
