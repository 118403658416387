import React from 'react';
import _ from 'lodash';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { Dialog, DialogTitle,DialogContent, DialogActions, Typography, Grid, FormControl, Button  } from '@material-ui/core';
import API from '../../../API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import {DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE} from 'Constants'
import { gridPageChanged, gridRowsPerPageChanged } from 'Functions/FormFunctions'
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { connect } from 'react-redux';
import logo from 'Assets/Images/clenaware_logo_icon.png';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    searchCriteria: {
        category: '',
        part: ''
    },
    gridPagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    },
    partList: {},
    categoryList: {},
    searchResults: {},
    access: {
        deleteProduct: false,
    },
    isLoading: true,
    technicalLibraryDialogOpen: false,
    removeDialogOpen: false,
    dialogData: {}
});
class EcommerceProductSearch extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState         = clearPageState.bind(this);
        this.getInitialState        = getInitialState.bind(this);
        this.hasPageState           = hasPageState.bind(this);
        this.savePageState          = savePageState.bind(this);
        this.persistenceId          = 'Ecommerce:ecommerceProductSearch';
        this.state                  = this.getInitialState(initialState());
        this.gridPageChanged        = gridPageChanged.bind(this);
        this.gridRowsPerPageChanged = gridRowsPerPageChanged.bind(this);
    }

    componentDidMount(){
        !this.hasPageState() && this.loadInitialData();
    }

    getAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/ecommerce-product-search:delete-ecommerce-product'),
        ])
        .then(([deleteRes]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    deleteProduct:  (deleteRes.data && deleteRes.data.has_access) || false,
                }
            });
        })
    }

    loadInitialData = () => {
        this.getSearchData();
        Promise.all([ 
            API.get('/parts/all' , {params: {active: true, ecommerceOnly: true}}),
            API.get(`/e-commerce/productCategories`)
        ])
        .then(([partRes, categoryRes]) =>  {
            let partList = _.map(partRes.data, el => {
                return _.assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description
                });
            });
            let categoryList = _.map(categoryRes.data, el => {
                return _.assign({
                    value: el.ecom_cat_id,
                    label: el.ecom_cat_name
                });
            });
            this.setState({
                partList,
                categoryList,
                isLoading: false,
            },() => {
                this.getAccess();
                
            });
        });
    };

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/e-commerce/products' , {
                params: {
                    category: this.state.searchCriteria.category,
                    part: this.state.searchCriteria.part,
                },
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    // Order by Part Number
                   let searchResults = _.orderBy(result.data, 'part.part_number', 'asc');

                    this.setState({
                        ...this.state,
                        searchResults,
                        isLoading: false
                    }, () => {this.savePageState()})
                }
            }) 
        })
    }
    
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                [fieldName]: selectedOption && selectedOption.value,
            },
            //gridPagination: initialState().gridPagination
        }, () => this.getSearchData());
    }

    reset = () => {
        this.setState({
            searchCriteria: initialState().searchCriteria,
            //gridPagination: initialState().gridPagination
        }, 
        () => {
            //this.props.history.replace();
            this.getSearchData();
        });
    }

    handleDialogClose = () => {this.setState({removeDialogOpen: false});};
    handleDialogOpen = rowData => {
        this.setState({
            removeDialogOpen: true,
            dialogData: rowData
        });
    };
    handleDialogSuccess = () => {
         API.post(`/e-commerce/products/${this.state.dialogData.ecom_prod_id}/delete`)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    removeDialogOpen: true
                });
            }
            else {
                this.props.deploySnackBar("success", "You have successfully removed this e-commerce product");
                this.setState({
                    ...initialState()
                },
                () => {
                    this.loadInitialData();
                });
            }
        });
    }

    render() {
        const { access, searchCriteria, categoryList, partList, gridPagination, dialogData } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        E-Commerce Products
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect                                        
                                        options={categoryList} 
                                        label='Category *'
                                        onChange={this.handleSelectChange('category')}
                                        value={searchCriteria?.category}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect                                         
                                        options={partList} 
                                        label='Part *'
                                        onChange={this.handleSelectChange('part')}
                                        value={searchCriteria?.part}
                                    />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.reset}
                                            variant="outlined"
                                            color="default"
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <CiDataTable  
                            config={{
                                key:                    'ecom_prod_id',
                                pagination:             true,
                                //page: gridPagination.page,
                                //rowsPerPage: gridPagination.rowsPerPage,
                                //gridPageChanged: this.gridPageChanged,
                                //gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                persistenceId:          this.persistenceId,
                                alternatingRowColours:  true,
                                responsiveImportance:   true,
                                isLoading:              this.state.isLoading
                            }}
                            columns={[
                                {
                                    field: rowData =>   <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}> 
                                                            {rowData.part.default_image && rowData.part.default_image.thumbnail_file_path ? 
                                                                <img src={rowData.part.default_image.thumbnail_file_path} alt={rowData.part.part_description} style={{maxHeight:35, maxWidth:35}} />
                                                            :
                                                                <img src={logo} alt="logo" style={{maxHeight:35, maxWidth:35}}/>
                                                            }
                                                        </div>,
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Part Number',
                                    field: rowData => rowData.part.part_number,
                                    sizeToContent: true,
                                    important: true
                                },
                                {
                                    heading: 'Part Description',
                                    field: rowData => rowData.part.part_description,
                                    important: true,
                                    truncate: true
                                },
                                {
                                    heading: 'Category',
                                    field: rowData =>  _.map(rowData.categories, 'ecom_cat_name').join(', '),
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Price',
                                    field: rowData =>   <>{rowData.part.default_price ?
                                                            <>£{rowData.part.default_price?.price_total} ex. VAT
                                                            <br /> 
                                                            £{rowData.part.default_price?.price_inc_vat} inc. VAT</>
                                                        :
                                                            '-'
                                                        }</>,
                                    sizeToContent: true,
                                    important: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Remove Product', icon: 'trash-alt', onClick: () => this.handleDialogOpen(rowData), disabled: !access.deleteProduct},
                                            {name: 'Update', icon: 'pencil-alt', link: { pathname: '/ecommerce/products/update/' + rowData.ecom_prod_id, state: {searchCriteria, gridPagination}}},
                                            {name: 'View', icon: 'search', link: { pathname: '/ecommerce/products/view/' + rowData.ecom_prod_id, state: {searchCriteria, gridPagination}}},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                        <Dialog 
                            open={this.state.removeDialogOpen} 
                            onClose={this.handleDialogClose} 
                            fullWidth={true} 
                            maxWidth="sm" 
                            scroll="body"
                        >
                            <DialogTitle>
                                <Typography>Remove E-Commerce Product</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="body2">
                                    Are you sure you want to remove <b>{dialogData?.part?.part_description}</b> from the e-commerce products?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleDialogClose} variant="outlined" color="default">Close</Button>
                                <Button onClick={this.handleDialogSuccess} color="secondary" autoFocus variant="contained">Remove</Button>
                            </DialogActions>
                        </Dialog>
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deploySnackBar:     (variant, msg)  => dispatch(deploySnackBar(variant, msg)),
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(EcommerceProductSearch);