import React, { Component } from 'react';
import _                    from 'lodash';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon   from 'Components/Common/Icons/AllIcon';  

import 'Assets/timeLine.css';

const initialState = () => ({
    isLoading: true,
    package: {},
    currentTab: 'Package',
});

class VersionsTab extends Component {
    constructor(props) {
        super(props);    
        this.state = initialState();
    }

    render(){
        
        const { salesPackage } = this.props;

        return(
            <DataTable
                config={{
                    key: 'p_id',
                    style: r => ({fontWeight: r.p_id === salesPackage.p_id && 'bold'}),
                }}
                rows={_.orderBy(salesPackage.all_versions, 'p_version', 'desc')}
                columns={[
                    {
                        heading: 'Version',
                        field: 'p_version',
                        fieldPrefix: 'v',
                        sizeToContent: true,    
                        alignment: 'center'
                    },
                    {
                        heading: 'Name',
                        field: 'p_name',
                    },
                    {
                        heading:       'Discount',
                        field:         i => 
                            <AllIcon
                                icon={i.p_discounted ? icons.true : icons.false}
                                color={i.p_discounted ? colors.green : colors.red}
                                tooltip={i.p_discounted ? `£${i.p_discount} (${Math.ceil(parseFloat(parseFloat(i.p_discount) / parseFloat(i.p_subtotal)) * 100)}%)` : 'No Discount'}
                                noMargin
                            />,
                        sizeToContent: true,
                        alignment:     'center',
                    },
                    {
                        heading:       'Sub Total',
                        field:         'p_subtotal',
                        fieldFormat:   'currency',
                        sizeToContent: true,
                    },
                    {
                        heading:       'VAT',
                        field:         'p_vat',
                        fieldFormat:   'currency',
                        sizeToContent: true,
                    },
                    {
                        heading:       'Total',
                        field:         'p_total',
                        fieldFormat:   'currency',
                        sizeToContent: true,
                    },
                    {
                        actions: r => ([
                            {
                                icon: icons.view,
                                name: 'View',
                                link: `/sales/package/${r.p_id}`,
                                hide: r.p_id === salesPackage.p_id,
                            }
                        ])
                    }
                ]}
            />
        );
    }
}

export default VersionsTab;