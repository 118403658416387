import IconHelper from "./IconHelper";

export const getFileExtension = (fileName) => {
    return fileName.includes('.') && fileName.split('.').pop();
};

export const getMediaType = (fileName) => {
    switch(getFileExtension(fileName).toLowerCase()) {
        case "txt":
        case "doc":
            return "document";
        case 'pdf':
            return "pdf";
        case "jpg":
        case "jpeg":
        case "gif":
        case "png":
            return "image";
        case "mpg":
        case 'mp4':
        case 'mov':
            return "video";
        default:
            return "file";
    }
}

export const getMediaTypeIcon = (fileName) => {
    switch (getMediaType(fileName)){
        case "document":
            return IconHelper.fileWord;
        case "pdf":
            return IconHelper.filePdf;
        case "image":
            return IconHelper.fileImage;
        case "video":
            return IconHelper.fileVideo;
        default:
            return "file";
    }
}

