import React, { Component } from "react";
import API                  from "API";
import { connect }          from 'react-redux';
import { Redirect }         from "react-router-dom";

import { Button, Grid, Typography } from '@material-ui/core/';

import AllIcon        from 'Components/Common/Icons/AllIcon';
import LoadingCircle  from 'Components/Common/LoadingCircle/LoadingCircle';
import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher';
import SnackBar       from 'Components/Common/SnackBars/SnackBar';

import BuildStages from './sections/BuildStages';
import Details     from './sections/Details';

import { colors }        from 'Helpers/ColourHelper';
import icons             from 'Helpers/IconHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { downloadS3File }            from 'Functions/MiscFunctions';

import DownloadCSV from "Components/Common/Buttons/DownloadCSV";
import _ from "lodash";
import BackButton from "Components/Common/Buttons/BackButton";

class ViewSubAssembly extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subAssembly: {
                part: {
                    sub_assembly_history: [],
                },
            },
            isLoading: true,
            snackbarOpen: this.props.location.state && this.props.location.state.successMsg ? true : false,
            succesMsg: this.props.location.state && this.props.location.state.successMsg ? this.props.location.state.successMsg : '',
            redirect: false,
            currentTab: 'd',
            feedBack: [],
            masterExcel: [],
            masterExcelAccess: false,
        };
    }

    componentDidMount() {
        this.getAccess();
    }

    getAccess = () => {
        API.get('/staff/my/access/check/master-excel').then(masterExcel => {
            this.setState({
                masterExcelAccess: masterExcel.data ? masterExcel.data.has_access : false,
            }, this.getSubAssembly);
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (parseInt(this.props.match.params.id) !== parseInt(prevProps.match.params.id)) this.getSubAssembly();
    }

    getSubAssembly = () => {
        this.setState({ isLoading: true }, ()=> {
            let urls = [
                API.get("/subAssemblies/" + this.props.match.params.id) 
            ]
    
            if (this.state.masterExcelAccess){
                urls.push(
                    API.get("/reports/masterExcel")
                )
            }
    
            Promise.all(urls).then(result => {
                if (result[0].data.errors) {
                    this.setState({ redirect: true });
                } else {
                    this.setState({
                        subAssembly: result[0].data,
                        isLoading:   false,
                        masterExcel: (this.state.masterExcelAccess) ? _.map(result[1].data, i => {
                            let isSub = _.find(result[0].data.sub_assembly_parts, { sa_part_id: i.mepo_part_id });
                            let isTop = parseInt(i.mepo_part_id) === parseInt(result[0].data.sa_part_id);
                            return {
                                part_number: i?.part?.part_number ?? 'CS-',
                                "Assembly (y)": !!isTop || !!isSub ? 'Y' : '',
                                'Qty': !!isSub ? isSub.sa_qty : '',
                            };
    
                        }) : [],
    
                    });
                }
            });
        });
    };

    downloadPDF = () => {
        API.get("/subAssemblies/" + this.props.match.params.id + "/pdf").then((result) => {
            if (result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        });
    };

    render() {
        const { isLoading, masterExcel } = this.state;

        if (this.state.redirect) {
            return <Redirect to="/sub-assemblies/search" />;
        }

        if (isLoading) return <LoadingCircle />;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">Sub Assembly</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TabBarSwitcher
                        disableMenu 
                        tabs={[
                            {
                                label:   'Details',
                                content: <Details subAssembly={this.state.subAssembly} />
                            },
                            {
                                label:   'Build Stages',
                                content: <BuildStages subAssembly={this.state.subAssembly} />
                            },
                            {/*
                                label:   'FeedBack',
                                contnet: <BuildFeedBack  subAssembly={this.state.subAssembly} history={this.props.history} refresh={this.getSubAssembly}/>
                            */}
                        ]}
                    />
                </Grid>
                <Grid item xs={12} className="buttonRow">
                    <BackButton history={this.props.history} />
                    {(this.state.masterExcelAccess) &&
                        <DownloadCSV 
                            data    ={masterExcel} 
                            color='primary' 
                            variant='contained' 
                            filename={`Master Excel - ${this.state.subAssembly.part.part_number}.csv`} 
                        >
                            <AllIcon icon={icons.excel} color={colors.white} buttonPrimary/>
                            Master Excel
                        </DownloadCSV>
                    }
                    {this.state.subAssembly.part.pdf_drawing &&
                        this.state.subAssembly.part.pdf_drawing.part_doc_url && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    downloadS3File(
                                        this.state.subAssembly.part.pdf_drawing.url
                                    )
                                }
                            >
                                <AllIcon icon={icons.download} color={colors.white} />
                                Process Document
                            </Button>
                        )}
                    <Button onClick={this.downloadPDF} variant="contained" color="primary">
                        <AllIcon icon="file-pdf" buttonPrimary /> PDF Download
                    </Button>
                </Grid>
                <SnackBar
                    variant="success"
                    anchorOriginVertical="bottom"
                    anchorOriginHorizontal="right"
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message={this.state.succesMsg}
                />
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog:    ()                              => {dispatch(closeDialog())},
    deployDialog:   (content, title, variant, size) => {dispatch(deployDialog(content, title, variant, size))},
})


export default connect(null, mapDispatchToProps)(ViewSubAssembly);
