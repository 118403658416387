import { Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, TextField, InputAdornment } from '@material-ui/core';
import API from 'API';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { getColorArrow, getColorCurrencyChange, getColorPercentageChange, getColorQtyChange, getCurrency, getPercentage, getPlainPercentage, getQty, months } from 'Helpers/ReportsHelper';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import MonthsBlock from './MonthsBlock';
import { getOrdinal } from 'Helpers/TimeHelper';

const currentMonth = moment().format('MMMM')

const initialState = (props={}) => ({
    isLoading: true,
    data: [],
    view: { value: 'year', allowCurrent: props.current },
    showCurrent: props.current,
    partSearch: '',
})

class partsReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.year !== this.props.year) {
            this.setState(initialState(this.props), this.getData);
        }
    }

    getData = () => {
        this.setState({isLoading: true}, ()=>{
            let params = {params: {cid:this.props.cid, partId:this.props.partId, year:this.props.year, current: this.props.current}}
            if (this.state.view.value !== 'year') {
                params.params.month = this.state.view.value;
            }
            API.get('/reports/yoysReport/parts', params)
            .then(response => {
                this.setState({
                    data: response.data, 
                    isLoading: false
                });
            })
        });
    }

    setView = (view) => () => {
        this.setState({view}, this.getData);
    }


    render() {

        const {isLoading, data, view, showCurrent} = this.state;
        const {year} = this.props;

        let
            currentYear = `${year-1}/${year}`,
            lastYear = `${year-2}/${year-1}`;
        if (view.value !== 'year') {
            currentYear = `${months[view.value].slice(0,3)} ${view.value<10 ? year : (year-1)}`;
            lastYear = `${months[view.value].slice(0,3)} ${(view.value<10 ? year : (year-1)) - 1}`;
        }

        let mod = view.allowCurrent && showCurrent ? 'c' : 't';

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MonthsBlock {...this.props} view={view} showCurrent={showCurrent}
                        setCurrentView={(showCurrent,v)=>{this.setState({ showCurrent }, this.setView(v))}}
                        setView={v=>this.setView(v)()}
                    />
                </Grid>
                {isLoading ?
                    <Grid item xs={12}>
                        <LoadingCircle/>
                    </Grid> :
                    <>
                        <Grid item xs={12}>
                            <PaddedPaper style={{height: '100%'}}>
                                <Grid container xs={12}>
                                    <Grid item xs={12} style={{marginBottom:10}}>
                                        <Typography variant='h6' style={{fontWeight: 'bold', textAlign: 'center'}}>Average Discount</Typography>
                                    </Grid>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{textAlign:'left', width:'1%'}}></TableCell>
                                                <TableCell style={{textAlign:'left', width:'11%'}}></TableCell>
                                                <TableCell style={{textAlign:'center', width:'11%'}}><b>Discounted Qty</b></TableCell>
                                                <TableCell style={{textAlign:'center', width:'11%'}}><b>Qty</b></TableCell>
                                                <TableCell style={{textAlign:'center', width:'11%'}}><b>List Price</b></TableCell>
                                                <TableCell style={{textAlign:'center', width:'11%'}}><b>Net</b></TableCell>
                                                <TableCell style={{textAlign:'center', width:'11%'}}><b>Discount</b></TableCell>
                                                <TableCell style={{textAlign:'center', width:'11%'}}><b>Difference</b></TableCell>
                                                <TableCell style={{textAlign:'center', width:'11%'}}><b>Discount %</b></TableCell>
                                                <TableCell style={{textAlign:'center', width:'11%'}}><b>Change</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {_.map(
                                                [
                                                    {name: 'Products', k: 'p'},
                                                    {name: 'Services', k: 's'},
                                                    {name: 'Total', k: 't'},
                                                ], ({name, k}, idx) => 
                                                    <TableRow>
                                                        <TableCell style={{textAlign:'left', borderBottom: idx === 2 && '0px solid black'}}><b>{name}</b></TableCell>
                                                        <TableCell style={{textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {currentYear}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {lastYear}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell style={{textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getQty(data[mod][k].c.d)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getQty(data[mod][k].l.d)}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell style={{textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getQty(data[mod][k].c.q)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getQty(data[mod][k].l.q)}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell style={{textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(parseFloat(data[mod][k].c.p) / data[mod][k].c.q)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(parseFloat(data[mod][k].l.p) / data[mod][k].l.q)}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell style={{textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(parseFloat(data[mod][k].c.t) / data[mod][k].c.q)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(parseFloat(data[mod][k].l.t) / data[mod][k].l.q)}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell style={{textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t))  / data[mod][k].c.q )}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t)) / data[mod][k].l.q)}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell style={{textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getColorCurrencyChange(
                                                                        parseFloat(
                                                                                parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t))  / data[mod][k].c.q 
                                                                        ),
                                                                        parseFloat(
                                                                            parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t)) / data[mod][k].l.q
                                                                        ),
                                                                        true
                                                                    )}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {
                                                                        getColorPercentageChange(
                                                                            parseFloat(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t))  / data[mod][k].c.q ),
                                                                            parseFloat(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t))  / data[mod][k].l.q ),
                                                                            true
                                                                        )
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell style={{textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getPercentage(parseFloat(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t))) , parseFloat(parseFloat(data[mod][k].c.p)))}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getPercentage(parseFloat(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t))) , parseFloat(parseFloat(data[mod][k].l.p)))}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell style={{textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}
                                                                    style={{
                                                                        color: (
                                                                            parseFloat(
                                                                                parseFloat(getPlainPercentage(parseFloat(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t))) , parseFloat(parseFloat(data[mod][k].c.p)))) -
                                                                                parseFloat(getPlainPercentage(parseFloat(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t))) , parseFloat(parseFloat(data[mod][k].l.p))))
                                                                            ) < 0
                                                                        ) ? colors.green : (
                                                                            parseFloat(
                                                                                parseFloat(getPlainPercentage(parseFloat(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t))) , parseFloat(parseFloat(data[mod][k].c.p)))) -
                                                                                parseFloat(getPlainPercentage(parseFloat(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t))) , parseFloat(parseFloat(data[mod][k].l.p))))
                                                                            ) > 0
                                                                            ? colors.red : colors.green
                                                                        )
                                                                    }}
                                                                >
                                                                    {
                                                                        getColorArrow(
                                                                            parseFloat(
                                                                                parseFloat(getPlainPercentage(parseFloat(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t))) , parseFloat(parseFloat(data[mod][k].c.p)))) -
                                                                                parseFloat(getPlainPercentage(parseFloat(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t))) , parseFloat(parseFloat(data[mod][k].l.p))))
                                                                            ), true
                                                                        )
                                                                    }
                                                                    {
                                                                        (
                                                                            parseFloat(
                                                                                parseFloat(getPlainPercentage(parseFloat(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t))) , parseFloat(parseFloat(data[mod][k].c.p)))) -
                                                                                parseFloat(getPlainPercentage(parseFloat(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t))) , parseFloat(parseFloat(data[mod][k].l.p))))
                                                                            ) < 0
                                                                        ) ? '': (
                                                                            parseFloat(
                                                                                parseFloat(getPlainPercentage(parseFloat(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t))) , parseFloat(parseFloat(data[mod][k].c.p)))) -
                                                                                parseFloat(getPlainPercentage(parseFloat(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t))) , parseFloat(parseFloat(data[mod][k].l.p))))
                                                                            ) > 0
                                                                            ? '+' : ''
                                                                        )
                                                                    }
                                                                    {
                                                                        parseFloat(
                                                                            parseFloat(getPlainPercentage(parseFloat(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t))) , parseFloat(parseFloat(data[mod][k].c.p)))) -
                                                                            parseFloat(getPlainPercentage(parseFloat(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t))) , parseFloat(parseFloat(data[mod][k].l.p))))
                                                                        ).toFixed(2)
                                                                    }%
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Card>
                                <CardHeader
                                    avatar={<AllIcon icon={ IconHelper.percent } size='medium' white />}
                                    title={
                                        <Grid container spacing={1} style={{alignItems: 'flex-end'}}>
                                            <Grid item >
                                                <>Discounts Report</>
                                            </Grid>
                                            <Grid item >
                                                <Typography variant="body1" style={{paddingBottom: 1}} >{currentYear} vs. {lastYear} {(this.props.current && mod === 'c') ? ` Up Until ${getOrdinal(moment().format('D'))} ${moment().format('MMMM')}` : ''}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    titleTypographyProps={{variant:'h6'}}
                                    style={{
                                        background:'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)',
                                        color: '#FFF'
                                    }}      
                                />
                                <CardContent style={{padding:5}}>
                                    <Table size="small" aria-label="a dense table" style={{color:'black'}}>
                                        <TableHead style={{backgroundColor:'#f5f5f5'}}>
                                            <TableRow>
                                                <TableCell align='center'   style={{width:'5%', borderBottom: 'none', color:'black'}}></TableCell>
                                                <TableCell align='center'   style={{width:'8%', borderBottom: 'none', color:'black'}}></TableCell>
                                                <TableCell align='center'   style={{width:'13%', borderBottom: 'none', color:'black',  textAlign:'center'}}><Typography style={{fontWeight: '600',  textAlign:'center'}}><b>Qty</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'13%', borderBottom: 'none', borderBottom: 'none', color:'black',  textAlign:'center'}}><Typography style={{fontWeight: '600'}}><b>List Price</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'13%', borderBottom: 'none', borderBottom: 'none', color:'black', textAlign:'center'}}><Typography style={{fontWeight: '600'}}><b>Net</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'13%', borderBottom: 'none', borderBottom: 'none', color:'black', textAlign:'center'}}><Typography style={{fontWeight: '600'}}><b>Discount £</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'13%', borderBottom: 'none', borderBottom: 'none', color:'black', textAlign:'center'}}><Typography style={{fontWeight: '600'}}><b>Difference</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'13%', borderBottom: 'none', borderBottom: 'none', color:'black', textAlign:'center'}}><Typography style={{fontWeight: '600'}}><b>Discount %</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'13%', borderBottom: 'none', borderBottom: 'none', color:'black', textAlign:'center'}}><Typography style={{fontWeight: '600'}}><b>Change</b></Typography></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {_.map([
                                                {
                                                    name: 'Products',
                                                    icon: IconHelper.products,
                                                    k: 'p'
                                                },
                                                {
                                                    name: 'Services',
                                                    icon: IconHelper.services,
                                                    k: 's'
                                                },
                                                {
                                                    name: 'Total',
                                                    icon: IconHelper.total,
                                                    k: 't'
                                                }
                                            ], ({name, icon, k}, idx) => 
                                                <TableRow>
                                                    <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                        <AllIcon icon={icon} size='medium'/> 
                                                        <Typography variant="body1" style={{textTransform: 'capitalize'}}>{name}</Typography>
                                                    </TableCell>
                                                    <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                {currentYear}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {lastYear}
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                {parseFloat(data[mod][k].c.q).toFixed(3)}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {parseFloat(data[mod][k].l.q).toFixed(3)}
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                {getCurrency(data[mod][k].c.p)}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {getCurrency(data[mod][k].l.p)}
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(data[mod][k].c.t)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(data[mod][k].l.t)}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(parseFloat(data[mod][k].c.p - data[mod][k].c.t))}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(parseFloat(data[mod][k].l.p - data[mod][k].l.t))}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell style={{textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getColorCurrencyChange(
                                                                        parseFloat(
                                                                                parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t)) 
                                                                        ),
                                                                        parseFloat(
                                                                            parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t) )
                                                                        ),
                                                                        true
                                                                    )}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {
                                                                        getColorPercentageChange(
                                                                            parseFloat(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t)) ),
                                                                            parseFloat(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t)) ),
                                                                            true
                                                                        )
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell style={{textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getPercentage(parseFloat(parseFloat(data[mod][k].c.p - data[mod][k].c.t)), parseFloat(data[mod][k].c.p))}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getPercentage(parseFloat(parseFloat(data[mod][k].l.p - data[mod][k].l.t)), parseFloat(data[mod][k].l.p))}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell style={{textAlign:'center', borderBottom: idx === 2 && '0px solid black'}}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}
                                                                    style={{
                                                                        color: (
                                                                            parseFloat(
                                                                                getPlainPercentage(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t)), parseFloat(parseFloat(data[mod][k].c.p))) -
                                                                                getPlainPercentage(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t)), parseFloat(parseFloat(data[mod][k].l.p)))
                                                                            ) < 0
                                                                        ) ? colors.green : (
                                                                            parseFloat(
                                                                                getPlainPercentage(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t)), parseFloat(parseFloat(data[mod][k].c.p))) -
                                                                                getPlainPercentage(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t)), parseFloat(parseFloat(data[mod][k].l.p)))
                                                                            ) > 0
                                                                            ? colors.red : colors.green
                                                                        )
                                                                    }}
                                                                >
                                                                    {
                                                                        getColorArrow(
                                                                            parseFloat(
                                                                                getPlainPercentage(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t)), parseFloat(parseFloat(data[mod][k].c.p))) -
                                                                                getPlainPercentage(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t)), parseFloat(parseFloat(data[mod][k].l.p)))
                                                                            ), true
                                                                        )
                                                                    }
                                                                    {
                                                                        (
                                                                            parseFloat(
                                                                                getPlainPercentage(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t)), parseFloat(parseFloat(data[mod][k].c.p))) -
                                                                                getPlainPercentage(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t)), parseFloat(parseFloat(data[mod][k].l.p)))
                                                                            ) < 0
                                                                        ) ? '': (
                                                                            parseFloat(
                                                                                getPlainPercentage(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t)), parseFloat(parseFloat(data[mod][k].c.p))) -
                                                                                getPlainPercentage(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t)), parseFloat(parseFloat(data[mod][k].l.p)))
                                                                            ) > 0
                                                                            ? '+' : ''
                                                                        )
                                                                    }
                                                                    {
                                                                        parseFloat(
                                                                            getPlainPercentage(parseFloat(parseFloat(data[mod][k].c.p) - parseFloat(data[mod][k].c.t)), parseFloat(parseFloat(data[mod][k].c.p))) -
                                                                            getPlainPercentage(parseFloat(parseFloat(data[mod][k].l.p) - parseFloat(data[mod][k].l.t)), parseFloat(parseFloat(data[mod][k].l.p)))
                                                                        ).toFixed(2)
                                                                    }%
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField 
                                placeholder='Search Part...'
                                fullWidth
                                variant="outlined"
                                value={this.state.partSearch}
                                onChange={(e)=>this.setState({partSearch: e.target.value})}
                                inputProps={{
                                    style: {padding: '10px'},
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment  position="end">
                                            <AllIcon icon={IconHelper.refresh} size='small' onClick={()=>this.setState({partSearch: ''})} noMargin/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CiDataTable
                                config={{
                                    key: 'i',
                                    pagination: false,
                                    alternatingRowColours: true,
                                    responsiveimportant: true,
                                    isLoading: isLoading,
                                    sticky:true
                                }}
                                rows={_.orderBy(_.filter(
                                    this.state.partSearch ?
                                    _.filter(data.p, i => i.p.n.toLowerCase().includes(this.state.partSearch.toLowerCase()) || i.p.d.toLowerCase().includes(this.state.partSearch.toLowerCase())) :
                                    data.p
                                    , i => ( parseFloat(i[mod].t.l) !== 0 || parseFloat(i[mod].t.c) !== 0 ) || (parseFloat(i[mod].q.l) !== 0 || parseFloat(i[mod].q.c) !== 0) ), i => i.p.n, 'asc')}
                                columns={[
                                    {
                                        heading: '',
                                        field: 'p.p',
                                        fieldFormat: 'image',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Part',
                                        field: 'p.n',
                                        field: i => 
                                            <Grid container spacing={0}
                                                onClick={()=>{
                                                    this.props.history.push(`/parts/view/${i.p.i}`);
                                                }}
                                                style={{cursor: 'pointer'}}
                                            >
                                                <Grid item xs={12}>
                                                    <Typography variant="body1">{i.p.n}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption">{i.p.d}</Typography>
                                                </Grid>
                                            </Grid>,
                                        dataRef: 'p.n',
                                        important: true,
                                        sizeToContent: true,
                                        truncate: true
                                    },
                                    {
                                        heading: '',
                                        field: () => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{currentYear}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{lastYear}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>Difference</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>Difference %</Typography>
                                                </Grid>
                                            </Grid>,
                                        sizeToContent: true
                                    },
                                    ..._.map([
                                        {
                                            heading: <>Total<br/>List Price</>,
                                            k: 'p',
                                            avg: false 
                                        },
                                        {
                                            heading: <>Avg.<br/>List Price</>,
                                            k: 'p',
                                            avg: true 
                                        },
                                        {
                                            heading: <>Total<br/>Net</>,
                                            k: 't',
                                            avg: false 
                                        },
                                        {
                                            heading: <>Avg.<br/>Net</>,
                                            k: 't',
                                            avg: true 
                                        },
                                    ], ({heading, k, avg}) => 
                                        (
                                            {
                                                heading: heading,
                                                sizeToContent: true,
                                                dataRef: `${mod}.${k}.c`,
                                                field: i => avg ?
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(i[mod][k].c / i[mod].q.c)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(i[mod][k].l / i[mod].q.l)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}> 
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange((i[mod][k].c / i[mod].q.c), (i[mod][k].l / i[mod].q.l))}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange((i[mod][k].c / i[mod].q.c), (i[mod][k].l / i[mod].q.l))}</Typography>
                                                        </Grid>
                                                    </Grid> :
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(i[mod][k].c)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(i[mod][k].l)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}> 
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange(i[mod][k].c, i[mod][k].l )}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(i[mod][k].c, i[mod][k].l )}</Typography>
                                                        </Grid>
                                                    </Grid>,
                                            }
                                        )
                                    ),
                                    {
                                        heading: <>Total<br/>Discount</>,
                                        sizeToContent: true,
                                        dataRef:i => parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)),
                                        field: i => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)))}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)))}</Typography>
                                                </Grid>
                                                <Grid item xs={12}> 
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange(
                                                        parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), 
                                                        parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)),
                                                        true
                                                    )}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(
                                                        parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), 
                                                        parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)),
                                                        true
                                                    )}</Typography>
                                                </Grid>
                                            </Grid>,
                                    },
                                    {
                                        heading: <>Avg.<br/>Discount</>,
                                        sizeToContent: true,
                                        dataRef:i => parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)),
                                        field: i => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)) / parseFloat(i[mod].q.c))}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)) / parseFloat(i[mod].q.l))}</Typography>
                                                </Grid>
                                                <Grid item xs={12}> 
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange(
                                                        parseFloat(parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)) / parseFloat(i[mod].q.c)), 
                                                        parseFloat(parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)) / parseFloat(i[mod].q.l)),
                                                        true
                                                    )}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(
                                                        parseFloat(parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)) / parseFloat(i[mod].q.c)), 
                                                        parseFloat(parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)) / parseFloat(i[mod].q.l)),
                                                        true
                                                    )}</Typography>
                                                </Grid>
                                            </Grid>,
                                    },
                                    {
                                        heading: '% Discount',
                                        sizeToContent: true,
                                        dataRef: i => parseFloat(getPlainPercentage(parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c))),
                                        field: i => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getPercentage(parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c))}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getPercentage(parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)), parseFloat(i[mod].p.l))}</Typography>
                                                </Grid>
                                                <Grid item xs={12} style={{
                                                        height: 28.04 * 2,
                                                        alignContent: 'center'
                                                    }}
                                                    container
                                                >
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" style={{fontSize: '14px', color: 
                                                            parseFloat(
                                                                getPlainPercentage( parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c) ) -
                                                                getPlainPercentage( parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)), parseFloat(i[mod].p.l) ) 
                                                            ) === 0 ? colors.green :
                                                            parseFloat(
                                                                getPlainPercentage( parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c) ) -
                                                                getPlainPercentage( parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)), parseFloat(i[mod].p.l) ) 
                                                            ) < 0 ? colors.green : colors.red
                                                        }}
                                                    >
                                                        {
                                                            getColorArrow(
                                                                parseFloat(
                                                                    getPlainPercentage( parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c) ) -
                                                                    getPlainPercentage( parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)), parseFloat(i[mod].p.l) ) 
                                                                ), true
                                                            )
                                                        }
                                                        {
                                                                parseFloat(
                                                                getPlainPercentage( parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c) ) -
                                                                getPlainPercentage( parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)), parseFloat(i[mod].p.l) ) 
                                                            ) === 0 ? '' :
                                                            parseFloat(
                                                                getPlainPercentage( parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c) ) -
                                                                getPlainPercentage( parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)), parseFloat(i[mod].p.l) ) 
                                                            ) > 0 ? '+' : '-'
                                                        }
                                                        {
                                                            parseFloat(
                                                                Math.abs(
                                                                    parseFloat(
                                                                        getPlainPercentage( parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c) ) -
                                                                        getPlainPercentage( parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)), parseFloat(i[mod].p.l) ) 
                                                                    )
                                                                )
                                                            ).toFixed(2)
                                                        }%
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>,
                                    },
                                    ..._.map([
                                        {
                                            heading: <>Total<br/>Qty</>,
                                            k: 'q',
                                        },
                                        {
                                            heading: 'Discounted',
                                            k: 'd',                                        
                                        },

                                    ], ({heading, k, avg}) => 
                                        (
                                            {
                                                heading: heading,
                                                sizeToContent: true,
                                                dataRef: `${mod}.${k}.c`,
                                                field: i => 
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getQty(i[mod][k].c)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getQty(i[mod][k].l)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}> 
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorQtyChange(i[mod][k].c, i[mod][k].l, k === 'd' )}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(i[mod][k].c, i[mod][k].l, k === 'd' )}</Typography>
                                                        </Grid>
                                                    </Grid>,
                                            }
                                        )
                                    ),
                                    {
                                        heading: <>Not.<br/>Discounted</>,
                                        sizeToContent: true,
                                        dataRef: i => i[mod].q.c - i[mod].d.c,
                                        field: i => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getQty(i[mod].q.c - i[mod].d.c)}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getQty(i[mod].q.l - i[mod].d.l)}</Typography>
                                                </Grid>
                                                <Grid item xs={12}> 
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorQtyChange(i[mod].q.c - i[mod].d.c, i[mod].q.l - i[mod].d.l)}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(i[mod].q.c - i[mod].d.c, i[mod].q.l - i[mod].d.l )}</Typography>
                                                </Grid>
                                            </Grid>,
                                    }
                                ]}
                            />
                        </Grid>
                    </>    
            }
            </Grid>
        )
    }
}

export default partsReport;