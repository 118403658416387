import React, { Component} from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import API from '../../../../API';
import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DataTable from '../../../Common/DataTables/DataTable';
import moment from 'moment';
import {statusColour} from './../../../../Helpers/ColourHelper'

class PartsPurchaseOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            open: false
        }
    }

    componentDidMount(){
        // this.getPPOforPart(); 
    }

    getPPOforPart = () => {
        API.get(`/parts/${this.props.partId}/partsOrders`)
        .then((result) => {
            _.map(result.data, (ppo) => {
                ppo.colour = statusColour(ppo.ppo_detail_schedule_delivery_status);
            });
            this.setState({
                rows: result.data,
                open: true
            })
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.searchResults !== prevProps.searchResults) {
            this.setState({
                rows: _.orderBy(this.props.searchResults, 'dco_date_added', 'desc')
            });
        }
    }

    handleOpen = () => {
        if(!this.state.open){
            this.getPPOforPart();
        } else {
            this.setState({
                open: false
            });
        }
    }

    render() {
        const { rows, open} = this.state;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={this.handleOpen}>
                    <Typography>Parts Purchase Orders</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {open &&
                        <>
                            {rows.length > 0 ? 
                                <DataTable 
                                    config={{
                                        key: 'ppo_id',
                                        pagination: false,
                                        plainHeader: true,
                                    }}
                                    columns={[
                                        {
                                            heading: 'Parts Order Date',
                                            field: row => moment(row.ppo_date).format("DD-MM-YYYY")
                                        },
                                        {
                                            heading: 'Parts Order Number',
                                            field: row => <Link to={`/parts-order/view/${row.ppo_id}`} className='blueLink'>{row.ppo_number + '-' + row.ppo_number_amendment}</Link>
                                        },
                                        {
                                            heading: 'Status',
                                            field: row => row.ppo_detail_schedule_delivery_status,
                                            cellProps: rowData => {
                                                return ({
                                                    style: {
                                                        color: rowData.colour
                                                    }
                                                });
                                            }
                                        },
                                        {
                                            heading: 'Qty Ordered',
                                            field: row => row.original_part_quantity,
                                            alignment: 'right'                                    
                                        },
                                        {
                                            heading: 'Qty Received',
                                            field: row => row.received_total_qty,
                                            alignment: 'right'                                    
                                        }
                                    ]}
                                    rows={rows}
                                />
                            :
                                <Typography>There are 0 Parts Purchase Orders found.</Typography>
                            }
                        </>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default PartsPurchaseOrders;
