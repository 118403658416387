import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import API from 'API';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { closeDialog } from 'Actions/Dialog/Dialog';

const useStyles = makeStyles(theme => ({
    settingsHeading: {
        paddingTop: theme.spacing(2)
    }
}));

const CustomerPaymentTermsAmendDialog = ({rowData, rowData:{payment_term_id}, paymentMethodsList, callback}) => {

    const classes = useStyles();

    const initialState = {
        formData: {
            name: '',
            upfrontPayment:     false,
            allowEpdq:          false,
            allowStripe:        false,
            allowPaypal:        false,
            allowCheque:        false,
            allowBankTransfer:  false,
            allowAccount:       false,
            allowDirectDebit:   false,
            allowCash:          false,
            allowRi:            rowData.payment_terms_allow_recurring_invoice ? true : false,
        },
        formErrors: {},
        isLoading: true
    };

    let [state, setState] = useState(initialState);

    const {formData, formData:{name, upfrontPayment}, formErrors, isLoading} = state;

    const dispatch = useDispatch();

    const handleClose = () => dispatch(closeDialog());

    const handleSelectChange = selectedOption => {
        setState(state => ({
            ...state,
            formData: {
                name,
                upfrontPayment:     false,
                allowEpdq:          false,
                allowStripe:        false,
                allowPaypal:        false,
                allowCheque:        false,
                allowBankTransfer:  false,
                allowAccount:       false,
                allowDirectDebit:   false,
                allowCash:          false,
                allowRi:            rowData.payment_terms_allow_recurring_invoice ? true : false,
            }
        }));
    }

    const handleChange = e => {
        const {name, value} = e.target;

        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleCheck = e => {
        const {name, value} = e.target;

        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: JSON.parse(value)
            }
        }));
    }

    const generatePaymentMethodCheckbox = el => {
        let name = '', label = '';

        switch(el.cpm_name) {
            case 'BarclayCard EPDQ':
                name = 'allowEpdq';
                label = 'Allow BarclayCard EPDQ';
            break;
            case 'Stripe':
                name = 'allowStripe';
                label = 'Allow Stripe';
            break;
            case 'PayPal':
                name = 'allowPaypal';
                label = 'Allow PayPal';
            break;
            case 'Cheque':
                name = 'allowCheque';
                label = 'Allow Cheque'
            break;
            case 'Bank Transfer':
                name = 'allowBankTransfer';
                label = 'Allow Bank Transfer';
            break;
            case 'Account':
                name = 'allowAccount';
                label = 'Allow Account';
            break;
            case 'Direct Debit':
                name = 'allowDirectDebit';
                label = 'Allow Direct Debit';
            break;
            case 'Cash':
                name = 'allowCash';
                label = 'Allow Cash';
            break;
            default:
                throw new Error('unhandled payment method');
        }

        return(
            <FormGroup row key={el.cpm_id}>
                <FormControlLabel
                    control={
                        <Checkbox 
                            id={name}
                            name={name}
                            onChange={handleCheck}
                            checked={formData[name] && true} 
                            value={!formData[name]}
                            color="primary"
                        />
                    }
                    label={label}
                />
            </FormGroup>
        );
    }

    const handleSubmit = () => {
        setState(state => ({
            ...state,
            isLoading: true
        }));
        API.post(`/customers/paymentTerms/${payment_term_id}`, formData)
        .then(result => {
             if(result.data.errors) {
                 setState(state => ({
                    ...state, 
                    formErrors: formatValidationErrors(result.data.errors),
                    isLoading: false
                }));
             } else if (result.data.success) {
                dispatch(deploySnackBar('success', 'You have successfully updated payment term'));
                handleClose();
                if(callback) callback();
             }
        });
    }

    useEffect(() => {
        const {payment_term_name, payment_term_upfront_payment, payment_method_epqd, payment_method_stripe, payment_method_paypal, payment_method_cheque, payment_method_bank_transfer, 
            payment_method_account, payment_method_direct_debit, payment_method_cash} = rowData;

        setState(state => ({
            ...state,
            formData: {
                name: payment_term_name,
                upfrontPayment: payment_term_upfront_payment,
                allowEpdq: payment_method_epqd,
                allowStripe: payment_method_stripe,
                allowPaypal: payment_method_paypal,
                allowCheque: payment_method_cheque,
                allowBankTransfer: payment_method_bank_transfer,
                allowAccount: payment_method_account,
                allowDirectDebit: payment_method_direct_debit,
                allowCash: payment_method_cash
            },
            isLoading: false
        }));

    }, [rowData]);

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <React.Fragment>
                    <form noValidate autoComplete="off">
                        <TextField
                            id="name"
                            name="name"
                            label="Name *"
                            value={name}
                            onChange={handleChange}
                            error={formErrors && formErrors['name'] && true}
                            helperText={formErrors && formErrors['name']}
                            margin="normal"
                            fullWidth
                        />
                        <FormControl fullWidth margin="normal">
                            <AutoCompleteSelect 
                                options={[
                                    {'value': 1, 'label': 'Upfront Payment'},
                                    {'value': 0, 'label': 'Credit'}
                                ]}
                                label='Payment Settings *'
                                onChange={handleSelectChange}
                                value={upfrontPayment}
                                noClear
                            />
                        </FormControl>
                        <br></br><br></br>
                        <Typography variant="subtitle1" className={classes.settingsHeading}>Payment Methods:</Typography>
                        {paymentMethodsList.map(el => { 
                            if(el.cpm_upfront_payment === upfrontPayment) {
                                return generatePaymentMethodCheckbox(el);
                            } else {
                                return '';
                            }
                        })}
                        <Typography variant="subtitle1" className={classes.settingsHeading}>Other:</Typography>
                        <FormGroup row >
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        id={'allowRi'}
                                        name={'allowRi'}
                                        onChange={handleCheck}
                                        checked={formData.allowRi} 
                                        value={!formData.allowRi}
                                        color="primary"
                                    />
                                }
                                label={'Allow On Recurring Invoices'}
                            />
                        </FormGroup>
                        <div className='buttonRow'>
                            <Button 
                                onClick={handleClose}
                                variant="outlined" 
                                color="default" 
                            >
                                Close
                            </Button>
                            <Button 
                                onClick={handleSubmit}
                                variant="contained" 
                                color="primary" 
                                disabled={!name && true}
                            >
                                Update
                            </Button>
                        </div>
                    </form>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default CustomerPaymentTermsAmendDialog;