import _ from 'lodash';
import React from 'react';

import { ExpansionPanel, Grid, Typography, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MediaTable from 'Components/Common/DataTables/MediaTable';
import Textarea from 'Components/Common/Inputs/Textarea';

import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import InfoBox from 'Components/Common/reports/InfoBox';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';
import { connect } from 'react-redux';

const initialState = props => {
    return {
        isLoading: true,
        cr: props.cr,
        returnConditions: [],
        parts: [],
        editFormData: {
            id: null,
            condition: null,
            notes: null,
            notesAfter: null,
            existingFiles: null,
            files:[],
            filesAfter: [],
            removedFiles: []
        }
    }
}

class GoodsInspected extends React.Component {
    constructor(props) {
        super(props);

        this.state = initialState(props);
    }
   
    componentDidMount() {
        this.getData();
    }

    
    getData = () => {
        Promise.all([
            API.get(`/customerReturns/returns/returnConditions`),
            API.get('/parts/all', { params: { use: 'partSearch', withStock: true, withImage: true } })
        ]).then(res => {
            this.setState({ 
                returnConditions: _.map(_.orderBy(res[0].data, [r => {return {pass: 0,  warning: 1, Fail:2}[r.crrc_grade]}, 'crrc_condition'], ['asc', 'asc']), i => ({value: i.crrc_condition, label: `(${i.crrc_grade === 'pass' ? 'Pass' : i.crrc_grade === 'warning' ? 'Pass With Warning' : 'Fail'}) - ${i.crrc_condition}`})),
                parts: res[1].data,
                isLoading: false 
            });
        })
    }

    render() {

        const { isLoading, cr } = this.state;
    
        if (isLoading) {
            return (
                <LoadingCircle/>
            )
        }

        return (
            <Grid container spacing={2}>
                <InfoBox rowSize={4} title='Inspected' value={parseFloat(_.sumBy(cr.collection.details, i => (_.sumBy(i.inspections, j => parseFloat(j.cri_qty))))).toFixed(3)} icon={IconHelper.inspection} />
                <InfoBox rowSize={4} title='Credit Note' value={parseFloat(_.sumBy(cr.collection.details, i => (_.sumBy(_.filter(i.inspections, j => j.cri_outcome === 'Refund'), j => parseFloat(j.cri_qty))))).toFixed(3)} icon={IconHelper.creditNote} />
                <InfoBox rowSize={4} title='Repair' value={parseFloat(_.sumBy(cr.collection.details, i => (_.sumBy(_.filter(i.inspections, j => j.cri_outcome === 'Repair'), j => parseFloat(j.cri_qty))))).toFixed(3)} icon={IconHelper.parts} />
                <InfoBox rowSize={4} title='Replace' value={parseFloat(_.sumBy(cr.collection.details, i => (_.sumBy(_.filter(i.inspections, j => j.cri_outcome === 'Replace'), j => parseFloat(j.cri_qty))))).toFixed(3)} icon={IconHelper.sales} />
                <Grid item xs={12}>
                    <CiDataTable
                        config={{
                            key: 'crcd_id',
                            dropRow: {
                                droppable: rowData => parseFloat(rowData.crcd_recieved) > 0,
                                hideDropArrow: true,
                                columns: [
                                    {
                                        colSpan: 6,
                                        field: inspection =>
                                            <Grid container spacing={1}>
                                                {
                                                    (inspection.cri_sn || inspection.cri_wvl) &&
                                                    <>
                                                        <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                            {
                                                                ( inspection.cri_sn && 'Serial Number' ) || 'Warranty Void Label'
                                                            }:
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            {inspection.cri_sn || inspection.cri_wvl}
                                                        </Grid>
                                                    </>
                                                }
                                                <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                    Inspected On:
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {clenyDate(inspection.cri_datetime)}
                                                </Grid>
                                                <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                    Inspected By:
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {inspection.cri_staff}
                                                </Grid>
                                                {inspection.cri_edited_staff &&
                                                    <>

                                                        <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                            Updated On:
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            {clenyDate(inspection.cri_edited_datetime)}
                                                        </Grid>
                                                        <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                            Updated By:
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            {inspection.cri_edited_staff}
                                                        </Grid>
                                                    </>
                                                }
                                                <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                    Condition:
                                                </Grid>
                                                <Grid item xs={4} style={{
                                                    color: inspection.cri_condition_grade === 'fail' ? colors.red : (inspection.cri_condition_grade === 'warning' ? colors.orange : colors.green)
                                                }}>
                                                    {inspection.cri_condition}
                                                </Grid>
                                                <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                    Outcome:
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {inspection.cri_outcome}
                                                </Grid>
                                                {   inspection.cri_outcome === 'Repair' &&
                                                    inspection.cri_parts &&
                                                    JSON.parse(inspection.cri_parts).length > 0 &&
                                                    <Grid item xs={12}>
                                                        {   
                                                            _.filter(JSON.parse(inspection.cri_parts), p => _.find(this.state.parts, {part_id: p.part})?.stockable).length > 0 &&
                                                            <ExpansionPanel >
                                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                    Recommended Parts
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails>
                                                                    <CiDataTable
                                                                        config={{
                                                                            key: 'part_id',
                                                                        }}
                                                                        columns={[
                                                                            {
                                                                                heading:       '',
                                                                                field:         'default_image.file_path',
                                                                                fieldFormat:   'image',
                                                                                sizeToContent: true,
                                                                            },
                                                                            {
                                                                                heading:      'Part Number',
                                                                                field:        'part_number',
                                                                                sizeToContent: true,
                                                                            },
                                                                            {
                                                                                heading:      'Part Description',
                                                                                field:        'part_description',
                                                                                truncate:     true,
                                                                            },
                                                                            {
                                                                                heading:      'Qty',
                                                                                field:        'qty',
                                                                                sizeToContent: true,
                                                                                fieldFormat: 'decimal:3'
                                                                            }
                                                                        ]}
                                                                        rows={
                                                                            _.map(_.filter(JSON.parse(inspection.cri_parts), p => _.find(this.state.parts, {part_id: p.part}).stockable), p => {
                                                                                return {
                                                                                    ..._.find(this.state.parts, {part_id: p.part}),
                                                                                    qty: p.qty,
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                        }
                                                        {   
                                                            _.filter(JSON.parse(inspection.cri_parts), p => !_.find(this.state.parts, {part_id: p.part})?.stockable).length > 0 &&
                                                            <ExpansionPanel >
                                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                    Recommended Services
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails>
                                                                    <CiDataTable
                                                                        config={{
                                                                            key: 'part_id',
                                                                        }}
                                                                        columns={[
                                                                            {
                                                                                heading:       '',
                                                                                field:         'default_image.file_path',
                                                                                fieldFormat:   'image',
                                                                                sizeToContent: true,
                                                                            },
                                                                            {
                                                                                heading:      'Part Number',
                                                                                field:        'part_number',
                                                                                sizeToContent: true,
                                                                            },
                                                                            {
                                                                                heading:      'Part Description',
                                                                                field:        'part_description',
                                                                                truncate:     true,
                                                                            },
                                                                            {
                                                                                heading:      'Qty',
                                                                                field:        'qty',
                                                                                sizeToContent: true,
                                                                                fieldFormat: 'decimal:3'
                                                                            }
                                                                        ]}
                                                                        rows={
                                                                            _.map(_.filter(JSON.parse(inspection.cri_parts), p => !_.find(this.state.parts, {part_id: p.part}).stockable), p => {
                                                                                return {
                                                                                    ..._.find(this.state.parts, {part_id: p.part}),
                                                                                    qty: p.qty,
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                        }
                                                    </Grid>
                                                }
                                                <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                    Notes:
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Textarea
                                                        value={inspection.cri_notes}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                {inspection.files.length > 0 &&
                                                    <>
                                                        <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                            Files:
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <MediaTable
                                                                config={{ itemPerRow: 3 }}
                                                                media='file_path'
                                                                items={inspection.files}
                                                            />
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                    }
                                ],
                                data: 'inspections'
                            }
                        }}
                        rows={_.filter(cr.collection.details, i => i.inspections.length > 0)}
                        columns={[
                            {
                                heading:       '',
                                field:         'part.default_image.thumbnail_file_path',
                                fieldFormat:   'image',
                                sizeToContent: true,
                            },
                            {
                                heading:       'Part Number',
                                field:         'part.part_number',
                                sizeToContent: true,
                            },
                            {
                                heading:       'Part Description',
                                field:         i => {
                                                    let sn = [];
                                                    let wvl = [];

                                                    _.each(i.received, j => {
                                                        sn  = [...sn,  ...JSON.parse(j.crcdr_serial_numbers)];
                                                        wvl = [...wvl, ...JSON.parse(j.crcdr_warranty_void_labels)];
                                                    })

                                                    return (    
                                                        <>
                                                            {i.part.part_description}<br />
                                                            {sn.length > 0 &&
                                                                <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>
                                                                    {_.map(sn, j => <>{j}<br/></>)}
                                                                </Typography>
                                                            }
                                                            {wvl.length > 0 &&
                                                                <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>
                                                                    {_.map(wvl, j => <>{j}<br/></>)}
                                                                </Typography>
                                                            }
                                                        </>
                                                    )
                                                },
                            },
                            {
                                heading: 'Inspected',
                                field: i => parseFloat(_.sumBy(i.inspections, j => parseFloat(j.cri_qty))).toFixed(3),
                                alignment: 'center',
                                sizeToContent: true,
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)            => dispatch(deploySnackBar(variant, msg))
});

export default connect(null, mapDispatchToProps)(GoodsInspected);
