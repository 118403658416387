import React, {Component} from 'react';
import { Redirect }       from 'react-router-dom';
import API                from 'API';

import { Typography, Grid, Button, TextField } from '@material-ui/core';

import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar           from 'Components/Common/SnackBars/SnackBar';
import Textarea           from 'Components/Common/Inputs/Textarea';
import BackButton         from 'Components/Common/Buttons/BackButton';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = {
    formData: {
        name: '',
        text: ''
    },
    template: {},
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    redirect: false
}

class UpdateStockReturnEmailTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getTemplate();
    }

    getTemplate = () => {
        API.get(`/supplierStockReturns/emailTemplates/${this.props.match.params.id}`)
        .then(result => {
            if (result.data.errors) {
                this.setState({ redirect: true });
            } else if(result.data) {
                this.setState({
                    template: result.data,
                    formData: {
                        ...this.state.formData,
                        name: result.data.sr_email_name,
                        text: result.data.sr_email_text,
                    }
                });
            }
        });
    };

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        API.post(`/supplierStockReturns/emailTemplates/${this.props.match.params.id}`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.getTemplate();
            }
            this.props.scrollToTop();
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }
       
    render() {
        const { formErrors } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/supplier-stock-return/stock-return-email-templates/" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Update Email Template
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                {formErrors && formErrors.generic && (
                                    <React.Fragment>
                                        <Typography component={"div"} style={{color: colors.red}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </React.Fragment>
                                )}
                                <form noValidate autoComplete="off">
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Name *"
                                        value={this.state.formData.name}
                                        error={formErrors && formErrors['name'] && true}
                                        helperText={formErrors && formErrors['name']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <Textarea
                                        id="text"
                                        name="text"
                                        label="Text *"
                                        value={this.state.formData.text}
                                        rows={10}
                                        error={formErrors && formErrors['text']}
                                        onChange={this.handleChange}
                                    />
                                    <div className='buttonRow'>
                                        <BackButton props={this.props} />
                                        <Button onClick={this.handleConfirmationOpen}
                                                variant="contained"
                                                color="primary">
                                            Update
                                        </Button>
                                    </div>
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>    
                    <ConfirmationDialog 
                        open={this.state.confirmationOpen} 
                        success={this.handleConfirmationSuccess} 
                        close={this.handleConfirmationClose} 
                        title="Update Email Template?" 
                        message="Are you sure you want to update this email template?"
                    />
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarOpen}
                        onClose={this.handleSnackbarClose}
                        message='You have successfully updated this email template'
                    />
                </Grid>
            );
        }
    }
}

export default UpdateStockReturnEmailTemplate;