import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import moment from "moment";
import DateTimePicker from "Components/Common/DatePickers/DateTimePicker";
import { connect } from "react-redux";
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import API from "API";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";

const initialState = () => ({
    formData: {
        schedule: 'now',
        dateTime: moment()
    }
})

class ProcessEmailCampaignDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    handleSchedule = (v) => {
        this.setState({
            formData: {
                schedule: v.value,
                dateTime: moment()
            }
        });
    }

    handleChangeDateTime = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                dateTime: moment(e)
            }
        });
    }

    handleProcess = () => {
        API.post(
            `/marketing/campaigns/emailCampaign/${this.props.campaign.ec_id}/process`,
            {
                dateTime: this.state.formData.dateTime.format('YYYY-MM-DD HH:mm:ss')
            }
        ).then(this.props.onClose)
    }

    render() {

        const { campaign } = this.props;
        const { formData } = this.state;

        return (
            <Grid container >
                <Grid item xs={12}>
                    <AutoCompleteSelect
                        label="Schedule Email"
                        value={formData.schedule}
                        options={[
                            {
                                label: 'Schedule Now',
                                value: 'now'
                            },
                            {
                                label: 'Schedule Future Date',
                                value: 'later'
                            }
                        ]}
                        fullWidth
                        onChange={this.handleSchedule}
                        noClear
                    />
                    <Typography variant="caption">
                        * Please Note: emails may take up to 1 hour to send.
                    </Typography>
                </Grid>
                {formData.schedule === 'later' &&
                    <Grid item xs={12}>
                        <DateTimePicker
                            value={formData.dateTime}
                            onChange={this.handleChangeDateTime}
                            label="Schedule"
                        />
                    </Grid>
                }
                <Grid item xs={12} style={{marginTop: '1em'}}>
                    <PaddedPaper>
                        <Typography variant="body1">Schedule Campaign</Typography>
                        <Typography variant="body2">
                            Email campaign run #{campaign.runs.length + 1} will be scheduled to send on {
                                formData.schedule === 'later' ? formData.dateTime.format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')
                            } at {
                                formData.schedule === 'later' ? formData.dateTime.format('HH:mm') : moment().add(5,'minute').format('HH:mm')
                            } 
                        </Typography>
                        <div className="buttonRow" >
                            <Button
                                variant="outlined"
                                onClick={this.props.onClose}
                            >Close</Button>
                            <Button 
                                variant="contained" 
                                color="primary"
                                onClick={() => this.props.deployConfirmation(
                                    `Are you sure you want to process this email campaign run?`,
                                    `Schedule Email Campaign Run`,
                                    this.handleProcess,
                                )}
                            >Process</Button>
                        </div>
                    </PaddedPaper>
                </Grid>                
            </Grid>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success, variant) => dispatch(deployConfirmation(message, title, success, null, variant))
    }
}

export default connect(null, mapDispatchToProps)(ProcessEmailCampaignDialog);