import React, { useEffect, useRef, useState } from 'react';
import _ from "lodash";
import ReactPlayer from 'react-player'

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/styles';

import API from "API";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import { closeDialog } from "Actions/Dialog/Dialog";
import { useDispatch } from "react-redux";
import { Button, TextField } from "@material-ui/core";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import Alert from '../Alert/Alert';
import { colors } from 'Helpers/ColourHelper';
import { S3_BUCKET_URL } from 'Constants';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    conversationBlock: {    
        cursor: 'pointer',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'inline-block',
        border: '1px solid',
        maxWidth: '80%',
    }
  }));

const WildixCallTranscription = ({ id }) => {

    const itemsRef = useRef([]);

    const [data, setData] = useState([]),
        [search, setSearch] = useState({}),
        [loading, setLoading] = useState(true),
        [playbackPosition, setPlaybackPosition] = useState({});

    const classes = useStyles();

    const dispatch = useDispatch();

    const speakerColors = [colors.blue, colors.red, colors.green];

    const handleLoadData = () => {
        setLoading(true);
        API.get(`/voip/${id}/transcriptions`).then((res) => {
            if (res?.data) {
                setData(res.data);
                setLoading(false);
            }
        });
    };

    const handleLogPosition = (index, position) => {
        if (playbackPosition?.[index] !== position) {
            setPlaybackPosition({
                ...playbackPosition,
                [index]: position
            });
        }
    }

    useEffect(() => {
        handleLoadData();
    }, [id]); /* eslint-disable-line */

    return (
        <>
            {(loading && <LoadingCircle />) || (
                <Box>
                    {_.map(data, (transcription, i) => {
                        const conversation = !transcription?.c ? [] : transcription.c.filter(el => !search?.[i] ? true : el.t.toLowerCase().includes(search[i].toLowerCase()));
                        return (
                            <Box key={i} mb={3}>
                                <Typography variant="subtitle2">
                                    <strong>Transcription {i + 1}/{_.size(data)}</strong>
                                </Typography>
                                <Box py={2}>
                                    <ReactPlayer 
                                        controls 
                                        config={{
                                            file: {
                                                forceAudio: true
                                            }
                                        }}
                                        height={54}
                                        onProgress={(e) => handleLogPosition(i, e?.playedSeconds ?? 0)}
                                        progressInterval={300}
                                        ref={el => itemsRef.current[i] = el} 
                                        url={`${S3_BUCKET_URL}${transcription.k}`}  
                                    />
                                </Box>
                                {transcription?.f ? (
                                    <Alert severity="error">Failed to transcribe this audio file, this could be as the duration is too short for accurate language detection!</Alert>
                                ) : transcription?.q ? (
                                    <Alert severity="warning">Queued for processing, please check back soon!</Alert>
                                ) : transcription?.p ? (
                                    <Alert severity="warning">Transcription processing, please check back soon!</Alert>
                                ) : transcription?.n ? (
                                    <Alert severity="success">Transcription complete - no audible conversation detected</Alert>
                                ) : (
                                    <>
                                        <Alert severity="success">Transcription complete - {transcription.s} speaker{transcription.s > 1 ? 's' : ''} detected</Alert>
                                        <TextField 
                                            label="Keywords" 
                                            fullWidth 
                                            value={search?.[i] ?? ''} 
                                            onChange={(e) => setSearch({ ...search, [i]: e.target.value })}
                                            margin="normal"
                                        />
                                        <Box pt={3}>
                                            <Grid container spacing={1}>
                                                {!_.isEmpty(conversation) ? conversation.map((text, idx) => {
                                                    const position = playbackPosition?.[i] ?? 0;
                                                    const playing = position > text.s && position < text.e;
                                                    const speakerNumber = text.u;
                                                    const speakerColor = speakerColors[speakerNumber];
                                                    return (
                                                        <Grid item xs={12} key={`t-${idx}`}>
                                                            <Paper
                                                                className={classes.conversationBlock}
                                                                style={{ borderColor: speakerColor, backgroundColor: playing ? '#fcfcd7' : colors.white}}
                                                                elevation={0}
                                                                onClick={() => itemsRef.current[i] && itemsRef.current[i].seekTo(text.s, 'seconds')}
                                                            >
                                                                <Grid container spacing={1} wrap="nowrap">
                                                                    <Grid item style={{borderRight: `1px solid ${speakerColor}`}}>
                                                                        <Box pr={1}>
                                                                            <Grid container alignItems='center'>
                                                                                <Grid item>
                                                                                    <FAIcon icon="user-headset"  size={13} />
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Typography variant="body1" >
                                                                                        {(parseInt(text.u) + 1)}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <Box pl={1}>
                                                                            <Typography variant="caption" >
                                                                                {(parseInt(text.s) > 0 ? moment.utc(parseInt(text.s) * 1000).format("mm:ss") : "0:00")}
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                {text.t}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                    )
                                                }) : (
                                                    <Typography variant="body1" color="textSecondary">
                                                        No matching conversation found
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        )
                    })}
                    <div className="buttonRow">
                        {_.some(data, el => el.q || el.p) ? (
                            <Button onClick={handleLoadData} variant="outlined" color="default">
                                <FAIcon icon="sync" size={15} button />
                                Refresh
                            </Button>
                        ) : null}
                        <Button onClick={() => dispatch(closeDialog())} variant="outlined" color="default">
                            <FAIcon icon="times" size={15} button />
                            Close
                        </Button>
                    </div>
                </Box>
            )}
        </>
    );
};

export default WildixCallTranscription;
