import React, { Component } from 'react';
import _, { initial }                    from 'lodash';
import moment               from 'moment';
import { connect }          from 'react-redux';
import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';

import DataTable   from 'Components/Common/DataTables/CiDataTable';
import MediaTable  from 'Components/Common/DataTables/MediaTable';
import AllIcon     from 'Components/Common/Icons/AllIcon';
import Textarea    from 'Components/Common/Inputs/Textarea';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import { Grid, Typography, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core/';
import API from 'API';

const initialState = {
    worksOrder:     {}, 
    returns:    [],
    editFormData:   {
        id:        0,
        description: '',
    },
}

function InfoBox({heading, value, icon}) {
    return (
        <Grid item xs={12} lg={12}>
            <PaddedPaper style={{textAlign:'center'}}>
                <AllIcon icon={icon} size='xlarge' />
                <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>{heading}</Typography>
                <Typography variant="h4" style={{textAlign:'center',  color: colors.red}}>{value}</Typography>
            </PaddedPaper>
        </Grid>
    )
}
class BuildWriteOffs extends Component {
    constructor(props) {
        super(props);

        this.state = initialState;
    }

    editDescription = rowData => () => {
        this.setState({
            editFormData: {
                id:          rowData.pwo_id,
                description: rowData.pwo_notes,
            }
        })
    }

    updateDescription = () => {
        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/writeOffs/editDescription`, this.state.editFormData)
        .then(res => {
            this.props.refresh();
            this.setState(initialState);
        })
    }

    render(){

        const { worksOrder } = this.props;
        const { editFormData } = this.state;

        const { write_offs, part_logs } = worksOrder;

        const partLogs = _.filter(part_logs, i => ['Written Off', 'Write Off Picked', 'Write Off Picked In Full', 'Write Off Complete'].includes(i.wopl_type));

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={3}>

                            <Grid item container spacing={3} xs={12} style={{justifyContent: 'center', alignItems: 'stretch'}}>
                                <Grid item xs={12} md={9}>
                                    <PaddedPaper style={{height: '100%'}}>
                                        <DataTable 
                                            config={{
                                                key: 'wobas_id',
                                                dropRow: {
                                                    droppable:     i => _.filter(partLogs, {wopl_part_id: i.part.part_id}).length > 0,
                                                    style:         {fontWeight: 'bold'},
                                                    hideDropArrow: true,
                                                    columns:       [
                                                        {
                                                            colSpan:     1,
                                                            field:       'wopl_datetime',
                                                            fieldFormat: 'clenydate',
                                                            alignment:   'right'
                                                        },
                                                        {
                                                            colSpan:   3,
                                                            field:     i => i.wopl_type + ( i.wopl_note ? ' - ': '' ) + i.wopl_note,
                                                            alignment: 'left'
                                                        },
                                                        {
                                                            colSpan:   1,
                                                            field:     i => {
                                                                _.each(worksOrder.build.stages, j => {
                                                                    if ( !j.wobs_complete) { 
                                                                        return `Stage ${j.instruction.sais_number}`;
                                                                    } else if ( moment(j.wobs_complete_datetime).isAfter(moment(i.wopl_datetime)) ) {
                                                                        return `Stage ${j.instruction.sais_number}`;
                                                                    } 
                                                                })
                                                                return `Stage 1`;
                                                            },
                                                            alignment: 'right'
                                                        },
                                                        {
                                                            colSpan:   1,
                                                            field:     'wopl_staff',
                                                            alignment: 'right'
                                                        },
                                                    ],
                                                    data: i => _.reverse(_.filter(partLogs, {wopl_part_id: i.part.part_id}))
                                                }
                                            }}
                                            columns={[
                                                {
                                                    field:          'part.default_image.file_path',
                                                    fieldFormat:    'image',
                                                    sizeToContent:  true
                                                },
                                                {
                                                    heading:       'Part Number',
                                                    field:         'part.part_number',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading:       'Part Description',
                                                    field:         'part.part_description',
                                                },
                                                {
                                                    heading:       'Qty',
                                                    field:         'qty',
                                                    sizeToContent: true,
                                                    alignment:     'center',
                                                    fieldFormat:   'decimal:3',
                                                    style:         { color: colors.red },
                                                },
                                                {
                                                    heading:       'Write Offs',
                                                    field:         'instances',
                                                    sizeToContent: true,
                                                    alignment:     'center',
                                                },
                                                {
                                                    heading:       'Stages',
                                                    field:         p => _.filter(_.map(_.uniqBy(write_offs,'wobwo_wobs_id'), i => _.find(write_offs, j => j.wobwo_wobs_id === i.wobwo_wobs_id && j.wobwo_part_id === p.part.part_id) && i.build_stage.instruction.sais_number), i => !!i).join(', '),
                                                    sizeToContent: true,
                                                    alignment:     'center',
                                                }
                                            ]}
                                            rows={_.orderBy(_.map(_.uniqBy(write_offs,'wobwo_part_id'), i => {
                                                let rows = _.filter(write_offs, j => j.wobwo_part_id === i.wobwo_part_id);
                                                return {
                                                    part:       i.part,
                                                    number:     i.part.part_number,
                                                    qty:        _.sumBy(rows, j => parseFloat(j.wobwo_qty)),
                                                    instances:  rows.length,
                                                    last:       _.last(rows).wobwo_datetime,
                                                }
                                            }), 'number')}
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item container xs={12} md={3} spacing={3} style={{alignContent: 'flex-start'}}>
                                    <InfoBox heading='Written Off Parts' value={_.uniqBy(write_offs, 'wobwo_part_id').length} icon={icons.parts} />
                                    <InfoBox heading='Written Off Quantity' value={_.sumBy(write_offs, i => parseFloat(i.wobwo_qty)).toFixed(3)} icon={icons.parts} />
                                </Grid>
                            </Grid>

                            {_.map(_.uniqBy(write_offs,'wobwo_wobs_id'), i => 
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6" style={{marginBottom: 15}}>Stage {i.build_stage.instruction.sais_number}: {i.build_stage.instruction.sais_name}</ Typography>
                                        <DataTable
                                            config={{
                                                key: 'wobas_id',
                                                dropRow: {
                                                    droppable:     rowData => rowData?.fault?.length > 0 ? true : false,
                                                    style:         {fontWeight: 'bold'},
                                                    columns:   [
                                                        {
                                                            colSpan: 6,
                                                            field:   rowData => 
                                                                <Grid container spacing={2}>    
                                                                    <Grid item xs={12} syle={{marginBottom: '0.5em'}}>
                                                                        <Typography variant="h6">Write Off: {rowData.idx + 1}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Grid container>
                                                                            <Grid item xs={12}>
                                                                                <TextField 
                                                                                    label="Fault"
                                                                                    value={rowData.pwo_reason}
                                                                                    disabled
                                                                                    fullWidth
                                                                                    variant="outlined"
                                                                                    inputProps={{
                                                                                        style: {fontWeight: 'normal', color: colors.black}
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} style={{paddingTop: '1em'}}>
                                                                                <TextField 
                                                                                    label="Quantity"
                                                                                    value={parseFloat(rowData.qty).toFixed(3)}
                                                                                    disabled
                                                                                    fullWidth
                                                                                    variant="outlined"
                                                                                    inputProps={{
                                                                                        style: {fontWeight: 'normal', color: colors.black}
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Textarea
                                                                                    id="feedback"
                                                                                    name="notes"
                                                                                    label="Fault Description"
                                                                                    value={rowData.pwo_notes}
                                                                                    disabled
                                                                                    rows={7}
                                                                                    margin="dense"
                                                                                    style={{height: '100%'}}
                                                                                    inputProps={{
                                                                                        style: {fontWeight: 'normal', color: colors.black}
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            {this.props.reviewMode && 
                                                                                <Grid item xs={12} className='buttonRow'>
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        onClick={this.editDescription(rowData)}
                                                                                    >Edit</Button>
                                                                                </Grid>
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                    {rowData.files.length > 0 &&
                                                                        <Grid item xs={12}>
                                                                            <MediaTable
                                                                                config={{ itemPerRow: 3 }}
                                                                                media='file_path'
                                                                                items={rowData.files}
                                                                                fields={[{actions: rowData => [{download: true}]}]}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                        }, 
                                                    ],
                                                    data: r => _.map(_.uniqBy(r.fault, i => `${i.pwo_reason}-${i.pwo_notes}-${_.map(i.files, j => j.pwoi_id).join('-')}`), (f, fidx) => {
                                                        return {
                                                            ...f,
                                                            qty:   _.sumBy(_.filter(r.fault, i => `${i.pwo_reason}-${i.pwo_notes}-${_.map(i.files, j => j.pwoi_id).join('-')}` == `${f.pwo_reason}-${f.pwo_notes}-${_.map(f.files, j => j.pwoi_id).join('-')}`), i => parseFloat(i.pwo_qty)),
                                                            idx: fidx
                                                        }
                                                    })
                                                }
                                            }}
                                            columns={[
                                                {
                                                    field:          'part.default_image.file_path',
                                                    fieldFormat:    'image',
                                                    sizeToContent:  true
                                                },
                                                {
                                                    heading:       'Part Number',
                                                    field:         'part.part_number',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading:       'Part Description',
                                                    field:         'part.part_description',
                                                },
                                                {
                                                    heading:       'Staff',
                                                    field:         'wobwo_staff',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading:       'Date',
                                                    field:         'wobwo_datetime',
                                                    fieldFormat:   'clenydate',
                                                    sizeToContent: true,
                                                }, 
                                                {
                                                    heading:       'Qty',
                                                    field:         'wobwo_qty',
                                                    alignment:     'center',
                                                    style:         { color: colors.red },
                                                    sizeToContent: true,
                                                },
                                            ]}
                                            rows={_.filter(write_offs, {wobwo_wobs_id: i.wobwo_wobs_id})}
                                        />
                                    </PaddedPaper>
                                </Grid>
                            )}
                        </Grid>
                    </PaddedPaper>
                    {
                        !!editFormData?.id &&
                        <Dialog
                            fullWidth
                            maxWidth='md'
                            open={editFormData?.id ? true : false}
                        >
                            <DialogTitle>Edit Fault Description</DialogTitle>
                            <DialogContent>
                                <Textarea
                                    value={editFormData?.description}
                                    onChange={(e)=>{
                                        this.setState({
                                            editFormData: {
                                                ...editFormData,
                                                description: e.target.value
                                            }
                                        })
                                    }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    onClick={()=>{
                                        this.setState({
                                            editFormData: initialState.editFormData
                                        })
                                    }}
                                >Close</Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>
                                        this.props.deployConfirmation(
                                            'Are you sure you want to update the fault description?',
                                            'Update Fault Description',
                                            this.updateDescription
                                        )
                                    }
                                >Update</Button>
                            </DialogActions>
                        </Dialog>
                    }
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation:     (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
    }
}

export default connect(null, mapDispatchToProps)(BuildWriteOffs);