import React, { useState, useEffect } from 'react';
import API                            from 'API';
import moment                         from 'moment';
import { find, map }                  from 'lodash';

import Paper          from 'Components/Common/Paper/PaddedPaper';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle  from 'Components/Common/LoadingCircle/LoadingCircle';
import DataTable      from 'Components/Common/DataTables/CiDataTable';
import BackButton     from 'Components/Common/Buttons/BackButton'; 

import { Grid, Typography, FormControl, InputLabel, Input, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(() => ({
    image: {
        textAlign: 'center',
        maxWidth: '100%',
    },
    label: {
        color: 'black'
    }
}));

export default function ViewCourier(props) {
    const classes = useStyles();
    const initialState = {
        formData: {
            supplier: '',
            name: '',
            logo: '',
            trackingURL: '',
        },
        files: [],
        isLoading: true,
        zones: [],
    }
    let [state, setState] = useState(initialState);
    const {formData:{supplier, name, logo, trackingURL}, files, isLoading, zones} = state;
    const downloadFile = url => window.open(url, '_blank');
    useEffect(() => {
        API.get(`/couriers/${props.match.params.id}`)
        .then(result => {
            let logo = find(result.data.courier.file, {'cour_file_type': 'Logo'});
            let files = [];
            map(result.data.courier.file, el => {
                switch(el.cour_file_type) {
                    case 'Logo':
                    break;
                    case 'Rates':
                        if(!files['Rates']) files['Rates'] = [];
                        files['Rates'].push(el);
                        break;
                    default:
                        throw new Error('Unhandled file type');
                }

            });
            setState(state => ({
                ...state,
                formData: {
                    ...state.formData,
                    supplier: result.data.courier.supplier.supp_company_name,
                    name: result.data.courier.cour_name,
                    logo: logo ? logo.file_url : '',
                    trackingURL: result.data.courier.cour_tracking_url,
                },
                files,
                isLoading: false,
                zones: result.data.courier.zones,
            }));
        });
    }, [props.match.params.id])
    return(
        <Grid container spacing={3}>
            {isLoading ? (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            ) : (
                <React.Fragment>
                    <Grid item xs={12} lg={2} align="center">
                        <Paper>
                            <ImageWithError src={logo} className={classes.image} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Paper>
                            <Typography variant="h6">Courier Details</Typography>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="supplier" shrink={true} className={classes.label}>Supplier</InputLabel>
                                    <Input id="supplier" value={supplier} disabled />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="name" shrink={true} className={classes.label}>Name</InputLabel>
                                    <Input id="name" value={name} disabled />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="trackingURL" shrink={true} className={classes.label}>Tracking URL</InputLabel>
                                    <Input id="trackingURL" value={trackingURL} disabled />
                                </FormControl>
                                <div className='buttonRow'>
                                    <BackButton props={props} />
                                </div>
                            </form>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Rates Card</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {files && files.Rates && files.Rates.length > 0 ? 
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={12}>
                                            <DataTable 
                                                config={{
                                                    key: 'cour_file_id',
                                                    isLoading,
                                                    plainHeader: true
                                                }}  
                                                columns={[
                                                    {
                                                        heading: 'Version',
                                                        field: rowData => 'v' + rowData.cour_file_version
                                                    },
                                                    {
                                                        heading: 'File',
                                                        field: rowData => rowData.cour_file_filename
                                                    },
                                                    {
                                                        heading: 'Date From',
                                                        field: rowData => rowData.cour_file_created_at_datetime,
                                                        fieldFormat: 'date'
                                                    },
                                                    {
                                                        heading: 'Date To',
                                                        field: rowData => files.Rates[rowData.rowIdx - 1] ? moment(files.Rates[rowData.rowIdx - 1].cour_file_created_at_datetime).format('DD/MM/YYYY') : 'Present',
                                                    },
                                                    {
                                                        actions: rowData => {
                                                            return [
                                                                {name: 'Download', icon: 'download', onClick: () => downloadFile(rowData.file_url)}
                                                            ]
                                                        }
                                                    }
                                                ]}
                                                rows={files.Rates}
                                            />
                                        </Grid>
                                    </Grid>
                                :
                                    <Typography>There are 0 Rates Cards found.</Typography>
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Zones</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <DataTable 
                                    config='cz_id'
                                    columns={[
                                        {
                                            heading:       'Zone',
                                            field:         'cz_name',
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Description',
                                            field:   'cz_desc'
                                        }
                                    ]}
                                    rows={zones}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
}