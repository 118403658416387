import React, {Component} from 'react';
import API                from 'API';
import _                  from 'lodash';
import { connect }        from 'react-redux';

import DataTable          from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import Textarea           from 'Components/Common/Inputs/Textarea';
import ImageWithError     from 'Components/Common/ImageWithError/ImageWithError';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons                      from 'Helpers/IconHelper';
import { colors }                 from 'Helpers/ColourHelper';

import logo from 'Assets/Images/clenaware_logo_icon.png';

import {Grid, Typography, FormControl, TextField, Button, FormHelperText} from '@material-ui/core';

import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

const initialState = {
    category:   {},
    isLoading:  true,
    formErrors: [],
    formData: {
        categoryName: '',
        categoryDesc: '',
        part:         null
    },
    parts:      [],
    access:     {}
}

class PartCategories extends Component{
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    
    componentDidMount = () => {
        this.getPartCategory();
        this.getParts();
        this.getAccess();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/link-part-categories'),
            API.get('/staff/my/access/check/update-part-categories'),
            API.get('/staff/my/access/check/view-part'), 
            API.get('/staff/my/access/check/update-part'),
        ]).then(([linkRes, updateRes, viewRes, updatePartRes]) => {
            this.setState({
                access: {
                    link:       (linkRes?.data?.has_access)       || false,
                    update:     (updateRes?.data?.has_access)     || false,
                    viewPart:   (viewRes?.data?.has_access)       || false,
                    updatePart: (updatePartRes?.data?.has_access) || false,
                },
            })
        })
    }

    getParts = () => {
        API.get(`/parts/all`)
        .then(res => {this.setState({parts: res.data, formData: {...this.state.formData, part: null}})})
    }

    getPartCategory = () => {
        API.get(`/parts/catagories/${this.props.match.params.pcId}`)
        .then(res => {
            this.setState({
                isLoading:  false,
                category: res.data,
                formData: {
                    categoryName: res.data.pc_name,
                    categoryDesc: res.data.pc_desc,
                },
                formErrors: [],
            });
        });
    }

    handleChangeName = e => { this.setState({ formData: { ...this.state.formData, categoryName: e.target.value } }); }
    handleChangeDesc = e => { this.setState({ formData: { ...this.state.formData, categoryDesc: e.target.value } }); }

    handleUpdateName = () => {
        API.post(`/parts/catagories/${this.props.match.params.pcId}`, this.state.formData)
        .then(result => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({formErrors: formatValidationErrors(result.data.errors),});
            } else {
                this.getPartCategory();
            }
        })
    }

    loadPartImage = (rowData) => {
        this.props.deployDialog(
            <Grid container xs={12} spacing={3} style={{justifyContent:'center'}}>
                <Grid item>
                    <ImageWithError src={rowData.default_image?.thumbnail_file_path} alt='Part Image' style={{maxWidth: 1000}}/>
                </Grid>
                <Grid item xs={12}><Button variant='outlined' onClick={this.props.closeDialog}>Close</Button></Grid>
            </Grid>
        ,`${rowData.part_number} - ${rowData.part_description}`,'sm')
    };

    handleSelectChange = e => { 
        this.setState({formData: {...this.state.formData, part: e ? e.value : null}}) 
    }

    handleLinkPart = (id = null) => {
        API.post(`/parts/${id ? id : this.state.formData.part}/category/link`, {pcId: this.props.match.params.pcId, unlink: id ? 1 : 0})
        .then(res => {
            if (id)  this.props.deploySnackBar('error',  'Part has been unlinked')
            if (!id) this.props.deploySnackBar('success','Part has been linked')
            this.getParts();
            this.getPartCategory();
            this.setState({formData: {...this.state.formData, part: null}})
        })
    }

    render(){
        const {category, isLoading, formData, formErrors, parts, access} = this.state;
        return isLoading ? (<LoadingCircle/>) : 
        (
            <Grid container xs={12} style={{justifyContent:'space-between'}}>
                <Grid item xs={12} style={{ marginBottom: '1.5em'}}>
                    <Typography variant="h5">{category.pc_name}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <PaddedPaper>
                        <Grid item xs={12}><Typography variant="h6">Update Part</Typography></Grid>
                        <FormControl margin="normal" fullWidth >
                            <TextField  label={'Category Name *'} 
                                        name='name'
                                        error={formErrors['name']}
                                        fullWidth 
                                        value={formData.categoryName} 
                                        onChange={this.handleChangeName}
                            />
                           {formErrors.name ? <FormHelperText style={{color:colors.red}}>{formErrors.name}</FormHelperText> : <></>}
                        </FormControl>
                        <FormControl margin="normal" fullWidth >
                            <Textarea
                                id="desc"
                                name="desc"
                                label={'Category Description'} 
                                value={formData.categoryDesc} 
                                onChange={this.handleChangeDesc}
                                error={formErrors['desc']}
                                errorText={formErrors['desc']}
                            />
                        </FormControl>
                        <div className='buttonRow'>
                            <Button variant="contained" color="primary" onClick={this.handleUpdateName} disabled={!formData.categoryName || !access.update}>Update</Button>
                        </div>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={6} style={{paddingRight: '1.5em',paddingLeft: '1.5em'}}>
                    <PaddedPaper>
                        <Grid container xs={12}>
                            <Grid item xs={12}><Typography variant="h6">Link A Part</Typography></Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={_.map(_.filter(parts, i=>i.part_part_category_id == 0), i => { return _.assign({value: i.part_id, label: `${i.part_number} - ${i.part_description}`}) })}
                                        name='Parts'
                                        label='Link Part *'
                                        value={formData.part}
                                        onChange={this.handleSelectChange}
                                    />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button variant="contained" color="primary" onClick={()=>{this.handleLinkPart()}} disabled={!access.link || !formData.part}>Link</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper style={{marginTop:'1.5em'}}>
                        <Grid container xs={12}>
                            <Grid item xs={12} style={{marginBottom:'1.5em'}}>
                                <Typography variant="h6">Linked Parts</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <DataTable
                                    config={{
                                        key: 'part_id',
                                        alternatingRowColours: true,
                                        isLoading: isLoading
                                    }}
                                    columns={[
                                        {
                                            field: rowData =>   <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                    {rowData && rowData.default_image?.thumbnail_file_path ?
                                                                        <Button onClick={() => this.loadPartImage(rowData)}>
                                                                            <img src={rowData.default_image?.thumbnail_file_path} alt={rowData.part_description} style={{maxHeight:35, maxWidth:35}} />
                                                                        </Button> 
                                                                    : 
                                                                        <img src={logo} alt="logo" style={{maxHeight:35, maxWidth:35}}/>
                                                                    }
                                                                </div>,
                                            sizeToContent: true,
                                            filter: 'custom',
                                            filterMod: {
                                                dataRef: (search, rowData) => {
                                                    return `${rowData.part_number}|${rowData.part_description}`.toLowerCase().includes(search.toLowerCase()) > 0;
                                                },
                                                label:     'Search Parts',
                                                styleGrid: {width: '20%'}
                                            }
                                        },
                                        {
                                            heading: 'Number',
                                            field: rowData => rowData.part_number,
                                            sizeToContent: true 
                                        },
                                        {
                                            heading: 'Description',
                                            field: rowData => rowData.part_description,
                                        },
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Unlink', icon: icons.unlink, onClick: () => {this.handleLinkPart(rowData.part_id)}, disabled: !access.link},
                                                    {name: 'Update', icon: icons.edit,   link: { pathname: '/parts/update/' + rowData.part_id}, disabled: !access.updatePart},
                                                    {name: 'View',   icon: icons.search, link: { pathname: '/parts/view/' + rowData.part_id},   disabled: !access.viewPart},
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={_.orderBy(category.parts, i=>i.part_number.toLowerCase())}
                                />
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, msg) => {
            dispatch(deploySnackBar(variant, msg))
        }
    }
}

export default connect(null, mapDispatchToProps)(PartCategories);