import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    fab
 } from '@fortawesome/free-brands-svg-icons'

/*
 * Add Light Icons to the library
 */
library.add(fab);

const styles = theme => ({
    iconStyle:{
        marginRight:theme.spacing(1),
        //marginLeft:theme.spacing (1),
        color: 'rgba(0,0,0,0.65)'
    },
    iconStyleLight:{
        marginRight:theme.spacing(1),
        //marginLeft:theme.spacing (1),
        color: 'rgba(0,0,0,0.54)'
    },
    iconStyleDisabled:{
        marginRight:theme.spacing(1),
        //marginLeft:theme.spacing (1),
        color: 'rgba(0,0,0,0.2)'
    },
    iconStyleError:{
        marginRight:theme.spacing(1),
        //marginLeft:theme.spacing (1),
        color: '#f44336'
    }
});

class FALightIcon extends Component {
    render() {
        return (
            <React.Fragment>
                <FontAwesomeIcon    icon={['fab', this.props.icon]}
                                    style={{
                                        cursor: this.props.link || (this.props.button || this.props.buttonPrimary) ? 'pointer' : 'default',
                                        color: this.props.buttonPrimary && (!this.props.disabled && 'white'),
                                        margin: this.props.noMargin && 0,
                                        height: (!this.props.fixedWidth && (this.props.size === 'xxlarge' ? '75' : (this.props.size === 'xlarge' ? '50' : ( this.props.size === 'large' ? '35' : (this.props.size === 'small' ? '15' : '20' /* Medium (default) */))))),
                                        width: (!this.props.fixedHeight && (this.props.size === 'xxlarge' ? '75' : (this.props.size === 'xlarge' ? '50' : ( this.props.size === 'large' ? '35' : (this.props.size === 'small' ? '15' : '20' /* Medium (default) */))))) || 'auto',
                                        ...this.props.style
                                    }}
                                    className={this.props.disabled ? this.props.classes.iconStyleDisabled : (this.props.error ? this.props.classes.iconStyleError : (this.props.light ? this.props.classes.iconStyleLight : this.props.classes.iconStyle))}
                                    rotation={this.props.rotation} />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(FALightIcon);
