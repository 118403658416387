import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import AllIcon from 'Components/Common/Icons/AllIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import CustomerReturnsDownloadPrevDocsDialog from 'Components/CustomerReturns/CustomerReturnsDownloadPrevDocsDialog/CustomerReturnsDownloadPrevDocsDialog';
import CustomerReturnsEmailPrevDocsDialog from 'Components/CustomerReturns/CustomerReturnsEmailPrevDocsDialog/CustomerReturnsEmailPrevDocsDialog';
import ApproveCreditNoteDialog from './ApproveCreditNoteDialog';
import DeclineCreditNoteDialog from './DeclineCreditNoteDialog';

import { colors } from 'Helpers/ColourHelper';
import { statusColour, statusHelper } from 'Helpers/CustomerReturnsHelper';
import icons from 'Helpers/IconHelper';

import { withStyles } from '@material-ui/core/styles';

import { Button, FormControl, Grid, IconButton, Link, TextField, Tooltip, Typography } from '@material-ui/core/';

const styles = () => ({
    card: {
        display: 'flex', 
        justifyContent:'center', 
        alignItems:'center', 
        textAlign:'left'
    },
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }
});

const initialState = () => ({
    isLoading:      true,
    creditNotes:    [],
    title:          '',
    status:         '',
    customerId:     null,
    nextStatus:     '',
    searchCriteria: {
        status:         'All',
        dateFrom:       null,
        dateTo:         null,
        site:           'All'
    },
    sites:          null,  
});

class SearchCreditNote extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() { 
        this.updateComponent() 
        if (this.props?.match?.params?.customerId || this.props?.cid) this.getCustomerSites();
    }

    componentDidUpdate() {
        if (this.props.status !== this.state.status || this.state.customerId !== (this.props?.match?.params?.customerId || this.props?.cid)) this.updateComponent();
    }

    updateComponent = () => {
        let title = (this.props.status) ? `Credit Notes ${this.props.status}` : ``;
        switch(this.props.status){
            case 'All':      title = 'All Credit Notes'      ; break;
        }
        
        this.setState({
            ...initialState(), 
            title, 
            status:     this.props.status, 
            customerId: this.props?.match?.params?.customerId || this.props?.cid,
            searchCriteria: {
                ...this.state.searchCriteria, 
                status: this.props.status
            }
        }, this.getSearchData);
    }

    getCustomerSites = () => {
        API.get(`/customers/${this.props?.match?.params?.customerId || this.props?.cid}`)
        .then(res => {
            this.setState({
                sites: [
                    {value: 'All', label:'All'},
                    {   value: res.data.registered_address.address_id, 
                        label:  res.data.registered_address.address_postcode + ' | ' + res.data.registered_address.address_name 
                    },
                    ..._.map(res.data.trading_addresses, i => {
                        return _.assign({
                            value: i.address_id,
                            label: i.address_postcode + ' | ' + i.address_name  
                        })
                     }),
                    ..._.map(res.data.account_addresses, i => {
                        return _.assign({
                            value: i.address_id,
                            label: i.address_postcode + ' | ' + i.address_name  
                        })
                     }),
                    ..._.map(res.data.site_addresses, i => {
                        return _.assign({
                            value: i.address_id,
                            label: i.address_postcode + ' | ' + i.address_name  
                        })
                     })
                    ]
            })
        })
    }

    getSearchData = () => {
        const params = {
            keywords: this.state.searchCriteria.keywords,
            status  : this.state.searchCriteria.status,
            dateFrom: this.state.searchCriteria.dateFrom,
            dateTo  : this.state.searchCriteria.dateTo,
            custId  : this.state.customerId,
            site    : this.state.searchCriteria.site === 'All' ? null : this.state.searchCriteria.site,  
            slim    : 1
        }
        API.get('/customerReturns/creditNote', {params})
        .then(searchRes => {
            if (searchRes.data){
                _.map(searchRes.data, o => {
                    // Status Colour
                    o.color     = statusColour(o.s);
                    o.icon      = statusHelper(o.s);
                    o.toolTip1  = o.s
                });

                this.setState({
                    creditNotes:    searchRes.data,
                    isLoading:      false,
                });
            }
        })
    }

    handleSelectChange = field => e => {
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                [field]: e.value
            }
        }, this.getSearchData);
    }

    handleDateChange = field => e => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                [field]: e
            },
        }, this.getSearchData);
    }

    handleSearchKeyword = e => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                keywords: e.target.value
            },
        }, this.getSearchData);
    }

    resetSearch = () => {
        this.setState({searchCriteria: initialState().searchCriteria},this.getSearchData);
    }

    handleDeclineCreditNote = rowData => {
        const dialog = <DeclineCreditNoteDialog 
            cnId={rowData.i}
            callback={this.getSearchData}
        />
        const title = <React.Fragment>You Are About To <b>Decline</b> This Credit Note</React.Fragment>

        this.props.deployDialog(dialog, title, 'warning');
    }
    
    handleApproveCreditNote = rowData => {

        const dialog = <ApproveCreditNoteDialog 
            status={this.props.status}
            cnId={rowData.i}
            callback={this.getSearchData}
        />
        let title = '';
        switch(this.props.status){
            case 'Approval':            title = <React.Fragment>You Are About To <b>Approve</b> This Credit Note</React.Fragment>;    break;
            case 'Confirmed':           title = <React.Fragment>You Are About To <b>Process</b> This Credit Note</React.Fragment>;    break;
        }
        this.props.deployDialog(dialog, title, 'success');
    }


    handleDownloadOpen = creditNote => this.props.deployDialog(<CustomerReturnsDownloadPrevDocsDialog cnId={creditNote} />, 'Download Previous Documents', '', 'md');

    handleEmailOpen = creditNote => this.props.deployDialog(<CustomerReturnsEmailPrevDocsDialog cnId={creditNote} />,  'Email Previous Documents',   '', 'md');

    getTableColumns = () => {
        let columns = [
            {
                heading: 'Credit Date',
                field: rowData => moment(rowData.d).format("DD/MM/YYYY"),
                important: true,
                sizeToContent: true
            },
            {
                actionsCustomHeader:    true,
                important:              true,
                alignment:              'left',
                heading:                'Reference',
                actions: rowData => {
                    let a = [ 
                        { label: rowData.r,                              link: `/returns/creditnote/${rowData.i}`,  type:'plainLink', color: colors.red}
                    ]
                    if (rowData.o.i){
                        a.push({ label: rowData.o.i ? `${rowData.o.r}` : null,  link: `/sales/order/view/${rowData.o.i}`,  type:'plainLink', class: "textSecondary"})
                    }
                    if (rowData.cr.length > 0){
                        _.each(rowData.cr, i => {
                            a.push({
                                label: i.r,
                                link: `/returns/customerReturn/${i.i}`,
                                type:'plainLink', 
                                color: colors.red
                            })
                        })
                    }
                    return a;
                }
            }
        ]

        if (this.props.status == 'Completed'){
            columns.push(
                {
                    heading: 'Credit Number',
                    field: rowData => <>{`${rowData.in.n} (Sage: ${rowData.in.sn})`}</>,
                    important: true,
                    alignment: 'left'
                }
            );
        }

        columns.push(
            {
                heading: 'Discount',
                field: rowData => rowData.t.d ? 
                    <AllIcon icon='check' style={{color:colors.green}} /> : 
                    <AllIcon icon='times' style={{color:colors.red}}/>,
                important: true,
                fieldFormat: 'boolean',
                tooltip: rowData => rowData.t.d ? 'With Discount' : 'Without Discount',
                sizeToContent: true,
                alignment: 'center'
            }
        );
            
        columns.push(
            {
                heading: 'Restocking Fee',
                field: rowData => rowData.t.r,
                important: true,
                fieldFormat: 'boolean',
                tooltip: rowData => rowData.t.r ? 'With Restocking Fee' : 'Without Restocking Fee',
                alignment: 'center'
            },
        )

            columns.push(
                (this.state.customerId) ?
                    {
                        heading: 'Delivery',
                        field: rowData => <>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Typography component="div" variant="body2">
                                                        {rowData.a.n}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs>
                                                    <Tooltip
                                                        title={
                                                            <div>
                                                                <Typography variant="body2">
                                                                    <b>Delivery Address:</b><br />
                                                                    {rowData.a.a.split('\n').map((item, key) => {
                                                                        return  <span key={key}>
                                                                                    {item}{rowData.a.a.split('\n').length === key+1 ? null : <br/>}
                                                                                </span>
                                                                    })}
                                                                </Typography>
                                                            </div>
        
                                                        }
                                                        placement="right"
                                                    >
                                                        <Link>
                                                            <AllIcon
                                                                heavy 
                                                                icon='info-circle'
                                                                button 
                                                                size='small'
                                                                style={{
                                                                    color: 'rgba(0, 0, 0, 0.38)'
                                                                }}
                                                            />
                                                        </Link>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </>,
                        important: true,
                        truncate: true
                    } : {
                        heading: 'Customer',
                        field: rowData => <>
                            {rowData.c.n}
                            {''}
                            <Typography component="div" variant="body2">
                                {rowData.a.n}
                            </Typography>    
                        </>,
                        info:    rowData =>     <div>
                                                    <Typography variant="body2">
                                                        <b>Delivery Address:</b><br />
                                                        {rowData.a.a.split('\n').map((item, key) => {
                                                            return  <span key={key}>
                                                                        {item}{rowData.a.a.split('\n').length === key+1 ? null : <br/>}
                                                                    </span>
                                                        })}
                                                    </Typography>
                                                </div>,
                        important: true,
                        truncate: true
                    }
            );

        if (this.props.status == 'All' || this.state.customerId) columns.push({
                heading: 'Status',
                field: rowData =>   <>
                                        <Tooltip
                                            title={
                                                <div>
                                                    <Typography variant="body2">{rowData.toolTip1}</Typography>
                                                </div>
                                            }
                                            placement="left"
                                        >
                                            <IconButton className={this.props.classes.iconButton}>
                                                <AllIcon 
                                                    icon={rowData.icon}
                                                    button
                                                    style={{color: rowData.color}}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </>,
                important: true
            })

        columns.push({
            heading: 'Credit Total',
            field: rowData => ( 
                <div style={{color:colors.red}}>
                    -£{rowData.t.t}
                    {' '}
                    <Tooltip 
                        title={
                            <div style={{display: 'flex', width: 200}}>
                                <div style={{width: '50%', textAlign:'right'}}>
                                    <Typography variant="body2">Sub Total</Typography>
                                    <Typography variant="body2">VAT ({parseInt(rowData.c.v)}%)</Typography>
                                    <Typography variant="body2">Total</Typography>
                                </div>
                                <div style={{width: '50%', textAlign:'right'}}>
                                    <Typography variant="body2">-£{rowData.t.s}</Typography>
                                    <Typography variant="body2">-£{rowData.t.v}</Typography>
                                    <Typography variant="body2">-£{rowData.t.t}</Typography>
                                </div>
                            </div>
                        } 
                        placement="left"
                    >
                        <Link>
                            <AllIcon
                                heavy 
                                icon='info-circle'
                                button 
                                size='small'
                                style={{
                                    color: 'rgba(0, 0, 0, 0.38)'
                                }}
                            />
                        </Link>
                    </Tooltip>
                </div>
            ),
            important: true,
            alignment: 'right',
        });
        if (this.props.status == 'All' || this.props.status == 'Completed' || this.state.customerId) columns.push({
                heading: 'Refund Method',
                field: rowData =>   <>
                                        {(rowData.icon == 'check' || rowData.icon == 'file-check' ) && 
                                            <>
                                                {_.map(rowData.in.m, p => {
                                                    
                                                    let icon = 'question-circle';
                                                    switch(p){
                                                        case 'Bank Transfer':       icon = icons.bank;      break;
                                                        case 'Card - Stripe':       icon = icons.stripe;    break;
                                                        case 'Card':
                                                        case 'Card - Barclaycard':  icon = icons.card;      break;
                                                        case 'Invoice':             icon = icons.invoice;   break;
                                                    }
                                                    let color = rowData.icon == 'check' ? colors.green : '#F39C12';
                                                    return (
                                                        <Tooltip
                                                            title={<Typography variant="body2">{p}</Typography>}
                                                            placement="left"
                                                        >
                                                            <IconButton>
                                                                <AllIcon 
                                                                    icon={icon}
                                                                    button
                                                                    style={{color: color}}
                                                                    noMargin
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )
                                                })}
                                            </>
                                        }
                                    </>,
                important: true,
                alignment: 'center',
                sizeToContent: true
            });

        columns.push({actions: rowData => {return this.getTableActions(rowData)}});

        return columns;
    }

    getTableActions = rowData => {
        let ret = [
            {name: 'Download',                  icon: 'download',   onClick: () => this.handleDownloadOpen(rowData.i)},
            {name: 'Send Order to Customer',    icon: 'envelope',   onClick: () => this.handleEmailOpen(rowData.i)},
            {name: 'View',                      icon: 'search',     link: {pathname: `/returns/creditnote/${rowData.i}`}},  
        ]

        if (!this.state.customerId){
            //ret.push({name: 'View this Customers Orders', icon: 'file-search', link: `/returns/creditnote/customer/${rowData.cn_cust_id}/search`})
            ret.push({name: 'View this Customers Orders', icon: 'file-search', link: `/customers/view/${rowData.c.i}/CreditNote`})
        }

        if (rowData.l){
            ret.push({name: 'Locked', icon: icons.lock, onClick:()=>{}});
        } else {
            switch(this.props.status){
                case 'Approval':
                    ret.push({name: 'Approve', icon: 'check', onClick: () => this.handleApproveCreditNote(rowData)});
                    ret.push({name: 'Decline', icon: 'times', onClick: () => this.handleDeclineCreditNote(rowData)});
                break;
                case 'Confirmed':
                    ret.push({name: 'Process',  icon: 'check', onClick: () => this.handleApproveCreditNote(rowData)});
                break;
            }
        }
        return ret;
    }

    render() {
        const { isLoading, creditNotes, searchCriteria, title, status, sites } = this.state;
        const { classes }                                               = this.props;
        return (
            <Grid container spacing={3}>
                <Grid container spacing={3} xs={12} style={{margin:'1em'}}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{title}</Typography>
                    </Grid>
                    <Grid item xs={12} style={{paddingRight:'2.5em'}}>
                        <PaddedPaper style={{width:'100%'}}>
                            <Grid container xs={12} spacing={1} >
                                <Grid item xs={12} lg={2}>
                                    <FormControl margin="none" fullWidth>
                                        <TextField label="Keywords" fullWidth value={searchCriteria?.keywords} onChange={this.handleSearchKeyword} />
                                    </FormControl>
                                </Grid>
                                {(status == 'All' || this.state.customerId) &&
                                    <Grid item xs={12} lg={2}>
                                        <FormControl margin="none" fullWidth>
                                            <AutoCompleteSelect 
                                                options={[
                                                    {value: 'All',                  label: 'All'},
                                                    {value: 'Awaiting Approval',    label: 'Awaiting Approval'},
                                                    {value: 'Cancelled',            label: 'Cancelled'},
                                                    {value: 'Completed',            label: 'Completed'},
                                                    {value: 'Confirmed',            label: 'Confirmed'},
                                                    {value: 'Approval',             label: 'Approval'},
                                                    {value: 'Declined',             label: 'Declined'},
                                                ]} 
                                                label='Status'
                                                onChange={this.handleSelectChange('status')}
                                                value={searchCriteria?.status}
                                            />
                                        </FormControl>
                                    </Grid>
                                }   
                                {(this.state.customerId && sites) &&
                                    <Grid item xs={12} lg={2}>
                                        <FormControl margin="none" fullWidth>
                                            <AutoCompleteSelect 
                                                options={sites} 
                                                label='Site'
                                                onChange={this.handleSelectChange('site')}
                                                value={searchCriteria?.site}
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={12} lg={2}>
                                    <DatePicker
                                        type='date'
                                        id="dateFrom"
                                        name="dateFrom"
                                        label="Date From"
                                        autoOk={true}
                                        value={searchCriteria?.dateFrom}
                                        onChange={this.handleDateChange('dateFrom')}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <DatePicker
                                        type='date'
                                        id="dateTo"
                                        name="dateTo"
                                        label="Date To"
                                        autoOk={true}
                                        value={searchCriteria?.dateTo}
                                        onChange={this.handleDateChange('dateTo')}    
                                        margin="none"                                        
                                    />
                                </Grid>
                                
                                <Grid item xs={12} lg={2} style={{marginLeft:'auto'}}>
                                    <div className='buttonRow'  >
                                        <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                        >
                                            <AllIcon icon='undo' button />
                                            Reset Search
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12} style={{paddingRight:'2.5em'}}>
                        <PaddedPaper>
                            <DataTable 
                                config={{
                                    key: 'i',
                                    pagination: true,
                                    alternatingRowColours: true,
                                    responsiveimportant: true,
                                    isLoading: isLoading,
                                }}
                                columns={this.getTableColumns()}
                                rows={creditNotes}
                            />
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deploySnackBar:     (variant, msg)                          => dispatch(deploySnackBar(variant, msg)),
    deployDialog:       (content, title, variant, size = 'lg')  => dispatch(deployDialog(content, title, variant, size)),
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(SearchCreditNote));