import React from 'react';
import { Button, ExpansionPanel, Grid, Typography, ExpansionPanelDetails, ExpansionPanelSummary, Box, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBox from 'Components/Common/Inputs/CheckBox';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import API from 'API';
import _, { unset } from 'lodash';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea from 'Components/Common/Inputs/Textarea';
import DragFileInputMulti from 'Components/Common/Inputs/DragFileInputMulti';
import {colors} from 'Helpers/ColourHelper';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SelectPartWithQR from 'Components/Common/Selects/SelectPartWithQR';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import IconHelper from 'Helpers/IconHelper';

const initialWriteOffState = () => ({
    roId: null,
    reason: null,
    notes: null,
    files: [],
    parts: [],
    services: []
})

const initialState = () => ({
    isLoading: true,
    globalWriteOff: false,
    formData: [],
    globalFormData: initialWriteOffState(),
    repair: null,
    lists: {
        reasons: []
    }
})

const WoBlock = (props) => {
    const { formData, onChange, lists, repairOrder } = props;
    
    const [serviceFormData, setServiceFormData] = React.useState({
        part: null,
        qty: null
    });

    const [partFormData, setPartFormData] = React.useState({
        part: null,
        qty: null
    });

    let estimatedParts = []
    if (repairOrder?.estimated_parts.length > 0) {
        estimatedParts = {};
        _.each(repairOrder?.estimated_parts, e => {
            if (estimatedParts[e.roep_part_id]) {
                estimatedParts[e.roep_part_id] = {...estimatedParts[e.roep_part_id], roep_qty: parseFloat(estimatedParts[e.roep_part_id].roep_qty) + parseFloat(e.roep_qty)};
            } else {
                estimatedParts[e.roep_part_id] = e;
            }
        });
        estimatedParts = _.values(estimatedParts);
    }

    const handleFileChange = (drop, name, event) => {
        let newFiles = drop === true ? event.dataTransfer.files : event.target.files;
        let existingFiles = formData.files ?? [];
        let allFiles = Array.prototype.slice.call(existingFiles).concat(Array.prototype.slice.call(newFiles))
        onChange({
            files: allFiles
        });
    }
    const cancelOnClick = idx => {
        let existingFiles = formData.files ?? [];
        unset(existingFiles, idx);
        onChange({
            files: existingFiles
        });
    }
    const addPart = () => {
        setPartFormData({ part: null, qty: null });
        onChange({ parts: [...formData.parts, partFormData] });
    };
    const addService = () => {
        setServiceFormData({ part: null, qty: null });
        onChange({ services: [...formData.services, serviceFormData] });
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <AutoCompleteSelect
                    label='Reason *'
                    options={lists.reasons}
                    value={formData.reason}
                    onChange={e => onChange({'reason': e.value})}
                    fullWidth
                    noClear
                />
            </Grid>
            <Grid item xs={12}>
                <Textarea
                    label='Notes *'
                    value={formData.notes}
                    row={4}
                    onChange={e => onChange({'notes':e.target.value})}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <DragFileInputMulti
                    label='Upload Files *'
                    file={formData.files}
                    onChange={handleFileChange}
                    cancelOnClick={cancelOnClick}
                    fullWidth
                />
            </Grid>
            {repairOrder?.group ?
                <Grid item xs={12}>
                     <Typography variant='caption'>Please Note: If parts & services where used for this write off, please write off each part individually</Typography>
                </Grid>
            : 
                <Grid item xs={12}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='body1'>Parts Used</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                {lists.parts.length > 0 &&
                                                    <SelectPartWithQR
                                                        parts=      {_.filter(lists.parts, i => i.part.stockable)}
                                                        onChange=   {(e)=>setPartFormData({...partFormData, part: e.value})}
                                                        value=      {partFormData.part}
                                                        margin=     "none"
                                                        label=      "Part *"
                                                    />
                                                }
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    label='Quantity *'
                                                    fullWidth
                                                    type='number'
                                                    value={partFormData.qty}
                                                    name={'partFormData.qty'}
                                                    onChange=   {(e)=>setPartFormData({...partFormData, qty: e.target.value})}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    fullWidth
                                                    onClick={addPart}
                                                    disabled={!partFormData.part || parseFloat(partFormData.qty) <= 0 || !partFormData.qty}
                                                >Add Part</Button>
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                                {formData.parts.length > 0 &&
                                    <Grid item xs={12}>
                                        <ExpansionPanel expanded={true}>
                                            <ExpansionPanelSummary style={{backgroundColor: colors.green}}>
                                                <Typography>Added Parts</Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <CiDataTable
                                                    config={{
                                                        key: 'rop_id'
                                                    }}
                                                    rows={_.filter(_.map(formData.parts, i => ({...i, part: _.find(lists.parts, {value: i.part})?.part})), i => i?.part)}
                                                    columns={[
                                                        {
                                                            heading: '',
                                                            field: 'part.default_image.file_path',
                                                            fieldFormat: 'image',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: 'Part',
                                                            field: i => <>
                                                                <Typography variant='body2'>{i?.part?.part_number}</Typography>
                                                                <Typography variant='caption'>{i?.part?.part_description}</Typography>
                                                            </>,
                                                        },
                                                        {
                                                            heading: 'qty',
                                                            field: 'qty',
                                                            fieldFormat: 'decimal:2',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            actions: i => ([
                                                                {
                                                                    name: 'Remove',
                                                                    icon: IconHelper.bin,
                                                                    onClick: () => {
                                                                        onChange({
                                                                            parts: _.filter(formData.parts, (j,idx) => idx !== i.rowIdx)
                                                                        });
                                                                    },
                                                                }
                                                            ])
                                                        }
                                                    ]}
                                                />
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                }
                                {
                                    _.filter(estimatedParts, i => (!_.find(formData.parts, j => parseFloat(j.part) === parseFloat(i.roep_part_id))) && i.part.stockable).length > 0 &&
                                    <Grid item xs={12}>
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{backgroundColor: colors.orange}}>
                                                <Typography>Recommended Parts</Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <CiDataTable
                                                    config={{
                                                        key: 'rop_id'
                                                    }}
                                                    rows={_.filter(estimatedParts, i => (!_.find(formData.parts, j => parseFloat(j.part) === parseFloat(i.roep_part_id))) && i.part.stockable)}
                                                    columns={[
                                                        {
                                                            heading: '',
                                                            field: 'part.default_image.file_path',
                                                            fieldFormat: 'image',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: 'Part',
                                                            field: i => <>
                                                                <Typography variant='body2'>{i.part.part_number}</Typography>
                                                                <Typography variant='caption'>{i.part.part_description}</Typography>
                                                            </>,
                                                        },
                                                        {
                                                            heading: 'qty',
                                                            field: 'roep_qty',
                                                            fieldFormat: 'decimal:2',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            actions: i => ([
                                                                {
                                                                    name: 'Add',
                                                                    icon: IconHelper.plus,
                                                                    onClick: () => {
                                                                        onChange({
                                                                            parts: [...formData.parts, 
                                                                                {
                                                                                    part: i.part.part_id,
                                                                                    qty: i.roep_qty
                                                                                }
                                                                            ]
                                                                        });
                                                                    },
                                                                }
                                                            ])
                                                        }
                                                    ]}
                                                />
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                }
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='body1'>Services Used</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                {lists.parts.length > 0 &&
                                                    <SelectPartWithQR
                                                        parts=      {_.filter(lists.parts, i => !i.part.stockable)}
                                                        onChange=   {(e)=>setServiceFormData({...serviceFormData, part: e.value})}
                                                        value=      {serviceFormData.part}
                                                        margin=     "none"
                                                        label=      "Service *"
                                                    />
                                                }
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    label='Quantity *'
                                                    fullWidth
                                                    type='number'
                                                    value={serviceFormData.qty}
                                                    name={'serviceFormData.qty'}
                                                    onChange=   {(e)=>setServiceFormData({...serviceFormData, qty: e.target.value})}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    fullWidth
                                                    onClick={addService}
                                                    disabled={!serviceFormData.part || !serviceFormData.qty || parseFloat(serviceFormData.qty) <= 0}
                                                >Add Service</Button>
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                                {formData.services.length > 0 &&
                                    <Grid item xs={12}>
                                        <ExpansionPanel expanded={true}>
                                            <ExpansionPanelSummary style={{backgroundColor: colors.green}}>
                                                <Typography>Added Services</Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <CiDataTable
                                                    config={{
                                                        key: 'rop_id'
                                                    }}
                                                    rows={_.map(formData.services, i => ({...i, part: _.find(lists.parts, {value: i.part})?.part}))}
                                                    columns={[
                                                        {
                                                            heading: '',
                                                            field: 'part.default_image.file_path',
                                                            fieldFormat: 'image',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: 'Part',
                                                            field: i => <>
                                                                <Typography variant='body2'>{i?.part?.part_number}</Typography>
                                                                <Typography variant='caption'>{i.part.part_description}</Typography>
                                                            </>,
                                                        },
                                                        {
                                                            heading: 'qty',
                                                            field: 'qty',
                                                            fieldFormat: 'decimal:2',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            actions: i => ([
                                                                {
                                                                    name: 'Remove',
                                                                    icon: IconHelper.bin,
                                                                    onClick: () => {
                                                                        onChange({
                                                                            services: _.filter(formData.services, (j,idx) => idx !== i.rowIdx)
                                                                        });
                                                                    },
                                                                }
                                                            ])
                                                        }
                                                    ]}
                                                />
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                }
                                {
                                    _.filter(estimatedParts, i => (!_.find(formData.services, j => parseFloat(j.part) === parseFloat(i.roep_part_id))) && !i.part.stockable).length > 0 &&
                                    <Grid item xs={12}>
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{backgroundColor: colors.orange}}>
                                                <Typography>Recommended Services</Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <CiDataTable
                                                    config={{
                                                        key: 'rop_id'
                                                    }}
                                                    rows={_.filter(estimatedParts, i => (!_.find(formData.services, j => parseFloat(j.part) === parseFloat(i.roep_part_id))) && !i.part.stockable)}
                                                    columns={[
                                                        {
                                                            heading: '',
                                                            field: 'part.default_image.file_path',
                                                            fieldFormat: 'image',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: 'Part',
                                                            field: i => <>
                                                                <Typography variant='body2'>{i.part.part_number}</Typography>
                                                                <Typography variant='caption'>{i.part.part_description}</Typography>
                                                            </>,
                                                        },
                                                        {
                                                            heading: 'qty',
                                                            field: 'roep_qty',
                                                            fieldFormat: 'decimal:2',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            actions: i => ([
                                                                {
                                                                    name: 'Add',
                                                                    icon: IconHelper.plus,
                                                                    onClick: () => {
                                                                        onChange({
                                                                            services: [...formData.services, 
                                                                                {
                                                                                    part: i.part.part_id,
                                                                                    qty: i.roep_qty
                                                                                }
                                                                            ]
                                                                        });
                                                                    },
                                                                }
                                                            ])
                                                        }
                                                    ]}
                                                />
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                }
                            </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
            }
        </Grid>
    )
}


class WriteOffDialog extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState();
    }

    componentDidMount(){
        this.getLists();
    }

    getLists = () => {
        Promise.all([
            API.get('/customerReturns/repairOrders/writeOffReasons'),
            API.get('/parts/all' , {params: {active: true, hasPrice: true, use: 'quotation'}})
        ]).then(res => {
            this.setState({ lists: { 
                reasons: _.map(res[0].data, i => ({value: i.rowor_name, label: i.rowor_name})),
                parts: _.map(res[1].data, i => ({
                    part: i,
                    value: i.part_id,
                    label: i.part_number + ' - ' + i.part_description,
                })) 
            } }, this.getRepairOrder);
        });
    }

    getRepairOrder = () => {
        API.get(`/customerReturns/repairOrders/${this.props.id}`)
        .then(res => {
            this.setState({
                repair: res.data,
                formData: _.map(res.data.repair_orders, r => ({
                    ...initialWriteOffState(),
                    roId: r.ro_id
                })),
                isLoading: false,
                // globalWriteOff: res.data.repair_orders.length > 1
            })
        })
    }

    submit = () => {
        let formData = this.state.globalWriteOff ? _.map(this.state.repair.repair_orders, r => ({...this.state.globalFormData, roId: r.ro_id, 
            parts: _.map(this.state.globalFormData.parts, p => ({
                ...p,
                qty: parseFloat(parseFloat(p.qty) / this.state.repair.repair_orders.length)
            })),
            services: _.map(this.state.globalFormData.services, p => ({
                ...p,
                qty: parseFloat(parseFloat(p.qty) / this.state.repair.repair_orders.length)
            }))
        })) : this.state.formData;
        Promise.all(
            _.map(formData, i => this._submit(i))
        ).then(this.props.onClose);
    }

    _submit = (formData) => {
        let newFormData = new FormData();
        newFormData.append('reason', formData.reason);
        newFormData.append('notes', formData.notes);
        newFormData.append('parts', JSON.stringify(formData.parts));
        newFormData.append('services', JSON.stringify(formData.services));

        _.each(formData.files, f => {
            newFormData.append(`files[]`, f);
        });
        return API.post(`/customerReturns/repairOrders/${this.props.id}/${formData.roId}/writeOff`, newFormData)
    }

    render () {
        const { globalWriteOff, isLoading, formData, globalFormData, lists } = this.state;
        const { onClose } = this.props;

        if (isLoading){
            return (
                <LoadingCircle/>
            )
        }

        return (    
            <Grid container spacing={1}>
                {/* {this.state.repair.repair_orders.length > 1 &&
                    <Grid item xs={12}>
                        <CheckBox
                            label='Group All Parts As Single Write Off'
                            value={this.state.globalWriteOff}
                            checked={this.state.globalWriteOff}
                            onChange={e => this.setState({globalWriteOff: !globalWriteOff})}
                            color='primary'
                        />
                    </Grid>
                } */}
                {
                    this.state.globalWriteOff ?
                    <Grid item xs={12}>
                        <WoBlock formData={globalFormData} onChange={(data) => {this.setState({ globalFormData: {...globalFormData, ...data} })} } lists={lists} repairOrder={
                            {estimated_parts: []/*_.flatten(_.map(this.state.repair.repair_orders, r => r.estimated_parts))*/, group: true}
                        } />
                    </Grid> :
                    <Grid item xs={12} >
                        {_.map(formData, (i,idx) => {
                            let row = _.find(this.state.repair.repair_orders, {ro_id: i.roId});
                            return (
                                <ExpansionPanel defaultExpanded={this.state.repair.repair_orders.length === 1}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Grid container spacing={1}
                                            style={{alignItems: 'center'}}
                                        >
                                            <Grid item style={{marginRight: '0.5em', width: 42}}>
                                                <Box
                                                    style={{
                                                        padding: 2,
                                                        backgroundColor: colors.white,
                                                        borderRadius: 2,
                                                        textAlign: 'center',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        verticalAlign: 'middle'
                                                    }}
                                                >
                                                    <ImageWithError
                                                        src={row?.part?.default_image?.file_path}
                                                        style={{
                                                            maxWidth: 38,
                                                            maxHeight: 38,
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item >
                                                <Typography style={{color: colors.white}} variant='body1'>{row.part.part_number}{((row.ro_sn) && (row.ro_sn) != '[]') && ` (${row.ro_sn})`}{((row.ro_wvl) && (row.ro_wvl) != '[]') && ` (${row.ro_wvl})`}</Typography>
                                                <Typography style={{color: colors.white}} variant='body2'>{row.part.part_description}</Typography>
                                            </Grid>
                                        </Grid>
                                        
                                        
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <WoBlock 
                                            formData={i} 
                                            onChange={(data) => {
                                                let newFormData = formData;
                                                newFormData[idx] = {...newFormData[idx], ...data};
                                                this.setState({ formData: newFormData })
                                            }} 
                                            lists={lists} 
                                            repairOrder={row}
                                        />
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )
                        })}
                    </Grid>
                }
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant='outlined'
                        onClick={onClose}
                    >Close</Button>
                    <Button
                        variant='contained'
                        color='secondary'
                        disabled={
                            globalWriteOff ? (
                                !globalFormData.reason ||
                                !globalFormData.notes ||
                                !globalFormData.files.length
                            ) : _.find(formData, i => !i.reason || !i.notes || !i.files.length)
                        }
                        onClick={() => this.props.deployConfirmation(
                            'Are you sure you want to write off this repair?',
                            'Write Off Repair',
                            this.submit
                        )}  
                    >
                        Write Off
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
})

export default connect(null, mapDispatchToProps)(WriteOffDialog);
