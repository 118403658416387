import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import API from '../../../API';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import DatePicker from '../../Common/DatePickers/DatePicker';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import { convertFileUploadsName } from './../../../Helpers/DocumentHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import MaterialLink from '@material-ui/core/Link';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import { MAX_UPLOAD_LIMIT_SIZE } from './../../../Constants';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import BackButton from 'Components/Common/Buttons/BackButton';
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData: {
        documentId: '',
        originalDocument:  '',
        pdfDocument:  '',
        dateAdded: '',
        category: '',
        subCategory: '',
        title: '',
        fileUploadsDisplay: '',
        actualDocStatus: '',
        currentStatus: '',
        versionMatch: '',
        duoReference: '',
        duoUrl: '',
        technicalLibraryAccess:''
    },
    showOriginalDocument: true,
    showPdfDocument: true,
    showFileUpload: false,
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    redirect: false,
    successRedirect: false,
    isLoading: false,
    initialLoading: true,
}

class UpdateDocument extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getDocumentDetails(this.props.match.params.id);
    }
    
    getDocumentDetails = (documentId) => {
        API.get(`/documents/${documentId}`)
        .then((result) => {
            if (result.data.errors) {
                this.setState({ redirect: true });
            } else if(result.data){
                switch(result.data.doc_file_uploads) {
                    case 'both':
                        this.setState({showOriginalDocument: true, showPdfDocument: true});
                    break;
                    case 'pdf_only':
                        this.setState({showOriginalDocument: false, showPdfDocument: true});
                    break;
                    case 'original_only':
                        this.setState({showOriginalDocument: true, showPdfDocument: false});
                    break;
                    default:
                    break;
                }
                const fileUploads = convertFileUploadsName(result.data.doc_file_uploads);
                let actual_document_status  = result.data.latest_update_order && result.data.latest_update_order.duo_version > 1 ? result.data.latest_update_order.duo_version : 1;
                let current_status       = result.data.latest_file && result.data.latest_file.file_version > 1 ? result.data.latest_file.file_version : 1
                this.setState({
                    formData: {
                        ...this.state.formData,
                        documentId: documentId,
                        category: result.data.category.parent.doc_cat_name,
                        subCategory: result.data.category.doc_cat_name,
                        title: result.data.doc_title,
                        fileUploadsDisplay: fileUploads,
                        fileUploads: result.data.doc_file_uploads,
                        actualDocStatus:actual_document_status,
                        currentStatus: current_status,
                        versionMatch: (actual_document_status === current_status) ? 'Yes' : 'No',
                        dateAdded: result.data.latest_update_order ? moment(result.data.latest_update_order.duo_date_changed).format("YYYY-MM-DD") : moment().toString(),
                        technicalLibraryAccess: result.data.doc_technical_library_access,
                    }
                }, 
                () => {
                    const versionNumber = this.state.formData.currentStatus + 1;
                    API.get(`/documents/updateOrders/document/${documentId}/version/${versionNumber}`)
                    .then((result) => {
                        if(result.data){
                            this.setState({
                                formData: {
                                    ...this.state.formData,
                                    duoReference: result.data.duo_reference,
                                    duoUrl: result.data.duo_file_url
                                },
                                initialLoading: false
                            })
                        }
                    });
                })
            }
        });
    }
    
    handleDateChange = date => {
        this.setState({
            formData: {
                ...this.state.formData,
                dateAdded: date
            }
        });
    };

    handleCheckChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleChange = (e) => {
        const name = e.target.name;
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        }, 
        () => {
            if(name === 'fileUploads'){
                switch(this.state.formData.fileUploadsDisplay){
                    case 'both':
                        this.setState({showOriginalDocument: true, showPdfDocument: true});
                    break;
                    case 'pdf_only':
                        this.setState({showOriginalDocument: false, showPdfDocument: true});
                    break;
                    case 'original_only':
                        this.setState({showOriginalDocument: true, showPdfDocument: false});
                    break;
                    default:
                    break;
                }
            }

        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            let newFormData = new FormData();
            Object.keys(this.state.formData).forEach(key => {
                if(key === 'dateAdded'){
                    newFormData.append(key, moment(new Date(this.state.formData[key])).format('DD/MM/YYYY'))
                }
                else{
                    newFormData.append(key, this.state.formData[key])
                }
                
            });
            API.post(`/documents/${this.state.formData.documentId}`, newFormData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        successRedirect: true,
                        isLoading: false
                    });
                }            
                this.props.scrollToTop();
            });
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleSelectChange = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: res.value
            }
        }, 
        () => {
            if(name === 'documentId'){
                this.getDocumentDetails(res.value);
            }
        });
    };

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    clearPdfDocument = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                pdfDocument: initialState.formData.pdfDocument
            }
        });
    };

    clearOriginalDocument = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                originalDocument: initialState.formData.originalDocument
            }
        });
    };

    downloadFile = (fileUrl) => {
        window.open(fileUrl, '_blank');
    }
       
    render() {
        if (this.state.successRedirect === true) {
            return <Redirect to={`/documents/view/${this.props.match.params.id}`} />
        } else if (this.state.redirect === true) {
            return <Redirect to="/documents/search" />
        }
        const { formErrors, formData, initialLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5" >
                        Update Document
                    </Typography>
                </Grid>
                {(initialLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                )) || (
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <Typography variant="h6" gutterBottom>
                                        Confirm Document Update Order
                                    </Typography>
                                    <Typography>
                                        {formData.duoReference ?
                                            <MaterialLink component="button" variant="body1" className='blueLink' onClick={() => {this.downloadFile(formData.duoUrl)}}>
                                                {formData.duoReference}
                                            </MaterialLink>
                                        :
                                            '-'
                                        }
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                            checked={this.state.showFileUpload}
                                            onChange={this.handleCheckChange('showFileUpload')}
                                            value="showFileUpload"
                                            color="primary"
                                            />
                                        }
                                        label="Correct Document Update Order"
                                    />
                                    <br></br><br></br>
                                    <Typography variant="h6">
                                        New Document(s)
                                    </Typography>
                                    {formErrors && formErrors.generic && (
                                        <React.Fragment>
                                            <Typography component={"div"} style={{color: colors.red}}>
                                                {formErrors.generic}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                    <form noValidate autoComplete="off">    
                                        {this.state.showOriginalDocument &&
                                            <DragFileInput
                                                id="originalDocumentInput"
                                                name="originalDocument"
                                                label="Original Document (.doc, .docx) *"
                                                file={this.state.formData.originalDocument}
                                                errorText={formErrors && formErrors['originalDocument']}
                                                onChange={this.handleFileChange}
                                                cancelOnClick={this.clearOriginalDocument}
                                                emptyText='No document selected'
                                                disabled={!this.state.showFileUpload}
                                            />
                                        }
                                        {this.state.showPdfDocument &&
                                            <DragFileInput
                                                id="pdfDocumentInput"
                                                name="pdfDocument"
                                                label="PDF Document (.pdf) *"
                                                file={this.state.formData.pdfDocument}
                                                errorText={formErrors && formErrors['pdfDocument']}
                                                onChange={this.handleFileChange}
                                                cancelOnClick={this.clearPdfDocument}
                                                emptyText='No document selected'
                                                disabled={!this.state.showFileUpload}
                                            />
                                        }
                                        <DatePicker
                                            type="date"
                                            id="dateAdded"
                                            name="dateAdded"
                                            label="Date Added *"
                                            value={this.state.formData.dateAdded}
                                            errorText={formErrors && formErrors['dateAdded']}
                                            onChange={this.handleDateChange}
                                            disableFuture={true}
                                            disabled={!this.state.showFileUpload}
                                        />
                                        <div className='buttonRow' style={{justifyContent: 'space-between', flexDirection: 'row', alignItems:'center'}}>
                                            <Typography variant="subtitle2" style={{flex: 1}}>
                                                <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                                            </Typography>
                                            <BackButton props={this.props} />
                                            <Button onClick={this.handleConfirmationOpen}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={!this.state.showFileUpload || this.state.isLoading}>
                                                Update Document
                                            </Button>
                                        </div>
                                    </form>
                                    <ConfirmationDialog 
                                        open={this.state.confirmationOpen} 
                                        success={this.handleConfirmationSuccess} 
                                        close={this.handleConfirmationClose} 
                                        title="Update This Document?" 
                                        message="Are you sure you want to update this document?"
                                    />
                                    <SnackBar
                                        variant="success"
                                        anchorOriginVertical='bottom'
                                        anchorOriginHorizontal='right'
                                        open={this.state.snackbarOpen}
                                        onClose={this.handleSnackbarClose}
                                        message='You have successfully updated this document'
                                    />
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <Typography variant="h6">
                                        Document Details
                                    </Typography>
                                    <form autoComplete="off" noValidate>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="category" shrink={!!formData.category}>Category</InputLabel>
                                            <Input id="category" value={formData.category || ''} />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="subCategory" shrink={!!formData.subCategory}>Sub Category</InputLabel>
                                            <Input id="subCategory" value={formData.subCategory || ''} />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="title" shrink={!!formData.title}>Title</InputLabel>
                                            <Input id="title" value={formData.title || ''} />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="fileUploads" shrink={!!formData.fileUploadsDisplay}>File Uploads</InputLabel>
                                            <Input id="fileUploads" value={formData.fileUploadsDisplay || ''} />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="actualDocStatus" shrink={!!(formData.actualDocStatus)}>Actual Doc Status (Based on DUO)</InputLabel>
                                            <Input id="actualDocStatus" value={(formData.actualDocStatus && `v${formData.actualDocStatus}`) || ''} />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="currentStatus" shrink={!!(formData.currentStatus)}>Current Status of Docs</InputLabel>
                                            <Input id="currentStatus" value={(formData.currentStatus && `v${formData.currentStatus}`) || ''} />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="versionMatch" shrink={true}>Version Match (DUO &amp; Docs)</InputLabel>
                                            <Input id="versionMatch" value={formData.versionMatch} style={{color: formData.versionMatch === 'Yes' ? colors.green : colors.red}} />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="technicalLibraryAccess" shrink={true}>Available in Technical Library</InputLabel>
                                            <Input id="technicalLibraryAccess" value={formData.technicalLibraryAccess} />
                                        </FormControl>
                                    </form>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

export default UpdateDocument;