export const orderTypes = [
    'Official PO by Email',
    'Official PO by Fax',
    'Official PO by Post',
    'Online Only',
    'Email Only',
    'Phone Only',
    'Post',
    'Phone & Email',
    'Fax & Email'
]
export const leadTimes = [
    'Within 1 Week', 
    '1 Week', 
    '2 Weeks',
    '3 Weeks', 
    '4 Weeks', 
    '5 Weeks',  
    '6 Weeks',
    '7 Weeks', 
    '8 Weeks', 
    '9 Weeks', 
    '10 Weeks',
    '11 Weeks', 
    '12 Weeks', 
    '13 Weeks', 
    '14 Weeks',
    '15 Weeks', 
    '16 Weeks'
]
export const paymentTerms = [
    'Direct Debit',
    'Credit/Debit Card', 
    'Pro Forma', 
    '7 Days', 
    '14 Days', 
    '21 Days', 
    '30 Days', 
    '60 Days', 
    '90 Days',
    '50% on Order / 50% on Delivery',
    '50% on Order / 50% on 30 Days',
    '50% on Order / 50% on 60 Days'
]