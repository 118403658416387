
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import moment from 'moment';
import _ from 'lodash';
import CiDataTable from '../../Common/DataTables/CiDataTable';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import {colors} from 'Helpers/ColourHelper';

class TransactionTables extends PureComponent {
    render() {
        const { transactions, actions, status, loggedInStaff, access } = this.props;
        return (
            <React.Fragment>
                {status == 'Authorisation' ?
                    _.map(transactions, (data, staff) => {
                        if(access.allStaffTransactions || loggedInStaff.id === data[0]?.staff?.staff_id){
                            return (
                                <ExpansionPanel key={staff}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{staff == 'undefined' ? 'Unassigned' : staff }</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{padding:0}}>
                                        <CiDataTable  
                                            config={{
                                                key: 'bank_trans_id',
                                                responsiveImportance: true,
                                                plainHeader: true,
                                                options: {
                                                    dataRef: true
                                                }
                                            }}
                                            columns={[
                                                {
                                                    heading: '',
                                                    field: rowData => Math.sign(rowData.bank_trans_gross) == '-1' ? 
                                                                    <FALightIcon icon='arrow-left' style={{color:colors.green}} />
                                                                    :
                                                                    <FALightIcon icon='arrow-right' style={{color:colors.red}} />,
                                                },
                                                {
                                                    heading: 'Bank / Card Transaction Date',
                                                    field: rowData => moment(rowData.bank_trans_date).format("DD-MM-YYYY"),
                                                    dataRef: 'bank_trans_date',
                                                },
                                                {
                                                    heading: 'Account Owner',
                                                    field: rowData => rowData.bank_trans_account_owner,
                                                },
                                                {
                                                    heading: 'Description',
                                                    field: rowData => rowData.bank_trans_description,
                                                },
                                                {
                                                    heading: 'Net',
                                                    field: rowData => rowData.bank_trans_net,
                                                    alignment: 'right',
                                                    fieldPrefix: '£',
                                                },
                                                {
                                                    heading: 'VAT',
                                                    field: rowData => rowData.bank_trans_vat,
                                                    alignment: 'right',
                                                    fieldPrefix: '£',
                                                },
                                                {
                                                    heading: 'Amount',
                                                    field: rowData => rowData.bank_trans_gross,
                                                    alignment: 'right',
                                                    fieldPrefix: '£',

                                                },
                                                {
                                                    actions: actions
                                                }
                                            ]}
                                            rows={data}
                                        />
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )
                        }
                    })
                    :
                    <CiDataTable  
                        config={{
                            key: 'bank_trans_id',
                            responsiveImportance: true,
                            options: {
                                dataRef: true
                            }
                        }}
                        columns={[
                            {
                                heading: '',
                                field: rowData => Math.sign(rowData.bank_trans_gross) == '-1' ? 
                                                <FALightIcon icon='arrow-left' style={{color:colors.green}} />
                                                :
                                                <FALightIcon icon='arrow-right' style={{color:colors.red}} />,
                            },
                            {
                                heading: 'Bank / Card Transaction Date',
                                field: rowData => moment(rowData.bank_trans_date).format("DD-MM-YYYY"),
                                dataRef: 'bank_trans_date',
                            },
                            {
                                heading: 'Receipt Date',
                                field: rowData => moment(rowData.bank_trans_receipt_date).format("DD-MM-YYYY"),
                                dataRef: 'bank_trans_receipt_date',
                            },
                            {
                                heading: 'Assigned To',
                                field: rowData => rowData.staffFullName,
                                dataRef: 'staffFullName',
                            },
                            {
                                heading: 'Description',
                                field: rowData => rowData.children && rowData.children.length > 0 ? 'Various' : rowData.bank_trans_description,
                            },
                            {
                                heading: 'Net',
                                field: rowData => rowData.bank_trans_net,
                                alignment: 'right',
                                fieldPrefix: '£',
                            },
                            {
                                heading: 'VAT',
                                field: rowData => rowData.bank_trans_vat,
                                alignment: 'right',
                                fieldPrefix: '£',
                            },
                            {
                                heading: 'Amount',
                                field: rowData => rowData.bank_trans_gross,
                                alignment: 'right',
                                fieldPrefix: '£',

                            },
                            {
                                actions: actions
                            }
                        ]}
                        rows={transactions}
                    />
                }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff
    };
}

export default connect(mapStateToProps)(TransactionTables)
