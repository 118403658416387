import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import API from 'API';
import _ from 'lodash';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { Tooltip, Grid, Typography, TextField, Button } from '@material-ui/core';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import moment from 'moment';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { colors } from 'Helpers/ColourHelper';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import CondemnEquipmentDialog from 'Components/Equipment/Misc/CondemnEquipmentDialog';

const Equipment = ({ customerId=0 }) => {

    const   dispatch = useDispatch(),
            [isLoading, setIsLoading] = useState(true),
            [equipmentList, setEquipmentList] = useState([]),
            [filteredList, setFilteredList] = useState([]),
            [sites, setSites] = useState([]),
            // Filters
            [filterView, setFilterView] = useState('Current'),
            [filterSite, setFilterSite] = useState(0),
            [keywords, setKeywords] = useState('');
    
    /*
    * Load Equipment List
    */
    useEffect(() => {
        if(!customerId) return;

        // Get Access
        API.get('/staff/my/access/check/view-customer-tab-equipment')
        .then(result => {
            if(!result.data?.has_access) {
                // Redirect to customer view
                window.location.href = `/customers/view/${customerId}`;
            }
            else {
                getEquipment();
            }
        });

    }, [customerId]);


    /*
    * Filter results
    */
    useEffect(() => {
        if(!equipmentList?.length) return;
        
        let filteredEquipmentList = [];

        // Filter by View
        switch(filterView) {
            case 'Current':
                filteredEquipmentList = _.filter(equipmentList, e => e?.latest === 1 && e?.details?.type !== 'customerReturn' && e?.details?.type !== 'creditNote');

            break;
            case 'Previous':
                filteredEquipmentList = _.filter(equipmentList, e => e?.latest === 0);
            break;
            default:
                filteredEquipmentList = equipmentList;
            break;
        }

        // Filter by Site
        if(filterSite) {
            filteredEquipmentList = _.filter(filteredEquipmentList, e => e?.addressId === filterSite);
        }

        // Filter by Keywords
        if(keywords) {
            let lowerCaseKeywords = keywords.toLowerCase();
            filteredEquipmentList = _.filter(filteredEquipmentList, e => {
                return _.includes(e?.sn?.toLowerCase(), lowerCaseKeywords) || _.includes(e?.partNo?.toLowerCase(), lowerCaseKeywords) || _.includes(e?.partDesc?.toLowerCase(), lowerCaseKeywords);
            });
        }

        setFilteredList(filteredEquipmentList);

    }, [filterView, equipmentList, filterSite, keywords]);

    const getEquipment = () => {
        API.get(`/equipment`, { 
            params: {
                customerId
            }
        })
        .then(result => {

            setIsLoading(false);

            if(result.data) {
                setEquipmentList(result.data);

                // Get unique sites
                const uniqueSites = _.uniqBy(result.data, 'addressId');
                const siteOptions = _.map(uniqueSites, site => {
                    return {
                        value: site?.addressId,
                        label: site?.addressName
                    }
                });
                setSites([
                    {value: 0, label: 'All'},
                    ...siteOptions
                ]);
            }  
        });
    }   

    const NotesDialog = ({ note }) => (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="body2" paragraph>
                    {note.split('\n').map((item, key) => {
                        return  <span key={key}>
                                    {item}{note.split('\n').length === key+1 ? null : <br/>}
                                </span>
                    })}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <div className='buttonRow'>
                    <Button variant="outlined" color="default" onClick={() => dispatch(closeDialog()) }>Close</Button>
                </div>
            </Grid>
        </Grid>
    )

    let restrictedStatuses = ['Returned', 'Condemned', 'Written Off', 'Replaced'];

    return (
        (isLoading && <LoadingCircle />) || (

            <Grid container xs={12} spacing={3}>
                <Grid item xs={12} lg={2}>
                    <AutoCompleteSelect 
                        options={[
                            {value: 'All', label: 'All Equipment'},
                            {value: 'Current', label: 'Current Equipment'},
                            {value: 'Previous', label: 'Previous Equipment'},
                        ]} 
                        label='View'
                        onChange={ e => setFilterView(e?.value) }
                        value={filterView}
                        noClear
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <AutoCompleteSelect 
                        options={sites} 
                        label='Site'
                        onChange={ e => setFilterSite(e?.value) }
                        value={filterSite}
                        noClear
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <TextField label="Keywords" fullWidth value={keywords} onChange={ e => setKeywords(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                    <DataTable  
                        config={{
                            key: 'id',
                            pagination: true,
                            alternatingRowColours: false,
                            isLoading: isLoading,
                            responsiveImportance: true,
                            rowProps: rowData => {
                                return {
                                    style: {
                                        color: rowData?.latest === 0 || ['creditNote', 'customerReturn'].includes(rowData?.details?.type) ? colors.red : undefined,
                                    }
                                }
                            }
                        }}
                        columns={[
                            {
                                heading: 'type',
                                field: rowData =>  
                                <>
                                    <Tooltip
                                        title={
                                            <div >
                                                {rowData.m ? 'Manual' : 'System Generated'}
                                            </div>
                                        }
                                        placement="left"
                                    >
                                        <span>
                                            <AllIcon 
                                                icon={rowData.m ? 'keyboard' : 'laptop-code'}
                                                button
                                                style={{ color: rowData?.latest === 0 || ['creditNote', 'customerReturn'].includes(rowData?.details?.type) ? colors.red : undefined,}}
                                                noMargin
                                            />
                                        </span>
                                    </Tooltip>
                                </>,
                                important: true,
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'Serial Number',
                                field: rowData => rowData?.sn,                                            
                                important: true,
                                sizeToContent: true
                            },
                            {
                                heading: 'Part Number',
                                field: rowData => rowData?.partNo,
                                hideInResponsiveDialog: true,
                                sizeToContent: true
                            },
                            {
                                heading: 'Part Description',
                                field: rowData => rowData?.partDesc,
                                main: true,
                                important: true,
                                hideInResponsiveDialog: true,
                                sizeToContent: true
                            },
                            {
                                heading: 'Address',
                                field: rowData => 
                                    <>
                                    {rowData?.details?.address && (
                                        <Tooltip
                                            title={
                                                <Typography variant="body2" style={{whiteSpace: 'pre-wrap'}}>
                                                    <b>Site Address:</b><br />
                                                    {rowData?.address.split('\n').map((item, key) => {
                                                        return  <span key={key}>
                                                                    {item}{rowData?.details?.address.split('\n').length === key+1 ? null : <br/>}
                                                                </span>
                                                    })}
                                                </Typography>

                                            }
                                            placement="right"
                                        >
                                            <span>
                                                <AllIcon 
                                                    heavy
                                                    icon='info-circle'
                                                    button 
                                                    size='xsmall'
                                                    style={{
                                                        color: 'rgba(0, 0, 0, 0.20)'
                                                    }}
                                                />
                                            </span>
                                        </Tooltip>
                                    )}
                                    <span style={{color: ['creditNote', 'customerReturn'].includes(rowData?.details?.type) ? colors.red : undefined}}>{rowData?.addressName ?? '-'}</span>
                                    </>,
                            },
                            {
                                actionsCustomHeader: true,
                                heading: 'Reference',
                                alignment: 'left',

                                actions: rowData => {
                                    return rowData?.details?.type ? 
                                            (rowData?.details?.type == 'order') ? 
                                            [{ label: rowData?.details?.ref,  link: `/sales/order/view/${rowData?.details?.id}`, type:'plainLink', color: rowData?.latest === 0  ? colors.red : undefined }] :
                                            ( rowData?.details?.type == 'creditNote' ? 
                                                [{ label: rowData?.details?.ref,  link: `/returns/creditnote/${rowData?.details?.id}`, type:'plainLink', color: colors.red }] 
                                            :
                                                [{ label: rowData?.details?.ref,  link: `/returns/customerReturn/${rowData?.details?.id}`, type:'plainLink', color: colors.red }]
                                            )
                                        :
                                            [{ label: '-'}]
                                }
                            },
                            {
                                heading: 'Status',
                                field: rowData => rowData?.status,
                                sizeToContent: true,
                                cellProps : rowData => {
                                    return {
                                        style: {
                                            color: rowData?.status === 'Condemned' ? colors?.red : null
                                        }
                                    }
                                }
                            },
                            {
                                heading: 'Reason',
                                field: rowData => rowData?.reason ? <Tooltip title={<Typography variant="body2">{rowData?.reason}</Typography>}><span>{rowData?.reason}</span></Tooltip> : '-',
                                sizeToContent: true,
                                truncate: true
                            },
                            {
                                actions: rowData => {
                                    return [
                                        {name: 'View Notes',        icon: 'file',               onClick: () => dispatch(deployDialog(<NotesDialog note={rowData?.notes} />, 'Notes', '', 'sm')), disabled: !rowData?.notes},
                                        {name: 'Condemn Equipment', icon: 'skull-crossbones',   onClick: () => dispatch(deployDialog(<CondemnEquipmentDialog equipId={rowData.id} callBack={getEquipment} />, <> <AllIcon icon='skull-crossbones' size={16} color='white' /> Condemn Equipment</>, 'warning', 'sm')), disabled: restrictedStatuses.includes(rowData?.status) || rowData?.latest === 0},
                                        {name: 'View Equipment',    icon: 'search',             link: '/equipment/view/' + rowData.id},
                                    ]
                                }
                            }
                        ]}
                        rows={filteredList}
                    />
                </Grid>
            </Grid>
        )
    )
};

export default Equipment;