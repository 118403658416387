import React       from 'react';
import API         from 'API';
import _           from 'lodash';
import { connect } from 'react-redux';
import moment      from 'moment';

import { Typography, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';

import { formatValidationErrors } from 'Helpers/ErrorHelper';

import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import DataTable     from 'Components/Common/DataTables/DataTable';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';
import BackButton    from 'Components/Common/Buttons/BackButton';
import { Alert } from '@material-ui/lab';

const initialState = {
    formErrors: {},
    access: {
        allStaffTransactions: false
    },
    isLoading: false,
    bankDetails: {},
    statementList: [],
    completeDialogOpen: false,
    dialogData: {}
}
class BankPayments extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount(){
        this.loadInitialData(this.props.match.params.id);
        this.checkAccess();
    }

    loadInitialData = (bankId) => {
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([ 
                API.get(`/accounts/banks/${bankId}`),
                API.get(`/accounts/banks/${bankId}/unassignedPayments`),
                API.get(`/accounts/banks/${bankId}/monthlyStatementsByStatus`, { params: { status: 'Awaiting Payment' } })
            ])
            .then(([bankRes, paymentsRes, statementRes]) =>  {
                let statementList = _.map(statementRes.data, (el) => {
                    return _.assign({
                        value: el.bms_id,
                        label: el.bms_month + ' ' + el.bms_year
                    });
                });
                this.setState({
                    bankDetails: bankRes.data,
                    formErrors: {},
                    isLoading: false,
                    payments: paymentsRes.data,
                    statementList
                });
            });
        });
    }

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/unassigned-bank-transactions:all-staff'),
        ])
        .then(([staffRes]) =>  {
            staffRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        allStaffTransactions: staffRes.data.has_access
                    }
                });
        });
    }

    handleDialogClose = () => {this.setState({completeDialogOpen: false});};
    handleDialogOpen = rowData => {
        this.setState({
            completeDialogOpen: true,
            dialogData: rowData
        });
    };
    handleDialogSuccess = () => {
         API.post(`/accounts/banks/payments/${this.state.dialogData.bank_pay_id}/process`)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    completeDialogOpen: true
                });
            }
            else {
                let bank_id = this.props.match.params.id;
                this.props.deploySnackBar("success", "You have successfully confirmed the payment");
                this.setState({
                    ...initialState
                },
                () => {
                    this.loadInitialData(bank_id);
                });
            }
        });
    }

    render() {
        const { isLoading, payments, dialogData } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Bank / Card Payments
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Alert severity="info">
                        <Typography>
                            Plaid integration removed on 10th June 2024 - no new transactions will be imported.
                        </Typography>
                    </Alert>
                </Grid>
                {isLoading ? (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                ) : (
                    <>
                    <Grid container item spacing={3}>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <DataTable
                                    config={{
                                        key: 'bank_pay_id',
                                        responsiveImportance: true
                                    }}
                                    columns={[
                                        {
                                            heading: 'Bank / Card Transaction Date',
                                            field: rowData => moment(rowData.bank_pay_date).format("DD-MM-YYYY"),
                                        },
                                        {
                                            heading: 'Description',
                                            field: rowData => rowData.bank_pay_description,
                                        },
                                        {
                                            heading: 'Amount',
                                            field: rowData => rowData.bank_pay_gross,
                                            alignment: 'right',
                                            fieldPrefix: '£',
                                        },
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Confirm Payment', icon: 'pencil-alt', onClick: () => this.handleDialogOpen(rowData)}
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={payments}
                                />
                                <div className='buttonRow'>
                                    <BackButton body={'back'} props={this.props} />
                                </div>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <Dialog 
                        open={this.state.completeDialogOpen} 
                        onClose={this.handleDialogClose} 
                        fullWidth={true} 
                        maxWidth="sm" 
                        scroll="body"
                    >
                        <DialogTitle>
                            <Typography>Confirm Payment</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant="body2">
                                Are you sure you want to confirm the payment of <b>£{dialogData.bank_pay_gross}</b> ?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDialogClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={this.handleDialogSuccess} autoFocus variant="contained" color="primary">Confirm</Button>
                        </DialogActions>
                    </Dialog>
                    </>
                )}
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, msg) => {
            dispatch(deploySnackBar(variant, msg))
        }
    }
}

export default connect(null, mapDispatchToProps)(BankPayments);