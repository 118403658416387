import React, { Component } from 'react';
import API                  from 'API';
import { connect }          from 'react-redux';

import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { colors } from 'Helpers/ColourHelper';

import Image         from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Qr            from 'Components/Common/QrReader/QrReader';
import NumberField   from 'Components/Common/Inputs/NumberField';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = {
    isLoading:  false,
    showQr:     true,
    part:       {},
    worksOrder: {},
    writeOff:   {},
    formData:  {
        partId: 0,
        qty:    0,
        action: 'Pick',
    }
}

class WriteOffScanDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            part:       this.props.worksOrder.active_write_off.part,
            worksOrder: this.props.worksOrder,
            writeOff:   this.props.worksOrder.active_write_off,
        };
    }

    handleScan = partNumber => {
        if (partNumber && this.state.part.part_number === partNumber) {
            this.setState({
                showQr: false,
                formData: {
                    ...this.state.formData,
                    partId: this.state.part.part_id,
                }
            });
        }
    }

    handleChange = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            }
        })
    }

    handleSubmit = () => {
        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/writeOffs/${this.props.worksOrder.active_write_off.wobwo_id}/pick`, {
            part:     this.state.formData.partId,
            quantity: this.state.formData.qty,
            action:   this.state.formData.action,
        })
        .then(res => {
            this.setState({
                formData: initialState.formData
            },this.props.callBack);
        })
    }

    render() {

        const { isLoading, part, showQr, formData, writeOff } = this.state;
        const { classes } = this.props;

        if (isLoading) return ( <LoadingCircle /> )

        return (
            <Grid container justify="center" className={`${classes.root} text-center`} style={{height: '100%', marginTop: 70}} spacing={8}>
                <Grid container item className={classes.qrRow}>
                    <Grid item xs={6}>
                        {showQr && <Qr handleScan={this.handleScan} width="100%" /> }
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>
                <Grid item container xs={12} style={{alignItems: 'center'}}>
                    <Grid item container justify="center" className="text-center" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h3">
                                {part.part_number}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                {part.part_description}<br></br>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="center" className="text-center" spacing={3}>
                                <Grid item xs={12}>
                                    <Image src={part?.default_image?.file_path} className={classes.img} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h5">
                                Required: {parseFloat(writeOff.wobwo_remaining).toFixed(3)}
                            </Typography>
                        </Grid>
                        {formData.partId > 0 && 
                            <>
                                <Grid item xs={12}>
                                    <Grid container justify="center" className="text-center" spacing={3}>
                                        <Grid item style={{width: 400}}>
                                            <NumberField
                                                id="qty"
                                                name="qty"
                                                label={`Quantity Required *`}
                                                value={formData.qty}
                                                type="number"
                                                variant="outlined"
                                                onChange={(e)=>{this.handleChange('qty', e)}}
                                                margin="normal"
                                                className={classes.confirmQtyInputContainer}
                                                inputProps={{
                                                    className: classes.confirmQtyInput,
                                                    min: 0,
                                                    pattern: "*"
                                                }}
                                                granularity={function(){
                                                    var decimals = (parseFloat(writeOff.wobwo_remaining) !== parseFloat(Math.floor(writeOff.wobwo_remaining)))?(writeOff.wobwo_remaining.toString()).split('.')[1].length:0;
                                                    if (!decimals) return 1;
                                                    return Math.pow(10, -(decimals-1));
                                                }()}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    {formData.qty > parseFloat(writeOff.wobwo_remaining) && 
                                        <span style={{color: colors.red}}>You cannot pick more stock than was written off</span>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant='contained' color='primary' disabled={!parseFloat(formData.qty) || formData.qty > parseFloat(writeOff.wobwo_remaining)} onClick={()=>{
                                        this.props.deployConfirmation('Are you sure you want to pick this part?', 'Pick Stock', this.handleSubmit)
                                    }}>Confirm</Button>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const styles = theme => ({
    root: {
        height: '80vh',
        padding: theme.spacing(1)
    },
    img: {
        maxHeight: 330,
        maxWidth: '100%'
    },
    qrRow: {
        maxHeight: '30vw',
        maxWidth: '30vw',
        zIndex: 99999,
        position:'fixed',
        top: '6em',
        left: '5em'
    },
    confirmQtyInputContainer: {
        maxWidth: 400,
    },
    confirmQtyInput: {
        textAlign: 'center',
        maxWidth: 400,
        fontSize: 40,
    },
    [theme.breakpoints.down('sm')]: {
        img: {
            maxHeight: 240,
            maxWidth:  240
        },
        actionText: {
            fontSize: '2rem',
        },
        confirmQtyInputContainer: {
            maxWidth: 120,
        },
        confirmQtyInput: {
            maxWidth: 100,
            fontSize: 30,
        },
        largeButton: {
            fontSize: 18
        }
    }
});


function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(WriteOffScanDialog))