import React, {Component} from 'react';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FALightIcon from '../Icons/FontAwesome/FALightIcon';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';


class SearchInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeout: null
        };
    }

    handleKeyup = (e) => {
        this.props.onChange(e);
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item spacing={3}>
                    <FormControl>
                        <Input
                            placeholder={this.props.placeholder}
                            value={this.props.value}
                            onChange={this.handleKeyup}
                            fullWidth
                            endAdornment={
                                <FALightIcon icon='search' button noMargin  />
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item spacing={3}>
                    <Button variant='outlined' onClick={this.props.onClick}>
                        <FALightIcon icon='undo' button />
                        Reset Search
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default SearchInput;
