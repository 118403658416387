import React from 'react';

import AllIcon from 'Components/Common/Icons/AllIcon';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

export default function ({icon, color, size, onClick}) {
    const iconsSize = parseInt(size / 2.5);
    return (
        <div style={{textAlign: 'center', position: 'relative', height: size, width: size, margin: 0}}>
            <AllIcon heavy noMargin icon={icons.locationPin} size={size} color={color} />
            <AllIcon heavy noMargin icon={icon} color={colors.white} size={iconsSize} style={{position: 'absolute', zIndex: 'auto', top: `calc(40% - ${parseInt(iconsSize/2)}px)`, left: `calc(50% - ${parseInt(iconsSize/2)}px)`}}/>
        </div>
    )
};