import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Grid from '@material-ui/core/Grid';
import CiDataTable from '../../Common/DataTables/CiDataTable';
import API from '../../../API';
import moment from 'moment';
import { pdfFromBase64 } from '../../../Helpers/PDFHelper';
import SnackBar from '../../Common/SnackBars/SnackBar';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import { downloadS3File } from 'Functions/MiscFunctions';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    searchResults:      [],
    isLoading:          false,
    confirmationOpen:   false,
    snackbarOpen:       false,
    snackbarMessage:    ''
});

class ProductionServiceBulletinSearch extends Component {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'RnD:ProductionServiceBulletinSearch';
        this.state              = this.getInitialState(initialState());
    }
    componentDidMount(){
        this.getProductionServiceBulletins();
        if(this.props.location.state) {
            const { year, month, snackbarOpen } = this.props.location.state;
            if(year && month && snackbarOpen){
                this.setState({
                    snackbarOpen,
                    snackbarMessage: `You have successfully updated production service bulletin ${moment(month, 'M').format('MMMM')} ${year}`
                });
            }
        }
    }
    getProductionServiceBulletins = () => {
        this.setState({isLoading: true}, () => {
            API.get('/researchDevelopment/productionServiceBulletin/all')
            .then(result => {
                if(result.data) {
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    });
                }
            });
        });
    }
    downloadBulletin = rowData => {
        if(rowData.psb_status === 'In Progress') {
            API.get(`/researchDevelopment/productionServiceBulletin/${rowData.psb_id}/pdf`)
            .then(result => {
                if(result.data) {
                    pdfFromBase64(result.data.pdf, result.data.pdfName);
                }
            });
        } else if(rowData.psb_status === 'Published'){
            downloadS3File(rowData.download_url)
        }
    }
    publishBulletin = psbId => {
        API.post(`/researchDevelopment/productionServiceBulletin/${psbId}/publish`)
        .then(result => {
            this.setState({...this.initialState, snackbarOpen: true, snackbarMessage: 'You have successfully published this bulletin'}, () => this.getProductionServiceBulletins());
        });
    }
    handleConfirmationOpen = rowData => this.setState({confirmationOpen: true, markedPsbId: rowData.psb_id});
    handleConfirmationClose = () => this.setState({confirmationOpen: false});
    handleConfirmationSuccess = () => this.setState({confirmationOpen: false}, () => this.publishBulletin(this.state.markedPsbId));
    handleSnackbarClose = () => this.setState({snackbarOpen: false});
    render() {
        return(
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Production & Service Bulletin Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                <Grid item xs={12}>
                        <PaddedPaper>
                            <CiDataTable 
                                config={{
                                    key:                    'psb_id',
                                    alternatingRowColours:  true,
                                    plainHeader:            false,
                                    pagination:             true,
                                    persistenceId:          this.persistenceId,
                                    isLoading:              this.state.isLoading,
                                    rowProps: row => {
                                        return ({
                                            style: {
                                                color: (row.psb_year === parseInt(moment().format('Y')) && row.psb_month === parseInt(moment().format('M'))) && '#20c997'
                                            }
                                        });
                                    }
                                }}
                                columns={[
                                    {
                                        heading: 'Year',
                                        field: rowData => rowData.psb_year
                                    },
                                    {
                                        heading: 'Month',
                                        field: rowData => moment(rowData.psb_month, 'M').format('MMMM')
                                    },
                                    {
                                        heading: 'Status',
                                        field: rowData => rowData.psb_status
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: 'Download', icon: 'download', disabled: rowData.psb_status === 'New' && true, onClick: this.downloadBulletin},
                                                {name: 'Update', icon: 'pencil-alt', disabled: rowData.psb_status === 'Published' && true, link: `/research-development/production-service-bulletin/update/${rowData.psb_id}`},
                                                {name: 'Publish', icon: 'check', disabled: (rowData.psb_status === 'Published' || rowData.psb_status === 'New') && true, onClick: this.handleConfirmationOpen}
                                            ];
                                        }
                                    }
                                ]}
                                rows={this.state.searchResults}
                            />
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Publish Bulletin?" 
                    message="Are you sure you want to publish this bulletin?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message={this.state.snackbarMessage}
                />
            </Grid>
        );
    }
}
const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductionServiceBulletinSearch);
