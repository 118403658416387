import React        from 'react';
import _            from 'lodash';
import API          from 'API';
import { Redirect } from 'react-router-dom';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControl, Grid, Input, InputLabel, Typography} from '@material-ui/core';
import MaterialLink   from '@material-ui/core/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import BackButton     from 'Components/Common/Buttons/BackButton';
import DataTable      from 'Components/Common/DataTables/DataTable';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle  from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper    from 'Components/Common/Paper/PaddedPaper';

import { downloadS3File } from 'Functions/MiscFunctions';

import { colors }                 from 'Helpers/ColourHelper';
import { convertFileUploadsName } from 'Helpers/DocumentHelper';

class ViewDocument extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            document: {
                category: '',
                subCategory: '',
                title: '',
                versionMatch: '',
                fileUploads: '',
                quickLinkOriginal: null,
                quickLinkPDF: null,
                quickLinkDUO: null,
                technicalLibraryAccess:''
            },
            originalFiles: {},
            pdfFiles: {},
            duoFiles: {},
            associatedParts: {},
            isLoading: true,
            redirect: false
        } 
    }

    componentDidMount(){
        this.getDocument(this.props.match.params.id);
    }

    componentDidUpdate(prevProps){
        if(this.props.match?.params?.id !== prevProps.match?.params?.id) {
            this.setState({
                isLoading: true
            }, () => this.getDocument(this.props.match.params.id))
        }
    }

    getDocument = (documentId) => {
        API.get(`/documents/${documentId}`)
        .then((result) => {
            if (result.data.errors) {
                this.setState({ redirect: true });
            } else if (result.data) {
                this.getDocumentFiles(this.props.match.params.id);
                this.getDocumentUpdateOrders(this.props.match.params.id);
                const fileUploads = convertFileUploadsName(result.data.doc_file_uploads);
                let actual_document_status  = result.data.latest_update_order && result.data.latest_update_order.duo_version > 1 ? result.data.latest_update_order.duo_version : 1;
                let current_status          = result.data.latest_file && result.data.latest_file.file_version > 1 ? result.data.latest_file.file_version : 1;
                this.setState({
                    document: {
                        ...this.state.document,
                        category: result.data.category,
                        subCategory: result.data.sub_category,
                        title: result.data.doc_title,
                        fileUploads: fileUploads,
                        qrCode: result.data.doc_qr_code,
                        actualDocStatus:actual_document_status,
                        currentStatus: current_status,
                        versionMatch: (actual_document_status === current_status) ? 'Yes' : 'No',
                        technicalLibraryAccess: result.data.doc_technical_library_access,
                    },
                    associatedParts: result.data.parts,
                    isLoading: false
                })
            }
        });
    };

    getDocumentFiles = (documentId) => {
        API.get(`/documents/${documentId}/files`)
        .then((result) => {

            let originalFiles = [];
            let pdfFiles = [];
            result.data.forEach((file) => {
                if(file.file_original){
                    originalFiles.push({
                        version: 'v' + file.file_version,
                        versionNumber: file.file_version,
                        file: file.file_original,
                        fileURL: file.file_original_url,
                        id: file.file_id
                    })
                }
                if(file.file_pdf){
                    pdfFiles.push({ version: 'v' + file.file_version,
                                    versionNumber: file.file_version,
                                    file: file.file_pdf,
                                    fileURL: file.file_pdf_url,
                                    id: file.file_id })
                }
            });
            originalFiles = _.orderBy(originalFiles, ['versionNumber'], ['desc']);
            pdfFiles =  _.orderBy(pdfFiles, ['versionNumber'], ['desc']);

            this.setState({
                originalFiles: originalFiles,
                pdfFiles: pdfFiles,
                document: {
                    ...this.state.document,
                    quickLinkOriginal: _.first(originalFiles),
                    quickLinkPDF: _.first(pdfFiles)
                }
            })
        });
    };

    getDocumentUpdateOrders = (documentId) => {
        API.get(`/documents/updateOrders/document/${documentId}`)
        .then((result) => {
            let duos = [];
            result.data.forEach((duo) => {
                let fromVersion = duo.duo_version-1;
                duos.push({ number: duo.duo_reference,
                            title: duo.duo_title,
                            version: `From v${fromVersion} to v${duo.duo_version}`,
                            versionNumber: duo.duo_version,
                            fileURL: duo.duo_file_url
                        })
                
            });
            duos = _.orderBy(duos, ['versionNumber'],['desc']);

            this.setState({
                duoFiles: duos,
                document: {
                    ...this.state.document,
                    quickLinkDUO: _.first(duos)
                }
            })
        })
    };

    displayFileTable(files, download){
        return(
            <DataTable  
                config={{
                    key: 'id',
                    pagination: false,
                    plainHeader: true
                }}
                columns={[
                    {
                        heading: 'Version',
                        field: rowData => rowData.version,
                        sizeToContent: true
                    },
                    {
                        heading: 'File',
                        field: rowData => rowData.file,
                        truncate: true
                    },
                    {
                        actions: rowData => {
                            return [
                                {name: 'Download', icon: 'download', onClick: () => {download(rowData.fileURL)}}
                            ]
                        }
                    }
                ]}
                rows={files}
            />
        )
    }

    render() {
        const { document, isLoading } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/documents/search" />
        }
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                <Typography variant="h5">
                        View Document
                    </Typography>
                </Grid>
                {(isLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                )) || (
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <Typography variant="h6">
                                        Document Details
                                    </Typography>
                                    <form noValidate autoComplete="off">
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="category" shrink={!!(document.category && document.category.parent.doc_cat_name)}>Category</InputLabel>
                                            <Input id="category" value={(document.category && document.category.parent.doc_cat_name) || ''} />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="subCategory" shrink={!!(document.category && document.category.doc_cat_name)}>Sub Category</InputLabel>
                                            <Input id="subCategory" value={(document.category && document.category.doc_cat_name) || ''} />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="title" shrink={!!document.title}>Title</InputLabel>
                                            <Input id="title" value={document.title || ''} />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="fileUploads" shrink={!!document.fileUploads}>File Uploads</InputLabel>
                                            <Input id="fileUploads" value={document.fileUploads || ''} />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="actualDocStatus" shrink={!!(document.actualDocStatus)}>Actual Doc Status (Based on DUO)</InputLabel>
                                            <Input id="actualDocStatus" value={(document.actualDocStatus && `v${document.actualDocStatus}`) || ''} />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="currentStatus" shrink={!!(document.currentStatus)}>Current Status of Docs</InputLabel>
                                            <Input id="currentStatus" value={(document.currentStatus && `v${document.currentStatus}`) || ''} />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="versionMatch" shrink={true}>Version Match (DUO &amp; Docs)</InputLabel>
                                            <Input id="versionMatch" value={document.versionMatch} style={{color: document.versionMatch === 'Yes' ? colors.green : colors.red}} />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="technicalLibraryAccess" shrink={true}>Available in Technical Library</InputLabel>
                                            <Input id="technicalLibraryAccess" value={document.technicalLibraryAccess} />
                                        </FormControl>
                                    </form>
                                        
                                    <div className='buttonRow'>
                                    <BackButton props={this.props} />
                                    </div>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={8}>
                                        <PaddedPaper>
                                            <Typography variant="h6" gutterBottom>
                                                Original Document
                                            </Typography>
                                            {document.quickLinkOriginal && document.quickLinkOriginal.file ?
                                                <MaterialLink component="div" variant="body1" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer'}} onClick={() => {downloadS3File(document.quickLinkOriginal && document.quickLinkOriginal.fileURL)}}>
                                                    {document.quickLinkOriginal.file}
                                                </MaterialLink>
                                                :
                                                '-'
                                            }
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={12} lg={8}>
                                        <PaddedPaper>
                                            <Typography variant="h6" gutterBottom>
                                                PDF Document
                                            </Typography>
                                            {document.quickLinkPDF && document.quickLinkPDF.file ?
                                                <MaterialLink component="div" variant="body1" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer'}} onClick={() => {downloadS3File(document.quickLinkPDF && document.quickLinkPDF.fileURL)}}>
                                                    {document.quickLinkPDF.file}
                                                </MaterialLink>
                                                :
                                                '-'
                                            }
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={12} lg={8}>
                                        <PaddedPaper>
                                            <Typography variant="h6" gutterBottom>
                                                Document Update Order
                                            </Typography>
                                            {document.quickLinkDUO && document.quickLinkDUO.number ?
                                                <MaterialLink component="div" variant="body1" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer'}} onClick={() => {downloadS3File(document.quickLinkDUO && document.quickLinkDUO.fileURL)}}>
                                                    {document.quickLinkDUO.number}
                                                </MaterialLink>
                                                :
                                                '-'
                                            }
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={12} lg={8}>
                                        <PaddedPaper>
                                            <Typography variant="h6" gutterBottom>
                                                QR Code
                                            </Typography>
                                            <ImageWithError type='QR' alt='QR Code' width="175" height="175" src={document.qrCode} />
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <ExpansionPanel className='xs-max-width'>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography> Original Files</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        {this.state.originalFiles && this.state.originalFiles.length > 0 ?
                                            this.displayFileTable(this.state.originalFiles, downloadS3File)
                                            :
                                            <Typography variant="body1">
                                                There are <b>0</b> Original Files attached to this document
                                            </Typography>
                                        }
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className='xs-max-width'>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>PDF Files</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        {this.state.pdfFiles ?
                                            this.displayFileTable(this.state.pdfFiles, downloadS3File)
                                            :
                                            <Typography variant="body1">
                                                There are <b>0</b> PDF Files attached to this document
                                            </Typography>
                                        }
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className='xs-max-width'>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Document Update Orders</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        {this.state.duoFiles && this.state.duoFiles.length > 0 ?
                                            <DataTable  
                                                config={{
                                                    key: 'id',
                                                    pagination: false,
                                                    plainHeader: true
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Number',
                                                        field: rowData => rowData.number
                                                    },
                                                    {
                                                        heading: 'Title',
                                                        field: rowData => rowData.title
                                                    },
                                                    {
                                                        heading: 'Version',
                                                        field: rowData => rowData.version
                                                    },
                                                    {
                                                        actions: rowData => {
                                                            return [
                                                                {name: 'Download', icon: 'download', onClick: () => {downloadS3File(rowData.fileURL)}}
                                                            ]
                                                        }
                                                    }
                                                ]}
                                                rows={this.state.duoFiles}
                                            />
                                            :
                                            <Typography variant="body1">
                                                There are <b>0</b> Document Update Orders attached to this document
                                            </Typography>
                                        }
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className='xs-max-width'>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Associated Parts</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        {this.state.associatedParts && this.state.associatedParts.length > 0 ?
                                            <DataTable  
                                                config={{
                                                    key: 'part_id',
                                                    pagination: false,
                                                    plainHeader: true,
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Part Number',
                                                        field: rowData => rowData.part_number,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Description',
                                                        field: rowData => rowData.part_description
                                                    }
                                                ]}
                                                rows={this.state.associatedParts}
                                            />
                                            :
                                            <Typography variant="body1">
                                                There are <b>0</b> associated parts for this document
                                            </Typography>
                                        }
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

export default ViewDocument;