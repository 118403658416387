import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import NotificationBadge from 'Components/Common/Badges/NotificationBadge';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

import { closePopNotifications } from 'Actions/PopNotification/PopNotification';
import { playSound } from 'Actions/Sounds/Sounds';

const AdminChat = ({toggleDrawer}) => {
    const dispatch = useDispatch();

    let [messageCount, setMessageCount] = useState(0);

    const badges = useSelector(state => state.notifications.badges)
    const count = badges['unread-messages'] ? badges['unread-messages'] : 0;

    useEffect(() => {
        if(count !== messageCount) {
            if (messageCount) dispatch(playSound('msg', messageCount));
            setMessageCount(count)
        }
    }, [count, dispatch, messageCount])

    if(badges) {
        return (
            <Tooltip title="Messages">
                <IconButton
                    onClick={() => {toggleDrawer('chat'); dispatch(closePopNotifications());}}
                >
                    {(badges?.['unread-messages'] && (
                        <NotificationBadge
                            badgeContent={count}
                            variant='standard'
                            color='secondary'
                            showZero={false}
                        >
                            <FAIcon type="light" icon="comment-alt-lines" noMargin buttonPrimary/>
                        </NotificationBadge>
                    )) || (
                        <FAIcon type="light" icon="comment-alt-lines" noMargin buttonPrimary/>
                    )}
                </IconButton>
            </Tooltip>
        )
    }
}

export default AdminChat;