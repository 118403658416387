import React from 'react';
import { connect } from 'react-redux';
import API from 'API';
import _ from 'lodash';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import ElectricalConnectionsDialog from './../ElectricalConnectionsDialog/ElectricalConnectionsDialog';
import ProccessDispachDialog from './ProccessDispachDialog';

const initialState = () => ({
    isLoading:      true,
    orderDetails:   [],
    dataRows:       [],
    orderId:        0,
    process:        false,
    Dialog:         'EC'
});

class DespatchDialog extends React.Component {
    constructor(props){
        super(props);
        this.state = {...initialState(), orderId: this.props.orderId ?  this.props.orderId : (this.props.match.orderId ? this.props.match.orderId : this.props.orderId)};
    }

    componentDidMount = () => {
        this.checkConnections();
    }

    checkConnections = () => {
        API.get(`/sales/orders/${this.state.orderId}`)
        .then(res =>{
            let ec = false;
            _.map(res.data.order_details, i => {
                //if (i.part.electrical_connections.length > 0 && !i.od_electrical_connection ) ec = true;
                if (i.part.electrical_connections.length > 0 ) ec = true;
            })
            this.setState({ 
                Dialog:     ec ? 'EC' : 'DD',
                isLoading:  false
            })
        });
    }

    ecCallback = (orderId) => {
        this.setState({
            orderId,
        }, () => {
            //test order is wrong version issue
            this.setState({ 
                Dialog: 'DD',
            })
        })
    }

    callback = () => {
        if (this.props.callback) this.props.callback();
        this.props.closeDialog();
    }

    render() {
        const {isLoading} = this.state;
        return( isLoading ? <LoadingCircle /> : ( this.state.Dialog === 'EC' ? 
            <ElectricalConnectionsDialog orderId={this.state.orderId} success={(id)=>{this.ecCallback(id)}} /> : 
            <ProccessDispachDialog orderId={this.state.orderId} callback={()=>{this.callback()}} paymentConfirmed={true}/>)
        )
    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog:        () => dispatch(closeDialog()),
    deployDialog:       (content, title, variant)   => dispatch(deployDialog(content, title, variant)),
})
export default connect(null, mapDispatchToProps)(DespatchDialog)