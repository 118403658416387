import React, { Component } from 'react'
import ReactPlayer          from 'react-player'

import AllIcon from 'Components/Common/Icons/AllIcon';

import { getMediaType } from 'Helpers/FileHelper';

class VideoWithError extends Component {
    
    validateVideo = () => {
        return (getMediaType(this.props.src) == 'video') ? true : false;
    }

    render () {
        if (!this.validateVideo()) return <AllIcon icon='video-slash' size='xxlarge' />;
        return (
          <div className='player-wrapper'>
            <ReactPlayer
              className='react-player fixed-bottom'
              url={this.props.src}
              width='100%'
              height='500px'
              controls = {true}
            />
          </div>
        )
      }
}

export default VideoWithError;