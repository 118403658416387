import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography } from '@material-ui/core/';

import AllIcon     from 'Components/Common/Icons/AllIcon';
import Textarea    from 'Components/Common/Inputs/Textarea';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import ProgressBar from 'Components/Common/ProgressBar/ProgressBar';

import { colors }    from 'Helpers/ColourHelper';
import icons         from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';

import { deploySnackBar }     from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = {
    formData: {
        show: false,
        note: '',
    },
    declineFormData: {
        show: false,
        note: '',
    },
    approveFormData: {
        show: false,
        note: '',
    },
}

const reviewOrder = {
    'Overview': 0,
    'Picks': 1,
    'Shortages': 2,
    'Write Offs': 3,
    'Additional Stock': 4,
    'Returned Stock': 5,
    'First Off Checks' : 6,
    'Build Time Sheet': 7,
    'Feedback': 8,
    'Notes': 9,
    'Activity Logs': 10,
    'Report': 11,
};

class ReviewProgress extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.lockWorkOrder();
    }

    componentWillUnmount(){
        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/review/unlock`)
    }

    lockWorkOrder = () => {
        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/review/lock`).then(this.handleCheckApproved);
    }
    
    handleCheckApproved = () => {
        const { worksOrder } = this.props;
        let nextTab = null;

        _.each(_.orderBy(worksOrder.review.stages, s => reviewOrder[s.wors_stage]), s => {
            if(!nextTab && !s.wors_approved) nextTab = s.wors_stage;
        })

        if (nextTab) this.props.toTab(nextTab);
    }

    completeReview = () => {
        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/review/unlock`).then(
            ()=>{
                API.post(`/worksOrders/${this.props.worksOrder.wo_id}/complete`)
                .then(this.props.onComplete);
            }
        )
    }

    addNote = () => {
        this.setState({
            formData: {
                ...initialState.formData,
                show: !this.state.formData.show
            }
        });
    }

    declineStage = () => {
        this.setState({
            declineFormData: {
                ...initialState.declineFormData,
                show: !this.state.declineFormData.show
            }
        });
    }

    handleChangeNote = (formData) => e => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                note: e.target.value
            }
        });
    }

    handleSaveNote = () => {
        const { formData } = this.state;
        const { worksOrder, tab } = this.props;

        let stage = _.find(worksOrder.review.stages, {wors_stage: tab});

        API.post(`/worksOrders/${worksOrder.wo_id}/review/${stage.wors_id}/addNote`, formData)
        .then(() => {
            this.setState({
                formData: {
                    ...initialState.formData
                }
            }, this.props.getWorksOrder);
        });
    }

    approveSection = section => () => {
        const { worksOrder, tab } = this.props;
        let stageIdx = _.findIndex(worksOrder.review.stages, {wors_stage: section}); 
        API.post(`/worksOrders/${worksOrder.wo_id}/review/${worksOrder.review.stages[stageIdx].wors_id}/approve`, this.state.approveFormData)
        .then(res => {
            this.setState({approveFormData: initialState.approveFormData}, () => {
                this.props.getWorksOrder();
                this.getNextStage(stageIdx);
                if (res.data.approved) this.props.deploySnackBar('error', 'This stage has already been approved');
            });
        })
    }

    getNextStage = (cidx = null) => {
        const { worksOrder } = this.props;

        let stage      = _.find(worksOrder.review.stages, {wors_stage: this.props.tab});
        let reviewStages = _.orderBy(worksOrder.review.stages, s => reviewOrder[s.wors_stage]);
        let idx = _.findIndex(reviewStages, {wors_id: stage.wors_id})

        stage = _.first(_.filter(reviewStages, (i, iidx) => !i.wors_approved && iidx > idx));
        if(stage) { this.props.toTab(stage.wors_stage); return;}
        stage = _.first(_.filter(reviewStages, (i, iidx) => !i.wors_approved && iidx !== idx));
        if(stage) { this.props.toTab(stage.wors_stage); return;}
        return this.props.toTab(_.first(reviewStages).wors_stage);
    }


    handleDeclineStage = () => {
        const { declineFormData } = this.state;
        const { worksOrder, tab } = this.props;

        let stage = _.find(worksOrder.review.stages, {wors_stage: tab});

        API.post(`/worksOrders/${worksOrder.wo_id}/review/${stage.wors_id}/decline`, declineFormData)
        .then(res => {
            this.setState({
                declineFormData: {
                    ...initialState.declineFormData
                }
            }, () => {
                let stageIdx = _.findIndex(worksOrder.review.stages, {wors_id: stage.wors_id}); 
                this.props.getWorksOrder();
                this.getNextStage(stageIdx);
                if (res.data.approved) this.props.deploySnackBar('error', 'This stage has already been approved');
            });
        });
    }

    close = () => {
        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/review/unlock`).then(this.props.close);
    }

    render(){

        const { worksOrder, tab, access }   = this.props;
        const { formData, declineFormData, approveFormData } = this.state;

        if (tab === 'Review') { this.getNextStage(); return (<></>) }

        let stage      = _.find(worksOrder.review.stages, {wors_stage: tab});

        let stages = {};
        let notes = [];
        let order = [ 'Overview', 'Picks', 'Shortages', 'Write Offs', 'Additional Stock', 'Returned Stock', 'First Off Checks', 'Build Time Sheet', 'Feedback', 'Notes', 'Activity Logs', 'Report' ];
        _.each(_.orderBy(worksOrder.review.stages, s => order.indexOf(s.wors_stage), 'asc'), s => { 
            stages[s.wors_stage] = s.wors_approved ? true : (s.declined.length > 0 ? false : null); 
            if (s.notes.length > 0 ) notes = [...notes, ...s.notes];
        })
        
        const sections = Object.keys(stages);

        return (
            <Grid container>            
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Typography variant="h5" gutterBottom>Review</Typography>
                        <ProgressBar 
                            showProgressLabel 
                            progressType='percentage' 
                            max={sections.length} 
                            progress={_.sumBy(sections, i => stages[i] ? 1 : 0)} 
                            bufferValue={!stages[tab] ? ( 100 / sections.length ) : 0}
                            variant='buffer'
                            colorPack='RGO'
                        />
                        <Grid container style={{alignItems: 'center'}}>
                            <Grid item>
                                <Grid container style={{alignItems: 'center'}}>
                                    {sections.map(section => 
                                        <Grid item container xs={12} style={{alignItems: 'center', cursor: 'pointer'}}>
                                            <Grid item>
                                                <AllIcon icon={stages[section] === null ? icons.dash : (stages[section] ? icons.true : icons.false)} color={stages[section] ? colors.green : ( section === tab ? colors.orange : colors.red)} size='small' /> 
                                            </Grid>
                                            <Grid item style={{color: stages[section] ? colors.green : ( section === tab ? colors.orange : colors.red) }} onClick={()=>this.props.toTab(section)}>
                                                {section}
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                {!stages[tab] &&
                    [
                        <Grid item xs={12} style={{marginTop: '1em'}}>
                            <Button 
                                onClick={()=>this.setState({approveFormData: {...initialState.approveFormData, show: 1}})}
                                fullWidth color='secondary' variant='contained' style={{backgroundColor: colors.green}}>Approve Stage</Button>
                        </Grid>,
                        <Grid item xs={12} style={{marginTop: '1em'}}>
                            <Button onClick={this.declineStage} fullWidth color='secondary' variant='contained'>Decline Stage</Button>
                        </Grid>
                    ]
                }
                {( (_.filter(worksOrder.stages, {wors_approved: 1}).length === worksOrder?.stages?.length) || access['works-orders-review:check-all'] ) && 
                    <Grid item xs={12} style={{marginTop: '1em'}}>
                        <Button onClick={()=>this.props.deployConfirmation('Are you sure you want to complete this review? Note: This will complete the Works Order and change stock of all parts involved.', 'Complete Review & Works Order', this.completeReview)} fullWidth variant='contained' color='primary'>{access['works-orders-review:check-all'] && !!_.filter(worksOrder.stages, {wors_approved: 0}).length ? 'Approve All' : 'Complete Review' }</Button>
                    </Grid>
                }
                <Grid item xs={12} style={{marginTop: '1em'}}>
                    <Button onClick={this.addNote} fullWidth variant='contained'>{notes.length > 0 ? 'Review Notes' : 'Add Review Note' }</Button>
                </Grid>
                <Grid item xs={12} style={{marginTop: '1em'}}>
                    <Button 
                        fullWidth 
                        variant='outlined'
                        onClick={this.close}
                    >Save & Close</Button>
                </Grid>
                <Dialog 
                    open={formData.show} 
                    fullWidth={true}
                    maxWidth="md" 
                    scroll="body"
                >
                    <DialogTitle>
                        <Typography>Review Notes</Typography>
                    </DialogTitle>
                    <DialogContent>
                        {notes.length > 0 &&
                            <ExpansionPanel styles={{width:'100%'}}>
                                <ExpansionPanelSummary expandIcon={<AllIcon noMargin icon={icons.expand} color={colors.white} />}>
                                    <Typography>Notes</Typography> 
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={1}>
                                        {_.map(notes, note =>
                                            <Grid item xs={12} style={{borderBottom: `1px solid ${colors.disabled}`, paddingTop: '1em', paddingBottom: '1em'}}>
                                                <Typography variant='body2' style={{color: colors.disabled}}>{note.worsn_by} -  {clenyDate(note.worsn_datetime)}</Typography>
                                                <Typography variant='body1' style={{paddingTop: '0.5em'}}>{note.worsn_note}</Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        }
                        <Textarea
                            margin='none'
                            label='Notes'
                            value={formData.note}
                            onChange={this.handleChangeNote('formData')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>this.setState({formData: initialState.formData})} variant="outlined" color="default">Close</Button>
                        <Button onClick={this.handleSaveNote} autoFocus variant="contained" color="primary" disabled={!formData.note}>Add</Button>
                    </DialogActions>
                </Dialog>
                <Dialog 
                    open={declineFormData.show} 
                    fullWidth={true}
                    maxWidth="md" 
                    scroll="body"
                >
                    <DialogTitle>
                        <Typography>Decline Stage</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Textarea
                            margin='none'
                            label='Reason *'
                            value={declineFormData.note}
                            onChange={this.handleChangeNote('declineFormData')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>this.setState({declineFormData: initialState.declineFormData})} variant="outlined" color="default">Close</Button>
                        <Button onClick={this.handleDeclineStage} autoFocus variant="contained" color="secondary" disabled={!declineFormData.note}>Decline</Button>
                    </DialogActions>
                </Dialog>
                <Dialog 
                    open={approveFormData.show} 
                    fullWidth={true}
                    maxWidth="md" 
                    scroll="body"
                >
                    <DialogTitle>
                        <Typography>Approve Stage</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Textarea
                            margin='none'
                            label='Notes'
                            value={approveFormData.note}
                            onChange={this.handleChangeNote('approveFormData')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>this.setState({approveFormData: initialState.approveFormData})} variant="outlined" color="default">Close</Button>
                        <Button onClick={()=>this.props.deployConfirmation('Are you sure you want to approve this stage?', 'Approve Stage',this.approveSection(tab))} autoFocus variant="contained" color="primary" >Approve</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)            => dispatch(deploySnackBar(variant, msg)),
});

export default connect(null, mapDispatchToProps)(ReviewProgress);