import { restockFeeMultiplier } from 'Constants';
import _ from 'lodash';
import { all, create } from 'mathjs';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, FormControl, InputAdornment, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import logo from 'Assets/Images/clenaware_logo_icon.png';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AddPartEntryRowDialog from './AddPartEntryRowDialog';

import { colors } from 'Helpers/ColourHelper';

const math = create(all, {});

const styles = () => ({
    td : {
        border: 'none'
    }
});

const initialState = () => ({
    formErrors:         [],
    dialogFormErrors:   null,
    partList:           [],
    selectedPartRow:    {},
    formData: {
        part:               null,
        quantity:           0,
        unitPrice:          0,
        totalPrice:         0,
        discountRate:       0,
        newUnitPrice:       0,
        discountTotal:      0,
        restockFee:         false,
        restockFeePrice:    0,
        cnNote:             null,
        serialNumbers:      [],
    },
});

class AddPartEntryRow extends Component {
    constructor(props) {
        super(props);

        this.state = initialState();
    }

    componentDidMount() {
        let partList = _.map(this.props.partList, (el) => {
            return _.assign({
                value: el.part_id,
                label: el.part_number + ' - ' + el.part_description
            });
        });
        let discountRate = this.props.discountRate;
        this.setState({partList,formData:{...this.state.formData,discountRate}})
    }
    
    handlePartSelect = e => { 
        let part            = e ? e.value : null;
        let selectedPartRow = e ? _.find(this.props.partList, {part_id: part}) : null;
        let unitPrice       = e ? selectedPartRow.price : 0;

        this.setState({
            formData: {
                ...this.state.formData,
                part,
                unitPrice,
                quantity: e ? 1 : 0
            },
            selectedPartRow
        },this.calculateTotals);
    }

    handleInputChange = e => {
        const {name,value} = e.target;
        this.setState({
            formData:{
                ...this.state.formData,
                [name]: value
            }
        },this.calculateTotals);
    }
    
    calculateTotals = () => {
        let newUnitPrice    = 0;
        let discountTotal   = 0;
        let totalPrice      = 0;
        let restockFeePrice = 0;
        let subTotal        = 0;
        const unitPrice     = this.state.formData.unitPrice;
        const discount      = this.state.formData.discountRate
        if ( unitPrice > 0 ) {  
            if (this.props.discount == 'With Discount'){
                discountTotal   = (unitPrice * (discount / 100));
                newUnitPrice    = unitPrice - discountTotal;
                totalPrice      = newUnitPrice *  this.state.formData.quantity;
            } else {
                totalPrice      = unitPrice *  this.state.formData.quantity;
            }
            subTotal            = totalPrice;
            if (this.state.formData.restockFee){
                restockFeePrice = totalPrice * restockFeeMultiplier;
                totalPrice      = totalPrice - restockFeePrice;
            }
            
        }
        this.setState({
            formData: {
                ...this.state.formData,
                newUnitPrice:       parseFloat(math.round(newUnitPrice,2)).toFixed(2),
                discountTotal:      parseFloat(math.round(discountTotal,2)).toFixed(2),
                totalPrice:         parseFloat(math.round(totalPrice,2)).toFixed(2),
                subTotal:           parseFloat(math.round(subTotal, 2)).toFixed(2),
                restockFeePrice:    parseFloat(math.round(restockFeePrice,2)).toFixed(2),
            }
        })
    }

    handleAutoCompleteSelectChange = f => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [f]: e.value
            }
        },this.calculateTotals);
    }

    submit = (cnNote, serialNumbers, warrantyVoidLabels) => {
        const ret           = this.state.selectedPartRow;
        this.setState({
            formData: {
                ...this.state.formData,
                serialNumbers,
                cnNote,
                warrantyVoidLabels
            }
        }, () => {
            this.props.closeDialog();
            _.map(this.state.formData, (value, key) => {if ( key !== 'part' ) ret[key] = value;})
            this.props.success(ret);
            this.setState({selectedPartRow: {},formData:{...initialState().formData, discountRate: this.props.discountRate}});
        });
    }

    render() {
        const { formErrors, partList, formData, selectedPartRow, dialogFormErrors } = this.state;
        const { classes, discount, usedSerialNumbers } = this.props;
        return(
            <>
            {partList.length > 0 &&
                <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
                    <Typography variant="h6">
                        Add Part
                    </Typography>
                    <Table ref={this.menuSizeRef}>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.td} style={{textAlign:'center', height:65, width:65}}>
                                    <img src={selectedPartRow && selectedPartRow.thumbnail_url ? selectedPartRow.thumbnail_url : logo} style={{maxHeight:65, maxWidth:'80%', verticalAlign:'middle'}} alt="" />
                                </TableCell>
                                <TableCell className={classes.td} style={{width:'20%', maxWidth:250}}>
                                    <FormControl fullWidth>
                                        <AutoCompleteSelect 
                                            label='Part'
                                            options={partList} 
                                            value={formData.part}
                                            onChange={this.handlePartSelect}
                                            error={this.props.formErrors && this.props.formErrors['partRows'] && true}
                                            errorText={this.props.formErrors && this.props.formErrors['partRows']}
                                            autoFocus={false}
                                        />
                                    </FormControl>
                                </TableCell>
                                <TableCell className={classes.td} style={{minWidth:'88px'}}>
                                    <TextField
                                        name="quantity"
                                        label="Quantity"
                                        value={formData.quantity}
                                        error={(formErrors && formErrors['qty'] && true)}
                                        helperText={(formErrors && formErrors['qty'])}
                                        onChange={this.handleInputChange}
                                        type="number"
                                        size="small"
                                        fullWidth
                                        inputProps={{
                                            min: 1
                                        }}
                                    />
                                </TableCell>
                                <TableCell className={classes.td} style={{minWidth:'125px'}}>
                                    <TextField
                                        name="unitPrice"
                                        label="Unit Price"
                                        value={formData.unitPrice}
                                        error={formErrors && formErrors['unitPrice'] && true}
                                        helperText={formErrors && formErrors['unitPrice']}
                                        onChange={this.handleInputChange}
                                        type="number"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                            inputProps: {
                                                min: 0
                                            }
                                        }}
                                    />
                                </TableCell>
                                {discount == 'With Discount'  &&
                                    <>
                                        <TableCell className={classes.td} style={{minWidth:'120px'}}>
                                            <TextField
                                                name="discountRate"
                                                label="Discount Rate"
                                                value={formData.discountRate}
                                                error={formErrors && formErrors['discountRate'] && true}
                                                helperText={formErrors && formErrors['discountRate']}
                                                onChange={this.handleInputChange}
                                                type="number"
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                                                    inputProps: {
                                                        min: 0,
                                                        max: 100
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                        
                                        <TableCell className={classes.td} style={{minWidth:'125px'}}>
                                            <TextField
                                                name="newUnitPrice"
                                                label={'New Unit Price'}
                                                value={formData.newUnitPrice}
                                                onChange={this.handleInputChange}
                                                type="number"
                                                fullWidth
                                                disabled
                                                InputProps={{
                                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{minWidth:'125px'}}>
                                            <TextField
                                                name="discountTotal"
                                                label="Discount Total"
                                                value={formData.discountTotal}
                                                onChange={this.handleInputChange}
                                                type="number"
                                                fullWidth
                                                disabled
                                                InputProps={{
                                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                }}
                                            />
                                        </TableCell>
                                       
                                    </>
                                }
                                <TableCell className={classes.td} style={{minWidth:'125px'}}>
                                    <FormControl fullWidth>
                                        <AutoCompleteSelect 
                                            label='Restock Fee?'
                                            options={[
                                                {value: true,  label: 'Yes'},
                                                {value: false, label: 'No'},
                                            ]} 
                                            value={formData.restockFee}
                                            onChange={this.handleAutoCompleteSelectChange('restockFee')}
                                            error={this.props.formErrors && this.props.formErrors['restockFee'] && true}
                                            errorText={this.props.formErrors && this.props.formErrors['restockFee']}
                                            noClear={true}
                                            autoFocus={false}
                                        />
                                    </FormControl>
                                </TableCell>
                                {formData.restockFee &&
                                <TableCell className={classes.td} style={{minWidth:'125px'}}>
                                    <TextField
                                        name="restockFeePrice"
                                        label="Restock Fee"
                                        value={formData.restockFeePrice}
                                        onChange={this.handleInputChange}
                                        type="number"
                                        fullWidth
                                        disabled
                                        style={{color:colors.red}}
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                        }}
                                    />
                                </TableCell>}
                                <TableCell className={classes.td} style={{minWidth:'125px'}}>
                                    <TextField
                                        name="totalPrice"
                                        label="Total Credit"
                                        value={formData.totalPrice}
                                        onChange={this.handleInputChange}
                                        type="number"
                                        fullWidth
                                        disabled
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                        }}
                                        style={{color:colors.red}}
                                    />
                                </TableCell>
                                <TableCell className={classes.td} style={{width: 88, float:'right'}}>
                                    <Button 
                                        variant="outlined"
                                        color="primary"
                                        type="submit"
                                        onClick={(e)=>{e.preventDefault(); this.props.deployDialog(<AddPartEntryRowDialog quantity={formData.quantity} usedSerialNumbers={usedSerialNumbers} warrantyVoidLabels={this.props.warrantyVoidLabels} part={selectedPartRow} callBack={this.submit}/>,'Add Credit Note Part', null)}}
                                        disabled={formData.Quantity == 0 || !formData.part}
                                    >
                                        Add
                                    </Button>
                                </TableCell>
                            </TableRow>   
                        </TableBody>                           
                    </Table>
                    <br></br>
                </form>}
            </>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    deployDialog: (content, title, variant, size='md')  => {dispatch(deployDialog(content, title, variant, size))},
    closeDialog:  ()                                    => {dispatch(closeDialog())}
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(AddPartEntryRow));