import API                  from 'API';
import _                    from 'lodash';
import moment               from 'moment';
import React, { Component } from 'react';
import { connect }          from 'react-redux';
import { Link }             from 'react-router-dom';

import 'Assets/timeLine.css';

import { AppBar, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, Tab, Table, 
    TableBody, TableCell, TableFooter, TableHead, TableRow, Tabs, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import ActivityLog                       from 'Components/Common/ActivityLog/ActivityLog';
import BackButton                        from 'Components/Common/Buttons/BackButton';
import DataTable                         from 'Components/Common/DataTables/CiDataTable';
import ConfirmationDialog                from 'Components/Common/Dialogs/ConfirmationDialog';
import AllIcon                           from 'Components/Common/Icons/AllIcon';
import FALightIcon                       from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon                       from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import Textarea                          from 'Components/Common/Inputs/Textarea';
import LoadingCircle                     from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper                       from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect                from 'Components/Common/Selects/AutoCompleteSelect';
import SnackBar                          from 'Components/Common/SnackBars/SnackBar';
import SelectCustomerAddressFormSwitcher from 'Components/Customers/SelectCustomerAddressForm/SelectCustomerAddressFormSwitcher';
import OrderDownloadPrevDocsDialog       from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog          from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';

import NoteBlock from '../../NoteBlock';

import { oriActivityLog, statusColour } from 'Helpers/ColourHelper';
import { formatValidationErrors }       from 'Helpers/ErrorHelper';
import { orderStatusIcons }             from 'Helpers/SalesHelper';
import { colors }                       from 'Helpers/ColourHelper';

import logo from 'Assets/Images/clenaware_logo_icon.png';

import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';
import { deployDialog, closeDialog }                     from 'Actions/Dialog/Dialog';

import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';


const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    btnRow: {
        textAlign: 'center', 
    },
    btnRowBtn: {   
        width: '100%',
    },
    link: {
        textDecoration: 'none',
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        }
    },
    statusHeading: {
        display: 'flex',
        alignItems: 'center'
    }
});

const initialState = () => ({
    currentTab:         0,
    invoiceId:          null,
    isLoading:          true,
    formErrors:         [],
    invoice:            {},
    editMode:           false,  
    formData: {
        notesText:  '',
        type:       'recurring-invoice-notes',
        id:         0
    },
    editFromData:{
        delivery: {
            edited:     false,
            type:       null,
            address:    null,
            contact:    null
        },
        invoice: {
            edited:     false,
            type:       null,
            address:    null,
            contact:    null
        },
        secondaryContacts: [],
        secondContact:     0,
        contactsEdited:    false
    },
    access: {
        delete: false,
        amend:  false
    },
    confirmNoteDelete:  0,
    dialogNotesOpen:    false, 
    deleteOpen:         false,
    contactsList:       false,
    importantNoteShown: false,
});

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

class RecurringInvoice extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `recuringInvoice:View:${this.props.recurringInvoiceId ? this.props.recurringInvoiceId : this.props.match.params.recurringInvoiceId}`;
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        let invoiceId = this.props.recurringInvoiceId ? this.props.recurringInvoiceId : this.props.match.params.recurringInvoiceId;
        this.setState({
            invoiceId,
            formData: {...this.state.formData, id: invoiceId},
            editMode: this.props.edit,
        }, ()=> {
            this.getInvoice();
            this.getAccess();
            if (this.props.edit) this.handleTabChange(null, 0);
        })
    }

    getContacts = () => {
        API.get(`/customers/${this.state.invoice.ori_cust_id}/contacts`)
        .then(res => {
            if (res.data){
                let contactsList = [];
                _.map(res.data, i => {
                    if (i.contact_email && !_.find(this.state.editFromData.secondaryContacts, j => i.contact_id == j.id )) 
                        contactsList.push({
                            value: i.contact_id,
                            label: `${i.contact_name} | ${i.contact_email}`,
                            name:  i.contact_name,
                            email: i.contact_email,
                            id:    i.contact_id
                        });
                });
                this.setState({contactsList}, this.getCustomer);
            }
        })
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/delete-recurring-invoice'),
            API.get('/staff/my/access/check/amend-recurring-invoice'),
        ])
        .then(([deleteRes, amendRes]) => {
            this.setState({
                access: {
                    delete: (deleteRes?.data?.has_access)    || false,
                    amend:  (amendRes?.data?.has_access)     || false
                }
            });
        });
    }

    getInvoice = () => {
        API.get(`/sales/orders/invoice/recurring/${this.state.invoiceId}`)
        .then(result => {
            if(result.data) {
                let secondaryContacts = [];
                if (result.data.secondary_contacts.length){
                    _.map(result.data.secondary_contacts, i => {
                        secondaryContacts.push({
                            value: i.oricce_contact_id,
                            label: `${i.oricce_contact_name} | ${i.oricce_contact_email}`,
                            name:  i.oricce_contact_name,
                            email: i.oricce_contact_email,
                            id:    i.oricce_contact_id,
                            rid:   i.oricce_id

                        })
                    });
                }
                _.map(result.data.activity_log, (log) => {
                    let data    = oriActivityLog(log.orial_type);
                    log.color   = data['color'];
                    log.icon    = data['icon'];
                    log.time    = moment(log.orial_datetime).format("DD/MM/YYYY");
                });
                _.map(result.data.recurred_orders, o => {
                    // Status Colour
                    o.color = statusColour(o.order.order_status);
                    o.icon = orderStatusIcons(o.order.order_status);

                    switch(o.order.order_status){
                        case 'Invoice':
                            o.toolTip1 = 'Awaiting Payment';
                        break;
                        case 'Completed':
                            o.toolTip1 = 'Order Completed';
                            o.toolTip2 = moment(o.d).format('DD/MM/YYYY');
                        break;
                        case 'Declined':
                            o.toolTip1 = 'Order Declined';
                            o.toolTip2 = moment(o.d).format('DD/MM/YYYY');
                        break;
                        case 'Cancelled':
                            o.toolTip1 = 'Order Cancelled';
                            o.toolTip2 = moment(o.d).format('DD/MM/YYYY');
                        break;
                        case 'Awaiting Invoice Completion':
                            o.toolTip1 = 'Awaiting Invoice Completion';
                        default:
                        break;
                    }
                });

                this.setState({
                    invoice:    result.data,
                    editFromData: {
                        ...this.state.editFromData,
                        secondaryContacts   
                    },
                    isLoading: 0
                }, () =>  {this.getContacts();})
            }
            else {
                window.location.href = `/sales/order/invoice`;
            }
        });
    }

    trimPartDescription = partDescription => {
        if(partDescription) {
            if(partDescription.length > 50){
                partDescription = partDescription.substring(0, 50) + '...';
            }
        } else {
            partDescription = '';
        }
        return partDescription;
    }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        }, ()=>this.savePageState());
    };

     // NOTES
     handleNotesOpen = () => {this.setState({dialogNotesOpen: true});};
     handleNotesClose = () => {this.setState({dialogNotesOpen: false});};
     handleNotesSuccess = () => {
         this.setState({ dialogNotesOpen: false });
         this.notesSubmit();
     }
     handleNotesSnackbarClose = () => {this.setState({ snackbarNotesOpen: false }); };
     notesSubmit = () => {
         API.post(`/misc/notes`, {...this.state.formData, notes: this.state.formData.notesText})
         .then((result) => {
             if(result.data.errors && result.data.errors.length > 0){           
                 this.setState({
                     formErrors: formatValidationErrors(result.data.errors),
                     dialogNotesOpen: true
                 });
             }
             else {
                 this.setState({
                     snackbarNotesOpen   : true,
                     formData            : initialState().formData
                 }, () => {
                     this.getInvoice();
                 });
             }
         });
     }
     handleDeleteNote = (id) => {
         this.setState({
             confirmNoteDelete: id
         });
     }
     handleCloseDeleteNote = () => {
         this.setState({
             confirmNoteDelete: 0
         });
     }
     deleteNote = () => {
         API.post(`/misc/notes/${this.state.confirmNoteDelete}/delete`, {type: "recurring-invoice-notes"})
             .then(result => {
                 if (!result.data.error) this.getInvoice()
                 this.setState({
                     confirmNoteDelete: 0
                 });
             })
         this.handleCloseDeleteNote();
     }
     handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleDownloadOpen  = orderId => this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents',  '', 'md');
    handleEmailOpen     = orderId => this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />,    'Email Previous Documents',     '', 'md');

    handleEditAddress = type => {
        this.setState({
            editFromData: {
                ...this.state.editFromData,
                [type]: {
                    ...this.state.editFromData[type],
                    edited: !this.state.editFromData[type].edited,
                }
            }
        })
    }

    handleAddressChange = type => e => {
        this.setState({
            editFromData: {
                ...this.state.editFromData,
                [type]: {
                    ...this.state.editFromData[type],
                    ...e,
                    edited: true
                }
            }
        })
    }

    handleUpdate = () => {
        let formData            = {};
        let invoice             = this.state.editFromData.invoice;
        let delivery            = this.state.editFromData.delivery;
        let secondaryContacts   = this.state.editFromData.secondaryContacts;
        if (invoice.edited){
            formData = {
                invoiceChange   : true,
                invoiceType     : invoice.type,
                invoiceAddr     : invoice.address,
                invoiceCont     : invoice.contact
            }
        }
        if (delivery.edited){
            formData = {
                ...formData,
                deliveryChange  : true,
                deliveryType    : delivery.type,
                deliveryAddr    : delivery.address,
                deliveryCont    : delivery.contact
            }
        }
        if ( this.state.editFromData.contactsEdited){
            formData = {...formData, secondaryContacts}
        }
        API.post(`/sales/orders/invoice/recurring/${this.state.invoiceId}/amend`, formData)
        .then((result) => {
            if (result.data.errors){
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                })
            }
            if (result.data.success){
                this.props.history.push(`/sales/order/invoice/recurring/${result.data.success}`);
                window.location.reload();
            }
        });
    }

    handleDelete = () => {
        API.post(`/sales/orders/invoice/recurring/${this.state.invoiceId}/delete`)
        .then((res) => {
            if (res.data.success) this.getInvoice();
            this.handleDeleteConfirm();
        })
    }

    handleDeleteConfirm = () => {
        this.setState({
            deleteOpen: !this.state.deleteOpen
        })
    }

    handleContactChange = (e) => {
        this.setState({
            editFromData: {
                ...this.state.editFromData,
                secondContact: e.value
            }
        });
    }

    handleContactAdd = () => {
        API.post(`/sales/orders/invoice/recurring/${this.state.invoiceId}/addContact`, {cid:this.state.editFromData.secondContact})
        .then( res => {
            if (res.data.success){
                this.setState({
                    editFromData: {
                        ...this.state.editFromData,
                        secondContact: 0
                    },
                    isLoading: 1
                }, ()=> { 
                    this.getInvoice();
                });
            }
        });
    }

    handleRemoveSeconderyContacts = i => {
        API.post(`/sales/orders/invoice/recurring/${this.state.invoiceId}/deleteContact`, {ricid:i})
        .then( res => {
            if (res.data.success){
                this.setState({
                    isLoading: 1
                }, ()=>{
                    this.getInvoice()
                });
            }
        });
    }

    getCustomer = () => {
        API.get(`/customers/${this.state.invoice.ori_cust_id}`)
        .then(custRes => {
            this.setState({
                customer: custRes.data
            }, ()=> {
                if (!this.state.importantNoteShown && custRes.data.cust_important_info) this.displayImportantInfo();
            });
        });
    }

    displayImportantInfo = () => {
        this.setState({importantNoteShown: true}, () => {
            this.props.deployDialog(
            <>
                <Typography component="p">
                    {this.state.customer?.cust_important_info && this.state.customer?.cust_important_info.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
                </Typography>
                <Button variant='outlined' style={{float: 'right'}} onClick={this.props.closeDialog}>Close</Button>
            </>
            , 'Important Information', null, 'sm', {backgroundColor : '#fcc6c5', color: '#000'})
        });
    }

    render() {
        const { classes } = this.props;
        const { currentTab, isLoading, formErrors, ori, invoice, editMode, editFromData, access, customer } = this.state;
        const customerPhone = invoice.ori_cust_contact_phone && invoice.ori_cust_contact_mobile ? invoice.ori_cust_contact_phone + ' / ' + invoice.ori_cust_contact_mobile : (invoice.ori_cust_contact_phone ? invoice.ori_cust_contact_phone : invoice.ori_cust_contact_mobile);
        const deliveryPhone = invoice.ori_delivery_cust_contact_phone && invoice.ori_delivery_cust_contact_mobile ? invoice.ori_delivery_cust_contact_phone + ' / ' + invoice.ori_delivery_cust_contact_mobile : (invoice.ori_delivery_cust_contact_phone ? invoice.ori_delivery_cust_contact_phone : invoice.ori_delivery_cust_contact_mobile);
        
        return (            
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {editMode && 'Amend ' }Recurring Invoice
                    </Typography>
                </Grid>
                { isLoading ? <LoadingCircle /> :
                    <>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={9}>
                                <PaddedPaper>
                                    <Typography variant="h4" align="center" className={classes.heading} style={{marginBottom: 25}}>
                                        {`${invoice.ori_reference}-0${invoice.ori_reference_unique_number}`}
                                    </Typography>
                                    <br className='xs-only' /> 
                                    {!!invoice.ori_latest &&
                                        <AppBar position="static" style={{backgroundColor:'white', margin: '25px 0'}} >
                                            {!editMode && 
                                                <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary" variant="scrollable">
                                                    <Tab label="Invoice" />
                                                    <Tab label={`Orders (${invoice.recurred_orders.length})`} />
                                                    <Tab label={`Notes (${invoice.notes.length})`} />
                                                    <Tab label="Activity Log" />
                                                    <Tab label="Revisions" />
                                                    <Tab label="Additional Contacts" />
                                                </Tabs>
                                            }   
                                        </AppBar>
                                    }
                                    {currentTab === 0 && (
                                        <TabContainer>
                                            {customer && !this.state.isLoading && customer?.account_status?.cas_name != 'Active - Verified' &&
                                                <Grid item xs={12} style={{padding:'1em', color: colors.white, backgroundColor: colors.red, margin:0, marginBottom: '1em', marginTop: '-25px'}}>
                                                    <Typography style={{fontWeight:'bold'}}>{customer?.cust_name} is {customer?.account_status?.cas_name }</Typography>
                                                </Grid>
                                            }
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} >
                                                    <PaddedPaper>
                                                        <Grid container item spacing={1}>
                                                            <Grid item xs={5}>
                                                                <Grid container item spacing={1}>
                                                                    <Grid item xs={4}>
                                                                        <Typography variant="body1" gutterBottom>
                                                                            <strong>Created By</strong>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        <Typography variant="body1" gutterBottom>
                                                                            {invoice.ori_staff_name}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={2}></Grid>
                                                            <Grid item xs={5}>
                                                                <Grid container item spacing={1}>
                                                                    <Grid item xs={4}>
                                                                        <Typography variant="body1" gutterBottom>
                                                                            <strong>Payment Terms</strong>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        <Typography variant="body1" gutterBottom>
                                                                            {invoice.ori_payment_terms}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </PaddedPaper>
                                                        <br></br>
                                                        <Grid container item spacing={1}>
                                                            <Grid item xs={6}>
                                                                <Grid container item spacing={1} xs={12}>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper fullWidth xs={12}>
                                                                            {!editMode &&
                                                                                <Grid item xs={12}>
                                                                                    <Typography variant="body">
                                                                                        <Typography variant="h6">Invoice Address</Typography>
                                                                                    </Typography>
                                                                                    <br></br>
                                                                                </Grid>
                                                                            }
                                                                            <Grid container item xs={12} >
                                                                                {editMode ? 
                                                                                    <Grid xs={12}>
                                                                                        <SelectCustomerAddressFormSwitcher
                                                                                            customerId= {invoice.ori_cust_id}
                                                                                            callback=   {this.handleAddressChange('invoice')}
                                                                                            name=       'customerAddress'
                                                                                            header=     'Invoice Address'
                                                                                            formErrors= {formErrors}
                                                                                            cname=      'Company'
                                                                                            addressName={invoice.ori_invoice_cust_address_name}
                                                                                            address=    {invoice.ori_invoice_cust_address}
                                                                                            contact=    {invoice.ori_invoice_cust_contact_name}
                                                                                            email=      {invoice.ori_invoice_cust_contact_email}
                                                                                            phone=      {invoice.ori_cust_contact_phone}
                                                                                            mobile=     {invoice.ori_cust_contact_mobile}
                                                                                        />
                                                                                    </Grid> :
                                                                                    <>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                <strong>Name</strong>
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={8}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                {invoice.ori_invoice_cust_address_name}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                <strong>Address</strong>
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={8}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                {invoice.ori_invoice_cust_address.split('\n').map((item, key) => {
                                                                                                    return  <span key={key}>
                                                                                                                {item}{invoice.ori_invoice_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                                                                            </span>
                                                                                                })}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                <strong>Contact</strong>
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={8}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                {invoice.ori_invoice_cust_contact_name}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                <strong>Phone Number</strong>
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={8}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                {deliveryPhone}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                <strong>Email</strong>
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={8}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                {invoice.ori_invoice_cust_contact_email}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </>
                                                                                }
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Grid container item spacing={1} xs={12}>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper fullWidth xs={12}>
                                                                            {!editMode &&
                                                                                <Grid item xs={12} >
                                                                                    <Typography variant="body">
                                                                                        <Typography variant="h6">Delivery Address</Typography>
                                                                                    </Typography>
                                                                                    <br></br>
                                                                                </Grid>
                                                                            }
                                                                            <Grid container item xs={12} >
                                                                                {editMode ? 
                                                                                    <Grid xs={12}>
                                                                                        <SelectCustomerAddressFormSwitcher
                                                                                            customerId= {invoice.ori_cust_id}
                                                                                            callback=   {this.handleAddressChange('delivery')}
                                                                                            name=       'delivery'
                                                                                            header=     'Delivery Address'
                                                                                            formErrors= {formErrors}
                                                                                            cname=      'Address'
                                                                                            addressName={invoice.ori_delivery_cust_address_name}
                                                                                            address=    {invoice.ori_delivery_cust_address}
                                                                                            contact=    {invoice.ori_delivery_cust_contact_name}
                                                                                            email=      {invoice.ori_delivery_cust_contact_email}
                                                                                            phone=      {invoice.ori_delivery_cust_contact_phone}
                                                                                            mobile=     {invoice.ori_delivery_cust_contact_mobile}
                                                                                        />
                                                                                    </Grid> :
                                                                                    <>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                <strong>Name</strong>
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={8}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                {invoice.ori_delivery_cust_address_name}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                <strong>Address</strong>
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={8}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                {invoice.ori_delivery_cust_address.split('\n').map((item, key) => {
                                                                                                    return  <span key={key}>
                                                                                                                {item}{invoice.ori_delivery_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                                                                            </span>
                                                                                                })}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                <strong>Contact</strong>
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={8}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                {invoice.ori_delivery_cust_contact_name}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                <strong>Phone Number</strong>
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={8}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                {deliveryPhone}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                <strong>Email</strong>
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={8}>
                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                {invoice.ori_delivery_cust_contact_email}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </>
                                                                                }
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>                                                       
                                                        </Grid>
                                                    
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <br></br>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow className={classes.tblHeader}>
                                                            <TableCell className={classes.tblHeaderCell}></TableCell>
                                                            <TableCell className={classes.tblHeaderCell}>Part Number</TableCell>
                                                            <TableCell className={classes.tblHeaderCell}>Part Description</TableCell>
                                                            <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Quantity</TableCell>
                                                            <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Unit Price</TableCell>
                                                            {invoice.ori_type && invoice.ori_type === 'With Discount' &&
                                                                <React.Fragment>
                                                                    <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Discount Rate</TableCell>
                                                                    <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>New Unit Price</TableCell>
                                                                    <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Discount Total</TableCell>
                                                                </React.Fragment>
                                                            }
                                                            <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Total Price</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {invoice.order_details && invoice.order_details.map((row ,key) => (
                                                        <TableRow style={{borderBottom: '1px solid rgb(224, 224, 224)'}} key={key}>
                                                            <TableCell style={{height:65, borderBottom: 'none', verticalAlign: 'middle'}} align='center'>
                                                                <img src={(row.part && row.part.default_image && row.part.default_image.library_thumbnail_url) ? row.part.default_image.thumbnail_file_path : logo} style={{overflow:'visible', maxHeight: 45, maxWidth: 45}} alt="" />
                                                            </TableCell>
                                                            <TableCell style={{whiteSpace:'nowrap'}}>{row.orid_part_number}</TableCell>
                                                            <TableCell style={{whiteSpace:'nowrap', width:'30%'}}>
                                                                {this.trimPartDescription(row.orid_part_description)} 
                                                                <br/> 
                                                                <span style={{color: '#757575', fontSize: '12px'}}>{row.orid_warranty_name !== null && row.orid_warranty_duration !== null ? row.orid_warranty_name : ''}</span>
                                                                <br/> 
                                                                <span style={{fontSize: '12px', whiteSpace:'pre-wrap'}}>
                                                                    {row.orid_notes && row.orid_notes.split('\n').map((item, key) => {
                                                                        return <span key={key}>{item}<br/></span>
                                                                    })}
                                                                </span>
                                                            </TableCell>
                                                            <TableCell style={{textAlign:'right'}}>{parseFloat(row.orid_quantity)}</TableCell>
                                                            <TableCell style={{textAlign:'right'}}>£{row.orid_unit_price}</TableCell>
                                                            {invoice.ori_type && invoice.ori_type === 'With Discount' &&
                                                                <React.Fragment>
                                                                    <TableCell style={{textAlign:'right'}}>{row.orid_discount_rate}%</TableCell>
                                                                    <TableCell style={{textAlign:'right'}}>£{row.orid_new_unit_price}</TableCell>
                                                                    <TableCell style={{textAlign:'right'}}>£{row.orid_discount_total}</TableCell>
                                                                </React.Fragment>
                                                            }
                                                            <TableCell style={{textAlign:'right'}}>£{row.orid_total_price}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TableCell style={{textAlign:'right', paddingRight: '8px'}} colSpan={invoice.ori_type && invoice.ori_type === 'With Discount' ? 8 : 5}><strong>Sub Total</strong></TableCell>
                                                            <TableCell style={{textAlign:'right'}}><strong>£{invoice.ori_sub_total}</strong></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{textAlign:'right', paddingRight: '8px'}} colSpan={invoice.ori_type && invoice.ori_type === 'With Discount' ? 8 : 5}><strong>VAT ({invoice.vat_value && invoice.vat_value.vat_value_percentage}%)</strong></TableCell>
                                                            <TableCell style={{textAlign:'right'}}><strong>£{invoice.ori_vat}</strong></TableCell>
                                                        </TableRow>
                                                        <TableRow className={classes.tblFooter}>
                                                            <TableCell style={{textAlign:'right', paddingRight: '8px'}} colSpan={invoice.ori_type && invoice.ori_type === 'With Discount' ? 8 : 5}><strong>Total</strong></TableCell>
                                                            <TableCell style={{textAlign:'right'}}><strong>£{invoice.ori_total_incl_vat}</strong></TableCell>
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                                <br></br>
                                            </Grid>
                                        </TabContainer>
                                    )}
                                    {currentTab === 1 && (
                                        <DataTable  
                                            config={{
                                                key: 'oro_order_id',
                                                pagination: true,
                                                alternatingRowColours: true,
                                                responsiveimportant: true,
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Order Date',
                                                    field: rowData => moment(rowData.order.order_date).format("DD/MM/YYYY"),
                                                    important: true,
                                                    sizeToContent: true,
                                                },
                                                {
                                                    actionsCustomHeader:    true,
                                                    important:              true,
                                                    alignment:              'left',
                                                    heading:                'Reference',
                                                    actions: rowData => {
                                                        return [
                                                            { label: rowData.order.order_reference, link: `/sales/order/view/${rowData.oro_order_id}`, type:'plainLink'},
                                                        ]
                                                    }
                                                },
                                                {
                                                    heading:        'Revision',
                                                    field:          rowData => rowData.oro_ori_revision_unique_number,
                                                    important:      true,
                                                    alignment:      'center'
                                                },
                                                {
                                                    heading: 'Customer',
                                                    field: rowData => <>
                                                                        {rowData.order.order_cust_name}
                                                                        {' '}
                                                                        <Tooltip
                                                                            title={
                                                                                <div>
                                                                                    <Typography variant="body2">
                                                                                        <b>Delivery Address:</b><br />
                                                                                        {rowData.order.order_delivery_cust_address.split('\n').map((item, key) => {
                                                                                            return  <span key={key}>
                                                                                                        {item}{rowData.order.order_delivery_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                                                                    </span>
                                                                                        })}
                                                                                    </Typography>
                                                                                </div>
        
                                                                            }
                                                                            placement="right"
                                                                        >
                                                                            <Link>
                                                                                <FASolidIcon 
                                                                                    icon='info-circle'
                                                                                    button 
                                                                                    size='small'
                                                                                    style={{
                                                                                        color: 'rgba(0, 0, 0, 0.38)'
                                                                                    }}
                                                                                />
                                                                            </Link>
                                                                        </Tooltip>
                                                                    </>,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Status',
                                                    field: rowData =>   <>
                                                                            <Tooltip
                                                                                title={
                                                                                    <div>
                                                                                        <Typography variant="body2">{rowData.toolTip1}</Typography>
                                                                                        <Typography variant="body2">{rowData.toolTip2}</Typography>
                                                                                    </div>
                                                                                }
                                                                                placement="left"
                                                                            >
                                                                                <IconButton className={classes.iconButton}>
                                                                                    <FALightIcon 
                                                                                        icon={`${rowData.icon}`}
                                                                                        button
                                                                                        style={{
                                                                                            color: rowData.color
                                                                                        }}
                                                                                        noMargin
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </>,
                                                    important: true,
                                                    alignment: 'center',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Total',
                                                    field: rowData => ( 
                                                        <React.Fragment>
                                                            {rowData.order.order_total_incl_vat}
                                                            {' '}
                                                            <Tooltip 
                                                                title={
                                                                    <div style={{display: 'flex', width: 200}}>
                                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                                            <Typography variant="body2">Sub Total</Typography>
                                                                            <Typography variant="body2">VAT ({rowData.order.vat_value.vat_value_percentage}%)</Typography>
                                                                            <Typography variant="body2">Total</Typography>
                                                                        </div>
                                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                                            <Typography variant="body2">£{rowData.order.order_sub_total}</Typography>
                                                                            <Typography variant="body2">£{rowData.order.order_vat}</Typography>
                                                                            <Typography variant="body2">£{rowData.order.order_total_incl_vat}</Typography>
                                                                        </div>
                                                                    </div>
                                                                } 
                                                                placement="left"
                                                            >
                                                                <Link>
                                                                    <FASolidIcon 
                                                                        icon='info-circle'
                                                                        button 
                                                                        size='small'
                                                                        style={{
                                                                            color: 'rgba(0, 0, 0, 0.38)'
                                                                        }}
                                                                    />
                                                                </Link>
                                                            </Tooltip>
                                                        </React.Fragment>
                                                    ),
                                                    important: true,
                                                    alignment: 'right',
                                                    fieldPrefix: '£',
                                                },
                                                {
                                                    actions: rowData => {
                                                        return [
                                                            {name: 'Download', icon: 'download', onClick: () => this.handleDownloadOpen(rowData.oro_order_id)},
                                                            {name: 'Send Order to Customer', icon: 'envelope', onClick: () => this.handleEmailOpen(rowData.oro_order_id)},
                                                            {name: 'View', icon: 'search', link: '/sales/order/view/' + rowData.oro_order_id},
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={_.orderBy(invoice.recurred_orders, ['oro_order_id'], 'desc' )}
                                        />
                                    )}
                                    {currentTab === 2 && (
                                        <TabContainer>
                                            <Grid container spacing={3} >
                                                {invoice.notes && 
                                                    invoice.notes.map(note => (
                                                        <Grid key={note.ori_note_id} item  xs={12}>
                                                            <NoteBlock 
                                                                prefix          = {'ori'}
                                                                deployDialog    = {this.props.deployDialog} 
                                                                note            = {note} 
                                                                editCallback    = {this.getInvoice}
                                                                deleteCallback  = {this.handleDeleteNote}
                                                            />
                                                        </Grid>
                                                    ))
                                                }
                                                {!invoice.notes.length > 0 && (
                                                    <Grid item xs={12}>
                                                        <Card>
                                                            <CardHeader                                                       
                                                                title='No notes'
                                                            />
                                                            <CardContent>
                                                                <Typography component="p">
                                                                    There are no notes to show for this recurring invoice
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </TabContainer>
                                    )}
                                    {currentTab === 3 && (
                                        <TabContainer>
                                            <ActivityLog 
                                                fields={{
                                                    type:   'orial_type',
                                                    date:   'orial_datetime',
                                                    icon:   { color: 'color', icon: 'icon' },
                                                    text:   'orial_text',
                                                    staff:  'orial_staff_name',
                                                }}
                                                atl={invoice?.activity_log}
                                            />
                                        </TabContainer>
                                    )}
                                     {currentTab === 4 && (
                                        <DataTable 
                                            config={{
                                                key: 'oro_order_id',
                                                pagination: true,
                                                alternatingRowColours: true,
                                                responsiveimportant: true,
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Revision',
                                                    field: rowData =>  rowData.ori_reference_unique_number,
                                                    important: true,
                                                    alignment: 'center',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Invoice Address',
                                                    field: rowData =>  
                                                        <>
                                                            {rowData.ori_invoice_cust_address_name}
                                                            {' '}
                                                            <Tooltip
                                                                    title={
                                                                        <div>
                                                                            <Typography variant="body2">
                                                                                    <b>Invoice Address:</b><br />
                                                                                    {rowData?.ori_invoice_cust_address.split('\n').map((item, key) => {
                                                                                        return  <span key={key}>{`${item}`}<br/></span>
                                                                                    })}
                                                                                    <b>Contact:</b><br />
                                                                                    {rowData?.ori_invoice_cust_address_name}<br />
                                                                                    {rowData?.ori_invoice_cust_contact_email}<br />
                                                                                    {rowData?.ori_invoice_cust_contact_mobile ? 
                                                                                        <>
                                                                                            <AllIcon icon='mobile-alt' style={{color:'white'}} size='small' />
                                                                                            {rowData?.ori_invoice_cust_contact_mobile}
                                                                                            <br/>
                                                                                        </> : ''}
                                                                                    {rowData?.ori_invoice_cust_contact_phone ? 
                                                                                        <>
                                                                                            <AllIcon icon='phone' style={{color:'white'}} size='small' />
                                                                                            {rowData?.ori_invoice_cust_contact_phone}
                                                                                            <br/>
                                                                                        </> : ''}
                                                                                </Typography>
                                                                        </div>

                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Link>
                                                                        <FASolidIcon 
                                                                            icon='info-circle'
                                                                            button 
                                                                            size='small'
                                                                            style={{
                                                                                color: 'rgba(0, 0, 0, 0.38)'
                                                                            }}
                                                                        />
                                                                    </Link>
                                                                </Tooltip>
                                                        </>,
                                                    
                                                },
                                                {
                                                    heading: 'Delivery Address',
                                                    field: rowData =>  
                                                        <>
                                                            {rowData.ori_delivery_cust_address_name}
                                                            {' '}
                                                            <Tooltip
                                                                    title={
                                                                        <div>
                                                                            <Typography variant="body2">
                                                                                    <b>Delivery Address:</b><br />
                                                                                    {rowData?.ori_delivery_cust_address.split('\n').map((item, key) => {
                                                                                        return  <span key={key}>{`${item}`}<br/></span>
                                                                                    })}
                                                                                    <b>Contact:</b><br />
                                                                                    {rowData?.ori_delivery_cust_address_name}<br />
                                                                                    {rowData?.ori_delivery_cust_contact_email}<br />
                                                                                    {rowData?.ori_delivery_cust_contact_mobile ? 
                                                                                        <>
                                                                                            <AllIcon icon='mobile-alt' style={{color:'white'}} size='small' />
                                                                                            {rowData?.ori_delivery_cust_contact_mobile}
                                                                                            <br/>
                                                                                        </> : ''}
                                                                                    {rowData?.ori_delivery_cust_contact_phone ? 
                                                                                        <>
                                                                                            <AllIcon icon='phone' style={{color:'white'}} size='small' />
                                                                                            {rowData?.ori_delivery_cust_contact_phone}
                                                                                            <br/>
                                                                                        </> : ''}
                                                                                </Typography>
                                                                        </div>

                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Link>
                                                                        <FASolidIcon 
                                                                            icon='info-circle'
                                                                            button 
                                                                            size='small'
                                                                            style={{
                                                                                color: 'rgba(0, 0, 0, 0.38)'
                                                                            }}
                                                                        />
                                                                    </Link>
                                                                </Tooltip>
                                                        </>,
                                                    
                                                },
                                                {
                                                    actions: rowData => {
                                                        return [
                                                            {name: 'View', icon: 'search', onClick: () => {
                                                                this.props.history.push('/sales/order/invoice/recurring/' + rowData.ori_id);
                                                                window.location.reload()}, disabled: rowData.ori_id == invoice.ori_id },
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={_.orderBy(invoice.revisions, ['ori_reference_unique_number'], 'desc' )}
                                        />
                                     )}
                                    {currentTab === 5 && (
                                        <Grid item xs={12}>
                                            <PaddedPaper>
                                                <Typography><b>Secondary Invoice Emails</b></Typography>
                                                {!!this.state.contactsList.length &&
                                                    <Grid container spacing={3} alignItems='flex-end'>
                                                        <Grid item xs={6}>
                                                            
                                                                <FormControl fullWidth margin="normal">
                                                                    <AutoCompleteSelect 
                                                                        options={this.state.contactsList}
                                                                        name='Contact'
                                                                        label='Contact *'
                                                                        error={formErrors && formErrors['Contact'] && true}
                                                                        errorText={formErrors && formErrors['Contact']}
                                                                        value={editFromData.secondContact}
                                                                        onChange={this.handleContactChange}
                                                                    />
                                                                </FormControl>
                                                            
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button variant='contained' color='primary'  onClick={this.handleContactAdd} disabled={!editFromData.secondContact}>Add</Button>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                <br></br>
                                                <DataTable 
                                                    config={{
                                                        key: 'oro_order_id',
                                                        pagination: true,
                                                        alternatingRowColours: true,
                                                        responsiveimportant: true,
                                                    }}
                                                    columns={[
                                                        {
                                                            heading: 'Name',
                                                            field: rowData => rowData.name,
                                                            important: true,
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: 'Email',
                                                            field: rowData => rowData.email,
                                                            important: false,
                                                            sizeToContent: false,
                                                        },
                                                        {
                                                            actions: rowData => {
                                                                return [
                                                                    {name: 'Remove', icon: 'times', onClick: () => this.handleRemoveSeconderyContacts(rowData.rid)},
                                                                ]
                                                            }
                                                        }
                                                    ]}
                                                    rows={editFromData.secondaryContacts}
                                                />
                                            </PaddedPaper>
                                        </Grid>)}
                                    <Divider style={{marginBottom:20}} />
                                    {editMode ? 
                                        <div className={`buttonRow ${classes.btnRow}`}>
                                            <Grid container justify='flex-end' spacing={1}>
                                                <Grid item xs={12} md={2}>
                                                    <BackButton style={{width:'100%'}} props={this.props} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <Button variant="contained" onClick={this.handleUpdate} className={classes.btnRowBtn} color="primary" disabled={!(editFromData.delivery.edited || editFromData.invoice.edited || editFromData.contactsEdited) }>Update</Button>
                                                </Grid>
                                            </Grid>
                                        </div>:
                                        <div className={`buttonRow ${classes.btnRow}`}>
                                            <Grid container justify='flex-end' spacing={1}>
                                                <Grid item xs={12} md={2}>
                                                    <BackButton style={{width:'100%'}} props={this.props} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleNotesOpen}><FALightIcon icon='plus' buttonPrimary /> Add Notes</Button>
                                                </Grid> 
                                            </Grid>
                                        </div>
                                    }
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <PaddedPaper>
                                    <Typography variant="h5">
                                        Last Used
                                        <br></br>
                                        <br></br>
                                    </Typography>
                                    <Grid container >
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                                <strong>Date</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body2" gutterBottom>
                                                {moment(invoice.ori_last_used_datetime).format("DD/MM/YYYY")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                                <strong>Processed By</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body2" gutterBottom>
                                                {_.last(invoice.recurred_orders).order.order_staff_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                                <strong>Order</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body2" gutterBottom>
                                                <Link to={`/sales/order/view/${_.last(invoice.recurred_orders).order.order_id}`} style={{ color: '#0282C6' }} >
                                                    {_.last(invoice.recurred_orders).order.order_reference}<br></br>
                                                </Link>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                                <br></br>
                                {/*}
                                {invoice.revisions.length > 1 &&
                                    <PaddedPaper>
                                        <Typography variant="h5">
                                            Revisions
                                            {invoice.revisions.map(r => 
                                                <Typography variant="body1" gutterBottom>
                                                    <a href={`/sales/order/invoice/recurring/${r.ori_id}`} style={{ color: '#0282C6' }} >
                                                        {`${r.ori_reference}-0${r.ori_reference_unique_number}`}
                                                    </a>
                                                </Typography>
                                            )}
                                        </Typography>
                                    </PaddedPaper>
                                }*/}
                            </Grid>
                        </Grid>

                        {/* NOTES */}
                        <ConfirmationDialog 
                            close   = {this.handleCloseDeleteNote} 
                            open    = {this.state.confirmNoteDelete}
                            title   = "Delete Note?"
                            message = "Are you sure you want to delete this note?"
                            success = {this.deleteNote}
                        />
                        <Dialog 
                            open={this.state.dialogNotesOpen} 
                            onClose={this.handleNotesClose} 
                            fullWidth={true} 
                            maxWidth="sm" 
                            scroll="body"
                            disableBackdropClick
                            disableEscapeKeyDown
                        >
                            <DialogTitle id="form-dialog-title">Add Notes</DialogTitle>
                            <DialogContent>
                                <Textarea
                                    id="notesText"
                                    name="notesText"
                                    label="Notes *"
                                    value={this.state.formData.notesText}
                                    rows={5}
                                    error={formErrors && formErrors['notesText']}
                                    onChange={this.handleChange}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleNotesClose} variant="outlined" color="default">Cancel</Button>
                                <Button onClick={this.handleNotesSuccess} autoFocus variant="contained" color="primary">Save</Button>
                            </DialogActions>
                        </Dialog>
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarNotesOpen}
                            onClose={this.handleNotesSnackbarClose}
                            message='You have successfully added new notes'
                        />
                         <ConfirmationDialog 
                            open={this.state.deleteOpen} 
                            success={this.handleDelete} 
                            close={this.handleDeleteConfirm} 
                            title="Stop Recurring Invoice?" 
                            message="Are you sure you want to stop this recurring invoice?"
                        />
                    </>
                }
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                    => dispatch(setPersistence(key, state)),
    deployDialog:       (content, title, variant, size, style=null) => dispatch(deployDialog(content, title, variant, size, style)),
    closeDialog:        ()                              => dispatch(closeDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RecurringInvoice));