import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import Notification from 'Components/Layout/Misc/Notification';
import DataTable from 'Components/Common/DataTables/DataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ReminderDialog from 'Components/Reminders/Misc/ReminderDialog';
import TaskDialog from 'Components/Tasks/Misc/TaskDialog';
import { deployDialog } from 'Actions/Dialog/Dialog';
import { dismissAllNotifications } from 'Actions/Notifications/Notifications';
import { putNotificationStatus } from 'Actions/Notifications/Notifications';

const initialState = {
    data: {},
    isLoading: true,
}

class NotificationList extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState
        this.timeout = false;
    }

    componentDidMount = () => {
        this.loadComponentData(false);
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout)
    }

    refreshData = () => {
        this.timeout = setTimeout(() => this.loadComponentData(true), 250);
    }

    loadComponentData = (noLoadingBar) => {
        API.get('/misc/notifications', 
        {
            props: {
                noLoading: noLoadingBar 
            }
        })
        .then(res => {
            if(res.data) {
                this.setState({
                    data: res.data,
                    isLoading: false
                })
            }
        })
    }

    handleNotificationAction = (id, action) => {
        /* Remember to change the handleAction function in AdminNotifications.js */
        this.props.putNotificationStatus(id, "read");
        action = action.split(":");
        switch(action[0]) {
            case "reminder":
                if(action[1] && !isNaN(action[1])) {
                    this.props.deployDialog(<ReminderDialog id={action[1]} />, "", "standard", "md")
                    this.refreshData()
                }
            break;
            case "task":
                if(action[1] && !isNaN(action[1])) {
                    this.props.deployDialog(<TaskDialog id={action[1]} />, "", "standard", "md")
                    this.refreshData()
                }
            break;
            case "url":
                this.props.history.push(action[1]);
            break;
            default:
        }
    }

    handleNotificationDismiss = (id, e) => {
        e.stopPropagation();
        this.props.putNotificationStatus(id, "dismissed");
        this.refreshData();
    }

    handleNotificationDismissAll = () => {
        this.props.dismissAllNotifications();
        this.refreshData();
    }

  	render() {
        const { data, isLoading } = this.state;
        const { notifications, status } = this.props;
        let rows = {
            notifications: {
                completed: _.sortBy(data.notifications, function(el) { return el.i }).reverse(),
                outstanding: _.sortBy(notifications, function(el) { return el.i }).reverse()
            }
        }
        return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <React.Fragment>   
                        <Grid item xs={12}>
                            <Grid container spacing={3} alignItems='center'>
                                <Grid item xs>
                                    <Typography variant="h6">
                                        New Notifications ({_.size(notifications)})
                                    </Typography>
                                </Grid>
                                {!_.isEmpty(notifications) && (
                                    <Grid item>
                                        <Button 
                                            color="primary" 
                                            size="small"
                                            variant="contained"
                                            onClick={() => this.handleNotificationDismissAll()}
                                        >   
                                            <FALightIcon icon="times" size="small" buttonPrimary />
                                            Dismiss All 
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Paper>
                                        <DataTable  
                                            config={{
                                                key: 'i',
                                                noHeader: true,
                                                noPadding: true,
                                                isLoading: isLoading,
                                                noResultsText: "You have no new notifications right now"
                                            }}
                                            columns={[  
                                                {
                                                    heading: '',
                                                    field: rowData => (
                                                        <Notification
                                                            key={rowData.i}
                                                            data={rowData}
                                                            handleAction={this.handleNotificationAction} 
                                                            handleDismiss={this.handleNotificationDismiss}
                                                            status={status}
                                                            enhanced
                                                        />
                                                    ),
                                                },   
                                            ]}
                                            rows={rows.notifications.outstanding}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3} alignItems='center'>
                                <Grid item xs>
                                    <Typography variant="h6">
                                        Past Notifications
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <DataTable  
                                            config={{
                                                key: 'i',
                                                pagination: true,
                                                noHeader: true,
                                                noPadding: true,
                                                isLoading: isLoading,
                                                rowsPerPage: 5,
                                                noResultsText: "You haven't had any notifications yet"
                                            }}
                                            columns={[  
                                                {
                                                    heading: '',
                                                    field: rowData => (
                                                        <Notification
                                                            key={rowData.i}
                                                            data={rowData}
                                                            handleAction={this.handleNotificationAction} 
                                                            handleDismiss={this.handleNotificationDismiss}
                                                            status={status}
                                                            enhanced
                                                        />
                                                    ),
                                                },   
                                            ]}
                                            rows={rows.notifications.completed}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        )
    }
}

function mapStateToProps(state){
    return {
        notifications: state.notifications.notifications,
        status: state.notifications.status
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployDialog: (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
        putNotificationStatus: (id, action) => dispatch(putNotificationStatus(id, action)),
        dismissAllNotifications: () => dispatch(dismissAllNotifications())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationList))