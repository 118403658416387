//InvoiceCreditNote
import API from 'API';
import _ from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import React from 'react';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography, Button, ListItemSecondaryAction } from '@material-ui/core';

import CheckBox from 'Components/Common/Inputs/CheckBox';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconHelper from 'Helpers/IconHelper';

const initialState = {
    isLoading:  true,
    categories:  [],
    customers:  [],
    formData: {
        subCategory: {},
        category: {},
    },
    ogFormData: null
};

class Marketing extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getCustomerMarketingCategories();
    }

    getCustomerMarketingCategories = () => {
        Promise.all([
            API.get('/marketing/customerMarketing'),
            API.get(`/customers/${this.props.customerId}/marketing`)
        ]).then(([r1, r2]) => {
            let subCategory = {};
            let category = {};
            _.each(_.uniqBy(r1.data, i => i.cm_category), i => {
                category[i.cm_category] = true;
                _.each(_.filter(r1.data, {cm_category: i.cm_category}), j => {
                    if (_.find(r2.data, {ctcm_cm_id: j.cm_id})){
                        subCategory[j.cm_id] = true;
                    } else {
                        subCategory[j.cm_id] = false;
                        category[i.cm_category] = false;
                    }
                });
            });

            this.setState({
                isLoading: false,
                categories: r1.data,
                customer: r2.data,
                formData: { subCategory, category },
                ogFormData: JSON.stringify({ subCategory, category })
            })
        })
    }

    checkSubCategory = id => () => {
        let subCategory = this.state.formData.subCategory;
        subCategory[id] = !subCategory[id];

        this.setState({
            formData: {
                ...this.state.formData,
                subCategory
            }
        }, ()=> {
            let category = _.find(this.state.categories, {cm_id: id}).cm_category;
            let categories = this.state.formData.category;
            categories[category] = true;
            _.each(_.filter(this.state.categories, i => i.cm_category === category && i.cm_name), i => {
                if (!subCategory[i.cm_id]) {
                    categories[category] = false;
                }
            });
            this.setState({
                formData: {
                    ...this.state.formData,
                    category: categories
                }
            })
        })
    }

    checkCategory = category => () => {
        let categories = this.state.formData.category;
        let subCategory = this.state.formData.subCategory;

        categories[category] = !categories[category];
        _.each(_.filter(this.state.categories, {cm_category: category}), i => {
            subCategory[i.cm_id] = categories[category];
        });


        this.setState({
            formData: {
                category: categories,
                subCategory: subCategory
            }
        })
    }

    submit = () => {
        this.setState({isLoading: true}, ()=>{
            API.post(`/customers/${this.props.customerId}/marketing`, {marketing: JSON.stringify(this.state.formData.subCategory)})
            .then(this.getCustomerMarketingCategories)
        });
    }

    render() {
        const { isLoading, categories, formData, ogFormData }  = this.state;

        if (isLoading) return (<LoadingCircle />);

        let effectedMailingLists = [];

        _.each(_.values(formData.subCategory), (i, idx) => {
            if (i){
                let cat = _.find(categories, c => parseInt(c.cm_id) === parseInt(_.keys(formData.subCategory)[idx]));
                if (cat?.mailing_lists?.length > 0) {
                    _.each(cat.mailing_lists, ml => effectedMailingLists.push(ml.mailing_list));
                }
            }
        })

        effectedMailingLists = _.filter(_.uniqBy(effectedMailingLists, 'ml_id'), i => i);

        return (
            <Grid container>
                <Grid item xs={12}>
                    {_.map(_.uniqBy(categories, 'cm_category'), (category, i) => 
                        _.filter(categories, i => i.cm_category === category.cm_category && i.cm_name).length > 0 &&
                            <ExpansionPanel key={i}>
                                <ExpansionPanelSummary dense expandIcon={<AllIcon icon={IconHelper.arrowDown} white noMargin />}>
                                    <Grid container style={{alignItems:'center'}}>
                                        <Grid item>
                                            <CheckBox
                                                checked={_.filter(_.filter(categories, {cm_category: category.cm_category}), i => i?.cm_name && formData.subCategory[i?.cm_id]).length > 0}
                                               /* indeterminate={
                                                    _.filter(_.filter(categories, {cm_category: category.cm_category}), i => i?.cm_name && formData.subCategory[i?.cm_id]).length !== _.filter(_.filter(categories, {cm_category: category.cm_category}), i => i?.cm_name).length &&
                                                    _.filter(_.filter(categories, {cm_category: category.cm_category}), i => i?.cm_name && formData.subCategory[i?.cm_id]).length > 0
                                                }
                                                */
                                                style={{color:'white'}}
                                                onClick={this.checkCategory(category.cm_category)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='h6'>{category.cm_category}</Typography>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <List fullWidth style={{width: '100%'}}>
                                        {
                                            _.map(_.filter(_.filter(categories, {cm_category: category.cm_category}), i => i.cm_name), (item, j) =>
                                                <ListItem key={`${i}_${j}`} dense fullWidth>
                                                    <ListItemAvatar>
                                                        <CheckBox 
                                                            color='primary'
                                                            checked={formData.subCategory[item.cm_id]}
                                                            onClick={this.checkSubCategory(item.cm_id)}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={item.cm_name} />
                                                    <ListItemSecondaryAction>
                                                        <Typography variant='body2'>{item.mailing_lists.length} Mailing Lists</Typography>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                </ExpansionPanelDetails>
                            </ExpansionPanel> 
                    )}  
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button 
                        variant='contained' 
                        color='primary'
                        onClick={()=>{
                            this.props.deployConfirmation(
                                <>Are you sure you want to update this customers marketing?{effectedMailingLists.length > 0 && <><br/><br/>The following mailing lists may have invalid contacts:<br/>{_.map(effectedMailingLists, i =><>{i.ml_name}<br/></>)}</>}</>,
                                'Update Marketing',
                                this.submit
                            )
                        }}
                        disabled={ogFormData === JSON.stringify(formData)}
                    >Update</Button>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
})

export default connect(null, mapDispatchToProps)(Marketing);