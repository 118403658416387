import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';


class SupplierDetails extends PureComponent {
    
    render() {
        const { formErrors, supplierContactList, returnType, company, fao, handleSelectChange, completionDate, address } = this.props;
        const loggedInStaff = this.props.loggedInStaff !== undefined ? this.props.loggedInStaff.firstName + ' ' + this.props.loggedInStaff.lastName : '';
        return (
            <form noValidate autoComplete="off">
                <Grid container spacing={3}>
                    <Grid container item spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>
                                {returnType}
                            </Typography>
                        </Grid>
                        <Grid item lg={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                Company
                            </Typography>
                        </Grid>
                        <Grid item lg={9}>
                            <Typography variant="subtitle1" gutterBottom>
                                {company}
                            </Typography>
                        </Grid>
                        <Grid item lg={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                Address
                            </Typography>
                        </Grid>
                        <Grid item lg={9}>
                            <Typography variant="subtitle1" gutterBottom>
                                {address.split('\n').map((item, key) => {
                                    return <span key={key}>{item}<br/></span>
                                })}
                            </Typography>
                        </Grid>
                        <Grid item lg={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                Completed By
                            </Typography>
                        </Grid>
                        <Grid item lg={9}>
                            <Typography variant="subtitle1" gutterBottom>
                                {loggedInStaff}
                            </Typography>
                        </Grid>
                        <Grid item lg={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                Completion Date
                            </Typography>
                        </Grid>
                        <Grid item lg={9}>
                            <Typography variant="subtitle1" gutterBottom>
                                {completionDate}
                            </Typography>
                        </Grid>
                        <Grid item lg={12}>
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteSelect
                                    name="fao"
                                    options={supplierContactList} 
                                    label='FAO *'
                                    value={fao}
                                    onChange={handleSelectChange('fao')}
                                    error={formErrors && formErrors['fao'] && true}
                                    errorText={formErrors && formErrors['fao'] && formErrors['fao']}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
        loading: {
            ...state.loading
        }
    };
}

export default connect(mapStateToProps)(SupplierDetails);
    