import { Button, ButtonGroup, Dialog, DialogContent, DialogTitle, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, Typography } from '@material-ui/core';
import API from 'API';
import EmailPreview from 'Components/Common/Email/EmailPreview';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import InputHelper from 'Helpers/InputHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

const initialState = (props) => ({
    isLoading: true,
    placeHolders: [],
    previewFormData: {
        open: false,
        email: [props.loggedInStaff.email],
        type: 'staff'
    },
    lists: {
        mailingLists: [],
        templates: [],
    },
})

class EmailPreviewTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
        this.inputHelper = new InputHelper(this);
    }

    componentDidMount() {
        this.getPlaceholders();
        this.getLists();
    }

    getLists = () => {
        Promise.all([
            API.get(`/marketing/campaigns/mailingLists`),
            API.get('/staff/all', {params: {searchStatus: 'active'}})
        ]).then(([mailingLists, staff]) => {
            this.setState({
                lists: {
                    mailingLists: mailingLists.data,
                    staff: staff.data,
                }
            });
        })
    }

    getPlaceholders = () => {
        const {survey} = this.props; 
        API.get(`/marketing/emailTemplates/placeholders`).then(res => {
            let placeHolders = [];
            _.each(res.data, (placeholder) => {
                if (survey.survey_email_json.includes(placeholder.etp_name)) {
                    placeHolders.push(placeholder);
                }
            })

            this.setState({
                isLoading: false,
                placeHolders
            })
        })
    }

    showPreviewDialog = () => {
        this.setState({
            previewFormData: {
                ...this.state.previewFormData,
                open: !this.state.previewFormData.open
            }
        })
    }

    sendEmailPreview = () => {
        let formData = new FormData();
        formData.append('email', JSON.stringify(this.state.previewFormData.email));
        API.post(`/survey/${this.props.survey.survey_id}/preview`, formData)
        .then(response => {
            this.showPreviewDialog();
        })

    }

    render() {
        const { isLoading, placeHolders, previewFormData, lists } = this.state;
        const { survey } = this.props; 

        if (isLoading) {
            return (
                <LoadingCircle />
            )
        }

        return (
            <Grid container spacing={3}>
                <Grid item  xs={8}>
                    <EmailPreview 
                        json={JSON.parse(survey.survey_email_json)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Grid container >
                        <Grid item xs={12} style={{marginBottom:'1.5em'}}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={this.showPreviewDialog}
                            >Send Preview</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h6'>Placeholders</Typography>
                            {placeHolders.length > 0 ?
                                <List dense style={{padding: 0}}>
                                    <ListItem dense style={{padding: 0}}>
                                        {_.map(placeHolders, (p) => 
                                            <ListItemText primary={p.etp_name} secondary={p.etp_desc} />
                                        )}
                                    </ListItem>
                                </List> : 
                                <List>
                                    <ListItem>
                                        <ListItemText primary='No placeholders used' />
                                    </ListItem>
                                </List>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {
                    previewFormData.open &&
                        <Dialog
                            open={previewFormData.open}
                            fullWidth
                            maxWidth='sm'
                        >
                            <DialogTitle>Email Preview</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={1} style={{alignItems: 'center'}}>
                                    <Grid item xs={12}>
                                        <ButtonGroup
                                            fullWidth
                                        >
                                            <Button
                                                onClick={this.inputHelper.handleButtonChange('previewFormData.type', 'staff')}
                                                variant={previewFormData.type === 'staff' ? 'contained' : 'outlined'}
                                                color={previewFormData.type === 'staff' ? 'primary' : 'default'}
                                            >Staff</Button>
                                            <Button
                                                onClick={this.inputHelper.handleButtonChange('previewFormData.type', 'custom')}
                                                variant={previewFormData.type === 'custom' ? 'contained' : 'outlined'}
                                                color={previewFormData.type === 'custom' ? 'primary' : 'default'}
                                            >custom</Button>
                                        </ButtonGroup>
                                    </Grid>
                                    {previewFormData.type === 'custom' &&
                                        <>
                                            <Grid item xs={11}>
                                                <TextField
                                                    label="Email *"
                                                    value={previewFormData?.customerEmail}
                                                    name='previewFormData.customerEmail'
                                                    onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <AllIcon
                                                    noMargin
                                                    icon={IconHelper.plus}
                                                    onClick={() => {
                                                        this.inputHelper.handleArrayAddRemove('previewFormData.email')(previewFormData.customerEmail)
                                                        this.inputHelper.handleButtonChange('previewFormData.customerEmail','')()
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    }   
                                    {previewFormData.type === 'staff' &&
                                        <Grid item xs={12}>
                                            <AutoCompleteSelect
                                                label="Staff *"
                                                options={_.map(_.filter(lists.staff, s =>!previewFormData.email.includes(s.staff_email)), s => ({ label: `${s.staff_first_name} ${s.staff_last_name} (${s.staff_email})`, value: s.staff_email }))}
                                                noClear
                                                fullWidth
                                                onChange={e => this.inputHelper.handleArrayAddRemove('previewFormData.email')(e.value)}
                                            />
                                        </Grid>
                                    }
                                    {
                                        previewFormData.email.length > 0 &&
                                        <Grid item xs={12}>
                                            <List>
                                                {_.map(previewFormData.email, e => 
                                                    <ListItem
                                                        style={{
                                                            borderBottom: `1px solid ${colors.border}`,
                                                        }}
                                                    >
                                                        <ListItemText primary={e} />
                                                        <ListItemSecondaryAction>
                                                            <AllIcon
                                                                icon={IconHelper.bin}
                                                                noMargin
                                                                onClick={() => this.inputHelper.handleArrayAddRemove('previewFormData.email')(e)}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>    
                                                )}
                                            </List>
                                        </Grid>
                                    }
                                    <Grid item xs={12} className='buttonRow'>
                                        <Button
                                            variant='outlined'
                                            onClick={this.showPreviewDialog}
                                        >Close</Button>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={this.sendEmailPreview}
                                        >Send</Button>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>
                }
            </Grid>
        )
    }
}

function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff
    };
}

export default connect(mapStateToProps, null)(EmailPreviewTab);