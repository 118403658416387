import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import API from 'API';
import BarChart from 'Components/Common/Charts/BarChart';
import LineChart from 'Components/Common/Charts/LineChart';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { fmonths, getColorCurrency, getColorPercentage, months } from 'Helpers/ReportsHelper';
import { clenyDate, getOrdinal } from 'Helpers/TimeHelper';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const initialState = () => ({
    isLoading: true,
    data: [],
})
const currentMonth = moment().month() + 1;

const ConsistentSalesRow = ({icon, data, comparator, showRed, showComparator}) => {
	return (
		<>
			<Grid item xs={showComparator ? 2 : 3} style={{textAlign:'center'}}><AllIcon icon={icon} size='xs' /></Grid>
			<Grid item xs={showComparator ? 4 : 9} style={{color: showRed && colors.red}}>{data < 0 ? '-' : ''}£{parseFloat(Math.abs(data)).toFixed(2)}</Grid>
            {showComparator && <Grid item xs={6}>{comparator}</Grid> }
        </>
	)
}

class SalesReportYear extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.loadReports();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cid !== this.props.cid || prevProps.partId !== this.props.partId || prevProps.year !== this.props.year || prevProps.current !== this.props.current){
            this.setState(initialState());
            this.loadReports();
        }
    }

    loadReports = () => {
        let params = {params: {cid:this.props.cid, partId:this.props.partId, year:this.props.year, current: this.props.current ? 1 : 0}}
        API.get('/reports/yoysReport/sales/year', params)
        .then(response => {
            this.setState({
                data: response.data, 
                isLoading: false
            });
        })
    }

    render() {
        const {isLoading, data} = this.state;
        const {year, current} = this.props;

        let
            currentYear = `${year-1}/${year}`,
            lastYear = `${year-2}/${year-1}`;

        return (
            <Grid item xs={12}>
                <Card style={{color: '#000',backgroundColor: '#FFF',padding: 0}} >
                    <CardHeader
                        avatar={<AllIcon icon={IconHelper.year} size='medium' style={{color: '#fff'}} noMargin/>}
                        title={
                            <Grid container spacing={1} style={{alignItems: 'flex-end'}}>
                                <Grid item >
                                    <>{currentYear} vs. {lastYear}</>
                                </Grid>
                                <Grid item >
                                    <Typography variant="body1" style={{paddingBottom: 1}} >{current ? ` Up Until ${getOrdinal(moment().format('D'))} ${moment().format('MMMM YYYY')}` : ''}</Typography>
                                </Grid>
                            </Grid>
                        }
                        titleTypographyProps={{variant:'h6'}}
                        style={{background: 'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', color: '#FFF'}}
                    />
                    <CardContent style={{padding:15}}>
                        <Grid container spacing={2}>
                            {isLoading ?
                                <Grid item xs={12}>
                                    <LoadingCircle/>
                                </Grid> :
                                <>
                                    <Grid item xs={3}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>{currentYear}</b></Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PaddedPaper style={{padding: 10}}>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Typography gutterBottom variant='caption'><b>Total {current ? `To ${clenyDate(moment())}` : ''}</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={6} style={{marginLeft: 'auto'}}>
                                                            <Typography gutterBottom variant='caption'><b>Difference</b></Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                        <ConsistentSalesRow icon={IconHelper.products} data={data.c.p.c} comparator={
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={7}>
                                                                    {getColorCurrency(data.c.p.c - data.c.p.l , false, false)}
                                                                </Grid>
                                                                
                                                                <Grid item xs={5}>
                                                                    {getColorPercentage((data.c.p.c - data.c.p.l ), data.c.p.l, false, false)}
                                                                </Grid> 
                                                            </Grid>
                                                        } showComparator />
                                                        <ConsistentSalesRow icon={IconHelper.services} data={data.c.s.c} comparator={
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={7}>
                                                                    {getColorCurrency(data.c.s.c - data.c.s.l , false, false)}
                                                                </Grid>
                                                                
                                                                <Grid item xs={5}>
                                                                    {getColorPercentage((data.c.s.c - data.c.s.l ), data.c.s.l, false, false)}
                                                                </Grid> 
                                                            </Grid>
                                                        } showComparator />
                                                        <ConsistentSalesRow icon={IconHelper.total} data={data.c.t.c} comparator={
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={7}>
                                                                    {getColorCurrency(data.c.t.c - data.c.t.l , false, false)}
                                                                </Grid>
                                                                
                                                                <Grid item xs={5}>
                                                                    {getColorPercentage((data.c.t.c - data.c.t.l ), data.c.t.l, false, false)}
                                                                </Grid> 
                                                            </Grid>
                                                        } showComparator />
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>{lastYear}</b></Typography>
                                            </Grid>
                                            {!!current &&
                                                <Grid item xs={6}>
                                                    <PaddedPaper style={{padding: 10}}>
                                                        <Grid container style={{alignItems: 'center'}}>
                                                            <Grid item xs={12} style={{paddingBottom: 4}}>
                                                                <Typography variant='caption'><b>Total {current ? `To ${clenyDate(moment().subtract(1,'year'))}` : ''}</b></Typography>
                                                            </Grid>
                                                            <ConsistentSalesRow icon={IconHelper.products} data={data.c.p.l} comparator={data.c.p.l + data.c.p.c}  />
                                                            <ConsistentSalesRow icon={IconHelper.services} data={data.c.s.l} comparator={data.c.s.l + data.c.s.c}  />
                                                            <ConsistentSalesRow icon={IconHelper.total} data={data.c.t.l} comparator={data.c.t.l + data.c.t.c}  />
                                                        </Grid>
                                                    </PaddedPaper>
                                                </Grid>
                                            }
                                            <Grid item xs={current ? 6 : 12}>
                                                <PaddedPaper style={{padding: 10}}>
                                                    <Typography variant='caption'><b>Total Year</b></Typography>
                                                    <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                        <ConsistentSalesRow icon={IconHelper.products} data={data.t.p.l} />
                                                        <ConsistentSalesRow icon={IconHelper.services} data={data.t.s.l} />
                                                        <ConsistentSalesRow icon={IconHelper.total} data={data.t.t.l} />
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Difference</b></Typography>
                                            </Grid>
                                            {!!current &&
                                                <Grid item xs={6}>
                                                    <PaddedPaper style={{padding: 10}}>
                                                        <Typography gutterBottom variant='caption' style={{paddingBottom: 8}}><b>Total {current ? `To ${clenyDate(moment())}` : ''}</b></Typography>
                                                        <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                            <Grid item={3}>
                                                                <AllIcon 
                                                                    size={65} 
                                                                    icon={data.c.t.c > data.c.t.l ? IconHelper.arrowStemUp : (data.c.t.c < data.c.t.l ?  IconHelper.arrowStemDown : IconHelper.dash)} 
                                                                    color={data.c.t.c > data.c.t.l ? colors.green : (data.c.t.c < data.c.t.l ? colors.red : colors.grey)} 
                                                                />
                                                            </Grid>
                                                            <Grid item={9}>
                                                                <Typography variant='body1'>{getColorPercentage((data.c.t.c - data.c.t.l ), data.c.t.l, false, false)}</Typography>
                                                                <Typography variant='h5'>{getColorCurrency(data.c.t.c - data.c.t.l , false, false)}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </PaddedPaper>
                                                </Grid>
                                            }
                                            <Grid item xs={current ? 6 : 12}>
                                                <PaddedPaper style={{padding: 10}}>
                                                    <Typography gutterBottom variant='caption' style={{paddingBottom: 8}}><b>Total Year</b></Typography>
                                                    <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                        <Grid item={3}>
                                                            <AllIcon 
                                                                size={65} 
                                                                icon={data.t.t.c > data.t.t.l ? IconHelper.arrowStemUp : (data.t.t.c < data.t.t.l ?  IconHelper.arrowStemDown : IconHelper.dash)} 
                                                                color={data.t.t.c > data.t.t.l ? colors.green : (data.t.t.c < data.t.t.l ? colors.red : colors.grey)} 
                                                            />
                                                        </Grid>
                                                        <Grid item={9}>
                                                            <Typography variant='body1'>{getColorPercentage((data.t.t.c - data.t.t.l ), data.t.t.l, false, false)}</Typography>
                                                            <Typography variant='h5'>{getColorCurrency(parseFloat(data.t.t.c) -parseFloat(data.t.t.l), false, false)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Month On Month</b></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Running Total</b></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <PaddedPaper>
                                            <BarChart 
                                                data={_.map(_.keys(data.b), idx => {
                                                    let monthData = data.b[idx];
                                                    let label = months[ parseInt(idx) ];
                                                    let nullMonth = current ? (fmonths.indexOf( label ) <= fmonths.indexOf( months[ parseInt(currentMonth) ]) ? 0 : 1 ) : 0;
                                                    let today = current && label === months[ parseInt(currentMonth) ];
                                                    return {
                                                        label: `${label.slice(0,3)}`,
                                                        // label: `${label.slice(0,3)}${(today ? ` (${getOrdinal(moment().format('D'))})` : '')}`,
                                                        value: {
                                                            [currentYear] : nullMonth ? null : parseFloat(monthData['t']['c']),
                                                            [lastYear] :  nullMonth ? null : parseFloat(monthData['t'][ today ? 'lc' :'l']),
                                                        },
                                                        color: {
                                                            [currentYear] : colors.primary,
                                                            [lastYear] :    colors.red,
                                                        },
                                                        bold: today,
                                                    }
                                                    
                                                })}
                                                yLabel={i => `${i<0?'-':''}£${Math.abs(i)}`}
                                                tLabel={(e)=>`${parseFloat(e.raw)<0?'-':''}£${parseFloat(Math.abs(e.raw)).toFixed(2)}`}
                                                style={{
                                                    height: 300,
                                                }}
                                            />
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <PaddedPaper>
                                            
                                            <LineChart
                                                data={[
                                                        {
                                                            label:'',
                                                            value: {
                                                                [currentYear] : 0,
                                                                [lastYear] :   0,
                                                            },
                                                            color: {
                                                                [currentYear] : colors.primary,
                                                                [lastYear] :    colors.red,
                                                            },
                                                        },
                                                        ..._.map(_.keys(data.b2), idx => {
                                                            let monthData = data.b2[idx];
                                                            let label = months[ parseInt(idx) ];
                                                            let nullMonth = current ? (fmonths.indexOf( label ) <= fmonths.indexOf( months[ parseInt(currentMonth) ]) ? 0 : 1 ) : 0;
                                                            let today = current && label === months[ parseInt(currentMonth) ];
    
                                                            return {
                                                                label: `${label.slice(0,3)}`,
                                                                // label: `${label.slice(0,3)}${(today ? ` (${getOrdinal(moment().format('D'))})` : '')}`,
                                                                value: {
                                                                    [currentYear] : nullMonth ? null : parseFloat(monthData['t']['c']),
                                                                    [lastYear] :   parseFloat(monthData['t'][today ? 'lc' : 'l']),
                                                                },
                                                                color: {
                                                                    [currentYear] : colors.primary,
                                                                    [lastYear] :    colors.red,
                                                                },
                                                                bold: today,
                                                            }
                                                        })
                                                    ]
                                                }
                                                // data={current ? [
                                                //     {
                                                //         label:'Year Start',
                                                //         value: {
                                                //             [currentYear] : 0,
                                                //             [lastYear] :   0,
                                                //         },
                                                //         color: {
                                                //             [currentYear] : colors.primary,
                                                //             [lastYear] :    colors.red,
                                                //         },
                                                //     },
                                                //     ..._.map(_.take(_.keys(data.b2), parseInt( fmonths.indexOf( months[ parseInt(currentMonth) ])) + 1), idx => {
                                                //         let monthData = data.b2[idx];
                                                //         let nullMonth = current ? (fmonths.indexOf( months[ parseInt(idx) ]) <= fmonths.indexOf( months[ parseInt(currentMonth) ]) ? 0 : 1 ) : 0;

                                                //         return {
                                                //             label: months[ parseInt(idx) ],
                                                //             value: {
                                                //                 [currentYear] : nullMonth ? null : parseFloat(monthData['t']['c']),
                                                //                 [lastYear] :   parseFloat(monthData['t']['l']),
                                                //             },
                                                //             color: {
                                                //                 [currentYear] : colors.primary,
                                                //                 [lastYear] :    colors.red,
                                                //             },
                                                //         }
                                                //     }), 
                                                //     // {
                                                //     //         label: getOrdinal(moment().format('D')),
                                                //     //         value: {
                                                //     //             [currentYear] : data.b2[`${currentMonth}`.padStart(2, "0")]['t']['c'],
                                                //     //             [lastYear] :   data.b2[`${currentMonth}`.padStart(2, "0")]['t']['l'],
                                                //     //         },
                                                //     //         color: {
                                                //     //             [currentYear] : colors.primary,
                                                //     //             [lastYear] :    colors.red,
                                                //     //         },
                                                //     // },
                                                //     ..._.map(_.keys(data.b2).splice(parseInt( fmonths.indexOf( months[ parseInt(currentMonth) ]))+1), idx => {
                                                //         let monthData = data.b2[idx];
                                                //         let nullMonth = true;

                                                //         return {
                                                //             label: months[ parseInt(idx) ],
                                                //             value: {
                                                //                 [currentYear] : null,
                                                //                 [lastYear] :   parseFloat(monthData['t']['l']),
                                                //             },
                                                //             color: {
                                                //                 [currentYear] : colors.primary,
                                                //                 [lastYear] :    colors.red,
                                                //             },
                                                //         }
                                                //     }), 
                                                // ] : [
                                                //     {
                                                //         label:'Year Start',
                                                //         value: {
                                                //             [currentYear] : 0,
                                                //             [lastYear] :   0,
                                                //         },
                                                //         color: {
                                                //             [currentYear] : colors.primary,
                                                //             [lastYear] :    colors.red,
                                                //         },
                                                //     },
                                                //     ..._.map(_.keys(data.b2), idx => {
                                                //         let monthData = data.b2[idx];
                                                //         let nullMonth = current ? (fmonths.indexOf( months[ parseInt(idx) ]) <= fmonths.indexOf( months[ parseInt(currentMonth) ]) ? 0 : 1 ) : 0;

                                                //         return {
                                                //             label: months[ parseInt(idx) ],
                                                //             value: {
                                                //                 [currentYear] : nullMonth ? null : parseFloat(monthData['t']['c']),
                                                //                 [lastYear] :   parseFloat(monthData['t']['l']),
                                                //             },
                                                //             color: {
                                                //                 [currentYear] : colors.primary,
                                                //                 [lastYear] :    colors.red,
                                                //             },
                                                //         }
                                                //     })

                                                // ]}
                                                yLabel={i => `${i<0?'-':''}£${Math.abs(i)}`}
                                                tLabel={(e)=>`${parseFloat(e.raw)<0?'-':''}£${parseFloat(Math.abs(e.raw)).toFixed(2)}`}
                                                style={{
                                                    height: 300,
                                                }}
                                                noPoints
                                            />
                                        </PaddedPaper>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

export default SalesReportYear;