import React from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import API from 'API';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';


const initialState = (props) => ({
    formData: {
        contactId: props.update ? props.update.contact_id : null,
        name: props.update ? props.update.contact_name : '',
        position: props.update ? props.update.contact_position : '',
        email: props.update ? props.update.contact_email : '',
        telephone: props.update ? props.update.contact_telephone : '',
        mobile: props.update ? props.update.contact_mobile : ''
    },
    isUpdate: props.update ? true : false,
})

class CreateOrUpdateContactDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    handleChange = ({target:{name, value}}) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        });
    }

    submit = () => {
        API.post('/marketing/contacts', this.state.formData)
        .then(this.props.onClose)
    }

    render() {
        const { formData, isUpdate } = this.state;

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        label='Name *'
                        name='name'
                        value={formData.name}
                        onChange={this.handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='Email *'
                        name='email'
                        value={formData.email}
                        onChange={this.handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='Position'
                        name='position'
                        value={formData.position}
                        onChange={this.handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label='Telephone'
                        name='telephone'
                        value={formData.telephone}
                        onChange={this.handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label='Mobile'
                        name='mobile'
                        value={formData.mobile}
                        onChange={this.handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant='outlined'
                        onClick={this.props.onClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={
                            () => {
                                this.props.deployConfirmation(
                                    'Are you sure you want to ' + (isUpdate ? 'update' : 'submit') + ' this contact?',
                                    (isUpdate ? 'Update' : 'Submit') + ' Contact?',
                                    this.submit
                                )
                            }
                        }
                        disabled={!formData.name || !formData.email}
                    >{isUpdate ? 'Update' : 'Submit'}</Button>
                </Grid>
            </Grid>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback) => {
            dispatch(deployConfirmation(content, title, callback))
        }
    }
}

export default connect(null, mapDispatchToProps)(CreateOrUpdateContactDialog);