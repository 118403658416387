import Typography from '@material-ui/core/Typography';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import IconHelper from 'Helpers/IconHelper';
import React from 'react';
import { connect } from 'react-redux';

class ContactsSystem extends React.Component {

    validateContact = (contact) => {
        API.post(`/marketing/campaigns/mailingLists/${this.props.mailingList.i}/contacts/${contact.mlc_id}/validate`)
        .then(this.props.refresh);
    }

    render () {

        const { mailingList } = this.props;

        return (
            <>
                <Typography variant='body1' gutterBottom
                    style={{marginBottom: '1em'}}
                >
                    These contacts have been flagged as having possible errors with there email address. Please review and update as required.<br/>
                    Please Note: These contacts are still included in the mailing list.
                </Typography>
                <DataTable
                    config={{
                        key: 'i',
                        pagination: mailingList?.c?.w.length >  100,
                        isLoading: this.props.dataLoading
                    }}
                    rows={mailingList?.c?.w}
                    columns={[
                        { 
                            heading: 'Name', 
                            field: 'contact.contact_name', 
                            sizeToContent: true,
                            dataRef: 'contact.contact_name', 
                        },
                        { 
                            heading: 'Company', 
                            field: i => i.contact?.customer?.cust_name ?? '-',
                            truncate: true,
                            dataRef: i => i.contact?.customer?.cust_name ?? '-',
                        },
                        { 
                            heading: 'Email', 
                            field: 'contact.contact_email', 
                            dataRef: 'contact.contact_email', 
                        },
                        {
                            heading: 'System Contact?',
                            field: i => i.contact?.customer?.cust_name ? true : false,
                            dataRef: i => i.contact?.customer?.cust_name ? true : false,
                            sizeToContent: true,
                            alignment: 'center',
                            fieldFormat: 'boolean',
                        },
                        {
                            actions: r => ([
                                {
                                    name: 'Validate',
                                    onClick: (e) =>
                                        this.props.deployConfirmation(
                                            'Are you sure you want to validate this contact?',
                                            'Validate Contact',
                                            ()=>this.validateContact(e)
                                        ),
                                    icon: IconHelper.true,
                                },
                                {
                                    name: 'Edit',
                                    link: `/customers/contacts/update/${r.contact.contact_id}`,
                                    icon: IconHelper.edit,
                                },
                                {
                                    name: 'View',
                                    link: `/customers/contacts/view/${r.contact.contact_id}`,
                                    icon: IconHelper.view,
                                }
                            ])
                        }
                    ]}
                />
            </>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback) => {
            dispatch(deployConfirmation(content, title, callback))
        }
    }
}

export default connect(null, mapDispatchToProps)(ContactsSystem);