import React, { Component } from 'react';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import AllIcon            from 'Components/Common/Icons/AllIcon';
import Image              from 'Components/Common/ImageWithError/ImageWithError';
import Qr                 from 'Components/Common/QrReader/QrReader';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { colors } from 'Helpers/ColourHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deploySnackBar }     from 'Actions/SnackBar/SnackBar';
import { playSound }          from 'Actions/Sounds/Sounds';

import { Button, FormControl, Grid, TextField, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

const initialState = {
    formData: {
        part:   null,
        qty:    0,
        in:     1,
        reason: null,
    },
    activePart: {},
    showQR: true,
    partsAdded:  0
}

class ManualStockTake extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;

    }

    handleNext = () => {
        const { formData } = this.state;
        formData.qty && formData.reason && this.props.addPart(formData);
        this.setState({
            formData:   initialState.formData,
            activePart: initialState.activePart,
            showQR:     true,
        })
    }

    handleClose = () => {
        this.props.addPart(this.state.formData, true);
    }

    handleScan = number => {
        if (number){
            let activePart = _.find(this.props.parts, part => part.part.part_number === number).part;
            if (activePart) {
                this.setState({
                    activePart,
                    showQR: activePart ? false : true,
                    formData: {
                        ...initialState.formData,
                        part: activePart.part_id
                    },
                    partsAdded: this.state.partsAdded + 1,
                    showPartUnavailable: false
                });
            } else {
                this.setState({
                    showPartUnavailable: true
                })
            }
        }
    }

    handleChange = (field, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: value
            }
        });
    }

    render(){
        const {formData, showQR, activePart, partsAdded, showPartUnavailable} = this.state;
        const { classes } = this.props;
        
        return (
            <Grid container justify="center" className={`${classes.root} text-center`} spacing={8}>
                {showQR ?
                    <>
                        <Grid container item className={classes.qrRow}>
                            <Grid item xs={6}>
                                <Qr handleScan={this.handleScan} width="30%" /> 
                                {!!showPartUnavailable && 
                                    <Typography variant="body1" style={{color:colors.red, textAlign: 'left'}}>Part Unavailable</Typography>
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6" className="float-right">
                                    Parts Added {partsAdded}
                                </Typography>
                            </Grid>
                        </Grid> 
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" >
                                Scan a part to add to the stock movement
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                                <Button 
                                    variant="outlined"
                                    endIcon={<AllIcon icon='times' />}
                                    className="mt-3 mr-1"
                                    onClick={this.handleClose}
                                    style={{border: '1px solid black'}}
                                >
                                    close 
                                </Button>
                            </Grid>
                    </>:
                    <>
                        <Grid container item className={classes.qrRow}>
                            <Grid item xs={6}>
                                
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6" className="float-right">
                                    Parts Added {partsAdded}
                                </Typography>
                            </Grid>
                        </Grid> 
                        <Grid item xs={12}>
                            <Grid container justify="center" className="text-center" spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h3">
                                        {activePart.part_number}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {activePart.part_description}<br></br>
                                    </Typography>
                                </Grid>
                                <Grid container justify="center" className="text-center" spacing={3}>
                                    <Grid item xs={12}>
                                        <Image src={activePart.default_image?.file_path} className={classes.img} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="center" className="text-center" spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl style={{width: '50%'}} margin="none">
                                        <AutoCompleteSelect 
                                            options={[
                                                {value: 1, label: 'Stock In'},
                                                {value: 0, label: 'Stock Out'}
                                            ]} 
                                            label='Movement'
                                            onChange={(e)=>{this.handleChange('in', e.value)}}
                                            value={formData.in}
                                            isClearable={false}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl style={{width: '50%'}} margin="none">
                                        <AutoCompleteSelect 
                                            options={this.props.reasons} 
                                            label='Reason'
                                            onChange={(e)=>{this.handleChange('reason', e.value)}}
                                            value={formData.reason}
                                            isClearable={false}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="qty"
                                        name="qty"
                                        label={`Quantity ${formData.in ? 'Added' : 'Removed'} *`}
                                        value={formData.stockQTY}
                                        type="number"
                                        variant="outlined"
                                        onChange={(e)=>{this.handleChange('qty', e.target.value)}}
                                        margin="normal"
                                        className={classes.confirmQtyInputContainer}
                                        inputProps={{
                                            className: classes.confirmQtyInput,
                                            min: 0,
                                        }}
                                        autoFocus
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        
                        <Grid item xs={12} style={{paddingTop: 0}}>
                            <Button 
                                variant="outlined"
                                endIcon={<AllIcon icon='times' />}
                                className="mt-3 mr-1"
                                onClick={this.handleClose}
                                style={{border: '1px solid black'}}
                            >
                                {!!formData.qty && !!formData.reason && 'Add & '}close 
                            </Button>
                            <Button 
                                variant="outlined"
                                endIcon={<AllIcon icon="step-forward" />}
                                className="mt-3 mr-1"
                                onClick={this.handleNext}
                                style={{border: '1px solid black'}}
                                disabled={!formData.qty || !formData.reason}
                            >
                                Add Another
                            </Button>
                        </Grid>
                    </>
                }
            </Grid>
        )
    }
}

const styles = theme => ({
    root: {
        height: '80vh',
        padding: theme.spacing(1),
        maxWidth: '100vw',
        maxHeight: '100vh'
    },
    img: {
        maxHeight: 330,
        maxWidth: '100%'
    },
    qrRow: {
        maxHeight: 60
    },
    qtyRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    actionText: {
        border: `2px dashed ${theme.palette.secondary.main}`,
        padding: 35,
        width: '60%',
        margin: '0 auto'
    },
    connectionText : {
        margin: '1em auto',
        width:  '40%',
        border: `1px solid ${colors.red}`,
    },
    errorText: {
        color: theme.palette.secondary.main
    },
    button: {
        marginTop: 35
    },
    confirmQtyInputContainer: {
        maxWidth: '50%',
    },
    confirmQtyInput: {
        textAlign: 'center',
        maxWidth: '100%',
        padding: 30,
        fontSize: 50,
    },
    largeButton: {
        fontSize: 24
    },
    successTick: {
        color: colors.green
    },
    [theme.breakpoints.down('sm')]: {
        img: {
            maxHeight: 240,
            maxWidth:  240
        },
        actionText: {
            fontSize: '2rem',
        },
        confirmQtyInputContainer: {
            maxWidth: '50%',
        },
        confirmQtyInput: {
            maxWidth: '100%',
            padding: 20,
            fontSize: 30,
        },
        largeButton: {
            fontSize: 18
        }
    }
});

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success, cancel) => {
            dispatch(deployConfirmation(message, title, success, cancel))
        },
        playSound: (type, state) => {
            dispatch(playSound(type, state));
        },
        deploySnackBar: (variant, message) => {
            dispatch(deploySnackBar(variant, message));
        }
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ManualStockTake));