/*
    data: [{
        label: label
        value: value || {label: value}
        color: color || {label: color}
    }]
*/
import _ from 'lodash';
import React from 'react';

import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { colors, getRandomColor } from 'Helpers/ColourHelper';
import { index } from 'mathjs';

Chart.register(...registerables)

const initialState = {
    options: {}
}

class LineChart extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {...initialState, ...this.props};

        this.chartRef = React.createRef();
        this.usedColors = [];
    }

    getData = () => {
        this.usedColors = [];
        const data = this.props.data;
        let   datasets = [];
        if ( _.isObject(_.first(data)?.value)){
            _.each( _.first(data).value, (val, key) => {
                const colors = _.first(data).color;
                let color = colors ? colors[key] : this.getRandomColor();
                datasets.push(
                    {
                        label:              key,
                        data:               [],
                        borderColor:        color, 
                        backgroundColor:    `${color}75`,
                        fill:               this.props.fill || false,
                        tension:            this.props.tension || 0.2,
                        //defaults
                        pointRadius:        _.map(data, i => i.hidePoint ? 0 : _.has(i,'pointRadius') ? i.pointRadius : 3),
                    }
                )
            } )
            _.each(data, i => {
                _.each(i.value, (val, key) => {
                    datasets[ _.findIndex(datasets, {label:key}) ].data.push( parseFloat(val).toFixed(2));
                })
            })
        } else {
            datasets.push({
                label:              this.props.label || '',
                data:               _.map(data, i => i.value),
                borderColor:        _.map(data, i => { return i.color || colors.primary }), 
                backgroundColor:     _.map(data, i => { return `${i.color || colors.primary }75` }), 
                fill:               this.props.fill || false,
                tension:            this.props.tension || 0.2,

                //defaults
                pointRadius:        _.map(data, i => i.hidePoint ? 0 : _.has(i,'pointRadius') ? i.pointRadius : 3),
            })
        }
        return {
            labels: _.map(data, i => i.label), 
            datasets
        }
    }

   
    getRandomColor(){
        let color = (this.props.colors) ?
            this.props.colors[this.usedColors.length] :
            getRandomColor();
        let i = this.usedColors.length;
        while (this.usedColors.includes(color)){
            i++;
            color = (this.props.colors) ?
                this.props.colors[i] :
                getRandomColor();
        }
        this.usedColors.push(color);
        return color;
    }

    getOptions = () => {
        let options = {  
            responsive: true,
            maintainAspectRatio: false,
            plugins:{
                legend: {
                    labels: {
                        textTransform: 'capitalize'
                    }
                }
            }
        };
        if (this.props.onPointClick || this.props.onClick){
            options = {
                ...options,
                onClick: (e, elements) => {
                    if (this.chartRef.current){
                        if (this.props.onClick){
                            this.props.onClick(e,elements)
                        }

                        if (this.props.onPointClick && elements.length > 0) {
                            this.props.onPointClick(
                                {
                                    index: elements[0].index,
                                    data: this.props.data[elements[0].index],
                                }
                            );
                        }
                    }
                }
            }
        }
        if (this.props.yLabel)
            options.scales = {
                ...options.scales,
                y: {
                    ...options.scales?.y,
                    ticks:{
                        ...options.scales?.y?.ticks,
                        callback: (value, index, values) => { return this.props.yLabel(value, index, this.props.data)}
                    }
                }
            };
        if (this.props.xLabel)
            options.scales = {
                ...options.scales,
                xAxis: {
                    ...options.scales?.xAxis,
                    ticks:{
                        ...options.scales?.xAxis?.ticks,
                        callback: (value, index, values) => { return this.props.xLabel(value, index, this.props.data)},
                        autoSkip: false,
                        minRotation: 20
                    }
                    
                },
            };
        
        if (this.props.tLabel){
            options.plugins = {
                ...options.plugins,
                tooltip: {
                    ...options.plugins?.tooltips,
                    callbacks: {
                        ...options.plugins?.tooltips?.callbacks,
                        label: this.props.tLabel
                    }
                },
            }
        }

        if (this.props.hideLegend){
            options.plugins = {
                ...options.plugins,
                legend:{
                    ...options.plugins?.legend,
                    display:false
                }
            }
        }

        if(this.props.noPoints){
            options.elements = {
                ...options?.elements,
                point:{
                    ...options?.elements?.point,
                    radius: 0
                }
            }
        }

        if (this.props.tick){
            options.scales = {
                ...options.scales,
                yAxis: {
                    ...options.scales?.yAxis,
                    ticks: {
                        ...options.scales?.yAxis?.ticks,
                        ...(_.isFunction(this.props.tick) ? {callback: this.props.tick} : {stepSize: this.props.tick})
                    }
                },
            };
        }

        if (_.find(this.props.data, i => i?.bold)){
            options.scales = {
                ...options.scales,
                xAxis: {
                    ...options.scales?.xAxis,
                    ticks:{
                        ...options.scales?.xAxis?.ticks,
                        textStrokeWidth: ({index}) => {
                            return this.props.data[index]?.bold ? 1 : 0;
                        },
                        textStrokeColor: ({index}) => {
                            return this.props.data[index]?.bold ? '#666666' : null;
                        },
                    }
                    
                },
            };
        }

        return options;
    }

    render () {
        return (
            <div style={this.props.style}>
                <Line 
                    data={this.getData()} 
                    options={this.getOptions()}
                    style={this.props.style}
                    ref={this.chartRef}
               />
            </div>
        )
    }

} 



export default LineChart;