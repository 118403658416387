import React from 'react';
import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { Grid, Box, Button } from '@material-ui/core';
import ViewReturn from 'Components/CustomerReturns/CustomerReturns/ViewCustomerReturn/sections/ViewReturn';
import { colors } from 'Helpers/ColourHelper';
import Textarea from 'Components/Common/Inputs/Textarea';

const initialState = {
    isLoading: true,
    batch: null,
    cr: null,
    formData: {
        notes: null
    }
}

class ApproveReturnDialog extends React.Component {
    constructor(props){
        super(props)
        this.state = initialState;

    }

    componentDidMount(){
        this.getCr();
    }

    getCr = () => {
        API.get(`/customerReturns/returns/${this.props.id}`)
        .then(res => {
            this.setState({ 
                cr: res.data, 
                isLoading: false 
            });
        })
    }

    handleChange = name => event => {
        let formData = this.state.formData;
        formData[name] = event.target.value;
        this.setState({ formData });
    }

    render(){
        const { isLoading, cr, formData } = this.state;
        if (isLoading) {
            return (
                <LoadingCircle/>
            )
        }

        return (
            <Box style={{padding: '1em'}}>
                <Grid container spacing={1} >
                    <Grid item xs={12}>
                        <Box
                            style={{
                                border: '1px solid rgb(224, 224, 224)',
                                padding: '1.5em'
                            }}
                        >
                            <ViewReturn
                                cr={cr}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Textarea
                            label='Notes'
                            value={formData.notes}
                            onChange={this.handleChange('notes')}
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant='outlined'
                            onClick={this.props.onClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => this.props.onApproval(formData.notes)}
                            style={{backgroundColor: colors.green, color: colors.white}}
                        >
                            Approve
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )

    }

}

export default ApproveReturnDialog;