import React              from 'react';
import { Link, Redirect } from 'react-router-dom';
import API                from 'API';
import moment             from 'moment';
import _                  from 'lodash';
import { connect }        from 'react-redux';

import { Typography, Grid, FormControl, Input, InputLabel, InputAdornment, FormGroup, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaterialLink from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';

import PaddedPaper     from 'Components/Common/Paper/PaddedPaper';
import ImageWithError  from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle   from 'Components/Common/LoadingCircle/LoadingCircle';
import BackButton      from 'Components/Common/Buttons/BackButton';
import AllIcon         from 'Components/Common/Icons/AllIcon';
import CopyToClipboard from 'Components/Common/CopyToClipboard/CopyToClipboard';

import DesignChangeOrders    from './Sections/DesignChangeOrders';
import Prices                from './Sections/Prices';
import SuppliersAndCosts     from './Sections/SuppliersAndCosts';
import PDFDrawings           from './Sections/PDFDrawings';
import DesignFiles           from './Sections/DesignFiles';
import SubAssemblies         from './Sections/SubAssemblies';
import PartsUsage            from './Sections/PartsUsage';
import PartImages            from './Sections/PartImages';
import PartsPurchaseOrders   from './Sections/PartsPurchaseOrders';
import SupplierStockReturns  from './Sections/SupplierStockReturns';
import Documents             from './Sections/Documents';
import PickingDocuments      from './Sections/PickingDocuments';
import NominalCodes          from './Sections/NominalCodes';
import SpareForParts         from './Sections/SpareForParts';
import SpareParts            from './Sections/SpareParts';
import Stock                 from './Sections/Stock';
import Files3D               from './Sections/Files3D';
import QRCode                from './Sections/QRCode';
import PrintStockLabels      from './Sections/PrintStockLabels';
import BarcodeInformation    from './Sections/BarcodeInformation';
import AssociatedParts       from './Sections/AssociatedParts';
import ElectricalConnections from './Sections/ElectricalConnections';
import PartSales             from './Sections/PartSales';
import PartYoYSales          from './Sections/PartYoYSales';
import WorksOrders           from './Sections/WorksOrders';
import PartStock             from './Sections/PartStock';
import LinkedParts           from './Sections/LinkedParts';
import Packages              from './Sections/Packages';
import Forms                 from './Sections/Forms';

import ViewPartsSold from 'Components/Customers/ViewCustomer/ViewPartsSold';

import logo from 'Assets/Images/clenaware_logo_icon.png';

import { downloadS3File } from 'Functions/MiscFunctions';

import { colors }                           from 'Helpers/ColourHelper';
import icons                                from 'Helpers/IconHelper';    
import { pdfFromBase64, pdfDataFromBase64 } from 'Helpers/PDFHelper';

import { printA4, printLabel, printMulti } from 'Actions/Print/Print';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const styles = theme => ({
    green: {
        color: colors.green
    },
    red: {
        color: colors.red
    },
    ccGroup: {
        paddingBottom: '1px',
        marginBottom: '8px',
        '&:hover': {
            borderBottom: `2px solid ${colors.blue}`,
            paddingBottom: 0
        }
    },
    cc1: {
        paddingLeft: '8px',
        border: '1px solid rgba(212, 213, 231, 1)',
        borderRight: 'none',
        background:'rgba(212, 213, 231, 0.7)',
        '&:before': {
            borderBottom: '1px solid rgba(212, 213, 231, 1)'
        },
        '&:after': {
            borderBottom: '2px solid rgba(212, 213, 231, 1)'
        }
    },
    cc2: {
        paddingLeft: '8px',
        border: '1px solid rgba(182, 215, 235, 1)',
        borderRight: 'none',
        background: 'rgba(182, 215, 235, 0.7)',
        '&:before': {
            borderBottom: '1px solid rgba(182, 215, 235, 1)'
        },
        '&:after': {
            borderBottom: '2px solid rgba(182, 215, 235, 1)'
        }
    },
    cc3: {
        paddingLeft: '8px',
        border: '1px solid rgba(181, 222, 219, 1)',
        background:'rgba(181, 222, 219, 0.7)',
        '&:before': {
            borderBottom: '1px solid rgba(181, 222, 219, 1)'
        },
        '&:after': {
            borderBottom: '2px solid rgba(181, 222, 219, 1)'
        }
    }
});

const initialState = {
    part: {},
    stock: {},
    partId: 0,
    access: {
        partImages: false,
        pdfDrawings: false,
        designFiles: false,
        designChangeOrders: false,
        suppliersCosts: false,
        partsUsage: false,
        subAssemblies: false,
        prices: false,
        partsPurchaseOrders: false,
        supplierStockReturns: false,
        stock: false,
        stockLabels: false,
        documents: false,
        nominalCodes: false,
        files3D: false,
        spareForParts: false,
        spareParts: false,
        assocParts: false,
        electricalConnections: false,
        linkedParts: false,
        pickingDocuments: false,
        masterExcel: false,
        forms: false,
        soldParts: false,
        yoysReport: false
    },
    isLoading: true,
    redirect: false
}
class ViewPart extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `Parts:${this.props.id ? this.props.id : this.props.match.params.id}`;
        this.state              = this.getInitialState({...initialState,partId: this.props.id ? this.props.id : this.props.match.params.id});
    }

    componentDidUpdate(prevProps){
        if(prevProps.id) {
            if (this.props.id !== prevProps.id) {
                this.setState({
                    isLoading: true,
                    partId: this.props.id
                }, () => {
                    this.getPartDetails();
                    this.props.scrollToTop();
                })
            }
        } else {
            if (this.props?.match?.params.id !== prevProps?.match?.params.id) {
                this.setState({
                    isLoading: true,
                    partId: this.props?.match?.params.id
                }, () => {
                    this.getPartDetails();
                    this.props.scrollToTop();
                })
            }
        }
    }

    componentDidMount(){
        if (!this.hasPageState()) { this.getPartDetails(); } else { this.setState({isLoading: false}); }
    }

    getPartDetails = () => {
        let partId = this.state.partId
        this.setState({
            isLoading: true
        },
        () => {
            Promise.all([ 
                API.get(`/parts/${partId}`), 
                API.get(`/researchDevelopment/changeOrders/part/${partId}/actualIssue`),
                API.get(`/stock/${partId}`),
                API.get(`/partsOrdering/partsOnOrder/${partId}`),
            ])
            .then(([partRes, issueRes, stockRes, ppoRes]) =>  {
                if (partRes.data.errors) {
                    this.setState({ redirect: true })
                } else {
                    let partDetails = partRes.data;
                    // Issue
                    partDetails.version_match_dco = 'No';
                    partDetails.actual_part_status = issueRes.data;
                    if ((partDetails.part_issue_status_pdf === partDetails.part_issue_status_design) && (partDetails.part_issue_status_pdf === partDetails.actual_part_status))
                    { 
                        partDetails.version_match_dco = 'Yes';
                    }
                    else if ((partDetails.part_issue_status_pdf > 0) && (partDetails.part_issue_status_design === 0) && (partDetails.part_issue_status_pdf === partDetails.actual_part_status))
                    {
                        partDetails.version_match_dco = 'Yes';
                    }
                    else if ((partDetails.part_issue_status_pdf === 0) && (partDetails.part_issue_status_design > 0) && (partDetails.part_issue_status_design === partDetails.actual_part_status))
                    {
                        partDetails.version_match_dco = 'Yes';
                    }
                    partDetails.issue_status_pdf = partDetails && partDetails.part_issue_status_pdf > 0 ? 'v' + partDetails.part_issue_status_pdf : '-';
                    partDetails.issue_status_design = partDetails && partDetails.part_issue_status_design > 0 ? 'v' + partDetails.part_issue_status_design : '-';
                    partDetails.actual_part_status = issueRes.data && issueRes.data > 1 ? 'v' + issueRes.data : 'v1';
                    
                    // Stock
                    let currentCostPerUnit      = (stockRes.data && stockRes.data.part && stockRes.data.part.default_cost && stockRes.data.part.default_cost.cost_per_unit) ? stockRes.data.part.default_cost.cost_per_unit : 0;
                    let currentStockCurrentQty  = stockRes.data && stockRes.data.stock_current_qty ? stockRes.data.stock_current_qty : 0;
                    let currentStockValue       = (currentCostPerUnit * currentStockCurrentQty);
                    let stock = {
                        currentStockQty: currentStockCurrentQty,
                        currentStockValue: currentStockValue,
                        onOrderQty: ppoRes.data.onOrderQty,
                        onOrderValue: ppoRes.data.onOrderValue,
                        totalQty: (currentStockCurrentQty + ppoRes.data.onOrderQty),
                        totalValue: (currentStockValue + ppoRes.data.onOrderValue)
                    }
    
                    this.setState({
                        part: partDetails,
                        stock: stock
                    }, 
                    () => {
                        this.checkAccess();
                    });
                }
            });
        });
    }

    downloadStockLabel = (type, print=false) => {
        API.get('/parts/' + this.props.match.params.id + '/stockLabel', {
            params: {
              type: type
            }
        })
        .then(result => {
            if(result.data) {
                if (print){
                    if (type === 'partLabel'){
                        this.props.printLabel(pdfDataFromBase64(result.data.pdf))
                    } else {
                        this.props.printA4(pdfDataFromBase64(result.data.pdf))
                    }
                } else {
                    pdfFromBase64(result.data.pdf, 'stock_label_'+this.state.part.part_number+'.pdf');
                }
            }
        });
    }

    checkAccess = () => {
        API.multiAccess([ 
            'view-part:part-images', 
            'view-part:pdf-drawings',
            'view-part:design-files',
            'view-part:design-change-orders',
            'view-part:suppliers-costs',
            'view-part:parts-usage',
            'view-part:sub-assemblies',
            'view-part:prices',
            'view-part:parts-purchase-orders',
            'view-part:supplier-stock-returns',
            'view-part:stock',
            'view-part:stock-labels',
            'view-part:documents',
            'view-part:nominal-codes',
            'view-part:three-dimensional-printing',
            'view-part:used-as-a-spare-part-in',
            'view-part:available-spare-parts',
            'view-part:associated-parts',
            'view-part:electrical-connections',
            'electrical-connections',
            'view-part:sales-reports',
            'view-part:works-order',
            'view-part:stock-reports',
            'view-part:linked-parts',
            'view-part:picking-documents',
            'view-part:packages',
            'master-excel',
            'view-part:forms',
            'view-part:sold-parts',
            'view-part:yoy-sales-reports'
        ])
        .then(([partImages, pdfDrawings, designFiles,designChangeOrders,suppliersCosts, partsUsage,subAssemblies,prices,partsPurchaseOrders,supplierStockReturns,stock,stockLabels,doc,
                nominalCodes, files3D, spareForParts, spareParts, assocParts, ecParts, vecParts, salesReport, worksOrders, stockReports, linkedParts, pickingDocuments, packages, masterExcel,
            forms, soldParts, yoysReport]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    partImages:                partImages          ,
                    pdfDrawings:               pdfDrawings         ,
                    designFiles:               designFiles         ,
                    designChangeOrders:        designChangeOrders  ,
                    suppliersCosts:            suppliersCosts      ,
                    partsUsage:                partsUsage          ,
                    subAssemblies:             subAssemblies       ,
                    prices:                    prices              ,
                    partsPurchaseOrders:       partsPurchaseOrders ,
                    supplierStockReturns:      supplierStockReturns,
                    stock:                     stock               ,
                    stockLabels:               stockLabels         ,
                    documents:                 doc                 ,
                    nominalCodes:              nominalCodes        ,
                    files3D:                   files3D             ,
                    spareForParts:             spareForParts       ,
                    spareParts:                spareParts          ,
                    assocParts:                assocParts          ,
                    electricalConnections:     ecParts             ,
                    viewElectricalConnections: vecParts            ,
                    salesReport:               salesReport         ,
                    worksOrders:               worksOrders         ,
                    stockReports:              stockReports        ,
                    linkedParts:               linkedParts         ,
                    pickingDocuments:          pickingDocuments    ,
                    packages:                  packages            ,
                    masterExcel:               masterExcel         ,
                    forms:                     forms               ,
                    soldParts:                 soldParts           ,
                    yoysReport:                yoysReport          
                },
                isLoading: false
            });
        });
    }

    downloadPartLabel = () => {
        API.get('/parts/' + this.props.match.params.id + '/partLabel')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, 'part_label_'+this.state.part.part_number+'.pdf');
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { part, access, stock, isLoading } = this.state;
        if (this.state.redirect) {
            return <Redirect to='/parts/search'/>
        } else {
            return (
                <>
                    {!!part.part_locked &&
                        <Grid container style={{padding:'1em', color:colors.white, backgroundColor: colors.orange, margin: '-1.4em 0 1em -1.8em', width: 'calc(100% + 3.6em)' }}>
                            <Grid item><AllIcon icon={icons.lock} color={colors.white} heavy/></Grid>
                            <Grid item><Typography style={{fontWeight:'bold'}}>Part Is Locked</Typography></Grid>
                        </Grid>
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                View Part
                            </Typography>
                        </Grid>
                        {(isLoading && (
                            <Grid item xs={12}>
                                <LoadingCircle />
                            </Grid>
                        )) || (
                            <React.Fragment>
                                <Grid container item spacing={3}>
                                    <Grid item xs={12} lg={6}>
                                        <PaddedPaper>
                                            <Grid item xs={12}>
                                                <Grid container item >
                                                    <Grid item xs={12} lg={3}>
                                                        {part && part.default_image && part.default_image.thumbnail_file_path ? 
                                                            <Link to={{pathname: '/library/parts/search', state: { part: part.part_id } }}>
                                                                <ImageWithError src={part.default_image.thumbnail_file_path} style={{height:'auto', maxHeight: 120, maxWidth: '100%'}} />
                                                            </Link>
                                                        : 
                                                            <img src={logo} alt="logo"/>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} lg={9}>
                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="partNumber" shrink={!!part.part_number}>Part Number</InputLabel>
                                                            <Input 
                                                                id="partNumber" 
                                                                value={part.part_number || ''} 
                                                                endAdornment={
                                                                    <InputAdornment style={{paddingBottom: 20, paddingRight: 10}}>
                                                                        <CopyToClipboard field="partNumber" />
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </FormControl>
                                                        <FormControl margin="normal" fullWidth>
                                                            <InputLabel htmlFor="description" shrink={!!part.part_description} >Description</InputLabel>
                                                            <Input 
                                                                id="description" 
                                                                value={part.part_description || ''}
                                                                endAdornment={
                                                                    <InputAdornment style={{paddingBottom: 20, paddingRight: 10}}>
                                                                        <CopyToClipboard field="description" />
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <form noValidate autoComplete="off">
                                                    <Grid container item spacing={3}>
                                                        <Grid item xs={12} lg={6}>
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel htmlFor="status" shrink={!!part.part_status}>Status</InputLabel>
                                                                <Input id="status" value={part.part_status+ ' Part'} style={{color: part.part_status === 'Active' ? colors.green: colors.red}} />
                                                            </FormControl>
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel htmlFor="partType" shrink={!!(part.part_type && part.part_type.part_type_name)}>Part Type</InputLabel>
                                                                <Input id="partType" value={(part.part_type && part.part_type.part_type_name) || ''} />
                                                            </FormControl>
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel htmlFor="buildType" shrink={!!(part.build_type && part.build_type.build_type_name)}>Build Type</InputLabel>
                                                                <Input id="buildType" value={(part.build_type && part.build_type.build_type_name) || ''} />
                                                            </FormControl>
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel htmlFor="sparePart" shrink={!!part.part_spare_part}>Spare Part</InputLabel>
                                                                <Input id="sparePart" value={part.part_spare_part || ''} />
                                                            </FormControl>
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel htmlFor="electricalConnections" shrink={!!part.version_match_dco}>Electrical Connections</InputLabel>
                                                                <Input id="electricalConnections" value={part.electrical_connections.length ? 'Yes' : 'No'} />
                                                            </FormControl>
                                                            {part.part_product === 'Yes' && 
                                                                <FormControl margin="normal" fullWidth>
                                                                    <InputLabel htmlFor="exemptFromStock"> Exempt From Stock Take</InputLabel>
                                                                    <Input id="exemptFromStock" value={part.part_exempt_from_stock ? 'Yes' : 'No'} />
                                                                </FormControl>
                                                            }
                                                            {part.part_product === 'Yes' && !part.part_exempt_from_stock &&
                                                                <FormControl margin="normal" fullWidth>
                                                                    <InputLabel htmlFor="exemptFromStock"> Requires Stock Movement</InputLabel>
                                                                    <Input id="exemptFromStock" value={part.part_requires_stock_movement ? 'Yes' : 'No'} />
                                                                </FormControl>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <FormControl margin="normal" fullWidth>
                                                                        <InputLabel htmlFor="Replaced" shrink={!!part.part_status}>Replaced By</InputLabel>
                                                                        {part.part_status === 'Replaced' && part.replaced_part ?
                                                                            <Input id="Replaced" value={`${part.replaced_part.part_number} - ${part.replaced_part.part_description}`} 
                                                                                endAdornment={
                                                                                    <AllIcon icon={icons.search} noMargin noPadding onClick={()=>this.props.history.push(`/parts/view/${part.replaced_part.part_id}`)}/>
                                                                                }
                                                                            /> : 
                                                                            <Input id="Replaced" value='-'/>
                                                                        }
                                                            </FormControl>
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel htmlFor="status" shrink={!!part.part_status}>Part Category</InputLabel>
                                                                <Input id="status" value={part.category?.pc_name || 'Uncategorised'} />
                                                            </FormControl>
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel htmlFor="dateAdded" shrink={!!part.part_date_added}>Point of Introduction</InputLabel>
                                                                <Input id="dateAdded" value={moment(part.part_date_added).format('DD/MM/YYYY')} />
                                                            </FormControl>
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel htmlFor="actualPartStatus" shrink={!!part.actual_part_status}>Actual Part Status</InputLabel>
                                                                <Input id="actualPartStatus" value={part.actual_part_status || ''} />
                                                            </FormControl>
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel htmlFor="versionMatch" shrink={!!part.version_match_dco}>Version Match (DCO to Files)</InputLabel>
                                                                <Input id="versionMatch" value={part.version_match_dco || ''} className={part.version_match_dco === 'Yes' ? classes.green : classes.red} />
                                                            </FormControl>
                                                            {part.part_product === 'Yes' && !!!part.part_exempt_from_stock &&
                                                                <FormControl margin="normal" fullWidth>
                                                                    <InputLabel htmlFor="stockThreshold">Stock Take Threshold</InputLabel>
                                                                    <Input id="stockThreshold" value={`${part.part_stock_threshold}%`}/>
                                                                </FormControl>
                                                            }
                                                            {!this.props.dialog &&
                                                                <div className='buttonRow' style={{marginTop:'2em'}}>
                                                                    <BackButton props={this.props} />
                                                                </div>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    
                                                </form>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <PaddedPaper>
                                            <form noValidate autoComplete="off">
                                                <Typography variant="body2" gutterBottom>
                                                    <b>Part Files</b>
                                                </Typography>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel htmlFor="pdfDrawing" shrink={!!part.part_pdf_drawing}>PDF Drawing</InputLabel>
                                                    <Input id="pdfDrawing" value={part.part_pdf_drawing || ''} />
                                                </FormControl>
                                                    <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                        {access.pdfDrawings ?
                                                            (part.pdf_drawing && part.pdf_drawing.part_doc_url &&
                                                                <MaterialLink variant="body1" className='blueLink' onClick={() => downloadS3File(part.pdf_drawing.url)}>
                                                                    {part.pdf_drawing.part_doc_url}
                                                                </MaterialLink>
                                                            ) :
                                                            ( ( part.pdf_drawing && part.pdf_drawing.part_doc_url ) &&
                                                                part.pdf_drawing.part_doc_url
                                                            )
                                                        }   
                                                    </div>
                                                {part.issue_status_pdf && 
                                                    <FormControl margin="normal" fullWidth>
                                                        <InputLabel htmlFor="issuePdf" shrink={!!part.issue_status_pdf}>Issue Status PDF</InputLabel>
                                                        <Input id="issuePdf" value={part.issue_status_pdf || ''} />
                                                    </FormControl>
                                                }
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel htmlFor="designFile" shrink={!!part.part_design_file}>Design File</InputLabel>
                                                    <Input id="designFile" value={part.part_design_file || ''} />
                                                </FormControl>
                                                <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    {access.designFiles ? (
                                                        part.current_part_file_design && part.current_part_file_design.part_doc_url &&
                                                            <MaterialLink variant="body1" className='blueLink' onClick={() => downloadS3File(part.current_part_file_design.file_path)}>
                                                                {part.current_part_file_design.part_doc_url}
                                                            </MaterialLink>
                                                        ) : ( ( part.current_part_file_design && part.current_part_file_design.part_doc_url ) && part.current_part_file_design.part_doc_url )
                                                    }
                                                </div>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel htmlFor="issueDesign" shrink={!!part.issue_status_design}>Issue Status Design File</InputLabel>
                                                    <Input id="issueDesign" value={part.issue_status_design || ''} />
                                                </FormControl>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel shrink={!!part.part_file_technical_library_access}>Available in Technical Library</InputLabel>
                                                    <Input value={part.part_file_technical_library_access || ''} />
                                                </FormControl>
                                            </form>
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <PaddedPaper>
                                            <Typography variant="body2">
                                                <b>Commodity Codes</b>
                                            </Typography>
                                            <br />
                                            <Grid container item>
                                                <Grid item xs={12}>
                                                    <FormGroup className={classes.ccGroup} >
                                                        <InputLabel htmlFor="importCommodity1" shrink={true}>Import</InputLabel>
                                                        <div style={{display:'flex-inline', marginTop:20}}>
                                                            <Input
                                                                className={classes.cc1}
                                                                value={part.part_import_commodity_code.substr(0,2)}
                                                                variant="filled"
                                                                inputProps={{
                                                                    size:2,
                                                                    maxLength:2,
                                                                    style: {
                                                                        letterSpacing: 8
                                                                    }
                                                                }}
                                                            />
                                                            <Input
                                                                className={classes.cc2}
                                                                value={part.part_import_commodity_code.substr(2,2)}
                                                                variant="filled"
                                                                inputProps={{
                                                                    size:2,
                                                                    maxLength:2,
                                                                    style: {
                                                                        letterSpacing: 8
                                                                    }
                                                                }}
                                                            />
                                                            <Input
                                                                className={classes.cc3}
                                                                value={part.part_import_commodity_code.substr(4,6)}
                                                                variant="filled"
                                                                inputProps={{
                                                                    size:11,
                                                                    maxLength:6,
                                                                    style: {
                                                                        letterSpacing: 8
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormGroup className={classes.ccGroup} >
                                                        <InputLabel htmlFor="exportCommodity1" shrink={true}>Export</InputLabel>
                                                        <div style={{display:'flex-inline', marginTop:20}}>
                                                            <Input
                                                                className={classes.cc1}
                                                                value={part.part_export_commodity_code.substr(0,2)}
                                                                variant="filled"
                                                                inputProps={{
                                                                    size:2,
                                                                    maxLength:2,
                                                                    style: {
                                                                        letterSpacing: 8
                                                                    }
                                                                }}
                                                            />
                                                            <Input
                                                                className={classes.cc2}
                                                                value={part.part_export_commodity_code.substr(2,2)}
                                                                variant="filled"
                                                                inputProps={{
                                                                    size:2,
                                                                    maxLength:2,
                                                                    style: {
                                                                        letterSpacing: 8
                                                                    }
                                                                }}
                                                            />
                                                            <Input
                                                                className={classes.cc3}
                                                                value={part.part_export_commodity_code.substr(4,4)}
                                                                variant="filled"
                                                                inputProps={{
                                                                    size:6,
                                                                    maxLength:4,
                                                                    style: {
                                                                        letterSpacing: 8
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                        <br />
                                        <PaddedPaper>
                                            <form noValidate autoComplete="off">
                                                <Typography variant="body2" gutterBottom>
                                                    <b>Serial Numbers / Warranty</b>
                                                </Typography>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel shrink={!!part.part_warranty_void_label}>Warranty Void Label</InputLabel>
                                                    <Input value={part.part_warranty_void_label || ''} />
                                                </FormControl>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel shrink={!!part.part_warranty_terms}>Warranty Terms</InputLabel>
                                                    <Input value={part.warranty_terms.pwt_name || ''} />
                                                </FormControl>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel shrink={!!part.part_req_serial_number}>Serial Number Required</InputLabel>
                                                    <Input value={ (part.part_req_serial_number === 'No' || !part.part_req_serial_number) ? 'No' : 
                                                        ( part.part_req_serial_number === 'Yes' ? 'Yes - System Generated' : 'Yes - Manual') } />
                                                </FormControl>
                                            </form>
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <PaddedPaper>
                                            <Typography variant="body2">
                                                <b>Part Weight &amp; Dimensions</b>
                                            </Typography>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={!!part.part_weight}>Part Weight</InputLabel>
                                                <Input value={(parseFloat((part.part_weight / 1000).toFixed(7).toString()) + ' kg')   +  ' / ' +  (parseFloat((part.part_weight).toString()) + ' g')} />
                                            </FormControl>
                                            <Grid container item spacing={3}>
                                                <Grid item xs={12} lg={4}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <InputLabel shrink={!!part.part_dimensions_width}>Width</InputLabel>
                                                        <Input value={part.part_dimensions_width || ''} endAdornment={<InputAdornment position="end">mm</InputAdornment>} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <InputLabel shrink={!!part.part_dimensions_height}>Height</InputLabel>
                                                        <Input value={part.part_dimensions_height || ''} endAdornment={<InputAdornment position="end">mm</InputAdornment>} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <InputLabel shrink={!!part.part_dimensions_depth}>Depth</InputLabel>
                                                        <Input value={part.part_dimensions_depth || ''} endAdornment={<InputAdornment position="end">mm</InputAdornment>} />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <PaddedPaper>
                                            <Typography variant="body2">
                                                <b>Shipping Weight &amp; Dimensions</b>
                                            </Typography>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={!!part.part_shipping_weight}>Shipping Weight</InputLabel>
                                                <Input value={(parseFloat((part.part_shipping_weight / 1000).toFixed(7).toString()) + ' kg')   +  ' / ' +  (parseFloat((part.part_shipping_weight).toString()) + ' g')} />
                                            </FormControl>
                                            <Grid container item spacing={3}>
                                                <Grid item xs={12} lg={4}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <InputLabel shrink={!!part.part_shipping_dimensions_width}>Width</InputLabel>
                                                        <Input value={part.part_shipping_dimensions_width || ''} endAdornment={<InputAdornment position="end">mm</InputAdornment>} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <InputLabel shrink={!!part.part_shipping_dimensions_height}>Height</InputLabel>
                                                        <Input value={part.part_shipping_dimensions_height || ''} endAdornment={<InputAdornment position="end">mm</InputAdornment>} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <InputLabel shrink={!!part.part_shipping_dimensions_depth}>Depth</InputLabel>
                                                        <Input value={part.part_shipping_dimensions_depth || ''} endAdornment={<InputAdornment position="end">mm</InputAdornment>} />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <PaddedPaper>
                                            <Typography variant="body2">
                                                <b>Sales</b>
                                            </Typography>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={!!part.part_product}>Product/Service</InputLabel>
                                                <Input value={(part.part_product === 'Yes' ? 'Product' : 'Service') || ''} />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={!!part.part_discount}>Part Discount</InputLabel>
                                                <Input value={part.part_discount || ''} />
                                            </FormControl>
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <PaddedPaper>
                                            <Typography variant="body2" gutterBottom>
                                                <b>E-Commerce</b>
                                            </Typography>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={true}>E-Commerce Product</InputLabel>
                                                <Input value={part.ecommerce ? 'Yes' : 'No'} />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel shrink={!!part.ecommerce?.categories}>E-Commerce Categories</InputLabel>
                                                <Input value={_.map(part.ecommerce?.categories, 'ecom_cat_name').join(', ') || ''} />
                                            </FormControl>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>

                                    {/* Alphabetical order */}
                                    {access.files3D && <Files3D partId={this.state.partId} />}

                                    {access.assocParts && <AssociatedParts parts={this.state.part.associated_parts} associatedPartFor={this.state.part.associated_part_for} originalPart={part.part_number} />}
                                    {access.spareParts && <SpareParts parts={this.state.part.spare_parts} originalPart={part.part_number +' - '+part.part_description} />}

                                    <BarcodeInformation barcodeNumber={part.part_barcode_number} barcodeURL={part.barcode_url} />

                                    {access.designChangeOrders && <DesignChangeOrders partId={this.state.partId} />}
                                    {access.designFiles && <DesignFiles partId={this.state.partId} />}
                                    {access.documents && <Documents partId={this.state.partId} />}
                                    
                                    {access.electricalConnections && <ElectricalConnections ecs={this.state.part.electrical_connections} originalPart={part.part_number +' - '+part.part_description} viewElectricalConnections={access.viewElectricalConnections} />}
                                    
                                    {access.forms && <Forms partId={this.state.partId} originalPart={part.part_number} /> }
                                    
                                    {access.pickingDocuments && <PickingDocuments partId={this.state.partId} />}
                                    {access.linkedParts && <LinkedParts part={part} />}
                                    
                                    {access.nominalCodes && <NominalCodes nominalCodes={this.state.part.nominal_codes} />}

                                    {access.partImages  && <PartImages partId={this.state.partId} />}
                                    {access.partsPurchaseOrders && <PartsPurchaseOrders partId={this.state.partId} />}
                                    {access.partsUsage && <PartsUsage partId={this.state.partId} />}
                                    {access.pdfDrawings && <PDFDrawings partId={this.state.partId} />}
                                    {access.prices &&<Prices partId={this.state.partId} partDiscount={part.part_discount} />}
                                    {access.stockLabels && <PrintStockLabels downloadStockLabel={this.downloadStockLabel} />}

                                    <QRCode qrCode={part.part_qr_code} />

                                    {access.packages && <Packages packages={part.packages} />}
                                    {access.salesReport  && <PartSales history={this.props.history} partId={this.state.partId} /> }
                                    {access.yoysReport  && <PartYoYSales 
                                        history={this.props.history} 
                                        partId={this.state.partId} 
                                        startYear={moment(part.part_date_added).format('YYYY')}    
                                    /> }
                                    {access.stock && part.part_product === 'Yes' && part.part_exempt_from_stock === 0 && <Stock stock={stock} partId={this.state.partId} />}
                                    {access.stockReports && <PartStock partId={this.state.partId} /> }
                                    {access.subAssemblies && <SubAssemblies partId={this.state.partId} showMasterExcel={access.masterExcel}/>}
                                    {access.supplierStockReturns && <SupplierStockReturns partId={this.state.partId} />}
                                    {access.suppliersCosts && <SuppliersAndCosts partId={this.state.partId} />}
                                    
                                    {access.spareForParts && <SpareForParts parts={this.state.part.spare_for_parts} originalPart={part.part_number +' - '+part.part_description} />}

                                    {access.worksOrders && <WorksOrders partId={this.state.partId} />}
                                    {access.soldParts && 
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography>Sold Parts</Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <ViewPartsSold pid={this.state.partId} />
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    }                                          
                                    {/*{access.stock && part.part_product === 'Yes' && part.part_exempt_from_stock === 0 && <WriteOffs partId={this.state.partId}/>}*/}
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </>
            );
        }
    }
}

const mapDispatchToProps = dispatch => ({
    printA4:    (data) => dispatch(printA4(data)),
    printLabel: (data) => dispatch(printLabel(data)),
    printMulti: (data) => dispatch(printMulti(data)),
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(ViewPart));

