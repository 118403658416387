import React  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import {
    Button,
    Grid,
    TextField,
    Typography,
    DialogActions,
} from '@material-ui/core';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { handleDateChange, getFormData, handleChange } from 'Functions/FormFunctions';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { toggleDialog } from 'Functions/MiscFunctions';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DatePicker from 'Components/Common/DatePickers/DatePicker';

const initialState = () => ({
    allocationId: 0,
    allocation: {},
    formData: {
        startDate: null,
        allocateTo: '',
        allocateToId: 0,
        overnightPostcode: '',
    },
    formErrors: [],
    isLoading: true,
    staffList: [],
    engineerList: [],
});

class VehicleAllocationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleDateChange = handleDateChange.bind(this);
        this.getFormData = getFormData.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
        this.handleChange = handleChange.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData(); 
    }

    loadComponentData = () => {

        const { allocationId } = this.props;

        this.setState({
            ...this.state,
            allocationId
        },
        () => {
            if (this.state.allocationId) {
                this.getAllocation();
            }
            else {
                this.populateLists();
            }
        });
    }

    getAllocation = () => {
        this.setState({
            isLoading: true,
        }, 
        () => {
            API.get(`/vehicles/${this.props.vehicleId}/allocations/${this.state.allocationId}`)
            .then(allocationRes => {
    
                let al = allocationRes.data;
    
                this.setState({
                    ...this.state,
                    allocation: al,
                    formData: {
                        ...this.state.formData,
                        startDate: al?.startDate !== '0000-00-00' ? al?.startDate : null,
                        endDate: al?.endDate !== '0000-00-00' ? al?.endDate : null,
                        allocateTo: al?.allocateTo,
                        allocateToId: al?.allocateDetails?.id,
                        overnightPostcode: al?.overnightPostcode
                    }
                },
                () => {
                    // Get the current staff id / engineer id - to populate the lists incase they are inactive
                    this.populateLists(al?.allocateTo === 'Staff Member' ? al?.allocateDetails?.id : 0, al?.allocateTo === 'Engineer' ? al?.allocateDetails?.staffId : 0);
                });
            });
        });
    }

    populateLists = (staffId = 0, engineerStaffId = 0) => {
        Promise.all([
            API.get('/engineers', { 
                params: { 
                    active: true,
                    currentStaffId: engineerStaffId,
                    forList: true,
                    internalOnly: true
            }}),
            API.get('/staff/all', { 
                params: { 
                    active: true,
                    currentStaffId: staffId
                } 
            })
        ])
        .then(([engineersRes, result]) => {

            let engineerList = _.map(engineersRes.data, (en) => {
                return _.assign({
                    value: en?.id,
                    label: en?.engineer?.fn + ' ' + en?.engineer?.ln
                });
            });
            let staffList = _.map(result.data, (staff) => {
                return _.assign({
                    value: staff.staff_id,
                    label: staff.staff_first_name + ' ' + staff.staff_last_name
                });
            });

            this.setState({
                ...this.state,
                staffList,
                engineerList,
                isLoading: false,
            });
        });
    }

    handleSubmit = () => {
        const { allocationId } = this.state;
        const route = allocationId ? `/vehicles/${this.props.vehicleId}/allocations/${allocationId}` : `/vehicles/${this.props.vehicleId}/allocations`;

        API.post(route, this.getFormData())
        .then(res => {
            if(res.data.errors && res.data.errors.length > 0) {
                this.setState({
                    formErrors: formatValidationErrors(res.data.errors)
                });
            } else {
                this.setState({
                    ...initialState(),
                }, () => {
                    this.props.deploySnackBar("success", `The allocation was successfully ${allocationId ? 'updated' : 'added'}`);
                    this.props.refresh();
                })
            }
        });
    }

    handleSelectChange(fieldName, selectedOption) {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, () => {
            if(fieldName === 'allocateTo') {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        allocateToId: ''
                    }
                });
            }
        });
    }

    render() {
        const { formErrors, formData, isLoading, allocationId, staffList, engineerList } = this.state;
        return (   
            <>   
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {allocationId ? 'Update Allocation' : 'New Allocation'}
                    </Typography>
                </Grid>     
                <Grid item xs={12}>
                    <form noValidate>
                        {(isLoading && (
                            <LoadingCircle />          
                        )) || (
                            <Grid container item spacing={2}>
                                <Grid item xs={12}>
                                    {formErrors && formErrors.generic && (
                                        <Typography component={"div"} style={{color: 'red'}}>
                                            {formErrors.generic}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePicker
                                        type="date"
                                        id="startDate"
                                        name="startDate"
                                        label={"Start Date " + (allocationId ? '' : '*')}
                                        value={formData.startDate}
                                        errorText={formErrors && formErrors['startDate']}
                                        onChange={date => this.handleDateChange('startDate', date, 'YYYY-MM-DD')}
                                        autoOk={true}
                                        inputProps={{
                                            className:"textDefault"
                                        }}
                                    />
                                </Grid>
                                {allocationId && formData.endDate !== null ?
                                    <Grid item xs={12}>
                                        <DatePicker
                                            type="date"
                                            id="endDate"
                                            name="endDate"
                                            label={"End Date " + (allocationId ? '' : '*')}
                                            value={formData.endDate}
                                            errorText={formErrors && formErrors['endDate']}
                                            onChange={date => this.handleDateChange('endDate', date, 'YYYY-MM-DD')}
                                            autoOk={true}
                                            inputProps={{
                                                className:"textDefault"
                                            }}
                                        />
                                    </Grid>
                                : null }
                                <Grid item xs={12}>
                                    <AutoCompleteSelect
                                        label={"Allocate To " + (allocationId ? '' : '*')}
                                        value={formData.allocateTo}
                                        options={[
                                            {value: 'Pool Vehicle', label: 'Pool Vehicle'},
                                            {value: 'Staff Member', label: 'Staff Member'},
                                            {value: 'Engineer', label: 'Engineer'},
                                        ]}
                                        onChange={v => this.handleSelectChange('allocateTo', v)}
                                        noClear
                                        creatable
                                        fullWidth
                                        error={formErrors && formErrors['allocateTo'] && true}
                                        errorText={formErrors && formErrors['allocateTo']}
                                        disabled={allocationId ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {formData.allocateTo === 'Staff Member' || formData.allocateTo === 'Engineer' ?
                                        <AutoCompleteSelect 
                                            name="allocateToId"
                                            options={formData.allocateTo === 'Staff Member' ? staffList : (formData.allocateTo === 'Engineer' ? engineerList : [])} 
                                            label='Name'
                                            value={formData?.allocateToId}
                                            onChange={(v) => this.handleSelectChange('allocateToId', v)}
                                            fullWidth
                                            disabled={allocationId ? true : false}
                                        />
                                    : null
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        name="overnightPostcode"
                                        label={"Overnight Postcode " + (allocationId ? '' : '*')}
                                        onChange={this.handleChange}
                                        error={formErrors && formErrors['overnightPostcode'] && true}
                                        helperText={formErrors && formErrors['overnightPostcode']}
                                        value={formData?.overnightPostcode}
                                        margin="none"
                                        fullWidth
                                        inputProps={{
                                            className:"textDefault"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} align="right">
                                    <DialogActions className='pr-0 pb-0'>
                                        <Button onClick={() => this.props.onClose()} variant="text">
                                            <AllIcon icon="times" size={15} />
                                            Close
                                        </Button>
                                        <Button 
                                            onClick={() => this.props.deployConfirmation(`Are you sure you want to ${allocationId ? 'update' : 'add'} this allocation?`, `${allocationId ? 'Update' : 'Add'} Allocation?`, this.handleSubmit)}
                                            variant="contained"
                                            color="primary"
                                        >
                                            {allocationId ? 'Update' : 'Add'}
                                        </Button>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        )}
                    </form>
                </Grid>  
            </Grid>
            </>  
        );
    }
}

const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
    }
)

export default connect(null, mapDispatchToProps)(VehicleAllocationForm);