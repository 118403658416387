import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import API from '../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import { MAX_UPLOAD_LIMIT_SIZE } from './../../../Constants';
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData: {
        categoryId: '',
        categoryName: '',
        imageFile: '',
        imageFileRaw: '',
        image3DFileObj: '',
        image3DFileMtl: ''
    },
    showImageFile: false,
    showRawImageFile: false,
    show3DImageFile: false,
    categoryList: [],
    formErrors: [],
    confirmationOpen: {
        categoryName: false,
        categoryImage: false
    },
    snackbarOpen: {
        categoryName: false,
        categoryImage: false
    },
    isLoading: false
}

class AddCategoryImage extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getCategories();
    }

    getCategories = () => {        
        API.get('/library/categories')
        .then((result) => {
            let categoryList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.library_cat_id,
                    label: el.library_cat_name
                });
            });
            this.setState({
                categoryList: categoryList
            });
        })
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    submitImage = () => {
        this.setState({
            isLoading: true
        },
        () => {
            let newFormData = new FormData();
            Object.keys(this.state.formData).forEach(key => {
                newFormData.append(key, this.state.formData[key])  
            });
            API.post(`/library/categories`, newFormData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...initialState,
                        snackbarOpen: { 
                            ...this.state.snackbarOpen,
                            categoryImage:true
                        }
                    });
                    this.getCategories();
                }
                this.props.scrollToTop();
            });
        });
    }
    submitName = () => {
        API.post(`/library/categories/name`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarOpen: { 
                        ...this.state.snackbarOpen,
                        categoryName:true
                    }
                });
                this.getCategories();
            }
            this.props.scrollToTop();
        });
    }

    handleNameConfirmationOpen = () => {this.setState({ confirmationOpen: { ...this.state.confirmationOpen,categoryName:true}});}
    handleImageConfirmationOpen = () => {this.setState({ confirmationOpen: { ...this.state.confirmationOpen,categoryImage:true}});}

    handleNameConfirmationClose = () => {this.setState({ confirmationOpen: { ...this.state.confirmationOpen,categoryName:false}});}
    handleImageConfirmationClose = () => {this.setState({ confirmationOpen: { ...this.state.confirmationOpen,categoryImage:false}});}

    handleNameSnackbarClose = () => {this.setState({ snackbarOpen: { ...this.state.snackbarOpen,categoryName:false}});}
    handleImageSnackbarClose = () => {this.setState({ snackbarOpen: { ...this.state.snackbarOpen,categoryImage:false}});}

    handleNameConfirmationSuccess = () => {
        this.setState({ confirmationOpen: { ...this.state.confirmationOpen,categoryName:false}});
        this.submitName();
    }
    handleImageConfirmationSuccess = () => {
        this.setState({ confirmationOpen: { ...this.state.confirmationOpen,categoryImage:false}});
        this.submitImage();
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    clearDocument = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: initialState.formData.name
            }
        });
    };

    handleCheckChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    }
       
    render() {
        const { formErrors } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Category Image
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Category Image
                            </Typography>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <form noValidate autoComplete="off">
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.categoryList} 
                                        label='Category *'
                                        value={this.state.formData.categoryId}
                                        onChange={this.handleSelectChange('categoryId')}
                                        error={formErrors && formErrors['categoryId'] && true}
                                        errorText={formErrors && formErrors['categoryId'] && formErrors['categoryId']}
                                    />
                                </FormControl>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={2} lg={1}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                checked={this.state.showImageFile}
                                                onChange={this.handleCheckChange('showImageFile')}
                                                value="showImageFile"
                                                color="primary"
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={10} lg={11}>
                                        <DragFileInput
                                            id="imageFileInput"
                                            name="imageFile"
                                            label="Image File (.jpg, .tiff, .tif, .gif, .png) *"
                                            file={this.state.formData.imageFile}
                                            errorText={formErrors && formErrors['imageFile']}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={this.clearDocument('imageFile')}
                                            emptyText='No file selected'
                                            disabled={!this.state.showImageFile}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={2} lg={1}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                checked={this.state.showRawImageFile}
                                                onChange={this.handleCheckChange('showRawImageFile')}
                                                value="showRawImageFile"
                                                color="primary"
                                                />
                                            }
                                        />    
                                    </Grid>
                                    <Grid item xs={10} lg={11}>
                                        <DragFileInput
                                            id="imageFileRawInput"
                                            name="imageFileRaw"
                                            label="Raw Image File (.psd, .ai, .zip, .zipx) *"
                                            file={this.state.formData.imageFileRaw}
                                            errorText={formErrors && formErrors['imageFileRaw']}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={this.clearDocument('imageFileRaw')}
                                            emptyText='No file selected'
                                            disabled={!this.state.showRawImageFile}
                                        />
                                    </Grid>
                                </Grid>
                                
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={2} lg={1}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                checked={this.state.show3DImageFile}
                                                onChange={this.handleCheckChange('show3DImageFile')}
                                                value="show3DImageFile"
                                                color="primary"
                                                />
                                            }
                                        />    
                                    </Grid>
                                    <Grid item xs={10} lg={11}>
                                        <DragFileInput
                                            id="image3DFileObj"
                                            name="image3DFileObj"
                                            label="3D File (.obj) *"
                                            file={this.state.formData.image3DFileObj}
                                            errorText={formErrors && formErrors['image3DFileObj']}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={this.clearDocument('image3DFileObj')}
                                            emptyText='No file selected'
                                            disabled={!this.state.show3DImageFile}
                                        />
                                        <DragFileInput
                                            id="image3DFileMtl"
                                            name="image3DFileMtl"
                                            label="3D File (.mtl) *"
                                            file={this.state.formData.image3DFileMtl}
                                            errorText={formErrors && formErrors['image3DFileMtl']}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={this.clearDocument('image3DFileMtl')}
                                            emptyText='No file selected'
                                            disabled={!this.state.show3DImageFile}
                                        />
                                    </Grid>
                                </Grid>
                                <div className='buttonRow' style={{justifyContent: 'space-between', alignItems:'center'}}>
                                    <Typography variant="subtitle2">
                                        <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                                    </Typography>
                                    <Button onClick={this.handleImageConfirmationOpen}
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.isLoading}
                                            >
                                        Add
                                    </Button>
                                </div>
                            </form>
                            <ConfirmationDialog 
                                open={this.state.confirmationOpen.categoryImage} 
                                success={this.handleImageConfirmationSuccess} 
                                close={this.handleImageConfirmationClose} 
                                title="Add A New Image Files?" 
                                message="Are you sure you want to add these image files?"
                            />
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarOpen.categoryImage}
                                onClose={this.handleImageSnackbarClose}
                                message='You have successfully added new image file(s)'
                            />
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <React.Fragment>
                                <Typography variant="h6" gutterBottom>
                                    Add Category
                                </Typography>
                                <form noValidate autoComplete="off">
                                    <TextField
                                        id="categoryName"
                                        name="categoryName"
                                        label="Category Name *"
                                        value={this.state.formData.categoryName}
                                        onChange={this.handleChange}
                                        error={formErrors && formErrors['categoryName'] && true}
                                        helperText={formErrors && formErrors['categoryName']}
                                        margin="normal"
                                        fullWidth
                                    />
                                   <div className='buttonRow'>
                                        <Button 
                                            onClick={this.handleNameConfirmationOpen}
                                            variant="contained" 
                                            color="primary" 
                                        >
                                            Add
                                        </Button>
                                    </div>
                                    <ConfirmationDialog 
                                        open={this.state.confirmationOpen.categoryName} 
                                        success={this.handleNameConfirmationSuccess} 
                                        close={this.handleNameConfirmationClose} 
                                        title="Add a New Category?" 
                                        message="Are you sure you want to add a new category?"
                                    />
                                    <SnackBar
                                        variant="success"
                                        anchorOriginVertical='bottom'
                                        anchorOriginHorizontal='right'
                                        open={this.state.snackbarOpen.categoryName}
                                        onClose={this.handleNameSnackbarClose}
                                        message="You have successfully added a new category"
                                    />
                                </form>
                            </React.Fragment>
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default AddCategoryImage;