import React from 'react';
import API from 'API';
import { connect } from 'react-redux';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import Typography from '@material-ui/core/Typography';
import {
    Button,
    Grid
} from '@material-ui/core';
import { getFormData, handleDateChange } from 'Functions/FormFunctions';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

const initialState = () => ({
    vehicleId: 0,
    formData: {
        returnDate: null,
    },
    formErrors: [],
})

class VehicleReturnForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.getFormData = getFormData.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
    }

    componentDidMount() {
        const { returnDate, vehicleId } = this.props;

        this.setState({
            ...this.state,
            vehicleId,
            formData: {
                ...this.state.formData,
                returnDate: returnDate !== '0000-00-00' ? returnDate : null
            }
        });
    }

    handleSubmit = () => {
        const { vehicleId } = this.state;

        API.post(`/vehicles/${vehicleId}/return`, this.getFormData())
        .then(res => {
            if(res.data.errors && res.data.errors.length > 0) {
                this.setState({
                    formErrors: formatValidationErrors(res.data.errors)
                });
            } else {
                this.props.deploySnackBar("success", `The vehicle was successfully returned`);
                this.props.refresh();
                this.props.close();
            }
        });
    }

    render() {

        const { formErrors, formData } = this.state;

        return (
            <Grid container item spacing={3}>                        
                <Grid item xs={12}>
                    <DatePicker
                        type="date"
                        id="returnDate"
                        name="returnDate"
                        label={"Returned Date *"}
                        value={formData?.returnDate}
                        errorText={formErrors && formErrors['returnDate']}
                        onChange={date => this.handleDateChange('returnDate', date, 'YYYY-MM-DD')}
                        autoOk={true}
                        inputProps={{
                            className:"textDefault"
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" paragraph>
                        The vehicle status will change to <b>Lease Completed / Sold</b><br></br> 
                        If you have a current allocation, the end date will be updated to the 'Return Date'
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <div className="buttonRow">
                        <Button onClick={this.props.close} variant="outlined" color="default">Cancel</Button>
                        <Button onClick={this.handleSubmit} color="primary" autoFocus variant="contained">Submit</Button>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
    }
)

export default connect(null, mapDispatchToProps)(VehicleReturnForm);