import React, { Component } from 'react';
import API                  from 'API';
import { Redirect }         from 'react-router-dom';
import _                    from 'lodash';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControl, FormLabel, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'; 
import ExternalLink   from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CallHistory        from 'Components/Phone/CallHistory';
import BackButton         from 'Components/Common/Buttons/BackButton';
import CopyToClipboard    from 'Components/Common/CopyToClipboard/CopyToClipboard';
import Textarea           from 'Components/Common/Inputs/Textarea';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { DialAdornment } from 'Functions/MiscFunctions';

import * as lists from 'Helpers/SupplierHelper';

const styles = theme => ({
    disabledText: {
        color: 'black !important',
    }
});

class ViewSupplier extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            supplier: {
                supp_company_name: '',
                supp_trading_name: '',
                supp_address_line_one: '',
                supp_address_line_two: '',
                supp_address_line_three: '',
                supp_city: '',
                supp_postcode: '',
                supp_county_id: 0,
                supp_country_id: 0,
                supp_telephone: '',
                supp_fax: '',
                supp_email: '',
                supp_website: '',
                supp_vat: '',
                contacts: [{
                    supp_cont_first_name: '',
                    supp_cont_last_name: '',
                    supp_cont_position: '',
                    supp_cont_department: '',
                    supp_cont_direct_dial: '',
                    supp_cont_mobile: '',
                    supp_cont_email: '',
                }],  
                supp_order_type: '',
                supp_lead_time: '',
                supp_payment_terms: '',
                supp_account_number: '',
                supp_ordering_notes: '',
                supp_online_url: '',
                supp_username: '',
                supp_password: '',
                supp_online_notes: '',
                supp_notes: '',                  
            },
            departmentContacts: [],
            personContacts: [],
            supplierTypeList: [],
            countyList: [],
            countryList: [],
            paymentTermList: [],
            leadTimeList: [],
            orderTypeList: [],
            isLoading: true,
            access: {
                viewOnlineInformation: false,
                viewPhoneHistory: false
            },
            redirect: false
        };  
    }

    componentDidMount(){
        const supplierId = this.props.match.params.id;
        this.populateDropdowns();
        this.getSupplier(supplierId);
        this.checkAccess();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.match?.params?.id !== prevProps.match?.params?.id) {
            this.setState({
                isLoading: true
            }, () => this.getSupplier(this.props.match.params.id))
        }
    }

    checkAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/view-supplier:online-information'),
            API.get('/staff/my/access/check/view-supplier:phone-history')
        ])
        .then(([onlineInfo, phoneHistory]) => {
            this.setState({
                access: {
                    ...this.state.access,
                    viewOnlineInformation: onlineInfo?.data?.has_access ?? false,
                    viewPhoneHistory: phoneHistory?.data?.has_access ?? false
                }
            });
        });
    }

    populateDropdowns = () => {
        let supplierTypeList = [];
        let countyList = [];
        let countryList = [];
        Promise.all([
            API.get('/suppliers/types/all'), 
            API.get('/misc/counties/all'),
            API.get('/misc/countries/all')
        ])
        .then(([typeRes, countyRes, countryRes]) =>  {
            if(typeRes.data){
                supplierTypeList = _.map(typeRes.data, (el) => {
                    return _.assign({
                        value: el.supp_types_id,
                        label: el.supp_types_name,
                    });
                });
            }
            if(countyRes.data){
                countyList = _.map(countyRes.data, (el) => {
                    return _.assign({
                        value: el.county_id,
                        label: el.county_name,
                    });
                });
            }
            if(countryRes.data){
                countryList = _.map(countryRes.data, (el) => {
                    return _.assign({
                        value: el.country_id,
                        label: el.country_name,
                    });
                });
            }
            this.setState({
                supplierTypeList: supplierTypeList,
                countyList: countyList,
                countryList: countryList
            });
        });
        let paymentTermList = _.map(lists.paymentTerms, (el) => {
            return _.assign({
                value: el,
                label: el,
            });
        });
        let leadTimeList = _.map(lists.leadTimes, (el) => {
            return _.assign({
                value: el,
                label: el,
            });
        });
        let orderTypeList = _.map(lists.orderTypes, (el) => {
            return _.assign({
                value: el,
                label: el,
            });
        });
        this.setState({
            leadTimeList: leadTimeList,
            paymentTermList: paymentTermList,
            orderTypeList: orderTypeList
        });
        
    }

    getSupplier = (supplierId) => {
        API.get(`/suppliers/${supplierId}`)
        .then((result) => {
            if (result.data.errors) {
                this.setState ({ redirect: true})
            } else if(result.data) {
                let departmentContacts = [];
                let personContacts = [];
                result.data.active_contacts.forEach(contact => {
                    if (contact.supp_cont_type === 'Department') {
                        departmentContacts.push(contact);
                    }
                    if (contact.supp_cont_type === 'Person') {
                        personContacts.push(contact);
                    }
                });
                this.setState({
                    supplier: result.data,
                    departmentContacts,
                    personContacts,
                    isLoading: false
                });
            }
        })
    };
  
    render() {
        const { supplier, departmentContacts, personContacts, isLoading } = this.state;
        const { classes } = this.props;
        if (this.state.redirect) {
            return <Redirect to="/suppliers/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Supplier Details
                        </Typography>
                    </Grid>
                    {(isLoading && (
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <LoadingCircle />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>                    
                    )) || (
                        <React.Fragment>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Supplier Details
                                        </Typography>
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                options={this.state.supplierTypeList} 
                                                label='Supplier Type'
                                                value={supplier.supp_type_id}
                                                disabled
                                            />
                                        </FormControl>
                                        <TextField
                                            id="companyName"
                                            name="companyName"
                                            label="Company Name"
                                            value={supplier.supp_company_name}
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: !!supplier.supp_company_name
                                            }}
                                        />
                                        <TextField
                                            id="tradingName"
                                            name="tradingName"
                                            label="Trading Name"
                                            value={supplier.supp_trading_name}
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: !!supplier.supp_trading_name
                                            }}
                                        />
                                        <TextField
                                            id="addressOne"
                                            name="addressOne"
                                            label="Address Line 1"
                                            value={supplier.supp_address_line_one}
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: !!supplier.supp_address_line_one
                                            }}
                                        />
                                        <TextField
                                            id="addressTwo"
                                            name="addressTwo"
                                            label="Address Line 2"
                                            value={supplier.supp_address_line_two}
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: !!supplier.supp_address_line_two
                                            }}
                                        />
                                        <TextField
                                            id="addressThree"
                                            name="addressThree"
                                            label="Address Line 3"
                                            value={supplier.supp_address_line_three}
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: !!supplier.supp_address_line_three
                                            }}
                                        />
                                        <TextField
                                            id="city"
                                            name="city"
                                            label="City"
                                            value={supplier.supp_city}
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: !!supplier.supp_city
                                            }}
                                        />
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                options={this.state.countyList} 
                                                label='County'
                                                value={supplier.supp_county_id}
                                                disabled
                                            />
                                        </FormControl>
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                options={this.state.countryList} 
                                                label='Country'
                                                value={supplier.supp_country_id}
                                                disabled
                                            />
                                        </FormControl>
                                        <TextField
                                            id="postcode"
                                            name="postcode"
                                            label="Postcode"
                                            value={supplier.supp_postcode}
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: !!supplier.supp_postcode
                                            }}
                                        />
                                        <TextField
                                            id="mainTelephone"
                                            name="mainTelephone"
                                            label="Main Telephone"
                                            value={supplier.supp_telephone}
                                            InputProps={{
                                                startAdornment: <DialAdornment number={supplier.supp_telephone} />
                                            }}
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: !!supplier.supp_telephone
                                            }}
                                        />
                                        <TextField
                                            id="fax"
                                            name="fax"
                                            label="Main Fax Number"
                                            value={supplier.supp_fax}
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: !!supplier.supp_fax
                                            }}
                                        />
                                        <TextField
                                            id="mainEmail"
                                            name="mainEmail"
                                            label="Main Email Address"
                                            value={supplier.supp_email}
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: !!supplier.supp_email
                                            }}
                                        />
                                        <TextField
                                            id="vat"
                                            name="vat"
                                            label="VAT Number"
                                            value={supplier.supp_vat}
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: !!supplier.supp_vat
                                            }}
                                        />
                                        <br /><br />
                                        <FormLabel>Website</FormLabel><br />
                                        {supplier.supp_website.length > 0 ?
                                            <ExternalLink href={supplier.supp_website.startsWith('http') ? supplier.supp_website : `http://${supplier.supp_website}`} rel="noopener" target="_blank">
                                                {supplier.supp_website.startsWith('http') ? supplier.supp_website : `http://${supplier.supp_website}`}        
                                            </ExternalLink>
                                        : '-'}
                                        <div className='buttonRow'>
                                            <BackButton props={this.props} />
                                        </div>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    {departmentContacts && departmentContacts.map((contact, idx) => (
                                        <Grid key={contact.supp_cont_id} item xs={12} lg={12} style={{marginBottom:20}}>
                                            <PaddedPaper>
                                                <Typography variant="h6">
                                                    Contact Department {idx+1}
                                                </Typography>
                                                <Grid container>
                                                    <Grid item lg={12}>
                                                        <TextField
                                                            name="firstName"
                                                            label="Name"
                                                            value={contact.supp_cont_first_name ? contact.supp_cont_first_name : ''}
                                                            margin="normal"
                                                            inputProps={{
                                                                className: classes.disabledText
                                                            }}
                                                            InputLabelProps={{
                                                                className: classes.disabledText
                                                            }}
                                                            fullWidth
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid container item spacing={3}>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                name="directDial"
                                                                label="Phone Number"
                                                                value={contact.supp_cont_direct_dial ? contact.supp_cont_direct_dial : ''}
                                                                margin="normal"                                                                
                                                                InputProps={{
                                                                    startAdornment: <DialAdornment number={contact.supp_cont_direct_dial} />
                                                                }}
                                                                inputProps={{
                                                                    className: classes.disabledText
                                                                }}
                                                                InputLabelProps={{
                                                                    className: classes.disabledText
                                                                }}
                                                                fullWidth
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item sx={12} lg={6}>
                                                            <TextField
                                                                id="email"
                                                                name="email"
                                                                label="Email"
                                                                value={contact.supp_cont_email ? contact.supp_cont_email : ''}
                                                                margin="normal"
                                                                inputProps={{
                                                                    className: classes.disabledText
                                                                }}
                                                                InputLabelProps={{
                                                                    className: classes.disabledText
                                                                }}
                                                                fullWidth
                                                                disabled
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    {personContacts && personContacts.map((item, idx) => {
                                    let number = idx+1
                                        return (
                                            <ExpansionPanel key={idx}>
                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Typography>Contact Person {number}</Typography>
                                                </ExpansionPanelSummary>  
                                                <ExpansionPanelDetails>
                                                     <Grid item xs={12}>
                                                        <Grid container item spacing={3}>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    name="firstName"
                                                                    label="First Name"
                                                                    value={item.supp_cont_first_name}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: !!item.supp_cont_first_name
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    name="lastName"
                                                                    label="Last Name"
                                                                    value={item.supp_cont_last_name}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: !!item.supp_cont_last_name
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <TextField
                                                            name="position"
                                                            label="Position in Company"
                                                            value={item.supp_cont_position}
                                                            margin="normal"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: !!item.supp_cont_position
                                                            }}
                                                        />
                                                        <TextField
                                                            name="department"
                                                            label="Department"
                                                            value={item.supp_cont_department}
                                                            margin="normal"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: !!item.supp_cont_department
                                                            }}
                                                        />
                                                        <TextField
                                                            name="directDial"
                                                            label="Direct Dial"
                                                            value={item.supp_cont_direct_dial}
                                                            margin="normal"
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: <DialAdornment number={item.supp_cont_direct_dial} />
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: !!item.supp_cont_direct_dial
                                                            }}
                                                        />
                                                        <TextField
                                                            name="mobile"
                                                            label="Mobile Number"
                                                            value={item.supp_cont_mobile}
                                                            margin="normal"
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: <DialAdornment number={item.supp_cont_mobile} />
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: !!item.supp_cont_mobile
                                                            }}
                                                        />
                                                        <TextField
                                                            id="email"
                                                            name="email"
                                                            label="Email"
                                                            value={item.supp_cont_email}
                                                            margin="normal"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: !!item.supp_cont_email
                                                            }}
                                                        />
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        )
                                    })}
                                    <ExpansionPanel>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>Ordering Information</Typography>
                                        </ExpansionPanelSummary>  
                                        <ExpansionPanelDetails>
                                            <Grid container item spacing={3}>
                                                <Grid item xs={12}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <AutoCompleteSelect 
                                                            options={this.state.orderTypeList} 
                                                            label='How to Order'
                                                            value={supplier.supp_order_type}
                                                            disabled
                                                        />
                                                    </FormControl>
                                                    <FormControl margin="normal" fullWidth>
                                                        <AutoCompleteSelect 
                                                            options={this.state.leadTimeList} 
                                                            label='General Lead Time'
                                                            value={supplier.supp_lead_time}
                                                            disabled
                                                        />
                                                    </FormControl>
                                                    <FormControl margin="normal" fullWidth>
                                                        <AutoCompleteSelect 
                                                            options={this.state.paymentTermList} 
                                                            label='Payment Terms'
                                                            value={supplier.supp_payment_terms}
                                                            disabled
                                                        />
                                                    </FormControl>
                                                    <TextField
                                                        id="accountNumber"
                                                        name="accountNumber"
                                                        label="Account Number"
                                                        value={supplier.supp_account_number}
                                                        margin="normal"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: !!supplier.supp_account_number
                                                        }}
                                                    />
                                                    <Textarea
                                                        id="orderingNotes"
                                                        name="orderingNotes"
                                                        label="Ordering Notes"
                                                        value={supplier.supp_ordering_notes}
                                                        rows={10}
                                                        InputLabelProps={{
                                                            shrink: !!supplier.supp_ordering_notes
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    {this.state.access.viewOnlineInformation &&
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography>Online Information</Typography>
                                            </ExpansionPanelSummary>  
                                            <ExpansionPanelDetails>
                                                <Grid container item spacing={3}>
                                                    <Grid item xs={12}>
                                                        <br />
                                                        <FormLabel>Online URL</FormLabel><br />
                                                        {supplier.supp_online_url.length > 0 ?
                                                            <ExternalLink href={supplier.supp_online_url.startsWith('http') ? supplier.supp_online_url : `http://${supplier.supp_online_url}`} rel="noopener" target="_blank">
                                                                {supplier.supp_online_url.startsWith('http') ? supplier.supp_online_url : `http://${supplier.supp_online_url}`}        
                                                            </ExternalLink>
                                                        : '-'}
                                                        <TextField
                                                            id="onlineUsername"
                                                            name="onlineUsername"
                                                            label="Online Username"
                                                            value={supplier.supp_username}
                                                            margin="normal"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: !!supplier.supp_username
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment style={{paddingBottom: 20, paddingRight: 10}}>
                                                                        <CopyToClipboard field ="onlineUsername" />
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />                                      
                                                        <TextField
                                                            id="onlinePassword"
                                                            name="onlinePassword"
                                                            label="Online Password"
                                                            value={supplier.supp_password}
                                                            margin="normal"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: !!supplier.supp_password
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment style={{paddingBottom: 20, paddingRight: 10}}>
                                                                        <CopyToClipboard field ="onlinePassword" />
                                                                    </InputAdornment>
                                                                )
                                                            }}                                                    
                                                        />
                                                        <Textarea
                                                            id="onlineNotes"
                                                            name="onlineNotes"
                                                            label="Notes"
                                                            value={supplier.supp_online_notes}
                                                            rows={10}
                                                            InputLabelProps={{
                                                                shrink: !!supplier.supp_online_notes
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    }
                                    <ExpansionPanel>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>General</Typography>
                                        </ExpansionPanelSummary>  
                                        <ExpansionPanelDetails>
                                            <Grid container item spacing={3}>
                                                <Grid item xs={12}>
                                                    <Textarea
                                                        id="notes"
                                                        name="notes"
                                                        label="Notes"
                                                        value={supplier.supp_notes}
                                                        rows={10}
                                                        InputLabelProps={{
                                                            shrink: !!supplier.supp_notes
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                                {this.state.access.viewPhoneHistory && (
                                    <Grid item xs={12}>
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography>Phone History</Typography>
                                            </ExpansionPanelSummary>  
                                            <ExpansionPanelDetails>
                                                <CallHistory inPage supplierId={supplier.supp_id} />
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                )}
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            );
        }
    }
}

export default withStyles(styles)(ViewSupplier);