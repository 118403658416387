import {
    Button,
    DialogActions,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { getFormData, handleChange, handleDateChange, handleSelectChange } from 'Functions/FormFunctions';
import { toggleDialog } from 'Functions/MiscFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';


const initialState = () => ({
    mileageId: 0,
    mileage: {},
    formData: {
        date: moment().format('YYYY-MM-DD'),
        mileage: '',
    },
    formErrors: [],
    isLoading: true
});

class VehicleMileageForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
        this.getFormData = getFormData.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData(); 
    }

    loadComponentData = () => {

        const { mileageId } = this.props;

        this.setState({
            ...this.state,
            isLoading: false,
            mileageId,
        },
        () => {
            if (this.state.mileageId) {
                this.getMileage();
            }
        }); 
    }

    getMileage = () => {
        this.setState({
            isLoading: true,
        }, 
        () => {
            API.get(`/vehicles/${this.props.vehicleId}/mileage/${this.state.mileageId}`)
            .then(res => {
    
                let mileage = res.data;
    
                this.setState({
                    ...this.state,
                    isLoading: false,
                    mileage,
                    formData: {
                        ...this.state.formData,
                        date: mileage?.date,
                        mileage: mileage?.currentMileage
                    }
                });
            });
        });
    }

    handleSubmit = () => {
        const { mileageId } = this.state;
        const route = mileageId ? `/vehicles/${this.props.vehicleId}/mileage/${this.state.mileageId}`: `/vehicles/${this.props.vehicleId}/mileage`;

        API.post(route, this.getFormData())
        .then(res => {
            if(res.data.errors && res.data.errors.length > 0) {
                this.setState({
                    formErrors: formatValidationErrors(res.data.errors)
                });
            } else {
                this.setState({
                    ...initialState(),
                }, () => {
                    this.props.deploySnackBar("success", `The mileage was successfully ${mileageId ? 'updated' : 'added'}`);
                    this.props.refresh();
                })
            }
        });
    }

    render() {
        const { formErrors, formData, isLoading, mileageId } = this.state;
        return (   
            <>   
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {mileageId ? 'Update Mileage' : 'Add Mileage'}
                    </Typography>
                </Grid>     
                <Grid item xs={12}>
                    <form noValidate>
                        {(isLoading && (
                            <LoadingCircle />          
                        )) || (
                            <Grid container item spacing={1}>
                                {formErrors && formErrors.generic && (
                                    <Grid item xs={12}>
                                        <Typography component={"div"} style={{color: 'red'}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <DatePicker
                                        type="date"
                                        id="date"
                                        name="date"
                                        label={"Date " + (mileageId ? '' : '*')}
                                        value={formData.date}
                                        errorText={formErrors && formErrors['date']}
                                        onChange={date => this.handleDateChange('date', date, 'YYYY-MM-DD')}
                                        autoOk={true}
                                        inputProps={{
                                            className:"textDefault"
                                        }}
                                        disableFuture={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField  
                                        name="mileage"
                                        label="Mileage *"
                                        margin="none"
                                        error={!!formErrors && formErrors['mileage'] && true}
                                        helperText={formErrors && formErrors['mileage']}
                                        value={formData.mileage || ''}
                                        onChange={this.handleChange}
                                        fullWidth
                                        type='number'
                                    />
                                </Grid>
                                <Grid item xs={12} align="right">
                                    <DialogActions className='pr-0 pb-0'>
                                        <Button onClick={() => this.props.onClose()} variant="text">
                                            <AllIcon icon="times" size={15} />
                                            Close
                                        </Button>
                                        <Button 
                                            onClick={() => this.props.deployConfirmation(`Are you sure you want to ${mileageId ? 'update' : 'add'} this mileage?`, `${mileageId ? 'Update' : 'Add'} Mileage?`, this.handleSubmit)}
                                            variant="contained"
                                            color="primary"
                                        >
                                            {mileageId ? 'Update' : 'Add'}
                                        </Button>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        )}
                    </form>
                </Grid>  
            </Grid>
            </>  
        );
    }
}

const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
    }
)

export default connect(null, mapDispatchToProps)(VehicleMileageForm);