import React, { Component } from 'react';
import moment               from 'moment';
import { Redirect }         from 'react-router-dom';
import API                  from 'API';

import { Grid, Typography } from '@material-ui/core';

import BackButton    from 'Components/Common/Buttons/BackButton';
import DataTable     from 'Components/Common/DataTables/DataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';

const initialState = {
    subAssembly: {
        part: {
            sub_assembly_history: []
        }
    },
    isLoading: true,
    redirect: false
}

class SubAssemblyHistory extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount(){
        this.getHistory();
    }
    getHistory = () => {
        API.get('/subAssemblies/' + this.props.match.params.id + '/history').then(result => {
            if (result.data.errors) {
                this.setState({ redirect: true });
            } else if(result.data) {
                this.setState({
                    subAssembly: result.data,
                    isLoading: false
                });
            }
        });
    }
    downloadFile = (file) => {
        window.open(file, '_blank');
    }
    render() {
        const { isLoading } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/sub-assemblies/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Sub Assembly History
                        </Typography>
                    </Grid>                
                    {(isLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                    )) || (
                        <React.Fragment>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={8}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            {this.state.subAssembly.part.part_number} - {this.state.subAssembly.part.part_description}
                                        </Typography>
                                        <Typography variant="h6" gutterBottom>
                                            Current Version: v{this.state.subAssembly.sa_version}
                                        </Typography>
                                        <DataTable  
                                            config={{
                                                key: 'sa_history_id'
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Description Of Change',
                                                    field: rowData => rowData.sa_history_type
                                                },
                                                {
                                                    heading: 'Version',
                                                    field: rowData => 'v' + rowData.sa_history_version + ' to v' + rowData.sa_history_new_version
                                                },
                                                {
                                                    heading: 'Date',
                                                    field: rowData => moment(rowData.sa_history_date_added).format("DD/MM/YYYY")
                                                },
                                                {
                                                    actions: rowData => {
                                                        return [
                                                            {name: 'History', icon: 'download', label:'History', onClick: () => { this.downloadFile(rowData.history_download_url) }},
                                                            {name: 'Changes', icon: 'download', label:'Changes', onClick: () => { this.downloadFile(rowData.changes_download_url) }, disabled: !rowData.changes_download_url},
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={this.state.subAssembly.part.sub_assembly_history}
                                        />
                                        <div className='buttonRow'>
                                            <BackButton props={this.props} />
                                        </div>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            );
        }
    }
}

export default SubAssemblyHistory;