import { ExpansionPanel, ExpansionPanelDetails, Grid, Typography, ExpansionPanelSummary } from '@material-ui/core';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import React from 'react';
import _ from 'lodash'; 
import MediaTable from 'Components/Common/DataTables/MediaTable';
import InfoIcon from 'Components/Common/Icons/InfoIcon';
import IconHelper from 'Helpers/IconHelper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const initialState = () => ({})

class OutcomeWriteOff extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState();
    }

    render(){

        return (
            <Grid container spacing={1}>
                {this.props.outcome.cro_outcome_id > 1 &&
                    <Grid item xs={12} style={{marginBottom: '2em'}}>
                        <CiDataTable
                            rows={[this.props.outcome.order]}
                            config={{
                                key: 'order_id',
                            }}
                            columns={[
                                {
                                    heading: 'Order Date',
                                    field: 'order_date',
                                    sizeToContent: true,
                                    alignment: 'center',
                                    fieldFormat: 'clenydate',
                                },
                                {
                                    heading: 'Reference',
                                    field: i => <>{i.order_reference }-{i.order_reference_amendment}</>,
                                },
                                {
                                    heading: 'Discount',
                                    field: i => i.order_type === 'With Discount',
                                    fieldFormat: 'boolean',
                                    sizeToContent: true,
                                    alignment: 'center'
                                },
                                {
                                    heading: 'Customer',
                                    field: i => 
                                        <>
                                            {i.order_cust_name}<br/>
                                            {i.order_delivery_cust_address_name} <InfoIcon info={
                                                <span style={{whiteSpace: 'pre-wrap'}}>
                                                    <b>Delivery Address</b><br/>
                                                    {i.order_delivery_cust_address}
                                                </span>
                                            }/>
                                        </>,
                                },
                                {
                                    heading: 'Total',
                                    field: 'order_total_incl_vat',
                                    fieldFormat: 'currency',
                                    sizeToContent: true,
                                    alignment: 'right',
                                    info: rowData => 
                                        <div style={{display: 'flex', width: 200}}>
                                            <div style={{width: '50%', textAlign:'right'}}>
                                                <Typography variant="body2">Sub Total</Typography>
                                                <Typography variant="body2">VAT ({parseInt(this.props.cr.cr_vat_rate * 100).toFixed(2)}%)</Typography>
                                                <Typography variant="body2">Total</Typography>
                                            </div>
                                            <div style={{width: '50%', textAlign:'right'}}>
                                                <Typography variant="body2">£{rowData.order_sub_total}</Typography>
                                                <Typography variant="body2">£{rowData.order_vat}</Typography>
                                                <Typography variant="body2">£{rowData.order_total_incl_vat}</Typography>
                                            </div>
                                        </div>
                                },
                                {
                                    actions: i => ([
                                        {
                                            name: 'View',
                                            link: `/sales/order/view/${i.order_id}`,
                                            icon: IconHelper.view
                                        }
                                    ])
                                }
                            ]}
                        />
                    </Grid>
                }
                {_.map(this.props.outcome.details, (i,idx) =>{
                    let wo = i?.repair.write_off;
                    let part = i?.part;
                    if (wo) {
                        return (
                            <Grid item xs={12} key={idx}>
                                <PaddedPaper>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <ImageWithError
                                                src={part?.default_image?.file_path}
                                                style={{
                                                    maxHeight: 50,
                                                    maxWidth: 50
                                                }}
                                            />
                                        </Grid>
                                        <Grid item  container xs={11}>
                                            <Grid item xs={12}>
                                                <Typography variant='body1'>{part?.part_number}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='body2'>{part?.part_description}</Typography>
                                            </Grid>
                                            {  (i.repair.ro_sn !== '[]' ) || (i.repair.ro_wvl !== '[]' ) && 
                                                <Grid item xs={12}>
                                                    <Typography variant='caption'>{( (i.repair.ro_sn !== '[]' && i.repair.ro_sn) || (i.repair.ro_wvl !== '[]' && i.repair.ro_wvl) )}</Typography> 
                                                </Grid>
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='body2'><strong>Reason:</strong> {wo.rowo_reason}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='body2' style={{whiteSpace: 'pre-wrap'}}><strong>Notes:</strong> {wo.rowo_notes}</Typography>
                                        </Grid>
                                        {wo.files &&
                                            <Grid item xs={12}>
                                                <MediaTable
                                                    config={{ 
                                                        itemPerRow: 5,
                                                        mobileItemPerRow: 5,
                                                    }}
                                                    media='file_url'
                                                    items={wo.files}
                                                />
                                            </Grid>
                                        }
                                        {wo.parts_used.length > 0 &&
                                            <Grid item xs={12}>
                                                {_.filter(wo.parts_used, j => j.part.stockable).length > 0 &&
                                                    <ExpansionPanel>
                                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                            Parts Used
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails>
                                                            <CiDataTable
                                                                config={{
                                                                    key: 'rowopu_id'
                                                                }}
                                                                columns={[
                                                                    {
                                                                        heading: '',
                                                                        field: 'part.default_image.thumbnail_file_path',
                                                                        fieldFormat: 'image',
                                                                        sizeToContent: true,
                                                                    },
                                                                    {
                                                                        heading: 'Part',
                                                                        field: i =>
                                                                            <Grid container >
                                                                                <Grid item xs={12}>
                                                                                    <Typography variant='body1'>{i.part.part_number}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Typography variant='body2'>{i.part.part_description}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                    },
                                                                    {
                                                                        heading: 'Quantity',
                                                                        field: 'rowopu_qty',
                                                                        sizeToContent: true,
                                                                        fieldFormat: 'decimal:2',
                                                                    }
                                                                ]}
                                                                rows={_.filter(wo.parts_used, j => j.part.stockable)}
                                                            />
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                }
                                                {_.filter(wo.parts_used, j => !j.part.stockable).length > 0 &&
                                                    <ExpansionPanel>
                                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                            Services Used
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails>
                                                            <CiDataTable
                                                                config={{
                                                                    key: 'rowopu_id'
                                                                }}
                                                                columns={[
                                                                    {
                                                                        heading: '',
                                                                        field: 'part.default_image.thumbnail_file_path',
                                                                        fieldFormat: 'image',
                                                                        sizeToContent: true,
                                                                    },
                                                                    {
                                                                        heading: 'Part',
                                                                        field: i =>
                                                                            <Grid container >
                                                                                <Grid item xs={12}>
                                                                                    <Typography variant='body1'>{i.part.part_number}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Typography variant='body2'>{i.part.part_description}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                    },
                                                                    {
                                                                        heading: 'Quantity',
                                                                        field: 'rowopu_qty',
                                                                        sizeToContent: true,
                                                                        fieldFormat: 'decimal:2',
                                                                    }
                                                                ]}
                                                                rows={_.filter(wo.parts_used, j => !j.part.stockable)}
                                                            />
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                }
                                            </Grid>
                                        }
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        )
                    }
                })}
            </Grid>
        )
    }  
}

export default OutcomeWriteOff;