import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import API from '../../../API';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import DataTable from '../../Common/DataTables/DataTable';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import { connect } from 'react-redux';
import {colors} from 'Helpers/ColourHelper';
import { Dial } from 'Functions/MiscFunctions';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    searchCriteria: {
        searchType: 'customer_site',
        selectedSearchId: '',
    },
    searchResult: {},
    associatedResults: {},
    customerId: 0,
    access: {
        viewCustomer: false,
        updateCustomer: false,
        viewAddress: false,
        updateAddress: false
    },
    customerList: [],
    customers: {},
    addresses: {},
    isLoading: false
});

const accountStatus = ['','Active - Verified','On Hold','Closed','Active - Not Verified']

class CustomerSearch extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Customer:customerSearch';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        !this.hasPageState() && this.loadComponentData();
    }

    loadComponentData = () => {
        this.getCustomers();
        this.getCustomerAddresses();
        this.checkAccess();
    }

    getCustomers = () => {
        API.get(`/customers`, {
            props: {
                cancellation: true
            }
        })
        .then((res) =>  {
            if(res?.data) {
                this.setState({
                    customers: res.data
                },
                () => {
                    this.savePageState()
                });
            }
        })
    }
    getCustomerAddresses = () => {
        API.get(`/customers/addresses`)
        .then((res) =>  {
            this.setState({
                addresses: res.data
            },
            () => {
                this.populateCustomerDropdown(this.state.searchCriteria.searchType);
            });
        })
    }
    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/view-customer'), 
            API.get('/staff/my/access/check/update-customer'),
            API.get('/staff/my/access/check/view-customer-address'), 
            API.get('/staff/my/access/check/update-customer-address'),
        ])
        .then(([viewCust, updateCust, viewAdd, updateAdd]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    viewCustomer: viewCust.data && viewCust.data.has_access,
                    updateCustomer: updateCust.data && updateCust.data.has_access,
                    viewAddress: viewAdd.data && viewAdd.data.has_access,
                    updateAddress: updateAdd.data && updateAdd.data.has_access
                }
            });
        });
    }

    handleSelectChange = (selectedOption) => {
        if(selectedOption){
            this.setState({
                searchCriteria: {
                    ...this.state.searchCriteria,
                    selectedSearchId: selectedOption && selectedOption.value,
                }
            }, 
            () => {
                this.getSelectedCutomerId();
            });
        } else {
            this.setState({
                searchCriteria: {
                    ...this.state.searchCriteria,
                    selectedSearchId: '',
                },
                associatedResults: '',
                searchResult: '',
                customerId: ''
            });
        }
    };
    getSelectedCutomerId = () => {
        let selectedSearchId = this.state.searchCriteria.selectedSearchId;
        if(selectedSearchId){
            
            let customer = null;
            if(this.state.searchCriteria.searchType === 'customer_name' && selectedSearchId){
                customer = _.find(this.state.customers, {'cust_id': selectedSearchId});
            }
            else if(this.state.searchCriteria.searchType === 'customer_site' && selectedSearchId) {
                customer = _.find(this.state.addresses, {'address_id': selectedSearchId});
            }
            this.setState({
                customerId: customer?.cust_id
            }, 
            () => {
                this.getSearchedCustomers();
            });
        }
    }
    getSearchedCustomers(){
        this.setState({
            isLoading: true
        }, () => {
            API.get(`/customers/${this.state.customerId}/associated`)
            .then((result) => {
                let searchArray = [];
                let searchType = this.state.searchCriteria.searchType === 'customer_name' ? 'Customer' : 'Address';
                let searchResult = _.find(result.data, {'id': this.state.searchCriteria.selectedSearchId, 'type': searchType});
                searchArray.push(searchResult);
                // Remove the searched result from associated results
                _.remove(result.data, {'id': this.state.searchCriteria.selectedSearchId, 'type': searchType });
    
                this.setState({
                    ...this.state,
                    associatedResults: result.data,
                    searchResult: searchArray,
                    isLoading: false
                }, () => {
                    this.savePageState()
                })
            });
        })
    }
    handleChange = (event) => {
        const { name , value } = event.target;
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                [name]: value,
                selectedSearchId: '',
            }
        },
        () => {
            this.populateCustomerDropdown(value);
        });
    }
    populateCustomerDropdown(value){
        let customerList = null;
        if(value === 'customer_name'){
            customerList = _.map(this.state.customers, el => {
                el.registeredPostcode = _.result(_.find(el?.addresses, {'address_type': 'Registered'}), 'address_postcode');
                return _.assign({
                    value: el.cust_id,
                    label: el.cust_name + ' | ' + el.cust_type + ' ('+el?.registeredPostcode+') | '+ accountStatus[el.account_status.cas_id] + ' | ' + 'CS' + el.cust_id
                });
            });
        }
        else if(value === 'customer_site') {
            customerList = _.map(this.state.addresses, el => {
                return _.assign({
                    value: el.address_id,
                    label: el.address_name + ' | ' + el.address_type + ' | ' + el.address_postcode + ' | ' + accountStatus[el.cust_account_status_id] + ' | ' + 'CS' + el.cust_id
                });
            });
        }
        this.setState({
            customerList: customerList
        });
    }
    reset = () => {
        this.clearPageState();
        this.setState({
            searchCriteria: initialState().searchCriteria,
            associatedResults: '',
            searchResult: '',
            customerId: ''
        },
        () => {
            //this.props.history.replace();
            this.savePageState();
        });
    }

    render() {
        const { access, searchCriteria } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Customer Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <RadioGroup
                                    name="searchType"
                                    value={searchCriteria?.searchType}
                                    onChange={this.handleChange}
                                    row
                                >
                                    <FormControlLabel
                                        value="customer_site"
                                        control={<Radio color="primary" />}
                                        label="Customer Addresses"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="customer_name"
                                        control={<Radio color="primary" />}
                                        label="Customer Name"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.customerList} 
                                        label='Customer'
                                        value={searchCriteria?.selectedSearchId}
                                        onChange={this.handleSelectChange}
                                    />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.reset}
                                        variant="outlined"
                                        color="default"
                                    >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                {(searchCriteria.selectedSearchId || this.state.isLoading) && (
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant="h6" gutterBottom>
                                Search Result
                            </Typography>
                            <DataTable  
                                config={{
                                    pagination: false,
                                    isLoading: this.state.isLoading,
                                    responsiveImportance: true,
                                    alternatingRowColours: true
                                }}
                                columns={[
                                    {
                                        heading: '',
                                        field: rowData => rowData.type === 'Customer' ? <FALightIcon icon='star' /> : <FALightIcon icon='building' />,
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Type',
                                        field: rowData => rowData.subType,
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Name',
                                        field: rowData => rowData.name,
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Phone',
                                        field: rowData => <Dial number={rowData.phone || '-'} />,
                                        important: true
                                    },
                                    {
                                        heading: 'Postcode',
                                        field: rowData => rowData.postcode || '-',
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Contacts',
                                        field: rowData => rowData.contacts,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Account Status',
                                        field: rowData =>   <>
                                                                {rowData.accountStatus != '-' &&
                                                                    <FASolidIcon 
                                                                        icon='circle'
                                                                        size='small'
                                                                        style={{color: (rowData.accountStatus == 'Active - Verified' ? colors.green : colors.red) }}
                                                                    />
                                                                }
                                                            {rowData.accountStatus}</>,
                                        sizeToContent: true
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {
                                                    name: 'Update', 
                                                    icon: 'pencil-alt', 
                                                    link: { pathname: rowData.type === 'Customer' ? `/customers/update/${rowData.id}` : `/customers/addresses/update/${rowData.id}`, state: {searchCriteria}},
                                                    disabled: rowData.type === 'Customer' ? !access.updateCustomer : !access.updateAddress
                                                },
                                                {
                                                    name: 'View', 
                                                    icon: 'search', 
                                                    link: { pathname: rowData.type === 'Customer' ? `/customers/view/${rowData.id}` : `/customers/addresses/view/${rowData.id}`, state: {searchCriteria}},
                                                    disabled: rowData.type === 'Customer' ? !access.viewCustomer : !access.viewAddress
                                                }
                                            ]
                                        }
                                    }
                                ]}
                                rows={this.state.searchResult}
                            />
                            <br></br>
                            <Typography variant="h6" gutterBottom>
                                Associated Results
                            </Typography>
                            <DataTable  
                                config={{
                                    pagination: false,
                                    isLoading: this.state.isLoading,
                                    responsiveImportance: true,
                                    alternatingRowColours: true
                                }}
                                columns={[
                                    {
                                        heading: '',
                                        field: rowData => rowData.type === 'Customer' ? <FALightIcon icon='star' /> : <FALightIcon icon='building' />,
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Type',
                                        field: rowData => rowData.subType,
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Name',
                                        field: rowData => rowData.name,
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Phone',
                                        field: rowData => <Dial number={rowData.phone || '-'} />,
                                        important: true
                                    },
                                    {
                                        heading: 'Postcode',
                                        field: rowData => rowData.postcode || '-',
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Contacts',
                                        field: rowData => rowData.contacts,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Account Status',
                                        field: rowData =>   <>
                                                                {rowData.accountStatus != '-' &&
                                                                    <FASolidIcon 
                                                                        icon='circle'
                                                                        size='small'
                                                                        style={{color: (rowData.accountStatus == 'Active - Verified' ? colors.green : colors.red) }}
                                                                    />
                                                                }
                                                            {rowData.accountStatus}</>,
                                        sizeToContent: true
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {
                                                    name: 'Update', 
                                                    icon: 'pencil-alt', 
                                                    link: { pathname: rowData.type === 'Customer' ? `/customers/update/${rowData.id}` : `/customers/addresses/update/${rowData.id}`, state: {searchCriteria}},
                                                    disabled: rowData.type === 'Customer' ? !access.updateCustomer : !access.updateAddress
                                                },
                                                {
                                                    name: 'View', 
                                                    icon: 'search', 
                                                    link: { pathname: rowData.type === 'Customer' ? `/customers/view/${rowData.id}` : `/customers/addresses/view/${rowData.id}`, state: {searchCriteria}},
                                                    disabled: rowData.type === 'Customer' ? !access.viewCustomer : !access.viewAddress
                                                }
                                            ]
                                        }
                                    }
                                ]}
                                rows={this.state.associatedResults}
                            />
                        </PaddedPaper>
                    </Grid>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearch);