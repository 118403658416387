import { TableFooter } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../Common/Paper/PaddedPaper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { Component } from 'react';

const styles = theme => ({
    tblHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    tblHeaderCell: {
        color:'white',
    },
    tblFooter: {
        backgroundColor: '#f2f2f2',
    }
});
class SupplierStockReturn extends Component {
    render() {
        const { classes, ssr } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container item spacing={3}>
                            <Grid item xs={6}>
                                <Grid container item spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Company Name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {ssr.sr_supplier_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Address
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {ssr.sr_supplier_address && ssr.sr_supplier_address.split('\n').map((item, key) => {
                                                return <span key={key}>{item}<br/></span>
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            FAO
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {ssr.sr_supplier_contact}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container item spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Reference
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {ssr.full_reference}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Completed By
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {ssr.sr_staff_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Date of Completion
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {moment(ssr.sr_date).format("DD/MM/YYYY")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Action Required
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {ssr.sr_return_type}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <br></br>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.tblHeader}>
                                        <TableCell className={classes.tblHeaderCell}>Deduct Stock</TableCell>
                                        <TableCell className={classes.tblHeaderCell}>Part</TableCell>
                                        {ssr.sr_return_type && ssr.sr_return_type === 'Credit' &&
                                            <TableCell className={classes.tblHeaderCell}>Nominal Code</TableCell>
                                        }
                                        <TableCell className={classes.tblHeaderCell}>Quantity</TableCell>
                                        {ssr.sr_return_type && ssr.sr_return_type === 'Credit' &&
                                            <React.Fragment>
                                                <TableCell className={classes.tblHeaderCell}>Unit Price</TableCell>
                                                <TableCell className={classes.tblHeaderCell}>Total Price</TableCell>
                                            </React.Fragment>
                                        }
                                        <TableCell className={classes.tblHeaderCell}>Return Description</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {ssr.stock_return_details && ssr.stock_return_details.map(row => (
                                    <TableRow key={row.srd_id}>
                                        <TableCell style={{verticalAlign:'top'}}>{row.srd_deduct_stock === 1 ? 'Yes' : 'No'}</TableCell>
                                        <TableCell style={{verticalAlign:'top'}}>{row.part.part_number + ' - ' + row.part.part_description}</TableCell>
                                        {ssr.sr_return_type && ssr.sr_return_type === 'Credit' &&
                                            <TableCell style={{verticalAlign:'top'}}>{row.nominal_code && row.nominal_code.nominal_code + ' - ' + row.srd_nominal_name + ' ('+row.nominal_code.category.nom_cat_name+')'}</TableCell>
                                        }
                                        <TableCell style={{verticalAlign:'top'}}>{row.srd_quantity}</TableCell>
                                        {ssr.sr_return_type && ssr.sr_return_type === 'Credit' &&
                                            <React.Fragment>
                                                <TableCell style={{verticalAlign:'top'}}>£{row.srd_part_unit_price}</TableCell>
                                                <TableCell style={{verticalAlign:'top'}}>£{row.srd_part_total_price}</TableCell>
                                            </React.Fragment>
                                        }
                                        <TableCell>
                                            {row.srd_return_description && row.srd_return_description.split('\n').map((item, key) => {
                                                return <span key={key}>{item}<br/></span>
                                            })}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow className={classes.tblFooter}>
                                        <TableCell colSpan={ssr.sr_return_type && ssr.sr_return_type === 'Credit' ? 3 : 2} style={{textAlign:'right'}}>Total</TableCell>
                                        <TableCell>{ssr.sr_total_parts}</TableCell>
                                        {ssr.sr_return_type && ssr.sr_return_type === 'Credit' ?
                                            <React.Fragment>
                                                <TableCell></TableCell>
                                                <TableCell>£{ssr.sr_total_price}</TableCell>
                                                <TableCell></TableCell>
                                            </React.Fragment>
                                        :
                                        <TableCell></TableCell>
                                        }
                                    </TableRow>
                                </TableFooter>
                            </Table>
                            <br></br>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={6}>
                                <Grid container item spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Company Name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            Clenaware Systems Ltd
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Address
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            44 Huxley Close<br></br>
                                            Park Farm Industrial Estate<br></br>
                                            Wellingborough<br></br>
                                            Northants<br></br>
                                            NN8 6AB
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            FAO
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            Accounts
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container item spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Company Name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            Clenaware Systems Ltd
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Address
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            44 Huxley Close<br></br>
                                            Park Farm Industrial Estate<br></br>
                                            Wellingborough<br></br>
                                            Northants<br></br>
                                            NN8 6AB
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            FAO
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {ssr.sr_staff_name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                    <br></br>
                </Grid>
            </Grid>
        );
    }
}

export default (withStyles(styles)(SupplierStockReturn));