import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableFooter } from '@material-ui/core';
import PaddedPaper from '../Common/Paper/PaddedPaper';

const styles = theme => ({
    tblHeader: {
        backgroundColor:  '#f2f2f2',
    },
    tblHeaderCell: {
        padding: '8px',
        fontWeight: 'bold'
    },
    tblCell: {
        padding: '8px'
    },
    tblFooter: {
        padding: '8px'
    },
});
class PartsPurchaseOrder extends Component {
    render() {
        const { classes, ppo } = this.props;
        return (
            <PaddedPaper>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Company Name
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_supplier_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Address
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_supplier_address && ppo.ppo_supplier_address.split('\n').map((item, key) => {
                                        return <span key={key}>{item}<br/></span>
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    FAO
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_supplier_contact}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Reference
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.full_reference}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Parts Order Date
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {moment(ppo.ppo_date).format("DD/MM/YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Order Date
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {moment(ppo.ppo_order_date).format("DD/MM/YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Order Placed By
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_placed_by}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Payment Terms
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_supplier_payment_terms}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Delivery Schedule
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    v{ppo.ppo_schedule_version}<br></br>
                                    <i>Please refer to the delivery schedule associated with this purchase order.</i>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                                <TableRow className={classes.tblHeader}>
                                    <TableCell className={classes.tblHeaderCell}>Part</TableCell>
                                    <TableCell className={classes.tblHeaderCell}>Nominal Code</TableCell>
                                    <TableCell className={classes.tblHeaderCell}>Suppliers Reference</TableCell>
                                    <TableCell className={classes.tblHeaderCell}>PDF Drawing</TableCell>
                                    <TableCell className={classes.tblHeaderCell} align="right">Quantity</TableCell>
                                    <TableCell className={classes.tblHeaderCell} align="right">Unit Price</TableCell>
                                    <TableCell className={classes.tblHeaderCell} align="right">Total Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {ppo.parts_order_details && ppo.parts_order_details.map(row => (
                                <TableRow key={row.ppo_detail_id}>
                                    <TableCell className={classes.tblCell}>
                                        {`${row.ppo_detail_part_number && (row.ppo_detail_part_number + ' - ')} ${row.ppo_detail_description}`}
                                        <br></br><i>{row.ppo_detail_notes}</i>
                                    </TableCell>
                                    <TableCell className={classes.tblCell}>{row.nominal_code && row.nominal_code.nominal_code + ' - ' + row.ppo_detail_nominal_name + ' ('+row.nominal_code.category.nom_cat_name+')'}</TableCell>
                                    <TableCell className={classes.tblCell}>{row.ppo_detail_supplier_reference}</TableCell>
                                    <TableCell className={classes.tblCell}>{row.ppo_detail_drawing_no}</TableCell>
                                    <TableCell className={classes.tblCell} align="right">{row.ppo_detail_qty}</TableCell>
                                    <TableCell className={classes.tblCell} align="right">£{row.ppo_detail_unit_price}</TableCell>
                                    <TableCell className={classes.tblCell} align="right">£{row.ppo_detail_total_price}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell className={classes.tblFooter} colSpan={6} style={{textAlign:'right'}}><b>Total Net Value Excluding VAT (GBP)</b></TableCell>
                                    <TableCell className={classes.tblFooter} align="right"><b>£{ppo.ppo_total_price}</b></TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>   
                        <br></br> 
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} style={{backgroundColor: '#f2f2f2'}}>
                                <b>Invoice To</b>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Company Name
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_invoice_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Address
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_invoice_address && ppo.ppo_invoice_address.split('\n').map((item, key) => {
                                        return <span key={key}>{item}<br/></span>
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    FAO
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_invoice_fao}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} style={{backgroundColor: '#f2f2f2'}}>
                                <b>Delivery To</b>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Company Name
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_delivery_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    Address
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_delivery_address && ppo.ppo_delivery_address.split('\n').map((item, key) => {
                                        return <span key={key}>{item}<br/></span>
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    FAO
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {ppo.ppo_delivery_fao}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </PaddedPaper>
        );
    }
}

export default (withStyles(styles)(PartsPurchaseOrder));