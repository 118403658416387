import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import DownloadCSV from 'Components/Common/Buttons/DownloadCSV';
import BarChart from 'Components/Common/Charts/BarChart';
import PieChart from 'Components/Common/Charts/PieChart';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import DateRangeDialog from './../DateRangeDialog';
import ReportCell from './../ReportCell';

import logo from 'Assets/Images/clenaware_logo_icon.png';

import { SystemColors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons from 'Helpers/IconHelper';

import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

import { setPersistence } from 'Actions/StatePersistence/StatePersistence';
import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';

const styles = theme => ({
    card: {
        color: '#000',
        backgroundColor: '#FFF',
        padding: '0'
    },
    row: {
        color: '#000',
        backgroundColor: '#FFF',
        '&:hover': {
            backgroundColor: '#FCFCFC',
            cursor: 'pointer'
        },
    },
    header: {
        background:'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)',
        color: '#FFF'
    },
    legendWrapper: {
        padding: '0 1rem'
    },
    legendTitle: {
        marginBottom: '0.8rem'
    },
    legendItem: {
        display: 'grid',
        gridTemplateColumns: '30px 60px auto',
        marginBottom: '0.4rem'
    },
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }

});

const colors = SystemColors();

const initialState = {
    filter:      {
        to:         moment(),
        from:       moment(),
    },
    overview:    {},
    parts:      {},
    isLoading:   true,
    showResults: false,
    formErrors:  null
}

const OverviewRow = ({data, classes, label}) => {
    return (
        <TableRow class={classes.row} sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
            <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  borderBottom: 0, textAlign:'center'}}>
                <AllIcon icon={icons[label]} size='medium'/>
                <Typography variant="h6" style={{textTransform: 'capitalize'}}>{label}</Typography>
            </TableCell>
            <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  borderBottom: 0}}>
                <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                    <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} primaryColor={colors.green} />
                    <Typography variant="h6" style={{fontSize: '14px'}}>
                        Invoiced
                    </Typography>
                </Box>
                <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                    <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} swapOpacity secondaryColor={colors.red} />
                    <Typography variant="h6" style={{fontSize: '14px'}}>
                        Credited
                    </Typography>
                </Box>
                <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                    <AllIcon icon={'equals'} size='medium' style={{display: 'inline-block', paddingRight: '0.5rem', color: '#2d2d2d'}} />
                    <Typography variant="h6" style={{fontSize: '14px'}}>
                        Total
                    </Typography>
                </Box>
            </TableCell>
            <ReportCell data={data} field={'qty'}   style={{borderBottom: 0}} hidePound forceFixed={2}/>
            <ReportCell data={data} field={'net'}   style={{borderBottom: 0}} />
            <ReportCell data={data} field={'vat'}   style={{borderBottom: 0}} />
            <ReportCell data={data} field={'gro'}   style={{borderBottom: 0}} />
        </TableRow>
    )
}

class SalesReportParts extends PureComponent {
    constructor(props) {
        super(props);
        this.clearPageState  = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState    = hasPageState.bind(this);
        this.savePageState   = savePageState.bind(this);
        switch (true){
            case     props.cid > 0:    this.persistenceId = `SalesReport:parts:cid:${props.cid}`;    break;
            case     props.partId > 0: this.persistenceId = `SalesReport:parts:pid:${props.partId}`; break;
            default:                   this.persistenceId = `SalesReport:parts`;                     break;
        }
        this.state           = this.getInitialState(initialState)
    }

    handleSetFilterParams = (filter) => { this.setState({filter, showResults: true}, this.getData)}

    getOverview = () => {
        const params = {params: {...this.state.filter, cid:this.props.cid, partId:this.props.partId}}
        API.get( '/reports/salesReport/parts/overview' , params)
        .then( res => {
            if (res.data.errors){
                this.setState({formErrors:formatValidationErrors(res.data.errors)})
            } else {
                this.setState({
                    overview:   (res.data.in.qty || res.data.out.qty) &&  {
                        services: {
                            in:     res.data.in.services,
                            out:    res.data.out.services
                        },
                        products: {
                            in:     res.data.in.products,
                            out:    res.data.out.products
                        },
                        total:    {
                            in:     {
                                qty: res.data.in.products.qty + res.data.in.services.qty,
                                net: res.data.in.products.net + res.data.in.services.net,
                                vat: res.data.in.products.vat + res.data.in.services.vat,
                                gro: res.data.in.products.gro + res.data.in.services.gro,
                            },
                            out:     {
                                qty: res.data.out.products.qty + res.data.out.services.qty,
                                net: res.data.out.products.net + res.data.out.services.net,
                                vat: res.data.out.products.vat + res.data.out.services.vat,
                                gro: res.data.out.products.gro + res.data.out.services.gro,
                            }
                        }
                    }
                },()=>{this.savePageState()});
            }
        } )
    }

    getParts = () => {
        const params = {params: {...this.state.filter, cid: this.props.cid, partId:this.props.partId}}
        API.get( '/reports/salesReport/parts' , params)
        .then( res => {
            if (!res.data.errors){
                this.setState({
                    parts: _.map(res.data, (i, name) => { return i; }),
                }, () => {
                    this.setState({isLoading:  false},()=>{this.savePageState()});
                })
            }
        } )
    }

    getData = () => {
        this.setState({isLoading: true}, ()=> {
            this.getOverview();
            this.getParts();
        })
    }

    loadPartImage = (rowData) => {
        this.props.deployDialog(
            <Grid container xs={12} spacing={3} style={{justifyContent:'center'}}>
                <Grid item>
                    <ImageWithError src={rowData.l} alt='Part Image' style={{maxWidth: 1000}}/>
                </Grid>
                <Grid item xs={12}><Button variant='outlined' onClick={this.props.closeDialog}>Close</Button></Grid>
            </Grid>
        ,`${rowData.n} - ${rowData.d}`,'sm')
    };

    getCsvData = () => {
        const {parts} = this.state;
        return _.map(parts, i => {
            return _.assign({
                '#':        i.i,
                'number':   i.n,
                'qty':      i.in.q - i.out.q,
                'net':      i.in.n - i.out.n,
                'vat':      i.in.v - i.out.v,
                'gross':    i.in.g - i.out.g,
            });
        })
    }

    render() {
        const { isLoading, filter, overview, parts, showResults } = this.state;
        const { classes }                                         =  this.props;
        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <DateRangeDialog callback={this.handleSetFilterParams}/>
                    </PaddedPaper>
                </Grid>
                {showResults &&
                    <>
                        {!_.isEmpty(overview) &&
                            <>
                                {isLoading ? <Grid item xs={12} lg={8}><LoadingCircle/></Grid> :
                                    <>
                                        <Grid item xs={12} lg={3}>
                                            <PaddedPaper>
                                                <PieChart
                                                    data={[
                                                        {label: 'Products',   value: parseFloat(overview.products.in.qty - overview.products.out.qty ).toFixed(2), color: colors.green},
                                                        {label: 'Services',   value: parseFloat(overview.services.in.qty - overview.services.out.qty ).toFixed(2), color: colors.blue},
                                                    ]}
                                                    style={{ height: 245 }}
                                                />
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={12} lg={5}>
                                            <PaddedPaper>
                                                <BarChart
                                                    data={[
                                                        {label: 'Products',   value: parseFloat(overview.products.in.net - overview.products.out.net).toFixed(2), color: colors.green},
                                                        {label: 'Services',   value: parseFloat(overview.services.in.net - overview.services.out.net).toFixed(2), color: colors.blue},
                                                    ]}
                                                    style={{ height: 245 }}
                                                    hideLegend
                                                    sidebar
                                                    xLabel={(v)=>{return `£${v}`}}
                                                    tLabel={(e)=>{return `£${parseFloat(e.raw).toFixed(2)}`}}
                                                />
                                            </PaddedPaper>
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12} lg={12}>
                                    <Card>
                                        <CardHeader
                                            avatar={<AllIcon icon={ icons['sales'] } size='medium' style={{color: '#fff'}} />}
                                            title={`Sales Report For ${
                                                filter.to === filter.from ?
                                                    moment(filter.from).format('dddd Do of MMMM') :
                                                    `${moment(filter.from).format('DD/MM/YYYY')} To ${moment(filter.to).format('DD/MM/YYYY')}`
                                                }`}
                                            titleTypographyProps={{variant:'h6'}}
                                            className={classes.header}
                                        />
                                        <CardContent style={{padding:5}}>
                                            <Table size="small" aria-label="a dense table" style={{color:'black'}}>
                                                <TableHead style={{backgroundColor:'#f5f5f5'}}>
                                                    <TableRow>
                                                        <TableCell align='center'   style={{width:'3%', borderBottom: 'none',  color:'black'}}></TableCell>
                                                        <TableCell align='center'   style={{width:'3%', borderBottom: 'none',  color:'black'}}></TableCell>
                                                        <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Qty</b></Typography></TableCell>
                                                        <TableCell align='center'   style={{width:'28%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Net</b></Typography></TableCell>
                                                        <TableCell align='center'   style={{width:'28%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Vat</b></Typography></TableCell>
                                                        <TableCell align='center'   style={{width:'28%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Gross</b></Typography></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <OverviewRow data={overview.products} classes={classes} label='products'/>
                                                    <OverviewRow data={overview.services} classes={classes} label='services'/>
                                                    <OverviewRow data={overview.total}    classes={classes} label='total'/>
                                                </TableBody>
                                            </Table>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </>
                        }
                        {isLoading ? <Grid item xs={12} lg={12}><LoadingCircle/></Grid> :
                            <>
                                {_.isEmpty(parts) ? <Grid item xs={12} style={{padding: '1em', textAlign: 'center', fontWeight: 'bold'}}>{'No Results For this Date Range'}</Grid> :
                                    <>
                                        <Grid item xs={12} lg={12} style={{padding:'1em 0px'}}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <DownloadCSV
                                                data={this.getCsvData()} color='primary' variant='contained'
                                                filename={`${filter.from}_to_${filter.to}_part_report`}
                                                style={{marginBottom: '1em'}}
                                            >download Report</DownloadCSV>
                                            <DataTable
                                                config={{
                                                    key: 'i',
                                                    pagination: false,
                                                    alternatingRowColours: true,
                                                    responsiveimportant: true,
                                                    isLoading: isLoading,
                                                    sticky:true
                                                }}
                                                columns={[
                                                    {
                                                        field: rowData =>   <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                                {rowData && rowData.l ?
                                                                                    <Button onClick={() => this.loadPartImage(rowData)}>
                                                                                        <ImageWithError src={rowData.l} alt={`${rowData.n} - ${rowData.d}`} style={{maxWidth: 35, maxHeight: 50}}/>
                                                                                    </Button>
                                                                                :
                                                                                    <img src={logo} alt="logo" style={{maxHeight:35, maxWidth:35}}/>
                                                                                }
                                                                            </div>,
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading: 'Number',
                                                        field: rowData => 
                                                            <Grid container spacing={0}
                                                                onClick={()=>{
                                                                    this.props.history.push(`/parts/view/${rowData.i}`);
                                                                }}
                                                                style={{cursor: 'pointer'}}
                                                            >
                                                                <Grid item xs={12}>{rowData.n}</Grid>
                                                            </Grid>,
                                                        dataRef: 'n',
                                                        important: true,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Description',
                                                        field: rowData => 
                                                            <Grid container spacing={0}
                                                                onClick={()=>{
                                                                    this.props.history.push(`/parts/view/${rowData.i}`);
                                                                }}
                                                                style={{cursor: 'pointer'}}
                                                            >
                                                                <Grid item xs={12}>{rowData.d}</Grid>
                                                            </Grid>,
                                                        dataRef: 'd',
                                                        sizeToContent: true,
                                                        truncate: true
                                                    },
                                                    {
                                                        heading: 'Part Type',
                                                        field: rowData => rowData.t,
                                                        dataRef: 't',
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Tot/Avg Cost',
                                                        field: rowData =>
                                                            <>
                                                                £{ parseFloat(rowData.c).toFixed(2)}<br/>
                                                                £{ (parseFloat(rowData.c) / ( parseFloat(rowData.in.q) - parseFloat(rowData.out.q) ) ).toFixed(2)}
                                                            </>,
                                                        dataRef: 'c',
                                                        important: true,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Avg net',
                                                        field: rowData => <>£{((parseFloat(rowData.in.n) + parseFloat(rowData.out.n)) / (parseFloat(rowData.in.q) + parseFloat(rowData.out.q))).toFixed(2)}</>,
                                                        dataRef: rowData => {
                                                            return parseFloat(parseFloat(rowData.in.n) + parseFloat(rowData.out.n)) / (parseFloat(rowData.in.q) + parseFloat(rowData.out.q))
                                                        },
                                                        important: true,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: '',
                                                        field: rowData =>
                                                            <Grid container xs={12} style={{textAlign: 'left'}}>
                                                                <Grid item xs={12}><AllIcon icon={'arrow-down-arrow-up'} size='small' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} primaryColor='#33dd33' />Invoiced</Grid>
                                                                <Grid item xs={12} ><AllIcon icon={'arrow-down-arrow-up'} size='small' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} swapOpacity secondaryColor={colors.red} />Credited</Grid>
                                                                <Grid item xs={12}><AllIcon icon={'equals'} size='small' style={{display: 'inline-block', paddingRight: '0.5rem', color: '#2d2d2d'}} />Total</Grid>
                                                            </Grid>,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Qty',
                                                        field: rowData =>
                                                            <Grid container xs={12} style={{textAlign: 'right'}}>
                                                                <Grid item xs={12}>{parseFloat(rowData.in.q).toFixed(2)}</Grid>
                                                                <Grid item xs={12} style={{color: colors.red}}>{parseFloat(rowData.out.q).toFixed(2)}</Grid>
                                                                <Grid item xs={12} style={{color:  parseFloat(rowData.in.q) - parseFloat(rowData.out.q) < 0 ? colors.red : null}}>
                                                                    {(parseFloat(rowData.in.q) - parseFloat(rowData.out.q)).toFixed(2)}
                                                                </Grid>
                                                            </Grid>,
                                                        dataRef: rowData => {return parseFloat(rowData.in.q) - parseFloat(rowData.out.q)},
                                                        sizeToContent: true,
                                                        alignment: 'right'
                                                    },
                                                    {
                                                        heading: 'Net',
                                                        field: rowData =>
                                                            <Grid container xs={12}  style={{textAlign: 'right'}}>
                                                                <Grid item xs={12}>£{parseFloat(rowData.in.n).toFixed(2)}</Grid>
                                                                <Grid item xs={12} style={{color: colors.red}}>{parseFloat(rowData.out.n) != 0 ? '-£' : '£'}{parseFloat(rowData.out.n).toFixed(2)}</Grid>
                                                                <Grid item xs={12} style={{color: parseFloat(rowData.in.n) - parseFloat(rowData.out.n) < 0 ? colors.red: null}}>
                                                                    {parseFloat(rowData.in.n) - parseFloat(rowData.out.n) < 0 ? '-£' : '£'}{( Math.sqrt( Math.pow( parseFloat(rowData.in.n) - parseFloat(rowData.out.n), 2 ) ) ).toFixed(2)}
                                                                </Grid>
                                                            </Grid>,
                                                        dataRef: rowData => {return parseFloat(rowData.in.n) - parseFloat(rowData.out.n)},
                                                        sizeToContent: true,
                                                        alignment: 'right'
                                                    },
                                                    {
                                                        heading: 'Vat',
                                                        field: rowData =>
                                                            <Grid container xs={12}  style={{textAlign: 'right'}}>
                                                                <Grid item xs={12}>£{parseFloat(rowData.in.v).toFixed(2)}</Grid>
                                                                <Grid item xs={12} style={{color: colors.red}}>{parseFloat(rowData.out.v) != 0 ? '-£' : '£'}{parseFloat(rowData.out.v).toFixed(2)}</Grid>
                                                                <Grid item xs={12} style={{color: parseFloat(rowData.in.v) - parseFloat(rowData.out.v) < 0 ? colors.red: null}}>
                                                                    {parseFloat(rowData.in.v) - parseFloat(rowData.out.v) < 0 ? '-£' : '£'}{( Math.sqrt( Math.pow( parseFloat(rowData.in.v) - parseFloat(rowData.out.v), 2 ) ) ).toFixed(2)}
                                                                </Grid>
                                                            </Grid>,
                                                        dataRef: rowData => {return parseFloat(rowData.in.v) - parseFloat(rowData.out.v)},
                                                        sizeToContent: true,
                                                        alignment: 'right'
                                                    },
                                                    {
                                                        heading: 'Gross',
                                                        field: rowData =>
                                                            <Grid container xs={12}  style={{textAlign: 'right'}}>
                                                                <Grid item xs={12}>£{parseFloat(rowData.in.g).toFixed(2)}</Grid>
                                                                <Grid item xs={12} style={{color: colors.red}}>{parseFloat(rowData.out.g) != 0 ? '-£' : '£'}{parseFloat(rowData.out.g).toFixed(2)}</Grid>
                                                                <Grid item xs={12} style={{color: parseFloat(rowData.in.g) - parseFloat(rowData.out.g) < 0 ? colors.red: null}}>
                                                                    {parseFloat(rowData.in.g) - parseFloat(rowData.out.g) < 0 ? '-£' : '£'}{( Math.sqrt( Math.pow( parseFloat(rowData.in.g) - parseFloat(rowData.out.g), 2 ) ) ).toFixed(2)}
                                                                </Grid>
                                                            </Grid>,
                                                        dataRef: rowData => {return parseFloat(rowData.in.g) - parseFloat(rowData.out.g)},
                                                        sizeToContent: true,
                                                        alignment: 'right'
                                                    },
                                                ]}
                                                rows={_.orderBy(parts, [(i) => { return ( parseFloat(i.out.q) + parseFloat(i.in.q) ) }, 'n'], ['desc','asc'] )}
                                            />
                                        </Grid>
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    setPersistence: (key, state)                => dispatch(setPersistence(key, state)),
    deployDialog:   (content, title, size='sm') => dispatch(deployDialog(content, title, null, size)),
    closeDialog:    ()                          => {dispatch(closeDialog())}
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SalesReportParts));