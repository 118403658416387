import React, { Component }         from 'react';
import { connect }                  from 'react-redux';

import { ButtonGroup, Button } from '@material-ui/core';

import { deployDialog }             from 'Actions/Dialog/Dialog';
import { closeDialog }              from 'Actions/Dialog/Dialog';

import AllIcon                      from 'Components/Common/Icons/AllIcon';
import BarclayCardDialog            from './BarclayCardDialog';
import BankTransferDialog           from './BankTransferDialog';
import StripeDialog                 from './StripeDialog';
import InvoiceDialog                from './InvoiceDialog';

import icons                        from 'Helpers/IconHelper';

class CreditNoteInvoicePaymentsDialog extends Component {
    constructor(props) {
        super(props);    
    }

    handleProcessPayment = method => {
        switch (method){
            case 'barclaycard': this.props.deployDialog(<BarclayCardDialog  id={this.props.id} cn={this.props.cn} callback={this.callback}/>,   'Barclaycard Refund',   'success'); break;
            case 'transfer':    this.props.deployDialog(<BankTransferDialog id={this.props.id} cn={this.props.cn} callback={this.callback}/>,   'Bank Transfer Refund', 'success'); break;
            case 'stripe':      this.props.deployDialog(<StripeDialog       id={this.props.id} cn={this.props.cn} callback={this.callback}/>,   'Stripe Refund',        'success'); break;
            case 'invoice':     this.props.deployDialog(<InvoiceDialog      id={this.props.id} cn={this.props.cn} callback={this.callback}/>,   'Assign To Invoice',    'success'); break;
            default: break;
        }
    }

    callback = () => {
        this.props.callback();
        this.props.closeDialog();
    }

    render() {
        return (
            <>
                <br /><br />
                <div style={{textAlign:'center' }}>
                    <ButtonGroup aria-label="outlined primary button group">
                        <Button 
                            startIcon={<AllIcon icon={icons.card}/>}
                            onClick={() => this.handleProcessPayment('barclaycard')}
                        >
                            BarclayCard EPDQ
                        </Button>
                        <Button 
                            startIcon={<AllIcon icon={icons.bank} />}
                            onClick={() => this.handleProcessPayment('transfer')}
                        >
                            Bank Transfer
                        </Button>
                        <Button 
                            startIcon={<AllIcon icon={icons.stripe} />}
                            onClick={() => this.handleProcessPayment('stripe')}
                        >
                            Stripe
                        </Button>
                        <Button 
                            startIcon={<AllIcon icon={icons.invoice} />}
                            onClick={() => this.handleProcessPayment('invoice')}
                        >
                            Invoice
                        </Button>
                    </ButtonGroup>
                </div>
                <br /><br />
                <div className="buttonRow">
                    <Button 
                        variant="outlined" 
                        color="default" 
                        onClick={this.props.closeDialog}
                    >
                        Cancel
                    </Button>
                </div>
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployDialog:           (content, title, variant)               => dispatch(deployDialog(content, title, variant)),
    closeDialog:            ()                                      => dispatch(closeDialog())
})

export default connect(null, mapDispatchToProps)(CreditNoteInvoicePaymentsDialog);