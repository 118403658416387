import React, { PureComponent } from 'react';

import { Grid, Paper, Typography } from '@material-ui/core/';

import AccountReportCredit from './AccountReportCredit/AccountReportCredit';

import _ from 'lodash';

const initialState = {
    currentTab: 0,
    filtered:   null,
    filter:     {
        dateFrom:               null,
        dateTo:                 null,
        includeSalesStatuses:   [],
    }
}

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

class AccountReports extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        });
    };

    render() {
       
        const {currentTab} = this.state;

        const tabs = [
            { label:"Credit" },
        ]

        return (
            <Grid container xs={12} style={{width: '100%'}}>
                <Grid item xs={12} style={{ marginBottom: '1.5em'}}>
                    {( this.props.partId || this.props.cid ) ?
                        <></> :
                        <Typography variant="h5">
                            Accounts Reports
                        </Typography>
                    }
                </Grid>
                {/*
                <AppBar position="static" style={{backgroundColor:'white', color:"#000", minWidth: 1522, width: '100%'}} >
                    <Tabs   value={currentTab} 
                            onChange={this.handleTabChange} 
                            className='xs-max-width' 
                            indicatorColor="primary" 
                            textColor="primary" 
                            variant='scrollable'
                            scrollButtons="auto"
                    >
                        {_.map(tabs, (i, idx) => <Tab label={i.label} value={idx} style={i.style} />)}
                    </Tabs>
                </AppBar>
                */}
                {currentTab === _.findIndex(tabs, i => i.label === 'Credit') && 
                    <Paper style={{width:'100%'}}>
                        <Grid container xs={12} >
                            <Grid item xs={12}>
                                <TabContainer>
                                    <AccountReportCredit partId={this.props.partId} cid={this.props.cid} />
                                </TabContainer>
                            </Grid>
                        </Grid>
                    </Paper>
                }
            </Grid>
        )
    }
};

export default AccountReports;

