import React, { Component } from "react";

import moment from "moment";

import Image from "Components/Common/ImageWithError/ImageWithError";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
    tblHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    tblHeaderCell: {
        color: "white",
    },
    tblFooter: {
        backgroundColor: "#f2f2f2",
    },
});

class DeliveryNote extends Component {
    trimPartDescription = (partDescription) => {
        if (partDescription) {
            if (partDescription.length > 40) {
                partDescription = partDescription.substring(0, 40) + "...";
            }
        } else {
            partDescription = "";
        }
        return partDescription;
    };

    generateWarrantyOrSerialNumbers = (serial, warranty) => {
        let serialString = JSON.parse(serial); // stored in json array
        serialString = serialString.length > 0 ? serialString : null;
        let warrantyString = JSON.parse(warranty); // stored in json array
        warrantyString = warrantyString.length > 0 ? warrantyString : null;

        let newString = serialString ?? warrantyString ?? null;

        let spans = [];

        if (newString) {
            newString.forEach((el, i) => {
                spans.push(
                    <Typography key={i} component="p">
                        {el}
                    </Typography>
                );
            });
            return spans;
        } else {
            return "-";
        }
    };

    render() {
        const { classes, cd } = this.props;
        const customerPhone =
            cd.desp_delivery_cust_contact_phone && cd.desp_delivery_cust_contact_mobile ? 
                cd.desp_delivery_cust_contact_phone + " / " + cd.desp_delivery_cust_contact_mobile
                : 
                (cd.desp_delivery_cust_contact_phone ? 
                    cd.desp_delivery_cust_contact_phone
                    : 
                    cd.desp_delivery_cust_contact_mobile);

        return (
            <React.Fragment>
                <Grid container item spacing={1}>
                    <Grid item xs={6}>
                        <Grid container item spacing={1}>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Company Name</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {cd.desp_delivery_cust_address_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Delivery Address</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {cd.desp_delivery_cust_address
                                        .split("\n")
                                        .map((item, key) => {
                                            return (
                                                <span key={key}>
                                                    {item}
                                                    {cd.desp_delivery_cust_address.split(
                                                        "\n"
                                                    ).length ===
                                                    key + 1 ? null : (
                                                        <br />
                                                    )}
                                                </span>
                                            );
                                        })}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>FAO</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {cd.desp_delivery_cust_contact_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Contact Phone</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {customerPhone}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Email Address</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {cd.desp_delivery_cust_contact_email}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container item spacing={1}>
                            <Grid item xs={5}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Date Order Placed</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body1" gutterBottom>
                                    {moment(cd.desp_order_date).format(
                                        "DD/MM/YYYY"
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Order Placed By</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body1" gutterBottom>
                                    {cd.desp_cust_contact_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Order Processed By</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body1" gutterBottom>
                                    {cd.desp_order_staff_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>
                                        Purchase Order Number
                                    </strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body1" gutterBottom>
                                    {cd.desp_po_details}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Order Reference</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body1" gutterBottom>
                                    {cd.order_full_reference}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Delivery Reference</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body1" gutterBottom>
                                    {cd.full_reference}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body1">
                                    <strong>Date Picked</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body1" gutterBottom>
                                    {moment(cd.desp_picked_date).format("DD/MM/YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Order Actioned By</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body1" gutterBottom>
                                    {cd.desp_picked_staff_name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <br />
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tblHeader}>
                                <TableCell
                                    className={classes.tblHeaderCell}
                                ></TableCell>
                                <TableCell className={classes.tblHeaderCell}>
                                    Part Number
                                </TableCell>
                                <TableCell className={classes.tblHeaderCell}>
                                    Part Description
                                </TableCell>
                                <TableCell className={classes.tblHeaderCell}>
                                    Warranty /<br />
                                    Serial Number
                                </TableCell>
                                <TableCell
                                    className={classes.tblHeaderCell}
                                    style={{ textAlign: "right" }}
                                >
                                    Qty <br />
                                    Ordered
                                </TableCell>
                                <TableCell
                                    className={classes.tblHeaderCell}
                                    style={{ textAlign: "right" }}
                                >
                                    Qty <br />
                                    Required
                                </TableCell>
                                <TableCell
                                    className={classes.tblHeaderCell}
                                    style={{ textAlign: "right" }}
                                >
                                    Qty <br />
                                    Picked
                                </TableCell>
                                <TableCell
                                    className={classes.tblHeaderCell}
                                    style={{ textAlign: "right" }}
                                >
                                    Qty<br />
                                    Already Sent
                                </TableCell>
                                <TableCell
                                    className={classes.tblHeaderCell}
                                    style={{ textAlign: "right" }}
                                >
                                    Qty <br />
                                    To Follow
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cd.despatch_details &&
                                cd.despatch_details.map((row, key) => (
                                    <TableRow
                                        style={{
                                            borderBottom:
                                                "1px solid rgb(224, 224, 224)",
                                        }}
                                        key={key}
                                    >
                                        <TableCell
                                            style={{
                                                height: 90,
                                                textAlign: 'center',
                                                verticalAlign: 'top'
                                            }}
                                        >
                                            <Image
                                                src={
                                                    row?.part?.default_image
                                                        ?.thumbnail_file_path
                                                }
                                                style={{
                                                    overflow: "visible",
                                                    maxHeight: 45, 
                                                    maxWidth: 45
                                                }}
                                                alt=""
                                            />
                                        </TableCell>
                                        <TableCell
                                            style={{ 
                                                whiteSpace: "nowrap",
                                                verticalAlign: 'top'
                                            }}
                                        >
                                            {row.odd_part_number}
                                        </TableCell>
                                        <TableCell
                                            style={{ 
                                                whiteSpace: "nowrap",
                                                verticalAlign: 'top'
                                            }}
                                        >
                                            {this.trimPartDescription(
                                                row.odd_part_description
                                            )}
                                            {row.order_detail.od_notes &&
                                                <>
                                                    <br/>
                                                    <span style={{color: '#757575', fontSize: '12px', whiteSpace: 'pre-wrap'}}>
                                                        {row.order_detail.od_notes}
                                                    </span>
                                                </>
                                            }
                                            <br />
                                            {row.odd_electrical_connection ? <> <span style={{color: '#757575', fontSize: '12px'}}>{`${row.odd_electrical_connection} Connection`} </span><br></br> </>: ''}
                                            <span
                                                style={{
                                                    color: "#757575",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {row.odd_warranty_name !==
                                                    null &&
                                                row.odd_warranty_duration !==
                                                    null
                                                    ? row.odd_warranty_name
                                                    : ""}
                                            </span>
                                        </TableCell>
                                        <TableCell style={{verticalAlign: 'top'}}>
                                            {this.generateWarrantyOrSerialNumbers(
                                                row.odd_serial_numbers,
                                                row.odd_warranty_labels
                                            )}
                                        </TableCell>
                                        <TableCell
                                            style={{ 
                                                textAlign: "right",
                                                verticalAlign: 'top'
                                            }}
                                        >
                                            {parseFloat(
                                                row.odd_quantity_ordered
                                            )}
                                        </TableCell>
                                        <TableCell
                                            style={{ 
                                                textAlign: "right",
                                                verticalAlign: 'top'
                                            }}
                                        >
                                            {parseFloat(
                                                row.odd_quantity_required
                                            )}
                                        </TableCell>
                                        <TableCell
                                            style={{ 
                                                textAlign: "right",
                                                verticalAlign: 'top'
                                            }}
                                        >
                                            {parseFloat(row.odd_quantity_picked)}
                                        </TableCell>
                                        <TableCell
                                            style={{ 
                                                textAlign: "right",
                                                verticalAlign: 'top'
                                            }}
                                        >
                                            {parseFloat(row.odd_quantity_already_sent)}
                                        </TableCell>
                                        <TableCell
                                            style={{ 
                                                textAlign: "right",
                                                verticalAlign: 'top'
                                            }}
                                        >
                                            {parseFloat(
                                                row.odd_quantity_to_follow
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <br/>
                </Grid>
                <Grid container item spacing={1}>
                    <Grid item xs={4}>
                        <Grid item xs={12}
                            style={{
                                backgroundColor: "#0282C6",
                                color: "white",
                                textAlign: "center",
                            }}
                        >
                            Total Parts Weight
                        </Grid>
                        <Grid item xs={12} style={{textAlign: "center"}}>
                            {cd.total_part_weight_kilos} kg
                        </Grid>
                    </Grid>
                    {cd.total_shipping_weight_kilos > 0 &&
                        <Grid item xs={4}>
                            <Grid item xs={12}
                                style={{
                                    backgroundColor: "#0282C6",
                                    color: "white",
                                    textAlign: "center",
                                }}
                            >
                                Total Shipping Weight
                            </Grid>
                            <Grid item xs={12} style={{textAlign: "center"}}>
                                {cd.total_shipping_weight_kilos} kg
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={cd.total_shipping_weight_kilos > 0 ? 4 : 8}>
                        <Grid
                            item
                            xs={12}
                            style={{
                                backgroundColor: "#0282C6",
                                color: "white",
                                textAlign: "center",
                            }}
                        >
                            Any Courier Notes
                        </Grid>
                        <Grid item xs={12} style={{textAlign: "center"}}>
                            {cd.desp_courier_notes
                                ? cd.desp_courier_notes
                                    .split("\n")
                                    .map((item, key) => {
                                        return (
                                            <span key={key}>
                                                {item}
                                                <br />
                                            </span>
                                        );
                                    })
                                : "No Courier Notes"}
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DeliveryNote);
