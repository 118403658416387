import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import BarChart from 'Components/Common/Charts/BarChart';
import LineChart from 'Components/Common/Charts/LineChart';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { fmonths, getColorCurrency, getColorPercentageChange, months } from 'Helpers/ReportsHelper';
import { clenyDate, getOrdinal } from 'Helpers/TimeHelper';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const currentMonth = moment().month() + 1;

const ConsistentSalesRow = ({icon, data, comparator, showRed, showComparator}) => {
	return (
		<>
			<Grid item xs={showComparator ? 2 : 3} style={{textAlign:'center'}}><AllIcon icon={icon} size='xs' /></Grid>
			<Grid item xs={showComparator ? 4 : 9} style={{color: showRed && colors.red}}>{data < 0 ? '-' : ''}£{parseFloat(Math.abs(data)).toFixed(2)}</Grid>
            {showComparator && <Grid item xs={6}>{comparator}</Grid> }
        </>
	)
}

class SalesReportYear extends React.Component {

    render() {
        const {year, current, isLoading, data} = this.props;

        let currentYear = `${year-1}/${year}`;

        return (
            <Grid item xs={12}>
                <Card style={{color: '#000',backgroundColor: '#FFF',padding: 0}} >
                    <CardHeader
                        avatar={<AllIcon icon={IconHelper.year} size='medium' style={{color: '#fff'}} noMargin/>}
                        title={
                            <Grid container spacing={1} style={{alignItems: 'flex-end'}}>
                                <Grid item >
                                    <>{currentYear} Budget</>
                                </Grid>
                                <Grid item >
                                    <Typography variant="body1" style={{paddingBottom: 1}} >{current ? ` Up Until ${getOrdinal(moment().format('D'))} ${moment().format('MMMM YYYY')}` : ''}</Typography>
                                </Grid>
                            </Grid>
                        }
                        titleTypographyProps={{variant:'h6'}}
                        style={{background: 'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', color: '#FFF'}}
                    />
                    <CardContent style={{padding:15}}>
                        <Grid container spacing={2}>
                            {isLoading ?
                                <Grid item xs={12}>
                                    <LoadingCircle/>
                                </Grid> :
                                <>
                                    <Grid item xs={3}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>{currentYear}</b></Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PaddedPaper style={{padding: 10}}>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Typography gutterBottom variant='caption'><b>Total {current ? `To ${clenyDate(moment())}` : ''}</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={6} style={{marginLeft: 'auto'}}>
                                                            <Typography gutterBottom variant='caption'><b>Difference</b></Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                        {
                                                            _.map([
                                                                {
                                                                    icon: IconHelper.products,
                                                                    k: 'p'
                                                                },
                                                                {
                                                                    icon: IconHelper.services,
                                                                    k: 's'
                                                                },
                                                                {
                                                                    icon: IconHelper.total,
                                                                    k: 't'
                                                                },
                                                            ], ({k, icon}) => 
                                                            
                                                                <ConsistentSalesRow icon={icon} data={data.t.c.t[k].b} comparator={
                                                                    <Grid container spacing={1}>
                                                                        <Grid item xs={7}>
                                                                            {getColorCurrency(data.t.c.t[k].b - data.b.c.t[k].b , false, false)}
                                                                        </Grid>
                                                                        
                                                                        <Grid item xs={5}>
                                                                            {getColorPercentageChange(data.t.c.t[k].b, data.b.c.t[k].b, false, false)}
                                                                        </Grid> 
                                                                    </Grid>
                                                                } showComparator />
                                                            )
                                                        }
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Budget</b></Typography>
                                            </Grid>
                                            {!!current &&
                                                <Grid item xs={6}>
                                                    <PaddedPaper style={{padding: 10}}>
                                                        <Typography gutterBottom variant='caption'><b>Budget {current ? `To ${clenyDate(moment())}` : ''}</b></Typography>
                                                        <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                            {_.map([
                                                                {
                                                                    icon: IconHelper.products,
                                                                    k: 'p'
                                                                },
                                                                {
                                                                    icon: IconHelper.services,
                                                                    k: 's'
                                                                },
                                                                {
                                                                    icon: IconHelper.total,
                                                                    k: 't'
                                                                },
                                                            ], ({k, icon}) => 
                                                            <ConsistentSalesRow icon={icon} data={data.b.c.t[k].b} />
                                                        )}
                                                        </Grid>
                                                    </PaddedPaper>
                                                </Grid>
                                            }
                                            <Grid item xs={current ? 6 : 12}>
                                                <PaddedPaper style={{padding: 10}}>
                                                    <Typography gutterBottom variant='caption'><b>Budget Year</b></Typography>
                                                    <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                        {_.map([
                                                            {
                                                                icon: IconHelper.products,
                                                                k: 'p'
                                                            },
                                                            {
                                                                icon: IconHelper.services,
                                                                k: 's'
                                                            },
                                                            {
                                                                icon: IconHelper.total,
                                                                k: 't'
                                                            },
                                                        ], ({k, icon}) => 
                                                        <ConsistentSalesRow icon={icon} data={data.b.t.t[k].b} />
                                                    )}
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Difference</b></Typography>
                                            </Grid>
                                            {!!current &&
                                                <Grid item xs={6}>
                                                    <PaddedPaper style={{padding: 10}}>
                                                        <Typography gutterBottom variant='caption' style={{paddingBottom: 8}}><b>Total {current ? `To ${clenyDate(moment())}` : ''}</b></Typography>
                                                        <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                            <Grid item={3}>
                                                                <AllIcon 
                                                                    size={65} 
                                                                    icon={data.t.c.t.t.b > data.b.c.t.t.b ? IconHelper.arrowStemUp : (data.t.c.t.t.b < data.b.c.t.t.b ?  IconHelper.arrowStemDown : IconHelper.dash)} 
                                                                    color={data.t.c.t.t.b > data.b.c.t.t.b ? colors.green : (data.t.c.t.t.b < data.b.c.t.t.b ? colors.red : colors.grey)} 
                                                                />
                                                            </Grid>
                                                            <Grid item={9}>
                                                                <Typography variant='body1'>{getColorPercentageChange(data.t.c.t.t.b, data.b.c.t.t.b, false, false)}</Typography>
                                                                <Typography variant='h5'>{getColorCurrency(data.t.c.t.t.b - data.b.c.t.t.b , false, false)}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </PaddedPaper>
                                                </Grid>
                                            }
                                            <Grid item xs={current ? 6 : 12}>
                                                <PaddedPaper style={{padding: 10}}>
                                                    <Typography gutterBottom variant='caption' style={{paddingBottom: 8}}><b>Total Year</b></Typography>
                                                    <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                        <Grid item={3}>
                                                            <AllIcon 
                                                                size={65} 
                                                                icon={data.t.t.t.t.b > data.b.t.t.t.b ? IconHelper.arrowStemUp : (data.t.t.t.t.b < data.b.t.t.t.b ?  IconHelper.arrowStemDown : IconHelper.dash)} 
                                                                color={data.t.t.t.t.b > data.b.t.t.t.b ? colors.green : (data.t.t.t.t.b < data.b.t.t.t.b ? colors.red : colors.grey)} 
                                                            />
                                                        </Grid>
                                                        <Grid item={9}>
                                                            <Typography variant='body1'>{getColorPercentageChange(data.t.t.t.t.b, data.b.t.t.t.b, false, false)}</Typography>
                                                            <Typography variant='h5'>{getColorCurrency(data.t.t.t.t.b - data.b.t.t.t.b , false, false)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Month On Month</b></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Running Total</b></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <PaddedPaper>
                                            <BarChart 
                                                data={_.map(_.orderBy(_.keys(data.b.c.m), i => fmonths.indexOf( months[ parseInt(i) ]), 'asc'), idx => {
                                                    let label = months[ parseInt(idx) ];
                                                    let nullMonth = current ? (fmonths.indexOf( label ) <= fmonths.indexOf( months[ parseInt(currentMonth) ]) ? 0 : 1 ) : 0;
                                                    let today = current && label === months[ parseInt(currentMonth) ];
                                                    return {
                                                        label: `${label.slice(0,3)}`,
                                                        value: {
                                                            [currentYear] : nullMonth ? null : parseFloat(data.t.c.m[idx].t.b),
                                                            'Budget' :  nullMonth ? null : parseFloat(data.b.c.m[idx].t.b),
                                                        },
                                                        color: {
                                                            [currentYear] : colors.primary,
                                                            'Budget' :      colors.red,
                                                        },
                                                        bold: today,
                                                    }
                                                    
                                                })}
                                                yLabel={i => `${i<0?'-':''}£${Math.abs(i)}`}
                                                tLabel={(e)=>`${parseFloat(e.raw)<0?'-':''}£${parseFloat(Math.abs(e.raw)).toFixed(2)}`}
                                                style={{
                                                    height: 300,
                                                }}
                                            />
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <PaddedPaper>
                                            
                                            <LineChart
                                                data={[
                                                        {
                                                            label:'',
                                                            value: {
                                                                [currentYear] : 0,
                                                                'Budget' :    0,
                                                            },
                                                            color: {
                                                                [currentYear] : colors.primary,
                                                                'Budget' :     colors.red,
                                                            },
                                                        },
                                                        ..._.map(_.orderBy(_.keys(data.b.c.m), i => fmonths.indexOf( months[ parseInt(i) ]), 'asc'), idx => {
                                                            let label = months[ parseInt(idx) ];
                                                            let nullMonth = current ? (fmonths.indexOf( label ) <= fmonths.indexOf( months[ parseInt(currentMonth) ]) ? 0 : 1 ) : 0;
                                                            let today = current && label === months[ parseInt(currentMonth) ];
    
                                                            return {
                                                                label: `${label.slice(0,3)}`,
                                                                // label: `${label.slice(0,3)}${(today ? ` (${getOrdinal(moment().format('D'))})` : '')}`,
                                                                value: {
                                                                    [currentYear] : nullMonth ? null : parseFloat(data.t.c.mr[idx].t.b),
                                                                    'Budget' : parseFloat(data.b.t.mr[idx].t.b),
                                                                },
                                                                color: {
                                                                    [currentYear] : colors.primary,
                                                                    'Budget' :      colors.red,
                                                                },
                                                                bold: today,
                                                            }
                                                        })
                                                    ]
                                                }
                                                yLabel={i => `${i<0?'-':''}£${Math.abs(i)}`}
                                                tLabel={(e)=>`${parseFloat(e.raw)<0?'-':''}£${parseFloat(Math.abs(e.raw)).toFixed(2)}`}
                                                style={{
                                                    height: 300,
                                                }}
                                                noPoints
                                            />
                                        </PaddedPaper>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

export default SalesReportYear;