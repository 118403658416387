import React                                                       from 'react';
import { connect }                                                 from 'react-redux';
import API                                                         from 'API';
import {DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE} from 'Constants'

import { Button, Grid, TextField,Typography } from '@material-ui/core';

import FALightIcon                  from 'Components/Common/Icons/FontAwesome/FALightIcon';
import DataTable                    from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper                  from 'Components/Common/Paper/PaddedPaper';
import { ReduxAvatar, UserAvatar }  from 'Components/Common/Badges/Avatar';
import AutoCompleteSelect           from 'Components/Common/Selects/AutoCompleteSelect';

import { gridPageChanged, gridRowsPerPageChanged }                      from 'Functions/FormFunctions'
import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';

import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

const initialState = () => ({
    searchResults: {},
    searchString: '',
    searchStatus: 'active',
    access: {
        updateStaff: false,
        staffAccess: false,
        viewStaff: false
    },
    gridPagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    },
    dataLoading: true
});

class StaffSearch extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState         = clearPageState.bind(this);
        this.getInitialState        = getInitialState.bind(this);
        this.hasPageState           = hasPageState.bind(this);
        this.savePageState          = savePageState.bind(this);
        this.persistenceId          = 'Staff:Search';
        this.state                  = this.getInitialState(initialState());
        this.gridPageChanged        = gridPageChanged.bind(this);
        this.gridRowsPerPageChanged = gridRowsPerPageChanged.bind(this);
    }
    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-staff'), 
            API.get('/staff/my/access/check/view-staff'),
            API.get('/staff/my/access/check/staff-access')
        ])
        .then(([updateStaffRes, viewStaffRes, staffAccessRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    updateStaff: (updateStaffRes.data && updateStaffRes.data.has_access) || false,
                    viewStaff: (viewStaffRes.data && viewStaffRes.data.has_access) || false,
                    staffAccess: (staffAccessRes.data && staffAccessRes.data.has_access) || false
                }
            });
        })
    }
    componentDidMount = () => {
        this.getSearchData();
        this.getAccess();
    }
    componentDidUpdate(prevProps) { 
        if (prevProps.location.state !== this.props.location.state) {
            this.setQuery();
        }
    }
    getSearchData = () => {
        this.setState({
            dataLoading: true
        }, () => {
            API.get('/staff/all', { params: { searchString: this.state.searchString, searchStatus: this.state.searchStatus }, props: { cancellation: true } }).then(result => {
                this.setState({
                    dataLoading: false
                });
                if(result?.data) {
                    this.setState({
                        searchResults: result.data
                    },()=>{this.savePageState()});
                } else {
                    /* Error */
                }
            });
        });
    }
    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value,
            gridPagination: initialState().gridPagination
        },
        () => {
            this.savePageState()
            this.getSearchData();
        });
    }
    resetSearch = () => {
        this.clearPageState();
        this.setState({
            searchString: '',
            searchStatus: 'all'
        }, 
        () => {
           // this.props.history.replace();
            this.getSearchData();
        });
    }
    changeSearchStatus = (e) => {
        this.setState({
            searchStatus: e.value
        }, () => {
            this.savePageState();
            this.getSearchData();
        });
    }
    render() {
        const { access, dataLoading, gridPagination, searchString, searchStatus } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Staff Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Grid container spacing={1}>
                                    <Grid item xs={6} >
                                        <TextField id="nameSearch" label="Staff Name" fullWidth value={searchString} onChange={this.onSearchStringChange} />
                                    </Grid>
                                    <Grid item xs={3} >
                                        <AutoCompleteSelect
                                            disableSort
                                            options={[
                                                {value: 'all',      label: 'All'},
                                                {value: 'active',   label: 'Active'},
                                                {value: 'inactive', label: 'Inactive'},
                                            ]}
                                            value={searchStatus}
                                            onChange={this.changeSearchStatus}
                                            fullWidth
                                            label="Status"
                                            noClear
                                        />
                                    </Grid>
                                    <Grid item xs={3} className='buttonRow'>
                                        <Button onClick={this.resetSearch}
                                                variant="outlined"
                                                color="default"
                                            
                                                >
                                            <FALightIcon icon='undo' button />
                                            Reset Search
                                        </Button>
                                    </Grid>
                                </Grid>
                                
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key:                        'staff_id',
                                pagination:                 true,
                                //page: gridPagination.page,
                                //rowsPerPage: gridPagination.rowsPerPage,
                                //gridPageChanged: this.gridPageChanged,
                                //gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                persistenceId:              this.persistenceId,
                                alternatingRowColours:      true,
                                isLoading:                  dataLoading,
                                responsiveImportance:       true
                            }}
                            columns={[
                                {
                                    heading: '',
                                    field: rowData => {
                                        if(this.props.status) {
                                            if(this.props.status[rowData.staff_id]) {
                                                return ReduxAvatar(this.props.status[rowData.staff_id])
                                            } else {
                                                return UserAvatar(rowData)
                                            }
                                        } else {
                                            return UserAvatar(rowData)
                                        }
                                    },
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Name',
                                    field: rowData => (
                                        <React.Fragment>
                                            {rowData.staff_first_name} {rowData.staff_last_name}
                                        </React.Fragment>
                                    ),
                                    important: true,
                                    main: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Email',
                                    field: rowData => rowData.staff_email,
                                    important: true,
                                    truncate: true
                                },
                                {
                                    heading: 'MFA',
                                    field: rowData => rowData.staff_mfa === 1 ? "Enabled" : "-",
                                    sizeToContent: true,
                                    alignment: 'center'
                                },
                                {
                                    heading: 'Status',
                                    field: rowData => rowData.staff_status === "active" ? "Active" : "Inactive",
                                    sizeToContent: true,                                
                                    alignment: 'center'
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil-alt', link: { pathname: '/staff/update/' + rowData.staff_id, state: {searchString, gridPagination}}, disabled: !access.updateStaff},
                                            {name: 'Access', icon: 'lock-alt', link: { pathname: '/staff/access/' + rowData.staff_id, state: {searchString, gridPagination}}, disabled: !access.staffAccess},
                                            {name: 'View', icon: 'search', link: { pathname: '/staff/view/' + rowData.staff_id, state: {searchString, gridPagination}}, disabled: !access.viewStaff}
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence:   state.statePersistence,
    status:             state.notifications.status
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(StaffSearch);