import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DatePicker from '../../Common/DatePickers/DatePicker';
import moment from 'moment';
import API from '../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from '../../Common/SnackBars/SnackBar';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import { convertVideoUploadsName } from  '../../../Helpers/DocumentHelper';
import Textarea from '../../Common/Inputs/Textarea';
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData: {
        title: '',
        description: '',
        dateOfChange: moment().toString(),
        approvedBy: '',
        category: '',
        subCategory: '',
        video: '',
        detailsOfChange: '',
        version: null
    },
    currentVersion: '-',
    newVersion: '-',
    fileUploads: '-',
    categoryList: [],
    subCategoryList: [],
    videoList: [],
    staffList: [],
    categories: {},
    videos: {},
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
}

class AddVideoUpdateOrder extends Component {
    constructor(props) {
        super(props);
    
        this.state = initialState;
    }

    componentDidMount(){
        this.getCategories();
        this.getVideos();
        this.getStaff();
    }

    getStaff = () => {
        API.get('/staff/all', { params: { active: true } })
        .then(result => {
            if(result.data) {
                let staffList = _.map(result.data, (el) => {
                    return _.assign({
                        value: el.staff_id,
                        label: el.staff_first_name + ' ' + el.staff_last_name
                    });
                });
                this.setState({
                    staffList: staffList
                });
            }
        });
    }

    getCategories = () => {
        API.get('/videos/categories/all').then(result => {
            if(result.data) {
                let categoryList = _.map(result.data, (el) => {
                    return _.assign({
                        value: el.video_cat_id,
                        label: el.video_cat_name
                    });
                });
                this.setState({
                    categoryList: categoryList,
                    categories: result.data
                });
            }
        });
    };

    getVideos = () => {
        API.get('/videos/all').then(result => {
            if(result.data) {
                this.setState({
                    videos: result.data
                });
            }
        });
    };

    handleDateChange = date => {
        this.setState({
            formData: {
                ...this.state.formData,
                dateOfChange: date
            }
        });
    };

    handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        const formData = {
            ...this.state.formData,
            dateOfChange: (moment(new Date(this.state.formData.dateOfChange)).format('DD/MM/YYYY'))
        }

        API.post('/videos/updateOrders', formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState(initialState);
                this.setState({
                    snackbarOpen: true
                });
                this.getCategories();
                this.getVideos();
                this.getStaff();
            }
            this.props.scrollToTop();
        });
    }

    populateSubCategories = (categoryID) => {
        if(categoryID){
            let catList = _.find(this.state.categories, { 'video_cat_id': categoryID });

            let subCategoryList = _.map(catList.children, (el) => {
                return _.assign({
                    value: el.video_cat_id,
                    label: el.video_cat_name
                });
            });
            this.setState({
                subCategoryList: subCategoryList
            });
        }
        else {
            this.setState({
                subCategoryList: initialState.subCategoryList,
                formData: {
                    ...this.state.formData,
                    subCategory: null,
                    video: null
                }
            });
        }
    };

    populateVideos = (subCategoryID) => {
        if(subCategoryID){
            let vidList = _.filter(this.state.videos, { 'video_category_id': subCategoryID });

            let videoList = _.map(vidList, (el) => {
                return _.assign({
                    value: el.video_id,
                    label: el.video_title
                });
            });
            this.setState({
                videoList: videoList
            });
        }
        else {
            this.setState({
                videoList: initialState.videoList,
                formData: {
                    ...this.state.formData,
                    video: null
                }
            });
        }
    };

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    getDUOVersion = (videoId) => {
        let selectedVid = _.find(this.state.videos, { 'video_id': videoId });
        const displayFileUpload = convertVideoUploadsName(selectedVid.video_file_uploads);

        API.get(`/videos/updateOrders/video/${videoId}/version/latest`)
        .then(result => {
            if(result.data) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        version: result.data
                    },
                    currentVersion: result.data,
                    newVersion: result.data + 1,
                    fileUploads: displayFileUpload
                });
            }
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, 
        () => {
            if(fieldName === 'category'){
                 this.setState({
                    formData: {
                      ...this.state.formData,
                      subCategory: null,
                      video: null
                    }
                },
                () => {
                    this.populateSubCategories(selectedOption && selectedOption.value);
                });
            }
            else if(fieldName === 'subCategory'){

                this.setState({
                    formData: {
                      ...this.state.formData,
                      video: null
                    }
                },
                () => {
                    this.populateVideos(selectedOption && selectedOption.value);
                });
            }
            else if(fieldName === 'video' && selectedOption && selectedOption.value){
               this.getDUOVersion(selectedOption.value);
            }
        });
    };
       
    render() {
        const { formErrors } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Update Order
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Main Details
                            </Typography>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <form noValidate autoComplete="off">
                                <TextField
                                    id="title"
                                    name="title"
                                    label="Title *"
                                    value={this.state.formData.title}
                                    error={formErrors && formErrors['title'] && true}
                                    helperText={formErrors && formErrors['title']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    id="description"
                                    name="description"
                                    label="Description *"
                                    value={this.state.formData.description}
                                    error={formErrors && formErrors['description'] && true}
                                    helperText={formErrors && formErrors['description']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <DatePicker
                                    type="date"
                                    id="poi"
                                    name="poi"
                                    label="Date of Change *"
                                    value={this.state.formData.dateOfChange}
                                    errorText={formErrors && formErrors['dateOfChange']}
                                    onChange={this.handleDateChange}
                                    disableFuture={true}
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Typography variant="h6">
                                    Affected Video
                                </Typography>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.categoryList} 
                                        label='Category'
                                        onChange={this.handleSelectChange('category')}
                                        value={this.state.formData.category}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.subCategoryList} 
                                        label='Sub Category'
                                        onChange={this.handleSelectChange('subCategory')}
                                        value={this.state.formData.subCategory}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.videoList} 
                                        label='Video'
                                        onChange={this.handleSelectChange('video')}
                                        error={formErrors && formErrors['video'] && true}
                                        errorText={formErrors && formErrors['video']}
                                        value={this.state.formData.video}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <Grid container spacing={8} >
                                        <Grid item xs={12} sm={6} md={3}>
                                            <PaddedPaper>
                                                <Typography variant="subtitle1" gutterBottom align='center'>
                                                    Current Version
                                                </Typography>
                                                <Typography variant="h5" align='center'>
                                                    {this.state.currentVersion}
                                                </Typography>
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <PaddedPaper>
                                                <Typography variant="subtitle1" gutterBottom align='center'>
                                                    New Version
                                                </Typography>
                                                <Typography variant="h5" align='center'>
                                                    {this.state.newVersion}
                                                </Typography>
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <PaddedPaper>
                                                <Typography variant="subtitle1" gutterBottom align='center'>
                                                    File Uploads
                                                </Typography>
                                                <Typography variant="h5" align='center'>
                                                    {this.state.fileUploads}
                                                </Typography>
                                            </PaddedPaper>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                                <Textarea
                                    id="detailsOfChange"
                                    name="detailsOfChange"
                                    label="Details of Change *"
                                    value={this.state.formData.detailsOfChange}
                                    rows={10}
                                    error={formErrors && formErrors['detailsOfChange']}
                                    onChange={this.handleChange}
                                />
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.staffList} 
                                        label='Approved By *'
                                        onChange={this.handleSelectChange('approvedBy')}
                                        error={formErrors && formErrors['approvedBy'] && true}
                                        errorText={formErrors && formErrors['approvedBy']}
                                        value={this.state.formData.approvedBy}
                                    />
                                </FormControl>
                            </form>
                            <div className='buttonRow'>
                                <Button onClick={this.handleConfirmationOpen}
                                        variant="contained"
                                        color="primary"
                                       >
                                    Add
                                </Button>
                            </div>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Add A New Video Update Order?" 
                    message="Are you sure you want to add this video update order?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added a new video update order'
                />
            </Grid>
        );
    }
}

export default AddVideoUpdateOrder;