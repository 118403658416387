import React, {Component} from 'react';

import FALightIcon  from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FAThinIcon   from 'Components/Common/Icons/FontAwesome/FAThinIcon';
import FADuoIcon    from 'Components/Common/Icons/FontAwesome/FADuoIcon';
import FASolidIcon  from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import FABrandIcon  from 'Components/Common/Icons/FontAwesome/FABrandIcon';
import CiCustomIcon from 'Components/Common/Icons/CiCustomIcon';

import { withStyles }                            from '@material-ui/core/styles';
import { Typography, Tooltip, IconButton, Link } from '@material-ui/core';

import { colors } from 'Helpers/ColourHelper';

const brands = [
    'stripe-s', 
    'paypal'
]

const ciCustom = [
    'DirectDebit',
    'InternalOrderProcess',
    'clenAware'
]

const Icon = (props) => {
    return (
        <>
            {ciCustom.includes(props.icon) ? 
                <CiCustomIcon {...props} /> :
                brands.includes(props.icon) ? 
                    <FABrandIcon  {...props} /> : 
                        (props.plainText || props.icon?.includes('TextToIcon:') ? 
                            <Typography {...{...props, style: {fontWeight: 'bold', color: (props.white ? colors.white : 'rgba(0,0,0,0.45)'), cursor: 'pointer', ...props.style, 
                                fontSize: props.size && (props.size === 'xxlarge' ? '4.2em' : (props.size || '1.5em'))
                            }}}>
                                {props.plainText || props.icon.replace('TextToIcon:','')}
                            </Typography> : 
                            (props.icon &&
                                ( props.duo ?
                                    <FADuoIcon {...props} /> :
                                    ( props.thin ?
                                        <FAThinIcon {...props} /> :
                                        (props.heavy ? 
                                            <FASolidIcon {...props} /> : 
                                            <FALightIcon {...props} /> 
                                        )
                                    )
                                )
                            )
                        )
            }
        </>
    )
}

const ToolTipIcon = (props) => {
    return (
        <Tooltip title={props.tooltip} placement={props.ttPlacement || 'left'}>
            <IconButton disabled={props.disabled} onClick={(e)=>{props.onClick && props.onClick(e)}} style={{...props.buttonStyle, padding: props.noPadding && '0px'}} className={props.noHover && props.classes.noHover} >
                <Icon button {...props} />
            </IconButton>
        </Tooltip>
    )
}

const ButtonIcon = (props) => {
    return (
        <IconButton disabled={props.disabled} onClick={(e)=>{props.onClick && props.onClick(e)}} style={{...props.buttonStyle, padding: props.noPadding && '0px'}} className={props.noHover && props.classes.noHover} >
            <Icon button {...props} />
        </IconButton>
    )
}

const LinkIcon = (props) => {
    return (
        <Link href={props.link} style={{textDecoration: 'none'}} >
            {(
                (props.tooltip && <ToolTipIcon {...props} />) || 
                <ButtonIcon {...props} button/>
            )}
        </Link>
    )
}

class AllIcon extends Component {

    render() {
        const props = this.props;  
        return (
            <>
                {   (   ( props.link    && <LinkIcon {...props}/>       )
                    || (( props.tooltip && <ToolTipIcon {...props} />   )
                    || (( props.onClick && <ButtonIcon {...props}/>     ) 
                    || <Icon {...props} /> )))
                }
            </>
        )
    }

}

const styles = theme => ({
    noHover: {
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.05)',
            color: 'black'
        }
    }
});

export default withStyles(styles)(AllIcon);