import React, { Component } from 'react';
import _                    from 'lodash';
import API                  from 'API';
import moment               from 'moment';

import icons      from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon   from 'Components/Common/Icons/AllIcon';

import { Grid, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';

import 'Assets/timeLine.css';

const initialState = () => ({
    prices: []
});

class OrderTab extends Component {
    constructor(props) {
        super(props);    
        this.state = initialState();
    }

    componentDidMount(){
        this.getPrices();
    }

    getPrices = () => {
        Promise.all(
            _.map(this.props.updatedParts, i => API.get(`/parts/${i.part_id}/prices`))
        ).then((prices) => {
            this.setState({ 
                prices: _.filter(_.map(prices, j => {
                    let prices = _.map(_.filter(j.data, i => (i.price_change_type !== 'Indirect Cost' &&  i.price_change_type !== 'Direct Cost' ) && moment(_.first(_.orderBy(_.filter(this.props.salesPackage.logs, i => i.pl_type === 'Created' || i.pl_type === 'Amended'), 'pl_version', 'desc')).pl_datetime).isSameOrBefore(`${i.price_date_from} 23:59:59`)), (l, idx) => ({
                        ...l,
                        changeNo: parseInt(j.data.length) - parseInt(idx)
                    }));
                    if (prices.length > 0 ) {
                        prices = [
                            ...prices,
                            {..._.find(j.data, i => parseInt(i.price_id) < parseInt(_.last(prices).price_id)), changeNo: parseInt(j.data.length) - parseInt(prices.length)}
                        ];

                        let newPrices = [];
                        _.forEach(prices, (i, idx) => {
                            if (idx > 0){
                                newPrices.push({
                                    date: i.price_date_to === "0000-00-00" ? i.price_date_from : i.price_date_to,
                                    next: prices[idx - 1].price_total,
                                    prev: i.price_total,
                                });
                            }
                        });

                        return {
                            part:   _.find(this.props.updatedParts, {part_id:_.first(j.data).price_part_id}),
                            prices: newPrices
                        };
                    }
                    return null;
                }), i => i)
            });
        })
    }

    render(){

        return(
            <Grid container>
                {_.map(this.state.prices, i => 
                    <Grid item xs={12}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<AllIcon icon={icons.arrowDown} noMargin white/>}>
                                <Grid container spacing={1} style={{alignItems: 'center'}}>
                                    <Grid item xs={9}>
                                        <Typography variant="body1">
                                            {i.part.part_number} - {i.part.part_description}
                                        </Typography>        
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body1">
                                            Current Package Price: £{parseFloat(i.part.currentPrice).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <DataTable
                                    config={{
                                        key: 'price_id',
                                        style: i => ({
                                            color: i.price_default === 'Yes' && colors.green
                                        })
                                    }}
                                    columns={[
                                        {
                                            heading: 'Date Changed',
                                            field: 'date',
                                            fieldFormat: 'clenydate',
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Price Change',
                                            field:   i => `£${i.prev} Changed To £${i.next}`,
                                        }
                                    ]}
                                    rows={i.prices}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default OrderTab;