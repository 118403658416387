import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import API from '../../../../API';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FALightIcon from '../../../Common/Icons/FontAwesome/FALightIcon';
import DataTable from '../../../Common/DataTables/DataTable';
import {DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE} from 'Constants'
import { gridPageChanged, gridRowsPerPageChanged } from 'Functions/FormFunctions'
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    keywords: '',
    searchResults: {},
    access: {
        viewSaf: false
    },
    gridPagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    },
    isLoading: true
});
class StockAdditionSearch extends Component {
    constructor(props) {
        super(props);
        this.clearPageState         = clearPageState.bind(this);
        this.getInitialState        = getInitialState.bind(this);
        this.hasPageState           = hasPageState.bind(this);
        this.savePageState          = savePageState.bind(this);
        this.persistenceId          = 'stock:StockAdditionSearch';
        this.state                  = this.getInitialState(initialState());
        this.gridPageChanged        = gridPageChanged.bind(this);
        this.gridRowsPerPageChanged = gridRowsPerPageChanged.bind(this);
    }

    componentDidMount = () => {
        this.getSearchData();
        this.checkAccess();
    }

    checkAccess = () => {
        API.get('/staff/my/access/check/view-stock-addition')
        .then((res) =>  {
            res.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        viewSaf: res.data.has_access
                    }
                });
        });
    }

    getSearchData = () => {
        const params = {
            keywords: this.state.keywords
        }
        this.setState({
            isLoading: true
        }, () => {
            API.get('/stock/addition', {
                params,
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    })
                }
            })
        })
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value,
            gridPagination: initialState().gridPagination
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
            gridPagination: initialState().gridPagination
        }, 
        () => {
            this.props.history.replace();
            this.getSearchData();
        });
    }

    downloadFile = rowData => {
        window.open(rowData.saf_file_url, '_blank');
    }

    render() {
        const { keywords, gridPagination } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Stock Addition Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key:                    'saf_id',
                                pagination:             true,
                                //page: gridPagination.page,
                                //rowsPerPage: gridPagination.rowsPerPage,
                                //gridPageChanged: this.gridPageChanged,
                                //gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                persistenceId:          this.persistenceId,
                                alternatingRowColours:  true,
                                responsiveImportance:   true,
                                isLoading:              this.state.isLoading
                            }}
                            columns={[
                                {
                                    heading: 'Reference',
                                    field: rowData => rowData.saf_reference,
                                    main: true,
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Type',
                                    field: rowData => rowData.saf_type,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Part Number',
                                    field: rowData => rowData.part && rowData.part.part_number + ' - ' + rowData.part.part_description,
                                    important: true,
                                    truncate: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Download', icon: 'download', onClick: this.downloadFile},
                                            {name: 'View', icon: 'search', link: { pathname: '/stock/additions/view/' + rowData.saf_id, state: {keywords, gridPagination}}, disabled: !this.state.access.viewSaf}
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}
const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)        => dispatch(clearPersistence(key)),
    setPersistence:     (key, state) => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(StockAdditionSearch);
