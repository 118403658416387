import _ from 'lodash';
import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PaddedPaper from '../../Common/Paper/PaddedPaper';

import { deployDialog } from 'Actions/Dialog/Dialog';
import BackButton from 'Components/Common/Buttons/BackButton';
import { connect } from 'react-redux';
import API from '../../../API';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import ViewStaffMiniProfile from '../ViewStaff/ViewStaffMiniProfileCard';
import SnackBar from './../../Common/SnackBars/SnackBar';
import StaffAccessItems from './StaffAccessItems';

function objectsHaveSameKeys(...objects) {
    const allKeys = objects.reduce((keys, object) => keys.concat(Object.keys(object)), []);
    const union = new Set(allKeys);
    return objects.every(object => union.size === Object.keys(object).length);
}

const styles = theme => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#D2041B',
        color: '#fff'
    },
    flex: {
        flex: 1,
    }
});

const initialState = {
    allAccess: {},
    staffAccess: {},
    staffDashboardAccess: [],
    staffData: {},
    currentStaffAccess: {},
    currentDashboardAccess: [],
    confirmationOpen: false,
    snackbarOpen: false,
    viewPage: {
        dialogOpen: false,
        url: null
    },
    dashboardAccess: [],
    allAccessLoading: true,
    currentAccessLoading: true
}

class StaffAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        window.onpopstate = this.handleBackButton;
    }
    componentDidMount = () => {
        API.get('/staff/' + this.props.match.params.staffId)
        .then(result => {
            this.setState({
                ...this.state,
                staffData: result.data,
            });
        });
        this.loadAllAccess();
        this.loadCurrentStaffAccess();
    }
    handleBackButton = () => {
        if(this.state.viewPage.dialogOpen) {
            // GET IT TO STOP PAGE CHANGE IF DIALOG OPEN
            this.props.history.go(1);
        }
    }
    loadAllAccess = () => {
        this.setState({
            allAccessLoading: true
        }, () => {
            API.get('/access/all').then(result => {
                this.setState({
                    allAccess: result.data,
                    allAccessLoading: false
                });
                //}, this.getDashboardAccess);
            })
        })
    }
    _getDashBoardChildren = async (items, parentId=0) => {
        let i = 0;
        let ret = [];
        let item = _.filter(items, {da_parent: parentId});
        while (true){
            if (!item[i]) break;
            if (parseInt(item[i].children_count) > 0) {
                item[i].children = await this._getDashBoardChildren(items, item[i].da_id);
                ret.push(item[i]);
            } else {
                ret.push(item[i]);
            }
            i++;
        }
        return ret;
    }
    // getDashboardAccess = async () => {
        // API.get('/dashboard/access').then(async result => {
        //     let dashboardAcc = result.data;
        //     let keys = _.keys(dashboardAcc);
        //     let access = {};
        //     let unfilteredDashboardAccess = [];
        //     let i = 0;
        //     while (true){
        //         if (!keys[i]) break;    
        //         access[keys[i]] = await this._getDashBoardChildren(dashboardAcc[keys[i]]);
        //         unfilteredDashboardAccess = [...unfilteredDashboardAccess, ...dashboardAcc[keys[i]]];
        //         i++;
        //     }
        //     this.setState({
        //         dashboardAccess: access,
        //         unfilteredDashboardAccess: unfilteredDashboardAccess
        //     });
        // })
    // }
    loadCurrentStaffAccess = () => {
        this.setState({
            currentAccessLoading: true
        }, () => {
            API.get('/staff/' + this.props.match.params.staffId + '/access', {
                params: {
                    noStructure: true
                }
            }).then(result => {
                _.map(result.data, access => {
                    this.setState({
                        currentStaffAccess: {
                            ...this.state.currentStaffAccess,
                            [access.access_id]: true
                        },
                        staffAccess: {
                            ...this.state.staffAccess,
                            [access.access_id]: true
                        }
                    })
                })
                this.setState({
                    currentAccessLoading: false
                });
                //}, this.loadCurrentStaffDashboardAccess);
            })
        })
    }
    updateAccess = () => {
        let accessIds = Object.keys(this.state.staffAccess);
        API.put('/staff/' + this.props.match.params.staffId + '/access', accessIds).then(result => {
            if(result.data.errors) {
            } else {
                this.setState({
                    ...this.state,
                    currentStaffAccess: {
                        ...this.state.staffAccess
                    },
                    snackbarOpen: true
                });
            }
            this.props.scrollToTop();
        });
    }
    checkAllToggle = (event, overrideId = 0) => {
        event.stopPropagation();
        let accessId = parseInt(event.target.name);
        
        let accessItem = _.find(this.state.allAccess, (accessItem) => {
            return accessItem.access_id === accessId
        });
        let allAccessItems = this.getRecursiveAccessChildren(accessItem);
        let newAccess = this.state.staffAccess;
        
        if(event.target.checked) {
            if(!this.state.staffAccess.hasOwnProperty(accessId)) {
                newAccess = {
                    ...newAccess,
                    [accessId]: true
                }
            }
            allAccessItems.forEach(accessItem => {
                if(!this.state.staffAccess.hasOwnProperty(accessItem.access_id)) {
                    newAccess = {
                        ...newAccess,
                        [accessItem.access_id]: true
                    }
                }
            });
        } else {
            allAccessItems.forEach(accessItem => {
                if(newAccess.hasOwnProperty(accessId)) {
                    newAccess = _.omit(newAccess, accessId);
                }
                if(newAccess.hasOwnProperty(accessItem.access_id)) {
                    newAccess = _.omit(newAccess, accessItem.access_id);
                }
            });
        }
        this.setState({
            staffAccess: newAccess
        });
    }
    // dashboardCheckChildren = async (items) => {
    //     let ids = [];
    //     let i = 0;
    //     while (true){
    //         if (!items[i]) break;    
    //         ids.push(items[i].da_id);
    //         if (items[i].children) {
    //             ids = [...ids, ...await this.dashboardCheckChildren(items[i].children)];
    //         }
    //         i++;
    //     }
    //     return ids;
    // }
    // checkAllToggleDashboard = item => async (event, overrideId = 0) => {
    //     event.stopPropagation();
    //     let checked = event.target.checked;

    //     let ids = [item.da_id, ...(item?.children?.length > 0 ? await this.dashboardCheckChildren(item.children) : [])];     

    //     if(checked) {
    //         this.setState({
    //             staffDashboardAccess: [...this.state.staffDashboardAccess, ...ids]
    //         });
    //     } else {
    //         this.setState({
    //             staffDashboardAccess: _.difference(this.state.staffDashboardAccess, ids)
    //         });
    //     }
    // }
    getRecursiveAccessChildren = (accessItem) => {
        let allChildren = [];
        _.map(accessItem.children, (child) => {
            allChildren.push(child);
            if(child.children) {
                let childrensChildren = this.getRecursiveAccessChildren(child);
                allChildren = [ ...allChildren, ...childrensChildren ]
            }
        });
        return allChildren;
    }
    isAllChecked = (accessId) => {
        let accessItem = _.find(this.state.allAccess, (accessItem) => {
            return accessItem.access_id === accessId
        });
        if(!this.state.staffAccess.hasOwnProperty(accessId)) {
            return false;
        }
        let allAccessItems = this.getRecursiveAccessChildren(accessItem);
        allAccessItems.forEach(_accessItem => {
            if(!this.state.staffAccess.hasOwnProperty(_accessItem.access_id)) {
                return false;
            }
        });
        return true;
    }
    toggleAccess = (event) => {
        let accessId = event.target.name;
        if(this.state.staffAccess.hasOwnProperty(accessId)) {
            this.setState({
                staffAccess: _.omit(this.state.staffAccess, accessId)
            })
        } else {
            let parent =  _.find(this.state.allAccess, {'children': [{'access_id': parseInt(accessId)}] });
            // If a child element has been ticked then automatically check the parent
            if(parent && parent.access_id > 0){
                this.setState({
                    staffAccess: {
                        ...this.state.staffAccess,
                        [accessId]: true,
                        [parent.access_id]: true,
                    }
                });
            }
            else {
                this.setState({
                    staffAccess: {
                        ...this.state.staffAccess,
                        [accessId]: true
                    }
                });
            }
        }
    };
    showPageExample = (url, title) => {
        this.setState({
            viewPage: {
                title: title,
                url: url,
                dialogOpen: true
            }
        })
    };

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.updateAccess();
    }

    handleViewPageDialogClose = () => {
        this.setState({ 
            viewPage: {
                ...this.state.viewPage,
                dialogOpen: false
            }
        });
    }
    // updateDashboard = () => {
    //     API.post( `/staff/${this.props.match.params.staffId}/dashboard/access`, { dashboardAccess: JSON.stringify(
    //         _.filter(
    //             this.state.staffDashboardAccess,
    //             i => this.state.staffAccess.hasOwnProperty(
    //                 _.find(this.state.unfilteredDashboardAccess, {da_id: i}).da_access_id.toString()
    //             )
    //         )
    //     ) } )
    //     .then(result => {
    //         this.loadAllAccess();
    //         this.loadCurrentStaffAccess();
    //     });
    // }
    // loadCurrentStaffDashboardAccess = () => {
    //     API.get(`/staff/${this.props.match.params.staffId}/dashboard/access`).then(result => {
    //         this.setState({
    //             currentDashboardAccess: result.data,
    //             staffDashboardAccess: result.data
    //         });
    //     });
    // }
  render() {
    const { classes } = this.props;
    const { staffAccess, staffData, allAccess, snackbarOpen, viewPage, allAccessLoading, currentAccessLoading } = this.state;

    // const DashboardAccessChildrenBlock = ({items, padding=0}) => {
    //     return _.map(items, (item) => 
    //         this.state.staffAccess.hasOwnProperty(item.da_access_id) &&
    //         <>
    //             <Grid item xs={12} style={{paddingLeft: padding*32}}>
    //                 <Typography component="div">
    //                     <Checkbox
    //                         name={item.da_id.toString()}
    //                         onClick={this.checkAllToggleDashboard(item)}
    //                         checked={this.state.staffDashboardAccess.includes(item.da_id)}
    //                         color='primary'
    //                     />
    //                     {item.da_name}
    //                 </Typography>
    //             </Grid>
    //             {
    //                 item.children && 
    //                 <DashboardAccessChildrenBlock items={item.children} padding={padding+1} />
    //             }
    //         </>
    //     );
    // }

    return (
        <Grid container spacing={3}>
            <Grid container item spacing={3}>
                <Grid item xs={12} lg={6}>
                    <PaddedPaper>
                        {!allAccessLoading && (
                            <ViewStaffMiniProfile staffData={staffData} />
                        )}
                        <br />
                        <StaffAccessItems   
                            toggleAccess={this.toggleAccess}
                            checkAllToggle={this.checkAllToggle}
                            staffAccess={staffAccess}
                            accessItems={allAccess}
                            dashboardAccess={this.state.dashboardAccess}
                            isAllChecked={this.isAllChecked}
                            showPageExample={this.showPageExample}
                            dataLoading={((allAccessLoading || currentAccessLoading) && true) || false}     
                            staffId={this.props.match.params.staffId}
                            unfilteredDashboardAccess={this.state.unfilteredDashboardAccess}
                        />
                        <div className='buttonRow'>
                            <BackButton props={this.props} />
                            <Button type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleConfirmationOpen}
                                    disabled={objectsHaveSameKeys(this.state.currentStaffAccess, this.state.staffAccess)}>
                                Update
                            </Button>
                        </div>
                    </PaddedPaper>
                    
                    <ConfirmationDialog 
                        open={this.state.confirmationOpen} 
                        success={this.handleConfirmationSuccess} 
                        close={this.handleConfirmationClose} 
                        title="Update staff access?" 
                        message="Are you sure you want to update the access for this staff member?"
                    />
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={snackbarOpen}
                        onClose={this.handleSnackbarClose}
                        message="The staff member's access was successfully updated"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    {/* {_.filter(_.keys(this.state.dashboardAccess), k =>  staffAccess[_.find(allAccess[0].children, {access_reference: `dashboard_${k.toLowerCase()}_dashboard`})?.access_id]).length > 0 &&
                        <PaddedPaper >
                            <Grid container spacing={1}>
                                {
                                    _.map(_.filter(_.keys(this.state.dashboardAccess), k =>  staffAccess[_.find(allAccess[0].children, {access_reference: `dashboard_${k.toLowerCase()}_dashboard`}).access_id]), (key) => 
                                        <Grid item xs={12}>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Typography component="div" style={{color:'white'}}>
                                                        {key} Dashboard
                                                    </Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container>
                                                        {
                                                            _.map(this.state.dashboardAccess[key], (item) =>
                                                                this.state.staffAccess.hasOwnProperty(item.da_access_id) &&
                                                                <Grid item xs={12}>
                                                                    <ExpansionPanel>
                                                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                            <Typography component="div" style={{color:'white'}}>
                                                                                <Checkbox style={{color:'white'}}
                                                                                    name={item.da_id.toString()}
                                                                                    onClick={this.checkAllToggleDashboard(item)}
                                                                                    checked={this.state.staffDashboardAccess.includes(item.da_id)}
                                                                                    key={`${item.da_id}-${this.state.staffDashboardAccess.includes(item.da_id)}`}
                                                                                />
                                                                                {item.da_name}
                                                                            </Typography>
                                                                        </ExpansionPanelSummary>
                                                                        <ExpansionPanelDetails>
                                                                            <Grid container>
                                                                                <DashboardAccessChildrenBlock
                                                                                    items={item.children}
                                                                                />
                                                                            </Grid>
                                                                        </ExpansionPanelDetails>
                                                                    </ExpansionPanel>
                                                                </Grid>
                                                            )
                                                        }
                                                        
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} className='buttonRow'>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        disabled={JSON.stringify(this.state.currentDashboardAccess) === JSON.stringify(this.state.staffDashboardAccess)}
                                        onClick={this.updateDashboard}
                                    >
                                        update dashboard access
                                    </Button>
                                </Grid>
                            </Grid>
                        </PaddedPaper> 
                    }*/}
                </Grid>
            </Grid>
            <Dialog open={viewPage.dialogOpen}
                    onClose={this.handleViewPageDialogClose}
                    fullScreen
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            Viewing Page
                        </Typography>
                        <IconButton color="inherit" onClick={this.handleViewPageDialogClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {(viewPage.url && (
                    <iframe src={viewPage.url} style={{height:'100%', border:'none', pointerEvents:'none'}} title="Preview Page" />
                )) || (
                    <DialogContent style={{marginTop:16}}>
                        No page to show
                    </DialogContent>
                )}
            </Dialog>
        </Grid>
    );
  }
}

export default withStyles(styles)(StaffAccess)