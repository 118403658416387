import React, { Component } from 'react';
import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import ActivityLog from 'Components/Common/ActivityLog/ActivityLog';
import BackButton from 'Components/Common/Buttons/BackButton';
import DataTable from 'Components/Common/DataTables/DataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import PDFViewer from 'Components/Common/PDFViewer/PDFViewer';
import CancelCreditNoteDialog from 'Components/CustomerReturns/SearchCreditNote/CancelCreditNoteDialog';
import AddNoteDialog from 'Components/CustomerReturns/ViewCreditNote/AddNoteDialog';
import CreditNote from 'Components/CustomerReturns/ViewCreditNote/CreditNote';
import CreditNoteNote from 'Components/CustomerReturns/ViewCreditNote/CreditNoteNote';

import AddFilesDialog from './AddFilesDialog';
import CustomerReturn from './CustomerReturn';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import { colors, CreditNoteActivityLog } from 'Helpers/ColourHelper';
import { getMediaType } from 'Helpers/FileHelper';
import icons from 'Helpers/IconHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

import { downloadS3File } from 'Functions/MiscFunctions';

import { AppBar, Button, Card, CardContent, CardHeader, Chip, Collapse, Divider, FormControl, Grid, IconButton, Input, InputLabel, Link, Paper, Step, StepLabel, Stepper, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from '@material-ui/core';
import MuiLink from '@material-ui/core/Link';
import { withStyles } from '@material-ui/styles';
import ViewEmails from 'Components/Common/Email/ViewEmails/ViewEmails';

const PaymentDialog  = ({rowData, closeDialog, viewFile}) => {
    return (
        <>
            <FormControl margin="normal" fullWidth>
                <InputLabel shrink={true} style={{color: 'black'}}>Refund Date</InputLabel>
                <Input value={moment(rowData.cnp_refund_date).format('DD/MM/YYYY')} disabled />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel shrink={true} style={{color: 'black'}}>Method</InputLabel>
                <Input value={rowData.cnp_method} disabled />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel shrink={true} style={{color: 'black'}}>Refund Amount</InputLabel>
                <Input value={`£${rowData.cnp_amount}`} disabled />
            </FormControl>
            {rowData.cnp_method == 'Invoice' ? 
                <>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel shrink={true} style={{color: 'black'}}>Invoice Number</InputLabel>
                        <Input value={rowData.cnp_invoice_number} disabled />
                    </FormControl>
                </> :
                <>
                    {rowData.cnp_method == 'Bank Transfer' ? 
                        <>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true} style={{color: 'black'}}>Refund Ref</InputLabel>
                                <Input value={rowData.cnp_refund_ref} disabled />
                            </FormControl>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true} style={{color: 'black'}}>Payee</InputLabel>
                                <Input value={rowData.cnp_payee} disabled />
                            </FormControl>
                        </>
                        :
                        <>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true} style={{color: 'black'}}>Refund Ref</InputLabel>
                                <Input value={rowData.cnp_refund_ref} disabled />
                            </FormControl>
                            {rowData.cnp_auth_no &&
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel shrink={true} style={{color: 'black'}}>Auth No</InputLabel>
                                    <Input value={rowData.cnp_auth_no} disabled />
                                </FormControl>
                            }
                        </>
                    }
                </>
            }
            <>
                <FormControl margin="normal" fullWidth>
                    <InputLabel shrink={true} style={{paddingBottom: '1.8em', color: 'black'}}>Refund File</InputLabel>
                    <TextField
                        style={{marginTop: '0.7em'}}
                        fullWidth
                        value={rowData.cnp_file ? _.last(rowData.cnp_file.split('/')) : 'No File'}
                        disabled
                        InputProps={{
                            endAdornment: rowData.file_upload && (<AllIcon icon={icons.search} onClick={viewFile(rowData.file_upload)} noMargin />)
                        }}
                    />
                </FormControl>
            </>
            <div className="buttonRow">
                <Button 
                    variant="outlined" 
                    color="default" 
                    onClick={closeDialog}
                >
                    Close
                </Button>
            </div>
        </>
    )
}

const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    btnRow: {
        textAlign: 'center', 
    },
    btnRowBtn: {   
        width: '100%',
    },
    link: {
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'none',
        }
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        },
        stepper: {
            display: 'none',
        },
    },
    statusHeading: {
        display: 'flex',
        alignItems: 'center'
    },
    label: {
        color: 'black'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tabScrollButtons: {
        color: 'black'
    }
});

const initialState = () => ({
    creditNote: {},
    files:      {
        refundConfirmation: []
    },
    isLoading:  true,
    currentTab: 0,
    status: {
        title:  '',
        desc:   '',
        icon:   '', 
        color:  ''
    },
    invoice: { expand: false,}
});

const statuses = ['Approval', 'Confirmed', 'Credit Note']

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

class ViewCreditNote extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getCreditNote(this?.props?.match?.params?.id);
    }

    softUpdate = () => {
        Promise.all([
            API.get(`/customerReturns/creditNote/${this?.props?.match?.params?.id}/files`)
        ]).then(([filesRes]) => {
            if (filesRes.data){
                let files = initialState().files;
                _.map(filesRes.data, i => {
                    let type = '';
                    switch (i.cn_file_type){
                        case 'Refund Confirmation': type = 'refundConfirmation'; break;
                    }
                    files[type].push(i);
                })
                this.setState({files})
            }
        })
    }

    getCreditNote = (id = null) => {
        API.get(`/customerReturns/creditNote/${id}`)
        .then(result => {
            if (result.data){
                _.map(result.data.activity_log, (log) => {
                    let data = CreditNoteActivityLog(log.cnacl_type);
                    log.color = data['color'];
                    log.icon = data['icon'];
                    log.time = moment(log.cnacl_datetime).format("DD/MM/YYYY");
                });
                this.setState({
                    ...this.state,
                    creditNote: result?.data,
                    isLoading:  false
                }, () => {
                    this.getStatus(); 
                    this.softUpdate();
                });
            }
        })
    }

    getStatus = () => {
        let     {title, desc, icon, color}       = this.state.status;
        const   {cnsc_staff_name, cnsc_datetime} = (_.find(this.state.creditNote.status_change, {'cnsc_status': this.state.creditNote.cn_status})) ?
            _.find(this.state.creditNote.status_change, {'cnsc_status': this.state.creditNote.cn_status}) : {cnsc_staff_name: this.state.creditNote.cn_staff_name, cnsc_datetime: this.state.creditNote.cn_date};

        title = this.state.creditNote.cn_status;
        color = '#F39C12';
        switch(this.state.creditNote.cn_status){
            case 'Approval':
                desc  = `Credit note was created on ${moment(cnsc_datetime, "YYYY-MM-DD").format("Do MMMM YYYY")} by ${cnsc_staff_name}`;
                icon  = 'file-user';
                title = 'Awaiting Approval';
            break;
            case 'Cancelled':
                desc  = `Credit note was approved at ${moment(cnsc_datetime, "YYYY-MM-DD").format("Do MMMM YYYY")} by ${cnsc_staff_name}`;
                icon  = 'ban';
                color = colors.red;
            break;
            case 'Declined':
                desc  = `Credit note was declined at ${moment(cnsc_datetime, "YYYY-MM-DD").format("Do MMMM YYYY")} by ${cnsc_staff_name}`;
                icon  = 'user-times';
                color = colors.red;
            break;
            case 'Confirmed':
                desc  = `Credit note was confirmed at ${moment(cnsc_datetime, "YYYY-MM-DD").format("Do MMMM YYYY")} by ${cnsc_staff_name}`;
                icon  = 'user-tick';
            break;
            case 'Credit Note':
                desc  = `Credit note was approved at ${moment(cnsc_datetime, "YYYY-MM-DD").format("Do MMMM YYYY")} by ${cnsc_staff_name}`;
                icon  = 'file-check';
            break
            case 'Credit Note Awaiting Approval':
                desc  = `Credit note was processed at ${moment(cnsc_datetime, "YYYY-MM-DD").format("Do MMMM YYYY")} by ${cnsc_staff_name}`;
                icon  = 'file-invoice-dollar';
            break;
            default:
                desc  = `Credit note was created on ${moment(this.state.creditNote.cn_created_datetime, "YYYY-MM-DD").format("Do MMMM YYYY")} by ${this.state.creditNote.cn_staff_name}`;
                icon  = 'file-user';
            break;
        } 
        this.setState({status:{title, desc, icon, color}})
    }

    handleTabChange = (event, value) => {
        this.setState({currentTab: value});
    };

    downloadCN = (id, status) => {
        API.post(`/customerReturns/creditNote/${id}/prevPdf`, {type: 'Credit Note'}).then((result) => {
            if (result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    handleExpandInvoices = () => {
        this.setState({invoice:{ ...this.state.invoice, expanded: !this.state.invoice.expanded }})
    }

    handleDownloadInvoice = i => {
        API.post(`/customerReturns/creditNote/invoice/${i}/prevPdf`, {type: 'Invoice'}).then((result) => {
            if (result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    };

    deleteFile = id => {
        API.post(`/customerReturns/creditNote/${this?.props?.match?.params?.id}/files/${id}/delete`)
        .then( (res) => {this.softUpdate();})
    }


    viewFile = file => () => {
        this.props.deployDialog(
            <Grid container spacing={1}>
                <Grid item xs={12} style={{textAlign:'center'}}>
                    {getMediaType(file) === 'image' &&
                        <ImageWithError height='500' width='500' src={file} />
                    }
                    {getMediaType(file) === 'pdf' &&
                        <PDFViewer pdfUrl={file} />
                    }
                    {(getMediaType(file) !== 'pdf' && getMediaType(file) !== 'image') && 'File Not Supported'}
                </Grid>
                <Grid className='buttonRow' item xs={12}>
                    <Button onClick={()=>{ this.props.closeDialog() }} variant='outlined'>Close</Button>
                </Grid>
            </Grid>, 'View File', 'success', 'md');
    }

    handleDownloadFile = file => () => { downloadS3File(file); }

    render() {
        const { isLoading, creditNote, currentTab, files } = this.state;
        const { classes }                           = this.props;
        const activeStep                            = ( creditNote.cn_status== 'Credit Note Awaiting Approval') ? 'Credit Note' : _.findIndex(statuses, function(el) { return el === creditNote.cn_status; });
        const tabItems                              = _.filter([
            {name: 'creditnote',    label: 'Credit Note'},
            {name: 'notes',         label: 'Notes'},
            {name: 'acl',           label: 'Activity Log'},
            {name: 'order',         label: 'Order'},
            {name: 'invoice',       label: 'Refunds'},
            {name: 'emails',        label: `Emails`},
            creditNote?.cn_cr_id && {name: 'returns',       label: 'Refund For'},
        ], i =>i);
        return (
            <>
                {isLoading ? <LoadingCircle/> :
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={9}>
                            <PaddedPaper>
                                <Grid item spacing={3} xs={12}>
                                    <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                                        {statuses.map(label => (<Step key={label}><StepLabel>{label}</StepLabel></Step>))}
                                    </Stepper>
                                    <Typography variant="h4" align="center" className={classes.heading} style={{marginBottom: 25, color: colors.red}}>
                                        {creditNote.full_reference}
                                    </Typography>
                                    <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                        Credit Note (v{parseInt(creditNote.cn_reference_amendment)})
                                        <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={() => this.downloadCN(creditNote.cn_id, creditNote.cn_status)}
                                        >
                                            <AllIcon icon='download' />
                                            Download
                                        </Button> 
                                    </Typography>
                                </Grid>
                                <Grid item spacing={3} xs={12}>
                                    <AppBar position="static" style={{backgroundColor:'white', color: 'black', margin: '25px 0'}} >
                                        <Tabs 
                                            value={currentTab} 
                                            onChange={this.handleTabChange} 
                                            indicatorColor="primary" 
                                            textColor="primary" 
                                            variant="scrollable" 
                                            scrollButtons="auto"
                                        >
                                            {_.map(tabItems, i => <Tab label={i.label} /> )}
                                        </Tabs>
                                    </AppBar>
                                </Grid>
                                {currentTab === _.findIndex(tabItems, i => i.name == 'emails') && 
                                    <TabContainer>
                                        <ViewEmails 
                                            creditNoteId={creditNote?.cn_id} 
                                            smallMode 
                                            sendProps={
                                                {
                                                    to: [creditNote.cn_delivery_cust_contact_id],
                                                    customerId: creditNote.cn_cust_id ,
                                                }
                                            }
                                        />
                                    </TabContainer>
                                }
                                {currentTab === _.findIndex(tabItems, i => i.name == 'returns') && 
                                    <TabContainer>
                                        <CustomerReturn cr_id={creditNote?.cn_cr_id} />
                                    </TabContainer>
                                }
                                {currentTab === _.findIndex(tabItems, i => i.name == 'invoice') && 
                                    <TabContainer>
                                        <Grid container spacing={3}>   
                                            {creditNote.invoice &&
                                                <Grid item xs={12}>
                                                    <Paper>
                                                        <CardHeader 
                                                            title={<Typography variant={'h6'}>{creditNote.invoice.cni_invoice_number}</Typography>}
                                                            subheader={
                                                                <>
                                                                    <Typography variant={'caption'} paragraph>Sage: {creditNote.invoice.cni_sage_invoice_number}</Typography>
                                                                    <Typography variant={'body2'} gutterBottom>
                                                                        {creditNote.invoice.past_revisions.map( i => 
                                                                            <Link to="" onClick={(e) => { e.preventDefault(); this.handleDownloadInvoice(i.cni_id)}} className='blueLink'>
                                                                                {`${i.cni_reference}-0${i.cni_reference_unique_number}`} <br/>
                                                                            </Link>
                                                                        )}
                                                                    </Typography>
                                                                    <Typography variant={'body1'} gutterBottom>
                                                                        {creditNote.invoice.cni_status == 'Completed' ? <Chip size="small" label="Refunded" style={{backgroundColor: colors.green, color: '#fff', marginRight:'20px'}} /> : (creditNote.invoice.cni_status  == 'Approval' ? <Chip size="small" label="Awaiting Approval" style={{backgroundColor: '#F39C12', color: '#fff', marginRight:'20px'}} /> :<Chip size="small" label="Pending" style={{backgroundColor: '#F39C12', color: '#fff', marginRight:'20px'}} />)}
                                                                        <span style={{fontSize:'12px'}}>Refunded: </span><span  style={{color: colors.red}}>£{creditNote.invoice.sum_of_payments || '0.00'}</span>
                                                                        <span style={{fontSize:'30px', color: 'rgba(0, 0, 0, 0.2)'}}> / </span> 
                                                                        <span style={{fontSize:'12px'}}>Due: </span><span  style={{color: colors.red}}>-£{creditNote.invoice.cni_total_incl_vat}</span>
                                                                        <span style={{fontSize:'30px', color: 'rgba(0, 0, 0, 0.2)'}}> / </span> 
                                                                        <span style={{fontSize:'12px'}}>Outstanding: </span><span  style={{color: colors.red}}>{parseFloat(creditNote.invoice.cni_total_incl_vat) !== parseFloat(creditNote.invoice.sum_of_payments) ? `-£${(parseFloat(creditNote.invoice.cni_total_incl_vat) - parseFloat(creditNote.invoice.sum_of_payments) ) ? (parseFloat(creditNote.invoice.cni_total_incl_vat) - parseFloat(creditNote.invoice.sum_of_payments) ) : creditNote.invoice.cni_total_incl_vat}` : '£0'}</span>
                                                                    </Typography>
                                                                </>
                                                            }
                                                            action={
                                                                <>
                                                                    {creditNote.invoice.cni_status != 'Approval' &&
                                                                        <IconButton onClick={this.handleExpandInvoices}><AllIcon icon={this.state.invoice.expanded ? "chevron-up" : "chevron-down"} button noMargin/></IconButton>
                                                                    }
                                                                </>
                                                            }
                                                            disableTypography
                                                        />
                                                        <Collapse in={this.state.invoice.expanded} timeout="auto" unmountOnExit>
                                                            <CardContent style={{paddingTop: 0}}>
                                                                {!!creditNote.invoice.payments.length ? 
                                                                    <>                                    
                                                                        <Table size="small">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell>Date Paid</TableCell>
                                                                                    <TableCell>Method</TableCell>
                                                                                    <TableCell align="right">Amount</TableCell>
                                                                                    <TableCell></TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {_.map(creditNote.invoice.payments, (payment, idx) => {
                                                                                    return (
                                                                                        <TableRow key={idx}>
                                                                                            <TableCell>{moment(payment.cnp_refund_date).format("DD/MM/YYYY")}</TableCell>
                                                                                            <TableCell>
                                                                                                {payment.cnp_method} {payment.cnp_method == 'Invoice' &&  <>{`(${payment.cnp_invoice_number})`}</>}
                                                                                            </TableCell>
                                                                                            <TableCell align="right">£{payment.cnp_amount}</TableCell>
                                                                                            <TableCell align="right">
                                                                                                <AllIcon noMargin icon={icons.download} onClick={this.handleDownloadFile(payment.file_upload)} disabled={!payment.file_upload}/>
                                                                                                <IconButton onClick={() => { this.props.deployDialog(<PaymentDialog rowData={payment} closeDialog={this.props.closeDialog} viewFile={this.viewFile}  />, 'Refund Details', null, 'xs')} }>
                                                                                                    <AllIcon icon="search" button noMargin/>
                                                                                                </IconButton>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                })}
                                                                            </TableBody>
                                                                        </Table>     
                                                                    </>
                                                                    :<Typography variant="caption">
                                                                        <IconButton disabled>
                                                                            <AllIcon icon="exclamation-circle" />
                                                                        </IconButton>
                                                                        This invoice has 0 payments
                                                                    </Typography>
                                                                }
                                                            </CardContent>   
                                                        </Collapse>   
                                                    </Paper>
                                                </Grid>
                                            }
                                        
                                            {!!files.refundConfirmation.length &&
                                                <Grid item xs={12}>
                                                    <PaddedPaper>
                                                        <Typography variant="h5" style={{paddingBottom:'1em'}}>
                                                            Confirmed Refund Files
                                                        </Typography>
                                                        {_.map(files.refundConfirmation, (rev, idx) => 
                                                            <Grid container key={idx} >
                                                                <Grid item >
                                                                    <Tooltip title={'Delete'}>
                                                                        { idx === (files?.refundConfirmation?.length - 1) ? 
                                                                            <IconButton size='small' onClick={()=>{this.props.deployConfirmation('Are you sure you want to delete this file?','Delete File?', ()=>{this.deleteFile(rev.cn_file_id)})}} >
                                                                                <AllIcon icon='trash-alt' size="small" noMargin button /> 
                                                                            </IconButton> :
                                                                            <IconButton size='small' disabled='disabled'>
                                                                                <AllIcon icon='trash-alt' style={{color:'#FFF'}} size="small" noMargin button /> 
                                                                            </IconButton>
                                                                        }
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item  style={{padding:'3px'}}>
                                                                    <MuiLink component="div" variant="body2" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={() => {downloadS3File(rev.file_url)}}>
                                                                        {rev.cn_file_filename || '-'}
                                                                    </MuiLink>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </PaddedPaper>
                                                </Grid>
                                            }
                                        </Grid> 
                                    </TabContainer>
                                }
                                {currentTab === _.findIndex(tabItems, i => i.name == 'creditnote') &&
                                    <TabContainer>
                                        <Grid container spacing={3}>
                                            <Grid item spacing={3} xs={12}>
                                                <PaddedPaper>
                                                    <CreditNote cn={creditNote} />
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </TabContainer>
                                }
                                {currentTab === _.findIndex(tabItems, i => i.name == 'acl') && 
                                    <TabContainer>
                                    {!creditNote?.activity_log?.length ? <>No activity has been monitored yet</> :
                                        <Card>
                                            <CardContent>
                                                <ActivityLog 
                                                    fields={{
                                                        type:   'cnacl_type',
                                                        date:   'cnacl_datetime',
                                                        icon:   { color: 'color', icon: 'icon' },
                                                        text:   'cnacl_text',
                                                        staff:  'cnacl_staff_name',
                                                    }}
                                                    atl={creditNote?.activity_log}
                                                />
                                            </CardContent>
                                        </Card>
                                    }
                                </TabContainer>
                                }
                                {currentTab === _.findIndex(tabItems, i => i.name == 'notes') && 
                                    <TabContainer>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" gutterBottom>
                                                    Notes
                                                </Typography>
                                            </Grid>
                                            <Grid container spacing={3} item xs={12}>
                                                {!!creditNote?.notes?.length ? (
                                                    <>
                                                        {creditNote?.notes.map(note => (
                                                            <Grid item xs={12}><CreditNoteNote note={note} callback={()=>{this.getCreditNote(creditNote.cn_id)}} /></Grid>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <Grid item xs={12}>
                                                        <Typography component="p">There are no notes to show for this credit note</Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </TabContainer>
                                }
                                {currentTab === _.findIndex(tabItems, i => i.name == 'order') && 
                                    <TabContainer>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    {!!creditNote.order ? 
                                                        <DataTable
                                                            config={{
                                                                key: 'order_id',
                                                                pagination: false,
                                                                alternatingRowColours: true,
                                                                responsiveimportant: true,
                                                                isLoading: isLoading,
                                                            }}
                                                            columns={[
                                                                {
                                                                    heading: 'Order Date',
                                                                    field: rowData => moment(rowData.order_date).format("DD/MM/YYYY"),
                                                                    important: true,
                                                                    sizeToContent: true
                                                                },
                                                                {
                                                                    heading: 'Reference',
                                                                    field: rowData => <>{`${rowData.order_reference}-${rowData.order_reference_amendment}`}</>,
                                                                    important: true,
                                                                },
                                                                {
                                                                    heading: 'Discount',
                                                                    field: rowData => rowData.order_type === 'With Discount' ? 
                                                                        <AllIcon icon='check' style={{color:colors.green}} /> : 
                                                                        <AllIcon icon='times' style={{color:colors.red}}/>,
                                                                    important: true,
                                                                    fieldFormat: 'boolean',
                                                                    tooltip: rowData => rowData.order_type,
                                                                    sizeToContent: true,
                                                                    alignment: 'center'
                                                                },
                                                                {
                                                                    heading: 'Customer',
                                                                    field: rowData => <>
                                                                                        {rowData.order_cust_name}
                                                                                        {' '}
                                                                                        <Grid container spacing={1}>
                                                                                            <Grid item>
                                                                                                <Typography component="div" variant="body2">
                                                                                                    {rowData.order_delivery_cust_address_name}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs>
                                                                                                <Tooltip
                                                                                                    title={
                                                                                                        <div>
                                                                                                            <Typography variant="body2">
                                                                                                                <b>Delivery Address:</b><br />
                                                                                                                {rowData.order_delivery_cust_address.split('\n').map((item, key) => {
                                                                                                                    return  <span key={key}>
                                                                                                                                {item}{rowData.order_delivery_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                                                                                            </span>
                                                                                                                })}
                                                                                                            </Typography>
                                                                                                        </div>
                                
                                                                                                    }
                                                                                                    placement="right"
                                                                                                >
                                                                                                    <Link>
                                                                                                        <AllIcon
                                                                                                            heavy 
                                                                                                            icon='info-circle'
                                                                                                            button 
                                                                                                            size='small'
                                                                                                            style={{
                                                                                                                color: 'rgba(0, 0, 0, 0.38)'
                                                                                                            }}
                                                                                                        />
                                                                                                    </Link>
                                                                                                </Tooltip>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </>,
                                                                    important: true
                                                                },
                                                                {
                                                                    heading: 'Total',
                                                                    field: rowData => ( 
                                                                        <React.Fragment>
                                                                            {rowData.order_total_incl_vat}
                                                                            {' '}
                                                                            <Tooltip 
                                                                                title={
                                                                                    <div style={{display: 'flex', width: 200}}>
                                                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                                                            <Typography variant="body2">Sub Total</Typography>
                                                                                            <Typography variant="body2">VAT ({parseInt(creditNote.vat.vat_value_percentage)}%)</Typography>
                                                                                            <Typography variant="body2">Total</Typography>
                                                                                        </div>
                                                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                                                            <Typography variant="body2">£{rowData.order_sub_total}</Typography>
                                                                                            <Typography variant="body2">£{rowData.order_vat}</Typography>
                                                                                            <Typography variant="body2">£{rowData.order_total_incl_vat}</Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                } 
                                                                                placement="left"
                                                                            >
                                                                                <Link>
                                                                                    <AllIcon
                                                                                        heavy 
                                                                                        icon='info-circle'
                                                                                        button 
                                                                                        size='small'
                                                                                        style={{
                                                                                            color: 'rgba(0, 0, 0, 0.38)'
                                                                                        }}
                                                                                    />
                                                                                </Link>
                                                                            </Tooltip>
                                                                        </React.Fragment>
                                                                    ),
                                                                    important: true,
                                                                    alignment: 'right',
                                                                    fieldPrefix: '£',
                                                                },
                                                                {
                                                                    actions: rowData => {
                                                                        return [
                                                                            {name: 'View', icon: 'search', link: {pathname: `/sales/order/view/${rowData.order_id}`}}
                                                                        ]
                                                                    }
                                                                }
                                                            ]}
                                                            rows={[creditNote.order]}
                                                        /> : 
                                                        <Typography component="p">There is no order attached to this credit note</Typography>
                                                    }
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </TabContainer>
                                }
                                <Divider style={{marginBottom:20}} />
                                <div className={`buttonRow ${classes.btnRow}`}>
                                    <Grid container justify='flex-end' spacing={1}>
                                        <Grid item xs={12} md={3}>
                                            <BackButton props={this.props} style={{width: '100%'}} />
                                        </Grid>
                                        {!!creditNote.cn_latest &&
                                            <Grid item xs={12} md={3}>
                                                <Button variant="contained" className={classes.btnRowBtn} color="primary" 
                                                    onClick={()=>{this.props.deployDialog(<AddNoteDialog cn_ref={creditNote.cn_reference}  callback={()=>{this.getCreditNote(creditNote.cn_id)}} />,'Add Credit Note Note', 'success')}}>
                                                    <AllIcon icon='plus' buttonPrimary /> Add Notes
                                                </Button>
                                            </Grid>
                                        }
                                        {!!creditNote.cn_latest &&
                                            <Grid item xs={12} md={3}>
                                                <Button variant="contained" className={classes.btnRowBtn} color="primary" 
                                                    onClick={()=>{this.props.deployDialog(<AddFilesDialog cnId={creditNote.cn_id} callback={()=>{this.getCreditNote(creditNote.cn_id)}}/>,'Add Credit Note Files', 'success')}}>
                                                    <AllIcon icon='plus' buttonPrimary /> Add Files
                                                </Button>
                                            </Grid>
                                        }
                                        {creditNote.locked ?
                                            <Grid item xs={12} md={3}>
                                                <Button variant="contained" className={classes.btnRowBtn} color="secondary" onClick={()=>{}} disabled>
                                                    <AllIcon icon={icons.lock} buttonPrimary /> Locked
                                                </Button>
                                            </Grid>:
                                            <>
                                                { ['Awaiting Approval', 'Approval', 'Confirmed'].includes(creditNote.cn_status) && !!creditNote.cn_latest &&
                                                    [<Grid item xs={12} md={3}>
                                                        <Link href={`/returns/creditnote/edit/${creditNote.cn_id}`} className={classes.link}>
                                                            <Button variant="contained" color="primary" className={classes.btnRowBtn}><AllIcon icon='pen' buttonPrimary /> Amend Credit Note</Button>
                                                        </Link>
                                                    </Grid>,
                                                    <Grid item xs={12} md={3}>
                                                        <Button variant="contained" className={classes.btnRowBtn} color="secondary" 
                                                            onClick={()=>{this.props.deployDialog(<CancelCreditNoteDialog cnId={creditNote.cn_id} callback={()=>{this.getCreditNote(creditNote.cn_id)}} />, 'Cancel Credit Note', 'warning')}}>
                                                            <AllIcon icon='ban' buttonPrimary /> Cancel Credit Note
                                                        </Button>
                                                    </Grid>]                                                  
                                                }
                                            </>
                                        }
                                    </Grid>
                                </div>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h5" style={{color:this.state.status.color, display: 'flex',alignItems: 'center', marginBottom:'0.5em'}}>
                                        <AllIcon icon={this.state.status.icon} style={{color:this.state.status.color}} size='large'/> {this.state.status.title}
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary">
                                        {this.state.status.desc}
                                    </Typography>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12}><br/></Grid>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h5">
                                        Previous Credit Notes
                                    </Typography>
                                    {creditNote.revisions && creditNote.revisions.length > 1 ?
                                        creditNote.revisions.map((rev, idx) => (
                                            <>
                                                {creditNote.cn_id !== rev.cn_id &&
                                                    <>
                                                        <Link to="" onClick={(e) => { e.preventDefault(); this.downloadCN(rev.cn_id, rev.cn_status)}} className='blueLink'>
                                                            {`${rev.cn_reference}-0${rev.cn_reference_amendment}`}
                                                        </Link>
                                                        <br></br>
                                                    </>
                                                }   
                                            </>
                                        ))
                                    :
                                        <Typography variant="body1" gutterBottom>
                                            0 previous Credit Notes
                                        </Typography>
                                    }
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success)               => dispatch(deployConfirmation(message, title, success)),
        deployDialog:       (content, title, variant, size='md')    => dispatch(deployDialog(content, title, variant, size)),
        closeDialog:        ()                                      => dispatch(closeDialog())
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ViewCreditNote))