import React, { Component } from 'react';
import API                  from 'API';
import _, { isInteger }     from 'lodash';
import { connect }          from 'react-redux';

import Image              from 'Components/Common/ImageWithError/ImageWithError';
import NumberField        from 'Components/Common/Inputs/NumberField';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import QrScanWrapper      from 'Components/Warehouse/Common/Inputs/QrScanWrapper';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';

import { colors } from 'Helpers/ColourHelper';

import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { playSound }      from 'Actions/Sounds/Sounds';

import { Button, Grid, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

const initialState = {
    part:       null,
    showError:  false,
    formData: {
        part:   null,
        qty:    0,
    },
    confirmationDialog: {
        open:    false,
        title:   '',
        message: '',
        success: null
    },
    parts: [],
    isLoading: true,
    formErrors: []
}

class ScanDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.setState({
                parts:      this.props.parts,
                isLoading:  false,
            });
        }, 300);
    }

    handleScan = (scannedPartNumber) => {
        if (scannedPartNumber){
            let part = _.find(this.state.parts, {part_number: scannedPartNumber});
            if (part){
                this.props.playSound('pickSuccess', 1)
                this.setState({
                    part: part,
                    formData: {
                        ...this.state.formData,
                        part: part.part_id,
                    }
                })
            } else {
                this.props.playSound('pickFailed', 1);
                this.setState({qrError: 'Part Connot Be Added'});
            }
        }
    }

    handleClose = () => {
        this.props.close();
    }

    _handleKeyDown = (e) => {
        let { formData, confirmationDialog } = this.state;
        if (e.keyCode === 0) return;
        e.preventDefault();
        switch(e.key){
            case 'F1':
                if (confirmationDialog.open) this.handleConfirmationClose();
            break;
            case 'F2':
                if (confirmationDialog.open){
                    this.submitStockCorrection();
                } else {
                    if (formData.qty && parseFloat(formData.qty) > 0) 
                        this.deployConfirmation(
                            'Are you sure you want tot add this part to the stock correction?',
                            'Add Stock correction Part?',
                            this.submitStockCorrection
                        );
                }
            break;
            case 'F3':
                this.handleClose();
            break;
            default:
                if (formData.part) {
                    if (e.key === 'Backspace'){
                        formData.qty = formData.qty.toString().slice(0, -1);
                    } else if ( e.key === '.' ) {
                        formData.qty = formData.qty.toString() + '.';
                    } else if (isInteger(parseInt(e.key))){
                        formData.qty = parseFloat(formData.qty.toString() + e.key.toString());
                    } 
                    this.setState({formData});
                }
            break;
        } 
    }

    handleChange = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    deployConfirmation = (message, title, success) => {
        this.setState({
            confirmationDialog: {
                open: true,
                message: message,
                title: title,
                success: success
            }
        })
    }

    handleConfirmationClose = () => {
        this.setState({
            confirmationDialog: {
                ...this.state.confirmationDialog,
                open: false
            }
        })
    }

    submitStockCorrection = () => {
        this.handleConfirmationClose();
        API.post(`/stock/correction/${this.props.correction.sc_id}/addPart`, {qty: this.state.formData.qty, partId: this.state.formData.part})
        .then(res => {
            if(res.data.errors && res.data.errors.length > 0){           
                this.setState({
                    qrError:  'Part Is locked',
                    part:     initialState.part,
                    formData: initialState.formData
                });
            } else {
                this.setState({
                    part:     initialState.part,
                    formData: initialState.formData
                });
            }
        })

    }

    render() {

        const { isLoading, formData, part, qrError, confirmationDialog, reasons, formErrors } = this.state;

        const { classes } = this.props;

        if (isLoading) return (<LoadingCircle />);

        return(
            <Grid container justify="center" className={`${classes.root} text-center`} spacing={2}>
                {part ? 
                    <>
                        <Grid item xs={12} >
                            <Grid container justify="center" className="text-center" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" style={{textAlign: 'center'}}>
                                        {part.part_number}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{textAlign: 'center'}}>
                                        {part.part_description}<br></br>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{textAlign: 'center'}}>
                                    <Image src={part.default_image?.file_path} className={classes.img} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <PaddedPaper style={{padding: 7}}>
                                        <Grid container spacing={1}  style={{alignItems: 'center'}}>
                                            <Grid item xs={12}>
                                                <Typography variant='body1' style={{fontWeight: 'bold'}}>Stock</Typography>
                                                <Typography variant='h5' style={{fontWeight: 'bold'}}>{part?.stock.stock_current_qty}</Typography>
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={6}>
                                    <PaddedPaper style={{padding: 7}}>
                                        <Grid container spacing={1}  style={{alignItems: 'center'}}>
                                            <Grid item xs={12}>
                                                <Typography variant='body1' style={{fontWeight: 'bold'}}>Pending</Typography>
                                                <Typography variant='h5' style={{fontWeight: 'bold'}}>
                                                    {part?.stock.stock_current_qty - part?.stock.stock_predicted >= 0 ? '+' : '-'}{Math.abs(part?.stock.stock_current_qty - part?.stock.stock_predicted)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <NumberField
                                fullWidth
                                label="New Stock Level *"
                                value={formData.qty}
                                onChange={(e)=>{this.handleChange('qty', e)}}
                                inputProps={{
                                    min: 0,
                                    pattern: "\d*"
                                }}
                                granularity={1}
                                disableKeyboard={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={!formData.qty || parseFloat(formData.qty) < 0}
                                onClick={()=>
                                    this.deployConfirmation(
                                        'Are you sure you want tot add this part to the stock correction?',
                                        'Add Stock correction Part?',
                                        this.submitStockCorrection
                                    )
                                }
                            >Add [F2]</Button>
                        </Grid>
                    </> :
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h4">Scan Part</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" style={{color: colors.red}}>{qrError}</Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={12} style={{marginTop: 'auto'}}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={this.handleClose}
                    >Close [F3]</Button>
                </Grid>
                <QrScanWrapper
                    handleScan={this.handleScan}
                    parentScan={this._handleKeyDown}
                />
                {confirmationDialog.open &&
                    <ConfirmationDialog
                        open={confirmationDialog.open}
                        title={confirmationDialog.title}
                        message={confirmationDialog.message}
                        success={confirmationDialog.success}
                        close={this.handleConfirmationClose}
                        isPda={true}
                    />
                }
            </Grid>
        )    
    }
}

const styles = theme => ({
    root: {
        padding:    theme.spacing(1),
        alignItems: 'flex-start',
    },
    qrRow: {
        justifyContent: 'flex-end',
        maxHeight:      '50px',
    },
    img: {
        maxHeight: 80,
        maxWidth:  130,
        width: 'auto',
        height: 'auto',
    },  
    qtyRow: {
        textAlign: 'center'
    },
    actionText: {
        border: `2px dashed ${theme.palette.secondary.main}`,
        padding: 15,
        width: '80%',
        margin: '0 auto',
        textAlign: 'center',
        marginTop: '1em'
    },
});

function mapDispatchToProps(dispatch) {
    return {
        playSound: (type, state) => {
            dispatch(playSound(type, state));
        },
        deploySnackBar: (variant, message) => {
            dispatch(deploySnackBar(variant, message));
        }
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ScanDialog));