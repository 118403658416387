import React from 'react';
import API from 'API';
import _ from 'lodash';
import {
    Grid,
    Typography
} from '@material-ui/core';
import moment from 'moment';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import TabBar from 'Components/Common/Navigation/TabBar';
import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';
import { TabPanel } from 'Functions/MiscFunctions';
import uuidv4 from 'uuid/v4';
import { CLENAWARE_GREEN, CLENAWARE_RED, CLENAWARE_ORANGE } from 'Constants';
// Tabs
import VehicleOverview from './Tabs/VehicleOverview';
import VehicleInformation from './Tabs/VehicleInformation';
import VehicleAllocation from './Tabs/VehicleAllocation/VehicleAllocation';
import VehicleDocuments from './Tabs/VehicleDocuments/VehicleDocuments';
import VehicleSubscriptions from './Tabs/VehicleSubscriptions/VehicleSubscriptions';
import VehicleMot from './Tabs/VehicleMot/VehicleMot';
import VehicleMileage from './Tabs/VehicleMileage/VehicleMileage';
import VehicleMaintenance from './Tabs/VehicleMaintenance/VehicleMaintenance'; 
import VehicleInsurance from './Tabs/VehicleInsurance/VehicleInsurance';
import VehicleIncidents from './Tabs/VehicleIncidents/VehicleIncidents';
import VehicleReturn from './Tabs/VehicleReturn/VehicleReturn';

const initialState = () => ({
    vehicle: {},
    isLoading: true,
    currentTab: 'overview',
    keys: {
        overview: uuidv4(),
        information: uuidv4(),
        allocation: uuidv4(),
        documents: uuidv4(),
        subscriptions: uuidv4(),
        mot: uuidv4(),
        mileage: uuidv4(),
        maintenance: uuidv4(),
        insurance: uuidv4(),
        incidents: uuidv4(),
        vehicleReturn: uuidv4()
    }
});

class ViewVehicle extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.savePageState      = savePageState.bind(this);
    }

    componentDidMount = () => {        
        this.loadComponentData()
    }

    loadComponentData = (refreshKeys = true) => {
        API.get('/vehicles/' + this.props.match.params.vehicleId)
        .then(result => {

            let vehicle = result.data;

            vehicle.motColour = vehicle?.motExpiry === '0000-00-00' || moment().isSameOrAfter(vehicle?.motExpiry) ? 
                                    CLENAWARE_RED 
                                : 
                                    (moment().isSameOrAfter(moment(vehicle?.motExpiry).subtract(1, 'months').format('YYYY-MM-DD')) ?  CLENAWARE_ORANGE : CLENAWARE_GREEN);

            let keys = this.state.state;
            if(refreshKeys){
                keys = {
                    overview: uuidv4(),
                    information: uuidv4(),
                    allocation: uuidv4(),
                    documents: uuidv4(),
                    subscriptions: uuidv4(),
                    mot: uuidv4(),
                    mileage: uuidv4(),
                    maintenance: uuidv4(),
                    insurance: uuidv4(),
                    incidents: uuidv4(),
                    vehicleReturn: uuidv4()
                }
            }                        
            this.setState({
                ...this.state,
                vehicle,
                isLoading: false,
                keys
            });
        });
    };

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        });
    };

    render() {
        const { isLoading, vehicle, currentTab, keys } = this.state;

        const tabItems = [
            {name: 'overview',          label: `Overview` },
            {name: 'information',       label: `Information` },
            {name: 'allocation',        label: `Allocation` },
            {name: 'documents',         label: 'Documents' },
            {name: 'insurance',         label: `Insurance & Breakdown` },
            {name: 'subscriptions',     label: 'Subscriptions' },
            {name: 'maintenance',       label: `Maintenance` },
            {name: 'mot',               label: `MOT History` },
            {name: 'mileage',           label: `Mileage` },
            {name: 'incidents',         label: `Incidents` },
            {name: 'vehicleReturn',    label: `Vehicle Return` }
        ]

        const activeVehicle = vehicle?.status === "In Contract / Ownership" ? true : false;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Vehicle Details
                    </Typography>
                </Grid>              
                <Grid container item spacing={3}>                        
                    <Grid item xs={12}>
                        {(isLoading && (
                            <LoadingCircle />
                        )) || (
                            <>
                                <TabBar
                                    tabs={_.map(_.filter(tabItems, i => i?.label), i => ({label: i.label, value: i.name}))}
                                    currentTab={currentTab} 
                                    handleTabChange={this.handleTabChange}
                                />
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TabPanel value={currentTab} index={'overview'}> 
                                            <VehicleOverview key={keys?.overview} vehicle={vehicle} tabChange={this.handleTabChange} activeVehicle={activeVehicle} />
                                        </TabPanel>

                                        <TabPanel value={currentTab} index={'information'}> 
                                            <VehicleInformation key={keys?.information} vehicle={vehicle} {...this.props} activeVehicle={activeVehicle} />
                                        </TabPanel>

                                        <TabPanel value={currentTab} index={'allocation'}> 
                                            <VehicleAllocation key={keys?.allocation} vehicleId={vehicle?.id} refresh={this.loadComponentData} activeVehicle={activeVehicle} />
                                        </TabPanel>

                                        <TabPanel value={currentTab} index={'documents'}>
                                            <VehicleDocuments key={keys?.documents} vehicleId={vehicle?.id} activeVehicle={activeVehicle} />
                                        </TabPanel>

                                        <TabPanel value={currentTab} index={'insurance'}> 
                                            <VehicleInsurance  key={keys?.insurance} vehicle={vehicle} activeVehicle={activeVehicle} />  
                                        </TabPanel>

                                        <TabPanel value={currentTab} index={'subscriptions'}>
                                            <VehicleSubscriptions key={keys?.subscriptions} vehicleId={vehicle?.id} refresh={this.loadComponentData} activeVehicle={activeVehicle} />
                                        </TabPanel>

                                        <TabPanel value={currentTab} index={'maintenance'}>
                                            <VehicleMaintenance key={keys?.maintenance} vehicleId={vehicle?.id} refresh={this.loadComponentData} activeVehicle={activeVehicle} />
                                        </TabPanel>

                                        <TabPanel value={currentTab} index={'mot'}> 
                                            <VehicleMot key={keys?.mot} refresh={this.loadComponentData} vehicle={vehicle} activeVehicle={activeVehicle} />
                                        </TabPanel>

                                        <TabPanel value={currentTab} index={'mileage'}> 
                                            <VehicleMileage key={keys?.mileage} vehicleId={vehicle?.id} refresh={this.loadComponentData} mileageStats={vehicle?.mileage} deliveryDate={vehicle?.deliveryDate} leaseAnnualMileageAllowance={vehicle?.leaseAnnualMileageAllowance}  activeVehicle={activeVehicle} />
                                        </TabPanel>

                                        <TabPanel value={currentTab} index={'incidents'}>
                                            <VehicleIncidents key={keys?.incidents} vehicleId={vehicle?.id} refresh={this.loadComponentData} activeVehicle={activeVehicle} />
                                        </TabPanel>

                                        <TabPanel value={currentTab} index={'vehicleReturn'}>
                                            <VehicleReturn key={keys?.vehicleReturn} vehicle={vehicle} refresh={this.loadComponentData} tabChange={this.handleTabChange} {...this.props} activeVehicle={activeVehicle} />
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>          
        );
    }
}

export default ViewVehicle;