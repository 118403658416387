import React  from 'react';
import Button from '@material-ui/core/Button';


export default function({body, variant, props, style, force}){

    const innerText = body ? body : 'Back'; 

    const goBack = () => {
        if (force) {
            if (typeof props?.history !== 'undefined') {
                props.history.push(force)
            } else {
                window.location.href = force;
            }
        } else {
            if (typeof props?.history !== 'undefined') {
                if (props.onClick) props.onClick();
                props.history.goBack()
            } else {
                window.history.back();
            }
        }
    }

    return (
        <Button 
            style={style}
            variant={variant ? variant : "outlined"}
            onClick={goBack}>
                {innerText}
        </Button>)
}