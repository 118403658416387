import React from 'react';
import Qr from 'react-qr-reader'
import {MANUAL_QR_CODE_ENTRY} from 'Constants'
import TextField from '@material-ui/core/TextField';

class QrReader extends React.Component {

    handleError = err => {
        console.error(err)
    }

    render() {
        return (
            <>
                <Qr
                    delay={300}
                    resolution={1000}
                    facingMode="environment"
                    showViewFinder={false}
                    onError={this.handleError}
                    onScan={this.props.handleScan}
                    style={{ width: this.props.width ?? '75%' }}
                    className={this.props.className}
                />
                {
                    MANUAL_QR_CODE_ENTRY &&
                    <><br/><TextField
                        onBlur={(e)=>this.props.handleScan(e.target.value)}
                        placeholder="Manual QR Entry"
                        style={{
                            position: 'absolute',
                            top: '380px',
                            left: '2em',
                            width: '270px'
                        }}
                    /></>
                }
            </>
        );
    }
}

export default QrReader;
