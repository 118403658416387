import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';
import { CSVLink }          from "react-csv";

import { Button, Grid, TextField, Typography } from '@material-ui/core';

import DataTable   from 'Components/Common/DataTables/CiDataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import { DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE } from 'Constants';

import { gridPageChanged, gridRowsPerPageChanged }                      from 'Functions/FormFunctions';
import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

const initialState = () => ({
    searchResults: {},
    searchString: '',
    access: {
        updateSubAssembly: false,
        viewSubAssembly: false,
        viewSubAssemblyHistory: false,
        masterExcel: false
    },
    isLoading: true,
    gridPagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    },
    csvDownload: {
        data: [],
        filename: '',
    },
    masterExcelOrder: [],
});
class SubAssemblySearch extends Component {
    constructor(props) {
        super(props);
        this.clearPageState         = clearPageState.bind(this);
        this.getInitialState        = getInitialState.bind(this);
        this.hasPageState           = hasPageState.bind(this);
        this.savePageState          = savePageState.bind(this);
        this.persistenceId          = 'SubAssembly:Search';
        this.state                  = this.getInitialState(initialState());
        this.gridPageChanged        = gridPageChanged.bind(this);
        this.gridRowsPerPageChanged = gridRowsPerPageChanged.bind(this);
    }
    componentDidMount(){
        this.getMaterExcelOrder();
        this.getSearchData();
        this.getAccess();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-sub-assembly'), 
            API.get('/staff/my/access/check/view-sub-assembly'),
            API.get('/staff/my/access/check/sub-assembly-history'),
            API.get('/staff/my/access/check/master-excel')
        ])
        .then(([updateSubAssemblyRes, viewSubAssemblyRes, viewSubAssemblyHistoryRes, masterExcelRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    updateSubAssembly: (updateSubAssemblyRes.data && updateSubAssemblyRes.data.has_access) || false,
                    viewSubAssembly: (viewSubAssemblyRes.data && viewSubAssemblyRes.data.has_access) || false,
                    viewSubAssemblyHistory: (viewSubAssemblyHistoryRes.data && viewSubAssemblyHistoryRes.data.has_access) || false,
                    masterExcel: (masterExcelRes.data && masterExcelRes.data.has_access) || false
                }
            }, this.getMaterExcelOrder);
        })
    }
    getMaterExcelOrder = () => {
        if (this.state.access.masterExcel) {
            API.get("/reports/masterExcel").then(result => {
                if(result?.data) {
                    this.setState({
                        ...this.state,
                        masterExcelOrder: _.map(result.data, i => ({
                            part_number: i?.part?.part_number ?? 'CS-',
                            "Assembly (y)": '',
                            'Qty':  ''
                        }))
                    });
                }
            });
        }

    }
    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/subAssemblies/all', { 
                params: { 
                    searchString:       this.state.searchString,
                    includeOutsourced:  true,
                    with:               ['instruction.stages.jigs']
                },
                props: {
                    cancellation: true
                } 
            }).then(result => {
                if(result?.data) {
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    });
                }
            });
        })
    }
    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value,
            gridPagination: initialState().gridPagination
        },
        () => {
            this.savePageState()
            this.getSearchData();
        });
    }
    resetSearch = () => {
        this.clearPageState();
        this.setState({
            searchString: '',
            gridPagination: initialState().gridPagination
        }, 
        () => {
            this.props.history.replace();
            this.getSearchData();
        });
    }
    downloadMasterExcel = row => {
        API.get("/subAssemblies/" + row.sa_id).then(result => {
            this.setState({
                csvDownload: {
                    data: _.map(this.state.masterExcelOrder, i => {
                        let isSub = _.find(result.data.sub_assembly_parts, j => j?.part?.part_number === i.part_number);
                        let isTop = i.part_number === result.data.part.part_number;
                        return {
                            ...i,
                            "Assembly (y)": !!isTop || !!isSub ? 'Y' : '',
                            'Qty': !!isSub ? isSub.sa_qty : '',
                        }
                    }),
                    filename: `Master Excel - ${result.data.part.part_number}.csv`,
                }
            }, ()=>{
                setTimeout(()=>{
                    document.getElementById('downloadMasterExcel').click();
                }, 100);
            })

        });
    }
    render() {
        const { access, gridPagination, searchString, csvDownload } = this.state;
        return (
            <Grid container spacing={3}>
                {!!csvDownload.filename && !!csvDownload.data &&
                    <CSVLink 
                        data={csvDownload.data} 
                        label={''} 
                        filename={csvDownload.filename}
                        className='hidden'
                        target='_blank'
                        id='downloadMasterExcel'
                    />
                }
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Sub Assembly Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <TextField id="keywordSearch" label="Keywords" fullWidth value={searchString} onChange={this.onSearchStringChange} />
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                           
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key:                        'sa_id',
                                alternatingRowColours:      true,
                                pagination:                 true,
                                //page: gridPagination.page,
                                //rowsPerPage: gridPagination.rowsPerPage,
                                //gridPageChanged: this.gridPageChanged,
                                //gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                persistenceId:              this.persistenceId,
                                isLoading:                  this.state.isLoading,
                                responsivenessImportant:    true
                            }}
                            columns={[
                                {
                                    heading: 'Number',
                                    field: rowData => rowData.part_number,
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Description',
                                    field: rowData => rowData.part_description,
                                    important: true,
                                    main: true,
                                    truncate: true
                                },
                                {
                                    heading: 'Version',
                                    field: rowData => 'v' + rowData.sa_version,
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {
                                                name: 'Master Excel',
                                                icon: icons.excel,
                                                onClick: this.downloadMasterExcel,
                                                disabled: !this.state.access.masterExcel
                                            },
                                            rowData.instruction ? 
                                                (
                                                    rowData.instruction.sai_require_jig && _.sumBy(rowData.instruction.stages, j => j.jigs.length) === 0 ?
                                                        {name: 'Jigs', icon: icons.jigs, link: { pathname: '/sub-assemblies/createBuildJigs/'  + rowData.sa_id}, color: colors.red} :
                                                        {name: 'Jigs', icon: icons.jigs, onClick: ()=>{}, color: colors.green }
                                                ) : 
                                                {name: 'Jigs', icon: icons.jigs, disabled: true},
                                            rowData.instruction ? 
                                                {name: 'Build Stages', icon: icons.worksOrder, onClick: ()=>{}, color: colors.green}:
                                                {name: 'Build Stages', icon: icons.worksOrder, link: { pathname: '/sub-assemblies/createBuildStages/'  + rowData.sa_id, state: {searchString, gridPagination}}, color: colors.red},
                                            {name: 'Update',        icon: icons.update,       link: { pathname: '/sub-assemblies/update/'             + rowData.sa_id,                                                                 state: {searchString, gridPagination}}, disabled: !access.updateSubAssembly || !rowData.can_update || !(rowData.part_outsourced_subassembly || rowData.instruction)},
                                            {name: 'History',       icon: icons.history,      link: { pathname: '/sub-assemblies/history/'            + rowData.sa_id,                                                                 state: {searchString, gridPagination}}, disabled: !access.viewSubAssemblyHistory},
                                            {name: 'View',          icon: icons.search,       link: { pathname: '/sub-assemblies/view/'               + rowData.sa_id,                                                                 state: {searchString, gridPagination}}, disabled: !access.viewSubAssembly}
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(SubAssemblySearch);