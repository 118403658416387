import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import API from '../../../API';
import CiDataTable from '../../Common/DataTables/CiDataTable';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import TextField from '@material-ui/core/TextField';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    access: {
        deactivateShortlink: false
    },
    searchResults: {},
    searchString: '',
    isLoading: true
});

class ShortlinkSearch extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.persistenceId = 'marketing:shortlinkSearch';
        this.state = this.getInitialState(initialState());
    }

    componentDidMount(){
        !this.hasPageState() && this.loadComponentData();
    }

    loadComponentData = () => {
        API.get('/staff/my/access/check/shortlink-deactivate')
        .then(deactivateShortlink => {
            this.setState({
                ...this.state,
                access: {
                    deactivateShortlink: (deactivateShortlink.data && deactivateShortlink.data.has_access) || false
                }
            }, () => {
                this.getSearchData();
            });
        })
    }

    getSearchData = () => {
        const { searchString } = this.state;
        this.setState({
            isLoading: true
        }, () => {
            API.get('/marketing/shortlinks/all', 
                {
                    params: {
                        searchString
                    },
                    props: { 
                        cancellation: true 
                    }
                }
            )
            .then(result => {
                if(result?.data) {
                    this.setState({
                        searchResults: result.data,
                        isLoading: false
                    }, () => {
                        this.savePageState();
                    });
                }
            });
        })
    }
    handleDeactivate = id => API.put(`/marketing/shortlinks/${id}/deactivate`)
    .then(res => {
        if(res?.data) {
            this.getSearchData(false);
            this.props.deploySnackBar("success", "The shortlink has been de-activated");
        }
    })
    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.getSearchData();
        });
    }
    resetSearch = () => {
        this.setState({
            searchString: '',
            dateFrom: null,
            dateTo: null
        }, 
        () => {
            this.getSearchData();
        });
    }
    render() {
        const { access, isLoading, searchString, searchResults } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Shortlink Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <TextField id="keywordSearch" label="Keywords" fullWidth value={searchString} onChange={this.onSearchStringChange} />
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                           >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <CiDataTable  
                            config={{
                                key:                    'sl_id',
                                pagination:             true,
                                isLoading:              isLoading,
                                responsiveImportance:   true,
                                alternatingRowColours:  true,
                                persistenceId:          this.persistenceId,
                                options: {
                                    dataRef: true,
                                    reset: this.resetSearch,
                                    export: {
                                        title: `Short URL List`,
                                        name: `Short-URLs`,
                                        excel: true,
                                        pdf: true,
                                        print: true
                                    }
                                }
                            }}
                            columns={[
                                {
                                    heading: 'Code',
                                    field: rowData => rowData.sl_code,
                                    dataRef: 'sl_code',
                                    main: true,
                                    important: true,
                                    sizeToContent: true,
                                    cellProps: rowData => ({
                                        style: {
                                            textDecoration: rowData.sl_active === 0 ? 'line-through' : undefined
                                        }
                                    })
                                },
                                {
                                    heading: 'Short URL',
                                    field: rowData => rowData.url,
                                    dataRef: 'url',
                                    main: true,
                                    important: true,
                                    sizeToContent: true,
                                    cellProps: rowData => ({
                                        style: {
                                            textDecoration: rowData.sl_active === 0 ? 'line-through' : undefined
                                        }
                                    })
                                },
                                {
                                    heading: 'Target URL',
                                    field: rowData => rowData.sl_target,
                                    truncate: true,
                                    important: true,
                                    dataRef: 'sl_target',
                                    cellProps: rowData => ({
                                        style: {
                                            textDecoration: rowData.sl_active === 0 ? 'line-through' : undefined
                                        }
                                    })
                                },
                                {
                                    heading: 'Clicks',
                                    field: rowData => rowData.clicks_count,
                                    important: true,
                                    dataRef: 'clicks_count',
                                    sizeToContent: true,
                                    alignment: 'right',
                                    cellProps: rowData => ({
                                        style: {
                                            textDecoration: rowData.sl_active === 0 ? 'line-through' : undefined
                                        }
                                    })
                                },
                                {
                                    heading: 'Unique Clicks',
                                    field: rowData => rowData.unique_clicks_count,
                                    important: true,
                                    dataRef: 'unique_clicks_count',
                                    sizeToContent: true,
                                    alignment: 'right',
                                    cellProps: rowData => ({
                                        style: {
                                            textDecoration: rowData.sl_active === 0 ? 'line-through' : undefined
                                        }
                                    })
                                },
                                {
                                    heading: 'Created',
                                    field: rowData => rowData.sl_created_datetime,
                                    fieldFormat: 'date',
                                    dataRef: 'sl_created_datetime',
                                    sizeToContent: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'De-Activate', icon: 'trash-alt', onClick: () => this.props.deployConfirmation("Are you sure you want to de-activate this shortlink?", "De-Activate Shortlink", () => this.handleDeactivate(rowData.sl_id)), disabled: (rowData.sl_active === 0 || !access.deactivateShortlink) && true},
                                            {name: 'Copy to Clipboard', type: 'copyToClipboard', data: rowData.url, disabled: rowData.sl_active === 0 && true},
                                            {name: 'Open Link', icon: 'external-link', onClick: () => window.open(rowData.url), disabled: rowData.sl_active === 0 && true}
                                        ]
                                    }
                                }
                            ]}
                            rows={searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                       => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                => dispatch(setPersistence(key, state)),
    deploySnackBar:     (variant, message)          => dispatch(deploySnackBar(variant, message)),
    deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success))
})

export default connect(mapStateToProps, mapDispatchToProps)(ShortlinkSearch);