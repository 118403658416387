import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import React from 'react';
import IconHelper from 'Helpers/IconHelper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { colors } from 'Helpers/ColourHelper';
import _ from 'lodash';
import InfoBox from 'Components/Common/reports/InfoBox';
import { Grid } from '@material-ui/core';

export default function (props) {
    const [typeFilter, setTypeFilter] = React.useState('All');
    const contacts = [...props.campaign.contacts, ...props.campaign.invalidContacts]
    return (
        <Grid container spacing={2}>
            <InfoBox
                onClick={()=>setTypeFilter('All')}
                title='All'
                value={contacts.length}
                icon={IconHelper.contacts}
                rowSize={4}
                color={typeFilter === 'All' && colors.green}
                border={typeFilter === 'All' && `1px solid ${colors.green}`}
            />
            <InfoBox
                onClick={()=>setTypeFilter('Valid')}
                title='Valid'
                value={_.filter(contacts, {type: 'Valid'}).length}
                icon={IconHelper.true}
                rowSize={4}
                color={typeFilter === 'Valid' && colors.green}
                border={typeFilter === 'Valid' && `1px solid ${colors.green}`}
            />
            <InfoBox
                onClick={()=>setTypeFilter('Warning')}
                title='Warning'
                value={_.filter(contacts, {type: 'Warning'}).length}
                icon={IconHelper.warning}
                rowSize={4}
                color={typeFilter === 'Warning' && colors.orange}
                border={typeFilter === 'Warning' && `1px solid ${colors.orange}`}
            />
            <InfoBox
                onClick={()=>setTypeFilter('Invalid')}
                title='Invalid'
                value={_.filter(contacts, {type: 'Invalid'}).length}
                icon={IconHelper.warning}
                rowSize={4}
                color={typeFilter === 'Invalid' && colors.red}
                border={typeFilter === 'Invalid' && `1px solid ${colors.red}`}
            />
            <Grid item xs={12}>
                <CiDataTable
                    config={{
                        key: 'contact_id',
                        pagination: contacts.length > 100,
                    }}
                    rows={_.orderBy(_.filter(contacts, c => (typeFilter === 'All' || c.type === typeFilter)), 'contact_name')}
                    columns={[
                        {
                            heading: 'Name',
                            field: 'contact_name',
                            sizeToContent: true,
                            dataRef: 'contact_name',
                        },
                        {
                            heading: 'Email',
                            field: 'contact_email',
                            dataRef: 'contact_email',
                        },
                        {
                            heading: '',
                            field: i => i.type !== 'Valid' &&
                                <AllIcon
                                    icon={IconHelper.warning}
                                    color={i.type === 'Warning' ? colors.orange : colors.red}
                                    tooltip={i.type === 'Warning' ?  'This contacts email address may be invalid.' : 'This contacts email address is invalid.'}
                                    noMargin
                                />,
                            sizeToContent: true,
                            dataRef: 'warning',
                            alignment: 'center',
                        },
                        {
                            actions: t => ([
                                {
                                    name: 'View',
                                    icon: IconHelper.view,
                                    link: `/customers/contacts/view/${t.contact_id}`
                                }
                            ])
                        }
                    ]}
                />
            </Grid>
        </Grid>
    )
}
