import React from 'react';
import { connect } from 'react-redux';

import API from 'API';

import _ from 'lodash';

import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import StaffActions from 'Components/Sales/Order/ViewOrder/sections/StaffActions';

import { TableContainer } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog } from 'Actions/Dialog/Dialog';


import { colors } from 'Helpers/ColourHelper';

const initialState = () => ({
    isLoading:          true,
    orderDetails:       [],
    dataRows:           [],
    orderId:            0,
    process:            false, 
    confirmationOpen:   false,
    orderLocked:        true,
});

class ElectricalConnectionsDialog extends React.Component {
    constructor(props){
        super(props);
        this.state = {...initialState(), orderId: this.props.orderId ?  this.props.orderId : (this.props.match.orderId ? this.props.match.orderId : this.props.orderId)};
        this.timeout = null;
    }

    handleSubmit = () => {
        //stop double clicking causing multiple submits
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.#handleSubmit, 500);
    }

    #handleSubmit = () => {
        //just incase
        clearTimeout(this.timeout);
        let parts = _.map(this.state.dataRows, i => {
            return _.assign({
                connections:    i.connections, 
                id:             i.og.od_id
            });
        });
        API.post(`/sales/orders/${this.state.orderId}/setElectricalConnections`, {parts})
        .then(res => {
            if (res.data.errors){
                this.props.deploySnackbar('Order has already been updated', 'error');
                this.handleClose();
            } else if (res.data.id) { 
                this.props.success(res.data.id);
            }
        })
    };
 
    componentDidMount = () => {
        this.getOrderDetails()
    }

    handleClose = () => {
        window.location.reload();
        this.props.closeDialog();
    }

    getOrderDetails = () => {
        API.get(`/sales/orders/${this.state.orderId}`)
        .then(res => {
            if (res.data.order_latest) {
                let dataRows = [];
                _.map(res.data.order_details, i =>{
                    const options = _.map(i.part.electrical_connections, e => {return _.assign({value: e.connection.ec_name, label: e.connection.ec_name})})
                    dataRows.push({
                        og:             i,
                        max_quantity:   parseFloat(i.od_quantity),
                        options,
                        ogOptions:      [...options],
                        connections:    [{
                            quantity:       parseFloat(i.od_quantity),
                            ec:             i.od_electrical_connection || (options.length === 1 ? options[0].value : null),
                        }]
                    })
                });
                this.setState({
                    orderDetails:   res.data.order_details,
                    isLoading:      false,
                    dataRows,
                }, () => {
                    this.validate()
                    _.map(dataRows, (dataRow, rowIdx) => {
                        _.map(dataRows.connections, (con, conIdx) => {
                            this.getSelectOptions(rowIdx, conIdx);
                        })
                    })
                });
            } else {
                this.props.deployConfirmation(
                    'This Order is not the most recent version. The page will be refreshed to get the correct data',
                    'Outdated Order',
                    ()=>{window.location.reload()},
                    ()=>{window.location.reload()}
                )
            }
        })
    };

    handleSelectChange = (rowIdx, conIdx) => e => {
        const   value  = e ? e.value : null;
        let     dataRows    = [...this.state.dataRows],
                dataRow     = dataRows[rowIdx],
                connections = [...dataRow.connections],
                options     = dataRow.options;

        if (value) {
            _.pullAllBy(options, [{value, label:value}], 'value')
        } else {
            options.push({value: connections[conIdx].ec, label: connections[conIdx].ec})
        }
        connections[conIdx] = {...connections[conIdx], ec: value};
        dataRows[rowIdx]    = {...dataRow, connections, options};
        this.setState({dataRows}, ()=>{ this.validate(); this.getSelectOptions(rowIdx, conIdx)});
    }

    getSelectOptions = (rowIdx, conIdx) => {
        let     dataRows    = [...this.state.dataRows],
                dataRow     = dataRows[rowIdx],
                connections = [...dataRow.connections],
                og          = [...dataRow.ogOptions];
        
        let selected = [];
        _.map(connections, c=> selected.push({value: c.ec, label:c.ec}));
        _.pullAllBy(og, selected, 'value')

        dataRows[rowIdx]    = {...dataRow, options:og};
        this.setState({dataRows});
    }

    handleChangeQuantity= (rowIdx, conIdx) => v => {
        const   dataRows    = [...this.state.dataRows],
                connections = [...dataRows[rowIdx].connections];
        connections[conIdx] = {...connections[conIdx], quantity: v};
        dataRows[rowIdx]    = {...dataRows[rowIdx], connections};
        this.setState({dataRows}, this.validate);
    }

    validate = () => {
        let process = true;
        _.map(this.state.dataRows, r => {
            if (r.options.length) {
                if (parseFloat(_.sumBy(r.connections, j => {return parseFloat(j.quantity)})) !== r.max_quantity) process = false;
                if (_.filter(r.connections, i => { return i.ec == '' || i.ec == null }).length) process = false;
            }
        });
        this.setState({process})
    }

    split = (rowIdx, conIdx) => {
        const dataRows      = [...this.state.dataRows];
        const dataRow       = dataRows[rowIdx];
        const connections   = [...dataRow.connections]
        const connection    = connections[conIdx];
        let   rowQuantity   = 1;
        
    /*
        if(connection.quantity > 1){
            //split row
            //rowQuantity = Math.floor(parseInt(connection.quantity) / 2);
            rowQuantity = 1;
            //connections[conIdx] = {...connections[conIdx], quantity: connection.quantity - rowQuantity};
            connections[0] = {...connections[0], quantity: (connections[0].quantity - rowQuantity)};
        } 
    */

        rowQuantity = 1;
        connections[0] = {...connections[0], quantity: (connections[0].quantity - rowQuantity)};

        connections.push({
            ec:         null,
            quantity:   rowQuantity
        })
        dataRows[rowIdx]    = {...dataRows[rowIdx], connections};
        this.setState({dataRows}, this.validate);
    }

    delete = (rowIdx, conIdx) => {
        const dataRows      = [...this.state.dataRows];
        const dataRow       = dataRows[rowIdx];
        const connections   = [...dataRow.connections]

        //connections[(conIdx - 1)].quantity = parseInt(connections[(conIdx - 1)].quantity) + parseInt(connections[conIdx].quantity)
        connections[0].quantity = parseInt(connections[0].quantity) + parseInt(connections[conIdx].quantity)

        _.pullAt(connections,[conIdx]);
        
        if (connections.length === 1){
            connections[0].quantity = dataRow.max_quantity;
        } 

        dataRows[rowIdx]    = {...dataRows[rowIdx], connections};
        this.setState({dataRows}, ()=>{ this.validate(); this.getSelectOptions(rowIdx, conIdx)});
    }

    toggleConfirm = () => {
        this.setState({confirmationOpen: !this.state.confirmationOpen})
        this.props.deployConfirmation(
            'Are you sure you want to set these electrical connections?',
            'Attach Electrical Connections?',
            this.handleSubmit
        )
    }

    lockOrder = orderLocked => {
        this.setState({ orderLocked });
    };

    render(){

        const { isLoading, orderLocked, orderId } = this.state;

        return (
            <>
                {isLoading ? (
                    <LoadingCircle />
                ) : (
                    <Box>
                        <Grid container spacing={1}>
                            <Grid xs={12}>
                                <StaffActions orderId={orderId} lockCallback={this.lockOrder} type={'Confirming'}/>
                            </Grid>
                            {orderLocked ? 
                                <>
                                    <Grid item xs={12}>
                                        {orderLocked}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="buttonRow">
                                            <Button onClick={this.handleClose} variant="outlined" color="default">Close</Button>
                                        </div>
                                    </Grid>
                                </>
                                : <>
                                    <Typography variant="body" style={{padding:'1em 0'}}>
                                    Set Electrical Connections Before Despatch *
                                    </Typography>
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                {_.map(this.state.dataRows, (r, ri) => 
                                                    <>
                                                        {!!r.ogOptions.length &&
                                                            <>
                                                                {ri > 0 && 
                                                                    <TableRow colspan={4} key={`row-div-${ri}`} style={{paddingTop: '2.5em'}}>
                                                                        <br></br>
                                                                    </TableRow>
                                                                }
                                                                <TableRow key={`row-${ri}`}>
                                                                    <TableCell colspan={3}>
                                                                        <b>{`${r.og.od_part_number} - ${r.og.od_part_description}`}</b>
                                                                    </TableCell>
                                                                    <TableCell style={{color: parseFloat(_.sumBy(r.connections, j => {return parseFloat(j.quantity)})) !== r.max_quantity ? colors.red: '' }}>
                                                                        {`${parseFloat(_.sumBy(r.connections, j => {return parseFloat(j.quantity)}))}/${r.max_quantity}`}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell >Split</TableCell>
                                                                    <TableCell>Quantity</TableCell>
                                                                    <TableCell>Connection *</TableCell>
                                                                    <TableCell></TableCell>
                                                                </TableRow>
                                                                {_.map(r.connections, (c, ci) => 
                                                                    <TableRow key={ci}>
                                                                        <TableCell padding="checkbox">
                                                                            <IconButton disabled={r.max_quantity <= 1 || (ci === 0 && c.quantity === 1 ) || r.connections.length === r.ogOptions.length} onClick={()=>{this.split(ri,ci)}}>
                                                                                <FALightIcon icon='level-down-alt' button noMargin disabled={r.max_quantity <= 1 || (ci == 0 && c.quantity == 1 ) || r.connections.length === r.ogOptions.length}/>
                                                                            </IconButton>
                                                                        </TableCell>
                                                                        <TableCell style={{width:'5em'}}>
                                                                            <FormControl margin="none" >
                                                                                <TextField type='number' value={c.quantity} disabled={r.max_quantity <= 1} 
                                                                                    disabled={ r.ogOptions.length === 1}
                                                                                    onChange={(e)=>{this.handleChangeQuantity(ri,ci)(e.target.value)}} 
                                                                                    InputProps={{ inputProps: { min: 1, max: r.max_quantity } }}
                                                                                />
                                                                            </FormControl>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <FormControl fullWidth margin="none"  disabled={ r.ogOptions.length === 1}>
                                                                                {r.ogOptions.length == 1 ? 
                                                                                    <TextField disabled={1} value={c.ec} />:
                                                                                    <AutoCompleteSelect options={r.options} value={c.ec} onChange={(e) => {this.handleSelectChange(ri,ci)(e)}}/>
                                                                                }
                                                                            </FormControl>
                                                                        </TableCell>
                                                                        <TableCell padding="checkbox">
                                                                            <IconButton disabled={r.connections.length === 1 || ci == 0} onClick={()=>{this.delete(ri,ci)}}>
                                                                                <FALightIcon icon='times' button noMargin disabled={r.connections.length === 1 || ci == 0}/>
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </>
                                                        }
                                                    </>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Grid item xs={12}>
                                        <div className="buttonRow">
                                            <Button onClick={this.handleClose} variant="outlined" color="default">Close</Button>
                                            <Button onClick={this.toggleConfirm} autoFocus variant="contained" color="primary" disabled={!this.state.process}>Process</Button>
                                        </div>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Box>
                )}
            </>
        );
    }

}

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(closeDialog()),
    deploySnackbar: (msg, variant)                      => dispatch(deploySnackBar(variant, msg)),
    deployConfirmation: (message, title, success, cancel) => dispatch(deployConfirmation(message, title, success, cancel)),
})

export default connect(null, mapDispatchToProps)(ElectricalConnectionsDialog)