import React from 'react';
import _ from 'lodash';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { handlePopperClick, handlePopperClose } from 'Functions/MiscFunctions';

const initialState = {
    anchorEl: null
}

class ActionMenu extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handlePopperClick = handlePopperClick.bind(this);
        this.handlePopperClose = handlePopperClose.bind(this);
    }

    handleOnClick = onClick => {
        this.handlePopperClose();
        if(onClick) {
            onClick();
        }
    }

    render = () => {
        const   { anchorEl }    =   this.state,
                { rows }        =   this.props,
                open            =   Boolean(anchorEl),
                disabled        =   !rows || _.isEmpty(rows) || _.every(rows, el => !el.display);
        return (
            <ClickAwayListener onClickAway={this.handlePopperClose} touchEvent={false}>
                <span>
                    <Tooltip title="More" placement="bottom">
                        <IconButton
                           aria-controls='action-menu'
                           aria-haspopup="true"
                           disabled={disabled}
                           onClick={this.handlePopperClick}
                        >
                            <FAIcon type="light" icon="ellipsis-v" disabled={disabled} size={17.5} button noMargin />
                        </IconButton>
                    </Tooltip>
                    <Popper 
                        id={open ? 'action-menu' : undefined} 
                        open={open}
                        anchorEl={anchorEl}
                        className="action-menu"
                        transition
                        placement="bottom"
                    >
                        <Paper>
                            <List disablePadding className='sm-scrollbar'>
                                {_.map(rows, (row, idx) => {
                                    if(row.display) {
                                        return (
                                            <React.Fragment key={idx}>
                                                <ListItem
                                                    button
                                                    disabled={row.disabled}
                                                    onClick={() => this.handleOnClick(row.onClick)}
                                                >
                                                    <ListItemIcon>
                                                        <FAIcon type="light" icon={row.icon ?? 'cog'} size={15} disabled={row.disabled} button noMargin />
                                                    </ListItemIcon>
                                                    <ListItemText 
                                                        primary={<Typography variant="body2" style={{fontWeight: 400, fontSize: 12}}>{row.label}</Typography>} 
                                                    />
                                                </ListItem>
                                                {row.divider && (
                                                    <Divider />
                                                )}
                                            </React.Fragment>
                                        )
                                    } else {
                                        return <React.Fragment key={idx} />
                                    }
                                })}
                            </List>
                        </Paper>
                    </Popper>
                </span>
            </ClickAwayListener>
        )
    }
}

export default ActionMenu;