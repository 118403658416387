import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import DataTable from '../../../Common/DataTables/DataTable';

class NominalCodes extends Component {

    render() {
        const { nominalCodes } = this.props;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Nominal Codes</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {nominalCodes && nominalCodes.length > 0 ? 
                        <DataTable 
                            config={{
                                key: 'nominal_id',
                                pagination: false,
                                plainHeader: true
                            }}
                            columns={[
                                {
                                    heading: 'Category',
                                    field: row => row.category && row.category.nom_cat_name
                                },
                                {
                                    heading: 'Code',
                                    field: row => row.nominal_code
                                },
                                {
                                    heading: 'Name',
                                    field: row => row.nominal_name
                                }
                            ]}
                            rows={nominalCodes}
                        />
                    :
                        <Typography>There are 0 Nominal Codes found.</Typography>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default NominalCodes;
