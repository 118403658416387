import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import API from '../../../API';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import CiDataTable from '../../Common/DataTables/CiDataTable';
import moment from 'moment';
import {statusColour} from './../../../Helpers/ColourHelper'
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import Link from '@material-ui/core/Link';
import {DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE} from 'Constants'
import { gridPageChanged, gridRowsPerPageChanged } from 'Functions/FormFunctions'
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    keywords: '',
    searchResults: {},
    isLoading: true,
    gridPagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    }
});

class PurchaseOrderSearch extends Component {
    constructor(props) {
        super(props);
        this.clearPageState         = clearPageState.bind(this);
        this.getInitialState        = getInitialState.bind(this);
        this.hasPageState           = hasPageState.bind(this);
        this.savePageState          = savePageState.bind(this);
        this.persistenceId          = 'PurchaseOrder:PurchaseOrderSearch';
        this.state                  = this.getInitialState(initialState());
        this.gridPageChanged        = gridPageChanged.bind(this);
        this.gridRowsPerPageChanged = gridRowsPerPageChanged.bind(this);
    }

    componentDidMount = () => {
        //this.setQuery();
        this.getSearchData();
    }
/*
    setQuery = () => {
        const { state = {} } = this.props.location;
        this.setState({
            keywords: state?.keywords || initialState.keywords,
            gridPagination: {
                ...this.state.gridPagination,
                page: state?.gridPagination?.page || initialState.gridPagination.page,
                rowsPerPage: state?.gridPagination?.rowsPerPage || initialState.gridPagination.rowsPerPage,
            }
        },
        () => {
            this.getSearchData();
        });
    };
*/
    getSearchData = () => {
        const params = {
            keywords: this.state.keywords
        }
        this.setState({
            isLoading: true
        }, () => {
            API.get('/purchaseOrders', {
                params,
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    _.map(result.data, (po) => {
                        po.colour = statusColour(po.po_status);
                    });
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    })
                }
            })
        })
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value,
            gridPagination: initialState().gridPagination
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
            //gridPagination: initialState.gridPagination
        }, 
        () => {
            //this.props.history.replace();
            this.getSearchData();
        });
    }

    downloadFile = rowData => {
        API.get('/purchaseOrders/' + rowData.po_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    render() {
        const { keywords, gridPagination } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Purchase Order Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default">
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <CiDataTable  
                            config={{
                                key: 'po_id',
                                pagination: true,
                                //page: gridPagination.page,
                                //rowsPerPage: gridPagination.rowsPerPage,
                                //gridPageChanged: this.gridPageChanged,
                                //gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                persistenceId: this.persistenceId,
                                alternatingRowColours: true,
                                responsiveImportance: true,
                                isLoading: this.state.isLoading
                            }}
                            columns={[
                                {
                                    heading: 'Purchase Order Date',
                                    field: rowData => moment(rowData.po_date).format("DD/MM/YYYY"),
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    actionsCustomHeader:    true,
                                    sizeToContent:          true,
                                    alignment:              'left',
                                    heading:                'Purchase Order Number',
                                    actions: rowData => {
                                        return [
                                            {label: rowData.po_number + '-' + rowData.po_number_amendment, link: `/purchase-order/view/${rowData.po_id}`, type:'plainLink',}
                                        ]
                                    }
                                },
                                {
                                    heading: 'Supplier',
                                    field: rowData => rowData.po_supplier_name,
                                    important: true,
                                    truncate: true,
                                },
                                {
                                    heading: 'Placed By',
                                    field: rowData => rowData.po_placed_by,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Approval By',
                                    field: rowData => rowData.po_approval_by,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Status',
                                    field: rowData => ['Completed', 'Declined', 'Cancelled'].includes(rowData.po_status) ? rowData.po_status : 'Awaiting '+rowData.po_status,
                                    cellProps: rowData => {
                                        return ({
                                            style: {
                                                color: rowData.colour
                                            }
                                        });
                                    },
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Net Total',
                                    field: rowData => rowData.po_total_price,
                                    fieldFormat: 'currency',
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Download', icon: 'download', onClick: this.downloadFile},
                                            {name: 'View', icon: 'search', link: { pathname: '/purchase-order/view/' + rowData.po_id, state: {keywords, gridPagination}}}
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)        => dispatch(clearPersistence(key)),
    setPersistence:     (key, state) => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderSearch);
