import React, { Component } from 'react';
import { connect }          from 'react-redux';
import API                  from 'API';
import _                    from 'lodash';

import DataTable          from 'Components/Common/DataTables/CiDataTable';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import Textarea           from 'Components/Common/Inputs/Textarea';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import SelectPartWithQR   from 'Components/Common/Selects/SelectPartWithQR';

import { colors }    from 'Helpers/ColourHelper';
import icons         from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';

import { AppBar, Button, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, List, ListItem, ListItemText, Tab, Tabs, Typography, Dialog } from '@material-ui/core/';

import logo from 'Assets/Images/clenaware_logo_icon.png';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

const initialState = {
    isLoading:   true,
    formData:    {
        part: 0
    },
    partList:       [],
    staffList:      [],
    suppliersList:  [],
    trackers:       [],
    showTracker:    0,
    notes:          null,
    currentTab:     'Created'
}


class PartsOrderingTracker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            showTracker: parseInt(this.props.match.params.tid)
        }
    }

    componentDidMount = () => { 
        this.getStaticData();
        this.getData(); 
    }

    getStaticData = () => {
        Promise.all([
            API.get('/parts/all' , {params: {active: true}}),
            API.get('/staff/all/withAccess/procurement-tracker:notification', {params: {active: true}}),
            API.get('/staff/my/access/check/procurement-tracker:create'), 
            API.get('/suppliers/all')
        ]).then(([partsRes, staffRes, createAccessRes, suppRes]) => {
            this.setState({
                partList:   _.map(partsRes.data, part => _.assign({
                                    value: part.part_id,
                                    label: `${part.part_number} - ${part.part_description}`,
                                    part
                                })
                            ),
                staffList:  _.map(staffRes.data, staff => _.assign({
                    value: staff.staff_id,
                    label: staff.full_name,
                    staff
                })),
                suppliersList:  _.map(suppRes.data, supp => _.assign({
                    value: supp.supp_id,
                    label: supp.supp_company_name,
                    supp
                })),
                access: { 
                    create: createAccessRes.data.has_access || false,
                }
            })
        })
    }

    getData = () => {
        this.setState({isLoading: true},()=>
            API.get('/partsOrdering/tracker',{params:{status: this.state.currentTab}})
            .then(res => {
                this.setState({
                    trackers:    res.data,
                    isLoading:   false,
                    note:        null
                });
            })
        )
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    }

    handleCreateStockTrack = () => {
        API.post('/partsOrdering/tracker',this.state.formData).then(res => {
            this.getData();
            this.handleSelectChange('part')(null);
            this.setState({showTracker: res.data.ppot_id});
        })
    }

    
    handleTabChange = (event, value) => {
        this.setState({currentTab: value},this.getData);
    };
    
    updateAssigned = e => {
        if (e) API.post(`/partsOrdering/tracker/${this.state.showTracker}`,{staff: e.value}).then(this.getData);
    }
    
    handleChangeStatus = (id, status) => {
        if (this.state.note){ API.post(`/partsOrdering/tracker/${id}`,{note: this.state.note}) }
        this.setState({showTracker: 0}, () => {API.post(`/partsOrdering/tracker/${id}`,{status}).then(this.getData)})
    }
    
    addNote = id => {
        API.post(`/partsOrdering/tracker/${id}`,{note: this.state.note})
        .then(res => {this.setState({note: null},this.getData)})
    }

    handleSupplier = e => {
        if (e) API.post(`/partsOrdering/tracker/${this.state.showTracker}`,{supplier: e.value}).then(this.getData);
    }

    handleReminder = e => {
        API.post(`/partsOrdering/tracker/${this.state.showTracker}`,{reminder: e.value}).then(this.getData);
    }

    handleClose = () => {
        if (this.state.note){ this.addNote(this.state.showTracker) }
        this.setState({showTracker: 0, note: null})
    }

    render() {
        const {partList, isLoading, formData, trackers, access, note, currentTab} = this.state;

        const tabs = [
            {label: 'Created',   value: 'Created'},
            {label: 'In Progress',   value: 'In Progress'},
            {label: 'Completed', value: 'Completed'},
        ]

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h5'>Procurement Tracker</Typography>
                </Grid>
                <Grid item xs={12}>
                    <AppBar position="static" style={{backgroundColor:'white'}} >
                        <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                            {_.map(tabs, t => <Tab {...t}/>   )}
                        </Tabs>
                    </AppBar>
                </Grid>
                {currentTab == 'Created' && access?.create && 
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <SelectPartWithQR
                                        parts=      {partList}
                                        onChange=   {this.handleSelectChange('part')}
                                        value=      {formData.part}
                                        margin=     "none"
                                    />
                                </Grid>
                                <Grid item xs={12} className='buttonRow'>
                                    <Button variant='contained' color='primary' onClick={this.handleCreateStockTrack}>Add</Button>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
                <Grid item xs={12}>
                    {isLoading ? <LoadingCircle/> :
                        <PaddedPaper>
                            <DataTable
                                config={{
                                    key: 'ppot_id',
                                }}
                                columns={[
                                    {
                                        heading:       'Date',
                                        field:         'ppot_created_datetime',
                                        fieldFormat:   'clenydate',
                                        sizeToContent: true,
                                        alignment:     'center',
                                        filter:        'dateRange',
                                    },
                                    {
                                        heading:       'Part',
                                        field:         rowData => rowData.part.part_number,
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       'Description',
                                        field:         rowData => rowData.part.part_description,
                                        filter:        'select',
                                        filterMod:     {
                                            styleGrid: {
                                                width: '20%'
                                            }
                                        },
                                    },
                                    {
                                        heading:       'Assigned To',
                                        field:         'ppot_assinged',
                                        dataRef:       'ppot_assinged',
                                        sizeToContent: true,
                                        filter:        'select',
                                        filterMod:     {
                                            styleGrid: {
                                                width: '20%'
                                            }
                                        }
                                    },
                                    {
                                        heading:        'Last Update',
                                        field:          rowData => {
                                                            if (rowData.notes.length == 0) return '-';
                                                            let lastUpdate = _.last(rowData.notes);
                                                            return _.last(rowData.notes).ppotn_tracker
                                                        },
                                        sizeToContent:  true,
                                        alignment:      'left',
                                        info:           rowData => rowData.notes.length > 0 && clenyDate(_.last(rowData.notes).ppotn_datetime),
                                        infoEnd:        'stretch',
                                        dataRef:        rowData => _.last(rowData.notes)?.ppotn_tracker || '-',
                                        filter:        'select',
                                        filterMod:     {
                                            styleGrid: {
                                                width: '20%'
                                            }
                                        }
                                    },
                                    {
                                        heading:       'Last Note',
                                        field:          rowData => rowData.notes.length > 0 ?
                                                            <>
                                                                {_.last(rowData.notes).ppotn_note}
                                                            </> : '-',
                                        info:           rowData => rowData.notes.length > 0 &&
                                                            <>
                                                                {_.last(rowData.notes).ppotn_note.split('\n').map((item, key) => {
                                                                        return <span key={key}>{item}<br/></span>
                                                                    })
                                                                }
                                                            </>,
                                        infoEnd:        'stretch',
                                        truncate:       true,
                                    },
                                    {
                                        actions: rowData => [
                                            {
                                                name: 'Edit',
                                                icon: rowData.ppot_status == 'Completed' ? icons.search : icons.edit,
                                                onClick: () => {this.setState({showTracker: rowData.ppot_id})}
                                            }
                                        ]
                                    }
                                ]}
                                rows={_.orderBy(trackers, 'ppot_created_datetime', 'desc' )}
                            />
                        </PaddedPaper>
                    }
                </Grid>
                {!!this.state.showTracker && _.find(trackers, {ppot_id: parseInt(this.state.showTracker)})?.ppot_id &&
                    <Dialog 
                        open={this.state.showTracker} 
                        onClose={()=>this.setState({showTracker: 0})} 
                        fullWidth={true} 
                        fullScreen
                    >
                        <DialogTitle style={{padding: 0, background: colors.blue}}>
                            <Grid container xs={12} style={{alignItems: 'center', padding: '0.5em 1em'}}>
                                <Grid item style={{paddingRight: 15}}>
                                    <img src={_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.part?.default_image?.thumbnail_file_path || logo} alt='Not Found' style={{maxHeight:65, maxWidth:65}} />
                                </Grid>
                                <Grid item>
                                    <Typography style={{color: colors.white}} variant="h6">{_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.part?.part_number}</Typography>
                                    <Typography style={{color: colors.white}} variant="body1">{_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.part?.part_description}</Typography>
                                </Grid>
                                <Grid item style={{marginLeft: 'auto'}}>
                                    <AllIcon noMargin icon={icons.search} color={colors.white} size='large' solid onClick={()=>{window.open(`/parts/view/${_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.part?.part_id}`, '_blank').focus();}} />
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container xs={12} spacing={3} style={{height: '100%', paddingTop: '1em'}}>
                                <Grid item xs={5}>
                                    <Grid container xs={12} style={{height: '100%', alignItems: 'flex-start', alignContent: 'flex-start'}}>
                                        {_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.ppot_status != 'Completed' &&
                                            <Grid item xs={12} style={{marginBottom: '1.5em'}}>
                                                <Typography variant='h5'>Assigned</Typography> 
                                                <FormControl fullWidth>
                                                    <AutoCompleteSelect
                                                        label='Assigned To'
                                                        options={this.state.staffList}
                                                        value={_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.ppot_assinged_id}
                                                        onChange={this.updateAssigned}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item xs={12} style={{marginBottom: '1.5em'}}>
                                           <Typography variant='h5'>Suppliers</Typography> 
                                            {_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.ppot_status != 'Completed' &&
                                                <FormControl fullWidth>
                                                    <AutoCompleteSelect
                                                        options={_.filter(this.state.suppliersList, i=>!_.find(_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.suppliers, {supp_id: i.value}))}
                                                        value={null}
                                                        label="Add Supplier"
                                                        onChange={this.handleSupplier}
                                                    />
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={12} style={_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.suppliers.length < 7 ? {} : {height: _.find(this.state.trackers, {ppot_id:this.state.showTracker})?.ppot_status != 'Completed' ? 'calc(100vh - 405px)' : 'calc(100vh - 250px)', overflowY:'scroll'}}>
                                            <List style={{paddingRight: 15}}>
                                                {_.map(_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.suppliers, (supp,idx) => 
                                                    <ListItem style={{padding: 0, borderBottom: _.find(this.state.trackers, {ppot_id:this.state.showTracker})?.suppliers.length - 1 > idx && `1px solid ${colors.disabled}`}}>
                                                        <ListItemText 
                                                            primary={<Grid container>
                                                                        <Grid item ><em>{supp.supp_company_name}</em></Grid>
                                                                        {_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.ppot_status != 'Completed' && <Grid item style={{marginLeft: 'auto'}} ><AllIcon icon={icons.bin} noPadding noMargin onClick={()=>this.handleSupplier({value:supp.supp_id})} /></Grid> }
                                                                    </Grid>} 
                                                            secondary={
                                                                <>
                                                                    {supp.supp_telephone} <br/>
                                                                    {supp.supp_email}
                                                                </>
                                                            }
                                                        />
                                                        <Divider/>
                                                    </ListItem>
                                                )}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant='h5'>Notes</Typography> 
                                    {_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.ppot_status != 'Completed' &&
                                        <>
                                            <Textarea
                                                value={this.state.note ? note : ''}
                                                onChange={e => this.setState({note: e.target.value})}
                                                label="Notes"
                                                margin='none'
                                                rows={4}
                                            />
                                            <div className='buttonRow'>
                                                <Button variant='contained' color='primary' onClick={()=>this.addNote(this.state.showTracker)}>Add Note</Button>
                                            </div>
                                        </>
                                    }
                                    <div style={{height: _.find(this.state.trackers, {ppot_id:this.state.showTracker})?.ppot_status != 'Completed' ? 'calc(100vh - 425px)' : 'calc(100vh - 250px)', overflowY:'scroll', marginTop: '1.5em'}}>
                                        <List style={{padding: 0, paddingRight: 25}}>
                                            {_.map(_.orderBy(_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.notes, ['ppotn_datetime'], 'desc'), note => 
                                                <ListItem style={{padding: 0, borderBottom: `1px solid ${colors.disabled}`}}>
                                                    <ListItemText 
                                                        primary={<em>{note.ppotn_tracker}</em>} 
                                                        secondary={
                                                            <>
                                                                <em>{clenyDate(note.ppotn_datetime, true)}</em><br/>
                                                                {note.ppotn_note.split('\n').map((item, key) => {
                                                                return <span key={key}>{item}<br/></span>
                                                                })} 
                                                            </>
                                                        }
                                                    />
                                                    <Divider/>
                                                </ListItem>
                                            )}
                                        </List>
                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{borderTop: `1px solid ${colors.disabled}`}}>
                            <Grid style={{width: 200}}>
                                {_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.ppot_status != 'Completed' && 
                                    <AutoCompleteSelect
                                        label='Reminder'
                                        noClear
                                        options={[
                                            { value: 'daily',    label: 'Daily' },
                                            { value: 'weekly',   label: 'Weekly' },
                                            { value: 'biweekly', label: 'Bi-Weekly' },
                                            { value: 'monthly',  label: 'Monthly' },
                                            { value: 'none',     label: 'No Reminder' },
                                        ]}
                                        value={_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.ppot_notification_frequency}
                                        onChange={this.handleReminder}
                                        fullWidth
                                    />
                                }
                            </Grid>
                            {_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.ppot_status != 'Completed' && (_.find(this.state.trackers, {ppot_id:this.state.showTracker})?.ppot_status == 'Created' ?
                                <Button onClick={()=>this.handleChangeStatus(this.state.showTracker, 'In Progress')} variant="contained" style={{background: colors.orange, color: colors.white}}>Mark As In Progress</Button> :
                                <Button onClick={()=>this.handleChangeStatus(this.state.showTracker, 'Completed')} variant="contained" style={{background: colors.green, color: colors.white}}>Mark As Complete</Button> )
                            }
                            <Button onClick={this.handleClose} variant="outlined" color="default">Close</Button>
                        </DialogActions>
                    </Dialog>
                }
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployDialog:           (content, title)          => { dispatch(deployDialog(content, title))},
    deployConfirmation:     (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    closeDialog:            ()                        => {dispatch(closeDialog())}
})


export default connect(null,mapDispatchToProps)(PartsOrderingTracker);
