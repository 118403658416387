import React, { Component } from 'react';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import DataTable            from 'Components/Common/DataTables/CiDataTable';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

const initialState = () => ({
    isLoading: true,
    package: {},
    currentTab: 'Package',
});

class PackageTab extends Component {
    constructor(props) {
        super(props);    
        this.state = initialState();
    }

    render(){
        
        const { salesPackage } = this.props;

        return(
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DataTable
                        config={{
                            key:        'pd_id',
                        }}
                        columns={_.filter([
                            {
                                heading: '',
                                field: 'part.default_image.file_path',
                                fieldFormat: 'image',
                                sizeToContent: true,
                            },
                            {
                                heading: 'Part Number',
                                field: 'part.part_number',
                                sizeToContent: true,
                            },
                            {
                                heading: 'Part Description',
                                field: i => <>
                                    {i.part.part_description}<br/>
                                    <span style={{color: '#757575', fontSize: '12px'}}>{i.pd_warranty_name}</span>
                                    {i.pd_notes && <><br/><span style={{fontSize: '0.7rem', whiteSpace:'pre-wrap'}}>{i.pd_notes}</span></>}
                                </>,
                            }, 
                            {
                                heading: 'Quantity',
                                field: 'pd_qty',
                                sizeToContent: true,
                                fieldFormat: 'decimal:2',
                                alignment: 'right',
                            },
                            {
                                heading: 'Unit Price',
                                field: 'pd_unit_price',
                                sizeToContent: true,
                                fieldFormat: 'currency',
                                alignment: 'right',
                                showTotalLabel: salesPackage.p_discounted ? '' : 'Total',
                            },
                            ...(salesPackage.p_discounted ? [
                                {
                                    heading: 'Discount Rate',
                                    field: 'pd_discount',
                                    fieldSuffix: '%',
                                    sizeToContent: true,
                                    fieldFormat: 'decimal:2',
                                    alignment: 'right',
                                },
                                {
                                    heading: 'New Unit Price',
                                    field: 'pd_new_unit_price',
                                    sizeToContent: true,
                                    fieldFormat: 'currency',
                                    alignment: 'right',
                                    showTotalLabel: 'Total',
                                },
                                {
                                    heading: 'Discount Total',
                                    field: 'pd_discount_total',
                                    sizeToContent: true,
                                    fieldFormat: 'currency',
                                    alignment: 'right',
                                    showTotal:   true,
                                },
                            ] : []),
                            {
                                heading: 'Total Price',
                                field: 'pd_total',
                                sizeToContent: true,
                                fieldFormat: 'currency',
                                alignment: 'right',
                                showTotal:   true,
                            }
                        ], i => i)}
                        rows={salesPackage.details}
                    />
                    <DataTable
                        config={{
                            noHeader: true,
                        }}
                        columns={[
                            {
                                field: 'name',
                                alignment: 'right',
                            },
                            {
                                field: 'value',
                                fieldFormat: 'currency',
                                alignment: 'right',
                                sizeToContent: true,
                                style: {fontWeight: 'bold'},
                            }
                        ]}
                        rows={[
                            { name: 'Subtotal', value: salesPackage.p_subtotal },
                            { name: `VAT (${parseFloat(parseFloat(parseFloat(salesPackage.p_vat) / parseFloat(salesPackage.p_subtotal)) * 100).toFixed(2)}%)`, value: salesPackage.p_vat },
                            { name: 'Total', value: salesPackage.p_total },
                        ]}
                    />
                </Grid>
            </Grid>
        );
    }
}

const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    btnRow: {
        textAlign: 'center', 
    },
    btnRowBtn: {   
        width: '100%',
    },
    link: {
        textDecoration: 'none',
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        },
        stepper: {
            display: 'none',
        },
    },
    statusHeading: {
        display: 'flex',
        alignItems: 'center'
    },
    label: {
        color: 'black'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tabScrollButtons: {
        color: 'black'
    }
});

const mapDispatchToProps = dispatch => ({
    deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:        ()                              => dispatch(closeDialog()),
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(PackageTab));