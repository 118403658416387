import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import API from '../../../../API';
import DataTable from '../../../Common/DataTables/DataTable';
import { downloadS3File } from 'Functions/MiscFunctions';
import {colors} from 'Helpers/ColourHelper';

class PDFDrawings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            open: false
        }
    }

    componentDidMount(){
        // this.getPartFiles();
    }

    getPartFiles = () => {
        API.get(`/parts/${this.props.partId}/files`, {
            params: {
              type: 'PDF Drawing'
            }
          })
        .then((res) =>  {
            this.setState({
                rows: res.data,
                open: true
            });
        })
    }

    handleOpen = () => {
        if(!this.state.open){
            this.getPartFiles();
        } else {
            this.setState({
                open: false
            });
        }
    }

    render() {
        const { open } = this.state;    
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={this.handleOpen}>
                    <Typography>PDF Drawings</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {open && <>
                        {this.state.rows.length > 0 ? 
                            <DataTable 
                                config={{
                                    key: 'part_doc_id',
                                    pagination: false,
                                    plainHeader: true,
                                    rowProps: row => {
                                        return ({
                                            style: {
                                                color: row.default === 'Yes' && colors.green
                                            }
                                        });
                                    }}
                                }
                                columns={[
                                    {
                                        heading: 'Version',
                                        field: row => 'v'+row.part_doc_version
                                    },
                                    {
                                        heading: 'File',
                                        field: row => row.part_doc_url
                                    },
                                    {
                                        actions: row => {
                                            return [
                                                {name: 'Download', icon: 'download', onClick: () => downloadS3File(row.download_url)},
                                            ]
                                        }
                                    }
                                ]}
                                rows={this.state.rows}
                            />
                        :
                            <Typography>There are 0 PDF Drawings found.</Typography>
                        }
                    </>}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default PDFDrawings;
