import React, { Component } from 'react';
import _                    from 'lodash';
import moment               from 'moment';
import { connect }          from 'react-redux';
import uuidv4               from 'uuid/v4';
import API                  from 'API';

import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import MaterialLink from '@material-ui/core/Link';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { getZonesFromPostcode }   from 'Helpers/AddressHelper';
import icons                      from 'Helpers/IconHelper';

import BackButton         from 'Components/Common/Buttons/BackButton';
import CopyToClipboard    from 'Components/Common/CopyToClipboard/CopyToClipboard';
import AddressLookup      from 'Components/Common/AddressLookup/AddressLookup';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar           from 'Components/Common/SnackBars/SnackBar';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import Textarea           from 'Components/Common/Inputs/Textarea';

import AccessTimes from '../AccessTimes/AccessTimes';
import OpeningTimes from '../OpeningTimes/OpeningTimes';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import {specialCharacterWhiteList} from 'Constants';

const initialState = {
    formId: uuidv4(), 
    formData: {
        customer:               '',
        addressType:            '',
        name:                   '',
        addressOne:             '',
        addressTwo:             '',
        addressThree:           '',
        town:                   '',
        county:                 '',
        country:                243,
        postcode:               '',
        phone:                  '',
        email:                  '',
        showAddress:            false,
        showAddressLookup:      true,
        associatedContacts:     [],
        useAddress:             'Custom',
        creditLimitNet:         0, 
        creditLimitGross:       0,
        accessTimes: [{
            day:        '',
            startTime:  '00:00',
            endTime:    '00:00'
        }],
        accessTimeNotes:    '',
        addAccessTimes:     false,
        openingTimes: [{
            day:        '',
            startTime:  '00:00',
            endTime:    '00:00'
        }],
        openingTimeNotes:   '',
        addOpeningTimes:    false,
        linkedAccount:      0,
        zones:              [],
    },
    customerList:           [],
    customers:              {},
    customerContacts:       [],
    contacts:               {},
    formErrors:             [],
    countryList:            [],
    confirmationOpen:       false,
    snackbarOpen:           false,
    isLoading:              false,
    disableTradingName:     false,
    prepopulatedAddresses:  [],
    vatList:                [],
    accountsAddresses:      [],
    couriers:               [],
    contactsSearchFilter:   '',
}

class AddCustomerAddress extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount(){
        this.getCustomers();
        this.getCountries();
        this.getVat();
        this.getCouriers()
    }

    
    getCouriers(){
        API.get('/couriers').then(res => {
            this.setState({ couriers: res.data.couriers });
        })
    }

    getLogoSrc = files => {
        let logo = _.find(files, {'cour_file_type': 'Logo'});
        return logo ? logo.file_url : '';
    }

    getCustomers = () => {
        this.setState({
            isLoading: true
        },
        () => {
            let customerList;
            API.get(`/customers`)
            .then((result) => {
                if(result.data){

                    customerList = _.map(result.data, el => {
                        el.registeredPostcode = _.result(_.find(el?.addresses, {'address_type': 'Registered'}), 'address_postcode');

                        return _.assign({
                            value: el.cust_id,
                            label: el.cust_name + ' ('+el?.registeredPostcode+')',
                        });
                    });
                }
                this.setState({
                    customerList: customerList,
                    customers: result.data,
                    isLoading: false,
                }, () => {
                    if (this.props.match.params.cid) this.preFillCustomer()
                });
            });
        });
    };

    preFillCustomer = () => {
        let cust = _.find(this.state.customers, i => i.cust_id == this.props.match.params.cid);
        if (cust){
            this.setState({
                customerContacts:   cust.active_contacts,
                disableTradingName: cust && cust.cust_trading_name ? false : true,
                formData: {
                    ...this.state.formData,
                    customer:           cust.cust_id,
                    associatedContacts: [],
                    useAddress:         'Custom',
                }
            }, this.getAccountsAddresses);
        }
    }
    
    getAccountsAddresses = () => {
        let cust                = _.find(this.state.customers, i => i.cust_id == this.state.formData.customer);
        const accountsAddresses = _.filter(cust.addresses, {address_type:'Accounts'} );
        const formData          = {...this.state.formData, linkedAccount: (accountsAddresses.length === 1) ? accountsAddresses[0].address_id : 0}
        this.setState({accountsAddresses,formData})
    }

    getVat = () => {
        API.get('/accounts/vatRates/byType')
        .then( res => {this.setState({vatList: res.data})})
    }

    getCountries = () => {
        let countryList = [];
        API.get('/misc/countries/all')
        .then((countryRes) =>  {
            if(countryRes.data){
                countryList = _.map(countryRes.data, el => {
                    return _.assign({
                        value: el.country_id,
                        label: el.country_name,
                    });
                });
            }
            this.setState({
                countryList: countryList
            });
        });
    }
    handleDayChange = field => (addressIdx, idx) => day => {
        let newDay = [...this.state.formData[field]];
        newDay[idx] = {
            ...newDay[idx],
            day: day && day.value
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: newDay
            }
        });
    }
    handleTimeChange = field => (fieldName, addressIdx, idx) => time => {
        let newTime = [...this.state.formData[field]];
        newTime[idx] = {
            ...newTime[idx],
            [fieldName]: time &&  moment(time).format('HH:mm')
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: newTime
            }
        });
    }
    handleAddAccessTimeRow = field => () => {
        let item = initialState.formData[field][0];
        this.setState({
            formData:{
                ...this.state.formData,
                [field]: [...this.state.formData[field], item]
            }
        });
    }
    handleRemoveRow = field => (addressIdx, idx) => () => {
        const accessTimes = [...this.state.formData[field]]
        accessTimes.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                [field]: accessTimes
            }
        })
    }
    handleChange = fieldname => (e) => {
        const { name, value } = e.target;
        if (e.target.name === 'creditLimitNet') {         
            let cust = _.find(this.state.customers, {'cust_id': this.state.formData.customer});  
            let vat =  _.find(_.find(this.state.vatList, {vat_type_id:cust.cust_vat_type_id}).vat_values, {'current': true});
            this.setState({
                formData: {
                    ...this.state.formData,
                    creditLimitNet:     e.target.value,
                    creditLimitGross:   parseFloat( parseInt(e.target.value) + ( e.target.value * (parseFloat(vat.vat_value_percentage) / 100) ) ).toFixed(0)
                }
            });
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: value
                }
            },
            () => {
                let cust = _.find(this.state.customers, {'cust_id': this.state.formData.customer});
                if(fieldname === 'useAddress' && cust){

                    let filteredAddresses = cust.addresses ? _.filter(cust.addresses, { 'address_type': value }) : [];
                    let addresses = _.map(filteredAddresses, el => {
                        return _.assign({
                            value: el,
                            label: el.address_line_one + ', ' + el.address_town + ', ' + el.address_postcode,
                        });
                    });

                    this.setState({
                        prepopulatedAddresses: addresses
                    });
                }
                this.checkForErrors();
            });
        }
    }
    handleSelectedAddress = e => {
        if(e && e.value){
            this.setState({
                formData: {
                    ...this.state.formData,
                    addressOne: e.value.line1,
                    addressTwo:  e.value.line2,
                    addressThree:  e.value.line3,
                    town:  e.value.town,
                    county: e.value.county,
                    postcode: e.value.postcode,
                    showAddress: true,
                    selectedAddress: e,
                }
            });
        }
        else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    selectedAddress: null
                }
            });
        }
    }
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if(fieldName === 'customer'){
                let cust = _.find(this.state.customers, {'cust_id': selectedOption && selectedOption.value});
                this.setState({
                    customerContacts: selectedOption ? cust.active_contacts : [],
                    disableTradingName: cust && cust.cust_trading_name ? false : true,
                    formData: {
                        ...this.state.formData,
                        associatedContacts: [],
                        useAddress: 'Custom',
                    }
                }, this.getAccountsAddresses);
            }
            if(fieldName == 'country'){
                this.setState({
                    formData: {
                        ...this.state.formData,
                        county: ''
                    }
                })
            }
        });
    }
    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    }
    handleDuplicateCheck = () => {
        const cust = _.find(this.state.customers, i => i.cust_id == this.state.formData.customer);
        const addr = _.filter(cust.addresses, i => {
            let ret = 0;
            if (this.state.formData.name.toLowerCase()      === i.address_name.toLowerCase())     ret = 1;
            if (this.state.formData.postcode.toLowerCase()  === i.address_postcode.toLowerCase()) ret = 1;
            return ret;
        })
        if (addr.length){
            this.props.deployDialog(
                <>
                    <Typography>The entered address is similar to the address's below</Typography>
                    <br></br>
                    <Grid container xs={12} spacing={3}>
                        {_.map(addr , a => 
                            <Grid item xs={6}>
                                <PaddedPaper>
                                    <Typography variant="h6" >{a.address_name}</Typography>
                                    <Typography variant="body2">{a.address_type} Address</Typography>
                                    <Typography variant="body2">
                                        {a.address_line_one   ? <Typography variant="body2">{`${a.address_line_one}`}</Typography>   : ''}
                                        {a.address_line_two   ? <Typography variant="body2">{`${a.address_line_two}`}</Typography>   : ''}
                                        {a.address_line_three ? <Typography variant="body2">{`${a.address_line_three}`}</Typography> : ''}
                                        {a.address_town       ? <Typography variant="body2">{`${a.address_town}`}</Typography>       : ''}
                                        {a.address_postcode   ? <Typography variant="body2">{`${a.address_postcode}`}</Typography>   : ''}
                                        {a.county_name        ? <Typography variant="body2">{`${a.county_name}`}</Typography>        : ''}
                                    </Typography>
                                </PaddedPaper>
                            </Grid>    
                        )}
                    </Grid>
                    
                    
                    <div className='buttonRow'>
                        <Button onClick={this.props.closeDialog} variant="outlined" color="default">Cancel</Button>
                        <Button onClick={()=>{this.props.closeDialog();this.submit()}}
                                variant="contained"
                                color="primary">
                            Add Anyway
                        </Button>
                    </div>
                </>,
                'Site Already Exists'
            )
        } else {
            this.handleConfirmationOpen(null)
        }
    }
    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    }
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    }
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }
    handleAddAccessTimes = field => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: JSON.parse(e.target.value)
            }
        });
    }
    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            API.post(`/customers/addresses`, this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false,
                        formData: {
                            ...this.state.formData,
                            showAddress: true,
                            showAddressLookup: false,
                        }
                    });
                }
                else {
                    this.setState({
                        ...initialState,                    
                        formId: (initialState.formId + 1),
                        snackbarOpen: true
                    });
                    this.getCustomers();
                    this.getCountries();
                    this.getVat();
                    this.getCouriers();
                }
                this.props.scrollToTop();
            });
        });
    }
    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.state.customerContacts.map(n => n.contact_id);
            this.setState({
                formData:{
                    ...this.state.formData,
                    associatedContacts: newSelecteds
                }
            });
        } 
        else {
            this.setState({
                formData:{
                    ...this.state.formData,
                    associatedContacts: []
                }
            });
        }
    }
    handleClick(e, name) {
        if ( e.target.nodeName == 'TD' || e.target.nodeName == 'INPUT') {
            const selectedContacts = this.state.formData.associatedContacts;

            const selectedIndex = selectedContacts.indexOf(name);
            let newSelected = [];
        
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedContacts, name);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedContacts.slice(1));
            } else if (selectedIndex === selectedContacts.length - 1) {
                newSelected = newSelected.concat(selectedContacts.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedContacts.slice(0, selectedIndex),
                    selectedContacts.slice(selectedIndex + 1),
                );
            }
            this.setState({
                formData:{
                    ...this.state.formData,
                    associatedContacts: newSelected
                }
            });
        }
    }

    handleManuallyEnterAddress = e => {
        e.preventDefault();
        
        this.setState({
            formData: {
                ...this.state.formData,
                showAddress:        this.state.formData.showAddress && this.state.formData.showAddressLookup ? true : !this.state.formData.showAddress,
                showAddressLookup:  this.state.formData.showAddress && this.state.formData.showAddressLookup ? false : !this.state.formData.showAddressLookup,
                addressOne:         this.state.formData.showAddress ? '' : this.state.formData.addressOne,
                addressTwo:         this.state.formData.showAddress ? '' : this.state.formData.addressTwo,
                addressThree:       this.state.formData.showAddress ? '' : this.state.formData.addressThree,
                town:               this.state.formData.showAddress ? '' : this.state.formData.town,
                county:             this.state.formData.showAddress ? '' : this.state.formData.county,
                postcode:           this.state.formData.showAddress ? '' : this.state.formData.postcode
            }
        });
    }

    usePrepopulatedAddress = e => {
        if(e && e.value){
            let val = e.value;
            this.setState({
                formData: {
                    ...this.state.formData,
                    addressOne: val.address_line_one,
                    addressTwo:  val.address_line_two,
                    addressThree: val.address_line_three,
                    town: val.address_town,
                    county: val.county ? val.county.county_name : '',
                    country: val.address_country_id,
                    postcode: val.address_postcode,
                    phone: val.address_phone,
                    email: val.address_email,
                    showAddress: true,
                    showAddressLookup: false
                }
            });
        }
    }

    handleCourierSelect = id => e => {
        let value = e ? e.value : null;
        let address = this.state.formData;
        let zidx  = _.findIndex(address.zones, {courier: id});
        address.zones[zidx].zone = value;
        this.setState({
            formData: address
        });
    }

    handleCourierCheck = id => {

        let addresses = _.cloneDeep(this.state.formData);
        let data = addresses.zones;

        let iidx  = _.findIndex(data, {courier: id});
        if (iidx >= 0) {
            data.splice(iidx, 1);
        } else {
            data.push({courier: id, zone: 0});
        }

        this.setState({
            formData: {
                ...this.state.formData,
                zones: data
            }
        });
    }

    checkZones = async () => {
        let zones = await getZonesFromPostcode( this.state.formData.postcode );

        if (this.state.formData.country !== 243) return;
        this.setState({
            formData: {
                ...this.state.formData,
                zones: _.uniqBy([...zones, ...this.state.formData.zones], i => i.courier)
            }
        });
    }

    doesStringContainNonAlphanumeric = (textString) => {
        const regex = specialCharacterWhiteList;
        return regex.test(textString);
    };

    checkForErrors = () => {
        let { formData, formErrors, specialCharacterError } = this.state;
        formErrors['name'] = (this.doesStringContainNonAlphanumeric(formData.name)) ? 'Address name contains invalid characters' : null;
        specialCharacterError = (formErrors['name']) ? true : false;
        this.setState({formErrors, specialCharacterError})
    }

    render() {
        const { formData, formErrors, customerContacts, isLoading, prepopulatedAddresses, accountsAddresses } = this.state;
        const isSelected = name => this.state.formData.associatedContacts.indexOf(name) !== -1;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Address
                    </Typography>
                </Grid>
                {(isLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                )) || (
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    {formErrors && formErrors.generic && (
                                        <React.Fragment>
                                            <Typography component={"div"} style={{color: colors.red}}>
                                                {formErrors.generic}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                    <form noValidate autoComplete="off">
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect
                                                noClear
                                                options={this.state.customerList} 
                                                label='Customer *'
                                                onChange={this.handleSelectChange('customer')}
                                                error={formErrors && formErrors['customer'] && true}
                                                errorText={formErrors && formErrors['customer']}
                                                value={formData.customer}
                                            />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <AutoCompleteSelect
                                                noClear
                                                options={[
                                                {value: 'Trading', label: 'Trading'},
                                                {value: 'Accounts', label: 'Accounts'},
                                                {value: 'Site', label: 'Site'}
                                                ]} 
                                                label='Address Type *'
                                                value={formData.addressType}
                                                onChange={this.handleSelectChange('addressType')}
                                                error={formErrors && formErrors['addressType'] && true}
                                                errorText={formErrors && formErrors['addressType']}
                                            />
                                        </FormControl>
                                    </form>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>                      
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <Typography variant="h6">
                                        Address Details
                                    </Typography>
                                    <form noValidate autoComplete="off">
                                        <RadioGroup
                                            name="useAddress"
                                            value={formData.useAddress}
                                            onChange={this.handleChange('useAddress')}
                                            row
                                            margin="normal"
                                        >
                                            <FormControlLabel
                                                value="Custom"
                                                control={<Radio color="primary" />}
                                                label="Custom Address"
                                                labelPlacement="end"
                                            />
                                            {formData.addressType &&
                                                <React.Fragment>
                                                    {formData.addressType !== 'Trading' &&
                                                        <FormControlLabel
                                                            value="Trading"
                                                            control={<Radio color="primary" />}
                                                            label="Use Trading Address"
                                                            labelPlacement="end"
                                                        />
                                                    }
                                                    {formData.addressType !== 'Accounts' &&
                                                        <FormControlLabel
                                                            value="Accounts"
                                                            control={<Radio color="primary" />}
                                                            label="Use Accounts Address"
                                                            labelPlacement="end"
                                                            disabled={this.state.disableTradingName}
                                                        />
                                                    }
                                                    {formData.addressType !== 'Site' &&
                                                        <FormControlLabel
                                                            value="Site"
                                                            control={<Radio color="primary" />}
                                                            label="Use Site Address"
                                                            labelPlacement="end"
                                                            disabled={this.state.disableTradingName}
                                                        />
                                                    }
                                                </React.Fragment>
                                            }
                                        </RadioGroup>


                                        {formData.useAddress && formData.useAddress !== 'Custom' &&
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteSelect 
                                                    options={prepopulatedAddresses} 
                                                    label={`Use ${formData.useAddress} Address`}
                                                    onChange={this.usePrepopulatedAddress}
                                                />
                                            </FormControl>
                                        }
                                        <TextField
                                            name="name"
                                            label="Name *"
                                            value={formData.name}
                                            error={formErrors && formErrors['name'] && true}
                                            helperText={formErrors && formErrors['name']}
                                            onChange={this.handleChange('name')}
                                            fullWidth
                                            style={{marginBottom: 0}}
                                            margin="normal"
                                        />
                                        
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                name="country"
                                                options={this.state.countryList} 
                                                label='Country'
                                                onChange={this.handleSelectChange('country')}
                                                value={formData.country}
                                            />
                                        </FormControl>

                                        {formData.country === 243 && this.state.formData.showAddressLookup && // United Kingdom
                                            <AddressLookup                                    
                                                key={this.state.formId}
                                                handleSelectedAddress={this.handleSelectedAddress}
                                                inlineLayout={false}
                                                value={formData.selectedAddress}
                                            />
                                        }
                                        <MaterialLink component="button" variant="caption" className='blueLink' onClick={this.handleManuallyEnterAddress}>
                                            {this.state.formData.showAddressLookup ? 'Manually enter address': 'Use address lookup (UK Only)' }
                                        </MaterialLink>

                                        {(formData.country !== 243 || (formData.country === 243 && formData.showAddress) || formData.showAddress)  &&
                                            <React.Fragment>
                                                <TextField
                                                    name="addressOne"
                                                    label="Address Line 1 *"
                                                    value={formData.addressOne}
                                                    error={formErrors && formErrors['addressOne'] && true}
                                                    helperText={formErrors && formErrors['addressOne']}
                                                    onChange={this.handleChange('addressOne')}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                                <TextField
                                                    name="addressTwo"
                                                    label="Address Line 2"
                                                    value={formData.addressTwo}
                                                    onChange={this.handleChange('addressTwo')}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                                <TextField
                                                    name="addressThree"
                                                    label="Address Line 3"
                                                    value={formData.addressThree}
                                                    onChange={this.handleChange('addressThree')}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            name="town"
                                                            label="City / Town *"
                                                            value={formData.town}
                                                            onChange={this.handleChange('town')}
                                                            error={formErrors && formErrors['town'] && true}
                                                            helperText={formErrors && formErrors['town']}
                                                            margin="normal"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            name="postcode"
                                                            label="Postcode"
                                                            value={formData.postcode}
                                                            onChange={this.handleChange('postcode')}
                                                            margin="normal"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {formData.country === 243 &&
                                                    <FormControl fullWidth margin="normal">
                                                        <TextField
                                                            name="county"
                                                            label="County"
                                                            value={formData.county}
                                                            onChange={this.handleChange('county')}
                                                            margin="normal"
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                }
                                            </React.Fragment>
                                        }
                                        {formData.addressType === 'Site' &&
                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteSelect 
                                                    options={_.map(accountsAddresses, i=>{return _.assign({value: i.address_id, label: `${i.address_name} | ${i.address_postcode}`})})} 
                                                    label='Accounts Address *'
                                                    onChange={this.handleSelectChange('linkedAccount')}
                                                    error={formErrors && formErrors['linkedAccount'] && true}
                                                    errorText={formErrors && formErrors['linkedAccount']}
                                                    value={formData.linkedAccount}
                                                    disabled={accountsAddresses.length === 1}
                                                />
                                            </FormControl>
                                        } 
                                        {formData.addressType === 'Accounts' && 
                                            <Grid container spacing={3} xs={12} style={{marginTop:'1em'}}>
                                                <Grid item xs={12} lg={6}>
                                                    <TextField
                                                        name="creditLimitNet"
                                                        label="Credit Limit Net *"
                                                        value={this.state.formData.creditLimitNet}
                                                        error={formErrors && formErrors['CreditLimitNet'] && true}
                                                        helperText={formErrors && formErrors['CreditLimitNet']}
                                                        onChange={this.handleChange('')}
                                                        margin="none"
                                                        fullWidth
                                                        type='number'
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        InputProps={{
                                                            inputProps: { min: 0 },
                                                            startAdornment: (
                                                            <InputAdornment  position="start">
                                                                £
                                                            </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <TextField
                                                        name="creditLimitGross"
                                                        label="Credit Limit Gross"
                                                        value={this.state.formData.creditLimitGross || 0}
                                                        margin="none"
                                                        fullWidth
                                                        type='number'
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        InputProps={{
                                                            inputProps: { min: 0 },
                                                            startAdornment: (
                                                            <InputAdornment  position="start">
                                                                £
                                                            </InputAdornment>
                                                            )
                                                        }}
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        <TextField
                                            name="phone"
                                            label="Phone"
                                            value={formData.phone}
                                            onChange={this.handleChange('phone')}
                                            margin="normal"
                                            fullWidth
                                        />
                                        <TextField
                                            name="email"
                                            label="Email"
                                            value={formData.email}
                                            onChange={this.handleChange('email')}
                                            margin="normal"
                                            fullWidth
                                        />
                                        {/* Add access times */}
                                        {formData.addressType === "Site" && (
                                            <>
                                                <AccessTimes
                                                    handleDayChange={this.handleDayChange('accessTimes')}
                                                    handleTimeChange={this.handleTimeChange('accessTimes')}
                                                    handleAddAccessTimes={this.handleAddAccessTimes('addAccessTimes')}
                                                    handleAddAccessTimeRow={this.handleAddAccessTimeRow('accessTimes')}
                                                    handleRemoveRow={this.handleRemoveRow('accessTimes')}
                                                    addAccessTimes={formData.addAccessTimes}
                                                    formData={formData} 
                                                    formErrors={formErrors}
                                                    addressIdx={0}
                                                    update={false}
                                                />
                                                <Textarea 
                                                    label="Access Time Notes"
                                                    name="accessTimeNotes"
                                                    onChange={this.handleChange('accessTimeNotes')}
                                                />
                                            </>
                                        )}
                                        {/* Add Opening times */}
                                        {formData.addressType === "Site" && (
                                            <>
                                                <OpeningTimes
                                                    handleDayChange={this.handleDayChange('openingTimes')}
                                                    handleTimeChange={this.handleTimeChange('openingTimes')}
                                                    handleAddAccessTimes={this.handleAddAccessTimes('addOpeningTimes')}
                                                    handleAddAccessTimeRow={this.handleAddAccessTimeRow('openingTimes')}
                                                    handleRemoveRow={this.handleRemoveRow('openingTimes')}
                                                    addAccessTimes={formData.addOpeningTimes}
                                                    formData={formData} 
                                                    formErrors={formErrors}
                                                    addressIdx={0}
                                                    update={false}
                                                />
                                                <Textarea 
                                                    label="Opening Time Notes"
                                                    name="openingTimeNotes"
                                                    onChange={this.handleChange('openingTimeNotes')}
                                                />
                                            </>
                                        )}
                                        <Grid container spacing={2} style={{alignItems: 'center'}}>
                                            <Grid item xs={6}>
                                                <Typography variant="h6" gutterBottom>Couriers</Typography> 
                                            </Grid>
                                            <Grid item xs={6} className='buttonRow' >
                                                <Button onClick={this.checkZones}> <AllIcon icon={icons.location }/> Check Postcode </Button>
                                            </Grid>
                                            {_.map(_.filter(this.state.couriers, c => c.zones.length > 0), (i, idx) => {
                                                let cz = _.find(this.state.formData.zones, {courier: i.cour_id})
                                                return (
                                                    <Grid item container xs={12} spacing={1} style={{alignItems: 'center', borderBottom: (idx + 1) !== _.filter(this.props.couriers, c => c.zones.length > 0).length && `1px solid ${colors.disabled}`}}>
                                                        <Grid item>
                                                            <Checkbox 
                                                                checked={!!cz} 
                                                                value={!!cz}
                                                                onChange={()=>this.handleCourierCheck(i.cour_id)}
                                                            />
                                                        </Grid>
                                                        <Grid item style={{paddingTop: '0.5em'}}>
                                                            <img src={this.getLogoSrc(i.file)} style={{maxWidth: 64}} />
                                                        </Grid>    
                                                        <Grid item >{i.cour_name}</Grid>
                                                        <Grid item xs={5} style={{marginLeft: 'auto'}}>
                                                            <AutoCompleteSelect 
                                                                fullWidth
                                                                options={_.map(i.zones, z => ({label: z.cz_name, value: z.cz_id}))}
                                                                onChange={this.handleCourierSelect(i.cour_id)}
                                                                value={ cz ? cz?.zone : null }
                                                                noClear
                                                                disabled={!cz}
                                                            />
                                                        </Grid>
                                                    </Grid>    
                                                )}
                                            )}
                                        </Grid>
                                    </form>
                                    <Grid item xs={12} className='buttonRow' style={{paddingTop: '1em'}}>
                                        <BackButton />
                                        <Button onClick={this.handleDuplicateCheck}
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.formData === initialState.formData || this.state.specialCharacterError}>
                                            Add Without Contacts
                                        </Button>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={8}>
                                <PaddedPaper>
                                    <Grid container spacing={3}>
                                        <Grid item xs={9}>
                                            <Typography variant="h6" gutterBottom>
                                                Associated Contacts
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                placeholder='Search Contacts'
                                                onChange={e=>this.setState({contactsSearchFilter: e.target.value})}
                                                value={this.state.contactsSearchFilter}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: 
                                                        <InputAdornment position="end">
                                                            <AllIcon 
                                                                icon={icons.decline} 
                                                                noMargin
                                                                onClick={()=>this.setState({contactsSearchFilter: ''})}
                                                                size='small'
                                                            />
                                                        </InputAdornment>
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    {customerContacts && customerContacts.length > 0 ?
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            indeterminate={formData.associatedContacts.length > 0 && formData.associatedContacts.length < customerContacts.length}
                                                            checked={formData.associatedContacts.length === customerContacts.length}
                                                            onChange={this.handleSelectAllClick}
                                                            color="primary"
                                                        />
                                                    </TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Email</TableCell>
                                                    <TableCell>Mobile</TableCell>
                                                    <TableCell>Phone</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {customerContacts && customerContacts.filter(i => !this.state.contactsSearchFilter || `${i.contact_email}|${i.contact_name}`.toLowerCase().includes(this.state.contactsSearchFilter)).map((item, idx) => {
                                                    const isItemSelected = isSelected(item.contact_id);
                                                    return (
                                                        <TableRow
                                                            hover
                                                            onClick={event => this.handleClick(event, item.contact_id)}
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={item.contact_id}
                                                            selected={isItemSelected}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    color="primary"
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.contact_name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item?.contact_email || '-'} {item?.contact_email && <CopyToClipboard copy={item?.contact_email}/>}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item?.contact_mobile || '-'} {item?.contact_mobile && <CopyToClipboard copy={item?.contact_mobile}/>}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item?.contact_telephone || '-'} {item?.contact_telephone && <CopyToClipboard copy={item?.contact_telephone}/>}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                        :
                                        <Typography>
                                            No results were found
                                        </Typography>
                                    }
                                    {this.state.specialCharacterError && 
                                        <Grid item xs={12}>
                                            <Typography variant="body1" gutterBottom style={{color: 'red', textAlign: 'right'}}>
                                                Please remove special characters from the marked fields
                                            </Typography>
                                        </Grid>
                                    }
                                    <div className='buttonRow'>
                                        <BackButton />
                                        <Button onClick={this.handleDuplicateCheck}
                                                variant="contained"
                                                color="primary"
                                                disabled={this.state.formData === initialState.formData || this.state.specialCharacterError}>
                                            Add With Contacts
                                        </Button>
                                    </div>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen} 
                            success={this.handleConfirmationSuccess} 
                            close={this.handleConfirmationClose} 
                            title="Add Address?" 
                            message="Are you sure you want to add this address?"
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen}
                            onClose={this.handleSnackbarClose}
                            message='You have successfully added the address'
                        />
                    </React.Fragment>
                )}
            </Grid>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {
        deployDialog:   (content, title)    => {dispatch(deployDialog(content, title, null, 'md'))},
        closeDialog:    ()                  => {dispatch(closeDialog())}
    }
}

export default connect(null, mapDispatchToProps)(AddCustomerAddress);