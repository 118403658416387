import React, {Component}  from 'react';
import toUint8Array from 'base64-to-uint8array';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import API from '../../../API';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import Divider from '@material-ui/core/Divider';
import AutoCompleteMultiSelect from '../../Common/Selects/AutoCompleteMultiSelect';
import { WEB_EMAIL } from './../../../Constants';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import IconButton from '@material-ui/core/IconButton';
import NotificationDialog from '../../Common/Dialogs/NotificationDialog';
import Textarea from '../../Common/Inputs/Textarea';
import Link from '@material-ui/core/Link';
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData: {
        sendType: 'email',
        emailToType: '',
        emailTemplate: '',
        emailBody: '',
        emailCustomer: '',
        emailCustomerAddressType: '',
        emailCustomerAddress: '',
        emailTo: '',
        emailCc: [],
        emailSubject: '',
        part: '',
        attSupplier: '',
        attCustomer: '',
        librayCategory:'',
        docCategory: '',
        docSubCategory: '',
        document: '',
        attachmentType: '',
        attachments: {
            partImages: [],
            partDrawings: [],
            partDesignFiles: [],
            part3DFiles: [],
            partDcos: [],
            partPpos: [],
            partSsrs: [],
            videos: [],
            purchaseOrders: [],
            categoryImages: [],
            documents: [],
            customerQuotations: []
        }
    },
    totalFileSize: 0,
    // Dropdown Values
    partImages: '',
    partDrawings: '',
    partDesignFiles: '',
    part3DFiles: '',
    partDcos: '',
    partPpos: '',
    partSsrs: '',
    documents: '',
    purchaseOrders: '',
    categoryImages: '',
    customerQuotations: '',
    // Arrays
    partImageArray: [],
    partDrawingArray: [],
    partDesignArray: [],
    part3DArray: [],
    partDcoArray: [],
    partPpoArray: [],
    partSsrArray: [],
    catImageArray: [],
    videoArray: [],

    showManualEntry: false,
    showCustomer: false,
    showSupplier: false,

    showPartFiles: false,
    showDocumentFiles: false,
    showPOFiles: false,
    showCategoryImageFiles: false,
    showVideoFiles: false,
    showCustomerFiles:false,

    templateList: [],
    templates: {},
    customers: {},
    allDocuments: {},
    customerList: [],
    supplierList: [],
    customerAddressList: [],
    emailToList: [],
    emailCcList: [],
    customerAddresses: {},
    customerAddressTypeList: [],
    partImageList: [],
    partDrawingList: [],
    partDesignList: [],
    part3DList: [],
    partDcoList: [],
    partPpoList: [],
    partSsrList: [],
    videoList: [],
    poList: [],
    libraryCatList: [],
    catImageList:[],
    docCatList: [],
    documentList: [],
    docSubcatList: [],
    custQuoteList: [],

    suppliers: {},
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    previewOpen: false,
    previewHtml: '',
    isLoading: false
}

class SendFiles extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.populateDropdowns();
    }

    populateDropdowns = () => {
        Promise.all([
            API.get('/email/emailTemplates'),
            API.get('/customers'),
            API.get('/suppliers/all'),
            API.get('/parts/all'),
            API.get('/videos/all'),
            API.get('/library/categories/withImages'),
            API.get('/documents/categories/all'),
            API.get(`/documents/all`)
        ])
        .then(([templateRes, custRes, allSuppRes, partRes, videoRes, imageRes, docCatRes, docRes]) =>  {
            let templateList = [];
            let customerList = [];
            let supplierList = [];
            let partList = [];
            let videoList = [];
            let libraryCatList = [];
            let docCatList = [];

            if(templateRes.data) {
                templateList = _.map(templateRes.data, (el) => {
                    return _.assign({
                        value: el.et_id,
                        label: el.et_name
                    });
                });
                templateList.unshift({
                    value: 0,
                    label: 'None'
                });
            }
            if(custRes.data) {
                customerList = _.map(custRes.data, (el) => {
                    el.registeredPostcode = _.result(_.find(el?.addresses, {'address_type': 'Registered'}), 'address_postcode');
                    return _.assign({
                        value: el.cust_id,
                        label: el.cust_name + ' ('+el?.registeredPostcode+')'
                    });
                });
            }
            if(allSuppRes.data){
                supplierList = _.map(allSuppRes.data, (el) => {
                    return _.assign({
                        value: el.supp_id,
                        label: el.supp_company_name
                    });
                });
            }
            if(partRes.data){
                partList = _.map(partRes.data, el => {
                    return _.assign({
                        value: el.part_id,
                        label: el.part_number + ' - ' + el.part_description
                    });
                });
            }
            if(videoRes.data){
                videoRes.data.forEach((el) => {
                    if(el.latest_file){
                        if(el.latest_file.video_file_mov){
                            videoList.push({
                                value: el.latest_file.video_file_id+'|mov',
                                label: el.latest_file.video_file_mov,
                                link: el.latest_file.video_file_mov_url,
                            });
                        }
                        if(el.latest_file.video_file_mp4){
                            videoList.push({
                                value: el.latest_file.video_file_id+'|mp4',
                                label: el.latest_file.video_file_mp4,
                                link: el.latest_file.video_file_mp4_url,
                            });
                        }
                    }
                });
            }
            if(imageRes.data){
                libraryCatList = _.map(imageRes.data, el => {
                    return _.assign({
                        value: el.library_cat_id,
                        label: el.library_cat_name
                    });
                });
            }
            if(docCatRes.data){
                docCatList = _.map(docCatRes.data, el => {
                    return _.assign({
                        value: el.doc_cat_id,
                        label: el.doc_cat_name
                    });
                });
            }
            this.setState({
                templateList: templateList,
                templates: templateRes.data,
                customerList: customerList,
                customers: custRes.data,
                supplierList: supplierList,
                suppliers: allSuppRes.data,
                partList: partList,
                videoList: videoList,
                videoArray: videoRes.data,
                libraryCatList: libraryCatList,
                docCatList: docCatList,
                documentCategories: docCatRes.data,
                allDocuments: docRes.data
            });
        });
    }

    handleSelectAttachmentChange = fieldName => selectedOption => {        
        if(selectedOption && selectedOption.value){
            this.setState({
                [fieldName]: selectedOption && selectedOption.value,
                formErrors: []
            },
            () => {

                let link = '';
                let dynamicDocument = false;
                let dynamicApiCall = '';
                switch(fieldName){
                    case 'partImages':
                        var partImg = _.find(this.state.partImageArray, {'image_files':{'files': [{'library_id': selectedOption && selectedOption.value}]}});
                        var partImg2 = _.find(partImg.image_files.files, {'library_id': selectedOption && selectedOption.value});
                        link = partImg2.file;
                    break;
                    case 'categoryImages':
                        var catImg = _.find(this.state.catImageArray, {'image_files':{'files': [{'library_id': selectedOption && selectedOption.value}]}});
                        var catImg2 = _.find(catImg.image_files.files, {'library_id': selectedOption && selectedOption.value});
                        link = catImg2.file;
                    break;
                    case 'partDrawings':
                        var partFile = _.find(this.state.partDrawingArray, {'part_doc_id': selectedOption && selectedOption.value});
                        link = partFile.download_url;
                    break;
                    case 'partDesignFiles':
                        var desginFile = _.find(this.state.partDesignArray, {'part_doc_id': selectedOption && selectedOption.value});
                        link = desginFile.download_url;
                    break;
                    case 'part3DFiles':
                        var p3DFile = _.find(this.state.part3DArray, {'part_3D_id': selectedOption && selectedOption.value});
                        link = p3DFile.download_url;
                    break;
                    case 'partDcos':
                        var dcoFile = _.find(this.state.partDcoArray, {'dco_id': selectedOption && selectedOption.value});
                        link = dcoFile.dco_file_url;
                    break;
                    case 'videos':
                        link = selectedOption && selectedOption.link;
                    break;
                    case 'documents': 
                        link = selectedOption && selectedOption.link;
                    break;
                    case 'partPpos':
                        dynamicDocument = true;
                        dynamicApiCall = '/partsOrdering/' + selectedOption.value + '/pdfPartsOrder';
                    break;
                    case 'partSsrs':
                        dynamicDocument = true;
                        dynamicApiCall = '/supplierStockReturns/' + selectedOption.value + '/pdf';
                    break;
                    case 'purchaseOrders':
                        dynamicDocument = true;
                        dynamicApiCall = '/purchaseOrders/' + selectedOption.value + '/pdf';
                    break;
                    case 'customerQuotations':
                        dynamicDocument = true;
                        dynamicApiCall = '/sales/quotations/' + selectedOption.value + '/pdf';
                    break;
                    default:
                    break; 
                }
                let attachmentArray = [...this.state.formData.attachments[fieldName]];
                const index = attachmentArray.findIndex(x => x.id === selectedOption.value);
                if(index === -1){   

                    if(dynamicDocument) {
                        API.get(dynamicApiCall)
                        .then(result => {
                            if(result.data) {
                                const fileSize = new toUint8Array(result.data.pdf);
                                attachmentArray.push({
                                    id: selectedOption.value, 
                                    name: selectedOption.label,
                                    link: link,
                                    size: fileSize.length
                                });                          
                                this.setState({
                                    formData:{
                                        ...this.state.formData,
                                        attachments: {
                                            ...this.state.formData.attachments,
                                            [fieldName]: attachmentArray
                                        }
                                    }
                                });
                            }
                        });  
                    } else {
                        API.get('/getS3FileSize', {
                            params: {
                                request: btoa(link)
                            }
                        })
                        .then(result => {
                            if(result.data.errors && result.data.errors.length > 0){           
                                this.setState({
                                    formErrors: formatValidationErrors(result.data.errors)
                                });
                            }
                            else { 
                                if(result.data) {
                                    attachmentArray.push({
                                        id: selectedOption.value, 
                                        name: selectedOption.label,
                                        link: link,
                                        size: result.data.file_size
                                    });                          
                                    this.setState({
                                        formData:{
                                            ...this.state.formData,
                                            attachments: {
                                                ...this.state.formData.attachments,
                                                [fieldName]: attachmentArray
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                }
            });
        }
        else {
            this.setState({
                [fieldName]: ''
            });
        }
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if(fieldName === 'emailTemplate'){
                let email = _.find(this.state.templates, {'et_id': selectedOption && selectedOption.value });

                this.setState({
                    formData:{
                        ...this.state.formData,
                        emailBody: (typeof email === 'undefined') ? '' : email.et_body
                    }
                });
            }
            if(fieldName === 'emailToType'){
                this.setState({
                    showManualEntry: this.state.formData.emailToType === 'manual' ? true : false,
                    showCustomer: this.state.formData.emailToType === 'customer' ? true : false,
                    showSupplier: this.state.formData.emailToType === 'supplier' ? true : false,
                    formData:{
                        ...this.state.formData,
                        emailTo: ''
                    }
                });
            }
            if(fieldName === 'emailCustomer' && this.state.formData.emailCustomer > 0){
                let cust = _.find(this.state.customers, {'cust_id': selectedOption && selectedOption.value });
                let customerAddressTypeList = _.map(cust.address_types, (el) => {
                    return _.assign({
                        value: el.address_type,
                        label: el.address_type
                    });
                });
                this.setState({
                    customerAddressTypeList: customerAddressTypeList,
                    customerAddresses: cust.addresses,
                    formData:{
                        ...this.state.formData,
                        emailCustomerAddressType: '',
                        emailCustomerAddress: '',
                        emailTo: ''
                    }
                });
            }
            if(fieldName === 'emailCustomerAddressType' && this.state.formData.emailCustomerAddressType){
                let addresses = _.filter(this.state.customerAddresses, { 'address_type': selectedOption && selectedOption.value });

                let customerAddressList = _.map(addresses, (el) => {
                    return _.assign({
                        value: el.address_id,
                        label: `${el.address_name} - ${el.address_line_one}, ${el.address_town}, ${el.address_postcode}`
                    });
                });
                this.setState({
                    customerAddressList: customerAddressList, 
                    formData:{
                        ...this.state.formData,
                        emailCustomerAddress: '',
                        emailTo: ''
                    }
                });
            }
            if(fieldName === 'emailCustomerAddress' && this.state.formData.emailCustomerAddress){
                let address = _.find(this.state.customerAddresses, { 'address_id': selectedOption && selectedOption.value });

                let emailToList = _.map(address.active_contacts, (el) => {
                    return _.assign({
                        value: el.contact_email,
                        label: el.contact_name + ' (' + el.contact_email + ' )'
                    });
                });
                let emailCcList = _.map(address.active_contacts, (el) => {
                    return _.assign({
                        value: el.contact_email,
                        label: el.contact_name + ' (' + el.contact_email + ' )'
                    });
                });
                this.setState({
                    emailToList: emailToList,
                    emailCcList: emailCcList,
                    formData:{
                        ...this.state.formData,
                        emailTo: ''
                    }
                });
            }
            if(fieldName === 'emailSupplierAddress' && this.state.formData.emailSupplierAddress > 0){
                let supplier = _.find(this.state.suppliers, {'supp_id': selectedOption && selectedOption.value });
                let emailToList = _.map(supplier.active_contacts, (el) => {
                    return _.assign({
                        value: el.supp_cont_email,
                        label: el.supp_cont_first_name + ' ' + el.supp_cont_last_name + ' (' + el.supp_cont_email + ' )'
                    });
                });
                let emailCcList = _.map(supplier.active_contacts, (el) => {
                    return _.assign({
                        value: el.supp_cont_email,
                        label: el.supp_cont_first_name + ' ' + el.supp_cont_last_name + ' (' + el.supp_cont_email + ' )'
                    });
                });
                this.setState({
                    emailToList: emailToList,
                    emailCcList: emailCcList,
                    formData:{
                        ...this.state.formData,
                        emailTo: ''
                    }
                });
            }
            if(fieldName === 'attachmentType'){
                this.setState({
                    showPartFiles: this.state.formData.attachmentType === 'part' ? true : false,
                    showDocumentFiles: this.state.formData.attachmentType === 'document' ? true : false,
                    showPOFiles: this.state.formData.attachmentType === 'purchase_order' ? true : false,
                    showCategoryImageFiles: this.state.formData.attachmentType === 'category_image' ? true : false,
                    showVideoFiles: this.state.formData.attachmentType === 'video' ? true : false,
                    showCustomerFiles:  this.state.formData.attachmentType === 'customer' ? true : false,

                    partImages: '',
                    partDrawings: '',
                    partDesignFiles: '',
                    part3DFiles: '',
                    partDcos: '',
                    partPpos: '',
                    partSsrs: '',
                    documents: '',
                    purchaseOrders: '',
                    categoryImages: '',
                    customerQuotations: '',

                    catImageList:[],
                    docSubcatList: [],
                    documentList: [],
                    partDcoList: [],
                    partDesignList: [],
                    partDrawingList: [],
                    partImageList: [],
                    partPpoList: [],
                    part3DList: [],
                    partSsrList: [],
                    poList: [],
                    custQuoteList: [],

                    formData: {
                        ...this.state.formData,
                        librayCategory:'',
                        docCategory: '',
                        docSubCategory: '',
                        part: '',
                        attSupplier: '',
                        attCustomer: ''
                    }
                });
            }
            if(fieldName === 'part'){
                this.setState({
                    partDcoList: [],
                    partDesignList: [],
                    partDrawingList: [],
                    partImageList: [],
                    partPpoList: [],
                    part3DList: [],
                    partSsrList: [],

                    partImages: '',
                    partDrawings: '',
                    partDesignFiles: '',
                    part3DFiles: '',
                    partDcos: '',
                    partPpos: '',
                    partSsrs: ''
                },
                () => {
                    this.populatePartDropdowns();
                });
            }
            if(fieldName === 'attSupplier'){
                this.setState({
                    poList: [],
                    purchaseOrders: ''
                },
                () => {
                    this.populatePODropdown();
                });
            }
            if(fieldName === 'attCustomer'){
                this.setState({
                    custQuoteList: [],
                    customerQuotations: ''
                },
                () => {
                    this.populateCQDropdown();
                });
            }
            if(fieldName === 'librayCategory'){
                this.setState({
                    catImageList: [],
                    categoryImages: ''
                },
                () => {
                    this.populateLibraryDropdown();
                });
            }
            if(fieldName === 'docCategory'){
                this.setState({
                    docSubcatList: [],
                    documentList: [],
                    documents: '',
                    formData: {
                        ...this.state.formData,
                        docSubCategory: ''
                    }
                },
                () => {
                    this.populateDocumentSubCategoryDropdown();
                });
            }
            if(fieldName === 'docSubCategory'){
                this.populateDocumentDropdown();
            }
        });
    }

    populateDocumentDropdown = () => {
        let documentList = [];
        if(this.state.allDocuments){
            let subCategories = _.filter(this.state.allDocuments, {'doc_category_id': this.state.formData.docSubCategory });
            subCategories.forEach((el) => {
                if(el.latest_file){
                    if(el.latest_file.file_original){
                        documentList.push({
                            value: el.latest_file.file_id+'|original',
                            label: el.latest_file.file_original,
                            link: el.latest_file.file_original_url,
                            size: el.latest_file.file_original_size
                        });
                    }
                    if(el.latest_file.file_pdf){
                        documentList.push({
                            value: el.latest_file.file_id+'|pdf',
                            label: el.latest_file.file_pdf,
                            link: el.latest_file.file_pdf_url,
                            size: el.latest_file.file_pdf_size
                        });
                    }
                }
            });
        }
        this.setState({
            documentList: documentList,
            formData: {
                ...this.state.formData,
                document: ''
            },
            documents: ''
        });
    }

    populateDocumentSubCategoryDropdown = () => {
        if(this.state.documentCategories && this.state.formData.docCategory){
            let categories = _.find(this.state.documentCategories, {'doc_cat_id': this.state.formData.docCategory });
            let docSubcatList = [];
            if(categories.children){
                docSubcatList = _.map(categories.children, el => {
                    return _.assign({
                        value: el.doc_cat_id,
                        label: el.doc_cat_name
                    });
                });
            }
            this.setState({
                docSubcatList: docSubcatList,
                formData: {
                    ...this.state.formData,
                    docSubCategory: '',
                    document: ''
                },
                documents: ''
            });
        }
    }

    populateLibraryDropdown = () => {
        if(this.state.formData.librayCategory){
            API.get(`/library/categories/${this.state.formData.librayCategory}`)
            .then(res =>  {
                let catImageList = [];
    
                if(res.data){
                    res.data.forEach((el) => {
                        if(el.image_files.files){
                            el.image_files.files.forEach((x) => {
                                catImageList.push({
                                    value: x.library_id,
                                    label: x.library_url
                                });
                            });
                        }
                    });
                }
                this.setState({
                    catImageList: catImageList,
                    categoryImages: '',
                    catImageArray: res.data
                });
            });
        }
    }

    populatePODropdown = () => {
        if(this.state.formData.attSupplier){
            API.get(`/purchaseOrders/supplier/${this.state.formData.attSupplier}`)
            .then(res =>  {
                let poList = [];
    
                if(res.data){
                    poList = _.map(res.data, el => {
                        return _.assign({
                            value: el.po_id,
                            label: el.po_number + '-' + el.po_number_amendment
                        });
                    });
                }
                this.setState({
                    poList: poList,
                    purchaseOrders: ''
                });
            });
        }
    }

    populateCQDropdown = () => {
        if(this.state.formData.attCustomer){
            API.get(`/customers/${this.state.formData.attCustomer}/sales/quotations`)
            .then(res =>  {
                let custQuoteList = [];
    
                if(res.data){
                    custQuoteList = _.map(res.data, el => {
                        return _.assign({
                            value: el.quote_id,
                            label: el.quote_reference + '-' + el.quote_reference_amendment
                        });
                    });
                }
                this.setState({
                    custQuoteList: custQuoteList,
                    customerQuotations: ''
                });
            });
        }
    }

    populatePartDropdowns = () => {
        if(this.state.formData.part){
            Promise.all([
                API.get(`/library/parts/${this.state.formData.part}`),
                API.get(`/parts/${this.state.formData.part}/files`, {params: {type: 'PDF Drawing'}}),
                API.get(`/parts/${this.state.formData.part}/files`, {params: {type: 'Design File'}}),
                API.get(`/parts/${this.state.formData.part}/files/3D`),
                API.get(`/researchDevelopment/changeOrders/part/${this.state.formData.part}/all`),
                API.get(`/parts/${this.state.formData.part}/partsOrders`),
                API.get(`/parts/${this.state.formData.part}/supplierStockReturns`)
            ])
            .then(([imageRes, drawingRes, designRes, part3Dres, dcoRes, ppoRes, ssrRes]) =>  {
                let partImageList = [];
                let partDrawingList = [];
                let partDesignList = [];
                let part3DList = [];
                let partDcoList = [];
                let partPpoList = [];
                let partSsrList = [];
    
                if(imageRes.data){
                    imageRes.data.forEach((el) => {
                        if(el.image_files.files){
                            el.image_files.files.forEach((x) => {
                                partImageList.push({
                                    value: x.library_id,
                                    label: x.library_url
                                });
                            });
                        }
                    });
                }
                if(drawingRes.data){
                    partDrawingList = _.map(drawingRes.data, el => {
                        return _.assign({
                            value: el.part_doc_id,
                            label: el.part_doc_url
                        });
                    });
                }
                if(designRes.data){
                    partDesignList = _.map(designRes.data, el => {
                        return _.assign({
                            value: el.part_doc_id,
                            label: el.part_doc_url
                        });
                    });
                }
                if(part3Dres.data){
                    part3DList = _.map(part3Dres.data, el => {
                        return _.assign({
                            value: el.part_3D_id,
                            label: el.part_3D_url
                        });
                    });
                }
                if(dcoRes.data){
                    partDcoList = _.map(dcoRes.data, el => {
                        return _.assign({
                            value: el.dco_id,
                            label: el.dco_file_name
                        });
                    });
                }
                if(ppoRes.data){
                    partPpoList = _.map(ppoRes.data, el => {
                        return _.assign({
                            value: el.ppo_id,
                            label: el.ppo_number + '-' + el.ppo_number_amendment
                        });
                    });
                }
                if(ssrRes.data){
                    partSsrList = _.map(ssrRes.data, el => {
                        return _.assign({
                            value: el.sr_id,
                            label: el.sr_reference + '-' + el.sr_reference_amendment
                        });
                    });
                }
                this.setState({
                    partImageList: partImageList,
                    partDrawingList: partDrawingList,
                    partDesignList: partDesignList,
                    part3DList: part3DList,
                    partDcoList: partDcoList,
                    partPpoList: partPpoList,
                    partSsrList: partSsrList,
                    // Arrays
                    partImageArray: imageRes.data,
                    partDrawingArray: drawingRes.data,
                    partDesignArray: designRes.data,
                    part3DArray: part3Dres.data,
                    partDcoArray: dcoRes.data,
                    partPpoArray: ppoRes.data,
                    partSsrArray: ssrRes.data,
                    // Values
                    partImages: '',
                    partDrawings: '',
                    partDesignFiles: '',
                    part3DFiles: '',
                    partDcos: '',
                    partPpos: '',
                    partSsrs: ''
                });
            });
        }
    }

    removeAttachment = (name, val) => () => {
        let rows = [...this.state.formData.attachments[name]]
        const index = rows.findIndex(x => x.id === val);
        rows.splice(index, 1);
        this.setState({
            formData:{
                ...this.state.formData,
                attachments: {
                    ...this.state.formData.attachments,
                    [name]: rows
                }
            }
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleMultiSelectChange = fieldName => selectedOptions => {
        if(selectedOptions){
            let values = selectedOptions.map(a => a.value);
            this.setState({
                formData: {
                    ...this.state.formData,
                    [fieldName]: values
                }
            });
        }
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            API.post(`/email/sendFiles`, this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...initialState,
                        snackbarOpen: true
                    });
                    this.populateDropdowns();
                }
                this.props.scrollToTop();
            });
        });
    }

    previewSubmit = (e) => {
        API.post(`/email/preview`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                let attachmentList = []
                _.map(this.state.formData.attachments, (att) => {
                        _.map(att, (obj) => {
                            attachmentList.push(obj);
                    });
                });
                this.setState({
                    previewOpen: true,
                    previewHtml:  result.data.mail.Body,
                    attachmentList: attachmentList
                });
            }
        });
    };

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    displayAttachments = (primary, secondary, fieldName, value, link ='', size='Unknown') => {
        var onclick = '';
        switch(fieldName){
            case 'partPpos':
                onclick = () => this.downloadPpo(value)
            break;
            case 'partSsrs':
                onclick = () => this.downloadSSr(value)
            break;
            case 'purchaseOrders':
                onclick = () => this.downloadPo(value)
            break;
            case 'customerQuotations':
                onclick = () => this.downloadCq(value)
            break;
            default:
                onclick = () => this.downloadFile(link)
            break;
        }
        return (
            <ListItem key={value}>
                <ListItemAvatar>
                    {(fieldName === 'partImages' || fieldName ===  'categoryImages') && ['.png', '.PNG', '.jpg', '.JPG', '.gif'].some(el => link.includes(el)) ?
                        <Avatar src={link} />
                    :
                        <Avatar><FALightIcon icon='paperclip' noMargin fixedWidth /></Avatar>
                    }
                </ListItemAvatar>
                <ListItemText 
                    primary={<Link component="button" variant="body1" className='blueLink' onClick={onclick}>{primary}</Link> } 
                    secondary={secondary + ' - ' + this.displayFileSize(size)}  
                    style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer'}}
                />
                <ListItemSecondaryAction>
                    <IconButton aria-label="Delete" onClick={this.removeAttachment(fieldName, value)}>
                        <FALightIcon icon='trash-alt' noMargin fixedWidth />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        )
    }

    displayFileSize = initialSize => {
        if(initialSize !== "Unknown") {
            if(initialSize === 0) {
                return "Unknown";
            } else {                
                var i = Math.floor(Math.log(initialSize) / Math.log(1024));
                var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];   
                return (initialSize / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];                 
            }  
        } else {
            return "Unknown";
        }
    }

    displayTotalFileSize = initialSize => {
        if(initialSize !== "Unknown") {
            if(initialSize === 0) {
                return "No attachments have been added yet";
            } else {                
                var i = Math.floor(Math.log(initialSize) / Math.log(1024));
                var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];       
                return  "Total Filesize: " + (initialSize / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];                  
            }  
        } else {
            return "Total Filesize: Unknown";
        }
    }

    handlePreviewClose = () => {
        this.setState({ 
            previewOpen: false 
        });
    };

    downloadFile = (fileUrl) => {
        window.open(fileUrl, '_blank');
    }
    downloadPpo = (id) => {
        API.get('/partsOrdering/' + id + '/pdfPartsOrder')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }
    downloadSSr = (id) => {
        API.get('/supplierStockReturns/' + id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }
    downloadPo = (id) => {
        API.get('/purchaseOrders/' + id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }
    downloadCq = (id) => {
        API.get('/sales/quotations/' + id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }
       
    render() {        
        const { formErrors, formData } = this.state;      
        var sizeArray = [0];
        _.each(formData.attachments, (att) => {
            _.each(att, (file) => {
                sizeArray.push(file.size);
            });
        });
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Send Files
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <RadioGroup
                                name="sendType"
                                value={formData.sendType}
                                onChange={this.handleChange}
                                row
                            >
                                <FormControlLabel
                                    value="email"
                                    control={<Radio color="primary" />}
                                    label="Email"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="transfer"
                                    control={<Radio color="primary" />}
                                    label="File Transfer"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                            <Typography><small>Select 'Email' for attachments under 10MB, anything over select 'File Transfer'</small></Typography>
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteSelect 
                                    options={[
                                        {value: 'manual', label: 'Manual'},
                                        {value: 'customer', label: 'Customer'},
                                        {value: 'supplier', label: 'Supplier'}
                                    ]} 
                                    label='Email To *'
                                    onChange={this.handleSelectChange('emailToType')}
                                    error={formErrors && formErrors['emailToType'] && true}
                                    errorText={formErrors && formErrors['emailToType']}
                                    value={formData.emailToType}
                                />
                            </FormControl>
                            {/* Customer */
                            this.state.showCustomer &&
                                <React.Fragment>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.customerList} 
                                            label='Customer'
                                            value={formData.emailCustomer}
                                            onChange={this.handleSelectChange('emailCustomer')}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.customerAddressTypeList} 
                                            label='Address Type'
                                            value={formData.emailCustomerAddressType}
                                            onChange={this.handleSelectChange('emailCustomerAddressType')}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.customerAddressList} 
                                            label='Address'
                                            value={formData.emailCustomerAddress}
                                            onChange={this.handleSelectChange('emailCustomerAddress')}
                                            error={formErrors && formErrors['emailCustomerAddress'] && true}
                                            errorText={formErrors && formErrors['emailCustomerAddress']}
                                        />
                                    </FormControl>
                                </React.Fragment>
                            }
                            {/* Supplier */
                            this.state.showSupplier &&
                                <React.Fragment>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.supplierList} 
                                            label='Supplier *'
                                            value={formData.emailSupplierAddress}
                                            onChange={this.handleSelectChange('emailSupplierAddress')}
                                            error={formErrors && formErrors['emailSupplierAddress'] && true}
                                            errorText={formErrors && formErrors['emailSupplierAddress']}
                                        />
                                    </FormControl>
                                </React.Fragment>
                            }
                            <Divider style={{marginTop:50}} />

                            <TextField
                                id="emailFrom"
                                name="emailFrom"
                                label="From"
                                value={WEB_EMAIL}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth
                                disabled
                            />

                            {this.state.showManualEntry ?
                                <TextField
                                    id="emailTo"
                                    name="emailTo"
                                    label="To *"
                                    value={formData.emailTo}
                                    error={formErrors && formErrors['emailTo'] && true}
                                    helperText={formErrors && formErrors['emailTo']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                            :
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.emailToList} 
                                        label='To *'
                                        value={formData.emailTo}
                                        onChange={this.handleSelectChange('emailTo')}
                                        error={formErrors && formErrors['emailTo'] && true}
                                        errorText={formErrors && formErrors['emailTo']}
                                    />
                                </FormControl>
                            }

                            <FormControl fullWidth margin="normal">
                                <AutoCompleteMultiSelect
                                    name="emailCc"
                                    options={this.state.emailCcList}
                                    label='Cc'
                                    value={formData.emailCc}
                                    onChange={this.handleMultiSelectChange('emailCc')}
                                    error={formErrors && formErrors['emailCc'] && true}
                                    errorText={formErrors && formErrors['emailCc']}
                                />
                            </FormControl>
                            
                            <TextField
                                id="emailSubject"
                                name="emailSubject"
                                label="Subject *"
                                value={formData.emailSubject}
                                error={formErrors && formErrors['emailSubject'] && true}
                                helperText={formErrors && formErrors['emailSubject']}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth
                            />
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteSelect 
                                    options={this.state.templateList} 
                                    label='Email Template'
                                    value={formData.emailTemplate}
                                    onChange={this.handleSelectChange('emailTemplate')}
                                />
                            </FormControl>
                            <Textarea
                                id="emailBody"
                                name="emailBody"
                                label="Email Body *"
                                value={formData.emailBody}
                                rows={10}
                                error={formErrors && formErrors['emailBody']}
                                onChange={this.handleChange}
                            />
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Select Attachment Files:
                            </Typography>
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteSelect 
                                    options={[
                                        {value: 'part', label: 'Part'},
                                        {value: 'document', label: 'Document'},
                                        {value: 'purchase_order', label: 'Purchase Order'},
                                        {value: 'category_image', label: 'Category Image'},
                                        {value: 'video', label: 'Video'},
                                        {value: 'customer', label: 'Customer'}
                                    ]}
                                    label='Attachment Type'
                                    onChange={this.handleSelectChange('attachmentType')}
                                    error={formErrors && formErrors['attachmentType'] && true}
                                    errorText={formErrors && formErrors['attachmentType']}
                                    value={formData.attachmentType}
                                />
                            </FormControl>
                            {this.state.showPartFiles &&
                                <React.Fragment>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            name='part'
                                            options={this.state.partList} 
                                            label='Part Number'
                                            onChange={this.handleSelectChange('part')}
                                            error={formErrors && formErrors['part'] && true}
                                            errorText={formErrors && formErrors['part']}
                                            value={formData.part}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.partImageList} 
                                            label='Part Images'
                                            value={this.state.partImages}
                                            onChange={this.handleSelectAttachmentChange('partImages')}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.partDrawingList} 
                                            label='PDF Drawings'
                                            value={this.state.partDrawings}
                                            onChange={this.handleSelectAttachmentChange('partDrawings')}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.partDesignList} 
                                            label='Design Files'
                                            value={this.state.partDesignFiles}
                                            onChange={this.handleSelectAttachmentChange('partDesignFiles')}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.part3DList} 
                                            label='3D Files'
                                            value={this.state.part3DFiles}
                                            onChange={this.handleSelectAttachmentChange('part3DFiles')}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.partDcoList} 
                                            label='Design Change Orders'
                                            value={this.state.partDcos}
                                            onChange={this.handleSelectAttachmentChange('partDcos')}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.partPpoList} 
                                            label='Part Orders'
                                            value={this.state.partPpos}
                                            onChange={this.handleSelectAttachmentChange('partPpos')}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.partSsrList} 
                                            label='Supplier Stock Returns'
                                            value={this.state.partSsrs}
                                            onChange={this.handleSelectAttachmentChange('partSsrs')}
                                        />
                                    </FormControl>
                                </React.Fragment>
                            }
                            {this.state.showDocumentFiles &&
                                <React.Fragment>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.docCatList} 
                                            label='Category'
                                            value={formData.docCategory}
                                            onChange={this.handleSelectChange('docCategory')}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.docSubcatList} 
                                            label='Sub Category'
                                            value={formData.docSubCategory}
                                            onChange={this.handleSelectChange('docSubCategory')}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.documentList} 
                                            label='Documents'
                                            value={this.state.documents}
                                            onChange={this.handleSelectAttachmentChange('documents')}
                                        />
                                    </FormControl>
                                </React.Fragment>
                            }
                            {this.state.showPOFiles &&
                                <React.Fragment>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.supplierList} 
                                            label='Suppliers'
                                            value={formData.attSupplier}
                                            onChange={this.handleSelectChange('attSupplier')}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.poList} 
                                            label='Purchase Orders'
                                            value={this.state.purchaseOrders}
                                            onChange={this.handleSelectAttachmentChange('purchaseOrders')}
                                        />
                                    </FormControl>
                                </React.Fragment>
                            }
                            {this.state.showCategoryImageFiles &&
                                <React.Fragment>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.libraryCatList} 
                                            label='Categories'
                                            value={formData.librayCategory}
                                            onChange={this.handleSelectChange('librayCategory')}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.catImageList} 
                                            label='Category Images'
                                            value={this.state.categoryImages}
                                            onChange={this.handleSelectAttachmentChange('categoryImages')}
                                        />
                                    </FormControl>
                                </React.Fragment>
                            }
                            {this.state.showVideoFiles &&
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        name='video'
                                        options={this.state.videoList} 
                                        label='Video'
                                        onChange={this.handleSelectAttachmentChange('videos')}
                                        error={formErrors && formErrors['video'] && true}
                                        errorText={formErrors && formErrors['video']}
                                        value={formData.video}
                                    />
                                </FormControl>
                            }
                            {this.state.showCustomerFiles &&
                                <React.Fragment>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.customerList} 
                                            label='Customers'
                                            value={formData.attCustomer}
                                            onChange={this.handleSelectChange('attCustomer')}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.custQuoteList} 
                                            label='Quotations'
                                            value={this.state.customerQuotations}
                                            onChange={this.handleSelectAttachmentChange('customerQuotations')}
                                        />
                                    </FormControl>
                                </React.Fragment>
                            }
                            {formErrors && formErrors.file_error && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.file_error}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <div className='buttonRow'>
                                {formErrors && formErrors.send && (
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors && formErrors.send}
                                    </Typography>
                                )}
                                <Button onClick={this.previewSubmit}
                                        variant="outlined"
                                       >
                                    Preview
                                </Button>
                                <Button onClick={this.handleConfirmationOpen}
                                        variant="contained"
                                        color="primary"
                                        disabled={this.state.isLoading}
                                       >
                                    Send
                                </Button>
                            </div>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            {formErrors && formErrors.attachment && (
                                <Typography component={"div"} style={{color: colors.red}}>
                                    {formErrors && formErrors.attachment}
                                </Typography>
                            )}
                            {formErrors && formErrors.uid && (
                                <Typography component={"div"} style={{color: colors.red}}>
                                    {formErrors && formErrors.uid}
                                </Typography>
                            )}
                            <Typography variant="h6" gutterBottom>
                                Attachment(s):
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                {this.displayTotalFileSize(_.sum(sizeArray))}
                            </Typography>
                            <List dense={true}>
                                {formData.attachments.partImages && formData.attachments.partImages.map(val => {
                                    return this.displayAttachments(val.name, 'Part Image', 'partImages', val.id, val.link, val.size);
                                })}
                                {formData.attachments.partDrawings && formData.attachments.partDrawings.map(val => {
                                    return this.displayAttachments(val.name, 'Part Drawing', 'partDrawings', val.id, val.link, val.size);
                                })}
                                {formData.attachments.partDesignFiles && formData.attachments.partDesignFiles.map(val => {
                                    return this.displayAttachments(val.name, 'Part Design File', 'partDesignFiles', val.id, val.link, val.size);
                                })}
                                {formData.attachments.part3DFiles && formData.attachments.part3DFiles.map(val => {
                                    return this.displayAttachments(val.name, 'Part 3D File', 'part3DFiles', val.id, val.link, val.size);
                                })}
                                {formData.attachments.partDcos && formData.attachments.partDcos.map(val => {
                                    return this.displayAttachments(val.name, 'Design Change Order', 'partDcos', val.id, val.link, val.size);
                                })}
                                {formData.attachments.partPpos && formData.attachments.partPpos.map(val => {
                                    return this.displayAttachments(val.name, 'Part Orders', 'partPpos', val.id, val.link, val.size);
                                })}
                                {formData.attachments.partSsrs && formData.attachments.partSsrs.map(val => {
                                    return this.displayAttachments(val.name, 'Supplier Stock Returns', 'partSsrs', val.id, val.link, val.size);
                                })}
                                {formData.attachments.videos && formData.attachments.videos.map(val => {
                                    return this.displayAttachments(val.name, 'Video', 'videos', val.id, val.link, val.size);
                                })}
                                {formData.attachments.purchaseOrders && formData.attachments.purchaseOrders.map(val => {
                                    return this.displayAttachments(val.name, 'Purchase Order', 'purchaseOrders', val.id, val.link, val.size);
                                })}
                                {formData.attachments.categoryImages && formData.attachments.categoryImages.map(val => {
                                    return this.displayAttachments(val.name, 'Library Image', 'categoryImages', val.id, val.link, val.size);
                                })}
                                {formData.attachments.documents && formData.attachments.documents.map(val => {
                                    return this.displayAttachments(val.name, 'Document', 'documents', val.id, val.link, val.size);
                                })}
                                {formData.attachments.customerQuotations && formData.attachments.customerQuotations.map(val => {
                                    return this.displayAttachments(val.name, 'Customer Quotation', 'customerQuotations', val.id, val.link, val.size);
                                })}
                            </List>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Send Email?" 
                    message="Are you sure you want to send this email?"
                />
                <NotificationDialog 
                    maxWidth="md"
                    open={this.state.previewOpen} 
                    close={this.handlePreviewClose} 
                    title="Email Preview" 
                    message={
                        <React.Fragment>
                            <Grid container spacing={2} style={{color: 'rgba(0, 0, 0, 0.87)'}}>
                                <Grid container item spacing={1}>
                                    <Grid item lg={1}>
                                        <Avatar>CS</Avatar>
                                    </Grid>
                                    <Grid item lg={11}>
                                        <Typography variant="body1" gutterBottom>
                                            Clenaware Systems {`<${WEB_EMAIL}>`} 
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            To: {this.state.formData.emailTo} <br></br>
                                            Cc: {this.state.formData.emailCc.join(', ')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item spacing={1} style={{overflow: 'auto',maxHeight: 150}}>  
                                        {_.map(this.state.attachmentList, (att, idx) => {
                                            return (
                                                <Grid item lg={6}>
                                                    <div key={idx} style={{border: '1px solid #e5e5e5', padding: '5px', fontSize: '0.8em', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                                                        {att.name}
                                                    </div>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <br></br>
                            <iframe width="900" height="900" border="0" srcDoc={this.state.previewHtml} title="Preview Email"></iframe>
                        </React.Fragment>
                    }
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully sent this email'
                />
            </Grid>
        );
    }
}

export default SendFiles;