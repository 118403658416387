import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';

const components = {
    Control,
    NoOptionsMessage,
    SingleValue,
    ValueContainer,
};

const styles = theme => ({
    
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden'
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        fontSize: 16
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        fontSize: 16
    }
});

function NoOptionsMessage(props) {
    return (
        <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div style={{height: 'auto'}} ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            error={props.selectProps.error}
            helperText={props.selectProps.errorText}
            label={props.selectProps.label}
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            InputLabelProps={{
                shrink: !!props.hasValue || props.isFocused
            }}
            {...props.selectProps.textFieldProps}
            fullWidth
        />
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <Typography component='div' className={props.selectProps.classes.valueContainer}>{props.children}</Typography>;
}

class MultiSelect extends Component {

    render() {
        const { classes, theme } = this.props;

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
            menuList: base => ({
              ...base,
              padding: 0
            }),
            menu: base => ({
                ...base,
                borderRadius: 0
            }),
            menuPortal: base => ({
                ...base,
                zIndex: 9999
            }),
            dropdownIndicator: (base, props) => ({
                ...base,
                paddingRight: 0,
                marginRight: 0,
                paddingLeft: 0,
                transform: 'scale(0.75)',
                height: '30px',
                color: (props.selectProps.error ? '#f44336' : 'rgba(0, 0, 0, 0.54)')
            }),
            indicatorSeparator: (base) => ({
                ...base,
                display: 'none'
            }),
            clearIndicator: (base) => ({
                ...base,
                paddingLeft: 5,
                paddingRight: 5,
                transform: 'scale(0.75)',
                height: '30px',
                cursor: 'pointer'
            }),
            option: (base, props) => ({
                ...base,
                backgroundColor: props.isSelected ? theme.palette.primary.main : (props.isFocused ? theme.palette.primary.opaque : '#ffffff')
            }),
            multiValueLabel: (base) => ({
                ...base,
                color: '#ffffff',
                backgroundColor: theme.palette.primary.main,
                borderRadius: 0,
                fontSize: '13px',
                padding: 3,
                paddingLeft: 8,
                paddingRight: 8
            }),
            multiValueRemove: (base) => ({
              ...base,
              backgroundColor: theme.palette.primary.dark,
              color: '#ffffff',
              borderRadius: 0,
              padding: 4,
              cursor: 'pointer',
              ':hover': {
                backgroundColor: theme.palette.primary.opaque,
                color: 'white',
              },
            }),
        };

        const cleanValue = [];
        if(Array.isArray(this.props.value)) {
            this.props.value.forEach(val => {
                let realValue = this.props.options.find(option => option.value === val);
                cleanValue.push(realValue);
            });
        }
        
        return (
            <React.Fragment>
                <FormControl>
                    <Select
                        isSearchable={false}
                        isMulti={true}
                        classes={classes}
                        styles={selectStyles}
                        placeholder={null}
                        label={this.props.label}
                        components={components}
                        options={this.props.options}
                        onChange={this.props.onChange}
                        defaultValue={this.props.defaultValue}
                        menuPortalTarget={document.body}
                        value={cleanValue}
                        error={!!this.props.error}
                        errorText={this.props.errorText}
                        isOptionDisabled={(option) => option.disabled === true}
                        isDisabled={this.props.disabled}
                    />
                </FormControl>
            </React.Fragment>
        );
    }
}
  
MultiSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};
  
export default withStyles(styles, { withTheme: true })(MultiSelect);