import { async } from "q";

const _cleanEntry = (entry) => {
    return entry.replaceAll('\r','');
}

export const parseRow = (row) => {
    var insideQuote = false,                                             
        entries = [],                                                    
        entry = [];
    row.split('').forEach(function (character) {                         
        if(character === '"') {
        insideQuote = !insideQuote;                                      
        } else {
        if(character == "," && !insideQuote) {                           
            entries.push(entry.join(''));                                  
            entry = [];                                                    
        } else {
            entry.push(character);                                         
        }                                                                
        }                                                                  
    });
    entries.push(_cleanEntry(entry.join('')));                                        
    return entries;
}
