import React, { useState, useEffect } from 'react';
import API from 'API';
import { 
    Grid, DialogActions, Button, Typography
} from '@material-ui/core';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';
import { map } from 'lodash';

const PrePopulateFormDialog = ({ formId, prePopulationFormList, closeDialog, callBack, openForm }) => {

    const [formData, setFormData] = useState({  
        formID: 0
    });

    const submit = () => {

        API.post(`/forms/${formId}/copyFormResults`, { 
            formID: formData.formID,
        })
        .then(result => {
            if(callBack){
                callBack();
            }
            openForm();
        });
    }

    return (
        <>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="caption" paragraph>
                    This form has not been started, therefore you can pre-populate the form results from a previous form.
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Would you like to copy the results from a previous form?
                </Typography>
                <AutoCompleteSelect 
                    options={prePopulationFormList} 
                    label='Copy From *'
                    fullWidth
                    onChange={(o) => setFormData({...formData, formID: o?.value})}
                    value={formData.formID}
                />
                <br></br><br></br>
            </Grid>
        </Grid>
        <DialogActions className='pr-0 pb-0 pt-3'>
            <Button 
                size='small'
                color="default"
                onClick={() => closeDialog()}
                variant="outlined"
            >
                Cancel
            </Button>
            <Button 
                size='small'
                color="secondary"
                onClick={() => {closeDialog(); openForm()}}
                variant="outlined"
            >
                Don't Copy
            </Button>
            <Button 
                size='small'
                color="primary"
                onClick={() => submit()}
                variant="contained"
                disabled={!formData.formID}
            >
                Copy
            </Button>
        </DialogActions>
        </> 
    );
};

export default PrePopulateFormDialog;