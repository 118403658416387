import API from 'API';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';

import icons from 'Helpers/IconHelper';

const initialState = {
    isLoading:  true,
    templates:  [],
    editDialog: {
        id:         null,
        name:       null,
        heading:    null,
        subheading: null,
    }
};

class Templates extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        API.get('/customerReturns/greenaware/templates')
        .then(res => {
            this.setState({
                isLoading: false,
                templates: res.data
            })
        });
    }

    handleEdit = (rowData) => {
        this.setState({
            editDialog: {
                id:          rowData.gt_id,
                name:        rowData.gt_template_label,
                heading:     rowData.gt_heading,
                subheading:  rowData.gt_subheading,
                description: rowData.gt_description,
            }
        })
    }

    handleDialogClose = () => {
        this.setState({editDialog: initialState.editDialog}, this.getData)
    }

    handleEditSubmit = () => {
        API.post(`/customerReturns/greenaware/templates/${this.state.editDialog.id}`, this.state.editDialog)
        .then(() => { 
            this.props.callBack(); 
            this.handleDialogClose();
        })
    }

    render() {
        const { isLoading, templates, editDialog }  = this.state;

        if (isLoading) return (<LoadingCircle />);

        return (
            <PaddedPaper>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DataTable
                            config={{
                                key: 'gt_id' 
                            }}
                            columns={[
                                {
                                    heading:       '#',
                                    field:         'gt_id',
                                    sizeToContent: true,
                                },
                                {
                                    heading:       'Template',
                                    field:         'gt_template_label',
                                },
                                {
                                    actions: rowData => [
                                        {
                                            name: 'edit',
                                            icon: icons.edit,
                                            onClick: () => {this.handleEdit(rowData);}
                                        }
                                    ]
                                }
                            ]}
                            rows={templates}
                        />
                    </Grid>
                </Grid>
                <Dialog 
                    open={this.state.editDialog.id} 
                    onClose={this.handleDialogClose} 
                    fullWidth={true} 
                    maxWidth="sm" 
                >
                    <DialogTitle>
                        <Typography>Edit Template Name</Typography>
                    </DialogTitle>
                    <DialogContent style={{height: 300}}>
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Template Name"
                                        value={this.state.editDialog.name}
                                        onChange={(e) => {this.setState({editDialog: {...this.state.editDialog, name: e.target.value}})}}
                                        fullWidth
                                    />
                                 </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Template Heading (what the customer sees)"
                                        value={this.state.editDialog.heading}
                                        onChange={(e) => {this.setState({editDialog: {...this.state.editDialog, heading: e.target.value}})}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Template Subheading (what the customer sees)"
                                        value={this.state.editDialog.subheading}
                                        onChange={(e) => {this.setState({editDialog: {...this.state.editDialog, subheading: e.target.value}})}}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} variant="outlined" color="default">Close</Button>
                        <Button disabled={!this.state.editDialog.name || this.state.editDialog.name == ''} onClick={() => this.props.deployConfirmation('Are you sure you want to edit this template?', 'Edit Template', this.handleEditSubmit)} autoFocus variant="contained" color="primary">Confirm</Button>
                    </DialogActions>
                </Dialog>
            </PaddedPaper>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
})

export default connect(null, mapDispatchToProps)(Templates);