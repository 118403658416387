import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import StickyNotes from 'Components/Common/StickyNotes/StickyNotes';
import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';
import OrderSearchBlock from 'Components/Sales/Order/OrderSearch/OrderSearchBlock';

import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import JobSnapshot from 'Components/Engineers/Jobs/Misc/JobSnapshot';
import { downloadS3File } from 'Functions/MiscFunctions';
import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import 'Assets/timeLine.css';

const initialState = () => ({
    formData:   {
        searchString: '',
        dateFrom: null,
        dateTo: null,
        type: 'All'
    },
    formErrors: {},
    data:       {},
    access:     {},
    isLoading:  false,
});

const styles = theme => ({
    custName: {
        '&:hover':{cursor: 'pointer'}
    }
});

class InvoiceEndOfMonth extends React.Component  {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `invoices:iaa:${this.props.cid}`;
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.getAccess();
        this.getData();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/customer-order-search'),
            API.get('/staff/my/access/check/repeat-customer-order'),
        ])
        .then(([accessRes, repeatOrderRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    customerOrderSearch: ( accessRes?.data?.has_access )      || false,
                    repeatOrder:         ( repeatOrderRes?.data?.has_access ) || false
                }
            });
        })
    }

    handleSetSearch = props => {
        this.setState({formData: props},this.getData)
    }

    getData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/sales/orders/invoice', { params: {
                keywords: this.state.formData.keywords,
                dateFrom: this.state.formData.dateFrom !== null ? moment(this.state.formData.dateFrom).format('YYYY-MM-DD') : null,
                dateTo:   this.state.formData.dateTo !== null ? moment(this.state.formData.dateTo).format('YYYY-MM-DD') : null,
                status:   'Approval',
                slim:1,
                endOfMonth: 1
            }})
            .then(invoices => {
                let invoicesAwaitingApproval            = _.filter(invoices.data, { 'ri':0 });
                this.setState({
                    ...this.state,
                    data: invoicesAwaitingApproval,
                    isLoading: false
                }, () => { this.props.updateTabs(); this.savePageState();});
            });
        });
    }

    handleDownloadOpen = orderId => this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md');
    handleEmailOpen    = orderId => this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />, 'Email Previous Documents', '', 'md');

    handleShowStickyNote = orderId => { this.props.deployDialog(<StickyNotes orderId={orderId} dialog/>, 'Sticky Notes', null,'xs') }

    handleDownloadInvoice = (invoiceId) => {
        API.post(`/sales/orders/invoice/${invoiceId}/pdf`)
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    setEndOfMonth = (invoiceId) => () => {
        API.post(`/sales/orders/invoice/${invoiceId}/setEndOfMonth`)
        .then(this.getData);
    }

    render() {
        const { formData, access, formErrors, isLoading, data } = this.state;
        const { classes } = this.props;

        return (            
            <Grid container item spacing={2}>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={12} style={{paddingTop:'1.8em'}}>
                        <OrderSearchBlock disableSites disableStatus callBack={this.handleSetSearch} formData={this.state.formData} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Typography variant="h5">
                            <strong>Invoices Awaiting Approval End of Month</strong><br></br>
                        </Typography>
                        <DataTable  
                            config={{
                                key: 'invoice_id',
                                pagination: true,
                                alternatingRowColours: true,
                                responsiveimportant: true,
                                isLoading: this.state.isLoading
                            }}
                            columns={[
                                {
                                    heading: 'Invoice Date',
                                    field: rowData => moment(rowData.d).format("DD/MM/YYYY"),
                                    important: true,
                                    dataRef: 'd'
                                },
                                {
                                    heading: 'Invoice Number',
                                    field: rowData => rowData.n,
                                    important: true,
                                    dataRef: 'n'
                                },
                                {
                                    actionsCustomHeader:    true,
                                    important:              true,
                                    alignment:              'left',
                                    heading:                'Reference',
                                    dataRef: 'r',
                                    actions: rowData => {
                                        let a = [
                                            { label: rowData.r,         link: (e) => { e.preventDefault(); this.handleDownloadInvoice(rowData.i)}, type:'plainLink'},
                                            { label: rowData.o.r,       link: `/sales/order/view/${rowData.o.i}`,  class: "textSecondary",  type:'plainLink'},
                                        ];

                                        if(rowData.o.cn.length > 0) {
                                            _.each(rowData.o.cn, (cr, index) => {
                                                a.push(
                                                    { label: cr.r, link: `/returns/creditnote/${cr.i}`, color: colors.red,  type:'plainLink'}
                                                )
                                            });
                                        }

                                        if(rowData.o.cr.length > 0) {
                                            _.each(rowData.o.cr, (cr, index) => {
                                                a.push(
                                                    { label: cr.r, link: `/returns/customerReturn/${cr.i}`, color: colors.red,  type:'plainLink'}
                                                )
                                            });
                                        }

                                        return a;
                                    }
                                },
                                // {
                                //     heading: 'Discount',
                                //     field: rowData => rowData.o.t === 'With Discount',
                                //     important: true,
                                //     fieldFormat: 'boolean',
                                //     tooltip: rowData => rowData.o.t,
                                //     dataRef: 'o.t',
                                //     alignment: 'center'
                                // },
                                {
                                    heading: 'Customer',
                                    field: rowData => <Tooltip
                                                            title={
                                                                <Typography variant="body2">
                                                                    <b>Delivery Address:</b><br />
                                                                    {rowData.c.d.a.split('\n').map((item, key) => {
                                                                        return  <span key={key}>
                                                                                    {item}{rowData.c.d.a.split('\n').length === key+1 ? null : <br/>}
                                                                                </span>
                                                                    })}
                                                                </Typography>
                                                            }
                                                            placement="left"
                                                        >
                                                            <div className={classes.custName} onClick={()=>{ this.props.history.push(`/customers/view/${rowData.c.i}`); }}>
                                                                {rowData.c.n}
                                                                {' '}
                                                                <Typography component="div" variant="body2">
                                                                    {rowData.c.d.n}
                                                                </Typography>
                                                            </div>
                                                        </Tooltip>,
                                    important: true,
                                    dataRef: 'c.n',
                                    truncate: true
                                },
                                {
                                    heading: 'Files',
                                    dataRef: rowData => !!rowData.f.ej + !!rowData.f.po + !!rowData.f.cn,
                                    field: rowData => 
                                        <Grid container style={{width:'100%'}} spacing={1} xs={12} alignItems='flex-end' justify="center">
                                            {!!rowData.f.ej && 
                                                <Grid item xs={12} lg={4}>
                                                    <AllIcon 
                                                        icon='user-hard-hat'  
                                                        button 
                                                        noMargin
                                                        tooltip='Engineer Job Sheet'
                                                        onClick={() => {downloadS3File(rowData.f.ej)}}
                                                    />
                                                </Grid>
                                            }
                                            {!!rowData.f.po && 
                                                <Grid item xs={12} lg={4}>
                                                    <AllIcon 
                                                        icon={(rowData.f.po.includes('.wav') || rowData.f.po.includes('.x-wav')) ? 'cassette-tape' : 'file-alt' }
                                                        button 
                                                        noMargin
                                                        tooltip='Purchase Order'
                                                        onClick={() => {downloadS3File(rowData.f.po)}}
                                                    />
                                                </Grid>
                                            }
                                            {!!rowData.f.cn && 
                                                <Grid item xs={12} lg={4}>
                                                    <AllIcon 
                                                        icon='truck'  
                                                        button 
                                                        noMargin
                                                        tooltip='Order Despatched'
                                                        onClick={() => {downloadS3File(rowData.f.cn)}}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>,
                                    important: true,
                                    alignment: 'center',
                                },
                                {
                                    heading: 'Total',
                                    dataRef: 't.t',
                                    field: rowData => ( 
                                        <Tooltip 
                                            title={
                                                <div style={{display: 'flex', width: 200}}>
                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                            <Typography variant="body2">Sub Total</Typography>
                                                            <Typography variant="body2">VAT ({rowData.t.vp}%)</Typography>
                                                            <Typography variant="body2">Total</Typography>
                                                        </div>
                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                            <Typography variant="body2">£{rowData.t.s}</Typography>
                                                            <Typography variant="body2">£{rowData.t.v}</Typography>
                                                            <Typography variant="body2">£{rowData.t.t}</Typography>
                                                        </div>
                                                    </div>
                                            } 
                                            placement="left"
                                        >
                                            <span>{rowData.t.t}</span>
                                        </Tooltip>
                                    ),
                                    important: true,
                                    alignment: 'right',
                                    fieldPrefix: '£',
                                },
                                {
                                    heading: '',
                                    field: i => !!i?.p &&
                                    <AllIcon
                                    icon={icons.partialOrderPayment}
                                    tooltip='Order is partially paid'
                                    color={colors.red}
                                    noMargin
                                    />,
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Jobs',
                                    field:      rowData => <>
                                        {rowData?.ej && 
                                            <Tooltip
                                                title={<JobSnapshot jobRef={rowData?.ej?.ref} forms={rowData?.ej?.f} />}
                                                placement="left"
                                            >
                                                <IconButton className={classes.iconButton} onClick={() => this.props.history.push(`/engineers/jobs/view/${rowData.ej?.id}`) }>
                                                    <AllIcon 
                                                        icon={`user-helmet-safety`}
                                                        button
                                                        noMargin
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </>,
                                    alignment: 'center',
                                    sizeToContent: true,
                                    important: false
                                },
                                {
                                    actions: rowData => {
                                        let ret =   [
                                                        {name: 'Sticky Notes',                  icon: icons.stickyNote,     onClick: () => this.handleShowStickyNote(rowData.o.i), hide:rowData.sn[0] === 0, color: (rowData.sn[1] && colors.yellow)  },
                                                        {
                                                            name: 'Remove From End of Month',
                                                            icon: 'calendar-xmark',
                                                            onClick:()=>this.props.deployConfirmation(
                                                                `Are you sure you want to remove this invoice from end of month?`,
                                                                'Remove From End of Month',
                                                                this.setEndOfMonth(rowData.i)
                                                            ),
                                                        }
                                                    ]
                                        ret.push(rowData.l ? 
                                            {name: 'locked',  icon: icons.lock,    onClick: ()=>{}}:
                                            {name: 'Approve', icon: icons.search,  link: `/sales/order/invoice/approval/${rowData.i}`}
                                        );
                                        ret.push({
                                            type: 'actionMenu',
                                            rows: [
                                                {
                                                    icon: icons.repeat,
                                                    label: "Repeat Order",
                                                    onClick:() => this.props.history.push(`/sales/order/add/repeat/${rowData.o.i}`),
                                                    display: true,
                                                    disabled: !access.repeatOrder
                                                },
                                                {
                                                    icon: "download",
                                                    label: "Download",
                                                    onClick: () => this.handleDownloadOpen(rowData.o.i),
                                                    display: true
                                                },
                                                {
                                                    icon: "envelope",
                                                    label: "Send to Customer",
                                                    onClick: () => this.handleEmailOpen(rowData.o.i),
                                                    display: true
                                                },
                                                {
                                                    icon: "file-search",
                                                    label: "View this Customers Orders",
                                                    onClick: () => this.props.history.push(`/customers/view/${rowData.c.i}/Orders`),
                                                    display: true,
                                                    disabled: !access.customerOrderSearch
                                                }
                                            ]
                                        });
                                        return ret;
                                    }
                                }
                            ]}
                            rows={data}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                    => dispatch(setPersistence(key, state)),
    deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:        ()                              => dispatch(closeDialog()),
    deployConfirmation: (message, title, success)       => dispatch(deployConfirmation(message, title, success))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InvoiceEndOfMonth));