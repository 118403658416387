import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import API from '../../../API';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import {statusColour} from './../../../Helpers/ColourHelper'
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import SnackBar from '../../Common/SnackBars/SnackBar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StatusDataTable from '../StatusDataTable';
import Textarea from '../../Common/Inputs/Textarea';
import { connect } from 'react-redux';
import icons from 'Helpers/IconHelper';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';
import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    formData: {
        sr_id: '',
        notes:''
    },
    keywords: '',
    searchResults: {},
    formErrors: [],
    dialogOpen: false,
    snackbarOpen: false,
    statuses: ['Approval', 'Dispatch', 'Confirmation', 'Delivery', 'Complete'],
    isLoading: true
});

class SupplierStockReturnConfirmation extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'SupplierStockReturn:Confirmation';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        !this.hasPageState() && this.getSearchData();
    }

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            const params = {
                keywords: this.state.keywords,
                status: 'Confirmation'
            }
            API.get('/supplierStockReturns', {
                params,
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    _.map(result.data, (ssr) => {
                        ssr.colour = statusColour(ssr.sr_status);
                    });
                    this.setState({
                        searchResults: result.data,
                        isLoading: false
                    },()=>this.savePageState())
                }
            })
        });
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
        }, 
        () => {
            this.getSearchData();
        });
    }

    handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleDialogOpen = rowData => {
        this.setState({
            dialogOpen: true,
            statuses: ['Approval', 'Dispatch', 'Confirmation',  (rowData.sr_return_type === 'Credit' ? 'Credit' : 'Delivery'), 'Complete'],
            formData: {
                ...this.state.formData,
                sr_id: rowData.sr_id,
                
            }
        });
    };

    handleDialogClose = () => {this.setState({dialogOpen: false});};
    handleSnackbarClose = () => {this.setState({ snackbarOpen: false }); };

    handleSuccess = () => {
        this.setState({ dialogOpen: false });
        this.submit();
    }

    submit = () => {
        API.post(`/supplierStockReturns/${this.state.formData.sr_id}/confirmation`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.getSearchData();
            }
        });
    }

    downloadFile = rowData => {
        API.get('/supplierStockReturns/' + rowData.sr_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    render() {
        const { formErrors } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Supplier Stock Return Confirmation
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={this.state.keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <StatusDataTable 
                            isLoading={this.state.isLoading}
                            searchResults={this.state.searchResults}
                            actions={ rowData => {
                                return [
                                    {name: 'Download', icon: 'download', onClick: this.downloadFile},
                                    rowData.locked ?
                                        {name: 'Locked',  icon: icons.lock, onClick: ()=>{}} :
                                        {name: 'Confirm', icon: 'check',    onClick: this.handleDialogOpen}
                                ]
                            }}
                        />
                    </PaddedPaper>
                </Grid>
                <Dialog 
                    open={this.state.dialogOpen} 
                    onClose={this.handleDialogClose} 
                    fullWidth={true} 
                    maxWidth="sm" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title" color="secondary">Confirm Stock Return</DialogTitle>
                    <DialogContent>
                        <Stepper activeStep={2} alternativeLabel >
                            {this.state.statuses.map(label => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Textarea
                            id="notes"
                            name="notes"
                            label="Notes"
                            value={this.state.formData.notes}
                            rows={5}
                            error={formErrors && formErrors['notes']}
                            onChange={this.handleChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} variant="outlined" color="default">Cancel</Button>
                        <Button onClick={this.handleSuccess} color="primary" autoFocus variant="contained">Confirm</Button>
                    </DialogActions>
                </Dialog>
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully confirmed this supplier stock return'
                />
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(SupplierStockReturnConfirmation);
