import React, { Component } from 'react';
import _                    from 'lodash';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import 'Assets/timeLine.css';

import ActivityLog from 'Components/Common/ActivityLog/ActivityLog';

const initialState = () => ({
    isLoading: true,
    package: {},
    currentTab: 'Package',
});

class ActivityLogTab extends Component {
    constructor(props) {
        super(props);    
        this.state = initialState();
    }

    render(){
     
        const _icons = {
            'Created'   : icons.plus,
            'Amended'   : icons.edit,
            'Deleted'   : icons.delete,
            'Reinstated': icons.clear,
        }

        const _colors = {
            'Created'   : colors.blue,
            'Amended'   : colors.blue,
            'Deleted'   : colors.red,
            'Reinstated': colors.blue,
        }

        const { salesPackage } = this.props;

        return(
            <ActivityLog 
                disableTabBar
                fields={{
                    type:   'type',
                    date:   'date',
                    icon:   { color: 'color', icon: 'icon' },
                    text:   'text',
                    staff:  'staff',
                }}
                atl={_.map(_.filter(salesPackage.logs, i => parseInt(i.pl_version) <= parseInt(salesPackage.p_version) ), i => ({
                    type:   i.pl_type,
                    date:   i.pl_datetime,
                    icon:   _icons[i.pl_type],
                    color:  _colors[i.pl_type],
                    text:   i.pl_notes,
                    staff:  i.pl_staff,
                }))}
            />
        );
    }
}

export default ActivityLogTab;