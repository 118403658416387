import { Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography, Button } from "@material-ui/core";
import API from "API";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import { colors } from "Helpers/ColourHelper";
import IconHelper from "Helpers/IconHelper";
import AllIcon from "Components/Common/Icons/AllIcon";
import InputHelper from "Helpers/InputHelper";
import { clenyDate } from "Helpers/TimeHelper";
import _ from "lodash";
import React from "react";
import ViewEmail from "../ViewEmail/ViewEmail";
import SearchBox from "./SearchBox";
import {  DEFAULT_EMAIL, WEB_EMAIL, ACCOUNTS_EMAIL, SALES_EMAIL } from 'Constants';
import moment from "moment";
import { connect } from "react-redux";
import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';
import SendEmail from '../SendEmail/SendEmail'

const initialState = (props) => ({
    isLoading: true,
    emails: [],
    sendEmailProps : {
        ...(
            (props.orderId && { source: 'Order', sourceData: props.orderId }) ||
            (props.quotationId && { source: 'Quote', sourceData: props.quotationId }) ||
            (props.creditNoteId && { source: 'Credit Note', sourceData: props.creditNoteId }) ||
            (props.customerReturnId && { source: 'Customer Return', sourceData: props.customerReturnId }) ||
            { source: 'Manual', sourceData: null }
        ),
        to: props.contactId ?? null,
        customerId: props.customerId ?? null,
        ...(props.sendProps || {})
    },
    formData: {
        contactId: props.contactId,
        orderId: props.orderId,
        quotationId: props.quotationId,
        customerId: props.customerId,
        creditNoteId: props.creditNoteId,
        customerReturnId: props.customerReturnId,
        addressId: props.addressId,
        area: null,
        sub: null,

        newEmailsOnly: props.allEmails
    }, 
    localFormData: {
        search: null,
        read: 'AllSent',

        //advances search
        from: 'all',
        fromEmail: 'all',
        hasAttachment: false,
        cc: false,
        dateFrom: null,
        dateTo: null
    },
    show: {
        areas: ( props.contactId || props.customerId || props.allEmails || props.addressId ) ? true : false,
        contact: ( props.contactId ) ? false : true,
        status: null,
        subArea: null,
    },
    selectEmail: null,
    count: 0,
    folders: [],
    style: {
        height: 1200,
        ...props.style
    },
    lists: {
        from: [],
        fromEmail: [
            {value: 'all', label: 'All'},
            {value: 'Default', label: `Default (${DEFAULT_EMAIL})`},
            {value: 'Accounts', label: `Accounts (${ACCOUNTS_EMAIL})`},
            {value: 'Web', label: `Web (${WEB_EMAIL})`},
            {value: 'Sales', label: `Sales (${SALES_EMAIL})`},
        ]
    },
    bounced: {
        show: !props?.hideBouncedWarning,
        count: 0,
        bounced: []
    }
})

const StatusToIcon = {
    'Sent': {
        icon: IconHelper.email,
        color: colors.orange
    },
    'Opened': {
        icon: IconHelper.emailOpen,
        color: colors.green
    },
    'OptOut': {
        //treat oped out as open
        icon: IconHelper.emailOpen,
        color: colors.green
    },
    'Error': {
        icon: IconHelper.warning,
        color: colors.red
    },
    'Bounced': {
        icon: IconHelper.emailBounced,
        color: colors.red
    },
    'Complaint': {
        icon: IconHelper.emailComplaint,
        color: colors.red
    }
}

class ViewEmails extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState(props);
        this.inputHelper = new InputHelper(this);
    }

    componentDidMount(){
        this.getFolders();
    }

    getFolders = () => {
        API.get(`/email/folders`).then(res => {
            this.setState({
                folders: _.map(_.orderBy(res.data, 'ef_order'), f => ({
                    id: f.ef_id,
                    name: f.ef_name,
                    icon: f.ef_icon,
                    source: f.ef_internal_area,
                    count: 0,
                    sub: _.map(_.orderBy(f.sub_folders,'ef_order'), s => ({
                        id: s.ef_id,
                        name: s.ef_name,
                        icon: s.ef_icon,
                        count: 0,
                        subjects: JSON.parse(s.ef_subject)
                    }))
                }))
            }, this.getEmails)
        })
    }

    getEmails = () => {
        let _formData = _.cloneDeep(this.state.formData);

        if (_formData.sub){
            _formData.sub = JSON.stringify(_.find(this.state.folders, {id: _formData.area}).sub[_.findIndex(_.find(this.state.folders, {id: _formData.area}).sub, {id: _formData.sub})].subjects);
        }

        if (_formData.area){
            _formData.area = _.find(this.state.folders, {id: _formData.area}).source;
        }
        
        API.get(`/email/all`, {
            params: _formData
        }).then(res => {

            let emailData = _.filter(res.data, i => ['Sent', 'Opened', 'OptOut'].includes(i.email_status));
            if (parseInt(emailData.length) > parseInt(this.state.count)){
                //updateFixed
                this.setState({
                    count: parseInt(emailData.length),
                    folders: _.map(this.state.folders, f => ({
                        ...f,
                        count: _.filter(emailData, i => i.contact.etc_source === f.source).length > 99 ? '99+' : _.filter(emailData, i => i.contact.etc_source === f.source).length,
                        sub: _.map(f.sub, s => ({
                            ...s,
                            count: _.filter(emailData, i => i.contact.etc_source === f.source && s.subjects.includes(i.email_subject)).length > 99 ? '99+' : _.filter(emailData, i => i.contact.etc_source === f.source && s.subjects.includes(i.email_subject)).length
                        }))
                    })),
                    lists: {
                        ...this.state.lists,
                        from: _.map(_.uniqBy(emailData, i => i.contact.etc_staff), i => ({
                            value: i.contact.etc_staff,
                            label: i?.contact?.staff ? `${i.contact.staff.staff_first_name} ${i.contact.staff.staff_last_name}` : 'System'
                        }))
                    }
                })
            }

            this.setState({
                isLoading: false,
                emails: res.data,
                selectedEmail: this.state.selectedEmail ? this.state.selectedEmail : ( _.filter(emailData, this.filterEmails)[0]?.email_id ?? null),
                bounced: {
                    ...this.state.bounced,
                    count : _.filter(res.data, i => ['Bounced'].includes(i.email_status)).length > this.state.bounced.count ? _.filter(res.data, i => ['Bounced'].includes(i.email_status)).length : this.state.bounced.count,
                    //bounced: _.filter(res.data, i => ['Bounced'].includes(i.email_status))
                }
            })
        })
    }

    setArea = (area) => () => {
        this.setState({
            formData: {
                ...this.state.formData,
                area: area,
                sub: null
            },
            selectedEmail: null
        }, () => {
            this.getEmails();
        })
    }

    setSubArea = (subId) => () => {
        let area = _.find(this.state.folders, i => _.find(i.sub, {id: subId}));
        this.setState({
            formData: {
                ...this.state.formData,
                sub: subId,
                area: area.id
            },
            selectedEmail: null
        }, () => {
            this.getEmails();
        })
    }

    showSubArea = (sub) => () => {
        this.setState({
            show: {
                ...this.state.show,
                subArea: this.state.show.subArea === sub ? null : sub
            },  
        })
    }

    selectEmail = (id) => () => {
        this.setState({
            selectedEmail: id
        })
    }

    filterEmails = (i) => {
        const { read, search, from, fromEmail, hasAttachment, cc, dateFrom, dateTo } = this.state.localFormData;

        if (read){
            switch (read){
                case 'AllSent':
                    if (!['Sent', 'Opened', 'OptOut'].includes(i.email_status)){
                        return false;
                    }
                    break;
                case 'All':
                    if (!['Sent', 'Opened', 'OptOut', 'Bounced', 'Complaint'].includes(i.email_status)){
                        return false;
                    }
                    break;
                default:
                    if (i.email_status !== read){
                        return false;
                    }
                    break;
            }
        }

        if (from !== 'all' && from !== i.contact.etc_staff) {
            return false;
        }

        if (fromEmail !== 'all' && fromEmail !== i.email_from) {
            return false;
        }

        if (hasAttachment && parseInt(i.attachments_count) === 0){
            return false;
        }

        if (cc && parseInt(i.cc_count) === 0) {
            return false;
        }

        if (dateFrom ){
            if (moment(i.email_datetime_added).isBefore(moment(dateFrom))){
                return false;
            }
        }

        if (dateTo ){
            if (moment(i.email_datetime_added).isAfter(moment(dateTo))){
                return false;
            }
        }

        if (search) {
            if (`${i.email_to}|${i.email_subject}|${i.email_body}`.toLowerCase().indexOf(search.toLowerCase()) === -1){
                return false;
            } 
        }
        return true;
    }

    composeEmail = () => {
        this.props.deployDialog(
            <SendEmail
                {...this.state.sendEmailProps}
                onSend={()=>{this.props.closeDialog(); this.getEmails()}}
            />,
            <Typography variant="h6" style={{color: colors.white}}>Send Email</Typography>, 
            'md', 'success'
        )
    }

    render(){
        const { isLoading, show, formData, selectedEmail, count, folders, localFormData, lists, bounced } = this.state;

        if (isLoading) return <LoadingCircle />
        
        if (count === 0 && bounced.count === 0) return (
            <Grid container spacing={0}
                style={{
                    borderRadius: 5,
                    border: `1px solid ${colors.grey}20`,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >   
                <Grid item style={{padding: '0.5em'}}>
                    <Typography variant="h6">
                        No Emails Sent                     
                    </Typography>
                </Grid>
                {/*}
                    <Grid item style={{padding: '0.5em'}}>
                        <Button
                            onClick={this.composeEmail}
                            variant="contained"
                            color="primary"
                        >
                            <AllIcon   
                                white 
                                icon={IconHelper.email} 
                            />    
                            New Email
                        </Button>
                </Grid>
                */}
            </Grid>
        )

        return (
            <>
                <Grid container spacing={0}
                    style={{
                        borderRadius: 5,
                        border: `1px solid ${colors.grey}20`,
                        overflow: 'hidden',
                        height: '100%',
                        minHeight: '45vh'
                    }}
                >
                    {bounced.show && bounced.count > 0 && 
                        <Grid item xs={12} style={{backgroundColor: colors.red, padding: '0.5em', color: colors.white}}>
                            <AllIcon icon={IconHelper.warning} white size='small'/>
                            Please Note: There are {bounced.count} bounced emails. 
                        </Grid>
                    }
                    <Grid item xs={12}
                        style={{
                            backgroundColor: `${colors.primary}`,
                        }}
                    >
                        <Grid container spacing={3} style={{padding: '1em', /*justifyContent: 'center'*/}}>
                             {/*
                                <Grid item >
                                    <Button
                                        variant="outlined"
                                        style={{
                                            padding: "3px 9px",
                                            color: colors.white,
                                            borderColor: colors.white,
                                            fontSize: 12
                                        }}
                                        onClick={this.composeEmail}
                                    >
                                        <AllIcon   
                                            white 
                                            icon={IconHelper.email} 
                                            size='small'
                                        />    
                                        New Email
                                    </Button>
                                </Grid>
                            */}
                            <Grid item xs={3}></Grid>
                            <Grid item xs={6}>
                                <SearchBox
                                    formData={localFormData}
                                    onChange={this.inputHelper.handleValueChange('localFormData')}
                                    lists={lists}
                                />
                            </Grid>
                            <Grid item xs={3}></Grid>
                        </Grid>
                    </Grid>
                    {(show.areas) &&
                        <Grid item 
                            style={{
                                width: `${((100/12) * 1.5)}%`,
                                backgroundColor: `${colors.primary}20`,
                                maxHeight: this.state.style.height,
                                height: this.state.style.height,
                                overflowY: 'auto'
                            }}
                        >
                            <List>
                                <ListItem key={-1}
                                    button
                                    onClick={this.setArea(null)}
                                    style={{
                                        borderRight: !formData.area && `5px solid ${colors.primary}`
                                    }}
                                >
                                    <ListItemText 
                                        primaryTypographyProps={{ style: {
                                            fontWeight: !formData.area && 'bold'
                                        }}}
                                        primary={<> 
                                            <AllIcon
                                                style={{
                                                    marginRight: 15
                                                }}
                                                icon={IconHelper.email}
                                                heavy={formData.area === null}
                                            />All
                                        </>}
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography variant="caption" style={{fontWeight: 'bold'}}>
                                            {parseInt(count) > 99 ? '99+' : count}
                                        </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {_.map(_.filter(folders, folder => parseInt(folder.count) > 0) , (folder) => 
                                    <>
                                        <ListItem key={`area-${folder.name.replaceAll(' ', '_')}`}
                                            button
                                            onClick={this.setArea(folder.id)}
                                            style={{
                                                borderRight: ( parseInt(formData.area) ===  parseInt(folder.id) && !formData.sub )&& `5px solid ${colors.primary}`
                                            }}
                                        >
                                            <ListItemText 
                                                primaryTypographyProps={{ style: {
                                                    fontWeight: parseInt(formData.area) ===  parseInt(folder.id) && 'bold',
                                                    fontSize: parseInt(formData.area) ===  parseInt(folder.id) && 15
                                                }}}
                                                primary={<>
                                                    <AllIcon
                                                        style={{
                                                            marginRight: 15
                                                        }}
                                                        icon={folder.icon}
                                                        heavy={parseInt(formData.area) ===  parseInt(folder.id)}
                                                    />{folder.name}
                                                </>} 
                                            />
                                            <ListItemSecondaryAction>
                                                {folder.sub.length > 0 ?
                                                    <AllIcon 
                                                        icon={parseInt(show.subArea) === parseInt(folder.id) ? IconHelper.arrowDown : IconHelper.next}
                                                        heavy={formData.area === folder.id}
                                                        size='small'
                                                        onClick={this.showSubArea(folder.id)}
                                                        noMargin
                                                        noPadding
                                                    /> : 
                                                    <Typography variant="caption" style={{fontWeight: 'bold'}}>
                                                        {folder.count}
                                                    </Typography>
                                                }
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {folder.sub.length > 0 &&
                                            <div
                                                style={{
                                                    display: parseInt(show.subArea) === parseInt(folder.id) ? 'block' : 'none',
                                                }}
                                            >
                                                {_.map(_.filter(folder.sub, subFolder => parseInt(subFolder.count) > 0), subFolder => 
                                                    <ListItem 
                                                        key={`sub-${subFolder.name.replaceAll(' ', '_')}`}
                                                        button
                                                        onClick={this.setSubArea(subFolder.id)}
                                                        style={{
                                                            borderRight: ( formData.sub ===  subFolder.id )&& `5px solid ${colors.primary}`
                                                        }}    
                                                    >
                                                        <ListItemText 
                                                            secondaryTypographyProps={{ style: {
                                                                fontWeight: formData.sub ===  subFolder.id && 'bold',
                                                                fontSize: formData.sub ===  subFolder.id && 12
                                                            }}}
                                                            secondary={subFolder.name} 
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <Typography variant="caption" style={{fontWeight: 'bold'}}>
                                                                {subFolder.count}
                                                            </Typography>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )}
                                            </div>
                                        }
                                    </>
                                )}
                            </List>
                        </Grid>
                    }
                    <Grid item 
                        style={{
                            width: `calc(${(100/12) * (show.areas ? 2.5 : 3)}% + 30px)`,
                            backgroundColor: `${colors.primary}05`,
                            height: this.state.style.height,
                            maxHeight: this.state.style.height,
                            overflowY: 'auto'
                        }}
                    >
                        <List>
                            {
                                _.map(_.filter(this.state.emails, 
                                        this.filterEmails
                                    ), (email, i) => 
                                    <ListItem key={`Email_${email.email_id}`}
                                        button
                                        style={{
                                            borderBottom: `1px solid ${colors.grey}20`,
                                            borderRight: (email.email_id === selectedEmail) && `5px solid ${colors.primary}`,
                                            minHeight: 73
                                        }}
                                        onClick={this.selectEmail(email.email_id)}
                                    >
                                        <ListItemAvatar
                                            style={{
                                                minWidth: 33
                                            }}
                                        >
                                            <AllIcon
                                                icon={StatusToIcon[email.email_status].icon}
                                                color={StatusToIcon[email.email_status].color}
                                                noMargin
                                                heavy={email.email_id === selectedEmail}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 180,
                                                maxHeight: 48,
                                            }}
                                        >
                                            {
                                                <>
                                                    {show.contact &&
                                                        <Typography 
                                                            style={{
                                                                fontWeight: email.email_id === selectedEmail && 'bold',
                                                                fontSize: email.email_id === selectedEmail && 14,
                                                                maxWidth: this.props.smallMode ? 140 : 180,
                                                                maxHeight: 48,
                                                                overflow: 'hidden',
                                                            }}
                                                            variant={'body2'}>{email.contact.contact.contact_name}</Typography>
                                                    }
                                                    <Typography 
                                                        style={{
                                                            fontWeight: email.email_id === selectedEmail && 'bold',
                                                            fontSize: email.email_id === selectedEmail && (show.contact ? 11 : 15 ),
                                                            maxWidth: this.props.smallMode ? 140 : 180,
                                                            maxHeight: 48,
                                                            overflow: 'hidden',
                                                        }}
                                                        variant={show.contact ? 'caption' : 'body1'}>{this.props.smallMode ? email.email_subject.replaceAll('Clenaware Systems -', '') : email.email_subject}</Typography>
                                                </>
                                            }
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <Typography 
                                                variant='caption'
                                                style={{
                                                    fontWeight: email.email_id === selectedEmail && 'bold',
                                                    textAlign: 'center'
                                                }}
                                            >   
                                                {clenyDate(email.email_datetime_added, false, 1)} <br/>
                                                {clenyDate(email.email_datetime_added)}
                                            </Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            }
                        </List>
                    </Grid>
                    <Grid item 
                        style={{
                            borderLeft: `1px solid ${colors.grey}20`,
                            width: `calc(${(100/12) * ( show.areas ? 8 : 9 ) }% - 30px)`,
                        }}
                    >
                        {selectedEmail &&
                            <ViewEmail
                                emailId={selectedEmail}
                                refresh={this.getEmails}
                                viewEmail={(i) => this.selectEmail(i)()}
                            />
                        }
                    </Grid>
                </Grid>
            </>
        )   
    }
}



const mapDispatchToProps = dispatch => ({
    deployDialog: (content, title, size, variant=null) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:  ()                     => dispatch(closeDialog()),
})

export default connect(null, mapDispatchToProps)(ViewEmails);

