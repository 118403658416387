import React, {useState, useEffect} from 'react';

import API from 'API';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import CustomerAccountStatusList from 'Components/Accounts/CustomerAccountStatuses/CustomerAccountStatusList';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles =  makeStyles(theme => ({
    settingsHeading: {
        paddingTop: theme.spacing(2)
    }
}));

export default function CustomerAccountStatuses() {
    const classes = useStyles();
    const initialState = {
        formData: {
            name: '',
            allowQuotes: false,
            allowOrders: false
        },
        formErrors: {},
        confirmationOpen: false,
        access: {
            customerAccountStatuses: false,
            addCustomerAccountStatuses: false
        },
        data: [],
        isLoading: true
    }
    const [state, setState] = useState({...initialState});
    const {formData, formErrors, confirmationOpen, access, data, isLoading} = state;
    const handleTextChange = e => {
        e.persist();
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [e.target.id]: e.target.value
            }
        }));
    }
    const handleCheckbox = e => {
        e.persist();
        setState(state => ({
            ...state, 
            formData: {
                ...formData,
                [e.target.id]: JSON.parse(e.target.value)
            }
        }));
    }
    const handleConfirmationOpen = () => setState(state => ({...state, confirmationOpen: true}));
    const handleConfirmationClose = () => setState(state => ({...state, confirmationOpen: false}));
    const handleConfirmationSuccess = () => {
        API.post('/customers/accountStatuses', formData)
        .then(result => {
            if(result.data.errors) {
                setState(state => ({...state, formErrors: formatValidationErrors(result.data.errors), confirmationOpen: false}));
            } else {
                getComponentData();
            }
        });
    }
    const getComponentData = () => {
        Promise.all([
            API.get('/staff/my/access/check/customer-account-statuses'), 
            API.get('/staff/my/access/check/customer-account-statuses:add-account-status'),
            API.get('/customers/accountStatuses')
        ])
        .then(([accountRes, addAccountRes, result]) => {
            setState(state => ({
                ...state,
                formData: {
                    name: '',
                    allowQuotes: false,
                    allowOrders: false
                },
                access: {
                    customerAccountStatuses: accountRes.data.has_access,
                    addCustomerAccountStatuses: addAccountRes.data.has_access
                },
                confirmationOpen: false,
                data: result.data,
                isLoading: false
            }));
        });
    }
    useEffect(() => {
        getComponentData();
    }, [])
    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5">
                    Account Statuses
                </Typography>
            </Grid>
            {isLoading ? (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            ) : (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Grid container item spacing={3}>
                            {access.addCustomerAccountStatuses && 
                                <Grid item xs={12} md={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Add Account Status
                                        </Typography>
                                        <form noValidate autoComplete="off">
                                            <TextField 
                                                id="name"
                                                name="name"
                                                label="Name *"
                                                value={formData.name}
                                                onChange={handleTextChange}
                                                error={formErrors && formErrors['name'] && true}
                                                helperText={formErrors && formErrors['name']}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <Typography variant="subtitle1" className={classes.settingsHeading}>Settings</Typography>
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            id="allowQuotes"
                                                            checked={formData.allowQuotes} 
                                                            onChange={handleCheckbox} 
                                                            value={!formData.allowQuotes}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Allow Quotes"
                                                />
                                            </FormGroup>
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            id="allowOrders"
                                                            checked={formData.allowOrders} 
                                                            onChange={handleCheckbox} 
                                                            value={!formData.allowOrders}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Allow Orders"
                                                />
                                            </FormGroup>
                                            <div className="buttonRow">
                                                <Button
                                                    onClick={handleConfirmationOpen}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={!formData.name}
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                            <ConfirmationDialog 
                                                open={confirmationOpen}
                                                success={handleConfirmationSuccess}
                                                close={handleConfirmationClose}
                                                title="Add a New Account Status?"
                                                message="Are you sure you want to add a new account status?"
                                            />
                                        </form>
                                    </PaddedPaper>
                                </Grid>
                            } 
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <CustomerAccountStatusList 
                                isLoading={isLoading}
                                statusList={data}
                            />
                        </PaddedPaper>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
}