import React       from 'react';
import API         from 'API';
import _           from 'lodash';
import { connect } from 'react-redux';

import DataTable     from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AllIcon       from 'Components/Common/Icons/AllIcon';

import { Checkbox, Typography, Grid, Button, ButtonGroup } from '@material-ui/core';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

const initialState = {
    stock:          [],
    table:          'All',
    isLoading:      true,
    correctedStock: false
}


class LowInStock extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }   

    componentDidMount() {
        this.getStockLevels();
    }

    getStockLevels = () => {
        API.get(`/stock/all`)
        .then(res => {
            this.setState({
                stock: _.map(res.data, i => ({
                    image:             i.part.default_image?.thumbnail_file_path,
                    num:               i.part.part_number,
                    desc:              i.part.part_description,
                    current:           parseFloat(i?.stock_current_qty),
                    pending:           parseFloat(i?.stock_pending),
                    predicted:         parseFloat(i?.stock_predicted),
                    lowStockLevel:     parseFloat(i?.stock_minimum_level),
                    highStockLevel:    parseFloat(i?.stock_maximum_level),
                    diff:              parseFloat(parseFloat(i?.stock_predicted) - parseFloat(i?.stock_minimum_level)),
                    part:              i.part.part_id,
                    level:             i?.stock_level,
                    predictedLevel:    i?.stock_level_predicted,
                    type:              i?.stock_level_predicted === -1 ? 'Low' : i?.stock_level_predicted === 1 ? 'High' : 'Normal',
                    activeProcurement: i?.part.active_parts_ordering_tracker_count > 0
                })), 
                isLoading: false
            });
        });
    }

    changeTable = (table) => () => {
        this.setState({ table },  this.resetChecked);
    } 

    createProcurement = rowData => {
        API.post(`/partsOrdering/tracker`, {part: rowData.part})
        .then(this.getStockLevels)
    }

    render() {
        const {isLoading, table, stock } = this.state;

        if ( isLoading ) return ( <LoadingCircle /> );

        let rows = table === 'All' ? stock : _.filter(stock, {type: table})

        return (
            <Grid container spacing={3} >
               <Grid item xs={12}>
                    <Grid container spacing={2} style={{alignItems: 'center', alignContent: 'center'}}>
                        <Grid item>
                            <ButtonGroup variant='outlined'>
                                <Button onClick={this.changeTable('All')} color={table === 'All' && 'primary'} variant={table === 'All' && 'contained'} >All Levels</Button>
                                <Button onClick={this.changeTable('Low')} color={table === 'Low' && 'primary'} variant={table === 'Low' && 'contained'} >Low Stock</Button>
                                <Button onClick={this.changeTable('Normal')} color={table === 'Normal' && 'primary'} variant={table === 'Normal' && 'contained'} >Correct Stock</Button>
                                <Button onClick={this.changeTable('High')} color={table === 'High' && 'primary'} variant={table === 'High' && 'contained'} >High Stock</Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item><Typography variant='body2' style={{color: colors.disabled}}><AllIcon color={colors.disabled} icon={icons.info}/> Levels based on predicted stock</Typography></Grid>
                    </Grid>
               </Grid>
               <Grid item xs={12}>
                    <DataTable
                        config={{
                            key:         r => `${r.type}${r.checkId}`,
                            filter:     true,
                            filterMod:  {
                                clearable:              true,
                            },
                            sticky: true,
                        }}
                        columns={_.filter([
                            {
                                field:          'image',
                                fieldFormat:    'image',
                                sizeToContent:  true,
                                filter:         'custom',
                                filterMod:      {
                                                    dataRef:    (search, i) => {return `${i.num}|${i.desc}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                                    label:      'Keywords',
                                                    styleGrid:  {width: '50%'},
                                                }
                            },
                            {
                                heading: 'Part/Description',
                                field:   i => <>{i.num}<br/>{i.desc}</>,
                                dataRef: 'num',
                                truncate: true
                            },
                            {
                                heading:       'Minimum Stock',
                                field:         'lowStockLevel',
                                dataRef:       'lowStockLevel',
                                sizeToContent: true,
                                alignment:     'center',
                            },
                            {
                                heading:       'Maximum Stock',
                                field:         'highStockLevel',
                                dataRef:       'highStockLevel',
                                sizeToContent: true,
                                alignment:     'center',
                            },
                            {
                                heading:       'Current Stock',
                                field:         'current',
                                dataRef:       'current',
                                fieldFormat:   'decimal:3',
                                alignment:     'right',
                                sizeToContent: true
                            },
                            {
                                heading:       '',
                                sizeToContent: true,
                                field:         i => 
                                                <AllIcon
                                                    color={!i.level ? colors.green : (i.level > 0 ? colors.orange : colors.red )}
                                                    icon={!i.level ? icons.stockLevelCorrect : ( i.level > 0 ? icons.stockLevelHigh : icons.stockLevelLow )}
                                                    noMargin
                                                />,
                            },
                            {
                                heading:       'Pending Stock',
                                field:         'pending',
                                dataRef:       'pending',
                                fieldFormat:   'decimal:3',
                                alignment:     'right',
                                sizeToContent: true
                            },
                            {
                                heading:       'Predicted Stock',
                                field:         'predicted',
                                dataRef:       'predicted',
                                fieldFormat:   'decimal:3',
                                alignment:     'right',
                                sizeToContent: true
                            },
                            {
                                heading:       '',
                                sizeToContent: true,
                                field:         i => 
                                                <AllIcon
                                                    color={!i.predictedLevel ? colors.green : (i.predictedLevel > 0 ? colors.orange : colors.red )}
                                                    icon={!i.predictedLevel ? icons.stockLevelCorrect : ( i.predictedLevel > 0 ? icons.stockLevelHigh : icons.stockLevelLow )}
                                                    noMargin
                                                />,
                            },
                            {
                                actions: rowData => ([
                                    {
                                        name: 'View',
                                        icon: icons.search,
                                        link: `/parts/view/${rowData.part}`,
                                    }, 
                                    {
                                        name: 'Create Procurement',
                                        icon: icons.partsOrdering,
                                        onClick: this.createProcurement,
                                        disabled: rowData.activeProcurement || rowData.predictedLevel === 0
                                    }
                                ])
                            }
                        ], i => !!i)}
                        rows={_.orderBy(rows, 'num', 'asc')}
                    />
               </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) { return { deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)) } };

export default connect(null, mapDispatchToProps)(LowInStock);