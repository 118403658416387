import React, { useEffect, useState }   from 'react';
import { useDispatch }                  from 'react-redux';
import API                              from 'API';

import { formatValidationErrors }       from 'Helpers/ErrorHelper';

import Grid                             from '@material-ui/core/Grid';
import Typography                       from '@material-ui/core/Typography';
import DialogContent                    from '@material-ui/core/DialogContent';
import Button                           from '@material-ui/core/Button';
import FormControl                      from '@material-ui/core/FormControl';


import LoadingCircle                    from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect               from 'Components/Common/Selects/AutoCompleteSelect';
import CreditNote                       from 'Components/CustomerReturns/ViewCreditNote/CreditNote';
import Textarea                         from 'Components/Common/Inputs/Textarea';
import EmailDialog                      from 'Components/Common/Dialogs/EmailCustomerReturnsDialog';

import { deployConfirmation }           from 'Actions/Confirmation/Confirmation';
import { deploySnackBar }               from 'Actions/SnackBar/SnackBar';
import { closeDialog, deployDialog }    from 'Actions/Dialog/Dialog';



const AddNoteDialog = ({cn_ref, callback, note, noteId}) => {

    const dispatch = useDispatch();

    const initialState = {
        formData: {
            notes:      note    || '',
            type:       'credit-notes',
        },
        formErrors:         {},
        isLoading:          false
    };
    let [state, setState] = useState(initialState);

    const {formData, formData:{notes, type}, formErrors,  isLoading} = state;

    const handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleClose = () => dispatch(closeDialog());

    const handleSuccess = () => {
        setState(state => ({...state,isLoading: true}));
        const uri = (noteId) ? `/misc/notes/${noteId}` : '/misc/notes';
        API.post(uri, {notes, type, id: cn_ref})
        .then(res => {
            if (res.data.success){
                handleClose();
                callback();
            }
        });
    }

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Textarea
                                id="notes"
                                name="notes"
                                label="Notes"
                                value={notes}
                                rows={5}
                                error={formErrors && formErrors['notes']}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button onClick={handleClose} variant="outlined"  color="default">Close</Button>
                        <Button onClick={handleSuccess} variant="contained" color="primary" disabled={!notes.length}>{noteId ? 'Update' : 'Add'}</Button>
                    </Grid>
                </DialogContent>
            )}
        </React.Fragment>
    );

}

export default AddNoteDialog;