import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, ButtonGroup, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import API from 'API';
import _ from 'lodash';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconHelper from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';


export default function EmailPreviewDialog({open, onClose, emailPrefill=[], emailData}) {
    
    const [formData, setFormData] = React.useState({
        type: 'staff',
        customerEmail: '',

        emails: [
            ...emailPrefill
        ],
    });

    const [staffList, setStaffList] = React.useState([]);

    if (staffList.length < 1){
        API.get('/staff/all', {params: {searchStatus: 'active'}}).then(res => {
            setStaffList(res.data);
        })
    }

    const sendEmailPreview = () => {
        let emails = formData.emails;
        emailData.ref.current.editor.exportHtml((data) => {
            const {  html } = data;
            let formData = new FormData();
            formData.append('email', JSON.stringify(emails));
            formData.append('subject', emailData.subject ? `Preview - ${emailData.subject}` : 'Email Preview');
            formData.append('html', html);
            API.post(`/marketing/campaigns/emailCampaign/emailPreview`, formData).then(onClose)
        });
    }

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth='sm'
        >
            <DialogTitle>
                Email Preview
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1} style={{alignItems: 'center'}}>
                    <Grid item xs={12}>
                        <ButtonGroup
                            fullWidth
                        >
                            <Button
                                onClick={()=>setFormData({...formData, type: 'staff'})}
                                variant={formData.type === 'staff' ? 'contained' : 'outlined'}
                                color={formData.type === 'staff' ? 'primary' : 'default'}
                            >Staff</Button>
                            <Button
                                onClick={()=>setFormData({...formData, type: 'custom'})}
                                variant={formData.type === 'custom' ? 'contained' : 'outlined'}
                                color={formData.type === 'custom' ? 'primary' : 'default'}
                            >custom</Button>
                        </ButtonGroup>
                    </Grid>
                    {formData.type === 'custom' &&
                        <>
                            <Grid item xs={11}>
                                <TextField
                                    label="Email *"
                                    value={formData?.customerEmail}
                                    onChange={e=>setFormData({...formData, customerEmail: e.target.value})}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <AllIcon
                                    noMargin
                                    icon={IconHelper.plus}
                                    onClick={() => {
                                        setFormData({...formData, emails: [...formData.emails, formData.customerEmail], customerEmail: ''})
                                    }}
                                />
                            </Grid>
                        </>
                    }   
                    {formData.type === 'staff' &&
                        <Grid item xs={12}>
                            <AutoCompleteSelect
                                label="Staff *"
                                options={_.map(_.filter(staffList, s =>!formData.emails.includes(s.staff_email)), s => ({ label: `${s.staff_first_name} ${s.staff_last_name} (${s.staff_email})`, value: s.staff_email }))}
                                noClear
                                fullWidth
                                onChange={e => setFormData({...formData, emails: [...formData.emails, e.value]})}
                            />
                        </Grid>
                    }
                    {
                        formData.emails.length > 0 &&
                        <Grid item xs={12}>
                            <List>
                                {_.map(formData.emails, e => 
                                    <ListItem
                                        style={{
                                            borderBottom: `1px solid ${colors.border}`,
                                        }}
                                    >
                                        <ListItemText primary={e} />
                                        <ListItemSecondaryAction>
                                            <AllIcon
                                                icon={IconHelper.bin}
                                                noMargin
                                                onClick={() => _.remove(formData.emails, e)}    
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>    
                                )}
                            </List>
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={onClose}
                >Close</Button>
                <Button
                    variant="contained"
                    color='primary'
                    onClick={sendEmailPreview}
                    disabled={formData.emails.length < 1}
                >Send</Button>
            </DialogActions>
        </Dialog>
    )
}