import React from 'react';
import { Box, Grid, Button} from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea from 'Components/Common/Inputs/Textarea';
import API from 'API';

const initialState = {
    formData: {
        type: null,
        notes: null
    }
}

const options = [
    { label: 'Created Notes', value: 'Created Notes' },
    { label: 'Approval Notes', value: 'Approval Notes' },
    { label: 'Confirmation Notes', value: 'Confirmation Notes' },
    { label: 'Delivery Notes', value: 'Delivery Notes' },
    { label: 'Review Notes', value: 'Review Notes' },
    { label: 'Completed Notes', value: 'Completed Notes' },
    { label: 'Decline Notes', value: 'Decline Notes' },
    { label: 'Cancelled Notes', value: 'Canceled Notes' },
    { label: 'General Notes', value: 'Notes' },
]

class AddNotesDialog extends React.Component {
    constructor(props){
        super(props)
        this.state = initialState;

    }

    handleSelectChange = name => event => {
        let formData = this.state.formData;
        formData[name] = event.value;
        this.setState({ formData });
    }

    handleChange = name => event => {
        let formData = this.state.formData;
        formData[name] = event.target.value;
        this.setState({ formData });
    }

    addNote = () => {
        API.post(`/customerReturns/returns/${this.props.cr.cr_id}/addNote`, this.state.formData)
        .then(()=>{
            this.setState(initialState);
            this.props.onClose();
        });
    }

    render(){

        const {formData} = this.state

        return (
            <Box>
                <Grid container spacing={1} >
                    <Grid item xs={12}>
                        <AutoCompleteSelect
                            options={options}
                            value={formData.type}
                            onChange={this.handleSelectChange('type')}
                            label='Note Type *'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Textarea
                            label='Notes *'
                            value={formData.notes}
                            onChange={this.handleChange('notes')}
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant='outlined'
                            onClick={this.props.onClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={this.addNote}
                            disabled={(!formData.type || !formData.notes)}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default AddNotesDialog;