import React, { Component } from 'react';
import _ from 'lodash';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';

import ActivityLog from 'Components/Common/ActivityLog/ActivityLog';

const typeTo = {
    'Created': {
        color: colors.yellow,
        icon:  icons.plus
    },
    'Added Sticky Note': {
        color: colors.blue,
        icon:  icons.stickyNote
    },
    'Amended': {
        color: colors.blue,
        icon:  icons.edit
    },
    'Confirmed': {
        color: colors.green,
        icon:  icons.truck
    },
    'Collection Inspected': {
        color: colors.blue,
        icon:  icons.view
    },
    'Approved': {
        color: colors.green,
        icon:  icons.true
    },
    'Inspection Complete': {
        color: colors.blue,
        icon:  icons.true
    },
    'Cancelled': {
        color: colors.red,
        icon:  icons.false
    },
    'Pushed Back To Collection': {
        color: colors.red,
        icon:  icons.refresh
    },
    'Review Complete': {
        color: colors.blue,
        icon:  icons.review
    },
    'Declined': {
        color: colors.red,
        icon:  icons.false
    },
    'Completed': {
        color: colors.yellow,
        icon:  icons.trophy
    },
    'Notes Added': {
        color: colors.blue,
        icon:  icons.notes
    },
    'Outcome Added': {
        color: colors.blue,
        icon:  icons.plus
    },
    'Credit Note Added':{
        color: colors.blue,
        icon:  icons.creditNote
    },
    'Order Added':{
        color: colors.blue,
        icon:  icons.sales
    },
    'File Uploaded':{
        color: colors.blue,
        icon:  icons.upload
    },
    'Email Sent': {
        color: colors.blue,
        icon:  icons.email
    },
    'Collection Received': {
        color: colors.blue,
        icon:  icons.truck
    },
    'Collection Complete': {
        color: colors.blue,
        icon:  icons.truck
    },
    'Parts Written Off': {
        color: colors.red,
        icon:  icons.broken
    },
    'Deleted Sticky Note': {
        color: colors.red,
        icon:  icons.stickyNote
    }
}

class ViewReturn extends Component {   
    render() {

        const { cr } = this.props;

        return (
            <ActivityLog 
                fields={{
                    type:   't',
                    date:   'd',
                    icon:   { color: 'c', icon: 'i' },
                    text:   'n',
                    staff:  's',
                }}
                atl={_.map(cr?.logs, i => ({
                    t:  i.crl_type,
                    d:  i.crl_datetime,
                    c: typeTo[i.crl_type]?.color,
                    i:  typeTo[i.crl_type]?.icon,
                    n:  i.crl_notes,
                    s: i.crl_staff,
                }))}
                disableTabBar
            />
        )
    }
}

export default ViewReturn;
