import { Card, CardContent, CardHeader, Grid, InputAdornment, Link, Table, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import API from 'API';
import BarChart from 'Components/Common/Charts/BarChart';
import PieChart from 'Components/Common/Charts/PieChart';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import InfoIcon from 'Components/Common/Icons/InfoIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { getColorCurrencyChange, getColorPercentageChange, getColorQtyChange, getCurrency, getPlainQty, months } from 'Helpers/ReportsHelper';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import MonthsBlock from './MonthsBlock';
import { getOrdinal } from 'Helpers/TimeHelper';

const initialState = (props={}) => ({
    isLoading: true,
    data: [],
    view: { value: 'year', allowCurrent: props.current },
    showCurrent: props.current,

    partSearch: '',
})

class partsReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.year !== this.props.year) {
            this.setState(initialState(this.props), this.getData);
        }
    }

    getData = () => {
        this.setState({isLoading: true}, ()=>{
            let params = {params: {cid:this.props.cid, partId:this.props.partId, year:this.props.year, current: this.props.current}}
            if (this.state.view.value !== 'year') {
                params.params.month = this.state.view.value;
            }
            API.get('/reports/yoysReport/parts', params)
            .then(response => {
                this.setState({
                    data: response.data, 
                    isLoading: false
                });
            })
        });
    }

    setView = (view) => () => {
        this.setState({view}, this.getData);
    }


    render() {

        const {isLoading, data, view, showCurrent} = this.state;
        const {year, current} = this.props;

        let
            currentYear = `${year-1}/${year}`,
            lastYear = `${year-2}/${year-1}`;
        if (view.value !== 'year') {
            currentYear = `${months[view.value]} ${view.value<10 ? year : (year-1)}`;
            lastYear = `${months[view.value]} ${(view.value<10 ? year : (year-1)) - 1}`;
        }

        let mod = view.allowCurrent && showCurrent ? 'c' : 't';

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MonthsBlock {...this.props} view={view} showCurrent={showCurrent}
                        setCurrentView={(showCurrent,v)=>{this.setState({ showCurrent }, this.setView(v))}}
                        setView={v=>this.setView(v)()}
                    />
                </Grid>
                {/* <Grid item xs={12}  style={{marginTop:0}}>
                    <Grid container spacing={1}>
                        {_.map(views, v => 
                            <>
                                
                                {(v.allowCurrent) ?
                                    <>
                                        <Grid item  style={{marginLeft:v.value !== 'year' && 'auto' }}>
                                            <Button 
                                                variant={view.value === v.value ? (showCurrent ? 'contained' : 'outlined') : 'outlined'}
                                                color={view.value === v.value ? (showCurrent ? 'primary' : 'default') : 'default'}
                                                onClick={()=>this.setState({ showCurrent: true },this.setView(v))}
                                            >
                                                {v.value !== 'year' ?
                                                    `${v.label} Until ${getOrdinal(moment().format('D'))} ${v.label}`  :
                                                    `${v.label} Until ${getOrdinal(moment().format('D'))} ${moment().format('MMMM')}`
                                                }
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button 
                                                variant={view.value === v.value ? (!showCurrent ? 'contained' : 'outlined') : 'outlined'}
                                                color={view.value === v.value ? (!showCurrent ? 'primary' : 'default') : 'default'}
                                                onClick={()=>this.setState({ showCurrent: false },this.setView(v))}
                                            >
                                                {v.label}
                                            </Button>
                                        </Grid> 
                                    </> :
                                    <Grid item style={{marginRight: v.value === 'year' && 'auto' }}> 
                                        <Button
                                            variant={view.value === v.value ? 'contained' : 'outlined'}
                                            color={view.value === v.value ? 'primary' : 'default'}
                                            onClick={this.setView(v)}
                                        >
                                            {v.label}
                                        </Button>
                                    </Grid>    
                                }
                            </>
                        )}
                    </Grid>
                </Grid> */}
                {isLoading ?
                    <Grid item xs={12}>
                        <LoadingCircle/>
                    </Grid> :
                    <>
                        <Grid item xs={3}>
                            <PaddedPaper style={{padding: 10}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} style={{textAlign:'center'}}>
                                        <Grid container spacing={1}>    
                                            <Grid item xs={2}></Grid>
                                            <Grid item xs={8}>
                                                <Typography variant="h6" style={{textAlign:'center'}}>
                                                    {currentYear}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{textAlign: 'right'}}>
                                                <InfoIcon placement='top' info=
                                                    {<Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            Pie Chart Showing Quantity Sold
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <div style={{height: 15, width: 15, borderRadius: 15, backgroundColor: `${colors.green}75`}}></div>
                                                        </Grid>
                                                        <Grid item xs={11}>Products</Grid>
                                                        <Grid item xs={1}>
                                                            <div style={{height: 15, width: 15, borderRadius: 15, backgroundColor: `${colors.primary}75`}}></div>
                                                        </Grid>
                                                        <Grid item xs={11}>Services</Grid>
                                                    </Grid>} 
                                                    />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PieChart 
                                            data={[
                                                {label: 'Parts', value:  data[mod].p.c.q, color: colors.green},
                                                {label: 'Services', value: data[mod].s.c.q, color: colors.primary},
                                            ]} 
                                            style={{ height: 200 }}
                                            hideLegend
                                        />
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={3}>
                            <PaddedPaper style={{padding: 10}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} style={{textAlign:'center'}}>
                                        <Grid container spacing={1}>    
                                            <Grid item xs={2}></Grid>
                                            <Grid item xs={8}>
                                                <Typography variant="h6" style={{textAlign:'center'}}>
                                                    {lastYear}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{textAlign: 'right'}}>
                                                <InfoIcon placement='top' info=
                                                    {<Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            Pie Chart Showing Quantity Sold
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <div style={{height: 15, width: 15, borderRadius: 15, backgroundColor: `${colors.green}75`}}></div>
                                                        </Grid>
                                                        <Grid item xs={11}>Products</Grid>
                                                        <Grid item xs={1}>
                                                            <div style={{height: 15, width: 15, borderRadius: 15, backgroundColor: `${colors.primary}75`}}></div>
                                                        </Grid>
                                                        <Grid item xs={11}>Services</Grid>
                                                    </Grid>} 
                                                    />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PieChart 
                                            data={[
                                                {label: 'Parts', value:  data[mod].p.l.q, color: `${colors.green}75`},
                                                {label: 'Services', value: data[mod].s.l.q, color: `${colors.primary}75`},
                                            ]} 
                                            style={{ height: 200 }}
                                            hideLegend
                                        />
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={6}>
                            <PaddedPaper style={{padding: 10}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} style={{textAlign:'center'}}>
                                        <Grid container spacing={1}>    
                                            <Grid item xs={2}></Grid>
                                            <Grid item xs={8}>
                                                <Typography variant="h6" style={{textAlign:'center'}}>
                                                    {currentYear} vs. {lastYear}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{textAlign: 'right'}}>
                                                <InfoIcon placement='top' info=
                                                    {<Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            Bar Chart Showing Net (total before vat) £
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <div style={{height: 15, width: 15, borderRadius: 15, backgroundColor: colors.green}}></div>
                                                        </Grid>
                                                        <Grid item xs={11}>Products</Grid>
                                                        <Grid item xs={1}>
                                                            <div style={{height: 15, width: 15, borderRadius: 15, backgroundColor: colors.primary}}></div>
                                                        </Grid>
                                                        <Grid item xs={11}>Services</Grid>
                                                    </Grid>} 
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BarChart
                                            data={[
                                                {   
                                                    label: currentYear,   
                                                    value: {
                                                        parts: parseFloat(data[mod].p.c.t),
                                                        services: parseFloat(data[mod].s.c.t)
                                                    },
                                                    color: {
                                                        parts: colors.green,
                                                        services: colors.primary
                                                    }
                                                },
                                                {
                                                    label: lastYear,   
                                                    value: {
                                                        parts: parseFloat(data[mod].p.l.t),
                                                        services: parseFloat(data[mod].s.l.t)
                                                    },
                                                    color: {
                                                        parts: `${colors.green}75`,
                                                        services: `${colors.primary}75`
                                                    }
                                                },
                                            ]}
                                            style={{ height: 200 }}
                                            hideLegend
                                            sidebar
                                            xLabel={(v)=> `${v<0?'-':''}£${parseInt(Math.abs(v))}`}
                                            tLabel={(e)=> getCurrency(e.raw)}
                                        />
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Card>
                                <CardHeader
                                    avatar={<AllIcon icon={ IconHelper.sales } size='medium' white />}
                                    title={
                                        <Grid container spacing={1} style={{alignItems: 'flex-end'}}>
                                            <Grid item >
                                                <>Parts Report</>
                                            </Grid>
                                            <Grid item >
                                                <Typography variant="body1" style={{paddingBottom: 1}} >{currentYear} vs. {lastYear} {(current && mod === 'c') ? ` Up Until ${getOrdinal(moment().format('D'))} ${moment().format('MMMM')}` : ''}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    titleTypographyProps={{variant:'h6'}}
                                    style={{
                                        background:'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)',
                                        color: '#FFF'
                                    }}      
                                />
                                <CardContent style={{padding:5}}>
                                    <Table size="small" aria-label="a dense table" style={{color:'black'}}>
                                        <TableHead style={{backgroundColor:'#f5f5f5'}}>
                                            <TableRow>
                                                <TableCell align='center' style={{width:'1%', borderBottom: 'none', color:'black'}}></TableCell>
                                                <TableCell align='center' style={{width:'6%', borderBottom: 'none', color:'black'}}></TableCell>
                                                <TableCell align='center' colSpan={4} style={{width:'46.5%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Qty</b></Typography></TableCell>
                                                <TableCell align='center' colSpan={4} style={{width:'46.5%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Net</b></Typography></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align='center' style={{width:'1%', borderBottom: 'none', color:'black'}}></TableCell>
                                                <TableCell align='center' style={{width:'6%', borderBottom: 'none', color:'black'}}></TableCell>
                                                <TableCell align='center' style={{width:'11.625%', borderBottom: 'none', color:'black'}}>
                                                    <b>{currentYear}</b>
                                                </TableCell>
                                                <TableCell align='center' style={{width:'11.625%', borderBottom: 'none', color:'black'}}>
                                                    <b>{lastYear}</b>
                                                </TableCell>
                                                <TableCell align='center' style={{width:'11.625%', borderBottom: 'none', color:'black'}}>
                                                    <b>Difference</b>
                                                </TableCell>
                                                <TableCell align='center' style={{width:'11.625%', borderBottom: 'none', color:'black'}}>
                                                    <b>Difference %</b>
                                                </TableCell>
                                                <TableCell align='center' style={{width:'11.625%', borderBottom: 'none', color:'black'}}>
                                                    <b>{currentYear}</b>
                                                </TableCell>
                                                <TableCell align='center' style={{width:'11.625%', borderBottom: 'none', color:'black'}}>
                                                    <b>{lastYear}</b>
                                                </TableCell>
                                                <TableCell align='center' style={{width:'11.625%', borderBottom: 'none', color:'black'}}>
                                                    <b>Difference</b>
                                                </TableCell>
                                                <TableCell align='center' style={{width:'11.625%', borderBottom: 'none', color:'black'}}>
                                                    <b>Difference %</b>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {_.map([{
                                            icon: IconHelper.products,
                                            label: 'Products',
                                            k: 'p'
                                        },
                                        {
                                            icon: IconHelper.services,
                                            label: 'Services',
                                            k: 's'
                                        },
                                        {
                                            icon: IconHelper.total,
                                            label: 'Total',
                                            k: 't'
                                        }], ({icon, label, k}) => 
                                            <TableRow style={{
                                                color: '#000',
                                                backgroundColor: '#FFF',
                                            }} sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center'}}>
                                                    <AllIcon icon={icon} size='small'/> 
                                                </TableCell>
                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center'}}>
                                                    <Typography variant="body1" style={{textTransform: 'capitalize'}}>{label}</Typography>
                                                </TableCell>
                                                <TableCell align='center' style={{color:'black'}}>
                                                    {getPlainQty(data[mod][k].c.q)}
                                                </TableCell>
                                                <TableCell align='center' style={{color:'black'}}>
                                                    {getPlainQty(data[mod][k].l.q)}
                                                </TableCell>
                                                <TableCell align='center' style={{color:'black'}}>
                                                    {getColorQtyChange(data[mod][k].c.q, data[mod][k].l.q)}
                                                </TableCell>
                                                <TableCell align='center' style={{color:'black'}}>
                                                    {getColorPercentageChange(data[mod][k].c.q, data[mod][k].l.q)}
                                                </TableCell>
                                                <TableCell align='center' style={{color:'black'}}>
                                                    {getCurrency(data[mod][k].c.t)}
                                                </TableCell>
                                                <TableCell align='center' style={{color:'black'}}>
                                                    {getCurrency(data[mod][k].l.t)}
                                                </TableCell>
                                                <TableCell align='center' style={{color:'black'}}>
                                                    {getColorCurrencyChange(data[mod][k].c.t , data[mod][k].l.t )}
                                                </TableCell>
                                                <TableCell align='center' style={{color:'black'}}>
                                                    {getColorPercentageChange(data[mod][k].c.t , data[mod][k].l.t )}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField 
                                placeholder='Search Part...'
                                fullWidth
                                variant="outlined"
                                value={this.state.partSearch}
                                onChange={(e)=>this.setState({partSearch: e.target.value})}
                                inputProps={{
                                    style: {padding: '10px'},
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment  position="end">
                                            <AllIcon icon={IconHelper.refresh} size='small' onClick={()=>this.setState({partSearch: ''})} noMargin/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CiDataTable
                            config={{
                                    key: 'i',
                                    pagination: false,
                                    alternatingRowColours: true,
                                    responsiveimportant: true,
                                    isLoading: isLoading,
                                    sticky:true,
                                }}
                                rows={_.orderBy(
                                    _.filter(
                                            this.state.partSearch ?
                                            _.filter(data.p, i => i.p.n.toLowerCase().includes(this.state.partSearch.toLowerCase()) || i.p.d.toLowerCase().includes(this.state.partSearch.toLowerCase())) :
                                            data.p, 
                                        i => ( parseFloat(i[mod].t.l) !== 0 || parseFloat(i[mod].t.c) !== 0 ) || (parseFloat(i[mod].q.l) !== 0 || parseFloat(i[mod].q.c) !== 0) ), 
                                    i => i.p.n
                                , 'asc')}
                                columns={[
                                    {
                                        heading: '',
                                        field: 'p.p',
                                        fieldFormat: 'image',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Part',
                                        field: 'p.n',
                                        field: i => 
                                        < >
                                            <Grid container spacing={0}
                                               onClick={()=>{
                                                    this.props.history.push(`/parts/view/${i.p.i}`);
                                                }}
                                                style={{cursor: 'pointer'}}
                                            >
                                                <Grid item xs={12}>
                                                    <Typography variant="body1">{i.p.n}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption">{i.p.d}</Typography>
                                                </Grid>
                                            </Grid>
                                        </>,
                                        dataRef: 'p.n',
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Part Type',
                                        field: 'p.t',
                                        dataRef: 'p.t',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: '',
                                        field: () => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{currentYear}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{lastYear}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>Difference</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>Difference %</Typography>
                                                </Grid>
                                            </Grid>,
                                        sizeToContent: true
                                    },
                                    ..._.map([
                                        {
                                            heading: 'Total cost',
                                            k: 'c',
                                            avg: false 
                                        },
                                        {
                                            heading: 'Avg. cost',
                                            k: 'c',
                                            avg: true 
                                        },
                                        {
                                            heading: 'Total Net',
                                            k: 't',
                                            avg: false 
                                        },
                                        {
                                            heading: 'Avg. Net',
                                            k: 't',
                                            avg: true 
                                        },
                                    ], ({heading, k, avg}) => 
                                        (
                                            {
                                                heading: heading,
                                                sizeToContent: true,
                                                dataRef: `${mod}.${k}.c`,
                                                field: i => avg ?
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(i[mod][k].c / i[mod].q.c)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(i[mod][k].l / i[mod].q.l)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}> 
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange((i[mod][k].c / i[mod].q.c), (i[mod][k].l / i[mod].q.l))}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange((i[mod][k].c / i[mod].q.c), (i[mod][k].l / i[mod].q.l))}</Typography>
                                                        </Grid>
                                                    </Grid> :
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(i[mod][k].c)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(i[mod][k].l)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}> 
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange(i[mod][k].c, i[mod][k].l )}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(i[mod][k].c, i[mod][k].l )}</Typography>
                                                        </Grid>
                                                    </Grid>,
                                            }
                                        )
                                    ),
                                    {
                                        heading: 'qty',
                                        field: i => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{parseFloat(i[mod].q.c).toFixed(3)}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{parseFloat(i[mod].q.l).toFixed(3)}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorQtyChange(parseFloat(i[mod].q.c), parseFloat(i[mod].q.l))}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(parseFloat(i[mod].q.c), parseFloat(i[mod].q.l))}</Typography>
                                                </Grid>
                                            </Grid>,
                                        dataRef: `${mod}.q.c`,
                                        sizeToContent: true
                                    }
                                ]}
                            />
                        </Grid>
                    </>    
            }
            </Grid>
        )
    }
}

export default partsReport;