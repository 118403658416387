import React from "react";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import API from "API";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import CiDataTable from "Components/Common/DataTables/CiDataTable";
import DatePicker from "Components/Common/DatePickers/DatePicker";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import TaskDialog from "Components/Tasks/Misc/TaskDialog";
import NotificationDialog from "Components/Common/Dialogs/NotificationDialog";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import WildixCallAssignment from "Components/Common/Voip/WildixCallAssignment";
import WildixCallNotes from "Components/Common/Voip/WildixCallNotes";
import WildixCallRecordings from "Components/Common/Voip/WildixCallRecording";
import WildixCallTranscription from "Components/Common/Voip/WildixCallTranscription";
import { deployDialog } from "Actions/Dialog/Dialog";
import { Dial } from "Functions/MiscFunctions";
import { colors } from "Helpers/ColourHelper";
import { deploySnackBar } from "Actions/SnackBar/SnackBar";
import { deployConfirmation } from "Actions/Confirmation/Confirmation";
import InfoIcon from "Components/Common/Icons/InfoIcon";

const initialState = (inPage) => ({
    access: {
        callRec: false,
        viewAddress: false,
        viewCustomer: false,
        viewSupplier: false,
    },
    callNotes: null,
    dataLoading: true,
    results: [],
    searchDtFrom: moment()
        .subtract(1, inPage ? "month" : "day")
        .format("YYYY-MM-DD"),
    searchDtTo: moment().format("YYYY-MM-DD"),
    searchExInt: true,
    searchUasOnly: false,
    searchKeywords: "",
    searchSite: null,
    searchStaff: "",
    searchType: "ALL",
    sites: [],
    staff: [],
});

class CallHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(this.props.inPage);
        this.timeout = null;
    }

    componentDidMount = () => {
        Promise.all([
            API.get("/staff/my/access/check/call-rec"),
            API.get("/staff/my/access/check/view-customer-address"),
            API.get("/staff/my/access/check/view-customer"),
            API.get("/staff/my/access/check/view-supplier"),
            API.get("/staff/all", {
                params: {
                    active: true,
                },
            }),
        ]).then(([callRec, viewAddress, viewCustomer, viewSupplier, staffList]) => {
            const staff = _.map(staffList.data, (staff) => {
                return _.assign({
                    value: staff.staff_id,
                    label: `${staff.staff_first_name} ${staff.staff_last_name} (${staff.staff_email})`,
                });
            });
            this.setState(
                {
                    access: {
                        ...this.state.access,
                        callRec: callRec?.data?.has_access ?? false,
                        viewAddress: viewAddress?.data?.has_access ?? false,
                        viewCustomer: viewCustomer?.data?.has_access ?? false,
                        viewSupplier: viewSupplier?.data?.has_access ?? false,
                    },
                    staff,
                },
                () => {
                    this.getSearchData();
                    this.getCustomerSites();
                }
            );
        });
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.customerId !== this.props.customerId || prevProps.siteId !== this.props.siteId || prevProps.supplierId !== this.props.supplierId) {
            this.setState(
                {
                    ...initialState(this.props.inPage),
                    access: this.state.access,
                    staff: this.state.staff,
                },
                () => {
                    this.getSearchData();
                    this.getCustomerSites();
                }
            );
        }
    };

    componentWillUnmount = () => {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    };

    getCustomerSites = () => {
        const { customerId, siteId } = this.props;
        if (customerId && !siteId) {
            API.get(`/customers/${customerId}/addresses`, {
                params: {
                    type: "Site",
                },
            }).then((result) => {
                this.setState({
                    sites: _.map(result.data, (site) => {
                        return {
                            value: site.address_id,
                            label: site.address_name,
                        };
                    }),
                });
            });
        } else {
            this.setState({
                sites: [],
            });
        }
    };

    getSearchData = () => {
        const { customerId, siteId, supplierId } = this.props;
        const { searchDtFrom, searchDtTo, searchExInt, searchUasOnly, searchKeywords, searchSite, searchStaff, searchType } = this.state;

        let params = {
            dateFrom: searchDtFrom,
            dateTo: searchDtTo,
            excludeInternal: searchExInt ? 1 : null,
            unassignedOnly: searchUasOnly ? 1 : null,
            keywords: searchKeywords,
            staff: searchStaff,
            type: searchType,
        };

        if (customerId) {
            params.customer = customerId;
        }

        if (siteId) {
            params.site = siteId;
        } else if (searchSite) {
            params.site = searchSite;
        }

        if (supplierId) {
            params.supplier = supplierId;
        }

        this.setState(
            {
                dataLoading: true,
            },
            () => {
                API.get("/voip", {
                    params,
                }).then((result) => {
                    if (result?.data) {
                        this.setState({
                            dataLoading: false,
                            results: result.data,
                        });
                    }
                });
            }
        );
    };

    handleNotesDialog = (notes) => {
        let callNotes = notes;
        if (callNotes) {
            callNotes = notes?.split?.("\n").map((item, key) => (
                <Box
                    pb={key !== _.size(notes?.split?.("\n")) - 1 ? 1 : undefined}
                    key={key}
                    style={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        overflow: "hidden",
                    }}
                >
                    {item}
                </Box>
            ));
        }
        this.setState({
            callNotes,
        });
    };

    handleNotesUpdate = (id, addNotes) => {
        this.props.deployDialog(<WildixCallNotes callback={this.getSearchData} id={id} />, `${addNotes ? "Add" : "Update"} Call Notes`, "standard", "xs");
    };

    handleReAssignCall = (id) => {
        this.props.deployDialog(<WildixCallAssignment callback={this.getSearchData} id={id} />, "Update Call Assignment", "standard", "md");
    };

    handleTaskStatusClass = (status) => {
        switch (status) {
            case "Completed":
                return "task-completed";
            case "In Progress":
                return "task-in-progress";
            case "New":
                return "task-new";
            default:
                return undefined;
        }
    };

    onSearchChange = (fieldName, _value) => {
        let value = _value;
        if (fieldName === "searchDtTo" || fieldName === "searchDtFrom") {
            value = value ? moment(value).format("YYYY-MM-DD") : null;
        }
        this.setState(
            {
                [fieldName]: value,
            },
            () => {
                this.handleSetSearch();
            }
        );
    };

    resetSearch = () => {
        this.setState(
            {
                ...initialState(),
                access: this.state.access,
                sites: this.state.sites,
                staff: this.state.staff,
            },
            () => {
                this.getSearchData();
            }
        );
    };

    getIcon = (status) => {
        switch (status) {
            case "Answered":
                return <FAIcon icon="phone-volume" type="light" size={15} noMargin style={this.getStyle(status)} />;
            case "No Answer":
                return <FAIcon icon="phone-slash" type="light" size={15} noMargin style={this.getStyle(status)} />;
            default:
                return <FAIcon icon="voicemail" type="light" size={15} noMargin style={this.getStyle(status)} />;
        }
    };

    getStyle = (status) => {
        let style = {
            paddingLeft: 8,
            fontWeight: 400,
        };
        switch (status) {
            case "Answered":
                style.backgroundColor = "#d9eeda";
                style.color = "#000";
                break;
            case "No Answer":
                style.backgroundColor = "#f2dede";
                style.color = "#000";
                break;
            default:
                style.backgroundColor = "#fff";
                style.color = "#000";
                break;
        }
        return style;
    };

    handleDespatchCallRecordings = (id, ext, ca) => {
        this.props.deployDialog(<WildixCallRecordings id={id} />, `Extension ${ext} | ${moment(ca).format("DD/MM/YYYY HH:mm")}`, "standard", "xs");
    };

    handleDespatchCallTranscriptions = (id) => {
        this.props.deployDialog(<WildixCallTranscription id={id} />, "Call Transcription", "standard", "md");
    };

    handleDespatchTask = (id) => {
        this.props.deployDialog(<TaskDialog id={id} />, "", "standard", "md");
    };

    handleDeleteCallData = (id) => {
        API.put(`/voip/${id}/clean`).then((res) => {
            if (res?.data?.success) {
                this.props.deploySnackBar("success", "Call data deleted successfully");
                this.getSearchData();
            }
        });
    };

    handleSetSearch = () => {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.getSearchData();
        }, 300);
    };

    render = () => {
        const { customerId, inPage, siteId } = this.props;
        const {
            access,
            callNotes,
            dataLoading,
            results,
            searchDtFrom,
            searchDtTo,
            searchExInt,
            searchUasOnly,
            searchKeywords,
            searchSite,
            searchStaff,
            searchType,
            sites,
            staff,
        } = this.state;
        const PaperElement = inPage ? Box : PaddedPaper;
        const showSiteInput = customerId && !siteId && sites?.length;
        return (
            <Grid container spacing={3}>
                {!inPage && (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h5">Phone History</Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={6}>
                                        <DatePicker
                                            type={"date"}
                                            id="searchDtFrom"
                                            name="searchDtFrom"
                                            label="Date From"
                                            onChange={(v) => this.onSearchChange("searchDtFrom", v)}
                                            disableFuture
                                            maxDate={searchDtTo}
                                            value={searchDtFrom}
                                            autoOk={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            type={"date"}
                                            id="searchDtTo"
                                            name="searchDtTo"
                                            label="Date From"
                                            disableFuture
                                            minDate={searchDtFrom}
                                            value={searchDtTo}
                                            onChange={(v) => this.onSearchChange("searchDtTo", v)}
                                            autoOk={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <AutoCompleteSelect
                                                className={"main-shadow"}
                                                options={staff}
                                                label="Staff"
                                                value={searchStaff}
                                                onChange={(v) => this.onSearchChange("searchStaff", v?.value ?? "")}
                                                margin="none"
                                                adornment="sort"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <AutoCompleteSelect
                                                className={"main-shadow"}
                                                options={[
                                                    {
                                                        value: "ALL",
                                                        label: "All Calls",
                                                    },
                                                    {
                                                        value: "CUSTOMER",
                                                        label: "Customer Calls",
                                                    },
                                                    {
                                                        value: "SUPPLIER",
                                                        label: "Supplier Calls",
                                                    },
                                                ]}
                                                label="Show"
                                                value={searchType}
                                                onChange={(v) => this.onSearchChange("searchType", v?.value ?? "")}
                                                margin="none"
                                                adornment="sort"
                                                noClear
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Keywords"
                                            fullWidth
                                            value={searchKeywords}
                                            onChange={(e) => this.onSearchChange("searchKeywords", e?.target?.value ?? "")}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            className="m-0"
                                            control={
                                                <Switch
                                                    color="primary"
                                                    checked={searchExInt}
                                                    onChange={(e) => this.onSearchChange("searchExInt", e.target.checked)}
                                                    value="1"
                                                />
                                            }
                                            label={
                                                <Typography variant="body2" className="fw-400">
                                                    Exclude Internal Calls
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            className="m-0"
                                            control={
                                                <Switch
                                                    color="primary"
                                                    checked={searchUasOnly}
                                                    onChange={(e) => this.onSearchChange("searchUasOnly", e.target.checked)}
                                                    value="1"
                                                />
                                            }
                                            label={
                                                <Typography variant="body2" className="fw-400">
                                                    Show Unassigned Only
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} align="right">
                                        <Button onClick={this.resetSearch} variant="outlined" color="default">
                                            <FAIcon icon="undo" type="light" button />
                                            Reset Search
                                        </Button>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <PaperElement>
                        {inPage && (
                            <Box pb={!results || !results.length ? 3 : 0}>
                                {customerId ? <Typography variant={siteId ? "h6" : "h5"}>Phone History</Typography> : null}
                                <Grid container spacing={3} alignItems="flex-end">
                                    <Grid item xs={6} lg={showSiteInput ? 4 : 6}>
                                        <DatePicker
                                            type={"date"}
                                            id="searchDtFrom"
                                            name="searchDtFrom"
                                            label="Date From"
                                            onChange={(v) => this.onSearchChange("searchDtFrom", v)}
                                            disableFuture
                                            maxDate={searchDtTo}
                                            value={searchDtFrom}
                                            autoOk={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6} lg={showSiteInput ? 4 : 6}>
                                        <DatePicker
                                            type={"date"}
                                            id="searchDtTo"
                                            name="searchDtTo"
                                            label="Date From"
                                            disableFuture
                                            minDate={searchDtFrom}
                                            value={searchDtTo}
                                            onChange={(v) => this.onSearchChange("searchDtTo", v)}
                                            autoOk={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={showSiteInput ? 4 : 6} className="pt-0">
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect
                                                className={"main-shadow"}
                                                options={staff}
                                                label="Staff"
                                                value={searchStaff}
                                                onChange={(v) => this.onSearchChange("searchStaff", v?.value ?? "")}
                                                margin="normal"
                                                adornment="sort"
                                            />
                                        </FormControl>
                                    </Grid>
                                    {!showSiteInput ? null : (
                                        <Grid item xs={6} className="pt-0">
                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteSelect
                                                    className={"main-shadow"}
                                                    options={sites}
                                                    label="Site"
                                                    value={searchSite}
                                                    onChange={(v) => this.onSearchChange("searchSite", v?.value ?? null)}
                                                    margin="normal"
                                                    adornment="sort"
                                                />
                                            </FormControl>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} lg={6} className="pt-0">
                                        <TextField
                                            label="Keywords"
                                            fullWidth
                                            value={searchKeywords}
                                            onChange={(e) => this.onSearchChange("searchKeywords", e?.target?.value ?? "")}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        <CiDataTable
                            config={{
                                key: "id",
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: dataLoading,
                                responsiveImportance: true,
                                persistenceId: this.persistenceId,
                                title: "Phone History",
                                nesting: true,
                                options: !inPage
                                    ? {
                                          dataRef: true,
                                          reset: this.resetSearch,
                                          export: {
                                              title: `Phone History`,
                                              name: `phone-history`,
                                              excel: true,
                                          },
                                      }
                                    : null,
                            }}
                            columns={[
                                {
                                    heading: "Date / Time",
                                    field: (rowData) =>
                                        `${typeof rowData.children === "undefined" ? `Transfer: ` : ``}${moment(
                                            rowData.sd !== "0000-00-00 00:00:00" ? rowData.sd : rowData.ca
                                        ).format(typeof rowData.children === "undefined" ? "HH:mm:ss" : "DD/MM/YYYY HH:mm:ss")}`,
                                    dataRef: "ca",
                                    important: true,
                                    sizeToContent: true,
                                    nestingDropdown: true,
                                },
                                {
                                    heading: "Duration",
                                    field: (rowData) =>
                                        parseInt(rowData.du) > 0 ? moment.utc(parseInt(rowData.du) * 1000).format(rowData.du > 3600 ? "hh:mm:ss" : "mm:ss") : "-",
                                    dataRef: "du",
                                    important: true,
                                    sizeToContent: true,
                                },
                                {
                                    heading: "Direction",
                                    field: (rowData) => rowData.ic,
                                    dataRef: "ic",
                                    important: true,
                                    sizeToContent: true,
                                },
                                {
                                    heading: "Contact",
                                    field: (rowData) => <>
                                        <Typography variant="body2">{rowData.nm.replace(/^(\ )/, "") === "" ? "-" : rowData.nm}</Typography>
                                        {rowData.sa.length > 0 &&
                                            <Typography variant="caption">
                                                {rowData.sa.map(i => 
                                                   <> 
                                                        <InfoIcon 
                                                            size={12}
                                                            info={
                                                                <>
                                                                    Name: {i.nm} <br/>
                                                                    Company: {i.cu ? `${i.cu} ${i.ad ? `(${i.ad})` : ''}` : '-'} <br/>

                                                                </>
                                                            }
                                                        /> {i.nm} <br/>
                                                    </>             
                                                )}
                                            </Typography>
                                        }
                                    </>,
                                    dataRef: "nm",
                                    important: true,
                                    truncate: true,
                                },
                                {
                                    actionsCustomHeader: true,
                                    important: true,
                                    truncate: true,
                                    alignment: "left",
                                    heading: "Company",
                                    actions: (rowData) => {
                                        const a = [];
                                        a.push({
                                            label: rowData.cn || "-",
                                            link:
                                                access.viewCustomer && rowData.ci
                                                    ? `/customers/view/${rowData.ci}`
                                                    : access.viewSupplier && rowData.si
                                                    ? `/suppliers/view/${rowData.si}`
                                                    : null,
                                            type: (access.viewCustomer && rowData.ci) || (access.viewSupplier && rowData.si) ? "plainLink" : "block",
                                        });
                                        if (rowData?.ad?.id) {
                                            a.push({
                                                label: `${rowData.ad.nm || "-"} (${rowData.ad.ty} Address)`,
                                                link: access.viewAddress ? `/customers/addresses/view/${rowData.ad.id}` : null,
                                                color: colors.grey,
                                                type: access.viewAddress ? "plainLink" : "block",
                                            });
                                        }
                                        return a;
                                    },
                                },
                                {
                                    heading: "Phone Number",
                                    field: (rowData) => <Dial number={rowData?.nu || "-"} />,
                                    dataRef: "nu",
                                    important: true,
                                    sizeToContent: true,
                                },
                                // {
                                //     heading: "Notes",
                                //     field: (rowData) =>
                                //         _.isEmpty(rowData.nt) ? (
                                //             rowData?.nu?.length === 3 ? (
                                //                 `-`
                                //             ) : (
                                //                 <em>
                                //                     <FAIcon icon="exclamation-circle" type="light" size={14} />
                                //                     No notes added for this call
                                //                 </em>
                                //             )
                                //         ) : (
                                //             <Grid container spacing={1} wrap="nowrap" alignItems="center">
                                //                 <Grid item>
                                //                     <Typography
                                //                         variant="body2"
                                //                         style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                                //                     >
                                //                         {rowData.nt.length > 30 ? `${rowData.nt.substring(0, 30)}...` : rowData.nt}
                                //                     </Typography>
                                //                 </Grid>
                                //             </Grid>
                                //         ),
                                //     cellProps: () => ({
                                //         style: {
                                //             whiteSpace: "pre-wrap",
                                //             wordWrap: "break-word",
                                //             overflowWrap: "break-word",
                                //             overflow: "hidden",
                                //         },
                                //     }),
                                //     important: true,
                                // },
                                {
                                    heading: "Staff",
                                    field: (rowData) => `${rowData.us}${rowData.de ? ` (${rowData.de})` : ``}`,
                                    dataRef: "us",
                                    important: true,
                                    sizeToContent: true,
                                },
                                {
                                    heading: "Status",
                                    field: (rowData) => <Chip icon={this.getIcon(rowData.st)} label={rowData.st || "Answered"} style={this.getStyle(rowData.st)} />,
                                    dataRef: "st",
                                    important: true,
                                    sizeToContent: true,
                                },
                                {
                                    actions: (rowData) => {
                                        return [
                                            {
                                                name: `View Task - Status: ${rowData?.ts?.st ?? "Not Assigned"}`,
                                                icon: "list-check",
                                                onClick: () => this.handleDespatchTask(rowData?.ts?.id),
                                                disabled: !rowData?.ts?.id,
                                                className: this.handleTaskStatusClass(rowData?.ts?.st),
                                            },
                                            {
                                                name: "View Notes",
                                                icon: "notebook",
                                                onClick: () => this.handleNotesDialog(rowData.nt),
                                                disabled: _.isEmpty(rowData.nt),
                                            },
                                            {
                                                name: _.isEmpty(rowData.nt) ? "Add Notes" : "Update Notes",
                                                icon: "pencil-alt",
                                                onClick: () => this.handleNotesUpdate(rowData.id, _.isEmpty(rowData.nt)),
                                            },
                                            {
                                                name: "Re-assign Call",
                                                icon: "people-arrows",
                                                onClick: () => this.handleReAssignCall(rowData.id),
                                                disabled: rowData.nu.length === 3,
                                            },
                                            {
                                                name: "View Recordings",
                                                icon: "cassette-tape",
                                                onClick: () => this.handleDespatchCallRecordings(rowData.ri ?? rowData.id, rowData?.de ?? "", rowData.ca),
                                                disabled: !access.callRec || rowData.rc === 0,
                                            },
                                            {
                                                name: "View Transcriptions",
                                                icon: "file-lines",
                                                onClick: () => this.handleDespatchCallTranscriptions(rowData.ri ?? rowData.id),
                                                disabled: !access.callRec || rowData.tc === 0,
                                            },
                                            {
                                                name: "Delete Data",
                                                icon: "trash-alt",
                                                onClick: () =>
                                                    this.props.deployConfirmation(
                                                        `This will remove recordings and transcription data for this call`,
                                                        `Delete Call Data?`,
                                                        () => this.handleDeleteCallData(rowData.id)
                                                    ),
                                                disabled: !access.callRec || (rowData.rc === 0 && rowData.tc === 0),
                                            },
                                        ];
                                    },
                                },
                            ]}
                            rows={results}
                        />
                    </PaperElement>
                    <NotificationDialog maxWidth="xs" open={callNotes !== null} close={() => this.handleNotesDialog(null)} title="Call Notes" message={callNotes} />
                </Grid>
            </Grid>
        );
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (type, msg) => dispatch(deploySnackBar(type, msg)),
        deployConfirmation: (msg, title, success) => dispatch(deployConfirmation(msg, title, success)),
        deployDialog: (content, title, variant = "standard", size = "md", style = {}) => dispatch(deployDialog(content, title, variant, size, style)),
    };
};

export default connect(null, mapDispatchToProps)(CallHistory);
