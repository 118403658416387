import * as UIActionTypes from 'Actions/UI/Types/Types';

import { getMode } from 'Functions/AuthFunctions';

 const initialState = {
    device: {
        isMobile: window.innerWidth < 960 ? true : false,
        width: window.innerWidth,
        height: window.innerHeight,
    },
    updateNotification: false,
    appError: {
        state: false,
        message: "",
        unauthorized: false,
        unauthorizedPage: ""
    },
    scrollToTopUuid: null,
    mode: getMode(),
    voip: {
        hasCalls: false,
    },
};

 function UIReducer(state = initialState, action) {
    switch (action.type) {
        case UIActionTypes.WINDOW_RESIZE:
            return {
                ...state,
                device: {
                    isMobile: action.payload[0] < 960 ? true : false,
                    width: action.payload[0],
                    height: action.payload[1],
                }
            };
        case UIActionTypes.UPDATE_NOTIFICATION:
                if(state.updateNotification !== action.payload) {
                    return {
                        ...state,                   
                        updateNotification: action.payload,
                    };
                } else {
                    return state
                }
                case UIActionTypes.HANDLE_CALL_STATUS:
                    return {
                        ...state,
                        voip: {
                            ...state.voip,
                            hasCalls: action.payload,
                        },
                    };
        case UIActionTypes.HANDLE_ERROR:
            if(state.appError.state !== action.payload[0]) {
                return {
                    ...state,                   
                    appError: {
                        state: action.payload[0],
                        detail: action.payload[1]
                    }
                };
            } else {
                return state
            }
        case UIActionTypes.HANDLE_UNAUTHORIZED:
            if(state.appError.unauthorizedPage !== action.payload) {
                return {
                    ...state,   
                    unauthorized: action.payload === false ? false : true,               
                    unauthorizedPage: action.payload
                };
            } else {
                return state
            }
        case UIActionTypes.SCROLL_TO_TOP:
            if(state.scrollToTopUuid !== action.payload) {
                return {
                    ...state,                   
                    scrollToTopUuid: action.payload,
                };
            } else {
                return state
            }
        default:
            return state;
    }
}

 export default UIReducer;