import React, { PureComponent } from 'react';
import { connect }              from 'react-redux';
import API                      from 'API';
import _                        from 'lodash';
import moment                   from 'moment';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import LoadingCircle   from 'Components/Common/LoadingCircle/LoadingCircle';
import DataTable       from 'Components/Common/DataTables/CiDataTable';
import DownloadCSV     from 'Components/Common/Buttons/DownloadCSV';
import PaddedPaper     from 'Components/Common/Paper/PaddedPaper';
import DragFileInput   from 'Components/Common/Inputs/DragFileInput';
import DateRangeDialog from 'Components/Reports/SalesReports/DateRangeDialog';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { colors }                 from 'Helpers/ColourHelper';

import { Grid, Button, Checkbox, FormGroup, FormControlLabel, Divider, Typography } from '@material-ui/core/'
import { withStyles } from '@material-ui/core/styles';

const initialState = {
    isLoading:      false,
    file:           null,
    formErrors:     {},
    parts:          [],
    formData:       {
        to:            moment().format('YYYY-MM-DD'),
        from:          moment().format('YYYY-MM-DD'),
    },
    options:        {
        totalQty                : 0,
        totalCost               : 0,
        totalNet                : 0,
        currentCost             : 0,
        currentPrice            : 0,
        currentStock            : 0,
        currentStockValue       : 0,
        worksOrderWrittenOff    : 0,
        worksOrderReturned      : 0,
        worksOrderAdditional    : 0,
        worksOrderPicked        : 0,
        worksOrderBuilt         : 0,
        startStock              : 0,
        startStockValue         : 0,
        endStock                : 0,
        endStockValue           : 0,
        changeStock             : 0,
        changeStockValue        : 0,
        changeStockValueCurrent : 0,
        startStockValueCurrent  : 0,
        endStockValueCurrent    : 0,
        closingCostPerUnit      : 0,
        startingCostPerUnit     : 0,
        closingPricePerUnit     : 0,
        startingPricePerUnit    : 0,
    }
}

class MasterExcelReport extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => { 
        this.getExcelOrder() 
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({file});
    }

    clearFile = () => { this.setState({file: null});}

    handleFileSubmit = () => {
        this.setState({isLoading: true}, ()=>{
            let newFormData = new FormData();
            newFormData.append('file', this.state.file);
            API.post('/reports/masterExcel/set', newFormData)
            .then(res => {
                if (res.data.errors) this.setState({formErrors: formatValidationErrors(res.data.errors)});
                if (!res.data.success){
                    this.props.deployConfirmation(
                        <>
                            The following parts need updating or removing from the excel:<br/>
                            {_.map(res.data.error, e => <>{e}<br/></>)}
                        </>,
                        'Master Excel Could not be uploaded',
                        ()=>{
                            this.clearFile();
                        }
                    )
                } else {
                    this.clearFile();
                    this.getExcelOrder()
                }
            })
        });
    }

    getExcelOrder = () => {
        this.setState({isLoading: true}, ()=>{
            API.get('/reports/masterExcel')
            .then(res => {
                this.setState({
                    isLoading:  false,
                    parts:      res.data
                }, this.getReportsData)
            })
        });
    }

    setToFrom = formData => {
        this.setState({formData},this.getReportsData)
    }

    getReportsData = () => {
        this.setState({isLoading: true}, ()=>{
            const params = {params: this.state.formData}
            API.get( '/reports/masterExcel/parts' , params)
            .then( res => {
                if (!res.data.errors){
                    this.setState({
                        parts: _.map(this.state.parts, i => _.assign({
                            ...i,
                            totalQty:                 res.data[i.mepo_part_id] ? (parseFloat(res.data[i.mepo_part_id].in.q) - parseFloat(res.data[i.mepo_part_id].out.q)).toFixed(2)           : null,
                            totalNet:                 res.data[i.mepo_part_id] ? parseFloat(parseFloat(res.data[i.mepo_part_id].in.n) - parseFloat(res.data[i.mepo_part_id].out.n)).toFixed(2) : null,
                            totalCost:                res.data[i.mepo_part_id] ? parseFloat(res.data[i.mepo_part_id].c).toFixed(2)                                                             : null,
                            totalStock:               res.data[i.mepo_part_id] ? parseFloat(res.data[i.mepo_part_id].s.g ?? 0).toFixed(2)                                                      : 0,

                            currentCost:              i.part ? parseFloat(i?.part?.default_cost?.cost_per_unit).toFixed(2)                                                                        : null, 
                            currentPrice:             i.part ? parseFloat(i?.part?.default_price?.price_total || 0).toFixed(2)                                                                         : null,  
                            currentStock:             i.part ? i.part?.stock && parseFloat(i.part?.stock?.stock_current_qty).toFixed(3)                                                           : null,
                            currentStockValue:        i.part ? i.part?.stock && parseFloat(parseFloat(i?.part?.stock?.stock_current_qty) * i?.part?.default_cost?.cost_per_unit).toFixed(2)           : null, 
                              
                            worksOrderWrittenOff:     res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['w'] : 0,
                            worksOrderReturned:       res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['r'] : 0,
                            worksOrderAdditional:     res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['a'] : 0,
                            worksOrderPicked:         res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['p'] : 0,
                            worksOrderBuilt:          res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['b'] : 0,

                            openingStock:             res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['s']['w'] : 0,
                            closingStock:             res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['s']['e'] : 0,
                            changeStock:              res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['s']['r'] : 0,
                            openingStockValue:        res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['s']['t'] : 0,
                            closingStockValue:        res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['s']['y'] : 0,
                            changeStockValue:         res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['s']['u'] : 0,
                            openingStockValueCurrent: res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['s']['i'] : 0,
                            closingStockValueCurrent: res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['s']['o'] : 0,
                            changeStockValueCurrent:  res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['s']['p'] : 0,
                       
                            closingCostPerUnit:       res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['s']['a'] : 0,
                            openingCostPerUnit:       res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['s']['s'] : 0,

                            openingPricePerUnit:       res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['s']['d'] : 0,
                            closingPricePerUnit:       res.data[i.mepo_part_id] ? res.data[i.mepo_part_id]['s']['f'] : 0,
                        })),
                        isLoading: false
                    })
                }
            } )
        })
    }

    handleCheck = field => {
        this.setState({
            options: {
                ...this.state.options,
                [field]: !this.state.options[field]
            }
        })
    }

    getColumns = () => {

        const {totalQty, totalCost, totalNet, currentCost, currentPrice, currentStock, currentStockValue, worksOrderWrittenOff, worksOrderReturned, 
            worksOrderAdditional, worksOrderPicked, worksOrderBuilt, startStock, startStockValue, endStock, endStockValue, changeStock, changeStockValue, 
            changeStockValueCurrent, endStockValueCurrent, startStockValueCurrent, openingCostPerUnit, closingCostPerUnit, openingPricePerUnit, closingPricePerUnit, totalStock } = this.state.options;

        let ret  = [
            {
                heading:        'Part',
                field:          rowData => rowData.part ? rowData.part.part_number : 'CS-',
                sizeToContent:  true
            }
        ];

        /*{heading: 'Description',field:   rowData => rowData.part ? rowData.part.part_description : 'TBD',}*/

        if (totalQty)                ret.push({heading: 'Total Sales Qty',             alignment: 'right', field: rowData => rowData.part ? (rowData.totalQty          ? `${rowData.totalQty}`                                                                : '') : null});
        if (totalNet)                ret.push({heading: 'Total Sales Net',             alignment: 'right', field: rowData => rowData.part ? (rowData.totalNet          ? `${rowData.totalNet < 0          ? '-' : ''}£${Math.abs(rowData.totalNet).toFixed(2)}`          : '') : null});
        if (totalCost)               ret.push({heading: 'Total Sales Cost',            alignment: 'right', field: rowData => rowData.part ? (rowData.totalCost         ? `${rowData.totalCost < 0         ? '-' : ''}£${Math.abs(rowData.totalCost).toFixed(2)}`         : '') : null});
       

        if (currentCost)             ret.push({heading: 'Current Cost',                alignment: 'right', field: rowData => rowData.part ? (rowData.currentCost       ? `${rowData.currentCost < 0       ? '-' : ''}£${Math.abs(rowData.currentCost).toFixed(2)}`       : '') : null});
        if (currentPrice)            ret.push({heading: 'Current Price',               alignment: 'right', field: rowData => rowData.part ? (rowData.currentPrice      ? `${rowData.currentPrice < 0      ? '-' : ''}£${Math.abs(rowData.currentPrice).toFixed(2)}`      : '') : null});
        if (currentStock)            ret.push({heading: 'Current Stock Holding',       alignment: 'right', field: rowData => rowData.part ? (rowData.currentStock      ? `${rowData.currentStock}`                                                            : '') : null});
        if (currentStockValue)       ret.push({heading: 'Current Total Stock Value',   alignment: 'right', field: rowData => rowData.part ? (rowData.currentStockValue ? `${rowData.currentStockValue < 0 ? '-' : ''}£${Math.abs(rowData.currentStockValue).toFixed(2)}` : '') : null});

        if (worksOrderWrittenOff)    ret.push({heading: 'Works Order Written Off',     alignment: 'right', field: rowData => rowData.part ? rowData.worksOrderWrittenOff  : null});
        if (worksOrderReturned)      ret.push({heading: 'Works Order Returned',        alignment: 'right', field: rowData => rowData.part ? rowData.worksOrderReturned    : null});
        if (worksOrderAdditional)    ret.push({heading: 'Works Order Additional',      alignment: 'right', field: rowData => rowData.part ? rowData.worksOrderAdditional  : null});
        if (worksOrderPicked)        ret.push({heading: 'Works Order Picked',          alignment: 'right', field: rowData => rowData.part ? rowData.worksOrderPicked      : null});
        if (worksOrderBuilt)         ret.push({heading: 'Works Order Built',           alignment: 'right', field: rowData => rowData.part ? rowData.worksOrderBuilt       : null});
         
        if (startStock)              ret.push({heading: 'Opening Stock',               alignment: 'right', field: rowData => rowData.part ? parseFloat(rowData.openingStock).toFixed(3) : null});
        if (startStockValue)         ret.push({heading: 'Opening Stock Value',         alignment: 'right', field: rowData => rowData.part ? `${rowData.openingStockValue < 0  ? '-' : ''}£${Math.abs(rowData.openingStockValue).toFixed(2)}` : ''});
        if (startStockValueCurrent)  ret.push({heading: 'Opening Stock Current Value', alignment: 'right', field: rowData => rowData.part ? `${rowData.openingStockValueCurrent < 0  ? '-' : ''}£${Math.abs(rowData.openingStockValueCurrent).toFixed(2)}` : ''});
        if (endStock)                ret.push({heading: 'Closing Stock',               alignment: 'right', field: rowData => rowData.part ? parseFloat(rowData.closingStock).toFixed(3) : null});
        if (endStockValue)           ret.push({heading: 'Closing Stock Value',         alignment: 'right', field: rowData => rowData.part ? `${rowData.closingStockValue < 0  ? '-' : ''}£${Math.abs(rowData.closingStockValue).toFixed(2)}` : ''});
        if (endStockValueCurrent)    ret.push({heading: 'Closing Stock Current Value', alignment: 'right', field: rowData => rowData.part ? `${rowData.closingStockValueCurrent < 0  ? '-' : ''}£${Math.abs(rowData.closingStockValueCurrent).toFixed(2)}` : ''});
        if (changeStock)             ret.push({heading: 'Change Stock',                alignment: 'right', field: rowData => rowData.part ? parseFloat(rowData.changeStock).toFixed(3)  : null});
        if (changeStockValue)        ret.push({heading: 'Change Stock Value',          alignment: 'right', field: rowData => rowData.part ? `${rowData.changeStockValue < 0  ? '-' : ''}£${Math.abs(rowData.changeStockValue).toFixed(2)}` : ''});
        if (changeStockValueCurrent) ret.push({heading: 'Closing Stock Current Value', alignment: 'right', field: rowData => rowData.part ? `${rowData.changeStockValueCurrent < 0  ? '-' : ''}£${Math.abs(rowData.changeStockValueCurrent).toFixed(2)}` : ''});
        
        if (openingCostPerUnit)      ret.push({heading: 'Opening Cost Per Unit',       alignment: 'right', field: rowData => rowData.part ? `${rowData.openingCostPerUnit < 0  ? '-' : ''}£${Math.abs(rowData.openingCostPerUnit).toFixed(2)}` : ''});
        if (closingCostPerUnit)      ret.push({heading: 'Closing Cost Per Unit',       alignment: 'right', field: rowData => rowData.part ? `${rowData.closingCostPerUnit < 0  ? '-' : ''}£${Math.abs(rowData.closingCostPerUnit).toFixed(2)}` : ''});

        if (openingPricePerUnit)      ret.push({heading: 'Opening Price Per Unit',       alignment: 'right', field: rowData => rowData.part ? `${rowData.openingPricePerUnit < 0  ? '-' : ''}£${Math.abs(rowData.openingPricePerUnit).toFixed(2)}` : ''});
        if (closingPricePerUnit)      ret.push({heading: 'Closing Price Per Unit',       alignment: 'right', field: rowData => rowData.part ? `${rowData.closingPricePerUnit < 0  ? '-' : ''}£${Math.abs(rowData.closingPricePerUnit).toFixed(2)}` : ''});
        if (startStock)             ret.push({heading: 'Opening Stock',             alignment: 'right', field: rowData => rowData.part ? parseFloat(rowData.openingStock).toFixed(3) : null});
        if (startStockValue)        ret.push({heading: 'Opening Stock Value',       alignment: 'right', field: rowData => rowData.part ? `${rowData.openingStockValue < 0  ? '-' : ''}£${Math.abs(rowData.openingStockValue).toFixed(2)}` : ''});
        if (endStock)               ret.push({heading: 'Closing Stock',             alignment: 'right', field: rowData => rowData.part ? parseFloat(rowData.closingStock).toFixed(3) : null});
        if (endStockValue)          ret.push({heading: 'Closing Stock Value',       alignment: 'right', field: rowData => rowData.part ? `${rowData.closingStockValue < 0  ? '-' : ''}£${Math.abs(rowData.closingStockValue).toFixed(2)}` : ''});
        if (changeStock)            ret.push({heading: 'Change Stock',              alignment: 'right', field: rowData => rowData.part ? parseFloat(rowData.changeStock).toFixed(3)  : null});
        if (changeStockValue)       ret.push({heading: 'Change Stock Value',        alignment: 'right', field: rowData => rowData.part ? `${rowData.changeStockValue < 0  ? '-' : ''}£${Math.abs(rowData.changeStockValue).toFixed(2)}` : ''});
        if (totalStock)             ret.push({heading: 'Total Sales Stock',         alignment: 'right', field: rowData => rowData.part ? parseFloat(rowData.totalStock).toFixed(3)  : null});

        return ret;
    }

    getCsvData = () => {
        return _.map(this.state.parts, i => this.mapSelectedToDownload(i));
    }

    mapSelectedToDownload = i => {
        const {totalQty, totalCost, totalNet, currentCost, currentPrice, currentStock, currentStockValue, worksOrderWrittenOff, worksOrderReturned, 
            worksOrderAdditional, worksOrderPicked, worksOrderBuilt, startStock, startStockValue, endStock, endStockValue, changeStock, changeStockValue,
            changeStockValueCurrent, endStockValueCurrent, startStockValueCurrent, closingCostPerUnit, openingCostPerUnit, openingPricePerUnit, 
            closingPricePerUnit, totalStock } = this.state.options;

        let ret  = {Part: i.part ? i.part.part_number : 'CS-'}
        if (totalQty)                ret['Total Qty']                   = i.totalQty;
        if (totalNet)                ret['Total Net']                   = i.totalNet; 
        if (totalCost)               ret['Total Cost']                  = i.totalCost;
        if (totalStock)              ret['Total Sales Stock']           = i.totalStock;

        if (currentCost)             ret['Current Cost']                = i.currentCost;
        if (currentPrice)            ret['Current Price']               = i.currentPrice;
        if (currentStock)            ret['Current Stock']               = i.currentStock;
        if (currentStockValue)       ret['Current Stock Value']         = i.currentStockValue;

        if (worksOrderWrittenOff)    ret['Works Order Written Off']     = i.worksOrderWrittenOff;
        if (worksOrderReturned)      ret['Works Order Returned']        = i.worksOrderReturned;
        if (worksOrderAdditional)    ret['Works Order Additional']      = i.worksOrderAdditional;
        if (worksOrderPicked)        ret['Works Order Picked']          = i.worksOrderPicked;
        if (worksOrderBuilt)         ret['Works Order Built']           = i.worksOrderBuilt;

        if (startStock)              ret['Opening Stock']               = i.openingStock;
        if (startStockValue)         ret['Opening Stock Value']         = i.openingStockValue;
        if (startStockValueCurrent)  ret['Opening Stock Value Current'] = i.openingStockValueCurrent;
        if (endStock)                ret['Closing Stock']               = i.closingStock;
        if (endStockValue)           ret['Closing Stock Value']         = i.closingStockValue;
        if (endStockValueCurrent)    ret['Closing Stock Value Current'] = i.closingStockValueCurrent;
        if (changeStock)             ret['Change Stock']                = i.changeStock;
        if (changeStockValue)        ret['Change Stock Value']          = i.changeStockValue;
        if (changeStockValueCurrent) ret['Change Stock Value Current']  = i.changeStockValueCurrent;

        if (openingCostPerUnit)      ret['Opening Cost Per Unit']       = i.openingCostPerUnit;
        if (closingCostPerUnit)      ret['Closing Cost Per Unit']       = i.closingCostPerUnit;

        if (openingPricePerUnit)      ret['Opening Price Per Unit']       = i.openingPricePerUnit;
        if (closingPricePerUnit)      ret['Closing Price Per Unit']       = i.closingPricePerUnit;
        return ret;
    }

    render() {

        const {isLoading, file, formErrors, parts, options} = this.state;

        return (
            <Grid container xs={12} spacing={3} style={{width: '100%'}}>
                <Grid item xs={6}>
                    <Grid container xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h6">Custom Report</Typography>
                                <DateRangeDialog onChange={this.setToFrom} />
                                <Grid container xs={12} style={{alignItems: 'center'}}>
                                    <Grid item xs={1}>
                                       <b>Sales</b> 
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Divider style={{margin: '1.3em 0'}}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="totalQty"
                                                        checked={options.totalQty}
                                                        onChange={()=>{this.handleCheck('totalQty')}}
                                                        color="primary"
                                                    />
                                                }
                                                label={'Total Sales Qty'}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="totalCost"
                                                        checked={options.totalCost}
                                                        onChange={()=>{this.handleCheck('totalCost')}}
                                                        color="primary"
                                                    />
                                                }
                                                label={'Total Sales Cost'}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="totalNet"
                                                        checked={options.totalNet}
                                                        onChange={()=>{this.handleCheck('totalNet')}}
                                                        color="primary"
                                                    />
                                                }
                                                label={'Total Sales Net'}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="totalStock"
                                                        checked={options.totalStock}
                                                        onChange={()=>{this.handleCheck('totalStock')}}
                                                        color="primary"
                                                    />
                                                }
                                                label={'Total Sales Stock'}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} style={{alignItems: 'center'}}>
                                    <Grid item xs={1}>
                                        Stock
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Divider style={{margin: '1.3em 0'}}/>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="startStock"
                                                            checked={options.startStock}
                                                            onChange={()=>{this.handleCheck('startStock')}}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={'Opening Stock'}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="endStock"
                                                            checked={options.endStock}
                                                            onChange={()=>{this.handleCheck('endStock')}}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={'Closing Stock'}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="startStock"
                                                            checked={options.changeStock}
                                                            onChange={()=>{this.handleCheck('changeStock')}}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={'Change In Stock'}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="startStockValue"
                                                            checked={options.startStockValue}
                                                            onChange={()=>{this.handleCheck('startStockValue')}}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={'Opening Stock Value (Based On Start Date)'}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="endStockValue"
                                                            checked={options.endStockValue}
                                                            onChange={()=>{this.handleCheck('endStockValue')}}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={'Closing Stock Value (Based On End Date)'}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="startStock"
                                                            checked={options.changeStockValue}
                                                            onChange={()=>{this.handleCheck('changeStockValue')}}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={'Change In Stock Value (Based On End Date)'}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="startStockValue"
                                                            checked={options.startStockValueCurrent}
                                                            onChange={()=>{this.handleCheck('startStockValueCurrent')}}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={'Opening Stock Value (Based On Current)'}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="endStockValue"
                                                            checked={options.endStockValueCurrent}
                                                            onChange={()=>{this.handleCheck('endStockValueCurrent')}}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={'Closing Stock Value (Based On Current)'}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="startStock"
                                                            checked={options.changeStockValueCurrent}
                                                            onChange={()=>{this.handleCheck('changeStockValueCurrent')}}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={'Change In Stock Value (Based On Current)'}
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} style={{alignItems: 'center'}}>
                                    <Grid item xs={2}>
                                        Works Orders
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Divider style={{margin: '1.3em 0'}}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="worksOrderPicked"
                                                        checked={options.worksOrderPicked}
                                                        onChange={()=>{this.handleCheck('worksOrderPicked')}}
                                                        color="primary"
                                                    />
                                                }
                                                label={'Works Order Stock Picked'}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="worksOrderAdditional"
                                                        checked={options.worksOrderAdditional}
                                                        onChange={()=>{this.handleCheck('worksOrderAdditional')}}
                                                        color="primary"
                                                    />
                                                }
                                                label={'Works Order Stock Additional'}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="worksOrderReturned"
                                                        checked={options.worksOrderReturned}
                                                        onChange={()=>{this.handleCheck('worksOrderReturned')}}
                                                        color="primary"
                                                    />
                                                }
                                                label={'Works Order Stock Returned'}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="worksOrderWrittenOff"
                                                        checked={options.worksOrderWrittenOff}
                                                        onChange={()=>{this.handleCheck('worksOrderWrittenOff')}}
                                                        color="primary"
                                                    />
                                                }
                                                label={'Works Order Stock Written Off'}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="worksOrderBuilt"
                                                        checked={options.worksOrderBuilt}
                                                        onChange={()=>{this.handleCheck('worksOrderBuilt')}}
                                                        color="primary"
                                                    />
                                                }
                                                label={'Works Order Built'}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>            
                                <Grid container xs={12} style={{alignItems: 'center'}}>
                                    <Grid item xs={2}>
                                        Costs
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Divider style={{margin: '1.3em 0'}}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="openingCostPerUnit"
                                                        checked={options.openingCostPerUnit}
                                                        onChange={()=>{this.handleCheck('openingCostPerUnit')}}
                                                        color="primary"
                                                    />
                                                }
                                                label={'Opening Cost Per Unit'}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="closingCostPerUnit"
                                                        checked={options.closingCostPerUnit}
                                                        onChange={()=>{this.handleCheck('closingCostPerUnit')}}
                                                        color="primary"
                                                    />
                                                }
                                                label={'Closing Cost Per Unit'}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} style={{alignItems: 'center'}}>
                                    <Grid item xs={2}>
                                        Prices
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Divider style={{margin: '1.3em 0'}}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="openingPricePerUnit"
                                                        checked={options.openingPricePerUnit}
                                                        onChange={()=>{this.handleCheck('openingPricePerUnit')}}
                                                        color="primary"
                                                    />
                                                }
                                                label={'Opening Price Per Unit'}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="closingPricePerUnit"
                                                        checked={options.closingPricePerUnit}
                                                        onChange={()=>{this.handleCheck('closingPricePerUnit')}}
                                                        color="primary"
                                                    />
                                                }
                                                label={'Closing Price Per Unit'}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <div className='buttonRow'>
                                    <DownloadCSV 
                                        data    ={this.getCsvData()} color='primary' variant='contained' 
                                        filename={`MasterExcelParts_${this.state.formData.to}_${this.state.formData.from}`} 
                                        style   ={{marginBottom: '1em'}} 
                                        disabled={isLoading}
                                    >download CSV</DownloadCSV>
                                </div>                 
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h6">Current Report</Typography>
                                <Grid container xs={12} style={{alignItems: 'center'}}>
                                    <Grid item xs={1}>
                                        <b>Costs</b>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Divider style={{margin: '1.3em 0'}}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="currentCost"
                                                        checked={options.currentCost}
                                                        onChange={()=>{this.handleCheck('currentCost')}}
                                                        color="primary"
                                                    />
                                                }
                                                label={'Current Cost'}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} style={{alignItems: 'center'}}>
                                    <Grid item xs={1}>
                                        <b>Prices</b>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Divider style={{margin: '1.3em 0'}}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="currentPrice"
                                                        checked={options.currentPrice}
                                                        onChange={()=>{this.handleCheck('currentPrice')}}
                                                        color="primary"
                                                    />
                                                }
                                                label={'Current Price'}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} style={{alignItems: 'center'}}>
                                        <Grid item xs={1}>
                                            Stock
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Divider style={{margin: '1.3em 0'}}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="currentStock"
                                                            checked={options.currentStock}
                                                            onChange={()=>{this.handleCheck('currentStock')}}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={'Current Stock Holding'}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="currentStockValue"
                                                            checked={options.currentStockValue}
                                                            onChange={()=>{this.handleCheck('currentStockValue')}}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={'Current Total Stock Value'}
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <div className='buttonRow'>
                                        <DownloadCSV 
                                            data    ={this.getCsvData()} color='primary' variant='contained' 
                                            filename={`MasterExcelParts_${this.state.formData.to}_${this.state.formData.from}`} 
                                            style   ={{marginBottom: '1em'}} 
                                            disabled={isLoading}
                                        >download CSV</DownloadCSV>
                                    </div>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                    <PaddedPaper>
                        Master Excel Order
                        <Typography variant='body1' style={{fontWeight: 'bold', marginBottom: '1em', marginTop: '1em'}}>Note: Uploaded CSV must have the following headings:</Typography>
                            <DataTable
                                config={{
                                    plainHeader: true,
                                    noHeader: true,
                                }}
                                columns={[
                                    {
                                        field:   'c',
                                        sizeToContent: true,
                                        style: { fontWeight: 'bold', borderRight: `1px solid ${colors.disabled}` },
                                    },
                                    {
                                        field: 'n',
                                    },
                                ]}
                                rows={[
                                    {c: 'A1', n: 'Part Number' },
                                ]}
                            />
                        <DragFileInput
                            accept=".csv"
                            id="file"
                            name="file"
                            label="Master Excel Parts CSV *"
                            placeholder="Please Include Headers In CSV"
                            file={file}
                            errorText={formErrors && formErrors['file']}
                            onChange={this.handleFileChange}
                            cancelOnClick={this.clearFile}
                            emptyText='No file selected'
                        />
                        <Button variant="contained" color="primary" onClick={()=>{this.props.deployConfirmation('Are you sure you want to update the parts order for the master Excel?', 'Change Parts Order', this.handleFileSubmit)}} disabled={!file}>
                            Upload
                        </Button>
                        
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        {isLoading ? <LoadingCircle /> : 
                            <DataTable
                                config={{
                                    key:    'mepo_id',
                                    sticky: true
                                }}
                                columns={this.getColumns()}
                                rows={parts}
                            />
                        }
                    </PaddedPaper>
                </Grid>
            </Grid>
        )
    }
};

const styles = theme => ({
    card: {
        color: '#000',
        backgroundColor: '#FFF',
        padding: '0',
        width: '100%'
    },
    header: {
        background: 'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', 
        color: '#FFF'
    },
});

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(MasterExcelReport));

