/*
    data: [{
        label: label
        value: [{x: x, y: y, r: r}]
    }]
*/
import _ from 'lodash';
import React from 'react';

import { Chart, registerables } from 'chart.js';
import { Bubble } from 'react-chartjs-2';

import {getRandomColor }           from 'Helpers/ColourHelper';

Chart.register(...registerables)

const initialState = {
    options: {}
}

class BubbleChart extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {...initialState, ...this.props};

        this.usedColors = [];
        this.chartRef = React.createRef();
    }

    getData = () => {
        this.usedColors = [];
        const data = this.props.data;
        let   datasets = [];

        if (_.isArray(data)){
           _.each(data, i => {
                let backgroundColor = i?.color || (i?.backgroundColor || this.getRandomColor());
                datasets.push({
                    ...i,
                    label: i?.label ? i.label : ( this.props.label || ''),
                    data: i?.value,
                    backgroundColor: backgroundColor, 
                    borderColor: i?.borderColor || backgroundColor,
                })
           });
        } else {
            let backgroundColor = data?.color || (data?.backgroundColor || this.getRandomColor());
            datasets.push({
                ...data,
                label: data?.label ? data.label : ( this.props.label || ''),
                data: data?.value,
                backgroundColor: backgroundColor, 
                borderColor: data?.borderColor || backgroundColor,
            })
        }

        return {datasets}
    }

    getRandomColor(){
        if (this.props.color) {
            return this.props.color;    
        } else {
            let color = null;
            if (this.props.colors){
                color = this.props.colors[this.usedColors.length];
            } else {
                color = getRandomColor();
                while (this.usedColors.includes(color)){
                    color = getRandomColor();
                }
            }
            this.usedColors.push(color);
            return color;
        }
    }

    getOptions = () => {
        let options = {  
            responsive: true,
            maintainAspectRatio: false,
            plugins:{
                legend: {
                    labels: {
                        textTransform: 'capitalize'
                    }
                }
            }
        };
        if (this.props.onPointClick || this.props.onClick){
            options = {
                ...options,
                onClick: (e, elements) => {
                    if (this.chartRef.current){
                        if (this.props.onClick){
                            this.props.onClick(e,elements)
                        }

                        if (this.props.onPointClick && elements.length > 0) {
                            this.props.onPointClick(
                                {
                                    index: elements[0].index,
                                    data: this.props.data[elements[0].index],
                                }
                            );
                        }
                    }
                }
            }
        }
        if (this.props.yLabel)
            options.scales = {
                ...options.scales,
                y: {
                    ...options.scales?.y,
                    ticks:{
                        ...options.scales?.y?.ticks,
                        callback: (value, index, values) => { return this.props.yLabel(value, index, this.props.data)}
                    }
                }
            };
        if (this.props.xLabel)
            options.scales = {
                ...options.scales,
                xAxis: {
                    ...options.scales?.xAxis,
                    ticks:{
                        ...options.scales?.xAxis?.ticks,
                        callback: (value, index, values) => { return this.props.xLabel(value, index, this.props.data)},
                        autoSkip: false,
                        minRotation: 20
                    }
                    
                },
            };
        
        if (this.props.tLabel){
            options.plugins = {
                ...options.plugins,
                tooltip: {
                    ...options.plugins?.tooltips,
                    callbacks: {
                        ...options.plugins?.tooltips?.callbacks,
                        label: this.props.tLabel
                    }
                },
            }
        }

        if (this.props.hideLegend){
            options.plugins = {
                ...options.plugins,
                legend:{
                    ...options.plugins?.legend,
                    display:false
                }
            }
        }


        if (this.props.tick){
            options.scales = {
                ...options.scales,
                yAxis: {
                    ...options.scales?.yAxis,
                    ticks: {
                        ...options.scales?.yAxis?.ticks,
                        ...(_.isFunction(this.props.tick) ? {callback: this.props.tick} : {stepSize: this.props.tick})
                    }
                },
            };
        }

        if (_.find(this.props.data, i => i?.bold)){
            options.scales = {
                ...options.scales,
                xAxis: {
                    ...options.scales?.xAxis,
                    ticks:{
                        ...options.scales?.xAxis?.ticks,
                        textStrokeWidth: ({index}) => {
                            return this.props.data[index]?.bold ? 1 : 0;
                        },
                        textStrokeColor: ({index}) => {
                            return this.props.data[index]?.bold ? '#666666' : null;
                        },
                    }
                    
                },
            };
        }

        return options;
    }

    getPlugins = () => {
        let plugins = {

        };
        return plugins;
    }

    render () {
        return (
            <div style={this.props.style}>
                <Bubble 
                    data={this.getData()} 
                    options={this.getOptions()}
                    plugins={this.getPlugins()}
                    ref={this.chartRef}
                />
            </div>
        )
    }
}

export default BubbleChart;