import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import API from '../../../API';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import ViewStaffMfaStatus from '../ViewStaff/ViewStaffMfaStatus';

const initialState = {
    action: 'status',
    mfaStatus: '',
    mfaActivationDatetime: '',
    mfaActivationQr: '',
    mfaActivationSecret: '',
    formData: {
        mfaCodeInd: {
            0: ' ',
            1: ' ',
            2: ' ',
            3: ' ',
            4: ' ',
            5: ' ',
        },
        mfaCode: '',
    },
    formErrors: [],
    confirmation: {
        revoke: false,
        submit: false,
    },
    snackbar: {
        revoke: false,
        submit: false,
    }
}

class UpdateStaffMfa extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    
    componentDidMount(){        
        this.mfaCode0 = React.createRef();
        this.mfaCode1 = React.createRef();
        this.mfaCode2 = React.createRef();
        this.mfaCode3 = React.createRef();
        this.mfaCode4 = React.createRef();
        this.mfaCode5 = React.createRef();
        
        this.getMfaStatus();
    }

    getMfaStatus = () => {
        API.get('/staff/' + this.props.staffId).then(result => {
            this.setState({
                mfaStatus: result.data.staff_mfa,
                mfaActivationDatetime: result.data.staff_mfa_activation_datetime
            })
        });
    }

    handleActivateMfa = () => {
        API.get('/staff/' + this.props.staffId + '/mfa').then(result => {
            if(result.data){
                this.setState({
                    action: 'activate',
                    mfaActivationQr: result.data.mfa_qr,
                    mfaActivationSecret: result.data.mfa_secret
                }, () => this.mfaCode0.current.focus());              
            }
        });
    }

    handleRevoke = () => {
        API.get('/staff/' + this.props.staffId + '/mfa/revoke').then(result => {
            if(result.data.success){
                this.setState({
                    action: 'status',
                    snackbar: {
                        ...this.state.snackbar,
                        revoke: true
                    },
                    formErrors: [],
                    formData: {
                        ...this.state.formData,
                        mfaCode: '',
                        mfaCodeInd: {
                            0: ' ',
                            1: ' ',
                            2: ' ',
                            3: ' ',
                            4: ' ',
                            5: ' '
                        }
                    }
                }, () => this.getMfaStatus());                
            }
        });
    }

    handleVerifyMfa = () => {       
        const mfaCode = this.state.formData.mfaCodeInd;
        this.setState({
            formData: {
                ...this.state.formData,
                mfaCode: mfaCode[0]+mfaCode[1]+mfaCode[2]+mfaCode[3]+mfaCode[4]+mfaCode[5]
            }            
        }, () => this.handleVerifyMfaSubmit());
    }

    handleVerifyMfaSubmit = () => {   
        API.post('/staff/' + this.props.staffId + '/mfa/verify', this.state.formData)
        .then(result => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),                    
                    formData: {
                        ...this.state.formData,
                        mfaCode: '',
                        mfaCodeInd: {
                            0: ' ',
                            1: ' ',
                            2: ' ',
                            3: ' ',
                            4: ' ',
                            5: ' '
                        }
                    }
                }, () => {
                    if(this.state.formErrors['mfaCode']){
                        this.mfaCode0.current.focus();
                    }
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbar: {
                        submit: true
                    }
                }, () => this.getMfaStatus());
            }
        });
    }

    handleMfaChange = (id, e) => {
        if(id === 0 && e.target.value.length >= 1) {
            this.mfaCode1.current.focus();
        } else if(id > 0 && id < 5 && e.target.value.length >= 1) {
            this['mfaCode'+(id+1)].current.focus();            
        } else if(id > 0) {            
            this['mfaCode'+(id-1)].current.focus();  
        }
        this.setState({
            formData: {
                ...this.state.formData,
                mfaCodeInd: {
                    ...this.state.formData.mfaCodeInd,
                    [id]: e.target.value.length > 0 ? (e.target.value.trim().length === 1 ? e.target.value.trim() : e.target.value.trim()[1]) : ' '
                },
            }
        }, () => {
            if(id === 5 && this.state.formData.mfaCodeInd[5] !== ' ') { 
                this.handleVerifyMfa(false);
            }
        });
    }

    handleSnackbarClose = (type) => {
        this.setState({
            snackbar: {
                ...this.state.snackbar,
                [type]: false
            }
        });
    };

    handleConfirmationOpen = (type) => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: true,
            }
        });
    };

    handleConfirmationClose = (type) => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false,
            }
        });
    };

    handleConfirmationSuccess = (type) => {
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                [type]: false,
            }
        }, () => {
            if(type === 'revoke') {
                this.handleRevoke();
            } else {
                this.handleActivateMfa();
            }
        });
    }

    render() {
        const { action, mfaStatus, formData, formErrors, mfaActivationDatetime, mfaActivationQr, mfaActivationSecret, confirmation, snackbar } = this.state;
        return (
            <React.Fragment>
                {(action === 'status' && (
                    <Grid container spacing={3} style={{marginTop: 12}}>
                        <Grid item xs={12}>
                            <ViewStaffMfaStatus status={[mfaStatus,mfaActivationDatetime]} />
                            <div className='buttonRow'>
                                {mfaStatus === 1 &&
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={() => this.handleConfirmationOpen('revoke')}
                                    > 
                                        Revoke
                                    </Button>
                                }                            
                                {mfaStatus === 0 &&
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={() => this.handleConfirmationOpen('submit')}
                                    > 
                                        Activate 
                                    </Button>
                                }
                            </div>
                        </Grid>
                    </Grid>
                )) || (
                    <React.Fragment>
                        <Grid container spacing={3} style={{marginTop: 10}}>
                            <Grid item align='center'>
                                <img src={mfaActivationQr} width='150' height='150' alt='Mfa Qr Code' /><br />
                                {mfaActivationSecret}
                            </Grid>
                            <Grid item xs>          
                                <Typography variant="body1">
                                    Please scan the QR code with your authenticator app and enter a one time code below to complete multi-factor authentication activation.
                                </Typography>
                                {this.state.formErrors.mfaCode && 
                                    <Typography variant="body2" color="error">
                                        {this.state.formErrors.mfaCode}
                                    </Typography>
                                }    
                                <Grid item container xs={12} spacing={window.innerWidth < 600 ? 1 : 3} style={{marginTop: 12, marginBottom: 12}} align='center'>
                                    <Grid item xs={2}>
                                        <TextField
                                            id="mfa-0"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            autoComplete="off"
                                            value={formData.mfaCodeInd[0]}
                                            error={formErrors && formErrors['mfaCode'] && true}
                                            onChange={(e) => this.handleMfaChange(0, e)} 
                                            className='mfaInput'
                                            inputProps={{
                                                ref:this.mfaCode0,
                                                size:2,
                                                maxLength:2,
                                                style: {
                                                    textAlign: 'center'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            id="mfa-1"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            autoComplete="off"
                                            className='mfaInput'
                                            value={formData.mfaCodeInd[1]}
                                            error={formErrors && formErrors['mfaCode'] && true}
                                            onChange={(e) => this.handleMfaChange(1, e)} 
                                            inputProps={{
                                                ref:this.mfaCode1,
                                                size:2,
                                                maxLength:2,
                                                style: {
                                                    textAlign: 'center'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            id="mfa-2"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="mfaCode3"
                                            autoComplete="off"
                                            className='mfaInput'
                                            value={formData.mfaCodeInd[2]}
                                            error={formErrors && formErrors['mfaCode'] && true}
                                            onChange={(e) => this.handleMfaChange(2, e)} 
                                            inputProps={{
                                                ref:this.mfaCode2,
                                                size:2,
                                                maxLength:2,
                                                style: {
                                                    textAlign: 'center'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            id="mfa-3"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            autoComplete="off"
                                            className='mfaInput'
                                            value={formData.mfaCodeInd[3]}
                                            error={formErrors && formErrors['mfaCode'] && true}
                                            onChange={(e) => this.handleMfaChange(3, e)} 
                                            inputProps={{                                                                        
                                                ref:this.mfaCode3,
                                                size:2,
                                                maxLength:2,
                                                style: {
                                                    textAlign: 'center'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            id="mfa-4"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            autoComplete="off"
                                            className='mfaInput'
                                            value={formData.mfaCodeInd[4]}
                                            error={formErrors && formErrors['mfaCode'] && true}
                                            onChange={(e) => this.handleMfaChange(4, e)} 
                                            inputProps={{
                                                ref:this.mfaCode4,
                                                size:2,
                                                maxLength:2,
                                                style: {
                                                    textAlign: 'center'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            id="mfa-5"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            autoComplete="off"
                                            className='mfaInput'
                                            value={formData.mfaCodeInd[5]}
                                            error={formErrors && formErrors['mfaCode'] && true}
                                            onChange={(e) => this.handleMfaChange(5, e)} 
                                            inputProps={{
                                                ref:this.mfaCode5,
                                                size:2,
                                                maxLength:2,
                                                style: {
                                                    textAlign: 'center'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <div className="buttonRow">
                                    <Button 
                                        variant="outlined" 
                                        color="secondary" 
                                        onClick={() => this.handleRevoke()}
                                    > 
                                        Cancel
                                    </Button>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={() => this.handleVerify()}
                                    > 
                                        Confirm
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>                        
                    </React.Fragment>
                )}
                    <ConfirmationDialog 
                        open={confirmation['revoke']} 
                        success={() => this.handleConfirmationSuccess('revoke')} 
                        close={() => this.handleConfirmationClose('revoke')} 
                        title="Revoke Multi-Factor Authentication?" 
                        message="Are you sure you want to revoke multi-factor authentication for this account?"
                    />                    
                    <ConfirmationDialog 
                        open={confirmation['submit']} 
                        success={() => this.handleConfirmationSuccess('submit')} 
                        close={() => this.handleConfirmationClose('submit')} 
                        title="Enable Multi-Factor Authentication?" 
                        message="Are you sure you want to enable multi-factor authentication for this account?"
                    />                    
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={snackbar['revoke']}
                        onClose={() => this.handleSnackbarClose('revoke')}
                        message="The staff member's multi-factor authentication has been revoked"
                    />
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={snackbar['submit']}
                        onClose={() => this.handleSnackbarClose('submit')}
                        message="The staff member's multi-factor authentcation has been enabled"
                    />
            </React.Fragment>
        )
    }
}

export default UpdateStaffMfa;