import {AppBar, Button} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import TransactionTables from './TransactionTables';
import { Link } from 'react-router-dom';
import BackButton from 'Components/Common/Buttons/BackButton';

const styles = theme => ({
    td : {
        border: 'none'
    },
    settingsHeading: {
        paddingTop: theme.spacing(2)
    }
});

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

class UnassignedTransactionView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { bankDetails, currentTab, access, transactionsAuthorisation, transactionsConfirmation, transactionsCompleted, transactionsAuthorisationByStaff,
            handleTabChange, handleDialogTransactionOpen, handleDeleteOpen, downloadPresignedFile } = this.props;
        return (
            <>
            <Grid container item spacing={3}>
                <Grid item xs={12} lg={3}>
                    <PaddedPaper>
                        <Grid container alignItems='center' spacing={1}>
                            <Grid item align='center'>
                                <FALightIcon icon='credit-card' style={{marginLeft: 8}} size='large' />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    Bank / Card
                                </Typography>  
                                <Typography variant="body1">
                                    {bankDetails?.bank_name}
                                </Typography>  
                            </Grid>         
                        </Grid>
                    </PaddedPaper>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {!access.allStaffTransactions &&
                    <>
                    <Typography variant="caption">
                        <FALightIcon icon='info-circle' size="small" />You will only be able to view transactions made by you
                    </Typography>
                    <br></br><br></br>
                    </>
                }
                <Paper>
                    <AppBar position="static" style={{backgroundColor:'white'}} >
                        <Tabs value={currentTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                            <Tab label={`Awaiting Authorisation ${access.allStaffTransactions ? `(${transactionsAuthorisation && transactionsAuthorisation.length})`: ''}`}/>
                            {access.allStaffTransactions && <Tab label={`Awaiting Confirmation (${transactionsConfirmation && transactionsConfirmation.length})`} />}
                            {access.allStaffTransactions && <Tab label={`Awaiting Assignment (${transactionsCompleted && transactionsCompleted.length})`} />}
                        </Tabs>
                    </AppBar>
                    {currentTab === 0 && (
                        <TabContainer>
                            <TransactionTables
                                transactions={transactionsAuthorisationByStaff}
                                editOnClick={handleDialogTransactionOpen}
                                access={access}
                                status="Authorisation"
                                actions={ rowData => {
                                    return [
                                        {name: 'View', icon: 'search', onClick:() => handleDialogTransactionOpen(rowData, "Authorisation") },
                                        {name: 'Delete Transaction', icon: 'trash-alt', onClick:() => handleDeleteOpen(rowData) }
                                    ]
                                }}
                            />
                        </TabContainer>
                    )}
                    {currentTab === 1 && (
                        <TabContainer>
                            <Grid container item spacing={3}>
                                <Grid item xs={12}>
                                    <TransactionTables
                                        transactions={transactionsConfirmation}
                                        actions={ rowData => {
                                            return [
                                                {name: 'View', icon: 'search', onClick:() => handleDialogTransactionOpen(rowData, "Confirmation") },
                                                {name: 'Delete Transaction', icon: 'trash-alt', onClick:() => handleDeleteOpen(rowData) }
                                            ]
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </TabContainer>
                    )}
                    {currentTab === 2 && (
                        <TabContainer>
                            <Grid container item spacing={3}>
                                <Grid item xs={12}>
                                    <TransactionTables
                                        transactions={transactionsCompleted}
                                        actions={ rowData => {
                                            return [
                                                {name: 'View', icon: 'search', onClick:() => handleDialogTransactionOpen(rowData, "Completed") },
                                                {name: 'Download Receipt', icon: 'download', onClick: () => {downloadPresignedFile(rowData.receipt_file_url)}},
                                                {name: 'Delete Transaction', icon: 'trash-alt', onClick:() => handleDeleteOpen(rowData) }
                                            ]
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </TabContainer>
                    )}
                    <div className='buttonRow'>
                        <BackButton body={'back'} props={this.props} style={{margin: 24}} />
                    </div>
                </Paper>
            </Grid>
            </>
        )
    }
}
export default withStyles(styles)(UnassignedTransactionView);