import React, { Fragment, useContext, useEffect, useState } from 'react';
import API from 'API';
import {
    Box,
    Grid,
    IconButton,
    InputAdornment,
    Tooltip,
    Typography
} from '@material-ui/core';
import _   from 'lodash';
import { colors } from 'Helpers/ColourHelper';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import WildixContext from 'Context/WildixContext';


export function cloneObject(obj) {
    var clone = {};
    for(var i in obj) {
        if(obj[i] != null &&  typeof(obj[i])=="object")
            clone[i] = cloneObject(obj[i]);
        else
            clone[i] = obj[i];
    }
    return clone;
}

export function displayFileSize(initialSize) {
    if(initialSize !== "Unknown") {
        if(initialSize === 0) {
            return "Unknown";
        } else {                
            var i = Math.floor(Math.log(initialSize) / Math.log(1024));
            var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];   
            return (initialSize / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];                 
        }  
    } else {
        return "Unknown";
    }
}

export function downloadS3File(fileUrl) {
    // Safari
    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
        var windowReference = window.open();
        API.get('/getS3PresignedFileUrl/?download=true&request='+btoa(fileUrl))
        .then(result => {
            windowReference.location = atob(result.data.download_url);
        });
    }
    // Other Browsers
    else {
        API.get('/getS3PresignedFileUrl/?download=true&request='+btoa(fileUrl))
        .then(result => {
            window.location.assign(atob(result.data.download_url));
        });
    }    
}

export const excelColumnIndexToLetter = num => {

    let letters = ''

    while (num >= 0) {
        letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
        num = Math.floor(num / 26) - 1
    }

    return letters

}

export const excelFitToColumns = (data) => {
    return data[0].map((a, i) => ({ wch: Math.max(...data.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
}

export const unpackObjectUsingString = (obj, str) => {
    if (!str)               return null;
    if (!str.includes('.')) return obj[str];
    let item = obj;
    _.each(str.split('.'), i => { 
        if (item && Object.keys(item).includes(i)) item = item[i]; 
        else return null 
    });
    
    return item
}

export const checkTrueFalse = data => {

    if (!data) return false;

    let t = ['Y','y','active','true','TRUE'];
    let f = ['F','f','inactive','false','FALSE'];

    if (f.includes(data)) return false;
    if (t.includes(data)) return true;

    if (data < 0) return false;

    return !!data;
}

//check if number is nan or infinate, if so return default
export const convertRealNumber = ( number, def = 0 ) => {
    return ( !isFinite(number) || isNaN(number) ) ? def : number;
}


export const isTouchDevice = () => {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
}

export function toggleDialog(dialog, loadOnExit) {
    let closing = this.state.dialog[dialog]
    this.setState({
        dialog: {
            ...this.state.dialog,
            [dialog]: !this.state.dialog[dialog]
        }
    }, () => {
        if(closing && !loadOnExit) {
            this.loadComponentData(true)
        }
    })
}

export const TabPanel = ({children, value, index, ...other}) => (
    <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        {...other}
    >
        {value === index && (
            <Box>
                {children}
            </Box>
        )}
    </Typography>
)

export function handlePopperClick(event) {
    let anchorEl = null;
    if(!this.state.anchorEl) {
        anchorEl = event.currentTarget;
    }
    this.setState({ anchorEl });
}

export function handlePopperClose() {
    this.setState({ anchorEl: null });
}

export const isTablet = () => {
    return (/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(navigator.userAgent.toLowerCase())) || (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)) || window.innerWidth < 1400;
}

export const isTouchMonitor = () => {
    return ((/((windows(.*touch)))/.test(navigator.userAgent.toLowerCase())) || isTouchDevice()) && window.innerWidth > 1400;
}

export const base64ImgtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

export const contrastingFontColor = hex => {
    var threshold = 130;
    var hRed = hexToR(hex);
    var hGreen = hexToG(hex);
    var hBlue = hexToB(hex);

    function hexToR(h) {return parseInt((cutHex(h)).substring(0,2),16)}
    function hexToG(h) {return parseInt((cutHex(h)).substring(2,4),16)}
    function hexToB(h) {return parseInt((cutHex(h)).substring(4,6),16)}

    function cutHex(h) {return (h.charAt(0)=="#") ? h.substring(1,7):h}

    var cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000

    if (cBrightness > threshold) return colors.black
    else return colors.white
}

export const isMobile = () => {
    return window.innerWidth < 600;
};

export const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export function useKeyPress(targetKey) {
    const [keyPressed, setKeyPressed] = useState(false);

    function downHandler({ key }) {
        if (key === targetKey) {
            setKeyPressed(true);
        }
    }

    const upHandler = ({ key }) => {
        if (key === targetKey) {
            setKeyPressed(false);
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);

        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, []); /* eslint-disable-line */

    return keyPressed;
}


export const MultiDial = ({numbers}) => (
    !numbers || !numbers?.length ? '-' : (
        <Grid container spacing={1}>
            {numbers.map((i, k) => (
                <Fragment key={k}>
                    <Grid item>
                        <Dial spacing={0} number={i} />
                    </Grid>
                    {k < numbers.length - 1 && (
                        <Grid item>
                            <Typography variant="body1">/</Typography>
                        </Grid>
                    )}
                </Fragment>
            ))}   
        </Grid>
    )
)

export const Dial = ({number, spacing = 1}) => {
    const context = useContext(WildixContext);
    const canDial = context?.dial === null;
    return (
        canDial && number && number.length && number !== '-' ? (
        <Grid container spacing={spacing} alignItems='center' wrap='nowrap'>
            <Grid item>
                <Tooltip title={`Dial ${number}`}>
                    <IconButton onClick={() => context.useDial(number)} size="small">
                        <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                {number}
            </Grid>
        </Grid>
        ) : number
    )
}

export const DialAdornment = ({number}) => {
    const context = useContext(WildixContext);
    const canDial = context?.dial === null;
    return (
        canDial && number && number.length && number !== '-' ? (
            <InputAdornment position="start">
                <Tooltip title={`Dial ${number}`}>
                    <IconButton onClick={() => context.useDial(number)} size="small">
                        <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin />
                    </IconButton>
                </Tooltip>
            </InputAdornment>
        ) : null
    )
}