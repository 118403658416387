import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import API from 'API';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import DataTable from 'Components/Common/DataTables/DataTable';

import _ from 'lodash';
import IconHelper from 'Helpers/IconHelper';
import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { connect } from 'react-redux';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

function SerialsTable({data, releaseSn, released}) {
    return(
        <DataTable  
            config={{
                key: 'id',
                alternatingRowColours: true,
            }}
            columns={_.filter([
                {
                    heading: 'Serial Number',
                    field: rowData => rowData.serial,
                    truncate: true
                },
                {
                    heading: 'Date Added',
                    field: rowData => rowData.date,
                    fieldFormat: 'date',
                    sizeToContent: true,
                    alignment: 'center'
                },
                released && {
                    heading: 'Date Removed',
                    field: rowData => rowData.dateReleased,
                    fieldFormat: 'date',
                    sizeToContent: true,
                    alignment: 'center'
                },
                releaseSn && {
                    actions: i => ([
                        {
                            name: 'Set Never Used',
                            icon: IconHelper.false,
                            onClick: releaseSn(i.id),
                            disabled: i.used
                        }
                    ])
                }
            ], i => i)}
            rows={data}
        />
    )
}

const initialState = {
    value: 0,
    title: '',
    data: [],
    isLoading: false
}

class ViewSerialNumbers extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData = () => {
        this.setState({
            ...this.state,
            isLoading: true
        }, 
        () => {
            API.get(`/parts/serialNumbers/modelTypes/${this.props.match.params.modelTypeId}`)
            .then(result => {
                let formattedData = [];
                let status = '';

                result.data.serial_numbers.forEach(el => {
                    status = el.psn_serial_number_status.replace(' ', '_').toLowerCase();
    
                    if(!formattedData[status]) formattedData[status] = [];

                    formattedData[status].push({
                        id: el.psn_id,
                        serial: el.psn_serial_number,
                        date: el.psn_created_at_datetime,
                        used: parseInt(el.history_count) > 0,
                        dateReleased: el.psn_released_at_datetime
                    });
                });

                this.setState({
                    ...this.state,
                    title: result.data.page_title,
                    data: formattedData,
                    isLoading: false
                });

            });
        });
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    releaseSn = (id) => () => {
        let sn = _.find(
            this.state.data['issued'],
            {
                id: id
            }
        )
        this.props.deployConfirmation(
            'Are you sure you want to release this serial number, it will render it unusable?',
            `Release ${sn.serial}`,
            this.handleReleaseSn(id)
        )
    }

    handleReleaseSn = (id) => () => {
        API.post(`/parts/serialNumbers/serialNumber/${id}/release`).then(this.loadComponentData);
    }

    render() {
        const { value, title, data, isLoading } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {title} 
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        {isLoading ? (
                            <LoadingCircle />
                        ) : (
                            <>
                                <AppBar position="static" style={{backgroundColor:'white'}}>
                                    <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" textColor="primary">


                                        <Tab label={`Issued (${data['issued']?.length ?? 0})`} />
                                        <Tab label={`In Stock (${data['in_stock']?.length ?? 0})`} />
                                        <Tab label={`Sold (${data['sold']?.length ?? 0})`} />
                                        <Tab label={`Replaced (${data['replaced']?.length ?? 0})`} />
                                        <Tab label={`Written Off (${data['written_off']?.length ?? 0})`} />
                                        <Tab label={`Condemned (${data['condemned']?.length ?? 0})`} />
                                        <Tab label={`Never Used (${data['released']?.length ?? 0})`} />
                                        
                                        
                                        
                                    </Tabs>
                                </AppBar>
                                { value === 0 && <TabContainer><SerialsTable data={data['issued']} releaseSn={this.releaseSn}/></TabContainer>}
                                { value === 1 && <TabContainer><SerialsTable data={data['in_stock']} /></TabContainer> }
                                { value === 2 && <TabContainer><SerialsTable data={data['sold']} /></TabContainer> }
                                { value === 3 && <TabContainer><SerialsTable data={data['replaced']} /></TabContainer>}
                                { value === 4 && <TabContainer><SerialsTable data={data['written_off']} /></TabContainer>}
                                { value === 5 && <TabContainer><SerialsTable data={data['condemned']} /></TabContainer> }
                                { value === 6 && <TabContainer><SerialsTable data={data['released']} released /></TabContainer>}
                                <div className="buttonRow">
                                    <Link to="/parts/serial-numbers">
                                        <Button 
                                            variant="outlined"
                                            className="m-2"
                                        >
                                            Back
                                        </Button>
                                    </Link>
                                </div>
                            </>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation:     (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
    }
}

export default connect(null, mapDispatchToProps)(ViewSerialNumbers);

