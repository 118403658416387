import API from 'API';
import React from 'react';
import { Grid, Typography, Button, DialogContent, DialogActions, Dialog, DialogTitle, TextField } from '@material-ui/core';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import InputHelper from 'Helpers/InputHelper';
import IconHelper from 'Helpers/IconHelper';
import { connect } from 'react-redux';
import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';
import { Dial } from 'Functions/MiscFunctions';
import Textarea from 'Components/Common/Inputs/Textarea';
import _ from 'lodash';
import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

const initialState = props => ({
    isLoading: false,
    templates: [],
    filter: {
        keywords: '',
        type: 'All' 
    },
    isLoading: true,
    templateDialog: {
        open: false,
        edit: false,
        data: {},
    },
    editTemplateFormData: {
        name: '',
        subject: '',
        body: ''
    },
    lists: {
        areas: []
    },
    access: {
        'update-email-template': false
    }
});
class EmailTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
        this.inputHelper = new InputHelper(this);
    }

    componentDidMount(){
        this.inputHelper.handleSetAccess(_.keys(this.state.access))
        this.getTemplates();
    }

    getTemplates = () => {
        API.get('/email/emailTemplates', {params: this.state.filter})
        .then(result => {
            this.setState({
                templates: result.data,
                isLoading: false,
                lists: {
                    areas: this.state.lists.areas.length > 0 ? this.state.lists.areas : [
                        { value: 'All', label: 'All' },
                        ..._.map(_.uniqBy(result.data,'et_type'), i => ({ value: i.et_type, label: i.et_type }))
                    ]
                }
            })
        });
    };

    showTemplateDialog = (edit) => (i) => {
        this.setState({
            templateDialog: {
                open: true,
                edit: edit,
                data: i,
            },
            editTemplateFormData: {
                name: i.et_name,
                subject: i.et_subject,
                body: i.et_body
            }
        })
    }

    updateTemplate = () => {
        API.post(
            `/email/emailTemplates/${this.state.templateDialog.data.et_id}`,
            this.state.editTemplateFormData
        ).then(result => {
            this.setState({
                templateDialog: initialState(this.props).templateDialog,
                isLoading: true
            }, () => {
                this.getTemplates();
            })
        })
    }

    render() {
        const { isLoading, templates, templateDialog, editTemplateFormData, lists, access } = this.state;

        if (isLoading) return ( <LoadingCircle/> )
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Email Templates
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>

                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    label='Search'
                                    placeholder='Start typing to search...'
                                    value={this.state.filter.keywords}
                                    name='filter.keywords'
                                    onChange={this.inputHelper.handleReactionChange('filter.keywords', this.getTemplates)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <AutoCompleteSelect
                                    value={this.state.filter.type}
                                    name='filter.type'
                                    onChange={this.inputHelper.handleReactionChange('filter.type', this.getTemplates)}
                                    options={lists.areas}
                                    fullWidth
                                    noClear
                                    label='Area'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CiDataTable
                                    config={{ 
                                        key: 'et_id',
                                    }}
                                    rows={_.orderBy(templates, ['et_type','et_name', 'et_subject'], ['asc','asc', 'asc'])}
                                    columns={[
                                        {
                                            heading: 'Area',
                                            field: 'et_type',
                                            dataRef: 'et_type',
                                            sizeToContent: true
                                        },
                                        // {
                                        //     heading: 'Name',
                                        //     field: 'et_name',
                                        //     dataRef: 'et_name',
                                        //     sizeToContent: true
                                        // },
                                        {
                                            heading: 'Subject',
                                            field: 'et_subject',
                                            dataRef: 'et_subject',
                                        },
                                        {
                                            actions: i => (_.filter([
                                                {
                                                    icon: IconHelper.search,
                                                    name: 'View',
                                                    onClick: this.showTemplateDialog(false)
                                                },
                                                access['update-email-template'] &&
                                                {
                                                    icon: IconHelper.edit,
                                                    name: 'Edit',
                                                    onClick: this.showTemplateDialog(true)
                                                }
                                            ], i => i))
                                        }
                                    ]}
                                />

                            </Grid>
                        </Grid>

                    </PaddedPaper>
                </Grid>
                <Dialog
                    open={templateDialog.open}
                    maxWidth='sm'
                    fullWidth
                >
                    <DialogTitle>
                        {templateDialog.edit ? 'Edit ' : 'View '} Email Template
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label='Area'
                                    value={templateDialog?.data?.et_type}
                                    name='templateDialog?.data?.et_type'
                                    onChange={null}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <TextField
                                    label='Name *'
                                    value={editTemplateFormData.name}
                                    name='name'
                                    onChange={templateDialog.edit ? (e) => {
                                        this.setState({
                                            editTemplateFormData: {
                                                ...editTemplateFormData,
                                                [e.target.name]: e.target.value
                                            }
                                        })
                                    } : ()=>{}}
                                    fullWidth
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <TextField
                                    label='Subject *'
                                    value={editTemplateFormData.subject}
                                    name='subject'
                                    onChange={templateDialog.edit ? (e) => {
                                        this.setState({
                                            editTemplateFormData: {
                                                ...editTemplateFormData,
                                                [e.target.name]: e.target.value
                                            }
                                        })
                                    } : ()=>{}}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Textarea
                                    label='Body *'
                                    value={editTemplateFormData.body}
                                    name='body'
                                    onChange={templateDialog.edit ? (e) => {
                                        this.setState({
                                            editTemplateFormData: {
                                                ...editTemplateFormData,
                                                [e.target.name]: e.target.value
                                            }
                                        })
                                    } : ()=>{}}
                                    fullWidth
                                    rows={5}
                                    style={{marginTop: 0}}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='outlined'
                            onClick={this.inputHelper.handleButtonChange('templateDialog', initialState(this.props).templateDialog)}
                        >Close</Button>
                        {templateDialog.edit &&
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={()=>this.props.deployConfirmation(
                                    'Are you sure you want to update this template?',
                                    'Update Template',
                                    this.updateTemplate
                                )}
                                disabled={!editTemplateFormData.subject || !editTemplateFormData.body}
                            >
                                Update
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation:     (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
    }
}

export default connect(null, mapDispatchToProps)(EmailTemplates);