import React, { Component }                     from 'react';
import { connect }                              from 'react-redux';
import API                                      from 'API';
import _                                        from 'lodash';
import moment                                   from 'moment';

import { closeDialog }                          from 'Actions/Dialog/Dialog';

import LoadingCircle                            from 'Components/Common/LoadingCircle/LoadingCircle';
import DataTable                                from 'Components/Common/DataTables/CiDataTable';

import { Grid, Button, Divider, Tabs, Tab, AppBar, Typography, Link, Tooltip, FormControl, TextField, ButtonGroup} from '@material-ui/core/'

const initialState = {
    history:     [],
    logs:        [],
    isLoading:   true,
    currentTab:  0
}

class CreditLimitHistory extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getCreditLimitHistory();
        this.getCreditStatementHistory();
    }

    getCreditLimitHistory = () => {
        API.get(`/customers/${this.props.customerId}/accounts/addresses/${this.props.addressId}/creditLimit/history`)
        .then( res => {
            this.setState({
                history:    res.data,
                isLoading:  false
            })
        })
    }

    getCreditStatementHistory = () => {
        API.get(`/customers/${this.props.customerId}/accounts/addresses/${this.props.addressId}/statement/history`)
        .then( res => {
            this.setState({
                logs:       res.data,
                isLoading:  false
            })
        })
    }

    handleTabChange = (event, value)  => { this.setState({currentTab: value}); };

    render() {
        const {history, isLoading, currentTab, logs} = this.state;
        return (
            <>
                {isLoading ? <LoadingCircle/> : 
                    <Grid container xs={12}>
                        <Grid item xs={12} lg={12}>
                            <AppBar position="static" style={{backgroundColor:'white', color:"#000", padding: 0}}>
                                <Tabs   value={currentTab} 
                                        onChange={this.handleTabChange} 
                                        className='xs-max-width' 
                                        indicatorColor="primary" 
                                        textColor="primary" 
                                        variant='scrollable'
                                        scrollButtons="auto"
                                >
                                    <Tab label={<Typography> Account Statements ({logs?.account?.length})</Typography>} />
                                    <Tab label={<Typography> Activity Statements ({logs?.activity?.length})</Typography>} />
                                    <Tab label={<Typography> Limits ({history.length})</Typography>} />
                                </Tabs>
                            </AppBar>
                        </Grid>
                        {currentTab === 2 &&
                            <Grid item xs={12} lg={12} >
                                <DataTable
                                    config={{
                                        key:            'cl_id',
                                        pagination:     false,
                                        plainHeader:    false,
                                        isLoading:      isLoading
                                    }}
                                    columns={[
                                        {
                                            heading: 'Date',
                                            field: rowData => `${moment(rowData.ccl_created_datetime).format("DD/MM/YYYY")}`
                                        },
                                        {
                                            heading: 'Net Limit',
                                            field: rowData => `£${rowData.ccl_net}`
                                        },
                                        {
                                            heading: 'Gross Limit',
                                            field: rowData => `£${rowData.ccl_gross}`
                                        },
                                        {
                                            heading: 'Set By',
                                            field: rowData => `${rowData.ccl_created_by_staff_name}`,
                                            important: true,
                                        },
                                    ]}
                                    rows={history}
                                />
                            </Grid>
                        }
                        {currentTab === 1 && 
                            <Grid item xs={12} lg={12}>
                                <DataTable
                                    config={{
                                        key:            'cl_id',
                                        pagination:     false,
                                        plainHeader:    false,
                                        isLoading:      isLoading
                                    }}
                                    columns={[
                                        {
                                            heading: 'Sent',
                                            field: rowData => <>
                                                {moment(rowData.ccaassl_sent_datetime).format("DD/MM/YYYY")}
                                            </>
                                        },
                                        {
                                            heading: 'From',
                                            field: rowData => `${moment(rowData.ccaassl_from).format("DD/MM/YYYY")}`
                                        },
                                        {
                                            heading: 'To',
                                            field: rowData => `${moment(rowData.ccaassl_to).format("DD/MM/YYYY")}`
                                        },
                                        {
                                            heading: 'Invoice',
                                            field: rowData => `£${parseFloat(rowData.ccaassl_invoice).toFixed(2)}`
                                        },
                                        {
                                            heading: 'Paid',
                                            field: rowData => `£${parseFloat(rowData.ccaassl_paid + rowData.ccaassl_credited).toFixed(2)}`
                                        },
                                        {
                                            heading: 'Outstanding',
                                            field: rowData => `£${parseFloat(rowData.ccaassl_remaining).toFixed(2)}`
                                        },
                                        {
                                            heading: 'Set To',
                                            field: rowData => `${rowData.ccaassl_contact_name}`,
                                            important: true,
                                        },
                                        {
                                            heading: 'Set By',
                                            field: rowData => `${rowData.ccaassl_staff_name}`,
                                            important: true,
                                        },
                                    ]}
                                    rows={_.orderBy(logs.activity, i => {return moment(i.ccaassl_sent_datetime).format("YYYYMMDDHHmmss");}, 'desc')}
                                />
                            </Grid>
                        }
                        {currentTab === 0 &&
                            <Grid item xs={12} lg={12} >
                                <DataTable
                                    config={{
                                        key:            'cl_id',
                                        pagination:     false,
                                        plainHeader:    false,
                                        isLoading:      isLoading
                                    }}
                                    columns={[
                                        {
                                            heading: 'Sent',
                                            field: rowData => <>
                                                {moment(rowData.ccssl_sent_datetime).format("DD/MM/YYYY")} at {moment(rowData.ccssl_sent_datetime).format("LT")}
                                            </>
                                        },
                                        {
                                            heading: 'Credit',
                                            field: rowData => `£${rowData.ccssl_credit}`
                                        },
                                        {
                                            heading: 'Limit',
                                            field: rowData => `£${rowData.credit_limit.ccl_gross}`
                                        },
                                        {
                                            heading: 'Set To',
                                            field: rowData => `${rowData.ccssl_received_contact_name}`,
                                            important: true,
                                        },
                                        {
                                            heading: 'Set By',
                                            field: rowData => `${rowData.ccssl_staff_name}`,
                                            important: true,
                                        },
                                    ]}
                                    rows={_.orderBy(logs.account, i => {return moment(i.ccssl_sent_datetime).format("YYYYMMDDHHmmss");}, 'desc')}
                                />
                            </Grid>
                        }
                        <Grid container xs={12} style={{justifyContent: 'flex-end', marginTop: '1.5em'}}>
                            <Grid item>
                                <Button onClick={this.props.closeDialog} variant="outlined" >Close</Button>
                            </Grid>
                        </Grid>
                    </Grid> 
                }
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({closeDialog: () => dispatch(closeDialog())})
export default connect(null, mapDispatchToProps)(CreditLimitHistory);