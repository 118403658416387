import React from 'react';
import Typography from '@material-ui/core/Typography';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import SnackBar from './../../Common/SnackBars/SnackBar'
import Button from '@material-ui/core/Button';
import API from '../../../API';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import _ from 'lodash';
import NominalCodeList from './NominalCodeList';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import {  connect } from 'react-redux';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}



const initialState = () => ({
    formData: {
        addCategory: {
            name: ''
        },
        addNominalCode: {
            code: '',
            name: ''
        }
    },
    formErrors: {
        addCategory: {},
        addNominalCode: {}
    },
    snackbarOpen: {
        addCategory: false,
        addNominalCode: false
    },
    confirmationOpen: {
        addCategory: false,
        addNominalCode: false
    },
    categories: {},
    categoryList: [],
    currentTab: 0,
    access: {
        addNominalCategory: false,
        addNominalCode: false
    },
    codes: {},
    isLoading: true
});

class NominalCodes extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'accounts:NominalCodes';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.checkAccess();
        this.getCategories();
        this.getNominalCodes();
    }

    getNominalCodes = () => {
        API.get('/accounts/nominalCodes/byCategory')
        .then(result => {
            if(result.data) {
                this.setState({
                    codes: result.data
                });
            }
        });
    };

    getCategories = () => {
        API.get('/accounts/nominalCodes/categories/all').then(result => {
            if(result.data) {
                let categoryList = _.map(result.data, (el) => {
                    return _.assign({
                        value: el.nom_cat_id,
                        label: el.nom_cat_name
                    });
                });
                this.setState({
                    categories: result.data,
                    categoryList: categoryList
                });
            }
        });
    };
    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/nominal-codes:add-category'), 
            API.get('/staff/my/access/check/nominal-codes:add-code'),
        ])
        .then(([categoryRes, codeRes]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    addNominalCategory: categoryRes.data ? categoryRes.data.has_access : false,
                    addNominalCode: codeRes.data ? codeRes.data.has_access : false,
                },
                isLoading: false
            });
        });
    }
    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        },() => { this.savePageState() });
    };
    /*
     * Add Category
     */
    addCategory = () => {
        API.post('/accounts/nominalCodes/categories', this.state.formData.addCategory).then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        addCategory: formatValidationErrors(result.data.errors)
                    }
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: {
                        ...this.state.snackbarOpen,
                        addCategory: true
                    }
                });
                this.checkAccess();
                this.getCategories();
                this.getNominalCodes();
            }
            this.props.scrollToTop();
        });
    }
    handleAddCategoryChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                addCategory: {
                    ...this.state.formData.addCategory,
                    [e.target.name]: e.target.value
                }
            }
        });
    };
    handleAddCategorySnackbarClose = () => {
        this.setState({
            snackbarOpen: {
                ...this.state.snackbarOpen,
                addCategory: false
            }
        });
    };
    handleAddCategoryConfirmationOpen = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addCategory: true
            }
        });
    };
    handleAddCategoryConfirmationClose = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addCategory: false
            }
        });
    };
    handleAddCategoryConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addCategory: false
            }
        });
        this.addCategory();
    };
    /*
     * Add Nominal Code
     */
    addNominalCode = () => {
        API.post('/accounts/nominalCodes', this.state.formData.addNominalCode).then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        addNominalCode: formatValidationErrors(result.data.errors)
                    }
                });
            } else {
                this.setState({
                    ...initialState,
                    currentTab: 1,
                    snackbarOpen: {
                        ...this.state.snackbarOpen,
                        addNominalCode: true
                    }
                });
                this.checkAccess();
                this.getCategories();
                this.getNominalCodes();
            }
            this.props.scrollToTop();
        });
    }
    handleAddNominalCodeChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                addNominalCode: {
                    ...this.state.formData.addNominalCode,
                    [e.target.name]: e.target.value
                }
            }
        });
    };
    handleNominalCodeSnackbarClose = () => {
        this.setState({
            snackbarOpen: {
                ...this.state.snackbarOpen,
                addNominalCode: false
            }
        });
    };
    handleAddNominalCodeConfirmationOpen = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addNominalCode: true
            }
        });
    };
    handleAddNominalCodeConfirmationClose = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addNominalCode: false
            }
        });
    };
    handleAddNominalCodeConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addNominalCode: false
            }
        });
        this.addNominalCode();
    };
    handleSelectChange = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                addNominalCode: {
                    ...this.state.formData.addNominalCode,
                    [name]: res && res.value,
                }
            }
        });
    };
  render() {
    const { access, currentTab, codes, formData, formErrors, isLoading, snackbarOpen, confirmationOpen } = this.state;
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5">
                    Nominal Codes
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    {isLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} style={{marginTop: 24}}>
                                <LoadingCircle />
                            </Grid>
                        </Grid>                    
                    )}
                    {(access.addNominalCategory || access.addNominalCode) && (
                        <React.Fragment>
                            <AppBar position="static" style={{backgroundColor:'white'}} >
                                <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                                    <Tab label="Add Category" disabled={!access.addNominalCategory} />
                                    <Tab label="Add Nominal Code" disabled={!access.addNominalCode} />
                                </Tabs>
                            </AppBar>
                            {(access.addNominalCategory && currentTab === 0) && (
                                <TabContainer>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <PaddedPaper>
                                                <Typography variant="h6">
                                                    Add Nominal Category
                                                </Typography>
                                                <form onChange={this.handleAddCategoryChange} noValidate autoComplete="off">
                                                    <TextField
                                                        id="name"
                                                        name="name"
                                                        label="Category Name *"
                                                        value={formData.addCategory.name}
                                                        error={formErrors.addCategory && formErrors.addCategory['name'] && true}
                                                        helperText={formErrors.addCategory && formErrors.addCategory['name']}
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                    <div className='buttonRow'>
                                                        <Button 
                                                            onClick={this.handleAddCategoryConfirmationOpen}
                                                            variant="contained" 
                                                            color="primary" 
                                                           
                                                            disabled={!this.state.formData.addCategory.name}
                                                        >
                                                            Add
                                                        </Button>
                                                    </div>
                                                    <ConfirmationDialog 
                                                        open={confirmationOpen.addCategory} 
                                                        success={this.handleAddCategoryConfirmationSuccess} 
                                                        close={this.handleAddCategoryConfirmationClose} 
                                                        title="Add A New Nominal Category?" 
                                                        message="Are you sure you want to add a new nominal category?"
                                                    />
                                                    <SnackBar
                                                        variant="success"
                                                        anchorOriginVertical='bottom'
                                                        anchorOriginHorizontal='right'
                                                        open={snackbarOpen.addCategory}
                                                        onClose={this.handleAddCategorySnackbarClose}
                                                        message="You have successfully added a new nominal category"
                                                    />
                                                </form>
                                            </PaddedPaper>
                                        </Grid>
                                    </Grid>
                                </TabContainer>
                            )}
                            {(access.addNominalCode && currentTab === 1) && (
                                <TabContainer>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <PaddedPaper>
                                                <Typography variant="h6">
                                                    Add Nominal Code
                                                </Typography>
                                                <form onChange={this.handleAddNominalCodeChange} noValidate autoComplete="off">
                                                    <FormControl error={formErrors.addNominalCode && formErrors.addNominalCode['category'] && true} fullWidth margin="normal">
                                                        <AutoCompleteSelect 
                                                            options={this.state.categoryList} 
                                                            label='Category *'
                                                            onChange={this.handleSelectChange('category')}
                                                        />
                                                        {formErrors.addNominalCode && formErrors.addNominalCode['category'] &&
                                                            <FormHelperText>{formErrors.addNominalCode['category']}</FormHelperText>
                                                        }
                                                    </FormControl>
                                                    <TextField
                                                        id="code"
                                                        name="code"
                                                        label="Nominal Code *"
                                                        value={this.state.formData.addNominalCode.code}
                                                        error={formErrors.addNominalCode && formErrors.addNominalCode['code'] && true}
                                                        helperText={formErrors.addNominalCode && formErrors.addNominalCode['code']}
                                                        margin="normal"
                                                        inputProps={{maxLength: 4}}
                                                    />
                                                    <TextField
                                                        id="name"
                                                        name="name"
                                                        label="Nominal Name *"
                                                        value={this.state.formData.addNominalCode.name}
                                                        error={formErrors.addNominalCode && formErrors.addNominalCode['name'] && true}
                                                        helperText={formErrors.addNominalCode && formErrors.addNominalCode['name']}
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                    <div className='buttonRow'>
                                                        <Button 
                                                            onClick={this.handleAddNominalCodeConfirmationOpen}
                                                            variant="contained" 
                                                            color="primary" 
                                                           
                                                            disabled={!this.state.formData.addNominalCode.name && !this.state.formData.addNominalCode.category}
                                                        >
                                                            Add
                                                        </Button>
                                                    </div>
                                                    <ConfirmationDialog 
                                                        open={confirmationOpen.addNominalCode} 
                                                        success={this.handleAddNominalCodeConfirmationSuccess} 
                                                        close={this.handleAddNominalCodeConfirmationClose} 
                                                        title="Add A New Nominal Code?"
                                                        message="Are you sure you want to add a new nominal code?"
                                                    />
                                                    <SnackBar
                                                        variant="success"
                                                        anchorOriginVertical='bottom'
                                                        anchorOriginHorizontal='right'
                                                        open={snackbarOpen.addNominalCode}
                                                        onClose={this.handleNominalCodeSnackbarClose}
                                                        message="You have successfully added a new nominal code"
                                                    />
                                                </form>
                                            </PaddedPaper>
                                        </Grid>
                                    </Grid>
                                </TabContainer>
                            )}
                        </React.Fragment>
                    )}
                    <div className={`padding3 ${(access.addNominalCategory || access.addNominalCode) && 'paddingTop0'}`}>
                        <NominalCodeList codes={codes} />
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
  }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(NominalCodes);