import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import TextField from '@material-ui/core/TextField';
import API from '../../../API';
import Button from '@material-ui/core/Button';
import CiDataTable from '../../Common/DataTables/CiDataTable';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import ViewEventDialog from './../ViewEventDialog/ViewEventDialog';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import DatePicker from '../../Common/DatePickers/DatePicker';
import {DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE} from 'Constants'
import { gridPageChanged, gridRowsPerPageChanged } from 'Functions/FormFunctions'
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    searchCriteria: {
        searchString: '',
        dateFrom: null,
        dateTo: null,
    },
    searchResults: {},
    access: {
        updateEvent: false,
        cancelEvent: false
    },
    cancelEventConfirmationOpen: false,
    cancelEventSnackbarOpen: false,
    cancelEventId: null,
    viewEventId: null,
    viewEventOpen: false,
    isLoading: true,
    gridPagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    },
});
class CalendarSearch extends Component {
    constructor(props) {
        super(props);
        this.clearPageState         = clearPageState.bind(this);
        this.getInitialState        = getInitialState.bind(this);
        this.hasPageState           = hasPageState.bind(this);
        this.savePageState          = savePageState.bind(this);
        this.persistenceId          = 'Calendar:Search';
        this.state                  = this.getInitialState(initialState());
        this.gridPageChanged        = gridPageChanged.bind(this);
        this.gridRowsPerPageChanged = gridRowsPerPageChanged.bind(this);
    }
    componentDidMount(){
        !this.hasPageState() && this.loadComponentData();
    }
    loadComponentData = () => {
        this.getAccess();
        this.getSearchData();
    }
    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-calendar-event'), 
            API.get('/staff/my/access/check/cancel-calendar-event')
        ])
        .then(([updateEventRes, cancelEventRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    updateEvent: (updateEventRes.data && updateEventRes.data.has_access) || false,
                    cancelEvent: (cancelEventRes.data && cancelEventRes.data.has_access) || false
                }
            }, () => this.savePageState());
        })
    }
    getSearchData = () => {
        const props = {
            cancellation: true
        }
        const params = {
            searchString: this.state.searchCriteria.searchString,
            dateFrom: this.state.searchCriteria.dateFrom !== null ? moment(this.state.searchCriteria.dateFrom).format('YYYY-MM-DD') : null,
            dateTo: this.state.searchCriteria.dateTo !== null ? moment(this.state.searchCriteria.dateTo).format('YYYY-MM-DD') : null,
            orderBy: 'calendar_event_start_datetime:desc'
        };
        this.setState({
            isLoading: true
        }, () => {
            API.get('/calendar/events/all', { params, props }).then(result => {
                if(result?.data) {
                    result.data.forEach((event) => {
                        event.status = '';
                        event.status_value = '';
                        if(event.calendar_event_status === 'Active' && moment(event.calendar_event_start_datetime) > moment()){
                            event.status_value = 'Upcoming Event';
                            event.status = <Chip size="small" label='Upcoming Event' style={{color:'white', backgroundColor:'#e8ab3d'}} />
                        }
                        else if(event.calendar_event_status === 'Active'){
                            event.status_value = 'Past Event';
                            event.status = <Chip size="small" label='Past Event' style={{color:'white', backgroundColor:'#5da14d'}} />
                        }
                        else {
                            event.status_value = 'Cancelled';
                            event.status = <Chip size="small" label='Cancelled' style={{color:'white', backgroundColor:'#d14039'}} />
                        }
                        event.export_category = `${event.parent_category.category_name} | ${event.sub_category.category_name}`;
                    });
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    }, () => this.savePageState());
                } else {
                    /* Error */
                }
            });
        })
    }
    cancelEvent() {
        API.post(`/calendar/events/${this.state.cancelEventId}/cancel`)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0) {
                alert('Unable to cancel event');
            } else {
                let rowIndex = this.state.searchResults.findIndex(x => x.calendar_event_id === this.state.cancelEventId);
                let newSearchResults = [...this.state.searchResults];
                newSearchResults[rowIndex].status = <Chip size="small" label='Cancelled' style={{color:'white', backgroundColor:'#d14039'}} />
                newSearchResults[rowIndex].calendar_event_status = 'Cancelled';
                newSearchResults[rowIndex].can_edit = false;
                this.setState({
                    ...this.state,
                    cancelEventSnackbarOpen: true,
                    searchResults: newSearchResults
                }, () => this.savePageState());
            }
        });
    }
    handleDateChange = name => date => {
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                [name]: date
            },
           //gridPagination: initialState().gridPagination
        }, 
        () => {
            this.getSearchData();
        });
    };
    onSearchStringChange = (event) => {
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                searchString: event.target.value
            },
            //gridPagination: initialState().gridPagination
        },
        () => {
            this.getSearchData();
        });
    }
    resetSearch = () => {
        this.clearPageState();
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                searchString: '',
                dateFrom: null,
                dateTo: null
            },
            //gridPagination: initialState().gridPagination
        }, 
        () => {
            //this.props.history.replace();
            this.getSearchData();
        });
    }
    handleCancelEventSnackbarClose = () => {
        this.setState({
            cancelEventSnackbarOpen: false
        });
    };
    handleCancelEventConfirmationOpen = rowData => {
        this.setState({
            cancelEventConfirmationOpen: true,
            cancelEventId: rowData.calendar_event_id
        });
    };
    handleCancelEventConfirmationClose = () => {
        this.setState({ 
            cancelEventConfirmationOpen: false 
        });
    };
    handleCancelEventConfirmationSuccess = () => {
        this.setState({ 
            cancelEventConfirmationOpen: false 
        });
        this.cancelEvent();
    }
    viewEvent = (eventId) => {
        this.setState({
            viewEventId: eventId,
            viewEventOpen: true
        });
    }
    viewEventClose = () => {
        this.setState({
            viewEventOpen: false
        });
    }
    render() {
        const { access, searchCriteria, gridPagination } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Calendar Event Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} lg={6}>
                                        <DatePicker
                                            type='date'
                                            id="dateFrom"
                                            name="dateFrom"
                                            label="Date From"
                                            autoOk={true}
                                            value={searchCriteria?.dateFrom}
                                            onChange={this.handleDateChange('dateFrom')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <DatePicker
                                            type='date'
                                            id="dateTo"
                                            name="dateTo"
                                            label="Date To"
                                            autoOk={true}
                                            value={searchCriteria?.dateTo}
                                            onChange={this.handleDateChange('dateTo')}                                            
                                        />
                                    </Grid>
                                </Grid>
                                <TextField id="nameSearch" label="Keywords" fullWidth value={searchCriteria?.searchString} onChange={this.onSearchStringChange} />
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                           >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <CiDataTable  
                            config={{
                                key:                    'calendar_event_id',
                                pagination:             true,
                                //page: gridPagination.page,
                                //rowsPerPage: gridPagination.rowsPerPage,
                                //gridPageChanged: this.gridPageChanged,
                                //gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                persistenceId:          this.persistenceId,
                                isLoading:              this.state.isLoading,
                                responsiveImportance:   true,
                                alternatingRowColours:  true,
                                options: {
                                    dataRef: true,
                                    reset: this.resetSearch,
                                    export: {
                                        title: `Calendar Events List`,
                                        name: `Calendar-Events`,
                                        excel: true,
                                        pdf: true,
                                        print: true,
                                        disable: {
                                            pdf: {
                                                all: data => data.length > 1000,
                                            }
                                        }
                                    },
                                }
                            }}
                            columns={[
                                {
                                    heading: 'Type',
                                    field: rowData => (
                                        <React.Fragment>
                                            {rowData.parent_category.category_name} | {rowData.sub_category.category_name}
                                        </React.Fragment>
                                    ),
                                    cellProps: rowData => {
                                        return ({
                                            style: {
                                                textDecoration: rowData.calendar_event_status === 'Cancelled' && 'line-through'
                                            }
                                        });
                                    },
                                    dataRef: 'export_category',
                                    main: true,
                                    important: true,
                                },
                                {
                                    heading: 'Leader',
                                    field: rowData => rowData.leader && `${rowData.leader.staff_first_name} ${rowData.leader.staff_last_name}`,
                                    cellProps: rowData => {
                                        return ({
                                            style: {
                                                textDecoration: rowData.calendar_event_status === 'Cancelled' && 'line-through'
                                            }
                                        });
                                    },
                                    dataRef: 'leader.staff_first_name'
                                },
                                {
                                    heading: 'Starts',
                                    field: rowData => rowData.calendar_event_start_datetime,
                                    fieldFormat: 'datetime',
                                    cellProps: rowData => {
                                        return ({
                                            style: {
                                                textDecoration: rowData.calendar_event_status === 'Cancelled' && 'line-through'
                                            }
                                        });
                                    },
                                    dataRef: 'calendar_event_start_datetime'
                                },
                                {
                                    heading: 'Status',
                                    field: rowData => rowData.status,
                                    important: true,
                                    alignment: 'center',
                                    dataRef: 'status_value'
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'View', icon: 'search', onClick: () => { this.viewEvent(rowData.calendar_event_id) }},
                                            {name: 'Update', icon: 'pencil-alt', link: { pathname: '/calendar/events/update/' + rowData.calendar_event_id, state: {searchCriteria, gridPagination}}, disabled: (!rowData.can_edit || !access.updateEvent)},
                                            {name: 'Cancel', icon: 'times-circle', onClick: this.handleCancelEventConfirmationOpen, disabled: (!rowData.can_edit || !access.cancelEvent)}
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.cancelEventConfirmationOpen} 
                    success={this.handleCancelEventConfirmationSuccess} 
                    close={this.handleCancelEventConfirmationClose} 
                    title="Cancel Calendar Event?" 
                    message="Are you sure you want to cancel this calendar event?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.cancelEventSnackbarOpen}
                    onClose={this.handleCancelEventSnackbarClose}
                    message="The new calendar event was successfully cancelled"
                />
                <ViewEventDialog 
                    open={this.state.viewEventOpen}
                    eventId={this.state.viewEventId}
                    onClose={this.viewEventClose} />
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSearch);
