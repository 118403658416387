import React, { Component } from 'react';
import { connect }          from 'react-redux';
import _                    from 'lodash';

import {Tab, Tabs, AppBar, Grid, Typography, Paper} from '@material-ui/core/';

import SalesReportOverview          from './SalesReportOverview/SalesReportOverview';
import OrderSalesReportOverview     from './SalesReportOverview/OrderSalesReportOverview';
import SalesReportDetailed          from './SalesReportDetailed/SalesReportDetailed';
import SalesReportParts             from './SalesReportParts/SalesReportParts';
import SalesReportMargins           from './SalesReportMargins/SalesReportMargins';
import SalesReportDiscount          from './SalesReportDiscount/SalesReportDiscount';
import SalesReportDiscountBreakDown from './SalesReportDiscountBreakDown/SalesReportDiscountBreakDown';
import SalesReportPartCatagories    from './SalesReportPartCatagories/SalesReportPartCatagories';

import {setPersistence} from 'Actions/StatePersistence/StatePersistence';

import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';

const initialState = {
    currentTab: 1,//change back to 1
    filtered:   null,
    filter:     {
        dateFrom:               null,
        dateTo:                 null,
        includeSalesStatuses:   [],
    }
}

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

class SalesReports extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        switch (true){
            case     props.cid > 0:    this.persistenceId = `SalesReport:cid:${props.cid}`;    break;
            case     props.partId > 0: this.persistenceId = `SalesReport:pid:${props.partId}`; break;
            default:                   this.persistenceId = `SalesReport`;                     break;
        }
        this.state              = this.getInitialState(initialState);
    }

    handleTabChange = (event, value) => {this.setState({currentTab: value},()=>{this.savePageState()});};

    render() {
        const {currentTab} = this.state;

        const tabs = [
            { label:"Order Sales", style: {borderRight: '1px solid #c3c3c3'} },
            { label:"Sales" },
            { label:"Detailed" },
            { label:"Parts" },
            { label:"Margins" },
            { label:"Discounted Parts" },
            { label:"Discounts" },
            { label:"Part Categories" }
        ]

        return (
            <Grid container xs={12} style={{width: '100%'}}>
                <Grid item xs={12} style={{ marginBottom: '1.5em'}}>
                    {( this.props.partId || this.props.cid ) ?
                        <></> :
                        <Typography variant="h5">
                            Sales Reports
                        </Typography>
                    }
                </Grid>
                <AppBar position="static" style={{backgroundColor:'white', color:"#000", minWidth: 1522, width: '100%', zIndex:'auto'}} >
                    <Tabs   value={currentTab} 
                            onChange={this.handleTabChange} 
                            className='xs-max-width' 
                            indicatorColor="primary" 
                            textColor="primary" 
                            variant='scrollable'
                            scrollButtons="auto"
                    >
                        {_.map(tabs, (i, idx) => <Tab label={i.label} value={idx} style={i.style} />)}
                    </Tabs>
                </AppBar>
                {parseInt(currentTab) === parseInt(_.findIndex(tabs, i => i.label === 'Sales')) && 
                    <Paper style={{width:'100%'}}>
                        <Grid container xs={12} >
                            <Grid item xs={12}>
                                <TabContainer>
                                    <SalesReportOverview partId={this.props.partId} cid={this.props.cid} />
                                </TabContainer>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                 {parseInt(currentTab) === parseInt(_.findIndex(tabs, i => i.label === 'Order Sales')) && 
                    <Paper style={{width:'100%'}}>
                        <Grid container xs={12} >
                            <Grid item xs={12}>
                                <TabContainer>
                                    <OrderSalesReportOverview  partId={this.props.partId} cid={this.props.cid} />
                                </TabContainer>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                {parseInt(currentTab) === parseInt(_.findIndex(tabs, i => i.label === 'Detailed')) && 
                    <Paper style={{width:'100%'}}>
                        <Grid container xs={12} >
                            <Grid item xs={12}>
                                <TabContainer>
                                    <SalesReportDetailed partId={this.props.partId} cid={this.props.cid} />
                                </TabContainer>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                {parseInt(currentTab) === parseInt(_.findIndex(tabs, i => i.label === 'Parts')) && 
                    <Paper style={{width:'100%'}}>
                        <Grid container xs={12} >
                            <Grid item xs={12}>
                                <TabContainer>
                                    <SalesReportParts history={this.props.history} partId={this.props.partId} cid={this.props.cid} />
                                </TabContainer>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                {parseInt(currentTab) === parseInt(_.findIndex(tabs, i => i.label === 'Margins')) && 
                    <Paper style={{width:'100%'}}>
                        <Grid container xs={12} >
                            <Grid item xs={12}>
                                <TabContainer>
                                    <SalesReportMargins history={this.props.history} partId={this.props.partId} cid={this.props.cid} />
                                </TabContainer>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                {parseInt(currentTab) === parseInt(_.findIndex(tabs, i => i.label === 'Discounted Parts')) && 
                    <Paper style={{width:'100%'}}>
                        <Grid container xs={12} >
                            <Grid item xs={12}>
                                <TabContainer>
                                    <SalesReportDiscount history={this.props.history} partId={this.props.partId} cid={this.props.cid} />
                                </TabContainer>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                {parseInt(currentTab) === parseInt(_.findIndex(tabs, i => i.label === 'Discounts')) && 
                    <Paper style={{width:'100%'}}>
                        <Grid container xs={12} >
                            <Grid item xs={12}>
                                <TabContainer>
                                    <SalesReportDiscountBreakDown history={this.props.history} partId={this.props.partId} cid={this.props.cid} />
                                </TabContainer>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                {parseInt(currentTab) === parseInt(_.findIndex(tabs, i => i.label === 'Part Categories')) && 
                    <Paper style={{width:'100%'}}>
                        <Grid container xs={12} >
                            <Grid item xs={12}>
                                <TabContainer>
                                    <SalesReportPartCatagories history={this.props.history} partId={this.props.partId} cid={this.props.cid} />
                                </TabContainer>
                            </Grid>
                        </Grid>
                    </Paper>
                }
            </Grid>
        )
    }
};

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    setPersistence: (key, state) => dispatch(setPersistence(key, state)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesReports);

