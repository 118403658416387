import React from 'react';

import Typography from '@material-ui/core/Typography';

const renderMessage = (text, color) => {
    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    replacedText = text.replace(replacePattern1, '<a href="$1" style="text-decoration: none; color: #'+color+'!important" target="_blank">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '<a href="http://$2" style="text-decoration: none; color: #'+color+'!important" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1" style="text-decoration: none; color: #'+color+'!important" >$1</a>');

    return {__html: replacedText}
}

const ThreadContent = ({classes, message, myMessage}) => {
    const color = myMessage ? '000000' : 'FFFFFF';
    return (
        message.split('\n').map((item, key) => {
            return <Typography variant="body2" component="div" key={key} className={classes.message} dangerouslySetInnerHTML={renderMessage(item, color)} />
        })
    )
}

export default ThreadContent;