import React, { Component }  from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import { ADDRESS_LOOKUP_API_KEY, ADDRESS_LOOKUP_URL } from '../../../Constants';
import axios from 'axios';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    buttons: {        
        textTransform: 'none',
        textAlign: 'left',
        flex: 1,
    },
    address: {
        border: '1px solid #ddd',
        margin: '10px',
        padding: '6px 16px',
        fontSize: '0.875rem',
        boxSizing: 'border-box',
        fontWeight: '500',
        lineHeight: '1.75',
        borderRadius: '4px',
        letterSpacing: '0.02857em',
        '&:hover': {
            backgroundColor: '#fafafa',
            cursor: 'pointer'
        }
    }
});

class AddressLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addressList: [],
            postcode: '',
            dialogOpen: false,
            addressOptionOne: '',
            addressOptionTwo: ''
        };
    }

    addressLookup = () => {
        axios.get(`${ADDRESS_LOOKUP_URL}/?publicApiKey=${ADDRESS_LOOKUP_API_KEY}&postcode=${this.state.postcode.replace(/ /g, '')}`)
        .then((result) => {
            
            if(!result.data.invalidPostcode && !result.data.missingPostcode && result.data.addresses){
                let addressList = _.map(result.data.addresses, (el, idx) => {
                    return _.assign({
                        value: el,
                        label: `${el.line1}, ${el.line2 && el.line2 + ','}  ${el.town}, ${el.postcode}`
                    });
                });
                this.setState({
                    addressList: addressList
                });
            }
            else {
                this.setState({
                    addressList: [],
                });
            }
        });
    }

    handleChange = name => (e) => {
        const { value } = e.target;
        this.setState({
            [e.target.name]: value
        },
        () => {
            if(name === 'postcode'){
                if(value.length > 3){
                    this.addressLookup();
                }
                else {
                    this.setState({
                        addressList: [],
                    });
                    this.props.handleSelectedAddress(null);
                }
            }
        });
    }

    handleSelectChange = e => {
        let address = _.find(this.state.addressList, {'label': e && e.label });

        if(address && (address.value.line2 || address.value.line3)){
            this.setState({
                dialogOpen: true,
                addressOptionOne:   address.value.line1 + "\n\r" +
                                    (address.value.line2 ? address.value.line2 + "\n\r" : '') +
                                    (address.value.line3 ? address.value.line3  + "\n\r" : '') +
                                    (address.value.town ? address.value.town + "\n\r" : '') +
                                    (address.value.postcode),
                addressOptionTwo:   (address.value.line2 ? address.value.line2 + "\n\r" : '') +
                                    (address.value.line3 ? address.value.line3  + "\n\r" : '') +
                                    (address.value.town ? address.value.town + "\n\r" : '') +
                                    (address.value.postcode),
                address: address
            });
        }
        else {
            this.props.handleSelectedAddress(address);
        } 
    }

    selectAddress = addressOption => (e) => {
       this.setState({
            dialogOpen: false,
            address: {
                ...this.state.address,
                value: {
                    ...this.state.address.value,
                    line1: addressOption === 'addressOptionTwo' ? this.state.address.value.line2 : this.state.address.value.line1,
                    line2: addressOption === 'addressOptionTwo' ? this.state.address.value.line3 : this.state.address.value.line2,
                    line3: addressOption === 'addressOptionTwo' ? '' : this.state.address.value.line3,
                }
            }
        }, 
        () => {
            this.props.handleSelectedAddress(this.state.address);
        });
    }
    
    render() {
        const { inlineLayout, value, classes } = this.props;
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={inlineLayout ? 4 : 12}>
                        <TextField
                            name="postcode"
                            label="Enter a postcode to find an address..."
                            value={this.state.postcode}
                            onChange={this.handleChange('postcode')}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={inlineLayout ? 8 : 12}>
                        <FormControl fullWidth>
                            <AutoCompleteSelect
                                value={value}
                                options={this.state.addressList} 
                                label={this.state.addressList.length > 0 ? 'Please select an address' : '-'}
                                onChange={this.handleSelectChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="sm"
                    open={this.state.dialogOpen}
                    fullWidth={true}
                    scroll="body"
                >
                    <DialogTitle>Select Your Preferred Address</DialogTitle>
                    <DialogContent>
                        <div style={{display: 'flex',justifyContent: 'center'}} >
                            <div className={classes.address} onClick={this.selectAddress('addressOptionOne')}>
                                {this.state.addressOptionOne.split('\n').map((item, key) => {
                                        return <span key={key}>{item}<br/></span>
                                })}
                            </div>
                            <div className={classes.address} onClick={this.selectAddress('addressOptionTwo')}>
                                {this.state.addressOptionTwo.split('\n').map((item, key) => {
                                    return <span key={key}>{item}<br/></span>
                                })}
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(AddressLookup);
