import React from 'react';
import _, { map } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import API from 'API';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import {
    Grid,
    Typography,
    Checkbox,
    ListItemIcon,
    ListItem,
    ListItemText,
    List,
    Button,
    ListItemSecondaryAction
} from '@material-ui/core';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import Textarea from 'Components/Common/Inputs/Textarea';
import { getFormData, handleChange } from 'Functions/FormFunctions';
import NotificationDialogContent from 'Components/Common/Dialogs/NotificationDialogContent';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { CLENAWARE_YELLOW } from 'Constants';
import VehicleReturnForm from './VehicleReturnForm';
import { CLENAWARE_BLUE } from 'Constants';

const initialState = () => ({
    access: {
        updateVehicle: false,
    },
    formData: {
        returnDate: null,
        returnChecklist: [],
        notes: ''
    },
    formErrors: []
})



class VehicleReturn extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.getFormData = getFormData.bind(this);
        this.handleChange = handleChange.bind(this);
    }

    componentDidMount() {
        const { vehicle } = this.props;
        this.setState({
            ...this.state,
            vehicle
        }, () => this.getAccess());
    }
    

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-vehicle')
        ])
        .then(([updateRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    updateVehicle: (updateRes.data && updateRes.data.has_access) || false
                }
            }, () => {
                this.loadComponentData(); 
            });
        }) 
    }

    // routeChange=(path)=> {
    //     this.props.history.push(path);
    //   }

    loadComponentData = () => {

        const { vehicle } = this.state;
        let returnChecklist = [];

        if(vehicle?.returnInfo?.checklist == null){

            returnChecklist = [
                {id: 1, question: 'Remove Devices', checked: 0},
                {id: 2, question: 'Remove Subscriptions', checked: 0},
                {id: 3, question: 'Clean / Tidy vehicle', checked: 0},
                {id: 4, question: 'Gather relevant documentation to return with vehicle', checked: 0},
                {id: 5, question: 'Cancel Insurance', checked: 0},
                {id: 6, question: 'Cancel Breakdown', checked: 0},
                {id: 7, question: 'Restore factory settings on car connected services', checked: 0},
                {id: 8, question: 'Enough Fuel / Charge for collection', checked: 0},
                {id: 9, question: 'Keys', checked: 0},
                {id: 10, question: "Settle outstanding 'Penalty Charge Notices'", checked: 0}
            ]
        }
        else {
            returnChecklist = vehicle?.returnInfo?.checklist
        }

        // Assign the click actions of each check item
        map(returnChecklist, (item) => {
            switch(item.id){
                case 1:
                    item.clickAction = (e) => this.props.tabChange(e, 'information');
                break;
                case 2:
                    item.clickAction = (e) => this.props.tabChange(e, 'subscriptions');
                break;
                case 4:
                    item.clickAction = (e) => this.props.tabChange(e, 'documents');
                break;
                case 5:
                    item.clickAction = () => this.props.history.push('/vehicles/insurance/search');
                break;
                case 6:
                    item.clickAction = () => this.props.history.push('/vehicles/breakdown/search');
                break;
                case 10:
                    item.clickAction = (e) => this.props.tabChange(e, 'incidents');
                break;
                
                default:
                    item.clickAction = null;
                break;
            }
        })
        
        this.setState({
            formData: {
                ...this.state.formData,
                returnChecklist,
                returnDate: vehicle?.returnInfo?.returnDate !== '0000-00-00' ? vehicle?.returnInfo?.returnDate : null,
                notes: vehicle?.returnInfo?.notes
            }
        });
    }

    handleCheckedChange = (e, idx) => {
        let val             = e.target.checked,
            returnChecklist = this.state.formData?.returnChecklist;

        returnChecklist[idx].checked = val ? 1 : 0;

        this.setState({
            formData: {
                ...this.state.formData,
                returnChecklist
            }
        }, () => {
            this.saveData();
        });
    }

    handleDateChange(name, date) {
        let newDate = moment(date).format('YYYY-MM-DD');
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: date ? newDate : null
            }
        }, () => {
            this.saveData();
        });
    }

    saveData = () => {
        const { vehicle } = this.state;

        API.post(`/vehicles/${vehicle?.id}/save`, this.getFormData())
        .then(res => {

            this.props.deploySnackBar("success", `Saved`);
            this.props.refresh(false);
            
        });
    }

    checkOutstandingItems = () => {
        const { vehicle } = this.state;

        let outstandingItems = [];

        // Devices
        if(vehicle?.devices?.length > 0){
            outstandingItems.push({type: 'Devices', count: vehicle.devices.length});
        }

        // Subscriptions
        if(vehicle?.activeSubs?.length > 0){
            let x = 0;
            map(vehicle?.activeSubs, (sub) => {
                if(sub?.endDate == '0000-00-00'){
                    x++
                }
            })
            if(x > 0)
                outstandingItems.push({type: 'Subscriptions', count: x});
        }

        // Penalty Charge Notices
        if(vehicle?.outstandingPCN > 0){
            outstandingItems.push({type: 'Unsettled Penalty Charge Notices', count: vehicle.outstandingPCN});
        }

        // Breakdown
        if(vehicle?.breakdown?.length > 0){
            outstandingItems.push({type: 'Breakdown Policies', count: vehicle.breakdown.length});
        }

        // Insurance
        if(vehicle?.insurances?.length > 0){
            outstandingItems.push({type: 'Insurance Policies', count: vehicle.insurances.length});
        }

        if(outstandingItems?.length > 0){

            this.props.deployDialog(<NotificationDialogContent 
                                        title={<><AllIcon heavy icon="exclamation-triangle" style={{color: CLENAWARE_YELLOW}} /> <b>Warning</b></> }
                                        message={
                                            <>
                                            <Typography variant="body1" paragraph>
                                                <em>Please remove the following items that are still active on the vehicle:</em>
                                            </Typography>
                                            <List dense>
                                                {_.map(outstandingItems, (i, idx) => 
                                                    <ListItem
                                                        key={idx}
                                                        disablePadding
                                                    >
                                                        <ListItemText primary={<Typography variant="body1" color="textPrimary">{i?.type + ' (' + i?.count + ')'}</Typography>} />
                                                    </ListItem>
                                                )}
                                            </List>
                                            </>
                                        }
                                        close={this.props.closeDialog} 
                                    />, "", "", "xs")
        }
        else {

            this.props.deployDialog(<VehicleReturnForm 
                                        refresh={this.props.refresh} 
                                        close={this.props.closeDialog} 
                                        returnDate={vehicle?.returnInfo?.returnDate}
                                        vehicleId={vehicle?.id}
                                    />,'Return Vehicle', '', 'xs')
        }
    }

    render() {

        const { vehicle, formErrors, formData } = this.state;
        const { activeVehicle } = this.props;

        // Are all items checked?
        let allChecked = true;
        _.map(formData?.returnChecklist, (item) => {
            if(item?.checked == 0){
                allChecked = false;
            }
        })
        
        return (
            <>
                <PaddedPaper>
                    <Grid container item spacing={3}>
                        {activeVehicle ?
                            <Grid item xs={12} lg={4}>
                                <Grid container item spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" paragraph>
                                            <b>Return Vehicle</b>
                                        </Typography>
                                    </Grid>
                                    {vehicle?.status == "Lease Completed / Sold" && formData?.returnInfo ?
                                        <>
                                        <Typography variant="h6" paragraph>
                                            This vehicle was returned / sold on {moment(formData?.returnInfo?.returnDate).format('DD MMM, YYYY')}
                                        </Typography>
                                        </>
                                    :
                                        <>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                type="date"
                                                id="returnDate"
                                                name="returnDate"
                                                label={"Return Date *"}
                                                value={formData?.returnDate}
                                                errorText={formErrors && formErrors['returnDate']}
                                                onChange={date => this.handleDateChange('returnDate', date)}
                                                autoOk={true}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" paragraph>
                                                Please complete the following checklist before returning / selling the vehicle.
                                            </Typography>
                                            <List dense>
                                                {_.map(formData?.returnChecklist, (c, idx) => 
                                                    <ListItem
                                                        key={idx}
                                                        disablePadding
                                                    >
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                edge='end'
                                                                onChange={(e) => this.handleCheckedChange(e, idx)}
                                                                checked={c?.checked ? 1 : 0}
                                                                value={1}
                                                                color='primary'
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText primary={c?.question} />
                                                        <ListItemSecondaryAction>
                                                            {c?.clickAction ?
                                                                <AllIcon icon="arrow-up-right-from-square" size={15} onClick={() => c?.clickAction()} noMargin />
                                                            : null}
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )}
                                            </List>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Textarea
                                                id="notes"
                                                name="notes"
                                                label="Notes"
                                                value={formData?.notes}
                                                onChange={this.handleChange}
                                                onBlur={this.saveData}
                                                InputLabelProps={{
                                                    shrink: !!formData.notes,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className='buttonRow'>
                                                <Button 
                                                    onClick={this.checkOutstandingItems}
                                                    variant="contained"
                                                    color="secondary"
                                                    disabled={allChecked ? false : true}
                                                >
                                                    Return Vehicle
                                                </Button>
                                            </div>
                                        </Grid>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        :
                            <Grid item xs={12} align="center">
                                <AllIcon icon="clipboard-check" noMargin style={{color: CLENAWARE_BLUE, marginBottom: 20 }} size={40} />
                                <Typography variant="h4">
                                    Vehicle {vehicle?.ownershipType == "Owned" ? "Sold" : "Lease Completed"} 
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    Returned on {moment(vehicle?.returnInfo?.returnDate).format('MMM D, YYYY')}
                                </Typography>
                                <Typography variant="body2">
                                    <em>
                                        {vehicle?.returnInfo?.notes.split('\n').map((item, key) => {
                                        return <span key={key}>{item}<br/></span>
                                    })}</em>
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </PaddedPaper>
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                               => dispatch(closeDialog()),
    deployDialog:       (content, title, variant, size)  => dispatch(deployDialog(content, title, variant,size)),
    deploySnackBar:     (variant, msg)                   => dispatch(deploySnackBar(variant, msg)),
})

export default connect(null, mapDispatchToProps)(VehicleReturn);