import React, { Component } from 'react';
import API                  from 'API';
import { connect }          from 'react-redux';
import { Link }             from 'react-router-dom';
import _                    from 'lodash';
import moment               from 'moment';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';

import BackButton     from 'Components/Common/Buttons/BackButton';
import AllIcon        from 'Components/Common/Icons/AllIcon';
import LoadingCircle  from 'Components/Common/LoadingCircle/LoadingCircle';
import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher';
import PaddedPaper    from 'Components/Common/Paper/PaddedPaper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import ActivityLogTab from './ActivityLogTab';
import OrderTab       from './OrderTab';
import PackageTab     from './PackageTab';
import VersionsTab    from './VersionsTab';
import PriceChangesTab from './PriceChangesTab';

import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import 'Assets/timeLine.css';

const initialState = () => ({
    isLoading: true,
    package: {},
    currentTab: 'Package',
    access: {
        edit: false,
    }
});

class ViewPackage extends Component {
    constructor(props) {
        super(props);    
        this.state = initialState();
    }

    componentDidMount(){
        this.getPackage();
        this.getAccess();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.packageId !== this.props.match.params.packageId) {
            this.getPackage();
        }
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/edit-sales-package')
        ]).then(([edit]) => {
            this.setState({
                access: {
                    edit: edit?.data?.has_access || false,
                }
            });
        })
    }

    getPackage = () => {
        API.get(`/sales/packages/${this.props.match.params.packageId}`)
        .then(res => {
            this.setState({
                isLoading: false,
                salesPackage: res.data,
            });
        });
    }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        });
    };

    delete = () => {
        API.post(`/sales/packages/${this.props.match.params.packageId}/delete`)
        .then(this.getPackage)
    }

    reinstate = () => {
        API.post(`/sales/packages/${this.props.match.params.packageId}/reinstate`)
        .then(this.getPackage)
    }

    changePage = (page) => {
        this.props.history.push(page);
    }

    render(){
        
        const { isLoading, salesPackage, currentTab, access } = this.state;
        const { classes } = this.props;

        if (isLoading) return (<LoadingCircle />);

        const PackageCreated = _.first(_.orderBy(_.filter(salesPackage.logs, i => i.pl_type === 'Created' || i.pl_type === 'Amended'), 'pl_version', 'desc'));

        const updatedParts = _.filter(_.map(salesPackage.details, i => {
            if (moment(i.part.default_price.price_date_added).isAfter(PackageCreated.pl_datetime)){
                return {...i.part, currentPrice: i.pd_unit_price};
            }
            return null;
        }), i => i);

        return(
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Sales Package
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" align="left" className={classes.heading}>
                                    {salesPackage.p_name}
                                </Typography>
                                <Typography variant="h6" align="left" className={classes.heading}>
                                    {salesPackage.p_ref}-{salesPackage.p_version < 10 ? '0' + salesPackage.p_version : salesPackage.p_version}
                                </Typography>
                                <Typography variant="body1" align="left" className={classes.heading}>
                                    {salesPackage.p_desc}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {!!salesPackage.p_deleted &&
                                    <Typography variant='body1'
                                        style={{color: colors.white, backgroundColor: colors.red, width: '100%', padding: '10px', display: 'flex'}}
                                    >
                                        <AllIcon size={20} icon={icons.warning} white/>Package Has Been Deleted
                                        <Link onClick={this.reinstate} style={{marginLeft: 'auto', color: colors.white}}>Reinstate</Link>
                                    </Typography>                                
                                }
                                {!salesPackage.p_latest &&
                                    <Typography variant='body1'
                                        style={{color: colors.white, backgroundColor: colors.red, width: '100%', padding: '10px', display: 'flex'}}
                                    >
                                        <AllIcon size={20} icon={icons.warning} white/>Viewing An Old Version Of This Package
                                        <Link onClick={()=>this.props.history.push(`/sales/package/${salesPackage.latest_package.p_id}`)} style={{marginLeft: 'auto', color: colors.white, textDecoration: 'none'}}>View Latest</Link>
                                    </Typography>                                
                                }
                                <TabBarSwitcher
                                    disableMenu
                                    tabs={[
                                        {
                                            label: 'Details',
                                            content: <PackageTab salesPackage={salesPackage} />
                                        },
                                        {
                                            label: 'Versions',
                                            content: <VersionsTab salesPackage={salesPackage} />
                                        },
                                        {
                                            label: 'Orders',
                                            content: <OrderTab salesPackage={salesPackage} />
                                        }, 
                                        {
                                            label: 'Activity Log',
                                            content: <ActivityLogTab salesPackage={salesPackage} />
                                        },
                                        updatedParts.length > 0 && {
                                            label: 'Price Changes',
                                            content: <PriceChangesTab updatedParts={updatedParts} salesPackage={salesPackage} />
                                        }
                                    ]}
                                    currentTab={currentTab}  
                                    handleTabChange={this.handleTabChange}
                                />
                            </Grid>
                            <Grid item xs={12} className='buttonRow'>
                                <BackButton />
                                { !!( salesPackage.p_latest && !salesPackage.p_deleted ) && !!access.edit && 
                                    <>
                                        <Button color='primary' variant='contained'
                                            onClick={()=>this.changePage(`/sales/package/edit/${salesPackage.p_id}`)}
                                        ><AllIcon icon={icons.edit} size='xs' white/>Amend</Button>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    btnRow: {
        textAlign: 'center', 
    },
    btnRowBtn: {   
        width: '100%',
    },
    link: {
        textDecoration: 'none',
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        },
        stepper: {
            display: 'none',
        },
    },
    statusHeading: {
        display: 'flex',
        alignItems: 'center'
    },
    label: {
        color: 'black'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tabScrollButtons: {
        color: 'black'
    }
});

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
})
export default connect(null, mapDispatchToProps)(withStyles(styles)(ViewPackage));