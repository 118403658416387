import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import API from '../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import { MAX_UPLOAD_LIMIT_SIZE } from './../../../Constants';
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData: {
        part: '',
        imageFile: '',
        imageFileRaw: '',
        image3DFileObj: '',
        image3DFileMtl: '',
        webImage3DFile: ''
    },
    showImageFile: false,
    showRawImageFile: false,
    show3DImageFile: false,
    show3DWebImageFile: false,
    partList: [],
    parts: {},
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: false
}

class AddPartImage extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getParts();
    }

    getParts = () => {        
        API.get('/parts/all' , {params: {active: true}})
        .then((result) => {
            let partList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description,
                    version: el.version_no
                });
            });
            this.setState({
                partList: partList,
                parts: result.data
            });
        })
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            let newFormData = new FormData();
            Object.keys(this.state.formData).forEach(key => {
                newFormData.append(key, this.state.formData[key])  
            });
            API.post(`/library/parts`, newFormData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...initialState,
                        snackbarOpen: true
                    });
                    this.getParts();
                }
                this.props.scrollToTop();
            });
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    clearDocument = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: initialState.formData.name
            }
        });
    };

    handleCheckChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleSelectChange = fieldName => selectedOption => {
        if (!selectedOption) {
            selectedOption = {value: null};
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, ()=>{
            if (fieldName === 'part'){
                API.get(`/researchDevelopment/changeOrders/part/${selectedOption.value}/actualIssue`).
                then((versions) => {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            version: versions.data ? versions.data  : 0,
                            maxVersion: versions.data ? versions.data  : 0,
                        }
                    })
                })
            }
        });
    }
       
    render() {
        const { formErrors } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Part Image
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Part Image
                            </Typography>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <form noValidate autoComplete="off">
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.partList} 
                                        label='Part *'
                                        value={this.state.formData.part}
                                        onChange={this.handleSelectChange('part')}
                                        error={formErrors && formErrors['part'] && true}
                                        errorText={formErrors && formErrors['part'] && formErrors['part']}
                                    />
                                </FormControl>
                                { parseInt(this.state.formData.maxVersion) > 0 && 
                                    <AutoCompleteSelect
                                        fullWidth
                                        label='Link Images To Part Version *'
                                        noClear
                                        value={this.state.formData.version}
                                        disableSort
                                        options={_.reverse(_.range(0, ( this.state.formData.maxVersion + 1)).map(i => ({value: i, label: i ? `v${i}` : 'Un-versioned'})))}                                    
                                        onChange={this.handleSelectChange('version')}
                                    />
                                }

                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={2} lg={1}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                checked={this.state.showImageFile}
                                                onChange={this.handleCheckChange('showImageFile')}
                                                value="showImageFile"
                                                color="primary"
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={10} lg={11}>
                                        <DragFileInput
                                            id="imageFileInput"
                                            name="imageFile"
                                            label="Image File (.jpg, .jpeg, .tiff, .tif, .gif, .png) *"
                                            file={this.state.formData.imageFile}
                                            errorText={formErrors && formErrors['imageFile']}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={this.clearDocument('imageFile')}
                                            emptyText='No file selected'
                                            disabled={!this.state.showImageFile}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={2} lg={1}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                checked={this.state.showRawImageFile}
                                                onChange={this.handleCheckChange('showRawImageFile')}
                                                value="showRawImageFile"
                                                color="primary"
                                                />
                                            }
                                        />    
                                    </Grid>
                                    <Grid item xs={10} lg={11}>
                                        <DragFileInput
                                            id="imageFileRawInput"
                                            name="imageFileRaw"
                                            label="Raw Image File (.psd, .ai, .zip, .zipx) *"
                                            file={this.state.formData.imageFileRaw}
                                            errorText={formErrors && formErrors['imageFileRaw']}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={this.clearDocument('imageFileRaw')}
                                            emptyText='No file selected'
                                            disabled={!this.state.showRawImageFile}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={2} lg={1}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                checked={this.state.show3DImageFile}
                                                onChange={this.handleCheckChange('show3DImageFile')}
                                                value="show3DImageFile"
                                                color="primary"
                                                />
                                            }
                                        />    
                                    </Grid>
                                    <Grid item xs={10} lg={11}>
                                        <DragFileInput
                                            id="image3DFileObj"
                                            name="image3DFileObj"
                                            label="3D File (.obj)*"
                                            file={this.state.formData.image3DFileObj}
                                            errorText={formErrors && formErrors['image3DFileObj']}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={this.clearDocument('image3DFileObj')}
                                            accepts='.obj, .OBJ'
                                            emptyText='No file selected'
                                            disabled={!this.state.show3DImageFile}
                                        />
                                        <DragFileInput
                                            id="image3DFileMtl"
                                            name="image3DFileMtl"
                                            label="3D File (.mtl) *"
                                            file={this.state.formData.image3DFileMtl}
                                            errorText={formErrors && formErrors['image3DFileMtl']}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={this.clearDocument('image3DFileMtl')}
                                            emptyText='No file selected'
                                            disabled={!this.state.show3DImageFile}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={2} lg={1}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                checked={this.state.show3DWebImageFile}
                                                onChange={this.handleCheckChange('show3DWebImageFile')}
                                                value="show3DWebImageFile"
                                                color="primary"
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={10} lg={11}>
                                        <DragFileInput
                                            id="webImage3DFile"
                                            name="webImage3DFile"
                                            label="Web 3D File (.glb) *"
                                            file={this.state.formData.webImage3DFile}
                                            errorText={formErrors && formErrors['webImage3DFile']}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={this.clearDocument('webImage3DFile')}
                                            emptyText='No file selected'
                                            disabled={!this.state.show3DWebImageFile}
                                        />
                                    </Grid>
                                </Grid>
                                <div className='buttonRow' style={{justifyContent: 'space-between', alignItems:'center'}}>
                                    <Typography variant="subtitle2">
                                        <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                                    </Typography>
                                    <Button onClick={this.handleConfirmationOpen}
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.isLoading || this.state.formData.version === null}
                                           >
                                        Add
                                    </Button>
                                </div>
                            </form>
                            <ConfirmationDialog 
                                open={this.state.confirmationOpen} 
                                success={this.handleConfirmationSuccess} 
                                close={this.handleConfirmationClose} 
                                title="Add A New Image Files?" 
                                message="Are you sure you want to add these image files?"
                            />
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarOpen}
                                onClose={this.handleSnackbarClose}
                                message='You have successfully added new image file(s)'
                            />
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default AddPartImage;