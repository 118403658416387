import React, { Component } from "react";
import _                    from "lodash";
import { multiply }         from "mathjs";

import { Grid, Typography } from '@material-ui/core/';

import DataTable      from 'Components/Common/DataTables/CiDataTable';
import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher';

import { colors } from 'Helpers/ColourHelper';

import logo from 'Assets/Images/clenaware_logo_icon.png';

class BuildStages extends Component {

    render () {

        const { subAssembly } = this.props;

        return (
            <Grid item xs={12}>
               {subAssembly.instruction ? (
                    <Grid container spacing={3}>
                        {_.map(subAssembly.instruction.stages, (i, idx) => (
                            <Grid item xs={12} style={{borderBottom: (idx + 1)  !== subAssembly.instruction.stages.length && `1px solid ${colors.disabled}`, paddingTop: '1.5em', paddingBottom: '1.5em'}}>
                                <>
                                    <Typography variant="h6"><b>Stage: {i.sais_number} - {i.sais_name ? i.sais_name : ''}</b></Typography>
                                    <br/>
                                    <TabBarSwitcher 
                                        disableMenu
                                        tabs={[
                                            {
                                                label:   "Parts",
                                                content: 
                                                    <DataTable
                                                        config={{
                                                            showTotals: true,
                                                            pagination: false,
                                                        }}
                                                        columns={[
                                                            {
                                                                field:          rowData =>  <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                                                <img src={rowData && rowData.part.default_image?.thumbnail_file_path ? rowData.part.default_image?.thumbnail_file_path : logo} alt={rowData.part.part_description} style={{maxHeight:35, maxWidth:35}} />
                                                                                            </div>,
                                                                sizeToContent:  true,
                                                            },
                                                            {
                                                                heading: "Number",
                                                                field: (rowData) => rowData.part.part_number,
                                                                sizeToContent: true,
                                                                showTotalLabel: true,
                                                                style: { width: "5%" },
                                                            },
                                                            {
                                                                heading: "Description",
                                                                field: (rowData) => rowData.part.part_description,
                                                                important: true,
                                                                style: { width: "37%" },
                                                            },
                                                            {
                                                                heading: "Qty",
                                                                field: (rowData) => rowData.saisp_qty,
                                                                fieldFormat: "decimal:3",
                                                                alignment: "right",
                                                                showTotal: true,
                                                                style: { width: "10%" },
                                                            },
                                                            {
                                                                heading: "Cost Per Item",
                                                                field: (rowData) =>
                                                                    rowData.part.default_cost.cost_per_unit,
                                                                fieldFormat: "decimal:5",
                                                                fieldPrefix: "£",
                                                                alignment: "right",
                                                                style: { width: "17%" },
                                                            },
                                                            {
                                                                heading: "Cost",
                                                                field: (rowData) =>
                                                                    rowData?.part?.default_cost?.cost_per_unit
                                                                        ? multiply(
                                                                                rowData.part.default_cost.cost_per_unit,
                                                                                parseFloat(rowData.saisp_qty)
                                                                            )
                                                                        : 0,
                                                                fieldFormat: "decimal:5",
                                                                alignment: "right",
                                                                fieldPrefix: "£",
                                                                showTotal: true,
                                                                style: { width: "14%" },
                                                            },
                                                            {
                                                                heading: "Part Usage",
                                                                field: (rowData) =>
                                                                    `${
                                                                        (rowData.saisp_qty /
                                                                            _.sumBy(
                                                                                subAssembly.instruction
                                                                                    .stages,
                                                                                (s) => {
                                                                                    let part = _.find(
                                                                                        s.parts,
                                                                                        (p) =>
                                                                                            p.saisp_part_id ===
                                                                                            rowData.saisp_part_id
                                                                                    );
                                                                                    return part
                                                                                        ? parseFloat(part.saisp_qty)
                                                                                        : 0;
                                                                                }
                                                                            )) *
                                                                        100
                                                                    }`,
                                                                alignment: "right",
                                                                fieldFormat: "percentage",
                                                                showTotal: true,
                                                                overwriteTotal: (
                                                                    <>
                                                                        {parseFloat(
                                                                            (_.sumBy(i.parts, (j) =>
                                                                                parseFloat(j.saisp_qty)
                                                                            ) /
                                                                                _.sumBy(
                                                                                    subAssembly
                                                                                        .sub_assembly_parts,
                                                                                    (j) => parseFloat(j.sa_qty)
                                                                                )) *
                                                                                100
                                                                        ).toFixed(2)}
                                                                        %
                                                                    </>
                                                                ),
                                                            },
                                                        ]}
                                                        rows={_.orderBy(i.parts, (rowData) => rowData.part.part_number, 'asc')}
                                                    />
                                            }, 
                                            i.jigs.length > 0 && {
                                                label: 'Jigs',
                                                content:
                                                    <DataTable
                                                        config={{
                                                            key: 'saisj_id'
                                                        }}
                                                        columns={[
                                                            {
                                                                field:          rowData =>  <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                                                <img src={rowData && rowData.jig.default_image?.thumbnail_file_path ? rowData.jig.default_image?.thumbnail_file_path : logo} alt={rowData.jig.part_description} style={{maxHeight:35, maxWidth:35}} />
                                                                                            </div>,
                                                                sizeToContent:  true,
                                                            },
                                                            {
                                                                heading:       'Number',
                                                                field:         'jig.part_number',
                                                                sizeToContent: true
                                                            },
                                                            {
                                                                heading:    'Description',
                                                                field:      'jig.part_description',
                                                            },
                                                        ]}
                                                        rows={i.jigs}
                                                    />
                                            }
                                        ]}
                                    />
                                </>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <>Instructions have not been linked yet</>
                )}
            </Grid>
        )
    }
}

export default BuildStages;