import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';
import uuidv4               from 'uuid/v4';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography, Button } from '@material-ui/core';

import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import Map                from 'Components/Common/Map/Map';
import MapMarker          from 'Components/Common/Map/MapMarker';
import UpdateMarkerDialog from 'Components/Common/Map/UpdateMarkerDialog';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import DataTable          from 'Components/Common/DataTables/CiDataTable';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';

const initialState = {
    markers:   [],
    categories: [],
    isLoading: true,
    uuid: uuidv4(),
    mapCenter: null,
    access: {
        edit:   false,
        delete: false,
    }
}

class Markers extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getMarkers();
        this.getCategories();
        this.getAccess();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/map-markers-edit'),
            API.get('/staff/my/access/check/map-markers-delete'),
        ]).then(([ editRes, deleteRes]) => {
            this.setState({ 
                access: { 
                    edit:   editRes?.data?.has_access || false, 
                    delete: deleteRes?.data?.has_access || false 
                } 
            });
        })
    }

    getMarkers = () => {
        API.get('/engineers/maps/markers', {params: { office: true }})
        .then(res => {
            this.setState({ 
                markers: res.data, 
                isLoading: false, 
                uuid: uuidv4()
            });
        })
    }

    getCategories = () => {
        API.get('/engineers/maps/categories')
        .then(res => {
            this.setState({
                categories: res.data,
            });
        })
    }

    delete = id => {
        API.post(`/engineers/maps/markers/${id}/delete`)
        .then(res => {
            this.getMarkers();
        })
    }

    viewImage = rowData => {
        this.props.deployDialog(
            <Grid container>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <img src={rowData.file_upload} alt='Map Marker' style={{maxWidth: 500}} />
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                </Grid>
            </Grid>, 'View Image', 'sm'
        )
    }

    render() {

        const { isLoading, uuid, categories, mapCenter, access } = this.state;

        if (isLoading) return (<LoadingCircle/>);

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} key={uuid}>
                    <Map 
                        showFilter
                        onMarkerChange={this.getMarkers}
                        allowCreateMarker
                        height={'50vh'}
                        zoom={7}
                        center={mapCenter}
                        disableGoogleMarkers
                        showStreetView
                        showSwapMapType
                        showMarkers
                        allowDeleteMarker
                        allowEditMarker
                    />
                </Grid>
                <Grid item xs={12}>
                    {_.map(_.uniqBy(categories, 'mmc_category'), c => 
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<AllIcon icon={icons.arrowDown} noMargin color={colors.white} />} >
                                <Typography>{c.mmc_category} ({_.filter(this.state.markers, i => i.category.mmc_category === c.mmc_category).length})</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <DataTable
                                    config={{
                                        key:        'mm_id',
                                        filterMod:  {
                                            clearable:              true,
                                            colSpan:                3,
                                        },
                                    }}
                                    columns={[
                                        {
                                            heading: 'Icon',
                                            field:   i => <MapMarker icon={i.category.mmc_icon} color={i.category.mmc_color} size={30}/>,
                                            sizeToContent:  true,
                                            filter:         'custom',
                                            filterMod:      {
                                                                dataRef:    (search, rowData) => {return `${rowData.category.mmc_name}|${rowData.mm_name}}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                                                label:      'Search'
                                                            }
                                        },
                                        {
                                            heading:        'Type',
                                            field:          'category.mmc_name',
                                            sizeToContent:  true
                                        },
                                        {
                                            heading: 'Name',
                                            field:   'mm_name',
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: 'Notes',
                                            field:   'mm_notes',
                                            truncate: 600,
                                            info:    i => _.map(i.mm_notes.split('\n'), j => <>{j}<br/></>),
                                        },
                                        {
                                            actions: i => ([
                                                {
                                                    name:     'Picture',
                                                    icon:     icons.image,
                                                    onClick:  this.viewImage,
                                                    disabled: !i.mm_image,
                                                },
                                                {
                                                    name: 'Jump to',
                                                    icon: icons.locationPin,
                                                    onClick: () => this.setState({ mapCenter: [ parseFloat(i.mm_lat), parseFloat(i.mm_lng) ] })
                                                },
                                                access.edit && {
                                                    name: 'Edit',
                                                    icon: icons.edit,
                                                    onClick: () => this.props.deployDialog(<UpdateMarkerDialog id={i.mm_id} onUpdate={()=>{ this.props.closeDialog(); this.getMarkers();}} />,'Update Marker', 'md'),
                                                },
                                                access.delete && {
                                                    name: 'Delete',
                                                    icon: icons.delete,
                                                    onClick: () => this.props.deployConfirmation('Are you sure you want to delete this marker?', 'Delete Marker', ()=>this.delete(i.mm_id))
                                                }
                                            ])
                                        }
                                    ]}
                                    rows={_.orderBy(_.filter(this.state.markers, i => i.category.mmc_category === c.mmc_category), i => `${i.category.mmc_name} ${i.mm_name}`)}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )}
                </Grid>
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
        deployDialog:       (content, title, size='md') => dispatch(deployDialog(content, title, null, size)),
        closeDialog:        ()                         => dispatch(closeDialog()),
    }
}

export default connect(null, mapDispatchToProps)(Markers);