import Button from '@material-ui/core/Button';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import FALightIcon from '../Icons/FontAwesome/FALightIcon';
import FABrandIcon from '../Icons/FontAwesome/FABrandIcon';
import FADuoIcon from '../Icons/FontAwesome/FADuoIcon';
import FASolidIcon from '../Icons/FontAwesome/FASolidIcon';
import Pagination from '../Pagination/Pagination';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import CopyToClipboardDatagrid from '../../Common/CopyToClipboard/CopyToClipboardDatagrid';
import FADataTableIcon from '../Icons/FontAwesome/FALightIcon';
import Paper from '@material-ui/core/Paper';
import {DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE} from 'Constants'
import { handleScrollToTop } from 'Actions/UI/UI';
import { setPersistence } from 'Actions/StatePersistence/StatePersistence';

const styles = theme => ({
    alternatingRowColours: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.background.default,
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }
});

const TableHeaderCell = withStyles(theme => ({
    head: {
      backgroundColor: '#0282C6',
      color: theme.palette.common.white,
      padding: '8px 12px',
    },
    body: {
      fontSize: 14,
      whiteSpace: 'nowrap'
    }
}))(TableCell);

const TableBodyCell = withStyles(theme => ({
    body: {
        padding: '12px 12px',
        whiteSpace: 'nowrap',
        color: 'inherit'
    }
}))(TableCell);

const TableBodyCellNoPadding = withStyles(theme => ({
    body: {
        padding: 0,
        color: 'inherit'
    }
}))(TableCell);

const TableBodyCellCard = withStyles(theme => ({
    body: {
        padding: 0,
        borderBottom: 'none',
        color: 'inherit'
    }
}))(TableCell);

const TableBodyCellDense = withStyles(theme => ({
    body: {
        padding: '4px 12px',
        whiteSpace: 'nowrap',
        color: 'inherit'
    }
}))(TableCell);

const initialState = () => ({
    pagination: {
        rows: [],
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    },
    responsiveActionsColumns: null,
    responsiveActionsRow: null
})
class DataTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state      = this.getInitialTableState();
    }
    componentDidMount = () => {
        if(!this.hasTableState()) {
            if(this.props.config.rowsPerPage){
                this.setState({
                    pagination: {
                        ...this.state.pagination,
                        rowsPerPage: this.props.config.rowsPerPage,
                    }
                });
            }
        }
    }
    componentDidUpdate = (prevProps, prevState) => {

        if(this.props.config?.persistenceId !== prevProps.config?.persistenceId) {
            if(!this.hasTableState()) {
                if(this.props.config.rowsPerPage){
                    this.setState({
                        pagination: {
                            ...this.state.pagination,
                            rowsPerPage: this.props.config.rowsPerPage,
                        }
                    });
                }
            } else {
                this.setState({
                    ...this.getInitialTableState()
                })
            }
            return;
        }

        if(JSON.stringify(prevState.pagination) !== JSON.stringify(this.state.pagination)) {
            this.saveTableState();
        }

        if(this.props.rows !== prevProps.rows) {
            this.setState({
                pagination: {
                    ...this.state.pagination,
                    page: Number.isInteger(this.props.config.page) ? this.props.config.page : this.state.pagination.page,
                    rowsPerPage: this.props.config.rowsPerPage ? this.props.config.rowsPerPage : this.state.pagination.rowsPerPage,
                },
            }, () => {
                this.saveTableState();
            });
        }

    }
    getInitialTableState = () => ({
        ...initialState(),
        ...(this.hasTableState() && this.props.statePersistence?.[`dataTable:${this.props?.config?.persistenceId}`])
    })

    hasTableState = () => {
        return this.props?.config?.persistenceId ? !!this.props.statePersistence?.[`dataTable:${this.props?.config?.persistenceId}`] : false;
    }
    
    saveTableState = () => {
        this.props?.config?.persistenceId !== null && this.props.setPersistence(`dataTable:${this.props?.config?.persistenceId}`, this.state);
    }
    handleChangePage = (event, page) => {
        this.setState({ 
            pagination: { 
                ...this.state.pagination, 
                page: page 
            }, 
        },
        () => {
            this.props.handleScrollToTop();
            this.props.config.gridPageChanged && this.props.config.gridPageChanged(page)
        });
    };  
    handleChangeRowsPerPage = event => {
        this.setState({ pagination: { ...this.state.pagination, rowsPerPage: event.target.value, page: 0 } }, this.props.config.gridRowsPerPageChanged && this.props.config.gridRowsPerPageChanged(event.target.value));
    };
    handleResponsiveActionsOpen = (columns, row) => {
        this.setState({
            ...this.state,
            responsiveActionsColumns: columns,
            responsiveActionsRow: row
        })
    }
    handleResponsiveActionsClose = (e) => {
        this.setState({
            ...this.state,
            responsiveActionsColumns: null,
            responsiveActionsRow: null
        })
    }
    renderHeader() {
        const { config, columns, rows } = this.props;
        const HeaderCellElement = config.plainHeader ? TableCell : TableHeaderCell;
        const { pagination } = this.state;
        return (
            <React.Fragment>
                <TableHead>
                    <TableRow>
                        {config.pagination && (
                            <Pagination
                                colSpan={columns.length}
                                count={rows.length}
                                rowsPerPage={(config.defaultRowsPerPage && config.defaultRowsPerPage) || pagination.rowsPerPage}
                                page={pagination.page}
                                onChangePage={(this.handleChangePage)}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                isMobile={this.props.ui.device.isMobile}
                                noRowSelector={config.noRowSelector}
                            />
                        )}
                    </TableRow>
                    <TableRow>
                        {_.map(columns, column => {
                            return (
                                ((this.props.ui.device.isMobile && (column.actions || (config.responsiveImportance && column.important !== true))) && (
                                    <React.Fragment />
                                )) || (
                                    <HeaderCellElement key={uuidv4()} style={{width: (column.actions || column.sizeToContent) && '1%', whiteSpace: (column.actions || column.sizeToContent) && 'nowrap', textAlign: (column.actions && 'center') || (column.alignment && column.alignment)}} {...(typeof config.headerCellProps === "function" && config.headerCellProps(column, rows))}>
                                        {(column.actions && 'Actions') || column.heading}
                                    </HeaderCellElement>
                                )
                            )
                        })}
                    </TableRow>
                </TableHead>
            </React.Fragment>
        );
    }
    renderBody() {
        const { pagination } = this.state;
        const { config, columns } = this.props;
        let rows = _.values(this.props.rows);
        if(typeof rows === "undefined" || rows.length === 0) {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={columns.length} style={{textAlign: 'center'}}>
                            <Typography>
                                {(config.noResultsText && (
                                    (config.noResultsPaper && (
                                        <Paper>
                                            {config.noResultsText}
                                        </Paper>
                                    )) || (
                                        config.noResultsText
                                    )
                                )) || (config.noResultsPaper && (
                                    <Paper>
                                        No results were found
                                    </Paper>
                                )) || (
                                    'No results were found'
                                )}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        } else {
            for (let i = 0, l = rows.length; i < l; i++) {
                rows[i]['rowIdx'] = i;
                rows[i]['rowNumber'] = i + 1;
                rows[i]['rowNumberReversed'] = (rows.length - i);
            }
            rows = (config.pagination && rows.slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage)) || rows;
            return (
                <TableBody>
                    {_.map(rows, (row, bodyIdx) => {
                        return this.renderRow(row, bodyIdx)
                    })}
                </TableBody>
            )
        }
    }
    renderRow(row, bodyIdx, indentDepth = 0) {
        const { classes, config, columns } = this.props;
        let TableCellElement = config.dense ? TableBodyCellDense : (config.isCard ? TableBodyCellCard : (config.noPadding ? TableBodyCellNoPadding : TableBodyCell));
        if(!this.props.ui.device.isMobile) {
            _.map(columns, (column, colIdx) => {
                if(column.actions && config.dense !== false) {
                    TableCellElement = TableBodyCellDense;
                }
            })
        }
        let skipColumns = 0;
        return (
            <React.Fragment key={`fragment${bodyIdx}`}>
                <TableRow key={`body2${bodyIdx}`} className={config.alternatingRowColours && classes.alternatingRowColours} {...(config.rowProps && config.rowProps(row))} onClick={() => { this.props.ui.device.isMobile && this.handleResponsiveActionsOpen(columns, row) }} hover={!config.noRowHover}>
                    {_.map(columns, (column, colIdx) => {
                        if(skipColumns > 0) {
                            skipColumns = skipColumns - 1;
                            return <React.Fragment key={`skip-${colIdx}`} />
                        } else {
                            let cellProps = column.cellProps && column.cellProps(row);
                            if(cellProps) {
                                cellProps = {
                                    ...cellProps,
                                    style: {
                                        ...cellProps.style,
                                        textAlign: column.alignment && column.alignment
                                    }
                                }
                            } else {
                                cellProps = {
                                    style: {
                                        textAlign: column.alignment && column.alignment
                                    }
                                }
                            }
                            if(column.truncate) {
                                cellProps.style = {
                                    ...cellProps.style,
                                    textOverflow: 'ellipsis',
                                    overflowX: 'hidden',
                                    whiteSpace: 'nowrap',
                                    maxWidth: 1
                                }
                            }
                            if(column.colSpan && column.colSpan(row) > 1 && !(this.props.ui.device.isMobile && config.responsiveImportance && column.important !== true)) {
                                skipColumns = column.colSpan(row) - 1;
                            }
                            
                            return (
                                (column.actions && (
                                    (this.props.ui.device.isMobile && (
                                        <React.Fragment />
                                    )) || (
                                        <TableCellElement style={{textAlign: 'center'}} key={`col${colIdx}`}>
                                            {column.actions(row).map((action, actnIdx) => {
                                                let ButtonElement = (action.label && Button) || IconButton;

                                                let IconEl = FALightIcon;
                                                if(action?.lib) {
                                                    switch(action?.lib) {
                                                        case 'brand':
                                                            IconEl = FABrandIcon;
                                                        break;
                                                        case 'solid':
                                                            IconEl = FASolidIcon;
                                                        break;
                                                        case 'duo':
                                                            IconEl = FADuoIcon;
                                                        break;
                                                        default:
                                                        break;
                                                    }
                                                }

                                                return (
                                                    ((action.type && action.type === "copyToClipboard" && (
                                                        <CopyToClipboardDatagrid key={`col${colIdx}${actnIdx}`} text={action.data} iconSize='medium' />
                                                    )) || (
                                                        (action.disabled &&
                                                            <ButtonElement disabled key={`col${colIdx}${actnIdx}`}>
                                                                {action.icon && <IconEl icon={action.icon} button noMargin={!action.label} disabled fixedHeight />} {action.label && action.label}
                                                            </ButtonElement>
                                                        ) || (
                                                            (action.link && (
                                                                <Link to={action.link} style={{textDecoration: 'none'}} key={`col${colIdx}${actnIdx}`}>
                                                                    <Tooltip title={action.name}>
                                                                        <ButtonElement>
                                                                            {action.icon && <IconEl icon={action.icon} button noMargin={!action.label} fixedHeight />} {action.label && action.label}
                                                                        </ButtonElement>
                                                                    </Tooltip>
                                                                </Link>
                                                            )) || (
                                                                action.onClick && 
                                                                <i onClick={() => action.onClick(row)} key={`col${colIdx}${actnIdx}`}>
                                                                    <Tooltip title={action.name}>
                                                                        <ButtonElement>
                                                                            {action.icon && <IconEl icon={action.icon} button noMargin={!action.label} fixedHeight style={{color:action.color || ''}} />} {action.label && action.label}
                                                                        </ButtonElement>
                                                                    </Tooltip>
                                                                </i>
                                                            )
                                                        )
                                                    ))
                                                )
                                            })}
                                        </TableCellElement>
                                    )
                                )) || (
                                    (this.props.ui.device.isMobile && config.responsiveImportance && column.important !== true && (
                                        <React.Fragment />
                                    )) || (
                                        <TableCellElement colSpan={(column.colSpan && column.colSpan(row) > 1 && column.colSpan(row)) || 1} {...cellProps} key={`col${colIdx}`}>
                                            {config.nesting && column.nestingDropdown && ((row.children && (
                                                <React.Fragment>
                                                    <div style={{display: 'inline-block', width: (30 * indentDepth) + 'px'}}></div>
                                                    <FALightIcon icon='chevron-down' button />
                                                </React.Fragment>
                                            )) || (
                                                <span style={{display: 'inline-block', width: (30 * indentDepth) + 'px'}}></span>
                                            ))}
                                            {this.renderCellData(column, row)}
                                        </TableCellElement>
                                    )
                                )
                            )
                        }
                    })}
                </TableRow>
                {config.nesting && row.children && this.renderNestedChildren(row.children, indentDepth + 1)}
            </React.Fragment>
        )
    }
    renderNestedChildren(children, depth = 0) {
        return (
            <React.Fragment>
                {_.map(children, (row, bodyIdx) => this.renderRow(row, bodyIdx, depth))}
            </React.Fragment>
        );
    }
    renderTotals() {
        const { pagination } = this.state;
        const { classes, config, columns } = this.props;
        let TableCellElement = config.dense ? TableBodyCellDense : TableBodyCell;
        if(config.showTotals) {
            let rows = _.values(this.props.rows);
            if(typeof rows === "undefined" || rows.length === 0) {
                return (
                    <React.Fragment />
                )
            } else {
                rows = (config.pagination && rows.slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage)) || rows;
                let totals = [];
                _.each(rows, function (row) {
                    _.each(columns, function (column, idx) {
                        if(column.showTotal) {
                            totals[idx] = ((idx in totals && totals[idx]) || 0) + ((typeof column.totalField === 'function' && Number(column.totalField(row))) || Number((column.field(row))));
                        } else {
                            if(column.showTotalLabel) {
                                totals[idx] = 'Total';
                            } else {
                                totals[idx] = '';
                            }
                        }
                    });
                });
                return (
                    <React.Fragment>
                        <TableBody>
                            <TableRow className={config.alternatingRowColours && classes.alternatingRowColours} key='totals'>
                                {_.map(columns, (column, idx) => {
                                    return (
                                        (this.props.ui.device.isMobile && config.responsiveImportance && column.important !== true && (
                                            <React.Fragment />
                                        )) || (
                                            <TableCellElement key={`totals-${idx}`} style={{textAlign: column.alignment && column.alignment, fontWeight: '500'}}>
                                                {(column.showTotal && (
                                                    <React.Fragment>
                                                        {(column.overwriteTotal && (
                                                            <React.Fragment>
                                                                {column.overwriteTotal}
                                                            </React.Fragment>
                                                        )) ||
                                                        (
                                                            <React.Fragment>
                                                                {column.fieldPrefix}
                                                                {
                                                                (column.fieldFormat === 'datetime' && (
                                                                    moment(new Date(totals[idx])).format("H:mm on DD/MM/YYYY")
                                                                )) ||
                                                                (column.fieldFormat === 'date' && (
                                                                    moment(new Date(totals[idx])).format("DD/MM/YYYY")
                                                                )) ||
                                                                (column.fieldFormat && column.fieldFormat.startsWith('percentage') && (
                                                                    parseFloat(totals[idx]).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2) + '%'
                                                                )) ||
                                                                (column.fieldFormat && column.fieldFormat.startsWith('decimal') && (
                                                                    parseFloat(totals[idx]).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2)
                                                                )) ||
                                                                    totals[idx]
                                                                }
                                                                {column.fieldSuffix}
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )) ||
                                                (column.showTotalLabel && (
                                                    <React.Fragment>
                                                        {totals[idx]}
                                                    </React.Fragment>
                                                ))}
                                            </TableCellElement>
                                        )
                                    )
                                })}
                            </TableRow>
                        </TableBody>
                    </React.Fragment>
                )
            }
        }
    }
    renderFullTotals() {
        const { classes, config, columns } = this.props;
        let TableCellElement = config.dense ? TableBodyCellDense : TableBodyCell;
        if(config.showFullTotals) {
            let rows = _.values(this.props.rows);
            if(typeof rows === "undefined" || rows.length === 0) {
                return (
                    <React.Fragment />
                )
            } else {
                let totals = [];
                _.each(rows, function (row) {
                    _.each(columns, function (column, idx) {
                        if(column.showTotal) {
                            totals[idx] = ((idx in totals && totals[idx]) || 0) + ((typeof column.totalField === 'function' && Number(column.totalField(row))) || Number((column.field(row))));
                        } else {
                            if(column.showTotalLabel) {
                                totals[idx] = 'Total';
                            } else {
                                totals[idx] = '';
                            }
                        }
                    });
                });
                return (
                    <React.Fragment>
                        <TableBody>
                            <TableRow className={config.alternatingRowColours && classes.alternatingRowColours} key='totals'>
                                {_.map(columns, (column, idx) => {
                                    return (
                                        <TableCellElement style={{textAlign: column.alignment && column.alignment, fontWeight: 'bold', borderBottom: config.plainPagination ? 0 : undefined}}>
                                            {(column.showTotal && (
                                                <React.Fragment>
                                                    {(column.overwriteTotal && (
                                                        <React.Fragment>
                                                            {column.overwriteTotal}
                                                        </React.Fragment>
                                                    )) ||
                                                    (
                                                        <React.Fragment>
                                                            {column.fieldPrefix}
                                                            {
                                                            (column.fieldFormat === 'datetime' && (
                                                                moment(new Date(totals[idx])).format("H:mm on DD/MM/YYYY")
                                                            )) ||
                                                            (column.fieldFormat === 'date' && (
                                                                moment(new Date(totals[idx])).format("DD/MM/YYYY")
                                                            )) ||
                                                            (column.fieldFormat && column.fieldFormat.startsWith('percentage') && (
                                                                parseFloat(totals[idx]).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2) + '%'
                                                            )) ||
                                                            (column.fieldFormat && column.fieldFormat.startsWith('decimal') && (
                                                                parseFloat(totals[idx]).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2)
                                                            )) ||
                                                                totals[idx]
                                                            }
                                                            {column.fieldSuffix}
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )) ||
                                            (column.showTotalLabel && (
                                                <React.Fragment>
                                                    {totals[idx]}
                                                </React.Fragment>
                                            ))}
                                        </TableCellElement>
                                    )
                                })}
                            </TableRow>
                        </TableBody>
                    </React.Fragment>
                )
            }
        }
    }
    renderFooter() {
        const { config, columns, rows } = this.props;
        const { pagination } = this.state;
        return (
            <TableFooter>
                <TableRow>
                    {config.pagination && (
                        <Pagination
                            colSpan={columns.length}
                            count={rows.length}
                            rowsPerPage={(config.defaultRowsPerPage && config.defaultRowsPerPage) || pagination.rowsPerPage}
                            page={pagination.page}
                            onChangePage={(this.handleChangePage)}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            isMobile={this.props.ui.device.isMobile}
                            noRowSelector={config.noRowSelector}
                            plainPagination={config.plainPagination}
                        />
                    )}
                </TableRow>
            </TableFooter>
        );
    }
    renderTooltip(field, text){
        return(
            <Tooltip
                title={
                    <Typography variant="body2">{text}</Typography>
                }
                placement="left"
            >
                <IconButton className={this.props.classes.iconButton}>
                    {field}
                </IconButton>
            </Tooltip>
        );
    }
    renderCellData(column, row, responsiveDialog = false) {
        let field = 
            responsiveDialog === true ? (
                <React.Fragment>
                    <Typography variant="body2">
                        {column.heading}:
                    </Typography>
                    <Typography variant="body1" style={{marginBottom: 16}}>
                        {column.fieldPrefix}
                        {
                        (column.fieldFormat === 'boolean' && (
                            (column.field(row) === 'active' && (
                                'Active'                        
                            )) || (column.field(row) === 'inactive' && (
                                'Inactive'
                            )) || ((column.field(row) === 'true' || column.field(row) === true) && (
                                'Y'                        
                            )) || ((column.field(row) === 'false' || column.field(row) === false) && (
                                'N'                                       
                            )) || (column.field(row) > 0 && (
                                'Y'                                    
                            )) || (column.field(row) === 0 && (
                                'N'       
                            )) || (
                                column.field(row)
                            )
                        )) ||
                        (column.fieldFormat === 'datetime' && (
                            moment(new Date(column.field(row))).format("H:mm on DD/MM/YYYY")
                        )) ||
                        (column.fieldFormat === 'date' && (
                            moment(new Date(column.field(row))).format("DD/MM/YYYY")
                        )) ||
                        (column.fieldFormat && column.fieldFormat.startsWith('percentage') && (
                            parseFloat(column.field(row)).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2) + '%'
                        )) ||
                        (column.fieldFormat && column.fieldFormat.startsWith('decimal') && (
                            parseFloat(column.field(row)).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2)
                        )) ||
                            column.field(row)
                        }
                        {column.fieldSuffix}
                    </Typography>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {column.fieldPrefix}
                    {(column.fieldFormat === 'boolean' && (
                        (column.field(row) === 'Y' && (
                            <FADataTableIcon icon="check" style={{color: '#2E7D32'}} button={column.tooltip ? true : false} noMargin />   
                        )) || (column.field(row) === 'N' && (
                            <FADataTableIcon icon="times" style={{color: '#c62828'}} button={column.tooltip ? true : false} noMargin /> 
                        )) || (column.field(row) === 'active' && (
                            <FADataTableIcon icon="check" style={{color: '#2E7D32'}} button={column.tooltip ? true : false} noMargin />   
                        )) || (column.field(row) === 'inactive' && (
                            <FADataTableIcon icon="times" style={{color: '#c62828'}} button={column.tooltip ? true : false} noMargin />   
                        )) || ((column.field(row) === 'true' || column.field(row) === true) && (
                            <FADataTableIcon icon="check" style={{color: '#2E7D32'}} button={column.tooltip ? true : false} noMargin />                       
                        )) || ((column.field(row) === 'false' || column.field(row) === false) && (
                            <FADataTableIcon icon="times" style={{color: '#c62828'}} button={column.tooltip ? true : false} noMargin />                                    
                        )) || (column.field(row) > 0 && (
                            <FADataTableIcon icon="check" style={{color: '#2E7D32'}} button={column.tooltip ? true : false} noMargin />                                  
                        )) || (column.field(row) === 0 && (
                            <FADataTableIcon icon="times" style={{color: '#c62828'}} button={column.tooltip ? true : false} noMargin />    
                        )) || (column.field(row) === 'With Discount' && (
                            <FADataTableIcon icon="check" style={{color: '#2E7D32'}} button={column.tooltip ? true : false} noMargin />      
                        )) || (column.field(row) === 'Without Discount' && (
                            <FADataTableIcon icon="times" style={{color: '#c62828'}} button={column.tooltip ? true : false} noMargin />    
                        )) || (
                            column.field(row)
                        )
                    )) ||
                    (column.fieldFormat === 'datetime' && (
                        moment(new Date(column.field(row))).format("H:mm on DD/MM/YYYY")
                    )) ||
                    (column.fieldFormat === 'date' && (
                        moment(new Date(column.field(row))).format("DD/MM/YYYY")
                    )) ||
                    (column.fieldFormat && column.fieldFormat.startsWith('percentage') && (
                        parseFloat(column.field(row)).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2) + '%'
                    )) ||
                    (column.fieldFormat && column.fieldFormat.startsWith('decimal') && (
                        parseFloat(column.field(row)).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2)
                    )) ||
                        column.field(row)
                    }
                    {column.fieldSuffix}
                </React.Fragment>
            );

            if(column.tooltip){
                return this.renderTooltip(field, column.tooltip(row));
            } else {
                return field;
            }
    }
    renderResponsiveDialog = () => {
        let mainColumnFound = false;
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={Boolean(this.state.responsiveActionsRow)}
                onClose={this.handleResponsiveActionsClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {_.map(this.state.responsiveActionsColumns, (column, colIdx) => {
                        if(column.main)
                            mainColumnFound = true;
                        return column.main && this.renderCellData(column, this.state.responsiveActionsRow)
                    })}
                    {!mainColumnFound && (
                        this.renderCellData(this.state.responsiveActionsColumns[0], this.state.responsiveActionsRow)
                    )}
                    <IconButton aria-label="Close" className={this.props.classes.closeButton} onClick={this.handleResponsiveActionsClose}>
                        <FALightIcon icon='times' noMargin />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component="div">
                        {_.map(this.state.responsiveActionsColumns, (column, colIdx) => {
                            return !column.actions && !column.hideInResponsiveDialog && this.renderCellData(column, this.state.responsiveActionsRow, true)
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {_.map(this.state.responsiveActionsColumns, (column, colIdx) => {
                        return (
                            (column.actions &&
                                <React.Fragment>
                                    {column.actions(this.state.responsiveActionsRow).map((action, actnIdx) => {
                                        return (
                                            <React.Fragment>
                                                {(action.disabled &&
                                                    <Button disabled key={`col${colIdx}${actnIdx}`}>
                                                        {action.icon && <FALightIcon icon={action.icon} button disabled />} {action.name}
                                                    </Button>
                                                ) || (
                                                    (action.link && (
                                                        <Link to={action.link} style={{textDecoration: 'none'}} key={`col${colIdx}${actnIdx}`}>
                                                            <Button>
                                                                {action.icon && <FALightIcon icon={action.icon} button />} {(action.label && action.label) || action.name}
                                                            </Button>
                                                        </Link>
                                                    )) || (
                                                        action.onClick && 
                                                        <i onClick={() => { this.handleResponsiveActionsClose(); action.onClick(this.state.responsiveActionsRow) }} key={`col${colIdx}${actnIdx}`}>
                                                            <Button>
                                                                {action.icon && <FALightIcon icon={action.icon} button />} {(action.label && action.label) || action.name}
                                                            </Button>
                                                        </i>
                                                    )
                                                )}
                                            </React.Fragment>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        )
                    })}
                </DialogActions>
            </Dialog>
        )
    }
    render() {
        const { config } = this.props;
        return (
            <div style={{maxWidth: '100%', overflowX: 'auto'}}>
                {(config.isLoading === true && (
                    <LoadingCircle />
                )) || (
                    <React.Fragment>
                        <div style={{border: config.withBorder && '1px solid rgba(224, 224, 224, 1)', borderRadius: config.withBorderRadius && '4px'}}>
                            <Typography variant="body2" component={Table}>
                                {!config.noHeader && this.renderHeader()}
                                {this.renderBody()}
                                {this.renderTotals()}
                                {this.renderFullTotals()}
                                {this.renderFooter()}
                            </Typography>
                            {Boolean(this.state.responsiveActionsRow) && this.renderResponsiveDialog()}
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        statePersistence: state.statePersistence,
        ui: state.ui
    };
}
function mapDispatchToProps(dispatch) {
    return {
        handleScrollToTop: () => {
            dispatch(handleScrollToTop())
        },
        setPersistence: (key, state) => {
            dispatch(setPersistence(key, state))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DataTable));