import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom'
/* Dashboard */
import Dashboard from '../../Dashboard/Dashboard';
/* Staff */
import StaffSearch from '../../Staff/StaffSearch/StaffSearch';
import AddStaff from '../../Staff/AddStaff/AddStaff';
import UpdateStaff from '../../Staff/UpdateStaff/UpdateStaff';
import StaffAccess from '../../Staff/StaffAccess/StaffAccess';
import ViewStaff from '../../Staff/ViewStaff/ViewStaff';
import StaffTroll from '../../Staff/StaffTroll/StaffTroll';

/* Parts */
import PartNumbering             from 'Components/Parts/PartNumbering/PartNumbering';
import SerialNumbers             from 'Components/Parts/SerialNumbers/SerialNumbers';
import ViewSerialNumbers         from 'Components/Parts/SerialNumbers/ViewSerialNumbers';
import PartSearch                from 'Components/Parts/PartSearch/PartSearch';
import AddPart                   from 'Components/Parts/AddPart/AddPart';
import ViewPart                  from 'Components/Parts/ViewPart/ViewPart';
import UpdatePart                from 'Components/Parts/UpdatePart/UpdatePart';
import WarrantyTerms             from 'Components/Parts/WarrantyTerms/WarrantyTerms';
import ElectricalConnections     from 'Components/Parts/ElectricalConnections/ElectricalConnections';
import ViewElectricalConnections from 'Components/Parts/ElectricalConnections/ViewElectricalConnections';
import PartsCategories           from 'Components/Parts/PartCategories/PartCategories';
import PartsCategory             from 'Components/Parts/PartCategories/PartsCategory';
import ViewWriteOff              from 'Components/Parts/WriteOffs/ViewWriteOff';


/* Documents */
import DocumentCategories        from 'Components/Documents/DocumentCategories/DocumentCategories';
import AddDocument               from 'Components/Documents/AddDocument/AddDocument';
import DocumentSearch            from 'Components/Documents/DocumentSearch/DocumentSearch';
import ViewDocument              from 'Components/Documents/ViewDocument/ViewDocument';
import AddDocumentUpdateOrder    from 'Components/Documents/DocumentUpdateOrders/AddDocumentUpdateOrder/AddDocumentUpdateOrder';
import DocumentUpdateOrderSearch from 'Components/Documents/DocumentUpdateOrders/DocumentUpdateOrderSearch/DocumentUpdateOrderSearch';
import UpdateDocument            from 'Components/Documents/UpdateDocument/UpdateDocument';
import UpdateDocumentParts       from 'Components/Documents/UpdateDocumentParts/UpdateDocumentParts';
import UpdatePickingParts        from 'Components/Documents/UpdatePickingParts/UpdatePickingParts';

/* Calendar */
import ViewMyCalendar from '../../Calendar/ViewCalendar/ViewMyCalendar';
import ViewFullCalendar from '../../Calendar/ViewCalendar/ViewFullCalendar';
import AddCalendarEvent from '../../Calendar/AddCalendarEvent/AddCalendarEvent';
import UpdateCalendarEvent from '../../Calendar/UpdateCalendarEvent/UpdateCalendarEvent';
import CalendarCategories from '../../Calendar/CalendarCategories/CalendarCategories';
import CalendarEventSearch from '../../Calendar/CalendarEventSearch/CalendarEventSearch';
/* R & D */
import AddDesignChangeOrder from '../../R&D/AddDesignChangeOrder/AddDesignChangeOrder';
import DesignChangeOrderSearch from '../../R&D/DesignChangeOrderSearch/DesignChangeOrderSearch';
import AddPartFiles from '../../R&D/AddPartFiles/AddPartFiles';
import AddProductionServiceBulletin from '../../R&D/ProductionServiceBulletin/AddProductionServiceBulletin';
import ProductionServiceBulletinSearch from '../../R&D/ProductionServiceBulletin/ProductionServiceBulletinSearch';
import UpdateProductionServiceBulletin from '../../R&D/ProductionServiceBulletin/UpdateProductionServiceBulletin/UpdateProductionServiceBulletin';

/* Reports */
import StockReports             from 'Components/Reports/StockReports/StockReports';
import PartStockReport          from 'Components/Reports/StockReports/PartStockReport/PartStockReport';
import OverallStockReport       from 'Components/Reports/StockReports/OverallStockReport/OverallStockReport';
import MinimumStockLevelReport  from 'Components/Reports/StockReports/MinimumStockLevelReport/MinimumStockLevelReport';
import MaximumStockLevelReport  from 'Components/Reports/StockReports/MaximumStockLevelReport/MaximumStockLevelReport';
import PartsDeliveryReport      from 'Components/Reports/StockReports/PartsDeliveryReport/PartsDeliveryReport';
import SalesReports             from 'Components/Reports/SalesReports/SalesReports';
import AccountsReports          from 'Components/Reports/AccountReports/AccountReports';
import WorksOrderReport         from 'Components/Reports/WorksOrderReports/WorksOrderReport';
import MasterExcelReport        from 'Components/Reports/MasterExcelReport';
import MarketingReports         from 'Components/Reports/MarketingReports/MarketingReports';
import YoYsReports              from 'Components/Reports/YoYsReports/YoYsReports';
import PerformanceReports       from 'Components/Reports/PerformanceReports/PerformanceReports';

/* Suppliers */
import AddSupplierTypes from 'Components/Suppliers/AddSupplierTypes/AddSupplierTypes';
import AddSupplier      from 'Components/Suppliers/AddSupplier/AddSupplier';
import SupplierSearch   from 'Components/Suppliers/SupplierSearch/SupplierSearch';
import ViewSupplier     from 'Components/Suppliers/ViewSupplier/ViewSupplier';
import UpdateSupplier   from 'Components/Suppliers/UpdateSupplier/UpdateSupplier';
import SupplierParts    from 'Components/Suppliers/SupplierParts/SupplierParts';

/* Costs */
import AddCost from '../../Costs/AddCost/AddCost';
import CostSearch from '../../Costs/CostSearch/CostSearch';
import ViewCost from '../../Costs/ViewCost/ViewCost';
import UpdateCost from '../../Costs/UpdateCost/UpdateCost';
import UpdateSupplierReference from '../../Costs/UpdateSupplierReference/UpdateSupplierReference';

/* Sub Assemblies */
import AddSubAssembly               from 'Components/SubAssemblies/AddSubAssembly/AddSubAssembly';
import UpdateSubAssembly            from 'Components/SubAssemblies/UpdateSubAssembly/UpdateSubAssembly';
import ViewSubAssembly              from 'Components/SubAssemblies/ViewSubAssembly/ViewSubAssembly';
import SubAssemblyHistory           from 'Components/SubAssemblies/SubAssemblyHistory/SubAssemblyHistory';
import SubAssemblySearch            from 'Components/SubAssemblies/SubAssemblySearch/SubAssemblySearch';
import SubAssemblyCreateBuildStages from 'Components/SubAssemblies/AddSubAssembly/SubAssemblyCreateBuildStages';
import SubAssemblyCreateBuildJigs   from 'Components/SubAssemblies/AddSubAssembly/SubAssemblyCreateBuildJigs';

/* Prices */
import AddPrice                 from 'Components/Prices/AddPrice/AddPrice';
import PriceSearch              from 'Components/Prices/PriceSearch/PriceSearch';
import ViewPrice                from 'Components/Prices/ViewPrice/ViewPrice';
import UpdatePrice              from 'Components/Prices/UpdatePrice/UpdatePrice';
import MasterExcelPrices        from 'Components/Prices/MasterExcelPrices/MasterExcelPrices';
import SparePartsCalculator     from 'Components/Prices/SparePartsCalculator/SparePartsCalculator';
import PartCategoriesCalculator from 'Components/Prices/PartCategoriesCalculator/PartCategoriesCalculator';

/* Library */
import AddPartImage from '../../Library/AddPartImage/AddPartImage';
import PartImageSearch from '../../Library/PartImageSearch/PartImageSearch';
import EditPartImage from '../../Library/EditPartImage/EditPartImage';
import AddCategoryImage from '../../Library/AddCategoryImage/AddCategoryImage';
import CategoryImageSearch from '../../Library/CategoryImageSearch/CategoryImageSearch';
import EditCategoryImage from '../../Library/EditCategoryImage/EditCategoryImage';
/* Video */
import VideoCategories from '../../Videos/VideoCategories/VideoCategories';
import AddVideo from '../../Videos/AddVideo/AddVideo';
import VideoSearch from '../../Videos/VideoSearch/VideoSearch';
import ViewVideo from '../../Videos/ViewVideo/ViewVideo';
import AddVideoUpdateOrder from '../../Videos/AddVideoUpdateOrder/AddVideoUpdateOrder';
import VideoOrderUpdateSearch from '../../Videos/VideoOrderUpdateSearch/VideoOrderUpdateSearch';
import UpdateVideo from '../../Videos/UpdateVideo/UpdateVideo';
/* Supplier Stock Returns */
import AddSupplierStockReturns from '../../SupplierStockReturns/AddSupplierStockReturns/AddSupplierStockReturns';
import SupplierStockReturnSearch from '../../SupplierStockReturns/SupplierStockReturnSearch/SupplierStockReturnSearch';
import StockReturnEmailTemplates from '../../SupplierStockReturns/StockReturnEmailTemplates/StockReturnEmailTemplates';
import UpdateSupplierStockReturnTemplates from '../../SupplierStockReturns/UpdateSupplierStockReturnTemplates/UpdateSupplierStockReturnTemplates';
import ViewSupplierStockReturn from '../../SupplierStockReturns/ViewSupplierStockReturn/ViewSupplierStockReturn';
import SupplierStockReturnApproval from '../../SupplierStockReturns/SupplierStockReturnApproval/SupplierStockReturnApproval';
import SupplierStockReturnDispatch from '../../SupplierStockReturns/SupplierStockReturnDispatch/SupplierStockReturnDispatch';
import SupplierStockReturnCompleted from '../../SupplierStockReturns/SupplierStockReturnCompleted/SupplierStockReturnCompleted';
import SupplierStockReturnDeclined from '../../SupplierStockReturns/SupplierStockReturnDeclined/SupplierStockReturnDeclined';
import SupplierStockReturnCancelled from '../../SupplierStockReturns/SupplierStockReturnCancelled/SupplierStockReturnCancelled';
import SupplierStockReturnCredit from '../../SupplierStockReturns/SupplierStockReturnCredit/SupplierStockReturnCredit';
import SupplierStockReturnDelivery from '../../SupplierStockReturns/SupplierStockReturnDelivery/SupplierStockReturnDelivery';
import UpdateSupplierStockReturnDelivery from '../../SupplierStockReturns/UpdateSupplierStockReturnDelivery/UpdateSupplierStockReturnDelivery';
import SupplierStockReturnConfirmation from '../../SupplierStockReturns/SupplierStockReturnConfirmation/SupplierStockReturnConfirmation';

/* Stock */
import OpeningStock                from 'Components/Stock/OpeningStock/OpeningStock';
import StockActivity               from 'Components/Stock/StockActivity/StockActivity';
import MinMaxStockLevels           from 'Components/Stock/MinMaxStockLevels/MinMaxStockLevels';
import UpdateStock                 from 'Components/Stock/UpdateStock/UpdateStock';
import BuildChecker                from 'Components/Stock/BuildChecker/BuildChecker';
import PrintAllStockLabels         from 'Components/Stock/PrintAllStockLabels/PrintAllStockLabels';
import AddStockWriteOff            from 'Components/Stock/StockWriteOff/AddStockWriteOff/AddStockWriteOff';
import StockWriteOffDefaultReasons from 'Components/Stock/StockWriteOff/StockWriteOffDefaultReasons/StockWriteOffDefaultReasons';
import StockWriteOffSearch         from 'Components/Stock/StockWriteOff/StockWriteOffSearch/StockWriteOffSearch';
import ViewStockWriteOff           from 'Components/Stock/StockWriteOff/ViewStockWriteOff/ViewStockWriteOff';
import AddStockAddition            from 'Components/Stock/StockAddition/AddStockAddition/AddStockAddition';
import StockAdditionDefaultReasons from 'Components/Stock/StockAddition/StockAdditionDefaultReasons/StockAdditionDefaultReasons';
import StockAdditionSearch         from 'Components/Stock/StockAddition/StockAdditionSearch/StockAdditionSearch';
import ViewStockAddition           from 'Components/Stock/StockAddition/ViewStockAddtion/ViewStockAddition';
import StockMovementReasons        from 'Components/Stock/StockMovement/StockMovementReasons/StockMovementReasons';
import StockMovementTemplates      from 'Components/Stock/StockMovement/StockMovementTemplates/StockMovementTemplates';
import StockMovementTemplate       from 'Components/Stock/StockMovement/StockMovementTemplates/StockMovementTemplate';
import ViewStockMovements          from 'Components/Stock/StockMovement/ViewStockMovements';
import ViewStockMovement           from 'Components/Stock/StockMovement/StockMovement/ViewStockMovement';
import EditStockMovement           from 'Components/Stock/StockMovement/StockMovement/EditStockMovement';
import StockCorrections            from 'Components/Stock/StockCorrection/StockCorrections';
import EditStockCorrection         from 'Components/Stock/StockCorrection/StockCorrection/EditStockCorrection';
import ViewStockCorrection         from 'Components/Stock/StockCorrection/StockCorrection/ViewStockCorrection';
import NewStockTake                from 'Components/Stock/StockTake/NewStockTake/NewStockTake';
import StockTake                   from 'Components/Stock/StockTake/StockTakes/StockTake';
import ManualStockTake             from 'Components/Stock/StockTake/StockTake/ManualStockTake';
import ScanStockTake               from 'Components/Stock/StockTake/StockTake/ScanStockTake';               
import ReviewStockTake             from 'Components/Stock/StockTake/StockTake/ReviewStockTake';   
import ViewStockTake               from 'Components/Stock/StockTake/ViewStockTake/ViewStockTake';     
import LowInStock                  from 'Components/Stock/LowInStock/LowInStock';
import HighInStock                 from 'Components/Stock/LowInStock/HighInStock';
import StockTracker                from 'Components/Stock/LowInStock/StockTracker';
import StockMasterExcel            from 'Components/Stock/StockMasterExcel/StockMasterExcel';
import BlueTrollySearch            from 'Components/Stock/BlueTrolly/BlueTrollySearch';

/* works order */
import NewWorksOrder     from 'Components/WorksOrder/NewWorksOrder/NewWorksOrder';   
import AllWorksOrder     from 'Components/WorksOrder/SearchWorksOrder/AllWorksOrders';   
import ViewWorksOrder    from 'Components/WorksOrder/ViewWorksOrder/ViewWorksOrder';
import PickWorksOrder    from 'Components/WorksOrder/PickWorksOrder/PickWorksOrder';
import PickStockShortage from 'Components/WorksOrder/PickStockShortage/PickStockShortage';
import WorksOrderReasons from 'Components/WorksOrder/WorksOrderReasons/WorksOrderReasons';

/* Accounts */
import CompanyInformation       from 'Components/Accounts/CompanyInformation/CompanyInformation';
import NominalCodes             from 'Components/Accounts/NominalCodes/NominalCodes';
import UpdateNominalCodes       from 'Components/Accounts/UpdateNominalCodes/UpdateNominalCodes';
import VATRates                 from 'Components/Accounts/VATRates/VATRates';
import CustomerDiscounts        from 'Components/Accounts/CustomerDiscounts/CustomerDiscounts';
import CustomerPaymentTerms     from 'Components/Accounts/CustomerPaymentTerms/CustomerPaymentTerms';
import CustomerAccountStatuses  from 'Components/Accounts/CustomerAccountStatuses/CustomerAccountStatuses';
import AddBankAccount           from 'Components/Accounts/AddBankAccount/AddBankAccount';
import BankSearch               from 'Components/Accounts/BankSearch/BankSearch';
import BankTransactions         from 'Components/Accounts/BankTransactions/BankTransactions';
import BankPayments             from 'Components/Accounts/BankPayments/BankPayments';
import BankRunningTotals        from 'Components/Accounts/BankRunningTotals/BankRunningTotals';
import CustomerCreditStatements from 'Components/Accounts/CustomerCreditStatements/CustomerCreditStatements';
import CustomerPaymentRequests  from 'Components/Accounts/CustomerPaymentRequests/CustomerPaymentRequests';

/* Parts Ordering */
import PartsOrderEmailTemplate          from 'Components/PartsOrdering/PartsOrderEmailTemplate/PartsOrderEmailTemplate';
import UpdatePartsOrderEmailTemplate    from 'Components/PartsOrdering/UpdatePartsOrderEmailTemplate/UpdatePartsOrderEmailTemplate';
import DefaultPartsOrderList            from 'Components/PartsOrdering/DefaultPartsOrderList/DefaultPartsOrderList';
import UpdateDefaultPartsOrderList      from 'Components/PartsOrdering/UpdateDefaultPartsOrderList/UpdateDefaultPartsOrderList';
import DefaultAddresses                 from 'Components/PartsOrdering/DefaultAddresses/DefaultAddresses';
import UpdateDefaultAddress             from 'Components/PartsOrdering/UpdateDefaultAddress/UpdateDefaultAddress';
import AddPartsOrder                    from 'Components/PartsOrdering/AddPartsOrder/AddPartsOrder';
import PartsOrderApproval               from 'Components/PartsOrdering/PartsOrderApproval/PartsOrderApproval';
import PartsOrderDeclined               from 'Components/PartsOrdering/PartsOrderDeclined/PartsOrderDeclined';
import PartsOrderCancelled              from 'Components/PartsOrdering/PartsOrderCancelled/PartsOrderCancelled';
import PartsOrderCompleted              from 'Components/PartsOrdering/PartsOrderCompleted/PartsOrderCompleted';
import PartsOrderConfirmation           from 'Components/PartsOrdering/PartsOrderConfirmation/PartsOrderConfirmation';
import PartsOrderSearch                 from 'Components/PartsOrdering/PartsOrderSearch/PartsOrderSearch';
import PartsOrderConfirmDelivery        from 'Components/PartsOrdering/PartsOrderConfirmDelivery/PartsOrderConfirmDelivery';
import PartsOrderDelivery               from 'Components/PartsOrdering/PartsOrderDelivery/PartsOrderDelivery';
import UpdatePartsOrderDelivery         from 'Components/PartsOrdering/UpdatePartsOrderDelivery/UpdatePartsOrderDelivery';
import PartsOrderInvoice                from 'Components/PartsOrdering/PartsOrderInvoice/PartsOrderInvoice';
import ViewPartsOrder                   from 'Components/PartsOrdering/ViewPartsOrder/ViewPartsOrder';
import UpdatePartsOrderOtherItems       from 'Components/PartsOrdering/UpdatePartsOrderOtherItems/UpdatePartsOrderOtherItems';
import UpdatePartsOrderDeliverySchedule from 'Components/PartsOrdering/UpdatePartsOrderDeliverySchedule/UpdatePartsOrderDeliverySchedule';
import PartsOrderingTracker             from 'Components/PartsOrdering/PartsOrderingTracker/PartsOrderingTracker';    
import ThreeDPrinterTracker             from 'Components/PartsOrdering/ThreeDPrinterTracker/ThreeDPrinterTracker';    

/* Purchase Orders */
import PODefaultAddresses from '../../PurchaseOrders/DefaultAddresses/DefaultAddresses';
import POUpdateDefaultAddress from '../../PurchaseOrders/UpdateDefaultAddress/UpdateDefaultAddress';
import DefaultPurchaseOrderList from '../../PurchaseOrders/DefaultPurchaseOrderList/DefaultPurchaseOrderList';
import UpdateDefaultPurchaseOrderList from '../../PurchaseOrders/UpdateDefaultPurchaseOrderList/UpdateDefaultPurchaseOrderList';
import PurchaseOrderEmailTemplates from '../../PurchaseOrders/PurchaseOrderEmailTemplate/PurchaseOrderEmailTemplate';
import UpdatePurchaseOrderEmailTemplate from '../../PurchaseOrders/UpdatePurchaseOrderEmailTemplate/UpdatePurchaseOrderEmailTemplate';
import AddPurchaseOrder from '../../PurchaseOrders/AddPurchaseOrder/AddPurchaseOrder';
import PurchaseOrderSearch from '../../PurchaseOrders/PurchaseOrderSearch/PurchaseOrderSearch';
import PurchaseOrderApproval from '../../PurchaseOrders/PurchaseOrderApproval/PurchaseOrderApproval';
import PurchaseOrderDeclined from '../../PurchaseOrders/PurchaseOrderDeclined/PurchaseOrderDeclined';
import PurchaseOrderCancelled from '../../PurchaseOrders/PurchaseOrderCancelled/PurchaseOrderCancelled';
import PurchaseOrderCompleted from '../../PurchaseOrders/PurchaseOrderCompleted/PurchaseOrderCompleted';
import PurchaseOrderConfirmation from '../../PurchaseOrders/PurchaseOrderConfirmation/PurchaseOrderConfirmation';
import PurchaseOrderDelivery from '../../PurchaseOrders/PurchaseOrderDelivery/PurchaseOrderDelivery';
import PurchaseOrderInvoice from '../../PurchaseOrders/PurchaseOrderInvoice/PurchaseOrderInvoice';
import ViewPurchaseOrder from '../../PurchaseOrders/ViewPurchaseOrder/ViewPurchaseOrder';
import UpdatePurchaseOrder from '../../PurchaseOrders/UpdatePurchaseOrder/UpdatePurchaseOrder';
/* E-Commerce */
import EcommerceProductCategories from '../../Ecommerce/EcommerceProductCategories/EcommerceProductCategories';
import UpdateEcommerceProductCategory from '../../Ecommerce/UpdateEcommerceProductCategory/UpdateEcommerceProductCategory';
import AddEcommerceProduct from '../../Ecommerce/AddEcommerceProduct/AddEcommerceProduct';
import EcommerceProductSearch from '../../Ecommerce/EcommerceProductSearch/EcommerceProductSearch';
import ViewEcommerceProduct from '../../Ecommerce/ViewEcommerceProduct/ViewEcommerceProduct';
import UpdateEcommerceProduct from '../../Ecommerce/UpdateEcommerceProduct/UpdateEcommerceProduct';
/* Emails */
import EmailTemplates from '../../Email/EmailTemplates/EmailTemplates';
import UpdateEmailTemplate from '../../Email/UpdateEmailTemplate/UpdateEmailTemplate';
import SendFiles from '../../Email/SendFiles/SendFiles';
import EmailSearch from '../../Email/EmailSearch/EmailSearch';
import ViewEmail from '../../Email/ViewEmail/ViewEmail';
import ViewEmailFolders from  '../../Email/EmailFolders/ViewEmailFolders';

/* Engineers */
import EngineerForm     from 'Components/Engineers/Engineers/EngineerForm/EngineerForm';
import ViewEngineer     from 'Components/Engineers/Engineers/ViewEngineer/ViewEngineer';
import SearchEngineers  from 'Components/Engineers/Engineers/SearchEngineers/SearchEngineers';
import MapAndMarkers    from 'Components/Engineers/MapAndMarkers/MapAndMarkers';
import EngineerEmailTemplates from 'Components/Engineers/EmailTemplates/EngineerEmailTemplates';
import UpdateEngineerEmailTemplates from 'Components/Engineers/EmailTemplates/UpdateEngineerEmailTemplates';

/* Engineers - Jobs */
import JobsSearch       from 'Components/Engineers/Jobs/JobsSearch/JobsSearch';
import ViewJob          from 'Components/Engineers/Jobs/ViewJob/ViewJob';

/* Equipment */
import EquipmentSearch  from 'Components/Equipment/EquipmentSearch/EquipmentSearch';
import ViewEquipment    from 'Components/Equipment/ViewEquipment/ViewEquipment';
import EquipmentForm    from 'Components/Equipment/EquipmentForm/EquipmentForm';
import EquipmentReasons from 'Components/Equipment/EquipmentReasons/EquipmentReasons';

/* Forms */
import FormSearch       from 'Components/Forms/FormSearch/FormSearch';
import FormView         from 'Components/Forms/FormView/FormView';
import FormCategories   from 'Components/Forms/FormCategories/FormCategories';
import RelatedForms     from 'Components/Forms/RelatedForms/RelatedForms';
import MasterElements   from 'Components/Forms/MasterElements/MasterElements';

/*  Vehicles */
import VehicleForm from 'Components/Vehicles/VehicleForm/VehicleForm';
import ViewVehicle from 'Components/Vehicles/ViewVehicle/ViewVehicle';
import SearchVehicles from 'Components/Vehicles/SearchVehicles/SearchVehicles';

import SearchVehicleInsurance from 'Components/Vehicles/Insurance/SearchVehicleInsurance';
import SearchVehicleDevices from 'Components/Vehicles/Devices/SearchVehicleDevices';
import SearchVehicleBreakdown from 'Components/Vehicles/Breakdown/SearchVehicleBreakdown';

/* Engineer Uploads */
import AddUploads from '../../Engineers/Uploads/AddUploads/AddUploads';
import UploadsSearch from '../../Engineers/Uploads/UploadsSearch/UploadsSearch';
/* Meetings */
import NewMeeting from '../../Meetings/NewMeeting/NewMeeting';
import MeetingSearch from '../../Meetings/MeetingSearch/MeetingSearch';

/* MArketing */
import AddShortlink      from 'Components/Marketing/AddShortlink/AddShortlink';
import ShortlinkSearch   from 'Components/Marketing/ShortlinkSearch/ShortlinkSearch';
import EmailCampaigns    from 'Components/Marketing/EmailCampaigns/EmailCampaigns';
import ViewMailingList   from 'Components/Marketing/EmailCampaigns/ViewMailingList/ViewMailingList';
import ViewEmailCampaign from 'Components/Marketing/EmailCampaigns/ViewEmailCampaign/ViewEmailCampaign';
import CustomerMarketing from 'Components/Marketing/CustomerMarketing/CustomerMarketing';
import ContactsExcel     from 'Components/Marketing/ContactsExcel/ContactsExcel';
import EmailValidation   from 'Components/Marketing/EmailValidation/EmailValidation';
import MailingLists      from 'Components/Marketing/MailingLists/MailingLists';
import ManualContacts    from 'Components/Marketing/ManualContacts/ManualContacts';

/* Customers */
import AddCustomer             from 'Components/Customers/AddCustomer/AddCustomer';
import CustomerSearch          from 'Components/Customers/CustomerSearch/CustomerSearch';
import ViewCustomer            from 'Components/Customers/ViewCustomer/ViewCustomer';
import UpdateCustomerContact   from 'Components/Customers/UpdateCustomerContact/UpdateCustomerContact';
import ViewCustomerContact     from 'Components/Customers/ViewCustomerContact/ViewCustomerContact';
import ViewCustomerAddress     from 'Components/Customers/ViewCustomerAddress/ViewCustomerAddress';
import UpdateCustomerAddress   from 'Components/Customers/UpdateCustomerAddress/UpdateCustomerAddress';
import UpdateCustomer          from 'Components/Customers/UpdateCustomer/UpdateCustomer';
import AddCustomerContact      from 'Components/Customers/AddCustomerContact/AddCustomerContact';
import AddCustomerAddress      from 'Components/Customers/AddCustomerAddress/AddCustomerAddress';
import CustomerQuotationSearch from 'Components/Customers/CustomerQuotationSearch/CustomerQuotationSearch';
import CustomerOrderSearch     from 'Components/Customers/CustomerOrderSearch/CustomerOrderSearch';
import CustomerAccess          from 'Components/Customers/CustomerAccess/CustomerAccess';
//import CustomerEmails          from 'Components/Customers/CustomerEmails/CustomerEmails';
import CustomerMaps            from 'Components/Customers/CustomerMaps/CustomerMaps.js';

/* Customer Returns */
import AddCreditNote                       from 'Components/CustomerReturns/AddCreditNote/AddCreditNote';
import SearchCreditNote                    from 'Components/CustomerReturns/SearchCreditNote/SearchCreditNote';
import ViewCreditNote                      from 'Components/CustomerReturns/ViewCreditNote/ViewCreditNote';
import CustomerReturnsEmailTemplates       from 'Components/CustomerReturns/EmailTemplates/CustomerReturnsEmailTemplates';
import UpdateCustomerReturnsEmailTemplates from 'Components/CustomerReturns/EmailTemplates/UpdateCustomerReturnsEmailTemplates';
import CustomerReturnsLegalDocuments       from 'Components/CustomerReturns/LegalDocuments/LegalDocuments';
import CreditNoteInvoiceApproval           from 'Components/CustomerReturns/CreditNoteInvoiceApproval/CreditNoteInvoiceApproval';
import CreditNoteInvoicePayments           from 'Components/CustomerReturns/CreditNoteInvoicePayments/CreditNoteInvoicePayments';
import Greenaware                          from 'Components/CustomerReturns/Greenaware/Greenaware';
import AddCustomerReturn                   from 'Components/CustomerReturns/CustomerReturns/AddCustomerReturns/AddCustomerReturn';
import ViewCustomerReturns                 from 'Components/CustomerReturns/CustomerReturns/ViewCustomerReturns/ViewCustomerReturns';
import ViewCustomerReturn                  from 'Components/CustomerReturns/CustomerReturns/ViewCustomerReturn/ViewCustomerReturn';
import PickCustomerReturn                  from 'Components/CustomerReturns/CustomerReturns/PickCustomerReturn/PickCustomerReturn';
import CustomerReturnDefaults              from 'Components/CustomerReturns/CustomerReturns/CustomerReturnDefaults/CustomerReturnDefaults';
import ViewCustomerReturnInspection        from 'Components/CustomerReturns/CustomerReturns/CustomerReturnInspection/ViewCustomerReturnInspection';
import ViewCustomerReturnRepairs           from 'Components/CustomerReturns/CustomerReturns/ViewCustomerReturnRepairs/ViewCustomerReturnRepairs';
import ViewCustomerReturnRepair            from 'Components/CustomerReturns/CustomerReturns/ViewCustomerReturnRepair/ViewCustomerReturnRepair';

/* Sales */
import AddQuotation             from 'Components/Sales/Quotation/AddQuotation/AddQuotation';
import ViewQuotation            from 'Components/Sales/Quotation/ViewQuotation/ViewQuotation';
import QuotationSearch          from 'Components/Sales/Quotation/QuotationSearch/QuotationSearch';
import AmendQuotation           from 'Components/Sales/Quotation/AmendQuotation/AmendQuotation';
import AddOrder                 from 'Components/Sales/Order/AddOrder/AddOrder';
import ViewOrder                from 'Components/Sales/Order/ViewOrder/ViewOrder';
import OrderSearch              from 'Components/Sales/Order/OrderSearch/OrderSearch';
import AmendOrder               from 'Components/Sales/Order/AmendOrder/AmendOrder';
import OrderApproval            from 'Components/Sales/Order/OrderApproval/OrderApproval';
import OrderConfirmed           from 'Components/Sales/Order/OrderConfirmed/OrderConfirmed';
import OrderCompleted           from 'Components/Sales/Order/OrderCompleted/OrderCompleted';
import OrderDeclined            from 'Components/Sales/Order/OrderDeclined/OrderDeclined';
import OrderCancelled           from 'Components/Sales/Order/OrderCancelled/OrderCancelled';
import OrderPayment             from 'Components/Sales/Order/OrderPayment/OrderPayment';
import OrderPicking             from 'Components/Sales/Order/OrderPicking/OrderPicking';
import PickOrder                from 'Components/Sales/Order/Picking/PickOrder';
import OrderDespatch            from 'Components/Sales/Order/OrderDespatch/OrderDespatch';
import ViewDispatch             from 'Components/Sales/Order/ViewDispatch/ViewDispatch';
import OrderInvoice             from 'Components/Sales/Order/OrderInvoice/OrderInvoice';
import InvoiceApproval          from 'Components/Sales/Order/InvoiceApproval/InvoiceApproval';
import InvoicePayments          from 'Components/Sales/Order/InvoicePayments/InvoicePayments';
import SalesLegalDocuments      from 'Components/Sales/LegalDocuments/LegalDocuments';
import UpdateSalesEmailTemplate from 'Components/Sales/UpdateSalesEmailTemplate/UpdateSalesEmailTemplate';
import SalesEmailTemplate       from 'Components/Sales/SalesEmailTemplate/SalesEmailTemplate';
import AddCommercialInvoice     from 'Components/Sales/Order/AddCommercialInvoice/AddCommercialInvoice';
import AmendCommercialInvoice   from 'Components/Sales/Order/AmendCommercialInvoice/AmendCommercialInvoice';
import RecurringInvoice         from 'Components/Sales/Order/RecurringInvoice/RecurringInvoice';
import AllPackages              from 'Components/Sales/Packages/AllPackages/AllPackages'; 
import AddPackage               from 'Components/Sales/Packages/AddPackage/AddPackage';
import ViewPackage              from 'Components/Sales/Packages/ViewPackage/ViewPackage';

/* Phone */
import CallHistory      from "Components/Phone/CallHistory";
import GlobalPhoneBook  from "Components/Phone/GlobalPhoneBook";

/* Tasks & Reminders */
import Tasks from '../../Tasks/Tasks/Tasks';
import AddTask from '../../Tasks/AddTask/AddTask';
import Reminders from '../../Reminders/Reminders/Reminders';
import AddReminder from '../../Reminders/AddReminder/AddReminder';
/* Couriers */
import AddCourier from 'Components/Couriers/AddCourier/AddCourier';
import CourierSearch from 'Components/Couriers/CourierSearch/CourierSearch';
import ViewCourier from 'Components/Couriers/ViewCourier/ViewCourier';
import UpdateCourier from 'Components/Couriers/UpdateCourier/UpdateCourier';
/* My Account */
import MyAccount from '../../MyAccount/MyAccount';
/* Error Handling */
import Error404 from '../../Common/ErrorHandling/Error404';
import { handleAppError } from '../../../Actions/UI/UI';

/* Toolbox */
import QuickPartQrScan from 'Components/ToolBox/QuickPartQrScan';
import FindStock       from 'Components/ToolBox/FindStock';
import NavReorder      from 'Components/ToolBox/NavReorder';
import Printing        from 'Components/ToolBox/Printing';
import Processes       from 'Components/ToolBox/Processes';

// /* Performance */
import MonthlyTargets from 'Components/Performance/MonthlyTargets/MonthlyTargets';

/* Survey */
import SurveySearch from 'Components/Survey/SurveySearch';
import SurveyView from 'Components/Survey/View/SurveyView';

const initialState = {
    networkOffline: false,
    lastPath: '',
}

class AdminRouteSwitcher extends React.PureComponent {
    
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidUpdate(prevProps) {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            this.props.changePath(this.props.location.pathname);
        }
    }
    
    render() {
        const { changePage, scrollToTop } = this.props;
        return (
            <Switch>
                {/* Dashboard */}
                <Route exact path='/'                                                               render={(props) => <Dashboard                           {...props} />} />
                <Route exact path='/dashboard'                                                      render={(props) => <Dashboard                           {...props} />} />

                {/* Staff Routes */}
                <Route exact path='/staff/search'                                                   render={(props) => <StaffSearch                         {...props} />} />
                <Route exact path='/staff/troll'                                                    render={(props) => <StaffTroll                          {...props} />} />
                <Route exact path='/staff/add'                                                      render={(props) => <AddStaff                            {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/staff/update/:staffId'                                          render={(props) => <UpdateStaff                         {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/staff/access/:staffId'                                          render={(props) => <StaffAccess                         {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/staff/view/:staffId'                                            render={(props) => <ViewStaff                           {...props} />} />

                {/* Part Routes */}
                <Route exact path='/parts/numbering'                                                render={(props) => <PartNumbering                       {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts/serial-numbers'                                           render={(props) => <SerialNumbers                       {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts/serial-numbers/:modelTypeId'                              render={(props) => <ViewSerialNumbers                   {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts/serial-numbers/history/:serialNumber'                     render={(props) => <SerialNumbers                       {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts/add'                                                      render={(props) => <AddPart                             {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts/search'                                                   render={(props) => <PartSearch                          {...props} />} />
                <Route exact path='/parts/view/:id'                                                 render={(props) => <ViewPart                            {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts/update/:id'                                               render={(props) => <UpdatePart                          {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts/warranty-terms'                                           render={(props) => <WarrantyTerms                       {...props} />} />
                <Route exact path='/parts/electrical-connections'                                   render={(props) => <ElectricalConnections               {...props} />} />
                <Route exact path='/parts/electrical-connections/view/:id'                          render={(props) => <ViewElectricalConnections           {...props} />} />
                <Route exact path='/parts/electrical-connections/edit/:id'                          render={(props) => <ViewElectricalConnections           {...props} edit={1} />} />
                <Route exact path='/parts/categories'                                               render={(props) => <PartsCategories                     {...props} />} />
                <Route exact path='/parts/categories/:pcId'                                         render={(props) => <PartsCategory                       {...props} />} />
                <Route exact path='/parts/writeOffs/view/:id'                                       render={(props) => <ViewWriteOff                        {...props} />} />

                {/* Documents */}
                <Route exact path='/documents/categories'                                           render={(props) => <DocumentCategories                  {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/documents/add'                                                  render={(props) => <AddDocument                         {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/documents/update/:id'                                           render={(props) => <UpdateDocument                      {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/documents/update-parts/:id'                                     render={(props) => <UpdateDocumentParts                 {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/documents/picking-parts/:id'                                    render={(props) => <UpdatePickingParts                  {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/documents/search'                                               render={(props) => <DocumentSearch                      {...props} />} />
                <Route exact path='/documents/view/:id'                                             render={(props) => <ViewDocument                        {...props} />} />
                <Route exact path='/documents/update-order/add'                                     render={(props) => <AddDocumentUpdateOrder              {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/documents/update-order/search'                                  render={(props) => <DocumentUpdateOrderSearch           {...props} />} />
                
                {/* Calendar */}
                <Route exact path='/calendar'                                                       render={(props) => <ViewMyCalendar                      {...props} />} />
                <Route exact path='/calendar/full'                                                  render={(props) => <ViewFullCalendar                    {...props} />} />
                <Route exact path='/calendar/full/:eventId'                                         render={(props) => <ViewFullCalendar                    {...props} />} />
                <Route exact path='/calendar/events/add'                                            render={(props) => <AddCalendarEvent                    {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/calendar/events/update/:eventId'                                render={(props) => <UpdateCalendarEvent                 {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/calendar/events/search'                                         render={(props) => <CalendarEventSearch                 {...props} />} />
                <Route exact path='/calendar/categories'                                            render={(props) => <CalendarCategories                  {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/calendar/:eventId'                                              render={(props) => <ViewMyCalendar                      {...props} />} />
                
                {/* R & D */}
                <Route exact path='/research-development/change-order/add'                          render={(props) => <AddDesignChangeOrder                {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/research-development/change-order/search'                       render={(props) => <DesignChangeOrderSearch             {...props} />} />
                <Route exact path='/research-development/part-files/add'                            render={(props) => <AddPartFiles                        {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/research-development/production-service-bulletin/add'           render={(props) => <AddProductionServiceBulletin        {...props} />} />
                <Route exact path='/research-development/production-service-bulletin/search'        render={(props) => <ProductionServiceBulletinSearch     {...props} />} />
                <Route exact path='/research-development/production-service-bulletin/update/:id'    render={(props) => <UpdateProductionServiceBulletin     {...props} />} />

                {/* Reports */}
                <Route exact path='/reports/stock'                                                  render={(props) => <StockReports                        {...props} />} />
                <Route exact path='/reports/part-stock-report'                                      render={(props) => <PartStockReport                     {...props} />} />
                <Route exact path='/reports/overall-stock-report'                                   render={(props) => <OverallStockReport                  {...props} />} />
                <Route exact path='/reports/minimum-stock-level-report'                             render={(props) => <MinimumStockLevelReport             {...props} />} />
                <Route exact path='/reports/maximum-stock-level-report'                             render={(props) => <MaximumStockLevelReport             {...props} />} />
                <Route exact path='/reports/parts-delivery-report'                                  render={(props) => <PartsDeliveryReport                 {...props} />} />
                <Route exact path='/reports/sales'                                                  render={(props) => <SalesReports                        {...props} />} />
                <Route exact path='/reports/account'                                                render={(props) => <AccountsReports                     {...props} />} />
                <Route exact path='/reports/masterExcel'                                            render={(props) => <MasterExcelReport                   {...props} />} />
                <Route exact path='/reports/worksOrders'                                            render={(props) => <WorksOrderReport                    {...props} />} />
                <Route exact path='/reports/marketing'                                              render={(props) => <MarketingReports                    {...props} />} />
                <Route exact path='/reports/yoysales'                                               render={(props) => <YoYsReports                         {...props} />} />
                <Route exact path='/reports/performance'                                            render={(props) => <PerformanceReports                  {...props} />} />


                {/* Suppliers */}
                <Route exact path='/suppliers/types/add'                                            render={(props) => <AddSupplierTypes                    {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/suppliers/add'                                                  render={(props) => <AddSupplier                         {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/suppliers/search'                                               render={(props) => <SupplierSearch                      {...props} />} />
                <Route exact path='/suppliers/view/:id'                                             render={(props) => <ViewSupplier                        {...props} />} />
                <Route exact path='/suppliers/view/:id/parts'                                       render={(props) => <SupplierParts                       {...props} />} />
                <Route exact path='/suppliers/update/:id'                                           render={(props) => <UpdateSupplier                      {...props} scrollToTop={scrollToTop} />} />

                {/* Costs */}
                <Route exact path='/costs/add'                                                      render={(props) => <AddCost                             {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/costs/search'                                                   render={(props) => <CostSearch                          {...props} />} />
                <Route exact path='/costs/view/:id'                                                 render={(props) => <ViewCost                            {...props} />} />
                <Route exact path='/costs/update/:id'                                               render={(props) => <UpdateCost                          {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/costs/suppliers-reference/update'                               render={(props) => <UpdateSupplierReference             {...props} scrollToTop={scrollToTop} />} />
                
                {/* Sub Assemblies */}
                <Route exact path='/sub-assemblies/add'                                             render={(props) => <AddSubAssembly                      {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sub-assemblies/update/:id'                                      render={(props) => <UpdateSubAssembly                   {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sub-assemblies/view/:id'                                        render={(props) => <ViewSubAssembly                     {...props} />} />
                <Route exact path='/sub-assemblies/history/:id'                                     render={(props) => <SubAssemblyHistory                  {...props} />} />
                <Route exact path='/sub-assemblies/search'                                          render={(props) => <SubAssemblySearch                   {...props} />} />
                <Route exact path='/sub-assemblies/createBuildStages/:id'                           render={(props) => <SubAssemblyCreateBuildStages        {...props} />} />
                <Route exact path='/sub-assemblies/createBuildJigs/:id'                             render={(props) => <SubAssemblyCreateBuildJigs          {...props} />} />

                {/* Prices */}
                <Route exact path='/prices/add'                                                     render={(props) => <AddPrice                            {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/prices/search'                                                  render={(props) => <PriceSearch                         {...props} />} />
                <Route exact path='/prices/view/:id'                                                render={(props) => <ViewPrice                           {...props} />} />
                <Route exact path='/prices/update/:id'                                              render={(props) => <UpdatePrice                         {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/prices/excel'                                                   render={(props) => <MasterExcelPrices                   {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/prices/spare-parts-calculator'                                  render={(props) => <SparePartsCalculator                {...props}/>} />
                <Route exact path='/prices/part-categories-calculator'                              render={(props) => <PartCategoriesCalculator            {...props}/>} />

                {/* Library */}
                <Route exact path='/library/parts/add'                                              render={(props) => <AddPartImage                        {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/library/parts/search'                                           render={(props) => <PartImageSearch                     {...props} />} />
                <Route exact path='/library/parts/update/:id/:ref'                                  render={(props) => <EditPartImage                       {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/library/category/add'                                           render={(props) => <AddCategoryImage                    {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/library/category/search'                                        render={(props) => <CategoryImageSearch                 {...props} />} />
                <Route exact path='/library/category/update/:id/:ref'                               render={(props) => <EditCategoryImage                   {...props} scrollToTop={scrollToTop} />} />

                {/* Videos */}
                <Route exact path='/videos/categories'                                              render={(props) => <VideoCategories                     {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/videos/add'                                                     render={(props) => <AddVideo                            {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/videos/search'                                                  render={(props) => <VideoSearch                         {...props} />} />
                <Route exact path='/videos/view/:id'                                                render={(props) => <ViewVideo                           {...props} />} />
                <Route exact path='/videos/update-order/add'                                        render={(props) => <AddVideoUpdateOrder                 {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/videos/update-order/search'                                     render={(props) => <VideoOrderUpdateSearch              {...props} />} />
                <Route exact path='/videos/update/:id'                                              render={(props) => <UpdateVideo                         {...props} scrollToTop={scrollToTop} />} />

                {/* Supplier Stock Returns */}
                <Route exact path='/supplier-stock-return/add'                                      render={(props) => <AddSupplierStockReturns             {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/supplier-stock-return/search'                                   render={(props) => <SupplierStockReturnSearch           {...props} />} />
                <Route exact path='/supplier-stock-return/approval'                                 render={(props) => <SupplierStockReturnApproval         {...props} />} />
                <Route exact path='/supplier-stock-return/dispatch'                                 render={(props) => <SupplierStockReturnDispatch         {...props} />} />
                <Route exact path='/supplier-stock-return/completed'                                render={(props) => <SupplierStockReturnCompleted        {...props} />} />
                <Route exact path='/supplier-stock-return/declined'                                 render={(props) => <SupplierStockReturnDeclined         {...props} />} />
                <Route exact path='/supplier-stock-return/cancelled'                                render={(props) => <SupplierStockReturnCancelled        {...props} />} />
                <Route exact path='/supplier-stock-return/confirmation'                             render={(props) => <SupplierStockReturnConfirmation     {...props} />} />
                <Route exact path='/supplier-stock-return/credit'                                   render={(props) => <SupplierStockReturnCredit           {...props} />} />
                <Route exact path='/supplier-stock-return/delivery'                                 render={(props) => <SupplierStockReturnDelivery         {...props} />} />
                <Route exact path='/supplier-stock-return/delivery/update/:id'                      render={(props) => <UpdateSupplierStockReturnDelivery   {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/supplier-stock-return/view/:id'                                 render={(props) => <ViewSupplierStockReturn             {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/supplier-stock-return/stock-return-email-templates'             render={(props) => <StockReturnEmailTemplates           {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/supplier-stock-return/stock-return-email-templates/update/:id'  render={(props) => <UpdateSupplierStockReturnTemplates  {...props} scrollToTop={scrollToTop} />} />

                {/* Stock */}
                <Route exact path='/stock/opening-stock'                                            render={(props) => <OpeningStock                        {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/stock/activity'                                                 render={(props) => <StockActivity                       {...props} />} />
                <Route exact path='/stock/activity/:pid'                                            render={(props) => <StockActivity                       {...props} />} />
                <Route exact path='/stock/min-max-levels'                                           render={(props) => <MinMaxStockLevels                   {...props} />} />
                <Route exact path='/stock/update/:id'                                               render={(props) => <UpdateStock                         {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/stock/build-checker'                                            render={(props) => <BuildChecker                        {...props} />} />
                <Route exact path='/stock/print-all-labels'                                         render={(props) => <PrintAllStockLabels                 {...props} />} />
                <Route exact path='/stock/write-off/add'                                            render={(props) => <AddStockWriteOff                    {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/stock/write-off/default-reasons'                                render={(props) => <StockWriteOffDefaultReasons         {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/stock/write-off/search'                                         render={(props) => <StockWriteOffSearch                 {...props} />} />
                <Route exact path='/stock/write-off/view/:id'                                       render={(props) => <ViewStockWriteOff                   {...props} />} />
                <Route exact path='/stock/additions/add'                                            render={(props) => <AddStockAddition                    {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/stock/additions/default-reasons'                                render={(props) => <StockAdditionDefaultReasons         {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/stock/additions/search'                                         render={(props) => <StockAdditionSearch                 {...props} />} />
                <Route exact path='/stock/additions/view/:id'                                       render={(props) => <ViewStockAddition                   {...props} />} />
                <Route exact path='/stock/movement'                                                 render={(props) => <ViewStockMovements                  {...props} />} />
                <Route exact path='/stock/movement/reasons'                                         render={(props) => <StockMovementReasons                {...props} />} />
                <Route exact path='/stock/movement/templates'                                       render={(props) => <StockMovementTemplates              {...props} />} />
                <Route exact path='/stock/movement/templates/:smtId'                                render={(props) => <StockMovementTemplate               {...props} />} />
                <Route exact path='/stock/movement/edit/:smId'                                      render={(props) => <EditStockMovement                   {...props} />} />
                <Route exact path='/stock/movement/:smId'                                           render={(props) => <ViewStockMovement                   {...props} />} />
                <Route exact path='/stock/correction/edit/:scId'                                    render={(props) => <EditStockCorrection                 {...props} />} />
                <Route exact path='/stock/correction/:scId'                                         render={(props) => <ViewStockCorrection                 {...props} />} />
                <Route exact path='/stock/correction'                                               render={(props) => <StockCorrections                    {...props} />} />
                <Route exact path='/stock/take/new'                                                 render={(props) => <NewStockTake                        {...props} />} />
                <Route exact path='/stock/take'                                                     render={(props) => <StockTake                           {...props} />} />
                <Route exact path='/stock/take/manual/:stId'                                        render={(props) => <ManualStockTake                     {...props} />} />
                <Route exact path='/stock/take/scan/:stId'                                          render={(props) => <ScanStockTake                       {...props} />} />
                <Route exact path='/stock/take/review/:stId'                                        render={(props) => <ReviewStockTake                     {...props} />} />
                <Route exact path='/stock/take/view/:stId'                                          render={(props) => <ViewStockTake                       {...props} />} />
                <Route exact path='/stock/low'                                                      render={(props) => <LowInStock                          {...props} />} />
                <Route exact path='/stock/high'                                                     render={(props) => <HighInStock                         {...props} />} />
                <Route exact path='/stock/tracker'                                                  render={(props) => <StockTracker                        {...props} />} />
                <Route exact path='/stock/tracker/:tab'                                             render={(props) => <StockTracker                        {...props} />} />
                <Route exact path='/stock/masterExcel'                                              render={(props) => <StockMasterExcel                    {...props} />} />
                <Route exact path='/stock/blue-trolley'                                             render={(props) => <BlueTrollySearch                    {...props} />} />

                {/* Works Order */}
                <Route exact path='/WorksOrders/new'                                                render={(props) => <NewWorksOrder                       {...props} />} />
                <Route exact path='/WorksOrders/new/:saId'                                          render={(props) => <NewWorksOrder                       {...props} />} />
                <Route exact path='/WorksOrders/all'                                                render={(props) => <AllWorksOrder                       {...props} />} />
                <Route exact path='/WorksOrders/active'                                             render={(props) => <AllWorksOrder                       {...props} worksOrderType='active' />} />
                <Route exact path='/WorksOrders/completed'                                          render={(props) => <AllWorksOrder                       {...props} worksOrderType='completed' />} />
                <Route exact path='/WorksOrders/cancelled'                                          render={(props) => <AllWorksOrder                       {...props} worksOrderType='cancelled' />} />
                <Route exact path='/WorksOrders/view/:woId'                                         render={(props) => <ViewWorksOrder                      {...props} scrollToTop={scrollToTop}/>} />
                <Route exact path='/WorksOrders/pick/:woId'                                         render={(props) => <PickWorksOrder                      {...props} />} />
                <Route exact path='/WorksOrders/shortages/:woId'                                    render={(props) => <PickStockShortage                   {...props} />} />
                <Route exact path='/WorksOrders/WorksOrderReasons'                                  render={(props) => <WorksOrderReasons                   {...props} />} />

                {/* Accounts */}
                <Route exact path='/accounts/company-information'                                   render={(props) => <CompanyInformation                  {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/accounts/nominal-codes'                                         render={(props) => <NominalCodes                        {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/accounts/nominal-codes/update/:id'                              render={(props) => <UpdateNominalCodes                  {...props} />} />
                <Route exact path='/accounts/vat-rates'                                             render={(props) => <VATRates                            {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/accounts/customer-discounts'                                    render={(props) => <CustomerDiscounts                   {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/accounts/customer-payment-terms'                                render={(props) => <CustomerPaymentTerms                {...props} />} />
                <Route exact path='/accounts/customer-account-statuses'                             render={(props) => <CustomerAccountStatuses             {...props} />} />
                <Route exact path='/accounts/add-bank-account'                                      render={(props) => <AddBankAccount                      {...props} />} />
                <Route exact path='/accounts/unassigned-bank-transactions/:id'                      render={(props) => <BankTransactions                    {...props} />} />
                <Route exact path='/accounts/bank-transactions/:id'                                 render={(props) => <BankTransactions                    {...props} />} />
                <Route exact path='/accounts/bank-search'                                           render={(props) => <BankSearch                          {...props} />} />
                <Route exact path='/accounts/bank-payments/:id'                                     render={(props) => <BankPayments                        {...props} />} />
                <Route exact path='/accounts/bank-running-totals/:id'                               render={(props) => <BankRunningTotals                   {...props} />} />
                <Route exact path='/accounts/customer-credit-statements'                            render={(props) => <CustomerCreditStatements            {...props} />} />
                <Route exact path='/accounts/customer-payment-requests'                             render={(props) => <CustomerPaymentRequests             {...props} />} />

                {/* Parts Ordering */}
                <Route exact path='/parts-order/email-template'                                     render={(props) => <PartsOrderEmailTemplate             {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts-order/email-template/update/:id'                          render={(props) => <UpdatePartsOrderEmailTemplate       {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts-order/default-list'                                       render={(props) => <DefaultPartsOrderList               {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts-order/default-list/update/:id'                            render={(props) => <UpdateDefaultPartsOrderList         {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts-order/default-addresses'                                  render={(props) => <DefaultAddresses                    {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts-order/default-address/update/:id'                         render={(props) => <UpdateDefaultAddress                {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts-order/add'                                                render={(props) => <AddPartsOrder                       {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts-order/approval'                                           render={(props) => <PartsOrderApproval                  {...props} />} />
                <Route exact path='/parts-order/declined'                                           render={(props) => <PartsOrderDeclined                  {...props} />} />
                <Route exact path='/parts-order/cancelled'                                          render={(props) => <PartsOrderCancelled                 {...props} />} />
                <Route exact path='/parts-order/completed'                                          render={(props) => <PartsOrderCompleted                 {...props} />} />
                <Route exact path='/parts-order/confirmation'                                       render={(props) => <PartsOrderConfirmation              {...props} />} />
                <Route exact path='/parts-order/search'                                             render={(props) => <PartsOrderSearch                    {...props} />} />
                <Route exact path='/parts-order/delivery/confirm/:id'                               render={(props) => <PartsOrderConfirmDelivery           {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts-order/delivery'                                           render={(props) => <PartsOrderDelivery                  {...props} />} />
                <Route exact path='/parts-order/delivery/update/:id'                                render={(props) => <UpdatePartsOrderDelivery            {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts-order/invoice'                                            render={(props) => <PartsOrderInvoice                   {...props} />} />
                <Route exact path='/parts-order/view/:id'                                           render={(props) => <ViewPartsOrder                      {...props} />} />
                <Route exact path='/parts-order/other-items/update/:id'                             render={(props) => <UpdatePartsOrderOtherItems          {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts-order/delivery/change-schedule/:id'                       render={(props) => <UpdatePartsOrderDeliverySchedule    {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/parts-order/tracker'                                            render={(props) => <PartsOrderingTracker                {...props} />} />
                <Route exact path='/parts-order/tracker/:tid'                                       render={(props) => <PartsOrderingTracker                {...props} />} />
                <Route exact path='/parts-order/3dtracker'                                          render={(props) => <ThreeDPrinterTracker                {...props} />} />
                <Route exact path='/parts-order/3dtracker/:tid'                                     render={(props) => <ThreeDPrinterTracker                {...props} />} />

                {/* Purchase Orders */}
                <Route exact path='/purchase-order/default-address/update/:id'                      render={(props) => <POUpdateDefaultAddress              {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/purchase-order/default-addresses'                               render={(props) => <PODefaultAddresses                  {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/purchase-order/default-list/update/:id'                         render={(props) => <UpdateDefaultPurchaseOrderList      {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/purchase-order/default-list'                                    render={(props) => <DefaultPurchaseOrderList            {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/purchase-order/email-template/update/:id'                       render={(props) => <UpdatePurchaseOrderEmailTemplate    {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/purchase-order/email-template'                                  render={(props) => <PurchaseOrderEmailTemplates         {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/purchase-order/add'                                             render={(props) => <AddPurchaseOrder                    {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/purchase-order/search'                                          render={(props) => <PurchaseOrderSearch                 {...props} />} />
                <Route exact path='/purchase-order/approval'                                        render={(props) => <PurchaseOrderApproval               {...props} />} />
                <Route exact path='/purchase-order/declined'                                        render={(props) => <PurchaseOrderDeclined               {...props} />} />
                <Route exact path='/purchase-order/cancelled'                                       render={(props) => <PurchaseOrderCancelled              {...props} />} />
                <Route exact path='/purchase-order/completed'                                       render={(props) => <PurchaseOrderCompleted              {...props} />} />
                <Route exact path='/purchase-order/confirmation'                                    render={(props) => <PurchaseOrderConfirmation           {...props} />} />
                <Route exact path='/purchase-order/delivery'                                        render={(props) => <PurchaseOrderDelivery               {...props} />} />
                <Route exact path='/purchase-order/invoice'                                         render={(props) => <PurchaseOrderInvoice                {...props} />} />
                <Route exact path='/purchase-order/view/:id'                                        render={(props) => <ViewPurchaseOrder                   {...props} />} />
                <Route exact path='/purchase-order/update/:id'                                      render={(props) => <UpdatePurchaseOrder                 {...props} scrollToTop={scrollToTop} />} />

                {/* E-Commerce */}
                <Route exact path='/ecommerce/product-categories'                                   render={(props) => <EcommerceProductCategories          {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/ecommerce/products/add'                                         render={(props) => <AddEcommerceProduct                 {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/ecommerce/products/search'                                      render={(props) => <EcommerceProductSearch              {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/ecommerce/products/view/:id'                                    render={(props) => <ViewEcommerceProduct                {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/ecommerce/product-categories/update/:id'                        render={(props) => <UpdateEcommerceProductCategory      {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/ecommerce/products/update/:id'                                  render={(props) => <UpdateEcommerceProduct              {...props} scrollToTop={scrollToTop} />} />

                {/* Email */}
                <Route exact path='/email/email-templates'                                          render={(props) => <EmailTemplates                      {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/email/email-template/update/:id'                                render={(props) => <UpdateEmailTemplate                 {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/email/send-files'                                               render={(props) => <SendFiles                           {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/email/search'                                                   render={(props) => <EmailSearch                         {...props} />} />
                <Route exact path='/email/view/:id'                                                 render={(props) => <ViewEmail                           {...props} />} />
                <Route exact path='/email/email-folders'                                            render={(props) => <ViewEmailFolders                    {...props} />} />

                {/* Engineers */}
                <Route exact path='/engineers/add'                                                  render={(props) => <EngineerForm                        {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/engineers/view/:engineerId'                                     render={(props) => <ViewEngineer                        {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/engineers/update/:engineerId'                                   render={(props) => <EngineerForm                        {...props} />} />
                <Route exact path='/engineers/search'                                               render={(props) => <SearchEngineers                     {...props} />} />
                <Route exact path='/engineers/maps'                                                 render={(props) => <MapAndMarkers                       {...props} />} />
                <Route exact path='/engineers/jobs/view/:jobId'                                     render={(props) => <ViewJob                             {...props} />} />
                <Route exact path='/engineers/jobs/search'                                          render={(props) => <JobsSearch                          {...props} />} />
                <Route exact path='/engineers/email-templates'                                      render={(props) => <EngineerEmailTemplates              {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/engineers/email-templates/update/:id'                           render={(props) => <UpdateEngineerEmailTemplates        {...props} scrollToTop={scrollToTop} />} />

                {/* Equipment */}
                <Route exact path='/equipment/search'                                               render={(props) => <EquipmentSearch                     {...props} />} />
                <Route exact path='/equipment/view/:equipmentId'                                    render={(props) => <ViewEquipment                       {...props} />} />
                <Route exact path='/equipment/add'                                                  render={(props) => <EquipmentForm                       {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/equipment/reasons'                                              render={(props) => <EquipmentReasons                    {...props} scrollToTop={scrollToTop} />} />

                {/* Forms */}
                <Route exact path='/forms/search'                                                   render={(props) => <FormSearch                          {...props} />} />
                <Route exact path='/forms/view/:formId'                                             render={(props) => <FormView                            {...props} />} />
                <Route exact path='/forms/related/:formId'                                          render={(props) => <RelatedForms                        {...props} />} />
                <Route exact path='/forms/categories'                                               render={(props) => <FormCategories                      {...props} />} />
                <Route exact path='/forms/master-elements'                                          render={(props) => <MasterElements                      {...props} />} />

                {/* Vehicles */}
                <Route exact path='/vehicles/add'                                                   render={(props) => <VehicleForm                         {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/vehicles/view/:vehicleId'                                       render={(props) => <ViewVehicle                         {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/vehicles/update/:vehicleId'                                     render={(props) => <VehicleForm                         {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/vehicles/search'                                                render={(props) => <SearchVehicles                      {...props} />} />
                <Route exact path='/vehicles/insurance/search'                                      render={(props) => <SearchVehicleInsurance              {...props} />} />
                <Route exact path='/vehicles/devices/search'                                        render={(props) => <SearchVehicleDevices                {...props} />} />
                <Route exact path='/vehicles/breakdown/search'                                      render={(props) => <SearchVehicleBreakdown              {...props} />} />
                

                {/* Engineer Uploads */}
                <Route exact path='/engineers/uploads/add'                                          render={(props) => <AddUploads                          {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/engineers/uploads/search'                                       render={(props) => <UploadsSearch                       {...props} scrollToTop={scrollToTop} />} />
                
                {/* Customers */}
                <Route exact path='/customers/addresses/add'                                        render={(props) => <AddCustomerAddress                  {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/customers/addresses/add/:cid'                                   render={(props) => <AddCustomerAddress                  {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/customers/addresses/view/:id'                                   render={(props) => <ViewCustomerAddress                 {...props} />} />
                <Route exact path='/customers/addresses/update/:id'                                 render={(props) => <UpdateCustomerAddress               {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/customers/contacts/update/:id'                                  render={(props) => <UpdateCustomerContact               {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/customers/contacts/view/:id'                                    render={(props) => <ViewCustomerContact                 {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/customers/contacts/add'                                         render={(props) => <AddCustomerContact                  {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/customers/contacts/add/:custid'                                 render={(props) => <AddCustomerContact                  {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/customers/contacts/add/:custid/:number'                         render={(props) => <AddCustomerContact                  {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/customers/add'                                                  render={(props) => <AddCustomer                         {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/customers/search'                                               render={(props) => <CustomerSearch                      {...props} />} />
                <Route exact path='/customers/view/:id'                                             render={(props) => <ViewCustomer                        {...props} />} />
                <Route exact path='/customers/view/:id/:tab'                                        render={(props) => <ViewCustomer                        {...props} />} />
                <Route exact path='/customers/update/:id'                                           render={(props) => <UpdateCustomer                      {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/customers/quotation/search/:id'                                 render={(props) => <CustomerQuotationSearch             {...props} />} />
                <Route exact path='/customers/order/search/:id'                                     render={(props) => <CustomerOrderSearch                 {...props} />} />
                <Route exact path='/customers/access'                                               render={(props) => <CustomerAccess                      {...props} />} />
                <Route exact path='/customers/map'                                                  render={(props) => <CustomerMaps                        {...props} />} />
            {/* <Route exact path='/customers/emails'                                               render={(props) => <CustomerEmails                      {...props} />} /> */}

                {/* Customer Returns */}
                <Route exact path='/returns/creditnote/add'                                         render={(props) => <AddCreditNote                       {...props} />} />
                <Route exact path='/returns/creditnote/add/customer/:custId'                        render={(props) => <AddCreditNote                       {...props} />} />
                <Route exact path='/returns/creditnote/add/customer/site/:siteId'                   render={(props) => <AddCreditNote                       {...props} />} />
                <Route exact path='/returns/creditnote/add/fromReturn/:crId'                        render={(props) => <AddCreditNote                       {...props} />} />
                <Route exact path='/returns/creditnote/add/fromOutcome/:outcomeId'                  render={(props) => <AddCreditNote                       {...props} />} />
                <Route exact path='/returns/creditnote/search'                                      render={(props) => <SearchCreditNote                    {...props} status={'All'} />} />
                <Route exact path='/returns/creditnote/approval'                                    render={(props) => <SearchCreditNote                    {...props} status={'Approval'} />} />
                <Route exact path='/returns/creditnote/confirm'                                     render={(props) => <SearchCreditNote                    {...props} status={'Confirmed'} />} />
                <Route exact path='/returns/creditnote/decline'                                     render={(props) => <SearchCreditNote                    {...props} status={'Declined'} />} />
                <Route exact path='/returns/creditnote/cancel'                                      render={(props) => <SearchCreditNote                    {...props} status={'Cancelled'} />} />
                <Route exact path='/returns/creditnote/completed'                                   render={(props) => <SearchCreditNote                    {...props} status={'Completed'} />} />
                <Route exact path='/returns/creditnote/customer/:customerId/search'                 render={(props) => <SearchCreditNote                    {...props} status={'All'} />} />
                <Route exact path='/returns/creditnote/:id'                                         render={(props) => <ViewCreditNote                      {...props} />} />
                <Route exact path='/returns/creditnote/edit/:cnId'                                  render={(props) => <AddCreditNote                       {...props} />} />
                <Route exact path='/returns/email-template'                                         render={(props) => <CustomerReturnsEmailTemplates       {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/returns/email-template/update/:id'                              render={(props) => <UpdateCustomerReturnsEmailTemplates {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/returns/legal-documents'                                        render={(props) => <CustomerReturnsLegalDocuments       {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/returns/creditnote/invoice/:id'                                 render={(props) => <CreditNoteInvoiceApproval           {...props} />} />
                <Route exact path='/returns/creditnote/invoiced/:id'                                render={(props) => <CreditNoteInvoicePayments           {...props} />} />       
                <Route exact path='/returns/greenaware'                                             render={(props) => <Greenaware                          {...props} />} />       
                <Route exact path='/returns/add/:orderId'                                           render={(props) => <AddCustomerReturn                   {...props} />} />
                <Route exact path='/returns/edit/:returnId'                                         render={(props) => <AddCustomerReturn                   {...props} />} />
                <Route exact path='/returns/customerReturn/approval'                                render={(props) => <ViewCustomerReturns                 {...props} stage='Awaiting Approval'/>} />
                <Route exact path='/returns/customerReturn/confirm'                                 render={(props) => <ViewCustomerReturns                 {...props} stage='Awaiting Confirmation'/>} />
                <Route exact path='/returns/customerReturn/collection'                              render={(props) => <ViewCustomerReturns                 {...props} stage='Awaiting Collection'/>} />
                <Route exact path='/returns/customerReturn/review'                                  render={(props) => <ViewCustomerReturns                 {...props} stage='Awaiting Review'/>} />
                <Route exact path='/returns/customerReturn/outcomes'                                render={(props) => <ViewCustomerReturns                 {...props} stage='Awaiting Outcome'/>} />
                <Route exact path='/returns/customerReturn/inspection'                              render={(props) => <ViewCustomerReturns                 {...props} stage='Awaiting Inspection'/>} />
                <Route exact path='/returns/customerReturn/completed'                               render={(props) => <ViewCustomerReturns                 {...props} stage='Completed'/>} />
                <Route exact path='/returns/customerReturn/cancelled'                               render={(props) => <ViewCustomerReturns                 {...props} stage='Cancelled'/>} />
                <Route exact path='/returns/customerReturn/declined'                                render={(props) => <ViewCustomerReturns                 {...props} stage='Declined'/>} />
                <Route exact path='/returns/customerReturn/repair'                                  render={(props) => <ViewCustomerReturnRepairs           {...props} /> }/>
                <Route exact path='/returns/customerReturn/repair/:rogId'                           render={(props) => <ViewCustomerReturnRepair            {...props} /> }/>
                <Route exact path='/returns/customerReturn/search'                                  render={(props) => <ViewCustomerReturns                 {...props} /> }/>
                <Route exact path='/returns/customerReturn/default'                                 render={(props) => <CustomerReturnDefaults              {...props} /> }/>
                <Route exact path='/returns/customerReturn/pick/:id'                                render={(props) => <PickCustomerReturn                  {...props} /> }/>
                <Route exact path='/returns/customerReturn/inspection/:id'                          render={(props) => <ViewCustomerReturnInspection        {...props} /> }/>
                <Route exact path='/returns/customerReturn/:id'                                     render={(props) => <ViewCustomerReturn                  {...props} /> }/>

                {/* Sales */}
                <Route exact path='/sales/quotation/add'                                            render={(props) => <AddQuotation                        {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/quotation/add/:custid'                                    render={(props) => <AddQuotation                        {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/quotation/add/customer/site/:siteid'                      render={(props) => <AddQuotation                        {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/quotation/add/repeat/:repeatid'                           render={(props) => <AddQuotation                        {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/quotation/add/repair/:rogId'                              render={(props) => <AddQuotation                        {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/quotation/view/:id'                                       render={(props) => <ViewQuotation                       {...props} />} />
                <Route exact path='/sales/quotation/search'                                         render={(props) => <QuotationSearch                     {...props} />} />
                <Route exact path='/sales/quotation/amend/:id'                                      render={(props) => <AmendQuotation                      {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/order/add/:quoteId'                                       render={(props) => <AddOrder                            {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/order/add/'                                               render={(props) => <AddOrder                            {...props} scrollToTop={scrollToTop} />} />  
                <Route exact path='/sales/order/add/fromReturn/:crId'                               render={(props) => <AddOrder                            {...props} scrollToTop={scrollToTop} />} />  
                <Route exact path='/sales/order/add/fromOutcome/:outcomeId'                         render={(props) => <AddOrder                            {...props} scrollToTop={scrollToTop} />} />  
                <Route exact path='/sales/order/add/customer/:custid'                               render={(props) => <AddOrder                            {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/order/add/customer/site/:siteid'                          render={(props) => <AddOrder                            {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/order/add/repeat/:orderId'                                render={(props) => <AddOrder                            {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/order/view/:id'                                           render={(props) => <ViewOrder                           {...props} />} />
                <Route exact path='/sales/order/add/creditnote/:orderId'                            render={(props) => <AddCreditNote                       {...props} />} />
                <Route exact path='/sales/order/search'                                             render={(props) => <OrderSearch                         {...props} />} />
                <Route exact path='/sales/order/amend/:id'                                          render={(props) => <AmendOrder                          {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/order/amend/fromOutcome/:outcomeId'                       render={(props) => <AmendOrder                          {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/order/approval'                                           render={(props) => <OrderApproval                       {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/order/confirmed'                                          render={(props) => <OrderConfirmed                      {...props} />} />
                <Route exact path='/sales/order/payment'                                            render={(props) => <OrderPayment                        {...props} />} />
                <Route exact path='/sales/order/picking/:orderId'                                   render={(props) => <PickOrder                           {...props} />} />
                <Route exact path='/sales/order/picking'                                            render={(props) => <OrderPicking                        {...props} />} />
                <Route exact path='/sales/order/dispatch'                                           render={(props) => <OrderDespatch                       {...props} />} />
                <Route exact path='/sales/order/dispatch/view/:despId'                              render={(props) => <ViewDispatch                        {...props} />} />
                <Route exact path='/sales/order/invoice'                                            render={(props) => <OrderInvoice                        {...props} />} />
                <Route exact path='/sales/order/invoice/approval/:invoiceId'                        render={(props) => <InvoiceApproval                     {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/order/invoice/Payments/:orderId'                          render={(props) => <InvoicePayments                     {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/order/completed'                                          render={(props) => <OrderCompleted                      {...props} />} />
                <Route exact path='/sales/order/declined'                                           render={(props) => <OrderDeclined                       {...props} />} />
                <Route exact path='/sales/order/cancelled'                                          render={(props) => <OrderCancelled                      {...props} />} />
                <Route exact path='/sales/legal-documents'                                          render={(props) => <SalesLegalDocuments                 {...props} />} />
                <Route exact path='/sales/email-template/update/:id'                                render={(props) => <UpdateSalesEmailTemplate            {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/email-template'                                           render={(props) => <SalesEmailTemplate                  {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/order/commercial-invoice/add/:despatchId'                 render={(props) => <AddCommercialInvoice                {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/order/commercial-invoice/amend/:commercialId'             render={(props) => <AmendCommercialInvoice              {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/sales/order/invoice/recurring/:recurringInvoiceId'              render={(props) => <RecurringInvoice                    {...props} /> } />
                <Route exact path='/sales/order/invoice/recurring/amend/:recurringInvoiceId'        render={(props) => <RecurringInvoice                    {...props} edit={true} /> } />
                <Route exact path='/sales/package'                                                  render={(props) => <AllPackages                         {...props} /> } />
                <Route exact path='/sales/package/add'                                              render={(props) => <AddPackage                          {...props} /> } />
                <Route exact path='/sales/package/edit/:packageId'                                  render={(props) => <AddPackage                          {...props} /> } />
                <Route exact path='/sales/package/:packageId'                                       render={(props) => <ViewPackage                          {...props} /> } />

                {/* Couriers */}
                <Route exact path='/courier/add'                                                    render={(props) => <AddCourier                          {...props} /> } />
                <Route exact path='/courier/search'                                                 render={(props) => <CourierSearch                       {...props} /> } />
                <Route exact path='/courier/view/:id'                                               render={(props) => <ViewCourier                         {...props} /> } />
                <Route exact path='/courier/update/:id'                                             render={(props) => <UpdateCourier                       {...props} /> } />

                {/* Meetings */}
                <Route exact path='/meetings/new'                                                   render={(props) => <NewMeeting                          {...props} scrollToTop={scrollToTop} /> } />
                <Route exact path='/meetings/search'                                                render={(props) => <MeetingSearch                       {...props} scrollToTop={scrollToTop} /> } />

                {/* Marketing */}
                <Route exact path='/marketing/shortlinks/add'                                       render={(props) => <AddShortlink                        {...props} scrollToTop={scrollToTop} /> } />
                <Route exact path='/marketing/shortlinks/search'                                    render={(props) => <ShortlinkSearch                     {...props} /> } />
                <Route exact path='/marketing/campaigns'                                            render={(props) => <EmailCampaigns                      {...props} /> } />
                <Route exact path='/marketing/campaigns/emailCampaigns/:id'                         render={(props) => <ViewEmailCampaign                   {...props} /> } />
                <Route exact path='/marketing/campaigns/:tab'                                       render={(props) => <EmailCampaigns                      {...props} /> } />
                <Route exact path='/marketing/customerMarketing'                                    render={(props) => <CustomerMarketing                   {...props} /> } />
                <Route exact path='/marketing/contactsexcel'                                        render={(props) => <ContactsExcel                       {...props} /> } />
                <Route exact path='/marketing/emailValidation'                                      render={(props) => <EmailValidation                     {...props} /> } />
                <Route exact path='/marketing/mailingLists'                                         render={(props) => <MailingLists                        {...props} /> } />
                <Route exact path='/marketing/mailingLists/:id'                                     render={(props) => <ViewMailingList                     {...props} /> } />
                <Route exact path='/marketing/manualContacts'                                       render={(props) => <ManualContacts                      {...props} /> } />
                
                {/* Phone */}
                <Route exact path="/phone/call-history"                                             render={(props) => <CallHistory                         {...props} />} />
                <Route exact path="/phone/global-phone-book"                                        render={(props) => <GlobalPhoneBook                     {...props} />} />
                  
                {/* Tasks & Reminders */}
                <Route exact path='/tasks/add'                                                      render={(props) => <AddTask                             {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/tasks/:id'                                                      render={(props) => <Tasks                               {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/tasks'                                                          render={(props) => <Tasks                               {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/reminders/add'                                                  render={(props) => <AddReminder                         {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/reminders/:id'                                                  render={(props) => <Reminders                           {...props} scrollToTop={scrollToTop} />} />
                <Route exact path='/reminders'                                                      render={(props) => <Reminders                           {...props} scrollToTop={scrollToTop} />} />
                
                {/* Toolbox */}
                <Route exact path='/toolbox/quickQr'                                                render={(props) => <QuickPartQrScan                     {...props} />} />
                <Route exact path='/toolbox/findStock'                                              render={(props) => <FindStock                           {...props} />} />
                <Route exact path='/toolbox/reordernav'                                             render={(props) => <NavReorder                          {...props} />} />
                <Route exact path='/toolbox/printing'                                               render={(props) => <Printing                            {...props} />} />
                <Route exact path='/toolbox/processes'                                              render={(props) => <Processes                           {...props} />} />

                {/* Performance */}
                <Route exact path='/performance/targets'                                            render={(props) => <MonthlyTargets                      {...props} />} /> 

                {/* Survey */}
                <Route exact path='/survey'                                                         render={(props) => <SurveySearch                        {...props} />} />
                <Route exact path='/survey/:id'                                                     render={(props) => <SurveyView                          {...props} />} />

                {/* Manual page titles where access is true by default and no access record exist in the database*/}
                <Route exact path='/my-account'                                                     render={(props) => <MyAccount                           {...props} changePage={changePage} scrollToTop={scrollToTop} />} />

                {/* 404 - Redirect to Dashboard with 404 Error Dialog */}
                <Route component={Error404} />
            </Switch>
        );
    }
  
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError: (error, message) => dispatch(handleAppError(error, message))
    };
}

export default withRouter(connect(null, mapDispatchToProps)(AdminRouteSwitcher));