import React from 'react';
import Typography from '@material-ui/core/Typography';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import API from '../../../API';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import DataTable from '../../Common/DataTables/DataTable';

const initialState = {
        formData: {
            supplierType: ''
        },
        rows: [],
        formErrors:[],
        snackbarOpen: false,
        confirmationOpen: false,
        isLoading: true
    }

class AddSupplierTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getSupplierTypeList();
    }

    submit = () => {
        API.post('/suppliers/types', this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.getSupplierTypeList();
            }
            this.props.scrollToTop();
        });
    }
    
    getSupplierTypeList = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/suppliers/types/all')
            .then((result) => {
                this.setState({
                    ...this.state,
                    rows: result.data,
                    isLoading: false
                });
            })
        })
    }
    
    handleChange = (e) => {
        this.setState({
            formData: {
            ...this.state.formData,
            [e.target.name]: e.target.value
            }
        });
    }
    
    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    handleConfirmationOpen = () => {
        this.setState({ confirmationOpen: true });
    };

    handleConfirmationClose = () => {
        this.setState({ confirmationOpen: false });
    };

    handleConfirmationSuccess = () => {
        this.setState({ confirmationOpen: false });
        this.submit();
    }

    render() {
        const { formErrors } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Supplier Type
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <TextField
                                    id="supplierType"
                                    name="supplierType"
                                    label="Supplier Type *"
                                    value={this.state.formData.supplierType}
                                    error={formErrors && formErrors['supplierType'] && true}
                                    helperText={formErrors && formErrors['supplierType']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <div className='buttonRow'>
                                    <Button 
                                        onClick={this.handleConfirmationOpen}
                                        variant="contained" 
                                        color="primary" 
                                       
                                        disabled={!this.state.formData.supplierType}
                                    >
                                        Add
                                    </Button>
                                </div>
                                <ConfirmationDialog 
                                    open={this.state.confirmationOpen} 
                                    success={this.handleConfirmationSuccess} 
                                    close={this.handleConfirmationClose} 
                                    title="Add A New Supplier Type?" 
                                    message="Are you sure you want to add a new supplier type?"
                                />
                                <SnackBar
                                    variant="success"
                                    anchorOriginVertical='bottom'
                                    anchorOriginHorizontal='right'
                                    open={this.state.snackbarOpen}
                                    onClose={this.handleSnackbarClose}
                                    message='You have successfully added a new supplier type'
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key: 'supp_types_id',
                                pagination: true,
                                isLoading: this.state.isLoading,
                                alternatingRowColours: true
                            }}
                            columns={[
                                {
                                    heading: 'Name',
                                    field: rowData => rowData.supp_types_name,
                                    truncate: true
                                }
                            ]}
                            rows={this.state.rows}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}
export default AddSupplierTypes;