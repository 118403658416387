import React, { Component }      from 'react';
import API                       from 'API';
import _                         from 'lodash';
import { Document, Page, pdfjs } from 'react-pdf';
/*import Printer, { print }        from 'react-pdf-print'*/

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AllIcon       from 'Components/Common/Icons/AllIcon';

import { Grid, Typography } from '@material-ui/core/';
import { withStyles }       from '@material-ui/core/styles';

import { colors }        from 'Helpers/ColourHelper';
import icons             from 'Helpers/IconHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

import { downloadS3File }       from 'Functions/MiscFunctions';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const widthMod = 0.73;

const initialState = {
    numPages: '',
    pageNumber: 1,
    scale: 1,
    pdf: null,
    raw: null,
    width: 0,
    fullScreen: false,
    swipe: {
        xDown: null,
        yDown: null,
    }
}

class PDFViewer extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
        this.state.fullScreen = this.props.defaultFullScreen;
        this.state.width = ( (window.innerHeight * widthMod) * initialState.scale );
        window.addEventListener("keydown",    this.handleKeyPress, false);
        window.addEventListener('resize',     this.handleResize)
        window.addEventListener('touchstart', this.handleTouchStart, false);        
        window.addEventListener('touchmove',  this.handleTouchMove, false);
    }

    componentWillUnmount(){
        window.removeEventListener("keydown",    this.handleKeyPress, false);
        window.removeEventListener('resize',     this.handleResize)
        window.removeEventListener('touchstart', this.handleTouchStart, false);        
        window.removeEventListener('touchmove',  this.handleTouchMove, false);
        //window.addEventListener("scroll", this.handleScroll);
    }

/*    handleScroll = (e) => {
        const window = e.currentTarget;
        let y=0;
        if (y > window.scrollY) {
            !(this.state.pageNumber <= 1) &&
                this.handlePdfPagination(-1);
        } else if (y < window.scrollY) {
            !(this.state.pageNumber >= this.state.numPages) &&
                this.handlePdfPagination(1);
        }
    }
*/

    getTouches(evt) {
        return evt.touches || evt.originalEvent.touches; 
    }       

    handleTouchStart = (evt) => {
        const firstTouch = this.getTouches(evt)[0];      
        this.setState({ 
            swipe: { 
                xDown: firstTouch.clientX, 
                yDown: firstTouch.clientY
            } 
        });
    }

    handleTouchMove = (evt) => {
        const { xDown, yDown } = this.state.swipe;
        if ( !xDown || !yDown ) return;

        const touch = this.getTouches(evt)[0];  
        var xUp = touch.clientX;                                    
        var yUp = touch.clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        if ( Math.abs( xDiff ) > Math.abs( yDiff ) &&  Math.abs(Math.abs( xDiff ) - Math.abs( yDiff ) ) > 10 ) {
            if ( xDiff > 0 ) {
                this.handlePdfPagination(1);
            } else {
                this.handlePdfPagination(-1);
            }                       
        } 

        this.setState({ swipe: initialState.swipe });
    }

    handleKeyPress = (e) => {
        switch(e.key){
            case 'ArrowUp':
            case 'ArrowRight':
            case 'PageUp':
                !(this.state.pageNumber >= this.state.numPages) &&
                    this.handlePdfPagination(1);
                break;
            case 'ArrowDown':
            case 'ArrowLeft':
            case 'PageDown':
                !(this.state.pageNumber <= 1) &&
                    this.handlePdfPagination(-1);
                break;
        }
    }

    handleResize = () => {
        const newWidth = (window.innerHeight * widthMod) * this.state.scale;
        if (this.state.width !== newWidth) 
            this.setState({width: newWidth});
    }

    componentDidMount(){
        this.checkProps();
    }

    checkProps = () => {
        if (this.props.pageNumber) this.setState({pageNumber: this.props.pageNumber});
        if (this.props.pdfData) {
            this.setState({
                pdf: `data:application/pdf;base64,${this.props.pdfData}`,
                raw: this.props.pdfData
            });
        } else {
            this.getPDF()
        }
    }

    getPDF = () => {
        const url = this.props.pdfUrl || this.props.src;
        API.get('/getS3PresignedFileUrl/?for=display&request='+btoa(url))
        .then(result => {
            this.setState({
                pdf: new Buffer(result.data.download_url,'base64').toString('ascii'),
                raw: result.data.download_url
            });
        })
    }

    handleError = err => {
        console.error(err)
    }

    handlePdfLoad = (props) => { 
        this.setState({numPages: props.numPages}) 
    }

    handlePdfPagination = offset => { 
        let pageNumber = ((this.state?.pageNumber ?? 1) + offset);
        if (pageNumber > this.state.numPages) pageNumber = 1;
        else if (pageNumber < 1) pageNumber = this.state.numPages;
        this.setState({ pageNumber });
    }

    handlePdfScale = zoom => {
        let scale = this.state?.scale ?? 1;
        if(_.isNumber(zoom)) {
            scale = zoom;
        } else {
            if(zoom) {
                scale = (parseFloat(scale) + parseFloat(0.25)).toFixed(2)
            } else {
                scale = (parseFloat(scale) - parseFloat(0.25)).toFixed(2)
            }
        }
        this.setState({scale})
    }

    handleClose = () => {
        if (this.props.onClose) this.props.onClose();
    }

    handleDownload = () => {
        if (this.props.pdfUrl) {
            downloadS3File(this.props.pdfUrl);
        } else {
            pdfFromBase64(this.state.raw, this.props.downloadTitle || 'pdf_download.pdf');
        }
    }

    handlePrint = () => {
       //tbd
    };

    handleZoomIn = () => {
        this.setState({
            scale: this.state.scale < 2 ? this.state.scale + 0.25 : this.state.scale
        })
    }

    handleZoomOut = () => {
        this.setState({
            scale: this.state.scale > 0.25 ? this.state.scale - 0.25 : this.state.scale
        })
    }

    handleRefresh = async () => {
        if (this.props.pdfUrl) {
            this.getPDF()
        } else {
            let pdfData = await this.props.refresh();
            this.setState({
                pdf: `data:application/pdf;base64,${pdfData}`,
                raw: pdfData
            });
        }
    }

    handleFullScreen = () => {
        this.setState({fullScreen: !this.state.fullScreen})
    }

    render() {
        const pdf         = this.state;
        const { classes } = this.props;

        let style = this.props.style;
        if (pdf.fullScreen) 
            style = {
                ...style,
                maxHeight: '100vh',
            };
        

        if (_.isNull(pdf.pdf)) return (<LoadingCircle/>);
        return (
            <Grid container justify="center" className={pdf.fullScreen ? classes.fullScreenContainer : classes.container} style={{border: `1px solid ${colors.grey}`, ...style }}>
                <Grid item xs={12} className={classes.header}>
                    <Grid container spacing={1} alignItems='center' justify='space-between' style={{backgroundColor: colors.blue}}>
                        <Grid item>
                            <Grid container style={{alignItems: 'center'}}>
                                {!this.props.disableZoom &&
                                    <>
                                        <Grid item>
                                            <AllIcon
                                                noMargin
                                                icon={icons.zoomOut}
                                                tooltip="Zoom out"
                                                color={colors.white}
                                                onClick={this.handleZoomOut}
                                            />
                                        </Grid>
                                        <Grid item style={{color: colors.white}}>{pdf.scale * 100}%</Grid>
                                        <Grid item>
                                            <AllIcon
                                                noMargin
                                                icon={icons.zoomIn}
                                                tooltip="Zoom In"
                                                color={colors.white}
                                                onClick={this.handleZoomIn}
                                            />
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container style={{alignItems: 'center'}}>
                                <Grid item style={{marginLeft: 'auto'}}>
                                    <AllIcon
                                        onClick={() => this.handlePdfPagination(-1)}
                                        disabled={!pdf.numPages || pdf.pageNumber <= 1}
                                        icon="chevron-left"
                                        size="small"
                                        color={(!pdf.numPages || pdf.pageNumber <= 1) ? colors.blue : colors.white}
                                        noMargin
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" style={{color:colors.white}}>
                                        {`Page ${pdf.pageNumber} of ${pdf.numPages ?? 1}`}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <AllIcon
                                        onClick={() => this.handlePdfPagination(1)}
                                        disabled={!pdf.numPages || pdf.pageNumber >= pdf.numPages}
                                        icon="chevron-right"
                                        size="small"
                                        noMargin
                                        color={(!pdf.numPages || pdf.pageNumber >= pdf.numPages) ? colors.blue : colors.white}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                {!this.props.disableFullScreen &&
                                    <Grid item>
                                        <AllIcon
                                            icon={pdf.fullScreen ? icons.exitFullScreen : icons.fullScreen}
                                            tooltip={pdf.fullScreen ? 'Minimise' : 'Maximise'}
                                            color={colors.white}
                                            onClick={this.handleFullScreen}
                                            noMargin
                                        />
                                    </Grid>
                                }
                                {/*!this.props.disableDownload &&
                                    <Grid item>
                                        <AllIcon
                                            icon={icons.download}
                                            tooltip="Download"
                                            color={colors.white}
                                            onClick={this.handleDownload}
                                            noMargin
                                        />
                                    </Grid>
                                */}
                                {this.props.refresh &&
                                    <Grid item>
                                        <AllIcon
                                            icon={icons.refresh}
                                            tooltip="Refresh"
                                            color={colors.white}
                                            onClick={this.handleRefresh}
                                            noMargin
                                        />
                                    </Grid>
                                }
                                {/*this.props.allowPrint &&
                                    <Grid item>
                                        <AllIcon
                                            icon={icons.print}
                                            tooltip="Print"
                                            color={colors.white}
                                            onClick={this.handlePrint}
                                        />
                                    </Grid>
                            */}
                                {this.props.onClose &&
                                    <Grid item>
                                        <AllIcon
                                            icon={icons.false}
                                            tooltip="Close"
                                            color={colors.white}
                                            onClick={this.handleClose}
                                            noMargin
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container justify="center" className={pdf.fullScreen ? classes.fullScreenBodyContainer : classes.bodyContainer}>
                    {pdf.fullScreen && !(!pdf.numPages || pdf.pageNumber <= 1) &&
                        <div className={classes.floatingArrow} style={{left: 75}}>
                            <AllIcon
                                noMargin
                                icon={icons.previous}
                                size={125}
                                onClick={() => this.handlePdfPagination(-1)}
                            />
                        </div>
                    }
                    <Grid item className={classes.body} style={{width: pdf.width * pdf.scale}}>
                        <Document
                            file={pdf.pdf}
                            onLoadSuccess={this.handlePdfLoad}
                            onError={this.handleError}
                            loading={(<LoadingCircle/>)}
                        >
                            <Page 
                                pageNumber={pdf.pageNumber} 
                                loading={(<LoadingCircle />)}
                                scale={1.5}
                            >
                            </Page>
                        </Document>
                    </Grid>
                    {pdf.fullScreen && !(!pdf.numPages || pdf.pageNumber >= pdf.numPages) &&
                        <div className={classes.floatingArrow} style={{right: 75}}>
                            <AllIcon
                                noMargin
                                icon={icons.next}
                                size={125}
                                onClick={() => this.handlePdfPagination(1)}
                            />
                        </div>
                    }
                </Grid>
            </Grid>
        );
    }
}

const styles = theme => ({
    floatingArrow: {
        position: 'fixed',
        top: 'calc(50% - 62.5px)',
    },
    fullScreenContainer: {
        borderRadius:   0, 
        overflowY:     'hidden',
        overflowX:     'hidden',
        height:        '100vh',
        width:         '100%',
        zIndex:        99999,
        position:      'fixed',
        left:          0,
        top:           0,
        backgroundColor: colors.white,
    },
    container : {
        height:         700, 
        borderRadius:   5, 
        overflowY:     'hidden',
        overflowX:     'hidden',
    },
    header : {
        padding:       '0.2em',
        height:        47,
    },
    fullScreenBodyContainer: {
        width:         '100%',
        height:        'calc(100vh - 52px)',
        overflowY:     'scroll',
        overflowX:     'hidden',
        marginTop:      5,
        backgroundColor: colors.white,
    },
    bodyContainer : {
        width:         '100%',
        height:        'calc(700px - 52px)',
        overflowY:     'scroll',
        overflowX:     'hidden',
        marginTop:      5,
    },
    body : {
        marginRight:    5,
        padding:       '1.5em'
    }
});

export default withStyles(styles)(PDFViewer);