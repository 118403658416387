import React from 'react';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography, ListItem, ListItemText, List, makeStyles } from "@material-ui/core";
import { colors } from 'Helpers/ColourHelper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconHelper from 'Helpers/IconHelper';
import API from 'API';
import _ from 'lodash';

const LegendColor = ({name, color, negative=colors.white}) => {

    let useStyles = makeStyles(theme => ({
        main: {
            color: color,
            marginLeft: '0.7em',
            padding: '0.2em',
            backgroundColor: negative,
            "&:hover": {
                cursor: 'default',
                color:negative,
                backgroundColor: color,
            }
        }
    }));

    const classes = useStyles();

    return (
        <>
            {name}:<span className={classes.main}>{color}</span>
        </>
    )

}

export default function Legend ({additionalPlaceholders}) {

    const [ placeHolders, setPlaceHolders ] = React.useState([]);

    if (!placeHolders.length){
        API.get(`/marketing/emailTemplates/placeholders`).then(res => {
            setPlaceHolders([...res.data, ...(additionalPlaceholders ? additionalPlaceholders : [])])
        })
    }

    return (
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<AllIcon icon={IconHelper.arrowDown} noMargin white />}
            >
                Legend/Key
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1' gutterBottom>
                            <strong>Placeholders</strong>
                        </Typography>
                        <List>
                            {
                                _.map(placeHolders, (p) =>
                                    <ListItem>
                                        <ListItemText primary={p.etp_name} secondary={p.etp_desc} />
                                    </ListItem>
                                )
                            }
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1' gutterBottom>
                            <strong>Clenaware Colors</strong>
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary={<LegendColor name='Primary (Blue)' color={colors.primary} />} 
                                    secondary='Used for buttons, links and other primary elements' 
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<LegendColor name='Red' color={colors.red} />} 
                                    secondary='Used for Error messages and other secondary elements' 
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<LegendColor name='Orange' color={colors.orange} />} 
                                    secondary='Used for warning messages and pending actions' 
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<LegendColor name='Green' color={colors.green} />} 
                                    secondary='Used for success messages' 
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<LegendColor name='Black' color={colors.black}  />} 
                                    secondary='Used for text' 
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<LegendColor name='Disabled' color={colors.disabled}  />} 
                                    secondary='Used for disabled text and borders' 
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<LegendColor name='Background' color={colors.background} negative={colors.black} />} 
                                    secondary='Used for backgrounds' 
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )   
};