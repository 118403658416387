import React, {Component}  from 'react';
import API                 from 'API';

import { Grid, Button, DialogContent, Dialog, DialogTitle, Typography } from '@material-ui/core';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AllIcon       from 'Components/Common/Icons/AllIcon';

import icons      from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';

const initialState = {status:'Queued'}

class SinglePrintDialog extends Component {
    constructor(props){
        super(props);
        this.state = initialState;
        this.timeout = null;
    }

    componentDidMount() {
        this.getStatus();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState(initialState, this.getStatus);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    getStatus = () => {
        API.post(`/print/${this.props.id}/status`).then(res => {
            this.setState({status: res.data});
            if (res.data !== 'Completed' && res.data !== 'Error') { this.timeout = setTimeout(this.getStatus, 2000); }
            if (res.data === 'Completed') { this.timeout = setTimeout(this.props.handleClose, 1000); }
        });
    }

    render() {

        const { status } = this.state;
        const { id, handleClose } = this.props;

        return (
            <Dialog
                    open={id}
                    onClose={handleClose}
                    fullWidth={true} 
                    maxWidth="sm" 
                    scroll="body"
                >
                    <DialogTitle>
                        <Typography>Printing...</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container style={{justifyContent: 'center'}} spacing={2}>
                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                <Typography variant='h4'>{status === 'Sent' ? 'Received' : status}</Typography>
                            </Grid>
                            <Grid item xs={12}  style={{textAlign: 'center'}}>
                                {status === 'Completed' ? <AllIcon size='xxxlarge' icon={icons.true} color={colors.green} /> : (
                                    status === 'Error' ? <AllIcon size='xxxlarge' icon={icons.false} color={colors.red} /> : <LoadingCircle />
                                )}
                            </Grid>
                            <Grid item xs={12} className='buttonRow'>
                                <Button variant='outlined' onClick={handleClose}>Close [F3]</Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
            </Dialog>
        );
    }
}

export default SinglePrintDialog