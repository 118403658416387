import API from 'API';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Dialog, DialogTitle, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography } from '@material-ui/core';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog } from 'Actions/Dialog/Dialog';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import MediaTable from 'Components/Common/DataTables/MediaTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';
import _ from 'lodash';
import EditInspectionDialog from './EditInspectionDialog';

const initialState = (props) => ({
    cr: {},
    isLoading: true,
    formData: {
        outcomes:{},
    },
    editInspection: null
})

class ReviewReturnDialog extends Component {
    constructor(props) {
        super(props);   
        this.state = initialState(props); 
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        Promise.all([
            API.get(`/customerReturns/returns/${this.props.cr_id}`),
            API.get('/parts/all', { params: { use: 'partSearch', withStock: true, withImage: true } })
        ]).then(res => {
            let outcomes = {};
            _.each(_.filter(res[0].data.collection.details, i => parseFloat(i.crcd_recieved) > 0), (detail) => {
                _.each(detail.received, (r) => {
                    outcomes[r.crcdr_id] = res[0].data.cr_expected_outcome;
                })
            })
            this.setState({
                cr: res[0].data,
                formData: {
                    ...this.state.formData,
                    outcomes: outcomes,
                },
                lists: {
                    parts: res[1].data,
                },
                isLoading: false,
            })
        });
    }

    refreshData = () => {
        API.get(`/customerReturns/returns/${this.props.cr_id}`)
        .then(res => {
            this.setState({
                cr: res.data,
            })
        })
    }

    handleSelectChange = name => value => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value.value,
            }
        })
    }

    submitReview = () => {
        API.post(`/customerReturns/returns/${this.props.cr_id}/submitReview`).then(res => {
            this.props.closeDialog();
            this.props.onSubmit(this.state.formData.outcome);
        });
    }

    editInspection = (inspection) => () => {
        this.setState({
            editInspection: inspection
        });
    }

    closeEditInspection = () => {
        this.setState({
            editInspection: null
        }, this.refreshData);
    }

    onChangeOutcome = (crcdr_id, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                outcomes: {
                    ...this.state.formData.outcomes,
                    [crcdr_id]: value,
                }
            }
        })
    }

    render () {

        const { isLoading, cr, formData,editInspection } = this.state;

        if (isLoading) { return <LoadingCircle/> }

        return (
            <Grid container spacing={3} style={{alignItems: 'stretch'}}>
                <Grid item xs={12} >
                    <Box style={{padding: '1em', paddingBottom: 0}}>
                        <Box
                            style={{
                                border: '1px solid rgb(224, 224, 224)',
                                padding: '1.5em'
                            }}
                        >
                            <Grid container spacing={3} style={{alignItems: 'stretch'}}>
                                <Grid item xs={5}>
                                    <Grid container item spacing={1}>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Return Date</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                {moment(cr.cr_date).format("DD/MM/YYYY")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Created By</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                {cr.cr_staff_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Return For</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                {(cr.cr_outcome || cr.cr_expected_outcome) === "refund" || (cr.cr_outcome || cr.cr_expected_outcome) === "Refund" ? "Credit Note" : (cr.cr_outcome || cr.cr_expected_outcome)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={5}>
                                    <Grid container item spacing={1}>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Reference</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                {cr.cr_reference+'-'+(cr.cr_reference_amendment < 10 ? '0'+cr.cr_reference_amendment : cr.cr_reference_amendment)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Order Reference</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                {cr.order.order_reference+'-'+cr.order.order_reference_amendment}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={5}>
                                    <Grid container item spacing={1}>
                                        <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                                            Customer
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Name</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                {cr.cr_cust_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Address</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                {cr.cr_address.split('\n').map((item, key) => {
                                                    return  <span key={key}>
                                                                {item}{cr.cr_address.split('\n').length === key+1 ? null : <br/>}
                                                            </span>
                                                })}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Contact</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                {cr.cr_contact_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Phone Number</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                {cr.cr_contact_phone} {cr.cr_contact_phone && cr.cr_contact_mobile ? ' / ' : null} {cr.cr_contact_mobile}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Email</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                {cr.cr_contact_email	}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={5}>
                                    <Grid container item spacing={1}>
                                        <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                                            Collection Address
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Name</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                {cr.cr_collection_address_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Address</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                {cr.cr_collection_address.split('\n').map((item, key) => {
                                                    return  <span key={key}>
                                                                {item}{cr.cr_collection_address.split('\n').length === key+1 ? null : <br/>}
                                                            </span>
                                                })}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Contact</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                {cr.cr_collection_address_contact_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Phone Number</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                {cr.cr_collection_address_contact_phone} {cr.cr_collection_address_contact_phone && cr.cr_collection_address_contact_mobile ? ' / ' : null} {cr.cr_collection_address_contact_mobile}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Email</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                {cr.cr_collection_address_contact_email}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <CiDataTable
                                                config={{
                                                    key: 'crcd_id',
                                                    dropRow: {
                                                        droppable: rowData => parseFloat(rowData.crcd_recieved) > 0,
                                                        hideDropArrow: true,
                                                        columns: [
                                                            {
                                                                colSpan: 6,
                                                                field: inspection =>
                                                                    <Grid container spacing={1}>
                                                                        {
                                                                            (inspection.cri_sn || inspection.cri_wvl) &&
                                                                            <>
                                                                                <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                                                    {
                                                                                        ( inspection.cri_sn && 'Serial Number' ) || 'Warranty Void Label'
                                                                                    }:
                                                                                </Grid>
                                                                                <Grid item xs={8}>
                                                                                    {inspection.cri_sn || inspection.cri_wvl}
                                                                                </Grid>
                                                                            </>
                                                                        }
                                                                        <Grid item xs={(inspection.cri_sn || inspection.cri_wvl) ? 2 : 12} style={{ textAlign: 'right'}}> 
                                                                            <AllIcon
                                                                                icon={IconHelper.edit}
                                                                                noMargin
                                                                                tooltip='Edit Inspection'
                                                                                onClick={this.editInspection(inspection)}
                                                                                size='small'
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                                            Inspected On:
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            {clenyDate(inspection.cri_datetime)}
                                                                        </Grid>
                                                                        <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                                            Inspected By:
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            {inspection.cri_staff}
                                                                        </Grid>
                                                                        {inspection.cri_edited_staff &&
                                                                            <>

                                                                                <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                                                    Updated On:
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    {clenyDate(inspection.cri_edited_datetime)}
                                                                                </Grid>
                                                                                <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                                                    Updated By:
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    {inspection.cri_edited_staff}
                                                                                </Grid>
                                                                            </>
                                                                        }
                                                                        <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                                            Condition:
                                                                        </Grid>
                                                                        <Grid item xs={4} style={{
                                                                            color: inspection.cri_condition_grade === 'fail' ? colors.red : (inspection.cri_condition_grade === 'warning' ? colors.orange : colors.green)
                                                                        }}>
                                                                            {inspection.cri_condition}
                                                                        </Grid>
                                                                        <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                                            Outcome:
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            {inspection.cri_outcome === 'Refund' ? 'Credit Note' : inspection.cri_outcome}
                                                                        </Grid>
                                                                        {   inspection.cri_outcome === 'Repair' &&
                                                                            inspection.cri_parts &&
                                                                            <Grid item xs={12}>
                                                                                {   
                                                                                    _.filter(JSON.parse(inspection.cri_parts), p => _.find(this.state.lists.parts, {part_id: p.part}).stockable).length > 0 &&
                                                                                    <ExpansionPanel >
                                                                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                                            Recommended Parts
                                                                                        </ExpansionPanelSummary>
                                                                                        <ExpansionPanelDetails>
                                                                                            <CiDataTable
                                                                                                config={{
                                                                                                    key: 'part_id',
                                                                                                }}
                                                                                                columns={[
                                                                                                    {
                                                                                                        heading:       '',
                                                                                                        field:         'default_image.file_path',
                                                                                                        fieldFormat:   'image',
                                                                                                        sizeToContent: true,
                                                                                                    },
                                                                                                    {
                                                                                                        heading:      'Part Number',
                                                                                                        field:        'part_number',
                                                                                                        sizeToContent: true,
                                                                                                    },
                                                                                                    {
                                                                                                        heading:      'Part Description',
                                                                                                        field:        'part_description',
                                                                                                        truncate:     true,
                                                                                                    },
                                                                                                    {
                                                                                                        heading:      'Qty',
                                                                                                        field:        'qty',
                                                                                                        sizeToContent: true,
                                                                                                        fieldFormat: 'decimal:3'
                                                                                                    }
                                                                                                ]}
                                                                                                rows={
                                                                                                    _.map(_.filter(JSON.parse(inspection.cri_parts), p => _.find(this.state.lists.parts, {part_id: p.part}).stockable), p => {
                                                                                                        return {
                                                                                                            ..._.find(this.state.lists.parts, {part_id: p.part}),
                                                                                                            qty: p.qty,
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            />
                                                                                        </ExpansionPanelDetails>
                                                                                    </ExpansionPanel>
                                                                                }
                                                                                {   
                                                                                    _.filter(JSON.parse(inspection.cri_parts), p => !_.find(this.state.lists.parts, {part_id: p.part}).stockable).length > 0 &&
                                                                                    <ExpansionPanel >
                                                                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                                            Recommended Services
                                                                                        </ExpansionPanelSummary>
                                                                                        <ExpansionPanelDetails>
                                                                                            <CiDataTable
                                                                                                config={{
                                                                                                    key: 'part_id',
                                                                                                }}
                                                                                                columns={[
                                                                                                    {
                                                                                                        heading:       '',
                                                                                                        field:         'default_image.file_path',
                                                                                                        fieldFormat:   'image',
                                                                                                        sizeToContent: true,
                                                                                                    },
                                                                                                    {
                                                                                                        heading:      'Part Number',
                                                                                                        field:        'part_number',
                                                                                                        sizeToContent: true,
                                                                                                    },
                                                                                                    {
                                                                                                        heading:      'Part Description',
                                                                                                        field:        'part_description',
                                                                                                        truncate:     true,
                                                                                                    },
                                                                                                    {
                                                                                                        heading:      'Qty',
                                                                                                        field:        'qty',
                                                                                                        sizeToContent: true,
                                                                                                        fieldFormat: 'decimal:3'
                                                                                                    }
                                                                                                ]}
                                                                                                rows={
                                                                                                    _.map(_.filter(JSON.parse(inspection.cri_parts), p => !_.find(this.state.lists.parts, {part_id: p.part}).stockable), p => {
                                                                                                        return {
                                                                                                            ..._.find(this.state.lists.parts, {part_id: p.part}),
                                                                                                            qty: p.qty,
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            />
                                                                                        </ExpansionPanelDetails>
                                                                                    </ExpansionPanel>
                                                                                }
                                                                            </Grid>
                                                                        }
                                                                        <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                                            Notes:
                                                                        </Grid>
                                                                        <Grid item xs={10}>
                                                                            <Textarea
                                                                                value={inspection.cri_notes}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        {inspection.files.length > 0 &&
                                                                            <>
                                                                                <Grid item xs={2} style={{fontWeight: 'bold'}}>
                                                                                    Files:
                                                                                </Grid>
                                                                                <Grid item xs={10}>
                                                                                    <MediaTable
                                                                                        config={{ itemPerRow: 3 }}
                                                                                        media='file_path'
                                                                                        items={inspection.files}
                                                                                    />
                                                                                </Grid>
                                                                            </>
                                                                        }
                                                                    </Grid>
                                                            }
                                                        ],
                                                        data: 'inspections'
                                                    }
                                                }}
                                                columns={[
                                                    {
                                                        heading:       '',
                                                        field:         'part.default_image.thumbnail_file_path',
                                                        fieldFormat:   'image',
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading:       'Part Number',
                                                        field:         'part.part_number',
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading:       'Part Description',
                                                        field:         i => {
                                                                            let sn = [];
                                                                            let wvl = [];
                        
                                                                            _.each(i.received, j => {
                                                                                sn  = [...sn,  ...JSON.parse(j.crcdr_serial_numbers)];
                                                                                wvl = [...wvl, ...JSON.parse(j.crcdr_warranty_void_labels)];
                                                                            })
                        
                                                                            return (    
                                                                                <>
                                                                                    {i.part.part_description}<br />
                                                                                    {sn.length > 0 &&
                                                                                        <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>
                                                                                            {_.map(sn, j => <>{j}<br/></>)}
                                                                                        </Typography>
                                                                                    }
                                                                                    {wvl.length > 0 &&
                                                                                        <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>
                                                                                            {_.map(wvl, j => <>{j}<br/></>)}
                                                                                        </Typography>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        },
                                                    },
                                                    {
                                                        heading:       'Expected',
                                                        field:         'crcd_expected',
                                                        fieldFormat:   'decimal:3',
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading:       'Received',
                                                        field:         'crcd_recieved',
                                                        fieldFormat:   'decimal:3',
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading:       'Not Received',
                                                        field:         'crcd_remaining',
                                                        fieldFormat:   'decimal:3',
                                                        sizeToContent: true,
                                                        style:         i => parseFloat(i.crcd_remaining) > 0 ? {color: 'red'} : {}
                                                    },
                                                    {
                                                        heading:       '',
                                                        field:         i => 
                                                                            {
                                                                                let fail = !!_.find(i.received, j => j.crcdr_condition_grade === 'fail');
                                                                                let warning = !!_.find(i.received, j => j.crcdr_condition_grade === 'warning');
                        
                                                                                if (!fail && !warning) return '';
                        
                                                                                return (
                                                                                    <AllIcon
                                                                                        noMargin
                                                                                        icon={IconHelper.warning}
                                                                                        color={fail ? colors.red : colors.orange}
                                                                                        tooltip={fail ? 'Part/s failed inspection' : 'Part/s passed inspection but with warnings'}
                                                                                    />
                                                                                )
                        
                                                                            },
                                                        sizeToContent: true,
                                                        alignment:     'center',
                                                    }
                                                ]}
                                                rows={cr.collection.details}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <GoodsReceived 
                                        update
                                        cr={cr} 
                                        onChangeOutcome={this.onChangeOutcome}
                                        outcomes={formData.outcomes}
                                        refresh={this.refreshData}
                                    />
                                </Grid> */}
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} >
                    <Box style={{padding: '1em', paddingTop: 0}}>
                        <Grid container spacing={3} style={{alignItems: 'stretch'}}>
                            <Grid item style={{width: 'calc(100% / 5)', height: '100%'}}>
                                <PaddedPaper style={{height: '100%'}}>
                                <Grid container spacing={2} style={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <Grid item >
                                            <Typography variant="h6" gutterBottom style={{textAlign: 'center'}}>
                                                <strong>Expected</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item >
                                            <AllIcon
                                                icon={
                                                    cr.cr_expected_outcome === 'Repair' ? IconHelper.repair :
                                                    ( cr.cr_expected_outcome === 'Replace' ? IconHelper.repeat : IconHelper.creditNote )
                                                }
                                                size={50}
                                                noMargin
                                            />
                                        </Grid>
                                        <Grid item style={{textAlign: 'center'}}>
                                            <AllIcon icon={IconHelper.parts} size='small'/> {_.uniqBy(cr.collection.details, d => d.part.part_id).length}<br/>
                                            <AllIcon icon={IconHelper.qty} size='small'/> {parseFloat(_.sumBy(cr.collection.details, o => parseFloat(o.crcd_expected))).toFixed(3)}
                                        </Grid>
                                </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item style={{width: 'calc(100% / 5)', height: '100%'}}>
                                <PaddedPaper>
                                <Grid container spacing={2} style={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <Grid item >
                                            <Typography variant="h6" gutterBottom style={{textAlign: 'center'}}>
                                                <strong>Delivered</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item >
                                            <AllIcon
                                                icon={IconHelper.truck}
                                                size={50}
                                                noMargin
                                            />
                                        </Grid>
                                        <Grid item style={{textAlign: 'center'}}>
                                            <AllIcon icon={IconHelper.parts} size='small'/> {_.uniqBy(_.filter(cr.collection.details, o => parseFloat(o.crcd_recieved) > 0), d => d.part.part_id).length}<br/>
                                            <AllIcon icon={IconHelper.qty} size='small'/> {parseFloat(_.sumBy(cr.collection.details, o => parseFloat(o.crcd_recieved))).toFixed(3)}
                                        </Grid>
                                </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item style={{width: 'calc(100% / 5)', height: '100%'}}>
                                <PaddedPaper>
                                <Grid container spacing={2} style={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <Grid item >
                                            <Typography variant="h6" gutterBottom style={{textAlign: 'center'}}>
                                                <strong>Repair</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item >
                                            <AllIcon
                                                icon={IconHelper.repair}
                                                size={50}
                                                noMargin
                                            />
                                        </Grid>
                                        <Grid item style={{textAlign: 'center'}}>
                                            <AllIcon icon={IconHelper.parts} size='small'/> {_.uniqBy(_.filter(cr.collection.details, d => _.sumBy(d.inspections, r => r.cri_outcome === 'Repair' ? 1 : 0)), d => d?.part?.part_id).length}<br/>
                                            <AllIcon icon={IconHelper.qty} size='small'/>   {parseFloat(_.sumBy(cr.collection.details, d => _.sumBy(d.inspections, r => r.cri_outcome === 'Repair' ? parseFloat(r.cri_qty) : 0))).toFixed(3)}
                                        </Grid>
                                </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item style={{width: 'calc(100% / 5)', height: '100%'}}>
                                <PaddedPaper>
                                <Grid container spacing={2} style={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <Grid item >
                                            <Typography variant="h6" gutterBottom style={{textAlign: 'center'}}>
                                                <strong>Replace</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item >
                                            <AllIcon
                                                icon={IconHelper.repeat}
                                                size={50}
                                                noMargin
                                            />
                                        </Grid>
                                        <Grid item style={{textAlign: 'center'}}>
                                            <AllIcon icon={IconHelper.parts} size='small'/> {_.uniqBy(_.filter(cr.collection.details, d => _.sumBy(d.inspections, r => r.cri_outcome === 'Replace' ? 1 : 0)), d => d?.part?.part_id).length}<br/>
                                            <AllIcon icon={IconHelper.qty} size='small'/>   {parseFloat(_.sumBy(cr.collection.details, d => _.sumBy(d.inspections, r => r.cri_outcome === 'Replace' ? parseFloat(r.cri_qty) : 0))).toFixed(3)}
                                        </Grid>
                                </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item style={{width: 'calc(100% / 5)', height: '100%'}}>
                                <PaddedPaper>
                                <Grid container spacing={2} style={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <Grid item >
                                            <Typography variant="h6" gutterBottom style={{textAlign: 'center'}}>
                                                <strong>Credit</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item >
                                            <AllIcon
                                                icon={IconHelper.creditNote}
                                                size={50}
                                                noMargin
                                            />
                                        </Grid>
                                        <Grid item style={{textAlign: 'center'}}>
                                            <AllIcon icon={IconHelper.parts} size='small'/> {_.uniqBy(_.filter(cr.collection.details, d => _.sumBy(d.inspections, r => r.cri_outcome === 'Refund' ? 1 : 0)), d => d?.part?.part_id).length}<br/>
                                            <AllIcon icon={IconHelper.qty} size='small'/>   {parseFloat(_.sumBy(cr.collection.details, d => _.sumBy(d.inspections, r => r.cri_outcome === 'Refund' ? parseFloat(r.cri_qty) : 0))).toFixed(3)}
                                        </Grid>
                                </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {/*
                <Grid item xs={12} style={{marginTop: '1em'}}>
                    <AutoCompleteSelect
                        noClear
                        label='Outcome *'
                        options={[
                            {value: 'Refund',  label: 'Credit Note'},
                            {value: 'Replace', label: 'Replace'},
                            {value: 'Repair',  label: 'Repair'},
                        ]}
                        value={formData.outcome}
                        onChange={this.handleSelectChange('outcome')}
                        fullWidth
                    />
                </Grid>
                */}
                <Grid item xs={12} className='buttonRow' >
                    <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                    <Button variant='contained' color='primary' onClick={()=>this.props.deployConfirmation('Are you sure you want to submit this review?', 'Submit Review', this.submitReview)} disabled={_.values(formData.outcomes).length !== _.sumBy(cr.collection.details, i => i.received.length)}>Submit</Button>
                </Grid>
                {
                    editInspection &&
                    <Dialog
                        open={!!editInspection}
                        onClose={this.closeEditInspection}
                        fullWidth
                        maxWidth='md'
                    >
                        <DialogTitle>Edit Inspection</DialogTitle>
                        <EditInspectionDialog 
                            inspection={editInspection}
                            handleClose={this.closeEditInspection}
                            crId={cr.cr_id}
                        />
                    </Dialog>
                }
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    closeDialog: () => dispatch(closeDialog()),
});

export default connect(null, mapDispatchToProps)(ReviewReturnDialog);