import React, {Component} from 'react';
import moment from 'moment';
import {
    Button,
    Grid,
    TextField,
    Typography
} from '@material-ui/core';
import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import VehicleBreakdownForm from './VehicleBreakdownForm';
import { toggleDialog } from 'Functions/MiscFunctions';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import { CLENAWARE_RED, CLENAWARE_ORANGE } from 'Constants';

const initialState = () => ({
    searchResults: {},
    searchString: '',
    access: {
        viewBreakdown: false,
        manageBreakdown: false,
    },
    isLoading: true,
    dialog: {
        breakdown: false,
        breakdownId: 0,
        readOnly: false,
        renew: false,
    },
});

class SearchVehicleBreakdown extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.toggleDialog = toggleDialog.bind(this);
    }

    componentDidMount = () => {
        Promise.all([
            API.get('/staff/my/access/check/view-vehicle-breakdown'),
            API.get('/staff/my/access/check/manage-vehicle-breakdown'),
        ])
        .then(([viewVehicleRes, updateVehicleRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    viewBreakdown: (viewVehicleRes.data && viewVehicleRes.data.has_access) || false,
                    manageBreakdown: (updateVehicleRes.data && updateVehicleRes.data.has_access) || false
                }
            }, () => this.getSearchData());
        })        
    };

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/vehicles/breakdown', { params: { searchString: this.state.searchString } })
            .then(result => {
                this.setState({
                    isLoading: false
                });
                if(result.data) {
                    this.setState({
                        searchResults: result.data
                    });
                } else {
                    /* Error */
                }
            });
        });
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.getSearchData();
        });
    }
    
    resetSearch = () => {
        this.setState({
            searchString: ''
        }, 
        () => {
            this.getSearchData();
        });
    }

    openDialog = (id = 0, readOnly = false, renew = false) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                breakdown: true,
                breakdownId: id,
                readOnly,
                renew
            },
        });
    }
    
    render() {
        const { access, isLoading, searchResults, dialog } = this.state;
        return (
            <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Vehicle Breakdown
                    </Typography>
                </Grid> 
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <TextField id="nameSearch" label="Company" fullWidth value={this.state.searchString} onChange={this.onSearchStringChange} />
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container item spacing={3}>
                            {access?.manageBreakdown && (
                                <>
                                <Grid item xs>
                                    <em><FALightIcon icon='circle-info' size="small" /> The renew button will become available 1 month before the breakdown expires.</em>
                                </Grid>
                                <Grid item xs align="right">
                                    <Button 
                                        onClick={() => this.openDialog()}
                                        variant="outlined"
                                        color="default"
                                        size="small"
                                    >
                                        <FALightIcon icon='plus' button />
                                        Add Breakdown
                                    </Button>
                                </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <DataTable  
                                    config={{
                                        key: 'id',
                                        pagination: true,
                                        alternatingRowColours: true,
                                        isLoading: isLoading,
                                        responsiveImportance: true
                                    }}
                                    columns={[
                                        {
                                            heading: 'Company',
                                            field: rowData => rowData?.supplier?.name,
                                            main: true,
                                            important: true,
                                            hideInResponsiveDialog: true
                                        },
                                        {
                                            heading: 'Contact',
                                            field: rowData => rowData?.contactNumber,
                                            important: true
                                        },
                                        {
                                            heading: 'Start Date',
                                            field: rowData => moment(rowData?.startDate).format('DD MMMM YYYY'),                                            
                                            important: true,
                                        },
                                        {
                                            heading: 'End Date',
                                            field: rowData =>  moment(rowData?.endDate).format('DD MMMM YYYY'),
                                            important: true,
                                            cellProps: rowData => ({
                                                style: {
                                                    color:  rowData?.status == 'Active' && moment().isSameOrAfter(rowData?.endDate) ? 
                                                                CLENAWARE_RED 
                                                            : 
                                                            (rowData?.status == 'Active' && moment().isSameOrAfter(moment(rowData?.endDate).subtract(1, 'months').format('YYYY-MM-DD')) ? 
                                                                CLENAWARE_ORANGE 
                                                            : 
                                                                undefined
                                                            )
                                                }
                                            })
                                        },
                                        {
                                            heading: 'Status',
                                            field: rowData => rowData?.status,                                            
                                            important: true,
                                            cellProps: rowData => ({
                                                style: {
                                                    color:  rowData?.status == 'Inactive' ? CLENAWARE_RED : undefined
                                                }
                                            })
                                        },
                                        {
                                            actions: rowData => {
                                                const isExpiring    = rowData?.status == 'Active' && moment().isSameOrAfter(moment(rowData?.endDate).subtract(1, 'months').format('YYYY-MM-DD'));
                                                const isExpired     = rowData?.status == 'Inactive' && moment().isSameOrAfter(moment(rowData?.endDate).format('YYYY-MM-DD'));
                                                return [
                                                    {name: 'Renew',     icon: 'rotate',     onClick: () => this.openDialog(rowData?.id, false, true),   disabled: !access.manageBreakdown || !isExpiring},
                                                    {name: 'Update',    icon: 'pencil-alt', onClick: () => this.openDialog(rowData?.id),                disabled: !access.manageBreakdown || isExpired},
                                                    {name: 'View',      icon: 'search',     onClick: () => this.openDialog(rowData?.id, true),          disabled: !access.viewBreakdown}
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={searchResults}
                                />
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
            </Grid>
            <SmallFormDialog
                open={dialog?.breakdown}
                onClose={() => this.toggleDialog('breakdown')}
                content={
                    <VehicleBreakdownForm 
                        onClose={() => this.toggleDialog('breakdown', true)}
                        breakdownId={dialog?.breakdownId}
                        refresh={() => {this.toggleDialog('breakdown', true); this.getSearchData();}}
                        readOnly={dialog?.readOnly}
                        renew={dialog?.renew}
                    />
                }
                maxWidth='md'
            />
            </>
        );
    }
}

export default SearchVehicleBreakdown;