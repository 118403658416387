import React, { Component } from 'react';
import API                  from 'API';
import moment               from 'moment';
import { connect }          from 'react-redux';
import _ from 'lodash';

import DataTable                   from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper                 from 'Components/Common/Paper/PaddedPaper';
import SnackBar                    from 'Components/Common/SnackBars/SnackBar';
import StickyNotes                 from 'Components/Common/StickyNotes/StickyNotes';
import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog    from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';
import OrderSearchBlock            from 'Components/Sales/Order/OrderSearch/OrderSearchBlock';
import Order                       from 'Components/Sales/Order/ViewOrder/Order';
import AllIcon                     from 'Components/Common/Icons/AllIcon';

import { deployDialog }                     from 'Actions/Dialog/Dialog';
import { deploySnackBar }                   from 'Actions/SnackBar/SnackBar';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';
import JobSnapshot from 'Components/Engineers/Jobs/Misc/JobSnapshot';

const styles = () => ({
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    },
    custName: {
        '&:hover':{cursor: 'pointer'}
    }
});

const initialState = () => ({
    access: {
        orderSearch: false
    },
    order: {},
    orderStats: [],
    keywords: '',
    status: 'Completed',
    dateFrom: null,
    dateTo: null,
    searchResults: {},
    isLoading: false,
    approveDialogOpen: false,
    declineDialogOpen: false
});

class OrderCompleted extends Component {
    constructor(props) {
        super(props);
        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.persistenceId = 'sales:completed';
        this.state = this.getInitialState(initialState());
        this.timeout = false;
    }

    componentDidMount = () => {
        this.getSearchData();
        this.getAccess();
    }

    componentWillUnmount = () => {
        if (this.timeout)
            clearTimeout(this.timeout)
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/order-completed'),
            API.get('/staff/my/access/check/repeat-customer-order')
        ])
        .then(([accessRes, repeatOrderRes]) =>  {
            this.setState({
                access: {
                    orderCompleted: ( accessRes?.data?.has_access )      || false,
                    repeatOrder:    ( repeatOrderRes?.data?.has_access ) || false
                }
            });
        })
    }

    getSearchData = () => {
        const params = {
            keywords:   this.state.keywords,
            status:     this.state.status,
            dateFrom:   this.state.dateFrom !== null ? moment(this.state.dateFrom).format('YYYY-MM-DD') : null,
            dateTo:     this.state.dateTo !== null ? moment(this.state.dateTo).format('YYYY-MM-DD') : null,
            slim:       1
        }
        const props = {
            cancellation: true
        }
        this.setState({
            isLoading: true
        },
            () => {
                API.get('/sales/orders/search', { params, props })
                .then(resultRes => {
                    this.setState({
                        ...this.state,
                        searchResults: resultRes.data,
                        isLoading: false
                    });
                });
            });
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        },
            () => {
                this.savePageState();
                this.setSearch();
            });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
            status: 'Completed',
            dateFrom: null,
            dateTo: null,
        },
            () => {
                this.getSearchData();
            });
    }

    handleDownloadOpen = orderId => this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md');

    handleEmailOpen = orderId => this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />, 'Email Previous Documents', '', 'md');

    handleEmailClose = () => {
        this.setState({
            emailDialogOpen: false
        },
            () => {
                this.getSearchData();
            });
    };

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        },
            () => {
                this.savePageState();
                this.getSearchData();
            });
    };

    handleDateChange = name => date => {
        this.setState({
            [name]: date
        },
            () => {
                this.savePageState();
                this.getSearchData();
            });
    };

    setSearch = () => {
        if (this.timeout)
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchData, 200);
    }

    handleShowStickyNote = orderId => { this.props.deployDialog(<StickyNotes orderId={orderId} dialog/>, 'Sticky Notes', null,'xs') }

    handleSetSearch = props => {
        this.setState({
            ...props,
            status: 'Completed',
        },this.getSearchData)
    }

    render() {
        const { access } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Order Completed
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={12}>
                        <OrderSearchBlock disableSites disableStatus callBack={this.handleSetSearch} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable
                            config={{
                                key: 'order_id',
                                pagination: true,
                                alternatingRowColours: true,
                                responsiveimportant: true,
                                isLoading: this.state.isLoading,
                                persistenceId: this.persistenceId,
                            }}
                            columns={[
                                {
                                    heading: 'Order Date',
                                    field: rowData => moment(rowData.d).format("DD/MM/YYYY"),
                                    important: true,
                                    sizeToContent: true,
                                },
                                {
                                    actionsCustomHeader:    true,
                                    important:              true,
                                    alignment:              'left',
                                    heading:                'Reference',
                                    actions: rowData => {
                                        let ret = [
                                            { label: rowData.r,         link: `/sales/order/view/${rowData.i}`,         type:'plainLink'},
                                            { label: rowData.q,         link: `/sales/quotation/view/${rowData.qi}`,    type:'plainLink', class: "textSecondary"}
                                        ];
                                        if (rowData.crn.length > 0) {
                                            _.each(rowData.crn, r => {
                                                ret.push({
                                                    label: r.r,
                                                    link: `/returns/creditnote/${r.i}`,
                                                    type:'plainLink', 
                                                    color: colors.red
                                                })
                                            })
                                        }
                                        if (rowData.cr.length > 0) {
                                            _.each(rowData.cr, r => {
                                                ret.push({
                                                    label: r.r,
                                                    link: `/returns/customerReturn/${r.i}`,
                                                    type:'plainLink', 
                                                    color: colors.red
                                                })
                                            })
                                        }
                                        return ret;
                                    }
                                },
                                {
                                    heading: 'Invoice(s)',
                                    field: rowData => <>
                                        {rowData.inv.map((item, key) => {
                                            return <><Typography variant="caption" key={key}>
                                                {item.n} (Sage: {item.si})<br/> {item.cn}
                                            </Typography><br /></>
                                        })}
                                    </>,
                                    important: true,
                                    sizeToContent: true,
                                    main: true
                                },
                                // {
                                //     heading: 'Discount',
                                //     field: rowData => rowData.t === 'With Discount',
                                //     important: true,
                                //     sizeToContent: true,
                                //     fieldFormat: 'boolean',
                                //     tooltip: rowData => rowData.t,
                                //     alignment: 'center'
                                // },
                                {
                                    heading: 'Customer',
                                    field: rowData =>   
                                                <Tooltip
                                                    title={
                                                        <Typography variant="body2">
                                                            <b>Delivery Address:</b><br />
                                                            {rowData.da.split('\n').map((item, key) => {
                                                                return  <span key={key}>
                                                                            {item}{rowData.da.split('\n').length === key+1 ? null : <br/>}
                                                                        </span>
                                                            })}
                                                        </Typography>
                                                    }
                                                    placement="left"
                                                >
                                                    <div className={this.props.classes.custName} onClick={()=>{ this.props.history.push(`/customers/view/${rowData.ci}`); }}>
                                                        {rowData.cn}
                                                    </div>
                                                </Tooltip>,
                                    important: true,
                                    dataRef: 'cn',
                                    truncate: true,
                                },
                                {
                                    heading: 'Total',
                                    field: rowData => ( 
                                        <Tooltip 
                                            title={
                                                <div style={{display: 'flex', width: 200}}>
                                                    <div style={{width: '50%', textAlign:'right'}}>
                                                        <Typography variant="body2">Sub Total</Typography>
                                                        <Typography variant="body2">VAT ({rowData.tl.vp}%)</Typography>
                                                        <Typography variant="body2">Total</Typography>
                                                    </div>
                                                    <div style={{width: '50%', textAlign:'right'}}>
                                                        <Typography variant="body2">£{rowData.tl.s}</Typography>
                                                        <Typography variant="body2">£{rowData.tl.v}</Typography>
                                                        <Typography variant="body2">£{rowData.tl.iv}</Typography>
                                                    </div>
                                                </div>
                                            } 
                                            placement="left"
                                        >
                                            <span>{rowData.tl.iv}</span>
                                        </Tooltip>
                                    ),
                                    important: true,
                                    alignment: 'right',
                                    fieldPrefix: '£',
                                },
                                {
                                    heading: 'Paid',
                                    field: rowData => rowData.tl.ip || '0',
                                    important: true,
                                    alignment: 'right',
                                    fieldPrefix: '£',
                                },
                                {
                                    heading: 'Jobs',
                                    field:      rowData => <>
                                        {rowData?.ej && 
                                            <Tooltip
                                                title={<JobSnapshot jobRef={rowData?.ej?.ref} forms={rowData?.ej?.f} />}
                                                placement="left"
                                            >
                                                <IconButton onClick={() => this.props.history.push(`/engineers/jobs/view/${rowData.ej?.id}`) }>
                                                    <AllIcon 
                                                        icon={`user-helmet-safety`}
                                                        button
                                                        noMargin
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </>,
                                    alignment: 'center',
                                    sizeToContent: true,
                                    important: false
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            { name: 'Sticky Notes',               icon: icons.stickyNote, onClick: () => this.handleShowStickyNote(rowData.i), hide:rowData.n[0] === 0, color: (rowData.n[1] && colors.yellow)  },
                                            { name: 'View',                       icon: 'search',         link: '/sales/order/view/' + rowData.i },
                                            {
                                                type: 'actionMenu',
                                                rows: [
                                                    {
                                                        icon: icons.repeat,
                                                        label: "Repeat Order",
                                                        onClick:() => this.props.history.push(`/sales/order/add/repeat/${rowData.i}`),
                                                        display: true,
                                                        disabled: !access.repeatOrder
                                                    },
                                                    {
                                                        icon: "download",
                                                        label: "Download",
                                                        onClick: () => this.handleDownloadOpen(rowData.i),
                                                        display: true
                                                    },
                                                    {
                                                        icon: "envelope",
                                                        label: "Send Order to Customer",
                                                        onClick: () => this.handleEmailOpen(rowData.i),
                                                        display: true
                                                    },
                                                    {
                                                        icon: "file-search",
                                                        label: "View this Customers Orders",
                                                        onClick: () => this.props.history.push(`/customers/view/${rowData.ci}/Orders`),
                                                        display: true,
                                                        disabled: !access.orderCompleted
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                    <Dialog
                        open={this.state.approveDialogOpen}
                        onClose={this.handleApproveClose}
                        fullWidth={true}
                        maxWidth="lg"
                        scroll="body"
                        disableEscapeKeyDown
                        disableBackdropClick
                    >
                        <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#5da14d' }}>
                            <Typography style={{ color: 'white', fontSize: '1.25rem' }}>You Are About To <b>Approve</b> This Order</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Order co={this.state.order} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleApproveClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={this.handleApproveSuccess} style={{ backgroundColor: '#5da14d', color: 'white' }} autoFocus variant="contained">Approve</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.declineDialogOpen}
                        onClose={this.handleDeclineClose}
                        fullWidth={true}
                        maxWidth="lg"
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#D61F26' }}>
                            <Typography style={{ color: 'white', fontSize: '1.25rem' }}>
                                You Are About To <b>Decline</b> This Order
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Order co={this.state.order} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDeclineClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={this.handleDeclineSuccess} color="secondary" autoFocus variant="contained">Decline</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.emailSnackbarOpen}
                        onClose={this.handleEmailSnackbarClose}
                        message='You have successfully sent the email'
                    />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deploySnackBar:   (variant, msg)            => { dispatch(deploySnackBar(variant, msg)) },
    deployDialog:     (content, title, variant) => { dispatch(deployDialog(content, title, variant)) },
    clearPersistence: (key)                     => dispatch(clearPersistence(key)),
    setPersistence:   (key, state)              => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderCompleted));