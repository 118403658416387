import * as dialogActionTypes from 'Actions/Dialog/Types/Types';
import uuidv4 from 'uuid/v4';

const initialState = {
    key: uuidv4(),
    dialogOpen: false,
    dialogContent: '',
    dialogTitle: '',
    dialogVariant: '',
    dialogSize: ''
}

const DialogReducer = (state = initialState, action) => {
    switch(action.type) {
        case dialogActionTypes.DIALOG_OPEN:
            return {
                ...state,
                key: uuidv4(),
                dialogOpen:     true,
                dialogContent:  action.content,
                dialogTitle:    action.title,
                dialogVariant:  action.variant,
                dialogSize:     action.size,
                dialogStyle:    action.style,
                disableDrag:    action.disableDrag
            };
        case dialogActionTypes.DIALOG_CLOSE:
            return {
                dialogOpen: false,
                dialogContent:  '',
                dialogTitle:    '',
                dialogVariant:  '',
                dialogSize:     '',
                dialogStyle:    ''
            };
        default:
            return state;
    }
}

export default DialogReducer;