import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import DataTable          from 'Components/Common/DataTables/CiDataTable';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import DragFileInput      from 'Components/Common/Inputs/DragFileInput';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import { Grid, Typography, Button } from '@material-ui/core/';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = {
    stock: [],
    masterExcel: [],
    file: null,
    isLoading: true,
    action: 'stock'
}


class StockMasterExcel extends Component {
    constructor(props) {
        super(props);
        this.state = {...initialState, id: this.props.match.params.smtId === 'new' ? 0 : this.props.match.params.smtId }
    }

    componentDidMount() { this.getData(); }

    getData = () => {
        API.get('/parts/all', {params:{withStock: true, isStockAble: true, withDefaultImg: true}})
        .then(res => {
            this.setState({
                stock: _.map(res.data, i => ({
                    id:          i?.part_id,
                    img:         i?.default_image?.file_path,
                    number:      i?.part_number,
                    description: i?.part_description,
                    stock:       parseFloat(i?.stock?.stock_current_qty) ? parseFloat(i?.stock?.stock_current_qty) : 0,
                    predicted:   parseFloat(i?.stock?.stock_predicted) ? parseFloat(i?.stock?.stock_predicted) : 0,
                    pending:     parseFloat(i?.stock?.stock_predicted) ? (parseFloat(i?.stock?.stock_predicted) - (parseFloat(i?.stock?.stock_current_qty) ? parseFloat(i?.stock?.stock_current_qty) : 0)) : 0,
                    max:         i?.stock?.stock_maximum_level ? i?.stock?.stock_maximum_level : 0,
                    min:         i?.stock?.stock_minimum_level ? i?.stock?.stock_minimum_level : 0,
                }) ),
                isLoading: false
            });
        });
    }

    csvUpload = e => {
        let data = e.target.result.split('\n');

        let head = data[0].split(',');
        let body = [];

        for (let i = 1; i < data.length; i++) {
            let row = data[i].split(',');
            let obj = {};
    
            for (let j = 0; j < row.length; j++) {
                obj[String(head[j].replaceAll('"','').replaceAll("'",''))] = row[j].replaceAll('"','').replaceAll("'",'');
            }
    
            body.push(obj);
        }

        let changes = [];


        if (this.state.action === 'stock') {

            changes = _.filter(_.map(body, master => {
                let _stock = _.find(this.state.stock, {number: master[_.keys(master)[0]]});
                if (!_stock){ return; }
                if (parseFloat(parseFloat(master[_.keys(master)[1]]).toFixed(3) === parseFloat(_stock.stock).toFixed(3))) { return; }
                if (parseFloat(parseFloat(master[_.keys(master)[1]]) - parseFloat(_stock.stock)) === 0) { return; }

                return {
                    ..._stock,
                    master: parseFloat(master[_.keys(master)[1]]),
                    stock: parseFloat(_stock.stock),
                    diff: parseFloat(master[_.keys(master)[1]]) - parseFloat(_stock.stock),
                }
            }), i => !!i);
            
        } else {

            changes = _.filter(_.map(body, master => {
                let _stock = _.find(this.state.stock, {number: master[_.keys(master)[0]]});
                if (!_stock){ return; }
                if ( parseFloat( master[_.keys(master)[1]] - _stock.min ) === 0 && parseFloat( master[_.keys(master)[2]] - _stock.max ) === 0 ) { return; }

                return {
                    ..._stock,
                    masterMin: master[_.keys(master)[1]],
                    masterMax: master[_.keys(master)[2]],
                }
            }), i => !!i);

        }
        
        this.setState({
            masterExcel: body,
            changes: changes,
        });
    }

    fileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        var reader = new FileReader();

        reader.onload = this.csvUpload;
        reader.readAsText(file);
        this.setState({file});
    }

    clearFile = () => {
        this.setState({file: null});
    };

    submit = () => {
        this.setState({isLoading: true}, ()=>{
            let parts = [];
            _.each(this.state.changes, i => {parts.push((this.state.action === 'stock') ? { part: i.id, stock: i.master} : { part: i.id, min: i.masterMin, max: i.masterMax});});

            API.post(`/stock/${(this.state.action === 'stock') ? 'masterExcelCorrection' : 'masterExcelMinMax'}`, {parts: JSON.stringify(parts)})
            .then(res => {
                this.setState({...initialState}, this.getData);
            })
        });
    }

    render() {
        const {isLoading, masterExcel, changes} = this.state;
        
        if (isLoading) return( <LoadingCircle/> );

        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Master Excel Stock Correction
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <PaddedPaper>
                        <AutoCompleteSelect
                            options={[
                                {value: 'stock', label: 'Set Current Stock Levels'},
                                {value: 'minMax', label: 'Set Min/Max Stock Levels'},
                            ]}
                            value={this.state.action}
                            onChange={e => this.setState({action: e.value})}
                            fullWidth
                            label="Action *"
                            noClear
                        />
                        <DragFileInput
                            label="Upload Master Excel *"
                            placeholder="Please Include Headers In CSV"
                            onChange={this.fileChange}
                            cancelOnClick={this.clearFile}
                            noClear
                            accept=".csv"
                            required
                            file={this.state.file}
                            emptyText='No file selected'
                        />
                        {masterExcel.length > 0 && changes.length > 0 && 
                            <div className='buttonRow'>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>{this.props.deployConfirmation(`Are you sure you want to these parts ${this.state.action === 'stock' ? 'stock' : 'min/max levels'} to match the master Excel?`, `${this.state.action === 'stock' ? 'Correct' : 'Set'} ${changes.length} Parts ${this.state.action === 'stock' ? 'Stock' : 'Min/Max Levels'}`, this.submit)}}
                                >Correct {changes.length} Parts Stock</Button>
                            </div>
                        }
                    </PaddedPaper>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                    <PaddedPaper>
                        <Typography variant='body1' style={{fontWeight: 'bold', marginBottom: '1em'}}>Note: Uploaded CSV must have the following headings:</Typography>
                        <DataTable
                            config={{
                                plainHeader: true,
                                noHeader: true,
                            }}
                            columns={[
                                {
                                    field:   'c',
                                    sizeToContent: true,
                                    style: { fontWeight: 'bold', borderRight: `1px solid ${colors.disabled}` },
                                },
                                {
                                    field: 'n',
                                },
                            ]}
                            rows={this.state.action === 'stock' ? [
                                {c: 'A1', n: 'Part Number' },
                                {c: 'A2', n: 'Stock' }
                            ] : [
                                {c: 'A1', n: 'Part Number' },
                                {c: 'A2', n: 'Min' },
                                {c: 'A3', n: 'Max' }
                            ]}
                        />
                    </PaddedPaper>
                </Grid>
                {masterExcel.length > 0 &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            {changes.length > 0 ?
                                <DataTable
                                    config={{
                                        key: 'id',
                                        sticky:true
                                    }}
                                    columns={[
                                        {
                                            heading: '',
                                            field: 'img',
                                            fieldFormat: 'image',
                                            sizeToContent: true,
                                        }, 
                                        {
                                            heading: 'Part Number',
                                            field: 'number',
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: 'Part Description',
                                            field: 'description',
                                        },
                                        ...(this.state.action === 'stock' ? [
                                            {
                                                heading: '',
                                                field: i => (parseFloat(i.predicted).toFixed(3) !== parseFloat(i.stock).toFixed(3) &&  
                                                    <AllIcon noMargin icon={icons.warning} color={colors.orange} tooltip={
                                                        <>
                                                            Pending: {parseFloat(i.pending).toFixed(3)}<br/>
                                                            Predicted Current: {parseFloat(i.predicted).toFixed(3)}<br/>
                                                            Predicted After Upload: {parseFloat(i.master + i.pending).toFixed(3)}
                                                        </>
                                                    }/>
                                                ) || (parseFloat(i.predicted).toFixed(3) === parseFloat(i.master).toFixed(3) &&  
                                                    <AllIcon noMargin icon={icons.warning} color={colors.red} tooltip={
                                                        <>
                                                            Predicted Matches Resulting Stock<br/>
                                                            Pending: {parseFloat(i.pending).toFixed(3)}<br/>
                                                            Predicted Current: {parseFloat(i.predicted).toFixed(3)}<br/>
                                                            Predicted After Upload: {parseFloat(i.master + i.pending).toFixed(3)}
                                                        </>
                                                    }/>
                                                ),
                                                sizeToContent: true,
                                            },
                                            {
                                                heading:       'System Stock',
                                                field:         'stock',
                                                sizeToContent: true,
                                                fieldFormat:   'decimal:3',
                                                alignment:     'right',
                                                style:         {fontWeight: 'bold'},
                                            },
                                            {
                                                heading: 'Excel Stock',
                                                field: 'master',
                                                sizeToContent: true,
                                                fieldFormat: 'decimal:3',
                                                style:         {fontWeight: 'bold'},
                                            },
                                            {
                                                heading:       'Change',
                                                field:         'diff',
                                                sizeToContent: true,
                                                fieldFormat:   'colorNumbers:decimal:3',
                                                alignment:     'center',
                                                style:         {fontWeight: 'bold'},
                                            },
                                        ] : [
                                            {
                                                heading: '',
                                                field: i => <>Min<br/>Max</>,
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: 'System Stock Levels',
                                                field: i => <span>{parseFloat(i.min).toFixed(3)}<br/>{parseFloat(i.max).toFixed(3)}</span>,
                                            },
                                            {
                                                heading: 'Master Excel Stock Levels',
                                                field: i => <span>{parseFloat(i.masterMin).toFixed(3)}<br/>{parseFloat(i.masterMax).toFixed(3)}</span>,
                                            },
                                            {
                                                heading: 'Change',
                                                field: i => <>
                                                    <span style={{color: parseFloat(i.masterMin - i.min) < 0 ? colors.red : colors.green}}>
                                                        {parseFloat(i.masterMin - i.min) > 0 ? '+' : ''}{parseFloat(i.masterMin - i.min).toFixed(3)}
                                                    </span><br/>
                                                    <span style={{color: parseFloat(i.masterMax - i.max) < 0 ? colors.red : colors.green}}>
                                                    {parseFloat(i.masterMax - i.max) > 0 ? '+' : ''}{parseFloat(i.masterMax - i.max).toFixed(3)}
                                                    </span>
                                                </>
                                            }
                                        ])
                                    ]}
                                    rows={_.orderBy(changes, ['number'], ['asc'])}
                                />:
                                <>
                                    <Typography variant="h6">
                                        No Stock needs to be changed
                                    </Typography>
                                </> 
                            }
                        </PaddedPaper>
                    </Grid>
                }
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    }
}

export default connect(null, mapDispatchToProps)(StockMasterExcel);