import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch }                             from 'react-redux';
import { Redirect }                                from 'react-router-dom';
import API                                         from 'API';
import { ACCOUNTS_EMAIL, WEB_EMAIL }               from 'Constants';
import _, { assign, find, map }                    from 'lodash';



import Textarea                from 'Components/Common/Inputs/Textarea';
import LoadingCircle           from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';

import { Button, FormControl, Typography } from '@material-ui/core';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog }        from 'Actions/Dialog/Dialog';
import { deploySnackBar }     from 'Actions/SnackBar/SnackBar';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { colors }                 from 'Helpers/ColourHelper';

const EmailCustomerReturnsDialog = ({ customerId, customerContactId, type, id, redirectPath, callback, subType, accountsEmail, deliveryAddressId = 0, onclose }) => {

    const dispatch = useDispatch();

    const initialState = {
        formErrors: {},
        formData: {
            type: '',
            id: '',
            emailTemplate: '',
            emailTo: '',
            emailName: '',
            emailAddress: '',
            emailCc: [],
            staff: [],
            emailText: '',
        },
        emailTemplateList: [],
        customerContactList: [],
        staffList: [],
        countryList: [],
        isLoading: true,
        redirect: false
    };

    let [state, setState] = useState({...initialState});
    
    const {formErrors, formData, 
        formData:{emailTemplate, emailTo, emailCc, staff, emailText, addressCountry}, 
        emailTemplateList, customerContactList, staffList, isLoading, redirect, countryList} = state;
        
    const handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleSelectChange = fieldName => selectedOption => {
        setState(state => ({
            ...state,
            formData:{
                ...formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }));
    }

    const handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [fieldName]: values
            }
        }));
    }

    const handleEmailDialogClose = () => {
        if(redirectPath) setState(state => ({...state, redirect: true}));
        if (onclose) onclose();
        dispatch(closeDialog());
    }

    const handleSubmit = () => dispatch(deployConfirmation('Are you sure you want to send email to customer?', 'Send Email?', submit));

    const submit = () => {
        setState(state => ({...state, isLoading: true}));
        let apiURL = '';

        switch(type) {
            default: apiURL = `/customerReturns/creditNote/${id}/email`; break;
        }
        API.post(apiURL, formData)
        .then(result => {
            if(result.data.errors) {
                setState(state => ({
                    ...state, 
                    formErrors: formatValidationErrors(result.data.errors), 
                    isLoading: false
                }));
            } else if(result.data.success) {
                handleEmailSuccess();
            }
        });
    }

    const handleEmailSuccess = () => {
        if(redirectPath) setState(state => ({...state, redirect: true}));
        if(callback) callback();
        handleEmailDialogClose();
        dispatch(deploySnackBar('success', 'You have successfully sent email'));
    }

    // undisable all when emailTo is cleared
    const resetFields = useCallback(() => {
        customerContactList.forEach(el => el.disabled = false);
        setState(state => ({...state, customerContactList}));

    }, [customerContactList])

    const setFields = useCallback(() => {
        let customer = find(customerContactList, { 'value': emailTo});

        if (!customer) 
            customer = find(customerContactList, i => i.primary_invoice_count > 0);

        if (!customer) 
            customer = customerContactList[0];

        let newContactList = [...customerContactList];
        let newEmailCcList = [...emailCc];

        for(let i in newContactList) {
            if(newContactList[i].value === emailTo) {
                newContactList[i].disabled = true;
                if(emailCc.includes(emailTo)) {
                    newEmailCcList = newEmailCcList.filter(id => id !== emailTo);
                }
            } else {
                newContactList[i].disabled = false;
            }
        }

        setState(state => ({
            ...state, 
            customerContactList: newContactList,
            formData: {
                ...state.formData,
                emailName: customer?.name,
                emailAddress: customer?.email,
                emailCc: newEmailCcList
            }
        }));

    }, [customerContactList, emailTo, emailCc])

    useEffect(() => {
        let country = find(countryList, { 'value': addressCountry});
        setState(state => ({
            ...state,
            formData: {
                ...state.formData,
                addressCountryCode: country?.code
            }
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressCountry]);

    // Handle email formData prefill on emailTo change (required for send email on submit) and emailcc disabling
    useEffect(() => {
        if(emailTo && emailTo !== '') {
            setFields();
        } else {
            resetFields();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailTo]);

    // Handle prefill email text from on email template change 
    useEffect(() => {
        if(emailTemplateList && emailTemplateList.length > 0 && emailTemplate !== '') {
            let selectedEmailTemplate = find(emailTemplateList, { 'value': emailTemplate });

            setState(state => ({
                ...state,
                formData: {
                    ...state.formData,
                    emailText: selectedEmailTemplate ? selectedEmailTemplate.text : ''
                }
            }));
        }

    }, [emailTemplate, emailTemplateList]);
    
    // On load, get email templates, activ staff and customer contacts, then format for autocomplete select
    useEffect(() => {
        let promiseArr = [
            API.get('/customerReturns/emailTemplates'),
            API.get(`/customers/${customerId}/contacts`),
            API.get('/staff/all'),
            API.get('/misc/countries/all'),
        ];

        Promise.all(promiseArr)
        .then(([emailRes, contactRes, staffRes, countryRes]) => {
            let emailTemplateList = map(emailRes.data, el => {
                return assign({
                    value:  el.cret_id,
                    label:  el.cret_name,
                    text:   el.cret_text
                });
            });

            let customerContactList = map(_.filter(contactRes.data, i => {return i.contact_email}), el => {
                    return assign({
                        value: el.contact_id,
                        label: el.contact_name + ' (' + el.contact_email + ')',
                        name: el.contact_name,
                        email: el.contact_email
                    });
            });

            let staffList = map(staffRes.data, el => {
                return assign({
                    value: el.staff_id,
                    label: el.staff_first_name + ' ' + el.staff_last_name,
                });
            });

            let countryList = map(countryRes.data, el => {
                return assign({
                    value: el.country_id,
                    label: el.country_name,
                    code: el.country_code,
                });
            });

            let emailTemplateId = null;
            switch(subType) {
                case 'Acknowledgement':
                case 'AwaitingApproval':
                case 'Approval':
                    emailTemplateId = 1;
                break;
                case 'Confirmation':
                case 'Confirmed':
                    emailTemplateId = 2;
                break;
                case 'Decline':
                case 'Declined':
                    emailTemplateId = 3;
                break;
                case 'Invoice':
                    emailTemplateId = 4;
                break;
                case 'Cancelled':
                    emailTemplateId = 5;
                break;
                default:
                    emailTemplateId = 1;
            }

            setState(state => ({
                ...state, 
                formData: {
                    ...state.formData,
                    type: subType,
                    id,
                    emailTemplate: emailTemplateId,
                    emailTo: customerContactId && parseInt(customerContactId)
                },
                emailTemplateList, 
                customerContactList,
                staffList,
                countryList,
                isLoading: false
            }));
        });

    }, [customerId, customerContactId, id, type]);

    if(redirect && redirectPath) {
        return (
            <Redirect to={redirectPath} />
        );
    }

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <React.Fragment>
                    <br></br>
                    {formErrors && formErrors.generic && (
                        <React.Fragment>
                            <Typography component={"div"} style={{color: colors.red}}>
                                {formErrors.generic}
                            </Typography>
                        </React.Fragment>
                    )}
                    <Typography variant="body2" gutterBottom>
                        From: {accountsEmail ?
                            <>Clenaware Accounts {`<${ACCOUNTS_EMAIL}>`}</>:
                            <>Clenaware Systems {`<${WEB_EMAIL}>`}</>
                        }
                    </Typography>
                    <form noValidate autoComplete="off">
                        <FormControl fullWidth margin="normal">
                            <AutoCompleteSelect
                                id="emailTo"
                                name="emailTo"
                                options={customerContactList} 
                                label='To *'
                                value={emailTo}
                                onChange={handleSelectChange('emailTo')}
                                error={formErrors && formErrors['emailTo'] && true}
                                errorText={formErrors && formErrors['emailTo'] && formErrors['emailTo']}
                            />
                        </FormControl>
                        <FormControl margin="normal" fullWidth>
                            <AutoCompleteMultiSelect 
                                options={customerContactList} 
                                label='Email Cc'
                                value={emailCc}
                                onChange={handleMultiSelectChange('emailCc')}
                                error={formErrors && formErrors['emailCc'] && true}
                                errorText={formErrors && formErrors['emailCc']}
                            />
                        </FormControl>
                        <FormControl margin="normal" fullWidth>
                            <AutoCompleteMultiSelect 
                                options={staffList} 
                                label='Staff'
                                value={staff}
                                onChange={handleMultiSelectChange('staff')}
                                error={formErrors && formErrors['staff'] && true}
                                errorText={formErrors && formErrors['staff']}
                            />
                        </FormControl>
                        <FormControl error={formErrors && formErrors['emailTemplate'] && true} fullWidth margin="normal">
                            <AutoCompleteSelect
                                options={emailTemplateList} 
                                label='Email Template'
                                value={emailTemplate}
                                onChange={handleSelectChange('emailTemplate')}
                                error={formErrors && formErrors['emailTemplate'] && true}
                                errorText={formErrors && formErrors['emailTemplate'] && formErrors['emailTemplate']}
                            />
                        </FormControl>
                        <Textarea
                            id="emailText"
                            name="emailText"
                            label="Email Text *"
                            value={emailText}
                            rows={5}
                            error={formErrors && formErrors['emailText']}
                            onChange={handleChange}
                        />
                        <br></br>
                    </form>
                    <div className="buttonRow">
                        <Button 
                            onClick={handleEmailDialogClose} 
                            variant="contained" 
                            color="secondary"
                        >
                            Don't Send
                        </Button>
                        <Button 
                            onClick={handleSubmit} 
                            autoFocus 
                            variant="contained" 
                            color="primary" 
                            style={{backgroundColor:'#5da14d', color:'white'}}
                        >
                            Send
                        </Button>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default EmailCustomerReturnsDialog;