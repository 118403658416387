import React, {Component}  from 'react';
import _                   from 'lodash';
import API                 from 'API';
import { Redirect }  from 'react-router-dom';

import { Typography, Grid, TextField, InputAdornment, Button, Table, TableBody, TableCell, TableHead, TableRow, List, ListItem, ListItemText, 
    ListItemSecondaryAction } from '@material-ui/core';

import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar           from 'Components/Common/SnackBars/SnackBar';
import BackButton         from 'Components/Common/Buttons/BackButton';
import AllIcon            from 'Components/Common/Icons/AllIcon';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { colors }                 from 'Helpers/ColourHelper'; 
import IconHelper                 from 'Helpers/IconHelper';

const initialState = { 
    part: {},
    formData: {
        part: '',
        partDesc: '',
        price: '',
        grossMargin: '',
        currentCost: '',
        currentPrice: '',
        currentGrossMargin: '',
        defaultPriceId: ''
    },
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: true,
    redirect: false
}

class UpdatePrice extends Component {
    constructor(props) {
        super(props);
    
        this.state = initialState;
    }

    componentDidMount(){
        this.getPriceDetails(this.props.match.params.id);
    }

    getPriceDetails = (partId) => {
        Promise.all([ 
            API.get(`/parts/${partId}`), 
            API.get(`/parts/${partId}/costs/default`),
            API.get(`/parts/${partId}/prices/default`),
        ])
        .then(([partRes, costRes, priceRes]) =>  {
            if (partRes.data.errors) {
                this.setState({ redirect: true });
            } else {
                this.setState({
                    part: partRes.data,
                    formData: {
                        ...this.state.formData,
                        part: partRes.data.part_id,
                        partDesc: partRes.data.part_number + ' - ' + partRes.data.part_description,
                        currentCost: costRes.data?.cost_per_unit || '-',
                        price: '',
                        grossMargin: '',
                        currentPrice: priceRes.data.price_total,
                        currentGrossMargin: priceRes.data.price_margin,
                        defaultPriceId: priceRes.data.price_id
                    },
                    isLoading: false
                });
            }
        })
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, () => {
            if(fieldName === 'part'){
                this.getCurrentCost(selectedOption && selectedOption.value);
            }
        });
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            API.post('/prices/update', this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...initialState,
                        snackbarOpen: true
                    });
                    this.getPriceDetails(this.props.match.params.id);
                }
                this.props.scrollToTop();
            });
        });
    }

    calculateMargin = () => {
        let margin = 0;
        if(!isNaN(this.state.formData.price) && this.state.formData.price > 0){
            margin = ((this.state.formData.price - this.state.formData.currentCost) / this.state.formData.price) * 100;
        }
        this.setState({
            formData: {
                ...this.state.formData,
                grossMargin: margin.toFixed(2)
            }
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }
       
    render() {
        const { formErrors, part } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/prices/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Update Price
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                {formErrors && formErrors.generic && (
                                    <React.Fragment>
                                        <Typography component={"div"} style={{color: colors.red}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </React.Fragment>
                                )}
                                <form noValidate autoComplete="off">
                                    <TextField
                                        label="Part"
                                        value={this.state.formData.partDesc}
                                        margin="normal"
                                        fullWidth
                                        disabled
                                    />
                                    <Table style={{marginTop:30, marginBottom:30}}>
                                        <TableHead>
                                        <TableRow>
                                            <TableCell>Current Cost</TableCell>
                                            <TableCell>Current Price</TableCell>
                                            <TableCell>Current Gross Margin</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>£{this.state.formData.currentCost}</TableCell>
                                                <TableCell>£{this.state.formData.currentPrice}</TableCell>
                                                <TableCell>{this.state.formData.currentGrossMargin}%</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <TextField
                                        id="price"
                                        name="price"
                                        label="New Price *"
                                        type='number'
                                        value={this.state.formData.price}
                                        error={formErrors && formErrors['price'] && true}
                                        helperText={formErrors && formErrors['price']}
                                        onChange={this.handleChange}
                                        onKeyUp={this.calculateMargin}
                                        margin="normal"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                              <InputAdornment  position="start">
                                                £
                                              </InputAdornment>
                                            )
                                        }}
                                    />
                                    <TextField
                                        id="grossMargin"
                                        name="grossMargin"
                                        label="Gross Margin *"
                                        value={this.state.formData.grossMargin}
                                        error={formErrors && formErrors['grossMargin'] && true}
                                        helperText={formErrors && formErrors['grossMargin']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                        disabled
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment  position="end">
                                                %
                                              </InputAdornment>
                                            ),
                                        }}
                                    />
                                </form>
                                {formErrors && formErrors['part_locked'] &&
                                    <Grid container xs={12} style={{paddingTop:'1em'}}>
                                        <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>
                                            This part is locked, please try again later.
                                        </Grid>
                                    </Grid>
                                }
                                <div className='buttonRow'>
                                    <BackButton props={this.props} />
                                    <Button onClick={this.handleConfirmationOpen}
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.isLoading}
                                           >
                                        Update
                                    </Button>
                                </div>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={2}></Grid>
                        {part?.packages?.length > 0 &&
                            <Grid item xs={12} lg={4}>
                                <PaddedPaper style={{backgroundColor: colors.importantInfo}}>
                                    <Typography>
                                        The following packages will need to be updated if this parts price is changed:
                                    </Typography>
                                    <List>
                                        {_.map(part.packages, (packageItem, idx) => 
                                            <ListItem style={{borderBottom: (idx + 1) < part.packages.length && `1px solid ${colors.black}`}}>
                                                <ListItemText primary={packageItem.p_name} />
                                                <ListItemSecondaryAction>
                                                    <AllIcon noMargin icon={IconHelper.search} onClick={()=>this.props.history.push(`/sales/package/${packageItem.p_id}`)} />    
                                                </ListItemSecondaryAction>    
                                            </ListItem>
                                        )}
                                    </List>
                                </PaddedPaper>
                            </Grid>
                        }
                    </Grid>
                    <ConfirmationDialog 
                        open={this.state.confirmationOpen} 
                        success={this.handleConfirmationSuccess} 
                        close={this.handleConfirmationClose} 
                        title="Update Price?" 
                        message="Are you sure you want to update this price?"
                    />
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarOpen}
                        onClose={this.handleSnackbarClose}
                        message='You have successfully updated the price'
                    />
                </Grid>
            );
        }
    }
}

export default UpdatePrice;