import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import API from '../../../../API';
import _ from 'lodash';
import DatePicker from '../../../Common/DatePickers/DatePicker';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import FASolidIcon from '../../../Common/Icons/FontAwesome/FASolidIcon';
import IconButton from '@material-ui/core/IconButton';
import AutoCompleteMultiSelect from '../../../Common/Selects/AutoCompleteMultiSelect';
import { withStyles } from '@material-ui/core/styles';
import FALightIcon from '../../../Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from '../../../Common/LoadingCircle/LoadingCircle';
import {colors} from 'Helpers/ColourHelper';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    formData: {
        part: [],
        dateFrom: null,
        dateTo: null,
    },
    parts: [],
    partsList: [],
    partLetters: [],
    partsWithCodes: [],
    reportResults: null,
    isLoading: true
});

const styles = theme => ({
    letterLlinks: {
        textDecoration: 'none',
        color: '#2E85C2',
        minWidth: '26px'
    },
    dateRange: {
        textAlign: 'right',
    },
    [theme.breakpoints.down('xs')]: {
        dateRange: {
            textAlign: 'left',
        },
    },
});

class OverallStockReport extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Reports:OverallStockReport';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        !this.hasPageState() && this.getParts();
    }

    getParts = () => {
        API.get('/parts/withStock')
        .then((result) => {
            let partsWithCodes = [];
            _.map(result.data, (el) => {
                if(typeof partsWithCodes[el.part_type.part_type_code.substr(0,1)] === 'undefined'){
                    partsWithCodes[el.part_type.part_type_code.substr(0,1)] = [];
                }
                partsWithCodes[el.part_type.part_type_code.substr(0,1)].push(el.part_id);
            });

            let partsList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description
                });
            });
            this.setState({
                partsList: partsList,
                parts: result.data,
                partsWithCodes: partsWithCodes,
                isLoading: false
            }, 
            () => {
                this.savePageState();
                this.getPartLetters();
            });
        });
    }

    getPartLetters = () => {
        let partLetters = _.map(this.state.parts, (el) => {
            return el.part_number.substr(3,1);
        });
        this.setState({
            partLetters: _.sortedUniq(partLetters)
        },() => {this.savePageState();});
    }

    showResults = () => {    
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            if(key === "dateFrom" || key === "dateTo") {
                if(this.state.formData[key] !== null){
                    newFormData.append(key, moment(this.state.formData[key]).format('YYYY-MM-DD'));
                }
                
            } else {
                newFormData.append(key, this.state.formData[key]);
            }
        });
        API.post(`/reports/overallStockReport`, newFormData)
        .then((result) => {
            this.setState({
                reportResults: result.data
            },() => {this.savePageState();});
        })
    }

    handleMultiSelectChange = selectedOptions => {
        let values = selectedOptions && selectedOptions.map(a => a.value);
        this.setState({
            formData: {
                ...this.state.formData,
                part: values
            }
        },() => {this.savePageState();});
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },() => {this.savePageState();});
    }

    handleDateChange = name => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: date
            }
        },() => {this.savePageState();});
    };

    downloadFile = (url) => {
        window.open(url, '_blank');
    }

    selectLetter = letter => () =>  {
        let partIds = _.map(this.state.partsWithCodes[letter], (id) => {
            return id;
        });
        this.setState({
            formData: {
                ...this.state.formData,
                part: partIds
            }
        },() => {this.savePageState();});
    }

    selectAll = () => {
        let partIds = _.map(this.state.partsList, (el) => {
            return el.value;
        });
        this.setState({
            formData: {
                ...this.state.formData,
                part: partIds
            }
        },() => {this.savePageState();});
    }

    deselectAll = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                part: []
            }
        },() => {this.savePageState();});
    }
       
    render() {
        const { classes } = this.props;
        const { formData, isLoading, reportResults, partLetters } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Overall Stock Report
                    </Typography>
                </Grid>                
                {(isLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                )) || (
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <form noValidate autoComplete="off">
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteMultiSelect
                                                name="part"
                                                options={this.state.partsList}
                                                label='Part(s) *'
                                                value={formData.part}
                                                onChange={this.handleMultiSelectChange}
                                            />
                                        </FormControl>
                                        <Typography>
                                            <Button className={classes.letterLlinks} onClick={this.selectAll}>
                                                Select All
                                            </Button>
                                            <Button className={classes.letterLlinks} onClick={this.deselectAll}>
                                                Deselect All
                                            </Button>
                                            {_.map(partLetters, letter => {
                                                return (
                                                    <Button key={letter} className={classes.letterLlinks} onClick={this.selectLetter(letter)}>
                                                    {letter}
                                                    </Button>
                                                );
                                            })}
                                        </Typography>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} lg={6}>
                                                <DatePicker
                                                    type='date'
                                                    id="dateFrom"
                                                    name="dateFrom"
                                                    label="Date From"
                                                    autoOk={true}
                                                    value={formData.dateFrom}
                                                    onChange={this.handleDateChange('dateFrom')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <DatePicker
                                                    type='date'
                                                    id="dateTo"
                                                    name="dateTo"
                                                    label="Date To"
                                                    autoOk={true}
                                                    value={formData.dateTo}
                                                    onChange={this.handleDateChange('dateTo')}
                                                    
                                                />
                                            </Grid>
                                        </Grid>
                                        <div className='buttonRow'>
                                            <Button 
                                                onClick={this.showResults}
                                                color="primary"
                                                variant="contained"
                                            
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    </form>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
                {reportResults &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="h6" gutterBottom>
                                                <FALightIcon icon='cubes' /><b>Current Stock Situation</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className={classes.dateRange}>
                                            <Typography variant="h6" gutterBottom>
                                                <FALightIcon icon='calendar-alt' /><b>As of today ({moment().format("DD-MM-YYYY")})</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>                                    
                                <Grid item xs={12} lg={6} align='center'>
                                    <PaddedPaper style={{backgroundColor: '#f5f5f5'}}>
                                        <FALightIcon icon='hand-holding-box' size='xlarge' />
                                        <Typography variant="h6" style={{textAlign:'center'}}>
                                            Current Quantity
                                        </Typography>
                                        <Typography variant="h6" style={{textAlign:'center'}}>
                                            <b>{parseFloat(reportResults.current_quantity).toFixed(3)}</b>
                                        </Typography>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12} lg={6} align='center'>
                                    <PaddedPaper style={{backgroundColor: '#f5f5f5'}}>
                                        <FALightIcon icon='coins' size='xlarge' />
                                        <Typography variant="h6" style={{textAlign:'center'}}>
                                            Current Value
                                        </Typography>
                                        <Typography variant="h6" style={{textAlign:'center'}}>
                                            <b>£{parseFloat(reportResults.current_value).toFixed(2)}</b>
                                        </Typography>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Typography variant="h6">
                                        <FALightIcon icon='info-circle' /><b>Overview</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} lg={6} className={classes.dateRange}>
                                    <Typography variant="h6">
                                        <FALightIcon icon='calendar-alt' /><b>{reportResults.overview_title}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Table>
                                        <TableBody>
                                            <TableRow style={{backgroundColor: '#f5f5f5'}}>
                                                <TableCell colSpan={2}>Opening Stock</TableCell>
                                                <TableCell style={{textAlign:'right'}}>{parseFloat(reportResults.opening_stock).toFixed(3)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <FALightIcon icon='arrow-circle-right' /> IN
                                                </TableCell>
                                                <TableCell style={{textAlign:'right'}}>
                                                    <Tooltip title="Stock IN values = cost per unit on the date of the action multiplied by the quantity">
                                                        <IconButton>
                                                            <FASolidIcon icon='info-circle' noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                            {reportResults.overview && reportResults.overview.IN &&
                                            reportResults.overview.IN.map((item, idx) => {
                                                return(
                                                <TableRow key={idx}>
                                                    <TableCell style={{textAlign:'right'}}>{item.activity_type}</TableCell>
                                                    <TableCell style={{textAlign:'right', borderRight: '1px solid rgba(224, 224, 224, 1)'}}>{item.quantity} {' '} (£{parseFloat(item.value).toFixed(2)})</TableCell>
                                                    {idx === 0 &&
                                                        <TableCell rowSpan={reportResults.overview.IN.length} style={{textAlign:'right'}}>
                                                            <FASolidIcon icon='plus' noMargin style={{color:colors.green}} /> {reportResults.overview.IN_QTY}{' '}({reportResults.overview.IN_VALUE})
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                                )
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <FALightIcon icon='arrow-circle-left' /> OUT
                                                </TableCell>
                                                <TableCell style={{textAlign:'right'}}>
                                                    <Tooltip title="Stock OUT values = cost per unit on the date of the action multiplied by the quantity">
                                                        <IconButton>
                                                            <FASolidIcon icon='info-circle' noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                            {reportResults.overview && reportResults.overview.OUT  && 
                                            reportResults.overview.OUT.map((item, idx) => {
                                                return(
                                                <TableRow>
                                                    <TableCell style={{textAlign:'right'}}>{item.activity_type}</TableCell>
                                                    <TableCell style={{textAlign:'right', borderRight: '1px solid rgba(224, 224, 224, 1)'}}>{item.quantity} {' '} (£{parseFloat(item.value).toFixed(2)})</TableCell>
                                                    {idx === 0 &&
                                                        <TableCell rowSpan={reportResults.overview.IN.length} style={{textAlign:'right'}}>
                                                            <FASolidIcon icon='minus' noMargin style={{color:colors.red}} /> {reportResults.overview.OUT_QTY}{' '}({reportResults.overview.OUT_VALUE})
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                                )
                                            })}
                                            <TableRow style={{backgroundColor: '#f5f5f5'}}>
                                                <TableCell colSpan={2}>Closing Stock as at {reportResults.date_to === null ? moment().format("DD-MM-YYYY") : moment(reportResults.date_to).format("DD-MM-YYYY")}</TableCell>
                                                <TableCell style={{textAlign:'right'}}>{parseFloat(reportResults.closing_stock).toFixed(3)}</TableCell>
                                            </TableRow>
                                            <TableRow style={{backgroundColor: '#f5f5f5'}}>
                                                <TableCell colSpan={2}>
                                                    Closing Stock Value as at {reportResults.date_to === null ? moment().format("DD-MM-YYYY") : moment(reportResults.date_to).format("DD-MM-YYYY")}
                                                    <Tooltip title={`The cost per unit on ${reportResults.date_to === null ? moment().format("DD-MM-YYYY") : moment(reportResults.date_to).format("DD-MM-YYYY")} multiplied by the stock quantity on this date`}>
                                                        <IconButton>
                                                            <FASolidIcon icon='info-circle' noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell style={{textAlign:'right'}}>£{parseFloat(reportResults.closing_stock_value).toFixed(2)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OverallStockReport));