import React, { Component } from 'react';
import _                    from 'lodash';

import DatePicker       from 'Components/Common/DatePickers/DatePicker';
import FALightIcon      from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper      from 'Components/Common/Paper/PaddedPaper';
import SelectPartWithQR from 'Components/Common/Selects/SelectPartWithQR';

import { Button, FormControl, Grid, TextField } from '@material-ui/core';

const initialState = {
    keywords:           '',
    dateFrom:           null,
    dateTo:             null,
    part:               null
}

class SearchBar extends Component {
    constructor(props) {
        super(props);    
        this.timeout = false;
        this.state   = {...initialState, ...props.formData};
        this.timeout = false;
    }

    componentWillUnmount = () => { if(this.timeout) clearTimeout(this.timeout) }

    handleReset = () => { this.setState(initialState,this.handleReturnChange) }

    handleReturnChange = () => { 
        clearTimeout(this.timeout);
        this.timeout  = setTimeout(() => {
            this.props.callBack({...this.state}); 
        },400);
    }

    handleSelectChange = (f) => (e) => {
        this.setState({
            [f]: e?.value
        }, this.handleReturnChange);
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, this.handleReturnChange);
    };

    handleDateChange = name => date => {
        this.setState({
            [name]: date
        }, this.handleReturnChange);
    };

    render(){

        const { keywords, dateFrom, dateTo } = this.state;
        const Element = this.props.noPad ? Grid : PaddedPaper;
        const bigSize = 12;
        const subSize = Math.floor(100/_.sum([3,!this.props.disablePart ? 1 : 0, !this.props.disableDate ? 2 : 0, !this.props.disableReset ? 1 : 0 ]));

        return (
            <Grid container xs={bigSize}>
                <Element style={{width:'100%'}}>
                    <form noValidate autoComplete="off">
                        <Grid container xs={12} spacing={3} >
                            <Grid item style={{width: `${subSize * 2}%`}}>
                                <FormControl margin="normal" fullWidth style={{margin:0}}>
                                    <TextField label="Keywords" fullWidth value={keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                            </Grid>
                            {!this.props.disableDate &&
                                <>
                                    <Grid item style={{width: `${subSize}%`}}>
                                        <DatePicker
                                            type='date'
                                            id="dateFrom"
                                            name="dateFrom"
                                            label="Date From"
                                            autoOk={true}
                                            value={dateFrom}
                                            onChange={this.handleDateChange('dateFrom')}
                                            margin='none'
                                        />
                                    </Grid>
                                    <Grid item style={{width: `${subSize}%`}}>
                                        <DatePicker
                                            type='date'
                                            id="dateTo"
                                            name="dateTo"
                                            label="Date To"
                                            autoOk={true}
                                            value={dateTo}
                                            onChange={this.handleDateChange('dateTo')}  
                                            margin='none'                                          
                                        />
                                    </Grid>
                                </>
                            }
                            {!this.props.disablePart &&
                                <Grid item style={{width: `${subSize}%`}}>
                                    <SelectPartWithQR 
                                        onChange={this.handleSelectChange('part')}
                                        parts   ={this.props.parts}
                                        margin  ='none'
                                        label   ='Assembly'
                                    />
                                </Grid>
                            }
                            {!this.props.disableReset &&
                                <Grid item   style={{marginLeft:'auto'}}>
                                    <div className='buttonRow'>
                                        <Button onClick={this.handleReset}
                                                variant="outlined"
                                                color="default"
                                        >
                                            <FALightIcon icon='undo' button />
                                            Reset Search
                                        </Button>
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    </form>
                </Element>
            </Grid>
        )
    }

}

export default SearchBar;