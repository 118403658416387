import React from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import { map } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { closeDialog }        from 'Actions/Dialog/Dialog';

const ViewFormNotesDialog = ({ notes, closeDialog }) => {

    return (
        <>
        <Grid container spacing={1}>
            {map(notes, (note) => {
                return (
                    <Grid item xs={12}>
                        <Box style={{backgroundColor: 'rgb(250 250 250 / 1)', padding: '10px'}}>
                            <Typography variant="body2">
                                <b>{note.name}</b> 
                                <span style={{color: 'rgb(107 114 128 / 1)', marginLeft: '6px'}}>{moment(note?.datetime).format('MMM DD, YYYY HH:mm')}</span>
                            </Typography>
                            <Typography variant="body2">
                                {map(note?.text.split('\n'), (line, index) => (
                                    <p key={index} >{line}</p>
                                ))}
                            </Typography>
                        </Box>
                    </Grid>
                );
            })}
            <Grid item xs={12} className='buttonRow'>
                <Button variant='outlined' onClick={() => closeDialog()}>Close</Button>
            </Grid>
        </Grid>
        </> 
    );

};

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(closeDialog())
})

export default connect(null, mapDispatchToProps)(ViewFormNotesDialog);