import React, {Component} from 'react';
import API                from 'API';
import _                  from 'lodash';
import { connect }        from 'react-redux';

import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import DataTable          from 'Components/Common/DataTables/CiDataTable';
import ImageWithError     from 'Components/Common/ImageWithError/ImageWithError';
import BackButton         from 'Components/Common/Buttons/BackButton';
import ScanDialog         from 'Components/Stock/StockTake/StockTake/ScanDialog';

import { colors }                 from 'Helpers/ColourHelper';
import { clenyDate }              from 'Helpers/TimeHelper';

import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';
import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { deploySnackBar }            from 'Actions/SnackBar/SnackBar';

import {Grid, Typography, Button, Dialog, DialogActions, DialogContent, Checkbox} from '@material-ui/core/';

import logo from 'Assets/Images/clenaware_logo_icon.png';

const initialState = {
    stockTake:  {},
    formData:   {
        notes: null
    },
    details:    [],
    access:     {},
    isLoading:  true,
    formErrors: {},
    showScan:   false,
}

class ManualStockTake extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;  
    }

    componentDidMount() { 
        this.getData(); 
        this.getAccess();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/stock-take:submit'), 
            API.get('/staff/my/access/check/stock-take:review'), 
            API.get('/staff/my/access/check/stock-take:download'), 
        ]).then(([submitRes,  reviewRes, downloadRes]) => {
            this.setState({
                access: {
                    download:   downloadRes.data.has_access || false,
                    submit:     submitRes.data.has_access   || false,
                    reviewRes:  reviewRes.data.has_access   || false,
                }
            })
        });
    }

    getData = () => {
        API.get(`/stock/take/${this.props.match.params.stId}`)
        .then( res => {
            this.setState({
                stockTake: res.data,
                details:   _.map(res.data.details, i => _.assign({...i, taken: 0, reScan: 0})),
                formData:  {
                    notes: res.data.st_notes
                },
                isLoading: false,
            });
        });
    }

    loadPartImage = (rowData) => {
        this.setState({ 
            partImageDialog: true,
            partImageDialogData: rowData.part.default_image?.file_path
        });
    };

    handleChange = (e, id) => {
        this.setState({
            details: _.map(this.state.details, i => {
                if(i.std_id === id) {
                    i.std_taken_stock = e.target.value;
                    i.taken           = 1;
                }
                return i;
            })
        })
    }


    save = (notes) => { 
        let data = {
            details: _.map(_.filter(this.state.details, i => i.std_taken_stock !== null && i.taken), i=>_.assign({
                id:  i.std_id,
                qty: i.std_taken_stock,
            })),
            status:  'QR',
            notes,
        }
        API.post(`/stock/take/${this.props.match.params.stId}/save`, data)
        .then( res => {
            this.props.deploySnackBar('success','Stock Take Saved');
            this.setState({
                showScan: false,
                formData: { notes },
            })
        })
    }

    submit = () => {
       // this.save(this.state.formData.notes);
        API.post(`/stock/take/${this.props.match.params.stId}/submit`)
        .then(res => {
            this.props.history.push(`/stock/take`);
        })
    }

    handleNoteChange = e => {
        this.setState({
            formData: {
                notes: e.target.value
            }
        })
    }

    beginStockTake = () => { this.setState({showScan: true}) }

    handleAddQty = (id, qty) => {
        this.setState({
            details: _.map(this.state.details, i => {
                if(i.std_id === id) {
                    i.std_taken_stock = qty;
                    i.taken           = 1;
                }
                return i;
            })
        })
    }

    handleCheckReScan = id => {
        this.setState({
            details: _.map(this.state.details, i => {
                if(i.std_id === id) i.reScan = !i.reScan;
                return i;
            })
        })
    }

    getColumns = () => {
        let ret = [
            {
                field: rowData => 
                    <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {rowData && rowData.part.default_image?.thumbnail_file_path ?
                            <Button onClick={() => this.loadPartImage(rowData)}>
                                <img src={rowData.part.default_image?.thumbnail_file_path} alt={rowData.part.part_description} style={{maxHeight:35, maxWidth:35}} />
                            </Button> 
                        : 
                            <img src={logo} alt="logo" style={{maxHeight:35, maxWidth:35}}/>
                        }
                    </div>,
                sizeToContent: true,
                alignment: 'center',
            },
            {
                heading: 'Number',
                field: rowData => rowData.part.part_number,
                important: true,
                sizeToContent: true
            },
            {
                heading: 'Description',
                field: rowData => rowData.part.part_description,
                important: true,
                main: true,
                truncateXs: 150,
            },   
        ];
        if (_.find(this.state.details, i => i.std_taken_stock !== null)) {
            ret = [
                ...ret,
                {
                    heading: 'Stock Taken',
                    field: rowData => rowData.std_taken_stock ? parseFloat(rowData.std_taken_stock) : null,
                    sizeToContent: true,
                    alignment: 'center',
                },
                {
                    heading: 'Re-Scan?',
                    field: rowData => 
                        rowData.std_taken_stock !== null &&
                            (rowData.std_skip_recheck ?
                                'Approved' :
                                <Checkbox
                                    name="editNominal"
                                    checked={rowData.reScan}
                                    onChange={()=>{this.handleCheckReScan(rowData.std_id)}}
                                    value={rowData.reScan}
                                    color="primary"
                                    tabIndex="-1"
                                />
                            ),
                    alignment: 'center',
                }
            ]
        }
        return ret;
    }

    goBack = () => {
        this.props.history.push(`/stock/take`);
    }

    render(){
        const { stockTake, formData, isLoading, access, details, showScan } = this.state;

        if (isLoading) return (<LoadingCircle />);
        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12}>
                <PaddedPaper>
                        <Grid container xs={12}>
                            <Grid container item spacing={3} xs={12}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Stock Take:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                        {stockTake.full_reference}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3} xs={12}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Date:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                        {clenyDate(stockTake.st_date)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                {details.length > 0 &&
                    <>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <DataTable
                                    config={{
                                        key: 'idx',
                                        alternatingRowColours: true,
                                        responsiveimportant: true,
                                        isLoading: isLoading,
                                        style: rowData => ({
                                            color: rowData.std_skip_recheck && colors.grey
                                        })
                                    }}
                                    columns={this.getColumns()}
                                    rows={_.orderBy(details, i => i.part_number)}
                                />
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <div className='buttonRow'>
                                    <BackButton />
                                    {_.filter(details, i => i.reScan == 1 || i.std_taken_stock === null).length > 0 &&
                                        <Button variant='contained' color='primary' onClick={this.beginStockTake}>Begin Stock Take</Button>
                                    }
                                    {!_.find(details, i=>i.std_taken_stock === null) && access.submit &&
                                        <Button onClick={()=>{
                                            this.props.deployConfirmation('Are you sure you want to submit this for review?', 'Submit Stock Take',this.submit)
                                        }} variant="contained" color="primary">Submit For Review</Button>
                                    }
                                </div>
                            </PaddedPaper>
                        </Grid>
                    </>
                }
                <Dialog
                    open={showScan}
                    fullScreen
                >
                    <DialogContent>
                        <ScanDialog
                            details=        {_.orderBy(_.filter(details, i => i.reScan == 1 || i.std_taken_stock === null), [i => i.part_number],['desc'])}
                            onComplete=     {this.completeScan}
                            itemScanned=    {this.handleAddQty}
                            save=           {this.save}
                            access=         {access}
                            notes=          {formData.notes}
                            stockTake=      {stockTake}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.partImageDialog}
                    onClose={this.handlePartImageDialogClose}
                    scroll="body"
                    disableBackdropClick	
                    disableEscapeKeyDown
                >                
                    <DialogContent>
                        <ImageWithError src={this.state.partImageDialogData} alt='Part Image' style={{maxWidth: 500}}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{
                            this.setState({partImageDialog: false})
                        }} variant="outlined" color="default">Close</Button>
                    </DialogActions>
                </Dialog>  
            </Grid>
        )
    }

}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                               => dispatch(closeDialog()),
    deployDialog:       (content, title, variant, size)  => dispatch(deployDialog(content, title, variant,size)),
    deployConfirmation: (message, title, success)        => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)                   => dispatch(deploySnackBar(variant, msg)),
})

export default connect(null, mapDispatchToProps)(ManualStockTake);