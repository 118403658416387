import React, { Component } from 'react';
import { connect } from 'react-redux';
import API from 'API';
import _ from 'lodash';
import moment from 'moment';

import { colors } from 'Helpers/ColourHelper';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';
import BatchProcessPayments from 'Components/Sales/Order/OrderInvoice/BatchProcessPayments';
import OrderSearchBlock from 'Components/Sales/Order/OrderSearch/OrderSearchBlock';

import {
    Button,
    Grid,
    Typography,
    Tooltip,
    IconButton
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';
import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistenceFunctions';
import JobSnapshot from 'Components/Engineers/Jobs/Misc/JobSnapshot';
import 'Assets/timeLine.css';

const initialState = () => ({
    formData:   {
        searchString: '',
        dateFrom: null,
        dateTo: null,
        type: 'All'
    },
    formErrors: {},
    data:       {},
    access:     {},
    isLoading:  false,
});

const styles = theme => ({
    custName: {
        '&:hover':{cursor: 'pointer'}
    }
});

class RecurringInvoicesPayment extends Component  {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `invoices:rip:${this.props.cid}`;
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.getAccess();
        this.getData();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/customer-order-search'),
        ])
        .then(([accessRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    customerOrderSearch: (accessRes?.data?.has_access) || false,
                }
            });
        })
    }

    handleSetSearch = props => {
        this.setState({formData: props},this.getData)
    }

    getData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/sales/orders/awaitingInvoice', {params: {
                keywords: this.state.formData.keywords,
                dateFrom: this.state.formData.dateFrom !== null ? moment(this.state.formData.dateFrom).format('YYYY-MM-DD') : null,
                dateTo: this.state.formData.dateTo !== null ? moment(this.state.formData.dateTo).format('YYYY-MM-DD') : null,
                slim:1
            }})
            .then(orders => {
                this.setState({
                    ...this.state,
                    data: _.map(_.reject( orders.data, i => { return !i.ir  } ), i => {i.checked = 0; return i}),
                    isLoading: false
                }, () => { this.props.updateTabs(); this.savePageState();});
            });
        });
    }

    handleDownloadOpen = orderId => this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md');
    handleEmailOpen    = orderId => this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />, 'Email Previous Documents', '', 'md');

    handleProcessCheck = id => {
        let data = this.state.data
        let indx = _.findIndex(data, i => i.i == id);
        data[indx].checked = !data[indx].checked;
        this.setState({data});
    }

    handleCheckAll = () => {
        let data = this.state.data;
        _.map(data, i => {
            i.checked = 1;
        });
        this.setState({data});
    }

    render() {
        const { formData, access, formErrors, isLoading, data } = this.state;
        const { classes } = this.props;

        return (            
            <>
                <Grid container item spacing={2}>
                    <Grid item xs={12} lg={!!_.find(this.state.data, i => i.checked) ? 9 : 12} style={{paddingTop:'1.8em'}}>
                        <OrderSearchBlock disableSites disableStatus callBack={this.handleSetSearch} formData={this.state.formData}/>
                    </Grid>
                    {!!_.find(this.state.data, i => i.checked) &&
                        <Grid item xs={12} lg={3}>
                            <PaddedPaper style={{marginTop: 20}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        Selected
                                    </Grid>
                                    <Grid item xs={6}>
                                        {_.filter(this.state.data, i => i.checked).length}
                                    </Grid>
                                    <Grid item xs={6}>
                                        Total
                                    </Grid>
                                    <Grid item xs={6}>
                                        £{parseFloat(_.sumBy(_.filter(this.state.data, i => i.checked),  i => { return i.t.s - i.t.p })).toFixed(2)}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button fullWidth variant='contained' color='primary'
                                            onClick={()=>{this.props.deployDialog(<BatchProcessPayments callback={this.getData} orders={_.map(_.filter(this.state.data, i => i.checked ), i => {return i.i})}
                                            callBack={this.getData} />,'Process Payments', '', 'xs')}}
                                        >Process Payments</Button>
                                    </Grid>    
                                </Grid>    
                            </PaddedPaper>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container spacing={3}>
                                <Grid item xs={9}>
                                    <Typography variant="h5">
                                        <b>Recurring Invoices Awaiting Payment</b>
                                        <br></br>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    {!!this.state.data.length && <Button style={{float:'right'}} onClick={this.handleCheckAll} variant="contained" color="primary">Check All</Button>}
                                </Grid>
                            </Grid>
                            {!this.state.data.length && [<br></br>,<br></br>]}
                            <DataTable  
                                config={{
                                    key: 'invoice_id',
                                    pagination: true,
                                    alternatingRowColours: true,
                                    responsiveimportant: true,
                                    isLoading: this.state.isLoading
                                }}
                                columns={[
                                    {
                                        heading: 'Order Date',
                                        field: rowData => moment(rowData.od).format("DD/MM/YYYY"),
                                        important: true,
                                        dataRef: 'od',
                                    },
                                    {
                                        heading: 'Paid',
                                        dataRef: rowData => rowData.aop?.ta == rowData.t.s,
                                        field: rowData => {
                                            return rowData.aop?.ta == rowData.t.s ? 
                                            <AllIcon icon='check' style={{color:colors.green}}/> : <AllIcon icon='times' style={{color:colors.red}}/>;
                                        },
                                    },
                                    {
                                        actionsCustomHeader:    true,
                                        important:              true,
                                        alignment:              'left',
                                        heading:                <>Reference <br/></>,
                                        dataRef: 'r.f',
                                        actions: rowData => {
                                            return [
                                                { label: rowData.r.f,                           link: `/sales/order/view/${rowData.oi}`,                                            type:'plainLink' },
                                                { label: rowData.r?.q,                          link: rowData.qi ? `/sales/quotation/view/${rowData.qi}`: null,                     type:'plainLink', class:"textSecondary"}
                                            ]
                                        }
                                    },
                                    {
                                        heading:                <>Invoice(s) <br/></>,
                                        dataRef: rowData => rowData.in.map('invoice_number').join('|'),
                                        field: rowData =>  <>
                                                            {rowData.in.map((item, key) => {
                                                                return  <><Typography variant="caption" key={key}>
                                                                    {item.invoice_number} (Sage: {item.sn}) - {item?.c ? item?.cn : 'No Despatch'}
                                                                </Typography><br /></>
                                                            })}
                                                            </>,
                                        important: true,
                                        sizeToContent: true,
                                        main: true
                                    },
                                    {
                                        heading:                <>Customer <br/></>,
                                        dataRef: 'cn',
                                        field: rowData => <Tooltip
                                                            title={
                                                                <Typography variant="body2">
                                                                    <b>Delivery Address:</b><br />
                                                                    {rowData.d.a.split('\n').map((item, key) => {
                                                                        return  <span key={key}>
                                                                                    {item}{rowData.d.a.split('\n').length === key+1 ? null : <br/>}
                                                                                </span>
                                                                    })}
                                                                </Typography>
                                                            }
                                                            placement="left"
                                                        >
                                                            <div className={classes.custName} onClick={()=>{ this.props.history.push(`/customers/view/${rowData.c}`); }}>
                                                                {rowData.cn}
                                                                <Typography component="div" variant="body2">{rowData.d.n}</Typography>
                                                            </div>
                                                        </Tooltip>,
                                        important:      false,
                                        sizeToContent:  true,
                                        dataRef:        'cn',
                                        truncate:       true,
                                    },
                                    {
                                        heading: 'Order Total',
                                        field: rowData => rowData.t.t,
                                        important: true,
                                        alignment: 'right',
                                        fieldPrefix: '£',
                                        dataRef: 't.t',
                                    },
                                    {
                                        heading: 'No. of Invoices',
                                        field: rowData => rowData.in.length,
                                        important: true,
                                        alignment: 'center',
                                        dataRef: rowData => rowData.in.length,
                                    },
                                    {
                                        heading: 'Invoice Payments Total',
                                        field: rowData => rowData?.t.p != null ? '£'+rowData?.t.p : '-',
                                        important: true,
                                        alignment: 'right',
                                        dataRef: 't.p',
                                    },
                                    {
                                        heading: 'Invoices Total',
                                        field: rowData => rowData.t.s,
                                        important: true,
                                        alignment: 'right',
                                        fieldPrefix: '£',
                                        dataRef: 't.s',
                                    },
                                    {
                                        heading: 'Jobs',
                                        field:      rowData => <>
                                            {rowData?.ej && 
                                                <Tooltip
                                                    title={<JobSnapshot jobRef={rowData?.ej?.ref} forms={rowData?.ej?.f} />}
                                                    placement="left"
                                                >
                                                    <IconButton className={classes.iconButton} onClick={() => this.props.history.push(`/engineers/jobs/view/${rowData.ej?.id}`) }>
                                                        <AllIcon 
                                                            icon={`user-helmet-safety`}
                                                            button
                                                            noMargin
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </>,
                                        alignment: 'center',
                                        sizeToContent: true,
                                        important: false
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: 'View', icon: 'search', link: '/sales/order/invoice/payments/' + rowData.oi},
                                                {type: 'checkBox', checked: rowData.checked, onCheck: ()=>{this.handleProcessCheck(rowData.i)}, disabled: (rowData.t.t == 0.00 || (rowData.ch.d || rowData.ch.p ) || rowData.in.length > 1 )   },
                                                {
                                                    type: 'actionMenu',
                                                    rows: [
                                                        {
                                                            icon: "download",
                                                            label: "Download",
                                                            onClick: () => this.handleDownloadOpen(rowData.oi),
                                                            display: true
                                                        },
                                                        {
                                                            icon: "envelope",
                                                            label: "Send to Customer",
                                                            onClick: () => this.handleEmailOpen(rowData.oi),
                                                            display: true
                                                        },
                                                        {
                                                            icon: "file-search",
                                                            label: "View this Customers Orders",
                                                            onClick: () => this.props.history.push(`/customers/view/${rowData.c}/Orders`),
                                                            display: true,
                                                            disabled: !access.customerOrderSearch
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    }
                                ]}
                                rows={data}
                            />
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                    => dispatch(setPersistence(key, state)),
    deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:        ()                                                         => dispatch(closeDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RecurringInvoicesPayment));