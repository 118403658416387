import * as printActionTypes from 'Actions/Print/Types/Types';

import uuidv4 from 'uuid/v4';

 const initialState = {    
    key:     uuidv4(),
    open:    false,
    printer: null,
    url:     null,
    file:    null,
    multi:   []
};

function PrintReducer(state = initialState, action) {
    switch (action.type) {
        case printActionTypes.PRINT:
            return {
                ...initialState,
                key:      uuidv4(),
                open:     true,
                printer:  action.payload.printer,
                url:      action.payload.url,
                file:     action.payload.file,
                fileName: action.payload.fileName
            };
        case printActionTypes.PRINT_MULTI:
            return {
                ...initialState,
                key:     uuidv4(),
                open:    true,
                multi:   action.payload
            }
        case printActionTypes.PRINT_CLOSE:
            return initialState;
        default: return state;
    }
}

 export default PrintReducer;