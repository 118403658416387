import React from 'react';
import API from 'API';
import _ from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';

import { Button, Grid, TextField, Typography } from '@material-ui/core';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';

import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = () => ({
    isLoading: true,
    uploading: false,
    collection: {},
    formData: {
        condition:  null,
        received:   null,
        faultNotes: '',
        files:      [],
        faultNotesAfter: '',
        filesAfter:      [],
        serialNumbers: [],
        warrantyLabels: []
    },
    formErrors: {},
    returnConditions: [],
})

class InspectCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {...initialState(), collection: props.collection};
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        Promise.all([
            API.get(`/customerReturns/returns/returnConditions`),
        ]).then(res => {
            this.setState({ 
                returnConditions: _.map(_.orderBy(res[0].data, [r => {return {pass: 0,  warning: 1, Fail:2}[r.crrc_grade]}, 'crrc_condition'], ['asc', 'asc']), i => ({value: i.crrc_id, label: `(${i.crrc_grade === 'pass' ? 'Pass' : i.crrc_grade === 'warning' ? 'Pass With Warning' : 'Fail'}) - ${i.crrc_condition}`})),
                isLoading: false 
            });
        })
        this.setState({ isLoading: false });
    }

    handleSelectChange = name => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: e.value
            }
        })
    }

    handleMultiSelectChange = name => e => {
        let values = e ? e.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: values
            }
        }, ()=>{
            if (name === 'serialNumbers' || name === 'warrantyLabels'){
                this.setState({
                    formData: {
                        ...this.state.formData,
                        received: this.state.formData[name].length
                    }
                })
            }
        })
    }

    handleChange = name => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: e.target.value
            }
        })
    }

    handleBlur = (name, dp=3) => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: parseFloat(e.target.value).toFixed(dp)
            }
        })
    }

    handleUploads = type => (drop, name, event) => {
        let newFiles = drop === true ? event.dataTransfer.files : event.target.files;
        let existingFiles = this.state.formData[type];
        let allFiles = Array.prototype.slice.call(existingFiles).concat(Array.prototype.slice.call(newFiles))
        this.setState({
            formData: {
                ...this.state.formData,
                [type]: allFiles
            }
        });
    }

    clearUploads = type => idx => {
        let currentFiles = Array.from(this.state.formData[type]);
        currentFiles.splice(idx, 1);
        if(currentFiles.length === 0) currentFiles = '';

        this.setState({
            formData:{
                ...this.state.formData,
                [type]: currentFiles
            }
        });
    }

    submit = () => {
        this.setState({ uploading: true }, ()=>{
            let { formData } = this.state;
            let newformData = new FormData();

            _.forEach(_.keys(formData), key => {
                if (key === 'files'){
                    _.forEach(formData[key], file => {
                        newformData.append('files[]', file);
                    })
                } else if (key === 'filesAfter'){
                    _.forEach(formData[key], file => {
                        newformData.append('filesAfter[]', file);
                    })
                } else if ( key === 'serialNumbers' || key === 'warrantyLabels' ) {
                    newformData.append(key, JSON.stringify(formData[key]));
                } else {
                    newformData.append(key, formData[key]);
                }
            })

            newformData.append('crcd_id', this.state.collection.crcd_id);

            API.post(`/customerReturns/returns/${this.state.collection.cr_id}/inspect`, newformData)
            .then(res => {
                if(res.data.errors && res.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(res.data.errors),
                    });
                } else {
                    this.props.onClose();
                }
            });
        });
    }

    render() {

        const { collection, isLoading, formData, formErrors, returnConditions, uploading } = this.state;
        const { cr } = this.props;

        if (isLoading || uploading) return ( 
            <Grid container spacing={3} style={{justifyContent: 'center'}}>
                <Grid item xs={12} style={{height: '100px'}}></Grid>
                <Grid item xs={12}>
                    <LoadingCircle
                        message={uploading ? 'Uploading Files...' : 'Just Washing...'}
                    />
                </Grid>
            </Grid>
         )

        return (
            <Grid container spacing={3} style={{justifyContent: 'center'}}>
                <Grid item xs={12} style={{height: '100px'}}></Grid>
                <Grid item xs={8} style={{textAlign: 'center'}}>
                    <ImageWithError
                        src={collection.part?.default_image?.file_path}
                        style={{maxWidth: '100%', maxHeight: '20vh', height: 'auto', width: 'auto'}}
                    />
                </Grid>
                {cr.cr_notes && 
                    <>
                        <Grid item xs={8} style={{textAlign: 'left'}}>
                            <PaddedPaper style={{ backgroundColor: colors.importantInfo, height: '100%', padding: 10}}>
                                <Typography variant='body1'>
                                    <b>Return Reason / Notes:</b><br/>
                                    {cr.cr_notes.split('\n').map((item, key) => <>{item}<br/></>)}
                                </Typography>
                            </PaddedPaper>
                        </Grid>
                    </>
                }
                <Grid item xs={8} style={{textAlign: 'center'}}>
                    {(collection.serialNumbers.length > 0 && 
                        <AutoCompleteMultiSelect
                            label="Serial Numbers *"
                            options={collection.serialNumbers.map(a => ({value: a, label: a}))}
                            value={formData.serialNumbers}
                            onChange={this.handleMultiSelectChange('serialNumbers')}
                            fullWidth
                            noClear
                        />
                    ) || 
                        ((collection.warrantyLabels.length > 0 &&
                            <AutoCompleteMultiSelect
                                label="Warranty Void Labels *"
                                options={collection.warrantyLabels.map(a => ({value: a, label: a}))}
                                value={formData.warrantyLabels}
                                onChange={this.handleMultiSelectChange('warrantyLabels')}
                                fullWidth
                                noClear
                            />  
                        )
                      ||
                        <TextField
                            label={`Received * ( Remaining: ${parseFloat(collection.remaining).toFixed(3)} )`}
                            fullWidth
                            type='number'
                            value={formData.received}
                            onChange={this.handleChange('received')}
                            onBlur={this.handleBlur('received', 3)}
                            inputProps={{
                                min: 0, 
                                max: parseFloat(collection.remaining)
                            }}
                        />
                    )
                    }
                </Grid>
                {parseFloat(formData.received) > 0 && parseFloat(formData.received) < parseFloat(collection.remaining) &&
                    <Grid item xs={8} style={{textAlign: 'center'}}>
                        <Typography variant="body1" style={{color: colors.red}}>
                            Note: A further {parseFloat(parseFloat(collection.remaining) - parseFloat(formData.received)).toFixed(3)} will need inspecting
                        </Typography>
                    </Grid>
                }
                <Grid item xs={8} style={{textAlign: 'center'}}>
                    <Button 
                        fullWidth 
                        onClick={()=>this.props.deployConfirmation('Are You sure you want to submit this part delivery?','Submit Delivery',this.submit)} 
                        color='primary' 
                        variant='contained'
                        disabled={ 
                            !(parseFloat(formData.received) > 0) || 
                            parseFloat(formData.received) > parseFloat(collection.remaining)
                        }    
                    >Submit</Button>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
});

export default connect(null, mapDispatchToProps)(InspectCollection);
