import { Button, Grid } from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import FormView from 'Components/Common/Forms/FormView/FormView';
import IconHelper from 'Helpers/IconHelper';
import React from 'react';
import { connect } from 'react-redux';
import { colors } from 'Helpers/ColourHelper';
import _ from 'lodash';

const initialState = () => ({
    formData: {
        toSend: [],
    }
})

class CompleteContacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    selectAll = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                toSend: this.props.contacts.filter(i=>!i.contact.contact_email_opt_out).map(i => i.sc_id)
            }
        })
    }

    sendMulti = () => {
        this.#send(this.state.formData.toSend);
    }

    sendSingle = (contact) => () => {
        this.#send([contact]);
    }

    #send = (contacts) => {
        API.post(`/survey/${this.props.survey.survey_id}/send`, { contacts: JSON.stringify(contacts) }).then(res => {
            this.props.refresh();
        })
    }

    render() {

        const { contacts } = this.props;

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CiDataTable
                        config={{
                            key: 'sc_id',
                            pagination: contacts.length > 50,
                            rowsPerPage: 50,
                        }}
                        rows={_.orderBy(contacts, ['form.form_updated_datetime'], ['desc'])}
                        columns={[
                            {
                                heading: 'Name',
                                field: 'contact.contact_name',
                                dataRef: 'contact.contact_name',
                                sizeToContent: true
                            },
                            {
                                heading: 'Email',
                                field: 'contact.contact_email',
                                dataRef: 'contact.contact_email',
                            },
                            {
                                heading: 'Customer',
                                field: 'contact.customer.cust_name',
                                dataRef: 'contact.customer.cust_name',
                                truncate: true
                            },
                            this.props.survey.form.linked_parts.length > 0 &&
                            {
                                actionsCustomHeader:    true,
                                important:              true,
                                alignment:              'left',
                                heading:                'Order',
                                actions: i => ([
                                    { label: `${i.invoice.order.order_reference}-${i.invoice.order.order_reference_amendment}`,         link: ()=>{window.open(`/sales/order/view/${i.invoice.invoice_order_id}`)},         type:'plainLink'},
                                    ...(i?.invoice?.order?.credit_notes?.length > 0 ? i.invoice.order.credit_notes.map(creditNote => ({ label: `${creditNote.cn_reference}-0${creditNote.cn_reference_amendment}`, link: ()=>{window.open(`/returns/creditnote/${creditNote.cn_id}`)}, type:'plainLink', color: colors.red})) : [])
                                ])
                            },
                            {
                                heading: 'Created Date',
                                field: 'form.form_created_datetime',
                                fieldFormat: 'clenydate',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'Completed Date',
                                field: 'form.form_updated_datetime',
                                fieldFormat: 'clenydate',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                actions: i => ([
                                    {
                                        name: 'Sticky Notes',
                                        icon: IconHelper.stickyNote,
                                        onClick: this.props.showStickyNotes,
                                        color: i.sticky_notes_count > 0 ? colors.yellow : 'inherit'
                                    },
                                    {
                                        name: 'View Survey',
                                        icon: IconHelper.survey,
                                        linkExternal: i.form.adminExternalLink
                                    },
                                    {
                                        name: 'View Answers',
                                        icon: 'clipboard-list',
                                        onClick: ()=>this.props.deployDialog(
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} style={{marginTop: 16}}>
                                                    <FormView formId={this.props.survey.survey_form_id} displayForm={i.form.form_id} />
                                                </Grid>
                                                <Grid item xs={12} className='buttonRow'>
                                                    <Button variant='outlined' onClick={()=>this.props.closeDialog()}>Close</Button>
                                                </Grid>
                                            </Grid>,
                                            this.props.uuidReplace(i.form.form_uuid)
                                        )
                                    },
                                    {
                                        name: 'View Contact',
                                        icon: IconHelper.view,
                                        link: `/customers/contacts/view/${i.contact.contact_id}`,
                                    }
                                ])
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback, variant=null) => {
            dispatch(deployConfirmation(content, title, callback, null, variant))
        },
        deployDialog: (content, title, size='lg') => {
            dispatch(deployDialog(content, title, 'system', size))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        }
    }
}

export default connect(null, mapDispatchToProps)(CompleteContacts);