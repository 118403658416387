import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import NotificationBadge from 'Components/Common/Badges/NotificationBadge';
import Notification from 'Components/Layout/Misc/Notification';
import ReminderDialog from 'Components/Reminders/Misc/ReminderDialog';
import TaskDialog from 'Components/Tasks/Misc/TaskDialog';
import { deployDialog } from 'Actions/Dialog/Dialog';
import { putNotificationStatus } from 'Actions/Notifications/Notifications';
import {closePopNotifications} from 'Actions/PopNotification/PopNotification';
import { dismissAllNotifications } from 'Actions/Notifications/Notifications';

const styles = theme => ({
    list: {
        padding: 0
    },
})

const initialState = {
    anchorEl: null
}

class AdminNotifications extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleAction = (id, action) => {
        /* Remember to change the handleNotificationAction function in NotificationList.js */
        this.props.putNotificationStatus(id, "read");
        this.handleClose();
        action = action.split(":");
        switch(action[0]) {
            case "reminder":
                if(action[1] && !isNaN(action[1])) {
                    this.props.deployDialog(<ReminderDialog id={action[1]} />, "", "standard", "md")
                }
            break;
            case "task":
                if(action[1] && !isNaN(action[1])) {
                    this.props.deployDialog(<TaskDialog id={action[1]} />, "", "standard", "md")
                }
            break;
            case "url":
                this.props.history.push(action[1]);
            break;
            default:
        }
    }

    handleDismiss = (id, e) => {
        e.stopPropagation();
        this.props.putNotificationStatus(id, "dismissed");
    }
    
    handleClick = event => {
        this.props.closePopNotifications();
        this.setState({ anchorEl: event.currentTarget });
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    }

    handleNotificationDismissAll = () => {
        this.props.dismissAllNotifications();
    }

    render() {
        const { classes } = this.props;
        const { anchorEl } = this.state;
        let { notifications, status } = this.props;
        notifications = _.sortBy(notifications, function(el) { return el.i });
        if(!_.isEmpty(notifications)) {
            notifications.reverse()
        }
        return (
            <React.Fragment>
                <Tooltip title="New Notifications">
                    <IconButton
                        aria-controls='notification-menu'
                        aria-haspopup="true"
                        disabled={!notifications}
                        onClick={this.handleClick}
                    >
                        {(notifications && _.size(notifications) > 0 && (
                            <NotificationBadge
                                badgeContent={_.size(notifications)}
                                variant='standard'
                                color='secondary'
                                showZero={false}
                            >
                                <FASolidIcon icon="bell" noMargin buttonPrimary/>
                            </NotificationBadge>
                        )) || (
                            <FASolidIcon icon="bell" noMargin buttonPrimary/>
                        )}
                    </IconButton>
                </Tooltip>
                <Menu
                    classes={{list: classes.list}}
                    id="notification-menu"
                    anchorEl={anchorEl}
                    className="dd-menu"
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                {(!_.isEmpty(notifications) && (
                    <List disablePadding className='sm-scrollbar'>
                        {_.map(notifications, (notification, idx) => (
                            <Notification
                                key={idx}
                                data={notification}
                                handleDismiss={this.handleDismiss}
                                handleAction={this.handleAction} 
                                status={status}
                            />
                        ))}
                        <ListItem disableGutters>
                            <Grid container alignItems='center' className='menu-text'>
                                <Grid item xs={12} className='buttonRow' style={{margin:0}}>
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        onClick={
                                            () => { 
                                                this.handleClose(); 
                                                this.handleNotificationDismissAll()
                                            }
                                        }
                                    >
                                        <FALightIcon icon="xmark" buttonPrimary />
                                        Dismiss All
                                    </Button>
                                    <Button 
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        onClick={
                                            () => { 
                                                this.handleClose(); 
                                                this.props.history.push('/my-account')
                                            }
                                        }
                                    >
                                        <FALightIcon icon="search" buttonPrimary />
                                        View All
                                    </Button>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                )) || (
                    <Grid container alignItems='center' className='menu-text'>
                        <Grid item xs={12}>
                            <Typography component="div" variant="caption" paragraph>
                                You have no new notifications
                            </Typography>
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Button 
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={
                                    () => { 
                                        this.handleClose(); 
                                        this.props.history.push('/my-account')
                                    }
                                }
                            >
                                <FALightIcon icon="search" buttonPrimary />
                                View All
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Menu>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state){
    return {
        notifications: state.notifications.notifications,
        status: state.notifications.status
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployDialog: (content, title, variant, size)   => dispatch(deployDialog(content, title, variant, size)),
        putNotificationStatus: (id, action)             => dispatch(putNotificationStatus(id, action)),
        closePopNotifications: ()                       => dispatch(closePopNotifications()),
        dismissAllNotifications: () => dispatch(dismissAllNotifications())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(AdminNotifications)));