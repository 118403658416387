import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import ReCAPTCHA from "react-google-recaptcha";

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import MUIStylesProvider from '@material-ui/styles/ThemeProvider';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import API from '../../API';
import ErrorDialog from '../Common/ErrorHandling/ErrorDialog';
import FALightIcon from '../Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from '../Common/LoadingCircle/LoadingCircle';
import LoginLogo from './../../Assets/Images/login_logo.png';
import LoginBackgroundImage from './../../Assets/Images/login_bg.jpg';
import LoginBackgroundVideo from './../../Assets/Images/login.mp4';
import LoginStoredProfile from './LoginStoredProfile';
import theme from '../Theme/Theme';

import { API_URL, CLIENT_VERSION, RECAPTCHA_SITE_KEY } from '../../Constants';
import { connect } from 'react-redux';
import { formatValidationErrors } from '../../Helpers/ErrorHelper';
import {colors} from 'Helpers/ColourHelper';
import { authStaff } from '../../Actions/Auth/Auth';
import { handleAppError } from '../../Actions/UI/UI';
import {
    setToken, 
    getDecodedToken, 
    hasStoredProfile, 
    getStoredProfile, 
    setStoredProfile, 
    clearStoredProfile,
    hasLastActivePage,
    getLastActivePage,
    setLastActivePage,
    clearLastActivePage
} from '../../Functions/AuthFunctions';

const styles = theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${LoginBackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    loginLogo:{  
        width: '250px',
        height: 'auto',
        marginBottom: theme.spacing(2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        maxWidth: 500,
        marginTop: theme.spacing(1),
    },    
    error: {
        marginTop: theme.spacing(2),
        color:colors.red,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    mwl: {
        position: 'absolute', 
        bottom: 24,
    },
    copyright: {
        fontSize: '0.8em',
    },   
    rightText: {
        width: '100%',
        textAlign: 'right',
    },
    [theme.breakpoints.down('md')]: {
        rightText: {
            width: '100%',
            textAlign: 'left'
        },
    },
    [theme.breakpoints.down('sm')]: {
        form: {
            maxWidth: 320,
        },
        loginLogo: {
            width: 175,
        },
        mwl: {
            marginTop: theme.spacing(5),
        }
    }
});

const initialState = {
    appError: {
        key: uuidv4(),
        state: false,
        detail: ""
    },
    formData: {
        email: '',
        password: '',
        mfaCodeInd: {
            0: ' ',
            1: ' ',
            2: ' ',
            3: ' ',
            4: ' ',
            5: ' ',
        },
        mfaCode: '',
        storeProfile: false,
        googleRecaptchaToken: ''
    },
    formErrors: [],
    disableSubmit: false,
    storedProfile: false,
    isLoading: false,
    isMobile: false,
    ipBanned: false,
    networkOffline: !navigator.onLine,
    apiOffline: false,
    action: 'login',
    scheduledUpdateTime: false,
    scheduledUpdateTimeCountdown: false
}

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.versionCheck = false;
        this.googleRecaptcha = React.createRef();
    }

    componentDidMount() {

        this.versionCheck = setInterval(this.startVersionCheck, 20000);

        this.mfaCode0 = React.createRef();
        this.mfaCode1 = React.createRef();
        this.mfaCode2 = React.createRef();
        this.mfaCode3 = React.createRef();
        this.mfaCode4 = React.createRef();
        this.mfaCode5 = React.createRef();

        if(this.state.networkOffline === false){
            this.apiHealthCheck();
        }

        this.setState({
            scheduledUpdateTime: this.props.ui.updateNotification
        }, () => {                
            if(this.state.scheduledUpdateTime !== false) {
                this.startScheduledUpdateTime();
            }
        });

        if(hasStoredProfile()){
            const storedProfile = getStoredProfile();
            if(storedProfile.active || storedProfile.temp){
                this.setState({
                    storedProfile: storedProfile,
                    formData: {
                        ...this.state.formData,
                        email: storedProfile.email,                        
                        storeProfile: storedProfile.active,
                    },
                    isMobile: this.props.ui.device.isMobile
                });
            }
        } else {
            this.setState({
                isMobile: this.props.ui.device.isMobile
            });
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.ui.device.isMobile !== prevProps.ui.device.isMobile){
            this.setState({
                isMobile: this.props.ui.device.isMobile
            });
        }
        if(this.props.ui.updateNotification !== prevProps.ui.updateNotification) {
            this.setState({
                scheduledUpdateTime: this.props.ui.updateNotification
            }, () => {                
                if(this.state.scheduledUpdateTime !== false) {
                    this.startScheduledUpdateTime();
                }
            });
        }        
    }

    componentWillUnmount = () => {
        if(this.countdown)       
            clearInterval(this.countdown);
        if(this.versionCheck)
            clearInterval(this.versionCheck);
    }

    startScheduledUpdateTime = () => {
        this.countdown = setInterval(this.getScheduledUpdateTimeCountdown, 1000);
    }

    getScheduledUpdateTimeCountdown = () => {        
        var scheduled = moment(this.state.scheduledUpdateTime, 'X').subtract(10, 'seconds');
        const now = moment();
        if(now.unix() < scheduled.unix()) {
            const countdown = moment(scheduled - now);
            const mins = parseInt(countdown.format('m'));
            const countdownText = mins !== 0 ? countdown.format("m [ minutes] [and] s [ seconds]") : countdown.format("s[ seconds]");
            this.setState({                
                scheduledUpdateTimeCountdown: this.state.updateNotification !== false ? countdownText : ''
            });
        } else {            
            if(this.countdown) {
                clearInterval(this.countdown);
            }
            
            if(this.state.appError.state !== "APP_UPDATE") {

                setLastActivePage('/login');

                this.setState({                
                    scheduledUpdateTimeCountdown: '',
                    appError: {
                        ...this.state.appError,
                        state: "APP_UPDATE",
                        detail: ""
                    }
                })    

            }
        }
    }   
            
    apiHealthCheck = () => {
        fetch(API_URL+'/health-check')
        .then(result => {
            if(result.status === 200) {
                if(this.state.apiOffline === true) {
                    this.setState({
                        apiOffline: false,
                    });
                }
            } else {
                if(this.state.apiOffline === false){
                    this.setState({
                        apiOffline: true,
                    });
                }
            }
        }).catch(() => {
            if(this.state.apiOffline === false) {
                this.setState({
                    apiOffline: true,
                });
            }
        });
    }    

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        })
    }

    handleCheckedChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.checked
            }
        });
    }
    
    handleClearStoredProfile = () => {
        clearStoredProfile();
        this.setState({
            storedProfile: false,
            action: 'login'
        }, () => document.getElementById('email').focus());
    }

    handleCloseErrorDialog = () => {
        this.setState({
            appError: { 
                ...this.state.appError,
                state: false
            }
        })
    }

    handleMfa = (e) => {
        if(e) {
            e.preventDefault();
        }
        const mfaCode = this.state.formData.mfaCodeInd;
        this.setState({
            formData: {
                ...this.state.formData,
                mfaCode: mfaCode[0]+mfaCode[1]+mfaCode[2]+mfaCode[3]+mfaCode[4]+mfaCode[5]
            }            
        }, () => this.handleSubmit());
    }
    
    handleMfaChange = (id, e) => {
        if(id === 0 && e.target.value.length >= 1) {
            this.mfaCode1.current.focus();
        } else if(id > 0 && id < 5 && e.target.value.length >= 1) {
            this['mfaCode'+(id+1)].current.focus();            
        } else if(id > 0) {            
            this['mfaCode'+(id-1)].current.focus();  
        }
        this.setState({
            formData: {
                ...this.state.formData,
                mfaCodeInd: {
                    ...this.state.formData.mfaCodeInd,
                    [id]: e.target.value.length > 0 ? (e.target.value.trim().length === 1 ? e.target.value.trim() : e.target.value.trim()[1]) : ' '
                },
            }
        }, () => {
            if(id === 5 && this.state.formData.mfaCodeInd[5] !== ' ') { 
                this.handleMfa(false);
            }
        });
    }

    handleNetworkOnline = () => {
        if(this.state.networkOffline === true) {
            if(hasStoredProfile()){
                const storedProfile = getStoredProfile();
                if(storedProfile.active || storedProfile.temp){
                    this.setState({
                        storedProfile: storedProfile,
                        formData: {
                            email: storedProfile.email,
                            storeProfile: storedProfile.active,
                        },
                        networkOffline: false,
                    }, () => this.apiHealthCheck());
                }
            } else {
                this.setState({
                    networkOffline: false,
                }, () => this.apiHealthCheck());
            }
        }
    }
    
    handleNetworkOffline = () => {
        if(this.state.networkOffline === false) {
            this.setState({
                networkOffline: true,
                formData: {
                    email: '',
                    password: '',
                    googleRecaptchaToken: '',
                },
            });
        }
    }

    handleRecaptcha = async () => {
        const googleRecaptchaToken = await this.googleRecaptcha.current.executeAsync();
        this.setState({
            formData: {
                ...this.state.formData,
                googleRecaptchaToken
            },
            disableSubmit: true,
        },
        () => { 
            this.handleSubmit()
        });
    }

    handleRetryIpBan = () => {
        this.setState({
            ipBanned: false
        })
    }
    
    handleSubmit = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.post('/auth/login', this.state.formData)
            .then((result) => {
                if(result.data.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        disableSubmit: false,
                        isLoading: false,
                        formData: {
                            ...this.state.formData,
                            mfaCode: '',
                            mfaCodeInd: {
                                0: ' ',
                                1: ' ',
                                2: ' ',
                                3: ' ',
                                4: ' ',
                                5: ' '
                            }
                        }
                    }, () => {
                        if(this.state.formErrors['mfaCode']){
                            this.mfaCode0.current.focus();
                        }
                    });
                } else if(result.data.ip_locked) {
                    this.setState({
                        action: 'login',
                        ipBanned: true,
                        isLoading: false,
                        disableSubmit: false,
                        formErrors: [],
                    });
                } else if(result.data.mfa_required) {
                    this.setState({
                        action: 'mfa',
                        isLoading: false,
                        disableSubmit: false,
                        formErrors: [],
                    }, () => this.mfaCode0.current.focus());
                } else {
                    setToken(result.data.token);
                    // Get Profile Picture and check if password needs changing
                    Promise.all([ 
                        API.get('/staff/my/profilePhoto', {
                            params: {
                                asImage: true,
                                asBase64: true
                            }
                        }), 
                        API.get('/staff/my/password/requiresUpdate')
                    ])
                    .then(([photoRes, viewRes]) => {
    
                        const profile = getDecodedToken();
                        var iOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
                        setStoredProfile({
                            active: this.state.formData.storeProfile ? true : false,
                            temp: false,
                            firstName: profile.firstName,
                            lastName: profile.lastName,
                            email: profile.email,
                            avatar: iOS ? '' : (photoRes.data ? photoRes.data : ''),
                        });                            
    
                        if(viewRes.data){
                            if(viewRes.data.requires_update){
                                this.props.handleAppError("PASSWORD_UPDATE", "");
                            }
                        }

                        this.props.authStaff(result.data.token, photoRes.data ? photoRes.data : '');
                        
                        if(hasLastActivePage()){
                            const path = getLastActivePage();
                            clearLastActivePage();
                            this.props.history.replace(path);
                        } else {
                            this.props.history.replace('/dashboard'); // Redirect to Dashboard 
                        }
                    });
                }
            })
        })
    }  

    startVersionCheck = () => {
        API.get('/notifications', {
            props: {
                noLoading: true,
                ignoreErrors: true
            }
        });
    }

    render() {
        const { classes } = this.props
        const { action, apiOffline, appError, formData, formErrors, ipBanned, isLoading, isMobile, networkOffline, storedProfile, scheduledUpdateTime, scheduledUpdateTimeCountdown } = this.state;
        const LoginVideo = this.state.isMobile ? '' : LoginBackgroundVideo;        
        window.addEventListener('online', () => this.handleNetworkOnline());
        window.addEventListener('offline', () => this.handleNetworkOffline());
        return (
            <MUIStylesProvider theme={theme}>
                <CssBaseline />
                {!isMobile && window.innerHeight < window.innerWidth && (
                    <Grid container>
                        <Grid item md={12}>
                                <video style={{padding: 0, margin: 0, position: 'fixed', zIndex:-1}} loop autoPlay muted>
                                    <source src={LoginVideo} type="video/mp4" />
                                    <source src={LoginVideo} type="video/ogg" />
                                    Your browser does not support the video tag.
                                </video>
                        </Grid>
                    </Grid>
                )}           
                <Grid container component="main" className={classes.root}>
                    {!isMobile && window.innerHeight < window.innerWidth && (
                        <Grid item xs={false} md={4} />
                    )}
                    {isMobile && window.innerHeight < window.innerWidth && (
                        <Grid item xs={false} md={8} className={classes.image}/>
                    )}
                    <Grid item xs={12} md={window.innerHeight < window.innerWidth ? (isMobile ? 4 : 8) : 12} component={Paper} elevation={6} square>
                        <div className={classes.paper} style={{ zIndex: 9999 }}>
                            <img src={LoginLogo} className={classes.loginLogo} alt='Clenaware Systems' />
                            <React.Fragment>
                                {(isLoading && (
                                    <div style={{textAlign: 'center', marginTop: 'calc(50% - 35px)'}}>
                                        <LoadingCircle size='large' />
                                    </div>
                                )) || (
                                    <React.Fragment>
                                        {(scheduledUpdateTime !== false && scheduledUpdateTimeCountdown !== false && (
                                            <Grid container spacing={1} style={{maxWidth: 500,}} align='center'>
                                                <Grid item xs={12}>                                                    
                                                    <Typography variant="caption" component="div" style={{color: '#c62828'}} gutterBottom>
                                                        <FALightIcon 
                                                            icon="exclamation-triangle"
                                                            size="small"
                                                            style={{color: '#c62828'}}
                                                            heading
                                                        />
                                                        {(scheduledUpdateTimeCountdown !== '' && ( 
                                                            `An update ${!isMobile ? `for MyClenaware ` : ``} will begin in ${scheduledUpdateTimeCountdown}!`
                                                        )) || (
                                                            <React.Fragment>
                                                                An update for MyClenaware is currently in progress!
                                                            </React.Fragment>
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ))}
                                        {(apiOffline && (                                          
                                            <Grid container spacing={1} style={{marginTop: '20%'}}>
                                                <Grid item xs={12} align='center'>
                                                    <FALightIcon 
                                                        icon="exclamation-triangle"
                                                        size="xlarge"
                                                        style={{color: '#c62828'}}
                                                        noMargin
                                                        heading
                                                    />
                                                </Grid>
                                                <Grid item xs={12} align='center'>
                                                    <Typography variant="h6" gutterBottom>
                                                        Temporarily Unavailable
                                                    </Typography>
                                                    <Typography variant="caption" component="div" gutterBottom>
                                                        MyClenaware is currently undergoing maintenance, please try again soon!
                                                    </Typography>  
                                                    <Typography variant="caption" style={{fontSize: '0.75em'}} color="textSecondary" component="div" paragraph>
                                                        For help and support, please contact Crushed Ice on 01933 664344
                                                    </Typography>
                                                    <Typography variant="caption" style={{fontSize: '0.75em'}} color="textSecondary" component="div">
                                                        <Button
                                                            margin="normal"
                                                            variant="text"
                                                            color="secondary"
                                                            onClick={this.apiHealthCheck}
                                                        >                            
                                                            Retry
                                                        </Button>   
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )) || (networkOffline && (
                                                <Grid container spacing={1} style={{marginTop: '20%'}}>
                                                    <Grid item xs={12} align='center'>
                                                    <FALightIcon 
                                                        icon="exclamation-triangle"
                                                        size="xlarge"
                                                        style={{color: '#c62828'}}
                                                        noMargin
                                                        heading
                                                    />
                                                </Grid>
                                                <Grid item xs={12} align='center'>
                                                    <Typography variant="h6" gutterBottom>
                                                        Connectivity Issues
                                                    </Typography>
                                                    <Typography variant="caption" component="div" gutterBottom>
                                                        Please check your Internet Connection, the login form will show once you've re-connected
                                                    </Typography>  
                                                    <Typography variant="caption" style={{fontSize: '0.75em'}} color="textSecondary" component="div" paragraph>
                                                        For help and support, please contact Crushed Ice on 01933 664344
                                                    </Typography>
                                                    <Typography variant="caption" style={{fontSize: '0.75em'}} color="textSecondary" component="div">
                                                        <Button
                                                            margin="normal"
                                                            variant="text"
                                                            color="secondary"
                                                            onClick={this.handleNetworkOnline}
                                                        >                            
                                                            Retry
                                                        </Button>   
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )) || (ipBanned && (
                                            <Grid container spacing={1} style={{marginTop: '20%'}}>
                                                 <Grid item xs={12} align='center'>
                                                    <FALightIcon 
                                                        icon="exclamation-triangle"
                                                        size="xlarge"
                                                        style={{color: '#c62828'}}
                                                        noMargin
                                                        heading
                                                    />
                                                </Grid>
                                                <Grid item xs={12} align='center'>
                                                    <Typography variant="h6" gutterBottom>
                                                        IP Banned
                                                    </Typography>
                                                    <Typography variant="caption" component="div" gutterBottom>
                                                        You cannot login at this time due to multiple failed attempts from this IP Address
                                                    </Typography>  
                                                    <Typography variant="caption" style={{fontSize: '0.75em'}} color="textSecondary" component="div" paragraph>
                                                        For help and support, please contact Crushed Ice on 01933 664344
                                                    </Typography>
                                                    <Typography variant="caption" style={{fontSize: '0.75em'}} color="textSecondary" component="div">
                                                        <Button
                                                            margin="normal"
                                                            variant="text"
                                                            color="secondary"
                                                            onClick={this.handleRetryIpBan}
                                                        >                            
                                                            Retry
                                                        </Button>   
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )) || (
                                            <React.Fragment>
                                                {(action === 'login' && (
                                                    <form onSubmit={e => { e.preventDefault(); this.handleRecaptcha(); }} className={classes.form} noValidate autoComplete="off">    
                                                        <Grid container>
                                                            {storedProfile && (
                                                                <Grid item xs={12}>
                                                                    <LoginStoredProfile staffData={storedProfile} />
                                                                </Grid>
                                                            )}
                                                            { this.state.formErrors.googleRecaptchaToken && (
                                                                <Grid item xs={12}>
                                                                    <div style={{textAlign: 'center', marginBottom: 12}} className={classes.error}>{this.state.formErrors.googleRecaptchaToken}</div>
                                                                </Grid>
                                                            )}
                                                            { this.state.formErrors.generic && (
                                                                <Grid item xs={12}>                                                        
                                                                    <div style={{textAlign: 'center', marginBottom: 12}}  className={classes.error}>{this.state.formErrors.generic}</div>
                                                                </Grid>
                                                            )}        
                                                            <Grid item xs={12}>                
                                                                <TextField
                                                                    margin="normal"
                                                                    required
                                                                    fullWidth
                                                                    id="email"
                                                                    label="Email Address"
                                                                    name="email"
                                                                    autoComplete="off"
                                                                    autoFocus
                                                                    value={formData.email}
                                                                    error={formErrors && formErrors['email'] && true}
                                                                    helperText={formErrors && formErrors['email']}
                                                                    onChange={this.handleChange} 
                                                                    style={{display: storedProfile && 'none'}}    
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    margin="normal"
                                                                    required
                                                                    fullWidth
                                                                    name="password"
                                                                    label="Password"
                                                                    type="password"
                                                                    id="password"
                                                                    autoComplete="new-password"
                                                                    value={formData.password}
                                                                    error={formErrors && formErrors['password'] && true}
                                                                    helperText={formErrors && formErrors['password']}
                                                                    onChange={this.handleChange}   
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}                          
                                                                />     
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <ReCAPTCHA
                                                                    ref={this.googleRecaptcha}
                                                                    size="invisible"
                                                                    sitekey={RECAPTCHA_SITE_KEY}
                                                                />               
                                                                {!storedProfile && (
                                                                    <FormControlLabel
                                                                        control={<Checkbox name="storeProfile" value="remember" color="primary" onChange={this.handleCheckedChange}/>}
                                                                        label={<Typography variant="caption">Remember me</Typography>}
                                                                    />
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    type="submit"
                                                                    fullWidth
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classes.submit}
                                                                    disabled={!this.state.formData.email || !this.state.formData.password || this.state.disableSubmit}
                                                                >                            
                                                                    Sign In
                                                                </Button>   
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Link href="https://www.crushedice.biz" target="_blank" variant="body2" rel="noopener">
                                                                    <Typography variant="caption" component="div">
                                                                        Need help? Contact support
                                                                    </Typography>
                                                                </Link>
                                                            </Grid>
                                                            {storedProfile && (
                                                                <Grid item xs={12} lg={6}>
                                                                    <Link href="#" onClick={this.handleClearStoredProfile} variant="body2">
                                                                        <Typography variant="caption" component="div" className={classes.rightText}>
                                                                            {`Not ${storedProfile.firstName} ${storedProfile.lastName}? Logout`}
                                                                        </Typography>
                                                                    </Link>
                                                                </Grid>
                                                            )}
                                                        </Grid>                                                 
                                                    </form>
                                                )) || (
                                                    <form onSubmit={this.handleMfa} className={classes.form} noValidate autoComplete="off">             
                                                            {storedProfile && (
                                                            <LoginStoredProfile staffData={storedProfile} />
                                                        )}                                                              
                                                        <Grid container spacing={isMobile ? 1 : 3}>
                                                            <Grid item xs={12} align='center'>       
                                                                <Typography variant="caption">                                                 
                                                                    Please enter your multi-factor authentication code     
                                                                </Typography>                                                         
                                                            </Grid> 
                                                            {this.state.formErrors.mfaCode && 
                                                                <Grid item xs={12} style={{color: colors.red}} align='center'>   
                                                                    {this.state.formErrors.generic && (
                                                                        <React.Fragment>
                                                                            {this.state.formErrors.generic}
                                                                            <br />
                                                                        </React.Fragment>
                                                                    )}
                                                                    {this.state.formErrors.mfaCode}
                                                                </Grid>
                                                            }    
                                                            <Grid item xs={2}>
                                                                <TextField
                                                                    id="mfa-0"
                                                                    variant="outlined"
                                                                    required
                                                                    fullWidth
                                                                    autoComplete="off"
                                                                    className='mfaInput'
                                                                    value={formData.mfaCodeInd[0]}
                                                                    error={formErrors && formErrors['mfaCode'] && true}
                                                                    onChange={(e) => this.handleMfaChange(0, e)} 
                                                                    inputProps={{
                                                                        ref:this.mfaCode0,
                                                                        size:2,
                                                                        maxLength:2,
                                                                        style: {
                                                                            textAlign: 'center'
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <TextField
                                                                    id="mfa-1"
                                                                    variant="outlined"
                                                                    required
                                                                    fullWidth
                                                                    autoComplete="off"
                                                                    className='mfaInput'
                                                                    value={formData.mfaCodeInd[1]}
                                                                    error={formErrors && formErrors['mfaCode'] && true}
                                                                    onChange={(e) => this.handleMfaChange(1, e)} 
                                                                    inputProps={{
                                                                        ref:this.mfaCode1,
                                                                        size:2,
                                                                        maxLength:2,
                                                                        style: {
                                                                            textAlign: 'center'
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <TextField
                                                                    id="mfa-2"
                                                                    variant="outlined"
                                                                    required
                                                                    fullWidth
                                                                    name="mfaCode3"
                                                                    autoComplete="off"
                                                                    className='mfaInput'
                                                                    value={formData.mfaCodeInd[2]}
                                                                    error={formErrors && formErrors['mfaCode'] && true}
                                                                    onChange={(e) => this.handleMfaChange(2, e)} 
                                                                    inputProps={{
                                                                        ref:this.mfaCode2,
                                                                        size:2,
                                                                        maxLength:2,
                                                                        style: {
                                                                            textAlign: 'center'
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <TextField
                                                                    id="mfa-3"
                                                                    variant="outlined"
                                                                    required
                                                                    fullWidth
                                                                    autoComplete="off"
                                                                    className='mfaInput'
                                                                    value={formData.mfaCodeInd[3]}
                                                                    error={formErrors && formErrors['mfaCode'] && true}
                                                                    onChange={(e) => this.handleMfaChange(3, e)} 
                                                                    inputProps={{                                                                        
                                                                        ref:this.mfaCode3,
                                                                        size:2,
                                                                        maxLength:2,
                                                                        style: {
                                                                            textAlign: 'center'
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <TextField
                                                                    id="mfa-4"
                                                                    variant="outlined"
                                                                    required
                                                                    fullWidth
                                                                    autoComplete="off"
                                                                    className='mfaInput'
                                                                    value={formData.mfaCodeInd[4]}
                                                                    error={formErrors && formErrors['mfaCode'] && true}
                                                                    onChange={(e) => this.handleMfaChange(4, e)} 
                                                                    inputProps={{
                                                                        ref:this.mfaCode4,
                                                                        size:2,
                                                                        maxLength:2,
                                                                        style: {
                                                                            textAlign: 'center'
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <TextField
                                                                    id="mfa-5"
                                                                    variant="outlined"
                                                                    required
                                                                    fullWidth
                                                                    autoComplete="off"
                                                                    className='mfaInput'
                                                                    value={formData.mfaCodeInd[5]}
                                                                    error={formErrors && formErrors['mfaCode'] && true}
                                                                    onChange={(e) => this.handleMfaChange(5, e)} 
                                                                    inputProps={{
                                                                        ref:this.mfaCode5,
                                                                        size:2,
                                                                        maxLength:2,
                                                                        style: {
                                                                            textAlign: 'center'
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>                                                        
                                                        <Button
                                                            fullWidth
                                                            variant="contained"
                                                            color="primary"
                                                            type="submit"
                                                            className={classes.submit}
                                                            onClick={this.handleSubmit}
                                                            disabled={!this.state.formData.mfaCode || this.state.disableSubmit}
                                                        >                            
                                                            Authenticate
                                                        </Button>   
                                                        <Grid container>
                                                            <Grid item xs={12} lg={6}>
                                                                <Link href="https://www.crushedice.biz" target="_blank" variant="body2" rel="noopener">
                                                                    <Typography variant="caption" component="div">
                                                                        Need help? Contact support
                                                                    </Typography>
                                                                </Link>
                                                            </Grid>
                                                            {storedProfile && (
                                                                <Grid item xs={12} lg={6}>
                                                                    <Link href="#" onClick={this.handleClearStoredProfile} variant="body2">
                                                                        <Typography variant="caption" component="div" className={classes.rightText}>
                                                                            {`Not ${storedProfile.firstName} ${storedProfile.lastName}? Logout`}
                                                                        </Typography>
                                                                    </Link>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </form>
                                                )}
                                            </React.Fragment>                                    
                                        )}
                                        <Box className={classes.mwl}>
                                            <Typography variant="body2" color="textSecondary" align="center">
                                                MyClenaware v{CLIENT_VERSION} by Clenaware Systems
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" align="center" className={classes.copyright}>
                                                {`Copyright `}&copy;{` ${moment().year()} `}
                                                <Link color="inherit" href="http://www.crushedice.biz" target="_blank" rel="noopener">
                                                    Crushed Ice
                                                </Link>
                                            </Typography>
                                        </Box>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        </div>
                    </Grid>
                </Grid>
                {appError.state !== false && (
                    <ErrorDialog
                        key={appError.key}
                        state={appError.state}
                        detail={appError.detail}
                        history={this.props.history}
                        closeError={() => this.handleCloseErrorDialog()}
                    />
                )}
            </MUIStylesProvider>
        );
    }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state){
    return {
        ui: state.ui,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        authStaff: (token, profilePhoto) => dispatch(authStaff(token, profilePhoto)),
        handleAppError: (error, message) => dispatch(handleAppError(error, message)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));