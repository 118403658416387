//CreditNotePayments
import React, { Component }             from 'react';
import { connect }                      from 'react-redux';
import _                                from 'lodash';
import moment                           from 'moment';
import API                              from 'API';
import clsx                             from 'clsx';

import { deployConfirmation }           from 'Actions/Confirmation/Confirmation';
import { deploySnackBar }               from 'Actions/SnackBar/SnackBar';
import { deployDialog }                 from 'Actions/Dialog/Dialog';
import { closeDialog }                  from 'Actions/Dialog/Dialog';

import icons                            from 'Helpers/IconHelper' 
import { pdfFromBase64 }                from "Helpers/PDFHelper";
import { formatValidationErrors }       from 'Helpers/ErrorHelper';
import { colors }                       from 'Helpers/ColourHelper';
import { getMediaType }                 from 'Helpers/FileHelper';

import LoadingCircle                    from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper                      from 'Components/Common/Paper/PaddedPaper';
import BackButton                       from 'Components/Common/Buttons/BackButton';
import DataTable                        from 'Components/Common/DataTables/DataTable';
import AllIcon                          from 'Components/Common/Icons/AllIcon';
import SelectCustomerAddressForm        from 'Components/Customers/SelectCustomerAddressForm/SelectCustomerAddressForm';
import EmailDialog                      from 'Components/Common/Dialogs/EmailCustomerReturnsDialog';
import ImageWithError                   from 'Components/Common/ImageWithError/ImageWithError';
import PDFViewer                        from 'Components/Common/PDFViewer/PDFViewer';

import CreditNoteInvoicePaymentsDialog  from './CreditNoteInvoicePaymentsDialog'

import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Link, Paper, Collapse, CardHeader, Tooltip, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Chip, 
    FormControl, InputLabel, Input, TextField} from '@material-ui/core';

import { downloadS3File } from 'Functions/MiscFunctions';

const initialState = () => ({
    creditNote:             {},
    expanded:               false,
    isLoading:              true,
    highlightedPaymentIds:  [],
    addressDilalogOpen: false,
    addressDilalogData: {
        editInvoice: false,
        editDelivery:false,
        invoice: {
            type:        null,
            addrId:      0,
            contactId:   0,  
        },
        delivery: {
            type:        null,
            addrId:      0,
            contactId:   0,  
        },
        openConfirmation:       false,
        emailDialogOpen:        false,
        emailDialogID:          '',
        emailDialogCustomerId:  '',
        emailDialogContactId:   '',
    }
})

const styles = theme => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    icons: {
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    label: {
        color: 'black'
    },
    noMargin: {
        margin: 0
    }
});

const PaymentDialog  = ({rowData, closeDialog, viewFile}) => {
    return (
        <>
            <FormControl margin="normal" fullWidth>
                <InputLabel shrink={true} style={{color: 'black'}}>Refund Date</InputLabel>
                <Input value={moment(rowData.cnp_refund_date).format('DD/MM/YYYY')} disabled />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel shrink={true} style={{color: 'black'}}>Method</InputLabel>
                <Input value={rowData.cnp_method} disabled />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel shrink={true} style={{color: 'black'}}>Refund Amount</InputLabel>
                <Input value={`£${rowData.cnp_amount}`} disabled />
            </FormControl>
            {rowData.cnp_method == 'Invoice' ? 
                <>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel shrink={true} style={{color: 'black'}}>Invoice Number</InputLabel>
                        <Input value={rowData.cnp_invoice_number} disabled />
                    </FormControl>
                </> :
                <>
                    {rowData.cnp_method == 'Bank Transfer' ? 
                        <>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true} style={{color: 'black'}}>Refund Ref</InputLabel>
                                <Input value={rowData.cnp_refund_ref} disabled />
                            </FormControl>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true} style={{color: 'black'}}>Payee</InputLabel>
                                <Input value={rowData.cnp_payee} disabled />
                            </FormControl>
                        </>
                        :
                        <>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true} style={{color: 'black'}}>Refund Ref</InputLabel>
                                <Input value={rowData.cnp_refund_ref} disabled />
                            </FormControl>
                            {rowData.cnp_auth_no &&
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel shrink={true} style={{color: 'black'}}>Auth No</InputLabel>
                                    <Input value={rowData.cnp_auth_no} disabled />
                                </FormControl>
                            }
                        </>
                    }
                </>
            }
            <Grid item xs={12}>
                <FormControl margin="normal" fullWidth>
                    <InputLabel shrink={true} style={{paddingBottom: '1.8em', color: 'black'}}>Refund File</InputLabel>
                    <TextField
                        style={{marginTop: '0.7em'}}
                        fullWidth
                        value={rowData.cnp_file ? _.last(rowData.cnp_file.split('/')) : 'No File'}
                        disabled
                        InputProps={{
                            endAdornment: rowData.file_upload && (<AllIcon icon={icons.search} onClick={viewFile(rowData.file_upload)} noMargin />)
                        }}
                    />
                </FormControl>
            </Grid>
            <div className="buttonRow">
                <Button 
                    variant="outlined" 
                    color="default" 
                    onClick={closeDialog}
                >
                    Close
                </Button>
            </div>
        </>
    )
}
class CreditNoteInvoicePayments extends Component {
    constructor(props) {
        super(props);    
        this.state              = initialState();
    }

    componentDidMount = () => {this.getCreditNote()}

    getCreditNote = () => {
        API.get(`/customerReturns/creditNote/${this.props.match.params.id}`)
        .then(res => {
            if (res.data){
                this.setState({
                    creditNote: res.data,
                    isLoading:  false,
                }, this.getCustomer)
            }
        })
    }

    downloadCreditNote = () => {
        API.post(`/customerReturns/creditNote/${this.props.match.params.id}/prevPdf`, {type: 'preview',}).then((result) => {
            if (result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
                this.props.deploySnackBar("success", "PDF successfully downloaded");
            }
        });
    }


    handleExpandInvoice = () => {this.setState({expanded: !this.state.expanded});}

    toggleAddressDilalog = () => {this.setState({addressDilalogOpen: !this.state.addressDilalogOpen})}

    handleAddressChange = type => (name,e) => {
        this.setState({
            addressDilalogData: {
                ...this.state.addressDilalogData,
                [type]: e
            }
        });
    }

    toggleSubAddressDilalog = (type, close=false) => {
        if (close){
            this.subAddressResetData(type == 'editDelivery' ? 'delivery' : 'invoice');
        }
        this.setState({
            addressDilalogData: {
                ...this.state.addressDilalogData,
                [type]: !this.state.addressDilalogData[type]
            }
        })
    }

    subAddressResetData = type => {
        this.setState({
            addressDilalogData: {
                ...this.state.addressDilalogData,
                [type]: initialState().addressDilalogData[type]
            }
        });
    }

    handleSaveAddress = () => {
        const data = this.state.addressDilalogData;
        let formData = {};
        if (data.editInvoice){
            formData = {
                ...formData,
                invoiceAddress: data.invoice.address,
                invoiceContact: data.invoice.contact,
                changeInvoice : true
            }
        }
        if (data.editDelivery){
            formData = {
                ...formData,
                deliveryAddress: data.delivery.address,
                deliveryContact: data.delivery.contact,
                changeDelivery: true
            }
        }
        API.post(`/customerReturns/creditNote/invoice/${this.state.creditNote.invoice.cni_id}/changeAddress`, formData)
        .then(result => {
            if (result.data.errors){
                this.setState({ formErrors: formatValidationErrors(result.data.errors) });
            } else {    
                this.setState({
                    ...initialState(),
                    emailDialogOpen:        true,
                    emailDialogID:          this.state.creditNote.cn_id,
                    emailDialogCustomerId:  this.state.creditNote.invoice.cni_cust_id,
                    emailDialogContactId:   data.editInvoice ? data.invoice.contact : this.state.creditNote.invoice.cni_cust_contact_id
                },()=>{ this.handleEmailOpen(); this.getCreditNote(); })
            }
        });
    }

    handleEmailOpen = () => {
        const dialog =  <EmailDialog 
                            id=                 {this.state.emailDialogID}
                            customerId=         {this.state.emailDialogCustomerId}
                            customerContactId=  {this.state.emailDialogContactId}
                            type=               {'creditNote'}
                            subType=            {'Invoice'}
                        />
        this.props.deployDialog(dialog, 'You Are About To Email - Credit Note', 'success');
    }

    handleEmailClose = () => { this.setState(initialState(),this.props.closeDialog);}

    handleDownload = i => {
        API.post(`/customerReturns/creditNote/invoice/${i}/prevPdf`, {type: 'Invoice'}).then((result) => {
            if (result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    };

    viewFile = file => () => {
        this.props.deployDialog(
            <Grid container spacing={1}>
                <Grid item xs={12} style={{textAlign:'center'}}>
                    {getMediaType(file) === 'image' &&
                        <ImageWithError height='500' width='500' src={file} />
                    }
                    {getMediaType(file) === 'pdf' &&
                        <PDFViewer pdfUrl={file} />
                    }
                    {(getMediaType(file) !== 'pdf' && getMediaType(file) !== 'image') && 'File Not Supported'}
                </Grid>
                <Grid className='buttonRow' item xs={12}>
                    <Button onClick={()=>{ this.props.closeDialog() }} variant='outlined'>Close</Button>
                </Grid>
            </Grid>, 'View File', 'success', 'md');
    }

    handleDownloadFile = file => () => { downloadS3File(file); }

    getCustomer = () => {
        API.get(`/customers/${this.state.creditNote.cn_cust_id}`)
        .then(result => {
            this.setState({customer: result.data},() => {
                if (!this.state.importantNoteShown && result.data.cust_important_info) this.displayImportantInfo();
            });
        });
    }

    displayImportantInfo = () => {
        this.setState({importantNoteShown: true}, () => {
            this.props.deployDialog(
            <>
                <Typography component="p">
                    {this.state.customer?.cust_important_info && this.state.customer?.cust_important_info.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
                </Typography>
                <Button variant='outlined' style={{float: 'right'}} onClick={this.props.closeDialog}>Close</Button>
            </>
            , 'Important Information', null, 'sm', {backgroundColor : '#fcc6c5', color: '#000'})
        });
    }

    render() {
        const { creditNote, isLoading, expanded, customer } = this.state;
        const { classes } = this.props;
        return (
            <Grid container spacing={3}>
                {isLoading ? <LoadingCircle /> : 
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={7}>
                            <Typography variant="h5" gutterBottom>
                                Credit Note Information
                            </Typography>
                            <PaddedPaper>
                                {!this.state.isLoading && customer?.cust_name && customer?.account_status?.cas_name != 'Active - Verified' &&
                                    <Typography style={{
                                        fontWeight:'bold',
                                        color: colors.white, 
                                        backgroundColor: colors.red, 
                                        margin: '-24px', 
                                        marginBottom: 12,
                                        borderRadius: '5px 5px 0px 0px',
                                        padding: '0.5em 1em',
                                    }}>{customer.cust_name} is {customer?.account_status?.cas_name }</Typography>
                                }
                                <Grid container item spacing={3}>
                                    <Grid item lg={3}>
                                        Credit Note Reference:
                                    </Grid>
                                    <Grid item lg={9}>
                                        <Link to="" onClick={(e) => { e.preventDefault(); this.downloadCreditNote()}} className='blueLink'>
                                            {creditNote.full_reference}
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={3}>
                                    <Grid item lg={3}>
                                        Credit Note Date:
                                    </Grid>
                                    <Grid item lg={9}>
                                        {moment(creditNote?.cn_date).format("DD/MM/YYYY")}
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={3}>
                                    <Grid item lg={3}>
                                        Customer:
                                    </Grid>
                                    <Grid item lg={9}>
                                        {creditNote?.cn_cust_name}
                                    </Grid>
                                </Grid>
                                <div className='buttonRow'><BackButton props={this.props}/></div>
                            </PaddedPaper>
                            <br />
                            <Typography variant="h5" gutterBottom>
                                Credit Note Refunds
                            </Typography>
                            <PaddedPaper>
                                <DataTable
                                    config={{
                                        key: 'ip_id',
                                        withBorder: true,
                                        withBorderRadius: true,
                                        plainHeader: true,
                                    }}
                                    columns={[
                                        {
                                            heading: 'Credit Note Number',
                                            field: rowData => <>{creditNote.invoice.cni_invoice_number}</>,
                                            important: true,
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: 'Date Refunded',
                                            field: rowData => rowData.cnp_refund_date != '0000-00-00 00:00:00' ? moment(rowData.cnp_refund_date).format("DD/MM/YYYY") : '-',
                                            important: true,
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: 'Method',
                                            field: rowData => {
                                                return (
                                                    <>
                                                        {rowData.cnp_method} {rowData.cnp_method == 'Invoice' &&  
                                                            <>(<Link style={{textDecoration: 'none', color: '#0282C6'}} href={`/sales/order/invoice/payments/${rowData.cnp_invoice_order_id}`}>{`${rowData.cnp_invoice_number}`}</Link>)</>
                                                        }
                                                    </>
                                                )
                                            },
                                            important: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Amount',
                                            field: rowData => rowData.cnp_amount,
                                            important: true,
                                            sizeToContent: true,
                                            alignment: 'right',
                                            fieldPrefix: '£'
                                        },
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Download',       icon: icons.download, onClick: this.handleDownloadFile(rowData.file_upload),   disabled: !rowData.file_upload },
                                                    {name: 'Refund Details', icon: 'search', onClick: () => {
                                                        this.props.deployDialog(<PaymentDialog rowData={rowData} viewFile={this.viewFile} closeDialog={this.props.closeDialog} />, 'Refund Details', null, 'xs')
                                                    }}
                                                ]
                                            },
                                            alignment: 'right',
                                            sizeToContent: true,
                                        }
                                    ]}
                                    rows={creditNote?.invoice.payments}
                                />
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <Typography variant="h5" gutterBottom>
                                Credit Note
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper>
                                        <CardHeader 
                                            title={<Typography variant={'h6'}>{creditNote.invoice.cni_invoice_number}</Typography>}
                                            subheader={
                                                <>
                                                    <Grid container spacing={3}>
                                                        <Grid item>
                                                            <Typography variant={'caption'} paragraph className={this.props.classes.noMargin}>
                                                                Sage:
                                                            </Typography>
                                                            <Typography variant={'caption'} paragraph className={this.props.classes.noMargin}>
                                                                Invoice Address:
                                                            </Typography>
                                                            <Typography variant={'caption'} paragraph className={this.props.classes.noMargin}>
                                                                Delivery Address:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant={'caption'} paragraph className={this.props.classes.noMargin}>
                                                                {creditNote.invoice.cni_sage_invoice_number}
                                                            </Typography>
                                                            <Typography variant={'caption'} paragraph className={this.props.classes.noMargin}>
                                                                {creditNote?.invoice?.cni_cust_address_name}
                                                                &nbsp;<Tooltip
                                                                    style={{float:'right'}}
                                                                    title={
                                                                        <div>
                                                                            <Typography variant="body2">
                                                                                <b>Invoice Address:</b><br />
                                                                                {creditNote?.invoice?.cni_cust_address.split('\n').map((item, key) => {
                                                                                    return  <span key={key}>{`${item}`}<br/></span>
                                                                                })}
                                                                                <b>Contact:</b><br />
                                                                                {creditNote?.invoice?.cni_cust_contact_name}<br />
                                                                                {creditNote?.invoice?.cni_cust_contact_email}<br />
                                                                                {creditNote?.invoice?.cni_cust_contact_mobile ? 
                                                                                    <>
                                                                                        <AllIcon icon='mobile-alt' style={{color:'white'}} size='small' />
                                                                                        {creditNote?.invoice?.cni_cust_contact_mobile}
                                                                                        <br/>
                                                                                    </> : ''}
                                                                                {creditNote?.invoice?.cni_cust_contact_phone ? 
                                                                                    <>
                                                                                        <AllIcon icon='phone' style={{color:'white'}} size='small' />
                                                                                        {creditNote?.invoice?.cni_cust_contact_phone}
                                                                                        <br/>
                                                                                    </> : ''}
                                                                            </Typography>
                                                                        </div>

                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Link>
                                                                        <AllIcon
                                                                            heavy 
                                                                            icon='info-circle'
                                                                            button 
                                                                            size='small'
                                                                            style={{
                                                                                color: 'rgba(0, 0, 0, 0.38)'
                                                                            }}
                                                                        />
                                                                    </Link>
                                                                </Tooltip>
                                                            </Typography>
                                                            <Typography variant={'caption'} paragraph>
                                                                {creditNote?.invoice?.cni_delivery_cust_address_name} 
                                                                &nbsp;<Tooltip
                                                                    style={{float:'right'}}
                                                                    title={
                                                                        <div>
                                                                            <Typography variant="body2">
                                                                                <b>Delivery Address:</b><br />
                                                                                {creditNote?.invoice?.cni_delivery_cust_address.split('\n').map((item, key) => {
                                                                                    return  <span key={key}>{`${item}`}<br/></span>
                                                                                })}
                                                                                <b>Contact:</b><br />
                                                                                {creditNote?.invoice?.cni_delivery_cust_contact_name}<br />
                                                                                {creditNote?.invoice?.cni_delivery_cust_contact_email}<br />
                                                                                {creditNote?.invoice?.cni_delivery_cust_contact_mobile ? 
                                                                                    <>
                                                                                        <AllIcon icon='mobile-alt' style={{color:'white'}} size='small' />
                                                                                        {creditNote?.invoice?.cni_delivery_cust_contact_mobile}
                                                                                        <br/>
                                                                                    </> : ''}
                                                                                {creditNote?.invoice?.cni_delivery_cust_contact_phone ? 
                                                                                    <>
                                                                                        <AllIcon icon='phone' style={{color:'white'}} size='small' />
                                                                                        {creditNote?.invoice?.cni_delivery_cust_contact_phone}
                                                                                        <br/>
                                                                                    </> : ''}
                                                                            </Typography>
                                                                        </div>

                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Link>
                                                                        <AllIcon
                                                                            heavy 
                                                                            icon='info-circle'
                                                                            button 
                                                                            size='small'
                                                                            style={{
                                                                                color: 'rgba(0, 0, 0, 0.38)'
                                                                            }}
                                                                        />
                                                                    </Link>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {_.map(creditNote?.invoice?.past_revisions, i => 
                                                            <Typography variant={'body2'} gutterBottom>
                                                                <Link to="" onClick={(e) => { e.preventDefault(); this.handleDownload(i.cni_id)}} className='blueLink'>
                                                                    {`${i.cni_reference}-0${i.cni_reference_unique_number}`}
                                                                </Link>
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant={'body1'} gutterBottom>
                                                            {creditNote?.invoice?.cni_status == 'Completed' ?
                                                                <Chip size="small" label="Refunded" style={{backgroundColor: colors.green, color: '#fff', marginRight:'20px'}} />
                                                            :
                                                                <Chip size="small" label="Pending" style={{backgroundColor: '#F39C12', color: '#fff', marginRight:'20px'}} />
                                                            }
                                                            <span style={{fontSize:'12px'}}>Refunded: </span>£{creditNote?.invoice?.sum_of_payments || '0.00'}
                                                            <span style={{fontSize:'30px', color: 'rgba(0, 0, 0, 0.2)'}}> / </span> 
                                                            <span style={{fontSize:'12px'}}>Due: </span>£{creditNote?.invoice?.cni_total_incl_vat}
                                                            <span style={{fontSize:'30px', color: 'rgba(0, 0, 0, 0.2)'}}> / </span> 
                                                            <span style={{fontSize:'12px'}}>Outstanding: </span>£{(creditNote?.invoice?.cni_total_incl_vat - creditNote?.invoice?.sum_of_payments).toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            action={
                                                <>
                                                    {creditNote?.invoice?.cni_status != 'Completed' &&
                                                        <>
                                                            <Tooltip title="Assign / Make Payment">
                                                                <div>
                                                                    <IconButton onClick={() => {
                                                                        this.props.deployDialog(<CreditNoteInvoicePaymentsDialog id={creditNote?.invoice?.cni_id} cn={creditNote} callback={this.getCreditNote} />, 'Assign Refund', 'success');
                                                                    }}>
                                                                        <AllIcon icon="plus" button noMargin/>
                                                                    </IconButton>
                                                                </div>
                                                            </Tooltip>
                                                            <Tooltip title="Edit Address">
                                                                <div>
                                                                    <IconButton
                                                                        onClick={this.toggleAddressDilalog}
                                                                    >
                                                                        <AllIcon icon="pen" button noMargin/>
                                                                    </IconButton>
                                                                </div>
                                                            </Tooltip>
                                                        </>
                                                    }
                                                </>
                                            }
                                            disableTypography
                                        />
                                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                                        </Collapse>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Dialog
                            open=       {this.state.addressDilalogOpen}
                            onClose=    {this.toggleAddressDilalog}
                            size=       'md'
                            maxWidth=   'md'
                            scroll=     "body"
                            fullWidth
                            disableBackdropClick
                            disableEscapeKeyDown
                        >
                            <DialogTitle id="form-dialog-title">Change Credit Note Address's</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={1} xs={12}>
                                    <Grid item xs={6}>
                                        <PaddedPaper fullWidth style={{width:'100%', marginBottom:'1.5em'}}>
                                            {this.state.addressDilalogData.editInvoice ? 
                                                [
                                                    <Typography variant="h6">
                                                        Credit Note
                                                        <Tooltip title="Close" style={{float:'right', marginRight:'auto'}}>
                                                            <IconButton onClick={()=>{this.toggleSubAddressDilalog('editInvoice',true)}}>
                                                                <AllIcon icon="times" noMargin button />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Typography>,
                                                    <SelectCustomerAddressForm
                                                        customerId= {creditNote.invoice.cni_cust_id}
                                                        addrType=   {this.state.addressDilalogData.invoice.type}
                                                        addrId=     {this.state.addressDilalogData.invoice.addrId}
                                                        contId=     {this.state.addressDilalogData.invoice.contactId}
                                                        onChange=   {this.handleAddressChange('invoice')}
                                                        name=       'invoice'
                                                        formErrors= {this.state.formErrors}
                                                    />
                                                ]:
                                                [<Typography variant="h6">
                                                    Invoice
                                                    <Tooltip title="Change Adress" style={{float:'right', marginRight:'auto'}}>
                                                        <IconButton onClick={()=>{this.toggleSubAddressDilalog('editInvoice')}}>
                                                            <AllIcon icon="pencil-alt" noMargin button />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Typography>,
                                                <Typography variant="body">
                                                    <Grid container>
                                                        <Grid container item style={{marginBottom:'1em'}}>
                                                            <Grid item xs={6}>Company Name</Grid>
                                                            <Grid item xs={6}>
                                                                {creditNote?.invoice?.cni_cust_name}
                                                            </Grid>
                                                        </Grid>
                                                        
                                                        <Grid container item style={{marginBottom:'1em'}}>
                                                            <Grid item xs={6}>Address</Grid>
                                                            <Grid item xs={6}>
                                                                {creditNote?.invoice?.cni_cust_address?.split('\n').map(i=><>{i}<br/></>)}
                                                            </Grid>
                                                        </Grid>
                                                        
                                                        <Grid container item style={{marginBottom:'1em'}}>
                                                            <Grid item xs={6}>Contact</Grid>
                                                            <Grid item xs={6}>
                                                                {creditNote?.invoice?.cni_cust_contact_name}
                                                            </Grid>
                                                        </Grid>
                                                        
                                                        <Grid container item style={{marginBottom:'1em'}}>
                                                            <Grid item xs={6}>Email Address</Grid>
                                                            <Grid item xs={6}>
                                                                {creditNote?.invoice?.cni_cust_contact_email}
                                                            </Grid>
                                                        </Grid>
                                                        
                                                        <Grid container item>
                                                            <Grid item xs={6}>Phone Number</Grid>
                                                            <Grid item xs={6}>
                                                                {creditNote?.invoice?.cni_cust_contact_phone} / {creditNote?.invoice?.cni_cust_contact_mobile}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Typography>]
                                            }
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <PaddedPaper fullWidth style={{width:'100%', marginBottom:'1.5em'}}>
                                            {this.state.addressDilalogData.editDelivery ? 
                                                [
                                                    <Typography variant="h6">
                                                        Delivery
                                                        <Tooltip title="Close" style={{float:'right', marginRight:'auto'}}>
                                                            <IconButton onClick={()=>{this.toggleSubAddressDilalog('editDelivery',true)}}>
                                                                <AllIcon icon="times" noMargin button />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Typography>,
                                                    <SelectCustomerAddressForm
                                                        customerId= {creditNote.invoice.cni_cust_id}
                                                        addrType=   {this.state.addressDilalogData.delivery.type}
                                                        addrId=     {this.state.addressDilalogData.delivery.addrId}
                                                        contId=     {this.state.addressDilalogData.delivery.contactId}
                                                        onChange=   {this.handleAddressChange('delivery')}
                                                        name=       'delivery'
                                                        formErrors= {this.state.formErrors}
                                                    />
                                                ] : [ <Typography variant="h6">
                                                        Delivery
                                                        <Tooltip title="Change Adress" style={{float:'right', marginRight:'auto'}}>
                                                            <IconButton onClick={()=>{this.toggleSubAddressDilalog('editDelivery')}}>
                                                                <AllIcon icon="pencil-alt" noMargin button />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Typography>,
                                                    <Typography variant="body">
                                                        <Grid container>
                                                            <Grid container item style={{marginBottom:'1em'}}>
                                                                <Grid item xs={6}>Company Name</Grid>
                                                                <Grid item xs={6}>
                                                                    {creditNote?.invoice?.cni_delivery_cust_address_name}
                                                                </Grid>
                                                            </Grid>
                                                            
                                                            <Grid container item style={{marginBottom:'1em'}}>
                                                                <Grid item xs={6}>Address</Grid>
                                                                <Grid item xs={6}>
                                                                    {creditNote?.invoice?.cni_delivery_cust_address?.split('\n').map(i=><>{i}<br/></>)}
                                                                </Grid>
                                                            </Grid>
                                                            
                                                            <Grid container item style={{marginBottom:'1em'}}>
                                                                <Grid item xs={6}>Contact</Grid>
                                                                <Grid item xs={6}>
                                                                    {creditNote?.invoice?.cni_delivery_cust_contact_name}
                                                                </Grid>
                                                            </Grid>
                                                            
                                                            <Grid container item style={{marginBottom:'1em'}}>
                                                                <Grid item xs={6}>Email Address</Grid>
                                                                <Grid item xs={6}>
                                                                    {creditNote?.invoice?.cni_delivery_cust_contact_email}
                                                                </Grid>
                                                            </Grid>
                                                            
                                                            <Grid container item>
                                                                <Grid item xs={6}>Phone Number</Grid>
                                                                <Grid item xs={6}>
                                                                    {creditNote?.invoice?.cni_delivery_cust_contact_phone} / {creditNote?.invoice?.cni_delivery_cust_contact_mobile}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Typography>
                                                ]
                                            }
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.toggleAddressDilalog} variant="outlined" color="default">Close</Button>
                                <Button onClick={()=>{this.props.deployConfirmation('Are you sure you want to change address\'s', 'Change Address', this.handleSaveAddress )}} variant="contained" color="primary" 
                                    disabled={!(this.state.addressDilalogData.editDelivery || this.state.addressDilalogData.editInvoice)}>Save</Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                } 
            </Grid>
        )
    }

}

const mapDispatchToProps = dispatch => ({
    deployDialog:           (content, title, variant, size='md', style=null) => dispatch(deployDialog(content, title, variant, size, style)),
    deployConfirmation:     (message, title, success, cancel=null)           => dispatch(deployConfirmation(message, title, success, cancel)),
    deploySnackBar:         (variant, msg)                                   => dispatch(deploySnackBar(variant, msg)),
    closeDialog:            ()                                               => dispatch(closeDialog())
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(CreditNoteInvoicePayments));