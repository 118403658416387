import React, { PureComponent }                 from 'react';
import { connect }                              from 'react-redux';
import API                                      from 'API';
import moment                                   from 'moment';
import _                                        from 'lodash';

import {deployDialog, closeDialog}              from 'Actions/Dialog/Dialog';

import DateRangeDialog                          from './../DateRangeDialog';
import ReportCell                               from './../ReportCell';
import LoadingCircle                            from 'Components/Common/LoadingCircle/LoadingCircle';
import AllIcon                                  from 'Components/Common/Icons/AllIcon';
import DataTable                                from 'Components/Common/DataTables/CiDataTable';
import DownloadCSV                              from 'Components/Common/Buttons/DownloadCSV';
import PaddedPaper                              from 'Components/Common/Paper/PaddedPaper';
import ImageWithError                           from 'Components/Common/ImageWithError/ImageWithError';
import PieChart                                 from 'Components/Common/Charts/PieChart';
import BarChart                                 from 'Components/Common/Charts/BarChart';

import logo                                     from 'Assets/Images/clenaware_logo_icon.png';

import { formatValidationErrors }               from 'Helpers/ErrorHelper';
import {getDatesBetweenTwoDates}                from 'Helpers/ReportsHelper';
import icons                                    from 'Helpers/IconHelper';
import { SystemColors, colors }                 from 'Helpers/ColourHelper';

import { Card, CardContent, CardHeader, Typography, Grid, Divider, Tooltip, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Box, Button } from '@material-ui/core/';
import { withStyles }                           from '@material-ui/styles';
import { icon } from '@fortawesome/fontawesome-svg-core';

import {setPersistence} from 'Actions/StatePersistence/StatePersistence';

import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';
import { convertRealNumber }                                            from 'Functions/MiscFunctions'

const styles = theme => ({
    card: {
        color: '#000',
        backgroundColor: '#FFF',
        padding: '0'
    },
    row: {
        color: '#000',
        backgroundColor: '#FFF',
        '&:hover': {
            backgroundColor: '#FCFCFC',
            cursor: 'pointer'
        },
    },
    header: {
        background:'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', 
        color: '#FFF'
    },
    legendWrapper: {
        padding: '0 1rem'
    },
    legendTitle: {
        marginBottom: '0.8rem'
    },
    legendItem: {
        display: 'grid',
        gridTemplateColumns: '30px 60px auto',
        marginBottom: '0.4rem'
    },
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }

});

const initialState = {
    filter:      {
        to:         moment(),
        from:       moment(),
    },
    parts:       {},
    days:        [],
    isLoading:   true,
    showResults: false,
    formErrors:  null
}


const OverviewRow = ({data, classes, label}) => {
    let rData = {
        in: {
            q: _.sumBy(data, 'in.q').toFixed(2),
            s: _.sumBy(data, 'in.s'),
            t: _.sumBy(data, 'in.t'),
            l: _.sumBy(data, 'in.l'),
            d: _.sumBy(data, 'in.d'),
            p: parseFloat((_.sumBy(data, 'in.d')/_.sumBy(data, 'in.l'))*100).toFixed(2)
        },
        out: {
            q: Math.abs(_.sumBy(data, 'out.q')).toFixed(2),
            s: _.sumBy(data, 'out.s'),
            t: _.sumBy(data, 'out.t'),
            l: _.sumBy(data, 'out.l'),
            d: _.sumBy(data, 'out.d'),
            p: parseFloat((_.sumBy(data, 'out.d')/_.sumBy(data, 'out.l'))*100).toFixed(2)
        },
        tot: {
            q: _.sumBy(data, 'tot.q').toFixed(2),
            s: _.sumBy(data, 'tot.s'),
            t: _.sumBy(data, 'tot.t'),
            l: _.sumBy(data, 'tot.l'),
            d: _.sumBy(data, 'tot.d'),
            p: parseFloat((_.sumBy(data, 'tot.d')/_.sumBy(data, 'tot.l'))*100).toFixed(2)
        }   
    }
    if (rData.out.p == 'NaN') rData.out.p = 0;
    return (
        <TableRow class={classes.row} sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
            <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  borderBottom: 0, textAlign:'center'}}>
                <AllIcon icon={icons[label]} size='medium'/>
                <Typography variant="h6" style={{textTransform: 'capitalize'}}>{label}</Typography>
            </TableCell>
            <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  borderBottom: 0}}>
                <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                    <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} primaryColor={colors.green} />
                    <Typography variant="h6" style={{fontSize: '14px'}}>
                        Net
                    </Typography>
                </Box>
                <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                    <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} swapOpacity secondaryColor={colors.red} />
                    <Typography variant="h6" style={{fontSize: '14px'}}>
                        Credited
                    </Typography>
                </Box>
                <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                    <AllIcon icon={'equals'} size='medium' style={{display: 'inline-block', paddingRight: '0.5rem', color: '#2d2d2d'}} />
                    <Typography variant="h6" style={{fontSize: '14px'}}>
                        Total
                    </Typography>
                </Box>
            </TableCell>
            <ReportCell data={rData} manualTotal style={{borderBottom: 0}} field={'q'} hidePound  showZero forceFixed={2} />
            <ReportCell data={rData} manualTotal style={{borderBottom: 0}} field={'l'}            showZero/>
            <ReportCell data={rData} manualTotal style={{borderBottom: 0}} field={'t'}            showZero/>
            <ReportCell data={rData} manualTotal style={{borderBottom: 0}} field={'d'}            showZero/>
            <ReportCell data={rData} manualTotal style={{borderBottom: 0}} field={'p'} percentage showZero forceFixed={2} />
        </TableRow>
    )
}

class SalesReportDiscount extends PureComponent {
    constructor(props) {
        super(props);
        this.clearPageState  = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState    = hasPageState.bind(this);
        this.savePageState   = savePageState.bind(this);
        switch (true){
            case     props.cid > 0:    this.persistenceId = `SalesReport:DiscountedParts:cid:${props.cid}`;    break;
            case     props.partId > 0: this.persistenceId = `SalesReport:DiscountedParts:pid:${props.partId}`; break;
            default:                   this.persistenceId = `SalesReport:DiscountedParts`;                     break;
        }
        this.state           = this.getInitialState(initialState)
    }

    handleSetFilterParams = (filter) => { this.setState({filter, showResults: true}, this.getData)} 

    getData = () => {
        this.setState({isLoading: true}, ()=> {
            const params = {params: {...this.state.filter, cid:this.props.cid, partId:this.props.partId}}
            Promise.all([
                API.get( '/reports/salesReport/discounts/overview' , params),
                API.get( '/reports/salesReport/discounts/parts' , params)
            ]).then(([overview, parts]) => {
                this.setState({
                    overview:   overview.data,
                    parts:      parts.data,
                    isLoading:  false
                },()=>{this.savePageState()});
            })
        })
    }

    loadPartImage = (rowData) => {
        this.props.deployDialog(
            <Grid container xs={12} spacing={3} style={{justifyContent:'center'}}>
                <Grid item>
                    <ImageWithError src={rowData.l} alt='Part Image' style={{maxWidth: 1000}}/>
                </Grid>
                <Grid item xs={12}><Button variant='outlined' onClick={this.props.closeDialog}>Close</Button></Grid>
            </Grid>
        ,`${rowData.n} - ${rowData.d}`,'sm')
    };

    getCsvData = type => {
        const {parts} = this.state;
        if (type == 'parts') return _.map(parts, i => {
                                        return _.assign({
                                            '#':            i.i, 
                                            'Number':       i.n,
                                            'Price':        parseFloat(i.tot.s).toFixed(2),
                                            'Total':        parseFloat(i.tot.t).toFixed(2),
                                            'Discount':     parseFloat(i.tot.d).toFixed(2)
                                        });
                                    })
    }

    render() {
        const { isLoading, filter, overview, parts, showResults, days } = this.state;
        const { classes }                                         =  this.props;

        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <DateRangeDialog callback={this.handleSetFilterParams}/>
                    </PaddedPaper>
                </Grid>
                {showResults &&
                    <>
                        {isLoading ? <Grid item xs={12} lg={12}><LoadingCircle/></Grid> : 
                            <>
                                {!!overview && 
                                    <>
                                        <Grid item xs={12} lg={8}>
                                            <Grid container xs={12}>
                                                <Grid item  xs={12}>
                                                    <PaddedPaper>
                                                        <Grid container xs={12}>
                                                            <Grid item xs={12} style={{marginBottom:6}}>
                                                                <Typography variant='h6' style={{fontWeight: 'bold', textAlign: 'center'}}>Average Discounts</Typography>
                                                            </Grid>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell></TableCell>
                                                                        <TableCell><b>Discounted <br/>Parts</b></TableCell>
                                                                        <TableCell><b>All <br/>Parts</b></TableCell>
                                                                        <TableCell><b>List Price</b></TableCell>
                                                                        <TableCell><b>Net</b></TableCell>
                                                                        <TableCell><b>Discount</b></TableCell>
                                                                        <TableCell><b>Discount %</b></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell><b>Products</b></TableCell>
                                                                        <TableCell>{_.sumBy(_.filter(parts,{ip:1}),i=>i.in.c + i.out.c).toFixed(2)}</TableCell>
                                                                        <TableCell>{_.sumBy(_.filter(parts,{ip:1}),i=>(i.in.k + i.out.k) + (i.in.c + i.out.c)).toFixed(2)}</TableCell>
                                                                        <TableCell>£{ convertRealNumber( (_.sumBy(_.filter(parts,{ip:1}),i=>i.in.l - i.out.l) / _.sumBy(_.filter(parts,{ip:1}),i=>(i.in.k + i.out.k) + (i.in.c + i.out.c)) ) || 0).toFixed(2)}</TableCell>
                                                                        <TableCell>£{ convertRealNumber( (_.sumBy(_.filter(parts,{ip:1}),i=>i.in.t - i.out.t) / _.sumBy(_.filter(parts,{ip:1}),i=>(i.in.k + i.out.k) + (i.in.c + i.out.c)) ) || 0).toFixed(2)}</TableCell>
                                                                        <TableCell>£{ convertRealNumber( (_.sumBy(_.filter(parts,{ip:1}),i=>i.in.d - i.out.d) / _.sumBy(_.filter(parts,{ip:1}),i=>(i.in.k + i.out.k) + (i.in.c + i.out.c)) ) || 0).toFixed(2)}</TableCell>
                                                                        <TableCell>{ convertRealNumber( ( ( (_.sumBy(_.filter(parts,{ip:1}),i=>i.in.d - i.out.d) / _.sumBy(_.filter(parts,{ip:1}),i=>(i.in.k + i.out.k) + (i.in.c + i.out.c)) ) || 0) / ( (_.sumBy(_.filter(parts,{ip:1}),i=>i.in.s - i.out.s) / _.sumBy(_.filter(parts,{ip:1}),i=>(i.in.k + i.out.k) + (i.in.c + i.out.c)) ) || 0)) * 100 ).toFixed(2)}%</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell><b>Services</b></TableCell>
                                                                        <TableCell>{_.sumBy(_.filter(parts,{ip:0}),i=>i.in.c + i.out.c).toFixed(2)}</TableCell>
                                                                        <TableCell>{_.sumBy(_.filter(parts,{ip:0}),i=>(i.in.k + i.out.k) + (i.in.c + i.out.c)).toFixed(2)}</TableCell>
                                                                        <TableCell>£{convertRealNumber( (_.sumBy(_.filter(parts,{ip:0}),i=>i.in.l - i.out.l) / _.sumBy(_.filter(parts,{ip:0}),i=>(i.in.k + i.out.k) + (i.in.c + i.out.c)) ) || 0 ).toFixed(2)}</TableCell>
                                                                        <TableCell>£{convertRealNumber( (_.sumBy(_.filter(parts,{ip:0}),i=>i.in.t - i.out.t) / _.sumBy(_.filter(parts,{ip:0}),i=>(i.in.k + i.out.k) + (i.in.c + i.out.c)) ) || 0 ).toFixed(2)}</TableCell>
                                                                        <TableCell>£{convertRealNumber( (_.sumBy(_.filter(parts,{ip:0}),i=>i.in.d - i.out.d) / _.sumBy(_.filter(parts,{ip:0}),i=>(i.in.k + i.out.k) + (i.in.c + i.out.c)) ) || 0 ).toFixed(2)}</TableCell>
                                                                        <TableCell>{ convertRealNumber( ( ( (_.sumBy(_.filter(parts,{ip:0}),i=>i.in.d - i.out.d) / _.sumBy(_.filter(parts,{ip:0}),i=>(i.in.k + i.out.k) + (i.in.c + i.out.c)) ) || 0) / ( (_.sumBy(_.filter(parts,{ip:0}),i=>i.in.s - i.out.s) / _.sumBy(_.filter(parts,{ip:0}),i=>(i.in.k + i.out.k) + (i.in.c + i.out.c)) ) || 0)) * 100 ).toFixed(2)}%</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell><b>Total</b></TableCell>
                                                                        <TableCell>{_.sumBy(_.values(parts),i=>i.tot.c).toFixed(2)}</TableCell>
                                                                        <TableCell>{_.sumBy(_.values(parts),i=>(i.tot.k) + (i.tot.c)).toFixed(2)}</TableCell>
                                                                        <TableCell>£{convertRealNumber(_.sumBy(_.values(parts),i=>i.tot.l) / _.sumBy(_.values(parts),i=>(i.tot.k) + (i.tot.c))).toFixed(2)}</TableCell>
                                                                        <TableCell>£{convertRealNumber(_.sumBy(_.values(parts),i=>i.tot.t) / _.sumBy(_.values(parts),i=>(i.tot.k) + (i.tot.c))).toFixed(2)}</TableCell>
                                                                        <TableCell>£{convertRealNumber(_.sumBy(_.values(parts),i=>i.tot.d) / _.sumBy(_.values(parts),i=>(i.tot.k) + (i.tot.c))).toFixed(2)}</TableCell>
                                                                        <TableCell>{ convertRealNumber( ( ( (_.sumBy(_.filter(parts,i=>i.ip==0||i.ip==1),i=>i.in.d - i.out.d) / _.sumBy(_.filter(parts,i=>i.ip==0||i.ip==1),i=>(i.in.k + i.out.k) + (i.in.c + i.out.c)) ) || 0) / ( (_.sumBy(_.filter(parts,i=>i.ip==0||i.ip==1),i=>i.in.s - i.out.s) / _.sumBy(_.filter(parts,i=>i.ip==0||i.ip==1),i=>(i.in.k + i.out.k) + (i.in.c + i.out.c)) ) || 0)) * 100 ).toFixed(2)}%</TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </PaddedPaper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/*
                                        <Grid item xs={12} lg={4}>
                                            <Grid container xs={12}>
                                                <Grid item xs={12} lg={12}>
                                                    <PaddedPaper>
                                                        <BarChart 
                                                            data={[..._.map(overview.breakDown, (value,label) => {
                                                                let color = {Service:colors.green,Product:colors.blue};
                                                                return _.assign({label,value,color})
                                                            })]} 
                                                            style={{ height: 245 }}
                                                            hideLegend
                                                            sidebar
                                                            xLabel={(v)=>{return `£${parseFloat(v).toFixed(0)}`}}
                                                            tLabel={(e)=>{return `£${parseFloat(e.raw).toFixed(2)}`}}
                                                        />
                                                    </PaddedPaper>
                                                </Grid>
                                            </Grid>
                                        </Grid>*/}
                                    </>
                                }
                                {!!parts &&
                                    <Grid item xs={12} lg={12}>
                                        <Card>
                                            <CardHeader
                                                avatar={<AllIcon icon={ icons.percent} size='medium' style={{color: '#fff'}} />}
                                                title={`Discounts Report For ${
                                                    filter.to === filter.from ?
                                                        moment(filter.from).format('dddd Do of MMMM') :
                                                        `${moment(filter.from).format('DD/MM/YYYY')} To ${moment(filter.to).format('DD/MM/YYYY')}`
                                                    }`}
                                                titleTypographyProps={{variant:'h6'}}
                                                className={classes.header}
                                            />
                                            <CardContent style={{padding:5}}>
                                                <Table size="small" aria-label="a dense table" style={{color:'black'}}>
                                                    <TableHead style={{backgroundColor:'#f5f5f5'}}>
                                                        <TableRow>
                                                            <TableCell align='center'   style={{width:'3%', borderBottom: 'none', color:'black'}}></TableCell>
                                                            <TableCell align='center'   style={{width:'3%', borderBottom: 'none', color:'black'}}></TableCell>
                                                            <TableCell align='center'   style={{width:'12%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Qty</b></Typography></TableCell>
                                                            <TableCell align='center'   style={{width:'16.4%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>List Price</b></Typography></TableCell>
                                                            <TableCell align='center'   style={{width:'16.4%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Net</b></Typography></TableCell>
                                                            <TableCell align='center'   style={{width:'16.4%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Discount £</b></Typography></TableCell>
                                                            <TableCell align='center'   style={{width:'16.4%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Discount %</b></Typography></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <OverviewRow data={_.filter(parts,{ip:1})} classes={classes} label='products'/>
                                                        <OverviewRow data={_.filter(parts,{ip:0})} classes={classes} label='services'/>
                                                        <OverviewRow data={_.values(parts)}        classes={classes} label='total'/>
                                                    </TableBody>
                                                </Table>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                }
                                <Grid item xs={12} lg={12} style={{padding:'1em 0px'}}>
                                    <Divider/>
                                </Grid>
                                {!!parts && 
                                    <>
                                        <Grid item xs={12} lg={12}>
                                            {/*<Typography variant='h4' style={{textAlign: 'left', paddingBottom:'1em'}}>Parts</Typography>*/}
                                            <DownloadCSV 
                                                data={this.getCsvData('parts')} color='primary' variant='contained' 
                                                filename={`${filter.from}_to_${filter.to}_part_report`} 
                                                style={{marginBottom: '1em'}} 
                                            >download Report</DownloadCSV>
                                            <DataTable 
                                                config={{
                                                    key: 'i',
                                                    pagination: false,
                                                    alternatingRowColours: true,
                                                    responsiveimportant: true,
                                                    isLoading: isLoading,
                                                    sticky:true
                                                }}
                                                columns={[
                                                    {
                                                        field: rowData =>   <div style={{width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                                {rowData && rowData.l ?
                                                                                    <Button onClick={() => this.loadPartImage(rowData)}>
                                                                                        <ImageWithError src={rowData.l} alt={`${rowData.n} - ${rowData.d}`} style={{maxWidth: 30, maxHeight: 30}}/>
                                                                                    </Button> 
                                                                                : 
                                                                                    <img src={logo} alt="logo" style={{maxHeight:30, maxWidth:30}}/>
                                                                                }
                                                                            </div>,
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading: 'Number',
                                                        field: rowData => 
                                                            <Grid container spacing={0}
                                                                onClick={()=>{
                                                                    this.props.history.push(`/parts/view/${rowData.i}`);
                                                                }}
                                                                style={{cursor: 'pointer'}}
                                                            >
                                                                <Grid item xs={12}>{rowData.n}</Grid>
                                                            </Grid>,
                                                        dataRef: 'n',
                                                        important: true,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Description',
                                                        field: rowData => 
                                                            <Grid container spacing={0}
                                                                onClick={()=>{
                                                                    this.props.history.push(`/parts/view/${rowData.i}`);
                                                                }}
                                                                style={{cursor: 'pointer'}}
                                                            >
                                                                <Grid item xs={12}>{rowData.p}</Grid>
                                                            </Grid>,
                                                        dataRef: 'p',
                                                        sizeToContent: true,
                                                        truncate: true
                                                    },
                                                    {
                                                        heading:        <>Total<br/>List Price</>,
                                                        field:          'tot.l',
                                                        dataRef:        'tot.l',
                                                        fieldPrefix:    rowData => rowData.tot.l < 0 ? '-£' : '£',
                                                        fieldFormat:    'realNumber:absolute:decimal:2',
                                                        sizeToContent:  true,
                                                        style:          {textAlign: 'right'},
                                                    },
                                                    {
                                                        heading:        <>Average<br/>List Price</>,
                                                        field:          rowData => Math.abs(rowData.tot.l/(rowData.tot.k + rowData.tot.c)),
                                                        dataRef:        'tot.l',
                                                        fieldFormat:    'realNumber:decimal:2',
                                                        fieldPrefix:    rowData => rowData.tot.l < 0 ? '-£' : '£',
                                                        sizeToContent:  true,
                                                        style:          {textAlign: 'right'},
                                                    },
                                                    {
                                                        heading:        <>Total<br/>Net</>,
                                                        field:          'tot.t',
                                                        fieldPrefix:    rowData => rowData.tot.t < 0 ? '-£' : '£',
                                                        fieldFormat:    'realNumber:absolute:decimal:2',
                                                        dataRef:        'tot.t',
                                                        sizeToContent:  true,
                                                        style:          {textAlign: 'right'},
                                                    },
                                                    {
                                                        heading:        <>Average<br/>Net</>,
                                                        field:          rowData => rowData.tot.t/(rowData.tot.k + rowData.tot.c),
                                                        fieldPrefix:    rowData => rowData.tot.t < 0 ? '-£' : '£',
                                                        fieldFormat:    'realNumber:absolute:decimal:2',
                                                        dataRef:        'tot.t',
                                                        sizeToContent:  true,
                                                        style:          {textAlign: 'right'},
                                                    },
                                                    {
                                                        heading:        <>Total<br/>Discount</>,
                                                        field:          'tot.d',
                                                        dataRef:        'tot.d',
                                                        fieldFormat:    'realNumber:absolute:decimal:2',
                                                        fieldPrefix:    rowData => rowData.tot.d < 0 ? '-£' : '£',
                                                        sizeToContent:  true,
                                                        style:          {textAlign: 'right'},
                                                    },
                                                    {
                                                        heading:        <>Average<br/>Discount</>,
                                                        field:          rowData => rowData.tot.d/(rowData.tot.k + rowData.tot.c),
                                                        dataRef:        rowData => parseFloat(rowData.tot.d/(rowData.tot.k + rowData.tot.c)),
                                                        fieldPrefix:    rowData => rowData.tot.d < 0 ? '-£' : '£',
                                                        fieldFormat:    'realNumber:absolute:decimal:2',
                                                        sizeToContent:  true,
                                                        style:          {textAlign: 'right'},
                                                    },
                                                    {
                                                        heading:        <>%<br/>Discount</>,
                                                        field:          rowData => (rowData.tot.d/rowData.tot.s)*100,
                                                        dataRef:        rowData => parseFloat((rowData.tot.d/rowData.tot.s)*100),
                                                        fieldFormat:    'realNumber:absolute:decimal:2',
                                                        fieldSuffix:    '%',
                                                        sizeToContent:  true,
                                                        style:          {textAlign: 'center'},
                                                    },  
                                                    {
                                                        heading:        <><br/>Qty</>,
                                                        field:          rowData => rowData.tot.c + rowData.tot.k,
                                                        dataRef:        rowData => parseInt(rowData.tot.c + rowData.tot.k),
                                                        sizeToContent:  true,
                                                        fieldFormat:    'realNumber:decimal:2',
                                                        style:          {textAlign: 'center'},
                                                    }, 
                                                    {
                                                        heading:        <><br/>Discount</>,
                                                        field:          'tot.c',
                                                        dataRef:        'tot.c',
                                                        fieldFormat:    'realNumber:decimal:2',
                                                        sizeToContent:  true,
                                                        style:          {textAlign: 'center'},
                                                    }, 
                                                    {
                                                        heading:        <>Not<br/>Discount</>,
                                                        field:          'tot.k',
                                                        fieldFormat:    'realNumber:decimal:2',
                                                        dataRef:        'tot.k',
                                                        sizeToContent:  true,
                                                        style:          {textAlign: 'center'},
                                                        
                                                    }, 
                                                ]}
                                                rows={_.orderBy(_.filter(parts, i => (i.in.c !== 0 || i.out.c !== 0)),['n'],['asc'])}
                                            />
                                        </Grid>
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </Grid>
        )
    }
}


const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    setPersistence: (key, state)                => dispatch(setPersistence(key, state)),
    deployDialog:   (content, title, size='sm') => dispatch(deployDialog(content, title, null, size)),
    closeDialog:    ()                          => {dispatch(closeDialog())}
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SalesReportDiscount));