import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import moment from 'moment';
import {colors} from 'Helpers/ColourHelper';

class ViewStaffMfaStatus extends React.Component {
    render() {
        const { status, isLoading, myAccount } = this.props;
        return (
            <React.Fragment>
                {(isLoading && (
                    <LoadingCircle />
                )) || (            
                    <Grid container alignItems='center' spacing={1}>
                        <Grid item align='center'>
                            <FALightIcon icon='shield-alt' style={{marginLeft: 8, color: status[0] === 1 ? colors.green : '#D61F26'}} size='large' />
                        </Grid>
                        <Grid item xs>
                            {(status[0] === 1 && (
                                <React.Fragment>
                                    <Typography variant="body1">
                                        Multi-factor authentication is enabled{myAccount && ` for your account`}
                                    </Typography>                             
                                    <Typography variant="body2">
                                        Activated on {moment(status[1]).format('dddd, MMMM Do YYYY [at] HH:mmA')}
                                    </Typography>                            
                                </React.Fragment>   
                            )) || (
                                <Typography variant="body1">
                                    Multi-factor authentication is not enabled{myAccount && ` for your account`}    
                                </Typography>       
                            )}
                        </Grid>         
                    </Grid>
                )}
            </React.Fragment>
        )
    }
}

export default ViewStaffMfaStatus;