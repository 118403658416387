import React, { Component } from 'react';

import { Typography, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import StockActivity from 'Components/Stock/StockActivity/StockActivity';
class Stock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    handleOpen = () => {
        this.setState({open: !this.state.open});
    }

    render() {
        const { open } = this.state;    
        const { stock, partId } = this.props;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={this.handleOpen}>
                    <Typography>Stock</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {open &&
                        <Grid container xs={12} style={{marginTop:'1em'}}>
                            <StockActivity partId={partId} />
                        </Grid>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default Stock;
