import React from 'react';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { Grid, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core/';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import API from 'API';
import DateRangeDialog from './DateRangeDialog';
import InfoBox from "Components/Common/reports/InfoBox";
import IconHelper from 'Helpers/IconHelper';
import _ from 'lodash';
import AllIcon from "Components/Common/Icons/AllIcon";
import { colors } from 'Helpers/ColourHelper';
import moment from 'moment';
import { clenyDate } from "Helpers/TimeHelper";
import LineChart from 'Components/Common/Charts/LineChart';
import PieChart from 'Components/Common/Charts/PieChart';

const initialState = (props) => ({
    isLoading: true,
    campaigns: [],
    emails: [],
    filter: {
        keyword: null,
        to: null,
        from: null
    }
})

const breakdownTableBorderRight = [
    1,0,1,1,0,1,0,1,0,1,0,0,0,0,0
]

class CampaignsOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({
            isLoading: true
        }, ()=> {
            this.getCampaigns();
            this.getCampaignsEmails();
        })
    }

    getCampaignsEmails = () => {
        API.get('/reports/marketing/campaignsEmails', {params: this.state.filter}).then(res => {
            let emails = res.data
            this.setState({
                emails: _.orderBy(_.map(_.keys(emails), key => ({
                    label:  moment(key).format('MM YYYY'),
                    date:   key,
                    value: {
                        Sent: emails[key]?.sent ?? 0,
                        Opened: emails[key]?.opened ?? 0,
                        Unsubscribed: emails[key]?.unsubscribed ?? 0,
                        Bounced: emails[key]?.bounced ?? 0,
                        complaints: emails[key]?.complaints ?? 0,
                    },
                    color: {
                        Sent: colors.primary,
                        Opened: colors.green,
                        Unsubscribed: colors.red,
                        Bounced: colors.orange,
                        complaints: colors.stickyNotes
                    }
                })), 'date'),
                isLoading: false
            })
        })
    }

    getCampaigns = () => {
        API.get('/reports/marketing/campaigns', {params: this.state.filter}).then(res => {
            this.setState({
                campaigns: res.data,
                isLoading: false
            })
        })
    }

    handleSetFilterParams = (filter) => { this.setState({filter}, this.getData)} 

    render() {
        const { isLoading, campaigns, filter } = this.state;

        if (isLoading) {
            return <LoadingCircle/>
        }

        return (
            <Grid container spacing={2}>
               <Grid item xs={3}>
                    <PaddedPaper style={{height: '100%'}}>
                        <DateRangeDialog
                            callback={this.handleSetFilterParams} 
                            formData={filter}
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={9}>
                    <Grid container spacing={2}>
                        <InfoBox rowSize={5} title="Campaigns" icon={IconHelper.emailCampaigns} value={campaigns.length} />
                        <InfoBox rowSize={5} color={colors.orange} title="In Progress" icon={IconHelper.dash} value={_.filter(campaigns, i => i.active).length} />
                        <InfoBox rowSize={5} color={colors.green} title="Completed" icon={IconHelper.true} value={_.filter(campaigns, i => !i.active).length} />
                        <InfoBox rowSize={5} title="Runs" icon={IconHelper.emailCampaigns} value={_.sumBy(campaigns, 'runs')} />
                        <InfoBox rowSize={5} title="Scheduled" icon={IconHelper.day} value={_.sumBy(campaigns, 'scheduled')} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <InfoBox rowSize={8} title="Contacts" icon={IconHelper.contacts} value={_.sumBy(campaigns, 'sent') + _.sumBy(campaigns, 'failed')} />
                        <InfoBox rowSize={8} title="Sent" icon={IconHelper.emailRuns} value={_.sumBy(campaigns, 'sent')} />
                        <InfoBox rowSize={8} title="Failed" icon={IconHelper.false} value={_.sumBy(campaigns, 'failed')} />
                        <InfoBox rowSize={8} title="Bounced" icon={IconHelper.emailBounced} value={_.sumBy(campaigns, 'bounced')} />
                        <InfoBox rowSize={8} title="Complaints" icon={IconHelper.emailComplaint} value={_.sumBy(campaigns, 'complaints')} />
                        <InfoBox rowSize={8} title="Opened" icon={IconHelper.emailOpen} value={_.sumBy(campaigns, 'opened')} />
                        <InfoBox rowSize={8} title="Unread" icon={IconHelper.email} value={_.sumBy(campaigns, 'unread')} />
                        <InfoBox rowSize={8} title="Unsubed" icon={IconHelper.ban} value={_.sumBy(campaigns, 'unsubscribed')} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Table>
                            <TableHead>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                {_.map(['Campaigns','Runs', 'Scheduled', 'Contacts','Sent', 'Failed', 'Bounced', 'Complaints', 'Opened','Unread','Unsubscribed'], (i, idx) => <TableCell style={{textAlign: 'center', fontWeight: 'bold', borderRight: breakdownTableBorderRight[idx] && `1px solid ${colors.disabled}`}}>{i}</TableCell>)}
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{textAlign: 'center', width: '1%'}}> <AllIcon size={30} icon={IconHelper.total} tooltip='Total' noMargin /><br/>Total</TableCell>
                                    <TableCell style={{textAlign: 'center', width: '1%'}}>
                                        <Grid container >
                                            <Grid item xs={12}><AllIcon noPadding style={{padding: 5}} tooltip='In Progress' noMargin size={17} icon={IconHelper.dash} color={colors.orange}/></Grid>
                                            <Grid item xs={12}><AllIcon noPadding style={{padding: 5}} tooltip='Completed' noMargin size={17} icon={IconHelper.true} color={colors.green}/></Grid>
                                            <Grid item xs={12}><AllIcon noPadding style={{padding: 5}} tooltip='All' noMargin size={17} icon={IconHelper.circle} /></Grid>
                                        </Grid>
                                    </TableCell>
                                    {_.map([
                                        [_.filter(campaigns, i => i.active).length,_.filter(campaigns, i => !i.active).length, campaigns.length],
                                        [_.sumBy(_.filter(campaigns, i => i.active), 'runs'),_.sumBy(_.filter(campaigns, i => !i.active), 'runs'), _.sumBy(campaigns, 'runs')],
                                        [_.sumBy(_.filter(campaigns, i => i.active), 'scheduled'),_.sumBy(_.filter(campaigns, i => !i.active), 'scheduled'), _.sumBy(campaigns, 'scheduled')],
                                        [_.sumBy(_.filter(campaigns, i => i.active), 'attempt'),_.sumBy(_.filter(campaigns, i => !i.active), 'attempt'), _.sumBy(campaigns, 'attempt')],
                                        [_.sumBy(_.filter(campaigns, i => i.active), 'sent'),_.sumBy(_.filter(campaigns, i => !i.active), 'sent'), _.sumBy(campaigns, 'sent')],
                                        [_.sumBy(_.filter(campaigns, i => i.active), 'failed'),_.sumBy(_.filter(campaigns, i => !i.active), 'failed'), _.sumBy(campaigns, 'failed')],
                                        [_.sumBy(_.filter(campaigns, i => i.active), 'bounced'),_.sumBy(_.filter(campaigns, i => !i.active), 'bounced'), _.sumBy(campaigns, 'bounced')],
                                        [_.sumBy(_.filter(campaigns, i => i.active), 'complaints'),_.sumBy(_.filter(campaigns, i => !i.active), 'complaints'), _.sumBy(campaigns, 'complaints')],
                                        [_.sumBy(_.filter(campaigns, i => i.active), 'opened'),_.sumBy(_.filter(campaigns, i => !i.active), 'opened'), _.sumBy(campaigns, 'opened')],
                                        [_.sumBy(_.filter(campaigns, i => i.active), 'unread'),_.sumBy(_.filter(campaigns, i => !i.active), 'unread'), _.sumBy(campaigns, 'unread')],
                                        [_.sumBy(_.filter(campaigns, i => i.active), 'unsubscribed'),_.sumBy(_.filter(campaigns, i => !i.active), 'unsubscribed'), _.sumBy(campaigns, 'unsubscribed')],
                                    ],(i, idx) =>
                                        <TableCell style={{textAlign: 'center', borderRight: breakdownTableBorderRight[idx] && `1px solid ${colors.disabled}`}}>
                                            <Grid container >
                                                {_.map(i, (_i, _idx) => <Grid item xs={12}><Typography variant='body1' style={{width: '100%', color: [colors.orange, colors.green,''][_idx]}}>{_i ? _i : '-'}</Typography></Grid>)}
                                            </Grid>
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{textAlign: 'center', width: '1%'}}> <AllIcon size={30} icon={IconHelper.average} tooltip='Average' noMargin /><br/>Average</TableCell>
                                    <TableCell style={{textAlign: 'center', width: '1%'}}>
                                        <Grid container >
                                            <Grid item xs={12}><AllIcon noPadding style={{padding: 5}} tooltip='In Progress' noMargin size={17} icon={IconHelper.dash} color={colors.orange}/></Grid>
                                            <Grid item xs={12}><AllIcon noPadding style={{padding: 5}} tooltip='Completed' noMargin size={17} icon={IconHelper.true} color={colors.green}/></Grid>
                                            <Grid item xs={12}><AllIcon noPadding style={{padding: 5}} tooltip='All' noMargin size={17} icon={IconHelper.circle} /></Grid>
                                        </Grid>
                                    </TableCell>
                                    {_.map([
                                        [
                                            _.filter(campaigns, i => i.active).length > 0 ? 1 : 0,
                                            _.filter(campaigns, i => !i.active).length > 0 ? 1 : 0,
                                            campaigns.length > 0 ? 1 : 0,
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'runs') / _.filter(campaigns, i => i.active).length,
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'runs') / _.filter(campaigns, i => !i.active).length,
                                            _.sumBy(campaigns, 'runs') / campaigns.length,
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'scheduled') / _.filter(campaigns, i => i.active).length,
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'scheduled') / _.filter(campaigns, i => !i.active).length,
                                            _.sumBy(campaigns, 'scheduled') / campaigns.length,
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'attempt') / _.filter(campaigns, i => i.active).length,
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'attempt') / _.filter(campaigns, i => !i.active).length,
                                            _.sumBy(campaigns, 'attempt') / campaigns.length,
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'sent') / _.filter(campaigns, i => i.active).length,
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'sent') / _.filter(campaigns, i => !i.active).length,
                                            _.sumBy(campaigns, 'sent') / campaigns.length,
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'failed') / _.filter(campaigns, i => i.active).length,
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'failed') / _.filter(campaigns, i => !i.active).length,
                                            _.sumBy(campaigns, 'failed') / campaigns.length,
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'bounced') / _.filter(campaigns, i => i.active).length,
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'bounced') / _.filter(campaigns, i => !i.active).length,
                                            _.sumBy(campaigns, 'bounced') / campaigns.length,
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'complaints') / _.filter(campaigns, i => i.active).length,
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'complaints') / _.filter(campaigns, i => !i.active).length,
                                            _.sumBy(campaigns, 'complaints') / campaigns.length,
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'opened') / _.filter(campaigns, i => i.active).length,
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'opened') / _.filter(campaigns, i => !i.active).length,
                                            _.sumBy(campaigns, 'opened') / campaigns.length,
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'unread') / _.filter(campaigns, i => i.active).length,
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'unread') / _.filter(campaigns, i => !i.active).length,
                                            _.sumBy(campaigns, 'unread') / campaigns.length,
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'unsubscribed') / _.filter(campaigns, i => i.active).length,
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'unsubscribed') / _.filter(campaigns, i => !i.active).length,
                                            _.sumBy(campaigns, 'unsubscribed') / campaigns.length,
                                        ],
                                    ], (i, idx) =>
                                        <TableCell style={{textAlign: 'center', borderRight: breakdownTableBorderRight[idx] && `1px solid ${colors.disabled}`}}>
                                            <Grid container >
                                                {_.map(i, (_i, _idx) => <Grid item xs={12}><Typography variant='body1' style={{width: '100%', color: [colors.orange, colors.green,''][_idx]}}>{_i ? parseFloat(_i).toFixed(2) : '-'}</Typography></Grid>)}
                                            </Grid>
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{textAlign: 'center', width: '1%'}}> <AllIcon size={30} icon={IconHelper.percent} tooltip='Percent' noMargin /><br/>Percent</TableCell>
                                    <TableCell style={{textAlign: 'center', width: '1%'}}>
                                        <Grid container >
                                            <Grid item xs={12}><AllIcon noPadding style={{padding: 5}} tooltip='In Progress' noMargin size={17} icon={IconHelper.dash} color={colors.orange}/></Grid>
                                            <Grid item xs={12}><AllIcon noPadding style={{padding: 5}} tooltip='Completed' noMargin size={17} icon={IconHelper.true} color={colors.green}/></Grid>
                                            <Grid item xs={12}><AllIcon noPadding style={{padding: 5}} tooltip='All' noMargin size={17} icon={IconHelper.circle} /></Grid>
                                        </Grid>
                                    </TableCell>
                                    {_.map([
                                        [
                                            _.filter(campaigns, i => i.active).length > 0 ? 1 : 0,
                                            _.filter(campaigns, i => !i.active).length > 0 ? 1 : 0,
                                            campaigns.length > 0 ? 1 : 0,
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'runs') /  _.sumBy(_.filter(campaigns, i => i.active), 'scheduled'),
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'runs') /  _.sumBy(_.filter(campaigns, i => !i.active), 'scheduled'),
                                            _.sumBy(campaigns, 'runs') /  _.sumBy(campaigns, 'scheduled')
                                        ],
                                        [
                                            _.filter(campaigns, i => i.active).length > 0 ? 1 : 0,
                                            _.filter(campaigns, i => !i.active).length > 0 ? 1 : 0,
                                            campaigns.length > 0 ? 1 : 0,
                                        ],
                                        [
                                            _.filter(campaigns, i => i.active).length > 0 ? 1 : 0,
                                            _.filter(campaigns, i => !i.active).length > 0 ? 1 : 0,
                                            campaigns.length > 0 ? 1 : 0,
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'sent') / (_.sumBy(_.filter(campaigns, i => i.active), 'failed') + _.sumBy(_.filter(campaigns, i => i.active), 'sent')),
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'sent') / (_.sumBy(_.filter(campaigns, i => !i.active), 'failed') + _.sumBy(_.filter(campaigns, i => !i.active), 'sent')),
                                            _.sumBy(campaigns, 'sent') / (_.sumBy(campaigns, 'failed') + _.sumBy(campaigns, 'sent')),
                                        ], 
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'failed') / (_.sumBy(_.filter(campaigns, i => i.active), 'failed') + _.sumBy(_.filter(campaigns, i => i.active), 'sent')),
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'failed') / (_.sumBy(_.filter(campaigns, i => !i.active), 'failed') + _.sumBy(_.filter(campaigns, i => !i.active), 'sent')),
                                            _.sumBy(campaigns, 'failed') / (_.sumBy(campaigns, 'failed') + _.sumBy(campaigns, 'sent')),
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'bounced') / _.sumBy(_.filter(campaigns, i => i.active), 'sent'),
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'bounced') / _.sumBy(_.filter(campaigns, i => !i.active), 'sent'),
                                            _.sumBy(campaigns, 'bounced') / _.sumBy(campaigns, 'sent'),
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'complaints') / _.sumBy(_.filter(campaigns, i => i.active), 'sent'),
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'complaints') / _.sumBy(_.filter(campaigns, i => !i.active), 'sent'),
                                            _.sumBy(campaigns, 'complaints') / _.sumBy(campaigns, 'sent'),
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'opened') / _.sumBy(_.filter(campaigns, i => i.active), 'sent'),
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'opened') / _.sumBy(_.filter(campaigns, i => !i.active), 'sent'),
                                            _.sumBy(campaigns, 'opened') / _.sumBy(campaigns, 'sent'),
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'unread') / _.sumBy(_.filter(campaigns, i => i.active), 'sent'),
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'unread') / _.sumBy(_.filter(campaigns, i => !i.active), 'sent'),
                                            _.sumBy(campaigns, 'unread') / _.sumBy(campaigns, 'sent'),
                                        ],
                                        [
                                            _.sumBy(_.filter(campaigns, i => i.active), 'unsubscribed') / _.sumBy(_.filter(campaigns, i => i.active), 'sent'),
                                            _.sumBy(_.filter(campaigns, i => !i.active), 'unsubscribed') / _.sumBy(_.filter(campaigns, i => !i.active), 'sent'),
                                            _.sumBy(campaigns, 'unsubscribed') / _.sumBy(campaigns, 'sent'),
                                        ],
                                        
                                    ], (i, idx) =>
                                    <TableCell style={{textAlign: 'center', borderRight: breakdownTableBorderRight[idx] && `1px solid ${colors.disabled}`}}>
                                        <Grid container >
                                            {_.map(i, (_i, _idx) => <Grid item xs={12}><Typography variant='body1' style={{width: '100%', color: [colors.orange, colors.green,''][_idx]}}>{_i ? `${parseFloat(_i*100).toFixed(2)}%` : '-'}</Typography></Grid>)}
                                        </Grid>
                                    </TableCell>
                                )}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={8}>
                    <PaddedPaper>
                        <LineChart 
                            data={this.state.emails} 
                            style={{ height: 250 }}
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={4}>
                    <PaddedPaper>
                        <PieChart
                            data={[
                                {label: 'Opened',      value: _.sumBy(campaigns, 'opened') / campaigns.length, color: colors.green},
                                {label: 'Unread',      value:  _.sumBy(campaigns, 'unread') / campaigns.length, color: '#dadada'},
                                {label: 'Failed To Send',    value:  _.sumBy(campaigns, 'failed') / campaigns.length, color: colors.red},
                                {label: 'Bounced',    value: _.sumBy(campaigns, 'bounced') / campaigns.length, color: colors.orange},
                                {label: 'Complaints',    value: _.sumBy(campaigns, 'complaints') / campaigns.length, color: colors.stickyNotes},
                            ]} 
                            style={{ height: 250 }}
                            legendPosition="right"
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <CiDataTable
                        config={{
                            key: 'id'
                        }}
                        rows={_.orderBy(campaigns, 'id', 'desc')}
                        columns={[
                            {
                                heading: 'Reference',
                                field: 'ref',
                                dataRef: 'ref',
                                sizeToContent: true
                            },
                            {
                                heading: 'name',
                                field: 'name',
                                dataRef: 'name',
                                truncate: true,
                            },
                            {
                                heading: 'status',
                                field: i => <AllIcon
                                    tooltip={i.active ? 'In Progress' : 'Completed'}
                                    icon={i.active ? IconHelper.dash : IconHelper.true}
                                    color={i.active ? colors.orange : colors.green}
                                    noMargin
                                />,
                                dataRef: 'active',
                                sizeToContent: true,
                                alignment: 'center', 
                            },
                            {
                                heading: 'runs',
                                field: 'runs',
                                dataRef: 'runs',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'scheduled',
                                field: 'scheduled',
                                dataRef: 'scheduled',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'contacts',
                                field: 'attempt',
                                dataRef: 'attempt',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'sent',
                                field: 'sent',
                                dataRef: 'sent',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'failed',
                                field: 'failed',
                                dataRef: 'failed',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'bounced',
                                field: 'bounced',
                                dataRef: 'bounced',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'complaints',
                                field: 'complaints',
                                dataRef: 'complaints',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'opened',
                                field: 'opened',
                                dataRef: 'opened',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'unread',
                                field: 'unread',
                                dataRef: 'unread',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'unsubscribed',
                                field: 'unsubscribed',
                                dataRef: 'unsubscribed',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'orders',
                                field: 'sales',
                                dataRef: 'sales',
                                sizeToContent: true,
                                alignment: 'center',
                                info: i => `£${parseFloat(i?.sales_total ?? 0).toFixed(2)}`
                            },
                            {
                                actions: i => ([
                                    {
                                        name: 'View Campaign',
                                        icon: IconHelper.emailCampaigns,
                                        link: `/marketing/campaigns/emailCampaigns/${i.id}`
                                    },
                                    {
                                        name: 'View Report',
                                        icon: IconHelper.search,
                                        onClick: this.props.setCampaignReport
                                    }
                                ])
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default CampaignsOverview;