import React from "react";
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import IconHelper from 'Helpers/IconHelper';

export default function ({contact}){
    return (
        <CiDataTable
            config={{
                key: 'address_id',
            }}
            rows={contact.addresses}
            columns={[
                {
                    heading: 'Type',
                    field: 'address_type',
                    dataRef: 'address_type',
                    sizeToContent: true,
                },
                {
                    heading: 'Name',
                    field: 'address_name',
                    dataRef: 'address_name',
                    sizeToContent: true,
                },
                {
                    heading: 'Address',
                    field: item => `${item.address_line_one + ', ' + item.address_town + ', ' + item.address_postcode}`,
                    dataRef: item => `${item.address_line_one + ', ' + item.address_town + ', ' + item.address_postcode}`,
                },
                {
                    actions: i => ([
                        {
                            name: 'View',
                            icon: IconHelper.view,
                            link: `/customers/addresses/view/${i.address_id}`,
                        }
                    ])
                }
            ]}
        />
    )
}