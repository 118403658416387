import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import Image         from 'Components/Common/ImageWithError/ImageWithError';
import Textarea      from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Qr            from 'Components/Common/QrReader/QrReader';
import NumberField   from 'Components/Common/Inputs/NumberField';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

const initialState = {
    isLoading: true,
    showQr:    true,
    stage:     {},
    parts:     [],
    formData:  {
        part:   {},
        partId: 0,
        qty:    0,
        notes:  null,
        reason: '',
    }
}

class Addition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            stage: this.props.stage,
        };
    }

    componentDidMount() { this.getParts();}

    getParts = () => {
        Promise.all([
            API.get('/worksOrders/additionalReasons/all'),
            API.get('/parts/all', { params: { use: 'partSearch' }})
        ]).then(([reasons,parts]) => {
            this.setState({
                isLoading: false,
                parts:     parts.data,
                reasons:   _.map(reasons.data, i => ({ value: i.woar_reason, label: i.woar_reason })),
            })
        });
    }

    handleScan = partNumber => {
        if (partNumber) {
            let part = _.find(this.state.parts, {part_number: partNumber});
            if (part && part.part_id !== this.props.worksOrder.wo_part_id) {
                this.setState({
                    showQr: false,
                    formData: {
                        ...this.state.formData,
                        part:   part,
                        partId: part.part_id,
                    }
                })
            } else {
                this.setState({
                    qrError: 'Part cannot be added'
                })
            }
        }
    }

    handleChange = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            }
        })
    }

    handleSubmit = () => {
        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/build/${this.props.worksOrder.build.wob_id}/stockAddition`, {
            part:       this.state.formData.partId,
            quantity:   this.state.formData.qty,
            stage:      this.state.stage.wobs_id,
            notes:      this.state.formData.notes,
            reason:     this.state.formData.reason,
        })
        .then(res => {
            this.setState({
                formData: initialState.formData
            },this.props.callBack);
        })
    }

    render() {

        const { isLoading, showQr, formData, reasons } = this.state;
        const { classes } = this.props;

        if (isLoading) return ( <LoadingCircle /> )

        return (
            <Grid container justify="center" className={`${classes.root} text-center`} style={{height: '100%', marginTop: 70}} spacing={8}>
                <Grid container item className={classes.qrRow}>
                    <Grid item xs={6}>
                        {showQr && <Qr handleScan={this.handleScan} width="100%" /> }
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>
                {formData.partId > 0 && 
                <>
                    <Grid item container xs={12} style={{alignItems: 'center'}}>
                        <Grid item container justify="center" className="text-center" spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h3">
                                    {formData.part.part_number}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    {formData.part.part_description}<br></br>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify="center" className="text-center" spacing={3}>
                                    <Grid item xs={12}>
                                        <Image src={formData.part.default_image?.file_path} className={classes.img} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify="center" className="text-center" spacing={3}>
                                    <Grid item style={{width: 400}}>
                                        <NumberField
                                            id="qty"
                                            name="qty"
                                            value={formData.qty}
                                            type="number"
                                            variant="outlined"
                                            onChange={(e)=>{this.handleChange('qty', e)}}
                                            margin="normal"
                                            className={classes.confirmQtyInputContainer}
                                            inputProps={{
                                                className: classes.confirmQtyInput,
                                                min: 0
                                            }}
                                            granularity={1}
                                            hidePlusMinusButtons
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{padding: 0, margin: 0}}></Grid>
                                    <Grid item style={{width: 400}}>
                                        <AutoCompleteSelect
                                            fullWidth
                                            label='Reason *'      
                                            onChange={(e)=>{this.handleChange('reason', e.value)}}                    
                                            value={formData.reason}
                                            options={reasons}
                                            noClear
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{width: 400}} >
                                <Textarea
                                    name        = 'notes'
                                    label       = 'Notes'
                                    onChange={(e)=>{this.handleChange('notes', e.target.value)}}
                                    value       = {this.state.formData.notes}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant='contained' color='primary' disabled={!parseFloat(formData.qty) || !formData.reason} onClick={()=>{
                                    this.props.deployConfirmation('Are you sure you want these additional parts? Note: Stock will be effected', 'Pick Additional Stock', this.handleSubmit)
                                }}>Confirm</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </>}
            </Grid>
        )
    }
}

const styles = theme => ({
    root: {
        height: '80vh',
        padding: theme.spacing(1)
    },
    img: {
        maxHeight: 200,
        maxWidth: '100%'
    },
    qrRow: {
        maxHeight: '30vw',
        maxWidth: '30vw',
        zIndex: 99999,
        position:'fixed',
        top: '6em',
        left: '5em'
    },
    confirmQtyInputContainer: {
        maxWidth: 400,
    },
    confirmQtyInput: {
        textAlign: 'center',
        maxWidth: 400,
        fontSize: 40,
    },
    [theme.breakpoints.down('sm')]: {
        img: {
            maxHeight: 240,
            maxWidth:  240
        },
        actionText: {
            fontSize: '2rem',
        },
        confirmQtyInputContainer: {
            maxWidth: 400,
        },
        confirmQtyInput: {
            maxWidth: 360,
            padding: 20,
            fontSize: 30,
        },
        largeButton: {
            fontSize: 18
        }
    }
});


function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(Addition))