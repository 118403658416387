import { Grid, Button } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { getOrdinal } from 'Helpers/TimeHelper';

const months = [null, 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const fmonths = ['October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September' ];

class MonthBlock extends React.Component {
    render() {
        const { year, current, showCurrent, view, setView, setCurrentView } = this.props;
        const currentMonth = _.indexOf(fmonths, months[moment().format('M')]);

        let
            currentYear = `${year-1}/${year}`,
            lastYear = 'Budget';

        let monthsButtons  = _.map(fmonths, (month, i) => {
            return {
                value: _.indexOf(months, month),
                label: month,
                allowCurrent: current ? 
                    month === months[moment().format('M')]
                : false,
                disabled: current ? (i > currentMonth) : false
            }
        });

        return (
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Grid container spacing={2} style={{alignItems: 'center'}}>
                        {!!current &&
                            <Grid item xs={4}>
                                <Button
                                    fullWidth
                                    variant={view.value === 'year' ? ( (!showCurrent ) ? 'outlined' : 'contained') : 'outlined'}
                                    color={view.value === 'year' ? ( (!showCurrent ) ? 'default' : 'primary') : 'default'}
                                    onClick={()=>setCurrentView(true, {value: 'year', allowCurrent: true})}
                                >
                                    {currentYear}<br/>
                                    Until <br/>
                                    {`${getOrdinal(moment().format('D'))} ${fmonths[currentMonth]}`}
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={4}>
                            <Button
                                fullWidth
                                variant={view.value === 'year' ? ( (showCurrent ) ? 'outlined' : 'contained') : 'outlined'}
                                color={view.value === 'year' ? ( (showCurrent ) ? 'default' : 'primary') : 'default'}
                                onClick={()=>setCurrentView(false, {value: 'year', allowCurrent: true})}
                            >
                                {currentYear}<br/>
                                vs.<br/>
                                {lastYear}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <Grid container spacing={2} style={{alignItems: 'center'}}>
                        {!!current &&
                            <Grid item xs={2}>
                                <Button
                                     fullWidth
                                     variant={view.value === _.indexOf(months, fmonths[currentMonth]) ? ( (!showCurrent ) ? 'outlined' : 'contained') : 'outlined'}
                                     color={view.value === _.indexOf(months, fmonths[currentMonth]) ? ( (!showCurrent ) ? 'default' : 'primary') : 'default'}
                                     onClick={()=>setCurrentView(true, {value: _.indexOf(months, fmonths[currentMonth]), allowCurrent: true})}
                                >
                                    {fmonths[currentMonth]}<br/>
                                    Until <br/>
                                    {`${getOrdinal(moment().format('D'))} ${fmonths[currentMonth]}`}
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={current ? 10: 12}>
                            <Grid container spacing={2}>
                                {_.map(monthsButtons, ({value, label, allowCurrent, disabled}) => {
                                    return (
                                        <Grid item xs={2}> 
                                            <Button
                                                disabled={disabled}
                                                fullWidth
                                                variant={view.value === value ? ( (allowCurrent && showCurrent ) ? 'outlined' : 'contained') : 'outlined'}
                                                color={view.value === value ? ( (allowCurrent && showCurrent ) ? 'default' : 'primary') : 'default'}
                                                onClick={()=>
                                                    allowCurrent ?
                                                        setCurrentView(false, {value, label, allowCurrent}) :
                                                        setView({value, label, allowCurrent}) 
                                                    }
                                            >
                                                {label}
                                            </Button>
                                        </Grid>    
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default MonthBlock;