import React from 'react';

import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher';

import EmailCampaigns from './Tabs/EmailCampaigns';
import EmailTemplates from './Tabs/EmailTemplates';
import EmailSchedule from './Tabs/EmailSchedule';

//const defaultTab = 'Email Validation';
function Campaigns (props) {
    return (
        <TabBarSwitcher
            tabs={[            
                {
                    label: 'Email Campaigns',
                    content: <EmailCampaigns/>
                },
                {
                    label: 'Email Schedule',
                    content: <EmailSchedule/>
                },
                {
                    label: 'Email Templates',
                    content: <EmailTemplates/>
                },
            ]}
        />
    );
}

export default Campaigns;