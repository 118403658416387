import React, { useEffect, useState } from "react";
import _ from "lodash";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import Alert from "Components/Common/Alert/Alert";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { FormControl, ListItemSecondaryAction } from "@material-ui/core";

const WildixCallTransfer = ({
    activeCalls,
    call,
    callData,
    extension,
    getCallStatusClass,
    handleClose,
    handleGetPresenceClassName,
    handleGetPresenceColor,
    handleGetPresenceIcon,
    handleGetPresencePriority,
    handleGetPresenceText,
    onCallAttendantTransfer,
    onCallHangup,
    onDial,
    presence,
    roster,
}) => {
    const [transferTo, setTransferTo] = useState(""),
        [transferGo, setTransferGo] = useState(false),
        [transferLd, setTransferLd] = useState(false),
        [err, setError] = useState(false);

    const handleChangeBlindTransfer = (_to) => {
        let to = _to ?? "";
        let number = to.replace(/\s/g, "");

        if (number.startsWith("0")) {
            number = `+44${number.substring(1)}`;
        }

        setError(false);

        const phoneRegExp = new RegExp(/^(\+\d{10,15})*$/),
            numberRegExp = new RegExp(/^((\+\d+)|(#|\*|\d)+)$/);

        if (!number.match(numberRegExp) && !number.match(phoneRegExp)) {
            setError(true);
            return false;
        }

        setTransferTo(number);
    };

    const handleInitiateTransfer = () => {
        onDial(transferTo);
        setTransferGo(true);
    };

    const handleAcceptTransfer = (bridgeCall) => {
        onCallAttendantTransfer(call, bridgeCall);
        handleClose();
    };

    const handleCancelTransfer = () => {
        const otherCalls = _.filter(activeCalls, (c) => c.getChannel() !== call.getChannel() && c.getCalleeNumber() === transferTo);
        if (otherCalls.length > 0) {
            otherCalls.forEach((c) => {
                onCallHangup(c);
            });
        }
        setTransferTo("");
        setTransferGo(false);
    };

    const data = callData?.find?.((c) => c.ch === call.getChannel())?.dt ?? null;

    const transferCall = !transferGo ? null : _.find(activeCalls, (c) => c.getChannel() !== call.getChannel() && c.getCalleeNumber() === transferTo);

    useEffect(() => {
        if (transferGo) {
            setTransferLd(true);
        }
    }, [transferGo]);

    useEffect(() => {
        if (transferGo && transferCall && transferLd) {
            setTransferLd(false);
        }
    }, [transferGo, transferCall, transferLd]);

    const CallBox = ({ isConnecting, isRinging, name, number }) => {
        return (
            <Box mt={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12} align="center">
                        <FAIcon
                            className={isRinging ? "textPrimary icon-shake" : ""}
                            icon={isConnecting ? "signal-stream" : isRinging ? "phone-volume" : "phone-arrow-up-right"}
                            type="solid"
                            size={40}
                        />
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography variant="h6" className="fw-400" align="center">
                            {name ?? number} ({number})
                        </Typography>
                        <Typography variant="body1" className={`fw-400 ${transferCall ? getCallStatusClass(transferCall) : "textDefault"}`} align="center">
                            {isConnecting ? "Dialling" : isRinging ? "Ringing" : "Connected"}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    return (
        <Dialog disableEscapeKeyDown disableBackdropClick fullWidth maxWidth="xs" onClose={handleClose} open={true}>
            <DialogTitle>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Typography variant="h6">Call Transfer</Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Close">
                            <IconButton onClick={handleClose}>
                                <FAIcon disabled={transferGo} icon="times" button noMargin />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className="pt-0">
                <List dense className="pt-1 pb-1 pl-2 pr-1 content-light-white">
                    <ListItem disableGutters style={{ paddingRight: 90 }}>
                        {!call.isIncoming() ? (
                            <ListItemAvatar>
                                <FAIcon icon={`phone-arrow-up-right`} type="light" />
                            </ListItemAvatar>
                        ) : null}
                        <ListItemText
                            primary={
                                <>
                                    {data?.fn && (
                                        <Typography className={`fw-400`} variant="body1">
                                            {data.fn} {data.ln}
                                        </Typography>
                                    )}
                                    <Typography className={`fw-400`} variant="body1">
                                        {data?.cn ? data.cn : call.getCalleeName()}
                                        {data?.as ? ` (${data.as})` : ``}
                                    </Typography>
                                </>
                            }
                            secondary={
                                <Typography variant="body1" color="textSecondary">
                                    {call.getCalleeNumber()}
                                </Typography>
                            }
                        />
                        <ListItemSecondaryAction style={{ right: 8 }}>
                            <Typography align="right" variant="body1" className={getCallStatusClass(call)}>
                                {call.getFormattedState()}
                            </Typography>
                            <Typography align="right" variant="body1">
                                {call.getFormattedDuration()}
                            </Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                <Box my={2.5}>
                    <Grid container spacing={3}>
                        {(transferGo && (
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {(transferLd && (
                                        <Grid item xs={12}>
                                            <CallBox number={transferTo} isConnecting />
                                        </Grid>
                                    )) || (
                                        <>
                                            {(transferCall && (
                                                <Grid item xs={12}>
                                                    <CallBox
                                                        name={transferCall.getCalleeName()}
                                                        number={transferCall.getCalleeNumber()}
                                                        isRinging={transferCall.isRinging()}
                                                    />
                                                </Grid>
                                            )) || (
                                                <Grid item xs={12}>
                                                    <Alert severity="error">
                                                        <strong>Transfer Terminated</strong>
                                                        <Typography variant="body2">
                                                            The call you are trying to transfer to is no longer active. The user may be unavailable or the call may have been
                                                            terminated - please <em>Abort Transfer</em>.
                                                        </Typography>
                                                    </Alert>
                                                </Grid>
                                            )}
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Button
                                                    disabled={!transferCall || (transferCall && (transferCall.isRinging() || transferCall.getDuration() < 2))}
                                                    onClick={() => handleAcceptTransfer(transferCall)}
                                                    variant="contained"
                                                    className="textLight"
                                                    color="primary"
                                                    fullWidth
                                                    size="large"
                                                >
                                                    <FAIcon
                                                        icon="check"
                                                        className="mr-1"
                                                        disabled={!transferCall || (transferCall && transferCall.isRinging())}
                                                        size={15}
                                                        button
                                                    />
                                                    Complete Transfer
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button onClick={handleCancelTransfer} variant="outlined" color="default" fullWidth size="large">
                                                    <FAIcon icon="times" size={15} button />
                                                    Abort Transfer
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )) || (
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            <FAIcon icon="info-circle" size={12.5} button />
                                            This will connect you to the selected extension and if they want to accept the call, you can then complete the transfer.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <AutoCompleteSelect
                                                creatable
                                                creatableMessage={(userInput) => `Transfer to: ${userInput}`}
                                                error={err}
                                                errorText={err && "Please enter a valid number"}
                                                placeholder="Select an extension or type a number"
                                                onChange={(o) => handleChangeBlindTransfer(o?.value ?? "")}
                                                options={_.orderBy(
                                                    _.map(
                                                        _.filter(roster, (r) => r.getExtension() !== extension),
                                                        (_u) => {
                                                            const p = presence?.[_u.getExtension()] ?? null;
                                                            return _.assign({
                                                                value: _u?.getExtension(),
                                                                label: _u?.getName() !== _u?.getExtension() ? `${_u?.getName()} (${_u?.getExtension()})` : _u?.getExtension(),
                                                                icon: p ? handleGetPresenceIcon(p, false) : "phone-arrow-down-left",
                                                                color: p ? handleGetPresenceColor(p, false) : "#000",
                                                                className: p ? handleGetPresenceClassName(p, false) : "",
                                                                text: p ? handleGetPresenceText(p, false) : "Unknown",
                                                                order: p ? handleGetPresencePriority(p) : 99,
                                                                name: _u?.getName(),
                                                            });
                                                        },
                                                        ["order", "name"],
                                                        ["asc", "asc"]
                                                    )
                                                )}
                                                formatOptionLabel={({ label, icon, color, className, text }) => (
                                                    <Box pt={1} pb={1} pl={1}>
                                                        <Grid container alignItems="center">
                                                            <Grid item>
                                                                <FAIcon type="solid" icon={icon ?? "phone-arrow-up-right"} color={color ?? "#D61F26"} size={15} button />
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Typography variant="body2" component="div">
                                                                    <strong>{label}</strong>
                                                                </Typography>
                                                                <Typography variant="caption" className={className} component="div">
                                                                    <strong>{text}</strong>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                )}
                                                value={transferTo}
                                                variant="outlined"
                                                noMargin
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Button
                                                    disabled={err || transferTo === ""}
                                                    onClick={handleInitiateTransfer}
                                                    variant="contained"
                                                    color="primary"
                                                    className="textLight"
                                                    fullWidth
                                                    size="large"
                                                >
                                                    <FAIcon className="mr-1" disabled={err || transferTo === ""} icon="check" size={15} buttonPrimary />
                                                    Start Transfer
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button onClick={handleClose} variant="outlined" color="default" fullWidth size="large">
                                                    <FAIcon icon="times" size={15} button />
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default WildixCallTransfer;
