import React, { Component } from 'react';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import DataTable   from 'Components/Common/DataTables/CiDataTable';
import AllIcon     from 'Components/Common/Icons/AllIcon';
import MediaTable  from 'Components/Common/DataTables/MediaTable';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import { Button, Grid, Typography } from '@material-ui/core/';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import logo from 'Assets/Images/clenaware_logo_icon.png';
import Textarea from 'Components/Common/Inputs/Textarea';

const initialState = {
    worksOrder: {}, 
    stages:     [],
}

function InfoBox({heading, value, icon}) {
    return (
        <Grid item xs={6} lg={3}>
            <PaddedPaper style={{textAlign:'center'}}>
                <AllIcon icon={icon} size='xlarge' />
                <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>{heading}</Typography>
                <Typography variant="h4" style={{textAlign:'center', paddingTop:'0.5em'}}>{value}</Typography>
            </PaddedPaper>
        </Grid>
    )
}

class FirstOffChecks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState, 
            worksOrder: this.props.worksOrder,
            stages:     _.filter(this.props.worksOrder.build.stages, i => i.wobs_first_pass || i.declined.length > 0),
        };
    }
    
    render(){

        const { stages } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={3}>
                            <Grid item container spacing={3} xs={12} style={{justifyContent: 'center'}}>
                                <InfoBox heading='Approved'      value={_.sumBy(stages, stage => stage.wobs_first_pass)} icon={icons.true}/>
                                <InfoBox heading='Declined'      value={_.sumBy(stages, stage => stage.declined.length)} icon={icons.false}/>
                            </Grid>
                            <Grid item xs={12} container spacing={3}>
                                {_.map(stages, stage => {
                                    let items = _.map(stage.declined, i => ({
                                        key:        `d${i.wobsfod_id}`,
                                        approved:   0,
                                        notes:      i.wobsfod_reason,
                                        time:       i.wobsfod_datetime,
                                        supervisor: i.wobsfod_supervisor,
                                        builder:    i.wobsfod_builder,
                                        files:      i.files,
                                    }));

                                    if (stage.wobs_first_pass)
                                        items.push({
                                            key:        `f${stage.wobs_id}`,
                                            files:      stage.files,
                                            approved:   1,
                                            notes:      stage.wobs_first_pas_notes,
                                            time:       stage.wobs_first_pass_datetime,
                                            supervisor: stage.wobs_first_pass_supervisor,
                                            builder:    stage.wobs_first_pass_builder,
                                        });

                                    return (
                                        <Grid item xs={12}>
                                            <PaddedPaper>
                                                <Typography variant="h6" style={{marginBottom: 15}}>Stage {stage.instruction.sais_number}: {stage.instruction.sais_name}</ Typography>
                                                <DataTable
                                                    config={{
                                                        key:    'key',
                                                        style:  r => ({color: !r.approved && colors.red}),
                                                        dropRow: {
                                                            droppable: true,
                                                            columns: [{
                                                                colSpan: 5,
                                                                field:   i => 
                                                                    <Grid container>
                                                                        {i.notes && 
                                                                            <Grid item xs={12}>
                                                                                <Textarea
                                                                                    label={'Notes/Reason'}
                                                                                    value={i.notes}
                                                                                    disabled
                                                                                    inputProps={{
                                                                                        style: {fontWeight: 'normal', color: colors.black}
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {i?.files?.length > 0 &&
                                                                            <Grid item xs={12}>
                                                                                <MediaTable
                                                                                    config={{
                                                                                        key: 'wobsfof_id'
                                                                                    }}
                                                                                    items={i.files}
                                                                                    media='file_url'
                                                                                    fields={[{
                                                                                        actions: r => ([{
                                                                                            download: true
                                                                                        }])
                                                                                    }]}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                            }]
                                                        }
                                                    }}
                                                    columns={[
                                                        {
                                                            heading:       'Date',
                                                            field:         'time',
                                                            fieldFormat:   'clenydate',
                                                            alignment:     'left',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading:       'First Off Checked By',
                                                            field:         'supervisor',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading:       'Builder',
                                                            field:         'builder',
                                                        },
                                                        {
                                                            heading:       'Notes',
                                                            field:         'notes',
                                                            fieldFormat:   'boolean',
                                                            sizeToContent: true,
                                                            alignment:     'center',
                                                        },
                                                        {
                                                            heading:       'Files',
                                                            field:         i => i?.files?.length,
                                                            fieldFormat:   'boolean',
                                                            sizeToContent: true,
                                                            alignment:     'center',
                                                        }
                                                    ]}
                                                    rows={items}
                                                />
                                            </PaddedPaper>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
            </Grid>
        )
    }

}


function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff
    };
}

const mapDispatchToProps = dispatch => ({
    deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:        ()                              => dispatch(closeDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(FirstOffChecks)