import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';

const styles = theme => ({
    container: {
        paddingBottom: theme.spacing(1)
    },
    header: {
        padding: theme.spacing(1, 2),
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
    },
})

const AlertColor = type => {
    let color
    switch(type) {
        case "overdue":
            color = "#d32f2f"
        break;
        case "duetoday":
            color = "#E65100"
        break;    
        default:
            if(type) {
                color = "#388E3C"
            } else {
                color = "#757575"
            }
        break;
    }
    return color
}

const AlertBgColor = type => {
    let color
    switch(type) {
        case "overdue":
            color = "#ffcdd2"
        break; 
        case "duetoday":
            color = "#FFE0B2"
        break;     
        default:
            if(type) {
                color = "#C8E6C9"
            } else {
                color = "#EEEEEE"
            }
        break;
    }
    return color
}

const AlertIcon = type => {
    let icon
    switch(type) {
        case "overdue":
            icon = "exclamation-triangle"
        break;
        case "duetoday":
            icon = "calendar-exclamation"
        break;   
        default:
            if(type) { 
                icon = "calendar-check"
            } else {
                icon = "calendar-times"
            }
        break;
    }
    return icon
}

const AlertText = (type, date) => {
    let text
    switch(type) {
        case "overdue":
            text = "Overdue"
        break;
        case "duetoday":
            text = "Due Today"
        break;   
        default:
            if(type) {
                if(type === "completed") {
                    text = `Completed ${date}`
                } else {
                    text = `Due ${date}`
                }
            } else {
                text = "No Deadline"
            }
        break;
    }
    return text
}

const TaskAlert = props => (
    <Grid container>
        <Grid item xs={12} className={!props.inDialog && props.classes.header} style={{backgroundColor: !props.inDialog && AlertBgColor(props.type)}}>
            <Grid container spacing={1} alignItems='center'>
                <Grid item>
                    <FALightIcon icon={AlertIcon(props.type)} style={{color: props.inDialog ? '#fff' : AlertColor(props.type), width: props.inDialog && 25, height: props.inDialog && 25}} noMargin /> 
                </Grid>
                <Grid item xs>
                    <Typography variant={!props.inDialog ? 'caption' : 'h6'} style={{color: props.inDialog ? '#fff' : AlertColor(props.type)}}>
                        {AlertText(props.type, props.date)}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
)

export default withStyles(styles)(TaskAlert);