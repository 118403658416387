import React, { Component } from "react";
import _ from "lodash";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";

import FASolidIcon from "Components/Common/Icons/FontAwesome/FASolidIcon";
import TypographyAllowHtml from "Components/Common/Typography/TypographyAllowHtml";

import { clenyDate } from "Helpers/TimeHelper";

import TabBarSwitcher from "../Navigation/TabBarSwitcher";

/**
 * filter   => [ { label: $label, types: [ $customer_activity_log_type ]  } ]
 * atl      => activity log data object
 * fields   => {    date:   date field,
                    icon:   { color: icon color field, icon: icon icon field },
                    text:   acl text field,
                    staff:  staff name field,}
 */

class ActivityLog extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { fields, atl, filter } = this.props;

        const TimeLine = ({ logs, fields }) => {
            return (
                <VerticalTimeline layout="1-column">
                    {logs.map((log, key) => (
                        <VerticalTimelineElement
                            key={key}
                            className="vertical-timeline-element--work"
                            date={clenyDate(log[fields.date], true) + " - " + log[fields.staff]}
                            iconStyle={{ backgroundColor: log[fields.icon.color], color: "white" }}
                            icon={<FASolidIcon icon={log[fields.icon.icon]} style={{ color: "white" }} />}
                        >
                            <h3 className="vertical-timeline-element-title">{log[fields.type]}</h3>
                            {log[fields.text] &&
                                log[fields.text].split("\n").map((item, key) => {
                                    switch (log[fields.type]) {
                                        default:
                                            return (
                                                <span key={key}>
                                                    <TypographyAllowHtml body={`${item} <br/>`} />
                                                </span>
                                            );
                                    }
                                })}
                        </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
            );
        };

        let tabs = [];
        if (!this.props.disableAll) {
            tabs.push({
                label: "All",
                content: <TimeLine logs={atl} fields={fields} />,
            });
        }
        if (filter) {
            filter.forEach((f, idx) => {
                tabs.push({
                    label: f.label,
                    content: (
                        <TimeLine
                            logs={_.filter(atl, (i) => (f.filter ? f.filter(i) : f.types.includes(i[fields.type])))}
                            fields={fields}
                        />
                    ),
                });
            });
        }

        if (this.props.disableTabBar){
            return tabs[0].content;
        }

        return <TabBarSwitcher nopad tabs={tabs} />;
    }
}

export default ActivityLog;
