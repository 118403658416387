import { Grid, Link, Typography } from '@material-ui/core';
import { deployDialog } from 'Actions/Dialog/Dialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import React from 'react';
import { connect } from 'react-redux';
import ViewEmailDialog from '../Dialogs/ViewEmailDialog';
const initialState = {
    isLoading: true,
    text: null
}

class ViewLinked extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getLinked();
    }

    componentDidUpdate(prevProps){
        if ((prevProps.source !== this.props.source) || (prevProps.source_data !== this.props.source_data)){
            this.setState(initialState, () => {
                this.getLinked();
            })
        }
    }

    getLinked = () => {
        let text = null;
        let {email} = this.props;
        switch (email.contact.etc_source){
            case 'Resend':
                text = <>
                    This email has been resent <Link
                        onClick={()=>this.props.deployDialog(
                            <ViewEmailDialog
                                emailId={email.contact.etc_source_data}
                            />
                        )}
                    >view original</Link>
                </>;
                    this.setState({
                    isLoading: false,
                    text: text,
                });
                break;
            case 'Forwarded':
                text = <>
                    This email has been forwarded <Link
                        onClick={()=>this.props.deployDialog(
                            <ViewEmailDialog
                                emailId={email.contact.etc_source_data}
                            />
                        )}
                    >view original</Link>
                </>;
                this.setState({
                    isLoading: false,
                    text: text,
                });
                break;
            case 'CC':
                text = <>
                    This email is a carbon copy <Link
                        onClick={()=>this.props.deployDialog(
                            <ViewEmailDialog
                                emailId={email.contact.etc_source_data}
                            />
                        )}
                    >view original</Link>
                </>;
                this.setState({
                    isLoading: false,
                    text: text,
                });
                break;
            case 'Quotation':
                text = <>Email for quotation: <Link href={`/sales/quotation/view/${email.quotation.quotation.quote_id}`}>{email.quotation.quotation.quote_reference}-{email.quotation.quotation.quote_reference_amendment}</Link></>; 
                this.setState({
                    isLoading: false,
                    text: text,
                });
                break;
            case 'Order':
                text = <>Email for order: <Link href={`/sales/order/view/${email.order.order.order_id}`}>{email.order.order.order_reference}-{email.order.order.order_reference_amendment}</Link></>; 
                this.setState({
                    isLoading: false,
                    text: text,
                });
                break;
            case 'Credit Note':
                text = <>Email for credit note: <Link href={`/returns/creditnote/${email.credit_note.credit_note.cn_id}`}>{email.credit_note.credit_note.cn_reference}-{email.credit_note.credit_note.cn_reference_amendment < 10 ? `0${email.credit_note.credit_note.cn_reference_amendment}` : email.credit_note.credit_note.cn_reference_amendment}</Link></>; 
                this.setState({
                    isLoading: false,
                    text: text,
                });
                break;
            case 'Customer Return':
                text = <>Email for customer return: <Link href={`/returns/customerReturn/${email.customer_return.customer_return.cr_id}`}>{email.customer_return.customer_return.cr_reference}-{email.customer_return.customer_return.cr_reference_amendment < 10 ? `0${email.customer_return.customer_return.cr_reference_amendment}` : email.customer_return.customer_return.cr_reference_amendment}</Link></>; 
                this.setState({
                    isLoading: false,
                    text: text,
                });
                break;
            default:
                break;
        }
    }

    render(){
        const { isLoading, text} = this.state;

        if (isLoading || !text){
            return <></>
        }

        return (
            <Grid item xs={12}
                style={{
                    paddingLeft: '1em',
                    borderBottom: `1px solid ${colors.primary}10`
                }}
            >
                <Typography variant="caption">
                    <AllIcon icon={IconHelper.info} size='small'/> {text}
                </Typography>
            </Grid>
        )
    }
}



const mapDispatchToProps = dispatch => ({
    deployDialog:       (content)    => dispatch(deployDialog(content, 'View Email', null, 'md')),
})

export default connect(null, mapDispatchToProps)(ViewLinked);
