import React  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
    Button,
    Grid,
    Typography,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Link,
    TableHead,
    Tooltip,
    TextField
} from '@material-ui/core';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { handleDateChange, getFormData, handleFileChange, handleChange, handleSelectChange } from 'Functions/FormFunctions';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { downloadS3File, toggleDialog } from 'Functions/MiscFunctions';
import DragFileInputMulti from 'Components/Common/Inputs/DragFileInputMulti';
import Textarea from 'Components/Common/Inputs/Textarea';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

const initialState = () => ({
    maintenanceId: 0,
    maintenance: {},
    formData: {
        type: '',
        date: null,
        description: '',
        details: '',
        location: '',
        documents: '',
        mileage: ''
    },
    formErrors: [],
    isLoading: true,
    readOnly: false
});

class VehicleMaintenanceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
        this.getFormData = getFormData.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
        this.handleFileChange = handleFileChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData(); 
    }

    loadComponentData = () => {

        const { maintenanceId, readOnly } = this.props;

        this.setState({
            ...this.state,
            isLoading: false,
            maintenanceId,
            readOnly
        },
        () => {
            if (this.state.maintenanceId) {
                this.getMaintenance();
            }
        });
    }

    getMaintenance = () => {
        this.setState({
            isLoading: true,
        }, 
        () => {
            API.get(`/vehicles/${this.props.vehicleId}/maintenance/${this.state.maintenanceId}`)
            .then(res => {
    
                let maintenance = res.data;
    
                this.setState({
                    ...this.state,
                    isLoading: false,
                    maintenance,
                    formData: {
                        ...this.state.formData,
                        type: maintenance?.type,
                        date: maintenance?.date,
                        description:  maintenance?.desc,
                        details: maintenance?.details,
                        location:  maintenance?.location,
                        documents: '',
                        mileage: maintenance?.mileage ?? ''
                    }
                });
            });
        });
    }

    handleSubmit = () => {
        const { maintenanceId } = this.state;
        const route = maintenanceId ? `/vehicles/${this.props.vehicleId}/maintenance/${this.state.maintenanceId}`: `/vehicles/${this.props.vehicleId}/maintenance`;

        this.setState({
            isLoading: true,
        }, 
        () => {

            API.post(route, this.getFormData())
            .then(res => {
                if(res.data.errors && res.data.errors.length > 0) {
                    this.setState({
                        formErrors: formatValidationErrors(res.data.errors),
                        isLoading: false
                    });
                } else {
                    this.props.deploySnackBar("success", `The maintenance was successfully ${maintenanceId ? 'updated' : 'added'}` );
                    this.props.refresh(); 
                }
            });
        });
    }

    deleteDocument = (documentId) => {

        this.setState({
            isLoading: true,
        }, 
        () => {

            API.post(`/vehicles/${this.props.vehicleId}/maintenance/documents/${documentId}/delete`)
            .then(res => {
                if(res.data.errors && res.data.errors.length > 0) {
                    this.props.deploySnackBar("error", `There was an error deleting the document`);

                } else {
                    this.setState({
                        ...initialState(),
                    }, () => {
                        this.props.deploySnackBar("success", `The document was successfully deleted.`);
                        this.loadComponentData(); 
                    })
                }
            });
        });
    }

    handleUploads = (drop, name, event) => {
        let newFiles = drop === true ? event.dataTransfer.files : event.target.files;
        let existingFiles = this.state.formData.documents;
        let allFiles = Array.prototype.slice.call(existingFiles).concat(Array.prototype.slice.call(newFiles))
        this.setState({
            formData: {
                ...this.state.formData,
                documents: allFiles
            }
        });
    }

    clearUploads = idx => {
        let documents = Array.from(this.state.formData.documents);
        documents.splice(idx, 1);
        if(documents.length === 0){
            documents = '';
        }
        this.setState({
            formData:{
                ...this.state.formData,
                documents
            }
        });
    }

    render() {
        const { formErrors, formData, isLoading, maintenanceId, maintenance, readOnly } = this.state;
        return (   
            <>   
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {readOnly ? 'Maintenance Details' : (maintenanceId ? 'Update Maintenance' : 'Add Maintenance')}
                    </Typography>
                </Grid>     
                <Grid item xs={12}>
                    <form noValidate>
                        {(isLoading && (
                            <LoadingCircle />
                        )) || (
                            <Grid container item spacing={1}>
                                {formErrors && formErrors.generic && (
                                    <Grid item xs={12}>
                                        <Typography component={"div"} style={{color: 'red'}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <AutoCompleteSelect 
                                        options={[
                                            {value: 'Service', label: 'Service'},
                                            {value: 'Repair', label: 'Repair'},
                                        ]} 
                                        label={"Type " + (maintenanceId ? '' : '*')}
                                        onChange={v => this.handleSelectChange('type', v)}
                                        value={formData?.type}
                                        noClear
                                        fullWidth
                                        margin="normal"
                                        disabled={readOnly || maintenanceId}
                                        inputProps={{
                                            className:"textDefault"
                                        }}
                                        error={formErrors && formErrors['type'] && true}
                                        errorText={formErrors && formErrors['type']}
                                    />
                                </Grid>
                                {formData?.type == 'Service' ?
                                    <Grid item xs={12}>
                                        <TextField  
                                            name="mileage"
                                            label={"Mileage " + (maintenanceId ? '' : '*')}
                                            margin="none"
                                            error={!!formErrors && formErrors['mileage'] && true}
                                            helperText={formErrors && formErrors['mileage']}
                                            value={formData.mileage || ''}
                                            onChange={this.handleChange}
                                            fullWidth
                                            type='number'
                                            disabled={readOnly}
                                            inputProps={{
                                                className:"textDefault"
                                            }}
                                        />
                                    </Grid>
                                : null }
                                <Grid item xs={12}>
                                    <DatePicker
                                        type="date"
                                        id="date"
                                        name="date"
                                        label={"Date " + (maintenanceId ? '' : '*')}
                                        value={formData.date}
                                        errorText={formErrors && formErrors['date']}
                                        onChange={date => this.handleDateChange('date', date, 'YYYY-MM-DD')}
                                        autoOk={true}
                                        inputProps={{
                                            className:"textDefault"
                                        }}
                                        disabled={readOnly}
                                        disableFuture={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField  
                                        name="description"
                                        label={"Description " + (maintenanceId ? '' : '*')}
                                        margin="none"
                                        error={!!formErrors && formErrors['description'] && true}
                                        helperText={formErrors && formErrors['description']}
                                        value={formData.description || ''}
                                        onChange={this.handleChange}
                                        fullWidth
                                        disabled={readOnly}
                                        inputProps={{
                                            className:"textDefault"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Textarea
                                        id="details"
                                        name="details"
                                        label="Details"
                                        value={formData.details}
                                        onChange={this.handleChange}
                                        InputLabelProps={{
                                            shrink: !!formData.details,
                                        }}
                                        rows={5}
                                        disabled={readOnly}
                                        inputProps={{
                                            className:"textDefault"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Textarea
                                        id="location"
                                        name="location"
                                        label="Location"
                                        value={formData.location}
                                        onChange={this.handleChange}
                                        InputLabelProps={{
                                            shrink: !!formData.location,
                                        }}
                                        disabled={readOnly}
                                        inputProps={{
                                            className:"textDefault"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {!readOnly && 
                                        <DragFileInputMulti
                                            id="documents"
                                            name="documents[]"
                                            label="Upload Documents"
                                            file={formData?.documents}
                                            type="file"
                                            error={formErrors && formErrors['documents'] && true}
                                            errorText={formErrors && formErrors['documents']}
                                            onChange={this.handleUploads}
                                            cancelOnClick={this.clearUploads}
                                            emptyText='No files uploaded'
                                        />
                                    }
                                </Grid>
                                {maintenanceId ?
                                    <Grid item xs={12}>
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell colSpan={2}><b>Documents</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {maintenance.documents?.length > 0 ?
                                                <>
                                                    {maintenance.documents.map((item, idx) => {
                                                        return (
                                                            <TableRow key={idx}>
                                                                <TableCell>
                                                                    <Link className='blueLink' variant="body2" style={{cursor: 'pointer'}} onClick={() => {downloadS3File(item?.fileUrl)}}>
                                                                        <div>{item?.filename}</div>
                                                                    </Link>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Tooltip title="Delete">
                                                                        <span>
                                                                        <AllIcon icon="times" noMargin onClick={() => this.props.deployConfirmation(`Are you sure you want to delete this document?`, 'Delete Document', () => this.deleteDocument(item?.id))} />
                                                                        </span>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </>
                                                :
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography variant="body2" color="textSecondary" component="p">
                                                                <em>No documents uploaded</em>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                : null }
                                <Grid item xs={12} align="right">
                                    <DialogActions className='pr-0 pb-0'>
                                        <Button onClick={() => this.props.onClose()} variant="text">
                                            <AllIcon icon="times" size={15} />
                                            Close
                                        </Button>
                                        {!readOnly ? (
                                            <Button 
                                                onClick={() => this.props.deployConfirmation(`Are you sure you want to ${maintenanceId ? 'update' : 'add'} this maintenance?`, `${maintenanceId ? 'Update' : 'Add'} Maintenance?`, this.handleSubmit)}
                                                variant="contained"
                                                color="primary"
                                            >
                                                {maintenanceId ? 'Update' : 'Add'}
                                            </Button>
                                        ) : null }
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        )}
                    </form>
                </Grid>  
            </Grid>
            </>  
        );
    }
}

const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
    }
)

export default connect(null, mapDispatchToProps)(VehicleMaintenanceForm);