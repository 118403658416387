import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import API from '../../../API';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import DataTable from '../../Common/DataTables/CiDataTable';
import moment from 'moment';
import {statusColour} from './../../../Helpers/ColourHelper'
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import Link from '@material-ui/core/Link';
import FASolidIcon from '../../Common/Icons/FontAwesome/FASolidIcon';
import Tooltip from '@material-ui/core/Tooltip';

import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    keywords: '',
    searchResults: {},
    isLoading: true
});

class PartsOrderSearch extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'partsOrdering:PartsOrderSearch';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        !this.hasPageState() && this.getSearchData();
    }

    getSearchData = () => {
        const params = {
            keywords: this.state.keywords,
        }
        this.setState({
            isLoading: true
        }, () => {
            API.get('/partsOrdering', {
                params,
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    _.map(result.data, (ppo) => {
                        ppo.colour = statusColour(ppo.ppo_status);
                    });
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    }, () => {
                        this.savePageState();
                    })
                }
            })
        })
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.setState({
            keywords: '',
        }, 
        () => {
            this.getSearchData();
        });
    }

    downloadFile = type => rowData => {
        API.get('/partsOrdering/' + rowData.ppo_id + '/' + type)
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Parts Order Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={this.state.keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                           
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key: 'ppo_id',
                                pagination: true,
                                alternatingRowColours: true,
                                responsiveImportance: true,
                                isLoading: this.state.isLoading
                            }}
                            columns={[
                                {
                                    heading: 'Parts Order Date',
                                    field: rowData => moment(rowData.ppo_date).format("DD/MM/YYYY"),
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    actionsCustomHeader:    true,
                                    sizeToContent:          true,
                                    alignment:              'left',
                                    heading:                'Parts Order Number',
                                    actions: rowData => {
                                        return [
                                            {label: rowData.ppo_number + '-' + rowData.ppo_number_amendment, link: `/parts-order/view/${rowData.ppo_id}`, type:'plainLink',}
                                        ]
                                    }
                                },
                                {
                                    heading: 'Supplier',
                                    field: rowData => rowData.ppo_supplier_name,
                                    important: true,
                                    truncate: true,
                                },
                                {
                                    heading: 'Outstanding Invoices',
                                    alignment: 'center',
                                    field: rowData => (
                                        <React.Fragment>
                                            {rowData.ppo_status === 'Invoice' || rowData.ppo_status === 'Delivery' ?
                                                (rowData.receivedDeliveries === 0 ?
                                                    <FALightIcon 
                                                        icon='truck'
                                                        noMargin 
                                                    />
                                                :
                                                    <React.Fragment>
                                                        {rowData.outstandingInvoices && rowData.outstandingInvoices.length > 0 ?
                                                            <Tooltip 
                                                                title={
                                                                    <React.Fragment>
                                                                        <Typography variant="body1"><strong>{rowData.outstandingInvoices.length} Outstanding Delivery Invoices</strong></Typography>
                                                                        {(_.map(rowData.outstandingInvoices, (inv,idx) => {
                                                                            return (<Typography key={idx} variant="body2">{ moment(inv.ppo_file_delivery_date).format("DD/MM/YYYY") }</Typography>)
                                                                        }))}
                                                                    </React.Fragment>
                                                                } 
                                                                placement="right"
                                                            >
                                                                <Link href={`/parts-order/view/${rowData.ppo_id}`}>
                                                                    <FASolidIcon 
                                                                        icon='file-alt'
                                                                        button 
                                                                        noMargin 
                                                                        style={{color: 'rgb(214, 31, 38)'}} 
                                                                    />
                                                                </Link>
                                                            </Tooltip>
                                                        :
                                                            <FASolidIcon 
                                                                icon='file-alt'
                                                                noMargin 
                                                                style={{color: 'rgb(93, 161, 77)'}} 
                                                            />
                                                        }
                                                    </React.Fragment>
                                                )
                                            :
                                            '-'
                                            }
                                        </React.Fragment>
                                    )
                                },
                                {
                                    heading: 'Placed By',
                                    field: rowData => rowData.ppo_placed_by,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Approval By',
                                    field: rowData => rowData.ppo_approval_by,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Status',
                                    field: rowData => ['Completed', 'Declined', 'Cancelled'].includes(rowData.ppo_status) ? rowData.ppo_status : 'Awaiting '+rowData.ppo_status,
                                    cellProps: rowData => {
                                        return ({
                                            style: {
                                                color: rowData.colour
                                            }
                                        });
                                    },
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Download', icon: 'download', onClick: this.downloadFile('pdfPartsOrder')},
                                            {name: 'Delivery Schedule', icon: 'calendar-alt', onClick: this.downloadFile('pdfDeliverySchedule')},
                                            {name: 'View', icon: 'search', link: '/parts-order/view/' + rowData.ppo_id}
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(PartsOrderSearch);
