import React  from 'react';
import moment from 'moment';

import {IconButton, Card, CardHeader, CardContent, CardActions, Avatar, Typography  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import FALightIcon          from 'Components/Common/Icons/FontAwesome/FALightIcon';
import NotesDialog          from 'Components/Common/Dialogs/NotesDialog';

const styles = theme => ({
    paperGrey : {
        padding : theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding : theme.spacing(3),
        },
        backgroundColor :'#f8f9fa'
    },
});

class CustomerNote extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const { classes, note } = this.props;

        return (
            <Card className={classes.paperGrey}>
                <CardHeader
                    avatar={<Avatar alt={`${note.created_by_staff.staff_first_name} ${note.created_by_staff.staff_last_name}`} src={note.created_by_staff.staff_profile_photo} />}
                    title={note.cn_created_by_name}
                    subheader={`${moment(note.cn_created_at_datetime).format("DD/MM/YYYY")}`}
                    action={
                        <>
                            <IconButton onClick={()=>{this.props.deleteCallback(note.cn_id)}} >
                                <FALightIcon icon='times' size="small" button noMargin/>
                            </IconButton>
                            <IconButton onClick={() => this.props.deployDialog(<NotesDialog type="customer-notes" id={note?.cn_id} updateText={note?.cn_text} callback={this.props.editCallback} />, 'Update Note', '', 'xs')}>
                                <FALightIcon icon='pencil' size="small" button noMargin/>
                            </IconButton>
                        </>
                    }
                />
                <CardContent>
                    <Typography component="p">
                        {note?.cn_text.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })}
                    </Typography>
                </CardContent>
                {note?.updated_by_staff && (
                    <CardActions className="float-right pl-2">
                        <Typography component="p" variant="body2" className="textSecondary">
                            <small>Updated by {note?.cn_updated_by_name} at {moment(note?.updated_at_datetime).format('DD/MM/YYYY')}</small>
                        </Typography>
                    </CardActions>
                )}
            </Card>
        )
    }
}

export default withStyles(styles)(CustomerNote)