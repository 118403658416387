import { Grid, Typography, Link } from '@material-ui/core';
import API from 'API';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import icons from 'Helpers/IconHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import ReturnDownloadDialog from './../ViewCustomerReturns/ReturnDownloadDialog';
import ReturnEmailDialog from './../ViewCustomerReturns/ReturnEmailDialog';
import ApproveRepairDialog from './ApproveRepairDialog';
import WriteOffDialog from './WriteOffDialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconHelper from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';

const initialState = {
    isLoading: true,
    searchData: {
        keywords: '',
        forCustomerReturn: 1,
        activeOnly: 1,
    },
    repairs:{
        all: [],
        approval: [],
        repair: [],
    },
    access: {
        'view-return': false,
    }
}

class ViewCustomerReturnRepairs extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getReturns();
        this.getAccess();
    }

    getAccess = () => {
        Promise.all(
            _.map(_.keys(this.state.access), i => API.get(`/staff/my/access/check/${i}`))
        ).then(res => {
            let access = this.state.access;
            _.each(res, (i,idx) => {
                access[_.keys(this.state.access)[idx]] = i.data.has_access ?? false;
            });
            this.setState({access});
        })
    }   

    getReturns = () => {
        API.get('/customerReturns/repairOrders', { params: this.state.searchData })
        .then(res => {
            this.setState({ 
                repairs: {
                    all: res.data,
                    approval: _.filter(res.data, i => i.rog_status === 'Awaiting Quote'),
                    repair: _.filter(res.data, i => i.rog_status === 'Awaiting Repair'),
                },
                isLoading: false 
            })
        })
    }

    writeOff = (row) => {
        this.props.deployDialog(
            <WriteOffDialog
                id={row.rog_id}
                onClose={
                    () => {
                        this.props.closeDialog();
                        this.getReturns();
                    }
                }
            />,
            'Write Off Repair',
            'md'
        )
    }

    render (){

        const { isLoading, repairs, access } = this.state;

        if (isLoading) return ( <LoadingCircle/> )

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                    <Typography variant="h5" gutterBottom>Customer Return Repairs</Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <PaddedPaper>
                        <Typography variant="h6" gutterBottom>Awaiting Approval</Typography>
                        <br/>
                        <CiDataTable
                            config={{
                                key: 'rog_id'
                            }}
                            rows={repairs.approval}
                            columns={[
                                {
                                    heading:       'Date',
                                    field:         'customer_return_outcome.customer_return.cr_date',
                                    fieldFormat:   'clenydate',
                                    sizeToContent: true,   
                                },
                                {
                                    alignment: 'left',
                                    sizeToContent: true,
                                    heading:   'Reference',
                                    field:     i => {
                                        let ret = [];
                                        ret.push(
                                            <Grid item xs={12}>
                                                <Link
                                                    target="_blank"
                                                    href={`/returns/customerReturn/${i.customer_return_outcome.customer_return.cr_id}`}
                                                    style={{color: colors.red}}
                                                >
                                                    {`${i.customer_return_outcome.customer_return.cr_reference}-${i.customer_return_outcome.customer_return.cr_reference_amendment > 9 ? i.customer_return_outcome.customer_return.cr_reference_amendment : '0' + i.customer_return_outcome.customer_return.cr_reference_amendment}`}
                                                </Link>
                                            </Grid>
                                        )

                                        if (i?.quote) {
                                            ret.push(
                                                <Grid item xs={12}>
                                                    <Link
                                                        target="_blank"
                                                        href={`/sales/quotation/view/${i.quote.quote_id}`}
                                                        style={{color: colors.disabled}}
                                                    >
                                                        {`${i.quote.quote_reference}-${i.quote.quote_reference_amendment}`}
                                                    </Link>
                                                </Grid>
                                            )
                                            if (i?.quote?.order) {
                                                ret.push(
                                                    <Grid item xs={12}>
                                                        <Link
                                                            target="_blank"
                                                            href={`/sales/order/view/${i?.quote?.order?.order_id}`}
                                                            style={{color: colors.primary}}
                                                        >
                                                            {`${i?.quote?.order?.order_reference}-${i?.quote?.order?.order_reference_amendment}`}
                                                        </Link>
                                                    </Grid>
                                                )
                                                
                                            }
                                        }

                                        return (
                                            <Grid container>
                                                {ret}
                                            </Grid>
                                        );
                                    },
                                },
                                {
                                    heading:       'Customer',
                                    alignment: 'left',
                                    field:        i =>  <>
                                        <Typography variant='body2'>{i.customer_return_outcome.customer_return.cr_cust_name}</Typography>
                                        <Typography variant='caption'>{i.customer_return_outcome.customer_return.cr_collection_address_name}</Typography>
                                    </>,
                                },
                                {
                                    heading:       'Repairs',
                                    field:         i=>i.repair_orders.length,
                                    sizeToContent: true,
                                    alignment:     'center',
                                },
                                {
                                    heading:'Status',
                                    field: i => <AllIcon 
                                                    icon={i.rog_quote_id ? IconHelper.orderAwaitingApproval : IconHelper.timer}
                                                    tooltip={i.rog_quote_id ? 'Awaiting Customer Approval' : 'Awaiting Approval'}
                                                    noMargin
                                                    color={i.rog_quote_id && colors.orange }
                                                />,
                                    sizeToContent: true,
                                    alignment:     'center',
                                },
                                {
                                    actions: i => ([
                                        // {
                                        //     name: 'Download',
                                        //     icon: icons.download,
                                        //     onClick: i => this.props.deployDialog(<ReturnDownloadDialog cr={i.customer_return_outcome.customer_return}/>, 'Download Previous Documents', 'md'),
                                        //     disabled: !access['view-return']
                                        // }, 
                                        // {
                                        //     name: 'Email',
                                        //     icon: icons.email,
                                        //     onClick: i => this.props.deployDialog(<ReturnEmailDialog cr={i.customer_return_outcome.customer_return} />, 'Email Previous Documents', 'md'),
                                        //     disabled: !access['view-return']
                                        // },
                                        {
                                            name: 'View',
                                            icon: icons.view,
                                            onClick: i => this.props.history.push(`/returns/customerReturn/${i.customer_return_outcome.customer_return.cr_id}`),
                                            disabled: !access['view-return']
                                        },
                                        {
                                            name: 'View this Customers Returns',    
                                            icon: 'file-search',    
                                            onClick: i => this.props.history.push(`/customers/view/${i.customer_return_outcome.customer_return.cr_cust_id}/CustomerReturns`),
                                            disabled: !access['view-return']
                                        },
                                        (
                                            i.rog_quote_id ?
                                            {
                                                name: 'Cancel Repair / Write Off',
                                                icon: icons.broken,
                                                onClick: this.writeOff,  
                                            } :
                                            {
                                                name: 'Proccess Repair',
                                                icon: icons.true,
                                                onClick: ()=>this.props.deployDialog(<ApproveRepairDialog repair={i} rogId={i.rog_id} history={this.props.history} onClose={()=>{this.props.closeDialog(); this.getReturns()}} writeOff={()=>this.writeOff(i)} />, 'Approve Repair', 'md', 'success'),
                                            }
                                        )
                                    ]),
                                    alignment: 'center',    
                                }
                                
                            ]}
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <PaddedPaper>
                        <Typography variant="h6" gutterBottom>Repairs</Typography>
                        <br/>
                        <CiDataTable
                            config={{
                                key: 'rog_id'
                            }}
                            columns={[
                                {
                                    heading:       'Date',
                                    field:         'customer_return_outcome.customer_return.cr_date',
                                    fieldFormat:   'clenydate',
                                    sizeToContent: true,   
                                },
                                {
                                    alignment: 'left',
                                    sizeToContent: true,
                                    heading:   'Reference',
                                    field:     i => {
                                        let ret = [];
                                        ret.push(
                                            <Grid item xs={12}>
                                                <Link
                                                    target="_blank"
                                                    href={`/returns/customerReturn/${i.customer_return_outcome.customer_return.cr_id}`}
                                                    style={{color: colors.red}}
                                                >
                                                    {`${i.customer_return_outcome.customer_return.cr_reference}-${i.customer_return_outcome.customer_return.cr_reference_amendment > 9 ? i.customer_return_outcome.customer_return.cr_reference_amendment : '0' + i.customer_return_outcome.customer_return.cr_reference_amendment}`}
                                                </Link>
                                            </Grid>
                                        )

                                        if (i?.quote) {
                                            ret.push(
                                                <Grid item xs={12}>
                                                    <Link
                                                        target="_blank"
                                                        href={`/sales/quotation/view/${i.quote.quote_id}`}
                                                        style={{color: colors.disabled}}
                                                    >
                                                        {`${i.quote.quote_reference}-${i.quote.quote_reference_amendment}`}
                                                    </Link>
                                                </Grid>
                                            )
                                            if (i?.quote?.order) {
                                                ret.push(
                                                    <Grid item xs={12}>
                                                        <Link
                                                            target="_blank"
                                                            href={`/sales/order/view/${i?.quote?.order?.order_id}`}
                                                            style={{color: colors.primary}}
                                                        >
                                                            {`${i?.quote?.order?.order_reference}-${i?.quote?.order?.order_reference_amendment}`}
                                                        </Link>
                                                    </Grid>
                                                )
                                                
                                            }
                                        }

                                        return (
                                            <Grid container>
                                                {ret}
                                            </Grid>
                                        );
                                    },
                                },
                                {
                                    heading:       'Customer',
                                    alignment: 'left',
                                    field:        i =>  <>
                                        <Typography variant='body2'>{i.customer_return_outcome.customer_return.cr_cust_name}</Typography>
                                        <Typography variant='caption'>{i.customer_return_outcome.customer_return.cr_collection_address_name}</Typography>
                                    </>,
                                },
                                {
                                    heading:       'Repairs',
                                    field:         i=>i.repair_orders.length,
                                    sizeToContent: true,
                                    alignment:     'center',
                                },
                                {
                                    actions: i => ([
                                        // {
                                        //     name: 'Download',
                                        //     icon: icons.download,
                                        //     onClick: i => this.props.deployDialog(<ReturnDownloadDialog cr={i}/>, 'Download Previous Documents', 'md'),
                                        //     disabled: !access['view-return']
                                        // }, 
                                        // {
                                        //     name: 'Email',
                                        //     icon: icons.email,
                                        //     onClick: i => this.props.deployDialog(<ReturnEmailDialog cr={i} />, 'Email Previous Documents', 'md'),
                                        //     disabled: !access['view-return']
                                        // },
                                        {
                                            name: 'View',
                                            icon: icons.view,
                                            onClick: i => this.props.history.push(`/returns/customerReturn/${i.customer_return_outcome.customer_return.cr_id}`),
                                            disabled: !access['view-return']
                                        },
                                        {
                                            name: 'View this Customers Returns',    
                                            icon: 'file-search',    
                                            onClick: i => this.props.history.push(`/customers/view/${i.customer_return_outcome.customer_return.cr_cust_id}/CustomerReturns`),
                                            disabled: !access['view-return']
                                        },
                                        {
                                            name: 'Repair',
                                            icon: icons.parts,
                                            link: `/returns/customerReturn/repair/${i.rog_id}`
                                        }
                                    ]),
                                }
                                
                            ]}
                            rows={repairs.repair}
                        />
                    </PaddedPaper>
                </Grid>
                {/* <Grid item xs={12} lg={12}>
                    <TabBarSwitcher
                        tabs={[
                            {
                                label: 'Awaiting Approval',
                                content: <AwaitingApproval writeOff={this.writeOff} rows={repairs.approval} history={this.props.history} access={access} refresh={this.getReturns} />
                            },
                            {
                                label: 'Customer Confirmation',
                                content: <AwaitingConfirmation rows={repairs.confirmation} history={this.props.history} access={access} writeOff={this.writeOff} refresh={this.getReturns} />
                            },
                            {
                                label: 'Repair',
                                content: <Repair rows={repairs.repair} history={this.props.history} access={access} writeOff={this.writeOff} refresh={this.getReturns} />
                            },
                        ]}
                    />
                </Grid> */}
            </Grid>
        )
    }
}


const mapDispatchToProps = dispatch => ({
    deployDialog: (content, title, size, variant=null) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog: () => dispatch(closeDialog()),
});

export default connect(null, mapDispatchToProps)(ViewCustomerReturnRepairs);