import { Grid, Typography, Button, Dialog, DialogTitle, List, DialogActions, DialogContent, ListItem, ListItemText, Divider, ListItemSecondaryAction, TextField, Box } from "@material-ui/core";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import CheckBox from "Components/Common/Inputs/CheckBox";
import React, { useEffect } from "react";
import AllIcon from "Components/Common/Icons/AllIcon";
import IconHelper from "Helpers/IconHelper";
import API from 'API';
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import _ from 'lodash';
import { colors } from "Helpers/ColourHelper";
import { clenyDate } from "Helpers/TimeHelper";
import moment from "moment";

const initialState = props => ({
    isPromotion: props?.value?.length > 0 ? true : false,
    setPromotions:  props?.value?.length > 0 ?  props?.value : [],
    promotions: [],
    isLoading: true,
    promotionDialogOpen: false,
    search: '',
})

const PromoItem = ({ promotion, isAdded, addPromotion, removePromotion, showPromotion }) => {
    let emailCampaign = _.last(promotion.email_campaigns);

    return ( 
        <ListItem>
            <ListItemText
                primary={
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body1">{emailCampaign?.ec_reference}-0{emailCampaign?.ec_reference_amendment}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">{emailCampaign?.ec_name}</Typography>
                        </Grid>
                    </Grid>
                }
                secondary={
                    <Grid container>
                        {emailCampaign?.ec_desc &&
                            <Grid item xs={12}>
                                <Typography variant="caption">{emailCampaign?.ec_desc}</Typography>
                            </Grid>
                        }
                        {showPromotion &&
                            <Grid item xs={12} style={{border: `1px solid ${colors.info}`, borderRadius: 5, margin: 5, padding: 5, marginRight: 20}}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">Promotion Details</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">[{promotion?.p_code}]</Typography>
                                    </Grid>
                                    {promotion.p_name !== emailCampaign?.ec_name &&
                                        <Grid item xs={8} style={{marginLeft: 5}}>
                                            <Typography variant="body2">{promotion?.p_name}</Typography>
                                        </Grid>
                                    }
                                    {promotion.p_desc !== emailCampaign?.ec_desc &&
                                        <Grid item xs={12}>
                                            <Typography variant="caption">{promotion?.p_desc}</Typography>
                                        </Grid>
                                    }
                                    {moment().isAfter(moment(promotion?.p_end)) &&
                                        <Grid item style={{ marginRight: 5}}>
                                            <Typography variant="caption" style={{color: colors.red}}>EXPIRED</Typography>
                                        </Grid>
                                    }
                                    <Grid item style={{marginRight: 5}}>
                                        <Typography variant='caption'>
                                            Valid From: {clenyDate(promotion?.p_start)}
                                        </Typography>
                                    </Grid>
                                    {
                                        promotion?.p_end &&
                                        <Grid item style={{marginRight: 5}}>
                                            <Typography variant='caption'>
                                                To : {clenyDate(promotion?.p_end)}
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                }
                
            />
            <ListItemSecondaryAction>
                {isAdded ?
                    <AllIcon icon={IconHelper.bin} noMargin strong onClick={removePromotion(promotion.p_id)}/> : 
                    <AllIcon icon={IconHelper.plus} noMargin strong onClick={addPromotion(promotion.p_id)}/> 
                }
            </ListItemSecondaryAction>
        </ListItem>

    )
}

class AddPromotionDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount(){
        this.getPromotions();
    }

    getPromotions = () => {
        API.get('/marketing/promotions').then((response) => {
            this.setState({promotions: response.data, isLoading: false});
        });
    }

    setIsPromotion = () => {
        const { isPromotion } = this.state;

        if (isPromotion) {
            // Reset promotion fields
            this.setState({setPromotions: []});
            this.handlePromotionChange();
        }

        this.setState({isPromotion: !isPromotion});
    }

    handlePromotionChange = () => {
        this.props.onChange(this.state.setPromotions);
    }

    setPromotionDialogOpen = (value) => {
        this.setState({promotionDialogOpen: value});
    }

    addPromotion = (id) => () => {
        const { setPromotions } = this.state;

        this.setState({setPromotions: [...setPromotions, id]}, () => {
            this.handlePromotionChange();
        });
    }

    removePromotion = (id) => () => {
        const { setPromotions } = this.state;

        this.setState({setPromotions: _.filter(setPromotions, i => i !== id)}, () => {
            this.handlePromotionChange();
        });
    }

    handleSearchChange = (e) => {
        this.setState({search: e.target.value});
    }

    render() {
        const { isPromotion, promotions, isLoading, promotionDialogOpen, search, setPromotions } = this.state;

        if (isLoading) {
            return <PaddedPaper><LoadingCircle/></PaddedPaper>
        }

        let filteredPromos = _.map(_.filter(_.orderBy(promotions, 'p_id', 'desc'), i => 
            i?.email_campaigns?.length > 0 &&
            (
                search ?
                    (
                        i.p_name.toLowerCase().includes(search.toLowerCase()) || 
                        i.p_code.toLowerCase().includes(search.toLowerCase()) || 
                        i.p_desc.toLowerCase().includes(search.toLowerCase()) ||
                        i.p_type.toLowerCase().includes(search.toLowerCase()) ||
                        _.last(i?.email_campaigns)?.ec_reference?.toLowerCase()?.includes(search?.toLowerCase()) ||
                        _.last(i?.email_campaigns)?.ec_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                        _.last(i?.email_campaigns)?.ec_desc?.toLowerCase()?.includes(search?.toLowerCase())
                    ) : true
            )
        ), (promotion, idx) => {
            const emailCampaign = _.last(promotion.email_campaigns);
            return {
                emailCampaign: emailCampaign,
                promotion: promotion,
                showPromotion: !promotion.p_hidden,
            }
        });


        return (
            <PaddedPaper>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>Marketing - Email Campaigns</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CheckBox 
                            label='Is this the result of an email campaign?'
                            value={isPromotion}
                            checked={isPromotion}
                            onChange={() => this.setIsPromotion(!isPromotion)}
                            color='primary'
                        />
                    </Grid>
                    {isPromotion &&
                        <>
                            <List style={{width: '100%'}}>
                                {_.map(setPromotions, (id, idx) => {    
                                    const promotion = _.find(promotions, i => i.p_id === id);
                                    return (
                                        <>
                                            <PromoItem promotion={promotion} isAdded={true} addPromotion={this.addPromotion} removePromotion={this.removePromotion} showPromotion={!promotion.p_hidden} /> 
                                            <Divider/>
                                        </>
                                    )
                                })}
                            </List>
                            {setPromotions.length != promotions.length &&
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={() => this.setPromotionDialogOpen(1)}
                                    ><AllIcon icon={IconHelper.plus} noMargin strong/></Button>
                                </Grid>
                            }
                        </>
                    }
                </Grid>
                {(promotionDialogOpen) &&
                    <Dialog
                        open={promotionDialogOpen}
                        onClose={() => this.setPromotionDialogOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogTitle>Promotions</DialogTitle>
                        <DialogContent>
                            <Grid container
                                style={{
                                    border: `1px solid ${colors.subText}`,
                                    borderRadius: 2,
                                }}
                            >
                                <Grid item xs={12}
                                    style={{
                                        maxHeight: 600,
                                        overflowY: 'auto',
                                    }}
                                >
                                    <TextField
                                        placeholder="Search"
                                        value={search}
                                        onChange={this.handleSearchChange}
                                        fullWidth
                                        margin="none"
                                        style={{paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
                                    />
                                    <List>
                                        {_.map(filteredPromos, ({promotion, showPromotion, emailCampaign}, idx) => 
                                            <>
                                                <PromoItem promotion={promotion} isAdded={setPromotions.includes(promotion.p_id)} addPromotion={this.addPromotion} removePromotion={this.removePromotion} showPromotion={showPromotion} /> 
                                                <Divider/>
                                            </>
                                        )}
                                    </List>
                                </Grid>
                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setPromotionDialogOpen(false)} variant="outlined">Close</Button>
                        </DialogActions>
                    </Dialog>
                }
    
            </PaddedPaper>
        )
    }
}

export default AddPromotionDetails;