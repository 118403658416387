import API from 'API';
import { restockFeeMultiplier } from 'Constants';
import icons from 'Helpers/IconHelper';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogTitle, Grid, InputAdornment, Table, TableBody, TableCell, TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import { FullScreenDialog } from 'Components/Common/Dialogs/FullScreenDialog';
import SingleEmailDialog from 'Components/Common/Dialogs/SingleEmailDialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import ViewPart from 'Components/Parts/ViewPart/ViewPart';
    
import { colors } from 'Helpers/ColourHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import IconHelper from 'Helpers/IconHelper';

const initialState = () => ({
    isLoading:      true,
    order:          {},
    customer:       {},
    customerReturn: {},
    formData:       {
        orderId:                  0, 

        customerContact:          null,
        collectionAddressType:    null,
        collectionAddress:        null,
        collectionAddressContact: null,

        partsRow:                 [],
        deletedPartsRow:          [],

        orderTotal:               0,
        restockFee:               0,
        total:                    0,
        discount:                 0,

        expectedOutcome:          null,

        notes:                    '',
    },
    editPartsRow:   {
        show:               false,
        id:                 null,
        od_id:              null,
        qty:                null,
        qtyMax:             null,
        warrantyDuration:   null,
        serialNumbers:      null,
        WVL:                null,
        notes:              null,


        perUnit:            null,
        discount:           null,
        restockFee:         true,
    },
    lists:          {
        contacts:           [],
    },
    vatRate: 0.2,
    amend: false,
    partOrder: [],
})

class AddCustomerReturn extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount = () => {
        this.getLists();
    }

    getLists = () => {
        Promise.all([
            API.get('/accounts/vatRates/byType'),
            API.get('/parts/numbering/partTypes/all')
        ]).then(res => {
            this.setState({
                vatRate: parseFloat(_.find(_.find(res[0].data, {vat_type_name: 'UK Standard'}).vat_values, {current: true}).vat_value_percentage) / 100,
                partOrder: _.sortBy(res[1].data, [ 'part_type_sort_order' ,'asc']),
            },this.getData)
        });
    }

    getData = () => {
        if (this.props.match.params.returnId) {
            this.getCustomerReturn();
        }
        if (this.props.match.params.orderId)  {
            this.getOrder();
        }
    }

    getCustomerReturn = () => {
        API.get(`/customerReturns/returns/${this.props.match.params.returnId}`)
        .then(res => {

            let customerReturn = res.data;

            let partsRow = [];

            _.forEach(customerReturn.details, part => {

                partsRow.push({
                    id:                    part.crd_part_id,
                    od_id:                 part.crd_od_id,
                    qty:                   part.crd_qty,
                    qtyMax:                part.crd_qty,

                    warrantyDuration:      0,

                    serialNumbers:         _.map(JSON.parse(part.crd_serial_numbers), i => i),
                    selectedSerialNumbers: _.map(JSON.parse(part.crd_serial_numbers), i => i),
                    WVL:                   _.map(JSON.parse(part.crd_warrenty_void_labels), i => i),
                    selectedWVL:           _.map(JSON.parse(part.crd_warrenty_void_labels), i => i),

                    number:                part.part.part_number,
                    desc:                  part.part.part_description,
                    img:                   part?.part?.default_image?.thumbnail_file_path,

                    perUnit:               parseFloat(part.crd_unit_price),
                    unitDiscount:          parseFloat(part.crd_unit_price) * parseFloat(parseFloat(part.crd_discount) / 100),
                    discount:              parseFloat(part.crd_discount),
                    newUnitPrice:          parseFloat(part.crd_new_unit_price),

                    subTotal:              parseFloat(part.crd_sub_total),
                    totalDiscount:         parseFloat(part.crd_discount_total),
                    total:                 parseFloat(part.crd_total),
                    restockFee:            parseFloat(part.crd_restock_fee),
                    totalWithRestock:      parseFloat(part.crd_total_with_restock),

                    notes:                 part.crd_notes,
                })
            });

            API.get(`/sales/orders/${customerReturn.cr_order_id}`)
            .then(res => {

                let order = res.data;
                let deletedPartsRow = [];

                _.forEach(_.filter(order.order_details, i => i.part.stockable), part => {
                    let partInReturnIdx = _.findIndex(partsRow, {od_id: part.od_id});
                    let partInReturn = partsRow[partInReturnIdx];
                    if (partInReturn){
                        partsRow[partInReturnIdx].warrantyDuration = part.od_warranty_duration;
                        partsRow[partInReturnIdx].qtyMax           = parseFloat(part.od_quantity);
                        partsRow[partInReturnIdx].serialNumbers    = _.map(part.serial_numbers, i => i.psnsl_serial_number);
                        partsRow[partInReturnIdx].WVL              = _.map(part.warranty_void_labels, i => i.pwvll_code);

                        if (parseFloat(partInReturn.qty) !== parseFloat(part.od_quantity)){

                            let qty = parseFloat(part.od_quantity) - parseFloat(partInReturn.qty);
                            let perUnit = parseFloat(partInReturn.perUnit);
                            let discount = parseFloat(partInReturn.discount);
                            let unitDiscount = parseFloat(partInReturn.unitDiscount);
                            let newUnitPrice = parseFloat(partInReturn.newUnitPrice);
                            let total = newUnitPrice * qty;

                            deletedPartsRow.push({
                                id:                    part.od_part_id,
                                od_id:                 part.od_id,
                                qty:                   parseFloat(qty),
                                qtyMax:                parseFloat(part.od_quantity),
                                warrantyDuration:      part.od_warranty_duration,
                                serialNumbers:         _.map(part.serial_numbers, i => i.psnsl_serial_number),
                                selectedSerialNumbers: _.map(_.filter(part.serial_numbers, i =>!_.find(partsRow[partInReturnIdx].selectedSerialNumbers, j => j ===  i.psnsl_serial_number)), i => i.psnsl_serial_number),
                                WVL:                   _.map(part.warranty_void_labels, i => i.pwvll_code),
                                selectedWVL:           _.map(_.filter(part.warranty_void_labels, i =>!_.find(partsRow[partInReturnIdx].selectedWVL, j => j ===  i.pwvll_code)), i => i.pwvll_code),

                                number:                part.part.part_number,
                                desc:                  part.part.part_description,
                                img:                   part?.part?.default_image?.thumbnail_file_path,

                                perUnit:               perUnit,
                                discount:              discount,
                                unitDiscount:          unitDiscount,
                                newUnitPrice:          newUnitPrice,

                                subTotal:              newUnitPrice * qty,
                                totalDiscount:         unitDiscount * qty,
                                total:                 total,
                                restockFee:            parseFloat(partInReturn.restockFee) > 0 ? total * restockFeeMultiplier : 0,
                                totalWithRestock:      total * parseFloat(1 - (parseFloat(partInReturn.restockFee) > 0 ? restockFeeMultiplier : 0)),

                                notes:                 part.od_notes,
                            });
                        } 
                    } else {
                        deletedPartsRow.push({
                            id:                    part.od_part_id,
                            od_id:                 part.od_id,
                            qty:                   parseFloat(part.od_quantity),
                            qtyMax:                parseFloat(part.od_quantity),
                            warrantyDuration:      part.od_warranty_duration,
                            serialNumbers:         _.map(part.serial_numbers, i => i.psnsl_serial_number),
                            selectedSerialNumbers: _.map(part.serial_numbers, i => i.psnsl_serial_number),
                            WVL:                   _.map(part.warranty_void_labels, i => i.pwvll_code),
                            selectedWVL:           _.map(part.warranty_void_labels, i => i.pwvll_code),

                            number:                part.part.part_number,
                            desc:                  part.part.part_description,
                            img:                   part?.part?.default_image?.thumbnail_file_path,

                            perUnit:               parseFloat(part.od_unit_price),
                            unitDiscount:          parseFloat(part.od_unit_discount),
                            discount:              parseFloat(part.od_discount_rate),
                            newUnitPrice:          parseFloat(part.od_new_unit_price),

                            subTotal:              parseFloat(part.od_sub_total),
                            totalDiscount:         parseFloat(part.od_discount_total),
                            total:                 parseFloat(part.od_total_price),
                            restockFee:            parseFloat(part.od_total_price) * restockFeeMultiplier,
                            totalWithRestock:      parseFloat(part.od_total_price) - parseFloat(parseFloat(part.od_total_price) * restockFeeMultiplier),

                            notes:                 part.od_notes,
                        })
                    }
                });

                this.setState({
                    order: order,
                    customerReturn: customerReturn, 
                    formData: {
                        ...this.state.formData,
                        orderId:                  customerReturn.cr_order_id, 
                        hasDiscount:              customerReturn.cr_disocounted,

                        customerContact:          customerReturn.cr_contact_id ,
                        collectionAddressType:    customerReturn.cr_collection_address_type,
                        collectionAddress:        customerReturn.cr_collection_address_id ,
                        collectionAddressContact: customerReturn.cr_collection_address_contact_id ,

                        partsRow:                 partsRow,
                        deletedPartsRow:          deletedPartsRow,

                        orderTotal:               0,
                        restockFee:               0,
                        total:                    0,
                        discount:                 0,

                        expectedOutcome:          customerReturn.cr_expected_outcome,

                        notes:                    customerReturn.cr_notes,
                    },
                    amend: true,
                }, this.getCustomer);
            });

            
        })
    }

    showNoPartsTorReturn = () => {
        this.props.deployDialog(
            <>
                <Typography variant='body1' gutterBottom>
                    This order has no parts to left to return, Please check the customer returns tab inside the order for more information.
                </Typography>
                <div className='buttonRow'>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => { this.props.closeDialog(); this.props.history.push(`/sales/order/view/${this.props.match.params.orderId}`)}}
                    >
                        Return To Order 
                    </Button>
                </div>
            </>,
            'No Parts To Return', 'warning', 'sm'
        )
    }

    getOrder = () => {
        API.get(`/sales/orders/${this.props.match.params.orderId}`)
        .then(res => {

            let partsRow = [];
            _.each(res.data.order_details, (part) => {

                if (part.part.stockable){
                    partsRow.push({
                        id:                    part.od_part_id,
                        od_id:                 part.od_id,
                        qty:                   parseFloat(part.od_quantity),
                        qtyMax:                parseFloat(part.od_quantity),
                        warrantyDuration:      part.od_warranty_duration,
                        serialNumbers:         _.uniq(_.map(part.serial_numbers, i => i.psnsl_serial_number)),
                        selectedSerialNumbers: _.uniq(_.map(part.serial_numbers, i => i.psnsl_serial_number)),
                        WVL:                   _.uniq(_.map(part.warranty_void_labels, i => i.pwvll_code)),
                        selectedWVL:           _.uniq(_.map(part.warranty_void_labels, i => i.pwvll_code)),

                        number:                part.part.part_number,
                        desc:                  part.part.part_description,
                        img:                   part?.part?.default_image?.thumbnail_file_path,

                        perUnit:               parseFloat(part.od_unit_price),
                        unitDiscount:          parseFloat(part.od_unit_discount),
                        discount:              parseFloat(part.od_discount_rate),
                        newUnitPrice:          parseFloat(part.od_new_unit_price),

                        subTotal:              parseFloat(part.od_sub_total),
                        totalDiscount:         parseFloat(part.od_discount_total),
                        total:                 parseFloat(part.od_total_price),
                        restockFee:            0,//parseFloat(part.od_total_price) * restockFeeMultiplier,
                        totalWithRestock:      parseFloat(part.od_total_price), //* parseFloat(1 - restockFeeMultiplier),

                        notes:                 part.od_notes,
                    })
                }
            });

            if (res.data.customer_returns.length > 0){
                _.each(_.orderBy(res.data.customer_returns, cr => cr.cr_id, 'asc'), (cr) => {
                    if (!['Cancelled', 'Declined'].includes(cr.cr_status)){
                        let exclude = [];

                        if (['Completed'].includes(cr.cr_status)){
                            if (_.find(cr.outcomes, {cro_outcome: 'Repair'})){
                                _.each(_.find(cr.outcomes, {cro_outcome: 'Repair'}).details, d => {
                                    exclude.push(
                                        {
                                            od_id: d.inspection.return_detail.crd_od_id,
                                            qty:   d.crod_qty,
                                            wvl:   d?.crod_waranty_void_labels,
                                            sn:    d?.crod_serial_numbers,
                                        }
                                    );
                                })
                            }
                        }
                        if (['Awaiting Outcome', 'Awaiting Review'].includes(cr.cr_status)){
                            _.each(cr.collection.details, d => {
                                if (parseFloat(d.crcd_remaining) > 0){
                                    let detail = _.find(cr.details, {crd_id: d.crcd_crd_id});
                                    let esn = [];
                                    let ewvl = [];

                                    _.each(d.received, r => {
                                        esn = [...esn, r.crcdr_serial_numbers];
                                        ewvl = [...ewvl, r.crcdr_warranty_void_labels];
                                    });

                                    let eindex = _.findIndex(exclude, {od_id: detail.crd_od_id});

                                    let _item = {
                                        od_id:  detail.crd_od_id,
                                        qty:    parseFloat(d.crcd_remaining),
                                        wvl:    _.uniq(ewvl),
                                        sn:     _.uniq(esn),
                                        part:   detail.crd_part_id,
                                    }

                                    if (eindex > -1){
                                        exclude[eindex] = _item;
                                    } else {
                                        exclude.push(_item);
                                    }

                                }
                            });
                        }
                        _.each(cr.details, d => {
                            let index = _.findIndex(partsRow, {od_id: d.crd_od_id});
                            if (index > -1){
                                let row = partsRow[index];
                                let _exclude = _.find(exclude, {od_id: d.crd_od_id});
                                if (_exclude){
                                    if (row.qty > _exclude.qty){
                                        
                                        row.qty = row.qty - _exclude.qty;
                                        row.qtyMax = row.qty;

                                        row.serialNumbers = _.filter(row.serialNumbers, i => !_exclude.sn.includes(i));
                                        row.selectedSerialNumbers =  row.serialNumbers;

                                        row.WVL =  _.filter(row.wvl, i => !_exclude.wvl.includes(i));
                                        row.selectedWVL = row.wvl

                                        row.subTotal = parseFloat(row.perUnit) * parseFloat(row.qty)
                                        row.totalDiscount = parseFloat(row.unitDiscount) * parseFloat(row.qty)
                                        row.total = parseFloat(row.subTotal) - parseFloat(row.totalDiscount)
                                        row.totalWithRestock = row.total

                                        partsRow[index] = row;

                                    } 
                                } else {
                                    row.qty = parseFloat(row.qty) - parseFloat(d.crd_qty);
                                    if (row.qty <= 0){
                                        partsRow.splice(index, 1);
                                    } else {
                                        row.subTotal = parseFloat(row.perUnit) * parseFloat(row.qty)
                                        row.totalDiscount = parseFloat(row.unitDiscount) * parseFloat(row.qty)
                                        row.total = parseFloat(row.subTotal) - parseFloat(row.totalDiscount)
                                        row.totalWithRestock = row.total

                                        row.serialNumbers = _.filter(row.serialNumbers, i => !JSON.parse(d.crd_serial_numbers).includes(i))
                                        row.selectedSerialNumbers = _.filter(row.selectedSerialNumbers, i => !JSON.parse(d.crd_serial_numbers).includes(i))

                                        row.WVL = _.filter(row.WVL, i => !JSON.parse(d.crd_warrenty_void_labels).includes(i))
                                        row.selectedWVL = _.filter(row.selectedWVL, i => !JSON.parse(d.crd_warrenty_void_labels).includes(i))

                                        partsRow[index] = row;
                                    }
                                }

                            }

                        });
                    }
                })
            }
            if (_.filter(res.data.credit_notes, i => !['Cancelled', 'Declined'].includes(i.cn_status)).length > 0){
                _.each(_.filter(res.data.credit_notes, i => !['Cancelled', 'Declined'].includes(i.cn_status)), (cn) => {
                    _.each(cn.details, d => {
                        let pId = d.cnd_part_id;
                        let qty = parseFloat(d.cnd_quantity);
                        _.each(partsRow, (row, partIndex) => {
                            if (row){
                                if (row.id === pId && parseFloat(qty) > 0){
                                    if (d.cnd_serial_numbers.length > 0 || d.cnd_warranty_void_labels.length > 0) {
                                        if (d.cnd_serial_numbers.length > 0){
                                            _.each(d.cnd_serial_numbers, ({serialNumber}, snIdx) => {
                                                if (row.selectedSerialNumbers.includes(serialNumber)){
                                                    qty--;
                                                    row.qty--;
                                                    row.selectedSerialNumbers.splice(row.selectedSerialNumbers.indexOf(serialNumber), 1);
                                                    row.serialNumbers.splice(row.serialNumbers.indexOf(serialNumber), 1);
                                                }
                                            })
                                        }
                                        if (d.cnd_warranty_void_labels.length > 0){
                                            _.each(d.cnd_warranty_void_labels, (wvl, snIdx) => {
                                                if (row.selectedWVL.includes(wvl)){
                                                    qty--;
                                                    row.qty--;
                                                    row.selectedWVL.splice(row.selectedWVL.indexOf(wvl), 1);
                                                    row.WVL.splice(row.WVL.indexOf(wvl), 1);
                                                }
                                            })
                                        }
                                    } else {
                                        if (parseFloat(row.qty) > parseFloat(qty)){
                                            row.qty = parseFloat(parseFloat(row.qty) - parseFloat(qty));
                                            row.subTotal = parseFloat(row.perUnit) * parseFloat(row.qty)
                                            row.totalDiscount = parseFloat(row.unitDiscount) * parseFloat(row.qty)
                                            row.total = parseFloat(row.subTotal) - parseFloat(row.totalDiscount)
                                            row.totalWithRestock = row.total

                                            partsRow[partIndex] = row;

                                            qty = 0;
                                        } else {
                                            qty = parseFloat(qty) - parseFloat(row.qty);
                                            row.qty = qty;
                                        }
                                    }
                                    if (parseFloat(row.qty) <= 0){
                                        partsRow.splice(partIndex, 1);
                                    } else {
                                        row.qtyMax = parseFloat(row.qty);
                                        partsRow[partIndex] = row;
                                    }
                                } 
                            }
                        });
                    });
                });
            }

            if (partsRow.length === 0){
                this.showNoPartsTorReturn();
            }

            this.setState({
                order: res.data,
                formData: {
                    ...this.state.formData,
                    hasDiscount:              res.data.order_type === 'With Discount',
                    orderId:                  res.data.order_id,
                    customerContact:          res.data.order_cust_contact_id,
                    collectionAddressType:    res.data.order_delivery_cust_address_type,
                    collectionAddress:        res.data.order_delivery_cust_address_id,
                    collectionAddressContact: res.data.order_delivery_cust_contact_id,
                    partsRow:                 partsRow,
                    discount:                 parseFloat(_.sumBy(partsRow, 'totalDiscount')),
                    subTotal:                 parseFloat(_.sumBy(partsRow, 'subTotal')),
                    orderTotal:               parseFloat(_.sumBy(partsRow, 'total')),
                    restockFee:               0,// parseFloat(parseFloat(_.sumBy(partsRow, 'total')) * parseFloat(restockFeeMultiplier)),

                    totalWithRestock:         parseFloat(parseFloat(_.sumBy(partsRow, 'total')) /** parseFloat(1 - restockFeeMultiplier)*/),
                    vat:                      parseFloat(parseFloat(parseFloat(_.sumBy(partsRow, 'total')) * parseFloat(1 - restockFeeMultiplier)) * parseFloat(this.state.vatRate)),
                    total:                    parseFloat(parseFloat(parseFloat(_.sumBy(partsRow, 'total')) /** parseFloat(1 - restockFeeMultiplier)*/) * parseFloat(1 + parseFloat(this.state.vatRate))),
                }
            }, this.getCustomer)
        })
    }

    getCustomer = () => {
        API.get(`/customers/${this.state.order.order_cust_id}`)
        .then(res => {

            let customer = res.data;

            let addresses = {
                Registered: [
                    {
                        value:    customer.registered_address.address_id,
                        label:    `${customer.registered_address.address_name} - ${customer.registered_address.address_line_one}, ${customer.registered_address.address_town}, ${customer.registered_address.address_postcode}`,
                        address:  customer.registered_address,
                        contacts: _.map(customer.registered_address.active_contacts, i => ({
                            value:   i.contact_id,
                            label:   i.contact_name,
                            contact: i
                        }))
                    }
                ],
                Accounts: _.map(customer.account_addresses, i => ({
                    value:    i.address_id,
                    label:    `${i.address_name} - ${i.address_line_one}, ${i.address_town}, ${i.address_postcode}`,
                    address:  i,
                    contacts: _.map(i.active_contacts, i => ({
                        value:   i.contact_id,
                        label:   i.contact_name,
                        contact: i
                    }))
                })),
                Trading: _.map(customer.trading_addresses, i => ({
                    value:    i.address_id,
                    label:    `${i.address_name} - ${i.address_line_one}, ${i.address_town}, ${i.address_postcode}`,
                    address:  i,
                    contacts: _.map(i.active_contacts, i => ({
                        value:   i.contact_id,
                        label:   i.contact_name,
                        contact: i
                    }))
                })),
                Site: _.map(customer.site_addresses, i => ({
                    value:    i.address_id,
                    label:    `${i.address_name} - ${i.address_line_one}, ${i.address_town}, ${i.address_postcode}`,
                    address:  i,
                    contacts: _.map(i.active_contacts, i => ({
                        value:   i.contact_id,
                        label:   i.contact_name,
                        contact: i
                    }))
                })),
            };

            

            this.setState({
                customer: customer,
                isLoading: false,
                formData: {
                    ...this.state.formData,
                },
                lists: {
                    ...this.state.lists,
                    customerContacts: _.map(customer.registered_address.active_contacts , i => ({
                        value:   i.contact_id,
                        label:   i.contact_name,
                        contact: i,
                    })),
                    addresses: addresses
                }
            }, this.recalculateTotal)
        });
    }

    handleBlur = (formData, field, dp=2) => e => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                [field]: parseFloat(e.target.value).toFixed(dp)
            }
        });
    }

    handleChange = (formData, field) => e => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                [field]: e.target.value
            }
        }, ()=>{
            if (formData === 'editPartsRow'){
                this.recalculateEditPartsRow();
            }
        });
    }

    recalculateEditPartsRow = () => {
        let { editPartsRow } =  this.state;

        let qty = parseFloat(editPartsRow.qty);
        let perUnit = parseFloat(editPartsRow.perUnit);
        let discount = parseFloat(editPartsRow.discount);

        let unitDiscount = perUnit * (discount / 100);
        let newUnitPrice = perUnit - unitDiscount;

        let subTotal = qty * perUnit;
        let totalDiscount = qty * unitDiscount;
        let total = qty * newUnitPrice;

        let restockFee = editPartsRow.restockFee ? parseFloat(total * restockFeeMultiplier) : 0;
        let totalWithRestock = total - restockFee;

        this.setState({
            editPartsRow: {
                ...this.state.editPartsRow,
                qty, perUnit, discount, unitDiscount, newUnitPrice, subTotal, totalDiscount, total, restockFee, totalWithRestock
            }
        });
    }

    handleMultiSelectChange = (formData, field) => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            [formData]: {
                ...this.state[formData],
                [field]: values
            }
        }, ()=> {
            if (field === 'selectedSerialNumbers' || field === 'selectedWVL'){
                this.setState({
                    [formData]: {
                        ...this.state[formData],
                        qty: values.length ?? 0
                    }
                }, () => {
                    if (formData === 'editPartsRow'){
                        this.recalculateEditPartsRow();
                    }
                });
            } else if (formData === 'editPartsRow'){
                this.recalculateEditPartsRow();
            }
        });
    }

    handleSelectChange = (formData, field) => e => {
        switch(field) {
            case 'collectionAddressType':
                this.setState({
                    [formData]: {
                        ...this.state[formData],
                        collectionAddress: null,
                        collectionAddressContact: null,
                        [field]: e.value
                    }
                })
            break;
            case 'collectionAddress':
                this.setState({
                    [formData]: {
                        ...this.state[formData],
                        collectionAddressContact: null,
                        [field]: e.value
                    }
                })
            break;
            default:
                this.setState({
                    [formData]: {
                        ...this.state[formData],
                        [field]: e.value
                    }
                },()=>{
                    if (formData === 'editPartsRow'){
                        this.recalculateEditPartsRow();
                    }
                });
            break;
        }
    }

    handleEditItem = i => {
        this.setState({
            editPartsRow: {
                ...this.state.editPartsRow,
                ...i,
                show:               true,
                perUnit:            parseFloat(i.perUnit).toFixed(2),
                discount:           parseFloat(i.discount).toFixed(2),
            }
        })
    }

    handleDeleteItem = i => {
        this.setState({
            formData: {
                ...this.state.formData,
                partsRow:         _.filter(this.state.formData.partsRow, j => j.od_id !== i.od_id),
                deletedPartsRow:  [...this.state.formData.deletedPartsRow, i]
            }
        }, this.recalculateTotal);
    }

    handleReAssignItem = i => {
        this.setState({
            formData: {
                ...this.state.formData,
                deletedPartsRow: _.filter(this.state.formData.deletedPartsRow, j => j.od_id !== i.od_id),
            }
        }, () => {
            let rowIdx = _.findIndex(this.state.formData.partsRow, {od_id: i.od_id})
            if (rowIdx > -1){
                let { partsRow } = this.state.formData;
                partsRow[rowIdx].selectedSerialNumbers = partsRow[rowIdx].serialNumbers;
                partsRow[rowIdx].selectedWVL           = partsRow[rowIdx].WVL;
                partsRow[rowIdx].qty                   = partsRow[rowIdx].qtyMax;
                partsRow[rowIdx].total                 = partsRow[rowIdx].qtyMax * partsRow[rowIdx].newUnitPrice; 
                partsRow[rowIdx].restockFee            = parseFloat(partsRow[rowIdx].total)  * restockFeeMultiplier;
                partsRow[rowIdx].totalWithRestock      = parseFloat(partsRow[rowIdx].total) - parseFloat(partsRow[rowIdx].restockFee);
    
                this.setState({
                    formData: {
                        ...this.state.formData,
                        partsRow: partsRow
                    }
                }, this.recalculateTotal)
    
            } else {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        partsRow: [...this.state.formData.partsRow, i],
                    }
                }, this.recalculateTotal);
            }
        });
    }

    closeDialog = () => {
        this.setState({
            editPartsRow: initialState().editPartsRow
        }, this.recalculateTotal);
    }

    updateEditPart = () => {
        let { editPartsRow, formData } = this.state;
        let { partsRow, deletedPartsRow } = formData;

        let rowIdx = _.findIndex(partsRow, {od_id: editPartsRow.od_id})
        let selectedRow = partsRow[rowIdx];

        //remove from deleted partsRow
        deletedPartsRow = _.filter(deletedPartsRow, i => i.od_id !== editPartsRow.od_id);

        //update partsRow
        selectedRow = {
            ...selectedRow,
            selectedWVL:           editPartsRow.selectedWVL,
            selectedSerialNumbers: editPartsRow.selectedSerialNumbers,

            notes:                 editPartsRow.notes,
            qty:                   parseFloat(editPartsRow.qty),
            perUnit:               parseFloat(editPartsRow.perUnit),
            discount:              parseFloat(editPartsRow.discount),

            unitDiscount:          parseFloat(editPartsRow.perUnit) * parseFloat( parseFloat(editPartsRow.discount) / 100 ),
            newUnitPrice:          parseFloat(editPartsRow.perUnit) - parseFloat(parseFloat(editPartsRow.perUnit) * parseFloat( parseFloat(editPartsRow.discount) / 100 )),

            subTotal:              parseFloat(editPartsRow.qty) * parseFloat(editPartsRow.perUnit),
            totalDiscount:         parseFloat(editPartsRow.qty) * parseFloat(editPartsRow.perUnit) * parseFloat( parseFloat(editPartsRow.discount) / 100 ),
            total:                 parseFloat(parseFloat(editPartsRow.qty) * parseFloat(editPartsRow.perUnit) ) - parseFloat(  parseFloat(editPartsRow.qty) * parseFloat(editPartsRow.perUnit) * parseFloat( parseFloat(editPartsRow.discount) / 100 )),

            restockFee:            editPartsRow.restockFee,
            totalWithRestock:      parseFloat(parseFloat(editPartsRow.qty) * parseFloat(editPartsRow.perUnit) ) - parseFloat(  parseFloat(editPartsRow.qty) * parseFloat(editPartsRow.perUnit) * parseFloat( parseFloat(editPartsRow.discount) / 100 )) - parseFloat(editPartsRow.restockFee),
        }

        //create deleted
        if ( parseFloat(selectedRow.qty) < parseFloat(selectedRow.qtyMax) ){
            deletedPartsRow.push({
                ...selectedRow,
                qty:                   parseFloat( parseFloat(selectedRow.qtyMax) - parseFloat(selectedRow.qty) ),
                selectedWVL:           _.filter(selectedRow.WVL, i => !_.includes(selectedRow.selectedWVL, i)),
                selectedSerialNumbers: _.filter(selectedRow.serialNumbers, i => !_.includes(selectedRow.selectedSerialNumbers, i)),

                total:                 parseFloat(selectedRow.newUnitPrice) * parseFloat( parseFloat(selectedRow.qtyMax) - parseFloat(selectedRow.qty) ),
                restockFee:            editPartsRow.restockFee ? parseFloat(selectedRow.newUnitPrice) * parseFloat( parseFloat(selectedRow.qtyMax) - parseFloat(selectedRow.qty) ) * restockFeeMultiplier : 0,
                totalWithRestock:      parseFloat(selectedRow.newUnitPrice) * parseFloat( parseFloat(selectedRow.qtyMax) - parseFloat(selectedRow.qty) ) *  parseFloat( 1 - (editPartsRow.restockFee ? restockFeeMultiplier : 0)  )
            })
        }

        partsRow[rowIdx] = selectedRow;

        this.setState({
            formData: {
                ...this.state.formData,
                partsRow:         partsRow,
                deletedPartsRow:  deletedPartsRow
            }
        },()=>{this.closeDialog(); this.recalculateTotal()});
    }

    recalculateTotal = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                discount:         parseFloat(_.sumBy(this.state.formData.partsRow, 'totalDiscount')),
                subTotal:         parseFloat(_.sumBy(this.state.formData.partsRow, 'subTotal')),
                orderTotal:       parseFloat(_.sumBy(this.state.formData.partsRow, 'total')),
                restockFee:       parseFloat(_.sumBy(this.state.formData.partsRow, 'restockFee')),
                totalWithRestock: parseFloat(_.sumBy(this.state.formData.partsRow, 'totalWithRestock')),
                vat:              parseFloat(parseFloat(_.sumBy(this.state.formData.partsRow, 'totalWithRestock')) * this.state.vatRate),    
                total:            parseFloat(parseFloat(_.sumBy(this.state.formData.partsRow, 'totalWithRestock')) * (1 + this.state.vatRate)),
            }
        })
    }

    handleEmail = (id) => {
        API.get('/customerReturns/emailTemplates/9')
        .then(res => {
            this.props.deployDialog(
                <SingleEmailDialog
                    contactId={this.state.formData.collectionAddressContact}
                    customerId={this.state.customer.cust_id}
                    subject={res.data.cret_name}
                    body={res.data.cret_text}
                    emailUrl={`/customerReturns/returns/${id}/sendEmail`}
                    postParams={{
                        templateId: 9,
                    }}
                    onSubmit={()=>this.props.history.push('/returns/customerReturn/approval')}
                    onClose={()=>this.props.history.push('/returns/customerReturn/approval')}
                />, 'You Are About To Email - Customer Return Acknowledgement', 'success', 'md'
            )
        })
    }

    handleAdd = () => {
        let newFormData = new FormData();
        _.each(_.keys(this.state.formData), key => {
            switch(key){
                case 'partsRow':
                    newFormData.append(key, JSON.stringify(this.state.formData[key]));
                    break;
                case 'deletedPartsRow':
                    break;
                default:
                    newFormData.append(key, this.state.formData[key]);
                    break;
            }
        });

        API.post( this.state.amend ? `/customerReturns/returns/${this.props.match.params.returnId}/amend` : `/customerReturns/returns/create`, newFormData)
        .then(res => {
            this.handleEmail(res.data.cr_id);
        })
    }

    handlePreview = () => {
        let newFormData = new FormData();
        _.each(_.keys(this.state.formData), key => {
            switch(key){
                case 'partsRow':
                    newFormData.append(key, JSON.stringify(this.state.formData[key]));
                    break;
                case 'deletedPartsRow':
                    break;
                default:
                    newFormData.append(key, this.state.formData[key]);
                    break;
            }
        });

        newFormData.append('preview', 1);

        API.post( this.state.amend ? `/customerReturns/returns/${this.props.match.params.returnId}/amend` : `/customerReturns/returns/create`, newFormData)
        .then(res => {
            pdfFromBase64(res.data.pdf, res.data.pdfName);
        })
    }

    moveDown = (e) => {
        let index = e.rowIdx;
        let { partsRow } = this.state.formData;
        let temp = partsRow[index];
        partsRow[index] = partsRow[index + 1];
        partsRow[index + 1] = temp;
        this.setState({formData: {...this.state.formData, partsRow}});
    }

    moveUp = (e) => {
        let index = e.rowIdx;
        let { partsRow } = this.state.formData;
        let temp = partsRow[index];
        partsRow[index] = partsRow[index - 1];
        partsRow[index - 1] = temp;
        this.setState({formData: {...this.state.formData, partsRow}});
    }

    reOrder = () => {
        let { partsRow } = this.state.formData;
        let sorted       = [];
        _.each(partsRow, i => {
            let pt      = i.number;
                pt      = pt.substring(3, pt.length);
                pt      = pt.replace(/[0-9]/g, '');
            let order   = _.find(this.state.partOrder, {'part_type_code': pt}) ? _.find(this.state.partOrder, {'part_type_code': pt}).part_type_sort_order : 99; 

            if (!sorted[order]) sorted[order] = [];
            sorted[order].push(i);
            sorted[order] = _.orderBy(sorted[order], [i => {
                return parseInt(i.number.substring(pt.length - 4));
            }], ['asc']);
        })
        this.setState({formData: {...this.state.formData, partsRow: _.compact(_.flatten(sorted))}});
    }

    render() {

        const { isLoading, order, formData, lists, editPartsRow, amend } = this.state;

        if (isLoading) return ( <LoadingCircle/> )

        let customerContact = _.find(lists.customerContacts, {value: formData.customerContact})?.contact;
        let collectionAddressContact = !!formData.collectionAddressContact && _.find(_.find(lists.addresses[formData.collectionAddressType], {value: formData.collectionAddress})?.contacts, {value: formData.collectionAddressContact})?.contact;

        let tableColumns = _.filter([
            {
                heading:       '',
                field:         'img',
                fieldFormat:   'image',
                sizeToContent: true,
            },
            {
                heading: 'Part',
                field:   i => <>
                    <span>{`${i.number} - ${i.desc}`}</span>
                    { i.selectedWVL?.length > 0 && 
                        <Typography style={{color: colors.subText, fontSize: '0.7rem'}}>
                            {_.map(i.selectedWVL, i => <>{i}<br/></>)}
                        </Typography>
                    }
                    { i.selectedSerialNumbers?.length > 0 && 
                        <Typography style={{color: colors.subText, fontSize: '0.7rem'}}>
                            {_.map(i.selectedSerialNumbers, i => <>{i}<br/></>)}
                        </Typography>
                    }
                    {i.notes &&
                        <Typography variant="body2" style={{color: colors.subText, fontSize: '0.7rem'}}>
                            {i.notes}
                        </Typography>
                    }
                </>
            },
            {
                heading:       'Quantity',
                field:         'qty',
                fieldFormat:   'decimal:3',
                sizeToContent: true,
                alignment:     'right',
            },
            {
                heading:       'Unit Price',
                field:         'perUnit',
                fieldFormat:   'currency',
                sizeToContent: true,
                alignment:     'right',
            },
            ...formData.hasDiscount ? [
            {
                heading:       'Discount Rate',
                field:         'discount',
                fieldSuffix:   '%',
                sizeToContent: true,
                alignment:     'right',
                fieldFormat:   'decimal:2',
            },
            {
                heading:       'New Unit Price',
                field:         'newUnitPrice',
                fieldFormat:   'currency',
                sizeToContent: true,
                alignment:     'right',
            },
            {
                heading:       'Discount Total',
                field:         'totalDiscount',
                fieldFormat:   'currency',
                sizeToContent: true,
                alignment:     'right',
            },
            ] : [],
            {
                heading:       'Total Price',
                field:         'total',
                fieldFormat:   'currency',
                sizeToContent: true,
                alignment:     'right',
            },
            parseFloat(formData.restockFee) > 0 && {
                heading:       'Restock Fee',
                field:         'restockFee',
                fieldFormat:   'currency',
                sizeToContent: true,
                alignment:     'right',
            },
            {
                heading:       'Total Credit',
                field:         'totalWithRestock',
                fieldFormat:   'currency',
                sizeToContent: true,
                alignment:     'right',
                fieldPrefix:   '-',
            },
        ], i => i);

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <Typography variant="h5" gutterBottom style={{color: colors.red}}>{amend ? 'Amend' : 'Add'} Customer Return</Typography>
                </Grid>
                {/*}
                <Grid item xs={12} >
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<AllIcon icon={icons.arrowDown} noMargin white/>}>
                            <Typography variant="h5"> Based On Order: {order.full_reference}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <DataTable
                                config={{
                                    key: 'od_id',
                                    style: i => ({color: !i.part.stockable ? colors.disabled : 'inherit'}),
                                }}
                                columns={[
                                    {
                                        heading:       '',
                                        field:         'part.default_image.file_path',
                                        fieldFormat:   'image',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Part',
                                        field:   i => <>
                                            <span>{`${i.od_part_number} - ${i.od_part_description}`}</span>
                                            { i.warranty_void_labels?.length > 0 && 
                                                <Typography style={{color: colors.subText, fontSize: '0.7rem'}}>
                                                    {_.map(i.warranty_void_labels, i => <>{i.pwvll_code}<br/></>)}
                                                </Typography>
                                            }
                                            { i.serial_numbers?.length > 0 && 
                                                <Typography style={{color: colors.subText, fontSize: '0.7rem'}}>
                                                    {_.map(i.serial_numbers, i => <>{i.psnsl_serial_number}<br/></>)}
                                                </Typography>
                                            }
                                            {i.od_notes &&
                                                <Typography variant="body2" style={{color: colors.subText, fontSize: '0.7rem'}}>
                                                    {i.od_notes}
                                                </Typography>
                                            }
                                        </>
                                    },
                                    {
                                        heading:       'Quantity',
                                        field:         'od_quantity',
                                        fieldFormat:   'decimal:3',
                                        sizeToContent: true,
                                        alignment:     'right',
                                    },
                                    {
                                        heading:       'Unit Price',
                                        field:         'od_unit_price',
                                        fieldFormat:   'currency',
                                        sizeToContent: true,
                                        alignment:     'right',
                                    },
                                    {
                                        heading:       'Discount Rate',
                                        field:         'od_discount_rate',
                                        fieldSuffix:   '%',
                                        sizeToContent: true,
                                        alignment:     'right',
                                        fieldFormat:   'decimal:2',
                                    },
                                    {
                                        heading:       'New Unit Price',
                                        field:         'od_new_unit_price',
                                        fieldFormat:   'currency',
                                        sizeToContent: true,
                                        alignment:     'right',
                                    },
                                    {
                                        heading:       'Discount Total',
                                        field:         'od_discount_total',
                                        fieldFormat:   'currency',
                                        sizeToContent: true,
                                        alignment:     'right',
                                    },
                                    {
                                        heading:       'Total Price',
                                        field:         'od_total_price',
                                        fieldFormat:   'currency',
                                        sizeToContent: true,
                                        alignment:     'right',
                                    },
                                    {
                                        heading:       'In Return',
                                        field:         i => {
                                            if (!i.part.stockable){
                                                return (
                                                    <AllIcon
                                                        noMargin 
                                                        icon={icons.warning} 
                                                        color={colors.disabled} 
                                                        tooltip="This part is not returnable"    
                                                    />
                                                )
                                            }

                                            let returnedQTY = _.find(this.state.formData.partsRow, {od_id: i.od_id})?.qty || 0
                                            let qty = i.od_quantity;

                                            if (returnedQTY < qty) {
                                                /*
                                                return (
                                                    <AllIcon
                                                        noMargin 
                                                        icon={icons.false} 
                                                        color={colors.orange} 
                                                        tooltip="items partially returned"    
                                                    />
                                                )*//*
                                                return (
                                                    <AllIcon
                                                        noMargin 
                                                        icon={icons.true} 
                                                        color={colors.green} 
                                                        tooltip="Can Be Returned"    
                                                    />
                                                )
                                            } else if (returnedQTY >= qty){
                                                return (
                                                    <AllIcon
                                                        noMargin 
                                                        icon={icons.true} 
                                                        color={colors.green} 
                                                        tooltip="Can Be Returned"    
                                                    />
                                                )
                                            } else {
                                                return (
                                                    <AllIcon
                                                        noMargin 
                                                        icon={icons.false} 
                                                        color={colors.red} 
                                                        tooltip="Not  in return"    
                                                    />
                                                )
                                            }

                                        },                 
                                        sizeToContent: true,
                                    }
                                ]}
                                rows={order.order_details}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
                */}
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={3}
                            alignItems="center"
                        >
                            <Grid item xs={8}>
                                <Typography variant="h5"> Based On Order: {order.full_reference}</Typography>
                            </Grid>
                            <Grid item style={{marginLeft:'auto'}}>
                                <AllIcon
                                    icon={IconHelper.view}
                                    tooltip="View Order"
                                    onClick={() => window.open(`/sales/order/view/${order.order_id}`)}
                                    noMargin
                                />
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={6}>
                    <PaddedPaper>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h5">Customer</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant='body1'>Company Name</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant='body1'>{order.order_cust_name}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant='body1'>Address</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant='body1' style={{whiteSpace: 'pre-line'}}>{order.order_cust_address}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant='body1'>Payment Terms</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant='body1'>{order.order_payment_terms}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    label="Customer Contact *"
                                    options={lists.customerContacts}
                                    value={formData.customerContact}
                                    fullWidth
                                    noClear
                                    onChange={this.handleSelectChange('formData', 'customerContact')}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant='body1'>Phone Number</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant='body1'>{customerContact?.contact_telephone}{!!(customerContact?.contact_mobile && customerContact?.contact_telephone) && ' / '}{customerContact?.contact_mobile}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant='body1'>Email Address *</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant='body1'>{customerContact?.contact_email}</Typography>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={6}>
                    <PaddedPaper>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    noClear
                                    onChange={this.handleSelectChange('formData', 'collectionAddressType')}
                                    label="Address Type *"
                                    options={[
                                        {value: 'Registered', label: 'Registered'},
                                        {value: 'Accounts',   label: 'Accounts'},
                                        {value: 'Trading',    label: 'Trading'},
                                        {value: 'Site',       label: 'Site'},
                                    ]}
                                    value={formData.collectionAddressType}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    noClear
                                    onChange={this.handleSelectChange('formData', 'collectionAddress')}
                                    label="Address *"
                                    options={formData.collectionAddressType ? lists.addresses[formData.collectionAddressType] : []}
                                    value={formData.collectionAddress}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    noClear
                                    onChange={this.handleSelectChange('formData', 'collectionAddressContact')}
                                    label="Contact *"
                                    options={formData.collectionAddressType && formData.collectionAddress ? _.find(lists.addresses[formData.collectionAddressType], {value: formData.collectionAddress})?.contacts : []}
                                    value={formData.collectionAddressContact}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant='body1'>Phone Number</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant='body1'>{collectionAddressContact?.contact_telephone}{!!(collectionAddressContact?.contact_mobile && collectionAddressContact?.contact_telephone) && ' / '}{collectionAddressContact?.contact_mobile}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant='body1'>Email Address *</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant='body1'>{collectionAddressContact?.contact_email}</Typography>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                {/*}
                {formData.deletedPartsRow.length > 0 &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant='h6' gutterBottom>Return Removed Parts</Typography>
                            <DataTable
                                config={{
                                    key:   'od_id'
                                }}
                                columns={[...tableColumns, {
                                    actions: i => ([
                                        {
                                            name: 'Reassign',
                                            icon:   icons.plus,
                                            onClick: this.handleReAssignItem
                                        }
                                    ])
                                }]}
                                rows={_.orderBy(formData.deletedPartsRow, ['number'], ['asc'])}
                            />
                        </PaddedPaper>
                    </Grid>
                }
                */}
                <Grid item xs={12}>
                    <PaddedPaper key={_.flatten(_.map(formData.partsRow,'od_id'))}>
                        <DataTable
                            config={{
                                key:   'od_id',
                                style: {color: colors.red}
                            }}
                            columns={[...tableColumns,
                                {
                                    actions: i => ([
                                        {
                                            name:    'Edit',
                                            icon:    icons.edit,
                                            onClick: this.handleEditItem,
                                        },
                                        {
                                            name: 'View',      
                                            icon: icons.view,        
                                            onClick: () => {
                                                this.props.deployDialog(<FullScreenDialog open={1} onClose={this.props.closeDialog} title="Part Information" content={<ViewPart id={i.id} />}/>, "", null, 'fs')
                                            }
                                        },
                                        {
                                            name:     'Delete',
                                            icon:     icons.binAlt,
                                            onClick:  this.handleDeleteItem,
                                            disabled: formData.partsRow.length === 1
                                        },
                                        {
                                            name: 'Move Up',
                                            icon: icons.arrowUp,
                                            onClick: this.moveUp,
                                            disabled: parseFloat(formData.partsRow.length) === 1 || parseFloat(i.rowIdx) === 0
                                        },
                                        {
                                            name: 'Move Down',
                                            icon: icons.arrowDown,
                                            onClick: this.moveDown,
                                            disabled: parseFloat(formData.partsRow.length) === 1 || parseFloat(i.rowIdx) === parseFloat(parseFloat( formData.partsRow.length) - 1) 
                                        }
                                    ])
                                }
                            ]}
                            rows={formData.partsRow}
                        />
                        {formData.partsRow.length > 1 &&
                            <div className='buttonRow'>
                                <Button
                                    onClick={this.reOrder}
                                    color='primary'
                                    variant='contained'
                                >
                                    <AllIcon
                                        icon={icons.random}
                                        white
                                        size='small'
                                    />
                                    Sort
                                </Button>

                            </div>
                        }
                    </PaddedPaper>
                </Grid>
                <Grid item xs={6}>
                    <PaddedPaper>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="right">
                                        <Typography variant="subtitle1">
                                            Order Total
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="50%">
                                        <Typography style={{color: colors.red}}>-£{parseFloat(formData.totalWithRestock).toFixed(2)}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">
                                        <Typography variant="subtitle1">
                                            {`VAT (${parseFloat(parseFloat(this.state.vatRate) * 100).toFixed(2)}%)`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="50%">
                                        <Typography style={{color: colors.red}}>-£{parseFloat(formData.vat).toFixed(2)}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow  style={{backgroundColor:'#f2f2f2'}}>
                                    <TableCell align="right">
                                        <Typography variant="subtitle1">
                                            Total
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="50%">
                                        <Typography style={{color: colors.red}}>-£{parseFloat(formData.total).toFixed(2)}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <PaddedPaper>
                        <Typography variant='h5' gutterBottom>Customer Return Details</Typography>
                        <AutoCompleteSelect
                            noClear
                            label='Expected Outcome *'
                            options={[
                                {value: 'Refund',  label: 'Credit Note'},
                                {value: 'Replace', label: 'Replace'},
                                {value: 'Repair',  label: 'Repair'},
                            ]}
                            value={formData.expectedOutcome}
                            onChange={this.handleSelectChange('formData', 'expectedOutcome')}
                            fullWidth
                        />
                        <Textarea
                            label='Return Reason Notes'
                            value={formData.notes}
                            onChange={this.handleChange('formData', 'notes')}
                            rows={10}
                        />
                        <div className='buttonRow'>
                            <Button
                                variant="outlined"
                                onClick={this.handlePreview}
                                disabled={!formData.expectedOutcome || formData.partsRow.length === 0 || !formData.collectionAddress || !formData.collectionAddressContact || !formData.customerContact}
                            >Preview</Button>
                            {amend ?
                                <Button
                                variant="contained"
                                color="primary"
                                onClick={()=>this.props.deployConfirmation('Are you sure you want to amend this customer return?', 'Amend Customer Return', this.handleAdd)}
                                disabled={!formData.expectedOutcome || formData.partsRow.length === 0 || !formData.collectionAddress || !formData.collectionAddressContact || !formData.customerContact}
                                >Update</Button>
                            :
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>this.props.deployConfirmation('Are you sure you want to create this customer return?', 'Create Customer Return', this.handleAdd)}
                                    disabled={!formData.expectedOutcome || formData.partsRow.length === 0 || !formData.collectionAddress || !formData.collectionAddressContact || !formData.customerContact}
                                >Add</Button>
                            }
                        </div>
                    </PaddedPaper>
                </Grid>

                <Dialog
                    open={editPartsRow.show}
                    maxWidth='lg'
                    scroll="body"
                    fullWidth
                >
                    <DialogTitle>Update Part For {`${editPartsRow.number} - ${editPartsRow.desc}`}</DialogTitle>
                    <DialogContent>
                        <div style={{display: 'flex', padding: '0px 1em'}} >
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{marginBottom: '1.5em'}}>
                                    <Table cellspacing={0}>
                                        <TableRow>
                                            <TableCell style={{textAlign:'center', height:65, width: `5%`, maxWidth:65, padding: 5}}>
                                                <ImageWithError
                                                    src={editPartsRow.img}
                                                    style={{width: 'auto', maxHeight: '50px', maxWidth: '50px'}}
                                                />
                                            </TableCell>
                                            <TableCell style={{width: `${((100/5)*1)}%`, padding: 2}}>
                                                <TextField
                                                    name="Part"
                                                    label="Part"
                                                    value={`${editPartsRow.number} - ${editPartsRow.desc}`}
                                                    size="small"
                                                    fullWidth
                                                    Disabled
                                                />
                                            </TableCell>
                                            <TableCell style={{width: `${((100/5)*0.5)}%`, padding: 2}}>
                                                <TextField
                                                    name="quantity"
                                                    size="small"
                                                    label={`Quantity (Max: ${editPartsRow.qtyMax})`}
                                                    type='number'
                                                    value={parseFloat(editPartsRow.qty)}
                                                    onChange={this.handleChange('editPartsRow', 'qty')}
                                                    fullWidth
                                                    inputProps={{
                                                        max: editPartsRow.qtyMax,
                                                        min: 0
                                                    }}
                                                    disabled={ editPartsRow?.serialNumbers?.length > 0 || editPartsRow?.WVL?.length > 0 }
                                                />
                                            </TableCell>
                                            <TableCell style={{width: `${((100/5)*0.5)}%`, padding: 2}}>
                                                <TextField
                                                    name="perUnit"
                                                    size="small"
                                                    label={`Unit Price`}
                                                    type='number'
                                                    value={editPartsRow.perUnit}
                                                    onChange={this.handleChange('editPartsRow', 'perUnit')}
                                                    onBlur={this.handleBlur('editPartsRow', 'perUnit', 2)}
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                    }}
                                                />
                                            </TableCell>
                                            {!!formData.hasDiscount && <>
                                                <TableCell style={{width: `${((100/5)*0.5)}%`, padding: 2}}>
                                                    <TextField
                                                        name="discount"
                                                        size="small"
                                                        label={`Discount Rate`}
                                                        type='number'
                                                        value={editPartsRow.discount}
                                                        onChange={this.handleChange('editPartsRow', 'discount')}
                                                        onBlur={this.handleBlur('editPartsRow', 'discount', 2)}
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (<InputAdornment position="end">%</InputAdornment>)
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell style={{width: `${((100/5)*0.5)}%`, padding: 2}}>
                                                    <TextField
                                                        name="perUnit"
                                                        size="small"
                                                        label={`New Unit Price`}
                                                        type='number'
                                                        value={parseFloat(editPartsRow.newUnitPrice).toFixed(2)}
                                                        disabled
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                        }}
                                                    />
                                                </TableCell>
                                            </>}
                                            <TableCell style={{width: `${((100/5)*0.5)}%`, padding: 2}}>
                                                <AutoCompleteSelect
                                                    options={[
                                                        { 
                                                            value:  parseFloat(parseFloat(parseFloat(editPartsRow.newUnitPrice) * parseFloat(editPartsRow.qty)) * parseFloat(restockFeeMultiplier)),
                                                            label: 'Yes' 
                                                        },
                                                        {
                                                            value: 0,
                                                            label: 'No'
                                                        }
                                                    ]}
                                                    value={parseFloat(editPartsRow.restockFee)}
                                                    onChange={this.handleSelectChange('editPartsRow', 'restockFee')}
                                                    label='Restock Fee?'
                                                    fullWidth
                                                    noClear
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </Grid>
                                <Grid item xs={7}>
                                    <Grid container spacing={1} style={{paddingRight: '2em', alignContent: 'flex-start', alignItems: 'top'}}>
                                        {
                                            editPartsRow?.serialNumbers?.length > 0 &&
                                            <Grid item xs={12}>
                                                <AutoCompleteMultiSelect
                                                    label="Serial Numbers"
                                                    value={editPartsRow?.selectedSerialNumbers}
                                                    onChange={this.handleMultiSelectChange('editPartsRow', 'selectedSerialNumbers')}
                                                    options={_.map(editPartsRow?.serialNumbers, i => ({value: i, label: i}))}
                                                />
                                            </Grid>
                                        }
                                        {
                                            editPartsRow?.WVL?.length > 0 &&
                                            <Grid item xs={12}>
                                                <AutoCompleteMultiSelect
                                                    label="Warranty Void Labels"
                                                    value={editPartsRow?.selectedWVL}
                                                    onChange={this.handleMultiSelectChange('editPartsRow', 'selectedWVL')}
                                                    options={_.map(editPartsRow?.WVL, i => ({value: i, label: i}))}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <Textarea
                                                label="Notes"
                                                value={editPartsRow.notes}
                                                onChange={this.handleChange('editPartsRow', 'notes')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    {!!formData.hasDiscount &&
                                        <TextField
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                                inputProps: {
                                                    min: 0
                                                }
                                            }}
                                            fullWidth
                                            disabled
                                            label="Discount Total"
                                            value={parseFloat(editPartsRow.totalDiscount).toFixed(2)}
                                            style={{paddingBottom: '1em'}}
                                        />
                                    }
                                    <TextField
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                            inputProps: {
                                                min: 0
                                            }
                                        }}
                                        fullWidth
                                        disabled
                                        label="Total Credit"
                                        value={parseFloat(editPartsRow.totalWithRestock).toFixed(2)}
                                        style={{paddingBottom: '1em'}}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions
                        style={{padding: '1.5em 3em'}}
                    >
                        <Button variant='outlined' onClick={this.closeDialog} >Close</Button>
                        <Button color='primary' variant='contained' onClick={this.updateEditPart} disabled={(!(parseFloat(editPartsRow.qty) > 0)) || parseFloat(editPartsRow.qty) > parseFloat(editPartsRow.qtyMax)} >Update</Button>
                    </DialogActions>
                </Dialog>

            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployDialog: (content, title, variant, size='md')  => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:  ()                                    => dispatch(closeDialog()),
    deployConfirmation: (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
});

export default connect(null, mapDispatchToProps)(AddCustomerReturn);
