import API from 'API';
import _, { assign, map } from 'lodash';
import moment from 'moment';
import React,{ useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import DatePicker from 'Components/Common/DatePickers/DatePicker';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import StaffActions from 'Components/Sales/Order/ViewOrder/sections/StaffActions';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import { colors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import MuiLink from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';

import { downloadS3File } from 'Functions/MiscFunctions';

const ProccessDispachDialog = ({orderId, callback, paymentConfirmed, noClose, classes}) => {
    
    const initialState = {
        formErrors:{},
        formData: {
            despatchRequired: true,
            desp:               null,
            delivery:           null,
            picking:            null,
            courierId:          '',
            courierNotes:       '',
            noDespachReason:    null,
            parts:              [],
            consignments:       [],
            pfType:             0
        },
        allowDespatch:  true,
        couriersList:   [],
        reasonsList:    [],
        isLoading:      true,
        order: {},
        orderLocked:    true,
    };

    const dispatch = useDispatch(),

    [state, setState] = useState(initialState),

    {formErrors, formData, formData:{despatchRequired, desp, delivery, picking, courierId, courierNotes, pfType, consignments}, allowDespatch, couriersList, isLoading,reasonsList, order, orderLocked} = state,

    handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    },

    handleRadio = () => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                despatchRequired: !despatchRequired
            }
        }));
    },

    handleSelectChange = fieldName => selectedOption => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [fieldName]: fieldName === 'desp' || fieldName === 'delivery' || fieldName === 'picking' ? moment(selectedOption).format('YYYY-MM-DD') : selectedOption?.value
            }
        }));
    },

    handleSelectChangePart = (type, id, idx) => e => {
        let parts = formData.parts;
        let part  = _.find(parts, i => {return i.id == id;});
        switch(type){
            case 'serialNumber':      
            case 'serialNumberManual': 
                part.sn[idx] = e.target?.value ? e.target.value : e.value;
            break;
            case 'WarrantyVoidLabels':  
                part.wvl[idx] = e.target.value;
            break;
        }
        parts[_.findIndex(parts, i => {return i.id == id;})] = part;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                parts: _.map(parts, part => {
                    part.error = null;
                    if (part.rWVL){
                        if ((part.wvl.length > part.qty))
                                part.error = 'Too many warranty void labels added' ;
                        if ((part.wvl.length < part.qty))
                            part.error = `${part.qty} warranty void labels Required`;
                        
                        if (_.filter(part.wvl, w => (w.length != 10 || w.search('CS-') < 0)).length > 0)
                            part.error = 'Warranty void labels need to be in format CS-0000000';

                    }
        
                    if (part.autoSN && !part.manualSN) {
                        if ((part.sn.length > part.qty))
                            part.error = 'Too many serial numbers added' ;
                        if ((part.sn.length < part.qty))
                            part.error = `${part.qty} serial numbers Required`;
                    }

                    if (!part.autoSN && part.manualSN) {
                        if ((part.sn.length < part.qty))
                            part.error = `${part.qty} serial numbers Required`;
                    }
        
                    return part;
                })
            }
        }));
       
    },

    handleClose = () => {
        dispatch(closeDialog());
        window.location.reload();
    },

    handleSubmit = () => dispatch(deployConfirmation('Are you sure you want to handle despatch?', 'Handle Despatch?', submit)),

    submit = () => {
        setState(state => ({
            ...state,
            isLoading: true
        }));

        API.post(`/sales/orders/${orderId}/setDespatch`, {
            ...formData,
            parts: _.map(
                formData.parts, i => {
                    return _.assign({ 
                        id:         i.id,
                        qty:        i.qty,
                        hasSn:      i.manualSN || i.autoSN,
                        hasWVL:     i.rWVL,
                        manualSN:   i.manualSN,
                        sn:         i.sn,
                        wvl:        i.wvl
                    })
                }
            )
        })
        .then(result => {
            if(result.data.errors) {
                if (_.find(result.data.errors, {field: 'order'})){
                    dispatch(deploySnackBar('error', _.find(result.data.errors, {field: 'order'}).msg));
                    dispatch(closeDialog());
                } else {
                    setState(state => ({
                        ...state,
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    }));
                }
            } else if(result.data.success) {
                setState(state => (initialState));
                dispatch(deploySnackBar('success', 'You have successfully processed the order'));
                callback();
            }
        });
    };

    const updateConsignment = i => e => {
        let consignments = formData.consignments;
        consignments[i]  = parseInt(e);
        setState(state => ({
            ...state,
            formData: {
                ...state.formData,
                consignments
            }
        }));
    };

    const addRow = () => {
        let consignments = formData.consignments;
        consignments.push(1);
        setState(state => ({
            ...state,
            formData: {
                ...state.formData,
                consignments
            }
        }));
    }

    const removeConsignment = i => {
        let consignments = formData.consignments;
        consignments.splice(i,1);
        setState(state => ({
            ...state,
            formData: {
                ...state.formData,
                consignments
            }
        }));
    }

    const updateConsignmentCount = e => {
        let value = e ? e.value : 0;
        let consignments = formData.consignments;
        let i    = consignments.length;
        if (i > value){
            for(i; value < i; i--){ consignments.splice(-1);}
        } else {
            for(i; value > i; i++){consignments.push(1);}
        }
        setState(state => ({
            ...state,
            formData: {
                ...state.formData,
                consignments
            }
        }));
    }

    useEffect(() => {
        Promise.all([
            API.get(`/sales/orders/${orderId}`, { params: { forPicking: true } } ),
            API.get('/sales/couriers/all'), 
            API.get('/misc/noDespatchReasons')
        ])
        .then(([orderRes, courRes, ndrRes]) => {
            let reasonsList = _.map(ndrRes.data, i => {
                return _.assign({
                    value: i.ndr_name,
                    label: i.ndr_name
                });
            })
            let couriersList = [];
            couriersList = map(courRes.data.couriers, el => {
                return assign({
                    value: el.cour_id,
                    label: el.cour_name
                });
            });
            let parts = map(_.filter(orderRes.data.order_details, i => {
                let part = i.part;
                return (part.part_warranty_void_label == 'Yes' || part.part_req_serial_number == 'Yes' || part.part_req_serial_number == 'Manual');
            } ),  d => {
                let part = d.part;
                return _.assign({
                    id:         d.od_id,
                    qty:        d.od_quantity,
                    name:       `${part.part_number} - ${part.part_description}`,
                    error:      (part.part_req_serial_number == 'Manual') ? false : true,
                    manualSN:   (part.part_req_serial_number == 'Manual'),
                    autoSN:     (part.part_req_serial_number == 'Yes'),
                    rWVL:       (part.part_warranty_void_label == 'Yes'),
                    sn:         [],
                    wvl:        [],
                    availableNumbers: part?.part_model_type?.issued_serial_number ? _.map(part.part_model_type.issued_serial_number, sn => {
                        return _.assign({
                            value: sn.psn_id,
                            label: sn.psn_serial_number
                        })
                    }) : null
                })
            })
            setState(state => ({
                ...state,
                formData: {
                    ...state.formData,
                    desp: orderRes.data.order_despatch_date !== null ? moment(orderRes.data.order_despatch_date).format('YYYY-MM-DD') : null,
                    delivery: orderRes.data.order_delivery_date !== null ? moment(orderRes.data.order_delivery_date).format('YYYY-MM-DD') : null,
                    picking: orderRes.data.order_picking_date !== null ? moment(orderRes.data.order_picking_date).format('YYYY-MM-DD') : null,
                    courierId: orderRes.data.order_courier_id,
                    courierNotes: orderRes.data.order_courier_notes,
                    paymentConfirmed: paymentConfirmed,
                    despatchRequired: _.filter(orderRes.data.order_details, i => i.part.part_product == 'Yes' ).length > 0,
                    parts
                },
                allowDespatch:    _.filter(orderRes.data.order_details, i => i.part.part_product == 'Yes' ).length > 0,
                couriersList,
                reasonsList,
                isLoading: false,
                order: orderRes.data
            }));
        });
    }, [orderId]);
    
    const lockOrder = orderLocked => {
        setState(state => ({
            ...state,
            orderLocked
        }));
    };

    return (
        <>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={12} style={{paddingTop: 0, paddingBottom: '1em'}}>
                        <StaffActions orderId={order.order_id} lockCallback={lockOrder} type={'Confirming'}/>
                    </Grid>
                    {orderLocked ? 
                        <>
                            <Grid item xs={12}>
                                {orderLocked}
                            </Grid>
                            <Grid item xs={12} className='buttonRow'>
                                <Button onClick={handleClose} variant="outlined" color="default">Close</Button>
                            </Grid>
                        </>: <>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                Does this order require Despatch?
                            </Typography>
                        </Grid>
                        {!!!allowDespatch &&
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    Orders with only services cannot be despatched
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <RadioGroup value={despatchRequired} onChange={handleRadio} row>
                                    <FormControlLabel value={true} control={<Radio color="primary" checked={despatchRequired} />} label="Yes" disabled={!allowDespatch} />
                                    <FormControlLabel value={false} control={<Radio color="primary" checked={!despatchRequired} />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {despatchRequired && (
                            <>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <DatePicker 
                                            type="date"
                                            id="desp"
                                            name="desp"
                                            label="Picking Date *"
                                            value={picking}
                                            onChange={handleSelectChange('picking')}
                                            errorText={formErrors?.['picking']}
                                            autoOk
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DatePicker 
                                            type="date"
                                            id="desp"
                                            name="desp"
                                            label="Despatch Date *"
                                            value={desp}
                                            onChange={handleSelectChange('desp')}
                                            errorText={formErrors?.['desp']}
                                            autoOk
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DatePicker 
                                            type="date"
                                            id="delivery"
                                            name="delivery"
                                            label="Delivery Date *"
                                            value={delivery}
                                            onChange={handleSelectChange('delivery')}
                                            errorText={formErrors?.['delivery']}
                                            autoOk
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <AutoCompleteSelect 
                                            options={couriersList}
                                            label='Courier *'
                                            value={courierId}
                                            onChange={handleSelectChange('courierId')}
                                            error={formErrors?.['courier'] && true}
                                            errorText={formErrors?.['courier']}
                                        />
                                    </FormControl>
                                </Grid>
                                {courierId === 1 && 
                                    <>
                                        <FormControl error={formErrors && formErrors['pfType'] && true} fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                options={[
                                                    {value: 0, label: ''},
                                                    {value: 1, label: 'Parcel Force 24'},
                                                    {value: 2, label: 'Parcel Force 48'},
                                                ]}
                                                label='Service *'
                                                value={pfType}
                                                onChange={handleSelectChange('pfType')}
                                                error={formErrors?.['pfType'] && true}
                                                errorText={formErrors?.['pfType']}
                                            />
                                        </FormControl>
                                        <FormControl error={formErrors && formErrors['consignment'] && true} fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                options={_.map(_.range(0,11), i => _.assign({value: i, label: i}))}
                                                label='Consignments *'
                                                value={consignments.length}
                                                onChange={updateConsignmentCount}
                                            />
                                            <Table>
                                                <TableBody>
                                                    {!!consignments.length && _.map(consignments, (i,index) => 
                                                        <TableRow style={{borderBottom: '1px solid rgba(0, 0, 0, 0.54)'}}>
                                                            <TableCell style={{verticalAlign: 'middle'}}>
                                                                <Typography>Consignment {index + 1}:</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <FormControl fullWidth margin="none">
                                                                    <AutoCompleteSelect 
                                                                        name={`Consignment${index}`}
                                                                        label=""
                                                                        value={i}
                                                                        options={_.map(_.range(1,11), j => _.assign({value: j, label: j}))}
                                                                        onChange={(e)=>{updateConsignment(index)(e.value)}}
                                                                        noClear
                                                                    />
                                                                </FormControl>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </FormControl>
                                    </>
                                }
                                <Grid item xs={12}>
                                    <Textarea
                                        id="courierNotes"
                                        name="courierNotes"
                                        label="Any Courier Notes"
                                        rows={4}
                                        margin="dense"
                                        value={courierNotes}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </>
                        )}
                        {!despatchRequired && 
                            <>
                                <FormControl fullWidth>
                                    <AutoCompleteSelect 
                                        options={reasonsList}
                                        name='noDespachReason'
                                        label='Reason *'
                                        value={formData.noDespachReason}
                                        onChange={handleSelectChange('noDespachReason')}
                                        error={formErrors?.['noDespachReason'] && true}
                                        errorText={formErrors?.['noDespachReason']}
                                    />
                                </FormControl>
                                {!!formData.parts.length &&
                                    <>
                                        <Typography variant='body1' style={{marginTop:'2em'}}>Add Serial Numbers & Warranty Void Labels</Typography>
                                        {_.filter(order.files, {co_file_type: "Engineer Job Sheet"}).length > 0 &&
                                            <MuiLink component="div" variant="body2" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginTop:'1em'}} 
                                                onClick={() => {downloadS3File(_.last(_.filter(order.files, {co_file_type: "Engineer Job Sheet"})).file_url)}}>
                                                {_.last(_.filter(order.files, {co_file_type: "Engineer Job Sheet"})).co_file_filename }
                                            </MuiLink>
                                        }
                                        {_.map(formData.parts, i => 
                                            <>
                                                <Typography variant='body1' style={{marginTop:'2em'}}>{i.name} <br/><Typography variant='body1' style={{fontSize: '1em', color: colors.grey}}>Qty: {i.qty}</Typography></Typography> 
                                                {(!!i.autoSN &&
                                                    <FormControl fullWidth>
                                                        {i.error && <Typography variant='body2' style={{color: colors.red}}>{i.error}</Typography>}
                                                        {_.map(_.range(i.qty), j => 
                                                            <AutoCompleteSelect
                                                                options={_.map(i.availableNumbers, d => {
                                                                    return _.assign({
                                                                        value: d.value,
                                                                        label: d.label,
                                                                        disabled: i.sn.includes(d.value)
                                                                    });
                                                                })}
                                                                style={{marginTop: 5}}
                                                                name='serialNumber'
                                                                label={`Serial Number ${j + 1} *`}
                                                                onChange={handleSelectChangePart('serialNumber', i.id, j)}
                                                                value={i.sn[j]}
                                                                noClear
                                                            />
                                                        )}
                                                    </FormControl>
                                                ) || (
                                                    (
                                                        !!i.manualSN && 
                                                            <FormControl fullWidth>
                                                                {i.error && <Typography variant='body2' style={{color: colors.red}}>{i.error}</Typography>}
                                                                {formErrors?.['serialNumber'+i.id] && <Typography variant='body2' style={{color: colors.red}}>{formErrors?.['serialNumber'+i.id]}</Typography>}
                                                                {_.map(_.range(i.qty), j => 
                                                                    <TextField
                                                                        style={{marginTop: 5}}
                                                                        name='serialNumberManual'
                                                                        label={`Manual Serial Number ${j + 1} *`}
                                                                        onChange={handleSelectChangePart('serialNumberManual', i.id, j)}
                                                                        value={i.sn[j]}
                                                                        error={formErrors?.['serialNumber'+i.id+'_'+j] && true}
                                                                        helperText={formErrors?.['serialNumber'+i.id+'_'+j]} 
                                                                    />
                                                                )}
                                                            </FormControl>
                                                    ) || (
                                                        !!i.rWVL && 
                                                            <FormControl fullWidth style={{paddingTop: '0.5em'}}>
                                                                {i.error && <Typography variant='body2' style={{color: colors.red}}>{i.error}</Typography>}
                                                                {formErrors?.['warrantyVoid'+i.id] && <Typography variant='body2' style={{color: colors.red}}>{formErrors?.['warrantyVoid'+i.id]}</Typography>}
                                                                {_.map(_.range(i.qty), j => 
                                                                    <TextField
                                                                        style={{marginTop: 5}}
                                                                        name='WarrantyVoidLabels'
                                                                        label={`Warranty Void Labels ${j + 1}`}
                                                                        onChange={handleSelectChangePart('WarrantyVoidLabels', i.id, j)}
                                                                        value={i.wvl[j]}
                                                                        error={formErrors?.['warrantyVoid'+i.id+'_'+j] && true}
                                                                        helperText={formErrors?.['warrantyVoid'+i.id+'_'+j]} 
                                                                    />
                                                                )}
                                                            </FormControl>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </>
                                }
                            </>
                        }
                        <Grid item xs={12}>
                            <div className="buttonRow" style={{marginTop: '2em'}}>
                                {!noClose && <Button onClick={handleClose} variant="outlined" color="default">Close</Button>}
                                <Button onClick={handleSubmit} autoFocus variant="contained" color="primary" disabled={
                                    (!despatchRequired && ( !!_.filter(formData.parts, i => { return i.error }).length)) ||
                                    (courierId === 1 && ( !formData.consignments.length || !pfType))
                                }>Process</Button>
                            </div>
                        </Grid>
                    </>}
                </Grid>
            )}
        </>
    );
}

const styles = theme => ({ hover: {'&:hover': {cursor: 'pointer'}}});
export default withStyles(styles)(ProccessDispachDialog);