import * as React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import { colors } from 'Helpers/ColourHelper';

const useStyles = makeStyles(theme => ({
        root: props => ({
            backgroundColor: props.colorSecondary,
            "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: props.colorPrimary,
            },
        }),
        RGO: props => ({
            backgroundColor: colors.red,
            "& .MuiLinearProgress-bar": {
                backgroundColor: colors.green,
            },
            "& .MuiLinearProgress-bar2Buffer": {
                backgroundColor: colors.orange,
            },
            "& .MuiLinearProgress-dashedColorPrimary": {
                backgroundImage: `radial-gradient(${colors.red} 100%, ${colors.red} 100%)`
            }
        }),
    }));

function ProgressBar(props){

    const {progress, max, showProgressLabel, progressType, bufferValue, variant, colorPack, allowOver} = props;

    const classes = useStyles(props)

    let value = parseFloat((progress / max) * 100);
    if (!allowOver) { if(value > 100) value = 100; }
    
    let valueLabel = null;
    if (showProgressLabel){
        switch (progressType){
            case 'percentage':  valueLabel = `${parseInt(value)}%`;          break;
            default:            valueLabel = `${progress}/${max}`; break;
        }
    }
    
    
    let className = null;

    switch (colorPack) {
        case 'RGO':
            className = classes.RGO;
            break;
        default:
            className = classes.root;
    }

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant={variant || 'determinate'} {...props} value={value > 100 ? 100 : value} valueBuffer={value + bufferValue} className={className} />
            </Box>
            {showProgressLabel && 
                <Box minWidth={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{valueLabel}</Typography>
                </Box>
            }
        </Box>
    );
}

export default  ProgressBar;
