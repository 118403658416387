import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    textBox: {
        marginTop: '8px',
    },
});

const DisplayTextArea = withStyles({
    root: {
        "& .MuiInputBase-root.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.87)"
        },
        "& .MuiFormLabel-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.87)"
        }
      }
})(TextField)


class Textarea extends React.Component {

    render() {
        const { classes } = this.props;

        const Element = this.props.displayOnly ? DisplayTextArea : TextField;

        return (
            <FormControl margin={this.props.margin ? this.props.margin : 'normal'} fullWidth>
                <Element
                    id={this.props.id}
                    name={this.props.name}
                    label={this.props.label}
                    value={this.props.value}
                    rows={this.props.rows}
                    rowsMax={Infinity}
                    variant="outlined"
                    error={this.props.error && true}
                    helperText={this.props.error}
                    onChange={this.props.onChange}
                    onBlur={this.props.onBlur}
                    multiline
                    fullWidth
                    style={this.props.style}
                    InputLabelProps={this.props.InputLabelProps}
                    className={classes.textBox}
                    disabled={this.props.disabled || this.props.displayOnly}
                    inputProps={{
                        ...this.props.inputProps
                    }}
                    placeholder={this.props.placeholder}
                />
            </FormControl>
        );
    }
}

export default withStyles(styles)(Textarea);
