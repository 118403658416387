import React, { Component } from 'react';

import { ListItemText, ListItemSecondaryAction, Grid, List, ListItem, Typography, Divider, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AllIcon from 'Components/Common/Icons/AllIcon';

import icons from 'Helpers/IconHelper';

class PrintStockLabels extends Component {

    render() {
        const { downloadStockLabel } = this.props;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Print Stock Labels</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} >
                            <List>
                                <ListItem>
                                    <ListItemText primary="Part Label" secondary="90mm x 29mm" />
                                    <ListItemSecondaryAction>
                                        <AllIcon noMargin icon={icons.print} onClick={() => downloadStockLabel('partLabel', true)} />
                                        <AllIcon noMargin icon={icons.download} onClick={() => downloadStockLabel('partLabel', false)} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider component="li" />
                                <ListItem>
                                    <ListItemText primary="Long Label" secondary="143mm x 20mm" />
                                    <ListItemSecondaryAction>
                                        <AllIcon noMargin icon={icons.print} onClick={() => downloadStockLabel('longLabel', true)} />
                                        <AllIcon noMargin icon={icons.download} onClick={() => downloadStockLabel('longLabel', false)} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider component="li" />
                                <ListItem>
                                    <ListItemText primary="Box Label" secondary="71mm x 44mm" />
                                    <ListItemSecondaryAction>
                                        <AllIcon noMargin icon={icons.print} onClick={() => downloadStockLabel('boxLabel', true)} />
                                        <AllIcon noMargin icon={icons.download} onClick={() => downloadStockLabel('boxLabel', false)} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                    <Divider component="li" />
                                <ListItem>
                                    <ListItemText primary="A4 Label" secondary="A4" />
                                    <ListItemSecondaryAction>
                                        <AllIcon noMargin icon={icons.print} onClick={() => downloadStockLabel('a4Label', true)} />
                                        <AllIcon noMargin icon={icons.download} onClick={() => downloadStockLabel('a4Label', false)} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default PrintStockLabels;
