
import React                     from 'react';
import { Typography, TableCell } from '@material-ui/core/';
import {colors} from 'Helpers/ColourHelper';
import _ from 'lodash';

export default function ({data, field, hidePound, percentage, style, manualTotal, noMinus, showZero, forceFixed=0, inverse}) {
    const pound   = (hidePound || percentage) ? '' : '£';
    const fixed   = (hidePound || percentage) ? forceFixed : 2;
    const percent = percentage ? '%' : '';
    const minus   = noMinus ? '' : '-';
    const dash    = showZero ? `${pound}${parseFloat(0).toFixed(fixed)}${percent}` : '-';

    let out      = data.out[field];
    if (_.isNaN(out) || out == 'NaN') out = 0;
    
    let inv      = data.in[field];
    if (_.isNaN(inv) || inv == 'NaN') inv = 0;
    
    let total     = manualTotal ? data.tot[field] : inv - out;
    if (_.isNaN(total) || total == 'NaN') total = 0;

    if (inverse){
        inv   = -inv;
    }

    return (
        <TableCell align='center' style={{...style, color:'black'}}>
            {inv ? 
                <Typography variant="h6" style={{color:'black',fontSize: '20px'}}>
                    {`${pound}${hidePound ? parseFloat(inv).toFixed(fixed) : parseFloat(Math.abs(inv)).toFixed(fixed)}${percent}`}
                </Typography> : <Typography variant="h6" style={{color:'black'}}>{dash}</Typography>
            }
            {out ? 
                <Typography variant="h6" style={{color: colors.red, fontSize: '20px'}} >
                    {`${ out && fixed ? ( inverse ? '' : minus ) : ''}${pound}${ !hidePound ? parseFloat( Math.abs(out) ).toFixed(fixed) : parseFloat(out).toFixed(fixed)}${percent}`}
                </Typography> : <Typography variant="h6" style={{color:'black'}}>{dash}</Typography>
            }
            {total ? 
                <Typography variant="h6" style={{color: total < 0 && colors.red, fontSize: '20px'}} >
                    {`${ (inverse ? total > 0 : total < 0 ) && fixed ? minus : ''}${pound}${ !hidePound ? parseFloat( Math.abs(total)).toFixed(fixed) : parseFloat(total).toFixed(fixed)}${percent}`}
                </Typography> : <Typography variant="h6" style={{color:'black'}}>{dash}</Typography>
            }

        </TableCell>
    )
}