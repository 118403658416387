import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';

class StickyBox extends Component {
    render() {
        const { classes, children, style } = this.props;
        return (
            <div style={style} className={classes.sticky}>
                {children}
            </div>
        );
    }
}

const styles = theme => ({
    sticky: {
        position: '-webkit-sticky',
        position: 'sticky',
        top:      52,
        bottom:   20, 
        zIndex:   0,
    },
});

export default withStyles(styles)(StickyBox);
