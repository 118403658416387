import React from 'react';
import { connect } from 'react-redux';
import API from 'API';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';

import {
    Button, 
    TextField,
    DialogActions,
    IconButton,
    Tooltip,
    Grid,
    FormControl,
    Typography,
    Box,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Link,
    Checkbox,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@material-ui/core';

import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DynamicTableElement from 'Components/Common/Forms/FormBuilder/DynamicTableElement';
import InformationForm from 'Components/Common/Forms/FormBuilder/InformationForm';
import MasterElementDialog from 'Components/Common/Forms/FormBuilder/MasterElementDialog';
import { CLENAWARE_RED, CLENAWARE_ORANGE } from 'Constants'
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import AllIcon from 'Components/Common/Icons/AllIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import { toggleDialog } from 'Functions/MiscFunctions';
import TextArea     from 'Components/Common/Inputs/Textarea';
import { colors } from 'Helpers/ColourHelper';
import { withStyles } from '@material-ui/core/styles';
import IconHelper from 'Helpers/IconHelper';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = () => ({
    formData: {
        areaId: 0, // Used only for Master Element
        nm: '',
        description: '',
        info: [],
        elType: '',
        elConfig: {
            isRequired: 0,
            minLength: null,
            maxLength: null,
            min: null,
            max: null,
            placeholder: '',
            options: [],
            data: null,
            resultType: 'n/a',
        },
    },
    originalFormData: null,
    isLoading: true,
    formErrors: {},
    hasOptions: ['Dropdown', 'Checkbox', 'Radio'],
    infoOptionsPrePopulated: [],
    infoOptionsSelector: [],
    questionTextList: [],
    dialog: {
        informationForm: false,
        data: null,
        masterElementDialog: false,
    },
    keys: {
        informationForm: uuidv4(),
    },
    reusableElForms: [],
    reusableElRefs: [],
    masterElement: false,
    masterElements: [],
    masterElId: 0,
    areasList: [],
})

const styles = () => ({
    tooltipCustomWidth: {
        maxWidth: 500,
      }
});

class ElementConfiguration extends React.Component {

    constructor(props) {
        super(props);
    }
    render(){
        const { 
            level, infoOptionsPrePopulated, infoOptionsSelector, formErrors, nestedIdx=null, optionIdx=null,
            handleChange, handleAddRow, handleRemoveRow, handleRowEdit, handleSelectChange, toggleDialog,
            openInformationDialog, handleResultTypeDataChange, lists, formData, keys, classes, masterElement, openMasterElement, readOnly
        } = this.props;
        
        const elementIcon = (type) => {
            switch(type){
                case 'Dropdown':
                    return "caret-square-down"
                case 'Checkbox':
                    return "square"
                case 'Radio':
                    return "circle"
            }
        }

        const PlaceholderElement = formData?.elType === 'Text Area' ? TextArea : TextField;

        let selectedReusableEl = lists?.reusableElRefs ? _.find(lists?.reusableElRefs, (el) => el.value == formData?.elConfig?.resultTypeData?.ref) : null;

        const elementDefinitions = {
            'Text Field': 'Single line text input',
            'Number Input': 'Single line number input',
            'Text Area': 'Multi line text',
            'Dropdown': 'Dropdown list - select one option',
            'Checkbox': 'Checkbox list - select multiple options',
            'Radio': 'Radio list - select one option',
            'File Upload': 'File upload',
            'Signature': 'Signature input with name & date',
            'Date': 'Date picker',
            'Time': 'Time picker',
            'Date / Time': 'Date / Time picker',
            'Job Time': 'Select start and end time for job & it will calculate total time',
            'Parts Table': 'First column is a Parts dropdown, other cells are text inputs. Rows are dynamic.',
            'Static Table': 'All cells are text inputs. Rows and columns are fixed.',
            'Dynamic Table': 'All cells are text inputs. Rows are dynamic.',
            'Information': 'Read only information pre-populated onto the form',
            'Information Selector': 'Dropdown list that pulls information from different areas of the system - use the selector to choose which dropdown list to use',
            'General Information': 'Select Part Images / Category Images / Documents / Part Drawings to be displayed on the form'
        };

        return (
            <>
            <Grid container item spacing={3}>
                {(level == 1 && !readOnly) && (
                <>
                {masterElement ? (
                    <Grid item xs={12}>
                        <AutoCompleteSelect 
                            options={lists?.areasList} 
                            label='Area *'
                            fullWidth
                            value={formData.areaId}
                            noClear
                            onChange={(e) => handleChange(e.value, 'areaId', optionIdx, nestedIdx)}
                            error={formErrors && formErrors['areaId'] && true}
                            errorText={formErrors && formErrors['areaId']}
                        />
                    </Grid>
                )
                :
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            Create a custom element or pre-populate with a {' '}
                            <Link component="button" variant="caption" className='blueLink' onClick={() => openMasterElement()}>
                                Master Element
                            </Link>
                        </Typography>
                    </Grid>
                }
                </>
                )}

                <Grid item xs={12}>
                    <Typography variant="subtitle2" paragraph>
                        <Tooltip 
                            title={
                                <Grid container spacing={1}>
                                    {Object.entries(elementDefinitions).map(([label, description]) => (
                                        <>
                                        <Grid item xs={3}>
                                            <FAIcon icon={IconHelper[label]} color='white' size={12}  />
                                            {label}
                                        </Grid>
                                        <Grid item xs={9}>
                                            {description}
                                        </Grid>
                                        </>
                                    ))}
                                </Grid>
                            } 
                            classes={{ tooltip: classes.tooltipCustomWidth }}
                        >
                            <span>
                            <FALightIcon icon='circle-info' fixedHeight button size={15} />
                            </span>
                        </Tooltip>
                        <b>Element Type</b>
                    </Typography>

                    <FormControl margin="none" fullWidth>
                        <AutoCompleteSelect
                            options={[
                                {label: 'Text Field', value: 'Text Field', icon: 'text-size' },
                                {label: 'Number Input', value: 'Number Input', icon: 'input-numeric' },
                                {label: 'Text Area', value: 'Text Area', icon: 'input-text' },
                                {label: 'Dropdown', value: 'Dropdown', icon: 'caret-square-down' },
                                {label: 'Checkbox', value: 'Checkbox', icon: 'square' },
                                {label: 'Radio', value: 'Radio', icon: 'circle' },
                                {label: 'File Upload', value: 'File Upload', icon: 'file-arrow-up' },
                                {label: 'Signature', value: 'Signature', icon: 'signature' },
                                {label: 'Date', value: 'Date', icon: 'calendar-days' },
                                {label: 'Time', value: 'Time', icon: 'clock' },
                                {label: 'Date / Time', value: 'Date / Time', icon: 'calendar-clock' },
                                {label: 'Job Time', value: 'Job Time', icon: 'watch' },
                                {label: 'Parts Table', value: 'Parts Table', icon: 'wrench' },
                                {label: 'Static Table', value: 'Static Table', icon: 'table' },
                                {label: 'Dynamic Table', value: 'Dynamic Table', icon: 'table' },
                                {label: 'Information Pre-populated', value: 'Information', icon: 'square-info' },
                                {label: 'Information Selector', value: 'Information Selector', icon: 'circle-info' },
                                {label: 'General Information', value: 'General Information', icon: 'memo-circle-info' }
                            ]}
                            value={formData?.elType || ''}
                            onChange={(e) => handleChange(e.value, 'elType', optionIdx, nestedIdx)}
                            error={formErrors && formErrors['elType'] && true}
                            errorText={formErrors && formErrors['elType']}
                            noClear
                            margin="none"
                            noDefaultSort
                            disabled={readOnly}
                            formatOptionLabel={({label, icon}) => (
                                <Box>
                                    <Grid container alignItems='center'>
                                        <Grid item>
                                            <AllIcon icon={icon} size="small" />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" >
                                                {label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        />
                        {formData?.elType && (
                            <>
                                <Typography variant="caption" color={colors.subText} style={{color: '#bbb'}}>
                                    <i>{elementDefinitions.hasOwnProperty(formData?.elType) ? elementDefinitions[formData?.elType] : null}</i>
                                </Typography>
                            </>
                        )}
                    </FormControl>
                </Grid>
                {formData?.elType && (!['Information'].includes(formData?.elType)) && (
                    <>
                    <Grid item lg={6} xs={12}>
                        <TextField
                            name="nm"
                            label="Text *"
                            margin="none" 
                            autoComplete="off"
                            error={formErrors && formErrors['nm'] && true}
                            helperText={formErrors && formErrors['nm']}
                            value={formData?.nm || ''}
                            fullWidth
                            onChange={(e) => handleChange(e.target.value, 'nm', optionIdx, nestedIdx)}
                            disabled={readOnly}
                        />
                        {formData?.info?.length > 0 && (
                            <IconButton onClick={() => openInformationDialog('Info Icon', formData?.info, optionIdx, nestedIdx, true)}>
                                <FALightIcon icon='circle-info' noMargin fixedHeight button size={15} />
                            </IconButton>
                        )}
                        {!masterElement && (
                            <Link component="button" variant="caption" className='blueLink' onClick={() => openInformationDialog('Info Icon', formData?.info, optionIdx, nestedIdx)}>
                                {formData?.info?.length > 0 ? 'Update' : 'Add'} Information
                            </Link>
                        )}
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <TextField
                            name="description"
                            label="Description"
                            margin="none" 
                            autoComplete="off"
                            error={formErrors && formErrors['description'] && true}
                            helperText={formErrors && formErrors['description']}
                            value={formData?.description || ''}
                            fullWidth
                            onChange={(e) => handleChange(e.target.value, 'description', optionIdx, nestedIdx)}
                            disabled={readOnly}
                        />
                    </Grid>
                    </>
                )}

                {/* Element Configuration */}
                <Grid item xs={12}>
                    {(['Dropdown', 'Checkbox', 'Radio', 'Information', 'Parts Table', 'Static Table', 'Dynamic Table', 'Text Field', 'Text Area'].includes(formData?.elType)) && (
                        <>
                        <Typography variant="subtitle2" paragraph>
                            <b>Element Configuration</b>
                        </Typography>
                        {formErrors['elConfig'] && (
                            <Typography variant="caption" color="error" paragraph>
                                {formErrors['elConfig']}
                            </Typography>
                        )}
                        </>
                    )}

                    {/* Element Configuration - Placeholder */}
                    {(formData?.elType === 'Text Area' || formData?.elType === 'Text Field') && (
                        <>
                            <PlaceholderElement
                                label="Placeholder Text"
                                margin="none"
                                variant="outlined"
                                rows={1}
                                fullWidth
                                value={formData?.elConfig?.placeholder}
                                onChange={(e) => handleChange(e.target.value, 'placeholder', optionIdx, nestedIdx, true)}
                                disabled={readOnly}
                            /> 
                        </>
                    )}
                    {/* Element Configuration - Options */}
                    {(formData?.elType === 'Dropdown' || formData?.elType === 'Checkbox' || formData?.elType === 'Radio') && (
                        <>
                        <Grid container item spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="caption" paragraph>
                                    {formData?.elType} Options
                                </Typography>
                                {formData?.elConfig?.options?.map((option, idx) => {
                                    return (
                                        <>
                                        <Grid container item spacing={2} key={idx}>
                                            <Grid item xs={1} align='center' style={{marginTop: '16px'}}>
                                                <FALightIcon icon={elementIcon(formData?.elType)} color='#a1a1aa' size={15} noMargin />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <TextField
                                                    margin="none"
                                                    name="optionText"
                                                    autoComplete="off"
                                                    value={option?.value}
                                                    fullWidth
                                                    onChange={e => handleRowEdit(optionIdx, nestedIdx, idx, e?.target?.value ?? '')}
                                                    error={formErrors && formErrors['optionText|'+idx] && true}
                                                    helperText={formErrors && formErrors['optionText|'+idx]}
                                                    disabled={readOnly}
                                                />
                                            </Grid>
                                            <Grid item xs={1} align='center'>
                                                {!readOnly && (
                                                    <>
                                                    {formData?.elConfig?.options && formData?.elConfig?.options?.length > 1 &&
                                                        <Tooltip title="Remove">
                                                            <IconButton onClick={() => handleRemoveRow(optionIdx, nestedIdx, idx)}>
                                                                <FALightIcon button noMargin icon="times" size={15} fixedHeight/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    </>
                                                )}
                                            </Grid>
                                            <Grid item xs={1} align='center'>
                                                {(level == 1 && !readOnly) && (
                                                    <Tooltip title="Add Nested Element">
                                                        <IconButton onClick={() => this.props.addNestedElement(idx)} >
                                                            <FALightIcon button noMargin icon="circle-plus" size={15} fixedHeight/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Grid>

                                            <Grid item xs={12}>
                                                {(option?.nestedElements && option?.nestedElements.length > 0 && level == 1) && (
                                                    <ExpansionPanel style={{marginLeft: '8.5%', marginTop: '1%',}}>
                                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                            Nested Elements ({option?.nestedElements.length})
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails>
                                                            <Grid container item spacing={3}>
                                                                {option?.nestedElements.map((nestedElement, nIdx) => {
                                                                    return (
                                                                        <>
                                                                            <Grid item xs={12}>
                                                                                <Box>
                                                                                    <Typography variant="caption">
                                                                                        NESTED ELEMENT {nIdx+1}

                                                                                        <Tooltip title="Remove Nested Element">
                                                                                            <IconButton onClick={() => this.props.removeNestedElement(idx, nIdx)}>
                                                                                                <FALightIcon button noMargin icon='trash-alt' size={15} fixedHeight color={CLENAWARE_RED}/>
                                                                                            </IconButton>
                                                                                        </Tooltip>

                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Box style={{backgroundColor: '#fafafa', padding: '12px'}}>
                                                                                    <ElementConfiguration 
                                                                                        level={2} 
                                                                                        infoOptionsPrePopulated={infoOptionsPrePopulated}
                                                                                        infoOptionsSelector={infoOptionsSelector}
                                                                                        formData={nestedElement?.formData}
                                                                                        formErrors={formErrors}
                                                                                        nestedIdx={nIdx}
                                                                                        optionIdx={idx}
                                                                                        handleChange={handleChange}
                                                                                        handleAddRow={handleAddRow}
                                                                                        handleRemoveRow={handleRemoveRow}
                                                                                        handleRowEdit={handleRowEdit}
                                                                                        handleSelectChange={handleSelectChange}
                                                                                        toggleDialog={toggleDialog}
                                                                                        openInformationDialog={openInformationDialog}
                                                                                        keys={this.props.keys}
                                                                                        classes={classes}
                                                                                        masterElement={masterElement}
                                                                                        handleResultTypeDataChange={handleResultTypeDataChange}
                                                                                        lists={lists}
                                                                                        readOnly={readOnly}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                        </>
                                                                    )
                                                                })}
                                                            </Grid>
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                )}

                                            </Grid>
                                        </Grid>
                                        </>
                                    )
                                })}
                                <Grid container item spacing={3}>
                                    <Grid item xs={10}>
                                        {!readOnly && (
                                            <Button
                                                onClick={() => handleAddRow(optionIdx, nestedIdx)}
                                                variant="text"
                                                size='small'
                                            >
                                                <FALightIcon icon='plus' button size={15} />
                                                Add Option
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        </>
                    )}
                    {/* Element Configuration - Information */}
                    {(formData?.elType === 'Information') && (
                        <>
                        <Grid container item spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Text"
                                    margin="none" 
                                    autoComplete="off"
                                    value={formData?.elConfig?.data?.text || ''}
                                    fullWidth
                                    onChange={(e) => handleChange({ ...formData.elConfig.data, text: e.target.value}, 'data', optionIdx, nestedIdx, true)}
                                    disabled={readOnly}
                                />
                                <Typography variant="caption" paragraph style={{color: '#888'}}>
                                    <i>Enter text for a custom label. Defaults to Information Data name </i>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl margin="none" fullWidth>
                                    <AutoCompleteSelect
                                        options={infoOptionsPrePopulated}
                                        label='Information Data *'
                                        value={formData?.elConfig?.data?.value || ''}
                                        onChange={(o) => handleSelectChange(o, 'data', optionIdx, nestedIdx, true)}
                                        noClear
                                        margin="none"
                                        noDefaultSort
                                        disabled={readOnly}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        </>
                    )}
                    {/* Element Configuration - Information Selector */}
                    {(formData?.elType === 'Information Selector') && (
                        <>
                        <Grid container item spacing={3}>
                            <Grid item xs={6}>
                                <FormControl margin="none" fullWidth>
                                    <AutoCompleteSelect
                                        options={infoOptionsSelector}
                                        label='Selector *'
                                        value={formData?.elConfig?.data?.value || ''}
                                        onChange={(o) => handleSelectChange(o, 'data', optionIdx, nestedIdx, true)}
                                        noClear
                                        margin="none"
                                        noDefaultSort
                                        disabled={readOnly}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        </>
                    )}  
                    {/* Element Configuration - Table */}
                    {['Parts Table', 'Static Table', 'Dynamic Table'].includes(formData?.elType) && (
                        <DynamicTableElement 
                            handleChange={handleChange} 
                            data={formData?.elConfig?.data} 
                            optionIdx={optionIdx}
                            nestedIdx={nestedIdx}
                            withParts={formData?.elType === 'Parts Table' ? true : false} 
                            type={formData?.elType === 'Static Table'? "static" : "dynamic"}
                            readOnly={readOnly}
                        />
                    )}
                    {/* Element Configuration - General Information */}
                    {(formData?.elType === 'General Information') && (
                        <>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography variant="subtitle2" paragraph>
                                <b>General Information</b>
                            </Typography>
                            {!readOnly && (
                                <Link component="button" variant="caption" className='blueLink' onClick={() => openInformationDialog('General Information', formData?.elConfig?.data, optionIdx, nestedIdx)}>
                                    {formData?.elConfig?.data?.length > 0 ? 'UPDATE': 'ADD'} GENERAL INFORMATION
                                </Link>
                            )}
                        </div>
                        {formData?.elConfig?.data?.length > 0 && (
                            <Box style={{backgroundColor: '#fafafa', padding: '15px'}}>
                                <InformationForm 
                                    key={keys?.informationForm}
                                    data={formData?.elConfig?.data}
                                    optionIdx={optionIdx}
                                    nestedIdx={nestedIdx}
                                    readOnly={true}
                                    type={'General Information'}
                                    hideClose={true}
                                />
                            </Box>
                        )}
                        </>
                    )}
                </Grid>
                
                {!masterElement && (
                    <>
                    {/* Element Validation */}
                    <Grid item xs={12}>
                        {formData?.elType && (formData?.elType != 'General Information' && formData?.elType != 'Information') && (
                            <>
                                <Typography variant="subtitle2" paragraph>
                                    <b>Element Validation</b>
                                </Typography>
                                <Box style={{backgroundColor: '#fafafa', padding: '15px'}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="elementRequired"
                                                color="primary"
                                                checked={formData.elConfig.isRequired}
                                                onChange={(e) => handleChange(formData?.elConfig?.isRequired ? 0 : 1, 'isRequired', optionIdx, nestedIdx, true)}
                                                disabled={readOnly}
                                            />
                                        }
                                        label="Is Required"
                                        labelPlacement="end"
                                        style={{margin: '0 10px'}}
                                    />
                                </Box>
                            </>
                        )}
                    </Grid>

                    {/* Element Result */}
                    <Grid item xs={12}>
                        {(formData?.elType && (formData?.elType != 'General Information' && formData?.elType != 'Information')) && (
                            <>
                            <Typography variant="subtitle2" paragraph>
                                <b>Element Result</b> 
                                {formData?.reusableIn?.length > 0 && (
                                    <>
                                    {' '}
                                    <Tooltip 
                                        title={
                                            <>
                                            <Typography variant="subtitle2">
                                                <b>Unable to Edit</b> 
                                            </Typography>
                                            <Typography variant="caption" paragraph>
                                                This elements result is being used in the following forms:
                                            </Typography>
                                            {_.map(formData?.reusableIn, (el) => {
                                                return (
                                                    <Typography variant="caption" paragraph>
                                                        - {el?.nm}
                                                    </Typography>
                                                )
                                            })}
                                            <Typography variant="caption" paragraph>
                                                <i>Please remove the link on other forms to be able to edit it here</i>
                                            </Typography>
                                            
                                            </>
                                        }
                                    >
                                        <span><AllIcon heavy button noMargin icon="triangle-exclamation" size={15} fixedHeight style={{color: CLENAWARE_ORANGE}} /></span>
                                    </Tooltip>
                                    </>
                                )}
                                
                            </Typography>
                            <Box style={{backgroundColor: '#fafafa', padding: '15px'}}>

                                <RadioGroup
                                    value={formData.elConfig.resultType}
                                    onChange={(e) => handleChange(e.target?.value, 'resultType', optionIdx, nestedIdx, true)}
                                    row
                                >
                                    <FormControlLabel
                                        value="n/a"
                                        control={<Radio color="primary" />}
                                        label="N/A"
                                        labelPlacement="end"
                                        disabled={formData?.reusableIn?.length > 0 || readOnly}
                                    />
                                    <FormControlLabel
                                        value="pre-populated"
                                        control={<Radio color="primary" />}
                                        label={<>Pre-populate Result <small>*</small></>}
                                        labelPlacement="end"
                                        disabled={formData?.reusableIn?.length > 0 || readOnly}
                                        style={{color: 
                                            (lists?.reusableElForms?.length > 0 && 
                                            _.some(lists?.reusableElForms, el => {
                                                return _.filter(el?.elements, el => el?.elType === formData?.elType).length > 0;
                                            })) 
                                        ? 'green' : undefined}}
                                    />
                                    <FormControlLabel
                                        value="reusable"
                                        control={<Radio color="primary" />}
                                        label="Reusable Result"
                                        labelPlacement="end"
                                        disabled={formData?.reusableIn?.length > 0 || readOnly}
                                    />
                                </RadioGroup>

                                <Typography variant="caption" paragraph>
                                    <small><i>* Highlighted green: reusable results are available</i><br /></small>
                                    {formData.elConfig.resultType == 'pre-populated' ? 
                                        <i>The result will be pre-populated with data from another form</i>
                                    :
                                        (formData.elConfig.resultType == 'reusable' ?
                                            <i>The result from this element will be reusable on other forms</i>
                                            :
                                            <i></i>
                                        )
                                    }
                                </Typography>
                                {formData.elConfig.resultType == 'pre-populated' && (
                                    <Grid container item spacing={3}>
                                        <Grid item xs={6}>
                                            <FormControl margin="none" fullWidth>
                                                <AutoCompleteSelect
                                                    options={lists?.reusableElForms}
                                                    label='Form *'
                                                    value={formData?.elConfig?.resultTypeData?.form?.value || ''}
                                                    onChange={(o) => handleResultTypeDataChange(o, 'form', optionIdx, nestedIdx)}
                                                    margin="none"
                                                    noDefaultSort
                                                    disabled={readOnly}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl margin="none" fullWidth>
                                                <AutoCompleteSelect
                                                    options={
                                                        // Filter the lists?.reusableElRefs to only show the elements that match the element type
                                                        lists?.reusableElRefs ?
                                                        _.filter(lists?.reusableElRefs, (el) => el.elType == formData?.elType)
                                                        :
                                                        []
                                                    }
                                                    label='Element'
                                                    value={formData?.elConfig?.resultTypeData?.ref || ''}
                                                    onChange={(o) => handleResultTypeDataChange(o?.value, 'ref', optionIdx, nestedIdx)}
                                                    margin="none"
                                                    noDefaultSort
                                                    disabled={readOnly}
                                                />
                                            </FormControl>
                                        </Grid>

                                        {selectedReusableEl && selectedReusableEl?.elType != formData?.elType && (
                                            <Grid item xs={12}>
                                                <Typography variant="caption" paragraph style={{color: CLENAWARE_RED}}>
                                                    <AllIcon icon='triangle-exclamation' size={12} fixedHeight color={CLENAWARE_RED} /><i>Warning: Your Element Type <b>({formData?.elType})</b> does not match the element you want to pre-populate from <b>({selectedReusableEl?.elType})</b></i>
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                                {formData.elConfig.resultType == 'reusable' && (
                                    <Grid container item spacing={3}>
                                        <Grid item xs={6}>
                                            <TextField
                                                margin="none"
                                                autoComplete="off"
                                                value={formData?.elConfig?.resultTypeData?.ref || ''}
                                                fullWidth
                                                onChange={(e) => handleResultTypeDataChange(e?.target?.value ?? '', 'ref', optionIdx, nestedIdx)}
                                                disabled={formData?.reusableIn?.length > 0 || readOnly}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                
                            </Box>
                            </>
                        )}
                    </Grid>
                    </>
                )}
            </Grid>
            </>
        )
    }
}

class ElementForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState();
        this.toggleDialog = toggleDialog.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData() {
        let { data } = this.props;
        this.setState({
            ...this.state,
            formData:           data.formData !== null ? data.formData : initialState()?.formData,
            questionTextList:   data?.questionTextList, // Used to validate duplicate question names
            originalFormData:   data.formData !== null ? _.cloneDeep(data.formData) : null,
            configFormId:       data?.configFormId,
            masterElId:         data?.mElId || 0,
            masterElement:      this.props?.masterElement || false,
            areasList:          this.props?.areasList,
            readOnly:           this.props?.readOnly || false,
        }, () => {
            this.getElementInformationOptions();
            this.getReusableElements();
            if(this.state.masterElId){
                this.getMasterElements(this.state.masterElId);
            }
        });
    }

    getMasterElements = (masterElId) => {

        /*
        * masterElId: Update Master Element
        * Get a list of master elements to use for pre-populating
        */

        API.get(`/forms/configuration/masterElements/${masterElId}`)
        .then((result) => {

            this.setState({
                ...this.state,
                formData: result.data,
            });
        })
    }

    getElementInformationOptions = () => {        
        API.get('/forms/configuration/elements/informationOptions')
        .then((result) => {
            let infoOptions = _.map(result.data, (el) => {
                return _.assign({
                    value: el.val,
                    label: el.nm,
                    type: el.type
                });
            });
            this.setState({
                infoOptionsPrePopulated: _.filter(infoOptions, (el) => el.type == 'Pre-populated'),
                infoOptionsSelector: _.filter(infoOptions, (el) => el.type == 'Selector'),
                isLoading: false
            });
        })
    }

    getReusableElements = () => {
        API.get('/forms/configuration/elements/reusable')
        .then((result) => {
            let reusableElForms = [];
            _.map(result.data, (el) => {
                if(el.id != this.state.configFormId){ // Don't include the current form
                    reusableElForms.push({
                        value: el?.originalId,
                        label: el.nm,
                        elements: el.elmnts ? _.map(el.elmnts, (el) => {
                            return {
                                value: el?.ref,
                                label: el?.ref,
                                elType: el?.elType,
                            }
                        }) : []
                    });
                }
            });

            this.setState({
                reusableElForms
            }, () => {
                let reusableForm = this.state.formData?.elConfig?.resultTypeData?.form?.value;
                if(reusableForm){

                    let reusableElRefs = _.get(reusableElForms.find((el) => el.value == this.state.formData?.elConfig?.resultTypeData?.form?.value), 'elements');
                    this.setState({
                        reusableElRefs: reusableElRefs ? reusableElRefs : []
                    });
                }
            });
        })
    }

    handleSelectChange = (selectedOption, fieldName, optionIdx, nestedIdx, forElConfig = false) => {

        let formData        = {...this.state.formData},
            nestedElements  = optionIdx != null ? [...formData.elConfig.options[optionIdx].nestedElements] : null;

        if(optionIdx != null && nestedIdx != null){
            if(forElConfig){
                nestedElements[nestedIdx] = {
                    ...nestedElements[nestedIdx],
                    formData: {
                        ...nestedElements[nestedIdx].formData,
                        elConfig: {
                            ...nestedElements[nestedIdx].formData.elConfig,
                            [fieldName]: {
                                ...nestedElements[nestedIdx].formData.elConfig[fieldName],
                                name: selectedOption && selectedOption.label,
                                value: selectedOption && selectedOption.value,
                            }
                        }
                    }
                }
                formData.elConfig.options[optionIdx] = {
                    ...formData.elConfig.options[optionIdx],
                    nestedElements
                }
            }

        }
        else {
            if(forElConfig){
                formData = {
                    ...formData,
                    elConfig: {
                        ...formData.elConfig,
                        [fieldName]: {
                            ...formData.elConfig[fieldName],
                            name: selectedOption && selectedOption.label,
                            value: selectedOption && selectedOption.value,
                        }
                    }
                }
            }
            else {
                formData = {
                    ...formData,
                    [fieldName]: {
                        ...formData[fieldName],
                        name: selectedOption && selectedOption.label,
                        value: selectedOption && selectedOption.value,
                    }
                }
            }
        }

        this.setState({
            formData
        });
    };

    close = () => {

        // if form data is not null, then it means that the form is in edit mode, so we need to revert the changes
        if(!this.state.masterElement && this.props.data.formData !== null){
            this.props.addUpdateElement(this.state.originalFormData, this.props.data?.pIdx, this.props.data?.gIdx, this.props.data?.eIdx)
        }
        else {
            this.props.toggleDialog('elementForm', true)
        }
    }

    submit = () => {

        /*
        * Validation
        */
        let formErrors                      = {},
            { formData, questionTextList }  = this.state;

        if(formData?.elType.trim() === ''){
            formErrors['elType'] = 'Element Type cannot be empty'
        }
        /* Options Validation */
        else if(this.state.hasOptions.includes(formData?.elType)){
            _.map(formData?.elConfig?.options, (el, idx) => {
                if(el.value.trim() === ''){
                    formErrors['optionText|'+idx] = 'Option cannot be empty'
                }
            })
        }
        /* Parts Table Validation */
        else if(formData?.elType == 'Parts Table' || formData?.elType == 'Custom Table'|| formData?.elType == 'Dynamic Table'){
            // Check if there is a array with empty values
            let hasEmpty = false;
            _.map(formData?.elConfig?.data, (el, idx) => {
                _.map(el, (heading, idx) => {
                    if(heading.name.trim() == ''){
                        hasEmpty = true;
                    }
                })
            })
            if(hasEmpty){
                formErrors['elConfig'] = 'Please complete the configuration for the table';
            }

        }
        else {
            // Validate duplicate question text - is formData.nm in questionTextList
            if(_.includes(questionTextList, formData?.nm)){
                formErrors['nm'] = 'Question text already exists'
            }
        }
        /*
        * Validation: Master Element
        */
        if(this.state.masterElement){
            if(!formData?.areaId){
                formErrors['areaId'] = 'Area cannot be empty'
            }
        }

        this.setState({
            ...this.state,
            formErrors
        })
        
        if(_.isEmpty(formErrors)){
            // Submit
            if(this.state.masterElement){
                this.props.addUpdateElement(this.state.formData, this.state.masterElId)
            } else {
                this.props.addUpdateElement(this.state.formData, this.props.data?.pIdx, this.props.data?.gIdx, this.props.data?.eIdx)
            }
        }
    }

    handleRowEdit = (optionIdx, nestedIdx, idx, value) => {

        let formData    = {...this.state.formData},
        nestedElements  = optionIdx != null ? [...formData.elConfig.options[optionIdx].nestedElements] : null;

        if(optionIdx != null && nestedIdx != null){
            nestedElements[nestedIdx].formData.elConfig.options[idx] = {
                ...nestedElements[nestedIdx].formData.elConfig.options[idx],
                value,
                label: value
            };
            formData.elConfig.options[optionIdx].nestedElements = nestedElements;
        }
        else {
            formData.elConfig.options[idx] = {
                ...formData.elConfig.options[idx],
                value,
                label: value
            };
        }

        this.setState({
            formData
        });
    }

    handleRemoveRow = (optionIdx, nestedIdx, idx) => {

        let formData    = {...this.state.formData},
        nestedElements  = optionIdx != null ? [...formData.elConfig.options[optionIdx].nestedElements] : null;

        if(optionIdx != null && nestedIdx != null){
            nestedElements[nestedIdx].formData.elConfig.options.splice(idx, 1);
            formData.elConfig.options[optionIdx].nestedElements = nestedElements;
        }
        else {
            formData.elConfig.options.splice(idx, 1);
        }

        this.setState({
            formData
        });
    }

    addNestedElement = (idx) => {

        let elConfig        = this.state.formData?.elConfig,
            nestedElements  = [...elConfig.options[idx].nestedElements];

        nestedElements.push({ 
            formData: {
                ...initialState().formData,
                elConfig: {
                    ...initialState().formData.elConfig,
                    options: [{value: '', label: ''}]
                }
            }
        });

        elConfig.options[idx] = {
            ...elConfig.options[idx],
            nestedElements
        }

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                elConfig
            }
        })
    }

    removeNestedElement = (idx, nIdx) => {

        let elConfig        = this.state.formData?.elConfig,
            nestedElements  = [...elConfig.options[idx].nestedElements];
        
        nestedElements.splice(nIdx, 1);

        elConfig.options[idx] = {
            ...elConfig.options[idx],
            nestedElements
        }

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                elConfig
            }
        })
    }
    
    handleAddRow = (optionIdx, nestedIdx) => {

        let formData    = {...this.state.formData},
        nestedElements  = optionIdx != null ? [...formData.elConfig.options[optionIdx].nestedElements] : null;

        if(optionIdx != null && nestedIdx != null){
            nestedElements[nestedIdx] = {
                ...nestedElements[nestedIdx],
                formData: {
                    ...nestedElements[nestedIdx].formData,
                    elConfig: {
                        ...nestedElements[nestedIdx].formData.elConfig,
                        options: [
                            ...nestedElements[nestedIdx].formData.elConfig.options,
                            {
                                value: '',
                                label: ''
                            }
                        ]
                    }
                }
            }
            formData.elConfig.options[optionIdx] = {
                ...formData.elConfig.options[optionIdx],
                nestedElements
            }
        }
        else {
            formData = {
                ...formData,
                elConfig: {
                    ...formData.elConfig,
                    options: [
                        ...formData.elConfig.options,
                        {
                            value: '',
                            label: '',
                            nestedElements: []
                        }
                    ]
                }
            }
        }

        this.setState({
            formData
        });
    }

    handleChange = (value, fieldName, optionIdx, nestedIdx, forElConfig = false) => {

        let formData        = {...this.state.formData},
            nestedElements  = optionIdx != null ? [...formData.elConfig.options[optionIdx].nestedElements] : null; 

        if(optionIdx != null && nestedIdx != null){
            if(forElConfig){
                nestedElements[nestedIdx] = {
                    ...nestedElements[nestedIdx],
                    formData: {
                        ...nestedElements[nestedIdx].formData,
                        elConfig: {
                            ...nestedElements[nestedIdx].formData.elConfig,
                            [fieldName]: value
                        }
                    }
                }
             } 
             else {
                 nestedElements[nestedIdx] = {
                     ...nestedElements[nestedIdx],
                    formData: {
                        ...nestedElements[nestedIdx].formData,
                        [fieldName]: value
                    }
                }
            }
                
            formData.elConfig.options[optionIdx] = {
                ...formData.elConfig.options[optionIdx],
                nestedElements
            }
        }
        else {
            if(forElConfig){
                formData = {
                    ...formData,
                    elConfig: {
                        ...formData.elConfig,
                        [fieldName]: value
                    }
                }
            } else {
                formData = {
                    ...formData,
                    [fieldName]: value
                }
            }
        }

        // Reset form data if element type is changed
        if(fieldName == 'elType'){
            if(optionIdx != null && nestedIdx != null){
                nestedElements[nestedIdx] = {
                    ...nestedElements[nestedIdx],
                   formData: {
                       ...nestedElements[nestedIdx].formData,
                       nm: initialState().formData.nm,
                        description: initialState().formData.description,
                        elConfig: initialState().formData.elConfig // Make sure elConfig is reset to initial state
                   }
               }
               formData.elConfig.options[optionIdx] = {
                    ...formData.elConfig.options[optionIdx],
                    nestedElements
                }
            }
            else {
                formData = {
                    ...formData,
                    nm: initialState().formData.nm,
                    description: initialState().formData.description,
                    elConfig: initialState().formData.elConfig // Make sure elConfig is reset to initial state
                }
            }
        }
        // Reset resultTypeData if resultType is changed
        else if(fieldName == 'resultType'){

            if(optionIdx != null && nestedIdx != null){
                nestedElements[nestedIdx] = {
                    ...nestedElements[nestedIdx],
                    formData: {
                        ...nestedElements[nestedIdx].formData,
                        elConfig: {
                            ...nestedElements[nestedIdx].formData.elConfig,
                            resultTypeData: initialState().formData.elConfig.resultTypeData
                        }
                    }
                }
                formData.elConfig.options[optionIdx] = {
                    ...formData.elConfig.options[optionIdx],
                    nestedElements
                }
            }
            else {
                formData = {
                    ...formData,
                    elConfig: {
                        ...formData.elConfig,
                        resultTypeData: initialState().formData.elConfig.resultTypeData
                    }
                }
            }
        }

        this.setState({
            formData
        });
    }

    openInformationDialog = (type, info, optionIdx, nestedIdx, readOnly=false) => {

        this.setState({
            dialog: {
                ...this.state.dialog,
                data: {
                    ...this.state.dialog.data,
                    info,
                    nestedIdx,
                    optionIdx,
                    readOnly,
                    type
                }
            }
        }, () => {
            this.toggleDialog('informationForm', true)
        })
    }

    openMasterElement = () => {
        this.toggleDialog('masterElementDialog', true)
    }

    handleResultTypeDataChange = (val, fieldName, optionIdx, nestedIdx) => {
        let formData        = {...this.state.formData},
            reusableElForms = this.state.reusableElForms,
            nestedElements  = optionIdx != null ? [...formData.elConfig.options[optionIdx].nestedElements] : null;

        if(optionIdx != null && nestedIdx != null){

            nestedElements[nestedIdx] = {
                ...nestedElements[nestedIdx],
                formData: {
                    ...nestedElements[nestedIdx].formData,
                    elConfig: {
                        ...nestedElements[nestedIdx].formData.elConfig,
                        resultTypeData: {
                            ...nestedElements[nestedIdx].formData.elConfig.resultTypeData,
                            [fieldName]: val ? val : ''
                        }
                    }
                }
            }

            formData.elConfig.options[optionIdx] = {
                ...formData.elConfig.options[optionIdx],
                nestedElements
            }

        }
        else {
            formData = {
                ...formData,
                elConfig: {
                    ...formData.elConfig,
                    resultTypeData: {
                        ...formData.elConfig.resultTypeData,
                        [fieldName]: val ? val : ''
                    }
                }
            }
        }
        this.setState({
            formData
        }, () => {
            let formData = {...this.state.formData};

            if(fieldName == 'form'){


                let reusableElRefs = _.get(reusableElForms.find((el) => el.value == this.state.formData?.elConfig?.resultTypeData?.form?.value), 'elements');

                /*
                * Nested Elements: Clear the refs
                */
                if(optionIdx != null && nestedIdx != null){
                    nestedElements[nestedIdx] = {
                        ...nestedElements[nestedIdx],
                        formData: {
                            ...nestedElements[nestedIdx].formData,
                            elConfig: {
                                ...nestedElements[nestedIdx].formData.elConfig,
                                resultTypeData: {
                                    ...nestedElements[nestedIdx].formData.elConfig.resultTypeData,
                                    ref: ''
                                }
                            }
                        }
                    }
                    formData.elConfig.options[optionIdx] = {
                        ...formData.elConfig.options[optionIdx],
                        nestedElements
                    }
                }
                /*
                * Elements: Clear the refs
                */
                else {
                    formData = {
                        ...this.state.formData,
                        elConfig: {
                            ...this.state.formData.elConfig,
                            resultTypeData: {
                                ...this.state.formData.elConfig.resultTypeData,
                                ref: ''
                            }
                        }
                    }
                }
                
                this.setState({
                    reusableElRefs: reusableElRefs ? reusableElRefs : [],
                    formData
                });
            }
        });
    }

    handleUseMasterElement = (el) => {
        // If the element type == 'Information' clear the nm field
        if(el?.elType == 'Information'){
            el = {
                ...el,
                nm: '',
                description: ''
            }
        }

        this.setState({
            formData: el
        }, () => {
            this.toggleDialog('masterElementDialog', true);
        })
    }

    render() {
        const { isLoading, formErrors, infoOptionsPrePopulated, infoOptionsSelector, formData, dialog, keys, masterElement, readOnly } = this.state;
        const { data, classes } = this.props;

        return (isLoading ?
            <LoadingCircle/>
            :
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ElementConfiguration 
                            level={1} 
                            infoOptionsPrePopulated={infoOptionsPrePopulated}
                            infoOptionsSelector={infoOptionsSelector}
                            formData={this.state.formData}
                            lists={{reusableElForms: this.state.reusableElForms, reusableElRefs: this.state.reusableElRefs, areasList: this.state.areasList }}
                            formErrors={formErrors}
                            addNestedElement={this.addNestedElement}
                            removeNestedElement={this.removeNestedElement}
                            handleChange={this.handleChange}
                            handleAddRow={this.handleAddRow}
                            handleRemoveRow={this.handleRemoveRow}
                            handleRowEdit={this.handleRowEdit}
                            handleSelectChange={this.handleSelectChange}
                            toggleDialog={this.toggleDialog}
                            openInformationDialog={this.openInformationDialog}
                            handleResultTypeDataChange={this.handleResultTypeDataChange}
                            dialog={dialog}
                            keys={keys}
                            classes={classes}
                            masterElement={masterElement}
                            openMasterElement={this.openMasterElement}
                            readOnly={readOnly}
                        />
                    </Grid>
                </Grid>
                <DialogActions className='pr-0 pb-0' style={{paddingTop: '50px'}}>
                    <Button 
                        onClick={() => this.close()}
                        variant="text"
                    >
                        <FALightIcon icon="times" size={15} button />
                        Close
                    </Button>
                    {!readOnly && (
                        <Button 
                            color="primary"
                            onClick={() => masterElement ? this.props.deployConfirmation(`Are you sure you want to ${(data?.type).toLowerCase()} this master element?`, `${data?.type} Master Element?`, () => this.submit()) : this.submit()}
                            variant="contained"
                            disabled={
                                formData?.elType == '' || 
                                (!['Information'].includes(formData?.elType) && formData?.nm == '') ||
                                (['Information', 'Information Selector'].includes(formData?.elType) && formData?.elConfig?.data == null)
                            }
                        >
                            <FALightIcon icon="check" size={15} color="white" />
                            {data?.type}
                            {masterElement && ' Master Element'}
                        </Button>
                    )}
                </DialogActions> 

                {/* Information Dialog */}
                <SmallFormDialog
                    open={dialog?.informationForm}
                    onClose={() => this.toggleDialog('informationForm', true)}
                    title="Information"
                    content={
                    <InformationForm 
                        data={dialog?.data?.info}
                        toggleDialog={() => {
                            this.toggleDialog('informationForm', true);
                            this.setState({
                                keys: {
                                    ...this.state.keys,
                                    informationForm: uuidv4()
                                }
                            })
                        }}
                        handleChange={this.handleChange}
                        optionIdx={dialog?.data?.optionIdx}
                        nestedIdx={dialog?.data?.nestedIdx}
                        readOnly={dialog?.data?.readOnly}
                        type={dialog?.data?.type}
                    />}
                    maxWidth={dialog?.data?.readOnly ? 'sm' : 'lg'}
                />
                {/* Master Element Dialog */}
                <SmallFormDialog
                    open={dialog?.masterElementDialog}
                    onClose={() => this.toggleDialog('masterElementDialog', true)}
                    title="Master Elements"
                    content={
                    <MasterElementDialog
                        toggleDialog={() => {
                            this.toggleDialog('masterElementDialog', true);
                            this.setState({
                                keys: {
                                    ...this.state.keys,
                                   
                                }
                            })
                        }}
                        handleUseMasterElement={this.handleUseMasterElement}
                    />
                    }
                    maxWidth='sm'
                />
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success))
    };
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ElementForm));