import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, InputAdornment, TextField, Typography } from '@material-ui/core/';

import DataTable            from 'Components/Common/DataTables/CiDataTable';
import GenericEmailDialog   from 'Components/Common/Dialogs/GenericEmailDialog';
import AllIcon              from 'Components/Common/Icons/AllIcon';
import ImageWithError       from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle        from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper          from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect   from 'Components/Common/Selects/AutoCompleteSelect';
import PDFViewer            from 'Components/Common/PDFViewer/PDFViewer';

import SelectPaymentDialog from './SelectPaymentDialog';

import { colors }       from 'Helpers/ColourHelper';
import { getMediaType } from 'Helpers/FileHelper';
import icons            from 'Helpers/IconHelper';
import { clenyDate }    from 'Helpers/TimeHelper';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar }            from 'Actions/SnackBar/SnackBar';

const initialState = {
    isLoading:      true,
    access:         {},
    requests:       [],
    formData:       {
        customer:   null,
        amount:     0.00,
    },
    show:           {
        Pending:     false,
        Paid:        false,
        PartialPaid: false,
        Completed:   false,
    },
    customers:      [],
}

class CustomerPaymentRequests extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount(){
        this.getCustomers();
        this.getCustomerRequestedPayments();
    }

    getCustomerRequestedPayments = () => {
        API.get(`/accounts/customerPaymentRequests`)
        .then(res => {
            this.setState({
                isLoading: false,
                requests:  res?.data
            })
        });    
    }

    getCustomers = () => {
        API.get(`/customers`)
        .then(res => {
            this.setState({
                customers: res?.data
            });
        })
    }

    handleSelectChange = field => event => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: event && event.value
            }
        })
    }

    handleChange = field => event => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: event.target.value
            }
        })
    }

    handleBlur = field => event => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: parseFloat(event.target.value).toFixed(2)
            }
        })
    }

    submitRequest = () => {
        const { formData } = this.state;
        API.post(`/accounts/customerPaymentRequests`, formData)
        .then(res => {
            this.props.deploySnackBar('success','Customer Payment Request Created');
            this.getCustomerRequestedPayments();
            this.setState({ formData: initialState.formData });
        })
    }

    handleSelectPayment = rowData => {
        this.props.deployDialog(
            <SelectPaymentDialog 
                item={rowData}
                onSubmit={()=>{ this.props.closeDialog(); this.getCustomerRequestedPayments()}}
            />, 'Process Payment', 'success', 'md');
    }

    handleRequestActions = type => rowData => {
        let title = 'Send Payment Instruction';
        let templateId = 0;
        switch(type) {
            case 'stripe':
                title = 'Send Stripe Payment Instruction';
                templateId = 23;
            break;
            case 'cheque':
                title = 'Send Cheque Payment Instruction';
                templateId = 24;
            break;
            case 'transfer':
                title = 'Send Transfer Payment Instruction';
                templateId = 25;
            break;
            case 'cash':
                title = 'Send Cash Payment Instruction';
                templateId = 26;
            break;
            case 'paypal':
                title = 'Send PayPal Payment Instruction';
                templateId = 27;
            break;
            default:
                throw new Error('unhandled type');
        }

        this.props.deployDialog(
            <GenericEmailDialog 
                templates='sales'
                customerId={rowData.crp_cust_id} 
                templateId={templateId} 
                url={`/accounts/customerPaymentRequests/${rowData.crp_id}/email`}
                accounts
                onSend={this.getCustomerRequestedPayments}
            />, title, 'success', 'sm');
    }

    viewFile = file => () => {
        this.props.deployDialog(
            <Grid container spacing={1}>
                <Grid item xs={12} style={{textAlign:'center'}}>
                    {getMediaType(file) === 'image' &&
                        <ImageWithError height='500' width='500' src={file} />
                    }
                    {getMediaType(file) === 'pdf' &&
                        <PDFViewer pdfUrl={file} />
                    }
                    {(getMediaType(file) !== 'pdf' && getMediaType(file) !== 'image') && 'File Not Supported'}
                </Grid>
                <Grid className='buttonRow' item xs={12}>
                    <Button onClick={()=>{ this.props.closeDialog() }} variant='outlined'>Close</Button>
                </Grid>
            </Grid>, 'View File', 'success', 'md');
    }

    handleShowPayment = rowData => {
        let fileName = 'No File';
        let file     = null;

        if (rowData.payments.card_image)   file = rowData.payments.card_image;
        if (rowData.payments.cheque_image) file = rowData.payments.cheque_image;
        if (rowData.payments.crpp_file)    file = rowData.payments.file_upload;

        if (rowData.payments.card_image)   fileName = rowData.payments.crpp_card_epdq_img;
        if (rowData.payments.cheque_image) fileName = rowData.payments.crpp_cheque_image;
        if (rowData.payments.crpp_file)    fileName = rowData.payments.crpp_file;

        this.props.deployDialog(
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Method"
                        disabled
                        value={rowData.payments.crpp_method}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Paid"
                        disabled
                        value={rowData.payments.crpp_amount}
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Date"
                        disabled
                        value={clenyDate(rowData.payments.crpp_payment_datetime)}
                        fullWidth
                    />
                </Grid>
                {(rowData.payments.crpp_method === 'Card' || rowData.payments.crpp_method === 'PayPal' || rowData.payments.crpp_method === 'Stripe') && 
                    <Grid item container xs={12} spacing={2}>
                        {rowData.payments.crpp_card_payment_ref &&
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Payment Reference"
                                    value={rowData.payments.crpp_card_payment_ref}
                                    disabled
                                />
                            </Grid>
                        }
                        {rowData.payments.crpp_card_auth_no &&
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Auth No"
                                    value={rowData.payments.crpp_card_auth_no}
                                    disabled
                                />
                            </Grid>
                        }
                    </Grid>
                }
                {rowData.payments.crpp_method === 'Cheque' && 
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Cheque Number"
                                value={rowData.payments.crpp_cheque_no}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Cheque Payee"
                                value={rowData.payments.crpp_cheque_payee}
                                disabled
                            />
                        </Grid>
                    </Grid>
                }
                {rowData.payments.crpp_method === 'Bank Transfer' && 
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Reference"
                                value={rowData.payments.crpp_bank_transfer_ref}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Payee"
                                value={rowData.payments.crpp_bank_transfer_payee}
                                disabled
                            />
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Payment File"
                        value={fileName}
                        disabled
                        InputProps={{
                            endAdornment: file && (<AllIcon icon={icons.search} onClick={this.viewFile(file)} noMargin />)
                        }}
                    />
                </Grid>
                <Grid item xs={12} className='buttonRow'><Button onClick={this.props.closeDialog} variant='outlined'>Close</Button></Grid>
            </Grid>
            , 'Payment Details', 'success', 'sm');
    }

    dropExpansion = (status) => () => {
        this.setState({ 
            show: {
                ...this.state.show,
                [status]: !this.state.show[status]
            }
        });
    }

    deleteRequest = (id) => () => {
        API.post(`/accounts/customerPaymentRequests/${id}/delete`).then(this.getCustomerRequestedPayments);
    }

    render(){
        const { isLoading, requests, formData, customers, show } = this.state;

        if ( isLoading ) return <LoadingCircle />

        const RequestTables = ({status, title}) => {
            let data = requests;
            if (status === 'Paid' || status === 'PartialPaid') {
                data = _.filter(requests, {crp_status: 'Paid'});
                data = _.filter(data, i => {
                    if (status === 'Paid'        && i.invoices.length === 0) return true;
                    if (status === 'PartialPaid' && i.invoices.length > 0)   return true;
                    return false;
                })
            } else {
                data = _.filter(requests, {crp_status: status});
            }
            if (data.length === 0) return null;
            return (
                <ExpansionPanel
                    expanded={show[status]}
                >
                    <ExpansionPanelSummary onClick={this.dropExpansion(status)} expandIcon={<AllIcon icon={icons.arrowDown} noMargin color={colors.white}/>}>
                        <Typography>{title} ({data.length})</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <DataTable
                            config={{
                                key: 'crp_id',
                                dropRow: {
                                    droppable: rowData => rowData.invoices?.length > 0,
                                    style: {fontWeight: 'bold'},
                                    hideDropArrow: true,
                                    columns: [
                                        {   
                                            colSpan: 2,
                                            field: i => `${i.invoice.invoice_number} (Sage: ${i.invoice.invoice_sage_invoice_number})`,
                                        },
                                        {   
                                            colSpan:     1,
                                            field:       'ip_txn_amount',
                                            fieldFormat: 'currency',
                                        },
                                        {   
                                            colSpan: 1,
                                            actions: rowData => ([
                                                {
                                                    name:         'view Order', 
                                                    icon:         icons.search, 
                                                    linkExternal: `/sales/order/view/${rowData.invoice.invoice_order_id}`,
                                                },
                                            ])
                                        },
                                    ],
                                    data: 'invoices',
                                }
                            }}
                            columns={_.filter([
                                {
                                    heading: 'Customer',
                                    field:   'customer.cust_name',
                                    truncate: true,
                                },
                                (status === 'Paid' || status === 'Completed' || status === 'PartialPaid') && {
                                    heading:       'Method',
                                    field:         i => {
                                        let icon = icons.unknown;
                                        switch(i?.payments?.crpp_method) {
                                            case 'Cash':
                                                    icon = icons.cash;
                                                break;    
                                            case 'Stripe':
                                                icon = icons.stripe;
                                                break;  
                                            case 'PayPal':
                                                icon = icons.paypal;
                                                break;  
                                            case 'Card':
                                                icon = icons.Card;
                                                break; 
                                            case 'Cheque':
                                                icon = icons.cheque;
                                                break;
                                            case 'Bank Transfer':
                                                icon = icons.bank;
                                                break;
                                            default:
                                                icon = icons.unknown;
                                                break;
                                        }
                                        return ( <AllIcon icon={icon} noMargin tooltip={i.payments.crpp_method} /> );
                                    },
                                    sizeToContent: true,
                                },
                                (status === 'Paid' || status === 'Completed' || status === 'PartialPaid') && {
                                    heading:       'Payment Date',
                                    field:         'payments.crpp_payment_datetime',
                                    fieldFormat:   'clenydate',
                                    sizeToContent: true,
                                },
                                {
                                    heading:       'Requested',
                                    field:         'crp_amount',
                                    fieldFormat:   'currency',
                                    sizeToContent: true,
                                },
                                {
                                    heading:       'Paid',
                                    field:         'sum_of_payments',
                                    fieldFormat:   'currency',
                                    sizeToContent: true,
                                },
                                (status === 'Paid' || status === 'Completed' || status === 'PartialPaid') && {
                                    heading:       'Assigned',
                                    field:         i => i.crp_amount - i.remaining_funds,
                                    fieldFormat:   'currency',
                                    sizeToContent: true,
                                },
                                {
                                    actions: i => {
                                        if (status === 'Pending'){
                                            return [
                                                {name: 'Delete',                                  icon: icons.delete, onClick: () => this.props.deployConfirmation('Are you sure you want to delete this request?', 'Delete Payment Request', this.deleteRequest(i.crp_id)), disabled: parseFloat(i.sum_of_payments) > 0 },
                                                {name: 'Select payment method / process payment', icon: icons.true,   onClick: this.handleSelectPayment},
                                                {name: 'Send Stripe Payment Instruction',         icon: icons.stripe, onClick: this.handleRequestActions('stripe'),   color: _.find(i.intents, {crpp_method: 'Stripe'})        ? colors.yellow : colors.blue, lib: 'brand'},
                                                {name: 'Send PayPal Payment Instruction',         icon: icons.paypal, onClick: this.handleRequestActions('paypal'),   color: _.find(i.intents, {crpp_method: 'PayPal'})        ? colors.yellow : colors.blue, lib: 'brand'},
                                                {name: 'Send Cheque Payment Instruction',         icon: icons.cheque, onClick: this.handleRequestActions('cheque'),   color: _.find(i.intents, {crpp_method: 'Cheque'})        ? colors.yellow : colors.blue},
                                                {name: 'Send Bank Transfer Instruction',          icon: icons.bank,   onClick: this.handleRequestActions('transfer'), color: _.find(i.intents, {crpp_method: 'Bank Transfer'}) ? colors.yellow : colors.blue},
                                                {name: 'Send Cash Payment Instruction',           icon: icons.cash,   onClick: this.handleRequestActions('cash'),     color: _.find(i.intents, {crpp_method: 'Cash'})          ? colors.yellow : colors.blue}
                                            ]
                                        } else {
                                            return [
                                                {name: 'view Payment', icon: icons.search, onClick: this.handleShowPayment},
                                            ];
                                        }

                                    }
                                }
                            ], i => i)}
                            rows={data}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            )
        }

        return (
            <Grid container>
                <Grid item xs={12} style={{paddingBottom: '1.5em'}}><Typography variant='h5'>Customer Requested Payments</Typography></Grid>
                <Grid item xs={5} style={{paddingBottom: '1.5em'}}>
                    <PaddedPaper>
                        <Grid spacing={1} container>
                            <Grid item xs={12}>
                                <Typography variant="body1">Request New Customer Payment</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    fullWidth
                                    label="Customer"
                                    options={_.map(customers, c => ({value: c.cust_id , label: c.cust_name}))}
                                    value={formData.customer}
                                    onChange={this.handleSelectChange('customer')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="number"
                                    fullWidth
                                    label="Amount"
                                    value={formData.amount}
                                    onChange={this.handleChange('amount')}
                                    onBlur={this.handleBlur('amount')}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className='buttonRow'>
                                <Button color='primary' variant='contained' 
                                    onClick={() => this.props.deployConfirmation('Are you sure you want to request this payment?', 'Request Customer Payment', this.submitRequest)}
                                    disabled={!formData.customer || formData.amount <= 0}
                                >Create Request</Button>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <RequestTables status='Pending'     title='Pending' />
                    <RequestTables status='Paid'        title='Paid' />
                    <RequestTables status='PartialPaid' title='Partially Assigned' />
                    <RequestTables status='Completed'   title='Completed' />
                </Grid>
            </Grid>
        )
    }

}
function mapDispatchToProps(dispatch) {
    return {
        deployDialog:           (content, title, variant, size) => { dispatch(deployDialog(content, title, variant, size))},
        deploySnackBar:         (variant, body)                 => { dispatch(deploySnackBar(variant, body))},
        deployConfirmation:     (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
        closeDialog:            ()                              => {dispatch(closeDialog())}
    }
}

export default connect(null, mapDispatchToProps)(CustomerPaymentRequests);