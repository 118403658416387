import React, {Component} from 'react';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import FALightIcon from '../Icons/FontAwesome/FALightIcon';
import {colors} from 'Helpers/ColourHelper';
const materialTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#0282C6'
        },
        secondary: {
            main: '#D61F26'
        }
    },
    overrides: {
        MuiButton: {
            root: {
                '&:nth-child(2)': {
                    color: "white",
                    backgroundColor: 'rgba(2,130,198,1)',
                    '&:hover': {
                        color: "white",
                        backgroundColor: 'rgba(2,130,198,0.8)',
                    }
                }
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
                },
                '&:after': {
                    borderBottom: '2px solid #0282C6'
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '2px solid #0282C6'
                }
            }
        }
    }
});

export default class _TimePicker extends Component {
    render() {
        return (
            <React.Fragment>
                <FormControl fullWidth margin="normal" error={!!this.props.errorText}>
                    <MuiThemeProvider theme={materialTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                                id={this.props.id}
                                name={this.props.name}
                                label={this.props.label}
                                value={this.props.value}
                                onChange={this.props.onChange}
                                disableFuture={this.props.disableFuture}
                                disablePast={this.props.disablePast}
                                format="HH:mm"
                                autoOk={this.props.autoOk}
                                ampm={false}
                                disabled={this.props.disabled}
                                InputLabelProps={{
                                    error: !!this.props.errorText
                                }}
                                InputProps={{
                                    error: !!this.props.errorText,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <FALightIcon icon='clock' style={{color: !!this.props.errorText && colors.red}} disabled={this.props.disabled} light noMargin />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                    {this.props.errorText &&
                        <FormHelperText>{this.props.errorText}</FormHelperText>
                    }
                </FormControl>
            </React.Fragment>
        );
    }
}