import _      from 'lodash';
import uuidv4 from 'uuid/v4';
import API    from 'API';

import * as PopNotificationActionTypes from 'Actions/PopNotification/Types/Types';

 const initialState = {    
    key: uuidv4(),
    data: [],
};

function PopNotificationReducer(state = initialState, action) {
    switch (action.type) {
        case PopNotificationActionTypes.DEPLOY_POP_NOTIFICATION:
            let data = state.data;
            let change = false;
            _.each(action.payload.data, i => {if (_.findIndex(data, {id:i.id}) === -1) {data.push(i); change=true; }})
            if (!change) return state;
            return {
                ...state,
                key: uuidv4(),
                data: data,
            };
        case PopNotificationActionTypes.DISCARD_POP_NOTIFICATION:   
            let dat = state.data;
            _.map(dat, i => { i.closed = 1; });
            API.post(`/misc/notifications/pop/dismissAll`)
            return { key: uuidv4(), data: dat };
        case PopNotificationActionTypes.DISMISS_POP_NOTIFICATION:
            let d = state.data;
            const id = action.payload;
            API.post(`/misc/notifications/pop/${id}/dismiss`)
            _.map(d, i => {if (i.id === id) i.closed = 1;});
            return { key: uuidv4(), data: d };
        default: return state;
    }
}

 export default PopNotificationReducer;