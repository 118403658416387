import { Grid, Typography } from "@material-ui/core";
import { closeDialog, deployDialog } from "Actions/Dialog/Dialog";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import _ from "lodash";
import { useDispatch } from "react-redux";
import InfoDialog from "./InfoDialog";
import React from "react";
import { colors } from "Helpers/ColourHelper";
// import ThisWeekBlock from "./ThisWeekBlock";
// import TodayBlock from "./TodayBlock";
import AllIcon from "Components/Common/Icons/AllIcon";
import IconHelper from "Helpers/IconHelper";


const SectionBox = ({children, status}) => {
	return (
		<PaddedPaper
			style={{
				margin: "0.6em",
				padding: 7,
				// outline: `0.5px solid ${(status < 33 ? colors.red : (status < 66 ? colors.orange : colors.green))}`,
				// outline: `0.5px solid ${colors.primary}`,
				backgroundColor: "#FFFFFF",
			}}
		>
			{children}
		</PaddedPaper>
	)
}

export default function (props) {
	const { title, data, tasks, defaultOpen } = props;

	const [_showDeep, setShowDeep] = React.useState(false);

	// if (["This Week", "Today"].includes(title)) {
	// 	switch (title) {
	// 		case "This Week":
	// 			return (
	// 				<SectionBox><ThisWeekBlock data={_.find(data, {i: 11})}/></SectionBox>
	// 			);
	// 		case "Today":
	// 			return (
	// 				<SectionBox><TodayBlock  data={_.find(data, {i: 12}).d}/></SectionBox>
	// 			);
					
	// 	}
	// }

  	const dispatch = useDispatch();

	const showMoreInfo = (id, dialogTitle) => () => {
		dispatch(
		deployDialog(
			<InfoDialog id={id} closeDialog={() => dispatch(closeDialog())} />,
			dialogTitle,
			"standard",
			"md"
		)
		);
	};

	const showDeep = _showDeep || defaultOpen;

	return (
		<SectionBox status={( props.index / props.itemsLength ) * 100}>
			<Grid container spacing={2} style={{ alignItems: "center", backgroundColor: colors.primary, borderRadius: 5, }}>
				<Grid item style={{cursor: 'pointer'}} onClick={()=>setShowDeep(!showDeep)}>
					<AllIcon icon={IconHelper[`outstanding-${title}`] ?? IconHelper.unknown} size={25} white noMargin style={{marginLeft: '0.5em', cursor: 'pointer'}}/>
				</Grid>
				<Grid item style={{cursor: 'pointer'}} xs={6} onClick={()=>setShowDeep(!showDeep)}>
					<Typography variant="h6" style={{color: colors.white}} >
						{title}
					</Typography>
				</Grid>
				<Grid item style={{marginLeft: 'auto', cursor: 'pointer'}} onClick={()=>setShowDeep(!showDeep)}>
					<Typography variant="body1" style={{fontWeight: 'bold', color: colors.white}}>
						{tasks} Tasks
					</Typography>
				</Grid>
				<Grid item style={{cursor: 'pointer'}} onClick={()=>setShowDeep(!showDeep)}>
					<AllIcon noMargin size={25} icon={showDeep ? IconHelper.arrowUp : IconHelper.arrowDown} white  style={{cursor: 'pointer'}}/>
				</Grid>
				{showDeep &&
					<Grid item xs={12} style={{backgroundColor: colors.white, borderRadius: "0 0 5px 5px", padding: 16}}>
						<Grid container spacing={1} style={{ alignItems: "flex-end", }}>
							<Grid item xs={9} style={{borderBottom:"0.5px solid #e8e9eb", fontWeight: 'bold'}}>	
								<strong>Task</strong>
							</Grid>
							<Grid item xs={2} style={{textAlign: "right",borderBottom:"0.5px solid #e8e9eb", fontWeight: 'bold'}}>
								<strong>Qty</strong>
							</Grid>
							<Grid item xs={1} style={{textAlign: "right", cursor: "pointer", borderBottom:"0.5px solid #e8e9eb", fontWeight: 'bold'}}>
								<strong>View</strong>
							</Grid>
							{_.map(_.orderBy(data, 'd', 'desc'), ({ n, d, i }, idx) => {
								return (
									<>
										<Grid
											key={`${title}-${idx}`}
											item
											xs={9}
											style={{
											cursor: "pointer",
											borderBottom:
												idx + 1 !== data.length && "0.5px solid #e8e9eb",
											}}
											onClick={showMoreInfo(i, `${title} - ${n}`)}
										>
											<Typography variant="body1" gutterBottom>
												{n}
											</Typography>
										</Grid>
										<Grid
											item
											xs={2}
											style={{
											borderBottom:
												idx + 1 !== data.length && "0.5px solid #e8e9eb",
											}}
										>
											<Typography
												variant="body1"
												gutterBottom
												style={{
													textAlign: "right",
													fontWeight: "500",
												}}
											>
												{d}
											</Typography>
										</Grid>
										<Grid item xs={1} style={{textAlign: "right", cursor: "pointer",
											borderBottom:
											idx + 1 !== data.length && "0.5px solid #e8e9eb",
										}}>
											<AllIcon
												icon={IconHelper.search}
												noMargin
												onClick={showMoreInfo(i, `${title} - ${n}`)}
												noPadding
												style={{ padding: 4 }}
											/>
										</Grid>
									</>
								);
							})}
						</Grid>
					</Grid>
				}
			</Grid>
		</SectionBox>
	);
}
