import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { Grid, Button, Box, Typography, Tooltip } from '@material-ui/core';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';
import CustomerReturnsDownloadPrevDocsDialog from 'Components/CustomerReturns/CustomerReturnsDownloadPrevDocsDialog/CustomerReturnsDownloadPrevDocsDialog';
import CustomerReturnsEmailPrevDocsDialog from 'Components/CustomerReturns/CustomerReturnsEmailPrevDocsDialog/CustomerReturnsEmailPrevDocsDialog';
import ReturnDownloadDialog from 'Components/CustomerReturns/CustomerReturns/ViewCustomerReturns/ReturnDownloadDialog';
import ReturnEmailDialog    from 'Components/CustomerReturns/CustomerReturns/ViewCustomerReturns/ReturnEmailDialog';
import { colors } from 'Helpers/ColourHelper';
import API from 'API';
import moment from 'moment';

const EquipmentHistoryRows = ({ customerId=0, equipmentId=0, history, plainHeader=false, access, slim=false }) => {

    const   dispatch = useDispatch(),
            [isLoading, setIsLoading] = useState(true),
            [equipmentHistory, setEquipmentHistory] = useState([]);

    useEffect(() => {
        if(!equipmentId) return;
        setIsLoading(false);
        setEquipmentHistory(history);
    }, [equipmentId]);

    const NotesDialog = ({ note }) => (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="body2" paragraph>
                    {note.split('\n').map((item, key) => {
                        return  <span key={key}>
                                    {item}{note.split('\n').length === key+1 ? null : <br/>}
                                </span>
                    })}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <div className='buttonRow'>
                    <Button variant="outlined" color="default" onClick={() => dispatch(closeDialog()) }>Close</Button>
                </div>
            </Grid>
        </Grid>
    )

    const handleCRDialogOpen = (crId, type) => {
        API.get(`/customerReturns/returns/${crId}`).then(res =>{
            
            if(type = 'download'){
                dispatch(deployDialog(<ReturnDownloadDialog cr={res.data}/>, 'Download Previous Documents', '', 'md'));
            }
            else {
                dispatch(deployDialog(<ReturnEmailDialog cr={res.data} />, 'Previous Emails', '', 'md'));
            }
        });
    }

    const generateColumns = () => {

        let cols =  [];

        cols.push(
            {
            heading: 'Date',
            field: rowData => <>
                {rowData?.details?.historyDate ? 
                    <>
                    <Tooltip
                        title={
                            <Typography variant="body2" style={{whiteSpace: 'pre-wrap'}}>
                                {rowData?.details?.historyDateType}
                            </Typography>
                        }
                        placement="right"
                    >
                        <span>
                            <AllIcon 
                                heavy
                                icon='info-circle'
                                button 
                                size='xsmall'
                                style={{
                                    color: 'rgba(0, 0, 0, 0.20)'
                                }}
                            />
                        </span>
                    </Tooltip>
                    {moment(rowData?.details?.historyDate).format('DD/MM/YYYY')} 
                    </>
                : '-'}
            </>,
            sizeToContent: true
        }
        );

        !slim && cols.push(
        {
            actionsCustomHeader: true,
            heading: 'Reference',
            alignment: 'left',

            actions: rowData => {
                return rowData?.details?.type ? 
                        (rowData?.details?.type == 'order') ? 
                        [{ label: rowData?.details?.ref,  link: `/sales/order/view/${rowData?.details?.id}`, type:'plainLink', color: rowData?.status === 'Written Off' ? colors.red : undefined}] :
                        ( rowData?.details?.type == 'creditNote' ? 
                            [{ label: rowData?.details?.ref,  link: `/returns/creditnote/${rowData?.details?.id}`, type:'plainLink', color: colors.red }] 
                        :
                            [{ label: rowData?.details?.ref,  link: `/returns/customerReturn/${rowData?.details?.id}`, type:'plainLink', color: colors.red }]
                        )
                    :
                        [{ label: '-'}]
            },
            cellProps: rowData => {
                return {
                    style: {
                        width: '14%'
                    }
                }
            }
        })

        cols.push(
        {
            heading: 'Customer',
            field: rowData => <> 
                <Typography variant="body2" >
                    {rowData?.custNm ?? '-'}
                </Typography>
                <Typography variant="body2" >
                    {rowData?.details?.address && (
                        <Tooltip
                            title={
                                <Typography variant="body2" style={{whiteSpace: 'pre-wrap'}}>
                                    <b>Site Address:</b><br />
                                    {rowData?.details?.address.split('\n').map((item, key) => {
                                        return  <span key={key}>
                                                    {item}{rowData?.details?.address.split('\n').length === key+1 ? null : <br/>}
                                                </span>
                                    })}
                                </Typography>

                            }
                            placement="right"
                        >
                            <span>
                                <AllIcon 
                                    heavy
                                    icon='info-circle'
                                    button 
                                    size='xsmall'
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.20)'
                                    }}
                                />
                            </span>
                        </Tooltip>
                    )}
                    <span style={{color: ['creditNote', 'customerReturn'].includes(rowData?.details?.type) ? colors.red : undefined}}>{rowData?.details?.addressNm ?? '-'}</span>
                </Typography>
            </>
        },
        {
            heading: 'System Added',
            field: rowData => rowData?.manuallyAdded == 1 ? false : true,
            sizeToContent: true,
            alignment: 'center',
            fieldFormat: 'boolean',
        },
        {
            heading: 'Manually Added',
            field: rowData => rowData?.manuallyAdded == 1 ? true : false,
            sizeToContent: true,
            alignment: 'center',
            fieldFormat: 'boolean',
        },
        {
            heading: 'Status',
            field: rowData => rowData?.status,
            sizeToContent: true,
            cellProps : rowData => {
                return {
                    style: {
                        color: (rowData?.status === 'Condemned' || rowData?.status === 'Written Off') ? colors?.red : null
                    }
                }
            }
        },
        {
            heading: 'Reason',
            field: rowData => rowData?.reason ? <Tooltip title={<Typography variant="body2">{rowData?.reason}</Typography>}><span>{rowData?.reason}</span></Tooltip> : '-',
            sizeToContent: true,
            truncate: true
        });

        slim && cols.push(
            {
                heading: 'Created',
                field: rowData => rowData?.createdDateTime,
                sizeToContent: true,
                fieldFormat: 'datetime'
            }
        )

        !slim && cols.push(
        {
            actions: rowData => {

                let actions = [];

                switch(rowData?.details?.type){
                    case 'order':
                        actions = [
                            {name: 'View Notes',                    icon: 'file',           disabled: true},
                            {name: 'Download',                      icon: 'download',       onClick: () => dispatch(deployDialog(<OrderDownloadPrevDocsDialog orderId={rowData?.details?.id} />, 'Download Previous Documents', '', 'md'))}, 
                            {name: 'Send Order to Customer',        icon: 'envelope',       onClick: () => dispatch(deployDialog(<OrderEmailPrevDocsDialog orderId={rowData?.details?.id} />, 'Email Previous Documents', '', 'md'))}, 
                            {name: 'View Order',                    icon: 'search',         link: '/sales/order/view/' + rowData?.details?.id},
                            {name: 'View this Customers Orders',    icon: 'file-search',    link: `/customers/view/${rowData.custId}/Orders`}
                        ]
                    break;
                    case 'creditNote':
                        actions = [
                            {name: 'View Notes',                    icon: 'file',           disabled: true},
                            {name: 'Download',                      icon: 'download',       onClick: () => dispatch(deployDialog(<CustomerReturnsDownloadPrevDocsDialog cnId={rowData?.details?.id} />, 'Download Previous Documents', '', 'md'))},
                            {name: 'Send Credit Note to Customer',  icon: 'envelope',       onClick: () => dispatch(deployDialog(<CustomerReturnsEmailPrevDocsDialog cnId={rowData?.details?.id} />, 'Email Previous Documents', '', 'md'))}, 
                            {name: 'View Credit Note',              icon: 'search',         link: {pathname: `/returns/creditnote/${rowData?.details?.id}`}}, 
                            {name: 'View Customers Credit Notes',   icon: 'file-search',    link: `/customers/view/${rowData.custId}/CreditNote`}
                        ] 
                    break;
                    case 'customerReturn':
                        actions = [
                            {name: 'View Notes',                        icon: 'file',           disabled: true},
                            {name: 'Download',                          icon: 'download',       onClick: () => handleCRDialogOpen(rowData?.details?.id, 'download')},
                            {name: 'Send Customer Return to Customer',  icon: 'envelope',       onClick: () => handleCRDialogOpen(rowData?.details?.id, 'email')}, 
                            {name: 'View Customer Return',              icon: 'search',         link: {pathname: `/returns/customerReturn/${rowData?.details?.id}`}}, 
                            {name: 'View Customers Returns',            icon: 'file-search',    link: `/customers/view/${rowData.custId}/CustomerReturns`}
                        ]
                    break;
                    default:
                        actions = [
                            {name: 'View Notes',                    icon: 'file',           onClick: () => dispatch(deployDialog(<NotesDialog note={rowData?.notes} />, 'Notes', '', 'sm')), disabled: !rowData?.notes},
                            {name: 'Download',                      icon: 'download',       disabled: true},
                            {name: 'Send Credit Note to Customer',  icon: 'envelope',       disabled: true}, 
                            {name: 'View Credit Note',              icon: 'search',         disabled: true}, 
                            {name: 'View Customers Credit Notes',   icon: 'file-search',    disabled: true}
                        ]
                    break;
                }

                return actions;
            }
        }
    );
    

        return cols;
    } 
       


    return (
        (isLoading && <LoadingCircle />) || (
            <Box>
               {equipmentHistory && equipmentHistory?.length > 0 &&
                    map(equipmentHistory, (history, index) => {
                        return (
                            <Grid container spacing={3} key={index}>
                                <Grid item xs={12} style={{marginBottom: 50}}>
                                    <Typography variant="h6" gutterBottom>
                                        {history?.cust?.name ? 
                                            <Link to={{ pathname: access?.customerEquipment ? `/customers/view/${history?.cust?.id}/equipment`: `/customers/view/${history?.cust?.id}`}} className='blueLink'>
                                                <AllIcon icon='users' size='small' button style={{marginRight: 10}} color={colors.blue} /> {history?.cust?.name}
                                            </Link> 
                                        :
                                            <i>No Customer</i>
                                        }
                                    </Typography>
                                    <DataTable  
                                        config={{
                                            key: 'id',
                                            pagination: false,
                                            alternatingRowColours: false,
                                            isLoading: isLoading,
                                            responsiveImportance: true,
                                            plainHeader,
                                            rowProps: rowData => {
                                                return {
                                                    style: {
                                                        color: ['Returned', 'Written Off', 'Condemned','Replaced'].includes(rowData?.status) ? colors.red : undefined,
                                                        backgroundColor: rowData?.latest ? 'rgb(241, 248, 233)' : undefined
                                                    }
                                                }
                                            }
                                        }}
                                        columns={generateColumns()}
                                        rows={history?.history}
                                    />
                                </Grid>
                            </Grid>
                        )
                    }
                )}
            </Box>
        )
    )
};

export default EquipmentHistoryRows;