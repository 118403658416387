import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import API from '../../../API';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import {statusColour} from './../../../Helpers/ColourHelper'
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import StatusDataTable from '../StatusDataTable';
import icons from 'Helpers/IconHelper';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    keywords: '',
    searchResults: {},
    access: {
        updateDelivery: false
    },
    isLoading: true
});
class SupplierStockReturnDelivery extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'SupplierStockReturn:Delivery';
        this.state              = this.getInitialState(initialState()); 
    }

    componentDidMount(){
        !this.hasPageState() && this.loadComponentData();
    }

    loadComponentData = () => {
        this.getSearchData();
        this.checkAccess();
    }

    checkAccess = () => {
        API.get('/staff/my/access/check/update-stock-return-delivery')
        .then((viewRes) =>  {
            viewRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        updateDelivery: viewRes.data.has_access
                    }
                });
        });
    }

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            const params = {
                keywords: this.state.keywords,
                status: 'Delivery'
            }
            API.get('/supplierStockReturns', {
                params,
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    _.map(result.data, (ssr) => {
                        ssr.colour = statusColour(ssr.sr_status);
                    });
                    this.setState({
                        searchResults: result.data,
                        isLoading: false
                    },()=>this.savePageState())
                }
            })
        });
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
        }, 
        () => {
            this.getSearchData();
        });
    }

    downloadFile = rowData => {
        API.get('/supplierStockReturns/' + rowData.sr_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Supplier Stock Return Delivery
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={this.state.keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <StatusDataTable 
                            isLoading={this.state.isLoading}
                            searchResults={this.state.searchResults}
                            actions={ rowData => {
                                return [
                                    {name: 'Download', icon: 'download', onClick: this.downloadFile},
                                    rowData.locked ?
                                        {name: 'Locked', icon: icons.lock, onClick: ()=>{}} :
                                        {name: 'View',   icon: 'search',   link: '/supplier-stock-return/delivery/update/' + rowData.sr_id, disabled: !this.state.access.updateDelivery}
                                ]
                            }}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(SupplierStockReturnDelivery);
