import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Table, TableCell, TableHead, TableRow, Typography, Button, TableBody } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import API from "API";
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import BarChart from "Components/Common/Charts/BarChart";
import LineChart from "Components/Common/Charts/LineChart";
import PieChart from "Components/Common/Charts/PieChart";
import CiDataTable from "Components/Common/DataTables/CiDataTable";
import AllIcon from "Components/Common/Icons/AllIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import InfoBox from "Components/Common/reports/InfoBox";
import { colors } from "Helpers/ColourHelper";
import IconHelper from "Helpers/IconHelper";
import { clenyDate } from "Helpers/TimeHelper";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";

const initialState = (props) => ({
    isLoading: true,
    promotions: [],
})

const splitTableBorderRight = [ 'Contacts', 'Failed', 'Complaints', 'Unread' ];
class CampaignPromotions extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getPromotions();
    }

    getPromotions = () => {
        API.get('/reports/marketing/promotions').then((response) => {
            this.setState({
                promotions: response.data,
                isLoading: false
            });
        
        });
    }

    render() {

        const { isLoading, promotions } = this.state;

        if (isLoading) {
            return <LoadingCircle />;
        }

        return (
            <Grid container spacing={2} style={{alignItems: 'center'}}>
                <Grid item xs={6}>
                    <PaddedPaper>
                        <Table>
                            <TableHead>
                                <TableCell></TableCell>
                                <TableCell style={{textAlign: 'center'}}>Opened</TableCell>
                                <TableCell style={{textAlign: 'center'}}>Quotes</TableCell>
                                <TableCell>Quote Total</TableCell>
                                <TableCell style={{textAlign: 'center'}}>Orders</TableCell>
                                <TableCell>Order Total</TableCell>
                                <TableCell>Convertion</TableCell>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell  style={{color: colors.green}}>
                                        From Campaigns
                                    </TableCell>
                                    <TableCell  style={{color: colors.green, textAlign: 'center'}}>
                                        {_.sumBy(promotions, i => parseInt(i.c.d.o))}
                                    </TableCell>
                                    <TableCell  style={{color: colors.green, textAlign: 'center'}}>
                                        {_.sumBy(promotions, i => parseInt(i.q.i.q))}
                                    </TableCell>
                                    <TableCell  style={{color: colors.green}}>
                                        £{parseFloat(_.sumBy(promotions, i => parseFloat(i.q.i.t))).toFixed(2)}
                                    </TableCell>
                                    <TableCell  style={{color: colors.green, textAlign: 'center'}}>
                                        {_.sumBy(promotions, i => parseInt(i.o.i.q))}
                                    </TableCell>
                                    <TableCell  style={{color: colors.green}}>
                                        £{parseFloat(_.sumBy(promotions, i => parseFloat(i.o.i.t))).toFixed(2)}
                                    </TableCell>
                                    <TableCell  style={{color: colors.green, textAlign: 'center'}}>
                                        {parseFloat(
                                        parseInt(_.sumBy(promotions, i => parseInt(i.c.d.o))) <= parseInt(_.sumBy(promotions, i => parseInt(i.o.i.q))) ?
                                            100 :
                                            (
                                                (parseInt(_.sumBy(promotions, i => parseInt(i.c.d.o))) === 0 || parseInt(_.sumBy(promotions, i => parseInt(i.o.i.q))) === 0 ) ?
                                                0 :
                                                (parseInt(_.sumBy(promotions, i => parseInt(i.o.i.q)) / parseInt(_.sumBy(promotions, i => parseInt(i.c.d.o)))) * 100)
                                            )).toFixed(2)
                                        }%
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell  style={{color: colors.red}}>
                                        Outside Campaigns
                                    </TableCell>
                                    <TableCell  style={{color: colors.red, textAlign: 'center'}}>
                                        0
                                    </TableCell>
                                    <TableCell  style={{color: colors.red, textAlign: 'center'}}>
                                        {_.sumBy(promotions, i => parseInt(i.q.o.q))}
                                    </TableCell>
                                    <TableCell  style={{color: colors.red}}>
                                        £{parseFloat(_.sumBy(promotions, i => parseFloat(i.q.o.t))).toFixed(2)}
                                    </TableCell>
                                    <TableCell  style={{color: colors.red, textAlign: 'center'}}>
                                        {_.sumBy(promotions, i => parseInt(i.o.o.q))}
                                    </TableCell>
                                    <TableCell  style={{color: colors.red}}>
                                        £{parseFloat(_.sumBy(promotions, i => parseFloat(i.o.o.t))).toFixed(2)}
                                    </TableCell>
                                    <TableCell  style={{color: colors.red, textAlign: 'center'}}>
                                        {parseFloat(
                                        parseInt(_.sumBy(promotions, i => parseInt(i.c.d.o))) <= parseInt(_.sumBy(promotions, i => parseInt(i.o.o.q))) ?
                                            100 :
                                            (
                                                (parseInt(_.sumBy(promotions, i => parseInt(i.c.d.o))) === 0 || parseInt(_.sumBy(promotions, i => parseInt(i.o.o.q))) === 0 ) ?
                                                0 :
                                                (parseInt(_.sumBy(promotions, i => parseInt(i.o.o.q)) / parseInt(_.sumBy(promotions, i => parseInt(i.c.d.o)))) * 100)
                                            )).toFixed(2)
                                        }%
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell  >
                                        Total
                                    </TableCell>
                                    <TableCell  style={{color: colors.black, textAlign: 'center'}}>
                                        {_.sumBy(promotions, i => parseInt(i.c.d.o))}
                                    </TableCell>
                                    <TableCell  style={{color: colors.black, textAlign: 'center'}}>
                                        {_.sumBy(promotions, i => parseInt(i.q.t.q))}
                                    </TableCell>
                                    <TableCell  style={{color: colors.black}}>
                                        £{parseFloat(_.sumBy(promotions, i => parseFloat(i.q.t.t))).toFixed(2)}
                                    </TableCell>
                                    <TableCell  style={{color: colors.black, textAlign: 'center'}}>
                                        {_.sumBy(promotions, i => parseInt(i.o.t.q))}
                                    </TableCell>
                                    <TableCell  style={{color: colors.black}}>
                                        £{parseFloat(_.sumBy(promotions, i => parseFloat(i.o.t.t))).toFixed(2)}
                                    </TableCell>
                                    <TableCell  style={{color: colors.black, textAlign: 'center'}}>
                                        {parseFloat(
                                        parseInt(_.sumBy(promotions, i => parseInt(i.c.d.o))) <= parseInt(_.sumBy(promotions, i => parseInt(i.o.t.q))) ?
                                            100 :
                                            (
                                                (parseInt(_.sumBy(promotions, i => parseInt(i.c.d.o))) === 0 || parseInt(_.sumBy(promotions, i => parseInt(i.o.t.q))) === 0 ) ?
                                                0 :
                                                (parseInt(_.sumBy(promotions, i => parseInt(i.o.t.q)) / parseInt(_.sumBy(promotions, i => parseInt(i.c.d.o)))) * 100)
                                            )).toFixed(2)
                                        }%
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={6}>
                    <PaddedPaper>
                        <BarChart
                            data={_.take(_.orderBy(_.map(this.state.promotions, i => ({
                                label: !i.p.s ? ( i.c.n.length > 20 ?  i.c.r :  i.c.n ) : i.p.c,
                                color: {
                                    Gross: colors.green,
                                },
                                value: {
                                    Gross: parseFloat(i.o.t.t),
                                },
                            })), i => i.value.Gross, 'desc'), 5)}
                            style={{height:'184px'}}
                            tLabel={i => {return `£${parseFloat(i.raw).toFixed(2)}`;}}
                            xLabel={i => `£${i}`}
                            stacked
                            sidebar
                            hideLegend
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <CiDataTable
                        config={{
                            key: 'idx',
                        }}
                        rows={_.orderBy(this.state.promotions, p => parseInt(p.p.i), 'desc')}
                        columns={[
                            {
                                heading: 'campaign',
                                field: i => 
                                    i.c.s ?
                                        <>
                                            <Typography variant="body2">{i.c.n}</Typography>
                                            <Typography variant="caption">{i.c.r}</Typography>
                                        </> : '-',
                                dataRef: 'c.i',
                                truncate: true,
                                sizeToContent: true,
                                info: i =>  
                                    <Grid container>
                                        <Grid item xs={5}>
                                            Runs:
                                        </Grid>
                                        <Grid item xs={7}>
                                            {i.c.d.r}
                                        </Grid>
                                        <Grid item xs={5}>
                                            Sent:
                                        </Grid>
                                        <Grid item xs={7}>
                                            {i.c.d.s}
                                        </Grid>
                                        <Grid item xs={5}>
                                            opened:
                                        </Grid>
                                        <Grid item xs={7}>
                                            {i.c.d.o}
                                        </Grid>
                                    </Grid>
                            },
                            {
                                heading: 'Promo',
                                field: i => 
                                    i.p.s ?
                                    <>
                                        <Typography variant="body2">{i.p.n}</Typography>
                                        <Typography variant="caption">{i.p.c}</Typography>
                                    </> : '-',
                                dataRef: 'p_name',
                                truncate: true,
                                sizeToContent: true,
                                info: i =>  i.p.s &&
                                    <Grid container>
                                        {(i.p.t[1] && moment(i.p.t[1]).isBefore(moment())) &&
                                            <Grid item xs={12} style={{color: colors.red}}>
                                                EXPIRED
                                            </Grid>
                                        }
                                        <Grid item xs={5}>
                                            Valid From:
                                        </Grid>
                                        <Grid item xs={7}>
                                            {clenyDate(i.p.t[0])}
                                        </Grid>
                                        <Grid item xs={5}>
                                            Valid To:
                                        </Grid>
                                        <Grid item xs={7}>
                                            {i.p.t[1] ? clenyDate(i.p.t[1]) : '-'}
                                        </Grid>
                                    </Grid>
                            },
                            {
                                heading: 'opened',
                                field: 'i.c.d.o',
                                dataRef: 'i.c.d.o',
                                sizeToContent: true,
                                alignment: 'center',
                            },
                            {
                                heading: '',
                                field: i => 
                                    <Typography variant="caption">
                                        <Grid container>
                                            <Grid item xs={12} style={{color: colors.green}}>
                                                From Campaigns
                                            </Grid>
                                            <Grid item xs={12} style={{color: colors.red}}>
                                                Outside Campaigns
                                            </Grid>
                                            <Grid item xs={12}>
                                                Total
                                            </Grid>
                                        </Grid>
                                    </Typography>,
                                sizeToContent: true,
                            },
                            {
                                heading: 'quotes',
                                field: i => 
                                    <Typography variant="caption">
                                        <Grid container>
                                            <Grid item xs={12} style={{color: colors.green}}>
                                                {i.q.i.q}
                                            </Grid>
                                            <Grid item xs={12} style={{color: colors.red}}>
                                                {i.q.o.q}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {i.q.t.q}
                                            </Grid>
                                        </Grid>
                                    </Typography>,
                                dataRef: 'i.q.t.q',
                                alignment: 'center',
                                sizeToContent: true,
                            },
                            {
                                heading: '',
                                field: i => 
                                    <Typography variant="caption">
                                        <Grid container>
                                            <Grid item xs={12} style={{color: colors.green}}>
                                                £{parseFloat(_.isFinite(parseFloat(i.q.i.t)) ? i.q.i.t : 0).toFixed(2)}
                                            </Grid>
                                            <Grid item xs={12} style={{color: colors.red}}>
                                                £{parseFloat(_.isFinite(parseFloat(i.q.o.t)) ? i.q.o.t : 0).toFixed(2)}
                                            </Grid>
                                            <Grid item xs={12}>
                                                £{parseFloat(_.isFinite(parseFloat(i.q.t.t)) ? i.q.t.t : 0).toFixed(2)}
                                            </Grid>
                                        </Grid>
                                    </Typography>,
                                dataRef: 'i.q.t.t',
                                alignment: 'center',
                                sizeToContent: true,
                            },
                            {
                                heading: 'orders',
                                field: i => 
                                    <Typography variant="caption">
                                        <Grid container>
                                            <Grid item xs={12} style={{color: colors.green}}>
                                                {i.o.i.q}
                                            </Grid>
                                            <Grid item xs={12} style={{color: colors.red}}>
                                                {i.o.o.q}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {i.o.t.q}
                                            </Grid>
                                        </Grid>
                                    </Typography>,
                                dataRef: 'i.o.t.q',
                                alignment: 'center',
                                sizeToContent: true,
                            },
                            {
                                heading: '',
                                field: i => 
                                    <Typography variant="caption">
                                        <Grid container>
                                            <Grid item xs={12} style={{color: colors.green}}>
                                                £{parseFloat(_.isFinite(parseFloat(i.o.i.t)) ? i.o.i.t : 0).toFixed(2)}
                                            </Grid>
                                            <Grid item xs={12} style={{color: colors.red}}>
                                                £{parseFloat(_.isFinite(parseFloat(i.o.o.t)) ? i.o.o.t : 0).toFixed(2)}
                                            </Grid>
                                            <Grid item xs={12}>
                                                £{parseFloat(_.isFinite(parseFloat(i.o.t.t)) ? i.o.t.t : 0).toFixed(2)}
                                            </Grid>
                                        </Grid>
                                    </Typography>,
                                dataRef: 'i.o.t.t',
                                alignment: 'center',
                                sizeToContent: true,
                            },
                            {
                                heading: 'convertion',
                                field: i => {
                                    let sent = parseInt(i.c.d.o);
                                    let sold = parseInt(i.o.t.q);

                                    if (sent < sold) return 100;
                                    if (sent === 0 || sold === 0) return 0;
                                    return (sold / sent) * 100
                                },
                                dataRef: i => {
                                    let sent = parseInt(i.c.d.o);
                                    let sold = parseInt(i.o.t.q);

                                    if (sent < sold) return 100;
                                    if (sent === 0 || sold === 0) return 0;
                                    return (sold / sent) * 100
                                },
                                sizeToContent: true,
                                alignment: 'center',
                                fieldFormat: 'percentage',
                            },
                            {
                                actions: i => ([
                                    {
                                        name: 'View',
                                        link: `/marketing/campaigns/emailCampaigns/${i.c.i}`,
                                        icon: IconHelper.search
                                    }
                                ]),
                                alignment: 'center',
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, size) => {
            dispatch(deployDialog(content, title, 'system', size))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        }
    }
}

export default connect(null, mapDispatchToProps)(CampaignPromotions);