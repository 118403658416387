import React, { Component} from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import API from '../../../../API';
import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DataTable from '../../../Common/DataTables/DataTable';
import moment from 'moment';
import {statusColour} from './../../../../Helpers/ColourHelper'


class SupplierStockReturns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            open: false
        }
    }

    componentDidMount(){
        // this.getPPOforPart();
    }

    getPPOforPart = () => {
        API.get(`/parts/${this.props.partId}/supplierStockReturns`)
        .then((result) => {
            _.map(result.data, (ssr) => {
                ssr.colour = statusColour(ssr.sr_return_type === 'Credit' ? ssr.sr_status : ssr.srd_delivery_status);
            });
            this.setState({
                rows: result.data,
                open: true
            })
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.searchResults !== prevProps.searchResults) {
            this.setState({
                rows: _.orderBy(this.props.searchResults, 'dco_date_added', 'desc')
            });
        }
    }

    handleOpen = () => {
        if(!this.state.open){
            this.getPPOforPart();
        } else {
            this.setState({
                open: false
            });
        }
    }

    render() {
        const { rows, open } = this.state;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={this.handleOpen}>
                    <Typography>Supplier Stock Returns</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {open && 
                        <>
                            {rows.length > 0 ? 
                                <DataTable 
                                    config={{
                                        key: 'sr_id',
                                        pagination: false,
                                        plainHeader: true,
                                        headerCellProps: (column, rows) => {
                                            return ({
                                                style: {
                                                    textAlign: (['Qty Returned', 'Qty Received'].includes(column.heading) && 'right'),
                                                }
                                            });
                                        }
                                    }}
                                    columns={[
                                        {
                                            heading: 'Stock Return Date',
                                            field: row => moment(row.sr_date).format("DD-MM-YYYY")
                                        },
                                        {
                                            heading: 'Reference',
                                            field: row => <Link to={`/parts-order/view/${row.sr_id}`} className='blueLink'>{row.sr_reference + '-' + row.sr_reference_amendment}</Link>
                                        },
                                        {
                                            heading: 'Return Type',
                                            field: row => row.sr_return_type
                                        },
                                        {
                                            heading: 'Status',
                                            field: row => row.sr_return_type === 'Credit' ? row.sr_status : row.srd_delivery_status,
                                            cellProps: rowData => {
                                                return ({
                                                    style: {
                                                        color: rowData.colour
                                                    }
                                                });
                                            }
                                        },
                                        {
                                            heading: 'Qty Returned',
                                            field: row => row.srd_quantity,
                                            alignment: 'right'
                                        },
                                        {
                                            heading: 'Qty Received',
                                            field: row => row.sr_return_type === 'Credit' ? 'N/A' : row.received_total_qty,
                                            alignment: 'right'
                                        }
                                    ]}
                                    rows={rows}
                                />
                            :
                                <Typography>There are 0 Supplier Stock Returns found.</Typography>
                            }
                        </>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default SupplierStockReturns;
