import React from 'react';
import { Grid, TextField } from '@material-ui/core/';
import EmailField from 'Components/Common/Inputs/EmailField';
import Textarea from 'Components/Common/Inputs/Textarea';
import { DialAdornment } from 'Functions/MiscFunctions';

export default function ({contact, classes}){
    return (
        <form noValidate autoComplete="off">
            <TextField
                label="Name"
                value={contact.contact_name}
                margin="normal"
                fullWidth
                disabled
                inputProps={{
                    className: classes.disabledText
                }}
                InputLabelProps={{
                    className: classes.disabledText
                }}
            />
            {!contact.contact_customer_id &&
                <TextField
                    label="Company"
                    value={contact.contact_company }
                    margin="normal"
                    fullWidth
                    disabled
                    inputProps={{
                        className: classes.disabledText
                    }}
                    InputLabelProps={{
                        className: classes.disabledText
                    }}
                />
            }
            <TextField
                label="Position"
                value={contact.contact_position}
                margin="normal"
                fullWidth
                disabled
                inputProps={{
                    className: classes.disabledText
                }}
                InputLabelProps={{
                    className: classes.disabledText
                }}
            />
            <EmailField
                label="Email"
                value={contact.contact_email}
                margin="normal"
                fullWidth
                disabled
                inputProps={{
                    className: classes.disabledText
                }}
                InputLabelProps={{
                    className: classes.disabledText
                }}
            />
            <Grid container item spacing={3}>
                <Grid item xs={12} lg={6}>
                    <TextField
                        label="Mobile"
                        value={contact.contact_mobile || '-'}
                        margin="normal"
                        fullWidth
                        disabled    
                        InputProps={{
                            startAdornment: <DialAdornment number={contact.contact_mobile} />
                        }}
                        inputProps={{
                            className: classes.disabledText
                        }}
                        InputLabelProps={{
                            className: classes.disabledText
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        label="Telephone"
                        value={contact.contact_telephone || '-'}
                        margin="normal"
                        fullWidth
                        disabled    
                        InputProps={{
                            startAdornment: <DialAdornment number={contact.contact_telephone} />
                        }}
                        inputProps={{
                            className: classes.disabledText
                        }}
                        InputLabelProps={{
                            className: classes.disabledText
                        }}
                    />
                </Grid>
            </Grid>
            {contact.contact_source !== 'Customer' &&
                <TextField
                    label="Source"
                    value={contact.contact_source}
                    margin="normal"
                    fullWidth
                    disabled
                    inputProps={{
                        className: classes.disabledText
                    }}
                    InputLabelProps={{
                        className: classes.disabledText
                    }}
                />
            }
            {contact.contact_source !== 'Customer' && contact.contact_source_data &&
                <Textarea
                    value={contact.contact_source_data}
                    fullWidth
                    disabled
                    inputProps={{
                        className: classes.disabledText
                    }}
                    InputLabelProps={{
                        className: classes.disabledText
                    }}
                    label="Source Notes"
                    margin="normal"
                />
            }
        </form>
    )
}