import { Button, Grid, InputAdornment, TextField } from '@material-ui/core';
import API from 'API';
import { closeDialog } from 'Actions/Dialog/Dialog';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

const initialState = {
    isLoading: false,
    formData: {
        paymentAmount:    0.00,
        outStanding:      0.00,
        request:          0.00,
        requestRemaining: 0.00,
        requestAmount:    0.00,
        requestTotal:     0.00,
        date:             moment().format('YYYY-MM-DD'),
    },
    payments: [],
    formErrors: {},
}

class AssignOrderPaymentDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState, 
            formData: {
                ...initialState.formData, 
                paymentAmount:    props.invoice.amount_outstanding, 
                outStanding:      props.invoice.amount_outstanding,
                requestRemaining: props.availableAmount - props.invoice.amount_outstanding,
                requestAmount:    props.availableAmount,
                requestTotal:     props.availableAmount,
            }
        };
    }


    handleAmountChange = (toFixed=false) => e => {
        const { value } = e.target;

        this.setState(state => ({
            ...state,
            formData: {
                ...state.formData,
                paymentAmount:      toFixed ? parseFloat(value).toFixed(2) : value,
                requestRemaining:   parseFloat(this.state.formData.requestTotal - value).toFixed(2),
            }
        }));
    }


    submit = () => {
        API.post(`/sales/orders/invoice/${this.props.invoice.invoice_id}/processPayment`, {
            invoice:          this.props.invoice.invoice_id,
            requestedPayment: this.state.formData.request,
            paymentAmount:    this.state.formData.paymentAmount,
            paymentType:      'Assign',
            paymentMethod:    'fromOrder',
            date:             this.state.formData.date,
        })
        .then(res => {
            if (res.data.errors){
                this.setState({ formErrors: formatValidationErrors(res.data.errors) });
            } else {
                this.props.closeDialog();
                this.props.callback();
            }
        })
    }

    handleDateChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                date: moment(e).format('YYYY-MM-DD')
            }
        });
    }

    render() {
        const { isLoading, formData, formErrors } = this.state;

        if ( isLoading ) return ( <LoadingCircle /> );

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Payment Outstanding"
                        name="outStanding"
                        value={formData.outStanding}
                        disabled
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Payment Amount *"
                        name="paymentAmount"
                        type='number'
                        value={formData.paymentAmount}
                        onChange={this.handleAmountChange(false)}
                        onBlur={this.handleAmountChange(true)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                            endAdornment: <InputAdornment position="start">(Available: £{this.props.availableAmount})</InputAdornment>
                        }}
                        fullWidth
                        error={!!formErrors['paymentAmount']}
                        helperText={formErrors['paymentAmount']}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Order Payment Remaining"
                        name="requestRemaining"
                        value={parseFloat(formData.requestRemaining).toFixed(2)}
                        disabled
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Invoice Remaining"
                        name="invoiceRemaining"
                        value={parseFloat(formData.outStanding - formData.paymentAmount).toFixed(2)}
                        disabled
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        type="date"
                        id="date"
                        name="date"
                        label="Payment Date *"
                        value={formData.date}
                        onChange={this.handleDateChange}
                        autoOk={true}
                    />
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button 
                        variant='outlined'
                        onClick={this.props.closeDialog}
                    >Close</Button>
                    <Button 
                        variant='contained' 
                        color='primary'
                        disabled={ (formData.outStanding - formData.paymentAmount) < 0 || formData.requestRemaining < 0 || formData.paymentAmount <= 0  }
                        onClick={this.submit}
                    >Assign Payment</Button>
                </Grid>
            </Grid>
        );

    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(closeDialog()),
})

export default connect(null, mapDispatchToProps)(AssignOrderPaymentDialog);