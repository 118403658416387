import { Button, Grid, Typography } from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { checkMailingListContacts } from 'Helpers/EmailHelper';
import InputHelper from 'Helpers/InputHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { colors } from 'Helpers/ColourHelper';

const initialState = (props) => ({
    formData: {
        customer: null,
        contact: null
    },
    lists: props.lists,
    existingContacts: props.existingContacts,
    error: null,
    warning: null,
    isLoading: false,
})

class AddContactsSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
        this.inputHelper = new InputHelper(this, 'formData', this.checkContact);
    }

    checkContact = async () => {
        await this.setState({isLoading:true}, async ()=>{
            if (_.find(this.state.lists.contacts, i => i.value === this.state.formData.contact )?.customer !== this.state.formData.customer){
                this.setState({
                    formData: {
                        ...this.state.formData,
                        contact: null
                    }
                })
            }
    
            if (this.state.formData.contact) {
                let check = await checkMailingListContacts([_.find(this.state.lists.contacts, i => i.value === this.state.formData.contact ).contact], this.state.existingContacts, this.props.lists.emailValidation)
    
                let error = null;
                let warning = null;
    
                switch(1){
                    case check?.breakdown.na:
                        error = 'This contact is not eligible to be added to the mailing list.';
                        break;
                    case check?.breakdown.warning?.length:
                        warning = 'This contact may have a faulty email address.';
                        break;
                    case check?.breakdown.invalid?.length:
                        error = 'This contact has a faulty email address.';
                        break;
                    case check?.breakdown.inListDuplicate?.length:
                    case check?.breakdown.outListDuplicate?.length:
                        error = 'This contact is already in the mailing list.';
                        break;
                }
                this.setState({ error, warning, isLoading: false })
            }    
        })
    }

    submit = () => {
        let contact = _.find(this.state.lists.contacts, i => i.value === this.state.formData.contact ).contact;
        API.post(`/marketing/campaigns/mailingLists/${this.props.mailingList.i}/addContacts`,{contacts: JSON.stringify([
            {
                contact_id: contact.contact_id,
                contact_name: contact.contact_name,
                contact_email: contact.contact_email,
            }
        ])}).then(this.props.refresh)
    }

    render() {

        const { lists, formData, error, warning, isLoading } = this.state;

        return (
            <Grid container spacing={3} style={{padding: '1em'}}>
                <Grid item xs={12}>
                    <AutoCompleteSelect
                        label='Customer'
                        options={lists.customers}
                        value={formData.customer}
                        fullWidth
                        onChange={this.inputHelper.handleSelectChange('customer')}
                    />
                </Grid>
                {
                    formData.customer &&
                    <Grid item xs={12}>
                        <AutoCompleteSelect
                            label='Contact *'
                            options={_.filter(formData.customer ? _.filter(lists.contacts, {customer: formData.customer}) : lists.contacts, c => !c?.addresses || (c?.addresses?.length > 1 || (
                                c.addresses.length == 1 && c.addresses[0].address_type !== 'Accounts')
                            ))}
                            value={formData.contact}
                            fullWidth
                            onChange={this.inputHelper.handleSelectChange('contact')}
                        />
                    </Grid>
                }
                {error &&
                    <Grid item xs={12}>
                        <Typography style={{color: colors.red, textAlign: 'right', width: '100%'}}>
                            {error}
                        </Typography>
                    </Grid>
                }
                {warning &&
                    <Grid item xs={12}>
                        <Typography style={{color: colors.orange, textAlign: 'right', width: '100%'}}>
                            {warning}
                        </Typography>
                    </Grid>
                }
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant='outlined'
                        onClick={this.props.refresh}
                    >Close</Button>
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={!formData.contact || !formData.customer || error || isLoading }
                        onClick={
                            () => this.props.deployConfirmation(
                                'Are you sure you want to add this contact to the mailing list?',
                                'Add Contact?',
                                this.submit
                            )   
                        }
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
        )
    }


}

const mapDispatchToProps = dispatch => ({
    deployConfirmation:     (body, title, callback)     => dispatch(deployConfirmation(body, title, callback)),
})


export default connect(null, mapDispatchToProps)(AddContactsSearch);