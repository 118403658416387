import React from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import InputHelper from 'Helpers/InputHelper';
import _ from 'lodash';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import CheckBox from 'Components/Common/Inputs/CheckBox';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { colors } from 'Helpers/ColourHelper'

const initialState = (props) => ({
    isLoading: true,
    formData: {
        customerId: null,
        contactId: null,
        emailId: props.emailId,
        manual: false,
        email: null,
        name: null,
    },
    lists: {
        customers: [],
        contacts: [],
    }
})

class ForwardEmailDialog extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState(props);
        this.inputHelper = new InputHelper(this);
    }

    componentDidMount(){
        this.getContacts();
    }

    getContacts = () => {
        API.get(
            '/marketing/campaigns/mailingLists/eligibleContacts',
            {
                params: {
                    fullData: true
                }
            }
        ).then(res => {
            this.setState({
                lists: {
                    customers: [{value: 'all', label: 'All'},..._.uniqBy(_.map(res.data, c => ({
                        value: c?.customer?.cust_id || 'manual',
                        label: c?.customer?.cust_name || 'manual'
                    })), 'value')],
                    contacts: _.map(res.data, c => ({
                        value: c.contact_id,
                        label: `${c.contact_name} (${c.contact_email})`,
                        customer: c?.customer?.cust_id  || 'manual',
                        contact: c
                    }))
                },
                isLoading: false
            })
        })
    }

    forwardEmail = () => {
        API.post(`/email/${this.props.emailId}/forward`, this.state.formData)
        .then(r => {
            this.props.onSuccess && this.props.onSuccess();
        })
    }

    render(){
        const { isLoading, formData, lists } = this.state;

        if (isLoading){
            return <LoadingCircle />
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CheckBox value={formData.manual} onChange={() => this.inputHelper.handleValueChange('formData.manual')(!formData.manual)} label="Contact Not Listed?" color='primary' />
                </Grid>
                {!formData.manual && 
                    <>
                        <Grid item xs={12}>
                            <AutoCompleteSelect
                                label="Customer"
                                value={formData.customerId}
                                onChange={this.inputHelper.handleSelectChange('formData.customerId')}
                                options={lists.customers}
                                fullWidth
                                noClear
                            />
                        </Grid>
                        { formData.customerId && 
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    label="Contact *"
                                    value={formData.contactId}
                                    onChange={this.inputHelper.handleSelectChange('formData.contactId')}
                                    options={_.filter(lists.contacts, c => c.customer === formData.customerId)}
                                    fullWidth
                                    noClear
                                />
                            </Grid>
                        }
                    </>
                }
                {
                    formData.manual &&
                    <>
                        <Grid item xs={12}>
                            <TextField
                                label="Name *"
                                value={formData.name}
                                name='formData.name'
                                onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email *"
                                value={formData.email}
                                name='formData.email'
                                onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                fullWidth
                            />
                        </Grid>
                    </>
                }
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        onClick={this.props.onClose}
                        variant="outlined"
                    >
                        Close
                    </Button>
                    <Button
                        onClick={()=>this.props.deployConfirmation(
                            'Are you sure you want to forward this email?',
                            <Typography variant='h6' style={{color: colors.black}}>Forward Email</Typography>,
                            this.forwardEmail
                        )}
                        variant="contained"
                        color="primary"
                        disabled={
                            formData.manual ?
                                ( !formData.name || !formData.email ) :
                                ( !formData.contactId )
                        }
                    >Forward</Button>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
})

export default connect(null, mapDispatchToProps)(ForwardEmailDialog);