import React, { PureComponent } from 'react';
import _                        from 'lodash';
import API                      from 'API';

import { Grid, Typography } from '@material-ui/core/';

import DataTable     from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';
import AllIcon       from 'Components/Common/Icons/AllIcon';
import InfoIcon      from 'Components/Common/Icons/InfoIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import DateRangeDialog from './DateRangeDialog';

import { colors }                 from 'Helpers/ColourHelper';
import icons                      from 'Helpers/IconHelper';
import { colorNumbers, currency } from 'Helpers/NumberHelper';
import {getFiscalYearTimestamps}  from 'Helpers/ReportsHelper';

const initialState = { 
    filter:      {
        type:       'fyear',      
        from:       getFiscalYearTimestamps().start.format('YYYY-MM-DD'), 
        to:         getFiscalYearTimestamps().end.format('YYYY-MM-DD'), 
    },
    formErrors:  {},
    data:        [],
    isLoading:   true,
 }

 const InfoBlock = ({title, icon, value, xs=4}) => {
    return (
        <Grid item style={{width: '20%'}}>
            <PaddedPaper  style={{textAlign: 'center'}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}><Typography variant="h6" style={{textAlign: 'center'}}>{title}</Typography></Grid>
                    <Grid item xs={12}><AllIcon icon={icon} noMargin size='large' /></Grid>
                    <Grid item xs={12}><Typography variant="h6"  style={{textAlign: 'center'}}>{value}</Typography></Grid>
                </Grid>
            </PaddedPaper>
        </Grid>
    )
} 

class StockReportsCosts extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getData();
    }

    handleSetFilterParams = (filter) => { this.setState({filter, isLoading: true}, this.getData)} 

    getData = () => {
        API.get('/reports/stocksReport/costs', {params: {...this.state.filter, partId: this.props.partId}})
        .then(res => {
            this.setState({
                data: _.filter(res.data, i => i.w), 
                isLoading: false
            });
        })
    }

    render() {
        const { filter, formErrors, data, isLoading } = this.state;

        return (
            <Grid container xs={12} spacing={2} style={{width: '100%'}}>
               <Grid item xs={12}>
                    <PaddedPaper style={{height: '100%'}}>
                        <DateRangeDialog 
                            callback={this.handleSetFilterParams} 
                            error={formErrors} 
                            formData={filter}
                            inline
                        />
                    </PaddedPaper>
                </Grid>
                {isLoading ?
                    <Grid item xs={12}><LoadingCircle/></Grid>:
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <InfoBlock 
                                    title='Opening Stock' 
                                    icon={icons.stock} 
                                    value={<>
                                        <AllIcon icon={icons.parts}/>{colorNumbers(data.length)}<br/>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(data, r => parseFloat(r.f.q) - parseFloat(parseFloat(r.j.q) + parseFloat(r.k.q))).toFixed(3))}
                                    </>}
                                />
                                <InfoBlock 
                                    title='Incoming' 
                                    icon={icons.stock} 
                                    value={<>
                                        <AllIcon icon={icons.parts}/>{colorNumbers(_.filter(data, r => parseFloat(r.j.c) > 0).length)}<br/>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(data,  r => parseFloat(r.j.q) ).toFixed(3))}
                                    </>}
                                />
                                <InfoBlock 
                                    title='Outgoing' 
                                    icon={icons.stock} 
                                    value={<>
                                        <AllIcon icon={icons.parts}/>{colorNumbers(_.filter(data, r => parseFloat(r.k.c) < 0).length)}<br/>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(data,  r => parseFloat(r.k.q) ).toFixed(3))}
                                    </>}
                                />
                                <InfoBlock 
                                    title='Closing Stock' 
                                    icon={icons.stock} 
                                    value={<>
                                        <AllIcon icon={icons.parts}/>{colorNumbers(data.length)}<br/>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(data, r => parseFloat(r.f.q)).toFixed(3))}
                                    </>}
                                />
                                <InfoBlock 
                                    title='Change' 
                                    icon={icons.stock} 
                                    value={<>
                                        <AllIcon icon={icons.parts}/>{colorNumbers(data.length)}<br/>
                                        <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(data, r => parseFloat(r.j.q) + parseFloat(r.k.q)).toFixed(3))}
                                    </>}
                                />
                                <InfoBlock 
                                    title='Opening Stock' 
                                    icon={icons.poundSign} 
                                    value={currency(_.sumBy(data, r => parseFloat(r.f.c) - parseFloat(parseFloat(r.j.c) + parseFloat(r.k.c))),3,false)}
                                />
                                <InfoBlock 
                                    title='Incoming' 
                                    icon={icons.poundSign} 
                                    value={currency(_.sumBy(data, r => parseFloat(r.j.c)),3,false)}
                                />
                                <InfoBlock 
                                    title='Outgoing' 
                                    icon={icons.poundSign} 
                                    value={currency(_.sumBy(data, r => parseFloat(r.k.c)),3,false)}
                                />
                                <InfoBlock 
                                    title='Closing Stock' 
                                    icon={icons.poundSign} 
                                    value={currency(_.sumBy(data, r => parseFloat(r.f.c)),3,false)}
                                />
                                <InfoBlock 
                                    title='Change' 
                                    icon={icons.poundSign} 
                                    value={currency(_.sumBy(data, r => parseFloat(parseFloat(r.j.c) + parseFloat(r.k.c))),3,false)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop: '1.5em'}}> 
                            <DataTable 
                                config={{
                                    key: 'q',
                                    pagination: true,
                                    responsiveimportant: true,
                                    rowsPerPage: 25,
                                    filter: true,
                                    filterMod: {
                                        filterInPagination:     true,
                                        clearable:              true,
                                        colSpan:                2,
                                    },
                                    sticky:true,
                                    dropRow: {
                                        droppable: true,
                                        style: {fontWeight: 'bold'},
                                        columns: [
                                            {
                                                colSpan: 8,
                                                field: r => 
                                                    <Grid container spacing={2}>
                                                        <InfoBlock 
                                                            xs={2}
                                                            title={<>SSR <InfoIcon info='Supplier Stock Return'/></>} 
                                                            icon={icons.ssr} 
                                                            value={
                                                                <>
                                                                    <AllIcon icon={icons.qty}/>{colorNumbers( parseFloat(r.y.q).toFixed(3) )}<br/>
                                                                    {currency(r.y.c)}
                                                                </>
                                                            }
                                                        />
                                                        <InfoBlock 
                                                            xs={2}
                                                            title={<>PPO <InfoIcon info='Part Purchase Order'/></>} 
                                                            icon={icons.partsOrdering} 
                                                            value={
                                                                <>
                                                                    <AllIcon icon={icons.qty}/>{colorNumbers( parseFloat(r.u.q).toFixed(3) )}<br/>
                                                                    {currency(r.u.c)}
                                                                </>
                                                            }
                                                        />
                                                        <InfoBlock 
                                                            xs={2}
                                                            title={<>Sales</>} 
                                                            icon={icons.sales} 
                                                            value={
                                                                <>
                                                                    <AllIcon icon={icons.qty}/>{colorNumbers( parseFloat(r.i.q).toFixed(3) )}<br/>
                                                                    {currency(r.i.c)}
                                                                </>
                                                            }
                                                        />
                                                        <InfoBlock 
                                                            xs={2}
                                                            title={<>Returns <InfoIcon info='Credit Notes & Greenaware'/></>} 
                                                            icon={icons.returns} 
                                                            value={
                                                                <>
                                                                    <AllIcon icon={icons.qty}/>{colorNumbers( parseFloat(r.o.q).toFixed(3) )}<br/>
                                                                    {currency(r.o.c)}
                                                                </>
                                                            }
                                                        />
                                                        <InfoBlock 
                                                            xs={2}
                                                            title={<>WOC <InfoIcon info='Works Order Component'/></>} 
                                                            icon={icons.worksOrder} 
                                                            value={
                                                                <>
                                                                    <AllIcon icon={icons.qty}/>{colorNumbers( parseFloat(r.a.q).toFixed(3) )}<br/>
                                                                    {currency(r.a.c)}
                                                                </>
                                                            }
                                                        />
                                                        <InfoBlock 
                                                            xs={2}
                                                            title={<>WOB <InfoIcon info='Works Order Build'/></>} 
                                                            icon={icons.worksOrder} 
                                                            value={
                                                                <>
                                                                    <AllIcon icon={icons.qty}/>{colorNumbers( parseFloat(r.p.q).toFixed(3) )}<br/>
                                                                    {currency(r.p.c)}
                                                                </>
                                                            }
                                                        />  
                                                        <InfoBlock 
                                                            xs={2}
                                                            title={<>Movements</>} 
                                                            icon={icons.stock} 
                                                            value={
                                                                <>
                                                                    <AllIcon icon={icons.qty}/>{colorNumbers( parseFloat(r.s.q).toFixed(3) )}<br/>
                                                                    {currency(r.s.c)}
                                                                </>
                                                            }
                                                        />  
                                                        <InfoBlock 
                                                            xs={2}
                                                            title={<>Corrections</>} 
                                                            icon={icons.stock} 
                                                            value={
                                                                <>
                                                                    <AllIcon icon={icons.qty}/>{colorNumbers( parseFloat(r.d.q).toFixed(3) )}<br/>
                                                                    {currency(r.d.c)}
                                                                </>
                                                            }
                                                        />  
                                                        <InfoBlock 
                                                            xs={2}
                                                            title={<>Incoming</>} 
                                                            icon={icons.plus} 
                                                            value={
                                                                <>
                                                                    <AllIcon icon={icons.qty}/>{colorNumbers( parseFloat(r.j.q).toFixed(3) )}<br/>
                                                                    {currency(r.j.c)}
                                                                </>
                                                            }
                                                        />  
                                                        <InfoBlock 
                                                            xs={2}
                                                            title={<>Outgoing</>} 
                                                            icon={icons.minus} 
                                                            value={
                                                                <>
                                                                    <AllIcon icon={icons.qty}/>{colorNumbers( parseFloat(r.k.q).toFixed(3) )}<br/>
                                                                    {currency(r.k.c)}
                                                                </>
                                                            }
                                                        />  
                                                        <InfoBlock 
                                                            xs={2}
                                                            title={<>Change</>} 
                                                            icon={icons.plusMinus} 
                                                            value={
                                                                <>
                                                                    <AllIcon icon={icons.qty}/>{parseFloat(parseFloat(Math.abs(r.j.q)) - parseFloat(Math.abs(r.k.q))).toFixed(3)}<br/>
                                                                    {currency(parseFloat(Math.abs(parseFloat(Math.abs(r.j.c)) - parseFloat(Math.abs(r.k.c)))))}
                                                                </>
                                                            }
                                                        />  
                                                        <InfoBlock 
                                                            xs={2}
                                                            title={<>Stock</>} 
                                                            icon={icons.stock} 
                                                            value={
                                                                <>
                                                                    <AllIcon icon={icons.qty}/>{colorNumbers( parseFloat(r.f.q).toFixed(3) )}<br/>
                                                                    {currency(r.f.c)}
                                                                </>
                                                            }
                                                        />  
                                                    </Grid>
                                            }
                                        ]
                                    }
                                }}
                                columns={_.filter([
                                    {
                                        field:        'r',
                                        fieldFormat: 'image',
                                        sizeToContent: true,
                                        filter:         'custom',
                                        filterMod: {
                                            dataRef:   (search, rowData) => {return `${rowData.w}|${rowData.e}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                            label:     'Search Parts'
                                        }
                                    },
                                    {
                                        heading:  'Part/Description',
                                        field:    i => <>{i.w}<br/>{i.e}</>,
                                        truncate: true,
                                    },
                                    {
                                        heading:       'Cost Per',
                                        field:         't',
                                        fieldFormat:   'currencyCost',
                                        sizeToContent: true,
                                        alignment:     'left'
                                    },
                                    {
                                        heading:       <>Opening <br/> Stock</>,
                                        field:         r => <>{parseFloat(r.f.q - parseFloat(parseFloat(Math.abs(r.j.q)) - parseFloat(Math.abs(r.k.q)))).toFixed(3)}<br/>
                                        {`${parseFloat(r.f.c - parseFloat(parseFloat(Math.abs(r.j.c)) - parseFloat(Math.abs(r.k.c)))) < 0 ? '-' : ''}£${parseFloat(Math.abs(r.f.c - parseFloat(parseFloat(Math.abs(r.j.c)) - parseFloat(Math.abs(r.k.c))))).toFixed(3)}`}</>,
                                        dataRef:       'f.c',
                                        alignment:     'right',
                                        sizeToContent: true,
                                        style:         r => ({color:  parseFloat(r.f.c - parseFloat(parseFloat(Math.abs(r.j.c)) - parseFloat(Math.abs(r.k.c)))) === 0 ? colors.black :  ( parseFloat(r.f.c - parseFloat(parseFloat(Math.abs(r.j.c)) - parseFloat(Math.abs(r.k.c)))) < 0 ? colors.red : colors.green )})
                                    },
                                    {
                                        heading:       <>Stock +</>,
                                        field:         r => <>{parseFloat(r.j.q).toFixed(3)}<br/>{`${r.j.c < 0 ? '-' : ''}£${parseFloat(Math.abs(r.j.c)).toFixed(3)}`}</>,
                                        dataRef:       'j.c',
                                        alignment:     'right',
                                        sizeToContent: true,
                                        style:         r => ({color:  r.j.c === 0 ? colors.black :  ( r.j.c < 0 ? colors.red : colors.green )})
                                    },
                                    {
                                        heading:       <>Stock -</>,
                                        field:         r => <>{parseFloat(r.k.q).toFixed(3)}<br/>{`${r.k.c < 0 ? '-' : ''}£${parseFloat(Math.abs(r.k.c)).toFixed(3)}`}</>,
                                        dataRef:       'k.c',
                                        alignment:     'right',
                                        sizeToContent: true,
                                        style:         r => ({color:  r.k.c === 0 ? colors.black :  ( r.k.c < 0 ? colors.red : colors.green )})
                                    },
                                    {
                                        heading:       <>Closing <br/> Stock</>,
                                        field:         r => <>{parseFloat(r.f.q).toFixed(3)}<br/>{`${r.f.c < 0 ? '-' : ''}£${parseFloat(Math.abs(r.f.c)).toFixed(3)}`}</>,
                                        dataRef:       'f.c',
                                        alignment:     'right',
                                        sizeToContent: true,
                                        style:         r => ({color:  r.f.c === 0 ? colors.black :  ( r.f.c < 0 ? colors.red : colors.green )})
                                    },
                                    {
                                        heading:       <>Change</>,
                                        field:         r => <>{parseFloat(parseFloat(Math.abs(r.j.q)) - parseFloat(Math.abs(r.k.q))).toFixed(3)}<br/>{`${r.j.c < 0 ? '-' : ''}£${parseFloat(Math.abs(parseFloat(Math.abs(r.j.c)) - parseFloat(Math.abs(r.k.c)))).toFixed(3)}`}</>,
                                        dataRef:       r => parseFloat(Math.abs(r.j.c)) - parseFloat(Math.abs(r.k.c)),
                                        alignment:     'right',
                                        sizeToContent: true,
                                        style:         r => ({color:  parseFloat(Math.abs(r.j.c)) - parseFloat(Math.abs(r.k.c)) === 0 ? colors.black :  ( parseFloat(Math.abs(r.j.c)) - parseFloat(Math.abs(r.k.c)) < 0 ? colors.red : colors.green )})
                                    },
                                    {actions: r => ([
                                        {
                                            name: 'View',
                                            icon: icons.search,
                                            link: `/parts/view/${r.q}`,
                                        }
                                    ])} 
                                ], i => !!i)}
                                rows={_.orderBy(_.filter(data, i => i.w), 'w', 'asc')} 
                            />
                        </Grid>
                    </>
                }
            </Grid>
        )
    }
};

export default StockReportsCosts;

