import React, { Component } from 'react';
import _                    from 'lodash';
import moment               from 'moment';
import API                  from 'API';
import { Redirect }         from 'react-router-dom';

import { Button, FormControl, Grid, IconButton, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FALightIcon        from 'Components/Common/Icons/FontAwesome/FALightIcon';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import SnackBar           from 'Components/Common/SnackBars/SnackBar';
import Textarea           from 'Components/Common/Inputs/Textarea';
import BackButton         from 'Components/Common/Buttons/BackButton';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import {colors}                   from 'Helpers/ColourHelper';

const initialState = {
    formData:  {
        orderRows: [
            {
                detailId: '',
                description: '',
                quantity: '',
                unitPrice: '',
                totalPrice: '',
                nominalList: [],
            }
        ],
        overallPrice: 0
    },
    po: {},
    defaultItemsList: [],
    defaultItems: {},
    formErrors: [],
    nominalList: [],
    confirmationOpen: false,
    snackbarOpen: false,
    redirect: false,
    successRedirect: false,
    successRedirectID: 0
}

const styles = theme => ({
    td : {
        border: 'none'
    },
    [theme.breakpoints.down('xs')]: {
        responsiveTable: {
            maxWidth: '100%', 
            overflowX: 'auto'
        }
    }
});

class UpdatePurchaseOrder extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getPO();
    }

    getPO = () => {
        Promise.all([
            API.get('/purchaseOrders/defaultList'),
            API.get('/purchaseOrders/' + this.props.match.params.id),
            API.get('/accounts/nominalCodes')
        ])
        .then(([defaultItems, result, nominalRes]) => {
            let orderRows = [];
            let defaultItemsList = [];
            var nominalList = [];

            if (result.data.errors) {
                this.setState({ redirect: true });
            } else if(result.data) {
                result.data.order_details.forEach((row) => {
                    
                    let otherItem = _.find(defaultItems.data, {'po_list_description': row.po_detail_description });
                    if(otherItem && otherItem.nominal_codes){
                        nominalList =  _.map(otherItem.nominal_codes, (el) => {
                            return _.assign({
                                value: el.nominal_id,
                                label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                            });
                        });
                    }
                    orderRows.push({
                        detailId: row.po_detail_id,
                        description: row.po_detail_description,
                        nominalCode: row.nominal_code && row.nominal_code.nominal_id,
                        quantity: parseFloat(row.po_detail_qty).toFixed(2),
                        unitPrice: parseFloat(row.po_detail_unit_price).toFixed(2),
                        totalPrice: parseFloat(row.po_detail_total_price).toFixed(2),
                        nominalList: nominalList
                    });
                });
            }
            if(defaultItems.data) {
                defaultItemsList = _.map(defaultItems.data, (el) => {
                    return _.assign({
                        value: el.po_list_description,
                        label: el.po_list_description
                    });
                });
            }
            if(nominalRes.data) {
                nominalList = _.map(nominalRes.data, (el) => {
                    return _.assign({
                        value: el.nominal_id,
                        label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                    });
                });
            }
            this.setState({
                formData: {
                    ...this.state.formData,
                    orderRows: orderRows 
                },
                po: result.data,
                defaultItemsList: defaultItemsList,
                defaultItems: defaultItems.data,
                nominalList: nominalList,
            },
            () => {
                this.calculateOverallTotals();
            });
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    }

    handleAddRow = fieldName => t => {
        const item = initialState.formData[fieldName][0];
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: [...this.state.formData[fieldName], item]
            }
        });
    }

    handleRowChange = (idx, array, decimals) => e => {
        const { name, value } = e.target;
        let newQty =  [...this.state.formData[array]];
        let newVal = decimals ? parseFloat(value).toFixed(decimals) : value;

        newQty[idx] = {
            ...newQty[idx],
            [name]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                [array]: newQty 
            }
        },
        () => {
            this.calculatePrice(idx, array);
        });
    };

    handleSelectRowChange = (idx, fieldName, array) => selectedOption => {

        let data =  [...this.state.formData[array]];
        let newVal = selectedOption && selectedOption.value;

        data[idx] = {
            ...data[idx],
            [fieldName]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                [array]: data
            }
        },
        () => {
            if(fieldName === 'description'){
                let data =  [...this.state.formData[array]];
                var nominalList = [];
                let otherItem = {};

                if(selectedOption){
                    otherItem = _.find(this.state.defaultItems, {'po_list_description': selectedOption && selectedOption.value });

                    nominalList = _.map(otherItem.nominal_codes, (el) => {
                        return _.assign({
                            value: el.nominal_id,
                            label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                        });
                    });
                }
                data[idx] = {
                    ...data[idx],
                    nominalCode: (otherItem && otherItem.nominal_code && otherItem.nominal_code.nominal_id) || '',
                    nominalList: nominalList
                };
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [array]: data
                    }
                });
            }
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleRemoveRow = (idx, array) => () => {
        let rows = [...this.state.formData[array]]
        rows.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                [array]: rows
            }
        },
        () => {
            this.calculatePrice(idx, array);
        })
    }

    calculatePrice = (idx, array) => {
        let data =  [...this.state.formData[array]];
        if(data[idx]){
            data[idx] = {
                ...data[idx],
                totalPrice: data[idx] ? (data[idx].unitPrice * data[idx].quantity).toFixed(2) : (0).toFixed(2)
            };
            // Set new total price before setting overall price
            this.setState({
                formData: {
                    ...this.state.formData,
                    [array]: data
                }
            }, 
            () => {
                this.calculateOverallTotals();
            });
        }
        else {
            // Remove row - just calculate overall totals
            this.calculateOverallTotals();
        }
    }

    calculateOverallTotals = () => {
        let overallPrice = 0;
        this.state.formData.orderRows.forEach((row) => {
            if(!isNaN((row.totalPrice*1))){
                overallPrice = overallPrice + (row.totalPrice*1);
            }
        });
        this.setState({
            formData: {
                ...this.state.formData,
                overallPrice: overallPrice.toFixed(2)
            }
        });
    }

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false
        });
        this.submit();
    }
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    submit = () => {
        API.post(`/purchaseOrders/${this.props.match.params.id}/update`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){
                
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    successRedirect: true,
                    successRedirectID: result.data.poId
                });
            }
            this.props.scrollToTop();
        });
    }

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    }
       
    render() {
        const { classes } = this.props;
        const { formErrors, po } = this.state;
        if (this.state.successRedirect === true) {
            return <Redirect 
                        to={{
                            pathname:`/purchase-order/view/${this.state.successRedirectID}`,
                            state: {successMsg: 'You have successfully amended this purchase order'}
                        }}
                    />
        } else if (this.state.redirect === true) {
            return <Redirect to="/purchase-order/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Edit Other Items
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1">
                                        Purchase Order Number
                                        </Typography>
                                    </Grid>
                                        <Grid item xs={12} sm={8}>
                                        <Typography variant="subtitle1">
                                            {po.full_reference}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className='xs-only'/>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1" gutterBottom>
                                        FAO
                                        </Typography>
                                    </Grid>
                                        <Grid item xs={12} sm={8}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {po.po_supplier_contact}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className='xs-only'/>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1" gutterBottom>
                                        FAO Email
                                        </Typography>
                                    </Grid>
                                        <Grid item xs={12} sm={8}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {po.supplier_contact && po.supplier_contact.supp_cont_email}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className='xs-only'/>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1" gutterBottom>
                                        Purchase Order Date
                                        </Typography>
                                    </Grid>
                                        <Grid item xs={12} sm={8}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {moment(po.po_date).format('DD/MM/YYYY')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className='xs-only'/>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1" gutterBottom>
                                        Order Date
                                        </Typography>
                                    </Grid>
                                        <Grid item xs={12} sm={8}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {moment(po.po_order_date).format('DD/MM/YYYY')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className='xs-only'/>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Order Placed By
                                        </Typography>
                                    </Grid>
                                        <Grid item xs={12} sm={8}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {po.po_placed_by}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className='xs-only'/>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1" gutterBottom>
                                        Payment Terms
                                        </Typography>
                                    </Grid>
                                        <Grid item xs={12} sm={8}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {po.po_supplier_payment_terms}
                                        </Typography>
                                    </Grid>
                                    {po.po_type_id === 1 && 
                                    <React.Fragment>
                                        <Grid item xs={12} className='xs-only'/>
                                        <Grid item xs={12} sm={4}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Attendance Date
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                {moment(po.po_attendance_date).format('DD/MM/YYYY')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className='xs-only'/>
                                        <Grid item xs={12} sm={4}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Job Reference
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                {po.po_job_reference}
                                            </Typography>
                                        </Grid>
                                    </React.Fragment>
                                    }
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <PaddedPaper>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1">
                                            Company
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Typography variant="subtitle1">
                                            {po.po_supplier_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className='xs-only'/>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Address
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {po.po_supplier_address && po.po_supplier_address.split('\n').map((item, key) => {
                                                return <span key={key}>{item}<br/></span>
                                            })}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant="h6" gutterBottom>
                                Order Items
                            </Typography>
                            <div className={classes.responsiveTable}>
                                <Table style={{minWidth: 900}}>
                                    <TableBody>
                                        {this.state.formData.orderRows.map((item, idx) => {
                                            return (
                                                <React.Fragment key={`or_${idx}`}>
                                                    <TableRow>
                                                        <TableCell className={classes.td} style={{width:'30%'}}>
                                                            {po.po_type_id === 2 ?
                                                                <TextField
                                                                    name="description"
                                                                    value={item.description}
                                                                    label="Description *"
                                                                    error={formErrors && formErrors['orderRows|description|'+idx] && true}
                                                                    helperText={formErrors && formErrors['orderRows|description|'+idx]}
                                                                    onChange={this.handleRowChange(idx, 'orderRows')}
                                                                    fullWidth
                                                                />
                                                            :
                                                                <FormControl fullWidth>
                                                                    <AutoCompleteSelect 
                                                                        options={this.state.defaultItemsList} 
                                                                        value={item.description}
                                                                        label="Description *"
                                                                        error={formErrors && formErrors['orderRows|description|'+idx] && true}
                                                                        errorText={formErrors && formErrors['orderRows|description|'+idx]}
                                                                        onChange={this.handleSelectRowChange(idx, 'description', 'orderRows')}
                                                                        fullWidth
                                                                    />
                                                                </FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell className={classes.td} style={{width:'30%'}}>
                                                            <FormControl fullWidth>
                                                                <AutoCompleteSelect 
                                                                    options={po.po_type_id === 2 ? this.state.nominalList : item.nominalList} 
                                                                    value={item.nominalCode}
                                                                    label='Nominal Code *'
                                                                    error={formErrors && formErrors['orderRows|nominalCode|'+idx] && true}
                                                                    errorText={formErrors && formErrors['orderRows|nominalCode|'+idx]}
                                                                    onChange={this.handleSelectRowChange(idx, 'nominalCode', 'orderRows')}
                                                                    fullWidth
                                                                />
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell className={classes.td}>
                                                            <TextField
                                                                name="quantity"
                                                                value={item.quantity}
                                                                label="Quantity"
                                                                error={formErrors && formErrors['orderRows|quantity|'+idx] && true}
                                                                helperText={formErrors && formErrors['orderRows|quantity|'+idx]}
                                                                onChange={this.handleRowChange(idx, 'orderRows')}
                                                                onBlur={this.handleRowChange(idx, 'orderRows', 2)}
                                                                type="number"
                                                                inputProps={{ min: 0 }}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.td}>
                                                            <TextField
                                                                name="unitPrice"
                                                                value={item.unitPrice}
                                                                label="Unit Price"
                                                                error={formErrors && formErrors['orderRows|unitPrice|'+idx] && true}
                                                                helperText={formErrors && formErrors['orderRows|unitPrice|'+idx]}
                                                                onChange={this.handleRowChange(idx, 'orderRows')}
                                                                onBlur={this.handleRowChange(idx, 'orderRows', 2)}
                                                                type="number"
                                                                inputProps={{ min: 0 }}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.td}>
                                                            <TextField
                                                                name="totalPrice"
                                                                value={item.totalPrice}
                                                                label="Total Price"
                                                                error={formErrors && formErrors['orderRows|totalPrice|'+idx] && true}
                                                                helperText={formErrors && formErrors['orderRows|totalPrice|'+idx]}
                                                                onChange={this.handleRowChange(idx, 'orderRows')}
                                                                onBlur={this.handleRowChange(idx, 'orderRows', 2)}
                                                                type="number"
                                                                disabled
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.td}>
                                                            <IconButton 
                                                                onClick={this.handleRemoveRow(idx, 'orderRows')} 
                                                                disabled={this.state.formData.orderRows.length === 1}
                                                            >
                                                                <FALightIcon 
                                                                    icon='times' 
                                                                    noMargin 
                                                                    button 
                                                                    disabled={this.state.formData.orderRows.length === 1}
                                                                />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            );
                                        })}
                                        <TableRow>
                                            <TableCell className={classes.td} colSpan={5}>
                                                <Button 
                                                    onClick={this.handleAddRow('orderRows')}
                                                    variant="contained"
                                                    color="primary"
                                                    size="small">
                                                    Add Row
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                            {formErrors && formErrors['orderRows'] && formErrors['orderRows'].split('\n').map((item, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <Typography component={"div"} style={{color: colors.red}}>
                                            {item}<br/>
                                        </Typography>
                                    </React.Fragment>
                                )
                            })}
                            <Typography variant="h6" style={{display: 'flex', justifyContent:'flex-end', fontWeight: 'bold' }}>
                                Total Price: £{this.state.formData.overallPrice}
                            </Typography>
                        </PaddedPaper>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={8}>
                            <PaddedPaper>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={6}>
                                        <Typography variant="h6" gutterBottom>
                                            Invoice Details
                                        </Typography>
                                        <Grid container spacing={1}>                           
                                            <Grid item xs={12} sm={4}>
                                                <Typography variant="subtitle1">
                                                    Invoice FAO
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <Typography variant="subtitle1">
                                                    {po.po_invoice_fao}
                                                </Typography>
                                            </Grid> 
                                            <Grid item className='not-xs' sm={4}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Invoice Address
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    {po.po_invoice_address && po.po_invoice_address.split('\n').map((item, key) => {
                                                        return <span key={key}>{item}<br/></span>
                                                    })}
                                                </Typography>
                                            </Grid>          
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Typography variant="h6" gutterBottom>
                                            Delivery Details
                                        </Typography>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={4}>
                                                <Typography variant="subtitle1">
                                                    Delivery FAO
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <Typography variant="subtitle1">
                                                    {po.po_delivery_fao}
                                                </Typography>
                                            </Grid>
                                            <Grid item className='not-xs' sm={4}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Delivery Address
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    {po.po_delivery_address && po.po_delivery_address.split('\n').map((item, key) => {
                                                        return <span key={key}>{item}<br/></span>
                                                    })}
                                                </Typography>
                                            </Grid>                                        
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="h6" gutterBottom>
                                    Email Details
                                </Typography>
                                <Textarea
                                    id="emailText"
                                    name="emailText"
                                    label="Email Text"
                                    value={po.po_email_text}
                                    rows={10}
                                    disabled
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <TextField
                                    value={po.po_approval_by || ''}
                                    label="Approval Required By"
                                    fullWidth
                                    disabled
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {formErrors && formErrors.generic && (
                                    <React.Fragment>
                                        <Typography component={"div"} style={{color: colors.red}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </React.Fragment>
                                )}
                                <div className='buttonRow'>
                                <BackButton props={this.props} />
                                    <Button onClick={this.handleConfirmationOpen}
                                            variant="contained"
                                            color="primary">
                                        Update
                                    </Button>
                                </div>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    
                    <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Amend This Purchase Order?" 
                    message="Are you sure you want to amend this purchase order?"
                    />
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarOpen}
                        onClose={this.handleSnackbarClose}
                        message='You have successfully added a new purchase order'
                    />
                </Grid>
            );
        }
    }
}

export default withStyles(styles)(UpdatePurchaseOrder);
