import API from 'API';
import { API_URL } from 'Constants';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { getToken } from 'Functions/AuthFunctions';
import { gridPageChanged, gridRowsPerPageChanged } from 'Functions/FormFunctions';
import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';

import logo from 'Assets/Images/clenaware_logo_icon.png';

import { Button, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import Qr from 'Components/Common/QrReader/QrReader';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';


import '@google/model-viewer/dist/model-viewer';

import { downloadS3File } from 'Functions/MiscFunctions';

const initialState = () => ({
    searchCriteria: {
        searchPartType:   '',
        searchPartNumber: '',
        searchPartDesc:   '',
        status:           'all'
    },
    searchResults: {},
    single: null,
    initialPartNumbersList: [],
    partNumbersList: [],
    partTypesList: [],
    access: {
        viewPart:   false,
        updatePart: false,
        lock:       false,    
    },
    dataLoading: true,
    partImageDialog: false,
    partImageDialogData: '',
    part3DImageDialog: false,
    part3DImageDialogData: '',
    showQR: false,

    allCount:      0,
    activeCount:   0,
    replacedCount: 0,
    discountCount: 0,
})

class PartSearch extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.persistenceId = 'Parts:partSearch';
        this.state = this.getInitialState(initialState());
        this.gridPageChanged = gridPageChanged.bind(this);
        this.gridRowsPerPageChanged = gridRowsPerPageChanged.bind(this);
    }

    componentDidMount = () => {
        !this.hasPageState() && this.loadComponentData();
    }

    loadComponentData = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/view-part'), 
            API.get('/staff/my/access/check/update-part'),
            API.get('/staff/my/access/check/lock-part'),
            API.get('/parts/numbering/partTypes/all'),
            API.get('/parts/all', { params: { use: 'partSearch', withStock: true } }),
        ])
        .then(([viewRes, updateRes, lockRes, partTypesRes, partRes]) =>  {
            let partTypesList = []
            let partNumbersList = []

            if(partTypesRes.data) {
                partTypesList = _.map(partTypesRes.data, (el) => {
                    return _.assign({
                        value: el.part_type_id,
                        label:  el.part_type_code + ' - ' + el.part_type_name,
                    });
                });
            }

            if(partRes.data) {
                partNumbersList = _.map(partRes.data, (el) => {
                    return _.assign({
                        value: el.part_id,
                        label: el.part_number + ' - ' + el.part_description,
                        typeId: el.part_type_id,
                        part: el
                    });
                });
            }                

            this.setState({
                dataLoading: false,
                initialPartNumbersList: partNumbersList,
                partNumbersList,
                partTypesList,
                access: {
                    ...this.state.access,
                    viewPart:   (viewRes.data   && viewRes.data.has_access)   || false,
                    updatePart: (updateRes.data && updateRes.data.has_access) || false,
                    lock:       (lockRes.data   && lockRes.data.has_access)   || false,
                },
                allCount:      partRes.data.length,
                activeCount:   _.filter(partRes.data, {part_status: 'Active'}).length,
                replacedCount: _.filter(partRes.data, {part_status: 'Replaced'}).length,
                discountCount: _.filter(partRes.data, {part_status: 'Discontinued'}).length,
            }, () => {
                this.savePageState();
                this.getSearchData();
            });
        })

    } 

    getSearchData = () => {
        this.setState({
            dataLoading: true
        }, () => {
            API.get('/parts/all' , {
                params: {
                    searchPartType: this.state.searchCriteria.searchPartType,
                    searchPartId: this.state.searchCriteria.searchPartNumber,
                    searchString: this.state.searchCriteria.searchPartDesc,
                    use: 'partSearch',
                    withStock: true
                },
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    this.setState({
                        searchResults: result.data,
                        dataLoading: false
                    }, () => {
                        this.savePageState()
                    });
                }
            })
        })
    }

    handleSearchDesc = (e) => {
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                searchPartDesc: e.target.value
            },
            // gridPagination: initialState.gridPagination
        },
        () => {
            this.savePageState()
            this.getSearchData();
        })
    }

    handleChange = fieldName => selectedOption => {
        if(selectedOption === null) {
            selectedOption = ''
        }
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                [fieldName]: selectedOption && selectedOption.value,
            },
            gridPagination: initialState().gridPagination
        },
        () => {
            if(fieldName === 'searchPartType') {
                let partNumbersList = []
                if(this.state.searchCriteria.searchPartType !== ''){
                    partNumbersList = _.filter(this.state.initialPartNumbersList, {'typeId': this.state.searchCriteria.searchPartType});
                } else {
                    partNumbersList = this.state.initialPartNumbersList;
                }
                this.setState({
                    partNumbersList,
                    searchCriteria: {
                        ...this.state.searchCriteria,
                        searchPartNumber: ''
                    },
                    gridPagination: initialState().gridPagination
                })
            }
            this.savePageState()
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            searchCriteria: initialState().searchCriteria,
            // gridPagination: initialState.gridPagination
        }, 
        () => {
            // this.props.history.replace();
            this.getSearchData();
        });
    }

    loadPartImage = (rowData) => {
        this.setState({ 
            partImageDialog: true,
            partImageDialogData: rowData.default_image?.file_path
        });
    };
    handlePartImageDialogClose = () => {
        this.setState({ 
            partImageDialog: false
        });
    };

    load3DImage = (rowData) => {
        this.setState({ 
            part3DImageDialog: true,
            part3DImageDialogData: API_URL + '/getFileByType?request=' + btoa(rowData.default_image?.file_path_web_3D_image) + '&token=' + getToken() + '&fileType=glb',
        });
    };
    handle3DPartImageDialogClose = () => {
        this.setState({ 
            part3DImageDialog: false
        });
    };

    lockPart = id => {
        API.post(`/parts/${id}/lock`)
        .then(res => {this.getSearchData();});
    }

    handleScan = partNumber => {
        if (partNumber) this.setState({
            showQR: false
        },()=>{
            let value = _.find(this.state.initialPartNumbersList, i => i.part.part_number === partNumber).value;
            this.handleChange('searchPartNumber')({value})
        });
    }

    setPartsStatus = (status) => () => {
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                status
            }
        })
    }

    render() {
        const { access, dataLoading, searchCriteria, gridPagination, showQR } = this.state;
        const { classes } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Part Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.partTypesList} 
                                        label='Part Type'
                                        value={searchCriteria?.searchPartType}
                                        onChange={this.handleChange('searchPartType')}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.partNumbersList} 
                                        label='Part Number'
                                        value={searchCriteria?.searchPartNumber}
                                        onChange={this.handleChange('searchPartNumber')}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <TextField  id="PartSearch"
                                                label="Part Description"
                                                value={searchCriteria?.searchPartDesc ?? ''}
                                                onChange={this.handleSearchDesc}
                                                fullWidth />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button 
                                        variant="outlined"
                                        color="default"
                                        onClick={()=>{this.setState({showQR: !showQR});}}><AllIcon icon={icons.qr} noMargin/></Button>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                           
                                    >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                            { !!showQR &&
                                <Grid container item  className={classes.qrRow}>
                                    <Grid item xs={6} >
                                        <Qr handleScan={this.handleScan} width="100%" /> 
                                    </Grid>
                                </Grid>
                            }
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <PaddedPaper onClick={this.setPartsStatus('all')} style={{border: searchCriteria.status === 'all' && `1px solid ${colors.green}`}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{textAlign: 'center'}} variant='h5' gutterBottom>All</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{textAlign: 'center'}}>
                                            <AllIcon color={searchCriteria.status === 'all' && colors.green} noMargin icon={icons.parts} size='large' />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{textAlign: 'center'}} variant='h6'>{this.state.searchResults.length ?? '0'}</Typography>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={3}>
                                <PaddedPaper onClick={this.setPartsStatus('Active')} style={{border: searchCriteria.status === 'Active' && `1px solid ${colors.green}`}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{textAlign: 'center'}} variant='h5' gutterBottom>Active</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{textAlign: 'center'}}>
                                            <AllIcon  color={searchCriteria.status === 'Active' && colors.green} noMargin icon={icons.true} size='large' />
                                        </Grid>
                                        <Grid item xs={12}>
                                        <Typography style={{textAlign: 'center'}} variant='h6'>{_.filter(this.state.searchResults, {part_status: 'Active'}).length}</Typography>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={3}>
                                <PaddedPaper onClick={this.setPartsStatus('Replaced')} style={{border: searchCriteria.status === 'Replaced' && `1px solid ${colors.red}`}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{textAlign: 'center'}} variant='h5' gutterBottom>Replaced</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{textAlign: 'center'}}>
                                            <AllIcon noMargin  color={searchCriteria.status === 'Replaced' && colors.red} icon={icons.repeat} size='large' />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{textAlign: 'center'}} variant='h6'>{_.filter(this.state.searchResults, {part_status: 'Replaced'}).length}</Typography>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={3}>
                                <PaddedPaper onClick={this.setPartsStatus('Discontinued')} style={{border: searchCriteria.status === 'Discontinued' && `1px solid ${colors.red}`}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{textAlign: 'center'}} variant='h5' gutterBottom>Discontinued</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{textAlign: 'center'}}>
                                            <AllIcon noMargin  color={searchCriteria.status === 'Discontinued' && colors.red} icon={icons.false} size='large' />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{textAlign: 'center'}} variant='h6'>{_.filter(this.state.searchResults, {part_status: 'Discontinued'}).length}</Typography>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <CiDataTable  
                            config={{
                                key: 'part_id',
                                pagination: true,
                                // page: gridPagination.page,
                                // rowsPerPage: gridPagination.rowsPerPage,
                                // gridPageChanged: this.gridPageChanged,
                                // gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                persistenceId: this.persistenceId,
                                isLoading: dataLoading,
                                style: rowData => ({color: (rowData.part_status !== 'Active' && colors.red) || (rowData.part_locked && colors.orange),}),
                                responsiveImportance: true,
                                alternatingRowColours: true,
                                options: {
                                    dataRef: true,
                                    reset: this.resetSearch,
                                    export: {
                                        title: `Parts List`,
                                        name: `Parts`,
                                        excel: true,
                                        pdf: true,
                                        print: true
                                    }
                                },
                                rowsPerPage: 50
                            }}
                            columns={[
                                {
                                    field: rowData =>   <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                            {rowData && rowData.default_image?.thumbnail_file_path ?
                                                                <Button onClick={() => this.loadPartImage(rowData)}>
                                                                    <img src={rowData.default_image?.thumbnail_file_path} alt={rowData.part_description} style={{maxHeight:35, maxWidth:35}} />
                                                                </Button> 
                                                            : 
                                                                <img src={logo} alt="logo" style={{maxHeight:35, maxWidth:35}}/>
                                                            }
                                                        </div>,
                                    sizeToContent: true,
                                },
                                {
                                    heading: '3D Image',
                                    field: rowData =>   <>{rowData && rowData.default_image?.file_path_web_3D_image ?
                                                                <IconButton onClick={() => this.load3DImage(rowData)}>
                                                                    <FASolidIcon icon='cube'  noMargin button  />
                                                                </IconButton>
                                                            :
                                                                <FASolidIcon icon='cube' disabled noMargin  />
                                                        }</>,
                                    sizeToContent: true,
                                    alignment: 'center'
                                },
                                {
                                    heading: 'Number',
                                    field: rowData => rowData.part_number,
                                    dataRef: 'part_number',
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Description',
                                    field: rowData => rowData.part_description,
                                    dataRef: 'part_description',
                                    important: true,
                                    main: true,
                                    truncate: true
                                },
                                {
                                    heading: 'Part Type',
                                    field: rowData => rowData.part_type.part_type_name,
                                    dataRef: 'part_type.part_type_name',
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Build Type',
                                    field: rowData => rowData.build_type.build_type_name,
                                    dataRef: 'build_type.build_type_name',
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Version No',
                                    field: rowData => rowData.version_no ? 'v'+rowData.version_no : '-',
                                    dataRef: 'version_no',
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Drawing/Design Files',
                                    field: rowData => rowData.stockable && 
                                        <Grid container>
                                            <Grid item xs={6}><AllIcon noMargin icon={'ruler-horizontal'} tooltip='Design Drawing' disabled={rowData.part_pdf_drawing !== 'Yes' || !rowData.pdf_drawing} onClick={()=>downloadS3File(rowData?.pdf_drawing?.file_path)} /></Grid>
                                            <Grid item xs={6}><AllIcon noMargin icon={'ruler-combined'} disabled={!rowData.pdf_design_file}  tooltip='Design File'  onClick={()=>downloadS3File(rowData?.pdf_design_file?.file_path)} /></Grid>
                                        </Grid>,
                                },
                                {
                                    heading: 'Status',
                                    field: rowData => rowData.part_locked ? 'Locked' : rowData.part_status,
                                    dataRef: 'part_status',
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Stock',
                                    field: rowData => (rowData.part_locked || rowData.part_status !== 'Active') ? '-' 
                                        : <><AllIcon icon={icons.stock} noMargin tooltip={
                                            <span>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        Current Stock:
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        {rowData?.stock?.stock_current_qty}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        Predicted Stock:
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        {rowData?.stock?.stock_predicted}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        Available Stock:
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        {rowData?.stock?.stock_available}
                                                    </Grid>
                                                </Grid>
                                            </span>
                                        } 
                                            onClick={() => this.props.history.push(`/stock/activity/${rowData.part_id}`)}
                                        /></>,
                                    dataRef: 'stock.stock_available',
                                    sizeToContent: true
                                },
                                {
                                    field: i => i.missing_components && <AllIcon icon={icons.warning} noMargin tooltip={
                                        <Typography variant='body2'>
                                            {!parseInt(i.costs_count) && <>No Costs <br/></>}
                                            {!parseInt(i.prices_count) && <>No Prices <br/></>}
                                            {!parseInt(i.stock_count) && <>No Stock <br/></>}
                                        </Typography>
                                    } color={colors.red} />,
                                    sizeToContent: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Lock',              icon: rowData.part_locked ? icons.lock : icons.unlock,  onClick: () => {this.props.deployConfirmation('Are you sure you want to unlock this part?', 'Unlock Part?', () => { this.lockPart(rowData.part_id)})}, disabled: rowData.part_in_stock_take || !access.lock},
                                            {name: 'Update',            icon: 'pencil-alt',                                     link: { pathname: '/parts/update/' + rowData.part_id, state: {searchCriteria, gridPagination}}, disabled: !access.updatePart },
                                            {name: 'View',              icon: 'search',                                         link: { pathname: '/parts/view/' + rowData.part_id, state: {searchCriteria, gridPagination}}, disabled: !access.viewPart},
                                            {name: 'Copy to Clipboard', type: 'copyToClipboard',                                data: rowData.part_number + ' - ' + rowData.part_description}
                                        ]
                                    }
                                }
                            ]}
                            rows={searchCriteria.status === 'all' ? this.state.searchResults : _.filter(this.state.searchResults, {part_status: searchCriteria.status})}
                        />
                    </PaddedPaper>
                </Grid>
                <Dialog
                    open={this.state.partImageDialog}
                    onClose={this.handlePartImageDialogClose}
                    scroll="body"
                    disableBackdropClick	
                    disableEscapeKeyDown
                >                
                    <DialogContent>
                        <ImageWithError src={this.state.partImageDialogData} alt='Part Image' style={{maxWidth: 500}}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handlePartImageDialogClose} variant="outlined" color="default">Close</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.part3DImageDialog}
                    onClose={this.handle3DPartImageDialogClose}
                    maxWidth="md"
                    fullWidth
                >                
                    <DialogContent>
                        <model-viewer src={this.state.part3DImageDialogData} style={{width:'100%', minHeight: '550px'}} alt="A 3D model" auto-rotate camera-controls></model-viewer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handle3DPartImageDialogClose} variant="outlined" color="default">Close</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
}

const styles = theme => ({
    qrRow: {
        maxHeight: '30vw',
        maxWidth: '30vw',
        zIndex: 99999,
        position:'fixed',
        top: '6em',
        left: '5em'
    },
});


const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   ()              => dispatch(clearPersistence()),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state)),
    deployConfirmation: (message, title, success, variant = null) => { dispatch(deployConfirmation(message, title, success,false, variant)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PartSearch));