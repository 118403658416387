import React from 'react';
import API from 'API';
import { Button, Grid } from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea from 'Components/Common/Inputs/Textarea';
import DragFileInputMulti from 'Components/Common/Inputs/DragFileInputMulti';
import _ from 'lodash';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = () => ({
    isLoading: true,
    repairGroup: {},
    lists: {
        reasons: [],
    },
    formData: {
        files: [],
        notes: '',
        reason: '',
    }
})

class WriteOffDialog extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        Promise.all([
            API.get('/customerReturns/repairOrders/writeOffReasons'),
            API.get(`/customerReturns/repairOrders/${this.props.rogId}/${this.props.roId}`)
        ]).then(res => {
            this.setState({
                repairGroup: res[1].data,
                lists: {
                    reasons: _.map(res[0].data, i => ({value: i.rowor_name, label: i.rowor_name}))
                },
                isLoading: false
            })
        })
    }

    handleFileChange = (drop, name, event) => {
        let formData = this.state.formData;
        let newFiles = drop === true ? event.dataTransfer.files : event.target.files;
        let existingFiles = formData.files ?? [];
        let allFiles = Array.prototype.slice.call(existingFiles).concat(Array.prototype.slice.call(newFiles))
        this.onChange({files: allFiles});
    }

    cancelOnClick = idx => {
        let formData = this.state.formData;
        let existingFiles = formData.files ?? [];
        this.onChange({files: _.filter(existingFiles, (i,_idx) => _idx !== idx)});
    }

    onChange = (data) => {
        let formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                ...data
            }
        })
    }

    submit = () => {
        let formData = this.state.formData;
        let newFormData = new FormData();
        newFormData.append('reason', formData.reason);
        newFormData.append('notes', formData.notes);
        _.each(formData.files, f => {
            newFormData.append(`files[]`, f);
        });
        API.post(`/customerReturns/repairOrders/${this.props.rogId}/${this.props.roId}/writeOff`, newFormData)
        .then(this.props.closeDialog)
    }

    render() {

        const { isLoading, lists, formData } = this.state;

        if (isLoading) return (<LoadingCircle />);

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <AutoCompleteSelect
                        label='Reason *'
                        options={lists?.reasons}
                        value={formData?.reason}
                        onChange={e => this.onChange({'reason': e.value})}
                        fullWidth
                        noClear
                    />
                </Grid>
                <Grid item xs={12}>
                    <Textarea
                        label='Notes *'
                        value={formData.notes}
                        row={4}
                        onChange={e => this.onChange({'notes':e.target.value})}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <DragFileInputMulti
                        label='Upload Files *'
                        file={formData.files}
                        onChange={this.handleFileChange}
                        cancelOnClick={this.cancelOnClick}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant='outlined'
                        onClick={this.props.closeDialog}
                    >close</Button>
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={!formData.reason || !formData.notes || formData.files.length < 1}
                        onClick={()=>this.props.deployConfirmation(
                            'Are you sure you want to submit this write off?'
                            , 'Submit Repair Write Off', this.submit, 'warning'
                        )}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (content, title, callback, variant=null) => {
        dispatch(deployConfirmation(content, title, callback, variant=null))
    },
});

export default connect(null, mapDispatchToProps)(WriteOffDialog);