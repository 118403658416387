import API from 'API';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closeDialog } from 'Actions/Dialog/Dialog';
import logo from 'Assets/Images/clenaware_logo_icon.png';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { Button, FormControl, Grid, InputAdornment, Table, TableCell, TableRow, TextField } from '@material-ui/core/';

import { restockFeeMultiplier } from 'Constants';

const initialState = () => ({
    serialNumbers:      [],
    formErrors:         {warrantyVoidLabels: null, serialNumbers: null},
    part:               {},
});

class EditPartRowDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.setState({part: {...this.props.part, unitPrice: parseFloat(this.props.part.unitPrice).toFixed(2), quantity: parseFloat(this.props.part.quantity).toFixed(2)}});
        const { part } = this.props;
        if (part.part_req_serial_number == 'Yes') this.getSerialNumbers();
    }

    getSerialNumbers(){
        const id = this.props.part.part_id;
        API.get(`/parts/${id}/usedSerialNumbers`)
        .then(res => {
            if (res.data){
                let serialNumbers = _.map(res.data, i => {
                    if(!_.find(this.props.usedSerialNumbers, {id: i.id}))
                        return _.assign({
                            value: i.id,
                            label: i.serialNumber
                        });
                })
                this.setState({serialNumbers});
            }
        });
    }

    handleInputChange = e => {
        const {name,value} = e.target;
        this.setState({
            part:{
                ...this.state.part,
                [name]: Number.isInteger(value) ?parseFloat( value ).toFixed(2) : value
            }
        },this.calculateTotals);
    }

    handleAutoCompleteSelectChange = f => e => {
        this.setState({
            part: {
                ...this.state.part,
                [f]: e.value
            }
        },this.calculateTotals);
    }

    calculateTotals = () => {
        let newUnitPrice    = 0;
        let discountTotal   = 0;
        let totalPrice      = 0;
        let restockFeePrice = 0;
        let subTotal        = 0;
        const unitPrice     = this.state.part.unitPrice;
        const discount      = this.state.part.discountRate
        if ( unitPrice > 0 ) {  
            if (this.props.discount == 'With Discount'){
                discountTotal   = (unitPrice * (discount / 100));
                newUnitPrice    = unitPrice - discountTotal;
                totalPrice  = newUnitPrice *  this.state.part.quantity;
            } else {
                totalPrice  = unitPrice *  this.state.part.quantity;
            }
            if (this.state.part.restockFee){
                restockFeePrice = totalPrice * restockFeeMultiplier;
                subTotal        = totalPrice;
                totalPrice      = totalPrice - restockFeePrice;
            }
        }
        this.setState({
            part: {
                ...this.state.part,
                newUnitPrice:       parseFloat(newUnitPrice).toFixed(2),
                discountTotal:      parseFloat(discountTotal).toFixed(2),
                totalPrice:         parseFloat(totalPrice).toFixed(2),
                restockFeePrice:    parseFloat(restockFeePrice).toFixed(2),
                subTotal:           parseFloat(subTotal).toFixed(2)
            }
        })
    }

    handleMultiSelectChange = field => selectedOptions => {
        let value = selectedOptions ? (
            field == 'serialNumbers' ?
                selectedOptions.map(a => ({id:a.value, serialNumber: a.label})) :
                selectedOptions.map(a => a.value)
            ) : [];
            
        this.setState({
            part: {
                ...this.state.part,
                [field]: value,
            }
        });
    }

    submit  = () => {
        const {serialNumbers, quantity, part_req_serial_number, warrantyVoidLabels, part_warranty_void_label} = this.state.part;
        if ((part_req_serial_number == 'Yes' || part_req_serial_number == 'Manual') && serialNumbers.length != quantity) {
            let msg = 'Too Many Serial Numbers Selected';
            if (parseInt(quantity) > serialNumbers.length) msg = 'Select More Serial Numbers';
            this.setState({formErrors:{...this.state.formErrors, serialNumbers: msg}})
        } else if (part_warranty_void_label == 'Yes' && ( warrantyVoidLabels.length != quantity || _.find(warrantyVoidLabels, w => w.length != 10) )) {
            let msg = 'Too Many Warranty Void Labels Selected';
            _.each(warrantyVoidLabels, i => {if (i.length != 10 || !i.includes('CS-')){msg = 'Warranty Label is incorrect format ( CS-0000000 )';}})
            if (parseInt(quantity) > warrantyVoidLabels.length) msg = 'Select More Warranty Void Labels';
            this.setState({formErrors:{...this.state.formErrors, warrantyVoidLabels: msg}})
        } else {
            this.props.callBack(this.state.part);
            this.setState(initialState());
            this.props.closeDialog();
        }
    }


    render() {
        const {part, serialNumbers, formErrors}        = this.state;
        const {discount}    = this.props;

        return(
            <Grid container spacing={3} xs={12} >
                <Grid item xs={12} >
                    <Table cellspacing={0}>
                        <TableRow>
                            <TableCell style={{textAlign:'center', height:65, width: `5%`, maxWidth:65, padding: 5}}>
                                <img src={part.thumbnail_url ? part.thumbnail_url : logo} style={{maxHeight:50, maxWidth:50, verticalAlign:'middle'}} alt="" />
                            </TableCell>
                            <TableCell style={{width: `${((100/7)*1.5)}%`, padding: 2}}>
                                <TextField
                                    name="Part"
                                    label="Part"
                                    value={`${part.part_number} - ${part.part_description}`}
                                    size="small"
                                    fullWidth
                                    Disabled
                                />
                            </TableCell>
                            <TableCell style={{width: `${((100/7)*0.5)}%`, padding: 2}}>
                                <TextField
                                    name="quantity"
                                    label="Quantity"
                                    value={part.quantity}
                                    onChange={this.handleInputChange}
                                    type="number"
                                    size="small"
                                    inputProps={{
                                        min: 1
                                    }}
                                />
                            </TableCell>
                            <TableCell style={{width: `${100/7}%`, padding: 2}}>
                                <TextField
                                    name="unitPrice"
                                    label="Unit Price"
                                    value={part.unitPrice}
                                    onChange={this.handleInputChange}
                                    type="number"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                />
                            </TableCell>
                            {discount == 'With Discount'  && [
                                <TableCell style={{width: `${100/7}%`, padding: 2}}>
                                    <TextField
                                        name="discountRate"
                                        label="Discount Rate"
                                        value={part.discountRate}
                                        onChange={this.handleInputChange}
                                        type="number"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                                            inputProps: {
                                                min: 0,
                                                max: 100
                                            }
                                        }}
                                    />
                                </TableCell>,
                                <TableCell style={{width: `${100/7}%`, padding: 2}}>
                                    <TextField
                                        name="newUnitPrice"
                                        label={'New Unit Price'}
                                        value={parseFloat(part.newUnitPrice).toFixed(2)}
                                        onChange={this.handleInputChange}
                                        type="number"
                                        
                                        disabled
                                        noMargin
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                        }}
                                    />
                                </TableCell>
                                ]
                            }
                            <TableCell style={{width: `${100/7}%`, padding: 2}}>
                                <FormControl fullWidth>
                                    <AutoCompleteSelect 
                                        fullWidth
                                        label='Restock Fee?'
                                        options={[
                                            {value: true,  label: 'Yes'},
                                            {value: false, label: 'No'},
                                        ]} 
                                        value={part.restockFee}
                                        onChange={this.handleAutoCompleteSelectChange('restockFee')}
                                        noClear={true}
                                        autoFocus={false}
                                    />
                                </FormControl>
                            </TableCell>
                        </TableRow>
                    </Table>
                </Grid>
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        
                    </Grid>
                    <Grid item xs={12}>
                        {part.part_req_serial_number == 'Yes' &&
                            <>
                                {!!serialNumbers?.length ?
                                    <FormControl fullWidth>
                                        <AutoCompleteMultiSelect 
                                            options={serialNumbers} 
                                            label='Serial Numbers *'
                                            value={ _.map(part.serialNumbers, i => i.id )}
                                            onChange={this.handleMultiSelectChange('serialNumbers')}
                                            error={formErrors['serialNumbers']}
                                            errorText={formErrors['serialNumbers']}
                                        />
                                    </FormControl> : <LoadingCircle />
                                }
                            </>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {part.part_req_serial_number == 'Manual' &&
                            <FormControl fullWidth>
                                <AutoCompleteMultiSelect 
                                    options={_.map(part.serialNumbers, j => {return _.assign({value:j.id, label:j.serialNumber })})} 
                                    label='Serial Numbers *'
                                    value={ _.map(part.serialNumbers, i => i.id )}
                                    onChange={this.handleMultiSelectChange('serialNumbers')}
                                    error={formErrors['serialNumbers']}
                                    errorText={formErrors['serialNumbers']}
                                    creatable
                                />
                            </FormControl>
                        }
                    </Grid>
                    {part.part_warranty_void_label == 'Yes' && 
                        <FormControl fullWidth>
                            <AutoCompleteMultiSelect 
                                options={_.map(this.props.warrantyVoidLabels, i => {return _.assign({value: i, label: i})})} 
                                label='Warranty Void Labels *'
                                value={part.warrantyVoidLabels}
                                onChange={this.handleMultiSelectChange('warrantyVoidLabels')}
                                error={formErrors['warrantyVoidLabels']}
                                errorText={formErrors['warrantyVoidLabels']}
                                creatable
                            />
                        </FormControl>
                    }
                    {!!part.stockable &&
                        <Grid item  xs={12} style={{marginTop: '0.5em'}}>
                            <AutoCompleteSelect
                                label="Return part('s) back into stock *"
                                options={[
                                    {value: 1,  label: 'Yes'},
                                    {value: 0,  label: 'No'},
                                ]}
                                value={part.restock}
                                fullWidth
                                onChange={this.handleAutoCompleteSelectChange('restock')}
                                noClear
                            />
                        </Grid>
                    }
                    <Grid item  xs={12}>
                        <Textarea
                            id="notes"
                            name="cnNote"
                            label="Notes"
                            value={part.cnNote}
                            onChange={this.handleInputChange}
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={1} xs={6}>
                    {discount == 'With Discount'  && [
                       <Grid item xs={12}>
                            <TextField
                                name="discountTotal"
                                label="Discount Total"
                                value={part.discountTotal}
                                onChange={this.handleInputChange}
                                type="number"
                                fullWidth
                                disabled
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                }}
                            />
                        </Grid>
                    ]}
                    {!!part.restockFee &&
                        <Grid item spacing={3} xs={12}>
                            <TextField
                                name="restockFeePrice"
                                label="Restock Fee"
                                value={part.restockFeePrice}
                                onChange={this.handleInputChange}
                                type="number"
                                fullWidth
                                disabled
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                }}
                            />
                        </Grid>
                    }
                    <Grid item spacing={3} xs={12}>
                        <TextField
                            name="totalPrice"
                            label="Total Credit"
                            value={part.totalPrice}
                            onChange={this.handleInputChange}
                            type="number"
                            fullWidth
                            disabled
                            InputProps={{
                                startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className='buttonRow' style={{marginTop: 30}}>
                            <Button
                                variant="outlined" 
                                onClick={this.props.closeDialog}>Close</Button>
                            <Button 
                                onClick={this.submit}
                                variant="contained" 
                                color="primary" 
                                disabled={part.part_req_serial_number == 'Yes' && !part?.serialNumbers?.length}
                            >
                                Update
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}


const mapDispatchToProps = dispatch => ({closeDialog:() => {dispatch(closeDialog())}});

export default connect(null, mapDispatchToProps)(EditPartRowDialog);