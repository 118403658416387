import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import ChangeStaffPasswordForm from 'Components/Staff/UpdateStaff/ChangeStaffPasswordForm';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import NotificationList from 'Components/Common/Notifications/NotificationList';
import ViewStaffMfaStatus from 'Components/Staff/ViewStaff/ViewStaffMfaStatus';
import ViewStaffProfileCard from 'Components/Staff/ViewStaff/ViewStaffProfileCard';

const initialState = {
    staffData: {},
    isLoading: true,
}

class MyAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {  
        this.props.changePage('My Account', '/myaccount');    
        API.get('/staff/my/profile')
        .then(result => {
            this.setState({
                ...this.state,
                staffData: result.data,
                isLoading: false
            });
        });
    }
    
    render() {
        const { scrollToTop } = this.props;
        const { isLoading, staffData } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                My Profile
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ViewStaffProfileCard staffData={staffData} access={false} isLoading={isLoading} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Multi-factor Authentication
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>                        
                                    <ViewStaffMfaStatus status={[staffData.staff_mfa,staffData.staff_mfa_activation_datetime]} myAccount={true} isLoading={isLoading} />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Change Password
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>      
                                    {(isLoading && (
                                        <LoadingCircle />
                                    )) || (
                                        <ChangeStaffPasswordForm scrollToTop={scrollToTop} myPassword={true}/>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <NotificationList />
                </Grid>
            </Grid>
        )
    }
}

export default MyAccount;