import React from 'react';

import icons      from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';

import AllIcon from './AllIcon';

export default function ({info, size='small', placement='left', style={}, color=colors.info}){
    return (
        <AllIcon 
            icon=   {icons.info} 
            size=   {size} 
            tooltip={info}
            color=  {color}
            ttPlacement={placement}
            noPadding 
            noMargin
            heavy 
            style={style}
        />
    )
}

