import { Grid, Typography, Button } from '@material-ui/core';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import React from 'react';
import API from 'API';
import InfoBox from 'Components/Common/reports/InfoBox';
import IconHelper from 'Helpers/IconHelper';
import _ from 'lodash';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import { colors } from 'Helpers/ColourHelper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';
import { connect } from 'react-redux';
import RepairDialog from './RepairDialog';
import WriteOffDialog from './WriteOffDialog';
import BackButton from 'Components/Common/Buttons/BackButton';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

const initialState = () => ({
    isLoading: true,
    repairGroup: {}
})

class ViewCustomerReturnRepair extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getRepairGroup();
    }

    getRepairGroup = () => {
        this.setState({
            isLoading: true,
        }, ()=> {
            API.get(`/customerReturns/repairOrders/${this.props.match.params.rogId}`).then(res => {
                this.setState({ 
                    repairGroup: res.data,
                    isLoading: false 
                });
            });
        })
    }

    writeOff = (row) => {
        this.props.deployDialog(
            <WriteOffDialog rogId={this.props.match.params.rogId} roId={row.ro_id} closeDialog={()=>{
                this.props.closeDialog();
                this.getRepairGroup();
            }} />,
            'Write Off Part',
            'md',
            'warning'
        )
    }

    repair = (row) => {
        this.props.deployDialog(
            <RepairDialog repairOrder={row} repairGroup={this.state.repairGroup} closeDialog={()=>{
                this.props.closeDialog();
                this.getRepairGroup();
            }} />,
            'Repair Item',
            'fs'
        )
    }

    submit = () => {
        API.post(`/customerReturns/repairOrders/${this.props.match.params.rogId}/complete`).then(res => {
            this.props.history.push('/returns/customerReturn/repair');
        });
    }


    downloadCO = (order_id) => () => {
        API.get('/sales/orders/' + order_id + '/pdf?preview=1')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, /*result.data.pdfName*/ 'customer_order.pdf');
            }
        });
    }

    downloadCQ = (quote_id) => () => {
        API.get('/sales/quotations/' + quote_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    render() {

        const { isLoading, repairGroup } = this.state;

        if (isLoading) return ( <LoadingCircle /> );

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5'>View Repairs</Typography>
                </Grid>
                <InfoBox rowSize={4} title="Items" icon={IconHelper.parts} value={repairGroup.repair_orders.length} />
                <InfoBox rowSize={4} title="In Progress" icon={IconHelper.dash} value={_.filter(repairGroup.repair_orders, {ro_status: 'In Progress'}).length} />
                <InfoBox rowSize={4} title="Repaired" icon={IconHelper.true} value={_.filter(repairGroup.repair_orders, {ro_status: 'Complete'}).length} />
                <InfoBox rowSize={4} title="Written Off" icon={IconHelper.broken} value={_.filter(repairGroup.repair_orders, {ro_status: 'Write Off'}).length} />
                {repairGroup.quote &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container style={{alignItems: 'center'}}>
                                <Grid item xs={1}>
                                    <Typography variant='body1'>
                                        <strong>Quotation:</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant='body1'>{`${repairGroup.quote.quote_reference}-${repairGroup.quote.quote_reference_amendment}`}</Typography>
                                </Grid>
                                <Grid item style={{marginLeft: 'auto'}}>
                                    <AllIcon noMargin onClick={this.downloadCQ(repairGroup.quote.quote_id)} icon={IconHelper.download}/>
                                    <AllIcon noMargin link={`/sales/quotation/view/${repairGroup.quote.quote_id}`} icon={IconHelper.view}/>
                                </Grid>
                                {repairGroup?.quote?.order &&
                                    <>
                                        <Grid item xs={1}>
                                            <Typography variant='body1'>
                                                <strong>Order:</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant='body1'>{`${repairGroup.quote.order.order_reference}-${repairGroup.quote.order.order_reference_amendment}`}</Typography>
                                        </Grid>
                                        <Grid item style={{marginLeft: 'auto'}}>
                                            <AllIcon noMargin onClick={this.downloadCO(repairGroup.quote.order.order_id)} icon={IconHelper.download}/>
                                            <AllIcon noMargin link={`/sales/order/view/${repairGroup.quote.order.order_id}`} icon={IconHelper.view}/>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
                <Grid item xs={12}>
                    <PaddedPaper>
                        <CiDataTable
                            config={{
                                key: 'ro_id'
                            }}
                            columns={[
                                {
                                    heading: '',
                                    field: 'part.default_image.file_path',
                                    fieldFormat: 'image',
                                    sizeToContent: true,
                                    alignment: 'center',
                                },
                                {
                                    heading: 'Part Number',
                                    field: 'part.part_number',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Part Description',
                                    field: i => 
                                        <>
                                            <Typography variant='body2'>{i.part.part_description}</Typography>
                                            {( (i.ro_sn) !== '[]' ) && <Typography variant='caption '>{(i.ro_sn)}</Typography> }
                                            {( (i.ro_wvl) !== '[]' ) && <Typography variant='caption '>{(i.ro_wvl)}</Typography> }
                                        </>,
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Reference',
                                    field: 'ro_reference'
                                },
                                {
                                    heading: 'Quote',
                                    field:  () => repairGroup.quote ? 
                                        <>
                                            <Typography variant='caption '>
                                                {`${repairGroup.quote.quote_reference}-${repairGroup.quote.quote_reference_amendment}`}
                                            </Typography><br/>
                                        </>
                                    : '-',
                                },
                                {
                                    heading: 'Status',
                                    field: i => <AllIcon
                                        icon={IconHelper[
                                            (
                                                (i.ro_status === 'Write Off' && 'broken') ||
                                                (i.ro_status === 'Complete' && 'true') ||
                                                (i.ro_status === 'In Progress' && 'dash') ||
                                                (i.ro_status === 'Pending' && 'timer')
                                            )
                                        ]}
                                        color={
                                            (
                                                (i.ro_status === 'Write Off' && colors.red) ||
                                                (i.ro_status === 'Complete' && colors.green) ||
                                                (i.ro_status === 'In Progress' && colors.orange) ||
                                                (i.ro_status === 'Pending' && colors.orange)
                                            )
                                        }
                                        tooltip={i.ro_status}
                                        noMargin
                                    />,
                                    sizeToContent: true,
                                    alignment: 'center',
                                },
                                {
                                    actions: i => ([
                                        // {
                                        //     name: 'Write Off',
                                        //     icon: IconHelper.broken,
                                        //     onClick: this.writeOff,
                                        //     disabled: ['Write Off', 'Complete' ].includes(i.ro_status)
                                        // },
                                        {
                                            name: 'Repair',
                                            icon: IconHelper.parts,
                                            onClick: this.repair,
                                            disabled: ['Write Off', 'Complete' ].includes(i.ro_status)
                                        }
                                    ]),
                                }
                            ]}
                            rows={repairGroup.repair_orders}
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <BackButton/>
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={_.find(repairGroup.repair_orders, i => ['In Progress', 'Pending'].includes(i.ro_status)) || repairGroup.rog_status === 'Completed'}
                        onClick={()=>this.props.deployConfirmation(
                            'Are you sure you want to complete these repairs as complete?',
                            'Complete Repairs',
                            this.submit
                        )}
                    >
                        Complete Repairs
                    </Button> 
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployDialog: (content, title, size, variant='system') => dispatch(deployDialog(content, title, variant, size)),
    closeDialog: () => dispatch(closeDialog()),
    deployConfirmation: (content, title, callback, variant=null) => {dispatch(deployConfirmation(content, title, callback, variant=null))},
});

export default connect(null, mapDispatchToProps)(ViewCustomerReturnRepair);