import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import React from 'react';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import _ from 'lodash';
import IconHelper from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { clenyDate } from 'Helpers/TimeHelper';
import moment from 'moment';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { Grid, Button, Typography } from '@material-ui/core';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { connect } from 'react-redux';
import API from 'API';
import InfoBox from 'Components/Common/reports/InfoBox';

const initialState = () => ({
    isLoading: true,
    scheduled: [],
    typeFilter: 'All',
})

class Scheduled extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        //in case campaign is run while page is being viewed
        this.props.refresh();
        this.getScheduled();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.campaign.scheduled) !== JSON.stringify(prevProps.campaign.scheduled)) {
            this.getScheduled();
        }
    }

    getScheduled = () => {
        let scheduled = [];
        let run = 1;
        _.each(_.orderBy(this.props.campaign.scheduled, 'ecse_id', 'asc'), s => {
            scheduled.push({
                ...s,
                run: run,
            })
            if (s.ecse_status === 'Sent'){
                run++;
            }
        })
        this.setState({
            isLoading: false,
            scheduled
        })    
    }

    viewScheduled = s => {
        this.props.deployDialog(
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    Status: {s.ecse_status}<br/>
                    Scheduled: {moment(s.ecse_send_date).format('DD/MM/YYYY HH:mm:ss')}<br/>
                    Sent: {s.ecse_status === 'Sent' ? moment(s.ecse_sent_date).format('DD/MM/YYYY HH:mm:ss') : '-'}
                </Grid>
                {s.ecse_status === 'Sent' &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant='body1'>Sent Data</Typography>
                            Campaign: {JSON.parse(s.ecse_status_data).ec}<br/>
                            Time Taken: {moment.utc(JSON.parse(s.ecse_status_data).t).format('HH:mm:ss')}
                        </PaddedPaper>
                    </Grid>
                }
                {s.ecse_status === 'Canceled' &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant='body1'>Canceled Data</Typography>
                            Campaign: {JSON.parse(s.ecse_status_data).c}<br/>
                            Canceled By: {JSON.parse(s.ecse_status_data).s}<br/>
                            Canceled At: {moment(JSON.parse(s.ecse_status_data).t).format('DD/MM/YYYY HH:mm:ss')}
                        </PaddedPaper>
                    </Grid>
                }
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant="outlined"
                        onClick={this.props.closeDialog}
                    >
                        Close
                    </Button>
                </Grid>
            </Grid>,
            'View Schedule',
            'xs'
        );
    }

    cancelScheduled = s => {
        API.post(`/marketing/campaigns/scheduled/${s.ecse_id}/cancel`).then(this.props.refresh)
    }

    render() {

        const { isLoading, scheduled, typeFilter } = this.state;

        if (isLoading) {
            return <LoadingCircle />
        }
    
        return (
            <Grid container spacing={2}>
                <InfoBox
                    onClick={()=>this.setState({typeFilter: 'All'})}
                    title='All'
                    value={scheduled.length}
                    icon={IconHelper.day}
                    rowSize={4}
                    color={typeFilter === 'All' && colors.green}
                    border={typeFilter === 'All' && `1px solid ${colors.green}`}
                />
                <InfoBox
                    onClick={()=>this.setState({typeFilter: 'Awaiting'})}
                    title='Awaiting'
                    value={_.filter(scheduled, {ecse_status: 'Awaiting'}).length}
                    icon={IconHelper.clock}
                    rowSize={4}
                    color={typeFilter === 'Awaiting' && colors.orange}
                    border={typeFilter === 'Awaiting' && `1px solid ${colors.orange}`}
                />
                <InfoBox
                    onClick={()=>this.setState({typeFilter: 'Sent'})}
                    title='Sent'
                    value={_.filter(scheduled, {ecse_status: 'Sent'}).length}
                    icon={IconHelper.email}
                    rowSize={4}
                    color={typeFilter === 'Sent' && colors.green}
                    border={typeFilter === 'Sent' && `1px solid ${colors.green}`}
                />
                <InfoBox
                    onClick={()=>this.setState({typeFilter: 'Canceled'})}
                    title='Canceled'
                    value={_.filter(scheduled, {ecse_status: 'Canceled'}).length}
                    icon={IconHelper.false}
                    rowSize={4}
                    color={typeFilter === 'Canceled' && colors.red}
                    border={typeFilter === 'Canceled' && `1px solid ${colors.red}`}
                />
                <Grid item xs={12}>
                    <CiDataTable
                        config={{
                            key: 'ecse_id ',
                            pagination: scheduled.length > 100,
                            style: i => ({
                                color: i.ecse_status === 'Awaiting' ? 
                                    colors.disabled : 
                                    (
                                        (i.ecse_status === 'Error' || i.ecse_status === 'Canceled') ?
                                            colors.red :
                                            (
                                                i.ecse_status === 'Sending' ?
                                                    colors.orange :
                                                    colors.green
                                            )
                                    ),
                            })
                        }}
                        rows={_.orderBy(typeFilter !== 'All' ? _.filter(scheduled, {ecse_status: typeFilter}) : scheduled , 'ecse_send_date', 'asc')}
                        columns={[
                            {
                                heading: 'Run',
                                field: i => i.ecse_status === 'Awaiting' ?
                                    <AllIcon
                                        icon={IconHelper.clock}
                                        color={colors.disabled}
                                        noMargin
                                        tooltip="Awaiting Run"
                                    />
                                    : i.run,
                                sizeToContent: true,
                                dataRef: 'run',
                                alignment: 'center'
                            },
                            {
                                heading: 'Scheduled Date',
                                field: 'ecse_send_date',
                                dataRef: 'ecse_send_date',
                                fieldFormat: 'clenydate',
                            },
                            {
                                heading: 'Scheduled Time',
                                field: 'ecse_send_date',
                                dataRef: 'ecse_send_date',
                                fieldFormat: 'clenytime',
                            },
                            {
                                heading: 'Sent Date',
                                field: i => i.ecse_status === 'Sent' ?
                                    clenyDate(i.ecse_sent_date, false) : '-',
                                dataRef: 'ecse_sent_date',
                            },
                            {
                                heading: 'Sent Time',
                                field: i => i.ecse_status === 'Sent' ?
                                    clenyDate(i.ecse_sent_date, false, true) : '-',
                                dataRef: 'ecse_sent_date',
                            },
                            {
                                heading: 'Status',
                                field: 'ecse_status',
                                dataRef: 'ecse_status',
                                sizeToContent: true,
                                alignment: 'center',
                            },
                            {
                                actions: c => ([
                                    {
                                        name: 'Cancel',
                                        icon: IconHelper.cancel,
                                        onClick: (s) => this.props.deployConfirmation(
                                            'Are you sure you want to cancel this scheduled run?',
                                            'Cancel Scheduled Run',
                                            ()=>this.cancelScheduled(s)
                                        ),
                                        disabled: c.ecse_status !== 'Awaiting',
                                    }
                                ]),
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, size, variant=null) => {
            dispatch(deployDialog(content, title, variant, size))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        },
        deployConfirmation: (message, title, success, variant)   => dispatch(deployConfirmation(message, title, success, null, variant))
    }
}

export default connect(null, mapDispatchToProps)(Scheduled);