import { Box, Button, Grid } from '@material-ui/core';
import API from 'API';
import Textarea from 'Components/Common/Inputs/Textarea';
import React from 'react';

const initialState = {
    formData: {
        notes: null
    }
}

class CancelDialog extends React.Component {
    constructor(props){
        super(props)
        this.state = initialState;

    }

    handleChange = name => event => {
        let formData = this.state.formData;
        formData[name] = event.target.value;
        this.setState({ formData });
    }

    handleCancel = () => {
        API.post(`/customerReturns/returns/${this.props.cr.cr_id}/cancel`, this.state.formData).then(()=>this.props.onClose(true));
    }


    render(){

        const {formData} = this.state

        return (
            <Box>
                <Grid container spacing={1} >
                    <Grid item xs={12}>
                        <Textarea
                            label='Cancel Notes'
                            value={formData.notes}
                            onChange={this.handleChange('notes')}
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant='outlined'
                            onClick={()=>this.props.onClose()}
                        >
                            Close
                        </Button>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={this.handleCancel}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default CancelDialog;