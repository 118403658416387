import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';

import moment from "moment";

import API from "API";

import { pdfFromBase64 } from 'Helpers/PDFHelper';
import {colors}          from 'Helpers/ColourHelper';

import Button from "@material-ui/core/Button";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MuiLink from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";

import DataTable from "Components/Common/DataTables/DataTable";
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import Image from "Components/Common/ImageWithError/ImageWithError";

import { closeDialog } from "Actions/Dialog/Dialog";
import { deploySnackBar } from "Actions/SnackBar/SnackBar";

const initialState = {
    order: {},
    pickingList: {},
    totalQty: 0,
    isLoading: true,
};

const ViewOrderPick = ({ orderId }) => {
    const [state, setState] = useState(initialState),
        { order, pickingList, totalQty, isLoading } = state,
        dispatch = useDispatch(),
        handleDownload = (orderId, despId) => {
            API.post(`/sales/orders/${orderId}/prevPdf`, {'type': 'delivery-note', 'id': despId})
            .then(result => {
                if(result.data) {
                    pdfFromBase64(result.data.pdf, result.data.pdfName);
                    dispatch(deploySnackBar('success', 'PDF successfully downloaded'));
                }
            });
        },
        handleView = despId => {
            dispatch(closeDialog());
            window.location.href = `/sales/order/dispatch/view/${despId}`;
        }

    useEffect(() => {
        API.get(`/sales/orders/${orderId}`, {
            params: { forPicking: true },
        }).then((result) => {
            let totalQty = 0,
                pickingList = [];

            result.data.order_details.forEach((detail) => {
                totalQty += parseFloat(
                    detail.pick_detail.opd_quantity_to_follow
                );
                // remove from picking list
                if (parseFloat(detail.pick_detail.opd_quantity_to_follow) > 0) {
                    pickingList.push(detail);
                }
            });

            setState((state) => ({
                ...state,
                order: result.data,
                pickingList,
                totalQty,
                isLoading: false,
            }));
        });
    }, [orderId]);

    return (
        <Grid container spacing={7}>
            {isLoading ? (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            ) : (
                <>
                    <Grid item xs={12}>
                        <Grid container item spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    Customer:{" "}
                                    <strong>
                                        {order?.customers?.cust_trading_name}
                                    </strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    Despatch Date:{" "}
                                    <strong>
                                        {moment(
                                            order?.despatch?.[0]?.desp_expected_despatch_date
                                        ).format("DD/MM/YYYY")}
                                    </strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    Total Items To Pick: <strong>{totalQty}</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <DataTable
                                    config={{
                                        key: "od_id",
                                        responsiveImportance: true,
                                        isLoading
                                    }}
                                    columns={[
                                        {
                                            field: (rowData) => (
                                                <div className="thumbnailContainer">
                                                    <Image
                                                        src={
                                                            rowData.part
                                                                ?.default_image
                                                                ?.thumbnail_file_path
                                                        }
                                                        alt={
                                                            rowData.part
                                                                .part_description
                                                        }
                                                        className="thumbnail"
                                                    />
                                                </div>
                                            ),
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: "Part Number",
                                            field: (rowData) =>
                                                rowData.od_part_number,
                                        },
                                        {
                                            heading: "Part Description",
                                            field: (rowData) =>
                                                <>
                                                    <Typography >
                                                        {rowData.od_part_description} 
                                                    </Typography>
                                                    <span style={{color: '#757575', fontSize: '12px'}}> 
                                                        {rowData.od_electrical_connection ? `${rowData.od_electrical_connection} Connection`: '' }
                                                    </span>
                                                </>
                                        },
                                        {
                                            heading: "Qty Ordered",
                                            field: (rowData) =>
                                                parseFloat(rowData.od_quantity),
                                            alignment: "right",
                                        },
                                        {
                                            heading: "Qty Picked",
                                            field: (rowData) =>
                                                parseFloat(
                                                    rowData.pick_detail
                                                        .opd_quantity_picked
                                                ),
                                            alignment: "right",
                                        },
                                        {
                                            heading: "Qty To Follow",
                                            field: (rowData) =>
                                                parseFloat(
                                                    rowData.pick_detail
                                                        .opd_quantity_to_follow
                                                ),
                                            alignment: "right",
                                        },
                                    ]}
                                    rows={pickingList}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Awaiting Despatch ({order?.despatch?.length ?? 0})</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container item spacing={3}>
                                    <Grid item xs={12} >
                                        {(order?.despatch?.length > 0 && (
                                            <DataTable  
                                                config={{
                                                    key: 'cdv_id',
                                                    plainHeader: true,
                                                    rowProps: rowData => {
                                                        let backgroundColor = moment(rowData.desp_expected_despatch_date).isSame(moment().format('YYYY/MM/DD'))
                                                                            ? 'rgb(241, 248, 233)'
                                                                            : 'inherit';
                                                        return ({
                                                            style: {
                                                                backgroundColor
                                                            }
                                                        });
                                                    }
                                                }}
                                                columns={[
                                                    {
                                                        heading: '',
                                                        field: rowData =>   moment(rowData.desp_expected_despatch_date).isBefore(moment().format('YYYY/MM/DD')) 
                                                                                ? <FASolidIcon icon="exclamation-triangle" style={{color: colors.red}} /> 
                                                                                : '',
                                                        sizeToContent: true,
                                                        alignment: 'center'
                                                    },
                                                    {
                                                        heading: 'Despatch Date',
                                                        field: rowData =>   moment(rowData.desp_expected_despatch_date).isBefore(moment().format('YYYY/MM/DD')) 
                                                                                ? <strong style={{color: colors.red}}>{moment(rowData.desp_expected_despatch_date).format('DD/MM/YYYY')}</strong> 
                                                                                : moment(rowData.desp_expected_despatch_date).format('DD/MM/YYYY'),
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Delivery Date',
                                                        field: rowData =>   moment(rowData.desp_delivery_date).isBefore(moment().format('YYYY/MM/DD')) 
                                                                                ? <strong style={{color: colors.red}}>{moment(rowData.desp_delivery_date).format('DD/MM/YYYY')}</strong> 
                                                                                : moment(rowData.desp_delivery_date).format('DD/MM/YYYY'),
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Despatch Method',
                                                        field: rowData => rowData.courier.cour_name,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Reference',
                                                        field: rowData =>   <MuiLink href={`/sales/order/dispatch/view/${rowData.desp_id}`} className="blueLink">
                                                                                {rowData.full_reference}
                                                                            </MuiLink>,
                                                        sizeToContent: true,
                                                        main: true
                                                    },
                                                    {
                                                        actions: rowData => {
                                                            return [
                                                                {name: 'Download', icon: 'download', onClick: () => handleDownload(order.order_id, rowData.desp_id)},
                                                                {name: 'View Delivery', icon: 'search', onClick: () => handleView(rowData.desp_id)}
                                                            ]
                                                        }
                                                    }
                                                ]}
                                                rows={order?.despatch}
                                            />
                                        )) || (
                                            <List>
                                                <ListItem>
                                                    <ListItemText primary={<em>No deliveries to display</em>} />
                                                </ListItem>
                                            </List>
                                        )}
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="buttonRow">
                            <Button variant="outlined" onClick={() => dispatch(closeDialog())}>Close</Button>
                        </div>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default ViewOrderPick;
