import FormView from 'Components/Common/Forms/FormView/FormView';
import React from 'react';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import IconHelper from 'Helpers/IconHelper';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


export default function SurveyPreview({ survey }) {
    return (
        <>
            <Grid container spacing={2}>
                {survey?.form?.linked_parts?.length > 0 &&
                    <Grid item xs={12}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                            >Linked Parts</ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <CiDataTable
                                    config={{
                                        key: 'part_id',
                                    }}
                                    rows={survey.form.linked_parts}
                                    columns={[
                                        {
                                            heading: '',
                                            field: 'default_image.thumbnail_file_path',
                                            fieldFormat: 'image',
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: 'Part Number',
                                            field: 'part_number',
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: 'Description',
                                            field: 'part_description',
                                        },
                                        {
                                            actions: i => ([
                                                {
                                                    name: 'View Part',
                                                    icon: IconHelper.view,
                                                    link: `/parts/view/${i.part_id}`,
                                                }
                                            ])
                                        }
                                    ]}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                }
                <Grid item xs={12}>
                    <FormView formId={survey.survey_form_id} />
                </Grid>
            </Grid>
        </>
    )
}