import { Grid, Typography, Button } from '@material-ui/core';
import API from 'API';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import React from 'react';
import { connect } from 'react-redux';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import TabBar from 'Components/Common/Navigation/TabBar';
import FormReport from 'Components/Common/Forms/FormReports/FormReport';
import { colors } from 'Helpers/ColourHelper';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import _ from 'lodash';
import InfoBox from 'Components/Common/reports/InfoBox';
import IconHelper from 'Helpers/IconHelper';
import StickyNotes                 from 'Components/Common/StickyNotes/StickyNotes';

//tabs
import Overview from './Tabs/Overview';
import EmailPreview from './Tabs/EmailPreview';
import SurveyPreview from './Tabs/SurveyPreview';
import UnsentContacts from './Tabs/UnsentContacts';
import UnsentContactsManual from './Tabs/UnsentContactsManual';
import PendingContacts from './Tabs/PendingContacts';
import PartialContacts from './Tabs/PartialContacts';
import CompleteContacts from './Tabs/CompleteContacts';
import ActivityLog from './Tabs/ActivityLog';
import RemovedContacts from './Tabs/RemovedContacts';

const initialState = () => ({
    isLoading: true,
    survey: {},
    pendingContacts: [],
    currentTab: 'o',
})

class SurveySearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getSurvey(true);
    }

    getSurvey = (withPending=false) => {
        API.get(`/survey/${this.props.match.params.id}`).then(res => {
            this.setState({
                survey: res.data,
                isLoading: false,
            }, ()=> {
                if (withPending){
                    API.get(`/survey/${this.state.survey.survey_id}/contacts/pending`, {params: {manual: this.state.survey.form.linked_parts.length === 0 ? 1 : 0}}).then(res => {
                        let pendingContacts = [];
                        if (this.state.survey.form.linked_parts.length === 0){
                            pendingContacts = res.data;
                        } else {
                            _.each(res.data, c => {
                                _.each(c.invoices, i => {
                                    pendingContacts.push({
                                        ...i,
                                        customer: c.name,
                                        cust_id: c.id
                                    })
                                })
                            })
                        }
                        
                        this.setState({
                            pendingContacts: pendingContacts
                        })
                    })
                }
            })
        })
    }

    setTab = (event, tab) => {
        //stop me saying press F5
        this.getSurvey();

        
        this.setState({currentTab: tab});
    }


    endSurvey = () => {
        API.post(`/survey/${this.state.survey.survey_id}/end`).then(res => {
            this.getSurvey();
        })
    }

    uuidReplacer = (uuid)=>{
        const { survey } = this.state;
        let r = _.find([...survey.contacts_sent, ...survey.contacts_partial, ...survey.contacts_complete], r => r.form.form_uuid === uuid);
        if (!r){return <></>;}
        return (
            <Grid container>
                <Grid item xs={12}>
                    {r?.contact?.contact_name} <span style={{textTransform: 'lowercase'}}>({r?.contact?.contact_email})</span>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body2'>{r?.contact?.customer?.cust_name}</Typography>
                </Grid>
            </Grid>
        )
    }

    showStickyNotes = ({sc_id}) => { this.props.deployDialog(<StickyNotes onClose={this.getSurvey} scId={sc_id} dialog edit allowFiles/>, 'Sticky Notes And Files','xs', null, {backgroundColor: colors.stickyNotes}) }

    render() {

        const { isLoading, survey, currentTab, pendingContacts } = this.state;

        if (isLoading) { return <LoadingCircle/> }

        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">View Survey</Typography>
                    </Grid>
                    <Grid item xs={([...survey.contacts_sent, ...survey.contacts_partial].length === 0 || survey.survey_status !== 'active') ? 9 : 12}>
                        <PaddedPaper style={{height: '100%'}}>
                            <Typography variant='h4' style={{marginBottom: '0.2em'}}>{survey.survey_ref}</Typography>
                            <Typography variant="h5" style={{width: '100%'}}>{survey.survey_name}</Typography>
                            {survey.survey_desc && <Typography variant="body1" gutterBottom style={{whiteSpace: 'pre-wrap'}}>{survey.survey_desc}</Typography>}
                        </PaddedPaper>
                    </Grid>
                    {([...survey.contacts_sent, ...survey.contacts_partial].length === 0 || survey.survey_status !== 'active') &&
                        <Grid item xs={3}>
                            {survey.survey_status === 'active' ?
                                <Button variant="contained" color="secondary" fullWidth onClick={()=>this.props.deployConfirmation('Are you sure you want to end this survey?', 'End Survey', this.endSurvey)}>End Survey</Button>:
                                <PaddedPaper
                                    style={{backgroundColor: colors.importantNote}}
                                >
                                    <Typography variant="body1">Survey has ended</Typography>
                                </PaddedPaper>
                            }
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TabBar
                            handleTabChange={this.setTab}
                            currentTab={currentTab}
                            tabs={[
                                {
                                    label: 'Overview',
                                    value: 'o',
                                },
                                {
                                    label: 'Email Preview',
                                    value: 'ep',
                                },
                                {
                                    label: 'Survey Preview',
                                    value: 'sp',
                                },
                                survey.survey_status === 'active' &&
                                {
                                    label: 'Pending Surveys Invites',
                                    value: 'uc',
                                },
                                survey.survey_status === 'active' &&
                                {
                                    label: 'Survey Invites',
                                    value: 'pc',
                                },
                                survey.survey_status === 'active' &&
                                {
                                    label: 'Started Survey Invites',
                                    value: 'hc',
                                },
                                {
                                    label: 'Completed Survey Invites',
                                    value: 'cc',
                                },
                                {
                                    label: 'Removed Survey Invites',
                                    value: 'rc',
                                },
                                {
                                    label: 'Activity Log',
                                    value: 'acl',
                                },
                                {
                                    label: 'Report',
                                    value: 'r',
                                },
                            ]} 
                        />
                        {
                            currentTab === 'o' &&
                            <PaddedPaper>
                                <Overview survey={survey} pendingContacts={pendingContacts}/>
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'ep' &&
                            <PaddedPaper>
                                <EmailPreview survey={survey}/>
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'sp' &&
                            <PaddedPaper>
                                <SurveyPreview survey={survey} />
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'uc' &&
                            <PaddedPaper>
                                {this.state.survey.form.form_config_linked_part_type !== 'N/A' ?
                                    <UnsentContacts pendingContacts={pendingContacts} survey={survey} sent={[...survey.contacts_sent, ...survey.contacts_partial, ...survey.contacts_complete]} refresh={this.getSurvey}/> :
                                    <UnsentContactsManual pendingContacts={pendingContacts} survey={survey} sent={[...survey.contacts_sent, ...survey.contacts_partial, ...survey.contacts_complete]} refresh={this.getSurvey}/>
                                }
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'pc' &&
                            <PaddedPaper>
                                <PendingContacts uuidReplace={this.uuidReplacer} contacts={survey.contacts_sent} survey={survey} refresh={this.getSurvey} showStickyNotes={this.showStickyNotes}/>
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'hc' &&
                            <PaddedPaper>
                                <PartialContacts uuidReplace={this.uuidReplacer} contacts={survey.contacts_partial} survey={survey} refresh={this.getSurvey} showStickyNotes={this.showStickyNotes}/>
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'cc' &&
                            <PaddedPaper>
                                <CompleteContacts uuidReplace={this.uuidReplacer} contacts={survey.contacts_complete} survey={survey} refresh={this.getSurvey} showStickyNotes={this.showStickyNotes}/>
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'rc' &&
                            <PaddedPaper>
                                <RemovedContacts uuidReplace={this.uuidReplacer} contacts={survey.contacts_removed} survey={survey} refresh={this.getSurvey} showStickyNotes={this.showStickyNotes}/>
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'acl' &&
                            <PaddedPaper>
                                <ActivityLog log={survey.logs} />
                            </PaddedPaper>
                        }
                        {
                            currentTab === 'r' && survey &&
                            <PaddedPaper>
                                <FormReport 
                                    formId={survey.survey_form_id} 
                                    formIds={_.map([...survey.contacts_sent, ...survey.contacts_partial, ...survey.contacts_complete], r => parseInt(r.form.form_id))} 
                                    uuidReplace={this.uuidReplacer}
                                    OverrideTitle={()=>
                                        <Grid container spacing={1}>
                                            <InfoBox
                                                rowSize={4}
                                                icon={IconHelper.survey}
                                                value={[...survey.contacts_sent, ...survey.contacts_partial, ...survey.contacts_complete, ...survey.contacts_removed].length}
                                                title="Survey Invites Sent"
                                            />
                                            <InfoBox
                                                rowSize={4}
                                                icon={IconHelper.email}
                                                value={survey.contacts_sent.length}
                                                title="Survey Invites Not Started"
                                            />
                                            <InfoBox
                                                rowSize={4}
                                                icon={IconHelper.emailOpen}
                                                value={survey.contacts_partial.length}
                                                title="Started Survey Invites"
                                            />
                                            <InfoBox
                                                rowSize={4}
                                                icon={IconHelper.true}
                                                value={survey.contacts_complete.length}
                                                title="Completed Survey Invites"
                                            />
                                        </Grid>
                                    }
                                />
                            </PaddedPaper>
                        }
                    </Grid>
                </Grid>
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, size, variant='system', style=null) => {
            dispatch(deployDialog(content, title, variant, size, style))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        },
        deployConfirmation: (content, title, callback, variant=null) => {
            dispatch(deployConfirmation(content, title, callback, null, variant))
        }
    }
}

export default connect(null, mapDispatchToProps)(SurveySearch);