///StickyNotes
import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';

import CloseDialogButton    from 'Components/Common/Buttons/CloseDialogButton';
import LoadingCircle        from 'Components/Common/LoadingCircle/LoadingCircle';
import AllIcon              from 'Components/Common/Icons/AllIcon';

import icons                from 'Helpers/IconHelper';

import { TextField, Grid, Typography, Checkbox, InputAdornment, Paper, Button } from '@material-ui/core/';
import Dialog from '../Dialogs/Dialog';
import DragFileInput from '../Inputs/DragFileInput';

const initialState = {
    formData:   {
        newNote: '',
        file:    null,
        fileName: ''
    },
    dialogOpen: false,
    notes:      [],
    isLoading:  true,
    access:     {}
}

class StickyNotes extends Component{
    constructor(props) {
        super(props);
        this.state = initialState;

        this.fileRef = React.createRef('file');
    }

    componentDidMount() {
        this.getNotes();
        this.getAccess();
    }

    getAccess = () => {
        let type = null;
        if (this.props.orderId) type = 'sales-order-';
        if (this.props.quoteId)    type = 'sales-quote-';
        if (type) {
            Promise.all([
                API.get(`/staff/my/access/check/${type}create-sticky-note`), 
                API.get(`/staff/my/access/check/${type}delete-sticky-note`), 
                API.get(`/staff/my/access/check/${type}update-sticky-note`), 
                API.get(`/staff/my/access/check/${type}complete-sticky-note`), 
            ])
            .then(([createStickyNoteRes,deleteStickyNoteRes,updateStickyNoteRes,completeStickyNoteRes]) =>  {
                this.setState({
                    ...this.state,
                    access: {
                        create:    createStickyNoteRes.data.has_access      || false,
                        delete:    deleteStickyNoteRes.data.has_access      || false,
                        update:    updateStickyNoteRes.data.has_access      || false,
                        complete:  completeStickyNoteRes.data.has_access    || false
                    }
                });
            })
        }
    }

    getNotes() {
        let params  = {};
        if (this.props.orderId)  params = {orderId: this.props.orderId};
        if (this.props.quoteId)  params = {quoteId: this.props.quoteId};
        if (this.props.woId)     params = {woId: this.props.woId};
        if (this.props.crId)     params = {crId: this.props.crId};
        if (this.props.scId)     params = {scId: this.props.scId};
        API.get(`/misc/stickyNotes`, {params})
        .then((res) => { this.setState({notes: res.data, isLoading: false}); })
    }
    changeNewNote = e => { 
        if (!this.state.formData.newNote){
            if (e.target.value[0] === ' ' || e.target.value[0] === '\n') return;
        }
        this.setState({formData:{...this.state.formData, newNote: e.target.value}}); 
    }

    setNewNote = e => {
        if (e.key === 'Enter'  ) {
            if (this.state.formData.newNote){
                this._setNewNote();
            }
        }
    }

    _setNewNote = () => {
        this.setState({isLoading: true},()=>{
            let formData = new FormData();
            
            if (this.state.formData.file) formData.append('file', this.state.formData.file);
            if (this.state.formData.newNote) formData.append('note', this.state.formData.newNote);
            if (this.state.formData.fileName) formData.append('fileName', this.state.formData.fileName);
    
            if (this.props.orderId) formData.append('orderId', this.props.orderId);
            if (this.props.quoteId) formData.append('quoteId', this.props.quoteId);
            if (this.props.woId)    formData.append('woId', this.props.woId);
            if (this.props.crId)    formData.append('crId', this.props.crId);
            if (this.props.scId)    formData.append('scId', this.props.scId);
    
            API.post(`/misc/stickyNotes`, formData)
            .then(res => { 
                this.setState({
                    formData: {
                        ...this.state.formData, 
                        newNote: '',
                        file: null,
                        fileName: ''
                    },
                    dialogOpen: false
                },this.getNotes);
            })
        });
    }

    handleDelete = id => { 
        API.post(`/misc/stickyNotes/${id}/delete`)
        .then(res => {this.getNotes();});
    }

    handleComplete = id => { 
        API.post(`/misc/stickyNotes/${id}/complete`)
        .then(res => {this.getNotes();});
    }

    handleFileUpload = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        if (!file) return;
        this.setState({formData:{...this.state.formData, file}});
    }

    getPreview = (i) => {
        if (i.sn_type === 'Text') {
            return (
                <Typography 
                    variant='body1'
                    style={{paddingTop: 12, paddingBottom: 12 ,textDecoration: i.sn_completed && 'line-through'}}
                >
                    {i.sn_note}
                </Typography>
            );
        } else {
            switch (i.sn_file_type) {
                case 'image':
                    return (
                        <img 
                            src={i.file_path} 
                            style={{maxWidth: '100%', maxHeight: 250, marginTop: 8, marginBottom: 8}}
                            onClick={()=>window.open(i.file_path, '_blank')}
                        />
                    );
                case 'audio':
                    return (
                        <>
                            <audio controls
                                style={{height: 30, marginTop: 8, marginBottom: 8, width: 280}}
                            >
                                <source src={i.file_path} type="audio/ogg" />
                                <source src={i.file_path} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        </>
                    )
                default:
                    return (
                        <a href={i.file_path} target='_blank'>
                            <Typography 
                                variant='body1'
                                style={{paddingTop: 12, paddingBottom: 12 ,textDecoration: i.sn_completed && 'line-through'}}
                            >
                                {i.sn_file_name}
                            </Typography>
                        </a>
                    );
            }
        }
    }

    render() {

        const {edit, allowFiles} = this.props;
        const {isLoading, notes, formData, access} = this.state;

        return (
            <>
                {isLoading ? <LoadingCircle /> : 
                    <Grid container>
                        {(edit && (!this.props.orderId || access.create)) && 
                            <Grid item  xs={12}>
                                <TextField
                                    value={formData.newNote}
                                    rows={1}
                                    onChange={this.changeNewNote}
                                    onKeyDown={this.setNewNote}
                                    multiline
                                    fullWidth
                                    placeholder={this.props.placeholder || 'Start typing to add a sticky note'}
                                    InputProps={allowFiles && {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {/* <AllIcon noMargin icon={icons.paperclip} onClick={()=>this.fileRef.current.click()} />  */}
                                                <AllIcon noMargin icon={icons.paperclip} onClick={()=>this.setState({
                                                    dialogOpen: true
                                                })} /> 
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        }
                        {_.map(notes, (i, idx) => 
                            <Grid item container xs={12} alignItems='flex-start' style={{borderBottom: (idx + 1) < notes.length && '1px solid rgba(0,0,0,0.1)', flexWrap: 'nowrap'}}>
                                <Grid item>
                                    <Checkbox
                                        checked={i.sn_completed}
                                        onChange={()=>{this.handleComplete(i.sn_id)}}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        disabled={this.props.orderId && !access.complete}
                                        color='primary'
                                    />
                                </Grid>
                                <Grid item style={{paddingLeft: '0.5em'}}>
                                    {this.getPreview(i)}
                                </Grid>
                                { ((!this.props.orderId || access.delete) && edit) && 
                                    <Grid item style={{paddingLeft: '1.5em', marginLeft: 'auto'}}>
                                        {/*
                                            { access.update && <AllIcon noMargin icon={icons.edit} onClick={()=>{this.handleEdit(i.sn_id)}} /> }
                                        */}
                                        <AllIcon style={{padding:0}} noMargin icon={icons.delete} onClick={()=>{this.handleDelete(i.sn_id)}} /> 
                                    </Grid>
                                }
                            </Grid>
                        )}
                        {this.props.dialog &&
                            <Grid item xs={12} >
                                <CloseDialogButton 
                                    onClose={this.props.onClose}
                                    btnRow
                                /> 
                            </Grid>
                        }
                    </Grid>
                }
                <Dialog
                    dialogOpen={this.state.dialogOpen}
                    dialogContent={
                        <Paper style={{padding: 16}}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField 
                                        value={formData.fileName}
                                        onChange={e=>this.setState({formData:{...formData, fileName: e.target.value}})}
                                        label='File Name'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DragFileInput
                                        onChange={this.handleFileUpload}
                                        cancelOnClick={()=>this.setState({formData: {...formData, file: null}})}
                                        fullWidth
                                        file={formData.file}
                                    />
                                </Grid>
                                <Grid item xs={12} className='buttonRow'>
                                    <Button variant='outlined' onClick={()=>this.setState({dialogOpen: false, formData: {...formData, file: null, fileName: ''}})}>cancel</Button>
                                    <Button variant='contained' color='primary' onClick={this._setNewNote} disabled={!formData.file || !formData.fileName} >Add</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    }
                    dialogSize='xs'
                />
                {/* <>
                    <input 
                        style={{display: 'none'}}
                        type='file' 
                        ref={this.fileRef} 
                        onChange={(event)=>this.handleFileUpload(event)}
                    />
                </> */}
            </>
        );
    }
}

export default StickyNotes;