import * as PopNotTypes from './Types/Types';

export function deployPopNotification(data) {
    return (dispatch) => {
        dispatch({
            type: PopNotTypes.DEPLOY_POP_NOTIFICATION,
            payload: {data}
        });
    }
}

export const closePopNotifications = () => {
    return dispatch => {dispatch({type: PopNotTypes.DISCARD_POP_NOTIFICATION});}
}

export const dismissPopNotification = id => {
    return dispatch => {
        dispatch({
            type:       PopNotTypes.DISMISS_POP_NOTIFICATION,
            payload:    id
        });
    }
}


