import React, { Component } from 'react';
import _                    from 'lodash';

import DataTable   from 'Components/Common/DataTables/CiDataTable';
import AllIcon     from 'Components/Common/Icons/AllIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import icons from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';

import { Grid, Typography } from '@material-ui/core/';

const initialState = {
    worksOrder:     {}, 
    returns:    [],
}

function InfoBox({heading, value, icon}) {
    return (
        <Grid item xs={6} lg={3}>
            <PaddedPaper style={{textAlign:'center'}}>
                <AllIcon icon={icon} size='xlarge' />
                <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>{heading}</Typography>
                <Typography variant="h4" style={{textAlign:'center', color: colors.orange}}>{value}</Typography>
            </PaddedPaper>
        </Grid>
    )
}
class BuildReturns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState, 
            worksOrder: this.props.worksOrder,
            returns:    this.props.worksOrder.returns,
        };
    }
    
    render(){

        const { returns } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={3}>
                            <Grid item container spacing={3} xs={12} style={{justifyContent: 'center'}}>
                                <InfoBox heading='Returned Parts' value={_.uniqBy(returns, 'wobsr_part_id').length} icon={icons.parts} />
                                <InfoBox heading='Returned Quantity' value={parseFloat(0 - _.sumBy(returns, i => parseFloat(i.wobsr_qty))).toFixed(3)} icon={icons.parts} />
                            </Grid>
                            {_.map(_.uniqBy(returns,'wobsr_wobs_id'), i => 
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6" style={{marginBottom: 15}}>Stage {i.build_stage.instruction.sais_number}: {i.build_stage.instruction.sais_name}</ Typography>
                                        <DataTable
                                            config={{
                                                key: 'wobsr_id',
                                                dropRow: {
                                                    hideDropArrow: true,
                                                    droppable:     i => i.returns.length > 0,
                                                    style:         {fontWeight: 'bold'},
                                                    columns:       [
                                                        {
                                                            heading:       'Date',
                                                            field:         'wobsr_staff_datetime',
                                                            fieldFormat:   'clenydate',
                                                            sizeToContent: true,
                                                        }, 
                                                        {
                                                            heading:     'reason',
                                                            field:       'wobsr_reason',
                                                        },
                                                        {
                                                            heading:     'Notes',
                                                            field:       i => <box style={{whiteSpace: 'initial'}}>{i.wobsr_notes}</box>,
                                                            style:       { whiteSpace: 'initial'},
                                                        },
                                                        {
                                                            heading:       'Qty',
                                                            field:         i => parseFloat(0 - parseFloat(i.wobsr_qty)).toFixed(3),
                                                            sizeToContent: true,
                                                            style:         {color: colors.orange},
                                                        },
                                                        {
                                                            heading:       'Staff',
                                                            field:         'wobsr_staff_name',
                                                            sizeToContent: true,
                                                        },
                                                    ],
                                                    data: 'returns'
                                                }
                                            }}
                                            columns={[
                                                {
                                                    field:          'part.default_image.file_path',
                                                    fieldFormat:    'image',
                                                    sizeToContent:  true
                                                },
                                                {
                                                    heading:       'Part Number',
                                                    field:         'part.part_number',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading:       'Part Description',
                                                    field:         'part.part_description',
                                                },
                                                {
                                                    heading:       '',
                                                    field:         '',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading:       'Qty',
                                                    field:         i => parseFloat(0 - parseFloat(i.qty)).toFixed(3),
                                                    sizeToContent: true,
                                                    alignment:     'center',
                                                    style:         {color: colors.orange},
                                                }
                                            ]}
                                            rows={_.orderBy(_.map(_.uniqBy(_.filter(returns, {wobsr_wobs_id: i.wobsr_wobs_id}), i => i.part.part_id), i => {
                                                let rets = _.filter(returns, {wobsr_wobs_id: i.wobsr_wobs_id, wobsr_part_id: i.wobsr_part_id});
                                                return {...i, returns: rets, qty: parseFloat(_.sumBy(rets, j => parseFloat(j.wobsr_qty))).toFixed(3)} 
                                            }), i => i.part.part_number )}
                                        />
                                    </PaddedPaper>
                                </Grid>
                            )}
                        </Grid>
                    </PaddedPaper>
                </Grid>
            </Grid>
        )
    }
}

export default BuildReturns;