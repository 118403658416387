import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PaperComponent from './PaperComponent';

const styles = theme => ({
    buttons: {
        color: '#0282C6',
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class FormDialog extends React.Component {
  render() {
    return (
      <React.Fragment>
            <Dialog
                PaperComponent={PaperComponent}
                disableBackdropClick
                disableEscapeKeyDown
                open={this.props.open}
                TransitionComponent={Transition}
                onClose={this.props.close}
                scroll="body"
                fullWidth
            >
                <DialogTitle>{this.props.title || ''}</DialogTitle>
                <DialogContent style={{height: this.props.height ? this.props.height : '350px'}}>
                    {this.props.body}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.close} variant="outlined" color="default">Cancel</Button>
                    <Button onClick={this.props.handleSubmit} autoFocus variant="contained" color="primary">{this.props.submitText ? this.props.submitText : 'Add'}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
  }
}

export default withStyles(styles)(FormDialog);