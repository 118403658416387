import React from 'react';
import { Grid } from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import _ from 'lodash';

export default function ServicePlan({onSubmit, contacts, logicData}) {

    let [value, setValue] = React.useState(logicData);

    let returnContacts = () => {
        if (value !== null){
            onSubmit(_.filter(contacts, (contact) => ( contact.customer?.cust_service_plan ? value : !value )
                && contact.contact_status === 'Active' 
                && [1,4].includes(contact.customer?.cust_account_status_id)
                && _.filter(contact.addresses, {address_active: 1}).length > 0
            ))(value);
        }
    }

    React.useEffect(returnContacts, [value]);

    return (
        <Grid item xs={12}>
            <AutoCompleteSelect
                label="Service Plan *"
                value={value}
                onChange={(e)=>setValue(e.value)}
                fullWidth
                options={[
                    { value: 0, label: 'No Service Plan' },
                    { value: 1, label: 'Service Plan' },
                ]}
                disableSort
                noClear
            />
        </Grid>
    )
}