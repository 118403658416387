import React from 'react';
import { connect } from 'react-redux';
import API from 'API';
import _ from 'lodash';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import Textarea from 'Components/Common/Inputs/Textarea';

import { Button, Grid, TextField, Typography } from '@material-ui/core';

import { closeDialog } from 'Actions/Dialog/Dialog';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import { colors } from 'Helpers/ColourHelper';

const initialState = {
    formErrors: {},
    isLoading:  true,
    formData:   {
        customerId: '',
        templateId: null,
        contactId:  null,
        ccs:        [],
        staffIds:   [],
        subject:    '',
        body:       '',
        postParams: {}
    },
    lists: {
        staff: [],
        contacts: [],
    }
}

class SingleEmailDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getLists();
    }

    getLists = () => {
        const { customerId,  contactId, subject, body, postParams } = this.props;  
        let promise = [];

        Promise.all([
            API.get('/staff/all', { params: { active: 1 } }),
            API.get(`/customers/${customerId}/contacts`)
        ]).then(([staff, customer]) => {
            let lists = {
                staff:    _.map(staff.data, el => ({
                    value: el.staff_id,
                    label: el.staff_first_name + ' ' + el.staff_last_name,
                })),
                contacts: _.map(_.filter(customer.data, i => i.contact_email), el => ({
                    value: el.contact_id,
                    label: el.contact_name + ' (' + el.contact_email + ')',
                    name: el.contact_name,
                    email: el.contact_email
                }))
            }
            this.setState({ 
                lists, 
                isLoading: false ,
                formData: {
                    ...initialState.formData,
                    customerId: customerId,
                    contactId:  contactId,
                    subject:    subject,
                    body:       body,
                    postParams: postParams
                }
            });
        })
    }

    handleSelectChange = name => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: e.value
            }
        });
    }

    handleMultiSelectChange = name => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: e? e.map(el => el.value) : []
            }
        });
    }

    handleChange = name => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: e.target.value
            }
        });
    }

    close = () => {
        if (this.props.onClose) this.props.onClose();
        this.props.closeDialog();
    }

    submit = () => {
        API.post(this.props.emailUrl || '/email/send', {...this.state.formData, ...this.props.postParams})
        .then(res => {

            if (this.props.onSubmit) this.props.onSubmit();
            this.props.closeDialog();
        })
    }

    render(){
        const { isLoading, formData, formErrors, lists } = this.state;
        const { contactId, ccs, staffIds, subject, body } = formData;
        
        if ( isLoading ) return ( <LoadingCircle /> );

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom style={{marginTop: '1em'}}>
                        From: Clenaware Systems {'<online@clenaware.co.uk>'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <AutoCompleteSelect
                        label="To *"
                        value={contactId}
                        options={lists.contacts}
                        onChange={this.handleSelectChange('contactId')}
                        noClear
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <AutoCompleteMultiSelect
                        label="Email CC"
                        value={ccs}
                        options={lists.contacts}
                        onChange={this.handleMultiSelectChange('ccs')}
                        noClear
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <AutoCompleteMultiSelect
                        label="Staff"
                        value={staffIds}
                        options={lists.staff}
                        onChange={this.handleMultiSelectChange('staffIds')}
                        noClear
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                {/*}
                    <TextField
                        label="Subject *"
                        value={subject}
                        onChange={this.handleChange('subject')}
                        fullWidth
                        noClear
                        variant='outlined'
                    />
                {*/}
                    <Textarea
                        label="Body *"
                        value={body}
                        onChange={this.handleChange('body')}
                        fullWidth
                        noClear
                        rows={6}
                    />
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.close}
                    >Don't Send</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => this.props.deployConfirmation(
                                'Are you sure you want to send this email?',
                                'Send Email',
                                () => this.submit()
                            )
                        }
                        disabled={!contactId || !subject || !body}
                        style={{backgroundColor: colors.green}}
                    >Send</Button>
                </Grid>
            </Grid>
        )
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff
    };
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
    closeDialog:        ()                              => dispatch(closeDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(SingleEmailDialog);