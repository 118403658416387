import BarChart from 'Components/Common/Charts/BarChart';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { colors } from 'Helpers/ColourHelper';
import _ from 'lodash';
import React from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListSubheader, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import BubbleChart from 'Components/Common/Charts/BubbleChart';
import LineChart from 'Components/Common/Charts/LineChart';
import PieChart from 'Components/Common/Charts/PieChart';
import MediaTable from 'Components/Common/DataTables/MediaTable';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import IconHelper from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';
import moment from 'moment';

const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
]

const chartColors = [
    "#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7", "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6", "#115f9a", "#1984c5", "#22a7f0", "#48b5c4", "#76c68f", "#a6d75b", "#c9e52f", "#d0ee11", "#d0f400",
    "#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7", "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6", "#115f9a", "#1984c5", "#22a7f0", "#48b5c4", "#76c68f", "#a6d75b", "#c9e52f", "#d0ee11", "#d0f400",
    "#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7", "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6", "#115f9a", "#1984c5", "#22a7f0", "#48b5c4", "#76c68f", "#a6d75b", "#c9e52f", "#d0ee11", "#d0f400",
    "#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7", "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6", "#115f9a", "#1984c5", "#22a7f0", "#48b5c4", "#76c68f", "#a6d75b", "#c9e52f", "#d0ee11", "#d0f400",
    "#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7", "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6", "#115f9a", "#1984c5", "#22a7f0", "#48b5c4", "#76c68f", "#a6d75b", "#c9e52f", "#d0ee11", "#d0f400",
    "#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7", "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6", "#115f9a", "#1984c5", "#22a7f0", "#48b5c4", "#76c68f", "#a6d75b", "#c9e52f", "#d0ee11", "#d0f400",
    "#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7", "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6", "#115f9a", "#1984c5", "#22a7f0", "#48b5c4", "#76c68f", "#a6d75b", "#c9e52f", "#d0ee11", "#d0f400",
];

//elements 
const NestedFormElement = ({ element, forms, key, showForm, uuidReplace }) => {
    return (
        <Paper>
            <Box p={2}>
                    <Grid container spacing={3}>
                        {element?.nestedElements.map((e, eIdx) => <FormReportElement element={e.formData} forms={forms} key={key} setFormPreview={showForm} uuidReplace={uuidReplace} />)}
                    </Grid>
            </Box>
        </Paper>
    )
}

const NestedDropDownElement = ({ element, forms, showForm, groupedForm, uuidReplace }) => {

    const [showDialog, setShowDialog] = React.useState(false);

    let data = [];
    _.each(forms, form => data.push({..._.find(form.results, i => parseInt(i.elementId) === parseInt(element.id)), form}))
    let fields = [...element.elConfig.options]
    let unAnswered = 0;
    fields = _.map(fields, field => ({...field, data: 0, forms: []}));

    _.each(data, i => {
        if (!i || !i?.value){
            unAnswered++;
        } else {
            if (i.value.includes('[') && i.value.includes(']')){
                JSON.parse(i.value).forEach(_value => {
                    let index = _.findIndex(fields, field => field.value === _value.replaceAll('\"', ''));
                    if (index >= 0) {
                        fields[index].data++;
                        fields[index].forms.push(i.form);
                    } else {
                        unAnswered++;
                    }
                });
            }  else {
                //value uses "\" so we need to remove it
                let index = _.findIndex(fields, field => field.value === i.value.replaceAll('\"', ''));
                if (index >= 0) {
                    fields[index].data++;
                    fields[index].forms.push(i.form);
                } else {
                    unAnswered++;
                }
            }
        }
    })

    let max = _.maxBy(fields, 'data').data;

    let grouped = [];
    let nested = [];

    _.each(fields, field => {
        if (field.nestedElements.length > 0){
            nested.push(field);
        } else {
            grouped.push(field);
        }
    })

    return (
        <React.Fragment key={element.id}>
            <Grid container spacing={1} >
                <BarChartElement 
                    showDialog={e=>setShowDialog(
                        {
                            title: `${element.nm} - ${e.label}`,
                            groupedForms: e.forms
                        }
                    )}
                    fields={_.map([...grouped, ...nested], field => {
                        return {
                            ...field,
                            label: field?.label,
                            value: field?.data,
                        }
                    })}
                />
                {_.map(nested, (field, index) => 
                    <>
                        {/* <BarChartElement 
                            showDialog={e=>setShowDialog(
                                {
                                    title: `${element.nm} - ${e.label}`,
                                    groupedForms: e.forms
                                }
                            )}
                            fields={ [
                                {
                                    ...field,
                                    label: field?.label,
                                    value: [field?.data, (max - field?.data)],
                                    color: [colors.primary, '#f0f0f000']
                                },
                            ]} 
                            _props={{
                                stacked: true,
                            }}
                            OverridePercent={()=>
                                <PaddedPaper style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Typography variant='h2' style={{textAlign: 'center'}}>
                                        {field?.data > 0 ? Math.round((field?.data / (forms.length - unAnswered)) * 100) : 0}%
                                    </Typography>
                                </PaddedPaper>
                            }
                        /> */}
                        <Grid item style={{width: 50, textAlign: 'center', paddingTop: '1.5em'}}>
                            <AllIcon
                                icon={IconHelper.dropRowReverse}
                                noMargin
                            />
                        </Grid>
                        <Grid item style={{width: `calc(100% - 50px)`}}>
                            <NestedFormElement element={field} forms={forms} key={index} showForm={showForm} uuidReplace={uuidReplace} />
                        </Grid>
                    </>
                )}
            </Grid>
            {showDialog &&
                <GroupedDialog 
                    data={showDialog}
                    uuidReplace={uuidReplace}
                    onClose={()=>setShowDialog(false)}
                    showForm={showForm}
                />
            }
        </React.Fragment>
    )


}

const BarChartElement = ({ fields, showDialog, _props={}, OverridePercent=null }) => {
    return (
        <>
            <Grid item xs={10}>
                <BarChart
                    data={fields}
                    sidebar
                    hideLegend
                    tick={1}
                    onPointClick={(e) => {if (e.data && e.data.value){showDialog(e.data);}}}
                    {..._props}
                    colors={chartColors}
                />
            </Grid>
            <Grid item xs={2}>
                {OverridePercent ?
                    <OverridePercent /> :
                    <PieChart
                        data={fields}
                        sidebar
                        hideLegend
                        tick={1}
                        onPointClick={(e) => {if (e.data && e.data.value){showDialog(e.data);}}}
                        colors={chartColors}
                        {..._props}
                        labelSegments={{
                            formatter:  function(value, context) {
                                let percent = ( value / _.sum(context.chart.data.datasets[0].data) ) * 100;
                                return Math.round(percent) >= 8 ? `${Math.round(percent)}%` : null;
                            },
                        }}
                    />
                }
            </Grid>
        </>
    )
}
    

const DropDownElement = ({ element, forms, uuidReplace, showForm }) => {
    const [showDialog, setShowDialog] = React.useState(false);
    let data = [];
    _.each(forms, form => data.push({..._.find(form.results, i => parseInt(i.elementId) === parseInt(element.id)), form}))
    let fields = [...element.elConfig.options]
    let unAnswered = 0;
    fields = _.map(fields, field => ({...field, data: 0, forms: []}));

    _.each(data, i => {
        if (!i || !i?.value){
            unAnswered++;
        } else {

            if (i.value.includes('[') && i.value.includes(']')){
                JSON.parse(i.value).forEach(_value => {
                    let index = _.findIndex(fields, field => field.value === _value.replaceAll('\"', ''));
                    if (index >= 0) {
                        fields[index].data++;
                        fields[index].forms.push(i.form);
                    } else {
                        unAnswered++;
                    }
                });
            }  else {
                //value uses "\" so we need to remove it
                let index = _.findIndex(fields, field => field.value === i.value.replaceAll('\"', ''));
                if (index >= 0) {
                    fields[index].data++;
                    fields[index].forms.push(i.form);
                } else {
                    unAnswered++;
                }
            }

        }
    })

    return (
        <React.Fragment key={element.id}>
            <Grid container spacing={1} >
                <BarChartElement 
                    showDialog={e=>setShowDialog(
                        {
                            title: `${element.nm} - ${e.label}`,
                            groupedForms: e.forms
                        }
                    )}
                    fields={_.map(fields, field => {
                        return {
                            ...field,
                            label: field?.label,
                            value: field?.data,
                        }
                    })} 
                />
                {/* {unAnswered > 0 &&
                    <Grid item style={{marginLeft: 'auto'}}>
                        <Typography style={{fontWeight: 'bold'}}>Unanswered: {unAnswered}</Typography>
                    </Grid>
                } */}
            </Grid>
            {showDialog &&
                <GroupedDialog 
                    data={showDialog}
                    uuidReplace={uuidReplace}
                    onClose={()=>setShowDialog(false)}
                    showForm={showForm}
                />
            }
        </React.Fragment>
    )
}

const TextElement = ({ element, forms, showForm }) => {
    let data = [];
    _.each(forms, form => data.push({..._.find(form.results, i => parseInt(i.elementId) === parseInt(element.id)), form_id: form.id}))

    let unAnswered = 0;
    let values = [];
    let placeholder = element?.elConfig?.placeholder;
    let value = null;

    _.each(data, i => {
        value = i?.value && i?.value?.replaceAll('\"', '');
        if (!i || !value || value === placeholder){
            unAnswered++;
        } else {
            //value uses "\" so we need to remove it
           values.push({...i, value});
        }
    })

    const [showMore, setShowMore] = React.useState(false);

    return (
        <React.Fragment key={element.id}>
            <List fullWidth>
                {_.map(values, (value, index) => {
                    let bk = index % 2 === 0 ? colors.background : colors.background2;
                    if (index < 3 || showMore){
                        return (
                            <ListItem style={{borderBottom: `1px solid ${colors.disabled}`, backgroundColor: bk}} >
                                <Typography style={{whiteSpace: 'pre-wrap'}} key={index}>{value.value.replaceAll(/(\\n)/g, "\n").replaceAll(/(\\r)/g, "")}</Typography>
                                <ListItemSecondaryAction>
                                    <AllIcon icon={IconHelper.view} noMargin fixedHeight button size={14} onClick={() => showForm(_.find(forms, f=>f.id === value.form_id))} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    }
                })}
                {( values.length > 3 || unAnswered > 0 )&&
                    <ListItem >
                        <Grid container style={{alignItems: 'center'}}>
                            {values.length > 3 &&
                                <Grid item >
                                    <Button style={{cursor: 'pointer',}} onClick={() => setShowMore(!showMore)}>{showMore ? 'Show Less' : `Show All ${values.length} Answers`}</Button>
                                </Grid>
                            }
                            {/* {unAnswered > 0 &&
                                <Grid item style={{marginLeft: 'auto'}}>
                                    <Typography style={{fontWeight: 'bold'}}>Unanswered: {unAnswered}</Typography>
                                </Grid>
                            } */}
                        </Grid>
                        
                    </ListItem>
                }
            </List>
        </React.Fragment>
    )
}   

const DateElement = ({ element, forms, uuidReplace, showForm }) => {
    const [showDialog, setShowDialog] = React.useState(false);
    let data = {};
    let unAnswered = 0;
    let _forms = {};
    _.each(forms, form =>{
        let _v = _.find(form.results, i => parseInt(i.elementId) === parseInt(element.id));
        if (!_v.value){
            unAnswered++;
        } else {
            let value = _v.value.replaceAll('\"', '');
            if (!data[value]){ 
                data[value] = 0; 
                _forms[value] = [];
            }
            data[value]++;
            _forms[value].push(form);
        }
    })

    let start = _.min(_.keys(data));
    let end = _.max(_.keys(data));

    const showMonth = moment(start).month() !== moment(end).month();

    let diffInDays = moment(end).diff(moment(start), 'days');

    let dates = [];
    for (let m = moment(start); m.isBefore(moment(end)); m.add(1, 'days')) {
        dates.push(m.clone());
    }

    let lineData = _.map(dates, d => {
        return {
            idx: d.format('YYYY-MM-DD'),
            label: d.format('DD/MM/YYYY'),
            value: data[d.format('YYYY-MM-DD')] ?? 0,
            month: d.month(),
            week: d.week(),
            year: d.year(),
            hidePoint: !data[d.format('YYYY-MM-DD')],
            forms: _forms[d.format('YYYY-MM-DD')] ?? []
        }
    })

    return (
        <React.Fragment key={element.id}>
            <Grid container spacing={1}>
                <Grid item xs={showMonth ? 10 : 12}>
                    <LineChart
                        data={lineData}
                        xLabel={
                            (value, index, values) => {
                                if (diffInDays < 30) {
                                    return lineData[index].label;
                                } else if (diffInDays < 60) {
                                    return (
                                        lineData[index].week !== lineData[index - 1]?.week ? 
                                            `Week ${lineData[index].week} (${lineData[index].year})`
                                            : null
                                    )
                                } else if (diffInDays < 365) {
                                    return (
                                        lineData[index].month !== lineData[index - 1]?.month ? 
                                        `${months[lineData[index].month]} (${lineData[index].year})` : null
                                    )
                                } else {
                                    return (
                                        lineData[index].year !== lineData[index - 1]?.year ? 
                                        lineData[index].year : null
                                    )
                                }
                            }
                        }
                        hideLegend
                        tick={1}
                        style={{
                            height: 200
                        }}
                        noPoints
                        onPointClick={(e) => {
                            if (e.data && e.data.value > 0){
                                setShowDialog(
                                    {
                                        title: `${element.nm} - ${e.data.label}`,
                                        groupedForms: e.data.forms
                                    }
                                )
                            }
                        }}
                    />
                </Grid>
                {showMonth &&
                    <Grid item xs={2}>
                        <PieChart
                            colors={chartColors}
                            hideLegend
                            style={{
                                height: 175
                            }}
                            data={
                                _.map(_.uniqBy(dates, d=> d.month()), d => {
                                    let forms = [];
                                    _.each(
                                        _.range(0, 30), day => {
                                            let check = `${d.year()}-${(d.month()+1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                                            forms = [...forms, ...(_forms[check] ?? [])];
                                        }
                                    )
                                    return {
                                        label: d.format('MMM YYYY'),
                                        value: _.sumBy(_.filter(dates, i => i.month() === d.month() && i.year() === d.year()), d => data[d.format('YYYY-MM-DD')] ?? 0) ?? 0,
                                        forms: forms
                                    }
                                })
                            }
                            labelSegments={{
                                formatter:  function(value, context) {
                                    let percent = ( value / _.sum(context.chart.data.datasets[0].data) ) * 100;
                                    return Math.round(percent) >= 8 ? _.uniqBy(dates, d=> d.month())[context.dataIndex].format('MMM YYYY') : null;
                                },
                            }}
                            onPointClick={(e) => {
                                if (e.data && e.data.value > 0){
                                    setShowDialog(
                                        {
                                            title: `${element.nm} - ${e.data.label}`,
                                            groupedForms: e.data.forms
                                        }
                                    )
                                }
                            }}
                        />
                    </Grid>
                }
            </Grid>
            {showDialog &&
                <GroupedDialog 
                    data={showDialog}
                    uuidReplace={uuidReplace}
                    onClose={()=>setShowDialog(false)}
                    showForm={showForm}
                />
            }
        </React.Fragment>
    )
}


const TimeElement = ({ element, forms, showForm, uuidReplace }) => {

    const [showDialog, setShowDialog] = React.useState(false);

    let dataSets = {};

    _.each([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23], t => {
        dataSets[`${t.toString().padStart(2, '0')}:00`] = {
            label: `${t.toString().padStart(2, '0')}:00`,
            hour: t,
            minute: 0,
            value: 0,
            forms: [],
        }
        dataSets[`${t.toString().padStart(2, '0')}:30`] = {
            label: `${t.toString().padStart(2, '0')}:30`,
            value: 0,
            hour: t,
            minute: 30,
            forms: [],
        }
    })

    dataSets[`24:00`] = {
        label: `24:00`,
        hour: 24,
        minute: 0,
        value: 0,
        forms: {}
    }

    let data = {};
    let unAnswered = 0;
    let _forms = {};
    _.each(forms, form =>{
        let _v = _.find(form.results, i => parseInt(i.elementId) === parseInt(element.id));
        if (!_v.value){
            unAnswered++;
        } else {
            let value = _v.value.replaceAll('\"', '');
            if (!data[value]){ 
                data[value] = 0; 
                _forms[value] = [];
            }
            data[value]++;
            _forms[value].push(form);
        }
    })

    _.each(_.keys(_forms), key => {
        let time = key.split(':');
        let _dataset =  dataSets[`${time[0].toString().padStart(2, '0')}:${parseFloat(time[1]) < 30 ? '00' : '30'}`];
        _dataset.value += _forms[key].length;
        _dataset.forms = {..._dataset.forms, ...{[key]: _forms[key]}};
        dataSets[`${time[0].toString().padStart(2, '0')}:${parseFloat(time[1]) < 30 ? '00' : '30'}`] = _dataset;
    })
    return (
        <React.Fragment key={element.id}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <LineChart
                        data={_.map(dataSets, d => ({
                            ...d, hidePoint: !d.value
                        }))}
                        hideLegend
                        tick={1}
                        style={{
                            height: 200
                        }}
                        onPointClick={(e) => {
                            if (e.data && e.data.value > 0){
                                setShowDialog(
                                    {
                                        title: `${element.nm} - ${e.data.label}`,
                                        groupedForms: e.data.forms
                                    }
                                )
                            }
                        }}
                        fill
                        tension={0.3}
                        tLabel={(value) => {
                            let ds = dataSets[value.label];
                            return _.map(_.orderBy(_.keys(ds.forms), i=> parseInt(i.split(':')[1]), 'asc'), (key) => {
                                return `${key} (${ds.forms[key].length})`;
                            }).join('\n');
                        }}
                    />
                </Grid>
                {/* {unAnswered > 0 &&
                    <Grid item style={{marginLeft: 'auto'}}>
                        <Typography style={{fontWeight: 'bold'}}>Unanswered: {unAnswered}</Typography>
                    </Grid>
                } */}
            </Grid>
            {showDialog &&
                <GroupedDialog 
                    data={showDialog}
                    uuidReplace={uuidReplace}
                    onClose={()=>setShowDialog(false)}
                    showForm={showForm}
                />
            }
        </React.Fragment>
    )
}

const FileUploadElement = ({ element, forms, showForm }) => {

    const [showMore, setShowMore] = React.useState(false);

    let unAnswered = 0;
    let images = [];


    _.each(forms, form =>{
        let _v = _.find(form.results, i => parseInt(i.elementId) === parseInt(element.id));
        let _value = JSON.parse(_v?.value); 
        if (!_value || _value.length === 0){
            unAnswered++;
        } else {
            _.each(_value, v => 
                images.push({media: v, form})
            )
        }
    })


    return (
        <React.Fragment key={element.id}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MediaTable
                        config={{
                            rowSize: 6,
                            itemPerRow: 6,
                        }}
                        items={
                            showMore ? images : images.slice(0, 6)
                        }
                        media='media'
                        fields={
                            [
                                {
                                    actions: i => ([
                                        {
                                            icon: IconHelper.view,
                                            onClick: () => showForm(i.form),
                                            name: 'View Form'
                                        }
                                    ]) 
                                }
                            ]
                        }
                    />
                </Grid>
                {( images.length > 6 || unAnswered > 0 )&&
                    <ListItem >
                        <Grid container style={{alignItems: 'center'}}>
                            {images.length > 6 &&
                                <Grid item >
                                    <Button style={{cursor: 'pointer',}} onClick={() => setShowMore(!showMore)}>{showMore ? 'Show Less' : `Show All ${images.length} Answers`}</Button>
                                </Grid>
                            }
                            {/* {unAnswered > 0 &&
                                <Grid item style={{marginLeft: 'auto'}}>
                                    <Typography style={{fontWeight: 'bold'}}>Unanswered: {unAnswered}</Typography>
                                </Grid>
                            } */}
                        </Grid>
                        
                    </ListItem>
                }
            </Grid>
        </React.Fragment>
    )

}

const NumberInputElement = ({ element, forms, uuidReplace, showForm }) => {

    const [showDialog, setShowDialog] = React.useState(false);

    let unAnswered = 0;
    let data = [];

    _.each(forms, form =>{
        let _v = _.find(form.results, i => parseInt(i.elementId) === parseInt(element.id));
        let _value = JSON.parse(_v?.value); 
        if (!_value){
            unAnswered++;
        } else {
            if (!data[_value.toString()]){
                data[_value.toString()] = {
                    value: 0,
                    forms: []
                };
            }
            data[_value.toString()].value++;
            data[_value.toString()].forms.push(form);
        }
    })

    let max = _.maxBy(_.keys(data), i=>parseInt(i));

    let datasets = [];

    for (let i = 0; i <= max; i++){ datasets.push( { ...( data[i] ? data[i] : {value: 0, forms: []} ) } ) } 

    return (
        <React.Fragment key={element.id}>
            <Grid container spacing={1}>
                <BarChartElement 
                    showDialog={e=>{
                        if (e && e.value > 0){
                            setShowDialog(
                                {
                                    title: `${element.nm} - ${e.label}`,
                                    groupedForms: e.forms
                                }
                            )
                        }
                    }}
                    fields={_.map(_.keys(datasets), k => ({
                        ...datasets[k],
                        label: k,
                    }))} 
                    _props={{
                        yLabel:
                            (value) => {
                                if (!value) return null;
                                return `${value}`;
                            },
                        stacked: false
                    }}
                />
                {/* {unAnswered > 0 &&
                    <Grid item style={{marginLeft: 'auto'}}>
                        <Typography style={{fontWeight: 'bold'}}>Unanswered: {unAnswered}</Typography>
                    </Grid>
                } */}
            </Grid>
            {showDialog &&
                <GroupedDialog 
                    data={showDialog}
                    uuidReplace={uuidReplace}
                    onClose={()=>setShowDialog(false)}
                    showForm={showForm}
                />
            }
        </React.Fragment>
    )
}


const SignatureElement = ({ element, forms, showForm }) => {

    const [showMore, setShowMore] = React.useState(false);
    let unAnswered = 0;
    let values = [];
    let item = null;

    _.each(forms, form => {
        item = _.find(form.results, i => parseInt(i.elementId) === parseInt(element.id))
        if (!item || !item?.value || !JSON.parse(item?.value)){
            unAnswered++;
        } else {
            values.push({
                form: form,
                ...JSON.parse(item?.value)
            })
        }
    })


    return (
        <React.Fragment key={element.id}>
            <List fullWidth>
                {_.map(values, (value, index) => {
                    let bk = index % 2 === 0 ? colors.background : colors.background2;
                    if (index < 3 || showMore){
                        return (
                            <ListItem style={{borderBottom: `1px solid ${colors.disabled}`, backgroundColor: bk}} >
                                <ListItemAvatar>
                                    <ImageWithError
                                        src={value.signature}
                                        width={'auto'}
                                        style={{
                                            maxHeight: 30,
                                            maxWidth: 50,
                                        }}
                                    />
                                </ListItemAvatar>
                                <Grid container spacing={1} style={{marginLeft: '1em'}}>
                                    <Grid item>
                                        {value.name}
                                    </Grid>
                                    <Grid item style={{marginLeft: 'auto', marginRight: '1em'}}>
                                        {clenyDate(value.date)}
                                    </Grid>
                                </Grid>
                                <ListItemSecondaryAction>
                                    <AllIcon icon={IconHelper.view} noMargin fixedHeight button size={14} onClick={() => showForm(value.form)} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    }
                })}
                {( values.length > 3 || unAnswered > 0 )&&
                    <ListItem >
                        <Grid container style={{alignItems: 'center'}}>
                            {values.length > 3 &&
                                <Grid item >
                                    <Button style={{cursor: 'pointer',}} onClick={() => setShowMore(!showMore)}>{showMore ? 'Show Less' : `Show All ${values.length} Answers`}</Button>
                                </Grid>
                            }
                            {/* {unAnswered > 0 &&
                                <Grid item style={{marginLeft: 'auto'}}>
                                    <Typography style={{fontWeight: 'bold'}}>Unanswered: {unAnswered}</Typography>
                                </Grid>
                            } */}
                        </Grid>
                        
                    </ListItem>
                }
            </List>
        </React.Fragment>
    )
}

function DateTimeElement({ element, forms, uuidReplace, showForm }) {
    const [showDialog, setShowDialog] = React.useState(false);

    let unAnswered = 0;
    let data = {};
    let timeData = {};
    let dataData = {};
    let item = null, value = null, _moment = null, date = null, time = null, valueIdx = -1, _time=0;

    _.each(forms, form => {
        item = _.find(form.results, i => parseInt(i.elementId) === parseInt(element.id))
        if (!item || !item?.value || !JSON.parse(item?.value)){
            unAnswered++;
        } else {
            value = JSON.parse(item?.value);
            _moment = moment(value);
            date = _moment.format('YYYYMMDD');
            time = _moment.format('HHmm');
            
            if (!data[date]){
                data[date] = { 
                    value: [], 
                };
            }

            _time = parseInt((parseInt(time.toString().substring(0,2)) - 1) * 3600) + parseInt(time.toString().substring(2,4) * 60);
            valueIdx = _.findIndex(data[date].value, { x: parseInt(_moment.unix()) - _time, y: _time});
            if (valueIdx >= 0){
                data[date].value[valueIdx].r += 1;
                data[date].value[valueIdx].forms.push(form);
            } else {
                data[date].value.push({ 
                    x: parseInt(_moment.unix()) - _time, 
                    y: _time, 
                    r: 3  , 
                    forms: [form] 
                });
            }

            if (!timeData[time.toString().substring(0,2)]){
                timeData[time.toString().substring(0,2)] = { 
                    label: `${_moment.format('HH')}:00`,
                    value: 0, 
                    title: `${element.nm} - ${_moment.format('HH')}:00`,
                    groupedForms: {}
                };
            }

            timeData[time.toString().substring(0,2)].value++;
            if (!timeData[time.toString().substring(0,2)].groupedForms[`${time.toString().substring(0,2)}:${time.toString().substring(2,4)}`]){
                timeData[time.toString().substring(0,2)].groupedForms[`${time.toString().substring(0,2)}:${time.toString().substring(2,4)}`] = [];
            }
            timeData[time.toString().substring(0,2)].groupedForms[`${time.toString().substring(0,2)}:${time.toString().substring(2,4)}`].push(form);

            if (!dataData[date]){
                dataData[date] = { 
                    label: _moment.format('DD/MM/YYYY'),
                    value: 0, 
                    title: `${element.nm} - ${_moment.format('DD/MM/YYYY')}`,
                    groupedForms: {}
                };
            }

            dataData[date].value++;
            if (!dataData[date].groupedForms[`${time.toString().substring(0,2)}:${time.toString().substring(2,4)}`]){
                dataData[date].groupedForms[`${time.toString().substring(0,2)}:${time.toString().substring(2,4)}`] = [];
            }
            dataData[date].groupedForms[`${time.toString().substring(0,2)}:${time.toString().substring(2,4)}`].push(form);

        }
    })

    return (
        <React.Fragment key={element.id}>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <BubbleChart 
                        colors={chartColors}
                        data={_.values(data)}
                        hideLegend
                        style={{
                            height: 200
                        }}
                        xLabel={(value, index, values) => {
                            return  moment.unix(value).format('DD/MM/YYYY');
                        }}
                        yLabel={(value, index, values) => {
                            return moment.unix(value).format('HH:mm');
                        }}
                        tLabel={(value) => {
                            let item = value.dataset.data[value.dataIndex]
                            return `${moment.unix(item.x).format('DD/MM/YYYY')} ${moment.unix(item.y).format('HH:mm')} - ${item.r - 2} recipient`
                        }}
                        onPointClick={(e) => {
                            let item = e.data.value[e.index];
                            if (item.forms.length > 0){
                                setShowDialog(
                                    {
                                        title: `${element.nm} - ${item.x.toString().substring(6,8)}/${item.x.toString().substring(4,6)}/${item.x.toString().substring(0,4)} ${item.y.toString().substring(0,2)}:${item.y.toString().substring(2,4)}`,
                                        groupedForms: item.forms
                                    }
                                )
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <PieChart
                        data={_.values(timeData)}
                        colors={chartColors}
                        style={{
                            height: 175
                        }}
                        sidebar
                        hideLegend
                        tick={1}
                        onPointClick={(e) => {if (e.data && e.data.value){setShowDialog(e.data);}}}
                        labelSegments={{
                            formatter:  function(value, context) {
                                let percent = ( value / _.sum(context.chart.data.datasets[0].data) ) * 100;
                                return Math.round(percent) >= 8 ? _.values(timeData)[context.dataIndex].label : null;
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <PieChart
                        data={_.values(dataData)}
                        colors={chartColors}
                        style={{
                            height: 175
                        }}
                        sidebar
                        hideLegend
                        tick={1}
                        onPointClick={(e) => {if (e.data && e.data.value){setShowDialog(e.data);}}}
                        labelSegments={{
                            formatter:  function(value, context) {
                                let percent = ( value / _.sum(context.chart.data.datasets[0].data) ) * 100;
                                return Math.round(percent) >= 8 ? _.values(dataData)[context.dataIndex].label : null;
                            },
                        }}
                    />
                </Grid>
            </Grid>
            {showDialog &&
                <GroupedDialog 
                    data={showDialog}
                    uuidReplace={uuidReplace}
                    onClose={()=>setShowDialog(false)}
                    showForm={showForm}
                />
            }
        </React.Fragment>
    )
}

function JobTimeElement({ element, forms, uuidReplace, showForm }) {
    const [showDialog, setShowDialog] = React.useState(false);
    let unAnswered = 0;
    let dataSets = {};

    _.each([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23], t => {
        dataSets[`${t.toString().padStart(2, '0')}:00`] = {
            label: `${t.toString().padStart(2, '0')}:00`,
            hour: t,
            minute: 0,
            value: {
                Arrival: 0,
                Departure: 0,
                WorkTime: 0
            },
            groupedForms: {
                Arrival: [],
                Departure: [],
                WorkTime: []
            }
        }
        dataSets[`${t.toString().padStart(2, '0')}:30`] = {
            label: `${t.toString().padStart(2, '0')}:30`,
            value: {
                Arrival: 0,
                Departure: 0,
                WorkTime: 0
            },
            hour: t,
            minute: 30,
            groupedForms: {
                Arrival: [],
                Departure: [],
                WorkTime: []
            }
        }
    })

    dataSets[`24:00`] = {
        label: `24:00`,
        hour: 24,
        minute: 0,
        value: {
            Arrival: 0,
            Departure: 0,
            WorkTime: 0
        },
        groupedForms: {
            Arrival: [],
            Departure: [],
            WorkTime: []
        }
    }

    let value = null;

    _.each(forms, form =>{
        let item = _.find(form.results, i => parseInt(i.elementId) === parseInt(element.id));
        if (!item.value){
            unAnswered++;
        } else {
            value = JSON.parse(item.value);
            //set start
            dataSets[`${value.arrival.split(':')[0].padStart(2, '0')}:${parseInt(value.arrival.split(':')[1]) < 31 ? '00' : '30' }`].value.Arrival++;
            dataSets[`${value.departure.split(':')[0].padStart(2, '0')}:${parseInt(value.departure.split(':')[1]) < 31 ? '00' : '30' }`].value.Departure++;

            dataSets[`${value.arrival.split(':')[0].padStart(2, '0')}:${parseInt(value.arrival.split(':')[1]) < 31 ? '00' : '30' }`].groupedForms.Arrival.push(form);
            dataSets[`${value.departure.split(':')[0].padStart(2, '0')}:${parseInt(value.departure.split(':')[1]) < 31 ? '00' : '30' }`].groupedForms.Departure.push(form);
            
            //set time
            if (parseInt(value.arrival.split(':')[0]) < parseInt(value.departure.split(':')[0])) {
                _.each(_.range(parseInt(value.arrival.split(':')[0]), parseInt(value.departure.split(':')[0])), i => {
                    dataSets[`${i.toString().padStart(2, '0')}:${'00'}`].value.WorkTime++;
                    dataSets[`${i.toString().padStart(2, '0')}:${'30'}`].value.WorkTime++;
                    dataSets[`${i.toString().padStart(2, '0')}:${'00'}`].groupedForms.WorkTime.push(form);
                    dataSets[`${i.toString().padStart(2, '0')}:${'30'}`].groupedForms.WorkTime.push(form);
                })
            } else {
                dataSets[`${value.arrival.split(':')[0].padStart(2, '0')}:${parseInt(value.arrival.split(':')[1]) < 31 ? '00' : '30' }`].value.WorkTime++;
                dataSets[`${value.arrival.split(':')[0].padStart(2, '0')}:${parseInt(value.arrival.split(':')[1]) < 31 ? '00' : '30' }`].groupedForms.WorkTime.push(form);
            }
            if (parseInt(value.arrival.split(':')[1]) < parseInt(value.departure.split(':')[1])) {
                dataSets[`${value.departure.split(':')[0].padStart(2, '0')}:${parseInt(value.departure.split(':')[1]) < 31 ? '00' : '30' }`].value.WorkTime++;
                dataSets[`${value.departure.split(':')[0].padStart(2, '0')}:${parseInt(value.departure.split(':')[1]) < 31 ? '00' : '30' }`].groupedForms.WorkTime.push(form);
            }
        }
    })

    return (
        <React.Fragment key={element.id}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <LineChart
                        colors={chartColors}
                        data={_.map(_.values(dataSets), d => ({
                            ...d, hidePoint: 1
                        }))}
                        tick={1}
                        style={{
                            height: 250
                        }}
                        onPointClick={(e) => {
                            if (e.data){
                                setShowDialog(
                                    {
                                        title: `${element.nm} - ${e.data.label}`,
                                        groupedForms: e.data.groupedForms
                                    }
                                )
                            }
                        }}
                        fill
                        tension={0.3}
                    />
                </Grid>
            </Grid>
            {showDialog &&
                <GroupedDialog 
                    data={showDialog}
                    uuidReplace={uuidReplace}
                    onClose={()=>setShowDialog(false)}
                    showForm={showForm}
                />
            }
        </React.Fragment>
    )    
}

function StaticTableElement({ element, forms, showForm }) {
    let unAnswered = 0;
    let dataSets = [];
    const [showMore, setShowMore] = React.useState(false);

    _.each(forms, form =>{
        let item = _.find(form.results, i => parseInt(i.elementId) === parseInt(element.id));
        if (!item.value){
            unAnswered++;
        } else {
            let value = JSON.parse(item.value);
            dataSets.push({ value, form });
        }
    })

    return (
        <React.Fragment key={element.id}>
            <List fullWidth>
                {_.map(dataSets, (value, index) => {
                    let bk = index % 2 === 0 ? colors.background : colors.background2;
                    if (index < 3 || showMore){
                        return (
                            <ListItem style={{borderBottom: `1px solid ${colors.disabled}`, backgroundColor: bk}} >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            {_.map(element.elConfig.data.columns, c => 
                                                <TableCell>{c.name}</TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {_.map(value.value, v => 
                                            <TableRow>
                                                <TableCell>{v.name}</TableCell>
                                                {_.map(v.columns, c =>
                                                    <TableCell>{c.value}</TableCell>
                                                )}
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                <ListItemSecondaryAction>
                                    <AllIcon icon={IconHelper.view} noMargin fixedHeight button size={14} onClick={() => showForm(value.form)} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    }
                })}
                {( dataSets.length > 3 || unAnswered > 0 )&&
                    <ListItem >
                        <Grid container style={{alignItems: 'center'}}>
                            {dataSets.length > 3 &&
                                <Grid item >
                                    <Button style={{cursor: 'pointer',}} onClick={() => setShowMore(!showMore)}>{showMore ? 'Show Less' : `Show All ${dataSets.length} Answers`}</Button>
                                </Grid>
                            }
                        </Grid>
                    </ListItem>
                }
            </List>
        </React.Fragment>
    )

}

function DynamicTableElement({ element, forms, showForm }) {
    let unAnswered = 0;
    let dataSets = [];
    const [showMore, setShowMore] = React.useState(false);

    _.each(forms, form =>{
        let item = _.find(form.results, i => parseInt(i.elementId) === parseInt(element.id));
        if (!item.value){
            unAnswered++;
        } else {
            let value = JSON.parse(item.value);
            dataSets.push({ value, form });
        }
    })

    return (
        <React.Fragment key={element.id}>
            <List fullWidth>
                {_.map(dataSets, (value, index) => {
                    let bk = index % 2 === 0 ? colors.background : colors.background2;
                    if (index < 3 || showMore){
                        return (
                            <ListItem style={{borderBottom: `1px solid ${colors.disabled}`, backgroundColor: bk}} >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {_.map(element.elConfig.data.columns, c => 
                                                <TableCell>{c.name}</TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {_.map(value.value, v => 
                                            <TableRow>
                                                {_.map(v, c =>
                                                    <TableCell>{c.value}</TableCell>
                                                )}
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                <ListItemSecondaryAction>
                                    <AllIcon icon={IconHelper.view} noMargin fixedHeight button size={14} onClick={() => showForm(value.form)} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    }
                })}
                {( dataSets.length > 3 || unAnswered > 0 )&&
                    <ListItem >
                        <Grid container style={{alignItems: 'center'}}>
                            {dataSets.length > 3 &&
                                <Grid item >
                                    <Button style={{cursor: 'pointer',}} onClick={() => setShowMore(!showMore)}>{showMore ? 'Show Less' : `Show All ${dataSets.length} Answers`}</Button>
                                </Grid>
                            }
                        </Grid>
                    </ListItem>
                }
            </List>
        </React.Fragment>
    )
}

function PartsTableElement({ element, forms, showForm }) {
    let unAnswered = 0;
    let dataSets = [];
    const [showMore, setShowMore] = React.useState(false);

    _.each(forms, form =>{
        let item = _.find(form.results, i => parseInt(i.elementId) === parseInt(element.id));
        if (!item.value){
            unAnswered++;
        } else {
            let value = JSON.parse(item.value);
            dataSets.push({ value, form });
        }
    })

    return (
        <React.Fragment key={element.id}>
            <List fullWidth>
                {_.map(dataSets, (value, index) => {
                    let bk = index % 2 === 0 ? colors.background : colors.background2;
                    if (index < 3 || showMore){
                        return (
                            <ListItem style={{borderBottom: `1px solid ${colors.disabled}`, backgroundColor: bk}} >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {_.map(element.elConfig.data.columns, c => 
                                                <TableCell>{c.name}</TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {_.map(value.value, v => 
                                            <TableRow>
                                                {_.map(v, c =>{
                                                    return(
                                                        <TableCell>{_.isObject(c) ? c.value.label : JSON.stringify(c)}</TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                <ListItemSecondaryAction>
                                    <AllIcon icon={IconHelper.view} noMargin fixedHeight button size={14} onClick={() => showForm(value.form)} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    }
                })}
                {( dataSets.length > 3 || unAnswered > 0 )&&
                    <ListItem >
                        <Grid container style={{alignItems: 'center'}}>
                            {dataSets.length > 3 &&
                                <Grid item >
                                    <Button style={{cursor: 'pointer',}} onClick={() => setShowMore(!showMore)}>{showMore ? 'Show Less' : `Show All ${dataSets.length} Answers`}</Button>
                                </Grid>
                            }
                        </Grid>
                    </ListItem>
                }
            </List>
        </React.Fragment>
    )
}


let ChildElement = ({element, forms, key, setFormPreview, uuidReplace }) => {
    switch(element.elType) {
        case 'Dropdown':
        case 'Radio':
        case 'Checkbox':
            return (
                <Grid item xs={12} key={key}>
                    {
                        (_.find(element.elConfig.options, i => i?.nestedElements?.length > 0)) ?  
                            <NestedDropDownElement element={element} forms={forms} showForm={setFormPreview} uuidReplace={uuidReplace} /> : 
                            <DropDownElement element={element} forms={forms} showForm={setFormPreview} uuidReplace={uuidReplace} />
                    }
                </Grid>
            );
        case 'Text Field':
        case 'Text Area':
            return (<Grid item xs={12} key={key}><TextElement element={element} forms={forms} showForm={setFormPreview} uuidReplace={uuidReplace} /></Grid>);
        case 'Date':
            return (<Grid item xs={12} key={key}><DateElement element={element} forms={forms} showForm={setFormPreview} uuidReplace={uuidReplace} /></Grid>);
        case 'Time':
            return (<Grid item xs={12} key={key}><TimeElement element={element} forms={forms} showForm={setFormPreview} uuidReplace={uuidReplace} /></Grid>);
        case 'File Upload':
            return (<Grid item xs={12} key={key}><FileUploadElement element={element} forms={forms} showForm={setFormPreview} uuidReplace={uuidReplace} /></Grid>);
        case 'Number Input':
            return (<Grid item xs={12} key={key}><NumberInputElement element={element} forms={forms} showForm={setFormPreview} uuidReplace={uuidReplace} /></Grid>);
        case 'Signature':
            return (<Grid item xs={12} key={key}><SignatureElement element={element} forms={forms} showForm={setFormPreview} uuidReplace={uuidReplace} /></Grid>);
        case 'Date / Time':
            return (<Grid item xs={12} key={key}><DateTimeElement element={element} forms={forms} showForm={setFormPreview} uuidReplace={uuidReplace} /></Grid>);
        case 'Job Time':
            return (<Grid item xs={12} key={key}><JobTimeElement element={element} forms={forms} showForm={setFormPreview} uuidReplace={uuidReplace} /></Grid>);
        case 'Static Table': 
            return (<Grid item xs={12} key={key}><StaticTableElement element={element} forms={forms} showForm={setFormPreview} uuidReplace={uuidReplace} /></Grid>);
        case 'Dynamic Table':
            return (<Grid item xs={12} key={key}><DynamicTableElement element={element} forms={forms} showForm={setFormPreview} uuidReplace={uuidReplace} /></Grid>);
        case 'Parts Table':
            return (<Grid item xs={12} key={key}><PartsTableElement element={element} forms={forms} showForm={setFormPreview} uuidReplace={uuidReplace} /></Grid>);
        default:
            console.log(element.elType, element);
            return (<></>);
    }
};

function GroupedDialog({data, uuidReplace, onClose, showForm}) {
    let {title, groupedForms} = data;

    let forms = {};
    let subHeading = [];
    if (_.isArray(groupedForms)){
        forms = _.map(groupedForms, i => ({ uuid: i.uuid, id: i.id }))
    } else {
        _.each(_.keys(groupedForms), (k) => {
            subHeading.push(k);
            forms[k] = _.map(groupedForms[k], i => ({ uuid: i.uuid, id: i.id }))
        })
    }

    return !!data ?
        <Dialog
            open={true}
            maxWidth='sm'
            fullWidth
        >
            <DialogTitle
                style={{
                    backgroundColor: colors.primary,
                    color: colors.white,
                    padding: '0.5em 1em',
                }}
            >{title}</DialogTitle>
            <DialogContent>
                <List>
                    { 
                        subHeading?.length > 0 ?
                            _.map(subHeading, k => forms[k].length > 0 ?
                                <>
                                    <ListSubheader>{k}</ListSubheader>
                                    {
                                        _.map(forms[k], (d, index) => 
                                            <ListItem key={index}>
                                                <Typography>{
                                                    uuidReplace ?
                                                        uuidReplace(d.uuid) :
                                                        d.uuid}
                                                </Typography>
                                                <ListItemSecondaryAction>
                                                    <AllIcon icon={IconHelper.view} noMargin fixedHeight button size={14} onClick={() => {showForm(d)}} />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        )
                                    }
                                </> : <></>
                            )
                            : 
                            _.map(forms, (d, index) => 
                                <ListItem key={index}>
                                    <Typography>{
                                        uuidReplace ?
                                            uuidReplace(d.uuid) :
                                            d.uuid}
                                    </Typography>
                                    <ListItemSecondaryAction>
                                        <AllIcon icon={IconHelper.view} noMargin fixedHeight button size={14} onClick={() => {showForm(d)}} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog> : <></>
    
}

function QuestionBreakdown({forms, element}) {
    const elementId = element.id;

    let sent = forms.length;
    let answered = 0, unAnswered = 0;
    let placeholder = element?.elConfig?.placeholder;

    _.each(forms, f => {
        let _v = _.find(f.results, i => parseInt(i.elementId) === parseInt(element.id));
        let value = _v?.value ? JSON.parse(_v?.value) : null;
        if (_v && value && (placeholder ? value !== placeholder : true) && (_.isArray(value) ? value.length > 0 : true)){
            answered++;
        } else {
            unAnswered++;
        }
    })

    return (
        <React.Fragment key={`QB${elementId}`}>
            <Typography variant="body2" paragraph
                fontWeight='bold'
                style={{
                    fontWeight: 'bold',
                }}
            >
                <Grid container spacing={3}>
                    <Grid item>
                        <>Sent:</> {sent}
                    </Grid>
                    <Grid item>
                        <>Answered:</> {answered} ({answered > 0 ? Math.round((answered / sent) * 100) : 0}%)
                    </Grid>
                    <Grid item>
                        <>Unanswered:</> {unAnswered}  ({unAnswered > 0 ? Math.round((unAnswered / sent) * 100) : 0}%)
                    </Grid>
                </Grid>
            </Typography>
        </React.Fragment>
    )
}

function FormReportElement(props) {

    const { setFormPreview,  element, forms, key, uuidReplace } = props;

    if (['Information', 'General Information', 'Information Selector'].includes(element.elType)){
        return ( <></> ) 
    }

    return (
        <React.Fragment key={key}>
            <Grid item xs={12} align="left">
                <Typography variant="body1"
                    style={{
                        backgroundColor: colors.primary,
                        color: colors.white,
                        padding: '0.5em 1em',
                        marginBottom: '0.5em'
                    }}
                >
                    <b>{element?.nm}</b>
                    {element?.elConfig?.isRequired ? <span style={{color:'red'}}> *</span> : null}
                    {' '}
                    {element?.info && element?.info?.length > 0 && (
                        <AllIcon icon='circle-info' noMargin fixedHeight button size={14} />
                    )}
                </Typography>
                {element?.description &&
                    <Typography variant="caption" paragraph>
                        {element?.description}
                    </Typography>
                }
                <QuestionBreakdown forms={forms} element={element}/>
                <ChildElement  element={element} forms={forms} key={key} setFormPreview={setFormPreview} uuidReplace={uuidReplace} />
                {element?.elConfig?.resultType == 'pre-populated' && element?.elConfig?.resultTypeData && (
                    <Typography variant="caption"  style={{backgroundColor: 'aliceblue', padding: '4px'}}>
                        <b>Result pre-populated:</b> {element?.elConfig?.resultTypeData?.form?.label} - {element?.elConfig?.resultTypeData?.ref}
                    </Typography>
                )}
                {element?.elConfig?.resultType == 'reusable' && (
                    <Typography variant="caption"  style={{backgroundColor: 'aliceblue', padding: '4px'}}>
                        <b>Result reusable ref:</b> {element?.elConfig?.resultTypeData?.ref}
                    </Typography>
                )}
            </Grid>
        </React.Fragment>
    )
};  

export default FormReportElement