import React, { Component } from 'react';
import { connect } from 'react-redux';

import API from 'API';
import _ from 'lodash';
import moment from 'moment';


import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';
import OrderSearchBlock from 'Components/Sales/Order/OrderSearch/OrderSearchBlock';
import ProccessRecurringInvoices from 'Components/Sales/Order/RecurringInvoice/ProccessRecurringInvoices';

import {
    Button,
    Grid,
    Tooltip,
    Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';
import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistenceFunctions';

import 'Assets/timeLine.css';

const initialState = () => ({
    formData:   {
        searchString: '',
        dateFrom: null,
        dateTo: null,
        type: 'All'
    },
    formErrors: {},
    data:       {},
    access:     {},
    isLoading:  false,
});

const styles = theme => ({
    custName: {
        '&:hover':{cursor: 'pointer'}
    }
});

class RecurringInvoices extends Component  {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `invoices:ri:${this.props.cid}`;
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.getAccess();
        this.getData();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/customer-order-search'),
            API.get('/staff/my/access/check/amend-recurring-invoice'),
            API.get('/staff/my/access/check/delete-recurring-invoice'),
        ])
        .then(([accessRes, riAccess, delRiAccess]) =>  {
            this.setState({
                ...this.state,
                access: {
                    customerOrderSearch:   (accessRes?.data?.has_access)   || false,
                    amendRecurringInvoice: (riAccess?.data?.has_access)    || false,
                    delRecurringInvoice:   (delRiAccess?.data?.has_access) || false,
                }
            });
        })
    }

    handleSetSearch = props => {
        this.setState({formData: props},this.getData)
    }

    getData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/sales/orders/invoice/recurring', {params: {
                keywords: this.state.formData.keywords,
                dateFrom: this.state.formData.dateFrom !== null ? moment(this.state.formData.dateFrom).format('YYYY-MM-DD') : null,
                dateTo: this.state.formData.dateTo !== null ? moment(this.state.formData.dateTo).format('YYYY-MM-DD') : null,
                slim:1
            }})
            .then(orders => {
                this.setState({
                    ...this.state,
                    data: orders.data,
                    isLoading: false
                }, () => { this.props.updateTabs(); this.savePageState();});
            });
        });
    }

    handleDownloadOpen = orderId => this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md');
    handleEmailOpen    = orderId => this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />, 'Email Previous Documents', '', 'md');

    handleProcessCheck = id => {
        let data = this.state.data
        let indx = _.findIndex(data, i => i.i == id);
        data[indx].checked = !data[indx].checked;
        this.setState({data});
    }

    handleDeleteRecurringInvoice = id => {
        API.post(`/sales/orders/invoice/recurring/${id}/delete`)
        .then( res => {
            if (res.data.success) this.getData();
        });
    }

    render() {
        const { formData, access, formErrors, isLoading, data } = this.state;
        const { classes } = this.props;

        return (            
            <>
                <Grid container item spacing={2}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12} lg={!!data.length ? 9 : 12} style={{paddingTop:'1.8em'}}>
                            <OrderSearchBlock disableSites disableStatus callBack={this.handleSetSearch} formData={this.state.formData}/>
                        </Grid>
                        {!!data.length &&
                            <Grid item xs={12} lg={3} style={{paddingTop:'1.8em'}}>
                                    <PaddedPaper >
                                        <Grid container >
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    <strong>Recurring Invoices</strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1" gutterBottom>
                                                    {data.length}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    <strong>Last Run</strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1" gutterBottom>
                                                    {moment(data[0].ori_last_used_datetime).format("DD/MM/YYYY")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <br />
                                                <Button color='primary' variant="contained" fullWidth onClick={()=>{this.props.deployDialog(<ProccessRecurringInvoices invoiceList={data} callBack={this.getData} />, 'Process Recurring Invoices', '','sm')}}>Process All</Button>
                                            </Grid>
                                        </Grid>        
                                    </PaddedPaper>
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <DataTable  
                                        config={{
                                            key: 'ori_id',
                                            pagination: true,
                                            alternatingRowColours: true,
                                            isLoading: this.state.isLoading
                                        }}
                                        columns={[
                                            {
                                                heading: 'Last Used',
                                                field: rowData => moment(rowData.d).format("DD/MM/YYYY"),
                                                dataRef: 'd',
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Reference',
                                                field: rowData => rowData.r,
                                                dataRef: 'r'
                                            },
                                            {
                                                heading: 'Customer',
                                                field: rowData => <Tooltip
                                                            title={
                                                                <Typography variant="body2">
                                                                    <b>Delivery Address:</b><br />
                                                                    {rowData.c.d.a.split('\n').map((item, key) => {
                                                                        return  <span key={key}>
                                                                                    {item}{rowData.c.d.a.split('\n').length === key+1 ? null : <br/>}
                                                                                </span>
                                                                    })}
                                                                </Typography>
                                                            }
                                                            placement="left"
                                                        >
                                                            <div className={classes.custName} onClick={()=>{this.props.history.push(`/customers/view/${rowData.c.i}`); }}>
                                                                {rowData.c.n}
                                                                <Typography component="div" variant="body2">{rowData.c.d.n}</Typography>
                                                            </div>
                                                        </Tooltip>,
                                                important: false,
                                                dataRef: 'c.n',
                                                truncate: true
                                            },
                                            {
                                                heading: 'No. Runs',
                                                field: rowData => rowData.rn,                               
                                                alignment: 'center',
                                                dataRef: 'rn',
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Invoice Total',
                                                dataRef: 't.t',
                                                field: rowData => ( 
                                                    <Tooltip 
                                                        title={
                                                            <div style={{display: 'flex', width: 200}}>
                                                                <div style={{width: '50%', textAlign:'right'}}>
                                                                    <Typography variant="body2">Sub Total</Typography>
                                                                    <Typography variant="body2">VAT ({rowData.t.vp}%)</Typography>
                                                                    <Typography variant="body2">Total</Typography>
                                                                </div>
                                                                <div style={{width: '50%', textAlign:'right'}}>
                                                                    <Typography variant="body2">£{rowData.t.s}</Typography>
                                                                    <Typography variant="body2">£{rowData.t.v}</Typography>
                                                                    <Typography variant="body2">£{rowData.t.t}</Typography>
                                                                </div>
                                                            </div>
                                                        } 
                                                        placement="left"
                                                    >
                                                        <span>{rowData.t.t}</span>
                                                    </Tooltip>
                                                ),
                                                important: true,                                                    
                                                alignment: 'center',
                                                fieldPrefix: '£'
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Delete',                        icon: 'times',          onClick: () => this.props.deployConfirmation('Are you sure you want to delete this recurring invoice', 'Delete Recurring Invoice', ()=>{this.handleDeleteRecurringInvoice(rowData.i)}), disabled: !access.delRecurringInvoice},
                                                        {name: 'Edit',                          icon: 'pen',            link: '/sales/order/invoice/recurring/amend/' + rowData.i, disabled: !access.amendRecurringInvoice},
                                                        {name: 'View this Customers Orders',    icon: 'file-search',    link: `/customers/view/${rowData.c.i}/Orders`, disabled: !access.customerOrderSearch},
                                                        {name: 'View',                          icon: 'search',         link: '/sales/order/invoice/recurring/' + rowData.i},
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={data}
                                    />
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                    => dispatch(setPersistence(key, state)),
    deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:        ()                              => dispatch(closeDialog()),
    deployConfirmation: (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RecurringInvoices));