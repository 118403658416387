import React                            from 'react';
import { connect }                      from 'react-redux';
import API                              from 'API';
import DataTable                        from 'Components/Common/DataTables/CiDataTable';
import FALightIcon                      from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper                      from 'Components/Common/Paper/PaddedPaper';
import { deployDialog, closeDialog }    from 'Actions/Dialog/Dialog';
import ElementForm from 'Components/Common/Forms/FormBuilder/ElementForm';
import {
    Button,
    TextField,
    Grid,
    Typography
} from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { forEach, find, map } from 'lodash';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';
import _ from 'lodash';
import { toggleDialog } from 'Functions/MiscFunctions';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = () => ({
    searchResults: [],
    access: {
        manageMasterEl: false,
    },
    isLoading: true,
    searchCriteria: {
        areaId: null,
        searchString: '',
    },
    areasList: [],
    dialog: {
        elementForm: false,
    },
    elementDialogData:{
        formData: null,
        type: 'Add',
        mElId: 0
    },
})

class MasterElements extends React.Component {
    constructor(props) {
        super(props);
        this.getInitialState    = getInitialState.bind(this);
        this.persistenceId      = 'Forms:MasterElements';
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.clearPageState     = clearPageState.bind(this);
        this.toggleDialog       = toggleDialog.bind(this);
        this.state              = this.getInitialState(initialState());   
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.location?.search !== prevProps.location?.search){
            this.loadComponentData();
        }
    }

    loadComponentData = () => {

        Promise.all([
            API.get(`/forms/configuration/categories`),
        ])
        .then(([result]) =>  {
            let areasList           = [],
                areaId              = null;

            // Create Areas List for Select
            forEach(result.data, (area) => {
                areasList.push({
                    label: area.nm,
                    value: area.id
                });
            })

            this.setState({
                areasList,
                searchCriteria: {
                    ...this.state.searchCriteria,
                    areaId
                },
            }, () => {

                this.getSearchData();
                this.checkAccess();
            })
        });
    }

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/forms/configuration/masterElements', { 
                params: this.state.searchCriteria
            })
            .then(result => {
                this.setState({
                    isLoading: false
                });
                if(result.data) {
                    this.setState({
                        searchResults: result.data
                    });
                } 
            });
        });
    }

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/forms-master-elements:manage'),
        ])
        .then(([manageRes]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    manageMasterEl: manageRes.data && manageRes.data.has_access,
                }
            })
        });
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                searchString: event.target.value
            }
        },
        () => {
            this.savePageState();
            this.getSearchData();
        });
    }

    handleCategoryChange = (type, value) => {

        if(type == 'area'){

            this.setState({
                searchCriteria: {
                    ...this.state.searchCriteria,
                    areaId: value,
                }
            },
            () => {
                this.savePageState();
                this.getSearchData();
            });
        }
    }

    resetSearch = () => {
        this.setState({ searchCriteria: {...initialState().searchCriteria} }, () =>{ this.savePageState();  this.getSearchData()} );
    }

    handleElementOpen = (type, id, readOnly=false) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                elementForm: true
            },
            elementDialogData: {
                ...this.state.elementDialogData,
                type,
                mElId: id,
                readOnly
            }
        });
    }

    addUpdateElement = (formData, masterElId) => {

        this.setState({
            isLoading: true
        }, () => {

            let route = masterElId ? `/forms/configuration/masterElements/${masterElId}` : `/forms/configuration/masterElements`;

            API.post(route, {
                element: formData
            })
            .then(result => {
                if(result) {
                    if(result?.data?.errors) {
                        // this.setState({
                        //     ...this.state,
                        //     formErrors: formatValidationErrors(result.data.errors),
                        //     isLoading: false
                        // });
                    } else {
                        this.setState({
                            isLoading: false,
                        }, () => {
                            this.props.deploySnackBar("success", `You have successfully added a new master element`);
                            this.toggleDialog('elementForm', true);
                            this.loadComponentData();
                        })
                    }
                }
            },
            err => API.handleError(err));
        });
    }


    render() {
        const { access, searchCriteria, areasList, dialog, elementDialogData } = this.state;
        return (
            <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Master Elements
                    </Typography>
                </Grid> 
                <Grid item xs={12} lg={6}>
                    <PaddedPaper>
                        <form noValidate autoComplete="off">
                            <Grid container item spacing={3}>
                                <Grid item xs={12}>
                                    <AutoCompleteSelect 
                                        options={areasList} 
                                        label='Area'
                                        fullWidth
                                        value={searchCriteria.areaId}
                                        noClear
                                        onChange={(v) => this.handleCategoryChange('area', v.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="nameSearch" label="Element Name" fullWidth value={searchCriteria.searchString} onChange={this.onSearchStringChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className='buttonRow'>
                                        <Button onClick={this.resetSearch}
                                                variant="outlined"
                                                color="default"                                       
                                                >
                                            <FALightIcon icon='undo' button />
                                            Reset Search
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </form> 
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} align="right">
                    {access.manageMasterEl && (
                        <Button 
                            variant="contained" 
                            color="primary"
                            onClick={() => this.handleElementOpen('Add')}
                        >
                            <FALightIcon icon='plus' button color="white" /> Add Master Element
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key: 'id',
                                pagination: true,
                                persistenceId: this.persistenceId,
                                alternatingRowColours: true,
                                isLoading: this.state.isLoading,
                                responsiveImportance: true
                            }}
                            columns={[
                                {
                                    heading: 'Area',
                                    field: rowData => rowData?.area?.nm,
                                    important: true,
                                    main: true,
                                    hideInResponsiveDialog: true,
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Element Type',
                                    field: rowData => rowData?.el?.elType,
                                    important: true,
                                    main: true,
                                    hideInResponsiveDialog: true,
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Element Name',
                                    field: rowData => rowData?.el?.nm,
                                    important: true,
                                    main: true,
                                    hideInResponsiveDialog: true,
                                },
                                {
                                    heading: 'Version',
                                    field: rowData => 'v'+rowData?.v,
                                    main: true,
                                    hideInResponsiveDialog: true,
                                    alignment:' center',
                                    sizeToContent: true,
                                    
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil-alt', onClick: () => { this.handleElementOpen('Update', rowData?.el?.id) }, disabled: !access.manageMasterEl},
                                            {name: 'View', icon: 'search', onClick: () => { this.handleElementOpen('View', rowData?.el?.id, true) }},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
            {/* Element Dialog */}
            <SmallFormDialog
                open={dialog.elementForm}
                onClose={() => this.toggleDialog('elementForm', true)}
                title={`${elementDialogData?.type} Master Element`}
                content={<ElementForm 
                            data={elementDialogData} 
                            addUpdateElement={this.addUpdateElement}
                            toggleDialog={this.toggleDialog}
                            masterElement={true}
                            areasList={areasList}
                            readOnly={elementDialogData?.readOnly}
                        />}
                maxWidth='md'
            />
            </>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deployDialog: (content, title, variant, size='md')  => {dispatch(deployDialog(content, title, variant, size))},
    closeDialog: ()                                     => {dispatch(closeDialog())},
    clearPersistence: ()                                => dispatch(clearPersistence()),
    setPersistence: (key, state)                        => dispatch(setPersistence(key, state)),
    deploySnackBar: (variant, message)                  => dispatch(deploySnackBar(variant, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MasterElements);