import React from 'react';
import Badge from '@material-ui/core/Badge';

const NotificationBadge = props => (
    <Badge 
        badgeContent={props.badgeContent ? props.badgeContent : 0}
        color={props.color ? props.color : 'error'}
        classes={{badge: props.badge ? props.badge : null}}
        max={props.max ? props.max : 99}
        showZero={props.showZero ? props.showZero : false}
        variant={props.variant ? props.variant : 'standard'}
        overlap='circle'
    >            
        {props.children}
    </Badge>
)

export default NotificationBadge;