import { Grid, List, Typography, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import API from 'API';
import _ from 'lodash';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import IconHelper from 'Helpers/IconHelper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { statusColour, colors } from 'Helpers/ColourHelper';
import moment from 'moment';
import { clenyDate } from 'Helpers/TimeHelper';

const InitialState = () => ({
    isLoading: true,
    quotes: [],
})

class PromotionOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = InitialState();
    }

    componentDidMount() {
        this.getCampaigns();
    }

    getCampaigns = () => {
        Promise.all([
            API.get(`/customers/${this.props.customerId}/sales/quotations`, { params: { promoOnly: true } }),
            API.get(`/customers/${this.props.customerId}/emailCampaigns`)
        ]).then(([q,c]) => {
            this.setState({
                quotes: q.data,
                campaigns: [ ..._.map(c.data[0], i => ({...i, sent: 1})),  ..._.map(c.data[1], i => ({...i, sent: 0})) ],
                isLoading: false
            })
        })
    }

    render() {

        const { isLoading, quotes } = this.state;

        if (isLoading) {
            return <LoadingCircle />;
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <>
                        <CiDataTable
                            config={{
                                id: 'quote_id',
                                dropRow: {
                                    droppable: true,
                                    columns: [
                                        {
                                            colSpan: 8,
                                            field: i => 
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <List>
                                                            {
                                                                _.map(i.promotions, (promotion, index) => {
                                                                    const emailCampaign = _.find(this.state.campaigns, c =>
                                                                        c.promotion && c.promotion.p_id === promotion.p_id    
                                                                    );
                                                                    if (emailCampaign){
                                                                        return (
                                                                            <ListItem>
                                                                                <ListItemText
                                                                                    primary={
                                                                                        <Grid container>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant="body1">{emailCampaign?.ec_reference}-0{emailCampaign?.ec_reference_amendment}</Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant="body2">{emailCampaign?.ec_name}</Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    }
                                                                                    secondary={
                                                                                        <Grid container>
                                                                                            {emailCampaign?.ec_desc &&
                                                                                                <Grid item xs={12}>
                                                                                                    <Typography variant="caption">{emailCampaign?.ec_desc}</Typography>
                                                                                                </Grid>
                                                                                            }
                                                                                            {!promotion.p_hidden &&
                                                                                                <Grid item xs={12} style={{border: `1px solid ${colors.info}`, borderRadius: 5, margin: 5, padding: 5, marginRight: 20}}>
                                                                                                    <Grid container>
                                                                                                        <Grid item xs={12}>
                                                                                                            <Typography variant="body1">Promotion Details</Typography>
                                                                                                        </Grid>
                                                                                                        <Grid item>
                                                                                                            <Typography variant="body2">[{promotion?.p_code}]</Typography>
                                                                                                        </Grid>
                                                                                                        {promotion.p_name !== emailCampaign?.ec_name &&
                                                                                                            <Grid item xs={8} style={{marginLeft: 5}}>
                                                                                                                <Typography variant="body2">{promotion?.p_name}</Typography>
                                                                                                            </Grid>
                                                                                                        }
                                                                                                        {promotion.p_desc !== emailCampaign?.ec_desc &&
                                                                                                            <Grid item xs={12}>
                                                                                                                <Typography variant="caption">{promotion?.p_desc}</Typography>
                                                                                                            </Grid>
                                                                                                        }
                                                                                                        {moment().isAfter(moment(promotion?.p_end)) &&
                                                                                                            <Grid item style={{ marginRight: 5}}>
                                                                                                                <Typography variant="caption" style={{color: colors.red}}>EXPIRED</Typography>
                                                                                                            </Grid>
                                                                                                        }
                                                                                                        <Grid item style={{marginRight: 5}}>
                                                                                                            <Typography variant='caption'>
                                                                                                                Valid From: {clenyDate(promotion?.p_start)}
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                        {
                                                                                                            promotion?.p_end &&
                                                                                                            <Grid item style={{marginRight: 5}}>
                                                                                                                <Typography variant='caption'>
                                                                                                                    To : {clenyDate(promotion?.p_end)}
                                                                                                                </Typography>
                                                                                                            </Grid>
                                                                                                        }
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            }
                                                                                        </Grid>
                                                                                    }
                                                                                    
                                                                                />
                                                                            </ListItem>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </List>
                                                    </Grid>
                                                </Grid>
                                        }
                                    ]
                                }
                            }}
                            rows={_.orderBy(quotes, ['quote_date'], ['desc'])}
                            columns={[
                                {
                                    heading: 'Date',
                                    field: 'quote_date',
                                    fieldFormat: 'clenydate',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Reference',
                                    field: i => `${i.quote_reference}-${i.quote_reference_amendment}`,
                                    dataRef: 'quote_reference',
                                },
                                {
                                    heading: 'discount',
                                    field: i => i.quote_type === 'With Discount',
                                    dataRef: 'quote_type',
                                    sizeToContent: true,
                                    fieldFormat: 'boolean',
                                    alignment: 'center',
                                },
                                {
                                    heading: 'status',
                                    field: i => <AllIcon
                                        icon={(i.quote_status === 'Open' ? 
                                            IconHelper.file :
                                            (
                                                i.quote_status === 'Ordered' ?
                                                IconHelper.true :
                                                IconHelper.ban
                                            )
                                        )}
                                        tooltip={i.quote_status}
                                        color={statusColour(i.quote_status)}
                                    />,
                                    dataRef: 'quote_status',
                                    sizeToContent: true,
                                    alignment: 'center',
                                },
                                {
                                    heading: 'Total',
                                    field: 'quote_total_incl_vat',
                                    dataRef: 'quote_total_incl_vat',
                                    fieldFormat: 'currency',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Linked Campaigns',
                                    field: i => i.promotions.length,
                                    dataRef: i => i.promotions.length,
                                    sizeToContent: true,
                                    alignment: 'center',
                                },
                                {
                                    heading: 'Email Sent',
                                    field: i => _.find(_.filter(this.state.campaigns, {sent: 1}), c => c?.promotion && _.map(i.promotions, p => p.p_id).includes(c?.promotion?.p_id)),
                                    dataRef: i => _.find(_.filter(this.state.campaigns, {sent: 1}), c => c?.promotion && _.map(i.promotions, p => p.p_id).includes(c?.promotion?.p_id)),
                                    sizeToContent: true,
                                    alignment: 'center',
                                    fieldFormat: 'boolean',
                                },
                                {
                                    actions: i => ([
                                        {name: 'View', icon: 'search', link: { pathname: '/sales/quotation/view/' + i.quote_id} }
                                    ])
                                }
                            ]}
                            
                        />
                    </>
                </Grid>
            </Grid>
        );
    }
}

export default PromotionOrders;