import React from 'react';
import _ from "lodash";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
    Box,
    Grid,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Switch,
    Tooltip,
    Typography,
} from "@material-ui/core";

import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import PhoneBookForm from './PhoneBookForm';
import TaskDialog from 'Components/Tasks/Misc/TaskDialog';
import WildixCallAssignment from './WildixCallAssignment';
import WildixCallNotes from "./WildixCallNotes";
import WildixCallTranscription from "./WildixCallTranscription";
import { deployDialog } from "Actions/Dialog/Dialog";
import WildixCallRecordings from './WildixCallRecording';

const WildixRecentCalls = ({ access, calls, excludeRecentInternal, onDial, refresh, toggleExcludeInternal }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleAddPhoneBook = (number) => {
        let mobile = '',
            telephone = '';
        if (number.match(/^(\+447|447|07)/)) {
            mobile = number.replace(/^(\+447|447|07)/, "07");
        } else {
            telephone = number.replace(/^(\+44)/, "0");
        }
        dispatch(deployDialog(
            <PhoneBookForm callback={refresh} contact={null} telephone={telephone} mobile={mobile} />,
            "Add to Global Phone Book",
            "standard",
            "md"
        ))
    }

    const handleDespatchTask = id => {
        dispatch(deployDialog(
            <TaskDialog id={id} />,
            "", 
            "standard", 
            "md"
        ))
    }

    const handleTaskStatusClass = status => {
        switch(status) {
            case 'Completed':
                return 'task-completed';
            case 'In Progress':
                return 'task-in-progress';
            case 'New':
                return 'task-new';
            default:
                return undefined;
        }
    }
    
    const handleReAssignCall = (id) => {
        dispatch(deployDialog(
            <WildixCallAssignment callback={refresh} id={id} />,
            "Update Call Assignment",
            "standard",
            "md"
        ))
    }

    const handleUpdateNotes = (id) => {
        dispatch(deployDialog(
            <WildixCallNotes callback={refresh} id={id} />,
            "Update Call Notes",
            "standard",
            "xs"
        ))
    };

    const handleViewRecordings = (id) => {
        dispatch(deployDialog(
            <WildixCallRecordings id={id} />,
            "Call Recordings",
            "standard",
            "xs"
        ))
    }

    const handleViewTranscription = (id) => {
        dispatch(deployDialog(
            <WildixCallTranscription id={id} />,
            "Call Transcription",
            "standard",
            "md"
        ))
    }

    const thisDate = moment().format("DD/MM");
    return (
        <>
            <Grid container justify="space-between" alignItems="center" className="pb-1">
                <Grid item xs>
                    <Typography variant="h6" className="fw-400" align="left">
                        Recents
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        className="m-0"
                        checked={!!excludeRecentInternal}
                        control={<Switch color="primary" onChange={toggleExcludeInternal} value="1" />}
                        label={
                            <Typography variant="body2" className="fw-400">
                                Exclude Internal
                            </Typography>
                        }
                    />
                </Grid>
            </Grid>
            <Box className="scrollbar" style={{ height: "100%", overflowY: "auto", border: "1px solid #ddd" }}>
                <List dense className="p-0">
                    {(_.isEmpty(calls) && (
                        <Box className="mt-2">
                            <Typography variant="body2" align="center">
                                <em>No recent calls to display</em>
                            </Typography>
                        </Box>
                    )) ||
                        _.map(calls, (call, index) => {
                            const incoming = call.ic === 1,
                                internal = call.nu.startsWith('2') && call.nu.length === 3,
                                answered = call.st === 1,
                                backgroundColor = "#fff"; //index % 2 === 0 ? "#fafafa" : "#fff";
                            return (
                                <ListItem disableGutters divider={index !== _.size(calls) - 1} key={index} style={{ backgroundColor }}>
                                    <ListItemAvatar style={{ minWidth: 30 }}>
                                        {!incoming ? (
                                            <FAIcon className={`${answered ? "" : "textError"} pl-1`} style icon={`phone-arrow-up-right`} type="light" size={12.5} />
                                        ) : (
                                            <Box />
                                        )}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <>
                                                <Typography
                                                    className={`${answered ? "" : "textError"} fw-400`}
                                                    variant="body2"
                                                    component="div"
                                                    style={{
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        maxWidth: "100%",
                                                    }}
                                                >
                                                    {call.nm}
                                                </Typography>
                                                {call.cn ? (
                                                    <Typography
                                                        className={`${answered ? "" : "textError"} fw-400`}
                                                        variant="body2"
                                                        component="div"
                                                        style={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                            maxWidth: "100%",
                                                        }}
                                                    >
                                                        {call.cn}
                                                    </Typography>
                                                ) : null}
                                            </>
                                        }
                                        secondary={
                                            <Typography className={answered ? "" : "textError"} component="div" variant="body2">
                                                {call.nu}
                                                <Grid container spacing={1} wrap="nowrap">
                                                    <Grid item>
                                                        <Tooltip title="Update Notes" enterDelay={1000}>
                                                            <Typography
                                                                variant="caption"
                                                                component="div"
                                                                style={{
                                                                    border: "1px solid #ddd",
                                                                    borderRadius: 10,
                                                                    background: "#fcfcfc",
                                                                    cursor: "pointer",
                                                                    padding: "1px 7px",
                                                                    width: "fit-content",
                                                                    marginTop: 4,
                                                                }}
                                                                onClick={() => handleUpdateNotes(call.id)}
                                                            >
                                                                <FAIcon icon={"pencil-alt"} size={10} button noMargin className="pr-05" />
                                                                <em>{call.nt ?? `No notes added`}</em>
                                                            </Typography>
                                                        </Tooltip>
                                                    </Grid>
                                                    {call?.rc > 0 && (
                                                        <Grid item>
                                                            <Tooltip title="View Recordings" enterDelay={1000}>
                                                                 <Typography
                                                                    variant="caption"
                                                                    component="div"
                                                                    style={{
                                                                        border: "1px solid #ddd",
                                                                        borderRadius: 10,
                                                                        background: "#fcfcfc",
                                                                        cursor: "pointer",
                                                                        width: "fit-content",
                                                                        marginTop: 4,
                                                                    }}
                                                                >
                                                                    <IconButton onClick={() => handleViewRecordings(call.id)} size="small">
                                                                        <FAIcon icon="cassette-tape" type="light" button noMargin size={15} />
                                                                    </IconButton>
                                                                </Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                    )}
                                                    {call?.tc > 0 && (
                                                        <Grid item>
                                                            <Tooltip title="View Transcription" enterDelay={1000}>
                                                                 <Typography
                                                                    variant="caption"
                                                                    component="div"
                                                                    style={{
                                                                        border: "1px solid #ddd",
                                                                        borderRadius: 10,
                                                                        background: "#fcfcfc",
                                                                        cursor: "pointer",
                                                                        width: "fit-content",
                                                                        marginTop: 4,
                                                                    }}
                                                                >
                                                                    <IconButton onClick={() => handleViewTranscription(call.id)} size="small">
                                                                        <FAIcon icon="file-lines" type="light" button noMargin size={15} />
                                                                    </IconButton>
                                                                </Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                    )}
                                                    {call?.ts?.id ? (
                                                        <Grid item>
                                                            <Tooltip title={`View Task - Status: ${call?.ts?.st ?? 'Not Assigned'}`} enterDelay={1000}>
                                                                 <Typography
                                                                    variant="caption"
                                                                    component="div"
                                                                    style={{
                                                                        border: "1px solid #ddd",
                                                                        borderRadius: 10,
                                                                        background: "#fcfcfc",
                                                                        cursor: "pointer",
                                                                        width: "fit-content",
                                                                        marginTop: 4,
                                                                    }}
                                                                >
                                                                    <IconButton onClick={() => handleDespatchTask(call.ts.id)} size="small">
                                                                        <FAIcon className={handleTaskStatusClass(call?.ts?.st)} icon="list-check" type="light" button noMargin size={15} />
                                                                    </IconButton>
                                                                </Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                    ) : null}
                                                </Grid>
                                            </Typography>
                                        }
                                    />
                                    <Box>
                                        <Typography variant="caption" component="div">
                                            {call.ti}
                                        </Typography>
                                        {call.dt !== thisDate && (
                                            <Typography variant="caption" component="div">
                                                {call.dt}
                                            </Typography>
                                        )}
                                    </Box>
                                    <ListItemSecondaryAction>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Tooltip title="Call Back">
                                                    <IconButton onClick={() => onDial(call.nu)} size="small">
                                                        <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin size={20} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid> 
                                            {(call.si || call.ci) ? (
                                                <Grid item xs={12}>
                                                    <Grid item>
                                                        <Tooltip title={`View ${call.si ? "Supplier" : "Customer"}`}>
                                                            <IconButton
                                                                disabled={!call.si && !call.ci}
                                                                onClick={() => history.push(call.si ? `/suppliers/view/${call.si}` : `/customers/view/${call.ci}`)}
                                                                size="small"
                                                            >
                                                                <FAIcon icon="user-circle" type="light" button noMargin size={17} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            ) : access?.phoneBookAdd && call?.cc === -1 && call?.nu !== "anonymous" && call?.nu?.length > 3 ? (
                                                <Grid item xs={12}>
                                                    <Grid item>
                                                        <Tooltip title={`Add to Phone Book`}>
                                                            <IconButton
                                                                onClick={() => handleAddPhoneBook(call.nu)}
                                                                size="small"
                                                            >
                                                                <FAIcon icon="address-book" type="light" button noMargin size={17} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            ) : null}
                                            {internal ? null : (
                                                <Grid item xs={12}>
                                                    <Tooltip title="Re-assign Call">
                                                        <IconButton onClick={() => handleReAssignCall(call.id)} size="small">
                                                            <FAIcon icon="people-arrows" type="light" button noMargin size={17} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                </List>
            </Box>
        </>
    );
};

export default WildixRecentCalls;
