import {
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    List,
    ListItem, ListItemSecondaryAction,
    ListItemText,
    Typography
} from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import EditContactDialog from 'Components/Common/Contacts/EditContactDialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import InfoBox from 'Components/Common/reports/InfoBox';
import { colors } from 'Helpers/ColourHelper';
import { checkMailingListContacts } from 'Helpers/EmailHelper';
import IconHelper from 'Helpers/IconHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

const initialState = (props = null) => ({
    isLoading: true,
    globalContacts: [],
    contacts: props && props.mailingList?.c?.q,
    breakDown: {
        exsisting: [],
        new: [],
        inListDuplicate: [],
        outListDuplicate: [],
        invalid: [],
        na: [],
        warning: []
    },
    editFormData: {
        show: false,
        contact_name: '',
        contact_email: '',
        contact_id: 0,
    }
})

class ContactsSystem extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.checkContacts();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.mailingList?.c?.q) !== JSON.stringify(this.props.mailingList?.c?.q)) {
            this.setState({
                contacts: this.props.mailingList?.c?.q ?? []
            }, this.checkContacts);
        }
    }

    checkContacts = async () => {
        let bk = await checkMailingListContacts(this.state.contacts ? _.map(this.state.contacts, i => i.contact) : [],  _.map([
            ...this.props.mailingList?.c?.c,
            ...this.props.mailingList?.c?.r
        ], i => i.contact));
        this.setState({
            isLoading: false,
            breakDown: bk.breakdown
        });
    }

    editContact = (contact) => () => {
        window.location.href = `/customers/contacts/update/${contact.contact_id}`;
        /*
        this.props.deployDialog(
            <EditContactDialog
                id={contact.contact_id}
                onUpdate={this.props.refresh}
                onClose={this.props.closeDialog}
            />,
            'Edit Contact',
            'md'
        );
        */
    }

    deleteContact = (contact) => () => {
        let mlc = _.find(this.state.contacts, i => parseInt(i.contact.contact_id) === parseInt(contact.contact_id));
        API.post(`/marketing/campaigns/mailingLists/${this.props.mailingList.i}/contacts/${mlc.mlc_id}/remove`)
        .then(this.props.refresh);
    }

    removeAll = (type) => {
        let _promise = [];
        _.each(this.state.breakDown[type], c => {
            let mlc = _.find(this.state.contacts, i => parseInt(i.contact.contact_id) === parseInt(c.contact_id));
            API.post(`/marketing/campaigns/mailingLists/${this.props.mailingList.i}/contacts/${mlc.mlc_id}/remove`)
        });
        Promise.all(_promise).then(this.props.refresh);
    }

    flattenDuplicates = () => {
        let _promise = [];
        let allow = _.uniqBy(this.state.breakDown?.inListDuplicate , i => i.contact_email.toLowerCase());
        _.each(_.filter(this.state.breakDown?.inListDuplicate, i => !_.find(allow, {contact_id: i.contact_id})), c => {
            let mlc = _.find(this.state.contacts, i => parseInt(i.contact.contact_id) === parseInt(c.contact_id));
            API.post(`/marketing/campaigns/mailingLists/${this.props.mailingList.i}/contacts/${mlc.mlc_id}/remove`)
        });
        Promise.all(_promise).then(this.props.refresh);
    }

    convertReason = (reason) => {
        switch (reason){
            case 'unsubscribed':
                return 'Unsubscribed From Marketing';
            case 'blacklist':
                return 'Blacklisted Email';
            case 'hard bounce':
            case 'soft bounce':
                return 'Bounced Email';
            case 'invalid':
            case 'invalid ending':
                return 'Invalid Email';
            case 'invalid domain':
                return 'Invalid Domain';
            case 'complaint':
                return 'Complaint Email';
            case 'Closed Customer':
                return 'Customer Closed';
            case 'accounts':
                return 'Accounts Email';
            default:
                return reason;
        }
    }

    render () {

        const { isLoading, contacts, breakDown, editFormData } = this.state;

        if (isLoading || this.props.dataLoading) return (<LoadingCircle/>);
        return (
            <Grid container spacing={2}>
                <InfoBox 
                    title='Total'
                    value={contacts.length}
                    icon={IconHelper.customers}
                    rowSize={4}
                />
                {/*
                    <InfoBox 
                        title='Existing'
                        info='Contacts in the system (connected to a customer & manually added)'
                        value={breakDown?.eligible.length}
                        icon={IconHelper.true}
                        rowSize={4}
                    />
                    <InfoBox 
                        title='New'
                        info='Contacts not in the system'
                        value={breakDown?.newContacts.length}
                        icon={IconHelper.plus}
                        rowSize={4}
                    />
                */}
                <InfoBox 
                    title='Duplicate'
                    info='Contacts with duplicate emails'
                    value={[...(breakDown?.inListDuplicate ?? []), ...(breakDown?.outListDuplicate ?? [])].length}
                    icon={IconHelper.copy}
                    rowSize={4}
                />
                {/*}
                    <InfoBox 
                        title='Warnings'
                        info='Contacts that may have incorrect emails'
                        value={breakDown?.warnings.length}
                        icon={IconHelper.warning}
                        rowSize={4}
                    />
                */}
                <InfoBox 
                    title='Invalid'
                    info='Contacts with invalid emails'
                    value={breakDown?.invalid.length}
                    icon={IconHelper.ban}
                    rowSize={4}
                />
                
                <InfoBox 
                    title='Not Eligible'
                    info='Contacts that are unsubscribed or accounts'
                    value={breakDown?.na?.length}
                    icon={IconHelper.false}
                    rowSize={4}
                />
                <Grid item xs={12}>
                    {/*}
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                                style={{backgroundColor: colors.green }}
                            >Existing Contacts: {breakDown?.eligible.length}</ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{padding: '5px 8px'}}>
                                <List dense style={{width: '100%'}}>
                                    {_.map(breakDown?.eligible, (contact, i) =>
                                        <ListItem key={`eligible-${breakDown?.eligible.length}-${i}`}>
                                            <ListItemText primary={`${contact.contact_name} (${contact?.customer?.cust_name ?? 'Manual Contact'})`} secondary={contact.contact_email} />
                                            <ListItemSecondaryAction>
                                                <AllIcon icon={IconHelper.bin} noMargin onClick={()=>this.props.deployConfirmation(
                                                    'Are you sure you want to remove this contact from the mailing list?',
                                                    'Remove Contact',
                                                    this.deleteContact(contact)
                                                )}/>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                                style={{backgroundColor: colors.green }}
                            >New Contacts: {breakDown?.newContacts.length}</ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{padding: '5px 8px'}}>
                                <List dense style={{width: '100%'}}>
                                    {_.map(breakDown?.newContacts, (contact, i) =>
                                        <ListItem key={`newContacts-${breakDown?.newContacts.length}-${i}`}>
                                            <ListItemText primary={`${contact.contact_name}`} secondary={contact.contact_email} />
                                            <ListItemSecondaryAction>
                                                <AllIcon icon={IconHelper.edit} noMargin onClick={this.editContact(contact)}/>
                                                <AllIcon icon={IconHelper.bin} noMargin onClick={()=>this.props.deployConfirmation(
                                                    'Are you sure you want to remove this contact from the mailing list?',
                                                    'Remove Contact',
                                                    this.deleteContact(contact)
                                                )}/>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    */}
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                            style={{backgroundColor: breakDown?.inListDuplicate.length > 0 ? colors.red : colors.green }}
                        >Duplicate Emails: {breakDown?.inListDuplicate.length} Contacts / {_.uniqBy(breakDown?.inListDuplicate, 'contact_email').length} Unique Emails</ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{padding: '5px 8px'}}>
                            <Grid container>
                                {breakDown?.inListDuplicate.length > 0 &&
                                    <Grid item xs={12} className='buttonRow'>
                                        <Button
                                            color='secondary'
                                            variant='contained'
                                            onClick={this.flattenDuplicates}
                                            style={{
                                                marginBottom: '1em',
                                                marginRight: '1em'
                                            }}
                                        >
                                            Remove All / Take First
                                        </Button>
                                    </Grid>
                                }
                                {_.map(_.uniqBy(breakDown?.inListDuplicate, 'contact_email'), ({contact_email}, pi) =>
                                    <Grid item xs={12}>
                                        <Typography style={{paddingLeft: 16, fontWeight: 'bold'}} variant='body1'>{contact_email}</Typography>
                                        <List dense style={{width: 'calc(100% - 16px)', marginLeft: '16px'}}>
                                            {_.map(_.filter(breakDown?.inListDuplicate, {contact_email}), (contact, i) =>
                                                <ListItem key={`inListDuplicate-${breakDown?.inListDuplicate.length}-${i}`}>
                                                    <ListItemText primary={`${contact.contact_name}`}/>
                                                    <ListItemSecondaryAction>
                                                        <AllIcon icon={IconHelper.edit} noMargin onClick={this.editContact(contact)}/>
                                                        <AllIcon icon={IconHelper.bin} noMargin onClick={()=>this.props.deployConfirmation(
                                                            'Are you sure you want to remove this contact from the mailing list?',
                                                            'Remove Contact',
                                                            this.deleteContact(contact)
                                                        )}/>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )}
                                        </List>
                                    </Grid>
                                )}
                                {breakDown?.inListDuplicate.length > 0 &&
                                    <Grid item xs={12} className='buttonRow'>
                                        <Button
                                            color='secondary'
                                            variant='contained'
                                            onClick={this.flattenDuplicates}
                                            style={{
                                                marginTop: '1em',
                                                marginBottom: '1em',
                                                marginRight: '1em'
                                            }}
                                        >
                                            Remove All / Take First
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    {breakDown?.outListDuplicate.length > 0 &&
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                                style={{backgroundColor: colors.red }}
                            >Contacts Already Added: {breakDown?.outListDuplicate.length}</ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{padding: '5px 8px'}}>
                                <List dense style={{width: '100%'}}>
                                    {_.map(breakDown?.outListDuplicate, (contact, i) =>
                                        <ListItem key={`outListDuplicate-${breakDown?.outListDuplicate.length}-${i}`}>
                                            <ListItemText primary={`${contact.contact_name} (${contact?.customer?.cust_name ?? 'Manual Contact'})`} secondary={contact.contact_email} />
                                            <ListItemSecondaryAction>
                                                <AllIcon icon={IconHelper.bin} noMargin onClick={()=>this.props.deployConfirmation(
                                                    'Are you sure you want to remove this contact from the mailing list?',
                                                    'Remove Contact',
                                                    this.deleteContact(contact)
                                                )}/>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                    {breakDown?.outListDuplicate.length > 0 &&
                                        <ListItem
                                            style={{
                                                marginTop: '1em',
                                                marginBottom: '1em',
                                            }}
                                        >
                                            <ListItemSecondaryAction>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={
                                                        () => {
                                                            this.props.deployConfirmation(
                                                                'Are you sure you want to remove all contacts already added?',
                                                                'Remove Contacts Already Added',
                                                                () => {
                                                                    this.removeAll('outListDuplicate');
                                                                }
                                                            )
                                                        }
                                                    }
                                                >Remove All</Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    }
                                </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    }
                    {/*}
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                                style={{backgroundColor: breakDown?.warnings.length > 0 ? colors.red : colors.green }}
                            >Warnings: {breakDown?.warnings.length}</ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{padding: '5px 8px'}}>
                                <List dense style={{width: '100%'}}>
                                    {_.map(breakDown?.warnings, (contact, i) =>
                                        <ListItem key={`warnings-${breakDown?.warnings.length}-${i}`}>
                                            <ListItemText primary={`${contact.contact_name} (${contact?.customer?.cust_name ?? 'Manual Contact'})`} secondary={contact.contact_email} />
                                            <ListItemSecondaryAction>
                                                <AllIcon icon={IconHelper.edit} noMargin onClick={this.editContact(contact)}/>
                                                <AllIcon icon={IconHelper.bin} noMargin onClick={()=>this.props.deployConfirmation(
                                                    'Are you sure you want to remove this contact from the mailing list?',
                                                    'Remove Contact',
                                                    this.deleteContact(contact)
                                                )}/>
                                                <AllIcon icon={IconHelper.true} noMargin onClick={this.validateContact(contact)}/>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    */}
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                            style={{backgroundColor: breakDown?.invalid.length > 0 ? colors.red : colors.green }}
                        >Invalid Emails: {breakDown?.invalid.length}</ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{padding: '5px 8px'}}>
                            <List dense style={{width: '100%'}}>
                                {_.map(breakDown?.invalid, (contact, i) =>
                                    <ListItem key={`faultyEmails-${breakDown?.invalid.length}-${i}`}>
                                        <ListItemText primary={`${contact.contact_name} (${contact?.customer?.cust_name ?? 'Manual Contact'})`} 
                                            secondary={
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        {contact.contact_email}
                                                    </Grid>
                                                    {contact.reason &&
                                                        <Grid item xs={12} style={{fontWeight: 'bold'}}>
                                                           {this.convertReason(contact.reason)}
                                                        </Grid>
                                                    }
                                                </Grid>
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            <AllIcon icon={IconHelper.edit} noMargin onClick={this.editContact(contact)}/>
                                            <AllIcon icon={IconHelper.bin} noMargin onClick={()=>this.props.deployConfirmation(
                                                'Are you sure you want to remove this contact from the mailing list?',
                                                'Remove Contact',
                                                this.deleteContact(contact)
                                            )}/>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                            </List>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<AllIcon noMargin icon={IconHelper.arrowDown} white />}
                            style={{backgroundColor: breakDown?.na.length > 0 ? colors.red : colors.green }}
                        >Not Eligible: {breakDown?.na.length}</ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{padding: '5px 8px'}}>
                            <List dense style={{width: '100%'}}>
                                {_.map(breakDown?.na, (contact, i) =>
                                    <ListItem key={`na-${breakDown?.na.length}-${i}`}>
                                        <ListItemText primary={`${contact.contact_name} (${contact?.customer ?? 'Manual Contact'})`} 
                                            secondary={
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        {contact.contact_email}
                                                    </Grid>
                                                    {contact.reason &&
                                                        <Grid item xs={12} style={{fontWeight: 'bold'}}>
                                                            {this.convertReason(contact.reason)}
                                                        </Grid>
                                                    }
                                                </Grid>
                                            }
                                         />
                                        <ListItemSecondaryAction>
                                            <AllIcon icon={IconHelper.bin} noMargin onClick={()=>this.props.deployConfirmation(
                                                'Are you sure you want to remove this contact from the mailing list?',
                                                'Remove Contact',
                                                this.deleteContact(contact)
                                            )}/>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                                {breakDown?.na.length > 0 &&
                                    <ListItem
                                        style={{
                                            marginTop: '1em',
                                            marginBottom: '1em',
                                        }}
                                    >
                                        <ListItemSecondaryAction>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={
                                                    () => {
                                                        this.props.deployConfirmation(
                                                            'Are you sure you want to remove all N/A contacts?',
                                                            'Remove All N/A Contacts',
                                                            () => this.removeAll('na')
                                                        )
                                                    }
                                                }
                                            >Remove All</Button>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                }
                            </List>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
                {/*}
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={breakDown?.outListDuplicate.length > 0}
                            onClick={()=>this.props.deployConfirmation(
                                'Are you sure you want to update these contacts?',
                                'Update Contacts',
                                this.updateContacts
                            )}
                        >
                            Update
                        </Button>
                    </Grid>
                */}
                {/*}
                    <Dialog
                        open={!!editFormData.show}
                        maxWidth="xs"
                        fullWidth
                    >
                        <DialogTitle>Edit Contact</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        name='contact_name'
                                        label='Name *'
                                        value={editFormData?.contact_name}
                                        onChange={this.handleChange('editFormData')}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name='contact_email'
                                        label='Email *'
                                        value={editFormData?.contact_email}
                                        onChange={this.handleChange('editFormData')}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.updateContact}
                            >Update Contact</Button>
                        </DialogActions>
                    </Dialog>
                */}
            </Grid>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback) => {
            dispatch(deployConfirmation(content, title, callback))
        },
        deployDialog: (content, title, size, variant=null) => {
            dispatch(deployDialog(content, title, variant, size))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        }
    }
}

export default connect(null, mapDispatchToProps)(ContactsSystem);