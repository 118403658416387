import { Box, Button, ButtonGroup, Grid, Typography } from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { parseRow } from 'Helpers/CsvHelper.js';
import { checkEmails } from 'Helpers/EmailHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import AddContactsCSV from './AddContactsCSV';
import AddContactsManual from './AddContactsManual';
import AddContactsSearch from './AddContactsSearch';

const initialState = (props = null) => ({
    isLoading: true,
    existingContacts: _.map([...props?.mailingList?.c?.c ?? [], ...props?.mailingList?.c?.r ?? [], ...props?.mailingList?.c?.q  ?? [], ...props?.mailingList?.c?.l  ?? []], i => i.contact),
    viewMode: 'Contacts',
    lists: {
        contacts: [],
        customers: [],
        emailValidation: {}
    }
})

class AddContactDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify([...(this.props?.mailingList?.c?.c || []), ...(this.props?.mailingList?.c?.r || []), ...(this.props?.mailingList?.c?.q || [])]) !== JSON.stringify([...(prevProps?.mailingList?.c?.c || []), ...(prevProps?.mailingList?.c?.r || []), ...(prevProps?.mailingList?.c?.q || [])])) {
            this.setState(initialState(this.props), this.getData)
        }
    }

    getData = () => {
        Promise.all([
            API.get('/marketing/campaigns/mailingLists/eligibleContacts', { params: {  mlId: this.props.mailingList.i, fullData: true } }),
            API.get(`/marketing/contacts/emailValidation/all`)
        ]).then(([
            contacts,
            emailValidation
        ]) => {

            let warnings = {};
            _.each(_.filter(emailValidation.data, (v) => v.eav_type === 'domains'), i => {
                warnings[i.eav_primary] = JSON.parse(i.eav_secondary)
            });

            let _validation = {
                error: _.map(_.filter(emailValidation.data, (v) => v.eav_type === 'ending'), i => i.eav_primary),
                warning: warnings
            }

            this.setState({
                lists: {
                    customers: [{value: 'all', label: 'All'},..._.uniqBy(_.map(contacts.data, c => ({
                        value: c?.customer?.cust_id || 'manual',
                        label: c?.customer?.cust_name || 'manual'
                    })), 'value')],
                    contacts: _.map(contacts.data, c => ({
                        value: c.contact_id,
                        label: `${c.contact_name} (${c.contact_email})`,
                        customer: c?.customer?.cust_id  || 'manual',
                        contact: c
                    })),
                    emailValidation: _validation
                },
                isLoading: false
            })
        })
    }

    handleTabChange = (viewMode) => () => {
        this.setState({ 
            viewMode
        })
    }

    handleSelectChange = formData => name => e => {
        let value = e ? e?.value : null;
        this.setState({
            [formData]: {
                ...this.state[formData],
                [name]: value
            }
        }, () => {
            if (name === 'contact')
                this.checkContact(formData);
        });
    }

    checkContact = (formData) => {
        let data = this.state[formData];
        let contact = null;
        let error = null;
        let warning = null;

        if (formData === 'searchFormData') {
            if (!data.contact){
                return this.setState({ [formData]: { ...this.state[formData], error, warning} })
            }
            contact = this.state.lists.contacts.find(c => c.value === data.contact).contact;
        }
        if (formData === 'manualFormData') contact = data;

        if (contact){
            
            let validation = checkEmails([contact.contact_email]);
            if (validation?.error?.length > 0) error = 'The contacts email is invalid, this will need to be corrected before adding';
            if (validation?.warning?.length > 0) warning = 'The contacts email may be invalid, please double check before adding';

            if (_.find(this.state.existingContacts, {e: contact.contact_email})) error = 'This email already exists in the mailing list';
            if (_.find(this.state.contacts, {contact_email: contact.contact_email})) error = 'This email is waiting to be added to the mailing list';

            if (contact.contact_id){
                if (contact?.addresses?.length == 1 && contact.addresses[0].address_type === 'Accounts' ) error = 'This contact is an account contact, please select a different contact';
            }

            if (!contact.contact_id){
                if (_.find(this.state.lists.contacts, {contact:{contact_email: contact.contact_email, contact_name: connect.contact_name}})) error = 'This contact already exists in the system';
            }

            this.setState({
                [formData]: {
                    ...this.state[formData],
                    error,
                    warning,
                }
            })
        }
    }

    submitSingle = (formData) => () => {
        let data = this.state[formData];
        if (data?.contact){
            data = this.state.lists.contacts.find(c => c.value === data.contact).contact;
        }
        API.post(`/marketing/campaigns/mailingLists/${this.props.mailingList.i}/addContacts`,{contacts: JSON.stringify([data])}).then(this.props.refresh)
    }

    submitCSV = () => {
        API.post(`/marketing/campaigns/mailingLists/${this.props.mailingList.i}/addContacts`,{contacts: JSON.stringify(this.state.eligibleContacts.toAdd)}).then(this.props.refresh)
    }

    handleTextChange = formData => e => {
        let { name, value } = e.target;
        this.setState({
            [formData]: {
                ...this.state[formData],
                [name]: value
            }
        }, () => {
            if (formData === 'manualFormData') {
                if(this.state.manualFormData.contact_name && this.state.manualFormData.contact_email) {
                    this.checkContact(formData)
                }
            }
        })
    }

    handleFileChange = async (drop, name, event) => {
        const csv = drop === true ? event.dataTransfer.files[0] : event.target.files[0];

        var reader = new FileReader();

        reader.onload = this.csvUpload;
        reader.readAsText(csv);
    }

    csvUpload = e => {
        let data = e.target.result.split('\n');

        let head = data[0].replaceAll('\r','').split(',');
        let body = [];

        for (let i = 1; i < data.length; i++) {
            let row = parseRow(data[i]);
            let obj = {};
    
            for (let j = 0; j < row.length; j++) {
                obj[String(head[j].replaceAll('"','').replaceAll("'",''))] = row[j].replaceAll('"','').replaceAll("'",'');
            }
    
            body.push(obj);
        }

        if (head[0] !== 'Name' || head[1] !== 'Email' ){
            this.props.deployDialog(
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            The CSV file must contain the following columns in the following order: Name, Email.<br/> Please check your CSV file and try again.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant="contained"
                            color='secondary'
                            onClick={this.props.closeDialog}
                        >Ok</Button>
                    </Grid>
                </Grid>,
                'Invalid CSV File',
                'sm',
                'error'
            )
        } else if (body.length < 1){
            this.props.deployDialog(
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            The CSV file must contain at least one contact.<br/> Please check your CSV file and try again.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant="contained"
                            color='secondary'
                            onClick={this.props.closeDialog}
                        >Ok</Button>
                    </Grid>
                </Grid>,
                'Invalid CSV File',
                'sm',
                'error'
            )
        } else {
            this.setState({
                csvFormData: {
                    ...initialState().csvFormData,
                    csv: body,
                }
            }, () => {
                this.checkEligibleContacts([..._.map(_.filter(body, c => c?.Email), (c, idx) => {
                    let _contact = _.find(this.state.lists.contacts, {contact_email: c.Email.replaceAll('\r','').trim(), contact_name: c.Name.replaceAll('\r','').trim()});
                    return {...(_contact ? _contact : {contact_id: 0, contact_email: c.Email.replaceAll('\r','').trim(), contact_name: c.Name.replaceAll('\r','').trim()}) ,idx};
                })]);
            })
        }

    }

    checkEligibleContacts = async contacts => {
        let faultyEmails = [], unsubed = [], inListDuplicate = [], outListDuplicate = [],  newContacts = [], eligible = [], accounts = [], warnings = [];
        let validation = await checkEmails(_.uniq(_.map(contacts, i => i.contact_email)), this.state.emailValidation);

        let emails = {};
        _.each(_.uniq(_.map(contacts, i => i.contact_email)), i => {
            emails[i] = {
                valid: validation.valid.includes(i),
                error: validation.error.includes(i),
                warning: validation.warning.includes(i),
                optOut: _.find(this.state.lists.contacts, {contact_email: i,  contact_email_opt_out: 1}),
                accounts: _.filter(this.state.lists.contacts, {contact_email: i})?.addresses?.length > 0 && (_.filter(this.state.lists.contacts, {contact_email: i}).length === _.filter(_.filter(this.state.lists.contacts, {contact_email: i}), a =>
                                                                                                    a.addresses.length === 1 &&
                                                                                                    a.addresses[0].address_type === "Accounts"
                                                                                                ).length)               
            }
        })
        _.each(contacts, contact => {
            if (_.find(this.state.mlContacts, {contact_email: contact.contact_email})){
                outListDuplicate.push(contact);
            } else if (_.filter(contacts, {contact_email: contact.contact_email}).length > 1){
                inListDuplicate.push(contact);
            } else if (emails[contact.contact_email]?.error){
                faultyEmails.push(contact);
            } else if (emails[contact.contact_email]?.optOut){
                unsubed.push(contact);
            } else if (emails[contact.contact_email]?.accounts){
                accounts.push(contact);
            } else if (!contact?.valid && emails[contact.contact_email]?.warning) {
                warnings.push(contact);
            } else if (contact.contact_id){
                eligible.push(contact);
            } else {
                newContacts.push(contact);
            }
        });

        this.setState({
            eligibleContacts: {
                contacts: contacts,
                toAdd: [...eligible, ...newContacts],
                unsubed: unsubed,
                na: [...accounts, ...warnings],
                invalid: faultyEmails,
                duplicate: [...inListDuplicate, ...outListDuplicate]
            }
        });
    }


    updateCSV = csv => {
        this.setState({
            csvFormData: {
                ...this.state.csvFormData,
                csv: csv,
            }
        })
    }

    CSVToContacts = async () => {
        API.get(
            '/marketing/campaigns/mailingLists/eligibleContacts', { params: { breakdown: true } }
        ).then(({data}) => {
            let systemContacts = data.contacts;
            this.setState({
                csvFormData: {
                    ...initialState().csvFormData,
                    contacts: {
                        ...initialState().csvFormData.contacts,
                        all: [...this.state.csvFormData.contacts.all,..._.map(_.filter(this.state.csvFormData.csv, c => c?.Email), (c, idx) => {
                            let _contact = _.find(systemContacts, {contact_email: c.Email.replaceAll('\r','').trim(), contact_name: c.Name.replaceAll('\r','').trim()});
                            return {...(_contact ? _contact : {contact_id: 0, contact_email: c.Email.replaceAll('\r','').trim(), contact_name: c.Name.replaceAll('\r','').trim()}) ,idx};
                        })],
                    }
                }

            }, () => {
                this.checkCsvContacts();
            });
        })

    }

    checkCsvContacts = () => {
        this.setState({
            csvFormData: {
                ...this.state.csvFormData,
                contacts: {
                    ...this.state.csvFormData.contacts,
                    all: this.state.csvFormData.contacts.all,
                },
            }
        }, () => {
            this._checkCsvContacts(true);
        })
    }

    _checkCsvContacts = async (withDialog = false) => {
        let contacts = this.state.csvFormData.contacts.all;
        let 
            faultyEmails = [],
            unsubed = [],
            inListDuplicate = [],
            outListDuplicate = [], 
            newContacts = [],
            eligible = [],
            accounts = [],
            warnings = [];

        let validation = await checkEmails(_.uniq(_.map(this.state.csvFormData.contacts.all, i => i.contact_email)))
        let emails = {};
        _.each(_.uniq(_.map(this.state.csvFormData.contacts.all, i => i.contact_email)), i => {
            emails[i] = {
                valid: validation.valid.includes(i),
                error: validation.error.includes(i),
                warning: validation.warning.includes(i),
                optOut: _.find(this.state.lists.contacts, {contact:{contact_email: i,  contact_email_opt_out: 1}}),
                accounts: _.filter(this.state.lists.contacts, {contact:{contact_email: i}}).contact?.addresses?.length > 0 && (_.filter(this.state.lists.contacts, {contact:{contact_email: i}}).length === _.filter(_.filter(this.state.lists.contacts, {contact:{contact_email: i}}), a =>
                                                                                                    a.contact.addresses.length === 1 &&
                                                                                                    a.contact.addresses[0].address_type === "Accounts"
                                                                                                ).length)               
            }
        } )

        let mlContacts = _.map(this.state.existingContacts, i => ({
            contact_email: i.e,
            contact_name: i.n,
            contact_id: i.i
        }));

        _.each(this.state.csvFormData.contacts.all, contact => {
            if (_.find(mlContacts, {contact_email: contact.contact_email})){
                outListDuplicate.push(contact);
            } else if (_.filter(contacts, {contact_email: contact.contact_email}).length > 1){
                inListDuplicate.push(contact);
            } else if (emails[contact.contact_email]?.error){
                faultyEmails.push(contact);
            } else if (emails[contact.contact_email]?.optOut){
                unsubed.push(contact);
            } else if (emails[contact.contact_email]?.accounts){
                accounts.push(contact);
            } else if (!contact?.valid && emails[contact.contact_email]?.warning) {
                warnings.push(contact);
            } else if (contact.contact_id){
                eligible.push(contact);
            } else {
                newContacts.push(contact);
            }
        });

        this.setState({
            csvFormData: {
                ...this.state.csvFormData,
                contacts: {
                    ...this.state.csvFormData.contacts,
                    eligible,
                    newContacts,
                    inListDuplicate,
                    outListDuplicate,
                    faultyEmails,
                    unsubed,
                    accounts,
                    warnings
                }
            },
            eligibleContacts: {
                contacts: contacts,
                toAdd: [...eligible, ...newContacts],
                unsubed: unsubed,
                na: [...accounts, ...warnings],
                invalid: faultyEmails,
                duplicate: [...inListDuplicate, ...outListDuplicate]
            }
        });
    }

    onContactsDialogSubmit = (contacts) => (forceCommit=false) => () => {
        this.setState({
            formData: forceCommit || [...contacts.eligible, ...contacts.newContacts].length === contacts.contacts.length ? {
                ...this.state.formData,
                contacts: [...contacts.eligible, ...contacts.newContacts],
            } : this.state.formData,
            eligibleContacts: {
                contacts: contacts.contacts,
                toAdd: [...contacts.eligible, ...contacts.newContacts],
                unsubed: contacts.unsubed,
                na: contacts.accounts,
                invalid: [...contacts.faultyEmails, ...contacts.warnings],
                duplicate: [...contacts.inListDuplicate, ...contacts.outListDuplicate]
            },
            dialogFormData: {
                ...this.state.dialogFormData,
                showDialog: false,
            }
        })
    }

    showEligibleContactsPreview = withSub => () => {
        this.setState({
            eligibleContactsDialog: {
                open: !this.state.eligibleContactsDialog?.open,
                withSub: withSub,
            }
        })
    }

    showCsvContactsDialog = () => {
        this.setState({
            dialogFormData: {
                ...this.state.dialogFormData,
                showDialog: true,
            }
        })
    }

    render() {
        const { isLoading, viewMode, lists, searchFormData, manualFormData, csvFormData, eligibleContacts, eligibleContactsDialog, dialogFormData, existingContacts } = this.state;

        if (isLoading) {
            return (
                <LoadingCircle/>
            )
        }

        return (
            <Box>
                <Grid container spacing={3} alignItems='flex-end'>
                    <Grid item xs={12}>
                        <ButtonGroup variant='outlined' fullWidth>
                            <Button  variant={viewMode === 'Contacts' ? 'contained' : 'outlined'} color={viewMode === 'Contacts' ? 'primary' : ''} onClick={this.handleTabChange('Contacts')} >Search</Button>
                            <Button variant={viewMode === 'Manual' ? 'contained' : 'outlined'} color={viewMode === 'Manual' ? 'primary' : ''} onClick={this.handleTabChange('Manual')} >New Contact</Button>
                            <Button variant={viewMode === 'CSV' ? 'contained' : 'outlined'} color={viewMode === 'CSV' ? 'primary' : ''} onClick={this.handleTabChange('CSV')}>Csv</Button> 
                        </ButtonGroup>
                    </Grid>
                    {viewMode === 'Contacts' &&
                        <AddContactsSearch
                            {...this.props}
                            lists={lists}
                            existingContacts={existingContacts}
                        />
                    }
                    {viewMode === 'Manual' &&
                        <AddContactsManual
                            {...this.props}
                            lists={lists}
                            existingContacts={existingContacts}
                        />
                    }
                    {viewMode === 'CSV' &&
                        <AddContactsCSV
                            {...this.props}
                            lists={lists}
                            existingContacts={existingContacts}
                        />
                    }
{/*}

                    {viewMode === 'CSV' &&
                        <>
                            {eligibleContacts?.contacts?.length > 0 ?
                                <>
                                    <InfoBox
                                        rowSize={3}
                                        icon={IconHelper.customers}
                                        value={eligibleContacts.contacts.length}
                                        title="Contacts"
                                    />
                                    <InfoBox
                                        rowSize={3}
                                        icon={IconHelper.plus}
                                        value={eligibleContacts.toAdd.length}
                                        title="Eligible"
                                    />
                                    <InfoBox
                                        rowSize={3}
                                        icon={IconHelper.false}
                                        value={_.sumBy(['na', 'invalid', 'duplicate', 'unsubed'], r => eligibleContacts[r].length ?? 0)}
                                        title={<>Not Eligible <InfoIcon info='This includes; unsubscribed contacts, accounts contacts, invalid email addresses, and duplicate email addresses.'/></>}
                                    />
                                </> :
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant='body1' style={{fontWeight: 'bold', marginBottom: '1em', marginTop: '1em'}}>Note: Uploaded CSV must have the following headings:</Typography>
                                        <DataTable
                                            config={{
                                                plainHeader: true,
                                                noHeader: true,
                                            }}
                                            columns={[
                                                {
                                                    field:   'c',
                                                    sizeToContent: true,
                                                    style: { fontWeight: 'bold', borderRight: `1px solid ${colors.disabled}` },
                                                },
                                                {
                                                    field: 'n',
                                                },
                                            ]}
                                            rows={[
                                                {c: 'A1', n: 'Name' },
                                                {c: 'A2', n: 'Email' },
                                            ]}
                                        />
                                        <DragFileInput
                                            accept=".csv"
                                            onChange={this.handleFileChange}
                                            label='Upload CSV *'
                                        />
                                    </Grid>
                                </>
                            }
                            {eligibleContactsDialog?.open &&
                                <Dialog
                                    open={eligibleContactsDialog?.open}
                                    maxWidth="xs"
                                    fullWidth
                                >
                                    <DialogTitle>{eligibleContactsDialog.withSub ? 'Create Mailing List?' : 'Eligible Contacts'}</DialogTitle>
                                    <DialogContent>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="caption">
                                                    The following contacts will be added to the mailing list:
                                                </Typography>
                                                <List>
                                                    {_.map(eligibleContacts.toAdd, (contact, i) => 
                                                        <ListItem key={i}>
                                                            <ListItemText primary={`${contact.contact_name} ${contact?.customer?.cust_name ? `(${contact.customer.cust_name})` : ''}`} secondary={contact.contact_email} />
                                                        </ListItem>
                                                    )}
                                                </List>
                                            </Grid>
                                            
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            variant="outlined"
                                            onClick={this.showEligibleContactsPreview(false)}
                                        >{eligibleContactsDialog.withSub ? 'Cancel':'Close'}</Button>
                                        {
                                            eligibleContactsDialog.withSub &&
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.submitCSV}
                                            >
                                                Add Contacts
                                            </Button>
                                        }
                                    </DialogActions>
                                </Dialog>
                            }
                        </>
                    }
                    {viewMode === 'Contacts' &&
                        <>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    label='Customer'
                                    options={lists.customers}
                                    value={searchFormData.customer}
                                    fullWidth
                                    onChange={this.handleSelectChange('searchFormData')('customer')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    label='Contact *'
                                    options={_.filter(searchFormData.customer ? _.filter(lists.contacts, {customer: searchFormData.customer}) : lists.contacts, c => !c?.addresses || (c?.addresses?.length > 1 || (
                                        c.addresses.length == 1 && c.addresses[0].address_type !== 'Accounts')
                                    ))}
                                    value={searchFormData.contact}
                                    fullWidth
                                    onChange={this.handleSelectChange('searchFormData')('contact')}
                                />
                            </Grid>
                            {(searchFormData.error || searchFormData.warning) &&
                                <Grid item xs={12} style={{paddingBottom:0}}>
                                    <Typography style={{color:searchFormData.error ? colors.red : colors.orange, textAlign: 'right'}}>{searchFormData.error || searchFormData.warning}</Typography>
                                </Grid>
                            }
                        </>
                    }
                    {viewMode === 'Manual' &&
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    label='Contact Name *'
                                    fullWidth
                                    value={manualFormData.contact_name}
                                    name='contact_name'
                                    onChange={this.handleTextChange('manualFormData')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label='Contact Email *'
                                    fullWidth
                                    value={manualFormData.contact_email}
                                    name='contact_email'
                                    onChange={this.handleTextChange('manualFormData')}
                                />
                            </Grid>
                            {(manualFormData.error || manualFormData.warning) &&
                                <Grid item xs={12} style={{paddingBottom:0}}>
                                    <Typography style={{color:manualFormData.error ? colors.red : colors.orange, textAlign: 'right'}}>{manualFormData.error || manualFormData.warning}</Typography>
                                </Grid>
                            }
                        </>
                    }
                    
                    <Grid item xs={12} className='buttonRow'>
                        {viewMode === 'Contacts' &&
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!!searchFormData.error || !searchFormData.contact}
                                onClick={
                                    ()=> this.props.deployConfirmation(
                                        'Are you sure you want to add this contact to the mailing list?',
                                        'Add Contact',
                                        this.submitSingle('searchFormData')
                                    )
                                }
                            >Add</Button>
                        }
                        {viewMode === 'Manual' &&
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!!manualFormData.error || !manualFormData.contact_email || !manualFormData.contact_name}
                                onClick={
                                    ()=> this.props.deployConfirmation(
                                        'Are you sure you want to add this contact to the mailing list?',
                                        'Add Contact',
                                        this.submitSingle('manualFormData')
                                    )
                                }
                            >Add</Button>
                        }
                        {viewMode === 'CSV' &&
                            <>
                                {
                                    eligibleContacts?.toAdd?.length > 0 &&
                                    <>
                                        {eligibleContacts?.toAdd?.length !== eligibleContacts?.contacts?.length ?
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={this.showCsvContactsDialog}
                                            >
                                                Check Contacts
                                            </Button> :

                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={this.showEligibleContactsPreview(false)}
                                            >
                                                Preview Eligible Contacts
                                            </Button>    
                                        }
                                    </>
                                }
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.showEligibleContactsPreview(true)}
                                    disabled={ 
                                        eligibleContacts?.toAdd?.length <= 0 || 
                                        eligibleContacts?.toAdd?.length > 0 ||
                                        eligibleContacts?.contacts?.length !== eligibleContacts?.toAdd?.length
                                    }
                                >Add</Button>
                            </>
                        }
                    </Grid>
                    */}
                </Grid>
                {/*}
                {dialogFormData.showDialog &&
                    <CSVContactsDialog
                        open={dialogFormData.showDialog}
                        eligibleContacts={eligibleContacts}
                        contacts={lists.contacts}
                        onSubmit={this.onContactsDialogSubmit}
                        mlContacts={_.map(this.state.existingContacts, i => ({
                            contact_email: i.e,
                            ...i
                        }))}
                    />
                }
                */}
            </Box>
        )

    }

}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback) => {
            dispatch(deployConfirmation(content, title, callback))
        },
    }
}

export default connect(null, mapDispatchToProps)(AddContactDialog);