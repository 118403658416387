import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinkTo from '@material-ui/core/Link';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import API from '../../../API';
import DataTable from '../../Common/DataTables/DataTable';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import Textarea from '../../Common/Inputs/Textarea';
import BackButton from 'Components/Common/Buttons/BackButton';
import {colors} from 'Helpers/ColourHelper';

class ViewEmail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: {},
            email: {
                emailTo: '',
                emailCc: '',
                emailSubject: '',
                emailType: '',
                emailAttachments: '',
                emailBody: '',
                emailStatus: '',
                emailDatetimeAdded: '',
                emailDatetimeOpened: '',
                transfers: []
            },
            isLoading: true,
            redirect: false
        };
    }

    componentDidMount(){
       this.getEmail(this.props.match.params.id); 
    }

    getEmail = (emailId) => {
        this.setState({
            isLoading: true
        }, () => {
            API.get(`/email/${emailId}`)
            .then((result) => {
                if (result.data.errors) {
                    this.setState({ redirect: true })
                } else if (result.data) {
                    result.data.transfers.forEach(transfer => {
                        transfer.expired = transfer.transfer_expiry < moment().format("YYYY-MM-DD HH:mm") ? true :false
                    })
    
                    this.setState({
                        ...this.state,
                        email: {
                            emailUid: result.data.email_uid,
                            emailTo: result.data.email_to,
                            emailCc: result.data.email_cc,
                            emailSubject: result.data.email_subject,
                            emailType: result.data.email_type,
                            emailAttachments: result.data.email_attachments,
                            emailBody: result.data.email_body,
                            emailStatus: result.data.email_status,
                            emailDatetimeAdded: result.data.email_datetime_added,
                            emailDatetimeOpened: result.data.email_datetime_opened,
                            transfers: result.data.transfers
                        },
                        isLoading: false
                    });
                }
            })
        })
    }

    handleChange = (e) => {
        this.setState({
            formData: {
            ...this.state.formData,
            [e.target.name]: e.target.value
            }
        });
    }

    render() {
        const { email } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/email/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Email Details
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                {(this.state.isLoading && (
                                    <LoadingCircle />
                                )) || (
                                    <React.Fragment>
                                        <TextField
                                            id="emailTo"
                                            name="emailTo"
                                            label="To"
                                            value={email.emailTo}
                                            margin="normal"
                                            fullWidth
                                            disabled
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                        <TextField
                                            label="Cc"
                                            value={email.emailCc}
                                            margin="normal"
                                            fullWidth
                                            disabled
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                        <TextField
                                            label="Subject"
                                            value={email.emailSubject}
                                            margin="normal"
                                            fullWidth
                                            disabled
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                        {email.emailType === 'email' &&
                                            <Textarea
                                                label="Attachments"
                                                value={email.emailAttachments}
                                                rows={2}
                                                disabled
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        }
                                        <Textarea
                                            label="Email Body"
                                            value={email.emailBody}
                                            rows={10}
                                            disabled
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                        <div className='buttonRow'>
                                            <BackButton props={this.props} />
                                        </div>
                                    </React.Fragment>
                                )}
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <PaddedPaper>
                                {(this.state.isLoading && (
                                    <LoadingCircle />
                                )) || (
                                    <React.Fragment>
                                        <Typography variant="h5" gutterBottom>
                                            {email.emailStatus === 'Sent' ? <FALightIcon icon='envelope' size='large' /> : <FALightIcon icon='envelope-open' size='large' />}
                                            {email.emailStatus}
                                        </Typography>
                                        <br></br>
                                        <Typography component="p" variant="body1" gutterBottom>
                                        <b>Email Sent:</b>  {moment(email.emailDatetimeAdded).format("DD/MM/YYYY  H:mm")}
                                        </Typography>
                                        <Typography component="p" variant="body1" gutterBottom>
                                        <b>Email Opened:</b> {email.emailDatetimeOpened !== '0000-00-00 00:00:00' ? moment(email.emailDatetimeOpened).format("DD/MM/YYYY  H:mm") : '-'}
                                        </Typography>
                                    </React.Fragment>
                                )}
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    {email.emailType === 'transfer' &&
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h5" gutterBottom>
                                    Transfers
                                </Typography>
                                <DataTable 
                                    config={{
                                        key: 'transfer_id',
                                        pagination: false,
                                        alternatingRowColours: true,
                                        isLoading: this.state.isLoading
                                    }}
                                    columns={[
                                        {
                                            heading: 'Category',
                                            field: rowData => rowData.transfer_category
                                        },
                                        {
                                            heading: 'Filename',
                                            field: rowData => rowData.expired ? rowData.transfer_filename : <LinkTo href={rowData.transfer_url} target="_blank">{rowData.transfer_filename}</LinkTo>
                                        },
                                        {
                                            heading: 'Status',
                                            field: rowData => rowData.transfer_status
                                        },
                                        {
                                            heading: 'Expiry',
                                            field: rowData => rowData.expired ? 'Expired '+ moment(rowData.transfer_expiry).format("DD/MM/YYYY HH:mm") : moment(rowData.transfer_expiry).format("DD/MM/YYYY HH:mm"),
                                            cellProps: rowData => {
                                                return ({
                                                    style: {
                                                        color: rowData.expired && colors.red
                                                    }
                                                });
                                            }
                                        }
                                    ]}
                                    rows={email.transfers}
                                />
                            </PaddedPaper>
                        </Grid>
                    }
                </Grid>
            );   
        }
    }
}
export default ViewEmail;
