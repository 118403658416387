import React from "react";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import Draggable from "react-draggable";

export default function PaperComponent(props: PaperProps) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}