import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import API from '../../../API';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar'
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import { TextField, InputAdornment } from '@material-ui/core';

const initialState = {
    formData: {
        code: '',
        target: ''
    },
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false
}

class AddShortlink extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount() {
        this.setState({
            formData: {
                code: this.generateCode()
            }
        })
    }
    handleSubmit = () => {
        API.post('/marketing/shortlinks', this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    formData: {
                        ...initialState.formData,
                        code: this.generateCode()
                    },
                    snackbarOpen: true
                });
            }
            this.props.scrollToTop();
        });
    }
    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };
    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    };
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }
    generateCode = () => {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for(let i = 0; i < 6; i++) {
           result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }
    render() {
        const { formData, formErrors, snackbarOpen } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Shortlink
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Typography variant="h6">
                                    Shortlink Details
                                </Typography>
                                <FormControl fullWidth>
                                    <TextField
                                        id="code"
                                        name="code"
                                        label="Shortlink URL *"
                                        value={formData.code}
                                        error={formErrors && formErrors['code']}
                                        helperText={formErrors && formErrors['code']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                        InputProps={{
                                          startAdornment: <InputAdornment position="start">https://clen.uk/</InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        id="target"
                                        name="target"
                                        label="Target URL *"
                                        value={formData.target}
                                        error={formErrors && formErrors['target']}
                                        helperText={formErrors && formErrors['target']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.handleConfirmationOpen}
                                        variant="contained"
                                        color="primary"
                                        disabled={Object.values(formData).every(x => (x === null || x === ""))}>
                                        Create
                                    </Button>
                                </div>
                                <ConfirmationDialog 
                                    open={this.state.confirmationOpen} 
                                    success={this.handleConfirmationSuccess} 
                                    close={this.handleConfirmationClose} 
                                    title="Add A New Shortlink?" 
                                    message="Are you sure you want to add a new shortlink?"
                                />
                                <SnackBar
                                    variant="success"
                                    anchorOriginVertical='bottom'
                                    anchorOriginHorizontal='right'
                                    open={snackbarOpen}
                                    onClose={this.handleSnackbarClose}
                                    message="The shortlink was successfully created"
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default AddShortlink;