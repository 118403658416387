import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';

const safeTagsDefault = [ 'b','br'];

class TypographyAllowHtml extends Component {
    constructor(props) {
        super(props);
        this.safeTags = this.props.safeTags ? this.props.safeTags : safeTagsDefault;
    }

    cleanProp = () => {
        var html = this.props.body;
        var tmp = document.createElement("DIV");

        for (let i = 0; i < this.safeTags.length; i++) {
            const st = this.safeTags[i];
            html = html.replace(`<${st}>`,`$${st}$`);  
            html = html.replace(`</${st}>`,`$/${st}$`);  
        }

        tmp.innerHTML = html;
        html = tmp.textContent||tmp.innerText;

        for (let i = 0; i < this.safeTags.length; i++) {
            const st = this.safeTags[i];
            html = html.replace(`$${st}$`,`<${st}>`);  
            html = html.replace(`$/${st}$`, `</${st}>`);   
        }
        return html;
    }

    render() {

        const TextType = () => {
            return this.props.component ? 
                <this.props.component dangerouslySetInnerHTML={{__html: `${this.cleanProp()}`}} /> :
                <Typography dangerouslySetInnerHTML={{__html: `${this.cleanProp()}`}} />
        }

        return (
            <TextType />
        )
    }
}

export default TypographyAllowHtml;
