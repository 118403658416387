import React from 'react';
import _ from "lodash";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import { useEffect, useState } from "react";
import API from "API";
import { S3_BUCKET_URL } from "Constants";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import { closeDialog, deployDialog } from "Actions/Dialog/Dialog";
import { useDispatch } from "react-redux";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import WildixCallTranscription from './WildixCallTranscription';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

const WildixCallRecordings = ({ id }) => {
    const [data, setData] = useState([]),
        [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    const handleLoadData = () => {
        setLoading(true);
        API.get(`/voip/${id}/recordings`).then((res) => {
            if (res?.data) {
                setData(res.data);
                setLoading(false);
            }
        });
    };

    const handleRequestTranscription = (recordingId) => {
        API.post(`/voip/${id}/recordings/${recordingId}/transcription`)
        .then((res) => {
            if (res?.data?.success) {
                dispatch(deploySnackBar("success", "Transcription requested successfully - this will be available shortly"));
                handleLoadData();
            }
        });
    };

    const handleViewTranscription = () => {
        dispatch(deployDialog(
            <WildixCallTranscription id={id} />,
            "Call Transcription",
            "standard",
            "md"
        ))
    };

    useEffect(() => {
        handleLoadData();
    }, [id]); /* eslint-disable-line */

    return (
        <>
            {(loading && <LoadingCircle />) || (
                <Box>
                    {_.map(data, (recording, i) => (
                        <Box key={i} mb={3}>
                            <Typography variant="subtitle2" paragraph>
                                Recording {i + 1}
                            </Typography>
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item>
                                    <audio src={`${S3_BUCKET_URL}${recording.fk}`} controls>
                                        Your browser does not support the audio element.
                                    </audio>
                                </Grid>
                                <Grid item>
                                    {recording.ta ? (
                                        <Tooltip title="View Transcription" placement="top">
                                            <IconButton onClick={handleViewTranscription}>
                                                <FAIcon icon="file-lines" button noMargin />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Request Transcription" placement="top">
                                            <IconButton onClick={() => handleRequestTranscription(recording.id)}>
                                                <FAIcon icon="sync" button noMargin />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                    <Button onClick={() => dispatch(closeDialog())} variant="outlined" color="default" fullWidth size="large">
                        <FAIcon icon="times" size={15} button />
                        Close
                    </Button>
                </Box>
            )}
        </>
    );
};

export default WildixCallRecordings;
