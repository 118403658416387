import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import API from 'API';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import _ from 'lodash';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import IconHelper from 'Helpers/IconHelper';

const InitialState = () => ({
    isLoading: false,
    campaigns: [],
})

class Promotions extends React.Component {
    constructor(props) {
        super(props);
        this.state = InitialState();
    }

    componentDidMount() {
        this.getCampaigns();
    }

    getCampaigns = () => {
        API.get(`/customers/${this.props.customerId}/emailCampaigns`).then(r => {
            this.setState({
                campaigns: [ ..._.map(r.data[0], i => ({...i, sent: 1})),  ..._.map(r.data[1], i => ({...i, sent: 0})) ],
                isLoading: false
            })
        })
    }

    render() {

        const { isLoading, campaigns } = this.state;

        if (isLoading) {
            return <LoadingCircle />;
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <>
                        <CiDataTable
                            config={{
                                id: 'ec_id',
                            }}
                            columns={[
                                {
                                    heading: 'Campaign',
                                    field: 'ec_name',
                                    truncate: true,
                                    dataRef: 'ec_name',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Reference',
                                    field: 'ec_reference',
                                    truncate: true,
                                    dataRef: 'ec_reference',
                                },
                                {
                                    heading: 'Email Sent',
                                    field: 'sent',
                                    dataRef: 'sent',
                                    fieldFormat: 'boolean',
                                    sizeToContent: true,
                                    alignment: 'center'
                                },
                                {
                                    heading: 'Quotation',
                                    field: i=> parseInt(i?.promotion?.quotes_count) > 0,
                                    dataRef: i=>i?.promotion?.quotes_count,
                                    fieldFormat: 'boolean',
                                    sizeToContent: true,
                                    alignment: 'center'
                                },
                                {
                                    heading: 'Order',
                                    field: i=> parseInt(i?.promotion?.orders_count) > 0,
                                    dataRef: i=>i?.promotion?.orders_count,
                                    fieldFormat: 'boolean',
                                    sizeToContent: true,
                                    alignment: 'center'
                                },
                                {
                                    actions: i => ([
                                        {
                                            name: 'View',
                                            link: `/marketing/campaigns/emailCampaigns/${i.ec_id}`,
                                            icon: IconHelper.search
                                        }
                                    ])
                                }
                            ]}
                            rows={campaigns}
                        />
                    </>
                </Grid>
            </Grid>
        );
    }
}

export default Promotions;