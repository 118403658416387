import React from 'react';
import _ from 'lodash';
import API from 'API';
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    FormControlLabel,
    Radio,
    RadioGroup,
    ListItem,
    ListItemText,
    List
} from '@material-ui/core';
import AllIcon from 'Components/Common/Icons/AllIcon';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import Textarea from 'Components/Common/Inputs/Textarea';

const initialState = () => ({
    access: {
        updateVehicle: false,
    },
    suppList: [],
    initialList: [],
    monthlyList: [],
})

class VehicleInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        const { vehicle } = this.props;
        this.setState({
            ...this.state,
            vehicle: vehicle
        }, () => this.getAccess());
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-vehicle')
        ])
        .then(([updateRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    updateVehicle: (updateRes.data && updateRes.data.has_access) || false
                }
            }, () => {
                this.loadComponentData(); 
            });
        }) 
    }

    loadComponentData = () => {
        
        API.get('/suppliers/all', {
            params: {
                supplierType: 10 // Vehicles
            }
        })
        .then(suppRes => {

            let suppList = _.map(suppRes.data, (supp) => {
                return _.assign({
                    value: supp.supp_id,
                    label: supp.supp_company_name,
                    contact: supp.supp_telephone,
                });
            });
            // List of numbers 1 - 12 (Lease payment profile - initial)
            let initialList = _.map(_.range(1, 13), (num) => {
                return { value: num, label: num };
            });
            // List of numbers 1 - 60 (Lease payment profile - monthly)
            let monthlyList = _.map(_.range(1, 61), (num) => {
                return { value: num, label: num };
            });
            
            this.setState({
                suppList,
                initialList,
                monthlyList
            });  
        });
    }

    render() {

        const { vehicle, access, suppList, initialList, monthlyList } = this.state;
        const { activeVehicle } = this.props;
        let selectedSupplier = _.find(suppList, (el) => parseInt(el.value) === parseInt(vehicle?.leaseCompany) );

        return (
            <>
                <PaddedPaper>
                    <Grid container item spacing={3}>                        
                        <Grid item xs={12} lg={6}>
                            <Grid container item spacing={3}>
                                <Grid item xs={12}>
                                    <Grid container item spacing={3}>
                                        <Grid item xs>
                                            <Typography variant="body1" paragraph>
                                                <b>Vehicle Details</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs align='right'>
                                            {access.updateVehicle && activeVehicle ?
                                                <AllIcon 
                                                    icon='pencil-alt'
                                                    button
                                                    noMargin
                                                    tooltip="Edit Vehicle"
                                                    onClick={() => this.props.history.push(`/vehicles/update/${vehicle?.id}`)}
                                                />
                                            : null }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item spacing={3}>                        
                                        <Grid item xs>
                                            <TextField  
                                                name="regNumber"
                                                label="Registration Number "
                                                margin="none"
                                                value={vehicle?.regNumber || ''}
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <AutoCompleteSelect 
                                                options={[
                                                    {value: 'Private', label: 'Private'},
                                                    {value: 'Commercial', label: 'Commercial'},
                                                ]} 
                                                label={"Type"}
                                                value={vehicle?.type}
                                                noClear
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item spacing={3}>                        
                                        <Grid item xs>
                                            <TextField  
                                                name="make"
                                                label="Make "
                                                margin="none"
                                                value={vehicle?.make || ''}
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField  
                                                name="model"
                                                label="Model "
                                                margin="none"
                                                value={vehicle?.model || ''}
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item spacing={3}>                        
                                        <Grid item xs>
                                            <AutoCompleteSelect 
                                                options={[
                                                    {value: 'Manual', label: 'Manual'},
                                                    {value: 'Automatic', label: 'Automatic'},
                                                ]} 
                                                label={"Transmission "}
                                                value={vehicle?.transmission}
                                                noClear
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <AutoCompleteSelect 
                                                options={[
                                                    {value: 'Diesel', label: 'Diesel'},
                                                    {value: 'Petrol', label: 'Petrol'},
                                                    {value: 'Electric', label: 'Electric'},
                                                    {value: 'Hybrid', label: 'Hybrid'},
                                                ]} 
                                                label={"Fuel Type "}
                                                value={vehicle?.fuelType}
                                                noClear
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item spacing={3}>                        
                                        <Grid item xs>
                                            <TextField  
                                                name="engineSize"
                                                label="Engine Size"
                                                margin="none"
                                                value={vehicle?.engineSize || ''}
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField  
                                                name="colour"
                                                label="Colour"
                                                margin="none"
                                                value={vehicle?.colour || ''}
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item spacing={3}>                        
                                        <Grid item xs>
                                            <TextField  
                                                name="vehicleValue"
                                                label="Vehicle Value"
                                                margin="none"
                                                value={vehicle?.vehicleValue || ''}
                                                fullWidth
                                                type="number"
                                                InputProps={{
                                                    startAdornment: (<InputAdornment position="end">£</InputAdornment>)
                                                }}
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField  
                                                name="yearOfMake"
                                                label="Year of Make"
                                                margin="none"
                                                value={vehicle?.yearOfMake || ''}
                                                fullWidth
                                                type="number"
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item spacing={3}>                        
                                        <Grid item xs>
                                            <TextField  
                                                name="vinNumber"
                                                label="Vin Number"
                                                margin="none"
                                                value={vehicle?.vinNumber || ''}
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField  
                                                name="electricRange"
                                                label="Range (Electric Only)"
                                                margin="none"
                                                value={vehicle?.electricRange || ''}
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item spacing={3}>                        
                                        <Grid item xs>
                                            <TextField  
                                                name="maxHeight"
                                                label="Max Height"
                                                margin="none"
                                                value={vehicle?.maxHeight || ''}
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField  
                                                name="maxLoad"
                                                label="Max Load"
                                                margin="none"
                                                value={vehicle?.maxLoad || ''}
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item spacing={3}>                        
                                        <Grid item xs>
                                            <TextField  
                                                name="keyCode"
                                                label="Key Code"
                                                margin="none"
                                                value={vehicle?.keyCode || ''}
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField  
                                                name="radioCode"
                                                label="Radio Code"
                                                margin="none"
                                                value={vehicle?.radioCode || ''}
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item spacing={3}>                        
                                        <Grid item xs>
                                            <AutoCompleteSelect 
                                                options={[
                                                    {value: 1, label: 'Yes'},
                                                    {value: 0, label: 'No'},
                                                ]} 
                                                label="Wrapped"
                                                value={vehicle?.wrapped}
                                                noClear
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <AutoCompleteSelect 
                                                options={[
                                                    {value: 1, label: 'Yes'},
                                                    {value: 0, label: 'No'},
                                                ]} 
                                                label="Modified"
                                                value={vehicle?.modified}
                                                noClear
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {vehicle?.modified ?
                                    <Grid item xs={12}>
                                        <Textarea
                                            label="Modifications"
                                            value={vehicle?.modifications}
                                            disabled={true}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                className:"textDefault"
                                            }}
                                        />
                                    </Grid>
                                : null
                                }
                                <Grid item xs={12}>
                                    <AutoCompleteSelect 
                                        options={[
                                            {value: 'In Contract / Ownership', label: 'In Contract / Ownership'},
                                            {value: 'Lease Completed / Sold', label: 'Lease Completed / Sold'},
                                        ]} 
                                        label="Status"
                                        value={vehicle?.status}
                                        noClear
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" paragraph style={{marginTop: 50}}>
                                        <b>Delivery Details</b>
                                    </Typography>
                                    <Grid container item spacing={3}>                        
                                        <Grid item xs>
                                            <DatePicker
                                                type="date"
                                                id="deliveryDate"
                                                name="deliveryDate"
                                                label={"Delivery Date"}
                                                value={vehicle?.deliveryDate !== '0000-00-00' ? vehicle?.deliveryDate : null}
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField  
                                                name="deliveryMileage"
                                                label="Delivery Mileage"
                                                margin="normal"
                                                value={vehicle?.deliveryMileage || ''}
                                                fullWidth
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" paragraph style={{marginTop: 50}}>
                                        <b>Maintenance Details</b>
                                    </Typography>
                                    <Grid container item spacing={3}>                        
                                        <Grid item xs={6}>
                                            <DatePicker
                                                type="date"
                                                name="motExpiry"
                                                label={"MOT Expiry"}
                                                value={vehicle?.motExpiry}
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" color="textSecondary">
                                                Service due every:
                                            </Typography>
                                            <Grid container item spacing={3}  direction="row" alignItems="flex-end">                        
                                                <Grid item xs>
                                                    <TextField  
                                                        name="serviceMonths"
                                                        margin="normal"
                                                        value={vehicle?.serviceMonths || ''}
                                                        fullWidth
                                                        type="number"
                                                        InputProps={{
                                                            endAdornment: (<InputAdornment position="end">Months</InputAdornment>)
                                                        }}
                                                        disabled={true}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>or</Grid>
                                                <Grid item xs>
                                                    <TextField  
                                                        name="serviceMiles"
                                                        margin="normal"
                                                        value={vehicle?.serviceMiles || ''}
                                                        fullWidth
                                                        type="number"
                                                        InputProps={{
                                                            endAdornment: (<InputAdornment position="end">Miles</InputAdornment>)
                                                        }}
                                                        disabled={true}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>    
                                <Grid item xs={12}>
                                    <Typography variant="body1" paragraph style={{marginTop: 50}}>
                                        <b>Road Tax</b>        
                                    </Typography>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12} md>
                                            <AutoCompleteSelect 
                                                options={[
                                                    {value: 1, label: 'Yes'},
                                                    {value: 0, label: 'No'},
                                                ]} 
                                                label="Road Tax Required"
                                                value={vehicle?.roadTaxRequired}
                                                noClear
                                                fullWidth
                                                margin="normal"
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        {vehicle?.roadTaxRequired === 1 && (
                                            <>
                                                <Grid item xs={12} md>
                                                    <AutoCompleteSelect 
                                                        options={[
                                                            {value: 'Annually', label: 'Paid Annually'},
                                                            {value: 'Direct Debit', label: 'Paid by Direct Debit'},
                                                        ]} 
                                                        label="Payment Method"
                                                        value={vehicle?.roadTaxPaymentMethod}
                                                        noClear
                                                        fullWidth
                                                        margin="normal"
                                                        disabled={true}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md>
                                                    <DatePicker
                                                        type="date"
                                                        id="roadTaxDue"
                                                        name="roadTaxDue"
                                                        label={"Tax Due"}
                                                        value={vehicle?.roadTaxDue !== '0000-00-00' ? vehicle?.roadTaxDue : null}
                                                        autoOk={true}
                                                        margin="none"
                                                        disabled={true}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container item spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" paragraph>
                                        <b>{vehicle?.ownershipType === 'Leased' ? 'Lease' : 'Ownership'} Information</b>
                                    </Typography>
                                    <Grid container item spacing={3}>
                                        {vehicle?.ownershipType === 'Owned' ?
                                            <>
                                            <Grid item xs={12}>
                                                <Grid container item spacing={3}>                        
                                                    <Grid item xs>
                                                        <TextField  
                                                            name="ownershipRegisteredKeeper"
                                                            label="Registered Keeper "
                                                            margin="none"
                                                            value={vehicle?.ownershipRegisteredKeeper || ''}
                                                            fullWidth
                                                            disabled={true}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                            
                                                        />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <DatePicker
                                                            type="date"
                                                            id="ownershipPurchaseDate"
                                                            name="ownershipPurchaseDate"
                                                            label={"Purchase Date"}
                                                            value={vehicle?.ownershipPurchaseDate !== '0000-00-00' ? vehicle?.ownershipPurchaseDate : null}
                                                            margin="none"
                                                            disabled={true}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container item spacing={3}>                        
                                                    <Grid item xs>
                                                        <Textarea
                                                            id="ownershipAddress"
                                                            name="ownershipAddress"
                                                            label="Address"
                                                            value={vehicle?.ownershipAddress}
                                                            disabled={true}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            </>
                                        :
                                            (vehicle?.ownershipType === 'Leased' ?
                                                <>
                                                <Grid item xs={12}>
                                                    <TextField  
                                                        name="leaseAgreementNumber"
                                                        label="Agreement Number "
                                                        margin="none"
                                                        value={vehicle?.leaseAgreementNumber || ''}
                                                        fullWidth
                                                        disabled={true}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container item spacing={3}>                        
                                                        <Grid item xs>
                                                            <AutoCompleteSelect 
                                                                options={suppList} 
                                                                label={"Lease Company"}
                                                                value={vehicle?.leaseCompany}
                                                                fullWidth
                                                                disabled={true}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <TextField  
                                                                label="Company Phone"
                                                                margin="none"
                                                                value={selectedSupplier?.contact || ''}
                                                                fullWidth
                                                                disabled={true}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container item spacing={3}>                        
                                                        <Grid item xs>
                                                            <DatePicker
                                                                type="date"
                                                                id="leaseStartDate"
                                                                name="leaseStartDate"
                                                                label={"Agreement Start Date"}
                                                                value={vehicle?.leaseStartDate !== '0000-00-00' ? vehicle?.leaseStartDate : null}
                                                                margin="none"
                                                                disabled={true}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <DatePicker
                                                                type="date"
                                                                id="leaseEndDate"
                                                                name="leaseEndDate"
                                                                label={"Agreement End Date"}
                                                                value={vehicle?.leaseEndDate !== '0000-00-00' ? vehicle?.leaseEndDate : null}
                                                                margin="none"
                                                                disabled={true}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" gutterBottom>
                                                        <b>Payment Profile</b>        
                                                    </Typography>
                                                    <Grid container item spacing={3} direction="row" alignItems="flex-end">                        
                                                        <Grid item xs>
                                                            <AutoCompleteSelect 
                                                                options={initialList} 
                                                                label="Initial"
                                                                value={vehicle?.leaseProfileInitial}
                                                                fullWidth
                                                                disabled={true}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <AllIcon icon="plus" size={15} />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <AutoCompleteSelect 
                                                                options={monthlyList} 
                                                                label="Monthly"
                                                                value={vehicle?.leaseProfileMonthly}
                                                                fullWidth
                                                                disabled={true}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" gutterBottom>
                                                        <b>Allowances</b>        
                                                    </Typography>
                                                    <Grid container item spacing={3}>                        
                                                        <Grid item xs={12} md={6}>
                                                            <TextField  
                                                                name="leaseAnnualMileageAllowance"
                                                                label="Annual Mileage Allowance"
                                                                margin="none"
                                                                value={vehicle?.leaseAnnualMileageAllowance || ''}
                                                                fullWidth
                                                                disabled={true}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField  
                                                                name="leaseContractMileageAllowance"
                                                                label="Contract Mileage Allowance"
                                                                margin="none"
                                                                value={vehicle?.leaseContractMileageAllowance || ''}
                                                                fullWidth
                                                                disabled={true}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container item spacing={3}>                        
                                                        <Grid item xs={12} md={6}>
                                                            <TextField  
                                                                name="leaseExcessMileageCharge"
                                                                label="Excess Mileage Charge (pence per mile)"
                                                                margin="none"
                                                                value={vehicle?.leaseExcessMileageCharge || ''}
                                                                fullWidth
                                                                InputProps={{
                                                                    endAdornment: (<InputAdornment position="end">p</InputAdornment>)
                                                                }}
                                                                disabled={true}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                </>
                                            : null)
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" paragraph style={{marginTop: 50}}>
                                        <b>Devices</b>
                                    </Typography>
                                    {vehicle?.devices?.length > 0 ?
                                        <List>
                                            {vehicle?.devices?.map((device, index) => (
                                                <ListItem key={index}>
                                                    <ListItemText>{device?.type} {(device?.modelNumber ? ' - ' + device?.modelNumber : '')} {device?.supplier?.name ? '('+ device?.supplier?.name +')' : ''}</ListItemText>
                                                </ListItem>
                                            ))}
                                    </List>
                                    : 
                                        <Typography variant="body2" paragraph><em>No devices found</em></Typography>
                                    }

                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" paragraph style={{marginTop: 50}}>
                                        <b>Mileage Tracker</b>
                                    </Typography>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                row
                                                disabled={true}
                                            >
                                                <FormControlLabel
                                                    value={1}
                                                    control={<Radio color="primary" />}
                                                    label="On"
                                                    labelPlacement="end"
                                                    checked={parseInt(vehicle?.mileageTracker) === 1 ? true : false}
                                                    disabled={true}
                                                />
                                                <FormControlLabel
                                                    value={0}
                                                    control={<Radio color="primary" />}
                                                    label="Off"
                                                    labelPlacement="end"
                                                    checked={parseInt(vehicle?.mileageTracker) === 0 ? true : false}
                                                    disabled={true}
                                                />
                                            </RadioGroup>
                                            <Typography variant="body2">
                                                {parseInt(vehicle?.mileageTracker) === 0 ?
                                                    'The system will not autogenerate mileage tracker entries - you will need to manually add them.'
                                                : 
                                                    'The system will autogenerate mileage tracker entries ready for the engineer to submit every Monday.'
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </PaddedPaper>
            </>
        )
    }
}

export default VehicleInformation;