import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { gridPageChanged, gridRowsPerPageChanged } from 'Functions/FormFunctions';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import {
    connect
} from 'react-redux';
import API from '../../../API';
import DataTable from '../../Common/DataTables/CiDataTable';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import { statusColour } from './../../../Helpers/ColourHelper';
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    access: {
        viewStockReturn: false
    },
    keywords: '',
    searchResults: {},
    isLoading: true,
    //gridPagination: {
    //    page: DEFAULT_PAGINATION_PAGE,
    //    rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    //}
});
class SupplierStockReturnSearch extends Component {
    constructor(props) {
        super(props);    
        this.clearPageState         = clearPageState.bind(this);
        this.getInitialState        = getInitialState.bind(this);
        this.hasPageState           = hasPageState.bind(this);
        this.savePageState          = savePageState.bind(this);
        this.persistenceId          = 'SupplierStockReturn:Search';
        this.state                  = this.getInitialState(initialState());
        this.gridPageChanged        = gridPageChanged.bind(this);
        this.gridRowsPerPageChanged = gridRowsPerPageChanged.bind(this);
    }

    componentDidMount = () => {
        this.getSearchData();
        this.getAccess();
    }

    getAccess = () => {
        API.get('/staff/my/access/check/view-stock-return')
        .then((res) =>  {
            this.setState({
                ...this.state,
                access: {
                    viewStockReturn: (res.data && res.data.has_access) || false
                }
            });
        })
    }

    getSearchData = () => {
        const params = {
            keywords: this.state.keywords
        }
        this.setState({
            isLoading: true
        }, () => {
            API.get('/supplierStockReturns', {
                params,
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    _.map(result.data, (ssr) => {
                        ssr.colour = statusColour(ssr.sr_status);
                    });
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    })
                }
            })
        })
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value,
            gridPagination: initialState().gridPagination
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
            //gridPagination: initialState.gridPagination
        }, 
        () => {
            //this.props.history.replace();
            this.getSearchData();
        });
    }

    downloadFile = rowData => {
        API.get('/supplierStockReturns/' + rowData.sr_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    render() {
        const { access, gridPagination, keywords } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Supplier Stock Return Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key:                        'sr_id',
                                pagination:                 true,
                                //page: gridPagination.page,
                                //rowsPerPage: gridPagination.rowsPerPage,
                                //gridPageChanged: this.gridPageChanged,
                                //gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                alternatingRowColours:      true,
                                persistenceId:              this.persistenceId,
                                responsiveimportant:        true,
                                isLoading:                  this.state.isLoading
                            }}
                            columns={[
                                {
                                    heading: 'Date',
                                    field: rowData => moment(rowData.sr_date).format("DD/MM/YYYY"),
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    actionsCustomHeader:    true,
                                    important:              true,
                                    alignment:              'left',
                                    heading:                'Reference',
                                    actions: rowData => { return [ { label: rowData.sr_reference + '-' + rowData.sr_reference_amendment, link: `/supplier-stock-return/view/${rowData.sr_id}`, type:'plainLink'}] }
                                },
                                {
                                    heading: 'Type',
                                    field: rowData => rowData.sr_return_type,
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Company Name',
                                    field: rowData => rowData.sr_supplier_name,
                                    important: true,
                                    truncate: true
                                },
                                {
                                    heading: 'Status',
                                    field: rowData => ['Completed', 'Declined', 'Cancelled'].includes(rowData.sr_status) ? rowData.sr_status : 'Awaiting '+rowData.sr_status,
                                    cellProps: rowData => {
                                        return ({
                                            style: {
                                                color: rowData.colour
                                            }
                                        });
                                    },
                                    important: true
                                },
                                {
                                    heading: 'Total Parts',
                                    field: i => _.uniqBy(i.stock_return_details, 'srd_part_id').length,
                                    alignment: 'right'
                                },
                                {
                                    heading: 'Total Qty',
                                    field: 'sr_total_parts',
                                    alignment: 'right',
                                    fieldFormat: 'decimal:3'
                                },
                                {
                                    heading: 'stock deducted',
                                    field: i => `${parseInt(_.uniqBy(_.filter(i.stock_return_details, j => parseInt(j.srd_deduct_stock)), j => parseInt(j.srd_part_id)).length)} / ${parseFloat(_.sumBy(_.filter(i.stock_return_details, j => parseInt(j.srd_deduct_stock)), j => parseFloat(j.srd_quantity))).toFixed(3)}`,
                                    alignment: 'right',
                                },
                                {
                                    heading: 'stock not deducted ',
                                    field: i => `${parseInt(_.uniqBy(_.filter(i.stock_return_details, j => !parseInt(j.srd_deduct_stock)), j => parseInt(j.srd_part_id)).length)} / ${parseFloat(_.sumBy(_.filter(i.stock_return_details, j => !parseInt(j.srd_deduct_stock)), j => parseFloat(j.srd_quantity))).toFixed(3)}`,
                                    alignment: 'right',
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Download', icon: 'download', onClick: this.downloadFile},
                                            {name: 'View', icon: 'search', link: { pathname: '/supplier-stock-return/view/' + rowData.sr_id, state: {keywords, gridPagination}}, disabled: !access.viewStockReturn}
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(SupplierStockReturnSearch);
