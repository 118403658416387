import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import API from 'API';

export default function BusinessType({onSubmit, logicData}) {

    const [type, setType] = useState(logicData?.type)

    const getEligibleContacts = () => {
        if (!type) return;
        API.get(`/marketing/campaigns/mailingLists/eligibleContacts`, {params: {
            breakdown: 1,
            logicData: JSON.stringify({
                ...logicData,
                type: type,
            }),
            logicType: 'BusinessType',
        }})
        .then(({data}) => {
            onSubmit(data.contacts)({
                ...logicData,
                type: type,
            });
        })
    }

    useEffect(getEligibleContacts, [type])

    return (
        <>
            <Grid item xs={12}>
                <AutoCompleteSelect
                    label="Business Type *"
                    value={type}
                    onChange={e => setType(e?.value)}
                    fullWidth
                    options={[
                        {
                            value: 'Dealer',
                            label: 'Dealer',
                        },
                        {
                            value: 'End User',
                            label: 'End User',
                        },
                        {
                            value: 'Group',
                            label: 'Group',
                        },
                    ]}
                    disableSort
                    noClear
                />
            </Grid>
        </>
    )
}