import React, { Component } from 'react';
import moment               from 'moment';
import API                  from 'API';
import { connect }          from 'react-redux';
import _                    from 'lodash'

import { formatValidationErrors } from 'Helpers/ErrorHelper';

import { Button, Grid, TextField, InputAdornment, FormControl } from '@material-ui/core';

import DatePicker                      from 'Components/Common/DatePickers/DatePicker';
import AutoCompleteSelect              from 'Components/Common/Selects/AutoCompleteSelect';
import CreditNoteInvoicePaymentsDialog from './CreditNoteInvoicePaymentsDialog'

import { closeDialog }        from 'Actions/Dialog/Dialog';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deployDialog }       from 'Actions/Dialog/Dialog';

const initialState = () => ({
    formData:   {
        refundAmount:       0.00,
        amountOutstanding:  0.00,
        invoice:            null,
        date:               null,
        method:             'Invoice'
    },
    invoices:       [],
    invoiceData:    null,
    formErrors:     [],
    isLoading:      true,
})

class InvoiceDialog extends Component {
    constructor(props) {
        super(props);    
        this.state = {
            ...initialState(), 
            formData:{
                ...initialState().formData,
                amountOutstanding:  (this.props.cn?.invoice?.cni_total_incl_vat - this.props.cn?.invoice?.sum_of_payments).toFixed(2),
            }
        };
    }

    componentDidMount() {this.getInvoices();}

    getInvoices = () => {
        const cId = this.props.cn.cn_cust_id;
        API.get('/sales/orders/awaitingInvoice', {params: {customer: cId, slim:1}}).then(res => {
            let invoices = [];
            _.map(res.data, i => {
                _.map(i.in, j => {
                    invoices.push({
                        label: j.n,
                        value: j.i,
                        data: j
                    })
                });
            })
            this.setState({invoices})
        });
    }
    
    handleDateChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                date: moment(e)
            }
        });
    }

    submit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => { newFormData.append(key, this.state.formData[key]); });
        API.post(`/customerReturns/creditNote/invoice/${this.props.cn.invoice.cni_id}/processPayment`, newFormData)
        .then(res => { 
            if (res.data.errors)    this.setState({formErrors: formatValidationErrors(res.data.errors)});
            if (res.data.success)   this.props.callback() 
        });
    }

    close = () => {
        this.props.closeDialog();
        this.props.deployDialog(<CreditNoteInvoicePaymentsDialog id={this.props.cn?.invoice?.cni_id} cn={this.props.cn} callback={this.props.callback} />, 'Assign Refund');
    }

    handleSelectChange = e => {
        API.get(`/sales/orders/invoice/${e.data.i}`,).then(res => {
            e.data.total = parseFloat( parseFloat(res.data.invoice_total_incl_vat ?? 0) - parseFloat(res.data.sum_of_payments ?? 0) );
            this.setState({
                formData: {
                    ...this.state.formData,
                    invoice:        e.value ?? null,
                    refundAmount:   parseFloat( e.data.total > this.state.formData.amountOutstanding ? this.state.formData.amountOutstanding : e.data.total ).toFixed(2)
                },
                invoiceData: e.data ?? null
            })
        });
    }

    handleToDecimalPlace = (decimal) => e => {
        let { value } = e.target;
        let newVal    = parseFloat(value).toFixed(decimal);
        if (this.state)
        if (parseFloat(value) > parseFloat(this.state.formData.amountOutstanding)) newVal = parseFloat(this.state.formData.amountOutstanding).toFixed(decimal);
        this.setState({
            formData: {
                ...this.state.formData,
                refundAmount: newVal
            }
        });
    }

    handleFormChange = e => {
        const {name, value} = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        });
    }

    render() {
        const {formData, formErrors, invoices, invoiceData} = this.state;
        const {refundAmount, amountOutstanding, invoice, date} = formData;

        return (
            <Grid container>
                <Grid item xs={12}>
                    <form>
                        <FormControl fullWidth>
                            <AutoCompleteSelect 
                                options={invoices}
                                name='invoice'
                                value={invoice}
                                label={'Select Invoice'}
                                error={(formErrors && formErrors['invoice'] && true) || false}
                                errorText={formErrors && formErrors['invoice']}
                                onChange={this.handleSelectChange}
                                fullWidth
                                disabled={formData.status === 'Completed'}
                            />
                        </FormControl>
                        
                        <TextField
                            label="Amount Outstanding"
                            value={amountOutstanding}
                            margin="normal"
                            fullWidth
                            disabled
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                className: 'disabledText'
                            }}
                        />
                        {invoiceData && 
                            <>
                                <TextField
                                    name="refundAmount"
                                    label="Refund Amount"
                                    value={refundAmount}
                                    onChange={this.handleFormChange}
                                    onBlur={this.handleToDecimalPlace(2)}
                                    margin="normal"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        inputProps:     { min: 0, max: amountOutstanding > invoiceData.total ? amountOutstanding : invoiceData.total } 
                                    }}
                                    type="number"
                                    fullWidth
                                    error={formErrors && formErrors['refundAmount'] && true}
                                    helperText={formErrors && formErrors['refundAmount']}
                                />
                                <TextField
                                    label="Invoice Total"
                                    value={parseFloat(invoiceData.total).toFixed(2)}
                                    margin="normal"
                                    fullWidth
                                    disabled
                                    type="number"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        className: 'disabledText'
                                    }}
                                />
                                <TextField
                                    label="Credit Note Remaining"
                                    value={parseFloat( amountOutstanding -  refundAmount ).toFixed(2)}
                                    margin="normal"
                                    fullWidth
                                    disabled
                                    type="number"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        className: 'disabledText'
                                    }}
                                />
                                <TextField
                                    label="Invoice Remaining"
                                    value={parseFloat(invoiceData.total - refundAmount).toFixed(2)}
                                    margin="normal"
                                    fullWidth
                                    disabled
                                    type="number"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        className: 'disabledText'
                                    }}
                                />
                            </>
                        }
                        <DatePicker
                            type="date"
                            id="date"
                            name="date"
                            label="Refund Date *"
                            value={date}
                            errorText={formErrors && formErrors['date']}
                            onChange={this.handleDateChange}
                            autoOk={true}
                        />
                    </form>
                </Grid>
                <Grid item xs={12}>
                    <div className="buttonRow">
                        <Button 
                            variant="outlined" 
                            color="default" 
                            onClick={this.close}
                        >
                            Close
                        </Button>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={()=>{this.props.deployConfirmation('Are you sure you want to process this refund?', 'Process refund?', this.submit)}}
                            disabled={parseFloat(invoiceData?.total - refundAmount) < 0 || amountOutstanding -  refundAmount < 0 || !invoice || !date}
                        >
                            Submit
                        </Button>
                    </div>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation:     (body, title, callback)     => dispatch(deployConfirmation(body, title, callback)),
    closeDialog:            ()                          => dispatch(closeDialog()),
    deployDialog:           (content, title)            => dispatch(deployDialog(content, title, 'success')),
})


export default connect(null, mapDispatchToProps)(InvoiceDialog);