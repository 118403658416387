import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import API from '../../../../API';
import _ from 'lodash';
import AutoCompleteSelect from '../../../Common/Selects/AutoCompleteSelect';
import DatePicker from '../../../Common/DatePickers/DatePicker';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import FASolidIcon from '../../../Common/Icons/FontAwesome/FASolidIcon';
import IconButton from '@material-ui/core/IconButton';
import DataTable from '../../../Common/DataTables/DataTable';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import FALightIcon from '../../../Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from '../../../Common/LoadingCircle/LoadingCircle';
import {colors} from 'Helpers/ColourHelper';

import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    formData: {
        part: '',
        dateFrom: null,
        dateTo: null,
    },
    partList: [],
    isLoading: true,
    reportResults: null
});

const styles = theme => ({
    dateRange: {
        textAlign: 'right',
    },
    inOut: {
        border: '1px solid #ddd',
        color: '#fff',
        marginTop: `${theme.spacing(1.5)}px 0`,
    },
    defaultInOut: {
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        color: '#000',
        cursor: 'default!important',
        margin: `${theme.spacing(1.5)}px 0`,
    },
    default: {
        cursor: 'default',
    },
    [theme.breakpoints.down('xs')]: {
        dateRange: {
            textAlign: 'left',
        },
    },
});

class PartStockReport extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Reports:PartStockReport';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        !this.hasPageState() && this.getParts();
    }

    getParts = () => {
        let partList;
        API.get('/parts/withStock')
        .then((partRes) =>  {
            if(partRes.data){
                partList = _.map(partRes.data, el => {
                    return _.assign({
                        value: el.part_id,
                        label: el.part_number + ' - ' + el.part_description
                    });
                });
            }
            this.setState({
                partList: partList,
                isLoading: false
            }, ()=>{this.savePageState()});
        });
    }

    showResults = () => {    
    
        API.get(`/reports/partStockReport`, {
            params: {
                part: this.state.formData.part,
                dateFrom: this.state.formData.dateFrom !== null ? moment(this.state.formData.dateFrom).format('YYYY-MM-DD') : null,
                dateTo: this.state.formData.dateTo !== null ? moment(this.state.formData.dateTo).format('YYYY-MM-DD') : null
            }
        })
        .then((result) => {
            this.setState({
                reportResults: result.data
            }, ()=>{this.savePageState()})
        })
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, () => {
            if (!this.state.formData.part){
                this.setState({ reportResults:null },()=>this.savePageState());
            }
            this.savePageState(); 
        });
    }

    handleDateChange = name => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: date
            }
        }, () => { this.savePageState() });
    };

    downloadFile = (url) => {
        window.open(url, '_blank');
    }
       
    render() {
        const { classes } = this.props;
        const { formData, isLoading, reportResults } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Part Stock Report
                    </Typography>
                </Grid>                
                {(isLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                )) || (
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <form noValidate autoComplete="off">
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                options={this.state.partList} 
                                                label='Part *'
                                                onChange={this.handleSelectChange('part')}
                                                value={this.state.formData.part}
                                            />
                                        </FormControl>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} lg={6}>
                                                <DatePicker
                                                    type='date'
                                                    id="dateFrom"
                                                    name="dateFrom"
                                                    label="Date From"
                                                    autoOk={true}
                                                    value={formData.dateFrom}
                                                    onChange={this.handleDateChange('dateFrom')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <DatePicker
                                                    type='date'
                                                    id="dateTo"
                                                    name="dateTo"
                                                    label="Date To"
                                                    autoOk={true}
                                                    value={formData.dateTo}
                                                    onChange={this.handleDateChange('dateTo')}                                            
                                                />
                                            </Grid>
                                        </Grid>
                                        <div className='buttonRow'>
                                            <Button 
                                                onClick={this.showResults}
                                                color="primary"
                                                variant="contained"
                                            
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    </form>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
                {reportResults &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="h6" gutterBottom>
                                                <FALightIcon icon='cubes' /><b>Current Stock Situation</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className={classes.dateRange}>
                                            <Typography variant="h6" gutterBottom>
                                                <FALightIcon icon='calendar-alt' /><b>As of today ({moment().format("DD-MM-YYYY")})</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>                                    
                                <Grid item xs={12} lg={6} align='center'>
                                    <PaddedPaper style={{backgroundColor: '#f5f5f5'}}>
                                        <FALightIcon icon='hand-holding-box' size='xlarge' />
                                        <Typography variant="h6" style={{textAlign:'center'}}>
                                            Current Quantity
                                        </Typography>
                                        <Typography variant="h6" style={{textAlign:'center'}}>
                                            <b>{parseFloat(reportResults.current_quantity).toFixed(3)}</b>
                                        </Typography>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12} lg={6} align='center'>                                    
                                    <PaddedPaper style={{backgroundColor: '#f5f5f5'}}>
                                        <FALightIcon icon='coins' size='xlarge' />
                                        <Typography variant="h6" style={{textAlign:'center'}}>
                                            Current Value
                                        </Typography>
                                        <Typography variant="h6" style={{textAlign:'center'}}>
                                            <b>£{parseFloat(reportResults.current_value).toFixed(2)}</b>
                                        </Typography>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={4} align='center'>
                                            <PaddedPaper style={{backgroundColor: '#f5f5f5'}}>
                                                <FALightIcon icon='level-down' size='xlarge' />
                                                <Typography variant="h6" style={{textAlign:'center'}}>
                                                    Minimum Stock Level
                                                </Typography>
                                                <Typography variant="h6" style={{textAlign:'center'}}>
                                                    <b>{parseFloat(reportResults.minimum_stock_level).toFixed(3)}</b>
                                                </Typography>
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={12} lg={4} align='center'>
                                            <PaddedPaper style={{backgroundColor: '#f5f5f5'}}>
                                                <FALightIcon icon='level-up' size='xlarge' />
                                                <Typography variant="h6" style={{textAlign:'center'}}>
                                                    Maximum Stock Level
                                                </Typography>
                                                <Typography variant="h6" style={{textAlign:'center'}}>
                                                    <b>{parseFloat(reportResults.maximum_stock_level).toFixed(3)}</b>
                                                </Typography>
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={12} lg={4} align='center'>
                                            <PaddedPaper style={{backgroundColor: '#f5f5f5'}}>
                                                <FALightIcon icon='thermometer-three-quarters' size='xlarge' />
                                                <Typography variant="h6" style={{textAlign:'center'}}>
                                                    Stock Status
                                                </Typography>
                                                <Typography variant="h6" style={{textAlign:'center'}}>
                                                    {reportResults.stock_status && (<b style={{color: reportResults.stock_status.text === 'Below' ? colors.red : (reportResults.stock_status.text === 'Over' ? 'orange' : colors.green)}}>{reportResults.stock_status.text }</b>)} 
                                                    <b> ({parseFloat(reportResults.stock_status && reportResults.stock_status.remainder).toFixed(3)})</b>
                                                </Typography>
                                            </PaddedPaper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Typography variant="h6">
                                        <FALightIcon icon='info-circle' /><b>Overview</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} lg={6} className={classes.dateRange}>
                                    <Typography variant="h6">
                                        <FALightIcon icon='calendar-alt' /><b>{reportResults.overview_title}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Table style={{border: '1px solid #ddd'}}>
                                        <TableBody>
                                            <TableRow style={{backgroundColor: '#f5f5f5'}}>
                                                <TableCell colSpan={2}>Opening Stock</TableCell>
                                                <TableCell style={{textAlign:'right'}}>{reportResults.opening_stock}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <FALightIcon icon='arrow-circle-right' /> IN
                                                </TableCell>
                                                <TableCell style={{textAlign:'right'}}>
                                                    <Tooltip title="Stock IN values = cost per unit on the date of the action multiplied by the quantity">
                                                        <IconButton>
                                                            <FASolidIcon icon='info-circle' noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                            {reportResults.overview && reportResults.overview.IN &&
                                            reportResults.overview.IN.map((item, idx) => {
                                                return(
                                                <TableRow>
                                                    <TableCell style={{textAlign:'right'}}>{item.activity_type}</TableCell>
                                                    <TableCell style={{textAlign:'right', borderRight: '1px solid rgba(224, 224, 224, 1)'}}>{item.quantity} {' '} (£{parseFloat(item.value).toFixed(2)})</TableCell>
                                                    {idx === 0 &&
                                                        <TableCell rowSpan={reportResults.overview.IN.length} style={{textAlign:'right'}}>
                                                            <FASolidIcon icon='plus' noMargin style={{color:colors.green}} /> {reportResults.overview.IN_QTY}{' '}({reportResults.overview.IN_VALUE})
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                                )
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <FALightIcon icon='arrow-circle-left' /> OUT
                                                </TableCell>
                                                <TableCell style={{textAlign:'right'}}>
                                                    <Tooltip title="Stock OUT values = cost per unit on the date of the action multiplied by the quantity">
                                                        <IconButton>
                                                            <FASolidIcon icon='info-circle' noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                            {reportResults.overview && reportResults.overview.OUT  && 
                                            reportResults.overview.OUT.map((item, idx) => {
                                                return(
                                                <TableRow>
                                                    <TableCell style={{textAlign:'right'}}>{item.activity_type}</TableCell>
                                                    <TableCell style={{textAlign:'right', borderRight: '1px solid rgba(224, 224, 224, 1)'}}>{item.quantity} {' '} (£{parseFloat(item.value).toFixed(2)})</TableCell>
                                                    {idx === 0 &&
                                                        <TableCell rowSpan={reportResults.overview.IN.length} style={{textAlign:'right'}}>
                                                            <FASolidIcon icon='minus' noMargin style={{color:colors.red}} /> {reportResults.overview.OUT_QTY}{' '}({reportResults.overview.OUT_VALUE})
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                                )
                                            })}
                                            <TableRow style={{backgroundColor: '#f5f5f5'}}>
                                                <TableCell colSpan={2}>Closing Stock as at {reportResults.date_to === null ? moment().format("DD-MM-YYYY") : moment(reportResults.date_to).format("DD-MM-YYYY")}</TableCell>
                                                <TableCell style={{textAlign:'right'}}>{reportResults.closing_stock}</TableCell>
                                            </TableRow>
                                            <TableRow style={{backgroundColor: '#f5f5f5'}}>
                                                <TableCell colSpan={2}>
                                                    Closing Stock Value as at {reportResults.date_to === null ? moment().format("DD-MM-YYYY") : moment(reportResults.date_to).format("DD-MM-YYYY")}
                                                    <Tooltip title={`The cost per unit on ${reportResults.date_to === null ? moment().format("DD-MM-YYYY") : moment(reportResults.date_to).format("DD-MM-YYYY")} multiplied by the stock quantity on this date`}>
                                                        <IconButton>
                                                            <FASolidIcon icon='info-circle' noMargin />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell style={{textAlign:'right'}}>£{parseFloat(reportResults.closing_stock_value).toFixed(2)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        <FALightIcon icon='arrow-circle-right' /><b>IN</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {(reportResults.safs && reportResults.safs.length > 0 && (
                                    <ExpansionPanel>
                                        <ExpansionPanelSummary className={classes.inOut} expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body2">
                                                <FALightIcon icon='exchange' buttonPrimary/> Stock Addition
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <DataTable  
                                                config={{
                                                    key: 'saf_id',
                                                    plainHeader: true,
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Type',
                                                        field: rowData => rowData.saf_type
                                                    },
                                                    {
                                                        heading: 'Qty',
                                                        field: rowData => rowData.saf_addition_qty
                                                    },
                                                    {
                                                        heading: 'Stock Value',
                                                        field: rowData => '£'+ parseFloat(rowData.saf_addition_value).toFixed(2)
                                                    },
                                                    {
                                                        heading: 'Addition Date',
                                                        field: rowData => moment(rowData.saf_addition_date).format('DD-MM-YYYY')
                                                    },
                                                    {
                                                        actions: rowData => {
                                                            return [
                                                                {name: 'Download', icon: 'download', onClick: () => {this.downloadFile(rowData.saf_file_url)}},
                                                            ]
                                                        }
                                                    }
                                                ]}
                                                rows={reportResults.safs}
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    )) || (
                                        <ExpansionPanelSummary className={classes.defaultInOut}>     
                                            <Typography variant="body2">
                                                <FALightIcon icon='info-circle' /> There are no related stock additions
                                            </Typography>
                                        </ExpansionPanelSummary>
                                    )}
                                    {(reportResults.delivered && reportResults.delivered.length > 0 && (
                                    <ExpansionPanel>
                                        <ExpansionPanelSummary className={classes.inOut} expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body2">
                                                <FALightIcon icon='chart-line' buttonPrimary/> Stock Delivered - Parts Order
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <DataTable  
                                                config={{
                                                    key: 'ppo_id',
                                                    plainHeader: true,
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Parts Order Number',
                                                        field: rowData => rowData.ppo_number + '-' + rowData.ppo_number_amendment
                                                    },
                                                    {
                                                        heading: 'Qty',
                                                        field: rowData => rowData.delivery_received_quantity
                                                    },
                                                    {
                                                        heading: 'Stock Value',
                                                        field: rowData => '£'+ parseFloat(rowData.stock_value).toFixed(2)
                                                    },
                                                    {
                                                        heading: 'PO Value',
                                                        field: rowData => '£'+ parseFloat(rowData.ppo_value).toFixed(2)
                                                    },
                                                    {
                                                        heading: 'Delivery Date',
                                                        field: rowData => moment(rowData.delivery_received_date).format('DD-MM-YYYY')
                                                    },
                                                    {
                                                        actions: rowData => {
                                                            return [
                                                                {name: 'View', icon: 'search', link: '/parts-order/view/' + rowData.ppo_id}
                                                            ]
                                                        }
                                                    }
                                                ]}
                                                rows={reportResults.delivered}
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    )) || (
                                        <ExpansionPanelSummary className={classes.defaultInOut}>     
                                            <Typography variant="body2">
                                                <FALightIcon icon='info-circle' /> There are no related part deliveries
                                            </Typography>
                                        </ExpansionPanelSummary>
                                    )}
                                    {(reportResults.ssr_delivered && reportResults.ssr_delivered.length > 0 && (
                                    <ExpansionPanel>
                                        <ExpansionPanelSummary className={classes.inOut} expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body2">
                                                <FALightIcon icon='exchange' buttonPrimary/> Stock Delivered - Supplier Stock Return
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <DataTable  
                                                config={{
                                                    key: 'sr_id',
                                                    plainHeader: true,
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Reference',
                                                        field: rowData => rowData.sr_reference + '-' + rowData.sr_reference_amendment
                                                    },
                                                    {
                                                        heading: 'Qty',
                                                        field: rowData => rowData.sr_delivery_quantity
                                                    },
                                                    {
                                                        heading: 'Stock Value',
                                                        field: rowData => '£'+ parseFloat(rowData.stock_value).toFixed(2)
                                                    },
                                                    {
                                                        heading: 'SSR Value',
                                                        field: rowData => '£'+ parseFloat(rowData.ssr_value).toFixed(2)
                                                    },
                                                    {
                                                        heading: 'Delivery Date',
                                                        field: rowData => moment(rowData.sr_delivery_date).format('DD-MM-YYYY')
                                                    },
                                                    {
                                                        actions: rowData => {
                                                            return [
                                                                {name: 'View', icon: 'search', link: '/supplier-stock-return/view/' + rowData.sr_id}
                                                            ]
                                                        }
                                                    }
                                                ]}
                                                rows={reportResults.ssr_delivered}
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    )) || (                                        
                                        <ExpansionPanelSummary className={classes.defaultInOut}>     
                                            <Typography variant="body2">
                                                <FALightIcon icon='info-circle' /> There are no related supplier stock returns
                                            </Typography>
                                        </ExpansionPanelSummary>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        <FALightIcon icon='arrow-circle-left' /><b>OUT</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {(reportResults.swofs && reportResults.swofs.length > 0 && (
                                    <ExpansionPanel>
                                        <ExpansionPanelSummary className={classes.inOut} expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body2">
                                                <FALightIcon icon='exchange' buttonPrimary/> Stock Write Off
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <DataTable  
                                                config={{
                                                    key: 'swof_id',
                                                    plainHeader: true,
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Type',
                                                        field: rowData => rowData.swof_type
                                                    },
                                                    {
                                                        heading: 'Qty',
                                                        field: rowData => rowData.swof_write_off_qty
                                                    },
                                                    {
                                                        heading: 'Stock Value',
                                                        field: rowData => '£'+ parseFloat(rowData.swof_write_off_value).toFixed(2)
                                                    },
                                                    {
                                                        heading: 'Write Off Date',
                                                        field: rowData => moment(rowData.swof_write_off_date).format('DD-MM-YYYY')
                                                    },
                                                    {
                                                        actions: rowData => {
                                                            return [
                                                                {name: 'Download', icon: 'download', onClick: () => {this.downloadFile(rowData.swof_file_url)}},
                                                            ]
                                                        }
                                                    }
                                                ]}
                                                rows={reportResults.swofs}
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    )) || (
                                            <ExpansionPanelSummary className={classes.defaultInOut}>     
                                                <Typography variant="body2">
                                                    <FALightIcon icon='info-circle' />There are no related stock write offs
                                                </Typography>
                                            </ExpansionPanelSummary>
                                    )}
                                    {(reportResults.srs && reportResults.srs.length > 0 && (
                                    <ExpansionPanel>
                                        <ExpansionPanelSummary className={classes.inOut} expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body2">
                                                <FALightIcon icon='exchange' buttonPrimary/> Supplier Stock Return
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <DataTable  
                                                config={{
                                                    key: 'sr_id',
                                                    plainHeader: true,
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Reference',
                                                        field: rowData => rowData.sr_reference + '-' + rowData.sr_reference_amendment
                                                    },
                                                    {
                                                        heading: 'Qty',
                                                        field: rowData => rowData.total_parts
                                                    },
                                                    {
                                                        heading: 'Return Type',
                                                        field: rowData => rowData.sr_return_type
                                                    },
                                                    {
                                                        heading: 'Company',
                                                        field: rowData => rowData.sr_supplier_name
                                                    },
                                                    {
                                                        heading: 'Date',
                                                        field: rowData => moment(rowData.sr_date).format('DD-MM-YYYY')
                                                    },
                                                    {
                                                        actions: rowData => {
                                                            return [
                                                                {name: 'View', icon: 'search', link: '/supplier-stock-return/view/' + rowData.sr_id}
                                                            ]
                                                        }
                                                    }
                                                ]}
                                                rows={reportResults.srs}
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    )) || (                     
                                        <ExpansionPanelSummary className={classes.defaultInOut}>          
                                            <Typography variant="body2">
                                                <FALightIcon icon='info-circle' /> There are no related supplier stock returns
                                            </Typography>
                                        </ExpansionPanelSummary>
                                    )}
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)        => dispatch(clearPersistence(key)),
    setPersistence:     (key, state) => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PartStockReport));
