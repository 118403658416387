import React, { Component } from 'react';
import _                    from 'lodash';
import API                  from 'API';

import icons       from 'Helpers/IconHelper';

import DataTable from 'Components/Common/DataTables/CiDataTable';

import { Typography, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const initialState = {
    worksOrders: [],
    open: false
}

class WorksOrders extends Component {
    constructor(props){
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        // this.getWorksOrders();
    }

    getWorksOrders = () => {
        API.get('/worksOrders/forPart',{params: {part: this.props.partId}})
        .then(res => {
            this.setState({worksOrders: res.data, open: true});
        })
    }

    handleOpen = () => {
        if(!this.state.open){
            this.getWorksOrders();
        } else {
            this.setState({
                open: false
            });
        }
    }

    render() {
        const { open } = this.state;    
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={this.handleOpen}>
                    <Typography>Works Orders</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {open &&
                        <DataTable
                            config={{
                                key:       'wo_id',
                                filterMod: {
                                    clearable: true,
                                },
                                pagination: true,

                            }}
                            columns={[
                                {
                                    heading:       'Date',
                                    field:         'd',
                                    sizeToContent: true,
                                    dataRef:       'd',
                                    fieldFormat:   'clenydate',
                                    filter:        'dateRange',
                                    filterMod:     {
                                        displayOrder: 2
                                    }
                                },
                                {
                                    heading:   'Reference',
                                    sizeToContent: true,
                                    field:     'r',
                                    dataRef:   'r',
                                    filter:    'custom',
                                    filterMod: {    
                                        dataRef:   (search, rowData) => `${rowData.r}|${rowData.p}`.toLowerCase().includes(search.toLowerCase()) > 0,
                                        label:     'Search Works Orders',
                                        styleGrid: {width: '20%'}
                                    },
                                },
                                {
                                    heading:   'Assembly',
                                    field:     'p',
                                    dataRef:   'p',
                                },
                                {
                                    heading:     'Required',
                                    field:       'q',
                                    dataRef:     'q',
                                    fieldFormat: 'decimal:3',
                                    sizeToContent: true,
                                },
                                {
                                    heading:       'Stage',
                                    field:         's',
                                    sizeToContent: true,
                                    dataRef:       's',
                                },
                                {
                                    heading:       'Build',
                                    field:         'b',
                                    sizeToContent: true,
                                    dataRef:       'b',
                                    fieldFormat:   'boolean',
                                    alignment:     'center',
                                },
                                {
                                    heading:       'Component',
                                    field:         'c',
                                    sizeToContent: true,
                                    dataRef:       'c',
                                    fieldFormat:   'boolean',
                                    alignment:     'center',
                                },
                                {
                                    actions: rowData => [
                                        {
                                            name: 'View',
                                            icon: icons.search,
                                            link: `/WorksOrders/view/${rowData.i}`,
                                        }
                                    ]
                                }
                            ]}
                            rows={_.orderBy(this.state.worksOrders, ['d'], ['desc'])}
                        />
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default WorksOrders;
