import React, { useState, useEffect } from 'react';
import { 
    Grid,
    Box, Typography,
    DialogActions, Button
} from '@material-ui/core';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import API from 'API';
import { assign, map, find } from 'lodash';
import LoadingCircle   from 'Components/Common/LoadingCircle/LoadingCircle';


const MasterElementDialog = ({ toggleDialog, handleUseMasterElement }) => { // Type (Info Icon / General Information)

    const   [isLoading,             setIsLoading]       = useState(false),
            [masterElements,        setMasterElements]  = useState([]),
            [filteredMasterElements, setFilteredMasterElements]  = useState([]),
            [areasList,             setAreasList]       = useState([]),
            [masterEl,              setMasterEl]        = useState(null),
            [areaId,                setAreaId]          = useState(null);

	useEffect(() => {

        Promise.all([
            API.get('/forms/configuration/masterElements'),
            API.get(`/forms/configuration/categories`),
        ])
        .then(([result, catRes]) => {

            let me = map(result.data, (res) => {
                return assign({
                    value: res?.el.id,
                    label: res?.el.nm,
                    element: res?.el
                });
            });
            
            // Create Areas List for Select
            let areas = map(catRes.data, (res) => {
                return assign({
                    label: res.nm,
                    value: res.id
                });
            })

            setMasterElements(me);
            setFilteredMasterElements(me);
            setAreasList(areas);
        })

	}, []);

    useEffect(() => {
        if(areaId){
            // Filter the Master Elements based on the selected Area
            let filtered = masterElements.filter((el) => {
                return el?.element?.areaId === areaId;
            });
            setFilteredMasterElements(filtered);
        }
    }, [areaId]);

    return (
        <>
        <Grid container item spacing={3}>
            {(isLoading && (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            )) || (
                <>
                <Grid item xs={12}>
                    <AutoCompleteSelect 
                        options={areasList} 
                        label='Area *'
                        fullWidth
                        value={areaId}
                        noClear
                        onChange={(e) => {setAreaId(e.value)}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AutoCompleteSelect
                        options={filteredMasterElements}
                        value={masterEl}
                        label='Master Element *'
                        onChange={(e) => setMasterEl(e)}
                        noClear
                        margin="none"
                        noDefaultSort
                        fullWidth
                        disabled={!areaId}
                        formatOptionLabel={({label, element}) => (
                            <Box>
                                <Typography variant="body2" >
                                    {label}
                                </Typography>
                                <Typography variant="caption" color='textSecondary'>
                                    {element?.elType}
                                </Typography>
                            </Box>
                        )}
                    />
                </Grid>
                </>
            )}
        </Grid>
        <DialogActions className='pr-0 pb-0'>
            <Button 
                onClick={() => toggleDialog()}
                variant="text"
            >
                <FALightIcon icon="times" size={15} button />
                Close
            </Button>
            <Button 
                color="primary"
                onClick={() => {
                    handleUseMasterElement(masterEl?.element)
                }}
                variant="contained"
                disabled={isLoading}
            >
                <FALightIcon icon="check" size={15} color="white" />
                Use
            </Button>
        </DialogActions>
        </> 
    );
};

export default MasterElementDialog;
