import React       from 'react';
import API         from 'API';
import moment      from 'moment';
import { connect } from 'react-redux';

import { Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, TextField, Typography } from '@material-ui/core';

import DatePicker            from 'Components/Common/DatePickers/DatePicker';
import ConfirmationDialog    from 'Components/Common/Dialogs/ConfirmationDialog';
import FALightIcon           from 'Components/Common/Icons/FontAwesome/FALightIcon';
import Textarea              from 'Components/Common/Inputs/Textarea';
import AutoCompleteSelect    from 'Components/Common/Selects/AutoCompleteSelect';
import CustomerAddressSearch from 'Components/Sales/CustomerAddressSearch';
import SearchSiteAddress from './SearchSiteAddress';

import { closeDialog }    from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import { formatValidationErrors } from 'Helpers/ErrorHelper';

import { handleChange, handleConfirmationClose, handleConfirmationOpen, handleConfirmationSuccess, handleSelectChange } from 'Functions/FormFunctions';

const initialState = {
    confirmation: {
        submit: false,
    },
    formData: {
        reminderTitle:          '',
        reminderText:           '',
        reminderDue:            null,
        reminderRepeats:        0,
        reminderOccurance:      1,
        reminderRepeat:         'day',
        attachCustomer:         0,
        attachCustomerAddId:    null,
        attachCustomerId:       null,
        attachAddress:          0,
        attachAddressId:        null,
        attachAddressAddId:     null,
        renderOptions:          [
            {label: 1, value: 1},
            {label: 2, value: 2},
            {label: 3, value: 3},
            {label: 4, value: 4},
            {label: 5, value: 5},
            {label: 6, value: 6},
            {label: 7, value: 7},
        ], 
    },
    formErrors:     [],
    customerList:   [],
    addresses:      [],
    customers:      [],
}

const OccuranceNumbers = {
    hour:   24,
    day:    7,
    week:   4, 
    month:  12,
    year:   5
}

class AddReminder extends React.Component {
    constructor(props) {
        super(props)
        this.state                      = initialState
        this.handleChange               = handleChange.bind(this)
        this.handleSelectChange         = handleSelectChange.bind(this)
        this.handleConfirmationClose    = handleConfirmationClose.bind(this)
        this.handleConfirmationOpen     = handleConfirmationOpen.bind(this)
        this.handleConfirmationSuccess  = handleConfirmationSuccess.bind(this)
    }

    handleCheckedChange = e => {
        let name = e.target.name, val = e.target.checked;
        
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: val ? 1 : 0,
            }
        }, () => {
            if (name === 'attachCustomer') 
                this.removeAttachedCustomer();
        });
    }

    handleDateChange = name => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: moment(date).format('YYYY-MM-DD HH:mm'),
            }
        })
    }



    handleRepeatReminderChange(type, value){
        var data = this.state.formData;
        var reminderRepeat      = type === 'reminderRepeat'     ? value : data.reminderRepeat;
        var reminderOccurance   = type === 'reminderOccurance'  ? value : data.reminderOccurance;
        var renderOptions       = data.renderOptions;

        if (type === 'reminderRepeat'){
            renderOptions = [];
            for (let i = 0; i < OccuranceNumbers[value]; i++) {
                renderOptions.push({label: (i+1), value: (i+1)})
            }
            reminderOccurance = 1;
        }

        this.setState(
            {
                formData: {
                    ...data,
                    reminderRepeat,
                    reminderOccurance,
                    renderOptions,
                }
            }
        );
    }

    submit = () => {
        //we only attach to customer but i allow for changes 
        var attachType = null;
        if (this.state.formData.attachAddress) {
            attachType = 'address'
        } else if (this.state.formData.attachCustomer) {
            attachType = 'customer';
        }
        var attach     = attachType ? 1 : 0;
        var attachId   = 0;
        switch( attachType ) {
            case 'customer' : attachId = this.state.formData.attachCustomerId;    break;
            case 'address' :  attachId = this.state.formData.attachAddressId;    break;
            default         : break;
        }
        API.post('/reminders', {...this.state.formData, attachId, attachType, attach})
        .then(result => {
            if(result && result.data) {
                if(result.data.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors)
                    })
                } else {
                    this.setState({
                        ...initialState,
                    }, () => {
                        this.props.deploySnackBar("success", "You have successfully created a new reminder")
                        if(this.props.callback) {
                            this.props.callback()
                        }
                    })
                }
            }
        })
    }

    selectCustomer = (e) => {
        if (e) {
            this.setState({
                formData:{
                    ...this.state.formData,
                    attachCustomerId:       e.cust_id,
                    attachCustomerName:     e.cust_name,
                }
            });
        } else {
            this.removeAttachedCustomer();
        }
    }

    removeAttachedCustomer = () => {
        this.setState({
            formData:{
                ...this.state.formData,
                attachCustomerId:       null,
                attachCustomerName:     null,
            }
        });
    }

    selectAddress = (e) => {
        if (e) {
            this.setState({
                formData:{
                    ...this.state.formData,
                    attachAddressId:       e.address_id,
                    attachAddressName:     e.address_name,
                }
            });
        } else {
            this.removeAttachedAddress();
        }
    }

    removeAttachedAddress = () => {
        this.setState({
            formData:{
                ...this.state.formData,
                attachAddressId:       null,
                attachAddressName:     null,
            }
        });
    }


    render() {
        const { confirmation, formData, formErrors } = this.state;
        const { dialog } = this.props;
        return (
            <React.Fragment>
                <Typography variant="h6">
                    Reminder Details
                </Typography>
                <form noValidate autoComplete="off">
                    <TextField
                        name="reminderTitle"
                        label="Reminder *"
                        margin="normal" 
                        autoComplete="off"
                        error={formErrors && formErrors['reminderTitle'] && true}
                        helperText={formErrors && formErrors['reminderTitle']}
                        value={formData.reminderTitle}
                        onChange={this.handleChange}
                        fullWidth
                    />
                    <FormControl fullWidth>
                        <DatePicker
                            id="reminderDue"
                            name="reminderDue"
                            type="datetime"
                            label="Date/Time"
                            value={formData.reminderDue}
                            errorText={formErrors && formErrors['reminderDue']}
                            onChange={this.handleDateChange('reminderDue')}
                            autoOk={true}
                            disablePast={true}
                        />
                    </FormControl>
                    <Textarea
                        name="reminderText"
                        label='Notes'
                        value={formData.reminderText}
                        onChange={this.handleChange}
                    />
                    <Grid container >
                        <Grid item >
                            <FormControl fullWidth margin="normal">
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.reminderRepeats === 1 ? true : false} 
                                            onChange={this.handleCheckedChange} 
                                            value={formData.reminderRepeats}
                                            name="reminderRepeats"
                                            color="primary"
                                        />
                                    }
                                    label="Repeat Reminder"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item >
                            <FormControl fullWidth margin="normal">
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.attachCustomer === 1 ? true : false} 
                                            onChange={this.handleCheckedChange} 
                                            value={formData.attachCustomer}
                                            name="attachCustomer"
                                            color="primary"
                                            disabled={formData.attachAddress}
                                        />
                                    }
                                    disabled={formData.attachAddress}
                                    label="Attach Customer"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item >
                            <FormControl fullWidth margin="normal">
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={formData.attachAddress === 1 ? true : false} 
                                            onChange={this.handleCheckedChange} 
                                            value={formData.attachAddress}
                                            name="attachAddress"
                                            color="primary"
                                            disabled={formData.attachCustomer}
                                        />
                                    }
                                    disabled={formData.attachCustomer}
                                    label="Attach Address"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    
                    
                    {!!formData.reminderRepeats && (
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item container spacing={2} alignItems='center'>
                                <Grid item>
                                    <Typography variant="body1">
                                        Every
                                    </Typography>
                                </Grid>
                                    {formData.reminderRepeat !== 'weekday' && 
                                        <Grid item xs={2}>
                                            <FormControl margin="none" fullWidth>
                                                <AutoCompleteSelect
                                                    options={formData.renderOptions}
                                                    value={formData.reminderOccurance}
                                                    onChange={v => this.handleRepeatReminderChange('reminderOccurance', v.value)}
                                                    error={formErrors && formErrors['reminderOccurance'] && true}
                                                    disableSort
                                                    noClear
                                                />
                                            </FormControl>
                                        </Grid>
                                    }
                                <Grid item xs={2}>
                                    <FormControl margin="none" fullWidth>
                                        <AutoCompleteSelect
                                            options={[
                                                {label: `Day${formData.reminderOccurance > 1 ? 's' : ''}`,      value: `day`},
                                                {label: `Week Days`,                                            value: `weekday`},
                                                {label: `Week${formData.reminderOccurance > 1 ? 's' : ''}`,     value: `week`},
                                                {label: `Month${formData.reminderOccurance > 1 ? 's' : ''}`,    value: `month`},
                                                {label: `Year${formData.reminderOccurance > 1 ? 's' : ''}`,     value: `year`},
                                            ]} 
                                            disabled={!formData.reminderRepeat}
                                            value={formData.reminderRepeat}
                                            onChange={v => this.handleRepeatReminderChange('reminderRepeat', v.value)}
                                            error={formErrors && formErrors['reminderRepeat'] && true}
                                            disableSort
                                            noClear
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs />
                            </Grid>
                        </Grid>
                    )}
                    {!!formData.attachCustomer && 
                        <>
                            {(formData.attachCustomerName) ?
                                <>
                                    <IconButton onClick={this.removeAttachedCustomer} > <FALightIcon icon={'times'} noMargin button /> </IconButton >Attached to <b> {formData.attachCustomerName} </b>
                                </>
                                :<></>}
                            <CustomerAddressSearch 
                                callback={this.selectCustomer} 
                                label = 'Search Customer'
                            />  
                        </>
                    }
                     {!!formData.attachAddress && 
                        <>
                            {(formData.attachAddressName) ?
                                <>
                                    <IconButton onClick={this.removeAttachedAddress} > <FALightIcon icon={'times'} noMargin button /> </IconButton >Attached to <b> {formData.attachAddressName} </b>
                                </>
                                :<></>}
                            <SearchSiteAddress 
                                callback={this.selectAddress} 
                            />  
                        </>
                    }
                    <div className='buttonRow'>
                        {dialog && 
                            <Button
                                variant="outlined"
                                color="default"
                                onClick={() => this.props.closeDialog()}
                            >
                                Close
                            </Button>
                        }
                        <Button onClick={() => this.handleConfirmationOpen('submit')}
                            variant="contained"
                            color="primary"
                            disabled={formData.reminderTitle.length === 0 && true}
                        >
                            Add
                        </Button>
                    </div>
                    <ConfirmationDialog 
                        open={confirmation.submit} 
                        success={() => this.handleConfirmationSuccess('submit')} 
                        close={() => this.handleConfirmationClose('submit')} 
                        title={`Add Reminder?`} 
                        message={`Are you sure you want to add this reminder?`}
                    />
                </form>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog())
    };
}

export default connect(null, mapDispatchToProps)(AddReminder);