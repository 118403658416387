import React, { Component } from 'react';
import API 					from 'API';
import _ 					from 'lodash';
import { connect } 			from 'react-redux';

import { Button, Grid, List, ListItem, ListItemText, Typography, ListItemSecondaryAction } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AllIcon 		 from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper 	 from 'Components/Common/Paper/PaddedPaper';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import { addKeyDown, removeKeyDown, setKeyDown } from 'Actions/KeyDown/KeyDown';

const initialState = {
    isLoading: true,
    access: {},
    page: 0,
	perPage: 4,
	worksOrders: [],
    searchResults: [],
}

class WorksOrderBuilds extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount(){
        this.props.addKeyDown(this._handleKeyDown);
        this.getAccess();
        this.getSearchData();
    }

	componentWillUnmount = () => {
		this.props.removeKeyDown()
	}

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/works-order:view'), 
            API.get('/staff/my/access/check/works-order:pick')
        ])
        .then(([viewRes, pickRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    view: viewRes.data.has_access || false,
                    pick: pickRes.data.has_access || false,
                }
            });
        })
    }

    getSearchData = () => {
        this.setState({isLoading: true}, ()=> {
            API.get('/worksOrders/all')
            .then(res => {
                let searchResults = _.reverse(_.filter(res.data, rowData => ( rowData.wo_status === 'Build' && !( rowData.shortage && !rowData.shortage?.woss_complete) )));
                this.setState({
                    ...this.state,
                    isLoading: false,
                    searchResults: searchResults,
                    worksOrders: _.take(searchResults, this.state.perPage),
                });
            })
        })
    }

    _handleKeyDown = (e) => {
		if (e.keyCode === 0 || e.keyCode === 192) return;
		e.preventDefault();
		switch(e.key) {
			case 'F1':
				this.state.page > 0 && this.prevPage();
				break;
			case 'F2':
				((this.state.page + 1) * this.state.perPage) < this.state.searchResults.length && this.nextPage();
				break;
			case 'F3':
				this.getSearchData();
				break;
			case '1':
			case '2':
			case '3':
            case '4':
				let order = this.state.worksOrders[parseInt(e.key) - 1];
				if (order) this.startPick(order);
			break
			default: break;
		}
	}
    
	nextPage = () => {
		const { page, perPage, searchResults } = this.state;
		const newPage = page + 1;
		const newOrders = _.take(_.slice(searchResults, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			worksOrders: newOrders
		});
	}

	prevPage = () => {
		const { page, perPage, searchResults } = this.state;
		const newPage = page - 1;
		const newOrders = _.take(_.slice(searchResults, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			worksOrders: newOrders
		});
	}

    startPick = (order) => {
        this.props.history.push(`/WorksOrders/view/${order.wo_id}`);
    }

    render(){

        const { isLoading, worksOrders, searchResults, perPage, page } = this.state;
        const { classes } = this.props;

        if (isLoading) return (<LoadingCircle/>);

        return (
            <Grid container spacing={1} style={{height: '100%'}}> 
                <Grid item xs={12}>
					<Typography variant='h4' style={{textAlign: 'center'}}>Works Order Builds</Typography>
				</Grid>
                <Grid item xs={12}>
					{worksOrders.length > 0 ?
						<List>
							{_.map(worksOrders, i => 
								<ListItem key={i.wo_id} className={classes.ListItem}
									onClick={() => this.startPick(i)}
								>
									<ListItemText 
										primary={i.wo_ref} 
										secondary={<>
											{i.part.part_number} - {i.part.part_description}<br/>
											Requested: {i.wo_qty} 
										</>}
									/>
									<ListItemSecondaryAction style={{textAlign: 'center'}}>
										<AllIcon
											noMargin
											icon={icons.worksOrder}
										/>
									</ListItemSecondaryAction>
								</ListItem>
							)}
						</List> : 
						<PaddedPaper>
							<Typography variant='h6' className='mb-1' style={{textAlign: 'center'}}>No Works Orders</Typography>
							<Button
								variant='contained'
								color='primary'
								onClick={this.getSearchData}
								fullWidth
							>Refresh [F3]</Button>
						</PaddedPaper>
					}
				</Grid>
                { searchResults.length > perPage &&
					<Grid item xs={12} style={{marginTop: 'auto'}}>
						<Grid container spacing={1} style={{justifyContent: 'space-between'}}> 
							{page > 0 &&
								<Grid item style={{marginRight: 'auto'}}>
									<Button variant='contained' color='primary'
										onClick={this.prevPage}
									>[F1] Prev</Button>
								</Grid>
							}
							{((page + 1) * perPage) < searchResults.length &&
								<Grid item style={{marginLeft: 'auto'}}>
									<Button variant='contained' color='primary'
										onClick={this.nextPage}
									>Next [F2]</Button>
								</Grid>
							}
						</Grid>
					</Grid>
				}
            </Grid>
        )
    }
}

const styles = theme => ({
    ListItem: {
		backgroundColor: colors.white, 
		borderRadius: 	 5,
		marginBottom: 	 '0.75em',
		boxShadow: 	  	 '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
	}
});

const mapDespatchToProps = dispatch => ({
	addKeyDown: (func) => dispatch(addKeyDown(func,'WorksOrderBuilds')),
	setKeyDown: () => dispatch(setKeyDown('WorksOrderBuilds')),
	removeKeyDown: () => dispatch(removeKeyDown('WorksOrderBuilds'))
});

export default connect(null, mapDespatchToProps)(withStyles(styles)(WorksOrderBuilds));


