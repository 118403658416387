import API                  from 'API';
import _                    from 'lodash';
import React, { Component } from 'react';
import { CirclePicker }     from 'react-color';
import { connect }          from 'react-redux';

import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';

import IconPicker         from 'Components/Common/Inputs/IconPicker';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import MapMarker          from 'Components/Common/Map/MapMarker';
import DataTable          from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';

import icons from 'Helpers/IconHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = {
    categories: [],
    formData: {
        label:    '',
        category: '',
        color:    '',
        icon:     '',
    },
    editFormData: {
        id:       0,
        label:    '',
        category: '',
        color:    '',
        icon:     '',
    },
    access: {
        create: false,
        delete: false,
    },
    isLoading: true,
}

class MarkerCatagory extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getCategories();
        this.getAccess();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/map-categories-create'), 
            API.get('/staff/my/access/check/map-categories-delete')
        ]).then(([createRes, deleteRes]) => {
            this.setState({ 
                access: { 
                    create: createRes?.data?.has_access || false, 
                    delete: deleteRes?.data?.has_access || false 
                } 
            });
        })
    }

    getCategories = () => {
        API.get('/engineers/maps/categories')
        .then(res => {
            this.setState({
                categories: res.data,
                isLoading: false
            });
        })
    }

    handelColorChange = formData => (colour, event) => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                color: colour.hex
            }
        });
    };

    handleChange = formData => field => event => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                [field]: event.target.value
            }
        });
    }

    handleSelectChange = formData => field => event => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                [field]: event ? event.value : ''
            }
        });
    }

    handleIconChange = formData => field => icon => {
        this.setState({
            [formData]: {
                ...this.state[formData],
                [field]: icon
            }
        });
    }

    submitMarker = () => {
        API.post('/engineers/maps/categories', this.state.formData)
        .then(res => {
            this.setState({
                formData: initialState.formData
            }, this.getCategories);
        })
    }

    updateMarker = () => {
        API.post(`/engineers/maps/categories/${this.state.editFormData.id}`, this.state.editFormData)
        .then(res => {
            this.setState({
                editFormData: initialState.editFormData
            }, this.getCategories);
        })
    }

    deleteMarker = id => () => {
        API.post(`/engineers/maps/categories/${id}/delete`)
        .then(this.getCategories)
    }

    render() {

        const { formData, isLoading, categories, editFormData, access } = this.state;

        if (isLoading) return (<LoadingCircle/>);

        return (
            <Grid container spacing={3}>
                {access.create &&
                    <Grid item xs={6}>
                        <PaddedPaper>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <AutoCompleteSelect
                                        creatable
                                        fullWidth
                                        label="Category"
                                        options={_.uniqBy([..._.map([
                                            'Electric Charge Points',
                                            'Hotels',
                                            'Parking',
                                        ], i => ({label: i, value: i}) ),..._.map(_.uniqBy(categories, 'mmc_category'), i => ({
                                            label: i.mmc_category,
                                            value: i.mmc_category
                                        }))], 'value')}
                                        value={formData.category}
                                        onChange={this.handleSelectChange('formData')('category')}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{marginBottom: 20}}>
                                    <TextField
                                        fullWidth
                                        label="Type"
                                        value={formData.label}
                                        onChange={this.handleChange('formData')('label')}
                                    />
                                </Grid>
                                <Grid item>
                                    <CirclePicker
                                        fullWidth
                                        color={formData.color}
                                        colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]}
                                        onChangeComplete={this.handelColorChange('formData')}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <IconPicker
                                        icons={_.uniq(_.map(categories, c => c.mmc_icon))}
                                        onChange={this.handleIconChange('formData')('icon')}
                                        freeType
                                    />
                                </Grid>
                                <Grid item style={{marginLeft: 'auto', position: 'relative'}}>
                                    <MapMarker icon={formData.icon} color={formData.color} size={110} />
                                </Grid>
                                <Grid item xs={12} className='buttonRow'>
                                    <Button 
                                        color='primary' 
                                        variant='contained' 
                                        disabled={formData.label === '' || formData.category === '' || formData.color === '' || formData.icon === ''}
                                        onClick={() => this.props.deployConfirmation('Are you sure you want to create this marker?', 'Create Marker', this.submitMarker )}
                                    >Create</Button>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
                <Grid item xs={12}>
                    <DataTable
                        config={{
                            key: 'mmc_id'
                        }}
                        columns={[
                            {
                                heading:       'Icon',
                                field:         i => <MapMarker icon={i.mmc_icon} color={i.mmc_color} size={40} />,
                                sizeToContent: true,
                            },
                            {
                                heading:       'Category',
                                field:         'mmc_category',
                            },
                            {
                                heading:       'Type',
                                field:         'mmc_name',
                            },
                            {
                                heading:       'Markers',
                                field:         'map_markers_count',
                                sizeToContent: true,
                                alignment:     'center',
                            },
                            {
                                actions: i => ([
                                    access.create &&
                                    {
                                        name:    'Edit',
                                        icon:    icons.edit,
                                        onClick: () => {
                                            this.setState({
                                                editFormData: {
                                                    id:       i.mmc_id,
                                                    label:    i.mmc_name,
                                                    category: i.mmc_category,
                                                    color:    i.mmc_color,
                                                    icon:     i.mmc_icon
                                                }
                                            });
                                        }
                                    },
                                    access.delete &&
                                    {
                                        name:    'Delete',
                                        icon:    icons.delete,
                                        onClick: () => this.props.deployConfirmation('Are you sure you want to delete this marker?', 'Delete Marker', this.deleteMarker(i.mmc_id) ),
                                        disabled: i.map_markers_count > 0
                                    }
                                ])
                            }
                        ]}
                        rows={_.orderBy(categories, c => `${c.mmc_category}-${c.mmc_name}`)}
                    />
                </Grid>
                <Dialog
                    open={editFormData.id}
                    fullWidth={true} 
                    maxWidth="md" 
                    scroll="body"
                >
                    <DialogTitle>Edit Marker Category</DialogTitle>
                    <DialogContent style={{height: 400}}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} style={{marginBottom: 20}}>
                                        <AutoCompleteSelect
                                            creatable
                                            fullWidth
                                            label="Category"
                                            options={_.map(_.uniqBy(categories, 'mmc_category'), i => ({
                                                label: i.mmc_category,
                                                value: i.mmc_category
                                            }))}
                                            value={editFormData.category}
                                            onChange={this.handleSelectChange('editFormData')('category')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Type"
                                            value={editFormData.label}
                                            onChange={this.handleChange('editFormData')('label')}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CirclePicker
                                            fullWidth
                                            color={editFormData.color}
                                            colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]}
                                            onChangeComplete={this.handelColorChange('editFormData')}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <IconPicker
                                            icons={_.uniq(_.map(categories, c => c.mmc_icon))}
                                            onChange={this.handleIconChange('editFormData')('icon')}
                                            icon={editFormData.icon}
                                            freeType
                                        />
                                    </Grid>
                                    <Grid item style={{marginLeft: 'auto', position: 'relative'}}>
                                        <MapMarker icon={editFormData.icon} color={editFormData.color} size={110} />
                                    </Grid>
                                    <Grid item xs={12} className='buttonRow'>
                                        <Button
                                            variant='outlined'
                                            onClick={()=>this.setState({editFormData: initialState.editFormData})}
                                        >Close</Button>
                                        <Button 
                                            color='primary' 
                                            variant='contained' 
                                            disabled={editFormData.label === '' || editFormData.category === '' || editFormData.color === '' || editFormData.icon === ''}
                                            onClick={() => this.props.deployConfirmation('Are you sure you want to update this marker category?', 'Update Marker Category', this.updateMarker )}
                                        >Update</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    }
}

export default connect(null, mapDispatchToProps)(MarkerCatagory);