import React, { PureComponent } from 'react';
import API                      from 'API';
import _                        from 'lodash';
import { connect }              from 'react-redux';

import { Grid, Typography } from '@material-ui/core/';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import DataTable     from 'Components/Common/DataTables/CiDataTable';
import AllIcon       from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

const initialState = { 
    formErrors:    {},
    data:          [],
    isLoading:     true,
}

const InfoBlock = ({title, icon, value, color}) => {
    return (
        <Grid item xs={2}>
            <PaddedPaper  style={{textAlign: 'center', height: '100%'}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}><Typography variant="h6" style={{textAlign: 'center'}}>{title}</Typography></Grid>
                    <Grid item xs={12}><AllIcon icon={icon} noMargin size='large' color={color}/></Grid>
                    <Grid item xs={12}><Typography variant="h6"  style={{textAlign: 'center'}}>{value}</Typography></Grid>
                </Grid>
            </PaddedPaper>
        </Grid>
    )
} 

class StockReportsShortages extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getData();
    }


    getData = () => {
        API.get('/reports/stocksReport/shortages', {params: {partId: this.props.partId}})
        .then(res => {
            this.setState({
                data:      res.data, 
                isLoading: false
            });
        })
    }

    createProcurement = rowData => {
        API.post(`/partsOrdering/tracker`, {part: rowData.q})
        .then(r => {
            window.location.href = `/parts-order/tracker/${r.data.ppot_id}`;
        })
    }

    create3dPrint = rowData => {
        API.post('/partsOrdering/3dtracker', {
            part:       rowData.q,
            qty:        parseFloat(rowData.u),
        }).then(r => {
            window.location.href = `/parts-order/3dtracker/${r.data.tdpt_id}`;
        })
    }

    render() {
        const { data, isLoading } = this.state;

        if (isLoading) return <LoadingCircle />;

        let worksOrders = [];

        data.forEach(row => { row.y.forEach(wo => {worksOrders.push(wo); }); })

        worksOrders = _.uniqBy(worksOrders, 'q');

        return (
            <Grid container xs={12} spacing={2} style={{width: '100%'}}>
                <Grid item xs={12}>
                    <Grid container spacing={2} style={{alignItems: 'stretch'}}>
                        <InfoBlock 
                            title={<>Parts<br/><br/></>} 
                            icon={icons.parts} 
                            value={data.length}
                        />
                        <InfoBlock 
                            title={<>Quantity<br/><br/></>} 
                            icon={icons.stock} 
                            value={_.sumBy(data, 'u')}
                        />
                        <InfoBlock 
                            title={<>Works Orders<br/><br/></>} 
                            icon={icons.worksOrder} 
                            value={worksOrders.length}
                        />
                        <InfoBlock 
                            title={<>Parts on Order<br/>(No Order Required)</> }
                            icon={icons.partsOrdering} 
                            value={_.filter(data, i => (parseFloat(i.a) > 0) && (parseFloat(i.a) >= parseFloat(i.u))).length}
                            color={colors.green}
                        />
                        <InfoBlock 
                            title={<>Parts on Order<br/>(Order Required)</> }
                            icon={icons.partsOrdering} 
                            value={_.filter(data, i => (parseFloat(i.a) > 0) && (parseFloat(i.a) < parseFloat(i.u))).length}
                            color={colors.orange}
                        />
                        <InfoBlock 
                            title={<>Parts Need Ordering<br/><br/></>} 
                            icon={icons.partsOrdering} 
                            value={_.filter(data, i => !parseFloat(i.a)).length}
                            color={colors.red}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{paddingTop: '1.5em'}}> 
                    <DataTable 
                         config={{
                            key: 'q',
                            pagination: true,
                            responsiveimportant: true,
                            rowsPerPage: 25,
                            filter: true,
                            filterMod: {
                                filterInPagination:     true,
                                clearable:              true,
                                colSpan:                3,
                            },
                            sticky:true,
                            dropRow: {
                                droppable: true,
                                style: {fontWeight: 'bold',},
                                columns: [
                                    {
                                        colSpan:   4,
                                        field:     'w',
                                        alignment: 'right',
                                    },
                                    {
                                        colSpan:   4,
                                        field:     'r',
                                        alignment: 'left',
                                    },
                                    {
                                        colSpan:   1,
                                        actions: i => ([
                                            {
                                                icon: icons.view,
                                                name: 'View Works Order',
                                                link: `/WorksOrders/view/${i.q}`,
                                                hide: !i.q,
                                            }
                                        ])
                                    }
                                ],
                                data: i => [{ w: 'Part short in the following Works Order(\'s)', r: '' },...i.y],
                            }
                        }}
                        columns={[
                            {
                                field:        'r',
                                fieldFormat: 'image',
                                sizeToContent: true,
                                filter:         'custom',
                                filterMod: {
                                    dataRef:   (search, rowData) => {return `${rowData.w}|${rowData.e}|${rowData.t}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                    label:     'Search Parts'
                                }
                            },
                            {
                                heading:  <>Part <br/> Number</>,
                                field:    'w',
                                sizeToContent: true,
                                dataRef:  'w'
                            },
                            {
                                heading:  <>Part <br/> Description</>,
                                field:    'e',
                                dataRef:  'e',
                                truncate: true,
                            },
                            {
                                heading:  <>Works <br/> Orders</>,
                                field:    i => i.y.length,
                                sizeToContent: true,
                                alignment:     'center',
                                dataRef:  i => i.y.length,
                            },
                            {
                                heading:       <>Quantity <br/> Short</>,
                                field:         'u',
                                fieldFormat:   'decimal:3',
                                sizeToContent: true,
                                dataRef:       'u',
                                alignment:     'right',
                            },
                            {
                                heading:       <>Quantity <br/> On Order</>,
                                field:         'a',
                                fieldFormat:   'colorNumbers:decimal:3',
                                sizeToContent: true,
                                alignment:     'right',
                                dataRef:       'a'
                            },
                            {
                                heading:       <>Quantity <br/> Required</>,
                                field:         i => (parseFloat(i.u) - parseFloat(i.a)) > 0 ? parseFloat(i.u) - parseFloat(i.a) : 0,
                                fieldFormat:   'decimal:3',
                                sizeToContent: true,
                                alignment:     'right',
                                dataRef:       i => parseFloat(i.u) - parseFloat(i.a),
                                style:         i => ({color: (parseFloat(i.u) - parseFloat(i.a)) > 0 ? (parseFloat(i.a) === 0 ? colors.red : colors.orange) :  colors.green})
                            },
                            {
                                heading:       <>Quantity <br/> In Stock</>,
                                field:         'j',
                                fieldFormat:   'colorNumbers:decimal:3',
                                sizeToContent: true,
                                alignment:     'right',
                                dataRef:       'a',
                                style:         {borderRight: `1px solid ${colors.disabled}`, borderLeft: `1px solid ${colors.disabled}`}
                            },
                            {
                                heading:  'Supplier',
                                field:    't',
                                dataRef:  't',
                                truncate: true,
                            },
                            {
                                actions: rowData => ([
                                    {
                                        name: 'View',
                                        icon: icons.parts,
                                        link: `/parts/view/${rowData.q}`,
                                    },
                                    rowData.i ?  ( 
                                                    parseFloat(rowData.a) === 0 ? {
                                                        name: 'Create Works Order',
                                                        icon: icons.worksOrder,
                                                        link:    `/WorksOrders/new/${rowData.i}`,
                                                        color: colors.red
                                                    } : {
                                                        name:  'View Works Order',
                                                        icon:  icons.worksOrder,
                                                        link:  rowData.s ? `/parts-order/view/${rowData.s}` : (rowData.o ? (rowData.h ? `/parts-order/3dtracker` : `/parts-order/tracker`) : `/WorksOrders/view/${rowData.d}`),
                                                        color: (parseFloat(rowData.a) > 0) && (parseFloat(rowData.a) >= parseFloat(rowData.u)) ? colors.green : colors.orange
                                                    }
                                                ) : ( 
                                                    parseFloat(rowData.a) === 0 && !rowData.o ? {
                                                        name: 'Create Procurement',
                                                        icon:  icons.partsOrdering,
                                                        onClick: rowData.f ? this.create3dPrint : this.createProcurement,
                                                        color: colors.red
                                                    } : {
                                                        name:  'View Procurement',
                                                        icon:  icons.partsOrdering,
                                                        link:  rowData.s ? `/parts-order/view/${rowData.s}` : (rowData.o ? (rowData.h ? `/parts-order/3dtracker` : `/parts-order/tracker`) : `/WorksOrders/view/${rowData.d}`),
                                                        color: rowData.o ? colors.green : ((parseFloat(rowData.a) > 0) && (parseFloat(rowData.a) >= parseFloat(rowData.u)) ? colors.green : colors.orange)
                                                    }
                                                )
                                ])
                            }
                        ]}
                        rows={_.orderBy(data,'w')}
                    />
                </Grid>
            </Grid>
        )
    }
};


function mapDispatchToProps(dispatch) { return { deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)) } };

export default connect(null, mapDispatchToProps)(StockReportsShortages);
