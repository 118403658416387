import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(3),
        },
    },
    paperGrey: {
        backgroundColor:'#f8f9fa'
    }
});

class PaddedPaper extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Paper 
                className={`${classes.paper} ${this.props.className} ${this.props.grey && classes.paperGrey}`} 
                onClick={this.props.onClick}
                style={this.props.style}
            >
                {this.props.children}
            </Paper>
        );
    }
}

export default withStyles(styles)(PaddedPaper);
