/*
    data: [{
        label: label
        value: value 
        color: color 
    }]
*/
import React, { PureComponent, createRef  } from 'react';
import _                        from 'lodash';

import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut, Pie, getElementAtEvent }   from 'react-chartjs-2';
import ChartDataLabels                        from 'chartjs-plugin-datalabels';

import { getRandomColor, colors }         from 'Helpers/ColourHelper';

Chart.register(ArcElement, Tooltip, Legend);

const initialState = {
    options: {},
}

const colorOverride = {
    'Yes': colors.green,
    'yes': colors.green,
    'No': colors.red,
    'no': colors.red,
}

class PieChart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {...initialState, ...this.props};

        this.chartRef = createRef();
        this.usedColors = [];
    }

    getData = () => {
        this.usedColors = [];
        const data = this.props.data;
        return {
            labels:    _.map(data, i => i.label), 
            datasets: [
                {
                    label:              this.props.label || '',
                    data:               _.map(data, i => i.value),
                    backgroundColor:    _.map(data, i => { return i.color || this.getColor(i) }), 
                }
            ]
        }
    }

    getColor(i){
        if (colorOverride[i.label]){
            return colorOverride[i.label];
        }
        return this.getRandomColor();
    }

    getRandomColor(){
        let color = null;
        if (this.props.colors){
            color = this.props.colors[this.usedColors.length];
        } else {
            color = getRandomColor();
            while (this.usedColors.includes(color)){
                color = getRandomColor();
            }
        }
        this.usedColors.push(color);
        return color;
    }

    getOptions = () => {
        let options = {  
            responsive: true,
            maintainAspectRatio: false,
            plugins:{
                legend: {
                    labels: {
                        textTransform: 'capitalize'
                    },
                    display: this.props.hideLabel ? false : true,
                    position: this.props.legendPosition || 'top',
                }
            }
        };

        if (this.props.centerLabel ){
            options.centerText = {
                display: true,
                text: this.props.centerLabel
            }
        }

        if (this.props.tLabel){
            options.plugins = {
                ...options.plugins,
                tooltip: {
                    ...options.plugins?.tooltips,
                    callbacks: {
                        ...options.plugins?.tooltips?.callbacks,
                        label: this.props.tLabel
                    }
                },
            }
        }
        if (this.props.hideLegend){
            options.plugins = {
                ...options.plugins,
                legend:{
                    ...options.plugins?.legend,
                    display:false
                }
            }
        }

        if (this.props.onPointClick || this.props.onClick){
            options = {
                ...options,
                onClick: (e, elements) => {
                    if (this.chartRef.current){
                        if (this.props.onClick){
                            this.props.onClick(e,elements)
                        }

                        if (this.props.onPointClick && elements.length > 0) {
                            this.props.onPointClick(
                                {
                                    index: elements[0].index,
                                    data: this.props.data[elements[0].index],
                                }
                            );
                        }
                    }
                }
            }
        }
        
        if (this.props.labelSegments) {
            let labelprops = (_.isObject(this.props.labelSegments) ? this.props.labelSegments : {})
            options.plugins = {
                ...options.plugins,
                datalabels: {
                    backgroundColor: function(context) {
                        return context.dataset.backgroundColor;
                    },

                    borderRadius: 25,
                    borderWidth: 2,
                    color: 'white',
                    font: {
                        weight: 'bold',
                    },
                    padding: 1,
                    formatter: Math.round,
                    textShadow: true,
                    textAlign:  'center',
                    formatter:  function(value, context) {
                        let percent = ( value / _.sum(context.chart.data.datasets[0].data) ) * 100;
                        return Math.round(percent) >= 8 ? context.chart.data.labels[context.dataIndex] : null;
                    },
                    anchor: 'center',
                    align: 'end',
                    
                    ...labelprops
                }
            }
        }

        return options;
    }

    getElement = (props) => {
        if (this.props.doughnut || this.props.centerLabel ){
            return (
                <Doughnut {...props} ref={this.chartRef} />
            )
        }
        return (<Pie {...props} ref={this.chartRef} />)
    }

    getPlugins = (props) => {
        let plugins = [];
        if (this.props.labelSegments) plugins.push(ChartDataLabels);
        if (this.props.centerLabel) {
            plugins.push({beforeDraw: function (chart) {
                var text = chart.config.options.centerText.text;
                const hideLegend = !chart.config.options.plugins.legend.display
                var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
                ctx.restore();
                var fontSize = parseFloat(parseFloat(height / parseFloat(80 + parseFloat( props.centerLabel.length > 3 ? props.centerLabel.length * 10 : 0 )))).toFixed(2);
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                var textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = (( height - (fontSize / 2) 
                        - (hideLegend ? (fontSize * 12) : 0) ) / 2) + ((props?.data?.length > 3 && !props.noHeight) ? 8 : 0) 
                        + parseFloat( props.centerLabel.length > 3 ? props.centerLabel.length : 0 );
                ctx.fillText(text, textX, textY);
                ctx.save();
            }});
        }

        return plugins;
    }

    render () {

        const Pie = this.getElement;

        return (
            <div style={this.props.style}>
                <Pie 
                    data={this.getData()} 
                    options={this.getOptions()}
                    style={this.props.style}
                    plugins={this.getPlugins(this.props)}
                />
            </div>
        )
    }

} 



export default PieChart;