import React from 'react';
import { map, assign, cloneDeep, filter } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';

import API from 'API';
import {
    Grid,
    Typography,
    Button
} from '@material-ui/core';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import VehicleSubscriptionForm from './VehicleSubscriptionForm';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import { toggleDialog } from 'Functions/MiscFunctions';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = () => ({
    vehicleId: 0,
    subscriptions: [],
    originalSubscriptions: [],
    dialog: {
        subscription: false,
        subscriptionId: 0,
    },
    access: {
        updateVehicle: false,
    },
    isLoading: true,
    suppList: [],
    formData: {
        subscriptionSupplierId: ''
    },
})

class VehicleSubscriptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.toggleDialog = toggleDialog.bind(this);
    }

    componentDidMount() {
        const { vehicleId } = this.props;
        this.setState({
            ...this.state,
            vehicleId
        }, () => this.getAccess());
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-vehicle')
        ])
        .then(([updateRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    updateVehicle: (updateRes.data && updateRes.data.has_access) || false
                }
            }, () => this.loadComponentData());
        }) 
    }

    loadComponentData = () => {
        Promise.all([
            API.get(`/vehicles/${this.props.vehicleId}/subscriptions`),
            API.get('/suppliers/all', {
                params: {
                    supplierType: 10 // Vehicles
                }
            })
        ])
        .then(([result, suppRes]) =>  {

            let suppList = map(suppRes.data, (supp) => {
                return assign({
                    value: supp.supp_id,
                    label: supp.supp_company_name
                });
            });

            if(result.data) {
                this.setState({
                    isLoading: false,
                    subscriptions: result.data,
                    originalSubscriptions: cloneDeep(result.data),
                    suppList
                });
            }
        });
        
    }

    openSubscriptionDialog = (id = 0, readOnly = false) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                subscription: true,
                subscriptionId: id,
                readOnly
            },
        });
    }

    filterSubscriptions = (fieldName, selectedOption) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption?.value ? selectedOption?.value : ''
            }
        },
        () => {
            let subscriptions = this.state.originalSubscriptions;

            if(selectedOption?.value){

                subscriptions = filter(this.state.originalSubscriptions, (el) => {
                    return el.supplierId == selectedOption.value;
                });
            }

            this.setState({
                subscriptions
            });
        });
    }

    render() {

        const { vehicleId, subscriptions, dialog, isLoading, access, suppList, formData } = this.state;
        const { activeVehicle } = this.props;
        return (
            <>
                <PaddedPaper>
                    {(isLoading && (
                        <LoadingCircle />
                    )) || (
                        <Grid container item spacing={3}>                        
                            <Grid item xs={12}>
                                <Grid container item spacing={3}>                        
                                    <Grid item xs>

                                        <Typography variant="h6" paragraph>
                                            <b>Vehicle Subscriptions</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs align='right'>
                                        {access.updateVehicle && activeVehicle ?
                                            <Button 
                                                variant='outlined' 
                                                size="small" 
                                                onClick={() => this.openSubscriptionDialog()}
                                            >
                                                <AllIcon icon='plus' size="small" />
                                                Add Subscription
                                            </Button>
                                        : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {subscriptions.length > 0 ?
                                <>
                                <Grid item xs={12}>
                                    <Grid container item spacing={3}>                        
                                        <Grid item xs={8} md={3}>
                                            <AutoCompleteSelect
                                                label="Filter"
                                                value={formData.subscriptionSupplierId}
                                                options={suppList}
                                                onChange={v => this.filterSubscriptions('subscriptionSupplierId', v)}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <DataTable  
                                        config={{
                                            key: 'id',
                                            pagination: true,
                                            alternatingRowColours: false,
                                            responsiveImportance: true,
                                            isLoading: isLoading
                                        }}
                                        columns={[
                                            {
                                                heading: 'Subscription Name',
                                                field: rowData => rowData?.name,
                                                main: true,
                                                important: true,
                                                hideInResponsiveDisplay: true,
                                            },
                                            {
                                                heading: 'Description',
                                                field: rowData => rowData?.desc ? rowData?.desc : '-',
                                                important: true,
                                            },
                                            {
                                                heading: 'Start Date',
                                                field: rowData => moment(rowData?.startDate).format('MMM D, YYYY'),                                   
                                                important: true,
                                            },
                                            {
                                                heading: 'End Date',
                                                field: rowData => rowData?.endDate !== '0000-00-00' ? moment(rowData?.endDate).format('MMM D, YYYY') : '-',                                          
                                                important: true,
                                            },
                                            {
                                                heading: 'Status',
                                                field: rowData => rowData?.status,
                                                important: true,
                                                alignment: 'center',
                                                cellProps: rowData => {
                                                    return ({
                                                        style: {
                                                            color: rowData?.status == 'Active' ? '#5da14d' : undefined,
                                                            fontWeight: rowData?.status == 'Active' ? 'bold' : undefined
                                                        }
                                                    });
                                                },
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Update', icon: 'pencil-alt', onClick: () => this.openSubscriptionDialog(rowData?.id), disabled: !access.updateVehicle || rowData?.endDate !== '0000-00-00' || !activeVehicle},
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={subscriptions}
                                    />
                                </Grid>
                                </>
                            :
                                <Grid item xs={12} align="center">
                                    <Typography variant="h6" paragraph color='textSecondary'>
                                        <em>No subscriptions found</em>
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    )}
                </PaddedPaper>
                <SmallFormDialog
                    open={dialog?.subscription}
                    onClose={() => this.toggleDialog('subscription')}
                    content={
                        <VehicleSubscriptionForm 
                            onClose={() => this.toggleDialog('subscription', true)}
                            subscriptionId={dialog?.subscriptionId}
                            refresh={() => {this.toggleDialog('subscription', true); this.props.refresh();}}
                            readOnly={dialog?.readOnly}
                            vehicleId={vehicleId}
                        />
                    }
                    maxWidth='sm'
                />
            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),

    }
}

export default connect(null, mapDispatchToProps)(VehicleSubscriptions)