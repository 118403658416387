import React       from 'react';
import API         from 'API';
import _           from 'lodash';
import { connect } from 'react-redux';

import { Button, FormControl, Grid, Paper, TableCell, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import BackButton         from 'Components/Common/Buttons/BackButton';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons                      from 'Helpers/IconHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import { downloadS3File } from 'Functions/MiscFunctions';

const TableHeaderCell = withStyles(theme => ({
    head: {
      backgroundColor: colors.blue,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    }
}))(TableCell);


const initialState = {
    isLoading:          true,
    subAssembly:        {},
    parts:              [],
    instructions:       [],
    instructionsError:  {
        parts:  {},
        show:   false,
    },
    formErrors:         {},
}

class SubAssemblyCreateBuildStages extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){ this.getSubAssembly(); }

    getSubAssembly = () => {
        API.get('/subAssemblies/' + this.props.match.params.id)
        .then((result) => {
            if (result.data.instruction) this.props.history.push(`/sub-assemblies/search`);
            let partsList = _.map(result.data.sub_assembly_parts, (el) => {
                return _.assign({
                    part: el.part.part_number + ' - ' + el.part.part_description,
                    quantity: el.sa_qty,
                    origQuantity: el.sa_qty,
                    saId: el.sa_id,
                    delete: false,
                    partId: el.part.part_id,
                    partNumber: el.part.part_number,
                    partDesc: el.part.part_description,
                    costPerUnit: el.part.default_cost.cost_per_unit,
                    totalCost: el.part.default_cost.cost,
                });
            });
            this.setState({
                subAssembly: result.data,
                parts:       _.sortBy(partsList, pn => pn.partNumber),
                isLoading:   false,
            });
        });
    }
    handleNumberOfStagesChange = (e) => {   
        let instructions = this.state.instructions;
        if (instructions.length < e.value) {
            for(var i = instructions.length; i < e.value; i++) {
                instructions.push({
                    stage:      i + 1,
                    name:       '',
                    test:       false,
                    testFile:   0,
                    parts:      {}
                });
            }
        } else {
            instructions.splice(e.value - instructions.length);
        }
        this.setState({instructions},this.checkInstructionsError);
    }
    handleRemoveInstruction = stage => {
        this.setState({
            instructions: _.map(_.filter(this.state.instructions, i => i.stage !== stage), (i,idx) => {
                return {
                    ...i, 
                    name:   i.stage > stage ? '' : i.name, 
                    stage:  (idx+1),  
                }
            })
        }, this.checkInstructionsError);
    }
    handleInstructionsChange = (stage, field) => (e) => {
        this.setState({
            instructions: this.state.instructions.map(i => {
                return i.stage === stage ? {...i, [field] : e.target.value} : i;
            })
        }, this.checkInstructionsError)
    }
    handleInstructionsPartsChange = (stage, partId, fixed = null) => (e) => {
        this.setState({
            instructions: this.state.instructions.map(i => {
                return i.stage === stage ? 
                    {...i, 
                        parts: {
                            ...i.parts, 
                            [partId]: fixed ? parseFloat(e.target.value).toFixed(3) : e.target.value
                        }
                    } : i;
            })
        }, this.checkInstructionsError)
    }
    checkInstructionsError = () => {
        let parts = _.filter(_.map(_.filter(this.state.parts, i => !i.delete ), p => {
            return (parseFloat(p.quantity).toFixed(3) != _.sumBy(this.state.instructions, i =>parseFloat(i.test ? 0 : (i.parts[p.partId] || 0))).toFixed(3)) ? p.partId : null;
        }), i => i != null);
        this.setState({
            instructionsError: {
                parts,
                show: parts.length > 0 ? true : false
            }
        });
    }
    handleSubmit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            API.post('/subAssemblies/' + this.props.match.params.id + '/createBuildStages', {instructions: this.state.instructions})
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                } else { window.location.href = `/sub-assemblies/view/${this.props.match.params.id}`; }
            });
        });
    }
    render(){
        const { classes } = this.props;
        const { subAssembly, isLoading, instructions, formErrors, parts, instructionsError } = this.state;
        if (isLoading) return (<LoadingCircle />);
        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Create Sub Assembly Build Stages
                    </Typography>
                </Grid>   
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container xs={12} >
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    {subAssembly.part.part_number} - {subAssembly.part.part_description}
                                </Typography>
                                <Typography variant="h6">
                                    Current Version: v{subAssembly.sa_version} {subAssembly.part.part_outsourced_subassembly === 1 ? <strong style={{color:'orange'}}>Outsourced</strong> : ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={3} style={{justifyContent: 'space-between'}}>
                            <Grid item>
                                <Typography variant="h6">
                                    Sub Assembly Build Stages
                                </Typography>
                            </Grid>
                            <Grid item>
                                {subAssembly.part?.pdf_drawing && subAssembly.part?.pdf_drawing?.part_doc_url ?
                                    <Button variant='contained' color='primary' onClick={() => downloadS3File(subAssembly.part.pdf_drawing.url)}>
                                        <AllIcon icon={icons.download} color={colors.white} />Process Document
                                    </Button> : 'No Process Document'
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options=    {_.map(_.range(1,16), i => _.assign({value: i, label: i}))}
                                        label=      'Number Of Stages *'
                                        value=      {instructions.length}
                                        onChange=   {this.handleNumberOfStagesChange}
                                        noClear
                                    />
                                </FormControl>
                            </Grid>
                            {instructions && 
                                <Grid container xs={12} spacing={3} style={{margin: '0.75em 0'}}>
                                    {_.map(instructions, i =>
                                        <Grid item xs={12} key={i.stage}>
                                            <Grid container style={{justifyContent: 'space-between', alignItems: 'flex-end'}}>
                                                <Grid item xs={1}>
                                                    <Typography variant="body1">
                                                        Stage {i.stage}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth margin="none">
                                                        <TextField
                                                            value       = {i.name}
                                                            onChange    = {this.handleInstructionsChange(i.stage, 'name')}
                                                            placeholder = 'Name *'
                                                            error       = {formErrors[i.stage]}
                                                            helperText  = {formErrors[i.stage]}
                                                            margin      = "none"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={1} style={{textAlign:'right'}}>
                                                    <AllIcon 
                                                        icon=    {icons.delete}
                                                        onClick= {()=>{this.handleRemoveInstruction(i.stage)}}
                                                        tooltip= "Delete"
                                                        noPadding
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            }
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Typography variant="h6">
                            Sub Assembly Parts
                        </Typography><br/>
                        <Grid container spacing={3} xs={12}>
                            {parts.map((item, idx) => {
                                let number = idx+1;
                                return (
                                    <Grid item xs={12} key={idx}>
                                        <Paper className={classes.paperGrey}>
                                            <Grid container xs={12} spacing={1} style={{alignItems: 'center'}}>
                                                <Grid item xs={10}>
                                                    <TextField
                                                        margin="normal"
                                                        name={`Part${number}`}
                                                        label={`Part ${number}:`}
                                                        value={parts[idx].part}
                                                        fullWidth
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item >
                                                    <TextField
                                                        margin="normal"
                                                        name="quantity"
                                                        label="Total Qty *"
                                                        error={formErrors && formErrors['parts|quantity|' + idx] && true}
                                                        helperText={formErrors && formErrors['parts|quantity|' + idx]}
                                                        value={parts[idx].quantity}
                                                        fullWidth
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Grid container spacing={1} xs={12}>
                                                        {_.map(_.filter(instructions, i => !i.delete ), i => 
                                                            <Grid item style={{width: instructions.length > 6 && `${100 / instructions.length}%`}}>
                                                                <TextField
                                                                    margin=     "none"
                                                                    label=      {`Stage ${i.stage}`}
                                                                    value=      {i.parts[parts[idx].partId] || 0.000}
                                                                    onChange=   {this.handleInstructionsPartsChange(i.stage,parts[idx].partId)}
                                                                    onBlur=     {this.handleInstructionsPartsChange(i.stage,parts[idx].partId, 3)}
                                                                    type=       "number"
                                                                    inputProps= {{min: 0}}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Grid container xs={12}>
                                                        <Grid item xs={6}>
                                                            Stage Total:<br/>
                                                            Difference:
                                                        </Grid>
                                                        <Grid item xs={6} style={{color: (parseFloat(_.sumBy(instructions, i => parseFloat(i.parts[parts[idx].partId]) || 0.000).toFixed(3)) - parseFloat(parseFloat(parts[idx].quantity).toFixed(3))) == 0 ? colors.green : colors.red}}>
                                                            {parseFloat(_.sumBy(instructions, i => parseFloat(i.parts[parts[idx].partId]) || 0.000)).toFixed(3)}<br/>
                                                            {`${(parseFloat(_.sumBy(instructions, i => parseFloat(i.parts[parts[idx].partId]) || 0.000).toFixed(3)) - parseFloat(parseFloat(parts[idx].quantity).toFixed(3))) == 0 ? '' : parseFloat(_.sumBy(instructions, i => parseFloat(i.parts[parts[idx].partId]) || 0.000) - parts[idx].quantity) > 0 ? '+' : '-'}${parseFloat(Math.abs(_.sumBy(instructions, i => parseFloat(i.parts[parts[idx].partId]) || 0.000) - parts[idx].quantity)).toFixed(3)}`}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>   
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </PaddedPaper>
                </Grid>
                {/*}
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Typography variant="h6">
                            Sub Assembly Parts
                        </Typography><br/>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeaderCell></TableHeaderCell>
                                    <TableHeaderCell>
                                        Part
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        Qty
                                    </TableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {parts.map((item, idx) => {
                                    let number = idx+1;
                                    return (
                                        <>
                                            <TableRow key={`table-${idx}`} >
                                                <TableCell style={{color: formErrors && (formErrors['parts|part|' + idx] || formErrors['parts|quantity|' + idx]) && colors.red, borderBottom: 0}}>
                                                    Part {number}:
                                                </TableCell>
                                                <TableCell style={{borderBottom: 0}}>
                                                    {parts[idx].saId ?
                                                        <Typography variant="body1">
                                                            {parts[idx].part}
                                                        </Typography>
                                                        :
                                                        <FormControl fullWidth margin="none">
                                                            <AutoCompleteSelect
                                                                options={this.state.partsList}
                                                                error={formErrors && formErrors['parts|part|' + idx] && true}
                                                                errorText={formErrors && formErrors['parts|part|' + idx]}
                                                                value={parts[idx].partID}
                                                                onChange={this.handlePartsSelectChange('partId', idx)}
                                                            />
                                                        </FormControl>
                                                    }
                                                </TableCell>
                                                <TableCell style={{borderBottom: 0}}>
                                                    <TextField
                                                        margin="none"
                                                        name="quantity"
                                                        value={parts[idx].quantity}
                                                        fullWidth
                                                        disabled
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key={`table-stages-${idx}`}>
                                                <TableCell colSpan={2}>
                                                    <Grid container spacing={1} xs={12}>
                                                        {_.map(_.filter(instructions, i => !i.delete ), i => 
                                                            <Grid item xs={1}>
                                                                <TextField
                                                                    margin=     "none"
                                                                    label=      {`Stage ${i.stage}`}
                                                                    value=      {i.parts[parts[idx].partId] || 0.000}
                                                                    onChange=   {this.handleInstructionsPartsChange(i.stage,parts[idx].partId)}
                                                                    onBlur=     {this.handleInstructionsPartsChange(i.stage,parts[idx].partId, 3)}
                                                                    type=       "number"
                                                                    inputProps= {{min: 0}}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        margin="none"
                                                        name="quantity"
                                                        value={parseFloat(_.sumBy(instructions, i => parseFloat(i.parts[parts[idx].partId] || 0))).toFixed(3)}
                                                        fullWidth
                                                        disabled
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </PaddedPaper>
                </Grid>
                            */}
                <Grid item xs={12}>
                    <PaddedPaper>
                        {!!instructionsError.show &&
                            <Grid item xs={12} style={{textAlign: 'right', color: colors.red, marginBottom: '1em'}}>
                                Build Stage parts do not match sub assembly parts
                            </Grid>
                        }
                        {instructions.length == 0 &&
                            <Grid item xs={12} style={{textAlign: 'right', color: colors.red, marginBottom: '1em'}}>
                                Sub assembly requires Build Stages
                            </Grid>
                        }
                        <div className='buttonRow' style={{marginTop: 0}}>
                            <BackButton props={this.props} />
                            <Button onClick={()=>this.props.deployConfirmation('Are you sure you want to add these build stages to this sub assembly?', 'Create Sub Assembly Build Stages', this.handleSubmit)}
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.isLoading || instructionsError.show || instructions.length == 0}>
                                Update
                            </Button>
                        </div>
                    </PaddedPaper>
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {return {
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
}}

const styles = theme => ({
    paperGrey: {        
        margin: 0,
        padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px `,
        backgroundColor:'#fefefe'
    }, 
    addPart: {
        display: 'flex',
        alignItems: 'center',
        justify: 'center',
        padding: theme.spacing(1),
    },
});


export default connect(null, mapDispatchToProps)( withStyles(styles)(SubAssemblyCreateBuildStages));