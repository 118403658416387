import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import Textarea from 'Components/Common/Inputs/Textarea';
import { ReduxAvatar, UserAvatar } from 'Components/Common/Badges/Avatar';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { TROLL_IMAGE } from 'Constants';

const TrollForm = ({cancel, count, staffId, staffName, submit}) => {

    const   [reason,setReason] = useState(""),
            dispatch           = useDispatch(),
            status             = useSelector(state => state.notifications.status);

    const handleSubmit = () => {
        dispatch(deployConfirmation(`Are you sure you want to give the troll to ${staffName}?`, `Give Troll?`, () => submit(staffId, staffName, reason)));
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={3} alignItems='center'>
                    {status?.[staffId] && (
                        <Grid item>
                            {ReduxAvatar(status[staffId], {size:'lg'})}
                        </Grid>
                    )}
                    <Grid item xs>
                        <Typography variant="h4">
                            {staffName}
                        </Typography>
                        <Typography variant="h6" style={{fontStyle: 'italic'}}>
                            {(count === 0 && (
                                `It's the first troll`
                            )) || (
                                `Given the troll ${count > 1 ? `${count} times` : `once`}`
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Textarea
                    label="Reason"
                    margin="none"
                    value={reason}
                    onChange={e => setReason(e?.target?.value)}
                    variant="outlined"
                    rows={5}
                /> 
            </Grid>
            <Grid item xs={12} align="right">
                <Grid container spacing={2}>
                    <Grid item xs />
                    <Grid item>
                        <Button 
                            color="secondary"
                            onClick={cancel}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            color="primary" 
                            onClick={handleSubmit}
                            variant="contained" 
                            disabled={_.isEmpty(reason)}
                        >
                            Give Troll
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}

const StaffTroll = () => {

    const initialState = {
        key: uuidv4(),
        insight: {},
        isLoading: true,
        searchResults: []
    }

    const   [state,setState]                            = useState(initialState),
            { key, insight, isLoading, searchResults }  = state,
            dispatch                                    = useDispatch(),
            status                                      = useSelector(state => state.notifications.status);

    const handleDeployTroll = (staffId, staffName, count) => {
        dispatch(deployDialog(
            <TrollForm
                staffId={staffId}
                staffName={staffName}
                count={count}
                cancel={() => dispatch(closeDialog())}
                submit={handleGiveTroll}
            />,
            "Give Troll",
            "standard",
            "sm"
        ))
    }

    const handleGiveTroll = (staffId, staffName, reason) => {
        API.put(`/staff/troll`, 
            {
                staffId,
                reason
            }
        )
        .then(res => {
            
            if(res?.data) {

                setState(state => ({
                    ...state,
                    key: uuidv4()
                }))

                dispatch(closeDialog());
                dispatch(deploySnackBar("success", `You have successfully given the troll to ${staffName}`));

            }

        })
    }

    const handleUnassignTroll = () => {
        API.post(`/staff/troll/unassign`)
        .then(res => {
            setState(state => ({
                ...state,
                key: uuidv4()
            }))
        })
    }

    useEffect(() => {

        API.get('/staff/troll')
        .then(res => {

            if(res?.data) {

                const   isLoading       = false,
                        searchResults   = res.data.staff,
                        insight         = res.data.troll;

                setState(state => ({
                    ...state,
                    insight,
                    isLoading,
                    searchResults
                }))

            }

        });

    }, [key]);

    return (
        (isLoading && (
            <LoadingCircle />
        )) || (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h5" align='center'>
                                {insight ? 'Current Troll' : "Who's First?"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box p={3}>
                                <Grid container spacing={3} alignItems='center' justify="center">
                                    <Grid item>
                                        <Avatar src={TROLL_IMAGE} alt='Current Troll' style={{width: 400, height: 400}} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" align='center' style={{color: '#D81B60'}}>
                                            {insight?.troll?.staff_first_name ?? "I Need"} {insight?.troll?.staff_last_name ?? "A Home"}
                                        </Typography>
                                        {insight && (
                                            <>
                                                <Typography variant="h6" align='center' style={{fontStyle: 'italic'}}>
                                                    {(insight?.troll?.trolls_count === 1 && (
                                                        `It's the first troll`
                                                    )) || (
                                                        insight.st_to_staff_id ? 
                                                        `Given the troll ${insight?.troll?.trolls_count > 1 ? `${insight?.troll?.trolls_count} times` : `once`}` : 
                                                        'Troll Is Unassigned'
                                                    )}
                                                </Typography>
                                                <Typography variant="body1" align='center'>
                                                    {!!insight.st_to_staff_id && insight?.st_reason}
                                                </Typography>
                                                {!!insight.st_to_staff_id &&
                                                    <Typography variant="body1" align='center'>
                                                        <Button
                                                            color="secondary"
                                                            variant="contained"
                                                            onClick={handleUnassignTroll}
                                                        >Unassign</Button>
                                                    </Typography>
                                                }
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h5" align='center'>
                                Troll Leaderboard
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <CiDataTable  
                                    config={{
                                        key: 'staff_id',
                                        pagination: true,
                                        alternatingRowColours: true,
                                        responsiveImportance: true
                                    }}
                                    columns={[
                                        {
                                            heading: '',
                                            field: rowData => {
                                                if(status) {
                                                    if(status?.[rowData.staff_id]) {
                                                        return ReduxAvatar(status[rowData.staff_id])
                                                    } else {
                                                        return UserAvatar(rowData)
                                                    }
                                                } else {
                                                    return UserAvatar(rowData)
                                                }
                                            },
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Name',
                                            field: rowData => (
                                                <React.Fragment>
                                                    {rowData.staff_first_name} {rowData.staff_last_name}
                                                </React.Fragment>
                                            ),
                                            dataRef: 'staff_first_name',
                                            important: true,
                                            main: true,
                                            cellProps: rowData => ({
                                                style: {
                                                    color: status?.[rowData.staff_id]?.troll === 1 ? '#D81B60' : undefined
                                                }
                                            })
                                        },
                                        {
                                            heading: 'Trolls',
                                            field: rowData => rowData.trolls_count,
                                            dataRef: 'trolls_count',
                                            important: true,
                                            sizeToContent: true
                                        },
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Give Troll', icon: 'alien-monster', onClick: () => handleDeployTroll(rowData.staff_id, `${rowData.staff_first_name} ${rowData.staff_last_name}`, rowData.trolls_count)}
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={searchResults}
                                />
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    )
}

export default StaffTroll;