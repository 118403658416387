import classNames  from 'classnames';
import moment      from 'moment';
import PropTypes   from 'prop-types';
import React       from 'react';
import { connect } from 'react-redux';

import { AppBar, CssBaseline, Grid, Toolbar, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

import { closePopNotifications } from 'Actions/PopNotification/PopNotification';
import { clearPersistence }      from 'Actions/StatePersistence/StatePersistence';

import AllIcon     from 'Components/Common/Icons/AllIcon';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';

import WarehouseLoadingBar from './WarehouseLoadingBar';
import WarehouseUser       from './WarehouseUser';

import { handleAppError } from 'Actions/UI/UI';

import { clearCookies, clearLastActivePage, clearToken, setLastActivePage } from 'Functions/AuthFunctions';


import icons from 'Helpers/IconHelper';

const drawerWidth = 285;

const styles = theme => ({
    toolbar: {
        paddingTop: 'calc(env(safe-area-inset-top) + 5px)',
        paddingRight: theme.spacing(3), // keep right padding when drawer closed
        backgroundColor: '#0282C6',
    },
    appBar: {
        position: 'fixed',
        top: 0,
        webkitOverflowScrolling: 'touch',
        webkitTransform: 'translateZ(0)',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: '0 4px 2px -2px rgba(0,0,0,0.2)'
    },  
    notificationBar: {
        paddingTop: 'calc(env(safe-area-inset-top) + 5px)',
        paddingRight: theme.spacing(3), // keep right padding when drawer closed
    },
    notificationAppBar: {
        position: 'fixed',
        top: 68,
        webkitOverflowScrolling: 'touch',
        webkitTransform: 'translateZ(0)',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: '0 4px 2px -2px rgba(0,0,0,0.2)'
    },
    appBarShift: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 5,
        marginRight: 35,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
    h5: {
        marginBottom: theme.spacing(2),
    },
    quicklinks: {
        marginRight: 10
    },
    avatar: {
        width: 40,
        height: 40,
    },
    avatarSpacer: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    iconButton: {
        marginLeft: 10
    },
    dropDown: {
        marginTop: 52
    },
    dropDownItem: {
        fontSize: 13,
        padding: theme.spacing(1.5, 2)
    },
    search: {
        paddingRight: 24,
        borderRight: '1px solid rgba(255,255,255,0.5)'
    },
    userContainer: {
        marginLeft: theme.spacing(2)
    },
    [theme.breakpoints.down('md')]: {
        search: {
            display: 'none'
        }
    },
    [theme.breakpoints.down('sm')]: {
        appBarShift: {
            display: 'none',
        },
        menuButton: {
            marginLeft: 5,
            marginRight: 20,
        },
        dropDown: {
            marginTop: window.matchMedia('(display-mode: standalone)').matches ? (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform) ? `calc(env(safe-area-inset-top) + 15px)` : 45) : 45,
        },
        toolbar: {
            paddingRight: theme.spacing(2),
        },    
        iconButton: {
            marginLeft: 0
        },
        notificationAppBar: {
            top: 'calc(env(safe-area-inset-top) + 60px)',
        }
    },
    [theme.breakpoints.up('sm')]: {
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    [theme.breakpoints.down('xs')]: {
        menuButton: {
            marginLeft: 3,
            marginRight: 3,
        },
    },    
    ['@media (orientation: landscape) and (max-height: 767px)']: { // eslint-disable-line no-useless-computed-key
        dropDown: {
            marginTop: 53,
        },
    }
});

const initialState = {
    anchorEl: null,
    completed: 0,
    buffer: 10,
    accessIconCustomer: false,
    accessIconPart: false,
    accessIconSupplier: false,
    accessIconDocument: false,
    accessIconSales: false,
    accessIconReminders: false,
    accessIconTasks: false,
    scheduledUpdateColor: '#FFF',
    scheduledUpdateBackgroundColor: '#c62828',
    scheduledUpdateTime: false,
    scheduledUpdateTimeCountdown: false,
}

class WarehouseHeader extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.countdown = false;
    }

    componentDidMount = () => {      
        this.setState({
            scheduledUpdateTime: this.props.ui.updateNotification
        }, () => {
            if(this.state.scheduledUpdateTime !== false) {
                this.startScheduledUpdateTime();
            }
        });
    }

    componentWillUnmount = () => {
        if(this.countdown)       
            clearInterval(this.countdown);
    }
    
    componentDidUpdate = (prevProps) => {
        if(this.props.ui.updateNotification !== prevProps.ui.updateNotification) {
            this.setState({
                scheduledUpdateTime: this.props.ui.updateNotification
            }, () => {                
                if(this.state.scheduledUpdateTime !== false) {
                    this.startScheduledUpdateTime();
                }
            });
        }        
    }

    startScheduledUpdateTime = () => {
        this.countdown = setInterval(this.getScheduledUpdateTimeCountdown, 1000);
    }

    getScheduledUpdateTimeCountdown = () => {
        var scheduled = moment(this.state.scheduledUpdateTime, 'X').subtract(10, 'seconds');
        const now = moment();
        if(now.unix() < scheduled.unix()) {
            const countdown = moment(scheduled - now);
            const mins = parseInt(countdown.format('m'));
            const countdownText = mins !== 0 ? countdown.format("m [ minutes] [and] s [ seconds]") : countdown.format("s[ seconds]");
            this.setState({                
                //scheduledUpdateBackgroundColor: mins > 1 ? '#E65100' : '#C62828',
                scheduledUpdateTimeCountdown: this.state.updateNotification !== false ? countdownText : ''
            });
        } else {      
            if(this.countdown) {      
                clearInterval(this.countdown);
            }
            setLastActivePage(this.props.location.pathname);
            this.setState({                
                scheduledUpdateTimeCountdown: ''
            }, () => this.props.handleAppError("APP_UPDATE", ""))    
        }
    }
    
    handleLogout = () => {
        clearCookies();
        clearToken();
        clearLastActivePage();
        this.props.history.push('/login');
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };


    render() {
        const { classes } = this.props;
        const {  scheduledUpdateTime, scheduledUpdateColor, scheduledUpdateBackgroundColor, scheduledUpdateTimeCountdown } = this.state;

        const isMobile = this.props.ui.device.isMobile;

        return (
            <React.Fragment>
                <CssBaseline />
                <AppBar
                    position="absolute"
                    className={classes.appBar}
                >
                    <Toolbar disableGutters className={classes.toolbar}>
                        <AllIcon
                            icon={icons.home} 
                            white
                            heavy
                            onClick={()=>{
                                this.props.changePage('Dashboard', '/dashboard')
                               this.props.history.push('/dashboard');
                            }}
                        />
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            className={classes.title}
                        > 
                            {this.props.pageTitle}
                        </Typography>
                        <WarehouseUser handleLogout={this.handleLogout} />
                    </Toolbar>
                    <WarehouseLoadingBar />
                </AppBar>
                {scheduledUpdateTime !== false && scheduledUpdateTimeCountdown !== false && (
                    <AppBar
                        position="absolute"
                        className={classNames(classes.notificationAppBar, this.props.navOpen && classes.appBarShift)}
                        style={{zIndex: 9999}}
                    >
                        <Toolbar style={{height: 35, minHeight: 35, backgroundColor: scheduledUpdateBackgroundColor}} className={classes.notificationBar}>
                            <Grid container style={{paddingBottom: 3}}>
                                <Grid item style={{width: 30}}>
                                    <FALightIcon icon='exclamation-triangle' size={isMobile ? 'small' : ''} style={{color: scheduledUpdateColor}} /> 
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption" style={{color: scheduledUpdateColor}}>
                                        {(scheduledUpdateTimeCountdown !== '' && ( 
                                            <React.Fragment>
                                                {`An update ${!isMobile ? `for MyClenaware ` : ``} will begin in ${scheduledUpdateTimeCountdown} ${(!isMobile && (` - please save your work before the update starts!`)) || (``)}`}
                                            </React.Fragment>
                                        )) || (
                                            <React.Fragment>
                                                An update for MyClenaware is starting now...
                                            </React.Fragment>
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                            
                        </Toolbar>
                    </AppBar>
                )}
            </React.Fragment>
        );
    }
}

WarehouseHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
        ui: state.ui
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError:         (error, message)                => dispatch(handleAppError(error, message)),
        clearPersistence:       ()                              => dispatch(clearPersistence()),
        closePopNotifications:  ()                              => dispatch(closePopNotifications())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WarehouseHeader));