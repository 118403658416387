import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { Typography, Grid, Button, TextField, FormControl, Dialog, DialogActions, DialogContent, DialogTitle, Radio, RadioGroup,
    FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';

import PaddedPaper             from 'Components/Common/Paper/PaddedPaper';
import FALightIcon             from 'Components/Common/Icons/FontAwesome/FALightIcon';
import SnackBar                from 'Components/Common/SnackBars/SnackBar';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea                from 'Components/Common/Inputs/Textarea';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';

import StatusDataTable from '../StatusDataTable';

import { statusColour }           from 'Helpers/ColourHelper'
import { pdfFromBase64 }          from 'Helpers/PDFHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { colors }                 from 'Helpers/ColourHelper';
import icons                      from 'Helpers/IconHelper';

const initialState = () => ({
    formData: {
        sr_id: '',
        declinedNotes:'',
        approveNotes: '',
        emailChoice: 'emailSingleSupplier',
        emailText: '',
        emailTemplate: '',
        emailPhotoList: [],
        supplierName: '',
        supplierContact: '',
        supplierEmail: '',
        additionalContacts :[]
    },
    additionalContactList: [],
    showHideAdditionalContacts: false,
    keywords: '',
    templateList: [],
    ssr: {},
    searchResults: {},
    photoList: [],
    formErrors: [],
    declineDialogOpen: false,
    declineSnackbarOpen: false,
    approveDialogOpen: false,
    approveSnackbarOpen: false,
    errorSnackbarOpen: false,
    statuses: ['Approval', 'Dispatch', 'Confirmation', 'Delivery', 'Complete'],
    snackbarErrorMsg: '',
    isLoading: true
});

class SupplierStockReturnApproval extends Component {
    constructor(props) {
        super(props);
        this.state              = initialState();
    }

    componentDidMount(){
        this.loadComponentData();
    }

    loadComponentData = () => {
        this.getSearchData();
        this.getEmailTemplates();
    }

    getEmailTemplates = () => {
        API.get('/supplierStockReturns/emailTemplates')
        .then((templateRes) =>  {

            let templateList = [];

            if(templateRes.data) {
                templateList = _.map(templateRes.data, (el) => {
                    return _.assign({
                        value: el.sr_email_id,
                        label: el.sr_email_name
                    });
                });
                templateList.unshift({
                    value: 0,
                    label: 'None'
                });
            }
            this.setState({
                templateList: templateList,
                templates: templateRes.data
            });
        });
    }

    getSearchData = () => {
        this.setState({
            isLoading: true,
        }, () => {
            const params = {
                keywords: this.state.keywords,
                status: 'Approval'
            }
            API.get('/supplierStockReturns', {
                params,
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    _.map(result.data, (ssr) => {
                        ssr.colour = statusColour(ssr.sr_status);
                    });
                    this.setState({
                        searchResults: result.data,
                        isLoading: false
                    });
                }
            })
        });
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.setState({
            keywords: '',
        }, 
        () => {
            this.getSearchData();
        });
    }

    handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        },
        () => {
            this.setState({ 
                showHideAdditionalContacts : (this.state.formData.emailChoice === 'emailMultipleSuppliers' ? true : false)
            });
        });
    }

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions && selectedOptions.map(a => a.value);
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if(fieldName === 'emailTemplate'){
                let email = _.find(this.state.templates, {'sr_email_id': selectedOption && selectedOption.value });

                this.setState({
                    formData:{
                        ...this.state.formData,
                        emailText: (typeof email === 'undefined') ? '' : email.sr_email_text
                    }
                });
            }
        });
    }

    handleErrorSnackbarClose = () => {this.setState({ errorSnackbarOpen: false }); };

    // Approve
    handleApproveOpen = rowData => {
        API.get('/supplierStockReturns/' + rowData.sr_id)
        .then(result => {
            let additional = [];
            if(result.data.supplier_contacts){
                result.data.supplier_contacts.forEach(val => {
                    if(val.supp_cont_id !== result.data.sr_supplier_contact_id){
                        additional.push(val);
                    }
                });
            }
            let photos = _.filter(result.data.files, {sr_file_type: 'Photo'});
            let photoList = _.map(photos, el => {
                return _.assign({
                    value: el.sr_file_id,
                    label: el.sr_file_filename
                })
            });
            this.setState({
                approveDialogOpen: true,
                ssr: result.data,
                additionalContactList: additional,
                formData: {
                    ...this.state.formData,
                    sr_id: rowData.sr_id,
                    emailText: result.data.sr_email_text,
                    emailTemplate: result.data.sr_email_template,
                    supplierName: result.data.sr_supplier_name,
                    supplierContact: result.data.sr_supplier_contact,
                    supplierEmail: result.data.supplier_contact && result.data.supplier_contact.supp_cont_email,
                },
                formErrors: [],
                photoList
            });
        });
        
    };
    handleApproveClose = () => {this.setState({approveDialogOpen: false});};
    handleApproveSnackbarClose = () => {this.setState({ approveSnackbarOpen: false }); };
    handleApproveSuccess = () => {
        this.setState({ approveDialogOpen: false });
        this.approveSubmit();
    }
    approveSubmit = () => {
        API.post(`/supplierStockReturns/${this.state.formData.sr_id}/approve`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    approveDialogOpen: true
                });
            }
            else {
                this.setState({
                    approveSnackbarOpen: true,
                    formData: initialState().formData
                },
                () => {
                    if(result.data.emailErrors && result.data.emailErrors.length > 0){
                        this.setState({
                            errorSnackbarOpen: true,
                            snackbarErrorMsg: 'Email failed to send to the following additional contacts:' + result.data.emailErrors.join()
                        });
                    }
                });
                
                this.getSearchData();
            }
        });
    }

    // Decline
    handleDeclineOpen = rowData => {
        this.setState({
            declineDialogOpen: true,
            formData: {
                ...this.state.formData,
                sr_id: rowData.sr_id
            }
        });
    };
    handleDeclineClose = () => {this.setState({declineDialogOpen: false});};
    handleDeclineSnackbarClose = () => {this.setState({ declineSnackbarOpen: false }); };
    handleDeclineSuccess = () => {
        this.setState({ declineDialogOpen: false });
        this.declineSubmit();
    }
    declineSubmit = () => {
        API.post(`/supplierStockReturns/${this.state.formData.sr_id}/decline`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    declineDialogOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState,
                    declineSnackbarOpen: true
                });
                this.getSearchData();
            }
        });
    }

    downloadFile = rowData => {
        API.get('/supplierStockReturns/' + rowData.sr_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    handleCheckChange = (e) => {
        let newArray = [...this.state.formData.additionalContacts];

        if(e.target.checked){
            newArray.push(e.target.value);
        } else {
            newArray.splice(e.target.value, 1)
        }
        
        this.setState({
            formData: {
                ...this.state.formData,
                additionalContacts: newArray
            }
        });
      };

    render() {
        const { formErrors } = this.state;
        const  staffId  = this.props.loggedInStaff !== undefined ? this.props.loggedInStaff.id : 0;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Supplier Stock Return Approval
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={this.state.keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <StatusDataTable
                            isLoading       = {this.state.isLoading}
                            searchResults   = {this.state.searchResults}
                            persistenceId   = {this.persistenceId}
                            actions={ rowData => {
                                return rowData.locked ?
                                    [
                                        {name: 'Download', icon: 'download', onClick: this.downloadFile},
                                        {name: 'Locked',   icon: icons.lock, onClick: ()=>{}},
                                    ]:
                                    [
                                        {name: 'Download', icon: 'download', onClick: this.downloadFile},
                                        {name: 'Approve', icon: 'check', onClick: this.handleApproveOpen, disabled: rowData.sr_approval_by_id !== staffId},
                                        {name: 'Decline', icon: 'times', onClick: this.handleDeclineOpen, disabled: rowData.sr_approval_by_id !== staffId}
                                    ]
                            }}
                        />
                    </PaddedPaper>
                </Grid>
                <Dialog 
                    open={this.state.declineDialogOpen} 
                    onClose={this.handleDeclineClose} 
                    fullWidth={true} 
                    maxWidth="sm" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title" style={{backgroundColor:colors.red}}>
                        <Typography style={{color:'white', fontSize:'1.25rem'}}>
                            You Are About To <b>Decline</b> This Stock Return
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <br></br>
                        <Typography variant="body1">Please feel free to leave any notes relating to this stock return:</Typography>
                        <Textarea
                            id="declinedNotes"
                            name="declinedNotes"
                            label="Notes"
                            value={this.state.formData.declinedNotes}
                            rows={5}
                            error={formErrors && formErrors['declinedNotes']}
                            onChange={this.handleChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeclineClose} variant="outlined" color="default">Close</Button>
                        <Button onClick={this.handleDeclineSuccess} color="secondary" autoFocus variant="contained">Decline</Button>
                    </DialogActions>
                </Dialog>
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.declineSnackbarOpen}
                    onClose={this.handleDeclineSnackbarClose}
                    message='You have successfully declined this supplier stock return'
                />

                <Dialog 
                    open={this.state.approveDialogOpen} 
                    onClose={this.handleApproveClose} 
                    fullWidth={true} 
                    maxWidth="md" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title" style={{backgroundColor:colors.green}}>
                        <Typography style={{color:'white', fontSize:'1.25rem'}}>You Are About To <b>Approve</b> This Stock Return Form</Typography>
                    </DialogTitle>
                    <DialogContent>
                        {formErrors && formErrors['stockReturnDetails'] && formErrors['stockReturnDetails'].split('\n').map((item, key) => {
                            return (
                                <React.Fragment key={key}>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {item}<br/>
                                    </Typography>
                                </React.Fragment>
                            )
                        })}
                        {formErrors && formErrors.generic && (
                            <Grid item xs={12}>
                                <Typography component={"div"} style={{color: colors.red, marginBottom: 5}}>
                                    {formErrors.mainEmail}
                                </Typography>
                            </Grid>
                        )}
                        <br></br>
                        <PaddedPaper style={{backgroundColor:'#f2f2f2'}}>
                            <Typography variant="body1" gutterBottom>
                                {this.state.formData.supplierName}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {this.state.formData.supplierContact + ' - ' + this.state.formData.supplierEmail}
                            </Typography>
                        </PaddedPaper>
                        <FormControl error={formErrors && formErrors['emailTemplate'] && true} fullWidth margin="normal">
                            <AutoCompleteSelect
                                options={this.state.templateList} 
                                label='Email Template'
                                value={this.state.formData.emailTemplate}
                                onChange={this.handleSelectChange('emailTemplate')}
                                error={formErrors && formErrors['emailTemplate'] && true}
                                errorText={formErrors && formErrors['emailTemplate'] && formErrors['emailTemplate']}
                            />
                        </FormControl>
                        <Textarea
                            id="emailText"
                            name="emailText"
                            label="Email Text *"
                            value={this.state.formData.emailText}
                            rows={5}
                            error={formErrors && formErrors['emailText']}
                            onChange={this.handleChange}
                        />
                        <FormControl margin="normal" fullWidth>
                            <AutoCompleteMultiSelect 
                                options={this.state.photoList} 
                                label='Photo Attachments'
                                value={this.state.formData.emailPhotoList}
                                onChange={this.handleMultiSelectChange('emailPhotoList')}
                                error={formErrors && formErrors['emailPhotoList'] && true}
                                errorText={formErrors && formErrors['emailPhotoList']}
                            />
                        </FormControl>
                        <Typography variant="subtitle2">
                            <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum attatchment limit 10MB
                        </Typography>
                        <Textarea
                            id="approveNotes"
                            name="approveNotes"
                            label="Notes"
                            value={this.state.formData.approveNotes}
                            rows={5}
                            error={formErrors && formErrors['approveNotes']}
                            onChange={this.handleChange}
                        />
                        <RadioGroup
                            name="emailChoice"
                            value={this.state.formData.emailChoice}
                            onChange={this.handleChange}
                            row
                        >
                            <FormControlLabel
                                value="emailSingleSupplier"
                                control={<Radio color="primary" />}
                                label="Email supplier contact above"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="emailMultipleSuppliers"
                                control={<Radio color="primary" />}
                                label="Email additional supplier contacts"
                                labelPlacement="end"
                            />
                            
                            <FormControlLabel
                                value="noEmail"
                                control={<Radio color="primary" />}
                                label="Do not send email"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                        {this.state.showHideAdditionalContacts &&
                            <FormControl component="fieldset">
                                <FormGroup>
                                    {this.state.additionalContactList && this.state.additionalContactList.map(v => (
                                        <FormControlLabel key={v.supp_cont_id}
                                            control={
                                                <Checkbox 
                                                    onChange={this.handleCheckChange} 
                                                    value={v.supp_cont_email}
                                                    color='primary'
                                                />
                                            }
                                            label= {`${v.supp_cont_first_name} ${v.supp_cont_last_name} (${v.supp_cont_email})`}
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Typography variant="subtitle2">If the parts have been selected to be deducted from stock, they will be deducted once you click 'Approve'</Typography>
                        <Button onClick={this.handleApproveClose} variant="outlined" color="default">Close</Button>
                        <Button onClick={this.handleApproveSuccess} style={{backgroundColor:colors.green, color:'white'}} autoFocus variant="contained">Approve</Button>
                    </DialogActions>
                </Dialog>
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.approveSnackbarOpen}
                    onClose={this.handleApproveSnackbarClose}
                    message='You have successfully approved this supplier stock return'
                />
                <SnackBar
                    variant="error"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='left'
                    open={this.state.errorSnackbarOpen}
                    onClose={this.handleErrorSnackbarClose}
                    message={this.state.snackbarErrorMsg}
                />
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    loggedInStaff:      state.staffAuth.staff
})

export default connect(mapStateToProps, null)(SupplierStockReturnApproval);