import React,{ useEffect, useRef, useState } from 'react';
import { CSVLink }                           from "react-csv";
import _                                     from 'lodash';

import Button           from '@material-ui/core/Button';

export default function(props){

    const csvLink = useRef()
    const [show, setShow] = useState(true)

    const getLabels = () => {
        let labels = [];
        _.each(props.data, i => {
            _.each(i, (bar,foo) => {
                if (!_.find(labels, {key: foo} )) labels.push({ key: foo, label: foo.charAt(0).toUpperCase() + foo.slice(1)})
            });
        });
        return labels;
    }

    useEffect(() => {
        setShow(false);
        setTimeout(() => {
            setShow(true);
        }, 100);
    }, [props.data])
    
    return (
        <Button {...props} onClick={()=>{csvLink.current.link.click()}} disabled={props.disabled || !show}>
            {props.children || 'Download CSV'}
            {show &&
                <CSVLink 
                    data={props.data} 
                    label={props.labels || getLabels()} 
                    filename={props.filename || Date.now()}
                    className='hidden'
                    ref={csvLink}
                    target='_blank'
                />
            }
        </Button>
    )
}