import React, { Component } from "react";
import _                    from 'lodash';
import Webcam               from "react-webcam";

import "react-html5-camera-photo/build/css/index.css";

import { Dialog, Slide, IconButton, Typography, DialogContent, Grid, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import FAIcon        from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";

import { colors } from "Helpers/ColourHelper";

const styles = (theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        flex: 1,
        color: "#fff",
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = () => ({
    imgUri: "",
    isLoading: true,
    facingMode: "environment",
    devices: [],
    currentDevice: null,
});

class CameraDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount = () => {
        this.setState({ mounted: true })
        navigator.mediaDevices.enumerateDevices().then(this.getDevises);
    };

    getDevises = (devices) => {
        let videoDevices = devices.filter((device) => device.kind === "videoinput");
        this.setState({ 
            devices: videoDevices,
            currentDevice: videoDevices[0].deviceId
        });
    }

    handlePhoto = (imgUri) => {
        this.setState({
            imgUri,
        });
    };

    resetPhoto = () => {
        this.setState({
            imgUri: initialState().imgUri,
        });
    };

    savePhoto = () => {
        this.props.save(this.state.imgUri);
        this.resetPhoto();
    };

    cameraReady = () => {
        this.setState({
            isLoading: false,
        });
    };

    toggleFacingMode = () => {
        this.setState({
            facingMode: this.state.facingMode === "user" ? "environment" : "user",
        });
    };

    toggleDevice = () => {
        const { devices, currentDevice } = this.state;
        let index = _.findIndex(devices, {deviceId: currentDevice});
        index += 1;
        if ( index > devices.length - 1) {
            index = 0;
        }
        this.setState({
            currentDevice: devices[index].deviceId
        })
    }

    render() {
        const { title } = this.props;

        const { imgUri, isLoading, facingMode, currentDevice } = this.state;

        const videoConstraints = {
            width: 1920,
            height: 1080,
            facingMode: 'environment',
            resizeMode: "crop-and-scale",
            isImageMirror: facingMode === "user",
        };

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.close}
                TransitionComponent={Transition}
                transitionDuration={300}
                scroll="body"
                maxWidth="lg"
            >
                {this.props.open && (
                    <DialogContent
                        style={{
                            overflow: "hidden",
                            minHeight: "50vh",
                            width: "auto",
                            paddingBottom: 48,
                            paddingTop: 24,
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} align="center">
                                <Typography variant="h5">
                                    <FAIcon icon="camera" size={24} />
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{padding: '1.5em'}}>
                                <Grid container spacing={3} style={{justifyContent: 'flex-end'}}>
                                    {isLoading && (
                                        <Grid item xs={12} align="center">
                                            <LoadingCircle />
                                        </Grid>
                                    )}
                                    {imgUri && (
                                        <>
                                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                                <img
                                                    src={imgUri}
                                                    style={{
                                                        border: "3px solid rgba(180, 180, 180, 0.5)",
                                                        maxHeight: "70vh",
                                                        width: '100%'
                                                    }}
                                                    alt=""
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                                <IconButton
                                                    onClick={this.resetPhoto}
                                                    style={{
                                                        border: `3px solid ${colors.red}`,
                                                        backgroundColor: "#ff",
                                                        marginRight: 16,
                                                    }}
                                                >
                                                    <FAIcon
                                                        icon="times"
                                                        type="solid"
                                                        size={24}
                                                        noMargin
                                                        color={colors.red}
                                                    />
                                                </IconButton>
                                                <IconButton
                                                    onClick={this.savePhoto}
                                                    style={{
                                                        border: `3px solid ${colors.green}`,
                                                        backgroundColor: "#fff",
                                                    }}
                                                >
                                                    <FAIcon
                                                        icon="check"
                                                        type="solid"
                                                        size={24}
                                                        noMargin
                                                        color={colors.green}
                                                    />
                                                </IconButton>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        <div style={{ position: "relative", display: "block", textAlign: 'center', width: '100%'}}>
                                            <Webcam
                                                audio={false}
                                                screenshotFormat="image/jpeg"
                                                width={"100%"}
                                                videoConstraints={{...videoConstraints, deviceId: currentDevice}}
                                                imageSmoothing={true}
                                                style={{
                                                    border: "3px solid rgba(180, 180, 180, 0.5)",
                                                    display: (isLoading || imgUri ) && "none",
                                                    maxHeight: "70vh",
                                                    width: '100%'
                                                }}
                                                onUserMedia={this.cameraReady}
                                            >
                                                {({ getScreenshot }) =>
                                                    isLoading || imgUri ? (
                                                        <></>
                                                    ) : (
                                                        <>
                                                            <Box style={{ marginTop: -80, textAlign: "center" }}>
                                                                <IconButton
                                                                    onClick={this.toggleDevice}
                                                                    style={{
                                                                        border: "3px solid rgba(255, 255, 255, 0.8)",
                                                                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                                                                    }}
                                                                >
                                                                    <FAIcon
                                                                        icon="sync"
                                                                        size={24}
                                                                        noMargin
                                                                        color={"#fff"}
                                                                    />
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        const imageSrc = getScreenshot();
                                                                        this.handlePhoto(imageSrc);
                                                                    }}
                                                                    style={{
                                                                        border: "3px solid rgba(255, 255, 255, 0.8)",
                                                                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                                                                        marginLeft: 16,
                                                                    }}
                                                                >
                                                                    <FAIcon
                                                                        icon="camera"
                                                                        size={24}
                                                                        noMargin
                                                                        color={"#fff"}
                                                                    />
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={this.props.close}
                                                                    style={{
                                                                        border: "3px solid rgba(255, 255, 255, 0.8)",
                                                                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                                                                        marginLeft: 16,
                                                                    }}
                                                                >
                                                                    <FAIcon
                                                                        icon="times"
                                                                        size={24}
                                                                        noMargin
                                                                        color={"#fff"}
                                                                    />
                                                                </IconButton>
                                                            </Box>
                                                        </>
                                                    )
                                                }
                                            </Webcam>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                )}
            </Dialog>
        );
    }
}

export default withStyles(styles)(CameraDialog);