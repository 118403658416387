import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DatePicker from '../../Common/DatePickers/DatePicker';
import moment from 'moment';
import API from './../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import SnackBar from '../../Common/SnackBars/SnackBar';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Textarea from '../../Common/Inputs/Textarea';
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData: {
        title: '',
        description: '',
        part: '',
        affectedFiles: '',
        detailsOfPartChange: '',
        specialInstructions: '',
        poi: moment().toString(),
        approvedBy: '',
        currentPdfVersion: 0,
        currentDesignVersion: 0,
        is3DPrinted: 'No',
        qtyOf3DParts: ''
    },
    newPdfVersion: '-',
    newDesignVersion: '-',
    affectedFilesDisabled: false,
    poiDisabled: false,

    staffList: [],
    partList: [],
    parts: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
}

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    }
}))(TableCell);

class AddDesignChangeOrder extends Component {
    constructor(props) {
        super(props);
    
        this.state = initialState;
    }

    componentDidMount(){
        this.getStaff();
        this.getParts();
    }

    getParts = () => {        
        API.get('/parts/all' , {params: {active: true, withSubAssembly: true}})
        .then((result) => {
            let partList = _.map(_.filter(result.data, i => i.sub_assembly_top_level && !i.part_outsourced_subassembly ? (i.sub_assembly_top_level.instruction ? true : false) : true), (el) => {
                return _.assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description
                });
            });
            this.setState({
                partList: partList,
                parts: result.data
            });
        })
    }

    getStaff = () => {
        API.get('/staff/all', { params: { active: true } })
        .then(result => {
            if(result.data) {
                let staffList = _.map(result.data, (el) => {
                    return _.assign({
                        value: el.staff_id,
                        label: el.staff_first_name + ' ' + el.staff_last_name
                    });
                });
                this.setState({
                    staffList: staffList
                });
            }
        });
    }

    handleDateChange = date => {
        this.setState({
            formData: {
                ...this.state.formData,
                poi: date
            }
        });
    };

    handleChange = fieldName => e => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        },
        () => {
            if(fieldName === 'is3DPrinted'){
                if(this.state.formData.is3DPrinted === 'No'){
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            qtyOf3DParts: ''
                        }
                    });
                }
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false }); 
    };

    submit = () => {
        const formData = {
            ...this.state.formData,
            poi: (moment(new Date(this.state.formData.poi)).format('DD/MM/YYYY'))
        }
        API.post('/researchDevelopment/changeOrders', formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState(initialState);
                this.setState({
                    snackbarOpen: true
                });
                this.getStaff();
                this.getParts();
            }
            this.props.scrollToTop();
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    getDCOVersion = (partID) => {
        if(partID) {
            let part = _.find(this.state.parts, { 'part_id': partID });

            API.get(`/researchDevelopment/changeOrders/part/${partID}/files/version/latest`)
            .then(result => {
                if(result.data) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            affectedFiles: result.data.affectedFiles,
                            currentPdfVersion: result.data.pdfIssue ? result.data.pdfIssue : 0,
                            currentDesignVersion: result.data.designIssue ? result.data.designIssue : 0,
                            poi: !result.data.pdfIssue && !result.data.designIssue ? moment(new Date(part.part_date_added)).toString() : moment().toString(),
                            is3DPrinted: result.data.is3DPrinted === 'Yes' ? 'Yes' : 'No',
                            qtyOf3DParts: result.data.noOf3DParts
                        },
                        affectedFilesDisabled: result.data.affectedFiles ? true : false,
                        poiDisabled: !result.data.pdfIssue && !result.data.designIssue ? true : false,
                        newPdfVersion: (result.data.affectedFiles !== 'Design Only') ? (result.data.pdfIssue ? result.data.pdfIssue + 1 : 1): '-',
                        newDesignVersion: (result.data.affectedFiles !== 'PDF Only') ? (result.data.designIssue ? result.data.designIssue + 1 : 1): '-'
                    });
                }
            });
        } else {
            this.setState({
                affectedFiles: initialState.formData.affectedFiles
            });
        }
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, 
        () => {
            if(fieldName === 'part'){
                this.setState({
                    formData: {
                      ...this.state.formData,
                      affectedFiles: null,
                      currentPdfVersion: initialState.formData.currentPdfVersion,
                      currentDesignVersion: initialState.formData.currentDesignVersion,
                      poi: initialState.formData.poi
                    },
                    newPdfVersion: initialState.formData.newPdfVersion,
                    newDesignVersion: initialState.formData.newDesignVersion,
                },
                () => {
                    this.getDCOVersion(selectedOption && selectedOption.value);
                });
            }
            else if(fieldName === 'affectedFiles'){
                if(this.state.formData.part && selectedOption && selectedOption.value){
                    switch(selectedOption.value) {
                        case 'Both':
                            this.setState({
                                newPdfVersion: 1,
                                newDesignVersion: 1
                            });
                        break;
                        case 'PDF Only':
                            this.setState({
                                newPdfVersion: 1,
                                newDesignVersion: '-'
                            });
                        break;
                        case 'Design Only':
                            this.setState({
                                newPdfVersion: '-',
                                newDesignVersion: 1
                            });
                        break;
                        default:
                        break;
                    }
                }
            }
        });
    };
       
    render() {
        const { formErrors } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Design Change Order
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Main Details
                            </Typography>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <form noValidate autoComplete="off">
                                <TextField
                                    id="title"
                                    name="title"
                                    label="Title *"
                                    value={this.state.formData.title}
                                    error={formErrors && formErrors['title'] && true}
                                    helperText={formErrors && formErrors['title']}
                                    onChange={this.handleChange('title')}
                                    margin="normal"
                                    fullWidth
                                />
                                <Textarea
                                    id="description"
                                    name="description"
                                    label="Description *"
                                    value={this.state.formData.description}
                                    rows={5}
                                    error={formErrors && formErrors['description']}
                                    onChange={this.handleChange('description')}
                                />
                                <Typography variant="h6" style={{marginTop:24}} gutterBottom>
                                    Affected Parts
                                </Typography>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.partList} 
                                        label='Part Number *'
                                        onChange={this.handleSelectChange('part')}
                                        error={formErrors && formErrors['part'] && true}
                                        errorText={formErrors && formErrors['part'] && formErrors['part']}
                                        value={this.state.formData.part}
                                    />
                                    <Typography variant='body2' style={{color: colors.red}}>Please Note: Sub assemblies without build stages have been removed from this list.</Typography>
                                    
                                </FormControl>
                                <FormControl error={formErrors && formErrors['affectedFiles'] && true} fullWidth margin="normal">
                                    <AutoCompleteSelect
                                        options={[
                                            {value: 'Both', label: 'Both'},
                                            {value: 'PDF Only', label: 'PDF Only'},
                                            {value: 'Design Only', label: 'Design Only'}
                                        ]}
                                        label='Affected File(s) *'
                                        value={this.state.formData.affectedFiles}
                                        onChange={this.handleSelectChange('affectedFiles')}
                                        error={formErrors && formErrors['affectedFiles'] && true}
                                        errorText={formErrors && formErrors['affectedFiles'] && formErrors['affectedFiles']}
                                        disabled={this.state.affectedFilesDisabled}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell colSpan={2}>Current Issue</CustomTableCell>
                                                <CustomTableCell colSpan={2}>New Issue</CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>PDF</TableCell>
                                                <TableCell>DF</TableCell>
                                                <TableCell>PDF</TableCell>
                                                <TableCell>DF</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>{this.state.formData.currentPdfVersion > 0 ? 'v'+this.state.formData.currentPdfVersion : '-'}</TableCell>
                                                <TableCell>{this.state.formData.currentDesignVersion > 0 ? 'v'+this.state.formData.currentDesignVersion : '-'}</TableCell>
                                                <TableCell>{this.state.newPdfVersion > 0 ? 'v'+this.state.newPdfVersion : '-'}</TableCell>
                                                <TableCell>{this.state.newDesignVersion > 0 ? 'v'+this.state.newDesignVersion : '-'}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </FormControl>
                                <br></br> <br></br>
                                <Typography variant="body1" style={{fontSize:'1.1rem'}}>
                                    Is This Part 3D Printed?
                                </Typography>
                                <FormControl component="fieldset">
                                    <RadioGroup 
                                        name="is3DPrinted" 
                                        value={this.state.formData.is3DPrinted} 
                                        onChange={this.handleChange('is3DPrinted')} 
                                        row
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio color="primary" />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio color="primary" />}
                                            label="No"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {this.state.formData.is3DPrinted === 'Yes' && (
                                    <FormControl margin="normal" fullWidth>
                                        <AutoCompleteSelect 
                                            options={[
                                                {value: '01', label: '01'},
                                                {value: '02', label: '02'},
                                                {value: '03', label: '03'},
                                                {value: '04', label: '04'},
                                                {value: '05', label: '05'},
                                                {value: '06', label: '06'},
                                                {value: '07', label: '07'},
                                                {value: '08', label: '08'},
                                                {value: '09', label: '09'},
                                                {value: '10', label: '10'},
                                            ]} 
                                            label='Number Of 3D Parts / Files'
                                            value={this.state.formData.is3DPrinted === 'No' ? '' : this.state.formData.qtyOf3DParts}
                                            onChange={this.handleSelectChange('qtyOf3DParts')}
                                            disabled={this.state.formData.is3DPrinted === 'No'}
                                        />
                                    </FormControl>
                                )}
                                <Textarea
                                    id="detailsOfPartChange"
                                    name="detailsOfPartChange"
                                    label="Details Of Part Change *"
                                    value={this.state.formData.detailsOfPartChange}
                                    rows={10}
                                    error={formErrors && formErrors['detailsOfPartChange']}
                                    onChange={this.handleChange('detailsOfPartChange')}
                                />
                                <Typography variant="h6" style={{marginTop:24}} gutterBottom>
                                    Other Details
                                </Typography>
                                <Textarea
                                    id="specialInstructions"
                                    name="specialInstructions"
                                    label="Special Instructions"
                                    value={this.state.formData.specialInstructions}
                                    rows={5}
                                    error={formErrors && formErrors['specialInstructions']}
                                    onChange={this.handleChange('specialInstructions')}
                                />
                                <DatePicker
                                    type="date"
                                    id="poi"
                                    name="poi"
                                    label="Point Of Introduction *"
                                    value={this.state.formData.poi}
                                    errorText={formErrors && formErrors['poi']}
                                    onChange={this.handleDateChange}
                                    disableFuture={true}
                                    disabled={this.state.poiDisabled}
                                />
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.staffList} 
                                        label='Approved By *'
                                        onChange={this.handleSelectChange('approvedBy')}
                                        error={formErrors && formErrors['approvedBy'] && true}
                                        errorText={formErrors && formErrors['approvedBy']}
                                        value={this.state.formData.approvedBy}
                                    />
                                </FormControl>
                            </form>
                            {formErrors && formErrors['part_locked'] &&
                                <Grid container xs={12} style={{paddingTop:'1em'}}>
                                    <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>
                                        This part is locked, please try again later.
                                    </Grid>
                                </Grid>
                            }
                            <div className='buttonRow'>
                                <Button onClick={this.handleConfirmationOpen}
                                        variant="contained"
                                        color="primary"
                                       >
                                    Add
                                </Button>
                            </div>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Add A New Design Change Order?" 
                    message="Are you sure you want to add this design change order?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added a new design change order'
                />
            </Grid>
        );
    }
}

export default AddDesignChangeOrder;