import React, { Component }                                          from 'react';
import { connect }                                                   from 'react-redux';
import _                                                             from 'lodash';
import moment                                                        from 'moment';
import API                                                           from 'API';
import { DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE } from 'Constants';

import { colors, statusColour }                      from 'Helpers/ColourHelper';
import icons                                         from 'Helpers/IconHelper';
import { orderPaymentMethodIcons, orderStatusIcons } from 'Helpers/SalesHelper';

import DataTable                   from 'Components/Common/DataTables/CiDataTable';
import AllIcon                     from 'Components/Common/Icons/AllIcon';
import FALightIcon                 from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon                 from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import PaddedPaper                 from 'Components/Common/Paper/PaddedPaper';
import SnackBar                    from 'Components/Common/SnackBars/SnackBar';
import StickyNotes                 from 'Components/Common/StickyNotes/StickyNotes';
import OrderSearchBlock            from 'Components/Sales/Order/OrderSearch/OrderSearchBlock';
import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog    from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';

import { Grid, IconButton, Link, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { downloadS3File }                                               from 'Functions/MiscFunctions';
import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';

import { closeDialog, deployDialog }        from 'Actions/Dialog/Dialog';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

const styles = () => ({
    card: {
        display: 'flex', 
        justifyContent:'center', 
        alignItems:'center', 
        textAlign:'left'
    },
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }
});

const initialState = () => ({
    searchCriteria: {
        keywords:   '',
        status:     'All',
        dateFrom:   null,
        dateTo:     null,
        site:       'All',
    },
    access: {
        viewQuotation:  false
    },
    gridPagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    },
    quote:              {},
    searchResults:      {},
    isLoading:          true,
    subLoading:         true,
    emailDialogOpen:    false,
    customerName:       '',
    sites:              null
});
class OrderSearch extends Component {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `Customer:Orders:${this.props.cid}`;
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        this.getCustomerSites();
        this.getSearchData();
    }

    
    getSearchData = () => {
        this.setState({subLoading: true},()=>{
            let customerId = this.props.cid;
            let addressId  = this.props.aid;
            const params = {
                keywords: this.state.searchCriteria.keywords,
                status  : this.state.searchCriteria.status,
                dateFrom: this.state.searchCriteria.dateFrom !== null   ? moment(this.state.searchCriteria.dateFrom).format('YYYY-MM-DD')   : null,
                dateTo  : this.state.searchCriteria.dateTo !== null     ? moment(this.state.searchCriteria.dateTo).format('YYYY-MM-DD')     : null,
                site    : this.state.searchCriteria.site === 'All'      ? addressId                                                         : this.state.searchCriteria.site,
                slim    : 1,
            }
            Promise.all([
                API.get('/staff/my/access/check/customer-order-search'),
                API.get('/staff/my/access/check/view-customer-order'),
                API.get('/staff/my/access/check/repeat-customer-order'),
                API.get(`/customers/${customerId}/sales/orders`, {params}),
            ])
            .then(([searchAccessRes, viewAccessRes, repeatOrderRes, searchRes]) => {
                let customer = _.first(searchRes.data, {'order_cust_id': customerId});
                _.map(searchRes.data, o => {
                        // Status Colour
                        o.color = statusColour(o.s);
                        o.icon = orderStatusIcons(o.s);

                        switch(o.s){
                            case 'Awaiting Approval':
                                o.toolTip1 = 'Order Acknowledgment';
                                o.toolTip2 = 'Awaiting Approval';
                            break;
                            case 'Confirmed':
                                o.toolTip1 = 'Order Confirmed'
                            break;
                            case 'Awaiting Payment':
                                o.toolTip1 = 'Awaiting Payment';
                            break;
                            case 'Payment Confirmed':
                                o.toolTip1 = 'Payment Confirmed';
                            break;
                            case 'Picking':
                                o.toolTip1 = 'Awaiting Picking';
                            break;
                            case 'Awaiting Despatch':
                                o.toolTip1 = 'Awaiting Despatch';
                            break;
                            case 'Invoice':
                                o.toolTip1 = 'Awaiting Invoice';
                            break;
                            case 'Completed':
                                o.toolTip1 = 'Order Completed';
                            break;
                            case 'Declined':
                                o.toolTip1 = 'Order Declined';
                            break;
                            case 'Cancelled':
                                o.toolTip1 = 'Order Cancelled';
                            break;
                            case 'Awaiting Invoice Completion':
                                o.toolTip1 = 'Awaiting Invoice Completion';
                            default:
                            break;
                        }

                        _.map(o.in, i => {
                            i.color     = (i.c) ? colors.green : colors.yellow;
                            i.toolTip   = (i.m === 'Card') ? 'Barclaycard' : i.m;
                            i.icon      = orderPaymentMethodIcons(i.m);
                        }); 
                });
                
                this.setState({
                    ...this.state,
                    access: {
                        customerOrderSearch: ( searchAccessRes.data && searchAccessRes.data.has_access ) || false,
                        viewOrder:           ( viewAccessRes.data   && viewAccessRes.data.has_access )   || false,
                        repeatOrder:         ( repeatOrderRes.data  && repeatOrderRes.data.has_access )  || false
                    },
                    searchResults:  searchRes.data,
                    customerName:   customer && customer.order_cust_name,
                    subLoading:     false,
                },()=>{this.savePageState()});
            });
        });
    }

    handleSearchKeyword = (e) => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                keywords: e.target.value
            },
            //gridPagination: initialState.gridPagination
        }, 
        () => {
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.setState({
            searchCriteria:initialState().searchCriteria,
            //gridPagination: initialState.gridPagination
        }, 
        () => {
            //this.props.history.replace();
            this.getSearchData();
        });
    }

    handleDownloadOpen = orderId => this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md');

    handleEmailOpen = orderId => this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />, 'Email Previous Documents', '', 'md');

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                [fieldName]: selectedOption && selectedOption.value
            },
            //gridPagination: initialState().gridPagination
        }, 
        () => {
            this.getSearchData();
        });
    };

    handleDateChange = name => date => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                [name]: date
            },
            //gridPagination: initialState().gridPagination
        }, 
        () => {
            this.getSearchData();
        });
    };

    getCustomerSites = () => {
        API.get(`/customers/${this.props.cid}`)
        .then(res => {
            this.setState({
                sites: [
                    {value: 'All', label:'All'},
                    {   value: res.data.registered_address.address_id, 
                        label:  res.data.registered_address.address_postcode + ' | ' + res.data.registered_address.address_name 
                    },
                    ..._.map(res.data.trading_addresses, i => {
                        return _.assign({
                            value: i.address_id,
                            label: i.address_postcode + ' | ' + i.address_name  
                        })
                     }),
                    ..._.map(res.data.account_addresses, i => {
                        return _.assign({
                            value: i.address_id,
                            label: i.address_postcode + ' | ' + i.address_name  
                        })
                     }),
                    ..._.map(res.data.site_addresses, i => {
                        return _.assign({
                            value: i.address_id,
                            label: i.address_postcode + ' | ' + i.address_name  
                        })
                     })
                    ]
            })
        })
    }

    handleShowStickyNote = orderId => { this.props.deployDialog(<StickyNotes orderId={orderId} dialog/>, 'Sticky Notes', null,'xs') }

    handleSetSearch = props => {
        this.setState({
            searchCriteria: {...props}
        },this.getSearchData)
    }

    render() {
        const { access, customerName, statusStats, isLoading, searchCriteria, gridPagination, subLoading, sites,  } = this.state;
        const { classes } = this.props;
        return (
            <PaddedPaper style={{marginBottom: -12}}>
                <Grid container style={{margin: !this.props.noMargin && '1em'}}>
                    <Grid item container xs={12}>
                        <Grid item xs={12} lg={12}>
                            <OrderSearchBlock noPad={this.props.disablePaper} disableSites={this.props.aid} sites={this.state.sites} callBack={this.handleSetSearch} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{paddingTop: '1.5em'}}>
                        <DataTable  
                            config={{
                                key: 'order_id',
                                pagination: true,
                                page: gridPagination.page,
                                rowsPerPage: gridPagination.rowsPerPage,
                                gridPageChanged: this.gridPageChanged,
                                gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                alternatingRowColours: true,
                                responsiveimportant: true,
                                isLoading: subLoading,
                            }}
                            columns={[
                                {
                                    heading: 'Order Date',
                                    field: rowData => moment(rowData.d).format("DD/MM/YYYY"),
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    actionsCustomHeader:    true,
                                    important:              true,
                                    alignment:              'left',
                                    heading:                'Reference',
                                    actions: rowData => {
                                        let ret = [
                                            { label: rowData.r,         link: `/sales/order/view/${rowData.i}`,         type:'plainLink'},
                                            { label: rowData.q,         link: `/sales/quotation/view/${rowData.qi}`,    type:'plainLink', class: "textSecondary"}
                                        ];
                                        if (rowData.crn.length > 0) {
                                            _.each(rowData.crn, r => {
                                                ret.push({
                                                    label: r.r,
                                                    link: `/returns/creditnote/${r.i}`,
                                                    type:'plainLink', 
                                                    color: colors.red
                                                })
                                            })
                                        }
                                        if (rowData.cr.length > 0) {
                                            _.each(rowData.cr, r => {
                                                ret.push({
                                                    label: r.r,
                                                    link: `/returns/customerReturn/${r.i}`,
                                                    type:'plainLink', 
                                                    color: colors.red
                                                })
                                            })
                                        }
                                        return ret;
                                    }
                                },
                                {
                                    heading: 'Invoice(s)',
                                    field:   rowData => _.map(rowData.inv, i =><span>{i.n} {i.si > 0 && `(${i.si})`}<br/></span>),
                                    truncate: true,
                                },
                                {
                                    heading: 'Discount',
                                    field: rowData => rowData.t === 'With Discount',
                                    important: true,
                                    fieldFormat: 'boolean',
                                    tooltip: rowData => rowData.t,
                                    sizeToContent: true,
                                    alignment: 'center'
                                },
                                {
                                    heading: 'Delivery Address',
                                    field: 'dan',
                                    info: rowData =>   <div>
                                                            <Typography variant="body2">
                                                                <b>Delivery Address:</b><br />
                                                                {rowData.da.split('\n').map((item, key) => {
                                                                    return  <span key={key}>
                                                                                {item}{rowData.da.split('\n').length === key+1 ? null : <br/>}
                                                                            </span>
                                                                })}
                                                            </Typography>
                                                        </div>,
                                    important: true,
                                    truncate: true,
                                },
                                {
                                    heading: 'Status',
                                    field: rowData =>   <>
                                                            <Tooltip
                                                                title={
                                                                    <div>
                                                                        <Typography variant="body2">{rowData.toolTip1}</Typography>
                                                                        <Typography variant="body2">{rowData.toolTip2}</Typography>
                                                                    </div>
                                                                }
                                                                placement="left"
                                                            >
                                                                <IconButton
                                                                    className={classes.iconButton}
                                                                >
                                                                    <FALightIcon 
                                                                        icon={rowData.icon}
                                                                        button
                                                                        style={{color: rowData.color}}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>,
                                    important: true
                                },
                                {
                                    heading: 'Files',
                                    field: rowData => 
                                        <Grid container style={{width:'100%'}} spacing={1} xs={12} alignItems='flex-end' justify="center">
                                            {!!rowData.f.ej && 
                                                <Grid item xs={12} lg={6}>
                                                    <AllIcon 
                                                        icon='user-hard-hat'  
                                                        button 
                                                        noMargin
                                                        tooltip='Engineer Job Sheet'
                                                        onClick={() => {downloadS3File(rowData.f.ej)}}
                                                    />
                                                </Grid>
                                            }
                                            {!!rowData.f.po && 
                                                <Grid item xs={12} lg={6}>
                                                    <AllIcon 
                                                        icon={(rowData.f.po.includes('.wav') || rowData.f.po.includes('.x-wav')) ? 'cassette-tape' : 'file-alt' }
                                                        button 
                                                        noMargin
                                                        tooltip='Purchase Order'
                                                        onClick={() => {downloadS3File(rowData.f.po)}}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>,
                                    alignment: 'center',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Total',
                                    field: rowData => ( 
                                        <React.Fragment>
                                            {rowData.tl.iv}
                                            {' '}
                                            <Tooltip 
                                                title={
                                                    <div style={{display: 'flex', width: 200}}>
                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                            <Typography variant="body2">Sub Total</Typography>
                                                            <Typography variant="body2">VAT ({rowData.tl.vp}%)</Typography>
                                                            <Typography variant="body2">Total</Typography>
                                                        </div>
                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                            <Typography variant="body2">£{rowData.tl.s}</Typography>
                                                            <Typography variant="body2">£{rowData.tl.v}</Typography>
                                                            <Typography variant="body2">£{rowData.tl.iv}</Typography>
                                                        </div>
                                                    </div>
                                                } 
                                                placement="left"
                                            >
                                                <Link>
                                                    <FASolidIcon 
                                                        icon='info-circle'
                                                        button 
                                                        size='small'
                                                        style={{
                                                            color: 'rgba(0, 0, 0, 0.38)'
                                                        }}
                                                    />
                                                </Link>
                                            </Tooltip>
                                        </React.Fragment>
                                    ),
                                    important: true,
                                    alignment: 'right',
                                    fieldPrefix: '£',
                                },
                                {
                                    heading: 'Payment Method',
                                    field: rowData =>   <>
                                                            {_.map(rowData.in, i => 
                                                                <Tooltip
                                                                    title={<Typography variant="body2">{i.toolTip}</Typography>}
                                                                    placement="left"
                                                                >
                                                                    <IconButton className={classes.iconButton}>
                                                                        <AllIcon 
                                                                            icon={`${i.icon}`}
                                                                            button
                                                                            style={{
                                                                                color: i.color
                                                                            }}
                                                                            noMargin
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </>,
                                    important: true,
                                    alignment: 'center',
                                    sizeToContent: true,
                                    truncate: true,
                                },
                                {
                                    heading:    'Recurring',
                                    field:      rowData => <>
                                        {!!rowData.ri && <Tooltip
                                                            title={<Typography variant="body2">Recurring Invoice</Typography>}
                                                            placement="left"
                                                        >
                                                            <IconButton style={{padding:5}}>
                                                                <AllIcon 
                                                                    icon={'sync-alt'}
                                                                    button
                                                                    noMargin
                                                                />
                                                            </IconButton>
                                                        </Tooltip>}
                                    </>,
                                    important: true,
                                    alignment: 'center',
                                    sizeToContent: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            { name: 'Sticky Notes',           icon: icons.stickyNote,    onClick: () => this.handleShowStickyNote(rowData.i), hide:rowData.n[0] === 0, color: (rowData.n[1] && colors.yellow)  },
                                            { name: 'Repeat Order',           icon: icons.repeat,        link: `/sales/order/add/repeat/${rowData.i}`, disabled: !access.repeatOrder },
                                            { name: 'Download',               icon: 'download',          onClick: () => this.handleDownloadOpen(rowData.i)},
                                            { name: 'Send Order to Customer', icon: 'envelope',          onClick: () => this.handleEmailOpen(rowData.i)},
                                            { name: 'View',                   icon: 'search',            link: { pathname: '/sales/order/view/' + rowData.i, state: {searchCriteria, gridPagination}}, disabled: !access.viewOrder}
                                        ]
                                    }
                                }
                            ]}
                            rows={_.orderBy(this.state.searchResults,['d'],['desc'])}
                        />     
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.emailSnackbarOpen}
                            onClose={this.handleEmailSnackbarClose}
                            message='You have successfully sent the email'
                        />
                    </Grid>
                </Grid>
            </PaddedPaper>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                            => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                     => dispatch(setPersistence(key, state)),
    closeDialog:        ()                               => dispatch(closeDialog()),
    deployDialog:       (content, title, variant, size)  => dispatch(deployDialog(content, title, variant,size)),
    clearPersistence:   (key)                            => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                     => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderSearch));