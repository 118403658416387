import React from 'react';
import DataTable from '../../Common/DataTables/DataTable';

class WarrantyTermsList extends React.Component {

    render() {
        const { warrantyTerms, isLoading } = this.props;
        return (
            <React.Fragment>
                <DataTable  
                    config={{
                        key: 'pwt_id',
                        responsiveImportance: true,
                        isLoading: isLoading
                    }}
                    columns={[
                        {
                            heading: 'Name',
                            field: rowData => rowData.pwt_name,
                        },
                        {
                            heading: 'Duration',
                            field: rowData => rowData.pwt_duration !== null ? rowData.pwt_duration + ' months' : '-'
                        }
                    ]}
                    rows={warrantyTerms}
                />
            </React.Fragment>
        );
    }
}

export default WarrantyTermsList;