import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

import {
    AppBar,
    Button,
    Grid,
    List, ListItem, ListItemText,
    Tab, Tabs,
    Typography
} from '@material-ui/core/';

import logo from 'Assets/Images/clenaware_logo_icon.png';

const initialState = {
    stockTake:  {},
    access:     {},
    isLoading:  true,
    currentTab: 'v'
}

class ViewStockTake extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;  
    }

    componentDidMount() { 
        this.getData(); 
    }

    getData = () => {
        API.get(`/stock/take/${this.props.match.params.stId}`)
        .then( res => {
            this.setState({
                stockTake: res.data,
                details:   _.map(res.data.details, i =>
                    _.assign({
                        ...i,
                        stock:      _.find(res.data.stock, {stock_activity_part_id : i.std_part_id }),
                        children:   _.filter(_.map(res.data.revisions, q => {return _.find(q.details, {std_part_id : i.std_part_id})}),i=>i.std_skip_recheck == 0).length == 1 ?
                            null :
                            _.orderBy(_.map(res.data.revisions, q => 
                                _.assign({
                                    ..._.find(q.details, {std_part_id : i.std_part_id}),
                                    stock: _.find(res.data.stock, {stock_activity_part_id : i.std_part_id }),
                                })
                           ), ['std_taken_at'], ['desc'])
                    })
                ),
                isLoading: false,
            });
        });
    }

    loadPartImage = (rowData) => {
        this.setState({ 
            partImageDialog: true,
            partImageDialogData: rowData.default_image?.file_path
        });
    };

    handleTabChange = (event, value) => {
        this.setState({currentTab: value});
    };

    render(){
        const { stockTake, isLoading, details, currentTab } = this.state;

        if (isLoading) return (<LoadingCircle />);
        return (
            <Grid container xs={12} spacing={3}>
                <Grid item lg={12} xs={12}>
                    <PaddedPaper>
                        <Grid container xs={12}>
                            <Grid container item spacing={3} xs={6}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Stock Take:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                        {stockTake.full_reference}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3} xs={6}></Grid>
                            <Grid container item spacing={3} xs={6}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Created:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                        {clenyDate(stockTake.st_date)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3} xs={6}></Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item lg={12} xs={12}>
                    <PaddedPaper>
                        <Grid container xs={12}>
                            <Grid container item spacing={3} xs={6}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Counted By:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                        {stockTake.st_taker}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3} xs={6}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Reviewed By:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                        {stockTake.st_reviewer}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3} xs={6}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Counted On:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                        {clenyDate(_.findLast(stockTake.statuses, i=> i.sts_status === 'Approval').sts_at)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3} xs={6}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Reviewed On:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                        {clenyDate(_.find(stockTake.statuses, i=> i.sts_status === 'Completed').sts_at)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}  style={{paddingBottom: 0}}>
                    <AppBar position="static" style={{backgroundColor:'white', color:"#000", minWidth: 1522, width: '100%', zIndex:'auto'}} >
                        <Tabs   value={currentTab} 
                                onChange={this.handleTabChange} 
                                className='xs-max-width' 
                                indicatorColor="primary" 
                                textColor="primary" 
                                variant='scrollable'
                                scrollButtons="auto"
                        >
                            <Tab label='Parts' value='v' />
                            <Tab label='Logs'  value='l' />
                            <Tab label='Notes' value='n' />
                        </Tabs>
                    </AppBar>
                </Grid>
                {currentTab === 'n' &&
                    <Grid item lg={12} xs={12} style={{marginTop:0, paddingTop: 0}}>
                        <PaddedPaper>
                            <List dense={true} fullWidth style={{width: '100%'}}>
                                {_.map(_.filter(stockTake.logs, i => i.stl_notes && i.stl_notes != "" ), (i, idx) => 
                                    <ListItem style={{borderBottom: (idx+1) < _.filter(stockTake.logs, i => i.stl_notes && i.stl_notes != "" ).length && `1px solid ${colors.disabled}`}} fullWidth>
                                        <ListItemText
                                            style={{padding: '1em 0'}}
                                            primary={<>{i.stl_notes}<br/><br/></>}
                                            secondary={`${i.stl_staff_name} - ${clenyDate(i.stl_datetime)}`}
                                        />
                                    </ListItem>
                                )}
                            </List>
                        </PaddedPaper>
                    </Grid>
                }
                {currentTab === 'l' &&
                    <Grid item lg={12} xs={12} style={{marginTop:0, paddingTop: 0}}>
                        <PaddedPaper>
                            <VerticalTimeline layout="1-column">
                                {stockTake.logs && stockTake.logs.map((log, key) => (
                                    <VerticalTimelineElement key={key}
                                        className="vertical-timeline-element--work"
                                        date={moment(log.stl_datetime).format("DD/MM/YYYY HH:mm") + ' - ' + log.stl_staff_name}
                                        iconStyle={{ backgroundColor: colors.blue, color: 'white' }}
                                        icon={<AllIcon icon={
                                            log.stl_type == 'Created' ? 
                                                icons.plus : 
                                                (
                                                    log.stl_type == 'Manual' ? 
                                                        icons.manualEntry : 
                                                        (
                                                            log.stl_type == 'Scanned' ? 
                                                                icons.qr :
                                                                (
                                                                    log.stl_type == 'Reviewed' ? 
                                                                        icons.review : 
                                                                        (
                                                                            log.stl_type == 'Notes Added' ?
                                                                                icons.notes : icons.tick
                                                                        )
                                                                        
                                                                )
                                                        ) 
                                                )
                                        } color={colors.white} />}
                                    >
                                        <h3 className="vertical-timeline-element-title">{log.stl_type}</h3>
                                        {log.stl_notes ? <span>{log.stl_notes}<br/></span> : null}
                                    </VerticalTimelineElement>
                                ))}
                            </VerticalTimeline>
                        </PaddedPaper>
                    </Grid>
                }
                {currentTab === 'v' &&
                    <Grid item lg={12} xs={12} style={{marginTop:0, paddingTop: 0}}>
                        <PaddedPaper>
                            <DataTable 
                                config={{
                                    key: 'std_id',
                                    responsiveimportant: true,
                                    isLoading: isLoading,
                                    dropRow: {
                                        droppable: rowData => rowData.children?.length > 0,
                                        style: {fontWeight: 'bold',},
                                        columns: [
                                            {
                                                colSpan: 4,
                                                field:   rowData => null
                                            },
                                            {
                                                useParent: 4,
                                            },
                                            {
                                                useParent: 5,
                                            },
                                            {
                                                useParent: 6,
                                            },
                                            {
                                                useParent: 7,
                                            },
                                            {
                                                useParent: 8,
                                            },
                                        ],
                                        data: rowData => rowData.children,
                                    }
                                }}
                                columns={[
                                    {
                                        field: rowData => 
                                            <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                {rowData && rowData.part.default_image?.thumbnail_file_path ?
                                                    <Button onClick={() => this.loadPartImage(rowData)}>
                                                        <img src={rowData.part.default_image?.thumbnail_file_path} alt={rowData.part.part_description} style={{maxHeight:35, maxWidth:35}} />
                                                    </Button> 
                                                : 
                                                    <img src={logo} alt="logo" style={{maxHeight:35, maxWidth:35}}/>
                                                }
                                            </div>,
                                        sizeToContent: true,
                                        alignment: 'center',
                                    },
                                    {
                                        heading:        'Number',
                                        field:          rowData => rowData.part.part_number,
                                        dataRef:        rowData => rowData.part.part_number,
                                        important:      true,
                                        sizeToContent:  true
                                    },
                                    {
                                        heading:    'Description',
                                        field:      rowData => rowData.part.part_description,
                                        dataRef:    rowData => rowData.part.part_description,
                                        important:  true,
                                        main:       true,
                                    },   
                                    {
                                        heading:    'Previous Stock',
                                        field:      rowData => rowData.stock?.stock_activity_previous_qty ? 
                                            parseFloat(rowData.stock?.stock_activity_previous_qty):
                                            parseFloat(rowData.std_taken_stock),
                                        dataRef:    rowData => rowData.stock?.stock_activity_previous_qty ? 
                                            parseFloat(rowData.stock?.stock_activity_previous_qty):
                                            parseFloat(rowData.std_taken_stock),
                                        important:  true,
                                        main:       true,
                                        alignment: 'center',
                                    },  
                                    {
                                        heading:        'Stock Threshold',
                                        field:          rowData =>
                                            <AllIcon 
                                                icon={
                                                    rowData.std_threshold == 'Correct' ? icons.exact : 
                                                    (
                                                        rowData.std_threshold == 'Outside' ? 
                                                            icons.outside :
                                                            icons.inside 
                                                    )
                                                }
                                                color={ rowData.std_threshold == 'Correct' ? colors.green : (
                                                    rowData.std_threshold == 'Outside' ? 
                                                        colors.red :
                                                        colors.orange
                                                )} 
                                                tooltip={rowData.std_threshold}
                                            />,
                                        important:      true,
                                        sizeToContent:  true,
                                        alignment: 'center',
                                    },
                                    {
                                        heading:    'New Stock',
                                        field:      rowData => parseFloat(rowData.std_taken_stock),
                                        dataRef:    rowData => parseFloat(rowData.std_taken_stock),
                                        important:  true,
                                        main:       true,
                                        alignment: 'center',
                                    },  
                                    {
                                        heading:    'Difference',
                                        field:      rowData => {
                                            let detail = _.find(this.state.details, i=>i.std_part_id === rowData.std_part_id);
                                            let prevStock = parseFloat(detail?.stock?.stock_activity_previous_qty || rowData.std_taken_stock)
                                            let diff = parseFloat(rowData.std_taken_stock) - prevStock
                                            if (diff == 0) return '-'
                                            return ( `${diff < 0 ? '-' : '+'}${Math.abs(diff)}`)
                                        },
                                        dataRef:      rowData => {
                                            let detail = _.find(this.state.details, i=>i.std_part_id === rowData.std_part_id);
                                            let prevStock = parseFloat(detail?.stock?.stock_activity_previous_qty)
                                            return ( parseFloat(rowData.std_taken_stock) - parseFloat(prevStock) )
                                        },
                                        important:  true,
                                        main:       true,
                                        alignment: 'center',
                                    }, 
                                    {
                                        heading:    'Difference %',
                                        field:      rowData => {
                                            let detail = _.find(this.state.details, i=>i.std_part_id === rowData.std_part_id);
                                            let prevStock = parseFloat(detail?.stock?.stock_activity_previous_qty)
                                            let diff = parseFloat(rowData.std_taken_stock) - prevStock
                                            let percentage = (diff /prevStock) * 100
                                            if (!diff || !_.isFinite(percentage)) return '-'
                                            return ( `${diff < 0 ? '-' : '+'}${Math.abs(parseInt(percentage))}%`)
                                        },
                                        dataRef:      rowData => {
                                            let detail = _.find(this.state.details, i=>i.std_part_id === rowData.std_part_id);
                                            let prevStock = parseFloat(detail?.stock?.stock_activity_previous_qty)
                                            let diff = parseFloat(rowData.std_taken_stock) - prevStock
                                            let percentage = (diff / parseFloat(prevStock)) * 100
                                            return percentage
                                        },
                                        important:  true,
                                        main:       true,
                                        alignment: 'center',
                                    }, 
                                    {
                                        heading:    'Checked By',
                                        field:      rowData => <>
                                            {rowData.std_taken_by} <br/>
                                            {clenyDate(rowData.std_taken_at)}
                                        </>,
                                        dataRef:    rowData => rowData.std_taken_by,
                                        important:  true,
                                        main:       true,
                                    },
                                    
                                    
                                ]}
                                rows={_.orderBy(details,[i=>i.part.part_number],['asc'])}
                            />
                        </PaddedPaper>
                    </Grid>
                }
                <Grid item xs={12}>
                    <PaddedPaper>
                        <div class='buttonRow' style={{marginTop:0}}>
                            <Button variant='outlined' onClick={
                                ()=>{this.props.history.push(`/stock/take/`);}
                            }>Back</Button>
                        </div>
                    </PaddedPaper>
                </Grid>
            </Grid>
        )
    }

}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                               => dispatch(closeDialog()),
    deployDialog:       (content, title, variant, size)  => dispatch(deployDialog(content, title, variant,size)),
    deployConfirmation: (message, title, success)        => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)                   => dispatch(deploySnackBar(variant, msg)),
})

export default connect(null, mapDispatchToProps)(ViewStockTake);