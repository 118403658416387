import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AllIcon       from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';

import ScanDialog       from './ScanDialog';
import SingleScanDialog from './SingleScanDialog';

import { colors }            from 'Helpers/ColourHelper';
import icons                 from 'Helpers/IconHelper';
import { pdfDataFromBase64 } from 'Helpers/PDFHelper';

import { printA4, printLabel } from 'Actions/Print/Print';
import { addKeyDown, removeKeyDown, setKeyDown } from 'Actions/KeyDown/KeyDown';

const initialState = {
    isLoading: true,
    access: {},
    page: 0,
	perPage: 3,
	worksOrders: [],
    searchResults:     [],
    pickingDialogOpen: false,
    existingPick:       false,
    startPick:          false,
    startSinglePick:    null,
    worksOrder:         {},
    pick:               {},
    shortage:           {},
    showLabels:         false,
    printingDialog:    false,
}


class PickWorksOrder extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }


    componentDidMount = () => {
    	this.props.addKeyDown(this._handleKeyDown)
        this.getPickData();
    }

	componentWillUnmount = () => {
		this.props.removeKeyDown()
	}

    getPickData = () => {
        API.get(`/worksOrders/${this.props.match.params.id}`)
        .then(res => {
            this.setState({
                ...this.state,
                isLoading:  false,
                worksOrder: res.data,
                shortage:   res.data.shortage,
                parts:      _.take(res.data.shortage.details, this.state.perPage),
            })
        });
    }

    handleStartPick = () => {
        // api call to start worksOrder pick
        API.post(`/worksOrders/${this.props.match.params.id}/shortages/${this.state.shortage.woss_id}/start`)
        .then(res => {
            if (res.data.errors){
                this.setState({existingPick: true});
            } else {
                API.get(`/worksOrders/${this.props.match.params.id}`).then(res => {
                    this.setState({
                        startPick:  true,
                        worksOrder: res.data,
                        pick:       res.data.pick,
                        shortage:   res.data.shortage
                    });
                });
            }
        });
    }

    handleStartSinglePick = rowData => {
        API.post(`/worksOrders/${this.props.match.params.id}/shortages/${this.state.shortage.woss_id}/start`)
        .then(res => {
            if (res.data.errors){
               // this.props.deploySnackBar('This shortage is already being picked','error')
            } else {
                API.get(`/worksOrders/${this.props.match.params.id}`).then(res => {
                    let startSinglePick = _.find( res.data.shortage.details, {wossd_part_id: rowData.wossd_part_id});
                    this.setState({
                        startSinglePick: startSinglePick?.wossd_status === 'Pending' ? startSinglePick : null,
                        worksOrder:      res.data,
                        pick:            res.data.pick,
                        shortage:        res.data.shortage
                    });
                });
            }
        }); 
    }

    endPick = () => {
        API.post(`/worksOrders/${this.props.match.params.id}/shortages/${this.state.shortage.woss_id}/end`)
        .then(res => {
            this.setState({
                startPick:       false,
                startSinglePick: null
            },this.getPickData);
        });
    }

    closePick = () => {
        this.endPick()
    }

    handleSavePick = detail => {
        API.post(`/worksOrders/${this.props.match.params.id}/shortages/${this.state.shortage.woss_id}/pick`, detail)
    }

    _handleKeyDown = (e) => {
        if (e.keyCode === 0 || e.keyCode === 192) return;
        e.preventDefault(); 
        if (this.state.pickingDialogOpen) return;
		switch(e.key) {
			case 'F1':
				this.state.page > 0 && this.prevPage();
				break;
			case 'F2':
				((this.state.page + 1) * this.state.perPage) < this.state.shortage.details.length && this.nextPage();
				break;
			case 'F3':
                if (this.state.printingDialog){
                    this.setState({printingDialog: null})
                } else if (this.state.showLabels){
                    this.setState({showLabels: false});
                } else {
                    !this.state.shortage.woss_complete && this.handleStartPick()
                }
                break;
            case ' ':
                this.setState({showLabels: true});
            break;
		}
	}

    nextPage = () => {
		const { page, perPage, shortage } = this.state;
		const newPage = page + 1;
		const newParts = _.take(_.slice(shortage.details, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			parts: newParts
		});
	}

	prevPage = () => {
		const { page, perPage, shortage } = this.state;
		const newPage = page - 1;
		const newParts = _.take(_.slice(shortage.details, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			parts: newParts
		});
	}

    handlePrintWorksOrderHeader = () => {
        API.get(`/worksOrders/${this.props.match.params.id}/headerSheet`)
        .then(result => {
            this.props.printA4(pdfDataFromBase64(result.data.pdf));
        });
    }

    handlePrintPart = id => {
        API.get('/parts/' + id + '/stockLabel', {
            params: {type:   'partLabel'}
        }).then(result => {
            this.props.printLabel(pdfDataFromBase64(result.data.pdf));
        });
    }

    render(){

        const { isLoading, worksOrder, pick, parts, perPage, page, startPick, shortage, startSinglePick, showLabels, printingDialog } = this.state;
        const { classes } = this.props;

        if (isLoading) return (<LoadingCircle/>);

        return (
            <Grid container spacing={1} style={{height: '100%'}}> 
                <Grid item xs={12}>
                    <Typography variant='h4' style={{textAlign: 'center'}}>Pick Stock Shortage</Typography>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper style={{padding: '0.9em'}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{textAlign: 'center'}}>{worksOrder.wo_ref}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{textAlign: 'center'}}><strong>{worksOrder.part.part_number} - {worksOrder.part.part_description}</strong></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{textAlign: 'center'}}><strong>Requested: {worksOrder.wo_qty}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <List>
                        {_.map(parts, i => 
                            <ListItem key={i.wossd_id} className={classes.ListItem}
                                onClick={()=> ( i.wossd_status === 'Pending' ) && this.handleStartSinglePick(i)}
                            >
                                <ListItemText 
                                    primary={i.part.part_number} 
                                    secondary={i.part.part_description}
                                />
                                <ListItemSecondaryAction>
                                    {i.wossd_status === 'Pending' ?
                                        <Typography variant="body2">
                                            Qty:<br/>{i.wossd_qty_remaining}
                                        </Typography> :
                                        <AllIcon
                                            icon={icons.true}
                                            color={colors.green}
                                        /> 
                                    }
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </List>
                </Grid>
                <Grid item xs={12} style={{marginTop: '-0.5em'}}>
                    <Grid container spacing={1} style={{justifyContent: 'space-between'}}> 
                        <Grid item xs={3} style={{marginRight: 'auto'}}>
                            { shortage.details.length > this.state.perPage && this.state.page > 0 && 
                                <Button variant='contained' color='primary'
                                    onClick={this.prevPage} fullWidth
                                >[F1] Prev</Button>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            {!shortage.woss_complete &&
                                <Button variant='contained' color='primary' onClick={this.handleStartPick} fullWidth>[F3] <br/> Start Pick </Button>
                            }
                        </Grid>
                        <Grid item xs={3} style={{marginLeft: 'auto'}}>
                            { shortage.details.length > this.state.perPage && ((this.state.page + 1) * this.state.perPage) < shortage.details.length &&
                                <Button variant='contained' color='primary'
                                    onClick={this.nextPage} fullWidth
                                >[F2] Next</Button>
                            }
                        </Grid>
                    </Grid>
                    <Button variant='contained' color='primary' style={{marginTop: '0.75em'}} onClick={()=>this.setState({showLabels: true})} fullWidth>Print Part Labels [_]</Button>
                </Grid>
                <Dialog 
                    open={startPick} 
                    onClose={this.closePick} 
                    fullScreen
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title" style={{backgroundColor: colors.blue,  fontWeight: 'bold'}}> 
                        <Grid container style={{
                            alignContent:   'center',
                            justifyContent: 'space-between',
                            alignItems:     'center'
                        }}>
                            <Grid item style={{color: colors.white}}>
                                {worksOrder.wo_ref}
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={{ width:'100vw', Maxwidth: '100vw'}}>
                        <ScanDialog 
                            short       = {shortage}
                            onClose     = {this.closePick}
                            onChange    = {this.handleSavePick}
                            woId        = {worksOrder.wo_id}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog 
                    open={startSinglePick} 
                    onClose={this.closePick} 
                    fullScreen
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title" style={{backgroundColor: colors.blue,  fontWeight: 'bold'}}> 
                        <Grid container style={{
                            alignContent:   'center',
                            justifyContent: 'space-between',
                            alignItems:     'center'
                        }}>
                            <Grid item style={{color: colors.white}}>
                                {worksOrder.wo_ref}
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={{ width:'100vw', Maxwidth: '100vw'}}>
                        <SingleScanDialog 
                            short       = {shortage}
                            detail      = {startSinglePick}
                            onClose     = {this.closePick}
                            onChange    = {this.handleSavePick}
                            woId        = {worksOrder.wo_id}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={showLabels}
                    onClose={()=>this.setState({showLabels: false})}
                    fullWidth={true} 
                    maxWidth="sm" 
                    scroll="body"
                >
                    <DialogTitle>
                        <Typography>Works Order Labels</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <List>
                            <ListItem onClick={this.handlePrintWorksOrderHeader}>
                                <Typography variant='body1'>Works Order Header Page</Typography>
                                <ListItemSecondaryAction><AllIcon size='small' icon={icons.print}/></ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                            {_.map(shortage.details, i => 
                                <>
                                    <ListItem onClick={()=>this.handlePrintPart(i.part.part_id)}>
                                        <Typography variant='body1'>{i.part.part_number} - {i.part.part_description}</Typography>
                                        <ListItemSecondaryAction><AllIcon size='small' icon={icons.print}/></ListItemSecondaryAction>
                                        
                                    </ListItem>
                                    <Divider />
                                </>
                            )}
                        </List> 
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>this.setState({showLabels: false})} variant="outlined" color="default">Close [F3]</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
}

const styles = theme => ({
    ListItem: {
		backgroundColor: colors.white, 
		borderRadius: 	 5,
		marginBottom: 	 '0.6em',
		boxShadow: 	  	 '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
	}
});

function mapDispatchToProps(dispatch) {
    return {
        printA4:    (data, file) => dispatch(printA4(data, file)),
        printLabel: (data, file) => dispatch(printLabel(data, file)),
        addKeyDown: (func) => dispatch(addKeyDown(func,'PickWorksOrder')),
        setKeyDown: () => dispatch(setKeyDown('PickWorksOrder')),
        removeKeyDown: () => dispatch(removeKeyDown('PickWorksOrder'))
    }
}


export default  connect(null, mapDispatchToProps)(withStyles(styles)(PickWorksOrder));