import React           from 'react';
import { useDispatch } from 'react-redux';
import { closeDialog } from 'Actions/Dialog/Dialog';
import Button          from '@material-ui/core/Button';

export default function({onClose, text, btnRow}){

    const dispatch = useDispatch();

    const close = () => {
        if (onClose) onClose();
        dispatch(closeDialog());
    }

    return (
        <div className={btnRow && 'buttonRow'}>
            <Button 
                variant='outlined'
                onClick={close} 
            >{text || 'Close'}</Button>
        </div>
    )
}
