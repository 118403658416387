import React                   from 'react';
import ReactStreetview         from "react-streetview";
import { GOOGLE_MAPS_API_KEY } from 'Constants';
 
const initialState = () => ({
    lat: 52.034530825365685,
    lng: -0.7466207881302033,
    draggable: true,
    zoom: 11,
    center: [52.034530825365685, -0.7466207881302033],
    markers: [],

    showStreetView: false,
})
class StreetMap extends React.Component {

    constructor(props) {
        super(props);
        let { lat, lng } = this.props;

        this.state = {...initialState(),
            lat:    lat ? lat : initialState().lat,
            lng:    lng ? lng : initialState().lng
        };
    }

    componentDidMount = () => {
        let { lat, lng, markers, zoom, center } = this.props;

        this.setState({
            lat: lat ? lat : this.state.lat,
            lng: lng ? lng : this.state.lng,
            markers: markers ? markers : this.state.markers,
            zoom: zoom ? zoom : this.state.zoom,
            center: center ? center : this.state.center
        });
    }

    render() {
        const { lat, lng } = this.state;
        const { width, height } = this.props;

        return (
            <div style={{height: height ?? '100vh', width: width ?? '100%', cursor: 'default'}}>
                <ReactStreetview
                    apiKey={GOOGLE_MAPS_API_KEY}
                    streetViewPanoramaOptions={{
                        position: { lat: parseFloat(lat), lng: parseFloat(lng) },
                        pov: { heading: 100, pitch: 0 },
                        zoom: 1,
                        addressControl: false,
                        showRoadLabels: false,
                        zoomControl: false,
                        fullscreenControl: false,
                    }}
                />
            </div>
        )
    }
}
 
export default StreetMap;