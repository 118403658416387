import { Box } from '@material-ui/core';
import React from 'react';
import EmailEditor from 'react-email-editor';

export default function EmailPreview(props) {
    let { json } = props;
    const emailEditorRef = React.createRef();
    
    const [ hasLoaded, setHasLoaded ] = React.useState(false);

    const EmailEditorOnReady = () => {
        if (!hasLoaded){
            if (emailEditorRef?.current?.editor){
                emailEditorRef.current.editor.showPreview("desktop");
                emailEditorRef.current.editor.loadDesign(json);
                setHasLoaded(true);
            } else {
                setTimeout(EmailEditorOnReady, 100)
            }
        }
    }

    return (
        <Box
            style={{
                
                overflow: 'hidden',
                backgroundColor: 'white',
                height: '450px',
                borderRadius: '4px',
                width: 960,
            }}
        >
            <EmailEditor
                ref={emailEditorRef}
                onReady={setTimeout(EmailEditorOnReady, 10)}  
                style={{ width: '40em', maxWidth: '40em', marginTop: '-8.6em', marginLeft: '-2em' }}
            />
        </Box>
    )
}