  
import * as ConfirmationActionTypes from 'Actions/Confirmation/Types/Types';
import uuidv4 from 'uuid/v4';

const initialState = {
    key: uuidv4(),
    open: false,
    message: '',
    title: '',
    close:   null,
    success: null,
}

const ConfirmationReducer = (state = initialState, action) => {
    switch(action.type) {
        case ConfirmationActionTypes.CONFIRMATION_OPEN:
            return {
                ...state,
                key: uuidv4(),
                open: true,
                message: action.message,
                title: action.title,
                close: action.close,
                success: action.success,
                variant:  action.variant,
            };
        case ConfirmationActionTypes.CONFIRMATION_CLOSE:
            return {
                ...state,
                open: false,
                message: initialState.message,
                title: initialState.title,
                close: initialState.close,
                success: initialState.success,
            };
        default:
            return state;
    }
}
export default ConfirmationReducer;