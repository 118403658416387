import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import DataTable          from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import DragFileInput      from 'Components/Common/Inputs/DragFileInput';

import { colors } from 'Helpers/ColourHelper';

import { Grid, Typography, Button } from '@material-ui/core/';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';

const initialState = {
    prices: [],
    masterExcel: [],
    file: null,
    isLoading: true,
}


class StockMasterExcel extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount() { this.getData(); }

    getData = () => {
        API.get('/parts/all', {params:{ withDefaultImg: true, withPrice: true, hasPrice: true, withCost: true}})
        .then(res => {
            this.setState({
                prices: _.map(res.data, i => ({
                    id:          i?.part_id,
                    img:         i?.default_image?.file_path,
                    number:      i?.part_number,
                    description: i?.part_description,
                    price:       i?.default_price.price_total,
                    margin:      i?.default_price.price_margin,
                    cost:        i?.default_cost.cost_per_unit,
                }) ),
                isLoading: false
            });
        });
    }

    csvUpload = e => {
        let data = e.target.result.split('\n');

        let head = data[0].split(',');
        let body = [];

        for (let i = 1; i < data.length; i++) {
            let row = data[i].split(',');
            let obj = {};
    
            for (let j = 0; j < row.length; j++) {
                obj[String(head[j].replaceAll('"','').replaceAll("'",''))] = row[j].replaceAll('"','').replaceAll("'",'');
            }
    
            body.push(obj);
        }

        let changes = [];


        changes = _.filter(_.map(body, master => {
            let _price = _.find(this.state.prices, {number: master[_.keys(master)[0]]});
            if (!_price){ return; }
            if (parseFloat(parseFloat(master[_.keys(master)[1]]).toFixed(3) === parseFloat(_price.price).toFixed(3))) { return; }
            if (parseFloat(parseFloat(master[_.keys(master)[1]]) - parseFloat(_price.price)) === 0) { return; }

            let masterMargin = parseFloat(
                parseFloat(parseFloat(master[_.keys(master)[1]]) - parseFloat(_price.cost)) / parseFloat(master[_.keys(master)[1]]) 
                * 100
            )

            return {
                ..._price,
                masterPrice:  parseFloat(master[_.keys(master)[1]]),
                systemPrice:  parseFloat(_price.price),
                changePrice:  parseFloat(parseFloat(master[_.keys(master)[1]]) - parseFloat(_price.price)),
                
                masterMargin: parseFloat(masterMargin < 0 ? 0 : masterMargin).toFixed(2),
                systemMargin: parseFloat(_price.margin).toFixed(2),
            }
        }), i => !!i);
        
        this.setState({
            masterExcel: body,
            changes: changes,
        });
    }

    fileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        var reader = new FileReader();

        reader.onload = this.csvUpload;
        reader.readAsText(file);
        this.setState({file});
    }

    clearFile = () => {
        this.setState({file: null});
    };

    submit = () => {
        this.setState({isLoading: true}, ()=>{
            let parts = [];
            _.each(this.state.changes, i => {parts.push({ part: i.id, price: i.masterPrice})});
            API.post(`/prices/masterExcelCorrection`, {parts: JSON.stringify(parts)})
            .then(res => {
                if (res.data.errors.length > 0) {
                    this.props.deployDialog(
                        <>
                            <Typography variant='body2' style={{marginBottom: '0.75em'}}>This can be down to missing prices, missing costs or locked parts. These parts should be changed manually.</Typography>
                            {_.map(res.data.errors, id => <Typography>{_.find(this.state.changes, {id}).number} - {_.find(this.state.changes, {id}).description}</Typography>)}
                            <div className='buttonRow'><Button variant='contained'
                                onClick={() => {this.props.closeDialog(); this.completeSubmit(res.data.packages);}}
                            >Close</Button></div>
                        </>, 'The following parts failed to update'
                    );
                } else {
                    this.completeSubmit(res.data.packages);
                }
                
            })
        });
    }

    completeSubmit = (packages) => {
        if (packages.length) {
            this.props.deployDialog(
                <>
                    {_.map(packages, pack => <Typography>{pack}</Typography>)}
                    <div className='buttonRow'><Button variant='contained'
                        onClick={this.props.closeDialog}
                    >Close</Button></div>
                </>, 'The following packages will need to be updated'
            )
        }
        this.setState({...initialState}, this.getData);
    }

    render() {
        const {isLoading, masterExcel, changes} = this.state;
        
        if (isLoading) return( <LoadingCircle/> );

        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Master Excel Price Change
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <PaddedPaper>
                        <DragFileInput
                            label="Upload Master Excel *"
                            placeholder="Please Include Headers In CSV"
                            onChange={this.fileChange}
                            cancelOnClick={this.clearFile}
                            noClear
                            accept=".csv"
                            required
                            file={this.state.file}
                            emptyText='No file selected'
                        />
                        {masterExcel.length > 0 && changes.length > 0 && 
                            <div className='buttonRow'>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>{this.props.deployConfirmation(`Are you sure you want to these parts prices to match the master Excel?`, `Change ${changes.length} Parts Prices`, this.submit)}}
                                >Change {changes.length} Parts Prices</Button>
                            </div>
                        }
                    </PaddedPaper>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                    <PaddedPaper>
                        <Typography variant='body1' style={{fontWeight: 'bold', marginBottom: '1em'}}>Note: Uploaded CSV must have the following headings:</Typography>
                        <DataTable
                            config={{
                                plainHeader: true,
                                noHeader: true,
                            }}
                            columns={[
                                {
                                    field:   'c',
                                    sizeToContent: true,
                                    style: { fontWeight: 'bold', borderRight: `1px solid ${colors.disabled}` },
                                },
                                {
                                    field: 'n',
                                },
                            ]}
                            rows={[
                                {c: 'A1', n: 'Part Number' },
                                {c: 'A2', n: 'Price' }
                            ]}
                        />
                    </PaddedPaper>
                </Grid>
                {masterExcel.length > 0 &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            {changes.length > 0 ?
                                <DataTable
                                    config={{
                                        key: 'id',
                                        sticky:true
                                    }}
                                    columns={[
                                        {
                                            heading: '',
                                            field: 'img',
                                            fieldFormat: 'image',
                                            sizeToContent: true,
                                        }, 
                                        {
                                            heading: 'Part Number',
                                            field: 'number',
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: 'Part Description',
                                            field: 'description',
                                        },
                                        {
                                            heading:     'Current Price',
                                            field:       'systemPrice',
                                            fieldFormat: 'currency',
                                        },
                                        {
                                            heading:     'Excel Price',
                                            field:       'masterPrice',
                                            fieldFormat: 'currency',
                                        },
                                        {
                                            heading:       'Change £',
                                            field:         'changePrice',
                                            fieldFormat:   'changeCurrency',
                                        },
                                        {
                                            heading:     'Change %',
                                            field:       i => _.isFinite(parseFloat(parseFloat(i.changePrice) / parseFloat(i.systemPrice))) ?
                                                `${i.changePrice > 0 ? '+' : ''} ${parseFloat(parseFloat(parseFloat(i.changePrice) / parseFloat(i.systemPrice)) * 100).toFixed(2)}%` : '-' ,
                                            style:       i => ({color: i.changePrice > 0 ? colors.green : colors.red}),
                                            alignment:   'center'
                                        }
                                    ]}
                                    rows={_.orderBy(changes, ['number'], ['asc'])}
                                />:
                                <>
                                    <Typography variant="h6">
                                        No Prices needs to be changed
                                    </Typography>
                                </> 
                            }
                        </PaddedPaper>
                    </Grid>
                }

            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
        deployDialog:       (content, header) => dispatch(deployDialog(content, header, null, 'md')),
        closeDialog:        ()                => dispatch(closeDialog())
    }
}

export default connect(null, mapDispatchToProps)(StockMasterExcel);