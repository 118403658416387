import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Box } from '@material-ui/core';
import 'Assets/Styles/WYSIWYG.css';
import { colors } from 'Helpers/ColourHelper';
import _ from 'lodash';

const initialState = props => {
    let value = '';
    if (props.value) {
        const blocksFromHtml = htmlToDraft(props.value);
        value = EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap));
    }  else {
        value = EditorState.createEmpty();
    }
    return {
        editorState: value,
    }
}

class WYSIWYG extends Component {
    constructor(props) {
        super(props);
        this.state = initialState(props)
    }
    componentDidUpdate(prevProps) {
        if (prevProps.update !== this.props.update) {
            this.setState(initialState(this.props));
        }
    }
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        }, this.onChange);
    };

    onChange = () => {
        if (!this.props.onChange) return;
        const { editorState } = this.state;
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.props.onChange(html);
    }

    render() {
        const { editorState } = this.state;
        return (
            <Box
                className='WYSIWYG-container'
            >
                <Editor
                    editorState={editorState}
                    wrapperClassName="WYSIWYG-wrapper"
                    editorClassName="WYSIWYG-editor"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                        options: [
                            'inline',
                            'fontSize', 
                            'list',
                            'colorPicker',
                            'link', 
                          //  'emoji'
                        ],
                        fontSize: {
                            options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 36, 48, 60, 72, 96],
                        },
                        colorPicker: {
                            colors: _.uniq(_.values(colors)),
                        },
                        inline: {
                            options: [
                                'bold', 
                                'italic', 
                                'underline'
                            ]
                        },
                    }}
                    toolbarStyle={{
                        padding: 4,
                        paddingTop: 9,
                        margin: 0,
                        border: 'none',
                    }}
                    stripPastedStyles={true}
                    spellCheck={true}
                />
            </Box>
        );
    }
}

export default WYSIWYG