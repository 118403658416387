import * as FileSaver from "file-saver";
import * as XLSX from "xlsx-js-style";
import _ from 'lodash';

/*
    Missing/Todo when required:
    - multiple sheets
    - columns above Z
*/

const columns = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

class ExcelHelper {
    constructor() {
        this.excel = XLSX?.utils?.book_new({
            'raw': false,
            'cellNF': true
        });
        this.sheets = [XLSX.utils.json_to_sheet([])];
        this.workingSheet = 0;
        this.rows = [[]];
        this.styles=[{}];
        this.colsStyle = [[]];
        this.colFormats = [[]];
    }

    //set items
    setItem = (value, column='A', index=-1) => {
        this.#createRowData(this.#createColumnData(value, column, index), index);
    }

    setItems = (valuesArr, startRow=-1, startColumn='A') => {
        valuesArr.forEach((i, index) => {
            this.setItem(i, startColumn, startRow < 0 ? -1 : startRow + index);
        });
    }

    newLine = (rows=1) => {
        for (let i = 0; i < rows; i++){
            this.rows[this.workingSheet].push({});
        }
    }

    // styles

    setStyles = async (stylesArr) => {
        await _.each(stylesArr, async (style) => {
           await this.setStyle(style[0], style[1] ?? null, style[2] ?? null);
        });
    }

    setStyle = (style, start='A2', end=null) => {
        let startNo = (start.match(/\d+/g))[0],
            endNo = end ? (end.match(/\d+/g))[0] : startNo,
            startAlpha = start.match(/[A-Z]+/g)[0],
            endAlpha = end ? end.match(/[A-Z]+/g)[0] : startAlpha;
        
        let styles = this.styles[this.workingSheet];

        for (let i = parseInt(startNo); i <= parseInt(endNo); i++){
            for (let j = this.#getColumnIndex(startAlpha); j <= this.#getColumnIndex(endAlpha); j++){
                if (
                    styles[this.#getColumnAlpha(j) + i] 
                ) {
                    let arr = styles[this.#getColumnAlpha(j) + i];
                    _.each(_.keys(style), (key) => {
                        arr[key] = {...(arr[key] ? arr[key]: {}), ...style[key]};
                    });
                    styles[this.#getColumnAlpha(j) + i] = arr;
                } else {
                    styles[this.#getColumnAlpha(j) + i] = style;
                }
                styles[this.#getColumnAlpha(j) + i] = styles[this.#getColumnAlpha(j) + i] ? {...styles[this.#getColumnAlpha(j) + i], ...style} : style;
            }
        }

        this.styles[this.workingSheet] = {...this.styles[this.workingSheet],...styles};
    }

    sizeToContent = () => {
        _.each(this.sheets, (sheet, idx) => {
            let _colStyle = {};
            _.each(this.rows[idx], (row) => {
                _.each(_.keys(row), (key) => {
                    if (_colStyle[key]){
                        _colStyle[key] = Math.max(_colStyle[key], row[key].length);
                    } else {
                        _colStyle[key] = row[key].length;
                    }
                });
            })
            this.colsStyle[idx] = _.map(_colStyle, w => ({wch: w && w > 8 ? w + 4 : 8}));
        })
    }

    setBorder = (start, end=null, style={style: 'medium'}) => {
        if (!end){
            this.styles[this.workingSheet][start] = {
                ...this.styles[this.workingSheet][start],
                border: {
                    ...this.styles[this.workingSheet][start]?.border,
                    top: style,
                    bottom: style,
                    left: style,
                    right: style
                }
            }
        } else {
            let startNo = (start.match(/\d+/g))[0],
                endNo = (end.match(/\d+/g))[0],
                startAlpha = start.match(/[A-Z]+/g)[0],
                endAlpha = end.match(/[A-Z]+/g)[0],
                startIdx = this.#getColumnIndex(startAlpha),
                endIdx = this.#getColumnIndex(endAlpha);

            //set y
            for (let i = parseInt(startNo); i <= parseInt(endNo); i++){
                for (let l = this.#getColumnIndex(startAlpha); l <= this.#getColumnIndex(endAlpha); l++){
                    this.styles[this.workingSheet][this.#getColumnAlpha(l) + i] = {
                        ...this.styles[this.workingSheet][this.#getColumnAlpha(l) + i],
                        border: {
                            top: i == startNo ? style : null,
                            bottom: i == endNo ? style : null,
                            left: l == startIdx ? style : null,
                            right: l == endIdx ? style : null,
                        }
                    }
                }
            }
        }
    }

    setBorders = (coords, style={style:'medium'}, ) => {

        if (!_.isArray(coords)){
            coords = [coords];
        }

        _.each(coords, async (coord) => {
            let exp = coord.split(':'); 
            await this.setBorder(exp[0], exp[1] ?? null, style);
        });

    }

    setFormat = (coord, format='currency') => {
        let _format = format;
        switch (format){
            case 'currency':
                _format = {
                    z: '£0.00',
                    t: 'n'
                };
                break;
            case 'date':
                _format = {
                    z: 'yyyy-mm-dd',
                    t: 'd'
                };
                break;
            default:
                break;
        }

        let exp = coord.split(':'); 
        let start = exp[0];
        let end = exp[1] ?? null;
        let startNo = (start.match(/\d+/g))[0],
                endNo = (end.match(/\d+/g))[0],
                startAlpha = start.match(/[A-Z]+/g)[0],
                endAlpha = end.match(/[A-Z]+/g)[0],
                startIdx = this.#getColumnIndex(startAlpha),
                endIdx = this.#getColumnIndex(endAlpha);

        for (let i = parseInt(startNo); i <= parseInt(endNo); i++){
            for (let l = this.#getColumnIndex(startAlpha); l <= this.#getColumnIndex(endAlpha); l++){
                this.colFormats[this.workingSheet][this.#getColumnAlpha(l) + i] = _format;
            }
        }

    }

    //response
    download = (filename) => {

        this.sheets.forEach((sheet, idx) => {
            let _sheet = sheet;

            XLSX.utils.sheet_add_json(_sheet, this.rows[idx], { origin: "A1", skipHeader: true });

            if (this.colsStyle[idx].length){
                _sheet["!cols"] = this.colsStyle[idx];
            }

           

            if (this.styles[idx]){
                let styles = this.styles[idx];
                _.each(_.keys(styles), (key) => {
                    _sheet[key] = {
                        ..._sheet[key],
                        s: styles[key]
                    } ;
                });
            }

            if (this.colFormats[idx]){
                let colFormats = this.colFormats[idx];
                _.each(_.keys(colFormats), (key) => {
                    _sheet[key] = {
                        ..._sheet[key],
                        ...colFormats[key],
                    } ;
                    delete _sheet[key].w;
                    XLSX.utils.format_cell(_sheet[key]);
                });
            }

            XLSX.utils.book_append_sheet(this.excel, _sheet, sheet?.name || "Sheet1");
        });

        const excelBuffer = XLSX.write(this.excel, {
            bookType: "xlsx",
            type: "array",
        });

        const data = new Blob([excelBuffer], {
            type: "xlsx",
        });
    
        /* Output blob to browser */
        FileSaver.saveAs(data, filename?.toLowerCase?.());
    }

    //private
    #getCurrentSheet = () => {
        return this.sheets[this.workingSheet];
    }

    #createRowData = (data, startRow=-1) => {
        let ret = {};
        if (startRow<0){
            this.rows[this.workingSheet].push(data);
        } else {
            if (startRow < this.rows.length){
                this.rows[this.workingSheet].splice(startRow, 0, data);
            } else {
                this.rows[this.workingSheet][startRow] = data;
            }
        }
        return ret;
    }

    #createColumnData = (data, startColumn='A', row=-1) => {
        let ret = this.#getColumnPadding(startColumn);
        if (row > -1){ ret = {...ret, ...this.rows[this.workingSheet][row]}; }
        let _data = data;
        let startIndex = this.#getColumnIndex(startColumn);
        if (_.isObject(data)){ _data = _.values(data);}
        _data.forEach((i,index) => {
            ret[this.#getColumnAlpha(startIndex + index)] = i;
        })
        return ret;
    }

    #getColumnPadding = (column) => {
        if (column === 'A'){
            return {};
        } else {
            let ret = {};
            for (let i = 0; i < this.#getColumnIndex(column); i++){
                ret[this.#getColumnAlpha(i)] = '';
            }
            return ret;
        }
    }

    #getColumnAlpha = (index) => {
        if (index > columns.length){
            let startIndex = this.#getColumnAlpha(Math.floor(index / columns.length));
            let endIndex = this.#getColumnAlpha(index % columns.length);
            return startIndex + endIndex;
        } else {
            return columns[index];
        }
    }

    #getColumnIndex = (alpha) => {
        if (alpha.length > 1){
            //auto generated from copilot not used yet
            let index = 0;
            for (let i = 0; i < alpha.length; i++){
                index += columns.indexOf(alpha[i]) * Math.pow(columns.length, alpha.length - i - 1);
            }
            return index;
        } else {
            return columns.indexOf(alpha);
        }
    }

}

export default ExcelHelper;