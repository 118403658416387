import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import React from 'react';
import InfoIcon from 'Components/Common/Icons/InfoIcon';
import IconHelper from 'Helpers/IconHelper';
import { Typography } from '@material-ui/core';

export default function ViewOrder({order, cr: {cr_vat_rate}}) {
    return (
        <CiDataTable
            rows={[order]}
            config={{
                key: 'order_id',
            }}
            columns={[
                {
                    heading: 'Order Date',
                    field: 'order_date',
                    sizeToContent: true,
                    alignment: 'center',
                    fieldFormat: 'clenydate',
                },
                {
                    heading: 'Reference',
                    field: i => <>{i.order_reference }-{i.order_reference_amendment}</>,
                },
                {
                    heading: 'Discount',
                    field: i => i.order_type === 'With Discount',
                    fieldFormat: 'boolean',
                    sizeToContent: true,
                    alignment: 'center'
                },
                {
                    heading: 'Customer',
                    field: i => 
                        <>
                            {i.order_cust_name}<br/>
                            {i.order_delivery_cust_address_name} <InfoIcon info={
                                <span style={{whiteSpace: 'pre-wrap'}}>
                                    <b>Delivery Address</b><br/>
                                    {i.order_delivery_cust_address}
                                </span>
                            }/>
                        </>,
                },
                {
                    heading: 'Total',
                    field: 'order_total_incl_vat',
                    fieldFormat: 'currency',
                    sizeToContent: true,
                    alignment: 'right',
                    info: rowData => 
                        <div style={{display: 'flex', width: 200}}>
                            <div style={{width: '50%', textAlign:'right'}}>
                                <Typography variant="body2">Sub Total</Typography>
                                <Typography variant="body2">VAT ({parseInt(cr_vat_rate * 100).toFixed(2)}%)</Typography>
                                <Typography variant="body2">Total</Typography>
                            </div>
                            <div style={{width: '50%', textAlign:'right'}}>
                                <Typography variant="body2">£{rowData.order_sub_total}</Typography>
                                <Typography variant="body2">£{rowData.order_vat}</Typography>
                                <Typography variant="body2">£{rowData.order_total_incl_vat}</Typography>
                            </div>
                        </div>
                },
                {
                    actions: i => ([
                        {
                            name: 'View',
                            link: `/sales/order/view/${i.order_id}`,
                            icon: IconHelper.view
                        }
                    ])
                }
            ]}
        />

    )
}