import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import IconHelper from 'Helpers/IconHelper';
import React from 'react';
import { connect } from 'react-redux';

class ContactsSystem extends React.Component {

    reinstateContact = (contact) => {
        API.post(`/marketing/campaigns/mailingLists/${this.props.mailingList.i}/contacts/${contact.mlc_id}/reinstate`)
        .then(this.props.refresh);
    }

    render () {

        const { mailingList } = this.props;

        return (
            <DataTable
                config={{
                    key: 'i',
                    pagination: mailingList?.c?.o.length >  100,
                    isLoading: this.props.dataLoading
                }}
                rows={mailingList?.c?.o}
                columns={[
                    { 
                        heading: 'Name', 
                        field: 'contact.contact_name', 
                        sizeToContent: true,
                        dataRef: 'contact.contact_name', 
                    },
                    { 
                        heading: 'Company', 
                        field: i => i.contact?.customer?.cust_name ?? '-',
                        truncate: true,
                        dataRef: i => i.contact?.customer?.cust_name ?? '-',
                    },
                    { 
                        heading: 'Email', 
                        field: 'contact.contact_email', 
                        dataRef: 'contact.contact_email', 
                    },
                    {
                        heading: 'System Contact?',
                        field: i => i.contact?.customer?.cust_name ? true : false,
                        dataRef: i => i.contact?.customer?.cust_name ? true : false,
                        sizeToContent: true,
                        alignment: 'center',
                        fieldFormat: 'boolean',
                    },
                    {
                        actions: r => ([
                            {
                                name: 'View',
                                link: `/customers/contacts/view/${r.contact.contact_id}`,
                                icon: IconHelper.view,
                            },
                            {
                                name: 'Add Back',
                                onClick: ()=>{
                                    this.props.deployConfirmation(
                                        'Are you sure you want to add this contact back to the list?',
                                        'Add Back Contact',
                                        ()=>this.reinstateContact(r)
                                    )
                                },
                                icon: IconHelper.plus
                            }
                        ])
                    }
                ]}
            />
        )
    }

}




function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback) => {
            dispatch(deployConfirmation(content, title, callback))
        }
    }
}

export default connect(null, mapDispatchToProps)(ContactsSystem);