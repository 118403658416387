import React, {Component} from 'react';
import API                from 'API';

import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher';

import MarkerCatagory from './MarkerCatagory';
import Markers        from './Markers';

const initialState = {
    access: {
        catagories: false,
    }
}

class MapAndMarkers extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getAccess();
    }

    getAccess = () => {
        API.get('/staff/my/access/check/map-categories')
        .then(res => {
            this.setState({
                access: {
                    catagories: res?.data?.has_access,
                }
            });
        })
    }

    render() {
        const { access } = this.state;
        return (
            <TabBarSwitcher
                defaultTab='Markers'
                tabs={[
                    access.catagories && {
                        label:   'Categories',
                        content: <MarkerCatagory />,
                    },
                    {
                        label:   'Markers',
                        content: <Markers {...this.props}/>,
                    }
                ]}
            />
        );
    }
}

export default MapAndMarkers;