import API from '../../../../API';
import { API_URL } from '../../../../Constants';
import { getToken } from '../../../../Functions/AuthFunctions';
import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import DataTable from '../../../Common/DataTables/DataTable';
import Grid from '@material-ui/core/Grid';
import ModelViewerDialog from '../ModelViewerDialog/ModelViewerDialog';
import _ from 'lodash';

class Files3D extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            viewModel: {
                open: false,
                title: '',
                url: ''
            },
            open: false,
        }
    }

    componentDidMount(){
        //this.getPartFiles();
    }

    getPartFiles = () => {
        API.get(`/parts/${this.props.partId}/files/3D`)
        .then((res) =>  {
            var files = [];
            _.each(_.orderBy(res.data, file => parseFloat(file.part_3D_part_no), 'desc'), (file) => {
                if(!files[file.part_3D_part_no]){files[file.part_3D_part_no] = []}
                files[file.part_3D_part_no].push(file);
            });

            this.setState({
                rows: files,
                open: true
            });
        })
    }

    downloadFile = rowData => {
        window.open(rowData.download_url, '_blank');
    }

    openModel = (title, url) => {
        this.setState({
            viewModel: {
                open: true,
                url: API_URL + '/getFileByType?request=' + btoa(url) + '&token=' + getToken() + '&fileType=stl',
                title: title
            }
        });
    }

    closeModel = () => {
        this.setState({
            viewModel: {
                open: false,
                url: '',
                title: ''
            }
        })
    }

    createGrids(rows){
        let x = [];
        for(let key in rows) {
            x.push(
                <React.Fragment key={key}>
                    <Typography variant='h6' style={{marginTop: 10}}>Part {key}</Typography>
                    {rows[key] && rows[key].length > 0 ? 
                        <DataTable 
                            config={{
                                key: 'part_3D_id',
                                pagination: false,
                                plainHeader: true,
                                responsiveImportant: true
                            }}
                            columns={[
                                {
                                    heading: 'Version',
                                    field: row => 'v'+row.part_3D_version,
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Type',
                                    field: row => row.part_3D_type,
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'File',
                                    field: row => row.part_3D_url,
                                    important: true,
                                    truncate: true
                                },
                                {
                                    actions: row => {
                                        return [
                                            {name: 'Download', icon: 'download', onClick: this.downloadFile},
                                            {name: 'View Model', icon: 'cubes', disabled: !row.download_url.toLowerCase().endsWith('stl'), onClick: () => { this.openModel(row.part_3D_type, row.download_url) }}
                                        ]
                                    }
                                }
                            ]}
                            rows={rows[key]}
                        />
                        
                    :
                        <Typography>There are 0 3D Printing Files found.</Typography>
                    }
                </React.Fragment>
            )
        }
        return x;
    }

    handleOpen = () => {
        if(!this.state.open){
            this.getPartFiles();
        } else {
            this.setState({
                open: false
            });
        }
    }

    render() {
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={this.handleOpen}>
                    <Typography>3D Printing</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={3}>
                        <Grid item lg={12}>
                            {this.state.open &&
                                <>{Object.keys(this.state.rows).length > 0 ? 
                                    this.createGrids(this.state.rows)
                                    :
                                    <Typography>There are 0 3D Printing Files found.</Typography>
                                }</>
                            }
                        </Grid>
                    </Grid>
                    <ModelViewerDialog
                        title={this.state.viewModel.title}
                        modelUrl={this.state.viewModel.url}
                        open={this.state.viewModel.open}
                        close={this.closeModel}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default Files3D;