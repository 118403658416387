import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import API from 'API';
import 'Assets/timeLine.css';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import DeliveryNote from 'Components/Sales/Order/ViewDispatch/DeliveryNote';

import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import BackButton from 'Components/Common/Buttons/BackButton';

const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    btnRow: {
        textAlign: 'center', 
    },
    btnRowBtn: {   
        width: '100%',
    },
    link: {
        textDecoration: 'none',
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        }
    },
    statusHeading: {
        display: 'flex',
        alignItems: 'center'
    }
});

const initialState = {
    formData: {
        notesText: '',
        fileType: '',
        file: ''
    },
    formErrors: [],
    currentTab: 0,
    cd: {},
    files: [],
    orderId: null,
    redirect: false,
    emailDialogOpen: false,
    isLoading: true,
    snackbarOpen: false,
    succesMsg: '',
    dialogCloseOpen: false,
    snackbarCloseOpen: false,
    dialogOrderOpen: false,
    snackbarOrderOpen: false,
    dialogNotesOpen: false,
    snackbarNotesOpen: false,
    quotationNotifications: []
}

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

class ViewOrder extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    componentDidMount(){
        this.getDeliveryNote();
    }
    getDeliveryNote = () => {
        API.get(`/sales/orders/despatch/${this.props.match.params.despId}`)
        .then(result => {
            this.setState({
                ...this.state,
                cd: result.data,
                isLoading: false
            });
        });
    }
    handleDownload = (orderId) => {
        API.post(`/sales/orders/${orderId}/prevPdf`, {'type': 'delivery-note', 'id': this.props.match.params.despId})
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
                if (result.data.additional && result.data.additional.length > 0) {
                    result.data.additional.forEach(file => {
                        pdfFromBase64(file.pdf, file.pdfName);
                    });
                }
                this.props.deploySnackBar('success', 'PDF successfully downloaded');
            }
        });
    }
    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        });
    };
    handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
   
    render() {
        const { currentTab, cd, isLoading } = this.state;
        const { classes } = this.props;
        
        if (this.state.redirect === true) {
            return <Redirect 
                        to={{
                            pathname:`/sales/order/view/${this.state.latestSrId}`,
                            state: {snackbarAmendOpen: true}
                        }}
                    />
        }
        
        return (            
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Delivery Note
                    </Typography>
                </Grid>
                {isLoading ? (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                ) : (
                    <>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={9}>
                                <PaddedPaper>
                                    <Typography variant="h4" align="center" className={classes.heading} style={{marginBottom: 25}}>
                                        {cd.full_reference}
                                    </Typography>
                                    <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                        <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={() => this.handleDownload(cd.desp_order_id)}
                                        >
                                            <FALightIcon icon='download' />
                                            Download
                                        </Button> 
                                    </Typography>
                                    <br className='xs-only' /> 
                                    <AppBar position="static" style={{backgroundColor:'white', margin: '25px 0'}} >
                                        <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary" variant="scrollable">
                                            <Tab label="Delivery Note" />
                                        </Tabs>
                                    </AppBar>
                                    {currentTab === 0 && (
                                        <TabContainer>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <PaddedPaper>
                                                        <DeliveryNote cd={cd} />
                                                    </PaddedPaper>
                                                </Grid>
                                            </Grid>
                                        </TabContainer>
                                    )}
                                    <Divider style={{marginBottom:20}} />
                                    <div className={`buttonRow ${classes.btnRow}`}>
                                        <Grid item xs={12} md={2} className="float-right">
                                            <BackButton props={this.props} style={{width:'100%'}} />
                                        </Grid>
                                    </div>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, variant, size) => {
            dispatch(deployDialog(content, title, variant, size));
        },
        deploySnackBar: (variant, message) => {
            dispatch(deploySnackBar(variant, message));
        }
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ViewOrder));
