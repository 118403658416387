import React from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Button } from '@material-ui/core';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea           from 'Components/Common/Inputs/Textarea';

export default function WarrantyDialog(props) {
    const { open, handleClose, onSubmit, handleChange, handleSelectChange, partFormData, warrantyTerms } = props;
    
    return(
        <Dialog 
            open={open} 
            onClose={handleClose} 
            fullWidth={true} 
            scroll="body"
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle>Part Warranty Terms</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="normal">
                    <AutoCompleteSelect 
                        options={warrantyTerms} 
                        label='Warranty Term *'
                        onChange={handleSelectChange('warrantyTerm')}
                        value={partFormData.warrantyTerm}
                        disableSort={true}
                        noClear
                    />
                </FormControl>
                <Textarea
                    name="notes"
                    value={partFormData.notes}
                    label="Part Notes"
                    rows={3}
                    onChange={handleChange('notes')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="default">Close</Button>
                <Button onClick={onSubmit} color="primary" variant="contained">Add</Button>
            </DialogActions>
        </Dialog>
    );
}