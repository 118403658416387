import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import API from '../../../API';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import { MAX_UPLOAD_LIMIT_SIZE } from './../../../Constants';
import Typography from '@material-ui/core/Typography';

const initialState = {
    currentProfilePhoto: null,
    formData: {},
    formErrors: {},
    confirmationOpen: false,
    snackbarOpen: false
}

class UploadStaffProfilePhotoForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount(){
        this.loadStaffProfilePhoto();
    }
    loadStaffProfilePhoto = () => {
        API.get('/staff/' + this.props.staffId + '/profilePhoto').then(result => {
            this.setState({
                currentProfilePhoto: result.data
            });
        });
    };
    clearNewStaffProfilePhoto = () => {
        this.setState({
            formData: initialState.formData
        });
    };
    handleSubmit = () => {
        let formData = new FormData();
        formData.append('photo', this.state.formData.photo);
        API.post('/staff/' + this.props.staffId + '/profilePhoto', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(result => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                let newPhoto = this.state.formData.photo;
                this.setState(initialState,
                () => {
                    this.setState({
                        ...initialState,
                        currentProfilePhoto: URL.createObjectURL(newPhoto),
                        snackbarOpen: true
                    });
                });
            }
            this.props.scrollToTop();
        });
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }

    render() {
        const { formErrors, formData, currentProfilePhoto, snackbarOpen } = this.state;
        return (
            <React.Fragment>
                <Avatar alt="Current Profile Photo" src={(formData.photo && URL.createObjectURL(formData.photo)) || currentProfilePhoto} style={{height: 80, width: 80, margin: 0}} />
                <form noValidate autoComplete="off">
                    <DragFileInput
                        id="photoInput"
                        name="photo"
                        label="Choose Profile Photo (.png, .jpg, .jpeg, .bmp, .gif) *"
                        file={formData.photo}
                        errorText={formErrors && formErrors['generic']}
                        onChange={this.handleFileChange}
                        cancelOnClick={this.clearNewStaffProfilePhoto}
                        emptyText='No photo selected'
                    />
                    <div className='buttonRow' style={{justifyContent: 'space-between', alignItems:'center'}}>
                        <Typography variant="subtitle2">
                            <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                        </Typography>
                        <Button onClick={this.handleConfirmationOpen}
                                variant="contained"
                                color="primary"
                                disabled={!(formData.photo && formData.photo.name)}>
                            Upload
                        </Button>
                    </div>
                    <ConfirmationDialog 
                        open={this.state.confirmationOpen} 
                        success={this.handleConfirmationSuccess} 
                        close={this.handleConfirmationClose} 
                        title="Upload New Profile Photo?" 
                        message="Are you sure you want to upload a new profile photo?"
                    />
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={snackbarOpen}
                        onClose={this.handleSnackbarClose}
                        message="The staff member's new profile photo was successfully uploaded"
                    />
                </form>
            </React.Fragment>
        )
    }
}

export default UploadStaffProfilePhotoForm;