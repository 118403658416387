import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import API from '../../../API';
import ViewStaffProfileCard from './ViewStaffProfileCard';
import ViewStaffAuditLog from './ViewStaffAuditLog';
import ViewStaffMfaStatus from './ViewStaffMfaStatus';
import ViewStaffLastLogin from './ViewStaffLastLogin';
import ViewStaffAutoUpdatesCard from './ViewStaffAutoUpdatesCard';
import ViewStaffNotifications from './ViewStaffNotifications';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import { Redirect } from 'react-router-dom';

const initialState = {
    access: {
        updateStaff: false,
        staffAccess: false
    },
    staffData: {},
    isLoading: true,
    redirect: false
}

class ViewStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount(){        
        this.loadStaffData();
        this.getAccess();
    }
    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-staff'),
            API.get('/staff/my/access/check/staff-access')
        ])
        .then(([updateStaffRes, staffAccessRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    updateStaff: (updateStaffRes.data && updateStaffRes.data.has_access) || false,
                    staffAccess: (staffAccessRes.data && staffAccessRes.data.has_access) || false
                }
            });
        })
    }
    loadStaffData = () => {
        API.get('/staff/' + this.props.match.params.staffId, { 
            params: { 
                loginAudit: true
            } 
        })
        .then(result => {
            if (result.data.errors) {
                this.setState({redirect: true});
            } else {
                this.setState({
                    ...this.state,
                    staffData: result.data,
                    isLoading: false,
                });
            }
        });
    };
    render() {
        const { history, location } = this.props;
        const { isLoading, staffData, access } = this.state;
        if (this.state.redirect) {
            return <Redirect to='/staff/search'/>
        } else if(this.state.isLoading) {
            return (
                <React.Fragment>
                    <LoadingCircle />
                </React.Fragment>
            )
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    Staff Profile
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ViewStaffProfileCard staffData={staffData} access={access} history={history} isLoading={isLoading} location={location}  />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    Staff Automated Email Updates
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ViewStaffAutoUpdatesCard staffData={staffData} history={history} isLoading={isLoading} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    Staff Notifications
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ViewStaffNotifications staffData={staffData} history={history} isLoading={isLoading} />
                            </Grid>
                        </Grid>                 
                    </Grid>
                    <Grid item xs={12} lg={6}>      
                        <Grid container spacing={3}>      
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    Account Security
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>                        
                                        <ViewStaffMfaStatus status={[staffData.staff_mfa,staffData.staff_mfa_activation_datetime]} isLoading={isLoading} />
                                    </CardContent>
                                </Card>                        
                            </Grid>                         
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>                        
                                        <ViewStaffLastLogin dateTime={staffData.staff_last_login_datetime} isLoading={isLoading}  />
                                    </CardContent>
                                </Card>                        
                            </Grid>                        
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>    
                                        <ViewStaffAuditLog data={staffData.login_audit} isLoading={isLoading} />                    
                                    </CardContent>
                                </Card>                        
                            </Grid> 
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    }
}

export default ViewStaff;