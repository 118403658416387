import React from 'react';
import DataTable from '../../Common/DataTables/DataTable';
import moment from 'moment';
// import PlaidLink from './PlaidLink';

class BankAccountList extends React.Component {
    render() {
        const { bankAccounts, getBankAccounts } = this.props;
        return (
            <React.Fragment>
                <DataTable  
                    config={{
                        key: 'bank_id',
                    }}
                    columns={[
                        {
                            heading: 'Name',
                            field: rowData => rowData.bank_name,
                            important: true,
                            sizeToContent: true
                        },
                        {
                            heading: 'Integrated Since',
                            field: rowData => moment(rowData.bank_created_datetime).format("DD-MM-YYYY"),
                            important: true,
                            sizeToContent: true
                        }
                        // ,{
                        //     heading: 'Status',
                        //     field: rowData => rowData?.link?.link_token ? <PlaidLink token={rowData?.link?.link_token} updateMode={true} bankId={rowData.bank_id} getBankAccounts={getBankAccounts} /> : rowData.bank_plaid_status,
                        //     important: true,
                        //     sizeToContent: true
                        // }
                    ]}
                    rows={bankAccounts}
                />
            </React.Fragment>
        );
    }
}

export default BankAccountList;