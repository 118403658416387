import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import API from 'API';
import { withStyles } from '@material-ui/core/styles';

import { Grid, Typography } from '@material-ui/core';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import SingleEmailDialog from 'Components/Common/Dialogs/SingleEmailDialog';

import BookCollectionDialog from './BookCollectionDialog';
import ReturnDownloadDialog from './ReturnDownloadDialog';
import ReturnEmailDialog from './ReturnEmailDialog';
import ReturnSearchBlock from './ReturnSearchBlock';
import ReviewReturnDialog from './ReviewReturnDialog';
import ApproveReturnDialog from './ApproveReturnDialog';
import DeclineReturnDialog from './DeclineReturnDialog';
import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import moment from 'moment';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

const initialState = () => ({
    isLoading: true,
    returns: [],
    searchData: {
        keywords: '',
        status:   '',
    },
    access: {
        view:       false,
        approve:    false,
        decline:    false,
        cancel:     false,
        confirm:    false,
        review:     false,
        inspect:    false,

        createCreditNote: false,
        createOrder: false,
    }
})

const statusIcons = {
    'Awaiting Approval': {
        icon:icons.orderAwaitingApproval,
        color:colors.orange,
        tooltip: 'Customer Return Awaiting Approval'
    },
    Declined: {
        icon:icons.false,
        color:colors.red,
        tooltip: 'Declined'
    },
    Cancelled: {
        icon:icons.false,
        color:colors.red,
        tooltip: 'Cancelled'
    },
    'Awaiting Confirmation': {
        icon:icons.true,
        color:colors.orange,
        tooltip: 'Customer Return Confirmed'
    },
    'Awaiting Collection': {
        icon:icons.truck,
        color:colors.orange,
        tooltip: 'Customer Return Collection Booked'
    },
    'Awaiting Inspection': {
        icon:icons.inspection,
        color:colors.orange,
        tooltip: 'Customer Return Awaiting Inspection'
    },
    'Awaiting Review': {
        icon:icons.boxes,
        color:colors.orange,
        tooltip: 'Customer Return Received'
    },
    'Completed': {
        icon:icons.true,
        color:colors.green,
        tooltip: 'Customer Return Completed'
    },
    'Awaiting Outcome': {
        icon:icons.timer,
        color:colors.orange,
        tooltip: 'Customer Return Awaiting Outcome'
    },
    'Repair': {
        icon:icons.parts,
        color:colors.orange,
        tooltip: 'Customer Return Awaiting Repair'
    }
}

class ViewCustomerReturns extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getAccess();
        this.setState({ 
            searchData: {
                ...this.state.searchData,
                status: this.props.stage,
                customerId: this.props.customerId
            }
        }, this.getReturns);
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/view-return'),
            API.get('/staff/my/access/check/approve-return'),
            API.get('/staff/my/access/check/decline-return'),
            API.get('/staff/my/access/check/cancel-return'),
            API.get('/staff/my/access/check/confirm-return'),
            API.get('/staff/my/access/check/reveiew-return'),
            API.get('/staff/my/access/check/inspect-return'),
            API.get('/staff/my/access/check/add-credit-note'),
            API.get('/staff/my/access/check/add-order'),
        ]).then(res => {
            this.setState({
                access: {
                    view:       res[0]?.data?.has_access || false,
                    approve:    res[1]?.data?.has_access || false,
                    decline:    res[2]?.data?.has_access || false,
                    cancel:     res[3]?.data?.has_access || false,
                    confirm:    res[4]?.data?.has_access || false,
                    review:     res[5]?.data?.has_access || false,
                    inspect:    res[6]?.data?.has_access || false,

                    createCreditNote: res[7]?.data?.has_access || false,
                    createOrder: res[8]?.data?.has_access || false,
                }
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.stage !== this.props.stage) {
            this.setState({ 
                isLoading: true,
                searchData: {
                    ...this.state.searchData,
                    status: this.props.stage
                }
            }, this.getReturns);
        }
    }

    getReturns = () => {
        Promise.all([
            API.get('/customerReturns/returns', { params: this.state.searchData }),
            // API.get('/customerReturns/returns', { params: this.state.searchData, old: true}),
        ]).then(res => {
            this.setState({ 
                // returns: [...res[0].data, ..._.map(res[1].data, i => ({...i, old: true}))] ,
                returns: res[0].data,
                isLoading: false 
            })
        })
    }

    handleSetSearch = props => {
        this.setState({
            searchData: {...props}
        },this.getReturns)
    }

    showApproval = row => {
        this.props.deployDialog(
            <ApproveReturnDialog
                id={row.cr_id}
                onApproval={
                    (notes) => {
                        this.props.deployConfirmation(
                            'Are you sure you want to approve this return?',
                            'Approve Return',
                            ()=>{ this.handleApprove(row, notes); this.props.closeDialog(); }
                        )
                    }
                }
                onClose={this.props.closeDialog}
            />, <>You Are About To <b>Approve</b> This Customer Return</>,
            'success', 'lg'
        );
    }

    handleApprove = (row, notes) => {
        API.post(`/customerReturns/returns/${row.cr_id}/approve`, {notes}).then(res => {
            this.handleEmail(row.cr_id, 15);
            this.props.deploySnackbar('Return Approved', 'success');
        });
    }

    showDecline = row => {
        this.props.deployDialog(
            <DeclineReturnDialog
                id={row.cr_id}
                onClose={this.props.closeDialog}
                onApproval={
                    (notes) => {
                        this.props.deployConfirmation(
                            'Are you sure you want to decline this return?',
                            'Decline Return',
                            ()=>{ this.handleDecline(row, notes); this.props.closeDialog(); }
                        )
                    }
                }
            />, <>You Are About To <b>Decline</b> This Customer Return</>,
            'warning', 'lg'
        );
    }

    handleDecline = (row, notes) => {
        API.post(`/customerReturns/returns/${row.cr_id}/decline`, {notes}).then(res => {
            this.handleEmail(row.cr_id, 10);
            this.props.deploySnackbar('Return Declined', 'error');
        });
    }

    handleEmail = (id, template, callBack) => {
        Promise.all([
            API.get(`/customerReturns/emailTemplates/${template}`),
            API.get(`/customerReturns/returns/${id}`)
        ])
        .then(res => {
            let title = '';
            switch(template) {
                case 9:
                    title = 'You Are About To Email - Customer Return Acknowledgement';
                break;
                case 11:
                    title = 'You Are About To Email - Customer Return Confirmation';
                break;
                case 10:
                    title = 'You Are About To Email - Customer Return Declined';
                break;
                case 14:
                    title = 'You Are About To Email - Customer Return Cancelled';
                break;
                case 12:
                    title = 'You Are About To Email - Customer Return Received Confirmation';
                break;
                case 13:
                    title = 'You Are About To Email - Customer Return Complete Confirmation';
                break;
                case 16:
                    title = 'You Are About To Email - Customer Return Collection Confirmation';
                break;
                case 15:
                    title = 'You Are About To Email - Customer Return Confirmation';
                break;
                default:
                    title = 'You Are About To Email - Customer Return';
                break;
            }
            this.props.deployDialog(
                <SingleEmailDialog
                    contactId={res[1].data.cr_collection_address_contact_id}
                    customerId={res[1].data.cr_cust_id}
                    subject={res[0].data.cret_name}
                    body={res[0].data.cret_text}
                    emailUrl={`/customerReturns/returns/${id}/sendEmail`}
                    postParams={{
                        templateId: template,
                    }}
                    onSubmit={()=>{this.getReturns(); callBack && callBack()}}
                    onClose={()=>{this.getReturns(); callBack && callBack()}}
                />, title, 'success', 'md'
            )
        })
    }

    handleConfirm = (row) => {
        this.props.deployDialog(
            <BookCollectionDialog cr_id={row.cr_id} onSubmit={()=>{ this.handleEmail(row.cr_id, 16); this.props.deploySnackbar('Collection Booked', 'success') }}/>,
            <>You Are About To <b>Process</b> This Customer Return Collection</>, 'success', 'lg');
    }

    handleCollectStock = (row) => {
        this.props.history.push(`/returns/customerReturn/pick/${row.cr_id}`);
    }
    
    handleReview = (row) => {
        this.props.deployDialog(
            <ReviewReturnDialog cr_id={row.cr_id} onSubmit={(outcome) => { outcome === 'Recheck' ? this.getReturns() : this.handleEmail(row.cr_id, 13, ()=>{
                switch (outcome) {
                    case 'Refund':
                        this.props.history.push(`/returns/creditnote/add/fromReturn/${row.cr_id}`);
                        break;
                    case 'Replace':
                        this.props.history.push(`/sales/order/add/fromReturn/${row.cr_id}`);
                        break;
                    default:
                        break;
                }
            })}}/>,
            'Review Customer Return', 'success', 'lg');
    }

    handleCancel = (row) => {
        API.post(`/customerReturns/returns/${row.cr_id}/cancel`).then(this.getReturns);
    }

    showInspection = row => {
        this.props.history.push(`/returns/customerReturn/inspection/${row.cr_id}`);
        // this.props.deployDialog(
        //     <InspectReturnDialog
        //         crId={row.cr_id}
        //         onClose={this.handleDialogClose}
        //     />,
        //     'Inspect Customer Return', 
        //     'success', 
        //     'lg'
        // );
    }

    handleDialogClose = () => {
        this.getReturns();
        this.props.closeDialog();
    }

    markComplete = (i) => {
        API.post(`/customerReturns/returns/${i.cr_id}/complete`).then(this.getReturns);
    }

    render() {

        const { returns, isLoading, access } = this.state;

        if (isLoading) return ( <LoadingCircle/> )


        let tableColumns = _.filter([
            ,
            ...(this.props.stage === 'Awaiting Collection') ? [
                {
                    heading:       '',
                    field:         i => moment(i.collection?.crc_delivery_date).endOf('day').isBefore(moment()) &&
                        <AllIcon
                            color={colors.red}
                            icon={icons.warning}
                            noMargin
                            heavy
                        />,
                    sizeToContent: true,
                    alignment:     'center',
                },
                {
                    heading:       'Date',
                    field:         'collection.crc_date',
                    fieldFormat:   'clenydate',
                    sizeToContent: true,
                    style: i => ({
                        color: moment(i.collection?.crc_delivery_date).endOf('day').isBefore(moment()) && colors.red,
                        fontWeight: moment(i.collection?.crc_delivery_date).endOf('day').isBefore(moment()) && 'bold'
                    })
                },
                {
                    heading:       'Delivery Date',
                    field:         'collection.crc_delivery_date',
                    fieldFormat:   'clenydate',
                    sizeToContent: true,
                    style: i => ({
                        color: moment(i.collection?.crc_delivery_date).endOf('day').isBefore(moment()) && colors.red,
                        fontWeight: moment(i.collection?.crc_delivery_date).endOf('day').isBefore(moment()) && 'bold'
                    })
                }
            ]:[
                {
                    heading:       'Date',
                    field:         'cr_date',
                    fieldFormat:   'clenydate',
                    sizeToContent: true,
                    
                }
            ],
            ...(this.props.stage === 'Awaiting Collection') ? [
                {
                    heading: 'Collection Method',
                    field:   'collection.crc_method',
                    sizeToContent: true,
                }
            ]:[

            ],
            {
                actionsCustomHeader:    true,
                important:              true,
                alignment:              'left',
                heading:                'Reference',
                actions: i => {
                    let a = [ 
                        { label: `${i.cr_reference}-${i.cr_reference_amendment > 9 ? i.cr_reference_amendment : '0' + i.cr_reference_amendment}`, link: `/returns/customerReturn/${i.cr_id}`, type:'plainLink', color: colors.red},
                        { label: `${i?.order?.order_reference}-${i?.order?.order_reference_amendment}`,  type:'plainLink', class: "textSecondary", link: `/sales/order/view/${i?.order?.order_id}`},
                    ]
                    if (i?.order?.credit_notes?.length > 0) {
                        _.each(i?.order?.credit_notes, j => {
                            a.push({ 
                                label: `${j.cn_reference}-${j.cn_reference_amendment > 9 ? j.cn_reference_amendment : '0' + j.cn_reference_amendment}`,  
                                link: `/returns/creditnote/${j.cn_id}`,
                                type:'plainLink', 
                                color: colors.red
                            })
                        })
                    }
                    return a;
                },
            },
            ...(this.props.stage === 'Awaiting Collection') ? [
            ]:[
                {
                    heading: 'Discount',
                    field:   'cr_has_discount',
                    fieldFormat:   'boolean',
                    sizeToContent: true,
                    alignment:     'center',
                    tooltip:       i => i.cr_has_discount ? 'With Discount' : 'Without Discount' ,
                },
                {
                    heading:        'Restocking Fee',
                    field:          i => parseFloat(i.cr_restock_fee) > 0,
                    fieldFormat:   'boolean',
                    sizeToContent: true,
                    alignment:     'center',
                    tooltip:       i =>parseFloat(i.cr_restock_fee) > 0 ? 'With Restocking Fee' : 'Without Restocking Fee' ,
                },
            ],
            {
                heading: 'Customer',
                field: rowData =>   <span className={this.props.classes.custName} onClick={()=>{ this.props.history.push(`/customers/view/${rowData.cr_cust_id}`); }}>
                                        {rowData.cr_cust_name}
                                        {' '}
                                        <Typography component="div" variant="body2">
                                            {rowData.cr_collection_address_name}
                                        </Typography>
                                    </span>,
                important: true,
                info:    rowData =>     <div>
                                            <Typography variant="body2">
                                                <b>Collection Contact:</b><br />
                                                {rowData.cr_collection_address_contact_name} <br/>
                                                {rowData.cr_collection_address_contact_email}<br/>
                                                {rowData.cr_collection_address_contact_phone} {!!rowData.cr_collection_address_contact_mobile && !!rowData.cr_collection_address_contact_phone && '/' } {rowData.cr_collection_address_contact_mobile} <br/>
                                                <br /><b>Collection Address:</b><br />
                                                {rowData.cr_collection_address.split('\n').map((item, key) => {
                                                    return  <span key={key}>
                                                                {item}{rowData.cr_collection_address.split('\n').length === key+1 ? null : <br/>}
                                                            </span>
                                                })}
                                            </Typography>
                                        </div>,
                infoEnd: 'stretch',
                truncate: true,
            },
            !this.props.stage && 
            {
                heading:       'Status',
                field:         i => <AllIcon
                    icon={statusIcons[i.cr_status]?.icon ?? icons.unknown}
                    color={statusIcons[i.cr_status]?.color ?? colors.disabled}
                    tooltip={statusIcons[i.cr_status]?.tooltip ?? i.cr_status}
                    noMargin
                />,
                sizeToContent: true,
                alignment:     'center',
            },
            {
                heading: 'Parts',
                field:   i => i.details.length,
                sizeToContent: true,
                alignment: 'center',
            },
            {
                heading: 'Qty',
                field:   i => _.sumBy(i.details, j => parseFloat(j.crd_qty)),
                sizeToContent: true,
                fieldFormat: 'decimal:3',
            },
            ...(this.props.stage === 'Awaiting Review') ? 
                [
                    {
                        heading: 'Received Parts',
                        sizeToContent: true,
                        alignment: 'center',
                        field:   i => _.filter(i.collection?.details, j => parseFloat(j.crcd_recieved) > 0).length,
                    },
                    {
                        heading: 'Received Qty',
                        field:   i => _.sumBy(i.collection?.details, j => parseFloat(j.crcd_recieved)),
                        sizeToContent: true,
                        fieldFormat: 'decimal:3',
                    }
                ]: [],
            {
                heading: 'Return Total',
                field: i => parseFloat(i.cr_total_with_restock).toFixed(2),
                fieldPrefix: '-£',
                style: {color: colors.red},
                sizeToContent: true,
                fieldFormat: 'redMinus:decimal:2',
                info: i =>  <Grid container
                                spacing={1}
                                style={{fontSize: '0.8rem'}}
                            >
                                <Grid item xs={6} style={{textAlign: 'right'}}>
                                    <b>Sub Total</b>
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'right'}}>
                                    -£{parseFloat(i.cr_total).toFixed(2)}
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'right'}}>
                                    <b>VAT ({parseInt(i.cr_vat_rate * 100)}%)</b>
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'right'}}>
                                    -£{parseFloat(i.cr_total * i.cr_vat_rate).toFixed(2)}
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'right'}}>
                                    <b>Total</b>
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'right'}}>
                                    -£{parseFloat(i.cr_total_with_restock).toFixed(2)}
                                </Grid>
                            </Grid>
            }
        ], i => i);

        let header = 'All Customer Returns';
        let actions = _.filter([
            {
                name: 'Download',
                icon: icons.download,
                onClick: i => {
                    this.props.deployDialog(<ReturnDownloadDialog cr={i}/>, 'Download Previous Documents', '', 'md');
                },
                disabled: !access.view
            }, 
            {
                name: 'Email',
                icon: icons.email,
                onClick: i => {
                    this.props.deployDialog(<ReturnEmailDialog cr={i} />, 'Email Previous Documents', '', 'md');
                },
                disabled: !access.view
            },
            {
                name: 'View',
                icon: icons.view,
                onClick: i => {
                    this.props.history.push(`/returns/customerReturn/${i.cr_id}`);
                },
                disabled: !access.view
            },
            !this.props.customerId &&
            {
                name: 'View this Customers Returns',    
                icon: 'file-search',    
                onClick: i => {
                    this.props.history.push(`/customers/view/${i.cr_cust_id}/CustomerReturns`);
                },
            },  
        ], i => i);

        switch (this.props.stage) {
            case 'Awaiting Inspection':
                header = 'Customer Returns Inspection';
                actions = [...actions,
                    {
                        name: 'Inspect',
                        icon: icons.inspection,
                        onClick: this.showInspection,
                        disabled: !access.view
                    }
                ];
                break;
            case 'Awaiting Approval':
                header = 'Customer Returns Approval';
                actions = [...actions, 
                    {
                        name:    'Approve',
                        icon:    icons.true,
                        onClick: this.showApproval,
                        disabled: !access.approve
                    },
                    {
                        name:    'Decline',
                        icon:    icons.false,
                        onClick: this.showDecline,
                        disabled: !access.decline
                    }
                ];
                break;
            case 'Awaiting Confirmation':
                header = 'Customer Returns Confirmation';
                actions = [...actions,
                    {
                        name:    'Confirm',
                        icon:    icons.true,
                        onClick: this.handleConfirm,
                        disabled: !access.confirm
                    }
                ];
                break;
            case 'Awaiting Collection':
                header = 'Customer Returns Delivery';
                actions = [...actions,
                    {
                        name:    'Collect',
                        icon:    icons.picking,
                        onClick: this.handleCollectStock,
                        disabled: !access.inspect,
                        color: row => (_.filter(row?.collection?.details, j => j.crcd_status === 'Awaiting').length === row?.collection?.details?.length) ? colors.red : 
                                        ( _.filter(row?.collection?.details, j => j.crcd_status === 'Awaiting').length > 0 ? colors.orange : colors.green )
                    }
                ];
                break;
            case 'Awaiting Review':
                header = 'Customer Returns Review';
                actions = [...actions,
                    {
                        name:    'Review',
                        icon:    icons.review,
                        onClick: this.handleReview,
                        disabled: !access.review
                    }
                ];
                break;
            case 'Completed':
                header = 'Customer Returns Completed';
                break;
            case 'Cancelled':
                header = 'Customer Returns Cancelled';
                break;
            case 'Declined':
                header = 'Customer Returns Declined';
                break;
            case 'Awaiting Outcome':
                header = 'Customer Returns Post Review';
            default:
                break;
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                    <Typography variant="h5" gutterBottom>{ header }</Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <ReturnSearchBlock callBack={this.handleSetSearch} disableStatus={this.props.stage} disableReset
                     />
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable
                            config={{
                                key: 'cr_id',
                                pagination: true,
                                style: i => ({
                                    backgroundColor: (this.props.stage === 'Awaiting Collection' && moment(i.collection?.crc_delivery_date).endOf('day').isSame(moment().endOf('day'))) && 'rgb(241, 248, 233)'
                                })
                            }}
                            columns={_.filter([
                                ...tableColumns, 
                                {actions: i =>
                                    {
                                        return this.props.stage === 'Awaiting Outcome' ? 
                                            [...actions,
                                                {
                                                    name:    'Create Credit Note',
                                                    icon:    icons.creditNote,
                                                    link: `/returns/creditnote/add/fromOutcome/${i?.outcomes_refund?.cro_id}`,
                                                    disabled: (!access.createCreditNote || !i.outcomes_refund || i?.outcomes_refund?.cro_outcome_id),
                                                    color: (!access.createCreditNote || !i.outcomes_refund) ? colors.disabled : (i?.outcomes_refund?.cro_outcome_id ? colors.green : colors.red)
                                                },
                                                {
                                                    name:    'Create Repair Order',
                                                    icon:    icons.repair,
                                                    link: `${ i.outcomes_repair?.repair_order?.rog_quote_id ? '/sales/order/amend/fromOutcome/' : '/sales/order/add/fromOutcome/' }${i?.outcomes_repair?.cro_id}`,
                                                    disabled: (!access.createOrder || !i.outcomes_repair || i?.outcomes_repair?.cro_outcome_id || !['Completed', 'Cancelled', 'Write Off'].includes(i.outcomes_repair?.repair_order?.rog_status)),
                                                    color:  (!access.createOrder || !i?.outcomes_repair) ? colors.disabled : (i?.outcomes_repair?.cro_outcome_id ? colors.green : (['Completed', 'Cancelled', 'Write Off'].includes(i.outcomes_repair?.repair_order?.rog_status) ? colors.red : colors.orange))
                                                },
                                                {
                                                    name:    'Create Replacement Order',
                                                    icon:    icons.repeat,
                                                    link:  `/sales/order/add/fromOutcome/${i?.outcomes_replace?.cro_id}`,
                                                    disabled: (!access.createOrder || !i.outcomes_replace || i?.outcomes_replace?.cro_outcome_id),
                                                    color: (!access.createOrder || !i.outcomes_replace ) ? colors.disabled : (i?.outcomes_replace?.cro_outcome_id ? colors.green : colors.red)
                                                },
                                                {
                                                    name: 'Create Written Off Order',
                                                    icon: icons.broken,
                                                    disabled: !i?.outcomes_write_off || i?.outcomes_write_off?.cro_outcome_id ||  !(!access.createOrder || !i.outcomes_repair || i?.outcomes_repair?.cro_outcome_id || !['Completed', 'Cancelled', 'Write Off'].includes(i.outcomes_repair?.repair_order?.rog_status)),
                                                    color: i?.outcomes_write_off && (!access.createOrder || !i.outcomes_repair || i?.outcomes_repair?.cro_outcome_id || !['Completed', 'Cancelled', 'Write Off'].includes(i.outcomes_repair?.repair_order?.rog_status)) ? (i?.outcomes_write_off?.cro_outcome_id ? colors.green : colors.red) : colors.disabled,
                                                    link: `/sales/order/add/fromOutcome/${i?.outcomes_write_off?.cro_id}`,
                                                },
                                                {
                                                    name: 'Mark Complete',
                                                    icon: icons.true,
                                                    disabled: (i.outcomes_refund || i.outcomes_repair || i.outcomes_replace || i.outcomes_write_off),
                                                    onClick: (i) => this.props.deployConfirmation( 
                                                        'Are you sure you want to mark this return as complete?',
                                                        'Mark Return Complete',
                                                        ()=>this.markComplete(i)
                                                    ),
                                                }
                                            ] : actions
                                    }
                                }], i=>i)}
                            rows={_.orderBy(returns, [ i => (this.props.stage === 'Awaiting Collection') ? i?.collection?.crc_date : i.cr_date, 'cr_reference'], [(this.props.stage === 'Awaiting Collection') ? 'asc' : 'desc', 'desc'])}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        )
    }
}

const styles = theme => ({
    custName: {
        '&:hover':{cursor: 'pointer'}
    }
});

const mapDispatchToProps = dispatch => ({
    deployDialog: (content, title, variant, size='md')  => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:  ()                                    => dispatch(closeDialog()),
    deployConfirmation: (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
    deploySnackbar: (msg, variant)                      => dispatch(deploySnackBar(variant, msg)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(ViewCustomerReturns));
