import React from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import TaskCard from 'Components/Tasks/Misc/TaskCard';

const TaskColumn = props => (
    <Grid item xs={12} lg={3}>
        <Paper>
            <Grid container>
                <Grid item xs={12} className={props.classHeader}>
                    <Grid container spacing={2} alignItems='center'>
                        <Grid item>
                            <FALightIcon icon={props.icon} style={{color: props.color}} className={props.classIcon} noMargin /> 
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h6" style={{color: props.color}}>
                                {props.title}
                            </Typography>
                        </Grid>
                        <Grid item align='right'>
                            <Typography variant="h6" style={{color: props.color}}>
                                {_.size(props.tasks)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {!_.isEmpty(props.tasks) && (
                    <Grid item xs={12}>
                        <Grid container className={props.classColumn}>
                           {_.map(props.tasks, (task, idx) => (
                                <Grid item xs={12} key={idx}>
                                    <TaskCard task={task} callback={props.callback} displayStatus={props.displayStatus} />                
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Paper>
    </Grid>
)

export default TaskColumn;