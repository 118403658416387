import API from 'API';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import ScanDialog from './ScanDialog';

import { colors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import { AppBar, Button, Dialog, DialogActions, DialogContent, FormControl, Grid, Hidden, Tab, Tabs, TextField, Typography } from '@material-ui/core/';

import logo from 'Assets/Images/clenaware_logo_icon.png';

const initialState = {
    lists: {
        parts:   [],
    },
    formDataCorrection: {
        notes: ''
    },
    formDataDetail: {
        part: 0
    },
    stockCorrection: {},
    details: [],
    access: {},
    showEditDialog: false,
    isLoading: true,
    subLoading: false,
    currentTab: 0,
    formErrors: {},
    showScan: false,
}


class EditStockCorrection extends Component {
    constructor(props) {
        super(props);
        this.state = {...initialState, id: this.props.match.params.scId }
    }

    componentDidMount() {
        this.getAccess();
        this.getData();
    }

    handleTabChange = (event, value) => { this.setState({currentTab: value});};

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/stock-correction:delete'),
            API.get('/staff/my/access/check/stock-correction:edit-part'),
            API.get('/staff/my/access/check/stock-correction:add-part'),
            API.get('/staff/my/access/check/stock-correction:submit'),
            API.get('/staff/my/access/check/stock-correction:save'),
        ])
        .then(([deleteRes,editRes,addRes,submitRes, saveRes]) => {
            this.setState({
                access: {
                    delete: (deleteRes?.data?.has_access) || false,
                    edit:   (editRes?.data?.has_access)   || false,
                    add:    (addRes?.data?.has_access)    || false,
                    submit: (submitRes?.data?.has_access) || false,
                    save: (saveRes?.data?.has_access) || false,
                },
            })
        });
    }

    getData = () => {
        Promise.all([
            API.get(`/stock/correction/${this.props.match.params.scId}`),
        ]).then(([stockCorrectionRes]) => {
            API.get('/parts/all',{params:{withDefaultImg:true, isStockAble:true, withStock:true, withStockDate: stockCorrectionRes.data.sc_date}})
            .then(partsRes => {
                this.setState({
                    stockCorrection: stockCorrectionRes.data,
                    details: _.map(stockCorrectionRes.data.details, (i,idx) => _.assign({
                        idx,
                        id:              i.scd_id,
                        part:            i.scd_part_id,
                        stock:           i.scd_new_stock,
                        current:         parseFloat( _.find(partsRes.data, {part_id: i.scd_part_id})?.stock_activity.length ? 
                                            parseFloat( _.find(partsRes.data, {part_id: i.scd_part_id})?.stock_activity[0]?.stock_activity_new_qty) : 
                                            0
                                        ),
                        pendingSales:    i.scd_pending_despatch_stock,
                        pendingSupplier: i.scd_pending_supplier,
                        pendingDelivery: i.scd_pending_stock_delivery,
                        pendingMovement: i.scd_pending_stock_pending,
                        deleted:         false, 
                        edited:          false,
                    })),
                    formDataCorrection: {
                        notes: stockCorrectionRes.data.sc_notes
                    },
                    lists: {
                        parts:      _.map(partsRes.data, i => _.assign({
                            value: i.part_id,
                            label: `${i.part_number} - ${i.part_description}`, 
                            part:  i
                        })),
                    },
                    isLoading: false
                })
            });
        })
    }

    getDetails = () => {
        API.get(`/stock/correction/${this.props.match.params.scId}`)
        .then(stockCorrectionRes => {
            this.setState({
                details: _.map(stockCorrectionRes.data.details, (i,idx) => _.assign({
                    idx,
                    id:              i.scd_id,
                    part:            i.scd_part_id,
                    stock:           i.scd_new_stock,
                    current:         i.scd_current_stock,
                    pendingSales:    i.scd_pending_despatch_stock,
                    pendingSupplier: i.scd_pending_supplier,
                    pendingDelivery: i.scd_pending_stock_delivery,
                    pendingMovement: i.scd_pending_stock_pending,
                    deleted:         false, 
                    edited:          false,
                })),
                subLoading: false
            });
        });
    }

    handleTabChange = (event, value) => { this.setState({currentTab: value});};

    handleSelectChange = fd => f => e => {
        let value = e ? e.value : null;
        this.setState({
            [fd]: {
                ...this.state[fd],
                [f]: value
            }
        });
    }

    addPart = (stock = 0) => {
        this.setState({ subLoading: true},()=>{
            API.get(`/stock/${this.state.formDataDetail.part}/breakdown`)
            .then(res => {
                this.setState({
                    details: [...this.state.details, {
                        idx:             this.state.details.length,
                        id:              0,
                        part:            this.state.formDataDetail.part,
                        stock:           stock,
                        current:         res.data.stock,
                        pendingSales:    res.data.pending.despatch,
                        pendingSupplier: res.data.pending.supplier,
                        pendingDelivery: res.data.pending.delivery,
                        pendingMovement: res.data.pending.movement,
                        deleted:         false, 
                        edited:          false,
                    }],
                    formDataDetail: initialState.formDataDetail,
                    subLoading: false
                });
            });
        });
    }

    handleChangePart = idx => e => {
        this.setState({
            details: _.map(this.state.details, i => {
                if (i.idx === idx) {
                    return {
                        ...i,
                        stock: e.target.value,
                        edited: true
                    }
                }
                return i;
            })
        });
    }
    
    loadPartImage = (rowData) => {
        this.setState({ 
            partImageDialog: true,
            partImageDialogData: rowData.default_image?.file_path
        });
    };

    deleteRow = idx => {
        this.setState({
            details: _.map(this.state.details, i => {
                if (i.idx === idx) {
                    return {
                        ...i,
                        deleted: true
                    }
                }
                return i;
            })
        });
    }
    refreshRow = idx => {
        API.get(`/stock/${_.find(this.state.details, {idx}).part}/breakdown`)
        .then(res => {
            this.setState({
                details: _.map(this.state.details, i => {
                    if (i.idx === idx) {
                        return {
                            ...i,
                            current:         res.data.stock || 0,
                            pendingSales:    res.data.pending.despatch || 0,
                            pendingSupplier: res.data.pending.supplier || 0,
                            pendingDelivery: res.data.pending.delivery || 0,
                            pendingMovement: res.data.pending.movement || 0,
                            edited:          true,
                        }
                    }
                    return i;
                })
            });
        });
    }

    submit = () => {
        let promiseArr = [];

        _.map(this.state.details, i => {
            if (i.deleted && i.id){
                promiseArr.push(API.post(`/stock/correction/${this.props.match.params.scId}/${i.id}/remove`))
            } else if (i.id == 0 && !i.deleted){
                promiseArr.push(API.post(`/stock/correction/${this.props.match.params.scId}/addPart`,{qty: i.stock, partId: i.part}))
            } else if (i.edited && !i.deleted && i.id) {
                promiseArr.push(API.post(`/stock/correction/${this.props.match.params.scId}/${i.id}/update`,{qty: i.stock, partId: i.part}))
            }
        })

        Promise.all(promiseArr)
        .then(res => {
            API.post(`/stock/correction/${this.props.match.params.scId}/submit`,{notes: this.state.formDataCorrection.notes})
            .then(result => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({formErrors: formatValidationErrors(result.data.errors)});
                } else {
                    this.props.history.push(`/stock/correction`);
                }
            })
        })
    }


    handleNoteChange = e => { this.setState({ formDataCorrection: {notes: e.target.value} }); }

    save = () => {
        this.setState({ subLoading: true},()=>{

            let promiseArr = [];
            _.map(this.state.details, i => {
                if (i.deleted && i.id){
                    promiseArr.push(API.post(`/stock/correction/${this.props.match.params.scId}/${i.id}/remove`))
                } else if (i.id == 0 && !i.deleted){
                    promiseArr.push(API.post(`/stock/correction/${this.props.match.params.scId}/addPart`,{qty: i.stock, partId: i.part}))
                } else if (i.edited && !i.deleted && i.id) {
                    promiseArr.push(API.post(`/stock/correction/${this.props.match.params.scId}/${i.id}/update`,{qty: i.stock, partId: i.part}))
                }
            })
            Promise.all(promiseArr)
            .then(res => {
                API.post(`/stock/correction/${this.props.match.params.scId}/save`, {notes: this.state.formDataCorrection.notes})
                .then(res => {
                    this.setState({subLoading: false});
                    this.props.deploySnackBar('success','Stock Correction Saved');   
                    setTimeout(()=>{this.getDetails();},500)
                })
            });
            /*
            API.post(`/stock/correction/${this.props.match.params.scId}/save`, {notes: this.state.formDataCorrection.notes})
            .then(res => {
                this.setState({
                    details:    _.map(this.state.details, i => {
                                    if (i.deleted && i.id){
                                        API.post(`/stock/correction/${this.props.match.params.scId}/${i.id}/remove`)
                                    } else if (i.id == 0 && !i.deleted){
                                        API.post(`/stock/correction/${this.props.match.params.scId}/addPart`,{qty: i.stock, partId: i.part})
                                    } else if (i.edited && !i.deleted && i.id) {
                                        API.post(`/stock/correction/${this.props.match.params.scId}/${i.id}/update`,{qty: i.stock, partId: i.part})
                                    }
                                    return i;
                                }),
                },()=>{
                    this.props.deploySnackBar('success','Stock Correction Saved');   
                    setTimeout(()=>{
                        this.getDetails();
                    },500)
                    
                })
            })
            */
        });
    }

    addScannedPart = (formDataDetail, close=false) => {
        if (!formDataDetail.qty || !formDataDetail.part) {
            this.setState({ showScan: !close});
        } else {
            this.setState({
                showScan: !close,
                formDataDetail
            }, ()=>{
                this.addPart(this.state.formDataDetail.qty);
            })
        }
    }
    render() {
        const {showScan,isLoading, subLoading, lists, details, showEditDialog, stockCorrection, formDataDetail, formDataCorrection, access, currentTab, formErrors} = this.state;
        
        if (isLoading) return( <LoadingCircle/> );
        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Amend Stock Correction
                    </Typography>
                </Grid>
                {!!access.add &&
                    <Hidden smDown>
                        <Grid item xs={6}>
                            <PaddedPaper>
                                <FormControl fullWidth margin="none">
                                    <AutoCompleteSelect 
                                        options={_.filter(_.filter(lists.parts, i => !i.part.part_locked), i => !_.find(details, j => j.part == i.value && !j.deleted))} 
                                        label='Part *'
                                        onChange={this.handleSelectChange('formDataDetail')('part')}
                                        value={formDataDetail.part || null}
                                    />
                                </FormControl>
                                <div className='buttonRow'><Button onClick={this.addPart} variant="contained" color="primary"> Add </Button></div>
                            </PaddedPaper>
                        </Grid>
                    </Hidden>
                }
                <Grid item xs={12} lg={6}>
                    <PaddedPaper>
                        <Grid container item spacing={3}>
                            <Grid item sm={3}>
                                <Typography variant="subtitle1">
                                    Stock Correction:
                                </Typography>
                            </Grid>
                            <Grid item sm={9}>
                                <Typography variant="subtitle1">
                                    {stockCorrection.sc_ref}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item sm={3}>
                                <Typography variant="subtitle1">
                                    Date:
                                </Typography>
                            </Grid>
                            <Grid item sm={9}>
                                <Typography variant="subtitle1">
                                    {clenyDate(stockCorrection.sc_date)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item sm={3}>
                                <Typography variant="subtitle1">
                                    Created By:
                                </Typography>
                            </Grid>
                            <Grid item sm={9}>
                                <Typography variant="subtitle1">
                                    {stockCorrection.sc_created_by}
                                </Typography>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                {!!access.add &&
                    <>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Button onClick={()=>{this.setState({
                                    showScan: true
                                })}} variant='contained' color='primary' fullWidth>Scan In Part Correction</Button>
                            </PaddedPaper>
                        </Grid>
                        <Dialog
                            open={showScan}
                            fullScreen
                        >
                            <DialogContent>
                                <ScanDialog
                                    parts=   {_.filter(_.filter(lists.parts, i => !i.part.part_locked), i => !_.find(details, j => j.part == i.value && !j.deleted))} 
                                    addPart= {this.addScannedPart}
                                />
                            </DialogContent>
                        </Dialog>
                    </>
                }
                <Grid item xs={12}>
                    <AppBar position="static" style={{backgroundColor:'white', color:"#000",  width: '100%', zIndex:'auto', marginTop:0}} >
                        <Tabs   value={currentTab} 
                                onChange={this.handleTabChange} 
                                className='xs-max-width' 
                                indicatorColor="primary" 
                                textColor="primary" 
                                variant='scrollable'
                                scrollButtons="auto"
                        >
                            <Tab label='Detailed' />
                            <Tab label='Overview' />
                        </Tabs>
                    </AppBar>
                </Grid>
                {currentTab == 1 &&
                    <>
                        {_.filter(details, i=>!i.deleted && i.current - i.stock < 0).length > 0 &&
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h4"><AllIcon size='large' icon={icons.plus} style={{color:colors.green}} /> Stock In</Typography>
                                    <br/>
                                    <DataTable
                                        config={{
                                            key: 'partId',
                                            sticky:                 true,
                                            alternatingRowColours:  true,
                                            responsiveimportant:    true,
                                        }}
                                        columns={[
                                            {
                                                heading:        'Number',
                                                field:          rowData => _.find(lists.parts,i=>i.value == rowData.part).part.part_number,
                                                dataRef:        rowData => _.find(lists.parts,i=>i.value == rowData.part).part.part_number,
                                                important:      true,
                                                sizeToContent:  true,
                                                style:          {width: '6%'},
                                            },
                                            {
                                                heading:        'Description',
                                                field:          rowData => _.find(lists.parts,i=>i.value == rowData.part).part.part_description,
                                                dataRef:        rowData => _.find(lists.parts,i=>i.value == rowData.part).part.part_description,
                                                important:      true,
                                                main:           true,
                                                style:          {width: '32%'},
                                            },
                                            {
                                                heading:    'Opening Stock',
                                                field:      rowData => parseFloat(rowData.current),
                                                alignment:  'center',
                                                dataRef:    'current',
                                                style:      {width: '20%'},
                                            },
                                            {
                                                heading:    'Stock In',
                                                field:      rowData => Math.abs(rowData.current - rowData.stock),
                                                dataRef:    rowData => rowData.current - rowData.stock,
                                                alignment:  'center',
                                                style:      {width: '22%'},
                                            },
                                            {
                                                heading:    'Closing Stock',
                                                field:      rowData => _.isNaN(parseFloat(rowData.stock)) ? '-' : parseFloat(rowData.stock),
                                                dataRef:    rowData => _.isNaN(parseFloat(rowData.stock)) ? 0 : parseFloat(rowData.stock),
                                                alignment:  'center',
                                                style:      {width: '20%'},
                                            },
                                            
                                        ]}
                                        rows={_.orderBy(_.filter(details, i=>!i.deleted && i.current - i.stock < 0), [o => _.find(lists.parts, i => i.value == o.part).part.part_number], ['asc'])}
                                    />
                                </PaddedPaper>
                            </Grid>
                        }
                        {_.filter(details, i=>!i.deleted && i.current - i.stock > 0).length > 0 &&
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h4"><AllIcon size='large' icon={icons.minus} style={{color:colors.red}} /> Stock Out</Typography>
                                    <br/>
                                    <DataTable
                                        config={{
                                            key: 'partId',
                                            sticky:                 true,
                                            alternatingRowColours:  true,
                                            responsiveimportant:    true,
                                        }}
                                        columns={[
                                            {
                                                heading:        'Number',
                                                field:          rowData => _.find(lists.parts,i=>i.value == rowData.part).part.part_number,
                                                dataRef:        rowData => _.find(lists.parts,i=>i.value == rowData.part).part.part_number,
                                                important:      true,
                                                sizeToContent:  true,
                                                style:          {width: '6%'},
                                            },
                                            {
                                                heading:        'Description',
                                                field:          rowData => _.find(lists.parts,i=>i.value == rowData.part).part.part_description,
                                                dataRef:        rowData => _.find(lists.parts,i=>i.value == rowData.part).part.part_description,
                                                important:      true,
                                                main:           true,
                                                style:          {width: '32%'},
                                            },
                                            {
                                                heading:    'Opening Stock',
                                                field:      rowData => parseFloat(rowData.current),
                                                alignment:  'center',
                                                dataRef:    'current',
                                                style:      {width: '20%'},
                                            },
                                            {
                                                heading:    'Stock out',
                                                field:      rowData => Math.abs(rowData.current - rowData.stock),
                                                dataRef:    rowData => rowData.current - rowData.stock,
                                                alignment:  'center',
                                                style:      {width: '22%'},
                                            },
                                            {
                                                heading:    'Closing Stock',
                                                field:      rowData => _.isNaN(parseFloat(rowData.stock)) ? '-' : parseFloat(rowData.stock),
                                                dataRef:    rowData => _.isNaN(parseFloat(rowData.stock)) ? 0 : parseFloat(rowData.stock),
                                                alignment:  'center',
                                                style:      {width: '20%'},
                                            },
                                            
                                        ]}
                                        rows={_.orderBy(_.filter(details, i=>!i.deleted && i.current - i.stock > 0), [o => _.find(lists.parts, i => i.value == o.part).part.part_number], ['asc'])}
                                    />
                                </PaddedPaper>
                            </Grid>
                        }
                    </>
                }
                {currentTab == 0 &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Hidden mdUp>
                                <DataTable
                                    config={{
                                        key: 'idx',
                                        alternatingRowColours: true,
                                        responsiveimportant: true,
                                        isLoading: subLoading,
                                        style: rowData => ({
                                            color: rowData.id && rowData.current !== _.find(lists.parts,i=>i.value == rowData.part).part?.stock?.stock_current_qty && colors.red
                                        })
                                    }}
                                    columns={[
                                        {
                                            field: rowData => {
                                                const part = _.find(lists.parts,i=>i.value == rowData.part).part
                                                return (
                                                    <>
                                                        {(rowData.id && rowData.current !== part.stock.stock_current_qty )?
                                                        <AllIcon 
                                                            icon={icons.warning}
                                                            color={colors.red}
                                                            tooltip='stock has changed since stock correction was created'
                                                            style={{padding: 0}}
                                                        /> : 
                                                        <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                            {part && part.default_image?.thumbnail_file_path ?
                                                                <Button onClick={() => this.loadPartImage(rowData)}>
                                                                    <img src={part.default_image?.thumbnail_file_path} alt={part.part_description} style={{maxHeight:35, maxWidth:35}} />
                                                                </Button> 
                                                            : 
                                                                <img src={logo} alt="logo" style={{maxHeight:35, maxWidth:35}}/>
                                                            }
                                                        </div>}
                                                    </>
                                                )
                                            },
                                            sizeToContent: true,
                                            alignment: 'center',
                                        },
                                        {
                                            heading: 'Number',
                                            field: rowData => _.find(lists.parts,i=>i.value == rowData.part).part.part_number,
                                            important: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: <>Pending <br/> Total</>,
                                            field: rowData => (parseFloat(rowData.pendingSales) + parseFloat(rowData.pendingDelivery) + parseFloat(rowData.pendingSupplier) + parseFloat(rowData.pendingMovement)),
                                            important: true,
                                            main: true,
                                            alignment: 'right',
                                        },
                                        {
                                            heading: <>Current <br/>Stock</>,
                                            field: rowData => parseFloat(rowData.current),
                                            important: true,
                                            main: true,
                                            alignment: 'right',
                                            style:{fontWeight: 'bold'}
                                        },
                                        {
                                            heading: <>New <br/>Stock</>,
                                            field: rowData => 
                                                <TextField
                                                    type="number"
                                                    value={parseFloat(rowData.stock)}
                                                    onChange={this.handleChangePart(rowData.idx)}
                                                    inputProps={{
                                                        min: 0,
                                                        style: { textAlign: 'center' }
                                                    }}
                                                />,
                                            important: true,
                                            main: true,
                                            alignment: 'center',
                                        },
                                        {
                                            heading: <>Stock <br/>Change</>,
                                            field: rowData => _.isNaN(Math.abs(rowData.current - rowData.stock)) ? '-' : `${rowData.current - rowData.stock > 0 ? '-' : '+'}${Math.abs(rowData.current - rowData.stock)}` ,
                                            important: true,
                                            main: true,
                                            style: rowData => ({color: !_.isNaN(Math.abs(rowData.current - rowData.stock)) && ( rowData.current - rowData.stock > 0 ? colors.red : colors.green )}),
                                            alignment: 'right',
                                            sizeToContent: true
                                        },
                                        {
                                            actions: rowData => {
                                                return([
                                                    {name: 'Refresh', icon: icons.refresh, onClick: ()=>{this.refreshRow(rowData.idx)}, disabled: !access.edit, hide: rowData.current == _.find(lists.parts,i=>i.value == rowData.part).part.stock.stock_current_qty},
                                                    {name: 'Remove',  icon: icons.delete,  onClick: ()=>{this.deleteRow(rowData.idx)},  disabled: !access.edit},
                                                ])
                                            }
                                        }
                                    ]}
                                    rows={_.orderBy(_.filter(details, i=>!i.deleted), [o => _.find(lists.parts, i => i.value == o.part).part.part_number], ['asc'])}
                                />
                            </Hidden>
                            <Hidden smDown>
                                <DataTable
                                    config={{
                                        key: 'idx',
                                        alternatingRowColours: true,
                                        responsiveimportant: true,
                                        isLoading: subLoading,
                                        style: rowData => ({
                                            color: rowData.id && rowData.current !== _.find(lists.parts,i=>i.value == rowData.part).part?.stock?.stock_current_qty && colors.red
                                        })
                                    }}
                                    columns={[
                                        {
                                            field: rowData => {
                                                const part = _.find(lists.parts,i=>i.value == rowData.part).part
                                                return (
                                                    <>
                                                        {(rowData.id && rowData.current !== part.stock.stock_current_qty )?
                                                        <AllIcon 
                                                            icon={icons.warning}
                                                            color={colors.red}
                                                            tooltip='stock has changed since stock correction was created'
                                                            style={{padding: 0}}
                                                        /> : 
                                                        <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                            {part && part.default_image?.thumbnail_file_path ?
                                                                <Button onClick={() => this.loadPartImage(rowData)}>
                                                                    <img src={part.default_image?.thumbnail_file_path} alt={part.part_description} style={{maxHeight:35, maxWidth:35}} />
                                                                </Button> 
                                                            : 
                                                                <img src={logo} alt="logo" style={{maxHeight:35, maxWidth:35}}/>
                                                            }
                                                        </div>}
                                                    </>
                                                )
                                            },
                                            sizeToContent: true,
                                            alignment: 'center',
                                        },
                                        {
                                            heading: 'Number',
                                            field: rowData => _.find(lists.parts,i=>i.value == rowData.part).part.part_number,
                                            important: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Description',
                                            field: rowData => _.find(lists.parts,i=>i.value == rowData.part).part.part_description,
                                            important: true,
                                            main: true,
                                        },
                                        {
                                            heading: <>Pending <br/> Sales Stock</>,
                                            field: rowData => parseFloat(rowData.pendingSales),
                                            important: true,
                                            main: true,
                                            alignment: 'right',
                                        },
                                        {
                                            heading: <>Pending <br/> Ordered Stock</>,
                                            field: rowData => parseFloat(rowData.pendingDelivery),
                                            important: true,
                                            main: true,
                                            alignment: 'right',
                                        },
                                        {
                                            heading: <>Pending <br/> Suppler Stock</>,
                                            field: rowData => parseFloat(rowData.pendingSupplier),
                                            important: true,
                                            main: true,
                                            alignment: 'right',
                                        },
                                        {
                                            heading: <>Pending <br/> Stock Change</>,
                                            field: rowData => parseFloat(rowData.pendingMovement),
                                            important: true,
                                            main: true,
                                            alignment: 'right',
                                        },
                                        {
                                            heading: <>Pending <br/> Total</>,
                                            field: rowData => (parseFloat(rowData.pendingSales) + parseFloat(rowData.pendingDelivery) + parseFloat(rowData.pendingSupplier) + parseFloat(rowData.pendingMovement)),
                                            important: true,
                                            main: true,
                                            alignment: 'right',
                                        },
                                        {
                                            heading: <>Current <br/>Stock</>,
                                            field: rowData => parseFloat(rowData.current),
                                            important: true,
                                            main: true,
                                            alignment: 'right',
                                            style:{fontWeight: 'bold'}
                                        },
                                        {
                                            heading: <>New <br/>Stock</>,
                                            field: rowData => 
                                                <TextField
                                                    type="number"
                                                    value={parseFloat(rowData.stock)}
                                                    onChange={this.handleChangePart(rowData.idx)}
                                                    inputProps={{
                                                        min: 0,
                                                        style: { textAlign: 'right' }
                                                    }}
                                                />,
                                            important: true,
                                            main: true,
                                            alignment: 'right',
                                        },
                                        {
                                            heading: <>Stock <br/>Change</>,
                                            field: rowData => _.isNaN(Math.abs(rowData.current - rowData.stock)) ? '-' : `${rowData.current - rowData.stock > 0 ? '-' : '+'}${Math.abs(rowData.current - rowData.stock)}` ,
                                            important: true,
                                            main: true,
                                            style: rowData => ({color: !_.isNaN(Math.abs(rowData.current - rowData.stock)) && ( rowData.current - rowData.stock > 0 ? colors.red : colors.green )}),
                                            alignment: 'right',
                                            sizeToContent: true
                                        },
                                        {
                                            actions: rowData => {
                                                return([
                                                    {name: 'Refresh', icon: icons.refresh, onClick: ()=>{this.refreshRow(rowData.idx)}, disabled: !access.edit, hide: rowData.current == _.find(lists.parts,i=>i.value == rowData.part).part.stock.stock_current_qty},
                                                    {name: 'Remove',  icon: icons.delete,  onClick: ()=>{this.deleteRow(rowData.idx)},  disabled: !access.edit},
                                                ])
                                            }
                                        }
                                    ]}
                                    rows={_.orderBy(_.filter(details, i=>!i.deleted), [o => _.find(lists.parts, i => i.value == o.part).part.part_number], ['asc'])}
                                />
                            </Hidden>
                        </PaddedPaper>
                    </Grid>
                }
                {currentTab == 0 &&
                    <Grid item xs={12} style={{paddingTop:'1.5em'}}>
                        <PaddedPaper>
                            <Textarea
                                id="notes"
                                name="notes"
                                label='Stock Correction Notes'
                                value={formDataCorrection.notes}
                                rows={10}
                                onChange={this.handleNoteChange}
                                noMargin
                            />
                        </PaddedPaper>
                    </Grid>
                }
                {formErrors['part_locked'] &&
                    <Grid container xs={12}>
                        <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>
                            Some parts are locked, please try again later.
                        </Grid>
                    </Grid>
                }
                {currentTab == 1 &&
                    <Grid item xs={12}>
                        <div class='buttonRow' style={{marginTop:0}}>
                            <Button variant='outlined' onClick={
                                ()=>{this.props.history.push(`/stock/correction/`);}
                            }>Back</Button>
                            {!!access.submit && details.length > 0 && <Button onClick={()=>{
                                this.props.deployConfirmation('Are you sure you want to submit this stock correction?', 'Submit Stock Correction', this.submit);
                            }} variant='contained' color='primary'>Submit Stock Correction</Button>}
                        </div>
                    </Grid>
                }
                {currentTab == 0 &&
                        <Grid item xs={12}>
                            <div class='buttonRow' style={{marginTop:0}}>
                                <Button variant='outlined' onClick={
                                    ()=>{this.props.history.push(`/stock/correction/`);}
                                }>Back</Button>
                                {!!access.save && details.length > 0 && <Button onClick={this.save} 
                                    variant='contained' color='primary'>Save</Button>}
                                {!!access.submit && details.length > 0 && <Button onClick={()=>{
                                    this.handleTabChange(null,1)
                                }} variant='contained' color='primary'>Review & Submit</Button>}
                            </div>
                        </Grid>
                }
                <Dialog
                    open={this.state.partImageDialog}
                    onClose={this.handlePartImageDialogClose}
                    scroll="body"
                    disableBackdropClick	
                    disableEscapeKeyDown
                >                
                    <DialogContent>
                        <ImageWithError src={this.state.partImageDialogData} alt='Part Image' style={{maxWidth: 500}}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{
                            this.setState({partImageDialog: false})
                        }} variant="outlined" color="default">Close</Button>
                    </DialogActions>
                </Dialog>           
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                               => dispatch(closeDialog()),
    deployDialog:       (content, title, variant, size)  => dispatch(deployDialog(content, title, variant,size)),
    deployConfirmation: (message, title, success)        => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)                   => dispatch(deploySnackBar(variant, msg)),
})

export default connect(null, mapDispatchToProps)(EditStockCorrection);
