import React from 'react';
import _ from "lodash";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { capitalize } from "Functions/MiscFunctions";

const WildixCallDetails = ({ call, callData, callLogs, getCallActions, getCallStatusClass }) => {
    const data = callData?.find?.((c) => c.ch === call.getChannel())?.dt ?? null;

    const log = callLogs?.find?.((c) => c.ch === call.getChannel()) ?? null;

    const callDirection = call?.getDirection?.(),
        callDuration = call?.getFormattedDuration?.(),
        callName = call?.getCalleeName?.(),
        callNumber = call?.getCalleeNumber?.(),
        callState = call?.getFormattedState?.(),
        isAnonymous = call?.getCalleeNumber?.() === "anonymous",
        isIncoming = call?.isIncoming?.() ?? false,
        isInternal = call?.getCalleeNumber?.()?.length === 3,
        isExternal = !isIncoming || (isIncoming && (callName.startsWith("+") || callName.startsWith("0"))),
        isOnHold = call?.isOnHold?.() ?? false,
        isRinging = call?.isRinging?.() ?? false;

    const getIcon = () => {
        return isRinging ? "phone-volume" : isOnHold ? "pause-circle" : isIncoming ? "phone-arrow-down-left" : "phone-arrow-up-right";
    };

    const getBgColor = () => {
        return isRinging || isOnHold ? "#f2dede" : "#d9eeda";
    };

    return (
        <Box>
            <Grid container alignItems="center">
                <Grid item xs={12}>
                    <Box className="content-light-white">
                        <Box className="fw-500" p={1} py={1.5} textAlign={"center"} fontSize={18} color={"#000"} style={{ background: getBgColor() }}>
                            <Grid container spacing={1} alignItems="center" justify="center">
                                <Grid item>
                                    <Box pt={0.2}>
                                        <FAIcon className={isRinging ? `icon-shake` : ``} icon={getIcon()} type="thin" size={30} noMargin />
                                    </Box>
                                </Grid>
                                <Grid item>{isOnHold ? `ON HOLD` : isInternal ? `INTERNAL` : isExternal ? `EXTERNAL` : callName?.toUpperCase?.()}</Grid>
                            </Grid>
                        </Box>
                        {((data?.fn || data?.ln) && (
                            <>
                                <Box width="100%" textAlign="center" p={0.25} borderBottom={`1px solid #ddd`} borderTop={`1px solid #ddd`} style={{ background: "#fafafa" }}>
                                    <Typography variant="caption" className="fw-400">
                                        <FAIcon icon="shield-check" type="light" size={12} />
                                        Caller ID Matched
                                    </Typography>
                                </Box>
                                <Box p={1} textAlign="left">
                                    <Typography variant="body1" className="fw-400" gutterBottom style={{whiteSpace: 'initial'}}>
                                        <FAIcon icon="user" size={17} noMargin /> {data.fn} {data.ln}
                                    </Typography>
                                    {data?.po?.length ? (
                                        <Typography variant="body1" className="fw-400" gutterBottom style={{whiteSpace: 'initial'}}>
                                            <FAIcon icon="user-crown" size={17} noMargin /> {data.po}
                                        </Typography>
                                    ) : null}
                                    {data.cn && (
                                        <Typography variant="body1" className="fw-400" gutterBottom style={{whiteSpace: 'initial'}}>
                                            <FAIcon icon="building" size={17} noMargin /> {data.cn ?? "No Company"}
                                        </Typography>
                                    )}
                                    {data?.lo?.length ? (
                                        <Typography variant="body1" className="fw-400" gutterBottom style={{whiteSpace: 'initial'}}>
                                            <FAIcon icon="arrow-right" size={17} noMargin /> {data.lo}
                                        </Typography>
                                    ) : null}
                                    <Typography variant="body1">
                                        {isRinging ? (isIncoming ? `${capitalize(callDirection)} call ` : `Dialling `) : ``}
                                        {isAnonymous ? "Anonymous" : callNumber}
                                    </Typography>
                                </Box>
                            </>
                        )) ||
                            (data?.cn && (
                                <>
                                    <Box
                                        width="100%"
                                        textAlign="center"
                                        p={0.25}
                                        borderBottom={`1px solid #ddd`}
                                        borderTop={`1px solid #ddd`}
                                        style={{ background: "#fafafa" }}
                                    >
                                        <Typography variant="caption" className="fw-400">
                                            <FAIcon icon="shield-check" type="light" size={12} />
                                            Caller ID Matched
                                        </Typography>
                                    </Box>
                                    <Box p={1} textAlign="left">
                                        <Typography variant="body1" className="fw-400" gutterBottom style={{whiteSpace: 'initial'}}>
                                            <FAIcon icon="building" size={17} noMargin /> {data.cn}
                                        </Typography>
                                        {data?.lo?.length ? (
                                            <Typography variant="body1" className="fw-400" gutterBottom style={{whiteSpace: 'initial'}}>
                                                <FAIcon icon="arrow-right" size={17} noMargin /> {data.lo}
                                            </Typography>
                                        ) : null}
                                        <Typography variant="body1">
                                            {isRinging ? (isIncoming ? `${capitalize(callDirection)} call ` : `Dialling `) : ` `}
                                            {isAnonymous ? "Anonymous" : callNumber}
                                        </Typography>
                                    </Box>
                                </>
                            )) || (
                                <>
                                    <Box
                                        width="100%"
                                        textAlign="center"
                                        p={0.25}
                                        borderBottom={`1px solid #ddd`}
                                        borderTop={`1px solid #ddd`}
                                        style={{ background: "#fafafa" }}
                                    >
                                        {(isInternal && (
                                            <Typography variant="caption" className="fw-400">
                                                <FAIcon icon="shield-check" type="light" size={12} />
                                                Caller ID Matched
                                            </Typography>
                                        )) || (
                                            <Typography variant="caption" className="fw-400">
                                                <FAIcon icon="shield-xmark" type="light" size={12} />
                                                Unknown Caller
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box p={1} textAlign="left">
                                        <Typography variant="body1" className={isRinging ? `` : `fw-400`} gutterBottom>
                                            {isRinging ? (isIncoming ? `${capitalize(callDirection)} call ` : `Dialling `) : ``}
                                            {isAnonymous ? "Anonymous" : callName}
                                        </Typography>
                                        <Typography variant="body1">{isAnonymous ? "Anonymous" : callNumber}</Typography>
                                    </Box>
                                </>
                            )}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className="content-light-white" p={1} borderTop={0}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <span className={getCallStatusClass(call)}>{callState}</span>
                            </Grid>
                            {!isRinging && <Grid item>{callDuration}</Grid>}
                            {!isRinging && log && (
                                <Grid item xs={12} align="left">
                                    {(log.nt === "" && (
                                        <Typography variant="body2">
                                            <em>No notes added yet</em>
                                        </Typography>
                                    )) ||
                                        log.nt?.split?.(`\n`).map?.((line, idx) => (
                                            <Typography key={idx} variant="body2">
                                                {line}&nbsp;
                                            </Typography>
                                        ))}
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className="content-light-white" p={1} borderTop={0}>
                        <Grid container justify="center">
                            {_.map(getCallActions(call, data?.ci ?? null, data?.si ?? null), (action, idx) => (
                                <Grid item key={idx}>
                                    <Tooltip title={action.name}>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                action.onClick();
                                            }}
                                        >
                                            <FAIcon icon={action.icon} className={action.className ?? ""} type={action.type ?? "thin"} size={20} noMargin button />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default WildixCallDetails;
