import API from 'API';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { formatValidationErrors } from 'Helpers/ErrorHelper';

import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';


import EmailDialog from 'Components/Common/Dialogs/EmailCustomerReturnsDialog';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import CreditNote from 'Components/CustomerReturns/ViewCreditNote/CreditNote';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

const ApproveCreditNoteDialog = ({cnId, status, callback}) => {

    const dispatch = useDispatch();

    const initialState = {
        creditNote:         {},
        formData: {
            notes:      '',
            status
        },
        formErrors:         {},
        emailDialog: {
            customerId:         '',
            customerContactId:  '',
            type:               '',
        },
        isLoading:          true,
        btnText:                '',
    };

    let [state, setState] = useState(initialState);

    const {creditNote, formData, formData:{notes}, formErrors, emailDialog:{customerId, customerContactId, type}, isLoading} = state;

    const handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleClose = () => dispatch(closeDialog());

    const handleApprove = () => {
        switch(status){
            case 'Approval':
                dispatch(deployConfirmation('Are you sure you want to approve this Credit Note', 'Approve Credit Note?', handleApproveSuccess))
            break;
            case 'Confirmed':
                dispatch(deployConfirmation('Are you sure you want to process this Credit Note', 'Process Credit Note?', handleApproveSuccess))
            break;
            default: break;
        }    
        
    };

    const handleApproveSuccess = () => {
        setState(state => ({
            ...state,
            isLoading: true
        }));
        API.post(`/customerReturns/creditNote/${cnId}/changeStatus`, formData)
        .then(result => {
            if(result.data.errors) {
                setState(state => ({
                    ...state,
                    formErrors: formatValidationErrors(result.data.errors),
                    isLoading: false
                }));
            } else if(result.data.success) {
                setState(state => ({
                    ...state,
                    emailDialog: {
                        customerId:         result.data.customerId,
                        customerContactId:  result.data.contactId,
                        type:               result.data.type
                    }
                }));
            }
        });
    }

    // handle email dialog
    useEffect(() => {
        if(customerId && customerContactId && type) {
            let subType    = '';
            switch(status){
                case 'Awaiting Approval':
                    dispatch(deploySnackBar('success', 'You have successfully approved this credit note!'));
                    subType = 'AwaitingApproval'
                break;
                case 'Approval':
                    dispatch(deploySnackBar('success', 'You have successfully confirmed this credit note!'));
                    subType = 'Approval'
                break;
                case 'Confirmed':
                    dispatch(deploySnackBar('success', 'You have successfully processed this credit note!'));
                    subType = 'Confirmed'
                break;
                default: break;
            }   

            if (status !== 'Confirmed'){
                const dialog = <EmailDialog 
                                    id=                 {cnId}
                                    customerId=         {customerId}
                                    customerContactId=  {customerContactId}
                                    type=               {'creditNote'}
                                    subType=            {subType}
                                />
                dispatch(deployDialog(dialog, 'You Are About To Email - Credit Note Confirmation', 'success'));
            } else {
                dispatch( closeDialog() )
            }
            if(callback) callback();
        }
    }, [cnId, customerId, customerContactId, type, dispatch, callback]);

    // handle load
    useEffect(() => {
        API.get(`/customerReturns/creditNote/${cnId}`)
        .then(result => {
            setState(state => ({
                ...state,
                creditNote: result?.data,
                isLoading:  false
            }));
        })
    }, [cnId]);

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} style={{border:'1px solid rgba(224, 224, 224, 1)', padding: '15px'}}>
                            <CreditNote cn={creditNote} />
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={12}>
                            <Textarea
                                id="notes"
                                name="notes"
                                label="Notes"
                                value={notes}
                                rows={5}
                                error={formErrors && formErrors['notes']}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button onClick={handleClose} variant="outlined" color="default">Close</Button>
                        <Button onClick={handleApprove} style={{backgroundColor:'#5da14d', color:'white'}} variant="contained">{status == 'Approval' ? 'Approve' : 'Process'}</Button>
                    </Grid>
                </DialogContent>
            )}
        </React.Fragment>
    );
}

export default ApproveCreditNoteDialog;