import React  from 'react';
import API    from 'API';
import _      from 'lodash';
import moment from 'moment';

import { Button, Dialog, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, Typography, ListItemSecondaryAction } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AllIcon       from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';

import ScanDialog from './ScanDialog';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

const initialState = {
	isLoading: true,
    take: {},
    page:                   0,
	perPage:                3,
    searchResults:          [],
    parts:                  [],
    reasons:                [],
    startPick:              false,
}

class StockTake extends React.Component {
	constructor(props) {
        super(props);
		this.state = initialState;
	}

	componentDidMount = () => {
        this.getTake();
    }

	componentWillUnmount = () => {
		this.props.removeKeyDown()
	}

    getTake = () => {
        API.get(`/stock/take/${this.props.match.params.id}`)
        .then(take => {
            this.setState({
                take:           take.data,
                isLoading:      false,
                parts:          _.take(_.orderBy(take.data.details, i => i.part.part_number), this.state.perPage),
                searchResults:  _.orderBy(take.data.details, i => i.part.part_number),
            }, () => {
                this.props.addKeyDown(this._handleKeyDown)
            });
        })
    }

	_handleKeyDown = (e) => {
		if (e.keyCode === 0 || e.keyCode === 192) return;
        e.preventDefault(); 
		switch(e.key) {
			case 'F1':
				this.state.page > 0 && this.prevPage();
				break;
			case 'F2':
				((this.state.page + 1) * this.state.perPage) < this.state.searchResults.length && this.nextPage();
				break;
            case 'F3':
                this.handleStartPick();
                break;
            default: break;
		}
	}

	nextPage = () => {
		const { page, perPage, searchResults } = this.state;
		const newPage = page + 1;
		const newOrders = _.take(_.slice(searchResults, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			parts: newOrders
		});
	}

	prevPage = () => {
		const { page, perPage, searchResults } = this.state;
		const newPage = page - 1;
		const newOrders = _.take(_.slice(searchResults, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			parts: newOrders
		});
	}


    handleStartPick = () => {
        document.removeEventListener("keydown", this._handleKeyDown, false)
        this.setState({
            startPick: true
        })
    }

    handleEndPick = () => {
        this.setState({
            startPick: false,
            isLoading: true
        }, this.getTake)
    }

  	render() {

		const { isLoading, searchResults, parts, startPick, perPage, take } = this.state;
		const { classes } = this.props;

		if (isLoading) return <LoadingCircle/>;

		return (
            <Grid container spacing={1} style={{height: '100%'}}> 
                <Grid item xs={12}>
					<Typography variant='h4' style={{textAlign: 'center'}}>Stock Take</Typography>
				</Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{textAlign: 'center'}}>{take.st_ref}-{take.st_checks > 9 ? take.st_checks : `0${take.st_checks}`}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{textAlign: 'center'}}><strong>{moment(take?.st_date).format('DD/MM/YYYY')}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
					<List>
						{_.map(parts, i => 
							<ListItem key={i.smg_id} className={classes.ListItem}>
								<ListItemText 
									primary={i.part.part_number} 
                                    secondary={i.part.part_description}
								/>
								<ListItemSecondaryAction
                                    style={{
                                        color:      colors.green,
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {parseFloat(i.std_taken_stock) > 0 ? parseFloat(i.std_taken_stock).toFixed(3): 
                                        <AllIcon
                                            style={{color: colors.red}}
                                            icon={icons.false}
                                            noMargin
                                        />
                                    }
								</ListItemSecondaryAction>
							</ListItem>
						)}
					</List>
                </Grid>
                <Grid item xs={12} style={{marginTop: 'auto'}}>
                    <Grid container spacing={1} style={{justifyContent: 'space-between'}}> 
                        <Grid item xs={3} style={{marginRight: 'auto'}}>
                            { searchResults.length > perPage && this.state.page > 0 && 
                                    <Button variant='contained' color='primary'
                                        onClick={this.prevPage} fullWidth
                                    >[F1] Prev</Button>
                                }
                        </Grid>
                        <Grid item xs={6}>
                            {_.filter(searchResults, i => i.std_taken_stock === null).length > 0 &&
                                <Button variant='contained' color='primary' onClick={this.handleStartPick} fullWidth>[F3] <br/> Start Pick </Button>
                            } 
                        </Grid>
                        <Grid item xs={3} style={{marginLeft: 'auto'}}>
                            { searchResults.length > perPage && ((this.state.page + 1) * this.state.perPage) < searchResults.length &&
                                <Button variant='contained' color='primary'
                                    onClick={this.nextPage} fullWidth
                                >[F2] Next</Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {startPick &&
                    <Dialog 
                        open={startPick} 
                        onClose={this.handleEndPick} 
                        fullScreen
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title" style={{backgroundColor: colors.blue,  fontWeight: 'bold'}}> 
                            <Grid container style={{
                                alignContent:   'center',
                                justifyContent: 'space-between',
                                alignItems:     'center'
                            }}>
                                <Grid item style={{color: colors.white}}>
                                    {take.st_ref}-{take.st_checks > 9 ? take.st_checks : `0${take.st_checks}`}
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ width:'100vw', Maxwidth: '100vw'}}>
                            <ScanDialog 
                                show     
                                take    = {take}
                                close   = {this.handleEndPick}
                                details = {_.orderBy(_.filter(searchResults, i => i.std_taken_stock === null), [i => i.part_number],['desc'])}
                            />
                        </DialogContent>
                    </Dialog>
                }
            </Grid>
		);
  	}
}

const styles = theme => ({
    ListItem: {
		backgroundColor: colors.white, 
		borderRadius: 	 5,
		marginBottom: 	 '0.75em',
		boxShadow: 	  	 '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
	}
});

export default withStyles(styles)(StockTake);