import React, {Component} from 'react';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FALightIcon from '../Icons/FontAwesome/FALightIcon';
import InputAdornment from '@material-ui/core/InputAdornment';


class SearchInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeout: null
        };
    }

    handleKeyup = (e) => {
        this.props.onChange(e);
        clearTimeout(this.state.timeout);

        this.setState({ 
            timeout: setTimeout(() => {
                            this.props.getContacts();
                        }, 900)
        });
    }

    render() {
        return (
            <React.Fragment>
                <FormControl>
                    <Input
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        onChange={this.handleKeyup}
                        fullWidth
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton 
                                    onClick={this.props.onClick}
                                >
                                    <FALightIcon icon='times' button noMargin  />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </React.Fragment>
        );
    }
}

export default SearchInput;
