import React from 'react';
import { Grid } from '@material-ui/core';
import ActivityLog from 'Components/Common/ActivityLog/ActivityLog';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import _ from 'lodash';

const typeTo = {
    Created: {
        icon: IconHelper.plus,
        color: colors.primary,
    },
    Updated: {
        icon: IconHelper.edit,
        color: colors.orange,
    },
    Ended: {
        icon: IconHelper.star,
        color: colors.yellow,
    },
    'Scheduled Run': {
        icon: IconHelper.day,
        color: colors.green,
    },
    'Cancelled Scheduled Run': {
        icon: IconHelper.day,
        color: colors.red,
    },
    'Emails Sent': {
        icon: IconHelper.email,
        color: colors.green,
    }
}


export default function({campaign}) {
    return (
        <Grid container style={{justifyContent: 'center'}}>
            <Grid item xs={12}>
                <ActivityLog 
                    fields={{
                        type:   't',
                        date:   'd',
                        icon:   { color: 'c', icon: 'i' },
                        text:   'n',
                        staff:  's',
                    }}
                    atl={_.map(campaign.activity_logs, i => ({
                        t: i.ecal_type,
                        d: i.ecal_datetime,
                        c: typeTo[i.ecal_type]?.color ?? colors.black,
                        i: typeTo[i.ecal_type]?.icon ?? IconHelper.unknown,
                        n: i.ecal_notes,
                        s: i.ecal_staff,
                    }))}
                    filter={
                        [
                            {label: 'Email Campaign', types: ['Created', 'Updated', 'Ended']},
                            {label: 'Runs', types: ['Scheduled Run', 'Cancelled Scheduled Run', 'Emails Sent']},
                        ]
                    }
                />
            </Grid>
        </Grid>
    )
}