import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import API from '../../../API';
import ViewEventDialog from './../ViewEventDialog/ViewEventDialog';
import ViewCalendar from './ViewCalendar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const initialState = {
    activeCategories: [],
    dateRange: {},
    events: [],
    eventId: null,
    viewEventOpen: false
}

class ViewFullCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    };
    componentDidMount = () => {    
        this.setInitialDate();
        if(this.props.match.params.eventId){
            this.setState({
                eventId: this.props.match.params.eventId,
                viewEventOpen: true,
            });
        }
    }
    setInitialDate = () => {
        let start = moment().startOf("month").startOf("isoWeek");
        let end = moment().endOf("month").endOf("isoWeek");
        this.handleDateRangeChange({
            start: start, 
            end: end
        });
    }
    setActiveCategories = (activeCategories) => {
        this.setState({
            activeCategories: _.keys(_.pickBy(activeCategories))
        },
        () => this.getEvents());
    };
    handleDateRangeChange = (date) => {
        let from, to;
        if(typeof date === 'object' && date.start && date.end) {
            from = date.start;
            to = date.end;
        } else if(Array.isArray(date) && date.length === 7) {
            from = date[0];
            to = date[6];
        } else if(Array.isArray(date) && date.length === 1) {
            from = date[0];
            to = date[0];
        }
        this.setState({
            dateRange: {
                from: moment(from).format("YYYY-MM-DD"),
                to: moment(to).format("YYYY-MM-DD")
            }
        }, () => {
            if(this.state.activeCategories.length > 0){
                this.getEvents();
            }
        });
    }
    getEvents = () => {
        API.get('/calendar/events/all', {
            params: {
                dateFrom: this.state.dateRange && this.state.dateRange.from,
                dateTo: this.state.dateRange && this.state.dateRange.to,
                activeCategories: this.state.activeCategories.length && this.state.activeCategories.join()
            }
        }).then(result => {
            if(result.data) {
                let events = [];
                _.each(result.data, (event) => {
                    if ((moment(event.calendar_event_end_datetime).endOf('day').diff( moment(event.calendar_event_start_datetime).startOf('day'), 'hours') / Math.ceil(24)) > 0) {
                        var i = 0;
                        for(i; i < ((moment(event.calendar_event_end_datetime).endOf('day').diff( moment(event.calendar_event_start_datetime).startOf('day'), 'hours') / Math.ceil(24)));i++){
                            events.push({
                                id: event.calendar_event_id,
                                title: `${moment(event.calendar_event_start_datetime).format('H:mm')} ${event.sub_category.category_name}`,
                                altTitle: `${event.sub_category.category_name}`,
                                colour: event.sub_category.category_colour,
                                // Requires moment ._d to fix event display issues on iOS devices
                                start: moment(event.calendar_event_start_datetime).add(i,'days')._d,
                                end: moment(event.calendar_event_start_datetime).add(i,'days').set({"hour": moment(event.calendar_event_end_datetime).get('hour'), "minute": moment(event.calendar_event_end_datetime).get('minute')})._d,
                                allDay: false,
                                cancelled: (event.calendar_event_status === 'Cancelled')
                            });
                        }
                    } else {
                        events.push({
                            id: event.calendar_event_id,
                            title: `${moment(event.calendar_event_start_datetime).format('H:mm')} ${event.sub_category.category_name}`,
                            altTitle: `${event.sub_category.category_name}`,
                            colour: event.sub_category.category_colour,
                            // Requires moment ._d to fix event display issues on iOS devices
                            start: moment(event.calendar_event_start_datetime)._d,
                            end: moment(event.calendar_event_end_datetime)._d,
                            allDay: false,
                            cancelled: (event.calendar_event_status === 'Cancelled')
                        });
                    }
                });
                this.setState({
                    events: events
                });
            } else {
                this.setState({
                    events: []
                });
            }
        });
    };
    onSelectEvent = (e) => {
        this.setState({
            eventId: e.id,
            viewEventOpen: true,
        });
    };
    handleDialogClose = () => {
        this.setState({ 
            viewEventOpen: false 
        });
    };
    render() {
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Full Calendar
                        </Typography>
                    </Grid>
                </Grid>
                <ViewCalendar events={this.state.events} setActiveCategories={this.setActiveCategories} getEvents={this.getEvents} onSelectEvent={this.onSelectEvent} handleDateRangeChange={this.handleDateRangeChange} />
                <ViewEventDialog 
                    open={this.state.viewEventOpen}
                    eventId={this.state.eventId}
                    onClose={this.handleDialogClose} />
            </React.Fragment>
        );
    }
}

export default ViewFullCalendar;