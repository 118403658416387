//UpdateOrderDetailsDialog
import React, { Component }     from 'react';
import _                        from 'lodash';
import { connect }              from 'react-redux';
import API                      from 'API';
import uuidv4                   from 'uuid/v4';

import {FormControl, Grid, Button, Typography} from '@material-ui/core/';

import AutoCompleteMultiSelect          from 'Components/Common/Selects/AutoCompleteMultiSelect';
import { deployDialog, closeDialog }    from 'Actions/Dialog/Dialog';
import LoadingCircle                    from 'Components/Common/LoadingCircle/LoadingCircle';
import Textarea                         from 'Components/Common/Inputs/Textarea';

const initialState = () => ({
    serialNumbers:      [],
    warrantyVoidLabels: [],
    details:            [],
    formErrors:         [],
    isLoading:          true,
    uuid:               0
});

class UpdateOrderDetailsDialog extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            ...initialState(), 
            details: this.props.details
        }
    }

    componentDidMount() {
        this.getWarrantyNumbers();
    }


    getSerialNumbers(){
        _.each(this.state.details, i => {
            if (!_.find(this.state.serialNumbers, j => j.id == i.part_id)){
                API.get(`/parts/${i.part_id}/usedSerialNumbers`)
                .then(res => {
                    this.setState({
                        serialNumbers: [...this.state.serialNumbers, {id: i.part_id, serialNumbers: _.map(res.data, i => { return _.assign({value: i.id, label: i.serialNumber})})}]
                    }, () => {
                        this.checkErrors();
                        this.updateWarrantyVoidLabels();
                    });
                })                
            }
        }); 
    }

    getWarrantyNumbers() {
        API.get(`/parts/warrantyTerms/warrantyVoidLabels`)
        .then(res => {
            this.setState({warrantyVoidLabels: _.map(res.data, i => {return i.pwvl_code;})}, this.getSerialNumbers);
        })
    }

    checkErrors = () => {
        this.setState({
            isLoading:  false,
            formErrors: _.map(this.state.details, (i,idx) => {
                i.error = null;
                if ( ( i.part_req_serial_number == 'Yes' || i.part_req_serial_number   == 'Manual' ) && _.find(this.state.serialNumbers, x => x.id == i.part_id)){
                    let serialNumbers = _.map(i.serialNumbers, j => {    
                        if (_.find(_.find(this.state.serialNumbers, x => x.id == i.part_id).serialNumbers, p => p.value == j.id )){
                            return j.serialNumber;
                        }
                        return 1;
                    });
                    if (_.find(serialNumbers, i => i === 1) && i.part_req_serial_number == 'Yes')   return 'Order Uses Released Serial Number(\'s)';
                    if (serialNumbers.length < parseInt(i.quantity))                                return 'Select More Serial Numbers';
                    if (serialNumbers.length > parseInt(i.quantity))                                return 'Select Less Serial Numbers';
                    
                }
                if (i.part_warranty_void_label == 'Yes' ){
                    if (_.find(i.warrantyVoidLabels, l => { return (l.length != 10 || !l.includes('CS-')) }))   return 'Warranty Label is incorrect format ( CS-0000000 )';
                    if (i.warrantyVoidLabels.length < parseInt(i.quantity))                                     return 'Select More Warranty Void Labels';
                    if (i.warrantyVoidLabels.length > parseInt(i.quantity))                                     return 'Select Less Warranty Void Labels';
                }
                return null;
            })
        })
        if (this.state.isLoading) this.setState({isLoading:false});
    }

    update = () => {
        this.props.callback(this.state.details);
        this.props.closeDialog();
    }

    handleMultiSelectChange = (field, idx) => e => {
        let details = this.state.details;
        details[idx] = (field == 'warrantyVoidLabels') ? 
            details[idx] = {...details[idx], warrantyVoidLabels: e ? _.map(e, j => { return j.value}) : []} :
            details[idx] = {...details[idx], serialNumbers: e ? _.map(e, j => { return _.assign({id:j.value, serialNumber: j.label})}) : []};
        this.setState({details},() => { 
            this.updateWarrantyVoidLabels();
            this.checkErrors() 
        });
    }

    updateWarrantyVoidLabels = () => {
        this.setState({
            warrantyVoidLabels: _.uniq(_.merge(this.state.warrantyVoidLabels, _.flatten(_.map(this.state.details, i => {return i.warrantyVoidLabels}))))
        })
    }

    render() {
        const { serialNumbers,warrantyVoidLabels, details, formErrors, isLoading, uuids } = this.state;
        return(
            <Grid container spacing={3} xs={12}>
                {isLoading ? <LoadingCircle/> : 
                    <>
                        <Grid item container xs={12} spacing={3} >
                            {_.map(details, (i,idx) => {
                                if (i.part_warranty_void_label == 'Yes' || i.part_req_serial_number == 'Yes' || i.part_req_serial_number   == 'Manual'){
                                    return (
                                        <Grid item xs={12} key={idx}>
                                            <Typography>{i.part_number} - {i.part_description}</Typography> <br></br>
                                            {i.part_req_serial_number   == 'Yes' && _.find(serialNumbers, j => j.id == i.part_id ) && 
                                                <FormControl fullWidth>
                                                    <AutoCompleteMultiSelect 
                                                        options={_.find(serialNumbers, j => j.id == i.part_id )?.serialNumbers} 
                                                        label='Serial Numbers *'
                                                        value={i.serialNumbers.length ? _.map(i.serialNumbers, j => {return j.id} ) : []}
                                                        onChange={this.handleMultiSelectChange('serialNumber', idx)}
                                                        error={formErrors[idx]}
                                                        errorText={formErrors[idx]}
                                                    />
                                                </FormControl>
                                            }
                                            {i.part_req_serial_number   == 'Manual' && 
                                                <FormControl fullWidth>
                                                    <AutoCompleteMultiSelect 
                                                        options={i.serialNumbers.length ? _.map(i.serialNumbers, j => {return _.assign({value:j.id, label:j.serialNumber })} ) : []} 
                                                        label='Manual Serial Numbers *'
                                                        value={i.serialNumbers.length ? _.map(i.serialNumbers, j => {return j.id} ) : []}
                                                        onChange={this.handleMultiSelectChange('serialNumber', idx)}
                                                        error={formErrors[idx]}
                                                        errorText={formErrors[idx]}
                                                        creatable
                                                    />
                                                </FormControl>
                                            }
                                            {i.part_warranty_void_label == 'Yes' && 
                                                <FormControl fullWidth>
                                                    <AutoCompleteMultiSelect 
                                                        options={_.map(_.concat(warrantyVoidLabels, i.warrantyVoidLabels), w => {return _.assign({value:w, label: w})})} 
                                                        label='Warranty void Labels *'
                                                        value={i.warrantyVoidLabels.length ? i.warrantyVoidLabels : []}
                                                        onChange={this.handleMultiSelectChange('warrantyVoidLabels', idx)}
                                                        error={formErrors[idx]}
                                                        errorText={formErrors[idx]}
                                                        creatable
                                                    />
                                                </FormControl>
                                            }
                                        </Grid>   
                                    )
                                }
                            })}
                        </Grid>
                        <Grid item xs={12}>
                            <div className='buttonRow'>
                                <Button variant='outlined' onClick={() => { this.props.closeDialog(); window.history.back();}}>Back</Button>
                                <Button onClick={this.update} color='primary' variant='contained' disabled={_.find(formErrors, i => {return i})}>Continue</Button>
                            </div>
                        </Grid>
                    </>
                }
            </Grid>
        );
    }
}


const mapDispatchToProps = dispatch => ({closeDialog:() => {dispatch(closeDialog())}});

export default connect(null, mapDispatchToProps)(UpdateOrderDetailsDialog);