import _ from 'lodash';
import * as NotificationActionTypes from '../../Actions/Notifications/Types/Types';

const initialState = {
    badges: {},
    ln: 0,
    notifications: {},
    status: {},
    troll: 0
};

function NotificationsReducer(state = initialState, action) {
    switch (action.type) {
        case NotificationActionTypes.LOAD_STAFF_STATUS:
            return {
                ...state,                   
                status: action.payload
            }
        case NotificationActionTypes.I_AM_ACTIVE:
            if(state.status[action.payload]?.status !== 2) {
                return {
                    ...state,                   
                    status: {
                        ...state.status,
                        [action.payload]: {
                            ...state.status[action.payload],
                            status: 2
                        }
                    } 
                }
            } else {
                return state
            }
        case NotificationActionTypes.MINUS_UNREAD_MESSAGE:
            let count = (state.badges['unread-messages'] - action.payload);
            if(count < 0) {
                count = 0;
            }
            return {
                ...state,                   
                badges: {
                    ...state.badges,
                    'unread-messages': count
                } 
            }
        case NotificationActionTypes.HANDLE_NOTIFICATIONS:
            if(JSON.stringify(action.payload.badges) !== JSON.stringify(state.badges)) {
                state = {
                    ...state,
                    badges: action.payload.badges
                }
            }
            if(!_.isEmpty(action.payload.notifications)) {
                state = {
                    ...state,
                    notifications: {
                        ...state.notifications,
                        ...action.payload.notifications
                    }
                }
            }
            if(action.payload.ln !== state.ln) {
                state = {
                    ...state,
                    ln: action.payload.ln
                }
            }
            if(action.payload.troll !== state.troll || (action.payload.troll > 0 && state.status?.[action.payload?.troll]?.troll !== 1)) {
                if(state.troll > 0) {
                    state = {
                        ...state,
                        status: {
                            ...state.status,
                            [state.troll]: {
                                ...state.status[state.troll],
                                troll: 0
                            }
                        } 
                    }
                }
                state = {
                    ...state,
                    status: {
                        ...state.status,
                        [action.payload.troll]: {
                            ...state.status[action.payload.troll],
                            troll: 1
                        }
                    },
                    troll: action.payload.troll
                }
            }
            Object.keys(action.payload.status).forEach((staffId) => {
                if(state.status?.[staffId]?.status !== action.payload?.status?.[staffId]) {
                    state = {
                        ...state,
                        status: {
                            ...state.status,
                            [staffId]: {
                                ...state.status[staffId],
                                status: action.payload.status[staffId]
                            }
                        }
                    }
                }
            });
            return state;
        case NotificationActionTypes.DISCARD_NOTIFICATION:
            if(state.notifications[action.payload]) {
                state = {
                    ...state,
                    notifications: _.omit(state.notifications, action.payload),
                }
            }
            return state;
        case NotificationActionTypes.DISCARD_ALL_NOTIFICATIONS:
            state = {
                ...state,
                notifications: initialState.notifications
            }
            return state;
        default:
            return state;
    }
}

 export default NotificationsReducer;