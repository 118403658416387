import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FormDialog from 'Components/Common/Dialogs/FormDialog';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import Editable from 'Components/Common/Inputs/Editable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import NotificationDialogContent from 'Components/Common/Dialogs/NotificationDialogContent';
import ReminderAlert from 'Components/Reminders/Misc/ReminderAlert';
import Textarea from 'Components/Common/Inputs/Textarea';
import TaskDialog from 'Components/Tasks/Misc/TaskDialog';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { 
    handleChange,
    handleSelectChange,
    handleConfirmationClose,
    handleConfirmationOpen, 
    handleConfirmationSuccess, 
} from 'Functions/FormFunctions';
import CustomerAddressSearch    from 'Components/Sales/CustomerAddressSearch';
import SearchSiteAddress        from './../AddReminder/SearchSiteAddress';

const styles = theme => ({
    alert: {
        marginBottom: 12
    },
    blank: {},
    content: {
        padding: '0 16px 16px 16px',
        marginTop: -4
    },
    complete: {
        backgroundColor: '#4CAF50!important',
        color: '#fff'
    },
    due: {
        backgroundColor: '#d32f2f!important',
        color: '#fff'
    },
    editableTitle: {
        cursor: 'pointer',
        border: '1px solid #fff',
        padding: theme.spacing(1, 4, 1, 1),
        minHeight: 50,
        "&:hover": {
            border: '1px solid #ddd'
        }
    },
    headerDue: {
        color: '#fff',
        backgroundColor: '#d32f2f!important',
    },
    headerUpcoming: {
        color: '#fff',
        backgroundColor: '#E65100!important',
    },
    headerDefault: {
        color: '#fff',
        backgroundColor: '#388E3C!important',
    },
    sideBar: {
        backgroundColor: '#fafafa',
        borderLeft: '1px solid #ddd'
    },
    status: {
        color: '#fff'
    },
    title: {
        paddingTop: `${theme.spacing(2)}px!important`
    },
});
const     snoozeOccuranceNumbers = {
    hour:   24,
    day:    7,
    week:   4, 
    month:  12,
    year:   5
}

const initialState = {
    confirmation: {
        delete: false,
        submit: false,
        update: false
    },
    formData: {
        reminderTitle:      '',
        reminderText:       '',
        reminderDue:        moment().format("YYYY-MM-DD HH:mm"),
        reminderRepeats:    0,
        reminderOccurance:  1,
        reminderRepeat:     'day',
        reminderAttachType: 'customer',
        reminderAttachId:   0,
        reminderAttach:     {},
        renderOptions:      [
            {label: 1, value: 1},
            {label: 2, value: 2},
            {label: 3, value: 3},
            {label: 4, value: 4},
            {label: 5, value: 5},
            {label: 6, value: 6},
            {label: 7, value: 7},
        ],
    },
    initialFormData: {
        reminderTitle:      '',
        reminderText:       '',
        reminderDue:        moment().format("YYYY-MM-DD HH:mm"),
        reminderRepeats:    0,
        reminderOccurance:  1,
        reminderRepeat:     'day',
        reminderAttachType: 'customer',
        reminderAttachId:   0,
        reminderAttach:     {},
        renderOptions:      [
            {label: 1, value: 1},
            {label: 2, value: 2},
            {label: 3, value: 3},
            {label: 4, value: 4},
            {label: 5, value: 5},
            {label: 6, value: 6},
            {label: 7, value: 7},
        ],
    },
    formErrors: [],
    isLoading: true,
    model: {},
    showDatePicker: false,
    snoozeFromData: {
        reminderOccurance: 'day',
        reminderAmount:    1, 
        showForm:          0,
        renderOptions:     [
            {label: 1, value: 1},
            {label: 2, value: 2},
            {label: 3, value: 3},
            {label: 4, value: 4},
            {label: 5, value: 5},
            {label: 6, value: 6},
            {label: 7, value: 7},
        ],
        date:              moment().add(1,'days').format(),
    },
    customers:             [],
    showLinkCustomer:      false, 
    showLinkAddress:       false,
}

class ReminderDialog extends React.Component { 
    constructor(props) {
        super(props)
        this.state                      = initialState
        this.handleChange               = handleChange.bind(this)
        this.handleSelectChange         = handleSelectChange.bind(this)
        this.handleConfirmationClose    = handleConfirmationClose.bind(this)
        this.handleConfirmationOpen     = handleConfirmationOpen.bind(this)
        this.handleConfirmationSuccess  = handleConfirmationSuccess.bind(this)
    }

    componentDidMount = () => {
        this.loadComponentData();
        this.getCustomerAddresses();
    }

    delete = () => {
        const { model } = this.state;
        API.post(`/reminders/${model.reminder_id}/delete`, {full_delete: 1})
        .then(result => {
            if(result && result.data) {
                if(this.props.callback) {
                    this.props.callback()
                }
                this.props.deploySnackBar("success", "You have successfully deleted this reminder")
                this.props.closeDialog()
            }
        })
    }

    update = () => {
        this.setState({ 
            formData: {
                ...this.state.formData,
                reminderDue: null
            },
            showDatePicker: true 
        })
    }
    
    submit = () => {
        const { model } = this.state;
        API.post(`/reminders/${model.reminder_id}/delete`)
        .then(result => {
            if(result && result.data) {
                if(this.props.callback) {
                    this.props.callback()
                }
                this.props.deploySnackBar("success", "You have successfully marked this reminder as completed")
                this.props.closeDialog()
            }
        })
    }

    loadComponentData = () => {
        API.get(`/reminders/${this.props.id}`)
        .then(result => {
            if(result && result.data) {
                if(result.data.errors) {
                    // this.props.deployDialog(<NotificationDialogContent title="Something went wrong" message="Sorry this reminder no longer exists" close={() => this.props.closeDialog()} />, "", "standard", "xs")
                    // dont show error message any longer instead redirect to reminders page
                    window.location = '/reminders';
                } else {
                    this.setState({
                        initialFormData: {
                            ...this.state.initialFormData,
                            reminderTitle       : result.data.reminder_title,
                            reminderText        : result.data.reminder_text,
                            reminderDue         : moment(result.data.reminder_due_datetime).format("YYYY-MM-DD HH:mm"),
                            reminderRepeats     : parseInt(result.data.reminder_repeats),
                            reminderOccurance   : parseInt(result.data.reminder_occurance),
                            reminderRepeat      : result.data.reminder_repeat,
                            reminderAttachType  : result.data.reminder_linked_type,
                            reminderAttachId    : result.data.reminder_linked_id,
                            reminderAttach      : result.data.attachment,
                            reminderAttachAddress      : result.data.attachment_address
                        },
                        formData: {
                            ...this.state.formData,
                            reminderTitle       : result.data.reminder_title,
                            reminderText        : result.data.reminder_text,
                            reminderDue         : moment(result.data.reminder_due_datetime).format("YYYY-MM-DD HH:mm"),
                            reminderRepeats     : parseInt(result.data.reminder_repeats),
                            reminderOccurance   : parseInt(result.data.reminder_occurance),
                            reminderRepeat      : result.data.reminder_repeat,    
                            reminderAttachType  : result.data.reminder_linked_type,
                            reminderAttachId    : result.data.reminder_linked_id,
                            reminderAttach      : result.data.attachment,
                            reminderAttachAddress      : result.data.attachment_address
                        },
                        isLoading: false,
                        model: result.data,
                    }, () => {
                        if(result.data.reminder_repeat !== 'day'){
                            this.handleRepeatReminderChange('reminderRepeat',result.data.reminder_repeat,0)
                        }
                    });
                }
            }
        })
    }


    handleReset = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                reminderTitle: this.state.initialFormData.reminderTitle,
                reminderText: this.state.initialFormData.reminderText,
                reminderDue: this.state.initialFormData.reminderDue,
                reminderRepeats: this.state.initialFormData.reminderRepeats,
                reminderOccurance: this.state.initialFormData.reminderOccurance,
                reminderRepeat: this.state.initialFormData.reminderRepeat,
            }
        })
    }
    
    handleCheckedChange = e => {
        let name = e.target.name, val = e.target.checked;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: val ? 1 : 0,
                reminderOccurance: 1,
                reminderRepeat: 'day'
            }
        });
    }

    handleDateChange = (name, date) => {
        const { formData } = this.state;
        this.setState({
            showDatePicker: false,
            formData: {
                ...formData,
                [name]: moment(date).format('YYYY-MM-DD HH:mm')
            }
        }, () => this.handleUpdateReminder())
    }

    handleSnooze = () => {
        const { model } = this.state;
        API.put(`/reminders/${model.reminder_id}/snooze`, {
            date: this.state.snoozeFromData.date
        } ) .then(result => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                })
            } else {
                if(this.props.callback) {
                    this.props.callback()
                }
                this.props.deploySnackBar("success", `You have successfully snoozed this reminder until ${this.state.snoozeFromData.date}`)
                this.loadComponentData();
                this.handleCloseSnoozeForm();
            }
        });
        
    }

    handleUpdateReminder = () => {
        const { model, initialFormData, formData } = this.state;
        if(JSON.stringify(initialFormData) !== JSON.stringify(formData)) {
            let data = formData;
            if(data.reminderTitle.length === 0) {
                data = {
                    ...data,
                    reminderTitle: initialFormData.reminder_title
                }
            }
            API.put(`/reminders/${model.reminder_id}`, data)
            .then(result => {
                if(result && result.data) {
                    if(result.data.errors) {
                        this.setState({
                            formErrors: formatValidationErrors(result.data.errors)
                        })
                    } else {
                        if(this.props.callback) {
                            this.props.callback()
                        }
                        this.setState({
                            formErrors: [],
                        }, () => {
                            this.props.deploySnackBar("success", "You have successfully updated this reminder")
                            this.loadComponentData()
                        });
                    }
                }
            })
        }
    }

    handleShowSnooze = () => {
        this.setState({
            snoozeFromData: {
                ...this.state.snoozeFromData,
                showForm: 1
            }
        });
    }

    handleCloseSnoozeForm = () => {
        this.setState({
            snoozeFromData: {
                ...initialState.snoozeFromData,
            }
        });
    }



    handleRepeatReminderChange(type, value, snooze){
        var data = (snooze) ? this.state.snoozeFromData : this.state.formData;

        var renderOptions       = [];
        if (snooze){
            var reminderOccurance   = type === 'reminderOccurance' ? value : data.reminderOccurance;
            var reminderAmount      = type === 'reminderAmount'    ? value : data.reminderAmount;
        } else {
            var reminderOccurance   = type === 'reminderRepeat'     ? value : data.reminderRepeat;
            var reminderAmount      = type === 'reminderOccurance'  ? value : data.reminderOccurance;
        }
        
        var renderOptions       = data.renderOptions;

        if (snooze ? type == 'reminderOccurance' : type == 'reminderRepeat'){
            renderOptions = [];
            for (let i = 0; i < snoozeOccuranceNumbers[value]; i++) {
                renderOptions.push({label: (i+1), value: (i+1)})
            }
            reminderAmount = 1;
        }

        var sstate;
        if (snooze){
            sstate = {
                snoozeFromData: {
                    ...data,
                    reminderOccurance,
                    reminderAmount,
                    renderOptions,
                    date: this.getSnoozeDate( reminderAmount, reminderOccurance ), 
                    showForm: 1
                }
            }
            
        } else {
            sstate = {
                formData: {
                    ...data,
                    reminderRepeat:     reminderOccurance,
                    reminderOccurance:  reminderAmount,
                    renderOptions,
                }
            };
        }

        this.setState(sstate);
    }

    getSnoozeDate = (amount, occurance) => {
        return moment().add(amount,`${occurance}s`).format()
    }

    handleSnoozeDateChange = e => {
        this.setState({
            snoozeFromData: {
                ...this.state.snoozeFromData,
                date: moment(e, "YYYY-MM-DD HH:mm:ss").format(),
            }
        });
    }

    handleResetCustomer = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                reminderAttachId: this.state.initialFormData.reminderAttachId,
                reminderAttach:   this.state.initialFormData.reminderAttach
            }
        }, () => { this.handleUpdateReminder(); this.handleShowCustSearch(); } )
    }

    HandleRemoveAddress = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                reminderAttachId: null,
                reminderAttachAddress:   null,
                showLinkCustomer: false,
            }
        }, () => { this.handleUpdateReminder(); } )
    }
    
    HandleRemoveCustomer = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                reminderAttachId: null,
                reminderAttach:   null,
                showLinkCustomer: false,
            }
        }, () => { this.handleUpdateReminder(); } )
    }
    
    getCustomerAddresses = () => {
        API.get(`/customers`)
        .then((res) =>  {
            this.setState({
                customers: res.data
            },
            () => {
                this.populateCustomerDropdown();
            });
        })
    }

    populateCustomerDropdown() {
        let customerList = null;
        customerList = _.map(this.state.customers, el => {
            return _.assign({
                value: el.cust_id,
                label: el.cust_name
            });
        });
        this.setState({
            customerList: customerList
        });
    }

    selectCustomer = (e) => {
        if(e){
            var cust = _.head(_.filter(this.state.customers,{'cust_id': e.address_customer_id}));
            this.setState({
                formData:{
                    ...this.state.formData,
                    reminderAttach:     cust,
                    reminderAttachId:   cust.cust_id,
                }
            },
            () => { this.handleUpdateReminder(); this.handleShowCustSearch(); } );
        } else {
            this.setState({
                formData:{
                    ...this.state.formData,
                    reminderAttach:     [],
                    reminderAttachId:   null
                }
            },
            () => { this.handleUpdateReminder(); this.handleShowCustSearch(); } );
        }
    }

    selectAddress = (e) => {
        if(e){
            this.setState({
                formData:{
                    ...this.state.formData,
                    reminderAttachAddress:     e,
                    reminderAttachId:   e.address_id,
                }
            },
            () => { this.handleUpdateReminder(); this.handleShowAddressSearch(); } );
        } else {
            this.setState({
                formData:{
                    ...this.state.formData,
                    reminderAttachAddress:     [],
                    reminderAttachId:   null
                }
            },
            () => { this.handleUpdateReminder(); this.handleShowAddressSearch(); } );
        }
    }

    handleShowCustSearch = ()=> {
        this.setState({showLinkCustomer: !this.state.showLinkCustomer});
    }

    handleShowAddressSearch = ()=> {
        this.setState({showLinkAddress: !this.state.showLinkAddress});
    }

    render() {
        const { confirmation, formData, formErrors, isLoading, model, snoozeOptions, snoozeFromData, showLinkCustomer, showLinkAddress } = this.state;
        const { classes } = this.props;
        let alert, date;
        
        if(model && model.reminder_due_datetime) {
            if(moment(model.reminder_due_datetime, "YYYY-MM-DD HH:mm:ss").isBefore(moment())) {
                alert = 'due';
                date = moment(model.reminder_due_datetime, "YYYY-MM-DD HH:mm:ss").fromNow(true)
            } 
            else if(moment(model.reminder_due_datetime, "YYYY-MM-DD HH:mm:ss").isBefore(moment().endOf('day'))) {
                alert = 'upcoming';
                date = moment(model.reminder_due_datetime, "YYYY-MM-DD HH:mm:ss").fromNow()
            } else {
                alert = 'default';
                date = moment(model.reminder_due_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")
            }
        }


        return (
            (isLoading && (
                <LoadingCircle />
            )) || (
                <React.Fragment>
                    <DialogContent className={classes.content}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} className={alert === 'due' ? classes.headerDue : (alert === 'upcoming' ? classes.headerUpcoming : classes.headerDefault)}>
                                <Grid container spacing={3} justify='flex-end' alignItems='center'>
                                    <Grid item xs>
                                        <ReminderAlert type={alert} date={date} inDialog />
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title='Delete'>
                                            <IconButton
                                                color="primary"
                                                onClick={() => this.handleConfirmationOpen('delete')} 
                                            >
                                                <FALightIcon icon={'trash-alt'} style={{width: 20, height: 20}} noMargin buttonPrimary />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="Close">
                                            <IconButton
                                                color="primary"
                                                onClick={() => this.props.closeDialog()} 
                                            >
                                                <FALightIcon icon="times" style={{width: 24, height: 24}} noMargin buttonPrimary />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className={classes.title}>
                                        <Editable
                                            text={(
                                                <Typography variant="h6" className={classes.editableTitle} style={{position: 'relative'}}>
                                                    {formData.reminderTitle}
                                                    <Tooltip title="Update Reminder">
                                                        <IconButton style={{position: 'absolute', right: 8, top: 16 }}>
                                                            <FALightIcon icon="pencil-alt" noMargin button />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Typography>
                                            )}
                                            type="input"
                                            onChange={this.handleUpdateReminder}
                                        >
                                            <TextField 
                                                name="reminderTitle"
                                                margin="none" 
                                                autoComplete="off"
                                                error={formErrors && formErrors['reminderTitle'] && true}
                                                helperText={formErrors && formErrors['reminderTitle']}
                                                value={formData.reminderTitle}
                                                onChange={this.handleChange}
                                                fullWidth
                                                autoFocus
                                            />
                                        </Editable>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />   
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridNotes}>
                                        <Grid container spacing={1} alignItems='flex-start'>
                                            <Grid item xs={12}>
                                                <Editable
                                                    text={(
                                                        <Typography variant="subtitle1" className={classes.editableTitle} style={{position: 'relative'}}>
                                                            {(!_.isEmpty(formData.reminderText) && (
                                                                formData.reminderText && formData.reminderText.split('\n').map((item, key) => (
                                                                    <span key={key}>{item}<br /></span>
                                                                ))
                                                            )) || (
                                                                'Add Notes...'
                                                            )}
                                                            <Tooltip title="Update Notes">
                                                                <IconButton style={{position: 'absolute', right: 8, top: 16 }}>
                                                                    <FALightIcon icon="pencil-alt" noMargin button />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Typography>
                                                    )}
                                                    type="textarea"
                                                    onChange={this.handleUpdateReminder}
                                                >
                                                    <Textarea 
                                                        name="reminderText"
                                                        margin="none" 
                                                        autoComplete="off"
                                                        error={formErrors && formErrors['reminderText'] && true}
                                                        helperText={formErrors && formErrors['reminderText']}
                                                        value={formData.reminderText}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        autoFocus
                                                    />
                                                </Editable>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridNotes}>
                                        <Grid container spacing={1} alignItems='flex-start'>
                                            <Grid container xs={12} style={{alignItems:'center'}}>
                                                <Grid item xs={12}>
                                                    {showLinkCustomer && 
                                                        <Grid container xs={12} spacing={4} >
                                                            <Grid item xs={12} >
                                                                <CustomerAddressSearch 
                                                                    reset={this.handleResetCustomer}
                                                                    callback={this.selectCustomer}
                                                                    value={formData?.reminderAttach?.cust_name}
                                                                />
                                                            </Grid>
                                                        </Grid>}
                                                    {!showLinkCustomer && 
                                                        ((formData.reminderAttachType === 'customer' &&  (
                                                         <Grid container  spacing={4} alignItems='flex-start'>
                                                            <Grid item xs={formData.reminderAttachId ? 10 : 12} >
                                                                <Typography variant="subtitle1" className={classes.editableTitle} style={{position: 'relative'}} onClick={this.handleShowCustSearch}>
                                                                    { formData.reminderAttach?.cust_name ? (<>Linked to customer <b>{formData.reminderAttach.cust_name}</b></>) : ('Link Customer...') }
                                                                    <Tooltip title="Update Customer">
                                                                        <IconButton style={{position: 'absolute', right: 8, top: 16 }}>
                                                                            <FALightIcon icon="pencil-alt" noMargin button />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Typography> 
                                                            </Grid>
                                                            {formData.reminderAttachId ?
                                                                <>
                                                                    <Grid item xs={1}>
                                                                        <IconButton 
                                                                            style={{marginTop:'1em', float: 'right'}} 
                                                                            onClick={this.HandleRemoveCustomer}
                                                                        >
                                                                            <FALightIcon icon="times" button />
                                                                        </IconButton>
                                                                    </Grid> 
                                                                    <Grid item xs={1}>
                                                                        <IconButton 
                                                                            style={{marginTop:'1em', float: 'right'}} 
                                                                            component='a' 
                                                                            href={`/customers/view/${formData.reminderAttach.cust_id}`}
                                                                        >
                                                                            <FALightIcon icon="search" button />
                                                                        </IconButton>
                                                                    </Grid> 
                                                                </>: <></> }
                                                         </Grid>
                                                        )))
                                                    }
                                                    {!showLinkAddress &&
                                                        ((formData.reminderAttachType === 'address' &&  (
                                                            <Grid container  spacing={4} alignItems='flex-start'>
                                                            <Grid item xs={formData.reminderAttachId ? 10 : 12} >
                                                                <Typography variant="subtitle1" className={classes.editableTitle} style={{position: 'relative'}} onClick={this.handleShowAddressSearch}>
                                                                    { formData.reminderAttachAddress?.address_name ? (<>Linked to address <b>{formData.reminderAttachAddress.address_name}</b></>) : ('Link Address...') }
                                                                    <Tooltip title="Update Address">
                                                                        <IconButton style={{position: 'absolute', right: 8, top: 16 }}>
                                                                            <FALightIcon icon="pencil-alt" noMargin button />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Typography> 
                                                            </Grid>
                                                            {formData.reminderAttachId ?
                                                                <>
                                                                    <Grid item xs={1}>
                                                                        <IconButton 
                                                                            style={{marginTop:'1em', float: 'right'}} 
                                                                            onClick={this.HandleRemoveAddress}
                                                                        >
                                                                            <FALightIcon icon="times" button />
                                                                        </IconButton>
                                                                    </Grid> 
                                                                    <Grid item xs={1}>
                                                                        <IconButton 
                                                                            style={{marginTop:'1em', float: 'right'}} 
                                                                            component='a' 
                                                                            href={`/customers/addresses/view/${formData.reminderAttachId}`}
                                                                        >
                                                                            <FALightIcon icon="search" button />
                                                                        </IconButton>
                                                                    </Grid> 
                                                                </>: <></> }
                                                            </Grid>
                                                        )))
                                                    }
                                                    {showLinkAddress &&
                                                        <Grid container xs={12} spacing={4} >
                                                            <Grid item xs={12} >
                                                                <SearchSiteAddress 
                                                                    callback={this.selectAddress} 
                                                                    value={formData?.reminderAttachAddress?.address_name}
                                                                    reset={this.handleResetAddress}
                                                                /> 
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                                
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={3} className={classes.sideBar}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems='center' align='center'>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" component="div">
                                                    Due
                                                </Typography>
                                            </Grid>
                                            {alert === "due" && (
                                                <Grid item xs={12}>
                                                    <Tooltip title={`Snooze Reminder`}>
                                                        <Chip
                                                            avatar={<Avatar alt='Snooze' style={{width: 32, height: 32}} className={classes.due}><FASolidIcon icon='bell-exclamation' noMargin buttonPrimary /></Avatar>}
                                                            className={classes.due}
                                                            label='Snooze'
                                                            clickable={true}
                                                            onClick={this.handleShowSnooze}
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <Tooltip title={`Mark as Completed`}>
                                                    <Chip
                                                        avatar={<Avatar alt='Complete' style={{width: 32, height: 32}} className={classes.complete}><FASolidIcon icon='calendar-check' noMargin buttonPrimary /></Avatar>}
                                                        className={classes.complete}
                                                        label='Mark Completed'
                                                        onClick={() => this.handleConfirmationOpen('submit')}
                                                        clickable={true}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {(this.state.showDatePicker && (
                                                    <DatePicker
                                                        id="reminderDue"
                                                        name="reminderDue"
                                                        type="datetime"
                                                        value={formData.reminderDue}
                                                        errorText={formErrors && formErrors['reminderDue']}
                                                        onChange={date => this.handleDateChange('reminderDue', date)}
                                                        autoOk={true}
                                                        margin="none"
                                                        open={true}
                                                        disablePast={true}
                                                    />
                                                )) || (
                                                    <Editable
                                                        text={(
                                                            <Tooltip title={`Change Due Date`}>
                                                                <Chip
                                                                    avatar={<Avatar alt='Due' style={{width: 32, height: 32}}><FASolidIcon icon='pencil-alt' noMargin buttonPrimary /></Avatar>}
                                                                    label={moment(model.reminder_due_datetime).format("DD/MM/YYYY HH:mm")}
                                                                    clickable={true}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                        type="chip"
                                                        onChange={this.handleUpdateReminder}
                                                        onEdit={() => {
                                                            if(formData.reminderRepeats === 1) {
                                                                this.handleConfirmationOpen('update')
                                                            } else {
                                                                this.update()
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {model.task && (
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='center' align='center'>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" component="div">
                                                        Task
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Chip
                                                        avatar={<Avatar alt='Due' style={{width: 32, height: 32}}><FASolidIcon icon='tasks' noMargin buttonPrimary /></Avatar>}
                                                        label='View Task'
                                                        clickable={true}
                                                        onClick={() => this.props.deployDialog(<TaskDialog id={model.task.task_id} />, "", "standard", "md")}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems='center' align='center'>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" component="div">
                                                    Repeats
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Editable
                                                    text={(
                                                        <Tooltip title={`Change Repetition`}>
                                                            <Chip
                                                                avatar={<Avatar alt='Due' style={{width: 32, height: 32}}><FASolidIcon icon='pencil-alt' noMargin buttonPrimary /></Avatar>}
                                                                label={model.reminder_repeat_text}
                                                                clickable={true}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    type="chip"
                                                    onSuccess={this.handleUpdateReminder}
                                                    onCancel={this.handleReset}
                                                    btnText='Update'
                                                    manualSave
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox 
                                                                checked={formData.reminderRepeats === 1 ? true : false} 
                                                                onChange={this.handleCheckedChange} 
                                                                value={formData.reminderRepeats}
                                                                name="reminderRepeats"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Repeat Reminder"
                                                    />
                                                    {!!formData.reminderRepeats && (
                                                        <Grid container spacing={1} alignItems='center'>
            
                                                            <Grid item xs={12}>
                                                                {formData.reminderRepeat !== 'weekday' &&
                                                                    <FormControl margin="none" fullWidth>
                                                                        <AutoCompleteSelect
                                                                            options={formData.renderOptions} 
                                                                            value={formData.reminderOccurance}
                                                                            onChange={e => this.handleRepeatReminderChange('reminderOccurance',e.value,0)}
                                                                            error={formErrors && formErrors['reminderOccurance'] && true}
                                                                            disableSort
                                                                            noClear
                                                                        />
                                                                    </FormControl>
                                                                }
                                                            </Grid>
                                                            
                                                            <Grid item xs={12}>
                                                                <FormControl margin="none" fullWidth>
                                                                    <AutoCompleteSelect
                                                                        options={[
                                                                            {label: `Hour${formData.reminderOccurance   > 1 ? 's' : ''}`,   value: `hour`},
                                                                            {label: `Day${formData.reminderOccurance    > 1 ? 's' : ''}`,   value: `day`},
                                                                            {label: `Week${formData.reminderOccurance   > 1 ? 's' : ''}`,   value: `week`},
                                                                            {label: `Week Day`,                                             value: `weekday`},
                                                                            {label: `Month${formData.reminderOccurance  > 1 ? 's' : ''}`,   value: `month`},
                                                                            {label: `Year${formData.reminderOccurance   > 1 ? 's' : ''}`,   value: `year`},
                                                                        ]} 
                                                                        defaultValue={formData.reminderRepeat}
                                                                        value={formData.reminderRepeat}
                                                                        onChange={e => this.handleRepeatReminderChange('reminderRepeat',e.value, 0)}
                                                                        disableSort
                                                                        noClear
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Editable>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems='center' align='center'>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" component="div">
                                                    About Reminder
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {model.reminder_next_due && (
                                                    <Typography variant="caption" component="div" paragraph>
                                                        Next Repeats<br />{moment(model.reminder_next_due).format("DD/MM/YYYY HH:mm")}
                                                    </Typography>
                                                )}
                                                <Typography variant="caption" component="div" paragraph>
                                                    First Due<br />{moment(model.reminder_base_datetime).format("DD/MM/YYYY HH:mm")}
                                                </Typography>
                                                <Typography variant="caption" component="div">
                                                    Created<br />{moment(model.reminder_created_datetime).format("DD/MM/YYYY HH:mm")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <FormDialog 
                        open            = {snoozeFromData.showForm}
                        close           = {this.handleCloseSnoozeForm}
                        handleSubmit    = {this.handleSnooze}
                        height          = {'200px'}
                        submitText      = 'Snooze'
                        body={
                            <>
                                <Grid container xs={12} spacing={3}>
                                    <Grid container item xs={12} alignItems='flex-end' spacing={3}>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                                Snooze for 
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteSelect
                                                    options={snoozeFromData.renderOptions} 
                                                    defaultValue={snoozeFromData.reminderAmount}
                                                    value={snoozeFromData.reminderAmount}
                                                    onChange={e => this.handleRepeatReminderChange('reminderAmount',e.value,1)}
                                                    disableSort
                                                    noClear
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteSelect
                                                    options={[
                                                        {label: `Hour${snoozeFromData.reminderAmount > 1 ? 's' : ''}`,  value: `hour`},
                                                        {label: `Day${snoozeFromData.reminderAmount > 1 ? 's' : ''}`,   value: `day`},
                                                        {label: `Week${snoozeFromData.reminderAmount > 1 ? 's' : ''}`,  value: `week`},
                                                        {label: `Month${snoozeFromData.reminderAmount > 1 ? 's' : ''}`, value: `month`},
                                                        {label: `Year${snoozeFromData.reminderAmount > 1 ? 's' : ''}`,  value: `year`},
                                                    ]} 
                                                    defaultValue={snoozeFromData.reminderOccurance}
                                                    value={snoozeFromData.reminderOccurance}
                                                    onChange={e => this.handleRepeatReminderChange('reminderOccurance',e.value, 1)}
                                                    disableSort
                                                    noClear
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            id="reminderDue"
                                            name="reminderDue"
                                            type="datetime"
                                            label="Date/Time"
                                            value={snoozeFromData.date}
                                            errorText={formErrors && formErrors['generic']}
                                            onChange={this.handleSnoozeDateChange}
                                            autoOk={true}
                                            disablePast={true}
                                            error={formErrors && formErrors['generic'] && true}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        }
                    />
                    <ConfirmationDialog 
                        open={confirmation.submit} 
                        success={() => this.handleConfirmationSuccess('submit')} 
                        close={() => this.handleConfirmationClose('submit')} 
                        title={`Complete Reminder?`} 
                        message={`Are you sure you want to mark this reminder as completed?`}
                    />
                    <ConfirmationDialog 
                        open={confirmation.delete} 
                        success={() => this.handleConfirmationSuccess('delete')} 
                        close={() => this.handleConfirmationClose('delete')} 
                        title={`Delete Reminder?`} 
                        message={`Are you sure you want to delete this reminder?`}
                    />
                    <ConfirmationDialog 
                        open={confirmation.update} 
                        success={() => this.handleConfirmationSuccess('update')} 
                        close={() => this.handleConfirmationClose('update')} 
                        title={`Change Due Date?`} 
                        message={`This reminder repeats ${model.reminder_repeat_text && model.reminder_repeat_text.toLowerCase()}, if you change the due date then future reminders will repeat based on the date and time you choose now.`}
                    />
                </React.Fragment>
            )
        )
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployDialog: (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
        closeDialog: () => dispatch(closeDialog())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReminderDialog))