import React, { Component } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import {colors} from 'Helpers/ColourHelper';

const materialTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#0282C6'
        },
        secondary: {
            main: '#D61F26'
        }
    },
    overrides: {
        MuiButton: {
            root: {
                '&:nth-child(2)': {
                    color: "white",
                    backgroundColor: 'rgba(2,130,198,1)',
                    '&:hover': {
                        color: "white",
                        backgroundColor: 'rgba(2,130,198,0.8)',
                    }
                }
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
                },
                '&:after': {
                    borderBottom: '2px solid #0282C6'
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '2px solid #0282C6'
                }
            }
        }
    }
});
export default class _DatePicker extends Component {
    render() {
        const ElementTag = this.props.type === 'datetime' ? KeyboardDateTimePicker : (this.props.type === 'keyboardDate' ? KeyboardDatePicker : DatePicker);
        return (
            <React.Fragment>
                <FormControl fullWidth margin={this.props.margin ? this.props.margin : 'normal'} error={!!this.props.errorText}>
                    <MuiThemeProvider theme={materialTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            {this.props.open !== undefined ?
                                <ElementTag
                                    id={this.props.id}
                                    name={this.props.name}
                                    label={this.props.label}
                                    value={this.props.value}
                                    onChange={this.props.onChange}
                                    disableFuture={this.props.disableFuture}
                                    disablePast={this.props.disablePast}
                                    minDate={this.props.minDate}
                                    maxDate={this.props.maxDate}
                                    format={this.props.type === 'datetime' ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"}
                                    autoOk={this.props.autoOk}
                                    ampm={false}
                                    disabled={this.props.disabled}
                                    InputLabelProps={{
                                        error: !!this.props.errorText
                                    }}
                                    InputProps={{
                                        error: !!this.props.errorText,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FAIcon icon='calendar-alt' style={{color: !!this.props.errorText && colors.red}} disabled={this.props.disabled} light noMargin />
                                            </InputAdornment>
                                        ),
                                        autoFocus: true
                                    }}
                                    open={this.props.open}
                                    onOpen={this.props.onOpen}
                                    onClose={this.props.onClose}
                                    inputVariant={this.props.variant}
                                    
                                />
                                :
                                <ElementTag
                                    id={this.props.id}
                                    name={this.props.name}
                                    label={this.props.label}
                                    value={this.props.value}
                                    onChange={this.props.onChange}
                                    disableFuture={this.props.disableFuture}
                                    disablePast={this.props.disablePast}
                                    minDate={this.props.minDate}
                                    maxDate={this.props.maxDate}
                                    format={this.props.type === 'datetime' ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"}
                                    autoOk={this.props.autoOk}
                                    ampm={false}
                                    disabled={this.props.disabled}
                                    InputLabelProps={{
                                        error: !!this.props.errorText
                                    }}
                                    InputProps={{
                                        error: !!this.props.errorText,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FAIcon icon='calendar-alt' style={{color: !!this.props.errorText && colors.red}} disabled={this.props.disabled} light noMargin />
                                            </InputAdornment>
                                        )
                                    }}
                                    inputVariant={this.props.variant}
                                    inputProps={{
                                        ...this.props.inputProps
                                    }}
                                />
                            }
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                    {this.props.errorText &&
                        <FormHelperText>{this.props.errorText}</FormHelperText>
                    }
                </FormControl>
            </React.Fragment>
        );
    }
}