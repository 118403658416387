import React, { Component } from 'react';
import API                  from 'API';
import { connect }          from 'react-redux';

import DataTable   from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import SearchBlock from './SearchBlock';

import { Grid, Typography } from '@material-ui/core';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import AllIcon from 'Components/Common/Icons/AllIcon';

const initialState = () => ({
    access: {
        addPackage: false,
        editPackage: false,
        deletePackage: false,
    },
    packages: [],
    searchData: {
        keywords: null,
    }
});

class AllPackages extends Component {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'packages:search';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        this.getAccess();
        this.getPackages();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/view-sales-package'),
            API.get('/staff/my/access/check/edit-sales-package'),
            API.get('/staff/my/access/check/delete-sales-package')
        ]).then(([view, edit, del]) => {
            this.setState({
                access: {
                    viewPackage:   view?.data?.has_access  || false,
                    editPackage:   edit?.data?.has_access || false,
                    deletePackage: del?.data?.has_access || false
                }
            });
        })
        
    }

    getPackages = () => {
        API.get('/sales/packages', {params: this.state.searchData})
        .then(res => {
            this.setState({ packages: res.data });
        })
    }

    handleSetSearch = props => {
        this.setState({
            searchData: {...props}
        },this.getPackages)
    }

    deletePackage = (id) => () => {
        API.post(`/sales/packages/${id}/delete`)
        .then(this.getPackages)
    }

   
    render() {
        const { packages, access } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5" style={{marginBottom: '1em'}}>Sales Packages</Typography>
                </Grid>
                <Grid item xs={12}>
                   <SearchBlock callBack={this.handleSetSearch} />
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable
                            config={{
                                key: 'p_id',
                                pagination: true,
                            }}
                            rows={packages}
                            columns={[
                                {
                                    heading: 'Sales Package',
                                    field: 'p_name',
                                },
                                {
                                    heading:       'Discount',
                                    field:         i => 
                                            <AllIcon
                                                icon={i.p_discounted ? icons.true : icons.false}
                                                color={i.p_discounted ? colors.green : colors.red}
                                                tooltip={i.p_discounted ? `£${i.p_discount} (${Math.ceil(parseFloat(parseFloat(i.p_discount) / parseFloat(i.p_subtotal)) * 100)}%)` : 'No Discount'}
                                                noMargin
                                            />,
                                    sizeToContent: true,
                                    alignment:     'center',
                                },
                                {
                                    heading:       'Sub Total',
                                    field:         'p_subtotal',
                                    fieldFormat:   'currency',
                                    sizeToContent: true,
                                },
                                {
                                    heading:       'VAT',
                                    field:         'p_vat',
                                    fieldFormat:   'currency',
                                    sizeToContent: true,
                                },
                                {
                                    heading:       'Total',
                                    field:         'p_total',
                                    fieldFormat:   'currency',
                                    sizeToContent: true,
                                },
                                {
                                    actions: r => ([
                                        {
                                            name: 'Delete',
                                            icon: icons.delete,
                                            onClick: ()=>this.props.deployConfirmation('Are you sure you want to delete this Package?', 'Delete Package', this.deletePackage(r.p_id)),
                                            disabled: !access.deletePackage,
                                        },
                                        {
                                            name: 'Edit',
                                            icon: icons.edit,
                                            link: `/sales/package/edit/${r.p_id}`,
                                            disabled: !access.editPackage,
                                        },
                                        {
                                            name: 'View',
                                            icon: icons.search,
                                            link: `/sales/package/${r.p_id}`,
                                            disabled: !access.viewPackage,
                                        }
                                    ])
                                }
                            ]}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AllPackages);
