import React, { useState } from 'react';
import moment              from 'moment';
import { useDispatch }     from 'react-redux';
import API                 from 'API';

import { formatValidationErrors } from 'Helpers/ErrorHelper';

import { Button, Grid, InputAdornment, TextField } from '@material-ui/core';

import DatePicker    from 'Components/Common/DatePickers/DatePicker';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog }        from 'Actions/Dialog/Dialog';
import { deploySnackBar }     from 'Actions/SnackBar/SnackBar';

const StripeProcessPaymentDialog = ({id, callback, type, amount = 0, paymentTerm, amountOutstanding, paymentTermId}) => {
    const dispatch = useDispatch();

    const initialState = {
        formData: {
            paymentAmount: amount,
            paymentMethod: 'Stripe',
            paymentRef: '',
            authNo: '',
            date: moment(),
            file: ''
        },
        formErrors: {},
        isLoading: false
    };

    let [state, setState] = useState(initialState);

    const {formData, formData:{paymentAmount, paymentRef, date, file}, formErrors, isLoading} = state;

    const handleClose = () => dispatch(closeDialog());

    const handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: file
            }
        }));
    }

    const clearFile = () => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                file: ''
            }
        }));
    }

    const handleFormChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleDateChange = e => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                date: moment(e)
            }
        }));
    }

    const handleChange = e => {
        let {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleToDecimalPlace = (decimal) => e => {
        let {name, value} = e.target;
        let newVal = parseFloat(value).toFixed(decimal);

        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: newVal
            }
        }));
    }

    const handleSubmit = () => dispatch(deployConfirmation('Are you sure you want to process the payment?', 'Process Payment?', submit));
    
    const submit = () => {
        setState(state => ({
            ...state,
            isLoading: true
        }));
        
        let newFormData = new FormData();
        Object.keys(formData).forEach(key => {
            if(key === 'file') {
                newFormData.append('file', formData[key]);
            } else {
                newFormData.append(key, formData[key]);
            }
        });

        let url = '';
        switch(type){
            case 'invoice':
                url = `/sales/orders/invoice/${id}/processPayment`;
            break;
            default:
                url = `/sales/orders/${id}/processPayment`;
            break;
        }
        
        API.post(url, newFormData)
        .then(result => {
            if(result.data.errors) {
                setState(state => ({
                    ...state,
                    formErrors: formatValidationErrors(result.data.errors),
                    isLoading: false
                }));
            } else if(result.data.success) {
                if(callback) callback();
                dispatch(deploySnackBar('success', 'You have successfully processed the card payment!'));
                handleClose();
            }
        });
    }

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <Grid container>
                    <Grid item xs={12}>
                       <form>
                            <TextField
                                label="Payment Term"
                                value={paymentTerm}
                                margin="normal"
                                fullWidth
                                disabled
                                InputProps={{
                                    className: 'disabledText'
                                }}
                            />
                            <TextField
                                name="paymentAmount"
                                label="Amount"
                                value={paymentAmount}
                                onChange={handleChange}
                                onBlur={handleToDecimalPlace(2)}
                                margin="normal"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                                type="number"
                                fullWidth
                                error={formErrors && formErrors['paymentAmount'] && true}
                                helperText={formErrors && formErrors['paymentAmount']}
                                disabled={parseInt(paymentTermId) === 5}
                            />
                            <TextField
                                label="Amount Outstanding"
                                value={amountOutstanding}
                                margin="normal"
                                fullWidth
                                disabled
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                    className: 'disabledText'
                                }}
                            />
                           <TextField 
                                id="paymentRef"
                                name="paymentRef"
                                label="Payment Ref: *"
                                value={paymentRef}
                                error={formErrors && formErrors['paymentRef'] && true}
                                helperText={formErrors && formErrors['paymentRef']}
                                onChange={handleFormChange}
                                margin="normal"
                                type="text"
                                fullWidth
                            />
                            <DragFileInput 
                                id="file"
                                name="file"
                                label="Upload File:"
                                file={file}
                                errorText={formErrors && formErrors['file']}
                                onChange={handleFileChange}
                                cancelOnClick={clearFile}
                                emptyText="No file selected"
                            />
                            <DatePicker
                                type="date"
                                id="date"
                                name="date"
                                label="Payment Date *"
                                value={date}
                                errorText={formErrors && formErrors['date']}
                                onChange={handleDateChange}
                                autoOk={true}
                            />
                       </form>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="buttonRow">
                            <Button 
                                variant="outlined" 
                                color="default" 
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
}

export default StripeProcessPaymentDialog;