import React, { PureComponent } from 'react';
import _                        from 'lodash';
import logo                     from 'Assets/Images/clenaware_logo_icon.png';

import { Button, FormControl, Table, TableBody, TableCell, TableRow, TextField, Typography, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AllIcon            from 'Components/Common/Icons/AllIcon';

import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';

const styles = () => ({
    td : {
        border: 'none'
    }
});

const initialState = {
    formErrors: []
}

class AddPartEntryRow extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.menuSizeRef = React.createRef();
        this.submit = false;
        this.focus = (typeof props.focus !== 'undefined') ? props.focus : true;
    }
    
    componentDidMount = () => {        
        if (this.focus) this.submit = setTimeout(() => this.partSelectDropdown.focus(), 50)
    }
    
    componentWillUnmount = () => {
        if(this.submit) {
            clearTimeout(this.submit);
        }
    }
    onSubmit = e => {
        if(e) e.preventDefault();
        this.submit = setTimeout(
            () => {
                let qty = !this.props.dialog && !this.props.dialogRowData ? this.props.selectedPartRow.quantity : this.props.dialogRowData.quantity;
                let unitPrice = !this.props.dialog && !this.props.dialogRowData ? this.props.selectedPartRow.unitPrice : this.props.dialogRowData.unitPrice;
                let discountRate = !this.props.dialog && !this.props.dialogRowData ? this.props.selectedPartRow.discountRate : this.props.dialogRowData.discountRate;

                if(!qty || qty <= 0 || isNaN(qty)) {
                    this.setState({
                        formErrors: {
                            qty: 'Please enter a valid quantity'
                        }
                    });
                } else if(!unitPrice || parseFloat(unitPrice) < 0 || isNaN(unitPrice)) {
                    this.setState({
                        formErrors: {
                            unitPrice: 'Please enter a valid unit price'
                        }
                    });
                } else if(((!discountRate && parseFloat(discountRate) !== 0) || discountRate < 0 || discountRate > 100 || isNaN(discountRate)) && this.props.quotationType === 'With Discount') {
                    this.setState({
                        formErrors: {
                            discountRate: 'Please enter a valid discount rate'
                        }
                    });
                } else {
                    if(!_.isEmpty(this.state.formErrors)) {
                        this.setState({
                            formErrors: initialState.formErrors
                        });
                    }
                    if(!this.props.dialog) {
                        this.props.handleWarrantyDialogOpen();
                        this.partSelectDropdown.focus();
                    } else {
                        if(e) {
                            this.props.handleUpdatePartDialogSuccess();
                        } else {
                            this.props.handleWarrantyUpdate();
                        }
                    }
                }
            }
        , 50);
    }
    render() {
        const { formErrors } = this.state;
        const { classes, selectedPartRow, handlePartSelect, handlePartSelectRowChange, partList, partSelectQuantity, quotationType, dialog, dialogRowData, handleUpdatePartDialogClose } = this.props;

        return(
            <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
                {!dialog && (
                    <Typography variant="h6">
                        Add Part
                    </Typography>
                )}
                <Table ref={this.menuSizeRef}>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.td} style={{textAlign:'center', height:65, width:65}}>
                                <img src={!dialog && !dialogRowData ? (selectedPartRow && selectedPartRow.partImage ? selectedPartRow.partImage : logo) : (dialogRowData.partImage ? dialogRowData.partImage : logo)} style={{maxHeight:65, maxWidth:'80%', verticalAlign:'middle'}} alt="" />
                            </TableCell>
                            <TableCell className={classes.td} style={{width:'20%', maxWidth:250}}>
                                <FormControl fullWidth>
                                    <AutoCompleteSelect 
                                        label='Part'
                                        options={partList} 
                                        value={!dialog && !dialogRowData ? selectedPartRow.part : dialogRowData.part}
                                        onChange={handlePartSelect}
                                        selectRef={select => this.partSelectDropdown = select}
                                        menuSizeRef={this.menuSizeRef}
                                        error={this.props.formErrors && this.props.formErrors['partRows'] && true}
                                        errorText={this.props.formErrors && this.props.formErrors['partRows']}
                                        noClear={dialogRowData ? true : undefined}
                                        autoFocus={false}
                                    />
                                </FormControl>
                            </TableCell>
                            <TableCell className={classes.td} style={{minWidth:'88px'}}>
                                <TextField
                                    name="quantity"
                                    label="Quantity"
                                    value={parseFloat(!dialog && !dialogRowData ? selectedPartRow.quantity : dialogRowData.quantity)}
                                    error={(formErrors && formErrors['qty'] && true)}
                                    helperText={(formErrors && formErrors['qty'])}
                                    onChange={handlePartSelectRowChange(0)}
                                    type="number"
                                    size="small"
                                    fullWidth
                                    inputProps={{
                                        min: 1
                                    }}
                                    inputRef={partSelectQuantity}
                                />
                            </TableCell>
                            <TableCell className={classes.td} style={{minWidth:'125px'}}>
                                <TextField
                                    name="unitPrice"
                                    label="Unit Price"
                                    value={!dialog && !dialogRowData ? selectedPartRow.unitPrice : dialogRowData.unitPrice}
                                    error={formErrors && formErrors['unitPrice'] && true}
                                    helperText={formErrors && formErrors['unitPrice']}
                                    onChange={handlePartSelectRowChange(0)}
                                    onBlur={handlePartSelectRowChange(2)}
                                    type="number"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                />
                            </TableCell>
                            {quotationType === 'With Discount' &&
                                <React.Fragment>
                                    <TableCell className={classes.td} style={{minWidth:'120px'}}>
                                        <TextField
                                            name="discountRate"
                                            label="Discount Rate"
                                            value={!dialog && !dialogRowData ? selectedPartRow.discountRate : dialogRowData.discountRate}
                                            error={formErrors && formErrors['discountRate'] && true}
                                            helperText={formErrors && formErrors['discountRate']}
                                            onChange={handlePartSelectRowChange(0)}
                                            onBlur={handlePartSelectRowChange(2)}
                                            type="number"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                                                inputProps: {
                                                    min: 0,
                                                    max: 100
                                                }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.td} style={{minWidth:'125px'}}>
                                        <TextField
                                            name="newUnitPrice"
                                            label={'New Unit Price'}
                                            value={!dialog && !dialogRowData ? selectedPartRow.newUnitPrice : dialogRowData.newUnitPrice}
                                            onChange={handlePartSelectRowChange(0)}
                                            onBlur={handlePartSelectRowChange(2)}
                                            type="number"
                                            fullWidth
                                            disabled
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.td} style={{minWidth:'125px'}}>
                                        <TextField
                                            name="discountTotal"
                                            label="Discount Total"
                                            value={!dialog && !dialogRowData ? selectedPartRow.discountTotal : dialogRowData.discountTotal}
                                            onChange={handlePartSelectRowChange(0)}
                                            onBlur={handlePartSelectRowChange(2)}
                                            type="number"
                                            fullWidth
                                            disabled
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                            }}
                                        />
                                    </TableCell>
                                </React.Fragment>
                            }
                            <TableCell className={classes.td} style={{minWidth:'125px'}}>
                                <TextField
                                    name="totalPrice"
                                    label="Total Price"
                                    value={!dialog && !dialogRowData ? selectedPartRow.totalPrice : dialogRowData.totalPrice}
                                    onChange={handlePartSelectRowChange(0)}
                                    onBlur={handlePartSelectRowChange(2)}
                                    type="number"
                                    fullWidth
                                    disabled
                                    InputProps={{
                                        startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                    }}
                                />
                            </TableCell>
                            {!dialog && (
                                <TableCell className={classes.td} style={{width: 88, float:'right'}}>
                                    <Button 
                                        variant="outlined"
                                        color="primary"
                                        type="submit"
                                        disabled={selectedPartRow.part < 1}
                                    >
                                        Add
                                    </Button>
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow style={{border: '0px solid black'}}>
                                <TableCell style={{border: '0px solid black'}}></TableCell>
                                <TableCell colSpan={3} style={{border: '0px solid black', padding: 0, paddingLeft: 8}}>
                                    {!!(selectedPartRow?.stock && selectedPartRow.stockable) && 
                                        <Typography variant='body1' style={{color: parseFloat(selectedPartRow.stock.stock_available) < parseFloat(!dialog && !dialogRowData ? selectedPartRow.quantity : dialogRowData.quantity) && colors.red}}>
                                            <AllIcon icon={IconHelper.stock} color={parseFloat(selectedPartRow.stock.stock_available) < parseFloat(!dialog && !dialogRowData ? selectedPartRow.quantity : dialogRowData.quantity) && colors.red}/>
                                            Current Stock: {parseFloat(selectedPartRow.stock.stock_current_qty).toFixed(3)} | Available Stock: {parseFloat(selectedPartRow.stock.stock_available).toFixed(3)}
                                        </Typography>
                                    }   
                            </TableCell>
                        </TableRow>
                    </TableBody>                           
                </Table>
                {dialog && 
                    <div className="buttonRow">
                        <Button onClick={handleUpdatePartDialogClose} variant="outlined" color="default">Close</Button>
                        <Button onClick={() => this.onSubmit(null)} color="primary" variant="contained" disabled={dialogRowData.part === '' && true}>Update Warranty / Notes</Button>
                        <Button color="primary" variant="contained" type="submit" disabled={dialogRowData.part === '' && true}>Update</Button>
                    </div>
                }
            </form>
        );
    }
}

export default withStyles(styles)(AddPartEntryRow);
