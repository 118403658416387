import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';

import DownloadCSV   from 'Components/Common/Buttons/DownloadCSV';
import DataTable     from 'Components/Common/DataTables/CiDataTable';
import AllIcon       from 'Components/Common/Icons/AllIcon';
import Textarea      from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';
import BackButton    from 'Components/Common/Buttons/BackButton';

import { colors }    from 'Helpers/ColourHelper';
import icons         from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';

import { AppBar, Grid, Tab, Tabs, Typography } from '@material-ui/core/';

const initialState = {
    stockMovement: {},
    details: [],
    isLoading: true,
    access: {},
    showEditDialog: false,
    currentTab: 0
}


class ViewStockMovement extends Component {
    constructor(props) {
        super(props);
        this.state = {...initialState, id: this.props.match.params.smtId == 'new' ? 0 : this.props.match.params.smtId }
    }

    componentDidMount() { this.getData(); }

    getData = () => {
        Promise.all([
            API.get(`/stock/movement/${this.props.match.params.smId}`),
        ]).then(([stockMovementRes]) => {
            this.setState({
                stockMovement: stockMovementRes.data,
                details: _.map(stockMovementRes.data.details, (i,idx) => _.assign({
                    id:               i.smd_id,
                    part:             i.smd_part_id,
                    part_number:      i.part.part_number,
                    part_description: i.part.part_description,
                    qty:              i.smd_qty_in ? i.smd_qty_in : i.smd_qty_out,
                    in:               i.smd_qty_in ? 1 : 0,
                    reason:           i.reason.smr_reason,
                })),
                grouped: _.map(stockMovementRes.data.grouped, (i,idx) => _.assign({
                    id:         i.smg_id,
                    part:       i.smg_part_id,
                    number:     i.part.part_number,
                    desc:       i.part.part_description,
                    before:     parseFloat(i.smg_stock_before),
                    in:         parseFloat(i.smg_stock_in),
                    out:        parseFloat(i.smg_stock_out),
                    change:     parseFloat(i.smg_stock_in) - parseFloat(i.smg_stock_out),
                    after:      parseFloat(i.smg_stock_before) + (parseFloat(i.smg_stock_in) - parseFloat(i.smg_stock_out))
                })),
                isLoading: false
            }, this.masterExcelData)
        })
    }

    getTableColumns = () => {
        return [
            {
                heading:    'Part',
                field:      rowData => rowData.part_number,
                dataRef:    'part',
                sizeToContent: true,
                style:          {width: '6%'},
            },
            {
                heading:    'Description',
                field:      rowData => rowData.part_description,
                dataRef:    'part',
                style:          {width: '32%'},
            },
            {
                heading:    'Stock',
                field:      rowData => rowData.qty,
                dataRef:    'qty',
                alignment:  'center',
                sizeToContent: true,
                style:      {width: '7%'},
            },
            {
                heading:    'Reason',
                field:      rowData => rowData.reason,
                dataRef:    'reason',
                alignment:  'left',
            },
        ]
    }

    handleTabChange = (event, value) => { this.setState({currentTab: value});};

    masterExcelData = () => {
        API.get(`/stock/movement/${this.props.match.params.smId}/masterExcel`)
        .then(res => {
            this.setState({MasterExcelData: res.data});
        })
    }

    render() {
        const {isLoading, details, stockMovement, currentTab, grouped, MasterExcelData} = this.state;
        
        if (isLoading) return( <LoadingCircle/> );
        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Stock Movement
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container xs={12}>
                            <Grid item xs={6}>
                                <Grid container item spacing={3}>
                                    <Grid item lg={3}>
                                        <Typography variant="subtitle1">
                                            Stock Movement:
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={9}>
                                        <Typography variant="subtitle1">
                                            {stockMovement.sm_ref}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={3}>
                                    <Grid item lg={3}>
                                        <Typography variant="subtitle1">
                                            Date For:
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={9}>
                                        <Typography variant="subtitle1">
                                            {clenyDate(stockMovement.sm_date)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={3}>
                                    <Grid item lg={3}>
                                        <Typography variant="subtitle1">
                                            Created By:
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={9}>
                                        <Typography variant="subtitle1">
                                            {stockMovement.sm_created_by}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container item spacing={3}>
                                    <Grid item lg={3}>
                                        <Typography variant="subtitle1">
                                            <br></br>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={9}>
                                        <Typography variant="subtitle1">
                                            <br></br>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {!!stockMovement.sm_submitted_by &&
                                <>
                                    <Grid container item spacing={3}>
                                        <Grid item lg={3}>
                                            <Typography variant="subtitle1">
                                                Completed:
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={9}>
                                            <Typography variant="subtitle1">
                                                {clenyDate(stockMovement.sm_submitted_datetime)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={3}>
                                        <Grid item lg={3}>
                                            <Typography variant="subtitle1">
                                                Completed By:
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={9}>
                                            <Typography variant="subtitle1">
                                                {stockMovement.sm_submitted_by}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>} 
                                {!!stockMovement.sm_deleted_by && !stockMovement.sm_submitted_by && <>
                                    <Grid container item spacing={3}>
                                        <Grid item lg={3}>
                                            <Typography variant="subtitle1">
                                                Deleted:
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={9}>
                                            <Typography variant="subtitle1">
                                                {clenyDate(stockMovement.sm_deleted_datetime)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={3}>
                                        <Grid item lg={3}>
                                            <Typography variant="subtitle1">
                                                Deleted By:
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={9}>
                                            <Typography variant="subtitle1">
                                                {stockMovement.sm_deleted_by}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>}
                                {!!stockMovement.sm_updated_by && !stockMovement.sm_deleted_by && !stockMovement.sm_submitted_by && <>
                                    <Grid container item spacing={3}>
                                        <Grid item lg={3}>
                                            <Typography variant="subtitle1">
                                                Last Update:
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={9}>
                                            <Typography variant="subtitle1">
                                                {clenyDate(stockMovement.sm_updated_datetime)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={3}>
                                        <Grid item lg={3}>
                                            <Typography variant="subtitle1">
                                                Last Update By:
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={9}>
                                            <Typography variant="subtitle1">
                                                {stockMovement.sm_updated_by}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>}
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <AppBar position="static" style={{backgroundColor:'white', color:"#000", minWidth: 1522, width: '100%', zIndex:'auto', marginTop:0}} >
                        <Tabs   value={currentTab} 
                                onChange={this.handleTabChange} 
                                className='xs-max-width' 
                                indicatorColor="primary" 
                                textColor="primary" 
                                variant='scrollable'
                                scrollButtons="auto"
                        >
                            <Tab label='Detailed' />
                            <Tab label='Overview' />
                        </Tabs>
                    </AppBar>
                </Grid>
                {currentTab === 0 &&
                    <>
                        {_.filter(details, i=> i.in && i.qty ).length > 0 &&
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h4"><AllIcon size='large' icon={icons.plus} style={{color:colors.green}} /> Stock In</Typography>
                                    <br/>
                                    <DataTable
                                        config={{
                                            key: 'part',
                                            alternatingRowColours: true,
                                            responsiveimportant: true,
                                        }}
                                        columns={this.getTableColumns()}
                                        rows={_.orderBy(_.filter(details,i=>i.in), ['part_number'], ['asc'])}
                                    />
                                </PaddedPaper>
                            </Grid>
                        }
                        {_.filter(details, i=> !i.in && i.qty ).length > 0 &&
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h4"><AllIcon size='large' icon={icons.minus} style={{color:colors.red}} /> Stock Out</Typography>
                                    <br/>
                                    <DataTable
                                        config={{
                                            key: 'part',
                                            alternatingRowColours: true,
                                            responsiveimportant: true,
                                        }}
                                        columns={this.getTableColumns()}
                                        rows={_.orderBy(_.filter(details,i=>!i.in), ['part_number'], ['asc'])}
                                    />
                                </PaddedPaper>
                            </Grid>
                        }
                        <Grid item xs={12} style={{paddingTop:'1.5em'}}>
                            <PaddedPaper>
                                <Textarea
                                    id="notes"
                                    name="notes"
                                    label='Stock Movement Notes'
                                    value={stockMovement.sm_notes || 'No Notes Added'}
                                    rows={10}
                                    disabled
                                    noMargin
                                />
                            </PaddedPaper>
                        </Grid>
                    </>
                }

                {currentTab == 1 &&
                    <>
                        {_.filter(grouped, g => g.in > 0).length > 0 &&
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h4"><AllIcon size='large' icon={icons.plus} style={{color:colors.green}} /> Stock In</Typography>
                                    <br/>
                                    <DataTable
                                        config={{
                                            key: 'partId',
                                            sticky:                 true,
                                            alternatingRowColours:  true,
                                            responsiveimportant:    true,
                                        }}
                                        columns={[
                                            {
                                                heading:    'Part',
                                                field:      rowData => rowData.number,
                                                dataRef:    'number',
                                                sizeToContent: true,
                                                style: {width: '6%'},
                                            },
                                            {
                                                heading:    'Description',
                                                field:      rowData => rowData.desc,
                                                dataRef:    'desc',
                                                style:      {width: '32%'},
                                            },
                                            {
                                                heading:    'Opening Stock',
                                                field:      rowData => parseFloat(rowData.before),
                                                dataRef:    'before',
                                                alignment:  'center',
                                                fieldFormat: 'decimal:3',
                                                style:      {width: '20%'},
                                            },
                                            {
                                                heading:    'Stock In',
                                                field:      rowData => parseFloat(rowData.in),
                                                dataRef:    'in',
                                                alignment:  'center',
                                                fieldFormat: 'decimal:3',
                                                style:      {width: '22%'},
                                            },
                                            {
                                                heading:    'Closing Stock',
                                                field:      rowData => (parseFloat(rowData.before) + parseFloat(rowData.in)).toFixed(2),
                                                dataRef:    rowData => parseFloat(rowData.before) + parseFloat(rowData.in),
                                                alignment:  'center',
                                                style:      {width: '20%'},
                                                fieldFormat: 'decimal:3',
                                            },
                                            
                                        ]}
                                        rows={_.orderBy(_.filter(grouped, g => g.in > 0),['number'],['asc'])}
                                    />
                                </PaddedPaper>
                            </Grid>
                        }
                        {_.filter(grouped, g => g.out > 0).length > 0 && 
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h4"><AllIcon size='large' icon={icons.minus} style={{color:colors.red}} /> Stock Out</Typography>
                                    <br/>
                                    <DataTable
                                        config={{
                                            key: 'partId',
                                            sticky:                 true,
                                            alternatingRowColours:  true,
                                            responsiveimportant:    true,
                                        }}
                                        columns={[
                                            {
                                                heading:    'Part',
                                                field:      rowData => rowData.number,
                                                dataRef:    'number',
                                                sizeToContent: true,
                                                style: {width: '6%'},
                                            },
                                            {
                                                heading:    'Description',
                                                field:      rowData => rowData.desc,
                                                dataRef:    'desc',
                                                style:      {width: '32%'},
                                            },
                                            {
                                                heading:    'Opening Stock',
                                                field:      rowData => parseFloat(rowData.before) + parseFloat(rowData.in),
                                                dataRef:    rowData => parseFloat(rowData.before) + parseFloat(rowData.in),
                                                alignment:  'center',
                                                style:      {width: '20%'},
                                                fieldFormat: 'decimal:3',
                                            },
                                            {
                                                heading:    'Stock Out',
                                                field:      rowData => parseFloat(rowData.out),
                                                dataRef:    'out',
                                                alignment:  'center',
                                                style:      {width: '22%'},
                                                fieldFormat: 'decimal:3',
                                            },
                                            {
                                                heading:    'Closing Stock',
                                                field:      rowData => (parseFloat(rowData.before) + parseFloat(rowData.in - rowData.out)).toFixed(2),
                                                dataRef:    rowData => parseFloat(rowData.before) + parseFloat(rowData.in - rowData.out),
                                                alignment:  'center',
                                                style:      {width: '20%'},
                                                fieldFormat: 'decimal:3',
                                            },
                                            
                                        ]}
                                        rows={_.orderBy(_.filter(grouped, g => g.out > 0),['number'],['asc'])}
                                    />
                                </PaddedPaper>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h4"><AllIcon size='large' icon="plus-minus" /> Stock Change</Typography>
                                <br/>
                                <DataTable
                                    config={{
                                        key: 'partId',
                                        sticky:                 true,
                                        alternatingRowColours:  true,
                                        responsiveimportant:    true,
                                        style: rowData => ({
                                            color: parseFloat(rowData.in) - parseFloat(rowData.out) < 0 ? colors.red : colors.green
                                        })
                                    }}
                                    columns={[
                                        {
                                            heading:        'Part',
                                            field:          rowData => rowData.number,
                                            dataRef:        'number',
                                            sizeToContent:  true,
                                            style:          {width: '6%'}
                                        },
                                        {
                                            heading:    'Description',
                                            field:      rowData => rowData.desc,
                                            dataRef:    'desc',
                                            style:      {width: '32%'},
                                        },
                                        {
                                            heading:    'Opening Stock',
                                            field:      rowData => parseFloat(rowData.before),
                                            dataRef:    rowData => parseFloat(rowData.before),
                                            alignment:  'center',
                                            style:      {width: '20%'},
                                            fieldFormat: 'decimal:3',
                                        },
                                        {
                                            heading:    'Stock Change',
                                            field:      rowData => `${parseFloat(rowData.in) - parseFloat(rowData.out) < 0 ? '-' : '+'}${Math.abs(parseFloat(rowData.in) - parseFloat(rowData.out))}`,
                                            dataRef:    rowData => parseFloat(rowData.in) - parseFloat(rowData.out),
                                            alignment:  'center',
                                            style:      {width: '22%'},
                                            fieldFormat: 'decimal:3',
                                        },
                                        {
                                            heading:    'Closing Stock',
                                            field:      rowData => (parseFloat(rowData.before) + parseFloat(parseFloat(rowData.in) - parseFloat(rowData.out))).toFixed(2),
                                            dataRef:    rowData => parseFloat(rowData.before) + parseFloat(parseFloat(rowData.in) - parseFloat(rowData.out)),
                                            alignment:  'center',
                                            style:      {width: '20%'},
                                            fieldFormat: 'decimal:3',
                                        },
                                        
                                    ]}
                                    rows={_.orderBy(grouped,['number'],['asc'])}
                                />
                            </PaddedPaper>
                        </Grid>
                        
                    </>
                }
                <div className='buttonRow' style={{width: '100%'}}>
                    { ( !!MasterExcelData ) &&
                        <DownloadCSV 
                            data    ={MasterExcelData} color='primary' variant='contained' 
                            filename={`stock_movement_${stockMovement.sm_ref}.csv`} 
                            disabled={isLoading}
                        >download CSV</DownloadCSV>
                    }<BackButton />
                </div>
            </Grid>
        );
    }
}

export default ViewStockMovement;
