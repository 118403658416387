import React from 'react';
import { Grid , Typography, Box} from '@material-ui/core';
import API from 'API';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import _ from 'lodash';
import FormViewElement from './FormViewElement';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { CLENAWARE_BLUE } from 'Constants'
import AllIcon from 'Components/Common/Icons/AllIcon';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import InformationForm from 'Components/Common/Forms/FormBuilder/InformationForm';

const initialState = () => ({
    isLoading: true,
    form: {},
    dataSets: [],
    dialog: {
        informationForm: false,
        informationDialogData: null
    },
    formData: {
        pages: []
    }
})

class FormView extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getForm();
    }

    #formatValue = (value, element) => {
        switch (element?.elType){
            case 'Checkbox':
            case 'File Upload':
            case 'Signature':
            case 'Static Table':
                return JSON.parse(value);
            default:
                return value ? value.replaceAll('\"', '') : null
        }
    }

    getForm = () => {
        this.setState({isLoading: true}, ()=>{
            let promise = [API.get(`/forms/configuration/${this.props.formId}`)];
    
            if (this.props.displayForm) {
                promise.push(
                        API.get(`/forms/configuration/${this.props.formId}/forms`, {params:{
                        formIds: [this.props.displayForm]
                    }})
                );
            }
    
            Promise.all(promise).then(res => {
                this.setState({
                    form: res[0].data,
                    isLoading: false,
                    formData: {
                        pages: _.map(res[0].data.pages, page => ({
                            groups: _.map(page.groups, group => ({
                                elements: _.map(group.elements, element => {
                                    let value = null;
                                    if (this.props.displayForm) {
                                        let v = _.find(res[1].data[0].results, i => parseInt(i.elementId) === parseInt(element.id))?.value;
                                        value = this.#formatValue(v, element);
                                    } 
                                    return {
                                        value,
                                        options: element?.elConfig?.options && element?.elConfig?.options?.map(option => {
                                            return {
                                                nested: option?.nestedElements && option?.nestedElements?.length > 0 && option?.nestedElements?.map(nested => {
                                                    let value = null;
                                                    if (this.props.displayForm) {
                                                        let v = _.find(res[1].data[0].results, i => parseInt(i.elementId) === parseInt(nested.formData.id))?.value;
                                                        value = this.#formatValue(v, nested.formData);
                                                    } 
                                                    return {
                                                        value
                                                    }
                                                })
                                            }
                                        }),
                                    }
                                })
                            }))
                        }))
                    },
                })
            })
        });
    }

    handleInformationOpen = (info) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                informationForm: true,
                informationDialogData: info ? info : null,
            },
        });
    }

    render() {

        const { isLoading, form, formData, dataSets, dialog } = this.state;

        if (isLoading) {
            return <LoadingCircle/>
        }

        return (
            <Grid container spacing={2}>
                {form?.pages?.map((page, pIdx) => {
                    return (
                        <Grid item container spacing={2}>
                            <Grid item xs={12} align="right">
                                <Typography variant="body2" >
                                    <b>Page {pIdx + 1}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} align="center">
                                            <Grid item xs={12}>
                                                <Grid container item spacing={3}>
                                                    <Grid item xs={12} align="left">
                                                        {page.groups?.map((group, gIdx) => {
                                                            return (
                                                                <React.Fragment key={gIdx}>
                                                                <Box style={{backgroundColor: CLENAWARE_BLUE, border: '1px solid rgb(241, 241, 241)', color: 'white'}} p={2} mt={1}>
                                                                    <Grid container spacing={3} style={{justifyContent: 'space-between'}}>
                                                                        <Grid item align="left">
                                                                            <Typography variant="subtitle1" gutterBottom>
                                                                                {group?.nm}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                                <Box p={2}>
                                                                    <Grid container spacing={3}>
                                                                        {group?.elements.map((e, eIdx) => {
                                                                            return (
                                                                                <React.Fragment key={eIdx}>
                                                                                    <Grid item xs={12} align="left">
                                                                                        <Typography variant="body1">
                                                                                            <b>{e?.nm}</b>
                                                                                            {e?.elConfig?.isRequired ? <span style={{color:'red'}}> *</span> : null}
                                                                                            {' '}
                                                                                            {e?.info && e?.info?.length > 0 && (
                                                                                                <AllIcon icon='circle-info' noMargin fixedHeight button size={14} onClick={() => this.handleInformationOpen(e?.info)} />
                                                                                            )}
                                                                                        </Typography>
                                                                                        {e?.description &&
                                                                                            <Typography variant="caption" paragraph>
                                                                                                {e?.description}
                                                                                            </Typography>
                                                                                        }
                                                                                        <FormViewElement 
                                                                                            element={e}
                                                                                            eIdx={eIdx}
                                                                                            dataSets={dataSets}
                                                                                            formData={this.state.formData.pages[pIdx].groups[gIdx].elements[eIdx]}
                                                                                            handleChange={(formData) => this.handleElementChange(pIdx, gIdx, eIdx, formData)}
                                                                                            handleSelectChange={(formData) => this.handleElementSelectChange(pIdx, gIdx, eIdx, formData)}
                                                                                            disabled={true}
                                                                                        />
                                                                                        {e?.elConfig?.resultType == 'pre-populated' && e?.elConfig?.resultTypeData && (
                                                                                            <Typography variant="caption"  style={{backgroundColor: 'aliceblue', padding: '4px'}}>
                                                                                                <b>Result pre-populated:</b> {e?.elConfig?.resultTypeData?.form?.label} - {e?.elConfig?.resultTypeData?.ref}
                                                                                            </Typography>
                                                                                        )}
                                                                                        {e?.elConfig?.resultType == 'reusable' && (
                                                                                            <Typography variant="caption"  style={{backgroundColor: 'aliceblue', padding: '4px'}}>
                                                                                                <b>Result reusable ref:</b> {e?.elConfig?.resultTypeData?.ref}
                                                                                            </Typography>
                                                                                        )}
                                                                                    </Grid>
                                                                                    
                                                                                    <Grid item xs={12}>
                                                                                        {_.map(e?.elConfig.options, (option, oIdx) => {
                                                                                            
                                                                                            // console.log(option, formData.pages[pIdx].groups[gIdx].elements[eIdx])

                                                                                            return (

                                                                                            <Box style={{
                                                                                                marginLeft: '8.5%', 
                                                                                                display: ( _.isArray( formData.pages[pIdx].groups[gIdx].elements[eIdx].value) ?  formData.pages[pIdx].groups[gIdx].elements[eIdx].value.includes(option.value) : formData.pages[pIdx].groups[gIdx].elements[eIdx].value === option.value ) ? 'block' : 'none'
                                                                                            }}>
                                                                                                {_.map(option?.nestedElements, (n, nIdx) => {
                                                                                                    // console.log(n)
                                                                                                    return (
                                                                                                        <>
                                                                                                        <Grid container spacing={1} key={eIdx} style={{marginBottom: 30}}>
                                                                                                            <Grid item xs={12}>
                                                                                                                <Typography variant="body1" gutterBottom>
                                                                                                                    <b>{n?.formData?.nm}</b>
                                                                                                                    {n?.formData?.elConfig?.isRequired ? <span style={{color:'red'}}> *</span> : null}
                                                                                                                    {' '}
                                                                                                                    {n?.formData?.info && n?.formData?.info?.length > 0 && (
                                                                                                                        <AllIcon icon='circle-info' noMargin fixedHeight button size={15} onClick={() => this.handleInformationOpen(n?.formData?.info)} />
                                                                                                                    )}
                                                                                                                </Typography>
                                                                                                                {n?.formData?.description &&
                                                                                                                    <Typography variant="caption" gutterBottom>
                                                                                                                        {n?.formData?.description}
                                                                                                                    </Typography>
                                                                                                                }
                                                                                                            </Grid>
                                                                                                            <Grid item xs={8}>
                                                                                                                <FormViewElement 
                                                                                                                    element={n?.formData}
                                                                                                                    eIdx={eIdx}
                                                                                                                    dataSets={dataSets}
                                                                                                                    disabled={true}
                                                                                                                    formData={this.state.formData.pages[pIdx].groups[gIdx].elements[eIdx].options[oIdx].nested[nIdx]}
                                                                                                                />
                                                                                                                {n?.formData?.elConfig?.resultType == 'pre-populated' && n?.formData?.elConfig?.resultTypeData && (
                                                                                                                    <Typography variant="caption"  style={{backgroundColor: 'aliceblue', padding: '4px'}}>
                                                                                                                        <b>Result pre-populated:</b> {n?.formData?.elConfig?.resultTypeData?.form?.label} - {n?.formData?.elConfig?.resultTypeData?.ref}
                                                                                                                    </Typography>
                                                                                                                )}
                                                                                                                {n?.formData?.elConfig?.resultType == 'reusable' && (
                                                                                                                    <Typography variant="caption"  style={{backgroundColor: 'aliceblue', padding: '4px'}}>
                                                                                                                        <b>Result reusable ref:</b> {n?.formData?.elConfig?.resultTypeData?.ref}
                                                                                                                    </Typography>
                                                                                                                )}
                                                                                                            </Grid>
                                                                                                            <Grid item xs={4} align="right" >
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                        </>
                                                                                                    )
                                                                                                })}
                                                                                            </Box>
                                                                                        )})}
                                                                                    </Grid>
                                                                                </React.Fragment>
                                                                            )
                                                                        })}
                                                                    </Grid>
                                                                    
                                                                </Box>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    )
                })}
                {/* Information Dialog */}
                <SmallFormDialog
                    open={dialog.informationForm}
                    onClose={() => this.toggleDialog('informationForm', true)}
                    title={`Information`}
                    content={
                    <InformationForm 
                        data={dialog?.informationDialogData}
                        toggleDialog={this.toggleDialog}
                        readOnly={true}
                    />}
                    maxWidth='sm'
                />
            </Grid>
        )
    }
}

export default FormView;