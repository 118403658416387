import React, { Component } from 'react';
import moment               from 'moment';
import API                  from 'API';
import { connect }          from 'react-redux';
import _                    from 'lodash'

import { formatValidationErrors } from 'Helpers/ErrorHelper';

import { Button, Grid, TextField, InputAdornment, FormControl } from '@material-ui/core';

import DatePicker                       from 'Components/Common/DatePickers/DatePicker';
import AutoCompleteSelect               from 'Components/Common/Selects/AutoCompleteSelect';

import { closeDialog }          from 'Actions/Dialog/Dialog';
import { deployConfirmation }   from 'Actions/Confirmation/Confirmation';
import { deployDialog }         from 'Actions/Dialog/Dialog';

const initialState = () => ({
    formData:   {
        paymentAmount:      0.00,
        amountOutstanding:  0.00,
        creditNote:         null,
        date:               null,
        paymentMethod:      'CreditNote',
        paymentType:        'make'
    },
    creditNotes:    [],
    creditNoteData: null,
    formErrors:     [],
    isLoading:      true,
})

class CreditNoteDialog extends Component {
    constructor(props) {
        super(props);    
        this.state = {
            ...initialState(), 
            formData:{
                ...initialState().formData,
                amountOutstanding:  parseFloat(this.props.amountOutstanding).toFixed(2),
            }
        };
    }

    componentDidMount() {this.getCreditNotes();}

    getCreditNotes = () => {
        const custId = this.props.cnId;
        API.get('/customerReturns/creditNote/invoice', {params: {custId, slim:1, status: 'Payment',}}).then(res => {
            let creditNotes = [];
            _.map(res.data, j => {
                creditNotes.push({
                    label: j.n,
                    value: j.cn.i,
                    data: j
                })
            })
            this.setState({creditNotes})
        });
    }
    
    handleDateChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                date: moment(e)
            }
        });
    }

    submit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => { newFormData.append(key, this.state.formData[key]); });
        API.post(`/sales/orders/invoice/${this.props.id}/processPayment`, newFormData)
        .then(res => { 
            if (res.data.errors)    this.setState({formErrors: formatValidationErrors(res.data.errors)});
            if (res.data.success)   {
                this.props.callback() 
                this.close();
            }
        });
    }

    close = () => {
        this.props.closeDialog();
    }

    handleSelectChange = e => {
        e.data.total = parseFloat(parseFloat(e.data.t.t) - parseFloat(e.data.t.c ?? 0)).toFixed(2);
        this.setState({
            formData: {
                ...this.state.formData,
                creditNote:     e.value ?? null,
                paymentAmount:   parseFloat( e.data.total > this.state.formData.amountOutstanding ? this.state.formData.amountOutstanding : e.data.total ).toFixed(2)
            },
            creditNoteData: e.data ?? null
        })
    }

    handleToDecimalPlace = (decimal) => e => {
        let value = e.target.value;
        let newVal = parseFloat(value).toFixed(decimal);
        if (this.state)
        if (parseFloat(value) > parseFloat(this.state.formData.amountOutstanding)) newVal = parseFloat(this.state.formData.amountOutstanding).toFixed(decimal);
        this.setState({
            formData: {
                ...this.state.formData,
                paymentAmount: newVal
            }
        });
    }

    handleFormChange = e => {
        const {name, value} = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        });
    }

    render() {
        const {formData, formErrors, creditNotes, creditNoteData} = this.state;
        const {paymentAmount, amountOutstanding, creditNote, date} = formData;
        return (
            <Grid container>
                <Grid item xs={12}>
                    <form>
                        <FormControl fullWidth>
                            <AutoCompleteSelect 
                                options={creditNotes}
                                name='creditNote'
                                value={creditNote}
                                label={'Select Credit Note'}
                                error={(formErrors && formErrors['creditNote'] && true) || false}
                                errorText={formErrors && formErrors['creditNote']}
                                onChange={this.handleSelectChange}
                                fullWidth
                            />
                        </FormControl>
                        
                        <TextField
                            label="Amount Outstanding"
                            value={amountOutstanding}
                            margin="normal"
                            fullWidth
                            disabled
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                className: 'disabledText'
                            }}
                        />
                        {creditNoteData && 
                            <>
                                <TextField
                                    name="paymentAmount"
                                    label="Amount"
                                    value={paymentAmount}
                                    onChange={this.handleFormChange}
                                    onBlur={this.handleToDecimalPlace(2)}
                                    margin="normal"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        inputProps:     { min: 0, max: amountOutstanding > creditNoteData.total ? amountOutstanding : creditNoteData.total } 
                                    }}
                                    type="number"
                                    fullWidth
                                    error={formErrors && formErrors['paymentAmount'] && true}
                                    helperText={formErrors && formErrors['paymentAmount']}
                                />
                                <TextField
                                    label="Credit Note Total"
                                    value={parseFloat(creditNoteData.total).toFixed(2)}
                                    margin="normal"
                                    fullWidth
                                    disabled
                                    type="number"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        className: 'disabledText'
                                    }}
                                />
                                <TextField
                                    label="Invoice Remaining"
                                    value={parseFloat( amountOutstanding -  paymentAmount ).toFixed(2)}
                                    margin="normal"
                                    fullWidth
                                    disabled
                                    type="number"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        className: 'disabledText'
                                    }}
                                />
                                <TextField
                                    label="Credit Note Remaining"
                                    value={parseFloat(creditNoteData.total - paymentAmount).toFixed(2)}
                                    margin="normal"
                                    fullWidth
                                    disabled
                                    type="number"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        className: 'disabledText'
                                    }}
                                />
                            </>
                        }
                        <DatePicker
                            type="date"
                            id="date"
                            name="date"
                            label="Payment Date *"
                            value={date}
                            errorText={formErrors && formErrors['date']}
                            onChange={this.handleDateChange}
                            autoOk={true}
                        />
                    </form>
                </Grid>
                <Grid item xs={12}>
                    <div className="buttonRow">
                        <Button 
                            variant="outlined" 
                            color="default" 
                            onClick={this.close}
                        >
                            Close
                        </Button>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={()=>{this.props.deployConfirmation('Are you sure you want to process refund?', 'Process Refund?', this.submit)}}
                            disabled={parseFloat(creditNoteData?.total - paymentAmount) < 0 || amountOutstanding -  paymentAmount < 0 || !creditNote || !date}
                        >
                            Submit
                        </Button>
                    </div>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation:     (body, title, callback)     => dispatch(deployConfirmation(body, title, callback)),
    closeDialog:            ()                          => dispatch(closeDialog()),
    deployDialog:           (content, title, variant)   => dispatch(deployDialog(content, title, variant)),
})


export default connect(null, mapDispatchToProps)(CreditNoteDialog);