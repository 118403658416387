import React from 'react';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const NotificationDialogContent = props => (
    <React.Fragment>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close} variant="outlined" color="default">Close</Button>
        </DialogActions>
    </React.Fragment>
)

export default NotificationDialogContent;