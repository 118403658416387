import React from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import QrReader from './QrReader';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import API from '../../../API';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import {colors} from 'Helpers/ColourHelper';

const styles = theme => ({
    
});

const initialState = { 
    optionsOpen: false,
    option: '',
    isFlipped: false,
    errorMsg: ''
}

class QrReaderMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleOpen = () => {
        this.setState({ 
            optionsOpen: true 
        });
    };

    handleClose = () => {
        this.setState(initialState);
    };

    handleScanError = () => {
        this.setState({ 
            isFlipped: false,
            errorMsg: `No ${this.state.option} data found`
        });
    }

    handleScan = scanData => {
        if(scanData && this.state.option) {
            switch(this.state.option){
                case 'part':
                    API.get(`/parts/byPartNumber/${scanData}`)
                    .then((result) => {
                        if(result.data && result.data.part_id > 0){
                            this.handleClose();
                            this.props.history.push(`/parts/view/${result.data.part_id}`);
                        }
                        else {
                            this.handleScanError();
                        }
                    })
                    .catch(err => {
                        this.handleScanError();
                    });
                break;
                case 'document':
                    API.get(`/documents/${scanData}`)
                    .then((result) => {
                        if(result.data){
                            this.handleClose();
                            this.props.history.push(`/documents/view/${scanData}`);
                        }
                        else {
                            this.handleScanError();
                        }
                    })
                    .catch(err => {
                        this.handleScanError();
                    });
                break;
                default:
                    //No action
            }
        }
    }

    handleSelectScanner = (option) => {
        this.setState(prevState => ({ 
            isFlipped: !prevState.isFlipped,
            option: option
        }));
    }

    render() {
        const { optionsOpen, isFlipped } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='QR Scanner'><IconButton onClick={this.handleOpen} style={{minWidth:'initial'}}><FALightIcon icon='qrcode' buttonPrimary noMargin /></IconButton></Tooltip>
                <Dialog 
                    open={optionsOpen} 
                    onClose={this.handleClose} 
                    aria-labelledby="simple-dialog-title" 
                    fullWidth={true}
                    maxWidth="sm"
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="simple-dialog-title">
                        <Grid container spacing={3} justify="center">
                            <Grid item xs={8} style={{paddingTop: 15}}>
                                Select QR Scanner
                            </Grid>
                            <Grid item xs={4} align="right">
                                <Button onClick={() => this.handleSelectScanner('')}><FALightIcon icon='chevron-left' noMargin />Back</Button>   
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={{padding: '0 24px 24px 24px', overflow: 'hidden'}}>
                        {this.state.errorMsg.length > 0 && (
                            <Typography variant="body1" gutterBottom style={{color:colors.red}}>
                                {this.state.errorMsg}
                            </Typography>
                        )}
                        {isFlipped ? 
                            <Grid container spacing={3}>                               
                                <Grid item xs={12} style={{display: 'flex',  justifyContent:'center'}}>
                                    <QrReader handleScan={this.handleScan} />
                                </Grid>
                            </Grid>
                        :
                            <List>
                                <ListItem button onClick={() => this.handleSelectScanner('part')}>
                                    <Avatar><FALightIcon icon='wrench' buttonPrimary noMargin /></Avatar>
                                    <ListItemText primary='Part' style={{marginLeft: 15, marginTop: 8}} />
                                </ListItem>
                                <ListItem button onClick={() => this.handleSelectScanner('document')}>
                                    <Avatar><FALightIcon icon='file-alt' buttonPrimary noMargin /></Avatar>
                                    <ListItemText primary='Document' style={{marginLeft: 15, marginTop: 8}} />
                                </ListItem>
                            </List>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="default" onClick={this.handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withRouter(QrReaderMenu));