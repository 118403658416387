import { Grid, Typography, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, TextField, List } from '@material-ui/core';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import React from 'react';
import { colors } from 'Helpers/ColourHelper'
import CheckBox from 'Components/Common/Inputs/CheckBox';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import API from 'API';
import _ from 'lodash';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Textarea from 'Components/Common/Inputs/Textarea';
import DragFileInputMulti from 'Components/Common/Inputs/DragFileInputMulti';
import SelectPartWithQR from 'Components/Common/Selects/SelectPartWithQR';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconHelper from 'Helpers/IconHelper';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { connect } from 'react-redux';

const initialCondition = () => ({
    qty: 1,
    condition: null,
    notes: null,
    files: [],
    outcome: null,
    _repair: {
        part: null,
        qty: 0
    },
    _service: {
        part: null,
        qty: 0
    },
    repairParts: []
});

const initialState = props => ({
    formData: {
        showGrouped: parseInt(props.delivery.crcd_recieved) > 1 && parseInt(props.delivery.crcd_recieved) === parseFloat(props.delivery.crcd_recieved),
        groupItems: true,
        items: [{
            ...initialCondition(),
            sn:  _.flatten(_.map(props.delivery.received, i => JSON.parse(i.crcdr_serial_numbers))),
            wvl: _.flatten(_.map(props.delivery.received, i => JSON.parse(i.crcdr_warranty_void_labels))),
            qty: parseFloat(props.delivery.crcd_recieved)
        }]
    },
    isLoading: true,
    lists: {
        conditions: []
    }
})


const InspectionItem = ({item, onChange, lists}) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <AutoCompleteSelect
                    label='Condition *'
                    value={item.condition}
                    onChange={e => onChange({...item, condition: e.value})}
                    options={lists.conditions}
                    fullWidth
                    noClear
                    disableSort
                />
            </Grid>
            <Grid item xs={12}>
                <Textarea
                    label='Notes *'
                    value={item.notes}
                    onChange={e => onChange({...item, notes: e.target.value})}
                    fullWidth
                    rows={3}
                />
            </Grid>
            <Grid item xs={12}>
                <DragFileInputMulti
                    id="files"
                    name="files[]"
                    file={item.files}
                    type="file"
                    onChange={(drop, name, event) => onChange({...item, files: Array.prototype.slice.call(item.files).concat(Array.prototype.slice.call(drop === true ? event.dataTransfer.files : event.target.files))})}
                    cancelOnClick={idx => onChange({...item, files: item.files.filter((file, index) => index !== idx)})}
                    emptyText='No files uploaded'
                    noMargin
                />
            </Grid>
            <Grid item xs={12}>
                <AutoCompleteSelect
                    label='Outcome *'
                    value={item.outcome}
                    onChange={e => onChange({...item, outcome: e.value})}
                    options={[
                        {value: 'Refund',  label: 'Credit Note'},
                        {value: 'Replace', label: 'Replace'},
                        {value: 'Repair',  label: 'Repair'},
                    ]}
                    fullWidth
                />
            </Grid>
            {item.outcome === 'Repair' &&
                <>
                    <Grid item xs={12}
                        style={{
                            border: `1px solid ${colors.disabled}`,
                            borderRadius: '5px',
                            marginTop: '1em'
                        }}
                    >
                        <Grid container spacing={2} style={{alignItems: 'center', padding: '1em'}}>
                            <Grid item xs={12}>
                                <Typography variant='body1' style={{fontWeight: 'Bold'}}>Repair Parts If Required</Typography>
                                <Typography variant='caption'>Include any predicted parts that will be needed to repair</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <SelectPartWithQR
                                    parts=      {_.filter(lists.parts, p => p.part.stockable)}
                                    onChange=   {e => onChange({...item, _repair: {...item._repair, part: e?.value}})}
                                    value=      {item._repair.part}
                                />
                            </Grid>
                            <Grid item xs={2} style={{paddingBottom: 16}}>
                                <TextField
                                    label='Qty *'
                                    value={item._repair.qty}
                                    onChange={e => onChange({...item, _repair: {...item._repair, qty: parseFloat(e.target.value)}})}
                                    type='number'
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    fullWidth
                                    disabled={!item._repair.part || !parseFloat(item._repair.qty) || parseFloat(item._repair.qty) <= 0 }
                                    onClick={() => onChange({...item, repairParts: [...item.repairParts, item._repair], _repair: {...item._repair, part: null, qty: 0}})}
                                >Add</Button>
                            </Grid>
                            {_.filter(item.repairParts, i => _.find(lists.parts, p => p.value === i.part && p.part.stockable)).length > 0 &&
                                <Grid item xs={12}>
                                    <Grid container style={{alignItems: 'center'}}>
                                        <Grid item xs={9}>
                                            <Typography variant='body1'>Repair Parts</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant='body1'>Qty</Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        {_.map(item.repairParts, (p,pIdx) => {
                                            let part = _.find(lists.parts, {value: p.part});
                                            if (part.part.stockable){
                                                return (
                                                    <>
                                                        <Grid item xs={9}>
                                                            <Typography variant='body2'>{_.find(lists.parts, {value: p.part}).label}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant='body2'>{parseFloat(p.qty).toFixed(3)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <AllIcon
                                                                icon={IconHelper.delete}
                                                                onClick={() => onChange({...item, repairParts: item.repairParts.filter((rp, rpIdx) => rpIdx !== pIdx)})}
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                        })}
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12}
                        style={{
                            border: `1px solid ${colors.disabled}`,
                            borderRadius: '5px',
                            marginTop: '1em'
                        }}
                    >
                        <Grid container spacing={2} style={{alignItems: 'center', padding: '1em'}}>
                            <Grid item xs={12}>
                                <Typography variant='body1' style={{fontWeight: 'Bold'}}>Repair Services If Required</Typography>
                                <Typography variant='caption'>Include any predicted labour time and delivery</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <SelectPartWithQR
                                    parts=      {_.filter(lists.parts, p => !p.part.stockable)}
                                    onChange=   {e => onChange({...item, _service: {...item._service, part: e?.value}})}
                                    value=      {item._service.part}
                                    label='Repair Services *'
                                />
                            </Grid>
                            <Grid item xs={2} style={{paddingBottom: 16}}>
                                <TextField
                                    label='Qty *'
                                    value={item._service.qty}
                                    onChange={e => onChange({...item, _service: {...item._service, qty: parseFloat(e.target.value)}})}
                                    type='number'
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    fullWidth
                                    disabled={!item._service.part || !item._service.qty || item._service.qty <= 0}
                                    onClick={() => onChange({...item, repairParts: [...item.repairParts, item._service], _service: {...item._service, part: null, qty: 0}})}
                                >Add</Button>
                            </Grid>
                            {_.filter(item.repairParts, i => _.find(lists.parts, p => p.value === i.part && !p.part.stockable)).length > 0 &&
                                <Grid item xs={12}>
                                    <Grid container style={{alignItems: 'center'}}>
                                        <Grid item xs={9}>
                                            <Typography variant='body1'>Repair Services</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant='body1'>Qty</Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        {_.map(item.repairParts, (p,pIdx) => {
                                            let part = _.find(lists.parts, {value: p.part});
                                            if (!part.part.stockable){
                                                return (
                                                    <>
                                                        <Grid item xs={9}>
                                                            <Typography variant='body2'>{_.find(lists.parts, {value: p.part}).label}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant='body2'>{parseFloat(p.qty).toFixed(3)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <AllIcon
                                                                icon={IconHelper.delete}
                                                                onClick={() => onChange({...item, repairParts: item.repairParts.filter((rp, rpIdx) => rpIdx !== pIdx)})}
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                        })}
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </>
            }
        </Grid>
    )
}

class InspectDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount = () => {
        this.getListData();
    }

    getListData = () => {
        Promise.all([
            API.get('/customerReturns/defaults/returnCondition'),
            API.get('/parts/all', { params: { use: 'partSearch', withStock: true } })
        ]).then(([res1, res2]) => {
            this.setState({
                lists: {
                    conditions: _.map(_.orderBy(res1.data, r => _.findIndex(['pass', 'warning', 'fail'], i => i === r.crrc_grade), 'asc'), r => ({
                        value: r.crrc_id,
                        label: `${r.crrc_grade === 'pass' ? 'Pass' : r.crrc_grade === 'warning' ? 'Pass With Warning' : 'Fail'} - ${r.crrc_condition}`
                    })),
                    parts: _.map(res2.data, r => ({
                        value: r.part_id,
                        label: `${r.part_number} - ${r.part_description}`,
                        part: r
                    }))
                },
                isLoading: false
            })
        })
    }

    groupConditions = () => {
        let items = [];
        let sn = [];
        let wvl = [];

        _.each(this.props.delivery.received, i => {
            sn = [...sn, ...JSON.parse(i.crcdr_serial_numbers)];
            wvl = [...wvl, ...JSON.parse(i.crcdr_warranty_void_labels)];
        })

        if (!this.state.formData.groupItems){
            items = [
                {
                    ...{...initialCondition(), id: this.props.delivery.crcd_id},
                    qty: parseInt(this.props.delivery.crcd_recieved),
                    sn: sn.length > 0 ? sn : null,
                    wvl: wvl.length > 0 ? wvl : null
                }
            ]
        } else {
            for (let i = 0; i < parseInt(this.props.delivery.crcd_recieved); i++){
                items.push({...initialCondition(), id: this.props.delivery.crcd_id, sn: sn[i] ? [sn[i]] : null, wvl: wvl[i] ? [wvl[i]] : null});
            }
        }

        this.setState({
            formData: {
                ...this.state.formData,
                groupItems: !this.state.formData.groupItems,
                items: items
            }
        })
    }

    handleItemChange = index => item => {
        let items = this.state.formData.items;
        items[index] = item;
        this.setState({
            formData: {
                ...this.state.formData,
                items: items
            }
        })
    }

    submit = () => {
        this.setState({isLoading: true}, () => {
            const { items } = this.state.formData;
    
            let newformData = new FormData();
            newformData.append('items', JSON.stringify(_.map(items, i => ({...i, files: undefined,   id: this.props.delivery.crcd_id}))));
            _.each(items, (i, idx) => {
                if (i.files.length > 0){
                    _.each(i.files, (f, fIdx) => {
                        newformData.append(`files${idx}[]`, f);
                    })
                }
            })
            API.post(`/customerReturns/returns/${this.props.cr.cr_id}/inspectDelivery`, newformData)
            .then(this.props.onClose)
        });
    }

    render () {

        const { isLoading, formData, lists } = this.state;
        const { delivery } = this.props;

        if (isLoading) return <LoadingCircle/>;
    
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container style={{alignItems: 'center'}}>
                            <Grid item>
                                <ImageWithError
                                    src={delivery?.part?.default_image?.file_path}
                                    width={35}
                                />
                            </Grid>
                            <Grid item style={{marginLeft: '1em'}}>
                                <Typography variant='body1'>{delivery.part.part_number}</Typography>
                                <Typography variant='caption'>{delivery.part.part_description}</Typography>
                            </Grid>
                            <Grid item style={{marginLeft: 'auto', borderLeft: `1px solid ${colors.disabled}`, paddingLeft: '1em'}}>
                                <Typography variant='caption'>Delivered</Typography><br/>
                                <Typography variant='body1'>{delivery.crcd_recieved}</Typography>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={1}>
                            {formData.showGrouped && 
                                <Grid item xs={12}>
                                    <CheckBox
                                        label='Set all items as same condition'
                                        value={formData.groupItems}
                                        checked={formData.groupItems}
                                        onClick={this.groupConditions}
                                        color='primary'
                                    />
                                </Grid>
                            }
                        </Grid>
                        {formData.groupItems ?
                            <>
                                <Grid item xs={12}>
                                    <InspectionItem item={formData.items[0]} onChange={this.handleItemChange(0)} lists={lists} />
                                </Grid>
                            </> :
                            <Grid item xs={12} style={{marginTop: '1em'}}>  
                                {_.map(formData.items, (item, index) => 
                                    <ExpansionPanel>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>
                                                { 
                                                    item?.sn?.length > 0 ? 
                                                        item?.sn[0] :
                                                        (
                                                            item?.wvl?.length > 0 ?
                                                                item?.wvl[0] :
                                                                <>Part {index+1}</>
                                                        )
                                                }
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <InspectionItem item={item} onChange={this.handleItemChange(index)} lists={lists} />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )}
                            </Grid>
                        }
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button variant='outlined' onClick={this.props.onClose} >Close</Button>
                    <Button onClick={
                        ()=>this.props.deployConfirmation(
                            'Are you sure you want to save this inspection?',
                            'Save Inspection',
                            this.submit
                        )
                    } variant='contained' color='primary' disabled={_.find(formData.items, i=> !i.notes || !i.condition || i.files.length < 1 || !i.outcome )} >Save</Button>
                </Grid>
            </Grid>
        )

    }

}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
});

export default connect(null, mapDispatchToProps)(InspectDialog);
