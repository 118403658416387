import React, { useState, useEffect } from 'react';
import API from 'API';
import { 
    Grid, TextField, Typography, 
    DialogActions, Button, Table, TableBody, TableCell, Checkbox, TableHead, TableRow
} from '@material-ui/core';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { CLENAWARE_BLUE } from 'Constants';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    tableCell: {
        backgroundColor: CLENAWARE_BLUE,
        color: '#ffffff'
    }
})

const LinkedFormsDialog = ({ orderId, toggleDialog, callBack, classes }) => {

    const [linkedForms, setLinkedForms] = useState([]);
    const [availableForms, setAvailableForms] = useState([]);
    const [formData, setFormData] = useState({ linkedForms: [], availableForms:[] });
    const [formErrors, setFormErrors] = useState([]);

    useEffect(() => {
        
        API.get(`/sales/orders/${orderId}/forms/availableLinkedForms`)
        .then(res => {
            if(res?.data){
                setLinkedForms(res.data?.linkedForms);
                setAvailableForms(res.data?.availableForms);
                setFormData({ 
                    availableForms: res.data?.availableForms.map(form => ({ formId: form.id, quantity: 0 })),
                    linkedForms: res.data?.linkedForms.map(form => ({ formId: form.id, delete: 0 }))
                });
            }
        })
	}, []);

    const submit = () => {
        API.post(`/sales/orders/${orderId}/forms`, formData)
        .then(result => {
            if(result.data.errors && result.data.errors.length > 0){
                setFormErrors(formatValidationErrors(result.data.errors))
            }
            else {
                toggleDialog();
                callBack();
            }
        })
    }

    return (
        <>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="body1">
                    <b>Linked Forms</b>
                </Typography>
                <Typography variant="caption">
                    <i>Forms currently linked to this order</i>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {linkedForms?.length > 0 ?
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCell}>
                                    Form Name
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Status
                                </TableCell>
                                <TableCell  align='center' style={{width: '12%', whiteSpace: 'nowrap'}} className={classes.tableCell}>
                                    Delete
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {linkedForms?.map((form, idx) => (
                            <TableRow key={idx}>
                                <TableCell>
                                    {form?.nm}
                                </TableCell>
                                <TableCell>
                                    {form?.s}
                                </TableCell>
                                <TableCell align='center'>
                                    
                                    <Checkbox
                                        name={`formDelete${idx}`}
                                        color="secondary"
                                        checked={formData?.linkedForms[idx]?.delete}
                                        onChange={e => {
                                            e.persist();
                                            setFormData(prevState => ({
                                                ...prevState,
                                                linkedForms: prevState.linkedForms.map((form, i) => {
                                                    if(i === idx){
                                                        return {
                                                            ...form,
                                                            delete: e?.target?.checked ? 1 : 0
                                                        }
                                                    }
                                                    return form;
                                                })
                                            }))
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>
                :
                    <Typography variant="body2">
                        <i>No linked forms</i>
                    </Typography>
                }
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">
                    <b>Available Forms</b>
                </Typography>
                <Typography variant="caption">
                    <i>Please enter the quantity of each form you'd link linked to this order.</i>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {availableForms?.length > 0 ?
                    <Table size='small'>
                        <TableHead>
                            <TableRow style={{backgroundColor: CLENAWARE_BLUE, color: '#ffffff'}}>
                                <TableCell  className={classes.tableCell}>
                                    Form Name
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Category
                                </TableCell>
                                <TableCell align='center' style={{width: '1%', whiteSpace: 'nowrap'}} className={classes.tableCell}>
                                    Version
                                </TableCell>
                                <TableCell align='center' style={{width: '12%', whiteSpace: 'nowrap'}} className={classes.tableCell}>
                                    Quantity
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {availableForms?.map((form, idx) => (
                            <TableRow key={idx}>
                                <TableCell>
                                    {form?.name}
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        {form?.subCat?.nm}
                                    </Typography>
                                    <Typography variant="caption" gutterBottom color='textSecondary'>
                                        {form?.cat?.nm}
                                    </Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    v{form?.version}
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        name={`formQty${idx}`}
                                        value={formData?.availableForms[idx]?.quantity}
                                        onChange={(e) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                availableForms: prevState.availableForms.map((form, i) => {
                                                    if(i === idx){
                                                        return {
                                                            ...form,
                                                            quantity: e?.target?.value
                                                        }
                                                    }
                                                    return form;
                                                })
                                            }))
                                        }}
                                        variant='outlined'
                                        type="number"
                                        fullWidth
                                        InputProps={{
                                            inputProps: {
                                                min: 0
                                            }
                                        }}
                                        style={{backgroundColor: '#fff'}}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>
                :
                    <Typography variant="body2">
                        <i>No available forms</i>
                    </Typography>
                }

                {formErrors['formQuantities'] &&
                    <Typography variant="body2" color='error'>
                        {formErrors['formQuantities']}
                    </Typography>
                }
            </Grid>

        </Grid>
        <DialogActions className='pr-0 pb-0 pt-3'>
            <Button 
                color="primary"
                onClick={() => submit()}
                variant="contained"
                style={{backgroundColor:'#5da14d', color:'white'}}
            >
                <FALightIcon icon="check" size={15} color="white" />
                Submit
            </Button>
        </DialogActions>
        </> 
    );

};

export default withStyles(styles)(LinkedFormsDialog);