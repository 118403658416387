import API from "API";

const zone2 = ['AB31', 'AB33', 'AB34', 'AB35', 'AB36', 'AB37', 'AB38', 'AB45', 'AB52', 'AB53', 'AB54', 'AB55', 'AB56', 'FK17', 'FK18', 'FK19', 'FK20', 'FK21', 'HS1', 'HS2', 'HS3', 
    'HS4', 'HS5', 'HS6', 'HS7', 'HS8', 'HS9', 'IV1', 'IV2', 'IV3', 'IV10', 'IV11', 'IV12', 'IV13', 'IV14', 'IV15', 'IV16', 'IV17', 'IV18', 'IV19', 'IV20', 'IV21', 'IV22', 'IV23', 
    'IV24', 'IV25', 'IV26', 'IV27', 'IV28', 'IV30', 'IV31', 'IV32', 'IV36', 'IV40', 'IV41', 'IV42', 'IV43', 'IV44', 'IV45', 'IV46', 'IV47', 'IV48', 'IV49', 'IV51', 'IV52', 'IV53', 
    'IV54', 'IV55', 'IV56', 'IV99', 'KA27', 'KA28', 'KW1', 'KW2', 'KW3', 'KW5', 'KW6', 'KW7', 'KW8', 'KW9', 'KW10', 'KW11', 'KW12', 'KW13', 'KW14', 'KW15', 'KW16', 'KW17', 'PA20', 
    'PA21', 'PA22', 'PA23', 'PA24', 'PA25', 'PA26', 'PA27', 'PA28', 'PA29', 'PA30', 'PA31', 'PA32', 'PA33', 'PA34', 'PA35', 'PA36', 'PA37', 'PA38', 'PA41', 'PA42', 'PA43', 'PA44', 
    'PA45', 'PA46', 'PA47', 'PA48', 'PA49', 'PA60', 'PA61', 'PA62', 'PA63', 'PA64', 'PA65', 'PA66', 'PA67', 'PA68', 'PA69', 'PA70', 'PA71', 'PA72', 'PA73', 'PA74', 'PA75', 'PA76', 
    'PA77', 'PA78', 'PA80', 'PH17', 'PH18', 'PH19', 'PH20', 'PH21', 'PH22', 'PH23', 'PH24', 'PH25', 'PH26', 'PH30', 'PH31', 'PH32', 'PH33', 'PH34', 'PH35', 'PH36', 'PH37', 'PH38', 
    'PH39', 'PH40', 'PH41', 'PH42', 'PH43', 'PH44', 'PH49', 'PH50', 'ZE1', 'ZE2', 'ZE3'
];

const zone3 = ['BT1', 'BT2', 'BT3', 'BT4', 'BT5', 'BT6', 'BT7', 'BT8', 'BT9', 'BT10', 'BT11', 'BT12', 'BT13', 'BT14', 'BT15', 'BT16', 'BT17', 'BT18', 'BT19', 'BT20', 'BT21', 'BT22', 
    'BT23', 'BT24', 'BT25', 'BT26', 'BT27', 'BT28', 'BT29', 'BT30', 'BT31', 'BT32', 'BT33', 'BT34', 'BT35', 'BT36', 'BT37', 'BT38', 'BT39', 'BT40', 'BT41', 'BT42', 'BT43', 
    'BT44', 'BT45', 'BT46', 'BT47', 'BT48', 'BT49', 'BT51', 'BT52', 'BT53', 'BT54', 'BT55', 'BT56', 'BT57', 'BT58', 'BT59', 'BT60', 'BT61', 'BT62', 'BT63', 'BT64', 'BT65', 
    'BT66', 'BT67', 'BT68', 'BT69', 'BT70', 'BT71', 'BT74', 'BT75', 'BT76', 'BT77', 'BT78', 'BT79', 'BT80', 'BT81', 'BT82', 'BT92', 'BT93', 'BT94' ,'IM1' ,'IM2' ,'IM3' ,'IM4' ,
    'IM5' ,'IM6' ,'IM7' ,'IM8' ,'IM9', 'IM99', 'TR21', 'TR22', 'TR23', 'TR24', 'TR25'
];

export const  parcelForceZone = (postcode) => {
    let area = postcode.split(' ')[0];
    if (zone2.includes(area)) return 2;
    if (zone3.includes(area)) return 3;
    return 1;
}

export const getZonesFromPostcode = areaCode => {
    return API.get(`/couriers/zones`, {params: {areaCode}}).then(res => { return res.data; })
}