import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';

import ViewOrder     from 'Components/Sales/Order/ViewOrder/Order';
import DeliveryNote  from 'Components/Sales/Order/ViewDispatch/DeliveryNote';
import AllIcon       from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    tblHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    tblHeaderCell: {
        color:'white',
    },
    tblFooter: {
        backgroundColor: '#f2f2f2',
    }
});
const initialState = {
    colspan:    '',
    order:      null,
    desp:       [],
    isLoading:  true
};
class DeliveryNotePreview extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount = () => {
        this.getOrder()
    }
    getOrder = () => {
        API.get('/sales/orders/' + this.props.order)
            .then(result => {
                this.setState({
                    order: result.data
                }, this.getDespatch); 
            });
    }
    getDespatch = () => {
        this.setState({desp:[]});
        _.each(this.state.order.despatch, d => {
            API.get(`/sales/orders/despatch/${d.desp_id}`)
            .then(res => { 
                let desp = this.state.desp;
                desp.push(res.data)
                this.setState({desp});
            })
        });
    }
    trimPartDescription = partDescription => {
        if(partDescription) {
            if(partDescription.length > 40){
                partDescription = partDescription.substring(0, 40) + '...';
            }
        } else {
            partDescription = '';
        }
        return partDescription;
    }
    render() {
        return (
            <>
                {!this.state.desp.length ? <LoadingCircle /> :
                <>
                    {this.state.order &&
                        <ExpansionPanel style={{marginTop:'1.5em'}}>
                            <ExpansionPanelSummary
                                expandIcon={<AllIcon icon="chevron-down" noMargin button style={{color: 'black'}}/>}
                                style={{backgroundColor: '#F1F1F1'}}
                            >
                                <Typography noWrap variant="body2" style={{color: 'black'}}>
                                    <b>Order</b>
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <box>
                                    <ViewOrder co={this.state.order} />
                                </box>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    }
                    {!!this.state.desp.length && 
                        <>
                            {_.map(_.orderBy(this.state.desp, ['desp_reference_unique_number'],['asc']), i => 
                                <ExpansionPanel style={{marginTop:'1.5em'}}>
                                    <ExpansionPanelSummary
                                        expandIcon={<AllIcon icon="chevron-down" noMargin button style={{color: 'black'}}/>}
                                        style={{backgroundColor: '#F1F1F1'}}
                                    >
                                        <Typography noWrap variant="body2" style={{color: 'black'}}>
                                            <b>
                                                {parseInt(this.props.despatch) === parseInt(i.desp_id) ? 
                                                    'Current Despatch' : 
                                                    `Despatch ${ i.desp_reference_unique_number.replace('0','')}`
                                                }
                                            </b>
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <box>
                                            <DeliveryNote cd={i} />
                                        </box>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )}
                        </>
                    }
                </>}
            </>
        );
    }
}

export default (withStyles(styles)(DeliveryNotePreview));