
import React       from 'react';
import _           from 'lodash';
import API         from 'API';
import { connect } from 'react-redux';

import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';
import DraggableList from 'Components/Common/Lists/DraggableList';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@material-ui/core';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import icons from 'Helpers/IconHelper';

const initialState = {
    allAccess:   [],
    isLoading:   true,
    newNav:      null,
    newDividers: [],
    editNameDialog: {
        name: '',
        id:   '',
        open: false
    }
}

class ReOrderNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.loadAllAccess()
    }

    onChange = items => {
        this.setState({
            newNav: _.map(items, (i, idx) => ({
                id:     i.id,
                index:  idx,
                children: _.map(i.children, (c, _idx) => ({
                    id:     c.id,
                    index:  _idx,
                }))
            })),
        });
    }

    loadAllAccess = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/toolbox/navReorder/nav').then(result => {
                this.setState({
                    allAccess: _.map(_.filter(result.data, {access_display_on_nav: 1}), a => _.assign({
                        id:       a.access_id,
                        key:      `${a.access_id}`,
                        primary:  a.access_name,
                        icon:     a.access_icon,
                        children: _.map(_.filter(a.children, {access_display_on_nav: 1}), i => _.assign({
                            key:      `${a.access_id}-${i.access_id}`,
                            id:         i.access_id,
                            primary:    i.access_name,
                            checked:    i.access_display_divider,
                            actions:    [
                                {
                                    icon: icons.edit,
                                    name: 'Edit Name',
                                    onClick: () => {this.setState({
                                        editNameDialog: {
                                            name: i.access_name,
                                            id:   i.access_id,
                                            open: true
                                        }
                                    })}
                                }
                            ]
                        }))
                    })),
                    isLoading: false
                })
            })
        })
    }

    addDividerLine = id => { 
        this.setState({
            newDividers: [...this.state.newDividers, id],
        })
    }

    submitChanges = () => {
        let promise = [];
        let { newNav, newDividers } = this.state;

        if (newDividers && newDividers.length > 0) _.each(newDividers, n => { promise.push( API.post(`/toolbox/navReorder/addDivider`, {id: n}) );});
        
        if (newNav) promise.push( API.post(`/toolbox/navReorder/reorder`, {reorder: newNav}) );

        Promise.all(promise).then(res => { window.location.reload(); })

    }

    editNavName = e => {
        this.setState({
            editNameDialog: {
                ...this.state.editNameDialog,
                name: e.target.value
            }
        })
    }

    closeNameDialog = () => {
        this.setState({editNameDialog: initialState.editNameDialog})
    }

    submitNavNameChange = () => {
        API.post(`/toolbox/navReorder/nameChange`, this.state.editNameDialog)
        .then(res => {
            this.closeNameDialog();
            window.location.reload();
        })
    }

    render(){

        const { allAccess, isLoading, newDividers, editNameDialog } = this.state;

        if (isLoading) return ( <LoadingCircle /> );

        return (
            <PaddedPaper>
                <Typography variant="h5" style={{marginBottom: 15}}>Re-Order Navigation</Typography>
                <DraggableList  
                    items={allAccess}
                    onChange={this.onChange}
                    onCheck={this.addDividerLine}
                    disableDrag
                />
                <div className='buttonRow'>
                    <Button 
                        onClick={()=>{this.props.deployConfirmation('Are you sure you want to alter the navigation?', 'Alter Navigation', this.submitChanges)}} 
                        variant="contained"
                        color="primary"
                        disabled={!(this.state.newNav || this.state.newDividers.length > 0)}
                    >Apply Changes</Button>
                </div>
                <Dialog 
                    open={editNameDialog.open}
                    onClose={this.closeNameDialog} 
                    fullWidth={true} 
                    maxWidth="sm" 
                    scroll="body"
                >
                    <DialogTitle>
                        <Typography>Edit Navigation Name</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            value={editNameDialog.name}
                            onChange={this.editNavName}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeNameDialog} variant="outlined" color="default">Close</Button>
                        <Button onClick={()=>this.props.deployConfirmation("Are you sure you want to change this navigation's name?", 'Edit Navigation Name', this.submitNavNameChange)} autoFocus variant="contained" color="primary">Submit</Button>
                    </DialogActions>
                </Dialog>
            </PaddedPaper>
)
    }

}

function mapDispatchToProps(dispatch) {return {
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),                                 
}}

export default connect(null, mapDispatchToProps)(ReOrderNavigation);
