import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import API from '../../../API';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import {statusColour} from './../../../Helpers/ColourHelper'
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import SnackBar from '../../Common/SnackBars/SnackBar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import AutoCompleteMultiSelect from '../../Common/Selects/AutoCompleteMultiSelect';
import StatusDataTable from '../StatusDataTable';
import Textarea from '../../Common/Inputs/Textarea';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import {colors} from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    formData: {
        ppo_id: '',
        declinedNotes:'',
        approveNotes: '',
        emailChoice: 'emailSingleSupplier',
        emailText: '',
        emailTemplate: '',
        supplierName: '',
        supplierContact: '',
        supplierEmail: '',
        additionalContacts :[],
        emailAttachments: []
    },
    additionalContactList: [],
    drawingList: [],
    showHideAdditionalContacts: false,
    keywords: '',
    templateList: [],
    ppo: {},
    searchResults: {},
    formErrors: [],
    declineDialogOpen: false,
    declineSnackbarOpen: false,
    approveDialogOpen: false,
    approveSnackbarOpen: false,
    errorSnackbarOpen: false,
    snackbarErrorMsg: '',
    isLoading: true
});

class PartsOrderApproval extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'partsOrdering:PartsOrderApproval';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.loadComponentData();
    }

    loadComponentData = () => {
        this.getSearchData();
        this.getEmailTemplates();
    } 

    getEmailTemplates = () => {
        API.get('/partsOrdering/emailTemplates')
        .then((templateRes) =>  {
            let templateList = [];
            if(templateRes.data) {
                templateList = _.map(templateRes.data, (el) => {
                    return _.assign({
                        value: el.ppo_email_id,
                        label: el.ppo_email_name
                    });
                });
                templateList.unshift({
                    value: 0,
                    label: 'None'
                });
            }
            this.setState({
                ...this.state,
                templateList: templateList,
                templates: templateRes.data
            });
        });
    }

    getSearchData = () => {
        const params = {
            keywords: this.state.keywords,
            status: 'Approval'
        }
        const props = {
            cancellation: true
        }
        this.setState({
            isLoading: true
        }, () => {
            API.get('/partsOrdering', {
                params,
                props
            })
            .then((result) => {
                if(result?.data) {
                    _.map(result.data, (ppo) => {
                        ppo.colour = statusColour(ppo.ppo_status);
                    });
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    })
                }
            })
        })
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
        }, 
        () => {
            this.getSearchData();
        });
    }

    handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        },
        () => {
            this.setState({ 
                showHideAdditionalContacts : (this.state.formData.emailChoice === 'emailMultipleSuppliers' ? true : false)
            });
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if(fieldName === 'emailTemplate'){
                let email = _.find(this.state.templates, {'ppo_email_id': selectedOption && selectedOption.value });

                this.setState({
                    formData:{
                        ...this.state.formData,
                        emailText: (typeof email === 'undefined') ? '' : email.ppo_email_text
                    }
                });
            }
        });
    }

    handleErrorSnackbarClose = () => {this.setState({ errorSnackbarOpen: false }); };

    // Approve
    handleApproveOpen = rowData => {
        API.get('/partsOrdering/' + rowData.ppo_id)
        .then(result => {
            let additional = [];
            if(result.data.supplier_contacts){
                result.data.supplier_contacts.forEach(val => {
                    if(val.supp_cont_id !== result.data.ppo_supplier_contact_id){
                        additional.push(val);
                    }
                });
            }
            let emailAttachments = [];
            let drawingList = _.map(result.data.drawings, (el) => {
                if(el.ppo_detail_attach_drawing === 1){
                    emailAttachments.push(el.ppo_detail_id);
                }
                return _.assign({
                    value: el.ppo_detail_id,
                    label: `${el.ppo_detail_part_number}_${el.ppo_detail_drawing_no}.pdf`
                });
            });
            this.setState({
                approveDialogOpen: true,
                ppo: result.data,
                additionalContactList: _.filter(additional, i => i.supp_cont_status == 'Active'),
                drawingList: drawingList,
                formErrors: [],
                formData: {
                    ...this.state.formData,
                    ppo_id: rowData.ppo_id,
                    emailText: result.data.ppo_email_text,
                    emailTemplate: result.data.ppo_email_template,
                    supplierName: result.data.ppo_supplier_name,
                    supplierContact: result.data.ppo_supplier_contact,
                    supplierEmail: result.data.supplier_contact.supp_cont_email,
                    emailAttachments: emailAttachments,
                }
            });
        });
        
    };
    handleApproveClose = () => {this.setState({approveDialogOpen: false});};
    handleApproveSnackbarClose = () => {this.setState({ approveSnackbarOpen: false }); };
    handleApproveSuccess = () => {
        this.setState({ approveDialogOpen: false });
        this.approveSubmit();
    }
    approveSubmit = () => {
        API.post(`/partsOrdering/${this.state.formData.ppo_id}/approve`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    approveDialogOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    approveSnackbarOpen: true
                },
                () => {
                    if(result.data.emailErrors && result.data.emailErrors.length > 0){
                        this.setState({
                            errorSnackbarOpen: true,
                            snackbarErrorMsg: 'Email failed to send to the following additional contacts:' + result.data.emailErrors.join()
                        });
                    }
                });
                
                this.getSearchData();
            }
        });
    }

    // Decline
    handleDeclineOpen = rowData => {
        this.setState({
            declineDialogOpen: true,
            formData: {
                ...this.state.formData,
                ppo_id: rowData.ppo_id
            }
        });
    };
    handleDeclineClose = () => {this.setState({declineDialogOpen: false});};
    handleDeclineSnackbarClose = () => {this.setState({ declineSnackbarOpen: false }); };
    handleDeclineSuccess = () => {
        this.setState({ declineDialogOpen: false });
        this.declineSubmit();
    }
    declineSubmit = () => {
        API.post(`/partsOrdering/${this.state.formData.ppo_id}/decline`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    declineDialogOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    declineSnackbarOpen: true
                });
                this.getSearchData();
            }
        });
    }

    downloadFile = type => rowData => {
        API.get('/partsOrdering/' + rowData.ppo_id + '/' + type)
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    handleCheckChange = (e) => {
        let newArray = [...this.state.formData.additionalContacts];

        if(e.target.checked){
            newArray.push(e.target.value);
        } else {
            newArray.splice(e.target.value, 1)
        }
        
        this.setState({
            formData: {
                ...this.state.formData,
                additionalContacts: newArray
            }
        });
    };

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions.map(a => a.value);
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }

    render() {
        const { formErrors } = this.state;
        const  staffId  = this.props.loggedInStaff !== undefined ? this.props.loggedInStaff.id : 0;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Parts Order Approval
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth style={{marginTop: 32}}>
                                    <TextField label="Keywords" fullWidth value={this.state.keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                           
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <StatusDataTable 
                            searchResults={this.state.searchResults}
                            actions={ rowData => {
                                let ret = [
                                    {name: 'Download', icon: 'download', onClick: this.downloadFile('pdfPartsOrder')},
                                    {name: 'Download Delivery Schedule', icon: 'calendar-alt', onClick: this.downloadFile('pdfDeliverySchedule')},
                                ];
                                if (rowData.locked ){
                                    ret.push(
                                        {name: 'Locked', icon: icons.lock, onClick: () => {}}
                                    )
                                } else {
                                    ret = [
                                        ...ret,
                                        {name: 'Approve', icon: 'check', onClick: this.handleApproveOpen, disabled: rowData.ppo_approval_by_id !== staffId},
                                        {name: 'Decline', icon: 'times', onClick: this.handleDeclineOpen, disabled: rowData.ppo_approval_by_id !== staffId}
                                    ];
                                }
                                return ret 
                            }}
                            isLoading={this.state.isLoading}
                        />
                    </PaddedPaper>
                </Grid>
                <Dialog 
                    open={this.state.declineDialogOpen} 
                    onClose={this.handleDeclineClose} 
                    fullWidth={true} 
                    maxWidth="sm" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title" style={{backgroundColor:'#D61F26'}}>
                        <Typography style={{color:'white', fontSize:'1.25rem'}}>
                            You Are About To <b>Decline</b> This Parts Order
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <br></br>
                        <Typography variant="body1">Please feel free to leave any notes relating to this parts order:</Typography>
                        <form noValidate autoComplete="off">
                            <Textarea
                                id="declinedNotes"
                                name="declinedNotes"
                                label="Notes"
                                value={this.state.formData.declinedNotes}
                                rows={5}
                                error={formErrors && formErrors['declinedNotes']}
                                onChange={this.handleChange}
                            />
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeclineClose} variant="outlined" color="default">Close</Button>
                        <Button onClick={this.handleDeclineSuccess} color="secondary" autoFocus variant="contained">Decline</Button>
                    </DialogActions>
                </Dialog>
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.declineSnackbarOpen}
                    onClose={this.handleDeclineSnackbarClose}
                    message='You have successfully declined this parts order'
                />

                <Dialog 
                    open={this.state.approveDialogOpen} 
                    onClose={this.handleApproveClose} 
                    fullWidth={true} 
                    maxWidth="md" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title" style={{backgroundColor:'#5da14d'}}>
                        <Typography style={{color:'white', fontSize:'1.25rem'}}>You Are About To <b>Approve</b> This Parts Order</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <form noValidate autoComplete="off">
                            {formErrors && formErrors['stockReturnDetails'] && formErrors['stockReturnDetails'].split('\n').map((item, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <Typography component={"div"} style={{color: colors.red}}>
                                            {item}<br/>
                                        </Typography>
                                    </React.Fragment>
                                )
                            })}
                            <br></br>
                            <PaddedPaper style={{backgroundColor: '#f2f2f2'}}>
                                <Typography variant="body1" gutterBottom>
                                    {this.state.formData.supplierName}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {this.state.formData.supplierContact + ' - ' + this.state.formData.supplierEmail}
                                </Typography>
                            </PaddedPaper>
                            <FormControl error={formErrors && formErrors['emailTemplate'] && true} fullWidth margin="normal">
                                <AutoCompleteSelect
                                    options={this.state.templateList} 
                                    label='Email Template'
                                    value={this.state.formData.emailTemplate}
                                    onChange={this.handleSelectChange('emailTemplate')}
                                    error={formErrors && formErrors['emailTemplate'] && true}
                                    errorText={formErrors && formErrors['emailTemplate'] && formErrors['emailTemplate']}
                                />
                            </FormControl>
                            <Textarea
                                id="emailText"
                                name="emailText"
                                label="Email Text *"
                                value={this.state.formData.emailText}
                                rows={5}
                                error={formErrors && formErrors['emailText']}
                                onChange={this.handleChange}
                            />
                            <FormControl margin="normal" fullWidth>
                                <AutoCompleteMultiSelect 
                                    options={this.state.drawingList} 
                                    label='Email Attachments'
                                    value={this.state.formData.emailAttachments}
                                    onChange={this.handleMultiSelectChange('emailAttachments')}
                                    error={formErrors && formErrors['emailAttachments'] && true}
                                    errorText={formErrors && formErrors['emailAttachments']}
                                />
                            </FormControl>
                            <Textarea
                                id="approveNotes"
                                name="approveNotes"
                                label="Notes"
                                value={this.state.formData.approveNotes}
                                rows={5}
                                error={formErrors && formErrors['approveNotes']}
                                onChange={this.handleChange}
                            />
                            <RadioGroup
                                name="emailChoice"
                                value={this.state.formData.emailChoice}
                                onChange={this.handleChange}
                                row
                            >
                                <FormControlLabel
                                    value="emailSingleSupplier"
                                    control={<Radio color="primary" />}
                                    label="Email supplier contact above"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="emailMultipleSuppliers"
                                    control={<Radio color="primary" />}
                                    label="Email additional supplier contacts"
                                    labelPlacement="end"
                                />
                                
                                <FormControlLabel
                                    value="noEmail"
                                    control={<Radio color="primary" />}
                                    label="Do not send email"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                            {this.state.showHideAdditionalContacts &&
                                <FormControl component="fieldset">
                                    <FormGroup>
                                        {this.state.additionalContactList && this.state.additionalContactList.map(v => (
                                            <FormControlLabel key={v.supp_cont_id}
                                                control={
                                                    <Checkbox 
                                                        onChange={this.handleCheckChange} 
                                                        value={v.supp_cont_email}
                                                        color="primary"
                                                    />
                                                }
                                                label= {`${v.supp_cont_first_name} ${v.supp_cont_last_name} (${v.supp_cont_email})`}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            }
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleApproveClose} variant="outlined" color="default">Close</Button>
                        <Button onClick={this.handleApproveSuccess} style={{backgroundColor:'#5da14d', color:'white'}} autoFocus variant="contained">Approve</Button>
                    </DialogActions>
                </Dialog>
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.approveSnackbarOpen}
                    onClose={this.handleApproveSnackbarClose}
                    message='You have successfully approved this parts order'
                />
                <SnackBar
                    variant="error"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='left'
                    open={this.state.errorSnackbarOpen}
                    onClose={this.handleErrorSnackbarClose}
                    message={this.state.snackbarErrorMsg}
                />
            </Grid>
        );
    }
}



const mapStateToProps = state => ({
    statePersistence:   state.statePersistence,
    loggedInStaff:      state.staffAuth.staff
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(PartsOrderApproval);
