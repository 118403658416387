import React from 'react';
import ActivityLog from 'Components/Common/ActivityLog/ActivityLog';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import _ from 'lodash';

const typeToAcl = {
    Created: [
        colors.blue,
        IconHelper.plus,
    ],
    "Contacts Added": [
        colors.blue,
        IconHelper.addContact,
    ],
    "Contact Reinstated": [
        colors.green,
        IconHelper.addContact,
    ],
    "Contact Removed": [
        colors.red,
        IconHelper.removeContact,
    ],
    "Contacts Removed": [
        colors.red,
        IconHelper.removeContact,
    ],
    "Contact Unsuscribed": [
        colors.red,
        IconHelper.ban,
    ],
    "Contacts Lapsed":[
        colors.red,
        IconHelper.removeContact,
    ],
    "Contacts un-Lapsed": [
        colors.green,
        IconHelper.plus,
    ],
    'Contacts Reinstated': [
        colors.green,
        IconHelper.addContact,
    ],
    'Updated': [
        colors.orange,
        IconHelper.edit,
    ],
    'Reactivated': [
        colors.green,
        IconHelper.plus,
    ],
    'Inactivated': [
        colors.disabled,
        IconHelper.minus,
    ],
    'Contact Validated': [
        colors.green,
        IconHelper.true
    ]
}

class ACL extends React.Component {

    render (){

        const { mailingList } = this.props;

        return (
            <ActivityLog 
                filter={
                    [
                        {label: 'Mailing List', types: ['Created', 'Updated', 'Inactivated', 'Reactivated']},
                        {label: 'Contacts', types: ['Contact Reinstated', 'Contact Removed', 'Contacts Added', 'Contacts Removed', "Contacts un-Lapsed", "Contacts Lapsed"]}
                    ]
                }
                fields={{
                    type:   't',
                    date:   'd',
                    icon:   { color: 'color', icon: 'icon' },
                    text:   'm',
                    staff:  's',
                }}
                atl={_.map(mailingList.a, i => ({
                    ...i,
                    color: typeToAcl[i.t] ? typeToAcl[i.t][0] : colors.disabled,
                    icon: typeToAcl[i.t] ? typeToAcl[i.t][1] : IconHelper.question,
                    s: i.s ?? 'System'
                }))}
            />
        )
    }
}

export default ACL;