import React, { Component } from 'react';
import _                    from 'lodash';

import icons from 'Helpers/IconHelper';

import DataTable   from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import { Grid, Typography } from '@material-ui/core';

import 'Assets/timeLine.css';

const initialState = () => ({
    isLoading: true,
    package: {},
    currentTab: 'Package',
});

class OrderTab extends Component {
    constructor(props) {
        super(props);    
        this.state = initialState();
    }

    render(){
    
        const { salesPackage } = this.props;
        
        let orders = _.filter(salesPackage.order_pivot, i => i.pto_package_version <= salesPackage.p_version);

        return(
            <Grid container spacing={2}>
                {orders.length > 0 ?
                    _.map(_.uniqBy(orders, 'pto_package_version'), i => 
                        <Grid item xs={12} key={i.pto_package_version}>
                            <PaddedPaper>
                                <Typography variant="h6" style={{marginBottom: '1em'}}>Version {i.pto_package_version}</Typography>
                                <DataTable
                                    config={{
                                        key: 'order_id'
                                    }}
                                    rows={_.map(_.filter(orders, j => j.pto_package_version === i.pto_package_version), i=>({...i.order, qty: i.pto_qty}))}
                                    columns={[
                                        {
                                            heading: 'Order Date',
                                            field: 'order_date',
                                            fieldFormat: 'clenydate',
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: 'Reference',
                                            field: i => i.order_reference + '-' + i.order_reference_amendment,
                                        },
                                        {
                                            heading: 'Customer',
                                            field:  i=><>{i.order_cust_name}<br/>{i.order_delivery_cust_address_name}</>,
                                        },
                                        {
                                            heading: 'Packages Used',
                                            field: 'qty',
                                            sizeToContent: true,
                                            alignment: 'center',
                                        },
                                        {
                                            heading: 'Subtotal',
                                            field: 'order_sub_total',
                                            fieldFormat: 'currency',
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: 'VAT',
                                            field: 'order_vat',
                                            fieldFormat: 'currency',
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: 'Total',
                                            field: 'order_total_incl_vat',
                                            fieldFormat: 'currency',
                                            sizeToContent: true,
                                        },
                                        {
                                            actions: i => ([
                                                {
                                                    icon: icons.view,
                                                    name: 'View',
                                                    link: `/sales/order/view/${i.order_id}`,
                                                }
                                            ])
                                        }
                                    ]}
                                />
                            </PaddedPaper>
                        </Grid>
                    ) : 
                    <Typography>No Orders Have Made Using This Package</Typography>
                }
            </Grid>
        );
    }
}

export default OrderTab;