import React, {Component} from 'react';
import { connect }        from 'react-redux';
import API                from 'API';
import moment             from 'moment'; 
import _                  from 'lodash';

import { Typography, Grid, Button, TextField, FormControl, Link, Tooltip, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

import FALightIcon        from 'Components/Common/Icons/FontAwesome/FALightIcon';
import DataTable          from 'Components/Common/DataTables/CiDataTable';
import SnackBar           from 'Components/Common/SnackBars/SnackBar';
import FASolidIcon        from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DatePicker         from 'Components/Common/DatePickers/DatePicker';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import EmailDialog        from 'Components/Common/Dialogs/EmailDialog';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import StickyNotes        from 'Components/Common/StickyNotes/StickyNotes';

import { pdfFromBase64 }        from 'Helpers/PDFHelper';
import { statusColour, colors } from 'Helpers/ColourHelper';

import { deployDialog, closeDialog }        from 'Actions/Dialog/Dialog';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';
import IconHelper from 'Helpers/IconHelper';

const initialState = () => ({
    access:             {
        'repeat-quotation': false,
        customerQuotationSearch: false
    },
    quote:              {},
    keywords:           '',
    status:             'All',
    dateFrom:           null,
    dateTo:             null,
    searchResults:      {},
    isLoading:          true,
    emailDialogOpen:    false,
});


const styles = () => ({
    card: {
        display: 'flex', 
        justifyContent:'center', 
        alignItems:'center', 
        textAlign:'left'
    },
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    },
    custName: {
        '&:hover':{cursor: 'pointer'}
    }
});

class QuotationSearch extends Component {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Sales:QuotationSearch';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        this.getSearchData();
        this.getInitialData();
    }

    getInitialData() {

            Promise.all([
                API.get('/staff/my/access/check/customer-quotation-search'),
                API.get('/sales/quotations/stats'),
                API.get(`/staff/my/access/check/repeat-quotation`)
            ])
            .then(([access, statRes, repeatAccess]) => {
                if(statRes?.data) {
                    this.setState({
                        ...this.state,
                        access: {
                            customerQuotationSearch: (access.data && access.data.has_access) || false,
                            'repeat-quotation': (repeatAccess.data && repeatAccess.data.has_access) || false
                        },
                        statusStats: statRes.data,
                        
                    });
                }
            });
    }

    getSearchData = () => {
        const params = {
            keywords: this.state.keywords,
            status: this.state.status,
            dateFrom: this.state.dateFrom !== null ? moment(this.state.dateFrom).format('YYYY-MM-DD') : null,
            dateTo: this.state.dateTo !== null ? moment(this.state.dateTo).format('YYYY-MM-DD') : null,
            slim: 1
        }
        const props = {
            cancellation: true
        }
        this.setState({
            isLoading: true
        }, () => {
            API.get('/sales/quotations', {params, props})
            .then((res) => {
                if(res?.data) {
                    _.map(res.data, q => {
                        // Status Colour
                        q.colour = statusColour(q.s);

                        // this is unused
                        //let log = _.filter(q.a, { 'cq_log_type': 'Email Sent' });
                        //q.email_sent = log.length > 0 ? true : false

                        switch(q.s){
                            case 'Open':
                                q.toolTip1 = 'Quote ' + q.s
                                q.toolTip2 = 'For ' + moment().diff(moment(q.d), 'days') + ' Days'
                                q.icon = 'file'

                            break;
                            case 'Ordered':
                                q.toolTip1 = 'Quote ' + q.s;
                                q.toolTip2 = moment(q.od).format('DD/MM/YYYY');
                                q.icon = 'check';
                            break;
                            case 'Closed':
                            case 'Expired':
                                q.toolTip1 = 'Quote ' + q.s;
                                q.toolTip2 = moment(q.cd).format('DD/MM/YYYY');
                                q.icon = 'ban';
                            break;
                            default:
                            break;
                        }
                    });
                    this.setState({
                        ...this.state,
                        searchResults: res.data,
                        isLoading: false
                    });
                }
            });
        });
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.setState({
            keywords: '',
            status: 'All',
            dateFrom: null,
            dateTo: null,
        }, 
        () => {
            this.clearPageState();
            this.getSearchData();
        });
    }

    downloadQuote = rowData => {
        API.get('/sales/quotations/' + rowData.i + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    // EMAIL
    handleEmailQuotation = rowData => {
        API.get('/sales/quotations/' + rowData.i).then(result => {
            const dialog = <EmailDialog
                                handleEmailDialogClose={this.handleEmailClose}
                                customerId={rowData.c.i}
                                customerContactId={rowData.c.c}
                                type='quote'
                                id={rowData.i}
                                pickingDocuments={result.data.pickingDocuments}
                            />
            this.props.deployDialog(dialog, 'You Are About To Email - Quotation', 'success');
        });
    }

    handleEmailClose = () => {
        this.props.closeDialog();
    }

    downloadFile = rowData => {
        API.get('/sales/quotations/' + rowData.i + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    handleDateChange = name => date => {
        this.setState({
            [name]: date
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    handleShowStickyNote = quoteId => { this.props.deployDialog(<StickyNotes quoteId={quoteId} dialog/>, 'Sticky Notes', null,'xs') }

    render() {
        const { access, statusStats, isLoading } = this.state;
        const { classes } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Quotation Search
                    </Typography>
                </Grid>
                <React.Fragment>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <PaddedPaper>
                                <form noValidate autoComplete="off">
                                    <Grid container xs={12} spacing={2}>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl margin="none" fullWidth>
                                                <TextField label="Keywords" fullWidth value={this.state.keywords} onChange={this.handleSearchKeyword} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <FormControl margin="none" fullWidth>
                                                <AutoCompleteSelect 
                                                    options={[
                                                        {value: 'All', label: 'All'},
                                                        {value: 'Open', label: 'Open'},
                                                        {value: 'Ordered', label: 'Ordered'},
                                                        {value: 'Closed', label: 'Closed'},
                                                        {value: 'Expired', label: 'Expired'}
                                                    ]} 
                                                    label='Status'
                                                    onChange={this.handleSelectChange('status')}
                                                    value={this.state.status}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <DatePicker
                                                type='date'
                                                id="dateFrom"
                                                name="dateFrom"
                                                label="Date From"
                                                margin="none"
                                                autoOk={true}
                                                value={this.state.dateFrom}
                                                onChange={this.handleDateChange('dateFrom')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <DatePicker
                                                type='date'
                                                id="dateTo"
                                                name="dateTo"
                                                label="Date To"
                                                margin="none"
                                                autoOk={true}
                                                value={this.state.dateTo}
                                                onChange={this.handleDateChange('dateTo')}                                            
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <div className='buttonRow'>
                                                <Button onClick={this.resetSearch}
                                                        variant="outlined"
                                                        color="default"
                                                        >
                                                    <FALightIcon icon='undo' button />
                                                    Reset Search
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            {isLoading ? <LoadingCircle /> : (
                                <React.Fragment>
                                    <Typography variant="h5" gutterBottom>
                                       <strong>{this.state.status} Quotations</strong> <br></br>
                                    </Typography>
                                <DataTable  
                                    config={{
                                        key:                    'quote_id',
                                        pagination:             true,
                                        persistenceId:          this.persistenceId,
                                        alternatingRowColours:  true,
                                        responsiveimportant:    true,
                                        isLoading
                                    }}
                                    columns={[
                                        {
                                            heading: 'Quote Date',
                                            field: rowData => moment(rowData.d).format("DD/MM/YYYY"),
                                            important: true,
                                            sizeToContent: true
                                        },
                                        {
                                            actionsCustomHeader:    true,
                                            important:              true,
                                            sizeToContent:          true,
                                            alignment:              'left',
                                            heading:                'Reference',
                                            actions: rowData => {
                                                return [
                                                    {name: 'View this Customers Quotations', label: rowData.r + '-' + rowData.ra, link: `/sales/quotation/view/${rowData.i}`, type:'plainLink',}
                                                ]
                                            }
                                        },
                                        {
                                            heading: 'Discount',
                                            field: rowData => rowData.t,
                                            field: rowData => {
                                                return rowData.t == 'With Discount' ? 
                                                    <FALightIcon icon='check' style={{color:colors.green}}/> : <FALightIcon icon='times' style={{color:colors.red}}/>;
                                            },
                                            important: true,
                                            sizeToContent: true,
                                            fieldFormat: 'boolean',
                                            tooltip: rowData => rowData.t,
                                            alignment: 'center'
                                        },
                                        {
                                            heading: 'Customer Name',
                                            field: rowData => <div className={classes.custName} onClick={()=>{ this.props.history.push(`/customers/view/${rowData.c.i}`); }}>{rowData.c.n}</div>,
                                            dataRef: 'c.n',
                                            sizeToContent: true,
                                            truncate: true,
                                        },
                                        {
                                            heading:    'Delivery Address',
                                            field:      rowData => rowData.c.d,
                                            important:  true,
                                            info:       rowData => <div>
                                                                        <Typography variant="body2">
                                                                            {rowData.c.a.split('\n').map((item, key) => {
                                                                                return  <span key={key}>
                                                                                            {item}{rowData.c.a.split('\n').length === key+1 ? null : <br/>}
                                                                                        </span>
                                                                            })}
                                                                        </Typography>
                                                                    </div>,
                                            infoEnd:    'stretch',
                                            truncate: true,
                                        },
                                        {
                                            heading: 'Status',
                                            field: rowData =>   <React.Fragment>
                                                                    <Tooltip
                                                                        title={
                                                                            <div>
                                                                                <Typography variant="body2">{rowData.toolTip1}</Typography>
                                                                                <Typography variant="body2">{rowData.toolTip2}</Typography>
                                                                            </div>
                                                                        }
                                                                        placement="left"
                                                                    >
                                                                        <IconButton className={classes.iconButton}>
                                                                            <FALightIcon 
                                                                                icon={`${rowData.icon}`}
                                                                                button
                                                                                style={{
                                                                                    color: rowData.colour
                                                                                }}
                                                                                noMargin
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </React.Fragment>,
                                            important: true,
                                            alignment: 'center'
                                        },
                                        {
                                            heading: 'Total',
                                            field: rowData => ( 
                                                <React.Fragment>
                                                    {rowData.tl.t}
                                                    {' '}
                                                    <Tooltip 
                                                        title={
                                                            <div style={{display: 'flex', width: 200}}>
                                                                <div style={{width: '50%', textAlign:'right'}}>
                                                                    <Typography variant="body2">Sub Total</Typography>
                                                                    <Typography variant="body2">VAT ({rowData.tl.p}%)</Typography>
                                                                    <Typography variant="body2">Total</Typography>
                                                                </div>
                                                                <div style={{width: '50%', textAlign:'right'}}>
                                                                    <Typography variant="body2">£{rowData.tl.s}</Typography>
                                                                    <Typography variant="body2">£{rowData.tl.v}</Typography>
                                                                    <Typography variant="body2">£{rowData.tl.t}</Typography>
                                                                </div>
                                                            </div>
                                                        } 
                                                        placement="left"
                                                    >
                                                        <Link>
                                                            <FASolidIcon 
                                                                icon='info-circle'
                                                                button 
                                                                size='small'
                                                                style={{
                                                                    color: 'rgba(0, 0, 0, 0.38)'
                                                                }}
                                                            />
                                                        </Link>
                                                    </Tooltip>
                                                </React.Fragment>
                                            ),
                                            important: true,
                                            alignment: 'right',
                                            fieldPrefix: '£',
                                        },
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Sticky Notes', icon: IconHelper.stickyNote, onClick: () => this.handleShowStickyNote(rowData.i), color: (rowData.n[1] && colors.yellow), hide: !rowData.n[0]},
                                                    {name: 'Repeat', icon: IconHelper.repeat, link: '/sales/quotation/add/repeat/' + rowData.i, disabled: !access['repeat-quotation']},
                                                    {name: 'Download', icon: 'download', onClick: this.downloadQuote},
                                                    {name: 'Send Quote to Customer', icon: 'envelope', onClick: this.handleEmailQuotation, disabled: rowData.s !== 'Open' && true},
                                                    {name: 'View', icon: 'search', link: '/sales/quotation/view/' + rowData.i},
                                                    {name: 'View this Customers Quotations', icon: 'file-search', link: `/customers/view/${rowData.c.i}/Quotations`, disabled: !access.customerQuotationSearch}
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={this.state.searchResults}
                                />
                                </React.Fragment>
                            )}
                        </PaddedPaper>
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.emailSnackbarOpen}
                            onClose={this.handleEmailSnackbarClose}
                            message='You have successfully sent the email'
                        />
                    </Grid>
                </React.Fragment>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deployDialog:           (content, title, variant)   => dispatch(deployDialog(content, title, variant)),
    closeDialog:            ()                          => dispatch(closeDialog()),
    clearPersistence:       (key)                       => dispatch(clearPersistence(key)),
    setPersistence:         (key, state)                => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(QuotationSearch));
