import React, { Component} from 'react';
import _ from 'lodash';
import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DataTable from 'Components/Common/DataTables/CiDataTable';

import icons from 'Helpers/IconHelper';

class LinkedParts extends Component {
    render() {
        const { linked_parts } = this.props.part;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Linked Parts</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {linked_parts.length > 0 ? 
                        <DataTable 
                            config={{
                                key: 'plp_id',
                                plainHeader: true,
                            }}
                            columns={[
                                {
                                    field:         'part.default_image.thumbnail_file_path',
                                    fieldFormat:   'image',
                                    sizeToContent: true,
                                },
                                {
                                    heading:       'Part Number',
                                    field:         'part.part_number',
                                    sizeToContent: true,
                                },
                                {
                                    heading:       'Part Description',
                                    field:         'part.part_description',
                                },
                                {
                                    heading:       'Qty',
                                    field:         'plp_qty',
                                    sizeToContent: true,
                                },
                                {
                                    actions: row => {
                                        return [
                                            {name: 'View', icon: icons.view, link: `/parts/view/${row.part.part_id}`},
                                        ]
                                    }
                                }
                            ]}
                            rows={_.orderBy(linked_parts, ['part.part_number'], ['asc'])}
                        /> 
                    :
                        <Typography>There are 0 linked parts found.</Typography>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default LinkedParts;
