import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';

class InvoiceDetails extends PureComponent {
    
    render() {
        const { formErrors, lists, staffList, invoiceFao, invoiceAddress, handleSelectChange } = this.props;
        return (
            <form noValidate autoComplete="off">
                <Typography variant="h6">
                    Invoice Details
                </Typography>
                <FormControl fullWidth margin="normal">
                    <AutoCompleteSelect 
                        options={lists.defaultAddressList} 
                        label='Invoice Address *'
                        value={invoiceAddress}
                        onChange={handleSelectChange('invoiceAddress')}
                        error={formErrors && formErrors['invoiceAddress'] && true}
                        errorText={formErrors && formErrors['invoiceAddress']}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <AutoCompleteSelect 
                        options={staffList} 
                        label='Invoice FAO *'
                        value={invoiceFao}
                        onChange={handleSelectChange('invoiceFao')}
                        error={formErrors && formErrors['invoiceFao'] && true}
                        errorText={formErrors && formErrors['invoiceFao']}
                    />
                </FormControl>
            </form>
        );
    }
}

export default InvoiceDetails;
    