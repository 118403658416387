import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import moment               from 'moment';
import { connect }          from 'react-redux';

import DataTable                   from 'Components/Common/DataTables/CiDataTable';
import AllIcon                     from 'Components/Common/Icons/AllIcon';
import FALightIcon                 from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon                 from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import PaddedPaper                 from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect          from 'Components/Common/Selects/AutoCompleteSelect';
import SnackBar                    from 'Components/Common/SnackBars/SnackBar';
import StickyNotes                 from 'Components/Common/StickyNotes/StickyNotes';
import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog    from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';
import ViewOrderPick               from 'Components/Sales/Order/ViewOrderPick/ViewOrderPick';

import { Button, FormControl, Grid, TextField, Typography, Tooltip, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import { deployDialog }                     from 'Actions/Dialog/Dialog';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';
import JobSnapshot from 'Components/Engineers/Jobs/Misc/JobSnapshot';

const initialState = () => ({
    access: {
        customerOrderSearch: false,
        orderSearch: false,
        viewOrder: false,
        viewQuote: false,
        repeatOrder: false,
    },
    order: {},
    status: 'Picking',
    display: 'All',
    courier: 'All',
    keywords: '',
    searchResults: {},
    orderStats: [],
    searchParams: [],
    courierList: [],
    isLoading: false
});

class OrderPicking extends Component {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Sales:picking';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        this.getAccess();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/customer-order-search'),
            API.get('/staff/my/access/check/order-search'),
            API.get('/staff/my/access/check/view-customer-order'),
            API.get('/staff/my/access/check/view-customer-quotation'),
            API.get('/staff/my/access/check/repeat-customer-order'),
        ])
        .then(([custOrderSearchRes, orderSearchRes, viewOrderRes, viewQuoteRes, repeatOrderRes]) => {
            this.setState({
                ...this.state,
                access: {
                    customerOrderSearch: custOrderSearchRes?.data?.has_access ?? false,
                    orderSearch:         orderSearchRes?.data?.has_access     ?? false,
                    viewOrder:           viewOrderRes?.data?.has_access       ?? false,
                    viewQuote:           viewQuoteRes?.data?.has_access       ?? false,
                    repeatOrder:         repeatOrderRes?.data?.has_access     ?? false
                }
            }, this.generateSearchParams);
        });
    }

    generateSearchParams = () => {
        // generate search params
        // then get search data
        let searchParams = [
            {value: 'Today', label: 'Today'},
            {value: 'This Week', label: 'This Week'},
            {value: 'This Month', label: 'This Month'}
        ];

        let nextMonth = '';

        for(let i = 1; i < 12; i++) {
            nextMonth = moment().add(i, 'months').format('MMMM YYYY');
            searchParams.push({
                value: nextMonth, label: nextMonth
            });
        }

        searchParams.push({
            value: 'All', label: 'All'
        });

        this.setState({
            ...this.state,
            searchParams
        }, 
        this.getSearchData);
    }

    getSearchData = () => {
        let customerId = null;
        const params = {
            keywords: this.state.keywords,
            status: this.state.status,
            display: this.state.display,
            courier: this.state.courier,
            withDelivery: true
        }
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([
                API.get('/sales/orders/pick', {params}),
            ])
            .then(([orders]) => {
                let courierList = [];
                orders.data.couriers.forEach(el => {
                    courierList.push({
                        value: el.cour_id, 
                        label: el.cour_name
                    });
                });

                courierList.push({
                    value: 'All', label: 'All'
                })

                this.setState({
                    ...this.state,
                    searchResults: orders.data.orders,
                    courierList,
                    isLoading: false
                });
            });
        });
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
            display: 'All',
            courier: 'All',
        }, 
        () => {
            this.getSearchData();
        });
    }

    handleDownloadOpen = orderId => this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md');

    handleEmailOpen = orderId => this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />, 'Email Previous Documents', '', 'md');

    handleViewPick = rowData => {
        const dialog = <ViewOrderPick 
            orderId={rowData.order_id}
        />;
        this.props.deployDialog(dialog, rowData.full_reference);
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption?.value ?? ''
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    handleDateChange = name => date => {
        this.setState({
            [name]: date
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    handleShowStickyNote = orderId => { this.props.deployDialog(<StickyNotes orderId={orderId} dialog/>, 'Sticky Notes', null,'xs') }
    
    render() {
        const { access:{customerOrderSearch, orderSearch, viewOrder, viewQuote} } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Order Awaiting Picking
                    </Typography>
                </Grid>
                    <>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={12}>
                                <PaddedPaper>
                                    <form noValidate autoComplete="off">
                                        <Grid container xs={12} item spacing={1}>
                                            <Grid item xs={3}>
                                                <FormControl margin="none" fullWidth>
                                                    <TextField label="Keywords" fullWidth value={this.state.keywords} onChange={this.handleSearchKeyword} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl margin="none" fullWidth>
                                                    <AutoCompleteSelect 
                                                        options={this.state.searchParams} 
                                                        label='Display: '
                                                        onChange={this.handleSelectChange('display')}
                                                        value={this.state.display}
                                                        disableSort
                                                        noClear
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl margin="none" fullWidth>
                                                    <AutoCompleteSelect 
                                                        options={this.state.courierList} 
                                                        label='Courier: '
                                                        onChange={this.handleSelectChange('courier')}
                                                        value={this.state.courier}
                                                        disableSort
                                                        noClear
                                                        margin="none"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <div className='buttonRow'>
                                                    <Button onClick={this.resetSearch}
                                                            variant="outlined"
                                                            color="default"
                                                    >
                                                        <FALightIcon icon='undo' button />
                                                        Reset Search
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <DataTable  
                                    config={{
                                        key:                    'order_id',
                                        pagination:             true,
                                        alternatingRowColours:  true,
                                        responsiveImportance:   true,
                                        isLoading:              this.state.isLoading,
                                        persistenceId:          this.persistenceId,
                                        rowProps: rowData => {
                                            let backgroundColor = moment(rowData.order_picking_date).isSame(moment().format('YYYY/MM/DD'))
                                                                ? 'rgb(241, 248, 233)'
                                                                : 'inherit';
                                            return ({
                                                style: {
                                                    backgroundColor
                                                }
                                            });
                                        }
                                    }}
                                    columns={[
                                        {
                                            heading: '',
                                            field: rowData => moment(rowData.order_picking_date).isBefore(moment().format('YYYY/MM/DD')) 
                                                                ? <FASolidIcon icon="exclamation-triangle" style={{color: colors.red}} /> 
                                                                : '',
                                            important: true,
                                            sizeToContent: true,
                                            alignment: 'center',
                                            hideInResponsiveDialog: true
                                        },
                                        {
                                            heading: 'Picking Date',
                                            field: rowData => moment(rowData.order_picking_date).isBefore(moment().format('YYYY/MM/DD')) 
                                                                ? <strong style={{color: colors.red}}>{moment(rowData.order_picking_date).format('DD/MM/YYYY')}</strong> 
                                                                : moment(rowData.order_picking_date).format('DD/MM/YYYY'),
                                            important: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Despatch Date',
                                            field: rowData => moment(rowData.order_despatch_date).isBefore(moment().format('YYYY/MM/DD')) 
                                                                ? <strong style={{color: colors.red}}>{moment(rowData.order_despatch_date).format('DD/MM/YYYY')}</strong> 
                                                                : moment(rowData.order_despatch_date).format('DD/MM/YYYY'),
                                            important: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Delivery Date',
                                            field: rowData => moment(rowData.order_delivery_date).isBefore(moment().format('YYYY/MM/DD')) 
                                                                ? <strong style={{color: colors.red}}>{moment(rowData.order_delivery_date).format('DD/MM/YYYY')}</strong> 
                                                                : moment(rowData.order_delivery_date).format('DD/MM/YYYY'),
                                            important: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Despatch Method',
                                            field: rowData => rowData?.courier?.cour_name,
                                            sizeToContent: true,
                                            important: true,
                                            hideInResponsiveDialog: true,
                                            truncate: true
                                        },
                                        {
                                            actionsCustomHeader:    true,
                                            important:              true,
                                            alignment:              'left',
                                            heading:                'Reference',
                                            actions: rowData => {
                                                return [
                                                    { label: rowData.full_reference,                                link: `/sales/order/view/${rowData.order_id}`,              type:'plainLink'},
                                                    { label: rowData?.quotation_full_reference?.full_reference,     link: rowData.order_quote_id ? `/sales/quotation/view/${rowData.order_quote_id}` : null,    type:'plainLink', class: "textSecondary"},
                                                ]
                                            }
                                        },
                                        // {
                                        //     heading: 'Discount',
                                        //     field: rowData => rowData.order_type === 'With Discount',
                                        //     sizeToContent: true,
                                        //     fieldFormat: 'boolean',
                                        //     tooltip: rowData => rowData.order_type,
                                        //     alignment: 'center',
                                        //     hideInResponsiveDialog: true
                                        // },
                                        {
                                            heading: 'Customer',
                                            field: rowData =>   <Tooltip
                                                                    title={
                                                                        <Typography variant="body2">
                                                                            <b>Delivery Address:</b><br />
                                                                            {rowData.order_delivery_cust_address.split('\n').map((item, key) => {
                                                                                return  <span key={key}>
                                                                                            {item}{rowData.order_delivery_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                                                        </span>
                                                                            })}
                                                                        </Typography>
                                                                    }
                                                                    placement="left"
                                                                >
                                                                    <span className={this.props.classes.custName} onClick={()=>{ this.props.history.push(`/customers/view/${rowData.order_cust_id}`); }}>
                                                                        {rowData.order_cust_name}
                                                                        {' '}
                                                                        <Typography component="div" variant="body2">
                                                                            {rowData.order_delivery_cust_address_name}
                                                                        </Typography>
                                                                    </span>
                                                                </Tooltip>,
                                            important: true,
                                            truncate: true
                                        },
                                        {
                                            heading:        'Total',
                                            field: rowData => ( 
                                                <Tooltip 
                                                    title={
                                                        <div style={{display: 'flex', width: 200}}>
                                                            <div style={{width: '50%', textAlign:'right'}}>
                                                                <Typography variant="body2">Sub Total</Typography>
                                                                <Typography variant="body2">VAT ({rowData.vat_value.vat_value_percentage}%)</Typography>
                                                                <Typography variant="body2">Total</Typography>
                                                            </div>
                                                            <div style={{width: '50%', textAlign:'right'}}>
                                                                <Typography variant="body2">£{rowData.order_sub_total}</Typography>
                                                                <Typography variant="body2">£{rowData.order_vat}</Typography>
                                                                <Typography variant="body2">£{rowData.order_total_incl_vat}</Typography>
                                                            </div>
                                                        </div>
                                                    } 
                                                    placement="left"
                                                >
                                                    <span>{rowData.order_total_incl_vat}</span>
                                                </Tooltip>
                                            ),
                                            important:      true,
                                            alignment:      'right',
                                            fieldPrefix:    '£',
                                        },
                                        {
                                            heading: 'Jobs',
                                            field:      rowData => <>
                                                {rowData?.ej && 
                                                    <Tooltip
                                                        title={<JobSnapshot jobRef={rowData?.ej?.ref} forms={rowData?.ej?.f} />}
                                                        placement="left"
                                                    >
                                                        <IconButton onClick={() => this.props.history.push(`/engineers/jobs/view/${rowData.ej?.id}`) }>
                                                            <AllIcon 
                                                                icon={`user-helmet-safety`}
                                                                button
                                                                noMargin
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </>,
                                            alignment: 'center',
                                            sizeToContent: true,
                                            important: false
                                        },
                                        {
                                            heading: '',
                                            field: i => !!i?.authorisedPayment?.op_txn_amount && ( parseFloat(i.authorisedPayment.op_txn_amount).toFixed(3) !== parseFloat(i.order_total_incl_vat).toFixed(3) ) &&
                                                <AllIcon
                                                    icon={icons.partialOrderPayment}
                                                    tooltip='Order is partially paid'
                                                    color={colors.red}
                                                    noMargin
                                                />,
                                            sizeToContent: true,
                                        },
                                        {
                                            actions: rowData => {
                                                let ret = [
                                                    {name: 'Sticky Notes',  icon: icons.stickyNote, onClick: () => this.handleShowStickyNote(rowData.order_id), hide:rowData.sticky_notes.length === 0, color: _.filter(rowData.sticky_notes, i =>!i.sn_completed).length > 0 && colors.yellow },
                                                    {name: 'View',          icon: 'search',         onClick: this.handleViewPick, disabled: !viewOrder},
                                                ];  
                                                ret.push(
                                                    rowData.locked ? 
                                                        {name: 'locked' ,    icon: icons.lock, onClick: () => {}} :
                                                        {name: 'Pick Order', icon: 'qrcode',   link: `/sales/order/picking/${rowData.order_id}`} 
                                                )
                                                ret.push({
                                                    type: 'actionMenu',
                                                    rows: [
                                                        {
                                                            icon: icons.repeat,
                                                            label: "Repeat Order",
                                                            onClick:() => this.props.history.push(`/sales/order/add/repeat/${rowData.order_id}`),
                                                            display: true,
                                                            disabled: !this.state.access.repeatOrder
                                                        },
                                                        {
                                                            icon: "download",
                                                            label: "Download",
                                                            onClick: () => this.handleDownloadOpen(rowData.order_id),
                                                            display: true,
                                                            disabled: !orderSearch
                                                        },
                                                        {
                                                            icon: "envelope",
                                                            label: "Send Order to Customer",
                                                            onClick: () => this.handleEmailOpen(rowData.order_id),
                                                            display: true,
                                                            disabled: !orderSearch
                                                        },
                                                        {
                                                            icon: "file-search",
                                                            label: "View this Customers Orders",
                                                            onClick: () => this.props.history.push(`/customers/view/${rowData.order_cust_id}/Orders`),
                                                            display: true,
                                                            disabled: !this.state.access.customerOrderSearch
                                                        }
                                                    ]
                                                });
                                                return ret;
                                            }
                                        }
                                    ]}
                                    rows={this.state.searchResults}
                                />
                            </PaddedPaper>
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.emailSnackbarOpen}
                                onClose={this.handleEmailSnackbarClose}
                                message='You have successfully sent the email'
                            />
                        </Grid>
                    </>
            </Grid>
        );
    }
}

const styles = theme => ({
    custName: {
        '&:hover':{cursor: 'pointer'}
    }
});

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deployDialog:       (content, title, variant)   => dispatch(deployDialog(content, title, variant)),
    clearPersistence:   (key)                       => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderPicking));