import React from 'react';
import { useDispatch } from "react-redux";
import _ from "lodash";

import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Alert from "Components/Common/Alert/Alert";
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { deploySnackBar } from "Actions/SnackBar/SnackBar";

const WildixSetDevice = ({ devices, selectedDevice, handleSubmit }) => {
    const dispatch = useDispatch();

    const handleChange = (device) => {
        if (device?.getId() !== selectedDevice?.getId()) {
            handleSubmit(device);
            dispatch(deploySnackBar(`success`, `Wildix VOIP Device set to ${formattedLabel(device?.getName())}`));
        }
    };

    const getIcon = (label) => {
        return label === "Web Browser" ? "globe" : label?.includes("iOS") || label?.includes("Android") ? "mobile" : "phone-office";
    };

    const formattedLabel = (label) => {
        return label?.includes("iOS") ? "iPhone" : label?.includes("Android") ? "Android" : label?.split("/")[0];
    }

    return (
        (_.isEmpty(devices) && <Alert severity="error">No Wildix VOIP devices connected</Alert>) || (
            <FormControl fullWidth>
                <AutoCompleteSelect
                    variant="outlined"
                    onChange={(o) => handleChange(o?.dv)}
                    options={_.map(devices, (d) => ({ dv: d.dv, label: d.nm, value: d?.id }))}
                    formatOptionLabel={({ label }) => (
                        <Box pt={1} pb={1} pl={1}>
                            <Grid container alignItems="center" wrap="nowrap">
                                <Grid item>
                                    <FAIcon type="thin" icon={getIcon(label)} size={15} button />
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="body2" component="div">
                                        <strong>{formattedLabel(label)}</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    value={selectedDevice?.getId?.() ?? ""}
                    noClear
                    noMargin
                />
            </FormControl>
        )
    );
};

export default WildixSetDevice;
