import React, { Component } from 'react';
import { connect } from 'react-redux';

import API from 'API';
import _ from 'lodash';
import moment from 'moment';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import CustomerReturnsDownloadPrevDocsDialog from 'Components/CustomerReturns/CustomerReturnsDownloadPrevDocsDialog/CustomerReturnsDownloadPrevDocsDialog';
import CustomerReturnsEmailPrevDocsDialog from 'Components/CustomerReturns/CustomerReturnsEmailPrevDocsDialog/CustomerReturnsEmailPrevDocsDialog';
import OrderSearchBlock from 'Components/Sales/Order/OrderSearch/OrderSearchBlock';

import {
    Grid,
    IconButton,
    Tooltip,
    Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';
import 'Assets/timeLine.css';
import JobSnapshot from 'Components/Engineers/Jobs/Misc/JobSnapshot';
import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    formData:   {
        searchString: '',
        dateFrom: null,
        dateTo: null,
        type: 'All'
    },
    formErrors: {},
    data:       {},
    access:     {},
    isLoading:  false,
});

const styles = theme => ({
    custName: {
        '&:hover':{cursor: 'pointer'}
    }
});

class CreditNotesAwaitingApproval extends Component  {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `invoices:cnaa:${this.props.cid}`;
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.getAccess();
        this.getData();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/customer-order-search'),
        ])
        .then(([accessRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    customerOrderSearch: (accessRes?.data?.has_access) || false,
                }
            });
        })
    }

    handleSetSearch = props => {
        this.setState({formData: props},this.getData)
    }

    getData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/customerReturns/creditNote/invoice', {params: {
                keywords:   this.state.formData.keywords,
                dateFrom:   this.state.formData.dateFrom !== null ? moment(this.state.formData.dateFrom).format('YYYY-MM-DD')   : null,
                dateTo:     this.state.formData.dateTo   !== null ? moment(this.state.formData.dateTo).format('YYYY-MM-DD')     : null,
                status:     'Approval',
                slim:       1
            }})
            .then(orders => {
                this.setState({
                    ...this.state,
                    data: orders.data,
                    isLoading: false
                }, () => { this.props.updateTabs(); this.savePageState();});
            });
        });
    }

    handleCNDownloadOpen = creditNote => this.props.deployDialog(<CustomerReturnsDownloadPrevDocsDialog cnId={creditNote} />, 'Download Previous Documents',  '', 'md');
    handleCNEmailOpen    = creditNote => this.props.deployDialog(<CustomerReturnsEmailPrevDocsDialog cnId={creditNote} />, 'Email Previous Documents',     '', 'md');

    handleProcessCheck = id => {
        let data = this.state.data
        let indx = _.findIndex(data, i => i.i == id);
        data[indx].checked = !data[indx].checked;
        this.setState({data});
    }

    render() {
        const { data } = this.state;
        const { classes } = this.props;

        return (            
            <>
                <Grid container item spacing={2}>
                    <Grid item xs={12} lg={12} style={{paddingTop:'1.8em'}}>
                        <OrderSearchBlock disableSites disableStatus callBack={this.handleSetSearch} formData={this.state.formData}/>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container xs={12}>
                                <Grid item xs={6}>
                                    <Typography variant="h5"><strong>Credit Notes Awaiting Invoice</strong><br></br></Typography>
                                </Grid>
                            </Grid>
                            <DataTable  
                                config={{
                                    key: 'cn_id',
                                    pagination: true,
                                    alternatingRowColours: true,
                                    responsiveimportant: true,
                                    isLoading: this.state.isLoading
                                }}
                                columns={[
                                    {
                                        heading: 'Credit Note Date',
                                        field: rowData => moment(rowData.d).format("DD/MM/YYYY"),
                                        dataRef: 'd',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:    'Credit Note Number',
                                        field:      rowData => rowData.n,
                                        alignment:  'center',
                                        dataRef: 'n',
                                        sizeToContent: true,
                                    },
                                    {
                                        actionsCustomHeader:    true,
                                        important:              true,
                                        alignment:              'left',
                                        heading:                'Reference',
                                        actions: rowData => {
                                            let a = [
                                                { label: rowData.r, link: `/returns/creditnote/invoice/${rowData.i}`, type:'plainLink', color: colors.red},
                                                { label: rowData.cn.r, link: `/returns/creditnote/${rowData.cn.i}`, type:'plainLink', color: colors.disabled},
                                            ]

                                            if (rowData.o.i){
                                                a.push(
                                                    { label: rowData?.o.r,  link: `/sales/order/view/${rowData.o.i}`, type:'plainLink'},
                                                )
                                            }

                                            if (rowData.o.cr.length > 0){
                                                _.each(rowData.o.cr, cr => {
                                                    a.push(
                                                        { label: cr.r, link: `/returns/customerReturn/${cr.i}`, color: colors.red,  type:'plainLink'}
                                                    )
                                                })
                                            }
                                            
                                            return a;
                                        },
                                        dataRef: 'cn.r',
                                    },
                                    {
                                        heading: 'Discount',
                                        field: rowData => rowData.t.d,
                                        important: true,
                                        fieldFormat: 'boolean',
                                        tooltip: rowData => rowData.t.d ? 'With Discount' : 'Without Discount',
                                        alignment: 'center',
                                        sizeToContent: true,
                                        dataRef: 't.d',
                                    },
                                    {
                                        heading: 'Restocking Fee',
                                        field: rowData => rowData.t.r,
                                        important: true,
                                        fieldFormat: 'boolean',
                                        tooltip: rowData => rowData.t.r ? 'With Restocking Fee' : 'Without Restocking Fee',
                                        dataRef: 't.r',
                                        sizeToContent: true,
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Customer',
                                        dataRef: 'c.n',
                                        field:      rowData =>  <Tooltip
                                                                    title={
                                                                        <Typography variant="body2">
                                                                            <b>Delivery Address:</b><br />
                                                                            {rowData.a.a.split('\n').map((item, key) => {
                                                                                return  <span key={key}>
                                                                                            {item}{rowData.a.a.split('\n').length === key+1 ? null : <br/>}
                                                                                        </span>
                                                                            })}
                                                                        </Typography>
                                                                    }
                                                                    placement="left"
                                                                >
                                                                    <div className={classes.custName} onClick={()=>{ this.props.history.push(`/customers/view/${rowData.c.i}`); }}>
                                                                        {rowData.c.n}
                                                                        {' '}
                                                                        <Grid container spacing={1}>
                                                                            <Grid item>
                                                                                <Typography component="div" variant="body2">
                                                                                    {rowData.a.n}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </Tooltip>,
                                        important: true,
                                        dataRef: 'c.n',
                                        truncate: true,
                                    },
                                    {
                                        heading: 'Credit Total',
                                        dataRef: 't.t',
                                        field: rowData => ( 
                                            <Tooltip 
                                                title={
                                                    <div style={{display: 'flex', width: 200}}>
                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                            <Typography variant="body2">Sub Total</Typography>
                                                            <Typography variant="body2">VAT ({rowData.c.v}%)</Typography>
                                                            <Typography variant="body2">Total</Typography>
                                                        </div>
                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                            <Typography variant="body2">-£{rowData.t.s}</Typography>
                                                            <Typography variant="body2">-£{rowData.t.v}</Typography>
                                                            <Typography variant="body2">-£{rowData.t.t}</Typography>
                                                        </div>
                                                    </div>
                                                } 
                                                placement="left"
                                            >
                                                <span style={{color: colors.red}}>{`-£${rowData.t.t}`}</span>
                                            </Tooltip>
                                        ),
                                        important: true,                                                    
                                        alignment: 'center',
                                    },
                                    {
                                        heading: 'Jobs',
                                        field:      rowData => <>
                                            {rowData?.ej && 
                                                <Tooltip
                                                    title={<JobSnapshot jobRef={rowData?.ej?.ref} forms={rowData?.ej?.f} />}
                                                    placement="left"
                                                >
                                                    <IconButton className={classes.iconButton} onClick={() => this.props.history.push(`/engineers/jobs/view/${rowData.ej?.id}`) }>
                                                        <AllIcon 
                                                            icon={`user-helmet-safety`}
                                                            button
                                                            noMargin
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </>,
                                        alignment: 'center',
                                        sizeToContent: true,
                                        important: false
                                    },
                                    {
                                        actions: rowData => {
                                            let ret = [];
                                            ret.push(
                                                rowData.cn.l ?
                                                    {name: 'locked', icon: icons.lock,    onClick: ()=>{}}:
                                                    {name: 'View',   icon: icons.search,  link: {pathname: `/returns/creditnote/invoice/${rowData.i}`}},
                                            );
                                            ret.push({
                                                type: 'actionMenu',
                                                rows: [
                                                    {
                                                        icon: "download",
                                                        label: "Download",
                                                        onClick: () => this.handleCNDownloadOpen(rowData.cn.i),
                                                        display: true
                                                    },
                                                    {
                                                        icon: "envelope",
                                                        label: "Send Order to Customer",
                                                        onClick: () => this.handleCNEmailOpen(rowData.cn.i),
                                                        display: true
                                                    },
                                                    {
                                                        icon: "file-search",
                                                        label: "View this Customers Orders",
                                                        onClick: () => this.props.history.push(`/customers/view/${rowData.c.i}/CreditNote`),
                                                        display: true,
                                                    }
                                                ]
                                            });
                                            return ret;
                                        }
                                    }
                                ]}
                                rows={data}
                            />
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                    => dispatch(setPersistence(key, state)),
    deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:        ()                                                         => dispatch(closeDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreditNotesAwaitingApproval));