import React, {Component} from 'react';
import moment             from 'moment';
import API                from 'API';
import _                  from 'lodash';
import { CSVLink }        from "react-csv";

import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import DataTable   from 'Components/Common/DataTables/CiDataTable';
import AllIcon     from 'Components/Common/Icons/AllIcon';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons                      from 'Helpers/IconHelper';
import { clenyDate }              from 'Helpers/TimeHelper';

import { Grid, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const initialState = {
    isLoading: true,
    formData: {
        date: null
    },
    stockMovements: [],
    formErrors: [],
    csvDownload: {},
}

const StockTable = ({data, label, icon, expanded, downloadMasterExcel}) => (
    <Grid item xs={12}>
        <ExpansionPanel styles={{width:'100%'}} expanded={expanded}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <AllIcon icon={icon} color={colors.white} /><Typography>{label}</Typography> 
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <DataTable
                    config={{
                        key: 'sm_id',
                        alternatingRowColours: true,
                        responsiveimportant: true,
                        pagination: true,
                        filterInPagination: true,
                        filterMod: {
                            filterInPagination:     true,
                            colSpan:                3,
                            clearable:              true,
                        },
                    }}
                    columns={[
                        {
                            heading:    'Date',
                            field:      rowData => clenyDate(rowData.sm_date),
                            dataRef:    'sm_date',
                            sizeToContent: true,
                            filter:        'date',
                            filterMod: {displayOrder: 1}
                        },
                        {
                            heading:    'Reference',
                            field:      rowData => rowData.sm_ref,
                            dataRef:    'sm_ref',
                            filter:     'custom',
                            filterMod: {
                                dataRef: (search, rowData) => {return `${rowData.sm_created_by}|${rowData.sm_ref}|${rowData.sm_template_id ? rowData.template.smt_name  : ''}|${rowData.sm_updated_by}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                label:     'Keywords',
                                styleGrid: {width: '50%'}
                            },
                            truncate:   true,
                        },
                        {
                            heading:        'Template',
                            field:          rowData => rowData.sm_template_id && !rowData.sm_modified ,
                            fieldFormat:    'boolean',
                            dataRef:        rowData => rowData.sm_template_id && !rowData.sm_modified ,
                            alignment:      'center',
                            sizeToContent:  true,
                        },
                        {
                            heading:        'Manual',
                            field:          rowData => !rowData.sm_template_id ,
                            fieldFormat:    'boolean',
                            dataRef:        rowData => !rowData.sm_template_id ,
                            alignment:      'center',
                            sizeToContent:  true,
                        },
                        {
                            heading:        'Template/Manual',
                            field:          rowData => rowData.sm_template_id && rowData.sm_modified ,
                            fieldFormat:    'boolean',
                            dataRef:        rowData => rowData.sm_template_id && rowData.sm_modified,
                            alignment:      'center',
                            sizeToContent:  true,
                        },
                        {
                            heading:        'Parts In',
                            field:          rowData => parseFloat(_.filter(rowData.grouped, i=>parseFloat(i.smg_stock_in) > 0).length),
                            dataRef:        rowData => parseFloat(_.filter(rowData.grouped, i=>parseFloat(i.smg_stock_in) > 0).length),
                            sizeToContent:  true,
                            alignment: 'right',
                        },
                        {
                            heading:        'Qty In',
                            field:          rowData => parseFloat(_.sumBy(rowData.grouped, i=>parseFloat(i.smg_stock_in))),
                            dataRef:        rowData => parseFloat(_.sumBy(rowData.grouped, i=>parseFloat(i.smg_stock_in))),
                            sizeToContent:  true,
                            alignment: 'right',
                        },
                        {
                            heading:        'Parts Out',
                            field:          rowData => parseFloat(_.filter(rowData.grouped, i=>parseFloat(i.smg_stock_out) > 0).length),
                            dataRef:        rowData => parseFloat(_.filter(rowData.grouped, i=>parseFloat(i.smg_stock_out) > 0).length),
                            sizeToContent:  true,
                            alignment: 'right',
                        },
                        {
                            heading:        'Qty Out',
                            field:          rowData => parseFloat(_.sumBy(rowData.grouped, i=>parseFloat(i.smg_stock_out))),
                            dataRef:        rowData => parseFloat(_.sumBy(rowData.grouped, i=>parseFloat(i.smg_stock_out))),
                            sizeToContent:  true,
                            alignment: 'right',
                        },
                        {
                            heading:    'Created',
                            field:      rowData => <>
                                {rowData.sm_created_by}<br/>
                                {clenyDate(rowData.sm_created_datetime)}
                            </>,
                            dataRef:    'sm_created_by',
                            sizeToContent:  true,
                        },
                        {
                            heading:    'Last Changed',
                            field:      rowData => rowData.sm_updated_datetime ? <>
                                {rowData.sm_updated_by}<br/>
                                {clenyDate(rowData.sm_updated_datetime)}
                            </> : '-',
                            dataRef:    'sm_updated_by',
                            sizeToContent:  true,
                        },
                        {
                            actions: rowData => {
                                return _.filter([
                                    downloadMasterExcel && {
                                        name:     'Master Excel',
                                        icon:     icons.excel,
                                        onClick:  downloadMasterExcel
                                    },
                                    {name: 'View', icon: 'search', link: { pathname: '/stock/movement/' + rowData.sm_id }, disabled: moment(rowData.sm_date).diff(moment(),'days') > 0}
                                ], i=>!!i)
                            }
                        }
                    ]}
                    rows={_.orderBy(data, ['sm_date', 'sm_id'],['desc', 'desc'])}
                />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    </Grid>
)



class StockMovements extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => { this.getData(); }

    handleDateChange = field => e => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: moment(e).format('YYYY-MM-DD')
            }
        }); 
    }

    submitStockMovement = () => {
        API.post(`/stock/movement`, this.state.formData)
        .then( res => {this.getData();})
    }

    getData = () => {
        API.get(`/stock/movement/all`)
        .then( result => {
        	if(result.data.errors) { this.setState({formErrors: formatValidationErrors(result.data.errors) });
            } else {
            	let stockMovements = result.data;
                this.setState({
                    stockMovements,
                    isLoading: false
                });
            }
        })
    }

    downloadMasterExcel = row => {
        API.get(`/stock/movement/${row.sm_id}/masterExcel`)
        .then(res => {
            this.setState({
                csvDownload: {
                    data:      res.data,
                    filename: `MasterExcelParts_${row.sm_ref}.csv`,
                }
            }, ()=>{
                setTimeout(()=>{
                    document.getElementById('downloadMasterExcel').click();
                }, 100);
            })
        })
    }

    render() {

        const { stockMovements, csvDownload } = this.state;

        return (
            <Grid container >
                {!!csvDownload.filename && !!csvDownload.data &&
                    <CSVLink 
                        data={csvDownload.data} 
                        label={''} 
                        filename={csvDownload.filename}
                        className='hidden'
                        target='_blank'
                        id='downloadMasterExcel'
                    />
                }
                <Grid item style={{padding:'1em', width:'20%'}}>
                    <PaddedPaper style={{textAlign:'center'}}>
                        <AllIcon icon={icons.stockStock} size='xxlarge' />
                        <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Outstanding</Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>{_.filter(stockMovements, i=>!i.sm_deleted && !i.sm_submitted).length}</Typography>
                    </PaddedPaper>
                </Grid>
                <Grid item style={{padding:'1em', width:'20%'}}>
                    <PaddedPaper style={{textAlign:'center'}}>
                        <AllIcon icon={icons.due} size='xxlarge' />
                        <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Due</Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>{_.filter(stockMovements, i=>!i.sm_deleted && !i.sm_submitted && parseFloat(moment(i.sm_date).diff(moment().startOf('day'),'days')) === 0).length}</Typography>
                    </PaddedPaper>
                </Grid>
                <Grid item style={{padding:'1em', width:'20%'}}>
                    <PaddedPaper style={{textAlign:'center'}}>
                        <AllIcon icon={icons.overdue} size='xxlarge' />
                        <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Overdue</Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>{_.filter(stockMovements, i=>!i.sm_deleted && !i.sm_submitted && moment(i.sm_date).diff(moment().startOf('day'),'days') < 0).length}</Typography>
                    </PaddedPaper>
                </Grid>
                <Grid item style={{padding:'1em', width:'20%'}}>
                    <PaddedPaper style={{textAlign:'center'}}>
                        <AllIcon icon={icons.delete} size='xxlarge' />
                        <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Deleted</Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>{_.filter(stockMovements, i=>i.sm_deleted).length}</Typography>
                    </PaddedPaper>
                </Grid>
                <Grid item style={{padding:'1em', width:'20%'}}>
                    <PaddedPaper style={{textAlign:'center'}}>
                        <AllIcon icon={icons.tick} size='xxlarge' />
                        <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Completed</Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>{_.filter(stockMovements, i=>i.sm_submitted).length}</Typography>
                    </PaddedPaper>
                </Grid>
                <StockTable data={_.filter(stockMovements, i=>!i.sm_deleted && !i.sm_submitted && parseFloat(moment(i.sm_date).diff(moment().startOf('day'),'days')) === 0)} label={`Due (${_.filter(stockMovements, i=>!i.sm_deleted && !i.sm_submitted && parseFloat(moment(i.sm_date).diff(moment().startOf('day'),'days')) === 0).length})`} icon={icons.due} />
                <StockTable data={_.filter(stockMovements, i=>!i.sm_deleted && !i.sm_submitted && moment(i.sm_date).diff(moment().startOf('day'),'days') < 0)}  label={`Overdue (${_.filter(stockMovements, i=>!i.sm_deleted && !i.sm_submitted && moment(i.sm_date).diff(moment().startOf('day'),'days') < 0).length})`} icon={icons.overdue} />
                <StockTable data={_.filter(stockMovements, i=>i.sm_deleted)}    label={`Deleted (${_.filter(stockMovements, i=>i.sm_deleted).length})`}   icon={icons.delete} />
                <StockTable downloadMasterExcel={this.downloadMasterExcel} data={_.filter(stockMovements, i=>i.sm_submitted)}  label={`Completed (${_.filter(stockMovements, i=>i.sm_submitted).length})`} icon={icons.tick} />
            </Grid>
        );
    }
}



export default StockMovements;
