import { Grid, TextField, Typography, InputAdornment, Button } from "@material-ui/core";
import API from "API";
import AllIcon from "Components/Common/Icons/AllIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import {
  clearPageState,
  getInitialState,
  hasPageState,
  savePageState,
} from "Functions/StatePersistenceFunctions";
import IconHelper from "Helpers/IconHelper";
import _ from "lodash";
import React from "react";
import SectionBox from "./SectionBox";


const initialState = () => ({
  search: "",
  outstandingTasks: {},
  isLoading: true,
  countdown: 15*60
});

class Outstanding extends React.Component {
  constructor(props) {
    super(props);
    this.clearPageState = clearPageState.bind(this);
    this.getInitialState = getInitialState.bind(this);
    this.hasPageState = hasPageState.bind(this);
    this.savePageState = savePageState.bind(this);
    this.persistenceId = "Dashboard:Outstanding";
    this.state = this.getInitialState(initialState());

    this.timeout = null;
  }

    componentDidMount(){
      if (!this.hasPageState()) {
        this.loadReports();
      } else {
        clearTimeout(this.timeout);
        this.addTimer();
      }
    }

    componentWillUnmount() {
      clearTimeout(this.timeout);
    }

    addTimer = () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(()=>this.countdown(15*60), 1000);
    }
  
    countdown = (num) => {
      if (num > 0) {
        this.setState({
          countdown: num
        }, ()=> {
          this.timeout = setTimeout(() => this.countdown(num - 1), 1000)
        });
      } else {
        this.setState({
          countdown: 0
        }, ()=>this.loadReports(false));
      }
    }

  //   loadReports = () => {
  //       this.setState({
  //           isLoading: true
  //       }, ()=> {
  //           API.get(`/dashboard`, {params: {
  //               section: 'Outstanding'
  //           }}).then(res => {
  //               this.setState({
  //                   outstandingTasks: res.data,
  //                   isLoading: false
  //               })
  //           })
  //       })
  // }   

  loadReports = (withLoading = true) => {
    clearTimeout(this.timeout);
    this.setState(
      {
        isLoading: withLoading,
        countdown: 0
      },
      () => {
        API.get(`/dashboard`, {
          params: {
            section: "Outstanding",
          },
        }).then((res) => {
          let maxValue = _.sumBy(_.values(res.data), i => _.sumBy(i, j => _.isInteger(j.d) ? parseInt(j.d) : 0));
          let outstandingTasks = _.map(_.keys(res.data), (title) => ({
            data: res.data[title],
            order: (_.sumBy(res.data[title], j => _.isInteger(j.d) ? parseInt(j.d) : 0) / maxValue) * 100,
            tasks:  _.sumBy(res.data[title], j => _.isInteger(j.d) ? parseInt(j.d) : 0),
            title: title,
          }));
          this.setState({
            outstandingTasks: _.map(_.orderBy(outstandingTasks, ['order'], ['desc']), (i,index) => ({...i, index})),
            isLoading: false,
            countdown:  15*60
          }, ()=> {
            this.addTimer()
         })
        });
      }
    );
  };

  render() {
    const { isLoading, outstandingTasks, search, defaultOpen } = this.state;

    if (isLoading) {
      return <LoadingCircle />;
    }

    const loggedInStaff =
      this.props.loggedInStaff !== undefined
        ? this.props.loggedInStaff.firstName +
          " " +
          this.props.loggedInStaff.lastName
        : "";
	
	const filteredSearch = (search) ? (
		_.map(_.filter(outstandingTasks, i => 
			(i.title.toLowerCase().includes(search.toLowerCase()) || _.find(i.data, j => j.n.toLowerCase().includes(search.toLowerCase())))
		), i => {
			if (i.title.toLowerCase().includes(search.toLowerCase())) {
				return i;
			}
			else {
				return {
					...i,
					data: _.filter(i.data, (j) => j.n.toLowerCase().includes(search.toLowerCase()))
				}
			}
		})
	) : outstandingTasks;

        return (
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container style={{alignItems: 'center'}}>
                  <Grid item xs={6} style={{marginRight: 'auto'}}>
                      <Typography variant="h6" gutterBottom>
                          Welcome <b>{loggedInStaff}</b>, you have <b>{_.sumBy(outstandingTasks, i => i.tasks)}</b> outstanding tasks
                      </Typography>
                  </Grid>
                  <Grid item >
                    <AllIcon 
                        icon={IconHelper.home}
                        heavy
                        onClick={this.props.onBack}
                        noMargin
                    />
                    <AllIcon 
                        icon={IconHelper.refresh}
                        heavy
                        onClick={()=>this.loadReports(false)}
                        noMargin
                    />
                  </Grid>
                  <Grid item>
							{this.state.countdown > 0 &&
								<>
									<Typography variant="body1" style={{width: 80}}>
										in {parseInt(this.state.countdown/60)}:{`${parseInt((parseFloat(this.state.countdown/60) - parseInt(this.state.countdown/60)) * 60)}`.padStart(2,'0')}
									</Typography>
								</>
							}
							{this.state.countdown < 1 &&
								<>
									<Typography variant="body1" style={{width: 110}}>
										Refreshing ...
									</Typography>
								</>
							}
						</Grid>
                </Grid>
                </Grid>
                <Grid item xs={9}>
                    <TextField 
						value={search}
                      	placeholder="Search..."
                      	onChange={e => this.setState({search: e.target.value})}
						variant="outlined"
						style={{width: 300}}
						inputProps={{
							style: {
								padding: '7px 9px'
							},
						}}
						InputProps={{
							endAdornment: (
								<>
									{search &&
										<InputAdornment position="end">
											<AllIcon icon={IconHelper.refresh} onClick={() => this.setState({search: ''})} noMargin noPadding size={15} />
										</InputAdornment>
									}
								</>
							),
						}}
                    />
                </Grid>
                <Grid item xs={3} style={{textAlign: 'right'}}>
                    <Button
                      variant={defaultOpen ? 'contained' : 'outlined'}
                      color={defaultOpen ? 'primary' : 'default'}
                      onClick={() => this.setState({defaultOpen: !defaultOpen})}
                    >Open All</Button>
                  </Grid>
                {_.map(_.orderBy(filteredSearch, ['order'], ['desc']), ({tasks, data, title, index}) => <Grid xs={12} md={6} lg={4}> <SectionBox defaultOpen={!!defaultOpen || !!search} itemsLength={outstandingTasks.length} title={title} data={data} tasks={tasks} index={index}/></Grid>)}
            </Grid>
        )
    }
  }

export default Outstanding;