import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { S3_BUCKET_URL } from 'Constants';

const getFileSize = size => {
    var i = Math.floor( Math.log(size) / Math.log(1024) );
    return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

const ThreadFile = ({classes, fileName, fileSize, fileUrl, handleClearFile}) => (
    <Box mt={1}>
        <Grid container alignItems='center' spacing={1}>
            <Grid item xs>
                <Paper className={classes.filePaper}>
                    <Box p={2}>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item xs align='right'>
                                <Typography variant="caption" component="div" className={classes.fileDetails} gutterBottom>
                                    {fileName}
                                </Typography>
                                <Typography variant="caption" component="div" className={classes.fileDetails} color="textSecondary">
                                    {getFileSize(fileSize)}
                                </Typography>
                            </Grid>
                            {!handleClearFile && (
                                <Grid item align='right'>
                                    <IconButton
                                        onClick={() => !handleClearFile && window.open(`${S3_BUCKET_URL}${fileUrl}`)}
                                    >
                                        <FAIcon type="light" icon="cloud-download" buttonPrimary noMargin />
                                    </IconButton>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
            {handleClearFile && (
                <Grid item>
                    <IconButton onClick={handleClearFile}>
                        <FAIcon type="light" icon="times" noMargin button />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    </Box>
)

export default ThreadFile;