import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { Button, ButtonGroup, FormControl, Grid } from '@material-ui/core';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import Textarea      from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog }        from 'Actions/Dialog/Dialog';
import AutoCompleteSelect     from 'Components/Common/Selects/AutoCompleteSelect';
import DragFileInputMulti from 'Components/Common/Inputs/DragFileInputMulti';

const initialState = {
    isLoading:    true,
    stage:        {},
    formErrors:   {},
    staffList:    [],
    formData:     {
        resp:       'approve',
        file:       null,
        notes:      '',
        supervisor: 0,
    }
}

class FirstOffCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            stage: this.props.stage,
        };
    }

    componentDidMount() {
        this.getStaff();
    }

    getStaff = () => {
        API.get('/staff/all', {params: { appearOn: 1 }})
        .then(res => {
            this.setState({
                isLoading: false,
                staffList: _.map(_.filter(res.data, i => {
                    if (i.staff_status === 'inactive') return false;
                    return this.props.loggedInStaff.id !== i.staff_id;
                }), i => _.assign({
                    value: i.staff_id,
                    label: i.full_name,
                })),
            })
        })
    }

    handleChange = field => value => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: value
            }
        });
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files : event.target.files;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: [...this.state.formData.file ? this.state.formData.file : [], ...file]
            }
        });
    }

    handleClearFile = idx => {
        let file = _.filter(this.state.formData.file, (i, index) => index !== idx);
        this.setState({
            formData: {
                ...this.state.formData,
                file: file.length > 0 ? file : null,
            }
        });
    }

    handleNoteChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                notes: e.target.value,
            }
        });
    }

    submit = () => {
        this.setState({ isLoading: true }, () => {
            let newFormData = new FormData();
            let formData = this.state.formData;
            Object.keys(formData).forEach(key => {if (key === 'file') {
                _.each (formData[key], f => newFormData.append('file[]', f));
            } else {
                newFormData.append(key, formData[key]);
            }});
            API.post(`/worksOrders/${this.props.worksOrder.wo_id}/build/${this.props.stage.wobs_wob_id}/${this.props.stage.wobs_id}/firstCheck/${formData.resp}`, newFormData)
            .then(res => {
                if(res.data.errors) {           
                    this.setState({ formErrors: formatValidationErrors(res.data.errors), isLoading: false });
                } else {
                    this.props.callBack()       
                }
            })
        });
    }

    handleSelectChange = field => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: e && e.value
            }
        });
    }

    render() {

        const { isLoading, parts, stage, formData, formErrors, writeOffList, writeOffs, staffList } = this.state;

        if (isLoading) return ( <LoadingCircle /> )

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <ButtonGroup fullWidth>
                        <Button variant="contained" onClick={()=>this.handleChange('resp')('approve')} style={{color: colors.white, backgroundColor: formData.resp === 'decline' ? colors.disabled : colors.green}} >Approved</Button>
                        <Button variant="contained" onClick={()=>this.handleChange('resp')('decline')} style={{color: colors.white, backgroundColor: formData.resp === 'approve' ? colors.disabled : colors.red}}   >Declined</Button>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                    <DragFileInputMulti
                        id="fop-file"
                        name="file"
                        label="Photo Of Build *"
                        file={formData.file}
                        error={formErrors && ( formErrors['firstOffProof'] || formErrors['file'] ) }
                        errorText={formErrors &&  ( formErrors['firstOffProof'] || formErrors['file'] )}
                        onChange={this.handleFileChange}
                        cancelOnClick={this.handleClearFile}
                        emptyText='No photo taken'
                        placeholder='Photo Of Build'
                        capture='environment'
                    />
                </Grid>
                <Grid item xs={12}>
                    <Textarea
                        id="notesText"
                        name="notes"
                        label={formData.resp === 'decline' ? "Decline Reason *" : "Notes/Feedback *"}
                        value={formData.notes}
                        rows={5}
                        error={formErrors && formErrors['notes']}
                        onChange={this.handleNoteChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <AutoCompleteSelect
                            options={staffList}
                            label="First Off Checked By *"
                            value={formData.supervisor}
                            onChange={this.handleSelectChange('supervisor')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button variant='outlined' onClick={this.props.closeDialog} >Cancel</Button>
                    <Button 
                        variant='contained' 
                        style={{
                            color:           colors.white,
                            backgroundColor: (!formData.resp || !formData.supervisor || (!formData.notes || formData.notes == '') || !(formData.file && formData.file.length > 0)) ? colors.disabled : (formData.resp === 'decline' ? colors.red : colors.green),
                        }}
                        disabled={!formData.resp || !formData.supervisor || (!formData.notes || formData.notes == '') || !(formData.file && formData.file.length > 0)}
                        onClick={this.submit}
                    >{formData.resp === 'decline' ? 'Decline' : 'Approve'}</Button>
                </Grid>
            </Grid>
        )
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff
    };
}


function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
        closeDialog:        ()                        => dispatch( closeDialog() ) ,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FirstOffCheck);