import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import {
    Button,
    TextField,
    Typography,
    IconButton, 
    Radio,
    RadioGroup,
    FormControl,
    Checkbox,
    FormControlLabel,
    Box,
    Grid,
    TableContainer, Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { handleDateChange, handleTimeChange } from 'Functions/FormFunctions';
import { base64ImgtoFile } from 'Functions/MiscFunctions';
import TextArea     from 'Components/Common/Inputs/Textarea';
import DatePicker   from 'Components/Common/DatePickers/DatePicker';
import TimePicker   from 'Components/Common/DatePickers/TimePicker';
import FAIcon       from 'Components/Common/Icons/FontAwesome/FAIcon';
import DragFileInputMulti from 'Components/Common/Inputs/DragFileInputMulti';
import SignatureCanvas from "react-signature-canvas";
import { CLENAWARE_RED, CLENAWARE_GREEN } from 'Constants'
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import DateTimePicker from 'Components/Common/DatePickers/DateTimePicker';
import InformationForm from 'Components/Common/Forms/FormBuilder/InformationForm';
import uuidv4 from 'uuid/v4';

const initialState = () => ({
    formData: {
        value: null,
        files: '',
        jobTimeArrival: null,
        jobTimeDeparture: null,
        jobTimeTotal: null,
        stringValue: '',
        arrayValue: []
    },
    tableCellEditingId: null,
    tableRow: [],
    partsList: [],
    informationSelectorArray: [],
    keys: {
        informationForm: uuidv4()
    }
});
class Elements extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleDateChange = handleDateChange.bind(this);
        this.handleTimeChange = handleTimeChange.bind(this);
    }

    componentDidMount() {
        this.loadComponentData();
    } 

    componentDidUpdate(prevProps) {
        const { element } = this.props;

        if(prevProps?.element !== element){
            this.loadComponentData();
            this.setState({
                keys: {
                    ...this.state.keys,
                    informationForm: uuidv4()
                }
            });
        }
    }

    loadComponentData = () => {
        const { element } = this.props;

        switch(element?.elType){
            case 'Parts Table':
            case 'Dynamic Table':
                this.initializeTableRow();
                this.getPartsList();
            break;
            case 'Information Selector':
                this.getInfoSelectorData();
            break;
        }

        // Set placeholder text
        if(element?.elConfig?.placeholder){
            this.setState({
                formData:{
                    value: element?.elConfig?.placeholder
                }
            })
        }
    }

    initializeTableRow = () => {
        const { element } = this.props;
        // Loop through columns to initialize row columns
        let tableRow = [];
        _.map(element?.elConfig?.data?.columns, (row, idx) => { 
            tableRow.push({ column: row?.name, type: row?.type, value: ''});
        });

        this.setState({
            tableRow,
            formData: {
                ...this.state.formData,
                arrayValue: []
            }
        })
    }

    getPartsList = () => {
        const { dataSets } = this.props;
        /*
        * Element - Parts Table
        * Re-factor the data for select in the parts table
        */
        if(dataSets?.parts){

            let partsList = _.map(dataSets?.parts, (el) => {
                return _.assign({
                    value: el.id,
                    label: el.partNumber,
                    desc: el.partDesc,
                });
            });
            
            this.setState({
                partsList
            });
        }
    }

    getInfoSelectorData = () => {
        /*
        * Element - Information Selector
        * Re-factor the data depending on the selector type
        */
        const { element, dataSets } = this.props;

        let infoSelectorArray = [];
        switch(element?.elConfig?.data?.value){
            case 'parts':
                if(dataSets?.parts){
                    _.map(dataSets?.parts, (el) => {
                        infoSelectorArray.push({
                            value: el.id,
                            label: el.partNumber + ' - ' + el.partDesc
                        });
                    });
                }
            break;
            case 'customerAddress':
                if(dataSets?.customerAddresses){
                    
                    // Group addresses by address type
                    const groupedAddresses = {};
                    _.map(dataSets?.customerAddresses, (address) => {
                        const { type, ...rest } = address;
                        if (!groupedAddresses[type]) {
                        groupedAddresses[type] = [];
                        }
                        groupedAddresses[type].push(rest);
                    });

                    Object.keys(groupedAddresses).map(addressType => {
                        infoSelectorArray.push({
                            label: addressType,
                            options: _.map(groupedAddresses[addressType], (el) => ({
                                value: el.id,
                                label: `${el.name} - ${el.line1}, ${el.town}, ${el.postcode}`
                            }))
                        })
                    });
                }
            break;
            case 'customerContact':
                if(dataSets?.customerContacts){
                    _.map(dataSets?.customerContacts, (el) => {
                        infoSelectorArray.push({
                            value: el.id,
                            label: `${el.name} - ${el.email} (${el.phone})`,
                        });
                    })
                }
            break;
            case 'associatedParts':
                if(dataSets?.associatedParts){
                    infoSelectorArray = dataSets?.associatedParts
                }
            break;
            case 'engineer':
                if(dataSets?.engineer){

                    // Group engineers by company
                    const groupedEngineers = {};
                    _.map(dataSets?.engineer, (engineer) => {
                        const { company, ...rest } = engineer;
                        if (!groupedEngineers[company]) {
                        groupedEngineers[company] = [];
                        }
                        groupedEngineers[company].push(rest);
                    });

                    Object.keys(groupedEngineers).map(company => {
                        infoSelectorArray.push({
                            label: company,
                            options: _.map(groupedEngineers[company], (el) => ({
                                value: el.id,
                                label: el.nm,
                            }))
                        })
                    });
                }
            break;
        }

        this.setState({
            informationSelectorArray: infoSelectorArray
        });

    }

    handleJobTimeChange = (name, time) => {

        let newTime = time && moment(time).format('HH:mm')

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: newTime
            }
        }, () => {
            let { formData } = this.state;
            let arrival     = formData?.jobTimeArrival ? moment(formData?.jobTimeArrival, 'HH:mm:ss') : null;
            let departure   = formData?.jobTimeDeparture ? moment(formData?.jobTimeDeparture, 'HH:mm:ss') : null;
            
            // Calculate total time and display in time format
            let total           = arrival && departure ? moment.duration(departure.diff(arrival)) : null;
            let totalHours      = total ? Math.floor(total.asHours()) : 0;
            let totalMinutes    = total ? total.minutes() : 0;
            let formattedTotal  = moment().startOf('day').add(totalHours, 'hours').add(totalMinutes, 'minutes');

            this.setState({
                formData: {
                    ...this.state.formData,
                    jobTimeTotal: formattedTotal
                }
            });
        });
    }

    handleChange = (val) => {
        this.setState({
            formData:{
                value: val
            }
        }, () => {
            if(this.props.handleChange){
                this.props.handleChange(val);
            }
        })
    }
    handleSelectChange = (fieldName, selectedOption) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, () => {
            if(this.props.handleChange){
                this.props.handleSelectChange(selectedOption);
            }
        });
    }

    handleArrayChange = (name) => {
        const arrayValue = this.state.formData.arrayValue;
        
        let inArray = false;

        /* eslint-disable-next-line */
        inArray = _.find(arrayValue, el => el.checked == name);

        if(inArray){
            arrayValue.splice(arrayValue.indexOf(inArray), 1);
        } else {
            arrayValue.push({
                checked: name
            })
        }

        this.setState({
            formData:{
                ...this.state.formData,
                arrayValue
            }
        }, () => {
            if(this.props.handleChange){
                this.props.handleChange(this.state.formData.arrayValue);
            }
        });
    }

    handleFileChange = (drop, name, event) => {
        
        let newFiles        = drop === true ? event.dataTransfer.files : event.target.files;
        let existingFiles   = this.state.formData.files;
        let allFiles = Array.prototype.slice.call(existingFiles).concat(Array.prototype.slice.call(newFiles))

        this.setState({
            formData: {
                ...this.state.formData,
                files: allFiles
            },
        }, () => {
            if(this.props.handleChange){
                //this.props.handleChange(this.state.formData.value, true);
            }
        });
    }

    clearUploads = idx => {

        let currentFiles = Array.from(this.state.formData.files);
        currentFiles.splice(idx, 1);
        if(currentFiles.length === 0) currentFiles = '';

        this.setState({
            formData:{
                ...this.state.formData,
                files: currentFiles
            }
        });
    }

    saveSignature = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                stringValue: base64ImgtoFile(
                    this.signatureCanvas.getTrimmedCanvas().toDataURL("image/png")
                ),
            },
        },
        () => {
            this.signatureCanvas && this.signatureCanvas.clear();
        });
    };

    clearSignature = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                stringValue: ''
            },
        },
        () => {
            this.signatureCanvas && this.signatureCanvas.clear();
        });
    };

    addTableRow = () => {
        const { arrayValue } = this.state.formData;
        const { tableRow } = this.state;

        this.setState({
            formData: {
                ...this.state.formData,
                arrayValue: [...arrayValue, tableRow],
            },
        });
    };
    removeTableRow = (rowIdx) => {
        const { arrayValue } = this.state.formData;
        arrayValue.splice(rowIdx, 1);
        this.setState({
            formData: { 
                ...this.state.formData,
                arrayValue,
            },
        });
    };
    tableCellClick = (cellUnique) => {
        this.setState({ tableCellEditingId: cellUnique });
    };
    handleCellValueChange = (val, rowIdx, columnIdx) => {

        // Create a new copy of the arrayValue array
        const updatedTableRows = [...this.state.formData.arrayValue];

        // Create a new copy of the row being edited
        const updatedRow = { ...updatedTableRows[rowIdx] };

        // Create a new copy of the column being edited
        const updatedCell = { ...updatedRow[columnIdx] };

        // Update the cell value
        updatedCell.value = val;

        // Update the updatedTableRows array with the modified cell
        updatedRow[columnIdx] = updatedCell;
        updatedTableRows[rowIdx] = updatedRow;
        
        this.setState({
            formData: {
                ...this.state.formData,
                arrayValue: updatedTableRows
            },
        });
    };

    render() {
        const { element, eIdx } = this.props;
        const { formData, tableCellEditingId, partsList, informationSelectorArray, keys } = this.state;

        return (
            <>
            {element?.elType === 'Dropdown' && (
                <FormControl margin="none" fullWidth>
                    <AutoCompleteSelect
                        name={"customDrop"+eIdx}
                        options={element?.elConfig?.options || []}
                        noClear
                        margin="none"
                        noDefaultSort
                        onChange={(v) => this.handleSelectChange("customDrop"+eIdx, v)}
                    />
                </FormControl>
            )}
            {element?.elType === 'Text Field' && (
                <TextField
                    margin="none" 
                    fullWidth
                    variant="outlined"
                    value={formData.value}
                    onChange={(e) => this.handleChange(e.target.value)}
                />
            )}
            {element?.elType === 'Number Input' && (
                <TextField
                    margin="none" 
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={formData.value}
                    onChange={(e) => this.handleChange(e.target.value)}
                />
            )}
            {element?.elType === 'Text Area' && (
                <TextArea
                    margin="none"
                    variant="outlined"
                    rows={3}
                    value={formData?.value}
                    onChange={(e) => this.handleChange(e.target.value)}
                /> 
            )}
            {element?.elType === 'Checkbox' && (
                <>
                    {_.map(element?.elConfig?.options, (option, oIdx) => (
                        <FormControlLabel
                            key={oIdx}
                            control={
                                <Checkbox 
                                    color="primary"
                                    name={option.value}
                                    checked={_.find(formData.arrayValue, el => el.checked === option.value) ? true : false}
                                    onClick={(e) => this.handleArrayChange(option.value)}
                                />
                            }
                            label={option.label}
                        />
                    ))}
                    <br />
                </>
            )}
            {element?.elType === 'Radio' && (
                <>
                <FormControl component="fieldset">
                    <RadioGroup row>
                        {_.map(element?.elConfig?.options, (option, rIdx) => (
                            <FormControlLabel
                                key={rIdx}
                                control={<Radio color="primary" />}
                                label={option.label}
                                labelPlacement="end"
                                margin="none"
                                checked={formData.value === option.value}
                                onChange={(e) => this.handleChange(option.value)}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
                <br />
                </>
            )}
            {element?.elType === 'Date' && (
                <Grid container>
                    <Grid item xs={4}>
                        <DatePicker
                            value={formData?.value}
                            type="date"
                            autoOk={true}
                            onChange={date => this.handleDateChange('value', date, 'YYYY-MM-DD')}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            )}
            {element?.elType === 'Time' && (
                <Grid container>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TimePicker 
                                margin="normal"
                                value={formData.value ? moment(formData?.value, 'HH:mm:ss').format() : null}
                                onChange={time => this.handleTimeChange('value', time)}
                                autoOk={true}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            )}
            {element?.elType === 'Date / Time' && (
                <Grid container>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <DateTimePicker
                                label="" 
                                type='datetime'
                                value={formData?.value}
                                onChange={date => this.handleDateChange('value', date, 'YYYY-MM-DD H:mm:ss')}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            )}
            {element?.elType === 'Job Time' && (
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TimePicker 
                                label="Arrival Time (24 hour)"
                                margin="normal"
                                value={formData.jobTimeArrival ? moment(formData?.jobTimeArrival, 'HH:mm:ss').format() : null}
                                onChange={time => this.handleJobTimeChange('jobTimeArrival', time)}
                                autoOk={true}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TimePicker 
                                label="Departure Time (24 hour)"
                                margin="normal"
                                value={formData.jobTimeDeparture ? moment(formData?.jobTimeDeparture, 'HH:mm:ss').format() : null}
                                onChange={time => this.handleJobTimeChange('jobTimeDeparture', time)}
                                autoOk={true}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TimePicker 
                                label="Total Time"
                                margin="normal"
                                value={formData.jobTimeTotal ? moment(formData?.jobTimeTotal, 'HH:mm').format() : null}
                                autoOk={true}
                                disabled
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            )}
            {element?.elType === 'File Upload' && (
                <>
                <DragFileInputMulti
                    id="files"
                    name="files[]"
                    file={formData.files}
                    type="file"
                    onChange={this.handleFileChange}
                    cancelOnClick={this.clearUploads}
                    emptyText='No files uploaded'
                    noMargin
                />
                </>
            )}
            {element?.elType === 'Signature' && (
                <Grid container spacing={3} alignItems="end">
                    <Grid item xs={12}>

                        {!formData.stringValue && (
                            <>
                            <Box>
                                <SignatureCanvas
                                    ref={(ref) => {
                                        this.signatureCanvas = ref;
                                    }}
                                    clearOnResize={false}
                                    canvasProps={{
                                        className: "sigCanvas",
                                        style: {
                                            border: "1px solid rgba(0, 0, 0, 0.2)",
                                            width: '100%',
                                            height: '100%'
                                        },
                                    }}
                                />
                                <Box textAlign="center" style={{backgroundColor: 'rgb(241, 241, 241)', marginTop: -5, color: 'red'}}>
                                    <IconButton onClick={this.clearSignature} size="small">
                                        <FAIcon type="light" icon="undo" button noMargin size={14} />
                                    </IconButton>
                                    {/* <IconButton onClick={this.saveSignature}>
                                        <FAIcon type="light" icon="check" color='green' button noMargin />
                                    </IconButton> */}
                                </Box>
                            </Box>
                            </>
                        ) || (
                            <>
                            <img
                                src={URL.createObjectURL(formData.stringValue)}
                                width={120}
                                alt="Signature"
                                maxHeight={120}
                                style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
                            />
                            <Box mt={3} textAlign="center">
                                <IconButton onClick={this.clearSignature}>
                                    <FAIcon type="light" icon="times" button noMargin />
                                </IconButton>
                            </Box>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} item>
                            <Grid item xs={8}>
                                <TextField
                                    label="Name"
                                    margin="none" 
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DatePicker
                                    label="Date"
                                    value={formData?.value}
                                    type="date"
                                    autoOk={true}
                                    margin="none"
                                    onChange={date => this.handleDateChange('value', date, 'YYYY-MM-DD')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {element?.elType === 'Information' && (
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell width="30%" style={{border: '1px solid rgba(224, 224, 224, 1)'}}>
                                    <b>{element?.elConfig?.data?.text ?? element?.elConfig?.data?.name}</b>
                            </TableCell>
                            <TableCell  style={{border: '1px solid rgba(224, 224, 224, 1)'}}>
                                <i>{"** "+ element?.elConfig?.data?.name +" **"}</i>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    
                </Table>
            )}
            {element?.elType === 'Information Selector' && (
                
                <FormControl margin="none" fullWidth>
                    <AutoCompleteSelect
                        name={"value"}
                        options={element?.elConfig?.data?.name === 'associatedParts' ? informationSelectorArray : informationSelectorArray}
                        noClear
                        margin="none"
                        noDefaultSort
                        onChange={(v) => this.handleSelectChange("value", v)}
                        isGrouped={element?.elConfig?.data?.name === 'customerAddress' ? true : false}
                    />
                </FormControl>
            )}
            {(element?.elType === 'Parts Table' || element?.elType === 'Dynamic Table') && (
                <>
                <TableContainer>
                    <Table size="small">
                        <TableHead style={{backgroundColor: '#fafafa'}}>
                            <TableRow>
                                {_.map(element?.elConfig?.data?.columns, (v, idx) => (
                                    <TableCell key={idx} style={{border: '1px solid rgba(224, 224, 224, 1)', width:'2%', whiteSpace: 'nowrap'}}>
                                        {v?.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(formData?.arrayValue, (row, rowIdx) => (
                                <TableRow key={rowIdx}>
                                    {_.map(row, (column, columnIdx) => (
                                        <>
                                        {column?.type === 'part' ? (
                                            <TableCell key={columnIdx} style={{border: '1px solid rgba(224, 224, 224, 1)', cursor: 'pointer'}}>
                                                <FormControl margin="none" fullWidth>
                                                    <AutoCompleteSelect
                                                        options={partsList}
                                                        noClear
                                                        margin="none"
                                                        noDefaultSort
                                                        value={column?.value}
                                                        onChange={(selectedOption) => this.handleCellValueChange(selectedOption?.value, rowIdx, columnIdx)}
                                                        formatOptionLabel={({label, desc}) => (
                                                            <>
                                                                <Typography variant="subtitle2">{label}</Typography>
                                                                <Typography variant="caption">{desc}</Typography>
                                                            </>
                                                        )}
                                                    />
                                                </FormControl>
                                            </TableCell>
                                        )
                                        :
                                        (
                                            <TableCell key={columnIdx} style={{border: '1px solid rgba(224, 224, 224, 1)', cursor: 'pointer'}} onClick={() => this.tableCellClick(`${rowIdx}:${columnIdx}`)}>
                                                {tableCellEditingId === `${rowIdx}:${columnIdx}` ? (
                                                    <TextField
                                                        value={row.name}
                                                        onChange={event => this.handleCellValueChange(event.target.value, rowIdx, columnIdx)}
                                                    />
                                                ) : (
                                                    column?.value ?? <>&nbsp;</>
                                                )}
                                            </TableCell>
                                        )}
                                        </>
                                    ))}
                                    <TableCell style={{border: '1px solid rgba(224, 224, 224, 1)', width: '1%'}} align='center'>
                                        <IconButton
                                            onClick={() => this.removeTableRow(rowIdx)}
                                            size="small"
                                        >
                                            <FALightIcon icon="times" button size={14} color={CLENAWARE_RED} noMargin />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    onClick={this.addTableRow}
                    variant="text"
                >
                    <FALightIcon icon='plus' button size={15} color={CLENAWARE_GREEN} />
                    Add Row
                </Button>
                <br />
                </>
            )}
            {(element?.elType === 'Static Table') && (
                <>
                <TableContainer>
                    <Table size="small">
                        <TableHead style={{backgroundColor: '#fafafa'}}>
                            <TableRow>
                                {element?.elConfig?.data?.rows?.length > 0 && (
                                    <TableCell style={{border: '1px solid rgba(224, 224, 224, 1)', width:'2%', whiteSpace: 'nowrap'}}>&nbsp;</TableCell>
                                )}
                                {_.map(element?.elConfig?.data?.columns, (v, idx) => (
                                    <TableCell key={idx} style={{border: '1px solid rgba(224, 224, 224, 1)', width:'2%', whiteSpace: 'nowrap'}}>
                                        {v?.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {_.map(element?.elConfig?.data?.rows, (row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    <TableCell style={{border: '1px solid rgba(224, 224, 224, 1)', width:'2%', whiteSpace: 'nowrap'}}>
                                        {row?.name}
                                    </TableCell>
                                    {_.map(element?.elConfig?.data?.columns, (column, columnIdx) => (
                                        <TableCell key={columnIdx} style={{border: '1px solid rgba(224, 224, 224, 1)', width:'2%', whiteSpace: 'nowrap'}}>
                                            &nbsp;
                                        </TableCell>

                                    ))}
                                </TableRow>
                                
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                </>
            )}
            {(element?.elType === 'General Information') && (
                <InformationForm 
                    key={keys?.informationForm}
                    data={element?.elConfig?.data}
                    readOnly={true}
                    type={'General Information'}
                    hideClose={true}
                />
            )}
            </>
        )
    }
}

export default Elements;