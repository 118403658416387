import React, {Component}  from 'react';
import { withStyles } from '@material-ui/core/styles';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import API from 'API';
import _ from 'lodash';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { Link } from 'react-router-dom';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import { Dialog, DialogActions, DialogContent, Paper, IconButton, Tooltip, CardContent, CardActionArea, Card, FormControl, Grid, Typography, Button } from '@material-ui/core';
import { downloadS3File } from 'Functions/MiscFunctions';

import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';
import { colors } from 'Helpers/ColourHelper';

const initialState = () => ({
    part: '',
    tileData: [],
    partList: [],
    parts: {},
    imagesLoading: false,
    accessUpdatePartImage: false,
    dialog: false,
    dialogFile: '',
})

const styles = theme => ({
    overlay: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2)
    },
    icons: {
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

class PartImageSearch extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Library:partImageSearch';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        !this.hasPageState() && this.loadComponentData();
    }

    loadComponentData = () => {
        this.getParts();
        this.getAccess();
        this.getEditableImages();
    }

    getParts = () => {
        API.get('/parts/withImages')
        .then((result) => {
            let partList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description
                });
            });
            this.setState({
                partList: partList,
                parts: result.data
            });
        })
    }

    getAccess = () => {
        API.get('/staff/my/access/check/update-part-image')
        .then((res) =>  {
            this.setState({
                ...this.state,
                accessUpdatePartImage: (res.data && res.data.has_access) || false
            });
        })
    }

    getEditableImages = () => {
        let partId = this.state.part
        if(partId){
            this.setState({
                imagesLoading: true
            }, () => {
                let tileData = [];
                API.get(`/library/parts/${partId}`)
                .then((result) => {
                    result.data.forEach(val => {
                        let imageFile = _.find(val.image_files.files, {'library_type': 'Part Image'});
                        let imageFileRaw = _.find(val.image_files.files, {'library_type': 'Part Image File'});
                        let imageFile3D = _.filter(val.image_files.files, {'library_type': 'Part 3D Image File'});
                        let imageFileWeb3D = _.find(val.image_files.files, {'library_type': 'Part Web 3D Image File'});

                        tileData.push({
                            fileUrl: val.library_file_url,
                            imgRef: val.library_image_ref,
                            partId: partId,
                            version: val.library_part_version,
                            default: imageFile && imageFile.library_default_image,

                            disableImage: imageFile ? false : true,
                            disableImageRaw: imageFileRaw ? false : true,
                            disableImage3D: imageFile3D.length > 0 ? false : true,
                            disableImageWeb3D: imageFileWeb3D ? false : true,

                            imageUrl: imageFile,
                            imageRawUrl: imageFileRaw,
                            image3DUrls: imageFile3D,
                            imageWeb3DUrl: imageFileWeb3D,
                        });
                    });

                    tileData = _.orderBy(tileData, 'default', 'desc');

                    this.setState({
                        tileData: tileData,
                        imagesLoading: false
                    },()=> { 
                        this.savePageState()
                    });
                })
            })
        }
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        },
        () => {
            if(fieldName === 'part' && selectedOption){
                this.getEditableImages();
            } 
            else {
                this.setState({
                    tileData: []
                })
            }
        });
    }

    delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    downloadFile = data => async e => {
        if (Array.isArray(data)) {
          for (const files of data) {
            await downloadS3File(files.file);
            await this.delay(1000);
          }
        } else {
          downloadS3File(data.file);
        }
    };

    loadImage = (file) => {
        this.setState({ 
            dialog: true,
            dialogFile: file
        });
    };
    
    handleDialogClose = () => {
        this.setState({ 
            dialog: false,
            dialogFile: ''
        });
    };
       
    render() {
        const { imagesLoading, accessUpdatePartImage, part } = this.state;
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Part Image Search
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="h6">
                                    Select Part
                                </Typography>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.partList} 
                                        label='Part *'
                                        value={part}
                                        onChange={this.handleSelectChange('part')}
                                    />
                                </FormControl>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3} alignItems="center" alignContent="center">
                        {imagesLoading === true ?
                            <Grid item xs={12} lg={6}>
                                <Paper>
                                    <LoadingCircle />
                                </Paper>
                            </Grid>
                        :
                            (this.state.tileData.map((tile, idx) => (
                                <Grid item xs={6} lg={3} xl={2} key={idx}>
                                    <Card style={{border:tile.default === 'Yes' && '1px solid #20c997'}}>
                                        <CardActionArea>
                                            {tile.default === 'Yes' &&
                                                <div className={classes.overlay}>
                                                    <FALightIcon icon='shield-check' size='large' noMargin style={{color:'#20c997'}} />
                                                    <Typography style={{position: 'absolute', top: '38px', left: '-4.5px', fontSize: '14px', fontWeight: 'bold', color:'#20c997'}}>Default</Typography>
                                                </div>
                                            }
                                            {parseInt(tile.version) > 0 &&
                                                <div className={classes.overlay} key={tile.version}>
                                                    <Typography style={{
                                                        width: '40px',
                                                        height: '35px',
                                                        textAlign: 'center',
                                                        position: 'absolute', 
                                                        top: '-19px', 
                                                        left: '190px', 
                                                        fontSize: '14px', 
                                                        fontWeight: 'bold', 
                                                        color: tile.default === 'Yes' ? '#20c997' : colors.disabled,
                                                        border: tile.default === 'Yes' ? '1px solid #20c997' : `1px solid rgba(0, 0, 0, 0.12)`,
                                                        paddingTop: 7,
                                                        paddingRight: 5,
                                                        borderRight: 0,
                                                        borderTop: 0,
                                                        borderRadius: 5
                                                    }
                                                    } key={tile.version}>v{tile.version}</Typography>
                                                </div>
                                            }
                                            <CardContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 150}}>
                                                <div onClick={() => this.loadImage(tile.fileUrl)}>
                                                    <ImageWithError src={tile.fileUrl} style={{height:'auto', maxHeight: 120, maxWidth: '100%'}} alt={tile.imgRef} />
                                                </div>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardContent>
                                            <div className={classes.icons}>
                                                <Tooltip title="Image File">
                                                    <div>{/* Need tag for tooltip */}
                                                        <IconButton onClick={this.downloadFile(tile.imageUrl)} disabled={tile.disableImage}>
                                                            <FALightIcon icon='image' noMargin fixedHeight button disabled={tile.disableImage} />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="Raw Image File">
                                                    <div>{/* Need tag for tooltip */}
                                                        <IconButton onClick={this.downloadFile(tile.imageRawUrl)} disabled={tile.disableImageRaw}>
                                                            <FALightIcon icon='file-image' noMargin fixedHeight button disabled={tile.disableImageRaw} />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="3D Image File">
                                                    <div>{/* Need tag for tooltip */}
                                                        <IconButton onClick={this.downloadFile(tile.image3DUrls)} disabled={tile.disableImage3D}>
                                                            <FALightIcon icon='cube' noMargin fixedHeight button disabled={tile.disableImage3D} />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="Web 3D Image File">
                                                    <div>{/* Need tag for tooltip */}
                                                        <IconButton onClick={this.downloadFile(tile.imageWeb3DUrl)} disabled={tile.disableImageWeb3D}>
                                                            <FASolidIcon icon='cube' noMargin fixedHeight button disabled={tile.disableImageWeb3D} />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="Update">
                                                    <div>{/* Need tag for tooltip */}
                                                        {accessUpdatePartImage ? 
                                                            <Link to={{pathname: '/library/parts/update/'+part+'/'+tile.imgRef, state: {part}}}>
                                                                <IconButton>
                                                                    <FALightIcon icon='pencil-alt' noMargin fixedHeight button style={{color:'#0282C6'}}  />
                                                                </IconButton>
                                                            </Link>
                                                        :
                                                            <IconButton disabled>
                                                                <FALightIcon icon='pencil-alt' noMargin fixedHeight button disabled />
                                                            </IconButton>
                                                        }
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )))
                        }
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.dialog}
                    onClose={this.handleDialogClose}
                    scroll="body"
                    disableBackdropClick	
                    disableEscapeKeyDown
                >                
                    <DialogContent>
                        <ImageWithError src={this.state.dialogFile} alt='Part Image' style={{maxWidth: 500}}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} variant="outlined" color="default">Close</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PartImageSearch));
