import React, { Component, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import API from 'API';
import { assign, map, forEach, filter, set } from 'lodash';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { InputAdornment, Grid, TextField, Button, Box, Typography, RadioGroup, FormControlLabel, Radio, Link, FormControl, FormLabel, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { closeDialog } from "Actions/Dialog/Dialog";
import { deployConfirmation } from "Actions/Confirmation/Confirmation";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import moment from 'moment';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deploySnackBar } from "Actions/SnackBar/SnackBar";
import DateTimePicker from 'Components/Common/DatePickers/DateTimePicker';
import EquipmentHistoryRows from 'Components/Equipment/Misc/EquipmentHistoryRows';
import Textarea from 'Components/Common/Inputs/Textarea';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { colors } from 'Helpers/ColourHelper';

const initialState = {
    serialNumberType: 'Existing System', // Existing System / Existing Manual / New Manual / New System
    status: '',
    existingSerialNumber: null,
    partId: null,
    serialNumber: '',
    custId: null,
    addressId: null,
    invoiceDate: null,
    reqSerialNo: null,
    orderDate: null,
    createdDateTime: null,
    notes: ''
}

const EquipmentForm = ({  }) => {

    const   dispatch = useDispatch(),
            [customers, setCustomers] = useState([]),
            [addresses, setAddresses] = useState([]),
            [partsList, setPartsList] = useState([]),
            [selectedPart, setSelectedPart] = useState({}),
            [selectedSN, setSelectedSN] = useState({}),
            [serialNumbers, setSerialNumbers] = useState([]),
            [isLoading, setIsLoading] = useState(true),
            [formErrors, setFormErrors] = useState({}),
            [searchSN, setSearchSN] = useState(''),
            [searchSNError, setSearchSNError] = useState(''),
            [SNDetails, setSNDetails] = useState(null),
            // Form fields
            [formData, setFormData] = useState(initialState);


    useEffect(() => {
        getInitialData();
    }, []);


    const getInitialData = () => {
        Promise.all([
            API.get('/parts/all', { params: { 
                use: 'forEquipment'
            }}),
            API.get(`/customers/withAddresses`)
        ])
        .then(([partRes, custRes]) => {

            setIsLoading(false);

            let partNumbersList = []
            if(partRes.data) {
                partNumbersList = map(partRes.data, (el) => {
                    return assign({
                        ...el,
                        value: el.id,
                        label: el.partNumber + ' - ' + el.partDesc
                    });
                });

                setPartsList(partNumbersList)
            }

            let c = map(custRes.data, c => {
                return assign({ 
                    value: c.cust_id,
                    label: c.cust_name + " | CS" + c.cust_id,
                });
            })

            let custAddresses = [];
            if(custRes?.data) {
                forEach(custRes.data, c => {
                    forEach(filter(c.addresses, e => e.address_type === 'Site'), a => {
                        custAddresses.push({
                            value: a.address_id,
                            label: a?.address_name + ' | ' + a.address_line_one + ' | ' + a.address_town + ' | ' + a.address_postcode,
                            cust_id: c.cust_id
                        })
                    })
                })
            }

            setCustomers(c);
            setAddresses(custAddresses);
        });
    }

    const handleSubmit = () => {
        API.post(`/equipment`, formData)
        .then(res => {
            if(res.data.errors){
                setFormErrors(formatValidationErrors(res.data.errors));
            }
            else if (res.data.success) {
                dispatch(deploySnackBar("success", "Equipment added successfully"));
                // Reset form
                setFormData(initialState);
                setFormErrors({});
                getInitialData();
            }
        })
    };

    const searchSNDetails = (sn) => {
        API.get(`/equipment/serialNumbers/${sn}`)
        .then(res => {
            if(res?.data?.id){
                setSNDetails(res.data);
                setSearchSNError('');
            }
            else {
                setSNDetails(null);
                setSearchSNError('Serial Number not found');
            }
        })
        .catch(err => {
            setSNDetails(null);
            setSearchSNError('Serial Number not found');
        })
    }

    useEffect(() => {

        // No Part Selected
        if(!formData?.partId){
            setSelectedSN(null);
            setSerialNumbers([]);
            setSelectedPart(null);
        }
        // Part Selected
        else {
            let selectedP = partsList.find(o => o.value === formData?.partId);
    
            setSelectedPart(selectedP);

            // Existing System / Existing Manual
            if(formData?.serialNumberType == 'Existing System' || formData?.serialNumberType == 'Existing Manual'){

                API.get(`/equipment/serialNumbers`, { 
                    params: { 
                        partId: formData?.partId,
                        manualSN: formData?.serialNumberType === 'Existing Manual' ? 1 : 0
                    }
                })
                .then(res => {
                    if(res.data){
                        let snList = map(res.data, sn => {
                            return assign({
                                ...sn,
                                value: sn?.id,
                                label: sn?.sn
                            });
                        });
            
                        setSerialNumbers(snList);
            
                    }
                });

                setFormData({ 
                    ...formData, 
                    serialNumber: '',
                    existingSerialNumber: null
                });
            }
            // New Manual / New System
            else {
                setSelectedSN(null);
                setSerialNumbers([]);
                setFormData({ 
                    ...formData, 
                    serialNumber: (selectedP && selectedP?.reqSerialNo == 'Yes' && formData?.serialNumberType == 'New System') ? selectedP?.pmtCode + '-' : ''
                });
            }
        }
    }, [formData?.partId, formData?.serialNumberType]);

    useEffect(() => {

        setSelectedSN(serialNumbers.find(o => o.value === formData?.existingSerialNumber));

    }, [formData?.existingSerialNumber]);

    return (
        (isLoading && <LoadingCircle />) || (
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Add Equipment
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container item spacing={1}>
                                <Grid item xs={12} md={4} lg={3}>
                                    <Typography variant="caption" gutterBottom>
                                        <i>Quick Search: Enter a valid serial number to view details</i>
                                    </Typography>
                                    <TextField 
                                        label="Serial Number" 
                                        fullWidth 
                                        value={searchSN} 
                                        onChange={ e => setSearchSN(e.target.value) }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <AllIcon 
                                                        onClick={() => searchSNDetails(searchSN)}
                                                        icon='search'
                                                        noMargin
                                                        disabled={!searchSN}
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {SNDetails ? (
                                        <Grid container item spacing={3}>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" paragraph style={{color: colors?.green, marginTop: 20}}>
                                                    <AllIcon icon='check-circle' heavy noMargin size={15} color={colors?.green} /> <i><b>Serial Number Found</b></i>
                                                </Typography>
                                                <Table size='small' style={{border: '1px solid rgba(224, 224, 224, 1)'}}>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell style={{width: '180px'}}>Serial Number Type</TableCell>
                                                            <TableCell style={{border: '1px solid rgba(224, 224, 224, 1)'}}>{SNDetails?.manual ? 'Manual' : 'System'}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Part</TableCell>
                                                            <TableCell style={{border: '1px solid rgba(224, 224, 224, 1)'}}>{!SNDetails?.manual ? `${SNDetails?.part?.number} - ${SNDetails?.part?.description}` : '-'} </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Build Location</TableCell>
                                                            <TableCell style={{border: '1px solid rgba(224, 224, 224, 1)'}}>{!SNDetails?.manual ? `${SNDetails?.bl}` : '-'} </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Model Type</TableCell>
                                                            <TableCell style={{border: '1px solid rgba(224, 224, 224, 1)'}}>{!SNDetails?.manual ? `${SNDetails?.pm}` : '-'} </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                        searchSNError &&
                                        <Typography variant="body2" gutterBottom style={{color: colors?.red}}>
                                            <AllIcon icon='times-circle' heavy noMargin size={15} color={colors?.red} /> <i><b>{searchSNError}</b></i>
                                        </Typography>
                                        
                                    }
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={5}>
                        <PaddedPaper>
                            <Grid container item spacing={3}>
                                <Grid item xs={12}>
                                    <AutoCompleteSelect
                                        label={'Status *'}
                                        onChange={(o) => setFormData({ ...formData, status: o?.value ?? "" })}
                                        error={formErrors && formErrors['status'] && true}
                                        errorText={formErrors && formErrors['status']}
                                        options={[
                                            { value: 'Sold', label: 'Sold' },
                                            { value: 'Returned', label: 'Returned' }
                                        ]}
                                        value={formData?.status}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AutoCompleteSelect
                                        label={'Part *'}
                                        onChange={
                                            (o) => {
                                                setFormData({ ...formData, partId: o?.value });
                                            }
                                        }
                                        error={formErrors && formErrors['partId'] && true}
                                        errorText={formErrors && formErrors['partId']}
                                        options={partsList}
                                        value={formData?.partId}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Serial Number Type</FormLabel>
                                        <RadioGroup
                                            name="serialNumberType"
                                            value={formData.serialNumberType}
                                            onChange={e => setFormData({ ...formData, serialNumberType: e.target.value })}
                                        >
                                            <FormControlLabel
                                                value="Existing System"
                                                control={<Radio color="primary" />}
                                                label="Existing System"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="Existing Manual"
                                                control={<Radio color="primary" />}
                                                label="Existing Manual"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="New System"
                                                control={<Radio color="primary" />}
                                                label="New System"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="New Manual"
                                                control={<Radio color="primary" />}
                                                label="New Manual"
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {formData?.partId ? (
                                    <>
                                    {(formData?.serialNumberType == 'New System' || formData?.serialNumberType == 'New Manual') ?
                                        <Grid item xs={12}>
                                            <TextField
                                                name="name"
                                                label="Serial Number *"
                                                value={formData?.serialNumber}
                                                error={formErrors && formErrors['serialNumber'] && true}
                                                helperText={formErrors && formErrors['serialNumber']}
                                                onChange={ e => setFormData({ ...formData, serialNumber: e.target.value.toUpperCase() })}
                                                fullWidth
                                                style={{marginBottom: 0}}
                                                margin="normal"
                                            />
                                            <Typography variant="caption" gutterBottom>
                                                <i>{ formData?.serialNumberType == 'New Manual' ? 'Enter Manual Serial Number' : 'Enter System Serial Number'}</i>
                                            </Typography>
                                        </Grid>
                                    :
                                        <Grid item xs={12}>
                                            <AutoCompleteSelect
                                                label={'Serial Number *'}
                                                onChange={(o) => setFormData({ ...formData, existingSerialNumber: o?.value ?? "" })}
                                                error={formErrors && formErrors['existingSerialNumber'] && true}
                                                errorText={formErrors && formErrors['existingSerialNumber']}
                                                options={serialNumbers}
                                                value={formData?.existingSerialNumber}
                                                fullWidth
                                            />
                                        </Grid>
                                    }
                                    </>
                                ) : null}
                                <Grid item xs={12}>
                                    <AutoCompleteSelect
                                        label={'Customer *'}
                                        onChange={(o) => setFormData({ ...formData, custId: o?.value})}
                                        options={customers}
                                        value={formData?.custId}
                                        fullWidth
                                        error={formErrors && formErrors['custId'] && true}
                                        errorText={formErrors && formErrors['custId']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AutoCompleteSelect
                                        label={'Site Address *'}
                                        onChange={(o) => setFormData({ ...formData, addressId: o?.value ?? "" })}
                                        options={formData?.custId ? filter(addresses, {cust_id: formData?.custId}) : []}
                                        value={formData?.addressId}
                                        fullWidth
                                        error={formErrors && formErrors['addressId'] && true}
                                        errorText={formErrors && formErrors['addressId']}
                                    />
                                </Grid>
                                {formData?.status === 'Sold' && (
                                    <Grid item xs={12}>
                                        <DatePicker
                                            type="keyboardDate"
                                            id="orderDate"
                                            name="orderDate"
                                            label="Order Date *"
                                            value={formData?.orderDate}
                                            onChange={od => setFormData({ ...formData, orderDate: moment(od).format('YYYY-MM-DD') })}
                                            autoOk={true}
                                            inputProps={{
                                                className:"textDefault"
                                            }}
                                            error={formErrors && formErrors['orderDate'] && true}
                                            errorText={formErrors && formErrors['orderDate']}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <DatePicker
                                        type="keyboardDate"
                                        id="invoiceDate"
                                        name="invoiceDate"
                                        label="Invoice Date *"
                                        value={formData?.invoiceDate}
                                        onChange={id => setFormData({ ...formData, invoiceDate: moment(id).format('YYYY-MM-DD') })}
                                        autoOk={true}
                                        inputProps={{
                                            className:"textDefault"
                                        }}
                                        error={formErrors && formErrors['invoiceDate'] && true}
                                        errorText={formErrors && formErrors['invoiceDate']}
                                    />
                                    {(formData?.status === 'Sold' && formData?.orderDate) && (
                                        <Link 
                                            to="" 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setFormData({
                                                    ...formData,
                                                    invoiceDate: moment(formData?.orderDate).format("YYYY-MM-DD")
                                                })
                                            }} 
                                            className='blueLink'
                                        >
                                            <small>Copy Order Date</small>
                                        </Link>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="caption" gutterBottom>
                                        <i>This date / time will determine the order in which the records shows</i>
                                    </Typography>
                                    <DatePicker
                                        type='datetime'
                                        id="createdDateTime"
                                        name="createdDateTime"
                                        label="Created Date / Time *"
                                        required
                                        value={formData?.createdDateTime}
                                        errorText={formErrors && formErrors['createdDateTime']}
                                        onChange={date => setFormData({ ...formData, createdDateTime: moment(date).format("YYYY-MM-DD HH:mm") })}
                                    />
                                    {formData?.invoiceDate && (
                                        <Link 
                                            to="" 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setFormData({
                                                    ...formData,
                                                    createdDateTime: formData?.status == 'Returned' ? moment(formData?.invoiceDate).format("YYYY-MM-DD 17:30") : moment(formData?.invoiceDate).format("YYYY-MM-DD 09:00")
                                                })
                                            }} 
                                            className='blueLink'
                                        >
                                            <small>Copy Invoice Date</small>
                                        </Link>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Textarea
                                        name="notes"
                                        label="Notes"
                                        value={formData?.notes}
                                        onChange={e => setFormData({ ...formData, notes: e.target.value })}
                                        fullWidth
                                        rows={5}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="buttonRow">
                                        <Button
                                            color="primary"
                                            className="textLight"
                                            onClick={() => dispatch(deployConfirmation(`Are you sure you want to add this equipment?`, `Add Equipment`, handleSubmit))}
                                            variant="contained"
                                        >
                                            Add
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={7}>
                        {selectedSN && selectedSN?.equipment && (
                            <PaddedPaper>
                                <Typography variant="h6" paragraph>
                                    Equipment History for <b>{selectedSN?.sn}</b>
                                </Typography>
                                <EquipmentHistoryRows 
                                    equipmentId={selectedSN?.equipment?.id}
                                    history={selectedSN?.equipment?.history}
                                    slim
                                />
                            </PaddedPaper>
                        )}
                    </Grid>
                </Grid>
            </Box>
        )
    )
};

export default EquipmentForm;