import React from 'react';
import { connect } from 'react-redux';

import API from '../API';
import AdminRouteReferences from './Routes/AdminRoutes/AdminRouteReferences';
import { authStaff } from '../Actions/Auth/Auth';
import { handleAppError } from '../Actions/UI/UI';
import { getToken, 
         isTokenExpired, 
         setLastActivePage, 
         forceStoredProfile, 
         clearToken 
        } from '../Functions/AuthFunctions';

export default ChildComponent => {
    
    class ComposedComponent extends React.Component {
        componentDidMount() {
            this.shouldNavigateAway();
        }
        
        componentDidUpdate(prevProps){
            if(this.props.location.pathname !== prevProps.location.pathname){
                this.shouldNavigateAway();
            }
            return false;
        }
        
        shouldNavigateAway(){
            // Get login / token expiration status (arr [0] => token, arr [1] => expired?)
            const token = getToken();

            if (!token) {
                // User Not Logged In
                this.props.history.replace('/login')
            } 
            else if (isTokenExpired(token)) {
                // Token Expired
                setLastActivePage(window.location.pathname);
                forceStoredProfile();
                clearToken();
                this.props.history.replace('/login/expired')
            } else if(this.props.loggedInStaff && this.props.loggedInStaff.id === 0) {
                Promise.all([ 
                    API.get('/staff/my/profilePhoto', {
                        params: {
                            asImage: true,
                            asBase64: true
                        }
                    }), 
                    API.get('/staff/my/password/requiresUpdate')
                ])
                .then(([photoRes, viewRes]) => {
                    if(viewRes.data){
                        if(viewRes.data.requires_update){
                            this.props.handleAppError("PASSWORD_UPDATE", "");
                        }
                    }
                    this.props.authStaff(getToken(), photoRes.data ? photoRes.data : '');
                })
            }
            
            let accessRef = '';
            if(this.props.location.pathname === '' || this.props.location.pathname === '/'){
                accessRef = 'dashboard';
            }
            else {
                for (const [key, value] of Object.entries(AdminRouteReferences)) {
                    if(this.props.location.pathname.startsWith(key)) {
                        accessRef = value;
                        break;
                    }
                }
            }
            
            // If access ref is empty - page isn't mapped!
            if(accessRef.length > 0 || accessRef === true) {
                // If access ref is true in route refs it is automatically authorized (My Account, etc..)
                if(accessRef !== true) {
                    // Check staff access
                    API.get('/staff/my/access/check/' + accessRef)
                    .then((accessRes) =>  {
                        if(accessRes && accessRes.data && !accessRes.data['has_access']){
                            this.props.handleAppError("UNAUTHORIZED", this.props.location.pathname)
                            this.props.history.replace('/')
                        }
                    });
                }
            } else {
                this.props.history.push('/404')
            }
        }

        render() {
            return <ChildComponent {...this.props}  />;
        }       
    }
    function mapStateToProps(state){
        return {
            loggedInStaff: state.staffAuth.staff
        };
    }
    const mapDispatchToProps = (dispatch) => {
        return {
            authStaff: (token, profilePhoto) => dispatch(authStaff(token, profilePhoto)),
            handleAppError: (error, message) => dispatch(handleAppError(error, message)),
        };
    }
    
    return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent);
}