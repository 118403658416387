import React from 'react';
import {
    Button,
    DialogActions,
    Grid,
    Typography,
    Divider
} from '@material-ui/core';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { handleDialogClose } from 'Functions/FormFunctions';
import moment from 'moment';

const initialState = () => ({
    changeLog: {}
})

class ChangeLogDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleDialogClose = handleDialogClose.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData() {
        let {
            changeLog,
        } = this.props;

        this.setState({
            changeLog,
        });
    }

    render() {
        const {changeLog} = this.state;
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={6} align="center">
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            <b>From Version </b>
                        </Typography>
                        <Typography variant="h6" paragraph>
                            {changeLog?.vFrom}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} align="center">
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            <b>To Version</b>
                        </Typography>
                        <Typography variant="h6" paragraph>
                            {changeLog?.vTo}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary" gutterBottom align="left">
                            <b>Change Log Description</b>
                        </Typography>
                        <Typography variant="body1" align="left">
                            {changeLog?.description?.split('\n').map((item, key) => (
                                <span key={key}>{item}<br/></span>
                            ))}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            <b>Updated By</b>
                        </Typography> 
                        <Typography variant="body1" paragraph>
                            {changeLog?.s?.nm}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            <b>Updated On</b>
                        </Typography> 
                        <Typography variant="body1" paragraph>
                            {moment(changeLog?.date, "YYYY-MM-DD HH:mm:ss").format(`HH:mm on DD/MM/YYYY `)}
                        </Typography>
                    </Grid>

                </Grid>
                <DialogActions className='pr-0 pb-0'>
                    <Button 
                        onClick={() => this.props.toggleDialog()} 
                        variant="text"
                    >
                        <FAIcon icon="times" size={15} button />
                        Close
                    </Button>
                </DialogActions>
            </>
            );
    }

}

export default ChangeLogDialog;