import React from 'react';
import _ from 'lodash';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import API from "API";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea from 'Components/Common/Inputs/Textarea';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deployConfirmation } from "Actions/Confirmation/Confirmation";
import { closeDialog } from "Actions/Dialog/Dialog";
import { deploySnackBar } from "Actions/SnackBar/SnackBar";
import { FormControl, FormControlLabel } from '@material-ui/core';

const WildixCallNotes = ({ callback, id }) => {
    const dispatch = useDispatch(),
        [notes, setNotes] = useState(""),
        [assign, setAssign] = useState(0),
        [assignTo, setAssignTo] = useState(""),
        [assignTitle, setAssignTitle] = useState(""),
        [assignedTo, setAssignedTo] = useState(0),
        [call, setCall] = useState(null),
        [isLoading, setIsLoading] = useState(true),
        [assignable, setAssignable] = useState(false),
        [staff, setStaff] = useState([]);

    const handleSubmit = () => {
        setIsLoading(true);
        API.put(`/voip/${id}/notes`, { assign, assignTitle, assignTo, notes })
        .then((res) => {
            if (res?.data?.success) {
                if(callback) {
                    callback(notes);
                }
                dispatch(deploySnackBar("success", "Call notes updated successfully"));
                dispatch(closeDialog());
            }
        });
    };

    useEffect(() => {
        if (call) {
            setIsLoading(false);
        }
    }, [call]);

    useEffect(() => {
        Promise.all([
            API.get(`/voip/${id}`),
            API.get(`/staff/my/access/check/call-task-assign`),
            API.get('/staff/all', 
                { 
                    params: { 
                        active: true
                    } 
                }
            )
        ])
        .then(([res, callTaskAssign, staffList]) => {
            if (res?.data) {
                setAssignable(callTaskAssign?.data?.has_access ?? false);
                setCall(res?.data);
                setNotes(res?.data?.nt);
                setAssign(res?.data?.ts > 0 ? true : false);
                setAssignTo(res?.data?.ts);
                setAssignedTo(res?.data?.ts);
                setStaff(_.map(staffList.data, staff => {
                    return _.assign({
                        value: staff.staff_id,
                        label: `${staff.staff_first_name} ${staff.staff_last_name}`,
                    })
                }))
            }
        });
    }, []); /* eslint-disable-line */

    const incoming = call?.ic === 1,
        answered = call?.st === 1;

    return (
        (isLoading && <LoadingCircle />) || (
            <Box>
                <Grid container>
                    {call && (
                        <Grid item xs={12} className="content-light-white pl-2">
                            <List dense>
                                <ListItem disableGutters>
                                    {!incoming ? (
                                        <ListItemAvatar>
                                            <FAIcon className={answered ? "" : "textError"} icon={`phone-arrow-up-right`} type="light" />
                                        </ListItemAvatar>
                                    ) : null}
                                    <ListItemText
                                        primary={
                                            <>
                                                <Typography className={`${answered ? "" : "textError"} fw-400`} variant="body1">
                                                    {call.nm}
                                                </Typography>
                                                {call.cn && (
                                                    <Typography className={`${answered ? "" : "textError"} fw-400`} variant="body1">
                                                        {call.cn}
                                                    </Typography>
                                                )}
                                            </>
                                        }
                                        secondary={
                                            <Typography className={answered ? "" : "textError"} variant="body1" color="textSecondary">
                                                {call.nu}
                                            </Typography>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Typography variant="body2" component="div" align="right">
                                                    {call.ti}
                                                </Typography>
                                                <Typography variant="body2" component="div" align="right">
                                                    {call.dt}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Textarea label="Notes:" onChange={(e) => setNotes(e?.target?.value ?? "")} rows={5} value={notes} variant="outlined" />
                    </Grid>
                    {assignable && (
                        <>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={assign}
                                            onChange={(e) => setAssign(e?.target?.checked ?? false)}
                                            name="assign"
                                            color="primary"
                                        />
                                    }
                                    disabled={assignedTo > 0}
                                    label="Assign Task"
                                />
                            </Grid>
                            {assign ? (
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <AutoCompleteSelect
                                                    label="Assign To *"
                                                    onChange={(o) => setAssignTo(o?.value ?? "")}
                                                    options={staff}
                                                    value={assignTo}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {!assignedTo ? (
                                            <Grid item xs={12}>
                                                <Textarea
                                                    label="Task Title *"
                                                    onChange={(e) => setAssignTitle(e?.target?.value ?? "")}
                                                    value={assignTitle}
                                                />
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            ) : null}
                        </>
                    )}
                </Grid>
                <div className="buttonRow">
                    <Button color="default" onClick={() => dispatch(closeDialog())} variant="outlined">
                        <FAIcon icon="times" button />
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        disabled={assign && (assignTo === "" || (!assignedTo && assignTitle === ""))}
                        className="textLight"
                        onClick={() => dispatch(deployConfirmation(`Are you sure you want to update notes for this call?`, `Update Call Notes`, handleSubmit))}
                        variant="contained"
                    >
                        <FAIcon icon="check" buttonPrimary />
                        Update Notes
                    </Button>
                </div>
            </Box>
        )
    );
};

export default WildixCallNotes;
