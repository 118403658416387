import React, {PureComponent} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FALightIcon from '../Icons/FontAwesome/FALightIcon';
import { Divider } from '@material-ui/core';
import API from '../../../API';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ImageWithError from '../../Common/ImageWithError/ImageWithError';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import _ from 'lodash';

const style = theme => ({
    dropzone: {
        height: 125,
        marginTop: 24,
        backgroundColor: '#fff',
        whiteSpace: 'nowrap', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis',
        textAlign: 'center',
        padding: '8px'
    },
    dropzoneArea: {
        border: '1px dashed rgb(187, 186, 186)',
        borderRadius: 4,
    },      
    dropzoneDisabled: {
        backgroundColor: 'rgba(187, 186, 186, 0.1)',
        color: 'rgba(0,0,0,0.5)',
        cursor: 'default'
    },    
    dropzoneHasFile: {
        backgroundColor: '#e8f5e9'
    },  
    fileInput: {
        display: 'none'
    },
    defaultOverlay: {
        zIndex: 100,
        position: 'absolute',
        top: 94,
        left: 32,
        color:'#20c997'
    }
});

const initialState = {
    partId: 0,
    imageDialog: false,
    imageData: [],
    selectedImages: []
}

class PartImagePicker extends PureComponent {
    constructor(props) {
        super(props)
        this.state = initialState;
    }
    componentDidMount() {
        let selectedImages = [];
        if(this.props.selectedPartImages.length > 0){
            selectedImages = this.props.selectedPartImages;
        }
        this.setState({
            partId: this.props.partId,
            selectedImages
        }, () => this.mountImageData());
    }
    mountImageData = () => {
        if(this.state.selectedImages.length > 0){
            API.get(`/library/parts/${this.props.partId}`)
            .then(result => {
                let imageData = this.handleImageData(result);
                this.setState({
                    imageData
                });
            });
        }
    }
    handleImageData = result => {
        let imageData = [];
        result.data.forEach(el => {
            let imageFile = _.find(el.image_files.files, {'library_type': 'Part Image'});
            imageData.push({
                library_id: el.library_id,
                library_file_url: el.library_file_url,
                default: imageFile && imageFile.library_default_image
            });
        });

        imageData = _.orderBy(imageData, 'default', 'desc');
        
        return imageData;
    }
    handleImageSelect = image => {
        let selectedImages = [...this.state.selectedImages];

        if(!selectedImages.includes(image.library_id)){
            selectedImages.push(image.library_id);
        } else {
            selectedImages.splice(selectedImages.indexOf(image.library_id), 1);
        }

        this.setState({
            ...this.state,
            selectedImages
        });
    }
    handleOpenDialog = partId => {
        if(this.state.imageData.length === 0 || this.state.partId !== partId) {
            API.get(`/library/parts/${partId}`)
            .then(result => {
                let imageData = this.handleImageData(result);

                this.setState({
                    imageData,
                    partId,
                    selectedImages: this.state.partId === partId ? this.props.selectedPartImages : [],
                    imageDialog: true,
                });
            });
        } else {
            this.setState({imageDialog: true});
        }
    }
    handleSubmit = () => {
        this.setState({imageDialog: false}, () => this.props.handlePartImagePickerSelection(this.props.sectionIdx, this.props.idx, this.state.selectedImages));
    }
    handleClear = () => {
        this.setState({selectedImages: []});
    }
    render() {
        const { dropzoneHover } = this.state;
        const { classes, partId } = this.props;
        return (
            <React.Fragment>
                <FormControl 
                    error={!!this.props.errorText}
                    fullWidth 
                    margin="normal"                         
                >                
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor={this.props.id} shrink={true}>
                                {this.props.label}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <div
                                className={`${classes.dropzone} ${classes.dropzoneArea} ${dropzoneHover ? classes.dropzoneHover : ''} ${this.props.file ? classes.dropzoneHasFile : ''} ${this.props.disabled ? classes.dropzoneDisabled : ''}`}
                            >
                                <div className='SmallScroll' style={{height: 70, minWidth:'100%', display:'flex', boxSizing:'border-box', justifyContent: this.state.selectedImages.length >= 10 ? 'space-between' : 'center',
                                    alignItems:'center', overflowX: 'auto', overflowY:'hidden'}}>
                                    {this.state.selectedImages.length > 0 ? this.state.selectedImages.map(imageId => {
                                        let img = this.state.imageData.find(image => (image.library_id === imageId))
                                        return (
                                            <ImageWithError src={img && img.library_file_url} style={{maxWidth:'10%', maxHeight:'90%', height:'auto', padding: '0 5px'}} key={imageId} />
                                        );
                                    }) : (
                                        <Typography variant="body2">No images selected</Typography>
                                    )}
                                </div>
                                <Divider style={{margin: '8px 0'}} />
                                <span onClick={() => this.handleOpenDialog(partId)} style={{cursor: 'pointer'}}>
                                    <FALightIcon icon='plus-circle' size='small' style={{marginRight: 5}} button />
                                    Select image(s) from part image library
                                </span>
                            </div>
                            {this.props.errorText &&
                                <FormHelperText>{this.props.errorText}</FormHelperText>
                            }
                        </Grid>
                    </Grid>
                </FormControl>
                <Dialog 
                    open={this.state.imageDialog} 
                    onClose={this.handleSubmit} 
                    maxWidth='md' 
                    scroll='body' 
                    style={{width: '100%'}}
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle>
                            Part Image Picker
                            <div style={{float: 'right'}}>
                                {this.state.selectedImages.length > 0 && 
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={this.handleClear}
                                        style={{margin: '0 8px'}}
                                    >
                                        Clear
                                    </Button>
                                }
                                {this.state.imageData.length > 0 && 
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleSubmit}
                                    >
                                        Submit
                                    </Button>
                                }
                            </div>
                    </DialogTitle>
                    <DialogContent style={{padding: 20}}>
                        <Grid container spacing={3}>
                            {this.state.imageData.length > 0 ? this.state.imageData.map(image => {
                                if(image.library_file_url !== null) {
                                    return (
                                        <Grid item xs={3} key={image.library_id} style={{minWidth: 200}}>
                                            <Card style={{border:image.default === 'Yes' && '1px solid #20c997'}}>
                                                {image.default === 'Yes' &&
                                                    <div className={classes.defaultOverlay}>
                                                        <FALightIcon icon='shield-check' size='medium' noMargin style={{color:'#20c997'}} />
                                                        <Typography style={{fontSize: '10px', fontWeight: 'bold', marginLeft:'-6px'}}>Default</Typography>
                                                    </div>
                                                }
                                                <CardActionArea onClick={() => this.handleImageSelect(image)}>
                                                    <span style={{float: 'right'}}>
                                                        <Checkbox
                                                            value="design"
                                                            color="primary"
                                                            checked={this.state.selectedImages.includes(image.library_id)}
                                                        />
                                                    </span>
                                                    <CardContent style={{height: 150, display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                                                        <img src={image.library_file_url} style={{height:'auto', maxHeight:'90%', maxWidth: '100%', marginLeft:40}} alt="" />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    );
                                } else {
                                    return <React.Fragment />
                                }
                            }) : (
                                <Grid item xs={12}>
                                    <Typography variant="body2" style={{padding:'0 50px 30px 2px'}}>
                                        There are no images available
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default withStyles(style)(PartImagePicker);