import React, {Component} from 'react';
import _                  from 'lodash';
import { connect }        from 'react-redux';

import Qr                       from 'Components/Common/QrReader/QrReader';
import AllIcon                  from 'Components/Common/Icons/AllIcon';
import AutoCompleteSelect       from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect  from 'Components/Common/Selects/AutoCompleteMultiSelect';

import { colors }   from 'Helpers/ColourHelper';
import icons        from 'Helpers/IconHelper';

import { playSound } from 'Actions/Sounds/Sounds';

import { Grid, FormControl,Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';


const initialState = {
    showQr: 0,
    showPartUnavailable: false,
}

class SelectPartWithQR extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleScan = (scannedPartNumber) => {
        if (scannedPartNumber) {
            let part = _.find(this.props.parts, i => i.part.part_number === scannedPartNumber);
            if (part) {
                this.setState({showPartUnavailable: false},
                ()=>{
                    this.handleChange(part);
                    this.props.playSound('success');
                });
            } else {
               
                this.setState({showPartUnavailable: true});
            }
        }
        
    }

    handleChange = part => {
        this.setState({
            showQR: 0
        }, () => {
            this.props.onChange(part)
        });
    }

    render(){
        const {showQR, showPartUnavailable} = this.state;
        const { parts, error, value, classes, label, name, margin, multi, disabled, noClear } = this.props;
        
        return (
            <>
                <Grid container item  style={{alignItems: 'flex-end', flexWrap: 'nowrap'}}>
                    <Grid item style={{width: '100%'}}>
                        <FormControl error={error} fullWidth margin={ margin || "none" } style={{paddingBottom: 12}}>
                            {multi ? 
                                <AutoCompleteMultiSelect 
                                    name=      {name}
                                    label=     {label || 'Part *'}
                                    options=   {parts} 
                                    onChange=  {this.handleChange}
                                    error=     {error}
                                    errorText= {error}
                                    value=     {value}
                                    disabled=  {disabled}
                                    noClear=   {noClear}
                                /> :
                                <AutoCompleteSelect 
                                    name=      {name}
                                    label=     {label || 'Part *'}
                                    options=   {parts} 
                                    onChange=  {this.handleChange}
                                    error=     {error}
                                    errorText= {error}
                                    value=     {value}
                                    disabled=  {disabled}
                                    noClear=   {noClear}
                                />
                            }
                        </FormControl>
                    </Grid>
                    {!this.props.disableQr &&
                        <Grid item style={{width: 44}}>
                            <AllIcon 
                                noMargin 
                                icon=    {showQR ? icons.close : icons.qr} 
                                onClick= {() => this.setState({showQR: !showQR, showPartUnavailable: false})} 
                                disabled={disabled}
                                size='20' 
                            />
                        </Grid>
                    }
                </Grid>
                { !!showQR &&
                    <Grid container item  className={classes.qrRow}>
                        <Grid item xs={6} >
                            <Qr handleScan={this.handleScan} width="100%" /> 
                            {showPartUnavailable && 
                                <Typography variant="body1" style={{color:colors.red, textAlign: 'center'}}>Part Unavailable</Typography>
                            }
                        </Grid>
                    </Grid>
                }
            </>
        )
    }
}

const styles = theme => ({
    qrRow: {
        maxHeight: '30vw',
        maxWidth: '30vw',
        zIndex: 99999,
        position:'fixed',
        top: '6em',
        left: '5em'
    },
});

function mapDispatchToProps(dispatch) {
    return {
        playSound: (type, state) => {
            dispatch(playSound(type, state));
        },
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(SelectPartWithQR));