import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import Reminder from 'Components/Reminders/Misc/Reminder';
import ReminderDialog from 'Components/Reminders/Misc/ReminderDialog';
import DataTable from 'Components/Common/DataTables/DataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { deployDialog } from 'Actions/Dialog/Dialog';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';


const initialState = () => ({
    isLoading: true,
    searchString: '',
    reminders: {}
});

/* Component */
class Reminders extends React.Component {
    constructor(props) {
        super(props);
        // this.clearPageState     = clearPageState.bind(this);
        // this.getInitialState    = getInitialState.bind(this);
        // this.hasPageState       = hasPageState.bind(this);
        // this.savePageState      = savePageState.bind(this);
        // this.persistenceId      = `Reminders:all`;
        // this.state              = this.getInitialState(initialState());

        this.state              = initialState();
    }

    componentDidMount = () => {
        // !this.hasPageState() && this.loadComponentData();
        this.loadComponentData();
    }

    loadComponentData = () => {
        if(this.props.match && this.props.match.params &&  this.props.match.params.id) {
            this.handleViewReminder(this.props.match.params.id)
        }
        this.getReminders();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.match && this.props.match.params && this.props.match.params.id) {
            if((!prevProps.match && !prevProps.match.params && !prevProps.match.params.id)
            || (this.props.match.params.id !== prevProps.match.params.id)) {
                    this.handleViewReminder(this.props.match.params.id)
            }
        }
    }

    getReminders = (loadingBar = true) => {
        API.get(`/reminders`, 
            {
                params: {
                    searchString: this.state.searchString,
                    addressId: this.props.addressId,
                    customerId: this.props.customerId
                },
                props: {
                    loadingBar,
                    cancellation: true
                },
            }
        )
        .then(result => {
            if(result?.data) {
                this.setState({
                    isLoading: false,
                    reminders: result.data
                })
            }
        })
    }

    delayGetReminders = _.debounce(this.getReminders, 250);

    handleCallback = () => { 
        this.getReminders(false)
    }

    handleReset = () => { 
        this.clearPageState();
        this.setState({
            isLoading: true,
            searchString: ''
        }, () => this.getReminders())
    }

    handleSearchChange = event => {
        this.setState({
            searchString: event.target.value
        },
        () => {this.delayGetReminders(); /*this.savePageState();*/});
    }

    handleViewReminder = id => {
        this.props.deployDialog(<ReminderDialog id={id} callback={this.getReminders} />, "", "standard", "md")
    }

    render() {
        const { isLoading, searchString, reminders } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Reminders
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container item spacing={3} alignItems='center'>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <form noValidate autoComplete="off">
                                    <TextField 
                                        label="Reminder"
                                        fullWidth 
                                        value={searchString} 
                                        onChange={this.handleSearchChange} 
                                    />
                                    <div className='buttonRow'>
                                        <Button 
                                            onClick={() => this.handleReset()}
                                            variant="outlined"
                                            color="default"
                                        >
                                            <FALightIcon icon='undo' button />
                                            Reset
                                        </Button>
                                    </div>
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                </Grid>
                {(isLoading && (
                    <Grid item xs={12} align='center'>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <Grid item xs={12} lg={6}>
                        {(!_.isEmpty(reminders) && (
                            this.props.customerId ? <>
                                <Grid container spacing={1}>
                                    {_.filter(reminders, {reminder_linked_type: 'customer'}).length > 0 && <>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                Attached to Customer
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                {_.map(_.filter(reminders, {reminder_linked_type: 'customer'}),rowData => 
                                                    <Grid item xs={12}>
                                                        <Reminder 
                                                            key={rowData.reminder_id}
                                                            callback={this.handleCallback}
                                                            model={rowData} 
                                                            onClick={() => this.handleViewTask(rowData.task_id)} 
                                                        />    
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </>}
                                    {_.filter(reminders, {reminder_linked_type: 'address'}).length > 0 && <>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                Attached to Address
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                {_.map(_.filter(reminders, {reminder_linked_type: 'address'}),rowData => 
                                                    <Grid item xs={12}>
                                                        <Reminder 
                                                            key={rowData.reminder_id}
                                                            callback={this.handleCallback}
                                                            model={rowData} 
                                                            onClick={() => this.handleViewTask(rowData.task_id)} 
                                                        />
                                                    </Grid>    
                                                )}
                                            </Grid>
                                        </Grid>
                                    </>}
                                </Grid>
                            </> : 
                            <DataTable  
                                config={{
                                    key: 'reminder_id',
                                    isCard: true,
                                    noHeader: true,
                                    noRowHover: true,
                                    noPadding: true,
                                    isLoading: isLoading
                                }}
                                columns={[
                                    {
                                        heading: '',
                                        field: rowData => (
                                            <Reminder 
                                                key={rowData.reminder_id}
                                                callback={this.handleCallback}
                                                model={rowData} 
                                                onClick={() => this.handleViewTask(rowData.task_id)} 
                                            />
                                        )
                                    },
                                ]}
                                rows={reminders}
                            />
                        )) || (
                            <Paper>
                                <Box p={2}>
                                    <Typography variant="body1" align='center'>
                                        No reminders were found
                                    </Typography>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                )}
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deployDialog:       (content, title, variant = 'standard', size = 'md') =>  dispatch(deployDialog(content, title, variant, size)),
    clearPersistence:   (key) =>                                                dispatch(clearPersistence(key)),
    setPersistence:     (key, state) =>                                         dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(Reminders);