import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import AllIcon            from 'Components/Common/Icons/AllIcon';
import Textarea           from 'Components/Common/Inputs/Textarea';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import MediaTable         from 'Components/Common/DataTables/MediaTable';
import DragFileInputMulti from 'Components/Common/Inputs/DragFileInputMulti';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons                      from 'Helpers/IconHelper';
import { clenyDate }              from 'Helpers/TimeHelper';


import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core/';

const initialState = {
    formData:   {
        feedback: '',
        file:     null
    },
    editFormData: {
        id:         0,
        feedback:   '',
        file:       [],
        removeFile: [],
        newfiles:   []
    },
    formErrors: {},
    isLoading:  false,
}

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value,
            }
        })
    }

    handleEditChange = e => {
        this.setState({
            editFormData: {
                ...this.state.editFormData,
                [e.target.name]: e.target.value,
            }
        })
    }

    handleEditFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({ editFormData: { ...this.state.editFormData, newfiles: this.state.editFormData.newfiles ? [...this.state.editFormData.newfiles, file] : [file] } });
    }

    handleEditClearFile = (index) => {
        this.setState({ editFormData: { ...this.state.editFormData, newfiles: this.state.editFormData.newfiles.filter((file, i) => i !== index) } });
    }

    removeFile = (id) => {
        this.setState({ editFormData: { ...this.state.editFormData, removeFile: [...this.state.editFormData.removeFile,id] } });
    }

    handleSubmit = () => {

        this.setState({ isLoading: true }, ()=> {

            let formData = new FormData();

            _.each(this.state.formData.file, f => {
                formData.append('file[]', f);
            })
            formData.append('feedback', this.state.formData.feedback);

            API.post(`/worksOrders/${this.props.worksOrder.wo_id}/feedback`, formData)
            .then(res => {
                if (res.data.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(res.data.errors)
                    })
                } else {
                    this.setState({
                        formData: initialState.formData,
                        formErrors: initialState.formErrors,
                        isLoading: false
                    }, this.props.refresh)
                }
            })
    });
    }

    deleteFeedback = id => () => {
        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/feedback/${id}/delete`)
        .then(this.props.refresh)
    }

    editFeedback = () => {
        let { file, feedback, newfiles } = this.state.editFormData;
        let formData = new FormData();

        formData.append('feedback', feedback);

        _.each(newfiles, f => {
            formData.append('newFile[]', f);
        })

        formData.append('file', JSON.stringify(_.map(file, f => f.woff_id)));

        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/feedback/${this.state.editFormData.id}/edit`, formData)
        .then(res => {
            if (res.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(res.data.errors)
                })
            } else {
                this.setState({
                    editFormData: initialState.editFormData,
                    formErrors: initialState.formErrors
                }, this.props.refresh)
            }
        })
    }

    handleFileChange = (drop, name, event) => {
        const files = drop === true ? event.dataTransfer.files : event.target.files;
        this.setState({
            formData: {
                ...this.state.formData,
                file: [...this.state.formData.file ? this.state.formData.file : [], ...files]
            }
        });
    }

    handleClearFile = idx => {
        let file = _.filter(this.state.formData.file, (i, index) => index !== idx);
        this.setState({
            formData: {
                ...this.state.formData,
                file: file.length > 0 ? file : null,
            }
        });
    }

    handleEditClearExistingFile = obj => {
        let { file } = this.state.editFormData;
        file.splice(obj.itemNumber - 1, 1);
        this.setState({
            editFormData: {
                ...this.state.editFormData,
                file: file.length > 0 ? file : null,
            }
        });
    }

    render(){

        const { formData, editFormData, formErrors, isLoading } = this.state;
        const { reviewMode } = this.props;

        if (isLoading) return (<LoadingCircle/>);

        const feedback = _.orderBy( this.props.worksOrder.feedback, ['wof_id'], ['desc'] );

        return (
            <PaddedPaper>
                <Grid container spacing={3}>
                    {!_.find(this.props.worksOrder?.review?.stages, {wors_stage: 'Feedback'})?.wors_approved ?
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant='h5'>Add Feedback</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Textarea 
                                            name="feedback"
                                            label='Feedback'
                                            value={formData.feedback}
                                            onChange={this.handleChange}
                                            rows={5}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DragFileInputMulti
                                            id="pof-file"
                                            name="file"
                                            label="Photo Of Feedback"
                                            file={formData.file}
                                            errorText={formErrors && formErrors['file']}
                                            cancelOnClick={this.handleClearFile}
                                            onChange={this.handleFileChange}
                                            emptyText='No photo taken'
                                            placeholder='Photo Of Feedback'
                                            capture='user'
                                        />
                                    </Grid>
                                    <Grid item xs={12} className='buttonRow'>
                                        <Button variant='contained' color='primary' onClick={this.handleSubmit} disabled={!formData.feedback} >Create Feedback</Button>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid> : (feedback.length === 0 && <Grid item xs={12}><PaddedPaper style={{textAlign: 'center'}}>No Feedback Given</PaddedPaper></Grid>)
                    }
                    {feedback.length > 0 && 
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                {_.map(feedback, (i, idx) => 
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Grid container spacing={1} style={{justifyContent: 'space-between'}}>
                                                <Grid item container spacing={2} style={{alignItems: 'center'}} xs={6}>
                                                    <Grid item>
                                                        <img src={i.staff.staff_profile_photo} width={37} height={37}/>
                                                    </Grid>
                                                    <Grid item>
                                                        {i.wof_staff_name} <br></br>
                                                        {clenyDate(i.wof_updated)}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} className='buttonRow'>
                                                    {!this.props.worksOrder?.wo_completed && ((parseInt(this.props.loggedInStaff.id) === parseInt(i.wof_staff_id)) || (reviewMode && !_.find(this.props.worksOrder?.review?.stages, {wors_stage: 'Feedback'})?.wors_approved && [30,2].includes(this.props.loggedInStaff.id) ) ) && [
                                                        <AllIcon icon={icons.edit} onClick={()=>this.setState({editFormData: {id: i.wof_id, feedback: i.wof_feedback, file: i.files}})} noMargin />, 
                                                        <AllIcon icon={icons.delete} onClick={()=>this.props.deployConfirmation('Are you sure you want to delete this feedback?', 'Delete Feedback', this.deleteFeedback(i.wof_id))} noMargin/>
                                                    ]}
                                                </Grid>
                                                <Grid item xs={12} style={{ whiteSpace: "pre-wrap" }}>
                                                    {i.wof_feedback}
                                                </Grid>
                                                {i?.files?.length > 0 &&
                                                    <Grid item xs={12}>
                                                        <MediaTable
                                                            config={{ key: 'woff_id' }}
                                                            items={i.files}
                                                            media='file_url'
                                                            fields={[{
                                                                actions: r => ([{
                                                                    download: true
                                                                }])
                                                            }]}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    }
                </Grid>
                <Dialog 
                    open={editFormData.id}
                    maxWidth='md'  
                    fullWidth={true} 
                    scroll="body"
                >
                    <DialogTitle>
                        <Typography>Update Feedback</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <Textarea 
                                    name="feedback"
                                    label='Feedback'
                                    value={editFormData.feedback}
                                    onChange={this.handleEditChange}
                                    rows={5}
                                />
                            </Grid>
                            {editFormData.file.length > 0 &&
                                <Grid item xs={12}>
                                     <MediaTable
                                        config={{ key: 'woff_id' }}
                                        items={editFormData.file}
                                        media='file_url'
                                        fields={[{
                                            actions: r => ([{
                                                name: 'Delete',
                                                icon: icons.delete,
                                                onClick: this.handleEditClearExistingFile
                                            }])
                                        }]}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} style={{height: '100%'}}>
                                <DragFileInputMulti
                                    id="pof-file"
                                    name="file"
                                    label="Add More Photos"
                                    file={editFormData.newfiles}
                                    errorText={formErrors && formErrors['file']}
                                    cancelOnClick={this.handleEditClearFile}
                                    onChange={this.handleEditFileChange}
                                    emptyText='No photo taken'
                                    placeholder='Add More Photos'
                                    capture='user'
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <div className='buttonRow'>
                            <Button variant='outlined' onClick={()=>this.setState({editFormData: initialState.editFormData})}>Cancel</Button>
                            <Button variant='contained' color='primary' onClick={()=>this.props.deployConfirmation('Are you sure you want to update this feedback?', 'Update Feed Back',this.editFeedback)}>Save</Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </PaddedPaper>
        )
    }
}

function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff
    };
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)