import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import API from '../../../API';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import DataTable from '../../Common/DataTables/CiDataTable';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    keywords: '',
    searchResults: {},
    isLoading: true
})

class DesignChangeOrderSearch extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'RnD:DesignChangeOrderSearch';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        !this.hasPageState() && this.getSearchData();
    }

    getSearchData = () => {
        const params = {
            keywords: this.state.keywords
        }
        this.setState({
            isLoading: true
        }, () => {
            API.get('/researchDevelopment/changeOrders/all', {
                params,
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    result.data.forEach((dco) => {
                        let version = Math.max(dco.dco_details_design_issue, dco.dco_details_pdf_issue);
                        dco.version = 'From v' + (version - 1) + ' to v' + version;
                    });
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    }, ()=>this.savePageState())
                }
            })
        })
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
        }, 
        () => {
            this.getSearchData();
        });
    }

    downloadFile = rowData => {
        window.open(rowData.dco_file_url, '_blank');
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5" >
                        Design Change Order Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={this.state.keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                           
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key:                    'doc_id',
                                pagination:             true,
                                persistenceId:          this.persistenceId,
                                responsiveImportance:   true,
                                alternatingRowColours:  true,
                                isLoading:              this.state.isLoading
                            }}
                            columns={[
                                {
                                    heading: 'DCO Number',
                                    field: rowData => rowData.dco_reference,
                                    sizeToContent: true,
                                    important: true,
                                    main: true
                                },
                                {
                                    heading: 'Title',
                                    field: rowData => rowData.dco_title,
                                    important: true,
                                    truncate: true
                                },
                                {
                                    heading: 'Part Description',
                                    field: rowData => rowData.part_number + ' - ' + rowData.part_description,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Version',
                                    field: rowData => rowData.version,
                                    sizeToContent: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Download', icon: 'download', onClick: this.downloadFile},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(DesignChangeOrderSearch);
