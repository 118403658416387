import React from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Avatar,
    Grid,
    TextField,
    Typography
} from '@material-ui/core';
import { upperFirst } from 'lodash';
import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { Dial } from 'Functions/MiscFunctions';
import { CLENAWARE_RED, CLENAWARE_GREEN } from 'Constants';

const initialState = {
    searchResults: {},
    searchString: '',
    access: {
        viewAllEngineers: false,
        viewEngineer: false,
        updateEngineer: false,
    },
    dataLoading: true
}

class SearchEngineers extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;        
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData = () => {
        Promise.all([
            API.get('/staff/my/access/check/view-all-engineers'),
            API.get('/staff/my/access/check/view-engineer'),
            API.get('/staff/my/access/check/update-engineer')
        ])
        .then(([viewAllEngineerRes, viewEngineerRes, updateEngineerRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    viewAllEngineers: (viewAllEngineerRes.data && viewAllEngineerRes.data.has_access) || false,
                    viewEngineer: (viewEngineerRes.data && viewEngineerRes.data.has_access) || false,
                    updateEngineer: (updateEngineerRes.data && updateEngineerRes.data.has_access) || false,
                }
            }, () => this.getSearchData());
        })
    }

    getSearchData = () => {
        this.setState({
            dataLoading: true
        }, () => {
            API.get('/engineers', { params: { searchString: this.state.searchString } }).then(result => {
                this.setState({
                    dataLoading: false
                });
                if(result.data) {
                    this.setState({
                        searchResults: result.data
                    });
                } 
            });
        });
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.getSearchData();
        });
    }
    
    resetSearch = () => {
        this.setState({
            searchString: ''
        }, 
        () => {
            this.getSearchData();
        });
    }

    render() {
        const { access } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Search Engineers
                    </Typography>
                </Grid> 
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <TextField id="nameSearch" label="Engineer Name" fullWidth value={this.state.searchString} onChange={this.onSearchStringChange} />
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"                                       
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key: 'id',
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: this.state.dataLoading,
                                responsiveImportance: true
                            }}
                            columns={[
                                {
                                    field: rowData => <Avatar alt={`${rowData.engineer?.fn} ${rowData.engineer?.ln}`} src={rowData.photo} />,
                                    hideInResponsiveDialog: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Name',
                                    field: rowData => (
                                        <React.Fragment>
                                            {rowData?.engineer?.fn} {rowData?.engineer?.ln}
                                        </React.Fragment>
                                    ),
                                    important: true,
                                    sizeToContent: true,
                                    main: true,
                                    hideInResponsiveDialog: true,
                                },
                                {
                                    heading: 'Mobile',
                                    field: rowData => <Dial number={rowData?.engineer?.mobile} />,
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Email',
                                    field: rowData => rowData?.engineer?.email,
                                },
                                {
                                    heading: 'Company',
                                    field: rowData => rowData?.engineer?.company,
                                },
                                {
                                    heading: 'External Profiles',
                                    field: rowData => rowData.exProfileCount,
                                    sizeToContent: true,
                                    alignment: 'center'
                                },
                                {
                                    heading: "Qualifications",
                                    field: rowData => rowData.qualCount,
                                    sizeToContent: true,
                                    alignment: 'center'
                                },
                                {
                                    heading: 'Status',
                                    field: rowData => upperFirst(rowData.engineer?.status),
                                    sizeToContent: true,                                
                                    alignment: 'center',
                                    cellProps: rowData => ({
                                        style: {
                                            color: upperFirst(rowData.engineer?.status) == 'Active' ? CLENAWARE_GREEN  : CLENAWARE_RED
                                        }
                                    })
                                },
                                {
                                    actions: rowData => {
                                        let viewEngineer = (rowData?.type == 'Internal' && (this.props?.loggedInStaff?.id === rowData.engineer?.id) && access.viewEngineer) ? true : false;
                                        return [
                                            {name: 'Update', icon: 'pencil-alt', link: '/engineers/update/' + rowData.id, disabled: !access.updateEngineer || rowData.engineer?.status === "inactive"},
                                            {name: 'View', icon: 'search', link: '/engineers/view/' + rowData.id, disabled: !access.viewAllEngineers && !viewEngineer}
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    loggedInStaff:      state.staffAuth.staff
})

export default connect(mapStateToProps, null)(SearchEngineers);