import React, { Component, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import API from 'API';
import _ from 'lodash';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { Grid, Button, Box, Typography } from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { closeDialog } from "Actions/Dialog/Dialog";
import { deployConfirmation } from "Actions/Confirmation/Confirmation";
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import moment from 'moment';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deploySnackBar } from "Actions/SnackBar/SnackBar";
import Textarea from 'Components/Common/Inputs/Textarea';

const CondemnEquipmentDialog = ({ equipId, callBack }) => {

    const   dispatch = useDispatch(),
            [isLoading, setIsLoading] = useState(true),
            [formErrors, setFormErrors] = useState({}),
            // Lists
            [reasons, setReasons] = useState([]),
            // Form Data
            [condemnedDate, setCondemnedDate] = useState(null),
            [reasonId, setReasonId] = useState(0),
            [notes, setNotes] = useState('');

    useEffect(() => {
        Promise.all([
            API.get('/equipment/reasons')
        ])
        .then(([reasonRes]) => {

            // Reason List
            let r = _.map(reasonRes.data, r => {
                return _.assign({
                    value: r.id,
                    label: r.name
                });
            })
            
            setIsLoading(false);
            setReasons(r);
        })
    }, []);

    const handleSubmit = () => {
        API.post(`/equipment/${equipId}/condemn`, {
            condemnedDate,
            reasonId,
            notes
        })
        .then(res => {
            if(res.data.errors){
                setFormErrors(formatValidationErrors(res.data.errors));

            }
            if (res.data.success) {
                if(callBack) {
                    callBack();
                }
                dispatch(deploySnackBar("success", "You have successfully condemned this equipment."));
                dispatch(closeDialog());
            }
        })
    };

    return (
        (isLoading && <LoadingCircle />) || (
            <Box>
                <Grid container spacing={1} className="pb-3">
                    <Grid item xs={12}>
                        <Grid container item spacing={1}>
                            <Grid item xs={12}>
                                <DatePicker
                                    type="date"
                                    id="date"
                                    name="condemnedDate"
                                    label="Condemned Date *"
                                    value={condemnedDate}
                                    onChange={date => setCondemnedDate(moment(date).format('YYYY-MM-DD'))}
                                    autoOk={true}
                                    inputProps={{
                                        className:"textDefault"
                                    }}
                                    error={formErrors && formErrors['condemnedDate'] && true}
                                    errorText={formErrors && formErrors['condemnedDate']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    label='Reason *'
                                    onChange={ (o) => setReasonId(o?.value ?? "")}
                                    options={ reasons }
                                    value={reasonId}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Textarea
                                    label="Notes"
                                    value={notes}
                                    onChange={e => setNotes(e.target.value)}
                                    fullWidth
                                    rows={5}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="buttonRow">
                            <Button color="default" onClick={() => dispatch(closeDialog())} variant="outlined">
                                Cancel
                            </Button>
                            <Button
                                color='secondary'
                                className="textLight"
                                onClick={() => dispatch(deployConfirmation(`Are you sure you want to condemn this equipment?`, `Condemn Equipment`, handleSubmit))}
                                variant="contained"
                                disabled={!reasonId || !condemnedDate}
                            >
                                Condemn
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        )
    )
};

export default CondemnEquipmentDialog;