import API from 'API';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import BackButton from 'Components/Common/Buttons/BackButton';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import AllIcon from 'Components/Common/Icons/AllIcon';
import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';

import ActivityLog from './Tabs/ActivityLog';
import Addresses from './Tabs/Addresses';
import Details from './Tabs/Details';
import Emails from './Tabs/Emails';
import Marketing from './Tabs/Marketing';
import Surveys from './Tabs/Surveys';

import React from 'react';

const initialState = {
    formData: {
        customerId: '',
        name: '',
        position: '',
        telephone: '',
        mobile: '',
        email: '',
        associatedAddresses: []
    },
    customerAddresses: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: true,
    redirect: false,
    importantNoteShown: false,
    customer: {}
}

const styles = theme => ({
    disabledText: {
        color: 'black !important',
    }
});

class ViewCustomerContact extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getContact(this.props.match.params.id);
    }

    getContact = (contactId) => {
        API.get(`/customers/contacts/${contactId}`)
            .then((res) => {
                if (res.data.errors) {
                    this.setState({ redirect: true });
                } else if (res.data) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            customerId: res.data.contact_customer_id,
                            name: res.data.contact_name,
                            position: res.data.contact_position,
                            telephone: res.data.contact_telephone,
                            mobile: res.data.contact_mobile,
                            email: res.data.contact_email,
                            associatedAddresses: res.data.addresses
                        },
                        isManual: !res.data.customer,
                        isLoading: false,
                        contact: res.data
                    }, this.getCustomer);
                }
            })
    }

    getCustomer = () => {
        if (this.state.formData.customerId){
            API.get(`/customers/${this.state.formData.customerId}`)
            .then(result => {
                this.setState({customer: result.data},() => {
                    if (!this.state.importantNoteShown && result.data.cust_important_info) this.displayImportantInfo();
                });
            });
        }
    }

    displayImportantInfo = () => {
        /*
        this.setState({importantNoteShown: true}, () => {
            this.props.deployDialog(
            <>
                <Typography component="p">
                    {this.state.customer?.cust_important_info && this.state.customer?.cust_important_info.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
                </Typography>
                <Button variant='outlined' style={{float: 'right'}} onClick={this.props.closeDialog}>Close</Button>
            </>
            , 'Important Information', 'sm', {backgroundColor : '#fcc6c5', color: '#000'})
        });
        */
    }
/*
    testEmail = () => {
        this.props.deployDialog(
            <EmailDialog
                type='Quotation'
                templateId={1}
                id={1158}
            />,
            'Send Email',
            'md'
        )
    }
*/
    render() {
        const { formErrors, formData, isLoading, customer, isManual, contact } = this.state;
        const { classes } = this.props;

        if (this.state.redirect) {
            return <Redirect to="/customers/search" />
        }

        if (isLoading) {
            return <LoadingCircle />
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        View Contact
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <PaddedPaper>
                        <Typography variant="h6">
                            {contact.contact_name}
                        </Typography>
                        <Typography variant="body1">
                            {contact.contact_email}
                        </Typography>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {customer?.cust_name && customer?.account_status?.cas_name != 'Active - Verified' &&
                                <PaddedPaper
                                    style={{
                                        backgroundColor: colors.red,
                                        color: colors.white,
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight:'bold',
                                            color: colors.white,
                                            textAlign: 'center',
                                        }}
                                        variant='body1'
                                    >
                                        <AllIcon heavy icon={IconHelper.warning} white/>
                                        {customer.cust_name} is {customer?.account_status?.cas_name }
                                        <AllIcon heavy icon={IconHelper.warning} white noMargin
                                            style={{marginLeft: '0.5em'}}
                                        />
                                    </Typography>
                                </PaddedPaper>
                            }
                            {
                                isManual &&
                                <PaddedPaper
                                    style={{
                                        backgroundColor: colors.orange,
                                        color: colors.white,
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight:'bold',
                                            color: colors.white,
                                            textAlign: 'center',
                                        }}
                                        variant='body1'
                                    >
                                        <AllIcon heavy icon={IconHelper.warning} white/>
                                        Contact is not yet linked to a customer
                                        <AllIcon heavy icon={IconHelper.warning} white noMargin
                                            style={{marginLeft: '0.5em'}}
                                        />
                                    </Typography>
                                </PaddedPaper>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TabBarSwitcher
                        defaultTab={`Contact Details`}
                        tabs={[
                            {
                                label: 'Contact Details',
                                content: <Details contact={contact} classes={classes}/>
                            },
                            {
                                label: `Associated Addresses (${contact.addresses.length})`,
                                content: <Addresses contact={contact}/>
                            },
                            {
                                label: `Marketing`,
                                content: <Marketing contact={contact}/>
                            },
                            {
                                label: `Surveys`,
                                content: <Surveys contact={contact}/>
                            },
                            {
                                label: `Sent Emails`,
                                content: <Emails contact={contact}/>
                            },
                            {
                                label: 'Activity Log',
                                content: <ActivityLog contact={contact}/>
                            }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <BackButton props={this.props} />
                    <Button 
                        variant="contained"
                        color="primary"
                        onClick={() => this.props.history.push(`/customers/contacts/update/${this.props.match.params.id}`)}
                    ><AllIcon icon={IconHelper.edit} white/> Edit </Button>
{/*}  
                    <Button
                        onClick={this.testEmail}
                    >Test Email</Button>
*/}
                </Grid>
            </Grid>
        )

        // if (this.state.redirect) {
        //     return <Redirect to="/customers/search" />
        // } else {
        //     return (
        //         <Grid container spacing={3}>
        //             <Grid item xs={12}>
        //                 <Typography variant="h5">
        //                     View Contact
        //                 </Typography>
        //             </Grid>
        //             {(isLoading && (
        //                 <Grid container item spacing={3}>
        //                     <Grid item xs={12} lg={6}>
        //                         <PaddedPaper>
        //                             <LoadingCircle />
        //                         </PaddedPaper>
        //                     </Grid>
        //                 </Grid>
        //             )) || (
        //                     <React.Fragment>
        //                         <Grid container item spacing={3}>
        //                             <Grid item xs={12} lg={8}>
        //                                 <PaddedPaper>
        //                                 {!this.state.isLoading && customer?.cust_name && customer?.account_status?.cas_name != 'Active - Verified' &&
        //                                     <Typography style={{
        //                                         fontWeight:'bold',
        //                                         color: colors.white, 
        //                                         backgroundColor: colors.red, 
        //                                         margin: '-24px', 
        //                                         marginBottom: 12,
        //                                         borderRadius: '5px 5px 0px 0px',
        //                                         padding: '0.5em 1em',
        //                                     }}>{customer.cust_name} is {customer?.account_status?.cas_name }</Typography>
        //                                 }
        //                                 {
        //                                     !this.state.isLoading && isManual &&
        //                                     <Typography style={{
        //                                         fontWeight:'bold',
        //                                         color: colors.white, 
        //                                         backgroundColor: colors.orange, 
        //                                         margin: '-24px', 
        //                                         marginBottom: 12,
        //                                         borderRadius: '5px 5px 0px 0px',
        //                                         padding: '0.5em 1em',
        //                                     }}>Contact is not yet linked to a customer</Typography>
        //                                 }
        //                                     <Grid item>
        //                                         <Typography variant="h6" className="float-left">
        //                                             Contact Details
        //                                         </Typography>
        //                                         <Link to={`/customers/contacts/update/${this.props.match.params.id}`} >
        //                                             <IconButton edge="end" className="float-right" >
        //                                                 <FALightIcon icon='pencil' button noMargin />
        //                                             </IconButton>
        //                                         </Link>
        //                                     </Grid>
        //                                     {formErrors && formErrors.generic && (
        //                                         <React.Fragment>
        //                                             <Typography component={"div"} style={{ color: colors.red }}>
        //                                                 {formErrors.generic}
        //                                             </Typography>
        //                                         </React.Fragment>
        //                                     )}
        //                                     <form noValidate autoComplete="off">
        //                                         <TextField
        //                                             label="Name"
        //                                             value={formData.name}
        //                                             margin="normal"
        //                                             fullWidth
        //                                             disabled
        //                                             inputProps={{
        //                                                 className: classes.disabledText
        //                                             }}
        //                                             InputLabelProps={{
        //                                                 className: classes.disabledText
        //                                             }}
        //                                         />
        //                                         <TextField
        //                                             label="Position"
        //                                             value={formData.position}
        //                                             margin="normal"
        //                                             fullWidth
        //                                             disabled
        //                                             inputProps={{
        //                                                 className: classes.disabledText
        //                                             }}
        //                                             InputLabelProps={{
        //                                                 className: classes.disabledText
        //                                             }}
        //                                         />
        //                                         <EmailField
        //                                             label="Email"
        //                                             value={formData.email}
        //                                             margin="normal"
        //                                             fullWidth
        //                                             disabled
        //                                             inputProps={{
        //                                                 className: classes.disabledText
        //                                             }}
        //                                             InputLabelProps={{
        //                                                 className: classes.disabledText
        //                                             }}
        //                                         />
        //                                         <Grid container item spacing={3}>
        //                                             <Grid item xs={12} lg={6}>
        //                                                 <TextField
        //                                                     label="Mobile"
        //                                                     value={formData.mobile}
        //                                                     margin="normal"
        //                                                     fullWidth
        //                                                     disabled
        //                                                     inputProps={{
        //                                                         className: classes.disabledText
        //                                                     }}
        //                                                     InputLabelProps={{
        //                                                         className: classes.disabledText
        //                                                     }}
        //                                                 />
        //                                             </Grid>
        //                                             <Grid item xs={12} lg={6}>
        //                                                 <TextField
        //                                                     label="Telephone"
        //                                                     value={formData.telephone}
        //                                                     margin="normal"
        //                                                     fullWidth
        //                                                     disabled
        //                                                     inputProps={{
        //                                                         className: classes.disabledText
        //                                                     }}
        //                                                     InputLabelProps={{
        //                                                         className: classes.disabledText
        //                                                     }}
        //                                                 />
        //                                             </Grid>
        //                                         </Grid>
        //                                     </form>
        //                                 </PaddedPaper>
        //                             </Grid>
        //                             {/*
        //                                 contact.contact_source !== 'Customer' && (
        //                                     <Grid item xs={12} lg={4} style={{marginLeft: 'auto'}}>
        //                                         <PaddedPaper>
        //                                             <Typography variant="h5" gutterBottom>
        //                                                 Contact Source
        //                                             </Typography>
        //                                             <Grid container>
        //                                                 <Grid item xs={3}>
        //                                                     source
        //                                                 </Grid>
        //                                                 <Grid item xs={9}>
        //                                                     {contact.contact_source}
        //                                                 </Grid>
        //                                                 {
        //                                                     contact.contact_source === 'CSV Upload' &&
        //                                                     <>
        //                                                         <Grid item xs={3}>
        //                                                             List
        //                                                         </Grid>
        //                                                         <Grid item xs={9}>
        //                                                             {JSON.parse(contact.contact_source_data).name}
        //                                                         </Grid>
        //                                                     </>
        //                                                 }
        //                                                 <Grid item xs={3}>
        //                                                     Added
        //                                                 </Grid>
        //                                                 <Grid item xs={9}>
        //                                                     {JSON.parse(contact.contact_source_data).time.split(' ')[0]}
        //                                                 </Grid>
        //                                                 <Grid item xs={3}>
        //                                                     Added By
        //                                                 </Grid>
        //                                                 <Grid item xs={9}>
        //                                                     {JSON.parse(contact.contact_source_data).staff}
        //                                                 </Grid>
        //                                             </Grid>
        //                                         </PaddedPaper>
        //                                     </Grid>
        //                                 )
        //                             */}
        //                         </Grid>
        //                         {!isManual &&
        //                             <Grid container item spacing={3}>
        //                                 <Grid item xs={12} lg={8}>
        //                                     <PaddedPaper>
        //                                         <Typography variant="h6" gutterBottom>
        //                                             Associated Addresses
        //                                         </Typography>
        //                                         <Table>
        //                                             <TableHead>
        //                                                 <TableRow>
        //                                                     <TableCell>Type</TableCell>
        //                                                     <TableCell>Name</TableCell>
        //                                                     <TableCell>Address</TableCell>
        //                                                 </TableRow>
        //                                             </TableHead>
        //                                             <TableBody>
        //                                                 {formData.associatedAddresses && formData.associatedAddresses.map((item, idx) => {
        //                                                     return (
        //                                                         <TableRow key={idx}>
        //                                                             <TableCell>
        //                                                                 {item.address_type}
        //                                                             </TableCell>
        //                                                             <TableCell>
        //                                                                 {item.address_name}
        //                                                             </TableCell>
        //                                                             <TableCell>
        //                                                                 {item.address_line_one + ', ' + item.address_town + ', ' + item.address_postcode}
        //                                                             </TableCell>
        //                                                         </TableRow>
        //                                                     );
        //                                                 })}
        //                                             </TableBody>
        //                                         </Table>
        //                                     </PaddedPaper>
        //                                 </Grid>
        //                             </Grid>
        //                         }
        //                         {contact.mailing_list.length > 0 &&
        //                             <Grid container item spacing={3}>
        //                                 <Grid item xs={12} lg={8}>
        //                                     <PaddedPaper>
        //                                         <Typography variant="h6" gutterBottom>
        //                                             Mailing Lists
        //                                         </Typography>
        //                                         <CiDataTable
        //                                             config={{
        //                                                 key: 'ml_id',
        //                                                 plainHeader: true,
        //                                             }}
        //                                             rows={contact.mailing_list}
        //                                             columns={[
        //                                                 {
        //                                                     heading: 'Mailing List',
        //                                                     field: 'ml_name',
        //                                                 },
        //                                                 {
        //                                                     heading: 'Adding Reason',
        //                                                     field: i => 
        //                                                     (
        //                                                         i.ml_logic_type === 'manual' && 'Manually'
        //                                                     ) || (
        //                                                         (
        //                                                             ( i.ml_logic_type === 'ServicePlan' && 
        //                                                                 (
        //                                                                     !!i.ml_logic_data ? 'With Service Plan' : 'Without Service Plan'   
        //                                                                 )
        //                                                             ) || 
        //                                                             (
        //                                                                 i.ml_logic_type === 'CustomerMarketing' &&
        //                                                                 'Customer Marketing'
        //                                                             )
        //                                                         )
        //                                                     ),
        //                                                     sizeToContent: true,
        //                                                     alignment: 'center',
        //                                                 },
        //                                                 {
        //                                                     actions: i => ([
        //                                                         {
        //                                                             name: 'View',
        //                                                             link: `/marketing/campaigns/mailingLists/${i.ml_id}`,
        //                                                             icon: IconHelper.view
        //                                                         }
        //                                                     ])
        //                                                 }
        //                                             ]}
        //                                         />
        //                                     </PaddedPaper>
        //                                 </Grid>
        //                             </Grid>
        //                         }
        //                         <Grid container item spacing={3}>
        //                             <Grid item xs={12} lg={8}>
        //                                 <div className='buttonRow'>
        //                                     <BackButton props={this.props} />
        //                                 </div>
        //                             </Grid>
        //                         </Grid>
        //                     </React.Fragment>
        //                 )}
        //         </Grid>
        //     );
        }
}


const mapDispatchToProps = (dispatch) => ({
    deployDialog:       (content, title, size, style=null) => dispatch(deployDialog(content, title, '', size, style)),
    closeDialog:        ()                                 => dispatch(closeDialog())
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(ViewCustomerContact));
