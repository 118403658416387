//BarclayCardDialog
import React, { Component } from 'react';
import moment               from 'moment';
import API                  from 'API';
import { connect }          from 'react-redux';

import { formatValidationErrors } from 'Helpers/ErrorHelper';

import {Button, Grid, TextField, InputAdornment } from '@material-ui/core';

import DatePicker                       from 'Components/Common/DatePickers/DatePicker';
import DragFileInput                    from 'Components/Common/Inputs/DragFileInput';
import CreditNoteInvoicePaymentsDialog  from './CreditNoteInvoicePaymentsDialog'

import { closeDialog }          from 'Actions/Dialog/Dialog';
import { deployConfirmation }   from 'Actions/Confirmation/Confirmation';
import { deployDialog }         from 'Actions/Dialog/Dialog';

const initialState = () => ({
    formData:   {
        refundAmount:       0.00,
        amountOutstanding:  0.00,
        paymentRef:         '',
        authNo:             '',       
        file:               null,
        date:               null,
        method:             'Card - Stripe'
    },
    formErrors: []
})

class StripeDialog extends Component {
    constructor(props) {
        super(props);    
        this.state = {
            ...initialState(), 
            formData:{
                ...initialState().formData,
                amountOutstanding:  (this.props.cn?.invoice?.cni_total_incl_vat - this.props.cn?.invoice?.sum_of_payments).toFixed(2),
            }
        };
    }

    handleToDecimalPlace = (decimal) => e => {
        let { value} = e.target;
        let newVal = parseFloat(value).toFixed(decimal);
        if (parseFloat(value) > parseFloat(this.state.formData.amountOutstanding)) newVal = parseFloat(this.state.formData.amountOutstanding).toFixed(decimal);
        this.setState({
            formData: {
                ...this.state.formData,
                refundAmount: newVal
            }
        });
    }

    handleFormChange = e => {
        const {name, value} = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        });
    }
    
    handleDateChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                date: moment(e)
            }
        });
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    clearFile = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                file: ''
            }
        });
    }

    submit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => { newFormData.append(key, this.state.formData[key]); });
        API.post(`/customerReturns/creditNote/invoice/${this.props.cn.invoice.cni_id}/processPayment`, newFormData)
        .then(res => { 
            if (res.data.errors)    this.setState({formErrors: formatValidationErrors(res.data.errors)});
            if (res.data.success)   this.props.callback() 
        });
    }

    close = () => {
        this.props.closeDialog();
        this.props.deployDialog(<CreditNoteInvoicePaymentsDialog id={this.props.cn?.invoice?.cni_id} cn={this.props.cn} callback={this.props.callback} />, 'Assign Refund');
    }

    render() {
        const {formData, formErrors} = this.state;
        const {refundAmount, amountOutstanding, paymentRef, authNo, file, date} = formData;
        
        return (
            <Grid container>
                <Grid item xs={12}>
                    <form>
                        <TextField
                            name="refundAmount"
                            label="Amount"
                            value={refundAmount}
                            onChange={this.handleFormChange}
                            onBlur={this.handleToDecimalPlace(2)}
                            margin="normal"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                inputProps:     { min: 0, max: amountOutstanding } 
                            }}
                            type="number"
                            fullWidth
                            error={formErrors && formErrors['refundAmount'] && true}
                            helperText={formErrors && formErrors['refundAmount']}
                        />
                        <TextField
                            label="Amount Outstanding"
                            value={amountOutstanding}
                            margin="normal"
                            fullWidth
                            disabled
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                className: 'disabledText'
                            }}
                        />
                        <TextField 
                            id="paymentRef"
                            name="paymentRef"
                            label="Refund Ref: *"
                            value={paymentRef}
                            error={formErrors && formErrors['paymentRef'] && true}
                            helperText={formErrors && formErrors['paymentRef']}
                            onChange={this.handleFormChange}
                            margin="normal"
                            type="text"
                            fullWidth
                        />
                        <TextField 
                            id="authNo"
                            name="authNo"
                            label="Auth No: "
                            value={authNo}
                            error={formErrors && formErrors['authNo'] && true}
                            helperText={formErrors && formErrors['authNo']}
                            onChange={this.handleFormChange}
                            margin="normal"
                            type="text"
                            fullWidth
                        />
                        <DragFileInput 
                            id="file"
                            name='file'
                            label="Upload File:"
                            file={file}
                            errorText={formErrors && formErrors['file']}
                            onChange={this.handleFileChange}
                            cancelOnClick={this.clearFile}
                            emptyText="No file selected"
                        />
                        <DatePicker
                            type="date"
                            id="date"
                            name="date"
                            label="Payment Date *"
                            value={date}
                            errorText={formErrors && formErrors['date']}
                            onChange={this.handleDateChange}
                            autoOk={true}
                        />
                    </form>
                </Grid>
                <Grid item xs={12}>
                    <div className="buttonRow">
                        <Button 
                            variant="outlined" 
                            color="default" 
                            onClick={this.close}
                        >
                            Close
                        </Button>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={()=>{this.props.deployConfirmation('Are you sure you want to process refund?', 'Process Refund?', this.submit)}}
                        >
                            Submit
                        </Button>
                    </div>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation:     (body, title, callback)     => dispatch(deployConfirmation(body, title, callback)),
    closeDialog:            ()                          => dispatch(closeDialog()),
    deployDialog:           (content, title, variant)   => dispatch(deployDialog(content, title, variant)),
})

export default connect(null, mapDispatchToProps)(StripeDialog);