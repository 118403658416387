import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import DataTable from '../../../Common/DataTables/DataTable';
import Grid from '@material-ui/core/Grid';
import FALightIcon from '../../../Common/Icons/FontAwesome/FALightIcon';

class ElectricalConnections extends Component {

    render() {
        const { ecs, originalPart } = this.props;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Electrical Connections</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {ecs && ecs.length > 0 ? 
                                <React.Fragment>
                                    <br></br>
                                    <Typography variant="subtitle2" style={{display:'flex', alignItems: 'center'}}>
                                        <FALightIcon icon='info-circle'/>Below is a list of electrical connections that {originalPart} uses
                                    </Typography>
                                    <br></br>
                                    <DataTable  
                                        config={{
                                            key: 'ec_id',
                                            plainHeader: true
                                        }}
                                        columns={[
                                            {
                                                heading: 'Name',
                                                field: rowData => rowData.connection.ec_name,
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: 'Description',
                                                field: rowData => rowData.connection.ec_desc,
                                                important: true
                                            }, 
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'View', icon: 'search', link: '/parts/electrical-connections/view/' + rowData.pec_ec_id, disabled: !this.props.viewElectricalConnections}
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={ecs}
                                    />
                                </React.Fragment>
                            :
                                <Typography>There are 0 electrical connections found.</Typography>
                            }
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default ElectricalConnections;
