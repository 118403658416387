import React from 'react';
import API from 'API';
import { connect } from 'react-redux';
import {
    Grid,
    FormControl,
    Typography,
    Button,
    InputLabel,
    Input,
    List, 
    ListItem,
    ListItemText,
    ListItemIcon
} from '@material-ui/core';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import _ from 'lodash';
import BackButton from 'Components/Common/Buttons/BackButton';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import { deployConfirmation }       from 'Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import AllIcon from 'Components/Common/Icons/AllIcon';

const initialState = () => ({
    formData: {
        relatedForms: []
    },
    isLoading: true,
    formId: '',
    formConfig: {},
    formsList: [],
    access: {
        manageRelated: false
    }
});

class RelatedForms extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();      
    }

    componentDidMount = () => {
        const formId = this.props.formId || this.props.match.params.formId;
        this.setState({
            formId
        }, () => {
            this.loadComponentData();
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match?.params?.formId !== this.props.match?.params?.formId) {
            const formId = this.props.match.params.formId;
            this.setState({
                isLoading: true,
                formId
            }, () => {
                this.loadComponentData();
            })
        }
    }

    loadComponentData = () => {

        this.checkAccess();

        Promise.all([
            API.get(`/forms/configuration/${this.state.formId}/relatedForms`)
        ])
        .then(([
            result
        ]) =>  {
            if (result && result.data.errors) {
                if(this.props.history) {
                    this.props.history.push('/forms');
                }
            } else if (result && result.data) {

                let relatedForms = [];
                 _.map(result.data.related, (el) => {
                    relatedForms.push(el.oId);
                });

                this.setState({
                    isLoading: false,
                    formConfig: result.data,
                    formData: {
                        relatedForms
                    }
                },
                () => {
                     this.getFormsList();
                });
            }
        });
    }

    getFormsList = () => {
        API.get('/forms/configuration')
        .then(result => {
            if(result.data) {

                let filterByArea = _.filter(result.data, (el) => {
                    return el.area == this.state.formConfig?.area?.nm;
                });
                // Remove the form we are currently on
                filterByArea = _.filter(filterByArea, (el) => {
                    return el.oId != this.state.formConfig?.oId;
                });

                let formsList = _.map(filterByArea, (el) => {
                    return _.assign({
                        value: el.oId,
                        label: el.cat + ' | ' +  el.subCat + ' | ' + el.nm
                    });
                });
                this.setState({
                    formsList
                });
            } 
        });
    }

    submit = () => {
        const { formData, formId } = this.state;
        this.setState({
            isLoading: true
        }, () => {
            API.post(`/forms/configuration/${formId}/updateRelatedForms`, formData)
            .then(result => {
                if(result.data) {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.deploySnackBar("success", `You have successfully updated the related forms`);
                        this.loadComponentData();
                    });
                }
            });
        });
    }

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/forms-related:manage'),
        ])
        .then(([manageRes]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    manageRelated: manageRes.data && manageRes.data.has_access
                }
            })
        });
    }

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions && selectedOptions.map(a => a.value);
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }

    render() {
        const { isLoading, formConfig, formData, formsList, access} = this.state;

        return (
            <>
            {(isLoading && (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            )) || (
                <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Related Forms
                        </Typography>
                    </Grid> 
                    <Grid item xs={12}>
                        <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6" paragraph>
                                            Current Related Forms
                                        </Typography>
                                        {formConfig?.related && formConfig?.related.length > 0 ? (
                                            <List dense>
                                                {_.map(formConfig?.related, (el, i) => (
                                                    <ListItem key={i}>
                                                        <ListItemIcon> 
                                                            <AllIcon icon='memo' noMargin fixedHeight size={14}  />
                                                        </ListItemIcon>
                                                        <ListItemText primary={el.nm} secondary={el.cat + ' | ' + el.subCat} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        )
                                        : 
                                            <Typography variant="body2" gutterBottom>
                                                <i>No current related forms</i>
                                            </Typography>
                                        }
                                        
                                        <br></br>

                                        <form autoComplete="off" noValidate>
                                            {access.manageRelated && (
                                                <FormControl margin="normal" fullWidth>
                                                    <AutoCompleteMultiSelect 
                                                        options={formsList} 
                                                        label='Update Related Forms'
                                                        value={formData.relatedForms}
                                                        onChange={this.handleMultiSelectChange('relatedForms')}
                                                        isGrouped
                                                    />
                                                </FormControl>
                                             )}
                                            <div className='buttonRow'>
                                                <BackButton props={this.props} />
                                                {access.manageRelated && (
                                                    <Button 
                                                        onClick={() => this.props.deployConfirmation(`Are you sure you want to update the related forms?`, 'Confirmation', this.submit)} 
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Update
                                                    </Button>
                                                )}
                                            </div>
                                        </form>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Form Details
                                        </Typography>
                                        <form autoComplete="off" noValidate>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="category" shrink={true}>Area</InputLabel>
                                                <Input id="category" value={formConfig?.area?.nm || ''} />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="subCategory" shrink={true}>Category</InputLabel>
                                                <Input id="subCategory" value={formConfig?.cat?.nm || ''} />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="subCategory" shrink={true}>Sub Category</InputLabel>
                                                <Input id="subCategory" value={formConfig?.subCat?.nm || ''} />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="title" shrink={true}>Name</InputLabel>
                                                <Input id="title" value={formConfig?.nm || ''} />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="actualDocStatus" shrink={true}>Version</InputLabel>
                                                <Input id="actualDocStatus" value={(formConfig?.v && `v${formConfig?.v}`) || ''} />
                                            </FormControl>
                                        </form>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                    </Grid>
                </Grid>
                </>
            )}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
    };
}

export default connect(null, mapDispatchToProps)(RelatedForms);