import React from 'react';
import API from 'API';
import _ from 'lodash';
import {
    Grid,
    Typography,
    Card,
    CardContent
} from '@material-ui/core';
import moment from 'moment';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import TabBar from 'Components/Common/Navigation/TabBar';
import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';
import { TabPanel } from 'Functions/MiscFunctions';
import { handleTabChange } from 'Functions/FormFunctions';
import uuidv4 from 'uuid/v4';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { engineerJobActivityLog, colors } from 'Helpers/ColourHelper';
import TypographyAllowHtml from 'Components/Common/Typography/TypographyAllowHtml';
import AllIcon from 'Components/Common/Icons/AllIcon';
// Tabs
import JobOverview from './Tabs/JobOverview';
import JobSheets from './Tabs/JobSheets/JobSheets';

const initialState = () => ({
    job: {},
    isLoading: true,
    currentTab: 'overview',
    keys: {
        overview: uuidv4(),
        jobSheets: uuidv4(),
        logs: uuidv4(),
    }
});

class ViewJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.savePageState      = savePageState.bind(this);
        this.handleTabChange    = handleTabChange.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData()
    }

    loadComponentData = (refreshKeys = true) => {
        API.get('/engineers/jobs/' + this.props.match.params.jobId)
        .then(result => {

            let job = result.data;

            // ACTIVITY
            _.map(job.log, (log) => {
                
                let data             = engineerJobActivityLog(log.type);
                log.color            = data['color'];
                log.icon             = data['icon'];
            });

            let keys = this.state.keys;
            if(refreshKeys){
                keys = {
                    overview: uuidv4(),
                    jobSheets: uuidv4(),
                    logs: uuidv4(),
                }
            }                        
            this.setState({
                ...this.state,
                job,
                isLoading: false,
                keys
            });
        });
    };

    render() {
        const { isLoading, job, currentTab, keys } = this.state;

        const tabItems = [
            {name: 'overview', label: `Overview` },
            {name: 'jobSheets', label: `Job Sheets (${job?.jobSheets?.count})` },
            {name: 'logs', label: `Activity Log` },
        ]

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Job Details
                    </Typography>
                </Grid>              
                <Grid container item spacing={3}>                        
                    <Grid item xs={12}>
                        {(isLoading && (
                            <LoadingCircle />
                        )) || (
                            <>
                                <TabBar
                                    tabs={_.map(_.filter(tabItems, i => i?.label), i => ({label: i.label, value: i.name}))}
                                    currentTab={currentTab} 
                                    handleTabChange={this.handleTabChange}
                                />
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TabPanel key={keys?.overview} value={currentTab} index={'overview'}> 
                                            <JobOverview key={keys?.overview} job={job} tabChange={this.handleTabChange}/>
                                        </TabPanel>

                                        <TabPanel key={keys?.jobSheets} value={currentTab} index={'jobSheets'}> 
                                            <JobSheets job={job} refreshTabs={() => this.loadComponentData(true)} />
                                        </TabPanel>

                                        <TabPanel key={keys?.logs} value={currentTab} index={'logs'}> 
                                            <Card>
                                                <CardContent>
                                                    <VerticalTimeline layout="1-column">
                                                        {job.log && job.log.map((log, key) => (
                                                            <VerticalTimelineElement key={key}
                                                                className="vertical-timeline-element--work"
                                                                date={moment(log.date).format("DD/MM/YYYY HH:mm") + ' - ' + log.staff?.nm}
                                                                iconStyle={{ backgroundColor: log.color, color: 'white' }}
                                                                icon={<AllIcon
                                                                        icon={log.icon}
                                                                        style={{color:'white'}}
                                                                    />
                                                                }
                                                            >
                                                                <h3 className="vertical-timeline-element-title">{log.type}</h3>
                                                                {log.text && log.text.split('\n').map((item, key) => {
                                                                    return <span key={key}><TypographyAllowHtml body={ `${item} <br/>`} /></span>
                                                                })}
                                                            </VerticalTimelineElement>
                                                        ))}
                                                    </VerticalTimeline>
                                                </CardContent>
                                            </Card>
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>          
        );
    }
}

export default ViewJob;