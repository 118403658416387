import React, { Component } from 'react';
import { connect }          from 'react-redux';
import API                  from 'API';
import _                    from 'lodash';

import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';
import AllIcon       from 'Components/Common/Icons/AllIcon';

import { ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel, Typography, Grid } from '@material-ui/core';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';

const initialState = () => ({
    isLoading: true,
    supplier: [],
});
class SupplierParts extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getSupplier();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) this.setState({isLoading: true}, this.getSupplier);
    }

    getSupplier = () => {
        API.get(`/suppliers/${this.props.match.params.id}`)
        .then(res => {
            this.setState({
                isLoading: false,
                supplier: res.data,
            });
        });
    }

    render() {

        const { isLoading, supplier } = this.state;

        if (isLoading) return (<LoadingCircle/>);

        const exParts      = _.filter(supplier.ex_part_costs, i => !!i.part && !_.find(supplier.default_part_costs, {cost_part_id: i.cost_part_id}));
        const defaultParts = _.filter(supplier.default_part_costs, i => !!i.part);

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={3} style={{justifyContent: 'space-between', alignItems: 'flex-end'}}>
                            <Grid item>
                                <Typography variant='h5'>{supplier.supp_company_name}</Typography>
                                <Typography variant='body1'>{supplier.supp_trading_name && `${supplier.supp_trading_name} - `}{supplier.type.supp_types_name}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body1'>{supplier.supp_telephone}</Typography>
                                <Typography variant='body1'>{supplier.supp_email}</Typography>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    {defaultParts.length > 0 &&
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<AllIcon icon={icons.expand} color={colors.white} noMargin />} >Default Parts ({defaultParts.length})</ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <DataTable
                                    config={{
                                        key:                   'cost_id',
                                        filterMod:             { clearable: true, filterInPagination: true, colSpan: 3 },
                                        alternatingRowColours: true,
                                        pagination:            true,
                                        style:                 r => ({color: r.part.part_status !== 'Active' && colors.red }),
                                    }}
                                    columns={[
                                        {
                                            heading:        '',
                                            field:          r => <ImageWithError src={r.part.default_image?.file_path} style={{ maxHeight: 30, maxWidth: 30 }}/>,
                                            sizeToContent:  true,
                                            filter:         'custom',
                                            filterMod:      {
                                                                dataRef:    (search, rowData) => {return `${rowData.part.part_number}|${rowData.part.part_description}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                                                label:      'Search'
                                                            },
                                        },
                                        {
                                            heading:       'Part',
                                            field:         'part.part_number',
                                            sizeToContent: true,
                                            dataRef:       'part.part_number',
                                        },
                                        {
                                            heading: 'Description',
                                            field:   'part.part_description',
                                            dataRef: 'part.part_description',
                                        },
                                        {
                                            heading: 'Status',
                                            field:   'part.part_status',
                                            dataRef: 'part.part_status',
                                        },
                                        {
                                            heading: 'Qty',
                                            field:   'cost_qty',
                                            sizeToContent: true,
                                            dataRef: 'cost_qty',
                                        },
                                        {
                                            heading:       'Cost Total',
                                            field:         'cost',
                                            fieldPrefix:   '£',
                                            sizeToContent: true,
                                            dataRef:       'cost',
                                        },
                                        {
                                            heading:       'Cost Per Unit',
                                            field:         'cost_per_unit',
                                            fieldPrefix:   '£',
                                            sizeToContent: true,
                                            dataRef:       'cost_per_unit',
                                        },
                                        {
                                            heading:     'From',
                                            field:       'cost_date_from',
                                            sizeToContent: true,
                                            fieldFormat: 'clenydate',
                                            dataRef:     'cost_date_from',
                                        },
                                        {
                                            heading:     'To',
                                            field:       'cost_date_to',
                                            sizeToContent: true,
                                            fieldFormat: 'clenydate',
                                            dataRef:     'cost_date_to',
                                        },
                                        {
                                            actions: r => ([
                                                { name: 'Costs', icon: icons.pound, link: `/costs/view/${r.cost_part_id}` },
                                                { name: 'Part',  icon: icons.parts, link: `/parts/view/${r.cost_part_id}` }
                                            ])
                                        }
                                    ]}
                                    rows={_.orderBy(defaultParts, [i => i.part.part_number], ['asc'])}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    }
                    {exParts.length > 0 &&
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<AllIcon icon={icons.expand} color={colors.white} noMargin />} >Supplied In Past ({exParts.length})</ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <DataTable
                                    config={{
                                        key:                   'cost_id',
                                        filterMod:             { clearable: true, filterInPagination: true, colSpan: 3 },
                                        alternatingRowColours: true,
                                        pagination:            true,
                                        style:                 r => ({color: r.part.part_status !== 'Active' && colors.red }),
                                    }}
                                    columns={[
                                        {
                                            heading:        '',
                                            field:          r => <ImageWithError src={r.part.default_image?.file_path} style={{ maxHeight: 30, maxWidth: 30 }}/>,
                                            sizeToContent:  true,
                                            filter:         'custom',
                                            filterMod:      {
                                                                dataRef:    (search, rowData) => {return `${rowData.part.part_number}|${rowData.part.part_description}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                                                label:      'Search'
                                                            },
                                        },
                                        {
                                            heading:       'Part',
                                            field:         'part.part_number',
                                            sizeToContent: true,
                                            dataRef:       'part.part_number',
                                        },
                                        {
                                            heading: 'Description',
                                            field:   'part.part_description',
                                            dataRef: 'part.part_description',
                                        },
                                        {
                                            heading: 'Status',
                                            field:   'part.part_status',
                                            dataRef: 'part.part_status',
                                        },
                                        {
                                            heading: 'Qty',
                                            field:   'cost_qty',
                                            sizeToContent: true,
                                            dataRef: 'cost_qty',
                                        },
                                        {
                                            heading:       'Cost Total',
                                            field:         'cost',
                                            fieldPrefix:   '£',
                                            sizeToContent: true,
                                            dataRef:       'cost',
                                        },
                                        {
                                            heading:       'Cost Per Unit',
                                            field:         'cost_per_unit',
                                            fieldPrefix:   '£',
                                            sizeToContent: true,
                                            dataRef:       'cost_per_unit',
                                        },
                                        {
                                            heading:     'From',
                                            field:       'cost_date_from',
                                            sizeToContent: true,
                                            fieldFormat: 'clenydate',
                                            dataRef:     'cost_date_from',
                                        },
                                        {
                                            heading:     'To',
                                            field:       'cost_date_to',
                                            sizeToContent: true,
                                            fieldFormat: 'clenydate',
                                            dataRef:     'cost_date_to',
                                        },
                                        {
                                            actions: r => ([
                                                { name: 'Costs',            icon: icons.pound,    link: `/costs/view/${r.cost_part_id}` },
                                                { name: 'Part',             icon: icons.parts,    link: `/parts/view/${r.cost_part_id}` },
                                                { name: 'Current Supplier', icon: icons.supplier, link: `/suppliers/view/${r.cost_current_supplier}/parts` }
                                            ])
                                        }
                                    ]}
                                    rows={_.orderBy(exParts, [i => i.part.part_number], ['asc'])}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    }
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(SupplierParts);