import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import API from '../../../API';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CiDataTable from '../../Common/DataTables/CiDataTable';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import {DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE} from 'Constants'
import { gridPageChanged, gridRowsPerPageChanged } from 'Functions/FormFunctions'
import icons from 'Helpers/IconHelper';

import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    keywords: '',
    searchResults: {},
    access: {
        viewPrice: false,
        updatePrice: false
    },
    dataLoading: true,
    gridPagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    }
});
class PriceSearch extends Component {
    constructor(props) {
        super(props);
        this.clearPageState         = clearPageState.bind(this);
        this.getInitialState        = getInitialState.bind(this);
        this.hasPageState           = hasPageState.bind(this);
        this.savePageState          = savePageState.bind(this);
        this.persistenceId          = 'Prices:PriceSearch';
        this.state                  = this.getInitialState(initialState());
        this.gridPageChanged        = gridPageChanged.bind(this);
        this.gridRowsPerPageChanged = gridRowsPerPageChanged.bind(this);
    }

    componentDidMount = () => {
        this.getSearchData();
        this.checkAccess();
    }

   /* 
    componentDidUpdate(prevProps) { 
        if (prevProps.location.state !== this.props.location.state) {
            this.setQuery();
        }
    }

    setQuery = () => {
        const { state = {} } = this.props.location;
        this.setState({
            keywords: state?.keywords || initialState.keywords,
            gridPagination: {
                ...this.state.gridPagination,
                page: state?.gridPagination?.page || initialState.gridPagination.page,
                rowsPerPage: state?.gridPagination?.rowsPerPage || initialState.gridPagination.rowsPerPage,
            }
        },
        () => {
            this.getSearchData();
        });
    };
*/
    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/view-price'), 
            API.get('/staff/my/access/check/update-price'),
        ])
        .then(([viewRes, updateRes]) =>  {
            viewRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        viewPrice: viewRes.data.has_access
                    }
                });
            updateRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        updatePrice: updateRes.data.has_access
                    }
                });
        });
    }

    getSearchData = () => {
        this.setState({
            dataLoading: true
        }, () => {
            API.get('/prices/all' , {
                params: {
                    keywords: this.state.keywords && this.state.keywords,
                },
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    this.setState({
                        searchResults: result.data,
                        dataLoading: false
                    })
                }
            });
        })
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value,
            gridPagination: initialState().gridPagination
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    reset = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
            //gridPagination: initialState.gridPagination
        }, 
        () => {
           // this.props.history.replace();
            this.getSearchData();
        });
    }

    render() {
        const { access, dataLoading, gridPagination, keywords } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Price Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.reset}
                                            variant="outlined"
                                            color="default"
                                           
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <CiDataTable  
                            config={{
                                key: 'price_id',
                                pagination: true,
                                //page: gridPagination.page,
                                //rowsPerPage: gridPagination.rowsPerPage,
                                //gridPageChanged: this.gridPageChanged,
                                //gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                persistenceId: this.persistenceId,
                                isLoading: dataLoading,
                                responsiveImportance: true,
                                alternatingRowColours: true
                            }}
                            columns={[
                                {
                                    heading: 'Number',
                                    field: rowData => rowData.part_number,
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Description',
                                    field: rowData => rowData.part_description,
                                    important: true,
                                    main: true,
                                    truncate: true
                                },
                                {
                                    heading: 'Default Price',
                                    field: rowData => '£'+rowData.price_total,
                                    alignment: 'right',
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            rowData.part_locked ?
                                                {name: 'Locked', icon: icons.lock, onClick:()=>{}} :
                                                {name: 'Update', icon: 'pencil-alt', link: { pathname: '/prices/update/' + rowData.part_id, state: {keywords, gridPagination}}, disabled: !access.updatePrice},
                                            {name: 'View', icon: 'search', link: { pathname: '/prices/view/' + rowData.part_id, state: {keywords, gridPagination}}, disabled: !access.viewPrice}
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults} 
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(PriceSearch);