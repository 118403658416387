import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MaterialLink from '@material-ui/core/Link';
import API from '../../../API';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import DatePicker from '../../Common/DatePickers/DatePicker';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import { convertVideoUploadsName } from './../../../Helpers/DocumentHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import { MAX_UPLOAD_LIMIT_SIZE } from './../../../Constants';
import BackButton from 'Components/Common/Buttons/BackButton';
import {colors} from 'Helpers/ColourHelper';
const initialState = {
    formData: {
        videoId: '',
        videoCategoryId: '',
        mov:  '',
        mp4:  '',
        dateAdded: moment().toString(),
        title: '',
        currentStatus: '',
        fileUploads: ''
    },
    category: '',
    subCategory: '',
    fileUploadsDisplay: '',
    vuoUrl: '',
    vuoReference: '',
    versionMatch: '',
    actualVideoStatus: '',

    showMov: true,
    showMp4: true,
    showFileUpload: false,
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    redirect: false,
    successRedirect: false,
    isLoading: false
}

class UpdateVideo extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getVideoDetails(this.props.match.params.id);
    }

    getVideoDetails = (videoId) => {
        API.get(`/videos/${videoId}`)
        .then((result) => {
            if (result.data.errors) {
                this.setState({ redirect: true });
            } else {
                switch(result.data.video_file_uploads) {
                    case 'both':
                        this.setState({showMov: true, showMp4: true});
                    break;
                    case 'mp4_only':
                        this.setState({showMov: false, showMp4: true});
                    break;
                    case 'mov_only':
                        this.setState({showMov: true, showMp4: false});
                    break;
                    default:
                    break;
                }
                const fileUploads = convertVideoUploadsName(result.data.video_file_uploads);
                let actual_video_status  = result.data.latest_update_order && result.data.latest_update_order.vuo_version > 1 ? result.data.latest_update_order.vuo_version : 1;
                let current_status       = result.data.latest_file && result.data.latest_file.video_file_version > 1 ? result.data.latest_file.video_file_version : 1

                this.setState({
                    formData: {
                        ...this.state.formData,
                        videoId: videoId,
                        videoCategoryId: result.data.video_category_id,
                        title: result.data.video_title,
                        fileUploads: result.data.video_file_uploads,
                        currentStatus: current_status,
                    },
                    category: result.data.category.parent.video_cat_name,
                    subCategory: result.data.category.video_cat_name,
                    fileUploadsDisplay: fileUploads,
                    versionMatch: (actual_video_status === current_status) ? 'Yes' : 'No',
                    actualVideoStatus: actual_video_status,
                }, 
                () => {
                    const versionNumber = this.state.formData.currentStatus + 1;
                    API.get(`/videos/updateOrders/video/${videoId}/version/${versionNumber}`)
                    .then((result) => {
                        this.setState({
                            vuoReference: result.data.vuo_reference,
                            vuoUrl: result.data.vuo_file_url
                        })
                    });
                })
            }
        });
    }

    
    handleDateChange = date => {
        this.setState({
            formData: {
                ...this.state.formData,
                dateAdded: date
            }
        });
    };

    handleCheckChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleChange = (e) => {
        const name = e.target.name;
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        }, 
        () => {
            if(name === 'fileUploads'){
                switch(this.state.fileUploadsDisplay){
                    case 'both':
                        this.setState({showMov: true, showMp4: true});
                    break;
                    case 'mp4_only':
                        this.setState({showMov: false, showMp4: true});
                    break;
                    case 'mov_only':
                        this.setState({showMov: true, showMp4: false});
                    break;
                    default:
                    break;
                }
            }

        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            let newFormData = new FormData();
            Object.keys(this.state.formData).forEach(key => {
                if(key === 'dateAdded'){
                    newFormData.append(key, moment(new Date(this.state.formData[key])).format('DD/MM/YYYY'))
                }
                else{
                    newFormData.append(key, this.state.formData[key])
                }
            });
            API.post(`/videos/${this.state.formData.videoId}`, newFormData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        successRedirect: true,
                        isLoading: false
                    });
                }
                this.props.scrollToTop();
            });
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleSelectChange = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: res.value
            }
        }, 
        () => {
            if(name === 'videoId'){
                this.getVideoDetails(res.value);
            }
        });
    };

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    clearMp4 = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                mp4: initialState.formData.mp4
            }
        });
    };

    clearMp4 = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                mov: initialState.formData.mov
            }
        });
    };

    downloadFile = (fileUrl) => {
        window.open(fileUrl, '_blank');
    }
       
    render() {
        const { formErrors, formData } = this.state;
        if (this.state.successRedirect === true) {
            return <Redirect to={`/videos/view/${this.props.match.params.id}`} />
        } else if (this.state.redirect === true) {
            return <Redirect to="/videos/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Update Video
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="h6">
                                Confirm Video Update Order
                                </Typography>
                                {this.state.vuoReference ?
                                    <MaterialLink component="button" variant="body1" className='blueLink' onClick={() => {this.downloadFile(this.state.vuoUrl)}}>
                                        {this.state.vuoReference}
                                    </MaterialLink>
                                :
                                    '-'
                                }
                                <form noValidate autoComplete="off">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                            checked={this.state.showFileUpload}
                                            onChange={this.handleCheckChange('showFileUpload')}
                                            value="showFileUpload"
                                            color="primary"
                                            />
                                        }
                                        label="Correct Video Update Order"
                                    />
                                    <Typography variant="h6">
                                        New Video(s)
                                    </Typography>
                                    {formErrors && formErrors.generic && (
                                        <React.Fragment>
                                            <Typography component={"div"} style={{color: colors.red}}>
                                                {formErrors.generic}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                    {this.state.showMov &&
                                        <DragFileInput
                                            id="movInput"
                                            name="mov"
                                            label="MOV (.mov) *"
                                            file={this.state.formData.mov}
                                            errorText={formErrors && formErrors['mov']}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={this.clearMp4}
                                            emptyText='No file selected'
                                            disabled={!this.state.showFileUpload}
                                        />
                                    }
                                    {this.state.showMp4 &&
                                        <DragFileInput
                                            id="mp4Input"
                                            name="mp4"
                                            label="MP4 (.mp4) *"
                                            file={this.state.formData.mp4}
                                            errorText={formErrors && formErrors['mp4']}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={this.clearMp4}
                                            emptyText='No file selected'
                                            disabled={!this.state.showFileUpload}
                                        />
                                    }
                                    <DatePicker
                                        type="date"
                                        id="dateAdded"
                                        name="dateAdded"
                                        label="Date Added *"
                                        value={this.state.formData.dateAdded}
                                        errorText={formErrors && formErrors['dateAdded']}
                                        onChange={this.handleDateChange}
                                        disableFuture={true}
                                        disabled={!this.state.showFileUpload}
                                    />
                                    <div className='buttonRow' style={{justifyContent: 'space-between', flexDirection: 'row', alignItems:'center'}}>
                                        <Typography variant="subtitle2" style={{flex: 1}}>
                                            <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                                        </Typography>
                                        <BackButton props={this.props} />
                                        <Button onClick={this.handleConfirmationOpen}
                                                variant="contained"
                                                color="primary"
                                                disabled={!this.state.showFileUpload || this.state.isLoading}>
                                            Update
                                        </Button>
                                    </div>
                                </form>
                                <ConfirmationDialog 
                                    open={this.state.confirmationOpen} 
                                    success={this.handleConfirmationSuccess} 
                                    close={this.handleConfirmationClose} 
                                    title="Update This Video?" 
                                    message="Are you sure you want to update this video?"
                                />
                                <SnackBar
                                    variant="success"
                                    anchorOriginVertical='bottom'
                                    anchorOriginHorizontal='right'
                                    open={this.state.snackbarOpen}
                                    onClose={this.handleSnackbarClose}
                                    message='You have successfully updated this video'
                                />
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="h6">
                                    Video Details
                                </Typography>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="category" shrink={!!this.state.category}>Category</InputLabel>
                                    <Input id="category" value={this.state.category || ''} />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="subCategory" shrink={!!this.state.subCategory}>Sub Category</InputLabel>
                                    <Input id="subCategory" value={this.state.subCategory || ''} />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="title" shrink={!!formData.title}>Title</InputLabel>
                                    <Input id="title" value={formData.title || ''} />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="fileUploads" shrink={!!this.state.fileUploadsDisplay}>File Uploads</InputLabel>
                                    <Input id="fileUploads" value={this.state.fileUploadsDisplay || ''} />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="actualVideoStatus" shrink={!!(this.state.actualVideoStatus)}>Actual Video Status (Based on VUO)</InputLabel>
                                    <Input id="actualVideoStatus" value={(this.state.actualVideoStatus && `v${this.state.actualVideoStatus}`) || ''} />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="currentStatus" shrink={!!(formData.currentStatus)}>Current Status of Video</InputLabel>
                                    <Input id="currentStatus" value={(formData.currentStatus && `v${formData.currentStatus}`) || ''} />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="versionMatch" shrink={true}>Version Match (VUO &amp; Video)</InputLabel>
                                    <Input id="versionMatch" value={this.state.versionMatch} style={{color: this.state.versionMatch === 'Yes' ? colors.green : colors.red}} />
                                </FormControl>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    }
}

export default UpdateVideo;