import { Button, DialogActions, DialogContent, ExpansionPanel, List, ListItem, ExpansionPanelSummary, ExpansionPanelDetails, Grid, ButtonGroup, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { getCurrency, getPercentage } from 'Helpers/ReportsHelper';
import { clenyDate } from 'Helpers/TimeHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { getCurrencyChange } from 'Helpers/ReportsHelper';


const RevisionItem = ({revision,months}) => {
    let [view, setView] = React.useState('month');

    let pc = [];
    _.each(revision.months, m => {
        _.each(m.part_categories, i => {
            if (!pc[i.tympc_pc_id]){
                pc[i.tympc_pc_id] = {
                    id: i.tympc_pc_id,
                    name: i.part_category.pc_name,
                    budget: 0,
                    cost: 0,
                }
            }
            pc[i.tympc_pc_id].budget += parseFloat(i.tympc_budget);
            pc[i.tympc_pc_id].cost += parseFloat(i.tympc_cost);
        })
    })

    return (
        <Grid container spacing={1}>
            {
                _.map([
                    {
                        title: `Per Year (${1})`,
                        div: 1,
                    },
                    {
                        title: `Per Month (${12})`,
                        div: 12,
                    },
                    {
                        title: `Per Week (${52})`,
                        div: 52,
                    },
                    {
                        title: `Per working day (${_.sumBy(months, i => parseInt(i.workingDays))})`,
                        div: _.sumBy(months, i => parseInt(i.workingDays))
                    }
                ], ({title, div}) => 
                    <Grid item xs={3}>
                        <PaddedPaper>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="body1" gutterBottom>
                                        <b>{title}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant='body2' >
                                        <>Sales</>
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant='body2' >
                                        {getCurrency(revision.yt_budget / div)}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant='body2' >
                                        <>Cost</>
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant='body2' >
                                        {getCurrency(revision.yt_cost / div)}
                                    </Typography>
                                </Grid>
                             
                                <Grid item xs={4}>
                                    <Typography variant='body2' >
                                        <>Margin £</> 
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant='body2' >
                                        £{getCurrencyChange( (revision.yt_budget / div) , (revision.yt_cost / div))} 
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant='body2' >
                                        <>Margin %</> 
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant='body2' >
                                        {getPercentage( (revision.yt_budget / div) - (revision.yt_cost / div), (revision.yt_budget / div), 2)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                )
            }
            <Grid item xs={12}>
                <ButtonGroup>
                    <Button
                        onClick={() => setView('month')}
                        variant={view === 'month' ? 'contained' : 'outlined'}
                        color={view === 'month' ? 'primary' : 'default'}
                    >
                        By Month
                    </Button>
                    <Button
                        onClick={() => setView('category')}
                        variant={view === 'category' ? 'contained' : 'outlined'}
                        color={view === 'category' ? 'primary' : 'default'}
                    >
                        By Category
                    </Button>
                </ButtonGroup>
            </Grid>
            {view === 'category' &&
                <Grid item xs={12}>
                    <CiDataTable
                        config={{
                            key: 'id',
                        }}
                        rows={_.filter(_.values(pc), i => i)}
                        columns={[
                            {
                                heading: 'Category',
                                field: 'name',
                            },
                            {
                                heading: 'Sales',
                                field: 'budget',
                                fieldFormat: 'currency',
                            },
                            {
                                heading: '% of Total',
                                field: i => getPercentage(i.budget, revision.yt_budget, 2),
                            },
                            {
                                heading: 'Cost',
                                field: 'cost',
                                fieldFormat: 'currency',
                            },
                            {
                                heading: '% of Total',
                                field: i => getPercentage(i.cost, revision.yt_cost, 2),
                            },
                            {
                                heading: 'Margin £',
                                field: i => i.budget - i.cost,
                                fieldFormat: 'currency',
                            },
                            {
                                heading: '% of Total',
                                field: i => getPercentage(i.budget - i.cost, revision.yt_budget - revision.yt_cost, 2),
                            },
                            {
                                heading: 'Margin %',
                                field: i => getPercentage(i.budget - i.cost, i.budget, 2),
                            },
                        ]}
                    />
                </Grid>
            }
            {view === 'month' &&
                <Grid item xs={12}>
                    <CiDataTable
                        config={{
                            key: 'ytm_id',
                        }}
                        rows={
                            _.orderBy(
                                _.map(revision.months,
                                    m => ({
                                        ...m,
                                        ..._.find(months, i => parseInt(i.month) === parseInt(m.ytm_month)) 
                                    })
                                ),
                                ['year', 'month'],
                                ['asc', 'asc']
                            )
                        }
                        columns={[
                            {
                                heading: 'Month',
                                field: 'name',
                            },
                            {
                                heading: 'Working Days',
                                field: 'workingDays',
                                alignment: 'center',
                            },
                            {
                                heading: 'Sales',
                                field: 'ytm_budget',
                                fieldFormat: 'currency',
                            },
                            {
                                heading: '% of total',
                                field: i => getPercentage(i.ytm_budget, revision.yt_budget),
                            },
                            {
                                heading: 'Cost',
                                field: 'ytm_cost',
                                fieldFormat: 'currency',
                            },
                            {
                                heading: '% of total',
                                field: i => getPercentage(i.ytm_cost, revision.yt_cost),
                            },
                            {
                                heading: 'Margin £',
                                field: i => getCurrency(i.ytm_budget - i.ytm_cost),
                            },
                            {
                                heading: '% of total',
                                field: i => getPercentage(i.ytm_budget - i.ytm_cost, revision.yt_budget - revision.yt_cost),
                            },
                            {
                                heading: 'Margin %',
                                field: i => `${getPercentage((i.ytm_budget - i.ytm_cost), i.ytm_budget)}`,
                            }
                        ]}
                    />
                </Grid>
            }
        </Grid>
    )
}

const initialState = (props) => ({
    isLoading: true,
    revisions: []
})

class showHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        Promise.all([
            API.get(`/performance/year/${this.props.year}`, {params: {withRevisions: true}}),
            API.get('/performance/year/' + this.props.year + '/getMonths')
        ])
        .then(([res, months]) => {            
            this.setState({
                revisions: _.reverse(res.data.revisions),
                months: months.data,
                isLoading: false
            })
        })
    }

    render() {
        const { isLoading, revisions, months } = this.state;

        if (isLoading) { return <LoadingCircle/> }

        return (
            <>
                <List style={{width: '100%'}}>
                    {_.map(revisions, r =>
                        <ListItem style={{width: '100%', padding: 0}}>
                            <ExpansionPanel style={{width: '100%'}}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            Revision: {r.yt_revision}
                                        </Grid>
                                        <Grid item xs={2}>
                                            Date: {clenyDate(r.yt_datetime)}
                                        </Grid>
                                        <Grid item xs={2}>
                                            Staff: {r.yt_staff}
                                        </Grid>
                                        <Grid item xs={2}>
                                            Sales: {getCurrency(r.yt_budget)}
                                        </Grid>
                                        <Grid item xs={2}>
                                            Cost: {getCurrency(r.yt_cost)}
                                        </Grid>
                                        <Grid item xs={3}>
                                            Margin: {getCurrency(r.yt_budget - r.yt_cost)} ({getPercentage((r.yt_budget - r.yt_cost), r.yt_budget)})
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <RevisionItem revision={r} months={months}/>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </ListItem>
                    )}
                </List>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
});

export default connect(null, mapDispatchToProps)(showHistory);
