import React, { Component } from 'react';
import _                    from 'lodash';

import { Typography } from '@material-ui/core/';

import AllIcon     from 'Components/Common/Icons/AllIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import { clenyDate } from 'Helpers/TimeHelper';
import { colors }    from 'Helpers/ColourHelper';
import icons         from 'Helpers/IconHelper';

class StageIndicater extends Component {
    constructor(props) {
        super(props);
    }
    
    render(){

        const { worksOrder } = this.props;

        let stage          = '';
        let secondary      = null;
        let stageIcon      = '';
        let stageTextColor = colors.orange;
        let log            = null;
        let shortage       = !!worksOrder.shortage;

        switch (worksOrder.wo_status) {
            case 'Created':
                log = _.first(worksOrder.logs);

                stage     = 'Created';
                secondary = `This works order was created on ${clenyDate(log.wol_datetime)} by ${log.wol_staff}`
                stageIcon = icons.plus
            break;
            case 'Picking':
                log = shortage ? _.find(worksOrder.logs, {wol_type:'Pick Completed'}) : _.first(worksOrder.logs);

                stage     = shortage ? 'Shortages' : 'Picking';
                secondary = `This works order was ${shortage ? 'Picked' : 'created'} on ${clenyDate(log.wol_datetime)} by ${log.wol_staff}`
                stageIcon = icons.picking
            break;
            case 'Build':
                let write_off = !!worksOrder.active_write_off;
                log           =  write_off ? _.findLast(worksOrder.logs, {wol_type:'Write Off Created'}) : _.find(worksOrder.logs, {wol_type: 'Build Created'});

                stage          = write_off ? 'Write Off' : 'Build';
                secondary      = `${write_off ? 'Works order write off created' : 'Works order build Created'} on ${clenyDate(log.wol_datetime)} by ${log.wol_staff}`
                stageIcon      = write_off ? icons.broken : icons.worksOrder;
                stageTextColor = write_off ? colors.red : stageTextColor;
            break;
            case 'Review':
                log = _.find(worksOrder.logs, {wol_type: 'Submitted For Review'});
                stage          = 'Review';
                secondary      = `Submitted For Review on ${clenyDate(log.wol_datetime)} by ${log.wol_staff}`
                stageIcon      = icons.review
                stageTextColor = stageTextColor;
            break;
            case 'Completed':
                stage          = 'Completed';
                secondary      = `Works Order completed on ${clenyDate(worksOrder.wo_completed)} by ${_.last(worksOrder.logs).wol_staff}`
                stageIcon      = icons.true;
                stageTextColor = colors.green;
            break;
            case 'Cancelled':
                stage          = 'Cancelled';
                secondary      = `Works Order cancelled on ${clenyDate(_.last(worksOrder.logs).wol_datetime)} by ${_.last(worksOrder.logs).wol_staff}`
                stageIcon      = icons.false;
                stageTextColor = colors.red;
            break;

        }

        return (
            <PaddedPaper style={{color: stageTextColor}}>
                <Typography variant="h5" gutterBottom style={{display: 'flex', alignItems: 'center'}}>
                    <AllIcon icon={stageIcon} size='large' color={stageTextColor}/>{stage}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    {secondary}
                </Typography>
            </PaddedPaper>
        )
    }
}


export default StageIndicater