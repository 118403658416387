import React, { PureComponent } from 'react';
import DataTable from 'Components/Common/DataTables/DataTable';

class InvoicePartItems extends PureComponent {
    trimPartDescription = partDescription => {
        if(partDescription) {
            if(partDescription.length > 50){
                partDescription = partDescription.substring(0, 50) + '...';
            }
        } else {
            partDescription = '';
        }
        return partDescription;
    }
    render() {
        const { invoiceDetails, orderType, handleNotesDialogOpen } = this.props; 
        const columns = (orderType === 'With Discount' ?
            [
                {
                    heading: 'Details',
                    field: rowData =>   <>
                                            {rowData.invoice_detail_part_number + ' - ' + this.trimPartDescription(rowData.invoice_detail_part_description) }
                                            {/* Electrical Connections */}
                                            {rowData.invoice_detail_electrical_connection ?  
                                                <span style={{color: '#757575', fontSize: '12px'}}> 
                                                    {rowData.invoice_detail_electrical_connection ? <><br></br>{`${rowData.invoice_detail_electrical_connection} Connection`}</>: '' }
                                                </span>
                                                : ''
                                            }
                                            {/* Warranty */}
                                            {rowData.invoice_detail_warranty_name && rowData.invoice_detail_warranty_duration != 0 &&
                                                    <><br /><span style={{color: '#9E9E9E', fontSize: '0.7rem'}}>{rowData.invoice_detail_warranty_name}</span></>
                                            }
                                            {/* Serial Numbers */}
                                            {JSON.parse(rowData.invoice_detail_serial_numbers) &&
                                                JSON.parse(rowData.invoice_detail_serial_numbers).map((item, key) => {
                                                    return <><br/><span style={{color: '#9E9E9E', fontSize: '0.7rem'}} key={key}>{item}</span></>
                                                })
                                            }
                                            {/* Warranty Void Labels */}
                                            {JSON.parse(rowData.invoice_detail_warranty_labels) &&
                                                JSON.parse(rowData.invoice_detail_warranty_labels).map((item, key) => {
                                                    return <><br/><span style={{color: '#9E9E9E', fontSize: '0.7rem'}} key={key}>{item}</span></>
                                                })
                                            }
                                            {/* Notes */}
                                            {rowData.invoice_detail_notes && rowData.invoice_detail_notes.split('\n').map((item, key) => {
                                                return <><br/><span style={{fontSize: '0.7rem', whiteSpace:'pre-wrap'}} key={key}>{item}</span></>
                                            })}
                                        </>,
                    sizeToContent: true

                },
                {
                    heading: 'Quantity',
                    field: rowData => parseFloat(rowData.invoice_detail_quantity),
                    alignment: 'right'
                },
                {
                    heading: 'Unit Price',
                    field: rowData => '£' + parseFloat(rowData.invoice_detail_unit_price).toFixed(2),
                    alignment: 'right'
                },
                {
                    heading: 'Discount Rate',
                    field: rowData => parseFloat(rowData.invoice_detail_discount_rate).toFixed(2) + '%',
                    alignment: 'right'
                },
                {
                    heading: 'Discount Total',
                    field: rowData => '£' + parseFloat(rowData.invoice_detail_discount_total).toFixed(2),
                    alignment: 'right'
                },
                {
                    heading: 'New Unit Price',
                    field: rowData => '£' + parseFloat(rowData.invoice_detail_new_unit_price).toFixed(2),
                    alignment: 'right'
                },
                {
                    heading: 'Net Amount',
                    field: rowData => '£' + parseFloat(rowData.invoice_detail_total_price).toFixed(2),
                    alignment: 'right'
                },
                {
                    actions: rowData => {
                        return [
                            {name: 'Update', icon: 'pencil-alt', onClick: () => handleNotesDialogOpen(rowData)}
                        ]
                    }
                }
            ] 
                :
            [
                {
                    heading: 'Details',
                    field: rowData =>   <>
                                        {rowData.invoice_detail_part_number + ' - ' + this.trimPartDescription(rowData.invoice_detail_part_description) }
                                        {/* Warranty */}
                                        {rowData.invoice_detail_warranty_name && rowData.invoice_detail_warranty_duration != 0 &&
                                                <><br /><span style={{color: '#9E9E9E', fontSize: '0.7rem'}}>{rowData.invoice_detail_warranty_name}</span></>
                                        }
                                        {/* Serial Numbers */}
                                        {JSON.parse(rowData.invoice_detail_serial_numbers) &&
                                            JSON.parse(rowData.invoice_detail_serial_numbers).map((item, key) => {
                                                return <><br/><span style={{color: '#9E9E9E', fontSize: '0.7rem'}} key={key}>{item}</span></>
                                            })
                                        }
                                        {/* Warranty Void Labels */}
                                        {JSON.parse(rowData.invoice_detail_warranty_labels) &&
                                            JSON.parse(rowData.invoice_detail_warranty_labels).map((item, key) => {
                                                return <><br/><span style={{color: '#9E9E9E', fontSize: '0.7rem'}} key={key}>{item}</span></>
                                            })
                                        }
                                        {/* Notes */}
                                        {rowData.invoice_detail_notes && rowData.invoice_detail_notes.split('\n').map((item, key) => {
                                            return <><br/><span style={{fontSize: '0.7rem', whiteSpace:'pre-wrap'}} key={key}>{item}</span></>
                                        })}
                                        </>,
                    sizeToContent: true
                },
                {
                    heading: 'Quantity',
                    field: rowData => parseFloat(rowData.invoice_detail_quantity).toFixed(3),
                    alignment: 'right'
                },
                {
                    heading: 'Unit Price',
                    field: rowData => '£' + parseFloat(rowData.invoice_detail_unit_price).toFixed(2),
                    alignment: 'right'
                },
                {
                    heading: 'Net Amount',
                    field: rowData => '£' + parseFloat(rowData.invoice_detail_total_price).toFixed(2),
                    alignment: 'right'
                },
                {
                    actions: rowData => {
                        return [
                            {name: 'Update', icon: 'pencil-alt', onClick: () => handleNotesDialogOpen(rowData)}
                        ]
                    }
                }
            ]);        
        return(
            <DataTable  
                config={{
                    key: 'part',
                }}
                columns={columns}
                rows={invoiceDetails}
            />
        );
    }
}

export default InvoicePartItems;
