import React from 'react';

import { Grid, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import AllIcon from 'Components/Common/Icons/AllIcon';

import icons from 'Helpers/IconHelper';
class NumberField extends React.Component {

    handleIncrease = () => {
        let granularity = this.props.granularity || 1;
        let max = parseFloat(this?.props?.inputProps?.max)
        let val = this.props.value ? this.props.value : 0;
        if (max && ( (parseFloat(val) + granularity) > max ) ) this.handleOnChange(max);
        else this.props.onChange && this.handleOnChange(parseFloat(val) + granularity, 3);
    }

    handleDecrease = () => {
        let granularity = this.props.granularity || 1;
        let min = parseFloat(this?.props?.inputProps?.min)
        if  (this?.props?.inputProps?.min !== null && ( (parseFloat(this.props.value) - granularity) < min ) ) this.handleOnChange(min);
        else this.props.onChange && this.handleOnChange(parseFloat(this.props.value || 0) - granularity, 3);
    }

    handleOnChange = (value, fix = 0) => {
        this.props.onChange && this.props.onChange(fix ? parseFloat(value).toFixed(fix) : parseFloat(value));
    }

    render() {
        const { inputProps, className, variant, label, value, classes, hidePlusMinusButtons } = this.props;
        
        return (
            <>
                {label && <small style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{label}</small>}
                <div className={ `${classes.container}`}>
                    <Grid container style={{alignItems: 'center', width: '100%', flexWrap: 'nowrap'}} className={ `${className}`}>
                        {!hidePlusMinusButtons && 
                            <Grid item style={{width: 44, borderRight: '1px solid rgba(0, 0, 0, 0.23)', minHeight: 56, paddingTop: 6}}>
                                <AllIcon heavy noMargin onClick={this.handleDecrease} icon={icons.minus}/>
                            </Grid>
                        }
                        <Grid item style={{maxWidth: hidePlusMinusButtons ? '400 !important' : 'calc(400px - 88px) !important', height: '100%', width: '100%'}}>
                            <TextField
                                value={parseFloat(value)}
                                variant={variant || "outlined"}
                                disableUnderline={false}
                                className={classes.input}
                                fullWidth
                                {...this.props}
                                InputProps={{
                                    ...inputProps,
                                    classes: { notchedOutline: classes.noBorder },
                                    style:   { color: this.props.disableKeyboard && '#000' ,...this.props.style}
                                }}
                                onChange={e => this.handleOnChange(e.target.value)}
                                onBlur={e => this.handleOnChange(e.target.value, 3)}
                                label=''
                                margin="none"
                                type="number"
                                disabled={this.props.disabled || this.props.disableKeyboard}
                            />
                        </Grid>
                        {!hidePlusMinusButtons && 
                            <Grid item style={{width: 44, borderLeft: '1px solid rgba(0, 0, 0, 0.23)', minHeight: 56, paddingTop: 6}}>
                                <AllIcon heavy noMargin onClick={this.handleIncrease} icon={icons.plus} />
                            </Grid>
                        }
                    </Grid>
                </div>
            </>
        );
    }

}

const styles = theme => ({
    container: {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 4,
        width: '100%',
        '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
        }
    },
    noBorder: {
        border: "none",
    },
    input: {
        '& input[type=number]': {
            '-moz-appearance':    'textfield',
            '-webkit-appearance': 'none',
            boxShadow:            'none !important',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-autofill' : {
            background: '#ccc !important',
        }
    },
});

export default  withStyles(styles)(NumberField);
