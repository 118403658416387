import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import API from '../../../../API';
import DataTable from '../../../Common/DataTables/DataTable';

class SuppliersAndCosts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            costs: [],
            reference: '',
            supplier: '',
            supplierId: 0,
            open: false
        }
    }

    componentDidMount(){
        // this.getDefaultCost();
    }

    getDefaultCost = () => {
        API.get(`/parts/${this.props.partId}/costs/default`)
        .then((costRes) =>  {
            if(costRes.data){
                this.setState({
                    costs: [{
                        ...this.state.costs,
                        costId: costRes.data.cost_id,
                        qty: costRes.data.cost_qty,
                        cost: costRes.data.cost,
                        costPerUnit: costRes.data.cost_per_unit,
                        dateFrom: moment(costRes.data.cost_date_from).format("DD-MM-YYYY"),
                        dateTo: 'Current',
                    }],
                    reference:  costRes.data.cost_reference,
                    supplier:   costRes.data.supplier.supp_company_name,
                    supplierId: costRes.data.supplier.supp_id,
                    open: true
                });
            }
        })
    }

    handleOpen = () => {
        if(!this.state.open){
            this.getDefaultCost();
        } else {
            this.setState({
                open: false
            });
        }
    }

    render() {

        const { open } = this.state;

        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={this.handleOpen}>
                    <Typography>Suppliers & Costs</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {open &&this.state.costs && this.state.costs.length > 0 ? 
                        <Grid container spacing={3}>
                            <Grid item xs={12} style={{marginTop:10}}>
                                <Typography variant="body1" gutterBottom>
                                    <b>Supplier:</b> {this.state.supplier}
                                </Typography>
                                <Typography variant="body1">
                                    <b>Reference:</b> {this.state.reference}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <DataTable  
                                    config={{
                                        key:                    'costId',
                                        alternatingRowColours:  true,
                                        plainHeader:            true,
                                    }}
                                    columns={[
                                        {
                                            heading: 'Qty',
                                            field: rowData => rowData.qty
                                        },
                                        {
                                            heading: 'Cost',
                                            field: rowData => rowData.cost,
                                            fieldPrefix: '£',
                                        },
                                        {
                                            heading: 'Cost Per Unit',
                                            field: rowData => rowData.costPerUnit,
                                            fieldPrefix: '£',
                                        },
                                        {
                                            heading: 'Date From',
                                            field: rowData => rowData.dateFrom,
                                        },
                                        {
                                            heading: 'Date to',
                                            field: rowData => rowData.dateTo,
                                        }
                                    ]}
                                    rows={this.state.costs}
                                />
                                <div className='buttonRow'>
                                    <Link to={`/suppliers/view/${this.state.supplierId}/parts`}>
                                        <Button variant="outlined">View Supplier Parts</Button>
                                    </Link>
                                    <Link to={`/costs/view/${this.props.partId}`}>
                                        <Button variant="outlined">View Full Costs Details</Button>
                                    </Link>
                                </div>
                            </Grid>
                        </Grid>
                    :
                        <Typography>There are 0 Costs found.</Typography>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default SuppliersAndCosts;
