import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, TextField, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import API from 'API';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import MediaTable from 'Components/Common/DataTables/MediaTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import DragFileInputMulti from 'Components/Common/Inputs/DragFileInputMulti';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SelectPartWithQR from 'Components/Common/Selects/SelectPartWithQR';
import { downloadS3File } from 'Functions/MiscFunctions';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import InputHelper from 'Helpers/InputHelper';
import { clenyDate } from 'Helpers/TimeHelper';
import _ from 'lodash';
import React from 'react';
import EditNoteDialog from './EditNoteDialog';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { connect } from 'react-redux';
import { deployConfirmation } from "Actions/Confirmation/Confirmation";
import { pdfFromBase64 } from 'Helpers/PDFHelper';

const initialState = (props) => ({
    isLoading: true,
    noteLoading: false,
    repairOrder: {},
    partsUsed: [],
    view: 'parts',
    partsFormData: {
        part: null,
        qty: 1,
    },
    serviceFormData: {
        part: null,
        qty: 1,
    },
    notesFormData: {
        note: '',
        files: [],
    },
    writeOffFormData: {
        reason: '',
        notes: '',
        files: [],
        parts: [],
        services: []
    },
    lists: {
        parts: [],
        reasons: [],
    },
    postRepairDialog: {
        open: false,
        notes: '',
        files: [],
    },
    editNoteDialog: null,
})

class RepairDialog extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState(props);
        this.inputHelper = new InputHelper(this);
    }

    componentDidMount() {
        this.getRepair();
        this.getParts();
    }

    getRepair = () => {
        API.get(`/customerReturns/repairOrders/${this.props.repairOrder.ro_group_id}/${this.props.repairOrder.ro_id}`).then(res => {
            this.setState({ repairOrder: res.data, isLoading: false }, this.setRepairStarted);
        });
    }

    setRepairStarted = () => {
        this.state.repairOrder.ro_status === 'Pending' && API.post(`/customerReturns/repairOrders/${this.props.repairOrder.ro_group_id}/${this.props.repairOrder.ro_id}/start`);
    }

    getParts = () => {
        Promise.all([
            API.get('/customerReturns/repairOrders/writeOffReasons'),
            API.get('/parts/all' , {params: {active: true, hasPrice: true, use: 'quotation'}})
        ]).then(res => {
            this.setState({ lists: { 
                reasons: _.map(res[0].data, i => ({value: i.rowor_name, label: i.rowor_name})),
                parts: _.map(res[1].data, i => ({
                    part: i,
                    value: i.part_id,
                    label: i.part_number + ' - ' + i.part_description,
                })) 
            } });
        });
    }

    addPart = () => {
        API.post(`/customerReturns/repairOrders/${this.props.repairOrder.ro_group_id}/${this.props.repairOrder.ro_id}/addPart`, {
            ...this.state.partsFormData,
            qty: parseFloat(this.state.partsFormData.qty).toFixed(3)
        }).then(()=>{
            this.getRepair();
            this.setState({ partsFormData: initialState().partsFormData });
        })
    }

    addService = () => {
        API.post(`/customerReturns/repairOrders/${this.props.repairOrder.ro_group_id}/${this.props.repairOrder.ro_id}/addPart`, {
            ...this.state.serviceFormData,
            qty: parseFloat(this.state.serviceFormData.qty).toFixed(3)
        }).then(()=>{
            this.getRepair();
            this.setState({ serviceFormData: initialState().serviceFormData });
        })
    }

    removePart = (part) => {
        API.post(`/customerReturns/repairOrders/${this.props.repairOrder.ro_group_id}/${this.props.repairOrder.ro_id}/removePart`, {
            ropId: part.rop_id,
        }).then(this.getRepair)
    }

    addEstimatedPart = (row) => () => {
        API.post(`/customerReturns/repairOrders/${this.props.repairOrder.ro_group_id}/${this.props.repairOrder.ro_id}/addPart`, {
            part: row.part.part_id,
            qty: parseFloat(row.roep_qty).toFixed(3)
        }).then(this.getRepair)
    }

    addNote = () => {
        this.setState({
            noteLoading: true,
        }, ()=> {
            let newFormData = new FormData();
            newFormData.append('note', this.state.notesFormData.note);
            _.each(this.state.notesFormData.files, i => newFormData.append('files[]', i))
            
            API.post(`/customerReturns/repairOrders/${this.props.repairOrder.ro_group_id}/${this.props.repairOrder.ro_id}/addNote`, newFormData).then(()=>{
                this.getRepair();
                this.setState({ notesFormData: initialState().notesFormData, noteLoading: false });
            })
        })
    }

    checkRepairSubmit = () => {
        this.setState({ postRepairDialog: { ...initialState().postRepairDialog, open: true } });
    }

    submitRepair = () => {
        API.post(`/customerReturns/repairOrders/${this.props.repairOrder.ro_group_id}/${this.props.repairOrder.ro_id}/submit`)
        .then(this.props.closeDialog)
    }

    editNote = (note=null) => () => {
        this.setState({
            editNoteDialog: note,
        }, ()=>{
            if (!note){ this.getRepair();}
        })
    }

    deleteNote = (note) => () => {
        API.post(`/customerReturns/repairOrders/${this.props.repairOrder.ro_group_id}/${this.props.repairOrder.ro_id}/deleteNote`, {id: note.ron_id}).then(this.getRepair);
    }

    submitWriteOff = () => {
        let newFormData = new FormData();
        newFormData.append('reason', this.state.writeOffFormData.reason);
        newFormData.append('notes', this.state.writeOffFormData.notes);
        _.each(this.state.writeOffFormData.files, f => { newFormData.append(`files[]`, f); });
        newFormData.append('parts', JSON.stringify(_.map(this.state.writeOffFormData.parts, i => ({part: i.part.part_id, qty: i.rop_qty}))));
        newFormData.append('services', JSON.stringify(_.map(this.state.writeOffFormData.services, i => ({part: i.part.part_id, qty: i.rop_qty}))));
        API.post(`/customerReturns/repairOrders/${this.props.repairOrder.ro_group_id}/${this.props.repairOrder.ro_id}/writeOff`, newFormData)
        .then(this.props.closeDialog)
    }

    downloadCQ = () => {
        API.get('/sales/quotations/' + this.props.repairGroup.quote.quote_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    render() {
        
        const { repairOrder, isLoading, view, partsFormData, lists, notesFormData, postRepairDialog, noteLoading, serviceFormData, editNoteDialog, writeOffFormData } = this.state;

        if (isLoading) return ( <LoadingCircle /> );

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={1} style={{alignItems: 'center'}}>
                            <Grid item style={{width: '110px'}}>
                                <ImageWithError
                                    src={repairOrder?.part?.default_image?.file_path}
                                    alt="Repair Image"
                                    style={{maxHeight: '100px', maxWidth: '100px'}}
                                />
                            </Grid>
                            <Grid item style={{width: 'calc(100% - 110px)'}}>
                                <Typography variant='h5'>{repairOrder?.part?.part_number}</Typography>
                                <Typography variant='h6'>{repairOrder?.part?.part_description}</Typography>
                                {((repairOrder.ro_sn) !== '[]' &&
                                    <Typography variant='body1'>{(repairOrder.ro_sn)}</Typography>
                                )}
                                {((repairOrder.ro_wvl) !== '[]' &&
                                    <Typography variant='body1'>{(repairOrder.ro_wvl)}</Typography>
                                )}
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <ButtonGroup
                        variant='contained'
                        color='primary'
                        fullWidth
                    >
                        <Button
                            variant={view !== 'parts' && 'outlined'}
                            onClick={this.inputHelper.handleButtonChange('view', 'parts')}
                            style={{
                                backgroundColor: view === 'parts' && (_.filter(repairOrder.parts, i => i.part.stockable).length < 1 ? colors.orange : colors.green),
                                borderColor: (_.filter(repairOrder.parts, i => i.part.stockable).length < 1 ? colors.orange : colors.green),
                                color: view !== 'parts' && (_.filter(repairOrder.parts, i => i.part.stockable).length < 1 ? colors.orange : colors.green),
                            }}
                        >Add Parts</Button>
                        <Button
                            variant={view !== 'services' && 'outlined'}
                            onClick={this.inputHelper.handleButtonChange('view', 'services')}
                            style={{
                                backgroundColor: view === 'services' && (_.filter(repairOrder.parts, i => !i.part.stockable).length < 1 ? colors.orange : colors.green),
                                borderColor: (_.filter(repairOrder.parts, i => !i.part.stockable).length < 1 ? colors.orange : colors.green),
                                color: view !== 'services' && (_.filter(repairOrder.parts, i => !i.part.stockable).length < 1 ? colors.orange : colors.green),
                            }}
                        >Add Services</Button>
                        <Button
                            onClick={this.inputHelper.handleButtonChange('view', 'notes')}
                            variant={view !== 'notes' && 'outlined' }
                            style={{
                                backgroundColor: view === 'notes' && (repairOrder.notes.length < 1 ? colors.red : colors.green),
                                borderColor: (repairOrder.notes.length < 1 ? colors.red : colors.green),
                                color: view !== 'notes' && (repairOrder.notes.length < 1 ? colors.red : colors.green),
                            }}
                        >Add Notes</Button>
                        <Button
                            onClick={()=>{
                                this.setState({
                                    view: 'writeOff',
                                    writeOffFormData: {
                                        ...writeOffFormData,
                                        parts: _.filter(repairOrder.parts, i => i.part.stockable),
                                        services: _.filter(repairOrder.parts, i => !i.part.stockable),
                                    }
                                })
                            }}
                            variant={
                                (repairOrder.notes.length > 0) ?
                                'contained' : ( view !== 'writeOff' && 'outlined' )
                            }
                            style={{
                                backgroundColor: view === 'writeOff' && (repairOrder.notes.length < 1 ? colors.red : colors.green),
                                color: view !== 'writeOff' && (repairOrder.notes.length < 1 ? colors.red : colors.green),
                            }}
                            disabled={repairOrder.notes.length > 0}
                        >Write Off</Button>
                    </ButtonGroup>
                </Grid>
                {view === 'services' &&
                    <>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {lists.parts.length > 0 &&
                                            <SelectPartWithQR
                                                parts=      {_.filter(lists.parts, i => !i.part.stockable)}
                                                onChange=   {this.inputHelper.handleSelectChange('serviceFormData.part')}
                                                value=      {serviceFormData.part}
                                                margin=     "none"
                                                label=      "Service *"
                                            />
                                        }
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            label='Quantity *'
                                            fullWidth
                                            type='number'
                                            value={serviceFormData.qty}
                                            name={'serviceFormData.qty'}
                                            onChange={this.inputHelper.handleNumberInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            fullWidth
                                            onClick={this.addService}
                                            disabled={!serviceFormData.part || parseFloat(serviceFormData.qty) <= 0}
                                        >Add Service</Button>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        {
                            _.filter(repairOrder.parts, i => !i.part.stockable).length > 0 &&
                            <Grid item xs={12}>
                                <ExpansionPanel
                                    expanded={true}
                                >
                                    <ExpansionPanelSummary style={{backgroundColor: colors.green}}>
                                        <Typography>Services Added</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <CiDataTable
                                                    config={{
                                                        key: 'rop_id'
                                                    }}
                                                    rows={_.filter(repairOrder.parts, i => !i.part.stockable)}
                                                    columns={[
                                                        {
                                                            heading: '',
                                                            field: 'part.default_image.file_path',
                                                            fieldFormat: 'image',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: 'Part',
                                                            field: i => <>
                                                                <Typography variant='body2'>{i.part.part_number}</Typography>
                                                                <Typography variant='caption'>{i.part.part_description}</Typography>
                                                            </>,
                                                        },
                                                        {
                                                            heading: 'qty',
                                                            field: 'rop_qty',
                                                            fieldFormat: 'decimal:3',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            actions: i => ([
                                                                {
                                                                    name: 'Remove',
                                                                    icon: IconHelper.delete,
                                                                    onClick: this.removePart,
                                                                }
                                                            ])
                                                        }
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                        }
                        {
                            _.filter(repairOrder.estimated_parts, i => (!_.find(repairOrder.parts, j => parseInt(j.rop_part_id) === parseInt(i.roep_part_id))) && !i.part.stockable).length > 0 &&
                            <Grid item xs={12}>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{backgroundColor: colors.orange}}>
                                        <Typography>Recommended Services</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <CiDataTable
                                            config={{
                                                key: 'rop_id'
                                            }}
                                            rows={_.filter(repairOrder.estimated_parts, i => (!_.find(repairOrder.parts, j => parseInt(j.rop_part_id) === parseInt(i.roep_part_id))) && !i.part.stockable)}
                                            columns={[
                                                {
                                                    heading: '',
                                                    field: 'part.default_image.file_path',
                                                    fieldFormat: 'image',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Part',
                                                    field: i => <>
                                                        <Typography variant='body2'>{i.part.part_number}</Typography>
                                                        <Typography variant='caption'>{i.part.part_description}</Typography>
                                                    </>,
                                                },
                                                {
                                                    heading: 'qty',
                                                    field: 'roep_qty',
                                                    fieldFormat: 'decimal:3',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    actions: i => ([
                                                        {
                                                            name: 'Add',
                                                            icon: IconHelper.plus,
                                                            onClick: this.addEstimatedPart(i),
                                                        }
                                                    ])
                                                }
                                            ]}
                                        />
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                        }
                    </>
                }
                {view === 'parts' &&
                    <>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {lists.parts.length > 0 &&
                                            <SelectPartWithQR
                                                parts=      {_.filter(lists.parts, i => i.part.stockable)}
                                                onChange=   {this.inputHelper.handleSelectChange('partsFormData.part')}
                                                value=      {partsFormData.part}
                                                margin=     "none"
                                            />
                                        }
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            label='Quantity *'
                                            fullWidth
                                            type='number'
                                            value={partsFormData.qty}
                                            name={'partsFormData.qty'}
                                            onChange={this.inputHelper.handleNumberInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            fullWidth
                                            onClick={this.addPart}
                                            disabled={!partsFormData.part || parseFloat(partsFormData.qty) <= 0}
                                        >Add Part</Button>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        {
                            _.filter(repairOrder.parts, i => i.part.stockable).length > 0 &&
                            <Grid item xs={12}>
                                <ExpansionPanel
                                    expanded={true}
                                >
                                    <ExpansionPanelSummary style={{backgroundColor: colors.green}}>
                                        <Typography>Parts Used</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <CiDataTable
                                                    config={{
                                                        key: 'rop_id'
                                                    }}
                                                    rows={_.filter(repairOrder.parts, i => i.part.stockable)}
                                                    columns={[
                                                        {
                                                            heading: '',
                                                            field: 'part.default_image.file_path',
                                                            fieldFormat: 'image',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: 'Part',
                                                            field: i => <>
                                                                <Typography variant='body2'>{i.part.part_number}</Typography>
                                                                <Typography variant='caption'>{i.part.part_description}</Typography>
                                                            </>,
                                                        },
                                                        {
                                                            heading: 'qty',
                                                            field: 'rop_qty',
                                                            fieldFormat: 'decimal:3',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            actions: i => ([
                                                                {
                                                                    name: 'Remove',
                                                                    icon: IconHelper.delete,
                                                                    onClick: this.removePart,
                                                                }
                                                            ])
                                                        }
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                        }
                        {
                            _.filter(repairOrder.estimated_parts, i => (!_.find(repairOrder.parts, j => parseInt(j.rop_part_id) === parseInt(i.roep_part_id))) && i.part.stockable).length > 0 &&
                            <Grid item xs={12}>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{backgroundColor: colors.orange}}>
                                        <Typography>Recommended Parts</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <CiDataTable
                                            config={{
                                                key: 'rop_id'
                                            }}
                                            rows={_.filter(repairOrder.estimated_parts, i => (!_.find(repairOrder.parts, j => parseInt(j.rop_part_id) === parseInt(i.roep_part_id))) && i.part.stockable)}
                                            columns={[
                                                {
                                                    heading: '',
                                                    field: 'part.default_image.file_path',
                                                    fieldFormat: 'image',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Part',
                                                    field: i => <>
                                                        <Typography variant='body2'>{i.part.part_number}</Typography>
                                                        <Typography variant='caption'>{i.part.part_description}</Typography>
                                                    </>,
                                                },
                                                {
                                                    heading: 'qty',
                                                    field: 'roep_qty',
                                                    fieldFormat: 'decimal:3',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    actions: i => ([
                                                        {
                                                            name: 'Add',
                                                            icon: IconHelper.plus,
                                                            onClick: this.addEstimatedPart(i),
                                                        }
                                                    ])
                                                }
                                            ]}
                                        />
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                        }
                    </>
                }
                {view === 'notes' &&
                    <>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                {noteLoading ? <LoadingCircle/> :
                                    <Grid container >
                                        <Grid item xs={12}>
                                            <Textarea
                                                label='Note *'
                                                fullWidth
                                                value={notesFormData.note}
                                                name='notesFormData.note'
                                                onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DragFileInputMulti
                                                label='Files'
                                                fullWidth
                                                file={notesFormData.files}
                                                name='notesFormData.files'
                                                onChange={this.inputHelper.handleMultiFileChange('notesFormData.files')}
                                                cancelOnClick={this.inputHelper.handleMultiClearFile('notesFormData.files')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className='buttonRow'>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                onClick={this.addNote}
                                                disabled={!notesFormData.note}
                                            >Add Note</Button>
                                        </Grid>
                                    </Grid>
                                }
                            </PaddedPaper>
                        </Grid>
                        {
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Grid container spacing={1}>
                                        {_.map(repairOrder.notes, i => 
                                            <Grid item xs={12} key={i.ron_id}
                                                style={{
                                                    borderBottom: `1px solid ${colors.disabled}`,
                                                }}
                                            >
                                                <Grid container>
                                                    <Grid item xs={11}>
                                                        <Typography variant='caption'>{i.ron_staff} - {clenyDate(i.ron_datetime)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={1} style={{textAlign: 'right'}}>
                                                        <AllIcon
                                                            icon={IconHelper.edit}
                                                            noMargin
                                                            onClick={this.editNote(i)}
                                                        />
                                                        <AllIcon
                                                            icon={IconHelper.bin}
                                                            noMargin
                                                            onClick={this.deleteNote(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant='body2' style={{whiteSpace: 'pre-wrap'}}>{i.ron_note}</Typography>
                                                    </Grid>
                                                    {i.files.length > 0 &&
                                                        <Grid item xs={12} style={{marginBottom: '0.5em'}}>
                                                            <MediaTable
                                                                config={{ 
                                                                    itemPerRow: 3,
                                                                    mobileItemPerRow: 3,
                                                                }}
                                                                media='file_path'
                                                                items={i.files}
                                                            />
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        }
                    </>
                }
                {view === 'writeOff' &&
                    <>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <AutoCompleteSelect
                                            label='Reason *'
                                            options={lists?.reasons}
                                            value={writeOffFormData?.reason}
                                            onChange={this.inputHelper.handleSelectChange('writeOffFormData.reason')}
                                            fullWidth
                                            noClear
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Textarea
                                            label='Note *'
                                            fullWidth
                                            value={writeOffFormData.notes}
                                            name='writeOffFormData.notes'
                                            onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DragFileInputMulti
                                            label='Files'
                                            fullWidth
                                            file={writeOffFormData.files}
                                            name='writeOffFormData.files'
                                            onChange={this.inputHelper.handleMultiFileChange('writeOffFormData.files')}
                                            cancelOnClick={this.inputHelper.handleMultiClearFile('writeOffFormData.files')}
                                        />
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        {
                            writeOffFormData.parts.length > 0 &&
                            <Grid item xs={12}>
                                <ExpansionPanel
                                    expanded={true}
                                >
                                    <ExpansionPanelSummary style={{backgroundColor: colors.red}}>
                                        <Typography>Parts Used</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <CiDataTable
                                                    config={{
                                                        key: 'rop_id'
                                                    }}
                                                    rows={writeOffFormData.parts}
                                                    columns={[
                                                        {
                                                            heading: '',
                                                            field: 'part.default_image.file_path',
                                                            fieldFormat: 'image',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: 'Part',
                                                            field: i => <>
                                                                <Typography variant='body2'>{i.part.part_number}</Typography>
                                                                <Typography variant='caption'>{i.part.part_description}</Typography>
                                                            </>,
                                                        },
                                                        {
                                                            heading: 'qty',
                                                            field: 'rop_qty',
                                                            fieldFormat: 'decimal:3',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            actions: i => ([
                                                                {
                                                                    name: 'Remove',
                                                                    icon: IconHelper.delete,
                                                                    onClick: ()=>{
                                                                        this.setState({
                                                                            writeOffFormData: {
                                                                                ...writeOffFormData,
                                                                                parts: writeOffFormData.services.filter(j => j.rop_id !== i.rop_id)
                                                                            }
                                                                        })
                                                                    },
                                                                }
                                                            ])
                                                        }
                                                        
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                        }
                        {
                            writeOffFormData.services.length > 0 &&
                            <Grid item xs={12}>
                                <ExpansionPanel
                                    expanded={true}
                                >
                                    <ExpansionPanelSummary style={{backgroundColor: colors.red}}>
                                        <Typography>Services Added</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <CiDataTable
                                                    config={{
                                                        key: 'rop_id'
                                                    }}
                                                    rows={writeOffFormData.services}
                                                    columns={[
                                                        {
                                                            heading: '',
                                                            field: 'part.default_image.file_path',
                                                            fieldFormat: 'image',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: 'Part',
                                                            field: i => <>
                                                                <Typography variant='body2'>{i.part.part_number}</Typography>
                                                                <Typography variant='caption'>{i.part.part_description}</Typography>
                                                            </>,
                                                        },
                                                        {
                                                            heading: 'qty',
                                                            field: 'rop_qty',
                                                            fieldFormat: 'decimal:3',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            actions: i => ([
                                                                {
                                                                    name: 'Remove',
                                                                    icon: IconHelper.delete,
                                                                    onClick: ()=>{
                                                                        this.setState({
                                                                            writeOffFormData: {
                                                                                ...writeOffFormData,
                                                                                services: writeOffFormData.services.filter(j => j.rop_id !== i.rop_id)
                                                                            }
                                                                        })
                                                                    },
                                                                }
                                                            ])
                                                        }
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                        }
                    </>
                }
                {repairOrder.parts.length < 1 &&
                    <Grid item xs={12}>
                        <Typography variant='body1' style={{color: colors.red, textAlign: 'right'}}>Parts or services must be added to process this repair</Typography>
                    </Grid>
                }
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant='outlined'
                        onClick={() => this.props.closeDialog()}
                    >Close & Save</Button>
                    {this.props.repairGroup?.quote &&
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={this.downloadCQ}
                        >Download Quote</Button>
                    }
                    {repairOrder?.part?.pdf_drawing.url &&
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={()=>downloadS3File(repairOrder?.part?.pdf_drawing.url)}
                        >Process Document</Button>
                    }
                    {view === 'writeOff' ?
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={()=>this.props.deployConfirmation("Are you sure you want to write off this part?", 'Write Off', this.submitWriteOff)}
                            disabled={
                                !writeOffFormData.reason ||
                                !writeOffFormData.notes ||
                                writeOffFormData.files.length < 1  ||
                                repairOrder.parts.length < 1
                            }
                        >
                            Write Off
                        </Button> :
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={this.checkRepairSubmit}
                            disabled={repairOrder.notes.length < 1 || (repairOrder.parts.length < 1)}
                        >
                            Submit Repair
                        </Button>
                }
                </Grid>
                {postRepairDialog.open &&
                    <Dialog
                        open={postRepairDialog.open}
                        maxWidth='sm'
                        fullWidth
                    >
                        <DialogTitle>Submit Repair</DialogTitle>
                        <DialogContent>
                            <Typography variant='body1'>
                                Are you sure you want to submit this repair, once submitted it cannot be edited?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant='outlined'
                                onClick={()=>this.setState({postRepairDialog: initialState().postRepairDialog})}
                            >Close</Button>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={this.submitRepair}
                            >Submit</Button>
                        </DialogActions>
                    </Dialog>
                }
                {
                    editNoteDialog &&
                    <Dialog
                        open={!!editNoteDialog}
                        maxWidth='md'
                        fullWidth
                    >
                        <DialogTitle>Edit Note</DialogTitle>
                        <EditNoteDialog
                            note={editNoteDialog}
                            onClose={this.editNote()}
                            repairOrder={repairOrder}
                        />
                    </Dialog>
                }
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback) => {
            dispatch(deployConfirmation(content, title, callback))
        }
    }
}

export default connect(null, mapDispatchToProps)(RepairDialog);