import React                            from 'react';
import { connect }                      from 'react-redux';
import API                              from 'API';
import DataTable                        from 'Components/Common/DataTables/CiDataTable';
import FALightIcon                      from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper                      from 'Components/Common/Paper/PaddedPaper';
import {
    Button,
    TextField,
    Grid,
    Typography,
    Tooltip
} from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { assign, map } from 'lodash';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';
import { DEFAULT_PAGINATION_PAGE } from 'Constants';
import { deployDialog } from 'Actions/Dialog/Dialog';
import LinkEquipmentToCustomerDialog from '../Misc/LinkEquipmentToCustomerDialog';
import CondemnEquipmentDialog from '../Misc/CondemnEquipmentDialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { colors } from 'Helpers/ColourHelper';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = () => ({
    searchResults: [],
    gridPagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: 25
    },
    isLoading: true,
    initialLoading: true,
    searchCriteria: {
        searchPartDesc: '',
        searchPartID: '',
        searchSN: ''
    },
    partNumbersList: [],
    access: {
        view: false
    }
})

class EquipmentSearch extends React.Component {
    constructor(props) {
        super(props);
        this.getInitialState    = getInitialState.bind(this);
        this.persistenceId      = 'Equipment:Search';
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.clearPageState     = clearPageState.bind(this);
        this.state              = this.getInitialState(initialState());
        this.timeout            = false;
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    componentWillUnmount = () => {
        if(this.timeout) 
            clearTimeout(this.timeout)
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.location?.search !== prevProps.location?.search){
            this.loadComponentData();
        }
    }

    loadComponentData = () => {

        this.getSearchData();
        this.checkAccess();
        this.getPartsList();
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchData, 200);
    }

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/equipment', { 
                params: {
                    ...this.state.searchCriteria,
                    isLatest: true  
                } 
            })
            .then(result => {
                
                this.setState({
                    isLoading: false,
                    initialLoading: false
                });
                if(result.data) {
                    this.setState({
                        searchResults: result.data
                    });
                } 
            });
        });
    }

    getPartsList = () => {
        API.get('/parts/withEquipment')
        .then(result => {
            let partNumbersList = []
            if(result.data) {
                partNumbersList = map(result.data, (el) => {
                    return assign({
                        value: el.id,
                        label: el.partNumber + ' - ' + el.partDesc
                    });
                });

                this.setState({
                    partNumbersList
                });
            }  
        });
    }

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/view-equipment')
        ])
        .then(([res]) =>  {
            res.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        view: res?.data?.has_access
                    }
                });
        });
    }

    onSearchStringChange = (event, name) => {
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                [name]: event.target.value
            }
        },
        () => {
            this.savePageState();
            this.setSearch();
        });
    }

    onSearchSelectChange = fieldName => selectedOption => {
        if(selectedOption === null) {
            selectedOption = ''
        }
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                [fieldName]: selectedOption && selectedOption.value,
            }
        },
        () => {
            this.savePageState()
            this.setSearch();
        });
    };

    resetSearch = () => {
        this.setState({ searchCriteria: {...initialState().searchCriteria} }, () =>{ this.savePageState();  this.getSearchData()} );
    }

    handleLinkCustomer = (equip) => {
        this.props.deployDialog(
            <LinkEquipmentToCustomerDialog 
                equip={equip}
                callBack={this.getSearchData}
            />,
            'Link Equipment To Customer',
            '',
            'md'
        );
    }

    handleCondemnEquipment = (equipId) => {
        this.props.deployDialog(
            <CondemnEquipmentDialog 
                equipId={equipId}
                callBack={this.getSearchData}
            />,
            <> <AllIcon icon='skull-crossbones' size={16} color='white' /> Condemn Equipment</>,
            'warning',
            'sm'
        );
    }

    render() {
        const { searchCriteria,  gridPagination, isLoading, partNumbersList, searchResults, initialLoading } = this.state;

        let restrictedStatuses = ['Returned', 'Condemned', 'Written Off', 'Replaced'];

        return (
            <>
            {initialLoading ? <LoadingCircle />
            :
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Search Equipment
                        </Typography>
                    </Grid> 
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Grid container item spacing={3}>
                                    <Grid item xs={12}>
                                        <AutoCompleteSelect 
                                            options={partNumbersList} 
                                            label='Part Number'
                                            value={searchCriteria?.searchPartID}
                                            onChange={this.onSearchSelectChange('searchPartID')}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Part Description" fullWidth value={searchCriteria.searchPartDesc} onChange={(e) => this.onSearchStringChange(e, 'searchPartDesc')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Serial Number" fullWidth value={searchCriteria.searchSN} onChange={(e) => this.onSearchStringChange(e, 'searchSN')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className='buttonRow'>
                                            <Button onClick={this.resetSearch}
                                                    variant="outlined"
                                                    color="default"                                       
                                                    >
                                                <FALightIcon icon='undo' button />
                                                Reset Search
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </form> 
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <DataTable  
                                config={{
                                    key: 'id',
                                    pagination: true,
                                    alternatingRowColours: true,
                                    isLoading: isLoading,
                                    responsiveImportance: true,
                                    rowsPerPage: gridPagination.rowsPerPage,
                                }}
                                columns={[
                                    {
                                        heading: 'Type',
                                        field: rowData =>  
                                        <>
                                            <Tooltip
                                                title={
                                                    <div >
                                                        {rowData.m ? 'Manual' : 'System Generated'}
                                                    </div>
                                                }
                                                placement="left"
                                            >
                                                <span>
                                                    <AllIcon 
                                                        icon={rowData.m ? 'keyboard' : 'laptop-code'}
                                                        button
                                                        style={{color: rowData?.latest === 0 ? 'rgb(231, 76, 60)' : null}}
                                                        noMargin
                                                    />
                                                </span>
                                            </Tooltip>
                                        </>,
                                        important: true,
                                        sizeToContent: true,
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Serial Number',
                                        field: rowData => rowData?.sn,                                            
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Part Number',
                                        field: rowData => rowData?.partNo,
                                        hideInResponsiveDialog: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Part Description',
                                        field: rowData => rowData?.partDesc,
                                        main: true,
                                        important: true,
                                        hideInResponsiveDialog: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Customer',
                                        field: rowData => <> 
                                            <Typography variant="body2" >
                                                {rowData?.cust?.name ?? '-'}
                                            </Typography>
                                            <Typography variant="body2" >
                                                {rowData?.address && (
                                                    <Tooltip
                                                        title={
                                                            <Typography variant="body2" style={{whiteSpace: 'pre-wrap'}}>
                                                                <b>Site Address:</b><br />
                                                                {rowData?.address.split('\n').map((item, key) => {
                                                                    return  <span key={key}>
                                                                                {item}{rowData?.address.split('\n').length === key+1 ? null : <br/>}
                                                                            </span>
                                                                })}
                                                            </Typography>

                                                        }
                                                        placement="right"
                                                    >
                                                        <span>
                                                            <AllIcon 
                                                                heavy
                                                                icon='info-circle'
                                                                button 
                                                                size='xsmall'
                                                                style={{
                                                                    color: 'rgba(0, 0, 0, 0.20)'
                                                                }}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                )}
                                                <span style={{color: '#999'}}>{rowData?.addressName ?? '-'}</span>
                                            </Typography>
                                        </>,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Status',
                                        field: rowData => rowData?.status,
                                        sizeToContent: true,
                                        cellProps : rowData => {
                                            return {
                                                style: {
                                                    color: (restrictedStatuses.includes(rowData?.status)) ? colors?.red : null
                                                }
                                            }
                                        }
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: 'Link To Customer', icon: 'link-horizontal', onClick: () => this.handleLinkCustomer(rowData), disabled: (restrictedStatuses.includes(rowData?.status))},
                                                {name: 'Condemn Equipment', icon: 'skull-crossbones', onClick: () => this.handleCondemnEquipment(rowData.id), disabled: (restrictedStatuses.includes(rowData?.status))},
                                                {name: 'View Equipment', icon: 'search', link: '/equipment/view/' + rowData.id, state: {searchCriteria, gridPagination}, disabled: !this.state.access.view}
                                            ]
                                        },
                                        alignment: 'center'
                                    }
                                ]}
                                rows={searchResults}
                            />
                        </PaddedPaper>
                    </Grid>
                </Grid>
            }
            </>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deployDialog: (content, title, variant, size='md')  => dispatch(deployDialog(content, title, variant, size)),
    clearPersistence: ()                                => dispatch(clearPersistence()),
    setPersistence: (key, state)                        => dispatch(setPersistence(key, state))
});

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentSearch);