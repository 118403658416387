import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import API from '../../../API';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import MeetingInviteForm from '../MeetingInviteForm/MeetingInviteForm';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';

const styles = theme => ({
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = {
    isLoading: false,
    formData: {
        meetingName: '',
        inviteType: null,
        staffContact: null,
        supplier: null,
        supplierContact: null,
        customer: null,
        customerContact: null,
        sendSmsInvite: false,
        smsName: '',
        smsNumber: '',
        smsBody: 'Please click the following link to join your ClenaCare online support session.',
        sendEmailInvite: false,
        emailName: '',
        emailAddress: '',
        emailBody: 'Please click the following link to join your ClenaCare online support session.'
    },
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
}

class AdditionalInviteDialog extends React.Component {
    constructor(props) {
        super(props);        
        this.state = initialState;
    }
    componentDidUpdate(prevProps) {
    }    
    handleCheckChange = name => event => {
        this.setState({
            formData: {
            ...this.state.formData,
            [name]: event.target.checked
            }
        });
    };
    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    };
    handleSelectChange = fieldName => (selectedOption, callback = null) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, () => {
            if(callback)
                callback();
        });
    };
    updateFormData = formData => {
        this.setState({
            formData: {
                ...this.state.formData,
                ...formData
            }
        })
    }
    handleSubmit = () => {
        API.put('/meetings/' + this.props.meetingId + '/invite', this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0) {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
            }
        });
    };
    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };
    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    };
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    };
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.onClose}
                    maxWidth='sm'
                    fullWidth
                    scroll={'body'}
                    TransitionComponent={Transition}
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    {(this.state.isLoading && (
                        <div className={classes.centered}>
                            <LoadingCircle />
                        </div>
                    )) || (   
                        <React.Fragment>
                            <DialogTitle>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} align="center" sm>
                                        Additional Invitation For {this.props.meetingName}
                                    </Grid>
                                </Grid>                                     
                            </DialogTitle>
                            <Typography component={DialogContent} variant="body2">
                                <MeetingInviteForm
                                    formData={this.state.formData}
                                    formErrors={this.state.formErrors}
                                    handleSelectChange={this.handleSelectChange}
                                    handleChange={this.handleChange}
                                    handleCheckChange={this.handleCheckChange}
                                    updateFormData={this.updateFormData}
                                />
                            </Typography>
                            <DialogActions>
                                <Button onClick={this.props.onClose} variant="outlined" color="default">Cancel</Button>
                                <Button onClick={this.handleConfirmationOpen}
                                    variant="contained"
                                    color="primary"
                                    disabled={false}>
                                    Send Invite
                                </Button>
                            </DialogActions>
                        </React.Fragment>
                    )}
                </Dialog>
                <ConfirmationDialog
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Send New Invitation?" 
                    message="Are you sure you want to send a new invitation?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message="The new invitation sent was successfully"
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AdditionalInviteDialog);