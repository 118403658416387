//ScanDialog

import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import Image              from 'Components/Common/ImageWithError/ImageWithError';
import NumberField        from 'Components/Common/Inputs/NumberField';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import QrScanWrapper      from 'Components/Warehouse/Common/Inputs/QrScanWrapper';

import { colors }            from 'Helpers/ColourHelper';
import icons                 from 'Helpers/IconHelper';
import { pdfDataFromBase64 } from 'Helpers/PDFHelper';

import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { playSound }      from 'Actions/Sounds/Sounds';
import { printLabel }     from 'Actions/Print/Print';

import { Button, Grid, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';



const initialState = {
    detail:     {},
    formData:   {
        qty:        0,
        required:   0,
        part:       null,
        skipped:    0,
        action:     'Pick',
        detail:     {}
    },
    activePart: {},
    returnData: [],
    formErrors: {},
    isLoading:  true,
    showQr:     false,
    showQty:    false,
    showError:  false,
    showNotes:  false,
    notes:      '',
    confirmationDialog: {
        open:    false,
        title:   '',
        message: '',
        success: null
    }
}

class ScanDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getPickData()
    }

    getPickData = () => {
        this.setState({isLoading: true},
        ()=>{
            const detail = this.props.detail;
            if (!detail) return this.props.onClose();
            this.setState({
                detail:     detail,
                formData: {
                    ...initialState.formData,
                    required: parseFloat(detail.wod_qty_required),
                    part:     detail.part.part_id,
                    detail
                },
                activePart: {...detail.part, part_required: detail.wossd_qty_remaining, wossd_qty_picked: detail.wossd_qty_picked},
                isLoading:  false,
                showQr:     true,
                showQty:    false,
            })
        })

    }

    handleScan = (scannedPartNumber) => {
        if (scannedPartNumber){
            if (scannedPartNumber === this.state.activePart.part_number){
                this.props.playSound('pickSuccess', 1)
                this.setState({
                    showQr:     false,
                    showQty:    true,
                    showError:  false,
                })
            } else {
                this.props.playSound('pickFailed', 1);
                this.setState({showError: true})
            }
        }
    }


    nextStage = () => {
        if (this.state.formData.action !== 'skip') this.props.onChange(this.state.formData);
        this.props.onClose();
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                qty: e
            }
        })
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleNoPick = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                action: 'NotRequired',
                qty:     0
            }
        },this.nextStage);
    }

    handleMissing = () => {
        API.post(`/worksOrders/${this.props.woId}/addPartLog`, {
            type: 'Shortage Stock Missing',
            part: this.state.formData.part,
        }).then(() => {
            this.setState({
                formData: {
                    ...this.state.formData,
                    action: 'skip',
                    qty:     0
                }
            },this.nextStage);
        });
    }

    handleSkip = () => {
        API.post(`/worksOrders/${this.props.woId}/addPartLog`, {
            type: 'Shortage Pick Skipped',
            part: this.state.formData.part,
        }).then(() => {
            this.setState({
                formData: {
                    ...this.state.formData,
                    action: 'skip',
                    qty:     0
                }
            },this.nextStage);
        });
    }

    handleNoteChange = (e) => {this.setState({notes: e.target.value})}

    deployConfirmation = (message, title, success) => {
        this.setState({
            confirmationDialog: {
                open: true,
                message: message,
                title: title,
                success: success
            }
        })
    }

    handleConfirmationClose = () => {
        this.setState({
            confirmationDialog: {
                ...this.state.confirmationDialog,
                open: false
            }
        })
    }

    _handleKeyDown = (e) => {
        if (e.keyCode === 0) return;
        e.preventDefault();
		switch(e.key) {
            case 'F1':
                if (this.state.confirmationDialog.open) {
                    this.handleConfirmationClose();
                } else {
                    this.handleSkip();
                }
            break;
            case 'F2':
                if (this.state.confirmationDialog.open) {
                    this.state.confirmationDialog.success();
                    this.handleConfirmationClose();
                } else {
                    this.deployConfirmation(
                        'Are you sure this part is missing stock?',
                        'Set Stock As Missing?',
                        this.handleMissing
                    );
                }
            break;
            case ' ':
                parseFloat(this.state.activePart.wossd_qty_picked) === 0 &&
                    this.deployConfirmation(
                        'Are you sure this part is not required?',
                        'Part Never Required?',
                        this.handleNoPick
                    )
            break;
            case 'Enter':
                if (this.state.showQty 
                    && !(parseFloat(this.state.formData.qty) <= 0 || parseFloat(this.state.formData.qty) > parseFloat(this.state.activePart.part_required))) {
                        this.nextStage();
                    }
            break;
            case 'F3':
                this.handleClose();
            break;
            case 'Tab':
                this.handlePrint()
                break;
            default:
                if (this.state.showQty){
                    if (_.isInteger(parseInt(e.key)) || e.key === '.'){
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                qty: this.state.formData.qty ?  this.state.formData.qty + e.key : 
                                    (e.key === '.' ? '0.' : e.key)
                            }
                        })
                    } else if (e.key === 'Backspace') {
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                qty: this.state.formData.qty.slice(0, -1)
                            }
                        })
                    } else if (e.key === 'Enter') {
                        this.handleEnterQty();
                    }
                }
            break;
		}
	}

    handlePrint = () => {
        API.get('/parts/' + this.state.activePart.part_id + '/stockLabel',{
            params: {
              type: 'partLabel'
            }
        })
        .then(result => {
            if(result.data) {
                this.props.printLabel(pdfDataFromBase64(result.data.pdf));
            }
        });
    }

    render(){
        const { formData, showError, showQty, isLoading, activePart, confirmationDialog } = this.state;
        const { classes } = this.props;
        

        if (isLoading) return (<LoadingCircle/>);
        return (
            <Grid container justify="center" className={`${classes.root} text-center`} spacing={1}>
                <Grid container item className={classes.qrRow}>
                    <Grid item xs={12}>
                        <Typography variant="body1" className="float-right">
                            Part 1 of 1
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Grid container justify="center" className="text-center" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{textAlign: 'center'}}>
                                {activePart.part_number}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{textAlign: 'center'}}>
                                {activePart.part_description}<br></br>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                            <Image src={activePart.default_image?.file_path} className={classes.img} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.qtyRow}>
                    <Typography variant="h6" component="span">
                        Required:
                    </Typography>
                    <Typography variant="h5" component="span" className="pl-1">
                        <strong>{parseFloat(activePart.part_required).toFixed(3)}</strong>
                    </Typography>
                </Grid>
                {showQty ?
                    <>
                        <Grid item style={{width: 400}}>
                            <NumberField
                                id="qty"
                                name="qty"
                                label={`Quantity Required * [Enter]`}
                                value={formData.qty}
                                type="number"
                                variant="outlined"
                                onChange={this.handleChange}
                                margin="none"
                                className={classes.confirmQtyInputContainer}
                                inputProps={{
                                    className: classes.confirmQtyInput,
                                    min: 0,
                                    max: parseFloat(activePart.part_required),
                                }}
                                granularity={function(){
                                    var decimals = (parseFloat(activePart.part_required) !== parseFloat(Math.floor(activePart.part_required)))?(activePart.part_required.toString()).split('.')[1].length:0;
                                    if (!decimals) return 1;
                                    return Math.pow(10, -(decimals-1));
                                }()}
                                disableKeyboard={true}
                            />
                        </Grid>
                        {   parseFloat(formData.qty) > 0 &&
                            parseFloat(formData.qty) !== parseFloat(activePart.part_required) &&
                            (
                                <Grid item xs={12}>
                                    <Typography variant="h6" component="span" className="pl-1" style={{color:colors.red}}>
                                        {(
                                            formData.qty < parseFloat(activePart.part_required) &&
                                            `This part pick is ${(parseFloat(activePart.part_required) - formData.qty).toFixed(3)} stock short`
                                        ) ||
                                        (
                                            formData.qty > parseFloat(activePart.part_required) &&
                                            `This part pick cannot be more than stock required`
                                        )}
                                    </Typography>
                                </Grid>
                            )
                        }
                    </> : 
                    <>
                        <Typography variant="h5" className={classes.actionText}>
                            <strong>{showError ? 'Incorrect Part Please Try Again' :'Please Scan QR Code'}</strong>
                        </Typography>
                    </>
                }
                <Grid item xs={12} style={{paddingTop: 0}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Button 
                                fullWidth
                                variant="contained"
                                color="primary"
                                endIcon={<AllIcon icon={icons.print} white />}
                                className="mt-2 mr-1"
                                onClick={this.handlePrint}
                                style={{border: '1px solid black'}}
                            >
                                Print Part Label [Tab]
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button 
                                fullWidth
                                variant="outlined"
                                endIcon={<AllIcon icon="step-forward" />}
                                className="mt-1 mr-1"
                                onClick={this.handleSkip}
                                style={{border: '1px solid black'}}
                            >
                                [F1] <br/>
                                Skip Part
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button 
                                fullWidth
                                variant="outlined"
                                endIcon={<AllIcon icon={icons.false} />}
                                className="mt-1 mr-1"
                                onClick={()=>{
                                    this.deployConfirmation(
                                        'Are you sure this part is missing stock?',
                                        'Set Stock As Missing?',
                                        this.handleMissing
                                    )
                                }}
                                style={{border: '1px solid black'}}
                            >
                                
                                Stock Missing [F2]
                            </Button>
                        </Grid>
                        {parseFloat(activePart.wossd_qty_picked) === 0 &&
                            <Grid item xs={6}>
                                <Button 
                                    fullWidth
                                    variant="outlined"
                                    endIcon={<AllIcon icon="times" />}
                                    className="mt-1 mr-1"
                                    onClick={()=>{
                                        this.deployConfirmation(
                                            'Are you sure this part is not required?',
                                            'Part Never Required?',
                                            this.handleNoPick
                                        )
                                    }}
                                    style={{border: '1px solid black'}}
                                >
                                    [_] Part Not Required
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={parseFloat(activePart.wossd_qty_picked) === 0 ? 6 : 12}>
                            <Button 
                                fullWidth
                                variant="outlined"
                                className="mt-1 mr-1"
                                onClick={this.handleClose}
                                style={{border: '1px solid black'}}
                            >
                                [F3] <br/>
                                Save & Close
                            </Button>
                        </Grid>
                    </Grid> 
                </Grid>
                <QrScanWrapper
                    handleScan={this.handleScan}
                    parentScan={this._handleKeyDown}
                />
                {confirmationDialog.open &&
                    <ConfirmationDialog
                        open={confirmationDialog.open}
                        title={confirmationDialog.title}
                        message={confirmationDialog.message}
                        success={()=>{
                            confirmationDialog.success();
                            this.handleConfirmationClose();
                        }}
                        close={this.handleConfirmationClose}
                        isPda={true}
                    />
                }
            </Grid>
        )
    }
}

const styles = theme => ({
    root: {
        padding:    theme.spacing(1),
        alignItems: 'flex-start',
    },
    qrRow: {
        justifyContent: 'flex-end',
        maxHeight:      '50px',
    },
    img: {
        maxHeight: 50,
        maxWidth:  130,
        width: 'auto',
        height: 'auto',
    }, 
    qtyRow: {
        textAlign: 'center'
    },
    actionText: {
        border: `2px dashed ${theme.palette.secondary.main}`,
        padding: 15,
        width: '80%',
        margin: '0 auto',
        textAlign: 'center',
        marginTop: '1em'
    },
});

function mapDispatchToProps(dispatch) {
    return {
        playSound: (type, state) => {
            dispatch(playSound(type, state));
        },
        deploySnackBar: (variant, message) => {
            dispatch(deploySnackBar(variant, message));
        },
        printLabel: (data, file) => dispatch(printLabel(data, file)),
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ScanDialog));