import { Button, Grid, TextField, Typography } from '@material-ui/core';
import API from 'API';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import IconHelper from 'Helpers/IconHelper';
import React from 'react';
import { connect } from 'react-redux';
import NewSurveyDialog from './NewSurveyDialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { colors } from 'Helpers/ColourHelper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';


const initialState = () => ({
    isLoading: true,
    surveys: [],
    searchData: {
        searchString: '',
        status: 'all'
    }
})

class SurveySearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        API.get('/survey', {params: this.state.searchData}).then(res => {
            this.setState({
                surveys: res.data,
                isLoading: false,
            })
        })

    }

    showCreateSurvey = () => {
        this.props.deployDialog(
            <NewSurveyDialog 
                onClose={()=>{this.props.closeDialog(); this.getData();}}
            />,
            'Create Survey',
            'fs'
        )
    }

    showCopySurvey = (i) => () => {
        this.props.deployDialog(
            <NewSurveyDialog 
                onClose={()=>{this.props.closeDialog(); this.getData();}}
                copy={i}
            />,
            'Create Survey',
            'fs'
        )
    }

    showEditSurvey = (i) => () => {
        this.props.deployDialog(
            <NewSurveyDialog 
                onClose={()=>{this.props.closeDialog(); this.getData();}}
                edit={i}
            />,
            'Create Survey',
            'fs'
        )
    }

    handleFilter = (e) => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                [e.target.name]: e.target.value
            }
        } , ()=> {
            if (this.state.searchData.searchString.length > 3 || this.state.searchData.searchString.length === 0) {
                this.getData();
            }
        });
    }

    handleSelectFilter = (e) => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                status: e.value
            }
        }, this.getData);
    }

    render() {

        const { isLoading, surveys, searchData } = this.state;

        if (isLoading) { return <LoadingCircle/> }

        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Surveys</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container style={{alignItems: 'center'}} spacing={1}>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        label="Search"
                                        name="searchString"
                                        value={searchData.searchString}
                                        onChange={this.handleFilter}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <AutoCompleteSelect
                                        label='Status'
                                        options={[
                                            {label: 'All', value: 'all'},
                                            {label: 'Active', value: 'active'},
                                            {label: 'Ended', value: 'inactive'},
                                        ]}
                                        value={searchData.status}
                                        onChange={this.handleSelectFilter}
                                        fullWidth   
                                        noClear
                                        disableSort
                                    />
                                </Grid>
                                <Grid item xs={5}></Grid>
                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.showCreateSurvey}
                                        fullWidth
                                    >Create Survey</Button>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <CiDataTable
                                config={{
                                    key: 'survey_id',
                                    pagination: true,
                                    rowsPerPage: 50,
                                }}
                                rows={surveys}
                                columns={[
                                    {
                                        heading: 'Date Added',
                                        field: 'created_log.sl_datetime',
                                        dataRef: 'created_log.sl_datetime',
                                        fieldFormat: 'clenydate',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Reference',
                                        field: 'survey_ref',
                                        dataRef: 'survey_ref',
                                    },
                                    {
                                        heading: 'Name',
                                        field: 'survey_name',
                                        dataRef: 'survey_name',
                                    },
                                    {
                                        heading: 'Category',
                                        field: 'form.sub_category.category.form_cat_name',
                                        dataRef: 'form.sub_category.category.form_cat_name',
                                    },
                                    {
                                        heading: 'Sub Category',
                                        field: 'form.sub_category.form_cat_name',
                                        dataRef: 'form.sub_category.form_cat_name',
                                    },
                                    {
                                        heading: 'Last Sent',
                                        field:  'last_survey.sc_created',
                                        dataRef: 'last_survey.sc_created',
                                        fieldFormat: 'clenydate',
                                        alignment: 'center',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'completed surveys',
                                        field:  i => `${i.contacts_complete_count} / ${i.contacts_count}`,
                                        dataRef: 'contacts_complete_count',
                                        alignment: 'center',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Status',
                                        field: i => {
                                            let color = colors.red;
                                            let toolTip = 'Not Started';

                                            if (i.contacts_count > 0){
                                                if (i.contacts_complete_count === i.contacts_count) {
                                                    color = colors.green;
                                                    toolTip = 'Completed';
                                                } else {
                                                    color = colors.orange;
                                                    toolTip = 'In Progress';
                                                }
                                            }


                                            return (
                                                <AllIcon
                                                    icon={IconHelper.survey}
                                                    color={color}
                                                    tooltip={toolTip}
                                                    noMargin
                                                />
                                            )
                                        },
                                        dataRef: 'contacts_complete_count',
                                        alignment: 'center',
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Active',
                                        field:  i => <AllIcon
                                            icon={i.survey_status === 'active' ? IconHelper.true : IconHelper.false}
                                            color={i.survey_status === 'active' ? colors.green : colors.red}
                                            tooltip={i.survey_status === 'active' ? 'Active' : 'Ended'}
                                            noMargin
                                        />,
                                        dataRef: 'survey_status',
                                        alignment: 'center',
                                        sizeToContent: true
                                    },
                                    {
                                        actions: i => ([
                                            {
                                                name: 'Edit',
                                                icon: IconHelper.edit,
                                                onClick: this.showEditSurvey(i),
                                                disabled: i.contacts_count > 0 || i.survey_status === 'ended'
                                            },
                                            {
                                                name: 'Copy',
                                                icon: IconHelper.copy,
                                                onClick: this.showCopySurvey(i),
                                            },
                                            {
                                                name: 'View',
                                                icon: IconHelper.view,
                                                link: `/survey/${i.survey_id}`
                                            }
                                        ])
                                    }
                                ]}
                            />
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, size) => {
            dispatch(deployDialog(content, title, 'system', size))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        }
    }
}

export default connect(null, mapDispatchToProps)(SurveySearch);