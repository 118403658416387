import React from 'react';
import Typography from '@material-ui/core/Typography';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import SnackBar from './../../Common/SnackBars/SnackBar'
import Button from '@material-ui/core/Button';
import API from '../../../API';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import FormControl from '@material-ui/core/FormControl';
import _ from 'lodash';
import CustomerDiscountList from './CustomerDiscountList';
import DatePicker from '../../Common/DatePickers/DatePicker';
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    formData: {
        addDiscountName: {
            name: ''
        },
        addDiscountValue: {
            name: '',
            startDate: null,
            discountValue: '',
        }
    },
    formErrors: {
        addDiscountName: {},
        addDiscountValue: {}
    },
    snackbarOpen: {
        addDiscountName: false,
        addDiscountValue: false
    },
    confirmationOpen: {
        addDiscountName: false,
        addDiscountValue: false
    },
    discountNames: {},
    discountNameList: [],
    currentTab: 0,
    access: {
        addDiscountName: false,
        addDiscountValue: false
    },
    discountValues: {}
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

class CustomerDiscounts extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'accounts:CustomerDiscounts';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.checkAccess();
        this.getDiscountNames();
        this.getDiscountValues();
    }

    getDiscountValues = () => {
        API.get('/accounts/customerDiscounts/byName')
        .then(result => {
            if(result.data) {
                this.setState({
                    discountValues: result.data,
                });
            }
        });
    };

    getDiscountNames = () => {
        API.get('/accounts/customerDiscounts/names').then(result => {
            if(result.data) {
                let discountNameList = _.map(result.data, (el) => {
                    return _.assign({
                        value: el.cdn_id,
                        label: el.cdn_name
                    });
                });
                this.setState({
                    discountNames: result.data,
                    discountNameList: discountNameList
                });
            }
        });
    };
    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/customer-discounts:add-discount-name'), 
            API.get('/staff/my/access/check/customer-discounts:add-discount-value'),
        ])
        .then(([nameRes, discountRes]) =>  {
            nameRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addDiscountName: nameRes.data.has_access
                    }
                });
                discountRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addDiscountValue: discountRes.data.has_access
                    }
                });
        });
    }
    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        }, () => {this.savePageState();});
    };
    /*
     * Add Discount Name
     */
    addDiscountName = () => {
        API.post('/accounts/customerDiscounts/names', this.state.formData.addDiscountName).then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        addDiscountName: formatValidationErrors(result.data.errors)
                    }
                });
            } else {
                this.setState({
                    ...initialState(),
                    snackbarOpen: {
                        ...this.state.snackbarOpen,
                        addDiscountName: true
                    }
                });
                this.checkAccess();
                this.getDiscountNames();
                this.getDiscountValues();
            }
            this.props.scrollToTop();
        });
    }
    handleDiscountNameChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                addDiscountName: {
                    ...this.state.formData.addDiscountName,
                    [e.target.name]: e.target.value
                }
            }
        });
    };
    handleDiscountNameSnackbarClose = () => {
        this.setState({
            snackbarOpen: {
                ...this.state.snackbarOpen,
                addDiscountName: false
            }
        });
    };
    handleDiscountNameConfirmationOpen = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addDiscountName: true
            }
        });
    };
    handleDiscountNameConfirmationClose = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addDiscountName: false
            }
        });
    };
    handleDiscountNameConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addDiscountName: false
            }
        });
        this.addDiscountName();
    };
    /*
     * Add Discount Value
     */
    addDiscountValue = () => {
        const formData = {
            ...this.state.formData.addDiscountValue,
            startDate: this.state.formData.addDiscountValue.startDate && (moment(new Date(this.state.formData.addDiscountValue.startDate)).format('DD/MM/YYYY'))
        }
        API.post('/accounts/customerDiscounts', formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        addDiscountValue: formatValidationErrors(result.data.errors)
                    }
                });
            } else {
                this.setState({
                    ...initialState(),
                    currentTab: 1,
                    snackbarOpen: {
                        ...this.state.snackbarOpen,
                        addDiscountValue: true
                    }
                });
                this.checkAccess();
                this.getDiscountNames();
                this.getDiscountValues();
            }
            this.props.scrollToTop();
        });
    }
    handleDiscountValueChange = (decimals) => (e) => {
        let newVal = decimals ? parseFloat(e.target.value).toFixed(decimals) : e.target.value;
        this.setState({
            formData: {
                ...this.state.formData,
                addDiscountValue: {
                    ...this.state.formData.addDiscountValue,
                    [e.target.name]: newVal
                }
            }
        });
    };
    handleDiscountValueSnackbarClose = () => {
        this.setState({
            snackbarOpen: {
                ...this.state.snackbarOpen,
                addDiscountValue: false
            }
        });
    };
    handleDiscountValueConfirmationOpen = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addDiscountValue: true
            }
        });
    };
    handleDiscountValueConfirmationClose = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addDiscountValue: false
            }
        });
    };
    handleDiscountValueConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addDiscountValue: false
            }
        },
        () => {
            this.addDiscountValue();
        });
    };
    handleSelectChange = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                addDiscountValue: {
                    ...this.state.formData.addDiscountValue,
                    [name]: res && res.value,
                }
            }
        });
    };
    handleDateChange = name => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                addDiscountValue: {
                    ...this.state.formData.addDiscountValue,
                    [name]: date,
                }
            }
        });
    };
    handleCheckChange = name => event => {
        this.setState({
            formData: {
                ...this.state.formData,
                addDiscountValue: {
                    ...this.state.formData.addDiscountValue,
                    [name]: event.target.checked
                }
            }
        })
    };
    render() {
        const { access, currentTab, discountValues, formData, formErrors, snackbarOpen, confirmationOpen } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Customer Discounts
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        {(access.addDiscountName || access.addDiscountValue) && (
                            <React.Fragment>
                                <AppBar position="static" style={{backgroundColor:'white'}} >
                                    <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                                        <Tab label="Add Name" disabled={!access.addDiscountName} />
                                        <Tab label="Add Discount Value" disabled={!access.addDiscountValue} />
                                    </Tabs>
                                </AppBar>
                                {(access.addDiscountName && currentTab === 0) && (
                                    <TabContainer>
                                        <Grid container item spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <PaddedPaper>
                                                    <Typography variant="h6">
                                                        Add Discount Name
                                                    </Typography>
                                                    <form onChange={this.handleDiscountNameChange} noValidate autoComplete="off">
                                                        <TextField
                                                            id="name"
                                                            name="name"
                                                            label="Name *"
                                                            value={formData.addDiscountName.name}
                                                            error={formErrors.addDiscountName && formErrors.addDiscountName['name'] && true}
                                                            helperText={formErrors.addDiscountName && formErrors.addDiscountName['name']}
                                                            margin="normal"
                                                            fullWidth
                                                        />
                                                        <div className='buttonRow'>
                                                            <Button 
                                                                onClick={this.handleDiscountNameConfirmationOpen}
                                                                variant="contained" 
                                                                color="primary" 
                                                                disabled={!this.state.formData.addDiscountName.name}
                                                            >
                                                                Add
                                                            </Button>
                                                        </div>
                                                        <ConfirmationDialog 
                                                            open={confirmationOpen.addDiscountName} 
                                                            success={this.handleDiscountNameConfirmationSuccess} 
                                                            close={this.handleDiscountNameConfirmationClose} 
                                                            title="Add A New Discount Name?" 
                                                            message="Are you sure you want to add a new discount name?"
                                                        />
                                                        <SnackBar
                                                            variant="success"
                                                            anchorOriginVertical='bottom'
                                                            anchorOriginHorizontal='right'
                                                            open={snackbarOpen.addDiscountName}
                                                            onClose={this.handleDiscountNameSnackbarClose}
                                                            message="You have successfully added a new discount name"
                                                        />
                                                    </form>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </TabContainer>
                                )}
                                {(access.addDiscountValue && currentTab === 1) && (
                                    <TabContainer>
                                        <Grid container item spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <PaddedPaper>
                                                    <Typography variant="h6">
                                                        Add Discount Value
                                                    </Typography>
                                                    <form noValidate autoComplete="off">
                                                        <FormControl fullWidth margin="normal">
                                                            <AutoCompleteSelect 
                                                                options={this.state.discountNameList} 
                                                                label='Name *'
                                                                onChange={this.handleSelectChange('name')}
                                                                error={formErrors.addDiscountValue && formErrors.addDiscountValue['name'] && true}
                                                                errorText={formErrors.addDiscountValue && formErrors.addDiscountValue['name']}
                                                            />
                                                        </FormControl>
                                                        <Grid container item spacing={3}>
                                                            <Grid item xs={12} md={4}>
                                                                <DatePicker
                                                                    type="date"
                                                                    id="startDate"
                                                                    name="startDate"
                                                                    label="Start Date *"
                                                                    value={formData.addDiscountValue.startDate}
                                                                    errorText={formErrors.addDiscountValue && formErrors.addDiscountValue['startDate']}
                                                                    onChange={this.handleDateChange('startDate')}
                                                                    autoOk={true}
                                                                    disablePast={true}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container item spacing={3}>
                                                            <Grid item xs={12} md={4}>
                                                                <TextField
                                                                    id="discountValue"
                                                                    name="discountValue"
                                                                    label="Discount Value *"
                                                                    value={formData.addDiscountValue.discountValue}
                                                                    error={formErrors.addDiscountValue && formErrors.addDiscountValue['discountValue'] && true}
                                                                    helperText={formErrors.addDiscountValue && formErrors.addDiscountValue['discountValue']}
                                                                    margin="normal"
                                                                    type="number"
                                                                    onChange={this.handleDiscountValueChange()}
                                                                    onBlur={this.handleDiscountValueChange(2)}
                                                                    fullWidth
                                                                    InputProps={{
                                                                        endAdornment: (<InputAdornment position="end">%</InputAdornment>)
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        
                                                        <div className='buttonRow'>
                                                            <Button 
                                                                onClick={this.handleDiscountValueConfirmationOpen}
                                                                variant="contained" 
                                                                color="primary" 
                                                                disabled={!formData.addDiscountValue.discountValue && !formData.addDiscountValue.name && !formData.addDiscountValue.startDate}
                                                            >
                                                                Add
                                                            </Button>
                                                        </div>
                                                        <ConfirmationDialog 
                                                            open={confirmationOpen.addDiscountValue} 
                                                            success={this.handleDiscountValueConfirmationSuccess} 
                                                            close={this.handleDiscountValueConfirmationClose} 
                                                            title="Add A New Discount Value?"
                                                            message="Are you sure you want to add a new discount value?"
                                                        />
                                                        <SnackBar
                                                            variant="success"
                                                            anchorOriginVertical='bottom'
                                                            anchorOriginHorizontal='right'
                                                            open={snackbarOpen.addDiscountValue}
                                                            onClose={this.handleDiscountValueSnackbarClose}
                                                            message="You have successfully added a new discount value"
                                                        />
                                                    </form>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </TabContainer>
                                )}
                            </React.Fragment>
                        )}
                        <div className={`padding3  ${(access.addDiscountName || access.addDiscountValue) && 'paddingTop0'}`}>
                            <CustomerDiscountList discounts={discountValues} />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDiscounts);