import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import moment               from 'moment';
import { connect }          from 'react-redux';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import DatePicker         from 'Components/Common/DatePickers/DatePicker';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import SelectPartWithQR   from 'Components/Common/Selects/SelectPartWithQR';

import { Button, FormControl, Grid, Typography, TextField } from '@material-ui/core';

import { colors } from 'Helpers/ColourHelper';

const initialState = {
    isLoading:  true,
    parts:      [],
    formData:   {
        part:       [],
        template:   null,
        customer:   null,
        site:       null,
        contact:    null,
        date:       null,
    },
};

class Manual extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        Promise.all([
            API.get('/customerReturns/greenaware/parts'),
            API.get('/customerReturns/greenaware/templates'),
            API.get('/customers')
        ]).then(([linkedParts, templates, customers]) => {

            this.setState({
                isLoading:      false,
                parts:          _.map(_.filter(linkedParts.data, i => i.parts.length > 0), i => ({
                                    value:      i.part.part_id,
                                    label:      `${i.part.part_number} - ${i.part.part_description}`,
                                    template:   i.template.gt_id,
                                    part:       i.part,
                                    parts:      i.parts,
                                    qty:        1,
                                })),
                templates:      _.map(templates.data, i => ({
                                    value: i.gt_id,
                                    label: i.gt_template_label
                                })),
                customers:      _.map(customers.data, i => ({
                                    value: i.cust_id,
                                    label: i.cust_name,
                                    sites: _.map(_.filter(i.addresses, a => a.address_active), j => ({
                                        value:     j.address_id,
                                        label:     `${j.address_postcode} | ${j.address_name} (${j.address_type})`,
                                        contacts:  _.map(j.active_contacts, k => ({
                                            value: k.contact_id,
                                            label: k.contact_name
                                        }))
                                    })),
                                }))
            })
        });
    }

    selectChange = (name) => (value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                site:    name === 'customer' ? initialState.formData.site : this.state.formData.site,
                contact: name === 'customer' ? initialState.formData.contact : this.state.formData.contact,
                [name]: value && value.value,
            }
        });
    }

    selectMultiChange = (name) => (value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: _.map(value, i => i.value), 
            }
        });
    }

    changeQty = id => e => {
        this.setState({
            parts: _.map(this.state.parts, i => i.value == id ? {...i, qty: e.target.value} : i)
        });
    }

    submit = () => {
        let formData = new FormData();
        formData.append('customer', this.state.formData.customer);
        formData.append('site',     this.state.formData.site);
        formData.append('template', this.state.formData.template);
        formData.append('contact',  this.state.formData.contact);
        formData.append('date',     moment(this.state.formData.date).unix());

        let parts = [];
        _.each(this.state.formData.part, i => {
            let l = _.find(this.state.parts, {value: i, template: this.state.formData.template});
            parts.push({id: i, qty: l ? l.qty : 1});
        })

        formData.append('parts', JSON.stringify(parts));

        API.post('/customerReturns/greenaware/returns/createManual',formData)
        .then(res => { this.setState({ formData: initialState.formData}); });
    }

    handleChangeDate = name => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: date
            }
        })
    }

    render() {
        const { isLoading, templates, parts, formData, customers }  = this.state;

        if (isLoading) return (<LoadingCircle />);

        return (
            <PaddedPaper>
                <Grid container spacing={3}>
                    <Grid item lg={6} xs={12}>
                        <PaddedPaper>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth margin='none'>
                                        <AutoCompleteSelect
                                            options={customers}
                                            value={formData.customer}
                                            label='Customer *'
                                            onChange={this.selectChange('customer')}
                                        />
                                    </FormControl>
                                </Grid>
                                {formData.customer && 
                                    <>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth margin='none'>
                                                <AutoCompleteSelect
                                                    options={_.find(customers, {value: formData.customer}).sites}
                                                    value={formData.site}
                                                    label='Site *'
                                                    onChange={this.selectChange('site')}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {formData.site &&
                                            <Grid item xs={12}>
                                                <FormControl fullWidth margin='none'>
                                                    <AutoCompleteSelect
                                                        options={_.find(_.find(customers, {value: formData.customer}).sites, {value: formData.site}).contacts}
                                                        value={formData.contact}
                                                        label='Contact *'
                                                        onChange={this.selectChange('contact')}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        }
                                    </>
                                }
                                <Grid item xs={12}>
                                    <FormControl fullWidth margin='none'>
                                        <AutoCompleteSelect
                                            options={templates}
                                            value={formData.template}
                                            label='Template *'
                                            onChange={this.selectChange('template')}
                                        />
                                    </FormControl>
                                </Grid>
                                {formData.template && 
                                    <>  
                                        <Grid item xs={12}>
                                            <SelectPartWithQR
                                                multi=    {true}
                                                parts=    {_.filter(parts, {template: formData.template})}
                                                value=    {formData.part}
                                                onChange= {this.selectMultiChange('part')}
                                                margin=   "none"
                                                label=    "Return Packaging From Part(s) *"
                                            />
                                        </Grid>
                                        {formData.part.length > 0 && 
                                            <>
                                                <Grid container item xs={12} style={{alignItems: 'center'}} spacing={1}>
                                                    <Grid item xs={12}><Typography variant='h6'>Returned Parts</Typography></Grid>   
                                                    {_.map(formData.part, (i, idx) => 
                                                        {
                                                            let l = _.find(parts, {value: i, template: formData.template});
                                                            return (
                                                                <>
                                                                    <Grid item xs={8}>
                                                                        {l.label}
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            value={l.qty}
                                                                            onChange={this.changeQty(i)}
                                                                            type='number'
                                                                            inputProps={{
                                                                                min: 1,
                                                                            }}
                                                                            margin='none'
                                                                        />
                                                                    </Grid>
                                                                    {_.map(l.parts, p => 
                                                                        <>
                                                                            <Grid item xs={1}></Grid>  
                                                                            <Grid item xs={7} style={{color: colors.disabled}}>{p.part_number} - {p.part_description}</Grid>    
                                                                            <Grid item xs={4} style={{color: colors.disabled}}>{l.qty}</Grid>   
                                                                        </>
                                                                    )}
                                                                </>
                                                            )
                                                        }
                                                    )}
                                                </Grid>
                                            </>
                                        }
                                        <Grid item xs={12}>
                                            <DatePicker 
                                                label='Collection Date *'
                                                value={formData.date}
                                                fullWidth
                                                onChange={this.handleChangeDate('date')}
                                                margin='none'
                                            />
                                        </Grid>
                                        <Grid item xs={12} className='buttonRow'>
                                            <Button 
                                                variant='contained' 
                                                color='primary' 
                                                onClick={
                                                    ()=>this.props.deployConfirmation('Are you sure you want to create this manual entry?','Create Manual Entry', this.submit)}
                                                disabled={formData.part.length == 0 || !formData.customer || !formData.template || !formData.site || !formData.contact || !formData.date}
                                            >Submit</Button>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </PaddedPaper>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success, cancel) => dispatch(deployConfirmation(message, title, success, cancel)),
    deployDialog:       (content, title, variant, size)   => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:        ()                                => dispatch(closeDialog())
})

export default connect(null, mapDispatchToProps)(Manual);