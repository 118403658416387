import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class SupplierDetails extends PureComponent {
    
    render() {
        const { paymentTerms, address } = this.props;
        const loggedInStaff = this.props.loggedInStaff !== undefined ? this.props.loggedInStaff.firstName + ' ' + this.props.loggedInStaff.lastName : '';
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle1" gutterBottom>
                        Address
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Typography variant="subtitle1" gutterBottom>
                        {address.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })}
                    </Typography>
                </Grid>
                <Grid item className='xs-only'><br /></Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle1" gutterBottom>
                        Order Placed By
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Typography variant="subtitle1" gutterBottom>
                        {loggedInStaff}
                    </Typography>
                </Grid>
                <Grid item className='xs-only'><br /></Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle1" gutterBottom>
                    Payment Terms
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Typography variant="subtitle1" gutterBottom>
                        {paymentTerms}
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
        loading: {
            ...state.loading
        }
    };
}

export default connect(mapStateToProps)(SupplierDetails);
    