import React, { PureComponent }                 from 'react';
import { connect }                              from 'react-redux';
import API                                      from 'API';
import moment                                   from 'moment';
import _                                        from 'lodash';

import {deployDialog, closeDialog}              from 'Actions/Dialog/Dialog';

import DateRangeDialog                          from './../DateRangeDialog';
import ReportCell                               from './../ReportCell';
import LoadingCircle                            from 'Components/Common/LoadingCircle/LoadingCircle';
import AllIcon                                  from 'Components/Common/Icons/AllIcon';
import DataTable                                from 'Components/Common/DataTables/CiDataTable';
import DownloadCSV                              from 'Components/Common/Buttons/DownloadCSV';
import PaddedPaper                              from 'Components/Common/Paper/PaddedPaper';
import ImageWithError                           from 'Components/Common/ImageWithError/ImageWithError';
import PieChart                                 from 'Components/Common/Charts/PieChart';
import BarChart                                 from 'Components/Common/Charts/BarChart';

import logo                                     from 'Assets/Images/clenaware_logo_icon.png';

import icons                                    from 'Helpers/IconHelper';
import { SystemColors, colores }                from 'Helpers/ColourHelper';

import { Card, CardContent, CardHeader, Typography, Grid, Divider, Tooltip, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Box, Button } from '@material-ui/core/';
import { withStyles }                           from '@material-ui/styles';

import {setPersistence} from 'Actions/StatePersistence/StatePersistence';
import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';
import { convertRealNumber }                                            from 'Functions/MiscFunctions'

const styles = theme => ({
    card: {
        color: '#000',
        backgroundColor: '#FFF',
        padding: '0'
    },
    row: {
        color: '#000',
        backgroundColor: '#FFF',
        '&:hover': {
            backgroundColor: '#FCFCFC',
            cursor: 'pointer'
        },
    },
    header: {
        background:'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', 
        color: '#FFF'
    },
    legendWrapper: {
        padding: '0 1rem'
    },
    legendTitle: {
        marginBottom: '0.8rem'
    },
    legendItem: {
        display: 'grid',
        gridTemplateColumns: '30px 60px auto',
        marginBottom: '0.4rem'
    },
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }

});

const colors = SystemColors();

const initialState = {
    filter:      {
        to:         moment(),
        from:       moment(),
    },
    parts:      {},
    isLoading:   true,
    showResults: false,
    formErrors:  null
}

const OverviewRow = ({data, classes, label}) => {
    return (
        <TableRow class={classes.row} sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
            <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  borderBottom: 0, textAlign:'center'}}>
                <AllIcon icon={icons[label]} size='medium'/>
                <Typography variant="h6" style={{textTransform: 'capitalize'}}>{label}</Typography>
            </TableCell>
            <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  borderBottom: 0}}>
                <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                    <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} primaryColor={colors.green} />
                    <Typography variant="h6" style={{fontSize: '14px'}}>
                        Invoiced
                    </Typography>
                </Box>
                <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                    <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} swapOpacity secondaryColor='red' />
                    <Typography variant="h6" style={{fontSize: '14px'}}>
                        Credited
                    </Typography>
                </Box>
                <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                    <AllIcon icon={'equals'} size='medium' style={{display: 'inline-block', paddingRight: '0.5rem', color: '#2d2d2d'}} />
                    <Typography variant="h6" style={{fontSize: '14px'}}>
                        Total
                    </Typography>
                </Box>
            </TableCell>
            <ReportCell data={data} manualTotal style={{borderBottom: 0}} field={'qty'}     hidePound  showZero forceFixed={2}/>
            <ReportCell data={data} manualTotal style={{borderBottom: 0}} field={'cost'}               showZero/>
            <ReportCell data={data} manualTotal style={{borderBottom: 0}} field={'price'}              showZero/>
            <ReportCell data={data} manualTotal style={{borderBottom: 0}} field={'margin'}  noMinus    showZero/>
            <ReportCell data={data} manualTotal style={{borderBottom: 0}} field={'marginP'} percentage showZero forceFixed={2}/>
        </TableRow>
    )
}

class SalesReportMargins extends PureComponent {
    constructor(props) {
        super(props);
        this.clearPageState  = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState    = hasPageState.bind(this);
        this.savePageState   = savePageState.bind(this);
        switch (true){
            case     props.cid > 0:    this.persistenceId = `SalesReport:margins:cid:${props.cid}`;    break;
            case     props.partId > 0: this.persistenceId = `SalesReport:margins:pid:${props.partId}`; break;
            default:                   this.persistenceId = `SalesReport:margins`;                     break;
        }
        this.state           = this.getInitialState(initialState)
    }

    handleSetFilterParams = (filter) => { this.setState({filter, showResults: true}, this.getData)} 

    getParts = () => {
        const params = {params: {...this.state.filter, cid:this.props.cid, partId:this.props.partId}}
        API.get( '/reports/salesReport/margin' , params)
        .then( res => {
            if (!res.data.errors){
                this.setState({
                    parts: _.map(res.data, (i, name) => { 
                        i.in.marginTotal       = i.in.i.d;
                        i.in.marginAverage     = i.in.i.d / i.in.q;
                        i.in.marginPercent     = (i.in.i.d / i.in.t) * 100;
                        i.in.marginPercent     = i.in.i.d ? (isFinite(i.in.marginPercent) ? i.in.marginPercent : -100 ) : 0

                        i.out.marginTotal      = i.out.i.d;
                        i.out.marginAverage    = i.out.i.d / i.out.q;
                        i.out.marginPercent    = i.out.i.d > 0 ? 100 : 0; 

                        i.tot                  = {};
                        i.tot.q                = i.in.q - i.out.q;
                        i.tot.c                = i.in.c - Math.abs(i.out.c);
                        i.tot.t                = i.in.t - Math.abs(i.out.t);
                        i.tot.marginTotal      = i.tot.t - i.tot.c;
                        i.tot.marginAverage    = i.tot.marginTotal / i.tot.q;
                        i.tot.marginPercent    = ( i.tot.marginTotal / i.tot.t ) * 100
                        i.tot.marginPercent    = isFinite(i.tot.marginPercent) ? i.tot.marginPercent : (i.tot.marginPercent < 0) ? -100 : 100 ;

                        if (i.in.marginPercent == 0 && i.out.marginPercent == 0) i.tot.marginPercent = 0;
                        if (i.in.marginTotal == 0 && i.out.marginTotal == 0)     i.tot.marginTotal = 0;

                        return i; 
                    }),
                }, () => {
                    this.setState({isLoading:  false},()=>{this.savePageState()});
                })
            }
        } )
    }

    getData = () => {
        this.setState({isLoading: true}, ()=> {
            this.getParts();
        })
    }

    loadPartImage = (rowData) => {
        this.props.deployDialog(
            <Grid container xs={12} spacing={3} style={{justifyContent:'center'}}>
                <Grid item>
                    <ImageWithError src={rowData.l} alt='Part Image' style={{maxWidth: 1000}}/>
                </Grid>
                <Grid item xs={12}><Button variant='outlined' onClick={this.props.closeDialog}>Close</Button></Grid>
            </Grid>
        ,`${rowData.n} - ${rowData.d}`,'sm')
    };

    getCsvData = () => {
        const {parts} = this.state;
        return _.map(parts, i => {
            return _.assign({
                '#':            i.i, 
                'Number':       i.n,
                'Service':      !i.ip,
                'QTY':          i.tot.q,
                'Cost':         parseFloat(i.tot.c).toFixed(2),
                'Total':        parseFloat(i.tot.t).toFixed(2),
                'Margin %':     parseFloat(i.tot.marginPercent).toFixed(2),
                'Margin £':     parseFloat(i.tot.marginTotal).toFixed(2)
            });
        })
    }

    render() {
        const { isLoading, filter, overview, parts, showResults } = this.state;
        const { classes }                                         =  this.props;

        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <DateRangeDialog callback={this.handleSetFilterParams}/>
                    </PaddedPaper>
                </Grid>
                {showResults &&
                    <>
                        {isLoading ? <Grid item xs={12} lg={12}><LoadingCircle/></Grid> : 
                            <>
                                {_.isEmpty(parts) ? <Grid item xs={12} style={{padding: '1em', textAlign: 'center', fontWeight: 'bold'}}>{'No Results For this Date Range'}</Grid> :
                                    <>
                                        <Grid item xs={12} lg={5}>
                                            <PaddedPaper style={{height: '100%'}}>
                                                <Grid container xs={12}>
                                                    <Grid item xs={12} style={{marginBottom:10}}>
                                                        <Typography variant='h6' style={{fontWeight: 'bold', textAlign: 'center'}}>Average Margins</Typography>
                                                    </Grid>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell style={{textAlign:'center'}}></TableCell>
                                                                <TableCell style={{textAlign:'center'}}><b>Cost</b></TableCell>
                                                                <TableCell style={{textAlign:'center'}}><b>Net</b></TableCell>
                                                                <TableCell style={{textAlign:'center'}}><b>Margin</b></TableCell>
                                                                <TableCell style={{textAlign:'center'}}><b>Margin %</b></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell style={{textAlign:'left'}}><b>Products</b></TableCell>
                                                                <TableCell style={{textAlign:'center'}}>£{convertRealNumber(parseFloat( ( _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.c)) / _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.q)) ) || 0 )).toFixed(2)}</TableCell>
                                                                <TableCell style={{textAlign:'center'}}>£{convertRealNumber(parseFloat( ( _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.t)) / _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.q)) ) || 0 )).toFixed(2)}</TableCell>
                                                                <TableCell style={{textAlign:'center'}}>£{convertRealNumber(parseFloat( ( ( _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.t)) - _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.c)) ) / _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.q)) ) || 0  )).toFixed(2)}</TableCell>
                                                                <TableCell style={{textAlign:'center'}}>{convertRealNumber(parseFloat( ( ( ( ( _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.t)) - _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.c)) ) / _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.q)) ) / ( _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.t)) / _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.q)) ) ) || 0 ) * 100 )).toFixed(2)}%</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{textAlign:'left'}}><b>Services</b></TableCell>
                                                                <TableCell style={{textAlign:'center'}}>£{convertRealNumber(parseFloat( ( _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.c)) / _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.q)) ) || 0 )).toFixed(2)}</TableCell>
                                                                <TableCell style={{textAlign:'center'}}>£{convertRealNumber(parseFloat( ( _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.t)) / _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.q)) ) || 0 )).toFixed(2)}</TableCell>
                                                                <TableCell style={{textAlign:'center'}}>£{convertRealNumber(parseFloat( ( ( _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.t)) - _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.c)) ) / _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.q)) ) || 0  )).toFixed(2)}</TableCell>
                                                                <TableCell style={{textAlign:'center'}}>{convertRealNumber(parseFloat( ( ( ( ( _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.t)) - _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.c)) ) / _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.q)) ) / ( _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.t)) / _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.q)) ) ) || 0 ) * 100 )).toFixed(2)}%</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{textAlign:'left'}}><b>Total</b></TableCell>
                                                                <TableCell style={{textAlign:'center'}}>£{convertRealNumber(parseFloat( _.sumBy(_.filter(parts, i=>i.ip==1||i.ip==0),i=>parseFloat(i.tot.c)) / _.sumBy(_.filter(parts, i=>i.ip==1||i.ip==0),i=>parseFloat(i.tot.q)) )).toFixed(2)}</TableCell>
                                                                <TableCell style={{textAlign:'center'}}>£{convertRealNumber(parseFloat( _.sumBy(_.filter(parts, i=>i.ip==1||i.ip==0),i=>parseFloat(i.tot.t)) / _.sumBy(_.filter(parts, i=>i.ip==1||i.ip==0),i=>parseFloat(i.tot.q)) )).toFixed(2)}</TableCell>
                                                                <TableCell style={{textAlign:'center'}}>£{convertRealNumber(parseFloat( ( _.sumBy(_.filter(parts, i=>i.ip==1||i.ip==0),i=>parseFloat(i.tot.t)) - _.sumBy(_.filter(parts, i=>i.ip==1||i.ip==0),i=>parseFloat(i.tot.c)) ) / _.sumBy(_.filter(parts, i=>i.ip==1||i.ip==0),i=>parseFloat(i.tot.q)) )).toFixed(2)}</TableCell>
                                                                <TableCell style={{textAlign:'center'}}>{convertRealNumber(parseFloat( ( ( ( ( _.sumBy(_.filter(parts, i=>i.ip==1||i.ip==0),i=>parseFloat(i.tot.t)) - _.sumBy(_.filter(parts, i=>i.ip==1||i.ip==0),i=>parseFloat(i.tot.c)) ) / _.sumBy(_.filter(parts, i=>i.ip==1||i.ip==0),i=>parseFloat(i.tot.q)) ) / ( _.sumBy(_.filter(parts, i=>i.ip==1||i.ip==0),i=>parseFloat(i.tot.t)) / _.sumBy(_.filter(parts, i=>i.ip==1||i.ip==0),i=>parseFloat(i.tot.q)) ) ) || 0 ) * 100 )).toFixed(2)}%</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={12} lg={3}>
                                            <PaddedPaper>
                                                <PieChart 
                                                    data={[
                                                        {label: 'Product',value: _.sumBy(_.filter(parts, {ip:1}), i => { return parseFloat(i.tot.marginTotal)}),color: colors.green},
                                                        {label: 'Service',value: _.sumBy(_.filter(parts, {ip:0}), i => { return parseFloat(i.tot.marginTotal)}),color: colors.blue},
                                                    ]} 
                                                    style={{ height: 243 }}
                                                    xLabel={(v)=>{return `£${parseFloat(v).toFixed(0)}`}}
                                                    tLabel={(e)=>{return `£${parseFloat(e.raw).toFixed(2)}`}}
                                                />
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <Card>
                                                <CardHeader
                                                    avatar={<AllIcon icon={ icons.percent} size='medium' style={{color: '#fff'}} />}
                                                    title={`Margins Report For ${
                                                        filter.to === filter.from ?
                                                            moment(filter.from).format('dddd Do of MMMM') :
                                                            `${moment(filter.from).format('DD/MM/YYYY')} To ${moment(filter.to).format('DD/MM/YYYY')}`
                                                        }`}
                                                    titleTypographyProps={{variant:'h6'}}
                                                    className={classes.header}
                                                />
                                                <CardContent style={{padding:5}}>
                                                    <Table size="small" aria-label="a dense table" style={{color:'black'}}>
                                                        <TableHead style={{backgroundColor:'#f5f5f5'}}>
                                                            <TableRow>
                                                                <TableCell align='center'   style={{width:'3%', borderBottom: 'none', color:'black'}}></TableCell>
                                                                <TableCell align='center'   style={{width:'3%', borderBottom: 'none', color:'black'}}></TableCell>
                                                                <TableCell align='center'   style={{width:'12%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Qty</b></Typography></TableCell>
                                                                <TableCell align='center'   style={{width:'21.25%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Cost</b></Typography></TableCell>
                                                                <TableCell align='center'   style={{width:'21.25%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Net</b></Typography></TableCell>
                                                                <TableCell align='center'   style={{width:'21.25%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Margin</b></Typography></TableCell>
                                                                <TableCell align='center'   style={{width:'21.25%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Margin %</b></Typography></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <OverviewRow data={{
                                                                in: {
                                                                        qty:    _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.in.q)).toFixed(2),
                                                                        cost:   _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.in.c)),
                                                                        price:  _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.in.t)),
                                                                        margin: _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.in.marginTotal)),
                                                                        marginP:(_.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.in.marginTotal))/_.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.in.t))) * 100,
                                                                    },
                                                                out:{
                                                                        qty:    _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.out.q)).toFixed(2),
                                                                        cost:   _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.out.c)),
                                                                        price:  _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.out.t)),
                                                                        margin: _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.out.marginTotal)),
                                                                        marginP:_.meanBy(_.filter(parts, {ip:1}),i=>parseFloat(i.out.marginPercent))? 100 : 0,
                                                                    },
                                                                tot:{
                                                                        qty:    _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.q)).toFixed(2),
                                                                        cost:   _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.c)),
                                                                        price:  _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.t)),
                                                                        margin: _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.t)) - _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.c)),
                                                                        marginP:(( _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.t)) - _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.c))) / _.sumBy(_.filter(parts, {ip:1}),i=>parseFloat(i.tot.t))) *100 ,
                                                                    },

                                                            }} classes={classes} label='products'/>
                                                            <OverviewRow data={{
                                                                in: {
                                                                        qty:    _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.in.q)).toFixed(2),
                                                                        cost:   _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.in.c)),
                                                                        price:  _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.in.t)),
                                                                        margin: _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.in.marginTotal)),
                                                                        marginP:(_.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.in.marginTotal))/_.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.in.t))) * 100,
                                                                    },
                                                                out:{
                                                                        qty:    _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.out.q)).toFixed(2),
                                                                        cost:   _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.out.c)),
                                                                        price:  _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.out.t)),
                                                                        margin: _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.out.marginTotal)),
                                                                        marginP:_.meanBy(_.filter(parts, {ip:0}),i=>parseFloat(i.out.marginPercent))? 100 : 0,
                                                                    },
                                                                tot:{
                                                                        qty:    _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.q)).toFixed(2),
                                                                        cost:   _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.c)),
                                                                        price:  _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.t)),
                                                                        margin: _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.t)) - _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.c)),
                                                                        marginP:(( _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.t)) - _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.c))) / _.sumBy(_.filter(parts, {ip:0}),i=>parseFloat(i.tot.t))) *100 ,
                                                                    },

                                                            }} classes={classes} label='services'/>
                                                            <OverviewRow data={{
                                                                in: {
                                                                        qty:    _.sumBy(parts,i=>parseFloat(i.in.q)).toFixed(2),
                                                                        cost:   _.sumBy(parts,i=>parseFloat(i.in.c)),
                                                                        price:  _.sumBy(parts,i=>parseFloat(i.in.t)),
                                                                        margin: _.sumBy(parts,i=>parseFloat(i.in.marginTotal)),
                                                                        marginP:(_.sumBy(parts,i=>parseFloat(i.in.marginTotal))/_.sumBy(parts,i=>parseFloat(i.in.t))) * 100,
                                                                    },
                                                                out:{
                                                                        qty:    _.sumBy(parts,i=>parseFloat(i.out.q)).toFixed(2),
                                                                        cost:   _.sumBy(parts,i=>parseFloat(i.out.c)),
                                                                        price:  _.sumBy(parts,i=>parseFloat(i.out.t)),
                                                                        margin: _.sumBy(parts,i=>parseFloat(i.out.marginTotal)),
                                                                        marginP:_.meanBy(parts,i=>parseFloat(i.out.marginPercent)) ? 100 : 0,
                                                                    },
                                                                tot:{
                                                                        qty:    _.sumBy(parts,i=>parseFloat(i.tot.q)).toFixed(2),
                                                                        cost:   _.sumBy(parts,i=>parseFloat(i.tot.c)),
                                                                        price:  _.sumBy(parts,i=>parseFloat(i.tot.t)),
                                                                        margin: _.sumBy(parts,i=>parseFloat(i.tot.t)) - _.sumBy(parts,i=>parseFloat(i.tot.c)),
                                                                        marginP:(( _.sumBy(parts,i=>parseFloat(i.tot.t)) - _.sumBy(parts,i=>parseFloat(i.tot.c))) / _.sumBy(parts,i=>parseFloat(i.tot.t))) *100 ,
                                                                    },

                                                            }} classes={classes} label='total'/>
                                                        </TableBody>
                                                    </Table>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={12} style={{padding:'1em 0px'}}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <DownloadCSV 
                                                data={this.getCsvData()} color='primary' variant='contained' 
                                                filename={`${filter.from}_to_${filter.to}_part_report`} 
                                                style={{marginBottom: '1em'}} 
                                            >download Report</DownloadCSV>
                                            <DataTable 
                                                config={{
                                                    key: 'i',
                                                    pagination: false,
                                                    alternatingRowColours: true,
                                                    responsiveimportant: true,
                                                    isLoading: isLoading,
                                                    sticky:true
                                                }}
                                                columns={[
                                                    {
                                                        field: rowData =>   <div style={{width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                                {rowData && rowData.l ?
                                                                                    <Button onClick={() => this.loadPartImage(rowData)}>
                                                                                        <ImageWithError src={rowData.l} alt={`${rowData.n} - ${rowData.d}`} style={{maxWidth: 30, maxHeight: 30}}/>
                                                                                    </Button> 
                                                                                : 
                                                                                    <img src={logo} alt="logo" style={{maxHeight:30, maxWidth:30}}/>
                                                                                }
                                                                            </div>,
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading: 'Number',
                                                        field: rowData => 
                                                            <Grid container spacing={0}
                                                                onClick={()=>{
                                                                    this.props.history.push(`/parts/view/${rowData.i}`);
                                                                }}
                                                                style={{cursor: 'pointer'}}
                                                            >
                                                                <Grid item xs={12}>{rowData.n}</Grid>
                                                            </Grid>,
                                                        dataRef: 'n',
                                                        important: true,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Description',
                                                        field: rowData => 
                                                            <Grid container spacing={0}
                                                                onClick={()=>{
                                                                    this.props.history.push(`/parts/view/${rowData.i}`);
                                                                }}
                                                                style={{cursor: 'pointer'}}
                                                            >
                                                                <Grid item xs={12}>{rowData.d}</Grid>
                                                            </Grid>,
                                                        dataRef: 'd',
                                                        sizeToContent: true,
                                                        truncate: true
                                                    },
                                                    {
                                                        heading: '',
                                                        field: rowData =>
                                                            <Grid container xs={12} style={{textAlign: 'left'}}>
                                                                <Grid item xs={12}><AllIcon icon={'arrow-down-arrow-up'} size='small' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} primaryColor='#33dd33' />Invoiced</Grid>
                                                                <Grid item xs={12} ><AllIcon icon={'arrow-down-arrow-up'} size='small' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} swapOpacity secondaryColor={colors.red} />Credited</Grid>
                                                                <Grid item xs={12}><AllIcon icon={'equals'} size='small' style={{display: 'inline-block', paddingRight: '0.5rem', color: '#2d2d2d'}} />Total</Grid>
                                                            </Grid>,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Qty',
                                                        field: rowData => 
                                                            <Grid container>
                                                                <Grid item xs={12} >{parseFloat(rowData.in.q).toFixed(2)}</Grid>
                                                                <Grid item xs={12} style={{color: colors.red}}>{parseFloat(rowData.out.q || 0).toFixed(2)}</Grid>
                                                                <Grid item xs={12} >{parseFloat(rowData.in.q -rowData.out.q).toFixed(2)}</Grid>
                                                            </Grid>,
                                                        dataRef: rowData => {return parseFloat(rowData.tot.q)},
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Cost',
                                                        field: rowData => 
                                                            <Grid container>
                                                                <Grid item xs={12} >£{ parseFloat(rowData.in.c && !_.isNaN(rowData.in.c) ? Math.abs(rowData.in.c) : 0).toFixed(2)}</Grid>
                                                                <Grid item xs={12} style={{color: colors.red}}>{!!rowData.out.c  && '-' }£{parseFloat(rowData.out.c ? Math.abs(rowData.out.c) : 0).toFixed(2)}</Grid>
                                                                <Grid item xs={12} >{rowData.tot.c < 0  && '-' }£{ parseFloat(rowData.tot.c).toFixed(2)}</Grid>
                                                            </Grid>,
                                                        dataRef: rowData => {
                                                            return parseFloat(rowData.in.c)
                                                        },
                                                        important: true,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Avg. Cost',
                                                        field: rowData => 
                                                            <Grid container>
                                                                <Grid item xs={12} >£{ parseFloat((rowData.in.c / rowData.in.q) || 0).toFixed(2)}</Grid>
                                                                <Grid item xs={12} style={{color: colors.red}}>{!!rowData.out.c  && '-' }£{parseFloat(rowData.out.c ? Math.abs(rowData.out.c / rowData.out.q) : 0).toFixed(2)}</Grid>
                                                                <Grid item xs={12} >{rowData.tot.c < 0  && '-' }£{ parseFloat(_.isNaN(rowData.tot.c / (rowData.in.q -rowData.out.q)) || !_.isFinite(rowData.tot.c / (rowData.in.q -rowData.out.q)) ? 0 : rowData.tot.c / (rowData.in.q -rowData.out.q) ).toFixed(2)}</Grid>
                                                            </Grid>,
                                                        dataRef: rowData => {
                                                            return parseFloat(rowData.tot.c / (rowData.in.q -rowData.out.q))
                                                        },
                                                        important: true,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Net',
                                                        field: rowData => 
                                                            <Grid container>
                                                                <Grid item xs={12} >£{parseFloat(rowData.in.t).toFixed(2)}</Grid>
                                                                <Grid item xs={12} style={{color: colors.red}}>{rowData.out.t < 0 && '-'}£{parseFloat(Math.abs(rowData.out.t) || 0).toFixed(2)}</Grid>
                                                                <Grid item xs={12} >{rowData.tot.t < 0 && '-'}£{parseFloat(Math.abs(rowData.tot.t)).toFixed(2)}</Grid>
                                                            </Grid>,
                                                        dataRef: rowData => {
                                                            return parseFloat(rowData.in.i.t) / parseFloat(rowData.in.q)
                                                        },
                                                        important: true,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Avg. Net',
                                                        field: rowData => 
                                                            <Grid container>
                                                                <Grid item xs={12} >£{parseFloat( ( rowData.in.t / rowData.in.q ) || 0).toFixed(2)}</Grid>
                                                                <Grid item xs={12} style={{color: colors.red}}>{rowData.out.t < 0 && '-'}£{parseFloat(Math.abs(rowData.out.t / rowData.out.q) || 0).toFixed(2)}</Grid>
                                                                <Grid item xs={12} >{rowData.tot.t < 0 && '-'}£{parseFloat(_.isNaN(Math.abs(rowData.tot.t) / (rowData.tot.q)) || !_.isFinite(Math.abs(rowData.tot.t) / (rowData.tot.q))  ? 0 : Math.abs(rowData.tot.t) / (rowData.tot.q)).toFixed(2)}</Grid>
                                                            </Grid>,
                                                        dataRef: rowData => {
                                                            return parseFloat(rowData.in.i.t) / parseFloat(rowData.in.q)
                                                        },
                                                        important: true,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Margin £',
                                                        field: rowData => 
                                                            <Grid container>
                                                                <Grid item xs={12} >{rowData.in.marginTotal < 0 && '-'}£{parseFloat(Math.abs(rowData.in.marginTotal)).toFixed(2)}</Grid>
                                                                <Grid item xs={12} style={{color: colors.red}}>{rowData.out.marginTotal < 0 && '-'}£{parseFloat(Math.abs(rowData.out.marginTotal)).toFixed(2)}</Grid>
                                                                <Grid item xs={12} >{rowData.tot.marginTotal < 0 && '-'}£{parseFloat(Math.abs(rowData.tot.marginTotal)).toFixed(2)}</Grid>
                                                            </Grid>,
                                                        dataRef: rowData => {
                                                            return parseFloat(rowData.in.i.t) / parseFloat(rowData.in.q)
                                                        },
                                                        important: true,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Avg. Margin £',
                                                        field: rowData => 
                                                            <Grid container>
                                                                <Grid item xs={12} >{rowData.in.marginTotal < 0 && '-'}£{parseFloat((Math.abs(rowData.in.marginTotal) / rowData.in.q) || 0).toFixed(2)}</Grid>
                                                                <Grid item xs={12} style={{color: colors.red}}>{rowData.out.marginTotal < 0 && '-'}£{parseFloat((Math.abs(rowData.out.marginTotal)/ rowData.out.q) || 0).toFixed(2)}</Grid>
                                                                <Grid item xs={12} >{rowData.tot.marginTotal < 0 && '-'}£{parseFloat( !isFinite(Math.abs(rowData.tot.marginTotal)/ rowData.tot.q) || _.isNaN(Math.abs(rowData.tot.marginTotal)/ rowData.tot.q) ? 0 : (Math.abs(rowData.tot.marginTotal)/ rowData.tot.q)).toFixed(2)}</Grid>
                                                            </Grid>,
                                                        dataRef: rowData => {
                                                            return Math.abs(rowData.tot.marginTotal)/ rowData.tot.q
                                                        },
                                                        important: true,
                                                        sizeToContent: true
                                                    },
                                                    {
                                                        heading: 'Margin %',
                                                        field: rowData => 
                                                            <Grid container>
                                                                <Grid item xs={12} >{parseFloat(rowData.in.marginPercent).toFixed(2)}%</Grid>
                                                                <Grid item xs={12} style={{color: colors.red}}>{parseFloat(rowData.out.marginPercent).toFixed(2)}%</Grid>
                                                                <Grid item xs={12} style={{color: !!rowData.tot.marginPercent < 0 && colors.red}}>{parseFloat(rowData.tot.marginPercent).toFixed(2)}%</Grid>
                                                            </Grid>,
                                                        dataRef: 'tot.marginPercent',
                                                        important: true,
                                                        sizeToContent: true
                                                    },
                                                ]}
                                                rows={_.orderBy(parts, ['n'], ['asc'] )}
                                            />
                                        </Grid>
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    setPersistence: (key, state)                => dispatch(setPersistence(key, state)),
    deployDialog:   (content, title, size='sm') => dispatch(deployDialog(content, title, null, size)),
    closeDialog:    ()                          => {dispatch(closeDialog())}
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SalesReportMargins));