import React, { Component } from "react";
import _                    from "lodash";

import { Grid, Typography } from '@material-ui/core/';

import DataTable   from 'Components/Common/DataTables/CiDataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import IconHelper from "Helpers/IconHelper";

class details extends Component {

    render () {

        const { subAssembly } = this.props;

        return (
            <Grid item xs={12}>
                <Typography variant="h6">
                    {subAssembly.part.part_number} -{" "}
                    {subAssembly.part.part_description}
                </Typography>
                <Typography variant="h6">
                    Current Version: v{subAssembly.sa_version}{" "}
                    {subAssembly.part.part_outsourced_subassembly === 1 ? (
                        <strong style={{ color: "orange" }}>Outsourced</strong>
                    ) : (
                        ""
                    )}
                </Typography>
                <br></br>
                {subAssembly.sub_assembly_parts.length > 0 &&
                    <DataTable
                        config={{
                            key: "sa_id",
                            showTotals: true,
                            alternatingRowColours: true,
                        }}
                        columns={[
                            {
                                heading: "Number",
                                field: (rowData) => rowData.part.part_number,
                                showTotalLabel: true,
                            },
                            {
                                heading: "Description",
                                field: (rowData) => rowData.part.part_description,
                            },
                            {
                                heading: "Qty",
                                field: (rowData) => rowData.sa_qty,
                                fieldFormat: "decimal:3",
                                showTotal: true,
                                alignment: "right",
                            },
                            {
                                heading: "Cost Per Item",
                                field: (rowData) => rowData.part.default_cost.cost_per_unit,
                                fieldFormat: "decimal:5",
                                fieldPrefix: "£",
                                alignment: "right",
                            },
                            {
                                heading: "Total Cost",
                                field: rowData => rowData ? (rowData?.sa_qty || 0) * (rowData?.part?.default_cost.cost_per_unit || 0) : 0,
                                fieldFormat: "decimal:5",
                                fieldPrefix: "£",
                                showTotal: true,
                                alignment: "right",
                            },
                            {
                                heading: "Cost %",
                                field: (rowData) =>
                                    (((rowData.sa_qty * rowData.part.default_cost.cost_per_unit) /
                                        _.sumBy(subAssembly.sub_assembly_parts, r => (r.sa_qty * r.part.default_cost.cost_per_unit))) *
                                    100) || 100,
                                fieldFormat: "realNumber:percentage",
                                showTotal: true,
                                overwriteTotal: "100.00%",
                                alignment: "right",
                            },
                            {
                                actions: (rowData) => ([
                                    {
                                        name: "View",
                                        icon: IconHelper.subassemblies,
                                        link: {
                                            pathname: "/sub-assemblies/view/" + rowData.latest,
                                        },
                                        disabled: !rowData.latest,
                                    },
                                    {
                                        name: "View",
                                        icon: IconHelper.parts,
                                        link: { pathname: "/parts/view/" + rowData.sa_part_id },
                                        disabled: rowData.part.part_outsourced_subassembly === 1,
                                    },
                                ])
                            },
                        ]}
                        rows={_.sortBy(subAssembly.sub_assembly_parts, function (pn) {
                            return pn.part.part_number;
                        })}
                    />
                }
                <Typography variant="body1" style={{ color: "orange" }}>
                    {subAssembly.part.part_outsourced_subassembly === 1 ? (
                        <strong style={{ display: "flex", alignItems: "center" }}>
                            <FALightIcon icon="info-circle" style={{ color: "orange" }} /> The above
                            cost demonstrates the cost of this Sub Assembly if it was reinstated.
                        </strong>
                    ) : (
                        ""
                    )}
                </Typography>
            </Grid>
        )
    }
}

export default details;