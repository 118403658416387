import React, { Component } from 'react';
import { connect }          from 'react-redux';
import API                  from 'API';
import _                    from 'lodash';
import moment               from 'moment';

import { InputLabel, Input, Grid, Button, Typography, FormControl, TextField, ButtonGroup, Card, CardHeader, CardContent, 
    ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Dialog, DialogContent, DialogTitle } from '@material-ui/core/'
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import LoadingCircle                         from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper                           from 'Components/Common/Paper/PaddedPaper';
import AllIcon                               from 'Components/Common/Icons/AllIcon';
import DataTable                             from 'Components/Common/DataTables/CiDataTable';
import OrderDownloadPrevDocsDialog           from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog              from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';
import CustomerReturnsEmailPrevDocsDialog    from 'Components/CustomerReturns/CustomerReturnsEmailPrevDocsDialog/CustomerReturnsEmailPrevDocsDialog';
import CustomerReturnsDownloadPrevDocsDialog from 'Components/CustomerReturns/CustomerReturnsDownloadPrevDocsDialog/CustomerReturnsDownloadPrevDocsDialog';
import DateRangeDialog                       from 'Components/Reports/SalesReports/DateRangeDialog';
import PDFViewer                             from 'Components/Common/PDFViewer/PDFViewer';
import ImageWithError                        from 'Components/Common/ImageWithError/ImageWithError';

import UpdateCreditLimitDialog from './UpdateCreditLimitDialog';
import EmailCreditLimitDialog  from './EmailCreditLimitDialog';  

import { colors }        from 'Helpers/ColourHelper';
import icons             from 'Helpers/IconHelper';
import { pdfFromBase64 } from "Helpers/PDFHelper";
import { getMediaType }  from 'Helpers/FileHelper';

import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar }            from 'Actions/SnackBar/SnackBar';
import ExcelHelper from 'Helpers/ExcelHelper';

const initialState = {
    searchCriteria: {
        dateFrom:   null,
        dateTo:     null,
        keywords:   null,
    },
    isLoading:              true,
    address:                {},
    currentTab:             0,
    access:                 {},
    credit:                 {},
    stats:                  {},
    LastSent:               {},
    creditLimitHistory:     [],
    statementHistory:       [],
    showDownloadDialog:     false,
}

const styles = theme => ({
    card: {
        color: '#000',
        backgroundColor: '#FFF',
        padding: '0',
        width: '100%'
    },
    header: {
        background: 'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', 
        color: '#FFF'
    },
});

function OverviewBlock({title, icon, data, sumBy, classes}){
    const sum  = _.sumBy(data, i => { return sumBy(i) } );
    return (
        <Grid item container xs={3} >
            <Card className={classes.card} >
                <CardHeader
                    avatar={<AllIcon icon={ icon } size='medium' style={{color: '#fff'}} />}
                    title={title}
                    titleTypographyProps={{variant:'h6'}}
                    className={classes.header}
                />
                <CardContent style={{padding:10}}>
                    <Grid container spacing={1} >
                        <Grid item xs={3}>
                            <PaddedPaper style={{padding:'1em'}}>
                                <Typography variant="h6" style={{textAlign:'center'}}>{data.length}</Typography>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={9}>
                            <PaddedPaper style={{padding:'1em'}}>
                                <Typography variant="h6" style={{textAlign:'center'}}>{sum < 0 && '-'}£{parseFloat(Math.sqrt(Math.pow(sum,2))).toFixed(2)}</Typography>
                            </PaddedPaper>
                        </Grid>
                        {/*}
                        <Grid item xs={12}>
                            Invoices / Credit Notes
                        </Grid>
                        <Grid item xs={3}>
                            <PaddedPaper >
                                <Typography variant="h6" style={{textAlign:'center'}}>{data.length}</Typography>
                            </PaddedPaper>
                        </Grid>
                        <Grid container item xs={9} alignContent='space-between'>
                            <Grid item xs={12}>
                                <PaddedPaper style={{padding:8}}>
                                    <Grid container spacing={3}> 
                                        <Grid item xs={4}>Products:</Grid>
                                        <Grid item xs={2}>{_.sumBy(data,'products') || 0}</Grid>
                                        <Grid item xs={6}>{psum < 0 && '-'}£{parseFloat(Math.sqrt(Math.pow(psum,2))).toFixed(2)}</Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12}>
                                <PaddedPaper style={{padding:8}}>
                                    <Grid container spacing={3}> 
                                        <Grid item xs={4}>Services:</Grid>
                                        <Grid item xs={2}>{_.sumBy(data,'services') || 0}</Grid>
                                        <Grid item xs={6}>{ssum < 0 && '-'}£{parseFloat(Math.sqrt(Math.pow(ssum,2))).toFixed(2)}</Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        */}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

function DueTable({data, access, title, handleDownloadOpen, handleEmailOpen, handleCNDownloadOpen, handleCNEmailOpen}){
    return(
        <Grid item xs={12}>
            <ExpansionPanel styles={{width:'100%'}}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{title}</Typography> 
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <DataTable 
                        config={{
                            key:                   'id',
                            pagination:            false,
                            alternatingRowColours: true,
                            responsiveimportant:   true,
                            showTotals:            true,
                        }}
                        columns={[
                            {
                                heading:     'Date',
                                field:       'date', 
                                dataRef:     'date', 
                                fieldFormat: 'clenydate',
                                style:       rowData => ({color: rowData.cn && colors.red})
                            },
                            {
                                actionsCustomHeader: true,
                                important:           true,
                                alignment:           'left',
                                heading:             'Reference',
                                actions:             rowData => _.filter([
                                                                    { label: rowData.invoice[0], link: `${rowData.cn ? '/returns/creditnote/invoiced/' : '/sales/order/invoice/payments/'}${rowData.invoice[1]}`, type:'plainLink', color: rowData.cn && colors.red },
                                                                    rowData.order[1] && { label: rowData.order[0], link: `/sales/order/view/${rowData.order[1]}`, type:'plainLink' }
                                                                ], i => !!i),
                                dataRef:             rowData => rowData.invoice[0]
                            },
                            {
                                heading:       'Number',
                                field:         'number',
                                sizeToContent: true,
                                main:          true,
                                dataRef:       'number',
                                style:         rowData => ({color: rowData.cn && colors.red})
                            },
                            {
                                heading:     'Due',
                                field:       'due',
                                alignment:   'center',
                                dataRef:     'due',
                                fieldFormat: 'clenydate',
                                style:        rowData => ({color: rowData.cn && colors.red})
                            },
                            {
                                heading:     'Due Days',
                                field:       'creditDays',
                                alignment:   'center',
                                dataRef:     'creditDays',
                                fieldFormat: 'colorNumbers'
                            },
                            {
                                heading:   'Term Days',
                                field:     r => r.termDays || '-',
                                alignment: 'center',
                                dataRef:   'termDays',
                                style:     rowData => ({color: rowData.cn && colors.red})
                            },
                            {
                                heading: 'Payment Method',
                                field: rowData => <Grid container style={{justifyContent: 'center'}}>{_.map(rowData.paymentMethods, i => <Grid item><AllIcon style={{color: (rowData.cn ? (rowData.invoice.cni_status === 'Completed') : (rowData.invoice_status === 'Completed')) ? colors.green : colors.orange}} icon={icons[i] || icons.unknown} tooltip={i} /></Grid>)}</Grid>,
                                alignment: 'center',
                                dataRef: 'termDays',
                                showTotalLabel: true
                            },
                            {
                                heading:     'Total',
                                field:       'total',
                                alignment:   'right',
                                showTotal:   true,
                                dataRef:     'total',
                                fieldFormat: 'currency',
                                style:     rowData => ({color: rowData.cn && colors.red})
                            },
                            {
                                heading:     'Paid',
                                field:       'paid',
                                alignment:   'right',
                                showTotal:   true,
                                dataRef:     'paid',
                                fieldFormat: 'currency',
                                style:     rowData => ({color: rowData.cn && colors.red})
                            },
                            {
                                heading:     'Remaining',
                                field:       'remaining',
                                alignment:   'right',
                                showTotal:   true,
                                dataRef:     'remaining',
                                fieldFormat: 'currency',
                                style:     rowData => ({color: rowData.cn && colors.red})
                            },
                            {
                                actions: rowData => ([
                                    {name: 'Download',                      icon: 'download',       onClick: () => { rowData.cn ? handleCNDownloadOpen(rowData.id) :  handleDownloadOpen(rowData.id)}},
                                    {name: 'Send to Customer',              icon: 'envelope',       onClick: () => { rowData.cn ? handleCNEmailOpen(rowData.id) : handleEmailOpen(rowData.id)}},
                                    {name: 'View',                          icon: 'search',         link: rowData.cn ?  `/returns/creditnote/invoice/${rowData.id}` : `/sales/order/invoice/payments/${rowData.id}`},
                                ])
                            }
                        ]}
                        rows={_.orderBy(data, rowData=>{moment(rowData.date).format('YYYYMMDD');}, 'asc')}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Grid>
    )
}

class CustomerAccount extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getAddress(1);
        this.getAccess();
        this.getHistory();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/customer-order-search'),
            API.get('/staff/my/access/check/update-customer-credit-limit'),
            API.get('/staff/my/access/check/process-customer-credit-limit'),
        ])
        .then(([customerOrderSearch, updateCustomerCreditLimit, processCustomerCreditLimit]) => {
            this.setState({
                access: {
                    customerOrderSearch:        (customerOrderSearch?.data?.has_access)        || false,
                    updateCustomerCreditLimit:  (updateCustomerCreditLimit?.data?.has_access)  || false,
                    processCustomerCreditLimit: (processCustomerCreditLimit?.data?.has_access) || false,
                },
            })
        });
    }

    getAddress = (first= false) => {
        API.get(`/customers/${this.props.cid}/accounts/addresses/${this.props.aid}`, {params:{singleAddress: this.props.back ? 0 : 1, ...this.state.searchCriteria}})
        .then(res => {
            this.setState({
                address:    res.data,
                credit: (first) ? res.data : this.state.credit,
                isLoading:  false
            }, this.getStats)
        });
    }
    handleTabChange      = (event, value)  => { this.setState({currentTab: value}); };

    handleDownloadOpen   = orderId    => {this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md')};

    handleEmailOpen      = orderId    => {this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />, 'Email Previous Documents', '', 'md')};

    handleCNDownloadOpen = creditNote => {this.props.deployDialog(<CustomerReturnsDownloadPrevDocsDialog cnId={creditNote} />, 'Download Previous Documents',  '', 'md')};

    handleCNEmailOpen    = creditNote => {this.props.deployDialog(<CustomerReturnsEmailPrevDocsDialog cnId={creditNote} />, 'Email Previous Documents',     '', 'md')};

    handleDateChange = name => date => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                [name]: date
            },
        },this.getAddress);
    };

    handleSearchKeyword = (e) => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                keywords: e.target.value
            },
        },this.getAddress);
    };

    resetSearch = () => {
        this.setState({searchCriteria: initialState.searchCriteria,},this.getAddress);
    }

    editCreditNote = () => {
        this.props.deployDialog(<UpdateCreditLimitDialog customerId={this.props.cid} addressId={this.props.aid} limit={this.state.credit.net} callback={()=>{this.getHistory();this.getAddress(1);}} />, 'Update Credit Limit', null, 'sm')
    }

    getStats = () => {
        const { address } = this.state;
        const all = _.concat(address.completed, address.over);
        //const all = _.concat(address.completed, address.due, address.over);
        this.setState({
            stats: {
                reliability: 100 - Math.round(( _.filter(all, {overDue:true}).length / all.length ) * 100),
                total:       parseFloat(_.sumBy(_.filter(all,{credited:true}), i => { return  parseFloat(i.invoice_total_incl_vat) } )).toFixed(2)
            }
        })
    }

    download = () => {
        API.post(`/customers/${this.props.cid}/accounts/addresses/${this.props.aid}/statement/download`).then((result) => {
            if (result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
                this.props.deploySnackBar("success", "PDF successfully downloaded");
            }
        });
    }

    handleDownloadPrev = (i,full=0) => {
        API.post(`/customers/${this.props.cid}/accounts/addresses/${this.props.aid}/statement/${i}/download`, {full})
        .then((result) => {
            if (result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
                this.props.deploySnackBar("success", "PDF successfully downloaded")
            }
        });
    }

    downloadExcelPrev = (i) => () => {
        API.post(`/customers/${this.props.cid}/accounts/addresses/${this.props.aid}/statement/${i}/download`, {dataOnly: true}).then(({data}) => {
            if (data) {

                let { details, customer, address } = data;

                const excel = new ExcelHelper();

                excel.setItems(
                    [
                        ['Date', 'Invoice No.', 'Details', 'Debit', 'Credit'],
                    ],1, 'B'
                );
                excel.setItems(_.map(details, i=>({
                    date: i.ccssld_date,
                    no: i.ccssld_invoice_no,
                    details: i.ccssld_details,
                    debit: parseFloat(i.ccssld_debit).toFixed(2),
                    credit: parseFloat(0 - parseFloat(i.ccssld_credit)).toFixed(2),
                })), 2, 'B');

                excel.setItems(
                    [
                        [ 'Account Number', `CS${customer.cust_id}` ],
                        [ 'Name', customer.cust_name ],
                        [ 'Address',  `${address.address_name}, ${address.address_line_one}, ${address.address_postcode}` ],
                        [ 'Date',   moment(data.ccssl_sent_datetime).format('DD/MM/YYYY') ],
                        [ 'Payment Terms',  customer.payment_term.payment_term_name ],
                    ],
                    1,
                    'H'
                );

                excel.setItems(
                    [
                        [ 'Current', `${parseFloat(data.ccssl_credit_current?.replaceAll(',','') ?? 0).toFixed(2)}` ],
                        [ 'Over 30 Days', `${parseFloat(data.ccssl_credit_o30?.replaceAll(',','') ?? 0).toFixed(2)}` ],
                        [ 'Over 60 Days', `${parseFloat(data.ccssl_credit_o60?.replaceAll(',','') ?? 0).toFixed(2)}` ],
                        [ 'Over 90 Days', `${parseFloat(data.ccssl_credit_o90?.replaceAll(',','') ?? 0).toFixed(2)}` ],
                        [ 'Older', `${parseFloat(data.ccssl_credit_older?.replaceAll(',','') ?? 0).toFixed(2)}` ],
                    ],
                    7,
                    'H'
                );

                excel.setItems(
                    [
                        [ 'Invoiced', `${parseFloat(_.sumBy(details, i => parseFloat(i.ccssld_debit))).toFixed(2)}` ],
                        [ 'Credited', `-${parseFloat(_.sumBy(details, i => parseFloat(i.ccssld_credit))).toFixed(2)}` ],
                        [ 'Total', `${parseFloat(data.ccssl_credit?.replaceAll(',','') ?? 0).toFixed(2)}` ]
                    ],
                    13,
                    'H'
                );

                excel.setStyles([
                    [{font: { bold: true, color: { rgb: "FFFFFF" } }, fill: { fgColor: { rgb: "0282c6" } }, alignment: { horizontal: 'center' }}, 'B2', 'F2'],
                    [{font: { bold: true }}, 'H2', 'H6'],
                    [{font: { bold: true }}, 'H8', 'H12'],
                    [{font: { bold: true }}, 'H14', 'H16']
                ]);

                excel.setFormat(`E3:F${details.length + 4}`);
                excel.setFormat(`I8:I16`);

                excel.sizeToContent();

                excel.setBorders(['H8:I12', 'H14:I16']);
                excel.download(`CS${data.ccssl_cust_id}-account_statement_${moment(data.ccssl_sent_datetime).format('YYYY_MM_DD')}.xlsx`);

            }

        });
    }

    downloadExcel = () => () => {
        API.post(`/customers/${this.props.cid}/accounts/addresses/${this.props.aid}/statement/download`, {dataOnly: true}).then((result) => {
            if (result.data) {
                let customer = result.data[0].customer;
                let address = result.data[0].address;

                const excel = new ExcelHelper();
                

                excel.setItems(
                    [
                        ['Date', 'Invoice No.', 'Details', 'Debit', 'Credit'],
                    ],1, 'B'
                );
                excel.setItems(_.map(result.data[0].invoices, i=>({
                    date: i.date,
                    no: i.number,
                    details: i.detail,
                    debit: parseFloat(i.debit ?? 0).toFixed(2),
                    credit: parseFloat(0 - (i.credit ?? 0)).toFixed(2),
                })), 2, 'B');   

                excel.setItems(
                    [
                        [ 'Account Number', `CS${customer.cust_id}` ],
                        [ 'Name', customer.cust_name ],
                        [ 'Address',  `${address.address_name}, ${address.address_line_one}, ${address.address_postcode}` ],
                        [ 'Date',   moment().format('DD/MM/YYYY') ],
                        [ 'Payment Terms',  customer.payment_term.payment_term_name ],
                    ],
                    1,
                    'H'
                );

                excel.setItems(
                    [
                        [ 'Current', `${parseFloat(result.data[0].breakDown.current?.replaceAll(',','') ?? 0).toFixed(2)}` ],
                        [ 'Over 30 Days', `${parseFloat(result.data[0].breakDown.o30?.replaceAll(',','') ?? 0).toFixed(2)}` ],
                        [ 'Over 60 Days', `${parseFloat(result.data[0].breakDown.o60?.replaceAll(',','') ?? 0).toFixed(2)}` ],
                        [ 'Over 90 Days', `${parseFloat(result.data[0].breakDown.o90?.replaceAll(',','') ?? 0).toFixed(2)}` ],
                        [ 'Older', `${parseFloat(result.data[0].breakDown.older?.replaceAll(',','') ?? 0).toFixed(2)}` ],
                    ],
                    7,
                    'H'
                );

                excel.setItems(
                    [
                        [ 'Invoiced', `${parseFloat(result.data[0].breakDown.in?.replaceAll(',','') ?? 0).toFixed(2)}` ],
                        [ 'Credited', `-${parseFloat(result.data[0].breakDown.cn?.replaceAll(',','') ?? 0).toFixed(2)}` ],
                        [ 'Total', `${parseFloat(result.data[0].breakDown.tot?.replaceAll(',','') ?? 0).toFixed(2)}` ]
                    ],
                    13,
                    'H'
                );

                // excel.setItems(l
                //     [
                //         [ 'Invoiced', `=SUM(E3:E${result.data[0].invoices.length + 4})` ],
                //         [ 'Credited', `=SUM(F3:F${result.data[0].invoices.length + 4})` ],
                //         [ 'Total', `£${parseFloat(result.data[0].breakDown.tot?.replaceAll(',','') ?? 0).toFixed(2)}` ]
                //     ],
                //     17,
                //     'H'
                // );

                excel.setStyles([
                    [{font: { bold: true, color: { rgb: "FFFFFF" } }, fill: { fgColor: { rgb: "0282c6" } }, alignment: { horizontal: 'center' }}, 'B2', 'F2'],
                    [{font: { bold: true }}, 'H2', 'H6'],
                    [{font: { bold: true }}, 'H8', 'H12'],
                    [{font: { bold: true }}, 'H14', 'H16']
                ]);

                excel.setFormat(`E3:F${result.data[0].invoices.length + 4}`);
                excel.setFormat(`I8:I16`);

                excel.sizeToContent();

                excel.setBorders(['H8:I12', 'H14:I16']);

                excel.download(`accounts_statement_CS${customer.cust_id}_${moment().format('YYYY_MM_DD')}.xlsx`);
            }
        });
    }

    downloadFull = range => {
        API.post(`/customers/${this.props.cid}/accounts/addresses/${this.props.aid}/statement/download`,{full:1, ...range}).then((result) => {
            if (result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
                this.props.closeDialog();
                this.props.deploySnackBar("success", "PDF successfully downloaded")
            }
        });
    }

    handleEmailStatement = () => {
        this.props.deployDialog(<EmailCreditLimitDialog customerId={this.props.cid} addressId={this.props.aid} callback={this.getHistory}/>, 'Email Accounts Statement', 'success', 'md');
    }
    handleEmailFullStatement = range => {
        this.props.deployDialog(<EmailCreditLimitDialog customerId={this.props.cid} addressId={this.props.aid} full={1} callback={this.getHistory} range={range}/>, 'Email Full Statement', 'success', 'md');
    }

    getHistory = () => {
        Promise.all([
            API.get(`/customers/${this.props.cid}/accounts/addresses/${this.props.aid}/creditLimit/history`),
            API.get(`/customers/${this.props.cid}/accounts/addresses/${this.props.aid}/statement/history`)
        ])
        .then(([resCredit, resStatement]) => {
            this.setState({
                statementHistory:   resStatement.data,
                creditLimitHistory: resCredit.data
            });
        })
    }

    

    handlePreviewPrev = (i,full=0) => {
        API.post(`/customers/${this.props.cid}/accounts/addresses/${this.props.aid}/statement/${i}/download`, {full})
        .then((result) => {
            if(result.data) this.props.deployDialog(
                <PDFViewer pdfData={result.data.pdf} onClose={this.props.closeDialog} style={{ height:'100%', maxHeight: 700, minHeight: 400, maxWidth: '100%' }} />
                ,'', null, 'md'
            );
        });
    }

    handlePaymentOpen = r => {
        this.props.deployDialog(
            <>
                <FormControl margin="normal" fullWidth>
                    <InputLabel shrink={true}>Payment Date</InputLabel>
                    <Input value={moment(r.date).format('DD/MM/YYYY')} disabled />
                </FormControl>
                <FormControl margin="normal" fullWidth>
                    <InputLabel shrink={true}>Payment Method</InputLabel>
                    <Input value={r.method} disabled />
                </FormControl>
                <FormControl margin="normal" fullWidth>
                    <InputLabel shrink={true}>Payment Amount</InputLabel>
                    <Input value={`£${r.amount}`} disabled />
                </FormControl>
                {(r.reference && r.reference !== '') &&
                    <FormControl margin="normal" fullWidth>
                        <InputLabel shrink={true}>Reference</InputLabel>
                        <Input value={r.reference} disabled />
                    </FormControl>
                }
                {(r.auth && r.auth !== '') ?
                    <FormControl margin="normal" fullWidth>
                        <InputLabel shrink={true}>Auth</InputLabel>
                        <Input value={r.auth} disabled />
                    </FormControl> : 
                    (
                        (r.payee && r.payee !== '') &&
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true}>Payee</InputLabel>
                                <Input value={r.payee} disabled />
                            </FormControl>
                    )
                }
                <FormControl margin="normal" fullWidth>
                    <TextField
                        fullWidth
                        label="Payment File"
                        value={r.file ? _.last(r.file.split('/')) : 'No File'}
                        disabled
                        InputProps={{
                            endAdornment: r.file && (<AllIcon icon={icons.search} onClick={this.viewFile(r.file)} noMargin />)
                        }}
                    />
                </FormControl>
                <div className='buttonRow' style={{width: '100%'}}>
                    <Button variant="outlined" color="primary" onClick={this.props.closeDialog}>Close</Button>
                </div>
            </>, 'Payment Details', null, 'md'
        )
    }

    viewFile = file => () => {
        this.props.deployDialog(
            <Grid container spacing={1}>
                <Grid item xs={12} style={{textAlign:'center'}}>
                    {getMediaType(file) === 'image' &&
                        <ImageWithError height='500' width='500' src={file} />
                    }
                    {getMediaType(file) === 'pdf' &&
                        <PDFViewer pdfUrl={file} />
                    }
                    {(getMediaType(file) !== 'pdf' && getMediaType(file) !== 'image') && 'File Not Supported'}
                </Grid>
                <Grid className='buttonRow' item xs={12}>
                    <Button onClick={()=>{ this.props.closeDialog() }} variant='outlined'>Close</Button>
                </Grid>
            </Grid>, 'View File', 'success', 'md');
    }

    render(){
        const { isLoading, address, access, credit, statementHistory, creditLimitHistory } = this.state;
        const { classes } =  this.props;

        return (
            <>
                {isLoading ? <LoadingCircle/> : 
                    <>
                        <Grid container xs={12} spacing={3} style={{paddingTop: '1.8em', paddingLeft: '1.5em'}}>
                            {this.props.back && 
                                <>
                                    <Grid item xs={11}>
                                        <Typography variant='h4'>{address.address.address_name}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Button onClick={()=>{this.props.back()}} fullWidth variant="outlined">Back</Button>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={9}>
                                <Grid container xs={12}>
                                    <Grid item xs={10}>
                                        <PaddedPaper style={{marginBottom:0, padding: 17}}>
                                            <Grid container spacing={2} style={{justifyContent: 'center'}}>
                                                <Grid item>
                                                    Total Credit
                                                    <Typography variant='h4' style={{textAlign: 'center'}}>{(credit.debit - credit.credit) < 0 ? '-' : ''}£{parseFloat(Math.sqrt(Math.pow((credit.debit - credit.credit),2))).toFixed(2)}</Typography>
                                                </Grid>
                                                <Grid item >
                                                    <br/>
                                                    <AllIcon size='large' icon={'slash-forward'} noMargin />
                                                </Grid>
                                                <Grid item>
                                                    Limit
                                                    <Typography variant='h4' style={{textAlign: 'center'}}>£{parseFloat(credit.gross).toFixed(2)}</Typography>
                                                </Grid>
                                                <Grid item >
                                                    <br/>
                                                    <AllIcon size='large' icon={'slash-forward'} noMargin />
                                                </Grid>
                                                <Grid item>
                                                    Remaining
                                                    <Typography variant='h4' style={{
                                                        textAlign: 'center', 
                                                        color: (credit.gross - ( credit.debit - credit.credit )) < 0 ? 
                                                            colors.red : 
                                                            (parseFloat(Math.sqrt(Math.pow(credit.gross - ( credit.debit - credit.credit ),2))) < ( credit.gross / 10 ) ? colors.orange : colors.green)}}
                                                    >{/*(credit.gross - ( credit.debit - credit.credit )) < 0 && '-'*/}£{parseFloat(Math.sqrt(Math.pow(credit.gross - ( credit.debit - credit.credit ),2))).toFixed(2)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={2} style={{paddingLeft:'1.5em'}}>
                                        <PaddedPaper style={{textAlign:'center', padding: 4}}>
                                            <div style={{marginBottom:2}}>Avg. Payment / Term Days</div>
                                            <Grid container xs={12} spacing={1} justifyContent='center' style={{justifyContent: 'center'}}>
                                                <Grid item style={{fontSize:31, color: parseInt(_.meanBy(_.concat(address.completed, address.over), i => i.termDays)) < parseInt(_.meanBy(_.concat(address.completed, address.over), i => i.dueDays)) && colors.red}} >
                                                    {_.isNaN(parseInt(_.meanBy(_.concat(address.completed, address.over), i => i.dueDays))) ? 0 : parseInt(_.meanBy(_.concat(address.completed, address.over), i => i.dueDays))}
                                                </Grid>
                                                <Grid item style={{fontSize:31}} >/</Grid>
                                                <Grid item style={{fontSize:31}} >{_.isNaN(parseInt(_.meanBy(_.concat(address.completed, address.over), i => i.termDays))) ? 0 : parseInt(_.meanBy(_.concat(address.completed, address.over), i => i.termDays))}</Grid>
                                            </Grid> 
                                        </PaddedPaper>
                                            
                                    </Grid>
                                </Grid>
                                <ButtonGroup fullWidth variant="contained"  color='primary' style={{marginTop:'1.6em'}}>
                                    {access.processCustomerCreditLimit && 
                                        [<Button  
                                            style={{textTransform:'none'}}
                                            onClick={this.handleEmailStatement}
                                        >
                                            <AllIcon icon={icons.email} style={{color:'#fff'}}/>
                                            <span style={{width: '100%'}}>Email <br/> Accounts Statement</span>
                                        </Button>,
                                        <Button  
                                            style={{textTransform:'none'}}
                                            // onClick={this.download}
                                            // onClick={this.downloadExcel
                                            onClick={()=>this.setState({showDownloadDialog: !this.state.showDownloadDialog})}
                                        >
                                            <AllIcon icon={icons.download} style={{color:'#fff'}}/>
                                            Download Accounts Statement
                                        </Button>,
                                        <Button  
                                            onClick={()=>{this.props.deployDialog(<DateRangeDialog callback={this.handleEmailFullStatement} subLabel={'Email'} close={this.props.closeDialog} />, 'Pick A Date Range', null,'sm') }}
                                            style={{textTransform:'none'}}
                                        >
                                            <AllIcon icon={icons.email} style={{color:'#fff'}}/>
                                            <span style={{width: '100%'}}>Email <br/> Account Activity</span>
                                        </Button>,
                                        <Button  
                                            style={{textTransform:'none'}}
                                            onClick={()=>{this.props.deployDialog(<DateRangeDialog callback={this.downloadFull} subLabel={'Download'} close={this.props.closeDialog} />, 'Pick A Date Range', null,'sm') }}
                                        >
                                            <AllIcon icon={icons.download} style={{color:'#fff'}}/>
                                            <span style={{width: '100%'}}>Download <br/> Account Activity</span>
                                        </Button>
                                    ]}
                                    {access.updateCustomerCreditLimit &&
                                        <Button  
                                            onClick={this.editCreditNote}
                                            style={{textTransform:'none'}}
                                        >
                                            <AllIcon icon={icons.edit} style={{color:'#fff'}}/>
                                            <span style={{width: '100%'}}>Edit <br/> Credit Limit</span>
                                        </Button>
                                    }
                                </ButtonGroup>
                            </Grid>
                            <Grid item xs={3}>
                                <PaddedPaper style={{padding: '1em'}}>
                                    <div style={{marginBottom:10, textAlign:'center'}}>Break Down</div>
                                    <Grid container xs={12} spacing={0}>
                                        <Grid item xs={5}><Typography>Current:</Typography></Grid>
                                        <Grid item xs={2}>{_.filter(_.concat(address.due, address.over, address.creditNote),  rowData => rowData.dueDays <= 30).length}</Grid>
                                        <Grid item xs={5}><Typography>£{parseFloat(_.sumBy(_.filter(_.concat(address.due, address.over, address.creditNote),  
                                            rowData => {
                                                return rowData.dueDays <= 30
                                            }), 
                                            rowData => {
                                                let v = parseFloat( rowData.cn ? rowData.invoice.cni_total_incl_vat : rowData.invoice_total_incl_vat) - parseFloat(rowData.sum_of_payments || 0);
                                                return (rowData.cn) ? 0 - v : v;
                                            })).toFixed(2)}
                                        </Typography></Grid>

                                        <Grid item xs={5}><Typography>Over 30 days:</Typography></Grid>
                                        <Grid item xs={2}>{_.filter(_.concat(address.due, address.over, address.creditNote),  rowData => rowData.dueDays <= 60 && rowData.dueDays > 30).length}</Grid>
                                        <Grid item xs={5}><Typography>£{parseFloat(_.sumBy(_.filter(_.concat(address.due, address.over, address.creditNote),  
                                            rowData => {
                                                return rowData.dueDays <= 60 && rowData.dueDays > 30
                                            }), 
                                            rowData => {
                                                let v = parseFloat( rowData.cn ? rowData.invoice.cni_total_incl_vat : rowData.invoice_total_incl_vat) - parseFloat(rowData.sum_of_payments || 0);
                                                return (rowData.cn) ? 0 - v : v;
                                            })).toFixed(2)}
                                        </Typography></Grid>

                                        <Grid item xs={5}><Typography>Over 60 days:</Typography></Grid>
                                        <Grid item xs={2}>{_.filter(_.concat(address.due, address.over, address.creditNote),  rowData => rowData.dueDays <= 90 && rowData.dueDays > 60).length}</Grid>
                                        <Grid item xs={5}><Typography>£{parseFloat(_.sumBy(_.filter(_.concat(address.due, address.over, address.creditNote),  
                                            rowData => {
                                                return rowData.dueDays <= 90 && rowData.dueDays > 60
                                            }), 
                                            rowData => {
                                                let v = parseFloat( rowData.cn ? rowData.invoice.cni_total_incl_vat : rowData.invoice_total_incl_vat) - parseFloat(rowData.sum_of_payments || 0);
                                                return (rowData.cn) ? 0 - v : v;
                                            })).toFixed(2)}
                                        </Typography></Grid>

                                        <Grid item xs={5}><Typography>Over 90 days:</Typography></Grid>
                                        <Grid item xs={2}>{_.filter(_.concat(address.due, address.over, address.creditNote),  rowData => rowData.dueDays <= 120 && rowData.dueDays > 90).length}</Grid>
                                        <Grid item xs={5}><Typography>£{parseFloat(_.sumBy(_.filter(_.concat(address.due, address.over, address.creditNote),  
                                            rowData => {
                                                return rowData.dueDays <= 120 && rowData.dueDays > 90
                                            }), 
                                            rowData => {
                                                let v = parseFloat( rowData.cn ? rowData.invoice.cni_total_incl_vat : rowData.invoice_total_incl_vat) - parseFloat(rowData.sum_of_payments || 0);
                                                return (rowData.cn) ? 0 - v : v;
                                            })).toFixed(2)}
                                        </Typography></Grid>

                                        <Grid item xs={5}><Typography>Older:</Typography></Grid>
                                        <Grid item xs={2}>{_.filter(_.concat(address.due, address.over, address.creditNote),  rowData => rowData.dueDays > 120).length}</Grid>
                                        <Grid item xs={5}><Typography>£{parseFloat(_.sumBy(_.filter(_.concat(address.due, address.over, address.creditNote),  
                                            rowData => {
                                                return rowData.dueDays > 120
                                            }), 
                                            rowData => {
                                                let v = parseFloat( rowData.cn ? rowData.invoice.cni_total_incl_vat : rowData.invoice_total_incl_vat) - parseFloat(rowData.sum_of_payments || 0);
                                                return (rowData.cn) ? 0 - v : v;
                                            })).toFixed(2)}
                                        </Typography></Grid>

                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <OverviewBlock title='Due'             icon={icons.creditDue}        data={address.due}        classes={classes} sumBy={rowData =>{return parseFloat(rowData.invoice_total_incl_vat) - parseFloat(rowData.sum_of_payments || 0)}} />
                            <OverviewBlock title='Over Due'        icon={icons.creditOverDue}    data={address.over}       classes={classes} sumBy={rowData =>{return parseFloat(rowData.invoice_total_incl_vat) - parseFloat(rowData.sum_of_payments || 0)}} />
                            <OverviewBlock title='Credit Note Due' icon={icons.creditCreditNote} data={address.creditNote} classes={classes} sumBy={rowData =>{return 0 - (parseFloat(rowData.cn_total_incl_vat) - parseFloat(rowData.invoice.sum_of_payments || 0))}} />
                            <OverviewBlock title='Completed'       icon={'check'}                data={address.completed}  classes={classes} sumBy={rowData =>{return rowData.cn ? (0 - parseFloat(rowData.cn_total_incl_vat)) : parseFloat(rowData.invoice_total_incl_vat) }} />
                        </Grid>
                        <Grid container xs={12}  style={{paddingTop: '2em', paddingLeft: '1em', paddingRight: '1em'}}>
                            <Grid item xs={12}>
                                <ExpansionPanel styles={{width:'100%'}}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Account Statement History</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <DataTable
                                            config={{
                                                key:            'cl_id',
                                                pagination:     false,
                                                plainHeader:    false,
                                                isLoading:      isLoading
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Sent',
                                                    field: rowData => <>
                                                        {moment(rowData.ccssl_sent_datetime).format("DD/MM/YYYY")}
                                                    </>
                                                },
                                                {
                                                    heading: 'Under 30 Days',
                                                    field: rowData => `£${rowData.ccssl_credit_current}`
                                                },
                                                {
                                                    heading: 'Over 30 Days',
                                                    field: rowData => `£${rowData.ccssl_credit_o30}`
                                                },
                                                {
                                                    heading: 'Over 60 Days',
                                                    field: rowData => `£${rowData.ccssl_credit_o60}`
                                                },
                                                {
                                                    heading: 'Over 90 Days',
                                                    field: rowData => `£${rowData.ccssl_credit_o90}`
                                                },
                                                {
                                                    heading: 'Older',
                                                    field: rowData => `£${rowData.ccssl_credit_older}`
                                                },
                                                {
                                                    heading: 'Total',
                                                    field: rowData => `£${rowData.ccssl_credit}`
                                                },
                                                {
                                                    heading: 'Set To',
                                                    field: rowData => `${rowData.ccssl_received_contact_name}`,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Set By',
                                                    field: rowData => `${rowData.ccssl_staff_name}`,
                                                    important: true,
                                                },
                                                {
                                                    actions: rowData => [
                                                        {name: 'Excel', icon: icons.excel,       onClick: this.downloadExcelPrev(rowData.ccssl_id)},
                                                        {name: 'Download', icon: icons.download, onClick: () => this.handleDownloadPrev(rowData.ccssl_id)},
                                                        {name: 'View', icon: icons.search,       onClick: () => this.handlePreviewPrev(rowData.ccssl_id)},
                                                    ]
                                                }
                                            ]}
                                            rows={_.orderBy(statementHistory.account, i => {return moment(i.ccssl_sent_datetime).format("YYYYMMDDHHmmss");}, 'desc')}
                                        />
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                            <Grid item xs={12}>
                                <ExpansionPanel styles={{width:'100%'}}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Account Activity Statement History</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <DataTable
                                            config={{
                                                key:            'cl_id',
                                                pagination:     false,
                                                plainHeader:    false,
                                                isLoading:      isLoading
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Sent',
                                                    field: rowData => <>
                                                        {moment(rowData.ccaassl_sent_datetime).format("DD/MM/YYYY")}
                                                    </>
                                                },
                                                {
                                                    heading: 'From',
                                                    field: rowData => `${moment(rowData.ccaassl_from).format("DD/MM/YYYY")}`
                                                },
                                                {
                                                    heading: 'To',
                                                    field: rowData => `${moment(rowData.ccaassl_to).format("DD/MM/YYYY")}`
                                                },
                                                {
                                                    heading: 'Invoice',
                                                    field: rowData => `£${parseFloat(rowData.ccaassl_invoice).toFixed(2)}`
                                                },
                                                {
                                                    heading: 'Paid',
                                                    field: rowData => `£${parseFloat(rowData.ccaassl_paid + rowData.ccaassl_credited).toFixed(2)}`
                                                },
                                                {
                                                    heading: 'Outstanding',
                                                    field: rowData => `£${parseFloat(rowData.ccaassl_remaining).toFixed(2)}`
                                                },
                                                {
                                                    heading: 'Set To',
                                                    field: rowData => `${rowData.ccaassl_contact_name}`,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Set By',
                                                    field: rowData => `${rowData.ccaassl_staff_name}`,
                                                    important: true,
                                                },
                                                {
                                                    actions: rowData => [
                                                        {name: 'Download', icon: icons.download, onClick: () => this.downloadFull({from: rowData.ccaassl_from, to: rowData.ccaassl_to})},
                                                    ]
                                                }
                                            ]}
                                            rows={_.orderBy(statementHistory.activity, i => {return moment(i.ccaassl_sent_datetime).format("YYYYMMDDHHmmss");}, 'desc')}
                                        />
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                            <Grid item xs={12}>
                                <ExpansionPanel styles={{width:'100%'}}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Credit Limit History</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <DataTable
                                            config={{
                                                key:            'cl_id',
                                                pagination:     false,
                                                plainHeader:    false,
                                                isLoading:      isLoading
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Date',
                                                    field: rowData => `${moment(rowData.ccl_created_datetime).format("DD/MM/YYYY")}`
                                                },
                                                {
                                                    heading: 'Net Limit',
                                                    field: rowData => `£${rowData.ccl_net}`
                                                },
                                                {
                                                    heading: 'Gross Limit',
                                                    field: rowData => `£${rowData.ccl_gross}`
                                                },
                                                {
                                                    heading: 'Set By',
                                                    field: rowData => `${rowData.ccl_created_by_staff_name}`,
                                                    important: true,
                                                },
                                            ]}
                                            rows={_.orderBy(creditLimitHistory, i => {return moment(i.ccl_created_datetime).format("YYYYMMDDHHmmss");}, 'desc')}
                                        />
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                            <DueTable title={'Invoices Due'} data={_.map(address.due, i => _.assign({
                                cn:             0,
                                date:           i.invoice_date,
                                invoice:        [ i.invoice_reference + '-' + i.invoice_reference_unique_number, i?.order?.order_id],
                                order:          i?.order?.order_reference ? [ i?.order?.order_reference + '-' + i?.order?.order_reference_amendment, i?.order?.order_id] : [],
                                number:         `${i.invoice_number} (Sage: ${i.invoice_sage_invoice_number})`,
                                due:            i.due,
                                creditDays:     i.creditDays,
                                paymentMethods: i.paymentMethods,
                                termDays:       i.termDays,
                                completed:      i.invoice_status === 'Completed',
                                total:          i.invoice_total_incl_vat,
                                paid:           i.sum_of_payments,
                                remaining:      i.invoice_total_incl_vat - (i.sum_of_payments || 0),
                                id:             i?.order?.order_id
                            }))} access={access} handleDownloadOpen={this.handleDownloadOpen} handleEmailOpen={this.handleEmailOpen} handleCNDownloadOpen={this.handleCNDownloadOpen} handleCNEmailOpen={this.handleCNEmailOpen} />
                            <DueTable title={'Invoices Overdue'} data={_.map(address.over, i => _.assign({
                                cn:             0,
                                date:           i.invoice_date,
                                invoice:        [ i.invoice_reference + '-' + i.invoice_reference_unique_number, i?.order?.order_id],
                                order:          i?.order?.order_reference ? [ i?.order?.order_reference + '-' + i?.order?.order_reference_amendment, i?.order?.order_id] : [],
                                number:         `${i.invoice_number} (Sage: ${i.invoice_sage_invoice_number})`,
                                due:            i.due,
                                creditDays:     i.creditDays,
                                paymentMethods: i.paymentMethods,
                                termDays:       i.termDays,
                                completed:      i.invoice_status === 'Completed',
                                total:          i.invoice_total_incl_vat,
                                paid:           i.sum_of_payments,
                                remaining:      i.invoice_total_incl_vat - (i.sum_of_payments || 0),
                                id:             i?.order?.order_id
                            }))} access={access} handleDownloadOpen={this.handleDownloadOpen} handleEmailOpen={this.handleEmailOpen} handleCNDownloadOpen={this.handleCNDownloadOpen} handleCNEmailOpen={this.handleCNEmailOpen}/>
                            <DueTable title={'Credit Notes Due'} data={_.map(address.creditNote, i => _.assign({
                                cn:             1,
                                date:           i.invoice.cni_invoice_date,

                                invoice:        [ i.cn_reference + '-0' + i.cn_reference_amendment, i.cn_id],
                                order:          [ i?.order?.order_reference + '-' + i?.order?.order_reference_amendment, i?.order?.order_id],

                                number:         `${i.invoice.cni_invoice_number} (Sage: ${i.invoice.cni_sage_invoice_number})`,
                                due:            i.due,
                                creditDays:     i.creditDays,
                                paymentMethods: i.paymentMethods,
                                termDays:       i.termDays,
                                completed:      i.invoice.cni_status === 'Completed',
                                total:          i.cn_total_incl_vat,
                                paid:           i.invoice.sum_of_payments,
                                remaining:      i.cn_total_incl_vat - (i.invoice.sum_of_payments || 0),
                                id:             i.cn_id
                            }))} access={access} handleDownloadOpen={this.handleDownloadOpen} handleEmailOpen={this.handleEmailOpen} handleCNDownloadOpen={this.handleCNDownloadOpen} handleCNEmailOpen={this.handleCNEmailOpen}/>
                            <DueTable title={'Completed Invoices & Credit Notes'} data={_.map(address.completed, i => _.assign({
                                    cn:             i.cn,
                                    date:           i.cn ? i.invoice.cni_invoice_date : i.invoice_date,
                                    invoice:        i.cn ? [ i.cn_reference + '-0' + i.cn_reference_amendment, i.cn_id] : [ i.invoice_reference + '-' + i.invoice_reference_unique_number, i?.order?.order_id],
                                    order:          i?.order?.order_reference ? [ i?.order?.order_reference + '-' + i?.order?.order_reference_amendment, i?.order?.order_id] : [],
                                    number:         i.cn ? `${i.invoice.cni_invoice_number} (Sage: ${i.invoice.cni_sage_invoice_number})` : `${i.invoice_number} (Sage: ${i.invoice_sage_invoice_number})`,
                                    due:            i.due,
                                    creditDays:     i.creditDays,
                                    paymentMethods: i.paymentMethods,
                                    termDays:       i.termDays,
                                    completed:      i.cn ? i.invoice.cni_status === 'Completed' : i.invoice_status === 'Completed',
                                    total:          i.cn ? 0 - i.cn_total_incl_vat : i.invoice_total_incl_vat,
                                    paid:           i.cn ? 0 - i.invoice.sum_of_payments : i.sum_of_payments,
                                    remaining:      i.cn ? ( (0 - i.cn_total_incl_vat) - (0 - (i.invoice.sum_of_payments || 0) )) : ( i.invoice_total_incl_vat - (i.sum_of_payments || 0) ),
                                    id:             i.cn ? i.cn_id : i?.order?.order_id
                                }))} access={access} handleDownloadOpen={this.handleDownloadOpen} handleEmailOpen={this.handleEmailOpen} handleCNDownloadOpen={this.handleCNDownloadOpen} handleCNEmailOpen={this.handleCNEmailOpen}/>
                            <Grid item xs={12}>
                                <ExpansionPanel styles={{width:'100%'}}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Payment/Refund History</Typography> 
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <DataTable
                                            config={{
                                                key:                   'id',
                                                alternatingRowColours: true,
                                                responsiveimportant:   true,
                                                showTotals:            true,
                                                style:  r => ({color: r.refund && colors.red}),
                                                dropRow: {
                                                    droppable: true,
                                                    style: r => ({fontWeight: 'bold', color: r.cn && colors.red}),
                                                    columns: ([
                                                        {
                                                            field:       'date',
                                                            fieldFormat: 'clenydate'
                                                        },
                                                        {
                                                            field:       'number',
                                                            colSpan:     2,
                                                        },
                                                        {
                                                            colSpan: 2,
                                                            field: r => r.amount - r.remaining,
                                                            fieldFormat: 'currency',
                                                        },
                                                        {
                                                            field:       'paymentType',
                                                        },
                                                        {
                                                            actions: r => ([
                                                                {
                                                                    name: 'View',
                                                                    icon: r.cn ? icons.creditNote : icons.invoice,
                                                                    link: r.cn ? `/returns/creditnote/invoiced/${r.invoiceId}` : `/sales/order/invoice/payments/${r.orderId}`,
                                                                    color: r.cn && colors.red
                                                                }
                                                            ])
                                                        }
                                                    ]),
                                                    data: 'invoices'
                                                },
                                            }}
                                            columns={[
                                                {
                                                    heading:       'Date',
                                                    field:         'date',
                                                    dataRef:       'date',
                                                    fieldFormat:   'clenydate',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading:       'Amount',
                                                    field:         'amount',
                                                    dataRef:       i => parseFloat(i.amount),
                                                    fieldFormat:   'currency',
                                                    alignment:     'right',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading:       'Invoices/Credit Notes',
                                                    field:         i => i.invoices.length,
                                                    dataRef:       i => i.invoices.length,
                                                    alignment:     'center',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading:       'Method',
                                                    field:         r => <AllIcon icon={icons[r.method]} tooltip={r.method} noMargin color={r.refund && colors.red} />,
                                                    dataRef:       'method',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading:     'Reference',
                                                    field:       'reference',
                                                    dataRef:     'reference',
                                                    fieldFormat: 'dashOnBlank'
                                                },
                                                {
                                                    heading:     'Payee/Auth',
                                                    field:       'payee',
                                                    dataRef:     'payee',
                                                    fieldFormat: 'dashOnBlank'
                                                },
                                                {
                                                    actions: r => ([
                                                        {
                                                            name: 'View',
                                                            icon: icons.search,
                                                            onClick: this.handlePaymentOpen
                                                        }
                                                    ])
                                                }
                                            ]}
                                            rows={_.orderBy(address.payments, ['date'], ['desc'])}
                                        />
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                            <DueTable title={'All'} data={_.map(_.concat(address.creditNote,address.completed, address.due, address.over), i => _.assign({
                                    cn:             i.cn,
                                    date:           i.cn ? i.invoice.cni_invoice_date : i.invoice_date,
                                    invoice:        i.cn ? [ i.cn_reference + '-0' + i.cn_reference_amendment, i.cn_id] : [ i.invoice_reference + '-' + i.invoice_reference_unique_number, i?.order?.order_id],
                                    order:          i?.order?.order_reference ? [ i?.order?.order_reference + '-' + i?.order?.order_reference_amendment, i?.order?.order_id] : [],
                                    number:         i.cn ? `${i.invoice.cni_invoice_number} (Sage: ${i.invoice.cni_sage_invoice_number})` : `${i.invoice_number} (Sage: ${i.invoice_sage_invoice_number})`,
                                    due:            i.due,
                                    creditDays:     i.creditDays,
                                    paymentMethods: i.paymentMethods,
                                    termDays:       i.termDays,
                                    completed:      i.cn ? i.invoice.cni_status === 'Completed' : i.invoice_status === 'Completed',
                                    total:          i.cn ? 0 - i.cn_total_incl_vat : i.invoice_total_incl_vat,
                                    paid:           i.cn ? 0 - i.invoice.sum_of_payments : i.sum_of_payments,
                                    remaining:      i.cn ? ( (0 - i.cn_total_incl_vat) - (0 - (i.invoice.sum_of_payments || 0) )) : ( i.invoice_total_incl_vat - (i.sum_of_payments || 0) ),
                                    id:             i.cn ? i.cn_id : i?.order?.order_id
                                }))} access={access} handleDownloadOpen={this.handleDownloadOpen} handleEmailOpen={this.handleEmailOpen} handleCNDownloadOpen={this.handleCNDownloadOpen} handleCNEmailOpen={this.handleCNEmailOpen}/>
                            <Grid container xs={12} lg={12} justifyContent='flex-end'>
                                <Grid item style={{marginLeft:'auto'}}>
                                    {this.props.back && <Button onClick={()=>{this.props.back()}} variant="outlined"  style={{marginTop: '2em'}}>Back</Button>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Dialog
                            open={this.state.showDownloadDialog}
                            maxWidth='xs'
                            fullWidth
                        >
                            <DialogTitle>Download Accounts Statement</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Button onClick={this.downloadExcel()} fullWidth variant='contained' color='primary'>Excel</Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button onClick={this.download} fullWidth variant='contained' color='primary'>PDF</Button>
                                    </Grid>
                                    <Grid item xs={12} className='buttonRow'>
                                        <Button onClick={()=>this.setState({showDownloadDialog: false})} variant='outlined'>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </>
                }
            </>
        )
    }

}

const mapDispatchToProps = dispatch => ({
    closeDialog:    ()                              => {dispatch(closeDialog())},
    deployDialog:   (content, title, variant, size) => {dispatch(deployDialog(content, title, variant, size))},
    deploySnackBar: (variant, body)                 => {dispatch(deploySnackBar(variant, body))}
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(CustomerAccount));