import React                              from 'react';
import moment                             from 'moment';
import PropTypes                          from 'prop-types';
import { CLIENT_VERSION, SHOW_DEV_ALERT } from 'Constants';

import { Link, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    footer:{
        position:"fixed",
        bottom:0,
        height:50,
        backgroundColor: SHOW_DEV_ALERT ? '#FFEE58' : '#F2F2F2',
        width:'100%',        
        boxShadow: '0 -6px 4px -4px rgba(0,0,0,0.075)',
        zIndex: 1
    }
});

class AdminFooter extends React.PureComponent {
    render() {
        const { classes } = this.props;
        return (
            <footer className={`${classes.footer} not-sm`}>
            <Typography style={{textAlign:'right',marginRight:16,marginTop:6,color:'rgba(0,0,0,0.72)'}}>
                MyClenaware v{CLIENT_VERSION} by Clenaware Systems 
            </Typography>
            <Typography style={{textAlign:'right',marginRight:16,fontSize:10,color:'rgba(0,0,0,0.72)'}}>
                {`Copyright `}&copy;{` ${moment().year()} `}
                <Link color="inherit" href="http://www.crushedice.biz" target="_blank" rel="noopener">
                    Crushed Ice
                </Link>
            </Typography>
            </footer>
        );
    }
}

AdminFooter.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminFooter);