import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import SalesReport from 'Components/Reports/SalesReports/SalesReports.js';
import YoYsReports from 'Components/Reports/YoYsReports/YoYsReports.js';

import { setPersistence } from 'Actions/StatePersistence/StatePersistence';
import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistenceFunctions';

class PartSales extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `Parts:${this.props.partId}:PartSales`;
        this.state              = this.getInitialState({
            rows: [],
            open: false,
        });
    }

    componentDidMount(){
        if (this.state.open) this.myRef.scrollIntoView();
    }

    handleOpen = () => {
        this.setState({open: !this.state.open},()=>{this.savePageState()});
    }

    render() {
        const { open } = this.state;
        return (
            <ExpansionPanel defaultExpanded={open} ref={(ref) => this.myRef=ref}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={this.handleOpen} >
                    <Typography>YoY Sales Report</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {/* limit database strain when loading part */}
                    {open &&
                        <box style={{Width:1522}}>
                            <YoYsReports startYear={this.props.startYear} partId={this.props.partId}/>
                        </box>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}


const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    setPersistence: (key, state) => dispatch(setPersistence(key, state)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PartSales);
