import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import NotificationDialogContent from 'Components/Common/Dialogs/NotificationDialogContent';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const NotificationDialog = props => (
    <Dialog
        fullWidth={true}
        maxWidth={props.maxWidth}
        open={props.open}
        TransitionComponent={Transition}
        onClose={props.close}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
    >
        <NotificationDialogContent
            title={props.title}
            message={props.message}
            close={props.close}
        />
    </Dialog>
)

export default NotificationDialog;