import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import API from '../../../API';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SnackBar from '../../Common/SnackBars/SnackBar';
import DatePicker from '../../Common/DatePickers/DatePicker';
import { Divider, TableFooter } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import NotificationDialog from '../../Common/Dialogs/NotificationDialog';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import { Redirect } from 'react-router-dom';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import IconButton from '@material-ui/core/IconButton';
import PartsPurchaseOrder from './../PartsPurchaseOrder';
import DeliverySchedule from './../DeliverySchedule';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import { MAX_UPLOAD_LIMIT_SIZE } from './../../../Constants';
import Textarea from '../../Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import BackButton from 'Components/Common/Buttons/BackButton';
import {colors} from 'Helpers/ColourHelper';

const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    poAlign: {
        marginLeft: '1.01em'
    },
    heading: {
        paddingTop: theme.spacing(3),
    },
    td : {
        border: 'none'
    },
    th : {
        fontWeight: 'bold',
        fontSize: '0.9em'
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        },
        poAlign: {
            marginLeft: 0
        },
        responsiveTable: {
            maxWidth: '100%', 
            overflowX: 'auto'
        }
    }
});

const initialState = {
    formData:  {
        partRows: [{
            dialogOpen: false,
            deliveryType: '',
            part: '',
            quantity: 0,
            unitPrice: '',
            totalPrice: '',
            notes: '',
            nominalCode: '',
            supplierReference: '',
            drawingNo: '',
        }],
        otherRows: [],
        overallPrice: 0,
        notes: ''
    },
    schedule: {
        quantity: '',
        price: '',
        date: null
    },
    ppo: {},
    formErrors: [],
    confirmationOpen: false,
    notificationOpen: true,
    snackbarOpen: false,
    successRedirect: false,
    redirect: false,
    showPPO: false,
    showDS: false,
    isLoading: true
}

class PartsOrderConfirmDelivery extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    
    componentDidMount(){
        this.getPPO();
    }

    getPPO = () => {
        API.get('/partsOrdering/' + this.props.match.params.id)
        .then(result => {
            if(result.data.errors) {
                this.setState({ redirect: true });
            } else if(result.data) {
                // Seperate Part Items & Other Items
                let partRows = [];
                let otherRows = [];
                _.map(result.data.parts_order_details, (el) => {
                    if(el.ppo_detail_part_id > 0){
                        let schedule = [];
                        _.map(el.delivery_schedule, (ds) => {
                            schedule.push({
                                quantity: ds.schedule_quantity,
                                price: ds.schedule_price,
                                date: ds.schedule_delivery_date
                            });
                        });

                        partRows.push({
                            detailId: el.ppo_detail_id,
                            part: el.ppo_detail_part_number + ' - ' + el.ppo_detail_description,
                            quantity: el.ppo_detail_qty,
                            unitPrice: el.ppo_detail_unit_price,
                            totalPrice: el.ppo_detail_total_price,
                            nominalCode: el.nominal_code && `${el.nominal_code.nominal_code} - ${el.ppo_detail_nominal_name} (${el.nominal_code.category.nom_cat_name})`,
                            supplierReference: el.ppo_detail_supplier_reference,
                            drawingNo: el.ppo_detail_drawing_no,
                            notes: el.ppo_detail_notes,
                            deliveryType: el.delivery_schedule[0].schedule_delivery_type,
                            dialogOpen: false,
                            schedule: schedule,
                            originalSchedule: schedule,
                            originalDeliveryType: el.delivery_schedule[0].schedule_delivery_type,
                            scheduleButtonColor: '#43a047',
                            scheduleValiditity: 'valid'
                        });
                    }
                    else {
                        otherRows.push({
                            description: el.ppo_detail_description,
                            nominalCode: el.nominal_code && `${el.nominal_code.nominal_code} - ${el.ppo_detail_nominal_name} (${el.nominal_code.category.nom_cat_name})`,
                            quantity: el.ppo_detail_qty,
                            unitPrice: el.ppo_detail_unit_price,
                            totalPrice: el.ppo_detail_total_price
                        });
                    }
                });
                this.setState({
                    ppo: result.data,
                    formData: {
                        ...this.state.formData,
                        partRows: partRows,
                        otherRows: otherRows,
                        overallPrice: result.data.ppo_total_price
                    },
                    isLoading: false
                });
            }
        });
    }

    handleRowChange = (idx, array, decimals) => e => {
        const { name, value, checked } = e.target;
        let newQty =  [...this.state.formData[array]];
        let newVal = (name === 'attachPO') ? checked : decimals ? parseFloat(value).toFixed(decimals) : value;

        newQty[idx] = {
            ...newQty[idx],
            [name]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                [array]: newQty 
            }
        },
        () => {
            this.setSchedule(idx);
        });
    };

    handleRowDateChange = (idx, dsIdx) => date => {
        let partRows = [...this.state.formData.partRows];
        let schedule = [...this.state.formData.partRows[idx].schedule];

        schedule[dsIdx] = {
            ...schedule[dsIdx],
            date: moment(date).format('YYYY-MM-DD')
        };

        partRows[idx] = {
            ...partRows[idx],
            schedule: schedule
        };
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: partRows
            }
        },
        () => {
            this.validateSchedule(idx);
        });
    };

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleScheduleOpen = (idx) => (e) => {
        if(this.state.formData.partRows[idx].part && this.state.formData.partRows[idx].quantity){
            let data =  [...this.state.formData.partRows];
            data[idx] = {
                ...data[idx],
                dialogOpen: true
            };
            this.setState({
                formData: {
                    ...this.state.formData,
                    partRows: data
                }
            });
        }
        else {
            alert('Please select a part');
        }
    };
    handleScheduleClose = (idx) => (e) => {
        let data =  [...this.state.formData.partRows];
        data[idx] = {
            ...data[idx],
            dialogOpen: false
        };
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: data
            }
        });
    };
    handleScheduleSave = (idx) => (e) => {
        let data =  [...this.state.formData.partRows];
        data[idx] = {
            ...data[idx],
            dialogOpen: false
        };
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: data
            }
        },
        () => {
            this.validateSchedule(idx);
        });
    };
    handleScheduleRevert = (idx) => (e) => {
        let partRows = [...this.state.formData.partRows];
        partRows[idx] = {
            ...partRows[idx],
            schedule: partRows[idx].originalSchedule,
            deliveryType: partRows[idx].originalDeliveryType
        };
        this.setState({
            formData:{
                ...this.state.formData,
                partRows: partRows
            }
        });
    }

    setSchedule = (idx) => {
        let partRows = [...this.state.formData.partRows];
        let newSchedule = [];
        switch(partRows[idx].deliveryType){
            case 'Delivery Date':
                newSchedule.push({
                    quantity: partRows[idx].quantity,
                    price: partRows[idx].totalPrice,
                    date: null
                });
            break;
            case 'Schedule':
                newSchedule.push({
                    quantity: partRows[idx].quantity,
                    price: partRows[idx].totalPrice,
                    date: null
                });
            break;
            case 'Supplier to Confirm':
                newSchedule.push({
                    quantity: partRows[idx].quantity,
                    price: partRows[idx].totalPrice,
                    date: '0000-00-00'
                });
            break;
            default:
            break;
        }
        partRows[idx] = {
            ...partRows[idx],
            schedule: newSchedule
        };
        this.setState({
            formData:{
                ...this.state.formData,
                partRows: partRows
            }
        },
        () => {
            this.validateSchedule(idx);
        });
    }

    validateSchedule = (idx) => {
        let schedule = [...this.state.formData.partRows[idx].schedule];
        let partRows =  [...this.state.formData.partRows];

        let isValid = true;
        schedule.forEach((ds) => {
            if(!ds.date || (ds.quantity % 1 !== 0)){
                isValid = false;
            }
        });
        partRows[idx] = {
            ...partRows[idx],
            scheduleButtonColor: (isValid) ? '#43a047' : '',
            scheduleValiditity : (isValid) ? 'valid' : 'invalid',
        };
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: partRows
            }
        });
    }

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false
        });
        this.submit();
    }
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };
    handleNotificationClose = () => {
        this.setState({ 
            notificationOpen: false 
        });
    };

    submit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            if(key === 'partRows'){
                newFormData.append(key, JSON.stringify(this.state.formData[key]))
            }
            else{
                newFormData.append(key, this.state.formData[key])
            }
        });

        API.post(`/partsOrdering/${this.props.match.params.id}/confirmDelivery`, newFormData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    successRedirect: true
                });
            }
            this.props.scrollToTop();
        });
    }

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    }

    handleAddRow = (idx) => (e) => {
        
        // Add new row
        const item = initialState.schedule;
        let abc =  [...this.state.formData.partRows];
        abc[idx] = {
            ...abc[idx],
            schedule: [...this.state.formData.partRows[idx].schedule, item]
        };
        // Get new quantity & price
        const quantity = (abc[idx].quantity / abc[idx].schedule.length).toFixed(3);
        const price = (abc[idx].totalPrice / abc[idx].schedule.length).toFixed(2);
       // Update new schedule
        let newSchedule = [];
        abc[idx].schedule.forEach((ds) => {
            newSchedule.push({
                quantity: quantity,
                price: price,
                date: ds.date
            });
        });
        abc[idx] = {
            ...abc[idx],
            schedule: newSchedule
        };
        this.setState({
            formData:{
                ...this.state.formData,
                partRows: abc
            }
        },
        () => {
            this.validateSchedule(idx);
        });
    }
    handleRemoveRow = (idx, dsIdx) => () => {
        let schedule = [...this.state.formData.partRows[idx].schedule];
        let partRows = [...this.state.formData.partRows];
        // Remove row
        schedule.splice(dsIdx, 1);
        // Get new quantity & price
        const quantity = (this.state.formData.partRows[idx].quantity / schedule.length).toFixed(3);
        const price = (this.state.formData.partRows[idx].totalPrice / schedule.length).toFixed(2);
        // Update new schedule
        let newSchedule = [];
        schedule.forEach((ds) => {
            newSchedule.push({
                quantity: quantity,
                price: price,
                date: ds.date
            });
        });
        partRows[idx] = {
            ...partRows[idx],
            schedule: newSchedule
        };
        this.setState({
            formData:{
                ...this.state.formData,
                partRows: partRows
            }
        },
        () => {
            this.validateSchedule(idx);
        });
    }

    downloadPPO = (ppoId) => {
        API.get('/partsOrdering/' + ppoId + '/pdfPartsOrder')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }
    downloadDS = (ppoId) => {
        API.get('/partsOrdering/' + ppoId + '/pdfDeliverySchedule')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }
    viewPPO = () => {
        this.setState(prevState => ({
            showPPO: !prevState.showPPO,
            showDS: false
        }));
    }
    viewDS = () => {
        this.setState(prevState => ({
            showDS: !prevState.showDS,
            showPPO: false
        }));
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    clearFile = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                confirmationFile: null
            }
        });
    }
       
    render() {
        if (this.state.successRedirect === true) {
            return <Redirect 
                        to={{
                            pathname:`/parts-order/confirmation`,
                            state: {successMsg: 'You have successfully confirmed this parts order'}
                        }}
                    />
        }
        if (this.state.redirect === true) {
            return <Redirect to="/parts-order/search" />
        }
        const { classes } = this.props;
        const { formErrors, ppo, formData } = this.state;
        return (
            <React.Fragment>
                {this.state.isLoading === true ? (
                    <LoadingCircle />
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Confirm Delivery Schedule
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                <Typography variant="h4" align="center" className={classes.heading} style={{marginBottom: 25}}>
                                    {ppo.full_reference}
                                </Typography>
                                <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                    Purchase Order (v{parseInt(ppo.ppo_number_amendment)})<br className='xs-only' />
                                    <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={this.viewPPO}
                                            style={{marginLeft:10}}
                                    >                                    
                                        <FALightIcon icon='search' />
                                        View
                                    </Button>
                                    <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={() => this.downloadPPO(ppo.ppo_id)}
                                    >
                                        <FALightIcon icon='download' />
                                        Download
                                    </Button> 
                                </Typography>
                                <br className='xs-only' />
                                <Typography variant="body1" align="center" gutterBottom style={{marginBottom:40}}>
                                    Delivery Schedule (v{parseInt(ppo.ppo_schedule_version)})<br className='xs-only' />
                                    <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            onClick={this.viewDS}
                                            className={classes.btn}
                                            style={{marginLeft:10}}
                                    >
                                        <FALightIcon icon='search' />
                                        View
                                    </Button>
                                    <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={() => this.downloadDS(ppo.ppo_id, parseInt(ppo.ppo_schedule_version))}
                                    >                                            
                                        <FALightIcon icon='download' />
                                        Download
                                    </Button> 
                                </Typography>
        
                                    { this.state.showPPO ? <PartsPurchaseOrder ppo={ppo} /> : null }
                                    { this.state.showDS ? <DeliverySchedule ppo={ppo} /> : null }
        
                            </PaddedPaper>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h6" gutterBottom>
                                    Part Items
                                </Typography>
                                <div className={classes.responsiveTable}>
                                    <Table style={{minWidth: 900}}>
                                        <TableBody>
                                            {formData.partRows && formData.partRows.map((item, idx) => {
                                                return (
                                                    <React.Fragment key={`pr${idx}`}>
                                                        <TableRow>
                                                            <TableCell className={classes.td} style={{width:'30%'}}>
                                                                <TextField
                                                                    name="part"
                                                                    value={item.part}
                                                                    label="Part"
                                                                    fullWidth
                                                                    disabled
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.td}>
                                                                <TextField
                                                                    name="quantity"
                                                                    value={item.quantity}
                                                                    label="Quantity"
                                                                    type="number"
                                                                    fullWidth
                                                                    disabled
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.td}>
                                                                <TextField
                                                                    name="unitPrice"
                                                                    value={item.unitPrice}
                                                                    label="Unit Price"
                                                                    type="number"
                                                                    fullWidth
                                                                    disabled
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.td}>
                                                                <TextField
                                                                    name="totalPrice"
                                                                    value={item.totalPrice}
                                                                    label="Total Price"
                                                                    type="number"
                                                                    disabled
                                                                    fullWidth
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.td} rowSpan={2} align="right">
                                                                <Button variant="contained" size="small" color="secondary" style={{backgroundColor:item.scheduleButtonColor}} onClick={this.handleScheduleOpen(idx)}>
                                                                    <FALightIcon icon='calendar-alt' buttonPrimary /> Schedule
                                                                </Button>
                                                                {formErrors && formErrors['partRows|deliverySchedule|'+idx] &&
                                                                    <Typography component={"div"} style={{color: colors.red}}>
                                                                        {formErrors && formErrors['partRows|deliverySchedule|'+idx]}
                                                                    </Typography>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow key={idx}>
                                                            <TableCell className={classes.td} style={{width:'30%'}}>
                                                                <TextField
                                                                    name="nominalCode"
                                                                    value={item.nominalCode !== null && item.nominalCode}
                                                                    label="Nominal Code"
                                                                    fullWidth
                                                                    disabled
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.td}>
                                                                <TextField
                                                                    name="supplierReference"
                                                                    value={item.supplierReference}
                                                                    label="Supplier Reference"
                                                                    fullWidth
                                                                    disabled
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.td}>
                                                                <TextField
                                                                    name="drawingNo"
                                                                    label="Drawing No."
                                                                    value={item.drawingNo}
                                                                    fullWidth
                                                                    disabled
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.td}>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.td} colSpan={6}>
                                                                <Divider style={{border:'5px solid #f2f2f2'}} />
                                                            </TableCell>
                                                        </TableRow>
        
                                                        <Dialog 
                                                            open={item.dialogOpen} 
                                                            onClose={this.handleScheduleClose(idx)} 
                                                            fullWidth={true} 
                                                            maxWidth="md"
                                                            disableBackdropClick
                                                            disableEscapeKeyDown
                                                        >
                                                            <DialogTitle id="form-dialog-title">
                                                            Delivery Schedule
                                                            </DialogTitle>
                                                            <DialogContent>
                                                                {formErrors && formErrors['stockReturnDetails'] && formErrors['stockReturnDetails'].split('\n').map((item, key) => {
                                                                    return (
                                                                        <React.Fragment key={key}>
                                                                            <Typography component={"div"} style={{color: colors.red}}>
                                                                                {item}<br/>
                                                                            </Typography>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                                <RadioGroup
                                                                    name="deliveryType"
                                                                    value={item.deliveryType}
                                                                    onChange={this.handleRowChange(idx, 'partRows')}
                                                                    row
                                                                >
                                                                    <FormControlLabel
                                                                        value="Delivery Date"
                                                                        control={<Radio color="primary" />}
                                                                        label="Delivery Date"
                                                                        labelPlacement="end"
                                                                    />
                                                                    <FormControlLabel
                                                                        value="Schedule"
                                                                        control={<Radio color="primary" />}
                                                                        label="Schedule"
                                                                        labelPlacement="end"
                                                                    />
                                                                    
                                                                    <FormControlLabel
                                                                        value="Supplier to Confirm"
                                                                        control={<Radio color="primary" />}
                                                                        label="Supplier to Confirm"
                                                                        labelPlacement="end"
                                                                    />
                                                                </RadioGroup>
                                                                <br></br>
                                                                <small>To ensure a delivery schedule is valid please fill out all dates and ensure the quantity is a whole number</small>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell className={classes.th}>Quantity</TableCell>
                                                                            <TableCell className={classes.th}>Price</TableCell>
                                                                            <TableCell className={classes.th}>Delivery Date</TableCell>
                                                                            <TableCell className={classes.th}></TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                    {item.deliveryType === 'Delivery Date' &&
                                                                        <TableRow>
                                                                            <TableCell className={classes.td}>
                                                                                <TextField
                                                                                    value={item.schedule[0].quantity}
                                                                                    type="number"
                                                                                    fullWidth
                                                                                    disabled
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell className={classes.td}>
                                                                                <TextField
                                                                                    value={item.schedule[0].price}
                                                                                    type="number"
                                                                                    disabled
                                                                                    fullWidth
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell className={classes.td} colSpan={2}>
                                                                                <DatePicker
                                                                                    type="date"
                                                                                    id="deliveryDate"
                                                                                    name="deliveryDate"
                                                                                    value={item.schedule[0].date}
                                                                                    errorText={formErrors && formErrors['partRows|deliveryDate|'+idx]}
                                                                                    onChange={this.handleRowDateChange(idx, 0)}
                                                                                    autoOk={true}
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    }
                                                                    {item.deliveryType === 'Schedule' && item.schedule.map((ds, dsIdx) => {
                                                                        return (
                                                                            <TableRow key={dsIdx}>
                                                                                <TableCell className={classes.td}>
                                                                                    <TextField
                                                                                        value={ds.quantity}
                                                                                        type="number"
                                                                                        fullWidth
                                                                                        disabled
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell className={classes.td}>
                                                                                    <TextField
                                                                                        value={ds.price}
                                                                                        type="number"
                                                                                        disabled
                                                                                        fullWidth
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell className={classes.td}>
                                                                                    <DatePicker
                                                                                        type="date"
                                                                                        id="deliveryDate"
                                                                                        name="deliveryDate"
                                                                                        value={ds.date}
                                                                                        errorText={formErrors && formErrors['partRows|deliveryDate|'+idx]}
                                                                                        onChange={this.handleRowDateChange(idx, dsIdx)}
                                                                                        autoOk={true}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell className={classes.td}>
                                                                                    {dsIdx > 0 &&
                                                                                        <IconButton onClick={this.handleRemoveRow(idx, dsIdx)}>
                                                                                            <FALightIcon icon='times' noMargin style={{color:colors.red}} />
                                                                                        </IconButton>
                                                                                    }
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                                    {item.deliveryType === 'Supplier to Confirm' &&
                                                                        <TableRow>
                                                                            <TableCell className={classes.td}>
                                                                                <TextField
                                                                                    value={item.schedule[0].quantity}
                                                                                    type="number"
                                                                                    fullWidth
                                                                                    disabled
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell className={classes.td}>
                                                                                <TextField
                                                                                    value={item.schedule[0].price}
                                                                                    type="number"
                                                                                    disabled
                                                                                    fullWidth
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell className={classes.td} colSpan={2}>
                                                                                <TextField
                                                                                    value="TBC"
                                                                                    margin="normal"
                                                                                    fullWidth
                                                                                    disabled
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                        <InputAdornment  position="end">
                                                                                            <FALightIcon icon='calendar-alt' noMargin />
                                                                                        </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    }
                                                                    </TableBody>
                                                                    <TableFooter>
                                                                        <TableRow>
                                                                            <TableCell className={classes.th}>
                                                                                {item.quantity}
                                                                            </TableCell>
                                                                            <TableCell className={classes.th}>
                                                                                {item.totalPrice}
                                                                            </TableCell>
                                                                            <TableCell className={classes.th}></TableCell>
                                                                            {item.deliveryType === 'Schedule' &&
                                                                            <TableCell>
                                                                                <IconButton onClick={this.handleAddRow(idx)}>
                                                                                    <FALightIcon icon='plus' style={{color:colors.green}} noMargin />
                                                                                </IconButton>
                                                                            </TableCell>
                                                                            }
                                                                        </TableRow>
                                                                    </TableFooter>
                                                                </Table>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                {item.scheduleButtonColor === '' &&
                                                                    <Typography variant="subtitle2" style={{color: colors.red}}>You have not completed the delivery schedule or it is invalid</Typography>
                                                                }
                                                                <Button onClick={this.handleScheduleClose(idx)} variant="outlined" color="default">Close</Button>
                                                                <Button onClick={this.handleScheduleRevert(idx)} variant="outlined" color="default">Revert</Button>
                                                                <Button onClick={this.handleScheduleSave(idx)} style={{backgroundColor:'#5da14d', color:'white'}} autoFocus variant="contained">Save & Close</Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
        
                                <Typography variant="h6" gutterBottom style={{marginTop: 20}}>
                                    Other Items
                                </Typography>
        
                                <div styles={{maxWidth: '100%', overflowX: 'auto'}}>
                                    <Table style={{minWidth: 900}}>
                                        <TableBody>
                                            {formData.otherRows && formData.otherRows.map((item, idx) => {
                                                return (
                                                    <React.Fragment key={`or_${idx}`}>
                                                        <TableRow>
                                                            <TableCell className={classes.td} style={{width:'30%'}}>
                                                                <TextField
                                                                    name="description"
                                                                    value={item.description}
                                                                    label="Description"
                                                                    fullWidth
                                                                    disabled
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.td} style={{width:'30%'}}>
                                                                <TextField
                                                                    name="nominalCode"
                                                                    value={item.nominalCode ? item.nominalCode : ''}
                                                                    label="Nominal Code"
                                                                    fullWidth
                                                                    disabled
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.td}>
                                                                <TextField
                                                                    name="quantity"
                                                                    value={item.quantity}
                                                                    label="Quantity"
                                                                    type="number"
                                                                    fullWidth
                                                                    disabled
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.td}>
                                                                <TextField
                                                                    name="unitPrice"
                                                                    value={item.unitPrice}
                                                                    label="Unit Price"
                                                                    type="number"
                                                                    fullWidth
                                                                    disabled
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.td}>
                                                                <TextField
                                                                    name="totalPrice"
                                                                    value={item.totalPrice}
                                                                    label="Total Price"
                                                                    type="number"
                                                                    disabled
                                                                    fullWidth
                                                                />
                                                            </TableCell>
                                                            
                                                            <TableCell className={classes.td}>
                                                                
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                {formErrors && formErrors['otherRows'] && formErrors['otherRows'].split('\n').map((item, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <Typography component={"div"} style={{color: colors.red}}>
                                                {item}<br/>
                                            </Typography>
                                        </React.Fragment>
                                    )
                                })}
                                <Typography variant="h6" style={{display: 'flex', justifyContent:'flex-end', fontWeight: 'bold' }}>
                                    Total Price: £{ppo.ppo_total_price}
                                </Typography>
                            </PaddedPaper>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <form noValidate autoComplete="off">
                                        <DragFileInput
                                            id="confirmationFile"
                                            name="confirmationFile"
                                            label="Confirmation"
                                            file={this.state.formData.confirmationFile}
                                            errorText={formErrors && formErrors['confirmationFile']}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={this.clearFile}
                                            emptyText='No file selected'
                                        />
                                        <Textarea
                                            id="notes"
                                            name="notes"
                                            label="Notes"
                                            value={this.state.formData.notes}
                                            rows={10}
                                            error={formErrors && formErrors['notes']}
                                            onChange={this.handleChange}
                                        />
                                        {formErrors && formErrors.schedule && (
                                            <Typography style={{color: colors.red}}>
                                                {formErrors.schedule}
                                            </Typography>
                                        )}
                                        <div className='buttonRow' style={{justifyContent: 'space-between', flexDirection: 'row', alignItems:'center'}}>
                                            <Typography variant="subtitle2" style={{flex: 1}}>
                                                <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                                            </Typography>
                                            
                                            <BackButton props={this.props} />
                                            <Button onClick={this.handleConfirmationOpen}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                Confirm
                                            </Button>
                                        </div>
                                    </form>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen} 
                            success={this.handleConfirmationSuccess} 
                            close={this.handleConfirmationClose} 
                            title="Confirm This Delivery Schedule?" 
                            message="Are you sure you want to confirm this delivery schedule?"
                        />
                        <NotificationDialog 
                            maxWidth="sm"
                            open={this.state.notificationOpen} 
                            close={this.handleNotificationClose} 
                            title="Parts Order Confirmation" 
                            message= {<React.Fragment>
                                        Before confirming the parts order, you have the opportunity to amend the delivery schedule.<br></br><br></br>
                                        Click on the schedule button <Button variant="contained" size="small" color="secondary" style={{backgroundColor:colors.green}}><FALightIcon icon='calendar-alt' buttonPrimary /> Schedule</Button> next to the part you need to amend.<br></br><br></br>
                                        If no amendments are needed, you can enter any confirmation notes you wish to add and click Confirm
                                        </React.Fragment>
                                        }
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen}
                            onClose={this.handleSnackbarClose}
                            message='You have successfully confirmed this delivery schedule'
                        />
                    </Grid>
                )}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(PartsOrderConfirmDelivery);
