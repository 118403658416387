import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Grid, TableCell, TableHead, TableRow, Typography, Table, TableBody, TableFooter } from '@material-ui/core';
import logo                 from 'Assets/Images/clenaware_logo_icon.png';
import {colors}             from 'Helpers/ColourHelper';
import { withStyles }       from '@material-ui/core/styles';

class ViewReturn extends Component {
    
    trimPartDescription = partDescription => {
        if(partDescription) {
            if(partDescription.length > 40){
                partDescription = partDescription.substring(0, 25) + '...';
            }
        } else {
            partDescription = '';
        }
        return partDescription;
    }

    render() {

        const { cr, classes } = this.props;

        const hasRestock =  true;

        return (
            <Grid container spacing={3}>
                <Grid item xs={5}>
                    <Grid container item spacing={1}>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Return Date</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {moment(cr.cr_date).format("DD/MM/YYYY")}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Created By</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {cr.cr_staff_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Return For</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {(cr.cr_outcome || cr.cr_expected_outcome) === "Refund" || (cr.cr_outcome || cr.cr_expected_outcome) === 'refund' ? "Credit Note" : (cr.cr_outcome || cr.cr_expected_outcome)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5}>
                    <Grid container item spacing={1}>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Reference</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {cr.cr_reference+'-'+(cr.cr_reference_amendment < 10 ? '0'+cr.cr_reference_amendment : cr.cr_reference_amendment)}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Order</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {cr.order.order_reference+'-'+cr.order.order_reference_amendment}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={5}>
                    <Grid container item spacing={1}>
                        <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                            Customer
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Name</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {cr.cr_cust_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Address</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {cr.cr_address.split('\n').map((item, key) => {
                                    return  <span key={key}>
                                                {item}{cr.cr_address.split('\n').length === key+1 ? null : <br/>}
                                            </span>
                                })}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Contact</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {cr.cr_contact_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Phone Number</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {cr.cr_contact_phone} {cr.cr_contact_phone && cr.cr_contact_mobile ? ' / ' : null} {cr.cr_contact_mobile}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Email</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {cr.cr_contact_email	}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5}>
                    <Grid container item spacing={1}>
                        <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                            Collection
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Name</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {cr.cr_collection_address_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Address</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {cr.cr_collection_address.split('\n').map((item, key) => {
                                    return  <span key={key}>
                                                {item}{cr.cr_collection_address.split('\n').length === key+1 ? null : <br/>}
                                            </span>
                                })}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Contact</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {cr.cr_collection_address_contact_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Phone Number</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {cr.cr_collection_address_contact_phone} {cr.cr_collection_address_contact_phone && cr.cr_collection_address_contact_mobile ? ' / ' : null} {cr.cr_collection_address_contact_mobile}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Email</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {cr.cr_collection_address_contact_email}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tblHeader}>
                                <TableCell className={classes.tblHeaderCell}></TableCell>
                                <TableCell className={classes.tblHeaderCell}>Part Number</TableCell>
                                <TableCell className={classes.tblHeaderCell} style={{minWidth: '180px'}}>Part Description</TableCell>
                                <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Quantity</TableCell>
                                <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Unit Price</TableCell>
                                {!!cr.cr_has_discount &&
                                    <React.Fragment>
                                        <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Discount Rate</TableCell>
                                        <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>New Unit Price</TableCell>
                                        <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Discount Total</TableCell>
                                    </React.Fragment>
                                }
                                {hasRestock &&[
                                    <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Order Price</TableCell>,
                                    <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Restocking Fee</TableCell>]
                                }
                                <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Total Credit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(cr.details, (rowData,key) =>
                                <TableRow style={{borderBottom: '1px solid rgb(224, 224, 224)'}} key={key}>
                                    <TableCell style={{height:65, borderBottom: 'none', verticalAlign: 'middle'}} align='center'>
                                        <img src={(rowData.part && rowData.part.default_image && rowData.part.default_image.library_thumbnail_url) ? rowData.part.default_image.thumbnail_file_path : logo} style={{overflow:'visible', maxHeight: 45, maxWidth: 45}} alt="" />
                                    </TableCell>
                                    <TableCell style={{whiteSpace:'nowrap', color: colors.red}}>{rowData.part.part_number}</TableCell>
                                    <TableCell style={{whiteSpace:'nowrap', minWidth: '180px'}}>
                                        <>
                                            <span style={{color: colors.red}}>{this.trimPartDescription(rowData.part.part_description)}</span><br/>
                                            {_.map(JSON.parse(rowData.crd_serial_numbers),       (i,idx) => { return <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>{i}</Typography>})}
                                            {_.map(JSON.parse(rowData.crd_warrenty_void_labels), (i,idx) => { return <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>{i}</Typography>})}
                                            {!!rowData.crd_notes && 
                                                <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>
                                                    {rowData.crd_notes}
                                                </Typography>
                                            }
                                        </>
                                    </TableCell>
                                    <TableCell style={{textAlign:'right'}}><span style={{color: colors.red}}>{rowData.crd_qty}</span></TableCell>
                                    <TableCell style={{textAlign:'right'}}><span style={{color: colors.red}}>£{parseFloat(rowData.crd_unit_price).toFixed(2)}</span></TableCell>
                                    {!!cr.cr_has_discount &&
                                        <React.Fragment>
                                            <TableCell style={{textAlign:'right'}}><span style={{color: colors.red}}>{`${parseFloat(rowData.crd_discount).toFixed(2)}%`}</span></TableCell>
                                            <TableCell style={{textAlign:'right'}}><span style={{color: colors.red}}>{`£${parseFloat(rowData.crd_new_unit_price).toFixed(2)}`}</span></TableCell>
                                            <TableCell style={{textAlign:'right'}}><span style={{color: colors.red}}>{`£${parseFloat(rowData.crd_discount_total).toFixed(2)}`}</span></TableCell>
                                        </React.Fragment>
                                    }
                                    {hasRestock &&
                                        <>
                                            <TableCell style={{textAlign:'right'}}><span style={{color: colors.red}}>{`£${(parseFloat(rowData.crd_new_unit_price).toFixed(2) * parseFloat(rowData.crd_qty)).toFixed(2)}`}</span></TableCell>
                                            <TableCell style={{textAlign:'right'}}><span style={{color:colors.red}}>{`£${parseFloat(rowData.crd_restock_fee).toFixed(2)}`}</span></TableCell>
                                        </>
                                    }
                                    <TableCell style={{textAlign:'right', width:'10%'}}><span style={{color:colors.red}}>{`-£${parseFloat(rowData.crd_total_with_restock).toFixed(2)}`}</span></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={ (hasRestock ? 8 : 6) - (!!cr.cr_has_discount ? 0 : 3)}></TableCell>
                                <TableCell style={{textAlign:'right', paddingRight: '8px', color: colors.red}} ><strong>Sub Total</strong></TableCell>
                                <TableCell colSpan={2} style={{textAlign:'right', color: colors.red}}><strong>-£{parseFloat(_.sumBy(cr.details, i => parseFloat(i.crd_total_with_restock))).toFixed(2)}</strong></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={ (hasRestock ? 8 : 6) - (!!cr.cr_has_discount ? 0 : 3)}></TableCell>
                                <TableCell style={{textAlign:'right', paddingRight: '8px', color: colors.red}} ><strong>VAT ({parseFloat(cr.cr_vat_rate * 100).toFixed(2)}%)</strong></TableCell>
                                <TableCell  colSpan={2} style={{textAlign:'right', color: colors.red}}><strong>-£{parseFloat(_.sumBy(cr.details, i => parseFloat(i.crd_total_with_restock)) * parseFloat(cr.cr_vat_rate)).toFixed(2)}</strong></TableCell>
                            </TableRow>
                            <TableRow className={classes.tblFooter}>
                                <TableCell colSpan={ (hasRestock ? 8 : 6) - (!!cr.cr_has_discount ? 0 : 3)}></TableCell>
                                <TableCell style={{textAlign:'right', paddingRight: '8px', color: colors.red}} ><strong>Total</strong></TableCell>
                                <TableCell colSpan={2} style={{textAlign:'right', color: colors.red}}><strong>-£{parseFloat(_.sumBy(cr.details, i => parseFloat(i.crd_total_with_restock)) * parseFloat( 1 + parseFloat(cr.cr_vat_rate) )).toFixed(2)}</strong></TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </Grid>
                <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                    Customer Return Notes
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom style={{whiteSpace: 'pre-wrap'}}>
                        {cr.cr_notes ? cr.cr_notes : 'No Notes'}
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}

const styles = theme => ({
    tblHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    tblHeaderCell: {
        color:'white',
    },
    tblFooter: {
        backgroundColor: '#f2f2f2',
    }
});
export default (withStyles(styles)(ViewReturn));