import React, { Component } from 'react';
import API                  from 'API';
import { isInteger }        from 'lodash';
import { connect }          from 'react-redux';

import Image              from 'Components/Common/ImageWithError/ImageWithError';
import NumberField        from 'Components/Common/Inputs/NumberField';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import QrScanWrapper      from 'Components/Warehouse/Common/Inputs/QrScanWrapper';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';

import { colors } from 'Helpers/ColourHelper';

import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { playSound }      from 'Actions/Sounds/Sounds';

import { Button, Grid, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

const initialState = {
    part:       null,
    showError:  false,
    formData: {
        part:   null,
        qty:    null,
        id:     null,
    },
    confirmationDialog: {
        open:    false,
        title:   '',
        message: '',
        success: null
    },
    details: [],
    stage: 0,
    isLoading: true,
    qrError: false,
    showQty: false,
}

class ScanDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.setState({
            details: this.props.details,
       }, this.getPickData);
    }

    getPickData = () => {
        this.setState({isLoading: true},
        ()=>{
            this.setState({
                formData: {
                    ...initialState.formData,
                    part:   this.state.details[this.state.stage].part.part_id,
                    id:     this.state.details[this.state.stage].std_id,
                },
                part: this.state.details[this.state.stage].part,
                isLoading:  false
            })
        })
    }

    handleScan = (scannedPartNumber) => {
        if (scannedPartNumber) {
            if (scannedPartNumber === this.state.part.part_number){
                this.props.playSound('pickSuccess', 1)
                this.setState({
                    showQty: true,
                    qrError: false,
                })
            } else {
                this.props.playSound('pickFailed', 1);
                this.setState({qrError: 'Part not found'});
            }
        }
    }

    handleClose = () => {
        this.props.close();
    }

    _handleKeyDown = (e) => {
        let { formData, confirmationDialog, showQty } = this.state;
        if (e.keyCode === 0) return;
        e.preventDefault();
        switch(e.key){
            case 'F1':
                if (confirmationDialog.open) this.handleConfirmationClose();
            break;
            case 'F2':
                if (confirmationDialog.open){
                    this.submit();
                } else {
                    this.handleSkip();
                }
            break;
            case 'Enter':
                if (!(formData.qty === null)){
                    this.deployConfirmation('Are you sure you want to submit this quantity?', 'Submit Stock', this.submit);
                }
            break;
            default:
                if (showQty) {
                    if (formData.qty === null) formData.qty = '';
                    if (e.key === 'Backspace'){
                        formData.qty = formData.qty.toString().slice(0, -1);
                    } else if ( e.key === '.' ) {
                        formData.qty = formData.qty.toString() + '.';
                    } else if (isInteger(parseInt(e.key))){
                        formData.qty = parseFloat(formData.qty.toString() + e.key.toString());
                    } 
                    this.setState({formData});
                }
            break;
        } 
    }

    handleChange = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    deployConfirmation = (message, title, success) => {
        this.setState({
            confirmationDialog: {
                open: true,
                message: message,
                title: title,
                success: success
            }
        })
    }

    handleConfirmationClose = () => {
        this.setState({
            confirmationDialog: {
                ...this.state.confirmationDialog,
                open: false
            }
        })
    }

    submit = () => {
        this.handleConfirmationClose();
        let data = {
            details: [this.state.formData],
            status:  'QR',
        }
        API.post(`/stock/take/${this.props.take.st_id}/save`, data)
        .then( res => {
            this.setState({
                isLoading: true,
                formData: initialState.formData,
                part:     initialState.part,
            }, this.nextStage)
        })
    }

    nextStage = () => {
        let stage = this.state.stage + 1;
        if (stage === this.state.details.length) {
            this.props.close();
        } else {
            this.setState({stage}, this.getPickData);
        }
    }

    handleSkip = () => {
        this.nextStage();
    }

    handleChange = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    render() {

        const { isLoading, formData, part, showQty, confirmationDialog, qrError } = this.state;

        const { classes } = this.props;

        if (isLoading) return (<LoadingCircle />);

        return(
            <Grid container justify="center" className={`${classes.root} text-center`} spacing={1}>
                <Grid item xs={12} >
                    <Grid container justify="center" className="text-center" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{textAlign: 'center'}}>
                                {part.part_number}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{textAlign: 'center'}}>
                                {part.part_description}<br></br>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                            <Image src={part.default_image?.file_path} className={classes.img} />
                        </Grid>
                        {qrError && 
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{textAlign: 'center', color: colors.red}}>
                                    {qrError}
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                {showQty &&
                    <>
                        <Grid item xs={12}>
                            <NumberField
                                fullWidth
                                label="Quantity *"
                                value={formData.qty}
                                onChange={(e)=>{this.handleChange('qty', e)}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => this.deployConfirmation('Are you sure you want to submit this quantity?', 'Submit Stock', this.submit)}
                                disabled={formData.qty === null}
                                fullWidth
                            >
                                Confirm [Enter]
                            </Button>
                        </Grid>
                    </>
                }
                <Grid item xs={12} style={{marginTop: 'auto'}}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={this.handleSkip}
                    >Skip [F2]</Button>
                </Grid>
                <QrScanWrapper
                    handleScan={this.handleScan}
                    parentScan={this._handleKeyDown}
                />
                {confirmationDialog.open &&
                    <ConfirmationDialog
                        open={confirmationDialog.open}
                        title={confirmationDialog.title}
                        message={confirmationDialog.message}
                        success={confirmationDialog.success}
                        close={this.handleConfirmationClose}
                        isPda={true}
                    />
                }
            </Grid>
        )    
    }
}

const styles = theme => ({
    root: {
        padding:    theme.spacing(1),
        alignItems: 'flex-start',
    },
    qrRow: {
        justifyContent: 'flex-end',
        maxHeight:      '50px',
    },
    img: {
        maxHeight: 80,
        maxWidth:  130,
        width: 'auto',
        height: 'auto',
    },  
    qtyRow: {
        textAlign: 'center'
    },
    actionText: {
        border: `2px dashed ${theme.palette.secondary.main}`,
        padding: 15,
        width: '80%',
        margin: '0 auto',
        textAlign: 'center',
        marginTop: '1em'
    },
});

function mapDispatchToProps(dispatch) {
    return {
        playSound: (type, state) => {
            dispatch(playSound(type, state));
        },
        deploySnackBar: (variant, message) => {
            dispatch(deploySnackBar(variant, message));
        }
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ScanDialog));