import React, { PureComponent } from 'react';
import _ from 'lodash';

import { Checkbox, FormControl, Grid, Paper } from '@material-ui/core';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AllIcon from 'Components/Common/Icons/AllIcon';

import icons from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';

class JigCard extends PureComponent {
    
    render() {
        const { classes, idx, options, data, formErrors, handleSelectChange, handleRemove, handleInstructionsPartsChange, instructions } = this.props;

        return (
            <Paper className={classes.paperGrey}>
                <form noValidate autoComplete="off">
                    <Grid container>
                        <Grid item xs>                    
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect
                                            noClear
                                            name='part'
                                            label={`Jig ${(idx+1)}`}
                                            options={options}
                                            error={formErrors && formErrors['parts|part|' + idx] && true}
                                            errorText={formErrors && formErrors['parts|part|' + idx]}
                                            value={data.part}
                                            onChange={handleSelectChange}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} xl={10} >
                                    <Grid container spacing={1}>
                                        {data.part && <>
                                            {_.map(_.filter(instructions, i => !i.delete ),(i, idx) => 
                                                <Grid item xs={12}>
                                                    <Checkbox
                                                        color=      'primary'
                                                        label=      {`Stage ${i.stage}`}
                                                        value=      {i.jigs[data.part]}
                                                        onChange=   {handleInstructionsPartsChange(i.stage, data.part)}
                                                    />
                                                    Stage {i.stage} - {i.name}
                                                </Grid>
                                            )}
                                        </>}
                                    </Grid>
                                </Grid>
                            </Grid>    
                        </Grid>
                        <Grid item style={{paddingTop: 30}}>
                            <AllIcon 
                                icon={icons.bin} 
                                buttonPrimary 
                                onClick={handleRemove(idx)} 
                                color={colors.grey}
                                noMargin
                                heavy
                            />
                        </Grid>
                    </Grid>   
                </form>                
            </Paper>
        )
    }
}

export default (JigCard);
