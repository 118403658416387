import * as authActionTypes from '../../Actions/Auth/Types/Types';

const initialState = {
    access: {},
    staff: {
        email: '',
        firstName: '',
        id: 0,
        lastName: '',
        exp: 0,
        token: '',
        staffProfilePhoto: '',
        engId: 0 // Engineer ID
    }
};

function authReducer(state = initialState, action) {
    switch(action.type) {
        case authActionTypes.EXTRACT_TOKEN:
            return {
                ...state,
                staff: action.payload
            };
        case authActionTypes.ACCESS:
            return {
                ...state,
                access: action.payload
            };
        default:
            return state;
    }
}

export default authReducer;