import React, {Component} from 'react';
import API from 'API';

import DataTable     from 'Components/Common/DataTables/DataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';
import Textarea      from 'Components/Common/Inputs/Textarea';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons                      from 'Helpers/IconHelper';
import { colors }                 from 'Helpers/ColourHelper';

import {Grid, Typography, FormControl, TextField, Button, FormHelperText} from '@material-ui/core';

const initialState = {
    catagories: [],
    isLoading: true,
    formData: {
        categoryName: '',
        categoryDesc: ''
    },
    formErrors: [],
    access: {}
}

class PartCategories extends Component{
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getPartCategories();
        this.getAccess();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/delete-part-categories'),
            API.get('/staff/my/access/check/update-part-categories'),
        ]).then(([deleteRes, updateRes]) => {
            this.setState({
                access: {
                    delete:  (deleteRes?.data?.has_access) || false,
                    update:  (updateRes?.data?.has_access) || false,
                },
            })
        })
    }

    getPartCategories = () => {
        API.get('/parts/catagories')
        .then(res => {
            this.setState({
                isLoading:  false,
                catagories: res.data
            });
        });
    }

    handleChangeName = e => { this.setState({ formData: { ...this.state.formData, categoryName: e.target.value } }); }
    handleChangeDesc = e => { this.setState({ formData: { ...this.state.formData, categoryDesc: e.target.value } }); }

    handleCreateName = () => {
        API.post('/parts/catagories', this.state.formData)
        .then(result => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({formErrors: formatValidationErrors(result.data.errors)});
            } else {
                this.setState({
                    formData: {
                        categoryName: '',
                        categoryDesc: ''
                    },
                    formErrors: [],
                },this.getPartCategories)
            }
        })
    }

    handleDeleteCategory = id => {
        API.post(`/parts/catagories/${id}/delete`).then(this.getPartCategories)
    }

    render(){
        const {catagories, isLoading, formData, formErrors, access} = this.state;
        return (
            <Grid container xs={12}>
                <Grid item xs={12} style={{ marginBottom: '1.5em'}}>
                    <Typography variant="h5"> Part Categories </Typography>
                </Grid>
                <Grid item xs={6}>
                    <PaddedPaper>
                        <Typography variant="h5">
                            Add Part Category 
                        </Typography>
                        <TextField  label={'Category Name *'} 
                                    name='name'
                                    error={formErrors.name || null}
                                    fullWidth 
                                    value={formData.categoryName} 
                                    onChange={this.handleChangeName}
                        />
                        {formErrors.name ? <FormHelperText style={{color:colors.red}}>{formErrors.name}</FormHelperText> : <></>}
                        <Textarea
                            id="desc"
                            name="desc"
                            label={'Category Description'} 
                            value={formData.categoryDesc} 
                            onChange={this.handleChangeDesc}
                            error={formErrors['desc']}
                            errorText={formErrors['desc']}
                        />
                        <div className='buttonRow'>
                            <Button variant="contained" color="primary" onClick={this.handleCreateName} disabled={!formData.categoryName}>Create</Button>
                        </div>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '1.5em'}}>
                    <PaddedPaper>
                        {isLoading ? <LoadingCircle/> : 
                            <DataTable
                                config={{
                                    key: 'pc_id',
                                    alternatingRowColours: true,
                                    isLoading: isLoading
                                }}
                                columns={[
                                    {
                                        heading: '#',
                                        field: rowData => rowData.pc_id,
                                        sizeToContent: true 
                                    },
                                    {
                                        heading: 'Name',
                                        field: rowData => rowData.pc_name,
                                    },
                                    {
                                        heading: 'Linked Parts',
                                        field: rowData => rowData.parts,
                                        sizeToContent: true 
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: 'Edit',   icon: icons.edit,   link: { pathname: `/parts/categories/${rowData.pc_id}`} },
                                                {name: 'Delete', icon: icons.delete, onClick: () => {this.handleDeleteCategory(rowData.pc_id)}, disabled: (rowData.parts > 0 || !access.delete)}
                                            ]
                                        }
                                    }
                                ]}
                                rows={catagories}
                            />
                        }
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

export default PartCategories;