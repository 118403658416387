import React, {Component} from 'react';
import API                from 'API';
import _                  from 'lodash';
import { connect }        from 'react-redux';

import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import DataTable          from 'Components/Common/DataTables/CiDataTable';
import Textarea           from 'Components/Common/Inputs/Textarea';
import ImageWithError     from 'Components/Common/ImageWithError/ImageWithError';
import BackButton         from 'Components/Common/Buttons/BackButton';

import { colors }                 from 'Helpers/ColourHelper';
import icons                      from 'Helpers/IconHelper';
import { clenyDate }              from 'Helpers/TimeHelper';
import { pdfFromBase64 }          from 'Helpers/PDFHelper';

import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';
import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { deploySnackBar }            from 'Actions/SnackBar/SnackBar';

import {Grid, Typography, TextField, Button, Dialog, DialogActions, DialogContent, ExpansionPanel, ExpansionPanelSummary, 
    ExpansionPanelDetails, List, ListItem, ListItemText} from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import logo from 'Assets/Images/clenaware_logo_icon.png';


const initialState = {
    stockTake:  {},
    formData:   {
        notes: ''
    },
    details:    [],
    access:     {},
    isLoading:  true,
    formErrors: {},
}

class ManualStockTake extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;  
    }

    componentDidMount() { 
        this.getData(); 
        this.getAccess();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/stock-take:submit'), 
            API.get('/staff/my/access/check/stock-take:review'), 
            API.get('/staff/my/access/check/stock-take:download'), 
        ]).then(([submitRes,  reviewRes, downloadRes]) => {
            this.setState({
                access: {
                    download:   downloadRes.data.has_access || false,
                    submit:     submitRes.data.has_access   || false,
                    review:     reviewRes.data.has_access   || false,
                }
            })
        });
    }

    getData = () => {
        API.get(`/stock/take/${this.props.match.params.stId}`)
        .then( res => {
            this.setState({
                stockTake: res.data,
                details:   _.map(res.data.details, i => _.assign({...i, taken: 0})),
                formData:   {
                    notes: res.data.st_notes
                },
                isLoading: false,
            });
        });
    }

    loadPartImage = (rowData) => {
        this.setState({ 
            partImageDialog: true,
            partImageDialogData: rowData.part.default_image?.file_path
        });
    };

    handleChange = (e, id) => {
        this.setState({
            details: _.map(this.state.details, i => {
                if(i.std_id === id) {
                    i.std_taken_stock = e.target.value;
                    i.taken           = 1;
                }
                return i;
            })
        })
    }


    download = () => {
        API.get(`/stock/take/${this.props.match.params.stId}/download`)
        .then( result => {
            if(result.data) pdfFromBase64(result.data.pdf, result.data.pdfName);
        })
    }
    
    save = (showSnack = true) => { 
        let data = {
            details: _.map(_.filter(this.state.details, i => i.std_taken_stock !== null && i.taken), i=>_.assign({
                id:  i.std_id,
                qty: i.std_taken_stock,
            })),
            status:  'Manual',
            notes:    this.state.formData.notes,
        }
        API.post(`/stock/take/${this.props.match.params.stId}/save`, data)
        .then( res => {
            if (showSnack) this.props.deploySnackBar('success','Stock Take Saved');
            this.props.history.push(`/stock/take`);
        })
    }

    submit = () => {
        this.save(false);
        API.post(`/stock/take/${this.props.match.params.stId}/submit`)
        .then(res => {this.props.history.push(`/stock/take`);})
    }

    handleNoteChange = e => {
        this.setState({
            formData: {
                notes: e.target.value
            }
        })
    }

    goBack = () => { this.props.history.push(`/stock/take`); }

    render(){
        const { stockTake, formData, isLoading, access, details } = this.state;

        if (isLoading) return (<LoadingCircle />);
        return (
            <Grid container xs={12} spacing={3} style={{justifyContent:'space-between'}}>
                <Grid item lg={6} xs={12}>
                    <PaddedPaper>
                        <Grid container xs={12}>
                            <Grid container item spacing={3} xs={12}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Stock Take:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                        {stockTake.full_reference}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3} xs={12}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Date:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                        {clenyDate(stockTake.st_date)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                {access.download &&
                    <Grid item xs={12} lg={4}>
                        <PaddedPaper>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Button variant='outlined' fullWidth onClick={this.download}> <AllIcon icon={icons.download} />Download Stock Take Sheet</Button>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
                {details.length > 0 &&
                    <>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <DataTable
                                    config={{
                                        key: 'idx',
                                        alternatingRowColours: true,
                                        responsiveimportant: true,
                                        isLoading: isLoading,
                                    }}
                                    columns={[
                                        {
                                            field: rowData => 
                                                <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                    {rowData && rowData.part.default_image?.thumbnail_file_path ?
                                                        <Button onClick={() => this.loadPartImage(rowData)}>
                                                            <img src={rowData.part.default_image?.thumbnail_file_path} alt={rowData.part.part_description} style={{maxHeight:35, maxWidth:35}} />
                                                        </Button> 
                                                    : 
                                                        <img src={logo} alt="logo" style={{maxHeight:35, maxWidth:35}}/>
                                                    }
                                                </div>,
                                            sizeToContent: true,
                                            alignment: 'center',
                                        },
                                        {
                                            heading: 'Number',
                                            field: rowData => rowData.part.part_number,
                                            important: true,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Description',
                                            field: rowData => rowData.part.part_description,
                                            important: true,
                                            main: true,
                                        },   
                                        {
                                            heading:   'Stock',
                                            field:     rowData => 
                                                <TextField
                                                    type="number"
                                                    value={rowData.std_taken_stock || null}
                                                    onChange={(e) => this.handleChange(e, rowData.std_id)}
                                                    inputProps={{ min: 0 }}
                                                    disabled={rowData.std_skip_recheck}
                                                    fullWidth
                                                />,
                                            important: true,
                                            main:      true,
                                            alignment: 'center'
                                        },
                                    ]}
                                    rows={_.orderBy(details,[i=>i.part.part_number],['asc'])}
                                />
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                {_.filter(stockTake.logs, i => i.stl_notes && i.stl_notes != "" ).length > 0 &&
                                    <ExpansionPanel styles={{width:'100%'}} >
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>Previous Notes</Typography> 
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <List dense={true} fullWidth style={{width: '100%'}}>
                                                {_.map(_.filter(stockTake.logs, i => i.stl_notes && i.stl_notes != "" ), (i, idx) => 
                                                    <ListItem style={{borderBottom: (idx+1) < _.filter(stockTake.logs, i => i.stl_notes && i.stl_notes != "" ).length && `1px solid ${colors.disabled}`}} fullWidth>
                                                        <ListItemText
                                                            style={{padding: '1em 0'}}
                                                            primary={<>{i.stl_notes}<br/><br/></>}
                                                            secondary={`${i.stl_staff_name} - ${clenyDate(i.stl_datetime)}`}
                                                        />
                                                    </ListItem>
                                                )}
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                }
                                <Textarea
                                    id="notes"
                                    name="notes"
                                    label='Stock Take Notes'
                                    value={formData.notes}
                                    rows={10}
                                    onChange={this.handleNoteChange}
                                    noMargin
                                />
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <div className='buttonRow'>
                                    <BackButton />
                                    <Button onClick={this.save} variant="contained" color="primary">Save</Button>
                                    {!_.find(details, i=>i.std_taken_stock === null) && access.submit &&
                                        <Button onClick={()=>{
                                            this.props.deployConfirmation('Are you sure you want to submit this for review?', 'Submit Stock Take',this.submit)
                                        }} variant="contained" color="primary">Submit For Review</Button>
                                    }
                                </div>
                            </PaddedPaper>
                        </Grid>
                    </>
                }
                <Dialog
                    open={this.state.partImageDialog}
                    onClose={this.handlePartImageDialogClose}
                    scroll="body"
                    disableBackdropClick	
                    disableEscapeKeyDown
                >                
                    <DialogContent>
                        <ImageWithError src={this.state.partImageDialogData} alt='Part Image' style={{maxWidth: 500}}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{
                            this.setState({partImageDialog: false})
                        }} variant="outlined" color="default">Close</Button>
                    </DialogActions>
                </Dialog>  
            </Grid>
        )
    }

}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                               => dispatch(closeDialog()),
    deployDialog:       (content, title, variant, size)  => dispatch(deployDialog(content, title, variant,size)),
    deployConfirmation: (message, title, success)        => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)                   => dispatch(deploySnackBar(variant, msg)),
})

export default connect(null, mapDispatchToProps)(ManualStockTake);