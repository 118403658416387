import React, { useState } from 'react';
import _                   from 'lodash';
import uuidv4              from 'uuid/v4';

import { AppBar, Tabs,  Tab, Grid, Menu, MenuItem } from '@material-ui/core';

import AllIcon from 'Components/Common/Icons/AllIcon';

import { colors } from 'Helpers/ColourHelper';

export default function ({tabs, currentTab, handleTabChange, TabProps={}, AppBarProps={}, disableMenu=false, nested=false}) {

    const [anchorEl, setAnchorEl]       = useState(false);
    const [anchorProps, setAnchorProps] = useState({left: 0, top: 0});
    const [keyId, setKeyId]             = useState(uuidv4());

    function getOffset() {
        const el = document.getElementById(keyId);
        if (el){
            const rect = el.getBoundingClientRect();
            setAnchorProps({
                left:   ( (rect.left + rect.width) - 200 ),
                top:    ( rect.top / (nested ? 2 : 1) ) - 9
            });
        }
    }

    return (
        <>
            <AppBar position="static" style={{backgroundColor:'white', color:"#000"}} id={keyId} {...AppBarProps}>
                <Grid container style={{alignItems: 'center'}}>
                    <Grid item style={{ width: 'calc(100% - 48px)' }}>
                        <Tabs   value={currentTab} 
                                onChange={handleTabChange} 
                                className='xs-max-width' 
                                indicatorColor="primary" 
                                textColor="primary" 
                                variant='scrollable'
                                scrollButtons="auto"
                                {...TabProps}
                        >
                            {_.map(_.filter(tabs, i => i?.label), (i, idx) => <Tab 
                                key={idx} 
                                label={i?.label} 
                                value={i?.value ?  i?.value : i?.label } 
                                style={{
                                    borderRight: i.divider ? '1px solid #e0e0e0' : 'none',
                                }}    
                            /> )}
                        </Tabs>
                    </Grid>
                    {!disableMenu &&
                        <Grid item style={{backgroundColor: colors.white}}>
                            <AllIcon icon='ellipsis' noMargin  onClick={()=>{ getOffset(); setAnchorEl(!anchorEl) }} />
                        </Grid>
                    }
                </Grid>
            </AppBar>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClick={()=>{ setAnchorEl(false) }}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'bottom', horizontal: 'left'}}
                style={{...anchorProps}}
            >
                {_.map(_.filter(tabs, i => i?.label), (i, idx) => 
                    <MenuItem key={idx} onClick={(e)=>{handleTabChange(e, i?.value ?  i?.value : i?.label )}}>{i?.label}</MenuItem>
                )}
            </Menu>
        </>
    )
}