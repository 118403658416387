import React, {Component}  from 'react';
import { withStyles } from '@material-ui/core/styles';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import API from '../../../API';
import _ from 'lodash';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import { Link } from 'react-router-dom';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import ImageWithError from '../../Common/ImageWithError/ImageWithError';
import { Dialog, DialogActions, DialogContent, Paper, IconButton, Tooltip, CardContent, CardActionArea, Card, FormControl, Grid, Typography, Button } from '@material-ui/core';
import { downloadS3File } from 'Functions/MiscFunctions';

import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    category: '',
    tileData: [],
    categoryList: [],
    imagesLoading: false,
    accessUpdateCategoryImage: false,
    dialog: false,
    dialogFile: '',
})

const styles = theme => ({
    overlay: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2)
    },
    icons: {
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

class CategoryImageSearch extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Library:categoryImageSearch';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        !this.hasPageState() && this.loadComponentData();
    }

    loadComponentData = () => {
        this.getCategories();
        this.getAccess();
        this.getImages();
    }
    
    getCategories = () => {        
        API.get('/library/categories/withImages')
        .then((result) => {
            let categoryList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.library_cat_id,
                    label: el.library_cat_name
                });
            });
            this.setState({
                categoryList: categoryList
            });
        })
    }

    getAccess = () => {
        API.get('/staff/my/access/check/update-category-image')
        .then((res) =>  {
            this.setState({
                ...this.state,
                accessUpdateCategoryImage: (res.data && res.data.has_access) || false
            });
        })
    }

    getImages = () => {
        let categoryId = this.state.category;
        if(categoryId){
            this.setState({
                imagesLoading: true
            }, () => {
                let tileData = [];
                API.get(`/library/categories/${categoryId}`)
                .then((result) => {
                    result.data.forEach(val => {
                        let imageFile = _.find(val.image_files.files, {'library_type': 'Category Image'});
                        let imageFileRaw = _.find(val.image_files.files, {'library_type': 'Category Image File'});
                        let imageFile3D = _.filter(val.image_files.files, {'library_type': 'Category 3D Image File'});

                        tileData.push({
                            fileUrl: val.library_file_url,
                            imgRef: val.library_image_ref,
                            categoryId: categoryId,
                            disableImage: imageFile ? false : true,
                            disableImageRaw: imageFileRaw ? false : true,
                            disableImage3D: imageFile3D.length > 0 ? false : true,
                            imageUrl: imageFile,
                            imageRawUrl: imageFileRaw,
                            image3DUrl: imageFile3D
                        });
                    });
                    this.setState({
                        tileData: tileData,
                        imagesLoading: false
                    }, () => {  this.savePageState()});
                });
            })
        }
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        },
        () => {
           if(fieldName === 'category' && selectedOption){
                this.getImages();
            } 
            else {
                this.setState({
                    tileData: []
                })
            }
        });
    }

    downloadFile = data => e => {
        if(Array.isArray(data)){
            data.forEach((files) => {
                downloadS3File(files.file)
            });
       }
       else {
            downloadS3File(data.file)
       }
    }

    loadImage = (file) => {
        this.setState({ 
            dialog: true,
            dialogFile: file
        });
    };
    
    handleDialogClose = () => {
        this.setState({ 
            dialog: false,
            dialogFile: ''
        });
    };
       
    render() {
        const { imagesLoading, accessUpdateCategoryImage, category } = this.state;
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Category Image Search
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="h6">
                                    Select Category
                                </Typography>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.categoryList} 
                                        label='Category *'
                                        value={category}
                                        onChange={this.handleSelectChange('category')}
                                    />
                                </FormControl>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3} alignItems="center" alignContent="center">
                        {imagesLoading === true ?
                            <Grid item xs={12} lg={6}>
                                <Paper>
                                    <LoadingCircle />
                                </Paper>
                            </Grid>
                        :
                            (this.state.tileData.map((tile, idx) => (
                                <Grid item xs={6} lg={3} xl={2} key={idx}>
                                    <Card>
                                        <CardActionArea>
                                            <CardContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 150}}>
                                                <div onClick={() => this.loadImage(tile.fileUrl)}>
                                                    <ImageWithError src={tile.fileUrl} style={{height:'auto', maxHeight: 120, maxWidth: '100%'}} alt={tile.imgRef} />
                                                </div>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardContent>
                                            <div className={classes.icons}>
                                                <Tooltip title="Image File">
                                                    <div>{/* Need tag for tooltip */}
                                                        <IconButton onClick={this.downloadFile(tile.imageUrl)} disabled={tile.disableImage}>
                                                            <FALightIcon icon='image' noMargin fixedHeight button disabled={tile.disableImage} />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="Raw Image File">
                                                    <div>{/* Need tag for tooltip */}
                                                        <IconButton onClick={this.downloadFile(tile.imageRawUrl)} disabled={tile.disableImageRaw}>
                                                            <FALightIcon icon='file-image' noMargin fixedHeight button disabled={tile.disableImageRaw} />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="3D Image File">
                                                    <div>{/* Need tag for tooltip */}
                                                        <IconButton onClick={this.downloadFile(tile.image3DUrl)} disabled={tile.disableImage3D}>
                                                            <FALightIcon icon='cube' noMargin fixedHeight button disabled={tile.disableImage3D} />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="Update">
                                                    <div>{/* Need tag for tooltip */}
                                                        {accessUpdateCategoryImage ? 
                                                            <Link to={{pathname: `/library/category/update/${this.state.category}/${tile.imgRef}`, state: {category}}}>
                                                                <IconButton>
                                                                    <FALightIcon icon='pencil-alt' noMargin fixedHeight button style={{color:'#0282C6'}}  />
                                                                </IconButton>
                                                            </Link>
                                                        :
                                                            <IconButton disabled>
                                                                <FALightIcon icon='pencil-alt' noMargin fixedHeight button disabled />
                                                            </IconButton>
                                                        }
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )))
                        }
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.dialog}
                    onClose={this.handleDialogClose}
                    scroll="body"
                    disableEscapeKeyDown
                    disableBackdropClick	
                >                
                    <DialogContent>
                        <ImageWithError src={this.state.dialogFile} alt='Category Image' style={{maxWidth: 500}}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} variant="outlined" color="default">Close</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)( withStyles(styles)(CategoryImageSearch));
