import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import React from 'react';

import { Grid, TextField, Button, Typography } from '@material-ui/core';
import API from 'API';
import _ from 'lodash';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea from 'Components/Common/Inputs/Textarea';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';

import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import IconHelper from 'Helpers/IconHelper';
import AllIcon from 'Components/Common/Icons/AllIcon';

const initialState = (props) => ({
    formData: {
        supplierId: 0,
        partNumber: '',
        description: '',
        associatedPartIds: [],
        file: null,
        notes: '',
        removeImage: 0,
        ...props.formData
    },
    lists: {
        suppliers: [],
        parts: []    
    },
    formErrors: {},
    isLoading: true,
})


class BlueTrollyform extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        Promise.all([
            API.get('/suppliers/all'),
            API.get('/parts/all', { params: { use: 'partSearch' } })
        ]).then(([supRes, partRes]) => {
            this.setState({
                lists: {
                    suppliers: _.map(supRes.data,s => ({value: s.supp_id, label: s.supp_company_name})),
                    parts: _.map(partRes.data, part => ({value: part.part_id, label: `${part.part_number} - ${part.part_description}`}))    
                },
                isLoading: false
            })
        })
    }

    submit = () => {
        this.setState({isLoading: true}, ()=>{
            let _formData = new FormData();
            _.forEach(this.state.formData, (value, key) => {
                if (key === 'associatedPartIds'){
                    _formData.append(key, JSON.stringify(value));
                } else {
                    _formData.append(key, value);
                }
            })   
            API.post(
                this.props.partId ? `/stock/blueTrolly/${this.props.partId}` : '/stock/blueTrolly',
            _formData).then(res => {
                if (res.data.errors){
                    this.setState({
                        formErrors: formatValidationErrors(res.data.errors),
                        isLoading: false
                    });
                } else {
                    this.props.onClose();
                }
            })
        });
    }

    render() {

        const { formData, lists, formErrors, isLoading } = this.state;

        if (isLoading) {
            return (
                <LoadingCircle />
            )
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <AutoCompleteSelect
                        label="Supplier *"
                        value={formData.supplierId}
                        options={lists.suppliers}
                        onChange={value => this.setState({formData: {...formData, supplierId: value?.value}})}
                        noClear
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        value={formData.partNumber}
                        label="Part Number *"
                        onChange={e => this.setState({formData: {...formData, partNumber: e.target.value}})}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Textarea 
                        value={formData.description}
                        label="Part Description *"
                        onChange={e => this.setState({formData: {...formData, description: e.target.value}})}
                        fullWidth
                        rows={2}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AutoCompleteMultiSelect
                        label="Associated Parts *"
                        value={formData.associatedPartIds}
                        options={lists.parts}
                        onChange={value => this.setState({formData: {...formData, associatedPartIds: value ? value.map(v => v.value) : []}})}
                        noClear
                        fullWidth
                    />
                </Grid>
               
                
                {(formData.filePath && !formData.removeImage ) ?
                    <Grid item xs={12} style={{marginTop: '1em'}}>
                        <PaddedPaper>
                            <Grid container style={{alignItems: 'center'}} spacing={3}>
                                <Grid item >
                                    <img src={formData.filePath} alt="part" style={{maxWidth: '50px', maxHeight: '50px', width: 'auto'}} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" style={{marginLeft: '10px'}}>Blue trolley already has an set image</Typography>    
                                </Grid>
                                <Grid item style={{marginLeft: 'auto'}}>
                                    <AllIcon
                                        icon={IconHelper.false}
                                        onClick={()=>this.setState({formData: {...formData, removeImage: 1}})}
                                        style={{cursor: 'pointer'}}
                                        noMargin
                                    />
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <DragFileInput
                            id="partImage"
                            name="partImage"
                            label="Part Image"
                            file={formData.file}
                            errorText={formErrors && formErrors['partImage']}
                            onChange={(drop, name, event) => {
                                const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
                                this.setState({
                                    formData: {
                                        ...this.state.formData,
                                        file                                
                                    }
                                });
                            }}
                            cancelOnClick={()=>this.setState({formData: {...formData, file: null}})}
                            emptyText='No image selected'
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Textarea
                        value={formData.notes}
                        label="Notes"
                        onChange={e => this.setState({formData: {...formData, notes: e.target.value}})}
                        fullWidth
                        rows={3}
                    />
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant="outlined"
                        onClick={this.props.onClose}
                    >Close</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={()=>this.props.deployConfirmation(
                            this.props.partId ? 'Are you sure you want to update this blue trolley part?' : 'Are you sure you want to add this blue trolley part?', 
                            this.props.partId ? 'Update Part' : 'Add Part', 
                            this.submit
                        )}
                        disabled={
                            isLoading
                            || !formData.supplierId
                            || !formData.partNumber
                            || !formData.description
                            || !formData.associatedPartIds.length
                        }
                    >{this.props.partId ? 'Update' : 'Submit'}</Button>
                </Grid>
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success)                   => {dispatch(deployConfirmation(message, title, success))}
    }
}

export default connect(null, mapDispatchToProps)(BlueTrollyform);