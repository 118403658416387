import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher';
import React from 'react';
import ManualContacts from './Tabs/ManualContacts';
import ContactSources from './Tabs/ContactSources';
import { Grid, Typography } from '@material-ui/core';

export default function(props) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">Manual Contacts</Typography>
            </Grid>
            <Grid item xs={12}>
                <TabBarSwitcher
                    tabs={[
                        {
                            label:   'Manual Contacts',
                            content: <ManualContacts {...props}/>,
                        },
                        {
                            label:   'Contact Sources',
                            content: <ContactSources {...props}/>,
                        },
                    ]}
                />
            </Grid>
        </Grid>
        
    )
}