import React, { PureComponent }     from 'react';
import API                          from 'API';
import _                            from 'lodash';
import moment                       from 'moment';

import LoadingCircle                from 'Components/Common/LoadingCircle/LoadingCircle';
import AllIcon                      from 'Components/Common/Icons/AllIcon';
import ReportTile                   from './ReportTile';

import { Card, CardContent, CardHeader, Typography, Grid, Divider, Tooltip, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Box } from '@material-ui/core/';

import {getFiscalYearTimestamps}    from 'Helpers/ReportsHelper';
import icons                        from 'Helpers/IconHelper';
import {colors}                     from 'Helpers/ColourHelper';

const initialState = {
    reportData: {},
    isLoading:  true
}

const ReportCell = ({data, field, hidePound, forceFixed=null, inverse}) => {
    const pound = (hidePound) ? '' : '£';
    let fixed = (hidePound) ? 0 : 2;
    const i     = data[field].in;
    const o     = data[field].out;
    if (forceFixed !== null) fixed = forceFixed;
    return (
        <TableCell align={'center'} style={{color:'black'}}>
            {i ? 
                <Typography variant="h6" style={{color:'black', fontSize: '17px'}}>
                    {`${inverse ? '-' : ''}${pound}${parseFloat(i).toFixed(fixed)}`}
                </Typography> : <Typography variant="h6" style={{color:'black'}}>-</Typography>
            }
            {o ? 
                <Typography variant="h6" style={{color: colors.red, fontSize: '17px'}}>
                    {`${o && fixed ? ( inverse ? '' : '-' ) : ''}${pound}${parseFloat(o).toFixed(fixed)}`}
                </Typography> : <Typography variant="h6" style={{color:'black'}}>-</Typography>
            }
            <Typography variant="h6" style={{color: (i - o) >= 0 ? 'black' : colors.red, fontSize: '17px'}}>
                {`${(i - o) < 0 ? ( inverse ? '' : '-' ) : ( inverse ? '-' : '' )}${pound}${parseFloat( Math.sqrt( Math.pow( ( i - o ) , 2) ) ).toFixed(fixed)}`}
            </Typography>
        </TableCell>
    )
}

class OrderSalesReportOverview extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount() { 
        this.getLedger();
        this.lazyLoadAllData(); 
    }

    lazyLoadAllData = () => {
        _.each([
            'Confirmed',
            'Awaiting Approval',
            'Awaiting Payment',
            'Payment Confirmed',
            'Picking',
            'Awaiting Despatch',
            'Awaiting Invoice',
            'Declined',
            'Cancelled',
        ], i => {
            _.each([
                ['day','week','month'],
                ['year'],
                ['total']
            ], j => {
                this.lazyLoadData(i,j);
            })
        })
    }

    lazyLoadData = (status, time) => {
        API.get(`/reports/salesReport`,{
                params:{
                    cid:this.props.cid, 
                    partId:this.props.partId, 
                    limitStatus: [status],
                    limitTime: time
                }
            }
        ).then(res =>{
            this.setState({
                reportData: 
                    { 
                        ...this.state.reportData,
                        [status]: {
                            ...this.state.reportData[status],  
                            ...res.data[status]
                        }
                    },
                isLoading:  false
            });
        })
    }

    getLedger = () => {
        API.get(`/reports/salesReport`,{
            params:{
                cid:this.props.cid, 
                partId:this.props.partId, 
                limitStatus: [
                    'Confirmed', 
                    'Picking',
                    'Awaiting Approval',
                    'Awaiting Payment',
                    'Payment Confirmed',
                    'Awaiting Despatch',
                    'Awaiting Invoice',
                ],
                limitTime: ['total']
            }
        }).then(res => {
            this.setState({
                ledgerData: {
                    parts: {
                        in: _.sum(_.map(Object.keys(res.data), title => {
                                if (title != 'Awaiting Invoice Payment' && title != 'Order Total' && title !='Cancelled' && title != 'Declined' && title != 'days'){
                                    return res.data[title].total.in.parts
                                }
                                return 0;
                            })),
                        out: _.sum(_.map(Object.keys(res.data), title => {
                                if (title != 'Awaiting Invoice Payment' && title != 'Order Total' && title !='Cancelled' && title != 'Declined' && title != 'days'){
                                    return res.data[title].total.out.parts
                                }
                                return 0;
                            })),
                    },
                    qty: {
                        in: _.sum(_.map(Object.keys(res.data), title => {
                                if (title != 'Awaiting Invoice Payment' && title != 'Order Total' && title !='Cancelled' && title != 'Declined' && title != 'days'){
                                    return res.data[title].total.in.qty
                                }
                                return 0;
                            })),
                        out: _.sum(_.map(Object.keys(res.data), title => {
                                if (title != 'Awaiting Invoice Payment' && title != 'Order Total' && title !='Cancelled' && title != 'Declined' && title != 'days'){
                                    return res.data[title].total.out.qty
                                }
                                return 0;
                            })),
                    },
                    partsStock: {
                        in: _.sum(_.map(Object.keys(res.data), title => {
                                if (title != 'Awaiting Invoice Payment' && title != 'Order Total' && title !='Cancelled' && title != 'Declined' && title != 'days'){
                                    return res.data[title].total.in.partsStock
                                }
                                return 0;
                            })),
                        out: _.sum(_.map(Object.keys(res.data), title => {
                                if (title != 'Awaiting Invoice Payment' && title != 'Order Total' && title !='Cancelled' && title != 'Declined' && title != 'days'){
                                    return res.data[title].total.out.partsStock
                                }
                                return 0;
                            })),
                    },
                    net: {
                        in: _.sum(_.map(Object.keys(res.data), title => {
                                if (title != 'Awaiting Invoice Payment' && title != 'Order Total' && title !='Cancelled' && title != 'Declined' && title != 'days'){
                                    return res.data[title].total.in.net
                                }
                                return 0;
                            })),
                        out: _.sum(_.map(Object.keys(res.data), title => {
                                if (title != 'Awaiting Invoice Payment' && title != 'Order Total' && title !='Cancelled' && title != 'Declined' && title != 'days'){
                                    return res.data[title].total.out.net
                                }
                                return 0;
                            })),
                    },
                    vat: {
                        in: _.sum(_.map(Object.keys(res.data), title => {
                                if (title != 'Awaiting Invoice Payment' && title != 'Order Total' && title !='Cancelled' && title != 'Declined' && title != 'days'){
                                    return res.data[title].total.in.vat
                                }
                                return 0;
                            })),
                        out: _.sum(_.map(Object.keys(res.data), title => {
                                if (title != 'Awaiting Invoice Payment' && title != 'Order Total' && title !='Cancelled' && title != 'Declined' && title != 'days'){
                                    return res.data[title].total.out.vat
                                }
                                return 0;
                            })),
                    },
                    total: {
                        in: _.sum(_.map(Object.keys(res.data), title => {
                                if (title != 'Awaiting Invoice Payment' && title != 'Order Total' && title !='Cancelled' && title != 'Declined' && title != 'days'){
                                    return res.data[title].total.in.total
                                }
                                return 0;
                            })),
                        out: _.sum(_.map(Object.keys(res.data), title => {
                                if (title != 'Awaiting Invoice Payment' && title != 'Order Total' && title !='Cancelled' && title != 'Declined' && title != 'days'){
                                    return res.data[title].total.out.total
                                }
                                return 0;
                            }))
                    }
                },
            });
        })
    }

    getLabels = () => {
        let year, month, week, day, fy = getFiscalYearTimestamps();
        day = moment().format('dddd Do of MMMM');
        week = moment().startOf('week').format('dddd Do of MMMM') + ' - ' + moment().endOf('week').format('dddd Do of MMMM');
        month = moment().startOf('month').format('dddd Do of MMMM') + ' - ' + moment().endOf('month').format('dddd Do of MMMM');
        year = fy.start.format('YYYY') + ' - ' + fy.end.format('YYYY');
        return {year, month, week, day}
    }

    getCancelledAndDeclined = () => {
        let {Cancelled, Declined}  = this.state.reportData;
        if (Cancelled && Declined){
            let ret = {};
            let status = ['net','qty','vat','total'];
            _.unset(Cancelled, 'days');
            _.map(Cancelled, (c, time) => {
                ret[time] = {
                    in: {
                        net  : c?.in?.net + Declined[time]?.in?.net ,   
                        qty  : c?.in?.qty + Declined[time]?.in?.qty    ,
                        vat  : c?.in?.vat + Declined[time]?.in?.vat    ,
                        total: c?.in?.total + Declined[time]?.in?.total ,   
                        parts: c?.in?.parts + Declined[time]?.in?.parts,
                        partsStock: c?.in?.partsStock + Declined[time]?.in?.partsStock
                    },
                    out:  {
                        net  : c?.out?.net + Declined[time]?.out?.net ,   
                        qty  : c?.out?.qty + Declined[time]?.out?.qty,
                        vat  : c?.out?.vat + Declined[time]?.out?.vat,
                        total: c?.out?.total + Declined[time]?.out?.total , 
                        parts: c?.out?.parts + Declined[time]?.out?.parts ,  
                        partsStock: c?.out?.partsStock + Declined[time]?.out?.partsStock ,  
                    }
                }
            })
            return {
                day: ret?.day,
                week: ret?.week,
                month: ret?.month,
                year: ret?.year,
                total: ret?.total
            };
        }
    }

    render() {
        const {isLoading, reportData, ledgerData}   = this.state;
        const labels                    = this.getLabels();
        return (
            <>
                {isLoading ? <LoadingCircle/> : 
                    <Grid container spacing={3} xs={12}>
                        {ledgerData &&
                            <Grid item xs={12}>
                                <Card style={{color: '#000',backgroundColor: '#FFF',padding: '0'}} >
                                    <CardHeader
                                        avatar={<AllIcon icon={icons['sales']} size='medium' style={{color: '#fff'}} />}
                                        title={'Sales Orders (Approval -> Invoices & Credit Notes Awaiting Approval)'}
                                        titleTypographyProps={{variant:'h6'}}
                                        style={{background: 'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', color: '#FFF'}}
                                    />
                                    <CardContent style={{padding:0, paddingBottom: '1.5em'}}>
                                        <Grid container xs={12}>
                                            <Grid item xs={12} style={{padding: '0.8rem 1.2rem', overflowX: 'auto'}}>
                                                <Table size="small" aria-label="a dense table" style={{color:'black'}}>
                                                    <TableHead style={{backgroundColor:'#f5f5f5'}}>
                                                        <TableRow>
                                                            <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}></TableCell>
                                                            <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Invoices / Credits</b></Typography></TableCell>
                                                            <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Qty</b></Typography></TableCell>
                                                            <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Stock</b></Typography></TableCell>
                                                            <TableCell align='center'   style={{width:'20%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Net</b></Typography></TableCell>
                                                            <TableCell align='center'   style={{width:'20%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Vat</b></Typography></TableCell>
                                                            <TableCell align='center'   style={{width:'20%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Gross</b></Typography></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem'}}>
                                                            <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                                <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} primaryColor='#33dd33' />
                                                                <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                    { 'Ordered'}
                                                                </Typography>
                                                            </Box>
                                                            <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                                <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} swapOpacity secondaryColor={colors.red} />
                                                                <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                    Credited
                                                                </Typography>
                                                            </Box>
                                                            <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                                <AllIcon icon={'equals'} size='medium' style={{display: 'inline-block', paddingRight: '0.5rem', color: '#2d2d2d'}} />
                                                                <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                    Total
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <ReportCell data={ledgerData} field='qty' hidePound  />
                                                        <ReportCell data={ledgerData} field='parts' hidePound forceFixed={3}/>
                                                        <ReportCell data={ledgerData} field='partsStock' hidePound forceFixed={3} inverse/>
                                                        <ReportCell data={ledgerData} field='net'   />
                                                        <ReportCell data={ledgerData} field='vat'   />
                                                        <ReportCell data={ledgerData} field='total' />
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        }
                        {_.map(_.orderBy(_.keys(reportData), title =>['Awaiting Approval', 'Confirmed', 'Awaiting Payment', 'Payment Confirmed', 'Picking', 'Awaiting Despatch', 'Awaiting Invoice'].indexOf(title), 'asc'), title => 
                            <>
                                { (title != 'Awaiting Invoice Payment' && title != 'Order Total' && title !='Cancelled' && title != 'Declined' && title != 'days') &&   
                                    <Grid item xs={12} lg={12}>
                                        <ReportTile title={title} 
                                            data={{
                                                day: reportData[title]?.day,
                                                week: reportData[title]?.week,
                                                month: reportData[title]?.month,
                                                year: reportData[title]?.year,
                                                total: reportData[title]?.total
                                            }} 
                                            labels={labels}
                                        />
                                    </Grid>
                                }
                            </>
                        )}
                        {
                            (this.state.reportData.Cancelled && this.state.reportData.Declined) &&
                            <Grid item xs={12} lg={12}>
                                <ReportTile title={'Cancelled'} data={this.getCancelledAndDeclined()} labels={labels}/>
                            </Grid>
                        }
                    </Grid>
                }
            </>
        )
    }
};

export default OrderSalesReportOverview;

