import React       from 'react';
import API         from 'API';
import _           from 'lodash';
import { connect } from 'react-redux';

import DataTable     from 'Components/Common/DataTables/CiDataTable';
import AllIcon       from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';

import { Button, Grid, Typography, Table, TableBody, TableCell, TableRow, Paper } from '@material-ui/core';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import { colors }                 from 'Helpers/ColourHelper';
import icons                      from 'Helpers/IconHelper';
import { colorNumbers, currency } from 'Helpers/NumberHelper';

const initialState = {
    stock:          [],
    isLoading:      true,
    table:          'All',
}

const BlockItem = ({rows}) => {

    let max     = _.sumBy(rows, 'highStockLevel');
    let min     = _.sumBy(rows, 'lowStockLevel');
    let _qty    = _.sumBy(rows, r => parseFloat(r.predicted));
    let level = _qty > max ? 1 : _qty < min ? -1 : 0;

    let parts = _.filter(rows, {predictedLevel: level});
    
    let maxCost = _.sumBy(parts, r => parseFloat(r.highStockLevel) * parseFloat(r.costPer));
    let minCost = _.sumBy(parts, r => parseFloat(r.lowStockLevel) * parseFloat(r.costPer));

    //predicted
    let pQty, pCost, cQty, cCost, pIcon, cIcon, pColor, cColor, pLabel;
    cQty    = _.sumBy(rows, r => parseFloat(r.current));
    cCost   = _.sumBy(rows, r => (parseFloat(r.current)) * parseFloat(r.costPer));
    pQty    = _.sumBy(rows, r => parseFloat(r.predicted));
    pCost   = _.sumBy(rows, r => (parseFloat(r.predicted)) * parseFloat(r.costPer));
    
    let cLevel = cQty >= max ? 1 : cQty <= min ? -1 : 0;

    switch (level) {
        case -1:
            pIcon   = icons.stockLevelLow;
            pColor  = colors.red;
        break;
        case 0:
            pIcon   = icons.stockLevelCorrect;
            pColor  = colors.green;
        break;
        case 1:
            pIcon   = icons.stockLevelHigh;
            pColor  = colors.orange;
        break;
    }

    switch (cLevel) {
        case -1:
            cIcon   = icons.stockLevelLow;
            cColor  = colors.red;
        break;
        case 0:
            cIcon   = icons.stockLevelCorrect;
            cColor  = colors.green;
        break;
        case 1:
            cIcon   = icons.stockLevelHigh;
            cColor  = colors.orange;
        break;
    }

    return (
        <>
            <Grid item xs={6} >
                <PaddedPaper style={{height: '100%', width: '100%'}}>
                    <Grid container spacing={2} style={{alignItems: 'center'}}>
                        <Grid item xs={4} style={{textAlign:'center'}}>
                            <AllIcon
                                icon={cIcon}
                                color={cColor}
                                size='xxxlarge'
                            />
                            <Typography variant='h6' style={{textAlign:'center', marginBottom:7, fontWeight: 'bolder'}}>Current Total</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={2} style={{alignItems: 'center'}}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><AllIcon icon={icons.parts} />Parts</TableCell>
                                            <TableCell>{rows.length}</TableCell>
                                            <TableCell>-</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><AllIcon icon={icons.qty} />Qty</TableCell>
                                            <TableCell>{colorNumbers(parseFloat(cQty).toFixed(3))}</TableCell>
                                            <TableCell>{currency(parseFloat(cCost))}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><AllIcon icon={icons.stockLevelLow} />Min</TableCell>
                                            <TableCell>{colorNumbers(parseFloat(min).toFixed(3))}</TableCell>
                                            <TableCell>{currency(parseFloat(minCost))}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><AllIcon icon={icons.stockLevelHigh} />Max</TableCell>
                                            <TableCell>{colorNumbers(parseFloat(max).toFixed(3))}</TableCell>
                                            <TableCell>{currency(parseFloat(maxCost))}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                </PaddedPaper>
            </Grid>
            <Grid item xs={6} >
                <PaddedPaper style={{height: '100%', width: '100%'}}>
                    <Grid container spacing={2} style={{alignItems: 'center'}}>
                        <Grid item xs={4} style={{textAlign:'center'}}>
                            <AllIcon
                                icon={pIcon}
                                color={pColor}
                                size='xxxlarge'
                            />
                            <Typography variant='h6' style={{textAlign:'center', marginBottom:7, fontWeight: 'bolder'}}>Predicted Total</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={2} style={{alignItems: 'center'}}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><AllIcon icon={icons.parts} />Parts</TableCell>
                                            <TableCell>{rows.length}</TableCell>
                                            <TableCell>-</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><AllIcon icon={icons.qty} />Qty</TableCell>
                                            <TableCell>{colorNumbers(parseFloat(pQty).toFixed(3))}</TableCell>
                                            <TableCell>{currency(parseFloat(pCost))}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><AllIcon icon={icons.stockLevelLow} />Min</TableCell>
                                            <TableCell>{colorNumbers(parseFloat(min).toFixed(3))}</TableCell>
                                            <TableCell>{currency(parseFloat(minCost))}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><AllIcon icon={icons.stockLevelHigh} />Max</TableCell>
                                            <TableCell>{colorNumbers(parseFloat(max).toFixed(3))}</TableCell>
                                            <TableCell>{currency(parseFloat(maxCost))}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                </PaddedPaper>
            </Grid>
        </>
    )

   
}

class StockReportsLevels extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }   

    componentDidMount() {
        this.getStockLevels();
    }

    getStockLevels = () => {
        API.get('/reports/stocksReport/levels', {params: { partId: this.props.partId}})
        .then(res => {
            this.setState({
                stock: _.map(_.filter(res.data, 'q'), i => ({
                    image:             i.r,
                    num:               i.w,
                    desc:              i.e,
                    current:           parseFloat(i?.i),
                    pending:           parseFloat(i?.o),
                    predicted:         parseFloat(i?.p),
                    lowStockLevel:     parseFloat(i?.y),
                    highStockLevel:    parseFloat(i?.u),
                    diff:              parseFloat(parseFloat(i?.p) - parseFloat(i?.y)),
                    part:              i.q,
                    level:             i?.i < i?.y ? -1 : i?.i > i?.u ? 1 : 0,
                    currentType:       i?.i < i?.y ? 'Low' : i?.i > i?.u ? 'High' : 'Normal',
                    predictedLevel:    i?.p < i?.y ? -1 : i?.p > i?.u ? 1 : 0,
                    type:              i?.p < i?.y ? 'Low' : i?.p > i?.u ? 'High' : 'Normal',
                    activeProcurement: i?.a,
                    fill:              (parseFloat(i?.p) < 0 ? 0 : ((parseFloat(i?.p) / parseFloat(i?.u || 0)) * 100)) || 0,
                    cost:              parseFloat(i?.p) * parseFloat(i?.t),   
                    costPer:           parseFloat(i?.t),
                })), 
                isLoading: false
            });
        });
    }

    changeTable = (table) => () => {
        this.setState({ table },  this.resetChecked);
    } 

    createProcurement = rowData => {
        API.post(`/partsOrdering/tracker`, {part: rowData.part})
        .then(this.getStockLevels)
    }

    render() {
        const {isLoading, stock, table } = this.state;

        if ( isLoading ) return ( <LoadingCircle /> );

        const rows        = table === 'All' ? stock : stock.filter(i => i.type === table);
        //const rowsCurrent = table === 'All' ? stock : stock.filter(i => i.currentType === table);

        return (
            <Grid container spacing={3} >
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Button 
                                fullWidth
                                variant='outlined'
                                onClick={this.changeTable('All')} 
                                style={{
                                    backgroundColor: table === 'All' && colors.blue,
                                    color: table === 'All' && colors.white ,
                                }}
                            >
                                <Typography><AllIcon color={table === 'All' && colors.white} icon={icons.stock}/>All Levels</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button 
                                fullWidth
                                variant='outlined'
                                onClick={!!_.filter(stock, {table: 'Low'}) ? this.changeTable('Low') : ()=>{}} 
                                style={{
                                    backgroundColor: table === 'Low' && colors.blue,
                                    color: _.filter(stock, {type: 'Low'}).length === 0 ? colors.disabled : (table === 'Low' && colors.white ),
                                }}
                            >
                                <Typography><AllIcon color={_.filter(stock, {type: 'Low'}).length === 0 ? colors.disabled : (table === 'Low' && colors.white )} icon={icons.stockLevelLow}/>Low</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button 
                                fullWidth
                                variant='outlined'
                                onClick={!!_.filter(stock, {table: 'Normal'}) ? this.changeTable('Normal') : ()=>{}} 
                                style={{
                                    backgroundColor: table === 'Normal' && colors.blue,
                                    color: _.filter(stock, {type: 'Normal'}).length === 0 ? colors.disabled : (table === 'Normal' && colors.white ),
                                }}
                            >
                                <Typography><AllIcon color={_.filter(stock, {type: 'Normal'}).length === 0 ? colors.disabled : (table === 'Normal' && colors.white)} icon={icons.stockLevelCorrect}/>Correct</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button 
                                fullWidth
                                variant='outlined'
                                onClick={!!_.filter(stock, {table: 'High'}) ? this.changeTable('High') : ()=>{}} 
                                style={{
                                    backgroundColor: table === 'High' && colors.blue,
                                    color: _.filter(stock, {type: 'High'}).length === 0 ? colors.disabled : (table === 'High' && colors.white ),
                                }}
                            >
                                <Typography><AllIcon color={_.filter(stock, {type: 'High'}).length === 0 ? colors.disabled : (table === 'High' && colors.white )} icon={icons.stockLevelHigh}/>High</Typography>
                            </Button>
                        </Grid>
                    </Grid>                        
                </Grid>
                <Grid item xs={6} style={{textAlign:'center'}}>
                    <Typography style={{color: colors.white, backgroundColor: colors.red, padding: 5, borderRadius: 5}}><AllIcon icon={icons.warning} color={colors.white} solid/><b>All stock levels based on predicted stock</b><AllIcon icon={icons.warning} color={colors.white} style={{marginLeft: 8}} solid/></Typography>
                </Grid>
                <Grid item xs={2}><PaddedPaper style={{padding: 15}} ><Typography variant='h6'><AllIcon icon={icons.parts}/>{colorNumbers(rows.length)}</Typography></PaddedPaper></Grid>
                <Grid item xs={2}><PaddedPaper style={{padding: 15}} ><Typography variant='h6'><AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(_.sumBy(rows, r => parseFloat(r.current))).toFixed(3))}</Typography></PaddedPaper></Grid>
                <Grid item xs={2}><PaddedPaper style={{padding: 15}} ><Typography variant='h6'><AllIcon icon={icons.poundSign}/>{currency( _.sumBy(rows, r => (parseFloat(r.current) > 0 &&  parseFloat(r.costPer) > 0) ? parseFloat(r.current) * parseFloat(r.costPer) : 0) )}</Typography></PaddedPaper></Grid>
                <Grid item xs={2}><PaddedPaper style={{padding: 15}} ><Typography variant='h6'><AllIcon icon={icons.parts}/>{colorNumbers(rows.length)}</Typography></PaddedPaper></Grid>
                <Grid item xs={2}><PaddedPaper style={{padding: 15}} ><Typography variant='h6'><AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(_.sumBy(rows,  r => parseFloat(r.predicted))).toFixed(3))}</Typography></PaddedPaper></Grid>
                <Grid item xs={2}><PaddedPaper style={{padding: 15}} ><Typography variant='h6'><AllIcon icon={icons.poundSign}/>{currency( _.sumBy(rows, r => (parseFloat(r.predicted) > 0 &&  parseFloat(r.costPer) > 0) ? parseFloat(r.predicted) * parseFloat(r.costPer) : 0) )}</Typography></PaddedPaper></Grid>
                <BlockItem rows={rows} label='Current Total' value='current' />
                <Grid item xs={12}>
                    <DataTable
                         config={{
                            key: 'q',
                            pagination: true,
                            responsiveimportant: true,
                            rowsPerPage: 25,
                            filter: true,
                            filterMod: {
                                filterInPagination:     true,
                                clearable:              true,
                                colSpan:                4,
                            },
                            sticky:true
                        }}
                        columns={_.filter([
                            {
                                field:         'image',
                                fieldFormat:   'image',
                                sizeToContent: true,
                                filter:         'custom',
                                filterMod: {
                                    dataRef:   (search, rowData) => {return `${rowData.num}|${rowData.desc}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                    label:     'Search Parts'
                                }
                            },
                            {
                                heading: 'Part/Description',
                                field:   i => <>{i.num}<br/>{i.desc}</>,
                                dataRef: 'num',
                                truncate: true
                            },
                            {
                                heading:       'Low Levels',
                                field:         'lowStockLevel',
                                dataRef:       'lowStockLevel',
                                fieldFormat:   'decimal:3',
                                sizeToContent: true,
                                alignment:     'center',
                                style:         i => ({color: !i.level ? colors.green : (i.level > 0 ? colors.orange : colors.red )})
                            },
                            {
                                heading:       'High Levels',
                                field:         'highStockLevel',
                                dataRef:       'highStockLevel',
                                fieldFormat:   'decimal:3',
                                sizeToContent: true,
                                alignment:     'center',
                                style:         i => ({color: !i.level ? colors.green : (i.level > 0 ? colors.orange : colors.red )})
                            },
                            {
                                heading:       'Current Stock',
                                field:         'current',
                                dataRef:       'current',
                                fieldFormat:   'decimal:3',
                                alignment:     'right',
                                sizeToContent: true,
                                style:         i => ({color: !i.level ? colors.green : (i.level > 0 ? colors.orange : colors.red )})
                            },
                            {
                                heading:       '',
                                sizeToContent: true,
                                field:         i => 
                                                <AllIcon
                                                    color={!i.level ? colors.green : (i.level > 0 ? colors.orange : colors.red )}
                                                    icon={!i.level ? icons.stockLevelCorrect : ( i.level > 0 ? icons.stockLevelHigh : icons.stockLevelLow )}
                                                    noMargin
                                                />,
                            },
                            {
                                heading:       'Difference',
                                field:         i => !i.level ? 0 : ( i.level > 0 ? i.current - i.highStockLevel : i.current - i.lowStockLevel ),
                                dataRef:       i => !i.level ? 0 : ( i.level > 0 ? i.current - i.highStockLevel : i.current - i.lowStockLevel ),
                                fieldFormat:   'decimal:3',
                                sizeToContent: true,
                                style:         i => ({color: !i.level ? colors.green : (i.level > 0 ? colors.orange : colors.red )})
                            },
                            {
                                heading:       'Pending Stock',
                                field:         'pending',
                                dataRef:       'pending',
                                fieldFormat:   'decimal:3',
                                alignment:     'right',
                                sizeToContent: true,
                                style:         i => ({color: !i.predictedLevel ? colors.green : (i.predictedLevel > 0 ? colors.orange : colors.red )})
                            },
                            {
                                heading:       'Predicted Stock',
                                field:         'predicted',
                                dataRef:       'predicted',
                                fieldFormat:   'decimal:3',
                                alignment:     'right',
                                sizeToContent: true,
                                style:         i => ({color: !i.predictedLevel ? colors.green : (i.predictedLevel > 0 ? colors.orange : colors.red )})
                            },
                            {
                                heading:       '',
                                sizeToContent: true,
                                field:         i => 
                                                <AllIcon
                                                    color={!i.predictedLevel ? colors.green : (i.predictedLevel > 0 ? colors.orange : colors.red )}
                                                    icon={!i.predictedLevel ? icons.stockLevelCorrect : ( i.predictedLevel > 0 ? icons.stockLevelHigh : icons.stockLevelLow )}
                                                    noMargin
                                                />,
                            },
                            {
                                heading:       'Difference',
                                field:         i => !i.predictedLevel ? 0 : ( i.predictedLevel > 0 ? i.predicted - i.highStockLevel : i.predicted - i.lowStockLevel ),
                                dataRef:       i => !i.predictedLevel ? 0 : ( i.predictedLevel > 0 ? i.predicted - i.highStockLevel : i.predicted - i.lowStockLevel ),
                                fieldFormat:   'decimal:3',
                                sizeToContent: true,
                                style:         i => ({color: !i.predictedLevel ? colors.green : (i.predictedLevel > 0 ? colors.orange : colors.red )})
                            },
                            {
                                actions: rowData => ([
                                    {
                                        name: 'View',
                                        icon: icons.search,
                                        link: `/parts/view/${rowData.part}`,
                                    }, 
                                    {
                                        name: 'Create Procurement',
                                        icon: icons.partsOrdering,
                                        onClick: this.createProcurement,
                                        disabled: rowData.activeProcurement
                                    }
                                ])
                            }
                        ], i => !!i)}
                        rows={_.orderBy(rows, 'num', 'asc')}
                    />
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) { return { deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)) } };

export default connect(null, mapDispatchToProps)(StockReportsLevels);