import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import API from '../../../API';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import _ from 'lodash';
import BackButton from 'Components/Common/Buttons/BackButton';
import {colors} from 'Helpers/ColourHelper';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import LinkContactToCustomerDialog from 'Components/Marketing/EmailCampaigns/Tabs/LinkContactToCustomerDialog';
import { clenyDate } from 'Helpers/TimeHelper';
import moment from 'moment';
import EmailField from 'Components/Common/Inputs/EmailField';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea from 'Components/Common/Inputs/Textarea';

const initialState = { 
    formData: {
        customerId: '',
        name: '',
        position: '',
        telephone: '',
        mobile: '',
        email: '',
        associatedAddresses: [],
        company: '',
        source: '',
        sourceData: '',
    },
    customerAddresses: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: true,
    redirect: false,
    showLinkCustomer: false,
    customers: []
}

class UpdateCustomerContact extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getData();
    }

    getData = () => {
        Promise.all([
            API.get(`/customers/contacts/${this.props.match.params.id}`),
            API.get('/customers'),
            API.get('/customers/contacts/sources')
        ]).then((
            [
                contact,
                customers,
                sources
            ]
        ) => {
            if (contact.data.errors) {
                this.setState({ redirect: true });
            } else if (contact.data) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        customerId: contact.data.contact_customer_id,
                        name: contact.data.contact_name,
                        position: contact.data.contact_position,
                        telephone: contact.data.contact_telephone,
                        mobile: contact.data.contact_mobile,
                        email: contact.data.contact_email,
                        associatedAddresses: contact.data.addresses.map(n => n.address_id),
                        company: contact.data.contact_company,
                        source: contact.data.contact_source,
                        sourceData: contact.data.contact_source_data
                    },
                    customerAddresses: contact.data?.customer?.addresses || [],
                    customers: customers.data,
                    isLoading: false,
                    contact: contact.data,
                    sources: _.map(sources.data, i => ({value: i.cs_source, label: i.cs_source})),
                });
            }
        });
    }

    getContact = (contactId) => {
        API.get(`/customers/contacts/${contactId}`)
        .then((res) =>  {
            if (res.data.errors) {
                this.setState({ redirect: true });
            } else if (res.data) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        customerId: res.data.contact_customer_id,
                        name: res.data.contact_name,
                        position: res.data.contact_position,
                        telephone: res.data.contact_telephone,
                        mobile: res.data.contact_mobile,
                        email: res.data.contact_email,
                        associatedAddresses: res.data.addresses.map(n => n.address_id)
                    },
                    customerAddresses: res.data?.customer?.addresses || [],
                    isLoading: false,
                    contact: res.data,
                });
            }
        })
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSelectChange = name => (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: e.value
            }
        })
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        API.post(`/customers/contacts/${this.props.match.params.id}`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState(initialState);
                this.setState({
                    snackbarOpen: true
                });
                this.getData();
            }
            this.props.scrollToTop();
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.state.customerAddresses.map(n => n.address_id);
            this.setState({
                formData:{
                    ...this.state.formData,
                    associatedAddresses: newSelecteds
                }
            });
        } 
        else {
            this.setState({
                formData:{
                    ...this.state.formData,
                    associatedAddresses: []
                }
            });
        }
    }

    handleClick(event, name) {
        const selectedAddresses = this.state.formData.associatedAddresses;

        const selectedIndex = selectedAddresses.indexOf(name);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selectedAddresses, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selectedAddresses.slice(1));
        } else if (selectedIndex === selectedAddresses.length - 1) {
          newSelected = newSelected.concat(selectedAddresses.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selectedAddresses.slice(0, selectedIndex),
            selectedAddresses.slice(selectedIndex + 1),
          );
        }
        this.setState({
            formData:{
                ...this.state.formData,
                associatedAddresses: newSelected
            }
        });
    }

    handleLinkCustomer = () => {
        this.setState({
            showLinkCustomer: true
        });
    }   

    handleLinkCustomerClose = () => {
        this.setState({
            showLinkCustomer: false
        }, ()=>{this.getContact(this.props.match.params.id)});
    }

    setCustomer = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                customerId: e.value,
                associatedAddresses: []
            },
            customerAddresses: e.customer.addresses
        });
    }
       
    render() {
        const { formErrors, formData, customerAddresses, isLoading, showLinkCustomer, contact, customers, sources } = this.state;
        const isSelected = name => this.state.formData.associatedAddresses.indexOf(name) !== -1;

        const AssociatedAdressRow = ({item, isItemSelected}) => {
            return (
                <TableRow
                    hover
                    onClick={event => this.handleClick(event, item.address_id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={item.address_id}
                    selected={isItemSelected}
                >
                    <TableCell padding="checkbox">
                        <Checkbox
                            checked={isItemSelected}
                            color="primary"
                        />
                    </TableCell>
                    <TableCell>
                        {item.address_type}
                    </TableCell>
                    <TableCell>
                        {item.address_name}
                    </TableCell>
                    <TableCell>
                        {item.address_line_one + ', ' + item.address_town + ', ' + item.address_postcode}
                    </TableCell>
                    <TableCell>
                        {item?.address_phone || '-'}
                    </TableCell>
                    <TableCell>
                        {item?.address_email || '-'}
                    </TableCell>
                </TableRow>
            );
        }

        if (this.state.redirect) {
            return <Redirect to="/customers/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Update Contact
                        </Typography>
                    </Grid>
                    {(isLoading && (
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <LoadingCircle />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>                    
                    )) || (
                        <React.Fragment>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={8}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Contact Details
                                        </Typography>
                                        {formErrors && formErrors.generic && (
                                            <React.Fragment>
                                                <Typography component={"div"} style={{color: colors.red}}>
                                                    {formErrors.generic}
                                                </Typography>
                                            </React.Fragment>
                                        )}
                                        <form noValidate autoComplete="off">
                                            <TextField
                                                id="name"
                                                name="name"
                                                label="Name *"
                                                value={formData.name}
                                                error={formErrors && formErrors['name'] && true}
                                                helperText={formErrors && formErrors['name']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <TextField
                                                id="position"
                                                name="position"
                                                label="Position"
                                                value={formData.position}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            {parseInt(formData.customerId) === 0 && (
                                                <TextField
                                                    id="company"
                                                    name="company"
                                                    label="Company"
                                                    value={formData.company}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            )}
                                            <EmailField
                                                id="email"
                                                name="email"
                                                label="Email"
                                                value={formData.email}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <Grid container item spacing={3}>
                                                <Grid item xs={12} lg={6}>
                                                    <TextField
                                                        id="mobile"
                                                        name="mobile"
                                                        label="Mobile"
                                                        value={formData.mobile}
                                                        onChange={this.handleChange}
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <TextField
                                                        id="telephone"
                                                        name="telephone"
                                                        label="Telephone"
                                                        value={formData.telephone}
                                                        onChange={this.handleChange}
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                            {formData.source !== 'Customer' &&
                                                <>
                                                    <TextField
                                                        label="Source"
                                                        options={sources}
                                                        value={formData.source}
                                                        fullWidth
                                                        margin="normal"
                                                        disabled
                                                    />
                                                    <Textarea
                                                        margin="normal"
                                                        label='Source Notes'
                                                        name='sourceData'
                                                        value={formData.sourceData}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        rows={4}
                                                    />
                                                </>
                                            }
                                        </form>
                                    </PaddedPaper>
                                </Grid>
                                {/*
                                    contact.contact_source !== 'Customer' && (
                                        <Grid item xs={12} lg={4} style={{marginLeft: 'auto'}}>
                                            <PaddedPaper>
                                                <Typography variant="h5" gutterBottom>
                                                    Contact Source
                                                </Typography>
                                                <Grid container>
                                                    <Grid item xs={3}>
                                                        source
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        {contact.contact_source}
                                                    </Grid>
                                                    {
                                                        contact.contact_source === 'CSV Upload' &&
                                                        <>
                                                            <Grid item xs={3}>
                                                                List
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {JSON.parse(contact.contact_source_data).name}
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid item xs={3}>
                                                        Added
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        {JSON.parse(contact.contact_source_data).time.split(' ')[0]}
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        Added By
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        {JSON.parse(contact.contact_source_data).staff}
                                                    </Grid>
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>
                                    )
                                */}
                                {parseInt(contact.contact_customer_id) === 0 && 
                                    <Grid item xs={8}>
                                        <PaddedPaper>
                                            <AutoCompleteSelect
                                                label='Link Customer *'
                                                value={formData.customerId}
                                                options={_.map(customers, i => ({label: i.cust_name, value: i.cust_id, customer: i}))}
                                                onChange={this.setCustomer}
                                                noClear
                                                fullWidth
                                            />
                                        </PaddedPaper>
                                    </Grid>
                                }
                                {parseInt(formData.customerId) > 0 && (
                                    <Grid item xs={8}>
                                        <PaddedPaper>
                                            <Typography variant="h6" gutterBottom>
                                                Associated Addresses
                                            </Typography>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                indeterminate={formData.associatedAddresses.length > 0 && formData.associatedAddresses.length < customerAddresses.length}
                                                                checked={formData.associatedAddresses.length === customerAddresses.length}
                                                                onChange={this.handleSelectAllClick}
                                                                color="primary"
                                                            />
                                                        </TableCell>
                                                        <TableCell>Type</TableCell>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Address</TableCell>
                                                        <TableCell>Phone Number</TableCell>
                                                        <TableCell>Email</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {customerAddresses && customerAddresses.filter( item =>  item.address_type === 'Registered').map((item, idx) => 
                                                        <AssociatedAdressRow isItemSelected={isSelected(item.address_id)} item={item} />
                                                    )}
                                                    {customerAddresses && _.sortBy(customerAddresses.filter( item => item.address_type === 'Trading'),[ca => ca.address_name.toLowerCase()]).map((item, idx) => 
                                                        <AssociatedAdressRow isItemSelected={isSelected(item.address_id)} item={item} />
                                                    )}
                                                    {customerAddresses && _.sortBy(customerAddresses.filter( item => item.address_type === 'Accounts'),[ca => ca.address_name.toLowerCase()]).map((item, idx) => 
                                                        <AssociatedAdressRow isItemSelected={isSelected(item.address_id)} item={item} />
                                                    )}
                                                    {customerAddresses && _.sortBy(customerAddresses.filter( item => item.address_type === 'Site'),[ca => ca.address_name.toLowerCase()]).map((item, idx) => 
                                                        <AssociatedAdressRow isItemSelected={isSelected(item.address_id)} item={item} />
                                                    )}
                                                </TableBody>
                                            </Table>
                                            <br></br>
                                            <Typography variant="body1" style={{color:colors.red}}>
                                                {formErrors && formErrors['associatedAddresses']}
                                            </Typography>
                                        </PaddedPaper>
                                    </Grid>
                                )}
                                <Grid item xs={8}>
                                    <PaddedPaper className='buttonRow' style={{marginTop:0}}>
                                        <BackButton props={this.props} />
                                        {/*
                                            parseInt(formData.customerId) === 0 && 
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleLinkCustomer}
                                                >Link Customer</Button>
                                        */}
                                        <Button onClick={this.handleConfirmationOpen}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Update
                                        </Button>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                            <ConfirmationDialog 
                                open={this.state.confirmationOpen} 
                                success={this.handleConfirmationSuccess} 
                                close={this.handleConfirmationClose} 
                                title="Update Contact?" 
                                message="Are you sure you want to update this contact?"
                            />
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarOpen}
                                onClose={this.handleSnackbarClose}
                                message='You have successfully updated the contact'
                            />
                        </React.Fragment>
                    )}
                    {showLinkCustomer &&
                        <Dialog
                            open={showLinkCustomer}
                            maxWidth='sm'
                            fullWidth={true}
                        >
                            <DialogTitle>Link {formData.name} To A Customer</DialogTitle>
                            <DialogContent>
                                <LinkContactToCustomerDialog 
                                    contact={contact}
                                    onClose={this.handleLinkCustomerClose}
                                />
                            </DialogContent>
                        </Dialog>
                    }
                </Grid>
            );
        }
    }
}

export default UpdateCustomerContact;
