import API from 'API';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { colors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { getMediaType } from 'Helpers/FileHelper';
import icons from 'Helpers/IconHelper';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import BackButton from 'Components/Common/Buttons/BackButton';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import EmailDialog from 'Components/Common/Dialogs/EmailDialog';
import EmailPaymentDialog from 'Components/Common/Dialogs/EmailPaymentDialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import FABrandIcon from 'Components/Common/Icons/FontAwesome/FABrandIcon';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import PDFViewer from 'Components/Common/PDFViewer/PDFViewer';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SelectCustomerAddressFormSwitcher from 'Components/Customers/SelectCustomerAddressForm/SelectCustomerAddressFormSwitcher';
import AssignOrderPaymentDialog from 'Components/Sales/Order/OrderPayment/Actions/AssignOrderPaymentDialog';
import AssignRequestedPaymentDialog from 'Components/Sales/Order/OrderPayment/Actions/AssignRequestedPaymentDialog';
import BankTransferDialog from 'Components/Sales/Order/OrderPayment/Actions/BankTransferDialog';
import BarclayCardEPDQDialog from 'Components/Sales/Order/OrderPayment/Actions/BarclayCardEPDQDialog';
import CashDialog from 'Components/Sales/Order/OrderPayment/Actions/CashDialog';
import ChequeDialog from 'Components/Sales/Order/OrderPayment/Actions/ChequeDialog';
import CreditNoteDialog from 'Components/Sales/Order/OrderPayment/Actions/CreditNoteDialog';
import DirectDebitDialog from 'Components/Sales/Order/OrderPayment/Actions/DirectDebitDialog';
import FOCDialog from 'Components/Sales/Order/OrderPayment/Actions/FOCDialog';

import {
    Button,
    ButtonGroup,
    CardContent,
    CardHeader,
    Chip,
    Collapse,
    Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Input,
    InputLabel,
    Paper,
    Radio, RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableHead, TableRow,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
    
    import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { downloadS3File } from 'Functions/MiscFunctions';
import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';

const styles = theme => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    icons: {
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    label: {
        color: 'black'
    },
    noMargin: {
        margin: 0
    }
});

const initialState = () => ({
    formData: {
        invoice: {},
        paymentType: 'Instruction',
        paymentAmount: 0.00,
        date: moment(),
    },
    isLoading: false,
    order: {},
    customerPaymentRequests: 0,
    paymentTerm:{},
    dialogPaymentsOpen: false,
    highlightedPaymentIds: [],
    expanded: {},
    formErrors: [],
    dialogPaymentOpen:  false, 
    dialogPaymentData:  {},
    addressDilalogOpen: false,
    addressDilalogData: {
        curr:        {},
        editInvoice: false,
        editDelivery:false,
        invoice: {
            type:        null,
            addrId:      0,
            contactId:   0,  
        },
        delivery: {
            type:        null,
            addrId:      0,
            contactId:   0,  
        },
        openConfirmation:       false,
        emailDialogOpen:        false,
        emailDialogID:          '',
        emailDialogCustomerId:  '',
        emailDialogContactId:   '',
        importantNoteShown: false,
        customer: {},
    }
});

class OrderPayments extends Component {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `Order:Payments:${this.props.match.params.orderId}`;
        this.state              = initialState();
    }

    componentDidMount = () => {
        this.getOrderInvoices();
    }

    getCustomerPaymentRequests = () => {
        API.get(`/accounts/customerPaymentRequests`, {params: { customer: this.state.order.order_cust_id, status: 'Paid'  }}) 
        .then(res => {
            this.setState({customerPaymentRequests: res.data.length });
        })
    }

    getOrderInvoices = () => {
        this.setState({
            ...initialState(),
            isLoading: true
        }, () => {
            Promise.all([
                API.get(`/sales/orders/${this.props.match.params.orderId}/invoices`)
            ])
            .then(([order]) => {
                let initialExpanded = {}
                if(order?.invoices){
                    for (let i=0, j=order?.invoices.length; i < j; i++) {
                        initialExpanded = {
                            ...initialExpanded,
                            [i]: false
                        }
                    }
                }

                switch(order.data.order_payment_terms){
                    case 'Card Payment':
                    case "Prepayment":
                        if (order.data.authorised_order_payment?.op_txn_method)
                            order.data.order_payment_terms = order.data.order_payment_terms + ' - ' 
                                + ( order.data.authorised_order_payment.op_txn_method == 'Card' ? 
                                    'Barclaycard' : order.data.authorised_order_payment.op_txn_method )
                    break;
                }

                if (order.data.invoice_payment_terms){
                    let payment         = _.sumBy(order.data.invoices, i => i.sum_of_payments) || 0;
                    let invoicePayments = [];
                    let i               = 1;
                    const paymentDays   = [0,7,14,30,0,0,0,0,60,90,0,0,0];
                    const days          = paymentDays[order.data.invoice_payment_terms.oipt_sub_payment_term_id];
                    const now           = moment();
                    for(i;i < (order.data.invoice_payment_terms.oipt_payments + 1); i++) {

                        let status = 'TBC';
                        let icon   = icons.waiting;
                        let to     = moment(_.first(order.data.invoices).invoice_date).add(days * i, 'days');
                        let from   = moment(_.first(order.data.invoices).invoice_date).add(days * (i-1), 'days');
                        let credit = parseFloat(order.data.invoice_payment_terms.oipt_payment_amount) * i;

                        if (now.isAfter(from)){
                            status = (now.isBefore(to)) ? 'due' : 'overdue';
                            icon   = (now.isBefore(to)) ? icons.due : icons.overdue;
                        }

                        if (payment >= credit) {
                            status = 'complete';
                            icon   = icons.complete;
                        }

                        invoicePayments.push({to, from, status, icon});
                    }
                    order.data.invoicePayments = invoicePayments;
                }

                this.setState({
                    ...this.state,
                    order: order.data,
                    isLoading: false,
                    expanded: initialExpanded 
                }, 
                () => {
                    this.savePageState();
                    this.getPaymentTerms(this.state.order.order_payment_term_id);
                    this.getCustomerPaymentRequests();
                    this.getCustomer();
                });
            });
        });
    }

    getPaymentTerms = (paymentTermId) => {
        API.get(`/customers/paymentTerms/${paymentTermId}`)
        .then(result => {
            this.setState({
                ...this.state,
                paymentTerm: result?.data?.paymentTerm,
            },()=>{this.savePageState()});
        });
    }

    handlePaymentDialogOpen = (invoice) => {
        this.setState({
            dialogPaymentsOpen: true, 
            formData: {
                ...this.state.formData,
                invoice,
                date: this.state.order.authorised_order_payment ? this.state.order?.authorised_order_payment?.op_payment_datetime : moment()
            }
        },()=>{this.savePageState()});
    };
    handlePaymentDialogClose = () => {this.setState({dialogPaymentsOpen: false});};
    handlePaymentDialogSuccess = () => {
        this.props.deployConfirmation('Are you sure you want to assign this payment?', 'Are you sure?', this.submitPayment);
    }

    submitPayment = () => {
        API.post(`/sales/orders/invoice/${this.state.formData.invoice.invoice_id}/processPayment`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogPaymentsOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                }, () => {
                    this.props.deploySnackBar("success", "You have successfully assigned the payment");
                    this.getOrderInvoices();
                });
            }
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleDateChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                date: moment(e)
            }
        });
    }

    handleToDecimalPlace = (decimal) => e => {
        let newVal = parseFloat(e.target.value).toFixed(decimal);

        let formData = {
            ...this.state.formData,
            [e.target.name]: newVal
        };
        this.setState({ formData });
    }

    handleExpandInvoices = (idx, invoice) => {
        if(!this.state.expanded[idx] == true){
            this.setState({
                highlightedPaymentIds: [...this.state.highlightedPaymentIds, invoice.invoice_id]
            },()=>{this.savePageState()});
        }
        else {
            let highlightedPaymentIds = [...this.state.highlightedPaymentIds];
            highlightedPaymentIds.splice(this.state.highlightedPaymentIds.indexOf(invoice.invoice_id), 1);
            this.setState({
                highlightedPaymentIds
            },()=>{this.savePageState()});
        }

        this.setState({
            expanded: {
                ...this.state.expanded,
                [idx]: !this.state.expanded[idx]
            }
        },()=>{this.savePageState()});
    }

    downloadInvoice = (invoiceId) => {
        API.post(`/sales/orders/invoice/${invoiceId}/pdf`)
        .then(result => {
            if(result.data) {
                this.props.deployDialog(
                    <PDFViewer pdfData={result.data.pdf} onClose={this.props.closeDialog} style={{ height:'100%', maxHeight: 700, minHeight: 400, maxWidth: '100%' }} />
                )
                //pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    downloadOrder = (order_id) => {
        API.get('/sales/orders/' + order_id + '/pdf')
        .then(result => {
            if(result.data) {
                this.props.deployDialog(
                    <PDFViewer pdfData={result.data.pdf} onClose={this.props.closeDialog} style={{ height:'100%', maxHeight: 700, minHeight: 400, maxWidth: '100%' }} />
                )
                //pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    emailPaymentInstruction = (type) => {
        let dialog, title;
        let invoice = this.state.formData.invoice;

        switch(type) {
            case 'stripe':
                title = 'Send Stripe Payment Instruction';
            break;
            case 'cheque':
                title = 'Send Cheque Payment Instruction';
            break;
            case 'transfer':
                title = 'Send Transfer Payment Instruction';
            break;
            case 'cash':
                title = 'Send Cash Payment Instruction';
            break;
            case 'paypal':
                title = 'Send PayPal Payment Instruction';
            break;
        }

        dialog = <EmailPaymentDialog 
                    customerId={invoice.invoice_cust_id}
                    customerContactId={invoice.invoice_cust_contact_id}
                    type={type}
                    id={invoice.invoice_id}
                    callback={this.getOrderInvoices}
                    deliveryAddressId={invoice.invoice_delivery_cust_address_id}
                    emailAttachment="invoice"
                    paymentTerm={this.state.paymentTerm.payment_term_name}
                    amountOutstanding={invoice.amount_outstanding}
                />

        this.props.deployDialog(dialog, title, 'success', '');
    }

    handleAssignPayment = type => {
        let dialog = '',
            title = '';

        switch(type) {
            case 'FromOrder':
                dialog = <AssignOrderPaymentDialog 
                            invoice={this.state.formData.invoice}
                            callback={this.getOrderInvoices}
                            type="invoice"
                            availableAmount={this.state.order?.remaining_order_payment_balance}
                        />;
                title = 'Assign Payment From Order';
            break;
            case 'FromRequested':
                dialog = <AssignRequestedPaymentDialog
                    invoice={this.state.formData.invoice}
                    callback={this.getOrderInvoices}
                    type="invoice"
                />
                title = 'Assign Payment From Requested';
        }

        this.props.deployDialog(dialog, title, 'success', 'sm');
    }

    handleProcessPayment = type => {
        let dialog = '',
            title = '',
            variant = '';

        switch(type) {
            case 'barclaycard':
                dialog = <BarclayCardEPDQDialog 
                            id={this.state.formData.invoice.invoice_id}
                            callback={this.getOrderInvoices}
                            type="invoice"
                            paymentTerm={this.state.order.order_payment_terms}
                            amountOutstanding={this.state.formData.invoice.amount_outstanding}
                        />;
                title = 'Process BarclayCard Payment';
                variant = 'success';
            break;
            case 'cheque':
                dialog = <ChequeDialog 
                            id={this.state.formData.invoice.invoice_id}
                            callback={this.getOrderInvoices}
                            type="invoice"
                            paymentTerm={this.state.order.order_payment_terms}
                            amountOutstanding={this.state.formData.invoice.amount_outstanding}
                        />;
                title = 'Process Cheque Payment';
                variant = 'success';
            break;
            case 'transfer':
                dialog = <BankTransferDialog 
                            id={this.state.formData.invoice.invoice_id}
                            callback={this.getOrderInvoices}
                            type="invoice"
                            paymentTerm={this.state.order.order_payment_terms}
                            amountOutstanding={this.state.formData.invoice.amount_outstanding}
                        />;
                title = 'Process Bank Transfer';
                variant = 'success';
            break;
            case 'cash':
                dialog = <CashDialog 
                            id={this.state.formData.invoice.invoice_id}
                            callback={this.getOrderInvoices}
                            type="invoice"
                            paymentTerm={this.state.order.order_payment_terms}
                            amountOutstanding={this.state.formData.invoice.amount_outstanding}
                        />
                title = 'Process Cash Payment';
                variant = 'success';
            break;
            case 'directDebit':
                dialog = <DirectDebitDialog 
                            id={this.state.formData.invoice.invoice_id}
                            callback={this.getOrderInvoices}
                            type="invoice"
                            paymentTerm={this.state.order.order_payment_terms}
                            amountOutstanding={this.state.formData.invoice.amount_outstanding}
                        />;
                title = 'Process Direct Debit';
                variant = 'success';
            break;
            case 'FOC':
                dialog = <FOCDialog 
                            id={this.state.formData.invoice.invoice_id}
                            callback={this.getOrderInvoices}
                            type="invoice"
                            paymentTerm={this.state.order.order_payment_terms}
                            amountOutstanding={this.state.formData.invoice.amount_outstanding}
                        />
                title = 'Process Free Of Charge';
                variant = 'success';
            break;
            case 'CreditNote':
                dialog = <CreditNoteDialog 
                            id={this.state.formData.invoice.invoice_id}
                            cnId={this.state.formData.invoice.invoice_cust_id}
                            callback={this.getOrderInvoices}
                            type="invoice"
                            paymentTerm={this.state.order.order_payment_terms}
                            amountOutstanding={this.state.formData.invoice.amount_outstanding}
                        />
                title   = 'Process Credit Note';
                variant = 'success';
            break;
            default:
                throw new Error('unhandled type ' + type);
        }

        this.props.deployDialog(dialog, title, variant, 'md');
    }

    // PAYMENT DETAILS
    handlePaymentOpen = (rowData) => {
        if(rowData?.ip_paypal_shipping_address && rowData.ip_paypal_shipping_address != 'null'){
            let paypalAddress = JSON.parse(rowData?.ip_paypal_shipping_address);
            rowData.paypalShippingAddress = paypalAddress.address_line_1 + '\n' + 
                                            paypalAddress.address_line_2 + '\n' + 
                                            paypalAddress.admin_area_1 + '\n' + 
                                            paypalAddress.admin_area_2 + '\n' + 
                                            paypalAddress.postal_code + '\n' + 
                                            paypalAddress.country_code
        }
        
        this.setState({
            dialogPaymentOpen: true, 
            dialogPaymentData: rowData
        });
    };
    handlePaymentClose = () => {this.setState({dialogPaymentOpen: false});};

    handleEditAddress = invoice => {
        this.setState({
            addressDilalogData: {
                ...initialState().addressDilalogData,
                curr: invoice,
            }
        },this.toggleAddressDilalog);
    }

    toggleAddressDilalog = () => {
        this.setState({addressDilalogOpen: !this.state.addressDilalogOpen})
    }

    subAddressResetData = type => {
        this.setState({
            addressDilalogData: {
                ...this.state.addressDilalogData,
                [type]: initialState().addressDilalogData[type]
            }
        });
    }

    toggleSubAddressDilalog = (type, close=false) => {
        if (close){
            this.subAddressResetData(type == 'editDelivery' ? 'delivery' : 'invoice');
        }
        this.setState({
            addressDilalogData: {
                ...this.state.addressDilalogData,
                [type]: !this.state.addressDilalogData[type]
            }
        })
    }

    handleAddressChange = type => (e) => {
        const edit = (type == 'invoice') ? 'editInvoice' : 'editDelivery';
        this.setState({
            addressDilalogData: {
                ...this.state.addressDilalogData,
                [edit]: true,
                [type]: e
            }
        });
    }


    handleSaveAddress = () => {
        const data = this.state.addressDilalogData;
        let formData = {};
        if (data.editInvoice){
            formData = {
                ...formData,
                invoiceAddress: data.invoice.address,
                invoiceContact: data.invoice.contact,
                changeInvoice : true
            }
        }
        if (data.editDelivery){
            formData = {
                ...formData,
                deliveryAddress: data.delivery.address,
                deliveryContact: data.delivery.contact,
                changeDelivery: true
            }
        }
        API.post(`/sales/orders/invoice/${data.curr.invoice_id}/changeAddress`, formData)
        .then(result => {
            if (result.data.errors){
                this.setState({ formErrors: formatValidationErrors(result.data.errors) });
            } else {    
                this.setState({
                    ...initialState(),
                    emailDialogOpen:        true,
                    emailDialogID:          result.data.id,
                    emailDialogCustomerId:  data.curr.invoice_cust_id,
                    emailDialogContactId:   data.editInvoice ? data.invoice.contact : data.curr.invoice_cust_contact_id
                },()=>{
                    this.handleEmailOpen();
                    this.getOrderInvoices();
                })
            }
        });
    }

    handleEmailOpen = () => {
        const dialog =  <EmailDialog
                            emailDialogOpen={this.state.emailDialogOpen}
                            handleEmailDialogClose={this.handleEmailClose}
                            customerId={this.state.emailDialogCustomerId}
                            customerContactId={this.state.emailDialogContactId}
                            type="invoice"
                            id={this.state.emailDialogID}
                            //redirectPath='/sales/order/invoice'
                            accountsEmail
                        />
        this.props.deployDialog(dialog, 'You Are About To Email - Invoice', 'success');
    }

    handleEmailClose = () => {
        this.setState(initialState(),this.props.closeDialog);
    }

    toggleAddressConfirmation = () => {
        this.setState({
            addressDilalogData: {
                ...this.state.addressDilalogData,
                openConfirmation: !this.state.addressDilalogData.openConfirmation
            }
        })
    }

    viewFile = file => () => {
        this.props.deployDialog(
            <Grid container spacing={1}>
                <Grid item xs={12} style={{textAlign:'center'}}>
                    {getMediaType(file) === 'image' &&
                        <ImageWithError height='500' width='500' src={file} />
                    }
                    {getMediaType(file) === 'pdf' &&
                        <PDFViewer pdfUrl={file} />
                    }
                    {(getMediaType(file) !== 'pdf' && getMediaType(file) !== 'image') && 'File Not Supported'}
                </Grid>
                <Grid className='buttonRow' item xs={12}>
                    <Button onClick={()=>{ this.props.closeDialog() }} variant='outlined'>Close</Button>
                </Grid>
            </Grid>, 'View File', 'success', 'md');
    }

    handleDownloadFile = file => () => { downloadS3File(file); }

    getCustomer = () => {
        API.get(`/customers/${this.state.order.order_cust_id}`)
        .then(result => {
            this.setState({customer: result.data},() => {
                if (!this.state.importantNoteShown && result.data.cust_important_info) this.displayImportantInfo();
            });
        });
    }

    displayImportantInfo = () => {
        this.setState({importantNoteShown: true}, () => {
            this.props.deployDialog(
            <>
                <Typography component="p">
                    {this.state.customer?.cust_important_info && this.state.customer?.cust_important_info.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
                </Typography>
                <Button variant='outlined' style={{float: 'right'}} onClick={this.props.closeDialog}>Close</Button>
            </>
            , 'Important Information', null, 'sm', {backgroundColor : '#fcc6c5', color: '#000'})
        });
    }

    render() {
        const { order, formData, expanded, formErrors, dialogPaymentData, paymentTerm, customer  } = this.state;
        const { classes } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={7}>
                        <Typography variant="h5" gutterBottom>
                            Order Information
                        </Typography>
                        <PaddedPaper>
                            {!this.state.isLoading && customer?.cust_name && customer?.account_status?.cas_name != 'Active - Verified' &&
                                <Typography style={{
                                    fontWeight:'bold',
                                    color: colors.white, 
                                    backgroundColor: colors.red, 
                                    margin: '-24px', 
                                    marginBottom: 12,
                                    borderRadius: '5px 5px 0px 0px',
                                    padding: '0.5em 1em',
                                }}>{customer?.cust_name} is {customer?.account_status?.cas_name }</Typography>}
                            <Grid container item spacing={3}>
                                <Grid item lg={2}>
                                    Order Reference:
                                </Grid>
                                <Grid item lg={10}>
                                    <Link to="" onClick={(e) => { e.preventDefault(); this.downloadOrder(order.order_id)}} className='blueLink'>
                                        {order.full_reference}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item lg={2}>
                                    Order Date:
                                </Grid>
                                <Grid item lg={10}>
                                    {moment(order?.order_date).format("DD/MM/YYYY")}
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item lg={2}>
                                    Customer:
                                </Grid>
                                <Grid item lg={10}>
                                    {order?.order_cust_name}
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item lg={2}>
                                    Payment Terms:
                                </Grid>
                                <Grid item lg={10}>
                                    {order?.order_payment_terms}
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="h6" gutterBottom>
                                Order Payment
                            </Typography>
                            {order?.authorised_order_payment ?
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date Paid</TableCell>
                                            <TableCell>Method</TableCell>
                                            <TableCell align="right">Amount</TableCell>
                                            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{moment(order?.authorised_order_payment?.op_payment_datetime).format("DD/MM/YYYY")}</TableCell>
                                            <TableCell>{order?.authorised_order_payment?.op_txn_method}</TableCell>
                                            <TableCell align="right">
                                                £{order?.authorised_order_payment?.op_txn_amount}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" colSpan={3}>
                                                <span style={{marginRight:'20px', fontSize:'12px'}}>Remaining balance to assign to Invoices:</span>
                                                £{order?.remaining_order_payment_balance}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            :
                                <Typography variant="caption" gutterBottom>
                                    - No order payments - 
                                </Typography>
                            }
                            {order.invoice_payment_terms &&
                                <>
                                    <br/>   
                                    <br/>   
                                    <Typography variant="h6" gutterBottom >
                                        Direct Debit / Spread Payments
                                    </Typography>
                                    <Grid container xs={12}>
                                        <Grid item xs={2}>
                                            Terms:
                                        </Grid>
                                        <Grid item xs={10}>
                                            {_.replace(order.invoice_payment_terms.sub_payment_term.payment_term_name, 'Account - ', '')}
                                        </Grid>
                                        <Grid item xs={2}>Payments:</Grid>
                                        <Grid item xs={10}>
                                            {_.filter(order.invoicePayments, {status: 'complete'}).length}/{order.invoice_payment_terms.oipt_payments}
                                        </Grid>
                                        <Grid item xs={2}>Amount:</Grid>
                                        <Grid item xs={10}>
                                            £{parseFloat(order.invoice_payment_terms.oipt_payment_amount).toFixed(2)}
                                        </Grid>
                                        <Grid item xs={2}>End Date:</Grid>
                                        <Grid item xs={10}>
                                            {moment(order.invoice_payment_terms.oipt_end_date).format('DD/MM/YYYY')}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{marginTop: '1em'}}>Payments:</Grid>
                                    <Grid container xs={12} spacing={1} style={{marginTop: '0.5em'}}>
                                        {_.map(order.invoicePayments, i =>
                                            <Grid item>
                                                <Chip 
                                                    style={{
                                                        padding:            8, 
                                                        backgroundColor:    ( (   i.status === 'due'        &&  colors.orange ) || 
                                                                                ( ( i.status === 'complete' &&  colors.green  ) ||
                                                                                ( i.status === 'overdue'    &&  colors.red    )
                                                                                )
                                                                            ),
                                                        color:              i.status !== 'TBC' && colors.white
                                                    }}
                                                    icon={<AllIcon icon={i.icon} size='small' style={{color: i.status !== 'TBC' && colors.white}}/>}
                                                    label={`${i.to.format('DD/MM/YYYY')}`} 
                                                />
                                            </Grid>     
                                        )}
                                        
                                    </Grid>
                                </>
                            }
                            <div className='buttonRow'>
                                <BackButton props={this.props} />
                            </div>
                        </PaddedPaper>
                        <br />
                        <Typography variant="h5" gutterBottom>
                            Invoice Payments
                        </Typography>
                        <PaddedPaper>
                            <DataTable  
                                config={{
                                    key: 'ip_id',
                                    withBorder: true,
                                    withBorderRadius: true,
                                    plainHeader: true,
                                    rowProps: row => {
                                        return ({
                                            style: {
                                                backgroundColor: this.state.highlightedPaymentIds.includes(row.invoice.invoice_id) && 'rgb(241, 248, 233)'
                                            }
                                        });
                                    }
                                }}
                                columns={[
                                    {
                                        heading: 'Invoice Number',
                                        field: rowData => rowData.invoice.invoice_number,
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Order Payment',
                                        field: rowData => rowData.ip_order_payment_id > 0 ? 'Yes' : 'No',
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Date Paid',
                                        field: rowData => rowData.ip_payment_datetime != '0000-00-00 00:00:00' ? moment(rowData.ip_payment_datetime).format("DD/MM/YYYY") : '-',
                                        important: true,
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Method',
                                        field: rowData => {
                                            return (
                                                <>
                                                    {rowData.ip_txn_method} {rowData.ip_txn_method == 'Credit Note' &&  
                                                        <>(<Link onClick={()=> {window.location = `/returns/creditnote/invoiced/${rowData.credit_note.cn_id}`}} style={{textDecoration: 'none', color: '#0282C6'}} >{`${rowData.credit_note.invoice.cni_invoice_number}`}</Link>)</>
                                                    }
                                                </>
                                            )
                                        },
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Amount',
                                        field: rowData => rowData.ip_txn_amount,
                                        important: true,
                                        sizeToContent: true,
                                        alignment: 'right',
                                        fieldPrefix: '£'
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: 'Download',        icon: icons.download, onClick: this.handleDownloadFile(rowData.file_upload),   disabled: !rowData.ip_file },
                                                {name: 'Payment Details', icon: icons.search,   onClick: () => this.handlePaymentOpen(rowData)}
                                            ]
                                        },
                                        alignment: 'right'
                                    }
                                ]}
                                rows={_.orderBy(order?.authorised_invoice_payments,['ip_payment_datetime'],['asc'])}
                            />
                        </PaddedPaper> 
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Typography variant="h5" gutterBottom>
                            Invoices
                        </Typography>
                        <Grid container spacing={3}>   
                            {_.map(order?.invoices, (invoice, idx) => {
                                return (
                                    <Grid item xs={12} key={idx}>
                                        <Paper style={{opacity: invoice.invoice_status == 'Approval'?'0.5':'1'}}>
                                            <CardHeader
                                                title={<Typography variant={'h6'}>{invoice.invoice_number}</Typography>}
                                                subheader={
                                                    <>
                                                        <Grid container spacing={3}>
                                                            <Grid item>
                                                                <Typography variant={'caption'} paragraph className={this.props.classes.noMargin}>
                                                                    Sage:
                                                                </Typography>
                                                                <Typography variant={'caption'} paragraph className={this.props.classes.noMargin}>
                                                                    Invoice Address:
                                                                </Typography>
                                                                <Typography variant={'caption'} paragraph className={this.props.classes.noMargin}>
                                                                    Delivery Address:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant={'caption'} paragraph className={this.props.classes.noMargin}>
                                                                    {invoice.invoice_sage_invoice_number}
                                                                </Typography>
                                                                <Typography variant={'caption'} paragraph className={this.props.classes.noMargin}>
                                                                    {invoice?.invoice_cust_name}
                                                                    &nbsp;<Tooltip
                                                                        style={{float:'right'}}
                                                                        title={
                                                                            <div>
                                                                                <Typography variant="body2">
                                                                                    <b>Invoice Address:</b><br />
                                                                                    {invoice?.invoice_cust_address.split('\n').map((item, key) => {
                                                                                        return  <span key={key}>{`${item}`}<br/></span>
                                                                                    })}
                                                                                    <b>Contact:</b><br />
                                                                                    {invoice?.invoice_cust_contact_name}<br />
                                                                                    {invoice?.invoice_cust_contact_email}<br />
                                                                                    {invoice?.invoice_cust_contact_mobile ? 
                                                                                        <>
                                                                                            <AllIcon icon='mobile-alt' style={{color:'white'}} size='small' />
                                                                                            {invoice?.invoice_cust_contact_mobile}
                                                                                            <br/>
                                                                                        </> : ''}
                                                                                    {invoice?.invoice_cust_contact_phone ? 
                                                                                        <>
                                                                                            <AllIcon icon='phone' style={{color:'white'}} size='small' />
                                                                                            {invoice?.invoice_cust_contact_phone}
                                                                                            <br/>
                                                                                        </> : ''}
                                                                                </Typography>
                                                                            </div>

                                                                        }
                                                                        placement="right"
                                                                    >
                                                                        <Link>
                                                                            <AllIcon
                                                                                heavy 
                                                                                icon='info-circle'
                                                                                button 
                                                                                size='small'
                                                                                style={{
                                                                                    color: 'rgba(0, 0, 0, 0.38)'
                                                                                }}
                                                                            />
                                                                        </Link>
                                                                    </Tooltip>
                                                                </Typography>   
                                                                <Typography variant={'caption'} paragraph>
                                                                    {invoice?.invoice_delivery_cust_address_name} 
                                                                    &nbsp;<Tooltip
                                                                        style={{float:'right'}}
                                                                        title={
                                                                            <div>
                                                                                <Typography variant="body2">
                                                                                    <b>Delivery Address:</b><br />
                                                                                    {invoice?.invoice_delivery_cust_address.split('\n').map((item, key) => {
                                                                                        return  <span key={key}>{`${item}`}<br/></span>
                                                                                    })}
                                                                                    <b>Contact:</b><br />
                                                                                    {invoice?.invoice_delivery_cust_contact_name}<br />
                                                                                    {invoice?.invoice_delivery_cust_contact_email}<br />
                                                                                    {invoice?.invoice_delivery_cust_contact_mobile ? 
                                                                                        <>
                                                                                            <AllIcon icon='mobile-alt' style={{color:'white'}} size='small' />
                                                                                            {invoice?.invoice_delivery_cust_contact_mobile}
                                                                                            <br/>
                                                                                        </> : ''}
                                                                                    {invoice?.invoice_delivery_cust_contact_phone ? 
                                                                                        <>
                                                                                            <AllIcon icon='phone' style={{color:'white'}} size='small' />
                                                                                            {invoice?.invoice_delivery_cust_contact_phone}
                                                                                            <br/>
                                                                                        </> : ''}
                                                                                </Typography>
                                                                            </div>

                                                                        }
                                                                        placement="right"
                                                                    >
                                                                        <Link>
                                                                            <AllIcon
                                                                                heavy 
                                                                                icon='info-circle'
                                                                                button 
                                                                                size='small'
                                                                                style={{
                                                                                    color: 'rgba(0, 0, 0, 0.38)'
                                                                                }}
                                                                            />
                                                                        </Link>
                                                                    </Tooltip>
                                                                </Typography> 
                                                            </Grid>
                                                        </Grid>                         
                                                        {_.map(invoice.past_revitions, i => 
                                                            <Typography variant={'body2'} gutterBottom>
                                                                <Link to="" onClick={(e) => { e.preventDefault(); this.downloadInvoice(i.invoice_id)}} className='blueLink'>
                                                                    {`${i.invoice_reference}-${i.invoice_reference_unique_number}`}
                                                                </Link>
                                                            </Typography>
                                                        )}
                                                        <Typography variant={'body1'} gutterBottom>
                                                            {invoice.invoice_status == 'Completed' ?
                                                                <Chip size="small" label="Paid" style={{backgroundColor: colors.green, color: '#fff', marginRight:'20px'}} />
                                                            :
                                                                <Chip size="small" label="Pending" style={{backgroundColor: '#F39C12', color: '#fff', marginRight:'20px'}} />
                                                            }
                                                            <span style={{fontSize:'12px'}}>Paid: </span>£{invoice.sum_of_payments || '0.00'}
                                                            <span style={{fontSize:'30px', color: 'rgba(0, 0, 0, 0.2)'}}> / </span> 
                                                            <span style={{fontSize:'12px'}}>Due: </span>£{invoice.invoice_total_incl_vat}
                                                            <span style={{fontSize:'30px', color: 'rgba(0, 0, 0, 0.2)'}}> / </span> 
                                                            <span style={{fontSize:'12px'}}>Outstanding: </span>£{invoice.amount_outstanding}
                                                        </Typography>
                                                    </>
                                                }
                                                action={
                                                    <>
                                                    {invoice.sum_of_payments != invoice.invoice_total_incl_vat && invoice.invoice_status != 'Approval' &&
                                                        <>
                                                            <Tooltip title="Assign / Make Payment">
                                                                <div>{/* Need tag for tooltip */}
                                                                    <IconButton
                                                                        onClick={() => this.handlePaymentDialogOpen(invoice)}
                                                                    >
                                                                        <FALightIcon icon="plus" button noMargin/>
                                                                    </IconButton>
                                                                </div>
                                                            </Tooltip>
                                                            {this.state.addressDilalogData.addrList != {} &&
                                                                <Tooltip title="Edit Address">
                                                                    <div>
                                                                        <IconButton
                                                                            onClick={() => this.handleEditAddress(invoice)}
                                                                        >
                                                                            <FALightIcon icon="pen" button noMargin/>
                                                                        </IconButton>
                                                                    </div>
                                                                </Tooltip>
                                                        }
                                                        </>
                                                    }
                                                    {invoice.invoice_status != 'Approval' &&
                                                        <IconButton
                                                            className={clsx(classes.expand, {
                                                                [classes.expandOpen]: expanded[idx],
                                                            })}
                                                            onClick={() => this.handleExpandInvoices(idx, invoice)}
                                                        >
                                                            <FALightIcon icon="chevron-down" button noMargin/>
                                                        </IconButton>
                                                    }
                                                    </>
                                                }
                                                disableTypography
                                            />  
                                            <Collapse in={expanded[idx]} timeout="auto" unmountOnExit>
                                                <CardContent style={{paddingTop: 0}}>
                                                    {((invoice?.invoice_payments.length + invoice?.invoice_payments.length) > 0 && (      
                                                        <React.Fragment>                                    
                                                            <Table size="small">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Status</TableCell>
                                                                        <TableCell>Date Paid</TableCell>
                                                                        <TableCell>Method</TableCell>
                                                                        <TableCell align="right">Amount</TableCell>
                                                                        
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {_.map(invoice?.invoice_payments, (payment, idx) => {
                                                                        return (
                                                                            <TableRow key={idx} style={{textDecoration: payment.ip_txn_status == 'Expired' && 'line-through'}}>
                                                                                <TableCell>{payment.ip_txn_status}</TableCell>
                                                                                <TableCell>{moment(payment.ip_payment_datetime).format("DD/MM/YYYY")}</TableCell>
                                                                                <TableCell>{payment.ip_txn_method}</TableCell>
                                                                                <TableCell align="right">£{payment.ip_txn_amount}</TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                                </TableBody>
                                                            </Table>     
                                                        </React.Fragment>
                                                    )) || (
                                                        <Typography variant="caption">
                                                            <IconButton disabled>
                                                                <FALightIcon icon="exclamation-circle" />
                                                            </IconButton>
                                                            This invoice has 0 payments
                                                        </Typography>
                                                    )}
                                                </CardContent>   
                                            </Collapse>                       
                                        </Paper>
                                    </Grid>
                                )
                            })}
                            {!(order.despatch_complete && order.pick_complete) && 
                                <Grid item xs={12} >
                                    <Paper style={{opacity: '1', backgroundColor: 'white', padding: '1em'}}>
                                       <Typography variant={'h6'}>There are Pending Invoices </Typography>
                                        {!order.despatch_complete && [<br></br>,
                                            <Typography variant={'body2'}>This order has items still awaiting despatch, this order cannot be completed until they have been picked and dispatched.</Typography>]}
                                        {!order.picking_complete && [<br></br>,
                                            <Typography variant={'body2'}>This order has items still awaiting picking, this order cannot be completed until they have been dispatched.</Typography>]}
                                    </Paper>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {/* ASSIGN/MAKE PAYMENT */}
                <Dialog 
                    open={this.state.dialogPaymentsOpen} 
                    onClose={this.handlePaymentDialogClose} 
                    fullWidth={true} 
                    maxWidth="md" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title">Assign / Make Payment</DialogTitle>
                    <DialogContent>
                    <Grid item xs={12}>
                        <RadioGroup
                            name="paymentType"
                            onChange={this.handleChange}
                            row
                        >
                            <FormControlLabel
                                value="Instruction"
                                control={<Radio color="primary" />}
                                label="Send Payment Instruction"
                                labelPlacement="end"
                                checked={formData.paymentType == 'Instruction' ?  true : false}
                            />
                            <FormControlLabel
                                value="Assign"
                                control={<Radio color="primary" />}
                                label="Assign Existing Payment"
                                labelPlacement="end"
                                checked={formData.paymentType == 'Assign' ? true : false}
                                disabled={!this.state.customerPaymentRequests && ( (order?.authorised_order_payment == null || order?.remaining_order_payment_balance == 0) ?? true )}
                            />
                            <FormControlLabel
                                value="New"
                                control={<Radio color="primary" />}
                                label="Process Payment"
                                labelPlacement="end"
                                checked={formData.paymentType == 'New' ?  true : false}
                            />
                            <FormControlLabel
                                value="Internal"
                                control={<Radio color="primary" />}
                                label="Internal Payment"
                                labelPlacement="end"
                                checked={formData.paymentType == 'Internal' ?  true : false}
                            />
                        </RadioGroup>
                        {formData.paymentType == 'Internal' && 
                            <>
                                <br /><br />
                                <div style={{textAlign:'center' }}>
                                    <ButtonGroup aria-label="outlined primary button group">
                                        <Button 
                                            startIcon={<AllIcon icon={icons.creditNote} size="small" noMargin />}
                                            className={classes.button}
                                            onClick={() => this.handleProcessPayment('CreditNote')}
                                        >
                                            Credit Note
                                        </Button>
                                        <Button 
                                            startIcon={<AllIcon icon={icons.foc} size="small" noMargin style={{filter:'grayscale(100%)'}} />}
                                            className={classes.button}
                                            onClick={() => this.handleProcessPayment('FOC')}
                                        >
                                            FOC
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </>
                        }
                        {formData.paymentType == 'Assign' &&
                            <>
                                <br /><br />
                                <div style={{textAlign:'center' }}>
                                    <ButtonGroup aria-label="outlined primary button group">
                                        <Button 
                                            startIcon={<AllIcon icon={icons.sales} size="small" noMargin />}
                                            className={classes.button}
                                            onClick={() => this.handleAssignPayment('FromOrder')}
                                            disabled={(order?.authorised_order_payment == null || order?.remaining_order_payment_balance == 0) ?? true}
                                        >
                                            Order Payments
                                        </Button>
                                        <Button 
                                            startIcon={<AllIcon icon={icons.accounts} size="small" noMargin style={{filter:'grayscale(100%)'}} />}
                                            className={classes.button}
                                            onClick={() => this.handleAssignPayment('FromRequested')}
                                            disabled={!this.state.customerPaymentRequests}
                                        >
                                            Requested Payments
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </>
                        }
                        {formData.paymentType == 'New' &&
                            <>
                            <br /><br />
                            <div style={{textAlign:'center' }}>
                                <ButtonGroup aria-label="outlined primary button group">
                                    <Button 
                                        startIcon={<FALightIcon icon="credit-card"/>}
                                        className={classes.button}
                                        onClick={() => this.handleProcessPayment('barclaycard')}
                                    >
                                        BarclayCard EPDQ
                                    </Button>
                                    <Button 
                                        startIcon={<FALightIcon icon="money-check-edit" />}
                                        className={classes.button}
                                        onClick={() => this.handleProcessPayment('cheque')}
                                    >
                                        Cheque
                                    </Button>
                                    <Button 
                                        startIcon={<FALightIcon icon="piggy-bank" />}
                                        className={classes.button}
                                        onClick={() => this.handleProcessPayment('transfer')}
                                    >
                                        Bank Transfer
                                    </Button>
                                    <Button 
                                        startIcon={<FALightIcon icon="money-bill" />}
                                        className={classes.button}
                                        onClick={() => this.handleProcessPayment('cash')}
                                    >
                                        Cash
                                    </Button>
                                    <Button 
                                        startIcon={<FALightIcon icon="university" />}
                                        className={classes.button}
                                        onClick={() => this.handleProcessPayment('directDebit')}
                                    >
                                        Direct Debit
                                    </Button>
                                    
                                </ButtonGroup>
                                <br /><br />
                                <Link onClick={() => window.open('https://payments.epdq.co.uk/Ncol/Prod/orderras.asp?new=True&MenuId=34&CSRFSP=%2fncol%2fprod%2fbackoffice%2fmenuindex.asp&CSRFKEY=3CC5B065D2A2A612AA86457776D8F1E0BC0418D9&CSRFTS=20200729141810&branding=EPDQ&MigrationMode=DOTNET', '_blank')} className='blueLink'>
                                        Barclaycard Link <FALightIcon icon="external-link" size="small" />
                                </Link>
                            </div>
                            </>
                        }
                        {formData.paymentType == 'Instruction' &&
                            <>
                            <br /><br />
                            <div style={{textAlign:'center' }}>
                                <ButtonGroup aria-label="outlined primary button group">
                                    <Button 
                                        startIcon={<FABrandIcon icon="stripe-s"/>}
                                        className={classes.button}
                                        onClick={() => this.emailPaymentInstruction('stripe')}
                                    >
                                        Stripe
                                    </Button>
                                    <Button 
                                        startIcon={<FABrandIcon icon="paypal" />}
                                        className={classes.button}
                                        onClick={() => this.emailPaymentInstruction('paypal')}
                                    >
                                        PayPal
                                    </Button>
                                    <Button 
                                        startIcon={<FALightIcon icon="money-check-edit" />}
                                        className={classes.button}
                                        onClick={() => this.emailPaymentInstruction('cheque')}
                                    >
                                        Cheque
                                    </Button>
                                    <Button 
                                        startIcon={<FALightIcon icon="piggy-bank" />}
                                        className={classes.button}
                                        onClick={() => this.emailPaymentInstruction('transfer')}
                                    >
                                        Bank Transfer
                                    </Button>
                                    <Button 
                                        startIcon={<FALightIcon icon="money-bill" />}
                                        className={classes.button}
                                        onClick={() => this.emailPaymentInstruction('cash')}
                                    >
                                        Cash
                                    </Button>
                                </ButtonGroup>
                            </div>
                            </>
                        }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handlePaymentDialogClose} variant="outlined" color="default">Cancel</Button>
                        {formData.paymentType == 'Assign' &&
                            <Button onClick={this.handlePaymentDialogSuccess} autoFocus variant="contained" color="primary">Save</Button>
                        }
                    </DialogActions>
                </Dialog>
                {/* PAYMENT DETAILS */}
                <Dialog 
                    open={this.state.dialogPaymentOpen} 
                    onClose={this.handlePaymentClose} 
                    fullWidth={true} 
                    maxWidth="sm" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title">Payment Details</DialogTitle>
                    <DialogContent>
                        <FormControl margin="normal" fullWidth>
                            <InputLabel shrink={true} className={classes.label}>Payment Date</InputLabel>
                            <Input value={moment(dialogPaymentData.ip_payment_datetime).format('DD/MM/YYYY')} disabled />
                        </FormControl>
                        <FormControl margin="normal" fullWidth>
                            <InputLabel shrink={true} className={classes.label}>Payment Method</InputLabel>
                            <Input value={dialogPaymentData.ip_txn_method} disabled />
                        </FormControl>
                        <FormControl margin="normal" fullWidth>
                            <InputLabel shrink={true} className={classes.label}>Payment Amount</InputLabel>
                            <Input value={`£${dialogPaymentData.ip_txn_amount}`} disabled />
                        </FormControl>
                        {dialogPaymentData.ip_txn_method == 'Credit Note' && 
                            <>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel shrink={true} className={classes.label}>Credit Note</InputLabel>
                                    <Input value={dialogPaymentData?.ip_credit_note_reference} disabled />
                                </FormControl>
                            </>
                        }
                        {dialogPaymentData.ip_txn_method == 'Bank Transfer' &&
                            <>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true} className={classes.label}>Ref</InputLabel>
                                <Input value={dialogPaymentData?.ip_bank_transfer_ref} disabled />
                            </FormControl>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true} className={classes.label}>Payee</InputLabel>
                                <Input value={dialogPaymentData?.ip_bank_transfer_payee} disabled />
                            </FormControl>
                            </>
                        }
                        {dialogPaymentData.ip_txn_method == 'Cheque' || dialogPaymentData.ip_txn_method == 'Cheque (PR)' &&
                            <>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel shrink={true} className={classes.label}>Cheque Number</InputLabel>
                                    <Input value={dialogPaymentData?.ip_cheque_no} disabled />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel shrink={true} className={classes.label}>Cheque Payee</InputLabel>
                                    <Input value={dialogPaymentData?.ip_cheque_payee} disabled />
                                </FormControl>
                            </>
                        }
                        {((dialogPaymentData.ip_txn_method == 'Card' || dialogPaymentData.ip_txn_method == 'Card (PR)') || ( dialogPaymentData.ip_txn_method == 'Stripe' && dialogPaymentData?.ip_card_payment_ref )) &&
                            <>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true} className={classes.label}>Payment Ref</InputLabel>
                                <Input value={dialogPaymentData?.ip_card_payment_ref} disabled />
                            </FormControl>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true} className={classes.label}>Auth No</InputLabel>
                                <Input value={dialogPaymentData?.ip_card_auth_no} disabled />
                            </FormControl>
                            </>
                        }
                        {dialogPaymentData.ip_txn_method == 'PayPal' &&
                            <>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true} className={classes.label}>PayPal Shipping Address</InputLabel>
                                <Input multiline value={dialogPaymentData?.paypalShippingAddress} disabled />
                            </FormControl>
                            </>
                        }
                        {( dialogPaymentData.ip_txn_method == 'Stripe' && dialogPaymentData?.ip_stripe_card_type ) &&
                            <>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true} className={classes.label}>Card Type</InputLabel>
                                <Input value={dialogPaymentData?.ip_stripe_card_type} disabled />
                            </FormControl>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true} className={classes.label}>Card Number</InputLabel>
                                <Input value={dialogPaymentData?.ip_stripe_card_number} disabled />
                            </FormControl>
                            </>
                        }
                        {dialogPaymentData.ip_txn_method == 'Direct Debit' &&
                            <>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true} className={classes.label}>Ref</InputLabel>
                                <Input value={dialogPaymentData?.ip_direct_debit_ref} disabled />
                            </FormControl>
                            </>
                        }
                        <Grid item xs={12}>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel shrink={true} className={classes.label} style={{paddingBottom: '1.8em'}}>Payment File</InputLabel>
                                <TextField
                                    style={{marginTop: '0.7em'}}
                                    fullWidth
                                    value={dialogPaymentData.ip_file ? _.last(dialogPaymentData.ip_file.split('/')) : 'No File'}
                                    disabled
                                    InputProps={{
                                        endAdornment: dialogPaymentData.file_upload && (<AllIcon icon={icons.search} onClick={this.viewFile(dialogPaymentData.file_upload)} noMargin />)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handlePaymentClose} variant="outlined" color="default">Close</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open=       {this.state.addressDilalogOpen}
                    onClose=    {this.toggleAddressDilalog}
                    size=       'md'
                    maxWidth=   'md'
                    scroll=     "body"
                    fullWidth
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title">Change Invoice Address's</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1} xs={12}>
                            <Grid item xs={6}>
                                <PaddedPaper fullWidth style={{width:'100%', marginBottom:'1.5em'}}>
                                    <SelectCustomerAddressFormSwitcher
                                        customerId= {this.state.addressDilalogData.curr.invoice_cust_id}
                                        callback=   {this.handleAddressChange('invoice')}
                                        name=       'Invoice'
                                        header=     'Invoice'
                                        formErrors= {formErrors}
                                        cname=      'Company'
                                        addressName={this.state.addressDilalogData.curr.invoice_cust_name}
                                        address=    {this.state.addressDilalogData.curr.invoice_cust_address}
                                        contact=    {this.state.addressDilalogData.curr.invoice_cust_contact_name}
                                        email=      {this.state.addressDilalogData.curr.invoice_cust_contact_email}
                                        phone=      {this.state.addressDilalogData.curr.invoice_cust_contact_phone}
                                        mobile=     {this.state.addressDilalogData.curr.invoice_cust_contact_mobile}
                                    />
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={6}>
                            <PaddedPaper fullWidth style={{width:'100%', marginBottom:'1.5em'}}>
                                    <SelectCustomerAddressFormSwitcher
                                        customerId= {this.state.addressDilalogData.curr.invoice_cust_id}
                                        callback=   {this.handleAddressChange('delivery')}
                                        name=       'Delivery'
                                        header=     'Delivery'
                                        formErrors= {formErrors}
                                        cname=      'Delivery'
                                        addressName={this.state.addressDilalogData.curr.invoice_delivery_cust_address_name}
                                        address=    {this.state.addressDilalogData.curr.invoice_delivery_cust_address}
                                        contact=    {this.state.addressDilalogData.curr.invoice_delivery_cust_address_name}
                                        email=      {this.state.addressDilalogData.curr.invoice_delivery_cust_contact_email}
                                        phone=      {this.state.addressDilalogData.curr.invoice_delivery_cust_contact_phone}
                                        mobile=     {this.state.addressDilalogData.curr.invoice_delivery_cust_contact_mobile}
                                    />
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleAddressDilalog} variant="outlined" color="default">Close</Button>
                        <Button onClick={()=>{this.props.deployConfirmation('Are you sure you want to change address\'s', 'Change Address', this.handleSaveAddress )}} variant="contained" color="primary" 
                            disabled={!(this.state.addressDilalogData.editDelivery || this.state.addressDilalogData.editInvoice)}>Save</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:       ()                                               => dispatch(clearPersistence()),
    setPersistence:         (key, state)                                     => dispatch(setPersistence(key, state)),
    deployDialog:           (content, title, variant, size='md', style=null) => dispatch(deployDialog(content, title, variant, size, style)),
    deployConfirmation:     (message, title, success, cancel=null)           => dispatch(deployConfirmation(message, title, success, cancel)),
    deploySnackBar:         (variant, msg)                                   => dispatch(deploySnackBar(variant, msg)),
    closeDialog:            ()                                               => dispatch(closeDialog()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderPayments));