import React  from 'react';
import API 	  from 'API';
import _ 	  from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import { Grid, Button, Typography, ListItemText, ListItem, List, ListItemSecondaryAction } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';
import AllIcon 		 from 'Components/Common/Icons/AllIcon';

import { clenyDate } from 'Helpers/TimeHelper';
import { colors } 	 from 'Helpers/ColourHelper';
import icons 		 from 'Helpers/IconHelper';

import { addKeyDown, removeKeyDown, setKeyDown } from 'Actions/KeyDown/KeyDown';

const initialState = {
	access: {
		orderSearch: false,
	},
	page: 0,
	perPage: 5,
	orders: [],
    searchResults: [],
    isLoading: false,
	test: 'test'
}

class StockMovements extends React.Component {
	constructor(props) {
        super(props);
		this.state = initialState;
	}

	componentDidMount = () => {
        this.getData();
		this.props.addKeyDown(this._handleKeyDown);
    }

	componentWillUnmount = () => {
		this.props.removeKeyDown()
	}

    getData = () => {
		this.setState({isLoading: true}, ()=> {
			let params = {active: true};
			API.get(`/stock/movement/all`,{params}).then(movementRes => {
				this.setState({
					searchResults:  movementRes.data,
					movements:      _.take(movementRes.data, this.state.perPage),
					isLoading:      false
				});
			});
		});
    }

	_handleKeyDown = (e) => {
		if (e.keyCode === 0 || e.keyCode === 192) return;
		e.preventDefault();
		switch(e.key) {
			case 'F1':
				this.state.page > 0 && this.prevPage();
				break;
			case 'F2':
				((this.state.page + 1) * this.state.perPage) < this.state.searchResults.length && this.nextPage();
				break;
			case 'F3':
				this.getData();
				break;
			case '1':
			case '2':
			case '3':
			case '4':
            case '5':
				let movements = this.state.movements[parseInt(e.key) - 1];
				if (movements) this.startPick(movements);
			break
			default: break;
		}
	}

	nextPage = () => {
		const { page, perPage, searchResults } = this.state;
		const newPage = page + 1;
		const newOrders = _.take(_.slice(searchResults, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			movements: newOrders
		});
	}

	prevPage = () => {
		const { page, perPage, searchResults } = this.state;
		const newPage = page - 1;
		const newOrders = _.take(_.slice(searchResults, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			movements: newOrders
		});
	}

    startPick = (movement) => {
        this.props.history.push(`/stock/movement/${movement.sm_id}`);
    }

  	render() {

		const { isLoading, searchResults, movements, page, perPage } = this.state;
		const { classes } = this.props;

		if (isLoading) return <LoadingCircle/>;

		return (
            <Grid container spacing={1} style={{height: '100%'}}> 
                <Grid item xs={12}>
					<Typography variant='h4' style={{textAlign: 'center'}}>Stock Movements</Typography>
				</Grid>
                <Grid item xs={12}>
					{movements?.length === 0 ? 
						<PaddedPaper>
							<Typography variant='h6' className='mb-1' style={{textAlign: 'center'}}>No Movements</Typography>
							<Button
								variant='contained'
								color='primary'
								onClick={this.getData}
								fullWidth
							>Refresh [F3]</Button>
						</PaddedPaper> :
						<List>
							{_.map(movements, i => 
								<ListItem key={i.sm_id} className={classes.ListItem}
									onClick={()=>this.startPick(i)}
									style={{backgroundColor: moment(i.sm_date).isSame(moment().format('YYYY/MM/DD'))
									? 'inherit'
									: colors.importantInfo}}
								>
									<ListItemText 
										primary={i.sm_ref} 
										secondary={<>
												{clenyDate(i.sm_date)}<br/>
											</>
										}
									/>
									<ListItemSecondaryAction>
										<AllIcon
											icon={icons.qr}
										/>
									</ListItemSecondaryAction>
								</ListItem>
							)}
						</List>
					}
                </Grid>
				{ searchResults.length > perPage &&
					<Grid item xs={12} style={{marginTop: 'auto'}}>
						<Grid container spacing={1} style={{justifyContent: 'space-between'}}> 
							{page > 0 &&
								<Grid item style={{marginRight: 'auto'}}>
									<Button variant='contained' color='primary'
										onClick={this.prevPage}
									>[F1] Prev</Button>
								</Grid>
							}
							{((page + 1) * perPage) < searchResults.length &&
								<Grid item style={{marginLeft: 'auto'}}>
									<Button variant='contained' color='primary'
										onClick={this.nextPage}
									>Next [F2]</Button>
								</Grid>
							}
						</Grid>
					</Grid>
				}
            </Grid>
		);
  	}
}

const styles = theme => ({
    ListItem: {
		backgroundColor: colors.white, 
		borderRadius: 	 5,
		marginBottom: 	 '0.75em',
		boxShadow: 	  	 '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
	}
});



const mapDespatchToProps = dispatch => ({
	addKeyDown: (func) => dispatch(addKeyDown(func,'StockMovements')),
	setKeyDown: () => dispatch(setKeyDown('StockMovements')),
	removeKeyDown: () => dispatch(removeKeyDown('StockMovements'))
});

export default connect(null, mapDespatchToProps)(withStyles(styles)(StockMovements));