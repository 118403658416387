import React from 'react';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import IconHelper from 'Helpers/IconHelper';
import { handleAddOrRemove } from 'Functions/FormFunctions';
import API from 'API';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { colors } from 'Helpers/ColourHelper';
import { Grid, Button, Table, TableHead, TableRow, TableCell } from '@material-ui/core';
import { clenyDate } from 'Helpers/TimeHelper';
import _ from 'lodash';
import InfoBox from 'Components/Common/reports/InfoBox';

const initialState = () => ({
    formData: {
        toSend: [],
        type: null
    }
})

class PendingContacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleAddOrRemove = handleAddOrRemove.bind(this);
    }

    selectAll = () => {
        const { contacts } = this.props;
        const { formData } = this.state;
        this.setState({
            formData: {
                ...this.state.formData,
                toSend: (
                    formData.type ? 
                            contacts.filter(i => {
                                let read = i.emails.filter(e => e.email_datetime_opened !== '0000-00-00 00:00:00').length;
                                let emails = i.emails.length;

                                if (formData.type === 'read'){
                                    return read === emails;
                                } else if (formData.type === 'semi'){
                                    return read > 0 && read < emails;
                                } else if (formData.type === 'unread'){
                                    return read === 0;
                                }   
                            }) :
                            contacts
                ).map(i => i.sc_id)
            }
        })
    }

    sendMulti = () => {
        this.#send(this.state.formData.toSend);
    }

    sendSingle = (contact) => () => {
        this.#send([contact]);
    }

    #send = (contacts) => {
        API.post(`/survey/${this.props.survey.survey_id}/send`, { contacts: JSON.stringify(contacts) }).then(res => {
            this.setState({ toSend: [] }, this.props.refresh);
        })
    }

    removeInvite = (contact) => {
        this.props.deployConfirmation(
            'Are you sure you want to remove this survey invite for ' + contact.contact.contact_name + '?',
            'Remove Survey',
            ()=>API.post(`/survey/${this.props.survey.survey_id}/${contact.sc_id}/remove`).then(res => {
                this.props.refresh();
            })
        )
    }

    render() {

        const { contacts } = this.props;
        const { formData } = this.state;

        return (
            <Grid container spacing={2}>
                <InfoBox
                    title='Contacts'
                    icon={IconHelper.contacts}
                    value={_.uniqBy(contacts, 'sc_contact_id').length}
                    rowSize={5}
                />
                <InfoBox
                    title='Survey Invites'
                    icon={IconHelper.survey}
                    value={contacts.length}
                    onClick={()=>this.setState({formData: {...formData, type: null}})}
                    rowSize={5}
                    color={!formData.type && colors.primary}
                    border={!formData.type && `1px solid ${colors.primary}`}
                />
                <InfoBox
                    title='Read'
                    icon={IconHelper.true}
                    value={_.filter(contacts, i => i.emails.filter(e => e.email_datetime_opened !== '0000-00-00 00:00:00').length === i.emails.length).length}
                    onClick={()=>this.setState({formData: {...formData, type: 'read'}})}
                    rowSize={5}
                    color={formData.type === 'read' && colors.green}
                    border={formData.type === 'read' && `1px solid ${colors.green}`}
                />
                <InfoBox
                    title='Semi-read'
                    icon={IconHelper.dash}
                    value={_.filter(contacts, i => { let read = i.emails.filter(e => e.email_datetime_opened !== '0000-00-00 00:00:00').length; let emails = i.emails.length; return read > 0 && read < emails } ).length}
                    onClick={()=>this.setState({formData: {...formData, type: 'semi'}})}
                    rowSize={5}
                    color={formData.type === 'semi' && colors.orange}
                    border={formData.type === 'semi' && `1px solid ${colors.orange}`}
                />
                <InfoBox
                    title='Unread'
                    icon={IconHelper.false}
                    value={_.filter(contacts, i => i.emails.filter(e => e.email_datetime_opened !== '0000-00-00 00:00:00').length === 0 ).length}
                    onClick={()=>this.setState({formData: {...formData, type: 'unread'}})}
                    rowSize={5}
                    color={formData.type === 'unread' && colors.red}
                    border={formData.type === 'unread' && `1px solid ${colors.red}`}
                />

                {contacts.length > 0 &&
                    <Grid item xs={12} className='buttonRow'>
                        {formData.toSend.length > 0 ?
                            <Button variant='contained' color='primary' onClick={
                                ()=>this.props.deployConfirmation(
                                    'Are you sure you want to resend this survey to ' + formData.toSend.length + ' contacts?',
                                    'Resend Survey',
                                    this.sendMulti
                                )}>Resend</Button> :
                            <Button variant='outlined' onClick={this.selectAll}>Select All</Button>
                        }
                    </Grid>
                }
                <Grid item xs={12}>
                    <CiDataTable
                        config={{
                            key: 'sc_id',
                            dropRow:{
                                droppable: true,
                                columns: [
                                    {
                                        colSpan: 9,
                                        field: i => 
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{fontWeight: 'bold'}}>
                                                            Email
                                                        </TableCell>
                                                        <TableCell style={{width: '1%', fontWeight: 'bold'}}>
                                                            Sent
                                                        </TableCell>
                                                        <TableCell style={{width: '1%', fontWeight: 'bold', textAlign:'center'}}>
                                                            Read
                                                        </TableCell>
                                                        <TableCell style={{width: '1%', fontWeight: 'bold', textAlign:'center'}}>
                                                            Status
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {i.emails.map(e => 
                                                    <TableRow key={e.email_id}>
                                                        <TableCell>
                                                            {e.email_to}
                                                        </TableCell>
                                                        <TableCell style={{width: '1%'}}>
                                                            {clenyDate(e.email_datetime_added)}
                                                        </TableCell>
                                                        <TableCell style={{width: '1%', textAlign:'center'}}>
                                                            {e.email_datetime_opened !== '0000-00-00 00:00:00' ? clenyDate(e.email_datetime_opened) : '-'}
                                                        </TableCell>
                                                        <TableCell style={{width: '1%', textAlign:'center'}}>
                                                            {e.email_status}
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </Table>
                                    }
                                ]
                            }
                        }}
                        rows={
                            formData.type ? 
                            contacts.filter(i => {
                                let read = i.emails.filter(e => e.email_datetime_opened !== '0000-00-00 00:00:00').length;
                                let emails = i.emails.length;

                                if (formData.type === 'read'){
                                    return read === emails;
                                } else if (formData.type === 'semi'){
                                    return read > 0 && read < emails;
                                } else if (formData.type === 'unread'){
                                    return read === 0;
                                }   
                            }) :
                            contacts
                        }
                        columns={[
                            {
                                heading: 'Name',
                                field: 'contact.contact_name',
                                sizeToContent: true
                            },
                            {
                                heading: 'Email',
                                field: 'contact.contact_email'
                            },
                            {
                                heading: 'Customer',
                                field: 'contact.customer.cust_name',
                                truncate: true
                            },
                            this.props.survey.form.linked_parts.length > 0 &&
                            {
                                actionsCustomHeader:    true,
                                important:              true,
                                alignment:              'left',
                                heading:                'Order',
                                actions: i => ([
                                    { label: `${i.invoice.order.order_reference}-${i.invoice.order.order_reference_amendment}`,         link: ()=>{window.open(`/sales/order/view/${i.invoice.invoice_order_id}`)},         type:'plainLink'},
                                    ...(i?.invoice?.order?.credit_notes?.length > 0 ? i.invoice.order.credit_notes.map(creditNote => ({ label: `${creditNote.cn_reference}-0${creditNote.cn_reference_amendment}`, link: ()=>{window.open(`/returns/creditnote/${creditNote.cn_id}`)}, type:'plainLink', color: colors.red})) : [])
                                ])
                            },
                            {
                                heading: 'Emails Sent',
                                field: 'emails',
                                fieldFormat: 'count',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'Read',
                                field: i => {
                                    let read = i.emails.filter(e => e.email_datetime_opened !== '0000-00-00 00:00:00').length;
                                    let emails = i.emails.length;

                                    let icon = IconHelper.true;
                                    let color = colors.green;

                                    if (read === 0){
                                        icon = IconHelper.false;
                                        color = colors.red;
                                    } else if (read < emails){
                                        icon = IconHelper.dash;
                                        color = colors.orange;
                                    }

                                    return <AllIcon
                                        icon={icon}
                                        color={color}
                                        tooltip={read + ' of ' + emails + ' emails read'}
                                        noMargin
                                    />
                                },
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'Created Date',
                                field: 'form.form_created_datetime',
                                fieldFormat: 'clenydate',
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'Last Sent',
                                field: i => clenyDate(_.last(i.emails).email_datetime_added),
                            },
                            {
                                actions: i => ([
                                    {
                                        name: 'Sticky Notes',
                                        icon: IconHelper.stickyNote,
                                        onClick: this.props.showStickyNotes,
                                        color: i.sticky_notes_count > 0 ? colors.yellow : 'inherit'
                                    },
                                    {
                                        name: 'View Survey',
                                        icon: IconHelper.survey,
                                        linkExternal: i.form.adminExternalLink
                                    },
                                    {
                                        name: 'Remove',
                                        icon: IconHelper.delete,
                                        onClick: this.removeInvite
                                    },
                                    {
                                        name: 'Resend',
                                        icon: IconHelper.refresh,
                                        onClick: ()=>this.props.deployConfirmation(
                                            'Are you sure you want to resend this survey to ' + i.contact.contact_name + '?',
                                            'Send Survey',
                                            this.sendSingle(i.sc_id),
                                        ),
                                        // disabled: i.contact.contact_email_opt_out
                                    },
                                    {
                                        type: 'checkBox',
                                        onCheck: () => this.handleAddOrRemove('toSend', i.sc_id),
                                        checked: formData.toSend.includes(i.sc_id),
                                        // disabled: i.contact.contact_email_opt_out
                                    },
                                    {
                                        name: 'View Contact',
                                        icon: IconHelper.view,
                                        link: `/customers/contacts/view/${i.contact.contact_id}`,
                                    }
                                ])
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback, variant=null) => {
            dispatch(deployConfirmation(content, title, callback, null, variant))
        }
    }
}

export default connect(null, mapDispatchToProps)(PendingContacts);