import _ from 'lodash';
import React, { Component } from 'react';

import API from '../../../API';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import { handleSelectChange } from 'Functions/FormFunctions';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from '../../Common/DataTables/DataTable';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';

import BackButton from 'Components/Common/Buttons/BackButton';

const initialState = () => ({
    connection:         {},
    formErrors:         [],
    confirmationOpen:   0,
    edit:               0, 
    id:                 0,
    access:             {}
})

class ViewElectricalConnections extends Component {
    constructor(props) {
        super(props);
        this.state = {...initialState(), edit: this.props.edit, id: this.props.match.params.id}
    }

    componentDidMount = () => {
       this.getElectricalConnections();
       this.getAccess();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-electrical-connections'),
            API.get('/staff/my/access/check/add-electrical-connections'),
            API.get('/staff/my/access/check/delete-electrical-connections'),
            API.get('/staff/my/access/check/view-part')
        ])
        .then(([editRes, addRes, delRes, partRes]) => {
            this.setState({
                access:{
                    edit:   editRes.data.has_access, 
                    add:    addRes.data.has_access, 
                    delete: delRes.data.has_access, 
                    part:   partRes.data.has_access
                }
            });
        });
    }

    getElectricalConnections = () => {
        API.get(`/parts/electricalConnections/${this.state.id}`).then(result => {
            this.setState({
                connection: result.data,
            })
        }) 
    }

    handleChange = (e) => {
        this.setState({
            connection: {
                ...this.state.connection,
                [e.target.name]: e.target.value
            }
        });        
    } 

    submit = () => {
        API.post(`/parts/electricalConnections/${this.state.id}`, { name: this.state.connection.ec_name, desc: this.state.connection.ec_desc})
        .then( result => {
            if (result.data.errors){
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                })
            } else {
                this.setState({
                    formErrors: [],
                }, ()=> {this.getElectricalConnections()});
            }
            this.toggleConfirmation();
        });
    }

    toggleConfirmation = () => {
        this.setState({
            confirmationOpen: !this.state.confirmationOpen,
        })
    }

    render(){

        const { formErrors, connection, formData, edit } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Grid container spacing={3}>
                                <Grid item xs={10}>
                                    <Typography variant="h6">
                                        {edit ? 
                                            'Edit Electrical Connection' :
                                            'View Electrical Connection'
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    {(!edit && this.state.access.edit) &&
                                        <IconButton variant="a" href={`/parts/electrical-connections/edit/${this.state.id}`}  style={{float:'right'}}>
                                            <FALightIcon icon='pen' noMargin button />
                                        </IconButton>
                                    }
                                </Grid>
                            </Grid>
                            
                            
                            <form noValidate autoComplete="off">
                                <TextField
                                    id="ec_name"
                                    name="ec_name"
                                    label="Name *"
                                    value={connection.ec_name || ''}
                                    error={formErrors && formErrors['name'] && true}
                                    helperText={formErrors && formErrors['name']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    disabled={!edit}
                                    fullWidth
                                />
                                <TextField
                                    id="ec_desc"
                                    name="ec_desc"
                                    label="Description"
                                    value={connection.ec_desc || ''}
                                    error={formErrors && formErrors['desc'] && true}
                                    helperText={formErrors && formErrors['desc']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    disabled={!edit}
                                    fullWidth
                                />
                                {edit ? 
                                    <div className='buttonRow'>
                                        <BackButton props={this.props} />
                                        <Button 
                                            onClick={this.toggleConfirmation}
                                            variant="contained" 
                                            color="primary" 
                                            
                                        >
                                            Update
                                        </Button>
                                    </div> : ''
                                }
                                <ConfirmationDialog 
                                    open={this.state.confirmationOpen} 
                                    success={this.submit} 
                                    close={this.toggleConfirmation} 
                                    title="Update Electrical Connection?" 
                                    message="Are you sure you want to update this electrical connection?"
                                />
                                <SnackBar
                                    variant="success"
                                    anchorOriginVertical='bottom'
                                    anchorOriginHorizontal='right'
                                    open={this.state.snackbarOpen}
                                    onClose={this.handleSnackbarClose}
                                    message='You have successfully added a new electrical connection'
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                    
                </Grid>
                {!!!edit &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant={'h6'} style={{marginBottom: '1.5em'}} >Parts Used In</Typography>
                            <DataTable 
                                
                                config={{
                                    key: 'ec_id',
                                    alternatingRowColours: true,
                                    isLoading: this.state.isLoading
                                }}
                                columns={[
                                    {
                                        heading: 'Name',
                                        field: rowData => ` ${rowData.part.part_number} - ${rowData.part.part_description}`,
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: 'View',      icon: 'search',         link: '/parts/view/' + rowData.part.part_id, disabled: !this.state.access.part}
                                            ]
                                        }
                                    }
                                ]}
                                rows={connection.parts}
                            />
                        </PaddedPaper>
                    </Grid>  
            }
        </Grid>
        );
    }
}


export default ViewElectricalConnections;