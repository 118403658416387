import React, { Component } from 'react';
import _ from 'lodash';
import API from 'API';

import { FormControl, Typography, Grid } from '@material-ui/core';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { SignalCellularNullRounded } from '@material-ui/icons';

const initialState = () => ({
    formData:    {
        type:       null,
        address:    null,
        contact:    null
    },
    addrList:    [],
    contactList: [],
    isLoading:   true,
    contact:     {},
    names:       {
        address: 'Address',
        contact: 'Contact'
    }
})

class SelectCustomerAddressForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState(),
            formData:{
                type:       null,
                address:    0,
                contact:    0    
            },
        };
    }

    componentDidMount = () => {
        this.getCustomerAddress()
    }

    preFill = () => {
        this.setState({
            formData:{
                type:       this.props.addrType,
                address:    this.props.addrId,
                contact:    this.props.contId    
            },
            contact: _.find(this.state.contactList[this.props.addrId], {'contact_id':this.props.contId})
        });
    }

    getCustomerAddress = () => {
        API.get(`/customers/${this.props.customerId}`)
        .then(res => {
            if (res.data){
                let addrList = {}, contactList = {};
                const types = {
                    Registered: 'registered_address',
                    Accounts:   'account_addresses',
                    Trading:    'trading_addresses',
                    Site:       'site_addresses'
                }

                _.forEach(types,  function(value, key) {
                    let data = (Object.prototype.toString.call(res.data[value]) !== '[object Array]') ?
                        [res.data[value]] : res.data[value];

                    if (data.length > 0){
                        addrList = {...addrList, [key]: data};
                        _.each(data, addr => {
                            contactList = {
                                ...contactList, 
                                [addr.address_id]: addr.active_contacts
                            }
                        })
                    }
                });

                this.setState({addrList, contactList, isLoading: false }, ()=>{if (this.props.addrType && this.props.addrId) this.preFill();});
            }
        });
    }

    handleChange = field => e => {
        let formData = this.state.formData;
        let contact = null;
        switch(field){
            case 'type':
                formData = {...initialState().formData, type: e ? e.value : null }
                break;
            case 'address':
                formData = {...formData, address: e ? e.value : null , contact: null }
                break;
            case 'contact':
                formData = {...formData, contact: e ? e.value : null  }
                contact  = e ? e.data : SignalCellularNullRounded; 
                break;
            default: break;
        }
        this.setState({ formData, contact },()=>{this.props.onChange(field,this.state.formData)});
    }

    render(){

        const {addrList, contactList, formData, isLoading, names } = this.state;
        const {formErrors, name}                                         = this.props;

        return (
            <Typography variant="body">
                {isLoading ? <LoadingCircle /> :[
                    <FormControl fullWidth margin="normal">
                        <AutoCompleteSelect 
                            options={[
                                {value: 'Registered', label: 'Registered'},
                                {value: 'Accounts', label: 'Accounts'},
                                {value: 'Trading', label: 'Trading'},
                                {value: 'Site', label: 'Site'}
                            ]} 
                            label='Address Type'
                            onChange={(e)=>{this.handleChange('type')(e)}}
                            value={formData.type}
                        />
                    </FormControl>,
                    <FormControl fullWidth margin="normal">
                        <AutoCompleteSelect 
                            options={_.map(_.filter(addrList[formData.type], {address_active: 1}), i => {
                                return _.assign({
                                    value:  i.address_id,
                                    label: `${i.address_name} - ${i.address_line_one}, ${i.address_town}, ${i.address_postcode}`
                                })
                            })}
                            label='Address *'                                                    
                            onChange={(e)=>{this.handleChange('address')(e)}}
                            value={formData.address}
                            name={`${name}${names.address}`}
                            error={formErrors && formErrors[`${name}${names.address}`] && true}
                            errorText={formErrors && formErrors[`${name}${names.address}`]}
                        />
                    </FormControl>,
                    <FormControl fullWidth margin="normal">
                        <AutoCompleteSelect 
                            options={_.map(_.filter(contactList[formData.address], i => {
                                return i.contact_email
                            }), i => {
                                return _.assign({
                                    value:  i.contact_id,
                                    label:  i.contact_name,
                                    data:   i
                                })
                            })}
                            label='Contact *'                                                    
                            onChange={(e)=>{this.handleChange('contact')(e)}}
                            value={formData.contact}
                            name={`${name}${names.contact}`}
                            error={formErrors && formErrors[`${name}${names.contact}`] && true}
                            errorText={formErrors && formErrors[`${name}${names.contact}`]}
                        />
                    </FormControl>,
                    <br />,<br />,
                    <Grid container item spacing={3}>
                        <Grid container item xs={12}>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">Phone Number</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">
                                    {this.state.contact?.contact_telephone && this.state.contact?.contact_mobile ?
                                        `${this.state.contact?.contact_telephone} / ${this.state.contact?.contact_mobile}` :
                                        this.state.contact?.contact_telephone ? this.state.contact?.contact_telephone : this.state.contact?.contact_mobile} 
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">
                                    Email Address *
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">
                                    {this.state.contact?.contact_email}
                                </Typography>
                            </Grid>
                            
                        </Grid>
                    </Grid>]
                }
            </Typography>
        )
    }
}

export default SelectCustomerAddressForm;