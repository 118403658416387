import API from 'API';
import moment from 'moment';
import React,{ Component } from 'react';
import { connect } from 'react-redux';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import { colors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons from 'Helpers/IconHelper';

import { Button, Grid, Typography } from '@material-ui/core/';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

const initialState = {
    isLoading:  true,
    templates:  [],
    formErrors: [],
    access:     {},
}


class StockMovementTemplates extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => { 
        this.getData(); 
        this.getAccess();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/template-stock-movement'),
            API.get('/staff/my/access/check/delete-template-stock-movement'),
        ])
        .then(([viewRes, deleteRes]) => {
            this.setState({
                access: {
                    add:    (viewRes?.data?.has_access)   || false,
                    delete: (deleteRes?.data?.has_access) || false,
                },
            })
        });
    }

    getData = () => {
        API.get(`/stock/movement/templates/all`)
        .then( result => {
        	if(result.data.errors) { this.setState({formErrors: formatValidationErrors(result.data.errors) });
            } else {
            	let templates = result.data;
                this.setState({
                    templates,
                    isLoading: false
                });
            }
        })
    }

    delete = id => {
        API.post(`/stock/movement/templates/${id}/delete`)
        .then(res => {this.getData()});
    }

    render() {

        const {access, isLoading, templates} = this.state;

        if (isLoading) return (<LoadingCircle/>);
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Stock Movement Templates
                    </Typography>
                </Grid>         
                <Grid item xs={12}>
                    <PaddedPaper>
                        <div className='buttonRow' style={{marginBottom:'1.5em'}}>
                            <Button variant='contained' color='primary' onClick={
                                ()=>{ window.location.href =`/stock/movement/templates/new`;}
                            }>Create Stock Movement Template</Button>
                        </div>
                        <DataTable
                            config={{
                                key: 'sm_id',
                                alternatingRowColours: true,
                                responsiveimportant: true,
                                style: rowData => ({
                                    color: moment(rowData.sm_date).diff(moment(),'days') < 0 && colors.red
                                })
                            }}
                            columns={[
                                {
                                    heading:    'Name',
                                    field:      rowData =>  rowData.smt_name,
                                    dateRef:    'sm_date',
                                    sizeToContent: true
                                },
                                {
                                    heading:    'Description',
                                    field:      rowData => rowData.smt_desc,
                                    dateRef:    'sm_ref',
                                    truncate:   true,
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Edit', icon: icons.edit, link: { pathname: `/stock/movement/templates/${rowData.smt_id}`, disabled: !access.view }},
                                            {name: 'Delete', icon: icons.delete, disabled: !access.delete, onClick: () =>this.props.deployConfirmation('Are you sure you want to delete this template', 'Delete Template',()=>{this.delete(rowData.smt_id)}) }
                                        ]
                                    }
                                }
                            ]}
                            rows={templates}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success)        => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)                   => dispatch(deploySnackBar(variant, msg))
})

export default connect(null, mapDispatchToProps)(StockMovementTemplates);
