import React  from 'react';
import API from 'API';
import { connect } from 'react-redux';
import {
    Button,
    DialogActions,
    Grid,
    Link,
    TextField,
    Typography
} from '@material-ui/core';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import Textarea from 'Components/Common/Inputs/Textarea';
import { handleChange, handleFileChange, getFormData, handleDateChange, clearValue } from 'Functions/FormFunctions';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import { downloadS3File } from 'Functions/MiscFunctions';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import DatePicker from 'Components/Common/DatePickers/DatePicker';

const initialState = () => ({
    qualificationId: 0,
    engineerId: 0,
    formData: {
        id: 0,
        name: '',
        details: '',
        expiryDate: null,
        file: null,
        fileUrl: ''
    },
    formErrors: [],
});

class EngineerQualificationForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
        this.handleFileChange = handleFileChange.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
        this.getFormData = getFormData.bind(this);
        this.clearValue = clearValue.bind(this);
    }

    componentDidMount = () => {
        const { qualificationId, engineerId } = this.props;
        this.setState({
            qualificationId,
            engineerId
        },
        () => {
            if (qualificationId) {
                this.getQualification(qualificationId);
            }
        })
    }

    getQualification = (qualificationId) => {
        const { engineerId } = this.state;

        API.get(`/engineers/${engineerId}/qualification/${qualificationId}`)
        .then(res => {

            let prof = res.data;

            this.setState({
                ...this.state,
                formData: {
                    ...this.state.formData,
                    name: prof?.nm,
                    details: prof?.details,
                    expiryDate: prof?.expiry,
                    fileUrl: prof?.fileUrl,
                },
                qualification: prof
            });
        });
    }

    handleSubmit = () => {
        const { engineerId, qualificationId } = this.state;
        const route = qualificationId ? `/engineers/${engineerId}/qualification/${qualificationId}`: `/engineers/${engineerId}/qualification`;

        API.post(route, this.getFormData())
        .then(res => {
            if(res.data.errors && res.data.errors.length > 0) {
                this.setState({
                    formErrors: formatValidationErrors(res.data.errors)
                });
            } else {
                this.setState({
                    ...initialState(),
                }, () => {
                    this.props.deploySnackBar("success", `The qualification was successfully ${qualificationId ? 'updated' : 'added'}.`);
                    this.props.onClose()
                    this.props.refresh();
                })
            }
        });
    }

    render() {
        const { qualificationId, formData, formErrors, qualification } = this.state;
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {qualificationId ? 'Update' : 'Add' } Qualification
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                            <Grid container item spacing={1}>
                                <Grid item xs={12}>
                                    <TextField 
                                        name="name"
                                        label="Name *"
                                        onChange={this.handleChange}
                                        error={formErrors && formErrors['name'] && true}
                                        helperText={formErrors && formErrors['name']}
                                        value={formData?.name}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Textarea
                                        id="details"
                                        name="details"
                                        label="Details"
                                        value={formData?.details}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePicker
                                        type="date"
                                        name="expiryDate"
                                        label="Expiry Date"
                                        value={formData?.expiryDate}
                                        onChange={date => this.handleDateChange('expiryDate', date, 'YYYY-MM-DD')}
                                        autoOk={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Link component="div" variant="body2" className='blueLink' style={{cursor: 'pointer'}} onClick={() => {downloadS3File(qualification?.fileUrl)}}>
                                        {qualification?.file}
                                    </Link>
                                    <DragFileInput
                                        name="file"
                                        file={formData?.file}
                                        onChange={this.handleFileChange}
                                        cancelOnClick={() => this.clearValue('file')}
                                        emptyText='No file selected'
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid item xs={12} align="right">
                        <DialogActions className='pr-0 pb-0'>
                            <Button onClick={() => this.props.onClose()} variant="text">
                                <AllIcon icon="times" size={15} />
                                Cancel
                            </Button>
                            <Button onClick={() => this.handleSubmit()} variant="contained" color="primary">
                                <AllIcon icon="check" size={15} style={{color: 'white'}} />
                                {qualification?.id ? 'Update' : 'Add'}
                            </Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </>
        )
    }
}

const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    }
)

export default connect(null, mapDispatchToProps)(EngineerQualificationForm);