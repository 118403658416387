import React  from 'react';
import API 	  from 'API';
import _ 	  from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import { Grid, Button, Typography, ListItemText, ListItem, List, ListItemSecondaryAction } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper 	 from 'Components/Common/Paper/PaddedPaper';
import AllIcon 		 from 'Components/Common/Icons/AllIcon';

import { clenyDate } from 'Helpers/TimeHelper';
import { colors } 	 from 'Helpers/ColourHelper';
import icons 		 from 'Helpers/IconHelper';

import { addKeyDown, removeKeyDown, setKeyDown } from 'Actions/KeyDown/KeyDown';

const initialState = {
	access: {
		orderSearch: false,
	},
	page: 0,
	perPage: 3,
	orders: [],
    searchResults: [],
    isLoading: true,
}

class OrderPicking extends React.Component {
	constructor(props) {
        super(props);
		this.state = initialState;
	}

	componentDidMount = () => {
        this.getAccess();
		this.props.addKeyDown(this._handleKeyDown);
    }

	componentWillUnmount = () => {
		this.props.removeKeyDown();
	}

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/order-search'),
        ])
        .then(([orderSearchRes]) => {
            this.setState({
                access: {
                    orderSearch: orderSearchRes?.data?.has_access ?? false,
                }
            }, this.getSearchData);
        });
    }

	getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([
                API.get('/sales/orders/pick', {params: {
					status:       'Picking',
					display:      'All',
					courier:      'All',
					withDelivery: true
				}}),
            ])
            .then(([orders]) => {
                this.setState({
                    searchResults: 	orders.data.orders,
					orders: 	   	_.take(orders.data.orders, this.state.perPage),
                    isLoading: 	 	false
                });
            });
        });
    }

	_handleKeyDown = (e) => {
		if (e.keyCode === 0 || e.keyCode === 192) return;
		e.preventDefault();
		switch(e.key) {
			case 'F1':
				this.state.page > 0 && this.prevPage();
				break;
			case 'F2':
				((this.state.page + 1) * this.state.perPage) < this.state.searchResults.length && this.nextPage();
				break;
			case 'F3':
				this.getSearchData();
				break;
			case '1':
			case '2':
			case '3':
				let order = this.state.orders[parseInt(e.key) - 1];
				if (order) this.startPick(order);
			break
			default: break;
		}
		return;
	}

	nextPage = () => {
		const { page, perPage, searchResults } = this.state;
		const newPage = page + 1;
		const newOrders = _.take(_.slice(searchResults, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			orders: newOrders
		});
	}

	prevPage = () => {
		const { page, perPage, searchResults } = this.state;
		const newPage = page - 1;
		const newOrders = _.take(_.slice(searchResults, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			orders: newOrders
		});
	}

	startPick = (order) => {
		this.props.history.push(`/sales/order/picking/${order.order_id}`);
		
	}

  	render() {

		const { isLoading, orders, page, searchResults, perPage } = this.state;
		const { classes } = this.props;

		if (isLoading) return <LoadingCircle/>;

		return (
            <Grid container spacing={1} style={{height: '100%'}}> 
                <Grid item xs={12}>
					<Typography variant='h4' style={{textAlign: 'center'}}>Order Picking</Typography>
				</Grid>
				{orders.length === 0 ?
					<Grid item xs={12}>
						<PaddedPaper>
							<Typography variant='h6' className='mb-1' style={{textAlign: 'center'}}>No Orders</Typography>
							<Button
								variant='contained'
								color='primary'
								onClick={this.getSearchData}
								fullWidth
							>Refresh [F3]</Button>
						</PaddedPaper>
					</Grid> :
					<Grid item xs={12}>
						<List>
							{_.map(orders, i => 
								<ListItem key={i.order_id} className={classes.ListItem}
									onClick={()=>this.startPick(i)}
									style={{backgroundColor: moment(i.order_despatch_date).isSame(moment().format('YYYY/MM/DD'))
									? 'rgb(241, 248, 233)'
									: 'inherit'}}
								>
									<ListItemText 
										primary={i.full_reference} 
										secondary={<>
											{i.order_cust_name}<br/>
											{i.order_delivery_cust_address_name}<br/>
											{i.courier.cour_name}<br/>
											{moment(i.order_despatch_date).isBefore(moment().format('YYYY/MM/DD')) ? 
											<strong style={{color: colors.red}}>{clenyDate(i.order_despatch_date)}</strong> 
											: clenyDate(i.order_despatch_date)}
											</>
										}
									/>
									<ListItemSecondaryAction>
										<AllIcon
											icon={icons.qr}
										/>
									</ListItemSecondaryAction>
								</ListItem>
							)}
						</List>
					</Grid>
				}
				{ searchResults.length > perPage &&
					<Grid item xs={12} style={{marginTop: 'auto'}}>
						<Grid container spacing={1} style={{justifyContent: 'space-between'}}> 
							{page > 0 &&
								<Grid item style={{marginRight: 'auto'}}>
									<Button variant='contained' color='primary'
										onClick={this.prevPage}
									>[F1] Prev</Button>
								</Grid>
							}
							{((page + 1) * perPage) < searchResults.length &&
								<Grid item style={{marginLeft: 'auto'}}>
									<Button variant='contained' color='primary'
										onClick={this.nextPage}
									>Next [F2]</Button>
								</Grid>
							}
						</Grid>
					</Grid>
				}
            </Grid>
		);
  	}
}

const styles = theme => ({
    ListItem: {
		backgroundColor: colors.white, 
		borderRadius: 	 5,
		marginBottom: 	 '0.75em',
		boxShadow: 	  	 '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
	}
});

const mapDespatchToProps = dispatch => ({
	addKeyDown: (func) => dispatch(addKeyDown(func,'OrderPicking')),
	setKeyDown: () => dispatch(setKeyDown('OrderPicking')),
	removeKeyDown: () => dispatch(removeKeyDown('OrderPicking'))
});

export default connect(null, mapDespatchToProps)(withStyles(styles)(OrderPicking));