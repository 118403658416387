import React, { Component } from 'react';
import API                  from 'API';
import { Redirect }         from 'react-router-dom';
import { connect }          from 'react-redux';

import { Typography, Grid, Button, TextField } from '@material-ui/core'; 

import PaddedPaper              from 'Components/Common/Paper/PaddedPaper';
import LoadingCircle            from 'Components/Common/LoadingCircle/LoadingCircle';
import Textarea                 from 'Components/Common/Inputs/Textarea';
import BackButton               from 'Components/Common/Buttons/BackButton';
import { deploySnackBar }       from 'Actions/SnackBar/SnackBar';
import { deployConfirmation }   from 'Actions/Confirmation/Confirmation';
import { handleChange }         from 'Functions/FormFunctions';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = () => ({
    formData: {
        name: '',
        text: ''
    },
    formErrors: [],
    isLoading: true,
    redirect: false
});

class UpdateEngineerEmailTemplates extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
    }

    componentDidMount(){
        this.getTemplate();
    }

    getTemplate = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get(`/engineers/emailTemplates/${this.props.match.params.id}`)
            .then(result => {
                if (result.data.errors) {
                    this.setState({ redirect: true });
                } else if(result.data) {
                    this.setState({
                        ...this.state,
                        formData: {
                            ...this.state.formData,
                            name: result.data.nm,
                            text: result.data.text,
                        },
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            });
        })
    };

    submit = () => {
        API.post(`/engineers/emailTemplates/${this.props.match.params.id}`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.props.deploySnackBar("success", "You have successfully updated the email template");
                this.getTemplate();
            }
            this.props.scrollToTop();
        });
    }
       
    render() {
        const { formErrors } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/engineers/email-templates" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Update Email Template
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                {(this.state.isLoading && (
                                    <LoadingCircle />
                                )) || (
                                    <React.Fragment>
                                        {formErrors && formErrors.generic && (
                                            <React.Fragment>
                                                <Typography component={"div"} style={{color: colors.red}}>
                                                    {formErrors.generic}
                                                </Typography>
                                            </React.Fragment>
                                        )}
                                        <form noValidate autoComplete="off">
                                            <TextField
                                                id="name"
                                                name="name"
                                                label="Name *"
                                                value={this.state.formData.name}
                                                error={formErrors && formErrors['name'] && true}
                                                helperText={formErrors && formErrors['name']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <Textarea
                                                id="text"
                                                name="text"
                                                label="Text *"
                                                value={this.state.formData.text}
                                                rows={10}
                                                error={formErrors && formErrors['text']}
                                                onChange={this.handleChange}
                                            />
                                            <div className='buttonRow'>
                                            <BackButton props={this.props} />
                                                <Button 
                                                    onClick={() => this.props.deployConfirmation('Are you sure you want to update this email template?', 'Update Email Template?', this.submit)}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Update
                                                </Button>
                                            </div>
                                        </form>
                                    </React.Fragment>
                                )}
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    }
}

const mapDispatchToProps = dispatch => ({
    deploySnackBar:     (variant, msg)              => {dispatch(deploySnackBar(variant, msg))},
    deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
})

export default connect(null, mapDispatchToProps)(UpdateEngineerEmailTemplates);