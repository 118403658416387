import React       from 'react';
import API         from 'API';
import { connect } from 'react-redux';
import moment      from 'moment';

import { Typography, Grid } from '@material-ui/core'

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import DataTable     from 'Components/Common/DataTables/DataTable';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';
import BackButton    from 'Components/Common/Buttons/BackButton';

import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import { colors } from 'Helpers/ColourHelper';

const initialState = {
    access: {
        runningTotals: false
    },
    isLoading: false,
    runningTotals: []
}
class BankRunningTotals extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount(){
        this.loadInitialData(this.props.match.params.id);
    }

    loadInitialData = (bankId) => {
        this.setState({
            isLoading: true
        }, () => {
            API.get(`/accounts/banks/${bankId}/runningTotals`)
            .then(bankRes =>  {
                this.setState({
                    runningTotals: bankRes.data,
                    isLoading: false,
                });
            });
        });
    }

    render() {
        const { isLoading, runningTotals } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Running Totals
                    </Typography>
                </Grid>
                {isLoading ? (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                ) : (
                    <>
                    <Grid container item spacing={3}>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <DataTable
                                    config={{
                                        key: 'bank_total_id',
                                        responsiveImportance: true,
                                        pagination: true,
                                        rowsPerPage: 25
                                    }}
                                    columns={[
                                        {
                                            heading: 'Date',
                                            field: rowData => rowData?.monthly_statement ? 
                                                                rowData?.monthly_statement?.bms_month + ' ' + rowData?.monthly_statement?.bms_year 
                                                                : moment(rowData.bank_total_date).format("DD-MM-YYYY"),
                                        },
                                        {
                                            heading: 'Previous Balance',
                                            field: rowData => '£'+rowData.bank_total_previous_balance,
                                            alignment: 'right',
                                        },
                                        {
                                            heading: 'Spent',
                                            field: rowData => rowData?.monthly_statement ? '£'+rowData.bank_total_amount : '-',
                                            alignment: 'right'
                                        },
                                        {
                                            heading: 'Paid',
                                            field: rowData => rowData?.monthly_statement ?  '-' : '£'+rowData.bank_total_amount,
                                            alignment: 'right',
                                            cellProps: rowData => {
                                                return ({
                                                    style: {
                                                        color: rowData.bank_total_amount.includes("-") && colors.red 
                                                    }
                                                });
                                            },
                                        },
                                        {
                                            heading: 'Ending Balance',
                                            field: rowData => '£'+rowData.bank_total_ending_balance,
                                            alignment: 'right',
                                        }
                                    ]}
                                    rows={runningTotals}
                                />
                                <div className='buttonRow'>
                                    <BackButton body={'back'} props={this.props} />
                                </div>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, msg) => {
            dispatch(deploySnackBar(variant, msg))
        }
    }
}

export default connect(null, mapDispatchToProps)(BankRunningTotals);