import { Button, ButtonGroup, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, Checkbox } from '@material-ui/core';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import React from 'react';
import InputHelper from 'Helpers/InputHelper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import _ from 'lodash';
import API from 'API';
import { colors } from 'Helpers/ColourHelper';

const initialState = () => ({
    formData: {
        linkedCustomer: null,

        //manual
        company: [],
        source: [],

        //customer
        addressTypes: [],
        addresses: [],
        servicePlan: 'all',
        customers: [],
        customerStatus: [1,2,3,4],
    },
    isLoading: true,
    lists: {
        addresses: [],
        viewSites: [],
    }
})

class CustomMailingList extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.InputHelper = new InputHelper(this, 'formData', this.checkForms);
    }   

    componentDidMount(){
        this.getAddresses();
    }

    getAddresses = () => {
        API.get('/customers').then((result) => {
            let addresses = [];
            _.each(result.data, i => {
                _.each(i.addresses, a => {
                    a.address_active &&
                        addresses.push({
                            customer_id: a.address_customer_id,
                            id: a.address_id,
                            name: a.address_name,
                            post: a.address_postcode,
                            contacts: a.active_contacts.length,
                            type: a.address_type,
                        });
                })
            })
            
            this.setState({
                lists: {
                    addresses: addresses,
                },
                isLoading: false,
            })
        })
    }

    checkForms = () => {
        let formData = this.state.formData;
        if (formData.linkedCustomer === 'Manual'){
            formData = {
                ...formData,
                customers: [],
                addressTypes: [],
                servicePlan: null,
                customerStatus: [],
            }
        }
        if (formData.linkedCustomer === 'Linked'){
            formData = {
                ...formData,
                company: [],
                source: [],
            }
        }

        this.setState({formData: formData});
    }

    getContacts = () => {
        let contacts = this.props.contacts;
        let formData = this.state.formData;

        if (formData.linkedCustomer === 'Manual'){
            contacts = _.filter(contacts, i => !i.contact_customer_id)
            if (formData.company && formData.company.length > 0){
                contacts = _.filter(contacts, i => _.includes(formData.company, i.contact_company))
            }
            if (formData.source && formData.source.length > 0){
                contacts = _.filter(contacts, i => _.includes(formData.source, i.contact_source))
            }
        }

        if (formData.linkedCustomer === 'Linked'){
            contacts = _.filter(contacts, i => i.contact_customer_id)
            contacts = _.filter(contacts, c => _.filter(c.addresses, {address_active: 1}).length > 0)
            if (formData.servicePlan !== 'all'){
                contacts = _.filter(contacts, i => i.customer?.cust_service_plan ? formData.servicePlan === 'yes' : formData.servicePlan === 'no')
            }
            if (formData.customers && formData.customers.length > 0){
                contacts = _.filter(contacts, i => _.includes(formData.customers, i.contact_customer_id))
            }
            if (formData.addressTypes && formData.addressTypes.length > 0){
                contacts = _.filter(contacts, i => _.find(i.addresses, a => _.includes(formData.addressTypes, a.address_type)))
            }
            if (formData.customerStatus && formData.customerStatus.length > 0){
                contacts = _.filter(contacts, i => _.includes(formData.customerStatus, i.customer?.cust_account_status_id))
            }
            if (formData.addresses && formData.addresses.length > 0){
                contacts = _.filter(contacts, i => _.find(i.addresses, a => _.includes(formData.addresses, a.address_id)))
            }
        }
        this.props.onSubmit(contacts)(formData);
    }

    addAddressType = (e) => {
        this.setState({formData: {
            ...this.state.formData,
            addressTypes: _.map(e, i => i.value),
        }}, this.filterChecked);
    }

    filterChecked = () => {
        this.setState({lists: {
            ...this.state.lists,
            viewSites: _.filter(this.state.lists.addresses, i => 
                this.state.formData.addressTypes.includes(i.type)
                && this.state.formData.customers.includes(i.customer_id)
            ),
        }}, () => {
            this.setState({formData: {
                ...this.state.formData,
                addresses: _.map(this.state.lists.viewSites, i => i.id),
            }});
        })

    }

    addCustomers = (e) => {
        this.setState({formData: {
            ...this.state.formData,
            customers: _.map(e, i => i.value),
        }}, this.filterChecked);
    }

    render() {
        const { formData, isLoading, view } = this.state;
        const { contacts } = this.props;    

        if (isLoading) { return (<LoadingCircle/>) }
        return (
            <Grid item xs={12}>
                <PaddedPaper>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <AutoCompleteSelect
                                label="Contacts Type *"
                                value={formData.linkedCustomer}
                                onChange={this.InputHelper.handleSelectChange('linkedCustomer')}
                                fullWidth
                                options={[
                                    { value: 'Linked', label: 'Customer Contacts Only' },
                                    { value: 'Manual', label: 'Manual Contacts Only' },
                                ]}
                            />
                        </Grid>
                        {formData.linkedCustomer === 'Manual' &&
                            <>
                                <Grid item xs={12}>
                                    <AutoCompleteMultiSelect
                                        label="Source"
                                        name="source"
                                        value={formData.source.length === 0 ? ['All'] : formData.source}
                                        onChange={this.InputHelper.handleMultiSelectChange('source')}
                                        options={[..._.map(_.uniqBy(_.filter(contacts, i => !i.contact_customer_id), 'contact_source'), i => ({value: i.contact_source, label: i.contact_source})), ...(formData.source.length === 0 ? [{
                                            label: 'All', value: 'All'
                                        }] : []) ]}
                                        fullWidth
                                    />
                                </Grid>
                            </>
                        }
                        {formData.linkedCustomer === 'Linked' &&
                            <>
                                {
                                    <>
                                        <Grid item xs={12}>
                                            <AutoCompleteMultiSelect
                                                label="Customers"
                                                name="customers"
                                                value={formData.customers}
                                                onChange={this.addCustomers}
                                                options={_.map(_.uniqBy(
                                                        _.filter(contacts, 
                                                            i => 
                                                                i.contact_customer_id 
                                                                && i.customer?.cust_name
                                                            )
                                                    , i => i.contact_customer_id), ({customer}) => ({
                                                    label: customer?.cust_name, 
                                                    value: customer?.cust_id,
                                                }))}
                                                fullWidth
                                                
                                            />
                                        </Grid>
                                        {formData.customers.length > 0 &&
                                            <>
                                                <Grid item xs={12}>
                                                    <AutoCompleteMultiSelect
                                                        label="Address Types"
                                                        name="addressTypes"
                                                        value={formData.addressTypes}
                                                        onChange={this.addAddressType}
                                                        options={[
                                                            { value: "Registered", label: "Registered" },
                                                            { value: "Trading", label: "Trading" },
                                                            { value: 'Site', label: 'Site' },
                                                        ]}
                                                        fullWidth
                                                        noSort
                                                    />
                                                </Grid>
                                                {formData.addressTypes.length > 0 &&
                                                    <>
                                                        <Grid item xs={12} style={{border: `1px solid ${colors.disabled}`, borderRadius: 5, marginTop: '0.75em'}}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableCell style={{width: 42, padding: 0, textAlign: 'center', fontWeight: 'bold'}}>#</TableCell>
                                                                    <TableCell style={{width: '20%', padding: 0,  fontWeight: 'bold', paddingLeft: 10}}>Type</TableCell>
                                                                    <TableCell style={{fontWeight: 'bold'}}>Address</TableCell>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {_.map(_.orderBy(this.state.lists.viewSites, [i => ['Registered', 'Trading', 'Site'].indexOf(i.type), 'name'], ['asc', 'asc'] ), a =>
                                                                        <TableRow>
                                                                            <TableCell style={{padding: 0, textAlign: 'center'}}>
                                                                                <Checkbox
                                                                                    checked={formData.addresses.includes(a.id)}
                                                                                    onClick={()=>this.InputHelper.handleArrayAddRemove('addresses')(a.id)}
                                                                                    color='primary'
                                                                                    disabled={a.type !== 'Site'}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell style={{padding: 0, paddingLeft: 10}}>
                                                                                {a.type}
                                                                            </TableCell>
                                                                            <TableCell style={{padding: 0}}>
                                                                                {a.name} {a.post && `(${a.post})`}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }

                            </>
                        }
                        <Grid item xs={12} className='buttonRow'>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    disabled={
                                        !formData.linkedCustomer || 
                                        (formData.linkedCustomer === 'Linked' && (formData.customers.length < 1 || formData.addresses.length < 1)) 
                                    }
                                    onClick={this.getContacts}
                                >Get Contacts</Button>
                        </Grid>
                    </Grid>
                </PaddedPaper>
            </Grid>
        )
    }
}

export default CustomMailingList;