import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import React,{ Component } from 'react';
import { connect } from 'react-redux';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';

import { Button, FormControl, Grid, Typography } from '@material-ui/core/';


import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = {
    isLoading: true,
    formData: {
        date:       null,
        template:   0
    },
    stockMovements: [],
    formErrors: [],
    templates:  []
}


class OutstandingStockMovements extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => { this.getData(); }

    handleDateChange = field => e => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: moment(e).format('YYYY-MM-DD')
            }
        }); 
    }

    submitStockMovement = () => {
        API.post(`/stock/movement`, this.state.formData)
        .then( res => { window.location.href = `/stock/movement/edit/${res.data.sm_id}`})
    }

    getData = () => {
        let params = {active: true};
        Promise.all([
            API.get(`/stock/movement/all`,{params}),
            API.get(`/stock/movement/templates/all`)
        ]).then(([movementRes, templatesRes]) => {
            this.setState({
                stockMovements: movementRes.data,
                templates:      [
                    {value: 0, label: 'No Template'},
                    ..._.map(templatesRes.data, i => _.assign({value: i.smt_id, label: i.smt_name}))
                ],
                isLoading: false
            });
        })
    }

    handleSelectChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                template: e ? e.value : 0
            }
        }); 
    }

    delete = id => {
        API.post(`/stock/movement/${id}/delete`)
        .then( res => {this.getData();})
    }

    render() {

        const {formData, isLoading, formErrors, stockMovements, templates} = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <Typography variant='h6'>Add Stock Movement</Typography>
                        <DatePicker
                            type='date'
                            id="date"
                            name="date"
                            label="Date"
                            autoOk={true}
                            value={formData.date}
                            onChange={this.handleDateChange('date')}
                            error={formErrors && formErrors['dateFrom'] && true}
                            errorText={formErrors && formErrors['dateFrom']}
                            noMargin
                            disableFuture
                        />
                        <FormControl fullWidth margin="normal">
                            <AutoCompleteSelect 
                                options={templates} 
                                label='Template *'
                                onChange={this.handleSelectChange}
                                value={formData.template}
                            />
                        </FormControl>
                        <div className='buttonRow'>
                            <Button variant='contained' color='primary' onClick={this.submitStockMovement}>Add</Button>
                        </div>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    {isLoading ? <LoadingCircle/>: 
                        <DataTable
                            config={{
                                key: 'sm_id',
                                alternatingRowColours:  true,
                                responsiveimportant:    true,
                                pagination:             true,
                                filterMod: {
                                    filterInPagination:     true,
                                    colSpan:                3,
                                    clearable:              true,
                                },
                                style: rowData => ({
                                    color: moment(rowData.sm_date).diff(moment(),'days') < 0 && colors.red
                                })
                            }}
                            columns={[
                                {
                                    heading:    'Date',
                                    field:      rowData => clenyDate(rowData.sm_date),
                                    dataRef:    'sm_date',
                                    sizeToContent: true,
                                    filter:     'date',
                                    filterMod: {displayOrder: 1}
                                },
                                {
                                    heading:        'Created By',
                                    field:          rowData => rowData.sm_created_by,
                                    dataRef:        'sm_created_by',
                                    sizeToContent:  true,
                                    filter:         'custom',
                                    filterMod: {
                                        dataRef: (search, rowData) => {return `${rowData.sm_created_by}|${rowData.sm_ref}|${rowData.sm_template_id ? rowData.template.smt_name  : ''}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                        label:     'Keywords',
                                        styleGrid: {width: '50%'}
                                    }
                                },
                                {
                                    heading:    'Reference',
                                    field:      rowData => rowData.sm_ref,
                                    dataRef:    'sm_ref',
                                    truncate:   true
                                },
                                {
                                    heading:    'Template',
                                    field:          rowData => rowData.sm_template_id && !rowData.sm_modified ,
                                    fieldFormat:    'boolean',
                                    dataRef:        rowData => rowData.sm_template_id && !rowData.sm_modified ,
                                    alignment:      'center',
                                    sizeToContent:  true,
                                },
                                {
                                    heading:        'Manual',
                                    field:          rowData => !rowData.sm_template_id ,
                                    fieldFormat:    'boolean',
                                    dataRef:        rowData => !rowData.sm_template_id ,
                                    alignment:      'center',
                                    sizeToContent:  true,
                                },
                                {
                                    heading:        'Template/Manual',
                                    field:          rowData => rowData.sm_template_id && rowData.sm_modified ,
                                    fieldFormat:    'boolean',
                                    dataRef:        rowData => rowData.sm_template_id && rowData.sm_modified,
                                    alignment:      'center',
                                    sizeToContent:  true,
                                },
                                {
                                    heading:    'Overdue Days',
                                    field:      rowData => moment(rowData.sm_date).diff(moment().startOf('day'),'days'),
                                    sizeToContent: true,
                                    alignment: 'center',
                                    dataRef:    rowData => moment(rowData.sm_date).diff(moment().startOf('day'),'days'),
                                },
                                {
                                    heading:    'Parts',
                                    field:      rowData => rowData.grouped.length,
                                    sizeToContent: true,
                                    alignment: 'center',
                                    dataRef:    rowData => rowData.grouped.length,
                                },
                                {
                                    heading:    'Status',
                                    field:      rowData => {
                                        const diff = moment(rowData.sm_date).diff(moment().startOf('day'),'days');

                                        if (diff == 0) return (
                                            <AllIcon icon={icons.today} color={colors.orange} tooltip='Due'/>
                                        );

                                        if (diff > 0) return (
                                            <AllIcon icon={icons.due} color={colors.green} tooltip='Future'/>
                                        );

                                        if (diff < 0) return (
                                            <AllIcon icon={icons.warning} color={colors.red} tooltip='OverDue'/>
                                        );
                                    
                                    },
                                    sizeToContent: true,
                                    alignment: 'center',
                                    dataRef:    rowData => moment(rowData.sm_date).diff(moment().startOf('day'),'days'),
                                },
                                {
                                    actions: rowData => {
                                        return rowData.locked ? 
                                            [{name: 'Locked', icon: icons.lock, onClick:()=>{}}]:
                                            [
                                                {name: 'Delete', icon: icons.delete, onClick: () => { 
                                                    this.props.deployConfirmation(
                                                        'Are you sure you want to delete this stock movement?',
                                                        'Delete Stock Movement',
                                                        ()=>{this.delete(rowData.sm_id)}
                                                    ) }},
                                                {name: 'Edit',   icon: icons.edit, link: { pathname: '/stock/movement/edit/' + rowData.sm_id }, disabled: moment(rowData.sm_date).diff(moment(),'days') > 0}
                                            ]
                                    }
                                }
                            ]}
                            rows={_.orderBy(stockMovements, ['sm_date', 'sm_id'],['desc', 'desc'])}
                        />
                    }
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation:     (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
})


export default connect(null,mapDispatchToProps)(OutstandingStockMovements);
