import React, { Component } from 'react';
import _                    from 'lodash';

import DataTable   from 'Components/Common/DataTables/CiDataTable';
import AllIcon     from 'Components/Common/Icons/AllIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import { Grid, Typography } from '@material-ui/core/';

const initialState = {
    worksOrder:     {}, 
    additionals:    [],
}

function InfoBox({heading, value, icon}) {
    return (
        <Grid item xs={6} lg={3}>
            <PaddedPaper style={{textAlign:'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <AllIcon icon={icon} size='xlarge' />
                <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>{heading}</Typography>
                <Typography variant="h4" style={{textAlign:'center', color: colors.green, marginTop: 'auto'}}>{value}</Typography>
            </PaddedPaper>
        </Grid>
    )
}
class BuildAdditionals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState, 
            worksOrder:       this.props.worksOrder,
            additionals:      this.props.worksOrder.additionals,
        };
    }
    
    render(){

        const { worksOrder, additionals } = this.state;

        let inWo = _.map(_.filter(additionals, j => _.map(worksOrder.details, d => d.wod_part_id).includes(j.wobas_part_id)), i => {
            return {
                ...i,
                anr:  (_.find(worksOrder.pick.details, {wopd_part_id: i.wobas_part_id})?.wopd_status === 'Not Required') || (_.find(worksOrder.shortage?.details, {wosd_part_id: i.wobas_part_id})?.wopd_status === 'Not Required')
            }
        })

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={3}>

                            <Grid item container spacing={3} xs={12} style={{justifyContent: 'center'}}>
                                <InfoBox heading='Added Parts' value={_.uniqBy(additionals, 'wobas_part_id').length} icon={icons.parts} />
                                <InfoBox heading={<>In Works Order Parts<br/>(Extra Stock)</>} value={_.filter(_.uniqBy(inWo, 'wobas_part_id'), j => !j.anr).length} icon={icons.link} />
                                <InfoBox heading={<>In Works Order Parts<br/>(After Not Required)</>} value={_.filter(_.uniqBy(inWo, 'wobas_part_id'), j => j.anr).length} icon={icons.link} />
                                <InfoBox heading='Not In Works Order Parts' value={_.filter(_.uniqBy(additionals, 'wobas_part_id'), j => !_.find(worksOrder.details, {wod_part_id: j.wobas_part_id})).length} icon={icons.unlink} />
                                <InfoBox heading='Quantity' value={`+${parseFloat(_.sumBy(additionals, i => parseFloat(i.wobas_qty))).toFixed(3)}`} icon={icons.parts} />
                                <InfoBox heading={<>In Works Order Quantity<br/>(Extra Stock)</>} value={`+${parseFloat(_.sumBy(_.filter(inWo, j => !j.anr), i => parseFloat(i.wobas_qty))).toFixed(3)}`}  icon={icons.link} />
                                <InfoBox heading={<>In Works Order Quantity<br/>(After Not Required)</>} value={`+${parseFloat(_.sumBy(_.filter(inWo, j => j.anr), i => parseFloat(i.wobas_qty))).toFixed(3)}`}  icon={icons.link} />
                                <InfoBox heading='Not In Works Order Quantity' value={`+${parseFloat(_.sumBy(_.filter(additionals, j => !_.find(worksOrder.details, {wod_part_id: j.wobas_part_id})), i => parseFloat(i.wobas_qty))).toFixed(3)}`} icon={icons.unlink} />
                            </Grid>

                            {_.map(_.uniqBy(additionals,'wobas_wobs_id'), i => 
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6" style={{marginBottom: 15}}>Stage {i.build_stage.instruction.sais_number}: {i.build_stage.instruction.sais_name}</ Typography>
                                        <DataTable
                                            config={{
                                                key: 'wobas_id',
                                                dropRow: {
                                                    hideDropArrow: true,
                                                    droppable:     i => i.additionals.length > 0,
                                                    style:         {fontWeight: 'bold'},
                                                    columns:       [
                                                        {
                                                            heading:       'Date',
                                                            field:         'wobas_datetime',
                                                            fieldFormat:   'clenydate',
                                                            sizeToContent: true,
                                                        }, 
                                                        {
                                                            heading:     'reason',
                                                            field:       'wobas_reason',
                                                        },
                                                        {
                                                            heading:     'Notes',
                                                            field:       i => <box style={{whiteSpace: 'initial'}}>{i.wobas_notes}</box>,
                                                            style:       { whiteSpace: 'initial'},
                                                        },
                                                        {
                                                            heading:       'Qty',
                                                            field:         'wobas_qty',
                                                            sizeToContent: true,
                                                            alignment:     'center',
                                                            style:         {color: colors.green},
                                                        },
                                                        {
                                                            heading:       'Staff',
                                                            field:         'wobas_staff_name',
                                                            sizeToContent: true,
                                                        },
                                                        
                                                    ],
                                                    data: 'additionals'
                                                }
                                            }}
                                            columns={[
                                                {
                                                    field:          'part.default_image.file_path',
                                                    fieldFormat:    'image',
                                                    sizeToContent:  true
                                                },
                                                {
                                                    heading:       'Part Number',
                                                    field:         'part.part_number',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading:       'Part Description',
                                                    field:         'part.part_description',
                                                },
                                                {
                                                    Header:        '',
                                                    field:         rowData => !_.map(worksOrder.details, d => d.wod_part_id).includes(rowData.wobas_part_id) ? 
                                                                                <AllIcon icon={icons.unlink} color={colors.green} tooltip='Part Not In Original Assembly' /> :
                                                                                <AllIcon icon={icons.link} color={colors.green} tooltip='Part In Original Assembly' /> ,
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading:       'Qty',
                                                    field:         'qty',
                                                    fieldPrefix:   '+',
                                                    style:         {color: colors.green},
                                                    sizeToContent: true,
                                                },
                                            ]}
                                            rows={_.orderBy(_.map(_.uniqBy(_.filter(additionals, {wobas_wobs_id: i.wobas_wobs_id}), i => i.part.part_id), i => {
                                                let adds = _.filter(additionals, {wobas_wobs_id: i.wobas_wobs_id, wobas_part_id: i.wobas_part_id});
                                                return {...i, additionals: adds, qty: parseFloat(_.sumBy(adds, j => parseFloat(j.wobas_qty))).toFixed(3)} 
                                            }), i => i.part.part_number )}
                                        />
                                    </PaddedPaper>
                                </Grid>
                            )}
                        </Grid>
                    </PaddedPaper>
                </Grid>
            </Grid>
        )
    }
}

export default BuildAdditionals;