import * as printActionTypes from '../Print/Types/Types';

function _print(printer, data, fileName=''){

    let file = ( data instanceof File || data instanceof Blob ) ? data : null;
    let url  = ( !file ) ? data : null;

    return async (dispatch) => {
        try {
            dispatch({
                type:    printActionTypes.PRINT,
                payload: { printer, url, file, fileName }
            });
        } catch (err) {
        }
    };
}

export function closePrint() {
    return async (dispatch) => {
        try {
            dispatch({
                type:    printActionTypes.PRINT_CLOSE,
                payload: {}
            });
        } catch (err) {
        }
    };
}

export function printA4(data, fileName='') {
    return _print(printActionTypes.PRINT_A4, data, fileName);
}

export function printLabel(data, fileName='') {
    return _print(printActionTypes.PRINT_LABEL, data, fileName);
}

export function printMulti(data) {
    //[{printer, file, url}]
    return async (dispatch) => {
        try {
            dispatch({
                type:    printActionTypes.PRINT_MULTI,
                payload: data 
            });
        } catch (err) {
        }
    };
}

export function print(printer, data){
    return _print(printer, data);
}

