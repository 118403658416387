import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DatePicker from '../../../Common/DatePickers/DatePicker';
import moment from 'moment';
import API from './../../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../../Helpers/ErrorHelper';
import ConfirmationDialog from '../../../Common/Dialogs/ConfirmationDialog';
import SnackBar from '../../../Common/SnackBars/SnackBar';
import AutoCompleteSelect from '../../../Common/Selects/AutoCompleteSelect';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import Textarea from '../../../Common/Inputs/Textarea';
import {colors} from 'Helpers/ColourHelper';
import SelectPartWithQR from 'Components/Common/Selects/SelectPartWithQR';

const initialState = {
    formData: {
        type: '',
        writeOffDate: moment().toString(),
        part: '',
        currentStock: '-',
        stockQuantity: '-',
        writeOffQuantity: '0.00',
        writeOffValue: '-',
        writeOffReason: '',
        notes: '', 
    },
    reasonList: [],
    partList: [],
    parts: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
}

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    
}))(TableCell);


class AddStockWriteOff extends Component {
    constructor(props) {
        super(props);
    
        this.state = initialState;
    }

    componentDidMount(){
        this.getWriteOffReasons();
        this.getParts(); 
    }

    getParts = () => {        
        API.get('/parts/withStock')
        .then((result) => {
            let partList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description,
                    part:  el
                });
            });
            this.setState({
                partList: partList,
                parts: result.data
            });
        })
    }

    getWriteOffReasons = () => {
        API.get('/stock/writeOff/defaultReasons')
        .then(result => {
            if(result.data) {
                let reasonList = _.map(result.data, (el) => {
                    return _.assign({
                        value: el.swo_default_reason,
                        label: el.swo_default_reason
                    });
                });
                this.setState({
                    reasonList: reasonList
                });
            }
        });
    }

    handleDateChange = date => {
        this.setState({
            formData: {
                ...this.state.formData,
                writeOffDate: date
            }
        },
        () => {
            this.getStockDetails();
        });
    };

    handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false }); 
    };

    submit = () => {
        const formData = {
            ...this.state.formData,
            writeOffDate: (moment(new Date(this.state.formData.writeOffDate)).format('DD/MM/YYYY'))
        }
        API.post('/stock/writeOff', formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState(initialState);
                this.setState({
                    snackbarOpen: true
                });
                this.getWriteOffReasons();
                this.getParts();
            }
            this.props.scrollToTop();
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    getStockDetails = () => {
        let partID = this.state.formData.part;
        if(partID) {
            let part = _.find(this.state.parts, { 'part_id': partID });

            Promise.all([ 
                API.get(`/parts/${partID}/costs/byDate`, {
                    params: {
                        date: (moment(new Date(this.state.formData.writeOffDate)).format('YYYY-MM-DD'))
                    }
                }), 
                API.get(`/stock/${partID}/byDate`, {
                    params: {
                        date: (moment(new Date(this.state.formData.writeOffDate)).format('YYYY-MM-DD'))
                    }
                }),
            ])
            .then(([cost, stock]) =>  {
                if(cost.data) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            currentStock: part.stock.stock_current_qty,
                            stockQuantity: stock.data.stock_activity_new_qty,
                            writeOffValue: (cost.data.cost_per_unit * this.state.formData.writeOffQuantity).toFixed(5),
                        }
                    });
                }
            });
        }
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, 
        () => {
            if(fieldName === 'part'){
                this.getStockDetails();
            }
        });
    };

    handleQuantityChange = decimals => e => {
        const { name, value } = e.target;
        let newVal = parseFloat(value).toFixed(decimals);
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: newVal
            }
        },
        () => {
            this.getStockDetails();
        });
    }
       
    render() {
        const { formErrors } = this.state;
        const loggedInStaff = this.props.loggedInStaff !== undefined ? this.props.loggedInStaff.firstName + ' ' + this.props.loggedInStaff.lastName : '';
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Stock Write Off
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6" >
                                Main Details
                            </Typography>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <form noValidate autoComplete="off">
                                <FormControl error={formErrors && formErrors['type'] && true} fullWidth margin="normal">
                                    <AutoCompleteSelect
                                        options={[
                                            {value: 'Manual', label: 'Manual'}
                                        ]}
                                        label='Type *'
                                        value={this.state.formData.type}
                                        onChange={this.handleSelectChange('type')}
                                        error={formErrors && formErrors['type'] && true}
                                        errorText={formErrors && formErrors['type'] && formErrors['type']}
                                    />
                                </FormControl>
                                <DatePicker
                                    type="date"
                                    id="writeOffDate"
                                    name="writeOffDate"
                                    label="Write Off Date *"
                                    value={this.state.formData.writeOffDate}
                                    errorText={formErrors && formErrors['writeOffDate']}
                                    onChange={this.handleDateChange}
                                    disableFuture={true}
                                />
                                <TextField
                                    label="Raised By"
                                    value={loggedInStaff}
                                    margin="normal"
                                    fullWidth
                                    disabled
                                />
                                <Typography variant="h6" style={{marginTop:24}} gutterBottom>
                                    Affected Parts
                                </Typography>
                                <SelectPartWithQR
                                    parts=      {this.state.partList}
                                    label=      'Part Number *'
                                    onChange=   {this.handleSelectChange('part')}
                                    error=      {formErrors && formErrors['part'] && true}
                                    value=      {this.state.formData.part}
                                    margin=     'none'
                                />
                                <FormControl margin="normal" fullWidth>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell style={{textAlign:'center'}}>Current Stock</CustomTableCell>
                                                <CustomTableCell style={{textAlign:'center'}}>Stock Qty <br></br>Based on write off date</CustomTableCell>
                                                <CustomTableCell style={{textAlign:'center'}}>Write Off Quantity</CustomTableCell>
                                                <CustomTableCell style={{textAlign:'center'}}>Write Off Value <br></br>Based on write off date</CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{textAlign:'center'}}>{this.state.formData.currentStock}</TableCell>
                                                <TableCell style={{textAlign:'center'}}>{this.state.formData.stockQuantity}</TableCell>
                                                <TableCell style={{textAlign:'center'}}>
                                                    <TextField
                                                        name="writeOffQuantity"
                                                        value={this.state.formData.writeOffQuantity}
                                                        error={formErrors && formErrors['writeOffQuantity'] && true}
                                                        helperText={formErrors && formErrors['writeOffQuantity']}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleQuantityChange(3)}
                                                        type="number"
                                                        margin="normal"
                                                        inputProps={{
                                                            style: {textAlign:'center'},
                                                            min: 0
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell style={{textAlign:'center'}}>{this.state.formData.writeOffValue === '-' ? '' : '£'}{this.state.formData.writeOffValue}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.reasonList} 
                                        label='Write Off Reason *'
                                        onChange={this.handleSelectChange('writeOffReason')}
                                        error={formErrors && formErrors['writeOffReason'] && true}
                                        errorText={formErrors && formErrors['writeOffReason']}
                                        value={this.state.formData.writeOffReason}
                                    />
                                </FormControl>
                                <Textarea
                                    id="notes"
                                    name="notes"
                                    label="Notes *"
                                    value={this.state.formData.notes}
                                    rows={10}
                                    error={formErrors && formErrors['notes']}
                                    onChange={this.handleChange}
                                />
                            </form>
                            <div className='buttonRow'>
                                <Button onClick={this.handleConfirmationOpen}
                                        variant="contained"
                                        color="primary">
                                    Add
                                </Button>
                            </div>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Add This Stock Write Off?" 
                    message="Are you sure you want to add this stock write off?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added a new stock write off'
                />
            </Grid>
        );
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff
    };
}

export default connect(mapStateToProps)(AddStockWriteOff);
