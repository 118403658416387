import React, { Component }      from 'react';
import _                         from 'lodash';
import { connect }               from 'react-redux';
import API                       from 'API';
import { MAX_UPLOAD_LIMIT_SIZE } from 'Constants';

import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Link, Radio, 
    RadioGroup, TextField, Typography} from '@material-ui/core'; 

import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';

import { colors }                 from 'Helpers/ColourHelper';
import icons                      from 'Helpers/IconHelper';
import { statusColour }           from 'Helpers/ColourHelper';
import { pdfFromBase64 }          from 'Helpers/PDFHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import FALightIcon        from 'Components/Common/Icons/FontAwesome/FALightIcon';
import DragFileInput      from 'Components/Common/Inputs/DragFileInput';
import Textarea           from 'Components/Common/Inputs/Textarea';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import SnackBar           from 'Components/Common/SnackBars/SnackBar';

import StatusDataTable from '../StatusDataTable';

const initialState = () => ({
    formData: {
        sr_id: '',
        notes: '',
        emailChoice: 'noEmail',
        additionalContacts :[],
        dispatchRequired: '',
        courier: '',
        trackingNumber: '',
        dispatchNoteFile: '',
        supplierContactEmail: '',
    },
    couriers: {},
    courierList: [],
    reference: '',
    supplierContact: '',
    courierSupplierName:'',
    trackingLink: '',
    showHideDispatch: false,
    disableEmailChoice: true,
    additionalContactList: [],
    showHideAdditionalContacts: false,
    keywords: '',
    ssr: {},
    searchResults: {},
    formErrors: [],
    dialogOpen: false,
    snackbarOpen: false,
    errorSnackbarOpen: false,
    snackbarErrorMsg: '',
    isLoading: true
});

class SupplierStockReturnDispatch extends Component {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'SupplierStockReturn:Dispatch';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        !this.hasPageState() && this.loadComponentData();
    }

    loadComponentData = () => {
        this.getSearchData();
        this.getCouriers();
    }

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            const params = {
                keywords: this.state.keywords,
                status: 'Dispatch'
            }
            API.get('/supplierStockReturns', {
                params,
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    _.map(result.data, (ssr) => {
                        ssr.colour = statusColour(ssr.sr_status);
                    });
                    this.setState({
                        searchResults: result.data,
                        isLoading: false
                    },()=>this.savePageState())
                }
            })
        });
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
        }, 
        () => {
            this.getSearchData();
        });
    }

    handleErrorSnackbarClose = () => {this.setState({ errorSnackbarOpen: false }); };

    handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        },
        () => {
            this.setState({ 
                showHideAdditionalContacts : (this.state.formData.emailChoice === 'emailMultipleSuppliers' ? true : false),
                showHideDispatch: (this.state.formData.dispatchRequired === 'Yes' ? true : false),
                disableEmailChoice: (this.state.formData.dispatchRequired === 'No' ? true : false),
                formData: {
                    ...this.state.formData,
                    emailChoice: (this.state.formData.dispatchRequired === 'Yes' ? this.state.formData.emailChoice : 'noEmail'),
                }
            }, 
            () => {
                this.trackingUrl();
            });
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if(fieldName === 'courier'){
                this.trackingUrl();
            }
        });
    }

    handleDialogOpen = rowData => {
        API.get('/supplierStockReturns/' + rowData.sr_id)
        .then(result => {
            let additional = [];
            if(result.data.supplier_contacts){
                result.data.supplier_contacts.forEach(val => {
                    if(val.supp_cont_id !== result.data.sr_supplier_contact_id){
                        additional.push(val);
                    }
                });
            }
            this.setState({
                dialogOpen: true,
                ssr: result.data,
                additionalContactList: additional,
                reference: result.data.full_reference,
                supplierContact: result.data.sr_supplier_contact,
                formData: {
                    ...this.state.formData,
                    sr_id: rowData.sr_id,
                    supplierContactEmail: result.data.supplier_contact.supp_cont_email,
                }
            });
        });
        
    };
    handleDialogClose = () => {
        this.setState({
            ...initialState()
        });        
        this.getSearchData();
        this.getCouriers();
    }
    handleSnackbarClose = () => {this.setState({ snackbarOpen: false }); };
    handleSuccess = () => {
        this.setState({ dialogOpen: false });
        this.submit();
    }
    submit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            if(key === 'additionalContacts'){
                newFormData.append(key, JSON.stringify(this.state.formData[key]))
            }
            else{
                newFormData.append(key, this.state.formData[key])
            }
        });
        API.post(`/supplierStockReturns/${this.state.formData.sr_id}/dispatch`, newFormData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    snackbarOpen: true
                },
                () => {
                    if(result.data.emailErrors && result.data.emailErrors.length > 0){
                        this.setState({
                            errorSnackbarOpen: true,
                            snackbarErrorMsg: 'Email failed to send to the following additional contacts:' + result.data.emailErrors.join()
                        });
                    }
                });
                
                this.getSearchData();
                this.getCouriers();
            }
        });
    }

    downloadFile = rowData => {
        API.get('/supplierStockReturns/' + rowData.sr_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    handleCheckChange = (e) => {
        let newArray = [...this.state.formData.additionalContacts];

        if(e.target.checked){
            newArray.push(e.target.value);
        } else {
            newArray.splice(e.target.value, 1)
        }
        
        this.setState({
            formData: {
                ...this.state.formData,
                additionalContacts: newArray
            }
        });
    };

    getCouriers = () => {
        API.get('/suppliers/couriersOnly')
        .then((result) => {
            let courierList = _.map(result.data, el => {
                return _.assign({
                    value: el.supp_id,
                    label: el.supp_company_name
                });
            });
            this.setState({
                couriers: result.data,
                courierList: courierList
            });
        });
    }

    trackingUrl = () => {
        let details = _.find(this.state.couriers, { 'supp_id': this.state.formData.courier });
        this.setState({
            courierSupplierName: details ? <b>{details.supp_company_name}</b> : '',
            trackingLink: details ? details.supp_tracking_url && this.state.formData.trackingNumber && 
            <Typography variant="body2">
                You can track the delivery via the following link:<br></br>
                <Link href={details.supp_tracking_url+this.state.formData.trackingNumber} variant="body1" className='blueLink' target='_blank' rel="noopener">
                    {details.supp_tracking_url+this.state.formData.trackingNumber}
                </Link>
            </Typography> : ''
        });
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    clearFile = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                dispatchNoteFile: initialState().formData.dispatchNoteFile
            }
        });
    };

    render() {
        const { formErrors } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Supplier Stock Return Dispatch
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={this.state.keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <StatusDataTable 
                            isLoading       = {this.state.isLoading}
                            searchResults   = {this.state.searchResults}
                            persistenceId   = {this.persistenceId}
                            actions={ rowData => {
                                return [
                                    {name: 'Download', icon: 'download', onClick: this.downloadFile},
                                    rowData.locked ?
                                        {name: 'Locked',  icon: icons.lock, onClick: ()=>{}} :
                                        {name: 'Approve', icon: 'check',    onClick: this.handleDialogOpen}
                                ]
                            }}
                        />
                    </PaddedPaper>
                </Grid>

                <Dialog 
                    open={this.state.dialogOpen} 
                    onClose={this.handleDialogClose} 
                    fullWidth={true} 
                    maxWidth="lg" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogContent>
                        {formErrors && formErrors['stockReturnDetails'] && formErrors['stockReturnDetails'].split('\n').map((item, key) => {
                            return (
                                <React.Fragment key={key}>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {item}<br/>
                                    </Typography>
                                </React.Fragment>
                            )
                        })}
                        <Grid container spacing={3}>
                            <Grid item xs={6} style={{borderRight: '1px solid #ddd'}}>
                                <Typography variant="h6" gutterBottom>Courier Details</Typography>
                                <FormControl fullWidth margin="normal" error={formErrors && formErrors['dispatchRequired'] && true}>
                                    <FormLabel >Dispatch Required ?</FormLabel>
                                    <RadioGroup
                                        name="dispatchRequired"
                                        value={this.state.formData.dispatchRequired}
                                        onChange={this.handleChange}
                                        row
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio color="primary" />}
                                            label="Yes"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio color="primary" />}
                                            label="No"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                    {formErrors && formErrors['dispatchRequired'] && <FormHelperText>{formErrors['dispatchRequired']}</FormHelperText>}
                                </FormControl>
                                {this.state.showHideDispatch &&
                                    <React.Fragment>
                                        <FormControl error={formErrors && formErrors['courier'] && true} fullWidth margin="normal">
                                            <AutoCompleteSelect
                                                options={this.state.courierList} 
                                                label='Courier *'
                                                value={this.state.formData.courier}
                                                onChange={this.handleSelectChange('courier')}
                                                error={formErrors && formErrors['courier'] && true}
                                                errorText={formErrors && formErrors['courier'] && formErrors['courier']}
                                            />
                                        </FormControl>
                                        <TextField
                                            id="trackingNumber"
                                            name="trackingNumber"
                                            label="Tracking Number"
                                            value={this.state.formData.trackingNumber}
                                            error={formErrors && formErrors['trackingNumber'] && true}
                                            helperText={formErrors && formErrors['trackingNumber']}
                                            onChange={this.handleChange}
                                            onBlur={this.trackingUrl}
                                            margin="normal"
                                            fullWidth
                                        />
                                        <small style={{color:'#666'}}>The tracking url will appear once you enter the tracking number.<br></br> If it doesn't, check the supplier has a tracking url.</small>
                                        <DragFileInput
                                            id="dispatchNoteFile"
                                            name="dispatchNoteFile"
                                            label="Dispatch Note"
                                            file={this.state.formData.dispatchNoteFile}
                                            errorText={formErrors && formErrors['dispatchNoteFile']}
                                            onChange={this.handleFileChange}
                                            cancelOnClick={this.clearFile}
                                            emptyText='No file selected'
                                        />
                                    </React.Fragment>
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6" gutterBottom>Email Supplier</Typography>
                                <TextField
                                    id="supplierContactEmail"
                                    name="supplierContactEmail"
                                    label="To"
                                    value={this.state.formData.supplierContactEmail}
                                    margin="normal"
                                    fullWidth
                                    disabled
                                />
                                <PaddedPaper style={{backgroundColor:'#f2f2f2'}}>
                                    <Typography variant="body2" gutterBottom>
                                        Dear {this.state.supplierContact},<br></br><br></br>
                                        Reference: Supplier Stock Return {this.state.reference}<br></br><br></br>
                                        We thought you would like to know that we have now dispatched the part(s) detailed in the attached Supplier Stock Return Form. 
                                        The part(s) will arrive via the courier {this.state.courierSupplierName},<br></br><br></br>
                                        {this.state.trackingLink}
                                        Should you have any questions regarding this Supplier Stock Return, then please contact us via the details below.
                                    </Typography>
                                </PaddedPaper>
                                <RadioGroup
                                    name="emailChoice"
                                    value={this.state.formData.emailChoice}
                                    onChange={this.handleChange}
                                    row
                                >
                                    <FormControlLabel
                                        value="emailSingleSupplier"
                                        checked={this.state.formData.emailChoice === "emailSingleSupplier" ? true : false}
                                        control={<Radio color="primary" />}
                                        label="Email contact above"
                                        labelPlacement="end"
                                        disabled={this.state.disableEmailChoice}
                                    />
                                    <FormControlLabel
                                        value="emailMultipleSuppliers"
                                        checked={this.state.formData.emailChoice === "emailMultipleSuppliers" ? true : false}
                                        control={<Radio color="primary" />}
                                        label="Email additional contacts"
                                        labelPlacement="end"
                                        disabled={this.state.disableEmailChoice}
                                    />                                    
                                    <FormControlLabel
                                        value="noEmail"
                                        checked={this.state.formData.emailChoice === "noEmail" ? true : false}
                                        control={<Radio color="primary" />}
                                        label="Do not send email"
                                        labelPlacement="end"
                                        disabled={this.state.disableEmailChoice}
                                    />
                                </RadioGroup>
                                {this.state.showHideAdditionalContacts &&
                                    <FormControl component="fieldset">
                                        <FormGroup>
                                            {this.state.additionalContactList && this.state.additionalContactList.map(v => (
                                                <FormControlLabel key={v.supp_cont_id}
                                                    control={
                                                        <Checkbox 
                                                            onChange={this.handleCheckChange} 
                                                            value={v.supp_cont_email}
                                                            color='primary'
                                                        />
                                                    }
                                                    label= {`${v.supp_cont_first_name} ${v.supp_cont_last_name} (${v.supp_cont_email})`}
                                                />
                                            ))}
                                        </FormGroup>
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Textarea
                                    id="notes"
                                    name="notes"
                                    label="Notes"
                                    value={this.state.formData.notes}
                                    rows={5}
                                    error={formErrors && formErrors['notes']}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        </Grid>
                        
                    </DialogContent>
                    <DialogActions>
                        <Typography variant="subtitle2" style={{flex: 1}}>
                            <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                        </Typography>
                        <Button onClick={this.handleDialogClose} variant="outlined" color="default">Close</Button>
                        <Button onClick={this.handleSuccess} color="primary" autoFocus variant="contained">Update</Button>
                    </DialogActions>
                </Dialog>
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully approved this supplier stock return'
                />
                <SnackBar
                    variant="error"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='left'
                    open={this.state.errorSnackbarOpen}
                    onClose={this.handleErrorSnackbarClose}
                    message={this.state.snackbarErrorMsg}
                />
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(SupplierStockReturnDispatch);
