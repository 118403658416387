import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import API from '../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import Button from '@material-ui/core/Button';
import {colors} from 'Helpers/ColourHelper';

const initialState = { 
    formData: {
        part: '',
        supplier: '',
        supplierRef: ''
    },
    partList: [],
    supplierList: [],
    existingSupplierList: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
}

class UpdateSupplierReference extends Component {
    constructor(props) {
        super(props);
    
        this.state = initialState;
    }

    componentDidMount(){
        this.getParts();
    }

    getParts = () => {
        let partList = null;
        API.get('/parts/withCosts')
        .then((partRes) =>  {
            if(partRes.data){
                partList = _.map(partRes.data, el => {
                    return _.assign({
                        value: el.part_id,
                        label: el.part_number + ' - ' + el.part_description
                    });
                });
            }
            this.setState({
                partList: partList
            });
        });
    }

    getExistingSuppliers = (partId) => {
        API.get(`/parts/${partId}/suppliers`)
        .then((result) => {
            let supplierList = _.map(result.data, el => {
                return _.assign({
                    value: el.supp_id,
                    label: el.supp_company_name
                })
            });
            this.setState({
                supplierList: supplierList,
                existingSupplierList: result.data
            });
        });
    }

    getReference = (supplierId) => {
        if(supplierId){
            let list = _.find(this.state.existingSupplierList, { 'supp_id': supplierId });

            this.setState({
                formData: {
                    ...this.state.formData,
                    supplierRef: list.costs[0].cost_reference
                }
            });
        }
        else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    supplierRef: ''
                }
            });
        }
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        if(selectedOption){
            this.setState({
                formData: {
                    ...this.state.formData,
                    [fieldName]: selectedOption && selectedOption.value
                }
            }, () => {
                if(fieldName === 'part'){
                    this.getExistingSuppliers(selectedOption && selectedOption.value);
                } 
                else if(fieldName === 'supplier'){
                    this.getReference(selectedOption && selectedOption.value);
                }
            });
        } else {
            this.setState({
                formData:{
                    part: '',
                    supplier: '',
                    supplierRef: ''
                }
            });
        }
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        API.post('/costs/supplierReference', this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState(initialState);
                this.setState({
                    snackbarOpen: true
                });
                this.getParts();
            }
            this.props.scrollToTop();
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }
       
    render() {
        const { formErrors } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Update Supplier Reference
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Cost Details
                            </Typography>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <form noValidate autoComplete="off">
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.partList} 
                                        label='Part *'
                                        onChange={this.handleSelectChange('part')}
                                        error={formErrors && formErrors['part'] && true}
                                        errorText={formErrors && formErrors['part']}
                                        value={this.state.formData.part}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.supplierList} 
                                        label='Supplier *'
                                        onChange={this.handleSelectChange('supplier')}
                                        error={formErrors && formErrors['supplier'] && true}
                                        errorText={formErrors && formErrors['supplier']}
                                        value={this.state.formData.supplier}
                                    />
                                </FormControl>
                                <TextField
                                    id="supplierRef"
                                    name="supplierRef"
                                    label="Suppliers Reference *"
                                    value={this.state.formData.supplierRef}
                                    error={formErrors && formErrors['supplierRef'] && true}
                                    helperText={formErrors && formErrors['supplierRef']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <div className='buttonRow'>
                                    <Button onClick={this.handleConfirmationOpen}
                                            variant="contained"
                                            color="primary"
                                           >
                                        Add
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Update The Supplier Reference?" 
                    message="Are you sure you want to update the supplier reference?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully updated the supplier reference'
                />
            </Grid>
        );
    }
}

export default UpdateSupplierReference;