import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { WEB_EMAIL, ACCOUNTS_EMAIL } from 'Constants';
import API from 'API';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import {colors} from 'Helpers/ColourHelper';

import { map, assign, find, add} from 'lodash';
import _  from 'lodash';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import Textarea from 'Components/Common/Inputs/Textarea';

import {Typography, TextField, FormControl, Button, Grid} from '@material-ui/core';
import MaterialLink from '@material-ui/core/Link';

import { closeDialog } from 'Actions/Dialog/Dialog';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

const EmailDialog = ({customerId, customerContactId, type, id, redirectPath, callback, deliveryAddressId = 0, accountsEmail, defaultEmailCc=[], pickingDocuments=[], onClose}) => {

    const dispatch = useDispatch();

    const initialState = {
        formErrors: {},
        formData: {
            type: '',
            id: '',
            emailTemplate: '',
            emailTo: '',
            emailName: '',
            emailAddress: '',
            emailCc: defaultEmailCc,
            staff: [],
            emailText: '',
            // Address details for PayPal shipping
            addressLineOne: '',
            addressLineTwo: '',
            addressPostcode: '',
            addressTown: '',
            addressCounty: '',
            addressCountryCode: '',
            addressCountry: '',
            additionalDocuments: type === 'pickingDocuments' ? [id] : (pickingDocuments.length > 0 ? pickingDocuments.map(a => a.id) : []),
            emailSubject: ''
        },
        emailTemplateList: [],
        customerContactList: [],
        staffList: [],
        legalDocuments: [],
        countryList: [],
        isLoading: true,
        redirect: false,
    };

    let [state, setState] = useState({...initialState});
    
    const {formErrors, formData, 
        formData:{ additionalDocuments, emailTemplate, emailTo, emailCc, staff, emailText, addressLineOne, addressLineTwo, addressPostcode, addressTown, addressCounty, addressCountryCode, addressCountry}, 
        emailTemplateList, customerContactList, staffList, legalDocuments, isLoading, redirect, countryList } = state;

    
    
    const handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleSelectChange = fieldName => selectedOption => {
        setState(state => ({
            ...state,
            formData:{
                ...formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }));
    }

    const handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [fieldName]: values
            }
        }));
    }

    const handleEmailDialogClose = () => {
        if(redirectPath) setState(state => ({...state, redirect: true}));
        if (onClose) onClose();
        dispatch(closeDialog());
    }

    const handleSubmit = () => dispatch(deployConfirmation('Are you sure you want to send email to customer?', 'Send Email?', submit));

    const submit = () => {
        setState(state => ({...state, isLoading: true}));
        let apiURL = '';

        switch(type) {
            case 'quote':
                apiURL = `/sales/quotations/${id}/email`;
            break;
            case 'noDespatched':
            case 'despatched':
                apiURL = `/sales/orders/despatch/${id}/email`;
            break;
            case 'invoice':
                apiURL = `/sales/orders/invoice/${id}/email`;
            break;
            case 'commercialInvoice':
                apiURL = `/sales/orders/commercialInvoice/${id}/email`;
            break;
            case 'pickingDocuments':
                apiURL = `/documents/${id}/email`;
            break;
            case 'formLink':
                apiURL = `/forms/${id}/emailLink`;
            break;
            default: // Order
                apiURL = `/sales/orders/${id}/email`;
            break;
        }
        API.post(apiURL, formData)
        .then(result => {
            if(result.data.errors) {
                setState(state => ({
                    ...state, 
                    formErrors: formatValidationErrors(result.data.errors), 
                    isLoading: false
                }));
            } else if(result.data.success) {
                handleEmailSuccess();
            }
        });
    }

    const handleEmailSuccess = () => {
        if(redirectPath) setState(state => ({...state, redirect: true}));
        if(callback) callback();
        handleEmailDialogClose();
        dispatch(deploySnackBar('success', 'You have successfully sent the email'));
    }

    // undisable all when emailTo is cleared
    const resetFields = useCallback(() => {
        customerContactList.forEach(el => el.disabled = false);
        setState(state => ({...state, customerContactList}));

    }, [customerContactList])

    const setFields = useCallback(() => {
        let customer = find(customerContactList, { 'value': emailTo});
        let newContactList = [...customerContactList];
        let newEmailCcList = [...emailCc];

        for(let i in newContactList) {
            if(newContactList[i].value === emailTo) {
                newContactList[i].disabled = true;
                if(emailCc.includes(emailTo)) {
                    newEmailCcList = newEmailCcList.filter(id => id !== emailTo);
                }
            } else {
                newContactList[i].disabled = false;
            }
        }

        setState(state => ({
            ...state, 
            customerContactList: newContactList,
            formData: {
                ...state.formData,
                emailName: customer?.name,
                emailAddress: customer?.email,
                emailCc: newEmailCcList
            }
        }));

    }, [customerContactList, emailTo, emailCc])

    useEffect(() => {
        let country = find(countryList, { 'value': addressCountry});
        setState(state => ({
            ...state,
            formData: {
                ...state.formData,
                addressCountryCode: country?.code
            }
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressCountry]);

    // Handle email formData prefill on emailTo change (required for send email on submit) and emailcc disabling
    useEffect(() => {
        if(emailTo && emailTo !== '') {
            setFields();
        } else {
            resetFields();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailTo]);

    // Handle prefill email text from on email template change 
    useEffect(() => {
        if(emailTemplateList && emailTemplateList.length > 0 && emailTemplate !== '') {
            let selectedEmailTemplate = find(emailTemplateList, { 'value': emailTemplate });

            setState(state => ({
                ...state,
                formData: {
                    ...state.formData,
                    emailText: selectedEmailTemplate ? selectedEmailTemplate.text : '',
                    emailSubject: selectedEmailTemplate ? selectedEmailTemplate.label : ''
                }
            }));
        }

    }, [emailTemplate, emailTemplateList]);

    // On load, get email templates, active staff and customer contacts, then format for autocomplete select
    useEffect(() => {
      
        var excludedLegalDocsTypes  = ["stripe", "paypal", "cheque", "transfer", "cash", "despatched", "noDespatched", "invoice", "commercialInvoice", 'pickingDocuments', 'formLink'];

        let getLegalDocs            = excludedLegalDocsTypes.includes(type) ? false : true;

        let promiseArr = [
            API.get('/sales/emailTemplates'),
            API.get(`/customers/${customerId}/contacts`),
            API.get('/staff/all'),
            API.get('/misc/countries/all'),
        ];

        if(getLegalDocs) {
            promiseArr.push(
                API.get('/sales/legalDocuments/forType', {
                    params: {
                        type: type !== 'quote' ? 'order' : 'quotation'
                    }
                })
            )
        }

        Promise.all(promiseArr)
        .then(([emailRes, contactRes, staffRes, countryRes, legalRes]) => {
            let emailTemplateList = map(emailRes.data, el => {
                return assign({
                    value: el.set_id,
                    label: el.set_name,
                    text: el.set_text
                });
            });

            let customerContactList = map(_.filter(contactRes.data, i => {return i.contact_email}), el => {
                    return assign({
                        value: el.contact_id,
                        label: el.contact_name + ' (' + el.contact_email + ')',
                        name: el.contact_name,
                        email: el.contact_email
                    });
            });

            let staffList = map(staffRes.data, el => {
                return assign({
                    value: el.staff_id,
                    label: el.staff_first_name + ' ' + el.staff_last_name,
                });
            });

            let countryList = map(countryRes.data, el => {
                return assign({
                    value: el.country_id,
                    label: el.country_name,
                    code: el.country_code,
                });
            });

            let legalDocuments = [];
            if(getLegalDocs) {
                map(legalRes.data, doc => {
                    legalDocuments.push({
                        document: doc.document.doc_title,
                        url: doc.document.latest_file.file_pdf_url,
                        version: doc.document && doc.document.latest_file && doc.document.latest_file.file_version,
                        legalId: doc.legal_id
                    });
                });
            }

            let emailTemplateId = null;
            switch(type) {
                case 'quote':
                    emailTemplateId = 1;
                break;
                case 'acknowledgement':
                case 'approve':
                    emailTemplateId = 2;
                break;
                case 'confirmation':
                    emailTemplateId = 3;
                break;
                case 'decline':
                case 'declined':
                    emailTemplateId = 4;
                break;
                case 'cancelled':
                    emailTemplateId = 5;
                break;
                case 'stripe':
                    emailTemplateId = 6;
                break;
                case 'cheque':
                    emailTemplateId = 7;
                break;
                case 'transfer':
                    emailTemplateId = 8;
                break;
                case 'cash':
                    emailTemplateId = 9;
                break;
                case 'awaiting-payment':
                    emailTemplateId = 10;
                break;
                case 'payment-confirmed':
                    emailTemplateId = 11;
                break;
                case 'paypal':
                    emailTemplateId = 12
                break;
                case 'despatched':
                    emailTemplateId = 13;
                break;
                case 'noDespatched':
                    emailTemplateId = 29;
                break;
                case 'invoice':
                    emailTemplateId = 14;
                break;
                case 'commercialInvoice':
                    emailTemplateId = 20;
                break;
                case 'pickingDocuments':
                    emailTemplateId = 28;
                break;
                default:
                    emailTemplateId = null;
            }

            let typeVal;
            switch(type) {
                case 'quote':
                case 'stripe':
                case 'paypal':
                case 'cheque':
                case 'transfer':
                case 'cash':
                case 'acknowledgement':
                case 'confirmation':
                case 'awaiting-payment':
                case 'payment-confirmed':
                case 'despatched':
                case 'noDespatched':
                case 'declined':
                case 'cancelled':
                case 'invoice':
                case 'formLink':
                    typeVal = type;
                break;
                default:
                    typeVal = 'order';
                break;
            }

            setState(state => ({
                ...state, 
                formData: {
                    ...state.formData,
                    type: typeVal,
                    id,
                    emailTemplate: emailTemplateId,
                    emailTo: customerContactId && parseInt(customerContactId)
                },
                emailTemplateList, 
                customerContactList,
                staffList,
                legalDocuments,
                countryList,
                isLoading: false
            }));
            // If PayPal then get the delivery address we will use to populate PayPal shipping
            if(type === 'paypal'){
                API.get(`/customers/addresses/${deliveryAddressId}`)
                .then((addressRes => {
                    setState(state => ({
                        ...state, 
                        formData: {
                            ...state.formData,
                            addressLineOne: addressRes?.data?.address_line_three == '' ? addressRes?.data?.address_line_one : addressRes?.data?.address_line_two,
                            addressLineTwo: addressRes?.data?.address_line_three == '' ? addressRes?.data?.address_line_two : addressRes?.data?.address_line_three,
                            addressPostcode: addressRes?.data?.address_postcode,
                            addressTown: addressRes?.data?.address_town,
                            addressCounty: addressRes?.data?.county?.county_name,
                            addressCountryCode: addressRes?.data?.country?.country_code,
                            addressCountry: addressRes.data?.country?.country_id,
                        }
                    }));
                }))
            }
        });

    }, [customerId, customerContactId, id, type]);

    if(redirect && redirectPath) {
        return (
            <Redirect to={redirectPath} />
        );
    }

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <React.Fragment>
                    <br></br>
                    {formErrors && formErrors.generic && (
                        <React.Fragment>
                            <Typography component={"div"} style={{color: colors.red}}>
                                {formErrors.generic}
                            </Typography>
                        </React.Fragment>
                    )}
                    {type == 'paypal' && (
                        <>
                        <Typography variant="h6">
                            PayPal Shipping Address
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid container item spacing={3}>
                                <Grid item lg={6}>
                                    <TextField
                                        id="addressLineOne"
                                        name="addressLineOne"
                                        label="Address Line One *"
                                        value={addressLineOne}
                                        error={formErrors && formErrors['addressLineOne'] && true}
                                        helperText={formErrors && formErrors['addressLineOne']}
                                        onChange={handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={6}>
                                <TextField
                                        id="addressLineTwo"
                                        name="addressLineTwo"
                                        label="Address Line Two"
                                        value={addressLineTwo}
                                        error={formErrors && formErrors['addressLineTwo'] && true}
                                        helperText={formErrors && formErrors['addressLineTwo']}
                                        onChange={handleChange}
                                        margin="normal"
                                        fullWidth
                                    /> 
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item lg={6}>
                                    <TextField
                                        id="addressTown"
                                        name="addressTown"
                                        label="Town / City *"
                                        value={addressTown}
                                        error={formErrors && formErrors['addressTown'] && true}
                                        helperText={formErrors && formErrors['addressTown']}
                                        onChange={handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={6}>
                                    <TextField
                                        id="addressCounty"
                                        name="addressCounty"
                                        label="County"
                                        value={addressCounty}
                                        error={formErrors && formErrors['addressCounty'] && true}
                                        helperText={formErrors && formErrors['addressCounty']}
                                        onChange={handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item lg={5}>
                                    <TextField
                                        id="addressPostcode"
                                        name="addressPostcode"
                                        label="Postocde"
                                        value={addressPostcode}
                                        error={formErrors && formErrors['addressPostcode'] && true}
                                        helperText={formErrors && formErrors['addressPostcode']}
                                        onChange={handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={5}>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={countryList} 
                                            label='Country'
                                            onChange={handleSelectChange('addressCountry')}
                                            error={formErrors && formErrors['addressCountry'] && true}
                                            errorText={formErrors && formErrors['addressCountry']}
                                            value={addressCountry}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item lg={2}>
                                    <TextField
                                        id="addressCountryCode"
                                        name="addressCountryCode"
                                        label="Country Code *"
                                        value={addressCountryCode}
                                        error={formErrors && formErrors['addressCountryCode'] && true}
                                        helperText={formErrors && formErrors['addressCountryCode']}
                                        margin="normal"
                                        fullWidth
                                        disabled
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            className: 'disabledText'
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <br></br>
                        <Typography variant="h6">
                            Email
                        </Typography>
                        <br></br>
                        </>
                    )}
                    <Typography variant="body2" gutterBottom>
                        From: {accountsEmail ?
                            <>Clenaware Accounts {`<${ACCOUNTS_EMAIL}>`}</>:
                            <>Clenaware Systems {`<${WEB_EMAIL}>`}</>
                        }
                    </Typography>
                    <form noValidate autoComplete="off">
                        <FormControl fullWidth margin="normal">
                            <AutoCompleteSelect
                                id="emailTo"
                                name="emailTo"
                                options={customerContactList} 
                                label='To *'
                                value={emailTo}
                                onChange={handleSelectChange('emailTo')}
                                error={formErrors && formErrors['emailTo'] && true}
                                errorText={formErrors && formErrors['emailTo'] && formErrors['emailTo']}
                            />
                        </FormControl>
                        <FormControl margin="normal" fullWidth>
                            <AutoCompleteMultiSelect 
                                options={customerContactList} 
                                label='Email Cc'
                                value={emailCc}
                                onChange={handleMultiSelectChange('emailCc')}
                                error={formErrors && formErrors['emailCc'] && true}
                                errorText={formErrors && formErrors['emailCc']}
                            />
                        </FormControl>
                        <FormControl margin="normal" fullWidth>
                            <AutoCompleteMultiSelect 
                                options={staffList} 
                                label='Staff'
                                value={staff}
                                onChange={handleMultiSelectChange('staff')}
                                error={formErrors && formErrors['staff'] && true}
                                errorText={formErrors && formErrors['staff']}
                            />
                        </FormControl>
                        <FormControl error={formErrors && formErrors['emailTemplate'] && true} fullWidth margin="normal">
                            <AutoCompleteSelect
                                options={emailTemplateList} 
                                label='Email Template'
                                value={emailTemplate}
                                onChange={handleSelectChange('emailTemplate')}
                                error={formErrors && formErrors['emailTemplate'] && true}
                                errorText={formErrors && formErrors['emailTemplate'] && formErrors['emailTemplate']}
                            />
                        </FormControl>
                        {pickingDocuments.length > 0 &&
                            <FormControl error={formErrors && formErrors['pickingDocuments'] && true} fullWidth margin="normal">
                                <AutoCompleteMultiSelect
                                    options={map(pickingDocuments, document => ({
                                        value: document.doc_id,
                                        label: document.doc_title
                                    }))} 
                                    label='Linked Part Files'
                                    value={additionalDocuments}
                                    onChange={handleMultiSelectChange('additionalDocuments')}
                                    error={formErrors && formErrors['additionalDocuments'] && true}
                                    errorText={formErrors && formErrors['additionalDocuments'] && formErrors['additionalDocuments']}
                                />
                            </FormControl>
                        }
                        
                        <Textarea
                            id="emailText"
                            name="emailText"
                            label="Email Text *"
                            value={emailText}
                            rows={5}
                            error={formErrors && formErrors['emailText']}
                            onChange={handleChange}
                        />
                        <br></br><br></br>
                        {(legalDocuments.length > 0) ? (
                            <React.Fragment>
                                <Typography variant="body2" gutterBottom>
                                    The email will have the quotation attached along wih following documents:
                                </Typography>
                                {map(legalDocuments, (document, idx) => {
                                    return (
                                        <Typography variant="body2" gutterBottom key={idx}>
                                            <MaterialLink 
                                                component="a" 
                                                variant="body2" 
                                                className='blueLink' 
                                                href={document.url}
                                                target="_blank"
                                            >
                                                {document.document + ' (v' + document.version + ')'}
                                            </MaterialLink>
                                        </Typography>
                                    )
                                })}
                            </React.Fragment>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    </form>
                    <div className="buttonRow">
                        <Button 
                            onClick={handleEmailDialogClose} 
                            variant="contained" 
                            color="secondary"
                        >
                            Don't Send
                        </Button>
                        <Button 
                            onClick={handleSubmit} 
                            autoFocus 
                            variant="contained" 
                            color="primary" 
                            style={{backgroundColor:'#5da14d', color:'white'}}
                        >
                            Send
                        </Button>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default EmailDialog;