import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import API from '../../../API';
import _ from 'lodash';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SnackBar from '../../Common/SnackBars/SnackBar';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import SupplierDetails from './SupplierDetails';
import EmailDetails from './EmailDetails';
import ReturnItems from './ReturnItems';
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData:  {
        returnType: 'Repair',
        supplier: '',
        returnRows: [
            {
                deductStock: true,
                part: '',
                nominalCode: '',
                quantity: '',
                unitPrice: '',
                totalPrice: '',
                returnDescription: '',
                nominalList: []
            }
        ],
        company: '',
        address: '',
        completedBy: '',
        completionDate: moment().format("DD/MM/YYYY"),
        fao: '',
        emailTemplate: '',
        emailText: '',
        approvedRequiredBy: '',
        overallQuantity: '',
        overallPrice: '',
        previewMode: false
    },
    showNoStockWarning: false,
    supplierContactList: [],
    supplierList: [],
    staffList: [],
    templateList: [],
    partList: [],
    templates: {},
    showForm: false,
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    deductedList: '',
    notDeductedList: '',
    isLoading: false
}

class AddSupplierStockReturn extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.populateDropdowns();
    }

    populateDropdowns = () => {
        Promise.all([
            API.get('/suppliers/withCosts'), 
            API.get('/staff/all', { params: { active: true } }),
            API.get('/supplierStockReturns/emailTemplates'),
            API.get('/accounts/nominalCodes')
        ])
        .then(([suppRes, staffRes, templateRes]) =>  {
            let supplierList = [];
            let staffList = [];
            let templateList = [];

            if(suppRes.data){
                supplierList = _.map(suppRes.data, (el) => {
                    return _.assign({
                        value: el.supp_id,
                        label: el.supp_company_name
                    });
                });
            }
            if(staffRes.data) {
                staffList = _.map(staffRes.data, (el) => {
                    return _.assign({
                        value: el.staff_id,
                        label: el.staff_first_name + ' ' + el.staff_last_name
                    });
                });
            }
            if(templateRes.data) {
                templateList = _.map(templateRes.data, (el) => {
                    return _.assign({
                        value: el.sr_email_id,
                        label: el.sr_email_name
                    });
                });
                templateList.unshift({
                    value: 0,
                    label: 'None'
                });
            }
            this.setState({
                supplierList: supplierList,
                staffList: staffList,
                templateList: templateList,
                templates: templateRes.data
            });
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if((fieldName === 'returnType' || fieldName === 'supplier') && (this.state.formData.returnType && this.state.formData.supplier > 0)){
                this.setState({
                    showForm: true,
                    formErrors: [],
                    formData:{
                        ...this.state.formData,
                        returnRows: fieldName === 'supplier' ? initialState.formData.returnRows : this.state.formData.returnRows,
                    }
                });

                Promise.all([
                    API.get(`/suppliers/${this.state.formData.supplier}`), 
                    API.get(`/parts/bySupplier/${this.state.formData.supplier}`),
                ])
                .then(([suppRes, partsRes]) =>  {
                    let supplierContactList = _.map(suppRes.data.active_contacts, (el) => {
                        return _.assign({
                            value: el.supp_cont_id,
                            label: el.supp_cont_first_name + ' ' + el.supp_cont_last_name + ' (' + el.supp_cont_type + ')'
                        });
                    });
                    let partList = _.map(_.filter(partsRes.data,i=>!i.part_locked), (el) => {
                        return _.assign({
                            value: el.part_id,
                            label: el.part_number + ' - ' + el.part_description
                        });
                    });
                    this.setState({
                        formData:{
                            ...this.state.formData,
                            company: suppRes.data.supp_company_name,
                            address: suppRes.data.supp_address_line_one + "\n\r" +
                                    (suppRes.data.supp_address_line_two ? suppRes.data.supp_address_line_two + "\n\r":'') +
                                    (suppRes.data.supp_address_line_three ? suppRes.data.supp_address_line_three + "\n\r":'') +
                                    suppRes.data.supp_city + "\n\r" +
                                    (suppRes.data.county && suppRes.data.county.county_name ? suppRes.data.county.county_name + "\n\r":'') +
                                    (suppRes.data.country && suppRes.data.country.country_name ? suppRes.data.country.country_name + "\n\r":'') +
                                    suppRes.data.supp_postcode,
                            completedBy: '',
                        },
                        supplierContactList: supplierContactList,
                        partList: partList
                    });
                });
            }

            if(fieldName === 'emailTemplate'){
                let email = _.find(this.state.templates, {'sr_email_id': selectedOption && selectedOption.value });

                this.setState({
                    formData:{
                        ...this.state.formData,
                        emailText: (typeof email === 'undefined') ? '' : email.sr_email_text
                    }
                });
            }
        });
    }

    handleAddRow = () => {
        const item = initialState.formData.returnRows[0];
        this.setState({
            formData:{
                ...this.state.formData,
                returnRows: [...this.state.formData.returnRows, item]
            }
        });
    }

    handleRowChange = (idx, decimals) => e => {
        const { name, value, checked } = e.target;
        let newQty =  [...this.state.formData.returnRows];
        let newVal = (name === 'deductStock') ? checked : decimals ? parseFloat(value).toFixed(decimals) : value;

        newQty[idx] = {
            ...newQty[idx],
            [name]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                returnRows: newQty
            }
        },
        () => {
            this.calculatePrice(idx);
        });
    };

    handleSelectRowChange = (idx, fieldName) => selectedOption => {
        let data =  [...this.state.formData.returnRows];
        let newVal = selectedOption && selectedOption.value;

        data[idx] = {
            ...data[idx],
            [fieldName]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                returnRows: data
            }
        }, 
        () => {
            if(fieldName === 'part'){
                let partId = selectedOption && selectedOption.value;
                if (partId){
                    API.get(`/parts/${partId}`)
                    .then((partsRes) =>  {
                        if(partsRes.data && partsRes.data.default_cost){
                            let nominalList = _.map(partsRes.data.nominal_codes, (el) => {
                                return _.assign({
                                    value: el.nominal_id,
                                    label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                                });
                            });
                            data[idx] = {
                                ...data[idx],
                                unitPrice: partsRes.data.default_cost.cost_per_unit,
                                nominalCode: this.state.formData.returnType === 'Credit' ? partsRes.data.nominal_code && partsRes.data.nominal_code.nominal_id : 0,
                                nominalList: nominalList
                            };
                            this.setState({
                                formData: {
                                    ...this.state.formData,
                                    returnRows: data
                                }
                            },
                            () => {
                                this.stockLevels(idx, partId);
                            });
                        }
                    });
                }
            }
        });
    }

    stockLevels = (idx, partId) => {
        let data =  [...this.state.formData.returnRows];
        API.get(`/stock/${partId}`)
        .then((stockRes) =>  {
            if(stockRes.data){
                if(stockRes.data.stock_current_qty <= 0){
                    data[idx] = {
                        ...data[idx],
                        deductStock: false
                    };
                    this.setState({
                        formErrors: {
                            ...this.state.formErrors,
                            [`returnRows|quantity|${idx}`]: 'No stock for this part'
                        },
                        showNoStockWarning: true,
                        formData: {
                            ...this.state.formData,
                            returnRows: data
                        }
                    });
                }
            }
            this.calculatePrice(idx);
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleRemoveRow = (idx) => () => {
        let rows = [...this.state.formData.returnRows]
        rows.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                returnRows: rows
            }
        },
        () => {
            this.calculatePrice(idx);
        })
    }

    calculatePrice = (idx) => {
        let data =  [...this.state.formData.returnRows];
        if(data[idx]){
            data[idx] = {
                ...data[idx],
                totalPrice: data[idx] ? (data[idx].unitPrice * data[idx].quantity).toFixed(2) : (0).toFixed(2)
            };
            // Set new total price before setting overall price
            this.setState({
                formData: {
                    ...this.state.formData,
                    returnRows: data
                }
            }, 
            () => {
                this.calculateOverallTotals();
            });
        }
        else {
            // Remove row - just calculate overall totals
            this.calculateOverallTotals();
        }
    }

    calculateOverallTotals = () => {
        let overallQuantity = 0;
        let overallPrice = 0;
        this.state.formData.returnRows.forEach((row) => {
            overallQuantity = overallQuantity + (row.quantity*1);
            overallPrice = overallPrice + (row.totalPrice*1);
        });
        this.setState({
            formData: {
                ...this.state.formData,
                overallQuantity: overallQuantity.toFixed(3),
                overallPrice: overallPrice.toFixed(2)
            }
        });
    }

    handleConfirmationOpen = (e) => {
        let deducted = '';
        let notDeducted = '';
        this.state.formData.returnRows.forEach((row) => {
            let part = _.find(this.state.partList, {'value': row.part });
            if(row.deductStock === true){
                deducted += `${row.quantity} x ${part && part.label} \n\r`;
            }
            else {
                notDeducted += `${row.quantity} x ${part && part.label} \n\r`;
            }
        });

        this.setState({
            deductedList: deducted,
            notDeductedList: notDeducted,
            confirmationOpen: true,
            formData: {
                ...this.state.formData,
                previewMode: false
            }
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handlePreview = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                previewMode: true
            }
        },
        () => {
            this.submit();
        });
    }

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            const formData = {
                ...this.state.formData,
                completionDate: (moment(new Date(this.state.formData.completionDate)).format('DD/MM/YYYY'))
            }
            API.post('/supplierStockReturns', formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    if(result.data.pdf){
                        this.setState({
                            isLoading: false
                        },
                        () => {
                            pdfFromBase64(result.data.pdf, result.data.pdfName);
                        });
                    }
                    else {
                        this.setState({
                            ...initialState,
                            snackbarOpen: true
                        }, () => {
                            this.populateDropdowns();
                        });
                    }                                
                }
                this.props.scrollToTop();
            });
        });
    }
       
    render() {
        const { formErrors } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Supplier Stock Returns
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={[
                                            {value: 'Repair', label: 'Repair'},
                                            {value: 'Replace', label: 'Replace'},
                                            {value: 'Credit', label: 'Credit'}
                                        ]} 
                                        label='Return Type *'
                                        onChange={this.handleSelectChange('returnType')}
                                        error={formErrors && formErrors['returnType'] && true}
                                        errorText={formErrors && formErrors['returnType']}
                                        value={this.state.formData.returnType}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.supplierList} 
                                        label='Supplier *'
                                        value={this.state.formData.supplier}
                                        onChange={this.handleSelectChange('supplier')}
                                    />
                                </FormControl>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                {this.state.showForm &&
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <SupplierDetails 
                                        address={this.state.formData.address} 
                                        returnType={this.state.formData.returnType}
                                        company={this.state.formData.company}
                                        completionDate={this.state.formData.completionDate}
                                        fao={this.state.formData.fao}
                                        handleSelectChange={this.handleSelectChange}
                                        formErrors={formErrors}
                                        supplierContactList={this.state.supplierContactList}
                                    />
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <ReturnItems
                                    returnRows={this.state.formData.returnRows}
                                    returnType={this.state.formData.returnType}
                                    overallPrice={this.state.formData.overallPrice}
                                    overallQuantity={this.state.formData.overallQuantity}
                                    partList={this.state.partList}
                                    handleRemoveRow={this.handleRemoveRow}
                                    handleAddRow={this.handleAddRow}
                                    handleSelectRowChange={this.handleSelectRowChange}
                                    handleRowChange={this.handleRowChange}
                                    formErrors={formErrors}
                                    type={this.state.formData.type}
                                />
                            </PaddedPaper>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <EmailDetails 
                                        templateList={this.state.templateList} 
                                        staffList={this.state.staffList} 
                                        formErrors={formErrors} 
                                        handleChange={this.handleChange} 
                                        handleSelectChange={this.handleSelectChange} 
                                        emailTemplate={this.state.emailTemplate} 
                                        emailText={this.state.formData.emailText} 
                                        approvedRequiredBy={this.state.formData.approvedRequiredBy}
                                    />
                                    {formErrors && formErrors['part_locked'] &&
                                        <Grid container xs={12} style={{paddingTop:'1em'}}>
                                            <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>
                                                Some parts are locked, please try again later.
                                            </Grid>
                                        </Grid>
                                    }
                                    <div className='buttonRow'>
                                        <Button onClick={this.handlePreview}
                                                variant="outlined"
                                                color="default">
                                            Preview
                                        </Button>
                                        <Button onClick={this.handleConfirmationOpen}
                                                variant="contained"
                                                color="primary"
                                                disabled={this.state.isLoading}>
                                            Add
                                        </Button>
                                    </div>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
                <Dialog
                    open={this.state.confirmationOpen}
                    onClose={this.handleConfirmationClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="alert-dialog-title">{"Add A New Stock Returns Form?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            The following parts will be <span style={{color:colors.red}}>deducted</span> from stock once approved:<br></br>
                            {this.state.deductedList.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br/></span>
                            })}
                            The following parts will be <span style={{color:colors.red}}>not be deducted</span> from stock:<br></br>
                            {this.state.notDeductedList.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br/></span>
                            })}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirmationClose} variant="outlined" color="default">
                        Cancel
                        </Button>
                        <Button onClick={this.handleConfirmationSuccess} autoFocus variant="contained" color="primary" >
                        Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added a new supplier stock return'
                />
            </Grid>
        );
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
        loading: {
            ...state.loading
        }
    };
}

export default connect(mapStateToProps)(AddSupplierStockReturn);
