import * as CiServiceWorkerTypes from './Types/Types';

export function handleCiServiceWorker(installStatus) {
    return (dispatch) => {
        dispatch({
            type: CiServiceWorkerTypes.INSTALLED,
            payload: installStatus,
        });
    }
}

export function handleCiServiceWorkerReset() {
    return (dispatch) => {
        dispatch({
            type: CiServiceWorkerTypes.RESET,
            payload: false,
        });
    }
}