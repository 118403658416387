import * as StatePersistenceActionTypes from 'Actions/StatePersistence/Types/Types';

const initialState = () => ({
});

function StatePersistenceReducer(state = initialState, action) {
    switch (action.type) {
        case StatePersistenceActionTypes.SET_PERSISTENCE:
            return {
                ...state,
                ...action.payload
            };
        case StatePersistenceActionTypes.CLEAR_PERSISTENCE:
            if (action.payload) {
                //if user resets !w/ persisted state just return the state
                if (typeof state[action.payload] !== 'undefined') delete state[action.payload];
                return state;
            }
            return {...initialState()};
        default:
            return state;
    }
}

export default StatePersistenceReducer;