import React, { useState } from 'react';
import _                   from 'lodash';

import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, TextField, InputAdornment, Checkbox } from '@material-ui/core';

import DataTable        from 'Components/Common/DataTables/CiDataTable';

import { colors } from 'Helpers/ColourHelper';

export default function LinkedPartsDialog({ copyOrder, handleSubmit, handleChange }) {

    return(
        <Dialog 
            open={true} 
            fullWidth={true} 
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="lg"
        >
            <DialogTitle style={{backgroundColor: colors.orange, color: colors.white}}>Repeat Quote Warning</DialogTitle>
            <DialogContent>
                <>
                    {copyOrder.discountChange &&
                        <Grid style={{marginBottom: '2em'}}>
                            <Typography variant="h6" gutterBottom>Note: Customers discount rate has changed from {parseFloat(copyOrder.orderDiscountRate).toFixed(2)}% to {parseFloat(copyOrder.customerDiscountRate).toFixed(2)}% </Typography>
                        </Grid>
                    }
                    {copyOrder.costChange &&
                        <Grid style={{marginBottom: '2em'}}>
                            <Typography variant="body1" gutterBottom>The following items price have changed</Typography>
                            <DataTable
                                config={{
                                    key: i => `${i.part}|${i.useOriginal ? 1 : 0}`,
                                    plainHeader: false,
                                }}
                                columns={_.filter([
                                    {
                                        heading: '',
                                        field:   'partImage',
                                        fieldFormat: 'image',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Part',
                                        field:   i => <>{i.partNumber} <br/>{i.partDescription}</>,
                                    },
                                    {
                                        heading: 'Quantity',
                                        field:   'quantity',
                                        fieldFormat: 'decimal:3',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       <>Order<br/>Price</>,
                                        field:   'unitPrice',
                                        fieldFormat: 'currency',
                                        sizeToContent: true,
                                        style: i => ({ fontWeight: i.useOriginal ? 'bold' : 'normal' }),
                                    },
                                    {
                                        heading:       <>Current<br/>Price</>,
                                        field:   'newCost',
                                        fieldFormat: 'currency',
                                        sizeToContent: true,
                                        style: i => ({ fontWeight: !i.useOriginal ? 'bold' : 'normal' }),
                                    },
                                    {
                                        heading:       <>Use Original<br/> Price?</>,
                                        sizeToContent: true,
                                        field:   i =>
                                                <Checkbox
                                                    checked={i.useOriginal}
                                                    color="primary"
                                                    onClick={()=>handleChange(i.part, 'useOriginal', !i.useOriginal)}
                                                /> ,
                                        alignment: 'center',
                                    },
                                    copyOrder.orderType === 'With Discount' &&
                                    {
                                        heading:       <>Discount<br/>Rate</>,
                                        field:         i => `${i.discountDecimal*100}%`,
                                        sizeToContent: true,
                                    },
                                    copyOrder.orderType === 'With Discount' &&
                                    {
                                        heading:       <>New<br/>Price</>,
                                        field:         i => parseFloat(i.useOriginal ? i.unitPrice : i.newCost) - parseFloat( parseFloat(i.useOriginal ? i.unitPrice : i.newCost) * (i.discountDecimal) ) ,
                                        fieldFormat:   'currency',
                                        sizeToContent: true,
                                    },
                                    copyOrder.orderType === 'With Discount' &&
                                    {
                                        heading:       <>Discount<br/>Total</>,
                                        field:   i => parseFloat( parseFloat( parseFloat(i.useOriginal ? i.unitPrice : i.newCost) * (i.discountDecimal) ) * i.quantity ),
                                        fieldFormat:   'currency',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       <>Total<br/>Price</>,
                                        field:   i => parseFloat( parseFloat(i.useOriginal ? i.unitPrice : i.newCost) - parseFloat( parseFloat(i.useOriginal ? i.unitPrice : i.newCost) * (i.discountDecimal) )  ) * i.quantity,
                                        fieldFormat:   'currency',
                                        sizeToContent: true,
                                    }
                                ], i => i)}
                                rows={_.filter(copyOrder.partRows, 'checkPrice')}
                            />
                        </Grid>
                    }
                    {copyOrder.stockChange &&
                        <Grid style={{marginBottom: '2em'}}>
                            <Typography variant="body1" gutterBottom>The following items do not have available stock</Typography>
                            <DataTable
                                config={{
                                    key: i => `${i.part}|${i.useOriginal ? 1 : 0}`,
                                    plainHeader: false,
                                }}
                                columns={[
                                    {
                                        heading: '',
                                        field:   'partImage',
                                        fieldFormat: 'image',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Part',
                                        field:   i => <>{i.partNumber} <br/>{i.partDescription}</>,
                                    },
                                    {
                                        heading: 'Required',
                                        field:   'quantity',
                                        fieldFormat: 'decimal:3',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Available',
                                        field:   'stockAvailable',
                                        fieldFormat: 'decimal:3',
                                        sizeToContent: true,
                                    }
                                ]}
                                rows={_.filter(copyOrder.partRows, 'checkStock')}
                            />
                        </Grid>
                    }
                </>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={handleSubmit} >Continue</Button>
            </DialogActions>
        </Dialog>
    );
}