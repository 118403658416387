import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Input, InputAdornment, InputLabel, Link, TextField, Typography } from '@material-ui/core';
import MuiLink from '@material-ui/core/Link';
import { withStyles } from '@material-ui/styles';
import API from 'API';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import { downloadS3File } from 'Functions/MiscFunctions';
import { colors } from 'Helpers/ColourHelper';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';

const initialState = () => ({
    showCourierDetails: false
})

class Collection extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    showCourierDetails = (rowData) => {
        this.setState({ showCourierDetails: true });
    }

    handleDelete = (id) => () =>  {
        API.post(`/customerReturns/returns/${this.props.cr.cr_id}/removeFile/${id}`)
            .then(this.props.refresh)
    }
   
    render() {
        const { showCourierDetails } = this.state;
        const { cr, classes } = this.props;
        let { collection } = cr;
    
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl margin="normal" fullWidth>
                            <InputLabel shrink={true} className={classes.label}>Expected Collection Date</InputLabel>
                            <Input value={moment(collection.crc_date).format('DD/MM/YYYY')} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='calendar-alt' /></InputAdornment>} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl margin="normal" fullWidth>
                            <InputLabel shrink={true} className={classes.label}>Expected Delivery Date</InputLabel>
                            <Input value={moment(collection.crc_delivery_date).format('DD/MM/YYYY')} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='calendar-alt' /></InputAdornment>} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl margin="normal" fullWidth>
                            <InputLabel shrink={true} className={classes.label}>Courier</InputLabel>
                            <Input value={collection.crc_method} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='truck' /></InputAdornment>} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <DataTable  
                                    config={{
                                        key: 'crc_id',
                                        plainHeader: true
                                    }}
                                    columns={[
                                        {
                                            heading: 'Collection Date',
                                            field: 'crc_date',
                                            sizeToContent: true,
                                            fieldFormat: 'clenydate'
                                        },
                                        {
                                            heading: 'Delivery Date',
                                            field: 'crc_delivery_date',
                                            sizeToContent: true,
                                            fieldFormat: 'clenydate'
                                        },
                                        {
                                            heading: 'Collection Method',
                                            field: rowData => <>{rowData.crc_method} <br /> 
                                                                {collection.files?.length ?? 0}
                                                                <small> Courier Collection File(s)</small>
                                                            </>,
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Status',
                                            field: rowData => <>
                                                                <FASolidIcon 
                                                                    icon='circle'
                                                                    size='small'
                                                                    style={{color: rowData.crc_status  == 'Awaiting Collection' ? colors.orange : colors.green }}
                                                                />
                                                                {rowData.crc_status  == 'Awaiting Collection' ? 'Awaiting Delivery'  : rowData.crc_status} </>,
                                            sizeToContent: true
                                        },
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Courier Details', icon: 'file-search', onClick: this.showCourierDetails},
                                                ]
                                            },
                                            alignment: 'center'
                                        }
                                    ]}
                                    rows={[collection]}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Dialog
                    open={showCourierDetails}
                    fullWidth={true} 
                    maxWidth="sm" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle>{collection.crc_collection_reference ?? 'Courier Details'}</DialogTitle>
                    <DialogContent>
                        <Typography variant='body1' gutterBottom>Courier</Typography>
                        <Typography variant='subtitle2' gutterBottom>
                            {collection.crc_method} <br/>
                            { JSON.parse(collection.crc_consignments).length > 0 &&
                                _.map(JSON.parse(collection.crc_consignments), i => <><Link
                                    href={i}
                                    target="_blank"
                                >{i}</Link><br/></>)
                                
                            }
                        </Typography>
                        <br />
                        <Typography variant='body1' gutterBottom>Collection File(s)</Typography>
                        {collection.files?.length > 0 ?
                            _.map(collection.files, (file, idx) => 
                                <Grid container key={idx} >
                                    <Grid item xs={1}>
                                        { idx === (collection.files.length - 1) ? 
                                            <AllIcon
                                                icon='trash-alt'
                                                tooltip='Delete'
                                                onClick={this.handleDelete(file.crf_id)}
                                                size='small'
                                                noMargin
                                                noPadding
                                            />
                                            : <></> 
                                        }
                                    </Grid>
                                    <Grid item xs={11} style={{padding:'2px'}}>
                                        <MuiLink component="div" variant="body2" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={() => {downloadS3File(file.file_path)}}>
                                            {_.last(file.crf_file.split('/')) || '-'}
                                        </MuiLink>
                                    </Grid>
                                </Grid>
                            ) : <small><i>No Files Added</i></small>
                        }
                        <br />
                        <Typography variant='body1' gutterBottom>Courier Notes</Typography>
                        <TextField
                            value={collection.crc_notes}
                            rows={5}
                            variant="outlined"
                            multiline
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='outlined'
                            onClick={() => this.setState({ showCourierDetails: false })}
                        >Close</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

const styles = theme => ({
    label: {
        color: 'black'
    },
});

export default withStyles(styles)(Collection);
