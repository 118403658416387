import { FormControl, Grid, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core/';
import React, { Component } from 'react';
import DataTable from './../Common/DataTables/DataTable';
import FALightIcon from './../Common/Icons/FontAwesome/FALightIcon';

import _ from 'lodash';
import API from '../../API';

const initialState = {
    pageType: '',
    isLoading: false,
    associatedResults: [],
    search: '',
    addresses: [],
    callback: null,
}

const accountStatus = ['','Active - Verified','On Hold','Closed','Active - Not Verified']

class CustomerAddressSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            search: this.props.value
        }
    }

    componentDidMount(){
        this.getCustomerAddresses();
    }

    getCustomerAddresses = () => {
        API.get(`/customers/addresses`)
        .then((res) =>  {
            this.setState({
                addresses: res.data
            });
        })
    }

    filterResults = e => {
        this.setState({
            search: e.target.value
        }, 
        () => {
            this.getResults()
        })
    }

    cleanString(str) { return str.replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]', 'g'), '\\$&') }

    getResults = () => {

        var sites = _.filter(this.state.addresses, site => {
            if (!this.state.search.length) return 0;
            var ret = 0;
            if (this.cleanString(site.address_name.toLowerCase())
                .search(this.cleanString(this.state.search.toLowerCase())) > -1 )       ret = 1;
            if ( this.cleanString(site.address_postcode.toLowerCase())
                .search(this.cleanString(this.state.search.toLowerCase())) > -1 )       ret = 1;
            return ret;
        } );
        var customers = [] ;
        _.forEach(sites, item => {
            if (_.findIndex(customers,{'address_customer_id':item.address_customer_id}) < 0 ) {
                customers.push(
                    _.find(this.state.addresses, {'address_customer_id'   : item.address_customer_id,})
                );
            }
        });
        this.setState({
            associatedResults: customers
        });
    }

    handleClearSearch =() => {
        this.setState({
            search: ''
        }, ()=>this.getResults())
    }

    handleCallBack = item => {
        this.props.callback(item); 
        if (!this.props.dontClear) {
            this.setState({
                associatedResults:[],
                search:''
            },  this.props.callback(item) );
        } else {
            this.props.callback(item);
        }
    }

    getColumns = () => {
        var slim    = this.props.slim;
        var columns = [];

        if (!slim)  columns.push({
                heading: '',
                field: rowData => <FALightIcon icon='star' />,
                important: !slim,
                sizeToContent: true
            });
        
        columns.push(
            {
                heading: 'Ref',
                field: rowData => <>{`CS${rowData.cust_id}`}</>,
                important: !slim,
                sizeToContent: true
            }
        )
        
        columns.push(
            {
                heading: 'Type',
                field: rowData => rowData.cust_type,
                important: !slim,
                sizeToContent: true
            }
        )

        columns.push(
            {
                heading: 'Name',
                field: rowData => rowData.cust_name,
                important:      !slim,
                sizeToContent:  !slim
            }
        );
        if (!slim) columns.push(
            {
                heading: 'Account Status',
                field: rowData => accountStatus[rowData.cust_account_status_id],
                sizeToContent: true
            }
        );
        columns.push(
            {
                heading: 'Postcode',
                field: rowData => rowData.address_postcode || '-',
                important: !slim,
                sizeToContent: true
            }
        );
        columns.push({
            actions: rowData => {
                return [
                    {
                        name:       `Add`, 
                        icon:       'check',
                        disabled:   (rowData.cust_account_status_id === 3 && this.props.disabledClosed) || (rowData.cust_account_status_id === 2  && this.props.disableOnHold),
                        onClick:    this.handleCallBack
                    },
                ]
            }
        });

        

        return columns;
    }

    render(){

        const {associatedResults} = this.state;

        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={this.props.reset || this.props.clear ? 9 : 12}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel htmlFor="Search-Site-Addresses">{this.props.label ? this.props.label :'Search Site Addresses'}</InputLabel>
                            <Input
                                id="Search-Site-Addresses"
                                onChange={this.filterResults}
                                value={this.state.search}
                                endAdornment={
                                    this.state.search &&
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="Clear Search"
                                        onClick={this.handleClearSearch}
                                      >
                                        <FALightIcon icon={'times'} noMargin button/>
                                      </IconButton>
                                    </InputAdornment>
                                  }
                            />
                        </FormControl>
                    </Grid>
                    {(this.props.reset || this.props.clear) &&
                        <Grid item alignItems='left' style={{paddingTop:'3em'}}>
                            {this.props.reset && <IconButton onClick={this.props.reset}><FALightIcon icon='undo' noMargin button /></IconButton> }
                            {this.props.clear && <IconButton onClick={this.props.clear}><FALightIcon icon='times' noMargin button /></IconButton>}
                        </Grid>  
                    }       
                </Grid>
                {!associatedResults?.length ? <></>:
                    <DataTable  
                        config={{
                            pagination: false,
                            isLoading: this.state.isLoading,
                            responsiveImportance: true,
                            alternatingRowColours: true
                        }}
                        columns={this.getColumns()}
                        rows={associatedResults}
                    />
                }
            </>
        )
    }
}

export default CustomerAddressSearch;