import React from 'react';
import {
    Grid,
    Typography
} from '@material-ui/core';
import DataTable from 'Components/Common/DataTables/DataTable';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { toggleDialog } from 'Functions/MiscFunctions';
import ChangelogDialog from 'Components/Common/Changelog/ChangelogDialog';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';

const initialState = {
    changeLogs: [],
    selectedChange: false,
    dialog: {
        changelogDialog: false
    },
}

class ChangelogView extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState;
        this.toggleDialog = toggleDialog.bind(this);
    }

    componentDidMount() {
        this.loadComponentData();
    }

    loadComponentData = () => {
        let changeLogs = this.props.changeLogs;
        this.setState({
            changeLogs,
            selectedChange: false
        });
    }

    handleOpenChangeLog = (change) => {
        this.setState({
            selectedChange: change,
        }, () => {
            this.toggleDialog('changelogDialog');
        });
    }

    render(){
        const {changeLogs, dialog} = this.state
        
        return (
        <>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PaddedPaper>
                    <Grid container alignItems='center'>
                        <Grid item xs={12}>
                            <Grid container spacing={1} alignItems='center' className='mb-1'>
                                <Grid item xs>
                                    <Typography variant="h6">
                                        Change Log
                                    </Typography>
                                </Grid>
                            </Grid>
                            <DataTable  
                                config={{
                                    key: 'id',
                                    pagination: true,
                                    alternatingRowColours: true,
                                    responsiveImportance: true,
                                    options: {
                                        plainPagination: true,
                                        dataRef: false,
                                    }
                                }}
                                columns={[
                                    {
                                        heading: 'Version From',
                                        field: rowData => rowData?.vFrom,
                                        dataRef: 'vFrom',
                                        important: true,
                                        sizeToContent: true,
                                        alignment: 'center',
                                        fieldPrefix: 'v'
                                    },
                                    {
                                        heading: 'Version To',
                                        field: rowData => rowData?.vTo,
                                        dataRef: 'vTo',
                                        important: true,
                                        sizeToContent: true,
                                        alignment: 'center',
                                        fieldPrefix: 'v'
                                    },
                                    {
                                        heading: 'Updated By',
                                        field: rowData =>  rowData?.s?.nm ?? 'Unknown',
                                    },
                                    {
                                        heading: 'Date',
                                        field: rowData => rowData.date,
                                        dataRef: 'date',
                                        fieldFormat: 'datetime',
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: `Version ` + rowData?.vFrom, icon: 'eye', link: rowData?.link, disabled: !rowData?.link},
                                                {name: `View`, icon: 'search', onClick: () => this.handleOpenChangeLog(rowData)}
                                            ];
                                        }
                                    }
                                ]}
                                rows={changeLogs}
                            />
                        </Grid>
                    </Grid>
                </PaddedPaper>
            </Grid>
        </Grid>
        {/* ChangeLog Dialog */}
        <SmallFormDialog
            open={dialog.changelogDialog}
            onClose={() => this.toggleDialog('changelogDialog')}
            title={'Change Log'}
            content={<ChangelogDialog 
                    toggleDialog={() => this.toggleDialog('changelogDialog')}  
                    changeLog={this.state.selectedChange}
                        />}
            maxWidth='sm'
        />
        </>
        );
    }
}

export default ChangelogView;