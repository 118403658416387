import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import API from '../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import {colors} from 'Helpers/ColourHelper';

const initialState = { 
    formData: {
        part: '',
        price: '',
        grossMargin: '',
        currentCost: ''
    },
    partList: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: false
}

class AddPrice extends Component {
    constructor(props) {
        super(props);
    
        this.state = initialState;
    }

    componentDidMount(){
        this.getParts();
    }

    getParts = () => {
        let partList;
        API.get('/parts/withoutPrices')
        .then((partRes) =>  {
            if(partRes.data){
                partList = _.map(partRes.data, el => {
                    return _.assign({
                        value: el.part_id,
                        label: el.part_number + ' - ' + el.part_description
                    });
                });
            }
            this.setState({
                partList: partList
            });
        });
    }

    getCurrentCost = (partId) => {
        API.get(`/parts/${partId}/costs/default`)
        .then((result) =>  {
            this.setState({
                formData: {
                    ...this.state.formData,
                    currentCost: result.data.cost_per_unit,
                    price: '',
                    grossMargin: '',
                }
            });
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, () => {
            if(fieldName === 'part' && selectedOption){
                this.getCurrentCost(selectedOption && selectedOption.value);
            }
            else {
                this.setState({
                    formData: initialState.formData
                });
            }
        });
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            API.post('/prices', this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...initialState,
                        snackbarOpen: true
                    });
                    this.getParts();
                }
                this.props.scrollToTop();
            });
        });
    }

    calculateMargin = () => {
        let margin = 0;
        if(!isNaN(this.state.formData.price) && this.state.formData.price > 0){
            margin = ((this.state.formData.price - this.state.formData.currentCost) / this.state.formData.price) * 100;
        }
        this.setState({
            formData: {
                ...this.state.formData,
                grossMargin: margin.toFixed(2)
            }
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }
       
    render() {
        const { formErrors, formData } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Price
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6" gutterBottom>
                                Select Part
                            </Typography>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <form noValidate autoComplete="off">
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.partList} 
                                        label='Part *'
                                        onChange={this.handleSelectChange('part')}
                                        error={formErrors && formErrors['part'] && true}
                                        errorText={formErrors && formErrors['part']}
                                        value={this.state.formData.part}
                                    />
                                </FormControl>
                                <TextField
                                    label="Current Cost"
                                    value={formData.currentCost}
                                    margin="normal"
                                    fullWidth
                                    disabled
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                          <InputAdornment  position="start">
                                            £
                                          </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    id="price"
                                    name="price"
                                    label="Price *"
                                    type='number'
                                    value={this.state.formData.price}
                                    error={formErrors && formErrors['price'] && true}
                                    helperText={formErrors && formErrors['price']}
                                    onChange={this.handleChange}
                                    onKeyUp={this.calculateMargin}
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                          <InputAdornment  position="start">
                                            £
                                          </InputAdornment>
                                        )
                                    }}
                                />
                                <TextField
                                    id="grossMargin"
                                    name="grossMargin"
                                    label="Gross Margin *"
                                    value={this.state.formData.grossMargin}
                                    error={formErrors && formErrors['grossMargin'] && true}
                                    helperText={formErrors && formErrors['grossMargin']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                    disabled
                                    InputProps={{
                                        endAdornment: (
                                          <InputAdornment  position="end">
                                            %
                                          </InputAdornment>
                                        ),
                                    }}
                                />
                            </form>
                            <div className='buttonRow'>
                                <Button onClick={this.handleConfirmationOpen}
                                        variant="contained"
                                        color="primary"
                                        disabled={this.state.isLoading}
                                       >
                                    Add
                                </Button>
                            </div>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Add A New Price?" 
                    message="Are you sure you want to add this new price?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added a new price'
                />
            </Grid>
        );
    }
}

export default AddPrice;