import API from 'API';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { Grid, Button, ButtonGroup } from '@material-ui/core';
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import IconHelper from 'Helpers/IconHelper';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { colors } from 'Helpers/ColourHelper';

const initialState = () => ({
    isLoading: true,
    scheduled: [],
    view: 'date',
})

class EmailSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getScheduled();
    }

    getScheduled = () => {
        API.get('/marketing/campaigns/scheduled')
        .then(res => {
            this.setState({
                isLoading: false,
                scheduled: res.data
            })
        })
    }

    cancelScheduled = s => {
        API.post(`/marketing/campaigns/emailCampaign/scheduled/${s.ecse_id}/cancel`).then(this.props.refresh)
    }

    render() {

        const { scheduled, isLoading, view } = this.state;

        if (isLoading) {
            return (
                <LoadingCircle />
            )
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} className='buttonRow'>
                    <ButtonGroup>
                        <Button
                            onClick={() => this.setState({view: 'date'})}
                            variant={view === 'date' ? 'contained' : 'outlined'}
                            color='primary'
                        >Date View</Button>
                        <Button
                            onClick={() => this.setState({view: 'all'})}
                            variant={view === 'all' ? 'contained' : 'outlined'}
                            color='primary'
                        >ALL Scheduled</Button>
                    </ButtonGroup>
                </Grid>
                {view === 'all' &&
                    <Grid item xs={12}>
                        <CiDataTable
                            config={{
                                key: 'ecse_id',
                                pagination: scheduled.length > 100,
                            }}
                            rows={_.filter(_.orderBy(scheduled, i => moment(i.ecse_send_date).unix(), 'asc'), {ecse_status: 'Awaiting'})}
                            columns={[
                                {
                                    heading: 'Scheduled Date',
                                    field: 'ecse_send_date',
                                    dataRef: 'ecse_send_date',
                                    fieldFormat: 'clenydate',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Scheduled Time',
                                    field: 'ecse_send_date',
                                    dataRef: 'ecse_send_date',
                                    fieldFormat: 'clenytime',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Email Campaign',
                                    field: i => i.email_campaign.ec_reference + '-' + ( i.email_campaign.ec_reference_amendment > 9 ? i.email_campaign.ec_reference_amendment : '0' + i.email_campaign.ec_reference_amendment),
                                    dataRef: 'email_campaign.ec_reference',
                                    sizeToContent: true,
                                    truncate: true,
                                },
                                {
                                    heading: '',
                                    field: 'email_campaign.ec_name',
                                    dataRef: 'email_campaign.ec_name',
                                    truncate: true,
                                },
                                {
                                    actions: c => ([
                                        {
                                            name: 'Cancel',
                                            icon: IconHelper.cancel,
                                            onClick: (s) => this.props.deployConfirmation(
                                                'Are you sure you want to cancel this scheduled run?',
                                                'Cancel Scheduled Run',
                                                ()=>this.cancelScheduled(s)
                                            )
                                        },
                                        {
                                            name: 'View Campaign',
                                            icon: IconHelper.emailCampaigns,
                                            link: `/marketing/campaigns/emailCampaigns/${c.email_campaign.ec_id}`,
                                        }
                                    ]),
                                }
                            ]}
                        />
                    </Grid>
                }
                {view === 'date' &&
                    <Grid item xs={12}>
                        <CiDataTable
                            config={{
                                key: 'ecse_send_date',
                                dropRow: {
                                    droppable: true,
                                    columns: [
                                        {
                                            colSpan: 3,
                                            field: i => 
                                                <CiDataTable
                                                    config={{
                                                        key: 'ecse_id',
                                                        noHeader: true,
                                                        style: {
                                                            color: colors.white,
                                                            backgroundColor: colors.primary,
                                                        }
                                                    }}
                                                    rows={
                                                        _.orderBy(
                                                            _.filter(
                                                                scheduled,
                                                                j => moment(i.ecse_send_date).format('DD/MM/YYYY') === moment(j.ecse_send_date).format('DD/MM/YYYY')
                                                                    && i.ecse_status === 'Awaiting'
                                                            ),
                                                            i => moment(i.ecse_send_date).unix(), 'asc'
                                                        )
                                                    }
                                                    columns={[
                                                        {
                                                            field: 'ecse_send_date',
                                                            fieldFormat: 'clenytime',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            field: i => i.email_campaign.ec_reference + '-' + ( i.email_campaign.ec_reference_amendment > 9 ? i.email_campaign.ec_reference_amendment : '0' + i.email_campaign.ec_reference_amendment),
                                                            truncate: true,
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            field: 'email_campaign.ec_name',
                                                        },
                                                        {
                                                            actions: c => ([
                                                                {
                                                                    name: 'Cancel',
                                                                    icon: IconHelper.cancel,
                                                                    onClick: (s) => this.props.deployConfirmation(
                                                                        'Are you sure you want to cancel this scheduled run?',
                                                                        'Cancel Scheduled Run',
                                                                        ()=>this.cancelScheduled(s)
                                                                    ),
                                                                    color: colors.white,
                                                                },
                                                                {
                                                                    name: 'View Campaign',
                                                                    icon: IconHelper.emailCampaigns,
                                                                    link: `/marketing/campaigns/emailCampaigns/${c.email_campaign.ec_id}`,
                                                                    color: colors.white,
                                                                }
                                                            ]),
                                                        }
                                                    ]}
                                                />

                                        }
                                    ]
                                }
                            }}
                            rows={_.orderBy(_.uniqBy(_.filter(scheduled, {ecse_status: 'Awaiting'}), i => moment(i.ecse_send_date).format('DD/MM/YYYY')), i => moment(i.ecse_send_date).unix(), 'asc')}
                            columns={[
                                {
                                    heading: 'Date',
                                    field: 'ecse_send_date',
                                    dataRef: 'ecse_send_date',
                                    fieldFormat: 'clenydate',
                                    sizeToContent: true,
                                },
                                {
                                    heading: '',
                                    field: i => moment(i.ecse_send_date).format('dddd Do MMMM YYYY'),
                                },
                                {
                                    heading: 'Scheduled',
                                    field: i => _.filter(scheduled, j => moment(i.ecse_send_date).format('DD/MM/YYYY') === moment(j.ecse_send_date).format('DD/MM/YYYY')).length,
                                    dataRef: i => _.filter(scheduled, j => moment(i.ecse_send_date).format('DD/MM/YYYY') === moment(j.ecse_send_date).format('DD/MM/YYYY')).length,
                                    sizeToContent: true,
                                    alignment: 'center',
                                }
                            ]}
                        />
                    </Grid>
                }
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success, variant)   => dispatch(deployConfirmation(message, title, success, null, variant))
    }
}

export default connect(null, mapDispatchToProps)(EmailSchedule);