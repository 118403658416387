import React from 'react';
import DataTable from 'Components/Common/DataTables/DataTable';

export default function CustomerAccountStatusList(props) {
    const { statusList, isLoading } = props;
    return(
        <DataTable 
            config={{
                key: 'cas_id',
                responsiveImportance: true,
                isLoading
            }}
            columns={[
                {
                    heading: 'Name',
                    field: rowData => rowData.cas_name
                },
                {
                    heading: 'Allow Quotes',
                    field: rowData => rowData.cas_allow_quotes,
                    fieldFormat: 'boolean',
                    alignment: 'center'
                },
                {
                    heading: 'Allow Orders',
                    field: rowData => rowData.cas_allow_orders,
                    fieldFormat: 'boolean',

                    alignment: 'center'
                }
            ]}
            rows={statusList}
        />
    );
}

