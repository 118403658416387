import React, { PureComponent } from 'react';

import DatePicker from 'Components/Common/DatePickers/DatePicker';
import Textarea from 'Components/Common/Inputs/Textarea';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';

class DeliveryDetails extends PureComponent {
    render() {
        const { formErrors, courierNotes, couriersList, courierId, despatchRequired, despatchDate, deliveryDate, handleChange, handleDateChange, handleSelectChange, pickingDate } = this.props;

        return (
            <form noValidate autoComplete="off">
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                        Delivery Details
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="normal" component="fieldset">
                        <FormLabel component="legend">Is there a known despatch date?</FormLabel>
                        <RadioGroup
                            name="despatchRequired"
                            value={despatchRequired}
                            onChange={handleChange}
                            row
                        >
                            <FormControlLabel
                                value="No"
                                control={<Radio color="primary" />}
                                label="No"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Yes"
                                control={<Radio color="primary" />}
                                label="Yes"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {despatchRequired === 'Yes' && 
                    <>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <DatePicker
                                type="date"
                                id="date"
                                name="date"
                                label="Picking Date *"
                                value={pickingDate}
                                errorText={formErrors?.['pickingDate']}
                                onChange={handleDateChange('pickingDate')}
                                autoOk={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DatePicker
                                type="date"
                                id="date"
                                name="date"
                                label="Despatch Date *"
                                value={despatchDate}
                                errorText={formErrors?.['despatchDate']}
                                onChange={handleDateChange('despatchDate')}
                                autoOk={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DatePicker
                                type="date"
                                id="date"
                                name="date"
                                label="Delivery Date *"
                                value={deliveryDate}
                                errorText={formErrors?.['deliveryDate']}
                                onChange={handleDateChange('deliveryDate')}
                                autoOk={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <AutoCompleteSelect 
                                options={couriersList}
                                label='Courier *'
                                value={courierId}
                                onChange={handleSelectChange('courierId')}
                                error={formErrors?.['courierId'] && true}
                                errorText={formErrors?.['courierId']}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Textarea
                            id="courierNotes"
                            name="courierNotes"
                            label="Any Courier Notes"
                            value={courierNotes}
                            rows={10}
                            onChange={handleChange}
                            margin="dense"
                        />
                    </Grid>
                    </>
                }
            </form>
        );
    }
}

export default DeliveryDetails;
    