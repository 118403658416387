import React, { Component }  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import API from 'API';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import Textarea from 'Components/Common/Inputs/Textarea';
import { colors } from 'Helpers/ColourHelper';
import { handleChange } from 'Functions/FormFunctions';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';


const initialState = () => ({
    formData: {
        name: '',
        text: ''
    },
    templates: [],
    formErrors: [],
    access: {
        manageTemplates: false
    },
    isLoading: true
});

class CustomerReturnsEmailTemplates extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
    }

    componentDidMount(){
        this.checkAccess();
        this.getTemplates();
    }

    getTemplates = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/engineers/emailTemplates')
            .then(result => {
                if(result.data) {
                    this.setState({
                        ...this.state,
                        templates: result.data,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            });
        })
    };

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/engineer-email-templates:manage'), 
        ])
        .then(([manageRes]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    manageTemplates: manageRes.data && manageRes.data.has_access,
                }
            })
        });
    }

    submit = () => {
        API.post('/engineers/emailTemplates', this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.props.deploySnackBar("success", "You have successfully added a new email template");
                this.checkAccess();
                this.getTemplates();
            }
            this.props.scrollToTop();
        });
    }
       
    render() {
        const { formErrors, access, formData } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Engineer Email Templates
                    </Typography>
                </Grid>
                {access.manageTemplates &&
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                {formErrors && formErrors.generic && (
                                    <React.Fragment>
                                        <Typography component={"div"} style={{color: colors.red}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </React.Fragment>
                                )}
                                <form noValidate autoComplete="off">
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Name *"
                                        value={formData.name}
                                        error={formErrors && formErrors['name'] && true}
                                        helperText={formErrors && formErrors['name']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <Textarea
                                        id="text"
                                        name="text"
                                        label="Text *"
                                        value={formData.text}
                                        rows={10}
                                        error={formErrors && formErrors['text']}
                                        onChange={this.handleChange}
                                    />
                                    <div className='buttonRow'>
                                        <Button 
                                            onClick={() => this.props.deployConfirmation('Are you sure you want to add this new email template?', 'Add A New Email Template?', this.submit)}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Add
                                        </Button>
                                    </div>
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                }    
                <Grid item xs={12}>    
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key:                    'id',
                                pagination:             true,
                                isLoading:              this.state.isLoading,
                                alternatingRowColours:  true,
                            }}
                            columns={[
                                {
                                    heading: 'Name',
                                    field: rowData => rowData.nm
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil-alt', link: '/engineers/email-templates/update/' + rowData.id, disabled: !access.manageTemplates},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.templates}
                        />
                    </PaddedPaper>
                </Grid> 
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                       => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                => dispatch(setPersistence(key, state)),
    deploySnackBar:     (variant, msg)              => {dispatch(deploySnackBar(variant, msg))},
    deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerReturnsEmailTemplates);