import React, { Component } from 'react';
import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import DatePicker from 'Components/Common/DatePickers/DatePicker';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog } from 'Actions/Dialog/Dialog';
import ViewReturn from 'Components/CustomerReturns/CustomerReturns/ViewCustomerReturn/sections/ViewReturn';
import DragFileInputMulti from 'Components/Common/Inputs/DragFileInputMulti';

import { Button, Grid, Box, Typography, TextField, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Link } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { colors } from 'Helpers/ColourHelper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconHelper from 'Helpers/IconHelper';

const initialState = () => ({
    isLoading:        true,
    cr:               {},
    formData:         {
        collection:      1,
        collectionDate:  null,
        deliveryDate:    null,
        reason:          '',
        courier:         null,
        collectionNotes: '',
        courierNotes: '',
        collectionReference: '',
        collectionFiles:  null,
        collectionTrackingUrl: null,
    
        consignments:    0,
        consignmentData: [],
    },
    lists:            {
        couriers: [],
    }
})

class BookCollectionDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount = () => {
        this.getLists();
    }

    getLists = () => {
        Promise.all([
            API.get('/sales/couriers/all'), 
            API.get(`/customerReturns/returns/${this.props.cr_id}`)
        ]).then(([cour, cr]) => {
            this.setState({
                isLoading: false,
                cr:        cr.data,
                lists:     {
                    couriers: _.map(cour.data.couriers, i => ({value:i.cour_id, label:i.cour_name, courier:i})),
                }
            });
        })
    }

    handleInputChange = field => (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: e.target.value
            }
        });
    }

    handleSelectChange = field => (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: e.value
            }
        });
    }

    handleDateChange = field => (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: moment(e)
            }
        });
    }

    changeConsignments = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                consignments: e.value
            }
        }, () => {
            let { formData } = this.state;
            let consignmentData = formData.consignmentData;

            if (formData.consignmentData.length > formData.consignments) {
                consignmentData = consignmentData.slice(0, formData.consignments);
            } else {
                for (let i = formData.consignmentData.length; i < formData.consignments; i++) {
                    consignmentData.push(null);
                }
            }
            this.setState({
                formData: {
                    ...this.state.formData,
                    consignmentData: consignmentData
                }
            });
        });
    }

    changeConsignment = index => e => {
        let { formData } = this.state;
        let consignmentData = formData.consignmentData;

        consignmentData[index] = e.target.value;

        this.setState({
            formData: {
                ...this.state.formData,
                consignmentData: consignmentData
            }
        }); 
    }

    submit = () => {

        let { formData } = this.state;
        let newFormData = new FormData();

        _.each(_.keys(formData), key => {
            if (key === 'collectionFiles'){
                _.forEach(formData[key], file => {
                    newFormData.append('files[]', file);
                })
            } else if (key === 'collectionDate' || key === 'deliveryDate') {
                newFormData.append(key, moment(formData[key]).format('YYYY-MM-DD'));
            } else if (key === 'consignmentData') {
                let trackingUrl = _.find(this.state.lists.couriers, {value: formData.courier})?.courier.cour_tracking_url
                newFormData.append(key, JSON.stringify(_.map(formData[key], (i, index) => `${trackingUrl}${i}`)));
            } else if (key === 'courier') {
                newFormData.append(key, _.find( this.state.lists.couriers, i => parseInt(i.value) === parseInt(formData[key]))?.label);
            } else {
                newFormData.append(key, formData[key]);
            }
        })

        API.post(`/customerReturns/returns/${this.props.cr_id}/confirm`, newFormData)
        .then(this.props.onSubmit);
    }

    handleFileChange = field => (drop, name, event) => {
        let newFiles = drop === true ? event.dataTransfer.files : event.target.files;
        let existingFiles = this.state.formData[field] ?? [];
        let allFiles = Array.prototype.slice.call(existingFiles).concat(Array.prototype.slice.call(newFiles))
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: allFiles
            }
        });
    }

    clearFile = name => () => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: null
            }
        });
    }

    render() {

        const { isLoading, formData, cr } = this.state;

        if (isLoading) {
            return <LoadingCircle />
        }

        return (
            <Box style={{padding: '1em'}}>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<AllIcon icon={IconHelper.arrowDown} noMargin />}
                                style={{backgroundColor: 'rgb(224, 224, 224)', color: colors.black}}
                            >
                                <Typography variant='h6'>
                                    Customer Return
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <ViewReturn
                                    cr={cr}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={6} style={{paddingTop: '1em', paddingRight: '1em', borderRight: `1px solid ${colors.disabled}`}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>
                                    Collection Details
                                </Typography> 
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker 
                                    label='Collection Date *'
                                    onChange={this.handleDateChange('collectionDate')}
                                    value={formData.collectionDate}
                                    fullWidth
                                    autoOk
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker 
                                    label='Estimated Delivery Date *'
                                    onChange={this.handleDateChange('deliveryDate')}
                                    value={formData.deliveryDate}
                                    fullWidth
                                    autoOk
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <Textarea 
                                    label='Collection Notes'
                                    onChange={this.handleInputChange('collectionNotes')}
                                    value={formData.collectionNotes}
                                    fullWidth
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} style={{paddingTop: '1em'}}>
                        <Grid container spacing={1}>
                        <Grid item xs={12}>
                                <Typography variant='h6'>
                                    Courier Details
                                </Typography> 
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteSelect 
                                    label='Collection Method *'
                                    options={this.state.lists.couriers}
                                    onChange={this.handleSelectChange('courier')}
                                    value={formData.courier}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteSelect 
                                    label="No. Tracking URL's *"
                                    options={_.map(_.range(0,11), i => _.assign({value: i, label: i}))}
                                    onChange={this.changeConsignments}
                                    value={formData.consignments}
                                    fullWidth
                                    noClear
                                />
                            </Grid>
                            {_.map(formData.consignmentData, (i, index) => 
                                <Grid item xs={12} key={index}>
                                    <TextField
                                        label={`Tracking Number`}
                                        onChange={this.changeConsignment(index)}
                                        value={i}
                                        fullWidth
                                    />
                                    {
                                        !!_.find(this.state.lists.couriers, j => parseInt(j.value) === parseInt(formData.courier))?.courier.cour_tracking_url && !!i && 
                                        <Link
                                            href={`${_.find(this.state.lists.couriers, j => parseInt(j.value) === parseInt(formData.courier))?.courier.cour_tracking_url}${i}`}
                                            target="_blank"
                                        >{_.find(this.state.lists.couriers, j => parseInt(j.value) === parseInt(formData.courier))?.courier.cour_tracking_url}{i}</Link>
                                    }
                                </Grid>
                            )}
                            <Grid item xs={12} >
                                
                                <DragFileInputMulti
                                    id="files"
                                    name="files[]"
                                    label="Upload Files:"
                                    file={formData.collectionFiles}
                                    type="file"
                                    onChange={this.handleFileChange('collectionFiles')}
                                    cancelOnClick={this.clearFile('collectionFiles')}
                                    emptyText="No files selected"
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <Textarea 
                                    label='Courier Notes'
                                    onChange={this.handleInputChange('courierNotes')}
                                    value={formData.courierNotes}
                                    fullWidth
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                    </Grid> 
                    <Grid item xs={12} className='buttonRow'>
                        <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                        <Button onClick={
                            () => this.props.deployConfirmation(
                                'Are you sure you want to book this collection?',
                                'Confirm Collection',
                                this.submit
                            )
                        } variant='contained' color='primary' disabled={!formData.courier || !formData.collectionDate || !formData.deliveryDate}>Book Collection</Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog:  ()                                    => dispatch(closeDialog()),
    deployConfirmation: (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
});

export default connect(null, mapDispatchToProps)(BookCollectionDialog);
