import React        from 'react';
import { Redirect } from 'react-router-dom';
import API          from 'API';
import _            from 'lodash';

import { Typography, Grid, FormControl, Input, InputLabel, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaterialLink   from '@material-ui/core/Link';

import PaddedPaper    from 'Components/Common/Paper/PaddedPaper';
import DataTable      from 'Components/Common/DataTables/DataTable';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import BackButton     from 'Components/Common/Buttons/BackButton';

import { convertVideoUploadsName } from 'Helpers/DocumentHelper';
import {colors}                    from 'Helpers/ColourHelper';
class ViewVideo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            video: {
                category: '',
                title: '',
                versionMatch: '',
                fileUploads: '',
                qrCode: '',
                actualVideoStatus: '',
                currentStatus: '',
                quickLinkMov: null,
                quickLinkMP4: null,
                quickLinkVUO: null
            },
            movFiles: {},
            mp4Files: {},
            vuoFiles: {},
            redirect: false
        }
    }

    componentDidMount(){
        let videoId = this.props.match.params.id;

        this.getVideo(videoId);
    }

    getVideo = (videoId) => {
        API.get(`/videos/${videoId}`)
        .then((result) => {
            if (result.data.errors) {
                this.setState({ redirect: true });
            } else {
                this.getVideoFiles(videoId);
                this.getVideoUpdateOrders(videoId);
                const fileUploads = convertVideoUploadsName(result.data.video_file_uploads);

                let actual_video_status  = result.data.latest_update_order && result.data.latest_update_order.vuo_version > 1 ? result.data.latest_update_order.vuo_version : 1;
                let current_status       = result.data.latest_file && result.data.latest_file.video_file_version > 1 ? result.data.latest_file.video_file_version : 1
    
                this.setState({
                    video: {
                        ...this.state.video,
                        category: result.data.category,
                        title: result.data.video_title,
                        fileUploads: fileUploads,
                        qrCode: result.data.video_qr_code,
                        actualVideoStatus: actual_video_status,
                        currentStatus: current_status,
                        versionMatch: (actual_video_status === current_status) ? 'Yes' : 'No',
                    }
                });
            }
        });
    };

    getVideoFiles = (videoId) => {
        API.get(`/videos/${videoId}/files`)
        .then((result) => {
            let movFiles = [];
            let mp4Files = [];
            result.data.forEach((file) => {
                if(file.video_file_mov){
                    movFiles.push({
                        version: 'v' + file.video_file_version,
                        versionNumber: file.video_file_version,
                        file: file.video_file_mov,
                        fileURL: file.file_mov_url,
                        id: file.video_file_id
                    })
                }
                if(file.video_file_mp4){
                    mp4Files.push({ version: 'v' + file.video_file_version,
                                    versionNumber: file.video_file_version,
                                    file: file.video_file_mp4,
                                    fileURL: file.file_mp4_url,
                                    id: file.video_file_id })
                }
            });
            movFiles = _.orderBy(movFiles, ['versionNumber'], ['desc']);
            mp4Files = _.orderBy(mp4Files, ['versionNumber'], ['desc']);

            this.setState({
                movFiles: movFiles,
                mp4Files: mp4Files,
                video: {
                    ...this.state.video,
                    quickLinkMov: _.first(movFiles),
                    quickLinkMP4: _.first(mp4Files)
                }
            })
        });
    };

    getVideoUpdateOrders = (videoId) => {
        API.get(`/videos/updateOrders/video/${videoId}`)
        .then((result) => {
            let vuos = [];
            result.data.forEach((vuo) => {
                let fromVersion = vuo.vuo_version-1;
                vuos.push({ number: vuo.vuo_reference,
                            title: vuo.vuo_title,
                            version: `From v${fromVersion} to v${vuo.vuo_version}`,
                            versionNumber: vuo.vuo_version,
                            url: vuo.vuo_file_url
                        })
            });
            vuos = _.orderBy(vuos, ['versionNumber'],['desc']);

            this.setState({
                vuoFiles: vuos,
                video: {
                    ...this.state.video,
                    quickLinkVUO: _.first(vuos)
                }
            })
        })
    };

    displayFileTable(files){
        return(
            <DataTable  
                config={{
                    key: 'id',
                    pagination: false,
                    plainHeader: true,
                    alternatingRowColours: true
                }}
                columns={[
                    {
                        heading: 'Version',
                        field: rowData => rowData.version
                    },
                    {
                        heading: 'File',
                        field: rowData => rowData.file
                    },
                    {
                        actions: rowData => {
                            return [
                                {name: 'Download', icon: 'download', onClick: () => { this.downloadFile(rowData.fileURL) }},
                            ]
                        }
                    }
                ]}
                rows={files}
            />
        )
    }

    downloadFile = (url) => {
        window.open(url, '_blank');
    }

    render() {
        const { video } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/videos/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            View Video
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="h6">
                                    Video Details
                                </Typography>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="category" shrink={!!(video.category && video.category.parent.video_cat_name)}>Category</InputLabel>
                                    <Input id="category" value={(video.category && video.category.parent.video_cat_name) || ''} />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="subCategory" shrink={!!(video.category && video.category.video_cat_name)}>Sub Category</InputLabel>
                                    <Input id="subCategory" value={(video.category && video.category.video_cat_name) || ''} />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="title" shrink={!!video.title}>Title</InputLabel>
                                    <Input id="title" value={video.title || ''} />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="fileUploads" shrink={!!video.fileUploads}>File Uploads</InputLabel>
                                    <Input id="fileUploads" value={video.fileUploads || ''} />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="actualVideoStatus" shrink={!!(video.actualVideoStatus)}>Actual Video Status (Based on VUO)</InputLabel>
                                    <Input id="actualVideoStatus" value={(video.actualVideoStatus && `v${video.actualVideoStatus}`) || ''} />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="currentStatus" shrink={!!(video.currentStatus)}>Current Status of Video</InputLabel>
                                    <Input id="currentStatus" value={(video.currentStatus && `v${video.currentStatus}`) || ''} />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="versionMatch" shrink={true}>Version Match (VUO &amp; Video)</InputLabel>
                                    <Input id="versionMatch" value={video.versionMatch} style={{color: video.versionMatch === 'Yes' ? colors.green : colors.red}} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <BackButton props={this.props} />
                                </div>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container item spacing={3}>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6" gutterBottom>
                                            MOV
                                        </Typography>
                                        {video.quickLinkMov && video.quickLinkMov.file ?
                                            <MaterialLink component="button" variant="body1" className='blueLink' onClick={() => {this.downloadFile(video.quickLinkMov.fileURL)}}>
                                                {video.quickLinkMov.file}
                                            </MaterialLink>
                                        :
                                            '-'
                                        }
                                        
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6" gutterBottom>
                                            MP4
                                        </Typography>
                                        {video.quickLinkMP4 && video.quickLinkMP4.file ?
                                            <MaterialLink component="button" variant="body1" className='blueLink' onClick={() => {this.downloadFile(video.quickLinkMP4.fileURL)}}>
                                                {video.quickLinkMP4.file}
                                            </MaterialLink>
                                        :
                                            '-'
                                        }
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6" gutterBottom>
                                            Video Update Order
                                        </Typography>
                                        {video.quickLinkVUO && video.quickLinkVUO.number ?
                                            <MaterialLink component="button" variant="body1" className='blueLink' onClick={() => {this.downloadFile(video.quickLinkVUO.url)}}>
                                                {video.quickLinkVUO.number}
                                            </MaterialLink>
                                        :
                                            '-'
                                        }
                                        
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6" gutterBottom>
                                            QR Code
                                        </Typography>
                                        <ImageWithError type='QR' alt='QR Code' width="100" height="100" src={video.qrCode} />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography> MOV Files</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {this.state.movFiles && this.state.movFiles.length > 0 ?
                                    this.displayFileTable(this.state.movFiles)
                                    :
                                    <Typography variant="body1">
                                        There are <b>0</b> MOV Files attached to this video
                                    </Typography>
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>MP4 Files</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {this.state.mp4Files ?
                                    this.displayFileTable(this.state.mp4Files)
                                    :
                                    <Typography variant="body1">
                                        There are <b>0</b> MP4 Files attached to this video
                                    </Typography>
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Video Update Orders</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <DataTable  
                                    config={{
                                        key: 'id',
                                        pagination: false,
                                        plainHeader: true,
                                        alternatingRowColours: true
                                    }}
                                    columns={[
                                        {
                                            heading: 'Number',
                                            field: rowData => rowData.number
                                        },
                                        {
                                            heading: 'Title',
                                            field: rowData => rowData.title
                                        },
                                        {
                                            heading: 'Version',
                                            field: rowData => rowData.version
                                        },
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Download', icon: 'download', onClick: () => { this.downloadFile(rowData.url) }},
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={this.state.vuoFiles}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                </Grid>
            );
        }
    }
}

export default ViewVideo;