import React, { useEffect, useState } from 'react';
import { useDispatch }                from 'react-redux';
import API                            from 'API';

import { formatValidationErrors } from 'Helpers/ErrorHelper';

import { Grid, DialogContent, Button } from '@material-ui/core';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import CreditNote    from 'Components/CustomerReturns/ViewCreditNote/CreditNote';
import Textarea      from 'Components/Common/Inputs/Textarea';
import EmailDialog   from 'Components/Common/Dialogs/EmailCustomerReturnsDialog';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { deploySnackBar }            from 'Actions/SnackBar/SnackBar';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

const DeclineCreditNoteDialog = ({cnId, callback}) => {

    const dispatch = useDispatch();

    const initialState = {
        creditNote:         {},
        formData: {
            notes:      '',
            status:     'Declined',
        },
        formErrors:         {},
        emailDialog: {
            customerId:         '',
            customerContactId:  '',
            type:               '',
        },
        isLoading:          true
    };

    let [state, setState] = useState(initialState);

    const {creditNote, formData, formData:{notes}, formErrors, emailDialog:{customerId, customerContactId, type}, isLoading} = state;

    const handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleClose = () => dispatch(closeDialog());

    const handleDecline = () => {
        dispatch(deployConfirmation('Are you sure you want to decline this credit note', 'Decline Order?', handleDeclineSuccess)) 
    };

    const handleDeclineSuccess = () => {
        setState(state => ({
            ...state,
            isLoading: true
        }));
        API.post(`/customerReturns/creditNote/${cnId}/changeStatus`, formData)
        .then(result => {
            if(result.data.errors) {
                setState(state => ({
                    ...state,
                    formErrors: formatValidationErrors(result.data.errors),
                    isLoading: false
                }));
            } else if(result.data.success) {
                setState(state => ({
                    ...state,
                    emailDialog: {
                        customerId:         result.data.customerId,
                        customerContactId:  result.data.contactId,
                        type:               result.data.type
                    }
                }));
            }
        });
    }

    // handle email dialog
    useEffect(() => {
        if(customerId && customerContactId && type) {
            dispatch(deploySnackBar('success', 'You have successfully declined this credit note!'));

            const dialog = <EmailDialog 
                                id=                 {cnId}
                                customerId=         {customerId}
                                customerContactId=  {customerContactId}
                                type=               {'creditNote'}
                                subType=            'Declined'
                            />
            dispatch(deployDialog(dialog, 'You Are About To Email - Credit Note Confirmation', 'warning'));
    
            if(callback) callback();
        }
    }, [cnId, customerId, customerContactId, type, dispatch, callback]);

    // handle load
    useEffect(() => {
        API.get(`/customerReturns/creditNote/${cnId}`)
        .then(result => {
            setState(state => ({
                ...state,
                creditNote: result?.data,
                isLoading:  false
            }));
        })
    }, [cnId]);

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} style={{border:'1px solid rgba(224, 224, 224, 1)', padding: '15px'}}>
                            <CreditNote cn={creditNote} />
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={12}>
                            <Textarea
                                id="notes"
                                name="notes"
                                label="Notes"
                                value={notes}
                                rows={5}
                                error={formErrors && formErrors['notes']}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button onClick={handleClose} variant="outlined" color="default">Close</Button>
                        <Button onClick={handleDecline} style={{backgroundColor:'#D61F26', color:'white'}} variant="contained">Decline</Button>
                    </Grid>
                </DialogContent>
            )}
        </React.Fragment>
    );
}

export default DeclineCreditNoteDialog;