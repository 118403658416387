import { Grid, Hidden, Typography } from '@material-ui/core';
import API from 'API';
import { closeDialog } from 'Actions/Dialog/Dialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import IconHelper from 'Helpers/IconHelper';
import { getAccess } from 'Helpers/StaffHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import Outstanding from './Sections/Outstanding/Outstanding';
import Sales from './Sections/Sales/Sales';
import YoySales from './Sections/YoySales/YoySales';
import logoName from 'Assets/Images/logo.svg'
import DashboardBK from 'Assets/Images/dashboard.m4v'
import { withStyles } from '@material-ui/styles';
import Budget from './Sections/Budget/Budget';

const dashboardToValue = {
    'dashboard-outstanding-dashboard': async ()=>{
        let i = await API.get(`/dashboard`, {params: {
            section: 'Outstanding'
        }}).then(res => {
            return `${_.sumBy(_.values(res.data), i => _.sumBy(i, j => _.isInteger(j.d) ? parseInt(j.d) : 0))} Outstanding Tasks`
        })
        return i;
    }
}

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            page: null,
            access: {
                'dashboard-outstanding-dashboard': false,
                'dashboard-sales-dashboard': false,
                'dashboard-yoys-dashboard': false,
                'dashboard-budget-dashboard': false,
            },
            previewValues: {
                'dashboard-outstanding-dashboard': null,
                'dashboard-sales-dashboard': null,
                'dashboard-yoys-dashboard': null,
                'dashboard-budget-dashboard': null,
            }
        }
    }
    componentDidMount(){
        this.getAccess();
    }
    componentDidUpdate(prevProps, prevState){
        if (prevProps.location.key !== this.props.location.key){
            this.setState({
                page: null
            });
        }
    }
    getAccess = async () => {
        this.setState({
            access: await getAccess(this.state.access),
            isLoading: false
        }, this.getPreviewValues)
    }
    getPreviewValues = async () => {
        return;
        _.each(this.state.access, async (i, idx) => {
            if (i){
                this.setState({
                    previewValues: {
                        ...this.state.previewValues,
                        [idx]: await dashboardToValue[idx]()
                    }
                })
            }
        })
    }
    componentWillUnmount(){
        this.props.closeDialog();
    }
    setPage = page => () => {
        this.setState({
            page
        });
    }
  	render() {

        const { isLoading, access, page, previewValues } = this.state;
        const loggedInStaff = this.props.loggedInStaff !== undefined ? this.props.loggedInStaff.firstName + ' ' + this.props.loggedInStaff.lastName : '';
        const { classes } = this.props;

        if (isLoading) {
            return (
                <LoadingCircle />
            )
        }

        const DashboardItemContainer = ({children, page, title, icon}) => 
            <Grid item className={classes.throbbingBox}>
                <PaddedPaper onClick={this.setPage(page)} style={{height: "100%", width:'100%', display: 'flex',flexWrap: 'wrap', alignContent: 'space-around', alignItems: 'center', opacity: 0.95}}>
                    <Grid item xs={12}>
                        <Typography variant="h5" style={{textAlign: 'center'}}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <AllIcon noMargin icon={icon} size={48} />
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        View Dashboard
                    </Grid>
                </PaddedPaper>
            </Grid>;

        switch (page) {
            case 'dashboard-outstanding-dashboard':
                return (
                    <Outstanding loggedInStaff={this.props.loggedInStaff} onBack={this.setPage(0)} />
                )
            case 'dashboard-sales-dashboard':
                return (
                    <Sales loggedInStaff={this.props.loggedInStaff} onBack={this.setPage(0)} />
                )
            case 'dashboard-yoys-dashboard':
                return (
                    <YoySales loggedInStaff={this.props.loggedInStaff} onBack={this.setPage(0)} />
                )
            case 'dashboard-budget-dashboard':
                return (
                    <Budget loggedInStaff={this.props.loggedInStaff} onBack={this.setPage(0)} />
                )
            // case 'dashboard-stock-dashboard':
            //     return (
            //         <Outstanding loggedInStaff={this.props.loggedInStaff} onBack={this.setPage(0)} />
            //     )
            default:
                return (
                    <Grid container spacing={3} style={{position: 'relative', height: 850, overflow: 'hidden', width: '100%'}}>
                        <video style={{
                            padding: 0, 
                            position: 'fixed', 
                            zIndex:1,
                            opacity: 0.7,
                            // width: '100%',
                            overflow: 'hidden',
                            height:1080,
                            width: 1920,
                            top: 0,
                            right: -20,
                        }} loop autoPlay muted>
                            <source src={DashboardBK} type="video/mp4" />
                            <source src={DashboardBK} type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                        <Grid container spacing={3}
                            style={{
                                height: 'calc(100vh - 100px)',
                                alignContent: 'space-between',
                                zIndex: 50,
                                position: 'absolute',
                                right: 0,
                                top: 10,
                            }}
                        >
                            <Hidden smDown>
                                <Grid item xs={12} style={{marginBottom: 'auto'}}>
                                    <Typography variant="h6" gutterBottom style={{textAlign: 'left'}}>
                                        Welcome <b>{loggedInStaff}</b> To Clenaware Systems, Home Of The Perfect Pint!
                                    </Typography>
                                </Grid>
                            </Hidden>
                            <Grid item xs={1} lg={3}></Grid>
                            <Grid item xs={10} lg={6}>
                                <Grid container spacing={2} justify='center'  style={{ borderRadius: 15, padding: 20}}>
                                    <Grid item xs={12} style={{textAlign: 'center'}}>
                                        <img src={logoName} width='auto' style={{maxWidth: 800}} />
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} justify='center' alignItems='center'>
                                        {_.map(access, (i, idx) => {
                                            if (i) {
                                                switch (idx){
                                                    case 'dashboard-outstanding-dashboard':
                                                        return (
                                                            <DashboardItemContainer page={idx} title='Tasks' icon={IconHelper.todo} />
                                                        )
                                                    case 'dashboard-sales-dashboard':
                                                        return (
                                                            <DashboardItemContainer page={idx} title='Sales' icon={IconHelper.sales} />
                                                        )
                                                    case 'dashboard-yoys-dashboard':
                                                        return (
                                                            <DashboardItemContainer page={idx} title='YoYs' icon={IconHelper.year} />
                                                        )
                                                    case 'dashboard-budget-dashboard':
                                                        return (
                                                            <DashboardItemContainer page={idx} title='Budget' icon={IconHelper.performance} />
                                                        )
                                                }
                                            }
                                        })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} lg={3}></Grid>
                            <Grid item xs={12} ></Grid>
                        </Grid>
                    </Grid>
                )
        }
    }
}

const mapStateToProps = state => ({
    loggedInStaff:      state.staffAuth.staff
})

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()              => dispatch(closeDialog())
})

const styles = theme => ({
    'throbbingBox': {
        width: 180, 
        height: 200,
        cursor: 'pointer',
        transition: 'width 0.2s, height 0.2s',
        '&:hover': {
            width: 190,
            height: 210,
        }
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard));

