import React  from 'react';
import moment from 'moment';

import icons from 'Helpers/IconHelper';

export function orderStatusIcons(status) {
    switch(status) {
        case 'Awaiting Approval':
            return 'file-user';
        case 'Confirmed':
            return 'user-check';
        case 'Awaiting Payment':
            return 'credit-card';
        case 'Payment Confirmed':
            return 'receipt';
        case 'Picking':
            return 'people-carry';
        case 'Awaiting Despatch':
            return 'truck';
        case 'Awaiting Invoice Completion':
            return 'file-check';
        case 'Invoice':
            return 'file-invoice-dollar';
        case 'Completed':
            return 'check';
        case 'Declined':
            return 'user-times';
        case 'Cancelled':
            return 'ban';
        default:
            throw new Error('unhandled status ' + status);
    }
}

export function orderPaymentMethodIcons(status) {
    switch (status){
        case 'PayPal':
        case 'PayPal (RP)':
            return icons.paypal;
        case 'Bank Transfer':
        case 'Bank Transfer (RP)':
            return icons.bank;
        case 'Cash':
        case 'Cash (RP)':
            return icons.cash;
        case 'Stripe':
        case 'Stripe (RP)':
            return icons.stripe;
        case 'Card':
        case 'Card (RP)':
            return icons.card;
        case '50% On Order / 50% On Completion':
            return 'balance-scale';
        case 'Account - 7 Days':        
            return 'TextToIcon:7';
        case 'Account - 14 Days':
            return 'TextToIcon:14';
        case 'Account - 30 Days':
            return 'TextToIcon:30';
        case 'Account - 60 Days':
            return 'TextToIcon:60';
        case 'Account - 90 Days':
            return 'TextToIcon:90';
        case 'Cheque':
        case 'Cheque (RP)':
            return icons.cheque;
        case 'Spread Payments - 12 Month':
        case 'Spread Payments - 12 Months':
            return 'tally';
        case 'Direct Debit':
            return icons.dd;
        case 'FOC':
        case 'Internal Order Process':
            return icons.foc;
        case 'Credit Note':
            return icons.creditNote;
        default:
            return icons[status] || icons.unknown;
    }
}

export function orderStatusTooltip(o) {
    switch(o.order_status) {
        case 'Awaiting Approval':
            return 'Awaiting Approval';
        case 'Confirmed':
            return 'Order Confirmed';
        case 'Awaiting Payment':
            return 'Awaiting Payment';
        case 'Payment Confirmed':
            return 'Payment Confirmed';
        case 'Picking':
            return 'Awaiting Picking';
        case 'Awaiting Despatch':
            return 'Awaiting Despatch';
        case 'Invoice':
            return 'Awaiting Invoice';
        case 'Completed':
            return <>Order Completed <br/> {moment(o.order_completed_datetime).format('DD/MM/YYYY')}</>;
        case 'Declined':
            return <>Order Declined <br/> {moment(o.order_cancelled_datetime).format('DD/MM/YYYY')}</>;
        case 'Cancelled':
            return <>Order Cancelled <br/> {moment(o.order_cancelled_datetime).format('DD/MM/YYYY')}</>;
        default:
            return;
    }
}

export const incotermsList = [
    {value: 'EXW', label: 'EXW - Ex Works (insert place of delivery)'},
    {value: 'FCA', label: 'FCA - Free Carrier (Insert named place of delivery)'},
    {value: 'CPT', label: 'CPT - Carriage Paid to (insert place of destination)'},
    {value: 'CIP', label: 'CIP - Carriage and Insurance Paid To (insert place of destination)'},
    {value: 'DAP', label: 'DAP - Delivered at Place (insert named place of destination)'},
    {value: 'DPU', label: 'DPU - Delivered at Place Unloaded (insert of place of destination)'},
    {value: 'DDP', label: 'DDP - Delivered Duty Paid (Insert place of destination)'},
    {value: 'FAS', label: 'FAS - Free Alongside Ship (insert name of port of loading)'},
    {value: 'FOB', label: 'FOB - Free on Board (insert named port of loading)'},
    {value: 'CFR', label: 'CFR - Cost and Freight (insert named port of destination)'},
    {value: 'CIF', label: 'CIF - Cost Insurance and Freight (insert named port of destination)'}
]

export const creditPaymentTerms = [1,2,3,8,9,7,4,10];