import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import ReminderAlert from 'Components/Reminders/Misc/ReminderAlert';
import ReminderDialog from 'Components/Reminders/Misc/ReminderDialog';
import { deployDialog } from 'Actions/Dialog/Dialog';
import IconHelper from 'Helpers/IconHelper';

const styles = theme => ({
    paper: {
        marginBottom: 24,
        borderRadius: 3
    },
    listItem: {
        padding: theme.spacing(2)
    },
    iconSecondary: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
})

const Reminder = props => { 
    let alert, date;
    if(props.model && props.model.reminder_due_datetime) {
        if(moment(props.model.reminder_due_datetime, "YYYY-MM-DD HH:mm:ss").isBefore(moment())) {
            alert = 'due';
            date = moment(props.model.reminder_due_datetime, "YYYY-MM-DD HH:mm:ss").fromNow(true)
        } 
        else if(moment(props.model.reminder_due_datetime, "YYYY-MM-DD HH:mm:ss").isBefore(moment().endOf('day'))) {
            alert = 'upcoming';
            date = moment(props.model.reminder_due_datetime, "YYYY-MM-DD HH:mm:ss").fromNow()
        } else {
            alert = 'default';
            date = moment(props.model.reminder_due_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")
        }
    }
    return (
        <Paper className={props.classes.paper}>
            <ReminderAlert type={alert} date={date} />
            <ListItem
                button
                className={props.classes.listItem}
                onClick={() => props.deployDialog(<ReminderDialog id={props.model.reminder_id} callback={props.callback} />, "", "standard", "md")}
                disableGutters
            >  
                <Grid container spacing={1} alignItems='center'>
                    <Grid item xs={12}>
                        <Typography variant="body1" component="div" gutterBottom>
                            {props.model.reminder_title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Typography variant="caption" color="textSecondary">
                                    <FALightIcon icon="bell-exclamation" className={props.classes.iconSecondary} size="small" /> {moment(props.model.reminder_due_datetime).format("DD/MM/YYYY HH:mm")}
                                </Typography>
                            </Grid>
                            {props.model.repeat && (
                                <Grid item>
                                    <Typography variant="caption" color="textSecondary">
                                        <FALightIcon icon="repeat" className={props.classes.iconSecondary} size="small" /> {props.model.repeat}
                                    </Typography>
                                </Grid>
                            )}
                            {props.model.task && (
                                <Grid item>
                                    <Typography variant="caption" color="textSecondary">
                                        <FALightIcon icon="tasks" className={props.classes.iconSecondary} size="small" /> {props.model.task.task_title}
                                    </Typography>
                                </Grid>
                            )}
                            {(props.model.reminder_linked_type && (props.model.attachment || props.model.attachment_address) ) && 
                                <Grid item>
                                    <Typography variant="caption" color="textSecondary">
                                        {props.model.reminder_linked_type === 'customer' ? 
                                           <> <FALightIcon icon={IconHelper.customer}  className={props.classes.iconSecondary} size="small"/> {props.model.attachment.cust_name}</>:
                                           <> {props.model.attachment_address.customer && <><FALightIcon icon={IconHelper.customer}  className={props.classes.iconSecondary} size="small"/> {props.model.attachment_address.customer.cust_name}</>} <FALightIcon style={{marginLeft: 8}} icon={IconHelper.building}  className={props.classes.iconSecondary} size="small"/> {props.model.attachment_address.address_name}</>
                                        }
                                    </Typography>
                                </Grid>
                            } 
                        </Grid>
                    </Grid>
                </Grid>
            </ListItem>
        </Paper>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployDialog: (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size))
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(Reminder));