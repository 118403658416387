import DataTable from 'Components/Common/DataTables/CiDataTable';
import React from 'react';
import { colors } from 'Helpers/ColourHelper';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { Grid, Typography } from '@material-ui/core';
import EmailPreview from 'Components/Common/Email/EmailPreview';

export default function(props) {

    return (
        <DataTable
            config={{
                key: 'ec_id',
                style: i => ({
                    color: i.ec_id === props.campaign.ec_id && colors.disabled,
                }),
                dropRow: {
                    droppable: i => i.ec_id !== props.campaign.ec_id,
                    columns: ([
                        {
                            colSpan: 3,
                            field: i => 
                                <Grid
                                    container
                                    spacing={1}                               
                                >
                                    <Grid item xs={12}>
                                        <PaddedPaper>   
                                            <Typography variant="h6">
                                                {i.ec_reference}-{i.ec_reference_amendment > 9 ? i.ec_reference_amendment : `0${i.ec_reference_amendment}` }
                                            </Typography>
                                            <Typography variant="body1">
                                                {i.ec_name}
                                            </Typography>
                                            <Typography variant="captions">
                                                {i.ec_desc}
                                            </Typography>
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PaddedPaper 
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <EmailPreview 
                                                json={JSON.parse(i.ec_email_json)}
                                            />
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            
                        }
                    ])
                }
            }}
            rows={props.campaign.versions}
            columns={[
                {
                    heading: 'Version',
                    field: 'ec_reference_amendment',
                    sizeToContent: true,
                    alignment: 'center'
                },
                {
                    heading: 'Name',
                    field: 'ec_name',
                },
                {
                    heading: '',
                    field: i => i.ec_latest ? 'Latest' : '',
                    sizeToContent: true,
                    alignment: 'center'
                },             
            ]}
        />
    )

}