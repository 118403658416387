import React, {Component} from 'react';
import { connect }        from 'react-redux';
import uuidv4             from 'uuid/v4';
import _                  from 'lodash';
import moment             from 'moment';
import API                from 'API';

import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, TextField, Typography, DialogActions, Button, Grid, FormControl, InputLabel, Input, DialogTitle, 
         Dialog, DialogContent, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

import { deploySnackBar }            from 'Actions/SnackBar/SnackBar';
import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';
import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';

import LoadingCircle                     from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper                       from 'Components/Common/Paper/PaddedPaper';
import InvoicePartItems                  from 'Components/Sales/Order/InvoiceApproval/InvoicePartItems';
import ConfirmationDialog                from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar                          from 'Components/Common/SnackBars/SnackBar';
import DatePicker                        from 'Components/Common/DatePickers/DatePicker';
import EmailDialog                       from 'Components/Common/Dialogs/EmailDialog';
import Textarea                          from 'Components/Common/Inputs/Textarea';
import BackButton                        from 'Components/Common/Buttons/BackButton';
import SelectCustomerAddressFormSwitcher from 'Components/Customers/SelectCustomerAddressForm/SelectCustomerAddressFormSwitcher';
import CopyToClipboard                   from 'Components/Common/CopyToClipboard/CopyToClipboard';
import PDFViewer                         from 'Components/Common/PDFViewer/PDFViewer';
import AllIcon                           from 'Components/Common/Icons/AllIcon';

import PaymentTermsDialog from './PaymentTermsDialog';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { pdfFromBase64 }          from 'Helpers/PDFHelper';
import { colors }                 from 'Helpers/ColourHelper';

const styles = theme => ({
    td : {
        textAlign: 'right'
    },
    label: {
        color: 'black'
    },
    underline: {
        "&&&:before": {
          borderBottom: "none"
        },
        "&&:after": {
          borderBottom: "none"
        }
      },
      subTotals : {
        fontWeight: 'bold'
    },
});

const initialState = {
    invoice: {},
    formData:  {
        // Customer
        customerAddressType: '',
        customerAddress: '',
        customerContact: '',
        customerPhone: '',
        customerMobile: '',
        customerEmail: '',
        customerAddressData: {
            type:       null,
            address:    null,
            contact:    null,
            key:        0,
            edited:     false
        },
        // Delivery Customer
        deliveryCustomerAddressType: '',
        deliveryCustomerAddress: '',
        deliveryCustomerContact: '',
        deliveryCustomerPhone: '',
        deliveryCustomerMobile: '',
        deliveryCustomerEmail: '',
        deliveryAddressData: {
            type:       null,
            address:    null,
            contact:    null,
            key:        0,
            edited:     false
        },
        // Details
        invoiceDetails: [],
        // Misc
        purchaseOrderNumber: '',
        invoiceDate: null,
        editDeliveryEnabled: false,
        editAddressEnabled: false,
        sageInvoiceNumber: '',
        previewMode: false,
        recurringInvoice: false,
        paymentTerm:            null,
        paymentTermEndDate:     null,
        paymentTermPayments:    0,
        paymentTermAmount:      0,
        prPaid:                 false,
        changePrimaryContact:   false,
    },
    lists: {
        customerAddressList: [],
        customerContactList: [],
        deliveryAddressList: [],
        deliveryContactList: [],
    },
    access: {
        recurringInvoice: false
    },
    customer: {},
    customerContacts: {},
    deliveryCustomerContacts: {},
    customerAddresses: [],
    customerContactList: [],
    registeredContactList: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: false,
    notesDialogOpen: false,
    notesDialogData: {}, 
    // Email Dialog
    emailDialogOpen: false,
    emailDialogID: '',
    emailDialogCustomerId: '',
    emailDialogContactId: '',
}

class InvoiceApproval extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getInvoice();
        this.getAccess();
    }

    getAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/recurring-invoice')
        ])
        .then(([recRes]) =>  {
            recRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        recurringInvoice: recRes.data.has_access
                    }
                });
        })
    }

    setPaymentTerms = data => {
        this.setState({
            formData: {
                ...this.state.formData,
                paymentTerm:            data.paymentTerm,
                paymentTermEndDate:     data.endDate,
                paymentTermPayments:    data.payments,
                paymentTermAmount:      data.singlePayment
            }
        })
    }

    getInvoice = () => {
        this.setState({
            ...this.state,
            isLoading: true
        },
        () => {
            API.get('/sales/orders/invoice/' + this.props.match.params.invoiceId)
            .then(result => {
                if(result.data) {
                    this.setState({
                        invoice: result.data,
                        formData: {
                            ...this.state.formData,
                            deliveryAddressData: {
                                type:       'Registered',
                                address:    result.data.invoice_cust_address,
                                contact:    result.data.invoice_cust_contact_id,
                                key:        uuidv4(),
                                edited:     false
                            },
                            customerAddressData: {
                                type:       result.data.invoice_delivery_cust_address_type,
                                address:    result.data.invoice_delivery_cust_address_id,
                                contact:    result.data.invoice_delivery_cust_contact_id,
                                key:        uuidv4(),
                                edited:     false
                            },
                            // Misc
                            purchaseOrderNumber: result.data.invoice_po_details,
                            invoiceDate: null,
                            invoiceDetails: result.data.invoice_details
                        }
                    },
                    () => {
                        API.get(`/customers/addresses/${result.data.invoice_cust_accounts_id}`)
                        .then(res => {
                            if (res.data?.primary_invoice_contact?.length > 0) {
                                this.setState({
                                    formData: {
                                        ...this.state.formData,
                                        customerAddressData: {
                                            ...this.state.formData.customerAddressData,
                                            contact:    res.data.primary_invoice_contact[0].contact_id,
                                        },
                                    },
                                    invoice: {
                                        ...this.state.invoice,
                                        invoice_cust_contact_id:        res.data.primary_invoice_contact[0].contact_id,
                                        invoice_cust_contact_email:     res.data.primary_invoice_contact[0].contact_email,
                                        invoice_cust_contact_mobile:    res.data.primary_invoice_contact[0].contact_mobile,
                                        invoice_cust_contact_name:      res.data.primary_invoice_contact[0].contact_name,
                                        invoice_cust_contact_phone:     res.data.primary_invoice_contact[0].contact_telephone,

                                    }
                                });
                            }
                            API.get(`/customers/${this.state.invoice.invoice_cust_id}`)
                            .then(custRes => {
                                let customerAddresses = [];
                                customerAddresses = customerAddresses.concat(   custRes.data.account_addresses, 
                                                                                custRes.data.trading_addresses,
                                                                                custRes.data.site_addresses,
                                                                                [custRes.data.registered_address]);
                                this.setState({
                                    customer: custRes.data,
                                    isLoading: false,
                                    customerAddresses
                                }, ()=> {
                                    if (!this.state.importantNoteShown && custRes.data.cust_important_info) {this.displayImportantInfo();} else {
                                        if (_.indexOf([10,4], this.state.invoice.order.order_payment_term_id) > -1){
                                            //display set payment terms dialog
                                            this.props.deployDialog(<PaymentTermsDialog invoice={this.state.invoice} callback={this.setPaymentTerms}/>, 'Set Payment Terms', null, 'xs')
                                        }
                                    }
                                });
                            });
                        });
                    });
                }
                else {
                    this.props.history.push(`/sales/order/invoice`);
                }
            });
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if(fieldName === 'customerContact'){
                let contact = this.state.formData.customerContact ? _.find(this.state.customerContacts, { 'contact_id': this.state.formData.customerContact }) : null;
                this.setState({
                    formData:{
                        ...this.state.formData,
                        customerPhone: contact && contact.contact_telephone ? contact.contact_telephone : '',
                        customerMobile: contact && contact.contact_mobile ? contact.contact_mobile : '',
                        customerEmail: contact && contact.contact_email ? contact.contact_email : '',
                    }
                });
            }
            if(fieldName === 'customerAddressType' || fieldName === 'deliveryCustomerAddressType'){
                let addresses = [];
                if(this.state.formData[fieldName]){
                    switch(this.state.formData[fieldName]){
                        case 'Registered':
                            addresses = [this.state.customer.registered_address]
                        break;
                        case 'Accounts':
                            addresses = this.state.customer.account_addresses
                        break;
                        case 'Trading':
                            addresses = this.state.customer.trading_addresses
                        break;
                        case 'Site':
                            addresses = this.state.customer.site_addresses
                        break;
                        default:
                        break;
                    }
                }
                
                if(fieldName === 'customerAddressType'){
                    let customerAddressList = _.map(addresses, (el) => {
                        return _.assign({
                            value: el.address_id,
                            label: `${el.address_name} - ${el.address_line_one}, ${el.address_town}, ${el.address_postcode}`
                        });
                    });
                    this.setState({
                        lists: {
                            ...this.state.lists,
                            customerAddressList, 
                        },
                        formData:{
                            ...this.state.formData,
                            customerAddress: '',
                            customerContact: '',
                            customerPhone: '',
                            customerMobile: '',
                            customerEmail: '',
                        }
                    });
                }
                else if(fieldName === 'deliveryCustomerAddressType') {
                    let deliveryAddressList = _.map(addresses, (el) => {
                        return _.assign({
                            value: el.address_id,
                            label: `${el.address_name} - ${el.address_line_one}, ${el.address_town}, ${el.address_postcode}`
                        });
                    });
                    this.setState({
                        lists: {
                            ...this.state.lists,
                            deliveryAddressList, 
                        },
                        formData:{
                            ...this.state.formData,
                            deliveryCustomerAddress: '',
                            deliveryCustomerContact: '',
                            deliveryCustomerPhone: '',
                            deliveryCustomerMobile: '',
                            deliveryCustomerEmail: '',
                        }
                    });
                }
                
            }
            if(fieldName === 'customerAddress' || fieldName === 'deliveryCustomerAddress'){
                let address = selectedOption ? _.find(this.state.customerAddresses, { 'address_id': selectedOption.value }) : null;

                if(fieldName === 'customerAddress'){
                    let customerContactList = _.map(address && address.active_contacts, (el) => {
                        return _.assign({
                            value: el.contact_id,
                            label: el.contact_name
                        });
                    });
                    this.setState({
                        lists: {
                            ...this.state.lists,
                            customerContactList,
                        },
                        formData:{
                            ...this.state.formData,
                            customerContact: '',
                            customerPhone: '',
                            customerMobile: '',
                            customerEmail: ''
                        },
                        customerContacts: address && address.active_contacts,
                    });
                } else if(fieldName === 'deliveryCustomerAddress'){
                    let deliveryContactList = _.map(address && address.active_contacts, (el) => {
                        return _.assign({
                            value: el.contact_id,
                            label: el.contact_name
                        });
                    });
                    this.setState({
                        lists: {
                            ...this.state.lists,
                            deliveryContactList,
                        },
                        formData:{
                            ...this.state.formData,
                            deliveryCustomerContact: '',
                            deliveryCustomerPhone: '',
                            deliveryCustomerMobile: '',
                            deliveryCustomerEmail: ''
                        },
                        deliveryCustomerContacts: address && address.active_contacts,
                    });
                }
            }
            if(fieldName === 'customerContact'){
                let contact = this.state.formData.customerContact ? _.find(this.state.customerContacts, { 'contact_id': this.state.formData.customerContact }) : null;
                this.setState({
                    formData:{
                        ...this.state.formData,
                        customerPhone: contact && contact.contact_telephone ? contact.contact_telephone : '',
                        customerMobile: contact && contact.contact_mobile ? contact.contact_mobile : '',
                        customerEmail: contact && contact.contact_email ? contact.contact_email : '',
                    }
                });
            }
            if(fieldName === 'deliveryCustomerContact'){
                let contact = this.state.formData.deliveryCustomerContact ? _.find(this.state.deliveryCustomerContacts, { 'contact_id': this.state.formData.deliveryCustomerContact }) : null;
                this.setState({
                    formData:{
                        ...this.state.formData,
                        deliveryCustomerPhone: contact && contact.contact_telephone ? contact.contact_telephone : '',
                        deliveryCustomerMobile: contact && contact.contact_mobile ? contact.contact_mobile : '',
                        deliveryCustomerEmail: contact && contact.contact_email ? contact.contact_email : '',
                    }
                });
            }
        });
    }

    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    setPrimaryContact = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                changePrimaryContact: true
            },
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({ 
            formData: {
                ...this.state.formData,
                previewMode: false
            },
            confirmationOpen: false 
        });
        this.submit();
    }

    handlePreview = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                previewMode: true
            }
        },
        () => {
            this.submit();
        });
    }

    handleToggleEditMode = name => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: !this.state.formData[name]
            }
        }, 
        () => {
            if(!this.state.formData.editAddressEnabled){
                this.setState({
                    formData: {
                        ...this.state.formData,
                        customerAddressType: '',
                        customerAddress: '',
                        customerContact: '',
                        customerPhone: '',
                        customerMobile: '',
                        customerEmail: ''
                    }
                });
            }
            if(!this.state.formData.editDeliveryEnabled){
                this.setState({
                    formData: {
                        ...this.state.formData,
                        deliveryCustomerAddressType: '',
                        deliveryCustomerAddress: '',
                        deliveryCustomerContact: '',
                        deliveryCustomerPhone: '',
                        deliveryCustomerMobile: '',
                        deliveryCustomerEmail: '',
                    }
                });
            }
        });
    }

    submit = () => {
        this.setState({
            formErrors: [],
            isLoading: true
        },
        () => {

            API.post(`/sales/orders/invoice/${this.state.invoice.invoice_id}/approve`, this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                    this.props.scrollToTop();
                }
                else {
                    if(result.data.pdf){
                        this.setState({
                            isLoading: false
                        },
                        () => {
                            this.props.deployDialog(
                                <Grid container xs={12}  style={{padding:'1em'}}>
                                    <Grid item xs={12} style={{padding:'1em'}}>
                                        <PDFViewer disableDownload pdfData={result.data.pdf} style={{ height:'100%', maxHeight: 700, minHeight: 400, maxWidth: '100%' }} />
                                    </Grid>
                                    <Grid item xs={12} className='buttonRow'>
                                        <Button variant="outlined" onClick={() => pdfFromBase64(result.data.pdf,result.data.pdfName)} ><AllIcon icon='download' />Download</Button> 
                                        <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                                    </Grid>
                                </Grid>    
                            ,'', null, 'md')
                        });
                    }
                    else {
                        this.setState({
                            snackbarOpen: true,
                            emailDialogOpen: true,
                            // Email
                            emailDialogID:          this.state.invoice.invoice_id,
                            emailDialogCustomerId:  this.state.invoice.invoice_cust_id,
                            emailDialogContactId:   this.state.formData.customerAddressData.contact
                        }, () => {
                            this.handleEmailOpen();
                        });
                    }
                }
            });
        });
    }

    handleEmailOpen = () => {
        //get email cc
        API.get(`/customers/addresses/${this.state.invoice.invoice_cust_accounts_id}`)
        .then(res => {
            const dialog = <EmailDialog
                        defaultEmailCc={_.map(res.data.primary_invoice_c_c, 'contact_id')}
                        emailDialogOpen={this.state.emailDialogOpen}
                        handleEmailDialogClose={this.handleEmailClose}
                        customerId={this.state.emailDialogCustomerId}
                        customerContactId={this.state.emailDialogContactId}
                        type="invoice"
                        id={this.state.emailDialogID}
                        redirectPath='/sales/order/invoice'
                        accountsEmail
                    />
            this.props.deployDialog(dialog, 'You Are About To Email - Invoice', 'success');
        });
       
    }

    handleEmailClose = () => {
        this.props.closeDialog();
    }

    handleDateChange = fieldName => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: moment(date).format('YYYY-MM-DD')
            }
        });
    };

    handleNotesDialogOpen = (rowData) => {
        this.setState({notesDialogOpen: true, notesDialogData: rowData})
    }
    handleNotesDialogClose = () => this.setState({notesDialogOpen: false});
    handleNotesDialogSuccess = () => {
        let invoiceDetails = [...this.state.formData.invoiceDetails];

        invoiceDetails[this.state.notesDialogData.rowIdx] = {
            ...invoiceDetails[this.state.notesDialogData.rowIdx],
            invoice_detail_notes: this.state.notesDialogData.invoice_detail_notes
        };

        this.setState({
            formData: {
                ...this.state.formData,
                invoiceDetails
            },
            notesDialogOpen: false
        });
    }
    handlePartRowChange = fieldName => e => {
        const { name, value } = e.target;

        this.setState({
            notesDialogData: {
                ...this.state.notesDialogData,
                [fieldName]: value
            }
        });
    };
       
    handleCheckbox = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.id]: !this.state.formData[e.target.id]
            }
        });
    }

    handleAddressChange = t => data => { 
        let invoice = (t == 'customerAddressData') ? {
                ...this.state.invoice,
                invoice_cust_name:                data.addressName,
                invoice_cust_address:             data.fullAddress,
                invoice_cust_accounts_id:         data.addressId,
                invoice_cust_contact_name:        data.contactName,
                invoice_cust_contact_email:       data.email,
                invoice_cust_contact_phone:       data.phone,
                invoice_cust_contact_mobile:      null,
                invoice_system_generated_address: this.state.invoice.invoice_cust_contact_name !== data.contactName ? false : this.state.invoice.invoice_system_generated_address,
                invoice_contact_changed:          this.state.invoice.invoice_cust_contact_name !== data.contactName ? true : this.state.invoice.invoice_contact_changed,
            } : {
                ...this.state.invoice,
                invoice_delivery_cust_address:              data.fullAddress,
                invoice_delivery_cust_contact_name:         data.contactName,
                invoice_delivery_cust_contact_email:        data.email,
                invoice_delivery_cust_contact_phone:        data.phone,
                invoice_delivery_cust_contact_mobile:       null,
                invoice_delivery_cust_address_name:         data.addressName
            };
        this.setState({invoice,formData: {...this.state.formData, [t]: {...data, edited: true}}});}

    displayImportantInfo = () => {
        this.setState({importantNoteShown: true}, () => {
            this.props.deployDialog(
            <>
                <Typography component="p">
                    {this.state.customer?.cust_important_info && this.state.customer?.cust_important_info.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
                </Typography>
                <Button variant='outlined' style={{float: 'right'}} onClick={()=>{
                    this.props.closeDialog();
                    if (_.indexOf([10,4], this.state.invoice.order.order_payment_term_id) > -1){
                        //display set payment terms dialog
                        this.props.deployDialog(<PaymentTermsDialog invoice={this.state.invoice} callback={this.setPaymentTerms}/>, 'Set Payment Terms', null, 'xs')
                    }
                }}>Close</Button>
            </>
            , 'Important Information', null, 'sm', {backgroundColor : '#fcc6c5', color: '#000'})
        });
    }

    render() {
        const { classes } = this.props;
        const { formErrors, invoice, formData, customer } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Approve Invoice
                    </Typography>
                </Grid>
                {(this.state.isLoading && (
                    <div>
                        <LoadingCircle />    
                    </div>
                )) || (  
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    {formErrors && formErrors.generic && (
                                        <React.Fragment>
                                            <Typography component={"div"} style={{color: colors.red}}>
                                                {formErrors.generic}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                    <Grid container item spacing={3}>
                                        <Grid item xs={4}>
                                            <Grid item lg={12}>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel shrink={true} className={classes.label}>Invoice Number</InputLabel>
                                                    <Input value={invoice.invoice_number} disabled />
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel shrink={true} className={classes.label}>Account Number</InputLabel>
                                                    <Input value={invoice.order?.order_cust_account_reference} disabled />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid item lg={12}>
                                                <DatePicker
                                                    type="date"
                                                    id="invoiceDate"
                                                    name="invoiceDate"
                                                    label="Invoice / Tax Date"
                                                    value={formData.invoiceDate}
                                                    errorText={formErrors && formErrors['invoiceDate']}
                                                    onChange={this.handleDateChange('invoiceDate')}
                                                    autoOk={true}
                                                    disableFuture={true}
                                                />
                                            </Grid>
                                            <Grid item lg={12}>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel shrink={true} className={classes.label}>Payment Terms</InputLabel>
                                                    <Input value={`${invoice.order?.order_payment_terms}${(invoice.order?.order_payment_terms == "Prepayment" || invoice.order?.order_payment_terms == "Card Payment") ? 
                                                        ` - ${invoice.order.authorised_order_payment.op_txn_method}` : 
                                                        ''}`} disabled />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} container style={{alignItems: 'flex-end'}}>
                                            <Grid item xs={11}>
                                                <TextField 
                                                    id="purchaseOrderNumber"
                                                    name="purchaseOrderNumber"
                                                    label="Order Number *"
                                                    value={formData.purchaseOrderNumber}
                                                    onChange={this.handleChange}
                                                    error={formErrors && formErrors['purchaseOrderNumber'] && true}
                                                    helperText={formErrors && formErrors['purchaseOrderNumber']}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={1} >
                                                <CopyToClipboard copy={formData.purchaseOrderNumber}/>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel shrink={true} className={classes.label}>Delivery Reference</InputLabel>
                                                    <Input value={
                                                        invoice?.invoice_delivery_reference ? invoice?.invoice_delivery_reference : 
                                                            (invoice?.order?.order_no_despach_reason || '') 
                                                    } disabled />
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <TextField 
                                                    id="sageInvoiceNumber"
                                                    name="sageInvoiceNumber"
                                                    label="Sage Invoice Number *"
                                                    value={formData.sageInvoiceNumber}
                                                    onChange={this.handleChange}
                                                    error={formErrors && formErrors['sageInvoiceNumber'] && true}
                                                    helperText={formErrors && formErrors['sageInvoiceNumber']}
                                                    margin="normal"
                                                    fullWidth
                                                    onInput = {(e) =>{
                                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,7)
                                                    }}
                                                    type='number'
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3} alignItems='stretch'>
                            {!this.state.isLoading && customer?.account_status?.cas_name != 'Active - Verified' &&
                                <Grid item xs={12} style={{padding:'1em', color: colors.white, backgroundColor: colors.red, margin: '0px 1em'}}>
                                    <Typography style={{fontWeight:'bold'}}>{customer.cust_name} is {customer?.account_status?.cas_name }</Typography>
                                </Grid>
                            }
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper style={{height:'100%'}}>
                                    <SelectCustomerAddressFormSwitcher
                                        setPrimaryContact={this.setPrimaryContact}
                                        customerId=       {invoice.invoice_cust_id}
                                        callback=         {this.handleAddressChange('customerAddressData')}
                                        name=             'customerAddress'
                                        header=           'Invoice Address'
                                        formErrors=       {formErrors}
                                        cname=            'Company'
                                        addressName=      {invoice.invoice_cust_name}
                                        address=          {invoice.invoice_cust_address}
                                        contact=          {invoice.invoice_cust_contact_name}
                                        email=            {invoice.invoice_cust_contact_email}
                                        phone=            {invoice.invoice_cust_contact_phone}
                                        mobile=           {invoice.invoice_cust_contact_mobile}
                                        systemGenerated = {invoice.invoice_system_generated_address}
                                    />
                                </PaddedPaper>
                                
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper style={{height:'100%'}}>
                                    <SelectCustomerAddressFormSwitcher
                                        customerId= {invoice.invoice_cust_id}
                                        callback=   {this.handleAddressChange('deliveryAddressData')}
                                        name=       'deliveryAddress'
                                        header=     'Delivery Address'
                                        formErrors= {formErrors}
                                        cname=      'Delivery'
                                        addressName={invoice.invoice_delivery_cust_address_name}
                                        address=    {invoice.invoice_delivery_cust_address}
                                        contact=    {invoice.invoice_delivery_cust_contact_name}
                                        email=      {invoice.invoice_delivery_cust_contact_email}
                                        phone=      {invoice.invoice_delivery_cust_contact_phone}
                                        mobile=     {invoice.invoice_delivery_cust_contact_mobile}
                                    />
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Grid container xs={12} item justify="flex-end">
                                    <Grid item xs={12}>
                                        <InvoicePartItems 
                                            invoiceDetails={formData.invoiceDetails}
                                            orderType={invoice.order?.order_type}
                                            handleNotesDialogOpen={this.handleNotesDialogOpen}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="right" className={classes.td}>
                                                        <Typography variant="subtitle1" className={classes.subTotals}>
                                                            Total Net
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.td}>
                                                        <Typography variant="subtitle1" className={classes.subTotals}>
                                                            £{invoice.invoice_sub_total}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="right" className={classes.td}>
                                                        <Typography variant="subtitle1" className={classes.subTotals}>
                                                            Total VAT ({invoice?.vat_value?.vat_value_percentage}%)
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell  align="right" className={classes.td}>
                                                        <Typography variant="subtitle1" className={classes.subTotals}>
                                                            £{invoice.invoice_vat}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{backgroundColor:'#f2f2f2'}}>
                                                    <TableCell align="right" className={classes.td}>
                                                        <Typography variant="subtitle1" className={classes.subTotals}>
                                                            Invoice Total
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.td}>
                                                        <Typography variant="subtitle1" className={classes.subTotals}>
                                                            £{invoice.invoice_total_incl_vat}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    
                                </Grid>
                                <Grid container item xs={6} justify="flex-start">
                                        <Grid item xs={12}>
                                            <br />
                                            {!!this.state.invoice?.order?.payment_term?.payment_terms_allow_recurring_invoice &&
                                                <>
                                                    {this.state.access.recurringInvoice &&
                                                        <FormGroup row >
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox 
                                                                        id="recurringInvoice"
                                                                        checked={formData.recurringInvoice} 
                                                                        onChange={this.handleCheckbox} 
                                                                        value={formData.recurringInvoice}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Make This A Recurring Invoice"
                                                            />
                                                        </FormGroup>
                                                    }
                                                </>
                                            }
                                            <FormGroup row >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            id="prPaid"
                                                            checked={formData.prPaid} 
                                                            onChange={this.handleCheckbox} 
                                                            value={formData.prPaid}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="This Invoice Has Already Been Paid"
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                {formErrors['part_locked'] &&
                                    <Grid container xs={12}>
                                        <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>
                                            Some parts are locked, please try again later.
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container spacing={6} className='buttonRow'>
                                    
                                    <Grid item>
                                        <Button onClick={this.handlePreview}
                                                variant="contained"
                                                color="primary">
                                            Preview
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <BackButton props={this.props} />
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={this.handleConfirmationOpen}
                                                variant="contained"
                                                color="primary">
                                            Approve
                                        </Button>
                                    </Grid>
                                    
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        {/* CONFIRMATION DIALOG */}
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen} 
                            success={this.handleConfirmationSuccess} 
                            close={this.handleConfirmationClose} 
                            title="Approve Invoice?" 
                            message="Are you sure you want to approve this invoice?"
                        />
                        <Dialog 
                            open={this.state.notesDialogOpen} 
                            onClose={this.handleNotesDialogClose} 
                            fullWidth={true} 
                            scroll="body"
                            disableBackdropClick
                            disableEscapeKeyDown
                        >
                            <DialogTitle>{this.state.notesDialogData.invoice_detail_part_number} - {this.state.notesDialogData.invoice_detail_part_description}</DialogTitle>
                            <DialogContent>
                                <Textarea
                                    name="partNotes"
                                    value={this.state.notesDialogData.invoice_detail_notes}
                                    rows={3}
                                    label="Notes"
                                    onChange={this.handlePartRowChange('invoice_detail_notes')}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleNotesDialogClose} variant="outlined" color="default">Close</Button>
                                <Button onClick={this.handleNotesDialogSuccess} color="primary" variant="contained">Update</Button>
                            </DialogActions>
                        </Dialog>
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen}
                            onClose={this.handleSnackbarClose}
                            message='You have successfully approved this invoice'
                        />
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar:     (variant, msg)                              => dispatch(deploySnackBar(variant, msg)),
        deployDialog:       (content, title, variant, size, style=null) => dispatch(deployDialog(content, title, variant, size, style)),
        closeDialog:        ()                                          => dispatch(closeDialog()),
        deployConfirmation: (message, title, success, close)            => dispatch(deployConfirmation(message, title, success, close)),
    }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(InvoiceApproval));