import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import API from '../../../API';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SnackBar from '../../Common/SnackBars/SnackBar';
import DatePicker from '../../Common/DatePickers/DatePicker';
import { Divider, TableFooter } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import { Link } from 'react-router-dom';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import IconButton from '@material-ui/core/IconButton';
import { Redirect  } from 'react-router-dom';
import PartsPurchaseOrder from './../PartsPurchaseOrder';
import DeliverySchedule from './../DeliverySchedule';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import BackButton from 'Components/Common/Buttons/BackButton';
import {colors} from 'Helpers/ColourHelper';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    poAlign: {
        marginLeft: '1.01em'
    },
    heading: {
        paddingTop: theme.spacing(3),
    },
    td : {
        border: 'none'
    },
    th : {
        fontWeight: 'bold',
        fontSize: '0.9em'
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        },
        poAlign: {
            marginLeft: 0
        },
        responsiveTable: {
            maxWidth: '100%', 
            overflowX: 'auto'
        }
    }
});

const initialState = () => ({
    formData:  {
        partRows: [],
        otherRows: [],
        overallPrice: 0
    },
    formDataOG: {},
    schedule: {
        quantity: 0,
        price: 0,
        date: null
    },
    ppo: {},
    formErrors: [],
    confirmationOpen: false,
    notificationOpen: true,
    snackbarOpen: false,
    redirect: false,
    successRedirect: false,
    showPPO: false,
    showDS: false,
    isLoading: true
});

class UpdatePartsOrderDeliverySchedule extends React.Component {
    constructor(props) {
        super(props)
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `PartsOrder:updateDeliverySchedule:${this.props.match.params.id}`;
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.getPPO();
    }

    getPPO = () => {
        API.get('/partsOrdering/' + this.props.match.params.id)
        .then(result => {
            if(result.data.errors) {
                this.setState({ redirect: true });
            } else if(result.data) {
                // Seperate Part Items & Other Items
                let partRows            = [];
                let otherRows           = [];
                _.map(result.data.parts_order_details, (el) => {
                    if(el.ppo_detail_part_id > 0){
                        let schedule = [];
                        schedule.push({
                            quantity: el.new_part_quantity,
                            price: el.new_part_total_price,
                            date: null
                        });

                        partRows.push({
                            detailId: el.ppo_detail_id,
                            part: el.ppo_detail_part_number + ' - ' + el.ppo_detail_description,
                            quantity: el.ppo_detail_qty,
                            unitPrice: el.ppo_detail_unit_price,
                            totalPrice: el.ppo_detail_total_price,
                            nominalCode: el.nominal_code && `${el.nominal_code.nominal_code} - ${el.ppo_detail_nominal_name} (${el.nominal_code.category.nom_cat_name})`,
                            newPartQuantity: el.new_part_quantity,
                            newPartTotalPrice: el.new_part_total_price,
                            supplierReference: el.ppo_detail_supplier_reference,
                            drawingNo: el.ppo_detail_drawing_no,
                            notes: el.ppo_detail_notes,
                            deliveryType: el.delivery_schedule[0].schedule_delivery_type,
                            dialogOpen: false,
                            schedule: schedule,
                            originalDeliveryType: el.delivery_schedule[0].schedule_delivery_type,
                            scheduleButtonColor: '#43a047',
                            scheduleValiditity: 'valid',
                            unitsLeft: el.new_part_quantity,
                            scheduleDeliveryStatus: el.ppo_detail_schedule_delivery_status,
                            DeliveryDate:       schedule[0],
                            supplierToConfirm:  schedule[0]
                        });
                    }
                    else {
                        otherRows.push({
                            description: el.ppo_detail_description,
                            nominalCode: el.nominal_code && `${el.nominal_code.nominal_code} - ${el.ppo_detail_nominal_name} (${el.nominal_code.category.nom_cat_name})`,
                            quantity: el.ppo_detail_qty,
                            unitPrice: el.ppo_detail_unit_price,
                            totalPrice: el.ppo_detail_total_price
                        });
                    }
                });

                var formData = {
                    ...this.state.formData,
                    partRows:       partRows,
                    otherRows:      otherRows,
                    overallPrice:   result.data.ppo_total_price,
                }

                this.setState({
                    ppo: result.data,
                    isLoading:  false,
                    formData,
                    formDataOG: formData
                });
            }
        });
    }

    handleRowScheduleChange = (idx, dsIdx, blur = false) => e => {
        const { value } = e.target;
        let partRows = [...this.state.formData.partRows];
        let schedule = [...this.state.formData.partRows[idx].schedule];

        schedule[dsIdx] = {
            ...schedule[dsIdx],
            quantity: (!blur) ? value : parseFloat(value).toFixed(2),
            price:    parseFloat(partRows[idx].unitPrice * value).toFixed(2),
        };

        partRows[idx] = {
            ...partRows[idx],
            schedule: schedule
        };
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: partRows 
            }
        },
        () => {
            this.handleShowScheduleError(idx);
        });
    };

    handleTabChange = idx => e => {
        
        const { name, value }       = e.target;
        let {partRows}              = this.state.formData;
        partRows[idx].deliveryType  = value;

        let formData = {
            ...this.state.formData,
            partRows,
        }

        this.setState({
            formData
        }, ()=> {this.handleShowScheduleError(idx)})
    }

    handleShowScheduleError = (idx) => {
        let {partRows, DeliveryDate}  = this.state.formData;
        let show        = false;
        let partRow     = partRows[idx];

        switch( partRow.deliveryType ){
            case 'Schedule':
                if (_.sumBy(partRow?.schedule, i =>  parseFloat(i?.quantity) || 0).toFixed(2) !== partRow.unitsLeft) show = true;
                _.forEach(partRow.schedule, i => {
                    if ( !i.date || !i.quantity || i.quantity === 0.00  ) show = true;
                })
            
            break;
            case 'Delivery Date':
                if (!partRow?.DeliveryDate.date || moment(partRow?.DeliveryDate.date).isBefore(moment(), 'day')) show = true;
            break;
        }
        partRows[idx].showScheduleError = show;
        this.setState({formData:{...this.state.formData, partRows}});
    }

    handleRowDateChange = (idx, dsIdx) => date => {

        let type;
        let formData;

        let partRows = [...this.state.formData.partRows];

        if ( this.state.formData.partRows[idx].deliveryType == 'Schedule' ) {

            let schedule = [...partRows[idx].schedule];            

            schedule[dsIdx] = {
                ...schedule[dsIdx],
                date: moment(date).format('YYYY-MM-DD')
            };

            partRows[idx] = {
                ...partRows[idx],
                schedule
            };
            
        } else {
            let DeliveryDate = this.state.formData.DeliveryDate;

            partRows[idx].DeliveryDate = {
                ...partRows[idx].DeliveryDate,
                date: moment(date).format('YYYY-MM-DD')
            }

        }

        formData = {
            ...this.state.formData,
            partRows
        }

        this.setState({formData},() => {
            this.handleShowScheduleError(idx);
        });
    };

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleScheduleOpen = (idx) => (e) => {
        if(this.state.formData.partRows[idx].part && this.state.formData.partRows[idx].quantity){
            let data =  [...this.state.formData.partRows];
            data[idx] = {
                ...data[idx],
                dialogOpen: true
            };
            this.setState({
                formData: {
                    ...this.state.formData,
                    partRows: data
                }
            });
        }
        else {
            alert('Please select a part');
        }
    };
    handleScheduleClose = (idx) => (e) => {
        let data =  [...this.state.formData.partRows];
        data[idx] = {
            ...data[idx],
            dialogOpen: false
        };
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: data
            }
        });
    };
    handleScheduleSave = (idx) => (e) => {
        let data =  [...this.state.formData.partRows];
        data[idx] = {
            ...data[idx],
            dialogOpen: false
        };
        this.setState({
            formData: {
                ...this.state.formData,
                partRows: data
            }
        },
        () => {
            this.handleShowScheduleError(idx);
        });
    };
    handleScheduleRevert = (idx) => (e) => {
        let partRows = [...this.state.formData.partRows];
        partRows[idx] = {...this.state.formDataOG.partRows[idx], dialogOpen: true};
        this.setState({
            formData:{
                ...this.state.formData,
                partRows: partRows
            }
        });
    }

    setSchedule = (idx) => {
        let partRows = [...this.state.formData.partRows];
        let newSchedule = [];
        switch(partRows[idx].deliveryType){
            case 'Delivery Date':
                newSchedule.push({
                    quantity: partRows[idx].newPartQuantity,
                    price: partRows[idx].newPartTotalPrice,
                    date: null
                });
            break;
            case 'Schedule':
                newSchedule.push({
                    quantity: partRows[idx].newPartQuantity,
                    price: partRows[idx].newPartTotalPrice,
                    date: null
                });
            break;
            case 'Supplier to Confirm':
                newSchedule.push({
                    quantity: partRows[idx].newPartQuantity,
                    price: partRows[idx].newPartTotalPrice,
                    date: '0000-00-00'
                });
            break;
            default:
            break;
        }
        partRows[idx] = {
            ...partRows[idx],
            schedule: newSchedule
        };
        this.setState({
            formData:{
                ...this.state.formData,
                partRows: partRows
            }
        },
        () => {
            this.handleShowScheduleError(idx);
        });
    }



    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false
        });
        this.submit();
    }
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };
    handleNotificationClose = () => {
        this.setState({ 
            notificationOpen: false 
        });
    };

    submit = () => {
        let partRows =  [...this.state.formData.partRows];
        for (let idx = 0; idx < partRows.length; idx++){
            const data = partRows[idx];
            switch (data.deliveryType) {
                case 'Supplier to Confirm':
                    partRows[idx].schedule = [{
                        ...data.supplierToConfirm,
                        date: 'TBC'
                    }]
                break;
                case 'Delivery Date':
                    partRows[idx].schedule = [data.DeliveryDate];
                break;
            }
        }
        const formData = {
            ...this.state.formData,
            partRows: partRows
        }
        API.post(`/partsOrdering/${this.state.ppo.ppo_id}/updateDeliverySchedule`, formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    successRedirect: true
                });
            }
            this.props.scrollToTop();
        });
    }

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    }

    handleAddRow = (idx) => (e) => {
        
        // Add new row
        const item = {
            quantity:   null,
            price:      null,
            date:       null,
        };
        let partRows =  [...this.state.formData.partRows];
        partRows[idx] = {
            ...partRows[idx],
            schedule: [...this.state.formData.partRows[idx].schedule, item]
        };
        this.setState({
            formData:{
                ...this.state.formData,
                partRows: partRows
            }
        },
        () => {
            this.handleShowScheduleError(idx);
        });
    }
    handleRemoveRow = (idx, dsIdx) => () => {
        let schedule = [...this.state.formData.partRows[idx].schedule];
        let partRows = [...this.state.formData.partRows];
        // Remove row
        schedule.splice(dsIdx, 1);
        // Update new schedule
        let new_total_qty = 0;
        let new_total_price = 0;
        let newSchedule = [];
        schedule.forEach((ds) => {
            newSchedule.push({
                quantity: ds.quantity,
                price: ds.price,
                date: ds.date
            });
            new_total_qty = parseFloat(new_total_qty)+ parseFloat(ds.quantity);
			new_total_price = parseFloat(new_total_price)+parseFloat(ds.price);
        });
        partRows[idx] = {
            ...partRows[idx],
            schedule: newSchedule,
            newPartQuantity: parseFloat(new_total_qty).toFixed(2),
            newPartTotalPrice: parseFloat(new_total_price).toFixed(2),
        };
        this.setState({
            formData:{
                ...this.state.formData,
                partRows: partRows
            }
        },
        () => {
            this.handleShowScheduleError(idx);
        });
    }

    downloadPPO = (ppoId) => {
        API.get('/partsOrdering/' + ppoId + '/pdfPartsOrder')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }
    downloadDS = (ppoId) => {
        API.get('/partsOrdering/' + ppoId + '/pdfDeliverySchedule')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }
    viewPPO = () => {
        this.setState(prevState => ({
            showPPO: !prevState.showPPO,
            showDS: false
        }),()=>this.savePageState());
    }
    viewDS = () => {
        this.setState(prevState => ({
            showDS: !prevState.showDS,
            showPPO: false
        }),()=>this.savePageState());
    }


    render() {
        const { classes }                           = this.props;
        const { formErrors, ppo, formData }         = this.state;
        const { supplierToConfirm, DeliveryDate }   = formData;
        if (this.state.successRedirect === true) {
            return <Redirect 
                        to={{
                            pathname:`/parts-order/view/${this.state.ppo.ppo_id}`,
                            state: {successMsg: 'You have successfully updated this parts order delivery schedule'}
                        }}
                    />
        } else if (this.state.redirect === true) {
            return <Redirect to="/parts-order/search" />
        } else {
            return (
                <React.Fragment>
                    {this.state.isLoading ? (
                        <LoadingCircle />
                    ) : (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    Update Delivery Schedule
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                    <Typography variant="h4" align="center" className={classes.heading} style={{marginBottom: 25}}>
                                        {ppo.full_reference}
                                    </Typography>
                                    <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                        Purchase Order (v{parseInt(ppo.ppo_number_amendment)})<br className='xs-only' />
                                        <Button variant="outlined"
                                                color="default"
                                                size="small"
                                                className={classes.btn}
                                                onClick={this.viewPPO}
                                                style={{marginLeft:10}}
                                        >                                    
                                            <FALightIcon icon='search' />
                                            {this.state.showPPO ? 'Hide' : 'View'}
                                        </Button>
                                        <Button variant="outlined"
                                                color="default"
                                                size="small"
                                                className={classes.btn}
                                                onClick={() => this.downloadPPO(ppo.ppo_id)}
                                        >
                                            <FALightIcon icon='download' />
                                            Download
                                        </Button> 
                                    </Typography>
                                    <br className='xs-only' />
                                    <Typography variant="body1" align="center" gutterBottom style={{marginBottom:40}}>
                                        Delivery Schedule (v{parseInt(ppo.ppo_schedule_version)})<br className='xs-only' />
                                        <Button variant="outlined"
                                                color="default"
                                                size="small"
                                                onClick={this.viewDS}
                                                className={classes.btn}
                                                style={{marginLeft:10}}
                                        >
                                            <FALightIcon icon='search' />
                                            {this.state.showDS ? 'Hide' : 'View'}
                                        </Button>
                                        <Button variant="outlined"
                                                color="default"
                                                size="small"
                                                className={classes.btn}
                                                onClick={() => this.downloadDS(ppo.ppo_id, parseInt(ppo.ppo_schedule_version))}
                                        >                                            
                                            <FALightIcon icon='download' />
                                            Download
                                        </Button> 
                                    </Typography>

                                        { this.state.showPPO ? <PartsPurchaseOrder ppo={ppo} /> : null }
                                        { this.state.showDS ? <DeliverySchedule ppo={ppo} /> : null }
                                        
                                </PaddedPaper>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Typography variant="h6" gutterBottom>
                                        Part Items
                                    </Typography>
                                    <div className={classes.responsiveTable}>
                                        <Table style={{minWidth: 900}}>
                                            <TableBody>
                                                {formData.partRows && formData.partRows.map((item, idx) => {
                                                    return (
                                                        <React.Fragment key={`pr${idx}`}>
                                                            <TableRow>
                                                                <TableCell className={classes.td} style={{width:'30%'}}>
                                                                    <TextField
                                                                        name="part"
                                                                        value={item.part}
                                                                        label="Part"
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                    <TextField
                                                                        name="quantity"
                                                                        value={item.quantity}
                                                                        label="Quantity"
                                                                        type="number"
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                    <TextField
                                                                        name="unitPrice"
                                                                        value={item.unitPrice}
                                                                        label="Unit Price"
                                                                        type="number"
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                    <TextField
                                                                        name="totalPrice"
                                                                        value={item.totalPrice}
                                                                        label="Total Price"
                                                                        type="number"
                                                                        disabled
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td} rowSpan={2} align="right">
                                                                    <Button variant="contained" size="small" color="secondary" style={{backgroundColor: item.showScheduleError || '#43a047'}} onClick={this.handleScheduleOpen(idx)}>
                                                                        <FALightIcon icon='calendar-alt' buttonPrimary /> Schedule
                                                                    </Button>
                                                                    {formErrors && formErrors['partRows|deliverySchedule|'+idx] &&
                                                                        <Typography component={"div"} style={{color: colors.red}}>
                                                                            {formErrors && formErrors['partRows|deliverySchedule|'+idx]}
                                                                        </Typography>
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow key={idx}>
                                                                <TableCell className={classes.td} style={{width:'30%'}}>
                                                                    <TextField
                                                                        name="nominalCode"
                                                                        value={item.nominalCode ? item.nominalCode : ""}
                                                                        label="Nominal Code"
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                    <TextField
                                                                        name="supplierReference"
                                                                        value={item.supplierReference}
                                                                        label="Supplier Reference"
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                    <TextField
                                                                        name="drawingNo"
                                                                        label="Drawing No."
                                                                        value={item.drawingNo}
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell className={classes.td} colSpan={6}>
                                                                    <Divider style={{border:'5px solid #f2f2f2'}} />
                                                                </TableCell>
                                                            </TableRow>

                                                            <Dialog 
                                                                open={item.dialogOpen} 
                                                                onClose={this.handleScheduleClose(idx)} 
                                                                fullWidth={true} 
                                                                maxWidth="md" 
                                                                disableBackdropClick={true}
                                                                disableEscapeKeyDown
                                                            >
                                                                <DialogTitle id="form-dialog-title">Delivery Schedule</DialogTitle>
                                                                <DialogContent>
                                                                    {formErrors && formErrors['stockReturnDetails'] && formErrors['stockReturnDetails'].split('\n').map((item, key) => {
                                                                        return (
                                                                            <React.Fragment key={key}>
                                                                                <Typography component={"div"} style={{color: colors.red}}>
                                                                                    {item}<br/>
                                                                                </Typography>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                    <RadioGroup
                                                                        name="deliveryType"
                                                                        value={item.deliveryType}
                                                                        onChange={this.handleTabChange(idx)}
                                                                        row
                                                                    >
                                                                        <FormControlLabel
                                                                            value="Delivery Date"
                                                                            control={<Radio color="primary" />}
                                                                            label="Delivery Date"
                                                                            labelPlacement="end"
                                                                        />
                                                                        <FormControlLabel
                                                                            value="Schedule"
                                                                            control={<Radio color="primary" />}
                                                                            label="Schedule"
                                                                            labelPlacement="end"
                                                                        />
                                                                        
                                                                        <FormControlLabel
                                                                            value="Supplier to Confirm"
                                                                            control={<Radio color="primary" />}
                                                                            label="Supplier to Confirm"
                                                                            labelPlacement="end"
                                                                        />
                                                                    </RadioGroup>
                                                                    <br></br>
                                                                    <small>To ensure a delivery schedule is valid please fill out all dates and ensure the quantity is a whole number</small>
                                                                    <br></br><br></br>
                                                                    <PaddedPaper style={{backgroundColor:'#f2f2f2', textAlign:'center'}}>
                                                                        You have <b>{item.unitsLeft}</b> units left to be delivered
                                                                    </PaddedPaper>
                                                                    <Table>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell className={classes.th}>Quantity</TableCell>
                                                                                <TableCell className={classes.th}>Price</TableCell>
                                                                                <TableCell className={classes.th}>Delivery Date</TableCell>
                                                                                <TableCell className={classes.th}></TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        
                                                                        {item.deliveryType === 'Delivery Date' &&
                                                                            [<TableBody>
                                                                                <TableRow>
                                                                                    <TableCell className={classes.td}>
                                                                                        <TextField
                                                                                            value={item.DeliveryDate.quantity}
                                                                                            type="number"
                                                                                            fullWidth
                                                                                            disabled
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.td}>
                                                                                        <TextField
                                                                                            value={item.DeliveryDate.price}
                                                                                            type="number"
                                                                                            disabled
                                                                                            fullWidth
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.td} colSpan={2}>
                                                                                        <DatePicker
                                                                                            type="date"
                                                                                            id="deliveryDate"
                                                                                            name="deliveryDate"
                                                                                            value={item.DeliveryDate.date}
                                                                                            errorText={formErrors && formErrors['partRows|deliveryDate|'+idx]}
                                                                                            onChange={this.handleRowDateChange(idx, 0)}
                                                                                            autoOk={true}
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>,
                                                                            <TableFooter>
                                                                                <TableRow>
                                                                                    <TableCell className={classes.th}>
                                                                                        {item.DeliveryDate.quantity}
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.th}>
                                                                                        {item.DeliveryDate.price}
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.th}></TableCell>
                                                                                </TableRow>
                                                                            </TableFooter>
                                                                            ]
                                                                        }
                                                                        {item.deliveryType === 'Schedule' && [
                                                                            <TableBody>
                                                                                { item.schedule.map((ds, dsIdx) => 
                                                                                    <TableRow key={dsIdx}>
                                                                                        <TableCell className={classes.td}>
                                                                                            <TextField
                                                                                                value={ds?.quantity || null}
                                                                                                type="number"
                                                                                                inputProps={{ min: 0 }}
                                                                                                fullWidth
                                                                                                onChange={this.handleRowScheduleChange(idx, dsIdx)}
                                                                                                onBlur={this.handleRowScheduleChange(idx, dsIdx, true)}
                                                                                            />
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.td}>
                                                                                            <TextField
                                                                                                value={ds?.price}
                                                                                                type="number"
                                                                                                disabled
                                                                                                fullWidth
                                                                                            />
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.td}>
                                                                                            <DatePicker
                                                                                                type="date"
                                                                                                id="deliveryDate"
                                                                                                name="deliveryDate"
                                                                                                value={ds?.date || null}
                                                                                                errorText={formErrors && formErrors['partRows|deliveryDate|'+idx]}
                                                                                                onChange={this.handleRowDateChange(idx, dsIdx)}
                                                                                                autoOk={true}
                                                                                            />
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.td}>
                                                                                            {dsIdx > 0 &&
                                                                                                <IconButton onClick={this.handleRemoveRow(idx, dsIdx)}>
                                                                                                    <FALightIcon icon='times' noMargin style={{color:colors.red}} />
                                                                                                </IconButton>
                                                                                            }
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                )}
                                                                            </TableBody>,
                                                                            <TableFooter>
                                                                                <TableRow>
                                                                                    <TableCell className={classes.th} style={{ color: ( _.sumBy(item?.schedule, i =>  parseFloat(i?.quantity) || 0).toFixed(2) !== item.unitsLeft ) ? colors.red: '' }}>
                                                                                        {_.sumBy(item?.schedule, i =>  parseFloat(i?.quantity) || 0).toFixed(2)}
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.th}>
                                                                                        {_.sumBy(item?.schedule, i =>  parseFloat(i?.price) || 0).toFixed(2)}
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.th}></TableCell>
                                                                                    <TableCell>
                                                                                        <IconButton onClick={this.handleAddRow(idx)}>
                                                                                            <FALightIcon icon='plus' style={{color:colors.green}} noMargin />
                                                                                        </IconButton>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableFooter>]
                                                                        }
                                                                        {item.deliveryType === 'Supplier to Confirm' && [
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell className={classes.td}>
                                                                                        <TextField
                                                                                            value={item.supplierToConfirm.quantity}
                                                                                            type="number"
                                                                                            fullWidth
                                                                                            disabled
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.td}>
                                                                                        <TextField
                                                                                            value={item.supplierToConfirm.price}
                                                                                            type="number"
                                                                                            disabled
                                                                                            fullWidth
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.td} colSpan={2}>
                                                                                        <TextField
                                                                                            value="TBC"
                                                                                            margin="normal"
                                                                                            fullWidth
                                                                                            disabled
                                                                                            InputProps={{
                                                                                                endAdornment: (
                                                                                                <InputAdornment  position="end">
                                                                                                    <FALightIcon icon='calendar-alt' noMargin />
                                                                                                </InputAdornment>
                                                                                                ),
                                                                                            }}
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>,
                                                                            <TableFooter>
                                                                                <TableRow>
                                                                                    <TableCell className={classes.th}>
                                                                                        {item.supplierToConfirm.quantity}
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.th}>
                                                                                        {item.supplierToConfirm.price}
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.th}></TableCell>
                                                                                </TableRow>
                                                                            </TableFooter>
                                                                            ]}
                                                                    </Table>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    {item.showScheduleError &&
                                                                        <Typography variant="subtitle2" style={{color: colors.red}}>You have not completed the delivery schedule or it is invalid</Typography>
                                                                    }
                                                                    <Button onClick={this.handleScheduleClose(idx)} variant="outlined" color="default">Close</Button>
                                                                    <Button onClick={this.handleScheduleRevert(idx)} variant="outlined" color="default">Revert</Button>
                                                                    <Button onClick={this.handleScheduleSave(idx)} style={{backgroundColor:'#5da14d', color:'white'}} autoFocus variant="contained">Save & Close</Button>
                                                                </DialogActions>
                                                            </Dialog>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </div>

                                    <Typography variant="h6" gutterBottom style={{marginTop: 20}}>
                                        Other Items
                                    </Typography>

                                    <div className={classes.responsiveTable}>
                                        <Table style={{minWidth: 900}}>
                                            <TableBody>
                                                {formData.otherRows && formData.otherRows.map((item, idx) => {
                                                    return (
                                                        <React.Fragment key={`or_${idx}`}>
                                                            <TableRow>
                                                                <TableCell className={classes.td} style={{width:'30%'}}>
                                                                    <TextField
                                                                        name="description"
                                                                        value={item.description}
                                                                        label="Description"
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td} style={{width:'30%'}}>
                                                                    <TextField
                                                                        name="nominalCode"
                                                                        value={item.nominalCode ? item.nominalCode : ''}
                                                                        label="Nominal Code"
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                    <TextField
                                                                        name="quantity"
                                                                        value={item.quantity}
                                                                        label="Quantity"
                                                                        type="number"
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                    <TextField
                                                                        name="unitPrice"
                                                                        value={item.unitPrice}
                                                                        label="Unit Price"
                                                                        type="number"
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.td}>
                                                                    <TextField
                                                                        name="totalPrice"
                                                                        value={item.totalPrice}
                                                                        label="Total Price"
                                                                        type="number"
                                                                        disabled
                                                                        fullWidth
                                                                    />
                                                                </TableCell>
                                                                
                                                                <TableCell className={classes.td}>
                                                                    
                                                                </TableCell>
                                                            </TableRow>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {formErrors && formErrors['otherRows'] && formErrors['otherRows'].split('\n').map((item, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                <Typography component={"div"} style={{color: colors.red}}>
                                                    {item}<br/>
                                                </Typography>
                                            </React.Fragment>
                                        )
                                    })}
                                    <Typography variant="h6" style={{display: 'flex', justifyContent:'flex-end', fontWeight: 'bold' }}>
                                        Total Price: £{ppo.ppo_total_price}
                                    </Typography>
                                    <div className='buttonRow'>
                                        {formErrors && formErrors.schedule && (
                                            <Typography style={{color: colors.red}}>
                                                {formErrors.schedule}
                                            </Typography>
                                        )}
                                        <BackButton props={this.props} />
                                        <Button onClick={this.handleConfirmationOpen}
                                                variant="contained"
                                                color="primary"
                                            >
                                            Update
                                        </Button>
                                    </div>
                                </PaddedPaper>
                            </Grid>
                            <ConfirmationDialog 
                            open={this.state.confirmationOpen} 
                            success={this.handleConfirmationSuccess} 
                            close={this.handleConfirmationClose} 
                            title="Update this Delivery Schedule?" 
                            message="Are you sure you want to update this delivery schedule?"
                            />
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarOpen}
                                onClose={this.handleSnackbarClose}
                                message='You have successfully updated this delivery schedule'
                            />
                        </Grid>
                    )}
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UpdatePartsOrderDeliverySchedule));
