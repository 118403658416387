import React, {Component} from 'react';
import { connect }        from 'react-redux';
import API                from 'API';
import _                  from 'lodash';

import { Grid, Button } from '@material-ui/core';

import DataTable from 'Components/Common/DataTables/DataTable';

import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

const initialState = {
    formData: {
        code:'',
        name:''
    },
    formErrors:[],
    rows : [],
    snackbarOpen: false,
    confirmationOpen: false,
    isLoading: true,
    showUpdate: false,
}

class PartTypesSortOrder extends Component{
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getPartTypeList();
    }

    getPartTypeList = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/parts/numbering/partTypes/all')
            .then((result) => {
                let partTypes = _.map(_.sortBy(result.data, ['part_type_sort_order', 'asc']), (el) => {
                    return _.assign({
                        id: el.part_type_id,
                        code:  el.part_type_code,
                        name:  el.part_type_name,
                    });
                });
                this.setState({
                    ...this.state,
                    rows: partTypes,
                    isLoading: false
                });
            })
        })
    }

    handleMove = (rowIdx, dir) => {
        let rows = [...this.state.rows];
        let rowMoveUp, rowMoveDown;
        switch(dir) {
            case 'up':
                rowMoveUp = rows[rowIdx];
                rowMoveDown = rows[rowIdx - 1];
                rows.splice(rowIdx, 1, rowMoveDown);
                rows.splice(rowIdx - 1, 1, rowMoveUp);
            break;
            case 'down':
                rowMoveDown = rows[rowIdx];
                rowMoveUp = rows[rowIdx + 1];
                rows.splice(rowIdx, 1, rowMoveUp);
                rows.splice(rowIdx + 1, 1, rowMoveDown);
            break;
            default:
            break;
        }
        this.setState({rows, showUpdate: true});
    }

    updateSortOrder = () => {
        const rows = this.state.rows;
        API.post('/parts/numbering/partTypes/sortOrder', {rows})
        .then(res => { 
            if (res.date){
                this.setState({showUpdate:false}, () => {
                    this.props.deploySnackBar('success', 'Part Types Have Been Reordered')
                })
            }
        })
    }

    render(){
        const { showUpdate } = this.state;
        return (
            <Grid container spacing={3}>
                {showUpdate &&
                    <Grid item xs={12} align='right'>
                        <Button color='primary' variant='contained' onClick={this.updateSortOrder}>Update Sort Order</Button>
                    </Grid>
                }
                <Grid item xs={12}>
                    <DataTable  
                        config={{
                            key: 'id',
                            alternatingRowColours: true,
                            isLoading: this.state.isLoading
                        }}
                        columns={[
                            {
                                heading: 'Sort Order',
                                field: rowData => <>{rowData.rowIdx+1}</>,
                                sizeToContent: true,
                                align:'center'
                            },
                            {
                                heading: 'Code',
                                field: rowData => rowData.code,
                                align:'center',
                                sizeToContent: true
                            },
                            {
                                heading: 'Name',
                                field: rowData => rowData.name
                            },
                            {
                                actions: rowData => {
                                    return [
                                        {name: 'Move Up',   icon: 'chevron-up',    onClick: () => {this.handleMove(rowData.rowIdx,'up')}, disabled: !rowData.rowIdx},
                                        {name: 'Move Down', icon: 'chevron-down',  onClick: () => {this.handleMove(rowData.rowIdx,'down')}, disabled: rowData.rowIdx === (this.state.rows.length -1)}
                                    ]
                                }
                            }
                        ]}
                        rows={this.state.rows}
                    />
                </Grid>  
            </Grid>     
        );
    }
}

function mapDispatchToProps(dispatch) {
    return { deploySnackBar: (variant, msg) => { dispatch(deploySnackBar(variant, msg))}}
}

export default connect(null, mapDispatchToProps)(PartTypesSortOrder);
