import React, {Component} from 'react';
import _                  from 'lodash';
import { connect }        from 'react-redux';

import AllIcon     from 'Components/Common/Icons/AllIcon';
import DataTable   from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import icons      from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';

import {Grid, Typography} from '@material-ui/core/';

function InfoBox({heading, value, icon}) {
    return (
        <Grid item xs={4}>
            <PaddedPaper style={{textAlign:'center'}}>
                <AllIcon icon={icon} size='xlarge' />
                <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>{heading}</Typography>
                <Typography variant="h4" style={{textAlign:'center'}}>{value}</Typography>
            </PaddedPaper>
        </Grid>
    )
}
class Shortages extends Component {
    constructor(props) {
        super(props);
    }
    
    render(){

        const { worksOrder } = this.props;

        const logs = _.filter(worksOrder.part_logs, i => ['Stock Shortage Created', 'Shortage Partially Picked', 'Shortage Picked In Full', 'Shortage Never Required', 'Stock Shortage Picked', 'Shortage Pick Skipped', 'Stock Shortage Complete', 'Shortage Stock Missing', 'Shortage - Partially Picked', 'Shortage - Picked In Full', 'Shortage - Picked In Full'].includes(i.wopl_type));
        
        const allParts = _.map(_.filter(worksOrder?.shortage?.details, i => i.wossd_status !== 'Not Required') , i => {
            let pick = _.first(_.filter(worksOrder?.pick.details, {wopd_part_id: i.wossd_part_id}));
            let _logs = _.filter(logs, {wopl_part_id: i.part.part_id});

            return {
                id:        i.part.part_id,
                img:       i.part?.default_image?.file_path,
                number:    i.part.part_number,
                desc:      i.part.part_description,
                required:  parseFloat(parseFloat(i.wossd_qty_required) - parseFloat(pick.wopd_picked)),
                picked:    parseFloat(parseFloat(i.wossd_qty_picked) - parseFloat(pick.wopd_picked)),
                remaining: i.wossd_qty_remaining,
                date:      _.last(_logs).wossdl_datetime,
                complete:  i.wossd_status === 'Complete' ? true : false,
                logs:      _.orderBy(_logs, 'wopl_datetime', 'desc'),
                total:     (parseFloat(i.wossd_qty_picked) - parseFloat(pick.wopd_picked)) + parseFloat(i.wossd_qty_remaining),
                pick,
                ip:        pick.wopd_picked,
                qp:        i.wossd_qty_picked,
            }
        })

        return (
            <PaddedPaper>
                <Grid container spacing={3}>
                    <InfoBox heading='Required Parts' icon={icons.stock} value={allParts.length}  />
                    <InfoBox heading='Shortage Parts' icon={icons.qr}    value={_.filter(allParts, r => parseFloat(r.picked) === 0).length}  />
                    <InfoBox heading='Picked Parts'   icon={icons.true}  value={_.filter(allParts, r => parseFloat(r.picked) > 0).length}  />

                    <InfoBox heading='Required Qty' icon={icons.stock} value={parseFloat(_.sumBy(allParts, r => parseFloat(r.total))).toFixed(3)} />
                    <InfoBox heading='Shortage Qty' icon={icons.qr}    value={parseFloat(_.sumBy(allParts, r => parseFloat(r.remaining))).toFixed(3)} />
                    <InfoBox heading='Picked Qty'   icon={icons.true}  value={parseFloat(_.sumBy(allParts, r => parseFloat(r.picked))).toFixed(3)} />
                    
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant="h5" style={{paddingBottom: '1em'}}>Pending Shortage Parts</Typography>
                            <DataTable
                                config={{
                                    key: 'id',
                                    dropRow: {
                                        droppable:     i => i.logs.length > 0,
                                        style:         {fontWeight: 'bold'},
                                        hideDropArrow: true,
                                        columns:       [
                                            {
                                                colSpan:     2,
                                                field:       'wopl_datetime',
                                                fieldFormat:  'clenydate',
                                                alignment:   'right'
                                            },
                                            {
                                                colSpan:   1,
                                                field:     'wopl_type',
                                                alignment: 'left'
                                            },
                                            {
                                                colSpan: 2,
                                                field:   'wopl_note',
                                                alignment: 'right'
                                            },
                                            {
                                                colSpan: 2,
                                                field:   'wopl_staff',
                                                alignment: 'right'
                                            },
                                            {
                                                colSpan: 1,
                                            }
                                        ],
                                        data: i => _.orderBy(i.logs, 'wopl_id', 'desc')
                                    }
                                }}
                                columns={[
                                    {
                                        heading:       '',
                                        field:         'img',
                                        fieldFormat:   'image',
                                        alignment:     'center',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       'Part Number',
                                        field:         'number',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       'Part Description',
                                        field:         'desc',
                                        
                                    },
                                    {
                                        heading:       'Required',
                                        field:         'required',
                                        sizeToContent: true,
                                        alignment:     'center',
                                        fieldFormat:   'decimal:3'
                                    },
                                    {
                                        heading:       'Picked',
                                        field:         'picked',
                                        sizeToContent: true,
                                        alignment:     'center',
                                        fieldFormat:   'decimal:3'
                                    },
                                    {
                                        heading:       'Remaining',
                                        field:         'remaining',
                                        sizeToContent: true,
                                        alignment:     'center',
                                        fieldFormat:   'decimal:3'
                                    },
                                    {
                                        heading:       '',
                                        field:         r => <div style={{width: 80}}></div>,
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:        '',
                                        field:          rowData => <AllIcon icon={icons.picking} color={parseFloat(rowData.picked) === 0 ? colors.red : colors.orange } 
                                                                        tooltip={parseFloat(rowData.picked) === 0 ? 'Pending' : 'Partial'} noMargin />,
                                        alignment:      'center',
                                        sizeToContent:  true,
                                    },
                                ]}
                                rows={_.orderBy(_.filter(allParts, i => !i.complete ), 'number')}
                            />
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant="h5" style={{paddingBottom: '1em'}}>Completed Shortage Parts</Typography>
                            <DataTable
                                config={{
                                    key: 'id',
                                    dropRow: {
                                        droppable:     i => i.logs.length > 0,
                                        style:         {fontWeight: 'bold'},
                                        hideDropArrow: true,
                                        columns:       [
                                            {
                                                colSpan:     2,
                                                field:       'wopl_datetime',
                                                fieldFormat:  'clenydate',
                                                alignment:   'right'
                                            },
                                            {
                                                colSpan:   1,
                                                field:     'wopl_type',
                                                alignment: 'left'
                                            },
                                            {
                                                colSpan: 2,
                                                field:   'wopl_note',
                                                alignment: 'right'
                                            },
                                            {
                                                colSpan: 2,
                                                field:   'wopl_staff',
                                                alignment: 'right'
                                            },
                                            {
                                                colSpan: 2,
                                            }
                                        ],
                                        data: i => _.orderBy(i.logs, 'wopl_id', 'desc')
                                    }
                                }}
                                columns={[
                                    {
                                        heading:       '',
                                        field:         'img',
                                        fieldFormat:   'image',
                                        alignment:     'center',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       'Part Number',
                                        field:         'number',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       'Part Description',
                                        field:         'desc',
                                        
                                    },
                                    {
                                        heading:       'Required',
                                        field:         'required',
                                        sizeToContent: true,
                                        fieldFormat:   'decimal:3',
                                        alignment:     'center',
                                    },
                                    {
                                        heading:       'Picked',
                                        field:         'picked',
                                        sizeToContent: true,
                                        fieldFormat:   'decimal:3',
                                        alignment:     'center',
                                    },
                                    {
                                        heading:       'Remaining',
                                        field:         'remaining',
                                        sizeToContent: true,
                                        fieldFormat:   'decimal:3',
                                        alignment:     'center',
                                    },
                                    {
                                        heading:       'Picked By',
                                        field:         i => _.first(i.logs).wopl_staff,
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       'Date',
                                        field:         i => _.first(i.logs).wopl_datetime,
                                        fieldFormat:   'clenydate',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:        '',
                                        field:          rowData => <AllIcon noMargin icon={icons.picking} color={colors.green}  tooltip={'Fully Picked'} />,
                                        alignment:      'center',
                                        sizeToContent:  true,
                                    },
                                ]}
                                rows={_.orderBy(_.filter(allParts, i => i.complete ), 'number')}
                            />
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </PaddedPaper>
        )
    }
}


function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff
    };
}

export default connect(mapStateToProps, null)(Shortages)