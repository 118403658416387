import React  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import {
    Button,
    Grid,
    TextField,
    Typography,
    DialogActions,
    ListItem,
    ListItemText,
    Checkbox,
    List,
    InputAdornment
} from '@material-ui/core';
import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { handleFileChange, handleDateChange, getFormData, handleSelectChange, handleChange } from 'Functions/FormFunctions';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { toggleDialog } from 'Functions/MiscFunctions';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea from 'Components/Common/Inputs/Textarea';
import DatePicker from 'Components/Common/DatePickers/DatePicker';

const initialState = () => ({
    breakdownId: 0,
    breakdown: {},
    formData: {
        company: '',
        cover: '',
        policyNumber: '',
        startDate: null,
        endDate: null,
        emergencyContact: '',
        cost: '',
        vehicles: [],
        status: 'Active',
    },
    formErrors: [],
    vehicleList: [],
    suppList: [],
    isLoading: true,
    readOnly: false,
    renew: false,
});

class VehicleBreakdownForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleFileChange = handleFileChange.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
        this.getFormData = getFormData.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleChange = handleChange.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData(); 
    }

    loadComponentData = () => {

        const { breakdownId, readOnly, renew } = this.props;

        Promise.all([ 
            API.get('/vehicles', { params: { activeOnly: true } }),
            API.get('/suppliers/all', {
                params: {
                    supplierType: 10 // Vehicles
                }
            })
        ])
        .then(([res, suppRes]) => {

            let vehicleList = _.map(res.data, (v) => {
                return _.assign({
                    value: v.id,
                    label: v.regNumber,
                    desc: v.make + ' ' + v.model
                });
            });

            let suppList = _.map(suppRes.data, (supp) => {
                return _.assign({
                    value: supp.supp_id,
                    label: supp.supp_company_name
                });
            });

            this.setState({
                isLoading: false,
                breakdownId,
                readOnly,
                renew,
                vehicleList,
                suppList
            },
            () => {
                if (this.state.breakdownId) {
                    this.loadData();
                }
            }); 
            
        });
         
    }

    loadData = () => {
        this.setState({
            isLoading: true,
        }, 
        () => {
            API.get('/vehicles/breakdown/' + this.state.breakdownId)
            .then(res => {
                const { renew } = this.state;
    
                let br = res.data;
    
                this.setState({
                    ...this.state,
                    isLoading: false,
                    breakdown: br,
                    formData: {
                        ...this.state.formData,
                        company: br?.supplier?.id,
                        cover: br?.cover,
                        policyNumber: renew ? '' : br?.policyNumber,
                        startDate: renew ? null : br?.startDate,
                        endDate: renew ? null : br?.endDate,
                        emergencyContact: renew ? '' : br?.emergencyContact,
                        cost: renew ? '' : br?.cost,
                        vehicles: br?.vehicles,
                        status: renew ? 'Active' : br?.status,
                    }
                });
            });
        });
    }

    handleSubmit = () => {
        const { breakdownId, renew } = this.state;
        const route = breakdownId && !renew ? `/vehicles/breakdown/${breakdownId}`: `/vehicles/breakdown`;

        API.post(route, this.getFormData(), {
            params: {
                renewFromId: renew ? breakdownId : null
        }})
        .then(res => {
            if(res.data.errors && res.data.errors.length > 0) {
                this.setState({
                    formErrors: formatValidationErrors(res.data.errors)
                });
            } else {
                this.setState({
                    ...initialState(),
                }, () => {
                    this.props.deploySnackBar("success", `The breakdown was successfully ${breakdownId && !renew ? 'updated' : 'added'}`);
                    this.props.refresh();
                })
            }
        });
    }

    toggleCheckbox = (vehicleId) => {
        
        let vehicles = this.state.formData?.vehicles;
        const vIndex = _.findIndex(vehicles, { vehicleId });

        if (vIndex > -1) {
            vehicles.splice(vIndex, 1);
        } else {
            vehicles.push({ vehicleId });
        }
        this.setState({
            formData: {
                ...this.state.formData,
                vehicles
            }
        });
    }

    render() {
        const { formErrors, formData, isLoading, breakdownId, breakdown, readOnly, vehicleList, suppList, renew } = this.state;
        return (   
            <>   
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {readOnly ? 'Breakdown Details' : (renew ? 'Renew ' : (breakdownId ? 'Update ' : 'Add ')) + 'Breakdown' }
                    </Typography>
                </Grid>     
                <Grid item xs={12}>
                    <form noValidate>
                        {(isLoading && (
                            <LoadingCircle />          
                        )) || (
                            <Grid container item spacing={3}>
                                <Grid item xs={12} md={8}>
                                    <Grid container item spacing={1}>
                                        <Grid item xs={12}>
                                            {formErrors && formErrors.generic && (
                                                <Typography component={"div"} style={{color: 'red'}}>
                                                    {formErrors.generic}
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AutoCompleteSelect 
                                                options={suppList} 
                                                label={"Company " + (readOnly ? '' : '*')}
                                                onChange={v => this.handleSelectChange('company', v)}
                                                error={formErrors && formErrors['company'] && true}
                                                errorText={formErrors && formErrors['company']}
                                                value={formData?.company}
                                                noClear
                                                fullWidth
                                                disabled={readOnly}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Textarea
                                                id="cover"
                                                name="cover"
                                                label="Summary of Cover"
                                                value={formData.cover}
                                                onChange={this.handleChange}
                                                InputLabelProps={{
                                                    shrink: !!formData.cover,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField 
                                                name="policyNumber"
                                                label={"Policy Number " + (readOnly ? '' : '*')}
                                                onChange={this.handleChange}
                                                error={formErrors && formErrors['policyNumber'] && true}
                                                helperText={formErrors && formErrors['policyNumber']}
                                                value={formData.policyNumber}
                                                margin="normal"
                                                fullWidth
                                                disabled={readOnly}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container item spacing={3}>
                                                <Grid item xs={6}>
                                                    <DatePicker
                                                        type="date"
                                                        id="startDate"
                                                        name="startDate"
                                                        label={"Start Date " + (readOnly ? '' : '*')}
                                                        value={formData.startDate}
                                                        errorText={formErrors && formErrors['startDate']}
                                                        onChange={date => this.handleDateChange('startDate', date, 'YYYY-MM-DD')}
                                                        autoOk={true}
                                                        disabled={readOnly}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DatePicker
                                                        type="date"
                                                        id="endDate"
                                                        name="endDate"
                                                        label={"End Date " + (readOnly ? '' : '*')}
                                                        value={formData.endDate}
                                                        errorText={formErrors && formErrors['endDate']}
                                                        onChange={date => this.handleDateChange('endDate', date, 'YYYY-MM-DD')}
                                                        autoOk={true}
                                                        disabled={readOnly}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                    <Typography variant="caption"><em>End date is the last day your policy covers you for</em></Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField 
                                                name="emergencyContact"
                                                label={"Emergency Contact " + (readOnly ? '' : '*')}
                                                onChange={this.handleChange}
                                                error={formErrors && formErrors['emergencyContact'] && true}
                                                helperText={formErrors && formErrors['emergencyContact']}
                                                value={formData.emergencyContact}
                                                margin="normal"
                                                fullWidth
                                                disabled={readOnly}
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField 
                                                name="cost"
                                                label={"Cost " + (readOnly ? '' : '*')}
                                                onChange={this.handleChange}
                                                error={formErrors && formErrors['cost'] && true}
                                                helperText={formErrors && formErrors['cost']}
                                                value={formData.cost}
                                                margin="normal"
                                                fullWidth
                                                disabled={readOnly}
                                                type="number"
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                                InputProps={{
                                                    startAdornment: (<InputAdornment position="end">£</InputAdornment>)
                                                }}
                                            />
                                        </Grid>
                                        {breakdownId && !renew ?
                                            <Grid item xs={12}>
                                                <AutoCompleteSelect 
                                                    options={[
                                                        {value: 'Active', label: 'Active'},
                                                        {value: 'Inactive', label: 'Inactive'}
                                                    ]}
                                                    label={"Status " + (readOnly ? '' : '*')}
                                                    onChange={(v) => this.handleSelectChange('status', v)}
                                                    error={formErrors && formErrors['status'] && true}
                                                    errorText={formErrors && formErrors['status']}
                                                    value={formData.status}
                                                    fullWidth
                                                    noClear
                                                    disabled={readOnly}
                                                    inputProps={{
                                                        className:"textDefault"
                                                    }}
                                                />
                                            </Grid>
                                        : null }
                                    </Grid>  
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="caption" gutterBottom>
                                        <b>Vehicles</b>
                                    </Typography>
                                    {formErrors && formErrors['vehicles'] && (
                                        <Typography variant='body2' style={{color: 'red'}} gutterBottom>
                                            {formErrors['vehicles']}
                                        </Typography>
                                    )}
                                    <Grid container item spacing={1} style={{overflowY: 'scroll', height: this.props.height ?? '30rem'}}>
                                        <Grid item xs={12}>
                                            <List style={{width:'100%'}}>
                                                {_.map(vehicleList, (vehicle) => {
                                                    let checked = _.find(formData?.vehicles, el => el.vehicleId == vehicle.value) ? true : false;
                                                    
                                                    return (
                                                        <>
                                                            {((readOnly && checked) || !readOnly) && (
                                                                <ListItem dense>
                                                                    <Checkbox 
                                                                        color="primary"
                                                                        name={vehicle.label}
                                                                        onClick={() => this.toggleCheckbox(vehicle.value)}
                                                                        checked={checked}
                                                                        disabled={readOnly}
                                                                    />
                                                                    <ListItemText>
                                                                        {vehicle?.label} <br></br>{vehicle?.desc}
                                                                    </ListItemText>
                                                                </ListItem>
                                                            )}
                                                        </>
                                                    )}
                                                )}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} align="right">
                                    <DialogActions className='pr-0 pb-0'>
                                        <Button onClick={() => this.props.onClose()} variant="text">
                                            <AllIcon icon="times" size={15} />
                                            Close
                                        </Button>
                                        {!readOnly && (
                                            <Button onClick={() => this.props.deployConfirmation(`Are you sure you want to ${breakdownId && !renew ? 'update' : 'add'} this breakdown?`, `${breakdownId && !renew ? 'Update' : 'Add'} Breakdown?`, this.handleSubmit)}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={Object.values(formData).every(x => (x === null || x === ""))}>
                                                {breakdownId && !renew ? 'Update' : 'Add'}
                                            </Button>
                                        )}
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        )}
                    </form>
                </Grid>  
            </Grid>
            </>  
        );
    }
}

const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
    }
)

export default connect(null, mapDispatchToProps)(VehicleBreakdownForm);