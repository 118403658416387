//ScanDialog

import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import Image              from 'Components/Common/ImageWithError/ImageWithError';
import NumberField        from 'Components/Common/Inputs/NumberField';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import QrScanWrapper      from 'Components/Warehouse/Common/Inputs/QrScanWrapper';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';

import { colors } from 'Helpers/ColourHelper';

import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { playSound }      from 'Actions/Sounds/Sounds';

import { Button, Grid, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';


const initialState = {
    isLoading:  false,
    part:       {},
    worksOrder: {},
    writeOff:   {},
    formData:  {
        partId: 0,
        qty:    0,
        action: 'Pick',
    },
    confirmationDialog: {
        open:    false,
        title:   '',
        message: '',
        success: null
    }
}

class StockWriteOff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            part:       this.props.worksOrder.active_write_off.part,
            worksOrder: this.props.worksOrder,
            writeOff:   this.props.worksOrder.active_write_off,
        };
    }

    handleScan = partNumber => {
        if (partNumber && this.state.part.part_number === partNumber) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    partId: this.state.part.part_id,
                }
            });
        }
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleChange = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            }
        })
    }

    handleSubmit = () => {
        API.post(`/worksOrders/${this.state.worksOrder.wo_id}/writeOffs/${this.state.worksOrder.active_write_off.wobwo_id}/pick`, {
            part:     this.state.formData.partId,
            quantity: this.state.formData.qty,
            action:   this.state.formData.action,
        })
        .then(res => {
            this.setState({
                formData: initialState.formData
            },this.props.onClose);
        })
    }

    deployConfirmation = (message, title, success) => {
        this.setState({
            confirmationDialog: {
                open: true,
                message: message,
                title: title,
                success: success
            }
        })
    }

    handleConfirmationClose = () => {
        this.setState({
            confirmationDialog: {
                ...this.state.confirmationDialog,
                open: false
            }
        })
    }

    _handleKeyDown = (e) => {
        if (e.keyCode === 0) return;
        e.preventDefault();
		switch(e.key) {
            case 'Enter':
                if (!(!parseFloat(this.state.formData.qty) || this.state.formData.qty > parseFloat(this.state.writeOff.wobwo_remaining) || parseFloat(this.state.formData.qty) < 0)){
                    this.deployConfirmation(
                        `Are you sure you want to ${this.state.formData.action.toLowerCase()} ${this.state.formData.qty} ${this.state.part.part_number}?`,
                        `Confirm ${this.state.formData.action}`,
                        this.handleSubmit
                    );
                }
            break;
            case 'F1':
                if (this.state.confirmationDialog.open) {
                    this.handleConfirmationClose();
                }
            break;
            case 'F2':
                if (this.state.confirmationDialog.open) {
                    this.state.confirmationDialog.success();
                    this.handleConfirmationClose();
                }
            break;
            default:
                if (this.state.formData.partId){
                    if (_.isInteger(parseInt(e.key)) || e.key === '.'){
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                qty: this.state.formData.qty + e.key 
                            }
                        })
                    } else if (e.key === 'Backspace') {
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                qty: this.state.formData.qty.slice(0, -1)
                            }
                        })
                    } 
                }
            break;

		}
	}

    render(){
        const { formData, showError, showQty, writeOff, isLoading, part, confirmationDialog } = this.state;
        const { classes, access } = this.props;
        

        if (isLoading) return (<LoadingCircle/>);
        return (
            <Grid container justify="center" className={`${classes.root} text-center`} spacing={1}>
                <Grid container item className={classes.qrRow}>
                    <Grid item xs={12}>
                        <Typography variant="h6" className="float-right">
                            Part 1 of 1
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Grid container justify="center" className="text-center" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{textAlign: 'center'}}>
                                {part.part_number}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{textAlign: 'center'}}>
                                {part.part_description}<br></br>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                            <Image src={part.default_image?.file_path} className={classes.img} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.qtyRow}>
                    <Typography variant="h5" component="span">
                        Required:
                    </Typography>
                    <Typography variant="h4" component="span" className="pl-1">
                        <strong>{parseFloat(writeOff.wobwo_remaining).toFixed(3)}</strong>
                    </Typography>
                </Grid>
                {formData.partId ?
                    <>
                        <Grid item style={{width: 400}}>
                            <NumberField
                                id="qty"
                                name="qty"
                                label={`Quantity Required * [Enter]`}
                                value={formData.qty}
                                type="number"
                                variant="outlined"
                                onChange={(e)=>{this.handleChange('qty', e)}}
                                margin="none"
                                className={classes.confirmQtyInputContainer}
                                inputProps={{
                                    className: classes.confirmQtyInput,
                                    min: 0,
                                    max: parseFloat(writeOff.wobwo_remaining),
                                    pattern: "\d*"
                                }}
                                granularity={function(){
                                    var decimals = (parseFloat(writeOff.wobwo_remaining) !== parseFloat(Math.floor(writeOff.wobwo_remaining)))?(writeOff.wobwo_remaining.toString()).split('.')[1].length:0;
                                    if (!decimals) return 1;
                                    return Math.pow(10, -(decimals-1));
                                }()}
                                disableKeyboard={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {formData.qty > parseFloat(writeOff.wobwo_remaining) && 
                                <span style={{color: colors.red}}>You cannot pick more stock than was written off</span>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant='contained' color='primary' disabled={!parseFloat(formData.qty) || formData.qty > parseFloat(writeOff.wobwo_remaining) || parseFloat(formData.qty) < 0} onClick={()=>{
                                this.deployConfirmation('Are you sure you want to pick this part?', 'Pick Stock', this.handleSubmit)
                            }} fullWidth>Confirm [Enter]</Button>
                        </Grid>
                    </> : 
                    <>
                        <Typography variant="h5" className={classes.actionText}>
                            <strong>{showError ? 'Incorrect Part Please Try Again' :'Please Scan QR Code'}</strong>
                        </Typography>
                    </>
                }
                <QrScanWrapper
                    handleScan={this.handleScan}
                    parentScan={this._handleKeyDown}
                />
                {confirmationDialog.open &&
                    <ConfirmationDialog
                        open={confirmationDialog.open}
                        title={confirmationDialog.title}
                        message={confirmationDialog.message}
                        success={()=>{
                            confirmationDialog.success();
                            this.handleConfirmationClose();
                        }}
                        close={this.handleConfirmationClose}
                        isPda={true}
                    />
                }
            </Grid>
        )
    }
}

const styles = theme => ({
    root: {
        padding:    theme.spacing(1),
        alignItems: 'flex-start',
    },
    qrRow: {
        justifyContent: 'flex-end',
        maxHeight:      '50px',
    },
    img: {
        maxHeight: 80,
        maxWidth:  130,
        width: 'auto',
        height: 'auto',
    }, 
    qtyRow: {
        textAlign: 'center'
    },
    actionText: {
        border: `2px dashed ${theme.palette.secondary.main}`,
        padding: 15,
        width: '80%',
        margin: '0 auto',
        textAlign: 'center',
        marginTop: '1em'
    },
});

function mapDispatchToProps(dispatch) {
    return {
        playSound: (type, state) => {
            dispatch(playSound(type, state));
        },
        deploySnackBar: (variant, message) => {
            dispatch(deploySnackBar(variant, message));
        }
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(StockWriteOff));