import React, { Component } from 'react';
import _                    from 'lodash';
import { connect }          from 'react-redux';
import API                  from 'API';

import { FormControl, Grid, Button } from '@material-ui/core/';

import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import Textarea                from 'Components/Common/Inputs/Textarea';

import { closeDialog } from 'Actions/Dialog/Dialog';

const initialState = () => ({
    serialNumbers:      [],
    warrantyVoidLabels: [],
    formErrors:         {
        warrantyVoidLabels:     null,
        serialNumbers:          null,
    },
    formData: {
        note:               null,
        serialNumber:       [],
        usedSerialNumber:   [],
        warrantyVoidLabels: []
    },
});

class AddPartEntryRowDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        const { part } = this.props;
        if (part.part_req_serial_number == 'Yes') this.getSerialNumbers();
    }

    getSerialNumbers(){
        const id = this.props.part.part_id;
        API.get(`/parts/${id}/usedSerialNumbers`)
        .then(res => {
            if (res.data){
                let serialNumbers = _.map(res.data, i => {
                    if(!_.find(this.props.usedSerialNumbers, {id: i.id}))
                        return _.assign({
                            value: i.id,
                            label: i.serialNumber
                        });
                })
                this.setState({...this.state,serialNumbers});
            }
        });
    }

    handleInputChange = e => {
        const {name,value} = e.target;
        this.setState({
            formData:{
                ...this.state.formData,
                note: value
            }
        });
    }

    handleMultiSelectChange = field => selectedOptions => {
        let value = selectedOptions ? (
            field === 'serialNumber' ?
                selectedOptions.map(a => ({id:a.value, serialNumber: a.label})) :
                selectedOptions.map(a => a.value)
            ) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: value,
            }
        });
    }
    submit  = () => {
        const {note, serialNumber, warrantyVoidLabels}           = this.state.formData;
        const {quantity, part}                                   = this.props;
        const {part_req_serial_number, part_warranty_void_label} = part;
        if ((part_req_serial_number === 'Yes' || part_req_serial_number === 'Manual') && serialNumber.length !== parseInt(quantity)) {
            let msg = 'Too Many Serial Numbers Selected';
            if (parseInt(quantity) > serialNumber.length) msg = 'Select More Serial Numbers';
            this.setState({formErrors:{...this.state.formErrors, serialNumbers: msg}})
        } else if (part_warranty_void_label === 'Yes' && ( warrantyVoidLabels.length !== parseInt(quantity) || _.find(warrantyVoidLabels, w => w.length !== 10) )) {
            let msg = 'Too Many Warranty Void Labels Selected';
            _.each(warrantyVoidLabels, i => {if (i.length != 10 || !i.includes('CS-')){msg = 'Warranty Label is incorrect format ( CS-0000000 )';}})
            if (parseInt(quantity) > warrantyVoidLabels.length) msg = 'Select More Warranty Void Labels';
            this.setState({formErrors:{...this.state.formErrors, warrantyVoidLabels: msg}})
        } else {
            this.props.callBack(note, serialNumber, warrantyVoidLabels);
            this.setState(initialState());
        }
    }

    render() {
        const { serialNumbers, formErrors, formData:{serialNumber, note, warrantyVoidLabels} } = this.state;
        const { part } = this.props;

        return(
            <Grid container spacing={3} xs={12}>
                {part.part_req_serial_number === 'Yes' &&
                    <Grid item spacing={3} xs={12}>
                        <FormControl fullWidth>
                            <AutoCompleteMultiSelect 
                                options={serialNumbers} 
                                label='Serial Numbers *'
                                value={_.map(serialNumber, i => i.id)}
                                onChange={this.handleMultiSelectChange('serialNumber')}
                                error={formErrors['serialNumbers']}
                                errorText={formErrors['serialNumbers']}
                            />
                        </FormControl>
                    </Grid>
                }
                {part.part_warranty_void_label === 'Yes' && 
                    <Grid item spacing={3} xs={12}>
                        <FormControl fullWidth>
                            <AutoCompleteMultiSelect 
                               options={_.merge(
                                   _.map(this.props.warrantyVoidLabels, i => {return _.assign({value: i, label: i})}),
                                   _.map(warrantyVoidLabels,            i => {return _.assign({value: i, label: i})}),
                               )} 
                                label='Warranty Void Labels *'
                                value={warrantyVoidLabels}
                                onChange={this.handleMultiSelectChange('warrantyVoidLabels')}
                                error={formErrors['warrantyVoidLabels']}
                                errorText={formErrors['warrantyVoidLabels']}
                                creatable
                            />
                        </FormControl>
                    </Grid>
                }
                 {part.part_req_serial_number === 'Manual' && 
                    <Grid item spacing={3} xs={12}>
                        <FormControl fullWidth>
                            <AutoCompleteMultiSelect 
                                options={_.map(serialNumber, j => {return _.assign({value:j.id, label:j.serialNumber })})} 
                                label='Manual Serial Numbers *'
                                value={_.map(serialNumber, i => i.id)}
                                onChange={this.handleMultiSelectChange('serialNumber')}
                                error={formErrors['serialNumbers']}
                                errorText={formErrors['serialNumbers']}
                                creatable
                            />
                        </FormControl>
                    </Grid>
                }
                <Grid item spacing={3} xs={12}>
                        <Textarea
                            id="notes"
                            name="notes"
                            label="Notes"
                            value={note}
                            onChange={this.handleInputChange}
                        />
                </Grid>
                <Grid item xs={12}>
                    <div className='buttonRow'>
                        <Button
                            variant="contained" 
                            onClick={this.props.closeDialog}
                        >Close</Button>
                        <Button 
                            onClick={this.submit}
                            variant="contained" 
                            color="primary" 
                            disabled={(part.part_req_serial_number === 'Yes' || part.part_req_serial_number === 'Manual') && !serialNumber.length}
                        >
                            Add
                        </Button>
                    </div>
                </Grid>
            </Grid>
        );
    }
}


const mapDispatchToProps = dispatch => ({closeDialog:() => {dispatch(closeDialog())}});

export default connect(null, mapDispatchToProps)(AddPartEntryRowDialog);