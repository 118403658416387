import React from 'react';
import API from 'API';
import { connect } from 'react-redux';
import {
    Button,
    Divider,
    Grid,
    TextField,
    Typography,
    IconButton, 
    Tooltip,
    FormControl,
    Box,
    DialogActions,
    FormControlLabel,
    RadioGroup,
    Radio

} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _, { isArray } from 'lodash';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import TextArea from 'Components/Common/Inputs/Textarea';
import { handleChange, handleMultiSelectChange } from 'Functions/FormFunctions';
import { toggleDialog } from 'Functions/MiscFunctions';
import { CLENAWARE_BLUE, CLENAWARE_RED, CLENAWARE_GREEN } from 'Constants'
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import ElementForm from 'Components/Common/Forms/FormBuilder/ElementForm';
import Elements from 'Components/Common/Forms/FormBuilder/Elements';
import { formatValidationErrors }   from 'Helpers/ErrorHelper';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import InformationForm from 'Components/Common/Forms/FormBuilder/InformationForm';
import AllIcon from 'Components/Common/Icons/AllIcon';

const styles = theme => ({
   
})

const initialState = () => ({
    formData: {
        systemCatId: null,
        linkedProductOrService: 'Product',
        linkedParts: [],
        areaId: null,
        subCatId: null,
        associatedParts: [],
        associatedDocuments: [],
        name: '',
        description: '',
        pages: [
            {
                groups: [],
            }
        ],
        changeLogDescription: ''
    },
    formId: null,
    formErrors: {},
    isLoading: false,
    dialog: {
        elementGroupForm: false,
        elementForm: false,
        informationForm: false,
        informationDialogData: null
    },
    groupDialogData:{
        name: '',
        gIdx: null,
        type: 'Add',
        pIdx: null
    },
    elementDialogData:{
        formData: null,
        questionTextList: [],
        eIdx: null,
        gIdx: null,
        pIdx: null,
        type: 'Add',
        configFormId: 0
    },
    partsList: [],
    productList: [],
    serviceList: [],
    documentsList: [],
    categories: [],

    areasList: [],
    categoriesList: [],
    systemCategories: [],
    dataSets: [],
    form: {}
});

class FormBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.toggleDialog = toggleDialog.bind(this);
        this.handleChange = handleChange.bind(this);
        this.handleMultiSelectChange = handleMultiSelectChange.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData();
    }

    loadComponentData = () => {

        let formId  = this.props.formId;

        Promise.all([
            API.get(`/forms/configuration/categories`),
            API.get(`/forms/configuration/categories/system`)
        ])
        .then(([result, sysCatRes]) =>  {

            let categories  = result.data;
            let areasList   = [];
            let systemCategories = [];

            // Create Areas List for Select
            _.forEach(result.data, (area) => {
                areasList.push({
                    label: area.nm,
                    value: area.id
                });
            })

            _.forEach(sysCatRes.data, (sc) => {
                systemCategories.push({
                    label: sc.nm,
                    options: _.map(sc.sub, (subCategory) => ({
                        label: subCategory.nm,
                        value: subCategory.id
                    }))
                })
            })

            this.setState({
                isLoading: formId ? true : false,
                formId,
                categories,
                areasList,
                systemCategories
            }, () => {

                this.getParts();
                this.getDocuments();

                if(formId) {
                    this.getUpdateData();
                }
            })
        });
    }

    getUpdateData = () => {

        API.get(`/forms/configuration/${this.state.formId}`)
        .then(result =>  {
            let form            = result.data,
                pages           = form?.pages;

            // Linked Parts
            let linkedParts = _.map(form?.linkedParts, (part) => {
                return part.id
            });

            // Associated Parts
            let associatedParts = [];
            let infoAssocParts = [];
            _.map(form.assocParts, (part) => {

                associatedParts.push(part.id);

                infoAssocParts.push({
                    value: part.id,
                    label: part.number + ' - ' + part.desc
                });
            });
            form.dataSets['associatedParts'] = infoAssocParts;

            // Associated Documents
            let associatedDocuments = _.map(form?.assocDocs, (document) => {
                return document.id
            });

            this.handleCategoryChange('area', form.area?.id)

            this.setState({
                formData: {
                    ...this.state.formData,
                    linkedParts,
                    linkedProductOrService: form.linkedProductOrService,
                    areaId: form.area?.id,
                    subCatId: form.subCat?.id,
                    systemCatId: form.sysCat?.id,
                    associatedParts,
                    associatedDocuments,
                    name: form.nm,
                    description: form.desc,
                    pages,
                    changeLogDescription: '',
                },
                isLoading: false,
                dataSets: form.dataSets,
                form: form
            })
        });
    }

    getParts = () => {        
        API.get('/parts/all' , {params: {active: true, use: 'forSelect'}})
        .then((result) => {
            let partsList = _.map(result.data, (el) => {
                return _.assign({
                    value:      el.id,
                    label:      el.partNumber + ' - ' + el.partDesc,
                    isProduct:  el.isProduct
                });
            });

            // Filter out only products
            let productList = _.filter(partsList, (el) => {return el.isProduct == 'Yes'});
            let serviceList = _.filter(partsList, (el) => {return el.isProduct == 'No'});

            this.setState({
                partsList: productList,
                productList,
                serviceList
            });
        })
    }

    getDocuments = () => {        
        API.get('/documents/all', { params: { forSelect: true } })
        .then((result) => {
            let documentsList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.id,
                    label: el.subCategory + ' | ' + el.title
                });
            });
            this.setState({
                documentsList
            });
        })
    }

    handleSubmit = () => {
        const   { formId, formData }      = this.state,
                route   = formId ? `/forms/configuration/${formId}`: `/forms/configuration`;

        this.setState({
            isLoading: true
        }, () => {
            API.post(route, formData).then(result => {
                if(result) {
                    if(result?.data?.errors) {
                        this.setState({
                            ...this.state,
                            formErrors: formatValidationErrors(result.data.errors),
                            isLoading: false
                        });
                    } else {
                        this.setState({
                            isLoading: false,
                        }, () => {
                            this.props.deploySnackBar("success", `You have successfully ${formId ? 'updated the' : 'added a new'} form`);
                            this.props.onClose()
                            this.props.history.push('/forms/view/'+result?.data?.id);
                        })
                    }
                }
            },
            err => API.handleError(err));
        });
    }

    handleMove = (pIdx, dir, gIdx = null, eIdx = null) => {
        let pages = this.state.formData.pages;
        let array, idx, item;

        if(eIdx !== null){ // Elements
            array   = pages[pIdx].groups[gIdx].elements;
            idx     = eIdx;
        } 
        else if(gIdx !== null){ // Groups
            array   = pages[pIdx].groups;
            idx     = gIdx;
        }
        else { // Pages
            array   = pages;
            idx     = pIdx;
        }

        switch(dir) {
            case 'up':
                if (idx === 0) {
                    return; // Item is already at the top
                }
                item = array[idx];
                array.splice(idx, 1); // Remove item from current position
                array.splice(idx - 1, 0, item); // Insert item one position above
            break;
            case 'down':
                if (idx === array.length - 1) {
                    return; // Item is already at the bottom
                }
                item = array[idx];
                array.splice(idx, 1); // Remove item from current position
                array.splice(idx + 1, 0, item); // Insert item one position below
            break;
            default:
            break;
        }
        this.setState({
            formData: {
                ...this.state.formData,
                pages
            }
        });
    }

    /*
    * PAGES
    */
    addPage = () => {
        let pages = this.state.formData.pages;
        pages.push({
            groups: [],
        });
        this.setState({
            formData: {
                ...this.state.formData,
                pages
            }
        })
    }

    removePage = (pIdx) => {
        let pages = this.state.formData.pages;

        pages.splice(pIdx, 1);

        this.setState({
            formData: {
                ...this.state.formData,
                pages
            }, 
        });
    }

    /*
    * ELEMENT GROUPS
    */
    openElementGroup = (type, pIdx, name, gIdx) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                elementGroupForm: true
            },
            groupDialogData: {
                ...this.state.groupDialogData,
                name,
                gIdx,
                type,
                pIdx
            }
        });
    }

    addUpdateElementGroup = (nm, gIdx, pIdx) => {
        let pages   = this.state.formData.pages;
        let groups  = pages[pIdx].groups;

        if(gIdx !== undefined) {
            groups[gIdx].nm = nm
        }
        else {
            groups.push({
                nm,
                elements: []
            })
        }

        this.setState({
            formData: {
                ...this.state.formData,
                pages
            }, 
            groupDialogData: {
                ...this.state.groupDialogData,
                name: '',
                gIdx: null,
            }
        }, 
        () => {
            this.toggleDialog('elementGroupForm', true)
        });
    }

    handleElementGroupChange = (event) => {
        this.setState({
            groupDialogData: {
                ...this.state.groupDialogData,
                name: event.target.value
            }
        });
    }

    removeElementGroup = (gIdx, pIdx) => {
        let pages           = this.state.formData.pages;
        let groups    = pages[pIdx].groups;

        groups.splice(gIdx, 1);

        this.setState({
            formData: {
                ...this.state.formData,
                pages
            }, 
        });
    }

    /*
    * ELEMENTS
    */
    handleElementOpen = (type, pIdx, gIdx, eIdx) => {
        let pages   = this.state.formData.pages;
        let groups  = pages[pIdx].groups;

        // Get a list of all the question text
        let questionTextList = [];
        groups.forEach((group, gIdx) => {
            group.elements.forEach((element, eIdx) => {
                if(element.nm !== '' && eIdx !== eIdx) {
                    questionTextList.push(element.nm)
                }
            })
        })

        this.setState({
            dialog: {
                ...this.state.dialog,
                elementForm: true
            },
            elementDialogData: {
                ...this.state.elementDialogData,
                type,
                pIdx,
                gIdx,
                eIdx,
                formData: eIdx !== undefined ? groups[gIdx].elements[eIdx] : null,
                questionTextList, // For validating duplicate question  names
                configFormId: this.state.formId
            }
        });
    }

    addUpdateElement = (formData, pIdx, gIdx, eIdx) => {

        let pages   = this.state.formData.pages;
        let groups  = pages[pIdx].groups;

        if(eIdx !== undefined) {
            let elements = groups[gIdx]?.elements
            elements[eIdx] = formData
        }
        else {
            groups[gIdx].elements.push(formData)
        }

        this.setState({
            formData: {
                ...this.state.formData,
                pages
            }, 
            elementDialogData: initialState.elementDialogData
        }, 
        () => {
            this.toggleDialog('elementForm', true)
        });
    }

    removeElement = (pIdx, gIdx, eIdx) => {
        let pages   = this.state.formData.pages;
        let groups  = pages[pIdx].groups;

        groups[gIdx].elements.splice(eIdx, 1);

        this.setState({
            formData: {
                ...this.state.formData,
                pages
            }, 
        });
    }

    handleCategoryChange = (type, value) => {

        if(type == 'area'){

            let categoriesList = [];

            // Find area in categories array
            let area = _.find(this.state.categories, (area) => area.id == value)

            // Create categoriesList to dynamically populate the select
            _.forEach(area.cat, (category) => {
                categoriesList.push({
                    label: category.nm,
                    options: _.map(category.sub, (subCategory) => ({
                        label: subCategory.nm,
                        value: subCategory.id
                    }))
                })
            })

            this.setState({
                formData: {
                    ...this.state.formData,
                    areaId: value,
                    subCatId: null,
                },
                categoriesList
            });
        }
        else if(type == 'subCat'){
            this.setState({
                formData: {
                    ...this.state.formData,
                    subCatId: value,
                }
            });
        }
        else if(type == 'sysCat'){
            this.setState({
                formData: {
                    ...this.state.formData,
                    systemCatId: value,
                }
            });
        }
    }

    handleElementChange = (pIdx, gIdx, eIdx, formData) => {

        let pages       = this.state.formData.pages;
        let groups      = pages[pIdx].groups;
        let elements    = groups[gIdx]?.elements
        
        elements[eIdx].answer = formData;

        this.setState({
            formData: {
                ...this.state.formData,
                pages
            },
        });
    }

    handleElementSelectChange = (pIdx, gIdx, eIdx, selectedOption) => {
        let pages       = this.state.formData.pages;
        let groups      = pages[pIdx].groups;
        let elements    = groups[gIdx]?.elements
        
        elements[eIdx].answer = selectedOption.value;

        this.setState({
            formData: {
                ...this.state.formData,
                pages
            },
        });
    }

    handleInformationOpen = (info) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                informationForm: true,
                informationDialogData: info ? info : null,
            },
        });
    }

    render() {
        const { formData, formErrors, isLoading, formId, dialog, partsList, productList, serviceList, documentsList, groupDialogData, categoriesList, areasList, dataSets, systemCategories, form } = this.state;
        const { classes } = this.props;
        
        return (
            <>
                <Grid container item spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            <b>Form Details</b>
                        </Typography>
                        <Typography variant="body2" paragraph>
                            Select the Products / Services this form links to
                        </Typography>

                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    {formErrors?.generic && (
                                        <Typography variant="body2" className="fw-400 textError" paragraph>
                                            {formErrors?.generic} 
                                        </Typography>
                                    )}
                                    <form noValidate autoComplete="off">
                                        <Grid container item spacing={3}>
                                            <Grid item xs={12}>
                                                {formId ?
                                                <>
                                                    <Typography variant="caption" color='textSecondary'>
                                                        Type of Form
                                                    </Typography>
                                                    <Typography variant="body1" gutterBottom>
                                                        {form?.sysCat?.nm}
                                                    </Typography>
                                                </>
                                                
                                                :
                                                    <AutoCompleteSelect 
                                                        isGrouped
                                                        options={systemCategories} 
                                                        label='Type of Form *'
                                                        fullWidth
                                                        value={formData.systemCatId}
                                                        noClear
                                                        onChange={(v) => this.handleCategoryChange('sysCat', v.value)}
                                                        error={formErrors && formErrors['systemCatId'] && true}
                                                        errorText={formErrors && formErrors['systemCatId'] && formErrors['systemCatId']}
                                                        disabled={formId}
                                                    />
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    This form is linked to:
                                                </Typography>
                                                <RadioGroup
                                                    name="linkedProductOrService"
                                                    value={formData?.linkedProductOrService}
                                                    onChange={this.handleChange}
                                                    row
                                                >
                                                    <FormControlLabel
                                                        value="Product"
                                                        control={<Radio color="primary" />}
                                                        label="Products"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Service"
                                                        control={<Radio color="primary" />}
                                                        label="Services"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="N/A"
                                                        control={<Radio color="primary" />}
                                                        label="Not Applicable"
                                                        labelPlacement="end"
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                            {formData?.linkedProductOrService !== 'N/A' &&
                                                <Grid item xs={12}>
                                                    <AutoCompleteMultiSelect
                                                        name="linkedParts"
                                                        options={formData?.linkedProductOrService == 'Product' ? productList : serviceList}
                                                        label={'Linked ' + formData?.linkedProductOrService + 's *'}
                                                        value={formData.linkedParts}
                                                        fullWidth
                                                        onChange={(v) => this.handleMultiSelectChange('linkedParts', v)} 
                                                        error={formErrors && formErrors['linkedParts'] && true}
                                                        errorText={formErrors && formErrors['linkedParts']}
                                                    />
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <Grid container item spacing={3}>
                                                    <Grid item xs={6}>
                                                        <AutoCompleteSelect 
                                                            options={areasList} 
                                                            label='Area *'
                                                            fullWidth
                                                            value={formData.areaId}
                                                            noClear
                                                            onChange={(v) => this.handleCategoryChange('area', v.value)}
                                                            error={formErrors && formErrors['areaId'] && true}
                                                            errorText={formErrors && formErrors['areaId'] && formErrors['areaId']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <AutoCompleteSelect 
                                                            isGrouped
                                                            options={categoriesList} 
                                                            label='Category *'
                                                            fullWidth
                                                            value={formData.subCatId}
                                                            noClear
                                                            disabled={!formData.areaId}
                                                            onChange={(v) => this.handleCategoryChange('subCat', v.value)}
                                                            error={formErrors && formErrors['subCatId'] && true}
                                                            errorText={formErrors && formErrors['subCatId'] && formErrors['subCatId']}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    name="name"
                                                    label="Name *"
                                                    margin="none" 
                                                    autoComplete="off"
                                                    error={formErrors && formErrors['name'] && true}
                                                    helperText={formErrors && formErrors['name']}
                                                    value={formData.name || ''}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    name="description"
                                                    label="Description"
                                                    margin="none" 
                                                    autoComplete="off"
                                                    error={formErrors && formErrors['description'] && true}
                                                    helperText={formErrors && formErrors['description']}
                                                    value={formData.description || ''}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    multiline
                                                />
                                            </Grid>
                                        </Grid>
                                    </form>
                                </PaddedPaper>   
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <form noValidate autoComplete="off">
                                        <Grid container item spacing={3}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth margin="normal">
                                                    <AutoCompleteMultiSelect
                                                        name="associatedParts"
                                                        options={partsList}
                                                        label='Associated Parts'
                                                        value={formData.associatedParts}
                                                        fullWidth
                                                        onChange={(v) => this.handleMultiSelectChange('associatedParts', v)} 
                                                        error={formErrors && formErrors['associatedParts'] && true}
                                                        errorText={formErrors && formErrors['associatedParts']}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth margin="normal">
                                                    <AutoCompleteMultiSelect
                                                        name="associatedDocuments"
                                                        options={documentsList}
                                                        label='Associated Documents'
                                                        value={formData.associatedDocuments}
                                                        fullWidth
                                                        onChange={(v) => this.handleMultiSelectChange('associatedDocuments', v)} 
                                                        error={formErrors && formErrors['associatedDocuments'] && true}
                                                        errorText={formErrors && formErrors['associatedDocuments']}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </PaddedPaper>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{marginBottom: 20}} />
                        <Grid container spacing={3} justify="center">
                            <Grid item xs={12} lg={8} align="center">
                                <Typography variant="body1">
                                    <b>FORM</b>
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    Create / edit your own elements
                                </Typography>

                                {formData?.pages.map((page, pIdx) => {
                                    return (
                                        <Grid container spacing={2} key={pIdx}>
                                            <Grid item xs={12} align="right">
                                                <Typography variant="body2" >
                                                    <b>Page {pIdx + 1}</b>
                                                    <Tooltip title="Remove Page">
                                                        <IconButton onClick={() => this.removePage(pIdx)}>
                                                            <FALightIcon button noMargin icon='trash-alt' size={15} fixedHeight color={CLENAWARE_RED} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Move Down">
                                                        <IconButton onClick={() => this.handleMove(pIdx, 'down')}>
                                                            <FALightIcon button noMargin icon='arrow-down' size={15} fixedHeight color={CLENAWARE_BLUE} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Move Up">
                                                        <IconButton onClick={() => this.handleMove(pIdx, 'up')}>
                                                            <FALightIcon button noMargin icon='arrow-up' size={15} fixedHeight color={CLENAWARE_BLUE} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} align="center">
                                                            <Grid item xs={12}>
                                                                <Grid container item spacing={3}>
                                                                    {formErrors?.groups && (
                                                                        <Grid item xs={12}>
                                                                            <Typography variant="body2" className="fw-500 textError">
                                                                                {formErrors?.groups} 
                                                                            </Typography>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                                <Grid container item spacing={3}>
                                                                    <Grid item xs={12} align="right">
                                                                        <Button
                                                                            onClick={() => this.openElementGroup('Add', pIdx)}
                                                                            variant="text"
                                                                        >
                                                                            <FALightIcon icon='plus' button size={15} color={CLENAWARE_GREEN} />
                                                                            Add Group
                                                                        </Button>
                                                                    </Grid>
                                                                    
                                                                    <Grid item xs={12} align="left">
                                                                        {page?.groups.map((group, gIdx) => {
                                                                            return (
                                                                                <React.Fragment key={gIdx}>
                                                                                <Box style={{backgroundColor: CLENAWARE_BLUE, border: '1px solid rgb(241, 241, 241)', color: 'white'}} p={2} mt={1}>
                                                                                    <Grid container spacing={3} style={{justifyContent: 'space-between'}}>
                                                                                        <Grid item>
                                                                                            <Typography variant="subtitle1" gutterBottom>
                                                                                                {group?.nm}
                                                                                            </Typography>
                                                                                            {formErrors['groupName|'+gIdx] && (
                                                                                                <Typography variant="body2" className="fw-500 textError">
                                                                                                    {formErrors['groupName|'+gIdx]} 
                                                                                                </Typography>
                                                                                            )}
                                                                                        </Grid>
                                                                                        <Grid item >
                                                                                            <Tooltip title="Add Element">
                                                                                                <IconButton onClick={() => this.handleElementOpen('Add', pIdx, gIdx)} style={{backgroundColor: 'white'}}>
                                                                                                    <FALightIcon button noMargin icon='plus' size={15} fixedHeight color={CLENAWARE_GREEN} />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                            <Tooltip title="Edit Element Group">
                                                                                                <IconButton onClick={() => this.openElementGroup('Update', pIdx, group?.nm, gIdx)} style={{backgroundColor: 'white'}}>
                                                                                                    <FALightIcon button noMargin icon='pencil-alt' size={15} fixedHeight/>
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                            <Tooltip title="Remove Element Group">
                                                                                                <IconButton onClick={() => this.props.deployConfirmation(`Are you sure you want to remove this element group?`, 'Remove Element Group?', () => this.removeElementGroup(gIdx, pIdx))} style={{backgroundColor: 'white'}}>
                                                                                                    <FALightIcon button noMargin icon='trash-alt' size={15} fixedHeight color={CLENAWARE_RED}/>
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                            <Tooltip title="Move Down">
                                                                                                <IconButton onClick={() => this.handleMove(pIdx, 'down', gIdx)} style={{backgroundColor: 'white'}}>
                                                                                                    <FALightIcon button noMargin icon='arrow-down' size={15} fixedHeight color={CLENAWARE_BLUE} />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                            <Tooltip title="Move Up">
                                                                                                <IconButton onClick={() => this.handleMove(pIdx, 'up', gIdx)} style={{backgroundColor: 'white'}}>
                                                                                                    <FALightIcon button noMargin icon='arrow-up' size={15} fixedHeight color={CLENAWARE_BLUE} />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                                <Box p={2}>
                                                                                    {formErrors['elements|'+gIdx] && (
                                                                                        <Typography variant="body2" className="fw-500 textError">
                                                                                            {formErrors['elements|'+gIdx]} 
                                                                                        </Typography>
                                                                                    )}
                                                                                    {group?.elements.map((q, eIdx) => {
                                                                                        return (
                                                                                            <>
                                                                                            <Grid container spacing={1} key={eIdx} style={{marginBottom: 30}}>
                                                                                                <Grid item xs={12}>
                                                                                                    <Typography variant="body1">
                                                                                                        <b>{q?.nm}</b>
                                                                                                        {q?.elConfig?.isRequired ? <span style={{color:'red'}}> *</span> : null}
                                                                                                        {' '}
                                                                                                        {q?.info && q?.info?.length > 0 && (
                                                                                                            <AllIcon icon='circle-info' noMargin fixedHeight button size={14} onClick={() => this.handleInformationOpen(q?.info)} />
                                                                                                        )}
                                                                                                    </Typography>
                                                                                                    {q?.description &&
                                                                                                        <Typography variant="caption" paragraph>
                                                                                                            {q?.description}
                                                                                                        </Typography>
                                                                                                    }
                                                                                                </Grid>
                                                                                                <Grid item xs={8}>
                                                                                                    {formErrors['elType|'+eIdx] && (
                                                                                                        <Typography variant="body2" className="fw-500 textError">
                                                                                                            {formErrors['elType|'+eIdx]} 
                                                                                                        </Typography>
                                                                                                    )}
                                                                                                    {formErrors['elConfig|'+eIdx] && (
                                                                                                        <Typography variant="body2" className="fw-500 textError">
                                                                                                            {formErrors['elConfig|'+eIdx]} 
                                                                                                        </Typography>
                                                                                                    )}
                                                                                                    <Elements 
                                                                                                        element={q}
                                                                                                        eIdx={eIdx}
                                                                                                        dataSets={dataSets}
                                                                                                        handleChange={(formData) => this.handleElementChange(pIdx, gIdx, eIdx, formData)}
                                                                                                        handleSelectChange={(formData) => this.handleElementSelectChange(pIdx, gIdx, eIdx, formData)}
                                                                                                    />
                                                                                                    {q?.elConfig?.resultType == 'pre-populated' && q?.elConfig?.resultTypeData && (
                                                                                                        <Typography variant="caption"  style={{backgroundColor: 'aliceblue', padding: '4px'}}>
                                                                                                            <b>Result pre-populated:</b> {q?.elConfig?.resultTypeData?.form?.label} - {q?.elConfig?.resultTypeData?.ref}
                                                                                                        </Typography>
                                                                                                    )}
                                                                                                    {q?.elConfig?.resultType == 'reusable' && (
                                                                                                        <Typography variant="caption"  style={{backgroundColor: 'aliceblue', padding: '4px'}}>
                                                                                                            <b>Result reusable ref:</b> {q?.elConfig?.resultTypeData?.ref}
                                                                                                        </Typography>
                                                                                                    )}
                                                                                                </Grid>
                                                                                                <Grid item xs={4} align="right" >
                                                                                                    <Tooltip title="Edit Element">
                                                                                                        <IconButton onClick={() => this.handleElementOpen('Update', pIdx, gIdx, eIdx)}>
                                                                                                            <FALightIcon button noMargin icon='pencil-alt' size={15} fixedHeight/>
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                    <Tooltip title="Remove Element">
                                                                                                        <IconButton onClick={() => this.props.deployConfirmation(`Are you sure you want to remove '${q?.nm}'?`, 'Remove', () => this.removeElement(pIdx, gIdx, eIdx))}>
                                                                                                            <FALightIcon button noMargin icon='trash-alt' size={15} fixedHeight color={CLENAWARE_RED}/>
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                    <Tooltip title="Move Down">
                                                                                                        <IconButton onClick={() => this.handleMove(pIdx, 'down', gIdx, eIdx)}>
                                                                                                            <FALightIcon button noMargin icon='arrow-down' size={15} fixedHeight color={CLENAWARE_BLUE} />
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                    <Tooltip title="Move Up">
                                                                                                        <IconButton onClick={() => this.handleMove(pIdx, 'up', gIdx, eIdx)}>
                                                                                                            <FALightIcon button noMargin icon='arrow-up' size={15} fixedHeight color={CLENAWARE_BLUE} />
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            { /* NESTED ELEMENTS */ }
                                                                                            {_.map(q?.elConfig.options, (option, oIdx) => (

                                                                                                <Box style={{
                                                                                                    marginLeft: '8.5%', 
                                                                                                    opacity: (isArray(q?.answer) && _.find(q?.answer, el => el.checked === option.value) || option?.value == q?.answer) ? 1 : 0.3
                                                                                                }}>
                                                                                                    {_.map(option?.nestedElements, (n, nIdx) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                            <Grid container spacing={1} key={eIdx} style={{marginBottom: 30}}>
                                                                                                                <Grid item xs={12}>
                                                                                                                    <Typography variant="body1" gutterBottom>
                                                                                                                        <b>{n?.formData?.nm}</b>
                                                                                                                        {' '}
                                                                                                                        {n?.formData?.info && n?.formData?.info?.length > 0 && (
                                                                                                                            <AllIcon icon='circle-info' noMargin fixedHeight button size={15} onClick={() => this.handleInformationOpen(n?.formData?.info)} />
                                                                                                                        )}
                                                                                                                    </Typography>
                                                                                                                    {n?.formData?.description &&
                                                                                                                        <Typography variant="caption" gutterBottom>
                                                                                                                            {n?.formData?.description}
                                                                                                                        </Typography>
                                                                                                                    }
                                                                                                                </Grid>
                                                                                                                <Grid item xs={8}>
                                                                                                                    {/* {formErrors['elType|'+eIdx] && (
                                                                                                                        <Typography variant="body2" className="fw-500 textError">
                                                                                                                            {formErrors['elType|'+eIdx]} 
                                                                                                                        </Typography>
                                                                                                                    )}
                                                                                                                    {formErrors['elConfig|'+eIdx] && (
                                                                                                                        <Typography variant="body2" className="fw-500 textError">
                                                                                                                            {formErrors['elConfig|'+eIdx]} 
                                                                                                                        </Typography>
                                                                                                                    )} */}
                                                                                                                    <Elements 
                                                                                                                        element={n?.formData}
                                                                                                                        eIdx={eIdx}
                                                                                                                        dataSets={dataSets}
                                                                                                                    />
                                                                                                                    {n?.formData?.elConfig?.resultType == 'pre-populated' && n?.formData?.elConfig?.resultTypeData && (
                                                                                                                        <Typography variant="caption"  style={{backgroundColor: 'aliceblue', padding: '4px'}}>
                                                                                                                            <b>Result pre-populated:</b> {n?.formData?.elConfig?.resultTypeData?.form?.label} - {n?.formData?.elConfig?.resultTypeData?.ref}
                                                                                                                        </Typography>
                                                                                                                    )}
                                                                                                                    {n?.formData?.elConfig?.resultType == 'reusable' && (
                                                                                                                        <Typography variant="caption"  style={{backgroundColor: 'aliceblue', padding: '4px'}}>
                                                                                                                            <b>Result reusable ref:</b> {n?.formData?.elConfig?.resultTypeData?.ref}
                                                                                                                        </Typography>
                                                                                                                    )}
                                                                                                                </Grid>
                                                                                                                <Grid item xs={4} align="right" >
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                            </>
                                                                                                        )
                                                                                                    })}
                                                                                                </Box>
                                                                                            ))}

                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </Box>
                                                                                </React.Fragment>
                                                                            )
                                                                        })}
                                                                    </Grid>

                                                                    <Grid item xs={12} align="right">
                                                                        <Button
                                                                            onClick={() => this.openElementGroup('Add', pIdx)}
                                                                            variant="text"
                                                                        >
                                                                            <FALightIcon icon='plus' button size={15} color={CLENAWARE_GREEN} />
                                                                            Add Group
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={12} lg={8} align="center">
                                <Button
                                    onClick={() => this.addPage()}
                                    variant="text"
                                >
                                    <FALightIcon icon='plus' button size={15} />
                                    Add Page
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider style={{marginTop: 20}} />
                    </Grid>
                    {formId &&
                        <Grid item xs={12}>
                            <Typography variant="body1" paragraph>
                                <b>Change Log</b>
                            </Typography>
                            <PaddedPaper>
                                <form noValidate autoComplete="off">
                                    <TextArea
                                        label="Change Log Description *"
                                        margin="none"
                                        name="changeLogDescription"
                                        value={formData.changeLogDescription}
                                        error={formErrors && formErrors['changeLogDescription']}
                                        helperText={formErrors && formErrors['changeLogDescription']}
                                        variant="outlined"
                                        onChange={this.handleChange}
                                        rows={2}
                                    /> 
                                </form>
                            </PaddedPaper>
                        </Grid>
                    }
                    <Grid item xs={12} align="right">
                        <div className='buttonRow'>
                            <Button
                                color="primary"
                                onClick={() => this.props.deployConfirmation(`Are you sure you want submit?`, 'Submit', () => this.handleSubmit())}
                                variant="contained"
                            >
                                <FALightIcon icon="check" size={15} buttonPrimary />
                                {formId ? `Update` : `Submit`}
                            </Button>
                        </div>
                        
                    </Grid>
                </Grid>
                {/* Element Group Dialog */}
                <SmallFormDialog
                    open={dialog.elementGroupForm}
                    onClose={() => this.toggleDialog('elementGroupForm', true)}
                    title={`${groupDialogData.type} Element Group`}
                    content={<>
                        <form noValidate autoComplete="off">
                            <TextField
                                label="Group Name *"
                                margin="none" 
                                autoComplete="off"
                                value={groupDialogData.name || ''}
                                fullWidth
                                onChange={this.handleElementGroupChange}
                            />
                        </form>
                        <DialogActions className='pr-0 pb-0'>
                            <Button 
                                onClick={() => this.toggleDialog('elementGroupForm', true)} 
                                variant="text"
                            >
                                <FALightIcon icon="times" size={15} button />
                                Cancel
                            </Button>
                            <Button 
                                color="primary"
                                onClick={() => this.addUpdateElementGroup(groupDialogData.name, groupDialogData.gIdx, groupDialogData.pIdx)}
                                variant="contained"
                                disabled={!groupDialogData.name}
                            >
                                <FALightIcon icon="check" size={15} color="white" />
                                {groupDialogData.type}
                            </Button>
                        </DialogActions>
                    </>}
                    maxWidth='xs'
                />
                {/* Element Dialog */}
                <SmallFormDialog
                    open={dialog.elementForm}
                    onClose={() => this.toggleDialog('elementForm', true)}
                    title={`${this.state.elementDialogData?.type} Element`}
                    content={<ElementForm 
                                data={this.state.elementDialogData} 
                                addUpdateElement={this.addUpdateElement}
                                toggleDialog={this.toggleDialog}
                            />}
                    maxWidth='md'
                />
                {/* Information Dialog */}
                <SmallFormDialog
                    open={dialog.informationForm}
                    onClose={() => this.toggleDialog('informationForm', true)}
                    title={`Information`}
                    content={
                    <InformationForm 
                        data={dialog?.informationDialogData}
                        toggleDialog={this.toggleDialog}
                        readOnly={true}
                    />}
                    maxWidth='sm'
                />
            
            </>
        );
    }
}

const mapStateToProps = state => ({
    loggedInStaff:      state.staffAuth.staff
})

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FormBuilder));