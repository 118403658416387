import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import ImageWithError from '../../../Common/ImageWithError/ImageWithError';

class QRCode extends Component {

    render() {
        const { qrCode } = this.props;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>QR Code</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <ImageWithError type='QR' alt='QR Code' width="175" height="175" src={qrCode} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default QRCode;
