import React  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
    Button,
    RadioGroup,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    Radio,
    InputAdornment,
    Avatar
} from '@material-ui/core';

import API from 'API';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import {colors} from 'Helpers/ColourHelper';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { handleFileChange, handleDateChange, handleChangeUc, getFormData, handleChange, handleSelectChange, handleMultiSelectChange, clearValue } from 'Functions/FormFunctions';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import Textarea from 'Components/Common/Inputs/Textarea';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AllIcon from 'Components/Common/Icons/AllIcon';

const initialState = () => ({
    vehicleId: 0,
    vehicle: {},
    formData: {
        regNumber: '',
        make: '',
        model: '',
        engineSize: '',
        transmission: '',
        fuelType: '',
        colour: '',
        vinNumber: '',
        yearOfMake: '',
        type: '',
        modified: 0,
        modifications: '',
        maxHeight: '',
        maxLoad: '',
        keyCode: '',
        radioCode: '',
        electricRange: '',
        wrapped: '',
        vehicleValue: '',
        // Delivery
        deliveryDate: null,
        deliveryMileage: '',
        // Maintenance
        motExpiry: null,
        serviceMonths: '',
        serviceMiles: '',
        // Tax
        roadTaxRequired: 0,
        roadTaxPaymentMethod: '',
        roadTaxDue: null,
        ownershipType: 'Leased',
        // Ownership
        ownershipRegisteredKeeper: '',
        ownershipAddress: '',
        ownershipPurchaseDate: null,
        // Lease
        leaseCompany: '',
        leaseAgreementNumber: '',
        leaseStartDate: null,
        leaseEndDate: null,
        leaseProfileInitial: 0,
        leaseProfileMonthly: 0,
        leaseAnnualMileageAllowance: '',
        leaseContractMileageAllowance: '',
        leaseExcessMileageCharge: '',

        photo: null,
        status: 'In Contract / Ownership',
        mileageTrackerStatus: 0,
        // Devices
        devices: [],
    },
    formErrors: '',
    isLoading: true,
    suppList: [],
    devList: [],
    initialList: [],
    monthlyList: [],
});

class VehicleForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleChange = handleChange.bind(this);
        this.handleFileChange = handleFileChange.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
        this.handleChangeUc = handleChangeUc.bind(this);
        this.getFormData = getFormData.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleMultiSelectChange = handleMultiSelectChange.bind(this);
        this.clearValue = clearValue.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData(); 
    }

    loadComponentData = () => {

        const { vehicleId } = this.props.match.params;

        Promise.all([ 
            API.get('/suppliers/all', {
                params: {
                    supplierType: 10 // Vehicles
                }
            }),
            API.get('/vehicles/devices', { params: { activeOnly: true} })
        ])
        .then(([suppRes, devRes]) => {

            let suppList = _.map(suppRes.data, (supp) => {
                return _.assign({
                    value: supp.supp_id,
                    label: supp.supp_company_name,
                    contact: supp.supp_telephone,
                });
            });

            let devList = _.map(devRes.data, (dev) => {
                return _.assign({
                    value: dev.id,
                    label: dev.type + (dev.modelNumber ? ' - ' + dev.modelNumber : '') + (dev.supplier?.name ? ' (' + dev.supplier.name + ')' : ''),
                    disabled: dev.vehicle && dev.vehicle.id != vehicleId ? true : false
                });
            });

            // List of numbers 1 - 12 (Lease payment profile - initial)
            let initialList = _.map(_.range(1, 13), (num) => {
                return { value: num, label: num };
            });
            // List of numbers 1 - 60 (Lease payment profile - monthly)
            let monthlyList = _.map(_.range(1, 61), (num) => {
                return { value: num, label: num };
            });
            
            this.setState({
                isLoading: false,
                vehicleId,
                suppList,
                devList,
                initialList,
                monthlyList
            },
            () => {
                if(this.state.vehicleId) {
                    this.loadVehicleData();
                }
            });  
        });
    }

    loadVehicleData = () => {
        this.setState({
            isLoading: true,
        }, 
        () => {
            API.get('/vehicles/' + this.state.vehicleId)
            .then(res => {
    
                let v = res.data;

                let devices = _.map(v.devices, (dev) => {
                    return dev.id;
                });
    
                this.setState({
                    ...this.state,
                    isLoading: false,
                    vehicle: v,
                    formData: {
                        ...this.state.formData,
                        regNumber: v?.regNumber,
                        make: v?.make,
                        model: v?.model,
                        engineSize: v?.engineSize,
                        transmission: v?.transmission,
                        fuelType: v?.fuelType,
                        colour: v?.colour,
                        vinNumber: v?.vinNumber,
                        yearOfMake: v?.yearOfMake,
                        type: v?.type,
                        modified: v?.modified,
                        modifications: v?.modifications,
                        maxHeight: v?.maxHeight,
                        maxLoad: v?.maxLoad,
                        keyCode: v?.keyCode,
                        radioCode: v?.radioCode,
                        electricRange: v?.electricRange,
                        wrapped: v?.wrapped,
                        vehicleValue: v?.vehicleValue,
                        mileageTrackerStatus: v?.mileageTracker,
                        status: v?.status,
                        // Delivery
                        deliveryDate: v?.deliveryDate !== '0000-00-00' ? v?.deliveryDate : null,
                        deliveryMileage: v?.deliveryMileage,
                        // Maintenance
                        motExpiry: v?.motExpiry !== '0000-00-00' ? v?.motExpiry : null,
                        serviceMonths: v?.serviceMonths,
                        serviceMiles: v?.serviceMiles,
                        // Tax
                        roadTaxRequired: v?.roadTaxRequired,
                        roadTaxPaymentMethod: v?.roadTaxPaymentMethod,
                        roadTaxDue: v?.roadTaxDue  !== '0000-00-00' ? v?.roadTaxDue : null,
                        ownershipType: v?.ownershipType,
                        // Ownership
                        ownershipRegisteredKeeper: v?.ownershipRegisteredKeeper,
                        ownershipAddress: v?.ownershipAddress,
                        ownershipPurchaseDate: v?.ownershipPurchaseDate !== '0000-00-00' ? v?.ownershipPurchaseDate : null,
                        // Lease
                        leaseCompany: v?.leaseCompany,
                        leaseAgreementNumber: v?.leaseAgreementNumber,
                        leaseStartDate: v?.leaseStartDate !== '0000-00-00' ? v?.leaseStartDate : null,
                        leaseEndDate: v?.leaseEndDate !== '0000-00-00' ? v?.leaseEndDate : null,
                        leaseProfileInitial: v?.leaseProfileInitial,
                        leaseProfileMonthly: v?.leaseProfileMonthly,
                        leaseAnnualMileageAllowance: v?.leaseAnnualMileageAllowance,
                        leaseContractMileageAllowance: v?.leaseContractMileageAllowance,
                        leaseExcessMileageCharge: v?.leaseExcessMileageCharge,
                        // Devices
                        devices
                    }
                });
            });
        });
    }

    handleSubmit = () => {
        const { vehicleId } = this.state;
        const route = vehicleId ? `/vehicles/${vehicleId}`: `/vehicles`;

        API.post(route, this.getFormData())
        .then(res => {
            if(res.data.errors && res.data.errors.length > 0) {
                this.setState({
                    formErrors: formatValidationErrors(res.data.errors)
                });
            } else {
                this.setState({
                    ...initialState()
                },
                () => {
                    this.props.deploySnackBar("success", `The vehicle was successfully ${vehicleId ? 'updated' : 'added'}`);
                    this.loadComponentData();
                });
            }
        });
    }

    render() {
        const { formErrors, formData, vehicleId, vehicle, suppList, devList, initialList, monthlyList } = this.state;
        
        let selectedSupplier = _.find(suppList, (el) => el.value == formData.leaseCompany);

        return (            
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {vehicleId ? 'Update' : 'Add'} Vehicle
                    </Typography>
                </Grid>              
                <Grid container item spacing={3}>                        
                    <Grid item xs={12} lg={6}>
                        <Grid container item spacing={3}>                        
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    <b>Vehicle Details</b>
                                </Typography>
                                <PaddedPaper>
                                    <Grid container item spacing={3}>                        
                                        {formErrors && formErrors.generic && (
                                            <Grid item xs={12}>
                                                <Typography component={"div"} style={{color: colors.red}}>
                                                    {formErrors.generic}
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Grid container item spacing={3}>                        
                                                <Grid item xs>
                                                    <TextField  
                                                        name="regNumber"
                                                        label="Registration Number *"
                                                        margin="none"
                                                        error={!!formErrors && formErrors['regNumber'] && true}
                                                        helperText={formErrors && formErrors['regNumber']}
                                                        value={formData.regNumber || ''}
                                                        onChange={this.handleChangeUc}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <AutoCompleteSelect 
                                                        options={[
                                                            {value: 'Private', label: 'Private'},
                                                            {value: 'Commercial', label: 'Commercial'},
                                                        ]} 
                                                        label={"Type *"}
                                                        onChange={v => this.handleSelectChange('type', v)}
                                                        value={formData?.type}
                                                        noClear
                                                        fullWidth
                                                        error={formErrors && formErrors['type'] && true}
                                                        errorText={formErrors && formErrors['type']}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container item spacing={3}>                        
                                                <Grid item xs>
                                                    <TextField  
                                                        name="make"
                                                        label="Make *"
                                                        margin="none"
                                                        error={!!formErrors && formErrors['make'] && true}
                                                        helperText={formErrors && formErrors['make']}
                                                        value={formData.make || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <TextField  
                                                        name="model"
                                                        label="Model *"
                                                        margin="none"
                                                        error={!!formErrors && formErrors['model'] && true}
                                                        helperText={formErrors && formErrors['model']}
                                                        value={formData.model || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container item spacing={3}>                        
                                                <Grid item xs>
                                                    <AutoCompleteSelect 
                                                        options={[
                                                            {value: 'Manual', label: 'Manual'},
                                                            {value: 'Automatic', label: 'Automatic'},
                                                        ]} 
                                                        label={"Transmission "}
                                                        onChange={v => this.handleSelectChange('transmission', v)}
                                                        error={formErrors && formErrors['transmission'] && true}
                                                        errorText={formErrors && formErrors['transmission']}
                                                        value={formData?.transmission}
                                                        noClear
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <AutoCompleteSelect 
                                                        options={[
                                                            {value: 'Diesel', label: 'Diesel'},
                                                            {value: 'Petrol', label: 'Petrol'},
                                                            {value: 'Electric', label: 'Electric'},
                                                            {value: 'Hybrid', label: 'Hybrid'},
                                                        ]} 
                                                        label={"Fuel Type *"}
                                                        onChange={v => this.handleSelectChange('fuelType', v)}
                                                        error={formErrors && formErrors['fuelType'] && true}
                                                        errorText={formErrors && formErrors['fuelType']}
                                                        value={formData?.fuelType}
                                                        noClear
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container item spacing={3}>                        
                                                <Grid item xs>
                                                    <TextField  
                                                        name="engineSize"
                                                        label="Engine Size"
                                                        margin="none"
                                                        value={formData.engineSize || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <TextField  
                                                        name="colour"
                                                        label="Colour"
                                                        margin="none"
                                                        value={formData.colour || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container item spacing={3}>                        
                                                <Grid item xs>
                                                    <TextField  
                                                        name="vehicleValue"
                                                        label="Vehicle Value *"
                                                        margin="none"
                                                        value={formData.vehicleValue || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        type="number"
                                                        error={formErrors && formErrors['vehicleValue'] && true}
                                                        helperText={formErrors && formErrors['vehicleValue']}
                                                        InputProps={{
                                                            startAdornment: (<InputAdornment position="end">£</InputAdornment>)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <TextField  
                                                        name="yearOfMake"
                                                        label="Year of Make *"
                                                        margin="none"
                                                        value={formData.yearOfMake || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        type="number"
                                                        error={formErrors && formErrors['yearOfMake'] && true}
                                                        helperText={formErrors && formErrors['yearOfMake']}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container item spacing={3}>                        
                                                <Grid item xs>
                                                    <TextField  
                                                        name="vinNumber"
                                                        label="Vin Number"
                                                        margin="none"
                                                        value={formData.vinNumber || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <TextField  
                                                        name="electricRange"
                                                        label="Range (Electric Only)"
                                                        margin="none"
                                                        value={formData.electricRange || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container item spacing={3}>                        
                                                <Grid item xs>
                                                    <TextField  
                                                        name="maxHeight"
                                                        label="Max Height"
                                                        margin="none"
                                                        value={formData.maxHeight || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <TextField  
                                                        name="maxLoad"
                                                        label="Max Load"
                                                        margin="none"
                                                        value={formData.maxLoad || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container item spacing={3}>                        
                                                <Grid item xs>
                                                    <TextField  
                                                        name="keyCode"
                                                        label="Key Code"
                                                        margin="none"
                                                        value={formData.keyCode || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <TextField  
                                                        name="radioCode"
                                                        label="Radio Code"
                                                        margin="none"
                                                        value={formData.radioCode || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container item spacing={3}>                        
                                                <Grid item xs>
                                                    <AutoCompleteSelect 
                                                        options={[
                                                            {value: 1, label: 'Yes'},
                                                            {value: 0, label: 'No'},
                                                        ]} 
                                                        label={"Wrapped "}
                                                        onChange={v => this.handleSelectChange('wrapped', v)}
                                                        value={formData?.wrapped}
                                                        noClear
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <AutoCompleteSelect 
                                                        options={[
                                                            {value: 1, label: 'Yes'},
                                                            {value: 0, label: 'No'},
                                                        ]} 
                                                        label={"Modified"}
                                                        onChange={v => this.handleSelectChange('modified', v)}
                                                        value={formData?.modified}
                                                        noClear
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {formData?.modified ?
                                            <Grid item xs={12}>
                                                <Textarea
                                                    id="modifications"
                                                    name="modifications"
                                                    label="Modifications"
                                                    value={formData.modifications}
                                                    onChange={this.handleChange}
                                                    InputLabelProps={{
                                                        shrink: !!formData.modifications,
                                                    }}
                                                />
                                            </Grid>
                                        : null
                                        }
                                        <Grid item xs={12}>
                                            {vehicleId ?
                                                <Avatar alt='Vehicle' src={vehicle?.photo} style={{height: 80, width: 80, margin: 0}}>
                                                    <FASolidIcon icon='car' size='large' noMargin buttonPrimary />
                                                </Avatar>
                                            : null}
                                            <DragFileInput
                                                id="photo"
                                                name="photo"
                                                label="Vehicle Photo (.png, .jpg, .jpeg, .bmp, .gif)"
                                                file={formData.photo}
                                                errorText={formErrors && formErrors['photo']}
                                                onChange={this.handleFileChange}
                                                cancelOnClick={() => this.clearValue('photo')}
                                                emptyText='No photo selected'
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AutoCompleteSelect 
                                                options={[
                                                    {value: 'In Contract / Ownership', label: 'In Contract / Ownership'},
                                                    {value: 'Lease Completed / Sold', label: 'Lease Completed / Sold'},
                                                ]} 
                                                label={"Status *"}
                                                onChange={v => this.handleSelectChange('status', v)}
                                                value={formData?.status}
                                                noClear
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    <b>Delivery Details</b>
                                </Typography>
                                <PaddedPaper>
                                    <Grid container item spacing={3}>                        
                                        <Grid item xs>
                                            <DatePicker
                                                type="date"
                                                id="deliveryDate"
                                                name="deliveryDate"
                                                label={"Delivery Date *"}
                                                value={formData.deliveryDate}
                                                onChange={date => this.handleDateChange('deliveryDate', date, 'YYYY-MM-DD')}
                                                autoOk={true}
                                                error={!!formErrors && formErrors['deliveryDate'] && true}
                                                errorText={formErrors && formErrors['deliveryDate']}
                                                disableFuture={true}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField  
                                                name="deliveryMileage"
                                                label="Delivery Mileage *"
                                                margin="normal"
                                                value={formData.deliveryMileage || ''}
                                                onChange={this.handleChange}
                                                fullWidth
                                                error={!!formErrors && formErrors['deliveryMileage'] && true}
                                                helperText={formErrors && formErrors['deliveryMileage']}
                                            />
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    <b>Maintenance Details</b>
                                </Typography>
                                <PaddedPaper>
                                    <Grid container item spacing={3}>                        
                                        <Grid item xs={6}>
                                            <DatePicker
                                                type="date"
                                                id="motExpiry"
                                                name="motExpiry"
                                                label={"MOT Expiry *"}
                                                value={formData.motExpiry}
                                                onChange={date => this.handleDateChange('motExpiry', date, 'YYYY-MM-DD')}
                                                autoOk={true}
                                                error={!!formErrors && formErrors['motExpiry'] && true}
                                                errorText={formErrors && formErrors['motExpiry']}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">
                                                Service due every *:
                                            </Typography>
                                            <Grid container item spacing={3}  direction="row" alignItems="flex-end">                        
                                                <Grid item xs>
                                                    <TextField  
                                                        name="serviceMonths"
                                                        margin="normal"
                                                        value={formData.serviceMonths || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        error={!!formErrors && formErrors['serviceMonths'] && true}
                                                        helperText={formErrors && formErrors['serviceMonths']}
                                                        type="number"
                                                        InputProps={{
                                                            endAdornment: (<InputAdornment position="end">Months</InputAdornment>)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>or</Grid>
                                                <Grid item xs>
                                                    <TextField  
                                                        name="serviceMiles"
                                                        margin="normal"
                                                        value={formData.serviceMiles || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        error={!!formErrors && formErrors['serviceMiles'] && true}
                                                        helperText={formErrors && formErrors['serviceMiles']}
                                                        type="number"
                                                        InputProps={{
                                                            endAdornment: (<InputAdornment position="end">Miles</InputAdornment>)
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    <b>Road Tax</b>
                                </Typography>
                                <PaddedPaper>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12} md>
                                            <AutoCompleteSelect 
                                                options={[
                                                    {value: 1, label: 'Yes'},
                                                    {value: 0, label: 'No'},
                                                ]} 
                                                label="Road Tax Required"
                                                onChange={v => this.handleSelectChange('roadTaxRequired', v)}
                                                value={formData?.roadTaxRequired}
                                                noClear
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid> 
                                        {formData.roadTaxRequired === 1 && (
                                            <>
                                            <Grid item xs={12} md>
                                                <AutoCompleteSelect 
                                                    options={[
                                                        {value: 'Annually', label: 'Paid Annually'},
                                                        {value: 'Direct Debit', label: 'Paid by Direct Debit'},
                                                    ]} 
                                                    label="Payment Method"
                                                    onChange={v => this.handleSelectChange('roadTaxPaymentMethod', v)}
                                                    value={formData?.roadTaxPaymentMethod}
                                                    noClear
                                                    fullWidth
                                                    margin="normal"
                                                    disabled={formData?.roadTaxRequired === 0}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md>
                                                <DatePicker
                                                    type="date"
                                                    id="roadTaxDue"
                                                    name="roadTaxDue"
                                                    label={"Tax Due"}
                                                    value={formData.roadTaxDue}
                                                    onChange={date => this.handleDateChange('roadTaxDue', date, 'YYYY-MM-DD')}
                                                    autoOk={true}
                                                    margin="none"
                                                    disabled={formData?.roadTaxRequired === 0}
                                                />
                                            </Grid>
                                            </>
                                        )}                     
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Grid container item spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    <b>Ownership Information</b>
                                </Typography>
                                <PaddedPaper>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                name="ownershipType"
                                                onChange={this.handleChange}
                                                row
                                            >
                                                <FormControlLabel
                                                    value='Leased'
                                                    control={<Radio color="primary" />}
                                                    label="Leased"
                                                    labelPlacement="end"
                                                    checked={formData?.ownershipType == 'Leased' ? true : false}
                                                />
                                                <FormControlLabel
                                                    value='Owned'
                                                    control={<Radio color="primary" />}
                                                    label="Owned"
                                                    labelPlacement="end"
                                                    checked={formData?.ownershipType == 'Owned' ? true : false}
                                                />
                                            </RadioGroup>
                                        </Grid>
                                        {formData?.ownershipType == 'Owned' ?
                                            <>
                                            <Grid item xs={12}>
                                                <Grid container item spacing={3}>                        
                                                    <Grid item xs>
                                                        <TextField  
                                                            name="ownershipRegisteredKeeper"
                                                            label="Registered Keeper *"
                                                            margin="none"
                                                            value={formData.ownershipRegisteredKeeper || ''}
                                                            onChange={this.handleChange}
                                                            fullWidth
                                                            error={!!formErrors && formErrors['ownershipRegisteredKeeper'] && true}
                                                            helperText={formErrors && formErrors['ownershipRegisteredKeeper']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <DatePicker
                                                            type="date"
                                                            id="ownershipPurchaseDate"
                                                            name="ownershipPurchaseDate"
                                                            label={"Purchase Date"}
                                                            value={formData.ownershipPurchaseDate}
                                                            onChange={date => this.handleDateChange('ownershipPurchaseDate', date, 'YYYY-MM-DD')}
                                                            autoOk={true}
                                                            margin="none"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Textarea
                                                    id="ownershipAddress"
                                                    name="ownershipAddress"
                                                    label="Address"
                                                    value={formData.ownershipAddress}
                                                    onChange={this.handleChange}
                                                    InputLabelProps={{
                                                        shrink: !!formData.ownershipAddress,
                                                    }}
                                                />
                                            </Grid>
                                            </>
                                        :
                                            (formData?.ownershipType == 'Leased' ?
                                                <>
                                                <Grid item xs={12}>
                                                    <TextField  
                                                        name="leaseAgreementNumber"
                                                        label="Agreement Number *"
                                                        margin="none"
                                                        error={!!formErrors && formErrors['leaseAgreementNumber'] && true}
                                                        helperText={formErrors && formErrors['leaseAgreementNumber']}
                                                        value={formData.leaseAgreementNumber || ''}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container item spacing={3}>                        
                                                        <Grid item xs>
                                                            <AutoCompleteSelect 
                                                                options={suppList} 
                                                                label={"Lease Company "}
                                                                onChange={v => this.handleSelectChange('leaseCompany', v)}
                                                                error={formErrors && formErrors['leaseCompany'] && true}
                                                                errorText={formErrors && formErrors['leaseCompany']}
                                                                value={formData?.leaseCompany}
                                                                fullWidth
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <TextField  
                                                                label="Company Phone"
                                                                margin="none"
                                                                value={selectedSupplier?.contact || ''}
                                                                fullWidth
                                                                disabled
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container item spacing={3}>                        
                                                        <Grid item xs>
                                                            <DatePicker
                                                                type="date"
                                                                id="leaseStartDate"
                                                                name="leaseStartDate"
                                                                label={"Agreement Start Date"}
                                                                value={formData.leaseStartDate}
                                                                onChange={date => this.handleDateChange('leaseStartDate', date, 'YYYY-MM-DD')}
                                                                autoOk={true}
                                                                margin="none"
                                                            />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <DatePicker
                                                                type="date"
                                                                id="leaseEndDate"
                                                                name="leaseEndDate"
                                                                label={"Agreement End Date"}
                                                                value={formData.leaseEndDate}
                                                                onChange={date => this.handleDateChange('leaseEndDate', date, 'YYYY-MM-DD')}
                                                                autoOk={true}
                                                                margin="none"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" gutterBottom>
                                                        <b>Payment Profile</b>        
                                                    </Typography>
                                                    <Grid container item spacing={3} direction="row" alignItems="flex-end">                        
                                                        <Grid item xs>
                                                            <AutoCompleteSelect 
                                                                options={initialList} 
                                                                label="Initial"
                                                                onChange={v => this.handleSelectChange('leaseProfileInitial', v)}
                                                                error={formErrors && formErrors['leaseProfileInitial'] && true}
                                                                errorText={formErrors && formErrors['leaseProfileInitial']}
                                                                value={formData?.leaseProfileInitial}
                                                                fullWidth
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                                noClear
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <AllIcon icon="plus" size={15} />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <AutoCompleteSelect 
                                                                options={monthlyList} 
                                                                label="Monthly"
                                                                onChange={v => this.handleSelectChange('leaseProfileMonthly', v)}
                                                                error={formErrors && formErrors['leaseProfileMonthly'] && true}
                                                                errorText={formErrors && formErrors['leaseProfileMonthly']}
                                                                value={formData?.leaseProfileMonthly}
                                                                fullWidth
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                                noClear
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" gutterBottom>
                                                        <b>Allowances</b>        
                                                    </Typography>
                                                    <Grid container item spacing={3}>                        
                                                        <Grid item xs={12} md>
                                                            <TextField  
                                                                name="leaseAnnualMileageAllowance"
                                                                label="Annual Mileage Allowance *"
                                                                margin="none"
                                                                value={formData.leaseAnnualMileageAllowance || ''}
                                                                onChange={this.handleChange}
                                                                fullWidth
                                                                error={!!formErrors && formErrors['leaseAnnualMileageAllowance'] && true}
                                                                helperText={formErrors && formErrors['leaseAnnualMileageAllowance']}
                                                                type="number"
                                                            />
                                                        </Grid>
                                                        <Grid item  xs={12} md>
                                                            <TextField  
                                                                name="leaseContractMileageAllowance"
                                                                label="Contract Mileage Allowance *"
                                                                margin="none"
                                                                value={formData.leaseContractMileageAllowance || ''}
                                                                onChange={this.handleChange}
                                                                fullWidth
                                                                error={!!formErrors && formErrors['leaseContractMileageAllowance'] && true}
                                                                helperText={formErrors && formErrors['leaseContractMileageAllowance']}
                                                                type="number"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container item spacing={3}>                        
                                                        <Grid item  xs={12} md>
                                                            <TextField  
                                                                name="leaseExcessMileageCharge"
                                                                label="Excess Mileage Charge (pence per mile) *"
                                                                margin="none"
                                                                value={formData.leaseExcessMileageCharge || ''}
                                                                onChange={this.handleChange}
                                                                fullWidth
                                                                InputProps={{
                                                                    endAdornment: (<InputAdornment position="end">p</InputAdornment>)
                                                                }}
                                                                error={!!formErrors && formErrors['leaseExcessMileageCharge'] && true}
                                                                helperText={formErrors && formErrors['leaseExcessMileageCharge']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                </>
                                            : null)
                                        }
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    <b>Devices</b>
                                </Typography>
                                <PaddedPaper>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12}>
                                            <AutoCompleteMultiSelect 
                                                options={devList} 
                                                label={"Devices "}
                                                onChange={v => this.handleMultiSelectChange('devices', v)}
                                                error={formErrors && formErrors['devices'] && true}
                                                errorText={formErrors && formErrors['devices']}
                                                value={formData?.devices}
                                                fullWidth
                                                inputProps={{
                                                    className:"textDefault"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    <b>Mileage Tracker</b>
                                </Typography>
                                <PaddedPaper>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                name="mileageTrackerStatus"
                                                onChange={this.handleChange}
                                                row
                                            >
                                                <FormControlLabel
                                                    value={1}
                                                    control={<Radio color="primary" />}
                                                    label="On"
                                                    labelPlacement="end"
                                                    checked={formData?.mileageTrackerStatus == 1 ? true : false}
                                                />
                                                <FormControlLabel
                                                    value={0}
                                                    control={<Radio color="primary" />}
                                                    label="Off"
                                                    labelPlacement="end"
                                                    checked={formData?.mileageTrackerStatus == 0 ? true : false}
                                                />
                                            </RadioGroup>
                                            <Typography variant="body2">
                                                {formData?.mileageTrackerStatus == 0 ?
                                                    'The system will not autogenerate mileage tracker entries - you will need to manually add them.'
                                                : 
                                                    'The system will autogenerate mileage tracker entries ready for the engineer to submit every Monday.'
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12}>
                                <div className='buttonRow'>
                                    {vehicleId ?
                                        <Button 
                                            variant='outlined' 
                                            onClick={()=>{this.props.history.push(`/vehicles/view/${vehicleId}`);}}
                                        >
                                            Back
                                        </Button>
                                    : null }
                                    <Button 
                                        onClick={() => this.props.deployConfirmation(`Are you sure you want to ${vehicleId ? 'update' : 'add'} this vehicle?`, `${vehicleId ? 'Update' : 'Add'} Vehicle?`, () => this.handleSubmit())}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {vehicleId ? 'Update' : 'Add'}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}


const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
    }
)

export default connect(null, mapDispatchToProps)(VehicleForm);