import React, { Component } from 'react';
import { connect }          from 'react-redux';
import _                    from 'lodash';
import moment               from 'moment';
import API                  from 'API';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { pdfFromBase64 }          from 'Helpers/PDFHelper';

import PaddedPaper            from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect     from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog     from 'Components/Common/Dialogs/ConfirmationDialog';
import { FullScreenDialog }   from 'Components/Common/Dialogs/FullScreenDialog';
import SnackBar               from 'Components/Common/SnackBars/SnackBar';
import ViewPart               from 'Components/Parts/ViewPart/ViewPart';
import AddPartEntryRow        from 'Components/Sales/AddPartEntryRow';
import AddPackageRow          from 'Components/Sales/AddPackageRow';
import DeliveryDetails        from 'Components/Sales/CustomerDeliveryDetails';
import CustomerDetails        from 'Components/Sales/CustomerDetails';
import QuoteDetails           from 'Components/Sales/Details';
import OrderPartDetailsButton from 'Components/Sales/OrderPartDetailsButton';
import QuotePartItems         from 'Components/Sales/PartItems';
import QuoteTotals            from 'Components/Sales/Totals';
import WarrantyDialog         from 'Components/Sales/WarrantyDialog';
import EmailDialog            from 'Components/Common/Dialogs/EmailDialog';
import LoadingCircle          from 'Components/Common/LoadingCircle/LoadingCircle';
import DataTable              from 'Components/Common/DataTables/CiDataTable';
import AddPromotionDetails    from 'Components/Sales/AddPromotionDetails';

import CustomerAddressSearch from './../../CustomerAddressSearch';
import LinkedPartsDialog     from './LinkedPartsDialog';

import { Button, Dialog, FormControl, DialogContent, DialogTitle, Grid, Typography, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import RepeatQuoteWarningDialog from './RepeatQuoteWarningDialog';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';

const initialState = {
    custId: null,
    siteId: null,
    repeatId: null,
    formData:  {
        repeatId: null,
        quotationType: '', 
        customer: '',
        // Customer
        company: '',
        addressId: '',
        address: '',
        paymentTermId: '',
        paymentTerms: '',
        customerContact: '',
        customerPhone: '',
        customerMobile: '',
        customerEmail: '',
        // Delivery Customer
        deliveryCustomerAddressType: '',
        deliveryCustomerAddress: '',
        deliveryCustomerContact: '',
        deliveryCustomerPhone: '',
        deliveryCustomerMobile: '',
        deliveryCustomerEmail: '',
        // Part Rows
        partRows: [],
        subTotal: '',
        vat: '',
        overallPrice: '',
        customerVatRateId: '',
        customerVatRate: '',
        customerVatDecimal: '',
        customerVatPercentage: '',
        customerDiscountRate: '',
        // Quote Details
        quoteDate: moment().toString(),
        notes: '',
        previewMode: false,
        salesPackages: [],
        promotions: [],
    },
    selectedPartRow: {
        partImage: '',
        part: '',
        partDiscount: 0,
        quantity: 0,
        unitPrice: 0,
        unitDiscount: 0,
        newUnitPrice: 0,
        subTotal: 0,
        discountRate: '0.00',
        discountTotal: 0,
        totalPrice: 0,
        warrantyId: '',
        warrantyName: '',
        warrantyDuration: '',
        notes: ''
    },
    dialogRowData: {
        partImage: '',
        part: '',
        partDiscount: 0,
        quantity: 0,
        unitPrice: 0,
        unitDiscount: 0,
        newUnitPrice: 0,
        subTotal: 0,
        discountRate: '0.00',
        discountTotal: 0,
        totalPrice: 0,
        warrantyId: '',
        warrantyName: '',
        warrantyDuration: '',
        notes: ''
    },
    selectedWarrantyTerm: {
        id: '',
        name: '',
        duration: ''
    },
    lists: {
        customerAddressList: [],
        customerContactList: [],
    },
    customer: {},
    customerContacts: {},
    deliveryCustomerContacts: {},
    customerAddresses: [],
    customerContactList: [],
    customerList: [],
    partList: [],
    allPartsData: [],
    showForm: false,
    warrantyTerms: [],
    formErrors: [],
    warrantyUpdate: false,
    warrantyDialogOpen: false,
    warrantyDialogClose: false,
    viewPartDialogOpen: false,
    updatePartDialogOpen: false,
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: false,
    emailDialogQuoteId: '',
    emailDialogCustomerId: '',
    emailDialogContactId: '',
    old_cust_id: 0,
}

class AddQuotation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            custId: this.props.custId ? this.props.custId : this.props.match.params.custid,
            siteId: this.props.siteId ? this.props.siteId : this.props.match.params.siteid,
            repeatId: this.props.repeatid ? this.props.repeatid : this.props.match.params.repeatid,
            rogId: this.props.rogId ? this.props.rogId : this.props.match.params.rogId,
        };
        this.updatePartSelectQuantity = React.createRef();
        this.partSelectQuantity = React.createRef();
    }

    componentDidMount(){
        this.populateDropdowns();
        this.checkPreFill();
    }

    checkPreFill = () => {
        this.setState({subLoading:true}, ()=>{
            if (this.state.rogId){
                this.getRepairOrder();
            } else if (this.state.repeatId) {
                this.getRepeatQuotation();
            } else {
                if (this.state.siteId){
                    this.checkSitePreFill()
                } else {
                    this.checkCustomerPreFill();
                }
            }
        });
    }

    getRepairOrderCustomerReturn = (repair) => {
        let cr_id = repair.customer_return_outcome.cro_cr_id;
        API.get(`/customerReturns/returns/${cr_id}`)
        .then(res => {
            let cr = res.data;
            API.get(`/customers/${cr.cr_cust_id}`) 
            .then(custRes => {
                const customerDiscountRate = custRes.data.discount_name && custRes.data.discount_name.default_discount_value.cdv_percentage;
                const orderDiscountRate = parseFloat(cr?.discount_value?.cdv_percentage) || 0;

                let partRows = [];
                let costChange = false;
                let stockChange = false;
                let discountChange = parseFloat(customerDiscountRate) !== parseFloat(orderDiscountRate);

                let registeredAddress = custRes.data.registered_address;
                let customerAddresses = [];
                customerAddresses = customerAddresses.concat(   custRes.data.account_addresses, 
                                                                custRes.data.trading_addresses,
                                                                custRes.data.site_addresses,
                                                                [custRes.data.registered_address]);

                let addresses = [];
                switch(cr.cr_collection_address_type){
                    case 'Registered':
                        addresses = [custRes.data.registered_address]
                    break;
                    case 'Accounts':
                        addresses = custRes.data.account_addresses
                    break; 
                    case 'Trading':
                        addresses = custRes.data.trading_addresses
                    break;
                    case 'Site':
                        addresses = custRes.data.site_addresses
                    break;
                    default:
                    break;
                }
                let customerAddressList = _.map(addresses, (el) => {
                    return _.assign({
                        value: el.address_id,
                        label: `${el.address_name} - ${el.address_line_one}, ${el.address_town}, ${el.address_postcode}`,
                        address: el
                    });
                });
                let address = _.find(customerAddresses, { 'address_id': cr.cr_collection_address_id });
                let customerContactList = [..._.map(registeredAddress.active_contacts, (el) => {
                    return _.assign({
                        value: el.contact_id,
                        label: el.contact_name
                    });
                }),..._.map(address?.active_contacts, (el) => {
                    return _.assign({
                        value: el.contact_id,
                        label: el.contact_name
                    });
                }) ];

                this.setState({
                    lists: {
                        ...this.state.lists,
                        customerAddressList: customerAddressList,
                        customerContactList: customerContactList,
                    },
                    customerAddresses: customerAddresses,
                    customerContactList: customerContactList,
                    customerContacts: registeredAddress.active_contacts,
                    deliveryCustomerContacts: address?.active_contacts,
                    copyOrder: {
                        costChange, stockChange, discountChange,

                        repairOrder:                    repair.rog_id,

                        quotationType:                  cr.cr_has_discount ? 'With Discount' : 'Without Discount',
                        customer:                        custRes.data.cust_id,

                        // Customer
                        company:                        custRes.data.cust_name,
                        customerContact:                cr.cr_contact_id,
                        customerPhone:                  cr.cr_contact_phone,
                        customerMobile:                 cr.cr_contact_mobile,
                        customerEmail:                  cr.cr_contact_email,

                        addressId: registeredAddress.address_id,
                        address: registeredAddress.address_line_one + "\n\r" +
                                (registeredAddress.address_line_two ? registeredAddress.address_line_two + "\n\r":'') +
                                (registeredAddress.address_line_three ? registeredAddress.address_line_three + "\n\r":'') +
                                registeredAddress.address_town + "\n\r" +
                                (registeredAddress.county && registeredAddress.county.county_name ? registeredAddress.county.county_name + "\n\r":'') +
                                (registeredAddress.country && registeredAddress.country.country_name ? registeredAddress.country.country_name + "\n\r":'') +
                                registeredAddress.address_postcode,

                        paymentTermId: custRes.data.payment_term && custRes.data.payment_term.payment_term_id,
                        paymentTerms: custRes.data.payment_term && custRes.data.payment_term.payment_term_name,
                        customerVatRateId: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_value_id,
                        customerVatRate: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_value_percentage,
                        customerVatDecimal: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_rate_decimal,
                        customerVatPercentage: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_rate_percent,
                        customerDiscountRate: customerDiscountRate,
                        orderDiscountRate: orderDiscountRate,

                        customerDiscountRateId:         custRes?.data?.discount_name?.cdn_id || 0,  

                        // Delivery Customer
                        deliveryCustomerAddressType:    cr.cr_collection_address_type,
                        deliveryCustomerAddress:        cr.cr_collection_address_id,
                        deliveryCustomerContact:        cr.cr_collection_address_contact_id,
                        deliveryCustomerPhone:          cr.cr_collection_address_contact_phone,
                        deliveryCustomerMobile:         cr.cr_collection_address_contact_mobile,
                        deliveryCustomerEmail:          cr.cr_collection_address_contact_email,

                        // Part Rows
                        partRows:                       partRows,

                        subTotal:                       '',
                        vat:                            '',         
                        overallPrice:                   '',

                        // Order Details
                        orderDate:                      moment().format('YYYY-MM-DD'),
                        notes:                          '',
                        despatchDate: null,
                        deliveryDate: null,
                    },
                },  () => {
                    this.completeRepairOrder(repair);
                });
            })
        })
    }

    completeRepairOrder = (repair) => {
        let _partRows = []

        let discountRate = this.state.copyOrder.orderDiscountRate;

        _.each(repair.repair_orders, ro => {
            if (ro.estimated_parts.length > 0){
                _.each(ro.estimated_parts, row => {
                    if (row.part){
                        /* Work out decimal */
                        let discountDecimal = parseFloat(discountRate) / 100;
                        let pricePer = parseFloat(row?.part?.default_price?.price_total ?? 0)

                        /* Discount amount for 1 unit */
                        let unitDiscount = (parseFloat(pricePer) * discountDecimal).toFixed(2);
                        let newUnitPrice = (parseFloat(pricePer) - unitDiscount).toFixed(2);

                        /* Subtotal = before discount */
                        let subTotal = (parseFloat(parseFloat(pricePer)) * parseFloat(row.roep_qty)).toFixed(2);
                        let discountTotal = (parseFloat(unitDiscount) * parseFloat(row.roep_qty)).toFixed(2);

                        /* totalPrice = after discount */
                        let totalPrice = (parseFloat(newUnitPrice) * parseFloat(row.roep_qty)).toFixed(2);

                        _partRows.push(
                            {
                                partImage:        row.part && row.part.default_image && row.part.default_image.library_thumbnail_url && row.part.default_image.thumbnail_file_path,
                                partDiscount:     row.part && row.part.part_discount,
                                part:             row.part.part_id,
                                partNumber:       row.part.part_number,
                                partDescription:  row.part.part_description,
                                quantity:         parseFloat(row.roep_qty),
                                unitPrice:        parseFloat(pricePer),
                                unitDiscount:     parseFloat(unitDiscount),
                                newUnitPrice:     parseFloat(newUnitPrice),
                                subTotal:         parseFloat(subTotal),
                                discountRate:     parseFloat(discountRate),
                                discountTotal:    parseFloat(discountTotal),
                                totalPrice:       parseFloat(totalPrice),
                                warrantyId:       parseInt(row.part.part_warranty_terms),
                                warrantyName:     row.part.warranty_terms.pwt_name,
                                warrantyDuration: row.part.warranty_terms.pwt_duration,
                                notes:            '',
                                newCost:          parseFloat(pricePer),
                                
                                stockAvailable:   parseFloat(row.part.stock.stock_available),
                                useOriginal:      true,
                                discountDecimal,
                            }
                        )
                    }
                })
            }
        });

        let partRows = [];
        _.each(_.uniqBy(_partRows, 'part'), row => {
            let _parts = _.filter(_partRows, {part: row.part});
            partRows.push({
                ...row,
                quantity: parseFloat(_.sumBy(_parts, i => parseFloat(i.quantity))),
                subTotal: parseFloat(_.sumBy(_parts, i => parseFloat(i.subTotal))),
                discountTotal: parseFloat(_.sumBy(_parts, i => parseFloat(i.discountTotal))),
                totalPrice: parseFloat(_.sumBy(_parts, i => parseFloat(i.totalPrice))),
                checkStock: ( parseFloat(_.sumBy(_parts, i => parseFloat(i.quantity))) > _.sumBy(_parts, i => parseFloat(i.stockAvailable))) ,
                checkPrice: false,
            });
        });

        this.setState({
            formData: { 
                ...initialState.formData,
                ...this.state.copyOrder,
                rogId: this.state.rogId,
                partRows,
                subTotal:                       parseFloat(_.sumBy(partRows, 'totalPrice')),
                vat:                            parseFloat(parseFloat(_.sumBy(partRows, 'totalPrice')) * parseFloat(this.state.copyOrder.customerVatDecimal)),         
                overallPrice:                   parseFloat(_.sumBy(partRows, 'totalPrice')) + parseFloat(parseFloat(_.sumBy(partRows, 'totalPrice')) * parseFloat(this.state.copyOrder.customerVatDecimal)),
            },
            showForm: true
        },this.calculateOverallTotals);
    }

    getRepairOrder = () => {
        API.get(`/customerReturns/repairOrders/${this.state.rogId}`)
        .then(res => {
            if (res.data.rog_cro_id > 0){
                this.getRepairOrderCustomerReturn(res.data)
            }
        })
    }

    getRepeatQuotation = () => {
        API.get(`/sales/quotations/${this.state.repeatId}`)
        .then(res => {
            let quote = res.data;
            API.get(`/customers/${quote.quote_cust_id}`) 
            .then(custRes => {
                const customerDiscountRate = custRes.data.discount_name && custRes.data.discount_name.default_discount_value.cdv_percentage;
                const orderDiscountRate = parseFloat(quote?.discount_value?.cdv_percentage) || 0;

                let partRows = [];
                let costChange = false;
                let stockChange = false;
                let discountChange = parseFloat(customerDiscountRate) !== parseFloat(orderDiscountRate);

                _.each(quote.quote_details, row => {
                    let discountRate = (quote.quote_type === 'With Discount' && row.part && customerDiscountRate) ? row.qd_discount_rate : 0;
                    
                    /* Work out decimal */
                    let discountDecimal = discountRate / 100;

                    /* Discount amount for 1 unit */
                    let unitDiscount = (row.qd_unit_price * discountDecimal).toFixed(2);
                    let newUnitPrice = (row.qd_unit_price - unitDiscount).toFixed(2);

                    /* Subtotal = before discount */
                    let subTotal = (parseFloat(row.qd_unit_price) * parseFloat(row.qd_quantity)).toFixed(2);
                    let discountTotal = (parseFloat(unitDiscount) * parseFloat(row.qd_quantity)).toFixed(2);

                    /* totalPrice = after discount */
                    let totalPrice = (parseFloat(newUnitPrice) * parseFloat(row.qd_quantity)).toFixed(2);

                    if (parseFloat(row.qd_unit_price) !== parseFloat(row.part.default_price.price_total)) {
                        costChange = true;
                    }

                    if ( row.part.stockable && (parseFloat(row.qd_quantity) > parseFloat(row.part.stock.stock_available)) ) {
                        stockChange = true;
                    }

                    partRows.push({
                        partImage:        row.part && row.part.default_image && row.part.default_image.library_thumbnail_url && row.part.default_image.thumbnail_file_path,
                        partDiscount:     row.part && row.part.part_discount,
                        part:             row.part.part_id,
                        partNumber:       row.part.part_number,
                        partDescription:  row.part.part_description,
                        quantity:         parseFloat(row.qd_quantity),
                        unitPrice:        parseFloat(row.qd_unit_price).toFixed(2),
                        unitDiscount:     parseFloat(unitDiscount).toFixed(2),
                        newUnitPrice:     parseFloat(newUnitPrice).toFixed(2),
                        subTotal:         parseFloat(subTotal).toFixed(2),
                        discountRate:     parseFloat(discountRate).toFixed(2),
                        discountTotal:    parseFloat(discountTotal).toFixed(2),
                        totalPrice:       parseFloat(totalPrice).toFixed(2),
                        warrantyId:       parseInt(row.qd_warranty_id),
                        warrantyName:     row.qd_warranty_name,
                        warrantyDuration: row.qd_warranty_duration,
                        notes:            row.qd_notes,
                        newCost:          parseFloat(row.part.default_price.price_total),
                        checkPrice:       ( parseFloat(row.qd_unit_price) !== parseFloat(row.part.default_price.price_total) ),    
                        checkStock:       ( parseFloat(row.qd_quantity) > parseFloat(row.part.stock.stock_available) ),
                        stockAvailable:   parseFloat(row.part.stock.stock_available),
                        useOriginal:      true,
                        discountDecimal,
                    });
                });

                let registeredAddress = custRes.data.registered_address;
                let customerAddresses = [];
                customerAddresses = customerAddresses.concat(   custRes.data.account_addresses, 
                                                                custRes.data.trading_addresses,
                                                                custRes.data.site_addresses,
                                                                [custRes.data.registered_address]);
                
                let addresses = [];
                switch(quote.quote_delivery_cust_address_type){
                    case 'Registered':
                        addresses = [custRes.data.registered_address]
                    break;
                    case 'Accounts':
                        addresses = custRes.data.account_addresses
                    break; 
                    case 'Trading':
                        addresses = custRes.data.trading_addresses
                    break;
                    case 'Site':
                        addresses = custRes.data.site_addresses
                    break;
                    default:
                    break;
                }
                let customerAddressList = _.map(addresses, (el) => {
                    return _.assign({
                        value: el.address_id,
                        label: `${el.address_name} - ${el.address_line_one}, ${el.address_town}, ${el.address_postcode}`,
                        address: el
                    });
                });

                let address = _.find(customerAddresses, { 'address_id': quote.quote_delivery_cust_address_id });
                let customerContactList = [..._.map(registeredAddress.active_contacts, (el) => {
                    return _.assign({
                        value: el.contact_id,
                        label: el.contact_name
                    });
                }),..._.map(address?.active_contacts, (el) => {
                    return _.assign({
                        value: el.contact_id,
                        label: el.contact_name
                    });
                }) ];
                this.setState({
                    lists: {
                        ...this.state.lists,
                        customerAddressList: customerAddressList,
                        customerContactList: customerContactList,
                    },
                    customerAddresses: customerAddresses,
                    customerContactList: customerContactList,
                    customerContacts: registeredAddress.active_contacts,
                    deliveryCustomerContacts: address?.active_contacts,
                    customer: custRes.data,
                    copyOrder: {
                        costChange, stockChange, discountChange,

                        quotationType:                  quote.quote_type,
                        customer:                       quote.quote_cust_id,
                        copyOrder:                      quote.quote_id,

                        // Customer
                        company:                        custRes.data.cust_name,
                        customerContact:                quote.quote_cust_contact_id,
                        customerPhone:                  quote.quote_cust_contact_phone,
                        customerMobile:                 quote.quote_cust_contact_mobile,
                        customerEmail:                  quote.quote_cust_contact_email,

                        addressId: registeredAddress.address_id,
                        address: registeredAddress.address_line_one + "\n\r" +
                                (registeredAddress.address_line_two ? registeredAddress.address_line_two + "\n\r":'') +
                                (registeredAddress.address_line_three ? registeredAddress.address_line_three + "\n\r":'') +
                                registeredAddress.address_town + "\n\r" +
                                (registeredAddress.county && registeredAddress.county.county_name ? registeredAddress.county.county_name + "\n\r":'') +
                                (registeredAddress.country && registeredAddress.country.country_name ? registeredAddress.country.country_name + "\n\r":'') +
                                registeredAddress.address_postcode,

                        paymentTermId: custRes.data.payment_term && custRes.data.payment_term.payment_term_id,
                        paymentTerms: custRes.data.payment_term && custRes.data.payment_term.payment_term_name,
                        customerVatRateId: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_value_id,
                        customerVatRate: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_value_percentage,
                        customerVatDecimal: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_rate_decimal,
                        customerVatPercentage: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_rate_percent,
                        customerDiscountRate: customerDiscountRate,
                        orderDiscountRate: orderDiscountRate,

                        customerDiscountRateId:         custRes?.data?.discount_name?.cdn_id || 0,  

                        // Delivery Customer
                        deliveryCustomerAddressType:    quote.quote_delivery_cust_address_type,
                        deliveryCustomerAddress:        quote.quote_delivery_cust_address_id,
                        deliveryCustomerContact:        quote.quote_delivery_cust_contact_id,
                        deliveryCustomerPhone:          quote.quote_delivery_cust_contact_phone,
                        deliveryCustomerMobile:         quote.quote_delivery_cust_contact_mobile,
                        deliveryCustomerEmail:          quote.quote_delivery_cust_contact_email,

                        // Part Rows
                        partRows:                       partRows,

                        subTotal:                       '',
                        vat:                            '',         
                        overallPrice:                   '',

                        // Order Details
                        orderDate:                      moment().format('YYYY-MM-DD'),
                        notes:                          '',
                        despatchDate: null,
                        deliveryDate: null,
                    },
                },  () => {
                    if (this.state.copyOrder.costChange || this.state.copyOrder.stockChange || this.state.copyOrder.discountChange) {
                        this.setState({RepeatOrderWarningDialogOpen: true})
                    } else {
                        this.completeCopyOrder();
                    }
                });
            })
            
        })
    }

    handleCopyOrderChange = (part, field, value) => {
        let partRows = this.state.copyOrder.partRows;
        partRows[_.findIndex(partRows, {part})][field] = value;
        this.setState({
            copyOrder: {
                ...this.state.copyOrder,
                partRows: partRows
            }
        });
    }

    completeCopyOrder = () => {

        let partRows = _.map(this.state.copyOrder.partRows, row => {
            if (row.useOriginal) return row;
            return {
                ...row,
                unitPrice:        parseFloat(row.newCost),
                unitDiscount:     parseFloat(parseFloat(row.newCost) * parseFloat(row.discountDecimal)),
                newUnitPrice:     parseFloat(parseFloat(row.newCost) - parseFloat(parseFloat(row.newCost) * parseFloat(row.discountDecimal))),
                subTotal:         parseFloat(parseFloat(row.newCost) * parseFloat(row.quantity)),
                discountTotal:    parseFloat(parseFloat(parseFloat(row.newCost) * parseFloat(row.discountDecimal)) * parseFloat(row.quantity)),
                totalPrice:       parseFloat(parseFloat(parseFloat(row.newCost) * parseFloat(row.quantity)) - parseFloat(parseFloat(parseFloat(row.newCost) * parseFloat(row.discountDecimal)) * parseFloat(row.quantity))),
            }
        });

        this.setState({
            RepeatOrderWarningDialogOpen: false,
            formData: { 
                ...initialState.formData,
                ...this.state.copyOrder,
                repeatId: this.state.repeatId,
                partRows,
                subTotal:                       parseFloat(_.sumBy(partRows, 'totalPrice')),
                vat:                            parseFloat(parseFloat(_.sumBy(partRows, 'totalPrice')) * parseFloat(this.state.copyOrder.customerVatDecimal)),         
                overallPrice:                   parseFloat(_.sumBy(partRows, 'totalPrice')) + parseFloat(parseFloat(_.sumBy(partRows, 'totalPrice')) * parseFloat(this.state.copyOrder.customerVatDecimal)),
            },
            showForm: true
        },this.calculateOverallTotals);
    };

    checkSitePreFill = () => {
        if (this.state.siteId){
            API.get(`/customers/addresses/${this.state.siteId}`)
                .then((result) => {
                    if(result.data && !result.data.error){
                        this.setState({
                            ...this.state,
                            custId: result.data.address_customer_id,
                            formData: {
                                ...this.state.formData, 
                                deliveryCustomerAddressType: result.data.address_type
                            }
                        })
                        this.checkCustomerPreFill();
                    }
                });
        }
    }

    checkCustomerPreFill = () => {
        if (this.state.custId){
            this.setState({isLoading: true});
            API.get('/customers/' + this.state.custId)
                .then(result => {
                    if(result.data && !result.data.errors) {
                        var dis = (result.data?.discount_name?.cdn_id && result.data?.discount_name?.cdn_id != 11) ?
                            'With Discount' : 'Without Discount';
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                quotationType: dis,
                                customer: result.data.cust_id
                            }
                        }, this.displayImportantInfo )
                        this.handleShowQuote('customer');
                    } 
                })
            this.setState({isLoading: false});
        }
    }

    populateDropdowns = () => {
        Promise.all([
            API.get('/customers'),
            API.get('/parts/all' , {params: {active: true, hasPrice: true, hasWarranty: true, use: 'quotation', withStock: true}}),
            API.get('/parts/warrantyTerms')
        ])
        .then(([custRes, partsRes, warrantyRes]) =>  {
            let customerList = [], 
                partList = [], 
                warrantyTerms = [];

            if(custRes.data){
                customerList = _.map(custRes.data, (el) => {
                    el.registeredPostcode = _.result(_.find(el?.addresses, {'address_type': 'Registered'}), 'address_postcode');
                    return _.assign({
                        value: el.cust_id,
                        label: el.account_status.cas_allow_quotes === 0 ? el.cust_name + ' | ' + el.cust_type + ' ('+el?.registeredPostcode+') ' + ' (' + el.account_status.cas_name + ')' : el.cust_name + ' | ' + el.cust_type + ' ('+el?.registeredPostcode+')' + ` | CS${el.cust_id}`,
                        disabled: el.account_status.cas_allow_quotes === 0 ? true : false
                    });
                });
            }
            if(partsRes.data){
                partList = _.map(partsRes.data, (el) => {
                    return _.assign({
                        value: el.part_id,
                        label: el.part_number + ' - ' + el.part_description
                    });
                });
            }
            if(warrantyRes.data) {
                warrantyTerms = _.map(warrantyRes.data, (el) => {
                    return _.assign({
                        value: el.pwt_id,
                        label: el.pwt_duration !== null ? el.pwt_name + ' (' + el.pwt_duration + ' months)' : el.pwt_name,
                        name: el.pwt_name,
                        duration: el.pwt_duration
                    });
                });
            }
            this.setState({
                customerList: customerList,
                partList: partList,
                allPartsData: partsRes.data,
                warrantyTerms
            });
        });
    }

    handleShowQuote = (fieldName) => {

        if (this.state.formData.quotationType && this.state.formData.customer > 0) {

            this.setState({
                showForm: true,
                formErrors: [],
            },()=>{
                API.get(`/customers/${this.state.formData.customer}`)
                .then(custRes => {
                    let registeredAddress = custRes.data.registered_address;
                    let customerContactList = _.map(_.filter(registeredAddress.active_contacts, i =>i.contact_email), (el) => {
                        return _.assign({
                            value: el.contact_id,
                            label: el.contact_name
                        });
                    });

                    let customerAddresses = [];
                    customerAddresses = customerAddresses.concat(   custRes.data.account_addresses, 
                                                                    custRes.data.trading_addresses,
                                                                    custRes.data.site_addresses,
                                                                    [custRes.data.registered_address]);
                    this.setState({
                        formData:{
                            ...this.state.formData,
                            company: custRes.data.cust_name,
                            addressId: registeredAddress.address_id,
                            address: registeredAddress.address_line_one + "\n\r" +
                                    (registeredAddress.address_line_two ? registeredAddress.address_line_two + "\n\r":'') +
                                    (registeredAddress.address_line_three ? registeredAddress.address_line_three + "\n\r":'') +
                                    registeredAddress.address_town + "\n\r" +
                                    (registeredAddress.county && registeredAddress.county.county_name ? registeredAddress.county.county_name + "\n\r":'') +
                                    registeredAddress.address_postcode + "\n\r" +
                                    (registeredAddress.country && registeredAddress.country.country_name ? registeredAddress.country.country_name : ''),
                            paymentTermId: custRes.data.payment_term && custRes.data.payment_term.payment_term_id,        
                            paymentTerms: custRes.data.payment_term && custRes.data.payment_term.payment_term_name,
                            customerVatRateId: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_value_id,
                            customerVatRate: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_value_percentage,
                            customerVatDecimal: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_rate_decimal,
                            customerVatPercentage: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_rate_percent,
                            customerDiscountRateId: custRes.data.discount_name && custRes.data.discount_name.default_discount_value.cdv_id,
                            customerDiscountRate: custRes.data.discount_name && custRes.data.discount_name.default_discount_value.cdv_percentage,
                            // Only clear fields if customer
                            customerContact: fieldName === 'quotationType' ? this.state.formData.customerContact : '',
                            customerPhone: fieldName === 'quotationType' ? this.state.formData.customerPhone : '',
                            customerMobile: fieldName === 'quotationType' ? this.state.formData.customerMobile : '',
                            customerEmail: fieldName === 'quotationType' ? this.state.formData.customerEmail : '',
                            deliveryCustomerAddressType: fieldName === 'quotationType' ? this.state.formData.deliveryCustomerAddressType : '',
                            deliveryCustomerAddress: fieldName === 'quotationType' ? this.state.formData.deliveryCustomerAddress : '',
                            deliveryCustomerContact: fieldName === 'quotationType' ? this.state.formData.deliveryCustomerContact : '',
                            deliveryCustomerPhone: fieldName === 'quotationType' ? this.state.formData.deliveryCustomerPhone : '',
                            deliveryCustomerMobile: fieldName === 'quotationType' ? this.state.formData.deliveryCustomerMobile : '',
                            deliveryCustomerEmail: fieldName === 'quotationType' ? this.state.formData.deliveryCustomerEmail : '',
                        },
                        customer: custRes.data,
                        customerAddresses: customerAddresses,
                        customerContactList: customerContactList,
                        customerContacts: registeredAddress.active_contacts
                    });
                }).then(() => {
                    this.displayImportantInfo();
                    if (this.state.siteId){
                        this.setState({subLoading:true});
                        //set data if we already have the site info passed
                        this.hijackSelectChange('deliveryCustomerAddressType', { value: "Site" } );
                        this.hijackSelectChange('deliveryCustomerAddress', { value: parseInt(this.state.siteId) } );
                        this.setState({subLoading:false});
                    }
                });
            });
        }
    }

    handleSelectChange = fieldName => selectedOption => { this.hijackSelectChange(fieldName, selectedOption); }

    hijackSelectChange = (fieldName, selectedOption) => {
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if((fieldName === 'quotationType' || fieldName === 'customer')){
               this.handleShowQuote(fieldName);
            }
            else if(fieldName === 'quotationType' || fieldName === 'customer'){ // empty customer or type
                this.setState({
                    showForm: false
                });
            }
            if(fieldName === 'deliveryCustomerAddressType' && this.state.formData.deliveryCustomerAddressType){
                let addresses = [];
                switch(this.state.formData.deliveryCustomerAddressType){
                    case 'Registered':
                        addresses = [this.state.customer.registered_address]
                    break;
                    case 'Accounts':
                        addresses = this.state.customer.account_addresses
                    break;
                    case 'Trading':
                        addresses = this.state.customer.trading_addresses
                    break;
                    case 'Site':
                        addresses = this.state.customer.site_addresses
                    break;
                    default:
                    break;
                }
                let customerAddressList = _.map(_.filter(addresses,{address_active: 1}), (el) => {
                    return _.assign({
                        value:   el.address_id,
                        label:   `${el.address_name} - ${el.address_line_one}, ${el.address_town}, ${el.address_postcode}`,
                        address: el
                    });
                });

                this.setState({
                    lists: {
                        ...this.state.lists,
                       customerAddressList: customerAddressList, 
                    },
                    formData:{
                        ...this.state.formData,
                        deliveryCustomerAddress: '',
                        deliveryCustomerContact: ''
                    }
                });
            }
            if(fieldName === 'deliveryCustomerAddress' && this.state.formData.deliveryCustomerAddress){
                let address = _.find(this.state.customerAddresses, { 'address_id': selectedOption && selectedOption.value });
                let customerContactList = _.map(_.filter(address.active_contacts, i =>i.contact_email), (el) => {
                    return _.assign({
                        value: el.contact_id,
                        label: el.contact_name
                    });
                });
                this.setState({
                    lists: {
                        ...this.state.lists,
                        customerContactList: customerContactList,
                    },
                    formData:{
                        ...this.state.formData,
                        deliveryCustomerContact: '',
                    },
                    deliveryCustomerContacts: address.active_contacts
                });
            }
            if(fieldName === 'customerContact' && this.state.formData.customerContact){
                let contact = _.find(this.state.customerContacts, { 'contact_id': this.state.formData.customerContact });
                this.setState({
                    formData:{
                        ...this.state.formData,
                        customerPhone: contact.contact_telephone ? contact.contact_telephone : '',
                        customerMobile: contact.contact_mobile ? contact.contact_mobile : '',
                        customerEmail: contact.contact_email ? contact.contact_email : '-',
                    }
                });
            }
            if(fieldName === 'deliveryCustomerContact' && this.state.formData.deliveryCustomerContact){
                let contact = _.find(this.state.deliveryCustomerContacts, { 'contact_id': this.state.formData.deliveryCustomerContact });
                this.setState({
                    formData:{
                        ...this.state.formData,
                        deliveryCustomerPhone: contact.contact_telephone ? contact.contact_telephone : '',
                        deliveryCustomerMobile: contact.contact_mobile ? contact.contact_mobile : '',
                        deliveryCustomerEmail: contact.contact_email ? contact.contact_email : '-',
                    }
                });
            }
        });

    }

    getPart = partId => {
        let part = _.find(this.state.allPartsData, { 'part_id': partId });

        if(part) {
            let unitPrice = part.price;
            let discountRate = (this.state.formData.quotationType === 'With Discount' && part.part_discount === 'Yes' && this.state.formData.customerDiscountRate) ? this.state.formData.customerDiscountRate : '0.00';
            
            let selectedPartRow = this.state.selectedPartRow;
            let selectedWarrantyTerm = this.state.selectedWarrantyTerm;

            selectedPartRow = {
                ...selectedPartRow,
                discountRate,
                partImage: part.thumbnail_url,
                part: part.part_id,
                partNumber: part.part_number,
                partDescription: part.part_description,
                partDiscount: part.part_discount,
                quantity: 1,
                unitPrice,
                warrantyId: part.pwt_id,
                warrantyName: part.pwt_name,
                warrantyDuration: part.pwt_duration,
                stock: part.stock,
                stockable: part.stockable
            }

            selectedWarrantyTerm = {
                id: part.pwt_id,
                name: part.pwt_name,
                duration: part.pwt_duration
            }

            this.setState({
                ...this.state,
                selectedPartRow,
                selectedWarrantyTerm
            }, () => {
                this.calculatePartSelectTotals();
                this.partSelectQuantity.current.focus();
            });
        }
    }

    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handlePartSelect = e => {
        if(e && e.value) {
            let partId = e && e.value;
            this.getPart(partId);
        } else {
            this.setState({
                selectedPartRow: initialState.selectedPartRow
            });
        }
    }

    handlePartSelectRowChange = decimals => e => {
        const { name, value } = e.target;
        let selectedPartRow = this.state.selectedPartRow;
        let newVal = decimals ? parseFloat(value).toFixed(decimals) : value;

        selectedPartRow = {
            ...selectedPartRow,
            [name]: newVal
        };

        this.setState({selectedPartRow}, () => this.calculatePartSelectTotals());
    }

    handlePartSelectRowRemove = () => {
        this.setState({
            selectedPartRow: initialState.selectedPartRow
        });
    }

    calculatePartSelectTotals = () => {
        let selectedPartRow = this.state.selectedPartRow;

        /* Work out decimal */
        let discountDecimal = selectedPartRow.discountRate / 100;

        /* Discount amount for 1 unit */
        let unitDiscount = (selectedPartRow.unitPrice * discountDecimal).toFixed(2);
        let newUnitPrice = (selectedPartRow.unitPrice - unitDiscount).toFixed(2);

        /* Subtotal = before discount */
        let subTotal = selectedPartRow ? (selectedPartRow.unitPrice * selectedPartRow.quantity).toFixed(2) : 0;
        let discountTotal = (unitDiscount * selectedPartRow.quantity).toFixed(2);

        /* totalPrice = after discount */
        let totalPrice = selectedPartRow ? (newUnitPrice * selectedPartRow.quantity).toFixed(2) : 0;

        if(selectedPartRow) {
            selectedPartRow = {
                ...selectedPartRow,
                unitDiscount,
                newUnitPrice,
                subTotal,
                discountTotal,
                totalPrice
            }

            this.setState({selectedPartRow});
        }
    }

    calculateDialogTotals = () => {
        let dialogRowData = this.state.dialogRowData;
        /* Work out decimal */
        let discountDecimal = dialogRowData.discountRate / 100;

        /* Discount amount for 1 unit */
        let unitDiscount = (dialogRowData.unitPrice * discountDecimal).toFixed(2);
        let newUnitPrice = (dialogRowData.unitPrice - unitDiscount).toFixed(2);

        /* Subtotal = before discount */
        let subTotal = dialogRowData ? (dialogRowData.unitPrice * dialogRowData.quantity).toFixed(2) : 0;
        let discountTotal = (unitDiscount * dialogRowData.quantity).toFixed(2);

        /* totalPrice = after discount */
        let totalPrice = dialogRowData ? (newUnitPrice * dialogRowData.quantity).toFixed(2) : 0;

        if(dialogRowData) {
            dialogRowData = {
                ...dialogRowData,
                unitDiscount,
                newUnitPrice,
                subTotal,
                discountTotal,
                totalPrice
            }

            this.setState({dialogRowData});
        }
    }

    handlePartSelectAddRow = () => {
        let selectedPartRow = this.state.selectedPartRow;
        let part = _.find(this.state.allPartsData, {part_id: selectedPartRow.part});

        API.get(`/parts/${selectedPartRow.part}/pickingDocuments`).then(res => {
            if (part.linked_parts.length > 0 || res.data.length > 0) {
                this.displayLinkedParts(selectedPartRow, res.data);
            }

            if(selectedPartRow.part > 0) {
                let partRows = [...this.state.formData.partRows];
                partRows.push(selectedPartRow);

                this.setState({
                    selectedPartRow: initialState.selectedPartRow,
                    formData: {
                        ...this.state.formData,
                        partRows
                    }
                }, () => {
                    this.calculateOverallTotals();
                });
            }
        })
    }

    handleDialogPartSelect = e => {
        if(e && e.value) {
            let partId = e && e.value;
            let part = _.find(this.state.allPartsData, { 'part_id': partId });

            if(part) {
                let discountRate = (this.state.formData.quotationType === 'With Discount' && this.state.formData.customerDiscountRate) ? this.state.formData.customerDiscountRate : 0;
                discountRate = (part.part_discount === 'Yes') ? discountRate : 0;

                let dialogRowData = this.state.dialogRowData;

                dialogRowData = {
                    ...dialogRowData,
                    discountRate,
                    partImage: part.thumbnail_url,
                    part: part.part_id,
                    partNumber: part.part_number,
                    partDescription: part.part_description,
                    partDiscount: part.part_discount,
                    quantity: 1,
                    unitPrice: part.price,
                    warrantyId: part.pwt_id,
                    warrantyName: part.pwt_name,
                    warrantyDuration: part.pwt_duration
                }

                this.setState({dialogRowData}, () => {
                    this.calculateDialogTotals();

                    if(this.state.updatePartDialogOpen) {
                        this.updatePartSelectQuantity.current.focus();
                    } else {
                        this.partSelectQuantity.current.focus();
                    }
                });
            }
        } else {
            this.setState({
                dialogRowData: initialState.dialogRowData
            });
        }
    }

    handleDialogPartRowChange = decimals => e => {
        const { name, value } = e.target;
        let dialogRowData = this.state.dialogRowData;
        let newVal = decimals ? parseFloat(value).toFixed(decimals) : value;

        dialogRowData = {
            ...dialogRowData,
            [name]: newVal
        };

        this.setState({dialogRowData}, () => this.calculateDialogTotals());
    }

    calculateOverallTotals = () => {
        let overallSubTotal = 0;
        let vat = 0;
        let overallPrice = 0;
        this.state.formData.partRows.forEach((row) => {
            overallSubTotal = overallSubTotal + (row.totalPrice*1);
        });
        vat = (overallSubTotal * this.state.formData.customerVatDecimal);
        overallPrice = (overallSubTotal + vat);
        this.setState({
            formData: {
                ...this.state.formData,
                subTotal: overallSubTotal.toFixed(2),
                vat: vat.toFixed(2),
                overallPrice: overallPrice.toFixed(2)
            }
        });
    }

    handleViewPart = rowData => {
        this.setState({
            viewPartDialogOpen: true,
            dialogRowData: rowData
        });
    }

    handleUpdatePart = rowData => {
        let selectedWarrantyTerm = this.state.selectedWarrantyTerm;
        selectedWarrantyTerm = {
            id: rowData.warrantyId,
            name: rowData.warrantyName,
            duration: rowData.warrantyDuration
        };
        this.setState({
            updatePartDialogOpen: true,
            dialogRowData: rowData,
            selectedWarrantyTerm
        });
    }

    handleRemovePart = rowIdx => {
        let partRows = [...this.state.formData.partRows];

        partRows.splice(rowIdx, 1);
        
        this.setState({
            formData: {
                ...this.state.formData,
                partRows
            }
        }, () => this.calculateOverallTotals());
    }

    handleMove = (rowIdx, dir) => {
        let partRows = [...this.state.formData.partRows];
        let rowMoveUp, rowMoveDown;
        switch(dir) {
            case 'up':
                rowMoveUp = partRows[rowIdx];
                rowMoveDown = partRows[rowIdx - 1];
                partRows.splice(rowIdx, 1, rowMoveDown);
                partRows.splice(rowIdx - 1, 1, rowMoveUp);
            break;
            case 'down':
                rowMoveDown = partRows[rowIdx];
                rowMoveUp = partRows[rowIdx + 1];
                partRows.splice(rowIdx, 1, rowMoveUp);
                partRows.splice(rowIdx + 1, 1, rowMoveDown);
            break;
            default:
            break;
        }
        this.setState({
            formData: {
                ...this.state.formData,
                partRows
            }
        });
    }

    handleWarrantyChange = name => e => {
        if(name === 'warrantyTerm'){
            if(e) {
                this.setState({
                    ...this.state,
                    selectedWarrantyTerm: {
                        id: e.value,
                        name: e.name,
                        duration: e.duration
                    }
                });
            } else {
                this.setState({
                    ...this.state,
                    selectedWarrantyTerm: initialState.selectedWarrantyTerm
                });
            }
        } else {
            const { value } = e.target;
            let data = this.state.warrantyUpdate ? 'dialogRowData' : 'selectedPartRow';
            let dataToUpdate = this.state[data];

            dataToUpdate = {
                ...dataToUpdate,
                [name]: value
            };
            this.setState({
                ...this.state,
                [data]: dataToUpdate
            });
        }
    }


    handleWarrantyDialogOpen = () => {
        this.setState({updatePartDialogOpen: false, warrantyDialogOpen: true});
    }

    handleWarrantyDialogClose = () => this.setState({warrantyUpdate: false, warrantyDialogOpen: false, updatePartDialogOpen: this.state.warrantyUpdate});

    handleWarrantyUpdate = () => {
        this.setState({
            warrantyUpdate: true,
            updatePartDialogOpen: false,
            warrantyDialogOpen: true,
        });
    }

    handleWarrantyDialogSuccess = () => {
        const { selectedWarrantyTerm } = this.state;

        if(this.state.warrantyUpdate) {
            //Updating existing part
            this.setState({
                warrantyUpdate: false,
                warrantyDialogOpen: false,
            }, () => {
                let partRows = [...this.state.formData.partRows];

                partRows[this.state.dialogRowData.rowIdx] = {
                    ...partRows[this.state.dialogRowData.rowIdx],
                    warrantyId: selectedWarrantyTerm.id,
                    warrantyName: selectedWarrantyTerm.name,
                    warrantyDuration: selectedWarrantyTerm.duration
                };

                this.setState({
                    formData: {
                        ...this.state.formData,
                        partRows
                    }
                }, this.handleUpdatePartDialogSuccess);
            });
        } else {
            this.setState({
                warrantyUpdate: false, 
                warrantyDialogOpen: false,
                selectedPartRow:{
                    ...this.state.selectedPartRow,
                    warrantyId: selectedWarrantyTerm.id,
                    warrantyName: selectedWarrantyTerm.name, 
                    warrantyDuration: selectedWarrantyTerm.duration,
                }
            }, () => this.handlePartSelectAddRow());
        }
    }

    handleViewPartDialogClose = () => {
        this.setState({
            viewPartDialogOpen: false
        });
    }


    handleUpdatePartDialogSuccess = () => {
        const { selectedWarrantyTerm } = this.state;

        let partRows = [...this.state.formData.partRows];
        let rowIndex = this.state.dialogRowData.rowIdx;
        let newRow = this.state.dialogRowData;

        newRow = {
            ...newRow,
            warrantyId: selectedWarrantyTerm.id,
            warrantyName: selectedWarrantyTerm.name,
            warrantyDuration: selectedWarrantyTerm.duration
        };

        partRows.splice(rowIndex, 1, newRow);

        this.setState({
            formData: {
                ...this.state.formData,
                partRows
            },
            updatePartDialogOpen: false
        }, () => this.calculateOverallTotals());
    }

    handleUpdatePartDialogClose = () => {
        this.setState({
            updatePartDialogOpen: false  
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
            formData: {
                ...this.state.formData,
                previewMode: false
            }
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handlePreview = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                previewMode: true
            }
        },
        () => {
            this.submit();
        });
    }

    handleEmailOrder = () => {
        API.get('/sales/quotations/' + this.state.emailDialogQuoteId).then(result => {
        const dialog = <EmailDialog
                                customerId={this.state.emailDialogCustomerId}
                                customerContactId={this.state.emailDialogContactId}
                                type='quote'
                                id={this.state.emailDialogQuoteId}
                                pickingDocuments={result.data.pickingDocuments}
                            />
            this.props.deployDialog(dialog, 'You Are About To Email - Quotation', 'success');
        });
    }

    submit = () => {
        this.setState({
            formErrors: [],
            isLoading: true
        },
        () => {
            const formData = {
                ...this.state.formData,
                quoteDate: (moment(new Date(this.state.formData.quoteDate)).format('DD/MM/YYYY'))
            }
            API.post('/sales/quotations', formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    if(result.data.pdf){
                        this.setState({
                            isLoading: false
                        },
                        () => {
                            pdfFromBase64(result.data.pdf, result.data.pdfName);
                        });
                    }
                    else {
                        this.setState({
                            ...initialState,
                            snackbarOpen: true,
                            emailDialogQuoteId: result.data.quoteId,
                            emailDialogCustomerId: result.data.customerId,
                            emailDialogContactId: result.data.contactId
                        }, () => {
                            this.populateDropdowns();
                            this.handleEmailOrder();
                        });
                    }
                }
                this.props.scrollToTop();
            });
        });
    }

    handleDateChange = date => {
        this.setState({
            formData: {
                ...this.state.formData,
                quoteDate: date
            }
        });
    };

    handleAddressSearchCallBack = item => {
        this.setState({
            custId: item.address_customer_id,
            siteId: null,
        },this.checkPreFill);
    }

    handleSnackbarClose = () => this.setState({...this.state, snackbarOpen: false});
       
    displayImportantInfo = () => {
        if (this.state.customer.cust_important_info && parseFloat(this.state.old_cust_id) != parseFloat(this.state.customer.cust_id)) {
            this.setState({old_cust_id: this.state.customer.cust_id}, () => { 
                this.props.deployDialog(
                    <>
                        <Typography component="p">
                            {this.state.customer?.cust_important_info && this.state.customer?.cust_important_info.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
                        </Typography>
                        <Button variant='outlined' style={{float: 'right'}} onClick={this.props.closeDialog}>Close</Button>
                    </>
                , 'Important Customer Information', null, 'sm', {backgroundColor : colors.importantInfo, color: '#000'})
            });
        }
        
    }

    updateRows = partRows => {
        this.setState({formData:{...this.state.formData, partRows}})
    }

    handleNewContact = contactId => {
        API.get(`/customers/${this.state.formData.customer}`)
        .then(res => {
            let customerAddresses = [];
            customerAddresses = customerAddresses.concat(   res.data.account_addresses, 
                                                            res.data.trading_addresses,
                                                            res.data.site_addresses,
                                                            [res.data.registered_address]);
            let address = _.find(customerAddresses, { 'address_id': this.state.formData.deliveryCustomerAddress});

            this.setState({
                formData: {
                    ...this.state.formData,
                    deliveryCustomerContact: contactId,
                },
                customer: res.data,
                customerAddresses,
                deliveryCustomerContacts:_.filter(address.active_contacts, i=>i.contact_email),
                lists: {
                    ...this.state.lists,
                    customerContactList: _.map(_.filter(address.active_contacts, i=>i.contact_email), (el) => {
                        return _.assign({
                            value: el.contact_id,
                            label: el.contact_name
                        });
                    })
                },
            },()=>{
                this.handleSelectChange('deliveryCustomerContact')({value: contactId});
                this.props.closeDialog()
            })
        })
    }

    setLinkedPartsFormData = (linkedPartsFormData) => {
        this.setState({linkedPartsFormData})
    }

    handleLinkedPartsDialogClose = () => {
        this.setState({linkedPartsDialogOpen: false})
    }

    addLinkedParts = () => {
        let partRows = this.state.formData.partRows;
        _.each(_.filter(this.state.linkedPartsFormData, i => i.include && parseFloat(i.quantity) > 0), i => {

            let warranty = _.find(this.state.warrantyTerms, {value: i.part_warranty_terms})

            let price        = parseFloat(i.price)        > 0 ? parseFloat(i.price)        : 0;
            let discountRate = parseFloat(i.discountRate) > 0 ? parseFloat(i.discountRate) : 0;
            let quantity     = parseFloat(i.quantity)     > 0 ? parseFloat(i.quantity)     : 0;

            partRows.push({
                part:             i.part_id,
                partDescription:  i.part_description,
                partNumber:       i.part_number,
                partImage:        i.thumbnail_url,
                
                rowIdx:           partRows.length,
                rowNumber:        partRows.length + 1,
                
                quantity:         quantity,
                discountRate:     parseFloat(discountRate).toFixed(2),
                partDiscount:     parseFloat(discountRate) > 0 ? 'Yes' : 'No',
                
                unitPrice:         parseFloat(price).toFixed(2),
                unitDiscount:      parseFloat(parseFloat(price) * (parseFloat(discountRate) / 100)).toFixed(2),
                newUnitPrice:      parseFloat(parseFloat(price) - (parseFloat(price) * (parseFloat(discountRate) / 100))).toFixed(2),
                subTotal:          parseFloat(parseFloat(price) * quantity).toFixed(2),
                discountTotal:     parseFloat((parseFloat(price) * (parseFloat(discountRate) / 100)) * quantity).toFixed(2),
                totalPrice:        parseFloat((parseFloat(price) - (parseFloat(price) * (parseFloat(discountRate) / 100))) * i.quantity).toFixed(2),
                
                warrantyDuration: warranty.duration,
                warrantyId:       warranty.value,
                warrantyName:     warranty.name,

                notes:            i.notes,
            });
        });
        this.setState({
            formData:{...this.state.formData, partRows}, 
            linkedPartsDialogOpen: false, 
            linkedPartsFormData: initialState.linkedPartsFormData
        }, () => this.calculateOverallTotals());
    }

    displayLinkedParts = (partRow, linkedDocs) => {
        let linkedParts = _.map(_.find(this.state.allPartsData, {part_id: partRow.part}).linked_parts, i => {
            let quantity     = parseFloat(i.quantity) * parseFloat(partRow.quantity);
            let discountRate = partRow.discountRate;
            return {
                ..._.find(this.state.allPartsData, {part_id: i.part_id}),
                include: true,
                quantity, discountRate
            }
        });

        this.setState({
            linkedPartsFormData:   _.orderBy(linkedParts, ['part_number'], ['asc']),
            pickingDocuments:       linkedDocs,
            linkedPartsDialogOpen: true
        })
    }

    addPackage = (salesPackage, qty=1) => {        
        let partRows = _.map(salesPackage.details, i => ({
            partImage:          i.part?.default_image?.file_path,
            part:               i.part.part_id,
            partNumber:         i.part.part_number,
            partDescription:    i.part.part_description,
            partDiscount:       i.pd_discount,
            quantity:           parseFloat(i.pd_qty) * parseInt(qty),
            unitPrice:          parseFloat(i.pd_unit_price).toFixed(2),
            unitDiscount:       parseFloat(i.pd_unit_discount).toFixed(2),
            newUnitPrice:       parseFloat(i.pd_new_unit_price).toFixed(2),
            subTotal:           parseFloat(parseFloat(i.pd_subtotal) * parseInt(qty)).toFixed(2),
            discountRate:       parseFloat(i.pd_discount).toFixed(2),
            discountTotal:      parseFloat(parseFloat(i.pd_discount_total) * parseInt(qty)).toFixed(2),
            totalPrice:         parseFloat(parseFloat(i.pd_total) * parseInt(qty)).toFixed(2),
            warrantyId:         i.pd_warranty_id,   
            warrantyName:       i.pd_warranty_name,
            warrantyDuration:   i.pd_warranty_duration,
            notes:              i.pd_notes,
        }));

        let salesPackages = this.state.formData.salesPackages;
        let idx = _.findIndex(salesPackages, {id: salesPackage.p_id});
        if (idx > -1) {
            salesPackages[idx].quantity += parseInt(qty);
        } else {
            salesPackages.push({
                id: salesPackage.p_id,
                quantity: parseInt(qty)
            })
        }

        this.setState({
            formData: {
                ...this.state.formData,
                salesPackages: salesPackages,
                partRows: [...this.state.formData.partRows, ...partRows]
            }
        }, this.calculateOverallTotals)
    }

    reIndex = () => {
        //checkServiceDiscount removes rowIdx from partRows
        // this.setState({
        //     formData: {
        //         ...this.state.formData,
        //         partRows: _.map(this.state.formData.partRows, (i, idx) => ({...i, rowIdx: idx}))
        //     }
        // })
    }

    checkServiceDiscount = () => {
        if (this.state.formData.quotationType === 'With Discount' && _.find(this.state.formData.partRows, i => parseInt(i.discountTotal) > 0 && _.find(this.state.allPartsData, {part_id: i.part}).part_product === 'No')) {
            this.props.deployDialog(
                <Grid container spacing={2} style={{alignItems: 'center'}}>
                    <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                            The following services are discounted. Please check the services and remove the discount if necessary.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Table>
                            <TableHead style={{backgroundColor: colors.primary, color: colors.white}}>
                                <TableRow>
                                    <TableCell style={{width: '38px', color: colors.white}}></TableCell>
                                    <TableCell style={{width: '13%', color: colors.white}}>Part Number</TableCell>
                                    <TableCell style={{color: colors.white}}>Description</TableCell>
                                    <TableCell style={{width: '13%', color: colors.white}}>Before Discount</TableCell>
                                    <TableCell style={{width: '13%', color: colors.white}}>Discount Rate</TableCell>
                                    <TableCell style={{width: '13%', color: colors.white}}>After Discount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(_.filter(this.state.formData.partRows, i => parseInt(i.discountTotal) > 0 && _.find(this.state.allPartsData, {part_id: i.part}).part_product === 'No'), i => 
                                    <TableRow key={i.rowIdx}>
                                        <TableCell>
                                            <ImageWithError
                                                src={i.partImage}
                                                alt={i.partNumber}
                                                style={{width: '30px', height: '30px'}}
                                            />
                                        </TableCell>
                                        <TableCell>{i.partNumber}</TableCell>
                                        <TableCell>{i.partDescription}</TableCell>
                                        <TableCell>£{ parseFloat(i.subTotal).toFixed(2)}</TableCell>
                                        <TableCell style={{color: colors.red}}>{ parseFloat(i.discountRate).toFixed(2)}%</TableCell>
                                        <TableCell style={{color: colors.red}}>£{ parseFloat(i.totalPrice).toFixed(2)}</TableCell>
                                    </TableRow>                            
                                )}
                            </TableBody>
                        </Table>
                        {/* <CiDataTable
                            config={{
                                key: 'rowNumber',
                            }} 
                            rows={_.filter(this.state.formData.partRows, i => parseInt(i.discountTotal) > 0 && _.find(this.state.allPartsData, {part_id: i.part}).part_product === 'No')}
                            columns={[
                                {
                                    heading: '',
                                    field: 'partImage',
                                    fieldFormat: 'image',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Part Number',
                                    field: 'partNumber',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Description',
                                    field: 'partDescription',
                                    truncate: true,
                                },
                                {
                                    heading: 'Before Discount',
                                    field: 'subTotal',
                                    sizeToContent: true,
                                    fieldFormat: 'currency'
                                },
                                {
                                    heading: 'Discount Rate',
                                    field: i => parseFloat(i.discountRate).toFixed(2),
                                    sizeToContent: true,
                                    fieldSuffix: '%',
                                    style: {color: colors.red}
                                },
                                {
                                    heading: 'After Discount',
                                    field: 'totalPrice',
                                    sizeToContent: true,
                                    fieldFormat: 'currency',
                                    style: {color: colors.red}
                                }
                            ]}
                        /> */}
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button variant="outlined" onClick={this.props.closeDialog}> Close </Button>
                        <Button variant="contained" color="primary" onClick={()=>{this.props.closeDialog();this.handleConfirmationOpen();}}> Continue </Button>
                    </Grid>
                </Grid>
                , 
                'Discounted Services ', 
                'error', 
                'md'
            )
        } else {
            this.handleConfirmationOpen();
        }
    }

    render() {
       
        const { formErrors, formData, selectedPartRow, isLoading, customerList, pickingDocuments, RepeatOrderWarningDialogOpen } = this.state;
        const loggedInStaff = this.props.loggedInStaff !== undefined ? this.props.loggedInStaff.firstName + ' ' + this.props.loggedInStaff.lastName : '';

        return (
            <Grid container spacing={3}>
                 {(isLoading )
                    ?   <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    : 
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Add Quotation
                            </Typography>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    {formErrors && formErrors.generic && (
                                        <React.Fragment>
                                            <Typography component={"div"} style={{color: colors.red}}>
                                                {formErrors.generic}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                    <form noValidate autoComplete="off" >
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                options={[
                                                    {value: 'With Discount', label: 'With Discount'},
                                                    {value: 'Without Discount', label: 'Without Discount'}
                                                ]} 
                                                label='Quotation Type *'
                                                onChange={this.handleSelectChange('quotationType')}
                                                error={formErrors && formErrors['quotationType'] && true}
                                                errorText={formErrors && formErrors['quotationType']}
                                                value={formData.quotationType}
                                                disabled={formData.quotationType && formData.customer && true}
                                            />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <AutoCompleteSelect 
                                                options={this.state.customerList} 
                                                label='Customer *'
                                                value={formData.customer}
                                                onChange={this.handleSelectChange('customer')}
                                                error={formErrors && formErrors['customer'] && true}
                                                errorText={formErrors && formErrors['customer']}
                                                noClear
                                            />
                                        </FormControl>
                                    </form>
                                    { customerList.length ?
                                        <CustomerAddressSearch 
                                            callback={this.handleAddressSearchCallBack} 
                                            customers={customerList}
                                            disabledClosed
                                        /> :
                                        <Grid item xs={12}> <LoadingCircle /></Grid> 
                                    }
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        {this.state.showForm &&
                            <React.Fragment>
                               <Grid container item spacing={3} style={{alignItems: 'stretch'}}>
                                    <Grid item xs={12} lg={6}>
                                        <PaddedPaper style={{height: '100%'}}>
                                            <CustomerDetails
                                                company={formData.company}
                                                address={formData.address}
                                                paymentTerms={formData.paymentTerms}
                                                customerContact={formData.customerContact}
                                                customerPhone={formData.customerPhone}
                                                customerMobile={formData.customerMobile}
                                                customerEmail={formData.customerEmail}
                                                customerContactList={this.state.customerContactList}
                                                handleSelectChange={this.handleSelectChange}
                                                formErrors={this.state.formErrors}
                                            />
                                        </PaddedPaper>
                                    </Grid>

                                    <Grid item xs={12} lg={6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <DeliveryDetails
                                                        deliveryCustomerAddressType={formData.deliveryCustomerAddressType}
                                                        deliveryCustomerAddress={formData.deliveryCustomerAddress}
                                                        deliveryCustomerContact={formData.deliveryCustomerContact}
                                                        deliveryCustomerPhone={formData.deliveryCustomerPhone}
                                                        deliveryCustomerMobile={formData.deliveryCustomerMobile}
                                                        deliveryCustomerEmail={formData.deliveryCustomerEmail}
                                                        lists={this.state.lists}
                                                        handleSelectChange={this.handleSelectChange}
                                                        formErrors={this.state.formErrors}
                                                        customer={this.state.customer}
                                                        handleNewContact={this.handleNewContact}
                                                    />
                                                </PaddedPaper>
                                            </Grid>
                                            {formData.deliveryCustomerAddress && this.state.lists.customerAddressList.length && _.find(this.state.lists.customerAddressList, { value: formData.deliveryCustomerAddress }) &&
                                                <Grid item xs={12}>
                                                    <PaddedPaper>
                                                        <Typography variant="h5" gutterBottom>
                                                            Couriers
                                                        </Typography>
                                                        <DataTable
                                                            config={{
                                                                key: 'cz_id',
                                                                noHeader: true,
                                                            }}
                                                            columns={[
                                                                {
                                                                    heading: 'Courier',
                                                                    field:   i => <img src={_.find(i.courier.file, {'cour_file_type': 'Logo'}).file_url} height={30}/>,
                                                                    style: {width: '1%'}
                                                                },
                                                                {
                                                                    field:   'courier.cour_name',
                                                                    style: {width: '1%'}
                                                                },
                                                                {
                                                                    heading:  'Zone',
                                                                    field:    'cz_name',
                                                                    info:      i => <>{_.map(i.cz_desc.split('\n'), i => <>{i}<br/></>)}</>
                                                                }
                                                            ]}
                                                            rows={_.find(this.state.lists.customerAddressList, { value: formData.deliveryCustomerAddress })?.address?.zones}
                                                        />
                                                    </PaddedPaper>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <AddPackageRow 
                                            discountType={formData.quotationType}
                                            addPackage={this.addPackage}
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <AddPartEntryRow 
                                            partList={this.state.partList}
                                            handlePartSelectAddRow={this.handlePartSelectAddRow}
                                            handleWarrantyDialogOpen={this.handleWarrantyDialogOpen}
                                            handlePartSelectRowRemove={this.handlePartSelectRowRemove}
                                            selectedPartRow={selectedPartRow}
                                            handlePartSelect={this.handlePartSelect}
                                            handlePartSelectRowChange={this.handlePartSelectRowChange}
                                            quotationType={formData.quotationType}
                                            partSelectQuantity={this.partSelectQuantity}
                                            formErrors={this.state.formErrors}
                                            focus={false}
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper key={formData.partRows.length}>
                                        <QuotePartItems 
                                            partRows={formData.partRows}
                                            partList={this.state.partList}
                                            quotationType={formData.quotationType}
                                            handleViewPart={this.handleViewPart}
                                            handleUpdatePart={this.handleUpdatePart}
                                            handleRemovePart={this.handleRemovePart}
                                            handleMove={this.handleMove}
                                        />
                                        {formData.partRows.length > 1 &&
                                            <Grid container xs={12} align="right" justify="flex-end" style={{}}>
                                                <Grid item xs={3} style={{marginTop:'1em'}}>
                                                    <OrderPartDetailsButton   partRows={formData.partRows}  callback={this.updateRows}/>
                                                </Grid>
                                            </Grid>
                                        }
                                    </PaddedPaper>
                                </Grid>
                               
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <QuoteTotals 
                                            partRows={formData.partRows}
                                            partList={this.state.partList}
                                            nominalList={this.state.nominalList}
                                            handleRemoveRow={this.handleRemoveRow}
                                            handleAddRow={this.handleAddRow}
                                            handleSelectRowChange={this.handleSelectRowChange}
                                            handleRowChange={this.handleRowChange}
                                            formErrors={formErrors}
                                            subTotal={formData.subTotal}
                                            customerVatPercentage={formData.customerVatPercentage}
                                            customerDiscountRate={formData.customerDiscountRate}
                                            vat={formData.vat}
                                            overallPrice={formData.overallPrice}
                                            quotationType={formData.quotationType}
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid container item spacing={3}>
                                    <Grid item xs={12} lg={6}>
                                        <>
                                            <QuoteDetails 
                                                formErrors={formErrors}
                                                handleDateChange={this.handleDateChange} 
                                                handleChange={this.handleChange}
                                                date={formData.quoteDate}
                                                notes={formData.notes}
                                            />
                                        </>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={3}>
                                    <Grid item xs={12} lg={6}>
                                        <AddPromotionDetails 
                                            onChange={promotions => this.setState({formData: {...this.state.formData, promotions}})}
                                            value={formData.promotions}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={3}>
                                    <Grid item xs={12} lg={6}>
                                        <PaddedPaper>
                                            <div className='buttonRow' style={{justifyContent: 'space-between', flexDirection: 'row', alignItems:'center'}}>
                                                <Typography variant="subtitle2" style={{flex: 1}}>
                                                    Quoted By: {loggedInStaff}
                                                </Typography>
                                                <Button onClick={this.handlePreview}
                                                        variant="outlined"
                                                        color="default">
                                                    Preview
                                                </Button>
                                                <Button onClick={this.checkServiceDiscount}
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={this.state.isLoading || !formData.customerVatPercentage || !formData.paymentTerms}>
                                                    Add
                                                </Button>
                                            </div>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                        {/* WARRANTY DIALOG */}
                        <WarrantyDialog 
                            warrantyDialogOpen={this.state.warrantyDialogOpen}
                            handleWarrantyDialogClose={this.handleWarrantyDialogClose}
                            selectedPartRow={selectedPartRow}
                            warrantyUpdate={this.state.warrantyUpdate}
                            dialogRowData={this.state.dialogRowData}
                            warrantyTerms={this.state.warrantyTerms}
                            handleWarrantyChange={this.handleWarrantyChange}
                            selectedWarrantyTerm={this.state.selectedWarrantyTerm}
                            handleWarrantyDialogSuccess={this.handleWarrantyDialogSuccess}
                        />
                        {/* VIEW PART DIALOG */}
                        <FullScreenDialog
                            open={this.state.viewPartDialogOpen}
                            onClose={this.handleViewPartDialogClose}
                            title="Part Information"
                            content={<ViewPart id={this.state.dialogRowData.part} />}
                        />
                        <LinkedPartsDialog
                            open={this.state.linkedPartsDialogOpen}
                            formData={this.state.linkedPartsFormData}
                            setFormData={this.setLinkedPartsFormData}
                            close={this.handleLinkedPartsDialogClose}
                            pickingDocuments={pickingDocuments}
                            submit={this.addLinkedParts}
                            warrantyTerms={this.state.warrantyTerms}
                            allowDiscount={this.state.formData.quotationType === 'With Discount'}
                        />
                        {/* UPDATE PART DIALOG */}
                        <Dialog 
                            open={this.state.updatePartDialogOpen} 
                            onClose={this.handleUpdatePartDialogClose} 
                            maxWidth="lg" 
                            fullWidth={true} 
                            scroll="body"
                            disableBackdropClick
                            disableEscapeKeyDown
                        >
                            <DialogTitle>Update Part</DialogTitle>
                            <DialogContent>
                                <AddPartEntryRow 
                                    partList={this.state.partList}
                                    handlePartSelectAddRow={this.handlePartSelectAddRow}
                                    selectedPartRow={selectedPartRow}
                                    handlePartSelect={this.handleDialogPartSelect}
                                    handlePartSelectRowChange={this.handleDialogPartRowChange}
                                    quotationType={formData.quotationType}
                                    dialog={true}
                                    dialogRowData={this.state.dialogRowData}
                                    partSelectQuantity={this.updatePartSelectQuantity}
                                    handleWarrantyUpdate={this.handleWarrantyUpdate}
                                    handleUpdatePartDialogSuccess={this.handleUpdatePartDialogSuccess}
                                    handleUpdatePartDialogClose={this.handleUpdatePartDialogClose}

                                />
                            </DialogContent>
                        </Dialog>
                        {/* CONFIRMATION DIALOG */}
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen} 
                            success={this.handleConfirmationSuccess} 
                            close={this.handleConfirmationClose} 
                            title="Add a New Quotation?" 
                            message="Are you sure you want to add this new quotation?"
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen}
                            onClose={this.handleSnackbarClose}
                            message='You have successfully added a new quotation'
                        />
                    </>
                }
                {RepeatOrderWarningDialogOpen && <RepeatQuoteWarningDialog handleChange={this.handleCopyOrderChange} copyOrder={this.state.copyOrder} handleSubmit={this.completeCopyOrder} /> }
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, variant, size, style) => {
            dispatch(deployDialog(content, title, variant, size, style))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        }
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
        loading: {
            ...state.loading
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddQuotation);