import React, { Component } from 'react';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import DataTable   from 'Components/Common/DataTables/CiDataTable';
import AllIcon     from 'Components/Common/Icons/AllIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import icons                                       from 'Helpers/IconHelper';
import { formatSeconds, isBetween, getDifference } from 'Helpers/TimeHelper';

import { Grid, Typography } from '@material-ui/core/';

const initialState = {
    worksOrder: {}, 
    timeSheet:  [],
}

function InfoBox({heading, value, icon}) {
    return (
        <Grid item xs={12} lg={12}>
            <PaddedPaper style={{textAlign:'center'}}>
                <AllIcon icon={icon} size='xlarge' />
                <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>{heading}</Typography>
                <Typography variant="h4" style={{textAlign:'center'}}>{value}</Typography>
            </PaddedPaper>
        </Grid>
    )
}
class TimeSheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState, 
            worksOrder: this.props.worksOrder,
            timeSheet:  this.props.worksOrder.build.time_sheets,
        };
    }
    
    render(){

        const { worksOrder, timeSheet } = this.state;

        const logs  = worksOrder.logs;
        const plogs = worksOrder.part_logs;

        let allParts =  _.uniqBy(_.map([...worksOrder.details, ...worksOrder.additionals], i => {
                            return { 
                                id               : i.part.part_id,
                                img              : i.part?.default_image?.file_path,
                                number           : i.part.part_number, 
                                desc             : i.part.part_description,
                            }
                        }), 'id');

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={3}>
                            <Grid item container spacing={3} xs={12} style={{justifyContent: 'center'}}>
                                <Grid item xs={12} md={9}>
                                    <PaddedPaper style={{height: '100%'}}>
                                        <DataTable
                                            config={{
                                                key: 'id',
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Staff',
                                                    field:   'name',
                                                },
                                                {
                                                    heading:       'Last Session',
                                                    field:         'last',
                                                    fieldFormat:   'clenydate',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading:       'Sessions',
                                                    field:         'sessions',
                                                    alignment:     'center',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading:       'Total Time',
                                                    field:         'time',
                                                    sizeToContent: true,
                                                    alignment:     'center'
                                                }
                                            ]}
                                            rows={_.orderBy(_.map(_.uniqBy(timeSheet, 'wobt_staff_id'), i => {
                                                let rows = _.filter(timeSheet, {wobt_staff_id: i.wobt_staff_id});
                                                return {
                                                    id:       i.wobt_staff_id,
                                                    name:     i.wobt_staff_name,
                                                    sessions: rows.length,
                                                    time:     formatSeconds(_.sumBy(rows, r => parseInt(getDifference(r.wobt_start, r.wobt_end, 'seconds'))), true, true, true),
                                                    last:     _.last(rows).wobt_end,
                                                }
                                            }), 'name')}
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item container xs={12} md={3} spacing={3}>
                                    <InfoBox heading='Sessions'              value={timeSheet.length} icon={icons.list} />
                                    <InfoBox heading='Time'                  value={formatSeconds(_.sumBy(timeSheet, r => parseInt(getDifference(r.wobt_start, r.wobt_end, 'seconds'))), true, true, true)} icon={icons.timer} />
                                </Grid>
                            </Grid>
                            {_.map(_.uniqBy(timeSheet,'wobt_start_stage'), i => {
                                const stage = worksOrder.build.stages[i.wobt_start_stage - 1];
                                return (
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Typography variant="h6" style={{marginBottom: 15}}>Stage {stage.instruction.sais_number}: {stage.instruction.sais_name}</ Typography>
                                            <DataTable
                                                config={{
                                                    key: 'wobt_id',
                                                    dropRow: {
                                                        droppable: true,
                                                        hideDropArrow: true,
                                                        style:     {fontWeight: 'bold',},
                                                        columns:   [
                                                            {
                                                                colSpan:       2,
                                                                field:         'date',
                                                                fieldFormat:   'clenydatetime',
                                                            },
                                                            {
                                                                colSpan:       2,
                                                                field:         'type',
                                                            },
                                                            {
                                                                colSpan:       3,
                                                                field:         'note',
                                                            },
                                                        ],
                                                        data:      r => {
                                                            let items = _.orderBy(
                                                                [..._.map(_.filter(logs, l => l.wol_staff_id === r.wobt_staff_id && isBetween( l.wol_datetime, r.wobt_start, r.wobt_end ) ), i => ({
                                                                    date: i.wol_datetime,
                                                                    type: i.wol_type,
                                                                    note: i.wol_note,
                                                                }) ), ..._.map(_.filter(plogs, l => l.wopl_staff_id === r.wobt_staff_id && isBetween( l.wopl_datetime, r.wobt_start, r.wobt_end ) ), i => ({
                                                                    date: i.wopl_datetime,
                                                                    type: i.wopl_type,
                                                                    note: `${_.find(allParts,{id:i.wopl_part_id}).number} - ${_.find(allParts,{id:i.wopl_part_id}).desc} ${i.wopl_note && i.wopl_note !== '' ? `* ${i.wopl_note}` : '' }`,
                                                                }))], 'date');

                                                            let ret = [
                                                                {
                                                                    date: r.wobt_start,
                                                                    type: 'Works Order Build',
                                                                    note: null,
                                                                }
                                                            ]

                                                            _.each(items, i => {
                                                                ret.push(i);
                                                                if (['Additional Stock Picked', 'Stock Returned', 'Write Off Completed', 'First Pass Approved', 'First Off Check Approved', 'Notes Added',
                                                                    'First Pass Declined', 'First Off Check Declined', 'Feedback Added', 'Feedback Updated', 'Feedback Removed', 'Important Note Added', 'Important Note Removed',
                                                                    'Amended Sticky Note', 'Added Sticky Note', 'Deleted Sticky Note', 'Completed Sticky Note'
                                                                ].includes(i.type)){
                                                                    ret.push({
                                                                        date: i.date,
                                                                        type: 'Works Order Build',
                                                                        note: null,
                                                                    });
                                                                }
                                                            })

                                                            return ret;
                                                        }
                                                    }
                                                }}
                                                columns={[
                                                    {
                                                        heading:       'Stages',
                                                        field:         rowData => `${rowData.wobt_start_stage} -> ${rowData.wobt_end_stage || ''}`,
                                                        sizeToContent: true,
                                                    }, 
                                                    {
                                                        heading:       'Staff',
                                                        field:         'wobt_staff_name',
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading:       'Start Date',
                                                        field:         'wobt_start',
                                                        fieldFormat:   'clenydate',
                                                        alignment:     'center',
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading:       'Start Time',
                                                        field:         'wobt_start',
                                                        fieldFormat:   'clenytime',
                                                        alignment:     'left'
                                                    },
                                                    {
                                                        heading:       'End Date',
                                                        field:         'wobt_end',
                                                        fieldFormat:   'clenydate',
                                                        alignment:     'center',
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading:       'End Time',
                                                        field:         'wobt_end',
                                                        fieldFormat:   'clenytime',
                                                        alignment:     'left'
                                                    },
                                                    
                                                    {
                                                        heading:       'Time',
                                                        field:         r => r.wobt_end && formatSeconds(getDifference(r.wobt_start, r.wobt_end, 'seconds'), true, true, true),
                                                        sizeToContent: true,
                                                    }
                                                ]}
                                                rows={_.filter(timeSheet, {wobt_start_stage: i.wobt_start_stage})}
                                            />
                                        </PaddedPaper>
                                    </Grid>
                                )}
                            )}
                        </Grid>
                    </PaddedPaper>
                </Grid>
            </Grid>
        )
    }
}


function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff
    };
}

export default connect(mapStateToProps, null)(TimeSheet)