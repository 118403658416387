import React, { Component } from 'react';
import { connect }          from 'react-redux';
import { Link }             from 'react-router-dom';
import API                  from 'API';
import _                    from 'lodash';
import moment               from 'moment';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import DataTable                   from 'Components/Common/DataTables/CiDataTable';
import AllIcon                     from 'Components/Common/Icons/AllIcon';
import PaddedPaper                 from 'Components/Common/Paper/PaddedPaper';
import StickyNotes                 from 'Components/Common/StickyNotes/StickyNotes';
import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog    from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';
import BatchProcessPayments        from 'Components/Sales/Order/OrderInvoice/BatchProcessPayments';
import OrderSearchBlock            from 'Components/Sales/Order/OrderSearch/OrderSearchBlock';

import { Button, Grid, Tooltip, Typography, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { closeDialog, deployDialog }        from 'Actions/Dialog/Dialog';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';
import JobSnapshot from 'Components/Engineers/Jobs/Misc/JobSnapshot';
import 'Assets/timeLine.css';

const initialState = () => ({
    formData:   {
        searchString: '',
        dateFrom: null,
        dateTo: null,
        type: 'All'
    },
    formErrors: {},
    data:       {},
    ogData:     {},
    access:     {},
    isLoading:  false,
});

const styles = theme => ({
    custName: {
        '&:hover':{cursor: 'pointer'}
    }
});

class Invoices extends Component  {
    constructor(props) {
        super(props);    
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `invoices:i:${this.props.cid}`;
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.getAccess();
        !this.hasPageState() && this.getData();
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/customer-order-search'),
            API.get('/staff/my/access/check/repeat-customer-order')
        ])
        .then(([accessRes, repeatOrderRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    customerOrderSearch: ( accessRes?.data?.has_access )      || false,
                    repeatOrder:         ( repeatOrderRes?.data?.has_access ) || false
                }
            });
        })
    }

    handleSetSearch = props => {
        this.setState({
            formData: props
        },this.getData);
    }

    getData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/sales/orders/awaitingInvoice', {params: {
                keywords: this.state.formData.keywords,
                dateFrom: this.state.formData.dateFrom !== null ? moment(this.state.formData.dateFrom).format('YYYY-MM-DD') : null,
                dateTo:   this.state.formData.dateTo   !== null ? moment(this.state.formData.dateTo).format('YYYY-MM-DD')   : null,
                slim:1,
                foc: 0,
            }})
            .then(orders => {
                this.setState({
                    ...this.state,
                    data:   _.map(_.reject( orders.data, i => { return i.ir  } ), i => {i.checked = 0; return i}),
                    ogData: _.map(_.reject( orders.data, i => { return i.ir  } ), i => {i.checked = 0; return i}),
                    isLoading: false
                },() => { this.props.updateTabs(); this.savePageState();});
            });
        });
    }

    handleDownloadOpen = orderId => this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md');
    handleEmailOpen    = orderId => this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />, 'Email Previous Documents', '', 'md');

    handleProcessCheck = id => {
        let data = this.state.data
        let indx = _.findIndex(data, i => parseInt(i.i) === parseInt(id));
        data[indx].checked = !data[indx].checked;
        this.setState({data});
    }

    handleShowStickyNote = orderId => { this.props.deployDialog(<StickyNotes orderId={orderId} dialog/>, 'Sticky Notes', null,'xs') }

    render() {
        const { access } = this.state;
        const { classes } = this.props;

        return (            
            <Grid container item spacing={2}>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={!!_.find(this.state.data, i => i.checked) ? 9 : 12} style={{paddingTop:'1.8em'}}>
                        <OrderSearchBlock disableSites disableStatus callBack={this.handleSetSearch} formData={this.state.formData}/>
                    </Grid>
                    {!!_.find(this.state.data, i => i.checked) &&
                        <Grid item  xs={5} lg={3} style={{paddingTop:'1.8em'}}>
                            <PaddedPaper>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        Selected
                                    </Grid>
                                    <Grid item xs={6}>
                                        {_.filter(this.state.data, i => i.checked).length}
                                    </Grid>
                                    <Grid item xs={6}>
                                        Total
                                    </Grid>
                                    <Grid item xs={6}>
                                        £{parseFloat(_.sumBy(_.filter(this.state.data, i => i.checked),  i => { return i.t.s - i.t.p })).toFixed(2)}
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Button fullWidth variant='contained' color='primary'
                                            onClick={()=>{this.props.deployDialog(<BatchProcessPayments callback={this.getData} orders={_.map(_.filter(this.state.data, i => i.checked ), i => {return i.i})}
                                            callBack={this.getData} />,'Process Payments', '', 'xs')}}
                                        >Process Payments</Button>
                                    </Grid>    
                                </Grid>    
                            </PaddedPaper>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container xs={12}>
                            <Grid item xs={6}>
                                <Typography variant="h5"><strong>All Invoices</strong><br></br></Typography>
                            </Grid>
                            
                        </Grid>
                        
                        <DataTable  
                            config={{
                                key: 'invoice_id',
                                pagination: true,
                                alternatingRowColours: true,
                                responsiveimportant: true,
                                isLoading: this.state.isLoading,
                                rowsPerPage: 50
                            }}
                            columns={[
                                {
                                    heading: 'Order Date',
                                    field: rowData => moment(rowData.od).format("DD/MM/YYYY"),
                                    important: true,
                                    dataRef: 'od',
                                },
                                {
                                    actionsCustomHeader:    true,
                                    important:              true,
                                    alignment:              'left',
                                    heading:                <>Reference <br/></>,
                                    dataRef:                'r.f',
                                    actions: rowData => {
                                        let a = [
                                            { label: rowData.r.f,                           link: `/sales/order/view/${rowData.oi}`,                                            type: 'plainLink' },
                                        ]
                                        if (rowData.qi){
                                            a.push({ label: rowData.r.q, link: `/sales/quotation/view/${rowData.qi}`, type:'plainLink', class: "textSecondary"})
                                        }

                                        if (rowData?.crn?.length > 0){
                                            _.each(rowData.crn, i => {
                                                a.push(
                                                    {
                                                        label: i.r,
                                                        link: `/returns/creditnote/${i.i}`,
                                                        type:'plainLink', 
                                                        color: colors.red
                                                    }
                                                )
                                            })
                                        }

                                        if (rowData?.cr?.length > 0){
                                            _.each(rowData.cr, i => {
                                                a.push(
                                                    {
                                                        label: i.r,
                                                        link: `/returns/customerReturn/${i.i}`,
                                                        type:'plainLink', 
                                                        color: colors.red
                                                    }
                                                )
                                            })
                                        }

                                        return a;
                                    }
                                },
                                {
                                    heading:                <>Invoice(s) <br/></>,
                                    field: rowData =>  <div style={{width:200, overflow:'hidden',textOverflow: "ellipsis"}}>
                                                            {rowData.in.map((item, key) => {
                                                                return  <><Typography variant="caption" key={key} >
                                                                    {item.n} (Sage: {item.sn}) - {item?.c ? item?.cn : 'No Despatch'}
                                                                </Typography><br /></>
                                                            })}
                                                        </div>,
                                    important: true,
                                    sizeToContent: true,
                                    main: true,
                                    dataRef: i => _.map(i.in, 'n').join('|'),
                                },
                                {
                                    heading: <>Customer <br/></>,
                                    field: rowData => <Tooltip
                                                            title={
                                                                <Typography variant="body2">
                                                                    <b>Delivery Address:</b><br />
                                                                    {rowData.d.a.split('\n').map((item, key) => {
                                                                        return  <span key={key}>
                                                                                    {item}{rowData.d.a.split('\n').length === key+1 ? null : <br/>}
                                                                                </span>
                                                                    })}
                                                                </Typography>
                                                            }
                                                            placement="left"
                                                        >
                                                            <div className={classes.custName} onClick={()=>{ this.props.history.push(`/customers/view/${rowData.c}`); }}>
                                                                <Typography>{rowData.cn}</Typography>
                                                                <Typography component="div" variant="body2" > {rowData.d.n} </Typography>
                                                            </div>
                                                        </Tooltip>,
                                    important: false,
                                    sizeToContent: true,
                                    dataRef: 'cn',
                                    truncate: true,
                                },
                                {
                                    heading: 'Order Total',
                                    field: rowData => rowData.t.t,
                                    important: true,
                                    alignment: 'right',
                                    fieldPrefix: '£',
                                    dataRef: 't.t',
                                },
                                {
                                    heading: 'Pending Invoices',
                                    alignment: 'center',
                                    dataRef: rowData => !!rowData.ch.d + !!rowData.ch.p,
                                    field: rowData => {
                                        return (rowData.ch.d || rowData.ch.p )? 
                                        <Tooltip
                                            title={
                                                <div>
                                                    {rowData.ch.d &&
                                                        <Typography variant="body2">
                                                            Order items in despatch
                                                        </Typography>
                                                    }
                                                    {rowData.ch.p &&
                                                        <Typography variant="body2">
                                                            Order items in Picking
                                                        </Typography>
                                                    }
                                                </div>

                                            }
                                            placement="right"
                                        >
                                            <Link>
                                                <AllIcon icon='exclamation-triangle' noMargin button style={{color:colors.red}}/> 
                                            </Link>
                                        </Tooltip> : <></>;
                                    },
                                },
                                // {
                                //     heading: 'Total Paid',  
                                //     field: rowData => rowData?.t.p != null ? '£'+parseFloat(rowData?.t.p).toFixed(2) : '-',
                                //     important: true,
                                //     alignment: 'right',
                                //     dataRef: rowData => rowData?.t.p != null ? parseFloat(rowData?.t.p) : 0,
                                // },
                                {
                                    heading: 'Total Remaining',
                                    field: rowData => rowData?.t.p != null ? '£'+parseFloat(rowData.t.t - rowData?.t.p).toFixed(2) : parseFloat(rowData.t.t).toFixed(2),
                                    important: true,
                                    alignment: 'right',
                                    dataRef: rowData => rowData?.t.p != null ? parseFloat(rowData.t.t - rowData?.t.p) : parseFloat(rowData.t.t),
                                },
                                {
                                    heading: '',
                                    field: i => !!i?.p &&
                                        <AllIcon
                                            icon={icons.partialOrderPayment}
                                            tooltip='Order is partially paid'
                                            color={colors.red}
                                            noMargin
                                        />,
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Invoices Total',
                                    field: rowData => rowData.t.s,
                                    important: true,
                                    alignment: 'right',
                                    fieldPrefix: '£',
                                    dataRef: 't.s',
                                },
                                {
                                    heading: 'Jobs',
                                    field:      rowData => <>
                                        {rowData?.ej && 
                                            <Tooltip
                                                title={<JobSnapshot jobRef={rowData?.ej?.ref} forms={rowData?.ej?.f} />}
                                                placement="left"
                                            >
                                                <IconButton className={classes.iconButton} onClick={() => this.props.history.push(`/engineers/jobs/view/${rowData.ej?.id}`) }>
                                                    <AllIcon 
                                                        icon={`user-helmet-safety`}
                                                        button
                                                        noMargin
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </>,
                                    alignment: 'center',
                                    sizeToContent: true,
                                    important: false
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            { name: 'Sticky Notes',               icon: icons.stickyNote,   onClick: () => this.handleShowStickyNote(rowData.i), hide:rowData.s[0] === 0, color: rowData.s[1] > 0 && colors.yellow },
                                            { name: 'View',                       icon: 'search',           link: '/sales/order/invoice/payments/' + rowData.i},
                                            { type: 'checkBox',                   checked: rowData.checked, onCheck: ()=>{this.handleProcessCheck(rowData.i)},  disabled: (parseFloat(rowData.t.t) === 0 || (rowData.ch.d || rowData.ch.p ) || rowData.in.length > 1 )   },
                                            {
                                                type: 'actionMenu',
                                                rows: [
                                                    {
                                                        icon: icons.repeat,
                                                        label: "Repeat Order",
                                                        onClick:() => this.props.history.push(`/sales/order/add/repeat/${rowData.oi}`),
                                                        display: true,
                                                        disabled: !access.repeatOrder
                                                    },
                                                    {
                                                        icon: "download",
                                                        label: "Download",
                                                        onClick: () => this.handleDownloadOpen(rowData.i),
                                                        display: true
                                                    },
                                                    {
                                                        icon: "envelope",
                                                        label: "Send to Customer",
                                                        onClick: () => this.handleEmailOpen(rowData.i),
                                                        display: true
                                                    },
                                                    {
                                                        icon: "file-search",
                                                        label: "View this Customers Orders",
                                                        onClick: () => this.props.history.push(`/customers/view/${rowData.c}/Orders`),
                                                        display: true,
                                                        disabled: !access.customerOrderSearch
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.data}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                    => dispatch(setPersistence(key, state)),
    deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:        ()                                                         => dispatch(closeDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Invoices));