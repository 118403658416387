import React, { Component } from 'react';
import AllIcon from '../Icons/AllIcon';

import icons from 'Helpers/IconHelper';

class CopyToClipboard extends Component{
    constructor(props) {
        super(props);

        this.state = {
            copyIconColor: '',
            tooltip: false,
        }
    }

    copyToClipboard = field => {
        if (this.props.copy)        return this.copyFromProp();
        if (this.props.disabled)    return this.copyFromDisabled(field);
        this.copy(field);
    }

    copyFromProp(){
        const el = document.createElement('textarea');
        el.value = this.props.copy;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        this.setState({
            copyIconColor: '#0282C6',
            tooltip: true
        }, 
            () => setTimeout(() => this.hideIconColorChange(), 1500)
        );
    }

    copyFromDisabled (field) {
        document.getElementById(field).disabled = false;
        this.copy(field);
        document.getElementById(field).disabled = true;
    }

    copy (field) {
        document.getElementById(field).select();      
        document.execCommand("copy");    
        this.setState({
            copyIconColor: '#0282C6',
            tooltip: true
        }, 
            () => setTimeout(() => this.hideIconColorChange(), 1500)
        );
    }

    hideIconColorChange = () => {
        this.setState({
            copyIconColor: '',
            tooltip: false
        })
    }

    render(){
        const { field, size, noPadding, color, icon, style } = this.props;
        const { tooltip, copyIconColor } = this.state;
        return (
                    <AllIcon 
                        tooltip={tooltip ? 'Copied' : 'Copy to clipboard'}
                        icon={icon || icons.copy} 
                        size={size || "small"} 
                        noMargin 
                        noPadding={noPadding}  
                        onClick={() => this.copyToClipboard(field)} 
                        color={color || copyIconColor}
                        style={style}
                        name="Copy to clipboard"
                    />
        );
    }
}

export default (CopyToClipboard);
