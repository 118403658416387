import React from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import API from 'API';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import ModelTypes from './ModelTypes';
import BuildLocations from './BuildLocations';
import Numbers from './Numbers';
import SerialNumbersHistory from './SerialNumbersHistory';
import WarrantyVoidLabelsHistory from './WarrantyVoidLabelsHistory';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    value: 0,
    lists: {
        partList: [],
        modelTypes: [],
        buildLocationsList: [],
        yearsList: []
    },
    isLoading: false
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class SerialNumbers extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Serial:Numbers';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        this.loadComponentData();
        if (this.props.match.params.serialNumber) this.setState({value: 3});
    }

    loadComponentData = () => {
        this.setState({
            ...this.state,
            isLoading: true
        }, () => {
            Promise.all([
                API.get('/parts/all', {params:{reqSerialNumber: true}}),
                API.get('/parts/serialNumbers/modelTypes/all'),
                API.get('/parts/serialNumbers/buildLocations/all')
            ])
            .then(([partRes, modelTypeRes, buildLocationRes]) => {
                let partList = _.map(partRes.data, el => {
                    return _.assign({
                        value: el.part_id,
                        label: el.part_number + ' - ' + el.part_description
                    });
                });
    
                let buildLocationsList = _.map(buildLocationRes.data, el => {
                    return _.assign({
                        value: el.pbl_id,
                        label: el.pbl_code + ' ' + el.pbl_name,
                        code: el.pbl_code,
                        name: el.pbl_name
                    });
                });
    
                let yearsList = [];
                let startYear = 2009;  
                while(startYear <= 2070 ) {
                    let y = startYear++
                    y = y.toString().substr(-2)
                    yearsList.push({
                        value: y,
                        label: y
                    });
                }

                this.setState({
                    ...this.state,
                    lists: {
                        partList,
                        modelTypes: modelTypeRes.data,
                        buildLocationsList,
                        yearsList
                    },
                    isLoading: false
                }, ()=>this.savePageState());
            });
        });
    }

    handleChange = (event, value) => {
        this.setState({ value }, ()=>this.savePageState());
    };

    render() {
        const { scrollToTop } = this.props;
        const { value, lists:{partList, modelTypes, buildLocationsList, yearsList}, isLoading } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Serial Numbers 
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        {isLoading ? (
                            <LoadingCircle />
                        ) : (
                            <>
                                <AppBar position="static" style={{backgroundColor:'white'}}>
                                    <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" textColor="primary">
                                        <Tab label="Model Types" />
                                        <Tab label="Build Location" />
                                        <Tab label="Serial Numbers" />
                                        {/*<Tab label="Serial Numbers (Never Used)" />*/}
                                        <Tab label="Serial Numbers History" />
                                        <Tab label="Warranty Void Label History" />
                                    </Tabs>
                                </AppBar>
                                { value === 0 && <TabContainer><ModelTypes partList={partList} modelTypes={modelTypes} loadComponentData={this.loadComponentData} scrollToTop={scrollToTop} /></TabContainer> }
                                { value === 1 && <TabContainer><BuildLocations buildLocations={buildLocationsList} loadComponentData={this.loadComponentData} scrollToTop={scrollToTop} /></TabContainer> }
                                { value === 2 && <TabContainer><Numbers modelTypes={modelTypes} buildLocations={buildLocationsList} years={yearsList} loadComponentData={this.loadComponentData}  /></TabContainer> }
                                {/* value === 3 && <TabContainer><SerialNumberNotInUse modelTypes={modelTypes} buildLocations={buildLocationsList} years={yearsList} loadComponentData={this.loadComponentData}  /></TabContainer> */}
                                { value === 3 && <TabContainer><SerialNumbersHistory serialNumber={this.props.match.params.serialNumber}/></TabContainer>}
                                { value === 4 && <TabContainer><WarrantyVoidLabelsHistory/></TabContainer>}
                            </>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}
const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(SerialNumbers);
