import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import DataTable from '../../Common/DataTables/DataTable';
import moment from 'moment';

class ViewStaffAuditLog extends React.Component {
    render() {
        const { isLoading, data } = this.props;
        return (
            <Grid container alignItems='center' spacing={1}>
                {!isLoading && (
                    <React.Fragment>
                        <Grid item align='center'>
                            <FALightIcon icon='user-secret' style={{marginLeft: 8}} size='large' />
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body1">
                                Audit Log
                            </Typography>                             
                            <Typography variant="body2">
                                Last 100 activity records
                            </Typography>
                        </Grid>
                    </React.Fragment>
                )}
                <Grid item xs={12} style={{margin: 12}}>          
                    <DataTable  
                        config={{
                            key: 'audit_id',
                            pagination: true,
                            alternatingRowColours: true,
                            isLoading: isLoading,
                            responsiveImportance: true,
                            rowsPerPage: 5
                        }}
                        columns={[
                            {
                                heading: 'Date',
                                field: rowData => moment(rowData.audit_date_time).format("DD/MM/YY HH:mm"),
                                important: true,
                                sizeToContent: true
                            },
                            {
                                heading: 'IP Address',
                                field: rowData => rowData.audit_ip_address,
                                important: true,
                                sizeToContent: true
                            },
                            {
                                heading: 'Type',
                                field: rowData => rowData.audit_type,  
                                sizeToContent: true,    
                                main: true     
                            },
                            {
                                heading: 'MFA Code',
                                field: rowData => rowData.audit_mfa_code === 0 ? '-' : rowData.audit_mfa_code,
                                sizeToContent: true,
                                alignment: 'center'
                            },
                            {
                                heading: 'Login Success',
                                field: rowData => rowData.audit_login_success,
                                sizeToContent: true,                                
                                alignment: 'center'
                            },
                            {
                                heading: 'IP Blocked?',
                                field: rowData => rowData.audit_ip_blocked,
                                sizeToContent: true,                                
                                alignment: 'center'
                            },
                        ]}
                        rows={data}
                    />
                </Grid>         
            </Grid>
        )
    }
}

export default ViewStaffAuditLog;