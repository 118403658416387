import React from 'react';
import { Grid, TextField, Button, Link } from '@material-ui/core';
import API from 'API';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import _ from 'lodash';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { colors } from 'Helpers/ColourHelper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconHelper from 'Helpers/IconHelper';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { clenyDate } from 'Helpers/TimeHelper';

const initialState = () => ({
    isLoading: true,
    searchLoading: false,
    data: [],
    formData: {
        keyword: '',
        part: '',
        customer: '',
    },
    parts: [],
    customers: [],
})

class ViewPartsSold extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.timeout = null;
    }

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    getData = () => {
        clearTimeout(this.timeout);
        let link = (
            this.props.aid ?
            `/customers/${this.props.cid}/addresses/${this.props.aid}/partsSold` :
            (
                this.props.cid ?
                    `/customers/${this.props.cid}/partsSold` :
                    `/parts/${this.props.pid}/partsSold`
            )
        )
        this.setState({
            searchLoading: true,
        }, ()=> {
            API.get(link, {params: this.state.formData}).then(response => {
                let uParts = _.uniqBy(response.data, 'part_id');
                let uCustomers = _.uniqBy(response.data, 'customer_id');
                this.setState({
                    data: response.data, 
                    parts: this.state.parts.length > uParts.length ? this.state.parts : uParts,
                    customers: this.state.customers.length > uCustomers.length ? this.state.customers : uCustomers,
                    isLoading: false,
                    searchLoading: false,
                });
            })
        })
    }

    render() {

        if (this.state.isLoading) {
            return <LoadingCircle/>
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    label="Search"
                                    value={this.state.formData.keyword}
                                    onChange={(e) => {
                                        this.setState({formData: {...this.state.formData, keyword: e.target.value}}, this.getData);
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            {!this.props.pid &&
                                <Grid item xs={4}>
                                    <AutoCompleteSelect
                                        label="By Part"
                                        options={_.map(this.state.parts, p => ({
                                            value: p.part_id,
                                            label: `${p.number} - ${p.description}`,
                                        }))}
                                        value={this.state.formData.part}
                                        onChange={(e) => {
                                            this.setState({formData: {...this.state.formData, part: e ? e.value : null}}, 
                                                ()=> {
                                                    clearTimeout(this.timeout);
                                                    this.timeout = setTimeout(this.getData, 500);
                                                }
                                            );
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            }
                            <Grid item style={{marginLeft: 'auto'}}> 
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({
                                            formData: initialState().formData,
                                        }, this.getData);
                                    }}
                                ><AllIcon icon={IconHelper.refresh}/> Reset </Button>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <CiDataTable
                        config={{
                            key: 'id',
                            pagination: true,
                            dropRow:{
                                droppable: true,
                                columns: [
                                    {
                                        colSpan: (7 + (!this.props.pid ? 2 : 0) + (!this.props.aid ? 1 : 0)),
                                        field: i => {
                                            let expired = ( i.has_warranty && parseInt(i.warranty_remaining) < 0 ) ? 1 : 0;
                                            return (
                                                < >
                                                    <Grid container spacing={2} style={{
                                                        backgroundColor: expired && colors.importantNote,
                                                        justifyContent: 'space-around',
                                                    }}>  
                                                        <Grid item>
                                                            <b>Quantity</b> <br/> 
                                                            {parseFloat(i.qty).toFixed(3)}
                                                        </Grid>
                                                        <Grid item style={{maxWidth: '35%'}}>
                                                            <b>Serial Numbers</b> <br/> 
                                                            {i.sn ? _.map(i.sn.split(','), i => <>
                                                                <Link href={`/equipment/view/${i.split('|')[0]}`}>{i.split('|')[1]}</Link> <br/>
                                                            </>): '-'}
                                                        </Grid>
                                                        <Grid item style={{maxWidth: '35%'}}>
                                                            <b>Warranty Void Labels</b> <br/> 
                                                            {(!!i.wvl && i.wvl !== '[]'  && i.wvl !== 'null') ? JSON.parse(i.wvl).join(', ') : '-'}
                                                        </Grid>                                                     
                                                        <Grid item>
                                                            <b>Warranty:</b> <br/>
                                                            {i.has_warranty ? i.warranty_name : 'No Warranty'}
                                                        </Grid>
                                                        <Grid item>
                                                            <b>Duration:</b> <br/>
                                                            { i.warranty ? `${parseFloat(i.warranty)} Months` : '-'}
                                                        </Grid>
                                                        <Grid item>
                                                            <b>Remaining:</b> <br/>
                                                            {( i.has_warranty && parseInt(i.warranty_remaining) >= 0 ) ? `${parseInt(i.warranty_remaining)} Days` : ( i.has_warranty ? 'EXPIRED' : '-' ) }
                                                        </Grid>
                                                        <Grid item>
                                                            <b>Start:</b> <br/>
                                                            { i.warranty ? clenyDate(i.date) : '-'}
                                                        </Grid>
                                                        <Grid item>
                                                            <b>End:</b> <br/>
                                                            { i.warranty ? clenyDate(i.warranty_end) : '-'}
                                                        </Grid>
                                                        {/* <Grid item style={{padding:0, borderTop: `1px solid ${expired ?  colors.grey : '#ededed'}`}} xs={12}></Grid> */}
                                                       
                                                    </Grid>
                                                </>
                                            )   
                                        },
                                    }
                                ],
                            }
                        }}
                        rows={_.orderBy(this.state.data, i => i.date, 'desc')}
                        columns={_.filter([
                            (!this.props.pid && {
                                heading: '',
                                field: 'thumbnail_url',
                                sizeToContent: true,
                                fieldFormat: 'image',
                            }),
                            (!this.props.pid && {
                                heading: 'Part Number',
                                field: i => 
                                    <Grid container>
                                        <Grid item xs={12}>
                                            {i.number}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {i.description}
                                        </Grid>
                                    </Grid>,
                                truncate: true,
                                sizeToContent: true,
                                dataRef: 'number',
                            }),
                            (!this.props.aid && {
                                heading: (!this.props.cid ? 'Customer' : 'Site'),
                                field: i => 
                                    <Grid container>
                                        {!this.props.cid &&
                                            <Grid item xs={12}>
                                                {i.customer}
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            {i.address}
                                        </Grid>
                                    </Grid>,
                                truncate: true,
                                dataRef: (!this.props.cid ? 'customer' : 'address'),
                            }),
                            {
                                heading: 'Order',
                                field: i => 
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Link href={`/sales/order/view/${i.order_id}`} >
                                                {i.order_reference}
                                            </Link>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {i.invoice_number}
                                        </Grid>
                                    </Grid>,
                                truncate: true,
                                dataRef: 'order_reference',
                            },
                            {
                                heading: 'Invoiced',
                                field: 'date',
                                fieldFormat: 'clenydate',
                                sizeToContent: true,
                                dataRef: 'date',
                            },
                            {
                                heading: 'warranty',
                                field: 'has_warranty',
                                fieldFormat: 'boolean',
                                sizeToContent: true,
                                alignment: 'center',
                                dataRef: 'has_warranty',
                            },
                            {
                                heading: 'valid',
                                field: 'valid_warranty',
                                fieldFormat: 'boolean',
                                sizeToContent: true,
                                alignment: 'center',
                                dataRef: 'valid_warranty',
                            },
                            {
                                actions: rowData => {
                                    let ret = [];

                                    if (!this.props.pid) {
                                        ret.push({
                                            name: 'View Part',
                                            icon: IconHelper.parts, 
                                            link: `/parts/view/${rowData.part_id}`, 
                                        })
                                    }

                                    if (!this.props.aid){

                                        if (!this.props.cid) 
                                            ret.push({
                                                name: 'View Customer',
                                                icon: IconHelper.customers, 
                                                link: `/customers/view/${rowData.customer_id}`, 
                                            }) 

                                        ret.push({
                                            name: 'View Site', 
                                            icon: IconHelper.building, 
                                            link: `/customers/addresses/view/${rowData.address_id}`, 
                                        })

                                    }

                                    ret.push({
                                        name: 'View', 
                                        icon: IconHelper.sales, 
                                        link: '/sales/order/view/' + rowData.order_id, 
                                    })
                                    
                                    return ret;
                                }
                            }
                        ],i=>i)}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default ViewPartsSold;