import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import API from 'API';
import {
    Grid,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,

} from '@material-ui/core';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import { toggleDialog } from 'Functions/MiscFunctions';
import { handleDateChange } from 'Functions/FormFunctions';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import VehicleMileageForm from './VehicleMileageForm';
import TileStat from 'Components/Common/Tiles/TileStat';
import { CLENAWARE_RED, CLENAWARE_GREEN } from 'Constants';

const initialState = () => ({
    vehicleId: 0,
    mileage: [],
    dialog: {
        mileage: false,
        mileageId: 0,
    },
    access: {
        updateVehicle: false,
    },
    isLoading: true,
    formErrors: [],
    editMode: false
})

class VehicleMileage extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.toggleDialog = toggleDialog.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
    }

    componentDidMount() {
        const { vehicleId } = this.props;
        this.setState({
            ...this.state,
            vehicleId
        }, () => this.getAccess());
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-vehicle')
        ])
        .then(([manageRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    updateVehicle: (manageRes.data && manageRes.data.has_access) || false
                }
            }, () => this.loadComponentData());
        }) 
    }

    loadComponentData = () => {
        API.get(`/vehicles/${this.props.vehicleId}/mileage`)
        .then(result =>  {

            if(result.data) {
                this.setState({
                    isLoading: false,
                    mileage: result.data
                });
            }
        });
    }

    openMileageDialog = (id = 0) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                mileage: true,
                mileageId: id
            },
        });
    }

    formatMiles = (miles) => {
        return miles.toLocaleString('en-GB', {minimumFractionDigits: 0, maximumFractionDigits: miles < 1 ? 2 : 0});
    }

    render() {

        const { vehicleId, mileage, dialog, isLoading, access } = this.state;
        const { mileageStats, leaseAnnualMileageAllowance, deliveryDate, activeVehicle } = this.props;

        /*
        * Average Miles
        */
        let     daysInPossession    = parseInt(moment().diff(moment(deliveryDate), 'days')),
                avgDay              = parseFloat(mileageStats?.totalMiles / daysInPossession).toFixed(2),
                avgWeek             = parseFloat(avgDay * 7),
                avgMonth            = parseFloat(avgDay * 30),
                avgYear             = parseFloat(avgDay * 365),
                contractAvgDay      = parseFloat(leaseAnnualMileageAllowance / 365),
                contractAvgWeek     = parseFloat(leaseAnnualMileageAllowance / 52),
                contractAvgMonth    = parseFloat(leaseAnnualMileageAllowance / 12),
                contractAvgYear     = parseFloat(leaseAnnualMileageAllowance);
        
        return (
            <>
                <PaddedPaper>
                    {(isLoading && (
                        <LoadingCircle />
                    )) || (
                        <Grid container item spacing={3}>                        
                            <Grid item xs={12}>
                                <Grid container item spacing={3}>                        
                                    <Grid item xs>
                                        <Typography variant="h6" paragraph>
                                            <b>Mileage Tracker</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs align='right'>
                                        {access.updateVehicle && activeVehicle ?
                                            <Button 
                                                variant='outlined' 
                                                size="small" 
                                                onClick={() => this.openMileageDialog()}
                                            >
                                                <AllIcon icon='plus' size="small" />
                                                Add Mileage
                                            </Button>
                                        : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container item spacing={3}>
                                    <Grid item xs={12} md={5} style={{paddingTop: this.props.ui.device.isMobile ? 0 : 65}}>
                                        <Grid container item spacing={3}>                        
                                            <Grid item xs={12} md>
                                                <TileStat
                                                    icon='steering-wheel'
                                                    text="Total Miles Driven"
                                                    stat={mileageStats ? mileageStats?.totalMiles?.toLocaleString() : '-'}
                                                />  
                                            </Grid>
                                            <Grid item xs={12} md>
                                                <TileStat
                                                    icon='gauge-low'
                                                    text="Car Mileage"
                                                    stat={mileageStats ? mileageStats?.currentMileage?.toLocaleString() : '-'}
                                                />  
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                <b>Average Miles</b>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            <TableCell align="right">Actual</TableCell>
                                                            <TableCell align="right" style={{display: !leaseAnnualMileageAllowance && 'none'}}>Contract</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>Per Day</TableCell>
                                                            <TableCell align="right" style={{color: leaseAnnualMileageAllowance ? avgDay > contractAvgDay ? CLENAWARE_RED : CLENAWARE_GREEN : undefined}}>{this.formatMiles(avgDay)}</TableCell>
                                                            <TableCell align="right" style={{display: !leaseAnnualMileageAllowance && 'none'}}>{this.formatMiles(contractAvgDay)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Per Week {daysInPossession < 7 && '*'}</TableCell>
                                                            <TableCell align="right" style={{color: leaseAnnualMileageAllowance ? avgWeek > contractAvgWeek ? CLENAWARE_RED : CLENAWARE_GREEN : undefined}}>{this.formatMiles(avgWeek)}</TableCell>
                                                            <TableCell align="right" style={{display: !leaseAnnualMileageAllowance && 'none'}}>{this.formatMiles(contractAvgWeek)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Per Month {daysInPossession < 30 && '*'}</TableCell>
                                                            <TableCell align="right" style={{color:leaseAnnualMileageAllowance ?  avgMonth > contractAvgMonth ? CLENAWARE_RED : CLENAWARE_GREEN : undefined}}>{this.formatMiles(avgMonth)}</TableCell>
                                                            <TableCell align="right" style={{display: !leaseAnnualMileageAllowance && 'none'}}>{this.formatMiles(contractAvgMonth)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Per Year {daysInPossession < 365 && '*'}</TableCell>
                                                            <TableCell align="right" style={{color: leaseAnnualMileageAllowance ? avgYear > contractAvgYear ? CLENAWARE_RED : CLENAWARE_GREEN : undefined}}>{this.formatMiles(avgYear)}</TableCell>
                                                            <TableCell align="right" style={{display: !leaseAnnualMileageAllowance && 'none'}}>{this.formatMiles(contractAvgYear)}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                                <br></br>
                                                <Typography variant="caption" gutterBottom>
                                                    <em>This vehicle has been in your possession for {daysInPossession} days</em><br></br>
                                                    <em>* predicted calculations</em>
                                                </Typography>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        {mileage.length > 0 ?
                                            <>
                                            <Grid item xs={12}>
                                                <DataTable  
                                                    config={{
                                                        key: 'id',
                                                        pagination: true,
                                                        alternatingRowColours: false,
                                                        responsiveImportance: true,
                                                        isLoading: isLoading,
                                                        plainPagination: true,
                                                        rowProps: row => {
                                                            return ({
                                                                style: {
                                                                    backgroundColor: row.latest === 1 && 'rgb(241, 248, 233)'
                                                                }
                                                            });
                                                        }
                                                    }}
                                                    columns={[
                                                        {
                                                            heading: 'Type',
                                                            field: rowData => rowData?.type,                                   
                                                            important: true,
                                                            sizeToContent: true,
                                                            hideInResponsiveDialog: true,
                                                        },
                                                        {
                                                            heading: 'Date',
                                                            field: rowData => moment(rowData?.date).format('ddd DD MMM, YYYY'),                                   
                                                            important: true,
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: 'Added By',
                                                            field: rowData => rowData?.staff ? rowData?.staff?.fn + ' ' + rowData?.staff?.ln : '-',
                                                            important: true,
                                                        },
                                                        {
                                                            heading: 'Previous Car Mileage',
                                                            field: rowData => rowData?.prevMileage ? rowData?.prevMileage?.toLocaleString() : '-',
                                                            important: true,
                                                            alignment: 'right',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: 'Miles Driven',
                                                            field: rowData => rowData?.miles ? rowData?.miles?.toLocaleString() : '-',
                                                            important: true,
                                                            alignment: 'right',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: 'Car Mileage',
                                                            field: rowData => rowData?.currentMileage ? rowData?.currentMileage?.toLocaleString() : <b style={{color: CLENAWARE_RED }}>Awaiting</b>,
                                                            important: true,
                                                            alignment: 'right',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            actions: rowData => {
                                                                return [
                                                                    {name: 'Update', icon: 'pencil-alt', onClick: () => this.openMileageDialog(rowData?.id), disabled: !access.updateVehicle || rowData?.type !== 'Manual' || !activeVehicle},
                                                                ]
                                                            }
                                                        }
                                                    ]}
                                                    rows={mileage}
                                                />
                                            </Grid>
                                            </>
                                        :
                                            <Grid item xs={12} align="center">
                                                <Typography variant="h6" paragraph color='textSecondary'>
                                                    <em>No mileage found</em>
                                                </Typography>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </PaddedPaper>
                <SmallFormDialog
                    open={dialog?.mileage}
                    onClose={() => this.toggleDialog('mileage')}
                    content={
                        <VehicleMileageForm 
                            onClose={() => this.toggleDialog('mileage', true)}
                            mileageId={dialog?.mileageId}
                            refresh={() => {this.toggleDialog('mileage', true); this.props.refresh();}}
                            vehicleId={vehicleId}
                        />
                    }
                    maxWidth='sm'
                />
            </>
        )
    }
}

function mapStateToProps(state){
    return {
        ui: state.ui
    }
}


function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleMileage)