import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { MAX_UPLOAD_LIMIT_SIZE } from 'Constants';
import API from 'API';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { map, assign } from 'lodash';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';

import { closeDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { getDayWithOptions } from 'date-fns/fp';
import AddDespachDialog from './AddDespachDialog';

export default function AddFileDialog(props) {
    const { orderId, getOrder, handleEmailOrder, despatch, order } = props;
    const dispatch = useDispatch();
    const initialState = {
        formErrors: {},
        formData: {
            orderId:    '',
            fileType:   '',
            file:       '',
            despatchId: '',
            eJSType:    'Other',
            ondnId:     null
        },
        isLoading: true,
        despatchList: [],
        showDespatchedDeliveries: false,
        showNoDespatchDialog: false,
    }
    let [state, setState] = useState({ ...initialState })
    const { formErrors, formData, formData: { fileType, file, despatchId, eJSType }, isLoading, despatchList, showDespatchedDeliveries } = state;
    const handleSelectChange = fieldName => selectedValue => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [fieldName]: selectedValue && selectedValue.value,
                ondnId: fieldName == 'despatchId' ? (selectedValue && selectedValue.ondn ? selectedValue.value :  null) : state.formData.ondnId
            }
        }));
    }
    const clearFile = () => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                file: initialState.formData.file
            }
        }));
    }
    const handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: file
            }
        }));
    }
    const handleFilesClose = () => dispatch(closeDialog());
    const handleSkip = () => {
        handleFilesClose();
        handleEmailOrder();
    }
    const handleFilesSuccess = () => {
        setState(state => ({
            ...state,
            isLoading: true
        }));
        let newFormData = new FormData();
        Object.keys(formData).forEach(key => {
            if (key === 'file') {
                newFormData.append('file[]', formData[key]);
            } else {
                newFormData.append(key, formData[key]);
            }
        });
        API.post(`/sales/orders/${orderId}/files`, newFormData)
            .then(result => {
                if (result.data.errors) {
                    setState(state => ({
                        ...state,
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    }));
                } else if (result.data.success) {
                    handleFilesClose();
                    dispatch(deploySnackBar('success', 'You have successfully uploaded a file'));
                    if (handleEmailOrder) handleEmailOrder();
                    if (getOrder) getOrder();
                }
            });
    }

    useEffect(() => {
        let despatchList = map(despatch, el => {
            return assign({
                value: el.desp_id,
                label: el.desp_reference === 'No Despatch Note' ? el.desp_reference : el.full_reference,
            });
        });
        setState(state => ({
            ...state,
            formData: {
                ...state.formData,
                orderId
            },
            isLoading: false,
            despatchList
        }));
    }, [orderId]);

    useEffect(() => {
        setState(state => ({
            ...state,
            showDespatchedDeliveries: fileType == 'Courier Despatch Note' ? true : false
        }));
    }, [fileType]);

    const getOptions = () => {
        var ret = [
            { value: 'Purchase Order',                   label: 'Purchase Order' },
        ];
        ret.push({ value: 'Courier Despatch Note', label: 'Courier Despatch Note' });
        ret.push({ value: 'Engineer Job Sheet',    label: 'Engineer Job Sheet' });
        ret.push({ value: 'Payment Confirmation',  label: 'Payment Confirmation' });
        ret.push({ value: 'Call Recording',  label: 'Call Recording' });
        return ret;
    }

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <React.Fragment>
                    <FormControl error={formErrors && formErrors['fileType'] && true} fullWidth margin="normal">
                        <AutoCompleteSelect
                            options={getOptions()}
                            label='File Type *'
                            value={fileType}
                            onChange={handleSelectChange('fileType')}
                            error={formErrors && formErrors['fileType'] && true}
                            errorText={formErrors && formErrors['fileType'] && formErrors['fileType']}
                        />
                    </FormControl>
                    {showDespatchedDeliveries &&
                        <FormControl error={formErrors && formErrors['despatchId'] && true} fullWidth margin="normal">
                            <AutoCompleteSelect
                                options={[
                                    ...despatchList, 
                                    ['Invoice', 'Completed'].includes(order.order_status) && 
                                    _.filter(despatchList, i => i.label.includes('/MD/')).length == 0 &&
                                        {
                                            value: 'no_despatch_note',
                                            label: 'Add Manual Despatch Note'
                                        }
                                ]}
                                label='Despatched Deliveries'
                                value={despatchId}
                                onChange={handleSelectChange('despatchId')}
                                error={formErrors && formErrors['despatchId'] && true}
                                errorText={formErrors && formErrors['despatchId'] && formErrors['despatchId']}
                            />
                        </FormControl>
                    }
                    {despatchId === 'no_despatch_note' ?
                        <>
                            <div className="buttonRow">
                                <Button onClick={handleFilesClose} variant="outlined" color="default">Cancel</Button>
                                <Button onClick={() => {
                                    setState(state => ({
                                        ...state,
                                        showNoDespatchDialog: !state.showNoDespatchDialog
                                    }));
                                }} color="primary" autoFocus variant="contained">Add Manual Despatch Note</Button>
                            </div>
                        </> :
                        <>
                            {fileType === 'Engineer Job Sheet' &&
                                <FormControl error={formErrors && formErrors['eJSType'] && true} fullWidth margin="normal">
                                    <AutoCompleteSelect
                                        options={[
                                            { value: 'Other',                       label: 'Other' },
                                            { value: 'Machine Installation',        label: 'Machine Installation' },
                                            { value: 'Machine Maintenance Service', label: 'Machine Maintenance Service' },
                                            { value: 'Machine Breakdown Repair',    label: 'Machine Breakdown Repair' },
                                            { value: 'Machine Assessment',          label: 'Machine Assessment' },
                                        ]}
                                        label='Job Sheet Type *'
                                        value={eJSType}
                                        onChange={handleSelectChange('eJSType')}
                                        error={formErrors && formErrors['eJSType'] && true}
                                        errorText={formErrors && formErrors['eJSType'] && formErrors['eJSType']}
                                        noClear
                                    />
                                </FormControl>
                            }
                            <DragFileInput
                                id="file"
                                name="file"
                                label='File *'
                                file={file}
                                errorText={formErrors && formErrors['file']}
                                onChange={handleFileChange}
                                cancelOnClick={clearFile}
                                emptyText='No file selected'
                            />
                            <div className="buttonRow">
                                <Typography variant="subtitle2" style={{ flex: 1 }}>
                                    <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                                </Typography>
                                {handleEmailOrder ?
                                    <Button onClick={handleSkip} variant="outlined" color="default">Skip</Button>
                                    :
                                    <Button onClick={handleFilesClose} variant="outlined" color="default">Cancel</Button>
                                }
                                <Button onClick={handleFilesSuccess} color="primary" autoFocus variant="contained">Upload</Button>
                            </div>
                        </>
                    }
                    
                </React.Fragment>
            )}
            <AddDespachDialog
                open={state.showNoDespatchDialog}
                orderId={orderId}
                closeDialog={() => { setState(state => ({
                    ...state,
                    showNoDespatchDialog: false
                })); getOrder() }}
            />
        </React.Fragment>
    );
}