import React from "react";
import { Grid, Button, Typography, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AllIcon from "Components/Common/Icons/AllIcon";
import IconHelper from "Helpers/IconHelper";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import API from "API";
import { connect } from "react-redux";
import { deployConfirmation } from "Actions/Confirmation/Confirmation";
import DataTable from "Components/Common/DataTables/CiDataTable";
import InputHelper from "Helpers/InputHelper";
import { colors } from "Helpers/ColourHelper";
import _ from "lodash";

const initialState = ({contact}) => ({
    mailingLists: [],
    marketing: [],
    unsubscribed: contact.contact_email_opt_out || contact?.customer?.cust_unsubscribed || _.find(contact.addresses, a => a.address_unsubscribed),
    isLoading: true,
    access: {
        'create-mailing-lists': false,
        'view-mailing-lists': false,
        'inactivate-mailing-lists': false,
        'reactivate-mailing-lists': false,
    }
})

class Marketing extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
        this.inputHelper = new InputHelper(this);
    }

    componentDidMount = () => {
        this.getData();
        this.inputHelper.handleSetAccess(_.keys(this.state.access));
    }

    getData = () => {
        Promise.all([
            API.get(`/marketing/campaigns/mailingLists`, { params: {contactId: this.props.contact.contact_id, status: 'Active'} }),
            API.get(`/customers/${this.props.contact.contact_customer_id}/marketing`)
        ]).then(([ml, marketing]) => {
            this.setState({
                mailingLists: ml.data,
                marketing: marketing.data,
                isLoading: false
            })
        })
    }

    unsubscribe = () => {
        API.post(`/customers/contacts/${this.props.contact.contact_id}/unsubscribe`).then(() => {
            this.setState({
                unsubscribed: true
            })
        })
    }

    resubscribe = () => {
        API.post(`/customers/contacts/${this.props.contact.contact_id}/resubscribe`).then(() => {
            this.setState({
                unsubscribed: false
            })
        })
    }

    removeContactFromMailingList = ({ml_id}) => {
        API.post(`/marketing/campaigns/mailingLists/${ml_id}/contacts/0/remove`, {contact_id: this.state.searchFormData.contactId})
        .then(this.getData);
    }

    render () {
        const { mailingLists, marketing, unsubscribed, isLoading, access } = this.state;

        if (isLoading) {
            return <LoadingCircle />
        }

        if (unsubscribed) {
            return (
                <Grid container spacing={2} style={{
                    justifyContent: 'center'
                }}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            style={{
                                textAlign: 'center'
                            }}
                        >This Contact Has Unsubscribed From All Marketing Emails</Typography>
                    </Grid>
                    {(this.props.contact?.customer?.cust_unsubscribed || _.find(this.props.contact.addresses, a => a.address_unsubscribed)?.address_unsubscribed) ? 
                        <></> :
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={()=>this.props.deployConfirmation(
                                    'Are you sure you want to resubscribe this contact to all marketing emails?',
                                    'Resubscribe Contact',
                                    this.resubscribe
                                )}
                            >
                                Resubscribe To Marketing Emails
                            </Button>
                        </Grid>
                    }
                </Grid>
            )
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Mailing Lists ({mailingLists.length})</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <DataTable
                                config={{
                                    key: 'ml_id'
                                }}
                                rows={mailingLists}
                                columns={[
                                    {
                                        heading: 'Date',
                                        field: 'ml_date',
                                        sizeToContent: true,
                                        dataRef: 'ml_date',
                                        fieldFormat: 'clenydate'
                                    },
                                    {
                                        heading: 'Reference',
                                        field: 'ml_reference',
                                        dataRef: 'ml_reference',
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Name',
                                        field: 'ml_name',
                                        dataRef: 'ml_name',
                                        important: true,
                                        truncate: true
                                    },
                                    {
                                        heading: 'Contact Adding',
                                        field: i => {
                                            switch (i.ml_logic_type) {
                                                case 'manual':
                                                    return 'Manual';
                                                case 'CustomerMarketing':
                                                    return 'Customer Marketing';
                                                case 'ServicePlan':
                                                    return (parseInt(i.ml_logic_data) ? 'With' : 'Without ') + ' Service Plan';
                                                default:
                                                    return 'Unknown';
                                            }
                                        },
                                        sizeToContent: true,
                                        dataRef: 'ml_logic_type',
                                    },
                                    {
                                        heading: '',
                                        field: i => 
                                            i.ml_status === 'Active' ?
                                                <AllIcon 
                                                    icon={['Error', 'Warning'].includes(i.ml_contacts_status) ? IconHelper.warning : IconHelper.true}
                                                    color={['Error', 'Warning'].includes(i.ml_contacts_status) ? ( i.ml_contacts_status === 'Error' ? colors.red : colors.orange) : colors.green}
                                                    tooltip={['Error', 'Warning'].includes(i.ml_contacts_status) ? ( i.ml_contacts_status === 'Error' ? 'Mailing list has invalid contacts' : 'Mailing list has warnings' ) : 'Mailing list has no issues'}
                                                    noMargin
                                                /> :
                                                <AllIcon
                                                    icon={IconHelper.false}
                                                    color={colors.disabled}
                                                    tooltip='Mailing list is inactive'
                                                    noMargin
                                                />
                                                ,
                                        sizeToContent: true,
                                    },
                                    {
                                        actions: i => ([
                                            {
                                                name: 'Remove From Mailing List',
                                                icon: IconHelper.false,
                                                onClick: (j) => this.props.deployConfirmation('Are you sure you want to remove this contact from this mailing list?', 'Remove Contact From Mailing List', ()=>{this.removeContactFromMailingList(j)}),
                                            },
                                            {
                                                name: 'View',
                                                link: `/marketing/mailingLists/${i.ml_id}`,
                                                icon: IconHelper.view,
                                                disabled: !access['view-mailing-lists'],
                                            },
                                        ])
                                    }
                                ]}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Customer Marketing ({marketing.length})</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <DataTable
                                config={{
                                    key: 'ctcm_id'
                                }}
                                rows={marketing}
                                columns={[
                                    {
                                        heading: 'Category',
                                        field: 'customer_marketing.cm_category',
                                        dataRef: 'customer_marketing.cm_category',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Sub Category',
                                        field: 'customer_marketing.cm_name',
                                        dataRef: 'customer_marketing.cm_name',
                                    }
                                ]}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
                <Grid item xs={12} className="buttonRow">
                    <Button
                        onClick={
                            ()=> this.props.deployConfirmation(
                                <>Are you sure you want to unsubscribe this contact from all marketing emails? <br/>Please Note: they will still receive system emails from sales and returns</>,
                                "Unsubscribe Contact",
                                this.unsubscribe
                            )
                        }
                        variant="contained"
                        color="secondary"
                    >
                        <AllIcon icon={IconHelper.ban} white />
                        Unsubscribe
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (content, title, callback) => dispatch(deployConfirmation(content, title, callback))
});

export default connect(null, mapDispatchToProps)(Marketing);