import React, { Component } from 'react';
import { connect }          from 'react-redux';
import _                    from 'lodash';
import moment               from 'moment';
import API                  from 'API';

import { statusColour, colors }                      from 'Helpers/ColourHelper';
import { orderStatusIcons, orderPaymentMethodIcons } from 'Helpers/SalesHelper';
import icons                                         from 'Helpers/IconHelper';

import DataTable                   from 'Components/Common/DataTables/CiDataTable';
import SnackBar                    from 'Components/Common/SnackBars/SnackBar';
import PaddedPaper                 from 'Components/Common/Paper/PaddedPaper';
import FALightIcon                 from 'Components/Common/Icons/FontAwesome/FALightIcon';
import AllIcon                     from 'Components/Common/Icons/AllIcon';
import StickyNotes                 from 'Components/Common/StickyNotes/StickyNotes';
import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog    from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';
import OrderSearchBlock            from 'Components/Sales/Order/OrderSearch/OrderSearchBlock';

import { Typography, Tooltip, IconButton, Grid } from '@material-ui/core';
import { withStyles }                                  from '@material-ui/core/styles';

import { deployDialog }                     from 'Actions/Dialog/Dialog';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';
import { downloadS3File }                                               from 'Functions/MiscFunctions';
import JobSnapshot from 'Components/Engineers/Jobs/Misc/JobSnapshot';

const initialState = () => ({
    access: {
        viewOrder:      false,
        orderSearch:    false
    },
    order:              {},
    keywords:           '',
    status:             'All',
    dateFrom:           null,
    dateTo:             null,
    searchResults:      {},
    orderStats:         [],
    isLoading:          false,
    emailDialogOpen:    false,
    approvalCount:      '',
    completedCount:     '',
    cancelledCount:     ''
});

const styles = () => ({
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    },
    custName: {
        width:200, 
        overflow:'hidden',
        textOverflow: "ellipsis", 
        '&:hover':{cursor: 'pointer'}
    }
});

class OrderSearch extends Component {
    constructor(props) {
        super(props);    
        this.timeout = false;
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Sales:OrderSearch';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        this.getSearchData();
        this.getInitialData();
    }

    componentWillUnmount = () => {
        if(this.timeout) 
            clearTimeout(this.timeout)
    }

    getInitialData = () => {

        this.setState({
            isLoading: true
        }, () => {
            Promise.all([
                API.get('/staff/my/access/check/customer-order-search'),
                API.get('/staff/my/access/check/view-customer-order'),
                API.get('/staff/my/access/check/repeat-customer-order'),
            ])
            .then(([orderSearchAccess, viewOrderAccess, repeatOrderRes]) => {

                this.setState({
                    ...this.state,
                    access: {
                        viewOrder:           (viewOrderAccess.data   && viewOrderAccess.data.has_access)   || false,
                        customerOrderSearch: (orderSearchAccess.data && orderSearchAccess.data.has_access) || false,
                        repeatOrder:         ( repeatOrderRes.data   && repeatOrderRes.data.has_access )   || false
                    },
                });
            });
        });
    }

    getSearchData = () => {
        const params = {
            keywords: this.state.keywords,
            status: this.state.status,
            dateFrom: this.state.dateFrom !== null ? moment(this.state.dateFrom).format('YYYY-MM-DD') : null,
            dateTo: this.state.dateTo !== null ? moment(this.state.dateTo).format('YYYY-MM-DD') : null,
            slim: 1
        }
        const props = {
            cancellation: true
        }
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([
                API.get('/sales/orders/search', {params, props})
            ])
            .then(([orders]) => {
                _.map(orders.data, o => {
                    // Status Colour
                    o.color = statusColour(o.s);
                    o.icon = orderStatusIcons(o.s);

                    switch(o.s){
                        case 'Awaiting Approval':
                            o.toolTip1 = 'Order Acknowledgment';
                            o.toolTip2 = 'Awaiting Approval';
                        break;
                        case 'Confirmed':
                            o.toolTip1 = 'Order Confirmed'
                        break;
                        case 'Awaiting Payment':
                            o.toolTip1 = 'Awaiting Payment';
                        break;
                        case 'Payment Confirmed':
                            o.toolTip1 = 'Payment Confirmed';
                        break;
                        case 'Picking':
                            o.toolTip1 = 'Awaiting Picking';
                        break;
                        case 'Awaiting Despatch':
                            o.toolTip1 = 'Awaiting Despatch';
                        break;
                        case 'Invoice':
                            o.toolTip1 = 'Awaiting Invoice';
                        break;
                        case 'Completed':
                            o.toolTip1 = 'Order Completed';
                            o.toolTip2 = moment(o.d).format('DD/MM/YYYY');
                        break;
                        case 'Declined':
                            o.toolTip1 = 'Order Declined';
                            o.toolTip2 = moment(o.d).format('DD/MM/YYYY');
                        break;
                        case 'Cancelled':
                            o.toolTip1 = 'Order Cancelled';
                            o.toolTip2 = moment(o.d).format('DD/MM/YYYY');
                        break;
                        case 'Awaiting Invoice Completion':
                            o.toolTip1 = 'Awaiting Invoice Completion';
                        break;
                        default:
                        break;
                    }

                    _.map(o.in, i => {
                        i.color     = (i.c) ? colors.green : colors.yellow;
                        i.toolTip   = (i.m === 'Card') ? 'Barclaycard' : i.m;
                        i.icon      = orderPaymentMethodIcons(i.m);
                    });

                });
                this.setState({
                    ...this.state,
                    searchResults: orders.data,
                    isLoading: false
                });
            });
        });
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.savePageState()
            this.setSearch();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
            status: 'All',
            dateFrom: null,
            dateTo: null,
        }, 
        () => {
            this.getSearchData();
        });
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchData, 200);
    }

    handleDownloadOpen = orderId => this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md');

    handleEmailOpen = orderId => this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />, 'Email Previous Documents', '', 'md');

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    handleDateChange = name => date => {
        this.setState({
            [name]: date
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    handleShowStickyNote = orderId => { this.props.deployDialog(<StickyNotes orderId={orderId} dialog/>, 'Sticky Notes', null,'xs') }

    handleSetSearch = props => {
        this.setState({
            ...props
        },this.getSearchData)
    }

    render() {
        const { access, isLoading } = this.state;
        const { classes } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Order Search
                    </Typography>
                </Grid>
                <React.Fragment>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <OrderSearchBlock disableSites  callBack={this.handleSetSearch} formData={{
                                keywords: this.state.keywords,
                                status:   this.state.status,
                                dateFrom: this.state.dateFrom,
                                dateTo:   this.state.dateTo,
                            }} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <DataTable  
                                config={{
                                    key: 'order_id',
                                    pagination: true,
                                    alternatingRowColours: true,
                                    responsiveImportance: true,
                                    isLoading: isLoading
                                }}
                                columns={[
                                    {
                                        heading: 'Order Date',
                                        field: rowData => moment(rowData.d).format("DD/MM/YYYY"),
                                        important: true,
                                        sizeToContent: true,
                                    },
                                    {
                                        actionsCustomHeader:    true,
                                        main:              true,
                                        alignment:              'left',
                                        heading:                'Reference',
                                        actions: rowData => {
                                            let ret = [
                                                { label: rowData.r,         link: `/sales/order/view/${rowData.i}`,         type:'plainLink'},
                                                { label: rowData.q,         link: `/sales/quotation/view/${rowData.qi}`,    type:'plainLink', class: "textSecondary"}
                                            ];
                                            if (rowData.crn.length > 0) {
                                                _.each(rowData.crn, r => {
                                                    ret.push({
                                                        label: r.r,
                                                        link: `/returns/creditnote/${r.i}`,
                                                        type:'plainLink', 
                                                        color: colors.red
                                                    })
                                                })
                                            }
                                            if (rowData.cr.length > 0) {
                                                _.each(rowData.cr, r => {
                                                    ret.push({
                                                        label: r.r,
                                                        link: `/returns/customerReturn/${r.i}`,
                                                        type:'plainLink', 
                                                        color: colors.red
                                                    })
                                                })
                                            }
                                            return ret;
                                        }
                                    },
                                    {
                                        heading: 'Invoice(s)',
                                        field:   rowData => _.map(rowData.inv, i =><span>{i.n} {i.si > 0 && `(${i.si})`}<br/></span>),
                                    },
                                    // {
                                    //     heading: 'Discount',
                                    //     field: rowData => rowData.t === 'With Discount',
                                    //     important: true,
                                    //     sizeToContent: true,
                                    //     fieldFormat: 'boolean',
                                    //     tooltip: rowData => rowData.t,
                                    //     alignment: 'center'
                                    // },
                                    {
                                        heading:    'Customer',
                                        field:      rowData =>  <Tooltip
                                                                    title={
                                                                            <Typography variant="body2">
                                                                                <b>Delivery Address:</b><br />
                                                                                {rowData.da.split('\n').map((item, key) => {
                                                                                    return  <span key={key}>
                                                                                                {item}{rowData.da.split('\n').length === key + 1 ? null : <br/>}
                                                                                            </span>
                                                                                })}
                                                                            </Typography>
                                                                    }
                                                                    placement="left"
                                                                >
                                                                    <div className={classes.custName} onClick={()=>{ this.props.history.push(`/customers/view/${rowData.ci}`); }}>
                                                                        {rowData.cn}
                                                                    </div>
                                                                </Tooltip>,
                                        important:  true,
                                        dataRef:    'cn',
                                        truncate:   true
                                    },
                                    {
                                        heading: 'Status',
                                        field: rowData =>   <>
                                                                <Tooltip
                                                                    title={
                                                                        <div>
                                                                            <Typography variant="body2">{rowData.toolTip1}</Typography>
                                                                            <Typography variant="body2">{rowData.toolTip2}</Typography>
                                                                        </div>
                                                                    }
                                                                    placement="left"
                                                                >
                                                                    <IconButton className={classes.iconButton}>
                                                                        <FALightIcon 
                                                                            icon={`${rowData.icon}`}
                                                                            button
                                                                            style={{
                                                                                color: rowData.color
                                                                            }}
                                                                            noMargin
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>,
                                        important: true,
                                        alignment: 'center',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Files',
                                        field: rowData => 
                                            <Grid container style={{width:'100%'}} spacing={1} xs={12} alignItems='flex-end' justify="center">
                                                {!!rowData.f.ej && 
                                                    <Grid item xs={12} lg={6}>
                                                        <AllIcon 
                                                            icon='user-hard-hat'  
                                                            button 
                                                            noMargin
                                                            tooltip='Engineer Job Sheet'
                                                            onClick={() => {downloadS3File(rowData.f.ej)}}
                                                        />
                                                    </Grid>
                                                }
                                                {!!rowData.f.po && 
                                                    <Grid item xs={12} lg={6}>
                                                        <AllIcon 
                                                            icon={(rowData.f.po.includes('.wav') || rowData.f.po.includes('.x-wav')) ? 'cassette-tape' : 'file-alt' }
                                                            button 
                                                            noMargin
                                                            tooltip='Purchase Order'
                                                            onClick={() => {downloadS3File(rowData.f.po)}}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>,
                                        alignment: 'center',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Total',
                                        field: rowData => ( 
                                                <Tooltip 
                                                    title={
                                                        <div style={{display: 'flex', width: 200}}>
                                                            <div style={{width: '50%', textAlign:'right'}}>
                                                                <Typography variant="body2">Sub Total</Typography>
                                                                <Typography variant="body2">VAT ({rowData.tl.vp}%)</Typography>
                                                                <Typography variant="body2">Total</Typography>
                                                            </div>
                                                            <div style={{width: '50%', textAlign:'right'}}>
                                                                <Typography variant="body2">£{rowData.tl.s}</Typography>
                                                                <Typography variant="body2">£{rowData.tl.v}</Typography>
                                                                <Typography variant="body2">£{rowData.tl.iv}</Typography>
                                                            </div>
                                                        </div>
                                                    } 
                                                    placement="left"
                                                >
                                                    <span>{rowData.tl.iv}</span>
                                                </Tooltip>
                                        ),
                                        important: true,
                                        dataRef: 'tl.iv',
                                        alignment: 'right',
                                        fieldPrefix: '£',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Payment Method',
                                        field: rowData =>   <>
                                                                {_.map(rowData.in, i => 
                                                                    <Tooltip
                                                                        title={<Typography variant="body2">{i.toolTip}</Typography>}
                                                                        placement="left"
                                                                    >
                                                                        <IconButton style={{padding:5}}>
                                                                            <AllIcon 
                                                                                icon={`${i.icon}`}
                                                                                button
                                                                                style={{
                                                                                    color: i.color
                                                                                }}
                                                                                noMargin
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}
                                                            </>,
                                        alignment: 'center',
                                        truncate: true,
                                    },
                                    {
                                        heading:    'Recurring',
                                        field:      rowData => <>
                                            {!!rowData.ri && <Tooltip
                                                                title={<Typography variant="body2">Recurring Invoice</Typography>}
                                                                placement="left"
                                                            >
                                                                <IconButton style={{padding:5}}>
                                                                    <AllIcon 
                                                                        icon={'sync-alt'}
                                                                        button
                                                                        noMargin
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>}
                                        </>,
                                        alignment: 'center',
                                        sizeToContent: true,
                                        important: false
                                    },
                                    {
                                        heading: 'Jobs',
                                        field:      rowData => <>
                                            {rowData?.ej && 
                                                <Tooltip
                                                    title={<JobSnapshot jobRef={rowData?.ej?.ref} forms={rowData?.ej?.f} />}
                                                    placement="left"
                                                >
                                                    <IconButton className={classes.iconButton} onClick={() => this.props.history.push(`/engineers/jobs/view/${rowData.ej?.id}`) }>
                                                        <AllIcon 
                                                            icon={`user-helmet-safety`}
                                                            button
                                                            noMargin
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </>,
                                        alignment: 'center',
                                        sizeToContent: true,
                                        important: false
                                    },
                                    {
                                        heading:    '',
                                        actions: rowData => {

                                            let actions = [
                                                {name: 'Sticky Notes', icon: icons.stickyNote, onClick: () => this.handleShowStickyNote(rowData.i), color: (rowData.n[1] && colors.yellow), hide: !rowData.n[0]},
                                                {name: 'View', icon: 'search', link: '/sales/order/view/' + rowData.i},
                                                {
                                                    type: 'actionMenu',
                                                    rows: [
                                                        {
                                                            icon: icons.repeat,
                                                            label: "Repeat Order",
                                                            onClick:() => this.props.history.push(`/sales/order/add/repeat/${rowData.i}`),
                                                            display: true,
                                                            disabled: !access.repeatOrder
                                                        },
                                                        {
                                                            icon: "download",
                                                            label: "Download",
                                                            onClick: () => this.handleDownloadOpen(rowData.i),
                                                            display: true
                                                        },
                                                        {
                                                            icon: "envelope",
                                                            label: "Send Order to Customer",
                                                            onClick: () => this.handleEmailOpen(rowData.i),
                                                            display: true
                                                        },
                                                        {
                                                            icon: "file-search",
                                                            label: "View this Customers Orders",
                                                            onClick: () => this.props.history.push(`/customers/view/${rowData.ci}/Orders`),
                                                            display: true,
                                                            disabled: !access.customerOrderSearch
                                                        }
                                                    ]
                                                }
                                            ];

                                            return actions;
                                        }
                                    },
                                ]}
                                rows={this.state.searchResults}
                            />
                        
                        </PaddedPaper>
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.emailSnackbarOpen}
                            onClose={this.handleEmailSnackbarClose}
                            message='You have successfully sent the email'
                        />
                    </Grid>
                </React.Fragment>
            </Grid>
        );
    }
}



const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deployDialog:       (content, title, variant, size=null) => dispatch(deployDialog(content, title, variant, size)),
    clearPersistence:   (key)                                => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                         => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderSearch));
