import * as keyDownActionTypes from 'Actions/KeyDown/Types/Types';
import uuidv4 from 'uuid/v4';

export const addKeyDown = (f, key = uuidv4() ) => {
    return dispatch => {
        dispatch({type: keyDownActionTypes.KEYDOWN_ADD, func: f, key: key});
    }
}

export const removeKeyDown = ( key = null ) => {
    return dispatch => {
        dispatch({type: keyDownActionTypes.KEYDOWN_REMOVE, key: key});
    }
}

export const clearKeyDown = ( key = null ) => {
    return dispatch => {
        dispatch({type: keyDownActionTypes.KEYDOWN_CLEAR, key: key});
    }
}

export const setKeyDown = ( key = null ) => {
    return dispatch => {
        dispatch({type: keyDownActionTypes.KEYDOWN_SET, key: key});
    }
}

export const unsetKeyDown = ( key = null ) => {
    return dispatch => {
        dispatch({type: keyDownActionTypes.KEYDOWN_UNSET, key: key});
    }
}