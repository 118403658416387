import { Grid, Typography } from '@material-ui/core';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import React from 'react';
import Order from 'Components/Sales/Order/ViewOrder/Order';
import CreditNote from 'Components/CustomerReturns/ViewCreditNote/CreditNote';
import API from 'API';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import IconHelper from 'Helpers/IconHelper';
import InfoIcon from 'Components/Common/Icons/InfoIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import OutcomeRepair from './OutcomeRepair';
import OutcomeWriteOff from './OutcomeWriteOff';

const initialState = () => ({
    isLoading: true,
    creditNote: null,
    order: null
})

class Outcome extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState();  
    }

    componentDidMount(){
        if (!this.props.outcome.cro_outcome_id || this.props.outcome.cro_outcome === 'Repair' || this.props.outcome.cro_outcome === 'Write Off'){
            this.setState({
                isLoading: false
            });
        } else {
            this.props.outcome.cro_outcome === 'Refund' ? this.getCreditNote() :
                this.props.outcome.cro_outcome === 'Replace' && this.getOrder();
        }
    }

    getCreditNote = () => {
        API.get(`/customerReturns/creditNote/${this.props.outcome.cro_outcome_id}`)
        .then(result => {
            this.setState({
                creditNote: result?.data,
                isLoading:  false
            });
        })
    }

    getOrder = () => {
        API.get(`/sales/orders/${this.props.outcome.cro_outcome_id}`)
        .then(result => {
            this.setState({
                order: result?.data,
                isLoading: false
            });
        })
    }

    render(){
        const { isLoading, creditNote, order } = this.state;
        const {cr: {cr_vat_rate}} = this.props;

        if (isLoading){
            return <LoadingCircle/>
        }

        if ( this.props.outcome.cro_outcome === 'Repair' ){
            return (
                <OutcomeRepair
                    cr={this.props.cr}
                    outcome={this.props.outcome}
                />
            )
        }

        if ( this.props.outcome.cro_outcome === 'Write Off' ){
            return (
                <OutcomeWriteOff
                    cr={this.props.cr}
                    outcome={this.props.outcome}
                />
            )
        }

        if (!this.props.outcome.cro_outcome_id){
            return (
                <PaddedPaper>
                    <Typography variant="body1" style={{textAlign: 'center', fontWeight: 'bold'}}>Outcome has not been added yet</Typography>
                </PaddedPaper>
            )
        }

      
        if (creditNote)
            return (
                <CiDataTable
                    rows={[creditNote]}
                    config={{
                        key: 'cn_id',
                    }}
                    columns={[
                        {
                            heading: 'Credit Date',
                            field: 'cn_date',
                            sizeToContent: true,
                            alignment: 'center',
                            fieldFormat: 'clenydate',
                        },
                        {
                            heading: 'Reference',
                            field: i => <>{i.cn_reference }-0{i.cn_reference_amendment}</>,
                        },
                        {
                            heading: 'Discount',
                            field: i => i.cn_type === 'With Discount',
                            fieldFormat: 'boolean',
                            sizeToContent: true,
                            alignment: 'center'
                        },
                        {
                            heading: 'Customer',
                            field: i => 
                                <>
                                    {i.cn_cust_name}<br/>
                                    {i.cn_delivery_cust_address_name} <InfoIcon info={
                                        <span style={{whiteSpace: 'pre-wrap'}}>
                                            <b>Delivery Address</b><br/>
                                            {i.cn_delivery_cust_address}
                                        </span>
                                    }/>
                                </>,
                        },
                        {
                            heading: 'Total',
                            field: 'cn_total_incl_vat',
                            fieldFormat: 'currency',
                            sizeToContent: true,
                            alignment: 'right',
                            info: rowData => 
                                <div style={{display: 'flex', width: 200}}>
                                    <div style={{width: '50%', textAlign:'right'}}>
                                        <Typography variant="body2">Sub Total</Typography>
                                        <Typography variant="body2">VAT ({parseInt(cr_vat_rate * 100).toFixed(2)}%)</Typography>
                                        <Typography variant="body2">Total</Typography>
                                    </div>
                                    <div style={{width: '50%', textAlign:'right'}}>
                                        <Typography variant="body2">£{rowData.cn_sub_total}</Typography>
                                        <Typography variant="body2">£{rowData.cn_vat}</Typography>
                                        <Typography variant="body2">£{rowData.cn_total_incl_vat}</Typography>
                                    </div>
                                </div>
                        },
                        {
                            actions: i => ([
                                {
                                    name: 'View',
                                    link: `/returns/creditnote/${i.cn_id}`,
                                    icon: IconHelper.view
                                }
                            ])
                        }
                    ]}
                />
            )

        if (creditNote)
            return <CreditNote cn={creditNote} />

        return (
            <CiDataTable
                rows={[order]}
                config={{
                    key: 'order_id',
                }}
                columns={[
                    {
                        heading: 'Order Date',
                        field: 'order_date',
                        sizeToContent: true,
                        alignment: 'center',
                        fieldFormat: 'clenydate',
                    },
                    {
                        heading: 'Reference',
                        field: i => <>{i.order_reference }-{i.order_reference_amendment}</>,
                    },
                    {
                        heading: 'Discount',
                        field: i => i.order_type === 'With Discount',
                        fieldFormat: 'boolean',
                        sizeToContent: true,
                        alignment: 'center'
                    },
                    {
                        heading: 'Customer',
                        field: i => 
                            <>
                                {i.order_cust_name}<br/>
                                {i.order_delivery_cust_address_name} <InfoIcon info={
                                    <span style={{whiteSpace: 'pre-wrap'}}>
                                        <b>Delivery Address</b><br/>
                                        {i.order_delivery_cust_address}
                                    </span>
                                }/>
                            </>,
                    },
                    {
                        heading: 'Total',
                        field: 'order_total_incl_vat',
                        fieldFormat: 'currency',
                        sizeToContent: true,
                        alignment: 'right',
                        info: rowData => 
                            <div style={{display: 'flex', width: 200}}>
                                <div style={{width: '50%', textAlign:'right'}}>
                                    <Typography variant="body2">Sub Total</Typography>
                                    <Typography variant="body2">VAT ({parseInt(cr_vat_rate * 100).toFixed(2)}%)</Typography>
                                    <Typography variant="body2">Total</Typography>
                                </div>
                                <div style={{width: '50%', textAlign:'right'}}>
                                    <Typography variant="body2">£{rowData.order_sub_total}</Typography>
                                    <Typography variant="body2">£{rowData.order_vat}</Typography>
                                    <Typography variant="body2">£{rowData.order_total_incl_vat}</Typography>
                                </div>
                            </div>
                    },
                    {
                        actions: i => ([
                            {
                                name: 'View',
                                link: `/sales/order/view/${i.order_id}`,
                                icon: IconHelper.view
                            }
                        ])
                    }
                ]}
            />
        )
    }
}

export default Outcome;