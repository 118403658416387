import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher'
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import OldSearch from './Tabs/OldSearch'
import ViewEmails from 'Components/Common/Email/ViewEmails/ViewEmails'

export default function(props){
    return (
        <Grid container spacing={1} >
            <Grid item xs={12}>
                <Typography variant='h6'>
                    Email Search
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TabBarSwitcher
                    tabs={[
                        {
                            label:   'Email Search (System)',
                            content: <ViewEmails allEmails hideBouncedWarning />,
                        },
                        {
                            label:   'Email Search (Send Files)',
                            content: <OldSearch />,
                        },
                    ]}
                />
            </Grid>
        </Grid>
    )
}