import React, {Component} from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import DataTable from '../../Common/DataTables/CiDataTable';

import { deployDialog } from 'Actions/Dialog/Dialog';
import GenerateSerialNumberDialog from './GenerateSerialNumberDialog';

class PartTypes extends Component {

    handleGenerateSerialNumbers = rowData => {
        let component = <GenerateSerialNumberDialog 
            modelTypeId={rowData.pmt_id} 
            nextUniqueNumber={rowData.nextUniqueNumber} 
            modelTypes={this.props.modelTypes} 
            buildLocations={this.props.buildLocations} 
            years={this.props.years}
            yearIssued={rowData.yearIssued}
            loadComponentData={this.props.loadComponentData} 
        />

        this.props.deployDialog(component, 'Generate Serial Number', '', 'sm');
    }

    render(){
        const { modelTypes } = this.props;
        return (
            <Grid item xs={12}>
                <DataTable  
                    config={{
                        key: 'pmt_id',
                        alternatingRowColours: true,
                    }}
                    columns={[
                        {
                            heading:       'Model Type',
                            field:         rowData => rowData.pmt_code,
                            sizeToContent: true,
                            filter:        'custom',
                            filterMod:     {
                                                dataRef: (search, rowData) => {
                                                    return `${rowData.pmt_code}|${rowData.part?.part_number}|${rowData.next}`.toLowerCase().includes(search.toLowerCase()) > 0;
                                                },
                                                label:     'Search',
                                                styleGrid: {width: '20%'}
                                            }
                        },
                        {
                            heading:       'Part Number',
                            field:         rowData => rowData.part?.part_number ?? '-',
                            sizeToContent: true
                        },
                        {
                            heading:       'Last Used',
                            field:         rowData => rowData.last ?? '-',
                            sizeToContent: true
                        },
                        {
                            heading: 'Next Used',
                            field:   rowData => rowData.next ?? '-',
                        },
                        {
                            actions: rowData => {
                                return [
                                    {name: 'Generate Serial Numbers', icon: 'sync', onClick: () => this.handleGenerateSerialNumbers(rowData), disabled: rowData.part.part_status !== 'Active' || rowData.part.part_locked},
                                    {name: 'View Serial Numbers', icon: 'search', link: `/parts/serial-numbers/${rowData.pmt_id}`}
                                ]
                            }
                        }
                    ]}
                    rows={modelTypes}
                />
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, variant, size) => {
            dispatch(deployDialog(content, title, variant, size))
        }
    }
}

export default connect(null, mapDispatchToProps)(PartTypes);