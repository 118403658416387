import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import API from '../../../../API';
import DataTable from '../../../Common/DataTables/DataTable';
import {colors} from 'Helpers/ColourHelper';

class DesignFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
        }
    }

    componentDidMount(){
        // this.getPartFiles();
    }

    getPartFiles = () => {
        API.get(`/parts/${this.props.partId}/files`, {
            params: {
              type: 'Design File'
            }
          })
        .then((res) =>  {
            this.setState({
                rows: res.data,
                open: true
            });
        })
    }

    downloadFile = rowData => {
        window.open(rowData.download_url, '_blank');
    }

    handleOpen = () => {
        if(!this.state.open){
            this.getPartFiles();
        } else {
            this.setState({
                open: false
            });
        }
    }

    render() {

        const { open } = this.state;

        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={this.handleOpen}>
                    <Typography>Design Files</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {open &&
                        <>
                            {this.state.rows.length > 0 ? 
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={12}>
                                        <DataTable 
                                            config={{
                                                key: 'part_doc_id',
                                                pagination: false,
                                                plainHeader: true,
                                                rowProps: row => {
                                                    return ({
                                                        style: {
                                                            color: row.default === 'Yes' && colors.green
                                                        }
                                                    });
                                                }
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Version',
                                                    field: row => 'v'+row.part_doc_version
                                                },
                                                {
                                                    heading: 'File',
                                                    field: row => row.part_doc_url
                                                },
                                                {
                                                    actions: row => {
                                                        return [
                                                            {name: 'Download', icon: 'download', onClick: this.downloadFile},
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={this.state.rows}
                                        /> 
                                    </Grid>
                                </Grid>
                            :
                                <Typography>There are 0 Design Files found.</Typography>
                            }
                        </>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default DesignFiles;
