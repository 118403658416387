import React, { Component } from 'react';
import { deploySnackBar }   from 'Actions/SnackBar/SnackBar';
import { connect }          from 'react-redux';
import { Redirect }         from 'react-router-dom';
import _                    from 'lodash';
import moment               from 'moment';
import API                  from 'API';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { pdfFromBase64 }          from 'Helpers/PDFHelper';
import { colors }                 from 'Helpers/ColourHelper';

import LoadingCircle          from 'Components/Common/LoadingCircle/LoadingCircle';
import LinkedPartsDialog      from 'Components/Sales/Order/AddOrder/LinkedPartsDialog';
import PaddedPaper            from 'Components/Common/Paper/PaddedPaper';
import ConfirmationDialog     from 'Components/Common/Dialogs/ConfirmationDialog';
import { FullScreenDialog }   from 'Components/Common/Dialogs/FullScreenDialog';
import SnackBar               from 'Components/Common/SnackBars/SnackBar';
import ViewPart               from 'Components/Parts/ViewPart/ViewPart';
import AddPartEntryRow        from 'Components/Sales/AddPartEntryRow';
import DeliveryDetails        from 'Components/Sales/CustomerDeliveryDetails';
import CustomerDetails        from 'Components/Sales/CustomerDetails';
import QuoteDetails           from 'Components/Sales/Details';
import QuotePartItems         from 'Components/Sales/PartItems';
import QuoteTotals            from 'Components/Sales/Totals';
import WarrantyDialog         from 'Components/Sales/WarrantyDialog';
import BackButton             from 'Components/Common/Buttons/BackButton';
import OrderPartDetailsButton from 'Components/Sales/OrderPartDetailsButton';
import DataTable              from 'Components/Common/DataTables/CiDataTable';
import AddPackageRow          from 'Components/Sales/AddPackageRow';
import AddPromotionDetails    from 'Components/Sales/AddPromotionDetails';

import {Button, Dialog, DialogContent, DialogTitle, Grid, Typography, TableCell, TableBody, TableRow, Table, TableHead } from '@material-ui/core'
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';

const initialState = {
    cq: {},
    formData:  {
        salesPackages: [],
        // Customer
        paymentTerms: '',
        customerContact: '',
        customerPhone: '',
        customerMobile: '',
        customerEmail: '',
        // Delivery Customer
        deliveryCustomerAddressType: '',
        deliveryCustomerAddress: '',
        deliveryCustomerContact: '',
        deliveryCustomerPhone: '',
        deliveryCustomerMobile: '',
        deliveryCustomerEmail: '',
        // Part Rows
        partRows: [],
        subTotal: '',
        vat: '',
        overallPrice: '',
        customerVatRateId: '',
        customerVatRate: '',
        customerVatDecimal: '',
        customerVatPercentage: '',
        customerDiscountRate: '',
        notes: '',
        previewMode: false,
        promotions: []
    },
    selectedPartRow: {
        partImage: '',
        part: '',
        partDiscount: 0,
        quantity: 0,
        unitPrice: 0,
        unitDiscount: 0,
        newUnitPrice: 0,
        subTotal: 0,
        discountRate: 0,
        discountTotal: 0,
        totalPrice: 0,
        warrantyId: '',
        warrantyDuration: '',
        warrantyName: '',
        notes: ''
    },
    dialogRowData: {
        partImage: '',
        part: '',
        partDiscount: 0,
        quantity: 0,
        unitPrice: 0,
        unitDiscount: 0,
        newUnitPrice: 0,
        subTotal: 0,
        discountRate: 0,
        discountTotal: 0,
        totalPrice: 0,
        warrantyId: '',
        warrantyName: '',
        warrantyDuration: '',
        notes: ''
    },
    selectedWarrantyTerm: {
        id: '',
        name: '',
        duration: ''
    },
    lists: {
        customerAddressList: [],
        customerContactList: [],
    },
    customer: {},
    customerContacts: {},
    deliveryCustomerContacts: {},
    customerAddresses: [],
    customerContactList: [],
    registeredContactList: [],
    partList: [],
    allPartsData: [],
    warrantyTerms: [],
    formErrors: [],
    warrantyUpdate: false,
    warrantyDialogOpen: false,
    warrantyDialogClose: false,
    viewPartDialogOpen: false,
    updatePartDialogOpen: false,
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: false,
    redirect: false,
    redirectID: 0
}

class AmendQuotation extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.updatePartSelectQuantity = React.createRef();
        this.partSelectQuantity = React.createRef();
    }

    componentDidMount(){
        this.getQuote();
        this.populateDropdowns();
    }

    getQuote = () => {
        this.setState({
            isLoading: true
        },
        () => {
            API.get('/sales/quotations/' + this.props.match.params.id)
            .then(result => {
                if(result.data) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            customerContact: result.data.quote_cust_contact_id,
                            customerPhone: result.data.quote_cust_contact_phone,
                            customerMobile: result.data.quote_cust_contact_mobile,
                            customerEmail: result.data.quote_cust_contact_email,
                            deliveryCustomerAddressType: result.data.quote_delivery_cust_address_type,
                            deliveryCustomerAddress: result.data.quote_delivery_cust_address_id,
                            deliveryCustomerContact: result.data.quote_delivery_cust_contact_id,
                            deliveryCustomerPhone: result.data.quote_delivery_cust_contact_phone,
                            deliveryCustomerMobile: result.data.quote_delivery_cust_contact_mobile,
                            deliveryCustomerEmail: result.data.quote_delivery_cust_contact_email,
                            notes: result.data.quote_notes,
                            salesPackages: JSON.parse(result.data.quote_packages),
                            promotions: _.map(result.data.promotions, i=> i.p_id)
                        },
                        cq: result.data
                    },
                    () => {
                        API.get(`/customers/${this.state.cq.quote_cust_id}`)
                        .then(custRes => {
                            let registeredAddress = custRes.data.registered_address;
                            let customerAddresses = [];
                            customerAddresses = customerAddresses.concat(   custRes.data.account_addresses, 
                                                                            custRes.data.trading_addresses,
                                                                            custRes.data.site_addresses,
                                                                            [custRes.data.registered_address]);
                            let addresses = [];
                            switch(this.state.formData.deliveryCustomerAddressType){
                                case 'Registered':
                                    addresses = [custRes.data.registered_address]
                                break;
                                case 'Accounts':
                                    addresses = custRes.data.account_addresses
                                break;
                                case 'Trading':
                                    addresses = custRes.data.trading_addresses
                                break;
                                case 'Site':
                                    addresses = custRes.data.site_addresses
                                break;
                                default:
                                break;
                            }
                            let customerAddressList = _.map(addresses, (el) => {
                                return _.assign({
                                    value: el.address_id,
                                    label: `${el.address_name} - ${el.address_line_one}, ${el.address_town}, ${el.address_postcode}`,
                                    address: el
                                });
                            });

                            let address = _.find(customerAddresses, { 'address_id': this.state.formData.deliveryCustomerAddress });
                            let contact = _.find(address.active_contacts, { 'contact_id': result.data.quote_cust_contact_id });
                            let deliveryContact = _.find(address.active_contacts, { 'contact_id': result.data.quote_delivery_cust_contact_id });
                            let customerContactList = _.map(address.active_contacts, (el) => {
                                return _.assign({
                                    value: el.contact_id,
                                    label: el.contact_name
                                });
                            });
                            let registeredContactList = _.map(registeredAddress.active_contacts, (el) => {
                                return _.assign({
                                    value: el.contact_id,
                                    label: el.contact_name
                                });
                            });

                            const customerDiscountRate = custRes.data.discount_name && custRes.data.discount_name.default_discount_value.cdv_percentage;

                            let partRows = [];
                            result.data.quote_details.forEach((row, idx) => {
                                let discountRate = (this.state.cq.quote_type === 'With Discount' && row.part && customerDiscountRate) ? row.qd_discount_rate : 0;
                                
                                /* Work out decimal */
                                let discountDecimal = (discountRate / 100);

                                /* Discount amount for 1 unit */
                                let unitDiscount = (row.qd_unit_price * discountDecimal).toFixed(2);
                                let newUnitPrice = (row.qd_unit_price - unitDiscount).toFixed(2);

                                /* Subtotal = before discount */
                                let subTotal = (row.qd_unit_price * row.qd_quantity).toFixed(2);
                                let discountTotal = (unitDiscount * row.qd_quantity).toFixed(2);

                                /* totalPrice = after discount */
                                let totalPrice = (newUnitPrice * row.qd_quantity).toFixed(2);

                                partRows.push({
                                    partImage: row.part && row.part.default_image && row.part.default_image.library_thumbnail_url && row.part.default_image.thumbnail_file_path,
                                    partDiscount: row.part && row.part.part_discount,
                                    part: row.qd_part_id,
                                    partNumber: row.part.part_number,
                                    partDescription: row.part.part_description,
                                    quantity: row.qd_quantity,
                                    unitPrice: row.qd_unit_price,
                                    unitDiscount,
                                    newUnitPrice,
                                    subTotal: subTotal,
                                    discountRate: discountRate,
                                    discountTotal: discountTotal,
                                    totalPrice: totalPrice,
                                    warrantyId: row.qd_warranty_id,
                                    warrantyName: row.qd_warranty_name,
                                    warrantyDuration: row.qd_warranty_duration,
                                    notes: row.qd_notes
                                });
                            });

                            this.setState({
                                lists: {
                                    ...this.state.lists,
                                   customerAddressList: customerAddressList,
                                   customerContactList: customerContactList,
                                },
                                formData:{
                                    ...this.state.formData,
                                    partRows: partRows,
                                    paymentTerms: custRes.data.payment_term && custRes.data.payment_term.payment_term_name,
                                    customerVatRateId: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_value_id,
                                    customerVatRate: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_value_percentage,
                                    customerVatDecimal: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_rate_decimal,
                                    customerVatPercentage: custRes.data.vat_type && custRes.data.vat_type.default_vat_value.vat_rate_percent,
                                    customerDiscountRate: customerDiscountRate,
                                    customerPhone: contact && contact.contact_telephone ? contact.contact_telephone : '',
                                    customerMobile: contact && contact.contact_mobile ? contact.contact_mobile : '',
                                    customerEmail: contact && contact.contact_email ? contact.contact_email : '',
                                    customerAddress: registeredAddress.address_line_one + "\n\r" +
                                                    (registeredAddress.address_line_two ? registeredAddress.address_line_two + "\n\r":'') +
                                                    (registeredAddress.address_line_three ? registeredAddress.address_line_three + "\n\r":'') +
                                                    registeredAddress.address_town + "\n\r" +
                                                    (registeredAddress.county && registeredAddress.county.county_name ? registeredAddress.county.county_name + "\n\r":'') +
                                                    registeredAddress.address_postcode + "\n\r" +
                                                    (registeredAddress.country && registeredAddress.country.country_name ? registeredAddress.country.country_name :''),
                                    deliveryCustomerPhone: deliveryContact && deliveryContact.contact_telephone ? deliveryContact.contact_telephone : '',
                                    deliveryCustomerMobile: deliveryContact && deliveryContact.contact_mobile ? deliveryContact.contact_mobile : '',
                                    deliveryCustomerEmail: deliveryContact && deliveryContact.contact_email ? deliveryContact.contact_email : '',
                                },
                                customer: custRes.data,
                                customerAddresses: customerAddresses,
                                customerContactList: customerContactList,
                                registeredContactList: registeredContactList,
                                customerContacts: registeredAddress.active_contacts,
                                deliveryCustomerContacts: address.active_contacts,
                                isLoading: false
                            },
                            () => {
                                this.calculateOverallTotals();
                            });
                        });
                    });
                }
                else {
                    window.location.href = `/customers/quotation/search/${this.state.cq.quote_cust_id}`;
                }
            });
        });
    }

    populateDropdowns = () => {
        Promise.all([
            API.get('/parts/all' , {params: {active: true, hasPrice: true, use: 'quotation'}}),
            API.get('/parts/warrantyTerms')
        ])
        .then(([partsRes, warrantyRes]) =>  {
            let partList = _.map(partsRes.data, (el) => {
                return _.assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description
                });
            });
            let warrantyTerms = _.map(warrantyRes.data, el => {
                return _.assign({
                    value: el.pwt_id,
                    label: el.pwt_duration !== null ? el.pwt_name + ' (' + el.pwt_duration + ' months)' : el.pwt_name,
                    name: el.pwt_name,
                    duration: el.pwt_duration
                });
            });
            this.setState({
                partList: partList,
                allPartsData: partsRes.data,
                warrantyTerms
            });
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if(fieldName === 'customerContact'){
                let contact = this.state.formData.customerContact ? _.find(this.state.customerContacts, { 'contact_id': this.state.formData.customerContact }) : null;
                this.setState({
                    formData:{
                        ...this.state.formData,
                        customerPhone: contact && contact.contact_telephone ? contact.contact_telephone : '',
                        customerMobile: contact && contact.contact_mobile ? contact.contact_mobile : '',
                        customerEmail: contact && contact.contact_email ? contact.contact_email : '',
                    }
                });
            }
            if(fieldName === 'deliveryCustomerAddressType'){
                let addresses = [];
                if(this.state.formData.deliveryCustomerAddressType){
                    switch(this.state.formData.deliveryCustomerAddressType){
                        case 'Registered':
                            addresses = [this.state.customer.registered_address]
                        break;
                        case 'Accounts':
                            addresses = this.state.customer.account_addresses
                        break;
                        case 'Trading':
                            addresses = this.state.customer.trading_addresses
                        break;
                        case 'Site':
                            addresses = this.state.customer.site_addresses
                        break;
                        default:
                        break;
                    }
                }
                let customerAddressList = _.map(_.filter(addresses,{address_active: 1}), (el) => {
                    return _.assign({
                        value:   el.address_id,
                        label:   `${el.address_name} - ${el.address_line_one}, ${el.address_town}, ${el.address_postcode}`,
                        address: el
                    });
                });

                this.setState({
                    lists: {
                        ...this.state.lists,
                       customerAddressList: customerAddressList, 
                    },
                    formData:{
                        ...this.state.formData,
                        deliveryCustomerAddress: '',
                        deliveryCustomerContact: '',
                        deliveryCustomerPhone: '',
                        deliveryCustomerMobile: '',
                        deliveryCustomerEmail: '',
                    }
                });
            }
            if(fieldName === 'deliveryCustomerAddress'){
                let address = selectedOption ? _.find(this.state.customerAddresses, { 'address_id': selectedOption.value }) : null;

                let customerContactList = _.map(address && address.active_contacts, (el) => {
                    return _.assign({
                        value: el.contact_id,
                        label: el.contact_name
                    });
                });
                this.setState({
                    lists: {
                        ...this.state.lists,
                        customerContactList: customerContactList,
                    },
                    formData:{
                        ...this.state.formData,
                        deliveryCustomerContact: '',
                        deliveryCustomerPhone: '',
                        deliveryCustomerMobile: '',
                        deliveryCustomerEmail: ''
                    },
                    deliveryCustomerContacts: address && address.active_contacts,
                });
            }
            if(fieldName === 'deliveryCustomerContact'){
                let contact = this.state.formData.deliveryCustomerContact ? _.find(this.state.deliveryCustomerContacts, { 'contact_id': this.state.formData.deliveryCustomerContact }) : null;
                this.setState({
                    formData:{
                        ...this.state.formData,
                        deliveryCustomerPhone: contact && contact.contact_telephone ? contact.contact_telephone : '',
                        deliveryCustomerMobile: contact && contact.contact_mobile ? contact.contact_mobile : '',
                        deliveryCustomerEmail: contact && contact.contact_email ? contact.contact_email : '',
                    }
                });
            }
        });
    }

    getPart = partId => {
        let part = _.find(this.state.allPartsData, { 'part_id': partId });

        if(part) {
            let unitPrice = part.price;
            let discountRate = (this.state.cq.quote_type === 'With Discount' && part.part_discount === 'Yes' && this.state.formData.customerDiscountRate) ? this.state.formData.customerDiscountRate : 0;
            
            let selectedPartRow = this.state.selectedPartRow;
            let selectedWarrantyTerm = this.state.selectedWarrantyTerm;

            selectedPartRow = {
                ...selectedPartRow,
                discountRate,
                partImage: part.thumbnail_url,
                part: part.part_id,
                partNumber: part.part_number,
                partDescription: part.part_description,
                partDiscount: part.part_discount,
                quantity: 1,
                unitPrice,
                warrantyId: part.pwt_id,
                warrantyName: part.pwt_name,
                warrantyDuration: part.pwt_duration
            }

            selectedWarrantyTerm = {
                id: part.pwt_id,
                name: part.pwt_name,
                duration: part.pwt_duration
            }

            this.setState({
                ...this.state,
                selectedPartRow,
                selectedWarrantyTerm
            }, () => {
                this.calculatePartSelectTotals();
                this.partSelectQuantity.current.focus();
            });
        }
    }

    handlePartSelect = e => {
        if(e && e.value) {
            let partId = e && e.value;
            this.getPart(partId);
        } else {
            this.setState({
                selectedPartRow: initialState.selectedPartRow
            });
        }
    }

    handlePartSelectRowChange = decimals => e => {
        const { name, value } = e.target;
        let selectedPartRow = this.state.selectedPartRow;
        let newVal = decimals ? parseFloat(value).toFixed(decimals) : value;

        selectedPartRow = {
            ...selectedPartRow,
            [name]: newVal
        };

        this.setState({selectedPartRow}, () => this.calculatePartSelectTotals());
    }

    handlePartSelectRowRemove = () => {
        this.setState({
            selectedPartRow: initialState.selectedPartRow
        });
    }

    calculatePartSelectTotals = () => {
        let selectedPartRow = this.state.selectedPartRow;
        /* Work out decimal */
        let discountDecimal = selectedPartRow.discountRate / 100;

        /* Discount amount for 1 unit */
        let unitDiscount = (selectedPartRow.unitPrice * discountDecimal).toFixed(2);
        let newUnitPrice = (selectedPartRow.unitPrice - unitDiscount).toFixed(2);

        /* Subtotal = before discount */
        let subTotal = selectedPartRow ? (selectedPartRow.unitPrice * selectedPartRow.quantity).toFixed(2) : 0;
        let discountTotal = (unitDiscount * selectedPartRow.quantity).toFixed(2);

         /* totalPrice = after discount */
        let totalPrice = selectedPartRow ? (newUnitPrice * selectedPartRow.quantity).toFixed(2) : 0;

        if(selectedPartRow) {
            selectedPartRow = {
                ...selectedPartRow,
                unitDiscount,
                newUnitPrice,
                subTotal,
                discountTotal,
                totalPrice
            }

            this.setState({selectedPartRow});
        }
    }

    calculateDialogTotals = () => {
        let dialogRowData = this.state.dialogRowData;

        /* Work out decimal */
        let discountDecimal = dialogRowData.discountRate / 100;

         /* Discount amount for 1 unit */
         let unitDiscount = (dialogRowData.unitPrice * discountDecimal).toFixed(2);
         let newUnitPrice = (dialogRowData.unitPrice - unitDiscount).toFixed(2);

         /* Subtotal = before discount */
        let subTotal = dialogRowData ? (dialogRowData.unitPrice * dialogRowData.quantity).toFixed(2) : 0;
        let discountTotal = (unitDiscount * dialogRowData.quantity).toFixed(2);

        /* totalPrice = after discount */
        let totalPrice = dialogRowData ? (newUnitPrice * dialogRowData.quantity).toFixed(2) : 0;

        if(dialogRowData) {
            dialogRowData = {
                ...dialogRowData,
                unitDiscount,
                subTotal,
                newUnitPrice,
                discountTotal,
                totalPrice
            }

            this.setState({dialogRowData});
        }
    }

    handlePartSelectAddRow = () => {
        let selectedPartRow = this.state.selectedPartRow;
        let part = _.find(this.state.allPartsData, {part_id: selectedPartRow.part});

        API.get(`/parts/${selectedPartRow.part}/pickingDocuments`).then(res => {
            if (part.linked_parts.length > 0 || res.data.length > 0) {
                this.displayLinkedParts(selectedPartRow, res.data);
            }

            if(selectedPartRow.part > 0) {
                let partRows = [...this.state.formData.partRows];
                partRows.push(selectedPartRow);

                this.setState({
                    selectedPartRow: initialState.selectedPartRow,
                    formData: {
                        ...this.state.formData,
                        partRows
                    }
                }, () => {
                    this.calculateOverallTotals();
                });
            }
        })
    }

    handleDialogPartSelect = e => {
        if(e && e.value) {
            let partId = e && e.value;
            let part = _.find(this.state.allPartsData, { 'part_id': partId });

            if(part) {
                let discountRate = (this.state.cq.quote_type === 'With Discount' && this.state.formData.customerDiscountRate) ? this.state.formData.customerDiscountRate : 0;
                discountRate = (part.part_discount === 'Yes') ? discountRate : 0;

                let dialogRowData = this.state.dialogRowData;

                dialogRowData = {
                    ...dialogRowData,
                    discountRate,
                    partImage: part.thumbnail_url,
                    part: part.part_id,
                    partNumber: part.part_number,
                    partDescription: part.part_description,
                    partDiscount: part.part_discount,
                    quantity: 1,
                    unitPrice: part.price,
                    warrantyId: part.pwt_id,
                    warrantyName: part.pwt_name,
                    warrantyDuration: part.pwt_duration
                }

                this.setState({dialogRowData}, () => {
                    this.calculateDialogTotals();

                    if(this.state.updatePartDialogOpen) {
                        this.updatePartSelectQuantity.current.focus();
                    } else {
                        this.partSelectQuantity.current.focus();
                    }
                });
            }
        } else {
            this.setState({
                dialogRowData: initialState.dialogRowData
            });
        }
    }

    handleDialogPartRowChange = decimals => e => {
        const { name, value } = e.target;
        let dialogRowData = this.state.dialogRowData;
        let newVal = decimals ? parseFloat(value).toFixed(decimals) : value;

        dialogRowData = {
            ...dialogRowData,
            [name]: newVal
        };

        this.setState({dialogRowData}, () => this.calculateDialogTotals());
    }

    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleRemoveRow = (idx) => () => {
        let rows = [...this.state.formData.partRows];
        rows.splice(idx, 1);
        this.setState({
            formData:{
                ...this.state.formData,
                partRows: rows
            }
        },
        () => {
            this.calculateOverallTotals();
        });
    }

    calculateOverallTotals = () => {
        let overallSubTotal = 0;
        let vat = 0;
        let overallPrice = 0;
        this.state.formData.partRows.forEach((row) => {
            overallSubTotal = overallSubTotal + (row.totalPrice*1);
        });
        vat = (overallSubTotal * this.state.formData.customerVatDecimal);
        overallPrice = (overallSubTotal + vat);
        this.setState({
            formData: {
                ...this.state.formData,
                subTotal: overallSubTotal.toFixed(2),
                vat: vat.toFixed(2),
                overallPrice: overallPrice.toFixed(2)
            }
        });
    }

    handleViewPart = rowData => {
        this.setState({
            viewPartDialogOpen: true,
            dialogRowData: rowData
        });
    }

    handleUpdatePart = rowData => {
        let selectedWarrantyTerm = this.state.selectedWarrantyTerm;
        selectedWarrantyTerm = {
            id: rowData.warrantyId,
            name: rowData.warrantyName,
            duration: rowData.warrantyDuration
        };
        this.setState({
            updatePartDialogOpen: true,
            dialogRowData: rowData,
            selectedWarrantyTerm
        });
    }

    handleRemovePart = rowIdx => {
        let partRows = [...this.state.formData.partRows];

        partRows.splice(rowIdx, 1);
        
        this.setState({
            formData: {
                ...this.state.formData,
                partRows
            }
        }, () => this.calculateOverallTotals());
    }

    handleMove = (rowIdx, dir) => {
        let partRows = [...this.state.formData.partRows];
        let rowMoveUp, rowMoveDown;

        switch(dir) {
            case 'up':
                rowMoveUp = partRows[rowIdx];
                rowMoveDown = partRows[rowIdx - 1];

                partRows.splice(rowIdx, 1, rowMoveDown);
                partRows.splice(rowIdx - 1, 1, rowMoveUp);
            break;
            case 'down':
                rowMoveDown = partRows[rowIdx];
                rowMoveUp = partRows[rowIdx + 1];

                partRows.splice(rowIdx, 1, rowMoveUp);
                partRows.splice(rowIdx + 1, 1, rowMoveDown);
            break;
            default:
            break;
        }

        this.setState({
            formData: {
                ...this.state.formData,
                partRows
            }
        });

    }

    handleWarrantyChange = name => e => {
        if(name === 'warrantyTerm'){
            if(e) {
                this.setState({
                    ...this.state,
                    selectedWarrantyTerm: {
                        id: e.value,
                        name: e.name,
                        duration: e.duration
                    }
                });
            } else {
                this.setState({
                    ...this.state,
                    selectedWarrantyTerm: initialState.selectedWarrantyTerm
                });
            }
        } else {
            const { value } = e.target;
            let data = this.state.warrantyUpdate ? 'dialogRowData' : 'selectedPartRow';
            let dataToUpdate = this.state[data];

            dataToUpdate = {
                ...dataToUpdate,
                [name]: value
            };
            this.setState({
                ...this.state,
                [data]: dataToUpdate
            });
        }
    }


    handleWarrantyDialogOpen = () => {
        this.setState({updatePartDialogOpen: false, warrantyDialogOpen: true});
    }

    handleWarrantyDialogClose = () => {
        this.setState({warrantyUpdate: false, warrantyDialogOpen: false});
    }

    handleWarrantyUpdate = () => {
        this.setState({
            warrantyUpdate: true,
            warrantyDialogOpen: true,
            updatePartDialogOpen: false
        });
    }

    handleWarrantyDialogSuccess = () => {
        const { selectedWarrantyTerm } = this.state;

        if(this.state.warrantyUpdate) {
            //Updating existing part
            this.setState({
                warrantyUpdate: false,
                warrantyDialogOpen: false,
            }, () => {
                let partRows = [...this.state.formData.partRows];

                partRows[this.state.dialogRowData.rowIdx] = {
                    ...partRows[this.state.dialogRowData.rowIdx],
                    warrantyId: selectedWarrantyTerm.id,
                    warrantyName: selectedWarrantyTerm.name,
                    warrantyDuration: selectedWarrantyTerm.duration
                };

                this.setState({
                    formData: {
                        ...this.state.formData,
                        partRows
                    }
                }, this.handleUpdatePartDialogSuccess);
            });
        } else {
            this.setState({
                warrantyUpdate: false, 
                warrantyDialogOpen: false,
                selectedPartRow:{
                    ...this.state.selectedPartRow,
                    warrantyId: selectedWarrantyTerm.id,
                    warrantyName: selectedWarrantyTerm.name, 
                    warrantyDuration: selectedWarrantyTerm.duration,
                }
            }, () => this.handlePartSelectAddRow());
        }
    }

    handleViewPartDialogClose = () => {
        this.setState({
            viewPartDialogOpen: false
        });
    }


    handleUpdatePartDialogSuccess = () => {
        const { selectedWarrantyTerm } = this.state;

        let partRows = [...this.state.formData.partRows];
        let rowIndex = this.state.dialogRowData.rowIdx;
        let newRow = this.state.dialogRowData;

        newRow = {
            ...newRow,
            warrantyId: selectedWarrantyTerm.id,
            warrantyName: selectedWarrantyTerm.name,
            warrantyDuration: selectedWarrantyTerm.duration
        };

        partRows.splice(rowIndex, 1, newRow);

        this.setState({
            formData: {
                ...this.state.formData,
                partRows
            },
            updatePartDialogOpen: false
        }, () => this.calculateOverallTotals());
    }

    handleUpdatePartDialogClose = () => {
        this.setState({
            updatePartDialogOpen: false  
        });
    }


    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
            formData: {
                ...this.state.formData,
                previewMode: false
            }
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    submit = () => {
        this.setState({
            formErrors: [],
            isLoading: true
        },
        () => {
            API.post(`/sales/quotations/${this.state.cq.quote_id}/amend`, this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                    this.props.scrollToTop();
                }
                else {
                    if(result.data.pdf){
                        this.setState({
                            isLoading: false
                        },
                        () => {
                            pdfFromBase64(result.data.pdf, result.data.pdfName);
                        });
                    }
                    else {
                        this.setState({
                            redirect: true,
                            redirectID: result.data.quoteId
                        });
                    }
                }
            });
        });
    }

    handleDateChange = fieldName => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: date
            }
        });
    };

    handlePreview = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                previewMode: true
            }
        },
        () => {
            this.submit();
        });
    }

    updateRows = partRows => {
        this.setState({formData:{...this.state.formData, partRows}})
    }

    addPackage = (salesPackage, qty=1) => {        
        let partRows = _.map(salesPackage.details, i => ({
            partImage:          i.part?.default_image?.file_path,
            part:               i.part.part_id,
            partNumber:         i.part.part_number,
            partDescription:    i.part.part_description,
            partDiscount:       i.pd_discount,
            quantity:           parseFloat(i.pd_qty) * parseInt(qty),
            unitPrice:          parseFloat(i.pd_unit_price).toFixed(2),
            unitDiscount:       parseFloat(i.pd_unit_discount).toFixed(2),
            newUnitPrice:       parseFloat(i.pd_new_unit_price).toFixed(2),
            subTotal:           parseFloat(parseFloat(i.pd_subtotal) * parseInt(qty)).toFixed(2),
            discountRate:       parseFloat(i.pd_discount).toFixed(2),
            discountTotal:      parseFloat(parseFloat(i.pd_discount_total) * parseInt(qty)).toFixed(2),
            totalPrice:         parseFloat(parseFloat(i.pd_total) * parseInt(qty)).toFixed(2),
            warrantyId:         i.pd_warranty_id,   
            warrantyName:       i.pd_warranty_name,
            warrantyDuration:   i.pd_warranty_duration,
            notes:              i.pd_notes,
        }));

        let salesPackages = this.state.formData.salesPackages;
        let idx = _.findIndex(salesPackages, {id: salesPackage.p_id});
        if (idx > -1) {
            salesPackages[idx].quantity += parseInt(qty);
        } else {
            salesPackages.push({
                id: salesPackage.p_id,
                quantity: parseInt(qty)
            })
        }

        this.setState({
            formData: {
                ...this.state.formData,
                salesPackages: salesPackages,
                partRows: [...this.state.formData.partRows, ...partRows]
            }
        }, this.calculateOverallTotals)
    }


    setLinkedPartsFormData = (linkedPartsFormData) => {
        this.setState({linkedPartsFormData})
    }

    handleLinkedPartsDialogClose = () => {
        this.setState({linkedPartsDialogOpen: false})
    }

    addLinkedParts = () => {
        let partRows = [];

        _.each(_.filter(this.state.linkedPartsFormData, i => i.include && parseFloat(i.quantity) > 0), i => {

            let price        = parseFloat(i.price)        > 0 ? parseFloat(i.price)        : 0;
            let discountRate = parseFloat(i.discountRate) > 0 ? parseFloat(i.discountRate) : 0;
            let quantity     = parseFloat(i.quantity)     > 0 ? parseFloat(i.quantity)     : 0;

            let warranty = _.find(this.state.warrantyTerms, {value: i.part_warranty_terms})

            partRows.push({
                part:             i.part_id,
                partDescription:  i.part_description,
                partNumber:       i.part_number,
                partImage:        i.thumbnail_url,
                
                rowIdx:           partRows.length,
                rowNumber:        partRows.length + 1,
                
                quantity:         quantity,
                discountRate:     parseFloat(discountRate).toFixed(2),
                partDiscount:     parseFloat(discountRate) > 0 ? 'Yes' : 'No',
                
                unitPrice:         parseFloat(price).toFixed(2),
                unitDiscount:      parseFloat(parseFloat(price) * (parseFloat(discountRate) / 100)).toFixed(2),
                newUnitPrice:      parseFloat(parseFloat(price) - (parseFloat(price) * (parseFloat(discountRate) / 100))).toFixed(2),
                subTotal:          parseFloat(parseFloat(price) * quantity).toFixed(2),
                discountTotal:     parseFloat((parseFloat(price) * (parseFloat(discountRate) / 100)) * quantity).toFixed(2),
                totalPrice:        parseFloat((parseFloat(price) - (parseFloat(price) * (parseFloat(discountRate) / 100))) * quantity).toFixed(2),
                
                warrantyDuration: warranty.duration,
                warrantyId:       warranty.value,
                warrantyName:     warranty.name,

                notes:            i.notes,
            });
        });
        this.setState({
            formData:{
                ...this.state.formData, 
                partRows: [...this.state.formData.partRows, ...partRows]
            }, 
            linkedPartsDialogOpen: false, 
            linkedPartsFormData: initialState.linkedPartsFormData
        }, () => this.calculateOverallTotals());
    }

    displayLinkedParts = (partRow, linkedDocs) => {
        let linkedParts = _.map(_.find(this.state.allPartsData, {part_id: partRow.part}).linked_parts, i => {
            let quantity     = parseFloat(i.quantity) * parseFloat(partRow.quantity);
            let discountRate = partRow.discountRate;
            return {
                ..._.find(this.state.allPartsData, {part_id: i.part_id}),
                include: true,
                quantity, discountRate
            }
        });

        this.setState({
            linkedPartsFormData:   _.orderBy(linkedParts, ['part_number'], ['asc']),
            pickingDocuments:       linkedDocs,
            linkedPartsDialogOpen: true
        })
    }

    checkServiceDiscount = () => {
        if (this.state.formData.quotationType !== 'Without Discount' && _.find(this.state.formData.partRows, i => parseInt(i.discountTotal) > 0 && _.find(this.state.allPartsData, {part_id: i.part}).part_product === 'No')) {
            this.props.deployDialog(
                <Grid container spacing={2} style={{alignItems: 'center'}}>
                    <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                            The following services are discounted. Please check the services and remove the discount if necessary.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Table>
                            <TableHead style={{backgroundColor: colors.primary, color: colors.white}}>
                                <TableRow>
                                    <TableCell style={{width: '38px', color: colors.white}}></TableCell>
                                    <TableCell style={{width: '13%', color: colors.white}}>Part Number</TableCell>
                                    <TableCell style={{color: colors.white}}>Description</TableCell>
                                    <TableCell style={{width: '13%', color: colors.white}}>Before Discount</TableCell>
                                    <TableCell style={{width: '13%', color: colors.white}}>Discount Rate</TableCell>
                                    <TableCell style={{width: '13%', color: colors.white}}>After Discount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(_.filter(this.state.formData.partRows, i => parseInt(i.discountTotal) > 0 && _.find(this.state.allPartsData, {part_id: i.part}).part_product === 'No'), i => 
                                    <TableRow key={i.rowIdx}>
                                        <TableCell>
                                            <ImageWithError
                                                src={i.partImage}
                                                alt={i.partNumber}
                                                style={{width: '30px', height: '30px'}}
                                            />
                                        </TableCell>
                                        <TableCell>{i.partNumber}</TableCell>
                                        <TableCell>{i.partDescription}</TableCell>
                                        <TableCell>£{ parseFloat(i.subTotal).toFixed(2)}</TableCell>
                                        <TableCell style={{color: colors.red}}>{ parseFloat(i.discountRate).toFixed(2)}%</TableCell>
                                        <TableCell style={{color: colors.red}}>£{ parseFloat(i.totalPrice).toFixed(2)}</TableCell>
                                    </TableRow>                            
                                )}
                            </TableBody>
                        </Table>
                        {/* <CiDataTable
                            config={{
                                key: 'idx',
                            }} 
                            rows={_.filter(this.state.formData.partRows, i => parseInt(i.discountTotal) > 0 && _.find(this.state.allPartsData, {part_id: i.part}).part_product === 'No')}
                            columns={[
                                {
                                    heading: '',
                                    field: 'partImage',
                                    fieldFormat: 'image',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Part Number',
                                    field: 'partNumber',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Description',
                                    field: 'partDescription',
                                    truncate: true,
                                },
                                {
                                    heading: 'Before Discount',
                                    field: 'subTotal',
                                    sizeToContent: true,
                                    fieldFormat: 'currency'
                                },
                                {
                                    heading: 'Discount Rate',
                                    field: i => parseFloat(i.discountRate).toFixed(2),
                                    sizeToContent: true,
                                    fieldSuffix: '%',
                                    style: {color: colors.red}
                                },
                                {
                                    heading: 'After Discount',
                                    field: 'totalPrice',
                                    sizeToContent: true,
                                    fieldFormat: 'currency',
                                    style: {color: colors.red}
                                }
                            ]}
                        /> */}
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button variant="outlined" onClick={this.props.closeDialog}> Close </Button>
                        <Button variant="contained" color="primary" onClick={()=>{this.props.closeDialog();this.handleConfirmationOpen();}}> Continue </Button>
                    </Grid>
                </Grid>
                , 
                'Discounted Services', 
                'error', 
                'md'
            )
        } else {
            this.handleConfirmationOpen();
        }
    }

    render() {
        const { formErrors, cq, formData, selectedPartRow, pickingDocuments } = this.state;
        if(this.state.redirect === true) {
            this.props.deploySnackBar("success", "You have successfully amended this quote")
            return <Redirect 
                        to={{
                            pathname:`/sales/quotation/view/${this.state.redirectID}`,
                        }}
                    />
        }
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Amend Quotation
                    </Typography>
                </Grid>
                {(this.state.isLoading && (
                    <div>
                        <LoadingCircle />    
                    </div>
                )) || (  
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    {formErrors && formErrors.generic && (
                                        <React.Fragment>
                                            <Typography component={"div"} style={{color: colors.red}}>
                                                {formErrors.generic}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                    <Grid container item spacing={3}>
                                        <Grid item xs={6}>
                                            <Grid container item spacing={3}>
                                                <Grid item lg={3}>
                                                    <Typography variant="subtitle1">
                                                        Quote Date
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={9}>
                                                    <Typography variant="subtitle1">
                                                        {moment(cq.quote_date).format("DD/MM/YYYY")}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item spacing={3}>
                                                <Grid item lg={3}>
                                                    <Typography variant="subtitle1">
                                                        Quoted By
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={9}>
                                                    <Typography variant="subtitle1">
                                                        {cq.quote_staff_name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container item spacing={3}>
                                                <Grid item lg={3}>
                                                    <Typography variant="subtitle1">
                                                        Reference
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={9}>
                                                    <Typography variant="subtitle1">
                                                        {cq.full_reference}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item spacing={3}>
                                                <Grid item lg={3}>
                                                    <Typography variant="subtitle1">
                                                        Payment Terms
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={9}>
                                                    <Typography variant="subtitle1">
                                                        {formData.paymentTerms}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3} style={{alignItems: 'stretch'}}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper style={{height: '100%'}}>
                                    <CustomerDetails
                                        company={cq.quote_cust_name}
                                        address={formData.customerAddress}
                                        paymentTerms={formData.paymentTerms}
                                        customerContact={formData.customerContact}
                                        customerPhone={formData.customerPhone}
                                        customerMobile={formData.customerMobile}
                                        customerEmail={formData.customerEmail}
                                        customerContactList={this.state.registeredContactList}
                                        handleSelectChange={this.handleSelectChange}
                                        formErrors={this.state.formErrors}
                                    />
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <DeliveryDetails
                                                deliveryCustomerAddressType={formData.deliveryCustomerAddressType}
                                                deliveryCustomerAddress={formData.deliveryCustomerAddress}
                                                deliveryCustomerContact={formData.deliveryCustomerContact}
                                                deliveryCustomerPhone={formData.deliveryCustomerPhone}
                                                deliveryCustomerMobile={formData.deliveryCustomerMobile}
                                                deliveryCustomerEmail={formData.deliveryCustomerEmail}
                                                lists={this.state.lists}
                                                handleSelectChange={this.handleSelectChange}
                                                formErrors={this.state.formErrors}
                                            />
                                        </PaddedPaper>
                                    </Grid>
                                    {formData.deliveryCustomerAddress && this.state.lists.customerAddressList.length && _.find(this.state.lists.customerAddressList, { value: formData.deliveryCustomerAddress }) &&
                                        <Grid item xs={12}>
                                            <PaddedPaper>
                                                <Typography variant="h5" gutterBottom>
                                                    Couriers
                                                </Typography>
                                                <DataTable
                                                    config={{
                                                        key: 'cz_id',
                                                    }}
                                                    columns={[
                                                        {
                                                            
                                                            field:   i => <img src={_.find(i.courier.file, {'cour_file_type': 'Logo'}).file_url} alt='logo' height={30}/>,
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading: 'Courier',
                                                            field:   'courier.cour_name',
                                                            sizeToContent: true,
                                                        },
                                                        {
                                                            heading:  'Zone',
                                                            field:    'cz_name',
                                                            info:      i => <>{_.map(i.cz_desc.split('\n'), i => <>{i}<br/></>)}</>
                                                        }
                                                    ]}
                                                    rows={_.find(this.state.lists.customerAddressList, { value: formData.deliveryCustomerAddress })?.address?.zones}
                                                />
                                            </PaddedPaper>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <AddPackageRow 
                                    discountType={formData.quotationType}
                                    addPackage={this.addPackage}
                                />
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <AddPartEntryRow 
                                    partList={this.state.partList}
                                    handlePartSelectAddRow={this.handlePartSelectAddRow}
                                    handleWarrantyDialogOpen={this.handleWarrantyDialogOpen}
                                    handlePartSelectRowRemove={this.handlePartSelectRowRemove}
                                    selectedPartRow={this.state.selectedPartRow}
                                    handlePartSelect={this.handlePartSelect}
                                    handlePartSelectRowChange={this.handlePartSelectRowChange}
                                    quotationType={cq.quote_type}
                                    partSelectQuantity={this.partSelectQuantity}
                                    formErrors={this.state.formErrors}
                                />
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <QuotePartItems 
                                    partRows={formData.partRows}
                                    partList={this.state.partList}
                                    quotationType={cq.quote_type}
                                    handleViewPart={this.handleViewPart}
                                    handleUpdatePart={this.handleUpdatePart}
                                    handleRemovePart={this.handleRemovePart}
                                    handleMove={this.handleMove}
                                />
                                {formData.partRows.length > 1 &&
                                    <Grid container xs={12} align="right" justify="flex-end" style={{}}>
                                        <Grid item xs={3} style={{marginTop:'1em'}}>
                                            <OrderPartDetailsButton partRows={formData.partRows}  callback={this.updateRows}/>
                                        </Grid>
                                    </Grid>
                                }
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <QuoteTotals 
                                    partRows={formData.partRows}
                                    partList={this.state.partList}
                                    nominalList={this.state.nominalList}
                                    handleRemoveRow={this.handleRemoveRow}
                                    handleAddRow={this.handleAddRow}
                                    handleSelectRowChange={this.handleSelectRowChange}
                                    handleRowChange={this.handleRowChange}
                                    formErrors={formErrors}
                                    subTotal={formData.subTotal}
                                    customerVatPercentage={formData.customerVatPercentage}
                                    customerDiscountRate={formData.customerDiscountRate}
                                    vat={formData.vat}
                                    overallPrice={formData.overallPrice}
                                    quotationType={formData.quotationType}
                                />
                            </PaddedPaper>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <>
                                    <QuoteDetails 
                                        handleChange={this.handleChange}
                                        notes={formData.notes}
                                    />
                                </>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <AddPromotionDetails 
                                    onChange={promotions => this.setState({formData: {...this.state.formData, promotions}})}
                                    value={formData.promotions}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <div className='buttonRow'>
                                        <BackButton props={this.props} />
                                        <Button onClick={this.handlePreview}
                                                variant="outlined"
                                                color="default">
                                            Preview
                                        </Button>
                                        <Button onClick={this.checkServiceDiscount}
                                                variant="contained"
                                                color="primary"
                                                disabled={this.state.isLoading || !formData.customerVatPercentage}>
                                            Update
                                        </Button>
                                    </div>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        {/* WARRANTY DIALOG */}
                        <WarrantyDialog 
                            warrantyDialogOpen={this.state.warrantyDialogOpen}
                            handleWarrantyDialogClose={this.handleWarrantyDialogClose}
                            selectedPartRow={selectedPartRow}
                            warrantyUpdate={this.state.warrantyUpdate}
                            dialogRowData={this.state.dialogRowData}
                            warrantyTerms={this.state.warrantyTerms}
                            handleWarrantyChange={this.handleWarrantyChange}
                            selectedWarrantyTerm={this.state.selectedWarrantyTerm}
                            handleWarrantyDialogSuccess={this.handleWarrantyDialogSuccess}
                        />
                        {/* VIEW PART DIALOG */}
                        <FullScreenDialog
                            open={this.state.viewPartDialogOpen}
                            onClose={this.handleViewPartDialogClose}
                            title="Part Information"
                            content={<ViewPart id={this.state.dialogRowData.part} />}
                        />
                        {/* UPDATE PART DIALOG */}
                        <Dialog 
                            open={this.state.updatePartDialogOpen} 
                            onClose={this.handleUpdatePartDialogClose} 
                            maxWidth="lg" 
                            fullWidth={true} 
                            scroll="body"
                            disableBackdropClick
                            disableEscapeKeyDown
                        >
                            <DialogTitle>Update Part</DialogTitle>
                            <DialogContent>
                                <AddPartEntryRow 
                                    partList={this.state.partList}
                                    handlePartSelectAddRow={this.handlePartSelectAddRow}
                                    selectedPartRow={selectedPartRow}
                                    handlePartSelect={this.handleDialogPartSelect}
                                    handlePartSelectRowChange={this.handleDialogPartRowChange}
                                    quotationType={cq.quote_type}
                                    dialog={true}
                                    dialogRowData={this.state.dialogRowData}
                                    partSelectQuantity={this.updatePartSelectQuantity}
                                    handleWarrantyUpdate={this.handleWarrantyUpdate}
                                    handleUpdatePartDialogSuccess={this.handleUpdatePartDialogSuccess}
                                    handleUpdatePartDialogClose={this.handleUpdatePartDialogClose}
                                />
                            </DialogContent>
                        </Dialog>
                        {/* CONFIRMATION DIALOG */}
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen} 
                            success={this.handleConfirmationSuccess} 
                            close={this.handleConfirmationClose} 
                            title="Amend Quotation?" 
                            message="Are you sure you want to amend this quotation?"
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen}
                            onClose={this.handleSnackbarClose}
                            message='You have successfully amended this quotation'
                        />
                        <LinkedPartsDialog
                            open={this.state.linkedPartsDialogOpen}
                            formData={this.state.linkedPartsFormData}
                            setFormData={this.setLinkedPartsFormData}
                            close={this.handleLinkedPartsDialogClose}
                            pickingDocuments={pickingDocuments}
                            submit={this.addLinkedParts}
                            warrantyTerms={this.state.warrantyTerms}
                            allowDiscount={this.state.formData.orderType === 'With Discount'}
                        />
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
        loading: {
            ...state.loading
        }
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, msg) => {
            dispatch(deploySnackBar(variant, msg))
        },
        deployDialog: (content, title, variant, size, style) => {
            dispatch(deployDialog(content, title, variant, size, style))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmendQuotation);