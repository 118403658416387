import React, { Component} from 'react';

import { Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon   from 'Components/Common/Icons/AllIcon'; 

import icons      from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';

class Packages extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Sales Packages</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <DataTable
                        config={{
                            key: 'p_id',
                        }}
                        rows={this.props.packages}
                        columns={[
                            {
                                heading: 'Package',
                                field: 'p_name',
                            },
                            {
                                heading:       'Discount',
                                field:         i => 
                                        <AllIcon
                                            icon={i.p_discounted ? icons.true : icons.false}
                                            color={i.p_discounted ? colors.green : colors.red}
                                            tooltip={i.p_discounted ? `£${i.p_discount} (${Math.ceil(parseFloat(parseFloat(i.p_discount) / parseFloat(i.p_subtotal)) * 100)}%)` : 'No Discount'}
                                            noMargin
                                        />,
                                sizeToContent: true,
                                alignment:     'center',
                            },
                            {
                                heading:       'Sub Total',
                                field:         'p_subtotal',
                                fieldFormat:   'currency',
                                sizeToContent: true,
                            },
                            {
                                heading:       'VAT',
                                field:         'p_vat',
                                fieldFormat:   'currency',
                                sizeToContent: true,
                            },
                            {
                                heading:       'Total',
                                field:         'p_total',
                                fieldFormat:   'currency',
                                sizeToContent: true,
                            },
                            {
                                actions: r => ([
                                    {
                                        name: 'View',
                                        icon: icons.search,
                                        link: `/sales/package/${r.p_id}`
                                    }
                                ])
                            }

                        ]}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}



export default Packages;
