import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import API from 'API';
import {
    Grid,
    Box,
    Typography,
    Button,
    Hidden,
    Tooltip
} from '@material-ui/core';
import { handleTabChange } from 'Functions/FormFunctions';
import TabBar from 'Components/Common/Navigation/TabBar';
import { TabPanel, toggleDialog } from 'Functions/MiscFunctions';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { statusColour } from 'Helpers/ColourHelper';
import GenericEmailDialog   from 'Components/Common/Dialogs/GenericEmailDialog';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import CreateLinkedFormDialog from './Dialogs/CreateLinkedFormDialog'
import ViewFormNotesDialog from './Dialogs/ViewFormNotesDialog';
import PrePopulateFormDialog from './Dialogs/PrePopulateFormDialog';
import FormAttachmentsDialog from './Dialogs/FormAttachmentsDialog';
import EngineerMediaDialog from './Dialogs/EngineerMediaDialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { FORM_SYSTEM_CAT_ID_MACHINE_INSTALLATION } from 'Constants';

const initialState = () => ({
    currentTab: 'sheet1',
    sheets: [],
    dialog: {
        form: null,
        // Linked Forms Dialog
        linkedFormsDialog: false,
        // Pre-Populate Dialog
        prePopulateFormDialog: false,
        prePopulationFormList: [],
        // Attachments Dialog
        attachmentsDialog: false,
        // Engineer Media Dialog
        engineerMediaDialog: false,
        readOnly: false,
    },
    access: {
        reviewForm: false,
    },
})

class JobSheets extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleTabChange = handleTabChange.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
        this.timeout = null;
    }

    componentDidMount() {
        this.getAccess();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    checkIfFormsNeedRefreshing = (formsLastUpdated) => {
        const { job } = this.props;
        API.post(`/engineers/jobs/${job.id}/hasEngineerJobFormsUpdated`, {formsLastUpdated})
        .then(result => {
            if(result.data){
                this.props.refreshTabs();
            }
        });
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/view-engineer-job:review-job-sheet-form'),
        ])
        .then(([reviewRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    reviewForm: (reviewRes.data && reviewRes.data.has_access) || false,
                }
            }, () => this.loadComponentData());
        }) 
    }

    loadComponentData = () => {
        const { job } = this.props;

        API.get(`/engineers/jobs/${job.id}/sheets`)
        .then(result => {

            let sheets = result.data;                     
            this.setState({
                ...this.state,
                sheets,
                isLoading: false
            },
            () => {

                let formsLastUpdated = {};
                // Loop sheets and forms and create array with form id as key and last updated as value
                _.forEach(sheets, (sheet) => {
                    _.forEach(sheet?.forms, (form) => {
                        formsLastUpdated[form?.id] = form?.lastUpdated;
                    });
                });

                clearTimeout(this.timeout);
                /*
                * Set Timeout to check form refresh every 3 seconds
                * - Used to check if a form status has changed on external link
                */
                this.timeout = setInterval(() => {
                    this.checkIfFormsNeedRefreshing(formsLastUpdated);
                }, 3000);
                
            });
        });
    };

    createInstallation = (form) => {

        this.setState({
            ...this.state,
            dialog: {
                ...this.state.dialog,
                form: form
            }
        }, () => {
            this.toggleDialog('linkedFormsDialog')
        });
    }

    openForm = (form, forAdmin, versionCheck=false, populateCheck=false) => {

        // External Link for Admin
        if(forAdmin){

            /* 
            * If the form has status of 'Not Started' check if a newer version of the form exists and update it
            */
            if(form?.status == 'Not Started' && versionCheck){

                API.get(`/forms/${form?.id}/checkIfNewerVersionAvailable`)
                .then(result => {
    
                    if(result.data?.newerVersion){

                        this.props.deployDialog(
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="body1" gutterBottom>
                                        The newer version of  the <b>{form?.config?.name}</b> form is available.
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        The system will up-issue from <b>v{form?.config?.v}</b> to <b>v{result.data?.newerVersion?.v}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className='buttonRow'>
                                    <Button size='small' variant="outlined" onClick={() => this.upIssueForm(form, result.data?.newerVersion?.v, false)}>
                                        Don't Pre-populate
                                    </Button>
                                    <Button size='small' variant="outlined" onClick={() => this.upIssueForm(form, result.data?.newerVersion?.v, true)}>
                                        Pre-populate Form
                                    </Button>
                                </Grid>
                            </Grid>,
                            'Newer Version Available','amber','xs', null
                        );
                    }
                    else {
                        /* 
                        * No newer version available
                        * - Check if we need to pre-populate the form
                        */
                        if(populateCheck){
                            this.prePopulateCheck(form);
                        }
                        else {
                            window.open(form?.externalLinkForAdmin, '_blank');
                        }
                    }
                });
            }
            else {
                window.open(form?.externalLinkForAdmin, '_blank');
            }
        }
        // External Link for Customer
        else {
            window.open(form?.externalLink, '_blank');
        }
    }

    prePopulateCheck = (form) => {
        let jobSheets = this.state.sheets;

        const originalId    = form?.config?.oId;
        const version       = form?.config?.v;
        let prePopulationFormList = [];

        if(form?.lastPrePopulated == null){
            // Create an array of all the forms we could possible pre-populate from
            _.forEach(jobSheets, (sheet) => {
                _.forEach(sheet?.forms, (f) => {
    
                    if( (f?.status != 'Not Started' && f?.status != 'Cancelled') && 
                        f?.id != form?.id && // Don't list the current form
                        f?.config?.oId == originalId && // Only list forms with the same original id
                        f?.config?.v == version // Only list forms with the same version
                    ){
                        prePopulationFormList.push({
                            value: f?.id,
                            label: 'Job Sheet ' + sheet?.sheetNo + ': ' + f?.config?.name + ' (' + f?.status + ')'
                        });
                    }
                });
            });
        }

        // If there are forms to check, show the dialog
        if(prePopulationFormList.length > 0){
            this.setState({
                ...this.state,
                dialog: {
                    ...this.state.dialog,
                    prePopulationFormList,
                    form: form
                }
            }, () => {
                this.toggleDialog('prePopulateFormDialog')
            });
        }
        else {
            window.open(form?.externalLinkForAdmin, '_blank');
        }
    }

    upIssueForm = (form, newVersion, prePopulate) => {
            
        API.post(`/forms/${form?.id}/upIssue`, {
            prePopulate
        })
        .then(result => {

            if(result.data?.success){

                this.props.refreshTabs();

                this.props.deployDialog(
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>
                                The <b>{form?.config?.name}</b> form has been up issued to <b>v{newVersion}</b> {prePopulate && <> and pre-populated</>}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className='buttonRow'>
                            <Button variant="outlined" onClick={() => {this.props.closeDialog();}}>
                                Ok
                            </Button>
                        </Grid>
                    </Grid>,
                    'Form Up Issued','amber','xs', null
                );
            }
            
        });
    }

    emailForm = (row) => {
        const { job } = this.props;

        const dialog =  <GenericEmailDialog 
                            templates='engineers'
                            customerId={job?.cust?.id} 
                            templateId={1} 
                            url={`/forms/${row.id}/emailLink`}
                            onSend={this.props.refreshTabs}
                        />
        this.props.deployDialog(dialog, `Email link for - ${row?.config?.name} Form`, 'Success');
    }

    viewNotes = (notes) => {

        this.props.deployDialog(<ViewFormNotesDialog 
            notes={notes}
        />, `View Notes`, 'Success', 'sm');
    }

    openAttachmentsDialog = (form, readOnly=false) => {
        this.setState({
            ...this.state,
            dialog: {
                ...this.state.dialog,
                form: form,
                readOnly
            }
        }, () => {
            this.toggleDialog('attachmentsDialog')
        });
    }
    openEngineerMediaDialog = (form, readOnly=false) => {
        this.setState({
            ...this.state,
            dialog: {
                ...this.state.dialog,
                form: form,
                readOnly
            }
        }, () => {
            this.toggleDialog('engineerMediaDialog')
        });
    }

    render() {
        const { currentTab, sheets, dialog, access } = this.state;
        return (
            <>
            <Box mt={3}>
                <Grid container item spacing={3}>
                    
                    <Grid item xs={12}>
                        <TabBar
                            tabs={sheets.map((sheet, idx) => ({
                                label: 'Job Sheet '+ sheet?.sheetNo,
                                value: 'sheet' + sheet?.sheetNo
                            }))
                            }
                            currentTab={currentTab} 
                            handleTabChange={this.handleTabChange}
                        />
                        {sheets.map((sheet, idx) => (
                            <TabPanel key={idx} value={currentTab} index={'sheet' + sheet?.sheetNo}>

                                <PaddedPaper>
                                    <Typography variant="h6" gutterBottom>
                                        Forms
                                    </Typography>
                                    
                                    <CiDataTable
                                        config={{
                                            key: 'id'
                                        }}
                                        columns={[
                                            {
                                                heading: 'Form Name',
                                                field: 'config.name'
                                            },
                                            {
                                                heading: 'Version',
                                                field: 'v',
                                                field: row => 'v'+row?.config?.v,
                                                alignment: 'center',
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Status',
                                                field: row => row?.stage ? row?.stage?.nm : row?.status,
                                                cellProps: rowData => {
                                                    return ({
                                                        style: {
                                                            color: statusColour(rowData?.status)
                                                        }
                                                    });
                                                },
                                            },
                                            {
                                                heading: 'Related Forms',
                                                field: row =>   row?.config?.systemCatId == 2 && 
                                                                <Tooltip title="Installation">
                                                                    <span>
                                                                        <AllIcon
                                                                            icon='screwdriver-wrench'
                                                                            color={row?.status == 'Completed' && !row?.linkedChildForm ? "orange" : ((row?.status == 'Completed' && row?.linkedChildForm && row?.linkedChildForm?.status != 'Completed') ? "red" : "default")}
                                                                            noMargin
                                                                            onClick={row?.linkedChildForm ? () => this.openForm(row?.linkedChildForm, true) : () => this.createInstallation(row)}
                                                                            disabled={row?.status != 'Completed'}
                                                                        />
                                                                    </span>
                                                                </Tooltip>,
                                                alignment: 'center',
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Attachments',
                                                field: row =>   <Tooltip title="View Attachments">
                                                                    <span>
                                                                        <AllIcon
                                                                            icon='paperclip'
                                                                            noMargin
                                                                            onClick={() => this.openAttachmentsDialog(row, true)}
                                                                            disabled={row?.attachments?.length == 0}
                                                                        />
                                                                    </span>
                                                                </Tooltip>,
                                                alignment: 'center',
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Engineer Media',
                                                field: row =>   <Tooltip title="View Engineer Media">
                                                                    <span>
                                                                        <AllIcon
                                                                            icon='images'
                                                                            noMargin
                                                                            onClick={() => this.openEngineerMediaDialog(row, true)}
                                                                            disabled={row?.engineerMedia?.length == 0}
                                                                        />
                                                                    </span>
                                                                </Tooltip>,
                                                alignment: 'center',
                                                sizeToContent: true
                                            },
                                            {
                                                actions: i => ([
                                                    {
                                                        icon: 'pen-to-square',
                                                        name: 'Review',
                                                        onClick: () => {this.openForm(i, true, false)},
                                                        disabled: (i?.stage?.nm != 'Awaiting Review' || !access?.reviewForm) ?? true,
                                                        hide: i?.config?.systemCatId != 2,
                                                        color: (i?.stage?.nm == 'Awaiting Review' && access?.reviewForm) ? 'green' : 'default'
                                                    },
                                                    {
                                                        icon: 'message-pen',
                                                        name: 'Notes',
                                                        onClick: () => {this.viewNotes(i?.notes)},
                                                        disabled: (i?.notes?.length == 0) ?? true,
                                                    },
                                                    {
                                                        icon: 'search',
                                                        name: 'Admin: View Form',
                                                        onClick: () => {this.openForm(i, true, true, (sheet?.sheetNo != 1 ? true : false))},
                                                    },
                                                    {
                                                        type: 'actionMenu',
                                                        rows: [
                                                            {
                                                                icon: 'envelope',
                                                                label: "Send to Customer",
                                                                onClick:() => {this.emailForm(i)},
                                                                display: i?.config?.systemCatId != 2 ? false : true,
                                                                disabled: (i?.status == 'Cancelled' || i?.status == 'Completed' ) ?? true,
                                                            },
                                                            {
                                                                icon: 'paperclip',
                                                                label: "Manage Attachments",
                                                                onClick:() => this.openAttachmentsDialog(i),
                                                                display: true
                                                            },
                                                            {
                                                                icon: 'paperclip',
                                                                label: "Manage Engineer Media ",
                                                                onClick:() => this.openEngineerMediaDialog(i),
                                                                display: true
                                                            },
                                                        ]
                                                    }
                                                    
                                                    
                                                ])
                                            }
                                        ]}
                                        rows={sheet?.forms}
                                    />

                                </PaddedPaper>

                            </TabPanel>
                        ))}
                        
                    </Grid>
                </Grid>
            </Box>
            {/* Related Forms Dialog */}
            <SmallFormDialog
                open={dialog?.linkedFormsDialog}
                onClose={() => this.toggleDialog('linkedFormsDialog', true)}
                title={'Create Installation Form'}
                content={
                    <CreateLinkedFormDialog
                        formId={dialog?.form?.id}
                        closeDialog={() => this.toggleDialog('linkedFormsDialog', true)}
                        callBack={this.props.refreshTabs}
                        systemCatID={FORM_SYSTEM_CAT_ID_MACHINE_INSTALLATION}
                    />
                }
                maxWidth='xs'
            />
            {/* Pre-Populate Data Dialog */}
            <SmallFormDialog
                open={dialog?.prePopulateFormDialog}
                onClose={() => this.toggleDialog('prePopulateFormDialog', true)}
                title={'Pre-populate Form'}
                content={
                    <PrePopulateFormDialog
                        formId={dialog?.form?.id}
                        prePopulationFormList={dialog?.prePopulationFormList}
                        closeDialog={() => this.toggleDialog('prePopulateFormDialog', true)}
                        callBack={this.props.refreshTabs}
                        openForm={() => window.open(dialog?.form?.externalLinkForAdmin, '_blank')}
                    />
                }
                maxWidth='xs'
            />
            {/* Attachments Dialog */}
            <SmallFormDialog
                open={dialog?.attachmentsDialog}
                onClose={() => this.toggleDialog('attachmentsDialog', true)}
                title="Attachments"
                content={
                    <FormAttachmentsDialog 
                        formId={dialog?.form?.id}
                        orderId={this.props.job?.order?.id}
                        customerId={this.props.job?.cust?.id}
                        attachments={dialog?.form?.attachments}
                        callBack={this.props.refreshTabs}
                        toggleDialog={() => {
                            this.toggleDialog('attachmentsDialog', true);
                        }}
                        readOnly={dialog?.readOnly}
                    />
                }
                maxWidth={dialog?.readOnly ? 'sm': 'lg'}
            />
            {/* Engineer Media Dialog */}
            <SmallFormDialog
                open={dialog?.engineerMediaDialog}
                onClose={() => this.toggleDialog('engineerMediaDialog', true)}
                title="Engineer Media"
                content={
                    <EngineerMediaDialog 
                        formId={dialog?.form?.id}
                        customerId={this.props.job?.cust?.id}
                        engineerMedia={dialog?.form?.engineerMedia}
                        callBack={this.props.refreshTabs}
                        toggleDialog={() => {
                            this.toggleDialog('engineerMediaDialog', true);
                        }}
                        readOnly={dialog?.readOnly}
                    />
                }
                maxWidth={dialog?.readOnly ? 'md': 'xl'}
            />

            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, variant, size, style) => {dispatch(deployDialog(content, title, variant, size, style))},
        closeDialog: () => {dispatch(closeDialog())},
    }
}

export default connect(null, mapDispatchToProps)(JobSheets);