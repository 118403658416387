import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import { Grid } from '@material-ui/core';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import { Link } from 'react-router-dom';
import CiDataTable from '../../Common/DataTables/CiDataTable';
import moment from 'moment';
import BackButton from 'Components/Common/Buttons/BackButton';
import {colors} from 'Helpers/ColourHelper';

const styles = theme => ({
    td : {
        border: 'none'
    },
    settingsHeading: {
        paddingTop: theme.spacing(2)
    }
});

class AssignedTransactions extends PureComponent {
    
    render() {
        const { statementDetails, access, transactions, handleDeleteOpen, downloadPresignedFile, handleDialogTransactionOpen } = this.props;
        const editable = statementDetails.bms_status == 'Open';
        return (
            <>
            <Grid container item spacing={3}>
                <Grid item xs={12} lg={3}>
                    <PaddedPaper>
                        <Grid container alignItems='center' spacing={1}>
                            <Grid item align='center'>
                                <FALightIcon icon='credit-card' style={{marginLeft: 8}} size='large' />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    Bank / Card
                                </Typography>  
                                <Typography variant="body1">
                                    {statementDetails?.bank?.bank_name}
                                </Typography>  
                            </Grid>         
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} lg={2}>
                    <PaddedPaper>
                        <Grid container alignItems='center' spacing={1}>
                            <Grid item align='center'>
                                <FALightIcon icon='calendar-alt' style={{marginLeft: 8}} size='large' />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    Month
                                </Typography>  
                                <Typography variant="body1">
                                    {statementDetails?.bms_month} {statementDetails?.bms_year}
                                </Typography>  
                            </Grid>         
                        </Grid>
                    </PaddedPaper>
                </Grid>
                {access.allStaffTransactions &&
                    <>
                        <Grid item xs={12} lg={3}>
                            <PaddedPaper>
                                <Grid container alignItems='center' spacing={1}>
                                    <Grid item align='center'>
                                        <FALightIcon icon='file-alt' style={{marginLeft: 8}} size='large' />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">
                                            Monthly Statement
                                        </Typography>  
                                        <Typography variant="body1">
                                            <Link to="" onClick={(e) => { e.preventDefault(); downloadPresignedFile(statementDetails?.statement_file_url)}} className='blueLink'>
                                                {statementDetails?.bms_monthly_statement_filename || '-' }
                                            </Link>
                                        </Typography>  
                                    </Grid>         
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <PaddedPaper>
                                <Grid container alignItems='center' spacing={1}>
                                    <Grid item align='center'>
                                        <FALightIcon icon='pound-sign' style={{marginLeft: 8}} size='large' />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">
                                            Total Spend
                                        </Typography>  
                                        <Typography variant="body1">
                                            £{statementDetails?.totalSpend}
                                        </Typography>  
                                    </Grid>         
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </>
                }
            </Grid>
            <Grid item xs={12}>
                <PaddedPaper>
                    <CiDataTable  
                        config={{
                            key: 'bank_trans_id',
                            responsiveImportance: true,
                            options: {
                                dataRef: true
                            }
                        }}
                        columns={[
                            {
                                heading: '',
                                field: rowData => Math.sign(rowData.bank_trans_gross) == '-1' ? 
                                                <FALightIcon icon='arrow-left' style={{color:colors.green}} />
                                                :
                                                <FALightIcon icon='arrow-right' style={{color:colors.red}} />,
                            },
                            {
                                heading: 'Statement Date',
                                field: rowData => moment(rowData.bank_trans_statement_date).format("DD-MM-YYYY"),
                                dataRef: 'bank_trans_statement_date',
                            },
                            {
                                heading: 'Bank / Card Transaction Date',
                                field: rowData => moment(rowData.bank_trans_date).format("DD-MM-YYYY"),
                                dataRef: 'bank_trans_date',
                            },
                            {
                                heading: 'Receipt Date',
                                field: rowData => moment(rowData.bank_trans_receipt_date).format("DD-MM-YYYY"),
                                dataRef: 'bank_trans_receipt_date',
                            },
                            {
                                heading: 'Assigned To',
                                field: rowData => rowData.staffFullName,
                                dataRef: 'staffFullName',
                            },
                            {
                                heading: 'Description',
                                field: rowData => rowData.children && rowData.children.length > 0 ? 'Various' : rowData.bank_trans_description,
                            },
                            {
                                heading: 'Net',
                                field: rowData => rowData.bank_trans_net,
                                alignment: 'right',
                                fieldPrefix: '£',
                            },
                            {
                                heading: 'VAT',
                                field: rowData => rowData.bank_trans_vat,
                                alignment: 'right',
                                fieldPrefix: '£',
                            },
                            {
                                heading: 'Amount',
                                field: rowData => rowData.bank_trans_gross,
                                alignment: 'right',
                                fieldPrefix: '£',

                            },
                            {
                                actions: rowData => {
                                    return [
                                        {name: 'View', icon: 'search', onClick:() => handleDialogTransactionOpen(rowData, "Completed") },
                                        {name: 'Download Receipt', icon: 'download', onClick: () => {downloadPresignedFile(rowData.receipt_file_url)}},
                                        {name: 'Delete Transaction', icon: 'trash-alt', onClick:() => handleDeleteOpen(rowData), disabled: !editable }
                                    ]
                                }
                            }
                        ]}
                        rows={transactions}
                    />
                    <div className='buttonRow'>
                        <BackButton props={this.props} />
                    </div>
                </PaddedPaper>
            </Grid>
            </>
        )
    }
}
export default withStyles(styles)(AssignedTransactions);